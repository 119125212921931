import React, { Component } from 'react'
import './PFEditView.css'


import { DeleteOutlined } from '@ant-design/icons';

export default class PFEditView extends Component {
  render() {
    return (
      <div className='PFEditView'>
        <DeleteOutlined onClick={() => this.props.deleteElementCallBack()} />
      </div>
    )
  }
}
