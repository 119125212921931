import React, { Component } from 'react'
import './PFContentView.css'
import PFPointView from './PFPointView'
import PFPaintResultView from './PFPaintResultView'
import PFDataLogic from './PFDataLogic'
import PFVolumeView from './PFVolumeView'
import PFEditView from './PFEditView'
import PFScaleView from './PFScaleView'
import PFOperateMode from './PFOperateMode'
import { Empty } from 'antd'
import PFOneBaseModel from './PFOneBaseModel'
import KLineVolumeYTitleView from '../Wave/KLineVolumeYTitleView'
export default class PFContentView extends Component {

  constructor(props) {
    super(props)

    let { kLineModels, grideValue, revertValue } = this.props.pfConfigData

    this.state = {
      'refresh': false,
      'pointContentHeight': 100,
      'selectedMeasure': null,
      'startLinePoint': null,
      'selectedElement': null
    }

    this.startNode = null
    this.endNode = null

    this.pointContainer = null
    this.volumeContainer = null
  }

  componentWillReceiveProps(nextProps) {
    let needRefresh =
      (nextProps.pfConfigData.grideValue != this.props.pfConfigData.grideValue) ||
      (nextProps.pfConfigData.revertValue != this.props.pfConfigData.revertValue) ||
      (nextProps.pfData != this.props.pfData)

    // if(needRefresh){
    // 计算新的Props
    this.handleNextPropsResize(nextProps)
    // }
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize);
    if (this.pointContainer != null) {
      this.pointContainer.addEventListener("scroll", this.handleScroll);
    }
    if (this.volumeContainer != null) {
      this.volumeContainer.addEventListener("scroll", this.handleVolumeScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.pointContainer.removeEventListener("scroll", this.handleScroll);
    this.volumeContainer.removeEventListener("scroll", this.handleVolumeScroll);
  }
  handleScroll = () => {
    var scrollLeft = this.pointContainer.scrollLeft;    // 获取垂直滚动位置
    if (this.volumeContainer != null) {
      this.volumeContainer.scrollTo({ left: scrollLeft });
    }
  }
  handleVolumeScroll = () => {
    var scrollLeft = this.volumeContainer.scrollLeft;    // 获取垂直滚动位置
    if (this.pointContainer != null) {
      this.pointContainer.scrollTo({ left: scrollLeft });
    }
  }
  setVolumeScrollCallBack(volumeContainer) {
    this.volumeContainer = volumeContainer
  }
  handleNextPropsResize(nextProps) {
    let { pfData } = nextProps
    this.reCaculate(pfData)
  }
  handleResize = () => {
    let { pfData } = this.props
    this.reCaculate(pfData)
  }
  reCaculate(pfData) {
    // 导航栏高度
    let navHeight = 44
    // 导航栏与成交量容器高度
    let pointAndVolumeContainerHeight = window.innerHeight - navHeight - 5
    // 坐标宽度
    let priceAxisYContainerWeight = 60
    // 右边左边宽度
    let rightPriceAxisYContainerWeight = priceAxisYContainerWeight + pfData.pointWidth * 2
    // 点数图容器宽度
    let pointContainerWidth = window.innerWidth - priceAxisYContainerWeight - rightPriceAxisYContainerWeight - 10 * 2

    console.log('tag::pointContainerWidth', pointContainerWidth)

    // 成交量高度
    let volumeContainerHeight = pointAndVolumeContainerHeight / 5.0
    // 成交量与点数图分割线高度
    let pointVolumeSeparateLineHeight = 1
    // 点数图容器高度
    let pointContainerHeight = pointAndVolumeContainerHeight - volumeContainerHeight - pointVolumeSeparateLineHeight
    // 依据宽度重新计算
    pfData.caculatePFPointDisplay(pointContainerWidth, pointContainerHeight)
    this.setState({
      'pointContainerWidth': pointContainerWidth,
      'pointContainerHeight': pointContainerHeight,
      'volumeContainerHeight': volumeContainerHeight,
      'pointVolumeSeparateLineHeight': pointVolumeSeparateLineHeight,
      'priceAxisYContainerWeight': priceAxisYContainerWeight,
      'rightPriceAxisYContainerWeight': rightPriceAxisYContainerWeight
    })
    this.refreshView()
  }

  refreshView() {
    let { refresh } = this.state
    this.setState({
      'refresh': !refresh
    })
  }
  // 节点被点击
  pointClick(node) {
    // 点击了空白点
    if (node.pfPoint == null) {
      return
    }

    if (this.startNode != null) {
      node.selected = true
      let { pfConfigData, pfData } = this.props
      if (pfConfigData.operateMode == PFOperateMode.MEASURE_UP) {
        pfData.addOneMeasure(this.startNode, node, 1)
      } else if (pfConfigData.operateMode == PFOperateMode.MEASURE_DOWN) {
        pfData.addOneMeasure(this.startNode, node, 0)
      } else if (pfConfigData.operateMode == PFOperateMode.DISTRIBUTION) {
        pfData.addDisModel(this.startNode, node)
      }

      this.startNode = null

      // 恢复空模式
      this.operateModeResetCallBack()

    } else {
      // 一个点都没准备
      this.startNode = node
      this.startNode.selected = true
    }

    this.refreshView()
  }

  operateModeResetCallBack() {
    this.props.pfConfigDataChangedCallBack({ 'operateMode': PFOperateMode.NONE })
  }

  // 容器被点击
  containterClick(event) {
    var rect = this.pointContainer.getBoundingClientRect();
    let x = event.clientX - rect.left; // 鼠标点击位置相对于元素左边界的X坐标  
    let y = event.clientY - rect.top; // 鼠标点击位置相对于元素上边界的Y坐标
    let realX = this.pointContainer.scrollLeft + x
    let realY = this.pointContainer.scrollTop + y


    let { pfConfigData, pfData } = this.props

    // 当前是测量模式
    if (pfConfigData.operateMode == PFOperateMode.MEASURE_UP ||
      pfConfigData.operateMode == PFOperateMode.MEASURE_DOWN ||
      pfConfigData.operateMode == PFOperateMode.DISTRIBUTION
    ) {
      let pointWidth = pfData.pointWidth
      let realJ = parseInt(realX / pointWidth)
      let realI = parseInt(realY / pointWidth)
      let node = pfData.displayNodes[realI][realJ]
      if (node == null) {
        return
      }
      this.pointClick(node)
    }
  }
  // 缩放显示
  scalePointWidthCallBack(plusOrSub) {
    let { pfData } = this.props
    pfData.makeScale(plusOrSub)
    this.handleResize()
  }

  // 已经选择
  hadSelectElementCallBack(selectedElement) {
    this.setState({
      'selectedElement': selectedElement
    })
  }

  // 删除当前选中的measure
  deleteElementCallBack() {
    let { selectedElement } = this.state
    let { pfData } = this.props
    pfData.deleteElement(selectedElement)
    this.setState({
      'selectedElement': null
    })
  }

  render() {
    let {
      pointContainerWidth,
      pointContainerHeight,
      volumeContainerHeight,
      priceAxisYContainerWeight,
      rightPriceAxisYContainerWeight,
      pointVolumeSeparateLineHeight,
      selectedMeasure,
      selectedElement
    } = this.state

    let { pfData } = this.props
    let { mode, operateMode } = this.props.pfConfigData

    // 更新坐标
    const priceTitles = []
    if (pfData != null) {
      for (let i = 0; i < pfData.displayNodes.length; i++) {
        let rowNodes = pfData.displayNodes[i]
        let rowFirstNode = rowNodes[0]
        let percent = pfData.disModel.lineSumVolumePercentMap.get(i)
        let axisYModel = {
          'price': rowFirstNode.price.toString(),
          'percent': percent
        }
        priceTitles.push(axisYModel)
      }
    }
    let yTitleFontSize = 13 * pfData.pointWidth / pfData.basePointWidth
    let disTitleFontSize = 12 * pfData.pointWidth / pfData.basePointWidth
    // 真实的点数图高度
    let pointContentRealHeight = priceTitles.length * pfData.pointWidth
    // 真实的点数图宽度
    let pointContentRealWidth = pfData.displayNodes[0].length * pfData.pointWidth

    // 是否显示编辑
    let isDisplayEdit = false
    if (selectedMeasure != null) {
      isDisplayEdit = true
    }

    if (selectedElement != null) {
      isDisplayEdit = true
    }

    console.log('tag::pointContentRealWidth',pointContentRealWidth)

    return (
      <div className='point_content_points_frame'>

        <div className='point_content_y_points_y' style={{ height: pointContainerHeight }}>
          <div className='price-axis-y-left' style={{ width: priceAxisYContainerWeight, height: pointContentRealHeight }}>
            {
              priceTitles.map((title) => (
                <div className='price_axis_y_item_left' style={{ height: pfData.pointWidth, fontSize: yTitleFontSize }}>{title.price}</div>
              )
              )
            }
          </div>

          <div className='point_container'
            style={{ width: pointContentRealWidth, height: pointContentRealHeight }}
            ref={(scrollableRef) => { this.pointContainer = scrollableRef }}
            onClick={(event) => this.containterClick(event)}
          >
            {/* 最底下的点数图 */}
            <div className='point_content_points_inner'>
              {
                pfData.displayNodes.map((sublist, index) => (
                  <div className='point_content_row' key={index} >
                    {
                      sublist.map((node, subIndex) => (
                        <PFPointView
                          node={node}
                          mode={mode}
                          index={index}
                          subIndex={subIndex}
                          basePointWidth={pfData.basePointWidth}
                          pointWidth={pfData.pointWidth}> </PFPointView>
                      ))
                    }
                  </div>
                ))
              }

            </div>
            <PFPaintResultView
              pfData={pfData}
              operateMode={operateMode}
              selectedMeasure={selectedMeasure}
              realWidth={pointContentRealWidth}
              realHeight={pointContentRealHeight}
              selectedElement={selectedElement}
              hadSelectElementCallBack={(selectedElement) => this.hadSelectElementCallBack(selectedElement)}
              operateModeResetCallBack={() => this.operateModeResetCallBack()}
            ></PFPaintResultView>
          </div>

          {
            // 编辑
            isDisplayEdit ? <PFEditView selectedElement={selectedElement} deleteElementCallBack={() => this.deleteElementCallBack()}></PFEditView> : Empty
          }


          {/* 缩放 */}
          <PFScaleView
            rightMargin={rightPriceAxisYContainerWeight}
            bottomMargin={volumeContainerHeight + pointVolumeSeparateLineHeight + 10}
            scalePointWidthCallBack={(value) => this.scalePointWidthCallBack(value)}
          ></PFScaleView>

          <div className='price-axis-y-right' style={{ width: rightPriceAxisYContainerWeight, height: pointContentRealHeight }} >
            {
              priceTitles.map((title) => (
                <div className='price_axis_y_item_right' style={{ height: pfData.pointWidth, fontSize: yTitleFontSize }}>
                  <div style={{ width: priceAxisYContainerWeight }}>{title.price}</div>
                  <div className='price_axis_y_item_right_dis' style={{ width: pfData.pointWidth, fontSize: disTitleFontSize }}>{title.percent == null ? '' : title.percent}</div>
                  <div className='price_axis_y_item_right_dis' style={{ width: pfData.pointWidth, fontSize: disTitleFontSize }}>{title.percent == null ? '' : '%'}</div>
                </div>
              )
              )
            }
          </div>

        </div>

        <div className='point_content_points_volume_line' style={{ height: pointVolumeSeparateLineHeight }}></div>

        <div className='point_volume_container'>
          <KLineVolumeYTitleView rightPriceAxisYContainerWeight={priceAxisYContainerWeight} isRightY={false} yTitleFontSize={yTitleFontSize}></KLineVolumeYTitleView>
          <PFVolumeView pfData={pfData} volumeViewHeight={volumeContainerHeight} pointContentRealWidth={pointContentRealWidth} setVolumeScrollCallBack={(scrollableRef) => { this.setVolumeScrollCallBack(scrollableRef) }}></PFVolumeView>
          <KLineVolumeYTitleView rightPriceAxisYContainerWeight={rightPriceAxisYContainerWeight} isRightY={true} yTitleFontSize={yTitleFontSize}></KLineVolumeYTitleView>
        </div>
      </div>
    )
  }
}


