import PFOneBaseModel from './PFOneBaseModel'
export default class PFOneDisModel extends PFOneBaseModel {
    // Node节点只是初始值
    constructor(startNode, endNode, displayNodes, pointWidth,defaultAll) {
        super()
        // 调整左右节点
        this.leftNode = startNode
        this.endNode = endNode
        if (startNode.j > endNode.j) {
            this.leftNode = endNode
            this.endNode = startNode
        }

        this.defaultAll = defaultAll
        this.displayNodes = displayNodes
        this.pointWidth = pointWidth
        if (this.pointWidth == null) {
            this.pointWidth = 21
        }
        this.lineHeight = 2

        // 左上角的点
        this.leftTopNode = null
        // 右下角的点
        this.rightBottomNode = null

        // 左上角的点
        this.leftTopPoint = null
        // 右下角的点
        this.rightBottomPoint = null

        this.lineSumVolumePercentMap = []

        // 计算矩形
        this.caculateInitializeRegin()
        // 计算百分比
        this.caculatePercent()
    }
    // makeScale(scalValue) {
    //     this.leftTopPoint.y = this.leftTopPoint.y * (1 + scalValue)
    //     // 调整下边距
    //     this.rightBottomPoint.y = this.rightBottomPoint.y * (1 + scalValue)
    //     // 调整左边距
    //     this.leftTopPoint.x = this.leftTopPoint.x * (1 + scalValue)
    //     // 调整右边距
    //     this.rightBottomPoint.x = this.rightBottomPoint.x * (1 + scalValue)
    //     this.caculatePercent()
    // }
    makeScaleRefresh(pointWidth, basePointWidth){
        let scalValue = pointWidth/ basePointWidth
        this.leftTopPoint.y = this.leftTopPoint.y * (1 + scalValue)
        // 调整下边距
        this.rightBottomPoint.y = this.rightBottomPoint.y * (1 + scalValue)
        // 调整左边距
        this.leftTopPoint.x = this.leftTopPoint.x * (1 + scalValue)
        // 调整右边距
        this.rightBottomPoint.x = this.rightBottomPoint.x * (1 + scalValue)
        this.caculatePercent()
    }
    // 根据初始化节点计算区域
    caculateInitializeRegin() {
        if (this.defaultAll) {
            this.leftTopPoint = { 'x': 0, 'y': 0 }
            let rowCount = this.displayNodes.length
            let colCount = this.displayNodes[0].length
            this.rightBottomPoint = { 'x': rowCount * this.pointWidth, 'y': colCount * this.pointWidth }
            return
        }
        if (this.leftNode.i < this.endNode.i) {
            this.leftTopNode = this.leftNode
            this.rightBottomNode = this.endNode
        } else {
            this.leftTopNode = this.displayNodes[this.endNode.i][this.leftNode.j]
            this.rightBottomNode = this.displayNodes[this.leftNode.i][this.endNode.j]
        }

        this.leftTopPoint = { 'x': this.leftNode.j * this.pointWidth, 'y': this.leftTopNode.i * this.pointWidth }
        this.rightBottomPoint = { 'x': (this.endNode.j + 1) * this.pointWidth, 'y': (this.rightBottomNode.i + 1) * this.pointWidth }
    }

    // 计算区域内的成交量分布
    caculatePercent() {
        let lineSumVolumeMap = new Map()
        let regionSumVolume = 0
        for (let i = 0; i < this.displayNodes.length; i++) {
            let oneRowDisplayNodes = this.displayNodes[i]
            let lineSumVolume = 0
            let hadpf = false
            for (let j = 0; j < oneRowDisplayNodes.length; j++) {
                // 中心点是否在区域内
                let centerPoint = { 'x': j * this.pointWidth + this.pointWidth / 2.0, 'y': i * this.pointWidth + this.pointWidth / 2.0 }
                if (this.isPointInRegin(centerPoint)) {
                    let node = this.displayNodes[i][j]
                    lineSumVolume = lineSumVolume + node.nomalizeVolume
                    hadpf = true
                }
            }
            if (hadpf) {
                lineSumVolumeMap.set(i, lineSumVolume)
            }

            regionSumVolume = regionSumVolume + lineSumVolume
        }

        let lineSumVolumePercentMap = new Map()
        for (let key of lineSumVolumeMap.keys()) {
            let value = lineSumVolumeMap.get(key)
            let percent = (value / regionSumVolume) * 100
            lineSumVolumePercentMap.set(key, percent.toFixed(0))
        }
        this.lineSumVolumePercentMap = lineSumVolumePercentMap
    }

    draw(context, selected) {
        super.draw(context, selected)
        if (this.defaultAll) {
            return
        }
        if (context != null) {
            context.beginPath()
            context.fillStyle = `rgba(181,98,101,0.2)`

            let width = Math.abs(this.leftTopPoint.x - this.rightBottomPoint.x)
            let height = Math.abs(this.leftTopPoint.y - this.rightBottomPoint.y)

            // 计算正在需要绘制的矩形
            let x = this.leftTopPoint.x
            let y = this.leftTopPoint.y
            if (this.leftTopPoint.x > this.rightBottomPoint.x) {
                x = this.rightBottomPoint.x
            }
            if (this.leftTopPoint.y > this.rightBottomPoint.y) {
                y = this.rightBottomPoint.y
            }
            context.fillRect(x, y, width, height)

            if (selected) {
                this.drawOneDot(context, this.leftTopPoint)
                this.drawOneDot(context, this.rightBottomPoint)
            }
        }
    }
    isPointInRegin(point) {
        let x = point.x
        let y = point.y
        // 只要在两点之间
        let xValidOne = (x >= this.leftTopPoint.x) && (x <= this.rightBottomPoint.x)
        let xValidTwo = (x >= this.rightBottomPoint.x) && (x <= this.leftTopPoint.x)
        let xValid = xValidOne || xValidTwo

        // 在两点之间
        let yValidOne = (y >= this.leftTopPoint.y) && (y <= this.rightBottomPoint.y)
        let yValidTwo = (y >= this.rightBottomPoint.y) && (y <= this.leftTopPoint.y)
        let yValid = yValidOne || yValidTwo

        // 在区域内
        let reginValid = xValid && yValid
        if (reginValid) {
            return true
        }
        return false
    }
    // 点击时是否在区域内
    isPointIn(point) {
        if (this.defaultAll) {
            return false
        }
        super.isPointIn(point)

        let x = point.x
        let y = point.y
        // 只要在两点之间
        let xValidOne = (x >= this.leftTopPoint.x) && (x <= this.rightBottomPoint.x)
        let xValidTwo = (x >= this.rightBottomPoint.x) && (x <= this.leftTopPoint.x)
        let xValid = xValidOne || xValidTwo

        // 在两点之间
        let yValidOne = (y >= this.leftTopPoint.y) && (y <= this.rightBottomPoint.y)
        let yValidTwo = (y >= this.rightBottomPoint.y) && (y <= this.leftTopPoint.y)
        let yValid = yValidOne || yValidTwo

        // 在区域内
        let reginValid = xValid && yValid

        // 在左上角的编辑点上
        this.isOnLeftTopPoint = this.isPointOnCircle(this.leftTopPoint, this.selectedCircleWidth, point)
        // 在右下角的编辑点上
        this.isOnBottomPoint = this.isPointOnCircle(this.rightBottomPoint, this.selectedCircleWidth, point)

        if (reginValid || this.isOnStartPoint || this.isOnBottomPoint) {
            return true
        }
        return false
    }
    // 移动
    moveByOffset(diffX, diffY) {
        super.moveByOffset(diffX, diffY)
        console.log('diffX', diffX)
        console.log('diffY', diffY)

        if (this.isOnLeftTopPoint) {
            let leftTopPoint = { 'x': this.leftTopPoint.x + diffX, 'y': this.leftTopPoint.y + diffY }
            this.leftTopPoint = leftTopPoint
        } else if (this.isOnBottomPoint) {
            let rightBottomPoint = { 'x': this.rightBottomPoint.x + diffX, 'y': this.rightBottomPoint.y + diffY }
            this.rightBottomPoint = rightBottomPoint
        } else {
            let leftTopPoint = { 'x': this.leftTopPoint.x + diffX, 'y': this.leftTopPoint.y + diffY }
            let rightBottomPoint = { 'x': this.rightBottomPoint.x + diffX, 'y': this.rightBottomPoint.y + diffY }
            this.leftTopPoint = leftTopPoint
            this.rightBottomPoint = rightBottomPoint
        }
    }
    justFloorOrCeil(target, pointWidth) {
        let intTarget = Math.floor(target / this.pointWidth) * this.pointWidth
        let realTarget = intTarget
        if (target - intTarget >= pointWidth / 2.0) {
            realTarget = intTarget + pointWidth
        }
        return realTarget
    }
    // 调整成指定区域
    adjust() {
        // 调整上边距
        this.leftTopPoint.y = this.justFloorOrCeil(this.leftTopPoint.y, this.pointWidth)
        // 调整下边距
        this.rightBottomPoint.y = this.justFloorOrCeil(this.rightBottomPoint.y, this.pointWidth)
        // 调整左边距
        this.leftTopPoint.x = this.justFloorOrCeil(this.leftTopPoint.x, this.pointWidth)
        // 调整右边距
        this.rightBottomPoint.x = this.justFloorOrCeil(this.rightBottomPoint.x, this.pointWidth)
        this.caculatePercent()
    }
}