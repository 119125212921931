import React, { Component } from 'react'
import './PFVolumeBarView.css'
export default class PointFeatureVolumeBar extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    let { index, colSumNomalizeVolume, maxColSumNomalizeVolume,volumeViewHeight, isUpCol,pointWidth} = this.props
    let barHeight = volumeViewHeight * (colSumNomalizeVolume / maxColSumNomalizeVolume)
    let backgroundColor = isUpCol ? `rgb(181,98,101`:  `rgb(124,164,219)`
    let barWidth = pointWidth - 9
    if (barWidth <= 0) {
      barWidth = 1
    }
    return (
      <div className='PFVolumeBarView' style={{ width: pointWidth}}>
        <div className='PointFeatureVolumeBar' style={{height: barHeight, backgroundColor: backgroundColor, width: barWidth}}></div>
      </div>
    )
  }
}
