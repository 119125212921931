import React, { Component } from 'react'
import './KLinePointView.css'
export default class KLinePointView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.canvasRef.width = this.props.realWidth - 1
    this.canvasRef.height = this.props.realHeight
  }

  componentDidUpdate() {
    // 一定要指定canvas的大小（不能通过css设置大小）
    this.canvasRef.width = this.props.realWidth - 1
    this.canvasRef.height = this.props.realHeight
    this.rePainAll()
  }

  rePainAll() {
    if (this.canvasRef.getContext) {
      let context = this.canvasRef.getContext('2d')
      context.clearRect(0, 0, this.props.realWidth, this.props.realHeight)
      this.props.node.draw(context)
    }
  }

  render() {
    let backgroundColor = (this.props.index % 2) == 0 ? `rgb(181,98,101)` : `rgb(124,164,219)`
    return (
      <div className='KLinePointView' style={{ width: this.props.pointWidth }}>
        <canvas
          ref={(canvasRef) => { this.canvasRef = canvasRef }}>
        </canvas>
      </div>
    )
  }
}
