import logo from './logo.svg';
import './App.css';
// 引入routes的路由方法
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter
} from "react-router-dom";

import routes from './routes';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

function App() {  
  const router = createBrowserRouter(routes)
  dayjs.locale('zh-cn');
  return (
    <div className="App">
       <ConfigProvider locale={zhCN}>
        <RouterProvider router={router} />
       </ConfigProvider>
    </div>
  );
}

export default App;
