import React, { Component } from 'react'
import "./KLineWaveResultView.css"
import PFOperateMode from '../PF/PFOperateMode'
import PFOneLineModel from '../PF/PFOneLineModel'

export default class KLineWaveResultView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'paintStartPoint': null,// 绘制的起始点
            'refresh': false // 是否需要刷新
        }
        this.isMouseDown = false
        this.lastMouseEvent = null
        this.isMouserMove = false
    }

    componentDidMount() {
        // 一定要指定canvas的大小（不能通过css设置大小）
        this.canvasRef.width = this.props.realWidth
        this.canvasRef.height = this.props.realHeight
    }
    componentDidUpdate() {
        // 一定要指定canvas的大小（不能通过css设置大小）
        this.canvasRef.width = this.props.realWidth
        this.canvasRef.height = this.props.realHeight
        this.rePainAll()
    }
    refresh() {
        let { refresh } = this.state
        this.setState({
            'refresh': !refresh
        })
    }
    rePainAll() {
        let { kLineLogic } = this.props
        if (this.canvasRef.getContext) {
            let context = this.canvasRef.getContext('2d')
            // 清除画布
            context.clearRect(0, 0, this.props.realWidth, this.props.realHeight)
            // 绘制波形
            for (let i = 0; i < kLineLogic.displayWaveNodes.length; i++) {
                let waveModel = kLineLogic.displayWaveNodes[i]
                waveModel.draw(context)
            }
            // 绘制线条
            for (let i = 0; i < kLineLogic.paintLineModels.length; i++) {
                let lineModel = kLineLogic.paintLineModels[i]
                lineModel.draw(context, false)
            }
        }
    }
    // 鼠标按下
    handleMouseDown = (event) => {
        this.isMouseDown = true
        this.lastMouseEvent = event

        let eventPoint = this.eventPointInCanvas(event)
        let selectedElement = this.checkSelectElement(eventPoint)
        console.log(`这是即将选中的点${eventPoint.x}, ${eventPoint.y}`)
        console.log("这是即将选中", selectedElement)
        // 1. 如果选中
        if (selectedElement != null) {
            this.props.hadSelectElementCallBack(selectedElement)
            this.props.operateModeResetCallBack()
            return
        }

        // 2. 绘制模式
        let { operateMode } = this.props
        if (operateMode == PFOperateMode.PAINT) {
            this.setState({
                'paintStartPoint': this.eventPointInCanvas(event)
            })
            return
        }

        this.props.hadSelectElementCallBack(null)
    }

    // 鼠标移动
    handleMousemove = (event) => {
        if (this.isMouseDown == false) {
            return
        }

        // 1. 选中了。并开始移动
        let { selectedElement } = this.props
        if (selectedElement != null) {
            let diffX = event.clientX - this.lastMouseEvent.clientX
            let diffY = event.clientY - this.lastMouseEvent.clientY
            selectedElement.moveByOffset(diffX, diffY)

            this.lastMouseEvent = event

            this.refresh()
            return
        }
        // 2. 没有选中，并开始移动
        let { paintStartPoint } = this.state
        let { operateMode } = this.props
        if (paintStartPoint != null && operateMode == PFOperateMode.PAINT) {
            // 是绘制模式
            let endPoint = this.eventPointInCanvas(event)
            let lineModel = new PFOneLineModel(paintStartPoint, endPoint)
            this.drawEditLine(lineModel)
        }
    }
    handleMouseUp = (event) => {
        this.isMouseDown = false
        this.lastMouseEvent = null

        let { selectedElement } = this.props

        // 1. 选中，并且移动了。
        if (selectedElement != null) {
            this.handleMouseUpMovedPain(event)
            this.props.operateModeResetCallBack()
            return
        }

        // 2. 抬起，检测是否选中
        let eventPoint = this.eventPointInCanvas(event)
        let toSelectedElement = this.checkSelectElement(eventPoint)
        if (toSelectedElement != null) {
            this.setState({
                'selectedElement': toSelectedElement
            })
            event.stopPropagation()
            return
        }
        //3. 添加一条绘制线
        this.handleMouseUpPaint(event)
    }
    // 移动一个元素后抬起
    handleMouseUpMovedPain(event) {
        let { selectedElement } = this.props
        selectedElement.adjust()
        this.refresh()
    }
    // 选中
    checkSelectElement(point) {
        // 绘制线条
        let { paintLineModels } = this.props.kLineLogic
        for (let i = 0; i < paintLineModels.length; i++) {
            let lineModel = paintLineModels[i]
            if (lineModel.isPointIn(point)) {
                return lineModel
            }
        }
    }
    // 正常依照模式绘制
    handleMouseUpPaint(event) {
        let { paintStartPoint } = this.state
        let { operateMode } = this.props
        let endPoint = this.eventPointInCanvas(event)
        if (operateMode == PFOperateMode.PAINT) {
            let lineModel = new PFOneLineModel(paintStartPoint, endPoint)
            this.props.kLineLogic.addPaintLine(lineModel)
            this.props.operateModeResetCallBack()
        }
        this.setState({
            'paintStartPoint': null
        })
    }
    // 事件所在的位置
    eventPointInCanvas(event) {
        var rect = this.canvasRef.getBoundingClientRect();
        let x = event.clientX - rect.left; // 鼠标点击位置相对于元素左边界的X坐标  
        let y = event.clientY - rect.top; // 鼠标点击位置相对于元素上边界的Y坐标
        let realX = this.canvasRef.scrollLeft + x
        let realY = this.canvasRef.scrollTop + y
        return { 'x': realX, 'y': realY }
    }
    // 从startPoint绘制到endPoint
    drawEditLine(lineModel) {
        if (this.canvasRef.getContext) {
            let context = this.canvasRef.getContext('2d')
            // 将已经确定好的重新绘制
            this.rePainAll()
            lineModel.draw(context)
        }
    }
    render() {
        return (
            <canvas className='KLineWaveResultView'
                style={{ width: this.props.realWidth, height: this.props.realHeight }}
                ref={(canvasRef) => { this.canvasRef = canvasRef }}
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMousemove}
                onMouseUp={this.handleMouseUp}
            >
            </canvas>
        )
    }
}