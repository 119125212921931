import React, { Component } from 'react'
import './KLineVolumeView.css'
import KLineVolumeBarView from './KLineVolumeBarView'
class KLineVolumeTimeView extends Component {
  constructor(props) {
    super(props)
  }
}

export default class KLineVolumeView extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.setVolumeScrollCallBack(this.volumeContainer)
  }
  render() {
    let { kLineLogic, pointContentRealWidth, volumeViewHeight, setVolumeScrollCallBack } = this.props
    this.setVolumeScrollCallBack = setVolumeScrollCallBack

    let everyTimeWidth = 10 * kLineLogic.pointWidth
    let dateStringArray = []
    for (let i = 0; i < kLineLogic.displayNodes.length; i++) {
      let node = kLineLogic.displayNodes[i]
      if (i % 10 == 0) {
        if (node.kLineModel != null) {
          dateStringArray.push(node)
        }
      }
    }
    return (
      <div className='KLineVolumeTimeContainerView'
        style={{ width: pointContentRealWidth, height: volumeViewHeight }}
        ref={(scrollableRef) => { this.volumeContainer = scrollableRef }}>

        <div className='KLineVolumeView'
          style={{ width: pointContentRealWidth, height: volumeViewHeight - 15 }}
        >
          {
            kLineLogic.displayNodes.map((node, index) => (
              <KLineVolumeBarView
                index={index}
                node={node}
                volumeBarViewHeight={volumeViewHeight - 15}
                pointWidth={kLineLogic.pointWidth}
                isWaveVolume={kLineLogic.isWaveVolume}
              > </KLineVolumeBarView>
            ))
          }
        </div>
        {/* 每隔10个标记 */}
        <div className='KLineTimeView' style={{ width: pointContentRealWidth }}>
          {
            dateStringArray.map((node, index) => (
              <div>
                <div className='KLineTimeItemView' style={{ left: node.index * kLineLogic.pointWidth, width: everyTimeWidth }}> {node.tradeTimeString()} </div>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}

