import React, { Component } from 'react'
import './PFScaleView.css'
import { PlusCircleOutlined, MinusCircleOutlined,SyncOutlined} from '@ant-design/icons';
import { Space } from 'antd'

export default class PFScaleView extends Component {
    render() {
        let { rightMargin, bottomMargin } = this.props
        return (
            <div className='PFScaleView' style={{ right: rightMargin, bottom: bottomMargin }}>
                <Space size={'small'}>
                    <PlusCircleOutlined onClick={() => this.props.scalePointWidthCallBack(1)}/>
                    <MinusCircleOutlined onClick={() => this.props.scalePointWidthCallBack(-1)}/>
                    <SyncOutlined onClick={() => this.props.scalePointWidthCallBack(0)}/>
                </Space>
            </div>
        )
    }
}
