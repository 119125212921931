import KlinePoint from "./KLineModel"
import PFPoint from "./PFPoint"

import PFAlgorithm from './PFAlgorithm'
import PFNode from './PFNode'
import PFOneMeasure from "./PFOneMeasure"
import PFOneDisModel from './PFOneDisModel'
// this.kLineModels = [
//    new KlinePoint(0, 0, 0, 37, 37),
//    new KlinePoint(0, 0, 0, 36, 36),
//    new KlinePoint(0, 0, 0, 37, 37),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 36, 36),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 34, 34),
//    new KlinePoint(0, 0, 0, 40, 40),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 36, 36),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 36, 36),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 36, 36),
//    new KlinePoint(0, 0, 0, 35, 35),
//    new KlinePoint(0, 0, 0, 40, 40)
// ]
// test value
// gridVlue = 0.1
// kLineModels = [
//    new KlinePoint(0, 0, 0, 0.79, 0.71,200),
//    new KlinePoint(0, 0, 0, 0.8, 0.75,100),
//    new KlinePoint(0, 0, 0, 0.9, 0.8,500),
//    new KlinePoint(0, 0, 0, 0.95, 0.85,600),
//    new KlinePoint(0, 0, 0, 0.99, 0.90,700),
//    new KlinePoint(0, 0, 0, 0.85, 0.80,600),
//    new KlinePoint(0, 0, 0, 0.75, 0.70,200),
//    new KlinePoint(0, 0, 0, 0.72, 0.71,300),
//    new KlinePoint(0, 0, 0, 0.65, 0.69,100),
//    new KlinePoint(0, 0, 0, 0.62, 0.59,200),
//    new KlinePoint(0, 0, 0, 0.7, 0.69,100),
//    new KlinePoint(0, 0, 0, 0.72, 0.71,200),
//    new KlinePoint(0, 0, 0, 0.75, 0.6,800)
// ]
export default class PFDataLogic {
   constructor(kLineModels, gridVlue, revertValue) {
      this.kLineModels = kLineModels

      this.gridVlue = gridVlue
      this.revertValue = revertValue

      this.resetData()
   }

   // 重置数据
   resetData() {
      // 点数图逻辑计算
      if (this.kLineModels.length > 0) {
         this.pfAlgorithm = new PFAlgorithm(this.kLineModels, this.gridVlue, this.revertValue)
      }

      // 展示的点
      this.displayNodes = []
      // 目标计算
      this.measureTargets = []
      // 所有的绘制线
      this.paintLineModels = []
      // 分布model
      this.disModel = null
      // 点的宽度
      this.pointWidth = 21
      this.basePointWidth =  21
      // 列的成交量最大值
      this.maxColSumNomalizeVolume = 0

      this.visibleWidth = 200
      this.visibleHeight = 200
      // 显示的点计算
      this.caculatePFPointDisplay(this.visibleWidth, this.visibleHeight)
   }
   // 刷新数据
   refreshPFPointDisplay(gridVlue, revertValue) {
      this.gridVlue = gridVlue
      this.revertValue = revertValue
      this.resetData()
   }
   // 计算显示
   caculatePFPointDisplay(visibleWidth, visibleHeight) {
      this.displayNodes = []
      this.visibleWidth = visibleWidth
      this.visibleHeight = visibleHeight

      let pfAlgorithm = this.pfAlgorithm

      const pfGridValue = pfAlgorithm.pfGridValue
      // 点数图的有效行数
      const rowCount = Math.ceil((pfAlgorithm.maxPFPrice - pfAlgorithm.minPFPrice) / pfAlgorithm.pfGridValue)
      // 点树图的有效列数
      const colCount = Object.keys(pfAlgorithm.colToPoints).length

      const maxVolume = pfAlgorithm.maxVolume

      // 显示的列数（默认向右空出2个点）
      let colCountDisplay = colCount + 2
      // 可见宽度
      let visibleColCount = Math.ceil(visibleWidth / this.pointWidth)
      
      // 显示宽度 < 可见宽度
      if (colCountDisplay < visibleColCount) {
         colCountDisplay = visibleColCount
      }

      // 显示的所有行数（默认上下空出两个点）
      let rowCountDisplay = rowCount + 2 + 2
      let visibleRowCount = Math.ceil(visibleHeight / this.pointWidth)
      if (rowCountDisplay < visibleRowCount) {
         rowCountDisplay = visibleRowCount
      }

      const rowOffset = 2 // 2个点的偏移
      for (let i = 0; i < rowCountDisplay; i++) {
         let oneRow = []
         // 某一行的价格         
         let price = this.floatDot3(pfAlgorithm.maxPFPrice - (i - rowOffset) * pfGridValue)

         for (let j = 0; j < colCountDisplay; j++) {
            // 空白点
            if (j >= colCount) {
               let node = new PFNode(i, j, price, null)
               oneRow.push(node)
               continue
            }
            // 某一列的pfPoint
            let colPFPoints = pfAlgorithm.colToPoints[j]

            let onePFPoint = colPFPoints[price]

            // 将pfPoint绑定到node上
            let node = new PFNode(i, j, price, onePFPoint)
            if (onePFPoint != null && maxVolume != 0) {
               let nomalizeVolume = Math.floor((onePFPoint.volume / maxVolume) * 100)
               node.changeNomalizeValue(nomalizeVolume)
            }

            oneRow.push(node)
         }

         this.displayNodes.push(oneRow)
      }
      this.maxPFPrice = pfAlgorithm.maxPFPrice
      this.minPFPrice = pfAlgorithm.maxPFPrice

      // 某一列的标准化成交量和保存在所在列的最后一个节点上
      for (let j = 0; j < colCountDisplay; j++) {

         let colSumNomalizeVolume = 0
         for (let i = 0; i < rowCountDisplay; i++) {
            let node = this.displayNodes[i][j]
            colSumNomalizeVolume += node.nomalizeVolume
            node.colSumNomalizeVolume = colSumNomalizeVolume
         }
         if (colSumNomalizeVolume > this.maxColSumNomalizeVolume) {
            this.maxColSumNomalizeVolume = colSumNomalizeVolume
         }


         let maxIputIndex = -1
         // 计算当前列是上升列还是下降列
         let isUpCol = false
         for (let i = 0; i < rowCountDisplay; i++) {
            let node = this.displayNodes[i][j]
            // 同一列的最后一个加入的节点，决定了此列是上升列还是下降列
            if (node.pfPoint != null) {
               if (maxIputIndex < node.pfPoint.putIndex) {
                  maxIputIndex = node.pfPoint.putIndex
                  if (node.pfPoint.type == 1) {
                     isUpCol = true
                  } else {
                     isUpCol = false
                  }
               }
            }
         }
         // 将值保存在最后当前列的最后一个节点上
         let node = this.displayNodes[rowCountDisplay - 1][j]
         node.isUpCol = isUpCol
      }
      // 显示横向分布
      this.resetDefaultDisModel()
   }
   // 添加一个测量
   addOneMeasure(startNode, endNode, direction) {
      let measure = new PFOneMeasure(startNode, endNode, direction, this.displayNodes)
      measure.pointWidth = this.pointWidth
      measure.caculateTarget()
      measure.displayTarget()
      this.measureTargets.push(measure)
   }

   // 新增一条绘制的线
   addPaintLine(lineModel) {
      lineModel.pointWidth = this.pointWidth
      this.paintLineModels.push(lineModel)
   }
   // 新增一个分布
   addDisModel(startNode, endNode) {
      let disModel = new PFOneDisModel(startNode, endNode, this.displayNodes, this.pointWidth, false)
      this.disModel = disModel
   }
   // 重置分布
   resetDefaultDisModel() {
      let startNode = this.displayNodes[0][0]
      let endNode = this.displayNodes[this.displayNodes.length - 1][this.displayNodes[0].length - 1]
      let disModel = new PFOneDisModel(startNode, endNode, this.displayNodes, this.pointWidth, true)
      console.log("这是disModel", disModel)
      this.disModel = disModel
   }
   // 删除一个元素
   deleteElement(selectedElement) {
      // 判定删除的是否是测量
      let measureTargets = this.measureTargets
      let measureSelectIndex = measureTargets.indexOf(selectedElement)
      if (measureSelectIndex != -1) {
         measureTargets.splice(measureSelectIndex, 1)
      }

      // 判定是否是dis
      if (this.disModel === selectedElement) {
         this.resetDefaultDisModel()
      }

      // 判定删除的是否是线条
      let paintLineModels = this.paintLineModels
      let lineSelectIndex = paintLineModels.indexOf(selectedElement)
      if (lineSelectIndex != - 1) {
         paintLineModels.splice(lineSelectIndex, 1)
      }
   }
   // 进行缩放
   makeScale(plusOrSub) {
      if (plusOrSub > 0) {
         this.pointWidth = this.pointWidth + 3
      } else if (plusOrSub == 0) {
         this.pointWidth = this.basePointWidth
      } else {
         this.pointWidth = this.pointWidth - 3
      }
      this.makeScaleMeasures()
      this.makeScaleDis()
      this.makeScalePainLines()
   }
   
   makeScaleMeasures() {
      for (let i = 0; i < this.measureTargets.length; i++) {
         let oneMeasure = this.measureTargets[i]
         oneMeasure.pointWidth = this.pointWidth
         oneMeasure.caculateTarget()
         oneMeasure.displayTarget()
      }
   }
   // 重新加载Dis
   makeScaleDis() {
      this.disModel.pointWidth = this.pointWidth
      if (this.disModel != null) {
         this.disModel.makeScaleRefresh(this.pointWidth, this.basePointWidth)
      }
   }
   // 重新加载painLines
   makeScalePainLines() {
      for (let i = 0; i < this.paintLineModels.length; i++) {
         let lineModel = this.paintLineModels[i]
         lineModel.pointWidth = this.pointWidth
         lineModel.refresh(this.pointWidth, this.basePointWidth)         
      }
   }
   floatDot3(value) {
      return parseFloat(value.toFixed(3))
   }
}