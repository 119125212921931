export default class PFNode {
    constructor(i, j, price, pfPoint) {
        this.i = i
        this.j = j
        this.price = price
        this.selected = false
        // 当前点的标准化成交量
        this.nomalizeVolume = 0
        // 所在列的标准化成交量总和
        this.colSumNomalizeVolume = 0
        // 显示出来的
        this.displayNomalizeVolume = ' '
        // 所在列的是上升列还是下降列
        this.isUpCol = false

        this.pfPoint = pfPoint
       
        this.displayOX = ''

        if (pfPoint != undefined && pfPoint != null) {
            if (pfPoint.type == 0) {
                this.displayOX = "O"
            } else {
                this.displayOX = "X"
            }
        }
    }
     changeNomalizeValue(value) {
        this.nomalizeVolume = value
        this.displayNomalizeVolume = String(value)
    }
}