import React, { Component } from 'react'
import './PFEmptyView.css'
import { Image } from 'antd';
import main_market_period_image from '../assets/main_market_period.png'
export default class PFEmptyView extends Component {
  render() {
    return (
      <div className='PFEmptyView'>
        <div>


         
          <h2>使用说明：</h2>
          <div>
            1.请输入代码<br />
            2.选择周期<br />
            3.选择开始时间，结束时间默认今天<br />
            4.点数图格值，转向值，可以不设置，系统自动推荐<br />
            5.波形图基点，可以不设置，系统自动推荐<br />
            6.当代码，周期，时间，发生变化时，会自动重新下载数据。
          </div>
          <h2>
            市场及周期支持
          </h2>
          <Image
            width={800}
            src={main_market_period_image}
          />
        </div>


      </div>
    )
  }
}
