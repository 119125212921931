export const stock_symbol_us = [
    {
        "序号": 1,
        "name": "Jet.AI Inc Wt",
        "最新价": 0.65,
        "涨跌额": 0.59,
        "涨跌幅": 983.33,
        "开盘价": 0.35,
        "最高价": 2.05,
        "最低价": 0.34,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 312138.0,
        "成交额": 340329.0,
        "振幅": 2850.0,
        "换手率": null,
        "symbol": "105.JTAIZ"
    },
    {
        "序号": 2,
        "name": "Healthcare AI Acquisition Corp ",
        "最新价": 0.05,
        "涨跌额": 0.03,
        "涨跌幅": 150.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 103.0,
        "成交额": 5.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HAIAW"
    },
    {
        "序号": 3,
        "name": "Custom Truck One Source Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.03,
        "涨跌幅": 150.0,
        "开盘价": 0.02,
        "最高价": 0.05,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2135.0,
        "成交额": 49.0,
        "振幅": 150.0,
        "换手率": null,
        "symbol": "106.CTOS_WS"
    },
    {
        "序号": 4,
        "name": "VerifyMe Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.02,
        "涨跌幅": 100.0,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 3516.0,
        "成交额": 133.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.VRMEW"
    },
    {
        "序号": 5,
        "name": "Spectaire Holdings Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 108745.0,
        "成交额": 1903.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SPECW"
    },
    {
        "序号": 6,
        "name": "Sonder Holdings Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.01,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 8271.0,
        "成交额": 113.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "105.SONDW"
    },
    {
        "序号": 7,
        "name": "Maquia Capital Acquisition Corp",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MAQCW"
    },
    {
        "序号": 8,
        "name": "MicroCloud Hologram Inc Wt",
        "最新价": 0.08,
        "涨跌额": 0.04,
        "涨跌幅": 100.0,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.08,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 8.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HOLOW"
    },
    {
        "序号": 9,
        "name": "Marblegate Acquisition Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 229000.0,
        "成交额": 3778.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GATEW"
    },
    {
        "序号": 10,
        "name": "BurTech Acquisition Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.01,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 106269.0,
        "成交额": 1382.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "105.BRKHW"
    },
    {
        "序号": 11,
        "name": "AERWINS Technologies Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.01,
        "涨跌幅": 100.0,
        "开盘价": 0.01,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 8402.0,
        "成交额": 110.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "105.AWINW"
    },
    {
        "序号": 12,
        "name": "AlphaTime Acquisition Corp Wt",
        "最新价": 0.04,
        "涨跌额": 0.02,
        "涨跌幅": 100.0,
        "开盘价": 0.02,
        "最高价": 0.04,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 13510.0,
        "成交额": 393.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "105.ATMCW"
    },
    {
        "序号": 13,
        "name": "Global Star Acquisition Inc Rt",
        "最新价": 0.15,
        "涨跌额": 0.07,
        "涨跌幅": 87.5,
        "开盘价": 0.13,
        "最高价": 0.15,
        "最低价": 0.13,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 14203.0,
        "成交额": 2080.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.GLSTR"
    },
    {
        "序号": 14,
        "name": "MBIA Inc",
        "最新价": 13.42,
        "涨跌额": 6.04,
        "涨跌幅": 81.84,
        "开盘价": 12.51,
        "最高价": 13.51,
        "最低价": 11.77,
        "昨收价": 7.38,
        "总市值": 686292050.0,
        "市盈率": -1.69,
        "成交量": 11521081.0,
        "成交额": 147082266.0,
        "振幅": 23.58,
        "换手率": 22.53,
        "symbol": "106.MBI"
    },
    {
        "序号": 15,
        "name": "Battery Future Acquisition Corp",
        "最新价": 0.07,
        "涨跌额": 0.03,
        "涨跌幅": 75.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.05,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 80600.0,
        "成交额": 6779.0,
        "振幅": 125.0,
        "换手率": null,
        "symbol": "106.BFAC_WS"
    },
    {
        "序号": 16,
        "name": "Intensity Therapeutics Inc",
        "最新价": 6.88,
        "涨跌额": 2.88,
        "涨跌幅": 72.0,
        "开盘价": 8.61,
        "最高价": 11.44,
        "最低价": 6.26,
        "昨收价": 4.0,
        "总市值": 94320514.0,
        "市盈率": -10.63,
        "成交量": 31873804.0,
        "成交额": 298650192.0,
        "振幅": 129.5,
        "换手率": 232.5,
        "symbol": "105.INTS"
    },
    {
        "序号": 17,
        "name": "Volato Group Inc Wt",
        "最新价": 0.12,
        "涨跌额": 0.05,
        "涨跌幅": 71.43,
        "开盘价": 0.09,
        "最高价": 0.13,
        "最低价": 0.08,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 186333.0,
        "成交额": 17669.0,
        "振幅": 71.43,
        "换手率": null,
        "symbol": "107.SOAR_WS"
    },
    {
        "序号": 18,
        "name": "SportsMap Tech Acquisition Corp",
        "最新价": 0.05,
        "涨跌额": 0.02,
        "涨跌幅": 66.67,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 200.0,
        "成交额": 10.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.SMAPW"
    },
    {
        "序号": 19,
        "name": "RCF Acquisition Corp Wt",
        "最新价": 0.1,
        "涨跌额": 0.04,
        "涨跌幅": 66.67,
        "开盘价": 0.06,
        "最高价": 0.1,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 8280.0,
        "成交额": 718.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "106.RCFA_WS"
    },
    {
        "序号": 20,
        "name": "Patria Latin American Opportuni",
        "最新价": 0.05,
        "涨跌额": 0.02,
        "涨跌幅": 66.67,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 61416.0,
        "成交额": 2764.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PLAOW"
    },
    {
        "序号": 21,
        "name": "Hyzon Motors Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.02,
        "涨跌幅": 66.67,
        "开盘价": 0.03,
        "最高价": 0.05,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 22304.0,
        "成交额": 812.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.HYZNW"
    },
    {
        "序号": 22,
        "name": "BuzzFeed Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.02,
        "涨跌幅": 66.67,
        "开盘价": 0.03,
        "最高价": 0.05,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 75420.0,
        "成交额": 2829.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.BZFDW"
    },
    {
        "序号": 23,
        "name": "Portage Fintech Acquisition Cor",
        "最新价": 0.11,
        "涨跌额": 0.04,
        "涨跌幅": 57.14,
        "开盘价": 0.08,
        "最高价": 0.11,
        "最低价": 0.08,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 16159.0,
        "成交额": 1711.0,
        "振幅": 42.86,
        "换手率": null,
        "symbol": "105.PFTAW"
    },
    {
        "序号": 24,
        "name": "Presidio Property Trust Inc Wt",
        "最新价": 0.06,
        "涨跌额": 0.02,
        "涨跌幅": 50.0,
        "开盘价": 0.04,
        "最高价": 0.06,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 57255.0,
        "成交额": 2633.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.SQFTW"
    },
    {
        "序号": 25,
        "name": "Social Leverage Acquisition Cor",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 37.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.SLACW"
    },
    {
        "序号": 26,
        "name": "Southport Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.01,
        "最高价": 0.03,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 155553.0,
        "成交额": 1565.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "106.PORT_WS"
    },
    {
        "序号": 27,
        "name": "Evergreen Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 141646.0,
        "成交额": 4249.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EVGRW"
    },
    {
        "序号": 28,
        "name": "ESGEN Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 300.0,
        "成交额": 8.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ESACW"
    },
    {
        "序号": 29,
        "name": "亿咖通科技(权证)",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1006.0,
        "成交额": 28.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ECXWW"
    },
    {
        "序号": 30,
        "name": "dMY Squared Technology Group In",
        "最新价": 0.15,
        "涨跌额": 0.05,
        "涨跌幅": 50.0,
        "开盘价": 0.08,
        "最高价": 0.18,
        "最低价": 0.08,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 4154.0,
        "成交额": 525.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "107.DMYY_WS"
    },
    {
        "序号": 31,
        "name": "Blockchain Coinvestors Acquisit",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 513.0,
        "成交额": 14.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BCSAW"
    },
    {
        "序号": 32,
        "name": "Auddia Inc Series A Wt",
        "最新价": 0.03,
        "涨跌额": 0.01,
        "涨跌幅": 50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 294.0,
        "成交额": 9.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AUUDW"
    },
    {
        "序号": 33,
        "name": "Conduit Pharmaceuticals Inc",
        "最新价": 5.15,
        "涨跌额": 1.61,
        "涨跌幅": 45.48,
        "开盘价": 3.65,
        "最高价": 6.0,
        "最低价": 3.42,
        "昨收价": 3.54,
        "总市值": 380222110.0,
        "市盈率": -3826.67,
        "成交量": 1558963.0,
        "成交额": 7954967.0,
        "振幅": 72.88,
        "换手率": 2.11,
        "symbol": "105.CDT"
    },
    {
        "序号": 34,
        "name": "BYTE Acquisition Corp Wt",
        "最新价": 0.1,
        "涨跌额": 0.03,
        "涨跌幅": 42.86,
        "开盘价": 0.05,
        "最高价": 0.1,
        "最低价": 0.05,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 44131.0,
        "成交额": 4120.0,
        "振幅": 71.43,
        "换手率": null,
        "symbol": "105.BYTSW"
    },
    {
        "序号": 35,
        "name": "Worksport Ltd Wt",
        "最新价": 0.14,
        "涨跌额": 0.04,
        "涨跌幅": 40.0,
        "开盘价": 0.16,
        "最高价": 0.18,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 7858.0,
        "成交额": 1123.0,
        "振幅": 80.0,
        "换手率": null,
        "symbol": "105.WKSPW"
    },
    {
        "序号": 36,
        "name": "GeoVax Labs Inc Wt",
        "最新价": 0.07,
        "涨跌额": 0.02,
        "涨跌幅": 40.0,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.07,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2200.0,
        "成交额": 153.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GOVXW"
    },
    {
        "序号": 37,
        "name": "Dune Acquisition Corp Wt",
        "最新价": 0.07,
        "涨跌额": 0.02,
        "涨跌幅": 40.0,
        "开盘价": 0.05,
        "最高价": 0.09,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 27219.0,
        "成交额": 1584.0,
        "振幅": 80.0,
        "换手率": null,
        "symbol": "105.DUNEW"
    },
    {
        "序号": 38,
        "name": "Arrowroot Acquisition Corp Wt",
        "最新价": 0.07,
        "涨跌额": 0.02,
        "涨跌幅": 40.0,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 57.0,
        "振幅": 60.0,
        "换手率": null,
        "symbol": "105.ARRWW"
    },
    {
        "序号": 39,
        "name": "Sky Harbour Group Corp Wt",
        "最新价": 1.0,
        "涨跌额": 0.28,
        "涨跌幅": 38.89,
        "开盘价": 0.89,
        "最高价": 1.0,
        "最低价": 0.81,
        "昨收价": 0.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 69489.0,
        "成交额": 67595.0,
        "振幅": 26.39,
        "换手率": null,
        "symbol": "107.SKYH_WS"
    },
    {
        "序号": 40,
        "name": "Loop Media Inc",
        "最新价": 1.14,
        "涨跌额": 0.31,
        "涨跌幅": 37.35,
        "开盘价": 0.79,
        "最高价": 1.24,
        "最低价": 0.78,
        "昨收价": 0.83,
        "总市值": 67685290.0,
        "市盈率": -1.8,
        "成交量": 633789.0,
        "成交额": 689302.0,
        "振幅": 55.42,
        "换手率": 1.07,
        "symbol": "107.LPTV"
    },
    {
        "序号": 41,
        "name": "Assure Holdings Corp",
        "最新价": 0.35,
        "涨跌额": 0.09,
        "涨跌幅": 34.62,
        "开盘价": 0.27,
        "最高价": 0.37,
        "最低价": 0.26,
        "昨收价": 0.26,
        "总市值": 2352161.0,
        "市盈率": -0.07,
        "成交量": 2712503.0,
        "成交额": 881438.0,
        "振幅": 42.31,
        "换手率": 40.36,
        "symbol": "105.IONM"
    },
    {
        "序号": 42,
        "name": "Zalatoris II Acquisition Corp W",
        "最新价": 0.08,
        "涨跌额": 0.02,
        "涨跌幅": 33.33,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 23324.0,
        "成交额": 2297.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.ZLSWW"
    },
    {
        "序号": 43,
        "name": "Revelstone Capital Acquisition ",
        "最新价": 0.04,
        "涨跌额": 0.01,
        "涨跌幅": 33.33,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 33950.0,
        "成交额": 1186.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.RCACW"
    },
    {
        "序号": 44,
        "name": "Pono Capital Three Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.01,
        "涨跌幅": 33.33,
        "开盘价": 0.03,
        "最高价": 0.05,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 10301.0,
        "成交额": 422.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.PTHRW"
    },
    {
        "序号": 45,
        "name": "Paysafe Ltd Wt",
        "最新价": 0.04,
        "涨跌额": 0.01,
        "涨跌幅": 33.33,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 57002.0,
        "成交额": 1707.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "106.PSFE_WS"
    },
    {
        "序号": 46,
        "name": "Mobilicom Ltd ADR Wt",
        "最新价": 0.28,
        "涨跌额": 0.07,
        "涨跌幅": 33.33,
        "开盘价": 0.28,
        "最高价": 0.28,
        "最低价": 0.28,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 277.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MOBBW"
    },
    {
        "序号": 47,
        "name": "ClimateRock Rt",
        "最新价": 0.08,
        "涨跌额": 0.02,
        "涨跌幅": 33.33,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.08,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 394.0,
        "成交额": 31.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLRCR"
    },
    {
        "序号": 48,
        "name": "ALX Oncology Holdings Inc",
        "最新价": 12.26,
        "涨跌额": 3.01,
        "涨跌幅": 32.54,
        "开盘价": 10.22,
        "最高价": 13.5,
        "最低价": 10.15,
        "昨收价": 9.25,
        "总市值": 610778635.0,
        "市盈率": -4.18,
        "成交量": 3850931.0,
        "成交额": 47880119.0,
        "振幅": 36.22,
        "换手率": 7.73,
        "symbol": "105.ALXO"
    },
    {
        "序号": 49,
        "name": "Global Lights Acquisition Corp ",
        "最新价": 0.25,
        "涨跌额": 0.06,
        "涨跌幅": 31.58,
        "开盘价": 0.2,
        "最高价": 0.26,
        "最低价": 0.2,
        "昨收价": 0.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 12750.0,
        "成交额": 2668.0,
        "振幅": 31.58,
        "换手率": null,
        "symbol": "105.GLACR"
    },
    {
        "序号": 50,
        "name": "Exicure Inc",
        "最新价": 0.52,
        "涨跌额": 0.12,
        "涨跌幅": 30.0,
        "开盘价": 0.62,
        "最高价": 0.66,
        "最低价": 0.47,
        "昨收价": 0.4,
        "总市值": 4497120.0,
        "市盈率": 1.47,
        "成交量": 24941156.0,
        "成交额": 13996492.0,
        "振幅": 47.5,
        "换手率": 288.39,
        "symbol": "105.XCUR"
    },
    {
        "序号": 51,
        "name": "Azitra Inc",
        "最新价": 1.26,
        "涨跌额": 0.28,
        "涨跌幅": 28.57,
        "开盘价": 1.02,
        "最高价": 1.27,
        "最低价": 1.02,
        "昨收价": 0.98,
        "总市值": 15243030.0,
        "市盈率": -1.18,
        "成交量": 29270.0,
        "成交额": 33702.0,
        "振幅": 25.51,
        "换手率": 0.24,
        "symbol": "107.AZTR"
    },
    {
        "序号": 52,
        "name": "Altisource Asset Management Cor",
        "最新价": 6.65,
        "涨跌额": 1.44,
        "涨跌幅": 27.64,
        "开盘价": 5.29,
        "最高价": 6.8,
        "最低价": 4.86,
        "昨收价": 5.21,
        "总市值": 18381491.0,
        "市盈率": -0.75,
        "成交量": 513077.0,
        "成交额": 3098738.0,
        "振幅": 37.24,
        "换手率": 18.56,
        "symbol": "107.AAMC"
    },
    {
        "序号": 53,
        "name": "EF Hutton Acquisition Corp I",
        "最新价": 9.1,
        "涨跌额": 1.92,
        "涨跌幅": 26.74,
        "开盘价": 7.05,
        "最高价": 17.0,
        "最低价": 7.05,
        "昨收价": 7.18,
        "总市值": 60288838.0,
        "市盈率": 1054.26,
        "成交量": 993412.0,
        "成交额": 12932270.0,
        "振幅": 138.58,
        "换手率": 14.99,
        "symbol": "105.EFHT"
    },
    {
        "序号": 54,
        "name": "Inflection Point Acquisition Co",
        "最新价": 0.19,
        "涨跌额": 0.04,
        "涨跌幅": 26.67,
        "开盘价": 0.18,
        "最高价": 0.2,
        "最低价": 0.18,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 7731.0,
        "成交额": 1422.0,
        "振幅": 13.33,
        "换手率": null,
        "symbol": "105.IPXXW"
    },
    {
        "序号": 55,
        "name": "Sizzle Acquisition Corp Wt",
        "最新价": 0.1,
        "涨跌额": 0.02,
        "涨跌幅": 25.0,
        "开盘价": 0.08,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 3160.0,
        "成交额": 264.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.SZZLW"
    },
    {
        "序号": 56,
        "name": "Investcorp India Acquisition Co",
        "最新价": 0.05,
        "涨跌额": 0.01,
        "涨跌幅": 25.0,
        "开盘价": 0.04,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 158345.0,
        "成交额": 6335.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.IVCAW"
    },
    {
        "序号": 57,
        "name": "Aetherium Acquisition Corp Wt",
        "最新价": 0.05,
        "涨跌额": 0.01,
        "涨跌幅": 25.0,
        "开盘价": 0.04,
        "最高价": 0.05,
        "最低价": 0.03,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 33443.0,
        "成交额": 1255.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.GMFIW"
    },
    {
        "序号": 58,
        "name": "AIB Acquisition Corp Rt",
        "最新价": 0.15,
        "涨跌额": 0.03,
        "涨跌幅": 25.0,
        "开盘价": 0.09,
        "最高价": 0.15,
        "最低价": 0.09,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 3582.0,
        "成交额": 444.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.AIBBR"
    },
    {
        "序号": 59,
        "name": "Volato Group Inc-A",
        "最新价": 6.98,
        "涨跌额": 1.33,
        "涨跌幅": 23.54,
        "开盘价": 7.52,
        "最高价": 8.0,
        "最低价": 6.08,
        "昨收价": 5.65,
        "总市值": 195743274.0,
        "市盈率": 55.89,
        "成交量": 3298029.0,
        "成交额": 23244123.0,
        "振幅": 33.98,
        "换手率": 11.76,
        "symbol": "107.SOAR"
    },
    {
        "序号": 60,
        "name": "Nogin Inc",
        "最新价": 0.16,
        "涨跌额": 0.03,
        "涨跌幅": 23.08,
        "开盘价": 0.13,
        "最高价": 0.17,
        "最低价": 0.12,
        "昨收价": 0.13,
        "总市值": 1806120.0,
        "市盈率": -0.05,
        "成交量": 1434393.0,
        "成交额": 208338.0,
        "振幅": 38.46,
        "换手率": 12.71,
        "symbol": "105.NOGN"
    },
    {
        "序号": 61,
        "name": "Oncternal Therapeutics Inc",
        "最新价": 0.43,
        "涨跌额": 0.08,
        "涨跌幅": 22.86,
        "开盘价": 0.35,
        "最高价": 0.45,
        "最低价": 0.35,
        "昨收价": 0.35,
        "总市值": 25356628.0,
        "市盈率": -0.61,
        "成交量": 414629.0,
        "成交额": 167305.0,
        "振幅": 28.57,
        "换手率": 0.7,
        "symbol": "105.ONCT"
    },
    {
        "序号": 62,
        "name": "天美生物",
        "最新价": 0.61,
        "涨跌额": 0.11,
        "涨跌幅": 22.0,
        "开盘价": 0.52,
        "最高价": 0.66,
        "最低价": 0.48,
        "昨收价": 0.5,
        "总市值": 6799198.0,
        "市盈率": 1.17,
        "成交量": 388462.0,
        "成交额": 233774.0,
        "振幅": 36.0,
        "换手率": 3.49,
        "symbol": "105.BON"
    },
    {
        "序号": 63,
        "name": "Virco Mfg Corp",
        "最新价": 8.51,
        "涨跌额": 1.5,
        "涨跌幅": 21.4,
        "开盘价": 7.99,
        "最高价": 8.75,
        "最低价": 7.73,
        "昨收价": 7.01,
        "总市值": 139115642.0,
        "市盈率": 5.34,
        "成交量": 675086.0,
        "成交额": 5587919.0,
        "振幅": 14.55,
        "换手率": 4.13,
        "symbol": "105.VIRC"
    },
    {
        "序号": 64,
        "name": "Intuitive Machines Inc Wt",
        "最新价": 0.23,
        "涨跌额": 0.04,
        "涨跌幅": 21.05,
        "开盘价": 0.21,
        "最高价": 0.23,
        "最低价": 0.2,
        "昨收价": 0.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 25449.0,
        "成交额": 5399.0,
        "振幅": 15.79,
        "换手率": null,
        "symbol": "105.LUNRW"
    },
    {
        "序号": 65,
        "name": "Bitfarms Ltd",
        "最新价": 2.59,
        "涨跌额": 0.45,
        "涨跌幅": 21.03,
        "开盘价": 2.17,
        "最高价": 2.62,
        "最低价": 2.16,
        "昨收价": 2.14,
        "总市值": 719201560.0,
        "市盈率": -11.32,
        "成交量": 55491170.0,
        "成交额": 136565983.0,
        "振幅": 21.5,
        "换手率": 19.98,
        "symbol": "105.BITF"
    },
    {
        "序号": 66,
        "name": "Rani Therapeutics Holdings Inc-",
        "最新价": 3.14,
        "涨跌额": 0.53,
        "涨跌幅": 20.31,
        "开盘价": 2.75,
        "最高价": 3.63,
        "最低价": 2.63,
        "昨收价": 2.61,
        "总市值": 156989035.0,
        "市盈率": -4.4,
        "成交量": 513974.0,
        "成交额": 1656655.0,
        "振幅": 38.31,
        "换手率": 1.03,
        "symbol": "105.RANI"
    },
    {
        "序号": 67,
        "name": "SK Growth Opportunities Corp Wt",
        "最新价": 0.06,
        "涨跌额": 0.01,
        "涨跌幅": 20.0,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 130111.0,
        "成交额": 6961.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.SKGRW"
    },
    {
        "序号": 68,
        "name": "Oak Woods Acquisition Corp Rt",
        "最新价": 0.18,
        "涨跌额": 0.03,
        "涨跌幅": 20.0,
        "开盘价": 0.18,
        "最高价": 0.18,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 6273.0,
        "成交额": 1123.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.OAKUR"
    },
    {
        "序号": 69,
        "name": "法拉第未来",
        "最新价": 0.3,
        "涨跌额": 0.05,
        "涨跌幅": 20.0,
        "开盘价": 0.25,
        "最高价": 0.37,
        "最低价": 0.24,
        "昨收价": 0.25,
        "总市值": 17224904.0,
        "市盈率": -0.04,
        "成交量": 89391044.0,
        "成交额": 28422266.0,
        "振幅": 52.0,
        "换手率": 155.69,
        "symbol": "105.FFIE"
    },
    {
        "序号": 70,
        "name": "Casa Systems Inc",
        "最新价": 0.54,
        "涨跌额": 0.09,
        "涨跌幅": 20.0,
        "开盘价": 0.47,
        "最高价": 0.56,
        "最低价": 0.44,
        "昨收价": 0.45,
        "总市值": 53518859.0,
        "市盈率": -0.5,
        "成交量": 359154.0,
        "成交额": 177994.0,
        "振幅": 26.67,
        "换手率": 0.36,
        "symbol": "105.CASA"
    },
    {
        "序号": 71,
        "name": "Argo Blockchain Plc ADR",
        "最新价": 1.86,
        "涨跌额": 0.31,
        "涨跌幅": 20.0,
        "开盘价": 1.58,
        "最高价": 1.92,
        "最低价": 1.51,
        "昨收价": 1.55,
        "总市值": 99570481.0,
        "市盈率": -0.5,
        "成交量": 851735.0,
        "成交额": 1491180.0,
        "振幅": 26.45,
        "换手率": 1.59,
        "symbol": "105.ARBK"
    },
    {
        "序号": 72,
        "name": "Alpha Partners Technology Merge",
        "最新价": 0.06,
        "涨跌额": 0.01,
        "涨跌幅": 20.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 68400.0,
        "成交额": 4309.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.APTMW"
    },
    {
        "序号": 73,
        "name": "Vertical Aerospace Ltd",
        "最新价": 0.81,
        "涨跌额": 0.13,
        "涨跌幅": 19.12,
        "开盘价": 0.7,
        "最高价": 0.82,
        "最低价": 0.7,
        "昨收价": 0.68,
        "总市值": 179180927.0,
        "市盈率": -19.93,
        "成交量": 911172.0,
        "成交额": 708558.0,
        "振幅": 17.65,
        "换手率": 0.41,
        "symbol": "106.EVTL"
    },
    {
        "序号": 74,
        "name": "旭明光电",
        "最新价": 1.5,
        "涨跌额": 0.24,
        "涨跌幅": 19.05,
        "开盘价": 1.24,
        "最高价": 1.54,
        "最低价": 1.24,
        "昨收价": 1.26,
        "总市值": 7411365.0,
        "市盈率": -2.76,
        "成交量": 73442.0,
        "成交额": 104823.0,
        "振幅": 23.81,
        "换手率": 1.49,
        "symbol": "105.LEDS"
    },
    {
        "序号": 75,
        "name": "汉广厦房地产",
        "最新价": 0.25,
        "涨跌额": 0.04,
        "涨跌幅": 19.05,
        "开盘价": 0.21,
        "最高价": 0.28,
        "最低价": 0.2,
        "昨收价": 0.21,
        "总市值": 13948317.0,
        "市盈率": -0.07,
        "成交量": 21178749.0,
        "成交额": 5148860.0,
        "振幅": 38.1,
        "换手率": 37.96,
        "symbol": "105.GGE"
    },
    {
        "序号": 76,
        "name": "开心汽车",
        "最新价": 1.69,
        "涨跌额": 0.27,
        "涨跌幅": 19.01,
        "开盘价": 1.47,
        "最高价": 1.81,
        "最低价": 1.4,
        "昨收价": 1.42,
        "总市值": 44741143.0,
        "市盈率": -2.41,
        "成交量": 969139.0,
        "成交额": 1584925.0,
        "振幅": 28.87,
        "换手率": 3.66,
        "symbol": "105.KXIN"
    },
    {
        "序号": 77,
        "name": "7GC & Co Holdings Inc-A",
        "最新价": 11.39,
        "涨跌额": 1.8,
        "涨跌幅": 18.77,
        "开盘价": 10.9,
        "最高价": 11.67,
        "最低价": 10.9,
        "昨收价": 9.59,
        "总市值": 103417077.0,
        "市盈率": -33.6,
        "成交量": 4079.0,
        "成交额": 45204.0,
        "振幅": 8.03,
        "换手率": 0.04,
        "symbol": "105.VII"
    },
    {
        "序号": 78,
        "name": "Greenidge Generation Holdings I",
        "最新价": 5.34,
        "涨跌额": 0.84,
        "涨跌幅": 18.67,
        "开盘价": 4.52,
        "最高价": 5.34,
        "最低价": 4.5,
        "昨收价": 4.5,
        "总市值": 39305139.0,
        "市盈率": -0.23,
        "成交量": 285569.0,
        "成交额": 1421905.0,
        "振幅": 18.67,
        "换手率": 3.88,
        "symbol": "105.GREE"
    },
    {
        "序号": 79,
        "name": "Organogenesis Holdings Inc-A",
        "最新价": 3.33,
        "涨跌额": 0.52,
        "涨跌幅": 18.51,
        "开盘价": 2.78,
        "最高价": 3.36,
        "最低价": 2.63,
        "昨收价": 2.81,
        "总市值": 439697076.0,
        "市盈率": 33.83,
        "成交量": 2481486.0,
        "成交额": 7562334.0,
        "振幅": 25.98,
        "换手率": 1.88,
        "symbol": "105.ORGO"
    },
    {
        "序号": 80,
        "name": "View Inc-A",
        "最新价": 1.63,
        "涨跌额": 0.25,
        "涨跌幅": 18.12,
        "开盘价": 1.46,
        "最高价": 2.02,
        "最低价": 1.34,
        "昨收价": 1.38,
        "总市值": 6629267.0,
        "市盈率": -0.02,
        "成交量": 6612403.0,
        "成交额": 11864264.0,
        "振幅": 49.28,
        "换手率": 162.59,
        "symbol": "105.VIEW"
    },
    {
        "序号": 81,
        "name": "Monte Rosa Therapeutics Inc",
        "最新价": 4.33,
        "涨跌额": 0.66,
        "涨跌幅": 17.98,
        "开盘价": 3.61,
        "最高价": 4.42,
        "最低价": 3.53,
        "昨收价": 3.67,
        "总市值": 216850830.0,
        "市盈率": -1.63,
        "成交量": 569990.0,
        "成交额": 2353421.0,
        "振幅": 24.25,
        "换手率": 1.14,
        "symbol": "105.GLUE"
    },
    {
        "序号": 82,
        "name": "NWTN Inc",
        "最新价": 8.45,
        "涨跌额": 1.28,
        "涨跌幅": 17.85,
        "开盘价": 7.21,
        "最高价": 8.65,
        "最低价": 7.1,
        "昨收价": 7.17,
        "总市值": 2418276102.0,
        "市盈率": -59.39,
        "成交量": 37026.0,
        "成交额": 279672.0,
        "振幅": 21.62,
        "换手率": 0.01,
        "symbol": "105.NWTN"
    },
    {
        "序号": 83,
        "name": "Investcorp Europe Acquisition C",
        "最新价": 0.2,
        "涨跌额": 0.03,
        "涨跌幅": 17.65,
        "开盘价": 0.22,
        "最高价": 0.23,
        "最低价": 0.2,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 673.0,
        "成交额": 146.0,
        "振幅": 17.65,
        "换手率": null,
        "symbol": "105.IVCBW"
    },
    {
        "序号": 84,
        "name": "Bitcoin Depot Inc-A",
        "最新价": 2.94,
        "涨跌额": 0.44,
        "涨跌幅": 17.6,
        "开盘价": 2.54,
        "最高价": 2.95,
        "最低价": 2.48,
        "昨收价": 2.5,
        "总市值": 171779649.0,
        "市盈率": -15.79,
        "成交量": 571838.0,
        "成交额": 1567894.0,
        "振幅": 18.8,
        "换手率": 0.98,
        "symbol": "105.BTM"
    },
    {
        "序号": 85,
        "name": "Harbor Custom Development Inc S",
        "最新价": 1.28,
        "涨跌额": 0.19,
        "涨跌幅": 17.43,
        "开盘价": 1.11,
        "最高价": 1.47,
        "最低价": 1.11,
        "昨收价": 1.09,
        "总市值": 1536000.0,
        "市盈率": null,
        "成交量": 71416.0,
        "成交额": 88619.0,
        "振幅": 33.03,
        "换手率": 5.95,
        "symbol": "105.HCDIP"
    },
    {
        "序号": 86,
        "name": "Innovative International Acquis",
        "最新价": 11.26,
        "涨跌额": 1.67,
        "涨跌幅": 17.41,
        "开盘价": 9.56,
        "最高价": 12.7,
        "最低价": 9.56,
        "昨收价": 9.59,
        "总市值": 130282940.0,
        "市盈率": -29.88,
        "成交量": 4355.0,
        "成交额": 48248.0,
        "振幅": 32.74,
        "换手率": null,
        "symbol": "105.IOACU"
    },
    {
        "序号": 87,
        "name": "疼痛治疗",
        "最新价": 24.39,
        "涨跌额": 3.61,
        "涨跌幅": 17.37,
        "开盘价": 21.0,
        "最高价": 24.84,
        "最低价": 20.9,
        "昨收价": 20.78,
        "总市值": 1028625372.0,
        "市盈率": -10.78,
        "成交量": 2313855.0,
        "成交额": 54825134.0,
        "振幅": 18.96,
        "换手率": 5.49,
        "symbol": "105.SAVA"
    },
    {
        "序号": 88,
        "name": "2seventy bio Inc",
        "最新价": 3.1,
        "涨跌额": 0.45,
        "涨跌幅": 16.98,
        "开盘价": 2.65,
        "最高价": 3.12,
        "最低价": 2.6,
        "昨收价": 2.65,
        "总市值": 156936279.0,
        "市盈率": -0.85,
        "成交量": 6423280.0,
        "成交额": 19048213.0,
        "振幅": 19.62,
        "换手率": 12.69,
        "symbol": "105.TSVT"
    },
    {
        "序号": 89,
        "name": "Cognition Therapeutics Inc",
        "最新价": 1.66,
        "涨跌额": 0.24,
        "涨跌幅": 16.9,
        "开盘价": 1.45,
        "最高价": 1.72,
        "最低价": 1.44,
        "昨收价": 1.42,
        "总市值": 50492356.0,
        "市盈率": -2.21,
        "成交量": 269640.0,
        "成交额": 445448.0,
        "振幅": 19.72,
        "换手率": 0.89,
        "symbol": "105.CGTX"
    },
    {
        "序号": 90,
        "name": "Applied Digital Corp",
        "最新价": 6.58,
        "涨跌额": 0.95,
        "涨跌幅": 16.87,
        "开盘价": 5.71,
        "最高价": 6.61,
        "最低价": 5.7,
        "昨收价": 5.63,
        "总市值": 699455612.0,
        "市盈率": -13.56,
        "成交量": 5987684.0,
        "成交额": 37435123.0,
        "振幅": 16.16,
        "换手率": 5.63,
        "symbol": "105.APLD"
    },
    {
        "序号": 91,
        "name": "Protara Therapeutics Inc",
        "最新价": 1.6,
        "涨跌额": 0.23,
        "涨跌幅": 16.79,
        "开盘价": 1.44,
        "最高价": 1.62,
        "最低价": 1.44,
        "昨收价": 1.37,
        "总市值": 18183845.0,
        "市盈率": -0.26,
        "成交量": 79751.0,
        "成交额": 121729.0,
        "振幅": 13.14,
        "换手率": 0.7,
        "symbol": "105.TARA"
    },
    {
        "序号": 92,
        "name": "Black Diamond Therapeutics Inc",
        "最新价": 3.0,
        "涨跌额": 0.43,
        "涨跌幅": 16.73,
        "开盘价": 2.57,
        "最高价": 3.03,
        "最低价": 2.53,
        "昨收价": 2.57,
        "总市值": 154905552.0,
        "市盈率": -1.84,
        "成交量": 1403748.0,
        "成交额": 4074001.0,
        "振幅": 19.46,
        "换手率": 2.72,
        "symbol": "105.BDTX"
    },
    {
        "序号": 93,
        "name": "Viveon Health Acquisition Corp ",
        "最新价": 0.07,
        "涨跌额": 0.01,
        "涨跌幅": 16.67,
        "开盘价": 0.04,
        "最高价": 0.07,
        "最低价": 0.04,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1013.0,
        "成交额": 66.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "107.VHAQr"
    },
    {
        "序号": 94,
        "name": "Rigel Pharmaceuticals Inc",
        "最新价": 1.33,
        "涨跌额": 0.19,
        "涨跌幅": 16.67,
        "开盘价": 1.17,
        "最高价": 1.34,
        "最低价": 1.14,
        "昨收价": 1.14,
        "总市值": 231911439.0,
        "市盈率": -9.49,
        "成交量": 1751958.0,
        "成交额": 2210514.0,
        "振幅": 17.54,
        "换手率": 1.0,
        "symbol": "105.RIGL"
    },
    {
        "序号": 95,
        "name": "Meta Materials Inc",
        "最新价": 0.07,
        "涨跌额": 0.01,
        "涨跌幅": 16.67,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": 39579022.0,
        "市盈率": -0.12,
        "成交量": 16986324.0,
        "成交额": 1066301.0,
        "振幅": 16.67,
        "换手率": 3.0,
        "symbol": "105.MMAT"
    },
    {
        "序号": 96,
        "name": "SeaStar Medical Holding Corp",
        "最新价": 0.56,
        "涨跌额": 0.08,
        "涨跌幅": 16.67,
        "开盘价": 0.5,
        "最高价": 0.59,
        "最低价": 0.47,
        "昨收价": 0.48,
        "总市值": 24920000.0,
        "市盈率": -0.7,
        "成交量": 4246761.0,
        "成交额": 2258674.0,
        "振幅": 25.0,
        "换手率": 9.54,
        "symbol": "105.ICU"
    },
    {
        "序号": 97,
        "name": "安博科-匹兹堡(权证)",
        "最新价": 0.14,
        "涨跌额": 0.02,
        "涨跌幅": 16.67,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 1402.0,
        "成交额": 201.0,
        "振幅": 8.33,
        "换手率": null,
        "symbol": "107.AP_WS"
    },
    {
        "序号": 98,
        "name": "Torrid Holdings Inc",
        "最新价": 4.84,
        "涨跌额": 0.69,
        "涨跌幅": 16.63,
        "开盘价": 5.25,
        "最高价": 5.93,
        "最低价": 4.5,
        "昨收价": 4.15,
        "总市值": 503857242.0,
        "市盈率": 42.54,
        "成交量": 1759405.0,
        "成交额": 9084600.0,
        "振幅": 34.46,
        "换手率": 1.69,
        "symbol": "106.CURV"
    },
    {
        "序号": 99,
        "name": "Applied Therapeutics Inc",
        "最新价": 3.11,
        "涨跌额": 0.44,
        "涨跌幅": 16.48,
        "开盘价": 2.64,
        "最高价": 3.37,
        "最低价": 2.57,
        "昨收价": 2.67,
        "总市值": 240182834.0,
        "市盈率": -2.49,
        "成交量": 3819218.0,
        "成交额": 11540745.0,
        "振幅": 29.96,
        "换手率": 4.95,
        "symbol": "105.APLT"
    },
    {
        "序号": 100,
        "name": "Brilliant Acquisition Corp Rt",
        "最新价": 0.29,
        "涨跌额": 0.04,
        "涨跌幅": 16.0,
        "开盘价": 0.35,
        "最高价": 0.35,
        "最低价": 0.29,
        "昨收价": 0.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 28112.0,
        "成交额": 8927.0,
        "振幅": 24.0,
        "换手率": null,
        "symbol": "105.BRLIR"
    },
    {
        "序号": 101,
        "name": "Sacks Parente Golf Inc",
        "最新价": 0.8,
        "涨跌额": 0.11,
        "涨跌幅": 15.94,
        "开盘价": 0.77,
        "最高价": 0.84,
        "最低价": 0.74,
        "昨收价": 0.69,
        "总市值": 11676696.0,
        "市盈率": -3.53,
        "成交量": 131303.0,
        "成交额": 105205.0,
        "振幅": 14.49,
        "换手率": 0.9,
        "symbol": "105.SPGC"
    },
    {
        "序号": 102,
        "name": "Alpha Tau Medical Ltd Wt",
        "最新价": 0.22,
        "涨跌额": 0.03,
        "涨跌幅": 15.79,
        "开盘价": 0.22,
        "最高价": 0.22,
        "最低价": 0.2,
        "昨收价": 0.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 24653.0,
        "成交额": 5378.0,
        "振幅": 10.53,
        "换手率": null,
        "symbol": "105.DRTSW"
    },
    {
        "序号": 103,
        "name": "Brilliant Acquisition Corp",
        "最新价": 12.15,
        "涨跌额": 1.65,
        "涨跌幅": 15.71,
        "开盘价": 10.5,
        "最高价": 13.83,
        "最低价": 10.2,
        "昨收价": 10.5,
        "总市值": 22048556.0,
        "市盈率": -60.91,
        "成交量": 99369.0,
        "成交额": 1224832.0,
        "振幅": 34.57,
        "换手率": 5.48,
        "symbol": "105.BRLI"
    },
    {
        "序号": 104,
        "name": "Microvast Holdings Inc Wt",
        "最新价": 0.15,
        "涨跌额": 0.02,
        "涨跌幅": 15.38,
        "开盘价": 0.14,
        "最高价": 0.15,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 3340.0,
        "成交额": 438.0,
        "振幅": 15.38,
        "换手率": null,
        "symbol": "105.MVSTW"
    },
    {
        "序号": 105,
        "name": "Spire Global Inc-A",
        "最新价": 7.09,
        "涨跌额": 0.94,
        "涨跌幅": 15.28,
        "开盘价": 6.18,
        "最高价": 7.21,
        "最低价": 6.16,
        "昨收价": 6.15,
        "总市值": 158532060.0,
        "市盈率": -2.29,
        "成交量": 310353.0,
        "成交额": 2099315.0,
        "振幅": 17.07,
        "换手率": 1.39,
        "symbol": "106.SPIR"
    },
    {
        "序号": 106,
        "name": "Equillium Inc",
        "最新价": 0.68,
        "涨跌额": 0.09,
        "涨跌幅": 15.25,
        "开盘价": 0.58,
        "最高价": 0.72,
        "最低价": 0.58,
        "昨收价": 0.59,
        "总市值": 23881089.0,
        "市盈率": -2.91,
        "成交量": 494906.0,
        "成交额": 324317.0,
        "振幅": 23.73,
        "换手率": 1.41,
        "symbol": "105.EQ"
    },
    {
        "序号": 107,
        "name": "CalciMedica Inc",
        "最新价": 3.89,
        "涨跌额": 0.5,
        "涨跌幅": 14.75,
        "开盘价": 3.24,
        "最高价": 3.89,
        "最低价": 3.06,
        "昨收价": 3.39,
        "总市值": 22114156.0,
        "市盈率": -0.39,
        "成交量": 6160.0,
        "成交额": 19576.0,
        "振幅": 24.48,
        "换手率": 0.11,
        "symbol": "105.CALC"
    },
    {
        "序号": 108,
        "name": "Presidio Property Trust Inc-A",
        "最新价": 1.18,
        "涨跌额": 0.15,
        "涨跌幅": 14.56,
        "开盘价": 0.97,
        "最高价": 1.19,
        "最低价": 0.97,
        "昨收价": 1.03,
        "总市值": 15493333.0,
        "市盈率": 0.82,
        "成交量": 108605.0,
        "成交额": 119623.0,
        "振幅": 21.36,
        "换手率": 0.83,
        "symbol": "105.SQFT"
    },
    {
        "序号": 109,
        "name": "Eyenovia Inc",
        "最新价": 1.82,
        "涨跌额": 0.23,
        "涨跌幅": 14.47,
        "开盘价": 1.68,
        "最高价": 1.85,
        "最低价": 1.62,
        "昨收价": 1.59,
        "总市值": 80302450.0,
        "市盈率": -3.16,
        "成交量": 1016942.0,
        "成交额": 1759799.0,
        "振幅": 14.47,
        "换手率": 2.3,
        "symbol": "105.EYEN"
    },
    {
        "序号": 110,
        "name": "Foghorn Therapeutics Inc",
        "最新价": 5.63,
        "涨跌额": 0.71,
        "涨跌幅": 14.43,
        "开盘价": 4.87,
        "最高价": 6.03,
        "最低价": 4.83,
        "昨收价": 4.92,
        "总市值": 237682419.0,
        "市盈率": -2.3,
        "成交量": 166258.0,
        "成交额": 909344.0,
        "振幅": 24.39,
        "换手率": 0.39,
        "symbol": "105.FHTX"
    },
    {
        "序号": 111,
        "name": "微美全息",
        "最新价": 0.8,
        "涨跌额": 0.1,
        "涨跌幅": 14.29,
        "开盘价": 0.66,
        "最高价": 0.89,
        "最低价": 0.66,
        "昨收价": 0.7,
        "总市值": 69366433.0,
        "市盈率": -1.52,
        "成交量": 2293290.0,
        "成交额": 1781572.0,
        "振幅": 32.86,
        "换手率": 2.64,
        "symbol": "105.WIMI"
    },
    {
        "序号": 112,
        "name": "Solid Power Inc Wt",
        "最新价": 0.24,
        "涨跌额": 0.03,
        "涨跌幅": 14.29,
        "开盘价": 0.2,
        "最高价": 0.24,
        "最低价": 0.2,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 8685.0,
        "成交额": 1845.0,
        "振幅": 19.05,
        "换手率": null,
        "symbol": "105.SLDPW"
    },
    {
        "序号": 113,
        "name": "The Lion Electric Co Wt",
        "最新价": 0.08,
        "涨跌额": 0.01,
        "涨跌幅": 14.29,
        "开盘价": 0.07,
        "最高价": 0.08,
        "最低价": 0.07,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 36076.0,
        "成交额": 2584.0,
        "振幅": 14.29,
        "换手率": null,
        "symbol": "106.LEV_WS"
    },
    {
        "序号": 114,
        "name": "Corvus Pharmaceuticals Inc",
        "最新价": 1.92,
        "涨跌额": 0.24,
        "涨跌幅": 14.29,
        "开盘价": 1.75,
        "最高价": 1.92,
        "最低价": 1.71,
        "昨收价": 1.68,
        "总市值": 94154077.0,
        "市盈率": -3.12,
        "成交量": 338465.0,
        "成交额": 625038.0,
        "振幅": 12.5,
        "换手率": 0.69,
        "symbol": "105.CRVS"
    },
    {
        "序号": 115,
        "name": "Bakkt Holdings Inc Wt",
        "最新价": 0.24,
        "涨跌额": 0.03,
        "涨跌幅": 14.29,
        "开盘价": 0.22,
        "最高价": 0.24,
        "最低价": 0.22,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 29847.0,
        "成交额": 7053.0,
        "振幅": 9.52,
        "换手率": null,
        "symbol": "106.BKKT_WS"
    },
    {
        "序号": 116,
        "name": "悦航阳光",
        "最新价": 0.72,
        "涨跌额": 0.09,
        "涨跌幅": 14.29,
        "开盘价": 0.72,
        "最高价": 0.83,
        "最低价": 0.72,
        "昨收价": 0.63,
        "总市值": 6425055.0,
        "市盈率": -0.48,
        "成交量": 32071.0,
        "成交额": 25393.0,
        "振幅": 17.46,
        "换手率": 0.36,
        "symbol": "105.ANTE"
    },
    {
        "序号": 117,
        "name": "Allarity Therapeutics Inc",
        "最新价": 0.56,
        "涨跌额": 0.07,
        "涨跌幅": 14.29,
        "开盘价": 0.56,
        "最高价": 0.63,
        "最低价": 0.46,
        "昨收价": 0.49,
        "总市值": 2701789.0,
        "市盈率": -0.21,
        "成交量": 4978693.0,
        "成交额": 2853715.0,
        "振幅": 34.69,
        "换手率": 103.19,
        "symbol": "105.ALLR"
    },
    {
        "序号": 118,
        "name": "Dave Inc-A",
        "最新价": 7.76,
        "涨跌额": 0.96,
        "涨跌幅": 14.12,
        "开盘价": 6.75,
        "最高价": 7.8,
        "最低价": 6.75,
        "昨收价": 6.8,
        "总市值": 93906631.0,
        "市盈率": -1.34,
        "成交量": 57561.0,
        "成交额": 428881.0,
        "振幅": 15.44,
        "换手率": 0.48,
        "symbol": "105.DAVE"
    },
    {
        "序号": 119,
        "name": "Perma-Pipe International Holdin",
        "最新价": 7.93,
        "涨跌额": 0.97,
        "涨跌幅": 13.94,
        "开盘价": 7.3,
        "最高价": 8.19,
        "最低价": 7.3,
        "昨收价": 6.96,
        "总市值": 63943563.0,
        "市盈率": 12.62,
        "成交量": 55252.0,
        "成交额": 426200.0,
        "振幅": 12.79,
        "换手率": 0.69,
        "symbol": "105.PPIH"
    },
    {
        "序号": 120,
        "name": "VerifyMe Inc",
        "最新价": 1.15,
        "涨跌额": 0.14,
        "涨跌幅": 13.86,
        "开盘价": 1.26,
        "最高价": 1.61,
        "最低价": 1.12,
        "昨收价": 1.01,
        "总市值": 11490577.0,
        "市盈率": -3.5,
        "成交量": 30526907.0,
        "成交额": 41110796.0,
        "振幅": 48.51,
        "换手率": 305.52,
        "symbol": "105.VRME"
    },
    {
        "序号": 121,
        "name": "Marker Therapeutics Inc",
        "最新价": 3.21,
        "涨跌额": 0.39,
        "涨跌幅": 13.83,
        "开盘价": 2.94,
        "最高价": 3.33,
        "最低价": 2.85,
        "昨收价": 2.82,
        "总市值": 28533754.0,
        "市盈率": -3.07,
        "成交量": 43108.0,
        "成交额": 131164.0,
        "振幅": 17.02,
        "换手率": 0.48,
        "symbol": "105.MRKR"
    },
    {
        "序号": 122,
        "name": "TeraWulf Inc",
        "最新价": 1.83,
        "涨跌额": 0.22,
        "涨跌幅": 13.66,
        "开盘价": 1.61,
        "最高价": 1.85,
        "最低价": 1.61,
        "昨收价": 1.61,
        "总市值": 440875014.0,
        "市盈率": -5.02,
        "成交量": 20535127.0,
        "成交额": 35736798.0,
        "振幅": 14.91,
        "换手率": 8.52,
        "symbol": "105.WULF"
    },
    {
        "序号": 123,
        "name": "AXT Inc",
        "最新价": 2.33,
        "涨跌额": 0.28,
        "涨跌幅": 13.66,
        "开盘价": 2.06,
        "最高价": 2.34,
        "最低价": 2.06,
        "昨收价": 2.05,
        "总市值": 101691249.0,
        "市盈率": -7.87,
        "成交量": 307889.0,
        "成交额": 685601.0,
        "振幅": 13.66,
        "换手率": 0.71,
        "symbol": "105.AXTI"
    },
    {
        "序号": 124,
        "name": "Tellurian Inc",
        "最新价": 0.75,
        "涨跌额": 0.09,
        "涨跌幅": 13.64,
        "开盘价": 0.65,
        "最高价": 0.76,
        "最低价": 0.63,
        "昨收价": 0.66,
        "总市值": 476131773.0,
        "市盈率": -3.92,
        "成交量": 51009633.0,
        "成交额": 34813435.0,
        "振幅": 19.7,
        "换手率": 8.04,
        "symbol": "107.TELL"
    },
    {
        "序号": 125,
        "name": "SHF Holdings Inc-A",
        "最新价": 1.0,
        "涨跌额": 0.12,
        "涨跌幅": 13.64,
        "开盘价": 0.91,
        "最高价": 1.06,
        "最低价": 0.9,
        "昨收价": 0.88,
        "总市值": 46593317.0,
        "市盈率": -0.82,
        "成交量": 353149.0,
        "成交额": 346324.0,
        "振幅": 18.18,
        "换手率": 0.76,
        "symbol": "105.SHFS"
    },
    {
        "序号": 126,
        "name": "Gyre Therapeutics Inc",
        "最新价": 20.77,
        "涨跌额": 2.49,
        "涨跌幅": 13.62,
        "开盘价": 19.0,
        "最高价": 20.96,
        "最低价": 18.04,
        "昨收价": 18.28,
        "总市值": 1590641829.0,
        "市盈率": -35.95,
        "成交量": 80672.0,
        "成交额": 1558054.0,
        "振幅": 15.97,
        "换手率": 0.11,
        "symbol": "105.GYRE"
    },
    {
        "序号": 127,
        "name": "Palatin Technologies Inc",
        "最新价": 3.02,
        "涨跌额": 0.36,
        "涨跌幅": 13.53,
        "开盘价": 2.7,
        "最高价": 3.28,
        "最低价": 2.67,
        "昨收价": 2.66,
        "总市值": 41486154.0,
        "市盈率": -1.65,
        "成交量": 401710.0,
        "成交额": 1210808.0,
        "振幅": 22.93,
        "换手率": 2.92,
        "symbol": "107.PTN"
    },
    {
        "序号": 128,
        "name": "LiveWire Group Inc Wt",
        "最新价": 0.34,
        "涨跌额": 0.04,
        "涨跌幅": 13.33,
        "开盘价": 0.3,
        "最高价": 0.34,
        "最低价": 0.3,
        "昨收价": 0.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 5701.0,
        "成交额": 1724.0,
        "振幅": 13.33,
        "换手率": null,
        "symbol": "106.LVWR_WS"
    },
    {
        "序号": 129,
        "name": "LM Funding America Inc",
        "最新价": 0.34,
        "涨跌额": 0.04,
        "涨跌幅": 13.33,
        "开盘价": 0.3,
        "最高价": 0.35,
        "最低价": 0.3,
        "昨收价": 0.3,
        "总市值": 4981640.0,
        "市盈率": -0.15,
        "成交量": 1090323.0,
        "成交额": 358044.0,
        "振幅": 16.67,
        "换手率": 7.44,
        "symbol": "105.LMFA"
    },
    {
        "序号": 130,
        "name": "Inuvo Inc",
        "最新价": 0.34,
        "涨跌额": 0.04,
        "涨跌幅": 13.33,
        "开盘价": 0.29,
        "最高价": 0.35,
        "最低价": 0.29,
        "昨收价": 0.3,
        "总市值": 46913771.0,
        "市盈率": -3.92,
        "成交量": 1359467.0,
        "成交额": 452193.0,
        "振幅": 20.0,
        "换手率": 0.99,
        "symbol": "107.INUV"
    },
    {
        "序号": 131,
        "name": "eFFECTOR Therapeutics Inc Wt",
        "最新价": 0.17,
        "涨跌额": 0.02,
        "涨跌幅": 13.33,
        "开盘价": 0.09,
        "最高价": 0.17,
        "最低价": 0.09,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 400.0,
        "成交额": 45.0,
        "振幅": 53.33,
        "换手率": null,
        "symbol": "105.EFTRW"
    },
    {
        "序号": 132,
        "name": "Arbe Robotics Ltd Wt",
        "最新价": 0.17,
        "涨跌额": 0.02,
        "涨跌幅": 13.33,
        "开盘价": 0.16,
        "最高价": 0.18,
        "最低价": 0.16,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 9704.0,
        "成交额": 1708.0,
        "振幅": 13.33,
        "换手率": null,
        "symbol": "105.ARBEW"
    },
    {
        "序号": 133,
        "name": "Anixa Biosciences Inc",
        "最新价": 4.08,
        "涨跌额": 0.48,
        "涨跌幅": 13.33,
        "开盘价": 3.58,
        "最高价": 4.22,
        "最低价": 3.56,
        "昨收价": 3.6,
        "总市值": 126765690.0,
        "市盈率": -11.96,
        "成交量": 896890.0,
        "成交额": 3542239.0,
        "振幅": 18.33,
        "换手率": 2.89,
        "symbol": "105.ANIX"
    },
    {
        "序号": 134,
        "name": "Xcel Brands Inc",
        "最新价": 1.3,
        "涨跌额": 0.15,
        "涨跌幅": 13.04,
        "开盘价": 1.18,
        "最高价": 1.35,
        "最低价": 1.18,
        "昨收价": 1.15,
        "总市值": 25740069.0,
        "市盈率": -1.27,
        "成交量": 99178.0,
        "成交额": 127353.0,
        "振幅": 14.78,
        "换手率": 0.5,
        "symbol": "105.XELB"
    },
    {
        "序号": 135,
        "name": "L Catterton Asia Acquisition Co",
        "最新价": 0.7,
        "涨跌额": 0.08,
        "涨跌幅": 12.9,
        "开盘价": 0.59,
        "最高价": 0.7,
        "最低价": 0.59,
        "昨收价": 0.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 4868.0,
        "成交额": 3026.0,
        "振幅": 17.74,
        "换手率": null,
        "symbol": "105.LCAAW"
    },
    {
        "序号": 136,
        "name": "JanOne Inc",
        "最新价": 0.53,
        "涨跌额": 0.06,
        "涨跌幅": 12.77,
        "开盘价": 0.47,
        "最高价": 0.54,
        "最低价": 0.47,
        "昨收价": 0.47,
        "总市值": 2627553.0,
        "市盈率": 0.2,
        "成交量": 255514.0,
        "成交额": 129629.0,
        "振幅": 14.89,
        "换手率": 5.15,
        "symbol": "105.JAN"
    },
    {
        "序号": 137,
        "name": "Regis Corp",
        "最新价": 9.8,
        "涨跌额": 1.1,
        "涨跌幅": 12.64,
        "开盘价": 8.94,
        "最高价": 9.91,
        "最低价": 8.78,
        "昨收价": 8.7,
        "总市值": 22540000.0,
        "市盈率": -2.94,
        "成交量": 54213.0,
        "成交额": 510041.0,
        "振幅": 12.99,
        "换手率": 2.36,
        "symbol": "106.RGS"
    },
    {
        "序号": 138,
        "name": "Screaming Eagle Acquisition Cor",
        "最新价": 0.09,
        "涨跌额": 0.01,
        "涨跌幅": 12.5,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 750.0,
        "成交额": 64.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SCRMW"
    },
    {
        "序号": 139,
        "name": "Satellogic Inc Wt",
        "最新价": 0.18,
        "涨跌额": 0.02,
        "涨跌幅": 12.5,
        "开盘价": 0.18,
        "最高价": 0.18,
        "最低价": 0.18,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 20000.0,
        "成交额": 3599.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SATLW"
    },
    {
        "序号": 140,
        "name": "Nouveau Monde Graphite Inc",
        "最新价": 3.06,
        "涨跌额": 0.34,
        "涨跌幅": 12.5,
        "开盘价": 2.75,
        "最高价": 3.06,
        "最低价": 2.67,
        "昨收价": 2.72,
        "总市值": 186365928.0,
        "市盈率": -5.62,
        "成交量": 261672.0,
        "成交额": 739014.0,
        "振幅": 14.34,
        "换手率": 0.43,
        "symbol": "106.NMG"
    },
    {
        "序号": 141,
        "name": "Mogo Inc",
        "最新价": 1.8,
        "涨跌额": 0.2,
        "涨跌幅": 12.5,
        "开盘价": 1.6,
        "最高价": 1.81,
        "最低价": 1.6,
        "昨收价": 1.6,
        "总市值": 44524451.0,
        "市盈率": -0.59,
        "成交量": 112415.0,
        "成交额": 190276.0,
        "振幅": 13.13,
        "换手率": 0.45,
        "symbol": "105.MOGO"
    },
    {
        "序号": 142,
        "name": "丽翔教育",
        "最新价": 0.72,
        "涨跌额": 0.08,
        "涨跌幅": 12.5,
        "开盘价": 0.63,
        "最高价": 0.74,
        "最低价": 0.5,
        "昨收价": 0.64,
        "总市值": 9600048.0,
        "市盈率": -6.37,
        "成交量": 307584.0,
        "成交额": 215045.0,
        "振幅": 37.5,
        "换手率": 2.31,
        "symbol": "105.LXEH"
    },
    {
        "序号": 143,
        "name": "Anghami Inc Wt",
        "最新价": 0.09,
        "涨跌额": 0.01,
        "涨跌幅": 12.5,
        "开盘价": 0.07,
        "最高价": 0.09,
        "最低价": 0.07,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1098.0,
        "成交额": 86.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.ANGHW"
    },
    {
        "序号": 144,
        "name": "安博教育",
        "最新价": 0.18,
        "涨跌额": 0.02,
        "涨跌幅": 12.5,
        "开盘价": 0.17,
        "最高价": 0.18,
        "最低价": 0.17,
        "昨收价": 0.16,
        "总市值": 5141477.0,
        "市盈率": -2.14,
        "成交量": 13379.0,
        "成交额": 2299.0,
        "振幅": 6.25,
        "换手率": 0.05,
        "symbol": "107.AMBO"
    },
    {
        "序号": 145,
        "name": "Cardio Diagnostics Holdings Inc",
        "最新价": 1.36,
        "涨跌额": 0.15,
        "涨跌幅": 12.4,
        "开盘价": 1.09,
        "最高价": 1.39,
        "最低价": 1.09,
        "昨收价": 1.21,
        "总市值": 27903038.0,
        "市盈率": -2.98,
        "成交量": 1667625.0,
        "成交额": 2099981.0,
        "振幅": 24.79,
        "换手率": 8.13,
        "symbol": "105.CDIO"
    },
    {
        "序号": 146,
        "name": "Biomea Fusion Inc",
        "最新价": 16.89,
        "涨跌额": 1.86,
        "涨跌幅": 12.38,
        "开盘价": 15.57,
        "最高价": 17.15,
        "最低价": 10.35,
        "昨收价": 15.03,
        "总市值": 603070726.0,
        "市盈率": -5.6,
        "成交量": 6633272.0,
        "成交额": 90645557.0,
        "振幅": 45.24,
        "换手率": 18.58,
        "symbol": "105.BMEA"
    },
    {
        "序号": 147,
        "name": "Syra Health Corp-A",
        "最新价": 1.37,
        "涨跌额": 0.15,
        "涨跌幅": 12.3,
        "开盘价": 1.22,
        "最高价": 1.4,
        "最低价": 1.22,
        "昨收价": 1.22,
        "总市值": 19072644.0,
        "市盈率": -7.46,
        "成交量": 36552.0,
        "成交额": 46775.0,
        "振幅": 14.75,
        "换手率": 0.26,
        "symbol": "105.SYRA"
    },
    {
        "序号": 148,
        "name": "Innate Pharma SA ADR",
        "最新价": 2.58,
        "涨跌额": 0.28,
        "涨跌幅": 12.17,
        "开盘价": 2.42,
        "最高价": 2.58,
        "最低价": 2.32,
        "昨收价": 2.3,
        "总市值": 208064959.0,
        "市盈率": -3.12,
        "成交量": 2983.0,
        "成交额": 7266.0,
        "振幅": 11.3,
        "换手率": 0.0,
        "symbol": "105.IPHA"
    },
    {
        "序号": 149,
        "name": "Mawson Infrastructure Group Inc",
        "最新价": 1.2,
        "涨跌额": 0.13,
        "涨跌幅": 12.15,
        "开盘价": 1.08,
        "最高价": 1.29,
        "最低价": 1.06,
        "昨收价": 1.07,
        "总市值": 19973653.0,
        "市盈率": -0.3,
        "成交量": 837939.0,
        "成交额": 998831.0,
        "振幅": 21.5,
        "换手率": 5.03,
        "symbol": "105.MIGI"
    },
    {
        "序号": 150,
        "name": "Mobiv Acquisition Corp Unit Con",
        "最新价": 4.99,
        "涨跌额": 0.54,
        "涨跌幅": 12.13,
        "开盘价": 4.54,
        "最高价": 4.99,
        "最低价": 4.52,
        "昨收价": 4.45,
        "总市值": 16669554.0,
        "市盈率": null,
        "成交量": 857.0,
        "成交额": 3980.0,
        "振幅": 10.56,
        "换手率": null,
        "symbol": "105.MOBVU"
    },
    {
        "序号": 151,
        "name": "派拉蒙全球-B",
        "最新价": 16.85,
        "涨跌额": 1.82,
        "涨跌幅": 12.11,
        "开盘价": 15.75,
        "最高价": 17.26,
        "最低价": 15.72,
        "昨收价": 15.03,
        "总市值": 10976197638.0,
        "市盈率": -9.97,
        "成交量": 49773411.0,
        "成交额": 839845216.0,
        "振幅": 10.25,
        "换手率": 8.15,
        "symbol": "105.PARA"
    },
    {
        "序号": 152,
        "name": "Saga Communications Inc-A",
        "最新价": 22.85,
        "涨跌额": 2.44,
        "涨跌幅": 11.95,
        "开盘价": 21.14,
        "最高价": 23.05,
        "最低价": 20.71,
        "昨收价": 20.41,
        "总市值": 139922638.0,
        "市盈率": 12.41,
        "成交量": 51961.0,
        "成交额": 1131043.0,
        "振幅": 11.46,
        "换手率": 0.85,
        "symbol": "105.SGA"
    },
    {
        "序号": 153,
        "name": "CleanSpark Inc",
        "最新价": 10.34,
        "涨跌额": 1.1,
        "涨跌幅": 11.9,
        "开盘价": 9.12,
        "最高价": 10.45,
        "最低价": 9.1,
        "昨收价": 9.24,
        "总市值": 1910311288.0,
        "市盈率": -13.99,
        "成交量": 31717640.0,
        "成交额": 318586640.0,
        "振幅": 14.61,
        "换手率": 17.17,
        "symbol": "105.CLSK"
    },
    {
        "序号": 154,
        "name": "应用光电",
        "最新价": 19.76,
        "涨跌额": 2.1,
        "涨跌幅": 11.89,
        "开盘价": 17.83,
        "最高价": 19.84,
        "最低价": 17.83,
        "昨收价": 17.66,
        "总市值": 702202781.0,
        "市盈率": -11.25,
        "成交量": 4883770.0,
        "成交额": 93457507.0,
        "振幅": 11.38,
        "换手率": 13.74,
        "symbol": "105.AAOI"
    },
    {
        "序号": 155,
        "name": "海天",
        "最新价": 1.32,
        "涨跌额": 0.14,
        "涨跌幅": 11.86,
        "开盘价": 1.22,
        "最高价": 1.44,
        "最低价": 1.17,
        "昨收价": 1.18,
        "总市值": 18997920.0,
        "市盈率": 13.58,
        "成交量": 242061.0,
        "成交额": 326381.0,
        "振幅": 22.88,
        "换手率": 1.68,
        "symbol": "105.HKIT"
    },
    {
        "序号": 156,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 8.79,
        "涨跌额": 0.93,
        "涨跌幅": 11.83,
        "开盘价": 7.9,
        "最高价": 8.9,
        "最低价": 7.9,
        "昨收价": 7.86,
        "总市值": 29134376.0,
        "市盈率": 80.62,
        "成交量": 3676.0,
        "成交额": 30767.0,
        "振幅": 12.72,
        "换手率": 0.11,
        "symbol": "105.MCAF"
    },
    {
        "序号": 157,
        "name": "Axos Financial Inc",
        "最新价": 49.9,
        "涨跌额": 5.27,
        "涨跌幅": 11.81,
        "开盘价": 46.8,
        "最高价": 50.34,
        "最低价": 46.48,
        "昨收价": 44.63,
        "总市值": 2880835133.0,
        "市盈率": 8.69,
        "成交量": 2259137.0,
        "成交额": 111646348.0,
        "振幅": 8.65,
        "换手率": 3.91,
        "symbol": "106.AX"
    },
    {
        "序号": 158,
        "name": "Fisker Inc-A",
        "最新价": 1.64,
        "涨跌额": 0.17,
        "涨跌幅": 11.56,
        "开盘价": 1.51,
        "最高价": 1.71,
        "最低价": 1.4,
        "昨收价": 1.47,
        "总市值": 574911159.0,
        "市盈率": -1.23,
        "成交量": 34198771.0,
        "成交额": 53305581.0,
        "振幅": 21.09,
        "换手率": 9.76,
        "symbol": "106.FSR"
    },
    {
        "序号": 159,
        "name": "Aspen Aerogels Inc",
        "最新价": 13.03,
        "涨跌额": 1.35,
        "涨跌幅": 11.56,
        "开盘价": 11.63,
        "最高价": 13.07,
        "最低价": 11.63,
        "昨收价": 11.68,
        "总市值": 915447746.0,
        "市盈率": -16.67,
        "成交量": 1434515.0,
        "成交额": 18124844.0,
        "振幅": 12.33,
        "换手率": 2.04,
        "symbol": "106.ASPN"
    },
    {
        "序号": 160,
        "name": "GraniteShares 1.5x COIN Daily E",
        "最新价": 26.7,
        "涨跌额": 2.75,
        "涨跌幅": 11.48,
        "开盘价": 23.91,
        "最高价": 26.7,
        "最低价": 23.88,
        "昨收价": 23.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 414285.0,
        "成交额": 10540217.0,
        "振幅": 11.77,
        "换手率": null,
        "symbol": "105.CONL"
    },
    {
        "序号": 161,
        "name": "Soluna Holdings Inc",
        "最新价": 3.69,
        "涨跌额": 0.38,
        "涨跌幅": 11.48,
        "开盘价": 3.28,
        "最高价": 3.9,
        "最低价": 3.28,
        "昨收价": 3.31,
        "总市值": 5940118.0,
        "市盈率": -0.12,
        "成交量": 221136.0,
        "成交额": 799638.0,
        "振幅": 18.73,
        "换手率": 13.74,
        "symbol": "105.SLNH"
    },
    {
        "序号": 162,
        "name": "Eneti Inc",
        "最新价": 10.94,
        "涨跌额": 1.12,
        "涨跌幅": 11.41,
        "开盘价": 10.8,
        "最高价": 11.0,
        "最低价": 10.61,
        "昨收价": 9.82,
        "总市值": 422799482.0,
        "市盈率": -11.57,
        "成交量": 879396.0,
        "成交额": 9574626.0,
        "振幅": 3.97,
        "换手率": 2.28,
        "symbol": "106.NETI"
    },
    {
        "序号": 163,
        "name": "Airgain Inc",
        "最新价": 3.34,
        "涨跌额": 0.34,
        "涨跌幅": 11.33,
        "开盘价": 3.05,
        "最高价": 3.4,
        "最低价": 3.05,
        "昨收价": 3.0,
        "总市值": 34881988.0,
        "市盈率": -3.43,
        "成交量": 33222.0,
        "成交额": 109455.0,
        "振幅": 11.67,
        "换手率": 0.32,
        "symbol": "105.AIRG"
    },
    {
        "序号": 164,
        "name": "Ensysce Biosciences Inc",
        "最新价": 1.09,
        "涨跌额": 0.11,
        "涨跌幅": 11.22,
        "开盘价": 0.99,
        "最高价": 1.14,
        "最低价": 0.99,
        "昨收价": 0.98,
        "总市值": 3429223.0,
        "市盈率": -0.27,
        "成交量": 70462.0,
        "成交额": 76518.0,
        "振幅": 15.31,
        "换手率": 2.24,
        "symbol": "105.ENSC"
    },
    {
        "序号": 165,
        "name": "Dune Acquisition Corp-A",
        "最新价": 4.77,
        "涨跌额": 0.48,
        "涨跌幅": 11.19,
        "开盘价": 4.25,
        "最高价": 6.36,
        "最低价": 4.2,
        "昨收价": 4.29,
        "总市值": 26209023.0,
        "市盈率": -7.91,
        "成交量": 362793.0,
        "成交额": 1968088.0,
        "振幅": 50.35,
        "换手率": 6.6,
        "symbol": "105.DUNE"
    },
    {
        "序号": 166,
        "name": "Sezzle Inc",
        "最新价": 12.45,
        "涨跌额": 1.25,
        "涨跌幅": 11.16,
        "开盘价": 11.24,
        "最高价": 13.8,
        "最低价": 11.16,
        "昨收价": 11.2,
        "总市值": 70905576.0,
        "市盈率": 14.79,
        "成交量": 45198.0,
        "成交额": 575182.0,
        "振幅": 23.57,
        "换手率": 0.79,
        "symbol": "105.SEZL"
    },
    {
        "序号": 167,
        "name": "Peraso Inc",
        "最新价": 0.2,
        "涨跌额": 0.02,
        "涨跌幅": 11.11,
        "开盘价": 0.19,
        "最高价": 0.22,
        "最低价": 0.18,
        "昨收价": 0.18,
        "总市值": 4968323.0,
        "市盈率": -0.22,
        "成交量": 551453.0,
        "成交额": 111771.0,
        "振幅": 22.22,
        "换手率": 2.22,
        "symbol": "105.PRSO"
    },
    {
        "序号": 168,
        "name": "Monopar Therapeutics Inc",
        "最新价": 0.4,
        "涨跌额": 0.04,
        "涨跌幅": 11.11,
        "开盘价": 0.38,
        "最高价": 0.44,
        "最低价": 0.36,
        "昨收价": 0.36,
        "总市值": 5946560.0,
        "市盈率": -0.63,
        "成交量": 287613.0,
        "成交额": 115622.0,
        "振幅": 22.22,
        "换手率": 1.93,
        "symbol": "105.MNPR"
    },
    {
        "序号": 169,
        "name": "Leafly Holdings Inc",
        "最新价": 5.5,
        "涨跌额": 0.55,
        "涨跌幅": 11.11,
        "开盘价": 4.99,
        "最高价": 5.5,
        "最低价": 4.95,
        "昨收价": 4.95,
        "总市值": 11977466.0,
        "市盈率": -0.81,
        "成交量": 24620.0,
        "成交额": 127223.0,
        "振幅": 11.11,
        "换手率": 1.13,
        "symbol": "105.LFLY"
    },
    {
        "序号": 170,
        "name": "Jasper Therapeutics Inc Wt",
        "最新价": 0.1,
        "涨跌额": 0.01,
        "涨跌幅": 11.11,
        "开盘价": 0.04,
        "最高价": 0.1,
        "最低价": 0.04,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 39468.0,
        "成交额": 2719.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.JSPRW"
    },
    {
        "序号": 171,
        "name": "Ginkgo Bioworks Holdings Inc Wt",
        "最新价": 0.1,
        "涨跌额": 0.01,
        "涨跌幅": 11.11,
        "开盘价": 0.09,
        "最高价": 0.11,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 71069.0,
        "成交额": 7257.0,
        "振幅": 22.22,
        "换手率": null,
        "symbol": "106.DNA_WS"
    },
    {
        "序号": 172,
        "name": "Canopy Growth Corp",
        "最新价": 0.8,
        "涨跌额": 0.08,
        "涨跌幅": 11.11,
        "开盘价": 0.73,
        "最高价": 0.81,
        "最低价": 0.7,
        "昨收价": 0.72,
        "总市值": 663266934.0,
        "市盈率": -0.71,
        "成交量": 42094500.0,
        "成交额": 32446028.0,
        "振幅": 15.28,
        "换手率": 5.08,
        "symbol": "105.CGC"
    },
    {
        "序号": 173,
        "name": "Ault Alliance Inc",
        "最新价": 0.1,
        "涨跌额": 0.01,
        "涨跌幅": 11.11,
        "开盘价": 0.1,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.09,
        "总市值": 7055997.0,
        "市盈率": -0.03,
        "成交量": 18054192.0,
        "成交额": 1825222.0,
        "振幅": 11.11,
        "换手率": 25.59,
        "symbol": "107.AULT"
    },
    {
        "序号": 174,
        "name": "AlphaTime Acquisition Corp Rt",
        "最新价": 0.1,
        "涨跌额": 0.01,
        "涨跌幅": 11.11,
        "开盘价": 0.09,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 78800.0,
        "成交额": 7878.0,
        "振幅": 11.11,
        "换手率": null,
        "symbol": "105.ATMCR"
    },
    {
        "序号": 175,
        "name": "Cipher Mining Inc",
        "最新价": 2.91,
        "涨跌额": 0.29,
        "涨跌幅": 11.07,
        "开盘价": 2.61,
        "最高价": 2.93,
        "最低价": 2.61,
        "昨收价": 2.62,
        "总市值": 741060810.0,
        "市盈率": -8.36,
        "成交量": 4309386.0,
        "成交额": 12243040.0,
        "振幅": 12.21,
        "换手率": 1.69,
        "symbol": "105.CIFR"
    },
    {
        "序号": 176,
        "name": "Kronos Bio Inc",
        "最新价": 1.41,
        "涨跌额": 0.14,
        "涨跌幅": 11.02,
        "开盘价": 1.27,
        "最高价": 1.43,
        "最低价": 1.21,
        "昨收价": 1.27,
        "总市值": 82226293.0,
        "市盈率": -0.69,
        "成交量": 243686.0,
        "成交额": 325757.0,
        "振幅": 17.32,
        "换手率": 0.42,
        "symbol": "105.KRON"
    },
    {
        "序号": 177,
        "name": "Eos Energy Enterprises Inc-A",
        "最新价": 1.22,
        "涨跌额": 0.12,
        "涨跌幅": 10.91,
        "开盘价": 1.09,
        "最高价": 1.24,
        "最低价": 1.06,
        "昨收价": 1.1,
        "总市值": 192838649.0,
        "市盈率": -0.79,
        "成交量": 4375317.0,
        "成交额": 5082212.0,
        "振幅": 16.36,
        "换手率": 2.77,
        "symbol": "105.EOSE"
    },
    {
        "序号": 178,
        "name": "派拉蒙全球-A",
        "最新价": 20.75,
        "涨跌额": 2.04,
        "涨跌幅": 10.9,
        "开盘价": 19.25,
        "最高价": 21.17,
        "最低价": 19.25,
        "昨收价": 18.71,
        "总市值": 13516682551.0,
        "市盈率": -12.28,
        "成交量": 112152.0,
        "成交额": 2319757.0,
        "振幅": 10.26,
        "换手率": 0.28,
        "symbol": "105.PARAA"
    },
    {
        "序号": 179,
        "name": "FiscalNote Holdings Inc-A",
        "最新价": 1.33,
        "涨跌额": 0.13,
        "涨跌幅": 10.83,
        "开盘价": 1.18,
        "最高价": 1.42,
        "最低价": 1.16,
        "昨收价": 1.2,
        "总市值": 171879389.0,
        "市盈率": -1.6,
        "成交量": 1305971.0,
        "成交额": 1734961.0,
        "振幅": 21.67,
        "换手率": 1.01,
        "symbol": "106.NOTE"
    },
    {
        "序号": 180,
        "name": "Cadrenal Therapeutics Inc",
        "最新价": 0.52,
        "涨跌额": 0.05,
        "涨跌幅": 10.64,
        "开盘价": 0.5,
        "最高价": 0.56,
        "最低价": 0.47,
        "昨收价": 0.47,
        "总市值": 6771832.0,
        "市盈率": null,
        "成交量": 40376.0,
        "成交额": 20948.0,
        "振幅": 19.15,
        "换手率": 0.31,
        "symbol": "105.CVKD"
    },
    {
        "序号": 181,
        "name": "比特矿业",
        "最新价": 4.09,
        "涨跌额": 0.39,
        "涨跌幅": 10.54,
        "开盘价": 3.78,
        "最高价": 4.09,
        "最低价": 3.65,
        "昨收价": 3.7,
        "总市值": 45449401.0,
        "市盈率": -0.36,
        "成交量": 168838.0,
        "成交额": 655791.0,
        "振幅": 11.89,
        "换手率": 1.52,
        "symbol": "106.BTCM"
    },
    {
        "序号": 182,
        "name": "Repare Therapeutics Inc",
        "最新价": 6.35,
        "涨跌额": 0.6,
        "涨跌幅": 10.43,
        "开盘价": 5.71,
        "最高价": 6.53,
        "最低价": 5.48,
        "昨收价": 5.75,
        "总市值": 267523284.0,
        "市盈率": -2.75,
        "成交量": 342621.0,
        "成交额": 2150691.0,
        "振幅": 18.26,
        "换手率": 0.81,
        "symbol": "105.RPTX"
    },
    {
        "序号": 183,
        "name": "Dolphin Entertainment Inc",
        "最新价": 1.8,
        "涨跌额": 0.17,
        "涨跌幅": 10.43,
        "开盘价": 1.65,
        "最高价": 1.8,
        "最低价": 1.63,
        "昨收价": 1.63,
        "总市值": 32654419.0,
        "市盈率": -1.95,
        "成交量": 50523.0,
        "成交额": 86540.0,
        "振幅": 10.43,
        "换手率": 0.28,
        "symbol": "105.DLPN"
    },
    {
        "序号": 184,
        "name": "乐居",
        "最新价": 1.59,
        "涨跌额": 0.15,
        "涨跌幅": 10.42,
        "开盘价": 1.62,
        "最高价": 1.64,
        "最低价": 1.42,
        "昨收价": 1.44,
        "总市值": 21916441.0,
        "市盈率": -0.39,
        "成交量": 53986.0,
        "成交额": 81538.0,
        "振幅": 15.28,
        "换手率": 0.39,
        "symbol": "106.LEJU"
    },
    {
        "序号": 185,
        "name": "NGM Biopharmaceuticals Inc",
        "最新价": 0.86,
        "涨跌额": 0.08,
        "涨跌幅": 10.26,
        "开盘价": 0.81,
        "最高价": 0.88,
        "最低价": 0.79,
        "昨收价": 0.78,
        "总市值": 71135336.0,
        "市盈率": -0.47,
        "成交量": 181713.0,
        "成交额": 151841.0,
        "振幅": 11.54,
        "换手率": 0.22,
        "symbol": "105.NGM"
    },
    {
        "序号": 186,
        "name": "MicroAlgo Inc",
        "最新价": 2.26,
        "涨跌额": 0.21,
        "涨跌幅": 10.24,
        "开盘价": 2.5,
        "最高价": 2.75,
        "最低价": 2.08,
        "昨收价": 2.05,
        "总市值": 99116156.0,
        "市盈率": -6.92,
        "成交量": 38000519.0,
        "成交额": 90287238.0,
        "振幅": 32.68,
        "换手率": 86.65,
        "symbol": "105.MLGO"
    },
    {
        "序号": 187,
        "name": "Surf Air Mobility Inc",
        "最新价": 0.76,
        "涨跌额": 0.07,
        "涨跌幅": 10.14,
        "开盘价": 0.76,
        "最高价": 0.78,
        "最低价": 0.65,
        "昨收价": 0.69,
        "总市值": 55956492.0,
        "市盈率": -0.34,
        "成交量": 272714.0,
        "成交额": 198778.0,
        "振幅": 18.84,
        "换手率": 0.37,
        "symbol": "106.SRFM"
    },
    {
        "序号": 188,
        "name": "Modular Medical Inc",
        "最新价": 1.74,
        "涨跌额": 0.16,
        "涨跌幅": 10.13,
        "开盘价": 1.59,
        "最高价": 1.92,
        "最低价": 1.52,
        "昨收价": 1.58,
        "总市值": 36935373.0,
        "市盈率": -2.49,
        "成交量": 86000.0,
        "成交额": 146261.0,
        "振幅": 25.32,
        "换手率": 0.41,
        "symbol": "105.MODD"
    },
    {
        "序号": 189,
        "name": "科恩",
        "最新价": 2.84,
        "涨跌额": 0.26,
        "涨跌幅": 10.08,
        "开盘价": 2.59,
        "最高价": 2.93,
        "最低价": 2.56,
        "昨收价": 2.58,
        "总市值": 69123933.0,
        "市盈率": -0.51,
        "成交量": 152795.0,
        "成交额": 423512.0,
        "振幅": 14.34,
        "换手率": 0.63,
        "symbol": "105.CONN"
    },
    {
        "序号": 190,
        "name": "Value Line Inc",
        "最新价": 52.89,
        "涨跌额": 4.82,
        "涨跌幅": 10.03,
        "开盘价": 46.8,
        "最高价": 52.89,
        "最低价": 46.8,
        "昨收价": 48.07,
        "总市值": 498758835.0,
        "市盈率": 27.0,
        "成交量": 2274.0,
        "成交额": 113526.0,
        "振幅": 12.67,
        "换手率": 0.02,
        "symbol": "105.VALU"
    },
    {
        "序号": 191,
        "name": "Ross Acquisition Corp II Wt",
        "最新价": 0.11,
        "涨跌额": 0.01,
        "涨跌幅": 10.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.11,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 192.0,
        "成交额": 20.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.ROSS_WS"
    },
    {
        "序号": 192,
        "name": "Gores Holdings IX Inc Wt",
        "最新价": 0.11,
        "涨跌额": 0.01,
        "涨跌幅": 10.0,
        "开盘价": 0.1,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 48624.0,
        "成交额": 5117.0,
        "振幅": 10.0,
        "换手率": null,
        "symbol": "105.GHIXW"
    },
    {
        "序号": 193,
        "name": "Prospector Capital Corp Unit Co",
        "最新价": 10.84,
        "涨跌额": 0.98,
        "涨跌幅": 9.94,
        "开盘价": 8.88,
        "最高价": 10.92,
        "最低价": 8.41,
        "昨收价": 9.86,
        "总市值": 111858567.0,
        "市盈率": -75.9,
        "成交量": 13324.0,
        "成交额": 135131.0,
        "振幅": 25.46,
        "换手率": null,
        "symbol": "105.PRSRU"
    },
    {
        "序号": 194,
        "name": "PepGen Inc",
        "最新价": 6.97,
        "涨跌额": 0.63,
        "涨跌幅": 9.94,
        "开盘价": 6.44,
        "最高价": 7.0,
        "最低价": 6.43,
        "昨收价": 6.34,
        "总市值": 165980423.0,
        "市盈率": -2.24,
        "成交量": 37790.0,
        "成交额": 260470.0,
        "振幅": 8.99,
        "换手率": 0.16,
        "symbol": "105.PEPG"
    },
    {
        "序号": 195,
        "name": "SiTime Corp",
        "最新价": 114.81,
        "涨跌额": 10.31,
        "涨跌幅": 9.87,
        "开盘价": 104.23,
        "最高价": 115.22,
        "最低价": 104.23,
        "昨收价": 104.5,
        "总市值": 2578220088.0,
        "市盈率": -41.54,
        "成交量": 237513.0,
        "成交额": 26777881.0,
        "振幅": 10.52,
        "换手率": 1.06,
        "symbol": "105.SITM"
    },
    {
        "序号": 196,
        "name": "PureCycle Technologies Inc Unit",
        "最新价": 5.82,
        "涨跌额": 0.52,
        "涨跌幅": 9.81,
        "开盘价": 5.82,
        "最高价": 5.82,
        "最低价": 5.82,
        "昨收价": 5.3,
        "总市值": 954825190.0,
        "市盈率": -10.99,
        "成交量": 133.0,
        "成交额": 774.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PCTTU"
    },
    {
        "序号": 197,
        "name": "伟亚光电",
        "最新价": 0.9,
        "涨跌额": 0.08,
        "涨跌幅": 9.76,
        "开盘价": 0.9,
        "最高价": 0.9,
        "最低价": 0.9,
        "昨收价": 0.82,
        "总市值": 20388155.0,
        "市盈率": -1.93,
        "成交量": 1056.0,
        "成交额": 950.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.VIAO"
    },
    {
        "序号": 198,
        "name": "Hanryu Holdings Inc",
        "最新价": 0.45,
        "涨跌额": 0.04,
        "涨跌幅": 9.76,
        "开盘价": 0.42,
        "最高价": 0.46,
        "最低价": 0.41,
        "昨收价": 0.41,
        "总市值": 23763865.0,
        "市盈率": -2.27,
        "成交量": 411324.0,
        "成交额": 178149.0,
        "振幅": 12.2,
        "换手率": 0.78,
        "symbol": "105.HRYU"
    },
    {
        "序号": 199,
        "name": "enGene Holdings Inc Wt",
        "最新价": 0.79,
        "涨跌额": 0.07,
        "涨跌幅": 9.72,
        "开盘价": 0.72,
        "最高价": 0.83,
        "最低价": 0.72,
        "昨收价": 0.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 42364.0,
        "成交额": 32078.0,
        "振幅": 15.28,
        "换手率": null,
        "symbol": "105.ENGNW"
    },
    {
        "序号": 200,
        "name": "天成金汇",
        "最新价": 1.48,
        "涨跌额": 0.13,
        "涨跌幅": 9.63,
        "开盘价": 1.26,
        "最高价": 1.48,
        "最低价": 1.26,
        "昨收价": 1.35,
        "总市值": 21830000.0,
        "市盈率": -28.3,
        "成交量": 205271.0,
        "成交额": 289942.0,
        "振幅": 16.3,
        "换手率": 1.39,
        "symbol": "105.TCJH"
    },
    {
        "序号": 201,
        "name": "挚文集团",
        "最新价": 7.2,
        "涨跌额": 0.63,
        "涨跌幅": 9.59,
        "开盘价": 7.3,
        "最高价": 7.68,
        "最低价": 6.86,
        "昨收价": 6.57,
        "总市值": 1364572771.0,
        "市盈率": 5.06,
        "成交量": 4684646.0,
        "成交额": 34457067.0,
        "振幅": 12.48,
        "换手率": 2.47,
        "symbol": "105.MOMO"
    },
    {
        "序号": 202,
        "name": "禧图集团",
        "最新价": 1.49,
        "涨跌额": 0.13,
        "涨跌幅": 9.56,
        "开盘价": 1.3,
        "最高价": 1.5,
        "最低价": 1.3,
        "昨收价": 1.36,
        "总市值": 16762500.0,
        "市盈率": -47.34,
        "成交量": 87594.0,
        "成交额": 123888.0,
        "振幅": 14.71,
        "换手率": 0.78,
        "symbol": "105.MGIH"
    },
    {
        "序号": 203,
        "name": "Ambac Financial Group Inc",
        "最新价": 16.42,
        "涨跌额": 1.43,
        "涨跌幅": 9.54,
        "开盘价": 15.96,
        "最高价": 16.6,
        "最低价": 15.61,
        "昨收价": 14.99,
        "总市值": 742094298.0,
        "市盈率": 3.83,
        "成交量": 1393186.0,
        "成交额": 22630617.0,
        "振幅": 6.6,
        "换手率": 3.08,
        "symbol": "106.AMBC"
    },
    {
        "序号": 204,
        "name": "Monogram Orthopaedics Inc",
        "最新价": 3.47,
        "涨跌额": 0.3,
        "涨跌幅": 9.46,
        "开盘价": 3.18,
        "最高价": 3.5,
        "最低价": 3.18,
        "昨收价": 3.17,
        "总市值": 108026596.0,
        "市盈率": -8.18,
        "成交量": 51049.0,
        "成交额": 171454.0,
        "振幅": 10.09,
        "换手率": 0.16,
        "symbol": "105.MGRM"
    },
    {
        "序号": 205,
        "name": "Kartoon Studios Inc",
        "最新价": 1.62,
        "涨跌额": 0.14,
        "涨跌幅": 9.46,
        "开盘价": 1.47,
        "最高价": 1.63,
        "最低价": 1.46,
        "昨收价": 1.48,
        "总市值": 57006379.0,
        "市盈率": -0.79,
        "成交量": 165327.0,
        "成交额": 255307.0,
        "振幅": 11.49,
        "换手率": 0.47,
        "symbol": "107.TOON"
    },
    {
        "序号": 206,
        "name": "BuzzFeed Inc-A",
        "最新价": 0.35,
        "涨跌额": 0.03,
        "涨跌幅": 9.38,
        "开盘价": 0.32,
        "最高价": 0.36,
        "最低价": 0.32,
        "昨收价": 0.32,
        "总市值": 50716414.0,
        "市盈率": -0.28,
        "成交量": 1035438.0,
        "成交额": 356699.0,
        "振幅": 12.5,
        "换手率": 0.71,
        "symbol": "105.BZFD"
    },
    {
        "序号": 207,
        "name": "American Rebel Holdings Inc",
        "最新价": 0.35,
        "涨跌额": 0.03,
        "涨跌幅": 9.38,
        "开盘价": 0.33,
        "最高价": 0.38,
        "最低价": 0.31,
        "昨收价": 0.32,
        "总市值": 1756972.0,
        "市盈率": -0.4,
        "成交量": 436584.0,
        "成交额": 151078.0,
        "振幅": 21.88,
        "换手率": 8.7,
        "symbol": "105.AREB"
    },
    {
        "序号": 208,
        "name": "Frontier Investment Corp Unit C",
        "最新价": 11.7,
        "涨跌额": 1.0,
        "涨跌幅": 9.35,
        "开盘价": 11.39,
        "最高价": 12.9,
        "最低价": 11.39,
        "昨收价": 10.7,
        "总市值": 90484243.0,
        "市盈率": 18.33,
        "成交量": 11583.0,
        "成交额": 138841.0,
        "振幅": 14.11,
        "换手率": null,
        "symbol": "105.FICVU"
    },
    {
        "序号": 209,
        "name": "IES Holdings Inc",
        "最新价": 81.61,
        "涨跌额": 6.91,
        "涨跌幅": 9.25,
        "开盘价": 75.25,
        "最高价": 81.62,
        "最低价": 75.25,
        "昨收价": 74.7,
        "总市值": 1648050131.0,
        "市盈率": 15.22,
        "成交量": 81826.0,
        "成交额": 6518749.0,
        "振幅": 8.53,
        "换手率": 0.41,
        "symbol": "105.IESC"
    },
    {
        "序号": 210,
        "name": "Clean Energy Technologies Inc",
        "最新价": 1.54,
        "涨跌额": 0.13,
        "涨跌幅": 9.22,
        "开盘价": 1.38,
        "最高价": 1.57,
        "最低价": 1.38,
        "昨收价": 1.41,
        "总市值": 60012191.0,
        "市盈率": -56.43,
        "成交量": 6085.0,
        "成交额": 8965.0,
        "振幅": 13.48,
        "换手率": 0.02,
        "symbol": "105.CETY"
    },
    {
        "序号": 211,
        "name": "Metals Acquisition Ltd Wt",
        "最新价": 1.43,
        "涨跌额": 0.12,
        "涨跌幅": 9.16,
        "开盘价": 1.3,
        "最高价": 1.43,
        "最低价": 1.3,
        "昨收价": 1.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 211.0,
        "成交额": 288.0,
        "振幅": 9.92,
        "换手率": null,
        "symbol": "106.MTAL_WS"
    },
    {
        "序号": 212,
        "name": "TH International Ltd-A",
        "最新价": 2.03,
        "涨跌额": 0.17,
        "涨跌幅": 9.14,
        "开盘价": 1.83,
        "最高价": 2.3,
        "最低价": 1.71,
        "昨收价": 1.86,
        "总市值": 319779956.0,
        "市盈率": -2.85,
        "成交量": 722375.0,
        "成交额": 1468036.0,
        "振幅": 31.72,
        "换手率": 0.46,
        "symbol": "105.THCH"
    },
    {
        "序号": 213,
        "name": "Valkyrie Bitcoin Miners ETF",
        "最新价": 14.75,
        "涨跌额": 1.23,
        "涨跌幅": 9.1,
        "开盘价": 13.71,
        "最高价": 14.77,
        "最低价": 13.62,
        "昨收价": 13.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 514700.0,
        "成交额": 7431139.0,
        "振幅": 8.51,
        "换手率": null,
        "symbol": "105.WGMI"
    },
    {
        "序号": 214,
        "name": "玩美(权证)",
        "最新价": 0.12,
        "涨跌额": 0.01,
        "涨跌幅": 9.09,
        "开盘价": 0.08,
        "最高价": 0.12,
        "最低价": 0.06,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 48884.0,
        "成交额": 3361.0,
        "振幅": 54.55,
        "换手率": null,
        "symbol": "106.PERF_WS"
    },
    {
        "序号": 215,
        "name": "Inotiv Inc",
        "最新价": 3.38,
        "涨跌额": 0.28,
        "涨跌幅": 9.03,
        "开盘价": 3.17,
        "最高价": 3.38,
        "最低价": 3.06,
        "昨收价": 3.1,
        "总市值": 87145668.0,
        "市盈率": -0.26,
        "成交量": 208078.0,
        "成交额": 680585.0,
        "振幅": 10.32,
        "换手率": 0.81,
        "symbol": "105.NOTV"
    },
    {
        "序号": 216,
        "name": "Tellurian Inc Notes",
        "最新价": 9.67,
        "涨跌额": 0.8,
        "涨跌幅": 9.02,
        "开盘价": 8.87,
        "最高价": 9.67,
        "最低价": 8.87,
        "昨收价": 8.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 15544.0,
        "成交额": 143233.0,
        "振幅": 9.02,
        "换手率": null,
        "symbol": "107.TELZ"
    },
    {
        "序号": 217,
        "name": "Newcourt Acquisition Corp-A",
        "最新价": 11.0,
        "涨跌额": 0.91,
        "涨跌幅": 9.02,
        "开盘价": 10.55,
        "最高价": 11.0,
        "最低价": 10.08,
        "昨收价": 10.09,
        "总市值": 96668231.0,
        "市盈率": 63.79,
        "成交量": 2326.0,
        "成交额": 24147.0,
        "振幅": 9.12,
        "换手率": 0.03,
        "symbol": "105.NCAC"
    },
    {
        "序号": 218,
        "name": "Upstart Holdings Inc",
        "最新价": 36.92,
        "涨跌额": 3.05,
        "涨跌幅": 9.01,
        "开盘价": 33.66,
        "最高价": 37.48,
        "最低价": 33.36,
        "昨收价": 33.87,
        "总市值": 3140316144.0,
        "市盈率": -12.41,
        "成交量": 13751072.0,
        "成交额": 494902304.0,
        "振幅": 12.16,
        "换手率": 16.17,
        "symbol": "105.UPST"
    },
    {
        "序号": 219,
        "name": "JAKKS Pacific Inc",
        "最新价": 32.96,
        "涨跌额": 2.71,
        "涨跌幅": 8.96,
        "开盘价": 30.35,
        "最高价": 33.14,
        "最低价": 30.35,
        "昨收价": 30.25,
        "总市值": 332014781.0,
        "市盈率": 3.81,
        "成交量": 448428.0,
        "成交额": 14429306.0,
        "振幅": 9.22,
        "换手率": 4.45,
        "symbol": "105.JAKK"
    },
    {
        "序号": 220,
        "name": "Ocean Biomedical Inc",
        "最新价": 0.61,
        "涨跌额": 0.05,
        "涨跌幅": 8.93,
        "开盘价": 0.57,
        "最高价": 0.62,
        "最低价": 0.56,
        "昨收价": 0.56,
        "总市值": 20784991.0,
        "市盈率": -0.3,
        "成交量": 241020.0,
        "成交额": 142966.0,
        "振幅": 10.71,
        "换手率": 0.71,
        "symbol": "105.OCEA"
    },
    {
        "序号": 221,
        "name": "BowFlex Inc",
        "最新价": 0.61,
        "涨跌额": 0.05,
        "涨跌幅": 8.93,
        "开盘价": 0.55,
        "最高价": 0.62,
        "最低价": 0.55,
        "昨收价": 0.56,
        "总市值": 22180531.0,
        "市盈率": -0.45,
        "成交量": 151794.0,
        "成交额": 89236.0,
        "振幅": 12.5,
        "换手率": 0.42,
        "symbol": "106.BFX"
    },
    {
        "序号": 222,
        "name": "Paramount Global Series A Pfd",
        "最新价": 22.64,
        "涨跌额": 1.85,
        "涨跌幅": 8.9,
        "开盘价": 21.16,
        "最高价": 22.88,
        "最低价": 20.92,
        "昨收价": 20.79,
        "总市值": 226400000.0,
        "市盈率": null,
        "成交量": 112777.0,
        "成交额": 2536698.0,
        "振幅": 9.43,
        "换手率": 1.13,
        "symbol": "105.PARAP"
    },
    {
        "序号": 223,
        "name": "NewAmsterdam Pharma Co NV",
        "最新价": 9.8,
        "涨跌额": 0.8,
        "涨跌幅": 8.89,
        "开盘价": 9.0,
        "最高价": 9.8,
        "最低价": 8.9,
        "昨收价": 9.0,
        "总市值": 808203726.0,
        "市盈率": -4.89,
        "成交量": 30706.0,
        "成交额": 283356.0,
        "振幅": 10.0,
        "换手率": 0.04,
        "symbol": "105.NAMS"
    },
    {
        "序号": 224,
        "name": "Oragenics Inc",
        "最新价": 4.91,
        "涨跌额": 0.4,
        "涨跌幅": 8.87,
        "开盘价": 4.45,
        "最高价": 4.99,
        "最低价": 4.45,
        "昨收价": 4.51,
        "总市值": 12615680.0,
        "市盈率": -1.24,
        "成交量": 31473.0,
        "成交额": 151497.0,
        "振幅": 11.97,
        "换手率": 1.22,
        "symbol": "107.OGEN"
    },
    {
        "序号": 225,
        "name": "Nvni Group Ltd",
        "最新价": 1.6,
        "涨跌额": 0.13,
        "涨跌幅": 8.84,
        "开盘价": 1.7,
        "最高价": 1.7,
        "最低价": 1.52,
        "昨收价": 1.47,
        "总市值": 53680581.0,
        "市盈率": 55.93,
        "成交量": 8829.0,
        "成交额": 14235.0,
        "振幅": 12.24,
        "换手率": 0.03,
        "symbol": "105.NVNI"
    },
    {
        "序号": 226,
        "name": "Evelo Biosciences Inc",
        "最新价": 0.37,
        "涨跌额": 0.03,
        "涨跌幅": 8.82,
        "开盘价": 0.36,
        "最高价": 0.37,
        "最低价": 0.34,
        "昨收价": 0.34,
        "总市值": 7004455.0,
        "市盈率": -0.09,
        "成交量": 249528.0,
        "成交额": 88131.0,
        "振幅": 8.82,
        "换手率": 1.32,
        "symbol": "105.EVLO"
    },
    {
        "序号": 227,
        "name": "趣活",
        "最新价": 1.73,
        "涨跌额": 0.14,
        "涨跌幅": 8.81,
        "开盘价": 1.58,
        "最高价": 1.75,
        "最低价": 1.5,
        "昨收价": 1.59,
        "总市值": 9503978.0,
        "市盈率": 19.1,
        "成交量": 38371.0,
        "成交额": 63553.0,
        "振幅": 15.72,
        "换手率": 0.7,
        "symbol": "105.QH"
    },
    {
        "序号": 228,
        "name": "Purple Innovation Inc-A",
        "最新价": 1.25,
        "涨跌额": 0.1,
        "涨跌幅": 8.7,
        "开盘价": 1.16,
        "最高价": 1.33,
        "最低价": 1.16,
        "昨收价": 1.15,
        "总市值": 132188609.0,
        "市盈率": -0.77,
        "成交量": 2007728.0,
        "成交额": 2513103.0,
        "振幅": 14.78,
        "换手率": 1.9,
        "symbol": "105.PRPL"
    },
    {
        "序号": 229,
        "name": "恩德拉生命科学",
        "最新价": 1.5,
        "涨跌额": 0.12,
        "涨跌幅": 8.7,
        "开盘价": 1.39,
        "最高价": 1.56,
        "最低价": 1.37,
        "昨收价": 1.38,
        "总市值": 12682383.0,
        "市盈率": -1.07,
        "成交量": 164653.0,
        "成交额": 243633.0,
        "振幅": 13.77,
        "换手率": 1.95,
        "symbol": "105.NDRA"
    },
    {
        "序号": 230,
        "name": "C3.ai Inc-A",
        "最新价": 28.27,
        "涨跌额": 2.25,
        "涨跌幅": 8.65,
        "开盘价": 26.12,
        "最高价": 28.62,
        "最低价": 25.91,
        "昨收价": 26.02,
        "总市值": 3388589119.0,
        "市盈率": -12.92,
        "成交量": 16088380.0,
        "成交额": 445995232.0,
        "振幅": 10.42,
        "换手率": 13.42,
        "symbol": "106.AI"
    },
    {
        "序号": 231,
        "name": "Ondas Holdings Inc",
        "最新价": 1.39,
        "涨跌额": 0.11,
        "涨跌幅": 8.59,
        "开盘价": 1.25,
        "最高价": 1.39,
        "最低价": 1.25,
        "昨收价": 1.28,
        "总市值": 81052731.0,
        "市盈率": -1.17,
        "成交量": 488694.0,
        "成交额": 651440.0,
        "振幅": 10.94,
        "换手率": 0.84,
        "symbol": "105.ONDS"
    },
    {
        "序号": 232,
        "name": "U.S. GoldMining Inc",
        "最新价": 7.6,
        "涨跌额": 0.6,
        "涨跌幅": 8.57,
        "开盘价": 6.98,
        "最高价": 7.75,
        "最低价": 6.9,
        "昨收价": 7.0,
        "总市值": 94230188.0,
        "市盈率": -14.44,
        "成交量": 8563.0,
        "成交额": 61939.0,
        "振幅": 12.14,
        "换手率": 0.07,
        "symbol": "105.USGO"
    },
    {
        "序号": 233,
        "name": "Ikena Oncology Inc",
        "最新价": 1.9,
        "涨跌额": 0.15,
        "涨跌幅": 8.57,
        "开盘价": 1.72,
        "最高价": 1.97,
        "最低价": 1.68,
        "昨收价": 1.75,
        "总市值": 91690411.0,
        "市盈率": -1.46,
        "成交量": 152515.0,
        "成交额": 290391.0,
        "振幅": 16.57,
        "换手率": 0.32,
        "symbol": "105.IKNA"
    },
    {
        "序号": 234,
        "name": "Gravitas Education Holdings Inc",
        "最新价": 11.4,
        "涨跌额": 0.9,
        "涨跌幅": 8.57,
        "开盘价": 10.8,
        "最高价": 11.89,
        "最低价": 10.3,
        "昨收价": 10.5,
        "总市值": 23979282.0,
        "市盈率": -0.33,
        "成交量": 24556.0,
        "成交额": 264707.0,
        "振幅": 15.14,
        "换手率": 1.17,
        "symbol": "106.GEHI"
    },
    {
        "序号": 235,
        "name": "格雷电视",
        "最新价": 8.54,
        "涨跌额": 0.67,
        "涨跌幅": 8.51,
        "开盘价": 7.88,
        "最高价": 8.58,
        "最低价": 7.75,
        "昨收价": 7.87,
        "总市值": 815184231.0,
        "市盈率": 6.85,
        "成交量": 1723797.0,
        "成交额": 14400978.0,
        "振幅": 10.55,
        "换手率": 1.98,
        "symbol": "106.GTN"
    },
    {
        "序号": 236,
        "name": "Hut 8 Corp",
        "最新价": 10.25,
        "涨跌额": 0.8,
        "涨跌幅": 8.47,
        "开盘价": 9.51,
        "最高价": 10.37,
        "最低价": 9.4,
        "昨收价": 9.45,
        "总市值": 907125000.0,
        "市盈率": -8.29,
        "成交量": 6155132.0,
        "成交额": 61623443.0,
        "振幅": 10.26,
        "换手率": 6.95,
        "symbol": "105.HUT"
    },
    {
        "序号": 237,
        "name": "DZS Inc",
        "最新价": 1.41,
        "涨跌额": 0.11,
        "涨跌幅": 8.46,
        "开盘价": 1.31,
        "最高价": 1.44,
        "最低价": 1.29,
        "昨收价": 1.3,
        "总市值": 43935287.0,
        "市盈率": -0.85,
        "成交量": 534833.0,
        "成交额": 714790.0,
        "振幅": 11.54,
        "换手率": 1.72,
        "symbol": "105.DZSI"
    },
    {
        "序号": 238,
        "name": "Global X Blockchain ETF",
        "最新价": 40.15,
        "涨跌额": 3.11,
        "涨跌幅": 8.4,
        "开盘价": 37.29,
        "最高价": 40.15,
        "最低价": 37.29,
        "昨收价": 37.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 82166.0,
        "成交额": 3225867.0,
        "振幅": 7.72,
        "换手率": null,
        "symbol": "105.BKCH"
    },
    {
        "序号": 239,
        "name": "Anebulo Pharmaceuticals Inc",
        "最新价": 2.33,
        "涨跌额": 0.18,
        "涨跌幅": 8.37,
        "开盘价": 2.2,
        "最高价": 2.33,
        "最低价": 2.15,
        "昨收价": 2.15,
        "总市值": 59725396.0,
        "市盈率": -5.15,
        "成交量": 5849.0,
        "成交额": 13072.0,
        "振幅": 8.37,
        "换手率": 0.02,
        "symbol": "105.ANEB"
    },
    {
        "序号": 240,
        "name": "Starbox Group Holdings Ltd",
        "最新价": 0.13,
        "涨跌额": 0.01,
        "涨跌幅": 8.33,
        "开盘价": 0.12,
        "最高价": 0.13,
        "最低价": 0.11,
        "昨收价": 0.12,
        "总市值": 9345050.0,
        "市盈率": 2.52,
        "成交量": 1996693.0,
        "成交额": 247258.0,
        "振幅": 16.67,
        "换手率": 2.78,
        "symbol": "105.STBX"
    },
    {
        "序号": 241,
        "name": "MyMD Pharmaceuticals Inc",
        "最新价": 0.26,
        "涨跌额": 0.02,
        "涨跌幅": 8.33,
        "开盘价": 0.26,
        "最高价": 0.27,
        "最低价": 0.24,
        "昨收价": 0.24,
        "总市值": 13234186.0,
        "市盈率": -2.46,
        "成交量": 1582905.0,
        "成交额": 410560.0,
        "振幅": 12.5,
        "换手率": 3.11,
        "symbol": "105.MYMD"
    },
    {
        "序号": 242,
        "name": "Inseego Corp",
        "最新价": 0.26,
        "涨跌额": 0.02,
        "涨跌幅": 8.33,
        "开盘价": 0.25,
        "最高价": 0.26,
        "最低价": 0.24,
        "昨收价": 0.24,
        "总市值": 30428242.0,
        "市盈率": -0.65,
        "成交量": 574077.0,
        "成交额": 143178.0,
        "振幅": 8.33,
        "换手率": 0.49,
        "symbol": "105.INSG"
    },
    {
        "序号": 243,
        "name": "ESH Acquisition Corp Rt",
        "最新价": 0.13,
        "涨跌额": 0.01,
        "涨跌幅": 8.33,
        "开盘价": 0.13,
        "最高价": 0.13,
        "最低价": 0.13,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 277481.0,
        "成交额": 36082.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ESHAR"
    },
    {
        "序号": 244,
        "name": "Sight Sciences Inc",
        "最新价": 2.87,
        "涨跌额": 0.22,
        "涨跌幅": 8.3,
        "开盘价": 2.59,
        "最高价": 2.94,
        "最低价": 2.58,
        "昨收价": 2.65,
        "总市值": 139912144.0,
        "市盈率": -2.27,
        "成交量": 450825.0,
        "成交额": 1277888.0,
        "振幅": 13.58,
        "换手率": 0.92,
        "symbol": "105.SGHT"
    },
    {
        "序号": 245,
        "name": "第九城市",
        "最新价": 6.79,
        "涨跌额": 0.52,
        "涨跌幅": 8.29,
        "开盘价": 6.15,
        "最高价": 7.23,
        "最低价": 6.1,
        "昨收价": 6.27,
        "总市值": 21313846.0,
        "市盈率": -0.15,
        "成交量": 114792.0,
        "成交额": 777057.0,
        "振幅": 18.02,
        "换手率": 3.66,
        "symbol": "105.NCTY"
    },
    {
        "序号": 246,
        "name": "COMPASS Pathways plc ADR",
        "最新价": 6.47,
        "涨跌额": 0.49,
        "涨跌幅": 8.19,
        "开盘价": 6.01,
        "最高价": 6.82,
        "最低价": 5.75,
        "昨收价": 5.98,
        "总市值": 400470581.0,
        "市盈率": -3.43,
        "成交量": 1168763.0,
        "成交额": 7546628.0,
        "振幅": 17.89,
        "换手率": 1.89,
        "symbol": "105.CMPS"
    },
    {
        "序号": 247,
        "name": "Marathon Digital Holdings Inc",
        "最新价": 16.78,
        "涨跌额": 1.27,
        "涨跌幅": 8.19,
        "开盘价": 15.6,
        "最高价": 16.79,
        "最低价": 15.56,
        "昨收价": 15.51,
        "总市值": 3735648557.0,
        "市盈率": -10.78,
        "成交量": 53071215.0,
        "成交额": 870968032.0,
        "振幅": 7.93,
        "换手率": 23.84,
        "symbol": "105.MARA"
    },
    {
        "序号": 248,
        "name": "Hepion Pharmaceuticals Inc",
        "最新价": 3.31,
        "涨跌额": 0.25,
        "涨跌幅": 8.17,
        "开盘价": 3.0,
        "最高价": 3.38,
        "最低价": 2.96,
        "昨收价": 3.06,
        "总市值": 14357727.0,
        "市盈率": -0.32,
        "成交量": 99621.0,
        "成交额": 319437.0,
        "振幅": 13.73,
        "换手率": 2.3,
        "symbol": "105.HEPA"
    },
    {
        "序号": 249,
        "name": "a.k.a. Brands Holding Corp",
        "最新价": 10.6,
        "涨跌额": 0.8,
        "涨跌幅": 8.16,
        "开盘价": 9.66,
        "最高价": 10.87,
        "最低价": 9.43,
        "昨收价": 9.8,
        "总市值": 112648426.0,
        "市盈率": -0.44,
        "成交量": 23162.0,
        "成交额": 238585.0,
        "振幅": 14.69,
        "换手率": 0.22,
        "symbol": "106.AKA"
    },
    {
        "序号": 250,
        "name": "维捷",
        "最新价": 1.46,
        "涨跌额": 0.11,
        "涨跌幅": 8.15,
        "开盘价": 1.34,
        "最高价": 1.49,
        "最低价": 1.34,
        "昨收价": 1.35,
        "总市值": 13336697.0,
        "市盈率": -1.15,
        "成交量": 7386.0,
        "成交额": 10511.0,
        "振幅": 11.11,
        "换手率": 0.08,
        "symbol": "105.VJET"
    },
    {
        "序号": 251,
        "name": "Quantum FinTech Acquisition Cor",
        "最新价": 8.9,
        "涨跌额": 0.67,
        "涨跌幅": 8.14,
        "开盘价": 8.81,
        "最高价": 9.3,
        "最低价": 8.14,
        "昨收价": 8.23,
        "总市值": 89726543.0,
        "市盈率": 128.38,
        "成交量": 14893.0,
        "成交额": 129121.0,
        "振幅": 14.09,
        "换手率": 0.15,
        "symbol": "107.QFTA"
    },
    {
        "序号": 252,
        "name": "5E Advanced Materials Inc",
        "最新价": 1.6,
        "涨跌额": 0.12,
        "涨跌幅": 8.11,
        "开盘价": 1.54,
        "最高价": 1.61,
        "最低价": 1.5,
        "昨收价": 1.48,
        "总市值": 70779286.0,
        "市盈率": -1.59,
        "成交量": 116813.0,
        "成交额": 183742.0,
        "振幅": 7.43,
        "换手率": 0.26,
        "symbol": "105.FEAM"
    },
    {
        "序号": 253,
        "name": "斗鱼",
        "最新价": 0.8,
        "涨跌额": 0.06,
        "涨跌幅": 8.11,
        "开盘价": 0.74,
        "最高价": 0.81,
        "最低价": 0.74,
        "昨收价": 0.74,
        "总市值": 255821320.0,
        "市盈率": 12.93,
        "成交量": 1316947.0,
        "成交额": 1041952.0,
        "振幅": 9.46,
        "换手率": 0.41,
        "symbol": "105.DOYU"
    },
    {
        "序号": 254,
        "name": "Hydrofarm Holdings Group Inc",
        "最新价": 1.07,
        "涨跌额": 0.08,
        "涨跌幅": 8.08,
        "开盘价": 0.96,
        "最高价": 1.08,
        "最低价": 0.95,
        "昨收价": 0.99,
        "总市值": 48962745.0,
        "市盈率": -0.58,
        "成交量": 285824.0,
        "成交额": 303043.0,
        "振幅": 13.13,
        "换手率": 0.62,
        "symbol": "105.HYFM"
    },
    {
        "序号": 255,
        "name": "NextCure Inc",
        "最新价": 1.35,
        "涨跌额": 0.1,
        "涨跌幅": 8.0,
        "开盘价": 1.24,
        "最高价": 1.37,
        "最低价": 1.24,
        "昨收价": 1.25,
        "总市值": 37669086.0,
        "市盈率": -0.57,
        "成交量": 244593.0,
        "成交额": 321247.0,
        "振幅": 10.4,
        "换手率": 0.88,
        "symbol": "105.NXTC"
    },
    {
        "序号": 256,
        "name": "Auddia Inc",
        "最新价": 0.27,
        "涨跌额": 0.02,
        "涨跌幅": 8.0,
        "开盘价": 0.25,
        "最高价": 0.29,
        "最低价": 0.23,
        "昨收价": 0.25,
        "总市值": 5385750.0,
        "市盈率": -0.64,
        "成交量": 231842.0,
        "成交额": 63024.0,
        "振幅": 24.0,
        "换手率": 1.16,
        "symbol": "105.AUUD"
    },
    {
        "序号": 257,
        "name": "Bitdeer Technologies Group-A",
        "最新价": 6.23,
        "涨跌额": 0.46,
        "涨跌幅": 7.97,
        "开盘价": 5.97,
        "最高价": 6.45,
        "最低价": 5.63,
        "昨收价": 5.77,
        "总市值": 692955430.0,
        "市盈率": -13.29,
        "成交量": 1259783.0,
        "成交额": 7835596.0,
        "振幅": 14.21,
        "换手率": 1.13,
        "symbol": "105.BTDR"
    },
    {
        "序号": 258,
        "name": "PureCycle Technologies Inc",
        "最新价": 5.03,
        "涨跌额": 0.37,
        "涨跌幅": 7.94,
        "开盘价": 4.61,
        "最高价": 5.07,
        "最低价": 4.45,
        "昨收价": 4.66,
        "总市值": 825218334.0,
        "市盈率": -9.5,
        "成交量": 7096672.0,
        "成交额": 35090611.0,
        "振幅": 13.3,
        "换手率": 4.33,
        "symbol": "105.PCT"
    },
    {
        "序号": 259,
        "name": "Gain Therapeutics Inc",
        "最新价": 2.72,
        "涨跌额": 0.2,
        "涨跌幅": 7.94,
        "开盘价": 2.54,
        "最高价": 2.95,
        "最低价": 2.54,
        "昨收价": 2.52,
        "总市值": 47935849.0,
        "市盈率": -2.16,
        "成交量": 195398.0,
        "成交额": 539860.0,
        "振幅": 16.27,
        "换手率": 1.11,
        "symbol": "105.GANX"
    },
    {
        "序号": 260,
        "name": "Capricor Therapeutics Inc",
        "最新价": 3.69,
        "涨跌额": 0.27,
        "涨跌幅": 7.89,
        "开盘价": 3.4,
        "最高价": 3.7,
        "最低价": 3.38,
        "昨收价": 3.42,
        "总市值": 113625687.0,
        "市盈率": -3.89,
        "成交量": 241880.0,
        "成交额": 857523.0,
        "振幅": 9.36,
        "换手率": 0.79,
        "symbol": "105.CAPR"
    },
    {
        "序号": 261,
        "name": "Territorial Bancorp Inc",
        "最新价": 9.57,
        "涨跌额": 0.7,
        "涨跌幅": 7.89,
        "开盘价": 8.74,
        "最高价": 9.7,
        "最低价": 8.74,
        "昨收价": 8.87,
        "总市值": 84470686.0,
        "市盈率": 10.38,
        "成交量": 26902.0,
        "成交额": 252020.0,
        "振幅": 10.82,
        "换手率": 0.3,
        "symbol": "105.TBNK"
    },
    {
        "序号": 262,
        "name": "Amneal Pharmaceuticals Inc-A",
        "最新价": 5.06,
        "涨跌额": 0.37,
        "涨跌幅": 7.89,
        "开盘价": 4.74,
        "最高价": 5.25,
        "最低价": 4.71,
        "昨收价": 4.69,
        "总市值": 1551113647.0,
        "市盈率": null,
        "成交量": 2315420.0,
        "成交额": 11733036.0,
        "振幅": 11.51,
        "换手率": 0.76,
        "symbol": "106.AMRX"
    },
    {
        "序号": 263,
        "name": "坎伯兰药业",
        "最新价": 1.78,
        "涨跌额": 0.13,
        "涨跌幅": 7.88,
        "开盘价": 1.68,
        "最高价": 2.03,
        "最低价": 1.68,
        "昨收价": 1.65,
        "总市值": 25375146.0,
        "市盈率": -10.46,
        "成交量": 17645.0,
        "成交额": 31332.0,
        "振幅": 21.21,
        "换手率": 0.12,
        "symbol": "105.CPIX"
    },
    {
        "序号": 264,
        "name": "LightPath Technologies Inc-A",
        "最新价": 1.37,
        "涨跌额": 0.1,
        "涨跌幅": 7.87,
        "开盘价": 1.28,
        "最高价": 1.38,
        "最低价": 1.27,
        "昨收价": 1.27,
        "总市值": 51397741.0,
        "市盈率": -12.82,
        "成交量": 67924.0,
        "成交额": 91344.0,
        "振幅": 8.66,
        "换手率": 0.18,
        "symbol": "105.LPTH"
    },
    {
        "序号": 265,
        "name": "Trinity Biotech plc ADR",
        "最新价": 0.55,
        "涨跌额": 0.04,
        "涨跌幅": 7.84,
        "开盘价": 0.55,
        "最高价": 0.55,
        "最低价": 0.5,
        "昨收价": 0.51,
        "总市值": 21014164.0,
        "市盈率": -0.68,
        "成交量": 38204.0,
        "成交额": 19594.0,
        "振幅": 9.8,
        "换手率": 0.1,
        "symbol": "105.TRIB"
    },
    {
        "序号": 266,
        "name": "Reborn Coffee Inc",
        "最新价": 0.55,
        "涨跌额": 0.04,
        "涨跌幅": 7.84,
        "开盘价": 0.51,
        "最高价": 0.6,
        "最低价": 0.5,
        "昨收价": 0.51,
        "总市值": 7294719.0,
        "市盈率": -1.76,
        "成交量": 87725.0,
        "成交额": 50396.0,
        "振幅": 19.61,
        "换手率": 0.66,
        "symbol": "105.REBN"
    },
    {
        "序号": 267,
        "name": "Clear Channel Outdoor Holdings ",
        "最新价": 1.65,
        "涨跌额": 0.12,
        "涨跌幅": 7.84,
        "开盘价": 1.5,
        "最高价": 1.7,
        "最低价": 1.5,
        "昨收价": 1.53,
        "总市值": 796966200.0,
        "市盈率": -3.36,
        "成交量": 5246794.0,
        "成交额": 8532541.0,
        "振幅": 13.07,
        "换手率": 1.09,
        "symbol": "106.CCO"
    },
    {
        "序号": 268,
        "name": "幸福来",
        "最新价": 2.62,
        "涨跌额": 0.19,
        "涨跌幅": 7.82,
        "开盘价": 2.43,
        "最高价": 2.62,
        "最低价": 2.35,
        "昨收价": 2.43,
        "总市值": 19222757.0,
        "市盈率": -0.27,
        "成交量": 82105.0,
        "成交额": 203880.0,
        "振幅": 11.11,
        "换手率": 1.12,
        "symbol": "105.PAVS"
    },
    {
        "序号": 269,
        "name": "PetVivo Holdings Inc",
        "最新价": 0.97,
        "涨跌额": 0.07,
        "涨跌幅": 7.78,
        "开盘价": 0.97,
        "最高价": 0.99,
        "最低价": 0.81,
        "昨收价": 0.9,
        "总市值": 14034264.0,
        "市盈率": -1.25,
        "成交量": 10177.0,
        "成交额": 9171.0,
        "振幅": 20.0,
        "换手率": 0.07,
        "symbol": "105.PETV"
    },
    {
        "序号": 270,
        "name": "Vaccinex Inc",
        "最新价": 0.7,
        "涨跌额": 0.05,
        "涨跌幅": 7.69,
        "开盘价": 0.67,
        "最高价": 0.72,
        "最低价": 0.66,
        "昨收价": 0.65,
        "总市值": 8745993.0,
        "市盈率": -0.4,
        "成交量": 32137.0,
        "成交额": 22082.0,
        "振幅": 9.23,
        "换手率": 0.26,
        "symbol": "105.VCNX"
    },
    {
        "序号": 271,
        "name": "Cineverse Corp-A",
        "最新价": 1.26,
        "涨跌额": 0.09,
        "涨跌幅": 7.69,
        "开盘价": 1.17,
        "最高价": 1.27,
        "最低价": 1.17,
        "昨收价": 1.17,
        "总市值": 16207767.0,
        "市盈率": -8.22,
        "成交量": 64427.0,
        "成交额": 78325.0,
        "振幅": 8.55,
        "换手率": 0.5,
        "symbol": "105.CNVS"
    },
    {
        "序号": 272,
        "name": "BT Brands Inc Wt",
        "最新价": 0.14,
        "涨跌额": 0.01,
        "涨跌幅": 7.69,
        "开盘价": 0.09,
        "最高价": 0.14,
        "最低价": 0.08,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 10501.0,
        "成交额": 859.0,
        "振幅": 46.15,
        "换手率": null,
        "symbol": "105.BTBDW"
    },
    {
        "序号": 273,
        "name": "Archer Aviation Inc-A",
        "最新价": 6.73,
        "涨跌额": 0.48,
        "涨跌幅": 7.68,
        "开盘价": 6.22,
        "最高价": 6.74,
        "最低价": 6.16,
        "昨收价": 6.25,
        "总市值": 2024233968.0,
        "市盈率": -4.56,
        "成交量": 7281175.0,
        "成交额": 47396717.0,
        "振幅": 9.28,
        "换手率": 2.42,
        "symbol": "106.ACHR"
    },
    {
        "序号": 274,
        "name": "Arm Holdings plc ADR",
        "最新价": 67.23,
        "涨跌额": 4.79,
        "涨跌幅": 7.67,
        "开盘价": 62.44,
        "最高价": 67.44,
        "最低价": 62.24,
        "昨收价": 62.44,
        "总市值": 68926481820.0,
        "市盈率": 382.92,
        "成交量": 18815931.0,
        "成交额": 1237841264.0,
        "振幅": 8.33,
        "换手率": 1.84,
        "symbol": "105.ARM"
    },
    {
        "序号": 275,
        "name": "Coinbase Global Inc-A",
        "最新价": 146.62,
        "涨跌额": 10.43,
        "涨跌幅": 7.66,
        "开盘价": 136.19,
        "最高价": 146.74,
        "最低价": 135.85,
        "昨收价": 136.19,
        "总市值": 35078182688.0,
        "市盈率": -47.69,
        "成交量": 12023554.0,
        "成交额": 1716503744.0,
        "振幅": 8.0,
        "换手率": 5.03,
        "symbol": "105.COIN"
    },
    {
        "序号": 276,
        "name": "FlexShopper Inc",
        "最新价": 1.83,
        "涨跌额": 0.13,
        "涨跌幅": 7.65,
        "开盘价": 1.79,
        "最高价": 1.83,
        "最低价": 1.74,
        "昨收价": 1.7,
        "总市值": 39622298.0,
        "市盈率": 11.95,
        "成交量": 100309.0,
        "成交额": 178776.0,
        "振幅": 5.29,
        "换手率": 0.46,
        "symbol": "105.FPAY"
    },
    {
        "序号": 277,
        "name": "Locafy Ltd Wt",
        "最新价": 4.8,
        "涨跌额": 0.34,
        "涨跌幅": 7.62,
        "开盘价": 4.33,
        "最高价": 4.8,
        "最低价": 4.33,
        "昨收价": 4.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 393.0,
        "成交额": 1824.0,
        "振幅": 10.54,
        "换手率": null,
        "symbol": "105.LCFYW"
    },
    {
        "序号": 278,
        "name": "Eterna Therapeutics Inc",
        "最新价": 1.56,
        "涨跌额": 0.11,
        "涨跌幅": 7.59,
        "开盘价": 1.44,
        "最高价": 1.62,
        "最低价": 1.36,
        "昨收价": 1.45,
        "总市值": 8440116.0,
        "市盈率": -0.42,
        "成交量": 39811.0,
        "成交额": 60330.0,
        "振幅": 17.93,
        "换手率": 0.74,
        "symbol": "105.ERNA"
    },
    {
        "序号": 279,
        "name": "Lantheus Holdings Inc",
        "最新价": 73.71,
        "涨跌额": 5.18,
        "涨跌幅": 7.56,
        "开盘价": 68.24,
        "最高价": 74.38,
        "最低价": 68.24,
        "昨收价": 68.53,
        "总市值": 5048335468.0,
        "市盈率": 48.5,
        "成交量": 949992.0,
        "成交额": 68756224.0,
        "振幅": 8.96,
        "换手率": 1.39,
        "symbol": "105.LNTH"
    },
    {
        "序号": 280,
        "name": "金铉集团",
        "最新价": 1.43,
        "涨跌额": 0.1,
        "涨跌幅": 7.52,
        "开盘价": 1.31,
        "最高价": 1.5,
        "最低价": 1.3,
        "昨收价": 1.33,
        "总市值": 8670365.0,
        "市盈率": -0.17,
        "成交量": 7660.0,
        "成交额": 10312.0,
        "振幅": 15.04,
        "换手率": 0.13,
        "symbol": "105.JXJT"
    },
    {
        "序号": 281,
        "name": "The Lion Electric Co Wt",
        "最新价": 0.43,
        "涨跌额": 0.03,
        "涨跌幅": 7.5,
        "开盘价": 0.39,
        "最高价": 0.46,
        "最低价": 0.39,
        "昨收价": 0.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 700.0,
        "成交额": 308.0,
        "振幅": 17.5,
        "换手率": null,
        "symbol": "106.LEV_WS_A"
    },
    {
        "序号": 282,
        "name": "Genfit SA ADR",
        "最新价": 3.44,
        "涨跌额": 0.24,
        "涨跌幅": 7.5,
        "开盘价": 3.47,
        "最高价": 3.51,
        "最低价": 3.33,
        "昨收价": 3.2,
        "总市值": 171432342.0,
        "市盈率": -4.61,
        "成交量": 148945.0,
        "成交额": 512494.0,
        "振幅": 5.63,
        "换手率": 0.3,
        "symbol": "105.GNFT"
    },
    {
        "序号": 283,
        "name": "Cipher Mining Inc Wt",
        "最新价": 0.43,
        "涨跌额": 0.03,
        "涨跌幅": 7.5,
        "开盘价": 0.38,
        "最高价": 0.43,
        "最低价": 0.36,
        "昨收价": 0.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 36219.0,
        "成交额": 14956.0,
        "振幅": 17.5,
        "换手率": null,
        "symbol": "105.CIFRW"
    },
    {
        "序号": 284,
        "name": "Borr Drilling Ltd",
        "最新价": 6.18,
        "涨跌额": 0.43,
        "涨跌幅": 7.48,
        "开盘价": 5.89,
        "最高价": 6.2,
        "最低价": 5.85,
        "昨收价": 5.75,
        "总市值": 1519745251.0,
        "市盈率": -55.06,
        "成交量": 2067378.0,
        "成交额": 12633681.0,
        "振幅": 6.09,
        "换手率": 0.84,
        "symbol": "106.BORR"
    },
    {
        "序号": 285,
        "name": "Payoneer Global Inc Wt",
        "最新价": 0.29,
        "涨跌额": 0.02,
        "涨跌幅": 7.41,
        "开盘价": 0.29,
        "最高价": 0.3,
        "最低价": 0.28,
        "昨收价": 0.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 34453.0,
        "成交额": 9955.0,
        "振幅": 7.41,
        "换手率": null,
        "symbol": "105.PAYOW"
    },
    {
        "序号": 286,
        "name": "D-MARKET Electronic Services & ",
        "最新价": 1.74,
        "涨跌额": 0.12,
        "涨跌幅": 7.41,
        "开盘价": 1.64,
        "最高价": 1.75,
        "最低价": 1.58,
        "昨收价": 1.62,
        "总市值": 567237025.0,
        "市盈率": 9.0,
        "成交量": 844333.0,
        "成交额": 1437075.0,
        "振幅": 10.49,
        "换手率": 0.26,
        "symbol": "105.HEPS"
    },
    {
        "序号": 287,
        "name": "Deep Medicine Acquisition Corp ",
        "最新价": 0.29,
        "涨跌额": 0.02,
        "涨跌幅": 7.41,
        "开盘价": 0.28,
        "最高价": 0.32,
        "最低价": 0.28,
        "昨收价": 0.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 4751.0,
        "成交额": 1362.0,
        "振幅": 14.81,
        "换手率": null,
        "symbol": "105.DMAQR"
    },
    {
        "序号": 288,
        "name": "System1 Inc-A",
        "最新价": 1.89,
        "涨跌额": 0.13,
        "涨跌幅": 7.39,
        "开盘价": 1.76,
        "最高价": 1.92,
        "最低价": 1.64,
        "昨收价": 1.76,
        "总市值": 219097677.0,
        "市盈率": 10.42,
        "成交量": 179487.0,
        "成交额": 326050.0,
        "振幅": 15.91,
        "换手率": 0.15,
        "symbol": "106.SST"
    },
    {
        "序号": 289,
        "name": "Tango Therapeutics Inc",
        "最新价": 9.18,
        "涨跌额": 0.63,
        "涨跌幅": 7.37,
        "开盘价": 8.85,
        "最高价": 9.32,
        "最低价": 8.62,
        "昨收价": 8.55,
        "总市值": 936265409.0,
        "市盈率": -9.36,
        "成交量": 581190.0,
        "成交额": 5318404.0,
        "振幅": 8.19,
        "换手率": 0.57,
        "symbol": "105.TNGX"
    },
    {
        "序号": 290,
        "name": "GeneDx Holdings Corp-A",
        "最新价": 1.61,
        "涨跌额": 0.11,
        "涨跌幅": 7.33,
        "开盘价": 1.53,
        "最高价": 1.61,
        "最低价": 1.45,
        "昨收价": 1.5,
        "总市值": 41673689.0,
        "市盈率": -0.09,
        "成交量": 815631.0,
        "成交额": 1234736.0,
        "振幅": 10.67,
        "换手率": 3.15,
        "symbol": "105.WGS"
    },
    {
        "序号": 291,
        "name": "Southland Holdings Inc Wt",
        "最新价": 0.44,
        "涨跌额": 0.03,
        "涨跌幅": 7.32,
        "开盘价": 0.53,
        "最高价": 0.53,
        "最低价": 0.44,
        "昨收价": 0.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1919.0,
        "成交额": 914.0,
        "振幅": 21.95,
        "换手率": null,
        "symbol": "107.SLND_WS"
    },
    {
        "序号": 292,
        "name": "Red Cat Holdings Inc",
        "最新价": 0.59,
        "涨跌额": 0.04,
        "涨跌幅": 7.27,
        "开盘价": 0.56,
        "最高价": 0.6,
        "最低价": 0.56,
        "昨收价": 0.55,
        "总市值": 32899966.0,
        "市盈率": -1.13,
        "成交量": 536399.0,
        "成交额": 313707.0,
        "振幅": 7.27,
        "换手率": 0.96,
        "symbol": "105.RCAT"
    },
    {
        "序号": 293,
        "name": "NeuroOne Medical Technologies C",
        "最新价": 1.92,
        "涨跌额": 0.13,
        "涨跌幅": 7.26,
        "开盘价": 1.76,
        "最高价": 2.0,
        "最低价": 1.74,
        "昨收价": 1.79,
        "总市值": 45924833.0,
        "市盈率": -4.55,
        "成交量": 828397.0,
        "成交额": 1590600.0,
        "振幅": 14.53,
        "换手率": 3.46,
        "symbol": "105.NMTC"
    },
    {
        "序号": 294,
        "name": "Kirkland's Inc",
        "最新价": 2.81,
        "涨跌额": 0.19,
        "涨跌幅": 7.25,
        "开盘价": 2.62,
        "最高价": 2.89,
        "最低价": 2.61,
        "昨收价": 2.62,
        "总市值": 36315532.0,
        "市盈率": -0.87,
        "成交量": 190859.0,
        "成交额": 526054.0,
        "振幅": 10.69,
        "换手率": 1.48,
        "symbol": "105.KIRK"
    },
    {
        "序号": 295,
        "name": "Synaptics Inc",
        "最新价": 106.75,
        "涨跌额": 7.21,
        "涨跌幅": 7.24,
        "开盘价": 101.0,
        "最高价": 107.02,
        "最低价": 100.78,
        "昨收价": 99.54,
        "总市值": 4184594129.0,
        "市盈率": -89.8,
        "成交量": 529133.0,
        "成交额": 55792830.0,
        "振幅": 6.27,
        "换手率": 1.35,
        "symbol": "105.SYNA"
    },
    {
        "序号": 296,
        "name": "AST SpaceMobile Inc-A",
        "最新价": 5.48,
        "涨跌额": 0.37,
        "涨跌幅": 7.24,
        "开盘价": 5.11,
        "最高价": 5.55,
        "最低价": 5.08,
        "昨收价": 5.11,
        "总市值": 1196371214.0,
        "市盈率": -18.73,
        "成交量": 2001269.0,
        "成交额": 10774398.0,
        "振幅": 9.2,
        "换手率": 0.92,
        "symbol": "105.ASTS"
    },
    {
        "序号": 297,
        "name": "Biofrontera Inc",
        "最新价": 3.57,
        "涨跌额": 0.24,
        "涨跌幅": 7.21,
        "开盘价": 3.3,
        "最高价": 3.6,
        "最低价": 3.22,
        "昨收价": 3.33,
        "总市值": 5417932.0,
        "市盈率": -0.2,
        "成交量": 16310.0,
        "成交额": 55678.0,
        "振幅": 11.41,
        "换手率": 1.07,
        "symbol": "105.BFRI"
    },
    {
        "序号": 298,
        "name": "trivago NV ADR",
        "最新价": 2.53,
        "涨跌额": 0.17,
        "涨跌幅": 7.2,
        "开盘价": 2.37,
        "最高价": 2.54,
        "最低价": 2.33,
        "昨收价": 2.36,
        "总市值": 175191084.0,
        "市盈率": -0.99,
        "成交量": 450634.0,
        "成交额": 1087903.0,
        "振幅": 8.9,
        "换手率": 0.65,
        "symbol": "105.TRVG"
    },
    {
        "序号": 299,
        "name": "Beam Global Wt",
        "最新价": 1.49,
        "涨跌额": 0.1,
        "涨跌幅": 7.19,
        "开盘价": 0.9,
        "最高价": 1.5,
        "最低价": 0.9,
        "昨收价": 1.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 6398.0,
        "成交额": 8205.0,
        "振幅": 43.17,
        "换手率": null,
        "symbol": "105.BEEMW"
    },
    {
        "序号": 300,
        "name": "NextPlat Corp Wt",
        "最新价": 0.45,
        "涨跌额": 0.03,
        "涨跌幅": 7.14,
        "开盘价": 0.44,
        "最高价": 0.45,
        "最低价": 0.43,
        "昨收价": 0.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 1615.0,
        "成交额": 718.0,
        "振幅": 4.76,
        "换手率": null,
        "symbol": "105.NXPLW"
    },
    {
        "序号": 301,
        "name": "Lytus Technologies Holdings PTV",
        "最新价": 0.15,
        "涨跌额": 0.01,
        "涨跌幅": 7.14,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.14,
        "总市值": 6092783.0,
        "市盈率": -2.59,
        "成交量": 139025.0,
        "成交额": 20372.0,
        "振幅": 7.14,
        "换手率": 0.34,
        "symbol": "105.LYT"
    },
    {
        "序号": 302,
        "name": "HIVE Digital Technologies Ltd",
        "最新价": 4.2,
        "涨跌额": 0.28,
        "涨跌幅": 7.14,
        "开盘价": 3.93,
        "最高价": 4.21,
        "最低价": 3.93,
        "昨收价": 3.92,
        "总市值": 368048591.0,
        "市盈率": -2.89,
        "成交量": 2809542.0,
        "成交额": 11528921.0,
        "振幅": 7.14,
        "换手率": 3.21,
        "symbol": "105.HIVE"
    },
    {
        "序号": 303,
        "name": "Genius Group Ltd",
        "最新价": 0.6,
        "涨跌额": 0.04,
        "涨跌幅": 7.14,
        "开盘价": 0.56,
        "最高价": 0.62,
        "最低价": 0.56,
        "昨收价": 0.56,
        "总市值": 44084270.0,
        "市盈率": -0.71,
        "成交量": 1155229.0,
        "成交额": 687067.0,
        "振幅": 10.71,
        "换手率": 1.57,
        "symbol": "107.GNS"
    },
    {
        "序号": 304,
        "name": "EF Hutton Acquisition Corp I Rt",
        "最新价": 0.3,
        "涨跌额": 0.02,
        "涨跌幅": 7.14,
        "开盘价": 0.3,
        "最高价": 0.47,
        "最低价": 0.28,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 448878.0,
        "成交额": 152221.0,
        "振幅": 67.86,
        "换手率": null,
        "symbol": "105.EFHTR"
    },
    {
        "序号": 305,
        "name": "Ads-Tec Energy PLC Wt",
        "最新价": 0.75,
        "涨跌额": 0.05,
        "涨跌幅": 7.14,
        "开盘价": 0.74,
        "最高价": 0.75,
        "最低价": 0.68,
        "昨收价": 0.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 3300.0,
        "成交额": 2386.0,
        "振幅": 10.0,
        "换手率": null,
        "symbol": "105.ADSEW"
    },
    {
        "序号": 306,
        "name": "PubMatic Inc-A",
        "最新价": 17.11,
        "涨跌额": 1.14,
        "涨跌幅": 7.14,
        "开盘价": 15.96,
        "最高价": 17.37,
        "最低价": 15.96,
        "昨收价": 15.97,
        "总市值": 868540985.0,
        "市盈率": 293.43,
        "成交量": 815398.0,
        "成交额": 13738353.0,
        "振幅": 8.83,
        "换手率": 1.61,
        "symbol": "105.PUBM"
    },
    {
        "序号": 307,
        "name": "坎特伯雷帕克控股",
        "最新价": 20.93,
        "涨跌额": 1.39,
        "涨跌幅": 7.11,
        "开盘价": 19.5,
        "最高价": 21.22,
        "最低价": 19.16,
        "昨收价": 19.54,
        "总市值": 103491357.0,
        "市盈率": 10.08,
        "成交量": 5719.0,
        "成交额": 117298.0,
        "振幅": 10.54,
        "换手率": 0.12,
        "symbol": "105.CPHC"
    },
    {
        "序号": 308,
        "name": "iShares Blockchain and Tech ETF",
        "最新价": 25.61,
        "涨跌额": 1.7,
        "涨跌幅": 7.11,
        "开盘价": 24.16,
        "最高价": 25.61,
        "最低价": 24.09,
        "昨收价": 23.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 14784.0,
        "成交额": 372925.0,
        "振幅": 6.36,
        "换手率": null,
        "symbol": "107.IBLC"
    },
    {
        "序号": 309,
        "name": "Livent Corp",
        "最新价": 15.52,
        "涨跌额": 1.03,
        "涨跌幅": 7.11,
        "开盘价": 14.77,
        "最高价": 15.61,
        "最低价": 14.77,
        "昨收价": 14.49,
        "总市值": 2790683792.0,
        "市盈率": 7.44,
        "成交量": 7837225.0,
        "成交额": 120292299.0,
        "振幅": 5.8,
        "换手率": 4.36,
        "symbol": "106.LTHM"
    },
    {
        "序号": 310,
        "name": "亚太交换中心",
        "最新价": 4.98,
        "涨跌额": 0.33,
        "涨跌幅": 7.1,
        "开盘价": 4.55,
        "最高价": 5.12,
        "最低价": 4.5,
        "昨收价": 4.65,
        "总市值": 153889171.0,
        "市盈率": 31.11,
        "成交量": 1512058.0,
        "成交额": 7440342.0,
        "振幅": 13.33,
        "换手率": 4.89,
        "symbol": "105.EBIX"
    },
    {
        "序号": 311,
        "name": "Verde Clean Fuels Inc-A",
        "最新价": 2.58,
        "涨跌额": 0.17,
        "涨跌幅": 7.05,
        "开盘价": 2.64,
        "最高价": 2.68,
        "最低价": 2.14,
        "昨收价": 2.41,
        "总市值": 82270617.0,
        "市盈率": -8.76,
        "成交量": 30259.0,
        "成交额": 74629.0,
        "振幅": 22.41,
        "换手率": 0.09,
        "symbol": "105.VGAS"
    },
    {
        "序号": 312,
        "name": "Intelligent Living Application ",
        "最新价": 0.76,
        "涨跌额": 0.05,
        "涨跌幅": 7.04,
        "开盘价": 0.75,
        "最高价": 0.78,
        "最低价": 0.71,
        "昨收价": 0.71,
        "总市值": 13725600.0,
        "市盈率": -3.24,
        "成交量": 64839.0,
        "成交额": 49168.0,
        "振幅": 9.86,
        "换手率": 0.36,
        "symbol": "105.ILAG"
    },
    {
        "序号": 313,
        "name": "Lightwave Logic Inc",
        "最新价": 5.03,
        "涨跌额": 0.33,
        "涨跌幅": 7.02,
        "开盘价": 4.69,
        "最高价": 5.06,
        "最低价": 4.68,
        "昨收价": 4.7,
        "总市值": 587941353.0,
        "市盈率": -27.78,
        "成交量": 807027.0,
        "成交额": 4000556.0,
        "振幅": 8.09,
        "换手率": 0.69,
        "symbol": "105.LWLG"
    },
    {
        "序号": 314,
        "name": "VanEck Digital Transformation E",
        "最新价": 8.69,
        "涨跌额": 0.57,
        "涨跌幅": 7.02,
        "开盘价": 8.12,
        "最高价": 8.7,
        "最低价": 8.12,
        "昨收价": 8.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 238133.0,
        "成交额": 2036132.0,
        "振幅": 7.14,
        "换手率": null,
        "symbol": "105.DAPP"
    },
    {
        "序号": 315,
        "name": "Funko Inc-A",
        "最新价": 6.76,
        "涨跌额": 0.44,
        "涨跌幅": 6.96,
        "开盘价": 6.34,
        "最高价": 6.88,
        "最低价": 6.34,
        "昨收价": 6.32,
        "总市值": 356128867.0,
        "市盈率": -1.92,
        "成交量": 546375.0,
        "成交额": 3663743.0,
        "振幅": 8.54,
        "换手率": 1.04,
        "symbol": "105.FNKO"
    },
    {
        "序号": 316,
        "name": "维罗纳制药",
        "最新价": 14.62,
        "涨跌额": 0.95,
        "涨跌幅": 6.95,
        "开盘价": 13.67,
        "最高价": 14.66,
        "最低价": 13.41,
        "昨收价": 13.67,
        "总市值": 1168722899.0,
        "市盈率": -23.06,
        "成交量": 646556.0,
        "成交额": 9207817.0,
        "振幅": 9.14,
        "换手率": 0.81,
        "symbol": "105.VRNA"
    },
    {
        "序号": 317,
        "name": "Origin Materials Inc",
        "最新价": 0.77,
        "涨跌额": 0.05,
        "涨跌幅": 6.94,
        "开盘价": 0.72,
        "最高价": 0.77,
        "最低价": 0.71,
        "昨收价": 0.72,
        "总市值": 111094989.0,
        "市盈率": 2.21,
        "成交量": 1963106.0,
        "成交额": 1468565.0,
        "振幅": 8.33,
        "换手率": 1.36,
        "symbol": "105.ORGN"
    },
    {
        "序号": 318,
        "name": "霍夫纳尼安",
        "最新价": 139.01,
        "涨跌额": 9.02,
        "涨跌幅": 6.94,
        "开盘价": 129.5,
        "最高价": 140.32,
        "最低价": 126.85,
        "昨收价": 129.99,
        "总市值": 847267340.0,
        "市盈率": 5.16,
        "成交量": 227535.0,
        "成交额": 31147873.0,
        "振幅": 10.36,
        "换手率": 3.73,
        "symbol": "106.HOV"
    },
    {
        "序号": 319,
        "name": "亚太电线电缆",
        "最新价": 1.39,
        "涨跌额": 0.09,
        "涨跌幅": 6.92,
        "开盘价": 1.39,
        "最高价": 1.39,
        "最低价": 1.39,
        "昨收价": 1.3,
        "总市值": 28656556.0,
        "市盈率": 6.78,
        "成交量": 215.0,
        "成交额": 298.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.APWC"
    },
    {
        "序号": 320,
        "name": "Tandy Leather Factory Inc",
        "最新价": 4.33,
        "涨跌额": 0.28,
        "涨跌幅": 6.91,
        "开盘价": 4.11,
        "最高价": 4.35,
        "最低价": 4.11,
        "昨收价": 4.05,
        "总市值": 36368731.0,
        "市盈率": 15.96,
        "成交量": 21832.0,
        "成交额": 91969.0,
        "振幅": 5.93,
        "换手率": 0.26,
        "symbol": "105.TLF"
    },
    {
        "序号": 321,
        "name": "Inhibikase Therapeutics Inc",
        "最新价": 1.24,
        "涨跌额": 0.08,
        "涨跌幅": 6.9,
        "开盘价": 1.2,
        "最高价": 1.24,
        "最低价": 1.15,
        "昨收价": 1.16,
        "总市值": 7656107.0,
        "市盈率": -0.4,
        "成交量": 72009.0,
        "成交额": 86985.0,
        "振幅": 7.76,
        "换手率": 1.17,
        "symbol": "105.IKT"
    },
    {
        "序号": 322,
        "name": "GCM Grosvenor Inc Wt",
        "最新价": 0.31,
        "涨跌额": 0.02,
        "涨跌幅": 6.9,
        "开盘价": 0.31,
        "最高价": 0.35,
        "最低价": 0.3,
        "昨收价": 0.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 13853.0,
        "成交额": 4343.0,
        "振幅": 17.24,
        "换手率": null,
        "symbol": "105.GCMGW"
    },
    {
        "序号": 323,
        "name": "Autolus Therapeutics plc ADR",
        "最新价": 4.36,
        "涨跌额": 0.28,
        "涨跌幅": 6.86,
        "开盘价": 4.17,
        "最高价": 4.48,
        "最低价": 3.9,
        "昨收价": 4.08,
        "总市值": 758592725.0,
        "市盈率": -4.8,
        "成交量": 1029101.0,
        "成交额": 4407056.0,
        "振幅": 14.22,
        "换手率": 0.59,
        "symbol": "105.AUTL"
    },
    {
        "序号": 324,
        "name": "Bright Minds Biosciences Inc",
        "最新价": 1.56,
        "涨跌额": 0.1,
        "涨跌幅": 6.85,
        "开盘价": 1.48,
        "最高价": 1.56,
        "最低价": 1.48,
        "昨收价": 1.46,
        "总市值": 5884432.0,
        "市盈率": -0.97,
        "成交量": 18393.0,
        "成交额": 27919.0,
        "振幅": 5.48,
        "换手率": 0.49,
        "symbol": "105.DRUG"
    },
    {
        "序号": 325,
        "name": "Piedmont Lithium Inc",
        "最新价": 26.53,
        "涨跌额": 1.7,
        "涨跌幅": 6.85,
        "开盘价": 25.5,
        "最高价": 26.96,
        "最低价": 25.19,
        "昨收价": 24.83,
        "总市值": 509623445.0,
        "市盈率": -69.9,
        "成交量": 507914.0,
        "成交额": 13421914.0,
        "振幅": 7.13,
        "换手率": 2.64,
        "symbol": "105.PLL"
    },
    {
        "序号": 326,
        "name": "Nayax Ltd",
        "最新价": 21.74,
        "涨跌额": 1.39,
        "涨跌幅": 6.83,
        "开盘价": 19.78,
        "最高价": 21.74,
        "最低价": 19.78,
        "昨收价": 20.35,
        "总市值": 721415073.0,
        "市盈率": -35.88,
        "成交量": 273.0,
        "成交额": 5667.0,
        "振幅": 9.63,
        "换手率": 0.0,
        "symbol": "105.NYAX"
    },
    {
        "序号": 327,
        "name": "Bitwise Crypto Industry Innovat",
        "最新价": 10.18,
        "涨跌额": 0.65,
        "涨跌幅": 6.82,
        "开盘价": 9.65,
        "最高价": 10.18,
        "最低价": 9.6,
        "昨收价": 9.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 231005.0,
        "成交额": 2311574.0,
        "振幅": 6.09,
        "换手率": null,
        "symbol": "107.BITQ"
    },
    {
        "序号": 328,
        "name": "Ellomay Capital Ltd",
        "最新价": 13.88,
        "涨跌额": 0.88,
        "涨跌幅": 6.77,
        "开盘价": 13.21,
        "最高价": 13.88,
        "最低价": 13.21,
        "昨收价": 13.0,
        "总市值": 178393880.0,
        "市盈率": 25.99,
        "成交量": 487.0,
        "成交额": 6596.0,
        "振幅": 5.15,
        "换手率": 0.0,
        "symbol": "107.ELLO"
    },
    {
        "序号": 329,
        "name": "BYTE Acquisition Corp Unit Cons",
        "最新价": 11.53,
        "涨跌额": 0.73,
        "涨跌幅": 6.76,
        "开盘价": 10.95,
        "最高价": 12.0,
        "最低价": 10.94,
        "昨收价": 10.8,
        "总市值": 126367739.0,
        "市盈率": -643.12,
        "成交量": 1934.0,
        "成交额": 22184.0,
        "振幅": 9.81,
        "换手率": null,
        "symbol": "105.BYTSU"
    },
    {
        "序号": 330,
        "name": "Affirm Holdings Inc-A",
        "最新价": 42.07,
        "涨跌额": 2.66,
        "涨跌幅": 6.75,
        "开盘价": 39.28,
        "最高价": 42.42,
        "最低价": 38.61,
        "昨收价": 39.41,
        "总市值": 12693931458.0,
        "市盈率": -14.01,
        "成交量": 27539071.0,
        "成交额": 1127334000.0,
        "振幅": 9.67,
        "换手率": 9.13,
        "symbol": "105.AFRM"
    },
    {
        "序号": 331,
        "name": "Rockwell Medical Inc",
        "最新价": 1.76,
        "涨跌额": 0.11,
        "涨跌幅": 6.67,
        "开盘价": 1.67,
        "最高价": 1.84,
        "最低价": 1.67,
        "昨收价": 1.65,
        "总市值": 50141807.0,
        "市盈率": -5.4,
        "成交量": 529249.0,
        "成交额": 938983.0,
        "振幅": 10.3,
        "换手率": 1.86,
        "symbol": "105.RMTI"
    },
    {
        "序号": 332,
        "name": "量子之歌",
        "最新价": 2.08,
        "涨跌额": 0.13,
        "涨跌幅": 6.67,
        "开盘价": 2.03,
        "最高价": 2.47,
        "最低价": 1.98,
        "昨收价": 1.95,
        "总市值": 117488179.0,
        "市盈率": -7.82,
        "成交量": 97425.0,
        "成交额": 224660.0,
        "振幅": 25.13,
        "换手率": 0.17,
        "symbol": "105.QSG"
    },
    {
        "序号": 333,
        "name": "Pagaya Technologies Ltd-A",
        "最新价": 1.28,
        "涨跌额": 0.08,
        "涨跌幅": 6.67,
        "开盘价": 1.2,
        "最高价": 1.35,
        "最低价": 1.2,
        "昨收价": 1.2,
        "总市值": 913215942.0,
        "市盈率": -6.17,
        "成交量": 7743057.0,
        "成交额": 9976369.0,
        "振幅": 12.5,
        "换手率": 1.09,
        "symbol": "105.PGY"
    },
    {
        "序号": 334,
        "name": "Estrella Immunopharma Inc",
        "最新价": 1.28,
        "涨跌额": 0.08,
        "涨跌幅": 6.67,
        "开盘价": 1.24,
        "最高价": 1.36,
        "最低价": 1.22,
        "昨收价": 1.2,
        "总市值": 45057577.0,
        "市盈率": -71.0,
        "成交量": 12791.0,
        "成交额": 16333.0,
        "振幅": 11.67,
        "换手率": 0.04,
        "symbol": "105.ESLA"
    },
    {
        "序号": 335,
        "name": "Dune Acquisition Corp Unit Cons",
        "最新价": 4.8,
        "涨跌额": 0.3,
        "涨跌幅": 6.67,
        "开盘价": 4.93,
        "最高价": 5.95,
        "最低价": 4.6,
        "昨收价": 4.5,
        "总市值": 26373859.0,
        "市盈率": -7.96,
        "成交量": 1966.0,
        "成交额": 10348.0,
        "振幅": 30.0,
        "换手率": null,
        "symbol": "105.DUNEU"
    },
    {
        "序号": 336,
        "name": "Blue Star Foods Corp",
        "最新价": 0.16,
        "涨跌额": 0.01,
        "涨跌幅": 6.67,
        "开盘价": 0.15,
        "最高价": 0.16,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": 2312056.0,
        "市盈率": -0.21,
        "成交量": 1190579.0,
        "成交额": 180572.0,
        "振幅": 6.67,
        "换手率": 8.24,
        "symbol": "105.BSFC"
    },
    {
        "序号": 337,
        "name": "Invesco Alerian Galaxy Crypto E",
        "最新价": 12.2,
        "涨跌额": 0.76,
        "涨跌幅": 6.64,
        "开盘价": 11.75,
        "最高价": 12.2,
        "最低价": 11.75,
        "昨收价": 11.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 13262.0,
        "成交额": 159875.0,
        "振幅": 3.93,
        "换手率": null,
        "symbol": "107.SATO"
    },
    {
        "序号": 338,
        "name": "STAAR Surgical Co",
        "最新价": 33.4,
        "涨跌额": 2.08,
        "涨跌幅": 6.64,
        "开盘价": 30.99,
        "最高价": 33.86,
        "最低价": 30.99,
        "昨收价": 31.32,
        "总市值": 1630503598.0,
        "市盈率": 83.86,
        "成交量": 1043545.0,
        "成交额": 34649529.0,
        "振幅": 9.16,
        "换手率": 2.14,
        "symbol": "105.STAA"
    },
    {
        "序号": 339,
        "name": "二倍做空白银ETF-ProShares",
        "最新价": 19.61,
        "涨跌额": 1.22,
        "涨跌幅": 6.63,
        "开盘价": 19.13,
        "最高价": 19.73,
        "最低价": 18.81,
        "昨收价": 18.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1082934.0,
        "成交额": 20921145.0,
        "振幅": 5.0,
        "换手率": null,
        "symbol": "107.ZSL"
    },
    {
        "序号": 340,
        "name": "Enliven Therapeutics Inc",
        "最新价": 11.99,
        "涨跌额": 0.74,
        "涨跌幅": 6.58,
        "开盘价": 11.01,
        "最高价": 12.54,
        "最低价": 11.01,
        "昨收价": 11.25,
        "总市值": 494131556.0,
        "市盈率": -21.85,
        "成交量": 89471.0,
        "成交额": 1070285.0,
        "振幅": 13.6,
        "换手率": 0.22,
        "symbol": "105.ELVN"
    },
    {
        "序号": 341,
        "name": "MicroSectors Gold Miners -3X In",
        "最新价": 5.03,
        "涨跌额": 0.31,
        "涨跌幅": 6.57,
        "开盘价": 5.04,
        "最高价": 5.17,
        "最低价": 4.82,
        "昨收价": 4.72,
        "总市值": 3626173080.0,
        "市盈率": 1.14,
        "成交量": 2405531.0,
        "成交额": 12008776.0,
        "振幅": 7.42,
        "换手率": null,
        "symbol": "107.GDXD"
    },
    {
        "序号": 342,
        "name": "Stronghold Digital Mining Inc-A",
        "最新价": 5.2,
        "涨跌额": 0.32,
        "涨跌幅": 6.56,
        "开盘价": 5.0,
        "最高价": 5.5,
        "最低价": 4.93,
        "昨收价": 4.88,
        "总市值": 58483745.0,
        "市盈率": -0.71,
        "成交量": 579786.0,
        "成交额": 3016075.0,
        "振幅": 11.68,
        "换手率": 5.16,
        "symbol": "105.SDIG"
    },
    {
        "序号": 343,
        "name": "NioCorp Developments Ltd",
        "最新价": 3.25,
        "涨跌额": 0.2,
        "涨跌幅": 6.56,
        "开盘价": 3.03,
        "最高价": 3.27,
        "最低价": 3.03,
        "昨收价": 3.05,
        "总市值": 108262837.0,
        "市盈率": -2.75,
        "成交量": 126176.0,
        "成交额": 396710.0,
        "振幅": 7.87,
        "换手率": 0.38,
        "symbol": "105.NB"
    },
    {
        "序号": 344,
        "name": "C4 Therapeutics Inc",
        "最新价": 1.3,
        "涨跌额": 0.08,
        "涨跌幅": 6.56,
        "开盘价": 1.21,
        "最高价": 1.35,
        "最低价": 1.18,
        "昨收价": 1.22,
        "总市值": 64065360.0,
        "市盈率": -0.47,
        "成交量": 1185613.0,
        "成交额": 1540579.0,
        "振幅": 13.93,
        "换手率": 2.41,
        "symbol": "105.CCCC"
    },
    {
        "序号": 345,
        "name": "Genelux Corp",
        "最新价": 12.84,
        "涨跌额": 0.79,
        "涨跌幅": 6.56,
        "开盘价": 12.02,
        "最高价": 13.1,
        "最低价": 11.77,
        "昨收价": 12.05,
        "总市值": 343054960.0,
        "市盈率": -13.71,
        "成交量": 66701.0,
        "成交额": 846858.0,
        "振幅": 11.04,
        "换手率": 0.25,
        "symbol": "105.GNLX"
    },
    {
        "序号": 346,
        "name": "Amplitude Inc-A",
        "最新价": 12.03,
        "涨跌额": 0.74,
        "涨跌幅": 6.55,
        "开盘价": 11.24,
        "最高价": 12.08,
        "最低价": 11.17,
        "昨收价": 11.29,
        "总市值": 1432172679.0,
        "市盈率": -14.94,
        "成交量": 705437.0,
        "成交额": 8384741.0,
        "振幅": 8.06,
        "换手率": 0.59,
        "symbol": "105.AMPL"
    },
    {
        "序号": 347,
        "name": "Advantage Solutions Inc-A",
        "最新价": 3.26,
        "涨跌额": 0.2,
        "涨跌幅": 6.54,
        "开盘价": 3.06,
        "最高价": 3.27,
        "最低价": 3.04,
        "昨收价": 3.06,
        "总市值": 1064043129.0,
        "市盈率": -0.71,
        "成交量": 872696.0,
        "成交额": 2789934.0,
        "振幅": 7.52,
        "换手率": 0.27,
        "symbol": "105.ADV"
    },
    {
        "序号": 348,
        "name": "Sangamo Therapeutics Inc",
        "最新价": 0.49,
        "涨跌额": 0.03,
        "涨跌幅": 6.52,
        "开盘价": 0.44,
        "最高价": 0.55,
        "最低价": 0.43,
        "昨收价": 0.46,
        "总市值": 86900020.0,
        "市盈率": -0.35,
        "成交量": 1473940.0,
        "成交额": 734199.0,
        "振幅": 26.09,
        "换手率": 0.83,
        "symbol": "105.SGMO"
    },
    {
        "序号": 349,
        "name": "Entrada Therapeutics Inc",
        "最新价": 14.23,
        "涨跌额": 0.87,
        "涨跌幅": 6.51,
        "开盘价": 13.27,
        "最高价": 14.5,
        "最低价": 12.71,
        "昨收价": 13.36,
        "总市值": 474827750.0,
        "市盈率": -21.81,
        "成交量": 88644.0,
        "成交额": 1209152.0,
        "振幅": 13.4,
        "换手率": 0.27,
        "symbol": "105.TRDA"
    },
    {
        "序号": 350,
        "name": "INmune Bio Inc",
        "最新价": 10.81,
        "涨跌额": 0.66,
        "涨跌幅": 6.5,
        "开盘价": 10.25,
        "最高价": 11.0,
        "最低价": 10.07,
        "昨收价": 10.15,
        "总市值": 194814491.0,
        "市盈率": -7.1,
        "成交量": 158504.0,
        "成交额": 1690836.0,
        "振幅": 9.16,
        "换手率": 0.88,
        "symbol": "105.INMB"
    },
    {
        "序号": 351,
        "name": "灿谷",
        "最新价": 0.99,
        "涨跌额": 0.06,
        "涨跌幅": 6.45,
        "开盘价": 0.98,
        "最高价": 1.03,
        "最低价": 0.95,
        "昨收价": 0.93,
        "总市值": 133404463.0,
        "市盈率": -1.84,
        "成交量": 72892.0,
        "成交额": 71913.0,
        "振幅": 8.6,
        "换手率": 0.05,
        "symbol": "106.CANG"
    },
    {
        "序号": 352,
        "name": "BTCS Inc",
        "最新价": 1.65,
        "涨跌额": 0.1,
        "涨跌幅": 6.45,
        "开盘价": 1.56,
        "最高价": 1.67,
        "最低价": 1.52,
        "昨收价": 1.55,
        "总市值": 23715757.0,
        "市盈率": -5.31,
        "成交量": 297757.0,
        "成交额": 481663.0,
        "振幅": 9.68,
        "换手率": 2.07,
        "symbol": "105.BTCS"
    },
    {
        "序号": 353,
        "name": "Ero Copper Corp",
        "最新价": 13.54,
        "涨跌额": 0.82,
        "涨跌幅": 6.45,
        "开盘价": 12.63,
        "最高价": 13.58,
        "最低价": 12.63,
        "昨收价": 12.72,
        "总市值": 1265144766.0,
        "市盈率": 16.13,
        "成交量": 305304.0,
        "成交额": 4083073.0,
        "振幅": 7.47,
        "换手率": 0.33,
        "symbol": "106.ERO"
    },
    {
        "序号": 354,
        "name": "超微电脑",
        "最新价": 272.65,
        "涨跌额": 16.5,
        "涨跌幅": 6.44,
        "开盘价": 255.0,
        "最高价": 274.2,
        "最低价": 254.61,
        "昨收价": 256.15,
        "总市值": 15142467327.0,
        "市盈率": 24.72,
        "成交量": 3157504.0,
        "成交额": 844224848.0,
        "振幅": 7.65,
        "换手率": 5.69,
        "symbol": "105.SMCI"
    },
    {
        "序号": 355,
        "name": "NexGel Inc",
        "最新价": 2.16,
        "涨跌额": 0.13,
        "涨跌幅": 6.4,
        "开盘价": 2.13,
        "最高价": 2.17,
        "最低价": 2.05,
        "昨收价": 2.03,
        "总市值": 12350079.0,
        "市盈率": -4.56,
        "成交量": 9904.0,
        "成交额": 21370.0,
        "振幅": 5.91,
        "换手率": 0.17,
        "symbol": "105.NXGL"
    },
    {
        "序号": 356,
        "name": "Riot Platforms Inc",
        "最新价": 15.83,
        "涨跌额": 0.95,
        "涨跌幅": 6.38,
        "开盘价": 15.09,
        "最高价": 15.97,
        "最低价": 15.04,
        "昨收价": 14.88,
        "总市值": 3269139019.0,
        "市盈率": -11.32,
        "成交量": 31412715.0,
        "成交额": 492560960.0,
        "振幅": 6.25,
        "换手率": 15.21,
        "symbol": "105.RIOT"
    },
    {
        "序号": 357,
        "name": "Edesa Biotech Inc",
        "最新价": 4.17,
        "涨跌额": 0.25,
        "涨跌幅": 6.38,
        "开盘价": 3.91,
        "最高价": 4.39,
        "最低价": 3.86,
        "昨收价": 3.92,
        "总市值": 12565790.0,
        "市盈率": -1.33,
        "成交量": 43911.0,
        "成交额": 179567.0,
        "振幅": 13.52,
        "换手率": 1.46,
        "symbol": "105.EDSA"
    },
    {
        "序号": 358,
        "name": "Global X Blockchain & Bitcoin S",
        "最新价": 56.07,
        "涨跌额": 3.36,
        "涨跌幅": 6.37,
        "开盘价": 54.01,
        "最高价": 56.33,
        "最低价": 54.01,
        "昨收价": 52.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 7607.0,
        "成交额": 420419.0,
        "振幅": 4.4,
        "换手率": null,
        "symbol": "105.BITS"
    },
    {
        "序号": 359,
        "name": "Fidelity Crypto Industry and Di",
        "最新价": 24.14,
        "涨跌额": 1.44,
        "涨跌幅": 6.34,
        "开盘价": 22.73,
        "最高价": 24.18,
        "最低价": 22.73,
        "昨收价": 22.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 75952.0,
        "成交额": 1798850.0,
        "振幅": 6.39,
        "换手率": null,
        "symbol": "105.FDIG"
    },
    {
        "序号": 360,
        "name": "Gogoro Inc",
        "最新价": 2.52,
        "涨跌额": 0.15,
        "涨跌幅": 6.33,
        "开盘价": 2.39,
        "最高价": 2.53,
        "最低价": 2.38,
        "昨收价": 2.37,
        "总市值": 615371990.0,
        "市盈率": -9.95,
        "成交量": 293196.0,
        "成交额": 725331.0,
        "振幅": 6.33,
        "换手率": 0.12,
        "symbol": "105.GGR"
    },
    {
        "序号": 361,
        "name": "冠科美博-A",
        "最新价": 0.84,
        "涨跌额": 0.05,
        "涨跌幅": 6.33,
        "开盘价": 0.79,
        "最高价": 0.85,
        "最低价": 0.79,
        "昨收价": 0.79,
        "总市值": 75163864.0,
        "市盈率": null,
        "成交量": 300806.0,
        "成交额": 246220.0,
        "振幅": 7.59,
        "换手率": 0.34,
        "symbol": "105.APLM"
    },
    {
        "序号": 362,
        "name": "Tyra Biosciences Inc",
        "最新价": 13.95,
        "涨跌额": 0.83,
        "涨跌幅": 6.33,
        "开盘价": 13.0,
        "最高价": 14.29,
        "最低价": 12.82,
        "昨收价": 13.12,
        "总市值": 600669521.0,
        "市盈率": -10.15,
        "成交量": 96188.0,
        "成交额": 1296336.0,
        "振幅": 11.2,
        "换手率": 0.22,
        "symbol": "105.TYRA"
    },
    {
        "序号": 363,
        "name": "Lyft Inc-A",
        "最新价": 13.21,
        "涨跌额": 0.78,
        "涨跌幅": 6.28,
        "开盘价": 12.31,
        "最高价": 13.27,
        "最低价": 12.27,
        "昨收价": 12.43,
        "总市值": 5191401771.0,
        "市盈率": -5.75,
        "成交量": 22655918.0,
        "成交额": 293434656.0,
        "振幅": 8.05,
        "换手率": 5.77,
        "symbol": "105.LYFT"
    },
    {
        "序号": 364,
        "name": "Zomedica Corp",
        "最新价": 0.17,
        "涨跌额": 0.01,
        "涨跌幅": 6.25,
        "开盘价": 0.16,
        "最高价": 0.17,
        "最低价": 0.16,
        "昨收价": 0.16,
        "总市值": 166591444.0,
        "市盈率": -11.16,
        "成交量": 4826327.0,
        "成交额": 792659.0,
        "振幅": 6.25,
        "换手率": 0.49,
        "symbol": "107.ZOM"
    },
    {
        "序号": 365,
        "name": "Virios Therapeutics Inc",
        "最新价": 0.68,
        "涨跌额": 0.04,
        "涨跌幅": 6.25,
        "开盘价": 0.63,
        "最高价": 0.69,
        "最低价": 0.63,
        "昨收价": 0.64,
        "总市值": 13095397.0,
        "市盈率": -2.1,
        "成交量": 49852.0,
        "成交额": 33452.0,
        "振幅": 9.38,
        "换手率": 0.26,
        "symbol": "105.VIRI"
    },
    {
        "序号": 366,
        "name": "Quantum Computing Inc",
        "最新价": 0.85,
        "涨跌额": 0.05,
        "涨跌幅": 6.25,
        "开盘价": 0.8,
        "最高价": 0.86,
        "最低价": 0.8,
        "昨收价": 0.8,
        "总市值": 63832662.0,
        "市盈率": -1.59,
        "成交量": 465555.0,
        "成交额": 388820.0,
        "振幅": 7.5,
        "换手率": 0.62,
        "symbol": "105.QUBT"
    },
    {
        "序号": 367,
        "name": "Gogoro Inc Wt",
        "最新价": 0.17,
        "涨跌额": 0.01,
        "涨跌幅": 6.25,
        "开盘价": 0.17,
        "最高价": 0.17,
        "最低价": 0.17,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 7900.0,
        "成交额": 1336.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GGROW"
    },
    {
        "序号": 368,
        "name": "Carvana Co-A",
        "最新价": 40.26,
        "涨跌额": 2.36,
        "涨跌幅": 6.23,
        "开盘价": 37.88,
        "最高价": 41.34,
        "最低价": 37.67,
        "昨收价": 37.9,
        "总市值": 8037902357.0,
        "市盈率": -33.21,
        "成交量": 13283810.0,
        "成交额": 529412400.0,
        "振幅": 9.68,
        "换手率": 6.65,
        "symbol": "106.CVNA"
    },
    {
        "序号": 369,
        "name": "Servotronics Inc",
        "最新价": 11.86,
        "涨跌额": 0.69,
        "涨跌幅": 6.18,
        "开盘价": 11.68,
        "最高价": 11.9,
        "最低价": 11.68,
        "昨收价": 11.17,
        "总市值": 29605062.0,
        "市盈率": -2.36,
        "成交量": 2778.0,
        "成交额": 32885.0,
        "振幅": 1.97,
        "换手率": 0.11,
        "symbol": "107.SVT"
    },
    {
        "序号": 370,
        "name": "Mobiv Acquisition Corp-A",
        "最新价": 5.0,
        "涨跌额": 0.29,
        "涨跌幅": 6.16,
        "开盘价": 4.62,
        "最高价": 5.74,
        "最低价": 4.19,
        "昨收价": 4.71,
        "总市值": 16702960.0,
        "市盈率": null,
        "成交量": 161992.0,
        "成交额": 805603.0,
        "振幅": 32.91,
        "换手率": 4.85,
        "symbol": "105.MOBV"
    },
    {
        "序号": 371,
        "name": "狮门娱乐-A",
        "最新价": 10.18,
        "涨跌额": 0.59,
        "涨跌幅": 6.15,
        "开盘价": 9.62,
        "最高价": 10.19,
        "最低价": 9.62,
        "昨收价": 9.59,
        "总市值": 2392419870.0,
        "市盈率": -2.31,
        "成交量": 780653.0,
        "成交额": 7773154.0,
        "振幅": 5.94,
        "换手率": 0.93,
        "symbol": "106.LGF_A"
    },
    {
        "序号": 372,
        "name": "Hudbay Minerals Inc",
        "最新价": 5.01,
        "涨跌额": 0.29,
        "涨跌幅": 6.14,
        "开盘价": 4.73,
        "最高价": 5.01,
        "最低价": 4.72,
        "昨收价": 4.72,
        "总市值": 1748028293.0,
        "市盈率": 96.0,
        "成交量": 2283471.0,
        "成交额": 11255578.0,
        "振幅": 6.14,
        "换手率": 0.65,
        "symbol": "106.HBM"
    },
    {
        "序号": 373,
        "name": "博实乐",
        "最新价": 1.21,
        "涨跌额": 0.07,
        "涨跌幅": 6.14,
        "开盘价": 1.25,
        "最高价": 1.25,
        "最低价": 1.16,
        "昨收价": 1.14,
        "总市值": 35968707.0,
        "市盈率": -0.65,
        "成交量": 1002.0,
        "成交额": 1194.0,
        "振幅": 7.89,
        "换手率": 0.0,
        "symbol": "106.BEDU"
    },
    {
        "序号": 374,
        "name": "Aurora Cannabis",
        "最新价": 0.52,
        "涨跌额": 0.03,
        "涨跌幅": 6.12,
        "开盘价": 0.49,
        "最高价": 0.52,
        "最低价": 0.48,
        "昨收价": 0.49,
        "总市值": 247026000.0,
        "市盈率": -0.18,
        "成交量": 8490181.0,
        "成交额": 4306117.0,
        "振幅": 8.16,
        "换手率": 1.79,
        "symbol": "105.ACB"
    },
    {
        "序号": 375,
        "name": "西佳科技",
        "最新价": 6.25,
        "涨跌额": 0.36,
        "涨跌幅": 6.11,
        "开盘价": 5.86,
        "最高价": 6.54,
        "最低价": 5.81,
        "昨收价": 5.89,
        "总市值": 444322600.0,
        "市盈率": -88.89,
        "成交量": 1927706.0,
        "成交额": 12061949.0,
        "振幅": 12.39,
        "换手率": 2.71,
        "symbol": "105.SIGA"
    },
    {
        "序号": 376,
        "name": "中国A股指数ETF-摩根士丹利",
        "最新价": 12.5,
        "涨跌额": 0.72,
        "涨跌幅": 6.11,
        "开盘价": 12.55,
        "最高价": 12.68,
        "最低价": 12.48,
        "昨收价": 11.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 175371.0,
        "成交额": 2196405.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "106.CAF"
    },
    {
        "序号": 377,
        "name": "BigBear.ai Holdings Inc",
        "最新价": 1.74,
        "涨跌额": 0.1,
        "涨跌幅": 6.1,
        "开盘价": 1.61,
        "最高价": 1.76,
        "最低价": 1.61,
        "昨收价": 1.64,
        "总市值": 272859525.0,
        "市盈率": -3.95,
        "成交量": 1669305.0,
        "成交额": 2856522.0,
        "振幅": 9.15,
        "换手率": 1.06,
        "symbol": "106.BBAI"
    },
    {
        "序号": 378,
        "name": "Joby Aviation Inc Wt",
        "最新价": 1.22,
        "涨跌额": 0.07,
        "涨跌幅": 6.09,
        "开盘价": 1.18,
        "最高价": 1.23,
        "最低价": 1.14,
        "昨收价": 1.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 27722.0,
        "成交额": 33031.0,
        "振幅": 7.83,
        "换手率": null,
        "symbol": "106.JOBY_WS"
    },
    {
        "序号": 379,
        "name": "单片机微系统",
        "最新价": 7.52,
        "涨跌额": 0.43,
        "涨跌幅": 6.06,
        "开盘价": 7.09,
        "最高价": 7.58,
        "最低价": 7.06,
        "昨收价": 7.09,
        "总市值": 174271120.0,
        "市盈率": -49.15,
        "成交量": 22331.0,
        "成交额": 165374.0,
        "振幅": 7.33,
        "换手率": 0.1,
        "symbol": "105.INVE"
    },
    {
        "序号": 380,
        "name": "BigBear.ai Holdings Inc Wt",
        "最新价": 0.35,
        "涨跌额": 0.02,
        "涨跌幅": 6.06,
        "开盘价": 0.33,
        "最高价": 0.36,
        "最低价": 0.31,
        "昨收价": 0.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 62334.0,
        "成交额": 21397.0,
        "振幅": 15.15,
        "换手率": null,
        "symbol": "106.BBAI_WS"
    },
    {
        "序号": 381,
        "name": "狮门娱乐-B",
        "最新价": 9.64,
        "涨跌额": 0.55,
        "涨跌幅": 6.05,
        "开盘价": 9.05,
        "最高价": 9.64,
        "最低价": 9.05,
        "昨收价": 9.09,
        "总市值": 2265513511.0,
        "市盈率": -2.18,
        "成交量": 1053073.0,
        "成交额": 9894785.0,
        "振幅": 6.49,
        "换手率": 0.7,
        "symbol": "106.LGF_B"
    },
    {
        "序号": 382,
        "name": "Socket Mobile Inc",
        "最新价": 1.23,
        "涨跌额": 0.07,
        "涨跌幅": 6.03,
        "开盘价": 1.24,
        "最高价": 1.25,
        "最低价": 1.23,
        "昨收价": 1.16,
        "总市值": 9007439.0,
        "市盈率": -3.89,
        "成交量": 7004.0,
        "成交额": 8703.0,
        "振幅": 1.72,
        "换手率": 0.1,
        "symbol": "105.SCKT"
    },
    {
        "序号": 383,
        "name": "Laser Photonics Corp",
        "最新价": 0.88,
        "涨跌额": 0.05,
        "涨跌幅": 6.02,
        "开盘价": 0.85,
        "最高价": 0.91,
        "最低价": 0.85,
        "昨收价": 0.83,
        "总市值": 8143009.0,
        "市盈率": -1.85,
        "成交量": 24417.0,
        "成交额": 21364.0,
        "振幅": 7.23,
        "换手率": 0.26,
        "symbol": "105.LASE"
    },
    {
        "序号": 384,
        "name": "Digital Turbine Inc",
        "最新价": 6.16,
        "涨跌额": 0.35,
        "涨跌幅": 6.02,
        "开盘价": 5.83,
        "最高价": 6.18,
        "最低价": 5.73,
        "昨收价": 5.81,
        "总市值": 624054871.0,
        "市盈率": -3.48,
        "成交量": 1984965.0,
        "成交额": 12067060.0,
        "振幅": 7.75,
        "换手率": 1.96,
        "symbol": "105.APPS"
    },
    {
        "序号": 385,
        "name": "华纳兄弟探索",
        "最新价": 11.47,
        "涨跌额": 0.65,
        "涨跌幅": 6.01,
        "开盘价": 11.0,
        "最高价": 11.68,
        "最低价": 11.0,
        "昨收价": 10.82,
        "总市值": 27970347868.0,
        "市盈率": -5.79,
        "成交量": 27965577.0,
        "成交额": 321034448.0,
        "振幅": 6.28,
        "换手率": 1.15,
        "symbol": "105.WBD"
    },
    {
        "序号": 386,
        "name": "Disc Medicine Inc",
        "最新价": 61.01,
        "涨跌额": 3.44,
        "涨跌幅": 5.98,
        "开盘价": 57.17,
        "最高价": 63.25,
        "最低价": 57.06,
        "昨收价": 57.57,
        "总市值": 1472807512.0,
        "市盈率": -22.98,
        "成交量": 365509.0,
        "成交额": 22010352.0,
        "振幅": 10.75,
        "换手率": 1.51,
        "symbol": "105.IRON"
    },
    {
        "序号": 387,
        "name": "比特数字",
        "最新价": 3.55,
        "涨跌额": 0.2,
        "涨跌幅": 5.97,
        "开盘价": 3.45,
        "最高价": 3.59,
        "最低价": 3.36,
        "昨收价": 3.35,
        "总市值": 322191010.0,
        "市盈率": -4.0,
        "成交量": 7768672.0,
        "成交额": 27154025.0,
        "振幅": 6.87,
        "换手率": 8.56,
        "symbol": "105.BTBT"
    },
    {
        "序号": 388,
        "name": "Beam Global",
        "最新价": 5.33,
        "涨跌额": 0.3,
        "涨跌幅": 5.96,
        "开盘价": 5.0,
        "最高价": 5.37,
        "最低价": 5.0,
        "昨收价": 5.03,
        "总市值": 75851449.0,
        "市盈率": -4.03,
        "成交量": 128647.0,
        "成交额": 673661.0,
        "振幅": 7.36,
        "换手率": 0.9,
        "symbol": "105.BEEM"
    },
    {
        "序号": 389,
        "name": "EverCommerce Inc",
        "最新价": 10.14,
        "涨跌额": 0.57,
        "涨跌幅": 5.96,
        "开盘价": 9.49,
        "最高价": 10.17,
        "最低价": 9.49,
        "昨收价": 9.57,
        "总市值": 1912998417.0,
        "市盈率": -47.76,
        "成交量": 146976.0,
        "成交额": 1465710.0,
        "振幅": 7.11,
        "换手率": 0.08,
        "symbol": "105.EVCM"
    },
    {
        "序号": 390,
        "name": "SEELAS Life Sciences Group Inc",
        "最新价": 1.07,
        "涨跌额": 0.06,
        "涨跌幅": 5.94,
        "开盘价": 1.03,
        "最高价": 1.08,
        "最低价": 1.02,
        "昨收价": 1.01,
        "总市值": 34306616.0,
        "市盈率": -0.9,
        "成交量": 304504.0,
        "成交额": 319439.0,
        "振幅": 5.94,
        "换手率": 0.95,
        "symbol": "105.SLS"
    },
    {
        "序号": 391,
        "name": "Owlet Inc",
        "最新价": 5.38,
        "涨跌额": 0.3,
        "涨跌幅": 5.91,
        "开盘价": 5.05,
        "最高价": 5.41,
        "最低价": 4.97,
        "昨收价": 5.08,
        "总市值": 46100122.0,
        "市盈率": -1.01,
        "成交量": 22849.0,
        "成交额": 120224.0,
        "振幅": 8.66,
        "换手率": 0.27,
        "symbol": "106.OWLT"
    },
    {
        "序号": 392,
        "name": "Selina Hospitality Plc",
        "最新价": 0.18,
        "涨跌额": 0.01,
        "涨跌幅": 5.88,
        "开盘价": 0.17,
        "最高价": 0.19,
        "最低价": 0.16,
        "昨收价": 0.17,
        "总市值": 19305672.0,
        "市盈率": -0.08,
        "成交量": 429648.0,
        "成交额": 75386.0,
        "振幅": 17.65,
        "换手率": 0.4,
        "symbol": "105.SLNA"
    },
    {
        "序号": 393,
        "name": "Nabors Energy Transition Corp W",
        "最新价": 0.18,
        "涨跌额": 0.01,
        "涨跌幅": 5.88,
        "开盘价": 0.18,
        "最高价": 0.18,
        "最低价": 0.18,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 2500.0,
        "成交额": 450.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.NETC_WS"
    },
    {
        "序号": 394,
        "name": "哈佛生物科学",
        "最新价": 5.22,
        "涨跌额": 0.29,
        "涨跌幅": 5.88,
        "开盘价": 4.98,
        "最高价": 5.3,
        "最低价": 4.91,
        "昨收价": 4.93,
        "总市值": 222832644.0,
        "市盈率": -68.29,
        "成交量": 113269.0,
        "成交额": 580025.0,
        "振幅": 7.91,
        "换手率": 0.27,
        "symbol": "105.HBIO"
    },
    {
        "序号": 395,
        "name": "Clean Earth Acquisitions Corp R",
        "最新价": 0.18,
        "涨跌额": 0.01,
        "涨跌幅": 5.88,
        "开盘价": 0.17,
        "最高价": 0.18,
        "最低价": 0.16,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 25044.0,
        "成交额": 4321.0,
        "振幅": 11.76,
        "换手率": null,
        "symbol": "105.CLINR"
    },
    {
        "序号": 396,
        "name": "Orchestra BioMed Holdings Inc",
        "最新价": 7.95,
        "涨跌额": 0.44,
        "涨跌幅": 5.86,
        "开盘价": 7.42,
        "最高价": 7.99,
        "最低价": 7.31,
        "昨收价": 7.51,
        "总市值": 284164577.0,
        "市盈率": -18.72,
        "成交量": 48655.0,
        "成交额": 370693.0,
        "振幅": 9.05,
        "换手率": 0.14,
        "symbol": "105.OBIO"
    },
    {
        "序号": 397,
        "name": "绿专资本",
        "最新价": 0.91,
        "涨跌额": 0.05,
        "涨跌幅": 5.81,
        "开盘价": 0.9,
        "最高价": 0.94,
        "最低价": 0.88,
        "昨收价": 0.86,
        "总市值": 6893990.0,
        "市盈率": 2.51,
        "成交量": 15786.0,
        "成交额": 14612.0,
        "振幅": 6.98,
        "换手率": 0.21,
        "symbol": "105.GRNQ"
    },
    {
        "序号": 398,
        "name": "红罗宾汉堡连琐店",
        "最新价": 10.77,
        "涨跌额": 0.59,
        "涨跌幅": 5.8,
        "开盘价": 10.2,
        "最高价": 10.83,
        "最低价": 10.2,
        "昨收价": 10.18,
        "总市值": 166719600.0,
        "市盈率": -3.26,
        "成交量": 147081.0,
        "成交额": 1570100.0,
        "振幅": 6.19,
        "换手率": 0.95,
        "symbol": "105.RRGB"
    },
    {
        "序号": 399,
        "name": "中国天然资源",
        "最新价": 1.83,
        "涨跌额": 0.1,
        "涨跌幅": 5.78,
        "开盘价": 1.76,
        "最高价": 1.83,
        "最低价": 1.76,
        "昨收价": 1.73,
        "总市值": 15002152.0,
        "市盈率": -4.24,
        "成交量": 402.0,
        "成交额": 721.0,
        "振幅": 4.05,
        "换手率": 0.0,
        "symbol": "105.CHNR"
    },
    {
        "序号": 400,
        "name": "Yext Inc",
        "最新价": 5.68,
        "涨跌额": 0.31,
        "涨跌幅": 5.77,
        "开盘价": 5.41,
        "最高价": 5.83,
        "最低价": 5.38,
        "昨收价": 5.37,
        "总市值": 704735310.0,
        "市盈率": -58.17,
        "成交量": 1922035.0,
        "成交额": 10915769.0,
        "振幅": 8.38,
        "换手率": 1.55,
        "symbol": "106.YEXT"
    },
    {
        "序号": 401,
        "name": "Moleculin Biotech Inc",
        "最新价": 0.55,
        "涨跌额": 0.03,
        "涨跌幅": 5.77,
        "开盘价": 0.5,
        "最高价": 0.55,
        "最低价": 0.49,
        "昨收价": 0.52,
        "总市值": 16395744.0,
        "市盈率": -0.62,
        "成交量": 263012.0,
        "成交额": 135823.0,
        "振幅": 11.54,
        "换手率": 0.88,
        "symbol": "105.MBRX"
    },
    {
        "序号": 402,
        "name": "阿提夫控股",
        "最新价": 1.1,
        "涨跌额": 0.06,
        "涨跌幅": 5.77,
        "开盘价": 1.02,
        "最高价": 1.1,
        "最低价": 0.95,
        "昨收价": 1.04,
        "总市值": 10590197.0,
        "市盈率": -3.67,
        "成交量": 1403.0,
        "成交额": 1425.0,
        "振幅": 14.42,
        "换手率": 0.01,
        "symbol": "105.ATIF"
    },
    {
        "序号": 403,
        "name": "Lavoro Ltd-A",
        "最新价": 6.97,
        "涨跌额": 0.38,
        "涨跌幅": 5.77,
        "开盘价": 6.31,
        "最高价": 6.97,
        "最低价": 6.3,
        "昨收价": 6.59,
        "总市值": 812760053.0,
        "市盈率": null,
        "成交量": 103539.0,
        "成交额": 684957.0,
        "振幅": 10.17,
        "换手率": 0.09,
        "symbol": "105.LVRO"
    },
    {
        "序号": 404,
        "name": "Schwab Crypto Thematic ETF",
        "最新价": 28.56,
        "涨跌额": 1.55,
        "涨跌幅": 5.74,
        "开盘价": 27.0,
        "最高价": 28.56,
        "最低价": 27.0,
        "昨收价": 27.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 44529.0,
        "成交额": 1262060.0,
        "振幅": 5.78,
        "换手率": null,
        "symbol": "107.STCE"
    },
    {
        "序号": 405,
        "name": "Korro Bio Inc",
        "最新价": 50.23,
        "涨跌额": 2.72,
        "涨跌幅": 5.73,
        "开盘价": 48.43,
        "最高价": 52.5,
        "最低价": 47.84,
        "昨收价": 47.51,
        "总市值": 402414531.0,
        "市盈率": -6.78,
        "成交量": 39737.0,
        "成交额": 1982830.0,
        "振幅": 9.81,
        "换手率": 0.5,
        "symbol": "105.KRRO"
    },
    {
        "序号": 406,
        "name": "Sabre Corp",
        "最新价": 3.88,
        "涨跌额": 0.21,
        "涨跌幅": 5.72,
        "开盘价": 3.67,
        "最高价": 3.97,
        "最低价": 3.64,
        "昨收价": 3.67,
        "总市值": 1472387238.0,
        "市盈率": -2.49,
        "成交量": 5759751.0,
        "成交额": 22303819.0,
        "振幅": 8.99,
        "换手率": 1.52,
        "symbol": "105.SABR"
    },
    {
        "序号": 407,
        "name": "理臣咨询",
        "最新价": 1.85,
        "涨跌额": 0.1,
        "涨跌幅": 5.71,
        "开盘价": 1.8,
        "最高价": 1.9,
        "最低价": 1.8,
        "昨收价": 1.75,
        "总市值": 49025000.0,
        "市盈率": 10.12,
        "成交量": 107950.0,
        "成交额": 200385.0,
        "振幅": 5.71,
        "换手率": 0.41,
        "symbol": "105.LICN"
    },
    {
        "序号": 408,
        "name": "Northann Corp",
        "最新价": 11.29,
        "涨跌额": 0.61,
        "涨跌幅": 5.71,
        "开盘价": 10.68,
        "最高价": 11.29,
        "最低价": 10.5,
        "昨收价": 10.68,
        "总市值": 241380200.0,
        "市盈率": -88.15,
        "成交量": 15406.0,
        "成交额": 168168.0,
        "振幅": 7.4,
        "换手率": 0.07,
        "symbol": "107.NCL"
    },
    {
        "序号": 409,
        "name": "Instil Bio Inc",
        "最新价": 6.67,
        "涨跌额": 0.36,
        "涨跌幅": 5.71,
        "开盘价": 6.2,
        "最高价": 6.77,
        "最低价": 6.2,
        "昨收价": 6.31,
        "总市值": 43355000.0,
        "市盈率": -0.22,
        "成交量": 67592.0,
        "成交额": 442437.0,
        "振幅": 9.03,
        "换手率": 1.04,
        "symbol": "105.TIL"
    },
    {
        "序号": 410,
        "name": "Cellectis SA ADR",
        "最新价": 2.97,
        "涨跌额": 0.16,
        "涨跌幅": 5.69,
        "开盘价": 2.85,
        "最高价": 2.99,
        "最低价": 2.81,
        "昨收价": 2.81,
        "总市值": 165083791.0,
        "市盈率": -1.94,
        "成交量": 113887.0,
        "成交额": 333724.0,
        "振幅": 6.41,
        "换手率": 0.2,
        "symbol": "105.CLLS"
    },
    {
        "序号": 411,
        "name": "Eventbrite Inc-A",
        "最新价": 7.8,
        "涨跌额": 0.42,
        "涨跌幅": 5.69,
        "开盘价": 7.86,
        "最高价": 8.39,
        "最低价": 7.73,
        "昨收价": 7.38,
        "总市值": 784860944.0,
        "市盈率": -36.46,
        "成交量": 1730490.0,
        "成交额": 13743348.0,
        "振幅": 8.94,
        "换手率": 1.72,
        "symbol": "106.EB"
    },
    {
        "序号": 412,
        "name": "Heron Therapeutics Inc",
        "最新价": 1.49,
        "涨跌额": 0.08,
        "涨跌幅": 5.67,
        "开盘价": 1.4,
        "最高价": 1.49,
        "最低价": 1.37,
        "昨收价": 1.41,
        "总市值": 223608234.0,
        "市盈率": -1.87,
        "成交量": 1746385.0,
        "成交额": 2531731.0,
        "振幅": 8.51,
        "换手率": 1.16,
        "symbol": "105.HRTX"
    },
    {
        "序号": 413,
        "name": "明珠货运",
        "最新价": 0.56,
        "涨跌额": 0.03,
        "涨跌幅": 5.66,
        "开盘价": 0.55,
        "最高价": 0.57,
        "最低价": 0.53,
        "昨收价": 0.53,
        "总市值": 12857755.0,
        "市盈率": 6.78,
        "成交量": 13282.0,
        "成交额": 7348.0,
        "振幅": 7.55,
        "换手率": 0.06,
        "symbol": "105.YGMZ"
    },
    {
        "序号": 414,
        "name": "Super League Enterprise Inc",
        "最新价": 1.68,
        "涨跌额": 0.09,
        "涨跌幅": 5.66,
        "开盘价": 1.68,
        "最高价": 1.68,
        "最低价": 1.56,
        "昨收价": 1.59,
        "总市值": 7114797.0,
        "市盈率": -0.21,
        "成交量": 24521.0,
        "成交额": 40133.0,
        "振幅": 7.55,
        "换手率": 0.58,
        "symbol": "105.SLE"
    },
    {
        "序号": 415,
        "name": "FTC Solar Inc",
        "最新价": 0.75,
        "涨跌额": 0.04,
        "涨跌幅": 5.63,
        "开盘价": 0.69,
        "最高价": 0.76,
        "最低价": 0.69,
        "昨收价": 0.71,
        "总市值": 93754365.0,
        "市盈率": -1.57,
        "成交量": 1154556.0,
        "成交额": 836822.0,
        "振幅": 9.86,
        "换手率": 0.92,
        "symbol": "105.FTCI"
    },
    {
        "序号": 416,
        "name": "Trinseo PLC",
        "最新价": 6.39,
        "涨跌额": 0.34,
        "涨跌幅": 5.62,
        "开盘价": 6.08,
        "最高价": 6.49,
        "最低价": 5.97,
        "昨收价": 6.05,
        "总市值": 224897616.0,
        "市盈率": -0.28,
        "成交量": 262512.0,
        "成交额": 1669915.0,
        "振幅": 8.6,
        "换手率": 0.75,
        "symbol": "106.TSE"
    },
    {
        "序号": 417,
        "name": "Achilles Therapeutics plc ADR",
        "最新价": 0.94,
        "涨跌额": 0.05,
        "涨跌幅": 5.62,
        "开盘价": 0.91,
        "最高价": 0.98,
        "最低价": 0.89,
        "昨收价": 0.89,
        "总市值": 38335971.0,
        "市盈率": -0.51,
        "成交量": 278609.0,
        "成交额": 260794.0,
        "振幅": 10.11,
        "换手率": 0.68,
        "symbol": "105.ACHL"
    },
    {
        "序号": 418,
        "name": "能链智电",
        "最新价": 2.08,
        "涨跌额": 0.11,
        "涨跌幅": 5.58,
        "开盘价": 2.03,
        "最高价": 2.13,
        "最低价": 1.93,
        "昨收价": 1.97,
        "总市值": 494949336.0,
        "市盈率": -3.38,
        "成交量": 955075.0,
        "成交额": 1976902.0,
        "振幅": 10.15,
        "换手率": 0.4,
        "symbol": "105.NAAS"
    },
    {
        "序号": 419,
        "name": "Lucid Group Inc-A",
        "最新价": 4.73,
        "涨跌额": 0.25,
        "涨跌幅": 5.58,
        "开盘价": 4.47,
        "最高价": 4.75,
        "最低价": 4.44,
        "昨收价": 4.48,
        "总市值": 10829000244.0,
        "市盈率": -4.09,
        "成交量": 34653287.0,
        "成交额": 160471426.0,
        "振幅": 6.92,
        "换手率": 1.51,
        "symbol": "105.LCID"
    },
    {
        "序号": 420,
        "name": "Cellectar Biosciences Inc",
        "最新价": 2.65,
        "涨跌额": 0.14,
        "涨跌幅": 5.58,
        "开盘价": 2.51,
        "最高价": 2.65,
        "最低价": 2.45,
        "昨收价": 2.51,
        "总市值": 32564061.0,
        "市盈率": -0.86,
        "成交量": 309159.0,
        "成交额": 797281.0,
        "振幅": 7.97,
        "换手率": 2.52,
        "symbol": "105.CLRB"
    },
    {
        "序号": 421,
        "name": "ZimVie Inc",
        "最新价": 11.19,
        "涨跌额": 0.59,
        "涨跌幅": 5.57,
        "开盘价": 10.52,
        "最高价": 11.22,
        "最低价": 10.36,
        "昨收价": 10.6,
        "总市值": 296938634.0,
        "市盈率": -3.34,
        "成交量": 309451.0,
        "成交额": 3419239.0,
        "振幅": 8.11,
        "换手率": 1.17,
        "symbol": "105.ZIMV"
    },
    {
        "序号": 422,
        "name": "PetVivo Holdings Inc Wt",
        "最新价": 0.19,
        "涨跌额": 0.01,
        "涨跌幅": 5.56,
        "开盘价": 0.19,
        "最高价": 0.19,
        "最低价": 0.19,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 19.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PETVW"
    },
    {
        "序号": 423,
        "name": "Haymaker Acquisition Corp 4 Wt",
        "最新价": 0.19,
        "涨跌额": 0.01,
        "涨跌幅": 5.56,
        "开盘价": 0.19,
        "最高价": 0.19,
        "最低价": 0.19,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 66600.0,
        "成交额": 12321.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.HYAC_WS"
    },
    {
        "序号": 424,
        "name": "数海信息",
        "最新价": 0.19,
        "涨跌额": 0.01,
        "涨跌幅": 5.56,
        "开盘价": 0.18,
        "最高价": 0.2,
        "最低价": 0.18,
        "昨收价": 0.18,
        "总市值": 7233914.0,
        "市盈率": -0.89,
        "成交量": 802023.0,
        "成交额": 152650.0,
        "振幅": 11.11,
        "换手率": 2.11,
        "symbol": "105.DTSS"
    },
    {
        "序号": 425,
        "name": "YieldMax COIN Option Income Str",
        "最新价": 24.32,
        "涨跌额": 1.28,
        "涨跌幅": 5.56,
        "开盘价": 23.22,
        "最高价": 24.38,
        "最低价": 23.09,
        "昨收价": 23.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 452655.0,
        "成交额": 10799531.0,
        "振幅": 5.6,
        "换手率": null,
        "symbol": "107.CONY"
    },
    {
        "序号": 426,
        "name": "必迈医药",
        "最新价": 2.09,
        "涨跌额": 0.11,
        "涨跌幅": 5.56,
        "开盘价": 2.0,
        "最高价": 2.18,
        "最低价": 2.0,
        "昨收价": 1.98,
        "总市值": 13772199.0,
        "市盈率": -1.22,
        "成交量": 17533.0,
        "成交额": 35966.0,
        "振幅": 9.09,
        "换手率": 0.27,
        "symbol": "105.BIMI"
    },
    {
        "序号": 427,
        "name": "Algoma Steel Group Inc Wt",
        "最新价": 1.14,
        "涨跌额": 0.06,
        "涨跌幅": 5.56,
        "开盘价": 1.16,
        "最高价": 1.17,
        "最低价": 1.08,
        "昨收价": 1.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 104070.0,
        "成交额": 118191.0,
        "振幅": 8.33,
        "换手率": null,
        "symbol": "105.ASTLW"
    },
    {
        "序号": 428,
        "name": "G-III Apparel Group Ltd",
        "最新价": 31.74,
        "涨跌额": 1.67,
        "涨跌幅": 5.55,
        "开盘价": 30.15,
        "最高价": 31.78,
        "最低价": 30.09,
        "昨收价": 30.07,
        "总市值": 1451395992.0,
        "市盈率": -12.75,
        "成交量": 758988.0,
        "成交额": 23829000.0,
        "振幅": 5.62,
        "换手率": 1.66,
        "symbol": "105.GIII"
    },
    {
        "序号": 429,
        "name": "Nuvei Corp",
        "最新价": 23.78,
        "涨跌额": 1.25,
        "涨跌幅": 5.55,
        "开盘价": 22.27,
        "最高价": 23.88,
        "最低价": 21.92,
        "昨收价": 22.53,
        "总市值": 3308959605.0,
        "市盈率": -284.54,
        "成交量": 687963.0,
        "成交额": 16014937.0,
        "振幅": 8.7,
        "换手率": 0.49,
        "symbol": "105.NVEI"
    },
    {
        "序号": 430,
        "name": "First Trust SkyBridge Crypto In",
        "最新价": 8.38,
        "涨跌额": 0.44,
        "涨跌幅": 5.54,
        "开盘价": 7.93,
        "最高价": 8.4,
        "最低价": 7.93,
        "昨收价": 7.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 32465.0,
        "成交额": 267904.0,
        "振幅": 5.92,
        "换手率": null,
        "symbol": "107.CRPT"
    },
    {
        "序号": 431,
        "name": "Zentalis Pharmaceuticals Inc",
        "最新价": 12.96,
        "涨跌额": 0.68,
        "涨跌幅": 5.54,
        "开盘价": 12.21,
        "最高价": 13.08,
        "最低价": 11.85,
        "昨收价": 12.28,
        "总市值": 917124392.0,
        "市盈率": -3.2,
        "成交量": 847948.0,
        "成交额": 10855105.0,
        "振幅": 10.02,
        "换手率": 1.2,
        "symbol": "105.ZNTL"
    },
    {
        "序号": 432,
        "name": "Minerva Neurosciences Inc",
        "最新价": 6.3,
        "涨跌额": 0.33,
        "涨跌幅": 5.53,
        "开盘价": 5.97,
        "最高价": 6.48,
        "最低价": 5.97,
        "昨收价": 5.97,
        "总市值": 44058458.0,
        "市盈率": -1.59,
        "成交量": 22863.0,
        "成交额": 143425.0,
        "振幅": 8.54,
        "换手率": 0.33,
        "symbol": "105.NERV"
    },
    {
        "序号": 433,
        "name": "Alector Inc",
        "最新价": 6.12,
        "涨跌额": 0.32,
        "涨跌幅": 5.52,
        "开盘价": 5.74,
        "最高价": 6.39,
        "最低价": 5.65,
        "昨收价": 5.8,
        "总市值": 514914652.0,
        "市盈率": -3.64,
        "成交量": 668308.0,
        "成交额": 4066901.0,
        "振幅": 12.76,
        "换手率": 0.79,
        "symbol": "105.ALEC"
    },
    {
        "序号": 434,
        "name": "Trevi Therapeutics Inc",
        "最新价": 1.15,
        "涨跌额": 0.06,
        "涨跌幅": 5.5,
        "开盘价": 1.17,
        "最高价": 1.18,
        "最低价": 1.13,
        "昨收价": 1.09,
        "总市值": 73436265.0,
        "市盈率": -2.75,
        "成交量": 187701.0,
        "成交额": 215919.0,
        "振幅": 4.59,
        "换手率": 0.29,
        "symbol": "105.TRVI"
    },
    {
        "序号": 435,
        "name": "美国像素",
        "最新价": 1.15,
        "涨跌额": 0.06,
        "涨跌幅": 5.5,
        "开盘价": 1.09,
        "最高价": 1.17,
        "最低价": 1.09,
        "昨收价": 1.09,
        "总市值": 65197545.0,
        "市盈率": -2.68,
        "成交量": 127304.0,
        "成交额": 145716.0,
        "振幅": 7.34,
        "换手率": 0.22,
        "symbol": "105.PXLW"
    },
    {
        "序号": 436,
        "name": "艾勒朗制药",
        "最新价": 2.3,
        "涨跌额": 0.12,
        "涨跌幅": 5.5,
        "开盘价": 2.11,
        "最高价": 2.43,
        "最低价": 2.11,
        "昨收价": 2.18,
        "总市值": 11237186.0,
        "市盈率": -0.87,
        "成交量": 26567.0,
        "成交额": 61964.0,
        "振幅": 14.68,
        "换手率": 0.54,
        "symbol": "105.ALRN"
    },
    {
        "序号": 437,
        "name": "Bluejay Diagnostics Inc",
        "最新价": 3.26,
        "涨跌额": 0.17,
        "涨跌幅": 5.5,
        "开盘价": 3.15,
        "最高价": 3.31,
        "最低价": 3.12,
        "昨收价": 3.09,
        "总市值": 4039596.0,
        "市盈率": -0.4,
        "成交量": 4237.0,
        "成交额": 13831.0,
        "振幅": 6.15,
        "换手率": 0.34,
        "symbol": "105.BJDX"
    },
    {
        "序号": 438,
        "name": "Bite Acquisition Corp Unit Cons",
        "最新价": 10.55,
        "涨跌额": 0.55,
        "涨跌幅": 5.5,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.55,
        "昨收价": 10.0,
        "总市值": 89866778.0,
        "市盈率": -137.98,
        "成交量": 360.0,
        "成交额": 3798.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BITE_U"
    },
    {
        "序号": 439,
        "name": "G. Willi-Food International Ltd",
        "最新价": 9.44,
        "涨跌额": 0.49,
        "涨跌幅": 5.47,
        "开盘价": 9.03,
        "最高价": 9.44,
        "最低价": 9.03,
        "昨收价": 8.95,
        "总市值": 130904640.0,
        "市盈率": 12.88,
        "成交量": 1458.0,
        "成交额": 13703.0,
        "振幅": 4.58,
        "换手率": 0.01,
        "symbol": "105.WILC"
    },
    {
        "序号": 440,
        "name": "Rallybio Corp",
        "最新价": 2.89,
        "涨跌额": 0.15,
        "涨跌幅": 5.47,
        "开盘价": 2.67,
        "最高价": 2.96,
        "最低价": 2.65,
        "昨收价": 2.74,
        "总市值": 109223807.0,
        "市盈率": -1.55,
        "成交量": 78441.0,
        "成交额": 223159.0,
        "振幅": 11.31,
        "换手率": 0.21,
        "symbol": "105.RLYB"
    },
    {
        "序号": 441,
        "name": "Royalty Management Holding Corp",
        "最新价": 3.28,
        "涨跌额": 0.17,
        "涨跌幅": 5.47,
        "开盘价": 3.0,
        "最高价": 3.44,
        "最低价": 3.0,
        "昨收价": 3.11,
        "总市值": 46808096.0,
        "市盈率": -95.27,
        "成交量": 44327.0,
        "成交额": 144571.0,
        "振幅": 14.15,
        "换手率": 0.31,
        "symbol": "105.RMCO"
    },
    {
        "序号": 442,
        "name": "Ambrx Biopharma Inc",
        "最新价": 13.34,
        "涨跌额": 0.69,
        "涨跌幅": 5.45,
        "开盘价": 12.77,
        "最高价": 13.61,
        "最低价": 12.35,
        "昨收价": 12.65,
        "总市值": 842424255.0,
        "市盈率": -14.13,
        "成交量": 1271756.0,
        "成交额": 16592619.0,
        "振幅": 9.96,
        "换手率": 2.01,
        "symbol": "105.AMAM"
    },
    {
        "序号": 443,
        "name": "Superior Industries Internation",
        "最新价": 3.1,
        "涨跌额": 0.16,
        "涨跌幅": 5.44,
        "开盘价": 2.91,
        "最高价": 3.12,
        "最低价": 2.86,
        "昨收价": 2.94,
        "总市值": 87083464.0,
        "市盈率": -1.18,
        "成交量": 32078.0,
        "成交额": 96683.0,
        "振幅": 8.84,
        "换手率": 0.11,
        "symbol": "106.SUP"
    },
    {
        "序号": 444,
        "name": "Outbrain Inc",
        "最新价": 4.07,
        "涨跌额": 0.21,
        "涨跌幅": 5.44,
        "开盘价": 4.07,
        "最高价": 4.08,
        "最低价": 3.81,
        "昨收价": 3.86,
        "总市值": 204132535.0,
        "市盈率": -130.52,
        "成交量": 91897.0,
        "成交额": 369969.0,
        "振幅": 6.99,
        "换手率": 0.18,
        "symbol": "105.OB"
    },
    {
        "序号": 445,
        "name": "Amprius Technologies Inc",
        "最新价": 4.07,
        "涨跌额": 0.21,
        "涨跌幅": 5.44,
        "开盘价": 3.83,
        "最高价": 4.09,
        "最低价": 3.83,
        "昨收价": 3.86,
        "总市值": 493500841.0,
        "市盈率": -14.91,
        "成交量": 169263.0,
        "成交额": 678735.0,
        "振幅": 6.74,
        "换手率": 0.19,
        "symbol": "106.AMPX"
    },
    {
        "序号": 446,
        "name": "Iterum Therapeutics plc",
        "最新价": 1.75,
        "涨跌额": 0.09,
        "涨跌幅": 5.42,
        "开盘价": 1.66,
        "最高价": 1.79,
        "最低价": 1.65,
        "昨收价": 1.66,
        "总市值": 22837512.0,
        "市盈率": -0.73,
        "成交量": 226237.0,
        "成交额": 376426.0,
        "振幅": 8.43,
        "换手率": 1.73,
        "symbol": "105.ITRM"
    },
    {
        "序号": 447,
        "name": "Third Harmonic Bio Inc",
        "最新价": 9.95,
        "涨跌额": 0.51,
        "涨跌幅": 5.4,
        "开盘价": 9.53,
        "最高价": 10.12,
        "最低价": 9.47,
        "昨收价": 9.44,
        "总市值": 401607591.0,
        "市盈率": -11.32,
        "成交量": 68015.0,
        "成交额": 672313.0,
        "振幅": 6.89,
        "换手率": 0.17,
        "symbol": "105.THRD"
    },
    {
        "序号": 448,
        "name": "Clever Leaves Holdings Inc",
        "最新价": 2.15,
        "涨跌额": 0.11,
        "涨跌幅": 5.39,
        "开盘价": 2.01,
        "最高价": 2.2,
        "最低价": 2.01,
        "昨收价": 2.04,
        "总市值": 3752077.0,
        "市盈率": -0.09,
        "成交量": 10856.0,
        "成交额": 22604.0,
        "振幅": 9.31,
        "换手率": 0.62,
        "symbol": "105.CLVR"
    },
    {
        "序号": 449,
        "name": "PureTech Health PLC ADR",
        "最新价": 18.0,
        "涨跌额": 0.92,
        "涨跌幅": 5.39,
        "开盘价": 17.6,
        "最高价": 18.0,
        "最低价": 17.57,
        "昨收价": 17.08,
        "总市值": 490889763.0,
        "市盈率": -10.44,
        "成交量": 2074.0,
        "成交额": 36741.0,
        "振幅": 2.52,
        "换手率": 0.01,
        "symbol": "105.PRTC"
    },
    {
        "序号": 450,
        "name": "Grayscale Future of Finance ETF",
        "最新价": 16.24,
        "涨跌额": 0.83,
        "涨跌幅": 5.39,
        "开盘价": 15.98,
        "最高价": 16.24,
        "最低价": 15.81,
        "昨收价": 15.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 5541.0,
        "成交额": 88627.0,
        "振幅": 2.79,
        "换手率": null,
        "symbol": "107.GFOF"
    },
    {
        "序号": 451,
        "name": "lululemon athletica inc",
        "最新价": 489.64,
        "涨跌额": 24.97,
        "涨跌幅": 5.37,
        "开盘价": 455.88,
        "最高价": 493.8,
        "最低价": 448.81,
        "昨收价": 464.67,
        "总市值": 59283058217.0,
        "市盈率": 59.25,
        "成交量": 7144393.0,
        "成交额": 3449652592.0,
        "振幅": 9.68,
        "换手率": 5.9,
        "symbol": "105.LULU"
    },
    {
        "序号": 452,
        "name": "Athira Pharma Inc",
        "最新价": 1.97,
        "涨跌额": 0.1,
        "涨跌幅": 5.35,
        "开盘价": 1.85,
        "最高价": 1.99,
        "最低价": 1.83,
        "昨收价": 1.87,
        "总市值": 74967529.0,
        "市盈率": -0.62,
        "成交量": 298247.0,
        "成交额": 572854.0,
        "振幅": 8.56,
        "换手率": 0.78,
        "symbol": "105.ATHA"
    },
    {
        "序号": 453,
        "name": "Petros Pharmaceuticals Inc",
        "最新价": 1.38,
        "涨跌额": 0.07,
        "涨跌幅": 5.34,
        "开盘价": 1.41,
        "最高价": 1.46,
        "最低价": 1.3,
        "昨收价": 1.31,
        "总市值": 3037475.0,
        "市盈率": -0.24,
        "成交量": 128943.0,
        "成交额": 179314.0,
        "振幅": 12.21,
        "换手率": 5.86,
        "symbol": "105.PTPI"
    },
    {
        "序号": 454,
        "name": "Mercurity Fintech Holding Inc",
        "最新价": 1.78,
        "涨跌额": 0.09,
        "涨跌幅": 5.33,
        "开盘价": 1.66,
        "最高价": 1.83,
        "最低价": 1.66,
        "昨收价": 1.69,
        "总市值": 82855646.0,
        "市盈率": -14.7,
        "成交量": 36038.0,
        "成交额": 61947.0,
        "振幅": 10.06,
        "换手率": 0.08,
        "symbol": "105.MFH"
    },
    {
        "序号": 455,
        "name": "Quad\/Graphics Inc-A",
        "最新价": 5.15,
        "涨跌额": 0.26,
        "涨跌幅": 5.32,
        "开盘价": 4.84,
        "最高价": 5.26,
        "最低价": 4.84,
        "昨收价": 4.89,
        "总市值": 264238090.0,
        "市盈率": -6.28,
        "成交量": 165228.0,
        "成交额": 846652.0,
        "振幅": 8.59,
        "换手率": 0.32,
        "symbol": "106.QUAD"
    },
    {
        "序号": 456,
        "name": "Bally's Corp",
        "最新价": 12.69,
        "涨跌额": 0.64,
        "涨跌幅": 5.31,
        "开盘价": 12.06,
        "最高价": 12.88,
        "最低价": 12.06,
        "昨收价": 12.05,
        "总市值": 578949335.0,
        "市盈率": -1.46,
        "成交量": 511356.0,
        "成交额": 6451520.0,
        "振幅": 6.8,
        "换手率": 1.12,
        "symbol": "106.BALY"
    },
    {
        "序号": 457,
        "name": "Enviva Inc",
        "最新价": 1.19,
        "涨跌额": 0.06,
        "涨跌幅": 5.31,
        "开盘价": 1.14,
        "最高价": 1.2,
        "最低价": 1.07,
        "昨收价": 1.13,
        "总市值": 88650879.0,
        "市盈率": -0.26,
        "成交量": 945606.0,
        "成交额": 1083612.0,
        "振幅": 11.5,
        "换手率": 1.27,
        "symbol": "106.EVA"
    },
    {
        "序号": 458,
        "name": "Inspirato Inc-A",
        "最新价": 4.17,
        "涨跌额": 0.21,
        "涨跌幅": 5.3,
        "开盘价": 4.17,
        "最高价": 4.22,
        "最低价": 3.98,
        "昨收价": 3.96,
        "总市值": 26687037.0,
        "市盈率": -0.54,
        "成交量": 9114.0,
        "成交额": 36984.0,
        "振幅": 6.06,
        "换手率": 0.14,
        "symbol": "105.ISPO"
    },
    {
        "序号": 459,
        "name": "Rackspace Technology Inc",
        "最新价": 1.59,
        "涨跌额": 0.08,
        "涨跌幅": 5.3,
        "开盘价": 1.49,
        "最高价": 1.61,
        "最低价": 1.47,
        "昨收价": 1.51,
        "总市值": 344086923.0,
        "市盈率": -0.32,
        "成交量": 1158514.0,
        "成交额": 1822335.0,
        "振幅": 9.27,
        "换手率": 0.54,
        "symbol": "105.RXT"
    },
    {
        "序号": 460,
        "name": "Taysha Gene Therapies Inc",
        "最新价": 1.79,
        "涨跌额": 0.09,
        "涨跌幅": 5.29,
        "开盘价": 1.67,
        "最高价": 1.8,
        "最低价": 1.67,
        "昨收价": 1.7,
        "总市值": 334658745.0,
        "市盈率": -1.56,
        "成交量": 1197195.0,
        "成交额": 2090883.0,
        "振幅": 7.65,
        "换手率": 0.64,
        "symbol": "105.TSHA"
    },
    {
        "序号": 461,
        "name": "Nephros Inc",
        "最新价": 2.19,
        "涨跌额": 0.11,
        "涨跌幅": 5.29,
        "开盘价": 2.05,
        "最高价": 2.2,
        "最低价": 2.05,
        "昨收价": 2.08,
        "总市值": 22962001.0,
        "市盈率": -12.49,
        "成交量": 62169.0,
        "成交额": 129876.0,
        "振幅": 7.21,
        "换手率": 0.59,
        "symbol": "105.NEPH"
    },
    {
        "序号": 462,
        "name": "MediWound Ltd",
        "最新价": 9.76,
        "涨跌额": 0.49,
        "涨跌幅": 5.29,
        "开盘价": 9.3,
        "最高价": 9.79,
        "最低价": 9.3,
        "昨收价": 9.27,
        "总市值": 89979987.0,
        "市盈率": -7.24,
        "成交量": 11957.0,
        "成交额": 115935.0,
        "振幅": 5.29,
        "换手率": 0.13,
        "symbol": "105.MDWD"
    },
    {
        "序号": 463,
        "name": "Able View Global Inc-B",
        "最新价": 2.99,
        "涨跌额": 0.15,
        "涨跌幅": 5.28,
        "开盘价": 2.85,
        "最高价": 2.99,
        "最低价": 2.82,
        "昨收价": 2.84,
        "总市值": 126654107.0,
        "市盈率": 107.91,
        "成交量": 12780.0,
        "成交额": 37428.0,
        "振幅": 5.99,
        "换手率": 0.03,
        "symbol": "105.ABLV"
    },
    {
        "序号": 464,
        "name": "南方铜业",
        "最新价": 75.45,
        "涨跌额": 3.78,
        "涨跌幅": 5.27,
        "开盘价": 72.13,
        "最高价": 76.1,
        "最低价": 71.76,
        "昨收价": 71.67,
        "总市值": 58331184886.0,
        "市盈率": 20.24,
        "成交量": 1484903.0,
        "成交额": 111931703.0,
        "振幅": 6.06,
        "换手率": 0.19,
        "symbol": "106.SCCO"
    },
    {
        "序号": 465,
        "name": "Locafy Ltd",
        "最新价": 7.79,
        "涨跌额": 0.39,
        "涨跌幅": 5.27,
        "开盘价": 7.39,
        "最高价": 7.88,
        "最低价": 7.33,
        "昨收价": 7.4,
        "总市值": 9941972.0,
        "市盈率": -4.24,
        "成交量": 8064.0,
        "成交额": 61167.0,
        "振幅": 7.43,
        "换手率": 0.63,
        "symbol": "105.LCFY"
    },
    {
        "序号": 466,
        "name": "互联电子",
        "最新价": 11.79,
        "涨跌额": 0.59,
        "涨跌幅": 5.27,
        "开盘价": 11.2,
        "最高价": 12.03,
        "最低价": 11.03,
        "昨收价": 11.2,
        "总市值": 77502390.0,
        "市盈率": 52.47,
        "成交量": 7416.0,
        "成交额": 84760.0,
        "振幅": 8.93,
        "换手率": 0.11,
        "symbol": "105.LINK"
    },
    {
        "序号": 467,
        "name": "Tonix Pharmaceuticals Holding C",
        "最新价": 0.4,
        "涨跌额": 0.02,
        "涨跌幅": 5.26,
        "开盘价": 0.4,
        "最高价": 0.42,
        "最低价": 0.38,
        "昨收价": 0.38,
        "总市值": 9539736.0,
        "市盈率": -0.08,
        "成交量": 2273491.0,
        "成交额": 899759.0,
        "振幅": 10.53,
        "换手率": 9.53,
        "symbol": "105.TNXP"
    },
    {
        "序号": 468,
        "name": "SaverOne 2014 Ltd ADR",
        "最新价": 0.4,
        "涨跌额": 0.02,
        "涨跌幅": 5.26,
        "开盘价": 0.35,
        "最高价": 0.47,
        "最低价": 0.35,
        "昨收价": 0.38,
        "总市值": 3096399.0,
        "市盈率": -0.36,
        "成交量": 499440.0,
        "成交额": 206307.0,
        "振幅": 31.58,
        "换手率": 6.45,
        "symbol": "105.SVRE"
    },
    {
        "序号": 469,
        "name": "Pieris Pharmaceuticals Inc",
        "最新价": 0.2,
        "涨跌额": 0.01,
        "涨跌幅": 5.26,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.19,
        "昨收价": 0.19,
        "总市值": 19770385.0,
        "市盈率": -0.7,
        "成交量": 258456.0,
        "成交额": 50184.0,
        "振幅": 5.26,
        "换手率": 0.26,
        "symbol": "105.PIRS"
    },
    {
        "序号": 470,
        "name": "InMed Pharmaceuticals Inc",
        "最新价": 0.4,
        "涨跌额": 0.02,
        "涨跌幅": 5.26,
        "开盘价": 0.41,
        "最高价": 0.41,
        "最低价": 0.39,
        "昨收价": 0.38,
        "总市值": 2101988.0,
        "市盈率": -0.3,
        "成交量": 35832.0,
        "成交额": 13993.0,
        "振幅": 5.26,
        "换手率": 0.68,
        "symbol": "105.INM"
    },
    {
        "序号": 471,
        "name": "Gevo Inc",
        "最新价": 1.2,
        "涨跌额": 0.06,
        "涨跌幅": 5.26,
        "开盘价": 1.15,
        "最高价": 1.23,
        "最低价": 1.15,
        "昨收价": 1.14,
        "总市值": 288365682.0,
        "市盈率": -3.94,
        "成交量": 4035437.0,
        "成交额": 4795811.0,
        "振幅": 7.02,
        "换手率": 1.68,
        "symbol": "105.GEVO"
    },
    {
        "序号": 472,
        "name": "凤凰新媒体",
        "最新价": 1.4,
        "涨跌额": 0.07,
        "涨跌幅": 5.26,
        "开盘价": 1.35,
        "最高价": 1.4,
        "最低价": 1.33,
        "昨收价": 1.33,
        "总市值": 17011768.0,
        "市盈率": -1.73,
        "成交量": 20984.0,
        "成交额": 28859.0,
        "振幅": 5.26,
        "换手率": 0.17,
        "symbol": "106.FENG"
    },
    {
        "序号": 473,
        "name": "EpicQuest Education Group Inter",
        "最新价": 1.8,
        "涨跌额": 0.09,
        "涨跌幅": 5.26,
        "开盘价": 1.68,
        "最高价": 1.8,
        "最低价": 1.68,
        "昨收价": 1.71,
        "总市值": 21686711.0,
        "市盈率": -3.45,
        "成交量": 3348.0,
        "成交额": 5983.0,
        "振幅": 7.02,
        "换手率": 0.03,
        "symbol": "105.EEIQ"
    },
    {
        "序号": 474,
        "name": "Weave Communications Inc",
        "最新价": 10.06,
        "涨跌额": 0.5,
        "涨跌幅": 5.23,
        "开盘价": 9.55,
        "最高价": 10.12,
        "最低价": 9.44,
        "昨收价": 9.56,
        "总市值": 698908551.0,
        "市盈率": -21.01,
        "成交量": 540546.0,
        "成交额": 5391266.0,
        "振幅": 7.11,
        "换手率": 0.78,
        "symbol": "106.WEAV"
    },
    {
        "序号": 475,
        "name": "UiPath Inc-A",
        "最新价": 24.63,
        "涨跌额": 1.22,
        "涨跌幅": 5.21,
        "开盘价": 23.44,
        "最高价": 24.67,
        "最低价": 23.08,
        "昨收价": 23.41,
        "总市值": 13942507519.0,
        "市盈率": -92.04,
        "成交量": 11204568.0,
        "成交额": 271182560.0,
        "振幅": 6.79,
        "换手率": 1.98,
        "symbol": "106.PATH"
    },
    {
        "序号": 476,
        "name": "Vislink技术",
        "最新价": 4.65,
        "涨跌额": 0.23,
        "涨跌幅": 5.2,
        "开盘价": 4.5,
        "最高价": 4.7,
        "最低价": 4.4,
        "昨收价": 4.42,
        "总市值": 11102321.0,
        "市盈率": -0.91,
        "成交量": 27214.0,
        "成交额": 123354.0,
        "振幅": 6.79,
        "换手率": 1.14,
        "symbol": "105.VISL"
    },
    {
        "序号": 477,
        "name": "NeuBase Therapeutics Inc",
        "最新价": 0.81,
        "涨跌额": 0.04,
        "涨跌幅": 5.19,
        "开盘价": 0.75,
        "最高价": 0.93,
        "最低价": 0.75,
        "昨收价": 0.77,
        "总市值": 3020657.0,
        "市盈率": -0.18,
        "成交量": 203246.0,
        "成交额": 167469.0,
        "振幅": 23.38,
        "换手率": 5.45,
        "symbol": "105.NBSE"
    },
    {
        "序号": 478,
        "name": "Rush Street Interactive Inc-A",
        "最新价": 4.06,
        "涨跌额": 0.2,
        "涨跌幅": 5.18,
        "开盘价": 3.84,
        "最高价": 4.07,
        "最低价": 3.78,
        "昨收价": 3.86,
        "总市值": 902395230.0,
        "市盈率": -35.3,
        "成交量": 542554.0,
        "成交额": 2154755.0,
        "振幅": 7.51,
        "换手率": 0.24,
        "symbol": "106.RSI"
    },
    {
        "序号": 479,
        "name": "Unicycive Therapeutics Inc",
        "最新价": 0.61,
        "涨跌额": 0.03,
        "涨跌幅": 5.17,
        "开盘价": 0.61,
        "最高价": 0.64,
        "最低价": 0.57,
        "昨收价": 0.58,
        "总市值": 21200185.0,
        "市盈率": -0.76,
        "成交量": 152199.0,
        "成交额": 90129.0,
        "振幅": 12.07,
        "换手率": 0.44,
        "symbol": "105.UNCY"
    },
    {
        "序号": 480,
        "name": "Forte Biosciences Inc",
        "最新价": 0.61,
        "涨跌额": 0.03,
        "涨跌幅": 5.17,
        "开盘价": 0.6,
        "最高价": 0.63,
        "最低价": 0.54,
        "昨收价": 0.58,
        "总市值": 22164414.0,
        "市盈率": -0.73,
        "成交量": 52559.0,
        "成交额": 31936.0,
        "振幅": 15.52,
        "换手率": 0.14,
        "symbol": "105.FBRX"
    },
    {
        "序号": 481,
        "name": "YieldMax AI Option Income Strat",
        "最新价": 19.52,
        "涨跌额": 0.96,
        "涨跌幅": 5.17,
        "开盘价": 18.58,
        "最高价": 19.65,
        "最低价": 18.58,
        "昨收价": 18.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 21536.0,
        "成交额": 418096.0,
        "振幅": 5.77,
        "换手率": null,
        "symbol": "107.AIYY"
    },
    {
        "序号": 482,
        "name": "Helius Medical Technologies Inc",
        "最新价": 7.13,
        "涨跌额": 0.35,
        "涨跌幅": 5.16,
        "开盘价": 7.05,
        "最高价": 7.21,
        "最低价": 6.92,
        "昨收价": 6.78,
        "总市值": 5049801.0,
        "市盈率": -0.4,
        "成交量": 12760.0,
        "成交额": 90522.0,
        "振幅": 4.28,
        "换手率": 1.8,
        "symbol": "105.HSDT"
    },
    {
        "序号": 483,
        "name": "Inspire Veterinary Partners Inc",
        "最新价": 0.41,
        "涨跌额": 0.02,
        "涨跌幅": 5.13,
        "开盘价": 0.38,
        "最高价": 0.41,
        "最低价": 0.37,
        "昨收价": 0.39,
        "总市值": 2055428.0,
        "市盈率": -0.16,
        "成交量": 35972.0,
        "成交额": 14357.0,
        "振幅": 10.26,
        "换手率": 0.72,
        "symbol": "105.IVP"
    },
    {
        "序号": 484,
        "name": "Farfetch Ltd-A",
        "最新价": 1.23,
        "涨跌额": 0.06,
        "涨跌幅": 5.13,
        "开盘价": 1.15,
        "最高价": 1.26,
        "最低价": 1.13,
        "昨收价": 1.17,
        "总市值": 486423222.0,
        "市盈率": -0.55,
        "成交量": 20281311.0,
        "成交额": 24580746.0,
        "振幅": 11.11,
        "换手率": 5.13,
        "symbol": "106.FTCH"
    },
    {
        "序号": 485,
        "name": "AC Immune SA",
        "最新价": 3.69,
        "涨跌额": 0.18,
        "涨跌幅": 5.13,
        "开盘价": 3.62,
        "最高价": 3.7,
        "最低价": 3.49,
        "昨收价": 3.51,
        "总市值": 331160397.0,
        "市盈率": -4.29,
        "成交量": 52485.0,
        "成交额": 188873.0,
        "振幅": 5.98,
        "换手率": 0.06,
        "symbol": "105.ACIU"
    },
    {
        "序号": 486,
        "name": "黑莓",
        "最新价": 4.12,
        "涨跌额": 0.2,
        "涨跌幅": 5.1,
        "开盘价": 3.9,
        "最高价": 4.17,
        "最低价": 3.89,
        "昨收价": 3.92,
        "总市值": 2404877393.0,
        "市盈率": -4.36,
        "成交量": 6811473.0,
        "成交额": 27834170.0,
        "振幅": 7.14,
        "换手率": 1.17,
        "symbol": "106.BB"
    },
    {
        "序号": 487,
        "name": "极星汽车",
        "最新价": 2.27,
        "涨跌额": 0.11,
        "涨跌幅": 5.09,
        "开盘价": 2.18,
        "最高价": 2.28,
        "最低价": 2.16,
        "昨收价": 2.16,
        "总市值": 4846856416.0,
        "市盈率": -6.63,
        "成交量": 2557424.0,
        "成交额": 5736593.0,
        "振幅": 5.56,
        "换手率": 0.12,
        "symbol": "105.PSNY"
    },
    {
        "序号": 488,
        "name": "DISH Network Corp-A",
        "最新价": 4.57,
        "涨跌额": 0.22,
        "涨跌幅": 5.06,
        "开盘价": 4.35,
        "最高价": 4.62,
        "最低价": 4.33,
        "昨收价": 4.35,
        "总市值": 2442162821.0,
        "市盈率": 2.0,
        "成交量": 9835944.0,
        "成交额": 44539745.0,
        "振幅": 6.67,
        "换手率": 1.84,
        "symbol": "105.DISH"
    },
    {
        "序号": 489,
        "name": "优利系统",
        "最新价": 5.62,
        "涨跌额": 0.27,
        "涨跌幅": 5.05,
        "开盘价": 5.3,
        "最高价": 5.7,
        "最低价": 5.29,
        "昨收价": 5.35,
        "总市值": 384375174.0,
        "市盈率": -1.5,
        "成交量": 680220.0,
        "成交额": 3773654.0,
        "振幅": 7.66,
        "换手率": 0.99,
        "symbol": "106.UIS"
    },
    {
        "序号": 490,
        "name": "Cyclacel Pharmaceuticals Inc Pf",
        "最新价": 10.0,
        "涨跌额": 0.48,
        "涨跌幅": 5.04,
        "开盘价": 9.55,
        "最高价": 10.51,
        "最低价": 9.55,
        "昨收价": 9.52,
        "总市值": 3352730.0,
        "市盈率": null,
        "成交量": 1031.0,
        "成交额": 10279.0,
        "振幅": 10.08,
        "换手率": 0.31,
        "symbol": "105.CYCCP"
    },
    {
        "序号": 491,
        "name": "Presidio Property Trust Inc Ser",
        "最新价": 14.6,
        "涨跌额": 0.7,
        "涨跌幅": 5.04,
        "开盘价": 13.83,
        "最高价": 14.6,
        "最低价": 13.83,
        "昨收价": 13.9,
        "总市值": 13124524.0,
        "市盈率": null,
        "成交量": 5091.0,
        "成交额": 73006.0,
        "振幅": 5.54,
        "换手率": 0.57,
        "symbol": "105.SQFTP"
    },
    {
        "序号": 492,
        "name": "Skillz Inc-A",
        "最新价": 6.47,
        "涨跌额": 0.31,
        "涨跌幅": 5.03,
        "开盘价": 6.18,
        "最高价": 6.53,
        "最低价": 6.12,
        "昨收价": 6.16,
        "总市值": 138766360.0,
        "市盈率": -0.61,
        "成交量": 194970.0,
        "成交额": 1249145.0,
        "振幅": 6.66,
        "换手率": 0.91,
        "symbol": "106.SKLZ"
    },
    {
        "序号": 493,
        "name": "2x Bitcoin Strategy ETF",
        "最新价": 26.3,
        "涨跌额": 1.26,
        "涨跌幅": 5.03,
        "开盘价": 25.49,
        "最高价": 26.49,
        "最低价": 25.45,
        "昨收价": 25.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 875461.0,
        "成交额": 22671227.0,
        "振幅": 4.15,
        "换手率": null,
        "symbol": "107.BITX"
    },
    {
        "序号": 494,
        "name": "Freightos Ltd",
        "最新价": 3.35,
        "涨跌额": 0.16,
        "涨跌幅": 5.02,
        "开盘价": 3.13,
        "最高价": 3.5,
        "最低价": 3.05,
        "昨收价": 3.19,
        "总市值": 159588586.0,
        "市盈率": -2.54,
        "成交量": 49204.0,
        "成交额": 164069.0,
        "振幅": 14.11,
        "换手率": 0.1,
        "symbol": "105.CRGO"
    },
    {
        "序号": 495,
        "name": "Sintx Technologies Inc",
        "最新价": 0.42,
        "涨跌额": 0.02,
        "涨跌幅": 5.0,
        "开盘价": 0.39,
        "最高价": 0.43,
        "最低价": 0.38,
        "昨收价": 0.4,
        "总市值": 1789098.0,
        "市盈率": -0.18,
        "成交量": 179001.0,
        "成交额": 72623.0,
        "振幅": 12.5,
        "换手率": 4.2,
        "symbol": "105.SINT"
    },
    {
        "序号": 496,
        "name": "Stitch Fix Inc-A",
        "最新价": 3.99,
        "涨跌额": 0.19,
        "涨跌幅": 5.0,
        "开盘价": 3.76,
        "最高价": 4.01,
        "最低价": 3.66,
        "昨收价": 3.8,
        "总市值": 470384791.0,
        "市盈率": -3.1,
        "成交量": 1660386.0,
        "成交额": 6479051.0,
        "振幅": 9.21,
        "换手率": 1.41,
        "symbol": "105.SFIX"
    },
    {
        "序号": 497,
        "name": "TransCode Therapeutics Inc",
        "最新价": 0.21,
        "涨跌额": 0.01,
        "涨跌幅": 5.0,
        "开盘价": 0.21,
        "最高价": 0.22,
        "最低价": 0.21,
        "昨收价": 0.2,
        "总市值": 5270495.0,
        "市盈率": -0.27,
        "成交量": 1012237.0,
        "成交额": 215407.0,
        "振幅": 5.0,
        "换手率": 4.03,
        "symbol": "105.RNAZ"
    },
    {
        "序号": 498,
        "name": "D-Wave Quantum Inc",
        "最新价": 1.05,
        "涨跌额": 0.05,
        "涨跌幅": 5.0,
        "开盘价": 0.99,
        "最高价": 1.08,
        "最低价": 0.99,
        "昨收价": 1.0,
        "总市值": 167924922.0,
        "市盈率": -2.12,
        "成交量": 1217276.0,
        "成交额": 1263268.0,
        "振幅": 9.0,
        "换手率": 0.76,
        "symbol": "106.QBTS"
    },
    {
        "序号": 499,
        "name": "Planet Labs PBC Wt",
        "最新价": 0.21,
        "涨跌额": 0.01,
        "涨跌幅": 5.0,
        "开盘价": 0.2,
        "最高价": 0.21,
        "最低价": 0.19,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 119304.0,
        "成交额": 22771.0,
        "振幅": 10.0,
        "换手率": null,
        "symbol": "106.PL_WS"
    },
    {
        "序号": 500,
        "name": "狮子集团控股",
        "最新价": 1.26,
        "涨跌额": 0.06,
        "涨跌幅": 5.0,
        "开盘价": 1.11,
        "最高价": 1.35,
        "最低价": 1.11,
        "昨收价": 1.2,
        "总市值": 4765165.0,
        "市盈率": -0.45,
        "成交量": 279324.0,
        "成交额": 336162.0,
        "振幅": 20.0,
        "换手率": 7.39,
        "symbol": "105.LGHL"
    },
    {
        "序号": 501,
        "name": "悦享控股",
        "最新价": 3.78,
        "涨跌额": 0.18,
        "涨跌幅": 5.0,
        "开盘价": 3.44,
        "最高价": 3.8,
        "最低价": 3.44,
        "昨收价": 3.6,
        "总市值": 37945330.0,
        "市盈率": 1.53,
        "成交量": 27812.0,
        "成交额": 102766.0,
        "振幅": 10.0,
        "换手率": 0.28,
        "symbol": "105.CHR"
    },
    {
        "序号": 502,
        "name": "Beneficient-A",
        "最新价": 0.42,
        "涨跌额": 0.02,
        "涨跌幅": 5.0,
        "开盘价": 0.4,
        "最高价": 0.42,
        "最低价": 0.37,
        "昨收价": 0.4,
        "总市值": 116067015.0,
        "市盈率": -0.08,
        "成交量": 147419.0,
        "成交额": 59090.0,
        "振幅": 12.5,
        "换手率": 0.05,
        "symbol": "105.BENF"
    },
    {
        "序号": 503,
        "name": "Affimed NV",
        "最新价": 0.42,
        "涨跌额": 0.02,
        "涨跌幅": 5.0,
        "开盘价": 0.42,
        "最高价": 0.43,
        "最低价": 0.37,
        "昨收价": 0.4,
        "总市值": 62722521.0,
        "市盈率": -0.48,
        "成交量": 1633513.0,
        "成交额": 648673.0,
        "振幅": 15.0,
        "换手率": 1.09,
        "symbol": "105.AFMD"
    },
    {
        "序号": 504,
        "name": "安朗杰",
        "最新价": 110.34,
        "涨跌额": 5.25,
        "涨跌幅": 5.0,
        "开盘价": 107.0,
        "最高价": 112.36,
        "最低价": 106.66,
        "昨收价": 105.09,
        "总市值": 9686480032.0,
        "市盈率": 17.39,
        "成交量": 2081064.0,
        "成交额": 229755648.0,
        "振幅": 5.42,
        "换手率": 2.37,
        "symbol": "106.ALLE"
    },
    {
        "序号": 505,
        "name": "CervoMed Inc",
        "最新价": 9.25,
        "涨跌额": 0.44,
        "涨跌幅": 4.99,
        "开盘价": 8.82,
        "最高价": 9.59,
        "最低价": 8.82,
        "昨收价": 8.81,
        "总市值": 52489310.0,
        "市盈率": -4.72,
        "成交量": 6557.0,
        "成交额": 60928.0,
        "振幅": 8.74,
        "换手率": 0.12,
        "symbol": "105.CRVO"
    },
    {
        "序号": 506,
        "name": "The E.W. Scripps Co-A",
        "最新价": 7.79,
        "涨跌额": 0.37,
        "涨跌幅": 4.99,
        "开盘价": 7.42,
        "最高价": 7.82,
        "最低价": 7.42,
        "昨收价": 7.42,
        "总市值": 659192916.0,
        "市盈率": -1.09,
        "成交量": 239135.0,
        "成交额": 1846717.0,
        "振幅": 5.39,
        "换手率": 0.28,
        "symbol": "105.SSP"
    },
    {
        "序号": 507,
        "name": "佳裕达",
        "最新价": 2.95,
        "涨跌额": 0.14,
        "涨跌幅": 4.98,
        "开盘价": 2.78,
        "最高价": 2.95,
        "最低价": 2.78,
        "昨收价": 2.81,
        "总市值": 62989058.0,
        "市盈率": -14.85,
        "成交量": 29120.0,
        "成交额": 82781.0,
        "振幅": 6.05,
        "换手率": 0.14,
        "symbol": "105.JYD"
    },
    {
        "序号": 508,
        "name": "布拉德斯科银行",
        "最新价": 2.95,
        "涨跌额": 0.14,
        "涨跌幅": 4.98,
        "开盘价": 2.86,
        "最高价": 2.95,
        "最低价": 2.86,
        "昨收价": 2.81,
        "总市值": 15724398809.0,
        "市盈率": 5.92,
        "成交量": 11865.0,
        "成交额": 34589.0,
        "振幅": 3.2,
        "换手率": 0.0,
        "symbol": "106.BBDO"
    },
    {
        "序号": 509,
        "name": "纳索尔技术",
        "最新价": 2.11,
        "涨跌额": 0.1,
        "涨跌幅": 4.98,
        "开盘价": 2.06,
        "最高价": 2.19,
        "最低价": 2.02,
        "昨收价": 2.01,
        "总市值": 23996648.0,
        "市盈率": -5.23,
        "成交量": 15009.0,
        "成交额": 30793.0,
        "振幅": 8.46,
        "换手率": 0.13,
        "symbol": "105.NTWK"
    },
    {
        "序号": 510,
        "name": "8x8 Inc",
        "最新价": 3.59,
        "涨跌额": 0.17,
        "涨跌幅": 4.97,
        "开盘价": 3.38,
        "最高价": 3.62,
        "最低价": 3.38,
        "昨收价": 3.42,
        "总市值": 438567134.0,
        "市盈率": -7.53,
        "成交量": 1064380.0,
        "成交额": 3789247.0,
        "振幅": 7.02,
        "换手率": 0.87,
        "symbol": "105.EGHT"
    },
    {
        "序号": 511,
        "name": "Via Renewables Inc-A",
        "最新价": 9.09,
        "涨跌额": 0.43,
        "涨跌幅": 4.97,
        "开盘价": 8.66,
        "最高价": 9.13,
        "最低价": 8.66,
        "昨收价": 8.66,
        "总市值": 65745252.0,
        "市盈率": 10.64,
        "成交量": 15585.0,
        "成交额": 140109.0,
        "振幅": 5.43,
        "换手率": 0.22,
        "symbol": "105.VIA"
    },
    {
        "序号": 512,
        "name": "恩佐生化",
        "最新价": 1.27,
        "涨跌额": 0.06,
        "涨跌幅": 4.96,
        "开盘价": 1.21,
        "最高价": 1.28,
        "最低价": 1.21,
        "昨收价": 1.21,
        "总市值": 64122009.0,
        "市盈率": 3.16,
        "成交量": 60419.0,
        "成交额": 75338.0,
        "振幅": 5.79,
        "换手率": 0.12,
        "symbol": "106.ENZ"
    },
    {
        "序号": 513,
        "name": "MicroStrategy Inc-A",
        "最新价": 599.39,
        "涨跌额": 28.3,
        "涨跌幅": 4.96,
        "开盘价": 573.48,
        "最高价": 599.39,
        "最低价": 572.81,
        "昨收价": 571.09,
        "总市值": 8195382748.0,
        "市盈率": 90.73,
        "成交量": 953073.0,
        "成交额": 564275888.0,
        "振幅": 4.65,
        "换手率": 6.97,
        "symbol": "105.MSTR"
    },
    {
        "序号": 514,
        "name": "Abits Group Inc",
        "最新价": 1.06,
        "涨跌额": 0.05,
        "涨跌幅": 4.95,
        "开盘价": 0.98,
        "最高价": 1.08,
        "最低价": 0.98,
        "昨收价": 1.01,
        "总市值": 37687958.0,
        "市盈率": -1.5,
        "成交量": 53966.0,
        "成交额": 56293.0,
        "振幅": 9.9,
        "换手率": 0.15,
        "symbol": "105.ABTS"
    },
    {
        "序号": 515,
        "name": "Tharimmune Inc",
        "最新价": 0.64,
        "涨跌额": 0.03,
        "涨跌幅": 4.92,
        "开盘价": 0.6,
        "最高价": 0.65,
        "最低价": 0.59,
        "昨收价": 0.61,
        "总市值": 1616303.0,
        "市盈率": -0.19,
        "成交量": 2121587.0,
        "成交额": 1318473.0,
        "振幅": 9.84,
        "换手率": 84.01,
        "symbol": "105.THAR"
    },
    {
        "序号": 516,
        "name": "李氏企业",
        "最新价": 9.62,
        "涨跌额": 0.45,
        "涨跌幅": 4.91,
        "开盘价": 9.2,
        "最高价": 9.83,
        "最低价": 9.14,
        "昨收价": 9.17,
        "总市值": 58323597.0,
        "市盈率": -5.6,
        "成交量": 7214.0,
        "成交额": 68289.0,
        "振幅": 7.52,
        "换手率": 0.12,
        "symbol": "105.LEE"
    },
    {
        "序号": 517,
        "name": "自由港麦克莫兰",
        "最新价": 38.15,
        "涨跌额": 1.78,
        "涨跌幅": 4.89,
        "开盘价": 37.05,
        "最高价": 38.41,
        "最低价": 36.89,
        "昨收价": 36.37,
        "总市值": 54706231859.0,
        "市盈率": 25.36,
        "成交量": 19549147.0,
        "成交额": 743864864.0,
        "振幅": 4.18,
        "换手率": 1.36,
        "symbol": "106.FCX"
    },
    {
        "序号": 518,
        "name": "戴蒙德海底钻探",
        "最新价": 12.25,
        "涨跌额": 0.57,
        "涨跌幅": 4.88,
        "开盘价": 11.77,
        "最高价": 12.4,
        "最低价": 11.76,
        "昨收价": 11.68,
        "总市值": 1253444525.0,
        "市盈率": 25.81,
        "成交量": 1236400.0,
        "成交额": 15143993.0,
        "振幅": 5.48,
        "换手率": 1.21,
        "symbol": "106.DO"
    },
    {
        "序号": 519,
        "name": "Senti Biosciences Inc-A",
        "最新价": 0.43,
        "涨跌额": 0.02,
        "涨跌幅": 4.88,
        "开盘价": 0.4,
        "最高价": 0.45,
        "最低价": 0.39,
        "昨收价": 0.41,
        "总市值": 19154430.0,
        "市盈率": -0.27,
        "成交量": 306818.0,
        "成交额": 130374.0,
        "振幅": 14.63,
        "换手率": 0.69,
        "symbol": "105.SNTI"
    },
    {
        "序号": 520,
        "name": "Flame Acquisition Corp Wt",
        "最新价": 0.86,
        "涨跌额": 0.04,
        "涨跌幅": 4.88,
        "开盘价": 0.86,
        "最高价": 0.88,
        "最低价": 0.85,
        "昨收价": 0.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 537972.0,
        "成交额": 466060.0,
        "振幅": 3.66,
        "换手率": null,
        "symbol": "106.FLME_WS"
    },
    {
        "序号": 521,
        "name": "Kaman Corp",
        "最新价": 22.69,
        "涨跌额": 1.05,
        "涨跌幅": 4.85,
        "开盘价": 21.64,
        "最高价": 22.76,
        "最低价": 21.58,
        "昨收价": 21.64,
        "总市值": 641137466.0,
        "市盈率": -13.46,
        "成交量": 176776.0,
        "成交额": 3967599.0,
        "振幅": 5.45,
        "换手率": 0.63,
        "symbol": "106.KAMN"
    },
    {
        "序号": 522,
        "name": "DocuSign Inc",
        "最新价": 49.73,
        "涨跌额": 2.29,
        "涨跌幅": 4.83,
        "开盘价": 46.0,
        "最高价": 50.5,
        "最低价": 44.34,
        "昨收价": 47.44,
        "总市值": 10141632549.0,
        "市盈率": 196.54,
        "成交量": 21422040.0,
        "成交额": 1039680016.0,
        "振幅": 12.98,
        "换手率": 10.5,
        "symbol": "105.DOCU"
    },
    {
        "序号": 523,
        "name": "胡克家具",
        "最新价": 23.25,
        "涨跌额": 1.07,
        "涨跌幅": 4.82,
        "开盘价": 22.4,
        "最高价": 23.73,
        "最低价": 22.15,
        "昨收价": 22.18,
        "总市值": 248119629.0,
        "市盈率": -28.83,
        "成交量": 47205.0,
        "成交额": 1090006.0,
        "振幅": 7.12,
        "换手率": 0.44,
        "symbol": "105.HOFT"
    },
    {
        "序号": 524,
        "name": "Qurate Retail Inc-B",
        "最新价": 6.09,
        "涨跌额": 0.28,
        "涨跌幅": 4.82,
        "开盘价": 5.9,
        "最高价": 6.09,
        "最低价": 5.9,
        "昨收价": 5.81,
        "总市值": 2371430288.0,
        "市盈率": 30.8,
        "成交量": 615.0,
        "成交额": 3699.0,
        "振幅": 3.27,
        "换手率": 0.01,
        "symbol": "105.QRTEB"
    },
    {
        "序号": 525,
        "name": "Mirion Technologies Inc Wt",
        "最新价": 1.74,
        "涨跌额": 0.08,
        "涨跌幅": 4.82,
        "开盘价": 1.69,
        "最高价": 1.74,
        "最低价": 1.68,
        "昨收价": 1.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 5768.0,
        "成交额": 9794.0,
        "振幅": 3.61,
        "换手率": null,
        "symbol": "106.MIR_WS"
    },
    {
        "序号": 526,
        "name": "Cohen & Co Inc",
        "最新价": 6.97,
        "涨跌额": 0.32,
        "涨跌幅": 4.81,
        "开盘价": 6.66,
        "最高价": 7.25,
        "最低价": 6.66,
        "昨收价": 6.65,
        "总市值": 12694789.0,
        "市盈率": -1.0,
        "成交量": 3104.0,
        "成交额": 21156.0,
        "振幅": 8.87,
        "换手率": 0.17,
        "symbol": "107.COHN"
    },
    {
        "序号": 527,
        "name": "Xtl生物制药",
        "最新价": 1.09,
        "涨跌额": 0.05,
        "涨跌幅": 4.81,
        "开盘价": 1.06,
        "最高价": 1.09,
        "最低价": 1.06,
        "昨收价": 1.04,
        "总市值": 5939477.0,
        "市盈率": -2.64,
        "成交量": 923.0,
        "成交额": 999.0,
        "振幅": 2.88,
        "换手率": 0.02,
        "symbol": "105.XTLB"
    },
    {
        "序号": 528,
        "name": "Rocky Brands Inc",
        "最新价": 30.85,
        "涨跌额": 1.41,
        "涨跌幅": 4.79,
        "开盘价": 29.12,
        "最高价": 31.07,
        "最低价": 29.01,
        "昨收价": 29.44,
        "总市值": 227606025.0,
        "市盈率": 22.25,
        "成交量": 56927.0,
        "成交额": 1739181.0,
        "振幅": 7.0,
        "换手率": 0.77,
        "symbol": "105.RCKY"
    },
    {
        "序号": 529,
        "name": "Icosavax Inc",
        "最新价": 10.51,
        "涨跌额": 0.48,
        "涨跌幅": 4.79,
        "开盘价": 10.1,
        "最高价": 10.74,
        "最低价": 9.51,
        "昨收价": 10.03,
        "总市值": 526449484.0,
        "市盈率": -5.44,
        "成交量": 452887.0,
        "成交额": 4669083.0,
        "振幅": 12.26,
        "换手率": 0.9,
        "symbol": "105.ICVX"
    },
    {
        "序号": 530,
        "name": "Cryo-Cell International Inc",
        "最新价": 6.35,
        "涨跌额": 0.29,
        "涨跌幅": 4.79,
        "开盘价": 5.96,
        "最高价": 6.47,
        "最低价": 5.96,
        "昨收价": 6.06,
        "总市值": 52621085.0,
        "市盈率": 21.0,
        "成交量": 17544.0,
        "成交额": 110143.0,
        "振幅": 8.42,
        "换手率": 0.21,
        "symbol": "107.CCEL"
    },
    {
        "序号": 531,
        "name": "XORTX Therapeutics Inc",
        "最新价": 2.41,
        "涨跌额": 0.11,
        "涨跌幅": 4.78,
        "开盘价": 2.33,
        "最高价": 2.42,
        "最低价": 2.28,
        "昨收价": 2.3,
        "总市值": 4817224.0,
        "市盈率": -0.72,
        "成交量": 7087.0,
        "成交额": 16586.0,
        "振幅": 6.09,
        "换手率": 0.35,
        "symbol": "105.XRTX"
    },
    {
        "序号": 532,
        "name": "InMode Ltd",
        "最新价": 21.74,
        "涨跌额": 0.99,
        "涨跌幅": 4.77,
        "开盘价": 20.76,
        "最高价": 21.96,
        "最低价": 20.75,
        "昨收价": 20.75,
        "总市值": 1809455636.0,
        "市盈率": 10.03,
        "成交量": 2124079.0,
        "成交额": 45828042.0,
        "振幅": 5.83,
        "换手率": 2.55,
        "symbol": "105.INMD"
    },
    {
        "序号": 533,
        "name": "Spero Therapeutics Inc",
        "最新价": 1.32,
        "涨跌额": 0.06,
        "涨跌幅": 4.76,
        "开盘价": 1.25,
        "最高价": 1.34,
        "最低价": 1.25,
        "昨收价": 1.26,
        "总市值": 69959098.0,
        "市盈率": -43.35,
        "成交量": 218627.0,
        "成交额": 284869.0,
        "振幅": 7.14,
        "换手率": 0.41,
        "symbol": "105.SPRO"
    },
    {
        "序号": 534,
        "name": "Sangoma Technologies Corp",
        "最新价": 3.3,
        "涨跌额": 0.15,
        "涨跌幅": 4.76,
        "开盘价": 3.14,
        "最高价": 3.3,
        "最低价": 3.14,
        "昨收价": 3.15,
        "总市值": 109507860.0,
        "市盈率": -3.71,
        "成交量": 3154.0,
        "成交额": 10073.0,
        "振幅": 5.08,
        "换手率": 0.01,
        "symbol": "105.SANG"
    },
    {
        "序号": 535,
        "name": "Renalytix plc ADR",
        "最新价": 0.44,
        "涨跌额": 0.02,
        "涨跌幅": 4.76,
        "开盘价": 0.43,
        "最高价": 0.47,
        "最低价": 0.43,
        "昨收价": 0.42,
        "总市值": 21434634.0,
        "市盈率": -0.49,
        "成交量": 112325.0,
        "成交额": 50966.0,
        "振幅": 9.52,
        "换手率": 0.23,
        "symbol": "105.RNLX"
    },
    {
        "序号": 536,
        "name": "Pineapple Energy Inc",
        "最新价": 0.66,
        "涨跌额": 0.03,
        "涨跌幅": 4.76,
        "开盘价": 0.63,
        "最高价": 0.66,
        "最低价": 0.63,
        "昨收价": 0.63,
        "总市值": 6733160.0,
        "市盈率": -0.49,
        "成交量": 13046.0,
        "成交额": 8430.0,
        "振幅": 4.76,
        "换手率": 0.13,
        "symbol": "105.PEGY"
    },
    {
        "序号": 537,
        "name": "Nine Energy Service Inc",
        "最新价": 2.2,
        "涨跌额": 0.1,
        "涨跌幅": 4.76,
        "开盘价": 2.15,
        "最高价": 2.28,
        "最低价": 2.12,
        "昨收价": 2.1,
        "总市值": 77714694.0,
        "市盈率": -5.58,
        "成交量": 736204.0,
        "成交额": 1609252.0,
        "振幅": 7.62,
        "换手率": 2.08,
        "symbol": "106.NINE"
    },
    {
        "序号": 538,
        "name": "Benson Hill Inc",
        "最新价": 0.22,
        "涨跌额": 0.01,
        "涨跌幅": 4.76,
        "开盘价": 0.22,
        "最高价": 0.23,
        "最低价": 0.22,
        "昨收价": 0.21,
        "总市值": 45843388.0,
        "市盈率": -0.35,
        "成交量": 357655.0,
        "成交额": 78751.0,
        "振幅": 4.76,
        "换手率": 0.17,
        "symbol": "106.BHIL"
    },
    {
        "序号": 539,
        "name": "Symbotic Inc-A",
        "最新价": 50.33,
        "涨跌额": 2.28,
        "涨跌幅": 4.75,
        "开盘价": 47.87,
        "最高价": 51.63,
        "最低价": 47.24,
        "昨收价": 48.05,
        "总市值": 28012315013.0,
        "市盈率": -1207.06,
        "成交量": 970015.0,
        "成交额": 48562028.0,
        "振幅": 9.14,
        "换手率": 0.17,
        "symbol": "105.SYM"
    },
    {
        "序号": 540,
        "name": "回声星通信",
        "最新价": 13.04,
        "涨跌额": 0.59,
        "涨跌幅": 4.74,
        "开盘价": 12.42,
        "最高价": 13.16,
        "最低价": 12.35,
        "昨收价": 12.45,
        "总市值": 1094145220.0,
        "市盈率": 11.79,
        "成交量": 1376489.0,
        "成交额": 17762239.0,
        "振幅": 6.51,
        "换手率": 1.64,
        "symbol": "105.SATS"
    },
    {
        "序号": 541,
        "name": "Aehr Test Systems",
        "最新价": 25.89,
        "涨跌额": 1.17,
        "涨跌幅": 4.73,
        "开盘价": 24.73,
        "最高价": 26.02,
        "最低价": 24.6,
        "昨收价": 24.72,
        "总市值": 745614214.0,
        "市盈率": 40.0,
        "成交量": 796697.0,
        "成交额": 20377678.0,
        "振幅": 5.74,
        "换手率": 2.77,
        "symbol": "105.AEHR"
    },
    {
        "序号": 542,
        "name": "Biomerica Inc",
        "最新价": 1.11,
        "涨跌额": 0.05,
        "涨跌幅": 4.72,
        "开盘价": 1.07,
        "最高价": 1.13,
        "最低价": 1.06,
        "昨收价": 1.06,
        "总市值": 18672027.0,
        "市盈率": -3.01,
        "成交量": 31006.0,
        "成交额": 34234.0,
        "振幅": 6.6,
        "换手率": 0.18,
        "symbol": "105.BMRA"
    },
    {
        "序号": 543,
        "name": "小i机器人",
        "最新价": 2.22,
        "涨跌额": 0.1,
        "涨跌幅": 4.72,
        "开盘价": 2.2,
        "最高价": 2.29,
        "最低价": 2.13,
        "昨收价": 2.12,
        "总市值": 159943844.0,
        "市盈率": -6.38,
        "成交量": 46140.0,
        "成交额": 102008.0,
        "振幅": 7.55,
        "换手率": 0.06,
        "symbol": "105.AIXI"
    },
    {
        "序号": 544,
        "name": "Bandwidth Inc-A",
        "最新价": 12.0,
        "涨跌额": 0.54,
        "涨跌幅": 4.71,
        "开盘价": 11.4,
        "最高价": 12.11,
        "最低价": 11.27,
        "昨收价": 11.46,
        "总市值": 307700592.0,
        "市盈率": 10.98,
        "成交量": 307695.0,
        "成交额": 3668999.0,
        "振幅": 7.33,
        "换手率": 1.2,
        "symbol": "105.BAND"
    },
    {
        "序号": 545,
        "name": "Sutro Biopharma Inc",
        "最新价": 3.57,
        "涨跌额": 0.16,
        "涨跌幅": 4.69,
        "开盘价": 3.37,
        "最高价": 3.67,
        "最低价": 3.33,
        "昨收价": 3.41,
        "总市值": 217602938.0,
        "市盈率": -1.26,
        "成交量": 734499.0,
        "成交额": 2610825.0,
        "振幅": 9.97,
        "换手率": 1.21,
        "symbol": "105.STRO"
    },
    {
        "序号": 546,
        "name": "Spruce Power Holding Corp",
        "最新价": 4.24,
        "涨跌额": 0.19,
        "涨跌幅": 4.69,
        "开盘价": 4.03,
        "最高价": 4.46,
        "最低价": 4.03,
        "昨收价": 4.05,
        "总市值": 77278957.0,
        "市盈率": -0.98,
        "成交量": 56606.0,
        "成交额": 243531.0,
        "振幅": 10.62,
        "换手率": 0.31,
        "symbol": "106.SPRU"
    },
    {
        "序号": 547,
        "name": "Guardion Health Sciences Inc",
        "最新价": 6.25,
        "涨跌额": 0.28,
        "涨跌幅": 4.69,
        "开盘价": 6.4,
        "最高价": 6.4,
        "最低价": 5.7,
        "昨收价": 5.97,
        "总市值": 7970238.0,
        "市盈率": -0.63,
        "成交量": 2681.0,
        "成交额": 16506.0,
        "振幅": 11.73,
        "换手率": 0.21,
        "symbol": "105.GHSI"
    },
    {
        "序号": 548,
        "name": "PLBY Group Inc",
        "最新价": 0.67,
        "涨跌额": 0.03,
        "涨跌幅": 4.69,
        "开盘价": 0.65,
        "最高价": 0.68,
        "最低价": 0.64,
        "昨收价": 0.64,
        "总市值": 49621397.0,
        "市盈率": -0.25,
        "成交量": 145813.0,
        "成交额": 96291.0,
        "振幅": 6.25,
        "换手率": 0.2,
        "symbol": "105.PLBY"
    },
    {
        "序号": 549,
        "name": "Eastside Distilling Inc",
        "最新价": 1.12,
        "涨跌额": 0.05,
        "涨跌幅": 4.67,
        "开盘价": 1.08,
        "最高价": 1.18,
        "最低价": 1.05,
        "昨收价": 1.07,
        "总市值": 1749387.0,
        "市盈率": -0.12,
        "成交量": 5931.0,
        "成交额": 6461.0,
        "振幅": 12.15,
        "换手率": 0.38,
        "symbol": "105.EAST"
    },
    {
        "序号": 550,
        "name": "Brilliant Acquisition Corp Unit",
        "最新价": 11.0,
        "涨跌额": 0.49,
        "涨跌幅": 4.66,
        "开盘价": 10.51,
        "最高价": 11.0,
        "最低价": 10.31,
        "昨收价": 10.51,
        "总市值": 19961656.0,
        "市盈率": -55.15,
        "成交量": 721.0,
        "成交额": 7647.0,
        "振幅": 6.57,
        "换手率": null,
        "symbol": "105.BRLIU"
    },
    {
        "序号": 551,
        "name": "Xeris制药",
        "最新价": 2.03,
        "涨跌额": 0.09,
        "涨跌幅": 4.64,
        "开盘价": 1.94,
        "最高价": 2.05,
        "最低价": 1.91,
        "昨收价": 1.94,
        "总市值": 280392928.0,
        "市盈率": -4.54,
        "成交量": 783702.0,
        "成交额": 1564048.0,
        "振幅": 7.22,
        "换手率": 0.57,
        "symbol": "105.XERS"
    },
    {
        "序号": 552,
        "name": "螺旋能源",
        "最新价": 9.48,
        "涨跌额": 0.42,
        "涨跌幅": 4.64,
        "开盘价": 9.12,
        "最高价": 9.55,
        "最低价": 9.12,
        "昨收价": 9.06,
        "总市值": 1428742451.0,
        "市盈率": 70.72,
        "成交量": 5660111.0,
        "成交额": 53536615.0,
        "振幅": 4.75,
        "换手率": 3.76,
        "symbol": "106.HLX"
    },
    {
        "序号": 553,
        "name": "Materialise NV ADR",
        "最新价": 6.55,
        "涨跌额": 0.29,
        "涨跌幅": 4.63,
        "开盘价": 6.25,
        "最高价": 6.56,
        "最低价": 6.2,
        "昨收价": 6.26,
        "总市值": 386890068.0,
        "市盈率": 129.89,
        "成交量": 130207.0,
        "成交额": 836273.0,
        "振幅": 5.75,
        "换手率": 0.22,
        "symbol": "105.MTLS"
    },
    {
        "序号": 554,
        "name": "快辑半导体",
        "最新价": 11.82,
        "涨跌额": 0.52,
        "涨跌幅": 4.6,
        "开盘价": 11.29,
        "最高价": 11.83,
        "最低价": 11.17,
        "昨收价": 11.3,
        "总市值": 164417701.0,
        "市盈率": -46.49,
        "成交量": 43214.0,
        "成交额": 498909.0,
        "振幅": 5.84,
        "换手率": 0.31,
        "symbol": "105.QUIK"
    },
    {
        "序号": 555,
        "name": "波士顿奥马哈",
        "最新价": 15.72,
        "涨跌额": 0.69,
        "涨跌幅": 4.59,
        "开盘价": 15.24,
        "最高价": 15.8,
        "最低价": 15.22,
        "昨收价": 15.03,
        "总市值": 492114647.0,
        "市盈率": 1538.98,
        "成交量": 183597.0,
        "成交额": 2866674.0,
        "振幅": 3.86,
        "换手率": 0.59,
        "symbol": "106.BOC"
    },
    {
        "序号": 556,
        "name": "安美瑞普",
        "最新价": 19.87,
        "涨跌额": 0.87,
        "涨跌幅": 4.58,
        "开盘价": 19.0,
        "最高价": 20.3,
        "最低价": 18.8,
        "昨收价": 19.0,
        "总市值": 104740910.0,
        "市盈率": 4.94,
        "成交量": 15093.0,
        "成交额": 296774.0,
        "振幅": 7.89,
        "换手率": 0.29,
        "symbol": "106.AXR"
    },
    {
        "序号": 557,
        "name": "Omega Therapeutics Inc",
        "最新价": 2.29,
        "涨跌额": 0.1,
        "涨跌幅": 4.57,
        "开盘价": 2.2,
        "最高价": 2.31,
        "最低价": 2.18,
        "昨收价": 2.19,
        "总市值": 126274809.0,
        "市盈率": -1.17,
        "成交量": 147520.0,
        "成交额": 333033.0,
        "振幅": 5.94,
        "换手率": 0.27,
        "symbol": "105.OMGA"
    },
    {
        "序号": 558,
        "name": "Vivakor Inc",
        "最新价": 0.92,
        "涨跌额": 0.04,
        "涨跌幅": 4.55,
        "开盘价": 0.82,
        "最高价": 0.92,
        "最低价": 0.82,
        "昨收价": 0.88,
        "总市值": 24122867.0,
        "市盈率": -1.25,
        "成交量": 20788.0,
        "成交额": 17719.0,
        "振幅": 11.36,
        "换手率": 0.08,
        "symbol": "105.VIVK"
    },
    {
        "序号": 559,
        "name": "OmniAb Inc Wt",
        "最新价": 0.69,
        "涨跌额": 0.03,
        "涨跌幅": 4.55,
        "开盘价": 0.65,
        "最高价": 0.69,
        "最低价": 0.65,
        "昨收价": 0.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 998.0,
        "成交额": 656.0,
        "振幅": 6.06,
        "换手率": null,
        "symbol": "105.OABIW"
    },
    {
        "序号": 560,
        "name": "LivePerson Inc",
        "最新价": 3.45,
        "涨跌额": 0.15,
        "涨跌幅": 4.55,
        "开盘价": 3.27,
        "最高价": 3.49,
        "最低价": 3.23,
        "昨收价": 3.3,
        "总市值": 278905597.0,
        "市盈率": -2.74,
        "成交量": 1364542.0,
        "成交额": 4638933.0,
        "振幅": 7.88,
        "换手率": 1.69,
        "symbol": "105.LPSN"
    },
    {
        "序号": 561,
        "name": "Lilium NV-A",
        "最新价": 1.38,
        "涨跌额": 0.06,
        "涨跌幅": 4.55,
        "开盘价": 1.37,
        "最高价": 1.4,
        "最低价": 1.26,
        "昨收价": 1.32,
        "总市值": 726104294.0,
        "市盈率": -1.29,
        "成交量": 7230254.0,
        "成交额": 9686409.0,
        "振幅": 10.61,
        "换手率": 1.37,
        "symbol": "105.LILM"
    },
    {
        "序号": 562,
        "name": "Greenwave Technology Solutions ",
        "最新价": 0.46,
        "涨跌额": 0.02,
        "涨跌幅": 4.55,
        "开盘价": 0.45,
        "最高价": 0.46,
        "最低价": 0.44,
        "昨收价": 0.44,
        "总市值": 7616258.0,
        "市盈率": -0.25,
        "成交量": 18700.0,
        "成交额": 8450.0,
        "振幅": 4.55,
        "换手率": 0.11,
        "symbol": "105.GWAV"
    },
    {
        "序号": 563,
        "name": "GT Biopharma Inc",
        "最新价": 0.23,
        "涨跌额": 0.01,
        "涨跌幅": 4.55,
        "开盘价": 0.22,
        "最高价": 0.24,
        "最低价": 0.22,
        "昨收价": 0.22,
        "总市值": 9539738.0,
        "市盈率": -0.93,
        "成交量": 86632.0,
        "成交额": 19294.0,
        "振幅": 9.09,
        "换手率": 0.21,
        "symbol": "105.GTBP"
    },
    {
        "序号": 564,
        "name": "American Lithium Corp",
        "最新价": 1.15,
        "涨跌额": 0.05,
        "涨跌幅": 4.55,
        "开盘价": 1.11,
        "最高价": 1.15,
        "最低价": 1.09,
        "昨收价": 1.1,
        "总市值": 246854186.0,
        "市盈率": -9.07,
        "成交量": 205446.0,
        "成交额": 231805.0,
        "振幅": 5.45,
        "换手率": 0.1,
        "symbol": "105.AMLI"
    },
    {
        "序号": 565,
        "name": "Thoughtworks Holding Inc",
        "最新价": 4.4,
        "涨跌额": 0.19,
        "涨跌幅": 4.51,
        "开盘价": 4.19,
        "最高价": 4.42,
        "最低价": 4.17,
        "昨收价": 4.21,
        "总市值": 1401072292.0,
        "市盈率": -46.51,
        "成交量": 880843.0,
        "成交额": 3783859.0,
        "振幅": 5.94,
        "换手率": 0.28,
        "symbol": "105.TWKS"
    },
    {
        "序号": 566,
        "name": "Castle Biosciences Inc",
        "最新价": 20.17,
        "涨跌额": 0.87,
        "涨跌幅": 4.51,
        "开盘价": 19.15,
        "最高价": 20.22,
        "最低价": 19.12,
        "昨收价": 19.3,
        "总市值": 542785753.0,
        "市盈率": -7.19,
        "成交量": 200117.0,
        "成交额": 3987385.0,
        "振幅": 5.7,
        "换手率": 0.74,
        "symbol": "105.CSTL"
    },
    {
        "序号": 567,
        "name": "Carrier Global Corp",
        "最新价": 55.27,
        "涨跌额": 2.38,
        "涨跌幅": 4.5,
        "开盘价": 56.05,
        "最高价": 56.87,
        "最低价": 54.67,
        "昨收价": 52.89,
        "总市值": 46374104421.0,
        "市盈率": 38.68,
        "成交量": 10424440.0,
        "成交额": 578217984.0,
        "振幅": 4.16,
        "换手率": 1.24,
        "symbol": "106.CARR"
    },
    {
        "序号": 568,
        "name": "世纪铝业",
        "最新价": 7.9,
        "涨跌额": 0.34,
        "涨跌幅": 4.5,
        "开盘价": 7.56,
        "最高价": 7.98,
        "最低价": 7.53,
        "昨收价": 7.56,
        "总市值": 729996838.0,
        "市盈率": -3.91,
        "成交量": 1269418.0,
        "成交额": 9929783.0,
        "振幅": 5.95,
        "换手率": 1.37,
        "symbol": "105.CENX"
    },
    {
        "序号": 569,
        "name": "Arvinas Holding Co LLC",
        "最新价": 34.16,
        "涨跌额": 1.47,
        "涨跌幅": 4.5,
        "开盘价": 33.05,
        "最高价": 36.94,
        "最低价": 32.87,
        "昨收价": 32.69,
        "总市值": 2322846079.0,
        "市盈率": -7.86,
        "成交量": 3056057.0,
        "成交额": 108480339.0,
        "振幅": 12.45,
        "换手率": 4.49,
        "symbol": "105.ARVN"
    },
    {
        "序号": 570,
        "name": "Battalion Oil Corp",
        "最新价": 5.12,
        "涨跌额": 0.22,
        "涨跌幅": 4.49,
        "开盘价": 5.09,
        "最高价": 5.56,
        "最低价": 4.92,
        "昨收价": 4.9,
        "总市值": 84257603.0,
        "市盈率": -1.94,
        "成交量": 50211.0,
        "成交额": 252429.0,
        "振幅": 13.06,
        "换手率": 0.31,
        "symbol": "107.BATL"
    },
    {
        "序号": 571,
        "name": "维珍银河",
        "最新价": 2.33,
        "涨跌额": 0.1,
        "涨跌幅": 4.48,
        "开盘价": 2.21,
        "最高价": 2.34,
        "最低价": 2.16,
        "昨收价": 2.23,
        "总市值": 931336623.0,
        "市盈率": -1.7,
        "成交量": 14213236.0,
        "成交额": 32134418.0,
        "振幅": 8.07,
        "换手率": 3.56,
        "symbol": "106.SPCE"
    },
    {
        "序号": 572,
        "name": "Core & Main Inc-A",
        "最新价": 38.03,
        "涨跌额": 1.63,
        "涨跌幅": 4.48,
        "开盘价": 36.81,
        "最高价": 38.27,
        "最低价": 36.49,
        "昨收价": 36.4,
        "总市值": 8223025113.0,
        "市盈率": 22.72,
        "成交量": 5489641.0,
        "成交额": 207645059.0,
        "振幅": 4.89,
        "换手率": 2.54,
        "symbol": "106.CNM"
    },
    {
        "序号": 573,
        "name": "Aptose Biosciences Inc",
        "最新价": 2.8,
        "涨跌额": 0.12,
        "涨跌幅": 4.48,
        "开盘价": 2.61,
        "最高价": 2.91,
        "最低价": 2.59,
        "昨收价": 2.68,
        "总市值": 22238616.0,
        "市盈率": -0.45,
        "成交量": 91666.0,
        "成交额": 256526.0,
        "振幅": 11.94,
        "换手率": 1.15,
        "symbol": "105.APTO"
    },
    {
        "序号": 574,
        "name": "Vertiv Holdings Co-A",
        "最新价": 47.14,
        "涨跌额": 2.02,
        "涨跌幅": 4.48,
        "开盘价": 44.84,
        "最高价": 47.25,
        "最低价": 44.83,
        "昨收价": 45.12,
        "总市值": 17981152310.0,
        "市盈率": 70.74,
        "成交量": 6951517.0,
        "成交额": 324987776.0,
        "振幅": 5.36,
        "换手率": 1.82,
        "symbol": "106.VRT"
    },
    {
        "序号": 575,
        "name": "Bridger Aerospace Group Holding",
        "最新价": 5.85,
        "涨跌额": 0.25,
        "涨跌幅": 4.46,
        "开盘价": 5.9,
        "最高价": 6.19,
        "最低价": 5.64,
        "昨收价": 5.6,
        "总市值": 261945017.0,
        "市盈率": 49.16,
        "成交量": 13031.0,
        "成交额": 76011.0,
        "振幅": 9.82,
        "换手率": 0.03,
        "symbol": "105.BAER"
    },
    {
        "序号": 576,
        "name": "三倍做空生物技术ETF-Direxion",
        "最新价": 14.51,
        "涨跌额": 0.62,
        "涨跌幅": 4.46,
        "开盘价": 14.06,
        "最高价": 14.57,
        "最低价": 13.59,
        "昨收价": 13.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 11440445.0,
        "成交额": 162307759.0,
        "振幅": 7.06,
        "换手率": null,
        "symbol": "107.LABD"
    },
    {
        "序号": 577,
        "name": "MGO Global Inc",
        "最新价": 0.47,
        "涨跌额": 0.02,
        "涨跌幅": 4.44,
        "开盘价": 0.45,
        "最高价": 0.47,
        "最低价": 0.45,
        "昨收价": 0.45,
        "总市值": 6695287.0,
        "市盈率": -1.17,
        "成交量": 71492.0,
        "成交额": 32504.0,
        "振幅": 4.44,
        "换手率": 0.5,
        "symbol": "105.MGOL"
    },
    {
        "序号": 578,
        "name": "王道科技",
        "最新价": 0.94,
        "涨跌额": 0.04,
        "涨跌幅": 4.44,
        "开盘价": 0.93,
        "最高价": 0.94,
        "最低价": 0.87,
        "昨收价": 0.9,
        "总市值": 14522484.0,
        "市盈率": -0.69,
        "成交量": 31641.0,
        "成交额": 28404.0,
        "振幅": 7.78,
        "换手率": 0.2,
        "symbol": "105.EDTK"
    },
    {
        "序号": 579,
        "name": "ChargePoint Holdings Inc",
        "最新价": 2.35,
        "涨跌额": 0.1,
        "涨跌幅": 4.44,
        "开盘价": 2.23,
        "最高价": 2.49,
        "最低价": 2.16,
        "昨收价": 2.25,
        "总市值": 982365544.0,
        "市盈率": -2.22,
        "成交量": 25658528.0,
        "成交额": 60840447.0,
        "振幅": 14.67,
        "换手率": 6.14,
        "symbol": "106.CHPT"
    },
    {
        "序号": 580,
        "name": "天演药业",
        "最新价": 1.88,
        "涨跌额": 0.08,
        "涨跌幅": 4.44,
        "开盘价": 1.83,
        "最高价": 1.9,
        "最低价": 1.78,
        "昨收价": 1.8,
        "总市值": 82409182.0,
        "市盈率": -2.26,
        "成交量": 26576.0,
        "成交额": 48770.0,
        "振幅": 6.67,
        "换手率": 0.06,
        "symbol": "105.ADAG"
    },
    {
        "序号": 581,
        "name": "BK Technologies Corp",
        "最新价": 12.94,
        "涨跌额": 0.55,
        "涨跌幅": 4.44,
        "开盘价": 12.39,
        "最高价": 13.0,
        "最低价": 12.39,
        "昨收价": 12.39,
        "总市值": 44714157.0,
        "市盈率": -12.85,
        "成交量": 7245.0,
        "成交额": 92984.0,
        "振幅": 4.92,
        "换手率": 0.21,
        "symbol": "107.BKTI"
    },
    {
        "序号": 582,
        "name": "Tile Shop Holdings Inc",
        "最新价": 6.83,
        "涨跌额": 0.29,
        "涨跌幅": 4.43,
        "开盘价": 6.54,
        "最高价": 6.89,
        "最低价": 6.4,
        "昨收价": 6.54,
        "总市值": 304145965.0,
        "市盈率": 27.93,
        "成交量": 65504.0,
        "成交额": 441232.0,
        "振幅": 7.49,
        "换手率": 0.15,
        "symbol": "105.TTSH"
    },
    {
        "序号": 583,
        "name": "agilon health inc",
        "最新价": 11.55,
        "涨跌额": 0.49,
        "涨跌幅": 4.43,
        "开盘价": 11.07,
        "最高价": 11.57,
        "最低价": 11.0,
        "昨收价": 11.06,
        "总市值": 4689772672.0,
        "市盈率": -52.91,
        "成交量": 3741884.0,
        "成交额": 42659021.0,
        "振幅": 5.15,
        "换手率": 0.92,
        "symbol": "106.AGL"
    },
    {
        "序号": 584,
        "name": "Immunocore Holdings plc ADR",
        "最新价": 57.99,
        "涨跌额": 2.46,
        "涨跌幅": 4.43,
        "开盘价": 55.84,
        "最高价": 58.48,
        "最低价": 54.82,
        "昨收价": 55.53,
        "总市值": 2866924465.0,
        "市盈率": -42.31,
        "成交量": 547172.0,
        "成交额": 31424830.0,
        "振幅": 6.59,
        "换手率": 1.11,
        "symbol": "105.IMCR"
    },
    {
        "序号": 585,
        "name": "Couchbase Inc",
        "最新价": 20.35,
        "涨跌额": 0.86,
        "涨跌幅": 4.41,
        "开盘价": 19.47,
        "最高价": 20.35,
        "最低价": 19.27,
        "昨收价": 19.49,
        "总市值": 978257854.0,
        "市盈率": -12.98,
        "成交量": 366964.0,
        "成交额": 7345662.0,
        "振幅": 5.54,
        "换手率": 0.76,
        "symbol": "105.BASE"
    },
    {
        "序号": 586,
        "name": "BranchOut Food Inc",
        "最新价": 1.42,
        "涨跌额": 0.06,
        "涨跌幅": 4.41,
        "开盘价": 1.35,
        "最高价": 1.5,
        "最低价": 1.35,
        "昨收价": 1.36,
        "总市值": 5742838.0,
        "市盈率": -1.74,
        "成交量": 15009.0,
        "成交额": 21288.0,
        "振幅": 11.03,
        "换手率": 0.37,
        "symbol": "105.BOF"
    },
    {
        "序号": 587,
        "name": "Argo Blockchain Plc Notes",
        "最新价": 10.18,
        "涨跌额": 0.43,
        "涨跌幅": 4.41,
        "开盘价": 9.78,
        "最高价": 10.5,
        "最低价": 9.78,
        "昨收价": 9.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 13658.0,
        "成交额": 138105.0,
        "振幅": 7.38,
        "换手率": null,
        "symbol": "105.ARBKL"
    },
    {
        "序号": 588,
        "name": "Brainsway Ltd ADR",
        "最新价": 5.69,
        "涨跌额": 0.24,
        "涨跌幅": 4.4,
        "开盘价": 5.45,
        "最高价": 6.2,
        "最低价": 5.35,
        "昨收价": 5.45,
        "总市值": 94119690.0,
        "市盈率": -11.49,
        "成交量": 339611.0,
        "成交额": 1938176.0,
        "振幅": 15.6,
        "换手率": 2.05,
        "symbol": "105.BWAY"
    },
    {
        "序号": 589,
        "name": "Sify Technologies Ltd ADR",
        "最新价": 1.66,
        "涨跌额": 0.07,
        "涨跌幅": 4.4,
        "开盘价": 1.59,
        "最高价": 1.7,
        "最低价": 1.56,
        "昨收价": 1.59,
        "总市值": 303506713.0,
        "市盈率": 63.14,
        "成交量": 30142.0,
        "成交额": 49418.0,
        "振幅": 8.81,
        "换手率": 0.02,
        "symbol": "105.SIFY"
    },
    {
        "序号": 590,
        "name": "Root Inc-A",
        "最新价": 10.7,
        "涨跌额": 0.45,
        "涨跌幅": 4.39,
        "开盘价": 10.25,
        "最高价": 10.79,
        "最低价": 10.25,
        "昨收价": 10.25,
        "总市值": 156220000.0,
        "市盈率": -0.86,
        "成交量": 124458.0,
        "成交额": 1305663.0,
        "振幅": 5.27,
        "换手率": 0.85,
        "symbol": "105.ROOT"
    },
    {
        "序号": 591,
        "name": "MoneyLion Inc-A",
        "最新价": 45.0,
        "涨跌额": 1.89,
        "涨跌幅": 4.38,
        "开盘价": 42.98,
        "最高价": 45.23,
        "最低价": 41.39,
        "昨收价": 43.11,
        "总市值": 462685590.0,
        "市盈率": -2.63,
        "成交量": 130702.0,
        "成交额": 5635806.0,
        "振幅": 8.91,
        "换手率": 1.27,
        "symbol": "106.ML"
    },
    {
        "序号": 592,
        "name": "Nexstar Media Group Inc",
        "最新价": 148.63,
        "涨跌额": 6.24,
        "涨跌幅": 4.38,
        "开盘价": 141.83,
        "最高价": 149.09,
        "最低价": 141.56,
        "昨收价": 142.39,
        "总市值": 5043166908.0,
        "市盈率": 11.59,
        "成交量": 391655.0,
        "成交额": 57731266.0,
        "振幅": 5.29,
        "换手率": 1.15,
        "symbol": "105.NXST"
    },
    {
        "序号": 593,
        "name": "Alta Equipment Group Inc-A",
        "最新价": 10.96,
        "涨跌额": 0.46,
        "涨跌幅": 4.38,
        "开盘价": 10.53,
        "最高价": 11.37,
        "最低价": 10.53,
        "昨收价": 10.5,
        "总市值": 354754508.0,
        "市盈率": 35.12,
        "成交量": 249337.0,
        "成交额": 2745306.0,
        "振幅": 8.0,
        "换手率": 0.77,
        "symbol": "106.ALTG"
    },
    {
        "序号": 594,
        "name": "盛大科技",
        "最新价": 8.6,
        "涨跌额": 0.36,
        "涨跌幅": 4.37,
        "开盘价": 8.2,
        "最高价": 9.03,
        "最低价": 8.2,
        "昨收价": 8.24,
        "总市值": 757703499.0,
        "市盈率": 528.25,
        "成交量": 402459.0,
        "成交额": 3530158.0,
        "振幅": 10.07,
        "换手率": 0.46,
        "symbol": "105.SDA"
    },
    {
        "序号": 595,
        "name": "极星汽车(权证)",
        "最新价": 0.24,
        "涨跌额": 0.01,
        "涨跌幅": 4.35,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.23,
        "昨收价": 0.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 43283.0,
        "成交额": 10260.0,
        "振幅": 4.35,
        "换手率": null,
        "symbol": "105.PSNYW"
    },
    {
        "序号": 596,
        "name": "Pagaya Technologies Ltd Wt",
        "最新价": 0.24,
        "涨跌额": 0.01,
        "涨跌幅": 4.35,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.24,
        "昨收价": 0.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 5250.0,
        "成交额": 1234.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PGYWW"
    },
    {
        "序号": 597,
        "name": "Marchex Inc-B",
        "最新价": 1.44,
        "涨跌额": 0.06,
        "涨跌幅": 4.35,
        "开盘价": 1.43,
        "最高价": 1.46,
        "最低价": 1.4,
        "昨收价": 1.38,
        "总市值": 62376140.0,
        "市盈率": -5.05,
        "成交量": 4308.0,
        "成交额": 6100.0,
        "振幅": 4.35,
        "换手率": 0.01,
        "symbol": "105.MCHX"
    },
    {
        "序号": 598,
        "name": "Flora Growth Corp",
        "最新价": 1.2,
        "涨跌额": 0.05,
        "涨跌幅": 4.35,
        "开盘价": 1.15,
        "最高价": 1.2,
        "最低价": 1.08,
        "昨收价": 1.15,
        "总市值": 9850314.0,
        "市盈率": -0.16,
        "成交量": 86686.0,
        "成交额": 99475.0,
        "振幅": 10.43,
        "换手率": 1.06,
        "symbol": "105.FLGC"
    },
    {
        "序号": 599,
        "name": "埃姆科",
        "最新价": 0.48,
        "涨跌额": 0.02,
        "涨跌幅": 4.35,
        "开盘价": 0.47,
        "最高价": 0.52,
        "最低价": 0.45,
        "昨收价": 0.46,
        "总市值": 35426394.0,
        "市盈率": -0.7,
        "成交量": 654489.0,
        "成交额": 323399.0,
        "振幅": 15.22,
        "换手率": 0.89,
        "symbol": "105.EMKR"
    },
    {
        "序号": 600,
        "name": "Castellum Inc",
        "最新价": 0.24,
        "涨跌额": 0.01,
        "涨跌幅": 4.35,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.21,
        "昨收价": 0.23,
        "总市值": 11441382.0,
        "市盈率": -0.54,
        "成交量": 264029.0,
        "成交额": 58355.0,
        "振幅": 13.04,
        "换手率": 0.55,
        "symbol": "107.CTM"
    },
    {
        "序号": 601,
        "name": "Breakwave Dry Bulk Shipping ETF",
        "最新价": 8.7,
        "涨跌额": 0.36,
        "涨跌幅": 4.32,
        "开盘价": 8.66,
        "最高价": 8.7,
        "最低价": 8.58,
        "昨收价": 8.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 202623.0,
        "成交额": 1751703.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "107.BDRY"
    },
    {
        "序号": 602,
        "name": "兰德马克银行",
        "最新价": 20.6,
        "涨跌额": 0.85,
        "涨跌幅": 4.3,
        "开盘价": 19.44,
        "最高价": 20.61,
        "最低价": 19.44,
        "昨收价": 19.75,
        "总市值": 112925190.0,
        "市盈率": 10.45,
        "成交量": 5354.0,
        "成交额": 108581.0,
        "振幅": 5.92,
        "换手率": 0.1,
        "symbol": "105.LARK"
    },
    {
        "序号": 603,
        "name": "Cardiol Therapeutics Inc",
        "最新价": 0.97,
        "涨跌额": 0.04,
        "涨跌幅": 4.3,
        "开盘价": 0.93,
        "最高价": 1.02,
        "最低价": 0.93,
        "昨收价": 0.93,
        "总市值": 63160528.0,
        "市盈率": -3.02,
        "成交量": 303363.0,
        "成交额": 303489.0,
        "振幅": 9.68,
        "换手率": 0.47,
        "symbol": "105.CRDL"
    },
    {
        "序号": 604,
        "name": "Lazydays Holdings Inc",
        "最新价": 8.01,
        "涨跌额": 0.33,
        "涨跌幅": 4.3,
        "开盘价": 7.66,
        "最高价": 8.07,
        "最低价": 7.64,
        "昨收价": 7.68,
        "总市值": 112364576.0,
        "市盈率": -30.36,
        "成交量": 74236.0,
        "成交额": 589642.0,
        "振幅": 5.6,
        "换手率": 0.53,
        "symbol": "105.LAZY"
    },
    {
        "序号": 605,
        "name": "Hudson Global Inc",
        "最新价": 17.0,
        "涨跌额": 0.7,
        "涨跌幅": 4.29,
        "开盘价": 16.25,
        "最高价": 17.25,
        "最低价": 16.25,
        "昨收价": 16.3,
        "总市值": 47855561.0,
        "市盈率": 31.34,
        "成交量": 5916.0,
        "成交额": 99063.0,
        "振幅": 6.13,
        "换手率": 0.21,
        "symbol": "105.HSON"
    },
    {
        "序号": 606,
        "name": "Vivid Seats Inc-A",
        "最新价": 7.05,
        "涨跌额": 0.29,
        "涨跌幅": 4.29,
        "开盘价": 6.6,
        "最高价": 7.1,
        "最低价": 6.5,
        "昨收价": 6.76,
        "总市值": 1494338642.0,
        "市盈率": 25.05,
        "成交量": 9573466.0,
        "成交额": 66385516.0,
        "振幅": 8.88,
        "换手率": 4.52,
        "symbol": "105.SEAT"
    },
    {
        "序号": 607,
        "name": "宏桥高科",
        "最新价": 1.22,
        "涨跌额": 0.05,
        "涨跌幅": 4.27,
        "开盘价": 1.39,
        "最高价": 1.39,
        "最低价": 1.13,
        "昨收价": 1.17,
        "总市值": 4755178.0,
        "市盈率": -0.22,
        "成交量": 308987.0,
        "成交额": 383243.0,
        "振幅": 22.22,
        "换手率": 7.93,
        "symbol": "105.PBTS"
    },
    {
        "序号": 608,
        "name": "National CineMedia Inc",
        "最新价": 4.15,
        "涨跌额": 0.17,
        "涨跌幅": 4.27,
        "开盘价": 3.99,
        "最高价": 4.25,
        "最低价": 3.99,
        "昨收价": 3.98,
        "总市值": 401657592.0,
        "市盈率": 0.58,
        "成交量": 480581.0,
        "成交额": 1991330.0,
        "振幅": 6.53,
        "换手率": 0.5,
        "symbol": "105.NCMI"
    },
    {
        "序号": 609,
        "name": "Talos Energy Inc",
        "最新价": 13.43,
        "涨跌额": 0.55,
        "涨跌幅": 4.27,
        "开盘价": 13.01,
        "最高价": 13.43,
        "最低价": 12.94,
        "昨收价": 12.88,
        "总市值": 1666399248.0,
        "市盈率": 15.99,
        "成交量": 1503807.0,
        "成交额": 19952946.0,
        "振幅": 3.8,
        "换手率": 1.21,
        "symbol": "106.TALO"
    },
    {
        "序号": 610,
        "name": "趋实医疗设备",
        "最新价": 1.96,
        "涨跌额": 0.08,
        "涨跌幅": 4.26,
        "开盘价": 1.88,
        "最高价": 2.0,
        "最低价": 1.88,
        "昨收价": 1.88,
        "总市值": 158533740.0,
        "市盈率": -8.0,
        "成交量": 330564.0,
        "成交额": 645608.0,
        "振幅": 6.38,
        "换手率": 0.41,
        "symbol": "107.STXS"
    },
    {
        "序号": 611,
        "name": "Enovix Corp",
        "最新价": 11.76,
        "涨跌额": 0.48,
        "涨跌幅": 4.26,
        "开盘价": 11.2,
        "最高价": 11.86,
        "最低价": 11.11,
        "昨收价": 11.28,
        "总市值": 1973023804.0,
        "市盈率": -11.94,
        "成交量": 3134600.0,
        "成交额": 36364689.0,
        "振幅": 6.65,
        "换手率": 1.87,
        "symbol": "105.ENVX"
    },
    {
        "序号": 612,
        "name": "BioLife Solutions Inc",
        "最新价": 13.26,
        "涨跌额": 0.54,
        "涨跌幅": 4.25,
        "开盘价": 12.67,
        "最高价": 13.42,
        "最低价": 12.53,
        "昨收价": 12.72,
        "总市值": 597329691.0,
        "市盈率": -5.84,
        "成交量": 225623.0,
        "成交额": 2969736.0,
        "振幅": 7.0,
        "换手率": 0.5,
        "symbol": "105.BLFS"
    },
    {
        "序号": 613,
        "name": "Virtus Convertible & Income Fun",
        "最新价": 2.95,
        "涨跌额": 0.12,
        "涨跌幅": 4.24,
        "开盘价": 2.83,
        "最高价": 2.97,
        "最低价": 2.81,
        "昨收价": 2.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 1757656.0,
        "成交额": 5087899.0,
        "振幅": 5.65,
        "换手率": null,
        "symbol": "106.NCZ"
    },
    {
        "序号": 614,
        "name": "Augmedix Inc",
        "最新价": 5.9,
        "涨跌额": 0.24,
        "涨跌幅": 4.24,
        "开盘价": 5.61,
        "最高价": 6.0,
        "最低价": 5.57,
        "昨收价": 5.66,
        "总市值": 280944524.0,
        "市盈率": -13.85,
        "成交量": 407287.0,
        "成交额": 2402171.0,
        "振幅": 7.6,
        "换手率": 0.86,
        "symbol": "105.AUGX"
    },
    {
        "序号": 615,
        "name": "The Lion Electric Co",
        "最新价": 1.73,
        "涨跌额": 0.07,
        "涨跌幅": 4.22,
        "开盘价": 1.65,
        "最高价": 1.75,
        "最低价": 1.65,
        "昨收价": 1.66,
        "总市值": 391299932.0,
        "市盈率": -7.55,
        "成交量": 322689.0,
        "成交额": 552583.0,
        "振幅": 6.02,
        "换手率": 0.14,
        "symbol": "106.LEV"
    },
    {
        "序号": 616,
        "name": "萨万通金控",
        "最新价": 18.29,
        "涨跌额": 0.74,
        "涨跌幅": 4.22,
        "开盘价": 17.55,
        "最高价": 18.5,
        "最低价": 17.43,
        "昨收价": 17.55,
        "总市值": 190238039.0,
        "市盈率": 9.62,
        "成交量": 13678.0,
        "成交额": 243284.0,
        "振幅": 6.1,
        "换手率": 0.13,
        "symbol": "105.ESSA"
    },
    {
        "序号": 617,
        "name": "辛克莱广播",
        "最新价": 13.35,
        "涨跌额": 0.54,
        "涨跌幅": 4.22,
        "开盘价": 12.78,
        "最高价": 13.56,
        "最低价": 12.78,
        "昨收价": 12.81,
        "总市值": 847919696.0,
        "市盈率": 8.08,
        "成交量": 308694.0,
        "成交额": 4125702.0,
        "振幅": 6.09,
        "换手率": 0.49,
        "symbol": "105.SBGI"
    },
    {
        "序号": 618,
        "name": "VCI Global Ltd",
        "最新价": 2.72,
        "涨跌额": 0.11,
        "涨跌幅": 4.21,
        "开盘价": 2.54,
        "最高价": 2.81,
        "最低价": 2.45,
        "昨收价": 2.61,
        "总市值": 103435015.0,
        "市盈率": 16.27,
        "成交量": 102164.0,
        "成交额": 275019.0,
        "振幅": 13.79,
        "换手率": 0.27,
        "symbol": "105.VCIG"
    },
    {
        "序号": 619,
        "name": "HomeStreet Inc",
        "最新价": 7.67,
        "涨跌额": 0.31,
        "涨跌幅": 4.21,
        "开盘价": 7.4,
        "最高价": 7.8,
        "最低价": 7.4,
        "昨收价": 7.36,
        "总市值": 144273122.0,
        "市盈率": -9.26,
        "成交量": 182161.0,
        "成交额": 1401849.0,
        "振幅": 5.43,
        "换手率": 0.97,
        "symbol": "105.HMST"
    },
    {
        "序号": 620,
        "name": "NGL Energy Partners LP Class B ",
        "最新价": 27.5,
        "涨跌额": 1.11,
        "涨跌幅": 4.21,
        "开盘价": 25.67,
        "最高价": 27.5,
        "最低价": 25.67,
        "昨收价": 26.39,
        "总市值": 346105155.0,
        "市盈率": null,
        "成交量": 14921.0,
        "成交额": 389271.0,
        "振幅": 6.93,
        "换手率": 0.12,
        "symbol": "106.NGL_B"
    },
    {
        "序号": 621,
        "name": "恩普雷萨电力",
        "最新价": 17.6,
        "涨跌额": 0.71,
        "涨跌幅": 4.2,
        "开盘价": 16.9,
        "最高价": 17.6,
        "最低价": 16.65,
        "昨收价": 16.89,
        "总市值": 770530507.0,
        "市盈率": 1.93,
        "成交量": 49239.0,
        "成交额": 839655.0,
        "振幅": 5.62,
        "换手率": 0.11,
        "symbol": "106.EDN"
    },
    {
        "序号": 622,
        "name": "Novo Integrated Sciences Inc",
        "最新价": 1.24,
        "涨跌额": 0.05,
        "涨跌幅": 4.2,
        "开盘价": 1.21,
        "最高价": 1.27,
        "最低价": 1.16,
        "昨收价": 1.19,
        "总市值": 18498838.0,
        "市盈率": -0.57,
        "成交量": 674726.0,
        "成交额": 816578.0,
        "振幅": 9.24,
        "换手率": 4.52,
        "symbol": "105.NVOS"
    },
    {
        "序号": 623,
        "name": "Cardlytics Inc",
        "最新价": 8.44,
        "涨跌额": 0.34,
        "涨跌幅": 4.2,
        "开盘价": 8.04,
        "最高价": 8.54,
        "最低价": 7.91,
        "昨收价": 8.1,
        "总市值": 331304992.0,
        "市盈率": -0.8,
        "成交量": 370552.0,
        "成交额": 3080476.0,
        "振幅": 7.78,
        "换手率": 0.94,
        "symbol": "105.CDLX"
    },
    {
        "序号": 624,
        "name": "Limbach Holdings Inc",
        "最新价": 41.96,
        "涨跌额": 1.69,
        "涨跌幅": 4.2,
        "开盘价": 40.27,
        "最高价": 42.22,
        "最低价": 40.27,
        "昨收价": 40.27,
        "总市值": 461703671.0,
        "市盈率": 23.91,
        "成交量": 179124.0,
        "成交额": 7469061.0,
        "振幅": 4.84,
        "换手率": 1.63,
        "symbol": "105.LMB"
    },
    {
        "序号": 625,
        "name": "明大嘉和",
        "最新价": 1.49,
        "涨跌额": 0.06,
        "涨跌幅": 4.2,
        "开盘价": 1.49,
        "最高价": 1.5,
        "最低价": 1.48,
        "昨收价": 1.43,
        "总市值": 17396072.0,
        "市盈率": -10.33,
        "成交量": 1070.0,
        "成交额": 1592.0,
        "振幅": 1.4,
        "换手率": 0.01,
        "symbol": "105.MDJH"
    },
    {
        "序号": 626,
        "name": "RingCentral Inc-A",
        "最新价": 34.11,
        "涨跌额": 1.37,
        "涨跌幅": 4.18,
        "开盘价": 32.36,
        "最高价": 34.57,
        "最低价": 32.14,
        "昨收价": 32.74,
        "总市值": 3197712421.0,
        "市盈率": -7.95,
        "成交量": 2048829.0,
        "成交额": 69973398.0,
        "振幅": 7.42,
        "换手率": 2.19,
        "symbol": "106.RNG"
    },
    {
        "序号": 627,
        "name": "Urban One Inc-A",
        "最新价": 3.99,
        "涨跌额": 0.16,
        "涨跌幅": 4.18,
        "开盘价": 3.9,
        "最高价": 4.09,
        "最低价": 3.85,
        "昨收价": 3.83,
        "总市值": 195210439.0,
        "市盈率": 2.71,
        "成交量": 22012.0,
        "成交额": 87155.0,
        "振幅": 6.27,
        "换手率": 0.15,
        "symbol": "105.UONE"
    },
    {
        "序号": 628,
        "name": "Matinas BioPharma Holdings Inc",
        "最新价": 0.25,
        "涨跌额": 0.01,
        "涨跌幅": 4.17,
        "开盘价": 0.23,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.24,
        "总市值": 54316132.0,
        "市盈率": -2.55,
        "成交量": 596783.0,
        "成交额": 139641.0,
        "振幅": 8.33,
        "换手率": 0.27,
        "symbol": "107.MTNB"
    },
    {
        "序号": 629,
        "name": "Martin Midstream Partners LP",
        "最新价": 2.5,
        "涨跌额": 0.1,
        "涨跌幅": 4.17,
        "开盘价": 2.39,
        "最高价": 2.54,
        "最低价": 2.39,
        "昨收价": 2.4,
        "总市值": 97287015.0,
        "市盈率": -17.88,
        "成交量": 101882.0,
        "成交额": 251413.0,
        "振幅": 6.25,
        "换手率": 0.26,
        "symbol": "105.MMLP"
    },
    {
        "序号": 630,
        "name": "Lithium Americas (Argentina) Co",
        "最新价": 5.75,
        "涨跌额": 0.23,
        "涨跌幅": 4.17,
        "开盘价": 5.53,
        "最高价": 5.93,
        "最低价": 5.53,
        "昨收价": 5.52,
        "总市值": 922875000.0,
        "市盈率": 25.53,
        "成交量": 1910609.0,
        "成交额": 11074776.0,
        "振幅": 7.25,
        "换手率": 1.19,
        "symbol": "106.LAAC"
    },
    {
        "序号": 631,
        "name": "FinWise Bancorp",
        "最新价": 12.0,
        "涨跌额": 0.48,
        "涨跌幅": 4.17,
        "开盘价": 11.42,
        "最高价": 12.01,
        "最低价": 11.42,
        "昨收价": 11.52,
        "总市值": 149922780.0,
        "市盈率": 7.55,
        "成交量": 5701.0,
        "成交额": 66865.0,
        "振幅": 5.12,
        "换手率": 0.05,
        "symbol": "105.FINW"
    },
    {
        "序号": 632,
        "name": "Cara Therapeutics Inc",
        "最新价": 1.25,
        "涨跌额": 0.05,
        "涨跌幅": 4.17,
        "开盘价": 1.19,
        "最高价": 1.31,
        "最低价": 1.19,
        "昨收价": 1.2,
        "总市值": 68100880.0,
        "市盈率": -0.58,
        "成交量": 657863.0,
        "成交额": 825295.0,
        "振幅": 10.0,
        "换手率": 1.21,
        "symbol": "105.CARA"
    },
    {
        "序号": 633,
        "name": "极速网络",
        "最新价": 17.01,
        "涨跌额": 0.68,
        "涨跌幅": 4.16,
        "开盘价": 16.28,
        "最高价": 17.1,
        "最低价": 16.24,
        "昨收价": 16.33,
        "总市值": 2204731231.0,
        "市盈率": 23.41,
        "成交量": 1496605.0,
        "成交额": 25135037.0,
        "振幅": 5.27,
        "换手率": 1.15,
        "symbol": "105.EXTR"
    },
    {
        "序号": 634,
        "name": "Oportun Financial Corp",
        "最新价": 3.01,
        "涨跌额": 0.12,
        "涨跌幅": 4.15,
        "开盘价": 2.93,
        "最高价": 3.01,
        "最低价": 2.85,
        "昨收价": 2.89,
        "总市值": 103032818.0,
        "市盈率": -0.7,
        "成交量": 429621.0,
        "成交额": 1260425.0,
        "振幅": 5.54,
        "换手率": 1.26,
        "symbol": "105.OPRT"
    },
    {
        "序号": 635,
        "name": "万豪度假环球",
        "最新价": 80.88,
        "涨跌额": 3.22,
        "涨跌幅": 4.15,
        "开盘价": 79.08,
        "最高价": 81.92,
        "最低价": 78.63,
        "昨收价": 77.66,
        "总市值": 2872631379.0,
        "市盈率": 9.36,
        "成交量": 1051473.0,
        "成交额": 84695690.0,
        "振幅": 4.24,
        "换手率": 2.96,
        "symbol": "106.VAC"
    },
    {
        "序号": 636,
        "name": "莱纳建筑-B",
        "最新价": 130.74,
        "涨跌额": 5.19,
        "涨跌幅": 4.13,
        "开盘价": 125.65,
        "最高价": 130.78,
        "最低价": 125.65,
        "昨收价": 125.55,
        "总市值": 37176559495.0,
        "市盈率": 9.53,
        "成交量": 211299.0,
        "成交额": 27313900.0,
        "振幅": 4.09,
        "换手率": 0.62,
        "symbol": "106.LEN_B"
    },
    {
        "序号": 637,
        "name": "LendingTree Inc",
        "最新价": 23.72,
        "涨跌额": 0.94,
        "涨跌幅": 4.13,
        "开盘价": 22.61,
        "最高价": 24.24,
        "最低价": 21.96,
        "昨收价": 22.78,
        "总市值": 308426084.0,
        "市盈率": -2.12,
        "成交量": 461217.0,
        "成交额": 10884115.0,
        "振幅": 10.01,
        "换手率": 3.55,
        "symbol": "105.TREE"
    },
    {
        "序号": 638,
        "name": "Vail Resorts Inc",
        "最新价": 225.87,
        "涨跌额": 8.94,
        "涨跌幅": 4.12,
        "开盘价": 218.68,
        "最高价": 227.68,
        "最低价": 217.7,
        "昨收价": 216.93,
        "总市值": 8575522718.0,
        "市盈率": 37.35,
        "成交量": 775918.0,
        "成交额": 175122611.0,
        "振幅": 4.6,
        "换手率": 2.04,
        "symbol": "106.MTN"
    },
    {
        "序号": 639,
        "name": "MYT Netherlands Parent BV ADR",
        "最新价": 3.54,
        "涨跌额": 0.14,
        "涨跌幅": 4.12,
        "开盘价": 3.48,
        "最高价": 3.56,
        "最低价": 3.32,
        "昨收价": 3.4,
        "总市值": 300511297.0,
        "市盈率": -11.89,
        "成交量": 85757.0,
        "成交额": 297056.0,
        "振幅": 7.06,
        "换手率": 0.1,
        "symbol": "106.MYTE"
    },
    {
        "序号": 640,
        "name": "Friedman Industries Inc",
        "最新价": 13.43,
        "涨跌额": 0.53,
        "涨跌幅": 4.11,
        "开盘价": 12.84,
        "最高价": 13.43,
        "最低价": 12.68,
        "昨收价": 12.9,
        "总市值": 99050037.0,
        "市盈率": 5.24,
        "成交量": 18303.0,
        "成交额": 237818.0,
        "振幅": 5.81,
        "换手率": 0.25,
        "symbol": "107.FRD"
    },
    {
        "序号": 641,
        "name": "浦项钢铁",
        "最新价": 89.85,
        "涨跌额": 3.54,
        "涨跌幅": 4.1,
        "开盘价": 89.81,
        "最高价": 90.35,
        "最低价": 89.36,
        "昨收价": 86.31,
        "总市值": 27269908796.0,
        "市盈率": 26.15,
        "成交量": 139387.0,
        "成交额": 12532963.0,
        "振幅": 1.15,
        "换手率": 0.05,
        "symbol": "106.PKX"
    },
    {
        "序号": 642,
        "name": "BioLineRx Ltd ADR",
        "最新价": 1.78,
        "涨跌额": 0.07,
        "涨跌幅": 4.09,
        "开盘价": 1.71,
        "最高价": 1.89,
        "最低价": 1.7,
        "昨收价": 1.71,
        "总市值": 115096937.0,
        "市盈率": -2.19,
        "成交量": 667228.0,
        "成交额": 1212483.0,
        "振幅": 11.11,
        "换手率": 1.03,
        "symbol": "105.BLRX"
    },
    {
        "序号": 643,
        "name": "涂鸦智能",
        "最新价": 2.29,
        "涨跌额": 0.09,
        "涨跌幅": 4.09,
        "开盘价": 2.19,
        "最高价": 2.29,
        "最低价": 2.18,
        "昨收价": 2.2,
        "总市值": 1318566870.0,
        "市盈率": -18.26,
        "成交量": 261598.0,
        "成交额": 578033.0,
        "振幅": 5.0,
        "换手率": 0.05,
        "symbol": "106.TUYA"
    },
    {
        "序号": 644,
        "name": "Recruiter.com Group Inc",
        "最新价": 1.79,
        "涨跌额": 0.07,
        "涨跌幅": 4.07,
        "开盘价": 1.81,
        "最高价": 1.83,
        "最低价": 1.64,
        "昨收价": 1.72,
        "总市值": 2566686.0,
        "市盈率": -0.24,
        "成交量": 36228.0,
        "成交额": 62763.0,
        "振幅": 11.05,
        "换手率": 2.53,
        "symbol": "105.RCRT"
    },
    {
        "序号": 645,
        "name": "Arista Networks Inc",
        "最新价": 224.03,
        "涨跌额": 8.74,
        "涨跌幅": 4.06,
        "开盘价": 216.43,
        "最高价": 224.23,
        "最低价": 216.43,
        "昨收价": 215.29,
        "总市值": 69695764812.0,
        "市盈率": 36.67,
        "成交量": 2211735.0,
        "成交额": 491898096.0,
        "振幅": 3.62,
        "换手率": 0.71,
        "symbol": "106.ANET"
    },
    {
        "序号": 646,
        "name": "Mineralys Therapeutics Inc",
        "最新价": 7.18,
        "涨跌额": 0.28,
        "涨跌幅": 4.06,
        "开盘价": 6.89,
        "最高价": 7.23,
        "最低价": 6.65,
        "昨收价": 6.9,
        "总市值": 295134747.0,
        "市盈率": -5.22,
        "成交量": 187785.0,
        "成交额": 1332319.0,
        "振幅": 8.41,
        "换手率": 0.46,
        "symbol": "105.MLYS"
    },
    {
        "序号": 647,
        "name": "ProFrac Holding Corp-A",
        "最新价": 7.97,
        "涨跌额": 0.31,
        "涨跌幅": 4.05,
        "开盘价": 7.83,
        "最高价": 8.09,
        "最低价": 7.69,
        "昨收价": 7.66,
        "总市值": 1270329063.0,
        "市盈率": 35.78,
        "成交量": 476933.0,
        "成交额": 3781530.0,
        "振幅": 5.22,
        "换手率": 0.3,
        "symbol": "105.ACDC"
    },
    {
        "序号": 648,
        "name": "美高梅",
        "最新价": 41.42,
        "涨跌额": 1.61,
        "涨跌幅": 4.04,
        "开盘价": 39.91,
        "最高价": 41.45,
        "最低价": 39.76,
        "昨收价": 39.81,
        "总市值": 14148383641.0,
        "市盈率": 12.72,
        "成交量": 5918537.0,
        "成交额": 243188303.0,
        "振幅": 4.25,
        "换手率": 1.73,
        "symbol": "106.MGM"
    },
    {
        "序号": 649,
        "name": "KKR Real Estate Finance Trust I",
        "最新价": 18.55,
        "涨跌额": 0.72,
        "涨跌幅": 4.04,
        "开盘价": 17.82,
        "最高价": 18.56,
        "最低价": 17.79,
        "昨收价": 17.83,
        "总市值": 243190500.0,
        "市盈率": null,
        "成交量": 40908.0,
        "成交额": 732824.0,
        "振幅": 4.32,
        "换手率": 0.31,
        "symbol": "106.KREF_A"
    },
    {
        "序号": 650,
        "name": "燃料电池能源",
        "最新价": 1.29,
        "涨跌额": 0.05,
        "涨跌幅": 4.03,
        "开盘价": 1.21,
        "最高价": 1.34,
        "最低价": 1.21,
        "昨收价": 1.24,
        "总市值": 581307970.0,
        "市盈率": -4.86,
        "成交量": 10516983.0,
        "成交额": 13577500.0,
        "振幅": 10.48,
        "换手率": 2.33,
        "symbol": "105.FCEL"
    },
    {
        "序号": 651,
        "name": "Olema Pharmaceuticals Inc",
        "最新价": 12.13,
        "涨跌额": 0.47,
        "涨跌幅": 4.03,
        "开盘价": 11.6,
        "最高价": 12.55,
        "最低价": 11.58,
        "昨收价": 11.66,
        "总市值": 664357674.0,
        "市盈率": -6.92,
        "成交量": 922782.0,
        "成交额": 11216170.0,
        "振幅": 8.32,
        "换手率": 1.68,
        "symbol": "105.OLMA"
    },
    {
        "序号": 652,
        "name": "Envoy Medical Inc-A",
        "最新价": 2.07,
        "涨跌额": 0.08,
        "涨跌幅": 4.02,
        "开盘价": 2.09,
        "最高价": 2.21,
        "最低价": 1.99,
        "昨收价": 1.99,
        "总市值": 40468463.0,
        "市盈率": -30.04,
        "成交量": 56640.0,
        "成交额": 118260.0,
        "振幅": 11.06,
        "换手率": 0.29,
        "symbol": "105.COCH"
    },
    {
        "序号": 653,
        "name": "Allakos Inc",
        "最新价": 3.11,
        "涨跌额": 0.12,
        "涨跌幅": 4.01,
        "开盘价": 3.04,
        "最高价": 3.17,
        "最低价": 2.96,
        "昨收价": 2.99,
        "总市值": 272051411.0,
        "市盈率": -1.64,
        "成交量": 927423.0,
        "成交额": 2867607.0,
        "振幅": 7.02,
        "换手率": 1.06,
        "symbol": "105.ALLK"
    },
    {
        "序号": 654,
        "name": "高斯电子",
        "最新价": 3.37,
        "涨跌额": 0.13,
        "涨跌幅": 4.01,
        "开盘价": 3.34,
        "最高价": 3.39,
        "最低价": 3.12,
        "昨收价": 3.24,
        "总市值": 31121259.0,
        "市盈率": -16.38,
        "成交量": 25406.0,
        "成交额": 84447.0,
        "振幅": 8.33,
        "换手率": 0.28,
        "symbol": "105.KOSS"
    },
    {
        "序号": 655,
        "name": "Sharps Technology Inc",
        "最新价": 0.52,
        "涨跌额": 0.02,
        "涨跌幅": 4.0,
        "开盘价": 0.5,
        "最高价": 0.54,
        "最低价": 0.49,
        "昨收价": 0.5,
        "总市值": 7942718.0,
        "市盈率": -1.05,
        "成交量": 65677.0,
        "成交额": 33589.0,
        "振幅": 10.0,
        "换手率": 0.43,
        "symbol": "105.STSS"
    },
    {
        "序号": 656,
        "name": "noco-noco Inc",
        "最新价": 0.26,
        "涨跌额": 0.01,
        "涨跌幅": 4.0,
        "开盘价": 0.26,
        "最高价": 0.27,
        "最低价": 0.25,
        "昨收价": 0.25,
        "总市值": 35211918.0,
        "市盈率": 64.98,
        "成交量": 526060.0,
        "成交额": 136743.0,
        "振幅": 8.0,
        "换手率": 0.39,
        "symbol": "105.NCNC"
    },
    {
        "序号": 657,
        "name": "Amprius Technologies Inc Wt",
        "最新价": 0.26,
        "涨跌额": 0.01,
        "涨跌幅": 4.0,
        "开盘价": 0.23,
        "最高价": 0.28,
        "最低价": 0.23,
        "昨收价": 0.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 6079.0,
        "成交额": 1530.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "106.AMPX_WS"
    },
    {
        "序号": 658,
        "name": "AIB Acquisition Corp-A",
        "最新价": 11.73,
        "涨跌额": 0.45,
        "涨跌幅": 3.99,
        "开盘价": 11.76,
        "最高价": 11.76,
        "最低价": 11.73,
        "昨收价": 11.28,
        "总市值": 42369065.0,
        "市盈率": 421.11,
        "成交量": 2211.0,
        "成交额": 25961.0,
        "振幅": 0.27,
        "换手率": 0.06,
        "symbol": "105.AIB"
    },
    {
        "序号": 659,
        "name": "Scholar Rock Holding Corp",
        "最新价": 18.25,
        "涨跌额": 0.7,
        "涨跌幅": 3.99,
        "开盘价": 17.91,
        "最高价": 19.53,
        "最低价": 17.88,
        "昨收价": 17.55,
        "总市值": 1316614459.0,
        "市盈率": -8.28,
        "成交量": 1678429.0,
        "成交额": 31257205.0,
        "振幅": 9.4,
        "换手率": 2.33,
        "symbol": "105.SRRK"
    },
    {
        "序号": 660,
        "name": "Eliem Therapeutics Inc",
        "最新价": 2.61,
        "涨跌额": 0.1,
        "涨跌幅": 3.98,
        "开盘价": 2.6,
        "最高价": 2.63,
        "最低价": 2.45,
        "昨收价": 2.51,
        "总市值": 71964653.0,
        "市盈率": -1.83,
        "成交量": 23047.0,
        "成交额": 59845.0,
        "振幅": 7.17,
        "换手率": 0.08,
        "symbol": "105.ELYM"
    },
    {
        "序号": 661,
        "name": "二倍做空金矿指数ETF-Direxion",
        "最新价": 11.75,
        "涨跌额": 0.45,
        "涨跌幅": 3.98,
        "开盘价": 11.78,
        "最高价": 12.0,
        "最低价": 11.45,
        "昨收价": 11.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 5415700.0,
        "成交额": 63513280.0,
        "振幅": 4.87,
        "换手率": null,
        "symbol": "107.DUST"
    },
    {
        "序号": 662,
        "name": "AXS 2X Innovation ETF",
        "最新价": 82.33,
        "涨跌额": 3.15,
        "涨跌幅": 3.98,
        "开盘价": 78.06,
        "最高价": 83.89,
        "最低价": 77.82,
        "昨收价": 79.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 249841.0,
        "成交额": 20403979.0,
        "振幅": 7.67,
        "换手率": null,
        "symbol": "105.TARK"
    },
    {
        "序号": 663,
        "name": "Klaviyo Inc-A",
        "最新价": 29.88,
        "涨跌额": 1.14,
        "涨跌幅": 3.97,
        "开盘价": 28.69,
        "最高价": 29.89,
        "最低价": 28.68,
        "昨收价": 28.74,
        "总市值": 7729688484.0,
        "市盈率": -27.34,
        "成交量": 692336.0,
        "成交额": 20467452.0,
        "振幅": 4.21,
        "换手率": 0.27,
        "symbol": "106.KVYO"
    },
    {
        "序号": 664,
        "name": "Lightspeed Commerce Inc",
        "最新价": 17.04,
        "涨跌额": 0.65,
        "涨跌幅": 3.97,
        "开盘价": 16.33,
        "最高价": 17.1,
        "最低价": 16.3,
        "昨收价": 16.39,
        "总市值": 2600052762.0,
        "市盈率": -2.65,
        "成交量": 1089656.0,
        "成交额": 18378066.0,
        "振幅": 4.88,
        "换手率": 0.71,
        "symbol": "106.LSPD"
    },
    {
        "序号": 665,
        "name": "Revance Therapeutics Inc",
        "最新价": 7.37,
        "涨跌额": 0.28,
        "涨跌幅": 3.95,
        "开盘价": 7.04,
        "最高价": 7.54,
        "最低价": 6.94,
        "昨收价": 7.09,
        "总市值": 647185348.0,
        "市盈率": -1.56,
        "成交量": 1229166.0,
        "成交额": 9032165.0,
        "振幅": 8.46,
        "换手率": 1.4,
        "symbol": "105.RVNC"
    },
    {
        "序号": 666,
        "name": "The RealReal Inc",
        "最新价": 2.37,
        "涨跌额": 0.09,
        "涨跌幅": 3.95,
        "开盘价": 2.25,
        "最高价": 2.4,
        "最低价": 2.22,
        "昨收价": 2.28,
        "总市值": 244946885.0,
        "市盈率": -1.32,
        "成交量": 2210944.0,
        "成交额": 5185105.0,
        "振幅": 7.89,
        "换手率": 2.14,
        "symbol": "105.REAL"
    },
    {
        "序号": 667,
        "name": "Arcimoto Inc",
        "最新价": 0.79,
        "涨跌额": 0.03,
        "涨跌幅": 3.95,
        "开盘价": 0.78,
        "最高价": 0.8,
        "最低价": 0.76,
        "昨收价": 0.76,
        "总市值": 6956659.0,
        "市盈率": -0.13,
        "成交量": 64700.0,
        "成交额": 50603.0,
        "振幅": 5.26,
        "换手率": 0.73,
        "symbol": "105.FUV"
    },
    {
        "序号": 668,
        "name": "Domo Inc-B",
        "最新价": 9.24,
        "涨跌额": 0.35,
        "涨跌幅": 3.94,
        "开盘价": 8.82,
        "最高价": 9.32,
        "最低价": 8.69,
        "昨收价": 8.89,
        "总市值": 338347530.0,
        "市盈率": -4.41,
        "成交量": 514974.0,
        "成交额": 4705501.0,
        "振幅": 7.09,
        "换手率": 1.41,
        "symbol": "105.DOMO"
    },
    {
        "序号": 669,
        "name": "NexGen Energy Ltd",
        "最新价": 6.87,
        "涨跌额": 0.26,
        "涨跌幅": 3.93,
        "开盘价": 6.63,
        "最高价": 6.9,
        "最低价": 6.58,
        "昨收价": 6.61,
        "总市值": 3591819580.0,
        "市盈率": -47.89,
        "成交量": 5204978.0,
        "成交额": 35459307.0,
        "振幅": 4.84,
        "换手率": 1.0,
        "symbol": "106.NXE"
    },
    {
        "序号": 670,
        "name": "Alvotech Wt",
        "最新价": 1.85,
        "涨跌额": 0.07,
        "涨跌幅": 3.93,
        "开盘价": 1.85,
        "最高价": 1.92,
        "最低价": 1.85,
        "昨收价": 1.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1583.0,
        "成交额": 2971.0,
        "振幅": 3.93,
        "换手率": null,
        "symbol": "105.ALVOW"
    },
    {
        "序号": 671,
        "name": "MicroSectors U.S. Big Banks Ind",
        "最新价": 19.33,
        "涨跌额": 0.73,
        "涨跌幅": 3.92,
        "开盘价": 18.59,
        "最高价": 19.55,
        "最低价": 18.47,
        "昨收价": 18.6,
        "总市值": 13935174082.0,
        "市盈率": 4.39,
        "成交量": 792608.0,
        "成交额": 15177060.0,
        "振幅": 5.81,
        "换手率": null,
        "symbol": "107.BNKU"
    },
    {
        "序号": 672,
        "name": "克利尔菲尔德通讯",
        "最新价": 27.84,
        "涨跌额": 1.05,
        "涨跌幅": 3.92,
        "开盘价": 26.98,
        "最高价": 28.05,
        "最低价": 26.81,
        "昨收价": 26.79,
        "总市值": 424685976.0,
        "市盈率": 13.05,
        "成交量": 374000.0,
        "成交额": 10343432.0,
        "振幅": 4.63,
        "换手率": 2.45,
        "symbol": "105.CLFD"
    },
    {
        "序号": 673,
        "name": "泰克资源",
        "最新价": 38.73,
        "涨跌额": 1.46,
        "涨跌幅": 3.92,
        "开盘价": 37.03,
        "最高价": 38.76,
        "最低价": 37.03,
        "昨收价": 37.27,
        "总市值": 20144113323.0,
        "市盈率": 12.24,
        "成交量": 4190433.0,
        "成交额": 161166294.0,
        "振幅": 4.64,
        "换手率": 0.81,
        "symbol": "106.TECK"
    },
    {
        "序号": 674,
        "name": "QuinStreet Inc",
        "最新价": 13.0,
        "涨跌额": 0.49,
        "涨跌幅": 3.92,
        "开盘价": 12.45,
        "最高价": 13.16,
        "最低价": 12.42,
        "昨收价": 12.51,
        "总市值": 709527572.0,
        "市盈率": -9.47,
        "成交量": 806775.0,
        "成交额": 10361820.0,
        "振幅": 5.92,
        "换手率": 1.48,
        "symbol": "105.QNST"
    },
    {
        "序号": 675,
        "name": "Pro-Dex Inc",
        "最新价": 16.76,
        "涨跌额": 0.63,
        "涨跌幅": 3.91,
        "开盘价": 16.3,
        "最高价": 16.76,
        "最低价": 16.25,
        "昨收价": 16.13,
        "总市值": 59453251.0,
        "市盈率": 11.31,
        "成交量": 5681.0,
        "成交额": 94239.0,
        "振幅": 3.16,
        "换手率": 0.16,
        "symbol": "105.PDEX"
    },
    {
        "序号": 676,
        "name": "Stem Inc-A",
        "最新价": 3.46,
        "涨跌额": 0.13,
        "涨跌幅": 3.9,
        "开盘价": 3.32,
        "最高价": 3.48,
        "最低价": 3.18,
        "昨收价": 3.33,
        "总市值": 539398918.0,
        "市盈率": -3.91,
        "成交量": 4469831.0,
        "成交额": 15067640.0,
        "振幅": 9.01,
        "换手率": 2.87,
        "symbol": "106.STEM"
    },
    {
        "序号": 677,
        "name": "Instructure Holdings Inc",
        "最新价": 27.15,
        "涨跌额": 1.02,
        "涨跌幅": 3.9,
        "开盘价": 25.95,
        "最高价": 27.16,
        "最低价": 25.93,
        "昨收价": 26.13,
        "总市值": 3928500635.0,
        "市盈率": -115.43,
        "成交量": 326939.0,
        "成交额": 8785991.0,
        "振幅": 4.71,
        "换手率": 0.23,
        "symbol": "106.INST"
    },
    {
        "序号": 678,
        "name": "Chijet Motor Co Inc",
        "最新价": 0.8,
        "涨跌额": 0.03,
        "涨跌幅": 3.9,
        "开盘价": 0.75,
        "最高价": 0.85,
        "最低价": 0.74,
        "昨收价": 0.77,
        "总市值": 128565738.0,
        "市盈率": -3.24,
        "成交量": 100584.0,
        "成交额": 79252.0,
        "振幅": 14.29,
        "换手率": 0.06,
        "symbol": "105.CJET"
    },
    {
        "序号": 679,
        "name": "Bark Inc",
        "最新价": 0.8,
        "涨跌额": 0.03,
        "涨跌幅": 3.9,
        "开盘价": 0.77,
        "最高价": 0.82,
        "最低价": 0.75,
        "昨收价": 0.77,
        "总市值": 143424688.0,
        "市盈率": -2.5,
        "成交量": 780532.0,
        "成交额": 619014.0,
        "振幅": 9.09,
        "换手率": 0.44,
        "symbol": "106.BARK"
    },
    {
        "序号": 680,
        "name": "格雷电视-A",
        "最新价": 8.81,
        "涨跌额": 0.33,
        "涨跌幅": 3.89,
        "开盘价": 8.66,
        "最高价": 8.81,
        "最低价": 8.45,
        "昨收价": 8.48,
        "总市值": 840957035.0,
        "市盈率": 7.07,
        "成交量": 2144.0,
        "成交额": 18405.0,
        "振幅": 4.25,
        "换手率": 0.03,
        "symbol": "106.GTN_A"
    },
    {
        "序号": 681,
        "name": "DarioHealth Corp",
        "最新价": 1.87,
        "涨跌额": 0.07,
        "涨跌幅": 3.89,
        "开盘价": 1.78,
        "最高价": 1.93,
        "最低价": 1.75,
        "昨收价": 1.8,
        "总市值": 50891433.0,
        "市盈率": -0.88,
        "成交量": 111276.0,
        "成交额": 204506.0,
        "振幅": 10.0,
        "换手率": 0.41,
        "symbol": "105.DRIO"
    },
    {
        "序号": 682,
        "name": "IMAX Corp",
        "最新价": 16.31,
        "涨跌额": 0.61,
        "涨跌幅": 3.89,
        "开盘价": 15.73,
        "最高价": 16.43,
        "最低价": 15.73,
        "昨收价": 15.7,
        "总市值": 890641719.0,
        "市盈率": 35.05,
        "成交量": 968817.0,
        "成交额": 15716567.0,
        "振幅": 4.46,
        "换手率": 1.77,
        "symbol": "106.IMAX"
    },
    {
        "序号": 683,
        "name": "Professional Diversity Network ",
        "最新价": 2.14,
        "涨跌额": 0.08,
        "涨跌幅": 3.88,
        "开盘价": 2.0,
        "最高价": 2.16,
        "最低价": 1.85,
        "昨收价": 2.06,
        "总市值": 23690836.0,
        "市盈率": -4.91,
        "成交量": 24751.0,
        "成交额": 50608.0,
        "振幅": 15.05,
        "换手率": 0.22,
        "symbol": "105.IPDN"
    },
    {
        "序号": 684,
        "name": "MicroSectors Gold -3X Inverse L",
        "最新价": 19.54,
        "涨跌额": 0.73,
        "涨跌幅": 3.88,
        "开盘价": 19.39,
        "最高价": 19.62,
        "最低价": 19.39,
        "昨收价": 18.81,
        "总市值": 14086565006.0,
        "市盈率": 4.44,
        "成交量": 764.0,
        "成交额": 14930.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.DULL"
    },
    {
        "序号": 685,
        "name": "Sky Harbour Group Corp-A",
        "最新价": 10.98,
        "涨跌额": 0.41,
        "涨跌幅": 3.88,
        "开盘价": 10.74,
        "最高价": 11.18,
        "最低价": 10.61,
        "昨收价": 10.57,
        "总市值": 701564531.0,
        "市盈率": -138.13,
        "成交量": 63691.0,
        "成交额": 697979.0,
        "振幅": 5.39,
        "换手率": 0.1,
        "symbol": "107.SKYH"
    },
    {
        "序号": 686,
        "name": "阿根廷YPF",
        "最新价": 17.14,
        "涨跌额": 0.64,
        "涨跌幅": 3.88,
        "开盘价": 16.53,
        "最高价": 17.33,
        "最低价": 16.42,
        "昨收价": 16.5,
        "总市值": 6741381272.0,
        "市盈率": 5.4,
        "成交量": 2813543.0,
        "成交额": 47871716.0,
        "振幅": 5.52,
        "换手率": 0.72,
        "symbol": "106.YPF"
    },
    {
        "序号": 687,
        "name": "Sprott Lithium Miners ETF",
        "最新价": 11.8,
        "涨跌额": 0.44,
        "涨跌幅": 3.87,
        "开盘价": 11.58,
        "最高价": 11.91,
        "最低价": 11.58,
        "昨收价": 11.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 14476.0,
        "成交额": 170465.0,
        "振幅": 2.9,
        "换手率": null,
        "symbol": "105.LITP"
    },
    {
        "序号": 688,
        "name": "Renovaro Biosciences Inc",
        "最新价": 3.49,
        "涨跌额": 0.13,
        "涨跌幅": 3.87,
        "开盘价": 3.41,
        "最高价": 3.55,
        "最低价": 3.4,
        "昨收价": 3.36,
        "总市值": 232776523.0,
        "市盈率": -5.66,
        "成交量": 58110.0,
        "成交额": 200623.0,
        "振幅": 4.46,
        "换手率": 0.09,
        "symbol": "105.RENB"
    },
    {
        "序号": 689,
        "name": "Cosan SA ADR",
        "最新价": 14.26,
        "涨跌额": 0.53,
        "涨跌幅": 3.86,
        "开盘价": 13.73,
        "最高价": 14.28,
        "最低价": 13.7,
        "昨收价": 13.73,
        "总市值": 6657637714.0,
        "市盈率": -56.95,
        "成交量": 322180.0,
        "成交额": 4546034.0,
        "振幅": 4.22,
        "换手率": 0.07,
        "symbol": "106.CSAN"
    },
    {
        "序号": 690,
        "name": "二倍做空小金矿指数ETF-Direxion",
        "最新价": 6.19,
        "涨跌额": 0.23,
        "涨跌幅": 3.86,
        "开盘价": 6.21,
        "最高价": 6.32,
        "最低价": 6.01,
        "昨收价": 5.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 8157289.0,
        "成交额": 50282655.0,
        "振幅": 5.2,
        "换手率": null,
        "symbol": "107.JDST"
    },
    {
        "序号": 691,
        "name": "IonQ Inc Wt",
        "最新价": 4.85,
        "涨跌额": 0.18,
        "涨跌幅": 3.85,
        "开盘价": 4.74,
        "最高价": 5.02,
        "最低价": 4.74,
        "昨收价": 4.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 24434.0,
        "成交额": 120410.0,
        "振幅": 6.0,
        "换手率": null,
        "symbol": "106.IONQ_WS"
    },
    {
        "序号": 692,
        "name": "Burke & Herbert Financial Servi",
        "最新价": 55.02,
        "涨跌额": 2.04,
        "涨跌幅": 3.85,
        "开盘价": 53.4,
        "最高价": 55.77,
        "最低价": 52.9,
        "昨收价": 52.98,
        "总市值": 408727624.0,
        "市盈率": null,
        "成交量": 30604.0,
        "成交额": 1673373.0,
        "振幅": 5.42,
        "换手率": 0.41,
        "symbol": "105.BHRB"
    },
    {
        "序号": 693,
        "name": "绿能宝",
        "最新价": 0.81,
        "涨跌额": 0.03,
        "涨跌幅": 3.85,
        "开盘价": 0.79,
        "最高价": 0.84,
        "最低价": 0.74,
        "昨收价": 0.78,
        "总市值": 25598518.0,
        "市盈率": -1.04,
        "成交量": 115577.0,
        "成交额": 94214.0,
        "振幅": 12.82,
        "换手率": 0.37,
        "symbol": "105.SPI"
    },
    {
        "序号": 694,
        "name": "Singularity Future Technology L",
        "最新价": 0.54,
        "涨跌额": 0.02,
        "涨跌幅": 3.85,
        "开盘价": 0.53,
        "最高价": 0.54,
        "最低价": 0.5,
        "昨收价": 0.52,
        "总市值": 9458384.0,
        "市盈率": -0.43,
        "成交量": 4418.0,
        "成交额": 2309.0,
        "振幅": 7.69,
        "换手率": 0.03,
        "symbol": "105.SGLY"
    },
    {
        "序号": 695,
        "name": "Asana Inc-A",
        "最新价": 19.44,
        "涨跌额": 0.72,
        "涨跌幅": 3.85,
        "开盘价": 18.62,
        "最高价": 19.54,
        "最低价": 18.5,
        "昨收价": 18.72,
        "总市值": 4326075287.0,
        "市盈率": -14.94,
        "成交量": 3437993.0,
        "成交额": 65978189.0,
        "振幅": 5.56,
        "换手率": 1.54,
        "symbol": "106.ASAN"
    },
    {
        "序号": 696,
        "name": "Atomera Inc",
        "最新价": 7.04,
        "涨跌额": 0.26,
        "涨跌幅": 3.83,
        "开盘价": 6.8,
        "最高价": 7.16,
        "最低价": 6.8,
        "昨收价": 6.78,
        "总市值": 181625087.0,
        "市盈率": -9.32,
        "成交量": 133407.0,
        "成交额": 937646.0,
        "振幅": 5.31,
        "换手率": 0.52,
        "symbol": "105.ATOM"
    },
    {
        "序号": 697,
        "name": "迪卡斯",
        "最新价": 2.98,
        "涨跌额": 0.11,
        "涨跌幅": 3.83,
        "开盘价": 2.88,
        "最高价": 3.0,
        "最低价": 2.86,
        "昨收价": 2.87,
        "总市值": 65697381.0,
        "市盈率": -1.46,
        "成交量": 221666.0,
        "成交额": 654060.0,
        "振幅": 4.88,
        "换手率": 1.01,
        "symbol": "105.DCTH"
    },
    {
        "序号": 698,
        "name": "Wayfair Inc-A",
        "最新价": 55.1,
        "涨跌额": 2.03,
        "涨跌幅": 3.83,
        "开盘价": 52.31,
        "最高价": 55.7,
        "最低价": 51.89,
        "昨收价": 53.07,
        "总市值": 6498891381.0,
        "市盈率": -7.1,
        "成交量": 6041082.0,
        "成交额": 328509184.0,
        "振幅": 7.18,
        "换手率": 5.12,
        "symbol": "106.W"
    },
    {
        "序号": 699,
        "name": "Vital Farms Inc",
        "最新价": 15.21,
        "涨跌额": 0.56,
        "涨跌幅": 3.82,
        "开盘价": 14.99,
        "最高价": 15.25,
        "最低价": 14.78,
        "昨收价": 14.65,
        "总市值": 632770420.0,
        "市盈率": 31.29,
        "成交量": 381863.0,
        "成交额": 5770453.0,
        "振幅": 3.21,
        "换手率": 0.92,
        "symbol": "105.VITL"
    },
    {
        "序号": 700,
        "name": "Redwire Corp",
        "最新价": 2.99,
        "涨跌额": 0.11,
        "涨跌幅": 3.82,
        "开盘价": 2.88,
        "最高价": 3.03,
        "最低价": 2.81,
        "昨收价": 2.88,
        "总市值": 193751525.0,
        "市盈率": -4.32,
        "成交量": 236328.0,
        "成交额": 700892.0,
        "振幅": 7.64,
        "换手率": 0.36,
        "symbol": "106.RDW"
    },
    {
        "序号": 701,
        "name": "兰亭集势",
        "最新价": 1.09,
        "涨跌额": 0.04,
        "涨跌幅": 3.81,
        "开盘价": 1.1,
        "最高价": 1.1,
        "最低价": 1.03,
        "昨收价": 1.05,
        "总市值": 123535170.0,
        "市盈率": -2.31,
        "成交量": 64776.0,
        "成交额": 69266.0,
        "振幅": 6.67,
        "换手率": 0.06,
        "symbol": "106.LITB"
    },
    {
        "序号": 702,
        "name": "宝视来",
        "最新价": 1.09,
        "涨跌额": 0.04,
        "涨跌幅": 3.81,
        "开盘价": 1.06,
        "最高价": 1.11,
        "最低价": 1.05,
        "昨收价": 1.05,
        "总市值": 10475591.0,
        "市盈率": -0.45,
        "成交量": 84850.0,
        "成交额": 91114.0,
        "振幅": 5.71,
        "换手率": 0.88,
        "symbol": "105.BOXL"
    },
    {
        "序号": 703,
        "name": "SandRidge Energy Inc",
        "最新价": 13.98,
        "涨跌额": 0.51,
        "涨跌幅": 3.79,
        "开盘价": 13.58,
        "最高价": 14.0,
        "最低价": 13.55,
        "昨收价": 13.47,
        "总市值": 518535941.0,
        "市盈率": 3.16,
        "成交量": 479950.0,
        "成交额": 6673286.0,
        "振幅": 3.34,
        "换手率": 1.29,
        "symbol": "106.SD"
    },
    {
        "序号": 704,
        "name": "泰尼特保健",
        "最新价": 71.55,
        "涨跌额": 2.61,
        "涨跌幅": 3.79,
        "开盘价": 68.92,
        "最高价": 71.72,
        "最低价": 68.84,
        "昨收价": 68.94,
        "总市值": 7266045600.0,
        "市盈率": 15.49,
        "成交量": 1717047.0,
        "成交额": 121842476.0,
        "振幅": 4.18,
        "换手率": 1.69,
        "symbol": "106.THC"
    },
    {
        "序号": 705,
        "name": "特百惠",
        "最新价": 1.92,
        "涨跌额": 0.07,
        "涨跌幅": 3.78,
        "开盘价": 1.82,
        "最高价": 1.95,
        "最低价": 1.82,
        "昨收价": 1.85,
        "总市值": 88837094.0,
        "市盈率": -0.38,
        "成交量": 741189.0,
        "成交额": 1417869.0,
        "振幅": 7.03,
        "换手率": 1.6,
        "symbol": "106.TUP"
    },
    {
        "序号": 706,
        "name": "Birks Group Inc-A",
        "最新价": 3.84,
        "涨跌额": 0.14,
        "涨跌幅": 3.78,
        "开盘价": 3.7,
        "最高价": 3.84,
        "最低价": 3.7,
        "昨收价": 3.7,
        "总市值": 71926921.0,
        "市盈率": -14.3,
        "成交量": 5328.0,
        "成交额": 19993.0,
        "振幅": 3.78,
        "换手率": 0.03,
        "symbol": "107.BGI"
    },
    {
        "序号": 707,
        "name": "Draganfly Inc",
        "最新价": 0.55,
        "涨跌额": 0.02,
        "涨跌幅": 3.77,
        "开盘价": 0.52,
        "最高价": 0.55,
        "最低价": 0.52,
        "昨收价": 0.53,
        "总市值": 24117634.0,
        "市盈率": -0.9,
        "成交量": 159209.0,
        "成交额": 84462.0,
        "振幅": 5.66,
        "换手率": 0.36,
        "symbol": "105.DPRO"
    },
    {
        "序号": 708,
        "name": "Aadi Bioscience Inc",
        "最新价": 4.96,
        "涨跌额": 0.18,
        "涨跌幅": 3.77,
        "开盘价": 4.75,
        "最高价": 5.14,
        "最低价": 4.7,
        "昨收价": 4.78,
        "总市值": 121648266.0,
        "市盈率": -1.92,
        "成交量": 218605.0,
        "成交额": 1065943.0,
        "振幅": 9.21,
        "换手率": 0.89,
        "symbol": "105.AADI"
    },
    {
        "序号": 709,
        "name": "AdaptHealth Corp",
        "最新价": 8.28,
        "涨跌额": 0.3,
        "涨跌幅": 3.76,
        "开盘价": 8.17,
        "最高价": 8.31,
        "最低价": 7.94,
        "昨收价": 7.98,
        "总市值": 1129306343.0,
        "市盈率": -2.64,
        "成交量": 772233.0,
        "成交额": 6324045.0,
        "振幅": 4.64,
        "换手率": 0.57,
        "symbol": "105.AHCO"
    },
    {
        "序号": 710,
        "name": "Amplify Transformational Data S",
        "最新价": 27.62,
        "涨跌额": 1.0,
        "涨跌幅": 3.76,
        "开盘价": 26.59,
        "最高价": 27.67,
        "最低价": 26.58,
        "昨收价": 26.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 255426.0,
        "成交额": 6989554.0,
        "振幅": 4.09,
        "换手率": null,
        "symbol": "107.BLOK"
    },
    {
        "序号": 711,
        "name": "Alteryx Inc-A",
        "最新价": 43.42,
        "涨跌额": 1.57,
        "涨跌幅": 3.75,
        "开盘价": 41.43,
        "最高价": 43.58,
        "最低价": 41.33,
        "昨收价": 41.85,
        "总市值": 3116722727.0,
        "市盈率": -10.73,
        "成交量": 2618472.0,
        "成交额": 112985747.0,
        "振幅": 5.38,
        "换手率": 3.65,
        "symbol": "106.AYX"
    },
    {
        "序号": 712,
        "name": "Northern Oil and Gas Inc",
        "最新价": 36.79,
        "涨跌额": 1.33,
        "涨跌幅": 3.75,
        "开盘价": 35.79,
        "最高价": 36.83,
        "最低价": 35.67,
        "昨收价": 35.46,
        "总市值": 3697676002.0,
        "市盈率": 5.44,
        "成交量": 1245036.0,
        "成交额": 45530959.0,
        "振幅": 3.27,
        "换手率": 1.24,
        "symbol": "106.NOG"
    },
    {
        "序号": 713,
        "name": "二倍做空黄金ETN-DB",
        "最新价": 2.49,
        "涨跌额": 0.09,
        "涨跌幅": 3.75,
        "开盘价": 2.48,
        "最高价": 2.49,
        "最低价": 2.4,
        "昨收价": 2.4,
        "总市值": 5003157000.0,
        "市盈率": 0.83,
        "成交量": 9844.0,
        "成交额": 24188.0,
        "振幅": 3.75,
        "换手率": null,
        "symbol": "107.DZZ"
    },
    {
        "序号": 714,
        "name": "T-Rex 2X Long NVIDIA Daily Targ",
        "最新价": 30.73,
        "涨跌额": 1.11,
        "涨跌幅": 3.75,
        "开盘价": 29.73,
        "最高价": 31.05,
        "最低价": 29.62,
        "昨收价": 29.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 108494.0,
        "成交额": 3312570.0,
        "振幅": 4.83,
        "换手率": null,
        "symbol": "107.NVDX"
    },
    {
        "序号": 715,
        "name": "Chicago Rivet & Machine Co",
        "最新价": 16.71,
        "涨跌额": 0.6,
        "涨跌幅": 3.72,
        "开盘价": 16.71,
        "最高价": 16.71,
        "最低价": 16.71,
        "昨收价": 16.11,
        "总市值": 16144066.0,
        "市盈率": -3.87,
        "成交量": 283.0,
        "成交额": 4728.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "107.CVR"
    },
    {
        "序号": 716,
        "name": "AVITA Medical Inc",
        "最新价": 13.65,
        "涨跌额": 0.49,
        "涨跌幅": 3.72,
        "开盘价": 13.11,
        "最高价": 13.74,
        "最低价": 12.82,
        "昨收价": 13.16,
        "总市值": 348766973.0,
        "市盈率": -10.36,
        "成交量": 107613.0,
        "成交额": 1452981.0,
        "振幅": 6.99,
        "换手率": 0.42,
        "symbol": "105.RCEL"
    },
    {
        "序号": 717,
        "name": "保证担保",
        "最新价": 70.8,
        "涨跌额": 2.54,
        "涨跌幅": 3.72,
        "开盘价": 70.0,
        "最高价": 71.47,
        "最低价": 68.91,
        "昨收价": 68.26,
        "总市值": 4053765368.0,
        "市盈率": 8.87,
        "成交量": 726390.0,
        "成交额": 51210770.0,
        "振幅": 3.75,
        "换手率": 1.27,
        "symbol": "106.AGO"
    },
    {
        "序号": 718,
        "name": "Daxor Corp",
        "最新价": 8.09,
        "涨跌额": 0.29,
        "涨跌幅": 3.72,
        "开盘价": 7.7,
        "最高价": 8.09,
        "最低价": 7.7,
        "昨收价": 7.8,
        "总市值": 38306045.0,
        "市盈率": -5.33,
        "成交量": 2063.0,
        "成交额": 16447.0,
        "振幅": 5.0,
        "换手率": 0.04,
        "symbol": "105.DXR"
    },
    {
        "序号": 719,
        "name": "佩尔马福克斯环境服务",
        "最新价": 8.1,
        "涨跌额": 0.29,
        "涨跌幅": 3.71,
        "开盘价": 7.92,
        "最高价": 8.31,
        "最低价": 7.69,
        "昨收价": 7.81,
        "总市值": 110268005.0,
        "市盈率": -85.61,
        "成交量": 23454.0,
        "成交额": 187099.0,
        "振幅": 7.94,
        "换手率": 0.17,
        "symbol": "105.PESI"
    },
    {
        "序号": 720,
        "name": "IonQ Inc",
        "最新价": 13.69,
        "涨跌额": 0.49,
        "涨跌幅": 3.71,
        "开盘价": 13.06,
        "最高价": 13.79,
        "最低价": 12.98,
        "昨收价": 13.2,
        "总市值": 2802406138.0,
        "市盈率": -20.83,
        "成交量": 8181388.0,
        "成交额": 110412054.0,
        "振幅": 6.14,
        "换手率": 4.0,
        "symbol": "106.IONQ"
    },
    {
        "序号": 721,
        "name": "Society Pass Inc",
        "最新价": 0.28,
        "涨跌额": 0.01,
        "涨跌幅": 3.7,
        "开盘价": 0.27,
        "最高价": 0.29,
        "最低价": 0.26,
        "昨收价": 0.27,
        "总市值": 9200686.0,
        "市盈率": -0.41,
        "成交量": 228227.0,
        "成交额": 64267.0,
        "振幅": 11.11,
        "换手率": 0.69,
        "symbol": "105.SOPA"
    },
    {
        "序号": 722,
        "name": "Smith Micro Software Inc",
        "最新价": 0.84,
        "涨跌额": 0.03,
        "涨跌幅": 3.7,
        "开盘价": 0.82,
        "最高价": 0.84,
        "最低价": 0.75,
        "昨收价": 0.81,
        "总市值": 60417719.0,
        "市盈率": -2.35,
        "成交量": 353332.0,
        "成交额": 283878.0,
        "振幅": 11.11,
        "换手率": 0.49,
        "symbol": "105.SMSI"
    },
    {
        "序号": 723,
        "name": "Sunshine Biopharma Inc",
        "最新价": 0.28,
        "涨跌额": 0.01,
        "涨跌幅": 3.7,
        "开盘价": 0.27,
        "最高价": 0.29,
        "最低价": 0.25,
        "昨收价": 0.27,
        "总市值": 7189921.0,
        "市盈率": -0.27,
        "成交量": 109760.0,
        "成交额": 29858.0,
        "振幅": 14.81,
        "换手率": 0.43,
        "symbol": "105.SBFM"
    },
    {
        "序号": 724,
        "name": "Prelude Therapeutics Inc",
        "最新价": 3.36,
        "涨跌额": 0.12,
        "涨跌幅": 3.7,
        "开盘价": 3.24,
        "最高价": 3.49,
        "最低价": 3.24,
        "昨收价": 3.24,
        "总市值": 184215300.0,
        "市盈率": -1.57,
        "成交量": 129764.0,
        "成交额": 438666.0,
        "振幅": 7.72,
        "换手率": 0.24,
        "symbol": "105.PRLD"
    },
    {
        "序号": 725,
        "name": "Compugen医疗",
        "最新价": 0.84,
        "涨跌额": 0.03,
        "涨跌幅": 3.7,
        "开盘价": 0.9,
        "最高价": 0.9,
        "最低价": 0.8,
        "昨收价": 0.81,
        "总市值": 74194854.0,
        "市盈率": -2.35,
        "成交量": 337771.0,
        "成交额": 296432.0,
        "振幅": 12.35,
        "换手率": 0.38,
        "symbol": "105.CGEN"
    },
    {
        "序号": 726,
        "name": "Babcock & Wilcox",
        "最新价": 1.68,
        "涨跌额": 0.06,
        "涨跌幅": 3.7,
        "开盘价": 1.6,
        "最高价": 1.7,
        "最低价": 1.59,
        "昨收价": 1.62,
        "总市值": 150143965.0,
        "市盈率": -1.17,
        "成交量": 601038.0,
        "成交额": 998413.0,
        "振幅": 6.79,
        "换手率": 0.67,
        "symbol": "106.BW"
    },
    {
        "序号": 727,
        "name": "Backblaze Inc-A",
        "最新价": 7.84,
        "涨跌额": 0.28,
        "涨跌幅": 3.7,
        "开盘价": 7.55,
        "最高价": 7.84,
        "最低价": 7.48,
        "昨收价": 7.56,
        "总市值": 294784000.0,
        "市盈率": -4.75,
        "成交量": 158999.0,
        "成交额": 1222404.0,
        "振幅": 4.76,
        "换手率": 0.42,
        "symbol": "105.BLZE"
    },
    {
        "序号": 728,
        "name": "SI-BONE Inc",
        "最新价": 19.34,
        "涨跌额": 0.69,
        "涨跌幅": 3.7,
        "开盘价": 18.6,
        "最高价": 19.61,
        "最低价": 17.51,
        "昨收价": 18.65,
        "总市值": 783289785.0,
        "市盈率": -18.0,
        "成交量": 467103.0,
        "成交额": 9013689.0,
        "振幅": 11.26,
        "换手率": 1.15,
        "symbol": "105.SIBN"
    },
    {
        "序号": 729,
        "name": "Stoke Therapeutics Inc",
        "最新价": 4.77,
        "涨跌额": 0.17,
        "涨跌幅": 3.7,
        "开盘价": 4.56,
        "最高价": 5.05,
        "最低价": 4.56,
        "昨收价": 4.6,
        "总市值": 212998540.0,
        "市盈率": -2.06,
        "成交量": 663432.0,
        "成交额": 3215903.0,
        "振幅": 10.65,
        "换手率": 1.49,
        "symbol": "105.STOK"
    },
    {
        "序号": 730,
        "name": "学贷美",
        "最新价": 16.86,
        "涨跌额": 0.6,
        "涨跌幅": 3.69,
        "开盘价": 16.53,
        "最高价": 17.15,
        "最低价": 16.39,
        "昨收价": 16.26,
        "总市值": 3814930021.0,
        "市盈率": 11.36,
        "成交量": 3611109.0,
        "成交额": 60904546.0,
        "振幅": 4.67,
        "换手率": 1.6,
        "symbol": "105.SLM"
    },
    {
        "序号": 731,
        "name": "Traeger Inc",
        "最新价": 2.25,
        "涨跌额": 0.08,
        "涨跌幅": 3.69,
        "开盘价": 2.23,
        "最高价": 2.27,
        "最低价": 2.16,
        "昨收价": 2.17,
        "总市值": 283070891.0,
        "市盈率": -3.17,
        "成交量": 490823.0,
        "成交额": 1088888.0,
        "振幅": 5.07,
        "换手率": 0.39,
        "symbol": "106.COOK"
    },
    {
        "序号": 732,
        "name": "华赢控股",
        "最新价": 3.38,
        "涨跌额": 0.12,
        "涨跌幅": 3.68,
        "开盘价": 3.16,
        "最高价": 3.42,
        "最低价": 3.16,
        "昨收价": 3.26,
        "总市值": 47320000.0,
        "市盈率": 35.08,
        "成交量": 195865.0,
        "成交额": 652489.0,
        "振幅": 7.98,
        "换手率": 1.4,
        "symbol": "105.SWIN"
    },
    {
        "序号": 733,
        "name": "环球验收",
        "最新价": 124.07,
        "涨跌额": 4.4,
        "涨跌幅": 3.68,
        "开盘价": 119.24,
        "最高价": 124.26,
        "最低价": 119.24,
        "昨收价": 119.67,
        "总市值": 772314782.0,
        "市盈率": 13.78,
        "成交量": 35617.0,
        "成交额": 4346254.0,
        "振幅": 4.19,
        "换手率": 0.57,
        "symbol": "105.WRLD"
    },
    {
        "序号": 734,
        "name": "二倍做多原油ETF-ProShares",
        "最新价": 26.27,
        "涨跌额": 0.93,
        "涨跌幅": 3.67,
        "开盘价": 26.03,
        "最高价": 26.51,
        "最低价": 25.84,
        "昨收价": 25.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 2902113.0,
        "成交额": 76061274.0,
        "振幅": 2.64,
        "换手率": null,
        "symbol": "107.UCO"
    },
    {
        "序号": 735,
        "name": "Enerflex Ltd",
        "最新价": 4.24,
        "涨跌额": 0.15,
        "涨跌幅": 3.67,
        "开盘价": 4.08,
        "最高价": 4.24,
        "最低价": 4.01,
        "昨收价": 4.09,
        "总市值": 525454707.0,
        "市盈率": -11.02,
        "成交量": 25001.0,
        "成交额": 103644.0,
        "振幅": 5.62,
        "换手率": 0.02,
        "symbol": "106.EFXT"
    },
    {
        "序号": 736,
        "name": "莎莉美容",
        "最新价": 11.05,
        "涨跌额": 0.39,
        "涨跌幅": 3.66,
        "开盘价": 10.64,
        "最高价": 11.13,
        "最低价": 10.62,
        "昨收价": 10.66,
        "总市值": 1174439183.0,
        "市盈率": 6.36,
        "成交量": 1914148.0,
        "成交额": 21045581.0,
        "振幅": 4.78,
        "换手率": 1.8,
        "symbol": "106.SBH"
    },
    {
        "序号": 737,
        "name": "Avadel Pharmaceuticals plc ADR",
        "最新价": 14.19,
        "涨跌额": 0.5,
        "涨跌幅": 3.65,
        "开盘价": 13.69,
        "最高价": 14.31,
        "最低价": 13.56,
        "昨收价": 13.69,
        "总市值": 1274342216.0,
        "市盈率": -8.02,
        "成交量": 1077378.0,
        "成交额": 14988283.0,
        "振幅": 5.48,
        "换手率": 1.2,
        "symbol": "105.AVDL"
    },
    {
        "序号": 738,
        "name": "Qorvo Inc",
        "最新价": 103.97,
        "涨跌额": 3.65,
        "涨跌幅": 3.64,
        "开盘价": 101.9,
        "最高价": 104.84,
        "最低价": 101.88,
        "昨收价": 100.32,
        "总市值": 10121038147.0,
        "市盈率": -100.74,
        "成交量": 1696066.0,
        "成交额": 176258214.0,
        "振幅": 2.95,
        "换手率": 1.74,
        "symbol": "105.QRVO"
    },
    {
        "序号": 739,
        "name": "Cerus Corp",
        "最新价": 1.71,
        "涨跌额": 0.06,
        "涨跌幅": 3.64,
        "开盘价": 1.64,
        "最高价": 1.71,
        "最低价": 1.59,
        "昨收价": 1.65,
        "总市值": 309846870.0,
        "市盈率": -6.22,
        "成交量": 1194083.0,
        "成交额": 2008897.0,
        "振幅": 7.27,
        "换手率": 0.66,
        "symbol": "105.CERS"
    },
    {
        "序号": 740,
        "name": "Codexis Inc",
        "最新价": 2.28,
        "涨跌额": 0.08,
        "涨跌幅": 3.64,
        "开盘价": 2.22,
        "最高价": 2.36,
        "最低价": 2.18,
        "昨收价": 2.2,
        "总市值": 159212854.0,
        "市盈率": -1.95,
        "成交量": 481076.0,
        "成交额": 1101187.0,
        "振幅": 8.18,
        "换手率": 0.69,
        "symbol": "105.CDXS"
    },
    {
        "序号": 741,
        "name": "Brera Holdings PLC-B",
        "最新价": 0.57,
        "涨跌额": 0.02,
        "涨跌幅": 3.64,
        "开盘价": 0.55,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.55,
        "总市值": 6500850.0,
        "市盈率": -4.97,
        "成交量": 2349.0,
        "成交额": 1380.0,
        "振幅": 9.09,
        "换手率": 0.02,
        "symbol": "105.BREA"
    },
    {
        "序号": 742,
        "name": "Kinnate Biopharma Inc",
        "最新价": 2.29,
        "涨跌额": 0.08,
        "涨跌幅": 3.62,
        "开盘价": 2.23,
        "最高价": 2.3,
        "最低价": 2.22,
        "昨收价": 2.21,
        "总市值": 107888078.0,
        "市盈率": -0.85,
        "成交量": 357472.0,
        "成交额": 808746.0,
        "振幅": 3.62,
        "换手率": 0.76,
        "symbol": "105.KNTE"
    },
    {
        "序号": 743,
        "name": "RENN Fund",
        "最新价": 1.72,
        "涨跌额": 0.06,
        "涨跌幅": 3.61,
        "开盘价": 1.7,
        "最高价": 1.72,
        "最低价": 1.7,
        "昨收价": 1.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 2324.0,
        "成交额": 3965.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "107.RCG"
    },
    {
        "序号": 744,
        "name": "慧俪轻体",
        "最新价": 7.17,
        "涨跌额": 0.25,
        "涨跌幅": 3.61,
        "开盘价": 6.84,
        "最高价": 7.17,
        "最低价": 6.8,
        "昨收价": 6.92,
        "总市值": 567010892.0,
        "市盈率": -10.42,
        "成交量": 3848026.0,
        "成交额": 27139716.0,
        "振幅": 5.35,
        "换手率": 4.87,
        "symbol": "105.WW"
    },
    {
        "序号": 745,
        "name": "Poseida Therapeutics Inc",
        "最新价": 3.16,
        "涨跌额": 0.11,
        "涨跌幅": 3.61,
        "开盘价": 3.09,
        "最高价": 3.25,
        "最低价": 3.0,
        "昨收价": 3.05,
        "总市值": 302173227.0,
        "市盈率": -2.3,
        "成交量": 438621.0,
        "成交额": 1381880.0,
        "振幅": 8.2,
        "换手率": 0.46,
        "symbol": "105.PSTX"
    },
    {
        "序号": 746,
        "name": "Avinger Inc",
        "最新价": 3.16,
        "涨跌额": 0.11,
        "涨跌幅": 3.61,
        "开盘价": 3.0,
        "最高价": 3.19,
        "最低价": 2.99,
        "昨收价": 3.05,
        "总市值": 4329573.0,
        "市盈率": -0.25,
        "成交量": 3185.0,
        "成交额": 9831.0,
        "振幅": 6.56,
        "换手率": 0.23,
        "symbol": "105.AVGR"
    },
    {
        "序号": 747,
        "name": "喀里多尼亚矿业(US)",
        "最新价": 12.66,
        "涨跌额": 0.44,
        "涨跌幅": 3.6,
        "开盘价": 12.0,
        "最高价": 12.86,
        "最低价": 12.0,
        "昨收价": 12.22,
        "总市值": 242921004.0,
        "市盈率": -26.8,
        "成交量": 47019.0,
        "成交额": 581545.0,
        "振幅": 7.04,
        "换手率": 0.25,
        "symbol": "107.CMCL"
    },
    {
        "序号": 748,
        "name": "LPL Financial Holdings Inc",
        "最新价": 224.11,
        "涨跌额": 7.77,
        "涨跌幅": 3.59,
        "开盘价": 217.29,
        "最高价": 225.17,
        "最低价": 216.07,
        "昨收价": 216.34,
        "总市值": 16947322133.0,
        "市盈率": 14.51,
        "成交量": 794470.0,
        "成交额": 176218076.0,
        "振幅": 4.21,
        "换手率": 1.05,
        "symbol": "105.LPLA"
    },
    {
        "序号": 749,
        "name": "三倍做空FTSE中国ETF-Direxion",
        "最新价": 14.72,
        "涨跌额": 0.51,
        "涨跌幅": 3.59,
        "开盘价": 14.6,
        "最高价": 14.85,
        "最低价": 14.48,
        "昨收价": 14.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 2865782.0,
        "成交额": 42016518.0,
        "振幅": 2.6,
        "换手率": null,
        "symbol": "107.YANG"
    },
    {
        "序号": 750,
        "name": "Grid Dynamics Holdings Inc",
        "最新价": 12.99,
        "涨跌额": 0.45,
        "涨跌幅": 3.59,
        "开盘价": 12.49,
        "最高价": 13.16,
        "最低价": 12.49,
        "昨收价": 12.54,
        "总市值": 981913489.0,
        "市盈率": -86.37,
        "成交量": 324314.0,
        "成交额": 4207018.0,
        "振幅": 5.34,
        "换手率": 0.43,
        "symbol": "105.GDYN"
    },
    {
        "序号": 751,
        "name": "芯科实验室",
        "最新价": 121.89,
        "涨跌额": 4.22,
        "涨跌幅": 3.59,
        "开盘价": 118.83,
        "最高价": 123.47,
        "最低价": 118.77,
        "昨收价": 117.67,
        "总市值": 3873583143.0,
        "市盈率": 63.89,
        "成交量": 1311481.0,
        "成交额": 159916375.0,
        "振幅": 3.99,
        "换手率": 4.13,
        "symbol": "105.SLAB"
    },
    {
        "序号": 752,
        "name": "Exela Technologies Inc",
        "最新价": 3.47,
        "涨跌额": 0.12,
        "涨跌幅": 3.58,
        "开盘价": 3.31,
        "最高价": 3.63,
        "最低价": 3.31,
        "昨收价": 3.35,
        "总市值": 22087775.0,
        "市盈率": -0.06,
        "成交量": 79715.0,
        "成交额": 274296.0,
        "振幅": 9.55,
        "换手率": 1.25,
        "symbol": "105.XELA"
    },
    {
        "序号": 753,
        "name": "Molecular Templates Inc",
        "最新价": 4.92,
        "涨跌额": 0.17,
        "涨跌幅": 3.58,
        "开盘价": 4.76,
        "最高价": 5.2,
        "最低价": 4.5,
        "昨收价": 4.75,
        "总市值": 26441399.0,
        "市盈率": -1.01,
        "成交量": 13003.0,
        "成交额": 61048.0,
        "振幅": 14.74,
        "换手率": 0.24,
        "symbol": "105.MTEM"
    },
    {
        "序号": 754,
        "name": "Intrusion Inc",
        "最新价": 0.29,
        "涨跌额": 0.01,
        "涨跌幅": 3.57,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.28,
        "昨收价": 0.28,
        "总市值": 9976405.0,
        "市盈率": -0.61,
        "成交量": 285131.0,
        "成交额": 81463.0,
        "振幅": 3.57,
        "换手率": 0.83,
        "symbol": "105.INTZ"
    },
    {
        "序号": 755,
        "name": "Nano-X Imaging Ltd",
        "最新价": 6.1,
        "涨跌额": 0.21,
        "涨跌幅": 3.57,
        "开盘价": 5.91,
        "最高价": 6.32,
        "最低价": 5.86,
        "昨收价": 5.89,
        "总市值": 352167793.0,
        "市盈率": -3.41,
        "成交量": 601996.0,
        "成交额": 3687970.0,
        "振幅": 7.81,
        "换手率": 1.04,
        "symbol": "105.NNOX"
    },
    {
        "序号": 756,
        "name": "Greenidge Generation Holdings I",
        "最新价": 8.75,
        "涨跌额": 0.3,
        "涨跌幅": 3.55,
        "开盘价": 8.7,
        "最高价": 9.12,
        "最低价": 8.7,
        "昨收价": 8.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 11980.0,
        "成交额": 107271.0,
        "振幅": 4.97,
        "换手率": null,
        "symbol": "105.GREEL"
    },
    {
        "序号": 757,
        "name": "Direxion Daily Homebuilders & S",
        "最新价": 86.96,
        "涨跌额": 2.98,
        "涨跌幅": 3.55,
        "开盘价": 83.9,
        "最高价": 87.54,
        "最低价": 83.01,
        "昨收价": 83.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 323388.0,
        "成交额": 27752210.0,
        "振幅": 5.39,
        "换手率": null,
        "symbol": "107.NAIL"
    },
    {
        "序号": 758,
        "name": "洲际酒店(US)",
        "最新价": 87.26,
        "涨跌额": 2.99,
        "涨跌幅": 3.55,
        "开盘价": 85.9,
        "最高价": 87.4,
        "最低价": 85.75,
        "昨收价": 84.27,
        "总市值": 14376134040.0,
        "市盈率": 23.26,
        "成交量": 267173.0,
        "成交额": 23117963.0,
        "振幅": 1.96,
        "换手率": 0.16,
        "symbol": "106.IHG"
    },
    {
        "序号": 759,
        "name": "NL Industries Inc",
        "最新价": 5.26,
        "涨跌额": 0.18,
        "涨跌幅": 3.54,
        "开盘价": 5.04,
        "最高价": 5.27,
        "最低价": 5.04,
        "昨收价": 5.08,
        "总市值": 256864126.0,
        "市盈率": -16.1,
        "成交量": 13410.0,
        "成交额": 69679.0,
        "振幅": 4.53,
        "换手率": 0.03,
        "symbol": "106.NL"
    },
    {
        "序号": 760,
        "name": "越洋钻探",
        "最新价": 5.85,
        "涨跌额": 0.2,
        "涨跌幅": 3.54,
        "开盘价": 5.73,
        "最高价": 5.93,
        "最低价": 5.71,
        "昨收价": 5.65,
        "总市值": 4732830449.0,
        "市盈率": -3.94,
        "成交量": 15706546.0,
        "成交额": 91873619.0,
        "振幅": 3.89,
        "换手率": 1.94,
        "symbol": "106.RIG"
    },
    {
        "序号": 761,
        "name": "兰德资本",
        "最新价": 14.07,
        "涨跌额": 0.48,
        "涨跌幅": 3.53,
        "开盘价": 14.13,
        "最高价": 14.13,
        "最低价": 13.85,
        "昨收价": 13.59,
        "总市值": 36314965.0,
        "市盈率": 6.31,
        "成交量": 2096.0,
        "成交额": 29507.0,
        "振幅": 2.06,
        "换手率": 0.08,
        "symbol": "105.RAND"
    },
    {
        "序号": 762,
        "name": "Team Inc",
        "最新价": 7.34,
        "涨跌额": 0.25,
        "涨跌幅": 3.53,
        "开盘价": 7.17,
        "最高价": 7.35,
        "最低价": 7.16,
        "昨收价": 7.09,
        "总市值": 32064217.0,
        "市盈率": 0.34,
        "成交量": 6912.0,
        "成交额": 50361.0,
        "振幅": 2.68,
        "换手率": 0.16,
        "symbol": "106.TISI"
    },
    {
        "序号": 763,
        "name": "NerdWallet Inc-A",
        "最新价": 12.92,
        "涨跌额": 0.44,
        "涨跌幅": 3.53,
        "开盘价": 12.34,
        "最高价": 12.92,
        "最低价": 12.34,
        "昨收价": 12.48,
        "总市值": 983537455.0,
        "市盈率": -1639.23,
        "成交量": 356741.0,
        "成交额": 4551729.0,
        "振幅": 4.65,
        "换手率": 0.47,
        "symbol": "105.NRDS"
    },
    {
        "序号": 764,
        "name": "Rail Vision Ltd",
        "最新价": 1.47,
        "涨跌额": 0.05,
        "涨跌幅": 3.52,
        "开盘价": 1.37,
        "最高价": 1.5,
        "最低价": 1.36,
        "昨收价": 1.42,
        "总市值": 4407469.0,
        "市盈率": -0.4,
        "成交量": 29460.0,
        "成交额": 42467.0,
        "振幅": 9.86,
        "换手率": 0.98,
        "symbol": "105.RVSN"
    },
    {
        "序号": 765,
        "name": "泛华控股集团",
        "最新价": 7.36,
        "涨跌额": 0.25,
        "涨跌幅": 3.52,
        "开盘价": 6.99,
        "最高价": 7.48,
        "最低价": 6.91,
        "昨收价": 7.11,
        "总市值": 425946724.0,
        "市盈率": 8.03,
        "成交量": 26588.0,
        "成交额": 192654.0,
        "振幅": 8.02,
        "换手率": 0.05,
        "symbol": "105.FANH"
    },
    {
        "序号": 766,
        "name": "Oil States International Inc",
        "最新价": 6.78,
        "涨跌额": 0.23,
        "涨跌幅": 3.51,
        "开盘价": 6.63,
        "最高价": 6.78,
        "最低价": 6.57,
        "昨收价": 6.55,
        "总市值": 433168613.0,
        "市盈率": 44.14,
        "成交量": 1121335.0,
        "成交额": 7498619.0,
        "振幅": 3.21,
        "换手率": 1.76,
        "symbol": "106.OIS"
    },
    {
        "序号": 767,
        "name": "Dragonfly Energy Holdings Corp",
        "最新价": 0.59,
        "涨跌额": 0.02,
        "涨跌幅": 3.51,
        "开盘价": 0.57,
        "最高价": 0.6,
        "最低价": 0.56,
        "昨收价": 0.57,
        "总市值": 35134979.0,
        "市盈率": -0.72,
        "成交量": 356929.0,
        "成交额": 208050.0,
        "振幅": 7.02,
        "换手率": 0.6,
        "symbol": "105.DFLI"
    },
    {
        "序号": 768,
        "name": "Seabridge Gold Inc",
        "最新价": 11.53,
        "涨跌额": 0.39,
        "涨跌幅": 3.5,
        "开盘价": 11.0,
        "最高价": 11.54,
        "最低价": 10.89,
        "昨收价": 11.14,
        "总市值": 971204784.0,
        "市盈率": -40.74,
        "成交量": 1043244.0,
        "成交额": 11794287.0,
        "振幅": 5.83,
        "换手率": 1.24,
        "symbol": "106.SA"
    },
    {
        "序号": 769,
        "name": "Paysafe Ltd-A",
        "最新价": 10.69,
        "涨跌额": 0.36,
        "涨跌幅": 3.48,
        "开盘价": 10.51,
        "最高价": 10.77,
        "最低价": 10.34,
        "昨收价": 10.33,
        "总市值": 659066647.0,
        "市盈率": -15.75,
        "成交量": 564089.0,
        "成交额": 6002430.0,
        "振幅": 4.16,
        "换手率": 0.91,
        "symbol": "106.PSFE"
    },
    {
        "序号": 770,
        "name": "Savara Inc",
        "最新价": 4.16,
        "涨跌额": 0.14,
        "涨跌幅": 3.48,
        "开盘价": 4.02,
        "最高价": 4.27,
        "最低价": 3.98,
        "昨收价": 4.02,
        "总市值": 563018660.0,
        "市盈率": -11.51,
        "成交量": 1813491.0,
        "成交额": 7522533.0,
        "振幅": 7.21,
        "换手率": 1.34,
        "symbol": "105.SVRA"
    },
    {
        "序号": 771,
        "name": "Dave & Buster's Entertainment I",
        "最新价": 47.29,
        "涨跌额": 1.59,
        "涨跌幅": 3.48,
        "开盘价": 45.72,
        "最高价": 47.45,
        "最低价": 45.0,
        "昨收价": 45.7,
        "总市值": 1900543863.0,
        "市盈率": 14.63,
        "成交量": 1309392.0,
        "成交额": 61410687.0,
        "振幅": 5.36,
        "换手率": 3.26,
        "symbol": "105.PLAY"
    },
    {
        "序号": 772,
        "name": "蓝帽子",
        "最新价": 1.19,
        "涨跌额": 0.04,
        "涨跌幅": 3.48,
        "开盘价": 1.14,
        "最高价": 1.25,
        "最低价": 1.14,
        "昨收价": 1.15,
        "总市值": 67594473.0,
        "市盈率": -7.22,
        "成交量": 62398.0,
        "成交额": 74740.0,
        "振幅": 9.57,
        "换手率": 0.11,
        "symbol": "105.BHAT"
    },
    {
        "序号": 773,
        "name": "Vimeo Inc",
        "最新价": 3.87,
        "涨跌额": 0.13,
        "涨跌幅": 3.48,
        "开盘价": 3.72,
        "最高价": 3.88,
        "最低价": 3.72,
        "昨收价": 3.74,
        "总市值": 648685008.0,
        "市盈率": 76.12,
        "成交量": 1185804.0,
        "成交额": 4547429.0,
        "振幅": 4.28,
        "换手率": 0.71,
        "symbol": "105.VMEO"
    },
    {
        "序号": 774,
        "name": "Lumen Technologies Inc",
        "最新价": 1.49,
        "涨跌额": 0.05,
        "涨跌幅": 3.47,
        "开盘价": 1.44,
        "最高价": 1.54,
        "最低价": 1.43,
        "昨收价": 1.44,
        "总市值": 1503258828.0,
        "市盈率": -0.13,
        "成交量": 8348714.0,
        "成交额": 12436046.0,
        "振幅": 7.64,
        "换手率": 0.83,
        "symbol": "106.LUMN"
    },
    {
        "序号": 775,
        "name": "Crown Crafts制衣",
        "最新价": 5.08,
        "涨跌额": 0.17,
        "涨跌幅": 3.46,
        "开盘价": 4.84,
        "最高价": 5.16,
        "最低价": 4.84,
        "昨收价": 4.91,
        "总市值": 52022853.0,
        "市盈率": 11.92,
        "成交量": 30986.0,
        "成交额": 157033.0,
        "振幅": 6.52,
        "换手率": 0.3,
        "symbol": "105.CRWS"
    },
    {
        "序号": 776,
        "name": "Guild Holdings Co-A",
        "最新价": 12.87,
        "涨跌额": 0.43,
        "涨跌幅": 3.46,
        "开盘价": 12.5,
        "最高价": 12.89,
        "最低价": 12.5,
        "昨收价": 12.44,
        "总市值": 786316292.0,
        "市盈率": 20.17,
        "成交量": 12022.0,
        "成交额": 153027.0,
        "振幅": 3.14,
        "换手率": 0.02,
        "symbol": "106.GHLD"
    },
    {
        "序号": 777,
        "name": "Cadiz Inc Series A Pfd",
        "最新价": 14.38,
        "涨跌额": 0.48,
        "涨跌幅": 3.45,
        "开盘价": 13.94,
        "最高价": 14.38,
        "最低价": 13.93,
        "昨收价": 13.9,
        "总市值": 33074.0,
        "市盈率": null,
        "成交量": 3934.0,
        "成交额": 55306.0,
        "振幅": 3.24,
        "换手率": 171.04,
        "symbol": "105.CDZIP"
    },
    {
        "序号": 778,
        "name": "Luxfer Holdings PLC",
        "最新价": 8.99,
        "涨跌额": 0.3,
        "涨跌幅": 3.45,
        "开盘价": 8.7,
        "最高价": 9.05,
        "最低价": 8.68,
        "昨收价": 8.69,
        "总市值": 241569984.0,
        "市盈率": 40.94,
        "成交量": 241688.0,
        "成交额": 2168248.0,
        "振幅": 4.26,
        "换手率": 0.9,
        "symbol": "106.LXFR"
    },
    {
        "序号": 779,
        "name": "Spruce Biosciences Inc",
        "最新价": 1.5,
        "涨跌额": 0.05,
        "涨跌幅": 3.45,
        "开盘价": 1.64,
        "最高价": 1.8,
        "最低价": 1.44,
        "昨收价": 1.45,
        "总市值": 61066038.0,
        "市盈率": -1.24,
        "成交量": 399761.0,
        "成交额": 642678.0,
        "振幅": 24.83,
        "换手率": 0.98,
        "symbol": "105.SPRB"
    },
    {
        "序号": 780,
        "name": "The OLB Group Inc",
        "最新价": 0.9,
        "涨跌额": 0.03,
        "涨跌幅": 3.45,
        "开盘价": 0.94,
        "最高价": 0.97,
        "最低价": 0.88,
        "昨收价": 0.87,
        "总市值": 13800669.0,
        "市盈率": -1.69,
        "成交量": 50278.0,
        "成交额": 47264.0,
        "振幅": 10.34,
        "换手率": 0.33,
        "symbol": "105.OLB"
    },
    {
        "序号": 781,
        "name": "橡木谷银行",
        "最新价": 27.93,
        "涨跌额": 0.93,
        "涨跌幅": 3.44,
        "开盘价": 27.34,
        "最高价": 28.04,
        "最低价": 27.34,
        "昨收价": 27.0,
        "总市值": 231628182.0,
        "市盈率": 6.72,
        "成交量": 6482.0,
        "成交额": 180712.0,
        "振幅": 2.59,
        "换手率": 0.08,
        "symbol": "105.OVLY"
    },
    {
        "序号": 782,
        "name": "Reality Shares Nasdaq NexGen Ec",
        "最新价": 22.88,
        "涨跌额": 0.76,
        "涨跌幅": 3.44,
        "开盘价": 22.36,
        "最高价": 22.88,
        "最低价": 22.36,
        "昨收价": 22.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 27135.0,
        "成交额": 615165.0,
        "振幅": 2.35,
        "换手率": null,
        "symbol": "105.BLCN"
    },
    {
        "序号": 783,
        "name": "洪恩",
        "最新价": 2.71,
        "涨跌额": 0.09,
        "涨跌幅": 3.44,
        "开盘价": 2.71,
        "最高价": 2.71,
        "最低价": 2.61,
        "昨收价": 2.62,
        "总市值": 143010712.0,
        "市盈率": 6.01,
        "成交量": 2207.0,
        "成交额": 5875.0,
        "振幅": 3.82,
        "换手率": 0.0,
        "symbol": "106.IH"
    },
    {
        "序号": 784,
        "name": "金沙集团",
        "最新价": 46.39,
        "涨跌额": 1.54,
        "涨跌幅": 3.43,
        "开盘价": 44.81,
        "最高价": 46.5,
        "最低价": 44.6,
        "昨收价": 44.85,
        "总市值": 35217079743.0,
        "市盈率": 52.56,
        "成交量": 5994374.0,
        "成交额": 275409952.0,
        "振幅": 4.24,
        "换手率": 0.79,
        "symbol": "106.LVS"
    },
    {
        "序号": 785,
        "name": "Oculis Holding AG",
        "最新价": 11.75,
        "涨跌额": 0.39,
        "涨跌幅": 3.43,
        "开盘价": 11.9,
        "最高价": 11.98,
        "最低价": 11.4,
        "昨收价": 11.36,
        "总市值": 430025995.0,
        "市盈率": -5.1,
        "成交量": 18847.0,
        "成交额": 222347.0,
        "振幅": 5.11,
        "换手率": 0.05,
        "symbol": "105.OCS"
    },
    {
        "序号": 786,
        "name": "Lithium Americas Corp",
        "最新价": 6.63,
        "涨跌额": 0.22,
        "涨跌幅": 3.43,
        "开盘价": 6.56,
        "最高价": 6.75,
        "最低价": 6.54,
        "昨收价": 6.41,
        "总市值": 1069489218.0,
        "市盈率": null,
        "成交量": 3714295.0,
        "成交额": 24663659.0,
        "振幅": 3.28,
        "换手率": 2.3,
        "symbol": "106.LAC"
    },
    {
        "序号": 787,
        "name": "Orthofix Medical Inc",
        "最新价": 11.76,
        "涨跌额": 0.39,
        "涨跌幅": 3.43,
        "开盘价": 11.37,
        "最高价": 11.89,
        "最低价": 11.21,
        "昨收价": 11.37,
        "总市值": 432257698.0,
        "市盈率": -3.17,
        "成交量": 755123.0,
        "成交额": 8825971.0,
        "振幅": 5.98,
        "换手率": 2.05,
        "symbol": "105.OFIX"
    },
    {
        "序号": 788,
        "name": "AMCON Distributing Co",
        "最新价": 190.0,
        "涨跌额": 6.3,
        "涨跌幅": 3.43,
        "开盘价": 186.91,
        "最高价": 194.41,
        "最低价": 185.37,
        "昨收价": 183.7,
        "总市值": 119768780.0,
        "市盈率": 10.33,
        "成交量": 372.0,
        "成交额": 71034.0,
        "振幅": 4.92,
        "换手率": 0.06,
        "symbol": "107.DIT"
    },
    {
        "序号": 789,
        "name": "SWK Holdings Corp",
        "最新价": 17.22,
        "涨跌额": 0.57,
        "涨跌幅": 3.42,
        "开盘价": 16.5,
        "最高价": 17.23,
        "最低价": 16.5,
        "昨收价": 16.65,
        "总市值": 215303606.0,
        "市盈率": 13.56,
        "成交量": 6823.0,
        "成交额": 116528.0,
        "振幅": 4.38,
        "换手率": 0.05,
        "symbol": "105.SWKH"
    },
    {
        "序号": 790,
        "name": "Magnite Inc",
        "最新价": 8.46,
        "涨跌额": 0.28,
        "涨跌幅": 3.42,
        "开盘价": 8.14,
        "最高价": 8.47,
        "最低价": 8.05,
        "昨收价": 8.18,
        "总市值": 1166219731.0,
        "市盈率": -5.15,
        "成交量": 733202.0,
        "成交额": 6143462.0,
        "振幅": 5.13,
        "换手率": 0.53,
        "symbol": "105.MGNI"
    },
    {
        "序号": 791,
        "name": "ATAI Life Sciences NV",
        "最新价": 1.21,
        "涨跌额": 0.04,
        "涨跌幅": 3.42,
        "开盘价": 1.17,
        "最高价": 1.22,
        "最低价": 1.16,
        "昨收价": 1.17,
        "总市值": 200872676.0,
        "市盈率": -3.0,
        "成交量": 882613.0,
        "成交额": 1051774.0,
        "振幅": 5.13,
        "换手率": 0.53,
        "symbol": "105.ATAI"
    },
    {
        "序号": 792,
        "name": "Cenovus Energy Inc Wt",
        "最新价": 11.52,
        "涨跌额": 0.38,
        "涨跌幅": 3.41,
        "开盘价": 11.3,
        "最高价": 11.52,
        "最低价": 11.29,
        "昨收价": 11.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5939.0,
        "成交额": 67147.0,
        "振幅": 2.06,
        "换手率": null,
        "symbol": "106.CVE_WS"
    },
    {
        "序号": 793,
        "name": "Waldencast plc-A",
        "最新价": 10.31,
        "涨跌额": 0.34,
        "涨跌幅": 3.41,
        "开盘价": 9.77,
        "最高价": 10.58,
        "最低价": 9.77,
        "昨收价": 9.97,
        "总市值": 1259388275.0,
        "市盈率": -123.22,
        "成交量": 53196.0,
        "成交额": 543961.0,
        "振幅": 8.12,
        "换手率": 0.04,
        "symbol": "105.WALD"
    },
    {
        "序号": 794,
        "name": "Paysign Inc",
        "最新价": 2.43,
        "涨跌额": 0.08,
        "涨跌幅": 3.4,
        "开盘价": 2.35,
        "最高价": 2.49,
        "最低价": 2.35,
        "昨收价": 2.35,
        "总市值": 128193129.0,
        "市盈率": 82.74,
        "成交量": 57282.0,
        "成交额": 139415.0,
        "振幅": 5.96,
        "换手率": 0.11,
        "symbol": "105.PAYS"
    },
    {
        "序号": 795,
        "name": "Fortress Biotech Inc Series A P",
        "最新价": 14.28,
        "涨跌额": 0.47,
        "涨跌幅": 3.4,
        "开盘价": 14.0,
        "最高价": 14.5,
        "最低价": 13.26,
        "昨收价": 13.81,
        "总市值": 48939531.0,
        "市盈率": null,
        "成交量": 11090.0,
        "成交额": 155668.0,
        "振幅": 8.98,
        "换手率": 0.32,
        "symbol": "105.FBIOP"
    },
    {
        "序号": 796,
        "name": "Arrow Dow Jones Global Yield ET",
        "最新价": 13.37,
        "涨跌额": 0.44,
        "涨跌幅": 3.4,
        "开盘价": 12.91,
        "最高价": 13.37,
        "最低价": 12.91,
        "昨收价": 12.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 709.0,
        "成交额": 9326.0,
        "振幅": 3.56,
        "换手率": null,
        "symbol": "107.GYLD"
    },
    {
        "序号": 797,
        "name": "Focus Universal Inc",
        "最新价": 1.52,
        "涨跌额": 0.05,
        "涨跌幅": 3.4,
        "开盘价": 1.45,
        "最高价": 1.55,
        "最低价": 1.45,
        "昨收价": 1.47,
        "总市值": 98529162.0,
        "市盈率": -23.71,
        "成交量": 29343.0,
        "成交额": 44042.0,
        "振幅": 6.8,
        "换手率": 0.05,
        "symbol": "105.FCUV"
    },
    {
        "序号": 798,
        "name": "Taseko Mines Ltd",
        "最新价": 1.22,
        "涨跌额": 0.04,
        "涨跌幅": 3.39,
        "开盘价": 1.18,
        "最高价": 1.25,
        "最低价": 1.18,
        "昨收价": 1.18,
        "总市值": 352247118.0,
        "市盈率": 36.56,
        "成交量": 865687.0,
        "成交额": 1064235.0,
        "振幅": 5.93,
        "换手率": 0.3,
        "symbol": "107.TGB"
    },
    {
        "序号": 799,
        "name": "Greenlane Holdings Inc-A",
        "最新价": 0.61,
        "涨跌额": 0.02,
        "涨跌幅": 3.39,
        "开盘价": 0.58,
        "最高价": 0.61,
        "最低价": 0.56,
        "昨收价": 0.59,
        "总市值": 1620289.0,
        "市盈率": -0.01,
        "成交量": 9850.0,
        "成交额": 5712.0,
        "振幅": 8.47,
        "换手率": 0.37,
        "symbol": "105.GNLN"
    },
    {
        "序号": 800,
        "name": "Corsair Gaming Inc",
        "最新价": 13.73,
        "涨跌额": 0.45,
        "涨跌幅": 3.39,
        "开盘价": 13.36,
        "最高价": 13.92,
        "最低价": 13.31,
        "昨收价": 13.28,
        "总市值": 1413895752.0,
        "市盈率": -494.02,
        "成交量": 616607.0,
        "成交额": 8476827.0,
        "振幅": 4.59,
        "换手率": 0.6,
        "symbol": "105.CRSR"
    },
    {
        "序号": 801,
        "name": "Imperial Petroleum Inc",
        "最新价": 1.84,
        "涨跌额": 0.06,
        "涨跌幅": 3.37,
        "开盘价": 1.79,
        "最高价": 1.88,
        "最低价": 1.78,
        "昨收价": 1.78,
        "总市值": 47080702.0,
        "市盈率": 0.6,
        "成交量": 473420.0,
        "成交额": 863391.0,
        "振幅": 5.62,
        "换手率": 1.85,
        "symbol": "105.IMPP"
    },
    {
        "序号": 802,
        "name": "Urban One Inc-D",
        "最新价": 3.99,
        "涨跌额": 0.13,
        "涨跌幅": 3.37,
        "开盘价": 3.81,
        "最高价": 4.04,
        "最低价": 3.81,
        "昨收价": 3.86,
        "总市值": 195210439.0,
        "市盈率": 2.71,
        "成交量": 13745.0,
        "成交额": 54423.0,
        "振幅": 5.96,
        "换手率": 0.04,
        "symbol": "105.UONEK"
    },
    {
        "序号": 803,
        "name": "Mural Oncology plc",
        "最新价": 3.99,
        "涨跌额": 0.13,
        "涨跌幅": 3.37,
        "开盘价": 3.71,
        "最高价": 4.14,
        "最低价": 3.71,
        "昨收价": 3.86,
        "总市值": 66585631.0,
        "市盈率": null,
        "成交量": 743430.0,
        "成交额": 2925760.0,
        "振幅": 11.14,
        "换手率": 4.45,
        "symbol": "105.MURA"
    },
    {
        "序号": 804,
        "name": "Structure Therapeutics Inc ADR",
        "最新价": 61.87,
        "涨跌额": 2.01,
        "涨跌幅": 3.36,
        "开盘价": 59.91,
        "最高价": 62.85,
        "最低价": 58.94,
        "昨收价": 59.86,
        "总市值": 2869928939.0,
        "市盈率": -37.28,
        "成交量": 348461.0,
        "成交额": 21251166.0,
        "振幅": 6.53,
        "换手率": 0.75,
        "symbol": "105.GPCR"
    },
    {
        "序号": 805,
        "name": "Y-mAbs Therapeutics Inc",
        "最新价": 7.08,
        "涨跌额": 0.23,
        "涨跌幅": 3.36,
        "开盘价": 7.0,
        "最高价": 7.31,
        "最低价": 6.9,
        "昨收价": 6.85,
        "总市值": 308841055.0,
        "市盈率": -16.02,
        "成交量": 211891.0,
        "成交额": 1503354.0,
        "振幅": 5.99,
        "换手率": 0.49,
        "symbol": "105.YMAB"
    },
    {
        "序号": 806,
        "name": "MDxHealth SA ADR",
        "最新价": 3.39,
        "涨跌额": 0.11,
        "涨跌幅": 3.35,
        "开盘价": 3.33,
        "最高价": 3.39,
        "最低价": 3.29,
        "昨收价": 3.28,
        "总市值": 92506635.0,
        "市盈率": -2.06,
        "成交量": 21752.0,
        "成交额": 72462.0,
        "振幅": 3.05,
        "换手率": 0.08,
        "symbol": "105.MDXH"
    },
    {
        "序号": 807,
        "name": "Krystal Biotech Inc",
        "最新价": 106.86,
        "涨跌额": 3.46,
        "涨跌幅": 3.35,
        "开盘价": 102.75,
        "最高价": 107.91,
        "最低价": 102.73,
        "昨收价": 103.4,
        "总市值": 3014128424.0,
        "市盈率": -101.1,
        "成交量": 323567.0,
        "成交额": 34429096.0,
        "振幅": 5.01,
        "换手率": 1.15,
        "symbol": "105.KRYS"
    },
    {
        "序号": 808,
        "name": "卫讯公司",
        "最新价": 24.18,
        "涨跌额": 0.78,
        "涨跌幅": 3.33,
        "开盘价": 23.4,
        "最高价": 24.35,
        "最低价": 23.4,
        "昨收价": 23.4,
        "总市值": 3010585958.0,
        "市盈率": 9.7,
        "成交量": 774379.0,
        "成交额": 18672939.0,
        "振幅": 4.06,
        "换手率": 0.62,
        "symbol": "105.VSAT"
    },
    {
        "序号": 809,
        "name": "巴西石油",
        "最新价": 14.88,
        "涨跌额": 0.48,
        "涨跌幅": 3.33,
        "开盘价": 14.45,
        "最高价": 14.92,
        "最低价": 14.43,
        "昨收价": 14.4,
        "总市值": 55370201482.0,
        "市盈率": 2.06,
        "成交量": 19778407.0,
        "成交额": 292458592.0,
        "振幅": 3.4,
        "换手率": 0.53,
        "symbol": "106.PBR"
    },
    {
        "序号": 810,
        "name": "Greenbrook TMS Inc",
        "最新价": 0.31,
        "涨跌额": 0.01,
        "涨跌幅": 3.33,
        "开盘价": 0.29,
        "最高价": 0.31,
        "最低价": 0.24,
        "昨收价": 0.3,
        "总市值": 13259943.0,
        "市盈率": -0.21,
        "成交量": 688372.0,
        "成交额": 188915.0,
        "振幅": 23.33,
        "换手率": 1.61,
        "symbol": "105.GBNH"
    },
    {
        "序号": 811,
        "name": "Enservco Corp",
        "最新价": 0.31,
        "涨跌额": 0.01,
        "涨跌幅": 3.33,
        "开盘价": 0.3,
        "最高价": 0.32,
        "最低价": 0.3,
        "昨收价": 0.3,
        "总市值": 8241576.0,
        "市盈率": -1.0,
        "成交量": 121204.0,
        "成交额": 37771.0,
        "振幅": 6.67,
        "换手率": 0.46,
        "symbol": "107.ENSV"
    },
    {
        "序号": 812,
        "name": "BAIYU Holdings Inc",
        "最新价": 1.55,
        "涨跌额": 0.05,
        "涨跌幅": 3.33,
        "开盘价": 1.5,
        "最高价": 1.8,
        "最低价": 1.5,
        "昨收价": 1.5,
        "总市值": 6381459.0,
        "市盈率": -2.73,
        "成交量": 243528.0,
        "成交额": 402775.0,
        "振幅": 20.0,
        "换手率": 5.92,
        "symbol": "105.BYU"
    },
    {
        "序号": 813,
        "name": "Distribution Solutions Group In",
        "最新价": 26.98,
        "涨跌额": 0.87,
        "涨跌幅": 3.33,
        "开盘价": 26.15,
        "最高价": 27.03,
        "最低价": 26.12,
        "昨收价": 26.11,
        "总市值": 1263904271.0,
        "市盈率": 230.64,
        "成交量": 33751.0,
        "成交额": 904515.0,
        "振幅": 3.49,
        "换手率": 0.07,
        "symbol": "105.DSGR"
    },
    {
        "序号": 814,
        "name": "Optex Systems Holdings Inc",
        "最新价": 4.35,
        "涨跌额": 0.14,
        "涨跌幅": 3.33,
        "开盘价": 4.34,
        "最高价": 4.35,
        "最低价": 4.21,
        "昨收价": 4.21,
        "总市值": 29419355.0,
        "市盈率": null,
        "成交量": 12257.0,
        "成交额": 52442.0,
        "振幅": 3.33,
        "换手率": 0.18,
        "symbol": "105.OPXS"
    },
    {
        "序号": 815,
        "name": "Invesco Alerian Galaxy Blockcha",
        "最新价": 16.78,
        "涨跌额": 0.54,
        "涨跌幅": 3.33,
        "开盘价": 16.64,
        "最高价": 16.78,
        "最低价": 16.55,
        "昨收价": 16.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 1813.0,
        "成交额": 30233.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.BLKC"
    },
    {
        "序号": 816,
        "name": "Radware Ltd",
        "最新价": 16.17,
        "涨跌额": 0.52,
        "涨跌幅": 3.32,
        "开盘价": 15.65,
        "最高价": 16.21,
        "最低价": 15.62,
        "昨收价": 15.65,
        "总市值": 679966659.0,
        "市盈率": -34.23,
        "成交量": 401013.0,
        "成交额": 6424899.0,
        "振幅": 3.77,
        "换手率": 0.95,
        "symbol": "105.RDWR"
    },
    {
        "序号": 817,
        "name": "Flexible Solutions Internationa",
        "最新价": 1.56,
        "涨跌额": 0.05,
        "涨跌幅": 3.31,
        "开盘价": 1.55,
        "最高价": 1.59,
        "最低价": 1.51,
        "昨收价": 1.51,
        "总市值": 19399430.0,
        "市盈率": 5.25,
        "成交量": 6487.0,
        "成交额": 10066.0,
        "振幅": 5.3,
        "换手率": 0.05,
        "symbol": "107.FSI"
    },
    {
        "序号": 818,
        "name": "Simplify Bitcoin Strategy PLUS ",
        "最新价": 24.67,
        "涨跌额": 0.79,
        "涨跌幅": 3.31,
        "开盘价": 24.51,
        "最高价": 24.67,
        "最低价": 24.26,
        "昨收价": 23.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 8746.0,
        "成交额": 212850.0,
        "振幅": 1.72,
        "换手率": null,
        "symbol": "105.MAXI"
    },
    {
        "序号": 819,
        "name": "Similarweb Ltd",
        "最新价": 5.0,
        "涨跌额": 0.16,
        "涨跌幅": 3.31,
        "开盘价": 4.84,
        "最高价": 5.21,
        "最低价": 4.84,
        "昨收价": 4.84,
        "总市值": 391359020.0,
        "市盈率": -9.55,
        "成交量": 121487.0,
        "成交额": 609764.0,
        "振幅": 7.64,
        "换手率": 0.16,
        "symbol": "106.SMWB"
    },
    {
        "序号": 820,
        "name": "Joby Aviation Inc",
        "最新价": 6.57,
        "涨跌额": 0.21,
        "涨跌幅": 3.3,
        "开盘价": 6.36,
        "最高价": 6.64,
        "最低价": 6.31,
        "昨收价": 6.36,
        "总市值": 4574818754.0,
        "市盈率": -9.84,
        "成交量": 5768327.0,
        "成交额": 37604467.0,
        "振幅": 5.19,
        "换手率": 0.83,
        "symbol": "106.JOBY"
    },
    {
        "序号": 821,
        "name": "PolyPid Ltd",
        "最新价": 4.7,
        "涨跌额": 0.15,
        "涨跌幅": 3.3,
        "开盘价": 4.68,
        "最高价": 4.7,
        "最低价": 4.68,
        "昨收价": 4.55,
        "总市值": 7771727.0,
        "市盈率": -0.32,
        "成交量": 1115.0,
        "成交额": 5223.0,
        "振幅": 0.44,
        "换手率": 0.07,
        "symbol": "105.PYPD"
    },
    {
        "序号": 822,
        "name": "Dynagas LNG Partners LP",
        "最新价": 2.82,
        "涨跌额": 0.09,
        "涨跌幅": 3.3,
        "开盘价": 2.59,
        "最高价": 2.85,
        "最低价": 2.59,
        "昨收价": 2.73,
        "总市值": 103782337.0,
        "市盈率": 2.41,
        "成交量": 64247.0,
        "成交额": 175152.0,
        "振幅": 9.52,
        "换手率": 0.17,
        "symbol": "106.DLNG"
    },
    {
        "序号": 823,
        "name": "美国房地产投资",
        "最新价": 16.01,
        "涨跌额": 0.51,
        "涨跌幅": 3.29,
        "开盘价": 15.65,
        "最高价": 16.56,
        "最低价": 15.52,
        "昨收价": 15.5,
        "总市值": 258594208.0,
        "市盈率": 5.22,
        "成交量": 5116.0,
        "成交额": 82076.0,
        "振幅": 6.71,
        "换手率": 0.03,
        "symbol": "106.ARL"
    },
    {
        "序号": 824,
        "name": "Energy Fuels Inc",
        "最新价": 7.55,
        "涨跌额": 0.24,
        "涨跌幅": 3.28,
        "开盘价": 7.3,
        "最高价": 7.57,
        "最低价": 7.24,
        "昨收价": 7.31,
        "总市值": 1217452162.0,
        "市盈率": 11.93,
        "成交量": 1940240.0,
        "成交额": 14523223.0,
        "振幅": 4.51,
        "换手率": 1.2,
        "symbol": "107.UUUU"
    },
    {
        "序号": 825,
        "name": "SpringWorks Therapeutics Inc",
        "最新价": 31.5,
        "涨跌额": 1.0,
        "涨跌幅": 3.28,
        "开盘价": 30.39,
        "最高价": 32.09,
        "最低价": 30.08,
        "昨收价": 30.5,
        "总市值": 2269941503.0,
        "市盈率": -7.44,
        "成交量": 1325311.0,
        "成交额": 41827319.0,
        "振幅": 6.59,
        "换手率": 1.84,
        "symbol": "105.SWTX"
    },
    {
        "序号": 826,
        "name": "American Shared Hospital Servic",
        "最新价": 2.52,
        "涨跌额": 0.08,
        "涨跌幅": 3.28,
        "开盘价": 2.5,
        "最高价": 2.54,
        "最低价": 2.49,
        "昨收价": 2.44,
        "总市值": 15876000.0,
        "市盈率": 36.0,
        "成交量": 8063.0,
        "成交额": 20259.0,
        "振幅": 2.05,
        "换手率": 0.13,
        "symbol": "107.AMS"
    },
    {
        "序号": 827,
        "name": "Steel Connect Inc",
        "最新价": 9.78,
        "涨跌额": 0.31,
        "涨跌幅": 3.27,
        "开盘价": 9.42,
        "最高价": 9.8,
        "最低价": 9.1,
        "昨收价": 9.47,
        "总市值": 61293509.0,
        "市盈率": 6.99,
        "成交量": 5472.0,
        "成交额": 52098.0,
        "振幅": 7.39,
        "换手率": 0.09,
        "symbol": "105.STCN"
    },
    {
        "序号": 828,
        "name": "ArrowMark金融",
        "最新价": 17.67,
        "涨跌额": 0.56,
        "涨跌幅": 3.27,
        "开盘价": 17.24,
        "最高价": 17.7,
        "最低价": 17.2,
        "昨收价": 17.11,
        "总市值": 125603696.0,
        "市盈率": 8.77,
        "成交量": 22148.0,
        "成交额": 384907.0,
        "振幅": 2.92,
        "换手率": 0.31,
        "symbol": "105.BANX"
    },
    {
        "序号": 829,
        "name": "Destination XL Group Inc",
        "最新价": 4.12,
        "涨跌额": 0.13,
        "涨跌幅": 3.26,
        "开盘价": 3.98,
        "最高价": 4.13,
        "最低价": 3.91,
        "昨收价": 3.99,
        "总市值": 247950170.0,
        "市盈率": 8.01,
        "成交量": 639262.0,
        "成交额": 2570237.0,
        "振幅": 5.51,
        "换手率": 1.06,
        "symbol": "105.DXLG"
    },
    {
        "序号": 830,
        "name": "Braemar Hotels & Resorts Inc Se",
        "最新价": 18.4,
        "涨跌额": 0.58,
        "涨跌幅": 3.25,
        "开盘价": 18.6,
        "最高价": 18.6,
        "最低价": 18.05,
        "昨收价": 17.82,
        "总市值": 29440000.0,
        "市盈率": null,
        "成交量": 1587.0,
        "成交额": 28988.0,
        "振幅": 3.09,
        "换手率": 0.1,
        "symbol": "106.BHR_D"
    },
    {
        "序号": 831,
        "name": "Cushman & Wakefield plc",
        "最新价": 8.89,
        "涨跌额": 0.28,
        "涨跌幅": 3.25,
        "开盘价": 8.79,
        "最高价": 8.91,
        "最低价": 8.64,
        "昨收价": 8.61,
        "总市值": 2020282282.0,
        "市盈率": -26.79,
        "成交量": 3302455.0,
        "成交额": 29185403.0,
        "振幅": 3.14,
        "换手率": 1.45,
        "symbol": "106.CWK"
    },
    {
        "序号": 832,
        "name": "Euroseas Ltd",
        "最新价": 27.96,
        "涨跌额": 0.88,
        "涨跌幅": 3.25,
        "开盘价": 27.33,
        "最高价": 28.04,
        "最低价": 27.32,
        "昨收价": 27.08,
        "总市值": 194429115.0,
        "市盈率": 1.76,
        "成交量": 28641.0,
        "成交额": 792068.0,
        "振幅": 2.66,
        "换手率": 0.41,
        "symbol": "105.ESEA"
    },
    {
        "序号": 833,
        "name": "SilverBow Resources Inc",
        "最新价": 30.55,
        "涨跌额": 0.96,
        "涨跌幅": 3.24,
        "开盘价": 29.88,
        "最高价": 30.77,
        "最低价": 29.88,
        "昨收价": 29.59,
        "总市值": 776874586.0,
        "市盈率": 2.7,
        "成交量": 255536.0,
        "成交额": 7779907.0,
        "振幅": 3.01,
        "换手率": 1.0,
        "symbol": "106.SBOW"
    },
    {
        "序号": 834,
        "name": "7GC & Co Holdings Inc Unit Cons",
        "最新价": 10.82,
        "涨跌额": 0.34,
        "涨跌幅": 3.24,
        "开盘价": 10.92,
        "最高价": 10.92,
        "最低价": 10.76,
        "昨收价": 10.48,
        "总市值": 98241683.0,
        "市盈率": -31.92,
        "成交量": 491.0,
        "成交额": 5328.0,
        "振幅": 1.53,
        "换手率": null,
        "symbol": "105.VIIAU"
    },
    {
        "序号": 835,
        "name": "Stagwell Inc-A",
        "最新价": 5.41,
        "涨跌额": 0.17,
        "涨跌幅": 3.24,
        "开盘价": 5.23,
        "最高价": 5.47,
        "最低价": 5.15,
        "昨收价": 5.24,
        "总市值": 1473051392.0,
        "市盈率": -146.21,
        "成交量": 617290.0,
        "成交额": 3330500.0,
        "振幅": 6.11,
        "换手率": 0.23,
        "symbol": "105.STGW"
    },
    {
        "序号": 836,
        "name": "Clean Energy Fuels Corp",
        "最新价": 3.51,
        "涨跌额": 0.11,
        "涨跌幅": 3.24,
        "开盘价": 3.38,
        "最高价": 3.51,
        "最低价": 3.36,
        "昨收价": 3.4,
        "总市值": 782801432.0,
        "市盈率": -8.4,
        "成交量": 1698476.0,
        "成交额": 5870779.0,
        "振幅": 4.41,
        "换手率": 0.76,
        "symbol": "105.CLNE"
    },
    {
        "序号": 837,
        "name": "Tilray Brands Inc",
        "最新价": 1.92,
        "涨跌额": 0.06,
        "涨跌幅": 3.23,
        "开盘价": 1.86,
        "最高价": 1.93,
        "最低价": 1.83,
        "昨收价": 1.86,
        "总市值": 1402155980.0,
        "市盈率": -0.97,
        "成交量": 14542810.0,
        "成交额": 27568913.0,
        "振幅": 5.38,
        "换手率": 1.99,
        "symbol": "105.TLRY"
    },
    {
        "序号": 838,
        "name": "宁圣国际",
        "最新价": 3.53,
        "涨跌额": 0.11,
        "涨跌幅": 3.22,
        "开盘价": 3.52,
        "最高价": 3.7,
        "最低价": 3.43,
        "昨收价": 3.42,
        "总市值": 14182114.0,
        "市盈率": 0.8,
        "成交量": 92253.0,
        "成交额": 330158.0,
        "振幅": 7.89,
        "换手率": 2.3,
        "symbol": "105.NISN"
    },
    {
        "序号": 839,
        "name": "Envestnet Inc",
        "最新价": 42.57,
        "涨跌额": 1.32,
        "涨跌幅": 3.2,
        "开盘价": 41.22,
        "最高价": 42.75,
        "最低价": 40.83,
        "昨收价": 41.25,
        "总市值": 2326720181.0,
        "市盈率": -25.27,
        "成交量": 593380.0,
        "成交额": 25067071.0,
        "振幅": 4.65,
        "换手率": 1.09,
        "symbol": "106.ENV"
    },
    {
        "序号": 840,
        "name": "James River Group Holdings Ltd",
        "最新价": 9.04,
        "涨跌额": 0.28,
        "涨跌幅": 3.2,
        "开盘价": 8.79,
        "最高价": 9.05,
        "最低价": 8.6,
        "昨收价": 8.76,
        "总市值": 340261695.0,
        "市盈率": 5.65,
        "成交量": 605472.0,
        "成交额": 5378979.0,
        "振幅": 5.14,
        "换手率": 1.61,
        "symbol": "105.JRVR"
    },
    {
        "序号": 841,
        "name": "Palantir Technologies Inc-A",
        "最新价": 17.77,
        "涨跌额": 0.55,
        "涨跌幅": 3.19,
        "开盘价": 17.18,
        "最高价": 17.92,
        "最低价": 17.09,
        "昨收价": 17.22,
        "总市值": 38667331087.0,
        "市盈率": 262.49,
        "成交量": 57542134.0,
        "成交额": 1014773120.0,
        "振幅": 4.82,
        "换手率": 2.64,
        "symbol": "106.PLTR"
    },
    {
        "序号": 842,
        "name": "先科电子",
        "最新价": 20.04,
        "涨跌额": 0.62,
        "涨跌幅": 3.19,
        "开盘价": 19.56,
        "最高价": 20.37,
        "最低价": 19.23,
        "昨收价": 19.42,
        "总市值": 1288266590.0,
        "市盈率": -2.57,
        "成交量": 3580749.0,
        "成交额": 71476254.0,
        "振幅": 5.87,
        "换手率": 5.57,
        "symbol": "105.SMTC"
    },
    {
        "序号": 843,
        "name": "Semler Scientific Inc",
        "最新价": 45.05,
        "涨跌额": 1.39,
        "涨跌幅": 3.18,
        "开盘价": 43.49,
        "最高价": 46.19,
        "最低价": 43.06,
        "昨收价": 43.66,
        "总市值": 309637480.0,
        "市盈率": 15.82,
        "成交量": 66201.0,
        "成交额": 2957591.0,
        "振幅": 7.17,
        "换手率": 0.96,
        "symbol": "105.SMLR"
    },
    {
        "序号": 844,
        "name": "Vincerx Pharma Inc",
        "最新价": 0.65,
        "涨跌额": 0.02,
        "涨跌幅": 3.17,
        "开盘价": 0.65,
        "最高价": 0.7,
        "最低价": 0.63,
        "昨收价": 0.63,
        "总市值": 13897371.0,
        "市盈率": -0.28,
        "成交量": 137045.0,
        "成交额": 89312.0,
        "振幅": 11.11,
        "换手率": 0.64,
        "symbol": "105.VINC"
    },
    {
        "序号": 845,
        "name": "Ultralife Corp",
        "最新价": 7.15,
        "涨跌额": 0.22,
        "涨跌幅": 3.17,
        "开盘价": 6.93,
        "最高价": 7.28,
        "最低价": 6.89,
        "昨收价": 6.93,
        "总市值": 116831808.0,
        "市盈率": 28.5,
        "成交量": 50254.0,
        "成交额": 356502.0,
        "振幅": 5.63,
        "换手率": 0.31,
        "symbol": "105.ULBI"
    },
    {
        "序号": 846,
        "name": "阿莱恩斯西部银行",
        "最新价": 56.58,
        "涨跌额": 1.74,
        "涨跌幅": 3.17,
        "开盘价": 54.8,
        "最高价": 57.25,
        "最低价": 54.2,
        "昨收价": 54.84,
        "总市值": 6194022116.0,
        "市盈率": 7.14,
        "成交量": 1675856.0,
        "成交额": 94650009.0,
        "振幅": 5.56,
        "换手率": 1.53,
        "symbol": "106.WAL"
    },
    {
        "序号": 847,
        "name": "Riley Exploration Permian Inc",
        "最新价": 26.36,
        "涨跌额": 0.81,
        "涨跌幅": 3.17,
        "开盘价": 25.67,
        "最高价": 26.5,
        "最低价": 25.47,
        "昨收价": 25.55,
        "总市值": 538434606.0,
        "市盈率": 5.36,
        "成交量": 87452.0,
        "成交额": 2306885.0,
        "振幅": 4.03,
        "换手率": 0.43,
        "symbol": "107.REPX"
    },
    {
        "序号": 848,
        "name": "The Joint Corp",
        "最新价": 9.45,
        "涨跌额": 0.29,
        "涨跌幅": 3.17,
        "开盘价": 9.18,
        "最高价": 9.45,
        "最低价": 8.68,
        "昨收价": 9.16,
        "总市值": 139428012.0,
        "市盈率": 53.56,
        "成交量": 82949.0,
        "成交额": 771105.0,
        "振幅": 8.41,
        "换手率": 0.56,
        "symbol": "105.JYNT"
    },
    {
        "序号": 849,
        "name": "RE\/MAX Holdings Inc-A",
        "最新价": 10.45,
        "涨跌额": 0.32,
        "涨跌幅": 3.16,
        "开盘价": 10.08,
        "最高价": 10.58,
        "最低价": 10.03,
        "昨收价": 10.13,
        "总市值": 190580078.0,
        "市盈率": -3.21,
        "成交量": 286687.0,
        "成交额": 2997773.0,
        "振幅": 5.43,
        "换手率": 1.57,
        "symbol": "106.RMAX"
    },
    {
        "序号": 850,
        "name": "圣乔",
        "最新价": 53.91,
        "涨跌额": 1.65,
        "涨跌幅": 3.16,
        "开盘价": 52.14,
        "最高价": 54.21,
        "最低价": 51.9,
        "昨收价": 52.26,
        "总市值": 3146836676.0,
        "市盈率": 33.96,
        "成交量": 333313.0,
        "成交额": 17726445.0,
        "振幅": 4.42,
        "换手率": 0.57,
        "symbol": "106.JOE"
    },
    {
        "序号": 851,
        "name": "OneSpan Inc",
        "最新价": 10.15,
        "涨跌额": 0.31,
        "涨跌幅": 3.15,
        "开盘价": 9.87,
        "最高价": 10.18,
        "最低价": 9.8,
        "昨收价": 9.84,
        "总市值": 404856460.0,
        "市盈率": -12.14,
        "成交量": 908173.0,
        "成交额": 9140303.0,
        "振幅": 3.86,
        "换手率": 2.28,
        "symbol": "105.OSPN"
    },
    {
        "序号": 852,
        "name": "Savers Value Village Inc",
        "最新价": 15.73,
        "涨跌额": 0.48,
        "涨跌幅": 3.15,
        "开盘价": 15.16,
        "最高价": 15.85,
        "最低价": 15.0,
        "昨收价": 15.25,
        "总市值": 2523919917.0,
        "市盈率": 70.74,
        "成交量": 917494.0,
        "成交额": 14366185.0,
        "振幅": 5.57,
        "换手率": 0.57,
        "symbol": "106.SVV"
    },
    {
        "序号": 853,
        "name": "Baytex Energy Corp",
        "最新价": 3.28,
        "涨跌额": 0.1,
        "涨跌幅": 3.14,
        "开盘价": 3.24,
        "最高价": 3.31,
        "最低价": 3.2,
        "昨收价": 3.18,
        "总市值": 2772780800.0,
        "市盈率": 4.99,
        "成交量": 11622414.0,
        "成交额": 37924533.0,
        "振幅": 3.46,
        "换手率": 1.37,
        "symbol": "106.BTE"
    },
    {
        "序号": 854,
        "name": "柯尔百货",
        "最新价": 24.65,
        "涨跌额": 0.75,
        "涨跌幅": 3.14,
        "开盘价": 23.9,
        "最高价": 24.79,
        "最低价": 23.67,
        "昨收价": 23.9,
        "总市值": 2729053857.0,
        "市盈率": -19.22,
        "成交量": 5800883.0,
        "成交额": 141805240.0,
        "振幅": 4.69,
        "换手率": 5.24,
        "symbol": "106.KSS"
    },
    {
        "序号": 855,
        "name": "MVB Financial Corp",
        "最新价": 21.4,
        "涨跌额": 0.65,
        "涨跌幅": 3.13,
        "开盘价": 20.65,
        "最高价": 21.67,
        "最低价": 20.11,
        "昨收价": 20.75,
        "总市值": 272563732.0,
        "市盈率": 9.14,
        "成交量": 59914.0,
        "成交额": 1252346.0,
        "振幅": 7.52,
        "换手率": 0.47,
        "symbol": "105.MVBF"
    },
    {
        "序号": 856,
        "name": "Natural Alternatives Internatio",
        "最新价": 6.59,
        "涨跌额": 0.2,
        "涨跌幅": 3.13,
        "开盘价": 6.39,
        "最高价": 6.6,
        "最低价": 6.39,
        "昨收价": 6.39,
        "总市值": 40125278.0,
        "市盈率": 51.84,
        "成交量": 10085.0,
        "成交额": 65842.0,
        "振幅": 3.29,
        "换手率": 0.17,
        "symbol": "105.NAII"
    },
    {
        "序号": 857,
        "name": "PowerFleet Inc",
        "最新价": 2.31,
        "涨跌额": 0.07,
        "涨跌幅": 3.13,
        "开盘价": 2.24,
        "最高价": 2.33,
        "最低价": 2.22,
        "昨收价": 2.24,
        "总市值": 85960422.0,
        "市盈率": -24.3,
        "成交量": 88141.0,
        "成交额": 200516.0,
        "振幅": 4.91,
        "换手率": 0.24,
        "symbol": "105.PWFL"
    },
    {
        "序号": 858,
        "name": "Mobilicom Ltd ADR",
        "最新价": 1.65,
        "涨跌额": 0.05,
        "涨跌幅": 3.13,
        "开盘价": 1.71,
        "最高价": 1.72,
        "最低价": 1.59,
        "昨收价": 1.6,
        "总市值": 7960060.0,
        "市盈率": -24.0,
        "成交量": 111711.0,
        "成交额": 184713.0,
        "振幅": 8.13,
        "换手率": 2.32,
        "symbol": "105.MOB"
    },
    {
        "序号": 859,
        "name": "Hyzon Motors Inc-A",
        "最新价": 0.99,
        "涨跌额": 0.03,
        "涨跌幅": 3.13,
        "开盘价": 0.95,
        "最高价": 1.02,
        "最低价": 0.95,
        "昨收价": 0.96,
        "总市值": 242552797.0,
        "市盈率": -1.37,
        "成交量": 329103.0,
        "成交额": 326918.0,
        "振幅": 7.29,
        "换手率": 0.13,
        "symbol": "105.HYZN"
    },
    {
        "序号": 860,
        "name": "First Wave BioPharma Inc",
        "最新价": 0.33,
        "涨跌额": 0.01,
        "涨跌幅": 3.13,
        "开盘价": 0.31,
        "最高价": 0.33,
        "最低价": 0.3,
        "昨收价": 0.32,
        "总市值": 4454993.0,
        "市盈率": -0.28,
        "成交量": 187726.0,
        "成交额": 58988.0,
        "振幅": 9.38,
        "换手率": 1.39,
        "symbol": "105.FWBI"
    },
    {
        "序号": 861,
        "name": "Great Ajax Corp",
        "最新价": 4.95,
        "涨跌额": 0.15,
        "涨跌幅": 3.13,
        "开盘价": 4.76,
        "最高价": 5.0,
        "最低价": 4.76,
        "昨收价": 4.8,
        "总市值": 135973594.0,
        "市盈率": -4.43,
        "成交量": 94282.0,
        "成交额": 465278.0,
        "振幅": 5.0,
        "换手率": 0.34,
        "symbol": "106.AJX"
    },
    {
        "序号": 862,
        "name": "Sound Financial Bancorp Inc",
        "最新价": 37.4,
        "涨跌额": 1.13,
        "涨跌幅": 3.12,
        "开盘价": 36.1,
        "最高价": 37.4,
        "最低价": 36.1,
        "昨收价": 36.27,
        "总市值": 96069642.0,
        "市盈率": 10.5,
        "成交量": 8358.0,
        "成交额": 305333.0,
        "振幅": 3.58,
        "换手率": 0.33,
        "symbol": "105.SFBC"
    },
    {
        "序号": 863,
        "name": "Cryoport Inc",
        "最新价": 15.23,
        "涨跌额": 0.46,
        "涨跌幅": 3.11,
        "开盘价": 14.66,
        "最高价": 15.36,
        "最低价": 14.66,
        "昨收价": 14.77,
        "总市值": 745717410.0,
        "市盈率": -15.99,
        "成交量": 266838.0,
        "成交额": 4034787.0,
        "振幅": 4.74,
        "换手率": 0.54,
        "symbol": "105.CYRX"
    },
    {
        "序号": 864,
        "name": "Community Health Systems Inc",
        "最新价": 2.65,
        "涨跌额": 0.08,
        "涨跌幅": 3.11,
        "开盘价": 2.54,
        "最高价": 2.66,
        "最低价": 2.5,
        "昨收价": 2.57,
        "总市值": 362522419.0,
        "市盈率": 1.54,
        "成交量": 1921977.0,
        "成交额": 4972709.0,
        "振幅": 6.23,
        "换手率": 1.4,
        "symbol": "106.CYH"
    },
    {
        "序号": 865,
        "name": "波音",
        "最新价": 244.7,
        "涨跌额": 7.37,
        "涨跌幅": 3.11,
        "开盘价": 237.32,
        "最高价": 244.7,
        "最低价": 237.32,
        "昨收价": 237.33,
        "总市值": 148037822226.0,
        "市盈率": -52.25,
        "成交量": 7171150.0,
        "成交额": 1739005952.0,
        "振幅": 3.11,
        "换手率": 1.19,
        "symbol": "106.BA"
    },
    {
        "序号": 866,
        "name": "Nuvation Bio Inc-A",
        "最新价": 1.33,
        "涨跌额": 0.04,
        "涨跌幅": 3.1,
        "开盘价": 1.3,
        "最高价": 1.35,
        "最低价": 1.28,
        "昨收价": 1.29,
        "总市值": 291195590.0,
        "市盈率": -3.51,
        "成交量": 546520.0,
        "成交额": 722467.0,
        "振幅": 5.43,
        "换手率": 0.25,
        "symbol": "106.NUVB"
    },
    {
        "序号": 867,
        "name": "Opendoor Technologies Inc",
        "最新价": 3.66,
        "涨跌额": 0.11,
        "涨跌幅": 3.1,
        "开盘价": 3.5,
        "最高价": 3.69,
        "最低价": 3.43,
        "昨收价": 3.55,
        "总市值": 2452314155.0,
        "市盈率": -4.21,
        "成交量": 14014722.0,
        "成交额": 50297744.0,
        "振幅": 7.32,
        "换手率": 2.09,
        "symbol": "105.OPEN"
    },
    {
        "序号": 868,
        "name": "美国石油勘探",
        "最新价": 59.03,
        "涨跌额": 1.77,
        "涨跌幅": 3.09,
        "开盘价": 57.28,
        "最高价": 59.03,
        "最低价": 57.28,
        "昨收价": 57.26,
        "总市值": 429688165.0,
        "市盈率": 14.54,
        "成交量": 15559.0,
        "成交额": 910197.0,
        "振幅": 3.06,
        "换手率": 0.21,
        "symbol": "106.ODC"
    },
    {
        "序号": 869,
        "name": "Natural Gas Services Group Inc",
        "最新价": 13.69,
        "涨跌额": 0.41,
        "涨跌幅": 3.09,
        "开盘价": 13.28,
        "最高价": 13.75,
        "最低价": 13.12,
        "昨收价": 13.28,
        "总市值": 170266527.0,
        "市盈率": 74.38,
        "成交量": 48665.0,
        "成交额": 651962.0,
        "振幅": 4.74,
        "换手率": 0.39,
        "symbol": "106.NGS"
    },
    {
        "序号": 870,
        "name": "海洋世界娱乐",
        "最新价": 51.84,
        "涨跌额": 1.55,
        "涨跌幅": 3.08,
        "开盘价": 50.42,
        "最高价": 52.06,
        "最低价": 50.42,
        "昨收价": 50.29,
        "总市值": 3314858671.0,
        "市盈率": 13.63,
        "成交量": 1038199.0,
        "成交额": 53706270.0,
        "振幅": 3.26,
        "换手率": 1.62,
        "symbol": "106.SEAS"
    },
    {
        "序号": 871,
        "name": "Maplebear Inc",
        "最新价": 24.44,
        "涨跌额": 0.73,
        "涨跌幅": 3.08,
        "开盘价": 23.89,
        "最高价": 24.64,
        "最低价": 23.71,
        "昨收价": 23.71,
        "总市值": 6848477818.0,
        "市盈率": -5.3,
        "成交量": 1623832.0,
        "成交额": 39495343.0,
        "振幅": 3.92,
        "换手率": 0.58,
        "symbol": "105.CART"
    },
    {
        "序号": 872,
        "name": "TOP Ships Inc",
        "最新价": 16.41,
        "涨跌额": 0.49,
        "涨跌幅": 3.08,
        "开盘价": 15.51,
        "最高价": 17.5,
        "最低价": 15.5,
        "昨收价": 15.92,
        "总市值": 27823253.0,
        "市盈率": 1.73,
        "成交量": 153337.0,
        "成交额": 2567909.0,
        "振幅": 12.56,
        "换手率": 9.04,
        "symbol": "105.TOPS"
    },
    {
        "序号": 873,
        "name": "The Glimpse Group Inc",
        "最新价": 1.34,
        "涨跌额": 0.04,
        "涨跌幅": 3.08,
        "开盘价": 1.28,
        "最高价": 1.36,
        "最低价": 1.28,
        "昨收价": 1.3,
        "总市值": 22387481.0,
        "市盈率": -0.96,
        "成交量": 10802.0,
        "成交额": 14337.0,
        "振幅": 6.15,
        "换手率": 0.06,
        "symbol": "105.VRAR"
    },
    {
        "序号": 874,
        "name": "Rumble Inc-A",
        "最新价": 4.69,
        "涨跌额": 0.14,
        "涨跌幅": 3.08,
        "开盘价": 4.61,
        "最高价": 4.75,
        "最低价": 4.53,
        "昨收价": 4.55,
        "总市值": 1810598686.0,
        "市盈率": -20.55,
        "成交量": 1119593.0,
        "成交额": 5248367.0,
        "振幅": 4.84,
        "换手率": 0.29,
        "symbol": "105.RUM"
    },
    {
        "序号": 875,
        "name": "AdTheorent Holding Co Inc",
        "最新价": 1.34,
        "涨跌额": 0.04,
        "涨跌幅": 3.08,
        "开盘价": 1.28,
        "最高价": 1.34,
        "最低价": 1.27,
        "昨收价": 1.3,
        "总市值": 118244143.0,
        "市盈率": 18.95,
        "成交量": 56395.0,
        "成交额": 74322.0,
        "振幅": 5.38,
        "换手率": 0.06,
        "symbol": "105.ADTH"
    },
    {
        "序号": 876,
        "name": "MicroSectors Travel 3X Leverage",
        "最新价": 40.21,
        "涨跌额": 1.2,
        "涨跌幅": 3.08,
        "开盘价": 39.27,
        "最高价": 40.23,
        "最低价": 38.8,
        "昨收价": 39.01,
        "总市值": 28987757364.0,
        "市盈率": 9.14,
        "成交量": 5096.0,
        "成交额": 202166.0,
        "振幅": 3.67,
        "换手率": null,
        "symbol": "107.FLYU"
    },
    {
        "序号": 877,
        "name": "Nextdoor Holdings Inc-A",
        "最新价": 1.68,
        "涨跌额": 0.05,
        "涨跌幅": 3.07,
        "开盘价": 1.66,
        "最高价": 1.71,
        "最低价": 1.6,
        "昨收价": 1.63,
        "总市值": 647014776.0,
        "市盈率": -4.6,
        "成交量": 4047935.0,
        "成交额": 6779026.0,
        "振幅": 6.75,
        "换手率": 1.05,
        "symbol": "106.KIND"
    },
    {
        "序号": 878,
        "name": "约翰威立国际出版-B",
        "最新价": 30.62,
        "涨跌额": 0.91,
        "涨跌幅": 3.06,
        "开盘价": 30.08,
        "最高价": 30.62,
        "最低价": 30.0,
        "昨收价": 29.71,
        "总市值": 1680097262.0,
        "市盈率": -14.63,
        "成交量": 1313.0,
        "成交额": 39526.0,
        "振幅": 2.09,
        "换手率": 0.01,
        "symbol": "106.WLYB"
    },
    {
        "序号": 879,
        "name": "知临集团",
        "最新价": 2.02,
        "涨跌额": 0.06,
        "涨跌幅": 3.06,
        "开盘价": 2.0,
        "最高价": 2.02,
        "最低价": 1.87,
        "昨收价": 1.96,
        "总市值": 10467028.0,
        "市盈率": -1.07,
        "成交量": 5829.0,
        "成交额": 11444.0,
        "振幅": 7.65,
        "换手率": 0.11,
        "symbol": "105.APM"
    },
    {
        "序号": 880,
        "name": "Alimera Sciences Inc",
        "最新价": 3.71,
        "涨跌额": 0.11,
        "涨跌幅": 3.06,
        "开盘价": 3.6,
        "最高价": 3.96,
        "最低价": 3.58,
        "昨收价": 3.6,
        "总市值": 194466480.0,
        "市盈率": -9.66,
        "成交量": 83747.0,
        "成交额": 318170.0,
        "振幅": 10.56,
        "换手率": 0.16,
        "symbol": "105.ALIM"
    },
    {
        "序号": 881,
        "name": "Summit Financial Group Inc",
        "最新价": 26.68,
        "涨跌额": 0.79,
        "涨跌幅": 3.05,
        "开盘价": 25.99,
        "最高价": 26.97,
        "最低价": 25.69,
        "昨收价": 25.89,
        "总市值": 391525051.0,
        "市盈率": 7.56,
        "成交量": 26948.0,
        "成交额": 706832.0,
        "振幅": 4.94,
        "换手率": 0.18,
        "symbol": "105.SMMF"
    },
    {
        "序号": 882,
        "name": "Vitru Ltd",
        "最新价": 13.18,
        "涨跌额": 0.39,
        "涨跌幅": 3.05,
        "开盘价": 12.96,
        "最高价": 13.37,
        "最低价": 12.7,
        "昨收价": 12.79,
        "总市值": 452196627.0,
        "市盈率": 14.29,
        "成交量": 16100.0,
        "成交额": 210798.0,
        "振幅": 5.24,
        "换手率": 0.05,
        "symbol": "105.VTRU"
    },
    {
        "序号": 883,
        "name": "奥瑞许科技",
        "最新价": 7.1,
        "涨跌额": 0.21,
        "涨跌幅": 3.05,
        "开盘价": 6.89,
        "最高价": 7.11,
        "最低价": 6.83,
        "昨收价": 6.89,
        "总市值": 521738331.0,
        "市盈率": 10.74,
        "成交量": 482807.0,
        "成交额": 3385512.0,
        "振幅": 4.06,
        "换手率": 0.66,
        "symbol": "105.OSUR"
    },
    {
        "序号": 884,
        "name": "AMC网络-A",
        "最新价": 17.92,
        "涨跌额": 0.53,
        "涨跌幅": 3.05,
        "开盘价": 17.32,
        "最高价": 18.62,
        "最低价": 17.32,
        "昨收价": 17.39,
        "总市值": 780549110.0,
        "市盈率": -28.47,
        "成交量": 330147.0,
        "成交额": 5977250.0,
        "振幅": 7.48,
        "换手率": 0.76,
        "symbol": "105.AMCX"
    },
    {
        "序号": 885,
        "name": "Aurora Innovation Inc-A",
        "最新价": 2.71,
        "涨跌额": 0.08,
        "涨跌幅": 3.04,
        "开盘价": 2.6,
        "最高价": 2.78,
        "最低价": 2.6,
        "昨收价": 2.63,
        "总市值": 4118627624.0,
        "市盈率": -4.59,
        "成交量": 5426392.0,
        "成交额": 14708465.0,
        "振幅": 6.84,
        "换手率": 0.36,
        "symbol": "105.AUR"
    },
    {
        "序号": 886,
        "name": "Centrus Energy Corp-A",
        "最新价": 51.21,
        "涨跌额": 1.51,
        "涨跌幅": 3.04,
        "开盘价": 50.11,
        "最高价": 51.57,
        "最低价": 50.11,
        "昨收价": 49.7,
        "总市值": 795109761.0,
        "市盈率": 16.1,
        "成交量": 98480.0,
        "成交额": 5021020.0,
        "振幅": 2.94,
        "换手率": 0.63,
        "symbol": "107.LEU"
    },
    {
        "序号": 887,
        "name": "Spirit AeroSystems Holdings Inc",
        "最新价": 28.86,
        "涨跌额": 0.85,
        "涨跌幅": 3.03,
        "开盘价": 28.1,
        "最高价": 28.9,
        "最低价": 28.1,
        "昨收价": 28.01,
        "总市值": 3309141482.0,
        "市盈率": -3.54,
        "成交量": 2041801.0,
        "成交额": 58460718.0,
        "振幅": 2.86,
        "换手率": 1.78,
        "symbol": "106.SPR"
    },
    {
        "序号": 888,
        "name": "帝国石油",
        "最新价": 55.37,
        "涨跌额": 1.63,
        "涨跌幅": 3.03,
        "开盘价": 54.48,
        "最高价": 55.95,
        "最低价": 53.8,
        "昨收价": 53.74,
        "总市值": 31376358324.0,
        "市盈率": 7.99,
        "成交量": 412640.0,
        "成交额": 22806257.0,
        "振幅": 4.0,
        "换手率": 0.07,
        "symbol": "107.IMO"
    },
    {
        "序号": 889,
        "name": "Pathfinder Bancorp Inc",
        "最新价": 13.25,
        "涨跌额": 0.39,
        "涨跌幅": 3.03,
        "开盘价": 13.1,
        "最高价": 13.25,
        "最低价": 13.1,
        "昨收价": 12.86,
        "总市值": 80819316.0,
        "市盈率": 7.86,
        "成交量": 3468.0,
        "成交额": 45596.0,
        "振幅": 1.17,
        "换手率": 0.06,
        "symbol": "105.PBHC"
    },
    {
        "序号": 890,
        "name": "Banco Macro SA ADR",
        "最新价": 27.19,
        "涨跌额": 0.8,
        "涨跌幅": 3.03,
        "开盘价": 26.76,
        "最高价": 27.25,
        "最低价": 26.17,
        "昨收价": 26.39,
        "总市值": 1738565056.0,
        "市盈率": 5.87,
        "成交量": 270679.0,
        "成交额": 7263469.0,
        "振幅": 4.09,
        "换手率": 0.42,
        "symbol": "106.BMA"
    },
    {
        "序号": 891,
        "name": "世纪互联",
        "最新价": 3.06,
        "涨跌额": 0.09,
        "涨跌幅": 3.03,
        "开盘价": 2.99,
        "最高价": 3.09,
        "最低价": 2.99,
        "昨收价": 2.97,
        "总市值": 471676754.0,
        "市盈率": -12.04,
        "成交量": 1252642.0,
        "成交额": 3806963.0,
        "振幅": 3.37,
        "换手率": 0.81,
        "symbol": "105.VNET"
    },
    {
        "序号": 892,
        "name": "Cue Health Inc",
        "最新价": 0.34,
        "涨跌额": 0.01,
        "涨跌幅": 3.03,
        "开盘价": 0.32,
        "最高价": 0.34,
        "最低价": 0.31,
        "昨收价": 0.33,
        "总市值": 52574899.0,
        "市盈率": -0.2,
        "成交量": 181730.0,
        "成交额": 58478.0,
        "振幅": 9.09,
        "换手率": 0.12,
        "symbol": "105.HLTH"
    },
    {
        "序号": 893,
        "name": "Entera Bio Ltd",
        "最新价": 0.68,
        "涨跌额": 0.02,
        "涨跌幅": 3.03,
        "开盘价": 0.63,
        "最高价": 0.68,
        "最低价": 0.63,
        "昨收价": 0.66,
        "总市值": 19593487.0,
        "市盈率": -1.97,
        "成交量": 15977.0,
        "成交额": 10700.0,
        "振幅": 7.58,
        "换手率": 0.06,
        "symbol": "105.ENTX"
    },
    {
        "序号": 894,
        "name": "LivaNova PLC",
        "最新价": 46.43,
        "涨跌额": 1.36,
        "涨跌幅": 3.02,
        "开盘价": 44.87,
        "最高价": 46.71,
        "最低价": 44.77,
        "昨收价": 45.07,
        "总市值": 2501222451.0,
        "市盈率": 872.11,
        "成交量": 568901.0,
        "成交额": 26309366.0,
        "振幅": 4.3,
        "换手率": 1.06,
        "symbol": "105.LIVN"
    },
    {
        "序号": 895,
        "name": "ARK 21Shares Blockchain and Dig",
        "最新价": 31.76,
        "涨跌额": 0.93,
        "涨跌幅": 3.02,
        "开盘价": 31.37,
        "最高价": 31.76,
        "最低价": 31.37,
        "昨收价": 30.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 129.0,
        "成交额": 4046.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.ARKD"
    },
    {
        "序号": 896,
        "name": "IperionX Ltd ADR",
        "最新价": 8.88,
        "涨跌额": 0.26,
        "涨跌幅": 3.02,
        "开盘价": 8.78,
        "最高价": 8.93,
        "最低价": 8.78,
        "昨收价": 8.62,
        "总市值": 171822648.0,
        "市盈率": -9.85,
        "成交量": 7227.0,
        "成交额": 64111.0,
        "振幅": 1.74,
        "换手率": 0.04,
        "symbol": "105.IPX"
    },
    {
        "序号": 897,
        "name": "ProShares UltraPro Short 20 Yea",
        "最新价": 78.62,
        "涨跌额": 2.3,
        "涨跌幅": 3.01,
        "开盘价": 78.6,
        "最高价": 79.8,
        "最低价": 78.18,
        "昨收价": 76.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 33133.0,
        "成交额": 2614613.0,
        "振幅": 2.12,
        "换手率": null,
        "symbol": "107.TTT"
    },
    {
        "序号": 898,
        "name": "Empire State Realty Op LP Serie",
        "最新价": 9.25,
        "涨跌额": 0.27,
        "涨跌幅": 3.01,
        "开盘价": 9.01,
        "最高价": 9.29,
        "最低价": 8.66,
        "昨收价": 8.98,
        "总市值": 259632654.0,
        "市盈率": 3.23,
        "成交量": 35697.0,
        "成交额": 318214.0,
        "振幅": 7.02,
        "换手率": 0.18,
        "symbol": "107.ESBA"
    },
    {
        "序号": 899,
        "name": "Direxion Daily Regional Banks B",
        "最新价": 79.97,
        "涨跌额": 2.33,
        "涨跌幅": 3.0,
        "开盘价": 78.01,
        "最高价": 81.78,
        "最低价": 75.82,
        "昨收价": 77.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 1824603.0,
        "成交额": 145552978.0,
        "振幅": 7.68,
        "换手率": null,
        "symbol": "107.DPST"
    },
    {
        "序号": 900,
        "name": "MYR Group Inc",
        "最新价": 133.9,
        "涨跌额": 3.9,
        "涨跌幅": 3.0,
        "开盘价": 129.99,
        "最高价": 133.97,
        "最低价": 129.79,
        "昨收价": 130.0,
        "总市值": 2237406603.0,
        "市盈率": 24.45,
        "成交量": 113010.0,
        "成交额": 15057823.0,
        "振幅": 3.22,
        "换手率": 0.68,
        "symbol": "105.MYRG"
    },
    {
        "序号": 901,
        "name": "Terran Orbital Corp",
        "最新价": 1.03,
        "涨跌额": 0.03,
        "涨跌幅": 3.0,
        "开盘价": 0.99,
        "最高价": 1.04,
        "最低价": 0.97,
        "昨收价": 1.0,
        "总市值": 200295202.0,
        "市盈率": -1.41,
        "成交量": 2705839.0,
        "成交额": 2724483.0,
        "振幅": 7.0,
        "换手率": 1.39,
        "symbol": "106.LLAP"
    },
    {
        "序号": 902,
        "name": "Bit Origin Ltd",
        "最新价": 5.15,
        "涨跌额": 0.15,
        "涨跌幅": 3.0,
        "开盘价": 5.03,
        "最高价": 5.23,
        "最低价": 4.91,
        "昨收价": 5.0,
        "总市值": 17480444.0,
        "市盈率": -0.62,
        "成交量": 29486.0,
        "成交额": 149594.0,
        "振幅": 6.4,
        "换手率": 0.87,
        "symbol": "105.BTOG"
    },
    {
        "序号": 903,
        "name": "MicroSectors Oil & Gas Exp. & P",
        "最新价": 32.68,
        "涨跌额": 0.95,
        "涨跌幅": 2.99,
        "开盘价": 32.45,
        "最高价": 32.99,
        "最低价": 32.15,
        "昨收价": 31.73,
        "总市值": 23559311381.0,
        "市盈率": 7.43,
        "成交量": 238617.0,
        "成交额": 7758115.0,
        "振幅": 2.65,
        "换手率": null,
        "symbol": "107.OILU"
    },
    {
        "序号": 904,
        "name": "FingerMotion Inc",
        "最新价": 4.13,
        "涨跌额": 0.12,
        "涨跌幅": 2.99,
        "开盘价": 3.99,
        "最高价": 4.18,
        "最低价": 3.99,
        "昨收价": 4.01,
        "总市值": 217012296.0,
        "市盈率": -36.43,
        "成交量": 157746.0,
        "成交额": 647430.0,
        "振幅": 4.74,
        "换手率": 0.3,
        "symbol": "105.FNGR"
    },
    {
        "序号": 905,
        "name": "MiX Telematics Ltd ADR",
        "最新价": 6.55,
        "涨跌额": 0.19,
        "涨跌幅": 2.99,
        "开盘价": 6.3,
        "最高价": 6.62,
        "最低价": 6.21,
        "昨收价": 6.36,
        "总市值": 145153400.0,
        "市盈率": 21.02,
        "成交量": 47242.0,
        "成交额": 304427.0,
        "振幅": 6.45,
        "换手率": 0.21,
        "symbol": "106.MIXT"
    },
    {
        "序号": 906,
        "name": "60 Degrees Pharmaceuticals Inc",
        "最新价": 0.69,
        "涨跌额": 0.02,
        "涨跌幅": 2.99,
        "开盘价": 0.66,
        "最高价": 0.69,
        "最低价": 0.66,
        "昨收价": 0.67,
        "总市值": 4001679.0,
        "市盈率": -1.17,
        "成交量": 16905.0,
        "成交额": 11408.0,
        "振幅": 4.48,
        "换手率": 0.29,
        "symbol": "105.SXTP"
    },
    {
        "序号": 907,
        "name": "康年控股",
        "最新价": 1.38,
        "涨跌额": 0.04,
        "涨跌幅": 2.99,
        "开盘价": 1.34,
        "最高价": 1.38,
        "最低价": 1.34,
        "昨收价": 1.34,
        "总市值": 16867576.0,
        "市盈率": -3.89,
        "成交量": 2025.0,
        "成交额": 2790.0,
        "振幅": 2.99,
        "换手率": 0.02,
        "symbol": "105.CNFR"
    },
    {
        "序号": 908,
        "name": "REE Automotive Ltd-A",
        "最新价": 4.49,
        "涨跌额": 0.13,
        "涨跌幅": 2.98,
        "开盘价": 4.43,
        "最高价": 4.77,
        "最低价": 4.1,
        "昨收价": 4.36,
        "总市值": 50367989.0,
        "市盈率": -0.44,
        "成交量": 45606.0,
        "成交额": 200814.0,
        "振幅": 15.37,
        "换手率": 0.41,
        "symbol": "105.REE"
    },
    {
        "序号": 909,
        "name": "大西洋电讯网络国际",
        "最新价": 32.14,
        "涨跌额": 0.93,
        "涨跌幅": 2.98,
        "开盘价": 31.29,
        "最高价": 32.15,
        "最低价": 31.2,
        "昨收价": 31.21,
        "总市值": 495646399.0,
        "市盈率": -49.11,
        "成交量": 53586.0,
        "成交额": 1712007.0,
        "振幅": 3.04,
        "换手率": 0.35,
        "symbol": "105.ATNI"
    },
    {
        "序号": 910,
        "name": "Outset Medical Inc",
        "最新价": 5.88,
        "涨跌额": 0.17,
        "涨跌幅": 2.98,
        "开盘价": 5.66,
        "最高价": 5.92,
        "最低价": 5.6,
        "昨收价": 5.71,
        "总市值": 295202872.0,
        "市盈率": -1.68,
        "成交量": 352150.0,
        "成交额": 2051557.0,
        "振幅": 5.6,
        "换手率": 0.7,
        "symbol": "105.OM"
    },
    {
        "序号": 911,
        "name": "诺亚财富",
        "最新价": 12.8,
        "涨跌额": 0.37,
        "涨跌幅": 2.98,
        "开盘价": 12.42,
        "最高价": 12.86,
        "最低价": 12.42,
        "昨收价": 12.43,
        "总市值": 819828966.0,
        "市盈率": 6.17,
        "成交量": 33176.0,
        "成交额": 422996.0,
        "振幅": 3.54,
        "换手率": 0.05,
        "symbol": "106.NOAH"
    },
    {
        "序号": 912,
        "name": "Atlas Lithium Corp",
        "最新价": 27.1,
        "涨跌额": 0.78,
        "涨跌幅": 2.96,
        "开盘价": 26.31,
        "最高价": 27.49,
        "最低价": 26.06,
        "昨收价": 26.32,
        "总市值": 301437880.0,
        "市盈率": -11.35,
        "成交量": 30531.0,
        "成交额": 811416.0,
        "振幅": 5.43,
        "换手率": 0.27,
        "symbol": "105.ATLX"
    },
    {
        "序号": 913,
        "name": "Valaris Ltd Wt",
        "最新价": 10.8,
        "涨跌额": 0.31,
        "涨跌幅": 2.96,
        "开盘价": 10.56,
        "最高价": 10.8,
        "最低价": 10.56,
        "昨收价": 10.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 3892.0,
        "成交额": 41768.0,
        "振幅": 2.29,
        "换手率": null,
        "symbol": "106.VAL_WS"
    },
    {
        "序号": 914,
        "name": "施乐",
        "最新价": 15.04,
        "涨跌额": 0.43,
        "涨跌幅": 2.94,
        "开盘价": 14.57,
        "最高价": 15.07,
        "最低价": 14.52,
        "昨收价": 14.61,
        "总市值": 1848506225.0,
        "市盈率": 10.27,
        "成交量": 2229428.0,
        "成交额": 33245372.0,
        "振幅": 3.76,
        "换手率": 1.81,
        "symbol": "105.XRX"
    },
    {
        "序号": 915,
        "name": "Netcapital Inc",
        "最新价": 0.35,
        "涨跌额": 0.01,
        "涨跌幅": 2.94,
        "开盘价": 0.35,
        "最高价": 0.36,
        "最低价": 0.34,
        "昨收价": 0.34,
        "总市值": 3310696.0,
        "市盈率": 1.38,
        "成交量": 42903.0,
        "成交额": 15028.0,
        "振幅": 5.88,
        "换手率": 0.45,
        "symbol": "105.NCPL"
    },
    {
        "序号": 916,
        "name": "Laird Superfood Inc",
        "最新价": 1.05,
        "涨跌额": 0.03,
        "涨跌幅": 2.94,
        "开盘价": 0.99,
        "最高价": 1.15,
        "最低价": 0.98,
        "昨收价": 1.02,
        "总市值": 9816917.0,
        "市盈率": -0.38,
        "成交量": 103819.0,
        "成交额": 108531.0,
        "振幅": 16.67,
        "换手率": 1.11,
        "symbol": "107.LSF"
    },
    {
        "序号": 917,
        "name": "加利西亚金融",
        "最新价": 17.5,
        "涨跌额": 0.5,
        "涨跌幅": 2.94,
        "开盘价": 16.98,
        "最高价": 17.62,
        "最低价": 16.8,
        "昨收价": 17.0,
        "总市值": 2580711159.0,
        "市盈率": 8.86,
        "成交量": 808276.0,
        "成交额": 14012665.0,
        "振幅": 4.82,
        "换手率": 0.55,
        "symbol": "105.GGAL"
    },
    {
        "序号": 918,
        "name": "FAT Brands Inc-B",
        "最新价": 5.25,
        "涨跌额": 0.15,
        "涨跌幅": 2.94,
        "开盘价": 5.25,
        "最高价": 5.25,
        "最低价": 5.25,
        "昨收价": 5.1,
        "总市值": 88143946.0,
        "市盈率": -0.65,
        "成交量": 393.0,
        "成交额": 2063.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "105.FATBB"
    },
    {
        "序号": 919,
        "name": "ARK Next Generation Internet ET",
        "最新价": 71.9,
        "涨跌额": 2.05,
        "涨跌幅": 2.93,
        "开盘价": 69.88,
        "最高价": 71.96,
        "最低价": 69.7,
        "昨收价": 69.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 302837.0,
        "成交额": 21611186.0,
        "振幅": 3.24,
        "换手率": null,
        "symbol": "107.ARKW"
    },
    {
        "序号": 920,
        "name": "Kodiak Sciences Inc",
        "最新价": 3.16,
        "涨跌额": 0.09,
        "涨跌幅": 2.93,
        "开盘价": 3.04,
        "最高价": 3.36,
        "最低价": 2.97,
        "昨收价": 3.07,
        "总市值": 165812440.0,
        "市盈率": -0.61,
        "成交量": 817567.0,
        "成交额": 2623600.0,
        "振幅": 12.7,
        "换手率": 1.56,
        "symbol": "105.KOD"
    },
    {
        "序号": 921,
        "name": "Ivanhoe Electric Inc",
        "最新价": 10.56,
        "涨跌额": 0.3,
        "涨跌幅": 2.92,
        "开盘价": 10.2,
        "最高价": 10.63,
        "最低价": 10.06,
        "昨收价": 10.26,
        "总市值": 1257038798.0,
        "市盈率": -6.55,
        "成交量": 307885.0,
        "成交额": 3197463.0,
        "振幅": 5.56,
        "换手率": 0.26,
        "symbol": "107.IE"
    },
    {
        "序号": 922,
        "name": "Enlivex Therapeutics Ltd",
        "最新价": 1.76,
        "涨跌额": 0.05,
        "涨跌幅": 2.92,
        "开盘价": 1.71,
        "最高价": 1.76,
        "最低价": 1.7,
        "昨收价": 1.71,
        "总市值": 32733457.0,
        "市盈率": -1.21,
        "成交量": 19436.0,
        "成交额": 33305.0,
        "振幅": 3.51,
        "换手率": 0.1,
        "symbol": "105.ENLV"
    },
    {
        "序号": 923,
        "name": "MorphoSys AG ADR",
        "最新价": 7.05,
        "涨跌额": 0.2,
        "涨跌幅": 2.92,
        "开盘价": 6.63,
        "最高价": 7.2,
        "最低价": 6.6,
        "昨收价": 6.85,
        "总市值": 963631901.0,
        "市盈率": 11.55,
        "成交量": 1327915.0,
        "成交额": 9173792.0,
        "振幅": 8.76,
        "换手率": 0.97,
        "symbol": "105.MOR"
    },
    {
        "序号": 924,
        "name": "LiveWire Group Inc",
        "最新价": 10.94,
        "涨跌额": 0.31,
        "涨跌幅": 2.92,
        "开盘价": 10.71,
        "最高价": 11.05,
        "最低价": 10.71,
        "昨收价": 10.63,
        "总市值": 2215828603.0,
        "市盈率": -22.41,
        "成交量": 64519.0,
        "成交额": 706140.0,
        "振幅": 3.2,
        "换手率": 0.03,
        "symbol": "106.LVWR"
    },
    {
        "序号": 925,
        "name": "Cognyte Software Ltd",
        "最新价": 5.65,
        "涨跌额": 0.16,
        "涨跌幅": 2.91,
        "开盘价": 5.49,
        "最高价": 5.71,
        "最低价": 5.42,
        "昨收价": 5.49,
        "总市值": 397295525.0,
        "市盈率": -5.44,
        "成交量": 340942.0,
        "成交额": 1912789.0,
        "振幅": 5.28,
        "换手率": 0.48,
        "symbol": "105.CGNT"
    },
    {
        "序号": 926,
        "name": "Direxion Daily NVDA Bull 1.5X S",
        "最新价": 26.5,
        "涨跌额": 0.75,
        "涨跌幅": 2.91,
        "开盘价": 25.71,
        "最高价": 26.67,
        "最低价": 25.67,
        "昨收价": 25.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 215709.0,
        "成交额": 5687313.0,
        "振幅": 3.88,
        "换手率": null,
        "symbol": "105.NVDU"
    },
    {
        "序号": 927,
        "name": "Duos Technologies Group Inc",
        "最新价": 3.18,
        "涨跌额": 0.09,
        "涨跌幅": 2.91,
        "开盘价": 3.09,
        "最高价": 3.18,
        "最低价": 2.91,
        "昨收价": 3.09,
        "总市值": 23045877.0,
        "市盈率": -2.55,
        "成交量": 22999.0,
        "成交额": 70319.0,
        "振幅": 8.74,
        "换手率": 0.32,
        "symbol": "105.DUOT"
    },
    {
        "序号": 928,
        "name": "REX American Resources Corp",
        "最新价": 42.41,
        "涨跌额": 1.2,
        "涨跌幅": 2.91,
        "开盘价": 41.54,
        "最高价": 42.63,
        "最低价": 41.03,
        "昨收价": 41.21,
        "总市值": 742333825.0,
        "市盈率": 15.3,
        "成交量": 85969.0,
        "成交额": 3620236.0,
        "振幅": 3.88,
        "换手率": 0.49,
        "symbol": "106.REX"
    },
    {
        "序号": 929,
        "name": "MicroSectors FANG Index 3X Leve",
        "最新价": 203.62,
        "涨跌额": 5.76,
        "涨跌幅": 2.91,
        "开盘价": 194.84,
        "最高价": 204.36,
        "最低价": 194.84,
        "昨收价": 197.86,
        "总市值": 146791523363.0,
        "市盈率": 46.27,
        "成交量": 1463858.0,
        "成交额": 294039392.0,
        "振幅": 4.81,
        "换手率": null,
        "symbol": "107.FNGU"
    },
    {
        "序号": 930,
        "name": "GraniteShares 1.5x Long NVDA Da",
        "最新价": 94.34,
        "涨跌额": 2.66,
        "涨跌幅": 2.9,
        "开盘价": 91.55,
        "最高价": 94.97,
        "最低价": 91.55,
        "昨收价": 91.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 369397.0,
        "成交额": 34693135.0,
        "振幅": 3.73,
        "换手率": null,
        "symbol": "105.NVDL"
    },
    {
        "序号": 931,
        "name": "VBI Vaccines Inc",
        "最新价": 0.71,
        "涨跌额": 0.02,
        "涨跌幅": 2.9,
        "开盘价": 0.7,
        "最高价": 0.72,
        "最低价": 0.69,
        "昨收价": 0.69,
        "总市值": 16818263.0,
        "市盈率": -0.15,
        "成交量": 73444.0,
        "成交额": 52144.0,
        "振幅": 4.35,
        "换手率": 0.31,
        "symbol": "105.VBIV"
    },
    {
        "序号": 932,
        "name": "盈透证券",
        "最新价": 83.15,
        "涨跌额": 2.34,
        "涨跌幅": 2.9,
        "开盘价": 81.15,
        "最高价": 83.91,
        "最低价": 81.15,
        "昨收价": 80.81,
        "总市值": 8898333503.0,
        "市盈率": 15.45,
        "成交量": 1827503.0,
        "成交额": 152010943.0,
        "振幅": 3.42,
        "换手率": 1.71,
        "symbol": "105.IBKR"
    },
    {
        "序号": 933,
        "name": "The Duckhorn Portfolio Inc",
        "最新价": 9.24,
        "涨跌额": 0.26,
        "涨跌幅": 2.9,
        "开盘价": 9.0,
        "最高价": 9.31,
        "最低价": 8.93,
        "昨收价": 8.98,
        "总市值": 1065997640.0,
        "市盈率": 16.39,
        "成交量": 1358255.0,
        "成交额": 12481786.0,
        "振幅": 4.23,
        "换手率": 1.18,
        "symbol": "106.NAPA"
    },
    {
        "序号": 934,
        "name": "DMC Global Inc",
        "最新价": 17.07,
        "涨跌额": 0.48,
        "涨跌幅": 2.89,
        "开盘价": 16.84,
        "最高价": 17.38,
        "最低价": 16.84,
        "昨收价": 16.59,
        "总市值": 337330188.0,
        "市盈率": 12.61,
        "成交量": 156042.0,
        "成交额": 2661460.0,
        "振幅": 3.25,
        "换手率": 0.79,
        "symbol": "105.BOOM"
    },
    {
        "序号": 935,
        "name": "Emergent BioSolutions Inc",
        "最新价": 2.49,
        "涨跌额": 0.07,
        "涨跌幅": 2.89,
        "开盘价": 2.4,
        "最高价": 2.6,
        "最低价": 2.31,
        "昨收价": 2.42,
        "总市值": 128999497.0,
        "市盈率": -0.21,
        "成交量": 804757.0,
        "成交额": 2005499.0,
        "振幅": 11.98,
        "换手率": 1.55,
        "symbol": "106.EBS"
    },
    {
        "序号": 936,
        "name": "Customers Bancorp Inc",
        "最新价": 50.2,
        "涨跌额": 1.41,
        "涨跌幅": 2.89,
        "开盘价": 48.67,
        "最高价": 50.42,
        "最低价": 48.67,
        "昨收价": 48.79,
        "总市值": 1576980089.0,
        "市盈率": 7.28,
        "成交量": 288381.0,
        "成交额": 14431420.0,
        "振幅": 3.59,
        "换手率": 0.92,
        "symbol": "106.CUBI"
    },
    {
        "序号": 937,
        "name": "凯撒娱乐",
        "最新价": 44.19,
        "涨跌额": 1.24,
        "涨跌幅": 2.89,
        "开盘价": 42.9,
        "最高价": 44.64,
        "最低价": 42.74,
        "昨收价": 42.95,
        "总市值": 9532255214.0,
        "市盈率": 13.43,
        "成交量": 5365707.0,
        "成交额": 234504478.0,
        "振幅": 4.42,
        "换手率": 2.49,
        "symbol": "105.CZR"
    },
    {
        "序号": 938,
        "name": "箭牌金融",
        "最新价": 27.81,
        "涨跌额": 0.78,
        "涨跌幅": 2.89,
        "开盘价": 27.03,
        "最高价": 27.9,
        "最低价": 27.03,
        "昨收价": 27.03,
        "总市值": 474306947.0,
        "市盈率": 13.77,
        "成交量": 51876.0,
        "成交额": 1437127.0,
        "振幅": 3.22,
        "换手率": 0.3,
        "symbol": "105.AROW"
    },
    {
        "序号": 939,
        "name": "Fusion Fuel Green PLC-A",
        "最新价": 1.07,
        "涨跌额": 0.03,
        "涨跌幅": 2.88,
        "开盘价": 1.07,
        "最高价": 1.08,
        "最低价": 1.04,
        "昨收价": 1.04,
        "总市值": 15963347.0,
        "市盈率": -0.55,
        "成交量": 75482.0,
        "成交额": 79863.0,
        "振幅": 3.85,
        "换手率": 0.51,
        "symbol": "105.HTOO"
    },
    {
        "序号": 940,
        "name": "GMS Inc",
        "最新价": 71.89,
        "涨跌额": 2.01,
        "涨跌幅": 2.88,
        "开盘价": 70.93,
        "最高价": 72.02,
        "最低价": 69.71,
        "昨收价": 69.88,
        "总市值": 2862491865.0,
        "市盈率": 9.29,
        "成交量": 515508.0,
        "成交额": 36821354.0,
        "振幅": 3.31,
        "换手率": 1.29,
        "symbol": "106.GMS"
    },
    {
        "序号": 941,
        "name": "Madison Square Garden Entertain",
        "最新价": 32.2,
        "涨跌额": 0.9,
        "涨跌幅": 2.88,
        "开盘价": 31.44,
        "最高价": 32.27,
        "最低价": 31.12,
        "昨收价": 31.3,
        "总市值": 1544630072.0,
        "市盈率": 35.24,
        "成交量": 581217.0,
        "成交额": 18572332.0,
        "振幅": 3.67,
        "换手率": 1.21,
        "symbol": "106.MSGE"
    },
    {
        "序号": 942,
        "name": "Calix Inc",
        "最新价": 41.9,
        "涨跌额": 1.17,
        "涨跌幅": 2.87,
        "开盘价": 40.71,
        "最高价": 42.14,
        "最低价": 40.56,
        "昨收价": 40.73,
        "总市值": 2759829144.0,
        "市盈率": 57.68,
        "成交量": 522698.0,
        "成交额": 21802318.0,
        "振幅": 3.88,
        "换手率": 0.79,
        "symbol": "106.CALX"
    },
    {
        "序号": 943,
        "name": "伊莱克森",
        "最新价": 3.94,
        "涨跌额": 0.11,
        "涨跌幅": 2.87,
        "开盘价": 3.77,
        "最高价": 3.94,
        "最低价": 3.75,
        "昨收价": 3.83,
        "总市值": 13506403.0,
        "市盈率": 38.48,
        "成交量": 1020.0,
        "成交额": 3892.0,
        "振幅": 4.96,
        "换手率": 0.03,
        "symbol": "105.ELSE"
    },
    {
        "序号": 944,
        "name": "loanDepot Inc-A",
        "最新价": 2.15,
        "涨跌额": 0.06,
        "涨跌幅": 2.87,
        "开盘价": 2.09,
        "最高价": 2.18,
        "最低价": 2.09,
        "昨收价": 2.09,
        "总市值": 690295372.0,
        "市盈率": -4.31,
        "成交量": 212339.0,
        "成交额": 453589.0,
        "振幅": 4.31,
        "换手率": 0.07,
        "symbol": "106.LDI"
    },
    {
        "序号": 945,
        "name": "NuScale Power Corp-A",
        "最新价": 3.23,
        "涨跌额": 0.09,
        "涨跌幅": 2.87,
        "开盘价": 3.17,
        "最高价": 3.48,
        "最低价": 3.13,
        "昨收价": 3.14,
        "总市值": 747202444.0,
        "市盈率": -14.31,
        "成交量": 1946975.0,
        "成交额": 6416363.0,
        "振幅": 11.15,
        "换手率": 0.84,
        "symbol": "106.SMR"
    },
    {
        "序号": 946,
        "name": "巴西石油-优先股",
        "最新价": 14.01,
        "涨跌额": 0.39,
        "涨跌幅": 2.86,
        "开盘价": 13.71,
        "最高价": 14.01,
        "最低价": 13.66,
        "昨收价": 13.62,
        "总市值": 39040505424.0,
        "市盈率": 1.94,
        "成交量": 9577043.0,
        "成交额": 133233835.0,
        "振幅": 2.57,
        "换手率": 0.34,
        "symbol": "106.PBR_A"
    },
    {
        "序号": 947,
        "name": "TFF Pharmaceuticals Inc",
        "最新价": 0.36,
        "涨跌额": 0.01,
        "涨跌幅": 2.86,
        "开盘价": 0.37,
        "最高价": 0.38,
        "最低价": 0.33,
        "昨收价": 0.35,
        "总市值": 21288087.0,
        "市盈率": -0.89,
        "成交量": 59188.0,
        "成交额": 21349.0,
        "振幅": 14.29,
        "换手率": 0.1,
        "symbol": "105.TFFP"
    },
    {
        "序号": 948,
        "name": "Solid Power Inc",
        "最新价": 1.44,
        "涨跌额": 0.04,
        "涨跌幅": 2.86,
        "开盘价": 1.4,
        "最高价": 1.47,
        "最低价": 1.39,
        "昨收价": 1.4,
        "总市值": 256949808.0,
        "市盈率": -5.47,
        "成交量": 1137392.0,
        "成交额": 1629867.0,
        "振幅": 5.71,
        "换手率": 0.64,
        "symbol": "105.SLDP"
    },
    {
        "序号": 949,
        "name": "Akerna Corp",
        "最新价": 0.36,
        "涨跌额": 0.01,
        "涨跌幅": 2.86,
        "开盘价": 0.34,
        "最高价": 0.36,
        "最低价": 0.33,
        "昨收价": 0.35,
        "总市值": 3690726.0,
        "市盈率": -0.11,
        "成交量": 317023.0,
        "成交额": 112022.0,
        "振幅": 8.57,
        "换手率": 3.09,
        "symbol": "105.KERN"
    },
    {
        "序号": 950,
        "name": "InflaRx NV",
        "最新价": 1.44,
        "涨跌额": 0.04,
        "涨跌幅": 2.86,
        "开盘价": 1.37,
        "最高价": 1.5,
        "最低价": 1.35,
        "昨收价": 1.4,
        "总市值": 84791912.0,
        "市盈率": -2.2,
        "成交量": 272254.0,
        "成交额": 391050.0,
        "振幅": 10.71,
        "换手率": 0.46,
        "symbol": "105.IFRX"
    },
    {
        "序号": 951,
        "name": "Carmell Therapeutics Corp-A",
        "最新价": 3.24,
        "涨跌额": 0.09,
        "涨跌幅": 2.86,
        "开盘价": 3.1,
        "最高价": 3.25,
        "最低价": 3.1,
        "昨收价": 3.15,
        "总市值": 74784520.0,
        "市盈率": -6.0,
        "成交量": 17747.0,
        "成交额": 56997.0,
        "振幅": 4.76,
        "换手率": 0.08,
        "symbol": "105.CTCX"
    },
    {
        "序号": 952,
        "name": "Blackboxstocks Inc",
        "最新价": 3.96,
        "涨跌额": 0.11,
        "涨跌幅": 2.86,
        "开盘价": 3.78,
        "最高价": 3.97,
        "最低价": 3.75,
        "昨收价": 3.85,
        "总市值": 12663347.0,
        "市盈率": -2.46,
        "成交量": 12458.0,
        "成交额": 48783.0,
        "振幅": 5.71,
        "换手率": 0.39,
        "symbol": "105.BLBX"
    },
    {
        "序号": 953,
        "name": "Thryv Holdings Inc",
        "最新价": 19.18,
        "涨跌额": 0.53,
        "涨跌幅": 2.84,
        "开盘价": 18.58,
        "最高价": 19.24,
        "最低价": 18.39,
        "昨收价": 18.65,
        "总市值": 674465026.0,
        "市盈率": -12.92,
        "成交量": 146557.0,
        "成交额": 2792954.0,
        "振幅": 4.56,
        "换手率": 0.42,
        "symbol": "105.THRY"
    },
    {
        "序号": 954,
        "name": "ProShares Bitcoin Strategy ETF",
        "最新价": 22.09,
        "涨跌额": 0.61,
        "涨跌幅": 2.84,
        "开盘价": 21.63,
        "最高价": 22.13,
        "最低价": 21.63,
        "昨收价": 21.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 17981619.0,
        "成交额": 393349056.0,
        "振幅": 2.33,
        "换手率": null,
        "symbol": "107.BITO"
    },
    {
        "序号": 955,
        "name": "世纪赌场",
        "最新价": 4.35,
        "涨跌额": 0.12,
        "涨跌幅": 2.84,
        "开盘价": 4.23,
        "最高价": 4.37,
        "最低价": 4.22,
        "昨收价": 4.23,
        "总市值": 132065700.0,
        "市盈率": -6.17,
        "成交量": 49299.0,
        "成交额": 212251.0,
        "振幅": 3.55,
        "换手率": 0.16,
        "symbol": "105.CNTY"
    },
    {
        "序号": 956,
        "name": "Bitwise Funds Trust Bitwise Web",
        "最新价": 39.89,
        "涨跌额": 1.1,
        "涨跌幅": 2.84,
        "开盘价": 39.89,
        "最高价": 39.89,
        "最低价": 39.89,
        "昨收价": 38.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 55.0,
        "成交额": 2193.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BWEB"
    },
    {
        "序号": 957,
        "name": "51Talk",
        "最新价": 7.98,
        "涨跌额": 0.22,
        "涨跌幅": 2.84,
        "开盘价": 7.85,
        "最高价": 7.98,
        "最低价": 7.66,
        "昨收价": 7.76,
        "总市值": 45182169.0,
        "市盈率": -4.38,
        "成交量": 2773.0,
        "成交额": 21663.0,
        "振幅": 4.12,
        "换手率": 0.05,
        "symbol": "107.COE"
    },
    {
        "序号": 958,
        "name": "Tecnoglass Inc",
        "最新价": 39.55,
        "涨跌额": 1.09,
        "涨跌幅": 2.83,
        "开盘价": 38.05,
        "最高价": 39.59,
        "最低价": 38.05,
        "昨收价": 38.46,
        "总市值": 1885537272.0,
        "市盈率": 9.36,
        "成交量": 326841.0,
        "成交额": 12854746.0,
        "振幅": 4.0,
        "换手率": 0.69,
        "symbol": "106.TGLS"
    },
    {
        "序号": 959,
        "name": "Excelerate Energy Inc-A",
        "最新价": 16.33,
        "涨跌额": 0.45,
        "涨跌幅": 2.83,
        "开盘价": 15.96,
        "最高价": 16.38,
        "最低价": 15.96,
        "昨收价": 15.88,
        "总市值": 1768254123.0,
        "市盈率": 53.21,
        "成交量": 115079.0,
        "成交额": 1872577.0,
        "振幅": 2.64,
        "换手率": 0.11,
        "symbol": "106.EE"
    },
    {
        "序号": 960,
        "name": "ContextLogic Inc-A",
        "最新价": 5.45,
        "涨跌额": 0.15,
        "涨跌幅": 2.83,
        "开盘价": 5.3,
        "最高价": 5.48,
        "最低价": 5.0,
        "昨收价": 5.3,
        "总市值": 130849050.0,
        "市盈率": -0.36,
        "成交量": 828232.0,
        "成交额": 4380041.0,
        "振幅": 9.06,
        "换手率": 3.45,
        "symbol": "105.WISH"
    },
    {
        "序号": 961,
        "name": "哥伦比亚国家石油",
        "最新价": 12.72,
        "涨跌额": 0.35,
        "涨跌幅": 2.83,
        "开盘价": 12.45,
        "最高价": 12.73,
        "最低价": 12.44,
        "昨收价": 12.37,
        "总市值": 26150217823.0,
        "市盈率": 6.01,
        "成交量": 2384894.0,
        "成交额": 30158748.0,
        "振幅": 2.34,
        "换手率": 0.12,
        "symbol": "106.EC"
    },
    {
        "序号": 962,
        "name": "fuboTV Inc",
        "最新价": 3.28,
        "涨跌额": 0.09,
        "涨跌幅": 2.82,
        "开盘价": 3.17,
        "最高价": 3.31,
        "最低价": 3.15,
        "昨收价": 3.19,
        "总市值": 960402109.0,
        "市盈率": -2.6,
        "成交量": 7830402.0,
        "成交额": 25396554.0,
        "振幅": 5.02,
        "换手率": 2.67,
        "symbol": "106.FUBO"
    },
    {
        "序号": 963,
        "name": "DiaMedica Therapeutics Inc",
        "最新价": 2.92,
        "涨跌额": 0.08,
        "涨跌幅": 2.82,
        "开盘价": 2.92,
        "最高价": 2.96,
        "最低价": 2.73,
        "昨收价": 2.84,
        "总市值": 110824836.0,
        "市盈率": -6.17,
        "成交量": 43380.0,
        "成交额": 124487.0,
        "振幅": 8.1,
        "换手率": 0.11,
        "symbol": "105.DMAC"
    },
    {
        "序号": 964,
        "name": "Embecta Corp",
        "最新价": 17.9,
        "涨跌额": 0.49,
        "涨跌幅": 2.81,
        "开盘价": 17.41,
        "最高价": 18.22,
        "最低价": 17.41,
        "昨收价": 17.41,
        "总市值": 1026289716.0,
        "市盈率": 14.58,
        "成交量": 330374.0,
        "成交额": 5903955.0,
        "振幅": 4.65,
        "换手率": 0.58,
        "symbol": "105.EMBC"
    },
    {
        "序号": 965,
        "name": "The India Fund",
        "最新价": 17.94,
        "涨跌额": 0.49,
        "涨跌幅": 2.81,
        "开盘价": 17.48,
        "最高价": 18.0,
        "最低价": 17.4,
        "昨收价": 17.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 165287.0,
        "成交额": 2927638.0,
        "振幅": 3.44,
        "换手率": null,
        "symbol": "106.IFN"
    },
    {
        "序号": 966,
        "name": "宝尊电商",
        "最新价": 2.93,
        "涨跌额": 0.08,
        "涨跌幅": 2.81,
        "开盘价": 2.84,
        "最高价": 2.99,
        "最低价": 2.8,
        "昨收价": 2.85,
        "总市值": 176200041.0,
        "市盈率": -2.39,
        "成交量": 944061.0,
        "成交额": 2759555.0,
        "振幅": 6.67,
        "换手率": 1.57,
        "symbol": "105.BZUN"
    },
    {
        "序号": 967,
        "name": "Zentek Ltd",
        "最新价": 1.1,
        "涨跌额": 0.03,
        "涨跌幅": 2.8,
        "开盘价": 1.11,
        "最高价": 1.15,
        "最低价": 1.09,
        "昨收价": 1.07,
        "总市值": 110970331.0,
        "市盈率": -11.85,
        "成交量": 7804.0,
        "成交额": 8625.0,
        "振幅": 5.61,
        "换手率": 0.01,
        "symbol": "105.ZTEK"
    },
    {
        "序号": 968,
        "name": "Notable Labs Ltd",
        "最新价": 3.3,
        "涨跌额": 0.09,
        "涨跌幅": 2.8,
        "开盘价": 3.22,
        "最高价": 3.42,
        "最低价": 3.19,
        "昨收价": 3.21,
        "总市值": 29704356.0,
        "市盈率": -3.78,
        "成交量": 8573.0,
        "成交额": 27569.0,
        "振幅": 7.17,
        "换手率": 0.1,
        "symbol": "105.NTBL"
    },
    {
        "序号": 969,
        "name": "Garrett Motion Inc",
        "最新价": 8.07,
        "涨跌额": 0.22,
        "涨跌幅": 2.8,
        "开盘价": 7.85,
        "最高价": 8.18,
        "最低价": 7.77,
        "昨收价": 7.85,
        "总市值": 1956327495.0,
        "市盈率": 6.09,
        "成交量": 1706280.0,
        "成交额": 13756624.0,
        "振幅": 5.22,
        "换手率": 0.7,
        "symbol": "105.GTX"
    },
    {
        "序号": 970,
        "name": "InspireMD Inc",
        "最新价": 2.57,
        "涨跌额": 0.07,
        "涨跌幅": 2.8,
        "开盘价": 2.7,
        "最高价": 2.74,
        "最低价": 2.5,
        "昨收价": 2.5,
        "总市值": 55382572.0,
        "市盈率": -2.86,
        "成交量": 55846.0,
        "成交额": 147232.0,
        "振幅": 9.6,
        "换手率": 0.26,
        "symbol": "105.NSPR"
    },
    {
        "序号": 971,
        "name": "美国伍德马克",
        "最新价": 88.19,
        "涨跌额": 2.4,
        "涨跌幅": 2.8,
        "开盘价": 86.96,
        "最高价": 88.65,
        "最低价": 86.67,
        "昨收价": 85.79,
        "总市值": 1413368040.0,
        "市盈率": 12.5,
        "成交量": 120865.0,
        "成交额": 10629094.0,
        "振幅": 2.31,
        "换手率": 0.75,
        "symbol": "105.AMWD"
    },
    {
        "序号": 972,
        "name": "Redwood Trust Inc Series A Pfd",
        "最新价": 24.26,
        "涨跌额": 0.66,
        "涨跌幅": 2.8,
        "开盘价": 23.95,
        "最高价": 24.36,
        "最低价": 23.95,
        "昨收价": 23.6,
        "总市值": 67928000.0,
        "市盈率": null,
        "成交量": 6201.0,
        "成交额": 149502.0,
        "振幅": 1.74,
        "换手率": 0.22,
        "symbol": "106.RWT_A"
    },
    {
        "序号": 973,
        "name": "三倍做空20年+国债ETF-Direxion",
        "最新价": 34.57,
        "涨跌额": 0.94,
        "涨跌幅": 2.8,
        "开盘价": 34.71,
        "最高价": 35.22,
        "最低价": 34.37,
        "昨收价": 33.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 5017453.0,
        "成交额": 174382373.0,
        "振幅": 2.53,
        "换手率": null,
        "symbol": "107.TMV"
    },
    {
        "序号": 974,
        "name": "Express Inc",
        "最新价": 8.47,
        "涨跌额": 0.23,
        "涨跌幅": 2.79,
        "开盘价": 8.28,
        "最高价": 8.49,
        "最低价": 8.21,
        "昨收价": 8.24,
        "总市值": 31730805.0,
        "市盈率": 0.18,
        "成交量": 58900.0,
        "成交额": 492158.0,
        "振幅": 3.4,
        "换手率": 1.57,
        "symbol": "106.EXPR"
    },
    {
        "序号": 975,
        "name": "Ventyx Biosciences Inc",
        "最新价": 2.21,
        "涨跌额": 0.06,
        "涨跌幅": 2.79,
        "开盘价": 2.16,
        "最高价": 2.25,
        "最低价": 2.11,
        "昨收价": 2.15,
        "总市值": 130496319.0,
        "市盈率": -0.72,
        "成交量": 1194255.0,
        "成交额": 2620820.0,
        "振幅": 6.51,
        "换手率": 2.02,
        "symbol": "105.VTYX"
    },
    {
        "序号": 976,
        "name": "indie Semiconductor Inc-A",
        "最新价": 7.75,
        "涨跌额": 0.21,
        "涨跌幅": 2.79,
        "开盘价": 7.5,
        "最高价": 7.76,
        "最低价": 7.41,
        "昨收价": 7.54,
        "总市值": 1397312980.0,
        "市盈率": -12.11,
        "成交量": 1903236.0,
        "成交额": 14593597.0,
        "振幅": 4.64,
        "换手率": 1.06,
        "symbol": "105.INDI"
    },
    {
        "序号": 977,
        "name": "Purple Biotech Ltd ADR",
        "最新价": 1.11,
        "涨跌额": 0.03,
        "涨跌幅": 2.78,
        "开盘价": 1.08,
        "最高价": 1.16,
        "最低价": 1.08,
        "昨收价": 1.08,
        "总市值": 27149360.0,
        "市盈率": -1.29,
        "成交量": 76538.0,
        "成交额": 86550.0,
        "振幅": 7.41,
        "换手率": 0.31,
        "symbol": "105.PPBT"
    },
    {
        "序号": 978,
        "name": "德久普",
        "最新价": 21.52,
        "涨跌额": 0.58,
        "涨跌幅": 2.77,
        "开盘价": 21.15,
        "最高价": 21.56,
        "最低价": 21.1,
        "昨收价": 20.94,
        "总市值": 735619301.0,
        "市盈率": 1743.17,
        "成交量": 173022.0,
        "成交额": 3701861.0,
        "振幅": 2.2,
        "换手率": 0.51,
        "symbol": "106.DRQ"
    },
    {
        "序号": 979,
        "name": "SmartRent Inc-A",
        "最新价": 3.34,
        "涨跌额": 0.09,
        "涨跌幅": 2.77,
        "开盘价": 3.24,
        "最高价": 3.38,
        "最低价": 3.23,
        "昨收价": 3.25,
        "总市值": 678537543.0,
        "市盈率": -12.88,
        "成交量": 1358718.0,
        "成交额": 4539710.0,
        "振幅": 4.62,
        "换手率": 0.67,
        "symbol": "106.SMRT"
    },
    {
        "序号": 980,
        "name": "Crescent Energy Co-A",
        "最新价": 11.51,
        "涨跌额": 0.31,
        "涨跌幅": 2.77,
        "开盘价": 11.3,
        "最高价": 11.51,
        "最低价": 11.12,
        "昨收价": 11.2,
        "总市值": 2067851195.0,
        "市盈率": 99.11,
        "成交量": 736719.0,
        "成交额": 8428102.0,
        "振幅": 3.48,
        "换手率": 0.41,
        "symbol": "106.CRGY"
    },
    {
        "序号": 981,
        "name": "PlayStudios Inc-A",
        "最新价": 2.6,
        "涨跌额": 0.07,
        "涨跌幅": 2.77,
        "开盘价": 2.55,
        "最高价": 2.62,
        "最低价": 2.54,
        "昨收价": 2.53,
        "总市值": 348480353.0,
        "市盈率": -282.86,
        "成交量": 307040.0,
        "成交额": 795396.0,
        "振幅": 3.16,
        "换手率": 0.23,
        "symbol": "105.MYPS"
    },
    {
        "序号": 982,
        "name": "FTAI Aviation Ltd-A",
        "最新价": 43.87,
        "涨跌额": 1.18,
        "涨跌幅": 2.76,
        "开盘价": 42.92,
        "最高价": 43.91,
        "最低价": 42.8,
        "昨收价": 42.69,
        "总市值": 4397444350.0,
        "市盈率": 28.88,
        "成交量": 651500.0,
        "成交额": 28411287.0,
        "振幅": 2.6,
        "换手率": 0.65,
        "symbol": "105.FTAI"
    },
    {
        "序号": 983,
        "name": "MicroSectors U.S. Big Oil Index",
        "最新价": 372.99,
        "涨跌额": 10.01,
        "涨跌幅": 2.76,
        "开盘价": 370.59,
        "最高价": 376.0,
        "最低价": 366.85,
        "昨收价": 362.98,
        "总市值": 268891907961.0,
        "市盈率": 84.75,
        "成交量": 41085.0,
        "成交额": 15256918.0,
        "振幅": 2.52,
        "换手率": null,
        "symbol": "107.NRGU"
    },
    {
        "序号": 984,
        "name": "Bitwise Bitcoin Strategy Optimu",
        "最新价": 37.65,
        "涨跌额": 1.01,
        "涨跌幅": 2.76,
        "开盘价": 36.94,
        "最高价": 37.65,
        "最低价": 33.86,
        "昨收价": 36.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 18137.0,
        "成交额": 667514.0,
        "振幅": 10.34,
        "换手率": null,
        "symbol": "107.BITC"
    },
    {
        "序号": 985,
        "name": "Stifel Financial Corp",
        "最新价": 65.03,
        "涨跌额": 1.74,
        "涨跌幅": 2.75,
        "开盘价": 63.19,
        "最高价": 65.06,
        "最低价": 62.88,
        "昨收价": 63.29,
        "总市值": 6632798449.0,
        "市盈率": 12.36,
        "成交量": 455266.0,
        "成交额": 29432869.0,
        "振幅": 3.44,
        "换手率": 0.45,
        "symbol": "106.SF"
    },
    {
        "序号": 986,
        "name": "Mativ Inc",
        "最新价": 12.36,
        "涨跌额": 0.33,
        "涨跌幅": 2.74,
        "开盘价": 12.03,
        "最高价": 12.59,
        "最低价": 12.03,
        "昨收价": 12.03,
        "总市值": 669920405.0,
        "市盈率": -1.44,
        "成交量": 401328.0,
        "成交额": 4964032.0,
        "振幅": 4.66,
        "换手率": 0.74,
        "symbol": "106.MATV"
    },
    {
        "序号": 987,
        "name": "淘屏",
        "最新价": 1.5,
        "涨跌额": 0.04,
        "涨跌幅": 2.74,
        "开盘价": 1.49,
        "最高价": 1.55,
        "最低价": 1.49,
        "昨收价": 1.46,
        "总市值": 2957733.0,
        "市盈率": -0.43,
        "成交量": 181813.0,
        "成交额": 276330.0,
        "振幅": 4.11,
        "换手率": 9.22,
        "symbol": "105.TAOP"
    },
    {
        "序号": 988,
        "name": "Sprout Social Inc-A",
        "最新价": 56.25,
        "涨跌额": 1.5,
        "涨跌幅": 2.74,
        "开盘价": 54.15,
        "最高价": 56.78,
        "最低价": 53.79,
        "昨收价": 54.75,
        "总市值": 3149663344.0,
        "市盈率": -54.03,
        "成交量": 387375.0,
        "成交额": 21726082.0,
        "振幅": 5.46,
        "换手率": 0.69,
        "symbol": "105.SPT"
    },
    {
        "序号": 989,
        "name": "BYND Cannasoft Enterprises Inc",
        "最新价": 0.75,
        "涨跌额": 0.02,
        "涨跌幅": 2.74,
        "开盘价": 0.7,
        "最高价": 0.75,
        "最低价": 0.7,
        "昨收价": 0.73,
        "总市值": 29751413.0,
        "市盈率": -9.91,
        "成交量": 13523.0,
        "成交额": 9828.0,
        "振幅": 6.85,
        "换手率": 0.03,
        "symbol": "105.BCAN"
    },
    {
        "序号": 990,
        "name": "Churchill Downs Inc",
        "最新价": 119.89,
        "涨跌额": 3.19,
        "涨跌幅": 2.73,
        "开盘价": 116.27,
        "最高价": 120.27,
        "最低价": 116.27,
        "昨收价": 116.7,
        "总市值": 8943172970.0,
        "市盈率": 24.79,
        "成交量": 239470.0,
        "成交额": 28596066.0,
        "振幅": 3.43,
        "换手率": 0.32,
        "symbol": "105.CHDN"
    },
    {
        "序号": 991,
        "name": "Primech Holdings Ltd",
        "最新价": 1.88,
        "涨跌额": 0.05,
        "涨跌幅": 2.73,
        "开盘价": 1.82,
        "最高价": 2.09,
        "最低价": 1.82,
        "昨收价": 1.83,
        "总市值": 66834000.0,
        "市盈率": -26.4,
        "成交量": 530328.0,
        "成交额": 1054790.0,
        "振幅": 14.75,
        "换手率": 1.49,
        "symbol": "105.PMEC"
    },
    {
        "序号": 992,
        "name": "美国国家银行",
        "最新价": 45.19,
        "涨跌额": 1.2,
        "涨跌幅": 2.73,
        "开盘价": 44.28,
        "最高价": 45.52,
        "最低价": 44.03,
        "昨收价": 43.99,
        "总市值": 480323923.0,
        "市盈率": 15.95,
        "成交量": 18316.0,
        "成交额": 823684.0,
        "振幅": 3.39,
        "换手率": 0.17,
        "symbol": "105.AMNB"
    },
    {
        "序号": 993,
        "name": "VirTra Inc",
        "最新价": 8.67,
        "涨跌额": 0.23,
        "涨跌幅": 2.73,
        "开盘价": 8.6,
        "最高价": 8.8,
        "最低价": 8.45,
        "昨收价": 8.44,
        "总市值": 96190954.0,
        "市盈率": 13.74,
        "成交量": 117502.0,
        "成交额": 1015814.0,
        "振幅": 4.15,
        "换手率": 1.06,
        "symbol": "105.VTSI"
    },
    {
        "序号": 994,
        "name": "通用不锈钢和合金制品",
        "最新价": 17.35,
        "涨跌额": 0.46,
        "涨跌幅": 2.72,
        "开盘价": 17.03,
        "最高价": 17.45,
        "最低价": 16.81,
        "昨收价": 16.89,
        "总市值": 157681051.0,
        "市盈率": -111.75,
        "成交量": 73717.0,
        "成交额": 1265185.0,
        "振幅": 3.79,
        "换手率": 0.81,
        "symbol": "105.USAP"
    },
    {
        "序号": 995,
        "name": "Comfort Systems USA Inc",
        "最新价": 194.01,
        "涨跌额": 5.14,
        "涨跌幅": 2.72,
        "开盘价": 188.5,
        "最高价": 194.56,
        "最低价": 188.5,
        "昨收价": 188.87,
        "总市值": 6929031258.0,
        "市盈率": 24.12,
        "成交量": 354270.0,
        "成交额": 68428217.0,
        "振幅": 3.21,
        "换手率": 0.99,
        "symbol": "106.FIX"
    },
    {
        "序号": 996,
        "name": "MV Oil Trust",
        "最新价": 12.08,
        "涨跌额": 0.32,
        "涨跌幅": 2.72,
        "开盘价": 11.88,
        "最高价": 12.09,
        "最低价": 11.88,
        "昨收价": 11.76,
        "总市值": 138920000.0,
        "市盈率": 6.84,
        "成交量": 21315.0,
        "成交额": 255682.0,
        "振幅": 1.79,
        "换手率": 0.19,
        "symbol": "106.MVO"
    },
    {
        "序号": 997,
        "name": "Crescent Point Energy Corp",
        "最新价": 6.8,
        "涨跌额": 0.18,
        "涨跌幅": 2.72,
        "开盘价": 6.7,
        "最高价": 6.84,
        "最低价": 6.68,
        "昨收价": 6.62,
        "总市值": 3565859147.0,
        "市盈率": -5.54,
        "成交量": 4396619.0,
        "成交额": 29753064.0,
        "振幅": 2.42,
        "换手率": 0.84,
        "symbol": "106.CPG"
    },
    {
        "序号": 998,
        "name": "ARK 21Shares Active Bitcoin Fut",
        "最新价": 45.01,
        "涨跌额": 1.19,
        "涨跌幅": 2.72,
        "开盘价": 44.42,
        "最高价": 45.01,
        "最低价": 44.35,
        "昨收价": 43.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 1455.0,
        "成交额": 64688.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "107.ARKA"
    },
    {
        "序号": 999,
        "name": "Science 37 Holdings Inc",
        "最新价": 4.93,
        "涨跌额": 0.13,
        "涨跌幅": 2.71,
        "开盘价": 3.93,
        "最高价": 5.23,
        "最低价": 3.93,
        "昨收价": 4.8,
        "总市值": 29580000.0,
        "市盈率": -0.24,
        "成交量": 59810.0,
        "成交额": 279079.0,
        "振幅": 27.08,
        "换手率": 1.0,
        "symbol": "105.SNCE"
    },
    {
        "序号": 1000,
        "name": "ProAssurance Corp",
        "最新价": 12.91,
        "涨跌额": 0.34,
        "涨跌幅": 2.7,
        "开盘价": 12.59,
        "最高价": 12.91,
        "最低价": 12.47,
        "昨收价": 12.57,
        "总市值": 658014218.0,
        "市盈率": -21.2,
        "成交量": 337313.0,
        "成交额": 4313607.0,
        "振幅": 3.5,
        "换手率": 0.66,
        "symbol": "106.PRA"
    },
    {
        "序号": 1001,
        "name": "Portage Biotech Inc",
        "最新价": 1.14,
        "涨跌额": 0.03,
        "涨跌幅": 2.7,
        "开盘价": 1.22,
        "最高价": 1.29,
        "最低价": 0.99,
        "昨收价": 1.11,
        "总市值": 22547177.0,
        "市盈率": -0.2,
        "成交量": 100036.0,
        "成交额": 118896.0,
        "振幅": 27.03,
        "换手率": 0.51,
        "symbol": "105.PRTG"
    },
    {
        "序号": 1002,
        "name": "古特拉",
        "最新价": 2.28,
        "涨跌额": 0.06,
        "涨跌幅": 2.7,
        "开盘价": 2.24,
        "最高价": 2.33,
        "最低价": 2.15,
        "昨收价": 2.22,
        "总市值": 45472035.0,
        "市盈率": -0.59,
        "成交量": 564652.0,
        "成交额": 1269428.0,
        "振幅": 8.11,
        "换手率": 2.83,
        "symbol": "105.CUTR"
    },
    {
        "序号": 1003,
        "name": "Assembly Biosciences Inc",
        "最新价": 0.76,
        "涨跌额": 0.02,
        "涨跌幅": 2.7,
        "开盘价": 0.74,
        "最高价": 0.77,
        "最低价": 0.72,
        "昨收价": 0.74,
        "总市值": 49938925.0,
        "市盈率": -0.69,
        "成交量": 215926.0,
        "成交额": 162735.0,
        "振幅": 6.76,
        "换手率": 0.33,
        "symbol": "105.ASMB"
    },
    {
        "序号": 1004,
        "name": "TPG房地产金融信托",
        "最新价": 6.1,
        "涨跌额": 0.16,
        "涨跌幅": 2.69,
        "开盘价": 5.92,
        "最高价": 6.12,
        "最低价": 5.81,
        "昨收价": 5.94,
        "总市值": 474182195.0,
        "市盈率": -5.46,
        "成交量": 493649.0,
        "成交额": 2983182.0,
        "振幅": 5.22,
        "换手率": 0.64,
        "symbol": "106.TRTX"
    },
    {
        "序号": 1005,
        "name": "TTM科技",
        "最新价": 14.87,
        "涨跌额": 0.39,
        "涨跌幅": 2.69,
        "开盘价": 14.46,
        "最高价": 14.89,
        "最低价": 14.29,
        "昨收价": 14.48,
        "总市值": 1520377529.0,
        "市盈率": -50.61,
        "成交量": 582910.0,
        "成交额": 8620920.0,
        "振幅": 4.14,
        "换手率": 0.57,
        "symbol": "105.TTMI"
    },
    {
        "序号": 1006,
        "name": "The Trade Desk Inc-A",
        "最新价": 70.63,
        "涨跌额": 1.85,
        "涨跌幅": 2.69,
        "开盘价": 68.18,
        "最高价": 70.72,
        "最低价": 68.02,
        "昨收价": 68.78,
        "总市值": 34629678028.0,
        "市盈率": 226.63,
        "成交量": 4209334.0,
        "成交额": 294843888.0,
        "振幅": 3.93,
        "换手率": 0.86,
        "symbol": "105.TTD"
    },
    {
        "序号": 1007,
        "name": "二倍做空FTSE中国50指数ETF",
        "最新价": 42.87,
        "涨跌额": 1.12,
        "涨跌幅": 2.68,
        "开盘价": 42.74,
        "最高价": 42.91,
        "最低价": 42.66,
        "昨收价": 41.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 5397.0,
        "成交额": 230704.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.FXP"
    },
    {
        "序号": 1008,
        "name": "Predictive Oncology Inc",
        "最新价": 3.07,
        "涨跌额": 0.08,
        "涨跌幅": 2.68,
        "开盘价": 3.0,
        "最高价": 3.1,
        "最低价": 2.93,
        "昨收价": 2.99,
        "总市值": 12473659.0,
        "市盈率": -0.68,
        "成交量": 9016.0,
        "成交额": 27103.0,
        "振幅": 5.69,
        "换手率": 0.22,
        "symbol": "105.POAI"
    },
    {
        "序号": 1009,
        "name": "First National Corp",
        "最新价": 20.35,
        "涨跌额": 0.53,
        "涨跌幅": 2.67,
        "开盘价": 19.82,
        "最高价": 20.45,
        "最低价": 19.82,
        "昨收价": 19.82,
        "总市值": 127410007.0,
        "市盈率": 8.35,
        "成交量": 11138.0,
        "成交额": 223210.0,
        "振幅": 3.18,
        "换手率": 0.18,
        "symbol": "105.FXNC"
    },
    {
        "序号": 1010,
        "name": "Grupo Supervielle SA ADR",
        "最新价": 3.84,
        "涨跌额": 0.1,
        "涨跌幅": 2.67,
        "开盘价": 3.81,
        "最高价": 4.0,
        "最低价": 3.71,
        "昨收价": 3.74,
        "总市值": 350762743.0,
        "市盈率": 9.21,
        "成交量": 1094645.0,
        "成交额": 4213882.0,
        "振幅": 7.75,
        "换手率": 1.2,
        "symbol": "106.SUPV"
    },
    {
        "序号": 1011,
        "name": "海洋产品",
        "最新价": 10.76,
        "涨跌额": 0.28,
        "涨跌幅": 2.67,
        "开盘价": 10.5,
        "最高价": 10.82,
        "最低价": 10.38,
        "昨收价": 10.48,
        "总市值": 370861972.0,
        "市盈率": 7.71,
        "成交量": 41685.0,
        "成交额": 446521.0,
        "振幅": 4.2,
        "换手率": 0.12,
        "symbol": "106.MPX"
    },
    {
        "序号": 1012,
        "name": "市民北方",
        "最新价": 21.15,
        "涨跌额": 0.55,
        "涨跌幅": 2.67,
        "开盘价": 20.95,
        "最高价": 21.4,
        "最低价": 20.77,
        "昨收价": 20.6,
        "总市值": 323061301.0,
        "市盈率": 11.68,
        "成交量": 12855.0,
        "成交额": 272451.0,
        "振幅": 3.06,
        "换手率": 0.08,
        "symbol": "105.CZNC"
    },
    {
        "序号": 1013,
        "name": "10x Genomics Inc-A",
        "最新价": 48.84,
        "涨跌额": 1.27,
        "涨跌幅": 2.67,
        "开盘价": 47.16,
        "最高价": 49.2,
        "最低价": 46.84,
        "昨收价": 47.57,
        "总市值": 5773308073.0,
        "市盈率": -25.85,
        "成交量": 1076706.0,
        "成交额": 52236808.0,
        "振幅": 4.96,
        "换手率": 0.91,
        "symbol": "105.TXG"
    },
    {
        "序号": 1014,
        "name": "Stabilis Solutions Inc",
        "最新价": 4.62,
        "涨跌额": 0.12,
        "涨跌幅": 2.67,
        "开盘价": 4.57,
        "最高价": 4.65,
        "最低价": 4.57,
        "昨收价": 4.5,
        "总市值": 85809066.0,
        "市盈率": -52.58,
        "成交量": 5877.0,
        "成交额": 27205.0,
        "振幅": 1.78,
        "换手率": 0.03,
        "symbol": "105.SLNG"
    },
    {
        "序号": 1015,
        "name": "Li-Cycle Holdings Corp",
        "最新价": 0.77,
        "涨跌额": 0.02,
        "涨跌幅": 2.67,
        "开盘价": 0.77,
        "最高价": 0.79,
        "最低价": 0.74,
        "昨收价": 0.75,
        "总市值": 137252368.0,
        "市盈率": -0.69,
        "成交量": 1830292.0,
        "成交额": 1404744.0,
        "振幅": 6.67,
        "换手率": 1.03,
        "symbol": "106.LICY"
    },
    {
        "序号": 1016,
        "name": "爱德斯",
        "最新价": 91.73,
        "涨跌额": 2.38,
        "涨跌幅": 2.66,
        "开盘价": 89.27,
        "最高价": 91.87,
        "最低价": 87.75,
        "昨收价": 89.35,
        "总市值": 1487370120.0,
        "市盈率": 25.78,
        "成交量": 90994.0,
        "成交额": 8213088.0,
        "振幅": 4.61,
        "换手率": 0.56,
        "symbol": "105.ADUS"
    },
    {
        "序号": 1017,
        "name": "Roku Inc-A",
        "最新价": 103.36,
        "涨跌额": 2.68,
        "涨跌幅": 2.66,
        "开盘价": 101.0,
        "最高价": 104.16,
        "最低价": 100.12,
        "昨收价": 100.68,
        "总市值": 14728369196.0,
        "市盈率": -16.96,
        "成交量": 4001193.0,
        "成交额": 412809824.0,
        "振幅": 4.01,
        "换手率": 2.81,
        "symbol": "105.ROKU"
    },
    {
        "序号": 1018,
        "name": "Lendway Inc",
        "最新价": 4.63,
        "涨跌额": 0.12,
        "涨跌幅": 2.66,
        "开盘价": 4.62,
        "最高价": 4.65,
        "最低价": 4.57,
        "昨收价": 4.51,
        "总市值": 8082758.0,
        "市盈率": 4.03,
        "成交量": 16781.0,
        "成交额": 77371.0,
        "振幅": 1.77,
        "换手率": 0.96,
        "symbol": "105.LDWY"
    },
    {
        "序号": 1019,
        "name": "Twilio Inc-A",
        "最新价": 70.61,
        "涨跌额": 1.83,
        "涨跌幅": 2.66,
        "开盘价": 68.07,
        "最高价": 70.67,
        "最低价": 67.83,
        "昨收价": 68.78,
        "总市值": 12783436486.0,
        "市盈率": -14.54,
        "成交量": 5206700.0,
        "成交额": 363638416.0,
        "振幅": 4.13,
        "换手率": 2.88,
        "symbol": "106.TWLO"
    },
    {
        "序号": 1020,
        "name": "Smart Sand Inc",
        "最新价": 1.93,
        "涨跌额": 0.05,
        "涨跌幅": 2.66,
        "开盘价": 1.89,
        "最高价": 1.94,
        "最低价": 1.88,
        "昨收价": 1.88,
        "总市值": 79700516.0,
        "市盈率": 6.61,
        "成交量": 40420.0,
        "成交额": 76866.0,
        "振幅": 3.19,
        "换手率": 0.1,
        "symbol": "105.SND"
    },
    {
        "序号": 1021,
        "name": "Amesite Inc",
        "最新价": 1.93,
        "涨跌额": 0.05,
        "涨跌幅": 2.66,
        "开盘价": 1.94,
        "最高价": 1.94,
        "最低价": 1.87,
        "昨收价": 1.88,
        "总市值": 4906909.0,
        "市盈率": -1.42,
        "成交量": 6306.0,
        "成交额": 12015.0,
        "振幅": 3.72,
        "换手率": 0.25,
        "symbol": "105.AMST"
    },
    {
        "序号": 1022,
        "name": "Shift4 Payments Inc-A",
        "最新价": 67.4,
        "涨跌额": 1.74,
        "涨跌幅": 2.65,
        "开盘价": 65.7,
        "最高价": 67.88,
        "最低价": 65.51,
        "昨收价": 65.66,
        "总市值": 5556078964.0,
        "市盈率": 54.63,
        "成交量": 1600011.0,
        "成交额": 107389827.0,
        "振幅": 3.61,
        "换手率": 1.94,
        "symbol": "106.FOUR"
    },
    {
        "序号": 1023,
        "name": "Ring Energy Inc",
        "最新价": 1.55,
        "涨跌额": 0.04,
        "涨跌幅": 2.65,
        "开盘价": 1.5,
        "最高价": 1.58,
        "最低价": 1.5,
        "昨收价": 1.51,
        "总市值": 303226967.0,
        "市盈率": 4.43,
        "成交量": 1290909.0,
        "成交额": 1987285.0,
        "振幅": 5.3,
        "换手率": 0.66,
        "symbol": "107.REI"
    },
    {
        "序号": 1024,
        "name": "思享无限",
        "最新价": 2.72,
        "涨跌额": 0.07,
        "涨跌幅": 2.64,
        "开盘价": 2.7,
        "最高价": 2.72,
        "最低价": 2.67,
        "昨收价": 2.65,
        "总市值": 111625909.0,
        "市盈率": 6.74,
        "成交量": 2017.0,
        "成交额": 5451.0,
        "振幅": 1.89,
        "换手率": 0.0,
        "symbol": "105.SJ"
    },
    {
        "序号": 1025,
        "name": "iRhythm Technologies Inc",
        "最新价": 89.16,
        "涨跌额": 2.29,
        "涨跌幅": 2.64,
        "开盘价": 86.38,
        "最高价": 89.58,
        "最低价": 85.43,
        "昨收价": 86.87,
        "总市值": 2732821583.0,
        "市盈率": -26.05,
        "成交量": 339948.0,
        "成交额": 30039145.0,
        "振幅": 4.78,
        "换手率": 1.11,
        "symbol": "105.IRTC"
    },
    {
        "序号": 1026,
        "name": "Laureate Education Inc",
        "最新价": 13.64,
        "涨跌额": 0.35,
        "涨跌幅": 2.63,
        "开盘价": 13.23,
        "最高价": 13.64,
        "最低价": 13.07,
        "昨收价": 13.29,
        "总市值": 2146490872.0,
        "市盈率": 20.45,
        "成交量": 729538.0,
        "成交额": 9863124.0,
        "振幅": 4.29,
        "换手率": 0.46,
        "symbol": "105.LAUR"
    },
    {
        "序号": 1027,
        "name": "Veru Inc",
        "最新价": 1.17,
        "涨跌额": 0.03,
        "涨跌幅": 2.63,
        "开盘价": 1.14,
        "最高价": 1.23,
        "最低价": 1.14,
        "昨收价": 1.14,
        "总市值": 109597239.0,
        "市盈率": -0.99,
        "成交量": 859997.0,
        "成交额": 1021087.0,
        "振幅": 7.89,
        "换手率": 0.92,
        "symbol": "105.VERU"
    },
    {
        "序号": 1028,
        "name": "优品车",
        "最新价": 0.39,
        "涨跌额": 0.01,
        "涨跌幅": 2.63,
        "开盘价": 0.37,
        "最高价": 0.42,
        "最低价": 0.37,
        "昨收价": 0.38,
        "总市值": 20475000.0,
        "市盈率": -3.76,
        "成交量": 13294318.0,
        "成交额": 5337677.0,
        "振幅": 13.16,
        "换手率": 25.32,
        "symbol": "105.UCAR"
    },
    {
        "序号": 1029,
        "name": "Meritage Homes Corp",
        "最新价": 157.56,
        "涨跌额": 4.04,
        "涨跌幅": 2.63,
        "开盘价": 153.25,
        "最高价": 158.32,
        "最低价": 153.08,
        "昨收价": 153.52,
        "总市值": 5743047189.0,
        "市盈率": 7.16,
        "成交量": 596512.0,
        "成交额": 93582785.0,
        "振幅": 3.41,
        "换手率": 1.64,
        "symbol": "106.MTH"
    },
    {
        "序号": 1030,
        "name": "Fresh2 Group Ltd ADR",
        "最新价": 1.17,
        "涨跌额": 0.03,
        "涨跌幅": 2.63,
        "开盘价": 1.2,
        "最高价": 1.28,
        "最低价": 1.17,
        "昨收价": 1.14,
        "总市值": 27832387.0,
        "市盈率": -1.61,
        "成交量": 7463.0,
        "成交额": 8934.0,
        "振幅": 9.65,
        "换手率": 0.03,
        "symbol": "105.FRES"
    },
    {
        "序号": 1031,
        "name": "Abacus Life Inc Wt",
        "最新价": 0.39,
        "涨跌额": 0.01,
        "涨跌幅": 2.63,
        "开盘价": 0.36,
        "最高价": 0.4,
        "最低价": 0.36,
        "昨收价": 0.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 5359.0,
        "成交额": 2013.0,
        "振幅": 10.53,
        "换手率": null,
        "symbol": "105.ABLLW"
    },
    {
        "序号": 1032,
        "name": "SoundThinking Inc",
        "最新价": 24.58,
        "涨跌额": 0.63,
        "涨跌幅": 2.63,
        "开盘价": 23.75,
        "最高价": 24.85,
        "最低价": 23.04,
        "昨收价": 23.95,
        "总市值": 312672545.0,
        "市盈率": -42.22,
        "成交量": 40772.0,
        "成交额": 989552.0,
        "振幅": 7.56,
        "换手率": 0.32,
        "symbol": "105.SSTI"
    },
    {
        "序号": 1033,
        "name": "康诺斯全球",
        "最新价": 8.59,
        "涨跌额": 0.22,
        "涨跌幅": 2.63,
        "开盘价": 8.34,
        "最高价": 8.62,
        "最低价": 8.34,
        "昨收价": 8.37,
        "总市值": 988082067.0,
        "市盈率": -15.51,
        "成交量": 294066.0,
        "成交额": 2497953.0,
        "振幅": 3.35,
        "换手率": 0.26,
        "symbol": "106.KRO"
    },
    {
        "序号": 1034,
        "name": "ZoomInfo Technologies Inc",
        "最新价": 15.64,
        "涨跌额": 0.4,
        "涨跌幅": 2.62,
        "开盘价": 15.13,
        "最高价": 15.66,
        "最低价": 14.97,
        "昨收价": 15.24,
        "总市值": 6096196580.0,
        "市盈率": 44.82,
        "成交量": 6177594.0,
        "成交额": 96049767.0,
        "振幅": 4.53,
        "换手率": 1.58,
        "symbol": "105.ZI"
    },
    {
        "序号": 1035,
        "name": "Modiv Inc-C",
        "最新价": 15.25,
        "涨跌额": 0.39,
        "涨跌幅": 2.62,
        "开盘价": 14.77,
        "最高价": 15.31,
        "最低价": 14.77,
        "昨收价": 14.86,
        "总市值": 115749605.0,
        "市盈率": -25.41,
        "成交量": 16491.0,
        "成交额": 251015.0,
        "振幅": 3.63,
        "换手率": 0.22,
        "symbol": "106.MDV"
    },
    {
        "序号": 1036,
        "name": "Nocturne Acquisition Corp",
        "最新价": 11.75,
        "涨跌额": 0.3,
        "涨跌幅": 2.62,
        "开盘价": 11.75,
        "最高价": 11.75,
        "最低价": 11.75,
        "昨收价": 11.45,
        "总市值": 60999138.0,
        "市盈率": -40.41,
        "成交量": 504.0,
        "成交额": 5922.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.MBTC"
    },
    {
        "序号": 1037,
        "name": "思维终端科技",
        "最新价": 1.96,
        "涨跌额": 0.05,
        "涨跌幅": 2.62,
        "开盘价": 1.93,
        "最高价": 1.96,
        "最低价": 1.92,
        "昨收价": 1.91,
        "总市值": 39515219.0,
        "市盈率": 7.9,
        "成交量": 37728.0,
        "成交额": 73386.0,
        "振幅": 2.09,
        "换手率": 0.19,
        "symbol": "105.MNDO"
    },
    {
        "序号": 1038,
        "name": "二倍做空黄金ETF-ProShares",
        "最新价": 27.84,
        "涨跌额": 0.71,
        "涨跌幅": 2.62,
        "开盘价": 27.77,
        "最高价": 28.03,
        "最低价": 27.51,
        "昨收价": 27.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 86890.0,
        "成交额": 2415520.0,
        "振幅": 1.92,
        "换手率": null,
        "symbol": "107.GLL"
    },
    {
        "序号": 1039,
        "name": "First Foundation Inc",
        "最新价": 7.06,
        "涨跌额": 0.18,
        "涨跌幅": 2.62,
        "开盘价": 6.92,
        "最高价": 7.17,
        "最低价": 6.9,
        "昨收价": 6.88,
        "总市值": 398493044.0,
        "市盈率": -2.16,
        "成交量": 281010.0,
        "成交额": 1985079.0,
        "振幅": 3.92,
        "换手率": 0.5,
        "symbol": "106.FFWM"
    },
    {
        "序号": 1040,
        "name": "Pinterest Inc-A",
        "最新价": 34.91,
        "涨跌额": 0.89,
        "涨跌幅": 2.62,
        "开盘价": 34.56,
        "最高价": 35.36,
        "最低价": 34.33,
        "昨收价": 34.02,
        "总市值": 23541856317.0,
        "市盈率": -107.35,
        "成交量": 14879575.0,
        "成交额": 520336352.0,
        "振幅": 3.03,
        "换手率": 2.21,
        "symbol": "106.PINS"
    },
    {
        "序号": 1041,
        "name": "Stratasys Ltd",
        "最新价": 12.16,
        "涨跌额": 0.31,
        "涨跌幅": 2.62,
        "开盘价": 11.85,
        "最高价": 12.18,
        "最低价": 11.83,
        "昨收价": 11.85,
        "总市值": 841046400.0,
        "市盈率": -7.61,
        "成交量": 468683.0,
        "成交额": 5646226.0,
        "振幅": 2.95,
        "换手率": 0.68,
        "symbol": "105.SSYS"
    },
    {
        "序号": 1042,
        "name": "Direxion Daily S&P 500 High Bet",
        "最新价": 33.75,
        "涨跌额": 0.86,
        "涨跌幅": 2.61,
        "开盘价": 32.91,
        "最高价": 34.2,
        "最低价": 32.75,
        "昨收价": 32.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 131702.0,
        "成交额": 4425825.0,
        "振幅": 4.41,
        "换手率": null,
        "symbol": "107.HIBL"
    },
    {
        "序号": 1043,
        "name": "iRobot Corp",
        "最新价": 39.7,
        "涨跌额": 1.01,
        "涨跌幅": 2.61,
        "开盘价": 39.2,
        "最高价": 39.99,
        "最低价": 38.9,
        "昨收价": 38.69,
        "总市值": 1106264519.0,
        "市盈率": -3.4,
        "成交量": 612007.0,
        "成交额": 24245452.0,
        "振幅": 2.82,
        "换手率": 2.2,
        "symbol": "105.IRBT"
    },
    {
        "序号": 1044,
        "name": "Clean Earth Acquisitions Corp-A",
        "最新价": 5.11,
        "涨跌额": 0.13,
        "涨跌幅": 2.61,
        "开盘价": 5.15,
        "最高价": 5.47,
        "最低价": 4.54,
        "昨收价": 4.98,
        "总市值": 85358615.0,
        "市盈率": 20.53,
        "成交量": 158622.0,
        "成交额": 808116.0,
        "振幅": 18.67,
        "换手率": 0.95,
        "symbol": "105.CLIN"
    },
    {
        "序号": 1045,
        "name": "Mesa Laboratories Inc",
        "最新价": 103.01,
        "涨跌额": 2.62,
        "涨跌幅": 2.61,
        "开盘价": 100.45,
        "最高价": 104.3,
        "最低价": 98.76,
        "昨收价": 100.39,
        "总市值": 555401695.0,
        "市盈率": -774.62,
        "成交量": 53938.0,
        "成交额": 5518423.0,
        "振幅": 5.52,
        "换手率": 1.0,
        "symbol": "105.MLAB"
    },
    {
        "序号": 1046,
        "name": "AST SpaceMobile Inc Wt",
        "最新价": 1.18,
        "涨跌额": 0.03,
        "涨跌幅": 2.61,
        "开盘价": 1.09,
        "最高价": 1.18,
        "最低价": 1.09,
        "昨收价": 1.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 91850.0,
        "成交额": 106370.0,
        "振幅": 7.83,
        "换手率": null,
        "symbol": "105.ASTSW"
    },
    {
        "序号": 1047,
        "name": "Eagle Bancorp Montana Inc",
        "最新价": 13.78,
        "涨跌额": 0.35,
        "涨跌幅": 2.61,
        "开盘价": 13.5,
        "最高价": 13.8,
        "最低价": 13.5,
        "昨收价": 13.43,
        "总市值": 110076459.0,
        "市盈率": 9.56,
        "成交量": 5429.0,
        "成交额": 73503.0,
        "振幅": 2.23,
        "换手率": 0.07,
        "symbol": "105.EBMT"
    },
    {
        "序号": 1048,
        "name": "Rimini Street Inc",
        "最新价": 3.15,
        "涨跌额": 0.08,
        "涨跌幅": 2.61,
        "开盘价": 3.03,
        "最高价": 3.16,
        "最低价": 3.0,
        "昨收价": 3.07,
        "总市值": 281575350.0,
        "市盈率": 24.62,
        "成交量": 434241.0,
        "成交额": 1342624.0,
        "振幅": 5.21,
        "换手率": 0.49,
        "symbol": "105.RMNI"
    },
    {
        "序号": 1049,
        "name": "One Stop Systems Inc",
        "最新价": 1.97,
        "涨跌额": 0.05,
        "涨跌幅": 2.6,
        "开盘价": 1.92,
        "最高价": 2.03,
        "最低价": 1.91,
        "昨收价": 1.92,
        "总市值": 40589979.0,
        "市盈率": -4.18,
        "成交量": 16877.0,
        "成交额": 32845.0,
        "振幅": 6.25,
        "换手率": 0.08,
        "symbol": "105.OSS"
    },
    {
        "序号": 1050,
        "name": "Amplify Energy Corp",
        "最新价": 5.91,
        "涨跌额": 0.15,
        "涨跌幅": 2.6,
        "开盘价": 5.81,
        "最高价": 6.03,
        "最低价": 5.81,
        "昨收价": 5.76,
        "总市值": 231061497.0,
        "市盈率": 0.61,
        "成交量": 511820.0,
        "成交额": 3032736.0,
        "振幅": 3.82,
        "换手率": 1.31,
        "symbol": "106.AMPY"
    },
    {
        "序号": 1051,
        "name": "Inspire Medical Systems Inc",
        "最新价": 165.09,
        "涨跌额": 4.19,
        "涨跌幅": 2.6,
        "开盘价": 159.62,
        "最高价": 168.2,
        "最低价": 159.62,
        "昨收价": 160.9,
        "总市值": 4874148362.0,
        "市盈率": -148.76,
        "成交量": 1437949.0,
        "成交额": 238432820.0,
        "振幅": 5.33,
        "换手率": 4.87,
        "symbol": "106.INSP"
    },
    {
        "序号": 1052,
        "name": "Loma Negra Compania Industrial ",
        "最新价": 6.7,
        "涨跌额": 0.17,
        "涨跌幅": 2.6,
        "开盘价": 6.51,
        "最高价": 6.72,
        "最低价": 6.51,
        "昨收价": 6.53,
        "总市值": 781867422.0,
        "市盈率": 4.83,
        "成交量": 145044.0,
        "成交额": 963076.0,
        "振幅": 3.22,
        "换手率": 0.12,
        "symbol": "106.LOMA"
    },
    {
        "序号": 1053,
        "name": "Globus Medical Inc-A",
        "最新价": 46.51,
        "涨跌额": 1.18,
        "涨跌幅": 2.6,
        "开盘价": 45.4,
        "最高价": 46.63,
        "最低价": 45.21,
        "昨收价": 45.33,
        "总市值": 6408761453.0,
        "市盈率": 40.59,
        "成交量": 2123547.0,
        "成交额": 98170011.0,
        "振幅": 3.13,
        "换手率": 1.54,
        "symbol": "106.GMED"
    },
    {
        "序号": 1054,
        "name": "MicroSectors Energy 3X Leverage",
        "最新价": 15.01,
        "涨跌额": 0.38,
        "涨跌幅": 2.6,
        "开盘价": 14.98,
        "最高价": 15.21,
        "最低价": 14.98,
        "昨收价": 14.63,
        "总市值": 10820846507.0,
        "市盈率": 3.41,
        "成交量": 2119.0,
        "成交额": 31941.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.WTIU"
    },
    {
        "序号": 1055,
        "name": "Bridgeline Digital Inc",
        "最新价": 0.79,
        "涨跌额": 0.02,
        "涨跌幅": 2.6,
        "开盘价": 0.76,
        "最高价": 0.8,
        "最低价": 0.76,
        "昨收价": 0.77,
        "总市值": 8229911.0,
        "市盈率": -4.46,
        "成交量": 10252.0,
        "成交额": 8104.0,
        "振幅": 5.19,
        "换手率": 0.1,
        "symbol": "105.BLIN"
    },
    {
        "序号": 1056,
        "name": "Genie Energy Ltd-B",
        "最新价": 26.51,
        "涨跌额": 0.67,
        "涨跌幅": 2.59,
        "开盘价": 26.09,
        "最高价": 26.95,
        "最低价": 25.84,
        "昨收价": 25.84,
        "总市值": 726850941.0,
        "市盈率": 11.97,
        "成交量": 262449.0,
        "成交额": 6947373.0,
        "振幅": 4.3,
        "换手率": 0.96,
        "symbol": "106.GNE"
    },
    {
        "序号": 1057,
        "name": "Via Renewables Inc Series A Pfd",
        "最新价": 20.62,
        "涨跌额": 0.52,
        "涨跌幅": 2.59,
        "开盘价": 20.81,
        "最高价": 20.81,
        "最低价": 20.2,
        "昨收价": 20.1,
        "总市值": 73562737.0,
        "市盈率": null,
        "成交量": 7174.0,
        "成交额": 146746.0,
        "振幅": 3.03,
        "换手率": 0.2,
        "symbol": "105.VIASP"
    },
    {
        "序号": 1058,
        "name": "SEMrush Holdings Inc-A",
        "最新价": 11.5,
        "涨跌额": 0.29,
        "涨跌幅": 2.59,
        "开盘价": 11.12,
        "最高价": 11.53,
        "最低价": 11.12,
        "昨收价": 11.21,
        "总市值": 1647156155.0,
        "市盈率": -83.08,
        "成交量": 507744.0,
        "成交额": 5788109.0,
        "振幅": 3.66,
        "换手率": 0.35,
        "symbol": "106.SEMR"
    },
    {
        "序号": 1059,
        "name": "悦商集团",
        "最新价": 3.97,
        "涨跌额": 0.1,
        "涨跌幅": 2.58,
        "开盘价": 3.96,
        "最高价": 4.33,
        "最低价": 3.77,
        "昨收价": 3.87,
        "总市值": 10421766.0,
        "市盈率": -1.15,
        "成交量": 17478.0,
        "成交额": 68193.0,
        "振幅": 14.47,
        "换手率": 0.67,
        "symbol": "105.WETG"
    },
    {
        "序号": 1060,
        "name": "诺基亚",
        "最新价": 3.18,
        "涨跌额": 0.08,
        "涨跌幅": 2.58,
        "开盘价": 3.19,
        "最高价": 3.22,
        "最低价": 3.16,
        "昨收价": 3.1,
        "总市值": 17607849255.0,
        "市盈率": 4.19,
        "成交量": 14536464.0,
        "成交额": 46350215.0,
        "振幅": 1.94,
        "换手率": 0.26,
        "symbol": "106.NOK"
    },
    {
        "序号": 1061,
        "name": "Crexendo Inc",
        "最新价": 3.18,
        "涨跌额": 0.08,
        "涨跌幅": 2.58,
        "开盘价": 3.15,
        "最高价": 3.27,
        "最低价": 3.03,
        "昨收价": 3.1,
        "总市值": 82790041.0,
        "市盈率": -2.51,
        "成交量": 74046.0,
        "成交额": 233605.0,
        "振幅": 7.74,
        "换手率": 0.28,
        "symbol": "105.CXDO"
    },
    {
        "序号": 1062,
        "name": "Innovid Corp",
        "最新价": 1.59,
        "涨跌额": 0.04,
        "涨跌幅": 2.58,
        "开盘价": 1.54,
        "最高价": 1.61,
        "最低价": 1.49,
        "昨收价": 1.55,
        "总市值": 223497172.0,
        "市盈率": -6.64,
        "成交量": 315458.0,
        "成交额": 487841.0,
        "振幅": 7.74,
        "换手率": 0.22,
        "symbol": "106.CTV"
    },
    {
        "序号": 1063,
        "name": "BioAtla Inc",
        "最新价": 1.59,
        "涨跌额": 0.04,
        "涨跌幅": 2.58,
        "开盘价": 1.54,
        "最高价": 1.63,
        "最低价": 1.49,
        "昨收价": 1.55,
        "总市值": 76217935.0,
        "市盈率": -0.61,
        "成交量": 272555.0,
        "成交额": 427457.0,
        "振幅": 9.03,
        "换手率": 0.57,
        "symbol": "105.BCAB"
    },
    {
        "序号": 1064,
        "name": "NeuroMetrix Inc",
        "最新价": 3.58,
        "涨跌额": 0.09,
        "涨跌幅": 2.58,
        "开盘价": 3.5,
        "最高价": 3.58,
        "最低价": 3.48,
        "昨收价": 3.49,
        "总市值": 3908197.0,
        "市盈率": -0.7,
        "成交量": 41197.0,
        "成交额": 145192.0,
        "振幅": 2.87,
        "换手率": 3.77,
        "symbol": "105.NURO"
    },
    {
        "序号": 1065,
        "name": "恩耐激光",
        "最新价": 13.54,
        "涨跌额": 0.34,
        "涨跌幅": 2.58,
        "开盘价": 13.2,
        "最高价": 13.56,
        "最低价": 13.11,
        "昨收价": 13.2,
        "总市值": 632129889.0,
        "市盈率": -12.37,
        "成交量": 159552.0,
        "成交额": 2152497.0,
        "振幅": 3.41,
        "换手率": 0.34,
        "symbol": "105.LASR"
    },
    {
        "序号": 1066,
        "name": "BGC Group Inc-A",
        "最新价": 6.39,
        "涨跌额": 0.16,
        "涨跌幅": 2.57,
        "开盘价": 6.26,
        "最高价": 6.42,
        "最低价": 6.23,
        "昨收价": 6.23,
        "总市值": 3170998176.0,
        "市盈率": 171.07,
        "成交量": 1926802.0,
        "成交额": 12224683.0,
        "振幅": 3.05,
        "换手率": 0.39,
        "symbol": "105.BGC"
    },
    {
        "序号": 1067,
        "name": "Crane Co",
        "最新价": 106.67,
        "涨跌额": 2.67,
        "涨跌幅": 2.57,
        "开盘价": 103.93,
        "最高价": 106.74,
        "最低价": 103.5,
        "昨收价": 104.0,
        "总市值": 6060437916.0,
        "市盈率": null,
        "成交量": 288516.0,
        "成交额": 30413399.0,
        "振幅": 3.12,
        "换手率": 0.51,
        "symbol": "106.CR"
    },
    {
        "序号": 1068,
        "name": "GraniteShares 1.5x Long META Da",
        "最新价": 107.59,
        "涨跌额": 2.69,
        "涨跌幅": 2.56,
        "开盘价": 104.09,
        "最高价": 107.59,
        "最低价": 103.86,
        "昨收价": 104.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 8236.0,
        "成交额": 877073.0,
        "振幅": 3.56,
        "换手率": null,
        "symbol": "105.FBL"
    },
    {
        "序号": 1069,
        "name": "ETRACS Monthly Pay 2x Leveraged",
        "最新价": 6.8,
        "涨跌额": 0.17,
        "涨跌幅": 2.56,
        "开盘价": 6.66,
        "最高价": 6.8,
        "最低价": 6.57,
        "昨收价": 6.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 46269.0,
        "成交额": 308690.0,
        "振幅": 3.47,
        "换手率": null,
        "symbol": "107.SMHB"
    },
    {
        "序号": 1070,
        "name": "Compass Inc-A",
        "最新价": 2.8,
        "涨跌额": 0.07,
        "涨跌幅": 2.56,
        "开盘价": 2.7,
        "最高价": 2.82,
        "最低价": 2.66,
        "昨收价": 2.73,
        "总市值": 1352524499.0,
        "市盈率": -3.42,
        "成交量": 2290789.0,
        "成交额": 6297485.0,
        "振幅": 5.86,
        "换手率": 0.47,
        "symbol": "106.COMP"
    },
    {
        "序号": 1071,
        "name": "B. Riley Financial Inc",
        "最新价": 25.28,
        "涨跌额": 0.63,
        "涨跌幅": 2.56,
        "开盘价": 24.37,
        "最高价": 26.3,
        "最低价": 23.91,
        "昨收价": 24.65,
        "总市值": 773134979.0,
        "市盈率": -11.41,
        "成交量": 877953.0,
        "成交额": 21943982.0,
        "振幅": 9.7,
        "换手率": 2.87,
        "symbol": "105.RILY"
    },
    {
        "序号": 1072,
        "name": "希尔顿度假酒店",
        "最新价": 39.74,
        "涨跌额": 0.99,
        "涨跌幅": 2.55,
        "开盘价": 38.74,
        "最高价": 39.86,
        "最低价": 38.67,
        "昨收价": 38.75,
        "总市值": 4291284955.0,
        "市盈率": 13.29,
        "成交量": 674991.0,
        "成交额": 26743829.0,
        "振幅": 3.07,
        "换手率": 0.63,
        "symbol": "106.HGV"
    },
    {
        "序号": 1073,
        "name": "Fastly Inc-A",
        "最新价": 17.27,
        "涨跌额": 0.43,
        "涨跌幅": 2.55,
        "开盘价": 16.64,
        "最高价": 17.35,
        "最低价": 16.55,
        "昨收价": 16.84,
        "总市值": 2258916000.0,
        "市盈率": -14.45,
        "成交量": 1830907.0,
        "成交额": 31382391.0,
        "振幅": 4.75,
        "换手率": 1.4,
        "symbol": "106.FSLY"
    },
    {
        "序号": 1074,
        "name": "Cheniere Energy Inc",
        "最新价": 176.21,
        "涨跌额": 4.38,
        "涨跌幅": 2.55,
        "开盘价": 172.78,
        "最高价": 176.33,
        "最低价": 172.78,
        "昨收价": 171.83,
        "总市值": 41982889938.0,
        "市盈率": 3.37,
        "成交量": 1360135.0,
        "成交额": 238980298.0,
        "振幅": 2.07,
        "换手率": 0.57,
        "symbol": "107.LNG"
    },
    {
        "序号": 1075,
        "name": "NOV Inc",
        "最新价": 18.92,
        "涨跌额": 0.47,
        "涨跌幅": 2.55,
        "开盘价": 19.0,
        "最高价": 19.25,
        "最低价": 18.75,
        "昨收价": 18.45,
        "总市值": 7453098764.0,
        "市盈率": 14.94,
        "成交量": 4469718.0,
        "成交额": 84763997.0,
        "振幅": 2.71,
        "换手率": 1.13,
        "symbol": "106.NOV"
    },
    {
        "序号": 1076,
        "name": "CPI Card Group Inc",
        "最新价": 17.74,
        "涨跌额": 0.44,
        "涨跌幅": 2.54,
        "开盘价": 17.03,
        "最高价": 18.03,
        "最低价": 16.91,
        "昨收价": 17.3,
        "总市值": 203232030.0,
        "市盈率": 6.03,
        "成交量": 11582.0,
        "成交额": 204142.0,
        "振幅": 6.47,
        "换手率": 0.1,
        "symbol": "105.PMTS"
    },
    {
        "序号": 1077,
        "name": "六旗娱乐",
        "最新价": 25.48,
        "涨跌额": 0.63,
        "涨跌幅": 2.54,
        "开盘价": 24.93,
        "最高价": 25.65,
        "最低价": 24.88,
        "昨收价": 24.85,
        "总市值": 2128523193.0,
        "市盈率": 26.93,
        "成交量": 898207.0,
        "成交额": 22823390.0,
        "振幅": 3.1,
        "换手率": 1.08,
        "symbol": "106.SIX"
    },
    {
        "序号": 1078,
        "name": "Acropolis Infrastructure Acquis",
        "最新价": 10.92,
        "涨跌额": 0.27,
        "涨跌幅": 2.54,
        "开盘价": 11.08,
        "最高价": 11.08,
        "最低价": 10.92,
        "昨收价": 10.65,
        "总市值": 181553725.0,
        "市盈率": 27.5,
        "成交量": 504.0,
        "成交额": 5533.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "106.ACRO_U"
    },
    {
        "序号": 1079,
        "name": "费森尤斯医疗",
        "最新价": 20.63,
        "涨跌额": 0.51,
        "涨跌幅": 2.53,
        "开盘价": 20.3,
        "最高价": 20.64,
        "最低价": 20.29,
        "昨收价": 20.12,
        "总市值": 12106238906.0,
        "市盈率": 23.28,
        "成交量": 317856.0,
        "成交额": 6522778.0,
        "振幅": 1.74,
        "换手率": 0.05,
        "symbol": "106.FMS"
    },
    {
        "序号": 1080,
        "name": "Direxion Technology Bull 3X Sha",
        "最新价": 61.11,
        "涨跌额": 1.51,
        "涨跌幅": 2.53,
        "开盘价": 59.24,
        "最高价": 61.25,
        "最低价": 58.92,
        "昨收价": 59.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 3305501.0,
        "成交额": 199675118.0,
        "振幅": 3.91,
        "换手率": null,
        "symbol": "107.TECL"
    },
    {
        "序号": 1081,
        "name": "DBV Technologies SA ADR",
        "最新价": 0.81,
        "涨跌额": 0.02,
        "涨跌幅": 2.53,
        "开盘价": 0.81,
        "最高价": 0.82,
        "最低价": 0.79,
        "昨收价": 0.79,
        "总市值": 155987456.0,
        "市盈率": -1.55,
        "成交量": 296738.0,
        "成交额": 238083.0,
        "振幅": 3.8,
        "换手率": 0.15,
        "symbol": "105.DBVT"
    },
    {
        "序号": 1082,
        "name": "Aqua Metals Inc",
        "最新价": 0.81,
        "涨跌额": 0.02,
        "涨跌幅": 2.53,
        "开盘价": 0.78,
        "最高价": 0.83,
        "最低价": 0.76,
        "昨收价": 0.79,
        "总市值": 87327288.0,
        "市盈率": -4.88,
        "成交量": 429803.0,
        "成交额": 339249.0,
        "振幅": 8.86,
        "换手率": 0.4,
        "symbol": "105.AQMS"
    },
    {
        "序号": 1083,
        "name": "SEACOR Marine Holdings Inc",
        "最新价": 11.75,
        "涨跌额": 0.29,
        "涨跌幅": 2.53,
        "开盘价": 11.45,
        "最高价": 11.81,
        "最低价": 11.43,
        "昨收价": 11.46,
        "总市值": 319123949.0,
        "市盈率": -11.24,
        "成交量": 64163.0,
        "成交额": 747191.0,
        "振幅": 3.32,
        "换手率": 0.24,
        "symbol": "106.SMHI"
    },
    {
        "序号": 1084,
        "name": "Calamos Convertible Opportuniti",
        "最新价": 10.16,
        "涨跌额": 0.25,
        "涨跌幅": 2.52,
        "开盘价": 10.0,
        "最高价": 10.18,
        "最低价": 9.96,
        "昨收价": 9.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 275258.0,
        "成交额": 2770357.0,
        "振幅": 2.22,
        "换手率": null,
        "symbol": "105.CHI"
    },
    {
        "序号": 1085,
        "name": "Matador Resources Co",
        "最新价": 54.12,
        "涨跌额": 1.33,
        "涨跌幅": 2.52,
        "开盘价": 53.33,
        "最高价": 54.36,
        "最低价": 53.09,
        "昨收价": 52.79,
        "总市值": 6447581654.0,
        "市盈率": 7.63,
        "成交量": 1455223.0,
        "成交额": 78433377.0,
        "振幅": 2.41,
        "换手率": 1.22,
        "symbol": "106.MTDR"
    },
    {
        "序号": 1086,
        "name": "巴克莱",
        "最新价": 7.34,
        "涨跌额": 0.18,
        "涨跌幅": 2.51,
        "开盘价": 7.22,
        "最高价": 7.35,
        "最低价": 7.21,
        "昨收价": 7.16,
        "总市值": 27661102948.0,
        "市盈率": 3.32,
        "成交量": 17508420.0,
        "成交额": 127653613.0,
        "振幅": 1.96,
        "换手率": 0.46,
        "symbol": "106.BCS"
    },
    {
        "序号": 1087,
        "name": "Gamb",
        "最新价": 9.4,
        "涨跌额": 0.23,
        "涨跌幅": 2.51,
        "开盘价": 9.18,
        "最高价": 9.48,
        "最低价": 9.17,
        "昨收价": 9.17,
        "总市值": 352919870.0,
        "市盈率": 47.2,
        "成交量": 143495.0,
        "成交额": 1341062.0,
        "振幅": 3.38,
        "换手率": 0.38,
        "symbol": "105.GAMB"
    },
    {
        "序号": 1088,
        "name": "Unity Software Inc",
        "最新价": 32.7,
        "涨跌额": 0.8,
        "涨跌幅": 2.51,
        "开盘价": 31.73,
        "最高价": 33.73,
        "最低价": 31.61,
        "昨收价": 31.9,
        "总市值": 12426622019.0,
        "市盈率": -14.47,
        "成交量": 13413568.0,
        "成交额": 438589600.0,
        "振幅": 6.65,
        "换手率": 3.53,
        "symbol": "106.U"
    },
    {
        "序号": 1089,
        "name": "诺德斯特龙",
        "最新价": 16.35,
        "涨跌额": 0.4,
        "涨跌幅": 2.51,
        "开盘价": 15.89,
        "最高价": 16.41,
        "最低价": 15.79,
        "昨收价": 15.95,
        "总市值": 2654141149.0,
        "市盈率": 22.3,
        "成交量": 5751836.0,
        "成交额": 93080639.0,
        "振幅": 3.89,
        "换手率": 3.54,
        "symbol": "106.JWN"
    },
    {
        "序号": 1090,
        "name": "Pacific Biosciences of Californ",
        "最新价": 8.59,
        "涨跌额": 0.21,
        "涨跌幅": 2.51,
        "开盘价": 8.25,
        "最高价": 8.67,
        "最低价": 8.18,
        "昨收价": 8.38,
        "总市值": 2297342989.0,
        "市盈率": -7.43,
        "成交量": 2907444.0,
        "成交额": 24771817.0,
        "振幅": 5.85,
        "换手率": 1.09,
        "symbol": "105.PACB"
    },
    {
        "序号": 1091,
        "name": "广达服务",
        "最新价": 199.25,
        "涨跌额": 4.87,
        "涨跌幅": 2.51,
        "开盘价": 195.0,
        "最高价": 199.59,
        "最低价": 194.72,
        "昨收价": 194.38,
        "总市值": 28948014333.0,
        "市盈率": 41.57,
        "成交量": 1477662.0,
        "成交额": 293540576.0,
        "振幅": 2.51,
        "换手率": 1.02,
        "symbol": "106.PWR"
    },
    {
        "序号": 1092,
        "name": "Workhorse Group Inc",
        "最新价": 0.41,
        "涨跌额": 0.01,
        "涨跌幅": 2.5,
        "开盘价": 0.4,
        "最高价": 0.42,
        "最低价": 0.4,
        "昨收价": 0.4,
        "总市值": 106978605.0,
        "市盈率": -0.91,
        "成交量": 3901937.0,
        "成交额": 1580661.0,
        "振幅": 5.0,
        "换手率": 1.5,
        "symbol": "105.WKHS"
    },
    {
        "序号": 1093,
        "name": "Edgio Inc",
        "最新价": 0.41,
        "涨跌额": 0.01,
        "涨跌幅": 2.5,
        "开盘价": 0.41,
        "最高价": 0.42,
        "最低价": 0.39,
        "昨收价": 0.4,
        "总市值": 92474191.0,
        "市盈率": -0.65,
        "成交量": 517827.0,
        "成交额": 208029.0,
        "振幅": 7.5,
        "换手率": 0.23,
        "symbol": "105.EGIO"
    },
    {
        "序号": 1094,
        "name": "Akili Inc",
        "最新价": 0.41,
        "涨跌额": 0.01,
        "涨跌幅": 2.5,
        "开盘价": 0.42,
        "最高价": 0.42,
        "最低价": 0.38,
        "昨收价": 0.4,
        "总市值": 32106998.0,
        "市盈率": -0.49,
        "成交量": 24832.0,
        "成交额": 9926.0,
        "振幅": 10.0,
        "换手率": 0.03,
        "symbol": "105.AKLI"
    },
    {
        "序号": 1095,
        "name": "露营世界控股",
        "最新价": 23.4,
        "涨跌额": 0.57,
        "涨跌幅": 2.5,
        "开盘价": 22.82,
        "最高价": 23.42,
        "最低价": 22.82,
        "昨收价": 22.83,
        "总市值": 1971382959.0,
        "市盈率": 134.91,
        "成交量": 683162.0,
        "成交额": 15884619.0,
        "振幅": 2.63,
        "换手率": 0.81,
        "symbol": "106.CWH"
    },
    {
        "序号": 1096,
        "name": "First Bank",
        "最新价": 13.58,
        "涨跌额": 0.33,
        "涨跌幅": 2.49,
        "开盘价": 13.25,
        "最高价": 13.69,
        "最低价": 13.25,
        "昨收价": 13.25,
        "总市值": 338509162.0,
        "市盈率": null,
        "成交量": 99372.0,
        "成交额": 1339121.0,
        "振幅": 3.32,
        "换手率": 0.4,
        "symbol": "105.FRBA"
    },
    {
        "序号": 1097,
        "name": "UFP Technologies Inc",
        "最新价": 177.08,
        "涨跌额": 4.3,
        "涨跌幅": 2.49,
        "开盘价": 174.4,
        "最高价": 177.91,
        "最低价": 171.75,
        "昨收价": 172.78,
        "总市值": 1352680829.0,
        "市盈率": 32.38,
        "成交量": 40906.0,
        "成交额": 7215494.0,
        "振幅": 3.57,
        "换手率": 0.54,
        "symbol": "105.UFPT"
    },
    {
        "序号": 1098,
        "name": "福克斯-B",
        "最新价": 28.01,
        "涨跌额": 0.68,
        "涨跌幅": 2.49,
        "开盘价": 27.4,
        "最高价": 28.12,
        "最低价": 27.4,
        "昨收价": 27.33,
        "总市值": 13523439924.0,
        "市盈率": 12.99,
        "成交量": 1794781.0,
        "成交额": 50116434.0,
        "振幅": 2.63,
        "换手率": 0.76,
        "symbol": "105.FOX"
    },
    {
        "序号": 1099,
        "name": "Mereo BioPharma Group plc ADR",
        "最新价": 2.06,
        "涨跌额": 0.05,
        "涨跌幅": 2.49,
        "开盘价": 2.04,
        "最高价": 2.09,
        "最低价": 1.99,
        "昨收价": 2.01,
        "总市值": 288901441.0,
        "市盈率": -9.5,
        "成交量": 487131.0,
        "成交额": 1001324.0,
        "振幅": 4.98,
        "换手率": 0.35,
        "symbol": "105.MREO"
    },
    {
        "序号": 1100,
        "name": "Atlantic Union Bankshares Corp",
        "最新价": 33.83,
        "涨跌额": 0.82,
        "涨跌幅": 2.48,
        "开盘价": 33.14,
        "最高价": 34.09,
        "最低价": 33.03,
        "昨收价": 33.01,
        "总市值": 2537797336.0,
        "市盈率": 11.78,
        "成交量": 492457.0,
        "成交额": 16657145.0,
        "振幅": 3.21,
        "换手率": 0.66,
        "symbol": "106.AUB"
    },
    {
        "序号": 1101,
        "name": "MasTec Inc",
        "最新价": 66.43,
        "涨跌额": 1.61,
        "涨跌幅": 2.48,
        "开盘价": 64.9,
        "最高价": 67.05,
        "最低价": 64.69,
        "昨收价": 64.82,
        "总市值": 5236219729.0,
        "市盈率": -110.29,
        "成交量": 856561.0,
        "成交额": 56721496.0,
        "振幅": 3.64,
        "换手率": 1.09,
        "symbol": "106.MTZ"
    },
    {
        "序号": 1102,
        "name": "Angi Inc-A",
        "最新价": 2.48,
        "涨跌额": 0.06,
        "涨跌幅": 2.48,
        "开盘价": 2.44,
        "最高价": 2.51,
        "最低价": 2.43,
        "昨收价": 2.42,
        "总市值": 1257745013.0,
        "市盈率": -14.18,
        "成交量": 1101618.0,
        "成交额": 2730824.0,
        "振幅": 3.31,
        "换手率": 0.22,
        "symbol": "105.ANGI"
    },
    {
        "序号": 1103,
        "name": "SentinelOne Inc-A",
        "最新价": 24.0,
        "涨跌额": 0.58,
        "涨跌幅": 2.48,
        "开盘价": 22.81,
        "最高价": 24.55,
        "最低价": 22.52,
        "昨收价": 23.42,
        "总市值": 7168194672.0,
        "市盈率": -19.89,
        "成交量": 11583589.0,
        "成交额": 276323600.0,
        "振幅": 8.67,
        "换手率": 3.88,
        "symbol": "106.S"
    },
    {
        "序号": 1104,
        "name": "Triumph Financial Inc Series C ",
        "最新价": 19.9,
        "涨跌额": 0.48,
        "涨跌幅": 2.47,
        "开盘价": 19.37,
        "最高价": 19.94,
        "最低价": 19.37,
        "昨收价": 19.42,
        "总市值": 895500.0,
        "市盈率": null,
        "成交量": 4298.0,
        "成交额": 85023.0,
        "振幅": 2.94,
        "换手率": 9.55,
        "symbol": "105.TFINP"
    },
    {
        "序号": 1105,
        "name": "Priority Income Fund Inc Series",
        "最新价": 20.75,
        "涨跌额": 0.5,
        "涨跌幅": 2.47,
        "开盘价": 20.25,
        "最高价": 20.75,
        "最低价": 20.25,
        "昨收价": 20.25,
        "总市值": 29050000.0,
        "市盈率": null,
        "成交量": 1302.0,
        "成交额": 26780.0,
        "振幅": 2.47,
        "换手率": 0.09,
        "symbol": "106.PRIF_K"
    },
    {
        "序号": 1106,
        "name": "KraneShares SSE STAR Market 50 ",
        "最新价": 12.45,
        "涨跌额": 0.3,
        "涨跌幅": 2.47,
        "开盘价": 12.38,
        "最高价": 12.5,
        "最低价": 12.38,
        "昨收价": 12.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 1066.0,
        "成交额": 13283.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.KSTR"
    },
    {
        "序号": 1107,
        "name": "Gritstone bio Inc",
        "最新价": 1.66,
        "涨跌额": 0.04,
        "涨跌幅": 2.47,
        "开盘价": 1.62,
        "最高价": 1.74,
        "最低价": 1.59,
        "昨收价": 1.62,
        "总市值": 158267811.0,
        "市盈率": -1.14,
        "成交量": 813107.0,
        "成交额": 1353353.0,
        "振幅": 9.26,
        "换手率": 0.85,
        "symbol": "105.GRTS"
    },
    {
        "序号": 1108,
        "name": "4D Molecular Therapeutics Inc",
        "最新价": 14.94,
        "涨跌额": 0.36,
        "涨跌幅": 2.47,
        "开盘价": 14.42,
        "最高价": 15.15,
        "最低价": 14.16,
        "昨收价": 14.58,
        "总市值": 638738889.0,
        "市盈率": -6.66,
        "成交量": 343800.0,
        "成交额": 5117424.0,
        "振幅": 6.79,
        "换手率": 0.8,
        "symbol": "105.FDMT"
    },
    {
        "序号": 1109,
        "name": "Jerash Holdings (US) Inc",
        "最新价": 2.91,
        "涨跌额": 0.07,
        "涨跌幅": 2.46,
        "开盘价": 2.8,
        "最高价": 2.94,
        "最低价": 2.8,
        "昨收价": 2.84,
        "总市值": 35777984.0,
        "市盈率": -154.64,
        "成交量": 48357.0,
        "成交额": 138383.0,
        "振幅": 4.93,
        "换手率": 0.39,
        "symbol": "105.JRSH"
    },
    {
        "序号": 1110,
        "name": "Merchants Bancorp",
        "最新价": 37.0,
        "涨跌额": 0.89,
        "涨跌幅": 2.46,
        "开盘价": 36.38,
        "最高价": 37.14,
        "最低价": 36.25,
        "昨收价": 36.11,
        "总市值": 1599887844.0,
        "市盈率": 6.18,
        "成交量": 147305.0,
        "成交额": 5440961.0,
        "振幅": 2.46,
        "换手率": 0.34,
        "symbol": "105.MBIN"
    },
    {
        "序号": 1111,
        "name": "Sleep Number Corp",
        "最新价": 14.98,
        "涨跌额": 0.36,
        "涨跌幅": 2.46,
        "开盘价": 14.58,
        "最高价": 15.15,
        "最低价": 14.41,
        "昨收价": 14.62,
        "总市值": 332975440.0,
        "市盈率": 74.47,
        "成交量": 961109.0,
        "成交额": 14231603.0,
        "振幅": 5.06,
        "换手率": 4.32,
        "symbol": "105.SNBR"
    },
    {
        "序号": 1112,
        "name": "Sea Ltd ADR",
        "最新价": 40.0,
        "涨跌额": 0.96,
        "涨跌幅": 2.46,
        "开盘价": 39.28,
        "最高价": 40.13,
        "最低价": 39.05,
        "昨收价": 39.04,
        "总市值": 22670353680.0,
        "市盈率": 32.99,
        "成交量": 6535861.0,
        "成交额": 260678587.0,
        "振幅": 2.77,
        "换手率": 1.15,
        "symbol": "106.SE"
    },
    {
        "序号": 1113,
        "name": "Microbot Medical Inc",
        "最新价": 1.25,
        "涨跌额": 0.03,
        "涨跌幅": 2.46,
        "开盘价": 1.22,
        "最高价": 1.25,
        "最低价": 1.21,
        "昨收价": 1.22,
        "总市值": 14634146.0,
        "市盈率": -1.38,
        "成交量": 30800.0,
        "成交额": 37810.0,
        "振幅": 3.28,
        "换手率": 0.26,
        "symbol": "105.MBOT"
    },
    {
        "序号": 1114,
        "name": "巴基斯坦ETF-Global X MSCI",
        "最新价": 18.34,
        "涨跌额": 0.44,
        "涨跌幅": 2.46,
        "开盘价": 18.0,
        "最高价": 18.5,
        "最低价": 17.94,
        "昨收价": 17.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 34428.0,
        "成交额": 628647.0,
        "振幅": 3.13,
        "换手率": null,
        "symbol": "107.PAK"
    },
    {
        "序号": 1115,
        "name": "Plumas Bancorp",
        "最新价": 39.22,
        "涨跌额": 0.94,
        "涨跌幅": 2.46,
        "开盘价": 38.9,
        "最高价": 39.22,
        "最低价": 38.77,
        "昨收价": 38.28,
        "总市值": 230230146.0,
        "市盈率": 7.66,
        "成交量": 7419.0,
        "成交额": 289792.0,
        "振幅": 1.18,
        "换手率": 0.13,
        "symbol": "105.PLBC"
    },
    {
        "序号": 1116,
        "name": "二倍做多每日标普油气出口与生产",
        "最新价": 30.54,
        "涨跌额": 0.73,
        "涨跌幅": 2.45,
        "开盘价": 30.3,
        "最高价": 30.81,
        "最低价": 30.07,
        "昨收价": 29.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 1300819.0,
        "成交额": 39601965.0,
        "振幅": 2.48,
        "换手率": null,
        "symbol": "107.GUSH"
    },
    {
        "序号": 1117,
        "name": "MP Materials Corp-A",
        "最新价": 16.74,
        "涨跌额": 0.4,
        "涨跌幅": 2.45,
        "开盘价": 16.34,
        "最高价": 17.24,
        "最低价": 16.32,
        "昨收价": 16.34,
        "总市值": 2976624071.0,
        "市盈率": 27.67,
        "成交量": 2457503.0,
        "成交额": 41478991.0,
        "振幅": 5.63,
        "换手率": 1.38,
        "symbol": "106.MP"
    },
    {
        "序号": 1118,
        "name": "PENN Entertainment Inc",
        "最新价": 23.86,
        "涨跌额": 0.57,
        "涨跌幅": 2.45,
        "开盘价": 23.16,
        "最高价": 24.06,
        "最低价": 23.16,
        "昨收价": 23.29,
        "总市值": 3607597308.0,
        "市盈率": -32.47,
        "成交量": 4106093.0,
        "成交额": 97376464.0,
        "振幅": 3.86,
        "换手率": 2.72,
        "symbol": "105.PENN"
    },
    {
        "序号": 1119,
        "name": "Roundhill MEME ETF",
        "最新价": 38.93,
        "涨跌额": 0.93,
        "涨跌幅": 2.45,
        "开盘价": 37.86,
        "最高价": 39.1,
        "最低价": 37.86,
        "昨收价": 38.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 5946.0,
        "成交额": 231127.0,
        "振幅": 3.26,
        "换手率": null,
        "symbol": "107.MEME"
    },
    {
        "序号": 1120,
        "name": "MAX Auto Industry 3x Leveraged ",
        "最新价": 18.85,
        "涨跌额": 0.45,
        "涨跌幅": 2.45,
        "开盘价": 18.85,
        "最高价": 18.85,
        "最低价": 18.85,
        "昨收价": 18.4,
        "总市值": 13589137685.0,
        "市盈率": 4.28,
        "成交量": 247.0,
        "成交额": 4656.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CARU"
    },
    {
        "序号": 1121,
        "name": "Hashdex Bitcoin Futures ETF",
        "最新价": 54.06,
        "涨跌额": 1.29,
        "涨跌幅": 2.44,
        "开盘价": 52.78,
        "最高价": 54.06,
        "最低价": 52.78,
        "昨收价": 52.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 1616.0,
        "成交额": 86146.0,
        "振幅": 2.43,
        "换手率": null,
        "symbol": "107.DEFI"
    },
    {
        "序号": 1122,
        "name": "Transportadora de Gas del Sur S",
        "最新价": 13.83,
        "涨跌额": 0.33,
        "涨跌幅": 2.44,
        "开盘价": 13.5,
        "最高价": 13.83,
        "最低价": 13.32,
        "昨收价": 13.5,
        "总市值": 2082137086.0,
        "市盈率": 180.04,
        "成交量": 156976.0,
        "成交额": 2136437.0,
        "振幅": 3.78,
        "换手率": 0.1,
        "symbol": "106.TGS"
    },
    {
        "序号": 1123,
        "name": "NET Power Inc-A",
        "最新价": 9.65,
        "涨跌额": 0.23,
        "涨跌幅": 2.44,
        "开盘价": 9.5,
        "最高价": 9.71,
        "最低价": 9.4,
        "昨收价": 9.42,
        "总市值": 2056080483.0,
        "市盈率": -135.71,
        "成交量": 178778.0,
        "成交额": 1711344.0,
        "振幅": 3.29,
        "换手率": 0.08,
        "symbol": "106.NPWR"
    },
    {
        "序号": 1124,
        "name": "Viking Therapeutics Inc",
        "最新价": 18.48,
        "涨跌额": 0.44,
        "涨跌幅": 2.44,
        "开盘价": 18.18,
        "最高价": 19.58,
        "最低价": 17.7,
        "昨收价": 18.04,
        "总市值": 1848535051.0,
        "市盈率": -22.86,
        "成交量": 4665125.0,
        "成交额": 87198436.0,
        "振幅": 10.42,
        "换手率": 4.66,
        "symbol": "105.VKTX"
    },
    {
        "序号": 1125,
        "name": "TrueCar Inc",
        "最新价": 2.94,
        "涨跌额": 0.07,
        "涨跌幅": 2.44,
        "开盘价": 2.84,
        "最高价": 2.95,
        "最低价": 2.77,
        "昨收价": 2.87,
        "总市值": 266893835.0,
        "市盈率": -4.04,
        "成交量": 161650.0,
        "成交额": 471074.0,
        "振幅": 6.27,
        "换手率": 0.18,
        "symbol": "105.TRUE"
    },
    {
        "序号": 1126,
        "name": "NextPlay Technologies Inc",
        "最新价": 0.42,
        "涨跌额": 0.01,
        "涨跌幅": 2.44,
        "开盘价": 0.43,
        "最高价": 0.47,
        "最低价": 0.39,
        "昨收价": 0.41,
        "总市值": 2494755.0,
        "市盈率": -0.07,
        "成交量": 154695.0,
        "成交额": 63962.0,
        "振幅": 19.51,
        "换手率": 2.6,
        "symbol": "105.NXTP"
    },
    {
        "序号": 1127,
        "name": "Innoviva Inc",
        "最新价": 15.12,
        "涨跌额": 0.36,
        "涨跌幅": 2.44,
        "开盘价": 14.74,
        "最高价": 15.14,
        "最低价": 14.69,
        "昨收价": 14.76,
        "总市值": 968990526.0,
        "市盈率": 19.43,
        "成交量": 1093842.0,
        "成交额": 16423142.0,
        "振幅": 3.05,
        "换手率": 1.71,
        "symbol": "105.INVA"
    },
    {
        "序号": 1128,
        "name": "Hagerty Inc Wt",
        "最新价": 2.1,
        "涨跌额": 0.05,
        "涨跌幅": 2.44,
        "开盘价": 2.1,
        "最高价": 2.1,
        "最低价": 2.1,
        "昨收价": 2.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 5100.0,
        "成交额": 10710.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.HGTY_WS"
    },
    {
        "序号": 1129,
        "name": "Nathan's Famous Inc",
        "最新价": 70.99,
        "涨跌额": 1.69,
        "涨跌幅": 2.44,
        "开盘价": 70.64,
        "最高价": 70.99,
        "最低价": 70.3,
        "昨收价": 69.3,
        "总市值": 289619323.0,
        "市盈率": 14.76,
        "成交量": 4110.0,
        "成交额": 291245.0,
        "振幅": 1.0,
        "换手率": 0.1,
        "symbol": "105.NATH"
    },
    {
        "序号": 1130,
        "name": "ARK 21Shares Active On-Chain Bi",
        "最新价": 29.45,
        "涨跌额": 0.7,
        "涨跌幅": 2.43,
        "开盘价": 29.15,
        "最高价": 29.45,
        "最低价": 29.15,
        "昨收价": 28.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 553.0,
        "成交额": 16134.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "107.ARKC"
    },
    {
        "序号": 1131,
        "name": "Global X Interest Rate Hedge ET",
        "最新价": 26.51,
        "涨跌额": 0.63,
        "涨跌幅": 2.43,
        "开盘价": 26.37,
        "最高价": 26.54,
        "最低价": 26.37,
        "昨收价": 25.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 901.0,
        "成交额": 23816.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.RATE"
    },
    {
        "序号": 1132,
        "name": "Roblox Corp-A",
        "最新价": 40.83,
        "涨跌额": 0.97,
        "涨跌幅": 2.43,
        "开盘价": 41.95,
        "最高价": 42.28,
        "最低价": 40.02,
        "昨收价": 39.86,
        "总市值": 25467181628.0,
        "市盈率": -22.78,
        "成交量": 16019462.0,
        "成交额": 657560864.0,
        "振幅": 5.67,
        "换手率": 2.57,
        "symbol": "106.RBLX"
    },
    {
        "序号": 1133,
        "name": "MIND Technology Inc",
        "最新价": 5.48,
        "涨跌额": 0.13,
        "涨跌幅": 2.43,
        "开盘价": 5.35,
        "最高价": 5.49,
        "最低价": 5.22,
        "昨收价": 5.35,
        "总市值": 7556230.0,
        "市盈率": -1.21,
        "成交量": 5669.0,
        "成交额": 30394.0,
        "振幅": 5.05,
        "换手率": 0.41,
        "symbol": "105.MIND"
    },
    {
        "序号": 1134,
        "name": "Ally Financial Inc",
        "最新价": 30.08,
        "涨跌额": 0.71,
        "涨跌幅": 2.42,
        "开盘价": 29.4,
        "最高价": 30.2,
        "最低价": 29.05,
        "昨收价": 29.37,
        "总市值": 9073042282.0,
        "市盈率": 7.42,
        "成交量": 5396000.0,
        "成交额": 161247024.0,
        "振幅": 3.92,
        "换手率": 1.79,
        "symbol": "106.ALLY"
    },
    {
        "序号": 1135,
        "name": "Lightbridge Corp",
        "最新价": 3.82,
        "涨跌额": 0.09,
        "涨跌幅": 2.41,
        "开盘价": 3.73,
        "最高价": 4.03,
        "最低价": 3.73,
        "昨收价": 3.73,
        "总市值": 49517785.0,
        "市盈率": -6.65,
        "成交量": 85399.0,
        "成交额": 333402.0,
        "振幅": 8.04,
        "换手率": 0.66,
        "symbol": "105.LTBR"
    },
    {
        "序号": 1136,
        "name": "Telesat Corp",
        "最新价": 10.62,
        "涨跌额": 0.25,
        "涨跌幅": 2.41,
        "开盘价": 10.39,
        "最高价": 10.77,
        "最低价": 10.39,
        "昨收价": 10.37,
        "总市值": 144421497.0,
        "市盈率": 1.14,
        "成交量": 14531.0,
        "成交额": 153851.0,
        "振幅": 3.66,
        "换手率": 0.11,
        "symbol": "105.TSAT"
    },
    {
        "序号": 1137,
        "name": "美国达尔令国际",
        "最新价": 47.59,
        "涨跌额": 1.12,
        "涨跌幅": 2.41,
        "开盘价": 46.41,
        "最高价": 48.38,
        "最低价": 46.41,
        "昨收价": 46.47,
        "总市值": 7592141491.0,
        "市盈率": 10.55,
        "成交量": 2719677.0,
        "成交额": 129782548.0,
        "振幅": 4.24,
        "换手率": 1.7,
        "symbol": "106.DAR"
    },
    {
        "序号": 1138,
        "name": "中阳金融集团",
        "最新价": 4.25,
        "涨跌额": 0.1,
        "涨跌幅": 2.41,
        "开盘价": 4.18,
        "最高价": 4.27,
        "最低价": 4.18,
        "昨收价": 4.15,
        "总市值": 127550248.0,
        "市盈率": 37.54,
        "成交量": 62521.0,
        "成交额": 263726.0,
        "振幅": 2.17,
        "换手率": 0.21,
        "symbol": "105.TOP"
    },
    {
        "序号": 1139,
        "name": "Taro Pharmaceutical Industries ",
        "最新价": 37.89,
        "涨跌额": 0.89,
        "涨跌幅": 2.41,
        "开盘价": 37.69,
        "最高价": 38.33,
        "最低价": 37.13,
        "昨收价": 37.0,
        "总市值": 1424081669.0,
        "市盈率": 66.55,
        "成交量": 28279.0,
        "成交额": 1071993.0,
        "振幅": 3.24,
        "换手率": 0.08,
        "symbol": "106.TARO"
    },
    {
        "序号": 1140,
        "name": "摩根士丹利",
        "最新价": 82.28,
        "涨跌额": 1.93,
        "涨跌幅": 2.4,
        "开盘价": 80.28,
        "最高价": 82.66,
        "最低价": 80.15,
        "昨收价": 80.35,
        "总市值": 135047116802.0,
        "市盈率": 13.77,
        "成交量": 10343400.0,
        "成交额": 848515984.0,
        "振幅": 3.12,
        "换手率": 0.63,
        "symbol": "106.MS"
    },
    {
        "序号": 1141,
        "name": "Atlassian Corp-A",
        "最新价": 192.36,
        "涨跌额": 4.51,
        "涨跌幅": 2.4,
        "开盘价": 185.75,
        "最高价": 193.39,
        "最低价": 184.5,
        "昨收价": 187.85,
        "总市值": 49640194038.0,
        "市盈率": -101.98,
        "成交量": 1119560.0,
        "成交额": 214267925.0,
        "振幅": 4.73,
        "换手率": 0.43,
        "symbol": "105.TEAM"
    },
    {
        "序号": 1142,
        "name": "西方铜金",
        "最新价": 1.28,
        "涨跌额": 0.03,
        "涨跌幅": 2.4,
        "开盘价": 1.26,
        "最高价": 1.28,
        "最低价": 1.24,
        "昨收价": 1.25,
        "总市值": 208152282.0,
        "市盈率": -66.47,
        "成交量": 69453.0,
        "成交额": 87467.0,
        "振幅": 3.2,
        "换手率": 0.04,
        "symbol": "107.WRN"
    },
    {
        "序号": 1143,
        "name": "第一社区(南卡州)",
        "最新价": 19.2,
        "涨跌额": 0.45,
        "涨跌幅": 2.4,
        "开盘价": 19.08,
        "最高价": 19.38,
        "最低价": 19.02,
        "昨收价": 18.75,
        "总市值": 145908768.0,
        "市盈率": 11.59,
        "成交量": 4240.0,
        "成交额": 81344.0,
        "振幅": 1.92,
        "换手率": 0.06,
        "symbol": "105.FCCO"
    },
    {
        "序号": 1144,
        "name": "红鳍公司",
        "最新价": 8.11,
        "涨跌额": 0.19,
        "涨跌幅": 2.4,
        "开盘价": 7.77,
        "最高价": 8.2,
        "最低价": 7.71,
        "昨收价": 7.92,
        "总市值": 934609684.0,
        "市盈率": -5.53,
        "成交量": 4739914.0,
        "成交额": 38188112.0,
        "振幅": 6.19,
        "换手率": 4.11,
        "symbol": "105.RDFN"
    },
    {
        "序号": 1145,
        "name": "ARK Fintech Innovation ETF",
        "最新价": 25.19,
        "涨跌额": 0.59,
        "涨跌幅": 2.4,
        "开盘价": 24.53,
        "最高价": 25.21,
        "最低价": 24.51,
        "昨收价": 24.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 437417.0,
        "成交额": 10949562.0,
        "振幅": 2.85,
        "换手率": null,
        "symbol": "107.ARKF"
    },
    {
        "序号": 1146,
        "name": "Veracyte Inc",
        "最新价": 25.62,
        "涨跌额": 0.6,
        "涨跌幅": 2.4,
        "开盘价": 24.9,
        "最高价": 26.1,
        "最低价": 24.42,
        "昨收价": 25.02,
        "总市值": 1871276371.0,
        "市盈率": -37.46,
        "成交量": 792986.0,
        "成交额": 20333351.0,
        "振幅": 6.71,
        "换手率": 1.09,
        "symbol": "105.VCYT"
    },
    {
        "序号": 1147,
        "name": "US Silica Holdings Inc",
        "最新价": 11.11,
        "涨跌额": 0.26,
        "涨跌幅": 2.4,
        "开盘价": 10.95,
        "最高价": 11.28,
        "最低价": 10.95,
        "昨收价": 10.85,
        "总市值": 857185517.0,
        "市盈率": 5.74,
        "成交量": 802100.0,
        "成交额": 8915435.0,
        "振幅": 3.04,
        "换手率": 1.04,
        "symbol": "106.SLCA"
    },
    {
        "序号": 1148,
        "name": "Seer Inc",
        "最新价": 1.71,
        "涨跌额": 0.04,
        "涨跌幅": 2.4,
        "开盘价": 1.7,
        "最高价": 1.72,
        "最低价": 1.65,
        "昨收价": 1.67,
        "总市值": 109141114.0,
        "市盈率": -1.2,
        "成交量": 273028.0,
        "成交额": 463356.0,
        "振幅": 4.19,
        "换手率": 0.43,
        "symbol": "105.SEER"
    },
    {
        "序号": 1149,
        "name": "Worthington Steel Inc",
        "最新价": 26.08,
        "涨跌额": 0.61,
        "涨跌幅": 2.39,
        "开盘价": 24.88,
        "最高价": 26.61,
        "最低价": 24.8,
        "昨收价": 25.47,
        "总市值": 1304654999.0,
        "市盈率": null,
        "成交量": 465798.0,
        "成交额": 12055869.0,
        "振幅": 7.11,
        "换手率": 0.93,
        "symbol": "106.WS"
    },
    {
        "序号": 1150,
        "name": "Elicio Therapeutics Inc",
        "最新价": 5.99,
        "涨跌额": 0.14,
        "涨跌幅": 2.39,
        "开盘价": 5.96,
        "最高价": 6.42,
        "最低价": 5.88,
        "昨收价": 5.85,
        "总市值": 50358565.0,
        "市盈率": -1.16,
        "成交量": 13427.0,
        "成交额": 82807.0,
        "振幅": 9.23,
        "换手率": 0.16,
        "symbol": "105.ELTX"
    },
    {
        "序号": 1151,
        "name": "ADT Inc",
        "最新价": 6.42,
        "涨跌额": 0.15,
        "涨跌幅": 2.39,
        "开盘价": 6.25,
        "最高价": 6.43,
        "最低价": 6.21,
        "昨收价": 6.27,
        "总市值": 5918092624.0,
        "市盈率": 76.03,
        "成交量": 2156424.0,
        "成交额": 13758303.0,
        "振幅": 3.51,
        "换手率": 0.23,
        "symbol": "106.ADT"
    },
    {
        "序号": 1152,
        "name": "Old Point Financial Corp",
        "最新价": 17.55,
        "涨跌额": 0.41,
        "涨跌幅": 2.39,
        "开盘价": 17.55,
        "最高价": 17.55,
        "最低价": 17.55,
        "昨收价": 17.14,
        "总市值": 88418058.0,
        "市盈率": 9.95,
        "成交量": 320.0,
        "成交额": 5616.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.OPOF"
    },
    {
        "序号": 1153,
        "name": "Tompkins Financial Corp",
        "最新价": 58.69,
        "涨跌额": 1.37,
        "涨跌幅": 2.39,
        "开盘价": 58.36,
        "最高价": 58.83,
        "最低价": 57.92,
        "昨收价": 57.32,
        "总市值": 842211888.0,
        "市盈率": 59.94,
        "成交量": 34988.0,
        "成交额": 2049506.0,
        "振幅": 1.59,
        "换手率": 0.24,
        "symbol": "107.TMP"
    },
    {
        "序号": 1154,
        "name": "博通",
        "最新价": 944.3,
        "涨跌额": 22.04,
        "涨跌幅": 2.39,
        "开盘价": 927.84,
        "最高价": 950.73,
        "最低价": 910.53,
        "昨收价": 922.26,
        "总市值": 389746136427.0,
        "市盈率": 27.68,
        "成交量": 6966842.0,
        "成交额": 6492748032.0,
        "振幅": 4.36,
        "换手率": 1.69,
        "symbol": "105.AVGO"
    },
    {
        "序号": 1155,
        "name": "卡朋特科技",
        "最新价": 66.0,
        "涨跌额": 1.54,
        "涨跌幅": 2.39,
        "开盘价": 64.09,
        "最高价": 66.36,
        "最低价": 63.61,
        "昨收价": 64.46,
        "总市值": 3254222598.0,
        "市盈率": 30.36,
        "成交量": 594356.0,
        "成交额": 39052325.0,
        "振幅": 4.27,
        "换手率": 1.21,
        "symbol": "106.CRS"
    },
    {
        "序号": 1156,
        "name": "美森特",
        "最新价": 92.58,
        "涨跌额": 2.16,
        "涨跌幅": 2.39,
        "开盘价": 91.04,
        "最高价": 92.59,
        "最低价": 90.31,
        "昨收价": 90.42,
        "总市值": 2028041649.0,
        "市盈率": 12.73,
        "成交量": 259438.0,
        "成交额": 23705180.0,
        "振幅": 2.52,
        "换手率": 1.18,
        "symbol": "106.DOOR"
    },
    {
        "序号": 1157,
        "name": "Ranpak Holdings Corp-A",
        "最新价": 4.72,
        "涨跌额": 0.11,
        "涨跌幅": 2.39,
        "开盘价": 4.55,
        "最高价": 4.73,
        "最低价": 4.52,
        "昨收价": 4.61,
        "总市值": 389896870.0,
        "市盈率": -15.53,
        "成交量": 356048.0,
        "成交额": 1653079.0,
        "振幅": 4.56,
        "换手率": 0.43,
        "symbol": "106.PACK"
    },
    {
        "序号": 1158,
        "name": "Karyopharm Therapeutics Inc",
        "最新价": 0.86,
        "涨跌额": 0.02,
        "涨跌幅": 2.38,
        "开盘价": 0.86,
        "最高价": 0.88,
        "最低价": 0.84,
        "昨收价": 0.84,
        "总市值": 98499232.0,
        "市盈率": -0.7,
        "成交量": 736447.0,
        "成交额": 633848.0,
        "振幅": 4.76,
        "换手率": 0.64,
        "symbol": "105.KPTI"
    },
    {
        "序号": 1159,
        "name": "Independence Realty Trust Inc",
        "最新价": 14.62,
        "涨跌额": 0.34,
        "涨跌幅": 2.38,
        "开盘价": 14.19,
        "最高价": 14.62,
        "最低价": 14.16,
        "昨收价": 14.28,
        "总市值": 3285269323.0,
        "市盈率": 57.72,
        "成交量": 3053550.0,
        "成交额": 44211532.0,
        "振幅": 3.22,
        "换手率": 1.36,
        "symbol": "106.IRT"
    },
    {
        "序号": 1160,
        "name": "Atea Pharmaceuticals Inc",
        "最新价": 3.01,
        "涨跌额": 0.07,
        "涨跌幅": 2.38,
        "开盘价": 2.96,
        "最高价": 3.01,
        "最低价": 2.93,
        "昨收价": 2.94,
        "总市值": 251140894.0,
        "市盈率": -1.91,
        "成交量": 231809.0,
        "成交额": 690261.0,
        "振幅": 2.72,
        "换手率": 0.28,
        "symbol": "105.AVIR"
    },
    {
        "序号": 1161,
        "name": "Global Water Resources Inc",
        "最新价": 12.48,
        "涨跌额": 0.29,
        "涨跌幅": 2.38,
        "开盘价": 12.12,
        "最高价": 12.57,
        "最低价": 12.12,
        "昨收价": 12.19,
        "总市值": 301660532.0,
        "市盈率": 39.37,
        "成交量": 17154.0,
        "成交额": 212675.0,
        "振幅": 3.69,
        "换手率": 0.07,
        "symbol": "105.GWRS"
    },
    {
        "序号": 1162,
        "name": "马里兰第一联合",
        "最新价": 21.97,
        "涨跌额": 0.51,
        "涨跌幅": 2.38,
        "开盘价": 21.26,
        "最高价": 22.35,
        "最低价": 21.26,
        "昨收价": 21.46,
        "总市值": 146896297.0,
        "市盈率": 7.25,
        "成交量": 20584.0,
        "成交额": 448548.0,
        "振幅": 5.08,
        "换手率": 0.31,
        "symbol": "105.FUNC"
    },
    {
        "序号": 1163,
        "name": "Coherent Corp",
        "最新价": 41.38,
        "涨跌额": 0.96,
        "涨跌幅": 2.38,
        "开盘价": 40.17,
        "最高价": 41.81,
        "最低价": 40.04,
        "昨收价": 40.42,
        "总市值": 6268338567.0,
        "市盈率": -21.74,
        "成交量": 1761403.0,
        "成交额": 72862871.0,
        "振幅": 4.38,
        "换手率": 1.16,
        "symbol": "106.COHR"
    },
    {
        "序号": 1164,
        "name": "优客工场",
        "最新价": 3.88,
        "涨跌额": 0.09,
        "涨跌幅": 2.37,
        "开盘价": 3.71,
        "最高价": 3.88,
        "最低价": 3.59,
        "昨收价": 3.79,
        "总市值": 2105342.0,
        "市盈率": -0.16,
        "成交量": 9319.0,
        "成交额": 34885.0,
        "振幅": 7.65,
        "换手率": 1.72,
        "symbol": "105.UK"
    },
    {
        "序号": 1165,
        "name": "JFrog Ltd",
        "最新价": 29.77,
        "涨跌额": 0.69,
        "涨跌幅": 2.37,
        "开盘价": 29.08,
        "最高价": 30.08,
        "最低价": 28.82,
        "昨收价": 29.08,
        "总市值": 3122486764.0,
        "市盈率": -42.67,
        "成交量": 943067.0,
        "成交额": 28157307.0,
        "振幅": 4.33,
        "换手率": 0.9,
        "symbol": "105.FROG"
    },
    {
        "序号": 1166,
        "name": "RxSight Inc",
        "最新价": 34.97,
        "涨跌额": 0.81,
        "涨跌幅": 2.37,
        "开盘价": 34.04,
        "最高价": 35.04,
        "最低价": 33.74,
        "昨收价": 34.16,
        "总市值": 1254080292.0,
        "市盈率": -22.78,
        "成交量": 542676.0,
        "成交额": 18812516.0,
        "振幅": 3.81,
        "换手率": 1.51,
        "symbol": "105.RXST"
    },
    {
        "序号": 1167,
        "name": "卡路美",
        "最新价": 17.28,
        "涨跌额": 0.4,
        "涨跌幅": 2.37,
        "开盘价": 16.77,
        "最高价": 17.29,
        "最低价": 16.71,
        "昨收价": 16.88,
        "总市值": 1381836033.0,
        "市盈率": 31.99,
        "成交量": 23722.0,
        "成交额": 404824.0,
        "振幅": 3.44,
        "换手率": 0.03,
        "symbol": "105.CLMT"
    },
    {
        "序号": 1168,
        "name": "Empire State Realty Op LP Serie",
        "最新价": 9.1,
        "涨跌额": 0.21,
        "涨跌幅": 2.36,
        "开盘价": 8.89,
        "最高价": 9.1,
        "最低价": 8.89,
        "昨收价": 8.89,
        "总市值": 255422395.0,
        "市盈率": 3.17,
        "成交量": 10845.0,
        "成交额": 96460.0,
        "振幅": 2.36,
        "换手率": 0.21,
        "symbol": "107.OGCP"
    },
    {
        "序号": 1169,
        "name": "Himalaya Shipping Ltd",
        "最新价": 6.07,
        "涨跌额": 0.14,
        "涨跌幅": 2.36,
        "开盘价": 5.89,
        "最高价": 6.13,
        "最低价": 5.85,
        "昨收价": 5.93,
        "总市值": 247551942.0,
        "市盈率": -67.69,
        "成交量": 162344.0,
        "成交额": 976763.0,
        "振幅": 4.72,
        "换手率": 0.4,
        "symbol": "106.HSHP"
    },
    {
        "序号": 1170,
        "name": "Nevro Corp",
        "最新价": 19.95,
        "涨跌额": 0.46,
        "涨跌幅": 2.36,
        "开盘价": 19.5,
        "最高价": 20.28,
        "最低价": 19.41,
        "昨收价": 19.49,
        "总市值": 722299625.0,
        "市盈率": -7.05,
        "成交量": 639116.0,
        "成交额": 12727541.0,
        "振幅": 4.46,
        "换手率": 1.77,
        "symbol": "106.NVRO"
    },
    {
        "序号": 1171,
        "name": "American Coastal Insurance Corp",
        "最新价": 8.69,
        "涨跌额": 0.2,
        "涨跌幅": 2.36,
        "开盘价": 8.48,
        "最高价": 8.72,
        "最低价": 8.42,
        "昨收价": 8.49,
        "总市值": 381471639.0,
        "市盈率": -333.75,
        "成交量": 242977.0,
        "成交额": 2086797.0,
        "振幅": 3.53,
        "换手率": 0.55,
        "symbol": "105.ACIC"
    },
    {
        "序号": 1172,
        "name": "ZipRecruiter Inc-A",
        "最新价": 13.47,
        "涨跌额": 0.31,
        "涨跌幅": 2.36,
        "开盘价": 13.1,
        "最高价": 13.51,
        "最低价": 13.09,
        "昨收价": 13.16,
        "总市值": 1331282962.0,
        "市盈率": 21.17,
        "成交量": 407134.0,
        "成交额": 5451360.0,
        "振幅": 3.19,
        "换手率": 0.41,
        "symbol": "106.ZIP"
    },
    {
        "序号": 1173,
        "name": "梅西百货",
        "最新价": 17.39,
        "涨跌额": 0.4,
        "涨跌幅": 2.35,
        "开盘价": 16.95,
        "最高价": 17.45,
        "最低价": 16.87,
        "昨收价": 16.99,
        "总市值": 4766135087.0,
        "市盈率": 6.97,
        "成交量": 11938270.0,
        "成交额": 206250508.0,
        "振幅": 3.41,
        "换手率": 4.36,
        "symbol": "106.M"
    },
    {
        "序号": 1174,
        "name": "Ashford Hospitality Trust Inc S",
        "最新价": 10.0,
        "涨跌额": 0.23,
        "涨跌幅": 2.35,
        "开盘价": 9.91,
        "最高价": 10.01,
        "最低价": 9.91,
        "昨收价": 9.77,
        "总市值": 13084150.0,
        "市盈率": null,
        "成交量": 4055.0,
        "成交额": 40448.0,
        "振幅": 1.02,
        "换手率": 0.31,
        "symbol": "106.AHT_H"
    },
    {
        "序号": 1175,
        "name": "23andMe Holding Co-A",
        "最新价": 0.87,
        "涨跌额": 0.02,
        "涨跌幅": 2.35,
        "开盘价": 0.85,
        "最高价": 0.89,
        "最低价": 0.85,
        "昨收价": 0.85,
        "总市值": 416820963.0,
        "市盈率": -1.24,
        "成交量": 3593294.0,
        "成交额": 3117594.0,
        "振幅": 4.71,
        "换手率": 0.75,
        "symbol": "105.ME"
    },
    {
        "序号": 1176,
        "name": "CoreCard Corp",
        "最新价": 14.37,
        "涨跌额": 0.33,
        "涨跌幅": 2.35,
        "开盘价": 13.83,
        "最高价": 14.74,
        "最低价": 13.83,
        "昨收价": 14.04,
        "总市值": 121287916.0,
        "市盈率": 31.06,
        "成交量": 70559.0,
        "成交额": 1009912.0,
        "振幅": 6.48,
        "换手率": 0.84,
        "symbol": "106.CCRD"
    },
    {
        "序号": 1177,
        "name": "MediaAlpha Inc-A",
        "最新价": 10.89,
        "涨跌额": 0.25,
        "涨跌幅": 2.35,
        "开盘价": 10.57,
        "最高价": 11.04,
        "最低价": 10.57,
        "昨收价": 10.64,
        "总市值": 708820288.0,
        "市盈率": -10.9,
        "成交量": 207673.0,
        "成交额": 2256111.0,
        "振幅": 4.42,
        "换手率": 0.32,
        "symbol": "106.MAX"
    },
    {
        "序号": 1178,
        "name": "康特科技",
        "最新价": 63.61,
        "涨跌额": 1.46,
        "涨跌幅": 2.35,
        "开盘价": 62.63,
        "最高价": 64.12,
        "最低价": 61.92,
        "昨收价": 62.15,
        "总市值": 2854793582.0,
        "市盈率": 35.9,
        "成交量": 375080.0,
        "成交额": 23846835.0,
        "振幅": 3.54,
        "换手率": 0.84,
        "symbol": "105.CAMT"
    },
    {
        "序号": 1179,
        "name": "第一资本金融",
        "最新价": 118.09,
        "涨跌额": 2.71,
        "涨跌幅": 2.35,
        "开盘价": 115.5,
        "最高价": 118.55,
        "最低价": 114.89,
        "昨收价": 115.38,
        "总市值": 44974195187.0,
        "市盈率": 8.31,
        "成交量": 3024938.0,
        "成交额": 356116880.0,
        "振幅": 3.17,
        "换手率": 0.79,
        "symbol": "106.COF"
    },
    {
        "序号": 1180,
        "name": "Magnolia Oil & Gas Corp-A",
        "最新价": 20.93,
        "涨跌额": 0.48,
        "涨跌幅": 2.35,
        "开盘价": 20.7,
        "最高价": 20.97,
        "最低价": 20.59,
        "昨收价": 20.45,
        "总市值": 4333561377.0,
        "市盈率": 8.31,
        "成交量": 1480502.0,
        "成交额": 30840267.0,
        "振幅": 1.86,
        "换手率": 0.72,
        "symbol": "106.MGY"
    },
    {
        "序号": 1181,
        "name": "SelectQuote Inc",
        "最新价": 1.31,
        "涨跌额": 0.03,
        "涨跌幅": 2.34,
        "开盘价": 1.3,
        "最高价": 1.35,
        "最低价": 1.27,
        "昨收价": 1.28,
        "总市值": 219730354.0,
        "市盈率": -4.66,
        "成交量": 342596.0,
        "成交额": 450580.0,
        "振幅": 6.25,
        "换手率": 0.2,
        "symbol": "106.SLQT"
    },
    {
        "序号": 1182,
        "name": "RedHill Biopharma Ltd ADR",
        "最新价": 1.31,
        "涨跌额": 0.03,
        "涨跌幅": 2.34,
        "开盘价": 1.29,
        "最高价": 1.42,
        "最低价": 1.21,
        "昨收价": 1.28,
        "总市值": 14502732.0,
        "市盈率": 1.25,
        "成交量": 2135310.0,
        "成交额": 2798855.0,
        "振幅": 16.41,
        "换手率": 19.29,
        "symbol": "105.RDHL"
    },
    {
        "序号": 1183,
        "name": "Hamilton Insurance Group Ltd-B",
        "最新价": 15.29,
        "涨跌额": 0.35,
        "涨跌幅": 2.34,
        "开盘价": 14.89,
        "最高价": 15.55,
        "最低价": 14.89,
        "昨收价": 14.94,
        "总市值": 1702858875.0,
        "市盈率": 23.38,
        "成交量": 115327.0,
        "成交额": 1755954.0,
        "振幅": 4.42,
        "换手率": 0.1,
        "symbol": "106.HG"
    },
    {
        "序号": 1184,
        "name": "Aerovate Therapeutics Inc",
        "最新价": 17.04,
        "涨跌额": 0.39,
        "涨跌幅": 2.34,
        "开盘价": 16.82,
        "最高价": 17.58,
        "最低价": 16.49,
        "昨收价": 16.65,
        "总市值": 471477834.0,
        "市盈率": -6.72,
        "成交量": 69047.0,
        "成交额": 1187184.0,
        "振幅": 6.55,
        "换手率": 0.25,
        "symbol": "105.AVTE"
    },
    {
        "序号": 1185,
        "name": "MainStreet Bancshares Inc",
        "最新价": 22.77,
        "涨跌额": 0.52,
        "涨跌幅": 2.34,
        "开盘价": 21.95,
        "最高价": 23.1,
        "最低价": 21.94,
        "昨收价": 22.25,
        "总市值": 171396507.0,
        "市盈率": 5.91,
        "成交量": 13043.0,
        "成交额": 293373.0,
        "振幅": 5.21,
        "换手率": 0.17,
        "symbol": "105.MNSB"
    },
    {
        "序号": 1186,
        "name": "路坦力",
        "最新价": 46.02,
        "涨跌额": 1.05,
        "涨跌幅": 2.33,
        "开盘价": 44.77,
        "最高价": 46.07,
        "最低价": 44.77,
        "昨收价": 44.97,
        "总市值": 11168579810.0,
        "市盈率": -65.35,
        "成交量": 2007485.0,
        "成交额": 91685217.0,
        "振幅": 2.89,
        "换手率": 0.83,
        "symbol": "105.NTNX"
    },
    {
        "序号": 1187,
        "name": "Graham Corp",
        "最新价": 17.97,
        "涨跌额": 0.41,
        "涨跌幅": 2.33,
        "开盘价": 17.69,
        "最高价": 18.01,
        "最低价": 17.56,
        "昨收价": 17.56,
        "总市值": 192328076.0,
        "市盈率": 65.46,
        "成交量": 41056.0,
        "成交额": 731379.0,
        "振幅": 2.56,
        "换手率": 0.38,
        "symbol": "106.GHM"
    },
    {
        "序号": 1188,
        "name": "Alto Ingredients Inc",
        "最新价": 2.63,
        "涨跌额": 0.06,
        "涨跌幅": 2.33,
        "开盘价": 2.57,
        "最高价": 2.66,
        "最低价": 2.57,
        "昨收价": 2.57,
        "总市值": 200248189.0,
        "市盈率": -4.75,
        "成交量": 612578.0,
        "成交额": 1603963.0,
        "振幅": 3.5,
        "换手率": 0.8,
        "symbol": "105.ALTO"
    },
    {
        "序号": 1189,
        "name": "Sibanye Stillwater Ltd ADR",
        "最新价": 4.83,
        "涨跌额": 0.11,
        "涨跌幅": 2.33,
        "开盘价": 4.61,
        "最高价": 4.85,
        "最低价": 4.6,
        "昨收价": 4.72,
        "总市值": 3417909971.0,
        "市盈率": 4.8,
        "成交量": 7049249.0,
        "成交额": 33585965.0,
        "振幅": 5.3,
        "换手率": 1.0,
        "symbol": "106.SBSW"
    },
    {
        "序号": 1190,
        "name": "Innovative Industrial Propertie",
        "最新价": 87.88,
        "涨跌额": 2.0,
        "涨跌幅": 2.33,
        "开盘价": 85.59,
        "最高价": 88.65,
        "最低价": 85.59,
        "昨收价": 85.88,
        "总市值": 2464140260.0,
        "市盈率": 14.89,
        "成交量": 391000.0,
        "成交额": 34311039.0,
        "振幅": 3.56,
        "换手率": 1.39,
        "symbol": "106.IIPR"
    },
    {
        "序号": 1191,
        "name": "WM Technology Inc-A",
        "最新价": 0.88,
        "涨跌额": 0.02,
        "涨跌幅": 2.33,
        "开盘价": 0.86,
        "最高价": 0.88,
        "最低价": 0.83,
        "昨收价": 0.86,
        "总市值": 131443392.0,
        "市盈率": -1.18,
        "成交量": 611143.0,
        "成交额": 528820.0,
        "振幅": 5.81,
        "换手率": 0.41,
        "symbol": "105.MAPS"
    },
    {
        "序号": 1192,
        "name": "Intercure Ltd",
        "最新价": 1.32,
        "涨跌额": 0.03,
        "涨跌幅": 2.33,
        "开盘价": 1.31,
        "最高价": 1.32,
        "最低价": 1.31,
        "昨收价": 1.29,
        "总市值": 60155949.0,
        "市盈率": 10.46,
        "成交量": 10866.0,
        "成交额": 14279.0,
        "振幅": 0.78,
        "换手率": 0.02,
        "symbol": "105.INCR"
    },
    {
        "序号": 1193,
        "name": "AYRO Inc",
        "最新价": 2.2,
        "涨跌额": 0.05,
        "涨跌幅": 2.33,
        "开盘价": 2.12,
        "最高价": 2.22,
        "最低价": 2.12,
        "昨收价": 2.15,
        "总市值": 10758301.0,
        "市盈率": -0.35,
        "成交量": 19788.0,
        "成交额": 43105.0,
        "振幅": 4.65,
        "换手率": 0.4,
        "symbol": "105.AYRO"
    },
    {
        "序号": 1194,
        "name": "CorVel Corp",
        "最新价": 224.42,
        "涨跌额": 5.1,
        "涨跌幅": 2.33,
        "开盘价": 218.29,
        "最高价": 224.42,
        "最低价": 218.29,
        "昨收价": 219.32,
        "总市值": 3838896428.0,
        "市盈率": 51.38,
        "成交量": 28434.0,
        "成交额": 6341636.0,
        "振幅": 2.8,
        "换手率": 0.17,
        "symbol": "105.CRVL"
    },
    {
        "序号": 1195,
        "name": "KraneShares CICC China 5G and T",
        "最新价": 14.53,
        "涨跌额": 0.33,
        "涨跌幅": 2.32,
        "开盘价": 14.47,
        "最高价": 14.61,
        "最低价": 14.47,
        "昨收价": 14.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 21884.0,
        "成交额": 318840.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.KFVG"
    },
    {
        "序号": 1196,
        "name": "MicroSectors FANG & Innovation ",
        "最新价": 88.96,
        "涨跌额": 2.02,
        "涨跌幅": 2.32,
        "开盘价": 85.51,
        "最高价": 89.16,
        "最低价": 85.51,
        "昨收价": 86.94,
        "总市值": 64132078963.0,
        "市盈率": 20.21,
        "成交量": 236596.0,
        "成交额": 20818628.0,
        "振幅": 4.2,
        "换手率": null,
        "symbol": "107.BULZ"
    },
    {
        "序号": 1197,
        "name": "DiamondRock Hospitality Co Seri",
        "最新价": 25.99,
        "涨跌额": 0.59,
        "涨跌幅": 2.32,
        "开盘价": 25.85,
        "最高价": 26.0,
        "最低价": 25.77,
        "昨收价": 25.4,
        "总市值": 123712400.0,
        "市盈率": null,
        "成交量": 1809.0,
        "成交额": 46815.0,
        "振幅": 0.91,
        "换手率": 0.04,
        "symbol": "106.DRH_A"
    },
    {
        "序号": 1198,
        "name": "景顺抵押资本",
        "最新价": 8.37,
        "涨跌额": 0.19,
        "涨跌幅": 2.32,
        "开盘价": 8.13,
        "最高价": 8.41,
        "最低价": 8.09,
        "昨收价": 8.18,
        "总市值": 405615440.0,
        "市盈率": -61.48,
        "成交量": 1321468.0,
        "成交额": 10998707.0,
        "振幅": 3.91,
        "换手率": 2.73,
        "symbol": "106.IVR"
    },
    {
        "序号": 1199,
        "name": "BILL Holdings Inc",
        "最新价": 70.98,
        "涨跌额": 1.61,
        "涨跌幅": 2.32,
        "开盘价": 68.73,
        "最高价": 71.49,
        "最低价": 68.5,
        "昨收价": 69.37,
        "总市值": 7577519160.0,
        "市盈率": -44.59,
        "成交量": 1795355.0,
        "成交额": 127227585.0,
        "振幅": 4.31,
        "换手率": 1.68,
        "symbol": "106.BILL"
    },
    {
        "序号": 1200,
        "name": "Vigil Neuroscience Inc",
        "最新价": 3.53,
        "涨跌额": 0.08,
        "涨跌幅": 2.32,
        "开盘价": 3.42,
        "最高价": 3.6,
        "最低价": 3.37,
        "昨收价": 3.45,
        "总市值": 126647296.0,
        "市盈率": -1.6,
        "成交量": 76012.0,
        "成交额": 265512.0,
        "振幅": 6.67,
        "换手率": 0.21,
        "symbol": "105.VIGL"
    },
    {
        "序号": 1201,
        "name": "投资者不动产",
        "最新价": 146.98,
        "涨跌额": 3.33,
        "涨跌幅": 2.32,
        "开盘价": 146.98,
        "最高价": 146.98,
        "最低价": 146.98,
        "昨收价": 143.65,
        "总市值": 277883769.0,
        "市盈率": 11.89,
        "成交量": 818.0,
        "成交额": 120229.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.ITIC"
    },
    {
        "序号": 1202,
        "name": "Elastic NV",
        "最新价": 115.81,
        "涨跌额": 2.62,
        "涨跌幅": 2.31,
        "开盘价": 112.74,
        "最高价": 118.24,
        "最低价": 112.49,
        "昨收价": 113.19,
        "总市值": 11538207742.0,
        "市盈率": -59.9,
        "成交量": 2501509.0,
        "成交额": 291608064.0,
        "振幅": 5.08,
        "换手率": 2.51,
        "symbol": "106.ESTC"
    },
    {
        "序号": 1203,
        "name": "CEL-SCI Corp",
        "最新价": 2.66,
        "涨跌额": 0.06,
        "涨跌幅": 2.31,
        "开盘价": 2.62,
        "最高价": 2.79,
        "最低价": 2.62,
        "昨收价": 2.6,
        "总市值": 132948939.0,
        "市盈率": -4.03,
        "成交量": 309570.0,
        "成交额": 830853.0,
        "振幅": 6.54,
        "换手率": 0.62,
        "symbol": "107.CVM"
    },
    {
        "序号": 1204,
        "name": "Universal Health Services Inc-B",
        "最新价": 137.45,
        "涨跌额": 3.1,
        "涨跌幅": 2.31,
        "开盘价": 134.58,
        "最高价": 137.77,
        "最低价": 133.7,
        "昨收价": 134.35,
        "总市值": 9382158865.0,
        "市盈率": 13.87,
        "成交量": 401316.0,
        "成交额": 54944370.0,
        "振幅": 3.03,
        "换手率": 0.59,
        "symbol": "106.UHS"
    },
    {
        "序号": 1205,
        "name": "Obsidian Energy Ltd",
        "最新价": 7.11,
        "涨跌额": 0.16,
        "涨跌幅": 2.3,
        "开盘价": 7.02,
        "最高价": 7.11,
        "最低价": 6.94,
        "昨收价": 6.95,
        "总市值": 562177305.0,
        "市盈率": 1.08,
        "成交量": 496578.0,
        "成交额": 3488999.0,
        "振幅": 2.45,
        "换手率": 0.63,
        "symbol": "107.OBE"
    },
    {
        "序号": 1206,
        "name": "希捷科技",
        "最新价": 80.59,
        "涨跌额": 1.81,
        "涨跌幅": 2.3,
        "开盘价": 78.48,
        "最高价": 80.75,
        "最低价": 78.38,
        "昨收价": 78.78,
        "总市值": 16858137432.0,
        "市盈率": -22.72,
        "成交量": 2359958.0,
        "成交额": 189331017.0,
        "振幅": 3.01,
        "换手率": 1.13,
        "symbol": "105.STX"
    },
    {
        "序号": 1207,
        "name": "Lazard Ltd-A",
        "最新价": 32.51,
        "涨跌额": 0.73,
        "涨跌幅": 2.3,
        "开盘价": 31.76,
        "最高价": 32.73,
        "最低价": 31.52,
        "昨收价": 31.78,
        "总市值": 2841857424.0,
        "市盈率": -29.39,
        "成交量": 831907.0,
        "成交额": 26908927.0,
        "振幅": 3.81,
        "换手率": 0.95,
        "symbol": "106.LAZ"
    },
    {
        "序号": 1208,
        "name": "Karooooo Ltd",
        "最新价": 25.85,
        "涨跌额": 0.58,
        "涨跌幅": 2.3,
        "开盘价": 25.0,
        "最高价": 25.85,
        "最低价": 25.0,
        "昨收价": 25.27,
        "总市值": 800086090.0,
        "市盈率": 23.22,
        "成交量": 14345.0,
        "成交额": 367072.0,
        "振幅": 3.36,
        "换手率": 0.05,
        "symbol": "105.KARO"
    },
    {
        "序号": 1209,
        "name": "Genworth金融",
        "最新价": 6.25,
        "涨跌额": 0.14,
        "涨跌幅": 2.29,
        "开盘价": 6.11,
        "最高价": 6.25,
        "最低价": 6.09,
        "昨收价": 6.11,
        "总市值": 2818779544.0,
        "市盈率": 7.79,
        "成交量": 2871480.0,
        "成交额": 17813597.0,
        "振幅": 2.62,
        "换手率": 0.64,
        "symbol": "106.GNW"
    },
    {
        "序号": 1210,
        "name": "Orion Group Holdings Inc",
        "最新价": 4.47,
        "涨跌额": 0.1,
        "涨跌幅": 2.29,
        "开盘价": 4.43,
        "最高价": 4.67,
        "最低价": 4.31,
        "昨收价": 4.37,
        "总市值": 145235262.0,
        "市盈率": -7.87,
        "成交量": 85225.0,
        "成交额": 379304.0,
        "振幅": 8.24,
        "换手率": 0.26,
        "symbol": "106.ORN"
    },
    {
        "序号": 1211,
        "name": "Ooma Inc",
        "最新价": 10.31,
        "涨跌额": 0.23,
        "涨跌幅": 2.28,
        "开盘价": 10.15,
        "最高价": 10.37,
        "最低价": 10.05,
        "昨收价": 10.08,
        "总市值": 265998000.0,
        "市盈率": 146.72,
        "成交量": 125703.0,
        "成交额": 1291309.0,
        "振幅": 3.17,
        "换手率": 0.49,
        "symbol": "106.OOMA"
    },
    {
        "序号": 1212,
        "name": "Security National Financial Cor",
        "最新价": 8.52,
        "涨跌额": 0.19,
        "涨跌幅": 2.28,
        "开盘价": 8.24,
        "最高价": 8.66,
        "最低价": 8.24,
        "昨收价": 8.33,
        "总市值": 195788492.0,
        "市盈率": 5.96,
        "成交量": 22844.0,
        "成交额": 191693.0,
        "振幅": 5.04,
        "换手率": 0.1,
        "symbol": "105.SNFCA"
    },
    {
        "序号": 1213,
        "name": "联合租赁",
        "最新价": 495.06,
        "涨跌额": 11.04,
        "涨跌幅": 2.28,
        "开盘价": 483.81,
        "最高价": 498.96,
        "最低价": 483.47,
        "昨收价": 484.02,
        "总市值": 33555884637.0,
        "市盈率": 14.08,
        "成交量": 556660.0,
        "成交额": 275297568.0,
        "振幅": 3.2,
        "换手率": 0.82,
        "symbol": "106.URI"
    },
    {
        "序号": 1214,
        "name": "Endava plc ADR",
        "最新价": 69.54,
        "涨跌额": 1.55,
        "涨跌幅": 2.28,
        "开盘价": 67.59,
        "最高价": 71.67,
        "最低价": 67.43,
        "昨收价": 67.99,
        "总市值": 4016003775.0,
        "市盈率": 41.62,
        "成交量": 724323.0,
        "成交额": 50422541.0,
        "振幅": 6.24,
        "换手率": 1.25,
        "symbol": "106.DAVA"
    },
    {
        "序号": 1215,
        "name": "Uranium Energy Corp",
        "最新价": 6.74,
        "涨跌额": 0.15,
        "涨跌幅": 2.28,
        "开盘价": 6.62,
        "最高价": 6.8,
        "最低价": 6.6,
        "昨收价": 6.59,
        "总市值": 2600618506.0,
        "市盈率": -786.4,
        "成交量": 5114755.0,
        "成交额": 34411115.0,
        "振幅": 3.03,
        "换手率": 1.33,
        "symbol": "107.UEC"
    },
    {
        "序号": 1216,
        "name": "Adeia Inc",
        "最新价": 9.89,
        "涨跌额": 0.22,
        "涨跌幅": 2.28,
        "开盘价": 9.64,
        "最高价": 9.91,
        "最低价": 9.62,
        "昨收价": 9.67,
        "总市值": 1059938883.0,
        "市盈率": 8.25,
        "成交量": 270339.0,
        "成交额": 2660423.0,
        "振幅": 3.0,
        "换手率": 0.25,
        "symbol": "105.ADEA"
    },
    {
        "序号": 1217,
        "name": "云米",
        "最新价": 0.9,
        "涨跌额": 0.02,
        "涨跌幅": 2.27,
        "开盘价": 0.92,
        "最高价": 0.92,
        "最低价": 0.88,
        "昨收价": 0.88,
        "总市值": 61831654.0,
        "市盈率": -1.83,
        "成交量": 130287.0,
        "成交额": 115582.0,
        "振幅": 4.55,
        "换手率": 0.19,
        "symbol": "105.VIOT"
    },
    {
        "序号": 1218,
        "name": "Target Hospitality Corp Wt",
        "最新价": 0.9,
        "涨跌额": 0.02,
        "涨跌幅": 2.27,
        "开盘价": 1.04,
        "最高价": 1.04,
        "最低价": 0.9,
        "昨收价": 0.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 20780.0,
        "成交额": 19673.0,
        "振幅": 15.91,
        "换手率": null,
        "symbol": "105.THWWW"
    },
    {
        "序号": 1219,
        "name": "NET Power Inc Wt",
        "最新价": 2.25,
        "涨跌额": 0.05,
        "涨跌幅": 2.27,
        "开盘价": 2.33,
        "最高价": 2.33,
        "最低价": 2.25,
        "昨收价": 2.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 850.0,
        "成交额": 1947.0,
        "振幅": 3.64,
        "换手率": null,
        "symbol": "106.NPWR_WS"
    },
    {
        "序号": 1220,
        "name": "乐信",
        "最新价": 1.8,
        "涨跌额": 0.04,
        "涨跌幅": 2.27,
        "开盘价": 1.77,
        "最高价": 1.82,
        "最低价": 1.76,
        "昨收价": 1.76,
        "总市值": 293449670.0,
        "市盈率": 1.53,
        "成交量": 311561.0,
        "成交额": 558657.0,
        "振幅": 3.41,
        "换手率": 0.19,
        "symbol": "105.LX"
    },
    {
        "序号": 1221,
        "name": "ProShares Ultra Semiconductors",
        "最新价": 45.5,
        "涨跌额": 1.01,
        "涨跌幅": 2.27,
        "开盘价": 44.47,
        "最高价": 45.81,
        "最低价": 44.45,
        "昨收价": 44.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 175553.0,
        "成交额": 7961849.0,
        "振幅": 3.06,
        "换手率": null,
        "symbol": "107.USD"
    },
    {
        "序号": 1222,
        "name": "Sterling Infrastructure Inc",
        "最新价": 69.49,
        "涨跌额": 1.54,
        "涨跌幅": 2.27,
        "开盘价": 67.95,
        "最高价": 69.72,
        "最低价": 67.95,
        "昨收价": 67.95,
        "总市值": 2142947004.0,
        "市盈率": 16.46,
        "成交量": 253657.0,
        "成交额": 17588374.0,
        "振幅": 2.6,
        "换手率": 0.82,
        "symbol": "105.STRL"
    },
    {
        "序号": 1223,
        "name": "Ouster Inc",
        "最新价": 5.87,
        "涨跌额": 0.13,
        "涨跌幅": 2.26,
        "开盘价": 5.7,
        "最高价": 5.95,
        "最低价": 5.52,
        "昨收价": 5.74,
        "总市值": 238863742.0,
        "市盈率": -0.63,
        "成交量": 515533.0,
        "成交额": 2976958.0,
        "振幅": 7.49,
        "换手率": 1.27,
        "symbol": "106.OUST"
    },
    {
        "序号": 1224,
        "name": "LifeVantage Corp",
        "最新价": 6.79,
        "涨跌额": 0.15,
        "涨跌幅": 2.26,
        "开盘价": 6.7,
        "最高价": 7.0,
        "最低价": 6.64,
        "昨收价": 6.64,
        "总市值": 86327293.0,
        "市盈率": 33.73,
        "成交量": 31623.0,
        "成交额": 215996.0,
        "振幅": 5.42,
        "换手率": 0.25,
        "symbol": "105.LFVN"
    },
    {
        "序号": 1225,
        "name": "Mondee Holdings Inc-A",
        "最新价": 3.17,
        "涨跌额": 0.07,
        "涨跌幅": 2.26,
        "开盘价": 3.17,
        "最高价": 3.25,
        "最低价": 3.07,
        "昨收价": 3.1,
        "总市值": 272050557.0,
        "市盈率": -4.24,
        "成交量": 209356.0,
        "成交额": 664002.0,
        "振幅": 5.81,
        "换手率": 0.24,
        "symbol": "105.MOND"
    },
    {
        "序号": 1226,
        "name": "Atlanta Braves Holdings Inc-C",
        "最新价": 37.16,
        "涨跌额": 0.82,
        "涨跌幅": 2.26,
        "开盘价": 36.25,
        "最高价": 37.29,
        "最低价": 36.25,
        "昨收价": 36.34,
        "总市值": 2293657486.0,
        "市盈率": null,
        "成交量": 168411.0,
        "成交额": 6184149.0,
        "振幅": 2.86,
        "换手率": 0.33,
        "symbol": "105.BATRK"
    },
    {
        "序号": 1227,
        "name": "Ginkgo Bioworks Holdings Inc-A",
        "最新价": 1.36,
        "涨跌额": 0.03,
        "涨跌幅": 2.26,
        "开盘价": 1.3,
        "最高价": 1.37,
        "最低价": 1.28,
        "昨收价": 1.33,
        "总市值": 2881073716.0,
        "市盈率": -3.36,
        "成交量": 17912783.0,
        "成交额": 23842438.0,
        "振幅": 6.77,
        "换手率": 0.85,
        "symbol": "106.DNA"
    },
    {
        "序号": 1228,
        "name": "Upwork Inc",
        "最新价": 14.06,
        "涨跌额": 0.31,
        "涨跌幅": 2.25,
        "开盘价": 13.64,
        "最高价": 14.1,
        "最低价": 13.58,
        "昨收价": 13.75,
        "总市值": 1913699528.0,
        "市盈率": 147.06,
        "成交量": 1160824.0,
        "成交额": 16250925.0,
        "振幅": 3.78,
        "换手率": 0.85,
        "symbol": "105.UPWK"
    },
    {
        "序号": 1229,
        "name": "美国汽油基金",
        "最新价": 59.44,
        "涨跌额": 1.31,
        "涨跌幅": 2.25,
        "开盘价": 59.14,
        "最高价": 59.66,
        "最低价": 58.99,
        "昨收价": 58.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 33232.0,
        "成交额": 1971519.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.UGA"
    },
    {
        "序号": 1230,
        "name": "C&F金融",
        "最新价": 60.84,
        "涨跌额": 1.34,
        "涨跌幅": 2.25,
        "开盘价": 58.73,
        "最高价": 61.45,
        "最低价": 58.73,
        "昨收价": 59.5,
        "总市值": 204937289.0,
        "市盈率": 7.11,
        "成交量": 7794.0,
        "成交额": 465495.0,
        "振幅": 4.57,
        "换手率": 0.23,
        "symbol": "105.CFFI"
    },
    {
        "序号": 1231,
        "name": "IAC Inc",
        "最新价": 49.07,
        "涨跌额": 1.08,
        "涨跌幅": 2.25,
        "开盘价": 48.0,
        "最高价": 49.34,
        "最低价": 47.58,
        "昨收价": 47.99,
        "总市值": 4212724321.0,
        "市盈率": -66.63,
        "成交量": 651985.0,
        "成交额": 31795079.0,
        "振幅": 3.67,
        "换手率": 0.76,
        "symbol": "105.IAC"
    },
    {
        "序号": 1232,
        "name": "TechTarget Inc",
        "最新价": 31.38,
        "涨跌额": 0.69,
        "涨跌幅": 2.25,
        "开盘价": 30.55,
        "最高价": 31.52,
        "最低价": 30.36,
        "昨收价": 30.69,
        "总市值": 890696165.0,
        "市盈率": 67.22,
        "成交量": 124116.0,
        "成交额": 3882418.0,
        "振幅": 3.78,
        "换手率": 0.44,
        "symbol": "105.TTGT"
    },
    {
        "序号": 1233,
        "name": "三倍做多半导体ETF-Direxion",
        "最新价": 23.7,
        "涨跌额": 0.52,
        "涨跌幅": 2.24,
        "开盘价": 23.01,
        "最高价": 24.04,
        "最低价": 22.95,
        "昨收价": 23.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 69286829.0,
        "成交额": 1636103664.0,
        "振幅": 4.7,
        "换手率": null,
        "symbol": "107.SOXL"
    },
    {
        "序号": 1234,
        "name": "Maiden Holdings Ltd",
        "最新价": 2.28,
        "涨跌额": 0.05,
        "涨跌幅": 2.24,
        "开盘价": 2.23,
        "最高价": 2.29,
        "最低价": 2.16,
        "昨收价": 2.23,
        "总市值": 230141467.0,
        "市盈率": -3.35,
        "成交量": 239917.0,
        "成交额": 535298.0,
        "振幅": 5.83,
        "换手率": 0.24,
        "symbol": "105.MHLD"
    },
    {
        "序号": 1235,
        "name": "迪堡金融设备",
        "最新价": 28.32,
        "涨跌额": 0.62,
        "涨跌幅": 2.24,
        "开盘价": 27.66,
        "最高价": 29.32,
        "最低价": 27.66,
        "昨收价": 27.7,
        "总市值": 1063888038.0,
        "市盈率": -1.08,
        "成交量": 186585.0,
        "成交额": 5263772.0,
        "振幅": 5.99,
        "换手率": 0.5,
        "symbol": "106.DBD"
    },
    {
        "序号": 1236,
        "name": "Direxion Daily Dow Jones Intern",
        "最新价": 12.79,
        "涨跌额": 0.28,
        "涨跌幅": 2.24,
        "开盘价": 12.26,
        "最高价": 12.85,
        "最低价": 12.23,
        "昨收价": 12.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 527673.0,
        "成交额": 6683648.0,
        "振幅": 4.96,
        "换手率": null,
        "symbol": "107.WEBL"
    },
    {
        "序号": 1237,
        "name": "Inogen Inc",
        "最新价": 5.94,
        "涨跌额": 0.13,
        "涨跌幅": 2.24,
        "开盘价": 5.8,
        "最高价": 6.07,
        "最低价": 5.8,
        "昨收价": 5.81,
        "总市值": 138448415.0,
        "市盈率": -1.04,
        "成交量": 323820.0,
        "成交额": 1929830.0,
        "振幅": 4.65,
        "换手率": 1.39,
        "symbol": "105.INGN"
    },
    {
        "序号": 1238,
        "name": "Beyond Inc",
        "最新价": 23.32,
        "涨跌额": 0.51,
        "涨跌幅": 2.24,
        "开盘价": 22.65,
        "最高价": 23.65,
        "最低价": 22.45,
        "昨收价": 22.81,
        "总市值": 1056511341.0,
        "市盈率": -6.51,
        "成交量": 1174876.0,
        "成交额": 27385070.0,
        "振幅": 5.26,
        "换手率": 2.59,
        "symbol": "106.BYON"
    },
    {
        "序号": 1239,
        "name": "Snap Inc-A",
        "最新价": 15.09,
        "涨跌额": 0.33,
        "涨跌幅": 2.24,
        "开盘价": 14.7,
        "最高价": 15.31,
        "最低价": 14.68,
        "昨收价": 14.76,
        "总市值": 24844107869.0,
        "市盈率": -18.23,
        "成交量": 20718265.0,
        "成交额": 312779568.0,
        "振幅": 4.27,
        "换手率": 1.26,
        "symbol": "106.SNAP"
    },
    {
        "序号": 1240,
        "name": "Civitas Resources Inc",
        "最新价": 69.05,
        "涨跌额": 1.51,
        "涨跌幅": 2.24,
        "开盘价": 68.27,
        "最高价": 69.2,
        "最低价": 67.89,
        "昨收价": 67.54,
        "总市值": 6475084135.0,
        "市盈率": 8.48,
        "成交量": 890351.0,
        "成交额": 61287373.0,
        "振幅": 1.94,
        "换手率": 0.95,
        "symbol": "106.CIVI"
    },
    {
        "序号": 1241,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 17.38,
        "涨跌额": 0.38,
        "涨跌幅": 2.24,
        "开盘价": 17.06,
        "最高价": 17.45,
        "最低价": 17.06,
        "昨收价": 17.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 40420.0,
        "成交额": 697103.0,
        "振幅": 2.29,
        "换手率": null,
        "symbol": "105.RILYG"
    },
    {
        "序号": 1242,
        "name": "Asure Software Inc",
        "最新价": 7.78,
        "涨跌额": 0.17,
        "涨跌幅": 2.23,
        "开盘价": 7.72,
        "最高价": 7.84,
        "最低价": 7.63,
        "昨收价": 7.61,
        "总市值": 193342320.0,
        "市盈率": -28.91,
        "成交量": 161523.0,
        "成交额": 1253392.0,
        "振幅": 2.76,
        "换手率": 0.65,
        "symbol": "105.ASUR"
    },
    {
        "序号": 1243,
        "name": "VOC Energy Trust",
        "最新价": 7.79,
        "涨跌额": 0.17,
        "涨跌幅": 2.23,
        "开盘价": 7.72,
        "最高价": 7.81,
        "最低价": 7.5,
        "昨收价": 7.62,
        "总市值": 132430000.0,
        "市盈率": 7.53,
        "成交量": 47080.0,
        "成交额": 363108.0,
        "振幅": 4.07,
        "换手率": 0.28,
        "symbol": "106.VOC"
    },
    {
        "序号": 1244,
        "name": "NeoGenomics Inc",
        "最新价": 18.34,
        "涨跌额": 0.4,
        "涨跌幅": 2.23,
        "开盘价": 17.77,
        "最高价": 18.35,
        "最低价": 17.77,
        "昨收价": 17.94,
        "总市值": 2337723139.0,
        "市盈率": -24.27,
        "成交量": 582598.0,
        "成交额": 10576396.0,
        "振幅": 3.23,
        "换手率": 0.46,
        "symbol": "105.NEO"
    },
    {
        "序号": 1245,
        "name": "Primoris Services Corp",
        "最新价": 32.13,
        "涨跌额": 0.7,
        "涨跌幅": 2.23,
        "开盘价": 31.33,
        "最高价": 32.16,
        "最低价": 31.33,
        "昨收价": 31.43,
        "总市值": 1714470005.0,
        "市盈率": 13.19,
        "成交量": 288624.0,
        "成交额": 9219032.0,
        "振幅": 2.64,
        "换手率": 0.54,
        "symbol": "106.PRIM"
    },
    {
        "序号": 1246,
        "name": "Hope Bancorp Inc",
        "最新价": 11.03,
        "涨跌额": 0.24,
        "涨跌幅": 2.22,
        "开盘价": 10.86,
        "最高价": 11.05,
        "最低价": 10.78,
        "昨收价": 10.79,
        "总市值": 1324790203.0,
        "市盈率": 8.34,
        "成交量": 860042.0,
        "成交额": 9464822.0,
        "振幅": 2.5,
        "换手率": 0.72,
        "symbol": "105.HOPE"
    },
    {
        "序号": 1247,
        "name": "NextNav Inc Wt",
        "最新价": 0.92,
        "涨跌额": 0.02,
        "涨跌幅": 2.22,
        "开盘价": 0.92,
        "最高价": 0.92,
        "最低价": 0.92,
        "昨收价": 0.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 2002.0,
        "成交额": 1841.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NNAVW"
    },
    {
        "序号": 1248,
        "name": "Direxion Daily 7-10 Year Treasu",
        "最新价": 14.27,
        "涨跌额": 0.31,
        "涨跌幅": 2.22,
        "开盘价": 14.24,
        "最高价": 14.34,
        "最低价": 14.18,
        "昨收价": 13.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 49626.0,
        "成交额": 706052.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.TYO"
    },
    {
        "序号": 1249,
        "name": "First Northwest Bancorp",
        "最新价": 15.21,
        "涨跌额": 0.33,
        "涨跌幅": 2.22,
        "开盘价": 15.0,
        "最高价": 15.38,
        "最低价": 14.91,
        "昨收价": 14.88,
        "总市值": 146296472.0,
        "市盈率": 10.55,
        "成交量": 9212.0,
        "成交额": 139246.0,
        "振幅": 3.16,
        "换手率": 0.1,
        "symbol": "105.FNWB"
    },
    {
        "序号": 1250,
        "name": "The Vita Coco Co Inc",
        "最新价": 27.2,
        "涨跌额": 0.59,
        "涨跌幅": 2.22,
        "开盘价": 26.54,
        "最高价": 27.59,
        "最低价": 26.54,
        "昨收价": 26.61,
        "总市值": 1545036296.0,
        "市盈率": 41.71,
        "成交量": 406747.0,
        "成交额": 11062326.0,
        "振幅": 3.95,
        "换手率": 0.72,
        "symbol": "105.COCO"
    },
    {
        "序号": 1251,
        "name": "捷普",
        "最新价": 119.0,
        "涨跌额": 2.58,
        "涨跌幅": 2.22,
        "开盘价": 116.38,
        "最高价": 119.86,
        "最低价": 116.38,
        "昨收价": 116.42,
        "总市值": 15225462616.0,
        "市盈率": 18.61,
        "成交量": 1833573.0,
        "成交额": 217755629.0,
        "振幅": 2.99,
        "换手率": 1.43,
        "symbol": "106.JBL"
    },
    {
        "序号": 1252,
        "name": "Jamf Holding Corp",
        "最新价": 17.07,
        "涨跌额": 0.37,
        "涨跌幅": 2.22,
        "开盘价": 16.62,
        "最高价": 17.13,
        "最低价": 16.53,
        "昨收价": 16.7,
        "总市值": 2147333828.0,
        "市盈率": -18.85,
        "成交量": 254550.0,
        "成交额": 4328232.0,
        "振幅": 3.59,
        "换手率": 0.2,
        "symbol": "105.JAMF"
    },
    {
        "序号": 1253,
        "name": "Coupang Inc-A",
        "最新价": 15.7,
        "涨跌额": 0.34,
        "涨跌幅": 2.21,
        "开盘价": 15.26,
        "最高价": 15.71,
        "最低价": 15.23,
        "昨收价": 15.36,
        "总市值": 28068263734.0,
        "市盈率": 65.36,
        "成交量": 11762648.0,
        "成交额": 183374708.0,
        "振幅": 3.13,
        "换手率": 0.66,
        "symbol": "106.CPNG"
    },
    {
        "序号": 1254,
        "name": "优倍快",
        "最新价": 122.86,
        "涨跌额": 2.66,
        "涨跌幅": 2.21,
        "开盘价": 121.43,
        "最高价": 123.82,
        "最低价": 120.26,
        "昨收价": 120.2,
        "总市值": 7426650372.0,
        "市盈率": 18.46,
        "成交量": 91277.0,
        "成交额": 11174433.0,
        "振幅": 2.96,
        "换手率": 0.15,
        "symbol": "106.UI"
    },
    {
        "序号": 1255,
        "name": "丹尼森矿业",
        "最新价": 1.85,
        "涨跌额": 0.04,
        "涨跌幅": 2.21,
        "开盘价": 1.82,
        "最高价": 1.86,
        "最低价": 1.8,
        "昨收价": 1.81,
        "总市值": 1643921479.0,
        "市盈率": 43.78,
        "成交量": 9254809.0,
        "成交额": 17028269.0,
        "振幅": 3.31,
        "换手率": 1.04,
        "symbol": "107.DNN"
    },
    {
        "序号": 1256,
        "name": "然健环球",
        "最新价": 5.56,
        "涨跌额": 0.12,
        "涨跌幅": 2.21,
        "开盘价": 5.45,
        "最高价": 5.58,
        "最低价": 5.44,
        "昨收价": 5.44,
        "总市值": 64033258.0,
        "市盈率": 160.89,
        "成交量": 18900.0,
        "成交额": 103330.0,
        "振幅": 2.57,
        "换手率": 0.16,
        "symbol": "105.NHTC"
    },
    {
        "序号": 1257,
        "name": "Golden Matrix Group Inc",
        "最新价": 2.32,
        "涨跌额": 0.05,
        "涨跌幅": 2.2,
        "开盘价": 2.29,
        "最高价": 2.32,
        "最低价": 2.27,
        "昨收价": 2.27,
        "总市值": 83833042.0,
        "市盈率": -22.31,
        "成交量": 3435.0,
        "成交额": 7847.0,
        "振幅": 2.2,
        "换手率": 0.01,
        "symbol": "105.GMGI"
    },
    {
        "序号": 1258,
        "name": "海上麦斯服务",
        "最新价": 33.41,
        "涨跌额": 0.72,
        "涨跌幅": 2.2,
        "开盘价": 32.69,
        "最高价": 33.74,
        "最低价": 32.69,
        "昨收价": 32.69,
        "总市值": 740737821.0,
        "市盈率": 6.78,
        "成交量": 165172.0,
        "成交额": 5519385.0,
        "振幅": 3.21,
        "换手率": 0.74,
        "symbol": "106.HZO"
    },
    {
        "序号": 1259,
        "name": "詹姆斯金融银行",
        "最新价": 10.69,
        "涨跌额": 0.23,
        "涨跌幅": 2.2,
        "开盘价": 10.64,
        "最高价": 10.7,
        "最低价": 10.64,
        "昨收价": 10.46,
        "总市值": 48568283.0,
        "市盈率": 5.68,
        "成交量": 2353.0,
        "成交额": 25152.0,
        "振幅": 0.57,
        "换手率": 0.05,
        "symbol": "105.BOTJ"
    },
    {
        "序号": 1260,
        "name": "正羽科技",
        "最新价": 69.26,
        "涨跌额": 1.49,
        "涨跌幅": 2.2,
        "开盘价": 67.77,
        "最高价": 69.81,
        "最低价": 67.77,
        "昨收价": 67.77,
        "总市值": 1865313506.0,
        "市盈率": 13.81,
        "成交量": 97447.0,
        "成交额": 6741437.0,
        "振幅": 3.01,
        "换手率": 0.36,
        "symbol": "105.PLUS"
    },
    {
        "序号": 1261,
        "name": "ARK 21Shares Active Bitcoin Eth",
        "最新价": 29.75,
        "涨跌额": 0.64,
        "涨跌幅": 2.2,
        "开盘价": 29.35,
        "最高价": 29.79,
        "最低价": 29.32,
        "昨收价": 29.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 16036.0,
        "成交额": 474486.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "107.ARKY"
    },
    {
        "序号": 1262,
        "name": "Structured Products Corp CorTS ",
        "最新价": 28.36,
        "涨跌额": 0.61,
        "涨跌幅": 2.2,
        "开盘价": 27.82,
        "最高价": 28.36,
        "最低价": 27.82,
        "昨收价": 27.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1400.0,
        "成交额": 39038.0,
        "振幅": 1.95,
        "换手率": null,
        "symbol": "106.KTH"
    },
    {
        "序号": 1263,
        "name": "纳普科安全技术",
        "最新价": 32.11,
        "涨跌额": 0.69,
        "涨跌幅": 2.2,
        "开盘价": 31.42,
        "最高价": 32.37,
        "最低价": 31.36,
        "昨收价": 31.42,
        "总市值": 1180687815.0,
        "市盈率": 34.2,
        "成交量": 318553.0,
        "成交额": 10242373.0,
        "振幅": 3.21,
        "换手率": 0.87,
        "symbol": "105.NSSC"
    },
    {
        "序号": 1264,
        "name": "卡尔普纺织",
        "最新价": 5.59,
        "涨跌额": 0.12,
        "涨跌幅": 2.19,
        "开盘价": 5.5,
        "最高价": 5.66,
        "最低价": 5.4,
        "昨收价": 5.47,
        "总市值": 69706758.0,
        "市盈率": -3.59,
        "成交量": 29803.0,
        "成交额": 165186.0,
        "振幅": 4.75,
        "换手率": 0.24,
        "symbol": "106.CULP"
    },
    {
        "序号": 1265,
        "name": "联邦快递",
        "最新价": 272.21,
        "涨跌额": 5.84,
        "涨跌幅": 2.19,
        "开盘价": 267.42,
        "最高价": 273.76,
        "最低价": 267.38,
        "昨收价": 266.37,
        "总市值": 68439159878.0,
        "市盈率": 16.39,
        "成交量": 2046438.0,
        "成交额": 556767408.0,
        "振幅": 2.4,
        "换手率": 0.81,
        "symbol": "106.FDX"
    },
    {
        "序号": 1266,
        "name": "阿拉莫",
        "最新价": 192.12,
        "涨跌额": 4.12,
        "涨跌幅": 2.19,
        "开盘价": 188.91,
        "最高价": 192.86,
        "最低价": 187.1,
        "昨收价": 188.0,
        "总市值": 2307703366.0,
        "市盈率": 17.25,
        "成交量": 55750.0,
        "成交额": 10658959.0,
        "振幅": 3.06,
        "换手率": 0.46,
        "symbol": "106.ALG"
    },
    {
        "序号": 1267,
        "name": "Perimeter Solutions SA",
        "最新价": 4.2,
        "涨跌额": 0.09,
        "涨跌幅": 2.19,
        "开盘价": 4.12,
        "最高价": 4.29,
        "最低价": 4.08,
        "昨收价": 4.11,
        "总市值": 641694052.0,
        "市盈率": 31.51,
        "成交量": 1448740.0,
        "成交额": 6064887.0,
        "振幅": 5.11,
        "换手率": 0.95,
        "symbol": "106.PRM"
    },
    {
        "序号": 1268,
        "name": "贝哲房屋",
        "最新价": 29.88,
        "涨跌额": 0.64,
        "涨跌幅": 2.19,
        "开盘价": 29.13,
        "最高价": 30.21,
        "最低价": 29.09,
        "昨收价": 29.24,
        "总市值": 935930911.0,
        "市盈率": 5.9,
        "成交量": 286535.0,
        "成交额": 8542949.0,
        "振幅": 3.83,
        "换手率": 0.91,
        "symbol": "106.BZH"
    },
    {
        "序号": 1269,
        "name": "华斯科",
        "最新价": 411.85,
        "涨跌额": 8.82,
        "涨跌幅": 2.19,
        "开盘价": 407.5,
        "最高价": 413.65,
        "最低价": 407.35,
        "昨收价": 403.03,
        "总市值": 16224511566.0,
        "市盈率": 27.43,
        "成交量": 283173.0,
        "成交额": 116529774.0,
        "振幅": 1.56,
        "换手率": 0.84,
        "symbol": "106.WSO"
    },
    {
        "序号": 1270,
        "name": "Talkspace Inc-A",
        "最新价": 2.34,
        "涨跌额": 0.05,
        "涨跌幅": 2.18,
        "开盘价": 2.31,
        "最高价": 2.38,
        "最低价": 2.2,
        "昨收价": 2.29,
        "总市值": 391002344.0,
        "市盈率": -10.81,
        "成交量": 1059741.0,
        "成交额": 2448231.0,
        "振幅": 7.86,
        "换手率": 0.63,
        "symbol": "105.TALK"
    },
    {
        "序号": 1271,
        "name": "Globus Maritime Ltd",
        "最新价": 2.81,
        "涨跌额": 0.06,
        "涨跌幅": 2.18,
        "开盘价": 2.8,
        "最高价": 2.85,
        "最低价": 2.79,
        "昨收价": 2.75,
        "总市值": 57836266.0,
        "市盈率": 33.22,
        "成交量": 243327.0,
        "成交额": 683812.0,
        "振幅": 2.18,
        "换手率": 1.18,
        "symbol": "105.GLBS"
    },
    {
        "序号": 1272,
        "name": "Morphic Holding Inc",
        "最新价": 27.17,
        "涨跌额": 0.58,
        "涨跌幅": 2.18,
        "开盘价": 27.38,
        "最高价": 28.05,
        "最低价": 26.29,
        "昨收价": 26.59,
        "总市值": 1351516386.0,
        "市盈率": -9.96,
        "成交量": 694396.0,
        "成交额": 18999161.0,
        "振幅": 6.62,
        "换手率": 1.4,
        "symbol": "105.MORF"
    },
    {
        "序号": 1273,
        "name": "美国原油基金",
        "最新价": 66.53,
        "涨跌额": 1.42,
        "涨跌幅": 2.18,
        "开盘价": 66.11,
        "最高价": 66.9,
        "最低价": 65.92,
        "昨收价": 65.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 4055468.0,
        "成交额": 269601840.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "107.USO"
    },
    {
        "序号": 1274,
        "name": "加的斯",
        "最新价": 3.28,
        "涨跌额": 0.07,
        "涨跌幅": 2.18,
        "开盘价": 3.21,
        "最高价": 3.29,
        "最低价": 3.17,
        "昨收价": 3.21,
        "总市值": 218811408.0,
        "市盈率": -6.94,
        "成交量": 147788.0,
        "成交额": 479366.0,
        "振幅": 3.74,
        "换手率": 0.22,
        "symbol": "105.CDZI"
    },
    {
        "序号": 1275,
        "name": "Blade Air Mobility Inc-A",
        "最新价": 3.28,
        "涨跌额": 0.07,
        "涨跌幅": 2.18,
        "开盘价": 3.22,
        "最高价": 3.37,
        "最低价": 3.16,
        "昨收价": 3.21,
        "总市值": 244798713.0,
        "市盈率": -6.52,
        "成交量": 564298.0,
        "成交额": 1843882.0,
        "振幅": 6.54,
        "换手率": 0.76,
        "symbol": "105.BLDE"
    },
    {
        "序号": 1276,
        "name": "加州资源",
        "最新价": 50.19,
        "涨跌额": 1.07,
        "涨跌幅": 2.18,
        "开盘价": 49.62,
        "最高价": 50.63,
        "最低价": 49.47,
        "昨收价": 49.12,
        "总市值": 3444030322.0,
        "市盈率": 7.5,
        "成交量": 552654.0,
        "成交额": 27715359.0,
        "振幅": 2.36,
        "换手率": 0.81,
        "symbol": "106.CRC"
    },
    {
        "序号": 1277,
        "name": "Robinhood Markets Inc-A",
        "最新价": 11.73,
        "涨跌额": 0.25,
        "涨跌幅": 2.18,
        "开盘价": 11.23,
        "最高价": 11.74,
        "最低价": 11.02,
        "昨收价": 11.48,
        "总市值": 10143892473.0,
        "市盈率": -13.76,
        "成交量": 23220055.0,
        "成交额": 267693623.0,
        "振幅": 6.27,
        "换手率": 2.69,
        "symbol": "105.HOOD"
    },
    {
        "序号": 1278,
        "name": "哥伦比亚银行系统",
        "最新价": 24.9,
        "涨跌额": 0.53,
        "涨跌幅": 2.17,
        "开盘价": 24.32,
        "最高价": 24.93,
        "最低价": 24.09,
        "昨收价": 24.37,
        "总市值": 5193537071.0,
        "市盈率": 20.64,
        "成交量": 1956504.0,
        "成交额": 48377489.0,
        "振幅": 3.45,
        "换手率": 0.94,
        "symbol": "105.COLB"
    },
    {
        "序号": 1279,
        "name": "Catheter Precision Inc",
        "最新价": 0.47,
        "涨跌额": 0.01,
        "涨跌幅": 2.17,
        "开盘价": 0.47,
        "最高价": 0.49,
        "最低价": 0.47,
        "昨收价": 0.46,
        "总市值": 2522807.0,
        "市盈率": -0.03,
        "成交量": 23617.0,
        "成交额": 11393.0,
        "振幅": 4.35,
        "换手率": 0.44,
        "symbol": "107.VTAK"
    },
    {
        "序号": 1280,
        "name": "TELUS International (Cda) Inc",
        "最新价": 7.52,
        "涨跌额": 0.16,
        "涨跌幅": 2.17,
        "开盘价": 7.37,
        "最高价": 7.53,
        "最低价": 7.26,
        "昨收价": 7.36,
        "总市值": 2060480000.0,
        "市盈率": 41.21,
        "成交量": 248321.0,
        "成交额": 1847553.0,
        "振幅": 3.67,
        "换手率": 0.09,
        "symbol": "106.TIXT"
    },
    {
        "序号": 1281,
        "name": "祁连国际",
        "最新价": 0.47,
        "涨跌额": 0.01,
        "涨跌幅": 2.17,
        "开盘价": 0.44,
        "最高价": 0.5,
        "最低价": 0.44,
        "昨收价": 0.46,
        "总市值": 16802500.0,
        "市盈率": 11.32,
        "成交量": 7607.0,
        "成交额": 3684.0,
        "振幅": 13.04,
        "换手率": 0.02,
        "symbol": "105.QLI"
    },
    {
        "序号": 1282,
        "name": "PHX矿物",
        "最新价": 3.29,
        "涨跌额": 0.07,
        "涨跌幅": 2.17,
        "开盘价": 3.22,
        "最高价": 3.37,
        "最低价": 3.22,
        "昨收价": 3.22,
        "总市值": 121731119.0,
        "市盈率": 5.92,
        "成交量": 44237.0,
        "成交额": 145848.0,
        "振幅": 4.66,
        "换手率": 0.12,
        "symbol": "106.PHX"
    },
    {
        "序号": 1283,
        "name": "Comstock Inc",
        "最新价": 0.47,
        "涨跌额": 0.01,
        "涨跌幅": 2.17,
        "开盘价": 0.47,
        "最高价": 0.47,
        "最低价": 0.46,
        "昨收价": 0.46,
        "总市值": 55051206.0,
        "市盈率": -2.83,
        "成交量": 234837.0,
        "成交额": 109429.0,
        "振幅": 2.17,
        "换手率": 0.2,
        "symbol": "107.LODE"
    },
    {
        "序号": 1284,
        "name": "Fury Gold Mines Ltd",
        "最新价": 0.47,
        "涨跌额": 0.01,
        "涨跌幅": 2.17,
        "开盘价": 0.46,
        "最高价": 0.47,
        "最低价": 0.46,
        "昨收价": 0.46,
        "总市值": 68407302.0,
        "市盈率": -6.0,
        "成交量": 58098.0,
        "成交额": 26843.0,
        "振幅": 2.17,
        "换手率": 0.04,
        "symbol": "107.FURY"
    },
    {
        "序号": 1285,
        "name": "Broadwind Inc",
        "最新价": 2.35,
        "涨跌额": 0.05,
        "涨跌幅": 2.17,
        "开盘价": 2.31,
        "最高价": 2.38,
        "最低价": 2.3,
        "昨收价": 2.3,
        "总市值": 50330737.0,
        "市盈率": 13.5,
        "成交量": 70068.0,
        "成交额": 163768.0,
        "振幅": 3.48,
        "换手率": 0.33,
        "symbol": "105.BWEN"
    },
    {
        "序号": 1286,
        "name": "Butterfly Network Inc-A",
        "最新价": 0.94,
        "涨跌额": 0.02,
        "涨跌幅": 2.17,
        "开盘价": 0.91,
        "最高价": 0.95,
        "最低价": 0.91,
        "昨收价": 0.92,
        "总市值": 194696107.0,
        "市盈率": -1.58,
        "成交量": 792404.0,
        "成交额": 735173.0,
        "振幅": 4.35,
        "换手率": 0.38,
        "symbol": "106.BFLY"
    },
    {
        "序号": 1287,
        "name": "AlloVir Inc",
        "最新价": 2.35,
        "涨跌额": 0.05,
        "涨跌幅": 2.17,
        "开盘价": 2.32,
        "最高价": 2.4,
        "最低价": 2.2,
        "昨收价": 2.3,
        "总市值": 268048840.0,
        "市盈率": -1.59,
        "成交量": 1379125.0,
        "成交额": 3202383.0,
        "振幅": 8.7,
        "换手率": 1.21,
        "symbol": "105.ALVR"
    },
    {
        "序号": 1288,
        "name": "Targa Resources Corp",
        "最新价": 86.56,
        "涨跌额": 1.84,
        "涨跌幅": 2.17,
        "开盘价": 85.19,
        "最高价": 86.73,
        "最低价": 85.09,
        "昨收价": 84.72,
        "总市值": 19300767936.0,
        "市盈率": 14.15,
        "成交量": 1204748.0,
        "成交额": 104058021.0,
        "振幅": 1.94,
        "换手率": 0.54,
        "symbol": "106.TRGP"
    },
    {
        "序号": 1289,
        "name": "Kodiak Gas Services Inc",
        "最新价": 18.35,
        "涨跌额": 0.39,
        "涨跌幅": 2.17,
        "开盘价": 18.02,
        "最高价": 18.43,
        "最低价": 18.02,
        "昨收价": 17.96,
        "总市值": 1420290000.0,
        "市盈率": 49.23,
        "成交量": 195839.0,
        "成交额": 3589908.0,
        "振幅": 2.28,
        "换手率": 0.25,
        "symbol": "106.KGS"
    },
    {
        "序号": 1290,
        "name": "OUTFRONT Media Inc",
        "最新价": 12.71,
        "涨跌额": 0.27,
        "涨跌幅": 2.17,
        "开盘价": 12.4,
        "最高价": 12.86,
        "最低价": 12.35,
        "昨收价": 12.44,
        "总市值": 2097779984.0,
        "市盈率": -4.86,
        "成交量": 3555158.0,
        "成交额": 45202458.0,
        "振幅": 4.1,
        "换手率": 2.15,
        "symbol": "106.OUT"
    },
    {
        "序号": 1291,
        "name": "HBT Financial Inc",
        "最新价": 20.25,
        "涨跌额": 0.43,
        "涨跌幅": 2.17,
        "开盘价": 20.24,
        "最高价": 20.25,
        "最低价": 19.79,
        "昨收价": 19.82,
        "总市值": 642127601.0,
        "市盈率": 10.61,
        "成交量": 24431.0,
        "成交额": 490765.0,
        "振幅": 2.32,
        "换手率": 0.08,
        "symbol": "105.HBT"
    },
    {
        "序号": 1292,
        "name": "Viper Energy Inc-A",
        "最新价": 30.14,
        "涨跌额": 0.64,
        "涨跌幅": 2.17,
        "开盘价": 29.64,
        "最高价": 30.16,
        "最低价": 29.6,
        "昨收价": 29.5,
        "总市值": 5360526161.0,
        "市盈率": 32.52,
        "成交量": 1070974.0,
        "成交额": 32113622.0,
        "振幅": 1.9,
        "换手率": 0.6,
        "symbol": "105.VNOM"
    },
    {
        "序号": 1293,
        "name": "TAT Technologies Ltd",
        "最新价": 9.89,
        "涨跌额": 0.21,
        "涨跌幅": 2.17,
        "开盘价": 9.61,
        "最高价": 9.99,
        "最低价": 9.37,
        "昨收价": 9.68,
        "总市值": 88135190.0,
        "市盈率": 18.48,
        "成交量": 9181.0,
        "成交额": 90256.0,
        "振幅": 6.4,
        "换手率": 0.1,
        "symbol": "105.TATT"
    },
    {
        "序号": 1294,
        "name": "Sunoco LP",
        "最新价": 53.71,
        "涨跌额": 1.14,
        "涨跌幅": 2.17,
        "开盘价": 52.96,
        "最高价": 54.22,
        "最低价": 52.6,
        "昨收价": 52.57,
        "总市值": 5396559461.0,
        "市盈率": 9.72,
        "成交量": 876282.0,
        "成交额": 47069681.0,
        "振幅": 3.08,
        "换手率": 0.87,
        "symbol": "106.SUN"
    },
    {
        "序号": 1295,
        "name": "e家快服",
        "最新价": 4.25,
        "涨跌额": 0.09,
        "涨跌幅": 2.16,
        "开盘价": 4.22,
        "最高价": 4.36,
        "最低价": 4.2,
        "昨收价": 4.16,
        "总市值": 63419495.0,
        "市盈率": -1.81,
        "成交量": 1196516.0,
        "成交额": 5080295.0,
        "振幅": 3.85,
        "换手率": 8.02,
        "symbol": "105.EJH"
    },
    {
        "序号": 1296,
        "name": "ProShares Bitcoin & Ether Marke",
        "最新价": 61.9,
        "涨跌额": 1.31,
        "涨跌幅": 2.16,
        "开盘价": 61.35,
        "最高价": 61.9,
        "最低价": 61.28,
        "昨收价": 60.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 2355.0,
        "成交额": 144464.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.BETH"
    },
    {
        "序号": 1297,
        "name": "Ares Management Corp-A",
        "最新价": 110.64,
        "涨跌额": 2.34,
        "涨跌幅": 2.16,
        "开盘价": 108.03,
        "最高价": 111.29,
        "最低价": 108.03,
        "昨收价": 108.3,
        "总市值": 33989759873.0,
        "市盈率": 81.34,
        "成交量": 623764.0,
        "成交额": 68849444.0,
        "振幅": 3.01,
        "换手率": 0.2,
        "symbol": "106.ARES"
    },
    {
        "序号": 1298,
        "name": "Credit Suisse X-Links Crude Oil",
        "最新价": 69.98,
        "涨跌额": 1.48,
        "涨跌幅": 2.16,
        "开盘价": 69.85,
        "最高价": 70.31,
        "最低价": 69.21,
        "昨收价": 68.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 46114.0,
        "成交额": 3221995.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "105.USOI"
    },
    {
        "序号": 1299,
        "name": "Travel + Leisure Co",
        "最新价": 38.81,
        "涨跌额": 0.82,
        "涨跌幅": 2.16,
        "开盘价": 37.97,
        "最高价": 38.91,
        "最低价": 37.97,
        "昨收价": 37.99,
        "总市值": 2810631144.0,
        "市盈率": 7.85,
        "成交量": 567181.0,
        "成交额": 21946614.0,
        "振幅": 2.47,
        "换手率": 0.78,
        "symbol": "106.TNL"
    },
    {
        "序号": 1300,
        "name": "Twin Vee PowerCats Co",
        "最新价": 1.42,
        "涨跌额": 0.03,
        "涨跌幅": 2.16,
        "开盘价": 1.38,
        "最高价": 1.42,
        "最低价": 1.36,
        "昨收价": 1.39,
        "总市值": 13518400.0,
        "市盈率": -1.91,
        "成交量": 12296.0,
        "成交额": 17114.0,
        "振幅": 4.32,
        "换手率": 0.13,
        "symbol": "105.VEEE"
    },
    {
        "序号": 1301,
        "name": "Twist Bioscience Corp",
        "最新价": 26.98,
        "涨跌额": 0.57,
        "涨跌幅": 2.16,
        "开盘价": 25.96,
        "最高价": 27.17,
        "最低价": 24.81,
        "昨收价": 26.41,
        "总市值": 1556038611.0,
        "市盈率": -7.6,
        "成交量": 1560568.0,
        "成交额": 41705375.0,
        "振幅": 8.94,
        "换手率": 2.71,
        "symbol": "105.TWST"
    },
    {
        "序号": 1302,
        "name": "Provident Financial Holdings In",
        "最新价": 11.85,
        "涨跌额": 0.25,
        "涨跌幅": 2.16,
        "开盘价": 11.41,
        "最高价": 11.85,
        "最低价": 11.4,
        "昨收价": 11.6,
        "总市值": 82783223.0,
        "市盈率": 10.02,
        "成交量": 13562.0,
        "成交额": 156841.0,
        "振幅": 3.88,
        "换手率": 0.19,
        "symbol": "105.PROV"
    },
    {
        "序号": 1303,
        "name": "SoFi Web 3 ETF",
        "最新价": 19.91,
        "涨跌额": 0.42,
        "涨跌幅": 2.15,
        "开盘价": 19.75,
        "最高价": 19.91,
        "最低价": 19.75,
        "昨收价": 19.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 2582.0,
        "成交额": 51114.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "105.TWEB"
    },
    {
        "序号": 1304,
        "name": "KVH通信",
        "最新价": 5.22,
        "涨跌额": 0.11,
        "涨跌幅": 2.15,
        "开盘价": 5.2,
        "最高价": 5.3,
        "最低价": 5.11,
        "昨收价": 5.11,
        "总市值": 102368324.0,
        "市盈率": -37.33,
        "成交量": 68630.0,
        "成交额": 358448.0,
        "振幅": 3.72,
        "换手率": 0.35,
        "symbol": "105.KVHI"
    },
    {
        "序号": 1305,
        "name": "二倍做多油气ETF-ProShares",
        "最新价": 35.12,
        "涨跌额": 0.74,
        "涨跌幅": 2.15,
        "开盘价": 34.9,
        "最高价": 35.26,
        "最低价": 34.71,
        "昨收价": 34.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 46705.0,
        "成交额": 1634464.0,
        "振幅": 1.6,
        "换手率": null,
        "symbol": "107.DIG"
    },
    {
        "序号": 1306,
        "name": "Almacenes Exito SA ADR",
        "最新价": 7.12,
        "涨跌额": 0.15,
        "涨跌幅": 2.15,
        "开盘价": 6.98,
        "最高价": 7.14,
        "最低价": 6.98,
        "昨收价": 6.97,
        "总市值": 9240794236.0,
        "市盈率": null,
        "成交量": 1157667.0,
        "成交额": 8184323.0,
        "振幅": 2.3,
        "换手率": 0.09,
        "symbol": "106.EXTO"
    },
    {
        "序号": 1307,
        "name": "Bel Fuse Inc-B",
        "最新价": 56.49,
        "涨跌额": 1.19,
        "涨跌幅": 2.15,
        "开盘价": 55.3,
        "最高价": 56.98,
        "最低价": 55.3,
        "昨收价": 55.3,
        "总市值": 721509995.0,
        "市盈率": 9.51,
        "成交量": 62928.0,
        "成交额": 3556851.0,
        "振幅": 3.04,
        "换手率": 0.59,
        "symbol": "105.BELFB"
    },
    {
        "序号": 1308,
        "name": "Net Lease Office Properties",
        "最新价": 19.5,
        "涨跌额": 0.41,
        "涨跌幅": 2.15,
        "开盘价": 18.58,
        "最高价": 19.58,
        "最低价": 18.58,
        "昨收价": 19.09,
        "总市值": 285107921.0,
        "市盈率": null,
        "成交量": 216309.0,
        "成交额": 4134227.0,
        "振幅": 5.24,
        "换手率": 1.48,
        "symbol": "106.NLOP"
    },
    {
        "序号": 1309,
        "name": "Century Therapeutics Inc",
        "最新价": 2.38,
        "涨跌额": 0.05,
        "涨跌幅": 2.15,
        "开盘价": 2.38,
        "最高价": 2.41,
        "最低价": 2.16,
        "昨收价": 2.33,
        "总市值": 142407224.0,
        "市盈率": -1.1,
        "成交量": 178050.0,
        "成交额": 406598.0,
        "振幅": 10.73,
        "换手率": 0.3,
        "symbol": "105.IPSC"
    },
    {
        "序号": 1310,
        "name": "CPS Technologies Corp",
        "最新价": 2.38,
        "涨跌额": 0.05,
        "涨跌幅": 2.15,
        "开盘价": 2.3,
        "最高价": 2.39,
        "最低价": 2.3,
        "昨收价": 2.33,
        "总市值": 34555732.0,
        "市盈率": 23.04,
        "成交量": 23996.0,
        "成交额": 56776.0,
        "振幅": 3.86,
        "换手率": 0.17,
        "symbol": "105.CPSH"
    },
    {
        "序号": 1311,
        "name": "联博控股",
        "最新价": 30.03,
        "涨跌额": 0.63,
        "涨跌幅": 2.14,
        "开盘价": 29.29,
        "最高价": 30.5,
        "最低价": 29.29,
        "昨收价": 29.4,
        "总市值": 3357260096.0,
        "市盈率": 13.5,
        "成交量": 447661.0,
        "成交额": 13455784.0,
        "振幅": 4.12,
        "换手率": 0.4,
        "symbol": "106.AB"
    },
    {
        "序号": 1312,
        "name": "SoFi Technologies Inc",
        "最新价": 8.13,
        "涨跌额": 0.17,
        "涨跌幅": 2.14,
        "开盘价": 7.95,
        "最高价": 8.22,
        "最低价": 7.88,
        "昨收价": 7.96,
        "总市值": 7794578289.0,
        "市盈率": -20.05,
        "成交量": 34626639.0,
        "成交额": 279430496.0,
        "振幅": 4.27,
        "换手率": 3.61,
        "symbol": "105.SOFI"
    },
    {
        "序号": 1313,
        "name": "艾利科技",
        "最新价": 221.23,
        "涨跌额": 4.62,
        "涨跌幅": 2.13,
        "开盘价": 215.86,
        "最高价": 222.05,
        "最低价": 214.9,
        "昨收价": 216.61,
        "总市值": 16943809690.0,
        "市盈率": 46.7,
        "成交量": 1341380.0,
        "成交额": 295453648.0,
        "振幅": 3.3,
        "换手率": 1.75,
        "symbol": "105.ALGN"
    },
    {
        "序号": 1314,
        "name": "Credo Technology Group Holding ",
        "最新价": 18.21,
        "涨跌额": 0.38,
        "涨跌幅": 2.13,
        "开盘价": 17.77,
        "最高价": 18.38,
        "最低价": 17.7,
        "昨收价": 17.83,
        "总市值": 2909229072.0,
        "市盈率": -92.55,
        "成交量": 1885127.0,
        "成交额": 34187226.0,
        "振幅": 3.81,
        "换手率": 1.18,
        "symbol": "105.CRDO"
    },
    {
        "序号": 1315,
        "name": "FormFactor Inc",
        "最新价": 37.41,
        "涨跌额": 0.78,
        "涨跌幅": 2.13,
        "开盘价": 36.5,
        "最高价": 37.48,
        "最低价": 36.5,
        "昨收价": 36.63,
        "总市值": 2912069257.0,
        "市盈率": -405.24,
        "成交量": 336535.0,
        "成交额": 12532930.0,
        "振幅": 2.68,
        "换手率": 0.43,
        "symbol": "105.FORM"
    },
    {
        "序号": 1316,
        "name": "Kimbell Royalty Partners LP",
        "最新价": 14.87,
        "涨跌额": 0.31,
        "涨跌幅": 2.13,
        "开盘价": 14.64,
        "最高价": 14.89,
        "最低价": 14.64,
        "昨收价": 14.56,
        "总市值": 1408170457.0,
        "市盈率": 17.63,
        "成交量": 251599.0,
        "成交额": 3727898.0,
        "振幅": 1.72,
        "换手率": 0.27,
        "symbol": "106.KRP"
    },
    {
        "序号": 1317,
        "name": "老虎证券",
        "最新价": 4.32,
        "涨跌额": 0.09,
        "涨跌幅": 2.13,
        "开盘价": 4.2,
        "最高价": 4.34,
        "最低价": 4.18,
        "昨收价": 4.23,
        "总市值": 674394338.0,
        "市盈率": 18.92,
        "成交量": 459632.0,
        "成交额": 1978464.0,
        "振幅": 3.78,
        "换手率": 0.29,
        "symbol": "105.TIGR"
    },
    {
        "序号": 1318,
        "name": "Rocket Lab USA Inc-A",
        "最新价": 4.8,
        "涨跌额": 0.1,
        "涨跌幅": 2.13,
        "开盘价": 4.76,
        "最高价": 4.93,
        "最低价": 4.72,
        "昨收价": 4.7,
        "总市值": 2332257566.0,
        "市盈率": -13.78,
        "成交量": 3203115.0,
        "成交额": 15420045.0,
        "振幅": 4.47,
        "换手率": 0.66,
        "symbol": "105.RKLB"
    },
    {
        "序号": 1319,
        "name": "Lantern Pharma Inc",
        "最新价": 3.84,
        "涨跌额": 0.08,
        "涨跌幅": 2.13,
        "开盘价": 3.77,
        "最高价": 3.85,
        "最低价": 3.74,
        "昨收价": 3.76,
        "总市值": 41737114.0,
        "市盈率": -2.75,
        "成交量": 24504.0,
        "成交额": 92883.0,
        "振幅": 2.93,
        "换手率": 0.23,
        "symbol": "105.LTRN"
    },
    {
        "序号": 1320,
        "name": "飞利浦",
        "最新价": 20.17,
        "涨跌额": 0.42,
        "涨跌幅": 2.13,
        "开盘价": 20.1,
        "最高价": 20.33,
        "最低价": 20.07,
        "昨收价": 19.75,
        "总市值": 18475457790.0,
        "市盈率": -24.07,
        "成交量": 901024.0,
        "成交额": 18187712.0,
        "振幅": 1.32,
        "换手率": 0.1,
        "symbol": "106.PHG"
    },
    {
        "序号": 1321,
        "name": "Direxion Daily Aerospace & Defe",
        "最新价": 22.13,
        "涨跌额": 0.46,
        "涨跌幅": 2.12,
        "开盘价": 21.65,
        "最高价": 22.2,
        "最低价": 21.65,
        "昨收价": 21.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 230840.0,
        "成交额": 5085876.0,
        "振幅": 2.54,
        "换手率": null,
        "symbol": "107.DFEN"
    },
    {
        "序号": 1322,
        "name": "Consensus Cloud Solutions Inc",
        "最新价": 25.53,
        "涨跌额": 0.53,
        "涨跌幅": 2.12,
        "开盘价": 24.89,
        "最高价": 25.68,
        "最低价": 24.8,
        "昨收价": 25.0,
        "总市值": 488991408.0,
        "市盈率": 6.32,
        "成交量": 113653.0,
        "成交额": 2892487.0,
        "振幅": 3.52,
        "换手率": 0.59,
        "symbol": "105.CCSI"
    },
    {
        "序号": 1323,
        "name": "VanEck Bitcoin Strategy ETF",
        "最新价": 40.95,
        "涨跌额": 0.85,
        "涨跌幅": 2.12,
        "开盘价": 40.62,
        "最高价": 40.95,
        "最低价": 40.11,
        "昨收价": 40.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 34599.0,
        "成交额": 1403454.0,
        "振幅": 2.09,
        "换手率": null,
        "symbol": "107.XBTF"
    },
    {
        "序号": 1324,
        "name": "InterPrivate III Financial Part",
        "最新价": 11.1,
        "涨跌额": 0.23,
        "涨跌幅": 2.12,
        "开盘价": 10.95,
        "最高价": 11.1,
        "最低价": 10.95,
        "昨收价": 10.87,
        "总市值": 86810647.0,
        "市盈率": -80.63,
        "成交量": 5715.0,
        "成交额": 62660.0,
        "振幅": 1.38,
        "换手率": 0.07,
        "symbol": "107.IPVF"
    },
    {
        "序号": 1325,
        "name": "二倍做多能源ETF-Direxion",
        "最新价": 54.13,
        "涨跌额": 1.12,
        "涨跌幅": 2.11,
        "开盘价": 53.66,
        "最高价": 54.44,
        "最低价": 53.46,
        "昨收价": 53.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 478856.0,
        "成交额": 25832253.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "107.ERX"
    },
    {
        "序号": 1326,
        "name": "NGL Energy Partners LP",
        "最新价": 4.35,
        "涨跌额": 0.09,
        "涨跌幅": 2.11,
        "开盘价": 4.16,
        "最高价": 4.38,
        "最低价": 4.16,
        "昨收价": 4.26,
        "总市值": 573883942.0,
        "市盈率": 7.93,
        "成交量": 759511.0,
        "成交额": 3295499.0,
        "振幅": 5.16,
        "换手率": 0.58,
        "symbol": "106.NGL"
    },
    {
        "序号": 1327,
        "name": "Seadrill Ltd",
        "最新价": 41.12,
        "涨跌额": 0.85,
        "涨跌幅": 2.11,
        "开盘价": 39.58,
        "最高价": 41.13,
        "最低价": 39.58,
        "昨收价": 40.27,
        "总市值": 3237070598.0,
        "市盈率": 6.8,
        "成交量": 776172.0,
        "成交额": 31445277.0,
        "振幅": 3.85,
        "换手率": 0.99,
        "symbol": "106.SDRL"
    },
    {
        "序号": 1328,
        "name": "瑞奇包装系统",
        "最新价": 25.64,
        "涨跌额": 0.53,
        "涨跌幅": 2.11,
        "开盘价": 25.19,
        "最高价": 25.7,
        "最低价": 25.16,
        "昨收价": 25.11,
        "总市值": 1061865729.0,
        "市盈率": 20.72,
        "成交量": 186429.0,
        "成交额": 4749502.0,
        "振幅": 2.15,
        "换手率": 0.45,
        "symbol": "105.TRS"
    },
    {
        "序号": 1329,
        "name": "Investar Holding Corp",
        "最新价": 12.1,
        "涨跌额": 0.25,
        "涨跌幅": 2.11,
        "开盘价": 12.05,
        "最高价": 12.1,
        "最低价": 11.9,
        "昨收价": 11.85,
        "总市值": 118012111.0,
        "市盈率": 5.35,
        "成交量": 6036.0,
        "成交额": 72496.0,
        "振幅": 1.69,
        "换手率": 0.06,
        "symbol": "105.ISTR"
    },
    {
        "序号": 1330,
        "name": "亿航智能",
        "最新价": 16.47,
        "涨跌额": 0.34,
        "涨跌幅": 2.11,
        "开盘价": 16.01,
        "最高价": 16.56,
        "最低价": 15.71,
        "昨收价": 16.13,
        "总市值": 994663660.0,
        "市盈率": -20.58,
        "成交量": 541293.0,
        "成交额": 8784802.0,
        "振幅": 5.27,
        "换手率": 0.9,
        "symbol": "105.EH"
    },
    {
        "序号": 1331,
        "name": "Calamos Dynamic Convertible and",
        "最新价": 19.38,
        "涨跌额": 0.4,
        "涨跌幅": 2.11,
        "开盘价": 19.0,
        "最高价": 19.44,
        "最低价": 18.97,
        "昨收价": 18.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 99331.0,
        "成交额": 1913012.0,
        "振幅": 2.48,
        "换手率": null,
        "symbol": "105.CCD"
    },
    {
        "序号": 1332,
        "name": "Marqeta Inc-A",
        "最新价": 6.3,
        "涨跌额": 0.13,
        "涨跌幅": 2.11,
        "开盘价": 6.11,
        "最高价": 6.38,
        "最低价": 6.04,
        "昨收价": 6.17,
        "总市值": 3287662466.0,
        "市盈率": -15.74,
        "成交量": 5239896.0,
        "成交额": 32762594.0,
        "振幅": 5.51,
        "换手率": 1.0,
        "symbol": "105.MQ"
    },
    {
        "序号": 1333,
        "name": "美国雅保",
        "最新价": 127.99,
        "涨跌额": 2.64,
        "涨跌幅": 2.11,
        "开盘价": 129.0,
        "最高价": 133.25,
        "最低价": 127.73,
        "昨收价": 125.35,
        "总市值": 15020045283.0,
        "市盈率": 4.52,
        "成交量": 4844856.0,
        "成交额": 628027168.0,
        "振幅": 4.4,
        "换手率": 4.13,
        "symbol": "106.ALB"
    },
    {
        "序号": 1334,
        "name": "Red Rock Resorts Inc-A",
        "最新价": 46.57,
        "涨跌额": 0.96,
        "涨跌幅": 2.1,
        "开盘价": 45.37,
        "最高价": 46.9,
        "最低价": 45.17,
        "昨收价": 45.61,
        "总市值": 4861188633.0,
        "市盈率": 22.99,
        "成交量": 295387.0,
        "成交额": 13731250.0,
        "振幅": 3.79,
        "换手率": 0.28,
        "symbol": "105.RRR"
    },
    {
        "序号": 1335,
        "name": "马库斯米利查普",
        "最新价": 37.02,
        "涨跌额": 0.76,
        "涨跌幅": 2.1,
        "开盘价": 36.18,
        "最高价": 37.14,
        "最低价": 36.06,
        "昨收价": 36.26,
        "总市值": 1420996744.0,
        "市盈率": -89.43,
        "成交量": 114289.0,
        "成交额": 4203652.0,
        "振幅": 2.98,
        "换手率": 0.3,
        "symbol": "106.MMI"
    },
    {
        "序号": 1336,
        "name": "Southern First Bancshares Inc",
        "最新价": 32.15,
        "涨跌额": 0.66,
        "涨跌幅": 2.1,
        "开盘价": 31.1,
        "最高价": 32.56,
        "最低价": 31.1,
        "昨收价": 31.49,
        "总市值": 260049712.0,
        "市盈率": 17.63,
        "成交量": 16470.0,
        "成交额": 527534.0,
        "振幅": 4.64,
        "换手率": 0.2,
        "symbol": "105.SFST"
    },
    {
        "序号": 1337,
        "name": "AtriCure Inc",
        "最新价": 34.14,
        "涨跌额": 0.7,
        "涨跌幅": 2.09,
        "开盘价": 33.43,
        "最高价": 34.46,
        "最低价": 32.95,
        "昨收价": 33.44,
        "总市值": 1618068099.0,
        "市盈率": -65.19,
        "成交量": 600173.0,
        "成交额": 20252396.0,
        "振幅": 4.52,
        "换手率": 1.27,
        "symbol": "105.ATRC"
    },
    {
        "序号": 1338,
        "name": "Bausch + Lomb Corp",
        "最新价": 15.66,
        "涨跌额": 0.32,
        "涨跌幅": 2.09,
        "开盘价": 15.38,
        "最高价": 15.69,
        "最低价": 15.17,
        "昨收价": 15.34,
        "总市值": 5494401343.0,
        "市盈率": -26.54,
        "成交量": 1193387.0,
        "成交额": 18494850.0,
        "振幅": 3.39,
        "换手率": 0.34,
        "symbol": "106.BLCO"
    },
    {
        "序号": 1339,
        "name": "GEO惩教集团",
        "最新价": 10.29,
        "涨跌额": 0.21,
        "涨跌幅": 2.08,
        "开盘价": 10.13,
        "最高价": 10.36,
        "最低价": 10.11,
        "昨收价": 10.08,
        "总市值": 1297613854.0,
        "市盈率": 10.5,
        "成交量": 2119930.0,
        "成交额": 21775887.0,
        "振幅": 2.48,
        "换手率": 1.68,
        "symbol": "106.GEO"
    },
    {
        "序号": 1340,
        "name": "甘尼特",
        "最新价": 1.96,
        "涨跌额": 0.04,
        "涨跌幅": 2.08,
        "开盘价": 1.92,
        "最高价": 1.98,
        "最低价": 1.91,
        "昨收价": 1.92,
        "总市值": 291797117.0,
        "市盈率": 10.47,
        "成交量": 515470.0,
        "成交额": 1008860.0,
        "振幅": 3.65,
        "换手率": 0.35,
        "symbol": "106.GCI"
    },
    {
        "序号": 1341,
        "name": "Chemomab Therapeutics Ltd ADR",
        "最新价": 0.49,
        "涨跌额": 0.01,
        "涨跌幅": 2.08,
        "开盘价": 0.46,
        "最高价": 0.5,
        "最低价": 0.44,
        "昨收价": 0.48,
        "总市值": 6078320.0,
        "市盈率": -0.18,
        "成交量": 236074.0,
        "成交额": 112015.0,
        "振幅": 12.5,
        "换手率": 1.9,
        "symbol": "105.CMMB"
    },
    {
        "序号": 1342,
        "name": "Suzano SA ADR",
        "最新价": 10.3,
        "涨跌额": 0.21,
        "涨跌幅": 2.08,
        "开盘价": 10.08,
        "最高价": 10.32,
        "最低价": 10.06,
        "昨收价": 10.09,
        "总市值": 13638411435.0,
        "市盈率": 4.07,
        "成交量": 1069228.0,
        "成交额": 10963568.0,
        "振幅": 2.58,
        "换手率": 0.08,
        "symbol": "106.SUZ"
    },
    {
        "序号": 1343,
        "name": "ZIM Integrated Shipping Service",
        "最新价": 7.36,
        "涨跌额": 0.15,
        "涨跌幅": 2.08,
        "开盘价": 7.24,
        "最高价": 7.5,
        "最低价": 7.2,
        "昨收价": 7.21,
        "总市值": 885058039.0,
        "市盈率": -0.41,
        "成交量": 2347985.0,
        "成交额": 17250805.0,
        "振幅": 4.16,
        "换手率": 1.95,
        "symbol": "106.ZIM"
    },
    {
        "序号": 1344,
        "name": "福克斯-A",
        "最新价": 29.94,
        "涨跌额": 0.61,
        "涨跌幅": 2.08,
        "开盘价": 29.39,
        "最高价": 30.16,
        "最低价": 29.36,
        "昨收价": 29.33,
        "总市值": 14455258526.0,
        "市盈率": 13.89,
        "成交量": 5149521.0,
        "成交额": 154179095.0,
        "振幅": 2.73,
        "换手率": 2.08,
        "symbol": "105.FOXA"
    },
    {
        "序号": 1345,
        "name": "Hamilton Lane Inc-A",
        "最新价": 103.69,
        "涨跌额": 2.11,
        "涨跌幅": 2.08,
        "开盘价": 101.2,
        "最高价": 104.03,
        "最低价": 101.2,
        "昨收价": 101.58,
        "总市值": 5600253661.0,
        "市盈率": 49.23,
        "成交量": 106384.0,
        "成交额": 10985262.0,
        "振幅": 2.79,
        "换手率": 0.2,
        "symbol": "105.HLNE"
    },
    {
        "序号": 1346,
        "name": "Sportradar Group AG-A",
        "最新价": 10.34,
        "涨跌额": 0.21,
        "涨跌幅": 2.07,
        "开盘价": 10.19,
        "最高价": 10.46,
        "最低价": 10.08,
        "昨收价": 10.13,
        "总市值": 11482770027.0,
        "市盈率": -589.83,
        "成交量": 550586.0,
        "成交额": 5653185.0,
        "振幅": 3.75,
        "换手率": 0.05,
        "symbol": "105.SRAD"
    },
    {
        "序号": 1347,
        "name": "SNDL Inc",
        "最新价": 1.48,
        "涨跌额": 0.03,
        "涨跌幅": 2.07,
        "开盘价": 1.45,
        "最高价": 1.5,
        "最低价": 1.44,
        "昨收价": 1.45,
        "总市值": 385524978.0,
        "市盈率": -2.4,
        "成交量": 2354086.0,
        "成交额": 3458264.0,
        "振幅": 4.14,
        "换手率": 0.9,
        "symbol": "105.SNDL"
    },
    {
        "序号": 1348,
        "name": "PAVmed Inc",
        "最新价": 2.96,
        "涨跌额": 0.06,
        "涨跌幅": 2.07,
        "开盘价": 2.9,
        "最高价": 3.02,
        "最低价": 2.75,
        "昨收价": 2.9,
        "总市值": 23829951.0,
        "市盈率": -0.34,
        "成交量": 81915.0,
        "成交额": 234014.0,
        "振幅": 9.31,
        "换手率": 1.02,
        "symbol": "105.PAVM"
    },
    {
        "序号": 1349,
        "name": "NN Inc",
        "最新价": 2.96,
        "涨跌额": 0.06,
        "涨跌幅": 2.07,
        "开盘价": 2.95,
        "最高价": 3.06,
        "最低价": 2.74,
        "昨收价": 2.9,
        "总市值": 140164318.0,
        "市盈率": -3.37,
        "成交量": 101156.0,
        "成交额": 295420.0,
        "振幅": 11.03,
        "换手率": 0.21,
        "symbol": "105.NNBR"
    },
    {
        "序号": 1350,
        "name": "布莱克波特科技",
        "最新价": 85.86,
        "涨跌额": 1.74,
        "涨跌幅": 2.07,
        "开盘价": 83.38,
        "最高价": 86.43,
        "最低价": 82.92,
        "昨收价": 84.12,
        "总市值": 4623785524.0,
        "市盈率": -186.16,
        "成交量": 215417.0,
        "成交额": 18432363.0,
        "振幅": 4.17,
        "换手率": 0.4,
        "symbol": "105.BLKB"
    },
    {
        "序号": 1351,
        "name": "韦斯银行",
        "最新价": 29.13,
        "涨跌额": 0.59,
        "涨跌幅": 2.07,
        "开盘价": 28.62,
        "最高价": 29.19,
        "最低价": 28.4,
        "昨收价": 28.54,
        "总市值": 1729397676.0,
        "市盈率": 9.81,
        "成交量": 200995.0,
        "成交额": 5839404.0,
        "振幅": 2.77,
        "换手率": 0.34,
        "symbol": "105.WSBC"
    },
    {
        "序号": 1352,
        "name": "西科国际",
        "最新价": 160.96,
        "涨跌额": 3.26,
        "涨跌幅": 2.07,
        "开盘价": 157.29,
        "最高价": 162.31,
        "最低价": 157.29,
        "昨收价": 157.7,
        "总市值": 8221187165.0,
        "市盈率": 9.76,
        "成交量": 347941.0,
        "成交额": 55765595.0,
        "振幅": 3.18,
        "换手率": 0.68,
        "symbol": "106.WCC"
    },
    {
        "序号": 1353,
        "name": "SM Energy Co",
        "最新价": 35.57,
        "涨跌额": 0.72,
        "涨跌幅": 2.07,
        "开盘价": 35.16,
        "最高价": 35.82,
        "最低价": 35.09,
        "昨收价": 34.85,
        "总市值": 4137284961.0,
        "市盈率": 4.99,
        "成交量": 1235091.0,
        "成交额": 43887859.0,
        "振幅": 2.09,
        "换手率": 1.06,
        "symbol": "106.SM"
    },
    {
        "序号": 1354,
        "name": "Surmodics Inc",
        "最新价": 34.59,
        "涨跌额": 0.7,
        "涨跌幅": 2.07,
        "开盘价": 33.66,
        "最高价": 35.07,
        "最低价": 33.66,
        "昨收价": 33.89,
        "总市值": 489656040.0,
        "市盈率": -318.79,
        "成交量": 48555.0,
        "成交额": 1672752.0,
        "振幅": 4.16,
        "换手率": 0.34,
        "symbol": "105.SRDX"
    },
    {
        "序号": 1355,
        "name": "朱砂能源",
        "最新价": 11.37,
        "涨跌额": 0.23,
        "涨跌幅": 2.06,
        "开盘价": 11.28,
        "最高价": 11.46,
        "最低价": 11.2,
        "昨收价": 11.14,
        "总市值": 1860882420.0,
        "市盈率": 2.59,
        "成交量": 1037156.0,
        "成交额": 11753042.0,
        "振幅": 2.33,
        "换手率": 0.63,
        "symbol": "106.VET"
    },
    {
        "序号": 1356,
        "name": "PTC Inc",
        "最新价": 166.61,
        "涨跌额": 3.37,
        "涨跌幅": 2.06,
        "开盘价": 162.88,
        "最高价": 166.81,
        "最低价": 162.8,
        "昨收价": 163.24,
        "总市值": 19867368464.0,
        "市盈率": 80.91,
        "成交量": 920429.0,
        "成交额": 152425378.0,
        "振幅": 2.46,
        "换手率": 0.77,
        "symbol": "105.PTC"
    },
    {
        "序号": 1357,
        "name": "铱星通讯",
        "最新价": 40.58,
        "涨跌额": 0.82,
        "涨跌幅": 2.06,
        "开盘价": 39.94,
        "最高价": 41.33,
        "最低价": 39.94,
        "昨收价": 39.76,
        "总市值": 5025855968.0,
        "市盈率": -214.63,
        "成交量": 736851.0,
        "成交额": 30076233.0,
        "振幅": 3.5,
        "换手率": 0.59,
        "symbol": "105.IRDM"
    },
    {
        "序号": 1358,
        "name": "Biglari Holdings Inc-A",
        "最新价": 754.23,
        "涨跌额": 15.23,
        "涨跌幅": 2.06,
        "开盘价": 754.23,
        "最高价": 754.23,
        "最低价": 754.23,
        "昨收价": 739.0,
        "总市值": 1560230347.0,
        "市盈率": 76.62,
        "成交量": 90.0,
        "成交额": 67880.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "106.BH_A"
    },
    {
        "序号": 1359,
        "name": "Virtu Financial Inc-A",
        "最新价": 18.83,
        "涨跌额": 0.38,
        "涨跌幅": 2.06,
        "开盘价": 18.4,
        "最高价": 18.87,
        "最低价": 18.34,
        "昨收价": 18.45,
        "总市值": 2997295246.0,
        "市盈率": 18.27,
        "成交量": 853228.0,
        "成交额": 15914197.0,
        "振幅": 2.87,
        "换手率": 0.54,
        "symbol": "105.VIRT"
    },
    {
        "序号": 1360,
        "name": "Creative Medical Technology Hol",
        "最新价": 4.46,
        "涨跌额": 0.09,
        "涨跌幅": 2.06,
        "开盘价": 4.56,
        "最高价": 4.56,
        "最低价": 4.45,
        "昨收价": 4.37,
        "总市值": 6164282.0,
        "市盈率": -0.63,
        "成交量": 2834.0,
        "成交额": 12722.0,
        "振幅": 2.52,
        "换手率": 0.21,
        "symbol": "105.CELZ"
    },
    {
        "序号": 1361,
        "name": "Phathom Pharmaceuticals Inc",
        "最新价": 8.43,
        "涨跌额": 0.17,
        "涨跌幅": 2.06,
        "开盘价": 8.24,
        "最高价": 8.78,
        "最低价": 8.24,
        "昨收价": 8.26,
        "总市值": 482638735.0,
        "市盈率": -2.73,
        "成交量": 356122.0,
        "成交额": 3028879.0,
        "振幅": 6.54,
        "换手率": 0.62,
        "symbol": "105.PHAT"
    },
    {
        "序号": 1362,
        "name": "Sotera Health Co",
        "最新价": 14.4,
        "涨跌额": 0.29,
        "涨跌幅": 2.06,
        "开盘价": 14.13,
        "最高价": 14.41,
        "最低价": 14.1,
        "昨收价": 14.11,
        "总市值": 4069765742.0,
        "市盈率": -13.26,
        "成交量": 326809.0,
        "成交额": 4683082.0,
        "振幅": 2.2,
        "换手率": 0.12,
        "symbol": "105.SHC"
    },
    {
        "序号": 1363,
        "name": "Docebo Inc",
        "最新价": 46.23,
        "涨跌额": 0.93,
        "涨跌幅": 2.05,
        "开盘价": 45.2,
        "最高价": 46.67,
        "最低价": 45.2,
        "昨收价": 45.3,
        "总市值": 1466292536.0,
        "市盈率": 1203.85,
        "成交量": 101135.0,
        "成交额": 4671121.0,
        "振幅": 3.25,
        "换手率": 0.32,
        "symbol": "105.DCBO"
    },
    {
        "序号": 1364,
        "name": "Cherry Hill Mortgage Investment",
        "最新价": 3.98,
        "涨跌额": 0.08,
        "涨跌幅": 2.05,
        "开盘价": 3.94,
        "最高价": 4.0,
        "最低价": 3.93,
        "昨收价": 3.9,
        "总市值": 107372746.0,
        "市盈率": -3.17,
        "成交量": 163467.0,
        "成交额": 648619.0,
        "振幅": 1.79,
        "换手率": 0.61,
        "symbol": "106.CHMI"
    },
    {
        "序号": 1365,
        "name": "Certara Inc",
        "最新价": 16.43,
        "涨跌额": 0.33,
        "涨跌幅": 2.05,
        "开盘价": 16.1,
        "最高价": 16.56,
        "最低价": 16.04,
        "昨收价": 16.1,
        "总市值": 2626249735.0,
        "市盈率": -77.87,
        "成交量": 724878.0,
        "成交额": 11880373.0,
        "振幅": 3.23,
        "换手率": 0.45,
        "symbol": "105.CERT"
    },
    {
        "序号": 1366,
        "name": "Momentus Inc-A",
        "最新价": 2.49,
        "涨跌额": 0.05,
        "涨跌幅": 2.05,
        "开盘价": 2.44,
        "最高价": 2.6,
        "最低价": 2.37,
        "昨收价": 2.44,
        "总市值": 16339930.0,
        "市盈率": -0.21,
        "成交量": 274306.0,
        "成交额": 686947.0,
        "振幅": 9.43,
        "换手率": 4.18,
        "symbol": "105.MNTS"
    },
    {
        "序号": 1367,
        "name": "The Gabelli Utility Trust",
        "最新价": 5.98,
        "涨跌额": 0.12,
        "涨跌幅": 2.05,
        "开盘价": 5.93,
        "最高价": 6.01,
        "最低价": 5.87,
        "昨收价": 5.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 147327.0,
        "成交额": 877756.0,
        "振幅": 2.39,
        "换手率": null,
        "symbol": "106.GUT"
    },
    {
        "序号": 1368,
        "name": "非洲指数ETF-VanEck Vectors",
        "最新价": 13.96,
        "涨跌额": 0.28,
        "涨跌幅": 2.05,
        "开盘价": 13.77,
        "最高价": 13.99,
        "最低价": 13.7,
        "昨收价": 13.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 6709.0,
        "成交额": 92892.0,
        "振幅": 2.12,
        "换手率": null,
        "symbol": "107.AFK"
    },
    {
        "序号": 1369,
        "name": "Cimpress plc",
        "最新价": 75.41,
        "涨跌额": 1.51,
        "涨跌幅": 2.04,
        "开盘价": 73.9,
        "最高价": 76.5,
        "最低价": 73.9,
        "昨收价": 73.9,
        "总市值": 2005154690.0,
        "市盈率": -12.85,
        "成交量": 126248.0,
        "成交额": 9536174.0,
        "振幅": 3.52,
        "换手率": 0.47,
        "symbol": "105.CMPR"
    },
    {
        "序号": 1370,
        "name": "Equitrans Midstream Corp",
        "最新价": 9.99,
        "涨跌额": 0.2,
        "涨跌幅": 2.04,
        "开盘价": 9.78,
        "最高价": 10.05,
        "最低价": 9.72,
        "昨收价": 9.79,
        "总市值": 4328277390.0,
        "市盈率": 11.48,
        "成交量": 3371692.0,
        "成交额": 33535991.0,
        "振幅": 3.37,
        "换手率": 0.78,
        "symbol": "106.ETRN"
    },
    {
        "序号": 1371,
        "name": "Viracta Therapeutics Inc",
        "最新价": 0.5,
        "涨跌额": 0.01,
        "涨跌幅": 2.04,
        "开盘价": 0.5,
        "最高价": 0.53,
        "最低价": 0.48,
        "昨收价": 0.49,
        "总市值": 19312277.0,
        "市盈率": -0.41,
        "成交量": 263261.0,
        "成交额": 131789.0,
        "振幅": 10.2,
        "换手率": 0.68,
        "symbol": "105.VIRX"
    },
    {
        "序号": 1372,
        "name": "Nutriband Inc",
        "最新价": 2.5,
        "涨跌额": 0.05,
        "涨跌幅": 2.04,
        "开盘价": 2.45,
        "最高价": 2.5,
        "最低价": 2.44,
        "昨收价": 2.45,
        "总市值": 19582875.0,
        "市盈率": -4.26,
        "成交量": 8227.0,
        "成交额": 20303.0,
        "振幅": 2.45,
        "换手率": 0.11,
        "symbol": "105.NTRB"
    },
    {
        "序号": 1373,
        "name": "Adamas One Corp",
        "最新价": 0.5,
        "涨跌额": 0.01,
        "涨跌幅": 2.04,
        "开盘价": 0.5,
        "最高价": 0.5,
        "最低价": 0.49,
        "昨收价": 0.49,
        "总市值": 11555763.0,
        "市盈率": -0.58,
        "成交量": 19520.0,
        "成交额": 9617.0,
        "振幅": 2.04,
        "换手率": 0.08,
        "symbol": "105.JEWL"
    },
    {
        "序号": 1374,
        "name": "Arqit Quantum Inc",
        "最新价": 0.5,
        "涨跌额": 0.01,
        "涨跌幅": 2.04,
        "开盘价": 0.5,
        "最高价": 0.5,
        "最低价": 0.48,
        "昨收价": 0.49,
        "总市值": 82035683.0,
        "市盈率": -1.17,
        "成交量": 433166.0,
        "成交额": 210978.0,
        "振幅": 4.08,
        "换手率": 0.26,
        "symbol": "105.ARQQ"
    },
    {
        "序号": 1375,
        "name": "迪募社区银行",
        "最新价": 23.51,
        "涨跌额": 0.47,
        "涨跌幅": 2.04,
        "开盘价": 23.0,
        "最高价": 23.63,
        "最低价": 23.0,
        "昨收价": 23.04,
        "总市值": 912679030.0,
        "市盈率": null,
        "成交量": 126356.0,
        "成交额": 2967781.0,
        "振幅": 2.73,
        "换手率": 0.33,
        "symbol": "105.DCOM"
    },
    {
        "序号": 1376,
        "name": "罗技",
        "最新价": 89.11,
        "涨跌额": 1.78,
        "涨跌幅": 2.04,
        "开盘价": 87.91,
        "最高价": 89.32,
        "最低价": 87.89,
        "昨收价": 87.33,
        "总市值": 13970934912.0,
        "市盈率": 36.62,
        "成交量": 429389.0,
        "成交额": 38119516.0,
        "振幅": 1.64,
        "换手率": 0.27,
        "symbol": "105.LOGI"
    },
    {
        "序号": 1377,
        "name": "Vera Bradley Inc",
        "最新价": 7.52,
        "涨跌额": 0.15,
        "涨跌幅": 2.04,
        "开盘价": 7.34,
        "最高价": 7.6,
        "最低价": 7.33,
        "昨收价": 7.37,
        "总市值": 231819063.0,
        "市盈率": -12.55,
        "成交量": 362251.0,
        "成交额": 2706959.0,
        "振幅": 3.66,
        "换手率": 1.18,
        "symbol": "105.VRA"
    },
    {
        "序号": 1378,
        "name": "Electromed Inc",
        "最新价": 10.53,
        "涨跌额": 0.21,
        "涨跌幅": 2.03,
        "开盘价": 10.55,
        "最高价": 10.85,
        "最低价": 10.3,
        "昨收价": 10.32,
        "总市值": 90365059.0,
        "市盈率": 27.89,
        "成交量": 23119.0,
        "成交额": 241047.0,
        "振幅": 5.33,
        "换手率": 0.27,
        "symbol": "107.ELMD"
    },
    {
        "序号": 1379,
        "name": "HubSpot Inc",
        "最新价": 502.04,
        "涨跌额": 10.0,
        "涨跌幅": 2.03,
        "开盘价": 488.46,
        "最高价": 506.7,
        "最低价": 485.78,
        "昨收价": 492.04,
        "总市值": 25267945808.0,
        "市盈率": -141.69,
        "成交量": 392337.0,
        "成交额": 197119262.0,
        "振幅": 4.25,
        "换手率": 0.78,
        "symbol": "106.HUBS"
    },
    {
        "序号": 1380,
        "name": "三倍做多小型股ETF-Direxion",
        "最新价": 32.14,
        "涨跌额": 0.64,
        "涨跌幅": 2.03,
        "开盘价": 31.34,
        "最高价": 32.62,
        "最低价": 31.16,
        "昨收价": 31.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 23503346.0,
        "成交额": 752519376.0,
        "振幅": 4.63,
        "换手率": null,
        "symbol": "107.TNA"
    },
    {
        "序号": 1381,
        "name": "Pure Storage Inc-A",
        "最新价": 33.17,
        "涨跌额": 0.66,
        "涨跌幅": 2.03,
        "开盘价": 32.26,
        "最高价": 33.41,
        "最低价": 32.26,
        "昨收价": 32.51,
        "总市值": 10347581548.0,
        "市盈率": -12437.0,
        "成交量": 3029609.0,
        "成交额": 100254319.0,
        "振幅": 3.54,
        "换手率": 0.97,
        "symbol": "106.PSTG"
    },
    {
        "序号": 1382,
        "name": "Climb Global Solutions Inc",
        "最新价": 53.3,
        "涨跌额": 1.06,
        "涨跌幅": 2.03,
        "开盘价": 48.16,
        "最高价": 53.63,
        "最低价": 48.16,
        "昨收价": 52.24,
        "总市值": 244094172.0,
        "市盈率": 20.62,
        "成交量": 10329.0,
        "成交额": 543517.0,
        "振幅": 10.47,
        "换手率": 0.23,
        "symbol": "105.CLMB"
    },
    {
        "序号": 1383,
        "name": "毕达菲尔特银行",
        "最新价": 31.2,
        "涨跌额": 0.62,
        "涨跌幅": 2.03,
        "开盘价": 30.6,
        "最高价": 31.31,
        "最低价": 30.4,
        "昨收价": 30.58,
        "总市值": 1519125972.0,
        "市盈率": 6.46,
        "成交量": 213088.0,
        "成交额": 6638445.0,
        "振幅": 2.98,
        "换手率": 0.44,
        "symbol": "106.NTB"
    },
    {
        "序号": 1384,
        "name": "Tourmaline Bio Inc",
        "最新价": 18.62,
        "涨跌额": 0.37,
        "涨跌幅": 2.03,
        "开盘价": 18.39,
        "最高价": 19.49,
        "最低价": 18.0,
        "昨收价": 18.25,
        "总市值": 378670117.0,
        "市盈率": -6.03,
        "成交量": 88603.0,
        "成交额": 1629240.0,
        "振幅": 8.16,
        "换手率": 0.44,
        "symbol": "105.TRML"
    },
    {
        "序号": 1385,
        "name": "华米科技",
        "最新价": 1.51,
        "涨跌额": 0.03,
        "涨跌幅": 2.03,
        "开盘价": 1.46,
        "最高价": 1.52,
        "最低价": 1.46,
        "昨收价": 1.48,
        "总市值": 92167531.0,
        "市盈率": -2.32,
        "成交量": 57501.0,
        "成交额": 86992.0,
        "振幅": 4.05,
        "换手率": 0.09,
        "symbol": "106.ZEPP"
    },
    {
        "序号": 1386,
        "name": "Woodside Energy Group Ltd ADR",
        "最新价": 19.63,
        "涨跌额": 0.39,
        "涨跌幅": 2.03,
        "开盘价": 19.39,
        "最高价": 19.68,
        "最低价": 19.36,
        "昨收价": 19.24,
        "总市值": 37272458005.0,
        "市盈率": 5.65,
        "成交量": 975391.0,
        "成交额": 19073374.0,
        "振幅": 1.66,
        "换手率": 0.05,
        "symbol": "106.WDS"
    },
    {
        "序号": 1387,
        "name": "海湾资源",
        "最新价": 1.51,
        "涨跌额": 0.03,
        "涨跌幅": 2.03,
        "开盘价": 1.48,
        "最高价": 1.53,
        "最低价": 1.42,
        "昨收价": 1.48,
        "总市值": 15752205.0,
        "市盈率": -2.76,
        "成交量": 17044.0,
        "成交额": 24957.0,
        "振幅": 7.43,
        "换手率": 0.16,
        "symbol": "105.GURE"
    },
    {
        "序号": 1388,
        "name": "黄金娱乐",
        "最新价": 39.26,
        "涨跌额": 0.78,
        "涨跌幅": 2.03,
        "开盘价": 38.46,
        "最高价": 39.4,
        "最低价": 38.46,
        "昨收价": 38.48,
        "总市值": 1123487677.0,
        "市盈率": 4.07,
        "成交量": 132384.0,
        "成交额": 5177230.0,
        "振幅": 2.44,
        "换手率": 0.46,
        "symbol": "105.GDEN"
    },
    {
        "序号": 1389,
        "name": "天弘科技",
        "最新价": 27.19,
        "涨跌额": 0.54,
        "涨跌幅": 2.03,
        "开盘价": 26.5,
        "最高价": 27.42,
        "最低价": 26.45,
        "昨收价": 26.65,
        "总市值": 3245386600.0,
        "市盈率": 16.0,
        "成交量": 1286692.0,
        "成交额": 34891910.0,
        "振幅": 3.64,
        "换手率": 1.08,
        "symbol": "106.CLS"
    },
    {
        "序号": 1390,
        "name": "TELA Bio Inc",
        "最新价": 5.54,
        "涨跌额": 0.11,
        "涨跌幅": 2.03,
        "开盘价": 5.5,
        "最高价": 5.68,
        "最低价": 5.42,
        "昨收价": 5.43,
        "总市值": 135667969.0,
        "市盈率": -3.1,
        "成交量": 96233.0,
        "成交额": 532591.0,
        "振幅": 4.79,
        "换手率": 0.39,
        "symbol": "105.TELA"
    },
    {
        "序号": 1391,
        "name": "希伦布兰德",
        "最新价": 40.82,
        "涨跌额": 0.81,
        "涨跌幅": 2.02,
        "开盘价": 40.93,
        "最高价": 41.0,
        "最低价": 40.22,
        "昨收价": 40.01,
        "总市值": 2854190650.0,
        "市盈率": 5.01,
        "成交量": 319540.0,
        "成交额": 13021504.0,
        "振幅": 1.95,
        "换手率": 0.46,
        "symbol": "106.HI"
    },
    {
        "序号": 1392,
        "name": "Renasant Corp",
        "最新价": 31.28,
        "涨跌额": 0.62,
        "涨跌幅": 2.02,
        "开盘价": 30.67,
        "最高价": 31.29,
        "最低价": 30.18,
        "昨收价": 30.66,
        "总市值": 1756107528.0,
        "市盈率": 10.78,
        "成交量": 238587.0,
        "成交额": 7441769.0,
        "振幅": 3.62,
        "换手率": 0.42,
        "symbol": "106.RNST"
    },
    {
        "序号": 1393,
        "name": "达内教育",
        "最新价": 1.01,
        "涨跌额": 0.02,
        "涨跌幅": 2.02,
        "开盘价": 1.01,
        "最高价": 1.02,
        "最低价": 0.98,
        "昨收价": 0.99,
        "总市值": 10869239.0,
        "市盈率": -2.18,
        "成交量": 39115.0,
        "成交额": 39766.0,
        "振幅": 4.04,
        "换手率": 0.36,
        "symbol": "105.TEDU"
    },
    {
        "序号": 1394,
        "name": "汇富金融服务",
        "最新价": 8.08,
        "涨跌额": 0.16,
        "涨跌幅": 2.02,
        "开盘价": 7.96,
        "最高价": 8.26,
        "最低价": 7.91,
        "昨收价": 7.92,
        "总市值": 228595312.0,
        "市盈率": 13.38,
        "成交量": 44414.0,
        "成交额": 359374.0,
        "振幅": 4.42,
        "换手率": 0.16,
        "symbol": "106.KFS"
    },
    {
        "序号": 1395,
        "name": "伊芙美尔医疗",
        "最新价": 4.55,
        "涨跌额": 0.09,
        "涨跌幅": 2.02,
        "开盘价": 4.5,
        "最高价": 4.55,
        "最低价": 4.45,
        "昨收价": 4.46,
        "总市值": 35266431.0,
        "市盈率": -5.6,
        "成交量": 13358.0,
        "成交额": 60299.0,
        "振幅": 2.24,
        "换手率": 0.17,
        "symbol": "105.ICCC"
    },
    {
        "序号": 1396,
        "name": "United Maritime Corp",
        "最新价": 2.53,
        "涨跌额": 0.05,
        "涨跌幅": 2.02,
        "开盘价": 2.5,
        "最高价": 2.55,
        "最低价": 2.5,
        "昨收价": 2.48,
        "总市值": 22034246.0,
        "市盈率": 14.41,
        "成交量": 21753.0,
        "成交额": 54772.0,
        "振幅": 2.02,
        "换手率": 0.25,
        "symbol": "105.USEA"
    },
    {
        "序号": 1397,
        "name": "Ultra Nasdaq Cybersecurity",
        "最新价": 35.46,
        "涨跌额": 0.7,
        "涨跌幅": 2.01,
        "开盘价": 34.96,
        "最高价": 35.46,
        "最低价": 34.96,
        "昨收价": 34.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 676.0,
        "成交额": 23632.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "105.UCYB"
    },
    {
        "序号": 1398,
        "name": "Yatra Online Inc",
        "最新价": 1.52,
        "涨跌额": 0.03,
        "涨跌幅": 2.01,
        "开盘价": 1.51,
        "最高价": 1.52,
        "最低价": 1.49,
        "昨收价": 1.49,
        "总市值": 97559216.0,
        "市盈率": -15.91,
        "成交量": 48491.0,
        "成交额": 73225.0,
        "振幅": 2.01,
        "换手率": 0.08,
        "symbol": "105.YTRA"
    },
    {
        "序号": 1399,
        "name": "永利度假村",
        "最新价": 84.19,
        "涨跌额": 1.66,
        "涨跌幅": 2.01,
        "开盘价": 82.35,
        "最高价": 84.22,
        "最低价": 82.32,
        "昨收价": 82.53,
        "总市值": 9508923151.0,
        "市盈率": 285.99,
        "成交量": 2346782.0,
        "成交额": 196426463.0,
        "振幅": 2.3,
        "换手率": 2.08,
        "symbol": "105.WYNN"
    },
    {
        "序号": 1400,
        "name": "百通公司",
        "最新价": 72.56,
        "涨跌额": 1.43,
        "涨跌幅": 2.01,
        "开盘价": 71.26,
        "最高价": 72.91,
        "最低价": 71.19,
        "昨收价": 71.13,
        "总市值": 3024417622.0,
        "市盈率": 11.36,
        "成交量": 368600.0,
        "成交额": 26737195.0,
        "振幅": 2.42,
        "换手率": 0.88,
        "symbol": "106.BDC"
    },
    {
        "序号": 1401,
        "name": "Matterport Inc-A",
        "最新价": 2.54,
        "涨跌额": 0.05,
        "涨跌幅": 2.01,
        "开盘价": 2.47,
        "最高价": 2.55,
        "最低价": 2.44,
        "昨收价": 2.49,
        "总市值": 776377241.0,
        "市盈率": -3.6,
        "成交量": 1926052.0,
        "成交额": 4830757.0,
        "振幅": 4.42,
        "换手率": 0.63,
        "symbol": "105.MTTR"
    },
    {
        "序号": 1402,
        "name": "Immatics NV Wt",
        "最新价": 2.54,
        "涨跌额": 0.05,
        "涨跌幅": 2.01,
        "开盘价": 2.53,
        "最高价": 2.54,
        "最低价": 2.52,
        "昨收价": 2.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 1367.0,
        "成交额": 3465.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "105.IMTXW"
    },
    {
        "序号": 1403,
        "name": "摩丁制造",
        "最新价": 53.36,
        "涨跌额": 1.05,
        "涨跌幅": 2.01,
        "开盘价": 52.2,
        "最高价": 53.67,
        "最低价": 52.06,
        "昨收价": 52.31,
        "总市值": 2787647420.0,
        "市盈率": 13.55,
        "成交量": 576287.0,
        "成交额": 30591665.0,
        "振幅": 3.08,
        "换手率": 1.1,
        "symbol": "106.MOD"
    },
    {
        "序号": 1404,
        "name": "ModivCare Inc",
        "最新价": 39.14,
        "涨跌额": 0.77,
        "涨跌幅": 2.01,
        "开盘价": 38.55,
        "最高价": 39.91,
        "最低价": 38.55,
        "昨收价": 38.37,
        "总市值": 555265677.0,
        "市盈率": -2.69,
        "成交量": 69990.0,
        "成交额": 2745115.0,
        "振幅": 3.54,
        "换手率": 0.49,
        "symbol": "105.MODV"
    },
    {
        "序号": 1405,
        "name": "ProShares UltraPro Russell2000",
        "最新价": 37.67,
        "涨跌额": 0.74,
        "涨跌幅": 2.0,
        "开盘价": 36.67,
        "最高价": 38.25,
        "最低价": 36.56,
        "昨收价": 36.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 1817553.0,
        "成交额": 68293368.0,
        "振幅": 4.58,
        "换手率": null,
        "symbol": "107.URTY"
    },
    {
        "序号": 1406,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 17.82,
        "涨跌额": 0.35,
        "涨跌幅": 2.0,
        "开盘价": 17.55,
        "最高价": 17.82,
        "最低价": 17.55,
        "昨收价": 17.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 4868.0,
        "成交额": 85848.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "107.PCG_B"
    },
    {
        "序号": 1407,
        "name": "Black Stone Minerals LP",
        "最新价": 16.3,
        "涨跌额": 0.32,
        "涨跌幅": 2.0,
        "开盘价": 16.05,
        "最高价": 16.32,
        "最低价": 16.05,
        "昨收价": 15.98,
        "总市值": 3422852061.0,
        "市盈率": 7.47,
        "成交量": 417226.0,
        "成交额": 6756174.0,
        "振幅": 1.69,
        "换手率": 0.2,
        "symbol": "106.BSM"
    },
    {
        "序号": 1408,
        "name": "XPO",
        "最新价": 82.05,
        "涨跌额": 1.61,
        "涨跌幅": 2.0,
        "开盘价": 80.51,
        "最高价": 82.73,
        "最低价": 79.96,
        "昨收价": 80.44,
        "总市值": 9515598599.0,
        "市盈率": 257.18,
        "成交量": 2076131.0,
        "成交额": 169189810.0,
        "振幅": 3.44,
        "换手率": 1.79,
        "symbol": "106.XPO"
    },
    {
        "序号": 1409,
        "name": "亚狮康药业",
        "最新价": 0.51,
        "涨跌额": 0.01,
        "涨跌幅": 2.0,
        "开盘价": 0.5,
        "最高价": 0.53,
        "最低价": 0.5,
        "昨收价": 0.5,
        "总市值": 8203183.0,
        "市盈率": -0.18,
        "成交量": 62094.0,
        "成交额": 31739.0,
        "振幅": 6.0,
        "换手率": 0.39,
        "symbol": "105.ASLN"
    },
    {
        "序号": 1410,
        "name": "i3 Verticals Inc-A",
        "最新价": 19.92,
        "涨跌额": 0.39,
        "涨跌幅": 2.0,
        "开盘价": 19.6,
        "最高价": 20.43,
        "最低价": 19.51,
        "昨收价": 19.53,
        "总市值": 664648529.0,
        "市盈率": -819.54,
        "成交量": 237829.0,
        "成交额": 4728858.0,
        "振幅": 4.71,
        "换手率": 0.71,
        "symbol": "105.IIIV"
    },
    {
        "序号": 1411,
        "name": "Heritage Commerce Corp",
        "最新价": 9.23,
        "涨跌额": 0.18,
        "涨跌幅": 1.99,
        "开盘价": 9.05,
        "最高价": 9.25,
        "最低价": 9.01,
        "昨收价": 9.05,
        "总市值": 563945201.0,
        "市盈率": 7.84,
        "成交量": 254945.0,
        "成交额": 2340010.0,
        "振幅": 2.65,
        "换手率": 0.42,
        "symbol": "105.HTBK"
    },
    {
        "序号": 1412,
        "name": "Cooper-Standard Holdings Inc",
        "最新价": 16.93,
        "涨跌额": 0.33,
        "涨跌幅": 1.99,
        "开盘价": 16.56,
        "最高价": 17.05,
        "最低价": 16.43,
        "昨收价": 16.6,
        "总市值": 291153319.0,
        "市盈率": -1.24,
        "成交量": 83974.0,
        "成交额": 1408584.0,
        "振幅": 3.73,
        "换手率": 0.49,
        "symbol": "106.CPS"
    },
    {
        "序号": 1413,
        "name": "玖富",
        "最新价": 4.11,
        "涨跌额": 0.08,
        "涨跌幅": 1.99,
        "开盘价": 4.11,
        "最高价": 4.11,
        "最低价": 4.11,
        "昨收价": 4.03,
        "总市值": 48388399.0,
        "市盈率": -0.57,
        "成交量": 3376.0,
        "成交额": 13875.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "105.JFU"
    },
    {
        "序号": 1414,
        "name": "拍明芯城",
        "最新价": 11.31,
        "涨跌额": 0.22,
        "涨跌幅": 1.98,
        "开盘价": 11.25,
        "最高价": 11.42,
        "最低价": 10.99,
        "昨收价": 11.09,
        "总市值": 117286487.0,
        "市盈率": 66.98,
        "成交量": 409548.0,
        "成交额": 4585144.0,
        "振幅": 3.88,
        "换手率": 3.95,
        "symbol": "105.IZM"
    },
    {
        "序号": 1415,
        "name": "Expion360 Inc",
        "最新价": 5.66,
        "涨跌额": 0.11,
        "涨跌幅": 1.98,
        "开盘价": 5.64,
        "最高价": 5.95,
        "最低价": 5.52,
        "昨收价": 5.55,
        "总市值": 39114658.0,
        "市盈率": -5.8,
        "成交量": 29718.0,
        "成交额": 169413.0,
        "振幅": 7.75,
        "换手率": 0.43,
        "symbol": "105.XPON"
    },
    {
        "序号": 1416,
        "name": "Evolus Inc",
        "最新价": 9.78,
        "涨跌额": 0.19,
        "涨跌幅": 1.98,
        "开盘价": 9.51,
        "最高价": 9.84,
        "最低价": 9.51,
        "昨收价": 9.59,
        "总市值": 558934061.0,
        "市盈率": -8.87,
        "成交量": 350931.0,
        "成交额": 3417155.0,
        "振幅": 3.44,
        "换手率": 0.61,
        "symbol": "105.EOLS"
    },
    {
        "序号": 1417,
        "name": "Landsea Homes Corp-A",
        "最新价": 11.85,
        "涨跌额": 0.23,
        "涨跌幅": 1.98,
        "开盘价": 11.61,
        "最高价": 11.91,
        "最低价": 11.61,
        "昨收价": 11.62,
        "总市值": 447873013.0,
        "市盈率": 10.58,
        "成交量": 186352.0,
        "成交额": 2208895.0,
        "振幅": 2.58,
        "换手率": 0.49,
        "symbol": "105.LSEA"
    },
    {
        "序号": 1418,
        "name": "AerCap飞机租赁",
        "最新价": 72.15,
        "涨跌额": 1.4,
        "涨跌幅": 1.98,
        "开盘价": 71.45,
        "最高价": 72.56,
        "最低价": 70.55,
        "昨收价": 70.75,
        "总市值": 17088114225.0,
        "市盈率": 9.19,
        "成交量": 2498942.0,
        "成交额": 180017614.0,
        "振幅": 2.84,
        "换手率": 1.06,
        "symbol": "106.AER"
    },
    {
        "序号": 1419,
        "name": "Sunstone Hotel Investors Inc Se",
        "最新价": 21.67,
        "涨跌额": 0.42,
        "涨跌幅": 1.98,
        "开盘价": 20.86,
        "最高价": 21.67,
        "最低价": 20.86,
        "昨收价": 21.25,
        "总市值": 99682000.0,
        "市盈率": null,
        "成交量": 4825.0,
        "成交额": 102209.0,
        "振幅": 3.81,
        "换手率": 0.1,
        "symbol": "106.SHO_H"
    },
    {
        "序号": 1420,
        "name": "格陵兰科技",
        "最新价": 2.58,
        "涨跌额": 0.05,
        "涨跌幅": 1.98,
        "开盘价": 2.55,
        "最高价": 2.58,
        "最低价": 2.43,
        "昨收价": 2.53,
        "总市值": 35073887.0,
        "市盈率": 17.84,
        "成交量": 26084.0,
        "成交额": 65453.0,
        "振幅": 5.93,
        "换手率": 0.19,
        "symbol": "105.GTEC"
    },
    {
        "序号": 1421,
        "name": "PCB Bancorp",
        "最新价": 17.09,
        "涨跌额": 0.33,
        "涨跌幅": 1.97,
        "开盘价": 16.86,
        "最高价": 17.34,
        "最低价": 16.8,
        "昨收价": 16.76,
        "总市值": 243692121.0,
        "市盈率": 7.27,
        "成交量": 22819.0,
        "成交额": 389279.0,
        "振幅": 3.22,
        "换手率": 0.16,
        "symbol": "105.PCB"
    },
    {
        "序号": 1422,
        "name": "卡梅科",
        "最新价": 45.09,
        "涨跌额": 0.87,
        "涨跌幅": 1.97,
        "开盘价": 44.35,
        "最高价": 45.28,
        "最低价": 44.24,
        "昨收价": 44.22,
        "总市值": 19562992599.0,
        "市盈率": 98.56,
        "成交量": 3746420.0,
        "成交额": 168361911.0,
        "振幅": 2.35,
        "换手率": 0.86,
        "symbol": "106.CCJ"
    },
    {
        "序号": 1423,
        "name": "Clear Secure Inc-A",
        "最新价": 21.78,
        "涨跌额": 0.42,
        "涨跌幅": 1.97,
        "开盘价": 21.26,
        "最高价": 21.84,
        "最低价": 21.22,
        "昨收价": 21.36,
        "总市值": 3284538911.0,
        "市盈率": 1170.54,
        "成交量": 523043.0,
        "成交额": 11316227.0,
        "振幅": 2.9,
        "换手率": 0.35,
        "symbol": "106.YOU"
    },
    {
        "序号": 1424,
        "name": "Primis Financial Corp",
        "最新价": 10.93,
        "涨跌额": 0.21,
        "涨跌幅": 1.96,
        "开盘价": 10.72,
        "最高价": 10.95,
        "最低价": 10.7,
        "昨收价": 10.72,
        "总市值": 269771681.0,
        "市盈率": 49.68,
        "成交量": 59789.0,
        "成交额": 650354.0,
        "振幅": 2.33,
        "换手率": 0.24,
        "symbol": "105.FRST"
    },
    {
        "序号": 1425,
        "name": "普得集团(帕尔迪)",
        "最新价": 96.31,
        "涨跌额": 1.85,
        "涨跌幅": 1.96,
        "开盘价": 93.99,
        "最高价": 96.62,
        "最低价": 93.99,
        "昨收价": 94.46,
        "总市值": 20763952524.0,
        "市盈率": 7.49,
        "成交量": 2226929.0,
        "成交额": 213793384.0,
        "振幅": 2.78,
        "换手率": 1.03,
        "symbol": "106.PHM"
    },
    {
        "序号": 1426,
        "name": "Guaranty Bancshares Inc",
        "最新价": 30.24,
        "涨跌额": 0.58,
        "涨跌幅": 1.96,
        "开盘价": 29.4,
        "最高价": 30.5,
        "最低价": 29.4,
        "昨收价": 29.66,
        "总市值": 348645851.0,
        "市盈率": 10.83,
        "成交量": 15518.0,
        "成交额": 465971.0,
        "振幅": 3.71,
        "换手率": 0.13,
        "symbol": "106.GNTY"
    },
    {
        "序号": 1427,
        "name": "Arlo Technologies Inc",
        "最新价": 9.39,
        "涨跌额": 0.18,
        "涨跌幅": 1.95,
        "开盘价": 9.15,
        "最高价": 9.47,
        "最低价": 9.07,
        "昨收价": 9.21,
        "总市值": 888562620.0,
        "市盈率": -19.8,
        "成交量": 929195.0,
        "成交额": 8630493.0,
        "振幅": 4.34,
        "换手率": 0.98,
        "symbol": "106.ARLO"
    },
    {
        "序号": 1428,
        "name": "英伟达",
        "最新价": 475.06,
        "涨跌额": 9.1,
        "涨跌幅": 1.95,
        "开盘价": 465.95,
        "最高价": 477.41,
        "最低价": 465.5,
        "昨收价": 465.96,
        "总市值": 1171497960000.0,
        "市盈率": 62.02,
        "成交量": 35922370.0,
        "成交额": 17030827264.0,
        "振幅": 2.56,
        "换手率": 1.46,
        "symbol": "105.NVDA"
    },
    {
        "序号": 1429,
        "name": "Texas Pacific Land Corp",
        "最新价": 1546.12,
        "涨跌额": 29.6,
        "涨跌幅": 1.95,
        "开盘价": 1529.34,
        "最高价": 1547.76,
        "最低价": 1525.47,
        "昨收价": 1516.52,
        "总市值": 11866265366.0,
        "市盈率": 30.25,
        "成交量": 27925.0,
        "成交额": 43000179.0,
        "振幅": 1.47,
        "换手率": 0.36,
        "symbol": "106.TPL"
    },
    {
        "序号": 1430,
        "name": "华美",
        "最新价": 68.62,
        "涨跌额": 1.31,
        "涨跌幅": 1.95,
        "开盘价": 67.51,
        "最高价": 68.86,
        "最低价": 67.34,
        "昨收价": 67.31,
        "总市值": 9670626344.0,
        "市盈率": 7.68,
        "成交量": 949646.0,
        "成交额": 64972202.0,
        "振幅": 2.26,
        "换手率": 0.67,
        "symbol": "105.EWBC"
    },
    {
        "序号": 1431,
        "name": "Aveanna Healthcare Holdings Inc",
        "最新价": 2.62,
        "涨跌额": 0.05,
        "涨跌幅": 1.95,
        "开盘价": 2.54,
        "最高价": 2.68,
        "最低价": 2.54,
        "昨收价": 2.57,
        "总市值": 499720861.0,
        "市盈率": -1.44,
        "成交量": 96484.0,
        "成交额": 252971.0,
        "振幅": 5.45,
        "换手率": 0.05,
        "symbol": "105.AVAH"
    },
    {
        "序号": 1432,
        "name": "Lemonade Inc",
        "最新价": 17.82,
        "涨跌额": 0.34,
        "涨跌幅": 1.95,
        "开盘价": 17.38,
        "最高价": 17.99,
        "最低价": 17.25,
        "昨收价": 17.48,
        "总市值": 1246038000.0,
        "市盈率": -4.83,
        "成交量": 1096161.0,
        "成交额": 19427455.0,
        "振幅": 4.23,
        "换手率": 1.57,
        "symbol": "106.LMND"
    },
    {
        "序号": 1433,
        "name": "MicroSectors FANG Index 2X Leve",
        "最新价": 41.94,
        "涨跌额": 0.8,
        "涨跌幅": 1.94,
        "开盘价": 40.9,
        "最高价": 42.02,
        "最低价": 40.79,
        "昨收价": 41.14,
        "总市值": 30234930212.0,
        "市盈率": 9.53,
        "成交量": 20493.0,
        "成交额": 854331.0,
        "振幅": 2.99,
        "换手率": null,
        "symbol": "107.FNGO"
    },
    {
        "序号": 1434,
        "name": "阿灵顿资产投资",
        "最新价": 4.72,
        "涨跌额": 0.09,
        "涨跌幅": 1.94,
        "开盘价": 4.65,
        "最高价": 4.75,
        "最低价": 4.64,
        "昨收价": 4.63,
        "总市值": 133683267.0,
        "市盈率": 313.81,
        "成交量": 342029.0,
        "成交额": 1605361.0,
        "振幅": 2.38,
        "换手率": 1.21,
        "symbol": "106.AAIC"
    },
    {
        "序号": 1435,
        "name": "Ichor Holdings Ltd",
        "最新价": 27.28,
        "涨跌额": 0.52,
        "涨跌幅": 1.94,
        "开盘价": 26.41,
        "最高价": 27.52,
        "最低价": 26.4,
        "昨收价": 26.76,
        "总市值": 801487082.0,
        "市盈率": -47.46,
        "成交量": 142233.0,
        "成交额": 3863275.0,
        "振幅": 4.19,
        "换手率": 0.48,
        "symbol": "105.ICHR"
    },
    {
        "序号": 1436,
        "name": "Taylor Devices Inc",
        "最新价": 23.09,
        "涨跌额": 0.44,
        "涨跌幅": 1.94,
        "开盘价": 22.6,
        "最高价": 23.09,
        "最低价": 22.52,
        "昨收价": 22.65,
        "总市值": 81308595.0,
        "市盈率": 11.4,
        "成交量": 6760.0,
        "成交额": 154730.0,
        "振幅": 2.52,
        "换手率": 0.19,
        "symbol": "105.TAYD"
    },
    {
        "序号": 1437,
        "name": "施泰力",
        "最新价": 9.98,
        "涨跌额": 0.19,
        "涨跌幅": 1.94,
        "开盘价": 9.86,
        "最高价": 10.06,
        "最低价": 9.8,
        "昨收价": 9.79,
        "总市值": 74687386.0,
        "市盈率": 3.25,
        "成交量": 11558.0,
        "成交额": 114705.0,
        "振幅": 2.66,
        "换手率": 0.15,
        "symbol": "106.SCX"
    },
    {
        "序号": 1438,
        "name": "Issuer Direct Corp",
        "最新价": 15.25,
        "涨跌额": 0.29,
        "涨跌幅": 1.94,
        "开盘价": 15.1,
        "最高价": 15.31,
        "最低价": 15.05,
        "昨收价": 14.96,
        "总市值": 58127647.0,
        "市盈率": 42.03,
        "成交量": 6116.0,
        "成交额": 92488.0,
        "振幅": 1.74,
        "换手率": 0.16,
        "symbol": "107.ISDR"
    },
    {
        "序号": 1439,
        "name": "希贝特",
        "最新价": 65.21,
        "涨跌额": 1.24,
        "涨跌幅": 1.94,
        "开盘价": 64.14,
        "最高价": 65.32,
        "最低价": 63.65,
        "昨收价": 63.97,
        "总市值": 767635883.0,
        "市盈率": 6.93,
        "成交量": 270758.0,
        "成交额": 17512827.0,
        "振幅": 2.61,
        "换手率": 2.3,
        "symbol": "105.HIBB"
    },
    {
        "序号": 1440,
        "name": "Alpine Immune Sciences Inc",
        "最新价": 16.84,
        "涨跌额": 0.32,
        "涨跌幅": 1.94,
        "开盘价": 16.44,
        "最高价": 17.25,
        "最低价": 16.15,
        "昨收价": 16.52,
        "总市值": 978369663.0,
        "市盈率": -17.16,
        "成交量": 520370.0,
        "成交额": 8780593.0,
        "振幅": 6.66,
        "换手率": 0.9,
        "symbol": "105.ALPN"
    },
    {
        "序号": 1441,
        "name": "韦伯斯特金融",
        "最新价": 47.9,
        "涨跌额": 0.91,
        "涨跌幅": 1.94,
        "开盘价": 47.04,
        "最高价": 47.99,
        "最低价": 46.59,
        "昨收价": 46.99,
        "总市值": 8240479566.0,
        "市盈率": 8.89,
        "成交量": 852542.0,
        "成交额": 40650624.0,
        "振幅": 2.98,
        "换手率": 0.5,
        "symbol": "106.WBS"
    },
    {
        "序号": 1442,
        "name": "Kenon Holdings Ltd",
        "最新价": 23.69,
        "涨跌额": 0.45,
        "涨跌幅": 1.94,
        "开盘价": 23.47,
        "最高价": 23.74,
        "最低价": 23.26,
        "昨收价": 23.24,
        "总市值": 1276758644.0,
        "市盈率": -1.18,
        "成交量": 7914.0,
        "成交额": 186876.0,
        "振幅": 2.07,
        "换手率": 0.01,
        "symbol": "106.KEN"
    },
    {
        "序号": 1443,
        "name": "Enterprise Financial Services C",
        "最新价": 15.8,
        "涨跌额": 0.3,
        "涨跌幅": 1.94,
        "开盘价": 15.65,
        "最高价": 15.95,
        "最低价": 15.65,
        "昨收价": 15.5,
        "总市值": 1185000.0,
        "市盈率": null,
        "成交量": 4102.0,
        "成交额": 64578.0,
        "振幅": 1.94,
        "换手率": 5.47,
        "symbol": "105.EFSCP"
    },
    {
        "序号": 1444,
        "name": "Forafric Global PLC Wt",
        "最新价": 1.58,
        "涨跌额": 0.03,
        "涨跌幅": 1.94,
        "开盘价": 1.51,
        "最高价": 1.58,
        "最低价": 1.48,
        "昨收价": 1.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1288.0,
        "成交额": 1941.0,
        "振幅": 6.45,
        "换手率": null,
        "symbol": "105.AFRIW"
    },
    {
        "序号": 1445,
        "name": "Sphere Entertainment Co-A",
        "最新价": 32.66,
        "涨跌额": 0.62,
        "涨跌幅": 1.94,
        "开盘价": 32.1,
        "最高价": 32.66,
        "最低价": 31.77,
        "昨收价": 32.04,
        "总市值": 1146704848.0,
        "市盈率": 1.87,
        "成交量": 1171449.0,
        "成交额": 37904744.0,
        "振幅": 2.78,
        "换手率": 3.34,
        "symbol": "106.SPHR"
    },
    {
        "序号": 1446,
        "name": "KKR & Co Inc",
        "最新价": 76.95,
        "涨跌额": 1.46,
        "涨跌幅": 1.93,
        "开盘价": 75.16,
        "最高价": 77.01,
        "最低价": 75.16,
        "昨收价": 75.49,
        "总市值": 68101417618.0,
        "市盈率": 27.12,
        "成交量": 3281005.0,
        "成交额": 251601696.0,
        "振幅": 2.45,
        "换手率": 0.37,
        "symbol": "106.KKR"
    },
    {
        "序号": 1447,
        "name": "Kosmos Energy Ltd",
        "最新价": 6.34,
        "涨跌额": 0.12,
        "涨跌幅": 1.93,
        "开盘价": 6.29,
        "最高价": 6.4,
        "最低价": 6.29,
        "昨收价": 6.22,
        "总市值": 2917222368.0,
        "市盈率": 37.61,
        "成交量": 3574402.0,
        "成交额": 22657418.0,
        "振幅": 1.77,
        "换手率": 0.78,
        "symbol": "106.KOS"
    },
    {
        "序号": 1448,
        "name": "Globant SA",
        "最新价": 227.27,
        "涨跌额": 4.3,
        "涨跌幅": 1.93,
        "开盘价": 221.71,
        "最高价": 228.57,
        "最低价": 221.71,
        "昨收价": 222.97,
        "总市值": 9606625401.0,
        "市盈率": 61.74,
        "成交量": 297010.0,
        "成交额": 67359052.0,
        "振幅": 3.08,
        "换手率": 0.7,
        "symbol": "106.GLOB"
    },
    {
        "序号": 1449,
        "name": "Piper Sandler Co",
        "最新价": 164.55,
        "涨跌额": 3.11,
        "涨跌幅": 1.93,
        "开盘价": 161.0,
        "最高价": 164.96,
        "最低价": 161.0,
        "昨收价": 161.44,
        "总市值": 2914109414.0,
        "市盈率": 40.72,
        "成交量": 69658.0,
        "成交额": 11417637.0,
        "振幅": 2.45,
        "换手率": 0.39,
        "symbol": "106.PIPR"
    },
    {
        "序号": 1450,
        "name": "Toast Inc-A",
        "最新价": 15.35,
        "涨跌额": 0.29,
        "涨跌幅": 1.93,
        "开盘价": 15.01,
        "最高价": 15.49,
        "最低价": 14.91,
        "昨收价": 15.06,
        "总市值": 8292191250.0,
        "市盈率": -26.75,
        "成交量": 7750416.0,
        "成交额": 118461887.0,
        "振幅": 3.85,
        "换手率": 1.43,
        "symbol": "106.TOST"
    },
    {
        "序号": 1451,
        "name": "墨菲石油",
        "最新价": 41.34,
        "涨跌额": 0.78,
        "涨跌幅": 1.92,
        "开盘价": 40.93,
        "最高价": 41.51,
        "最低价": 40.85,
        "昨收价": 40.56,
        "总市值": 6385919649.0,
        "市盈率": 8.58,
        "成交量": 1705619.0,
        "成交额": 70268844.0,
        "振幅": 1.63,
        "换手率": 1.1,
        "symbol": "106.MUR"
    },
    {
        "序号": 1452,
        "name": "Medigus Ltd ADR",
        "最新价": 3.18,
        "涨跌额": 0.06,
        "涨跌幅": 1.92,
        "开盘价": 3.0,
        "最高价": 3.5,
        "最低价": 3.0,
        "昨收价": 3.12,
        "总市值": 5411209.0,
        "市盈率": -0.38,
        "成交量": 13413.0,
        "成交额": 44263.0,
        "振幅": 16.03,
        "换手率": 0.79,
        "symbol": "105.MDGS"
    },
    {
        "序号": 1453,
        "name": "Gulf Island Fabrication Inc",
        "最新价": 4.24,
        "涨跌额": 0.08,
        "涨跌幅": 1.92,
        "开盘价": 4.2,
        "最高价": 4.24,
        "最低价": 4.12,
        "昨收价": 4.16,
        "总市值": 69058869.0,
        "市盈率": -2.23,
        "成交量": 14666.0,
        "成交额": 61233.0,
        "振幅": 2.88,
        "换手率": 0.09,
        "symbol": "105.GIFI"
    },
    {
        "序号": 1454,
        "name": "Apyx Medical Corp",
        "最新价": 2.12,
        "涨跌额": 0.04,
        "涨跌幅": 1.92,
        "开盘价": 2.11,
        "最高价": 2.19,
        "最低价": 2.05,
        "昨收价": 2.08,
        "总市值": 73444973.0,
        "市盈率": -4.85,
        "成交量": 150801.0,
        "成交额": 318217.0,
        "振幅": 6.73,
        "换手率": 0.44,
        "symbol": "105.APYX"
    },
    {
        "序号": 1455,
        "name": "PC Connection Inc",
        "最新价": 65.21,
        "涨跌额": 1.23,
        "涨跌幅": 1.92,
        "开盘价": 64.15,
        "最高价": 65.33,
        "最低价": 63.79,
        "昨收价": 63.98,
        "总市值": 1713165884.0,
        "市盈率": 21.88,
        "成交量": 50409.0,
        "成交额": 3269563.0,
        "振幅": 2.41,
        "换手率": 0.19,
        "symbol": "105.CNXN"
    },
    {
        "序号": 1456,
        "name": "伊顿",
        "最新价": 232.31,
        "涨跌额": 4.38,
        "涨跌幅": 1.92,
        "开盘价": 228.18,
        "最高价": 233.45,
        "最低价": 227.93,
        "昨收价": 227.93,
        "总市值": 92761383000.0,
        "市盈率": 30.98,
        "成交量": 1909765.0,
        "成交额": 443056048.0,
        "振幅": 2.42,
        "换手率": 0.48,
        "symbol": "106.ETN"
    },
    {
        "序号": 1457,
        "name": "Flanigan's Enterprises Inc",
        "最新价": 25.99,
        "涨跌额": 0.49,
        "涨跌幅": 1.92,
        "开盘价": 25.81,
        "最高价": 25.99,
        "最低价": 25.5,
        "昨收价": 25.5,
        "总市值": 48306236.0,
        "市盈率": 8.98,
        "成交量": 8067.0,
        "成交额": 206154.0,
        "振幅": 1.92,
        "换手率": 0.43,
        "symbol": "107.BDL"
    },
    {
        "序号": 1458,
        "name": "Banc of California Inc",
        "最新价": 12.73,
        "涨跌额": 0.24,
        "涨跌幅": 1.92,
        "开盘价": 12.52,
        "最高价": 12.95,
        "最低价": 12.44,
        "昨收价": 12.49,
        "总市值": 731265061.0,
        "市盈率": 7.15,
        "成交量": 2573190.0,
        "成交额": 32787785.0,
        "振幅": 4.08,
        "换手率": 4.48,
        "symbol": "106.BANC"
    },
    {
        "序号": 1459,
        "name": "Consumer Portfolio Services Inc",
        "最新价": 9.55,
        "涨跌额": 0.18,
        "涨跌幅": 1.92,
        "开盘价": 9.32,
        "最高价": 9.66,
        "最低价": 9.12,
        "昨收价": 9.37,
        "总市值": 201698579.0,
        "市盈率": 3.86,
        "成交量": 22350.0,
        "成交额": 211650.0,
        "振幅": 5.76,
        "换手率": 0.11,
        "symbol": "105.CPSS"
    },
    {
        "序号": 1460,
        "name": "US Cellular Corp Notes",
        "最新价": 17.53,
        "涨跌额": 0.33,
        "涨跌幅": 1.92,
        "开盘价": 17.19,
        "最高价": 17.59,
        "最低价": 17.16,
        "昨收价": 17.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 46032.0,
        "成交额": 799804.0,
        "振幅": 2.5,
        "换手率": null,
        "symbol": "106.UZE"
    },
    {
        "序号": 1461,
        "name": "Herc Holdings Inc",
        "最新价": 124.37,
        "涨跌额": 2.34,
        "涨跌幅": 1.92,
        "开盘价": 122.34,
        "最高价": 125.36,
        "最低价": 120.94,
        "昨收价": 122.03,
        "总市值": 3515655217.0,
        "市盈率": 9.93,
        "成交量": 227295.0,
        "成交额": 28316789.0,
        "振幅": 3.62,
        "换手率": 0.8,
        "symbol": "106.HRI"
    },
    {
        "序号": 1462,
        "name": "ARS Pharmaceuticals Inc",
        "最新价": 5.32,
        "涨跌额": 0.1,
        "涨跌幅": 1.92,
        "开盘价": 5.2,
        "最高价": 5.39,
        "最低价": 5.13,
        "昨收价": 5.22,
        "总市值": 510705381.0,
        "市盈率": -8.29,
        "成交量": 397272.0,
        "成交额": 2106216.0,
        "振幅": 4.98,
        "换手率": 0.41,
        "symbol": "105.SPRY"
    },
    {
        "序号": 1463,
        "name": "德意志银行",
        "最新价": 12.77,
        "涨跌额": 0.24,
        "涨跌幅": 1.92,
        "开盘价": 12.61,
        "最高价": 12.78,
        "最低价": 12.59,
        "昨收价": 12.53,
        "总市值": 25658761000.0,
        "市盈率": 4.28,
        "成交量": 2664356.0,
        "成交额": 33891000.0,
        "振幅": 1.52,
        "换手率": 0.13,
        "symbol": "106.DB"
    },
    {
        "序号": 1464,
        "name": "nVent Electric plc",
        "最新价": 55.44,
        "涨跌额": 1.04,
        "涨跌幅": 1.91,
        "开盘价": 54.43,
        "最高价": 55.66,
        "最低价": 54.43,
        "昨收价": 54.4,
        "总市值": 9198150414.0,
        "市盈率": 19.53,
        "成交量": 871047.0,
        "成交额": 48185856.0,
        "振幅": 2.26,
        "换手率": 0.53,
        "symbol": "106.NVT"
    },
    {
        "序号": 1465,
        "name": "双环",
        "最新价": 15.46,
        "涨跌额": 0.29,
        "涨跌幅": 1.91,
        "开盘价": 15.17,
        "最高价": 15.72,
        "最低价": 14.98,
        "昨收价": 15.17,
        "总市值": 215834911.0,
        "市盈率": 20.31,
        "成交量": 30609.0,
        "成交额": 471172.0,
        "振幅": 4.88,
        "换手率": 0.22,
        "symbol": "105.TWIN"
    },
    {
        "序号": 1466,
        "name": "UWM Holdings Corp-A",
        "最新价": 5.87,
        "涨跌额": 0.11,
        "涨跌幅": 1.91,
        "开盘价": 5.75,
        "最高价": 5.91,
        "最低价": 5.72,
        "昨收价": 5.76,
        "总市值": 9366900209.0,
        "市盈率": 690.21,
        "成交量": 788941.0,
        "成交额": 4628683.0,
        "振幅": 3.3,
        "换手率": 0.05,
        "symbol": "106.UWMC"
    },
    {
        "序号": 1467,
        "name": "enCore Energy Corp",
        "最新价": 4.27,
        "涨跌额": 0.08,
        "涨跌幅": 1.91,
        "开盘价": 4.24,
        "最高价": 4.3,
        "最低价": 4.15,
        "昨收价": 4.19,
        "总市值": 687600380.0,
        "市盈率": -60.09,
        "成交量": 751703.0,
        "成交额": 3198879.0,
        "振幅": 3.58,
        "换手率": 0.47,
        "symbol": "107.EU"
    },
    {
        "序号": 1468,
        "name": "Banco BBVA Argentina SA ADR",
        "最新价": 5.34,
        "涨跌额": 0.1,
        "涨跌幅": 1.91,
        "开盘价": 5.25,
        "最高价": 5.35,
        "最低价": 5.07,
        "昨收价": 5.24,
        "总市值": 1090623941.0,
        "市盈率": 56.15,
        "成交量": 873280.0,
        "成交额": 4557327.0,
        "振幅": 5.34,
        "换手率": 0.43,
        "symbol": "106.BBAR"
    },
    {
        "序号": 1469,
        "name": "Sprott Junior Copper Miners ETF",
        "最新价": 17.63,
        "涨跌额": 0.33,
        "涨跌幅": 1.91,
        "开盘价": 17.14,
        "最高价": 17.63,
        "最低价": 17.14,
        "昨收价": 17.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 2325.0,
        "成交额": 40396.0,
        "振幅": 2.83,
        "换手率": null,
        "symbol": "105.COPJ"
    },
    {
        "序号": 1470,
        "name": "Cellebrite DI Ltd",
        "最新价": 8.55,
        "涨跌额": 0.16,
        "涨跌幅": 1.91,
        "开盘价": 8.38,
        "最高价": 8.58,
        "最低价": 8.3,
        "昨收价": 8.39,
        "总市值": 1687495032.0,
        "市盈率": -28.42,
        "成交量": 1630288.0,
        "成交额": 13788160.0,
        "振幅": 3.34,
        "换手率": 0.83,
        "symbol": "105.CLBT"
    },
    {
        "序号": 1471,
        "name": "艾诺斯",
        "最新价": 92.45,
        "涨跌额": 1.73,
        "涨跌幅": 1.91,
        "开盘价": 90.86,
        "最高价": 92.54,
        "最低价": 90.86,
        "昨收价": 90.72,
        "总市值": 3734899661.0,
        "市盈率": 15.41,
        "成交量": 189956.0,
        "成交额": 17499881.0,
        "振幅": 1.85,
        "换手率": 0.47,
        "symbol": "106.ENS"
    },
    {
        "序号": 1472,
        "name": "LiveOne Inc",
        "最新价": 1.07,
        "涨跌额": 0.02,
        "涨跌幅": 1.9,
        "开盘价": 1.06,
        "最高价": 1.1,
        "最低价": 1.05,
        "昨收价": 1.05,
        "总市值": 97557414.0,
        "市盈率": -6.08,
        "成交量": 110076.0,
        "成交额": 118115.0,
        "振幅": 4.76,
        "换手率": 0.12,
        "symbol": "105.LVO"
    },
    {
        "序号": 1473,
        "name": "Luna Innovations Inc",
        "最新价": 6.42,
        "涨跌额": 0.12,
        "涨跌幅": 1.9,
        "开盘价": 6.24,
        "最高价": 6.46,
        "最低价": 6.24,
        "昨收价": 6.3,
        "总市值": 218014475.0,
        "市盈率": -102.64,
        "成交量": 140284.0,
        "成交额": 897441.0,
        "振幅": 3.49,
        "换手率": 0.41,
        "symbol": "105.LUNA"
    },
    {
        "序号": 1474,
        "name": "United Community Banks Inc Seri",
        "最新价": 22.5,
        "涨跌额": 0.42,
        "涨跌幅": 1.9,
        "开盘价": 22.22,
        "最高价": 22.8,
        "最低价": 22.01,
        "昨收价": 22.08,
        "总市值": 84263.0,
        "市盈率": null,
        "成交量": 11511.0,
        "成交额": 255543.0,
        "振幅": 3.58,
        "换手率": 307.37,
        "symbol": "105.UCBIO"
    },
    {
        "序号": 1475,
        "name": "美国血液技术",
        "最新价": 84.84,
        "涨跌额": 1.58,
        "涨跌幅": 1.9,
        "开盘价": 83.17,
        "最高价": 84.89,
        "最低价": 82.8,
        "昨收价": 83.26,
        "总市值": 4304991749.0,
        "市盈率": 33.56,
        "成交量": 226650.0,
        "成交额": 19105136.0,
        "振幅": 2.51,
        "换手率": 0.45,
        "symbol": "106.HAE"
    },
    {
        "序号": 1476,
        "name": "Nexa Resources SA",
        "最新价": 5.91,
        "涨跌额": 0.11,
        "涨跌幅": 1.9,
        "开盘价": 5.71,
        "最高价": 5.91,
        "最低价": 5.71,
        "昨收价": 5.8,
        "总市值": 782712191.0,
        "市盈率": -2.82,
        "成交量": 17237.0,
        "成交额": 100599.0,
        "振幅": 3.45,
        "换手率": 0.01,
        "symbol": "106.NEXA"
    },
    {
        "序号": 1477,
        "name": "泛华金融",
        "最新价": 2.15,
        "涨跌额": 0.04,
        "涨跌幅": 1.9,
        "开盘价": 2.11,
        "最高价": 2.22,
        "最低价": 2.09,
        "昨收价": 2.11,
        "总市值": 147451648.0,
        "市盈率": 5.97,
        "成交量": 10423.0,
        "成交额": 22369.0,
        "振幅": 6.16,
        "换手率": 0.02,
        "symbol": "106.CNF"
    },
    {
        "序号": 1478,
        "name": "ON24 Inc",
        "最新价": 7.54,
        "涨跌额": 0.14,
        "涨跌幅": 1.89,
        "开盘价": 7.34,
        "最高价": 7.58,
        "最低价": 7.34,
        "昨收价": 7.4,
        "总市值": 312919651.0,
        "市盈率": -5.79,
        "成交量": 307799.0,
        "成交额": 2309623.0,
        "振幅": 3.24,
        "换手率": 0.74,
        "symbol": "106.ONTF"
    },
    {
        "序号": 1479,
        "name": "阿帕奇石油",
        "最新价": 34.47,
        "涨跌额": 0.64,
        "涨跌幅": 1.89,
        "开盘价": 34.05,
        "最高价": 34.52,
        "最低价": 33.8,
        "昨收价": 33.83,
        "总市值": 10572618442.0,
        "市盈率": 6.93,
        "成交量": 2732037.0,
        "成交额": 93685650.0,
        "振幅": 2.13,
        "换手率": 0.89,
        "symbol": "105.APA"
    },
    {
        "序号": 1480,
        "name": "Atkore Inc",
        "最新价": 135.23,
        "涨跌额": 2.51,
        "涨跌幅": 1.89,
        "开盘价": 132.72,
        "最高价": 136.79,
        "最低价": 132.64,
        "昨收价": 132.72,
        "总市值": 5046526257.0,
        "市盈率": 7.31,
        "成交量": 400464.0,
        "成交额": 54203773.0,
        "振幅": 3.13,
        "换手率": 1.07,
        "symbol": "106.ATKR"
    },
    {
        "序号": 1481,
        "name": "Permian Resources Corp-A",
        "最新价": 12.95,
        "涨跌额": 0.24,
        "涨跌幅": 1.89,
        "开盘价": 12.85,
        "最高价": 13.02,
        "最低价": 12.78,
        "昨收价": 12.71,
        "总市值": 10020280358.0,
        "市盈率": 32.96,
        "成交量": 9438956.0,
        "成交额": 121881898.0,
        "振幅": 1.89,
        "换手率": 1.22,
        "symbol": "106.PR"
    },
    {
        "序号": 1482,
        "name": "Valkyrie Bitcoin Strategy ETF",
        "最新价": 16.19,
        "涨跌额": 0.3,
        "涨跌幅": 1.89,
        "开盘价": 16.0,
        "最高价": 16.21,
        "最低价": 15.98,
        "昨收价": 15.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 28604.0,
        "成交额": 459573.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "105.BTF"
    },
    {
        "序号": 1483,
        "name": "Lument Finance Trust Inc",
        "最新价": 2.16,
        "涨跌额": 0.04,
        "涨跌幅": 1.89,
        "开盘价": 2.13,
        "最高价": 2.16,
        "最低价": 2.12,
        "昨收价": 2.12,
        "总市值": 112819288.0,
        "市盈率": 6.73,
        "成交量": 31477.0,
        "成交额": 67488.0,
        "振幅": 1.89,
        "换手率": 0.06,
        "symbol": "106.LFT"
    },
    {
        "序号": 1484,
        "name": "Meta Platforms Inc-A",
        "最新价": 332.75,
        "涨跌额": 6.16,
        "涨跌幅": 1.89,
        "开盘价": 323.09,
        "最高价": 333.17,
        "最低价": 323.0,
        "昨收价": 326.59,
        "总市值": 855121824073.0,
        "市盈率": 28.76,
        "成交量": 14087351.0,
        "成交额": 4653388032.0,
        "振幅": 3.11,
        "换手率": 0.55,
        "symbol": "105.META"
    },
    {
        "序号": 1485,
        "name": "安德森斯",
        "最新价": 53.04,
        "涨跌额": 0.98,
        "涨跌幅": 1.88,
        "开盘价": 51.9,
        "最高价": 53.04,
        "最低价": 51.29,
        "昨收价": 52.06,
        "总市值": 1790223106.0,
        "市盈率": 30.33,
        "成交量": 183162.0,
        "成交额": 9671975.0,
        "振幅": 3.36,
        "换手率": 0.54,
        "symbol": "105.ANDE"
    },
    {
        "序号": 1486,
        "name": "Newmark Group Inc-A",
        "最新价": 8.68,
        "涨跌额": 0.16,
        "涨跌幅": 1.88,
        "开盘价": 8.5,
        "最高价": 8.7,
        "最低价": 8.33,
        "昨收价": 8.52,
        "总市值": 1498985621.0,
        "市盈率": 120.36,
        "成交量": 837729.0,
        "成交额": 7225860.0,
        "振幅": 4.34,
        "换手率": 0.49,
        "symbol": "105.NMRK"
    },
    {
        "序号": 1487,
        "name": "OPKO健康",
        "最新价": 1.63,
        "涨跌额": 0.03,
        "涨跌幅": 1.88,
        "开盘价": 1.6,
        "最高价": 1.63,
        "最低价": 1.56,
        "昨收价": 1.6,
        "总市值": 1260082149.0,
        "市盈率": -6.07,
        "成交量": 1105866.0,
        "成交额": 1775238.0,
        "振幅": 4.38,
        "换手率": 0.14,
        "symbol": "105.OPK"
    },
    {
        "序号": 1488,
        "name": "艾卫",
        "最新价": 1.63,
        "涨跌额": 0.03,
        "涨跌幅": 1.88,
        "开盘价": 1.69,
        "最高价": 1.69,
        "最低价": 1.6,
        "昨收价": 1.6,
        "总市值": 34215035.0,
        "市盈率": -7.08,
        "成交量": 47377.0,
        "成交额": 76525.0,
        "振幅": 5.63,
        "换手率": 0.23,
        "symbol": "105.AWRE"
    },
    {
        "序号": 1489,
        "name": "FVCBankcorp Inc",
        "最新价": 12.52,
        "涨跌额": 0.23,
        "涨跌幅": 1.87,
        "开盘价": 12.42,
        "最高价": 12.61,
        "最低价": 12.33,
        "昨收价": 12.29,
        "总市值": 222883206.0,
        "市盈率": 16.16,
        "成交量": 13273.0,
        "成交额": 165617.0,
        "振幅": 2.28,
        "换手率": 0.07,
        "symbol": "105.FVCB"
    },
    {
        "序号": 1490,
        "name": "恩斯塔",
        "最新价": 285.14,
        "涨跌额": 5.23,
        "涨跌幅": 1.87,
        "开盘价": 282.04,
        "最高价": 285.15,
        "最低价": 282.04,
        "昨收价": 279.91,
        "总市值": 4573452845.0,
        "市盈率": 6.13,
        "成交量": 25150.0,
        "成交额": 7151315.0,
        "振幅": 1.11,
        "换手率": 0.16,
        "symbol": "105.ESGR"
    },
    {
        "序号": 1491,
        "name": "马林银行",
        "最新价": 20.72,
        "涨跌额": 0.38,
        "涨跌幅": 1.87,
        "开盘价": 20.39,
        "最高价": 20.8,
        "最低价": 20.35,
        "昨收价": 20.34,
        "总市值": 334406731.0,
        "市盈率": 10.4,
        "成交量": 40643.0,
        "成交额": 839317.0,
        "振幅": 2.21,
        "换手率": 0.25,
        "symbol": "105.BMRC"
    },
    {
        "序号": 1492,
        "name": "托尔兄弟",
        "最新价": 92.86,
        "涨跌额": 1.7,
        "涨跌幅": 1.86,
        "开盘价": 90.73,
        "最高价": 93.26,
        "最低价": 90.69,
        "昨收价": 91.16,
        "总市值": 9980499940.0,
        "市盈率": 7.27,
        "成交量": 1862270.0,
        "成交额": 172365941.0,
        "振幅": 2.82,
        "换手率": 1.73,
        "symbol": "106.TOL"
    },
    {
        "序号": 1493,
        "name": "罗杰斯",
        "最新价": 130.07,
        "涨跌额": 2.38,
        "涨跌幅": 1.86,
        "开盘价": 126.86,
        "最高价": 130.15,
        "最低价": 126.07,
        "昨收价": 127.69,
        "总市值": 2421450626.0,
        "市盈率": 24.05,
        "成交量": 77298.0,
        "成交额": 9961137.0,
        "振幅": 3.2,
        "换手率": 0.42,
        "symbol": "106.ROG"
    },
    {
        "序号": 1494,
        "name": "PennantPark Floating Rate Capit",
        "最新价": 11.48,
        "涨跌额": 0.21,
        "涨跌幅": 1.86,
        "开盘价": 11.27,
        "最高价": 11.49,
        "最低价": 11.27,
        "昨收价": 11.27,
        "总市值": 674274379.0,
        "市盈率": 17.17,
        "成交量": 420141.0,
        "成交额": 4797241.0,
        "振幅": 1.95,
        "换手率": 0.72,
        "symbol": "106.PFLT"
    },
    {
        "序号": 1495,
        "name": "MGP Ingredients Inc",
        "最新价": 88.05,
        "涨跌额": 1.61,
        "涨跌幅": 1.86,
        "开盘价": 86.46,
        "最高价": 89.2,
        "最低价": 86.29,
        "昨收价": 86.44,
        "总市值": 1938518750.0,
        "市盈率": 19.57,
        "成交量": 162931.0,
        "成交额": 14375399.0,
        "振幅": 3.37,
        "换手率": 0.74,
        "symbol": "105.MGPI"
    },
    {
        "序号": 1496,
        "name": "HDFC银行",
        "最新价": 64.54,
        "涨跌额": 1.18,
        "涨跌幅": 1.86,
        "开盘价": 63.78,
        "最高价": 64.61,
        "最低价": 63.7,
        "昨收价": 63.36,
        "总市值": 120038866469.0,
        "市盈率": 18.89,
        "成交量": 2388687.0,
        "成交额": 153707973.0,
        "振幅": 1.44,
        "换手率": 0.13,
        "symbol": "106.HDB"
    },
    {
        "序号": 1497,
        "name": "TTEC Holdings Inc",
        "最新价": 20.26,
        "涨跌额": 0.37,
        "涨跌幅": 1.86,
        "开盘价": 19.88,
        "最高价": 20.31,
        "最低价": 19.78,
        "昨收价": 19.89,
        "总市值": 960817756.0,
        "市盈率": 23.62,
        "成交量": 157386.0,
        "成交额": 3164635.0,
        "振幅": 2.66,
        "换手率": 0.33,
        "symbol": "105.TTEC"
    },
    {
        "序号": 1498,
        "name": "MGIC Investment Corp",
        "最新价": 18.08,
        "涨跌额": 0.33,
        "涨跌幅": 1.86,
        "开盘价": 17.72,
        "最高价": 18.1,
        "最低价": 17.72,
        "昨收价": 17.75,
        "总市值": 5013790691.0,
        "市盈率": 6.96,
        "成交量": 1586624.0,
        "成交额": 28567459.0,
        "振幅": 2.14,
        "换手率": 0.57,
        "symbol": "106.MTG"
    },
    {
        "序号": 1499,
        "name": "Sprott Energy Transition Materi",
        "最新价": 16.99,
        "涨跌额": 0.31,
        "涨跌幅": 1.86,
        "开盘价": 16.92,
        "最高价": 16.99,
        "最低价": 16.85,
        "昨收价": 16.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 3114.0,
        "成交额": 52630.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "105.SETM"
    },
    {
        "序号": 1500,
        "name": "标志点制药",
        "最新价": 3.29,
        "涨跌额": 0.06,
        "涨跌幅": 1.86,
        "开盘价": 3.26,
        "最高价": 3.34,
        "最低价": 3.06,
        "昨收价": 3.23,
        "总市值": 77400655.0,
        "市盈率": -1.44,
        "成交量": 782677.0,
        "成交额": 2515618.0,
        "振幅": 8.67,
        "换手率": 3.33,
        "symbol": "105.CKPT"
    },
    {
        "序号": 1501,
        "name": "AMG资管",
        "最新价": 138.9,
        "涨跌额": 2.53,
        "涨跌幅": 1.86,
        "开盘价": 136.64,
        "最高价": 139.16,
        "最低价": 136.64,
        "昨收价": 136.37,
        "总市值": 4696019540.0,
        "市盈率": 3.74,
        "成交量": 232443.0,
        "成交额": 32211510.0,
        "振幅": 1.85,
        "换手率": 0.69,
        "symbol": "106.AMG"
    },
    {
        "序号": 1502,
        "name": "依生生物",
        "最新价": 0.55,
        "涨跌额": 0.01,
        "涨跌幅": 1.85,
        "开盘价": 0.55,
        "最高价": 0.58,
        "最低价": 0.54,
        "昨收价": 0.54,
        "总市值": 51182008.0,
        "市盈率": 2.9,
        "成交量": 20676.0,
        "成交额": 11448.0,
        "振幅": 7.41,
        "换手率": 0.02,
        "symbol": "105.YS"
    },
    {
        "序号": 1503,
        "name": "Power REIT",
        "最新价": 0.55,
        "涨跌额": 0.01,
        "涨跌幅": 1.85,
        "开盘价": 0.53,
        "最高价": 0.56,
        "最低价": 0.53,
        "昨收价": 0.54,
        "总市值": 1864314.0,
        "市盈率": -0.06,
        "成交量": 9307.0,
        "成交额": 5061.0,
        "振幅": 5.56,
        "换手率": 0.27,
        "symbol": "107.PW"
    },
    {
        "序号": 1504,
        "name": "GEE Group Inc",
        "最新价": 0.55,
        "涨跌额": 0.01,
        "涨跌幅": 1.85,
        "开盘价": 0.54,
        "最高价": 0.55,
        "最低价": 0.53,
        "昨收价": 0.54,
        "总市值": 62230152.0,
        "市盈率": 7.41,
        "成交量": 70771.0,
        "成交额": 38233.0,
        "振幅": 3.7,
        "换手率": 0.06,
        "symbol": "107.JOB"
    },
    {
        "序号": 1505,
        "name": "Cantaloupe Inc",
        "最新价": 7.15,
        "涨跌额": 0.13,
        "涨跌幅": 1.85,
        "开盘价": 7.0,
        "最高价": 7.17,
        "最低价": 7.0,
        "昨收价": 7.02,
        "总市值": 520000624.0,
        "市盈率": 46.37,
        "成交量": 173333.0,
        "成交额": 1236342.0,
        "振幅": 2.42,
        "换手率": 0.24,
        "symbol": "105.CTLP"
    },
    {
        "序号": 1506,
        "name": "MACOM Technology Solutions Hold",
        "最新价": 86.38,
        "涨跌额": 1.57,
        "涨跌幅": 1.85,
        "开盘价": 84.54,
        "最高价": 87.01,
        "最低价": 83.63,
        "昨收价": 84.81,
        "总市值": 6150958788.0,
        "市盈率": 67.17,
        "成交量": 304205.0,
        "成交额": 26249211.0,
        "振幅": 3.99,
        "换手率": 0.43,
        "symbol": "105.MTSI"
    },
    {
        "序号": 1507,
        "name": "Perella Weinberg Partners-A",
        "最新价": 12.12,
        "涨跌额": 0.22,
        "涨跌幅": 1.85,
        "开盘价": 11.86,
        "最高价": 12.17,
        "最低价": 11.79,
        "昨收价": 11.9,
        "总市值": 1044121383.0,
        "市盈率": -126.81,
        "成交量": 389086.0,
        "成交额": 4666762.0,
        "振幅": 3.19,
        "换手率": 0.45,
        "symbol": "105.PWP"
    },
    {
        "序号": 1508,
        "name": "BlackRock Long-Term Municipal A",
        "最新价": 10.47,
        "涨跌额": 0.19,
        "涨跌幅": 1.85,
        "开盘价": 10.25,
        "最高价": 10.47,
        "最低价": 10.11,
        "昨收价": 10.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 28633.0,
        "成交额": 292311.0,
        "振幅": 3.5,
        "换手率": null,
        "symbol": "106.BTA"
    },
    {
        "序号": 1509,
        "name": "Associated Capital Group Inc-A",
        "最新价": 34.22,
        "涨跌额": 0.62,
        "涨跌幅": 1.85,
        "开盘价": 33.94,
        "最高价": 34.22,
        "最低价": 33.87,
        "昨收价": 33.6,
        "总市值": 738852335.0,
        "市盈率": 21.25,
        "成交量": 1743.0,
        "成交额": 59317.0,
        "振幅": 1.04,
        "换手率": 0.01,
        "symbol": "106.AC"
    },
    {
        "序号": 1510,
        "name": "Abivax SA ADR",
        "最新价": 10.49,
        "涨跌额": 0.19,
        "涨跌幅": 1.84,
        "开盘价": 10.5,
        "最高价": 10.8,
        "最低价": 10.29,
        "昨收价": 10.3,
        "总市值": 659538483.0,
        "市盈率": -6.65,
        "成交量": 186531.0,
        "成交额": 1951061.0,
        "振幅": 4.95,
        "换手率": 0.3,
        "symbol": "105.ABVX"
    },
    {
        "序号": 1511,
        "name": "加拿大自然资源",
        "最新价": 62.4,
        "涨跌额": 1.13,
        "涨跌幅": 1.84,
        "开盘价": 61.57,
        "最高价": 62.5,
        "最低价": 61.29,
        "昨收价": 61.27,
        "总市值": 67542696000.0,
        "市盈率": 12.64,
        "成交量": 4636274.0,
        "成交额": 287577232.0,
        "振幅": 1.97,
        "换手率": 0.43,
        "symbol": "106.CNQ"
    },
    {
        "序号": 1512,
        "name": "ARK Innovation ETF",
        "最新价": 49.18,
        "涨跌额": 0.89,
        "涨跌幅": 1.84,
        "开盘价": 48.06,
        "最高价": 49.7,
        "最低价": 47.78,
        "昨收价": 48.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 19869098.0,
        "成交额": 972984256.0,
        "振幅": 3.98,
        "换手率": null,
        "symbol": "107.ARKK"
    },
    {
        "序号": 1513,
        "name": "IDX Dynamic Innovation ETF",
        "最新价": 26.53,
        "涨跌额": 0.48,
        "涨跌幅": 1.84,
        "开盘价": 26.48,
        "最高价": 26.53,
        "最低价": 26.37,
        "昨收价": 26.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 956.0,
        "成交额": 25235.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.DYNI"
    },
    {
        "序号": 1514,
        "name": "艾可菲",
        "最新价": 228.38,
        "涨跌额": 4.13,
        "涨跌幅": 1.84,
        "开盘价": 226.18,
        "最高价": 231.77,
        "最低价": 225.03,
        "昨收价": 224.25,
        "总市值": 28140247303.0,
        "市盈率": 54.0,
        "成交量": 1748728.0,
        "成交额": 400130064.0,
        "振幅": 3.01,
        "换手率": 1.42,
        "symbol": "106.EFX"
    },
    {
        "序号": 1515,
        "name": "National HealthCare Corp",
        "最新价": 88.6,
        "涨跌额": 1.6,
        "涨跌幅": 1.84,
        "开盘价": 87.02,
        "最高价": 89.18,
        "最低价": 86.11,
        "昨收价": 87.0,
        "总市值": 1357756016.0,
        "市盈率": 30.34,
        "成交量": 200554.0,
        "成交额": 17735098.0,
        "振幅": 3.53,
        "换手率": 1.31,
        "symbol": "107.NHC"
    },
    {
        "序号": 1516,
        "name": "Cogent通信",
        "最新价": 69.25,
        "涨跌额": 1.25,
        "涨跌幅": 1.84,
        "开盘价": 68.33,
        "最高价": 69.4,
        "最低价": 68.01,
        "昨收价": 68.0,
        "总市值": 3367201336.0,
        "市盈率": 3.13,
        "成交量": 349209.0,
        "成交额": 24065196.0,
        "振幅": 2.04,
        "换手率": 0.72,
        "symbol": "105.CCOI"
    },
    {
        "序号": 1517,
        "name": "IF Bancorp Inc",
        "最新价": 14.41,
        "涨跌额": 0.26,
        "涨跌幅": 1.84,
        "开盘价": 14.22,
        "最高价": 14.41,
        "最低价": 14.22,
        "昨收价": 14.15,
        "总市值": 48340161.0,
        "市盈率": 15.31,
        "成交量": 4692.0,
        "成交额": 67030.0,
        "振幅": 1.34,
        "换手率": 0.14,
        "symbol": "105.IROQ"
    },
    {
        "序号": 1518,
        "name": "BYTE Acquisition Corp-A",
        "最新价": 11.09,
        "涨跌额": 0.2,
        "涨跌幅": 1.84,
        "开盘价": 10.89,
        "最高价": 12.0,
        "最低价": 10.89,
        "昨收价": 10.89,
        "总市值": 121545380.0,
        "市盈率": -618.58,
        "成交量": 5163.0,
        "成交额": 58238.0,
        "振幅": 10.19,
        "换手率": 0.05,
        "symbol": "105.BYTS"
    },
    {
        "序号": 1519,
        "name": "EIDP Inc Pfd",
        "最新价": 55.55,
        "涨跌额": 1.0,
        "涨跌幅": 1.83,
        "开盘价": 54.85,
        "最高价": 55.55,
        "最低价": 54.85,
        "昨收价": 54.55,
        "总市值": 38885000.0,
        "市盈率": null,
        "成交量": 712.0,
        "成交额": 39373.0,
        "振幅": 1.28,
        "换手率": 0.1,
        "symbol": "106.CTA_A"
    },
    {
        "序号": 1520,
        "name": "FolioBeyond Rising Rates ETF",
        "最新价": 33.39,
        "涨跌额": 0.6,
        "涨跌幅": 1.83,
        "开盘价": 33.16,
        "最高价": 33.39,
        "最低价": 32.82,
        "昨收价": 32.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 70385.0,
        "成交额": 2336162.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "107.RISR"
    },
    {
        "序号": 1521,
        "name": "Direxion Daily NYSE FANG+ Bull ",
        "最新价": 83.92,
        "涨跌额": 1.5,
        "涨跌幅": 1.82,
        "开盘价": 81.05,
        "最高价": 84.28,
        "最低价": 81.05,
        "昨收价": 82.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 9875.0,
        "成交额": 825431.0,
        "振幅": 3.92,
        "换手率": null,
        "symbol": "107.FNGG"
    },
    {
        "序号": 1522,
        "name": "恩智浦半导体",
        "最新价": 215.55,
        "涨跌额": 3.85,
        "涨跌幅": 1.82,
        "开盘价": 211.43,
        "最高价": 217.23,
        "最低价": 210.8,
        "昨收价": 211.7,
        "总市值": 55560879531.0,
        "市盈率": 19.69,
        "成交量": 2609802.0,
        "成交额": 562564816.0,
        "振幅": 3.04,
        "换手率": 1.01,
        "symbol": "105.NXPI"
    },
    {
        "序号": 1523,
        "name": "M.D.C. Holdings Inc",
        "最新价": 49.27,
        "涨跌额": 0.88,
        "涨跌幅": 1.82,
        "开盘价": 48.16,
        "最高价": 49.4,
        "最低价": 48.16,
        "昨收价": 48.39,
        "总市值": 3678571070.0,
        "市盈率": 10.18,
        "成交量": 453088.0,
        "成交额": 22255967.0,
        "振幅": 2.56,
        "换手率": 0.61,
        "symbol": "106.MDC"
    },
    {
        "序号": 1524,
        "name": "Retractable Technologies Inc",
        "最新价": 1.12,
        "涨跌额": 0.02,
        "涨跌幅": 1.82,
        "开盘价": 1.07,
        "最高价": 1.12,
        "最低价": 1.07,
        "昨收价": 1.1,
        "总市值": 33529618.0,
        "市盈率": -2.99,
        "成交量": 71731.0,
        "成交额": 78177.0,
        "振幅": 4.55,
        "换手率": 0.24,
        "symbol": "107.RVP"
    },
    {
        "序号": 1525,
        "name": "Revelation Biosciences Inc",
        "最新价": 0.56,
        "涨跌额": 0.01,
        "涨跌幅": 1.82,
        "开盘价": 0.57,
        "最高价": 0.57,
        "最低价": 0.5,
        "昨收价": 0.55,
        "总市值": 3526490.0,
        "市盈率": 4.03,
        "成交量": 94122.0,
        "成交额": 50571.0,
        "振幅": 12.73,
        "换手率": 1.49,
        "symbol": "105.REVB"
    },
    {
        "序号": 1526,
        "name": "Navient Corp",
        "最新价": 18.48,
        "涨跌额": 0.33,
        "涨跌幅": 1.82,
        "开盘价": 18.15,
        "最高价": 18.53,
        "最低价": 17.99,
        "昨收价": 18.15,
        "总市值": 2172713762.0,
        "市盈率": 6.02,
        "成交量": 612974.0,
        "成交额": 11286462.0,
        "振幅": 2.98,
        "换手率": 0.52,
        "symbol": "105.NAVI"
    },
    {
        "序号": 1527,
        "name": "Drilling Tools International Co",
        "最新价": 3.36,
        "涨跌额": 0.06,
        "涨跌幅": 1.82,
        "开盘价": 3.34,
        "最高价": 3.36,
        "最低价": 3.29,
        "昨收价": 3.3,
        "总市值": 100022388.0,
        "市盈率": -43.07,
        "成交量": 33092.0,
        "成交额": 110322.0,
        "振幅": 2.12,
        "换手率": 0.11,
        "symbol": "105.DTI"
    },
    {
        "序号": 1528,
        "name": "AvidXchange Holdings Inc",
        "最新价": 11.2,
        "涨跌额": 0.2,
        "涨跌幅": 1.82,
        "开盘价": 10.91,
        "最高价": 11.24,
        "最低价": 10.88,
        "昨收价": 11.0,
        "总市值": 2275252930.0,
        "市盈率": -33.52,
        "成交量": 1913152.0,
        "成交额": 21262431.0,
        "振幅": 3.27,
        "换手率": 0.94,
        "symbol": "105.AVDX"
    },
    {
        "序号": 1529,
        "name": "二倍做空日元ETF-ProShares",
        "最新价": 72.27,
        "涨跌额": 1.29,
        "涨跌幅": 1.82,
        "开盘价": 71.71,
        "最高价": 72.27,
        "最低价": 71.44,
        "昨收价": 70.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 17325.0,
        "成交额": 1248733.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "107.YCS"
    },
    {
        "序号": 1530,
        "name": "马拉松石油",
        "最新价": 24.09,
        "涨跌额": 0.43,
        "涨跌幅": 1.82,
        "开盘价": 23.9,
        "最高价": 24.12,
        "最低价": 23.79,
        "昨收价": 23.66,
        "总市值": 14098608854.0,
        "市盈率": 8.38,
        "成交量": 7816371.0,
        "成交额": 187632233.0,
        "振幅": 1.39,
        "换手率": 1.34,
        "symbol": "106.MRO"
    },
    {
        "序号": 1531,
        "name": "Cboe Vest 10 Year Interest Rate",
        "最新价": 29.72,
        "涨跌额": 0.53,
        "涨跌幅": 1.82,
        "开盘价": 29.72,
        "最高价": 29.72,
        "最低价": 29.72,
        "昨收价": 29.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 905.0,
        "成交额": 26900.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RYSE"
    },
    {
        "序号": 1532,
        "name": "美国布伦特原油基金",
        "最新价": 26.95,
        "涨跌额": 0.48,
        "涨跌幅": 1.81,
        "开盘价": 26.84,
        "最高价": 27.14,
        "最低价": 26.75,
        "昨收价": 26.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 665178.0,
        "成交额": 17927902.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "107.BNO"
    },
    {
        "序号": 1533,
        "name": "Bausch Health Companies Inc",
        "最新价": 7.3,
        "涨跌额": 0.13,
        "涨跌幅": 1.81,
        "开盘价": 7.16,
        "最高价": 7.33,
        "最低价": 7.15,
        "昨收价": 7.17,
        "总市值": 2665923850.0,
        "市盈率": -2.77,
        "成交量": 3254438.0,
        "成交额": 23569086.0,
        "振幅": 2.51,
        "换手率": 0.89,
        "symbol": "106.BHC"
    },
    {
        "序号": 1534,
        "name": "英飞朗",
        "最新价": 4.5,
        "涨跌额": 0.08,
        "涨跌幅": 1.81,
        "开盘价": 4.41,
        "最高价": 4.54,
        "最低价": 4.41,
        "昨收价": 4.42,
        "总市值": 1021156965.0,
        "市盈率": -142.92,
        "成交量": 3191807.0,
        "成交额": 14226864.0,
        "振幅": 2.94,
        "换手率": 1.41,
        "symbol": "105.INFN"
    },
    {
        "序号": 1535,
        "name": "Antero Midstream Corp",
        "最新价": 12.94,
        "涨跌额": 0.23,
        "涨跌幅": 1.81,
        "开盘价": 12.75,
        "最高价": 12.94,
        "最低价": 12.75,
        "昨收价": 12.71,
        "总市值": 6207486220.0,
        "市盈率": 17.53,
        "成交量": 1908670.0,
        "成交额": 24623364.0,
        "振幅": 1.49,
        "换手率": 0.4,
        "symbol": "106.AM"
    },
    {
        "序号": 1536,
        "name": "Skyline Champion Corp",
        "最新价": 66.41,
        "涨跌额": 1.18,
        "涨跌幅": 1.81,
        "开盘价": 65.19,
        "最高价": 66.5,
        "最低价": 64.8,
        "昨收价": 65.23,
        "总市值": 3826390062.0,
        "市盈率": 16.11,
        "成交量": 227107.0,
        "成交额": 14992133.0,
        "振幅": 2.61,
        "换手率": 0.39,
        "symbol": "106.SKY"
    },
    {
        "序号": 1537,
        "name": "ETFMG Alternative Harvest ETF",
        "最新价": 3.38,
        "涨跌额": 0.06,
        "涨跌幅": 1.81,
        "开盘价": 3.36,
        "最高价": 3.41,
        "最低价": 3.31,
        "昨收价": 3.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 2534400.0,
        "成交额": 8524648.0,
        "振幅": 3.01,
        "换手率": null,
        "symbol": "107.MJ"
    },
    {
        "序号": 1538,
        "name": "二倍做空20年+国债ETF-ProShares",
        "最新价": 33.82,
        "涨跌额": 0.6,
        "涨跌幅": 1.81,
        "开盘价": 33.92,
        "最高价": 34.24,
        "最低价": 33.68,
        "昨收价": 33.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 1672748.0,
        "成交额": 56785243.0,
        "振幅": 1.69,
        "换手率": null,
        "symbol": "107.TBT"
    },
    {
        "序号": 1539,
        "name": "艾斯本科技",
        "最新价": 198.99,
        "涨跌额": 3.53,
        "涨跌幅": 1.81,
        "开盘价": 194.21,
        "最高价": 200.07,
        "最低价": 194.21,
        "昨收价": 195.46,
        "总市值": 12675534452.0,
        "市盈率": -117.63,
        "成交量": 154997.0,
        "成交额": 30771673.0,
        "振幅": 3.0,
        "换手率": 0.24,
        "symbol": "105.AZPN"
    },
    {
        "序号": 1540,
        "name": "Fulgent Genetics Inc",
        "最新价": 27.08,
        "涨跌额": 0.48,
        "涨跌幅": 1.8,
        "开盘价": 26.46,
        "最高价": 27.25,
        "最低价": 26.46,
        "昨收价": 26.6,
        "总市值": 802463184.0,
        "市盈率": -12.64,
        "成交量": 186074.0,
        "成交额": 5025777.0,
        "振幅": 2.97,
        "换手率": 0.63,
        "symbol": "105.FLGT"
    },
    {
        "序号": 1541,
        "name": "Spirit Realty Capital Inc Serie",
        "最新价": 23.72,
        "涨跌额": 0.42,
        "涨跌幅": 1.8,
        "开盘价": 23.96,
        "最高价": 23.96,
        "最低价": 23.31,
        "昨收价": 23.3,
        "总市值": 163668000.0,
        "市盈率": null,
        "成交量": 3141.0,
        "成交额": 74447.0,
        "振幅": 2.79,
        "换手率": 0.05,
        "symbol": "106.SRC_A"
    },
    {
        "序号": 1542,
        "name": "高盛",
        "最新价": 350.83,
        "涨跌额": 6.21,
        "涨跌幅": 1.8,
        "开盘价": 344.31,
        "最高价": 352.29,
        "最低价": 343.78,
        "昨收价": 344.62,
        "总市值": 114410035745.0,
        "市盈率": 14.6,
        "成交量": 2239001.0,
        "成交额": 784228304.0,
        "振幅": 2.47,
        "换手率": 0.69,
        "symbol": "106.GS"
    },
    {
        "序号": 1543,
        "name": "Tronox Holdings plc",
        "最新价": 13.01,
        "涨跌额": 0.23,
        "涨跌幅": 1.8,
        "开盘价": 12.8,
        "最高价": 13.2,
        "最低价": 12.73,
        "昨收价": 12.78,
        "总市值": 2039886753.0,
        "市盈率": -7.42,
        "成交量": 831099.0,
        "成交额": 10793091.0,
        "振幅": 3.68,
        "换手率": 0.53,
        "symbol": "106.TROX"
    },
    {
        "序号": 1544,
        "name": "联邦信号",
        "最新价": 72.41,
        "涨跌额": 1.28,
        "涨跌幅": 1.8,
        "开盘价": 70.92,
        "最高价": 72.59,
        "最低价": 70.89,
        "昨收价": 71.13,
        "总市值": 4411855349.0,
        "市盈率": 30.3,
        "成交量": 258567.0,
        "成交额": 18662299.0,
        "振幅": 2.39,
        "换手率": 0.42,
        "symbol": "106.FSS"
    },
    {
        "序号": 1545,
        "name": "慧荣科技",
        "最新价": 58.86,
        "涨跌额": 1.04,
        "涨跌幅": 1.8,
        "开盘价": 57.47,
        "最高价": 58.89,
        "最低价": 57.47,
        "昨收价": 57.82,
        "总市值": 1966351794.0,
        "市盈率": 35.55,
        "成交量": 960142.0,
        "成交额": 56081510.0,
        "振幅": 2.46,
        "换手率": 2.87,
        "symbol": "105.SIMO"
    },
    {
        "序号": 1546,
        "name": "Mirum Pharmaceuticals Inc",
        "最新价": 32.86,
        "涨跌额": 0.58,
        "涨跌幅": 1.8,
        "开盘价": 32.38,
        "最高价": 33.06,
        "最低价": 32.28,
        "昨收价": 32.28,
        "总市值": 1531745044.0,
        "市盈率": -36.74,
        "成交量": 533822.0,
        "成交额": 17512272.0,
        "振幅": 2.42,
        "换手率": 1.15,
        "symbol": "105.MIRM"
    },
    {
        "序号": 1547,
        "name": "Snowflake Inc-A",
        "最新价": 190.67,
        "涨跌额": 3.36,
        "涨跌幅": 1.79,
        "开盘价": 187.0,
        "最高价": 191.64,
        "最低价": 185.8,
        "昨收价": 187.31,
        "总市值": 62787631000.0,
        "市盈率": -71.85,
        "成交量": 5294921.0,
        "成交额": 1007528896.0,
        "振幅": 3.12,
        "换手率": 1.61,
        "symbol": "106.SNOW"
    },
    {
        "序号": 1548,
        "name": "Douglas Dynamics Inc",
        "最新价": 29.55,
        "涨跌额": 0.52,
        "涨跌幅": 1.79,
        "开盘价": 29.03,
        "最高价": 29.74,
        "最低价": 29.03,
        "昨收价": 29.03,
        "总市值": 679176166.0,
        "市盈率": 24.12,
        "成交量": 93846.0,
        "成交额": 2767777.0,
        "振幅": 2.45,
        "换手率": 0.41,
        "symbol": "106.PLOW"
    },
    {
        "序号": 1549,
        "name": "NewtekOne Inc",
        "最新价": 14.22,
        "涨跌额": 0.25,
        "涨跌幅": 1.79,
        "开盘价": 13.9,
        "最高价": 14.25,
        "最低价": 13.9,
        "昨收价": 13.97,
        "总市值": 350497134.0,
        "市盈率": 13.32,
        "成交量": 125282.0,
        "成交额": 1766616.0,
        "振幅": 2.51,
        "换手率": 0.51,
        "symbol": "105.NEWT"
    },
    {
        "序号": 1550,
        "name": "PVH Corp",
        "最新价": 108.26,
        "涨跌额": 1.9,
        "涨跌幅": 1.79,
        "开盘价": 106.1,
        "最高价": 108.95,
        "最低价": 105.31,
        "昨收价": 106.36,
        "总市值": 6456356508.0,
        "市盈率": 12.17,
        "成交量": 751819.0,
        "成交额": 81303718.0,
        "振幅": 3.42,
        "换手率": 1.26,
        "symbol": "106.PVH"
    },
    {
        "序号": 1551,
        "name": "Microvast Holdings Inc",
        "最新价": 1.14,
        "涨跌额": 0.02,
        "涨跌幅": 1.79,
        "开盘价": 1.09,
        "最高价": 1.16,
        "最低价": 1.06,
        "昨收价": 1.12,
        "总市值": 360849701.0,
        "市盈率": -3.12,
        "成交量": 2700512.0,
        "成交额": 2995221.0,
        "振幅": 8.93,
        "换手率": 0.85,
        "symbol": "105.MVST"
    },
    {
        "序号": 1552,
        "name": "Aeva Technologies Inc",
        "最新价": 0.57,
        "涨跌额": 0.01,
        "涨跌幅": 1.79,
        "开盘价": 0.55,
        "最高价": 0.58,
        "最低价": 0.55,
        "昨收价": 0.56,
        "总市值": 127363939.0,
        "市盈率": -0.87,
        "成交量": 542917.0,
        "成交额": 307605.0,
        "振幅": 5.36,
        "换手率": 0.24,
        "symbol": "106.AEVA"
    },
    {
        "序号": 1553,
        "name": "雷欧尼尔",
        "最新价": 31.42,
        "涨跌额": 0.55,
        "涨跌幅": 1.78,
        "开盘价": 30.87,
        "最高价": 31.51,
        "最低价": 30.78,
        "昨收价": 30.87,
        "总市值": 4659358488.0,
        "市盈率": 58.51,
        "成交量": 562365.0,
        "成交额": 17579334.0,
        "振幅": 2.36,
        "换手率": 0.38,
        "symbol": "106.RYN"
    },
    {
        "序号": 1554,
        "name": "YieldMax META Option Income Str",
        "最新价": 20.57,
        "涨跌额": 0.36,
        "涨跌幅": 1.78,
        "开盘价": 20.25,
        "最高价": 20.58,
        "最低价": 20.15,
        "昨收价": 20.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 24898.0,
        "成交额": 507798.0,
        "振幅": 2.13,
        "换手率": null,
        "symbol": "107.FBY"
    },
    {
        "序号": 1555,
        "name": "ProShares Bitcoin & Ether Equal",
        "最新价": 60.08,
        "涨跌额": 1.05,
        "涨跌幅": 1.78,
        "开盘价": 59.73,
        "最高价": 60.08,
        "最低价": 59.3,
        "昨收价": 59.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1350.0,
        "成交额": 80490.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.BETE"
    },
    {
        "序号": 1556,
        "name": "美光科技",
        "最新价": 74.96,
        "涨跌额": 1.31,
        "涨跌幅": 1.78,
        "开盘价": 73.48,
        "最高价": 75.35,
        "最低价": 73.43,
        "昨收价": 73.65,
        "总市值": 82735883699.0,
        "市盈率": -14.18,
        "成交量": 12007067.0,
        "成交额": 897620224.0,
        "振幅": 2.61,
        "换手率": 1.09,
        "symbol": "105.MU"
    },
    {
        "序号": 1557,
        "name": "阿卡迪亚医疗保健",
        "最新价": 73.26,
        "涨跌额": 1.28,
        "涨跌幅": 1.78,
        "开盘价": 71.98,
        "最高价": 73.32,
        "最低价": 71.37,
        "昨收价": 71.98,
        "总市值": 6755779032.0,
        "市盈率": -369.73,
        "成交量": 873958.0,
        "成交额": 63287977.0,
        "振幅": 2.71,
        "换手率": 0.95,
        "symbol": "105.ACHC"
    },
    {
        "序号": 1558,
        "name": "NMI Holdings Inc-A",
        "最新价": 28.1,
        "涨跌额": 0.49,
        "涨跌幅": 1.77,
        "开盘价": 27.53,
        "最高价": 28.1,
        "最低价": 27.53,
        "昨收价": 27.61,
        "总市值": 2278367501.0,
        "市盈率": 7.31,
        "成交量": 431913.0,
        "成交额": 12104942.0,
        "振幅": 2.06,
        "换手率": 0.53,
        "symbol": "105.NMIH"
    },
    {
        "序号": 1559,
        "name": "Direxion Daily Robotics Artific",
        "最新价": 18.37,
        "涨跌额": 0.32,
        "涨跌幅": 1.77,
        "开盘价": 18.12,
        "最高价": 18.37,
        "最低价": 17.98,
        "昨收价": 18.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 17877.0,
        "成交额": 326525.0,
        "振幅": 2.16,
        "换手率": null,
        "symbol": "107.UBOT"
    },
    {
        "序号": 1560,
        "name": "伊格尔散货航运",
        "最新价": 44.85,
        "涨跌额": 0.78,
        "涨跌幅": 1.77,
        "开盘价": 43.98,
        "最高价": 44.89,
        "最低价": 43.98,
        "昨收价": 44.07,
        "总市值": 445454416.0,
        "市盈率": 11.32,
        "成交量": 121657.0,
        "成交额": 5420223.0,
        "振幅": 2.06,
        "换手率": 1.22,
        "symbol": "106.EGLE"
    },
    {
        "序号": 1561,
        "name": "McGrath RentCorp",
        "最新价": 103.01,
        "涨跌额": 1.79,
        "涨跌幅": 1.77,
        "开盘价": 101.49,
        "最高价": 103.44,
        "最低价": 101.45,
        "昨收价": 101.22,
        "总市值": 2522657729.0,
        "市盈率": 13.84,
        "成交量": 73630.0,
        "成交额": 7566166.0,
        "振幅": 1.97,
        "换手率": 0.3,
        "symbol": "105.MGRC"
    },
    {
        "序号": 1562,
        "name": "NV5 Global Inc",
        "最新价": 103.04,
        "涨跌额": 1.79,
        "涨跌幅": 1.77,
        "开盘价": 101.57,
        "最高价": 103.06,
        "最低价": 101.57,
        "昨收价": 101.25,
        "总市值": 1637917348.0,
        "市盈率": 38.38,
        "成交量": 52877.0,
        "成交额": 5427761.0,
        "振幅": 1.47,
        "换手率": 0.33,
        "symbol": "105.NVEE"
    },
    {
        "序号": 1563,
        "name": "Uranium Royalty Corp",
        "最新价": 2.88,
        "涨跌额": 0.05,
        "涨跌幅": 1.77,
        "开盘价": 2.85,
        "最高价": 2.91,
        "最低价": 2.84,
        "昨收价": 2.83,
        "总市值": 293990512.0,
        "市盈率": -92.7,
        "成交量": 816704.0,
        "成交额": 2350625.0,
        "振幅": 2.47,
        "换手率": 0.8,
        "symbol": "105.UROY"
    },
    {
        "序号": 1564,
        "name": "凯雷集团",
        "最新价": 36.93,
        "涨跌额": 0.64,
        "涨跌幅": 1.76,
        "开盘价": 36.13,
        "最高价": 37.1,
        "最低价": 35.81,
        "昨收价": 36.29,
        "总市值": 13324183281.0,
        "市盈率": 63.21,
        "成交量": 3602227.0,
        "成交额": 132792246.0,
        "振幅": 3.55,
        "换手率": 1.0,
        "symbol": "105.CG"
    },
    {
        "序号": 1565,
        "name": "嘉信理财",
        "最新价": 64.07,
        "涨跌额": 1.11,
        "涨跌幅": 1.76,
        "开盘价": 63.0,
        "最高价": 64.33,
        "最低价": 62.86,
        "昨收价": 62.96,
        "总市值": 116821613752.0,
        "市盈率": 19.5,
        "成交量": 10159699.0,
        "成交额": 648851728.0,
        "振幅": 2.33,
        "换手率": 0.56,
        "symbol": "106.SCHW"
    },
    {
        "序号": 1566,
        "name": "美国合众银行",
        "最新价": 40.43,
        "涨跌额": 0.7,
        "涨跌幅": 1.76,
        "开盘价": 39.78,
        "最高价": 40.6,
        "最低价": 39.6,
        "昨收价": 39.73,
        "总市值": 62950011575.0,
        "市盈率": 11.43,
        "成交量": 9485036.0,
        "成交额": 382703088.0,
        "振幅": 2.52,
        "换手率": 0.61,
        "symbol": "106.USB"
    },
    {
        "序号": 1567,
        "name": "美国迪进国际",
        "最新价": 24.26,
        "涨跌额": 0.42,
        "涨跌幅": 1.76,
        "开盘价": 23.74,
        "最高价": 24.49,
        "最低价": 23.74,
        "昨收价": 23.84,
        "总市值": 878174737.0,
        "市盈率": 35.45,
        "成交量": 295663.0,
        "成交额": 7131460.0,
        "振幅": 3.15,
        "换手率": 0.82,
        "symbol": "105.DGII"
    },
    {
        "序号": 1568,
        "name": "泰坦国际",
        "最新价": 13.87,
        "涨跌额": 0.24,
        "涨跌幅": 1.76,
        "开盘价": 13.64,
        "最高价": 13.93,
        "最低价": 13.64,
        "昨收价": 13.63,
        "总市值": 858411221.0,
        "市盈率": 6.96,
        "成交量": 269190.0,
        "成交额": 3723943.0,
        "振幅": 2.13,
        "换手率": 0.43,
        "symbol": "106.TWI"
    },
    {
        "序号": 1569,
        "name": "Ambev SA ADR",
        "最新价": 2.89,
        "涨跌额": 0.05,
        "涨跌幅": 1.76,
        "开盘价": 2.86,
        "最高价": 2.92,
        "最低价": 2.86,
        "昨收价": 2.84,
        "总市值": 45497198169.0,
        "市盈率": 15.03,
        "成交量": 7909751.0,
        "成交额": 22817370.0,
        "振幅": 2.11,
        "换手率": 0.05,
        "symbol": "106.ABEV"
    },
    {
        "序号": 1570,
        "name": "富途控股",
        "最新价": 52.06,
        "涨跌额": 0.9,
        "涨跌幅": 1.76,
        "开盘价": 50.78,
        "最高价": 52.55,
        "最低价": 50.28,
        "昨收价": 51.16,
        "总市值": 7269171737.0,
        "市盈率": 12.99,
        "成交量": 1244986.0,
        "成交额": 64678412.0,
        "振幅": 4.44,
        "换手率": 0.89,
        "symbol": "105.FUTU"
    },
    {
        "序号": 1571,
        "name": "英国石油(US)",
        "最新价": 35.3,
        "涨跌额": 0.61,
        "涨跌幅": 1.76,
        "开盘价": 35.08,
        "最高价": 35.4,
        "最低价": 35.02,
        "昨收价": 34.69,
        "总市值": 99649042918.0,
        "市盈率": 3.88,
        "成交量": 8230908.0,
        "成交额": 290096656.0,
        "振幅": 1.1,
        "换手率": 0.29,
        "symbol": "106.BP"
    },
    {
        "序号": 1572,
        "name": "Capital Bancorp Inc",
        "最新价": 22.57,
        "涨跌额": 0.39,
        "涨跌幅": 1.76,
        "开盘价": 22.3,
        "最高价": 22.81,
        "最低价": 22.3,
        "昨收价": 22.18,
        "总市值": 313597037.0,
        "市盈率": 8.75,
        "成交量": 41727.0,
        "成交额": 941300.0,
        "振幅": 2.3,
        "换手率": 0.3,
        "symbol": "105.CBNK"
    },
    {
        "序号": 1573,
        "name": "Verra Mobility Corp-A",
        "最新价": 21.43,
        "涨跌额": 0.37,
        "涨跌幅": 1.76,
        "开盘价": 21.0,
        "最高价": 21.45,
        "最低价": 20.89,
        "昨收价": 21.06,
        "总市值": 3564101134.0,
        "市盈率": 43.35,
        "成交量": 1057854.0,
        "成交额": 22544797.0,
        "振幅": 2.66,
        "换手率": 0.64,
        "symbol": "105.VRRM"
    },
    {
        "序号": 1574,
        "name": "Miller\/Howard High Income Equit",
        "最新价": 10.43,
        "涨跌额": 0.18,
        "涨跌幅": 1.76,
        "开盘价": 10.36,
        "最高价": 10.44,
        "最低价": 10.3,
        "昨收价": 10.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 15371.0,
        "成交额": 158953.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "106.HIE"
    },
    {
        "序号": 1575,
        "name": "Atlanta Braves Holdings Inc-A",
        "最新价": 41.72,
        "涨跌额": 0.72,
        "涨跌幅": 1.76,
        "开盘价": 40.99,
        "最高价": 41.79,
        "最低价": 40.86,
        "昨收价": 41.0,
        "总市值": 2575118146.0,
        "市盈率": null,
        "成交量": 30847.0,
        "成交额": 1276181.0,
        "振幅": 2.27,
        "换手率": 0.27,
        "symbol": "105.BATRA"
    },
    {
        "序号": 1576,
        "name": "福特汽车",
        "最新价": 11.01,
        "涨跌额": 0.19,
        "涨跌幅": 1.76,
        "开盘价": 10.81,
        "最高价": 11.07,
        "最低价": 10.79,
        "昨收价": 10.82,
        "总市值": 44072528351.0,
        "市盈率": 7.15,
        "成交量": 48961188.0,
        "成交额": 538777056.0,
        "振幅": 2.59,
        "换手率": 1.22,
        "symbol": "106.F"
    },
    {
        "序号": 1577,
        "name": "亚朵",
        "最新价": 16.81,
        "涨跌额": 0.29,
        "涨跌幅": 1.76,
        "开盘价": 16.4,
        "最高价": 17.02,
        "最低价": 16.4,
        "昨收价": 16.52,
        "总市值": 2312975951.0,
        "市盈率": 38.11,
        "成交量": 442934.0,
        "成交额": 7416858.0,
        "振幅": 3.75,
        "换手率": 0.32,
        "symbol": "105.ATAT"
    },
    {
        "序号": 1578,
        "name": "Senstar Technologies Ltd",
        "最新价": 1.16,
        "涨跌额": 0.02,
        "涨跌幅": 1.75,
        "开盘价": 1.19,
        "最高价": 1.19,
        "最低价": 1.11,
        "昨收价": 1.14,
        "总市值": 27039585.0,
        "市盈率": 15.64,
        "成交量": 4812.0,
        "成交额": 5474.0,
        "振幅": 7.02,
        "换手率": 0.02,
        "symbol": "105.SNT"
    },
    {
        "序号": 1579,
        "name": "弗莱克斯蒂尔工业",
        "最新价": 19.18,
        "涨跌额": 0.33,
        "涨跌幅": 1.75,
        "开盘价": 18.86,
        "最高价": 19.57,
        "最低价": 18.86,
        "昨收价": 18.85,
        "总市值": 99764962.0,
        "市盈率": 6.55,
        "成交量": 3912.0,
        "成交额": 75723.0,
        "振幅": 3.77,
        "换手率": 0.08,
        "symbol": "105.FLXS"
    },
    {
        "序号": 1580,
        "name": "新聚思",
        "最新价": 100.57,
        "涨跌额": 1.73,
        "涨跌幅": 1.75,
        "开盘价": 98.8,
        "最高价": 100.6,
        "最低价": 98.51,
        "昨收价": 98.84,
        "总市值": 9287731924.0,
        "市盈率": 14.06,
        "成交量": 386103.0,
        "成交额": 38631466.0,
        "振幅": 2.11,
        "换手率": 0.42,
        "symbol": "106.SNX"
    },
    {
        "序号": 1581,
        "name": "白山保险集团",
        "最新价": 1483.12,
        "涨跌额": 25.49,
        "涨跌幅": 1.75,
        "开盘价": 1448.3,
        "最高价": 1489.73,
        "最低价": 1448.3,
        "昨收价": 1457.63,
        "总市值": 3797457570.0,
        "市盈率": 14.43,
        "成交量": 7437.0,
        "成交额": 11009009.0,
        "振幅": 2.84,
        "换手率": 0.29,
        "symbol": "106.WTM"
    },
    {
        "序号": 1582,
        "name": "BNY Mellon Alcentra Global Cred",
        "最新价": 8.15,
        "涨跌额": 0.14,
        "涨跌幅": 1.75,
        "开盘价": 7.98,
        "最高价": 8.22,
        "最低价": 7.98,
        "昨收价": 8.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 80297.0,
        "成交额": 652745.0,
        "振幅": 3.0,
        "换手率": null,
        "symbol": "106.DCF"
    },
    {
        "序号": 1583,
        "name": "Liberty Media Corp Liberty Form",
        "最新价": 62.92,
        "涨跌额": 1.08,
        "涨跌幅": 1.75,
        "开盘价": 61.72,
        "最高价": 63.41,
        "最低价": 61.46,
        "昨收价": 61.84,
        "总市值": 44959587553.0,
        "市盈率": 37.0,
        "成交量": 1149403.0,
        "成交额": 72044351.0,
        "振幅": 3.15,
        "换手率": 0.55,
        "symbol": "105.FWONK"
    },
    {
        "序号": 1584,
        "name": "Bowman Consulting Group Ltd",
        "最新价": 33.22,
        "涨跌额": 0.57,
        "涨跌幅": 1.75,
        "开盘价": 33.0,
        "最高价": 33.6,
        "最低价": 32.81,
        "昨收价": 32.65,
        "总市值": 486626326.0,
        "市盈率": 356.5,
        "成交量": 68440.0,
        "成交额": 2265991.0,
        "振幅": 2.42,
        "换手率": 0.47,
        "symbol": "105.BWMN"
    },
    {
        "序号": 1585,
        "name": "Bitwise Bitcoin and Ether Equal",
        "最新价": 37.89,
        "涨跌额": 0.65,
        "涨跌幅": 1.75,
        "开盘价": 37.53,
        "最高价": 37.89,
        "最低价": 37.47,
        "昨收价": 37.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 3209.0,
        "成交额": 120760.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.BTOP"
    },
    {
        "序号": 1586,
        "name": "第一联邦金融",
        "最新价": 14.59,
        "涨跌额": 0.25,
        "涨跌幅": 1.74,
        "开盘价": 14.32,
        "最高价": 14.6,
        "最低价": 14.32,
        "昨收价": 14.34,
        "总市值": 1489805895.0,
        "市盈率": 10.07,
        "成交量": 423659.0,
        "成交额": 6158677.0,
        "振幅": 1.95,
        "换手率": 0.41,
        "symbol": "106.FCF"
    },
    {
        "序号": 1587,
        "name": "谐波",
        "最新价": 10.51,
        "涨跌额": 0.18,
        "涨跌幅": 1.74,
        "开盘价": 10.32,
        "最高价": 10.65,
        "最低价": 10.05,
        "昨收价": 10.33,
        "总市值": 1179116175.0,
        "市盈率": 187.64,
        "成交量": 1509840.0,
        "成交额": 15877095.0,
        "振幅": 5.81,
        "换手率": 1.35,
        "symbol": "105.HLIT"
    },
    {
        "序号": 1588,
        "name": "Nuveen Select Tax-Free Income P",
        "最新价": 14.61,
        "涨跌额": 0.25,
        "涨跌幅": 1.74,
        "开盘价": 14.4,
        "最高价": 14.62,
        "最低价": 14.36,
        "昨收价": 14.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 105643.0,
        "成交额": 1529784.0,
        "振幅": 1.81,
        "换手率": null,
        "symbol": "106.NXP"
    },
    {
        "序号": 1589,
        "name": "达维塔保健",
        "最新价": 107.58,
        "涨跌额": 1.84,
        "涨跌幅": 1.74,
        "开盘价": 105.7,
        "最高价": 108.21,
        "最低价": 105.21,
        "昨收价": 105.74,
        "总市值": 9827217840.0,
        "市盈率": 16.14,
        "成交量": 1029978.0,
        "成交额": 110481947.0,
        "振幅": 2.84,
        "换手率": 1.13,
        "symbol": "106.DVA"
    },
    {
        "序号": 1590,
        "name": "CNH Industrial NV",
        "最新价": 11.11,
        "涨跌额": 0.19,
        "涨跌幅": 1.74,
        "开盘价": 10.89,
        "最高价": 11.13,
        "最低价": 10.89,
        "昨收价": 10.92,
        "总市值": 18903490417.0,
        "市盈率": 8.05,
        "成交量": 12948909.0,
        "成交额": 143381177.0,
        "振幅": 2.2,
        "换手率": 0.76,
        "symbol": "106.CNHI"
    },
    {
        "序号": 1591,
        "name": "ProShares K-1 Free Crude Oil St",
        "最新价": 42.12,
        "涨跌额": 0.72,
        "涨跌幅": 1.74,
        "开盘价": 42.04,
        "最高价": 42.27,
        "最低价": 41.81,
        "昨收价": 41.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 279518.0,
        "成交额": 11758527.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.OILK"
    },
    {
        "序号": 1592,
        "name": "Coastal Financial Corp",
        "最新价": 42.71,
        "涨跌额": 0.73,
        "涨跌幅": 1.74,
        "开盘价": 40.99,
        "最高价": 42.78,
        "最低价": 40.99,
        "昨收价": 41.98,
        "总市值": 568147597.0,
        "市盈率": 11.67,
        "成交量": 31851.0,
        "成交额": 1352651.0,
        "振幅": 4.26,
        "换手率": 0.24,
        "symbol": "105.CCB"
    },
    {
        "序号": 1593,
        "name": "Sitio Royalties Corp-A",
        "最新价": 21.07,
        "涨跌额": 0.36,
        "涨跌幅": 1.74,
        "开盘价": 20.96,
        "最高价": 21.15,
        "最低价": 20.73,
        "昨收价": 20.71,
        "总市值": 3311759528.0,
        "市盈率": 32.9,
        "成交量": 299282.0,
        "成交额": 6290360.0,
        "振幅": 2.03,
        "换手率": 0.19,
        "symbol": "106.STR"
    },
    {
        "序号": 1594,
        "name": "科韬",
        "最新价": 25.17,
        "涨跌额": 0.43,
        "涨跌幅": 1.74,
        "开盘价": 24.83,
        "最高价": 25.18,
        "最低价": 24.73,
        "昨收价": 24.74,
        "总市值": 1420101015.0,
        "市盈率": 185.83,
        "成交量": 326693.0,
        "成交额": 8163732.0,
        "振幅": 1.82,
        "换手率": 0.58,
        "symbol": "105.CRTO"
    },
    {
        "序号": 1595,
        "name": "Enviri Corp",
        "最新价": 7.03,
        "涨跌额": 0.12,
        "涨跌幅": 1.74,
        "开盘价": 6.89,
        "最高价": 7.06,
        "最低价": 6.84,
        "昨收价": 6.91,
        "总市值": 561230032.0,
        "市盈率": -8.2,
        "成交量": 695439.0,
        "成交额": 4841967.0,
        "振幅": 3.18,
        "换手率": 0.87,
        "symbol": "106.NVRI"
    },
    {
        "序号": 1596,
        "name": "Global X Cannabis ETF",
        "最新价": 5.86,
        "涨跌额": 0.1,
        "涨跌幅": 1.74,
        "开盘价": 5.7,
        "最高价": 5.86,
        "最低价": 5.65,
        "昨收价": 5.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 7969.0,
        "成交额": 45779.0,
        "振幅": 3.65,
        "换手率": null,
        "symbol": "105.POTX"
    },
    {
        "序号": 1597,
        "name": "合宜银行控股",
        "最新价": 3.52,
        "涨跌额": 0.06,
        "涨跌幅": 1.73,
        "开盘价": 3.49,
        "最高价": 3.53,
        "最低价": 3.47,
        "昨收价": 3.46,
        "总市值": 25520767.0,
        "市盈率": 4.83,
        "成交量": 6499.0,
        "成交额": 22706.0,
        "振幅": 1.73,
        "换手率": 0.09,
        "symbol": "105.OPHC"
    },
    {
        "序号": 1598,
        "name": "Braemar Hotels & Resorts Inc Se",
        "最新价": 12.33,
        "涨跌额": 0.21,
        "涨跌幅": 1.73,
        "开盘价": 12.25,
        "最高价": 12.33,
        "最低价": 12.25,
        "昨收价": 12.12,
        "总市值": 37951950.0,
        "市盈率": null,
        "成交量": 3966.0,
        "成交额": 48728.0,
        "振幅": 0.66,
        "换手率": 0.13,
        "symbol": "106.BHR_B"
    },
    {
        "序号": 1599,
        "name": "Dorian LPG Ltd",
        "最新价": 39.36,
        "涨跌额": 0.67,
        "涨跌幅": 1.73,
        "开盘价": 38.76,
        "最高价": 39.65,
        "最低价": 38.62,
        "昨收价": 38.69,
        "总市值": 1598326629.0,
        "市盈率": 6.26,
        "成交量": 1040126.0,
        "成交额": 40779537.0,
        "振幅": 2.66,
        "换手率": 2.56,
        "symbol": "106.LPG"
    },
    {
        "序号": 1600,
        "name": "eXp World Holdings Inc",
        "最新价": 14.69,
        "涨跌额": 0.25,
        "涨跌幅": 1.73,
        "开盘价": 14.39,
        "最高价": 15.28,
        "最低价": 14.33,
        "昨收价": 14.44,
        "总市值": 2259304592.0,
        "市盈率": 449.79,
        "成交量": 1514434.0,
        "成交额": 22428178.0,
        "振幅": 6.58,
        "换手率": 0.98,
        "symbol": "105.EXPI"
    },
    {
        "序号": 1601,
        "name": "VS Trust -1x Short VIX Futures ",
        "最新价": 35.26,
        "涨跌额": 0.6,
        "涨跌幅": 1.73,
        "开盘价": 34.75,
        "最高价": 35.45,
        "最低价": 34.75,
        "昨收价": 34.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 618568.0,
        "成交额": 21773266.0,
        "振幅": 2.02,
        "换手率": null,
        "symbol": "107.SVIX"
    },
    {
        "序号": 1602,
        "name": "The Pennant Group Inc",
        "最新价": 13.54,
        "涨跌额": 0.23,
        "涨跌幅": 1.73,
        "开盘价": 13.27,
        "最高价": 13.56,
        "最低价": 13.25,
        "昨收价": 13.31,
        "总市值": 405204824.0,
        "市盈率": 32.41,
        "成交量": 80843.0,
        "成交额": 1087545.0,
        "振幅": 2.33,
        "换手率": 0.27,
        "symbol": "105.PNTG"
    },
    {
        "序号": 1603,
        "name": "Liberty Media Corp Liberty Form",
        "最新价": 57.17,
        "涨跌额": 0.97,
        "涨跌幅": 1.73,
        "开盘价": 55.8,
        "最高价": 57.52,
        "最低价": 55.8,
        "昨收价": 56.2,
        "总市值": 40850915772.0,
        "市盈率": 33.62,
        "成交量": 61891.0,
        "成交额": 3526759.0,
        "振幅": 3.06,
        "换手率": 0.23,
        "symbol": "105.FWONA"
    },
    {
        "序号": 1604,
        "name": "Delek US Holdings Inc",
        "最新价": 25.94,
        "涨跌额": 0.44,
        "涨跌幅": 1.73,
        "开盘价": 25.82,
        "最高价": 26.38,
        "最低价": 25.82,
        "昨收价": 25.5,
        "总市值": 1658435535.0,
        "市盈率": 25.13,
        "成交量": 1218383.0,
        "成交额": 31750269.0,
        "振幅": 2.2,
        "换手率": 1.91,
        "symbol": "106.DK"
    },
    {
        "序号": 1605,
        "name": "NanoViricides Inc",
        "最新价": 1.18,
        "涨跌额": 0.02,
        "涨跌幅": 1.72,
        "开盘价": 1.14,
        "最高价": 1.2,
        "最低价": 1.14,
        "昨收价": 1.16,
        "总市值": 13653168.0,
        "市盈率": -1.52,
        "成交量": 35071.0,
        "成交额": 41572.0,
        "振幅": 5.17,
        "换手率": 0.3,
        "symbol": "107.NNVC"
    },
    {
        "序号": 1606,
        "name": "布鲁克代尔高级护理",
        "最新价": 5.32,
        "涨跌额": 0.09,
        "涨跌幅": 1.72,
        "开盘价": 5.22,
        "最高价": 5.38,
        "最低价": 5.17,
        "昨收价": 5.23,
        "总市值": 1001426852.0,
        "市盈率": -8.11,
        "成交量": 1384868.0,
        "成交额": 7369607.0,
        "振幅": 4.02,
        "换手率": 0.74,
        "symbol": "106.BKD"
    },
    {
        "序号": 1607,
        "name": "Global Self Storage Inc",
        "最新价": 4.73,
        "涨跌额": 0.08,
        "涨跌幅": 1.72,
        "开盘价": 4.74,
        "最高价": 4.74,
        "最低价": 4.68,
        "昨收价": 4.65,
        "总市值": 52706248.0,
        "市盈率": 23.1,
        "成交量": 24080.0,
        "成交额": 113426.0,
        "振幅": 1.29,
        "换手率": 0.22,
        "symbol": "105.SELF"
    },
    {
        "序号": 1608,
        "name": "是德科技",
        "最新价": 144.33,
        "涨跌额": 2.44,
        "涨跌幅": 1.72,
        "开盘价": 142.18,
        "最高价": 144.92,
        "最低价": 142.05,
        "昨收价": 141.89,
        "总市值": 25200018000.0,
        "市盈率": 23.84,
        "成交量": 1717182.0,
        "成交额": 247505013.0,
        "振幅": 2.02,
        "换手率": 0.98,
        "symbol": "106.KEYS"
    },
    {
        "序号": 1609,
        "name": "1-800-FLOWERS.COM Inc-A",
        "最新价": 9.47,
        "涨跌额": 0.16,
        "涨跌幅": 1.72,
        "开盘价": 9.28,
        "最高价": 9.54,
        "最低价": 9.28,
        "昨收价": 9.31,
        "总市值": 614586124.0,
        "市盈率": -14.55,
        "成交量": 290458.0,
        "成交额": 2738609.0,
        "振幅": 2.79,
        "换手率": 0.45,
        "symbol": "105.FLWS"
    },
    {
        "序号": 1610,
        "name": "Fennec Pharmaceuticals Inc",
        "最新价": 9.47,
        "涨跌额": 0.16,
        "涨跌幅": 1.72,
        "开盘价": 9.15,
        "最高价": 9.58,
        "最低价": 9.15,
        "昨收价": 9.31,
        "总市值": 252938671.0,
        "市盈率": -12.51,
        "成交量": 98433.0,
        "成交额": 927240.0,
        "振幅": 4.62,
        "换手率": 0.37,
        "symbol": "105.FENC"
    },
    {
        "序号": 1611,
        "name": "玩美",
        "最新价": 2.96,
        "涨跌额": 0.05,
        "涨跌幅": 1.72,
        "开盘价": 2.99,
        "最高价": 3.02,
        "最低价": 2.94,
        "昨收价": 2.91,
        "总市值": 349503033.0,
        "市盈率": -2.1,
        "成交量": 11390.0,
        "成交额": 33933.0,
        "振幅": 2.75,
        "换手率": 0.01,
        "symbol": "106.PERF"
    },
    {
        "序号": 1612,
        "name": "Pulse Biosciences Inc",
        "最新价": 9.48,
        "涨跌额": 0.16,
        "涨跌幅": 1.72,
        "开盘价": 9.59,
        "最高价": 9.82,
        "最低价": 9.24,
        "昨收价": 9.32,
        "总市值": 522163396.0,
        "市盈率": -13.23,
        "成交量": 425439.0,
        "成交额": 4061579.0,
        "振幅": 6.22,
        "换手率": 0.77,
        "symbol": "105.PLSE"
    },
    {
        "序号": 1613,
        "name": "赫斯",
        "最新价": 134.5,
        "涨跌额": 2.27,
        "涨跌幅": 1.72,
        "开盘价": 133.32,
        "最高价": 135.25,
        "最低价": 131.61,
        "昨收价": 132.23,
        "总市值": 41311900960.0,
        "市盈率": 28.18,
        "成交量": 5522576.0,
        "成交额": 740640224.0,
        "振幅": 2.75,
        "换手率": 1.8,
        "symbol": "106.HES"
    },
    {
        "序号": 1614,
        "name": "Evercore Inc-A",
        "最新价": 154.77,
        "涨跌额": 2.61,
        "涨跌幅": 1.72,
        "开盘价": 151.73,
        "最高价": 154.88,
        "最低价": 151.29,
        "昨收价": 152.16,
        "总市值": 5830590933.0,
        "市盈率": 18.62,
        "成交量": 269218.0,
        "成交额": 41429349.0,
        "振幅": 2.36,
        "换手率": 0.71,
        "symbol": "106.EVR"
    },
    {
        "序号": 1615,
        "name": "Ermenegildo Zegna NV",
        "最新价": 11.87,
        "涨跌额": 0.2,
        "涨跌幅": 1.71,
        "开盘价": 11.57,
        "最高价": 11.9,
        "最低价": 11.51,
        "昨收价": 11.67,
        "总市值": 2965473945.0,
        "市盈率": 32.83,
        "成交量": 411393.0,
        "成交额": 4845829.0,
        "振幅": 3.34,
        "换手率": 0.16,
        "symbol": "106.ZGN"
    },
    {
        "序号": 1616,
        "name": "CHS Inc Pfd",
        "最新价": 31.46,
        "涨跌额": 0.53,
        "涨跌幅": 1.71,
        "开盘价": 30.85,
        "最高价": 31.46,
        "最低价": 30.59,
        "昨收价": 30.93,
        "总市值": 386077214.0,
        "市盈率": null,
        "成交量": 17202.0,
        "成交额": 532253.0,
        "振幅": 2.81,
        "换手率": 0.14,
        "symbol": "105.CHSCP"
    },
    {
        "序号": 1617,
        "name": "Ashford Inc",
        "最新价": 4.75,
        "涨跌额": 0.08,
        "涨跌幅": 1.71,
        "开盘价": 4.64,
        "最高价": 4.84,
        "最低价": 4.64,
        "昨收价": 4.67,
        "总市值": 15266381.0,
        "市盈率": -8.06,
        "成交量": 1798.0,
        "成交额": 8555.0,
        "振幅": 4.28,
        "换手率": 0.06,
        "symbol": "107.AINC"
    },
    {
        "序号": 1618,
        "name": "Credit Suisse Asset Management ",
        "最新价": 2.97,
        "涨跌额": 0.05,
        "涨跌幅": 1.71,
        "开盘价": 2.92,
        "最高价": 2.99,
        "最低价": 2.92,
        "昨收价": 2.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 127537.0,
        "成交额": 375663.0,
        "振幅": 2.4,
        "换手率": null,
        "symbol": "107.CIK"
    },
    {
        "序号": 1619,
        "name": "美国原油基金(近12月合约平均)",
        "最新价": 35.08,
        "涨跌额": 0.59,
        "涨跌幅": 1.71,
        "开盘价": 34.91,
        "最高价": 35.23,
        "最低价": 34.81,
        "昨收价": 34.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 17119.0,
        "成交额": 600068.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.USL"
    },
    {
        "序号": 1620,
        "name": "LG Display Co Ltd ADR",
        "最新价": 4.76,
        "涨跌额": 0.08,
        "涨跌幅": 1.71,
        "开盘价": 4.73,
        "最高价": 4.78,
        "最低价": 4.72,
        "昨收价": 4.68,
        "总市值": 3406405464.0,
        "市盈率": -0.94,
        "成交量": 248065.0,
        "成交额": 1178811.0,
        "振幅": 1.28,
        "换手率": 0.03,
        "symbol": "106.LPL"
    },
    {
        "序号": 1621,
        "name": "ESS Tech Inc",
        "最新价": 1.19,
        "涨跌额": 0.02,
        "涨跌幅": 1.71,
        "开盘价": 1.17,
        "最高价": 1.22,
        "最低价": 1.14,
        "昨收价": 1.17,
        "总市值": 205879570.0,
        "市盈率": -2.38,
        "成交量": 706880.0,
        "成交额": 827753.0,
        "振幅": 6.84,
        "换手率": 0.41,
        "symbol": "106.GWH"
    },
    {
        "序号": 1622,
        "name": "EUDA Health Holdings Ltd",
        "最新价": 1.19,
        "涨跌额": 0.02,
        "涨跌幅": 1.71,
        "开盘价": 1.21,
        "最高价": 1.24,
        "最低价": 1.19,
        "昨收价": 1.17,
        "总市值": 29306736.0,
        "市盈率": -16.42,
        "成交量": 82365.0,
        "成交额": 100374.0,
        "振幅": 4.27,
        "换手率": 0.33,
        "symbol": "105.EUDA"
    },
    {
        "序号": 1623,
        "name": "Allego NV",
        "最新价": 1.19,
        "涨跌额": 0.02,
        "涨跌幅": 1.71,
        "开盘价": 1.17,
        "最高价": 1.22,
        "最低价": 1.15,
        "昨收价": 1.17,
        "总市值": 322502840.0,
        "市盈率": -2.19,
        "成交量": 48103.0,
        "成交额": 56874.0,
        "振幅": 5.98,
        "换手率": 0.02,
        "symbol": "106.ALLG"
    },
    {
        "序号": 1624,
        "name": "车车-A",
        "最新价": 7.15,
        "涨跌额": 0.12,
        "涨跌幅": 1.71,
        "开盘价": 6.77,
        "最高价": 7.15,
        "最低价": 6.77,
        "昨收价": 7.03,
        "总市值": 544462783.0,
        "市盈率": -665.03,
        "成交量": 9780.0,
        "成交额": 67771.0,
        "振幅": 5.41,
        "换手率": 0.01,
        "symbol": "105.CCG"
    },
    {
        "序号": 1625,
        "name": "DoubleVerify Holdings Inc",
        "最新价": 33.39,
        "涨跌额": 0.56,
        "涨跌幅": 1.71,
        "开盘价": 32.72,
        "最高价": 33.46,
        "最低价": 32.65,
        "昨收价": 32.83,
        "总市值": 5675834276.0,
        "市盈率": 100.58,
        "成交量": 1182732.0,
        "成交额": 39311419.0,
        "振幅": 2.47,
        "换手率": 0.7,
        "symbol": "106.DV"
    },
    {
        "序号": 1626,
        "name": "Ultra Nasdaq Cloud Computing",
        "最新价": 19.68,
        "涨跌额": 0.33,
        "涨跌幅": 1.71,
        "开盘价": 19.55,
        "最高价": 19.68,
        "最低价": 19.52,
        "昨收价": 19.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1095.0,
        "成交额": 21438.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.SKYU"
    },
    {
        "序号": 1627,
        "name": "Taboola.com Ltd",
        "最新价": 3.58,
        "涨跌额": 0.06,
        "涨跌幅": 1.7,
        "开盘价": 3.51,
        "最高价": 3.64,
        "最低价": 3.48,
        "昨收价": 3.52,
        "总市值": 1229508763.0,
        "市盈率": -17.42,
        "成交量": 898861.0,
        "成交额": 3213806.0,
        "振幅": 4.55,
        "换手率": 0.26,
        "symbol": "105.TBLA"
    },
    {
        "序号": 1628,
        "name": "DLH Holdings Corp",
        "最新价": 14.32,
        "涨跌额": 0.24,
        "涨跌幅": 1.7,
        "开盘价": 13.96,
        "最高价": 14.32,
        "最低价": 13.73,
        "昨收价": 14.08,
        "总市值": 201449922.0,
        "市盈率": 137.88,
        "成交量": 6690.0,
        "成交额": 94618.0,
        "振幅": 4.19,
        "换手率": 0.05,
        "symbol": "105.DLHC"
    },
    {
        "序号": 1629,
        "name": "Digital Health Acquisition Corp",
        "最新价": 12.55,
        "涨跌额": 0.21,
        "涨跌幅": 1.7,
        "开盘价": 12.33,
        "最高价": 12.58,
        "最低价": 12.33,
        "昨收价": 12.34,
        "总市值": 44978773.0,
        "市盈率": -16.13,
        "成交量": 1499.0,
        "成交额": 18637.0,
        "振幅": 2.03,
        "换手率": 0.04,
        "symbol": "105.DHAC"
    },
    {
        "序号": 1630,
        "name": "Valaris Ltd",
        "最新价": 65.15,
        "涨跌额": 1.09,
        "涨跌幅": 1.7,
        "开盘价": 64.93,
        "最高价": 65.85,
        "最低价": 64.57,
        "昨收价": 64.06,
        "总市值": 4749119935.0,
        "市盈率": null,
        "成交量": 688276.0,
        "成交额": 44837054.0,
        "振幅": 2.0,
        "换手率": 0.94,
        "symbol": "106.VAL"
    },
    {
        "序号": 1631,
        "name": "Genius Sports Ltd",
        "最新价": 5.38,
        "涨跌额": 0.09,
        "涨跌幅": 1.7,
        "开盘价": 5.31,
        "最高价": 5.42,
        "最低价": 5.24,
        "昨收价": 5.29,
        "总市值": 1123997423.0,
        "市盈率": -6.43,
        "成交量": 1349520.0,
        "成交额": 7214569.0,
        "振幅": 3.4,
        "换手率": 0.65,
        "symbol": "106.GENI"
    },
    {
        "序号": 1632,
        "name": "东北银行",
        "最新价": 53.99,
        "涨跌额": 0.9,
        "涨跌幅": 1.7,
        "开盘价": 53.07,
        "最高价": 54.34,
        "最低价": 53.07,
        "昨收价": 53.09,
        "总市值": 488152421.0,
        "市盈率": null,
        "成交量": 7800.0,
        "成交额": 420211.0,
        "振幅": 2.39,
        "换手率": 0.09,
        "symbol": "105.NBN"
    },
    {
        "序号": 1633,
        "name": "凯撒铝业",
        "最新价": 60.0,
        "涨跌额": 1.0,
        "涨跌幅": 1.69,
        "开盘价": 59.13,
        "最高价": 60.58,
        "最低价": 59.13,
        "昨收价": 59.0,
        "总市值": 960868440.0,
        "市盈率": 72.79,
        "成交量": 90521.0,
        "成交额": 5436089.0,
        "振幅": 2.46,
        "换手率": 0.57,
        "symbol": "105.KALU"
    },
    {
        "序号": 1634,
        "name": "DMK Pharmaceuticals Corp",
        "最新价": 0.6,
        "涨跌额": 0.01,
        "涨跌幅": 1.69,
        "开盘价": 0.6,
        "最高价": 0.63,
        "最低价": 0.57,
        "昨收价": 0.59,
        "总市值": 6061230.0,
        "市盈率": -0.27,
        "成交量": 53387.0,
        "成交额": 32199.0,
        "振幅": 10.17,
        "换手率": 0.53,
        "symbol": "105.DMK"
    },
    {
        "序号": 1635,
        "name": "Apollo Tactical Income Fund",
        "最新价": 13.2,
        "涨跌额": 0.22,
        "涨跌幅": 1.69,
        "开盘价": 13.05,
        "最高价": 13.24,
        "最低价": 13.01,
        "昨收价": 12.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 187818.0,
        "成交额": 2465964.0,
        "振幅": 1.77,
        "换手率": null,
        "symbol": "106.AIF"
    },
    {
        "序号": 1636,
        "name": "Taylor Morrison Home Corp",
        "最新价": 48.07,
        "涨跌额": 0.8,
        "涨跌幅": 1.69,
        "开盘价": 47.52,
        "最高价": 48.35,
        "最低价": 47.42,
        "昨收价": 47.27,
        "总市值": 5165051174.0,
        "市盈率": 5.93,
        "成交量": 982502.0,
        "成交额": 47195979.0,
        "振幅": 1.97,
        "换手率": 0.91,
        "symbol": "106.TMHC"
    },
    {
        "序号": 1637,
        "name": "威廉姆斯",
        "最新价": 35.52,
        "涨跌额": 0.59,
        "涨跌幅": 1.69,
        "开盘价": 35.11,
        "最高价": 35.65,
        "最低价": 35.06,
        "昨收价": 34.93,
        "总市值": 43210032829.0,
        "市盈率": 15.94,
        "成交量": 5501603.0,
        "成交额": 195031102.0,
        "振幅": 1.69,
        "换手率": 0.45,
        "symbol": "106.WMB"
    },
    {
        "序号": 1638,
        "name": "The Lovesac Co",
        "最新价": 25.3,
        "涨跌额": 0.42,
        "涨跌幅": 1.69,
        "开盘价": 24.8,
        "最高价": 25.34,
        "最低价": 24.38,
        "昨收价": 24.88,
        "总市值": 391871675.0,
        "市盈率": 18.77,
        "成交量": 326840.0,
        "成交额": 8186326.0,
        "振幅": 3.86,
        "换手率": 2.11,
        "symbol": "105.LOVE"
    },
    {
        "序号": 1639,
        "name": "培生",
        "最新价": 12.08,
        "涨跌额": 0.2,
        "涨跌幅": 1.68,
        "开盘价": 11.95,
        "最高价": 12.09,
        "最低价": 11.95,
        "昨收价": 11.88,
        "总市值": 8481960681.0,
        "市盈率": 24.26,
        "成交量": 269625.0,
        "成交额": 3249290.0,
        "振幅": 1.18,
        "换手率": 0.04,
        "symbol": "106.PSO"
    },
    {
        "序号": 1640,
        "name": "Resideo Technologies Inc",
        "最新价": 17.52,
        "涨跌额": 0.29,
        "涨跌幅": 1.68,
        "开盘价": 17.24,
        "最高价": 17.59,
        "最低价": 17.13,
        "昨收价": 17.23,
        "总市值": 2559461620.0,
        "市盈率": 15.33,
        "成交量": 914220.0,
        "成交额": 15961369.0,
        "振幅": 2.67,
        "换手率": 0.63,
        "symbol": "106.REZI"
    },
    {
        "序号": 1641,
        "name": "格里丰",
        "最新价": 51.41,
        "涨跌额": 0.85,
        "涨跌幅": 1.68,
        "开盘价": 50.81,
        "最高价": 51.63,
        "最低价": 50.51,
        "昨收价": 50.56,
        "总市值": 2681789026.0,
        "市盈率": 34.55,
        "成交量": 379203.0,
        "成交额": 19423049.0,
        "振幅": 2.22,
        "换手率": 0.73,
        "symbol": "106.GFF"
    },
    {
        "序号": 1642,
        "name": "威玛克",
        "最新价": 446.68,
        "涨跌额": 7.38,
        "涨跌幅": 1.68,
        "开盘价": 443.3,
        "最高价": 448.0,
        "最低价": 438.08,
        "昨收价": 439.3,
        "总市值": 1557793820.0,
        "市盈率": 38.33,
        "成交量": 27611.0,
        "成交额": 12215622.0,
        "振幅": 2.26,
        "换手率": 0.79,
        "symbol": "105.WINA"
    },
    {
        "序号": 1643,
        "name": "Dynagas LNG Partners LP Series ",
        "最新价": 24.25,
        "涨跌额": 0.4,
        "涨跌幅": 1.68,
        "开盘价": 24.1,
        "最高价": 24.25,
        "最低价": 23.86,
        "昨收价": 23.85,
        "总市值": 72750000.0,
        "市盈率": null,
        "成交量": 10440.0,
        "成交额": 251311.0,
        "振幅": 1.64,
        "换手率": 0.35,
        "symbol": "106.DLNG_A"
    },
    {
        "序号": 1644,
        "name": "GE HealthCare Technologies Inc",
        "最新价": 68.52,
        "涨跌额": 1.13,
        "涨跌幅": 1.68,
        "开盘价": 67.18,
        "最高价": 68.63,
        "最低价": 67.05,
        "昨收价": 67.39,
        "总市值": 31193248578.0,
        "市盈率": 18.15,
        "成交量": 1893851.0,
        "成交额": 129075632.0,
        "振幅": 2.34,
        "换手率": 0.42,
        "symbol": "105.GEHC"
    },
    {
        "序号": 1645,
        "name": "Simpson Manufacturing Co Inc",
        "最新价": 182.18,
        "涨跌额": 3.0,
        "涨跌幅": 1.67,
        "开盘价": 178.95,
        "最高价": 182.3,
        "最低价": 178.95,
        "昨收价": 179.18,
        "总市值": 7774152748.0,
        "市盈率": 21.79,
        "成交量": 183558.0,
        "成交额": 33256396.0,
        "振幅": 1.87,
        "换手率": 0.43,
        "symbol": "106.SSD"
    },
    {
        "序号": 1646,
        "name": "欧文斯科宁",
        "最新价": 143.36,
        "涨跌额": 2.36,
        "涨跌幅": 1.67,
        "开盘价": 142.15,
        "最高价": 143.57,
        "最低价": 141.79,
        "昨收价": 141.0,
        "总市值": 12741113692.0,
        "市盈率": 10.72,
        "成交量": 992215.0,
        "成交额": 141727548.0,
        "振幅": 1.26,
        "换手率": 1.12,
        "symbol": "106.OC"
    },
    {
        "序号": 1647,
        "name": "Noble Corp plc-A",
        "最新价": 42.54,
        "涨跌额": 0.7,
        "涨跌幅": 1.67,
        "开盘价": 42.15,
        "最高价": 42.69,
        "最低价": 42.0,
        "昨收价": 41.84,
        "总市值": 6000440308.0,
        "市盈率": 12.84,
        "成交量": 1286970.0,
        "成交额": 54564350.0,
        "振幅": 1.65,
        "换手率": 0.91,
        "symbol": "106.NE"
    },
    {
        "序号": 1648,
        "name": "Vista Energy SAB de CV ADR",
        "最新价": 30.39,
        "涨跌额": 0.5,
        "涨跌幅": 1.67,
        "开盘价": 30.08,
        "最高价": 30.46,
        "最低价": 29.9,
        "昨收价": 29.89,
        "总市值": 2892046025.0,
        "市盈率": 7.56,
        "成交量": 727529.0,
        "成交额": 21973090.0,
        "振幅": 1.87,
        "换手率": 0.76,
        "symbol": "106.VIST"
    },
    {
        "序号": 1649,
        "name": "信达金融",
        "最新价": 91.28,
        "涨跌额": 1.5,
        "涨跌幅": 1.67,
        "开盘价": 90.16,
        "最高价": 91.28,
        "最低价": 89.68,
        "昨收价": 89.78,
        "总市值": 5589663521.0,
        "市盈率": 8.68,
        "成交量": 382720.0,
        "成交额": 34759258.0,
        "振幅": 1.78,
        "换手率": 0.62,
        "symbol": "105.WTFC"
    },
    {
        "序号": 1650,
        "name": "马塞里奇房产",
        "最新价": 13.39,
        "涨跌额": 0.22,
        "涨跌幅": 1.67,
        "开盘价": 13.1,
        "最高价": 13.44,
        "最低价": 13.02,
        "昨收价": 13.17,
        "总市值": 2884851090.0,
        "市盈率": -8.62,
        "成交量": 1965825.0,
        "成交额": 26178927.0,
        "振幅": 3.19,
        "换手率": 0.91,
        "symbol": "106.MAC"
    },
    {
        "序号": 1651,
        "name": "-1x Short VIX Mid-Term Futures ",
        "最新价": 22.53,
        "涨跌额": 0.37,
        "涨跌幅": 1.67,
        "开盘价": 22.53,
        "最高价": 22.53,
        "最低价": 22.53,
        "昨收价": 22.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 393.0,
        "成交额": 8854.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ZIVB"
    },
    {
        "序号": 1652,
        "name": "Five9 Inc",
        "最新价": 80.39,
        "涨跌额": 1.32,
        "涨跌幅": 1.67,
        "开盘价": 78.2,
        "最高价": 81.43,
        "最低价": 78.2,
        "昨收价": 79.07,
        "总市值": 5844026215.0,
        "市盈率": -70.36,
        "成交量": 914528.0,
        "成交额": 73641070.0,
        "振幅": 4.08,
        "换手率": 1.26,
        "symbol": "105.FIVN"
    },
    {
        "序号": 1653,
        "name": "中央谷地",
        "最新价": 19.49,
        "涨跌额": 0.32,
        "涨跌幅": 1.67,
        "开盘价": 19.36,
        "最高价": 19.5,
        "最低价": 19.29,
        "昨收价": 19.17,
        "总市值": 230272070.0,
        "市盈率": 8.44,
        "成交量": 9424.0,
        "成交额": 183523.0,
        "振幅": 1.1,
        "换手率": 0.08,
        "symbol": "105.CVCY"
    },
    {
        "序号": 1654,
        "name": "ClearPoint Neuro Inc",
        "最新价": 6.7,
        "涨跌额": 0.11,
        "涨跌幅": 1.67,
        "开盘价": 6.5,
        "最高价": 6.75,
        "最低价": 6.46,
        "昨收价": 6.59,
        "总市值": 164957799.0,
        "市盈率": -7.55,
        "成交量": 44703.0,
        "成交额": 298437.0,
        "振幅": 4.4,
        "换手率": 0.18,
        "symbol": "105.CLPT"
    },
    {
        "序号": 1655,
        "name": "CrossAmerica Partners LP",
        "最新价": 23.15,
        "涨跌额": 0.38,
        "涨跌幅": 1.67,
        "开盘价": 22.99,
        "最高价": 23.39,
        "最低价": 22.98,
        "昨收价": 22.77,
        "总市值": 879022168.0,
        "市盈率": 20.47,
        "成交量": 67638.0,
        "成交额": 1566452.0,
        "振幅": 1.8,
        "换手率": 0.18,
        "symbol": "106.CAPL"
    },
    {
        "序号": 1656,
        "name": "Neuronetics Inc",
        "最新价": 1.83,
        "涨跌额": 0.03,
        "涨跌幅": 1.67,
        "开盘价": 1.78,
        "最高价": 1.88,
        "最低价": 1.78,
        "昨收价": 1.8,
        "总市值": 52947632.0,
        "市盈率": -1.6,
        "成交量": 72435.0,
        "成交额": 134004.0,
        "振幅": 5.56,
        "换手率": 0.25,
        "symbol": "105.STIM"
    },
    {
        "序号": 1657,
        "name": "礼来",
        "最新价": 598.05,
        "涨跌额": 9.78,
        "涨跌幅": 1.66,
        "开盘价": 589.0,
        "最高价": 598.3,
        "最低价": 584.77,
        "昨收价": 588.27,
        "总市值": 567733193088.0,
        "市盈率": 113.81,
        "成交量": 2446790.0,
        "成交额": 1454098736.0,
        "振幅": 2.3,
        "换手率": 0.26,
        "symbol": "106.LLY"
    },
    {
        "序号": 1658,
        "name": "Bowlero Corp-A",
        "最新价": 11.02,
        "涨跌额": 0.18,
        "涨跌幅": 1.66,
        "开盘价": 10.9,
        "最高价": 11.12,
        "最低价": 10.86,
        "昨收价": 10.84,
        "总市值": 1667191358.0,
        "市盈率": 12.46,
        "成交量": 721676.0,
        "成交额": 7947335.0,
        "振幅": 2.4,
        "换手率": 0.48,
        "symbol": "106.BOWL"
    },
    {
        "序号": 1659,
        "name": "现在服务公司",
        "最新价": 699.08,
        "涨跌额": 11.4,
        "涨跌幅": 1.66,
        "开盘价": 689.45,
        "最高价": 701.11,
        "最低价": 688.56,
        "昨收价": 687.68,
        "总市值": 143000309400.0,
        "市盈率": 90.16,
        "成交量": 1083831.0,
        "成交额": 755789984.0,
        "振幅": 1.82,
        "换手率": 0.53,
        "symbol": "106.NOW"
    },
    {
        "序号": 1660,
        "name": "Definitive Healthcare Corp-A",
        "最新价": 8.6,
        "涨跌额": 0.14,
        "涨跌幅": 1.65,
        "开盘价": 8.44,
        "最高价": 8.71,
        "最低价": 8.36,
        "昨收价": 8.46,
        "总市值": 1336938422.0,
        "市盈率": -7.38,
        "成交量": 365876.0,
        "成交额": 3146433.0,
        "振幅": 4.14,
        "换手率": 0.24,
        "symbol": "105.DH"
    },
    {
        "序号": 1661,
        "name": "哈特兰金融",
        "最新价": 35.02,
        "涨跌额": 0.57,
        "涨跌幅": 1.65,
        "开盘价": 34.39,
        "最高价": 35.23,
        "最低价": 33.83,
        "昨收价": 34.45,
        "总市值": 1494009372.0,
        "市盈率": 7.08,
        "成交量": 188814.0,
        "成交额": 6603424.0,
        "振幅": 4.06,
        "换手率": 0.44,
        "symbol": "105.HTLF"
    },
    {
        "序号": 1662,
        "name": "World Kinect Corp",
        "最新价": 20.89,
        "涨跌额": 0.34,
        "涨跌幅": 1.65,
        "开盘价": 20.66,
        "最高价": 20.89,
        "最低价": 20.6,
        "昨收价": 20.55,
        "总市值": 1257616333.0,
        "市盈率": 11.58,
        "成交量": 1310933.0,
        "成交额": 27255795.0,
        "振幅": 1.41,
        "换手率": 2.18,
        "symbol": "106.WKC"
    },
    {
        "序号": 1663,
        "name": "森科能源",
        "最新价": 30.74,
        "涨跌额": 0.5,
        "涨跌幅": 1.65,
        "开盘价": 30.37,
        "最高价": 30.8,
        "最低价": 30.31,
        "昨收价": 30.24,
        "总市值": 39808787106.0,
        "市盈率": 4.85,
        "成交量": 5660587.0,
        "成交额": 173643790.0,
        "振幅": 1.62,
        "换手率": 0.44,
        "symbol": "106.SU"
    },
    {
        "序号": 1664,
        "name": "Aytu BioPharma Inc",
        "最新价": 2.46,
        "涨跌额": 0.04,
        "涨跌幅": 1.65,
        "开盘价": 2.44,
        "最高价": 2.51,
        "最低价": 2.36,
        "昨收价": 2.42,
        "总市值": 13696025.0,
        "市盈率": -0.56,
        "成交量": 21325.0,
        "成交额": 51966.0,
        "振幅": 6.2,
        "换手率": 0.38,
        "symbol": "105.AYTU"
    },
    {
        "序号": 1665,
        "name": "Eagle Point Income Co Inc",
        "最新价": 14.79,
        "涨跌额": 0.24,
        "涨跌幅": 1.65,
        "开盘价": 14.62,
        "最高价": 14.84,
        "最低价": 14.6,
        "昨收价": 14.55,
        "总市值": 131750917.0,
        "市盈率": null,
        "成交量": 64235.0,
        "成交额": 948152.0,
        "振幅": 1.65,
        "换手率": 0.72,
        "symbol": "106.EIC"
    },
    {
        "序号": 1666,
        "name": "Northfield Bancorp Inc",
        "最新价": 11.13,
        "涨跌额": 0.18,
        "涨跌幅": 1.64,
        "开盘价": 10.93,
        "最高价": 11.17,
        "最低价": 10.9,
        "昨收价": 10.95,
        "总市值": 500361593.0,
        "市盈率": 11.48,
        "成交量": 150503.0,
        "成交额": 1668935.0,
        "振幅": 2.47,
        "换手率": 0.33,
        "symbol": "105.NFBK"
    },
    {
        "序号": 1667,
        "name": "Roundhill Alerian LNG ETF",
        "最新价": 24.17,
        "涨跌额": 0.39,
        "涨跌幅": 1.64,
        "开盘价": 24.05,
        "最高价": 24.18,
        "最低价": 24.05,
        "昨收价": 23.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1043.0,
        "成交额": 25163.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.LNGG"
    },
    {
        "序号": 1668,
        "name": "Ryman Hospitality Properties In",
        "最新价": 104.75,
        "涨跌额": 1.69,
        "涨跌幅": 1.64,
        "开盘价": 103.38,
        "最高价": 104.8,
        "最低价": 102.82,
        "昨收价": 103.06,
        "总市值": 6254465270.0,
        "市盈率": 27.53,
        "成交量": 389452.0,
        "成交额": 40628098.0,
        "振幅": 1.92,
        "换手率": 0.65,
        "symbol": "106.RHP"
    },
    {
        "序号": 1669,
        "name": "Wheels Up Experience Inc-A",
        "最新价": 1.86,
        "涨跌额": 0.03,
        "涨跌幅": 1.64,
        "开盘价": 1.92,
        "最高价": 1.92,
        "最低价": 1.8,
        "昨收价": 1.83,
        "总市值": 310271259.0,
        "市盈率": -0.49,
        "成交量": 292370.0,
        "成交额": 544717.0,
        "振幅": 6.56,
        "换手率": 0.18,
        "symbol": "106.UP"
    },
    {
        "序号": 1670,
        "name": "趣店",
        "最新价": 1.86,
        "涨跌额": 0.03,
        "涨跌幅": 1.64,
        "开盘价": 1.86,
        "最高价": 1.9,
        "最低价": 1.82,
        "昨收价": 1.83,
        "总市值": 418312002.0,
        "市盈率": 16.64,
        "成交量": 827949.0,
        "成交额": 1536688.0,
        "振幅": 4.37,
        "换手率": 0.37,
        "symbol": "106.QD"
    },
    {
        "序号": 1671,
        "name": "Palisade Bio Inc",
        "最新价": 0.62,
        "涨跌额": 0.01,
        "涨跌幅": 1.64,
        "开盘价": 0.61,
        "最高价": 0.63,
        "最低价": 0.6,
        "昨收价": 0.61,
        "总市值": 5714620.0,
        "市盈率": -0.44,
        "成交量": 121893.0,
        "成交额": 75577.0,
        "振幅": 4.92,
        "换手率": 1.32,
        "symbol": "105.PALI"
    },
    {
        "序号": 1672,
        "name": "ICC Holdings Inc",
        "最新价": 15.5,
        "涨跌额": 0.25,
        "涨跌幅": 1.64,
        "开盘价": 15.5,
        "最高价": 15.5,
        "最低价": 15.5,
        "昨收价": 15.25,
        "总市值": 48663769.0,
        "市盈率": 10.88,
        "成交量": 3450.0,
        "成交额": 53475.0,
        "振幅": 0.0,
        "换手率": 0.11,
        "symbol": "105.ICCH"
    },
    {
        "序号": 1673,
        "name": "Park National Corp",
        "最新价": 124.7,
        "涨跌额": 2.01,
        "涨跌幅": 1.64,
        "开盘价": 122.0,
        "最高价": 124.7,
        "最低价": 121.87,
        "昨收价": 122.69,
        "总市值": 2009724931.0,
        "市盈率": 14.85,
        "成交量": 48000.0,
        "成交额": 5949105.0,
        "振幅": 2.31,
        "换手率": 0.3,
        "symbol": "107.PRK"
    },
    {
        "序号": 1674,
        "name": "派拓网络",
        "最新价": 298.42,
        "涨跌额": 4.81,
        "涨跌幅": 1.64,
        "开盘价": 290.64,
        "最高价": 298.91,
        "最低价": 290.64,
        "昨收价": 293.61,
        "总市值": 94091826000.0,
        "市盈率": 153.27,
        "成交量": 2406633.0,
        "成交额": 714970848.0,
        "振幅": 2.82,
        "换手率": 0.76,
        "symbol": "105.PANW"
    },
    {
        "序号": 1675,
        "name": "防特网",
        "最新价": 52.16,
        "涨跌额": 0.84,
        "涨跌幅": 1.64,
        "开盘价": 51.14,
        "最高价": 52.36,
        "最低价": 50.97,
        "昨收价": 51.32,
        "总市值": 40054164892.0,
        "市盈率": 34.81,
        "成交量": 4620508.0,
        "成交额": 240093885.0,
        "振幅": 2.71,
        "换手率": 0.6,
        "symbol": "105.FTNT"
    },
    {
        "序号": 1676,
        "name": "Clearwater Analytics Holdings I",
        "最新价": 19.25,
        "涨跌额": 0.31,
        "涨跌幅": 1.64,
        "开盘价": 18.92,
        "最高价": 19.36,
        "最低价": 18.81,
        "昨收价": 18.94,
        "总市值": 4676794757.0,
        "市盈率": -232.03,
        "成交量": 856398.0,
        "成交额": 16467373.0,
        "振幅": 2.9,
        "换手率": 0.35,
        "symbol": "106.CWAN"
    },
    {
        "序号": 1677,
        "name": "Westwood Holdings Group Inc",
        "最新价": 11.18,
        "涨跌额": 0.18,
        "涨跌幅": 1.64,
        "开盘价": 11.08,
        "最高价": 11.23,
        "最低价": 10.93,
        "昨收价": 11.0,
        "总市值": 102248512.0,
        "市盈率": 21.03,
        "成交量": 100739.0,
        "成交额": 1112348.0,
        "振幅": 2.73,
        "换手率": 1.1,
        "symbol": "106.WHG"
    },
    {
        "序号": 1678,
        "name": "美森",
        "最新价": 98.96,
        "涨跌额": 1.59,
        "涨跌幅": 1.63,
        "开盘价": 97.44,
        "最高价": 99.73,
        "最低价": 97.44,
        "昨收价": 97.37,
        "总市值": 3457155725.0,
        "市盈率": 11.06,
        "成交量": 249411.0,
        "成交额": 24693164.0,
        "振幅": 2.35,
        "换手率": 0.71,
        "symbol": "106.MATX"
    },
    {
        "序号": 1679,
        "name": "GD Culture Group Ltd",
        "最新价": 2.49,
        "涨跌额": 0.04,
        "涨跌幅": 1.63,
        "开盘价": 2.45,
        "最高价": 2.6,
        "最低价": 2.42,
        "昨收价": 2.45,
        "总市值": 11179642.0,
        "市盈率": -1.02,
        "成交量": 65576.0,
        "成交额": 162295.0,
        "振幅": 7.35,
        "换手率": 1.46,
        "symbol": "105.GDC"
    },
    {
        "序号": 1680,
        "name": "Blue Foundry Bancorp",
        "最新价": 9.34,
        "涨跌额": 0.15,
        "涨跌幅": 1.63,
        "开盘价": 9.23,
        "最高价": 9.43,
        "最低价": 9.23,
        "昨收价": 9.19,
        "总市值": 232797651.0,
        "市盈率": -59.63,
        "成交量": 35917.0,
        "成交额": 335732.0,
        "振幅": 2.18,
        "换手率": 0.14,
        "symbol": "105.BLFY"
    },
    {
        "序号": 1681,
        "name": "WK Kellogg Co",
        "最新价": 11.84,
        "涨跌额": 0.19,
        "涨跌幅": 1.63,
        "开盘价": 11.68,
        "最高价": 12.13,
        "最低价": 11.6,
        "昨收价": 11.65,
        "总市值": 1013874639.0,
        "市盈率": null,
        "成交量": 743355.0,
        "成交额": 8839884.0,
        "振幅": 4.55,
        "换手率": 0.87,
        "symbol": "106.KLG"
    },
    {
        "序号": 1682,
        "name": "油气设服ETF-SPDR S&P",
        "最新价": 79.92,
        "涨跌额": 1.28,
        "涨跌幅": 1.63,
        "开盘价": 79.25,
        "最高价": 80.7,
        "最低价": 79.25,
        "昨收价": 78.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 66364.0,
        "成交额": 5294679.0,
        "振幅": 1.84,
        "换手率": null,
        "symbol": "107.XES"
    },
    {
        "序号": 1683,
        "name": "Emeren Group Ltd ADR",
        "最新价": 2.5,
        "涨跌额": 0.04,
        "涨跌幅": 1.63,
        "开盘价": 2.44,
        "最高价": 2.55,
        "最低价": 2.44,
        "昨收价": 2.46,
        "总市值": 143203766.0,
        "市盈率": -48.58,
        "成交量": 410563.0,
        "成交额": 1029437.0,
        "振幅": 4.47,
        "换手率": 0.72,
        "symbol": "106.SOL"
    },
    {
        "序号": 1684,
        "name": "Hall of Fame Resort & Entertain",
        "最新价": 3.75,
        "涨跌额": 0.06,
        "涨跌幅": 1.63,
        "开盘价": 3.8,
        "最高价": 3.8,
        "最低价": 3.62,
        "昨收价": 3.69,
        "总市值": 24131989.0,
        "市盈率": -0.36,
        "成交量": 9998.0,
        "成交额": 37080.0,
        "振幅": 4.88,
        "换手率": 0.16,
        "symbol": "105.HOFV"
    },
    {
        "序号": 1685,
        "name": "福陆",
        "最新价": 38.17,
        "涨跌额": 0.61,
        "涨跌幅": 1.62,
        "开盘价": 37.53,
        "最高价": 38.43,
        "最低价": 37.32,
        "昨收价": 37.56,
        "总市值": 6503217185.0,
        "市盈率": 38.25,
        "成交量": 1666583.0,
        "成交额": 63558466.0,
        "振幅": 2.96,
        "换手率": 0.98,
        "symbol": "106.FLR"
    },
    {
        "序号": 1686,
        "name": "T2 Biosystems Inc",
        "最新价": 3.76,
        "涨跌额": 0.06,
        "涨跌幅": 1.62,
        "开盘价": 3.68,
        "最高价": 3.77,
        "最低价": 3.57,
        "昨收价": 3.7,
        "总市值": 15229105.0,
        "市盈率": -0.31,
        "成交量": 119139.0,
        "成交额": 438552.0,
        "振幅": 5.41,
        "换手率": 2.94,
        "symbol": "105.TTOO"
    },
    {
        "序号": 1687,
        "name": "TotalEnergies SE ADR",
        "最新价": 66.57,
        "涨跌额": 1.06,
        "涨跌幅": 1.62,
        "开盘价": 65.94,
        "最高价": 66.68,
        "最低价": 65.89,
        "昨收价": 65.51,
        "总市值": 159494890983.0,
        "市盈率": 8.14,
        "成交量": 893672.0,
        "成交额": 59382830.0,
        "振幅": 1.21,
        "换手率": 0.04,
        "symbol": "106.TTE"
    },
    {
        "序号": 1688,
        "name": "Oric Pharmaceuticals Inc",
        "最新价": 9.43,
        "涨跌额": 0.15,
        "涨跌幅": 1.62,
        "开盘价": 9.24,
        "最高价": 9.6,
        "最低价": 9.03,
        "昨收价": 9.28,
        "总市值": 514418665.0,
        "市盈率": -5.52,
        "成交量": 295298.0,
        "成交额": 2783031.0,
        "振幅": 6.14,
        "换手率": 0.54,
        "symbol": "105.ORIC"
    },
    {
        "序号": 1689,
        "name": "Westrock Coffee Co",
        "最新价": 9.44,
        "涨跌额": 0.15,
        "涨跌幅": 1.61,
        "开盘价": 9.36,
        "最高价": 9.51,
        "最低价": 9.26,
        "昨收价": 9.29,
        "总市值": 831089897.0,
        "市盈率": -18.02,
        "成交量": 171222.0,
        "成交额": 1612224.0,
        "振幅": 2.69,
        "换手率": 0.19,
        "symbol": "105.WEST"
    },
    {
        "序号": 1690,
        "name": "Farmers National Banc Corp",
        "最新价": 13.22,
        "涨跌额": 0.21,
        "涨跌幅": 1.61,
        "开盘价": 12.9,
        "最高价": 13.23,
        "最低价": 12.9,
        "昨收价": 13.01,
        "总市值": 495610873.0,
        "市盈率": 10.17,
        "成交量": 46565.0,
        "成交额": 612671.0,
        "振幅": 2.54,
        "换手率": 0.12,
        "symbol": "105.FMNB"
    },
    {
        "序号": 1691,
        "name": "北欧皇家石油信托",
        "最新价": 6.93,
        "涨跌额": 0.11,
        "涨跌幅": 1.61,
        "开盘价": 6.78,
        "最高价": 7.24,
        "最低价": 6.75,
        "昨收价": 6.82,
        "总市值": 63690789.0,
        "市盈率": 2.26,
        "成交量": 61862.0,
        "成交额": 434523.0,
        "振幅": 7.18,
        "换手率": 0.67,
        "symbol": "106.NRT"
    },
    {
        "序号": 1692,
        "name": "Hyliion Holdings Corp",
        "最新价": 0.63,
        "涨跌额": 0.01,
        "涨跌幅": 1.61,
        "开盘价": 0.63,
        "最高价": 0.64,
        "最低价": 0.6,
        "昨收价": 0.62,
        "总市值": 115157356.0,
        "市盈率": -0.93,
        "成交量": 710036.0,
        "成交额": 440828.0,
        "振幅": 6.45,
        "换手率": 0.39,
        "symbol": "106.HYLN"
    },
    {
        "序号": 1693,
        "name": "AMC院线",
        "最新价": 6.93,
        "涨跌额": 0.11,
        "涨跌幅": 1.61,
        "开盘价": 6.82,
        "最高价": 7.04,
        "最低价": 6.79,
        "昨收价": 6.82,
        "总市值": 1374613303.0,
        "市盈率": -2.74,
        "成交量": 11951452.0,
        "成交额": 82956164.0,
        "振幅": 3.67,
        "换手率": 6.03,
        "symbol": "106.AMC"
    },
    {
        "序号": 1694,
        "name": "ProShares Ultra Technology",
        "最新价": 49.83,
        "涨跌额": 0.79,
        "涨跌幅": 1.61,
        "开盘价": 48.82,
        "最高价": 49.93,
        "最低价": 48.8,
        "昨收价": 49.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 49580.0,
        "成交额": 2458995.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.ROM"
    },
    {
        "序号": 1695,
        "name": "WEX Inc",
        "最新价": 180.43,
        "涨跌额": 2.86,
        "涨跌幅": 1.61,
        "开盘价": 177.53,
        "最高价": 180.85,
        "最低价": 176.14,
        "昨收价": 177.57,
        "总市值": 7711098076.0,
        "市盈率": 28.51,
        "成交量": 484961.0,
        "成交额": 87177432.0,
        "振幅": 2.65,
        "换手率": 1.13,
        "symbol": "106.WEX"
    },
    {
        "序号": 1696,
        "name": "Compass Group Diversified Holdi",
        "最新价": 24.64,
        "涨跌额": 0.39,
        "涨跌幅": 1.61,
        "开盘价": 24.27,
        "最高价": 24.64,
        "最低价": 24.27,
        "昨收价": 24.25,
        "总市值": 98560000.0,
        "市盈率": null,
        "成交量": 1603.0,
        "成交额": 38957.0,
        "振幅": 1.53,
        "换手率": 0.04,
        "symbol": "106.CODI_B"
    },
    {
        "序号": 1697,
        "name": "Baker Hughes Co-A",
        "最新价": 32.24,
        "涨跌额": 0.51,
        "涨跌幅": 1.61,
        "开盘价": 31.86,
        "最高价": 32.28,
        "最低价": 31.75,
        "昨收价": 31.73,
        "总市值": 32440980710.0,
        "市盈率": 19.25,
        "成交量": 8444330.0,
        "成交额": 271266832.0,
        "振幅": 1.67,
        "换手率": 0.84,
        "symbol": "105.BKR"
    },
    {
        "序号": 1698,
        "name": "腾讯音乐",
        "最新价": 8.22,
        "涨跌额": 0.13,
        "涨跌幅": 1.61,
        "开盘价": 7.95,
        "最高价": 8.3,
        "最低价": 7.93,
        "昨收价": 8.09,
        "总市值": 14106154013.0,
        "市盈率": 20.88,
        "成交量": 6710585.0,
        "成交额": 55175612.0,
        "振幅": 4.57,
        "换手率": 0.39,
        "symbol": "106.TME"
    },
    {
        "序号": 1699,
        "name": "Badger Meter Inc",
        "最新价": 150.72,
        "涨跌额": 2.38,
        "涨跌幅": 1.6,
        "开盘价": 148.72,
        "最高价": 151.36,
        "最低价": 148.55,
        "昨收价": 148.34,
        "总市值": 4423160849.0,
        "市盈率": 51.78,
        "成交量": 89260.0,
        "成交额": 13441452.0,
        "振幅": 1.89,
        "换手率": 0.3,
        "symbol": "106.BMI"
    },
    {
        "序号": 1700,
        "name": "Novanta Inc",
        "最新价": 153.89,
        "涨跌额": 2.43,
        "涨跌幅": 1.6,
        "开盘价": 150.5,
        "最高价": 154.5,
        "最低价": 150.5,
        "昨收价": 151.46,
        "总市值": 5510857839.0,
        "市盈率": 72.87,
        "成交量": 142579.0,
        "成交额": 21813049.0,
        "振幅": 2.64,
        "换手率": 0.4,
        "symbol": "105.NOVT"
    },
    {
        "序号": 1701,
        "name": "OP Bancorp",
        "最新价": 9.5,
        "涨跌额": 0.15,
        "涨跌幅": 1.6,
        "开盘价": 9.45,
        "最高价": 9.5,
        "最低价": 9.35,
        "昨收价": 9.35,
        "总市值": 142492429.0,
        "市盈率": 5.32,
        "成交量": 15442.0,
        "成交额": 144980.0,
        "振幅": 1.6,
        "换手率": 0.1,
        "symbol": "105.OPBK"
    },
    {
        "序号": 1702,
        "name": "Bridge Investment Group Holding",
        "最新价": 7.61,
        "涨跌额": 0.12,
        "涨跌幅": 1.6,
        "开盘价": 7.5,
        "最高价": 7.62,
        "最低价": 7.5,
        "昨收价": 7.49,
        "总市值": 900570292.0,
        "市盈率": -312.16,
        "成交量": 239887.0,
        "成交额": 1820173.0,
        "振幅": 1.6,
        "换手率": 0.2,
        "symbol": "106.BRDG"
    },
    {
        "序号": 1703,
        "name": "Accel Entertainment Inc-A",
        "最新价": 10.15,
        "涨跌额": 0.16,
        "涨跌幅": 1.6,
        "开盘价": 9.98,
        "最高价": 10.15,
        "最低价": 9.97,
        "昨收价": 9.99,
        "总市值": 860753333.0,
        "市盈率": 20.01,
        "成交量": 266751.0,
        "成交额": 2692430.0,
        "振幅": 1.8,
        "换手率": 0.31,
        "symbol": "106.ACEL"
    },
    {
        "序号": 1704,
        "name": "Cumulus Media Inc-A",
        "最新价": 5.08,
        "涨跌额": 0.08,
        "涨跌幅": 1.6,
        "开盘价": 4.95,
        "最高价": 5.09,
        "最低价": 4.91,
        "昨收价": 5.0,
        "总市值": 83861504.0,
        "市盈率": -4.22,
        "成交量": 51057.0,
        "成交额": 255508.0,
        "振幅": 3.6,
        "换手率": 0.31,
        "symbol": "105.CMLS"
    },
    {
        "序号": 1705,
        "name": "Varex Imaging Corp",
        "最新价": 19.69,
        "涨跌额": 0.31,
        "涨跌幅": 1.6,
        "开盘价": 19.32,
        "最高价": 20.08,
        "最低价": 19.29,
        "昨收价": 19.38,
        "总市值": 797445000.0,
        "市盈率": 16.54,
        "成交量": 544845.0,
        "成交额": 10734468.0,
        "振幅": 4.08,
        "换手率": 1.35,
        "symbol": "105.VREX"
    },
    {
        "序号": 1706,
        "name": "瑞杰金融",
        "最新价": 107.05,
        "涨跌额": 1.68,
        "涨跌幅": 1.59,
        "开盘价": 105.28,
        "最高价": 107.26,
        "最低价": 105.08,
        "昨收价": 105.37,
        "总市值": 22331353551.0,
        "市盈率": 12.84,
        "成交量": 660133.0,
        "成交额": 70483044.0,
        "振幅": 2.07,
        "换手率": 0.32,
        "symbol": "106.RJF"
    },
    {
        "序号": 1707,
        "name": "嘉银科技",
        "最新价": 5.1,
        "涨跌额": 0.08,
        "涨跌幅": 1.59,
        "开盘价": 5.02,
        "最高价": 5.15,
        "最低价": 5.0,
        "昨收价": 5.02,
        "总市值": 273793765.0,
        "市盈率": 1.32,
        "成交量": 16388.0,
        "成交额": 83002.0,
        "振幅": 2.99,
        "换手率": 0.03,
        "symbol": "105.JFIN"
    },
    {
        "序号": 1708,
        "name": "ICON plc",
        "最新价": 272.46,
        "涨跌额": 4.27,
        "涨跌幅": 1.59,
        "开盘价": 266.92,
        "最高价": 273.33,
        "最低价": 261.92,
        "昨收价": 268.19,
        "总市值": 22430807881.0,
        "市盈率": 43.69,
        "成交量": 726673.0,
        "成交额": 195787478.0,
        "振幅": 4.25,
        "换手率": 0.88,
        "symbol": "105.ICLR"
    },
    {
        "序号": 1709,
        "name": "Mitek Systems Inc",
        "最新价": 10.85,
        "涨跌额": 0.17,
        "涨跌幅": 1.59,
        "开盘价": 10.66,
        "最高价": 11.22,
        "最低价": 10.56,
        "昨收价": 10.68,
        "总市值": 494646889.0,
        "市盈率": 54.01,
        "成交量": 533511.0,
        "成交额": 5793669.0,
        "振幅": 6.18,
        "换手率": 1.17,
        "symbol": "105.MITK"
    },
    {
        "序号": 1710,
        "name": "NOW Inc",
        "最新价": 10.85,
        "涨跌额": 0.17,
        "涨跌幅": 1.59,
        "开盘价": 10.77,
        "最高价": 10.89,
        "最低价": 10.65,
        "昨收价": 10.68,
        "总市值": 1153693075.0,
        "市盈率": 8.74,
        "成交量": 921605.0,
        "成交额": 9974059.0,
        "振幅": 2.25,
        "换手率": 0.87,
        "symbol": "106.DNOW"
    },
    {
        "序号": 1711,
        "name": "Paragon 28 Inc",
        "最新价": 11.49,
        "涨跌额": 0.18,
        "涨跌幅": 1.59,
        "开盘价": 11.32,
        "最高价": 11.59,
        "最低价": 11.27,
        "昨收价": 11.31,
        "总市值": 950236868.0,
        "市盈率": -14.17,
        "成交量": 277798.0,
        "成交额": 3172224.0,
        "振幅": 2.83,
        "换手率": 0.34,
        "symbol": "106.FNA"
    },
    {
        "序号": 1712,
        "name": "Envirotech Vehicles Inc",
        "最新价": 1.28,
        "涨跌额": 0.02,
        "涨跌幅": 1.59,
        "开盘价": 1.32,
        "最高价": 1.32,
        "最低价": 1.26,
        "昨收价": 1.26,
        "总市值": 19335793.0,
        "市盈率": -0.4,
        "成交量": 12937.0,
        "成交额": 16758.0,
        "振幅": 4.76,
        "换手率": 0.09,
        "symbol": "105.EVTV"
    },
    {
        "序号": 1713,
        "name": "Evolv Technologies Holdings Inc",
        "最新价": 0.64,
        "涨跌额": 0.01,
        "涨跌幅": 1.59,
        "开盘价": 0.63,
        "最高价": 0.68,
        "最低价": 0.63,
        "昨收价": 0.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 46255.0,
        "成交额": 30070.0,
        "振幅": 7.94,
        "换手率": null,
        "symbol": "105.EVLVW"
    },
    {
        "序号": 1714,
        "name": "三倍做多金融ETF-Direxion",
        "最新价": 72.98,
        "涨跌额": 1.14,
        "涨跌幅": 1.59,
        "开盘价": 71.92,
        "最高价": 73.41,
        "最低价": 71.47,
        "昨收价": 71.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 512354.0,
        "成交额": 37151096.0,
        "振幅": 2.7,
        "换手率": null,
        "symbol": "107.FAS"
    },
    {
        "序号": 1715,
        "name": "博彩ETF-VanEck Vectors",
        "最新价": 40.37,
        "涨跌额": 0.63,
        "涨跌幅": 1.59,
        "开盘价": 40.4,
        "最高价": 40.63,
        "最低价": 40.3,
        "昨收价": 39.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 5500.0,
        "成交额": 222142.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.BJK"
    },
    {
        "序号": 1716,
        "name": "普罗斯佩克特资本",
        "最新价": 5.77,
        "涨跌额": 0.09,
        "涨跌幅": 1.58,
        "开盘价": 5.67,
        "最高价": 5.78,
        "最低价": 5.66,
        "昨收价": 5.68,
        "总市值": 2367926337.0,
        "市盈率": 22.04,
        "成交量": 2109371.0,
        "成交额": 12106834.0,
        "振幅": 2.11,
        "换手率": 0.51,
        "symbol": "105.PSEC"
    },
    {
        "序号": 1717,
        "name": "MAX S&P 500 4X Leveraged ETNs",
        "最新价": 25.65,
        "涨跌额": 0.4,
        "涨跌幅": 1.58,
        "开盘价": 25.05,
        "最高价": 25.76,
        "最低价": 24.99,
        "昨收价": 25.25,
        "总市值": 18491319980.0,
        "市盈率": 5.83,
        "成交量": 441977.0,
        "成交额": 11235359.0,
        "振幅": 3.05,
        "换手率": null,
        "symbol": "107.XXXX"
    },
    {
        "序号": 1718,
        "name": "The Goldman Sachs Group Inc Ser",
        "最新价": 21.82,
        "涨跌额": 0.34,
        "涨跌幅": 1.58,
        "开盘价": 21.45,
        "最高价": 21.84,
        "最低价": 21.45,
        "昨收价": 21.48,
        "总市值": 654578.0,
        "市盈率": null,
        "成交量": 29132.0,
        "成交额": 632624.0,
        "振幅": 1.82,
        "换手率": 97.11,
        "symbol": "106.GS_A"
    },
    {
        "序号": 1719,
        "name": "Blackstone Mortgage Trust Inc",
        "最新价": 21.18,
        "涨跌额": 0.33,
        "涨跌幅": 1.58,
        "开盘价": 20.86,
        "最高价": 21.47,
        "最低价": 20.83,
        "昨收价": 20.85,
        "总市值": 3649262300.0,
        "市盈率": 18.12,
        "成交量": 3072261.0,
        "成交额": 65123703.0,
        "振幅": 3.07,
        "换手率": 1.78,
        "symbol": "106.BXMT"
    },
    {
        "序号": 1720,
        "name": "IDEXX实验室",
        "最新价": 531.35,
        "涨跌额": 8.27,
        "涨跌幅": 1.58,
        "开盘价": 520.17,
        "最高价": 532.41,
        "最低价": 519.17,
        "昨收价": 523.08,
        "总市值": 44129521858.0,
        "市盈率": 53.64,
        "成交量": 540491.0,
        "成交额": 285825072.0,
        "振幅": 2.53,
        "换手率": 0.65,
        "symbol": "105.IDXX"
    },
    {
        "序号": 1721,
        "name": "Ponce Financial Group Inc",
        "最新价": 9.65,
        "涨跌额": 0.15,
        "涨跌幅": 1.58,
        "开盘价": 9.49,
        "最高价": 9.65,
        "最低价": 9.45,
        "昨收价": 9.5,
        "总市值": 228257240.0,
        "市盈率": -35.78,
        "成交量": 56580.0,
        "成交额": 539977.0,
        "振幅": 2.11,
        "换手率": 0.24,
        "symbol": "105.PDLB"
    },
    {
        "序号": 1722,
        "name": "顶点能源",
        "最新价": 3.22,
        "涨跌额": 0.05,
        "涨跌幅": 1.58,
        "开盘价": 3.2,
        "最高价": 3.35,
        "最低价": 3.17,
        "昨收价": 3.17,
        "总市值": 301116194.0,
        "市盈率": 8.19,
        "成交量": 1685199.0,
        "成交额": 5456763.0,
        "振幅": 5.68,
        "换手率": 1.8,
        "symbol": "105.VTNR"
    },
    {
        "序号": 1723,
        "name": "哈里逊合众银行",
        "最新价": 12.25,
        "涨跌额": 0.19,
        "涨跌幅": 1.58,
        "开盘价": 12.04,
        "最高价": 12.4,
        "最低价": 11.76,
        "昨收价": 12.06,
        "总市值": 540397100.0,
        "市盈率": 7.27,
        "成交量": 120786.0,
        "成交额": 1482750.0,
        "振幅": 5.31,
        "换手率": 0.27,
        "symbol": "105.HBNC"
    },
    {
        "序号": 1724,
        "name": "多邻国",
        "最新价": 214.75,
        "涨跌额": 3.33,
        "涨跌幅": 1.58,
        "开盘价": 209.0,
        "最高价": 219.0,
        "最低价": 208.02,
        "昨收价": 211.42,
        "总市值": 9022466986.0,
        "市盈率": -904.05,
        "成交量": 433448.0,
        "成交额": 93434611.0,
        "振幅": 5.19,
        "换手率": 1.03,
        "symbol": "105.DUOL"
    },
    {
        "序号": 1725,
        "name": "Doximity Inc-A",
        "最新价": 24.51,
        "涨跌额": 0.38,
        "涨跌幅": 1.57,
        "开盘价": 24.04,
        "最高价": 24.59,
        "最低价": 24.04,
        "昨收价": 24.13,
        "总市值": 4556599737.0,
        "市盈率": 37.0,
        "成交量": 1243185.0,
        "成交额": 30390615.0,
        "振幅": 2.28,
        "换手率": 0.67,
        "symbol": "106.DOCS"
    },
    {
        "序号": 1726,
        "name": "Moelis & Co-A",
        "最新价": 53.59,
        "涨跌额": 0.83,
        "涨跌幅": 1.57,
        "开盘价": 52.6,
        "最高价": 53.86,
        "最低价": 52.43,
        "昨收价": 52.76,
        "总市值": 3813170620.0,
        "市盈率": 3756.82,
        "成交量": 624071.0,
        "成交额": 33266758.0,
        "振幅": 2.71,
        "换手率": 0.88,
        "symbol": "106.MC"
    },
    {
        "序号": 1727,
        "name": "Radian Group Inc",
        "最新价": 25.83,
        "涨跌额": 0.4,
        "涨跌幅": 1.57,
        "开盘价": 25.34,
        "最高价": 25.87,
        "最低价": 25.34,
        "昨收价": 25.43,
        "总市值": 3956131531.0,
        "市盈率": 6.35,
        "成交量": 1086520.0,
        "成交额": 27978941.0,
        "振幅": 2.08,
        "换手率": 0.71,
        "symbol": "106.RDN"
    },
    {
        "序号": 1728,
        "name": "Simplify Interest Rate Hedge ET",
        "最新价": 81.37,
        "涨跌额": 1.26,
        "涨跌幅": 1.57,
        "开盘价": 81.07,
        "最高价": 82.5,
        "最低价": 81.07,
        "昨收价": 80.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 59813.0,
        "成交额": 4888802.0,
        "振幅": 1.79,
        "换手率": null,
        "symbol": "107.PFIX"
    },
    {
        "序号": 1729,
        "name": "Pinnacle Focused Opportunities ",
        "最新价": 23.27,
        "涨跌额": 0.36,
        "涨跌幅": 1.57,
        "开盘价": 23.29,
        "最高价": 23.29,
        "最低价": 23.27,
        "昨收价": 22.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 198.0,
        "成交额": 4611.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.FCUS"
    },
    {
        "序号": 1730,
        "name": "Zoom Video通讯",
        "最新价": 73.06,
        "涨跌额": 1.13,
        "涨跌幅": 1.57,
        "开盘价": 71.7,
        "最高价": 73.96,
        "最低价": 71.17,
        "昨收价": 71.93,
        "总市值": 22241180983.0,
        "市盈率": 94.81,
        "成交量": 5031081.0,
        "成交额": 368251904.0,
        "振幅": 3.88,
        "换手率": 1.65,
        "symbol": "105.ZM"
    },
    {
        "序号": 1731,
        "name": "B. Riley Financial Inc Series B",
        "最新价": 19.4,
        "涨跌额": 0.3,
        "涨跌幅": 1.57,
        "开盘价": 19.11,
        "最高价": 19.54,
        "最低价": 18.92,
        "昨收价": 19.1,
        "总市值": 33543.0,
        "市盈率": null,
        "成交量": 8159.0,
        "成交额": 156498.0,
        "振幅": 3.25,
        "换手率": 471.89,
        "symbol": "105.RILYL"
    },
    {
        "序号": 1732,
        "name": "二倍做多罗素2000ETF-ProShares",
        "最新价": 33.04,
        "涨跌额": 0.51,
        "涨跌幅": 1.57,
        "开盘价": 32.46,
        "最高价": 33.35,
        "最低价": 32.35,
        "昨收价": 32.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1023627.0,
        "成交额": 33702146.0,
        "振幅": 3.07,
        "换手率": null,
        "symbol": "107.UWM"
    },
    {
        "序号": 1733,
        "name": "iShares MSCI Global Energy Prod",
        "最新价": 23.98,
        "涨跌额": 0.37,
        "涨跌幅": 1.57,
        "开盘价": 23.8,
        "最高价": 24.0,
        "最低价": 23.77,
        "昨收价": 23.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 9574.0,
        "成交额": 228362.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.FILL"
    },
    {
        "序号": 1734,
        "name": "EPAM Systems Inc",
        "最新价": 268.5,
        "涨跌额": 4.14,
        "涨跌幅": 1.57,
        "开盘价": 263.79,
        "最高价": 269.74,
        "最低价": 262.84,
        "昨收价": 264.36,
        "总市值": 15492577806.0,
        "市盈率": 32.65,
        "成交量": 387760.0,
        "成交额": 103751584.0,
        "振幅": 2.61,
        "换手率": 0.67,
        "symbol": "106.EPAM"
    },
    {
        "序号": 1735,
        "name": "Heritage Financial Corp",
        "最新价": 20.11,
        "涨跌额": 0.31,
        "涨跌幅": 1.57,
        "开盘价": 19.94,
        "最高价": 20.36,
        "最低价": 19.78,
        "昨收价": 19.8,
        "总市值": 701860638.0,
        "市盈率": 8.99,
        "成交量": 149459.0,
        "成交额": 3002781.0,
        "振幅": 2.93,
        "换手率": 0.43,
        "symbol": "105.HFWA"
    },
    {
        "序号": 1736,
        "name": "Regional Management Corp",
        "最新价": 23.39,
        "涨跌额": 0.36,
        "涨跌幅": 1.56,
        "开盘价": 23.25,
        "最高价": 23.8,
        "最低价": 22.73,
        "昨收价": 23.03,
        "总市值": 230034545.0,
        "市盈率": 8.87,
        "成交量": 15719.0,
        "成交额": 365839.0,
        "振幅": 4.65,
        "换手率": 0.16,
        "symbol": "106.RM"
    },
    {
        "序号": 1737,
        "name": "康姆斯克",
        "最新价": 0.65,
        "涨跌额": 0.01,
        "涨跌幅": 1.56,
        "开盘价": 0.65,
        "最高价": 0.67,
        "最低价": 0.64,
        "昨收价": 0.64,
        "总市值": 61817024.0,
        "市盈率": -1.22,
        "成交量": 182554.0,
        "成交额": 118449.0,
        "振幅": 4.69,
        "换手率": 0.19,
        "symbol": "105.SCOR"
    },
    {
        "序号": 1738,
        "name": "Passage BIO Inc",
        "最新价": 0.65,
        "涨跌额": 0.01,
        "涨跌幅": 1.56,
        "开盘价": 0.66,
        "最高价": 0.69,
        "最低价": 0.63,
        "昨收价": 0.64,
        "总市值": 35632765.0,
        "市盈率": -0.32,
        "成交量": 341277.0,
        "成交额": 225029.0,
        "振幅": 9.38,
        "换手率": 0.62,
        "symbol": "105.PASG"
    },
    {
        "序号": 1739,
        "name": "LiqTech International Inc",
        "最新价": 3.25,
        "涨跌额": 0.05,
        "涨跌幅": 1.56,
        "开盘价": 3.23,
        "最高价": 3.25,
        "最低价": 3.23,
        "昨收价": 3.2,
        "总市值": 18613758.0,
        "市盈率": -2.47,
        "成交量": 1940.0,
        "成交额": 6283.0,
        "振幅": 0.63,
        "换手率": 0.03,
        "symbol": "105.LIQT"
    },
    {
        "序号": 1740,
        "name": "Liberty Latin America Ltd-A",
        "最新价": 7.15,
        "涨跌额": 0.11,
        "涨跌幅": 1.56,
        "开盘价": 7.03,
        "最高价": 7.19,
        "最低价": 6.98,
        "昨收价": 7.04,
        "总市值": 1463909118.0,
        "市盈率": 8.99,
        "成交量": 251904.0,
        "成交额": 1787924.0,
        "振幅": 2.98,
        "换手率": 0.59,
        "symbol": "105.LILA"
    },
    {
        "序号": 1741,
        "name": "Astra Space Inc-A",
        "最新价": 1.3,
        "涨跌额": 0.02,
        "涨跌幅": 1.56,
        "开盘价": 1.31,
        "最高价": 1.32,
        "最低价": 1.29,
        "昨收价": 1.28,
        "总市值": 29241399.0,
        "市盈率": -0.22,
        "成交量": 147665.0,
        "成交额": 192682.0,
        "振幅": 2.34,
        "换手率": 0.66,
        "symbol": "105.ASTR"
    },
    {
        "序号": 1742,
        "name": "远地点",
        "最新价": 48.8,
        "涨跌额": 0.75,
        "涨跌幅": 1.56,
        "开盘价": 48.05,
        "最高价": 49.15,
        "最低价": 48.05,
        "昨收价": 48.05,
        "总市值": 1077131022.0,
        "市盈率": 10.68,
        "成交量": 71136.0,
        "成交额": 3467950.0,
        "振幅": 2.29,
        "换手率": 0.32,
        "symbol": "105.APOG"
    },
    {
        "序号": 1743,
        "name": "Liberty Latin America Ltd-C",
        "最新价": 7.16,
        "涨跌额": 0.11,
        "涨跌幅": 1.56,
        "开盘价": 7.05,
        "最高价": 7.21,
        "最低价": 7.0,
        "昨收价": 7.05,
        "总市值": 1465956543.0,
        "市盈率": 9.0,
        "成交量": 635422.0,
        "成交额": 4519901.0,
        "振幅": 2.98,
        "换手率": 0.39,
        "symbol": "105.LILAK"
    },
    {
        "序号": 1744,
        "name": "西诺乌斯金融",
        "最新价": 35.83,
        "涨跌额": 0.55,
        "涨跌幅": 1.56,
        "开盘价": 35.39,
        "最高价": 35.98,
        "最低价": 35.11,
        "昨收价": 35.28,
        "总市值": 5239846919.0,
        "市盈率": 7.72,
        "成交量": 1661829.0,
        "成交额": 59392038.0,
        "振幅": 2.47,
        "换手率": 1.14,
        "symbol": "106.SNV"
    },
    {
        "序号": 1745,
        "name": "Spectrum Brands Holdings Inc",
        "最新价": 74.28,
        "涨跌额": 1.14,
        "涨跌幅": 1.56,
        "开盘价": 73.02,
        "最高价": 74.41,
        "最低价": 72.63,
        "昨收价": 73.14,
        "总市值": 2622679057.0,
        "市盈率": 1.46,
        "成交量": 816481.0,
        "成交额": 60267919.0,
        "振幅": 2.43,
        "换手率": 2.31,
        "symbol": "106.SPB"
    },
    {
        "序号": 1746,
        "name": "Affinity Bancshares Inc",
        "最新价": 14.99,
        "涨跌额": 0.23,
        "涨跌幅": 1.56,
        "开盘价": 14.88,
        "最高价": 15.01,
        "最低价": 14.88,
        "昨收价": 14.76,
        "总市值": 95760752.0,
        "市盈率": 14.43,
        "成交量": 4418.0,
        "成交额": 66163.0,
        "振幅": 0.88,
        "换手率": 0.07,
        "symbol": "105.AFBI"
    },
    {
        "序号": 1747,
        "name": "Kayne Anderson MLP\/Midstream In",
        "最新价": 8.48,
        "涨跌额": 0.13,
        "涨跌幅": 1.56,
        "开盘价": 8.37,
        "最高价": 8.5,
        "最低价": 8.34,
        "昨收价": 8.35,
        "总市值": 969091856.0,
        "市盈率": null,
        "成交量": 669350.0,
        "成交额": 5645873.0,
        "振幅": 1.92,
        "换手率": 0.59,
        "symbol": "106.KYN"
    },
    {
        "序号": 1748,
        "name": "Harrow Health Inc",
        "最新价": 9.79,
        "涨跌额": 0.15,
        "涨跌幅": 1.56,
        "开盘价": 9.69,
        "最高价": 9.89,
        "最低价": 9.62,
        "昨收价": 9.64,
        "总市值": 343802733.0,
        "市盈率": -24.2,
        "成交量": 484133.0,
        "成交额": 4725273.0,
        "振幅": 2.8,
        "换手率": 1.38,
        "symbol": "105.HROW"
    },
    {
        "序号": 1749,
        "name": "霍顿房屋",
        "最新价": 138.46,
        "涨跌额": 2.12,
        "涨跌幅": 1.55,
        "开盘价": 136.15,
        "最高价": 138.87,
        "最低价": 135.99,
        "昨收价": 136.34,
        "总市值": 46132708424.0,
        "市盈率": 9.72,
        "成交量": 2899220.0,
        "成交额": 400495376.0,
        "振幅": 2.11,
        "换手率": 0.87,
        "symbol": "106.DHI"
    },
    {
        "序号": 1750,
        "name": "Evolent Health Inc-A",
        "最新价": 28.1,
        "涨跌额": 0.43,
        "涨跌幅": 1.55,
        "开盘价": 27.69,
        "最高价": 28.18,
        "最低价": 27.36,
        "昨收价": 27.67,
        "总市值": 3230367261.0,
        "市盈率": -35.51,
        "成交量": 1463127.0,
        "成交额": 40801912.0,
        "振幅": 2.96,
        "换手率": 1.27,
        "symbol": "106.EVH"
    },
    {
        "序号": 1751,
        "name": "Willamette Valley Vineyards Inc",
        "最新价": 5.23,
        "涨跌额": 0.08,
        "涨跌幅": 1.55,
        "开盘价": 5.14,
        "最高价": 5.23,
        "最低价": 5.14,
        "昨收价": 5.15,
        "总市值": 25964487.0,
        "市盈率": -31.72,
        "成交量": 1187.0,
        "成交额": 6129.0,
        "振幅": 1.75,
        "换手率": 0.02,
        "symbol": "105.WVVI"
    },
    {
        "序号": 1752,
        "name": "Blue Bird Corp",
        "最新价": 21.58,
        "涨跌额": 0.33,
        "涨跌幅": 1.55,
        "开盘价": 21.51,
        "最高价": 21.71,
        "最低价": 21.1,
        "昨收价": 21.25,
        "总市值": 694125556.0,
        "市盈率": -38.78,
        "成交量": 326268.0,
        "成交额": 6965081.0,
        "振幅": 2.87,
        "换手率": 1.01,
        "symbol": "105.BLBD"
    },
    {
        "序号": 1753,
        "name": "SPDR S&P Kensho New Economies C",
        "最新价": 43.82,
        "涨跌额": 0.67,
        "涨跌幅": 1.55,
        "开盘价": 43.11,
        "最高价": 43.92,
        "最低价": 43.11,
        "昨收价": 43.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 131368.0,
        "成交额": 5740954.0,
        "振幅": 1.88,
        "换手率": null,
        "symbol": "107.KOMP"
    },
    {
        "序号": 1754,
        "name": "保富银行",
        "最新价": 68.03,
        "涨跌额": 1.04,
        "涨跌幅": 1.55,
        "开盘价": 66.81,
        "最高价": 68.28,
        "最低价": 66.81,
        "昨收价": 66.99,
        "总市值": 967173122.0,
        "市盈率": 26.59,
        "成交量": 87052.0,
        "成交额": 5918252.0,
        "振幅": 2.19,
        "换手率": 0.61,
        "symbol": "105.PFBC"
    },
    {
        "序号": 1755,
        "name": "Gran Tierra Energy Inc",
        "最新价": 5.89,
        "涨跌额": 0.09,
        "涨跌幅": 1.55,
        "开盘价": 5.84,
        "最高价": 5.91,
        "最低价": 5.8,
        "昨收价": 5.8,
        "总市值": 196068116.0,
        "市盈率": 10.17,
        "成交量": 176654.0,
        "成交额": 1034911.0,
        "振幅": 1.9,
        "换手率": 0.53,
        "symbol": "107.GTE"
    },
    {
        "序号": 1756,
        "name": "凯利服务-B",
        "最新价": 20.95,
        "涨跌额": 0.32,
        "涨跌幅": 1.55,
        "开盘价": 20.95,
        "最高价": 20.95,
        "最低价": 20.95,
        "昨收价": 20.63,
        "总市值": 739020698.0,
        "市盈率": 30.66,
        "成交量": 566.0,
        "成交额": 11857.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.KELYB"
    },
    {
        "序号": 1757,
        "name": "GrafTech International Ltd",
        "最新价": 2.62,
        "涨跌额": 0.04,
        "涨跌幅": 1.55,
        "开盘价": 2.58,
        "最高价": 2.72,
        "最低价": 2.57,
        "昨收价": 2.58,
        "总市值": 672836698.0,
        "市盈率": 53.87,
        "成交量": 2303409.0,
        "成交额": 6116224.0,
        "振幅": 5.81,
        "换手率": 0.9,
        "symbol": "106.EAF"
    },
    {
        "序号": 1758,
        "name": "APi Group Corp",
        "最新价": 30.8,
        "涨跌额": 0.47,
        "涨跌幅": 1.55,
        "开盘价": 30.29,
        "最高价": 30.85,
        "最低价": 30.29,
        "昨收价": 30.33,
        "总市值": 7255222436.0,
        "市盈率": 48.37,
        "成交量": 1116448.0,
        "成交额": 34320241.0,
        "振幅": 1.85,
        "换手率": 0.47,
        "symbol": "106.APG"
    },
    {
        "序号": 1759,
        "name": "嘉年华邮轮(US ADR)",
        "最新价": 16.4,
        "涨跌额": 0.25,
        "涨跌幅": 1.55,
        "开盘价": 16.21,
        "最高价": 16.41,
        "最低价": 16.11,
        "昨收价": 16.15,
        "总市值": 3063767574.0,
        "市盈率": -1.89,
        "成交量": 2025583.0,
        "成交额": 32985104.0,
        "振幅": 1.86,
        "换手率": 1.08,
        "symbol": "106.CUK"
    },
    {
        "序号": 1760,
        "name": "Primerica Inc",
        "最新价": 211.46,
        "涨跌额": 3.22,
        "涨跌幅": 1.55,
        "开盘价": 207.64,
        "最高价": 211.53,
        "最低价": 207.47,
        "昨收价": 208.24,
        "总市值": 7408283296.0,
        "市盈率": 15.68,
        "成交量": 72991.0,
        "成交额": 15364833.0,
        "振幅": 1.95,
        "换手率": 0.21,
        "symbol": "106.PRI"
    },
    {
        "序号": 1761,
        "name": "Rocket Companies Inc-A",
        "最新价": 10.51,
        "涨跌额": 0.16,
        "涨跌幅": 1.55,
        "开盘价": 10.31,
        "最高价": 10.54,
        "最低价": 10.26,
        "昨收价": 10.35,
        "总市值": 20833727875.0,
        "市盈率": -926.15,
        "成交量": 1887585.0,
        "成交额": 19775629.0,
        "振幅": 2.71,
        "换手率": 0.1,
        "symbol": "106.RKT"
    },
    {
        "序号": 1762,
        "name": "Simplify Managed Futures Strate",
        "最新价": 26.28,
        "涨跌额": 0.4,
        "涨跌幅": 1.55,
        "开盘价": 26.05,
        "最高价": 26.38,
        "最低价": 26.05,
        "昨收价": 25.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 31574.0,
        "成交额": 830003.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "107.CTA"
    },
    {
        "序号": 1763,
        "name": "SFL Corp Ltd",
        "最新价": 11.17,
        "涨跌额": 0.17,
        "涨跌幅": 1.55,
        "开盘价": 11.07,
        "最高价": 11.17,
        "最低价": 11.06,
        "昨收价": 11.0,
        "总市值": 1535507261.0,
        "市盈率": 15.2,
        "成交量": 544428.0,
        "成交额": 6058493.0,
        "振幅": 1.0,
        "换手率": 0.4,
        "symbol": "106.SFL"
    },
    {
        "序号": 1764,
        "name": "NexPoint Diversified Real Estat",
        "最新价": 8.55,
        "涨跌额": 0.13,
        "涨跌幅": 1.54,
        "开盘价": 8.27,
        "最高价": 8.59,
        "最低价": 8.23,
        "昨收价": 8.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 113406.0,
        "成交额": 954707.0,
        "振幅": 4.28,
        "换手率": null,
        "symbol": "106.NXDT"
    },
    {
        "序号": 1765,
        "name": "Citizens Financial Group Inc",
        "最新价": 29.61,
        "涨跌额": 0.45,
        "涨跌幅": 1.54,
        "开盘价": 29.33,
        "最高价": 29.9,
        "最低价": 29.12,
        "昨收价": 29.16,
        "总市值": 13804862053.0,
        "市盈率": 6.66,
        "成交量": 4002800.0,
        "成交额": 118556174.0,
        "振幅": 2.67,
        "换手率": 0.86,
        "symbol": "106.CFG"
    },
    {
        "序号": 1766,
        "name": "Orchid Island Capital Inc",
        "最新价": 7.9,
        "涨跌额": 0.12,
        "涨跌幅": 1.54,
        "开盘价": 7.75,
        "最高价": 7.93,
        "最低价": 7.71,
        "昨收价": 7.78,
        "总市值": 413425217.0,
        "市盈率": -13.16,
        "成交量": 919330.0,
        "成交额": 7236631.0,
        "振幅": 2.83,
        "换手率": 1.76,
        "symbol": "106.ORC"
    },
    {
        "序号": 1767,
        "name": "SPDR S&P Telecom ETF",
        "最新价": 74.4,
        "涨跌额": 1.13,
        "涨跌幅": 1.54,
        "开盘价": 73.17,
        "最高价": 74.61,
        "最低价": 73.17,
        "昨收价": 73.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 6815.0,
        "成交额": 505701.0,
        "振幅": 1.97,
        "换手率": null,
        "symbol": "107.XTL"
    },
    {
        "序号": 1768,
        "name": "国际游戏科技",
        "最新价": 27.0,
        "涨跌额": 0.41,
        "涨跌幅": 1.54,
        "开盘价": 26.5,
        "最高价": 27.24,
        "最低价": 26.5,
        "昨收价": 26.59,
        "总市值": 5413014000.0,
        "市盈率": 54.13,
        "成交量": 1038487.0,
        "成交额": 28095081.0,
        "振幅": 2.78,
        "换手率": 0.52,
        "symbol": "106.IGT"
    },
    {
        "序号": 1769,
        "name": "富国银行",
        "最新价": 46.1,
        "涨跌额": 0.7,
        "涨跌幅": 1.54,
        "开盘价": 45.36,
        "最高价": 46.34,
        "最低价": 45.12,
        "昨收价": 45.4,
        "总市值": 167418590815.0,
        "市盈率": 9.12,
        "成交量": 20238411.0,
        "成交额": 929724512.0,
        "振幅": 2.69,
        "换手率": 0.56,
        "symbol": "106.WFC"
    },
    {
        "序号": 1770,
        "name": "Armour住宅房产",
        "最新价": 19.1,
        "涨跌额": 0.29,
        "涨跌幅": 1.54,
        "开盘价": 18.74,
        "最高价": 19.17,
        "最低价": 18.68,
        "昨收价": 18.81,
        "总市值": 935811834.0,
        "市盈率": -7.3,
        "成交量": 1495185.0,
        "成交额": 28470768.0,
        "振幅": 2.6,
        "换手率": 3.05,
        "symbol": "106.ARR"
    },
    {
        "序号": 1771,
        "name": "光辉国际",
        "最新价": 54.72,
        "涨跌额": 0.83,
        "涨跌幅": 1.54,
        "开盘价": 53.66,
        "最高价": 54.85,
        "最低价": 53.41,
        "昨收价": 53.89,
        "总市值": 2875050415.0,
        "市盈率": 16.07,
        "成交量": 385076.0,
        "成交额": 20927952.0,
        "振幅": 2.67,
        "换手率": 0.73,
        "symbol": "106.KFY"
    },
    {
        "序号": 1772,
        "name": "UltraPro MidCap400",
        "最新价": 19.13,
        "涨跌额": 0.29,
        "涨跌幅": 1.54,
        "开盘价": 18.73,
        "最高价": 19.28,
        "最低价": 18.73,
        "昨收价": 18.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 12546.0,
        "成交额": 239591.0,
        "振幅": 2.92,
        "换手率": null,
        "symbol": "107.UMDD"
    },
    {
        "序号": 1773,
        "name": "iShares U.S. Broker-Dealers & S",
        "最新价": 100.95,
        "涨跌额": 1.53,
        "涨跌幅": 1.54,
        "开盘价": 99.44,
        "最高价": 100.95,
        "最低价": 99.4,
        "昨收价": 99.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 6781.0,
        "成交额": 680177.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "107.IAI"
    },
    {
        "序号": 1774,
        "name": "Eve Holding Inc Wt",
        "最新价": 0.66,
        "涨跌额": 0.01,
        "涨跌幅": 1.54,
        "开盘价": 0.66,
        "最高价": 0.66,
        "最低价": 0.59,
        "昨收价": 0.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 17067.0,
        "成交额": 10364.0,
        "振幅": 10.77,
        "换手率": null,
        "symbol": "106.EVEX_WS"
    },
    {
        "序号": 1775,
        "name": "Saratoga Investment Corp",
        "最新价": 26.41,
        "涨跌额": 0.4,
        "涨跌幅": 1.54,
        "开盘价": 26.11,
        "最高价": 26.5,
        "最低价": 26.04,
        "昨收价": 26.01,
        "总市值": 346366543.0,
        "市盈率": 10.53,
        "成交量": 230691.0,
        "成交额": 6073307.0,
        "振幅": 1.77,
        "换手率": 1.76,
        "symbol": "106.SAR"
    },
    {
        "序号": 1776,
        "name": "赫氏",
        "最新价": 71.36,
        "涨跌额": 1.08,
        "涨跌幅": 1.54,
        "开盘价": 70.18,
        "最高价": 71.4,
        "最低价": 70.18,
        "昨收价": 70.28,
        "总市值": 6002181940.0,
        "市盈率": 37.3,
        "成交量": 334203.0,
        "成交额": 23785325.0,
        "振幅": 1.74,
        "换手率": 0.4,
        "symbol": "106.HXL"
    },
    {
        "序号": 1777,
        "name": "莱纳建筑-A",
        "最新价": 139.58,
        "涨跌额": 2.11,
        "涨跌幅": 1.53,
        "开盘价": 136.82,
        "最高价": 140.0,
        "最低价": 136.69,
        "昨收价": 137.47,
        "总市值": 39690256802.0,
        "市盈率": 10.18,
        "成交量": 1689217.0,
        "成交额": 234916181.0,
        "振幅": 2.41,
        "换手率": 0.68,
        "symbol": "106.LEN"
    },
    {
        "序号": 1778,
        "name": "Direxion Daily Cloud Computing ",
        "最新价": 9.93,
        "涨跌额": 0.15,
        "涨跌幅": 1.53,
        "开盘价": 9.72,
        "最高价": 9.98,
        "最低价": 9.69,
        "昨收价": 9.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 5987.0,
        "成交额": 59006.0,
        "振幅": 2.97,
        "换手率": null,
        "symbol": "107.CLDL"
    },
    {
        "序号": 1779,
        "name": "Cadence Bank",
        "最新价": 27.82,
        "涨跌额": 0.42,
        "涨跌幅": 1.53,
        "开盘价": 27.4,
        "最高价": 28.0,
        "最低价": 27.24,
        "昨收价": 27.4,
        "总市值": 5080240107.0,
        "市盈率": 13.33,
        "成交量": 1378911.0,
        "成交额": 38299073.0,
        "振幅": 2.77,
        "换手率": 0.76,
        "symbol": "106.CADE"
    },
    {
        "序号": 1780,
        "name": "WisdomTree Cloud Computing Fund",
        "最新价": 32.47,
        "涨跌额": 0.49,
        "涨跌幅": 1.53,
        "开盘价": 31.8,
        "最高价": 32.59,
        "最低价": 31.8,
        "昨收价": 31.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 150282.0,
        "成交额": 4878921.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "105.WCLD"
    },
    {
        "序号": 1781,
        "name": "原油ETF-PowerShares",
        "最新价": 14.6,
        "涨跌额": 0.22,
        "涨跌幅": 1.53,
        "开盘价": 14.58,
        "最高价": 14.67,
        "最低价": 14.5,
        "昨收价": 14.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 973843.0,
        "成交额": 14215212.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.DBO"
    },
    {
        "序号": 1782,
        "name": "Main Thematic Innovation ETF",
        "最新价": 15.29,
        "涨跌额": 0.23,
        "涨跌幅": 1.53,
        "开盘价": 14.97,
        "最高价": 15.29,
        "最低价": 14.97,
        "昨收价": 15.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 17944.0,
        "成交额": 273055.0,
        "振幅": 2.12,
        "换手率": null,
        "symbol": "107.TMAT"
    },
    {
        "序号": 1783,
        "name": "Direxion Daily GOOGL Bear 1X Sh",
        "最新价": 17.95,
        "涨跌额": 0.27,
        "涨跌幅": 1.53,
        "开盘价": 18.04,
        "最高价": 18.04,
        "最低价": 17.78,
        "昨收价": 17.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 24761.0,
        "成交额": 443726.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "105.GGLS"
    },
    {
        "序号": 1784,
        "name": "硕腾",
        "最新价": 184.6,
        "涨跌额": 2.77,
        "涨跌幅": 1.52,
        "开盘价": 182.08,
        "最高价": 184.85,
        "最低价": 181.47,
        "昨收价": 181.83,
        "总市值": 84752387359.0,
        "市盈率": 37.17,
        "成交量": 1474667.0,
        "成交额": 271184160.0,
        "振幅": 1.86,
        "换手率": 0.32,
        "symbol": "106.ZTS"
    },
    {
        "序号": 1785,
        "name": "数字标识",
        "最新价": 33.99,
        "涨跌额": 0.51,
        "涨跌幅": 1.52,
        "开盘价": 33.48,
        "最高价": 34.09,
        "最低价": 32.92,
        "昨收价": 33.48,
        "总市值": 691964001.0,
        "市盈率": -14.47,
        "成交量": 130774.0,
        "成交额": 4422199.0,
        "振幅": 3.49,
        "换手率": 0.64,
        "symbol": "105.DMRC"
    },
    {
        "序号": 1786,
        "name": "Columbia Seligman Semiconductor",
        "最新价": 20.73,
        "涨跌额": 0.31,
        "涨跌幅": 1.52,
        "开盘价": 20.69,
        "最高价": 20.74,
        "最低价": 20.69,
        "昨收价": 20.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 1370.0,
        "成交额": 28379.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.SEMI"
    },
    {
        "序号": 1787,
        "name": "BCB银行",
        "最新价": 12.04,
        "涨跌额": 0.18,
        "涨跌幅": 1.52,
        "开盘价": 11.87,
        "最高价": 12.06,
        "最低价": 11.78,
        "昨收价": 11.86,
        "总市值": 202849992.0,
        "市盈率": 5.72,
        "成交量": 21560.0,
        "成交额": 257867.0,
        "振幅": 2.36,
        "换手率": 0.13,
        "symbol": "105.BCBP"
    },
    {
        "序号": 1788,
        "name": "CVRx Inc",
        "最新价": 24.15,
        "涨跌额": 0.36,
        "涨跌幅": 1.51,
        "开盘价": 23.66,
        "最高价": 24.93,
        "最低价": 23.65,
        "昨收价": 23.79,
        "总市值": 502697585.0,
        "市盈率": -11.81,
        "成交量": 132957.0,
        "成交额": 3228759.0,
        "振幅": 5.38,
        "换手率": 0.64,
        "symbol": "105.CVRX"
    },
    {
        "序号": 1789,
        "name": "Bath & Body Works Inc",
        "最新价": 37.57,
        "涨跌额": 0.56,
        "涨跌幅": 1.51,
        "开盘价": 36.99,
        "最高价": 37.68,
        "最低价": 36.91,
        "昨收价": 37.01,
        "总市值": 8488588041.0,
        "市盈率": 11.58,
        "成交量": 3563997.0,
        "成交额": 133671787.0,
        "振幅": 2.08,
        "换手率": 1.58,
        "symbol": "106.BBWI"
    },
    {
        "序号": 1790,
        "name": "Viant Technology Inc-A",
        "最新价": 7.38,
        "涨跌额": 0.11,
        "涨跌幅": 1.51,
        "开盘价": 7.27,
        "最高价": 7.61,
        "最低价": 7.22,
        "昨收价": 7.27,
        "总市值": 462160345.0,
        "市盈率": -73.78,
        "成交量": 72085.0,
        "成交额": 535782.0,
        "振幅": 5.36,
        "换手率": 0.12,
        "symbol": "105.DSP"
    },
    {
        "序号": 1791,
        "name": "Palomar Holdings Inc",
        "最新价": 61.06,
        "涨跌额": 0.91,
        "涨跌幅": 1.51,
        "开盘价": 59.87,
        "最高价": 61.18,
        "最低价": 59.65,
        "昨收价": 60.15,
        "总市值": 1510093178.0,
        "市盈率": 20.95,
        "成交量": 93868.0,
        "成交额": 5710152.0,
        "振幅": 2.54,
        "换手率": 0.38,
        "symbol": "105.PLMR"
    },
    {
        "序号": 1792,
        "name": "Chord Energy Corp",
        "最新价": 158.04,
        "涨跌额": 2.35,
        "涨跌幅": 1.51,
        "开盘价": 156.64,
        "最高价": 158.69,
        "最低价": 155.72,
        "昨收价": 155.69,
        "总市值": 6523642919.0,
        "市盈率": 5.93,
        "成交量": 282102.0,
        "成交额": 44561902.0,
        "振幅": 1.91,
        "换手率": 0.68,
        "symbol": "105.CHRD"
    },
    {
        "序号": 1793,
        "name": "Seacoast Banking Corp of Florid",
        "最新价": 26.24,
        "涨跌额": 0.39,
        "涨跌幅": 1.51,
        "开盘价": 26.04,
        "最高价": 26.49,
        "最低价": 25.44,
        "昨收价": 25.85,
        "总市值": 2234332851.0,
        "市盈率": 22.7,
        "成交量": 482480.0,
        "成交额": 12666285.0,
        "振幅": 4.06,
        "换手率": 0.57,
        "symbol": "105.SBCF"
    },
    {
        "序号": 1794,
        "name": "Global X FinTech ETF",
        "最新价": 23.57,
        "涨跌额": 0.35,
        "涨跌幅": 1.51,
        "开盘价": 23.1,
        "最高价": 23.62,
        "最低价": 23.1,
        "昨收价": 23.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 139416.0,
        "成交额": 3275227.0,
        "振幅": 2.24,
        "换手率": null,
        "symbol": "105.FINX"
    },
    {
        "序号": 1795,
        "name": "凯悦酒店",
        "最新价": 122.64,
        "涨跌额": 1.82,
        "涨跌幅": 1.51,
        "开盘价": 120.95,
        "最高价": 123.26,
        "最低价": 120.69,
        "昨收价": 120.82,
        "总市值": 12648959479.0,
        "市盈率": 25.92,
        "成交量": 832561.0,
        "成交额": 101915463.0,
        "振幅": 2.13,
        "换手率": 0.81,
        "symbol": "106.H"
    },
    {
        "序号": 1796,
        "name": "VanEck Social Sentiment ETF",
        "最新价": 17.56,
        "涨跌额": 0.26,
        "涨跌幅": 1.5,
        "开盘价": 17.25,
        "最高价": 17.59,
        "最低价": 17.25,
        "昨收价": 17.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 11553.0,
        "成交额": 201496.0,
        "振幅": 1.97,
        "换手率": null,
        "symbol": "107.BUZZ"
    },
    {
        "序号": 1797,
        "name": "布拉德斯科银行-优先股",
        "最新价": 3.38,
        "涨跌额": 0.05,
        "涨跌幅": 1.5,
        "开盘价": 3.29,
        "最高价": 3.38,
        "最低价": 3.29,
        "昨收价": 3.33,
        "总市值": 17954105549.0,
        "市盈率": 6.78,
        "成交量": 25128091.0,
        "成交额": 83777563.0,
        "振幅": 2.7,
        "换手率": 0.47,
        "symbol": "106.BBD"
    },
    {
        "序号": 1798,
        "name": "Cabaletta Bio Inc",
        "最新价": 16.23,
        "涨跌额": 0.24,
        "涨跌幅": 1.5,
        "开盘价": 15.91,
        "最高价": 16.56,
        "最低价": 15.35,
        "昨收价": 15.99,
        "总市值": 695445957.0,
        "市盈率": -11.13,
        "成交量": 983158.0,
        "成交额": 15900496.0,
        "振幅": 7.57,
        "换手率": 2.29,
        "symbol": "105.CABA"
    },
    {
        "序号": 1799,
        "name": "SITE Centers Corp",
        "最新价": 13.53,
        "涨跌额": 0.2,
        "涨跌幅": 1.5,
        "开盘价": 13.26,
        "最高价": 13.53,
        "最低价": 13.19,
        "昨收价": 13.33,
        "总市值": 2832066492.0,
        "市盈率": 29.05,
        "成交量": 1350795.0,
        "成交额": 18125143.0,
        "振幅": 2.55,
        "换手率": 0.65,
        "symbol": "106.SITC"
    },
    {
        "序号": 1800,
        "name": "CRH水泥",
        "最新价": 64.95,
        "涨跌额": 0.96,
        "涨跌幅": 1.5,
        "开盘价": 63.72,
        "最高价": 65.15,
        "最低价": 63.72,
        "昨收价": 63.99,
        "总市值": 45242226176.0,
        "市盈率": 15.49,
        "成交量": 2887878.0,
        "成交额": 186878376.0,
        "振幅": 2.23,
        "换手率": 0.41,
        "symbol": "106.CRH"
    },
    {
        "序号": 1801,
        "name": "艾睿电子",
        "最新价": 119.78,
        "涨跌额": 1.77,
        "涨跌幅": 1.5,
        "开盘价": 118.09,
        "最高价": 120.41,
        "最低价": 118.09,
        "昨收价": 118.01,
        "总市值": 6487212812.0,
        "市盈率": 6.13,
        "成交量": 875496.0,
        "成交额": 104772655.0,
        "振幅": 1.97,
        "换手率": 1.62,
        "symbol": "106.ARW"
    },
    {
        "序号": 1802,
        "name": "Columbia Financial Inc",
        "最新价": 18.29,
        "涨跌额": 0.27,
        "涨跌幅": 1.5,
        "开盘价": 18.09,
        "最高价": 18.29,
        "最低价": 17.98,
        "昨收价": 18.02,
        "总市值": 1918889113.0,
        "市盈率": 37.33,
        "成交量": 60568.0,
        "成交额": 1104601.0,
        "振幅": 1.72,
        "换手率": 0.06,
        "symbol": "105.CLBK"
    },
    {
        "序号": 1803,
        "name": "第一商业金融服务",
        "最新价": 35.93,
        "涨跌额": 0.53,
        "涨跌幅": 1.5,
        "开盘价": 35.46,
        "最高价": 36.0,
        "最低价": 35.42,
        "昨收价": 35.4,
        "总市值": 298754393.0,
        "市盈率": 7.99,
        "成交量": 4131.0,
        "成交额": 148106.0,
        "振幅": 1.64,
        "换手率": 0.05,
        "symbol": "105.FBIZ"
    },
    {
        "序号": 1804,
        "name": "梅赛尼斯",
        "最新价": 42.73,
        "涨跌额": 0.63,
        "涨跌幅": 1.5,
        "开盘价": 42.5,
        "最高价": 43.07,
        "最低价": 42.23,
        "昨收价": 42.1,
        "总市值": 2879040233.0,
        "市盈率": 12.7,
        "成交量": 118700.0,
        "成交额": 5069838.0,
        "振幅": 2.0,
        "换手率": 0.18,
        "symbol": "105.MEOH"
    },
    {
        "序号": 1805,
        "name": "第一银行(缅因州)",
        "最新价": 25.78,
        "涨跌额": 0.38,
        "涨跌幅": 1.5,
        "开盘价": 25.22,
        "最高价": 25.87,
        "最低价": 25.01,
        "昨收价": 25.4,
        "总市值": 286002418.0,
        "市盈率": 8.93,
        "成交量": 7919.0,
        "成交额": 202813.0,
        "振幅": 3.39,
        "换手率": 0.07,
        "symbol": "105.FNLC"
    },
    {
        "序号": 1806,
        "name": "Janus International Group Inc",
        "最新价": 10.87,
        "涨跌额": 0.16,
        "涨跌幅": 1.49,
        "开盘价": 10.69,
        "最高价": 10.92,
        "最低价": 10.65,
        "昨收价": 10.71,
        "总市值": 1596024904.0,
        "市盈率": 12.03,
        "成交量": 971564.0,
        "成交额": 10559643.0,
        "振幅": 2.52,
        "换手率": 0.66,
        "symbol": "106.JBI"
    },
    {
        "序号": 1807,
        "name": "Fortune Brands Innovations Inc",
        "最新价": 73.43,
        "涨跌额": 1.08,
        "涨跌幅": 1.49,
        "开盘价": 72.59,
        "最高价": 73.52,
        "最低价": 72.36,
        "昨收价": 72.35,
        "总市值": 9259153133.0,
        "市盈率": 21.39,
        "成交量": 698822.0,
        "成交额": 51232021.0,
        "振幅": 1.6,
        "换手率": 0.55,
        "symbol": "106.FBIN"
    },
    {
        "序号": 1808,
        "name": "FREYR Battery",
        "最新价": 2.04,
        "涨跌额": 0.03,
        "涨跌幅": 1.49,
        "开盘价": 2.01,
        "最高价": 2.08,
        "最低价": 1.95,
        "昨收价": 2.01,
        "总市值": 284998677.0,
        "市盈率": -12.67,
        "成交量": 2560564.0,
        "成交额": 5152579.0,
        "振幅": 6.47,
        "换手率": 1.83,
        "symbol": "106.FREY"
    },
    {
        "序号": 1809,
        "name": "HeartBeam Inc",
        "最新价": 1.36,
        "涨跌额": 0.02,
        "涨跌幅": 1.49,
        "开盘价": 1.35,
        "最高价": 1.4,
        "最低价": 1.34,
        "昨收价": 1.34,
        "总市值": 35807484.0,
        "市盈率": -2.47,
        "成交量": 12515.0,
        "成交额": 17046.0,
        "振幅": 4.48,
        "换手率": 0.05,
        "symbol": "105.BEAT"
    },
    {
        "序号": 1810,
        "name": "Innodata Inc",
        "最新价": 8.17,
        "涨跌额": 0.12,
        "涨跌幅": 1.49,
        "开盘价": 8.05,
        "最高价": 8.4,
        "最低价": 7.9,
        "昨收价": 8.05,
        "总市值": 234556991.0,
        "市盈率": -51.89,
        "成交量": 361647.0,
        "成交额": 2967287.0,
        "振幅": 6.21,
        "换手率": 1.26,
        "symbol": "105.INOD"
    },
    {
        "序号": 1811,
        "name": "Citizens Community Bancorp Inc",
        "最新价": 10.9,
        "涨跌额": 0.16,
        "涨跌幅": 1.49,
        "开盘价": 10.57,
        "最高价": 11.06,
        "最低价": 10.57,
        "昨收价": 10.74,
        "总市值": 114102192.0,
        "市盈率": 8.11,
        "成交量": 9784.0,
        "成交额": 107373.0,
        "振幅": 4.56,
        "换手率": 0.09,
        "symbol": "105.CZWI"
    },
    {
        "序号": 1812,
        "name": "第一地平线银行",
        "最新价": 13.63,
        "涨跌额": 0.2,
        "涨跌幅": 1.49,
        "开盘价": 13.51,
        "最高价": 13.7,
        "最低价": 13.43,
        "昨收价": 13.43,
        "总市值": 7616028558.0,
        "市盈率": 7.78,
        "成交量": 7666971.0,
        "成交额": 104237652.0,
        "振幅": 2.01,
        "换手率": 1.37,
        "symbol": "106.FHN"
    },
    {
        "序号": 1813,
        "name": "Shimmick Corp",
        "最新价": 6.14,
        "涨跌额": 0.09,
        "涨跌幅": 1.49,
        "开盘价": 6.07,
        "最高价": 6.32,
        "最低价": 5.96,
        "昨收价": 6.05,
        "总市值": 156532405.0,
        "市盈率": -7.99,
        "成交量": 24847.0,
        "成交额": 151193.0,
        "振幅": 5.95,
        "换手率": 0.1,
        "symbol": "105.SHIM"
    },
    {
        "序号": 1814,
        "name": "GrowGeneration Corp",
        "最新价": 2.73,
        "涨跌额": 0.04,
        "涨跌幅": 1.49,
        "开盘价": 2.67,
        "最高价": 2.78,
        "最低价": 2.64,
        "昨收价": 2.69,
        "总市值": 167379830.0,
        "市盈率": -4.9,
        "成交量": 566353.0,
        "成交额": 1549972.0,
        "振幅": 5.2,
        "换手率": 0.92,
        "symbol": "105.GRWG"
    },
    {
        "序号": 1815,
        "name": "Invesco Mortgage Capital Inc Se",
        "最新价": 21.2,
        "涨跌额": 0.31,
        "涨跌幅": 1.48,
        "开盘价": 20.95,
        "最高价": 21.25,
        "最低价": 20.68,
        "昨收价": 20.89,
        "总市值": 162841673.0,
        "市盈率": null,
        "成交量": 12099.0,
        "成交额": 255070.0,
        "振幅": 2.73,
        "换手率": 0.16,
        "symbol": "106.IVR_C"
    },
    {
        "序号": 1816,
        "name": "联信银行",
        "最新价": 49.97,
        "涨跌额": 0.73,
        "涨跌幅": 1.48,
        "开盘价": 49.19,
        "最高价": 50.53,
        "最低价": 49.02,
        "昨收价": 49.24,
        "总市值": 6589684416.0,
        "市盈率": 5.5,
        "成交量": 1865446.0,
        "成交额": 93044024.0,
        "振幅": 3.07,
        "换手率": 1.41,
        "symbol": "106.CMA"
    },
    {
        "序号": 1817,
        "name": "哈里伯顿",
        "最新价": 34.93,
        "涨跌额": 0.51,
        "涨跌幅": 1.48,
        "开盘价": 34.75,
        "最高价": 35.16,
        "最低价": 34.4,
        "昨收价": 34.42,
        "总市值": 31264157348.0,
        "市盈率": 11.87,
        "成交量": 6459770.0,
        "成交额": 224833183.0,
        "振幅": 2.21,
        "换手率": 0.72,
        "symbol": "106.HAL"
    },
    {
        "序号": 1818,
        "name": "T Stamp Inc-A",
        "最新价": 1.37,
        "涨跌额": 0.02,
        "涨跌幅": 1.48,
        "开盘价": 1.37,
        "最高价": 1.39,
        "最低价": 1.28,
        "昨收价": 1.35,
        "总市值": 11323757.0,
        "市盈率": -1.29,
        "成交量": 42140.0,
        "成交额": 56646.0,
        "振幅": 8.15,
        "换手率": 0.51,
        "symbol": "105.IDAI"
    },
    {
        "序号": 1819,
        "name": "Paycor HCM Inc",
        "最新价": 21.25,
        "涨跌额": 0.31,
        "涨跌幅": 1.48,
        "开盘价": 20.84,
        "最高价": 21.5,
        "最低价": 20.74,
        "昨收价": 20.94,
        "总市值": 3773764571.0,
        "市盈率": -44.52,
        "成交量": 825817.0,
        "成交额": 17567908.0,
        "振幅": 3.63,
        "换手率": 0.47,
        "symbol": "105.PYCR"
    },
    {
        "序号": 1820,
        "name": "UT斯达康",
        "最新价": 3.43,
        "涨跌额": 0.05,
        "涨跌幅": 1.48,
        "开盘价": 3.5,
        "最高价": 3.53,
        "最低价": 3.42,
        "昨收价": 3.38,
        "总市值": 31987048.0,
        "市盈率": -6.0,
        "成交量": 2924.0,
        "成交额": 10129.0,
        "振幅": 3.25,
        "换手率": 0.03,
        "symbol": "105.UTSI"
    },
    {
        "序号": 1821,
        "name": "中国绿色农业",
        "最新价": 2.06,
        "涨跌额": 0.03,
        "涨跌幅": 1.48,
        "开盘价": 2.1,
        "最高价": 2.1,
        "最低价": 2.02,
        "昨收价": 2.03,
        "总市值": 27564683.0,
        "市盈率": -1.9,
        "成交量": 1866.0,
        "成交额": 3850.0,
        "振幅": 3.94,
        "换手率": 0.01,
        "symbol": "106.CGA"
    },
    {
        "序号": 1822,
        "name": "Optical Cable Corp",
        "最新价": 2.75,
        "涨跌额": 0.04,
        "涨跌幅": 1.48,
        "开盘价": 2.71,
        "最高价": 2.78,
        "最低价": 2.71,
        "昨收价": 2.71,
        "总市值": 21707623.0,
        "市盈率": 4.8,
        "成交量": 4599.0,
        "成交额": 12569.0,
        "振幅": 2.58,
        "换手率": 0.06,
        "symbol": "105.OCC"
    },
    {
        "序号": 1823,
        "name": "National Bankshares Inc",
        "最新价": 28.9,
        "涨跌额": 0.42,
        "涨跌幅": 1.47,
        "开盘价": 28.02,
        "最高价": 29.47,
        "最低价": 28.02,
        "昨收价": 28.48,
        "总市值": 170271257.0,
        "市盈率": 8.18,
        "成交量": 11684.0,
        "成交额": 337023.0,
        "振幅": 5.09,
        "换手率": 0.2,
        "symbol": "105.NKSH"
    },
    {
        "序号": 1824,
        "name": "First Watch Restaurant Group In",
        "最新价": 19.28,
        "涨跌额": 0.28,
        "涨跌幅": 1.47,
        "开盘价": 18.92,
        "最高价": 19.28,
        "最低价": 18.83,
        "昨收价": 19.0,
        "总市值": 1153283887.0,
        "市盈率": 51.83,
        "成交量": 303418.0,
        "成交额": 5797772.0,
        "振幅": 2.37,
        "换手率": 0.51,
        "symbol": "105.FWRG"
    },
    {
        "序号": 1825,
        "name": "西维斯健康",
        "最新价": 75.1,
        "涨跌额": 1.09,
        "涨跌幅": 1.47,
        "开盘价": 74.37,
        "最高价": 75.52,
        "最低价": 73.88,
        "昨收价": 74.01,
        "总市值": 96645933308.0,
        "市盈率": 11.41,
        "成交量": 10516089.0,
        "成交额": 787266400.0,
        "振幅": 2.22,
        "换手率": 0.82,
        "symbol": "106.CVS"
    },
    {
        "序号": 1826,
        "name": "ITT Inc",
        "最新价": 113.73,
        "涨跌额": 1.65,
        "涨跌幅": 1.47,
        "开盘价": 112.08,
        "最高价": 113.92,
        "最低价": 112.08,
        "昨收价": 112.08,
        "总市值": 9337233000.0,
        "市盈率": 21.5,
        "成交量": 429811.0,
        "成交额": 48793834.0,
        "振幅": 1.64,
        "换手率": 0.52,
        "symbol": "106.ITT"
    },
    {
        "序号": 1827,
        "name": "BioNTech SE ADR",
        "最新价": 103.43,
        "涨跌额": 1.5,
        "涨跌幅": 1.47,
        "开盘价": 101.19,
        "最高价": 103.62,
        "最低价": 100.7,
        "昨收价": 101.93,
        "总市值": 24586914165.0,
        "市盈率": 7.0,
        "成交量": 458964.0,
        "成交额": 47203357.0,
        "振幅": 2.86,
        "换手率": 0.19,
        "symbol": "105.BNTX"
    },
    {
        "序号": 1828,
        "name": "Vroom Inc",
        "最新价": 0.69,
        "涨跌额": 0.01,
        "涨跌幅": 1.47,
        "开盘价": 0.68,
        "最高价": 0.71,
        "最低价": 0.68,
        "昨收价": 0.68,
        "总市值": 96440658.0,
        "市盈率": -0.48,
        "成交量": 1009410.0,
        "成交额": 701229.0,
        "振幅": 4.41,
        "换手率": 0.72,
        "symbol": "105.VRM"
    },
    {
        "序号": 1829,
        "name": "Superior Drilling Products Inc",
        "最新价": 0.69,
        "涨跌额": 0.01,
        "涨跌幅": 1.47,
        "开盘价": 0.68,
        "最高价": 0.7,
        "最低价": 0.68,
        "昨收价": 0.68,
        "总市值": 20969956.0,
        "市盈率": 9.6,
        "成交量": 35577.0,
        "成交额": 24583.0,
        "振幅": 2.94,
        "换手率": 0.12,
        "symbol": "107.SDPI"
    },
    {
        "序号": 1830,
        "name": "Granite Point Mortgage Trust In",
        "最新价": 17.25,
        "涨跌额": 0.25,
        "涨跌幅": 1.47,
        "开盘价": 17.09,
        "最高价": 17.25,
        "最低价": 16.85,
        "昨收价": 17.0,
        "总市值": 141958875.0,
        "市盈率": null,
        "成交量": 15267.0,
        "成交额": 259986.0,
        "振幅": 2.35,
        "换手率": 0.19,
        "symbol": "106.GPMT_A"
    },
    {
        "序号": 1831,
        "name": "思佳讯",
        "最新价": 103.78,
        "涨跌额": 1.5,
        "涨跌幅": 1.47,
        "开盘价": 102.39,
        "最高价": 104.81,
        "最低价": 102.22,
        "昨收价": 102.28,
        "总市值": 16600116097.0,
        "市盈率": 16.89,
        "成交量": 1734479.0,
        "成交额": 180255305.0,
        "振幅": 2.53,
        "换手率": 1.08,
        "symbol": "105.SWKS"
    },
    {
        "序号": 1832,
        "name": "杜比实验室",
        "最新价": 89.38,
        "涨跌额": 1.29,
        "涨跌幅": 1.46,
        "开盘价": 88.03,
        "最高价": 89.5,
        "最低价": 88.03,
        "昨收价": 88.09,
        "总市值": 8515769238.0,
        "市盈率": 42.44,
        "成交量": 179463.0,
        "成交额": 16004913.0,
        "振幅": 1.67,
        "换手率": 0.19,
        "symbol": "106.DLB"
    },
    {
        "序号": 1833,
        "name": "BlackLine Inc",
        "最新价": 61.07,
        "涨跌额": 0.88,
        "涨跌幅": 1.46,
        "开盘价": 59.99,
        "最高价": 61.13,
        "最低价": 59.81,
        "昨收价": 60.19,
        "总市值": 3741747419.0,
        "市盈率": 88.95,
        "成交量": 287051.0,
        "成交额": 17463493.0,
        "振幅": 2.19,
        "换手率": 0.47,
        "symbol": "105.BL"
    },
    {
        "序号": 1834,
        "name": "SunOpta Inc",
        "最新价": 4.86,
        "涨跌额": 0.07,
        "涨跌幅": 1.46,
        "开盘价": 4.93,
        "最高价": 5.0,
        "最低价": 4.76,
        "昨收价": 4.79,
        "总市值": 562069483.0,
        "市盈率": -3.46,
        "成交量": 862438.0,
        "成交额": 4179836.0,
        "振幅": 5.01,
        "换手率": 0.75,
        "symbol": "105.STKL"
    },
    {
        "序号": 1835,
        "name": "Direxion Daily South Korea Bull",
        "最新价": 7.64,
        "涨跌额": 0.11,
        "涨跌幅": 1.46,
        "开盘价": 7.6,
        "最高价": 7.77,
        "最低价": 7.49,
        "昨收价": 7.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 446545.0,
        "成交额": 3406374.0,
        "振幅": 3.72,
        "换手率": null,
        "symbol": "107.KORU"
    },
    {
        "序号": 1836,
        "name": "Corteva Inc",
        "最新价": 45.15,
        "涨跌额": 0.65,
        "涨跌幅": 1.46,
        "开盘价": 44.62,
        "最高价": 45.32,
        "最低价": 44.5,
        "昨收价": 44.5,
        "总市值": 31818514350.0,
        "市盈率": 34.1,
        "成交量": 5691408.0,
        "成交额": 256657564.0,
        "振幅": 1.84,
        "换手率": 0.81,
        "symbol": "106.CTVA"
    },
    {
        "序号": 1837,
        "name": "Fair Isaac Corp",
        "最新价": 1134.39,
        "涨跌额": 16.33,
        "涨跌幅": 1.46,
        "开盘价": 1114.12,
        "最高价": 1140.85,
        "最低价": 1112.92,
        "昨收价": 1118.06,
        "总市值": 28034811925.0,
        "市盈率": 65.29,
        "成交量": 130079.0,
        "成交额": 147270995.0,
        "振幅": 2.5,
        "换手率": 0.53,
        "symbol": "106.FICO"
    },
    {
        "序号": 1838,
        "name": "Cricut Inc-A",
        "最新价": 6.95,
        "涨跌额": 0.1,
        "涨跌幅": 1.46,
        "开盘价": 6.81,
        "最高价": 7.06,
        "最低价": 6.79,
        "昨收价": 6.85,
        "总市值": 1523210796.0,
        "市盈率": 28.61,
        "成交量": 520919.0,
        "成交额": 3586662.0,
        "振幅": 3.94,
        "换手率": 0.24,
        "symbol": "105.CRCT"
    },
    {
        "序号": 1839,
        "name": "SPDR S&P Semiconductor ETF",
        "最新价": 205.78,
        "涨跌额": 2.96,
        "涨跌幅": 1.46,
        "开盘价": 202.25,
        "最高价": 206.76,
        "最低价": 202.25,
        "昨收价": 202.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 37612.0,
        "成交额": 7729467.0,
        "振幅": 2.22,
        "换手率": null,
        "symbol": "107.XSD"
    },
    {
        "序号": 1840,
        "name": "S&T Bancorp Inc",
        "最新价": 30.6,
        "涨跌额": 0.44,
        "涨跌幅": 1.46,
        "开盘价": 30.35,
        "最高价": 30.64,
        "最低价": 30.22,
        "昨收价": 30.16,
        "总市值": 1169984788.0,
        "市盈率": 7.91,
        "成交量": 109610.0,
        "成交额": 3348729.0,
        "振幅": 1.39,
        "换手率": 0.29,
        "symbol": "105.STBA"
    },
    {
        "序号": 1841,
        "name": "Stevanato Group SpA",
        "最新价": 27.82,
        "涨跌额": 0.4,
        "涨跌幅": 1.46,
        "开盘价": 27.13,
        "最高价": 28.1,
        "最低价": 27.13,
        "昨收价": 27.42,
        "总市值": 8221923802.0,
        "市盈率": 49.73,
        "成交量": 278355.0,
        "成交额": 7763190.0,
        "振幅": 3.54,
        "换手率": 0.09,
        "symbol": "106.STVN"
    },
    {
        "序号": 1842,
        "name": "桑坦德银行巴西分行",
        "最新价": 6.26,
        "涨跌额": 0.09,
        "涨跌幅": 1.46,
        "开盘价": 6.15,
        "最高价": 6.29,
        "最低价": 6.15,
        "昨收价": 6.17,
        "总市值": 46599759260.0,
        "市盈率": 22.73,
        "成交量": 986635.0,
        "成交额": 6160621.0,
        "振幅": 2.27,
        "换手率": 0.01,
        "symbol": "106.BSBR"
    },
    {
        "序号": 1843,
        "name": "ACI环球",
        "最新价": 27.83,
        "涨跌额": 0.4,
        "涨跌幅": 1.46,
        "开盘价": 27.28,
        "最高价": 27.87,
        "最低价": 27.28,
        "昨收价": 27.43,
        "总市值": 3025748149.0,
        "市盈率": 33.95,
        "成交量": 487519.0,
        "成交额": 13524665.0,
        "振幅": 2.15,
        "换手率": 0.45,
        "symbol": "105.ACIW"
    },
    {
        "序号": 1844,
        "name": "WNS控股",
        "最新价": 61.26,
        "涨跌额": 0.88,
        "涨跌幅": 1.46,
        "开盘价": 60.08,
        "最高价": 61.68,
        "最低价": 59.49,
        "昨收价": 60.38,
        "总市值": 2910984535.0,
        "市盈率": 18.3,
        "成交量": 336102.0,
        "成交额": 20244643.0,
        "振幅": 3.63,
        "换手率": 0.71,
        "symbol": "106.WNS"
    },
    {
        "序号": 1845,
        "name": "Installed Building Products Inc",
        "最新价": 163.71,
        "涨跌额": 2.35,
        "涨跌幅": 1.46,
        "开盘价": 161.96,
        "最高价": 164.97,
        "最低价": 160.98,
        "昨收价": 161.36,
        "总市值": 4650850323.0,
        "市盈率": 18.78,
        "成交量": 190903.0,
        "成交额": 31227248.0,
        "振幅": 2.47,
        "换手率": 0.67,
        "symbol": "106.IBP"
    },
    {
        "序号": 1846,
        "name": "Edgewise Therapeutics Inc",
        "最新价": 6.97,
        "涨跌额": 0.1,
        "涨跌幅": 1.46,
        "开盘价": 6.89,
        "最高价": 7.34,
        "最低价": 6.77,
        "昨收价": 6.87,
        "总市值": 442327408.0,
        "市盈率": -4.95,
        "成交量": 302811.0,
        "成交额": 2099256.0,
        "振幅": 8.3,
        "换手率": 0.48,
        "symbol": "105.EWTX"
    },
    {
        "序号": 1847,
        "name": "KNOT Offshore Partners LP",
        "最新价": 5.58,
        "涨跌额": 0.08,
        "涨跌幅": 1.45,
        "开盘价": 5.48,
        "最高价": 5.6,
        "最低价": 5.46,
        "昨收价": 5.5,
        "总市值": 194952723.0,
        "市盈率": -9.9,
        "成交量": 46443.0,
        "成交额": 256493.0,
        "振幅": 2.55,
        "换手率": 0.13,
        "symbol": "106.KNOP"
    },
    {
        "序号": 1848,
        "name": "休闲娱乐ETF-PowerShares",
        "最新价": 40.52,
        "涨跌额": 0.58,
        "涨跌幅": 1.45,
        "开盘价": 39.98,
        "最高价": 40.66,
        "最低价": 39.98,
        "昨收价": 39.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 43015.0,
        "成交额": 1738919.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "107.PEJ"
    },
    {
        "序号": 1849,
        "name": "盖普",
        "最新价": 21.68,
        "涨跌额": 0.31,
        "涨跌幅": 1.45,
        "开盘价": 21.31,
        "最高价": 21.81,
        "最低价": 21.25,
        "昨收价": 21.37,
        "总市值": 8039666898.0,
        "市盈率": 182.72,
        "成交量": 6969238.0,
        "成交额": 150982648.0,
        "振幅": 2.62,
        "换手率": 1.88,
        "symbol": "106.GPS"
    },
    {
        "序号": 1850,
        "name": "Equinor ASA ADR",
        "最新价": 30.79,
        "涨跌额": 0.44,
        "涨跌幅": 1.45,
        "开盘价": 30.52,
        "最高价": 30.83,
        "最低价": 30.5,
        "昨收价": 30.35,
        "总市值": 92465590788.0,
        "市盈率": 3.84,
        "成交量": 1844197.0,
        "成交额": 56628423.0,
        "振幅": 1.09,
        "换手率": 0.06,
        "symbol": "106.EQNR"
    },
    {
        "序号": 1851,
        "name": "戴文能源",
        "最新价": 44.09,
        "涨跌额": 0.63,
        "涨跌幅": 1.45,
        "开盘价": 43.83,
        "最高价": 44.26,
        "最低价": 43.67,
        "昨收价": 43.46,
        "总市值": 28248463000.0,
        "市盈率": 7.44,
        "成交量": 7454733.0,
        "成交额": 327886160.0,
        "振幅": 1.36,
        "换手率": 1.16,
        "symbol": "106.DVN"
    },
    {
        "序号": 1852,
        "name": "RF Industries Ltd",
        "最新价": 2.8,
        "涨跌额": 0.04,
        "涨跌幅": 1.45,
        "开盘价": 2.77,
        "最高价": 2.8,
        "最低价": 2.76,
        "昨收价": 2.76,
        "总市值": 28811695.0,
        "市盈率": -16.23,
        "成交量": 1338.0,
        "成交额": 3708.0,
        "振幅": 1.45,
        "换手率": 0.01,
        "symbol": "105.RFIL"
    },
    {
        "序号": 1853,
        "name": "ParaZero Technologies Ltd",
        "最新价": 0.7,
        "涨跌额": 0.01,
        "涨跌幅": 1.45,
        "开盘价": 0.69,
        "最高价": 0.71,
        "最低价": 0.68,
        "昨收价": 0.69,
        "总市值": 4236693.0,
        "市盈率": -3.72,
        "成交量": 143671.0,
        "成交额": 99308.0,
        "振幅": 4.35,
        "换手率": 2.37,
        "symbol": "105.PRZO"
    },
    {
        "序号": 1854,
        "name": "BigCommerce Holdings Inc-1",
        "最新价": 9.1,
        "涨跌额": 0.13,
        "涨跌幅": 1.45,
        "开盘价": 8.93,
        "最高价": 9.1,
        "最低价": 8.81,
        "昨收价": 8.97,
        "总市值": 692417262.0,
        "市盈率": -7.33,
        "成交量": 423498.0,
        "成交额": 3823997.0,
        "振幅": 3.23,
        "换手率": 0.56,
        "symbol": "105.BIGC"
    },
    {
        "序号": 1855,
        "name": "Carlyle Group Inc Notes",
        "最新价": 18.91,
        "涨跌额": 0.27,
        "涨跌幅": 1.45,
        "开盘价": 18.73,
        "最高价": 18.93,
        "最低价": 18.57,
        "昨收价": 18.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 24709.0,
        "成交额": 464253.0,
        "振幅": 1.93,
        "换手率": null,
        "symbol": "105.CGABL"
    },
    {
        "序号": 1856,
        "name": "Amerant Bancorp Inc-A",
        "最新价": 23.12,
        "涨跌额": 0.33,
        "涨跌幅": 1.45,
        "开盘价": 22.66,
        "最高价": 23.24,
        "最低价": 22.66,
        "昨收价": 22.79,
        "总市值": 776297789.0,
        "市盈率": 10.84,
        "成交量": 54362.0,
        "成交额": 1252789.0,
        "振幅": 2.54,
        "换手率": 0.16,
        "symbol": "106.AMTB"
    },
    {
        "序号": 1857,
        "name": "Rapid7 Inc",
        "最新价": 56.75,
        "涨跌额": 0.81,
        "涨跌幅": 1.45,
        "开盘价": 55.75,
        "最高价": 56.94,
        "最低价": 55.51,
        "昨收价": 55.94,
        "总市值": 3487115718.0,
        "市盈率": -19.3,
        "成交量": 617741.0,
        "成交额": 34984729.0,
        "振幅": 2.56,
        "换手率": 1.01,
        "symbol": "105.RPD"
    },
    {
        "序号": 1858,
        "name": "ETRACS 2x Leveraged US Size Fac",
        "最新价": 16.12,
        "涨跌额": 0.23,
        "涨跌幅": 1.45,
        "开盘价": 16.09,
        "最高价": 16.12,
        "最低价": 16.09,
        "昨收价": 15.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 15499.0,
        "成交额": 249530.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.IWML"
    },
    {
        "序号": 1859,
        "name": "Everi Holdings Inc",
        "最新价": 10.52,
        "涨跌额": 0.15,
        "涨跌幅": 1.45,
        "开盘价": 10.39,
        "最高价": 10.64,
        "最低价": 10.35,
        "昨收价": 10.37,
        "总市值": 900160264.0,
        "市盈率": 8.25,
        "成交量": 1470983.0,
        "成交额": 15468371.0,
        "振幅": 2.8,
        "换手率": 1.72,
        "symbol": "106.EVRI"
    },
    {
        "序号": 1860,
        "name": "拖拉机供应",
        "最新价": 212.07,
        "涨跌额": 3.02,
        "涨跌幅": 1.44,
        "开盘价": 210.17,
        "最高价": 212.77,
        "最低价": 209.7,
        "昨收价": 209.05,
        "总市值": 22927779242.0,
        "市盈率": 20.29,
        "成交量": 1171480.0,
        "成交额": 247849308.0,
        "振幅": 1.47,
        "换手率": 1.08,
        "symbol": "105.TSCO"
    },
    {
        "序号": 1861,
        "name": "欧尼克",
        "最新价": 68.24,
        "涨跌额": 0.97,
        "涨跌幅": 1.44,
        "开盘价": 67.58,
        "最高价": 68.44,
        "最低价": 67.46,
        "昨收价": 67.27,
        "总市值": 39753262088.0,
        "市盈率": 16.18,
        "成交量": 3533360.0,
        "成交额": 240929463.0,
        "振幅": 1.46,
        "换手率": 0.61,
        "symbol": "106.OKE"
    },
    {
        "序号": 1862,
        "name": "Annaly Capital Management Inc",
        "最新价": 18.31,
        "涨跌额": 0.26,
        "涨跌幅": 1.44,
        "开盘价": 17.95,
        "最高价": 18.47,
        "最低价": 17.94,
        "昨收价": 18.05,
        "总市值": 9156647918.0,
        "市盈率": -4.3,
        "成交量": 4152035.0,
        "成交额": 76059847.0,
        "振幅": 2.94,
        "换手率": 0.83,
        "symbol": "106.NLY"
    },
    {
        "序号": 1863,
        "name": "YieldMax Innovation Option Inco",
        "最新价": 13.39,
        "涨跌额": 0.19,
        "涨跌幅": 1.44,
        "开盘价": 13.06,
        "最高价": 13.48,
        "最低价": 13.06,
        "昨收价": 13.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 115982.0,
        "成交额": 1546184.0,
        "振幅": 3.18,
        "换手率": null,
        "symbol": "107.OARK"
    },
    {
        "序号": 1864,
        "name": "M&T Bank Corp Series H Pfd",
        "最新价": 24.67,
        "涨跌额": 0.35,
        "涨跌幅": 1.44,
        "开盘价": 24.46,
        "最高价": 24.89,
        "最低价": 24.1,
        "昨收价": 24.32,
        "总市值": 246700000.0,
        "市盈率": null,
        "成交量": 26484.0,
        "成交额": 649494.0,
        "振幅": 3.25,
        "换手率": 0.26,
        "symbol": "106.MTB_H"
    },
    {
        "序号": 1865,
        "name": "AVROBIO Inc",
        "最新价": 1.41,
        "涨跌额": 0.02,
        "涨跌幅": 1.44,
        "开盘价": 1.4,
        "最高价": 1.43,
        "最低价": 1.36,
        "昨收价": 1.39,
        "总市值": 62849215.0,
        "市盈率": -15.36,
        "成交量": 124910.0,
        "成交额": 174987.0,
        "振幅": 5.04,
        "换手率": 0.28,
        "symbol": "105.AVRO"
    },
    {
        "序号": 1866,
        "name": "SPDR FactSet Innovative Technol",
        "最新价": 136.1,
        "涨跌额": 1.93,
        "涨跌幅": 1.44,
        "开盘价": 134.21,
        "最高价": 136.18,
        "最低价": 134.21,
        "昨收价": 134.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 3357.0,
        "成交额": 454583.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "107.XITK"
    },
    {
        "序号": 1867,
        "name": "ProShares Inflation Expectation",
        "最新价": 33.15,
        "涨跌额": 0.47,
        "涨跌幅": 1.44,
        "开盘价": 32.83,
        "最高价": 33.16,
        "最低价": 32.83,
        "昨收价": 32.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 9447.0,
        "成交额": 311786.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.RINF"
    },
    {
        "序号": 1868,
        "name": "Anywhere Real Estate Inc",
        "最新价": 6.35,
        "涨跌额": 0.09,
        "涨跌幅": 1.44,
        "开盘价": 6.2,
        "最高价": 6.5,
        "最低价": 6.15,
        "昨收价": 6.26,
        "总市值": 701597975.0,
        "市盈率": -1.58,
        "成交量": 1476715.0,
        "成交额": 9410425.0,
        "振幅": 5.59,
        "换手率": 1.34,
        "symbol": "106.HOUS"
    },
    {
        "序号": 1869,
        "name": "DigitalOcean Holdings Inc",
        "最新价": 31.05,
        "涨跌额": 0.44,
        "涨跌幅": 1.44,
        "开盘价": 30.28,
        "最高价": 31.24,
        "最低价": 30.11,
        "昨收价": 30.61,
        "总市值": 2670577432.0,
        "市盈率": -788.01,
        "成交量": 531130.0,
        "成交额": 16427564.0,
        "振幅": 3.69,
        "换手率": 0.62,
        "symbol": "106.DOCN"
    },
    {
        "序号": 1870,
        "name": "万机仪器",
        "最新价": 84.06,
        "涨跌额": 1.19,
        "涨跌幅": 1.44,
        "开盘价": 82.22,
        "最高价": 84.69,
        "最低价": 81.04,
        "昨收价": 82.87,
        "总市值": 5621806290.0,
        "市盈率": -3.27,
        "成交量": 757369.0,
        "成交额": 63678372.0,
        "振幅": 4.4,
        "换手率": 1.13,
        "symbol": "105.MKSI"
    },
    {
        "序号": 1871,
        "name": "铜矿ETF-Global X",
        "最新价": 35.32,
        "涨跌额": 0.5,
        "涨跌幅": 1.44,
        "开盘价": 34.82,
        "最高价": 35.45,
        "最低价": 34.65,
        "昨收价": 34.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 222272.0,
        "成交额": 7835254.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.COPX"
    },
    {
        "序号": 1872,
        "name": "A10 Networks Inc",
        "最新价": 12.74,
        "涨跌额": 0.18,
        "涨跌幅": 1.43,
        "开盘价": 12.51,
        "最高价": 12.82,
        "最低价": 12.5,
        "昨收价": 12.56,
        "总市值": 944520974.0,
        "市盈率": 23.56,
        "成交量": 955455.0,
        "成交额": 12143046.0,
        "振幅": 2.55,
        "换手率": 1.29,
        "symbol": "106.ATEN"
    },
    {
        "序号": 1873,
        "name": "齐昂银行",
        "最新价": 38.93,
        "涨跌额": 0.55,
        "涨跌幅": 1.43,
        "开盘价": 38.5,
        "最高价": 39.64,
        "最低价": 38.15,
        "昨收价": 38.38,
        "总市值": 5767432745.0,
        "市盈率": 6.88,
        "成交量": 2026913.0,
        "成交额": 79037733.0,
        "振幅": 3.88,
        "换手率": 1.37,
        "symbol": "105.ZION"
    },
    {
        "序号": 1874,
        "name": "Cal-Maine Foods Inc",
        "最新价": 51.7,
        "涨跌额": 0.73,
        "涨跌幅": 1.43,
        "开盘价": 50.5,
        "最高价": 52.07,
        "最低价": 50.5,
        "昨收价": 50.97,
        "总市值": 2532401092.0,
        "市盈率": 4.0,
        "成交量": 631791.0,
        "成交额": 32500904.0,
        "振幅": 3.08,
        "换手率": 1.29,
        "symbol": "105.CALM"
    },
    {
        "序号": 1875,
        "name": "Cenovus能源",
        "最新价": 16.29,
        "涨跌额": 0.23,
        "涨跌幅": 1.43,
        "开盘价": 16.33,
        "最高价": 16.4,
        "最低价": 16.08,
        "昨收价": 16.06,
        "总市值": 30672080144.0,
        "市盈率": 9.85,
        "成交量": 12489556.0,
        "成交额": 203102344.0,
        "振幅": 1.99,
        "换手率": 0.66,
        "symbol": "106.CVE"
    },
    {
        "序号": 1876,
        "name": "Vaxart Inc",
        "最新价": 0.71,
        "涨跌额": 0.01,
        "涨跌幅": 1.43,
        "开盘价": 0.69,
        "最高价": 0.71,
        "最低价": 0.68,
        "昨收价": 0.7,
        "总市值": 107947576.0,
        "市盈率": -1.21,
        "成交量": 449344.0,
        "成交额": 312054.0,
        "振幅": 4.29,
        "换手率": 0.3,
        "symbol": "105.VXRT"
    },
    {
        "序号": 1877,
        "name": "Reality Shares DIVCON Dividend ",
        "最新价": 38.34,
        "涨跌额": 0.54,
        "涨跌幅": 1.43,
        "开盘价": 37.7,
        "最高价": 38.82,
        "最低价": 37.7,
        "昨收价": 37.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 22051.0,
        "成交额": 839010.0,
        "振幅": 2.96,
        "换手率": null,
        "symbol": "107.DFND"
    },
    {
        "序号": 1878,
        "name": "Atlanticus Holdings Corp",
        "最新价": 31.95,
        "涨跌额": 0.45,
        "涨跌幅": 1.43,
        "开盘价": 31.25,
        "最高价": 32.79,
        "最低价": 31.25,
        "昨收价": 31.5,
        "总市值": 466071807.0,
        "市盈率": 4.64,
        "成交量": 12883.0,
        "成交额": 409148.0,
        "振幅": 4.89,
        "换手率": 0.09,
        "symbol": "105.ATLC"
    },
    {
        "序号": 1879,
        "name": "五三银行",
        "最新价": 31.25,
        "涨跌额": 0.44,
        "涨跌幅": 1.43,
        "开盘价": 30.99,
        "最高价": 31.51,
        "最低价": 30.72,
        "昨收价": 30.81,
        "总市值": 21281769250.0,
        "市盈率": 8.33,
        "成交量": 5397383.0,
        "成交额": 168575844.0,
        "振幅": 2.56,
        "换手率": 0.79,
        "symbol": "105.FITB"
    },
    {
        "序号": 1880,
        "name": "Direxion Daily Consumer Discret",
        "最新价": 31.27,
        "涨跌额": 0.44,
        "涨跌幅": 1.43,
        "开盘价": 30.5,
        "最高价": 31.42,
        "最低价": 30.29,
        "昨收价": 30.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 38639.0,
        "成交额": 1198027.0,
        "振幅": 3.67,
        "换手率": null,
        "symbol": "107.WANT"
    },
    {
        "序号": 1881,
        "name": "Carriage Services Inc",
        "最新价": 24.91,
        "涨跌额": 0.35,
        "涨跌幅": 1.43,
        "开盘价": 24.5,
        "最高价": 25.31,
        "最低价": 24.5,
        "昨收价": 24.56,
        "总市值": 373188144.0,
        "市盈率": 12.44,
        "成交量": 83916.0,
        "成交额": 2094481.0,
        "振幅": 3.3,
        "换手率": 0.56,
        "symbol": "106.CSV"
    },
    {
        "序号": 1882,
        "name": "新濠博亚娱乐",
        "最新价": 7.83,
        "涨跌额": 0.11,
        "涨跌幅": 1.42,
        "开盘价": 7.72,
        "最高价": 7.86,
        "最低价": 7.59,
        "昨收价": 7.72,
        "总市值": 3422416723.0,
        "市盈率": -9.18,
        "成交量": 1459202.0,
        "成交额": 11297363.0,
        "振幅": 3.5,
        "换手率": 0.33,
        "symbol": "105.MLCO"
    },
    {
        "序号": 1883,
        "name": "OceanFirst Financial Corp",
        "最新价": 15.67,
        "涨跌额": 0.22,
        "涨跌幅": 1.42,
        "开盘价": 15.58,
        "最高价": 15.8,
        "最低价": 15.08,
        "昨收价": 15.45,
        "总市值": 931159491.0,
        "市盈率": 7.18,
        "成交量": 220986.0,
        "成交额": 3466141.0,
        "振幅": 4.66,
        "换手率": 0.37,
        "symbol": "105.OCFC"
    },
    {
        "序号": 1884,
        "name": "iShares U.S. Oil Equipment & Se",
        "最新价": 20.68,
        "涨跌额": 0.29,
        "涨跌幅": 1.42,
        "开盘价": 20.53,
        "最高价": 20.82,
        "最低价": 20.49,
        "昨收价": 20.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 183549.0,
        "成交额": 3790502.0,
        "振幅": 1.62,
        "换手率": null,
        "symbol": "107.IEZ"
    },
    {
        "序号": 1885,
        "name": "Tri Pointe Homes Inc",
        "最新价": 31.38,
        "涨跌额": 0.44,
        "涨跌幅": 1.42,
        "开盘价": 30.78,
        "最高价": 31.47,
        "最低价": 30.78,
        "昨收价": 30.94,
        "总市值": 3045496624.0,
        "市盈率": 7.36,
        "成交量": 521238.0,
        "成交额": 16319964.0,
        "振幅": 2.23,
        "换手率": 0.54,
        "symbol": "106.TPH"
    },
    {
        "序号": 1886,
        "name": "阿波罗全球管理",
        "最新价": 92.09,
        "涨跌额": 1.29,
        "涨跌幅": 1.42,
        "开盘价": 90.68,
        "最高价": 92.27,
        "最低价": 90.54,
        "昨收价": 90.8,
        "总市值": 52266166104.0,
        "市盈率": 31.13,
        "成交量": 1715540.0,
        "成交额": 157700258.0,
        "振幅": 1.91,
        "换手率": 0.3,
        "symbol": "106.APO"
    },
    {
        "序号": 1887,
        "name": "Topgolf Callaway Brands Corp",
        "最新价": 12.85,
        "涨跌额": 0.18,
        "涨跌幅": 1.42,
        "开盘价": 12.61,
        "最高价": 12.99,
        "最低价": 12.49,
        "昨收价": 12.67,
        "总市值": 2371044067.0,
        "市盈率": 23.85,
        "成交量": 3074447.0,
        "成交额": 39333816.0,
        "振幅": 3.95,
        "换手率": 1.67,
        "symbol": "106.MODG"
    },
    {
        "序号": 1888,
        "name": "Olo Inc-A",
        "最新价": 5.72,
        "涨跌额": 0.08,
        "涨跌幅": 1.42,
        "开盘价": 5.6,
        "最高价": 5.72,
        "最低价": 5.55,
        "昨收价": 5.64,
        "总市值": 939102908.0,
        "市盈率": -18.5,
        "成交量": 922226.0,
        "成交额": 5237027.0,
        "振幅": 3.01,
        "换手率": 0.56,
        "symbol": "106.OLO"
    },
    {
        "序号": 1889,
        "name": "雪松娱乐",
        "最新价": 39.35,
        "涨跌额": 0.55,
        "涨跌幅": 1.42,
        "开盘价": 38.68,
        "最高价": 39.75,
        "最低价": 38.68,
        "昨收价": 38.8,
        "总市值": 2007449143.0,
        "市盈率": 13.67,
        "成交量": 163523.0,
        "成交额": 6443578.0,
        "振幅": 2.76,
        "换手率": 0.32,
        "symbol": "106.FUN"
    },
    {
        "序号": 1890,
        "name": "印孚瑟斯",
        "最新价": 17.89,
        "涨跌额": 0.25,
        "涨跌幅": 1.42,
        "开盘价": 17.89,
        "最高价": 17.93,
        "最低价": 17.74,
        "昨收价": 17.64,
        "总市值": 74043583866.0,
        "市盈率": 24.53,
        "成交量": 4581139.0,
        "成交额": 81804819.0,
        "振幅": 1.08,
        "换手率": 0.11,
        "symbol": "106.INFY"
    },
    {
        "序号": 1891,
        "name": "二倍做空NASDAQ生物技术ProShares",
        "最新价": 22.19,
        "涨跌额": 0.31,
        "涨跌幅": 1.42,
        "开盘价": 22.15,
        "最高价": 22.19,
        "最低价": 22.15,
        "昨收价": 21.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 915.0,
        "成交额": 20283.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.BIS"
    },
    {
        "序号": 1892,
        "name": "West Bancorporation Inc",
        "最新价": 19.33,
        "涨跌额": 0.27,
        "涨跌幅": 1.42,
        "开盘价": 18.46,
        "最高价": 19.45,
        "最低价": 18.46,
        "昨收价": 19.06,
        "总市值": 323296067.0,
        "市盈率": 11.32,
        "成交量": 15802.0,
        "成交额": 303939.0,
        "振幅": 5.19,
        "换手率": 0.09,
        "symbol": "105.WTBA"
    },
    {
        "序号": 1893,
        "name": "Insmed Inc",
        "最新价": 27.96,
        "涨跌额": 0.39,
        "涨跌幅": 1.41,
        "开盘价": 27.81,
        "最高价": 28.2,
        "最低价": 27.45,
        "昨收价": 27.57,
        "总市值": 4000015337.0,
        "市盈率": -5.53,
        "成交量": 1881967.0,
        "成交额": 52436417.0,
        "振幅": 2.72,
        "换手率": 1.32,
        "symbol": "105.INSM"
    },
    {
        "序号": 1894,
        "name": "NVR Inc",
        "最新价": 6535.89,
        "涨跌额": 91.09,
        "涨跌幅": 1.41,
        "开盘价": 6450.0,
        "最高价": 6538.36,
        "最低价": 6434.99,
        "昨收价": 6444.8,
        "总市值": 20777790387.0,
        "市盈率": 12.7,
        "成交量": 16843.0,
        "成交额": 109657650.0,
        "振幅": 1.6,
        "换手率": 0.53,
        "symbol": "106.NVR"
    },
    {
        "序号": 1895,
        "name": "埃尼石油",
        "最新价": 32.3,
        "涨跌额": 0.45,
        "涨跌幅": 1.41,
        "开盘价": 32.08,
        "最高价": 32.32,
        "最低价": 32.03,
        "昨收价": 31.85,
        "总市值": 54521396972.0,
        "市盈率": 8.25,
        "成交量": 170915.0,
        "成交额": 5509079.0,
        "振幅": 0.91,
        "换手率": 0.01,
        "symbol": "106.E"
    },
    {
        "序号": 1896,
        "name": "Applovin Corp-A",
        "最新价": 37.36,
        "涨跌额": 0.52,
        "涨跌幅": 1.41,
        "开盘价": 36.53,
        "最高价": 37.62,
        "最低价": 36.53,
        "昨收价": 36.84,
        "总市值": 12545546730.0,
        "市盈率": 119.52,
        "成交量": 1631409.0,
        "成交额": 60795287.0,
        "振幅": 2.96,
        "换手率": 0.49,
        "symbol": "105.APP"
    },
    {
        "序号": 1897,
        "name": "ProShares Ultra MSCI Brazil Cap",
        "最新价": 30.19,
        "涨跌额": 0.42,
        "涨跌幅": 1.41,
        "开盘价": 29.8,
        "最高价": 30.2,
        "最低价": 29.8,
        "昨收价": 29.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 1784.0,
        "成交额": 53503.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "107.UBR"
    },
    {
        "序号": 1898,
        "name": "Brighthouse Financial Inc",
        "最新价": 53.23,
        "涨跌额": 0.74,
        "涨跌幅": 1.41,
        "开盘价": 52.31,
        "最高价": 53.48,
        "最低价": 52.31,
        "昨收价": 52.49,
        "总市值": 3417621398.0,
        "市盈率": -0.87,
        "成交量": 222364.0,
        "成交额": 11810563.0,
        "振幅": 2.23,
        "换手率": 0.35,
        "symbol": "105.BHF"
    },
    {
        "序号": 1899,
        "name": "HCA Healthcare Inc",
        "最新价": 257.53,
        "涨跌额": 3.58,
        "涨跌幅": 1.41,
        "开盘价": 254.24,
        "最高价": 258.15,
        "最低价": 253.27,
        "昨收价": 253.95,
        "总市值": 68930685824.0,
        "市盈率": 12.06,
        "成交量": 1552543.0,
        "成交额": 399058624.0,
        "振幅": 1.92,
        "换手率": 0.58,
        "symbol": "106.HCA"
    },
    {
        "序号": 1900,
        "name": "Triumph Group Inc",
        "最新价": 11.51,
        "涨跌额": 0.16,
        "涨跌幅": 1.41,
        "开盘价": 11.33,
        "最高价": 11.67,
        "最低价": 11.33,
        "昨收价": 11.35,
        "总市值": 884611881.0,
        "市盈率": -33.96,
        "成交量": 635206.0,
        "成交额": 7298095.0,
        "振幅": 3.0,
        "换手率": 0.83,
        "symbol": "106.TGI"
    },
    {
        "序号": 1901,
        "name": "Pearl Holdings Acquisition Corp",
        "最新价": 10.8,
        "涨跌额": 0.15,
        "涨跌幅": 1.41,
        "开盘价": 10.79,
        "最高价": 10.83,
        "最低价": 10.79,
        "昨收价": 10.65,
        "总市值": 270000000.0,
        "市盈率": 32.6,
        "成交量": 871.0,
        "成交额": 9406.0,
        "振幅": 0.38,
        "换手率": 0.0,
        "symbol": "105.PRLH"
    },
    {
        "序号": 1902,
        "name": "骏利亨德森集团",
        "最新价": 27.36,
        "涨跌额": 0.38,
        "涨跌幅": 1.41,
        "开盘价": 26.87,
        "最高价": 27.44,
        "最低价": 26.87,
        "昨收价": 26.98,
        "总市值": 4532400281.0,
        "市盈率": 13.5,
        "成交量": 765720.0,
        "成交额": 20903817.0,
        "振幅": 2.11,
        "换手率": 0.46,
        "symbol": "106.JHG"
    },
    {
        "序号": 1903,
        "name": "二倍做多巴西ETF-Direxion",
        "最新价": 92.21,
        "涨跌额": 1.28,
        "涨跌幅": 1.41,
        "开盘价": 90.38,
        "最高价": 93.22,
        "最低价": 90.38,
        "昨收价": 90.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 10915.0,
        "成交额": 1004771.0,
        "振幅": 3.12,
        "换手率": null,
        "symbol": "107.BRZU"
    },
    {
        "序号": 1904,
        "name": "Etsy Inc",
        "最新价": 80.08,
        "涨跌额": 1.11,
        "涨跌幅": 1.41,
        "开盘价": 78.97,
        "最高价": 81.24,
        "最低价": 78.91,
        "昨收价": 78.97,
        "总市值": 9589280020.0,
        "市盈率": 28.72,
        "成交量": 4548232.0,
        "成交额": 364677264.0,
        "振幅": 2.95,
        "换手率": 3.8,
        "symbol": "105.ETSY"
    },
    {
        "序号": 1905,
        "name": "赫兹租车",
        "最新价": 9.38,
        "涨跌额": 0.13,
        "涨跌幅": 1.41,
        "开盘价": 9.22,
        "最高价": 9.55,
        "最低价": 9.12,
        "昨收价": 9.25,
        "总市值": 2887283736.0,
        "市盈率": 2.67,
        "成交量": 6249483.0,
        "成交额": 58687648.0,
        "振幅": 4.65,
        "换手率": 2.03,
        "symbol": "105.HTZ"
    },
    {
        "序号": 1906,
        "name": "PIMCO Strategic Income Fund",
        "最新价": 5.78,
        "涨跌额": 0.08,
        "涨跌幅": 1.4,
        "开盘价": 5.69,
        "最高价": 5.82,
        "最低价": 5.67,
        "昨收价": 5.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 96528.0,
        "成交额": 556386.0,
        "振幅": 2.63,
        "换手率": null,
        "symbol": "106.RCS"
    },
    {
        "序号": 1907,
        "name": "The Perficient Inc",
        "最新价": 64.45,
        "涨跌额": 0.89,
        "涨跌幅": 1.4,
        "开盘价": 63.13,
        "最高价": 64.58,
        "最低价": 63.07,
        "昨收价": 63.56,
        "总市值": 2241030716.0,
        "市盈率": 21.92,
        "成交量": 126511.0,
        "成交额": 8124080.0,
        "振幅": 2.38,
        "换手率": 0.36,
        "symbol": "105.PRFT"
    },
    {
        "序号": 1908,
        "name": "Trupanion Inc",
        "最新价": 28.99,
        "涨跌额": 0.4,
        "涨跌幅": 1.4,
        "开盘价": 28.22,
        "最高价": 29.29,
        "最低价": 28.22,
        "昨收价": 28.59,
        "总市值": 1204394710.0,
        "市盈率": -23.24,
        "成交量": 360765.0,
        "成交额": 10450327.0,
        "振幅": 3.74,
        "换手率": 0.87,
        "symbol": "105.TRUP"
    },
    {
        "序号": 1909,
        "name": "海洋石油信托",
        "最新价": 4.35,
        "涨跌额": 0.06,
        "涨跌幅": 1.4,
        "开盘价": 4.32,
        "最高价": 4.49,
        "最低价": 4.3,
        "昨收价": 4.29,
        "总市值": 8700000.0,
        "市盈率": 8.05,
        "成交量": 4478.0,
        "成交额": 19800.0,
        "振幅": 4.43,
        "换手率": 0.22,
        "symbol": "105.MARPS"
    },
    {
        "序号": 1910,
        "name": "Roundhill BIG Bank ETF",
        "最新价": 29.03,
        "涨跌额": 0.4,
        "涨跌幅": 1.4,
        "开盘价": 28.82,
        "最高价": 29.08,
        "最低价": 28.82,
        "昨收价": 28.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 4012.0,
        "成交额": 116105.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "105.BIGB"
    },
    {
        "序号": 1911,
        "name": "布埃纳文图拉开采",
        "最新价": 9.44,
        "涨跌额": 0.13,
        "涨跌幅": 1.4,
        "开盘价": 9.18,
        "最高价": 9.51,
        "最低价": 9.18,
        "昨收价": 9.31,
        "总市值": 2395071394.0,
        "市盈率": 26.04,
        "成交量": 1736655.0,
        "成交额": 16333877.0,
        "振幅": 3.54,
        "换手率": 0.68,
        "symbol": "106.BVN"
    },
    {
        "序号": 1912,
        "name": "国泰万通金控",
        "最新价": 39.94,
        "涨跌额": 0.55,
        "涨跌幅": 1.4,
        "开盘价": 39.38,
        "最高价": 40.04,
        "最低价": 39.07,
        "昨收价": 39.39,
        "总市值": 2901595588.0,
        "市盈率": 7.86,
        "成交量": 240149.0,
        "成交额": 9582006.0,
        "振幅": 2.46,
        "换手率": 0.33,
        "symbol": "105.CATY"
    },
    {
        "序号": 1913,
        "name": "KraneShares Global Luxury Index",
        "最新价": 24.71,
        "涨跌额": 0.34,
        "涨跌幅": 1.4,
        "开盘价": 24.71,
        "最高价": 24.71,
        "最低价": 24.71,
        "昨收价": 24.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 74.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KLXY"
    },
    {
        "序号": 1914,
        "name": "Fusion Pharmaceuticals Inc",
        "最新价": 5.82,
        "涨跌额": 0.08,
        "涨跌幅": 1.39,
        "开盘价": 5.77,
        "最高价": 5.93,
        "最低价": 5.68,
        "昨收价": 5.74,
        "总市值": 421401989.0,
        "市盈率": -4.61,
        "成交量": 497550.0,
        "成交额": 2876447.0,
        "振幅": 4.36,
        "换手率": 0.69,
        "symbol": "105.FUSN"
    },
    {
        "序号": 1915,
        "name": "Cactus Inc-A",
        "最新价": 41.49,
        "涨跌额": 0.57,
        "涨跌幅": 1.39,
        "开盘价": 41.19,
        "最高价": 41.94,
        "最低价": 41.18,
        "昨收价": 40.92,
        "总市值": 3295534021.0,
        "市盈率": 21.79,
        "成交量": 781676.0,
        "成交额": 32487440.0,
        "振幅": 1.86,
        "换手率": 0.98,
        "symbol": "106.WHD"
    },
    {
        "序号": 1916,
        "name": "Aura Biosciences Inc",
        "最新价": 8.75,
        "涨跌额": 0.12,
        "涨跌幅": 1.39,
        "开盘价": 8.55,
        "最高价": 8.83,
        "最低价": 8.53,
        "昨收价": 8.63,
        "总市值": 430474608.0,
        "市盈率": -6.08,
        "成交量": 182517.0,
        "成交额": 1591539.0,
        "振幅": 3.48,
        "换手率": 0.37,
        "symbol": "105.AURA"
    },
    {
        "序号": 1917,
        "name": "Par Pacific Holdings Inc",
        "最新价": 33.55,
        "涨跌额": 0.46,
        "涨跌幅": 1.39,
        "开盘价": 33.38,
        "最高价": 33.89,
        "最低价": 33.24,
        "昨收价": 33.09,
        "总市值": 2029764197.0,
        "市盈率": 3.87,
        "成交量": 466209.0,
        "成交额": 15642041.0,
        "振幅": 1.96,
        "换手率": 0.77,
        "symbol": "106.PARR"
    },
    {
        "序号": 1918,
        "name": "Reading International Inc-B",
        "最新价": 13.13,
        "涨跌额": 0.18,
        "涨跌幅": 1.39,
        "开盘价": 13.07,
        "最高价": 13.16,
        "最低价": 13.0,
        "昨收价": 12.95,
        "总市值": 292450057.0,
        "市盈率": -9.28,
        "成交量": 1594.0,
        "成交额": 20867.0,
        "振幅": 1.24,
        "换手率": 0.09,
        "symbol": "105.RDIB"
    },
    {
        "序号": 1919,
        "name": "Southern Missouri Bancorp Inc",
        "最新价": 47.44,
        "涨跌额": 0.65,
        "涨跌幅": 1.39,
        "开盘价": 46.6,
        "最高价": 47.9,
        "最低价": 46.6,
        "昨收价": 46.79,
        "总市值": 537801757.0,
        "市盈率": 12.57,
        "成交量": 15536.0,
        "成交额": 737697.0,
        "振幅": 2.78,
        "换手率": 0.14,
        "symbol": "105.SMBC"
    },
    {
        "序号": 1920,
        "name": "Sonim Technologies Inc",
        "最新价": 0.73,
        "涨跌额": 0.01,
        "涨跌幅": 1.39,
        "开盘价": 0.74,
        "最高价": 0.74,
        "最低价": 0.71,
        "昨收价": 0.72,
        "总市值": 31439226.0,
        "市盈率": 142.26,
        "成交量": 82612.0,
        "成交额": 59527.0,
        "振幅": 4.17,
        "换手率": 0.19,
        "symbol": "105.SONM"
    },
    {
        "序号": 1921,
        "name": "Insight Enterprises Inc",
        "最新价": 172.37,
        "涨跌额": 2.36,
        "涨跌幅": 1.39,
        "开盘价": 170.84,
        "最高价": 174.01,
        "最低价": 170.37,
        "昨收价": 170.01,
        "总市值": 5615268359.0,
        "市盈率": 20.94,
        "成交量": 186656.0,
        "成交额": 32160056.0,
        "振幅": 2.14,
        "换手率": 0.57,
        "symbol": "105.NSIT"
    },
    {
        "序号": 1922,
        "name": "Virtus Equity & Convertible Inc",
        "最新价": 20.46,
        "涨跌额": 0.28,
        "涨跌幅": 1.39,
        "开盘价": 20.2,
        "最高价": 20.5,
        "最低价": 20.1,
        "昨收价": 20.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 91807.0,
        "成交额": 1868774.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "106.NIE"
    },
    {
        "序号": 1923,
        "name": "Cars.com",
        "最新价": 19.0,
        "涨跌额": 0.26,
        "涨跌幅": 1.39,
        "开盘价": 18.65,
        "最高价": 19.1,
        "最低价": 18.65,
        "昨收价": 18.74,
        "总市值": 1258358809.0,
        "市盈率": 10.46,
        "成交量": 366788.0,
        "成交额": 6971727.0,
        "振幅": 2.4,
        "换手率": 0.55,
        "symbol": "106.CARS"
    },
    {
        "序号": 1924,
        "name": "Golden Ocean Group Ltd",
        "最新价": 8.77,
        "涨跌额": 0.12,
        "涨跌幅": 1.39,
        "开盘价": 8.74,
        "最高价": 8.78,
        "最低价": 8.67,
        "昨收价": 8.65,
        "总市值": 1750740130.0,
        "市盈率": 14.23,
        "成交量": 1175830.0,
        "成交额": 10276368.0,
        "振幅": 1.27,
        "换手率": 0.59,
        "symbol": "105.GOGL"
    },
    {
        "序号": 1925,
        "name": "帕克航空航天",
        "最新价": 15.36,
        "涨跌额": 0.21,
        "涨跌幅": 1.39,
        "开盘价": 15.2,
        "最高价": 15.4,
        "最低价": 14.95,
        "昨收价": 15.15,
        "总市值": 311041705.0,
        "市盈率": 29.52,
        "成交量": 33234.0,
        "成交额": 507930.0,
        "振幅": 2.97,
        "换手率": 0.16,
        "symbol": "106.PKE"
    },
    {
        "序号": 1926,
        "name": "美国黄金",
        "最新价": 3.66,
        "涨跌额": 0.05,
        "涨跌幅": 1.39,
        "开盘价": 3.62,
        "最高价": 3.69,
        "最低价": 3.55,
        "昨收价": 3.61,
        "总市值": 34022763.0,
        "市盈率": -3.97,
        "成交量": 18076.0,
        "成交额": 65799.0,
        "振幅": 3.88,
        "换手率": 0.19,
        "symbol": "105.USAU"
    },
    {
        "序号": 1927,
        "name": "太平洋第一合众银行",
        "最新价": 26.37,
        "涨跌额": 0.36,
        "涨跌幅": 1.38,
        "开盘价": 26.19,
        "最高价": 26.46,
        "最低价": 25.82,
        "昨收价": 26.01,
        "总市值": 2528882262.0,
        "市盈率": 10.54,
        "成交量": 640955.0,
        "成交额": 16857095.0,
        "振幅": 2.46,
        "换手率": 0.67,
        "symbol": "105.PPBI"
    },
    {
        "序号": 1928,
        "name": "DraftKings Inc-A",
        "最新价": 35.9,
        "涨跌额": 0.49,
        "涨跌幅": 1.38,
        "开盘价": 35.05,
        "最高价": 36.3,
        "最低价": 34.85,
        "昨收价": 35.41,
        "总市值": 30847286236.0,
        "市盈率": -30.84,
        "成交量": 9739857.0,
        "成交额": 349110288.0,
        "振幅": 4.09,
        "换手率": 1.13,
        "symbol": "105.DKNG"
    },
    {
        "序号": 1929,
        "name": "Farmers & Merchants Bancorp Inc",
        "最新价": 21.98,
        "涨跌额": 0.3,
        "涨跌幅": 1.38,
        "开盘价": 21.99,
        "最高价": 22.45,
        "最低价": 21.76,
        "昨收价": 21.68,
        "总市值": 300382680.0,
        "市盈率": 12.29,
        "成交量": 16397.0,
        "成交额": 362770.0,
        "振幅": 3.18,
        "换手率": 0.12,
        "symbol": "105.FMAO"
    },
    {
        "序号": 1930,
        "name": "戴康工业",
        "最新价": 108.55,
        "涨跌额": 1.48,
        "涨跌幅": 1.38,
        "开盘价": 106.97,
        "最高价": 109.01,
        "最低价": 106.5,
        "昨收价": 107.07,
        "总市值": 3184676481.0,
        "市盈率": 14.46,
        "成交量": 187966.0,
        "成交额": 20387297.0,
        "振幅": 2.34,
        "换手率": 0.64,
        "symbol": "106.DY"
    },
    {
        "序号": 1931,
        "name": "太平洋煤气电力",
        "最新价": 17.61,
        "涨跌额": 0.24,
        "涨跌幅": 1.38,
        "开盘价": 17.37,
        "最高价": 17.63,
        "最低价": 17.21,
        "昨收价": 17.37,
        "总市值": 45984143687.0,
        "市盈率": 24.86,
        "成交量": 13305916.0,
        "成交额": 232063578.0,
        "振幅": 2.42,
        "换手率": 0.51,
        "symbol": "106.PCG"
    },
    {
        "序号": 1932,
        "name": "IRSA Inversiones y Representaci",
        "最新价": 8.81,
        "涨跌额": 0.12,
        "涨跌幅": 1.38,
        "开盘价": 8.7,
        "最高价": 8.86,
        "最低价": 8.42,
        "昨收价": 8.69,
        "总市值": 660486593.0,
        "市盈率": 1.24,
        "成交量": 103872.0,
        "成交额": 901471.0,
        "振幅": 5.06,
        "换手率": 0.14,
        "symbol": "106.IRS"
    },
    {
        "序号": 1933,
        "name": "休伦咨询",
        "最新价": 105.05,
        "涨跌额": 1.43,
        "涨跌幅": 1.38,
        "开盘价": 103.82,
        "最高价": 105.85,
        "最低价": 103.82,
        "昨收价": 103.62,
        "总市值": 1969463218.0,
        "市盈率": 25.67,
        "成交量": 86010.0,
        "成交额": 9034732.0,
        "振幅": 1.96,
        "换手率": 0.46,
        "symbol": "105.HURN"
    },
    {
        "序号": 1934,
        "name": "Beyond Air Inc",
        "最新价": 1.47,
        "涨跌额": 0.02,
        "涨跌幅": 1.38,
        "开盘价": 1.44,
        "最高价": 1.48,
        "最低价": 1.4,
        "昨收价": 1.45,
        "总市值": 46999478.0,
        "市盈率": -0.74,
        "成交量": 233988.0,
        "成交额": 337455.0,
        "振幅": 5.52,
        "换手率": 0.73,
        "symbol": "105.XAIR"
    },
    {
        "序号": 1935,
        "name": "DermTech Inc",
        "最新价": 1.47,
        "涨跌额": 0.02,
        "涨跌幅": 1.38,
        "开盘价": 1.41,
        "最高价": 1.5,
        "最低价": 1.38,
        "昨收价": 1.45,
        "总市值": 50337242.0,
        "市盈率": -0.46,
        "成交量": 5135601.0,
        "成交额": 7428517.0,
        "振幅": 8.28,
        "换手率": 15.0,
        "symbol": "105.DMTK"
    },
    {
        "序号": 1936,
        "name": "Roundhill Sports Betting & iGam",
        "最新价": 16.19,
        "涨跌额": 0.22,
        "涨跌幅": 1.38,
        "开盘价": 16.08,
        "最高价": 16.26,
        "最低价": 16.08,
        "昨收价": 15.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 14076.0,
        "成交额": 227996.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.BETZ"
    },
    {
        "序号": 1937,
        "name": "FLEX LNG Ltd",
        "最新价": 28.71,
        "涨跌额": 0.39,
        "涨跌幅": 1.38,
        "开盘价": 28.42,
        "最高价": 28.73,
        "最低价": 28.42,
        "昨收价": 28.32,
        "总市值": 1542769690.0,
        "市盈率": 10.86,
        "成交量": 216361.0,
        "成交额": 6193428.0,
        "振幅": 1.09,
        "换手率": 0.4,
        "symbol": "106.FLNG"
    },
    {
        "序号": 1938,
        "name": "STRATS Trust for Allstate Corp ",
        "最新价": 22.1,
        "涨跌额": 0.3,
        "涨跌幅": 1.38,
        "开盘价": 21.95,
        "最高价": 22.13,
        "最低价": 21.95,
        "昨收价": 21.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 7815.0,
        "成交额": 172629.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "106.GJT"
    },
    {
        "序号": 1939,
        "name": "壳牌",
        "最新价": 64.14,
        "涨跌额": 0.87,
        "涨跌幅": 1.38,
        "开盘价": 63.58,
        "最高价": 64.28,
        "最低价": 63.53,
        "昨收价": 63.27,
        "总市值": 213141561605.0,
        "市盈率": 7.28,
        "成交量": 5831511.0,
        "成交额": 373355856.0,
        "振幅": 1.19,
        "换手率": 0.18,
        "symbol": "106.SHEL"
    },
    {
        "序号": 1940,
        "name": "阿默普莱斯金融",
        "最新价": 357.58,
        "涨跌额": 4.85,
        "涨跌幅": 1.37,
        "开盘价": 352.32,
        "最高价": 358.26,
        "最低价": 352.32,
        "昨收价": 352.73,
        "总市值": 36185734335.0,
        "市盈率": 16.17,
        "成交量": 418062.0,
        "成交额": 149108060.0,
        "振幅": 1.68,
        "换手率": 0.41,
        "symbol": "106.AMP"
    },
    {
        "序号": 1941,
        "name": "二倍做空天然气ETF-ProShares",
        "最新价": 96.84,
        "涨跌额": 1.31,
        "涨跌幅": 1.37,
        "开盘价": 95.21,
        "最高价": 98.0,
        "最低价": 92.89,
        "昨收价": 95.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 763519.0,
        "成交额": 72989768.0,
        "振幅": 5.35,
        "换手率": null,
        "symbol": "107.KOLD"
    },
    {
        "序号": 1942,
        "name": "Nextracker Inc-A",
        "最新价": 39.92,
        "涨跌额": 0.54,
        "涨跌幅": 1.37,
        "开盘价": 39.35,
        "最高价": 39.93,
        "最低价": 38.25,
        "昨收价": 39.38,
        "总市值": 5775203845.0,
        "市盈率": 45.35,
        "成交量": 1610307.0,
        "成交额": 63214568.0,
        "振幅": 4.27,
        "换手率": 1.11,
        "symbol": "105.NXT"
    },
    {
        "序号": 1943,
        "name": "Curo集团控股",
        "最新价": 0.74,
        "涨跌额": 0.01,
        "涨跌幅": 1.37,
        "开盘价": 0.72,
        "最高价": 0.8,
        "最低价": 0.72,
        "昨收价": 0.73,
        "总市值": 30562401.0,
        "市盈率": -0.07,
        "成交量": 33786.0,
        "成交额": 25441.0,
        "振幅": 10.96,
        "换手率": 0.08,
        "symbol": "106.CURO"
    },
    {
        "序号": 1944,
        "name": "Remitly Global Inc",
        "最新价": 19.27,
        "涨跌额": 0.26,
        "涨跌幅": 1.37,
        "开盘价": 18.81,
        "最高价": 19.49,
        "最低价": 18.68,
        "昨收价": 19.01,
        "总市值": 3567989669.0,
        "市盈率": -34.91,
        "成交量": 1729918.0,
        "成交额": 33146162.0,
        "振幅": 4.26,
        "换手率": 0.93,
        "symbol": "105.RELY"
    },
    {
        "序号": 1945,
        "name": "Invesco DWA Energy Momentum ETF",
        "最新价": 42.32,
        "涨跌额": 0.57,
        "涨跌幅": 1.37,
        "开盘价": 42.52,
        "最高价": 42.54,
        "最低价": 42.1,
        "昨收价": 41.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 7483.0,
        "成交额": 316908.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "105.PXI"
    },
    {
        "序号": 1946,
        "name": "V2X Inc",
        "最新价": 43.07,
        "涨跌额": 0.58,
        "涨跌幅": 1.37,
        "开盘价": 42.56,
        "最高价": 43.21,
        "最低价": 42.47,
        "昨收价": 42.49,
        "总市值": 1343416268.0,
        "市盈率": -41.08,
        "成交量": 75720.0,
        "成交额": 3252255.0,
        "振幅": 1.74,
        "换手率": 0.24,
        "symbol": "106.VVX"
    },
    {
        "序号": 1947,
        "name": "United-Guardian Inc",
        "最新价": 8.17,
        "涨跌额": 0.11,
        "涨跌幅": 1.36,
        "开盘价": 8.03,
        "最高价": 8.51,
        "最低价": 8.03,
        "昨收价": 8.06,
        "总市值": 37535586.0,
        "市盈率": 14.88,
        "成交量": 13133.0,
        "成交额": 108612.0,
        "振幅": 5.96,
        "换手率": 0.29,
        "symbol": "105.UG"
    },
    {
        "序号": 1948,
        "name": "威德福国际",
        "最新价": 86.19,
        "涨跌额": 1.16,
        "涨跌幅": 1.36,
        "开盘价": 85.4,
        "最高价": 87.11,
        "最低价": 85.4,
        "昨收价": 85.03,
        "总市值": 6215901703.0,
        "市盈率": 17.81,
        "成交量": 406755.0,
        "成交额": 35008797.0,
        "振幅": 2.01,
        "换手率": 0.56,
        "symbol": "105.WFRD"
    },
    {
        "序号": 1949,
        "name": "第一银行(密西西比州)",
        "最新价": 27.5,
        "涨跌额": 0.37,
        "涨跌幅": 1.36,
        "开盘价": 27.3,
        "最高价": 27.83,
        "最低价": 27.3,
        "昨收价": 27.13,
        "总市值": 854981380.0,
        "市盈率": 10.59,
        "成交量": 115715.0,
        "成交额": 3187403.0,
        "振幅": 1.95,
        "换手率": 0.37,
        "symbol": "105.FBMS"
    },
    {
        "序号": 1950,
        "name": "ChampionX Corp",
        "最新价": 29.16,
        "涨跌额": 0.39,
        "涨跌幅": 1.36,
        "开盘价": 28.98,
        "最高价": 29.48,
        "最低价": 28.97,
        "昨收价": 28.77,
        "总市值": 5680368000.0,
        "市盈率": 18.63,
        "成交量": 1152608.0,
        "成交额": 33631740.0,
        "振幅": 1.77,
        "换手率": 0.59,
        "symbol": "105.CHX"
    },
    {
        "序号": 1951,
        "name": "PagSeguro Digital Ltd-A",
        "最新价": 10.47,
        "涨跌额": 0.14,
        "涨跌幅": 1.36,
        "开盘价": 10.36,
        "最高价": 10.54,
        "最低价": 10.27,
        "昨收价": 10.33,
        "总市值": 3450998126.0,
        "市盈率": 10.87,
        "成交量": 3836562.0,
        "成交额": 40129174.0,
        "振幅": 2.61,
        "换手率": 1.16,
        "symbol": "106.PAGS"
    },
    {
        "序号": 1952,
        "name": "Inspired Entertainment Inc",
        "最新价": 8.23,
        "涨跌额": 0.11,
        "涨跌幅": 1.35,
        "开盘价": 8.15,
        "最高价": 8.3,
        "最低价": 8.13,
        "昨收价": 8.12,
        "总市值": 216750103.0,
        "市盈率": 12.18,
        "成交量": 91312.0,
        "成交额": 752352.0,
        "振幅": 2.09,
        "换手率": 0.35,
        "symbol": "105.INSE"
    },
    {
        "序号": 1953,
        "name": "Invesco KBW Bank ETF",
        "最新价": 45.69,
        "涨跌额": 0.61,
        "涨跌幅": 1.35,
        "开盘价": 45.14,
        "最高价": 45.85,
        "最低价": 44.99,
        "昨收价": 45.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1732575.0,
        "成交额": 78875772.0,
        "振幅": 1.91,
        "换手率": null,
        "symbol": "105.KBWB"
    },
    {
        "序号": 1954,
        "name": "阿维亚网络",
        "最新价": 32.22,
        "涨跌额": 0.43,
        "涨跌幅": 1.35,
        "开盘价": 31.64,
        "最高价": 32.35,
        "最低价": 31.52,
        "昨收价": 31.79,
        "总市值": 377619914.0,
        "市盈率": 20.66,
        "成交量": 79607.0,
        "成交额": 2551484.0,
        "振幅": 2.61,
        "换手率": 0.68,
        "symbol": "105.AVNW"
    },
    {
        "序号": 1955,
        "name": "ProShares UltraShort Consumer S",
        "最新价": 16.49,
        "涨跌额": 0.22,
        "涨跌幅": 1.35,
        "开盘价": 16.4,
        "最高价": 16.52,
        "最低价": 16.34,
        "昨收价": 16.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 5021.0,
        "成交额": 82550.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.SZK"
    },
    {
        "序号": 1956,
        "name": "PureCycle Technologies Inc Wt",
        "最新价": 1.5,
        "涨跌额": 0.02,
        "涨跌幅": 1.35,
        "开盘价": 1.58,
        "最高价": 1.61,
        "最低价": 1.4,
        "昨收价": 1.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 85470.0,
        "成交额": 129848.0,
        "振幅": 14.19,
        "换手率": null,
        "symbol": "105.PCTTW"
    },
    {
        "序号": 1957,
        "name": "Atossa Therapeutics Inc",
        "最新价": 0.75,
        "涨跌额": 0.01,
        "涨跌幅": 1.35,
        "开盘价": 0.74,
        "最高价": 0.77,
        "最低价": 0.72,
        "昨收价": 0.74,
        "总市值": 93978048.0,
        "市盈率": -3.15,
        "成交量": 191714.0,
        "成交额": 142647.0,
        "振幅": 6.76,
        "换手率": 0.15,
        "symbol": "105.ATOS"
    },
    {
        "序号": 1958,
        "name": "Invesco Dynamic Building & Cons",
        "最新价": 58.51,
        "涨跌额": 0.78,
        "涨跌幅": 1.35,
        "开盘价": 57.66,
        "最高价": 58.57,
        "最低价": 57.66,
        "昨收价": 57.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 16682.0,
        "成交额": 971436.0,
        "振幅": 1.58,
        "换手率": null,
        "symbol": "107.PKB"
    },
    {
        "序号": 1959,
        "name": "油服ETF-VanEck Vectors",
        "最新价": 297.08,
        "涨跌额": 3.96,
        "涨跌幅": 1.35,
        "开盘价": 295.39,
        "最高价": 299.67,
        "最低价": 294.51,
        "昨收价": 293.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 647846.0,
        "成交额": 192105713.0,
        "振幅": 1.76,
        "换手率": null,
        "symbol": "107.OIH"
    },
    {
        "序号": 1960,
        "name": "Sprinklr Inc-A",
        "最新价": 11.26,
        "涨跌额": 0.15,
        "涨跌幅": 1.35,
        "开盘价": 11.25,
        "最高价": 11.55,
        "最低价": 11.01,
        "昨收价": 11.11,
        "总市值": 3084006501.0,
        "市盈率": 104.21,
        "成交量": 7165939.0,
        "成交额": 81157027.0,
        "振幅": 4.86,
        "换手率": 2.62,
        "symbol": "106.CXM"
    },
    {
        "序号": 1961,
        "name": "Invesco S&P 500 Equal Weight Co",
        "最新价": 28.53,
        "涨跌额": 0.38,
        "涨跌幅": 1.35,
        "开盘价": 28.61,
        "最高价": 28.61,
        "最低价": 28.43,
        "昨收价": 28.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3806.0,
        "成交额": 108586.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.RSPC"
    },
    {
        "序号": 1962,
        "name": "爱德华兹生命科学",
        "最新价": 69.18,
        "涨跌额": 0.92,
        "涨跌幅": 1.35,
        "开盘价": 68.63,
        "最高价": 69.97,
        "最低价": 68.0,
        "昨收价": 68.26,
        "总市值": 41957670000.0,
        "市盈率": 29.32,
        "成交量": 5901812.0,
        "成交额": 407737648.0,
        "振幅": 2.89,
        "换手率": 0.97,
        "symbol": "106.EW"
    },
    {
        "序号": 1963,
        "name": "蒙特利尔银行",
        "最新价": 87.23,
        "涨跌额": 1.16,
        "涨跌幅": 1.35,
        "开盘价": 86.28,
        "最高价": 87.51,
        "最低价": 86.08,
        "昨收价": 86.07,
        "总市值": 62884906114.0,
        "市盈率": 19.82,
        "成交量": 796421.0,
        "成交额": 69372983.0,
        "振幅": 1.66,
        "换手率": 0.11,
        "symbol": "106.BMO"
    },
    {
        "序号": 1964,
        "name": "Custom Truck One Source Inc",
        "最新价": 6.02,
        "涨跌额": 0.08,
        "涨跌幅": 1.35,
        "开盘价": 5.95,
        "最高价": 6.1,
        "最低价": 5.95,
        "昨收价": 5.94,
        "总市值": 1462139689.0,
        "市盈率": 22.31,
        "成交量": 416617.0,
        "成交额": 2505136.0,
        "振幅": 2.53,
        "换手率": 0.17,
        "symbol": "106.CTOS"
    },
    {
        "序号": 1965,
        "name": "三倍做空新兴市场ETF-Direxion",
        "最新价": 12.8,
        "涨跌额": 0.17,
        "涨跌幅": 1.35,
        "开盘价": 12.85,
        "最高价": 12.95,
        "最低价": 12.68,
        "昨收价": 12.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 240088.0,
        "成交额": 3077634.0,
        "振幅": 2.14,
        "换手率": null,
        "symbol": "107.EDZ"
    },
    {
        "序号": 1966,
        "name": "Morgan Stanley Emerging Markets",
        "最新价": 6.78,
        "涨跌额": 0.09,
        "涨跌幅": 1.35,
        "开盘价": 6.68,
        "最高价": 6.79,
        "最低价": 6.68,
        "昨收价": 6.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 70219.0,
        "成交额": 473399.0,
        "振幅": 1.64,
        "换手率": null,
        "symbol": "106.MSD"
    },
    {
        "序号": 1967,
        "name": "加特纳",
        "最新价": 452.0,
        "涨跌额": 6.0,
        "涨跌幅": 1.35,
        "开盘价": 445.39,
        "最高价": 453.25,
        "最低价": 443.95,
        "昨收价": 446.0,
        "总市值": 35232772624.0,
        "市盈率": 37.86,
        "成交量": 431672.0,
        "成交额": 194556004.0,
        "振幅": 2.09,
        "换手率": 0.55,
        "symbol": "106.IT"
    },
    {
        "序号": 1968,
        "name": "FTAI Infrastructure Inc",
        "最新价": 3.78,
        "涨跌额": 0.05,
        "涨跌幅": 1.34,
        "开盘价": 3.73,
        "最高价": 3.8,
        "最低价": 3.67,
        "昨收价": 3.73,
        "总市值": 376073659.0,
        "市盈率": -2.76,
        "成交量": 479788.0,
        "成交额": 1808855.0,
        "振幅": 3.49,
        "换手率": 0.48,
        "symbol": "105.FIP"
    },
    {
        "序号": 1969,
        "name": "Patria Investments Ltd-A",
        "最新价": 14.4,
        "涨跌额": 0.19,
        "涨跌幅": 1.34,
        "开盘价": 14.2,
        "最高价": 14.42,
        "最低价": 14.19,
        "昨收价": 14.21,
        "总市值": 2119578192.0,
        "市盈率": 17.97,
        "成交量": 183691.0,
        "成交额": 2635426.0,
        "振幅": 1.62,
        "换手率": 0.12,
        "symbol": "105.PAX"
    },
    {
        "序号": 1970,
        "name": "FS KKR Capital Corp",
        "最新价": 20.47,
        "涨跌额": 0.27,
        "涨跌幅": 1.34,
        "开盘价": 20.21,
        "最高价": 20.49,
        "最低价": 20.16,
        "昨收价": 20.2,
        "总市值": 5732959884.0,
        "市盈率": 8.52,
        "成交量": 1879442.0,
        "成交额": 38360241.0,
        "振幅": 1.63,
        "换手率": 0.67,
        "symbol": "106.FSK"
    },
    {
        "序号": 1971,
        "name": "Summit Materials Inc-A",
        "最新价": 36.42,
        "涨跌额": 0.48,
        "涨跌幅": 1.34,
        "开盘价": 35.85,
        "最高价": 36.44,
        "最低价": 35.75,
        "昨收价": 35.94,
        "总市值": 4350758184.0,
        "市盈率": 13.91,
        "成交量": 620418.0,
        "成交额": 22505673.0,
        "振幅": 1.92,
        "换手率": 0.52,
        "symbol": "106.SUM"
    },
    {
        "序号": 1972,
        "name": "Park Hotels & Resorts Inc",
        "最新价": 15.18,
        "涨跌额": 0.2,
        "涨跌幅": 1.34,
        "开盘价": 14.95,
        "最高价": 15.2,
        "最低价": 14.86,
        "昨收价": 14.98,
        "总市值": 3187552930.0,
        "市盈率": -56.92,
        "成交量": 2011040.0,
        "成交额": 30393307.0,
        "振幅": 2.27,
        "换手率": 0.96,
        "symbol": "106.PK"
    },
    {
        "序号": 1973,
        "name": "Provident Bancorp Inc (MD)",
        "最新价": 9.88,
        "涨跌额": 0.13,
        "涨跌幅": 1.33,
        "开盘价": 9.87,
        "最高价": 9.9,
        "最低价": 9.78,
        "昨收价": 9.75,
        "总市值": 174697330.0,
        "市盈率": 16.29,
        "成交量": 13550.0,
        "成交额": 133601.0,
        "振幅": 1.23,
        "换手率": 0.08,
        "symbol": "105.PVBC"
    },
    {
        "序号": 1974,
        "name": "American Strategic Investment C",
        "最新价": 7.6,
        "涨跌额": 0.1,
        "涨跌幅": 1.33,
        "开盘价": 7.43,
        "最高价": 7.6,
        "最低价": 7.3,
        "昨收价": 7.5,
        "总市值": 17740984.0,
        "市盈率": -0.42,
        "成交量": 1080.0,
        "成交额": 8032.0,
        "振幅": 4.0,
        "换手率": 0.05,
        "symbol": "106.NYC"
    },
    {
        "序号": 1975,
        "name": "Enveric Biosciences Inc",
        "最新价": 1.52,
        "涨跌额": 0.02,
        "涨跌幅": 1.33,
        "开盘价": 1.59,
        "最高价": 1.85,
        "最低价": 1.46,
        "昨收价": 1.5,
        "总市值": 3528399.0,
        "市盈率": -0.16,
        "成交量": 100638.0,
        "成交额": 163293.0,
        "振幅": 26.0,
        "换手率": 4.34,
        "symbol": "105.ENVB"
    },
    {
        "序号": 1976,
        "name": "Dermata Therapeutics Inc",
        "最新价": 0.76,
        "涨跌额": 0.01,
        "涨跌幅": 1.33,
        "开盘价": 0.74,
        "最高价": 0.78,
        "最低价": 0.73,
        "昨收价": 0.75,
        "总市值": 2689666.0,
        "市盈率": -0.37,
        "成交量": 57507.0,
        "成交额": 43676.0,
        "振幅": 6.67,
        "换手率": 1.62,
        "symbol": "105.DRMA"
    },
    {
        "序号": 1977,
        "name": "Optica Rare Earths & Critical M",
        "最新价": 17.49,
        "涨跌额": 0.23,
        "涨跌幅": 1.33,
        "开盘价": 17.46,
        "最高价": 17.49,
        "最低价": 17.42,
        "昨收价": 17.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 505.0,
        "成交额": 8805.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.CRIT"
    },
    {
        "序号": 1978,
        "name": "TKO Group Holdings Inc-A",
        "最新价": 73.77,
        "涨跌额": 0.97,
        "涨跌幅": 1.33,
        "开盘价": 72.46,
        "最高价": 73.98,
        "最低价": 72.34,
        "昨收价": 72.8,
        "总市值": 12664897116.0,
        "市盈率": 50.19,
        "成交量": 1035208.0,
        "成交额": 75975972.0,
        "振幅": 2.25,
        "换手率": 0.6,
        "symbol": "106.TKO"
    },
    {
        "序号": 1979,
        "name": "Renaissance IPO ETF",
        "最新价": 35.0,
        "涨跌额": 0.46,
        "涨跌幅": 1.33,
        "开盘价": 34.39,
        "最高价": 35.07,
        "最低价": 34.32,
        "昨收价": 34.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 17234.0,
        "成交额": 600897.0,
        "振幅": 2.17,
        "换手率": null,
        "symbol": "107.IPO"
    },
    {
        "序号": 1980,
        "name": "AI Powered Equity ETF",
        "最新价": 32.73,
        "涨跌额": 0.43,
        "涨跌幅": 1.33,
        "开盘价": 32.22,
        "最高价": 32.86,
        "最低价": 32.22,
        "昨收价": 32.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 10655.0,
        "成交额": 347847.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.AIEQ"
    },
    {
        "序号": 1981,
        "name": "American Homes 4 Rent-A",
        "最新价": 35.08,
        "涨跌额": 0.46,
        "涨跌幅": 1.33,
        "开盘价": 34.39,
        "最高价": 35.08,
        "最低价": 34.32,
        "昨收价": 34.62,
        "总市值": 12700921779.0,
        "市盈率": 32.48,
        "成交量": 2557071.0,
        "成交额": 88994720.0,
        "振幅": 2.2,
        "换手率": 0.71,
        "symbol": "106.AMH"
    },
    {
        "序号": 1982,
        "name": "LifeMD Inc",
        "最新价": 8.39,
        "涨跌额": 0.11,
        "涨跌幅": 1.33,
        "开盘价": 8.28,
        "最高价": 8.5,
        "最低价": 8.18,
        "昨收价": 8.28,
        "总市值": 318642166.0,
        "市盈率": -11.06,
        "成交量": 357807.0,
        "成交额": 2984361.0,
        "振幅": 3.86,
        "换手率": 0.94,
        "symbol": "105.LFMD"
    },
    {
        "序号": 1983,
        "name": "Braemar Hotels & Resorts Inc",
        "最新价": 2.29,
        "涨跌额": 0.03,
        "涨跌幅": 1.33,
        "开盘价": 2.25,
        "最高价": 2.31,
        "最低价": 2.25,
        "昨收价": 2.26,
        "总市值": 151126015.0,
        "市盈率": -13.65,
        "成交量": 110998.0,
        "成交额": 253389.0,
        "振幅": 2.65,
        "换手率": 0.17,
        "symbol": "106.BHR"
    },
    {
        "序号": 1984,
        "name": "雪佛龙",
        "最新价": 144.31,
        "涨跌额": 1.89,
        "涨跌幅": 1.33,
        "开盘价": 143.51,
        "最高价": 144.67,
        "最低价": 143.12,
        "昨收价": 142.42,
        "总市值": 272421009846.0,
        "市盈率": 10.7,
        "成交量": 10821744.0,
        "成交额": 1559269056.0,
        "振幅": 1.09,
        "换手率": 0.57,
        "symbol": "106.CVX"
    },
    {
        "序号": 1985,
        "name": "攀登者",
        "最新价": 19.86,
        "涨跌额": 0.26,
        "涨跌幅": 1.33,
        "开盘价": 19.5,
        "最高价": 20.15,
        "最低价": 19.47,
        "昨收价": 19.6,
        "总市值": 272812848.0,
        "市盈率": 28.22,
        "成交量": 21152.0,
        "成交额": 420003.0,
        "振幅": 3.47,
        "换手率": 0.15,
        "symbol": "105.ESCA"
    },
    {
        "序号": 1986,
        "name": "Amplify Online Retail ETF",
        "最新价": 50.44,
        "涨跌额": 0.66,
        "涨跌幅": 1.33,
        "开盘价": 49.63,
        "最高价": 50.55,
        "最低价": 49.63,
        "昨收价": 49.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 9334.0,
        "成交额": 469062.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "107.IBUY"
    },
    {
        "序号": 1987,
        "name": "Direxion Daily MSFT Bull 1.5X S",
        "最新价": 39.82,
        "涨跌额": 0.52,
        "涨跌幅": 1.32,
        "开盘价": 39.0,
        "最高价": 39.83,
        "最低价": 38.88,
        "昨收价": 39.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 76433.0,
        "成交额": 3019585.0,
        "振幅": 2.42,
        "换手率": null,
        "symbol": "105.MSFU"
    },
    {
        "序号": 1988,
        "name": "安富利",
        "最新价": 47.5,
        "涨跌额": 0.62,
        "涨跌幅": 1.32,
        "开盘价": 46.88,
        "最高价": 47.62,
        "最低价": 46.88,
        "昨收价": 46.88,
        "总市值": 4298054743.0,
        "市盈率": 5.4,
        "成交量": 358836.0,
        "成交额": 17016791.0,
        "振幅": 1.58,
        "换手率": 0.4,
        "symbol": "105.AVT"
    },
    {
        "序号": 1989,
        "name": "迈威尔科技",
        "最新价": 52.88,
        "涨跌额": 0.69,
        "涨跌幅": 1.32,
        "开盘价": 51.72,
        "最高价": 53.12,
        "最低价": 51.66,
        "昨收价": 52.19,
        "总市值": 45714760000.0,
        "市盈率": -82.21,
        "成交量": 7086058.0,
        "成交额": 373772832.0,
        "振幅": 2.8,
        "换手率": 0.82,
        "symbol": "105.MRVL"
    },
    {
        "序号": 1990,
        "name": "ARK Autonomous Technology & Rob",
        "最新价": 55.18,
        "涨跌额": 0.72,
        "涨跌幅": 1.32,
        "开盘价": 54.28,
        "最高价": 55.28,
        "最低价": 54.2,
        "昨收价": 54.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 80593.0,
        "成交额": 4433299.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.ARKQ"
    },
    {
        "序号": 1991,
        "name": "Direxion Daily Travel & Vacatio",
        "最新价": 13.03,
        "涨跌额": 0.17,
        "涨跌幅": 1.32,
        "开盘价": 12.86,
        "最高价": 13.04,
        "最低价": 12.81,
        "昨收价": 12.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 10588.0,
        "成交额": 137513.0,
        "振幅": 1.79,
        "换手率": null,
        "symbol": "107.OOTO"
    },
    {
        "序号": 1992,
        "name": "Invesco S&P SmallCap Energy ETF",
        "最新价": 49.86,
        "涨跌额": 0.65,
        "涨跌幅": 1.32,
        "开盘价": 49.61,
        "最高价": 50.37,
        "最低价": 49.61,
        "昨收价": 49.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 30443.0,
        "成交额": 1522654.0,
        "振幅": 1.54,
        "换手率": null,
        "symbol": "105.PSCE"
    },
    {
        "序号": 1993,
        "name": "做空FTSE中国50ETF-ProShares",
        "最新价": 19.96,
        "涨跌额": 0.26,
        "涨跌幅": 1.32,
        "开盘价": 19.91,
        "最高价": 19.96,
        "最低价": 19.86,
        "昨收价": 19.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 12552.0,
        "成交额": 250058.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.YXI"
    },
    {
        "序号": 1994,
        "name": "海伍兹物业",
        "最新价": 21.52,
        "涨跌额": 0.28,
        "涨跌幅": 1.32,
        "开盘价": 21.05,
        "最高价": 21.54,
        "最低价": 20.95,
        "昨收价": 21.24,
        "总市值": 2274518977.0,
        "市盈率": 16.45,
        "成交量": 1631015.0,
        "成交额": 34846840.0,
        "振幅": 2.78,
        "换手率": 1.54,
        "symbol": "106.HIW"
    },
    {
        "序号": 1995,
        "name": "爱科集团",
        "最新价": 116.06,
        "涨跌额": 1.51,
        "涨跌幅": 1.32,
        "开盘价": 114.78,
        "最高价": 116.59,
        "最低价": 114.78,
        "昨收价": 114.55,
        "总市值": 8690565488.0,
        "市盈率": 7.53,
        "成交量": 347759.0,
        "成交额": 40268395.0,
        "振幅": 1.58,
        "换手率": 0.46,
        "symbol": "106.AGCO"
    },
    {
        "序号": 1996,
        "name": "CS Disco Inc",
        "最新价": 6.92,
        "涨跌额": 0.09,
        "涨跌幅": 1.32,
        "开盘价": 6.77,
        "最高价": 7.07,
        "最低价": 6.77,
        "昨收价": 6.83,
        "总市值": 419541919.0,
        "市盈率": -7.63,
        "成交量": 154724.0,
        "成交额": 1072420.0,
        "振幅": 4.39,
        "换手率": 0.26,
        "symbol": "106.LAW"
    },
    {
        "序号": 1997,
        "name": "TEGNA Inc",
        "最新价": 15.39,
        "涨跌额": 0.2,
        "涨跌幅": 1.32,
        "开盘价": 15.19,
        "最高价": 15.5,
        "最低价": 15.15,
        "昨收价": 15.19,
        "总市值": 3031336550.0,
        "市盈率": 4.9,
        "成交量": 1970185.0,
        "成交额": 30309383.0,
        "振幅": 2.3,
        "换手率": 1.0,
        "symbol": "106.TGNA"
    },
    {
        "序号": 1998,
        "name": "RBC轴承",
        "最新价": 262.41,
        "涨跌额": 3.41,
        "涨跌幅": 1.32,
        "开盘价": 258.03,
        "最高价": 263.91,
        "最低价": 258.03,
        "昨收价": 259.0,
        "总市值": 7632194588.0,
        "市盈率": 40.77,
        "成交量": 91197.0,
        "成交额": 23859168.0,
        "振幅": 2.27,
        "换手率": 0.31,
        "symbol": "106.RBC"
    },
    {
        "序号": 1999,
        "name": "劳埃德银行(US ADR)",
        "最新价": 2.31,
        "涨跌额": 0.03,
        "涨跌幅": 1.32,
        "开盘价": 2.29,
        "最高价": 2.32,
        "最低价": 2.28,
        "昨收价": 2.28,
        "总市值": 36707383649.0,
        "市盈率": 4.25,
        "成交量": 8133014.0,
        "成交额": 18729179.0,
        "振幅": 1.75,
        "换手率": 0.05,
        "symbol": "106.LYG"
    },
    {
        "序号": 2000,
        "name": "Rambus Inc",
        "最新价": 65.52,
        "涨跌额": 0.85,
        "涨跌幅": 1.31,
        "开盘价": 64.03,
        "最高价": 66.37,
        "最低价": 64.03,
        "昨收价": 64.67,
        "总市值": 7044005470.0,
        "市盈率": 24.18,
        "成交量": 991265.0,
        "成交额": 64979789.0,
        "振幅": 3.62,
        "换手率": 0.92,
        "symbol": "105.RMBS"
    },
    {
        "序号": 2001,
        "name": "做空恐慌指数期货短期合约ETF",
        "最新价": 99.57,
        "涨跌额": 1.29,
        "涨跌幅": 1.31,
        "开盘价": 98.73,
        "最高价": 99.79,
        "最低价": 98.69,
        "昨收价": 98.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 1420619.0,
        "成交额": 141062324.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "107.SVXY"
    },
    {
        "序号": 2002,
        "name": "先驱电气",
        "最新价": 6.18,
        "涨跌额": 0.08,
        "涨跌幅": 1.31,
        "开盘价": 6.19,
        "最高价": 6.31,
        "最低价": 6.1,
        "昨收价": 6.1,
        "总市值": 61367536.0,
        "市盈率": 34.57,
        "成交量": 25933.0,
        "成交额": 160799.0,
        "振幅": 3.44,
        "换手率": 0.26,
        "symbol": "105.PPSI"
    },
    {
        "序号": 2003,
        "name": "Cue Biopharma Inc",
        "最新价": 3.09,
        "涨跌额": 0.04,
        "涨跌幅": 1.31,
        "开盘价": 3.1,
        "最高价": 3.25,
        "最低价": 3.0,
        "昨收价": 3.05,
        "总市值": 139430938.0,
        "市盈率": -2.69,
        "成交量": 325520.0,
        "成交额": 1012213.0,
        "振幅": 8.2,
        "换手率": 0.72,
        "symbol": "105.CUE"
    },
    {
        "序号": 2004,
        "name": "加拿大丰业银行",
        "最新价": 44.82,
        "涨跌额": 0.58,
        "涨跌幅": 1.31,
        "开盘价": 44.24,
        "最高价": 44.86,
        "最低价": 44.24,
        "昨收价": 44.24,
        "总市值": 54413470904.0,
        "市盈率": 10.1,
        "成交量": 1329392.0,
        "成交额": 59396496.0,
        "振幅": 1.4,
        "换手率": 0.11,
        "symbol": "106.BNS"
    },
    {
        "序号": 2005,
        "name": "ProShares UltraShort MSCI Japan",
        "最新价": 13.16,
        "涨跌额": 0.17,
        "涨跌幅": 1.31,
        "开盘价": 13.42,
        "最高价": 13.42,
        "最低价": 13.14,
        "昨收价": 12.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 10798.0,
        "成交额": 142502.0,
        "振幅": 2.16,
        "换手率": null,
        "symbol": "107.EWV"
    },
    {
        "序号": 2006,
        "name": "Diodes Inc",
        "最新价": 72.05,
        "涨跌额": 0.93,
        "涨跌幅": 1.31,
        "开盘价": 70.68,
        "最高价": 73.01,
        "最低价": 70.51,
        "昨收价": 71.12,
        "总市值": 3309849688.0,
        "市盈率": 11.26,
        "成交量": 535138.0,
        "成交额": 38581079.0,
        "振幅": 3.52,
        "换手率": 1.16,
        "symbol": "105.DIOD"
    },
    {
        "序号": 2007,
        "name": "国际海洋工程",
        "最新价": 19.37,
        "涨跌额": 0.25,
        "涨跌幅": 1.31,
        "开盘价": 19.37,
        "最高价": 19.54,
        "最低价": 19.15,
        "昨收价": 19.12,
        "总市值": 1951052102.0,
        "市盈率": 25.67,
        "成交量": 641871.0,
        "成交额": 12418632.0,
        "振幅": 2.04,
        "换手率": 0.64,
        "symbol": "106.OII"
    },
    {
        "序号": 2008,
        "name": "Nuveen Multi-Asset Income Fund",
        "最新价": 12.4,
        "涨跌额": 0.16,
        "涨跌幅": 1.31,
        "开盘价": 12.26,
        "最高价": 12.45,
        "最低价": 12.2,
        "昨收价": 12.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 166678.0,
        "成交额": 2047945.0,
        "振幅": 2.04,
        "换手率": null,
        "symbol": "106.NMAI"
    },
    {
        "序号": 2009,
        "name": "LAVA Therapeutics NV",
        "最新价": 1.55,
        "涨跌额": 0.02,
        "涨跌幅": 1.31,
        "开盘价": 1.52,
        "最高价": 1.57,
        "最低价": 1.52,
        "昨收价": 1.53,
        "总市值": 40748085.0,
        "市盈率": -0.81,
        "成交量": 6144.0,
        "成交额": 9484.0,
        "振幅": 3.27,
        "换手率": 0.02,
        "symbol": "105.LVTX"
    },
    {
        "序号": 2010,
        "name": "英特尔",
        "最新价": 42.7,
        "涨跌额": 0.55,
        "涨跌幅": 1.3,
        "开盘价": 41.84,
        "最高价": 42.96,
        "最低价": 41.81,
        "昨收价": 42.15,
        "总市值": 180023200000.0,
        "市盈率": -109.5,
        "成交量": 42814959.0,
        "成交额": 1826967072.0,
        "振幅": 2.73,
        "换手率": 1.02,
        "symbol": "105.INTC"
    },
    {
        "序号": 2011,
        "name": "Chavant Capital Acquisition Cor",
        "最新价": 11.65,
        "涨跌额": 0.15,
        "涨跌幅": 1.3,
        "开盘价": 11.37,
        "最高价": 11.65,
        "最低价": 11.37,
        "昨收价": 11.5,
        "总市值": 32374325.0,
        "市盈率": -16.79,
        "成交量": 7728.0,
        "成交额": 88670.0,
        "振幅": 2.43,
        "换手率": 0.28,
        "symbol": "105.CLAY"
    },
    {
        "序号": 2012,
        "name": "Adit EdTech Acquisition Corp Un",
        "最新价": 11.65,
        "涨跌额": 0.15,
        "涨跌幅": 1.3,
        "开盘价": 11.08,
        "最高价": 12.48,
        "最低价": 9.51,
        "昨收价": 11.5,
        "总市值": 103685303.0,
        "市盈率": -63.01,
        "成交量": 7979.0,
        "成交额": 88454.0,
        "振幅": 25.83,
        "换手率": null,
        "symbol": "107.ADEX_U"
    },
    {
        "序号": 2013,
        "name": "Invesco DWA Consumer Cyclicals ",
        "最新价": 76.15,
        "涨跌额": 0.98,
        "涨跌幅": 1.3,
        "开盘价": 75.27,
        "最高价": 76.15,
        "最低价": 75.27,
        "昨收价": 75.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 3817.0,
        "成交额": 289654.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "105.PEZ"
    },
    {
        "序号": 2014,
        "name": "CRA国际",
        "最新价": 97.99,
        "涨跌额": 1.26,
        "涨跌幅": 1.3,
        "开盘价": 97.32,
        "最高价": 98.31,
        "最低价": 96.0,
        "昨收价": 96.73,
        "总市值": 686098151.0,
        "市盈率": 19.22,
        "成交量": 25877.0,
        "成交额": 2522559.0,
        "振幅": 2.39,
        "换手率": 0.37,
        "symbol": "105.CRAI"
    },
    {
        "序号": 2015,
        "name": "STRATS Trust For Dominion Resou",
        "最新价": 24.89,
        "涨跌额": 0.32,
        "涨跌幅": 1.3,
        "开盘价": 24.89,
        "最高价": 24.89,
        "最低价": 24.89,
        "昨收价": 24.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 2517.0,
        "成交额": 62648.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.GJP"
    },
    {
        "序号": 2016,
        "name": "Fidelity Cloud Computing ETF",
        "最新价": 21.78,
        "涨跌额": 0.28,
        "涨跌幅": 1.3,
        "开盘价": 21.4,
        "最高价": 21.78,
        "最低价": 21.4,
        "昨收价": 21.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 19198.0,
        "成交额": 416083.0,
        "振幅": 1.77,
        "换手率": null,
        "symbol": "107.FCLD"
    },
    {
        "序号": 2017,
        "name": "纽约梅隆银行",
        "最新价": 49.06,
        "涨跌额": 0.63,
        "涨跌幅": 1.3,
        "开盘价": 48.5,
        "最高价": 49.24,
        "最低价": 48.34,
        "昨收价": 48.43,
        "总市值": 37730735019.0,
        "市盈率": 10.41,
        "成交量": 3668815.0,
        "成交额": 179571674.0,
        "振幅": 1.86,
        "换手率": 0.48,
        "symbol": "106.BK"
    },
    {
        "序号": 2018,
        "name": "Ur-能源",
        "最新价": 1.56,
        "涨跌额": 0.02,
        "涨跌幅": 1.3,
        "开盘价": 1.54,
        "最高价": 1.59,
        "最低价": 1.54,
        "昨收价": 1.54,
        "总市值": 414943024.0,
        "市盈率": -13.79,
        "成交量": 1367686.0,
        "成交额": 2147461.0,
        "振幅": 3.25,
        "换手率": 0.51,
        "symbol": "107.URG"
    },
    {
        "序号": 2019,
        "name": "Direxion Mid Cap Bull 3X Shares",
        "最新价": 38.22,
        "涨跌额": 0.49,
        "涨跌幅": 1.3,
        "开盘价": 37.52,
        "最高价": 38.63,
        "最低价": 37.52,
        "昨收价": 37.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 38186.0,
        "成交额": 1461328.0,
        "振幅": 2.94,
        "换手率": null,
        "symbol": "107.MIDU"
    },
    {
        "序号": 2020,
        "name": "GAN Ltd",
        "最新价": 1.56,
        "涨跌额": 0.02,
        "涨跌幅": 1.3,
        "开盘价": 1.53,
        "最高价": 1.57,
        "最低价": 1.53,
        "昨收价": 1.54,
        "总市值": 69754539.0,
        "市盈率": -0.4,
        "成交量": 201900.0,
        "成交额": 314447.0,
        "振幅": 2.6,
        "换手率": 0.45,
        "symbol": "105.GAN"
    },
    {
        "序号": 2021,
        "name": "Fathom Digital Manufacturing Co",
        "最新价": 4.68,
        "涨跌额": 0.06,
        "涨跌幅": 1.3,
        "开盘价": 4.71,
        "最高价": 4.73,
        "最低价": 4.55,
        "昨收价": 4.62,
        "总市值": 31985287.0,
        "市盈率": -0.46,
        "成交量": 3077.0,
        "成交额": 14278.0,
        "振幅": 3.9,
        "换手率": 0.05,
        "symbol": "106.FATH"
    },
    {
        "序号": 2022,
        "name": "ProShares S&P Kensho Smart Fact",
        "最新价": 35.14,
        "涨跌额": 0.45,
        "涨跌幅": 1.3,
        "开盘价": 35.08,
        "最高价": 35.14,
        "最低价": 35.08,
        "昨收价": 34.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 301.0,
        "成交额": 10568.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.MAKX"
    },
    {
        "序号": 2023,
        "name": "Pacer Data and Digital Revoluti",
        "最新价": 34.36,
        "涨跌额": 0.44,
        "涨跌幅": 1.3,
        "开盘价": 34.07,
        "最高价": 34.41,
        "最低价": 34.07,
        "昨收价": 33.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1320.0,
        "成交额": 45321.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.TRFK"
    },
    {
        "序号": 2024,
        "name": "DTE能源",
        "最新价": 108.61,
        "涨跌额": 1.39,
        "涨跌幅": 1.3,
        "开盘价": 107.48,
        "最高价": 108.69,
        "最低价": 106.9,
        "昨收价": 107.22,
        "总市值": 22401760339.0,
        "市盈率": 18.02,
        "成交量": 2138993.0,
        "成交额": 231586075.0,
        "振幅": 1.67,
        "换手率": 1.04,
        "symbol": "106.DTE"
    },
    {
        "序号": 2025,
        "name": "Cambium Networks Corp",
        "最新价": 4.69,
        "涨跌额": 0.06,
        "涨跌幅": 1.3,
        "开盘价": 4.61,
        "最高价": 4.75,
        "最低价": 4.61,
        "昨收价": 4.63,
        "总市值": 129749446.0,
        "市盈率": -8.91,
        "成交量": 89963.0,
        "成交额": 421852.0,
        "振幅": 3.02,
        "换手率": 0.33,
        "symbol": "105.CMBM"
    },
    {
        "序号": 2026,
        "name": "Monarch Casino & Resort Inc",
        "最新价": 64.88,
        "涨跌额": 0.83,
        "涨跌幅": 1.3,
        "开盘价": 63.81,
        "最高价": 65.2,
        "最低价": 63.81,
        "昨收价": 64.05,
        "总市值": 1237100827.0,
        "市盈率": 14.27,
        "成交量": 51858.0,
        "成交额": 3362926.0,
        "振幅": 2.17,
        "换手率": 0.27,
        "symbol": "105.MCRI"
    },
    {
        "序号": 2027,
        "name": "RPC Inc",
        "最新价": 7.04,
        "涨跌额": 0.09,
        "涨跌幅": 1.29,
        "开盘价": 7.04,
        "最高价": 7.17,
        "最低价": 6.95,
        "昨收价": 6.95,
        "总市值": 1522247739.0,
        "市盈率": 6.29,
        "成交量": 1610733.0,
        "成交额": 11320196.0,
        "振幅": 3.17,
        "换手率": 0.74,
        "symbol": "106.RES"
    },
    {
        "序号": 2028,
        "name": "Invesco DWA Technology Momentum",
        "最新价": 48.51,
        "涨跌额": 0.62,
        "涨跌幅": 1.29,
        "开盘价": 47.88,
        "最高价": 48.56,
        "最低价": 47.88,
        "昨收价": 47.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 24546.0,
        "成交额": 1182830.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "105.PTF"
    },
    {
        "序号": 2029,
        "name": "欧麦斯-麦能医疗",
        "最新价": 21.91,
        "涨跌额": 0.28,
        "涨跌幅": 1.29,
        "开盘价": 22.09,
        "最高价": 22.25,
        "最低价": 21.73,
        "昨收价": 21.63,
        "总市值": 1676137852.0,
        "市盈率": -14.32,
        "成交量": 738531.0,
        "成交额": 16232852.0,
        "振幅": 2.4,
        "换手率": 0.97,
        "symbol": "106.OMI"
    },
    {
        "序号": 2030,
        "name": "Home Bancor Inc",
        "最新价": 39.13,
        "涨跌额": 0.5,
        "涨跌幅": 1.29,
        "开盘价": 38.65,
        "最高价": 39.66,
        "最低价": 38.33,
        "昨收价": 38.63,
        "总市值": 318826114.0,
        "市盈率": 7.66,
        "成交量": 13640.0,
        "成交额": 531031.0,
        "振幅": 3.44,
        "换手率": 0.17,
        "symbol": "105.HBCP"
    },
    {
        "序号": 2031,
        "name": "GraniteShares 1.75x Long AAPL D",
        "最新价": 28.2,
        "涨跌额": 0.36,
        "涨跌幅": 1.29,
        "开盘价": 27.83,
        "最高价": 28.24,
        "最低价": 27.74,
        "昨收价": 27.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 12947.0,
        "成交额": 363445.0,
        "振幅": 1.8,
        "换手率": null,
        "symbol": "105.AAPB"
    },
    {
        "序号": 2032,
        "name": "拉马尔户外广告",
        "最新价": 105.02,
        "涨跌额": 1.34,
        "涨跌幅": 1.29,
        "开盘价": 103.31,
        "最高价": 105.17,
        "最低价": 103.09,
        "昨收价": 103.68,
        "总市值": 10711846868.0,
        "市盈率": 25.95,
        "成交量": 493980.0,
        "成交额": 51703183.0,
        "振幅": 2.01,
        "换手率": 0.48,
        "symbol": "105.LAMR"
    },
    {
        "序号": 2033,
        "name": "Atmus Filtration Technologies I",
        "最新价": 23.53,
        "涨跌额": 0.3,
        "涨跌幅": 1.29,
        "开盘价": 23.37,
        "最高价": 23.7,
        "最低价": 23.18,
        "昨收价": 23.23,
        "总市值": 1959997140.0,
        "市盈率": 11.38,
        "成交量": 428048.0,
        "成交额": 10051849.0,
        "振幅": 2.24,
        "换手率": 0.51,
        "symbol": "106.ATMU"
    },
    {
        "序号": 2034,
        "name": "Quantum-Si Inc-A",
        "最新价": 1.57,
        "涨跌额": 0.02,
        "涨跌幅": 1.29,
        "开盘价": 1.6,
        "最高价": 1.67,
        "最低价": 1.56,
        "昨收价": 1.55,
        "总市值": 222513013.0,
        "市盈率": -2.08,
        "成交量": 517386.0,
        "成交额": 824215.0,
        "振幅": 7.1,
        "换手率": 0.37,
        "symbol": "105.QSI"
    },
    {
        "序号": 2035,
        "name": "美国社区银行",
        "最新价": 27.49,
        "涨跌额": 0.35,
        "涨跌幅": 1.29,
        "开盘价": 27.16,
        "最高价": 27.65,
        "最低价": 27.05,
        "昨收价": 27.14,
        "总市值": 3270756104.0,
        "市盈率": 12.83,
        "成交量": 524493.0,
        "成交额": 14397661.0,
        "振幅": 2.21,
        "换手率": 0.44,
        "symbol": "105.UCBI"
    },
    {
        "序号": 2036,
        "name": "Two Harbors Investment Corp Ser",
        "最新价": 21.22,
        "涨跌额": 0.27,
        "涨跌幅": 1.29,
        "开盘价": 20.87,
        "最高价": 21.22,
        "最低价": 20.87,
        "昨收价": 20.95,
        "总市值": 250396000.0,
        "市盈率": null,
        "成交量": 8780.0,
        "成交额": 184730.0,
        "振幅": 1.67,
        "换手率": 0.07,
        "symbol": "106.TWO_C"
    },
    {
        "序号": 2037,
        "name": "Templeton Global Income Fund",
        "最新价": 3.93,
        "涨跌额": 0.05,
        "涨跌幅": 1.29,
        "开盘价": 3.9,
        "最高价": 3.93,
        "最低价": 3.9,
        "昨收价": 3.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 682123.0,
        "成交额": 2666848.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "106.GIM"
    },
    {
        "序号": 2038,
        "name": "亿客行",
        "最新价": 145.5,
        "涨跌额": 1.85,
        "涨跌幅": 1.29,
        "开盘价": 142.7,
        "最高价": 145.83,
        "最低价": 142.51,
        "昨收价": 143.65,
        "总市值": 20202417756.0,
        "市盈率": 23.99,
        "成交量": 1748691.0,
        "成交额": 253689988.0,
        "振幅": 2.31,
        "换手率": 1.26,
        "symbol": "105.EXPE"
    },
    {
        "序号": 2039,
        "name": "ProShares UltraShort Lehman 7-1",
        "最新价": 23.6,
        "涨跌额": 0.3,
        "涨跌幅": 1.29,
        "开盘价": 23.6,
        "最高价": 23.66,
        "最低价": 23.54,
        "昨收价": 23.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 14823.0,
        "成交额": 349568.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.PST"
    },
    {
        "序号": 2040,
        "name": "Grab Holdings Ltd-A",
        "最新价": 3.15,
        "涨跌额": 0.04,
        "涨跌幅": 1.29,
        "开盘价": 3.13,
        "最高价": 3.15,
        "最低价": 3.11,
        "昨收价": 3.11,
        "总市值": 12322800000.0,
        "市盈率": -14.41,
        "成交量": 24985698.0,
        "成交额": 78194578.0,
        "振幅": 1.29,
        "换手率": 0.64,
        "symbol": "105.GRAB"
    },
    {
        "序号": 2041,
        "name": "ERShares Entrepreneurs ETF",
        "最新价": 12.6,
        "涨跌额": 0.16,
        "涨跌幅": 1.29,
        "开盘价": 12.6,
        "最高价": 12.6,
        "最低价": 12.6,
        "昨收价": 12.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 129.0,
        "成交额": 1625.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ENTR"
    },
    {
        "序号": 2042,
        "name": "西麦斯",
        "最新价": 7.09,
        "涨跌额": 0.09,
        "涨跌幅": 1.29,
        "开盘价": 6.99,
        "最高价": 7.12,
        "最低价": 6.94,
        "昨收价": 7.0,
        "总市值": 10442840245.0,
        "市盈率": 19.94,
        "成交量": 2712107.0,
        "成交额": 19199738.0,
        "振幅": 2.57,
        "换手率": 0.18,
        "symbol": "106.CX"
    },
    {
        "序号": 2043,
        "name": "哥伦比亚ETF-Global X MSCI",
        "最新价": 22.06,
        "涨跌额": 0.28,
        "涨跌幅": 1.29,
        "开盘价": 21.77,
        "最高价": 22.06,
        "最低价": 21.71,
        "昨收价": 21.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 24883.0,
        "成交额": 544244.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "107.GXG"
    },
    {
        "序号": 2044,
        "name": "三倍做多标普500ETF-ProShares",
        "最新价": 49.65,
        "涨跌额": 0.63,
        "涨跌幅": 1.29,
        "开盘价": 48.76,
        "最高价": 49.82,
        "最低价": 48.69,
        "昨收价": 49.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 8643034.0,
        "成交额": 426535776.0,
        "振幅": 2.31,
        "换手率": null,
        "symbol": "107.UPRO"
    },
    {
        "序号": 2045,
        "name": "New Fortress Energy Inc-A",
        "最新价": 37.88,
        "涨跌额": 0.48,
        "涨跌幅": 1.28,
        "开盘价": 37.74,
        "最高价": 38.48,
        "最低价": 37.29,
        "昨收价": 37.4,
        "总市值": 7766589659.0,
        "市盈率": 19.67,
        "成交量": 998557.0,
        "成交额": 37787014.0,
        "振幅": 3.18,
        "换手率": 0.49,
        "symbol": "105.NFE"
    },
    {
        "序号": 2046,
        "name": "西空航空",
        "最新价": 49.01,
        "涨跌额": 0.62,
        "涨跌幅": 1.28,
        "开盘价": 48.34,
        "最高价": 49.25,
        "最低价": 48.15,
        "昨收价": 48.39,
        "总市值": 2006649855.0,
        "市盈率": -66.27,
        "成交量": 591221.0,
        "成交额": 28916082.0,
        "振幅": 2.27,
        "换手率": 1.44,
        "symbol": "105.SKYW"
    },
    {
        "序号": 2047,
        "name": "YieldMax NVDA Option Income Str",
        "最新价": 21.37,
        "涨跌额": 0.27,
        "涨跌幅": 1.28,
        "开盘价": 21.03,
        "最高价": 21.49,
        "最低价": 21.03,
        "昨收价": 21.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 150907.0,
        "成交额": 3219292.0,
        "振幅": 2.18,
        "换手率": null,
        "symbol": "107.NVDY"
    },
    {
        "序号": 2048,
        "name": "Digital Brands Group Inc Wt",
        "最新价": 9.5,
        "涨跌额": 0.12,
        "涨跌幅": 1.28,
        "开盘价": 9.5,
        "最高价": 9.5,
        "最低价": 9.5,
        "昨收价": 9.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 358.0,
        "成交额": 3401.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DBGIW"
    },
    {
        "序号": 2049,
        "name": "Direxion Daily FTSE Europe Bull",
        "最新价": 21.41,
        "涨跌额": 0.27,
        "涨跌幅": 1.28,
        "开盘价": 21.0,
        "最高价": 21.5,
        "最低价": 21.0,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 26502.0,
        "成交额": 562797.0,
        "振幅": 2.37,
        "换手率": null,
        "symbol": "107.EURL"
    },
    {
        "序号": 2050,
        "name": "ProShares Ultra SmallCap600",
        "最新价": 21.45,
        "涨跌额": 0.27,
        "涨跌幅": 1.27,
        "开盘价": 21.55,
        "最高价": 21.64,
        "最低价": 21.27,
        "昨收价": 21.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 4184.0,
        "成交额": 90202.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "107.SAA"
    },
    {
        "序号": 2051,
        "name": "Resources Connection Inc",
        "最新价": 14.3,
        "涨跌额": 0.18,
        "涨跌幅": 1.27,
        "开盘价": 14.1,
        "最高价": 14.31,
        "最低价": 13.92,
        "昨收价": 14.12,
        "总市值": 481878097.0,
        "市盈率": 12.25,
        "成交量": 167211.0,
        "成交额": 2377341.0,
        "振幅": 2.76,
        "换手率": 0.5,
        "symbol": "105.RGP"
    },
    {
        "序号": 2052,
        "name": "Quanterix Corp",
        "最新价": 23.04,
        "涨跌额": 0.29,
        "涨跌幅": 1.27,
        "开盘价": 22.66,
        "最高价": 23.34,
        "最低价": 22.56,
        "昨收价": 22.75,
        "总市值": 871597348.0,
        "市盈率": -22.64,
        "成交量": 363211.0,
        "成交额": 8318802.0,
        "振幅": 3.43,
        "换手率": 0.96,
        "symbol": "105.QTRX"
    },
    {
        "序号": 2053,
        "name": "Perpetua Resources Corp",
        "最新价": 3.18,
        "涨跌额": 0.04,
        "涨跌幅": 1.27,
        "开盘价": 3.14,
        "最高价": 3.22,
        "最低价": 3.08,
        "昨收价": 3.14,
        "总市值": 201191550.0,
        "市盈率": -7.93,
        "成交量": 92925.0,
        "成交额": 292635.0,
        "振幅": 4.46,
        "换手率": 0.15,
        "symbol": "105.PPTA"
    },
    {
        "序号": 2054,
        "name": "On Holding AG-A",
        "最新价": 28.63,
        "涨跌额": 0.36,
        "涨跌幅": 1.27,
        "开盘价": 28.12,
        "最高价": 28.8,
        "最低价": 28.12,
        "昨收价": 28.27,
        "总市值": 18008950421.0,
        "市盈率": 195.41,
        "成交量": 3503122.0,
        "成交额": 100194366.0,
        "振幅": 2.41,
        "换手率": 0.56,
        "symbol": "106.ONON"
    },
    {
        "序号": 2055,
        "name": "Berkshire Hills Bancorp Inc",
        "最新价": 23.08,
        "涨跌额": 0.29,
        "涨跌幅": 1.27,
        "开盘价": 22.84,
        "最高价": 23.16,
        "最低价": 22.67,
        "昨收价": 22.79,
        "总市值": 1007909116.0,
        "市盈率": 9.93,
        "成交量": 155441.0,
        "成交额": 3582717.0,
        "振幅": 2.15,
        "换手率": 0.36,
        "symbol": "106.BHLB"
    },
    {
        "序号": 2056,
        "name": "Monolithic Power Systems Inc",
        "最新价": 575.73,
        "涨跌额": 7.23,
        "涨跌幅": 1.27,
        "开盘价": 566.16,
        "最高价": 580.7,
        "最低价": 566.16,
        "昨收价": 568.5,
        "总市值": 27584375760.0,
        "市盈率": 61.36,
        "成交量": 297759.0,
        "成交额": 171656743.0,
        "振幅": 2.56,
        "换手率": 0.62,
        "symbol": "105.MPWR"
    },
    {
        "序号": 2057,
        "name": "KORU Medical Systems Inc",
        "最新价": 2.39,
        "涨跌额": 0.03,
        "涨跌幅": 1.27,
        "开盘价": 2.39,
        "最高价": 2.47,
        "最低价": 2.31,
        "昨收价": 2.36,
        "总市值": 109149775.0,
        "市盈率": -13.23,
        "成交量": 34155.0,
        "成交额": 81973.0,
        "振幅": 6.78,
        "换手率": 0.07,
        "symbol": "105.KRMD"
    },
    {
        "序号": 2058,
        "name": "LCNB Corp",
        "最新价": 15.14,
        "涨跌额": 0.19,
        "涨跌幅": 1.27,
        "开盘价": 14.85,
        "最高价": 15.48,
        "最低价": 14.54,
        "昨收价": 14.95,
        "总市值": 168418450.0,
        "市盈率": 8.71,
        "成交量": 31618.0,
        "成交额": 469805.0,
        "振幅": 6.29,
        "换手率": 0.28,
        "symbol": "105.LCNB"
    },
    {
        "序号": 2059,
        "name": "Sigma Additive Solutions Inc",
        "最新价": 3.19,
        "涨跌额": 0.04,
        "涨跌幅": 1.27,
        "开盘价": 3.1,
        "最高价": 3.25,
        "最低价": 3.0,
        "昨收价": 3.15,
        "总市值": 2489549.0,
        "市盈率": -0.42,
        "成交量": 16436.0,
        "成交额": 50890.0,
        "振幅": 7.94,
        "换手率": 2.11,
        "symbol": "105.SASI"
    },
    {
        "序号": 2060,
        "name": "Alpha Architect U.S. Quantitati",
        "最新价": 47.9,
        "涨跌额": 0.6,
        "涨跌幅": 1.27,
        "开盘价": 47.3,
        "最高价": 47.9,
        "最低价": 47.3,
        "昨收价": 47.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 9988.0,
        "成交额": 474617.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "105.QMOM"
    },
    {
        "序号": 2061,
        "name": "HMN金融",
        "最新价": 19.96,
        "涨跌额": 0.25,
        "涨跌幅": 1.27,
        "开盘价": 19.75,
        "最高价": 19.98,
        "最低价": 19.75,
        "昨收价": 19.71,
        "总市值": 89567746.0,
        "市盈率": 12.81,
        "成交量": 1946.0,
        "成交额": 38753.0,
        "振幅": 1.17,
        "换手率": 0.04,
        "symbol": "105.HMNF"
    },
    {
        "序号": 2062,
        "name": "英国保诚",
        "最新价": 22.37,
        "涨跌额": 0.28,
        "涨跌幅": 1.27,
        "开盘价": 22.2,
        "最高价": 22.44,
        "最低价": 22.2,
        "昨收价": 22.09,
        "总市值": 30798129656.0,
        "市盈率": 8.93,
        "成交量": 550060.0,
        "成交额": 12289871.0,
        "振幅": 1.09,
        "换手率": 0.04,
        "symbol": "106.PUK"
    },
    {
        "序号": 2063,
        "name": "eGain Corp",
        "最新价": 7.99,
        "涨跌额": 0.1,
        "涨跌幅": 1.27,
        "开盘价": 7.89,
        "最高价": 8.01,
        "最低价": 7.7,
        "昨收价": 7.89,
        "总市值": 251566636.0,
        "市盈率": 53.29,
        "成交量": 77416.0,
        "成交额": 611984.0,
        "振幅": 3.93,
        "换手率": 0.25,
        "symbol": "105.EGAN"
    },
    {
        "序号": 2064,
        "name": "The Chefs' Warehouse Inc",
        "最新价": 28.01,
        "涨跌额": 0.35,
        "涨跌幅": 1.27,
        "开盘价": 27.63,
        "最高价": 28.26,
        "最低价": 27.46,
        "昨收价": 27.66,
        "总市值": 1111023064.0,
        "市盈率": 56.22,
        "成交量": 506585.0,
        "成交额": 14064261.0,
        "振幅": 2.89,
        "换手率": 1.28,
        "symbol": "105.CHEF"
    },
    {
        "序号": 2065,
        "name": "埃培智",
        "最新价": 31.22,
        "涨跌额": 0.39,
        "涨跌幅": 1.27,
        "开盘价": 30.9,
        "最高价": 31.31,
        "最低价": 30.69,
        "昨收价": 30.83,
        "总市值": 11957388970.0,
        "市盈率": 12.82,
        "成交量": 2843178.0,
        "成交额": 88284506.0,
        "振幅": 2.01,
        "换手率": 0.74,
        "symbol": "106.IPG"
    },
    {
        "序号": 2066,
        "name": "MRC Global Inc",
        "最新价": 10.41,
        "涨跌额": 0.13,
        "涨跌幅": 1.26,
        "开盘价": 10.27,
        "最高价": 10.43,
        "最低价": 10.22,
        "昨收价": 10.28,
        "总市值": 877582800.0,
        "市盈率": 7.7,
        "成交量": 570871.0,
        "成交额": 5909759.0,
        "振幅": 2.04,
        "换手率": 0.68,
        "symbol": "106.MRC"
    },
    {
        "序号": 2067,
        "name": "Nuveen Core Equity Alpha Fund",
        "最新价": 13.62,
        "涨跌额": 0.17,
        "涨跌幅": 1.26,
        "开盘价": 13.49,
        "最高价": 13.65,
        "最低价": 13.45,
        "昨收价": 13.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 84893.0,
        "成交额": 1153656.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "106.JCE"
    },
    {
        "序号": 2068,
        "name": "Invesco DB Energy Fund",
        "最新价": 20.03,
        "涨跌额": 0.25,
        "涨跌幅": 1.26,
        "开盘价": 20.0,
        "最高价": 20.08,
        "最低价": 19.9,
        "昨收价": 19.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 86905.0,
        "成交额": 1737086.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.DBE"
    },
    {
        "序号": 2069,
        "name": "伯灵顿百货",
        "最新价": 176.28,
        "涨跌额": 2.2,
        "涨跌幅": 1.26,
        "开盘价": 174.25,
        "最高价": 177.75,
        "最低价": 173.42,
        "昨收价": 174.08,
        "总市值": 11374513185.0,
        "市盈率": 38.25,
        "成交量": 801912.0,
        "成交额": 141618163.0,
        "振幅": 2.49,
        "换手率": 1.24,
        "symbol": "106.BURL"
    },
    {
        "序号": 2070,
        "name": "Jefferies Financial Group Inc",
        "最新价": 36.89,
        "涨跌额": 0.46,
        "涨跌幅": 1.26,
        "开盘价": 36.39,
        "最高价": 37.0,
        "最低价": 36.29,
        "昨收价": 36.43,
        "总市值": 7762679550.0,
        "市盈率": 22.3,
        "成交量": 710299.0,
        "成交额": 26155402.0,
        "振幅": 1.95,
        "换手率": 0.34,
        "symbol": "106.JEF"
    },
    {
        "序号": 2071,
        "name": "Roundhill Generative AI & Techn",
        "最新价": 29.73,
        "涨跌额": 0.37,
        "涨跌幅": 1.26,
        "开盘价": 29.28,
        "最高价": 29.74,
        "最低价": 29.28,
        "昨收价": 29.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 18555.0,
        "成交额": 549073.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.CHAT"
    },
    {
        "序号": 2072,
        "name": "QCR Holdings Inc",
        "最新价": 54.65,
        "涨跌额": 0.68,
        "涨跌幅": 1.26,
        "开盘价": 53.83,
        "最高价": 54.65,
        "最低价": 50.3,
        "昨收价": 53.97,
        "总市值": 914424786.0,
        "市盈率": 8.19,
        "成交量": 47786.0,
        "成交额": 2591221.0,
        "振幅": 8.06,
        "换手率": 0.29,
        "symbol": "105.QCRH"
    },
    {
        "序号": 2073,
        "name": "F.N.B. Corp",
        "最新价": 12.86,
        "涨跌额": 0.16,
        "涨跌幅": 1.26,
        "开盘价": 12.74,
        "最高价": 12.91,
        "最低价": 12.61,
        "昨收价": 12.7,
        "总市值": 4614535050.0,
        "市盈率": 8.05,
        "成交量": 1718525.0,
        "成交额": 22080187.0,
        "振幅": 2.36,
        "换手率": 0.48,
        "symbol": "106.FNB"
    },
    {
        "序号": 2074,
        "name": "Apollo Global Management Inc Se",
        "最新价": 55.58,
        "涨跌额": 0.69,
        "涨跌幅": 1.26,
        "开盘价": 54.85,
        "最高价": 55.67,
        "最低价": 54.85,
        "昨收价": 54.89,
        "总市值": 1597925000.0,
        "市盈率": null,
        "成交量": 136687.0,
        "成交额": 7586639.0,
        "振幅": 1.49,
        "换手率": 0.48,
        "symbol": "106.APO_A"
    },
    {
        "序号": 2075,
        "name": "iShares Lithium Miners and Prod",
        "最新价": 16.12,
        "涨跌额": 0.2,
        "涨跌幅": 1.26,
        "开盘价": 16.07,
        "最高价": 16.42,
        "最低价": 16.07,
        "昨收价": 15.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1967.0,
        "成交额": 31883.0,
        "振幅": 2.2,
        "换手率": null,
        "symbol": "105.ILIT"
    },
    {
        "序号": 2076,
        "name": "爱立信",
        "最新价": 5.66,
        "涨跌额": 0.07,
        "涨跌幅": 1.25,
        "开盘价": 5.66,
        "最高价": 5.72,
        "最低价": 5.64,
        "昨收价": 5.59,
        "总市值": 18927898820.0,
        "市盈率": -8.36,
        "成交量": 12371331.0,
        "成交额": 70183540.0,
        "振幅": 1.43,
        "换手率": 0.37,
        "symbol": "105.ERIC"
    },
    {
        "序号": 2077,
        "name": "Pioneer Bancorp Inc",
        "最新价": 8.9,
        "涨跌额": 0.11,
        "涨跌幅": 1.25,
        "开盘价": 8.69,
        "最高价": 9.03,
        "最低价": 8.69,
        "昨收价": 8.79,
        "总市值": 231201343.0,
        "市盈率": 11.48,
        "成交量": 7282.0,
        "成交额": 64836.0,
        "振幅": 3.87,
        "换手率": 0.03,
        "symbol": "105.PBFS"
    },
    {
        "序号": 2078,
        "name": "Janover Inc",
        "最新价": 1.62,
        "涨跌额": 0.02,
        "涨跌幅": 1.25,
        "开盘价": 1.55,
        "最高价": 1.73,
        "最低价": 1.52,
        "昨收价": 1.6,
        "总市值": 16192018.0,
        "市盈率": -8.4,
        "成交量": 65473.0,
        "成交额": 107233.0,
        "振幅": 13.13,
        "换手率": 0.66,
        "symbol": "105.JNVR"
    },
    {
        "序号": 2079,
        "name": "Design Therapeutics Inc",
        "最新价": 2.43,
        "涨跌额": 0.03,
        "涨跌幅": 1.25,
        "开盘价": 2.38,
        "最高价": 2.44,
        "最低价": 2.33,
        "昨收价": 2.4,
        "总市值": 136084841.0,
        "市盈率": -1.88,
        "成交量": 190346.0,
        "成交额": 457677.0,
        "振幅": 4.58,
        "换手率": 0.34,
        "symbol": "105.DSGN"
    },
    {
        "序号": 2080,
        "name": "第一储蓄金融",
        "最新价": 15.4,
        "涨跌额": 0.19,
        "涨跌幅": 1.25,
        "开盘价": 15.19,
        "最高价": 15.43,
        "最低价": 15.19,
        "昨收价": 15.21,
        "总市值": 105753663.0,
        "市盈率": 9.29,
        "成交量": 1904.0,
        "成交额": 29266.0,
        "振幅": 1.58,
        "换手率": 0.03,
        "symbol": "105.FSFG"
    },
    {
        "序号": 2081,
        "name": "Archrock Inc",
        "最新价": 14.6,
        "涨跌额": 0.18,
        "涨跌幅": 1.25,
        "开盘价": 14.46,
        "最高价": 14.68,
        "最低价": 14.44,
        "昨收价": 14.42,
        "总市值": 2278446669.0,
        "市盈率": 27.63,
        "成交量": 1423320.0,
        "成交额": 20738022.0,
        "振幅": 1.66,
        "换手率": 0.91,
        "symbol": "106.AROC"
    },
    {
        "序号": 2082,
        "name": "JELD-WEN Holding Inc",
        "最新价": 17.05,
        "涨跌额": 0.21,
        "涨跌幅": 1.25,
        "开盘价": 16.95,
        "最高价": 17.17,
        "最低价": 16.91,
        "昨收价": 16.84,
        "总市值": 1452917216.0,
        "市盈率": 11.11,
        "成交量": 378621.0,
        "成交额": 6465553.0,
        "振幅": 1.54,
        "换手率": 0.44,
        "symbol": "106.JELD"
    },
    {
        "序号": 2083,
        "name": "Themes Cybersecurity ETF",
        "最新价": 25.22,
        "涨跌额": 0.31,
        "涨跌幅": 1.24,
        "开盘价": 24.91,
        "最高价": 25.22,
        "最低价": 24.91,
        "昨收价": 24.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 1036.0,
        "成交额": 25867.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "105.SPAM"
    },
    {
        "序号": 2084,
        "name": "DigitalBridge Group Inc Series ",
        "最新价": 22.78,
        "涨跌额": 0.28,
        "涨跌幅": 1.24,
        "开盘价": 22.49,
        "最高价": 22.83,
        "最低价": 22.48,
        "昨收价": 22.5,
        "总市值": 191238100.0,
        "市盈率": null,
        "成交量": 3446.0,
        "成交额": 78212.0,
        "振幅": 1.56,
        "换手率": 0.04,
        "symbol": "106.DBRG_H"
    },
    {
        "序号": 2085,
        "name": "Ault Alliance Inc Series D Pfd",
        "最新价": 21.97,
        "涨跌额": 0.27,
        "涨跌幅": 1.24,
        "开盘价": 22.0,
        "最高价": 22.34,
        "最低价": 21.5,
        "昨收价": 21.7,
        "总市值": 9341578.0,
        "市盈率": null,
        "成交量": 6853.0,
        "成交额": 150920.0,
        "振幅": 3.87,
        "换手率": 1.61,
        "symbol": "107.AULT_D"
    },
    {
        "序号": 2086,
        "name": "华利安",
        "最新价": 113.93,
        "涨跌额": 1.4,
        "涨跌幅": 1.24,
        "开盘价": 112.4,
        "最高价": 115.05,
        "最低价": 112.4,
        "昨收价": 112.53,
        "总市值": 7859477342.0,
        "市盈率": 31.3,
        "成交量": 499533.0,
        "成交额": 56962348.0,
        "振幅": 2.35,
        "换手率": 0.72,
        "symbol": "106.HLI"
    },
    {
        "序号": 2087,
        "name": "Barings Corporate Investors",
        "最新价": 17.91,
        "涨跌额": 0.22,
        "涨跌幅": 1.24,
        "开盘价": 17.69,
        "最高价": 17.95,
        "最低价": 17.61,
        "昨收价": 17.69,
        "总市值": 352731038.0,
        "市盈率": null,
        "成交量": 37930.0,
        "成交额": 676397.0,
        "振幅": 1.92,
        "换手率": 0.19,
        "symbol": "106.MCI"
    },
    {
        "序号": 2088,
        "name": "斑马技术",
        "最新价": 236.13,
        "涨跌额": 2.9,
        "涨跌幅": 1.24,
        "开盘价": 232.29,
        "最高价": 237.8,
        "最低价": 232.29,
        "昨收价": 233.23,
        "总市值": 12127543293.0,
        "市盈率": 26.08,
        "成交量": 229931.0,
        "成交额": 54344737.0,
        "振幅": 2.36,
        "换手率": 0.45,
        "symbol": "105.ZBRA"
    },
    {
        "序号": 2089,
        "name": "吉利德科学",
        "最新价": 79.02,
        "涨跌额": 0.97,
        "涨跌幅": 1.24,
        "开盘价": 78.11,
        "最高价": 79.24,
        "最低价": 77.94,
        "昨收价": 78.05,
        "总市值": 98462230543.0,
        "市盈率": 16.76,
        "成交量": 6568994.0,
        "成交额": 517482768.0,
        "振幅": 1.67,
        "换手率": 0.53,
        "symbol": "105.GILD"
    },
    {
        "序号": 2090,
        "name": "Great Elm Group Inc Notes",
        "最新价": 22.0,
        "涨跌额": 0.27,
        "涨跌幅": 1.24,
        "开盘价": 22.0,
        "最高价": 22.0,
        "最低价": 22.0,
        "昨收价": 21.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 2200.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GEGGL"
    },
    {
        "序号": 2091,
        "name": "OFS Capital Corp",
        "最新价": 11.41,
        "涨跌额": 0.14,
        "涨跌幅": 1.24,
        "开盘价": 11.25,
        "最高价": 11.48,
        "最低价": 11.21,
        "昨收价": 11.27,
        "总市值": 152872070.0,
        "市盈率": 25.15,
        "成交量": 35319.0,
        "成交额": 400587.0,
        "振幅": 2.4,
        "换手率": 0.26,
        "symbol": "105.OFS"
    },
    {
        "序号": 2092,
        "name": "Cyclerion Therapeutics Inc",
        "最新价": 2.45,
        "涨跌额": 0.03,
        "涨跌幅": 1.24,
        "开盘价": 2.52,
        "最高价": 2.54,
        "最低价": 2.41,
        "昨收价": 2.42,
        "总市值": 5990485.0,
        "市盈率": -0.56,
        "成交量": 6004.0,
        "成交额": 14944.0,
        "振幅": 5.37,
        "换手率": 0.25,
        "symbol": "105.CYCN"
    },
    {
        "序号": 2093,
        "name": "油气开采ETF-SPDR S&P",
        "最新价": 132.34,
        "涨跌额": 1.62,
        "涨跌幅": 1.24,
        "开盘价": 131.96,
        "最高价": 132.98,
        "最低价": 131.38,
        "昨收价": 130.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 4190883.0,
        "成交额": 553810992.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.XOP"
    },
    {
        "序号": 2094,
        "name": "Pinnacle Financial Partners Inc",
        "最新价": 82.58,
        "涨跌额": 1.01,
        "涨跌幅": 1.24,
        "开盘价": 81.76,
        "最高价": 83.2,
        "最低价": 81.76,
        "昨收价": 81.57,
        "总市值": 6340013353.0,
        "市盈率": 10.48,
        "成交量": 541927.0,
        "成交额": 44711999.0,
        "振幅": 1.77,
        "换手率": 0.71,
        "symbol": "105.PNFP"
    },
    {
        "序号": 2095,
        "name": "ROBO Global Artificial Intellig",
        "最新价": 39.26,
        "涨跌额": 0.48,
        "涨跌幅": 1.24,
        "开盘价": 38.75,
        "最高价": 39.31,
        "最低价": 38.3,
        "昨收价": 38.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 17346.0,
        "成交额": 677962.0,
        "振幅": 2.6,
        "换手率": null,
        "symbol": "107.THNQ"
    },
    {
        "序号": 2096,
        "name": "Roundhill S&P Global Luxury ETF",
        "最新价": 24.54,
        "涨跌额": 0.3,
        "涨跌幅": 1.24,
        "开盘价": 24.54,
        "最高价": 24.54,
        "最低价": 24.54,
        "昨收价": 24.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 245.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LUXX"
    },
    {
        "序号": 2097,
        "name": "Xometry Inc-A",
        "最新价": 24.58,
        "涨跌额": 0.3,
        "涨跌幅": 1.24,
        "开盘价": 24.0,
        "最高价": 24.8,
        "最低价": 23.75,
        "昨收价": 24.28,
        "总市值": 1181584148.0,
        "市盈率": -14.53,
        "成交量": 342801.0,
        "成交额": 8358116.0,
        "振幅": 4.32,
        "换手率": 0.71,
        "symbol": "105.XMTR"
    },
    {
        "序号": 2098,
        "name": "New York Mortgage Trust Inc Ser",
        "最新价": 17.24,
        "涨跌额": 0.21,
        "涨跌幅": 1.23,
        "开盘价": 17.18,
        "最高价": 17.25,
        "最低价": 17.1,
        "昨收价": 17.03,
        "总市值": 51267209.0,
        "市盈率": null,
        "成交量": 3689.0,
        "成交额": 63304.0,
        "振幅": 0.88,
        "换手率": 0.12,
        "symbol": "105.NYMTZ"
    },
    {
        "序号": 2099,
        "name": "Hennessy Advisors Inc",
        "最新价": 6.57,
        "涨跌额": 0.08,
        "涨跌幅": 1.23,
        "开盘价": 6.58,
        "最高价": 6.58,
        "最低价": 6.55,
        "昨收价": 6.49,
        "总市值": 50417319.0,
        "市盈率": 10.57,
        "成交量": 1491.0,
        "成交额": 9794.0,
        "振幅": 0.46,
        "换手率": 0.02,
        "symbol": "105.HNNA"
    },
    {
        "序号": 2100,
        "name": "Global X Cybersecurity ETF",
        "最新价": 27.95,
        "涨跌额": 0.34,
        "涨跌幅": 1.23,
        "开盘价": 27.45,
        "最高价": 27.99,
        "最低价": 27.45,
        "昨收价": 27.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 213919.0,
        "成交额": 5945018.0,
        "振幅": 1.96,
        "换手率": null,
        "symbol": "105.BUG"
    },
    {
        "序号": 2101,
        "name": "Vitesse Energy Inc",
        "最新价": 23.03,
        "涨跌额": 0.28,
        "涨跌幅": 1.23,
        "开盘价": 22.75,
        "最高价": 23.39,
        "最低价": 22.73,
        "昨收价": 22.75,
        "总市值": 662973546.0,
        "市盈率": -41.2,
        "成交量": 228249.0,
        "成交额": 5271852.0,
        "振幅": 2.9,
        "换手率": 0.79,
        "symbol": "106.VTS"
    },
    {
        "序号": 2102,
        "name": "乌龟海岸",
        "最新价": 11.54,
        "涨跌额": 0.14,
        "涨跌幅": 1.23,
        "开盘价": 11.41,
        "最高价": 11.66,
        "最低价": 11.38,
        "昨收价": 11.4,
        "总市值": 200842310.0,
        "市盈率": -4.06,
        "成交量": 151663.0,
        "成交额": 1750432.0,
        "振幅": 2.46,
        "换手率": 0.87,
        "symbol": "105.HEAR"
    },
    {
        "序号": 2103,
        "name": "Essent Group Ltd",
        "最新价": 49.46,
        "涨跌额": 0.6,
        "涨跌幅": 1.23,
        "开盘价": 48.86,
        "最高价": 49.72,
        "最低价": 48.73,
        "昨收价": 48.86,
        "总市值": 5280348314.0,
        "市盈率": 7.9,
        "成交量": 398178.0,
        "成交额": 19681240.0,
        "振幅": 2.03,
        "换手率": 0.37,
        "symbol": "106.ESNT"
    },
    {
        "序号": 2104,
        "name": "DigitalBridge Group Inc Series ",
        "最新价": 22.27,
        "涨跌额": 0.27,
        "涨跌幅": 1.23,
        "开盘价": 21.65,
        "最高价": 22.39,
        "最低价": 21.65,
        "昨收价": 22.0,
        "总市值": 286548090.0,
        "市盈率": null,
        "成交量": 6339.0,
        "成交额": 140546.0,
        "振幅": 3.36,
        "换手率": 0.05,
        "symbol": "106.DBRG_I"
    },
    {
        "序号": 2105,
        "name": "The Acquirers Fund",
        "最新价": 33.0,
        "涨跌额": 0.4,
        "涨跌幅": 1.23,
        "开盘价": 33.07,
        "最高价": 33.1,
        "最低价": 32.88,
        "昨收价": 32.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 4482.0,
        "成交额": 147602.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.ZIG"
    },
    {
        "序号": 2106,
        "name": "Theravance Biopharma Inc",
        "最新价": 10.73,
        "涨跌额": 0.13,
        "涨跌幅": 1.23,
        "开盘价": 10.62,
        "最高价": 10.82,
        "最低价": 10.62,
        "昨收价": 10.6,
        "总市值": 534331102.0,
        "市盈率": -9.37,
        "成交量": 324171.0,
        "成交额": 3480763.0,
        "振幅": 1.89,
        "换手率": 0.65,
        "symbol": "105.TBPH"
    },
    {
        "序号": 2107,
        "name": "艾奇资本",
        "最新价": 79.3,
        "涨跌额": 0.96,
        "涨跌幅": 1.23,
        "开盘价": 78.68,
        "最高价": 79.39,
        "最低价": 78.32,
        "昨收价": 78.34,
        "总市值": 29592532384.0,
        "市盈率": 9.97,
        "成交量": 1372916.0,
        "成交额": 108536174.0,
        "振幅": 1.37,
        "换手率": 0.37,
        "symbol": "105.ACGL"
    },
    {
        "序号": 2108,
        "name": "北美多媒体网络ETF-iShares",
        "最新价": 61.15,
        "涨跌额": 0.74,
        "涨跌幅": 1.22,
        "开盘价": 60.32,
        "最高价": 61.16,
        "最低价": 60.32,
        "昨收价": 60.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 6293.0,
        "成交额": 382360.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "107.IGN"
    },
    {
        "序号": 2109,
        "name": "Glaukos Corp",
        "最新价": 63.63,
        "涨跌额": 0.77,
        "涨跌幅": 1.22,
        "开盘价": 62.29,
        "最高价": 63.98,
        "最低价": 62.15,
        "昨收价": 62.86,
        "总市值": 3105117339.0,
        "市盈率": -24.01,
        "成交量": 421685.0,
        "成交额": 26677499.0,
        "振幅": 2.91,
        "换手率": 0.86,
        "symbol": "106.GKOS"
    },
    {
        "序号": 2110,
        "name": "家园建设ETF-SPDR S&P",
        "最新价": 88.47,
        "涨跌额": 1.07,
        "涨跌幅": 1.22,
        "开盘价": 87.34,
        "最高价": 88.83,
        "最低价": 87.34,
        "昨收价": 87.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 3297601.0,
        "成交额": 291134720.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "107.XHB"
    },
    {
        "序号": 2111,
        "name": "SiriusPoint Ltd",
        "最新价": 10.78,
        "涨跌额": 0.13,
        "涨跌幅": 1.22,
        "开盘价": 10.7,
        "最高价": 10.84,
        "最低价": 10.59,
        "昨收价": 10.65,
        "总市值": 1806514481.0,
        "市盈率": 7.7,
        "成交量": 469112.0,
        "成交额": 5040542.0,
        "振幅": 2.35,
        "换手率": 0.28,
        "symbol": "106.SPNT"
    },
    {
        "序号": 2112,
        "name": "艾斯奎尔金融控股",
        "最新价": 47.27,
        "涨跌额": 0.57,
        "涨跌幅": 1.22,
        "开盘价": 47.0,
        "最高价": 47.44,
        "最低价": 46.72,
        "昨收价": 46.7,
        "总市值": 387811872.0,
        "市盈率": 9.64,
        "成交量": 22313.0,
        "成交额": 1046249.0,
        "振幅": 1.54,
        "换手率": 0.27,
        "symbol": "105.ESQ"
    },
    {
        "序号": 2113,
        "name": "SPDR S&P Software & Services ET",
        "最新价": 141.85,
        "涨跌额": 1.71,
        "涨跌幅": 1.22,
        "开盘价": 139.17,
        "最高价": 142.15,
        "最低价": 139.09,
        "昨收价": 140.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 24228.0,
        "成交额": 3432006.0,
        "振幅": 2.18,
        "换手率": null,
        "symbol": "107.XSW"
    },
    {
        "序号": 2114,
        "name": "iCAD Inc",
        "最新价": 1.66,
        "涨跌额": 0.02,
        "涨跌幅": 1.22,
        "开盘价": 1.64,
        "最高价": 1.69,
        "最低价": 1.57,
        "昨收价": 1.64,
        "总市值": 43745537.0,
        "市盈率": -4.38,
        "成交量": 149570.0,
        "成交额": 243794.0,
        "振幅": 7.32,
        "换手率": 0.57,
        "symbol": "105.ICAD"
    },
    {
        "序号": 2115,
        "name": "AXS Cannabis ETF",
        "最新价": 17.47,
        "涨跌额": 0.21,
        "涨跌幅": 1.22,
        "开盘价": 16.87,
        "最高价": 17.5,
        "最低价": 16.87,
        "昨收价": 17.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 4081.0,
        "成交额": 71145.0,
        "振幅": 3.65,
        "换手率": null,
        "symbol": "107.THCX"
    },
    {
        "序号": 2116,
        "name": "Udemy Inc",
        "最新价": 14.98,
        "涨跌额": 0.18,
        "涨跌幅": 1.22,
        "开盘价": 14.62,
        "最高价": 15.13,
        "最低价": 14.62,
        "昨收价": 14.8,
        "总市值": 2296874921.0,
        "市盈率": -16.5,
        "成交量": 1074749.0,
        "成交额": 16103292.0,
        "振幅": 3.45,
        "换手率": 0.7,
        "symbol": "105.UDMY"
    },
    {
        "序号": 2117,
        "name": "保德信金融",
        "最新价": 100.87,
        "涨跌额": 1.21,
        "涨跌幅": 1.21,
        "开盘价": 99.75,
        "最高价": 101.13,
        "最低价": 99.43,
        "昨收价": 99.66,
        "总市值": 36445697183.0,
        "市盈率": 27.44,
        "成交量": 2120921.0,
        "成交额": 213414936.0,
        "振幅": 1.71,
        "换手率": 0.59,
        "symbol": "106.PRU"
    },
    {
        "序号": 2118,
        "name": "AxoGen Inc",
        "最新价": 7.51,
        "涨跌额": 0.09,
        "涨跌幅": 1.21,
        "开盘价": 7.38,
        "最高价": 7.66,
        "最低价": 7.38,
        "昨收价": 7.42,
        "总市值": 323257481.0,
        "市盈率": -13.91,
        "成交量": 324804.0,
        "成交额": 2440894.0,
        "振幅": 3.77,
        "换手率": 0.75,
        "symbol": "105.AXGN"
    },
    {
        "序号": 2119,
        "name": "Trustmark Corp",
        "最新价": 25.88,
        "涨跌额": 0.31,
        "涨跌幅": 1.21,
        "开盘价": 25.56,
        "最高价": 26.01,
        "最低价": 25.4,
        "昨收价": 25.57,
        "总市值": 1580502910.0,
        "市盈率": 16.58,
        "成交量": 257741.0,
        "成交额": 6666323.0,
        "振幅": 2.39,
        "换手率": 0.42,
        "symbol": "105.TRMK"
    },
    {
        "序号": 2120,
        "name": "Invesco S&P Ultra Dividend Reve",
        "最新价": 41.86,
        "涨跌额": 0.5,
        "涨跌幅": 1.21,
        "开盘价": 41.47,
        "最高价": 42.0,
        "最低价": 41.45,
        "昨收价": 41.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 63950.0,
        "成交额": 2676563.0,
        "振幅": 1.33,
        "换手率": null,
        "symbol": "107.RDIV"
    },
    {
        "序号": 2121,
        "name": "Direxion Daily AAPL Bull 1.5X S",
        "最新价": 28.47,
        "涨跌额": 0.34,
        "涨跌幅": 1.21,
        "开盘价": 28.12,
        "最高价": 28.5,
        "最低价": 28.01,
        "昨收价": 28.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 96072.0,
        "成交额": 2721318.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "105.AAPU"
    },
    {
        "序号": 2122,
        "name": "瑞银集团",
        "最新价": 28.51,
        "涨跌额": 0.34,
        "涨跌幅": 1.21,
        "开盘价": 28.3,
        "最高价": 28.54,
        "最低价": 28.21,
        "昨收价": 28.17,
        "总市值": 92180387915.0,
        "市盈率": 2.98,
        "成交量": 2540580.0,
        "成交额": 72134866.0,
        "振幅": 1.17,
        "换手率": 0.08,
        "symbol": "106.UBS"
    },
    {
        "序号": 2123,
        "name": "桑坦德银行(US)",
        "最新价": 4.2,
        "涨跌额": 0.05,
        "涨跌幅": 1.2,
        "开盘价": 4.16,
        "最高价": 4.22,
        "最低价": 4.16,
        "昨收价": 4.15,
        "总市值": 67973413448.0,
        "市盈率": 5.84,
        "成交量": 1692991.0,
        "成交额": 7102665.0,
        "振幅": 1.45,
        "换手率": 0.01,
        "symbol": "106.SAN"
    },
    {
        "序号": 2124,
        "name": "AMMO Inc Pfd",
        "最新价": 26.06,
        "涨跌额": 0.31,
        "涨跌幅": 1.2,
        "开盘价": 26.08,
        "最高价": 26.08,
        "最低价": 26.0,
        "昨收价": 25.75,
        "总市值": 36484000.0,
        "市盈率": null,
        "成交量": 10487.0,
        "成交额": 272839.0,
        "振幅": 0.31,
        "换手率": 0.75,
        "symbol": "105.POWWP"
    },
    {
        "序号": 2125,
        "name": "Direxion Daily MSCI Mexico Bull",
        "最新价": 28.61,
        "涨跌额": 0.34,
        "涨跌幅": 1.2,
        "开盘价": 28.08,
        "最高价": 28.65,
        "最低价": 28.08,
        "昨收价": 28.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 2714.0,
        "成交额": 77227.0,
        "振幅": 2.02,
        "换手率": null,
        "symbol": "107.MEXX"
    },
    {
        "序号": 2126,
        "name": "ProShares Big Data Refiners ETF",
        "最新价": 31.99,
        "涨跌额": 0.38,
        "涨跌幅": 1.2,
        "开盘价": 31.4,
        "最高价": 31.99,
        "最低价": 31.4,
        "昨收价": 31.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 900.0,
        "成交额": 28377.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "107.DAT"
    },
    {
        "序号": 2127,
        "name": "Key Tronic Corp",
        "最新价": 4.21,
        "涨跌额": 0.05,
        "涨跌幅": 1.2,
        "开盘价": 4.01,
        "最高价": 4.21,
        "最低价": 4.0,
        "昨收价": 4.16,
        "总市值": 45307477.0,
        "市盈率": 10.44,
        "成交量": 24836.0,
        "成交额": 102908.0,
        "振幅": 5.05,
        "换手率": 0.23,
        "symbol": "105.KTCC"
    },
    {
        "序号": 2128,
        "name": "Direxion Daily S&P 500 Bull 3X ",
        "最新价": 94.34,
        "涨跌额": 1.12,
        "涨跌幅": 1.2,
        "开盘价": 92.71,
        "最高价": 94.7,
        "最低价": 92.56,
        "昨收价": 93.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 9790654.0,
        "成交额": 918510720.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.SPXL"
    },
    {
        "序号": 2129,
        "name": "TopBuild Corp",
        "最新价": 320.27,
        "涨跌额": 3.8,
        "涨跌幅": 1.2,
        "开盘价": 318.29,
        "最高价": 323.17,
        "最低价": 316.03,
        "昨收价": 316.47,
        "总市值": 10176241045.0,
        "市盈率": 16.64,
        "成交量": 202707.0,
        "成交额": 64839430.0,
        "振幅": 2.26,
        "换手率": 0.64,
        "symbol": "106.BLD"
    },
    {
        "序号": 2130,
        "name": "尼日利亚ETF-Global X MSCI",
        "最新价": 7.59,
        "涨跌额": 0.09,
        "涨跌幅": 1.2,
        "开盘价": 7.4,
        "最高价": 7.59,
        "最低价": 7.4,
        "昨收价": 7.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 4669.0,
        "成交额": 34877.0,
        "振幅": 2.53,
        "换手率": null,
        "symbol": "107.NGE"
    },
    {
        "序号": 2131,
        "name": "阿瑞斯商业地产",
        "最新价": 10.12,
        "涨跌额": 0.12,
        "涨跌幅": 1.2,
        "开盘价": 9.97,
        "最高价": 10.17,
        "最低价": 9.9,
        "昨收价": 10.0,
        "总市值": 547902447.0,
        "市盈率": 158.54,
        "成交量": 291782.0,
        "成交额": 2939675.0,
        "振幅": 2.7,
        "换手率": 0.54,
        "symbol": "106.ACRE"
    },
    {
        "序号": 2132,
        "name": "Invesco Russell 2000 Dynamic Mu",
        "最新价": 36.28,
        "涨跌额": 0.43,
        "涨跌幅": 1.2,
        "开盘价": 36.12,
        "最高价": 36.34,
        "最低价": 35.99,
        "昨收价": 35.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 34506.0,
        "成交额": 1247314.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.OMFS"
    },
    {
        "序号": 2133,
        "name": "天然气ETF-First Trust",
        "最新价": 23.64,
        "涨跌额": 0.28,
        "涨跌幅": 1.2,
        "开盘价": 23.53,
        "最高价": 23.72,
        "最低价": 23.46,
        "昨收价": 23.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 301193.0,
        "成交额": 7100384.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.FCG"
    },
    {
        "序号": 2134,
        "name": "三倍做多纳斯达克100ETF",
        "最新价": 44.83,
        "涨跌额": 0.53,
        "涨跌幅": 1.2,
        "开盘价": 43.65,
        "最高价": 45.0,
        "最低价": 43.59,
        "昨收价": 44.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 90894450.0,
        "成交额": 4043339632.0,
        "振幅": 3.18,
        "换手率": null,
        "symbol": "105.TQQQ"
    },
    {
        "序号": 2135,
        "name": "Caesarstone Ltd",
        "最新价": 4.23,
        "涨跌额": 0.05,
        "涨跌幅": 1.2,
        "开盘价": 4.28,
        "最高价": 4.28,
        "最低价": 4.1,
        "昨收价": 4.18,
        "总市值": 146037802.0,
        "市盈率": -1.11,
        "成交量": 10065.0,
        "成交额": 42263.0,
        "振幅": 4.31,
        "换手率": 0.03,
        "symbol": "105.CSTE"
    },
    {
        "序号": 2136,
        "name": "第一公民银行股份-A",
        "最新价": 1446.47,
        "涨跌额": 17.09,
        "涨跌幅": 1.2,
        "开盘价": 1422.06,
        "最高价": 1459.0,
        "最低价": 1422.06,
        "昨收价": 1429.38,
        "总市值": 21002893386.0,
        "市盈率": 1.87,
        "成交量": 48139.0,
        "成交额": 69697963.0,
        "振幅": 2.58,
        "换手率": 0.33,
        "symbol": "105.FCNCA"
    },
    {
        "序号": 2137,
        "name": "Miromatrix Medical Inc",
        "最新价": 3.39,
        "涨跌额": 0.04,
        "涨跌幅": 1.19,
        "开盘价": 3.34,
        "最高价": 3.39,
        "最低价": 3.33,
        "昨收价": 3.35,
        "总市值": 92951183.0,
        "市盈率": -3.39,
        "成交量": 41462.0,
        "成交额": 139122.0,
        "振幅": 1.79,
        "换手率": 0.15,
        "symbol": "105.MIRO"
    },
    {
        "序号": 2138,
        "name": "Chatham Lodging信托",
        "最新价": 10.17,
        "涨跌额": 0.12,
        "涨跌幅": 1.19,
        "开盘价": 9.95,
        "最高价": 10.19,
        "最低价": 9.95,
        "昨收价": 10.05,
        "总市值": 496895450.0,
        "市盈率": 51.79,
        "成交量": 187791.0,
        "成交额": 1900366.0,
        "振幅": 2.39,
        "换手率": 0.38,
        "symbol": "106.CLDT"
    },
    {
        "序号": 2139,
        "name": "Clockwise Capital Innovation ET",
        "最新价": 24.6,
        "涨跌额": 0.29,
        "涨跌幅": 1.19,
        "开盘价": 24.24,
        "最高价": 24.6,
        "最低价": 24.24,
        "昨收价": 24.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 2727.0,
        "成交额": 66621.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "107.TIME"
    },
    {
        "序号": 2140,
        "name": "泰纳瑞斯钢铁",
        "最新价": 33.96,
        "涨跌额": 0.4,
        "涨跌幅": 1.19,
        "开盘价": 33.63,
        "最高价": 34.08,
        "最低价": 33.58,
        "昨收价": 33.56,
        "总市值": 20045515373.0,
        "市盈率": 5.57,
        "成交量": 1588709.0,
        "成交额": 53797645.0,
        "振幅": 1.49,
        "换手率": 0.27,
        "symbol": "106.TS"
    },
    {
        "序号": 2141,
        "name": "SmartETFs Advertising & Marketi",
        "最新价": 14.47,
        "涨跌额": 0.17,
        "涨跌幅": 1.19,
        "开盘价": 14.3,
        "最高价": 14.47,
        "最低价": 14.3,
        "昨收价": 14.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 406.0,
        "成交额": 5805.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.MRAD"
    },
    {
        "序号": 2142,
        "name": "法国ETF-iShares MSCI",
        "最新价": 38.31,
        "涨跌额": 0.45,
        "涨跌幅": 1.19,
        "开盘价": 38.0,
        "最高价": 38.31,
        "最低价": 37.99,
        "昨收价": 37.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 281392.0,
        "成交额": 10752863.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.EWQ"
    },
    {
        "序号": 2143,
        "name": "能源指数ETF-Vanguard",
        "最新价": 115.0,
        "涨跌额": 1.35,
        "涨跌幅": 1.19,
        "开盘价": 114.45,
        "最高价": 115.33,
        "最低价": 114.24,
        "昨收价": 113.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 1291829.0,
        "成交额": 148302563.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.VDE"
    },
    {
        "序号": 2144,
        "name": "潮水海运",
        "最新价": 58.79,
        "涨跌额": 0.69,
        "涨跌幅": 1.19,
        "开盘价": 58.4,
        "最高价": 59.78,
        "最低价": 58.4,
        "昨收价": 58.1,
        "总市值": 3106571656.0,
        "市盈率": 44.29,
        "成交量": 417246.0,
        "成交额": 24600153.0,
        "振幅": 2.38,
        "换手率": 0.79,
        "symbol": "106.TDW"
    },
    {
        "序号": 2145,
        "name": "西方石油(权证)",
        "最新价": 34.94,
        "涨跌额": 0.41,
        "涨跌幅": 1.19,
        "开盘价": 35.19,
        "最高价": 35.55,
        "最低价": 34.51,
        "昨收价": 34.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 223604.0,
        "成交额": 7820631.0,
        "振幅": 3.01,
        "换手率": null,
        "symbol": "106.OXY_WS"
    },
    {
        "序号": 2146,
        "name": "Universal Electronics Inc",
        "最新价": 7.68,
        "涨跌额": 0.09,
        "涨跌幅": 1.19,
        "开盘价": 7.66,
        "最高价": 7.82,
        "最低价": 7.58,
        "昨收价": 7.59,
        "总市值": 99472458.0,
        "市盈率": -1.01,
        "成交量": 39090.0,
        "成交额": 301787.0,
        "振幅": 3.16,
        "换手率": 0.3,
        "symbol": "105.UEIC"
    },
    {
        "序号": 2147,
        "name": "Proto Labs Inc",
        "最新价": 37.55,
        "涨跌额": 0.44,
        "涨跌幅": 1.19,
        "开盘价": 36.96,
        "最高价": 37.63,
        "最低价": 36.85,
        "昨收价": 37.11,
        "总市值": 968260658.0,
        "市盈率": -9.24,
        "成交量": 276488.0,
        "成交额": 10340826.0,
        "振幅": 2.1,
        "换手率": 1.07,
        "symbol": "106.PRLB"
    },
    {
        "序号": 2148,
        "name": "ETRACS Quarterly Pay 1.5x Lever",
        "最新价": 31.62,
        "涨跌额": 0.37,
        "涨跌幅": 1.18,
        "开盘价": 31.54,
        "最高价": 31.62,
        "最低价": 31.54,
        "昨收价": 31.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 246.0,
        "成交额": 7758.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.BDCX"
    },
    {
        "序号": 2149,
        "name": "EverQuote Inc-A",
        "最新价": 10.26,
        "涨跌额": 0.12,
        "涨跌幅": 1.18,
        "开盘价": 10.21,
        "最高价": 10.52,
        "最低价": 10.17,
        "昨收价": 10.14,
        "总市值": 345884699.0,
        "市盈率": -6.47,
        "成交量": 318795.0,
        "成交额": 3291479.0,
        "振幅": 3.45,
        "换手率": 0.95,
        "symbol": "105.EVER"
    },
    {
        "序号": 2150,
        "name": "稀土战略原料ETF-VanEck Vectors",
        "最新价": 57.35,
        "涨跌额": 0.67,
        "涨跌幅": 1.18,
        "开盘价": 56.88,
        "最高价": 57.88,
        "最低价": 56.29,
        "昨收价": 56.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 86956.0,
        "成交额": 4991664.0,
        "振幅": 2.81,
        "换手率": null,
        "symbol": "107.REMX"
    },
    {
        "序号": 2151,
        "name": "威世科技",
        "最新价": 23.13,
        "涨跌额": 0.27,
        "涨跌幅": 1.18,
        "开盘价": 22.8,
        "最高价": 23.48,
        "最低价": 22.8,
        "昨收价": 22.86,
        "总市值": 3199705828.0,
        "市盈率": 9.27,
        "成交量": 1279364.0,
        "成交额": 29735288.0,
        "振幅": 2.97,
        "换手率": 0.92,
        "symbol": "106.VSH"
    },
    {
        "序号": 2152,
        "name": "HOST酒店及度假村",
        "最新价": 17.99,
        "涨跌额": 0.21,
        "涨跌幅": 1.18,
        "开盘价": 17.8,
        "最高价": 18.01,
        "最低价": 17.68,
        "昨收价": 17.78,
        "总市值": 12690811846.0,
        "市盈率": 16.81,
        "成交量": 4917629.0,
        "成交额": 88129319.0,
        "振幅": 1.86,
        "换手率": 0.7,
        "symbol": "105.HST"
    },
    {
        "序号": 2153,
        "name": "优步",
        "最新价": 61.7,
        "涨跌额": 0.72,
        "涨跌幅": 1.18,
        "开盘价": 60.51,
        "最高价": 62.25,
        "最低价": 60.51,
        "昨收价": 60.98,
        "总市值": 126969852791.0,
        "市盈率": 120.58,
        "成交量": 28852116.0,
        "成交额": 1780886032.0,
        "振幅": 2.85,
        "换手率": 1.4,
        "symbol": "106.UBER"
    },
    {
        "序号": 2154,
        "name": "OFG Bancorp",
        "最新价": 36.0,
        "涨跌额": 0.42,
        "涨跌幅": 1.18,
        "开盘价": 35.39,
        "最高价": 36.12,
        "最低价": 35.39,
        "昨收价": 35.58,
        "总市值": 1694081016.0,
        "市盈率": 9.33,
        "成交量": 112988.0,
        "成交额": 4061304.0,
        "振幅": 2.05,
        "换手率": 0.24,
        "symbol": "106.OFG"
    },
    {
        "序号": 2155,
        "name": "Molina Healthcare Inc",
        "最新价": 367.72,
        "涨跌额": 4.29,
        "涨跌幅": 1.18,
        "开盘价": 364.9,
        "最高价": 367.89,
        "最低价": 362.55,
        "昨收价": 363.43,
        "总市值": 21438076000.0,
        "市盈率": 23.03,
        "成交量": 341588.0,
        "成交额": 125041093.0,
        "振幅": 1.47,
        "换手率": 0.59,
        "symbol": "106.MOH"
    },
    {
        "序号": 2156,
        "name": "OneWater Marine Inc-A",
        "最新价": 27.45,
        "涨跌额": 0.32,
        "涨跌幅": 1.18,
        "开盘价": 27.0,
        "最高价": 27.72,
        "最低价": 26.92,
        "昨收价": 27.13,
        "总市值": 433355977.0,
        "市盈率": 5.49,
        "成交量": 47799.0,
        "成交额": 1314076.0,
        "振幅": 2.95,
        "换手率": 0.3,
        "symbol": "105.ONEW"
    },
    {
        "序号": 2157,
        "name": "荷兰国际",
        "最新价": 14.59,
        "涨跌额": 0.17,
        "涨跌幅": 1.18,
        "开盘价": 14.48,
        "最高价": 14.64,
        "最低价": 14.47,
        "昨收价": 14.42,
        "总市值": 51102934000.0,
        "市盈率": 3.11,
        "成交量": 2617337.0,
        "成交额": 38125806.0,
        "振幅": 1.18,
        "换手率": 0.07,
        "symbol": "106.ING"
    },
    {
        "序号": 2158,
        "name": "Tapestry Inc",
        "最新价": 33.53,
        "涨跌额": 0.39,
        "涨跌幅": 1.18,
        "开盘价": 33.03,
        "最高价": 33.65,
        "最低价": 32.91,
        "昨收价": 33.14,
        "总市值": 7684620763.0,
        "市盈率": 8.21,
        "成交量": 3590878.0,
        "成交额": 119875023.0,
        "振幅": 2.23,
        "换手率": 1.57,
        "symbol": "106.TPR"
    },
    {
        "序号": 2159,
        "name": "US Cellular Corp Note",
        "最新价": 17.23,
        "涨跌额": 0.2,
        "涨跌幅": 1.17,
        "开盘价": 17.0,
        "最高价": 17.27,
        "最低价": 16.62,
        "昨收价": 17.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 21442.0,
        "成交额": 366422.0,
        "振幅": 3.82,
        "换手率": null,
        "symbol": "106.UZF"
    },
    {
        "序号": 2160,
        "name": "Dream Finders Homes Inc-A",
        "最新价": 28.46,
        "涨跌额": 0.33,
        "涨跌幅": 1.17,
        "开盘价": 27.9,
        "最高价": 28.78,
        "最低价": 27.9,
        "昨收价": 28.13,
        "总市值": 2649861563.0,
        "市盈率": 9.45,
        "成交量": 834860.0,
        "成交额": 23770378.0,
        "振幅": 3.13,
        "换手率": 0.9,
        "symbol": "106.DFH"
    },
    {
        "序号": 2161,
        "name": "艺电",
        "最新价": 137.14,
        "涨跌额": 1.59,
        "涨跌幅": 1.17,
        "开盘价": 135.0,
        "最高价": 137.38,
        "最低价": 134.87,
        "昨收价": 135.55,
        "总市值": 36908430190.0,
        "市盈率": 37.17,
        "成交量": 1531409.0,
        "成交额": 209753801.0,
        "振幅": 1.85,
        "换手率": 0.57,
        "symbol": "105.EA"
    },
    {
        "序号": 2162,
        "name": "Credit Acceptance Corp",
        "最新价": 461.07,
        "涨跌额": 5.34,
        "涨跌幅": 1.17,
        "开盘价": 454.47,
        "最高价": 462.83,
        "最低价": 452.21,
        "昨收价": 455.73,
        "总市值": 5794108543.0,
        "市盈率": 18.12,
        "成交量": 44955.0,
        "成交额": 20613491.0,
        "振幅": 2.33,
        "换手率": 0.36,
        "symbol": "105.CACC"
    },
    {
        "序号": 2163,
        "name": "CarGurus Inc-A",
        "最新价": 21.59,
        "涨跌额": 0.25,
        "涨跌幅": 1.17,
        "开盘价": 21.31,
        "最高价": 21.72,
        "最低价": 21.28,
        "昨收价": 21.34,
        "总市值": 2423894835.0,
        "市盈率": 30.44,
        "成交量": 629340.0,
        "成交额": 13539377.0,
        "振幅": 2.06,
        "换手率": 0.56,
        "symbol": "105.CARG"
    },
    {
        "序号": 2164,
        "name": "卡津斯不动产",
        "最新价": 22.46,
        "涨跌额": 0.26,
        "涨跌幅": 1.17,
        "开盘价": 22.03,
        "最高价": 22.49,
        "最低价": 21.89,
        "昨收价": 22.2,
        "总市值": 3408838964.0,
        "市盈率": 38.61,
        "成交量": 1353848.0,
        "成交额": 30211404.0,
        "振幅": 2.7,
        "换手率": 0.89,
        "symbol": "106.CUZ"
    },
    {
        "序号": 2165,
        "name": "Bridgewater Bancshares Inc",
        "最新价": 11.23,
        "涨跌额": 0.13,
        "涨跌幅": 1.17,
        "开盘价": 11.08,
        "最高价": 11.35,
        "最低价": 11.08,
        "昨收价": 11.1,
        "总市值": 314446401.0,
        "市盈率": 7.02,
        "成交量": 92397.0,
        "成交额": 1039841.0,
        "振幅": 2.43,
        "换手率": 0.33,
        "symbol": "105.BWB"
    },
    {
        "序号": 2166,
        "name": "基立福",
        "最新价": 10.37,
        "涨跌额": 0.12,
        "涨跌幅": 1.17,
        "开盘价": 10.31,
        "最高价": 10.41,
        "最低价": 10.31,
        "昨收价": 10.25,
        "总市值": 7129944396.0,
        "市盈率": 666.96,
        "成交量": 2175986.0,
        "成交额": 22539713.0,
        "振幅": 0.98,
        "换手率": 0.32,
        "symbol": "105.GRFS"
    },
    {
        "序号": 2167,
        "name": "AssetMark Financial Holdings In",
        "最新价": 26.79,
        "涨跌额": 0.31,
        "涨跌幅": 1.17,
        "开盘价": 26.5,
        "最高价": 26.86,
        "最低价": 26.43,
        "昨收价": 26.48,
        "总市值": 1989568941.0,
        "市盈率": 17.44,
        "成交量": 78772.0,
        "成交额": 2106186.0,
        "振幅": 1.62,
        "换手率": 0.11,
        "symbol": "106.AMK"
    },
    {
        "序号": 2168,
        "name": "First Trust RBA American Indust",
        "最新价": 53.62,
        "涨跌额": 0.62,
        "涨跌幅": 1.17,
        "开盘价": 52.97,
        "最高价": 53.73,
        "最低价": 52.97,
        "昨收价": 53.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 60360.0,
        "成交额": 3224296.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "105.AIRR"
    },
    {
        "序号": 2169,
        "name": "博伊德赌场",
        "最新价": 59.72,
        "涨跌额": 0.69,
        "涨跌幅": 1.17,
        "开盘价": 58.98,
        "最高价": 59.81,
        "最低价": 58.72,
        "昨收价": 59.03,
        "总市值": 5844325986.0,
        "市盈率": 8.35,
        "成交量": 899449.0,
        "成交额": 53546290.0,
        "振幅": 1.85,
        "换手率": 0.92,
        "symbol": "106.BYD"
    },
    {
        "序号": 2170,
        "name": "GeoPark Ltd",
        "最新价": 8.66,
        "涨跌额": 0.1,
        "涨跌幅": 1.17,
        "开盘价": 8.57,
        "最高价": 8.72,
        "最低价": 8.56,
        "昨收价": 8.56,
        "总市值": 485981689.0,
        "市盈率": 3.55,
        "成交量": 204184.0,
        "成交额": 1766567.0,
        "振幅": 1.87,
        "换手率": 0.36,
        "symbol": "106.GPRK"
    },
    {
        "序号": 2171,
        "name": "GCM Grosvenor Inc-A",
        "最新价": 8.66,
        "涨跌额": 0.1,
        "涨跌幅": 1.17,
        "开盘价": 8.58,
        "最高价": 8.7,
        "最低价": 8.52,
        "昨收价": 8.56,
        "总市值": 1621358186.0,
        "市盈率": 116.59,
        "成交量": 99856.0,
        "成交额": 861937.0,
        "振幅": 2.1,
        "换手率": 0.05,
        "symbol": "105.GCMG"
    },
    {
        "序号": 2172,
        "name": "KFA Mount Lucas Managed Futures",
        "最新价": 29.45,
        "涨跌额": 0.34,
        "涨跌幅": 1.17,
        "开盘价": 29.4,
        "最高价": 29.45,
        "最低价": 29.22,
        "昨收价": 29.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 83642.0,
        "成交额": 2460028.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.KMLM"
    },
    {
        "序号": 2173,
        "name": "Eve Holding Inc",
        "最新价": 6.93,
        "涨跌额": 0.08,
        "涨跌幅": 1.17,
        "开盘价": 6.83,
        "最高价": 7.03,
        "最低价": 6.72,
        "昨收价": 6.85,
        "总市值": 1866658016.0,
        "市盈率": -17.21,
        "成交量": 50230.0,
        "成交额": 347122.0,
        "振幅": 4.53,
        "换手率": 0.02,
        "symbol": "106.EVEX"
    },
    {
        "序号": 2174,
        "name": "Equity Bancshares Inc-A",
        "最新价": 32.06,
        "涨跌额": 0.37,
        "涨跌幅": 1.17,
        "开盘价": 31.61,
        "最高价": 32.5,
        "最低价": 31.6,
        "昨收价": 31.69,
        "总市值": 494142832.0,
        "市盈率": 10.35,
        "成交量": 110144.0,
        "成交额": 3525048.0,
        "振幅": 2.84,
        "换手率": 0.71,
        "symbol": "106.EQBK"
    },
    {
        "序号": 2175,
        "name": "Computer Programs and Systems I",
        "最新价": 10.4,
        "涨跌额": 0.12,
        "涨跌幅": 1.17,
        "开盘价": 10.33,
        "最高价": 10.55,
        "最低价": 10.16,
        "昨收价": 10.28,
        "总市值": 151307270.0,
        "市盈率": -189.61,
        "成交量": 144791.0,
        "成交额": 1498527.0,
        "振幅": 3.79,
        "换手率": 1.0,
        "symbol": "105.CPSI"
    },
    {
        "序号": 2176,
        "name": "The Gabelli Utility Trust Serie",
        "最新价": 23.41,
        "涨跌额": 0.27,
        "涨跌幅": 1.17,
        "开盘价": 23.43,
        "最高价": 23.44,
        "最低价": 23.16,
        "昨收价": 23.14,
        "总市值": 46520961.0,
        "市盈率": null,
        "成交量": 2611.0,
        "成交额": 60994.0,
        "振幅": 1.21,
        "换手率": 0.13,
        "symbol": "106.GUT_C"
    },
    {
        "序号": 2177,
        "name": "Tejon Ranch Co",
        "最新价": 16.48,
        "涨跌额": 0.19,
        "涨跌幅": 1.17,
        "开盘价": 16.22,
        "最高价": 16.52,
        "最低价": 16.01,
        "昨收价": 16.29,
        "总市值": 440579501.0,
        "市盈率": 119.59,
        "成交量": 53332.0,
        "成交额": 866508.0,
        "振幅": 3.13,
        "换手率": 0.2,
        "symbol": "106.TRC"
    },
    {
        "序号": 2178,
        "name": "西蒙地产",
        "最新价": 132.88,
        "涨跌额": 1.53,
        "涨跌幅": 1.16,
        "开盘价": 130.81,
        "最高价": 132.91,
        "最低价": 130.35,
        "昨收价": 131.35,
        "总市值": 43351756239.0,
        "市盈率": 19.62,
        "成交量": 1394282.0,
        "成交额": 184284585.0,
        "振幅": 1.95,
        "换手率": 0.43,
        "symbol": "106.SPG"
    },
    {
        "序号": 2179,
        "name": "Federal Agricultural Mortgage C",
        "最新价": 22.61,
        "涨跌额": 0.26,
        "涨跌幅": 1.16,
        "开盘价": 23.0,
        "最高价": 23.24,
        "最低价": 22.6,
        "昨收价": 22.35,
        "总市值": 71899800.0,
        "市盈率": null,
        "成交量": 6060.0,
        "成交额": 139361.0,
        "振幅": 2.86,
        "换手率": 0.19,
        "symbol": "106.AGM_E"
    },
    {
        "序号": 2180,
        "name": "智富",
        "最新价": 0.87,
        "涨跌额": 0.01,
        "涨跌幅": 1.16,
        "开盘价": 0.86,
        "最高价": 0.89,
        "最低价": 0.86,
        "昨收价": 0.86,
        "总市值": 17622806.0,
        "市盈率": -29.96,
        "成交量": 30426.0,
        "成交额": 26281.0,
        "振幅": 3.49,
        "换手率": 0.15,
        "symbol": "105.MEGL"
    },
    {
        "序号": 2181,
        "name": "陆金所控股",
        "最新价": 0.87,
        "涨跌额": 0.01,
        "涨跌幅": 1.16,
        "开盘价": 0.84,
        "最高价": 0.91,
        "最低价": 0.84,
        "昨收价": 0.86,
        "总市值": 1994573273.0,
        "市盈率": 13.29,
        "成交量": 31523145.0,
        "成交额": 27992019.0,
        "振幅": 8.14,
        "换手率": 1.37,
        "symbol": "106.LU"
    },
    {
        "序号": 2182,
        "name": "Lisata Therapeutics Inc",
        "最新价": 2.61,
        "涨跌额": 0.03,
        "涨跌幅": 1.16,
        "开盘价": 2.58,
        "最高价": 2.75,
        "最低价": 2.53,
        "昨收价": 2.58,
        "总市值": 21243962.0,
        "市盈率": -0.99,
        "成交量": 16293.0,
        "成交额": 42729.0,
        "振幅": 8.53,
        "换手率": 0.2,
        "symbol": "105.LSTA"
    },
    {
        "序号": 2183,
        "name": "西部数据",
        "最新价": 47.86,
        "涨跌额": 0.55,
        "涨跌幅": 1.16,
        "开盘价": 47.13,
        "最高价": 48.11,
        "最低价": 47.03,
        "昨收价": 47.31,
        "总市值": 15518277829.0,
        "市盈率": -6.42,
        "成交量": 8455556.0,
        "成交额": 404279136.0,
        "振幅": 2.28,
        "换手率": 2.61,
        "symbol": "105.WDC"
    },
    {
        "序号": 2184,
        "name": "伟世通",
        "最新价": 122.9,
        "涨跌额": 1.41,
        "涨跌幅": 1.16,
        "开盘价": 121.49,
        "最高价": 124.21,
        "最低价": 121.49,
        "昨收价": 121.49,
        "总市值": 3417996849.0,
        "市盈率": 22.19,
        "成交量": 288241.0,
        "成交额": 35524184.0,
        "振幅": 2.24,
        "换手率": 1.04,
        "symbol": "105.VC"
    },
    {
        "序号": 2185,
        "name": "福里斯特",
        "最新价": 31.41,
        "涨跌额": 0.36,
        "涨跌幅": 1.16,
        "开盘价": 30.95,
        "最高价": 31.68,
        "最低价": 30.95,
        "昨收价": 31.05,
        "总市值": 1567664085.0,
        "市盈率": 9.39,
        "成交量": 156470.0,
        "成交额": 4907875.0,
        "振幅": 2.35,
        "换手率": 0.31,
        "symbol": "106.FOR"
    },
    {
        "序号": 2186,
        "name": "TrustCo Bank Corp NY",
        "最新价": 29.69,
        "涨跌额": 0.34,
        "涨跌幅": 1.16,
        "开盘价": 29.07,
        "最高价": 29.97,
        "最低价": 29.06,
        "昨收价": 29.35,
        "总市值": 564835416.0,
        "市盈率": 8.1,
        "成交量": 84800.0,
        "成交额": 2514734.0,
        "振幅": 3.1,
        "换手率": 0.45,
        "symbol": "105.TRST"
    },
    {
        "序号": 2187,
        "name": "Indonesia Energy Corp Ltd",
        "最新价": 2.62,
        "涨跌额": 0.03,
        "涨跌幅": 1.16,
        "开盘价": 2.59,
        "最高价": 2.69,
        "最低价": 2.58,
        "昨收价": 2.59,
        "总市值": 26573858.0,
        "市盈率": -7.21,
        "成交量": 22383.0,
        "成交额": 58271.0,
        "振幅": 4.25,
        "换手率": 0.22,
        "symbol": "107.INDO"
    },
    {
        "序号": 2188,
        "name": "Independent Bank Group Inc",
        "最新价": 42.87,
        "涨跌额": 0.49,
        "涨跌幅": 1.16,
        "开盘价": 42.3,
        "最高价": 43.06,
        "最低价": 41.98,
        "昨收价": 42.38,
        "总市值": 1769809841.0,
        "市盈率": 25.61,
        "成交量": 167575.0,
        "成交额": 7170142.0,
        "振幅": 2.55,
        "换手率": 0.41,
        "symbol": "105.IBTX"
    },
    {
        "序号": 2189,
        "name": "Ultrapar Participacoes SA ADR",
        "最新价": 5.25,
        "涨跌额": 0.06,
        "涨跌幅": 1.16,
        "开盘价": 5.18,
        "最高价": 5.28,
        "最低价": 5.16,
        "昨收价": 5.19,
        "总市值": 5719760524.0,
        "市盈率": 13.18,
        "成交量": 1388991.0,
        "成交额": 7263151.0,
        "振幅": 2.31,
        "换手率": 0.13,
        "symbol": "106.UGP"
    },
    {
        "序号": 2190,
        "name": "Provident Financial Services In",
        "最新价": 16.63,
        "涨跌额": 0.19,
        "涨跌幅": 1.16,
        "开盘价": 16.45,
        "最高价": 16.74,
        "最低价": 16.37,
        "昨收价": 16.44,
        "总市值": 1257336644.0,
        "市盈率": 8.38,
        "成交量": 442327.0,
        "成交额": 7348328.0,
        "振幅": 2.25,
        "换手率": 0.59,
        "symbol": "106.PFS"
    },
    {
        "序号": 2191,
        "name": "斯凯奇",
        "最新价": 60.43,
        "涨跌额": 0.69,
        "涨跌幅": 1.16,
        "开盘价": 59.63,
        "最高价": 60.61,
        "最低价": 59.58,
        "昨收价": 59.74,
        "总市值": 9305087784.0,
        "市盈率": 17.42,
        "成交量": 955113.0,
        "成交额": 57654169.0,
        "振幅": 1.72,
        "换手率": 0.62,
        "symbol": "106.SKX"
    },
    {
        "序号": 2192,
        "name": "Gravity Co Ltd ADR",
        "最新价": 76.23,
        "涨跌额": 0.87,
        "涨跌幅": 1.15,
        "开盘价": 75.02,
        "最高价": 76.59,
        "最低价": 75.02,
        "昨收价": 75.36,
        "总市值": 529714647.0,
        "市盈率": 4.72,
        "成交量": 23686.0,
        "成交额": 1797864.0,
        "振幅": 2.08,
        "换手率": 0.34,
        "symbol": "105.GRVY"
    },
    {
        "序号": 2193,
        "name": "Sprott Junior Uranium Miners ET",
        "最新价": 24.54,
        "涨跌额": 0.28,
        "涨跌幅": 1.15,
        "开盘价": 24.27,
        "最高价": 24.64,
        "最低价": 24.15,
        "昨收价": 24.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 80804.0,
        "成交额": 1971283.0,
        "振幅": 2.02,
        "换手率": null,
        "symbol": "105.URNJ"
    },
    {
        "序号": 2194,
        "name": "Blue Owl Capital Corp",
        "最新价": 14.9,
        "涨跌额": 0.17,
        "涨跌幅": 1.15,
        "开盘价": 14.69,
        "最高价": 14.92,
        "最低价": 14.68,
        "昨收价": 14.73,
        "总市值": 5807019733.0,
        "市盈率": 7.29,
        "成交量": 1943736.0,
        "成交额": 28823879.0,
        "振幅": 1.63,
        "换手率": 0.5,
        "symbol": "106.OBDC"
    },
    {
        "序号": 2195,
        "name": "Direxion Daily Industrials Bull",
        "最新价": 37.7,
        "涨跌额": 0.43,
        "涨跌幅": 1.15,
        "开盘价": 37.29,
        "最高价": 38.14,
        "最低价": 37.29,
        "昨收价": 37.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 19170.0,
        "成交额": 723124.0,
        "振幅": 2.28,
        "换手率": null,
        "symbol": "107.DUSL"
    },
    {
        "序号": 2196,
        "name": "日月光半导体",
        "最新价": 8.77,
        "涨跌额": 0.1,
        "涨跌幅": 1.15,
        "开盘价": 8.67,
        "最高价": 8.81,
        "最低价": 8.67,
        "昨收价": 8.67,
        "总市值": 19207173435.0,
        "市盈率": 16.29,
        "成交量": 3737644.0,
        "成交额": 32729795.0,
        "振幅": 1.61,
        "换手率": 0.17,
        "symbol": "106.ASX"
    },
    {
        "序号": 2197,
        "name": "Defiance R2000 Enhanced Options",
        "最新价": 20.18,
        "涨跌额": 0.23,
        "涨跌幅": 1.15,
        "开盘价": 20.0,
        "最高价": 20.18,
        "最低价": 19.98,
        "昨收价": 19.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 128532.0,
        "成交额": 2583986.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.IWMY"
    },
    {
        "序号": 2198,
        "name": "CHS Inc Class B Series 3 Pfd",
        "最新价": 25.45,
        "涨跌额": 0.29,
        "涨跌幅": 1.15,
        "开盘价": 25.16,
        "最高价": 25.45,
        "最低价": 25.08,
        "昨收价": 25.16,
        "总市值": 501365000.0,
        "市盈率": null,
        "成交量": 19471.0,
        "成交额": 492106.0,
        "振幅": 1.47,
        "换手率": 0.1,
        "symbol": "105.CHSCM"
    },
    {
        "序号": 2199,
        "name": "三菱日联金融",
        "最新价": 8.78,
        "涨跌额": 0.1,
        "涨跌幅": 1.15,
        "开盘价": 8.72,
        "最高价": 8.8,
        "最低价": 8.7,
        "昨收价": 8.68,
        "总市值": 108325101878.0,
        "市盈率": 11.83,
        "成交量": 2728242.0,
        "成交额": 23876295.0,
        "振幅": 1.15,
        "换手率": 0.02,
        "symbol": "106.MUFG"
    },
    {
        "序号": 2200,
        "name": "Invesco S&P 500 Equal Weight En",
        "最新价": 72.02,
        "涨跌额": 0.82,
        "涨跌幅": 1.15,
        "开盘价": 71.78,
        "最高价": 72.14,
        "最低价": 71.55,
        "昨收价": 71.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 69540.0,
        "成交额": 4986652.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.RSPG"
    },
    {
        "序号": 2201,
        "name": "Invesco Dynamic Oil & Gas Servi",
        "最新价": 27.23,
        "涨跌额": 0.31,
        "涨跌幅": 1.15,
        "开盘价": 27.03,
        "最高价": 27.45,
        "最低价": 27.01,
        "昨收价": 26.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 103514.0,
        "成交额": 2811369.0,
        "振幅": 1.63,
        "换手率": null,
        "symbol": "107.PXJ"
    },
    {
        "序号": 2202,
        "name": "United States Copper Index Fund",
        "最新价": 23.73,
        "涨跌额": 0.27,
        "涨跌幅": 1.15,
        "开盘价": 23.66,
        "最高价": 23.87,
        "最低价": 23.66,
        "昨收价": 23.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 28243.0,
        "成交额": 670331.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.CPER"
    },
    {
        "序号": 2203,
        "name": "P.A.M. Transportation Services ",
        "最新价": 20.23,
        "涨跌额": 0.23,
        "涨跌幅": 1.15,
        "开盘价": 19.91,
        "最高价": 20.48,
        "最低价": 19.91,
        "昨收价": 20.0,
        "总市值": 445491728.0,
        "市盈率": 11.53,
        "成交量": 6826.0,
        "成交额": 137948.0,
        "振幅": 2.85,
        "换手率": 0.03,
        "symbol": "105.PTSI"
    },
    {
        "序号": 2204,
        "name": "HE设备服务",
        "最新价": 46.62,
        "涨跌额": 0.53,
        "涨跌幅": 1.15,
        "开盘价": 45.93,
        "最高价": 47.01,
        "最低价": 45.93,
        "昨收价": 46.09,
        "总市值": 1699295550.0,
        "市盈率": 10.18,
        "成交量": 147721.0,
        "成交额": 6883319.0,
        "振幅": 2.34,
        "换手率": 0.41,
        "symbol": "105.HEES"
    },
    {
        "序号": 2205,
        "name": "Veris Residential Inc",
        "最新价": 14.97,
        "涨跌额": 0.17,
        "涨跌幅": 1.15,
        "开盘价": 14.72,
        "最高价": 14.97,
        "最低价": 14.65,
        "昨收价": 14.8,
        "总市值": 1380556244.0,
        "市盈率": -19.63,
        "成交量": 977586.0,
        "成交额": 14543219.0,
        "振幅": 2.16,
        "换手率": 1.06,
        "symbol": "106.VRE"
    },
    {
        "序号": 2206,
        "name": "ProShares Metaverse ETF",
        "最新价": 39.64,
        "涨跌额": 0.45,
        "涨跌幅": 1.15,
        "开盘价": 39.47,
        "最高价": 39.69,
        "最低价": 39.47,
        "昨收价": 39.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 2326.0,
        "成交额": 92216.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.VERS"
    },
    {
        "序号": 2207,
        "name": "埃克森美孚",
        "最新价": 99.55,
        "涨跌额": 1.13,
        "涨跌幅": 1.15,
        "开盘价": 99.02,
        "最高价": 99.79,
        "最低价": 98.75,
        "昨收价": 98.42,
        "总市值": 394508475551.0,
        "市盈率": 9.59,
        "成交量": 22074945.0,
        "成交额": 2193473200.0,
        "振幅": 1.06,
        "换手率": 0.56,
        "symbol": "106.XOM"
    },
    {
        "序号": 2208,
        "name": "艾诺加能源",
        "最新价": 15.0,
        "涨跌额": 0.17,
        "涨跌幅": 1.15,
        "开盘价": 14.91,
        "最高价": 15.26,
        "最低价": 14.91,
        "昨收价": 14.83,
        "总市值": 3119775000.0,
        "市盈率": 4.66,
        "成交量": 1161547.0,
        "成交额": 17458377.0,
        "振幅": 2.36,
        "换手率": 0.56,
        "symbol": "106.ERF"
    },
    {
        "序号": 2209,
        "name": "特灵科技",
        "最新价": 234.0,
        "涨跌额": 2.65,
        "涨跌幅": 1.15,
        "开盘价": 233.44,
        "最高价": 235.72,
        "最低价": 232.61,
        "昨收价": 231.35,
        "总市值": 53248334724.0,
        "市盈率": 27.19,
        "成交量": 1388376.0,
        "成交额": 325059120.0,
        "振幅": 1.34,
        "换手率": 0.61,
        "symbol": "106.TT"
    },
    {
        "序号": 2210,
        "name": "Extra Space Storage Inc",
        "最新价": 138.68,
        "涨跌额": 1.57,
        "涨跌幅": 1.15,
        "开盘价": 137.06,
        "最高价": 138.83,
        "最低价": 136.42,
        "昨收价": 137.11,
        "总市值": 29300008355.0,
        "市盈率": 37.03,
        "成交量": 994344.0,
        "成交额": 137438476.0,
        "振幅": 1.76,
        "换手率": 0.47,
        "symbol": "106.EXR"
    },
    {
        "序号": 2211,
        "name": "Ceridian HCM Holding Inc",
        "最新价": 67.17,
        "涨跌额": 0.76,
        "涨跌幅": 1.14,
        "开盘价": 66.28,
        "最高价": 67.71,
        "最低价": 65.96,
        "昨收价": 66.41,
        "总市值": 10487051329.0,
        "市盈率": 2621.76,
        "成交量": 1300140.0,
        "成交额": 87385562.0,
        "振幅": 2.64,
        "换手率": 0.83,
        "symbol": "106.CDAY"
    },
    {
        "序号": 2212,
        "name": "Vanguard U.S. Momentum Factor E",
        "最新价": 123.76,
        "涨跌额": 1.4,
        "涨跌幅": 1.14,
        "开盘价": 122.41,
        "最高价": 124.01,
        "最低价": 122.41,
        "昨收价": 122.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 11667.0,
        "成交额": 1440290.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "107.VFMO"
    },
    {
        "序号": 2213,
        "name": "KeyCorp",
        "最新价": 13.26,
        "涨跌额": 0.15,
        "涨跌幅": 1.14,
        "开盘价": 13.08,
        "最高价": 13.35,
        "最低价": 13.05,
        "昨收价": 13.11,
        "总市值": 12414811061.0,
        "市盈率": 9.58,
        "成交量": 13680607.0,
        "成交额": 181154754.0,
        "振幅": 2.29,
        "换手率": 1.46,
        "symbol": "106.KEY"
    },
    {
        "序号": 2214,
        "name": "斯特雷耶教育",
        "最新价": 91.06,
        "涨跌额": 1.03,
        "涨跌幅": 1.14,
        "开盘价": 89.68,
        "最高价": 91.2,
        "最低价": 89.68,
        "昨收价": 90.03,
        "总市值": 2223306846.0,
        "市盈率": 45.38,
        "成交量": 64779.0,
        "成交额": 5882362.0,
        "振幅": 1.69,
        "换手率": 0.27,
        "symbol": "105.STRA"
    },
    {
        "序号": 2215,
        "name": "Strawberry Fields REIT Inc",
        "最新价": 7.96,
        "涨跌额": 0.09,
        "涨跌幅": 1.14,
        "开盘价": 7.87,
        "最高价": 7.99,
        "最低价": 7.87,
        "昨收价": 7.87,
        "总市值": 51178931.0,
        "市盈率": 19.58,
        "成交量": 4978.0,
        "成交额": 39561.0,
        "振幅": 1.52,
        "换手率": 0.08,
        "symbol": "107.STRW"
    },
    {
        "序号": 2216,
        "name": "金德摩根",
        "最新价": 17.7,
        "涨跌额": 0.2,
        "涨跌幅": 1.14,
        "开盘价": 17.55,
        "最高价": 17.71,
        "最低价": 17.51,
        "昨收价": 17.5,
        "总市值": 39343098614.0,
        "市盈率": 15.95,
        "成交量": 11790035.0,
        "成交额": 208009709.0,
        "振幅": 1.14,
        "换手率": 0.53,
        "symbol": "106.KMI"
    },
    {
        "序号": 2217,
        "name": "克瑞拓斯安全防卫",
        "最新价": 20.39,
        "涨跌额": 0.23,
        "涨跌幅": 1.14,
        "开盘价": 20.14,
        "最高价": 20.47,
        "最低价": 20.08,
        "昨收价": 20.16,
        "总市值": 2629058503.0,
        "市盈率": -134.14,
        "成交量": 582955.0,
        "成交额": 11843704.0,
        "振幅": 1.93,
        "换手率": 0.45,
        "symbol": "105.KTOS"
    },
    {
        "序号": 2218,
        "name": "Tricon Residential Inc",
        "最新价": 7.98,
        "涨跌额": 0.09,
        "涨跌幅": 1.14,
        "开盘价": 7.87,
        "最高价": 7.99,
        "最低价": 7.81,
        "昨收价": 7.89,
        "总市值": 2173408716.0,
        "市盈率": 10.47,
        "成交量": 1330322.0,
        "成交额": 10542175.0,
        "振幅": 2.28,
        "换手率": 0.49,
        "symbol": "106.TCN"
    },
    {
        "序号": 2219,
        "name": "Priority Income Fund Inc Series",
        "最新价": 22.2,
        "涨跌额": 0.25,
        "涨跌幅": 1.14,
        "开盘价": 22.2,
        "最高价": 22.23,
        "最低价": 22.19,
        "昨收价": 21.95,
        "总市值": 35520000.0,
        "市盈率": null,
        "成交量": 1860.0,
        "成交额": 41313.0,
        "振幅": 0.18,
        "换手率": 0.12,
        "symbol": "106.PRIF_I"
    },
    {
        "序号": 2220,
        "name": "Spotify Technology SA",
        "最新价": 198.05,
        "涨跌额": 2.23,
        "涨跌幅": 1.14,
        "开盘价": 192.26,
        "最高价": 198.39,
        "最低价": 191.0,
        "昨收价": 195.82,
        "总市值": 38647290239.0,
        "市盈率": -48.99,
        "成交量": 1729918.0,
        "成交额": 340401584.0,
        "振幅": 3.77,
        "换手率": 0.89,
        "symbol": "106.SPOT"
    },
    {
        "序号": 2221,
        "name": "TimkenSteel Corp",
        "最新价": 20.43,
        "涨跌额": 0.23,
        "涨跌幅": 1.14,
        "开盘价": 20.23,
        "最高价": 20.7,
        "最低价": 20.22,
        "昨收价": 20.2,
        "总市值": 881652891.0,
        "市盈率": 25.26,
        "成交量": 190142.0,
        "成交额": 3886532.0,
        "振幅": 2.38,
        "换手率": 0.44,
        "symbol": "106.TMST"
    },
    {
        "序号": 2222,
        "name": "Pegasystems Inc",
        "最新价": 52.43,
        "涨跌额": 0.59,
        "涨跌幅": 1.14,
        "开盘价": 51.52,
        "最高价": 52.45,
        "最低价": 51.33,
        "昨收价": 51.84,
        "总市值": 4380836833.0,
        "市盈率": -108.86,
        "成交量": 356840.0,
        "成交额": 18612180.0,
        "振幅": 2.16,
        "换手率": 0.43,
        "symbol": "105.PEGA"
    },
    {
        "序号": 2223,
        "name": "Xtrackers MSCI Europe Hedged Eq",
        "最新价": 37.35,
        "涨跌额": 0.42,
        "涨跌幅": 1.14,
        "开盘价": 37.16,
        "最高价": 37.37,
        "最低价": 37.16,
        "昨收价": 36.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 26974.0,
        "成交额": 1005057.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.DBEU"
    },
    {
        "序号": 2224,
        "name": "Femasys Inc",
        "最新价": 0.89,
        "涨跌额": 0.01,
        "涨跌幅": 1.14,
        "开盘价": 0.88,
        "最高价": 0.89,
        "最低价": 0.86,
        "昨收价": 0.88,
        "总市值": 19268164.0,
        "市盈率": -1.51,
        "成交量": 257884.0,
        "成交额": 226336.0,
        "振幅": 3.41,
        "换手率": 1.19,
        "symbol": "105.FEMY"
    },
    {
        "序号": 2225,
        "name": "Bank7 Corp",
        "最新价": 24.92,
        "涨跌额": 0.28,
        "涨跌幅": 1.14,
        "开盘价": 24.94,
        "最高价": 24.94,
        "最低价": 23.9,
        "昨收价": 24.64,
        "总市值": 228923742.0,
        "市盈率": 6.43,
        "成交量": 4873.0,
        "成交额": 120920.0,
        "振幅": 4.22,
        "换手率": 0.05,
        "symbol": "105.BSVN"
    },
    {
        "序号": 2226,
        "name": "美国家园建设ETF-iShares",
        "最新价": 93.46,
        "涨跌额": 1.05,
        "涨跌幅": 1.14,
        "开盘价": 92.27,
        "最高价": 93.69,
        "最低价": 92.1,
        "昨收价": 92.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 2217170.0,
        "成交额": 206438934.0,
        "振幅": 1.72,
        "换手率": null,
        "symbol": "107.ITB"
    },
    {
        "序号": 2227,
        "name": "Booking Holdings",
        "最新价": 3275.0,
        "涨跌额": 36.73,
        "涨跌幅": 1.13,
        "开盘价": 3220.96,
        "最高价": 3277.43,
        "最低价": 3220.96,
        "昨收价": 3238.27,
        "总市值": 114264334075.0,
        "市盈率": 21.55,
        "成交量": 244872.0,
        "成交额": 800702432.0,
        "振幅": 1.74,
        "换手率": 0.7,
        "symbol": "105.BKNG"
    },
    {
        "序号": 2228,
        "name": "Ramaco Resources Inc-B",
        "最新价": 15.17,
        "涨跌额": 0.17,
        "涨跌幅": 1.13,
        "开盘价": 15.27,
        "最高价": 15.49,
        "最低价": 15.01,
        "昨收价": 15.0,
        "总市值": 799246544.0,
        "市盈率": 11.99,
        "成交量": 38383.0,
        "成交额": 583811.0,
        "振幅": 3.2,
        "换手率": 0.44,
        "symbol": "105.METCB"
    },
    {
        "序号": 2229,
        "name": "Amplify Natural Resources Divid",
        "最新价": 26.78,
        "涨跌额": 0.3,
        "涨跌幅": 1.13,
        "开盘价": 26.76,
        "最高价": 26.78,
        "最低价": 26.76,
        "昨收价": 26.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 713.0,
        "成交额": 19081.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.NDIV"
    },
    {
        "序号": 2230,
        "name": "西北管道",
        "最新价": 27.68,
        "涨跌额": 0.31,
        "涨跌幅": 1.13,
        "开盘价": 27.59,
        "最高价": 28.0,
        "最低价": 27.44,
        "昨收价": 27.37,
        "总市值": 277192945.0,
        "市盈率": 11.75,
        "成交量": 25104.0,
        "成交额": 695510.0,
        "振幅": 2.05,
        "换手率": 0.25,
        "symbol": "105.NWPX"
    },
    {
        "序号": 2231,
        "name": "Jeffs’ Brands Ltd",
        "最新价": 2.68,
        "涨跌额": 0.03,
        "涨跌幅": 1.13,
        "开盘价": 2.81,
        "最高价": 2.85,
        "最低价": 2.68,
        "昨收价": 2.65,
        "总市值": 3185997.0,
        "市盈率": -1.27,
        "成交量": 9019.0,
        "成交额": 24565.0,
        "振幅": 6.42,
        "换手率": 0.76,
        "symbol": "105.JFBR"
    },
    {
        "序号": 2232,
        "name": "iShares U.S. Regional Banks ETF",
        "最新价": 39.31,
        "涨跌额": 0.44,
        "涨跌幅": 1.13,
        "开盘价": 38.93,
        "最高价": 39.49,
        "最低价": 38.64,
        "昨收价": 38.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 332586.0,
        "成交额": 13041185.0,
        "振幅": 2.19,
        "换手率": null,
        "symbol": "107.IAT"
    },
    {
        "序号": 2233,
        "name": "ECB Bancorp Inc",
        "最新价": 12.53,
        "涨跌额": 0.14,
        "涨跌幅": 1.13,
        "开盘价": 12.23,
        "最高价": 12.65,
        "最低价": 12.23,
        "昨收价": 12.39,
        "总市值": 117640135.0,
        "市盈率": 25.34,
        "成交量": 8896.0,
        "成交额": 111726.0,
        "振幅": 3.39,
        "换手率": 0.09,
        "symbol": "105.ECBK"
    },
    {
        "序号": 2234,
        "name": "Calamos Strategic Total Return ",
        "最新价": 14.35,
        "涨跌额": 0.16,
        "涨跌幅": 1.13,
        "开盘价": 14.2,
        "最高价": 14.39,
        "最低价": 14.15,
        "昨收价": 14.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 191088.0,
        "成交额": 2725950.0,
        "振幅": 1.69,
        "换手率": null,
        "symbol": "105.CSQ"
    },
    {
        "序号": 2235,
        "name": "TransDigm Group Inc",
        "最新价": 983.92,
        "涨跌额": 10.96,
        "涨跌幅": 1.13,
        "开盘价": 973.7,
        "最高价": 986.15,
        "最低价": 971.94,
        "昨收价": 972.96,
        "总市值": 54424653208.0,
        "市盈率": 41.93,
        "成交量": 144938.0,
        "成交额": 142362177.0,
        "振幅": 1.46,
        "换手率": 0.26,
        "symbol": "106.TDG"
    },
    {
        "序号": 2236,
        "name": "Voya Financial Inc",
        "最新价": 72.72,
        "涨跌额": 0.81,
        "涨跌幅": 1.13,
        "开盘价": 72.26,
        "最高价": 73.0,
        "最低价": 71.91,
        "昨收价": 71.91,
        "总市值": 7596913033.0,
        "市盈率": 10.9,
        "成交量": 467202.0,
        "成交额": 33928403.0,
        "振幅": 1.52,
        "换手率": 0.45,
        "symbol": "106.VOYA"
    },
    {
        "序号": 2237,
        "name": "搜诺思",
        "最新价": 16.17,
        "涨跌额": 0.18,
        "涨跌幅": 1.13,
        "开盘价": 15.91,
        "最高价": 16.27,
        "最低价": 15.88,
        "昨收价": 15.99,
        "总市值": 2023682211.0,
        "市盈率": -196.97,
        "成交量": 1848096.0,
        "成交额": 29896948.0,
        "振幅": 2.44,
        "换手率": 1.48,
        "symbol": "105.SONO"
    },
    {
        "序号": 2238,
        "name": "Davis Select U.S. Equity ETF",
        "最新价": 34.18,
        "涨跌额": 0.38,
        "涨跌幅": 1.12,
        "开盘价": 33.71,
        "最高价": 34.28,
        "最低价": 33.49,
        "昨收价": 33.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 15238.0,
        "成交额": 518419.0,
        "振幅": 2.34,
        "换手率": null,
        "symbol": "107.DUSA"
    },
    {
        "序号": 2239,
        "name": "欣厄姆银行",
        "最新价": 175.51,
        "涨跌额": 1.95,
        "涨跌幅": 1.12,
        "开盘价": 173.37,
        "最高价": 175.87,
        "最低价": 170.25,
        "昨收价": 173.56,
        "总市值": 377416704.0,
        "市盈率": 14.65,
        "成交量": 4094.0,
        "成交额": 711839.0,
        "振幅": 3.24,
        "换手率": 0.19,
        "symbol": "105.HIFS"
    },
    {
        "序号": 2240,
        "name": "奥尔巴尼国际",
        "最新价": 89.13,
        "涨跌额": 0.99,
        "涨跌幅": 1.12,
        "开盘价": 88.29,
        "最高价": 90.14,
        "最低价": 88.29,
        "昨收价": 88.14,
        "总市值": 2780416143.0,
        "市盈率": 28.14,
        "成交量": 110880.0,
        "成交额": 9885956.0,
        "振幅": 2.1,
        "换手率": 0.36,
        "symbol": "106.AIN"
    },
    {
        "序号": 2241,
        "name": "SPDR S&P Capital Markets ETF",
        "最新价": 94.57,
        "涨跌额": 1.05,
        "涨跌幅": 1.12,
        "开盘价": 93.66,
        "最高价": 94.75,
        "最低价": 93.66,
        "昨收价": 93.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 20058.0,
        "成交额": 1892085.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "107.KCE"
    },
    {
        "序号": 2242,
        "name": "HealthEquity Inc",
        "最新价": 68.48,
        "涨跌额": 0.76,
        "涨跌幅": 1.12,
        "开盘价": 68.29,
        "最高价": 70.0,
        "最低价": 67.87,
        "昨收价": 67.72,
        "总市值": 5875662341.0,
        "市盈率": 201.64,
        "成交量": 755505.0,
        "成交额": 51859886.0,
        "振幅": 3.15,
        "换手率": 0.88,
        "symbol": "105.HQY"
    },
    {
        "序号": 2243,
        "name": "Xenia Hotels & Resorts Inc",
        "最新价": 12.63,
        "涨跌额": 0.14,
        "涨跌幅": 1.12,
        "开盘价": 12.44,
        "最高价": 12.67,
        "最低价": 12.38,
        "昨收价": 12.49,
        "总市值": 1328689375.0,
        "市盈率": 28.39,
        "成交量": 1401453.0,
        "成交额": 17573945.0,
        "振幅": 2.32,
        "换手率": 1.33,
        "symbol": "106.XHR"
    },
    {
        "序号": 2244,
        "name": "派克汉尼汾",
        "最新价": 438.63,
        "涨跌额": 4.86,
        "涨跌幅": 1.12,
        "开盘价": 432.91,
        "最高价": 440.03,
        "最低价": 432.91,
        "昨收价": 433.77,
        "总市值": 56353350681.0,
        "市盈率": 24.02,
        "成交量": 858426.0,
        "成交额": 376274352.0,
        "振幅": 1.64,
        "换手率": 0.67,
        "symbol": "106.PH"
    },
    {
        "序号": 2245,
        "name": "Cerence Inc",
        "最新价": 17.15,
        "涨跌额": 0.19,
        "涨跌幅": 1.12,
        "开盘价": 16.9,
        "最高价": 17.28,
        "最低价": 16.82,
        "昨收价": 16.96,
        "总市值": 705475883.0,
        "市盈率": -12.54,
        "成交量": 271450.0,
        "成交额": 4652249.0,
        "振幅": 2.71,
        "换手率": 0.66,
        "symbol": "105.CRNC"
    },
    {
        "序号": 2246,
        "name": "德美银行",
        "最新价": 31.6,
        "涨跌额": 0.35,
        "涨跌幅": 1.12,
        "开盘价": 31.34,
        "最高价": 31.82,
        "最低价": 30.92,
        "昨收价": 31.25,
        "总市值": 934584252.0,
        "市盈率": 10.53,
        "成交量": 66854.0,
        "成交额": 2111609.0,
        "振幅": 2.88,
        "换手率": 0.23,
        "symbol": "105.GABC"
    },
    {
        "序号": 2247,
        "name": "Nicolet Bankshares Inc",
        "最新价": 78.56,
        "涨跌额": 0.87,
        "涨跌幅": 1.12,
        "开盘价": 78.27,
        "最高价": 79.49,
        "最低价": 77.8,
        "昨收价": 77.69,
        "总市值": 1159634216.0,
        "市盈率": 19.84,
        "成交量": 24331.0,
        "成交额": 1910830.0,
        "振幅": 2.18,
        "换手率": 0.16,
        "symbol": "106.NIC"
    },
    {
        "序号": 2248,
        "name": "Fidelity MSCI Energy Index ETF",
        "最新价": 22.59,
        "涨跌额": 0.25,
        "涨跌幅": 1.12,
        "开盘价": 22.49,
        "最高价": 22.66,
        "最低价": 22.45,
        "昨收价": 22.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 640986.0,
        "成交额": 14453173.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.FENY"
    },
    {
        "序号": 2249,
        "name": "联合银行",
        "最新价": 29.83,
        "涨跌额": 0.33,
        "涨跌幅": 1.12,
        "开盘价": 29.61,
        "最高价": 29.87,
        "最低价": 29.49,
        "昨收价": 29.5,
        "总市值": 134450343.0,
        "市盈率": 11.54,
        "成交量": 2834.0,
        "成交额": 84021.0,
        "振幅": 1.29,
        "换手率": 0.06,
        "symbol": "105.UNB"
    },
    {
        "序号": 2250,
        "name": "First Trust Dorsey Wright Momen",
        "最新价": 24.43,
        "涨跌额": 0.27,
        "涨跌幅": 1.12,
        "开盘价": 24.19,
        "最高价": 24.44,
        "最低价": 24.19,
        "昨收价": 24.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1583.0,
        "成交额": 38348.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "105.DVLU"
    },
    {
        "序号": 2251,
        "name": "Synchrony Financial",
        "最新价": 35.29,
        "涨跌额": 0.39,
        "涨跌幅": 1.12,
        "开盘价": 34.9,
        "最高价": 35.43,
        "最低价": 34.71,
        "昨收价": 34.9,
        "总市值": 14603155864.0,
        "市盈率": 6.15,
        "成交量": 4215602.0,
        "成交额": 148409385.0,
        "振幅": 2.06,
        "换手率": 1.02,
        "symbol": "106.SYF"
    },
    {
        "序号": 2252,
        "name": "SPDR S&P Internet ETF",
        "最新价": 80.54,
        "涨跌额": 0.89,
        "涨跌幅": 1.12,
        "开盘价": 80.58,
        "最高价": 80.58,
        "最低价": 80.0,
        "昨收价": 79.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 1453.0,
        "成交额": 116689.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.XWEB"
    },
    {
        "序号": 2253,
        "name": "TORM plc-A",
        "最新价": 27.16,
        "涨跌额": 0.3,
        "涨跌幅": 1.12,
        "开盘价": 27.0,
        "最高价": 27.23,
        "最低价": 26.83,
        "昨收价": 26.86,
        "总市值": 2292176864.0,
        "市盈率": 3.31,
        "成交量": 520775.0,
        "成交额": 14096360.0,
        "振幅": 1.49,
        "换手率": 0.62,
        "symbol": "105.TRMD"
    },
    {
        "序号": 2254,
        "name": "Invesco DWA SmallCap Momentum E",
        "最新价": 78.78,
        "涨跌额": 0.87,
        "涨跌幅": 1.12,
        "开盘价": 77.8,
        "最高价": 79.07,
        "最低价": 77.8,
        "昨收价": 77.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 23794.0,
        "成交额": 1870213.0,
        "振幅": 1.63,
        "换手率": null,
        "symbol": "105.DWAS"
    },
    {
        "序号": 2255,
        "name": "Q2 Holdings Inc",
        "最新价": 38.08,
        "涨跌额": 0.42,
        "涨跌幅": 1.12,
        "开盘价": 37.54,
        "最高价": 38.42,
        "最低价": 37.54,
        "昨收价": 37.66,
        "总市值": 2232820990.0,
        "市盈率": -28.01,
        "成交量": 486754.0,
        "成交额": 18535909.0,
        "振幅": 2.34,
        "换手率": 0.83,
        "symbol": "106.QTWO"
    },
    {
        "序号": 2256,
        "name": "RiverNorth Opportunistic Munici",
        "最新价": 15.43,
        "涨跌额": 0.17,
        "涨跌幅": 1.11,
        "开盘价": 15.22,
        "最高价": 15.43,
        "最低价": 15.22,
        "昨收价": 15.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 16520.0,
        "成交额": 253020.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "106.RMI"
    },
    {
        "序号": 2257,
        "name": "Ferguson plc",
        "最新价": 180.65,
        "涨跌额": 1.99,
        "涨跌幅": 1.11,
        "开盘价": 177.75,
        "最高价": 181.37,
        "最低价": 177.75,
        "昨收价": 178.66,
        "总市值": 36760405453.0,
        "市盈率": 20.28,
        "成交量": 1397353.0,
        "成交额": 251549030.0,
        "振幅": 2.03,
        "换手率": 0.69,
        "symbol": "106.FERG"
    },
    {
        "序号": 2258,
        "name": "阿尔法泰克",
        "最新价": 12.71,
        "涨跌额": 0.14,
        "涨跌幅": 1.11,
        "开盘价": 12.46,
        "最高价": 12.87,
        "最低价": 12.45,
        "昨收价": 12.57,
        "总市值": 1732381439.0,
        "市盈率": -10.0,
        "成交量": 1345376.0,
        "成交额": 17080348.0,
        "振幅": 3.34,
        "换手率": 0.99,
        "symbol": "105.ATEC"
    },
    {
        "序号": 2259,
        "name": "Adaptiv Select ETF",
        "最新价": 23.62,
        "涨跌额": 0.26,
        "涨跌幅": 1.11,
        "开盘价": 23.62,
        "最高价": 23.62,
        "最低价": 23.62,
        "昨收价": 23.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 11.0,
        "成交额": 259.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ADPV"
    },
    {
        "序号": 2260,
        "name": "先锋自然资源",
        "最新价": 223.05,
        "涨跌额": 2.45,
        "涨跌幅": 1.11,
        "开盘价": 222.59,
        "最高价": 223.55,
        "最低价": 221.39,
        "昨收价": 220.6,
        "总市值": 52039546576.0,
        "市盈率": 10.19,
        "成交量": 2279922.0,
        "成交额": 507796400.0,
        "振幅": 0.98,
        "换手率": 0.98,
        "symbol": "106.PXD"
    },
    {
        "序号": 2261,
        "name": "Chimera Investment Corp Series ",
        "最新价": 21.0,
        "涨跌额": 0.23,
        "涨跌幅": 1.11,
        "开盘价": 20.73,
        "最高价": 21.04,
        "最低价": 20.55,
        "昨收价": 20.77,
        "总市值": 121800000.0,
        "市盈率": null,
        "成交量": 23202.0,
        "成交额": 484545.0,
        "振幅": 2.36,
        "换手率": 0.4,
        "symbol": "106.CIM_A"
    },
    {
        "序号": 2262,
        "name": "TPG RE Finance Trust Inc Series",
        "最新价": 13.7,
        "涨跌额": 0.15,
        "涨跌幅": 1.11,
        "开盘价": 13.66,
        "最高价": 14.02,
        "最低价": 13.65,
        "昨收价": 13.55,
        "总市值": 110285000.0,
        "市盈率": null,
        "成交量": 75626.0,
        "成交额": 1043376.0,
        "振幅": 2.73,
        "换手率": 0.94,
        "symbol": "106.TRTX_C"
    },
    {
        "序号": 2263,
        "name": "RumbleOn Inc-B",
        "最新价": 5.48,
        "涨跌额": 0.06,
        "涨跌幅": 1.11,
        "开盘价": 5.48,
        "最高价": 5.61,
        "最低价": 5.34,
        "昨收价": 5.42,
        "总市值": 92388959.0,
        "市盈率": -0.28,
        "成交量": 281986.0,
        "成交额": 1549218.0,
        "振幅": 4.98,
        "换手率": 1.67,
        "symbol": "105.RMBL"
    },
    {
        "序号": 2264,
        "name": "Invesco Municipal Income Opport",
        "最新价": 6.4,
        "涨跌额": 0.07,
        "涨跌幅": 1.11,
        "开盘价": 6.33,
        "最高价": 6.43,
        "最低价": 6.33,
        "昨收价": 6.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 121266.0,
        "成交额": 775695.0,
        "振幅": 1.58,
        "换手率": null,
        "symbol": "106.OIA"
    },
    {
        "序号": 2265,
        "name": "Teledyne Technologies Inc",
        "最新价": 411.49,
        "涨跌额": 4.5,
        "涨跌幅": 1.11,
        "开盘价": 407.03,
        "最高价": 411.8,
        "最低价": 404.03,
        "昨收价": 406.99,
        "总市值": 19416030969.0,
        "市盈率": 24.61,
        "成交量": 202834.0,
        "成交额": 83239418.0,
        "振幅": 1.91,
        "换手率": 0.43,
        "symbol": "106.TDY"
    },
    {
        "序号": 2266,
        "name": "ETFMG Travel Tech ETF",
        "最新价": 18.3,
        "涨跌额": 0.2,
        "涨跌幅": 1.1,
        "开盘价": 18.09,
        "最高价": 18.35,
        "最低价": 18.09,
        "昨收价": 18.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 45538.0,
        "成交额": 831733.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "107.AWAY"
    },
    {
        "序号": 2267,
        "name": "摩根大通",
        "最新价": 158.52,
        "涨跌额": 1.73,
        "涨跌幅": 1.1,
        "开盘价": 157.26,
        "最高价": 158.78,
        "最低价": 156.98,
        "昨收价": 156.79,
        "总市值": 458282642215.0,
        "市盈率": 8.94,
        "成交量": 7369370.0,
        "成交额": 1165637136.0,
        "振幅": 1.15,
        "换手率": 0.25,
        "symbol": "106.JPM"
    },
    {
        "序号": 2268,
        "name": "SPDR S&P Aerospace & Defense ET",
        "最新价": 131.16,
        "涨跌额": 1.43,
        "涨跌幅": 1.1,
        "开盘价": 129.82,
        "最高价": 131.17,
        "最低价": 129.55,
        "昨收价": 129.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 98414.0,
        "成交额": 12825241.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.XAR"
    },
    {
        "序号": 2269,
        "name": "Lumentum Holdings Inc",
        "最新价": 44.03,
        "涨跌额": 0.48,
        "涨跌幅": 1.1,
        "开盘价": 43.49,
        "最高价": 44.79,
        "最低价": 43.43,
        "昨收价": 43.55,
        "总市值": 2950001854.0,
        "市盈率": -14.82,
        "成交量": 1090546.0,
        "成交额": 48178544.0,
        "振幅": 3.12,
        "换手率": 1.63,
        "symbol": "105.LITE"
    },
    {
        "序号": 2270,
        "name": "地区金融",
        "最新价": 17.44,
        "涨跌额": 0.19,
        "涨跌幅": 1.1,
        "开盘价": 17.33,
        "最高价": 17.7,
        "最低价": 17.22,
        "昨收价": 17.25,
        "总市值": 16220332519.0,
        "市盈率": 6.85,
        "成交量": 7372592.0,
        "成交额": 128871676.0,
        "振幅": 2.78,
        "换手率": 0.79,
        "symbol": "106.RF"
    },
    {
        "序号": 2271,
        "name": "信也科技",
        "最新价": 4.59,
        "涨跌额": 0.05,
        "涨跌幅": 1.1,
        "开盘价": 4.53,
        "最高价": 4.65,
        "最低价": 4.53,
        "昨收价": 4.54,
        "总市值": 1285744199.0,
        "市盈率": 3.82,
        "成交量": 616209.0,
        "成交额": 2838631.0,
        "振幅": 2.64,
        "换手率": 0.22,
        "symbol": "106.FINV"
    },
    {
        "序号": 2272,
        "name": "银行ETF-SPDR S&P",
        "最新价": 43.16,
        "涨跌额": 0.47,
        "涨跌幅": 1.1,
        "开盘价": 42.67,
        "最高价": 43.37,
        "最低价": 42.45,
        "昨收价": 42.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 3012170.0,
        "成交额": 129798169.0,
        "振幅": 2.16,
        "换手率": null,
        "symbol": "107.KBE"
    },
    {
        "序号": 2273,
        "name": "康菲石油",
        "最新价": 112.07,
        "涨跌额": 1.22,
        "涨跌幅": 1.1,
        "开盘价": 111.74,
        "最高价": 112.19,
        "最低价": 111.06,
        "昨收价": 110.85,
        "总市值": 133072808060.0,
        "市盈率": 11.88,
        "成交量": 5713905.0,
        "成交额": 639096224.0,
        "振幅": 1.02,
        "换手率": 0.48,
        "symbol": "106.COP"
    },
    {
        "序号": 2274,
        "name": "PGT Innovations Inc",
        "最新价": 33.07,
        "涨跌额": 0.36,
        "涨跌幅": 1.1,
        "开盘价": 33.17,
        "最高价": 33.2,
        "最低价": 32.64,
        "昨收价": 32.71,
        "总市值": 1886640028.0,
        "市盈率": 16.88,
        "成交量": 363214.0,
        "成交额": 11975297.0,
        "振幅": 1.71,
        "换手率": 0.64,
        "symbol": "106.PGTI"
    },
    {
        "序号": 2275,
        "name": "ProShares Equities for Rising R",
        "最新价": 51.45,
        "涨跌额": 0.56,
        "涨跌幅": 1.1,
        "开盘价": 51.23,
        "最高价": 51.48,
        "最低价": 51.21,
        "昨收价": 50.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 1607.0,
        "成交额": 82393.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.EQRR"
    },
    {
        "序号": 2276,
        "name": "全球能源ETF-iShares",
        "最新价": 38.6,
        "涨跌额": 0.42,
        "涨跌幅": 1.1,
        "开盘价": 38.44,
        "最高价": 38.67,
        "最低价": 38.38,
        "昨收价": 38.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 863994.0,
        "成交额": 33302854.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.IXC"
    },
    {
        "序号": 2277,
        "name": "Argan Inc",
        "最新价": 42.29,
        "涨跌额": 0.46,
        "涨跌幅": 1.1,
        "开盘价": 41.85,
        "最高价": 42.33,
        "最低价": 40.9,
        "昨收价": 41.83,
        "总市值": 563635453.0,
        "市盈率": 16.59,
        "成交量": 196279.0,
        "成交额": 8208060.0,
        "振幅": 3.42,
        "换手率": 1.47,
        "symbol": "106.AGX"
    },
    {
        "序号": 2278,
        "name": "John Hancock Financial Opportun",
        "最新价": 29.42,
        "涨跌额": 0.32,
        "涨跌幅": 1.1,
        "开盘价": 29.03,
        "最高价": 29.59,
        "最低价": 28.99,
        "昨收价": 29.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 55817.0,
        "成交额": 1633383.0,
        "振幅": 2.06,
        "换手率": null,
        "symbol": "106.BTO"
    },
    {
        "序号": 2279,
        "name": "VanEck Energy Income ETF",
        "最新价": 68.07,
        "涨跌额": 0.74,
        "涨跌幅": 1.1,
        "开盘价": 67.61,
        "最高价": 68.07,
        "最低价": 67.55,
        "昨收价": 67.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 1174.0,
        "成交额": 79490.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.EINC"
    },
    {
        "序号": 2280,
        "name": "Jumia Technologies AG ADR",
        "最新价": 3.68,
        "涨跌额": 0.04,
        "涨跌幅": 1.1,
        "开盘价": 3.59,
        "最高价": 3.8,
        "最低价": 3.58,
        "昨收价": 3.64,
        "总市值": 370267910.0,
        "市盈率": -2.61,
        "成交量": 1343628.0,
        "成交额": 4949526.0,
        "振幅": 6.04,
        "换手率": 1.34,
        "symbol": "106.JMIA"
    },
    {
        "序号": 2281,
        "name": "Stellus Capital Investment Corp",
        "最新价": 12.89,
        "涨跌额": 0.14,
        "涨跌幅": 1.1,
        "开盘价": 12.8,
        "最高价": 12.91,
        "最低价": 12.72,
        "昨收价": 12.75,
        "总市值": 310979525.0,
        "市盈率": 31.01,
        "成交量": 79746.0,
        "成交额": 1021928.0,
        "振幅": 1.49,
        "换手率": 0.33,
        "symbol": "106.SCM"
    },
    {
        "序号": 2282,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 15.67,
        "涨跌额": 0.17,
        "涨跌幅": 1.1,
        "开盘价": 15.6,
        "最高价": 15.77,
        "最低价": 15.6,
        "昨收价": 15.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 4160.0,
        "成交额": 65119.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.PCG_G"
    },
    {
        "序号": 2283,
        "name": "亨廷顿银行",
        "最新价": 11.99,
        "涨跌额": 0.13,
        "涨跌幅": 1.1,
        "开盘价": 11.87,
        "最高价": 12.05,
        "最低价": 11.81,
        "昨收价": 11.86,
        "总市值": 17362420365.0,
        "市盈率": 7.38,
        "成交量": 15732335.0,
        "成交额": 188466885.0,
        "振幅": 2.02,
        "换手率": 1.09,
        "symbol": "105.HBAN"
    },
    {
        "序号": 2284,
        "name": "Fidelity D & D Bancorp Inc",
        "最新价": 56.31,
        "涨跌额": 0.61,
        "涨跌幅": 1.1,
        "开盘价": 55.93,
        "最高价": 56.61,
        "最低价": 55.5,
        "昨收价": 55.7,
        "总市值": 320761525.0,
        "市盈率": 12.89,
        "成交量": 14869.0,
        "成交额": 832491.0,
        "振幅": 1.99,
        "换手率": 0.26,
        "symbol": "105.FDBC"
    },
    {
        "序号": 2285,
        "name": "区域银行ETF-SPDR S&P",
        "最新价": 48.99,
        "涨跌额": 0.53,
        "涨跌幅": 1.09,
        "开盘价": 48.58,
        "最高价": 49.35,
        "最低价": 48.1,
        "昨收价": 48.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 13602945.0,
        "成交额": 665452512.0,
        "振幅": 2.58,
        "换手率": null,
        "symbol": "107.KRE"
    },
    {
        "序号": 2286,
        "name": "做空黄金ETN-DB",
        "最新价": 10.17,
        "涨跌额": 0.11,
        "涨跌幅": 1.09,
        "开盘价": 9.6,
        "最高价": 10.17,
        "最低价": 9.6,
        "昨收价": 10.06,
        "总市值": 20434581000.0,
        "市盈率": 3.41,
        "成交量": 433.0,
        "成交额": 4293.0,
        "振幅": 5.67,
        "换手率": null,
        "symbol": "107.DGZ"
    },
    {
        "序号": 2287,
        "name": "航空租赁",
        "最新价": 39.77,
        "涨跌额": 0.43,
        "涨跌幅": 1.09,
        "开盘价": 39.48,
        "最高价": 40.22,
        "最低价": 39.39,
        "昨收价": 39.34,
        "总市值": 4415553812.0,
        "市盈率": 8.19,
        "成交量": 636402.0,
        "成交额": 25351887.0,
        "振幅": 2.11,
        "换手率": 0.57,
        "symbol": "106.AL"
    },
    {
        "序号": 2288,
        "name": "Tigo Energy Inc",
        "最新价": 1.85,
        "涨跌额": 0.02,
        "涨跌幅": 1.09,
        "开盘价": 1.81,
        "最高价": 1.85,
        "最低价": 1.8,
        "昨收价": 1.83,
        "总市值": 108661576.0,
        "市盈率": 5.04,
        "成交量": 53455.0,
        "成交额": 97389.0,
        "振幅": 2.73,
        "换手率": 0.09,
        "symbol": "105.TYGO"
    },
    {
        "序号": 2289,
        "name": "Pacer American Energy Independe",
        "最新价": 28.71,
        "涨跌额": 0.31,
        "涨跌幅": 1.09,
        "开盘价": 28.52,
        "最高价": 28.76,
        "最低价": 28.52,
        "昨收价": 28.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 30027.0,
        "成交额": 861000.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.USAI"
    },
    {
        "序号": 2290,
        "name": "沃尔格林-联合博姿",
        "最新价": 23.16,
        "涨跌额": 0.25,
        "涨跌幅": 1.09,
        "开盘价": 22.95,
        "最高价": 23.42,
        "最低价": 22.72,
        "昨收价": 22.91,
        "总市值": 19967787025.0,
        "市盈率": -6.48,
        "成交量": 17563853.0,
        "成交额": 406455520.0,
        "振幅": 3.06,
        "换手率": 2.04,
        "symbol": "105.WBA"
    },
    {
        "序号": 2291,
        "name": "Alger Mid Cap 40 ETF",
        "最新价": 12.97,
        "涨跌额": 0.14,
        "涨跌幅": 1.09,
        "开盘价": 12.87,
        "最高价": 12.97,
        "最低价": 12.7,
        "昨收价": 12.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 32827.0,
        "成交额": 421904.0,
        "振幅": 2.1,
        "换手率": null,
        "symbol": "107.FRTY"
    },
    {
        "序号": 2292,
        "name": "Sagaliam Acquisition Corp-A",
        "最新价": 11.12,
        "涨跌额": 0.12,
        "涨跌幅": 1.09,
        "开盘价": 11.12,
        "最高价": 11.12,
        "最低价": 11.11,
        "昨收价": 11.0,
        "总市值": 548731267.0,
        "市盈率": -160.53,
        "成交量": 524.0,
        "成交额": 5825.0,
        "振幅": 0.09,
        "换手率": 0.0,
        "symbol": "105.SAGA"
    },
    {
        "序号": 2293,
        "name": "The Central and Eastern Europe ",
        "最新价": 9.27,
        "涨跌额": 0.1,
        "涨跌幅": 1.09,
        "开盘价": 9.15,
        "最高价": 9.32,
        "最低价": 9.15,
        "昨收价": 9.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 7026.0,
        "成交额": 65170.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "106.CEE"
    },
    {
        "序号": 2294,
        "name": "Truist Financial Corp",
        "最新价": 34.33,
        "涨跌额": 0.37,
        "涨跌幅": 1.09,
        "开盘价": 34.0,
        "最高价": 34.58,
        "最低价": 33.73,
        "昨收价": 33.96,
        "总市值": 45784825083.0,
        "市盈率": 8.06,
        "成交量": 11424116.0,
        "成交额": 392421280.0,
        "振幅": 2.5,
        "换手率": 0.86,
        "symbol": "106.TFC"
    },
    {
        "序号": 2295,
        "name": "LCI Industries",
        "最新价": 116.12,
        "涨跌额": 1.25,
        "涨跌幅": 1.09,
        "开盘价": 114.39,
        "最高价": 116.49,
        "最低价": 113.77,
        "昨收价": 114.87,
        "总市值": 2940690113.0,
        "市盈率": 59.48,
        "成交量": 155245.0,
        "成交额": 17988573.0,
        "振幅": 2.37,
        "换手率": 0.61,
        "symbol": "106.LCII"
    },
    {
        "序号": 2296,
        "name": "RiverNorth\/DoubleLine Strategic",
        "最新价": 19.51,
        "涨跌额": 0.21,
        "涨跌幅": 1.09,
        "开盘价": 19.5,
        "最高价": 19.51,
        "最低价": 19.5,
        "昨收价": 19.3,
        "总市值": 46824000.0,
        "市盈率": null,
        "成交量": 1631.0,
        "成交额": 31810.0,
        "振幅": 0.05,
        "换手率": 0.07,
        "symbol": "106.OPP_B"
    },
    {
        "序号": 2297,
        "name": "iMGP DBi Managed Futures Strate",
        "最新价": 26.96,
        "涨跌额": 0.29,
        "涨跌幅": 1.09,
        "开盘价": 26.75,
        "最高价": 27.0,
        "最低价": 26.75,
        "昨收价": 26.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 222681.0,
        "成交额": 5996324.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.DBMF"
    },
    {
        "序号": 2298,
        "name": "SPAR Group Inc",
        "最新价": 0.93,
        "涨跌额": 0.01,
        "涨跌幅": 1.09,
        "开盘价": 0.86,
        "最高价": 0.95,
        "最低价": 0.86,
        "昨收价": 0.92,
        "总市值": 21614092.0,
        "市盈率": -28.55,
        "成交量": 23669.0,
        "成交额": 21390.0,
        "振幅": 9.78,
        "换手率": 0.1,
        "symbol": "105.SGRP"
    },
    {
        "序号": 2299,
        "name": "ProPetro Holding Corp",
        "最新价": 8.37,
        "涨跌额": 0.09,
        "涨跌幅": 1.09,
        "开盘价": 8.42,
        "最高价": 8.48,
        "最低价": 8.2,
        "昨收价": 8.28,
        "总市值": 922689574.0,
        "市盈率": 7.97,
        "成交量": 2164698.0,
        "成交额": 18028845.0,
        "振幅": 3.38,
        "换手率": 1.96,
        "symbol": "106.PUMP"
    },
    {
        "序号": 2300,
        "name": "Phio Pharmaceuticals Corp",
        "最新价": 0.93,
        "涨跌额": 0.01,
        "涨跌幅": 1.09,
        "开盘价": 0.9,
        "最高价": 1.02,
        "最低价": 0.88,
        "昨收价": 0.92,
        "总市值": 2272406.0,
        "市盈率": -0.19,
        "成交量": 478691.0,
        "成交额": 447432.0,
        "振幅": 15.22,
        "换手率": 19.59,
        "symbol": "105.PHIO"
    },
    {
        "序号": 2301,
        "name": "NOVONIX Ltd ADR",
        "最新价": 1.86,
        "涨跌额": 0.02,
        "涨跌幅": 1.09,
        "开盘价": 1.83,
        "最高价": 1.86,
        "最低价": 1.81,
        "昨收价": 1.84,
        "总市值": 226350199.0,
        "市盈率": -4.61,
        "成交量": 29633.0,
        "成交额": 54212.0,
        "振幅": 2.72,
        "换手率": 0.02,
        "symbol": "105.NVX"
    },
    {
        "序号": 2302,
        "name": "Tema Luxury ETF",
        "最新价": 23.25,
        "涨跌额": 0.25,
        "涨跌幅": 1.09,
        "开盘价": 23.25,
        "最高价": 23.25,
        "最低价": 23.25,
        "昨收价": 23.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 139.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LUX"
    },
    {
        "序号": 2303,
        "name": "秘鲁ETF-iShares MSCI",
        "最新价": 30.69,
        "涨跌额": 0.33,
        "涨跌幅": 1.09,
        "开盘价": 30.34,
        "最高价": 30.88,
        "最低价": 29.96,
        "昨收价": 30.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 26965.0,
        "成交额": 823612.0,
        "振幅": 3.03,
        "换手率": null,
        "symbol": "107.EPU"
    },
    {
        "序号": 2304,
        "name": "Tekla Healthcare Opportunities ",
        "最新价": 17.68,
        "涨跌额": 0.19,
        "涨跌幅": 1.09,
        "开盘价": 17.4,
        "最高价": 17.72,
        "最低价": 17.4,
        "昨收价": 17.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 241115.0,
        "成交额": 4252258.0,
        "振幅": 1.83,
        "换手率": null,
        "symbol": "106.THQ"
    },
    {
        "序号": 2305,
        "name": "Allient Inc",
        "最新价": 27.92,
        "涨跌额": 0.3,
        "涨跌幅": 1.09,
        "开盘价": 27.72,
        "最高价": 28.19,
        "最低价": 27.14,
        "昨收价": 27.62,
        "总市值": 452618351.0,
        "市盈率": 19.3,
        "成交量": 31716.0,
        "成交额": 885122.0,
        "振幅": 3.8,
        "换手率": 0.2,
        "symbol": "105.ALNT"
    },
    {
        "序号": 2306,
        "name": "IBM国际商业机器(US)",
        "最新价": 161.96,
        "涨跌额": 1.74,
        "涨跌幅": 1.09,
        "开盘价": 160.0,
        "最高价": 162.04,
        "最低价": 160.0,
        "昨收价": 160.22,
        "总市值": 147888685379.0,
        "市盈率": 21.36,
        "成交量": 4561342.0,
        "成交额": 736973568.0,
        "振幅": 1.27,
        "换手率": 0.5,
        "symbol": "106.IBM"
    },
    {
        "序号": 2307,
        "name": "阿波罗房地产金融",
        "最新价": 11.17,
        "涨跌额": 0.12,
        "涨跌幅": 1.09,
        "开盘价": 11.03,
        "最高价": 11.19,
        "最低价": 10.98,
        "昨收价": 11.05,
        "总市值": 1578975618.0,
        "市盈率": 207.76,
        "成交量": 623467.0,
        "成交额": 6932519.0,
        "振幅": 1.9,
        "换手率": 0.44,
        "symbol": "106.ARI"
    },
    {
        "序号": 2308,
        "name": "DecisionPoint Systems Inc",
        "最新价": 6.52,
        "涨跌额": 0.07,
        "涨跌幅": 1.09,
        "开盘价": 6.31,
        "最高价": 6.52,
        "最低价": 6.31,
        "昨收价": 6.45,
        "总市值": 49909329.0,
        "市盈率": 15.68,
        "成交量": 3704.0,
        "成交额": 23995.0,
        "振幅": 3.26,
        "换手率": 0.05,
        "symbol": "107.DPSI"
    },
    {
        "序号": 2309,
        "name": "帕克合众银行",
        "最新价": 18.65,
        "涨跌额": 0.2,
        "涨跌幅": 1.08,
        "开盘价": 18.55,
        "最高价": 18.7,
        "最低价": 18.45,
        "昨收价": 18.45,
        "总市值": 222805414.0,
        "市盈率": 7.25,
        "成交量": 12241.0,
        "成交额": 227808.0,
        "振幅": 1.36,
        "换手率": 0.1,
        "symbol": "105.PKBK"
    },
    {
        "序号": 2310,
        "name": "WinVest Acquisition Corp Unit C",
        "最新价": 12.13,
        "涨跌额": 0.13,
        "涨跌幅": 1.08,
        "开盘价": 11.9,
        "最高价": 12.13,
        "最低价": 11.9,
        "昨收价": 12.0,
        "总市值": 50223404.0,
        "市盈率": -51.45,
        "成交量": 701.0,
        "成交额": 8459.0,
        "振幅": 1.92,
        "换手率": null,
        "symbol": "105.WINVU"
    },
    {
        "序号": 2311,
        "name": "前线通信",
        "最新价": 23.34,
        "涨跌额": 0.25,
        "涨跌幅": 1.08,
        "开盘价": 23.09,
        "最高价": 23.64,
        "最低价": 22.94,
        "昨收价": 23.09,
        "总市值": 5736715260.0,
        "市盈率": 34.35,
        "成交量": 1400144.0,
        "成交额": 32624735.0,
        "振幅": 3.03,
        "换手率": 0.57,
        "symbol": "105.FYBR"
    },
    {
        "序号": 2312,
        "name": "InvenTrust Properties Corp",
        "最新价": 25.21,
        "涨跌额": 0.27,
        "涨跌幅": 1.08,
        "开盘价": 24.89,
        "最高价": 25.23,
        "最低价": 24.72,
        "昨收价": 24.94,
        "总市值": 1702464955.0,
        "市盈率": 755.31,
        "成交量": 437134.0,
        "成交额": 10975965.0,
        "振幅": 2.04,
        "换手率": 0.65,
        "symbol": "106.IVT"
    },
    {
        "序号": 2313,
        "name": "Accolade Inc",
        "最新价": 9.34,
        "涨跌额": 0.1,
        "涨跌幅": 1.08,
        "开盘价": 9.19,
        "最高价": 9.39,
        "最低价": 8.95,
        "昨收价": 9.24,
        "总市值": 712008673.0,
        "市盈率": -5.03,
        "成交量": 361948.0,
        "成交额": 3355320.0,
        "振幅": 4.76,
        "换手率": 0.47,
        "symbol": "105.ACCD"
    },
    {
        "序号": 2314,
        "name": "微型股ETF-iShares",
        "最新价": 107.42,
        "涨跌额": 1.15,
        "涨跌幅": 1.08,
        "开盘价": 106.09,
        "最高价": 107.61,
        "最低价": 106.09,
        "昨收价": 106.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 38405.0,
        "成交额": 4116657.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "107.IWC"
    },
    {
        "序号": 2315,
        "name": "医疗保健房地产信托",
        "最新价": 15.88,
        "涨跌额": 0.17,
        "涨跌幅": 1.08,
        "开盘价": 15.68,
        "最高价": 15.91,
        "最低价": 15.64,
        "昨收价": 15.71,
        "总市值": 6048280359.0,
        "市盈率": -22.12,
        "成交量": 2911937.0,
        "成交额": 46092473.0,
        "振幅": 1.72,
        "换手率": 0.76,
        "symbol": "106.HR"
    },
    {
        "序号": 2316,
        "name": "芬塔公司",
        "最新价": 46.72,
        "涨跌额": 0.5,
        "涨跌幅": 1.08,
        "开盘价": 46.02,
        "最高价": 46.86,
        "最低价": 46.02,
        "昨收价": 46.22,
        "总市值": 18799228500.0,
        "市盈率": 3894.6,
        "成交量": 1954697.0,
        "成交额": 91161245.0,
        "振幅": 1.82,
        "换手率": 0.49,
        "symbol": "106.VTR"
    },
    {
        "序号": 2317,
        "name": "能源ETF-SPDR",
        "最新价": 82.23,
        "涨跌额": 0.88,
        "涨跌幅": 1.08,
        "开盘价": 81.89,
        "最高价": 82.48,
        "最低价": 81.73,
        "昨收价": 81.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 17063628.0,
        "成交额": 1401276128.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.XLE"
    },
    {
        "序号": 2318,
        "name": "Erasca Inc",
        "最新价": 1.87,
        "涨跌额": 0.02,
        "涨跌幅": 1.08,
        "开盘价": 1.9,
        "最高价": 1.93,
        "最低价": 1.86,
        "昨收价": 1.85,
        "总市值": 282532761.0,
        "市盈率": -1.23,
        "成交量": 1984315.0,
        "成交额": 3748438.0,
        "振幅": 3.78,
        "换手率": 1.31,
        "symbol": "105.ERAS"
    },
    {
        "序号": 2319,
        "name": "Babcock & Wilcox Enterprises In",
        "最新价": 20.57,
        "涨跌额": 0.22,
        "涨跌幅": 1.08,
        "开盘价": 20.3,
        "最高价": 20.7,
        "最低价": 19.9,
        "昨收价": 20.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 24057.0,
        "成交额": 485799.0,
        "振幅": 3.93,
        "换手率": null,
        "symbol": "106.BWSN"
    },
    {
        "序号": 2320,
        "name": "仲量联行",
        "最新价": 166.59,
        "涨跌额": 1.78,
        "涨跌幅": 1.08,
        "开盘价": 164.98,
        "最高价": 166.82,
        "最低价": 163.22,
        "昨收价": 164.81,
        "总市值": 7925176741.0,
        "市盈率": 34.79,
        "成交量": 160474.0,
        "成交额": 26635669.0,
        "振幅": 2.18,
        "换手率": 0.34,
        "symbol": "106.JLL"
    },
    {
        "序号": 2321,
        "name": "Live Nation Entertainment Inc",
        "最新价": 85.19,
        "涨跌额": 0.91,
        "涨跌幅": 1.08,
        "开盘价": 84.39,
        "最高价": 85.45,
        "最低价": 82.33,
        "昨收价": 84.28,
        "总市值": 19621417418.0,
        "市盈率": 34.36,
        "成交量": 2788645.0,
        "成交额": 236246092.0,
        "振幅": 3.7,
        "换手率": 1.21,
        "symbol": "106.LYV"
    },
    {
        "序号": 2322,
        "name": "道富",
        "最新价": 73.97,
        "涨跌额": 0.79,
        "涨跌幅": 1.08,
        "开盘价": 73.15,
        "最高价": 74.17,
        "最低价": 72.92,
        "昨收价": 73.18,
        "总市值": 22825922309.0,
        "市盈率": 9.25,
        "成交量": 2149842.0,
        "成交额": 158642549.0,
        "振幅": 1.71,
        "换手率": 0.7,
        "symbol": "106.STT"
    },
    {
        "序号": 2323,
        "name": "埃姆科",
        "最新价": 214.73,
        "涨跌额": 2.29,
        "涨跌幅": 1.08,
        "开盘价": 212.49,
        "最高价": 215.23,
        "最低价": 212.01,
        "昨收价": 212.44,
        "总市值": 10101852601.0,
        "市盈率": 18.44,
        "成交量": 197451.0,
        "成交额": 42153550.0,
        "振幅": 1.52,
        "换手率": 0.42,
        "symbol": "106.EME"
    },
    {
        "序号": 2324,
        "name": "Tortoise Energy Independence Fu",
        "最新价": 28.14,
        "涨跌额": 0.3,
        "涨跌幅": 1.08,
        "开盘价": 27.9,
        "最高价": 28.19,
        "最低价": 27.9,
        "昨收价": 27.84,
        "总市值": 408482238.0,
        "市盈率": null,
        "成交量": 3358.0,
        "成交额": 94324.0,
        "振幅": 1.04,
        "换手率": 0.02,
        "symbol": "106.NDP"
    },
    {
        "序号": 2325,
        "name": "Victory Capital Holdings Inc-A",
        "最新价": 32.85,
        "涨跌额": 0.35,
        "涨跌幅": 1.08,
        "开盘价": 32.54,
        "最高价": 33.04,
        "最低价": 32.54,
        "昨收价": 32.5,
        "总市值": 2167752743.0,
        "市盈率": 10.31,
        "成交量": 319381.0,
        "成交额": 10496582.0,
        "振幅": 1.54,
        "换手率": 0.48,
        "symbol": "105.VCTR"
    },
    {
        "序号": 2326,
        "name": "Gogo Inc",
        "最新价": 10.33,
        "涨跌额": 0.11,
        "涨跌幅": 1.08,
        "开盘价": 10.22,
        "最高价": 10.43,
        "最低价": 10.18,
        "昨收价": 10.22,
        "总市值": 1330931373.0,
        "市盈率": 8.38,
        "成交量": 480686.0,
        "成交额": 4950810.0,
        "振幅": 2.45,
        "换手率": 0.37,
        "symbol": "105.GOGO"
    },
    {
        "序号": 2327,
        "name": "Birkenstock Holding plc",
        "最新价": 47.91,
        "涨跌额": 0.51,
        "涨跌幅": 1.08,
        "开盘价": 47.4,
        "最高价": 48.3,
        "最低价": 46.64,
        "昨收价": 47.4,
        "总市值": 8998724113.0,
        "市盈率": 55.56,
        "成交量": 242442.0,
        "成交额": 11552024.0,
        "振幅": 3.5,
        "换手率": 0.13,
        "symbol": "106.BIRK"
    },
    {
        "序号": 2328,
        "name": "First Trust Lunt U.S. Factor Ro",
        "最新价": 26.31,
        "涨跌额": 0.28,
        "涨跌幅": 1.08,
        "开盘价": 26.17,
        "最高价": 26.31,
        "最低价": 26.09,
        "昨收价": 26.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 17580.0,
        "成交额": 461482.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.FCTR"
    },
    {
        "序号": 2329,
        "name": "Swvl Holdings Corp-A",
        "最新价": 0.94,
        "涨跌额": 0.01,
        "涨跌幅": 1.08,
        "开盘价": 0.93,
        "最高价": 0.96,
        "最低价": 0.9,
        "昨收价": 0.93,
        "总市值": 6384109.0,
        "市盈率": -0.05,
        "成交量": 4411.0,
        "成交额": 4016.0,
        "振幅": 6.45,
        "换手率": 0.06,
        "symbol": "105.SWVL"
    },
    {
        "序号": 2330,
        "name": "Sequans Communications SA ADR",
        "最新价": 2.82,
        "涨跌额": 0.03,
        "涨跌幅": 1.08,
        "开盘价": 2.81,
        "最高价": 2.82,
        "最低价": 2.79,
        "昨收价": 2.79,
        "总市值": 171710918.0,
        "市盈率": -5.99,
        "成交量": 45518.0,
        "成交额": 127801.0,
        "振幅": 1.08,
        "换手率": 0.07,
        "symbol": "106.SQNS"
    },
    {
        "序号": 2331,
        "name": "Portillo’s Inc-A",
        "最新价": 15.98,
        "涨跌额": 0.17,
        "涨跌幅": 1.08,
        "开盘价": 15.75,
        "最高价": 16.01,
        "最低价": 15.62,
        "昨收价": 15.81,
        "总市值": 1165184880.0,
        "市盈率": 79.87,
        "成交量": 381984.0,
        "成交额": 6052647.0,
        "振幅": 2.47,
        "换手率": 0.52,
        "symbol": "105.PTLO"
    },
    {
        "序号": 2332,
        "name": "NICE Ltd ADR",
        "最新价": 203.2,
        "涨跌额": 2.16,
        "涨跌幅": 1.07,
        "开盘价": 199.26,
        "最高价": 203.61,
        "最低价": 199.12,
        "昨收价": 201.04,
        "总市值": 12856703979.0,
        "市盈率": 39.22,
        "成交量": 302939.0,
        "成交额": 61199959.0,
        "振幅": 2.23,
        "换手率": 0.48,
        "symbol": "105.NICE"
    },
    {
        "序号": 2333,
        "name": "Core Laboratories Inc",
        "最新价": 17.88,
        "涨跌额": 0.19,
        "涨跌幅": 1.07,
        "开盘价": 17.81,
        "最高价": 18.25,
        "最低价": 17.66,
        "昨收价": 17.69,
        "总市值": 835418218.0,
        "市盈率": 20.26,
        "成交量": 213158.0,
        "成交额": 3805130.0,
        "振幅": 3.34,
        "换手率": 0.46,
        "symbol": "106.CLB"
    },
    {
        "序号": 2334,
        "name": "Atlas Energy Solutions Inc",
        "最新价": 16.94,
        "涨跌额": 0.18,
        "涨跌幅": 1.07,
        "开盘价": 17.06,
        "最高价": 17.23,
        "最低价": 16.9,
        "昨收价": 16.76,
        "总市值": 1694433393.0,
        "市盈率": 7.62,
        "成交量": 611440.0,
        "成交额": 10385924.0,
        "振幅": 1.97,
        "换手率": 0.61,
        "symbol": "106.AESI"
    },
    {
        "序号": 2335,
        "name": "Adaptive Biotechnologies Corp",
        "最新价": 4.71,
        "涨跌额": 0.05,
        "涨跌幅": 1.07,
        "开盘价": 4.63,
        "最高价": 4.74,
        "最低价": 4.5,
        "昨收价": 4.66,
        "总市值": 681879657.0,
        "市盈率": -3.48,
        "成交量": 1000323.0,
        "成交额": 4687266.0,
        "振幅": 5.15,
        "换手率": 0.69,
        "symbol": "105.ADPT"
    },
    {
        "序号": 2336,
        "name": "Veralto Corp",
        "最新价": 75.43,
        "涨跌额": 0.8,
        "涨跌幅": 1.07,
        "开盘价": 74.6,
        "最高价": 75.65,
        "最低价": 74.27,
        "昨收价": 74.63,
        "总市值": 18579016740.0,
        "市盈率": null,
        "成交量": 1131349.0,
        "成交额": 85105161.0,
        "振幅": 1.85,
        "换手率": 0.46,
        "symbol": "106.VLTO"
    },
    {
        "序号": 2337,
        "name": "泰普尔丝涟",
        "最新价": 46.22,
        "涨跌额": 0.49,
        "涨跌幅": 1.07,
        "开盘价": 45.6,
        "最高价": 46.45,
        "最低价": 45.6,
        "昨收价": 45.73,
        "总市值": 7962519671.0,
        "市盈率": 20.28,
        "成交量": 1310351.0,
        "成交额": 60423149.0,
        "振幅": 1.86,
        "换手率": 0.76,
        "symbol": "106.TPX"
    },
    {
        "序号": 2338,
        "name": "ACRES Commercial Realty Corp",
        "最新价": 8.49,
        "涨跌额": 0.09,
        "涨跌幅": 1.07,
        "开盘价": 8.32,
        "最高价": 8.52,
        "最低价": 8.32,
        "昨收价": 8.4,
        "总市值": 71784062.0,
        "市盈率": 5.41,
        "成交量": 35063.0,
        "成交额": 295283.0,
        "振幅": 2.38,
        "换手率": 0.41,
        "symbol": "106.ACR"
    },
    {
        "序号": 2339,
        "name": "Hannon Armstrong Sustainable In",
        "最新价": 25.51,
        "涨跌额": 0.27,
        "涨跌幅": 1.07,
        "开盘价": 25.15,
        "最高价": 25.77,
        "最低价": 24.9,
        "昨收价": 25.24,
        "总市值": 2839376060.0,
        "市盈率": 72.53,
        "成交量": 819262.0,
        "成交额": 20768315.0,
        "振幅": 3.45,
        "换手率": 0.74,
        "symbol": "106.HASI"
    },
    {
        "序号": 2340,
        "name": "海滩酒店度假",
        "最新价": 7.56,
        "涨跌额": 0.08,
        "涨跌幅": 1.07,
        "开盘价": 7.48,
        "最高价": 7.56,
        "最低价": 7.46,
        "昨收价": 7.48,
        "总市值": 1046719120.0,
        "市盈率": 27.18,
        "成交量": 336171.0,
        "成交额": 2529452.0,
        "振幅": 1.34,
        "换手率": 0.24,
        "symbol": "105.PLYA"
    },
    {
        "序号": 2341,
        "name": "enVVeno Medical Corp",
        "最新价": 3.78,
        "涨跌额": 0.04,
        "涨跌幅": 1.07,
        "开盘价": 3.7,
        "最高价": 3.84,
        "最低价": 3.5,
        "昨收价": 3.74,
        "总市值": 50336884.0,
        "市盈率": -2.09,
        "成交量": 85447.0,
        "成交额": 317591.0,
        "振幅": 9.09,
        "换手率": 0.64,
        "symbol": "105.NVNO"
    },
    {
        "序号": 2342,
        "name": "Westlake Chemical Partners LP",
        "最新价": 21.77,
        "涨跌额": 0.23,
        "涨跌幅": 1.07,
        "开盘价": 21.68,
        "最高价": 21.85,
        "最低价": 21.62,
        "昨收价": 21.54,
        "总市值": 766916477.0,
        "市盈率": 13.51,
        "成交量": 22637.0,
        "成交额": 492615.0,
        "振幅": 1.07,
        "换手率": 0.06,
        "symbol": "106.WLKP"
    },
    {
        "序号": 2343,
        "name": "Wrap Technologies Inc",
        "最新价": 2.84,
        "涨跌额": 0.03,
        "涨跌幅": 1.07,
        "开盘价": 2.8,
        "最高价": 2.95,
        "最低价": 2.8,
        "昨收价": 2.81,
        "总市值": 125568240.0,
        "市盈率": -8.18,
        "成交量": 169952.0,
        "成交额": 484747.0,
        "振幅": 5.34,
        "换手率": 0.38,
        "symbol": "105.WRAP"
    },
    {
        "序号": 2344,
        "name": "台积电",
        "最新价": 100.35,
        "涨跌额": 1.06,
        "涨跌幅": 1.07,
        "开盘价": 99.12,
        "最高价": 100.37,
        "最低价": 98.95,
        "昨收价": 99.29,
        "总市值": 520456664970.0,
        "市盈率": 18.88,
        "成交量": 10572458.0,
        "成交额": 1055234128.0,
        "振幅": 1.43,
        "换手率": 0.2,
        "symbol": "106.TSM"
    },
    {
        "序号": 2345,
        "name": "Altitude Acquisition Corp",
        "最新价": 10.42,
        "涨跌额": 0.11,
        "涨跌幅": 1.07,
        "开盘价": 10.4,
        "最高价": 10.43,
        "最低价": 10.35,
        "昨收价": 10.31,
        "总市值": 92057001.0,
        "市盈率": -23.19,
        "成交量": 5383.0,
        "成交额": 55936.0,
        "振幅": 0.78,
        "换手率": 0.06,
        "symbol": "105.ALTU"
    },
    {
        "序号": 2346,
        "name": "安霸",
        "最新价": 57.82,
        "涨跌额": 0.61,
        "涨跌幅": 1.07,
        "开盘价": 56.83,
        "最高价": 58.33,
        "最低价": 56.83,
        "昨收价": 57.21,
        "总市值": 2327143581.0,
        "市盈率": -19.41,
        "成交量": 1412163.0,
        "成交额": 81500192.0,
        "振幅": 2.62,
        "换手率": 3.51,
        "symbol": "105.AMBA"
    },
    {
        "序号": 2347,
        "name": "林肯教育服务",
        "最新价": 9.48,
        "涨跌额": 0.1,
        "涨跌幅": 1.07,
        "开盘价": 9.34,
        "最高价": 9.57,
        "最低价": 9.26,
        "昨收价": 9.38,
        "总市值": 297284363.0,
        "市盈率": 10.71,
        "成交量": 43701.0,
        "成交额": 412495.0,
        "振幅": 3.3,
        "换手率": 0.14,
        "symbol": "105.LINC"
    },
    {
        "序号": 2348,
        "name": "大宗商品指数ETF-iShares GSCI",
        "最新价": 19.92,
        "涨跌额": 0.21,
        "涨跌幅": 1.07,
        "开盘价": 19.87,
        "最高价": 20.01,
        "最低价": 19.82,
        "昨收价": 19.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 447087.0,
        "成交额": 8904583.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.GSG"
    },
    {
        "序号": 2349,
        "name": "SoFi Be Your Own Boss ETF",
        "最新价": 18.04,
        "涨跌额": 0.19,
        "涨跌幅": 1.06,
        "开盘价": 18.02,
        "最高价": 18.04,
        "最低价": 17.95,
        "昨收价": 17.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 2001.0,
        "成交额": 36028.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "105.BYOB"
    },
    {
        "序号": 2350,
        "name": "布鲁林克斯",
        "最新价": 94.0,
        "涨跌额": 0.99,
        "涨跌幅": 1.06,
        "开盘价": 92.8,
        "最高价": 94.85,
        "最低价": 92.25,
        "昨收价": 93.01,
        "总市值": 824116894.0,
        "市盈率": 8.35,
        "成交量": 82771.0,
        "成交额": 7775627.0,
        "振幅": 2.8,
        "换手率": 0.94,
        "symbol": "106.BXC"
    },
    {
        "序号": 2351,
        "name": "Regency Centers Corp Series A P",
        "最新价": 23.74,
        "涨跌额": 0.25,
        "涨跌幅": 1.06,
        "开盘价": 23.5,
        "最高价": 23.74,
        "最低价": 23.49,
        "昨收价": 23.49,
        "总市值": 109204000.0,
        "市盈率": null,
        "成交量": 7168.0,
        "成交额": 168512.0,
        "振幅": 1.06,
        "换手率": 0.16,
        "symbol": "105.REGCP"
    },
    {
        "序号": 2352,
        "name": "Integra Resources Corp",
        "最新价": 0.95,
        "涨跌额": 0.01,
        "涨跌幅": 1.06,
        "开盘价": 0.95,
        "最高价": 0.95,
        "最低价": 0.91,
        "昨收价": 0.94,
        "总市值": 65338650.0,
        "市盈率": -2.31,
        "成交量": 27156.0,
        "成交额": 25208.0,
        "振幅": 4.26,
        "换手率": 0.04,
        "symbol": "107.ITRG"
    },
    {
        "序号": 2353,
        "name": "Strive U.S. Energy ETF",
        "最新价": 27.55,
        "涨跌额": 0.29,
        "涨跌幅": 1.06,
        "开盘价": 27.44,
        "最高价": 27.6,
        "最低价": 27.42,
        "昨收价": 27.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 34970.0,
        "成交额": 961522.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.DRLL"
    },
    {
        "序号": 2354,
        "name": "Clover Health Investments Corp-",
        "最新价": 0.95,
        "涨跌额": 0.01,
        "涨跌幅": 1.06,
        "开盘价": 0.94,
        "最高价": 0.97,
        "最低价": 0.93,
        "昨收价": 0.94,
        "总市值": 463261740.0,
        "市盈率": -2.05,
        "成交量": 5476815.0,
        "成交额": 5178210.0,
        "振幅": 4.26,
        "换手率": 1.12,
        "symbol": "105.CLOV"
    },
    {
        "序号": 2355,
        "name": "First Trust Nasdaq Oil & Gas ET",
        "最新价": 27.57,
        "涨跌额": 0.29,
        "涨跌幅": 1.06,
        "开盘价": 27.46,
        "最高价": 27.66,
        "最低价": 27.42,
        "昨收价": 27.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 50235.0,
        "成交额": 1383100.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.FTXN"
    },
    {
        "序号": 2356,
        "name": "伟创力",
        "最新价": 25.67,
        "涨跌额": 0.27,
        "涨跌幅": 1.06,
        "开盘价": 25.33,
        "最高价": 25.72,
        "最低价": 25.31,
        "昨收价": 25.4,
        "总市值": 11125327353.0,
        "市盈率": 14.15,
        "成交量": 2868205.0,
        "成交额": 73373609.0,
        "振幅": 1.61,
        "换手率": 0.66,
        "symbol": "105.FLEX"
    },
    {
        "序号": 2357,
        "name": "YieldMax XOM Option Income Stra",
        "最新价": 17.14,
        "涨跌额": 0.18,
        "涨跌幅": 1.06,
        "开盘价": 17.1,
        "最高价": 17.14,
        "最低价": 17.01,
        "昨收价": 16.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 11671.0,
        "成交额": 199420.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.XOMO"
    },
    {
        "序号": 2358,
        "name": "iShares U.S. Oil & Gas Explorat",
        "最新价": 90.47,
        "涨跌额": 0.95,
        "涨跌幅": 1.06,
        "开盘价": 90.09,
        "最高价": 90.79,
        "最低价": 89.97,
        "昨收价": 89.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 148064.0,
        "成交额": 13381305.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.IEO"
    },
    {
        "序号": 2359,
        "name": "YieldMax TSLA Option Income Str",
        "最新价": 11.43,
        "涨跌额": 0.12,
        "涨跌幅": 1.06,
        "开盘价": 11.25,
        "最高价": 11.45,
        "最低价": 11.21,
        "昨收价": 11.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 5530598.0,
        "成交额": 62813411.0,
        "振幅": 2.12,
        "换手率": null,
        "symbol": "107.TSLY"
    },
    {
        "序号": 2360,
        "name": "Adams Natural Resources Fund",
        "最新价": 20.03,
        "涨跌额": 0.21,
        "涨跌幅": 1.06,
        "开盘价": 19.87,
        "最高价": 20.1,
        "最低价": 19.87,
        "昨收价": 19.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 64411.0,
        "成交额": 1289427.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "106.PEO"
    },
    {
        "序号": 2361,
        "name": "WD-40 Co",
        "最新价": 239.54,
        "涨跌额": 2.51,
        "涨跌幅": 1.06,
        "开盘价": 237.17,
        "最高价": 239.59,
        "最低价": 235.28,
        "昨收价": 237.03,
        "总市值": 3247368085.0,
        "市盈率": 49.21,
        "成交量": 63374.0,
        "成交额": 15107168.0,
        "振幅": 1.82,
        "换手率": 0.47,
        "symbol": "105.WDFC"
    },
    {
        "序号": 2362,
        "name": "UDR不动产信托",
        "最新价": 35.37,
        "涨跌额": 0.37,
        "涨跌幅": 1.06,
        "开盘价": 34.84,
        "最高价": 35.37,
        "最低价": 34.69,
        "昨收价": 35.0,
        "总市值": 11634188100.0,
        "市盈率": 25.52,
        "成交量": 4831698.0,
        "成交额": 170108603.0,
        "振幅": 1.94,
        "换手率": 1.47,
        "symbol": "106.UDR"
    },
    {
        "序号": 2363,
        "name": "Jack in the Box Inc",
        "最新价": 79.51,
        "涨跌额": 0.83,
        "涨跌幅": 1.05,
        "开盘价": 78.55,
        "最高价": 80.24,
        "最低价": 78.55,
        "昨收价": 78.68,
        "总市值": 1569271616.0,
        "市盈率": 12.0,
        "成交量": 191807.0,
        "成交额": 15236569.0,
        "振幅": 2.15,
        "换手率": 0.97,
        "symbol": "105.JACK"
    },
    {
        "序号": 2364,
        "name": "iShares U.S. Energy ETF",
        "最新价": 43.12,
        "涨跌额": 0.45,
        "涨跌幅": 1.05,
        "开盘价": 42.98,
        "最高价": 43.27,
        "最低价": 42.86,
        "昨收价": 42.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 827441.0,
        "成交额": 35607633.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.IYE"
    },
    {
        "序号": 2365,
        "name": "Tema American Reshoring ETF",
        "最新价": 29.73,
        "涨跌额": 0.31,
        "涨跌幅": 1.05,
        "开盘价": 29.73,
        "最高价": 29.73,
        "最低价": 29.73,
        "昨收价": 29.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 23.0,
        "成交额": 683.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RSHO"
    },
    {
        "序号": 2366,
        "name": "中网载线",
        "最新价": 0.96,
        "涨跌额": 0.01,
        "涨跌幅": 1.05,
        "开盘价": 0.93,
        "最高价": 1.0,
        "最低价": 0.91,
        "昨收价": 0.95,
        "总市值": 6916326.0,
        "市盈率": -0.81,
        "成交量": 24085.0,
        "成交额": 22516.0,
        "振幅": 9.47,
        "换手率": 0.33,
        "symbol": "105.CNET"
    },
    {
        "序号": 2367,
        "name": "BlackRock Resources & Commoditi",
        "最新价": 8.64,
        "涨跌额": 0.09,
        "涨跌幅": 1.05,
        "开盘价": 8.58,
        "最高价": 8.64,
        "最低价": 8.55,
        "昨收价": 8.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 326489.0,
        "成交额": 2814439.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.BCX"
    },
    {
        "序号": 2368,
        "name": "Allied Gaming & Entertainment I",
        "最新价": 0.96,
        "涨跌额": 0.01,
        "涨跌幅": 1.05,
        "开盘价": 0.95,
        "最高价": 0.97,
        "最低价": 0.95,
        "昨收价": 0.95,
        "总市值": 35368956.0,
        "市盈率": -8.32,
        "成交量": 19231.0,
        "成交额": 18390.0,
        "振幅": 2.11,
        "换手率": 0.05,
        "symbol": "105.AGAE"
    },
    {
        "序号": 2369,
        "name": "TrueShares Technology, AI & Dee",
        "最新价": 35.56,
        "涨跌额": 0.37,
        "涨跌幅": 1.05,
        "开盘价": 35.33,
        "最高价": 35.68,
        "最低价": 35.24,
        "昨收价": 35.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 12176.0,
        "成交额": 431451.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.LRNZ"
    },
    {
        "序号": 2370,
        "name": "PJT Partners Inc-A",
        "最新价": 97.07,
        "涨跌额": 1.01,
        "涨跌幅": 1.05,
        "开盘价": 95.79,
        "最高价": 98.25,
        "最低价": 95.44,
        "昨收价": 96.06,
        "总市值": 2353032615.0,
        "市盈率": 28.89,
        "成交量": 371493.0,
        "成交额": 36085160.0,
        "振幅": 2.93,
        "换手率": 1.53,
        "symbol": "106.PJT"
    },
    {
        "序号": 2371,
        "name": "特里迪加",
        "最新价": 4.81,
        "涨跌额": 0.05,
        "涨跌幅": 1.05,
        "开盘价": 4.74,
        "最高价": 4.85,
        "最低价": 4.71,
        "昨收价": 4.76,
        "总市值": 165505549.0,
        "市盈率": -2.23,
        "成交量": 75628.0,
        "成交额": 362039.0,
        "振幅": 2.94,
        "换手率": 0.22,
        "symbol": "106.TG"
    },
    {
        "序号": 2372,
        "name": "IBEX Ltd",
        "最新价": 18.3,
        "涨跌额": 0.19,
        "涨跌幅": 1.05,
        "开盘价": 18.16,
        "最高价": 18.32,
        "最低价": 18.16,
        "昨收价": 18.11,
        "总市值": 328279162.0,
        "市盈率": 10.11,
        "成交量": 38253.0,
        "成交额": 698945.0,
        "振幅": 0.88,
        "换手率": 0.21,
        "symbol": "105.IBEX"
    },
    {
        "序号": 2373,
        "name": "英格索兰",
        "最新价": 73.31,
        "涨跌额": 0.76,
        "涨跌幅": 1.05,
        "开盘价": 72.49,
        "最高价": 73.42,
        "最低价": 72.4,
        "昨收价": 72.55,
        "总市值": 29675666311.0,
        "市盈率": 38.73,
        "成交量": 2723589.0,
        "成交额": 199357898.0,
        "振幅": 1.41,
        "换手率": 0.67,
        "symbol": "106.IR"
    },
    {
        "序号": 2374,
        "name": "印度工业信贷投资银行",
        "最新价": 24.12,
        "涨跌额": 0.25,
        "涨跌幅": 1.05,
        "开盘价": 24.11,
        "最高价": 24.18,
        "最低价": 24.01,
        "昨收价": 23.87,
        "总市值": 84383928902.0,
        "市盈率": 17.36,
        "成交量": 6356984.0,
        "成交额": 153078716.0,
        "振幅": 0.71,
        "换手率": 0.18,
        "symbol": "106.IBN"
    },
    {
        "序号": 2375,
        "name": "科勒尼",
        "最新价": 11.58,
        "涨跌额": 0.12,
        "涨跌幅": 1.05,
        "开盘价": 11.48,
        "最高价": 11.69,
        "最低价": 11.46,
        "昨收价": 11.46,
        "总市值": 203437243.0,
        "市盈率": 9.37,
        "成交量": 15226.0,
        "成交额": 176437.0,
        "振幅": 2.01,
        "换手率": 0.09,
        "symbol": "105.CBAN"
    },
    {
        "序号": 2376,
        "name": "江森自控",
        "最新价": 56.08,
        "涨跌额": 0.58,
        "涨跌幅": 1.05,
        "开盘价": 55.73,
        "最高价": 57.12,
        "最低价": 55.63,
        "昨收价": 55.5,
        "总市值": 38152347507.0,
        "市盈率": 18.51,
        "成交量": 7686080.0,
        "成交额": 432745328.0,
        "振幅": 2.68,
        "换手率": 1.13,
        "symbol": "106.JCI"
    },
    {
        "序号": 2377,
        "name": "DT Midstream Inc",
        "最新价": 56.14,
        "涨跌额": 0.58,
        "涨跌幅": 1.04,
        "开盘价": 55.75,
        "最高价": 56.19,
        "最低价": 55.48,
        "昨收价": 55.56,
        "总市值": 5443953119.0,
        "市盈率": 15.64,
        "成交量": 723923.0,
        "成交额": 40558426.0,
        "振幅": 1.28,
        "换手率": 0.75,
        "symbol": "106.DTM"
    },
    {
        "序号": 2378,
        "name": "ProShares Ultra Consumer Discre",
        "最新价": 33.91,
        "涨跌额": 0.35,
        "涨跌幅": 1.04,
        "开盘价": 33.8,
        "最高价": 33.91,
        "最低价": 33.53,
        "昨收价": 33.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 1925.0,
        "成交额": 64931.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.UCC"
    },
    {
        "序号": 2379,
        "name": "哥伦比亚银行",
        "最新价": 28.12,
        "涨跌额": 0.29,
        "涨跌幅": 1.04,
        "开盘价": 27.83,
        "最高价": 28.17,
        "最低价": 27.83,
        "昨收价": 27.83,
        "总市值": 6761643810.0,
        "市盈率": 5.78,
        "成交量": 133614.0,
        "成交额": 3744557.0,
        "振幅": 1.22,
        "换手率": 0.06,
        "symbol": "106.CIB"
    },
    {
        "序号": 2380,
        "name": "独立银行(密歇根州)",
        "最新价": 23.28,
        "涨跌额": 0.24,
        "涨跌幅": 1.04,
        "开盘价": 23.17,
        "最高价": 23.47,
        "最低价": 22.98,
        "昨收价": 23.04,
        "总市值": 485162603.0,
        "市盈率": 8.03,
        "成交量": 41604.0,
        "成交额": 967267.0,
        "振幅": 2.13,
        "换手率": 0.2,
        "symbol": "105.IBCP"
    },
    {
        "序号": 2381,
        "name": "Blink Charging Co",
        "最新价": 2.91,
        "涨跌额": 0.03,
        "涨跌幅": 1.04,
        "开盘价": 2.9,
        "最高价": 3.06,
        "最低价": 2.82,
        "昨收价": 2.88,
        "总市值": 196157201.0,
        "市盈率": -0.92,
        "成交量": 4655161.0,
        "成交额": 13652176.0,
        "振幅": 8.33,
        "换手率": 6.91,
        "symbol": "105.BLNK"
    },
    {
        "序号": 2382,
        "name": "Simplify Propel Opportunities E",
        "最新价": 20.38,
        "涨跌额": 0.21,
        "涨跌幅": 1.04,
        "开盘价": 20.38,
        "最高价": 20.38,
        "最低价": 20.38,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 22.0,
        "成交额": 448.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SURI"
    },
    {
        "序号": 2383,
        "name": "Ingevity Corp",
        "最新价": 41.74,
        "涨跌额": 0.43,
        "涨跌幅": 1.04,
        "开盘价": 41.18,
        "最高价": 42.38,
        "最低价": 41.05,
        "昨收价": 41.31,
        "总市值": 1512293043.0,
        "市盈率": 11.91,
        "成交量": 217765.0,
        "成交额": 9106986.0,
        "振幅": 3.22,
        "换手率": 0.6,
        "symbol": "106.NGVT"
    },
    {
        "序号": 2384,
        "name": "DoorDash Inc-A",
        "最新价": 100.0,
        "涨跌额": 1.03,
        "涨跌幅": 1.04,
        "开盘价": 99.0,
        "最高价": 100.73,
        "最低价": 97.46,
        "昨收价": 98.97,
        "总市值": 39820871000.0,
        "市盈率": -38.14,
        "成交量": 4309014.0,
        "成交额": 431034960.0,
        "振幅": 3.3,
        "换手率": 1.08,
        "symbol": "105.DASH"
    },
    {
        "序号": 2385,
        "name": "DigitalBridge Group Inc Series ",
        "最新价": 22.34,
        "涨跌额": 0.23,
        "涨跌幅": 1.04,
        "开盘价": 22.0,
        "最高价": 22.34,
        "最低价": 21.8,
        "昨收价": 22.11,
        "总市值": 259456760.0,
        "市盈率": null,
        "成交量": 4509.0,
        "成交额": 99835.0,
        "振幅": 2.44,
        "换手率": 0.04,
        "symbol": "106.DBRG_J"
    },
    {
        "序号": 2386,
        "name": "OppFi Inc",
        "最新价": 3.89,
        "涨跌额": 0.04,
        "涨跌幅": 1.04,
        "开盘价": 3.85,
        "最高价": 3.97,
        "最低价": 3.84,
        "昨收价": 3.85,
        "总市值": 430502721.0,
        "市盈率": 55.93,
        "成交量": 261145.0,
        "成交额": 1016603.0,
        "振幅": 3.38,
        "换手率": 0.24,
        "symbol": "106.OPFI"
    },
    {
        "序号": 2387,
        "name": "Sunstone Hotel Investors Inc Se",
        "最新价": 20.47,
        "涨跌额": 0.21,
        "涨跌幅": 1.04,
        "开盘价": 20.26,
        "最高价": 20.47,
        "最低价": 20.21,
        "昨收价": 20.26,
        "总市值": 81880000.0,
        "市盈率": null,
        "成交量": 3617.0,
        "成交额": 73404.0,
        "振幅": 1.28,
        "换手率": 0.09,
        "symbol": "106.SHO_I"
    },
    {
        "序号": 2388,
        "name": "新美亚电子",
        "最新价": 49.8,
        "涨跌额": 0.51,
        "涨跌幅": 1.03,
        "开盘价": 49.29,
        "最高价": 50.07,
        "最低价": 49.29,
        "昨收价": 49.29,
        "总市值": 2830581553.0,
        "市盈率": 9.13,
        "成交量": 207504.0,
        "成交额": 10323022.0,
        "振幅": 1.58,
        "换手率": 0.37,
        "symbol": "105.SANM"
    },
    {
        "序号": 2389,
        "name": "Associated Banc-Corp",
        "最新价": 19.56,
        "涨跌额": 0.2,
        "涨跌幅": 1.03,
        "开盘价": 19.42,
        "最高价": 19.62,
        "最低价": 19.25,
        "昨收价": 19.36,
        "总市值": 2952380767.0,
        "市盈率": 7.72,
        "成交量": 1065872.0,
        "成交额": 20830920.0,
        "振幅": 1.91,
        "换手率": 0.71,
        "symbol": "106.ASB"
    },
    {
        "序号": 2390,
        "name": "Putnam Focused Large Cap Value ",
        "最新价": 30.32,
        "涨跌额": 0.31,
        "涨跌幅": 1.03,
        "开盘价": 29.96,
        "最高价": 30.38,
        "最低价": 29.96,
        "昨收价": 30.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 59345.0,
        "成交额": 1795084.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "107.PVAL"
    },
    {
        "序号": 2391,
        "name": "Turbo Energy SA ADR",
        "最新价": 1.96,
        "涨跌额": 0.02,
        "涨跌幅": 1.03,
        "开盘价": 1.86,
        "最高价": 2.0,
        "最低价": 1.86,
        "昨收价": 1.94,
        "总市值": 21593594.0,
        "市盈率": 19.7,
        "成交量": 18502.0,
        "成交额": 35350.0,
        "振幅": 7.22,
        "换手率": 0.17,
        "symbol": "105.TURB"
    },
    {
        "序号": 2392,
        "name": "CVB金融",
        "最新价": 19.62,
        "涨跌额": 0.2,
        "涨跌幅": 1.03,
        "开盘价": 19.26,
        "最高价": 19.72,
        "最低价": 19.16,
        "昨收价": 19.42,
        "总市值": 2733809480.0,
        "市盈率": 11.43,
        "成交量": 991107.0,
        "成交额": 19405049.0,
        "振幅": 2.88,
        "换手率": 0.71,
        "symbol": "105.CVBF"
    },
    {
        "序号": 2393,
        "name": "史蒂夫·马登",
        "最新价": 39.26,
        "涨跌额": 0.4,
        "涨跌幅": 1.03,
        "开盘价": 38.69,
        "最高价": 39.33,
        "最低价": 38.62,
        "昨收价": 38.86,
        "总市值": 2929118285.0,
        "市盈率": 17.49,
        "成交量": 388720.0,
        "成交额": 15210173.0,
        "振幅": 1.83,
        "换手率": 0.52,
        "symbol": "105.SHOO"
    },
    {
        "序号": 2394,
        "name": "麦迪逊广场花园体育",
        "最新价": 170.82,
        "涨跌额": 1.74,
        "涨跌幅": 1.03,
        "开盘价": 169.49,
        "最高价": 171.11,
        "最低价": 169.3,
        "昨收价": 169.08,
        "总市值": 4088385382.0,
        "市盈率": 87.37,
        "成交量": 76834.0,
        "成交额": 13099217.0,
        "振幅": 1.07,
        "换手率": 0.32,
        "symbol": "106.MSGS"
    },
    {
        "序号": 2395,
        "name": "百富门-B",
        "最新价": 55.08,
        "涨跌额": 0.56,
        "涨跌幅": 1.03,
        "开盘价": 54.29,
        "最高价": 55.37,
        "最低价": 54.21,
        "昨收价": 54.52,
        "总市值": 26197167611.0,
        "市盈率": 33.59,
        "成交量": 1511640.0,
        "成交额": 83311481.0,
        "振幅": 2.13,
        "换手率": 0.49,
        "symbol": "106.BF_B"
    },
    {
        "序号": 2396,
        "name": "Aurinia Pharmaceuticals Inc",
        "最新价": 8.86,
        "涨跌额": 0.09,
        "涨跌幅": 1.03,
        "开盘价": 8.85,
        "最高价": 8.89,
        "最低价": 8.7,
        "昨收价": 8.77,
        "总市值": 1272368333.0,
        "市盈率": -16.48,
        "成交量": 1251742.0,
        "成交额": 11015457.0,
        "振幅": 2.17,
        "换手率": 0.87,
        "symbol": "105.AUPH"
    },
    {
        "序号": 2397,
        "name": "Clean Harbors Inc",
        "最新价": 171.31,
        "涨跌额": 1.74,
        "涨跌幅": 1.03,
        "开盘价": 170.23,
        "最高价": 171.82,
        "最低价": 169.95,
        "昨收价": 169.57,
        "总市值": 9265517372.0,
        "市盈率": 25.6,
        "成交量": 230783.0,
        "成交额": 39482017.0,
        "振幅": 1.1,
        "换手率": 0.43,
        "symbol": "106.CLH"
    },
    {
        "序号": 2398,
        "name": "LendingClub Corp",
        "最新价": 6.9,
        "涨跌额": 0.07,
        "涨跌幅": 1.02,
        "开盘价": 6.82,
        "最高价": 6.99,
        "最低价": 6.72,
        "昨收价": 6.83,
        "总市值": 756576506.0,
        "市盈率": 14.45,
        "成交量": 1702213.0,
        "成交额": 11690173.0,
        "振幅": 3.95,
        "换手率": 1.55,
        "symbol": "106.LC"
    },
    {
        "序号": 2399,
        "name": "Invesco S&P 500 Momentum ETF",
        "最新价": 62.12,
        "涨跌额": 0.63,
        "涨跌幅": 1.02,
        "开盘价": 61.36,
        "最高价": 62.22,
        "最低价": 61.36,
        "昨收价": 61.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 90311.0,
        "成交额": 5571286.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "107.SPMO"
    },
    {
        "序号": 2400,
        "name": "Towne Bank",
        "最新价": 28.6,
        "涨跌额": 0.29,
        "涨跌幅": 1.02,
        "开盘价": 28.3,
        "最高价": 28.75,
        "最低价": 27.9,
        "昨收价": 28.31,
        "总市值": 2141547236.0,
        "市盈率": 24.43,
        "成交量": 139106.0,
        "成交额": 3970895.0,
        "振幅": 3.0,
        "换手率": 0.19,
        "symbol": "105.TOWN"
    },
    {
        "序号": 2401,
        "name": "TPG Inc-A",
        "最新价": 37.49,
        "涨跌额": 0.38,
        "涨跌幅": 1.02,
        "开盘价": 37.03,
        "最高价": 37.75,
        "最低价": 37.03,
        "昨收价": 37.11,
        "总市值": 11593750334.0,
        "市盈率": 128.03,
        "成交量": 444302.0,
        "成交额": 16645751.0,
        "振幅": 1.94,
        "换手率": 0.14,
        "symbol": "105.TPG"
    },
    {
        "序号": 2402,
        "name": "Global Ship Lease Inc Series B ",
        "最新价": 25.67,
        "涨跌额": 0.26,
        "涨跌幅": 1.02,
        "开盘价": 25.45,
        "最高价": 25.67,
        "最低价": 25.38,
        "昨收价": 25.41,
        "总市值": 1119007.0,
        "市盈率": null,
        "成交量": 12796.0,
        "成交额": 326414.0,
        "振幅": 1.14,
        "换手率": 29.35,
        "symbol": "106.GSL_B"
    },
    {
        "序号": 2403,
        "name": "Block Inc-A",
        "最新价": 69.17,
        "涨跌额": 0.7,
        "涨跌幅": 1.02,
        "开盘价": 67.85,
        "最高价": 70.67,
        "最低价": 67.6,
        "昨收价": 68.47,
        "总市值": 42476812810.0,
        "市盈率": -150.56,
        "成交量": 11485275.0,
        "成交额": 796746048.0,
        "振幅": 4.48,
        "换手率": 1.87,
        "symbol": "106.SQ"
    },
    {
        "序号": 2404,
        "name": "AdvisorShares Vice ETF",
        "最新价": 26.68,
        "涨跌额": 0.27,
        "涨跌幅": 1.02,
        "开盘价": 26.61,
        "最高价": 26.68,
        "最低价": 26.61,
        "昨收价": 26.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 356.0,
        "成交额": 9474.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.VICE"
    },
    {
        "序号": 2405,
        "name": "Target Hospitality Corp",
        "最新价": 10.89,
        "涨跌额": 0.11,
        "涨跌幅": 1.02,
        "开盘价": 10.78,
        "最高价": 11.12,
        "最低价": 10.78,
        "昨收价": 10.78,
        "总市值": 1107092766.0,
        "市盈率": 6.61,
        "成交量": 512028.0,
        "成交额": 5600101.0,
        "振幅": 3.15,
        "换手率": 0.5,
        "symbol": "105.TH"
    },
    {
        "序号": 2406,
        "name": "Amplify Emerging Markets FinTec",
        "最新价": 21.79,
        "涨跌额": 0.22,
        "涨跌幅": 1.02,
        "开盘价": 21.79,
        "最高价": 21.79,
        "最低价": 21.79,
        "昨收价": 21.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 43.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EMFQ"
    },
    {
        "序号": 2407,
        "name": "Simplify U.S. Equity PLUS GBTC ",
        "最新价": 27.75,
        "涨跌额": 0.28,
        "涨跌幅": 1.02,
        "开盘价": 27.63,
        "最高价": 27.75,
        "最低价": 27.54,
        "昨收价": 27.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 3397.0,
        "成交额": 93889.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "105.SPBC"
    },
    {
        "序号": 2408,
        "name": "First Trust Nasdaq Bank ETF",
        "最新价": 23.79,
        "涨跌额": 0.24,
        "涨跌幅": 1.02,
        "开盘价": 23.55,
        "最高价": 23.88,
        "最低价": 23.44,
        "昨收价": 23.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 69247.0,
        "成交额": 1639471.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "105.FTXO"
    },
    {
        "序号": 2409,
        "name": "GraniteShares Platinum Shares E",
        "最新价": 8.93,
        "涨跌额": 0.09,
        "涨跌幅": 1.02,
        "开盘价": 8.87,
        "最高价": 8.97,
        "最低价": 8.87,
        "昨收价": 8.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 19071.0,
        "成交额": 170177.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.PLTM"
    },
    {
        "序号": 2410,
        "name": "铀矿ETF-Global X",
        "最新价": 28.78,
        "涨跌额": 0.29,
        "涨跌幅": 1.02,
        "开盘价": 28.4,
        "最高价": 28.95,
        "最低价": 28.36,
        "昨收价": 28.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 1359588.0,
        "成交额": 39025061.0,
        "振幅": 2.07,
        "换手率": null,
        "symbol": "107.URA"
    },
    {
        "序号": 2411,
        "name": "ioneer Ltd ADS",
        "最新价": 3.97,
        "涨跌额": 0.04,
        "涨跌幅": 1.02,
        "开盘价": 4.13,
        "最高价": 4.25,
        "最低价": 3.97,
        "昨收价": 3.93,
        "总市值": 209359219.0,
        "市盈率": -32.76,
        "成交量": 23418.0,
        "成交额": 95061.0,
        "振幅": 7.12,
        "换手率": 0.04,
        "symbol": "105.IONR"
    },
    {
        "序号": 2412,
        "name": "帝亚吉欧(US ADR)",
        "最新价": 141.96,
        "涨跌额": 1.43,
        "涨跌幅": 1.02,
        "开盘价": 141.57,
        "最高价": 142.73,
        "最低价": 141.33,
        "昨收价": 140.53,
        "总市值": 79653635192.0,
        "市盈率": 17.07,
        "成交量": 656183.0,
        "成交额": 93080590.0,
        "振幅": 1.0,
        "换手率": 0.12,
        "symbol": "106.DEO"
    },
    {
        "序号": 2413,
        "name": "General American Investors Co I",
        "最新价": 24.82,
        "涨跌额": 0.25,
        "涨跌幅": 1.02,
        "开盘价": 24.57,
        "最高价": 24.87,
        "最低价": 24.55,
        "昨收价": 24.57,
        "总市值": 188748331.0,
        "市盈率": null,
        "成交量": 19257.0,
        "成交额": 475180.0,
        "振幅": 1.3,
        "换手率": 0.25,
        "symbol": "106.GAM_B"
    },
    {
        "序号": 2414,
        "name": "P10 Inc-A",
        "最新价": 9.93,
        "涨跌额": 0.1,
        "涨跌幅": 1.02,
        "开盘价": 9.8,
        "最高价": 9.98,
        "最低价": 9.77,
        "昨收价": 9.83,
        "总市值": 1161380647.0,
        "市盈率": -826.61,
        "成交量": 282335.0,
        "成交额": 2800987.0,
        "振幅": 2.14,
        "换手率": 0.24,
        "symbol": "106.PX"
    },
    {
        "序号": 2415,
        "name": "F5",
        "最新价": 170.82,
        "涨跌额": 1.72,
        "涨跌幅": 1.02,
        "开盘价": 169.1,
        "最高价": 170.98,
        "最低价": 169.1,
        "昨收价": 169.1,
        "总市值": 10199185783.0,
        "市盈率": 25.82,
        "成交量": 303013.0,
        "成交额": 51681788.0,
        "振幅": 1.11,
        "换手率": 0.51,
        "symbol": "105.FFIV"
    },
    {
        "序号": 2416,
        "name": "马拉松原油",
        "最新价": 143.02,
        "涨跌额": 1.44,
        "涨跌幅": 1.02,
        "开盘价": 142.61,
        "最高价": 144.15,
        "最低价": 142.07,
        "昨收价": 141.58,
        "总市值": 54304304557.0,
        "市盈率": 4.7,
        "成交量": 3092155.0,
        "成交额": 441907472.0,
        "振幅": 1.47,
        "换手率": 0.81,
        "symbol": "106.MPC"
    },
    {
        "序号": 2417,
        "name": "Invesco DWA Financial Momentum ",
        "最新价": 43.71,
        "涨跌额": 0.44,
        "涨跌幅": 1.02,
        "开盘价": 43.54,
        "最高价": 43.71,
        "最低价": 43.48,
        "昨收价": 43.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 866.0,
        "成交额": 37745.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.PFI"
    },
    {
        "序号": 2418,
        "name": "学乐教育",
        "最新价": 39.77,
        "涨跌额": 0.4,
        "涨跌幅": 1.02,
        "开盘价": 39.53,
        "最高价": 39.94,
        "最低价": 39.47,
        "昨收价": 39.37,
        "总市值": 1233512763.0,
        "市盈率": 21.42,
        "成交量": 124664.0,
        "成交额": 4953745.0,
        "振幅": 1.19,
        "换手率": 0.4,
        "symbol": "105.SCHL"
    },
    {
        "序号": 2419,
        "name": "Berry Corp (bry)",
        "最新价": 6.96,
        "涨跌额": 0.07,
        "涨跌幅": 1.02,
        "开盘价": 6.95,
        "最高价": 7.02,
        "最低价": 6.91,
        "昨收价": 6.89,
        "总市值": 526640761.0,
        "市盈率": 11.25,
        "成交量": 546119.0,
        "成交额": 3798321.0,
        "振幅": 1.6,
        "换手率": 0.72,
        "symbol": "105.BRY"
    },
    {
        "序号": 2420,
        "name": "Invesco DWA Industrials Momentu",
        "最新价": 109.42,
        "涨跌额": 1.1,
        "涨跌幅": 1.02,
        "开盘价": 109.32,
        "最高价": 109.57,
        "最低价": 108.98,
        "昨收价": 108.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 2300.0,
        "成交额": 251547.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "105.PRN"
    },
    {
        "序号": 2421,
        "name": "CION Investment Corp",
        "最新价": 10.96,
        "涨跌额": 0.11,
        "涨跌幅": 1.01,
        "开盘价": 10.79,
        "最高价": 11.0,
        "最低价": 10.79,
        "昨收价": 10.85,
        "总市值": 595122378.0,
        "市盈率": 11.05,
        "成交量": 371472.0,
        "成交额": 4062257.0,
        "振幅": 1.94,
        "换手率": 0.68,
        "symbol": "106.CION"
    },
    {
        "序号": 2422,
        "name": "Armada Hoffler Properties Inc",
        "最新价": 11.97,
        "涨跌额": 0.12,
        "涨跌幅": 1.01,
        "开盘价": 11.84,
        "最高价": 11.97,
        "最低价": 11.77,
        "昨收价": 11.85,
        "总市值": 806791502.0,
        "市盈率": 22.9,
        "成交量": 365387.0,
        "成交额": 4348048.0,
        "振幅": 1.69,
        "换手率": 0.54,
        "symbol": "106.AHH"
    },
    {
        "序号": 2423,
        "name": "Oshkosh Corp",
        "最新价": 99.82,
        "涨跌额": 1.0,
        "涨跌幅": 1.01,
        "开盘价": 98.67,
        "最高价": 100.67,
        "最低价": 98.67,
        "昨收价": 98.82,
        "总市值": 6529575869.0,
        "市盈率": 12.5,
        "成交量": 280769.0,
        "成交额": 28039306.0,
        "振幅": 2.02,
        "换手率": 0.43,
        "symbol": "106.OSK"
    },
    {
        "序号": 2424,
        "name": "NBT Bancorp Inc",
        "最新价": 38.97,
        "涨跌额": 0.39,
        "涨跌幅": 1.01,
        "开盘价": 38.6,
        "最高价": 39.03,
        "最低价": 38.24,
        "昨收价": 38.58,
        "总市值": 1835014216.0,
        "市盈率": 14.74,
        "成交量": 120787.0,
        "成交额": 4699248.0,
        "振幅": 2.05,
        "换手率": 0.26,
        "symbol": "105.NBTB"
    },
    {
        "序号": 2425,
        "name": "卡寇工业",
        "最新价": 306.82,
        "涨跌额": 3.07,
        "涨跌幅": 1.01,
        "开盘价": 302.0,
        "最高价": 307.05,
        "最低价": 301.93,
        "昨收价": 303.75,
        "总市值": 2554387876.0,
        "市盈率": 13.12,
        "成交量": 41173.0,
        "成交额": 12585959.0,
        "振幅": 1.69,
        "换手率": 0.49,
        "symbol": "105.CVCO"
    },
    {
        "序号": 2426,
        "name": "FAT Brands Inc-A",
        "最新价": 6.0,
        "涨跌额": 0.06,
        "涨跌幅": 1.01,
        "开盘价": 5.88,
        "最高价": 6.0,
        "最低价": 5.8,
        "昨收价": 5.94,
        "总市值": 100735938.0,
        "市盈率": -0.75,
        "成交量": 17221.0,
        "成交额": 101612.0,
        "振幅": 3.37,
        "换手率": 0.11,
        "symbol": "105.FAT"
    },
    {
        "序号": 2427,
        "name": "Alignment Healthcare Inc",
        "最新价": 8.0,
        "涨跌额": 0.08,
        "涨跌幅": 1.01,
        "开盘价": 7.91,
        "最高价": 8.15,
        "最低价": 7.88,
        "昨收价": 7.92,
        "总市值": 1512798296.0,
        "市盈率": -9.59,
        "成交量": 513244.0,
        "成交额": 4113118.0,
        "振幅": 3.41,
        "换手率": 0.27,
        "symbol": "105.ALHC"
    },
    {
        "序号": 2428,
        "name": "Ashford Hospitality Trust Inc S",
        "最新价": 10.0,
        "涨跌额": 0.1,
        "涨跌幅": 1.01,
        "开盘价": 10.14,
        "最高价": 10.14,
        "最低价": 9.9,
        "昨收价": 9.9,
        "总市值": 12510440.0,
        "市盈率": null,
        "成交量": 7088.0,
        "成交额": 70930.0,
        "振幅": 2.42,
        "换手率": 0.57,
        "symbol": "106.AHT_F"
    },
    {
        "序号": 2429,
        "name": "MiMedx Group Inc",
        "最新价": 8.01,
        "涨跌额": 0.08,
        "涨跌幅": 1.01,
        "开盘价": 7.89,
        "最高价": 8.18,
        "最低价": 7.81,
        "昨收价": 7.93,
        "总市值": 932176430.0,
        "市盈率": 214.94,
        "成交量": 468732.0,
        "成交额": 3764085.0,
        "振幅": 4.67,
        "换手率": 0.4,
        "symbol": "105.MDXG"
    },
    {
        "序号": 2430,
        "name": "First Trust Nasdaq Artificial I",
        "最新价": 43.06,
        "涨跌额": 0.43,
        "涨跌幅": 1.01,
        "开盘价": 42.31,
        "最高价": 43.13,
        "最低价": 42.31,
        "昨收价": 42.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 395892.0,
        "成交额": 16954150.0,
        "振幅": 1.92,
        "换手率": null,
        "symbol": "105.ROBT"
    },
    {
        "序号": 2431,
        "name": "AOT Growth and Innovation ETF",
        "最新价": 32.06,
        "涨跌额": 0.32,
        "涨跌幅": 1.01,
        "开盘价": 32.06,
        "最高价": 32.06,
        "最低价": 32.06,
        "昨收价": 31.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 70.0,
        "成交额": 2244.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AOTG"
    },
    {
        "序号": 2432,
        "name": "Invitation Homes Inc",
        "最新价": 33.08,
        "涨跌额": 0.33,
        "涨跌幅": 1.01,
        "开盘价": 32.69,
        "最高价": 33.1,
        "最低价": 32.53,
        "昨收价": 32.75,
        "总市值": 20243578546.0,
        "市盈率": 41.27,
        "成交量": 2522239.0,
        "成交额": 83026974.0,
        "振幅": 1.74,
        "换手率": 0.41,
        "symbol": "106.INVH"
    },
    {
        "序号": 2433,
        "name": "First Trust US Equity Opportuni",
        "最新价": 91.39,
        "涨跌额": 0.91,
        "涨跌幅": 1.01,
        "开盘价": 90.57,
        "最高价": 91.79,
        "最低价": 90.54,
        "昨收价": 90.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 42183.0,
        "成交额": 3862489.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "107.FPX"
    },
    {
        "序号": 2434,
        "name": "Franco-Nevada Corp",
        "最新价": 108.49,
        "涨跌额": 1.08,
        "涨跌幅": 1.01,
        "开盘价": 106.46,
        "最高价": 108.86,
        "最低价": 105.75,
        "昨收价": 107.41,
        "总市值": 20843035225.0,
        "市盈率": 30.6,
        "成交量": 843664.0,
        "成交额": 91153362.0,
        "振幅": 2.9,
        "换手率": 0.44,
        "symbol": "106.FNV"
    },
    {
        "序号": 2435,
        "name": "汇丰控股",
        "最新价": 39.21,
        "涨跌额": 0.39,
        "涨跌幅": 1.0,
        "开盘价": 38.95,
        "最高价": 39.23,
        "最低价": 38.95,
        "昨收价": 38.82,
        "总市值": 151570942704.0,
        "市盈率": 5.31,
        "成交量": 1911039.0,
        "成交额": 74779774.0,
        "振幅": 0.72,
        "换手率": 0.05,
        "symbol": "106.HSBC"
    },
    {
        "序号": 2436,
        "name": "iShares Commodity Curve Carry S",
        "最新价": 21.12,
        "涨跌额": 0.21,
        "涨跌幅": 1.0,
        "开盘价": 21.27,
        "最高价": 21.27,
        "最低价": 21.04,
        "昨收价": 20.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 7145.0,
        "成交额": 150974.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.CCRV"
    },
    {
        "序号": 2437,
        "name": "三井住友金融",
        "最新价": 10.06,
        "涨跌额": 0.1,
        "涨跌幅": 1.0,
        "开盘价": 9.97,
        "最高价": 10.06,
        "最低价": 9.95,
        "昨收价": 9.96,
        "总市值": 67103905651.0,
        "市盈率": 9.92,
        "成交量": 1545554.0,
        "成交额": 15482574.0,
        "振幅": 1.1,
        "换手率": 0.02,
        "symbol": "106.SMFG"
    },
    {
        "序号": 2438,
        "name": "嘉年华邮轮(US)",
        "最新价": 18.11,
        "涨跌额": 0.18,
        "涨跌幅": 1.0,
        "开盘价": 17.92,
        "最高价": 18.14,
        "最低价": 17.84,
        "昨收价": 17.93,
        "总市值": 20273153097.0,
        "市盈率": -12.48,
        "成交量": 32726959.0,
        "成交额": 589620720.0,
        "振幅": 1.67,
        "换手率": 2.92,
        "symbol": "106.CCL"
    },
    {
        "序号": 2439,
        "name": "滨特尔",
        "最新价": 67.41,
        "涨跌额": 0.67,
        "涨跌幅": 1.0,
        "开盘价": 66.83,
        "最高价": 67.59,
        "最低价": 66.7,
        "昨收价": 66.74,
        "总市值": 11142802826.0,
        "市盈率": 21.86,
        "成交量": 3268835.0,
        "成交额": 219960857.0,
        "振幅": 1.33,
        "换手率": 1.98,
        "symbol": "106.PNR"
    },
    {
        "序号": 2440,
        "name": "铂金ETF-ETFS",
        "最新价": 84.52,
        "涨跌额": 0.84,
        "涨跌幅": 1.0,
        "开盘价": 84.14,
        "最高价": 85.05,
        "最低价": 84.12,
        "昨收价": 83.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 52973.0,
        "成交额": 4475009.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.PPLT"
    },
    {
        "序号": 2441,
        "name": "Global X Cloud Computing ETF",
        "最新价": 21.14,
        "涨跌额": 0.21,
        "涨跌幅": 1.0,
        "开盘价": 20.82,
        "最高价": 21.17,
        "最低价": 20.81,
        "昨收价": 20.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 193515.0,
        "成交额": 4081020.0,
        "振幅": 1.72,
        "换手率": null,
        "symbol": "105.CLOU"
    },
    {
        "序号": 2442,
        "name": "林肯电气",
        "最新价": 206.8,
        "涨跌额": 2.05,
        "涨跌幅": 1.0,
        "开盘价": 204.57,
        "最高价": 207.45,
        "最低价": 204.41,
        "昨收价": 204.75,
        "总市值": 11828905405.0,
        "市盈率": 23.76,
        "成交量": 685030.0,
        "成交额": 141604939.0,
        "振幅": 1.48,
        "换手率": 1.2,
        "symbol": "105.LECO"
    },
    {
        "序号": 2443,
        "name": "TIM SA ADR",
        "最新价": 18.16,
        "涨跌额": 0.18,
        "涨跌幅": 1.0,
        "开盘价": 17.97,
        "最高价": 18.23,
        "最低价": 17.96,
        "昨收价": 17.98,
        "总市值": 8792361574.0,
        "市盈率": 18.84,
        "成交量": 340643.0,
        "成交额": 6171834.0,
        "振幅": 1.5,
        "换手率": 0.07,
        "symbol": "106.TIMB"
    },
    {
        "序号": 2444,
        "name": "PSQ Holdings Inc Wt",
        "最新价": 1.01,
        "涨跌额": 0.01,
        "涨跌幅": 1.0,
        "开盘价": 1.0,
        "最高价": 1.04,
        "最低价": 0.99,
        "昨收价": 1.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 21364.0,
        "成交额": 21478.0,
        "振幅": 5.0,
        "换手率": null,
        "symbol": "106.PSQH_WS"
    },
    {
        "序号": 2445,
        "name": "Global Business Travel Group In",
        "最新价": 6.06,
        "涨跌额": 0.06,
        "涨跌幅": 1.0,
        "开盘价": 6.0,
        "最高价": 6.1,
        "最低价": 5.97,
        "昨收价": 6.0,
        "总市值": 2830158271.0,
        "市盈率": -67.38,
        "成交量": 92407.0,
        "成交额": 558952.0,
        "振幅": 2.17,
        "换手率": 0.02,
        "symbol": "106.GBTG"
    },
    {
        "序号": 2446,
        "name": "Aclaris Therapeutics Inc",
        "最新价": 1.01,
        "涨跌额": 0.01,
        "涨跌幅": 1.0,
        "开盘价": 1.01,
        "最高价": 1.04,
        "最低价": 0.98,
        "昨收价": 1.0,
        "总市值": 71544556.0,
        "市盈率": -0.62,
        "成交量": 2095959.0,
        "成交额": 2105308.0,
        "振幅": 6.0,
        "换手率": 2.96,
        "symbol": "105.ACRS"
    },
    {
        "序号": 2447,
        "name": "新加坡ETF-iShares MSCI",
        "最新价": 18.19,
        "涨跌额": 0.18,
        "涨跌幅": 1.0,
        "开盘价": 18.11,
        "最高价": 18.21,
        "最低价": 18.08,
        "昨收价": 18.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 246897.0,
        "成交额": 4482435.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.EWS"
    },
    {
        "序号": 2448,
        "name": "iShares Currency Hedged MSCI Eu",
        "最新价": 33.37,
        "涨跌额": 0.33,
        "涨跌幅": 1.0,
        "开盘价": 33.26,
        "最高价": 33.37,
        "最低价": 33.26,
        "昨收价": 33.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 11974.0,
        "成交额": 398838.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.HEZU"
    },
    {
        "序号": 2449,
        "name": "三倍做多道指ETF-ProShares",
        "最新价": 66.77,
        "涨跌额": 0.66,
        "涨跌幅": 1.0,
        "开盘价": 66.05,
        "最高价": 67.11,
        "最低价": 65.85,
        "昨收价": 66.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 3378342.0,
        "成交额": 224708515.0,
        "振幅": 1.91,
        "换手率": null,
        "symbol": "107.UDOW"
    },
    {
        "序号": 2450,
        "name": "WisdomTree Artificial Intellige",
        "最新价": 19.24,
        "涨跌额": 0.19,
        "涨跌幅": 1.0,
        "开盘价": 18.99,
        "最高价": 19.28,
        "最低价": 18.94,
        "昨收价": 19.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 104758.0,
        "成交额": 2006118.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "107.WTAI"
    },
    {
        "序号": 2451,
        "name": "DoubleLine Opportunistic Credit",
        "最新价": 15.19,
        "涨跌额": 0.15,
        "涨跌幅": 1.0,
        "开盘价": 15.05,
        "最高价": 15.34,
        "最低价": 14.97,
        "昨收价": 15.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 104582.0,
        "成交额": 1581159.0,
        "振幅": 2.46,
        "换手率": null,
        "symbol": "106.DBL"
    },
    {
        "序号": 2452,
        "name": "Great Elm Group Inc",
        "最新价": 2.03,
        "涨跌额": 0.02,
        "涨跌幅": 1.0,
        "开盘价": 2.05,
        "最高价": 2.05,
        "最低价": 1.98,
        "昨收价": 2.01,
        "总市值": 63284448.0,
        "市盈率": 1.63,
        "成交量": 2147.0,
        "成交额": 4351.0,
        "振幅": 3.48,
        "换手率": 0.01,
        "symbol": "105.GEG"
    },
    {
        "序号": 2453,
        "name": "Ovintiv Inc",
        "最新价": 41.63,
        "涨跌额": 0.41,
        "涨跌幅": 0.99,
        "开盘价": 41.58,
        "最高价": 41.82,
        "最低价": 41.34,
        "昨收价": 41.22,
        "总市值": 11360927453.0,
        "市盈率": 4.43,
        "成交量": 2462054.0,
        "成交额": 102448464.0,
        "振幅": 1.16,
        "换手率": 0.9,
        "symbol": "106.OVV"
    },
    {
        "序号": 2454,
        "name": "Xtrackers MSCI Eurozone Hedged ",
        "最新价": 41.67,
        "涨跌额": 0.41,
        "涨跌幅": 0.99,
        "开盘价": 41.51,
        "最高价": 41.67,
        "最低价": 41.51,
        "昨收价": 41.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 19565.0,
        "成交额": 813487.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.DBEZ"
    },
    {
        "序号": 2455,
        "name": "Tarsus Pharmaceuticals Inc",
        "最新价": 17.29,
        "涨跌额": 0.17,
        "涨跌幅": 0.99,
        "开盘价": 16.99,
        "最高价": 17.61,
        "最低价": 16.9,
        "昨收价": 17.12,
        "总市值": 572378741.0,
        "市盈率": -5.32,
        "成交量": 418577.0,
        "成交额": 7225125.0,
        "振幅": 4.15,
        "换手率": 1.26,
        "symbol": "105.TARS"
    },
    {
        "序号": 2456,
        "name": "Integrated Wellness Acquisition",
        "最新价": 11.19,
        "涨跌额": 0.11,
        "涨跌幅": 0.99,
        "开盘价": 11.19,
        "最高价": 11.19,
        "最低价": 11.19,
        "昨收价": 11.08,
        "总市值": 92499584.0,
        "市盈率": 50.27,
        "成交量": 106.0,
        "成交额": 1186.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.WEL"
    },
    {
        "序号": 2457,
        "name": "大都会人寿",
        "最新价": 64.09,
        "涨跌额": 0.63,
        "涨跌幅": 0.99,
        "开盘价": 63.48,
        "最高价": 64.43,
        "最低价": 63.37,
        "昨收价": 63.46,
        "总市值": 47438791777.0,
        "市盈率": -242.03,
        "成交量": 3317506.0,
        "成交额": 212350174.0,
        "振幅": 1.67,
        "换手率": 0.45,
        "symbol": "106.MET"
    },
    {
        "序号": 2458,
        "name": "帕米亚盆地皇室信托",
        "最新价": 15.26,
        "涨跌额": 0.15,
        "涨跌幅": 0.99,
        "开盘价": 15.15,
        "最高价": 15.57,
        "最低价": 15.14,
        "昨收价": 15.11,
        "总市值": 711250227.0,
        "市盈率": 24.56,
        "成交量": 111747.0,
        "成交额": 1708029.0,
        "振幅": 2.85,
        "换手率": 0.24,
        "symbol": "106.PBT"
    },
    {
        "序号": 2459,
        "name": "Invesco S&P SmallCap Value with",
        "最新价": 50.87,
        "涨跌额": 0.5,
        "涨跌幅": 0.99,
        "开盘价": 50.38,
        "最高价": 50.99,
        "最低价": 50.37,
        "昨收价": 50.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 41823.0,
        "成交额": 2120478.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.XSVM"
    },
    {
        "序号": 2460,
        "name": "Corebridge Financial Inc",
        "最新价": 20.35,
        "涨跌额": 0.2,
        "涨跌幅": 0.99,
        "开盘价": 20.23,
        "最高价": 20.53,
        "最低价": 20.16,
        "昨收价": 20.15,
        "总市值": 12812275202.0,
        "市盈率": 5.83,
        "成交量": 3574099.0,
        "成交额": 72811322.0,
        "振幅": 1.84,
        "换手率": 0.57,
        "symbol": "106.CRBG"
    },
    {
        "序号": 2461,
        "name": "StoneX Group Inc",
        "最新价": 64.15,
        "涨跌额": 0.63,
        "涨跌幅": 0.99,
        "开盘价": 63.31,
        "最高价": 64.8,
        "最低价": 63.31,
        "昨收价": 63.52,
        "总市值": 2008217578.0,
        "市盈率": 8.42,
        "成交量": 241152.0,
        "成交额": 15442382.0,
        "振幅": 2.35,
        "换手率": 0.77,
        "symbol": "105.SNEX"
    },
    {
        "序号": 2462,
        "name": "Scorpio Tankers Inc",
        "最新价": 53.99,
        "涨跌额": 0.53,
        "涨跌幅": 0.99,
        "开盘价": 54.0,
        "最高价": 54.6,
        "最低价": 53.33,
        "昨收价": 53.46,
        "总市值": 2867288232.0,
        "市盈率": 4.15,
        "成交量": 469562.0,
        "成交额": 25407942.0,
        "振幅": 2.38,
        "换手率": 0.88,
        "symbol": "106.STNG"
    },
    {
        "序号": 2463,
        "name": "CrowdStrike Holdings Inc-A",
        "最新价": 242.55,
        "涨跌额": 2.38,
        "涨跌幅": 0.99,
        "开盘价": 238.34,
        "最高价": 243.49,
        "最低价": 237.63,
        "昨收价": 240.17,
        "总市值": 58247123180.0,
        "市盈率": -4914.13,
        "成交量": 1886724.0,
        "成交额": 455560176.0,
        "振幅": 2.44,
        "换手率": 0.79,
        "symbol": "105.CRWD"
    },
    {
        "序号": 2464,
        "name": "正康国际",
        "最新价": 1.02,
        "涨跌额": 0.01,
        "涨跌幅": 0.99,
        "开盘价": 1.02,
        "最高价": 1.04,
        "最低价": 0.95,
        "昨收价": 1.01,
        "总市值": 31000799.0,
        "市盈率": -5.06,
        "成交量": 68771.0,
        "成交额": 69334.0,
        "振幅": 8.91,
        "换手率": 0.23,
        "symbol": "105.ZKIN"
    },
    {
        "序号": 2465,
        "name": "工业物流房地产信托",
        "最新价": 4.08,
        "涨跌额": 0.04,
        "涨跌幅": 0.99,
        "开盘价": 4.04,
        "最高价": 4.09,
        "最低价": 3.86,
        "昨收价": 4.04,
        "总市值": 268642798.0,
        "市盈率": -2.49,
        "成交量": 594562.0,
        "成交额": 2354057.0,
        "振幅": 5.69,
        "换手率": 0.9,
        "symbol": "105.ILPT"
    },
    {
        "序号": 2466,
        "name": "赫兹租车(权证)",
        "最新价": 5.1,
        "涨跌额": 0.05,
        "涨跌幅": 0.99,
        "开盘价": 4.97,
        "最高价": 5.14,
        "最低价": 4.96,
        "昨收价": 5.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 302069.0,
        "成交额": 1530012.0,
        "振幅": 3.56,
        "换手率": null,
        "symbol": "105.HTZWW"
    },
    {
        "序号": 2467,
        "name": "烯石电车新材料",
        "最新价": 1.02,
        "涨跌额": 0.01,
        "涨跌幅": 0.99,
        "开盘价": 0.99,
        "最高价": 1.07,
        "最低价": 0.98,
        "昨收价": 1.01,
        "总市值": 40217314.0,
        "市盈率": -4.18,
        "成交量": 29620.0,
        "成交额": 30602.0,
        "振幅": 8.91,
        "换手率": 0.08,
        "symbol": "107.GRFX"
    },
    {
        "序号": 2468,
        "name": "嘉银通",
        "最新价": 90.82,
        "涨跌额": 0.89,
        "涨跌幅": 0.99,
        "开盘价": 89.73,
        "最高价": 90.94,
        "最低价": 89.46,
        "昨收价": 89.93,
        "总市值": 4188748727.0,
        "市盈率": 15.06,
        "成交量": 483871.0,
        "成交额": 43856158.0,
        "振幅": 1.65,
        "换手率": 1.05,
        "symbol": "105.EEFT"
    },
    {
        "序号": 2469,
        "name": "根科船务贸易",
        "最新价": 14.31,
        "涨跌额": 0.14,
        "涨跌幅": 0.99,
        "开盘价": 14.25,
        "最高价": 14.47,
        "最低价": 14.23,
        "昨收价": 14.17,
        "总市值": 608728296.0,
        "市盈率": 56.01,
        "成交量": 459567.0,
        "成交额": 6588386.0,
        "振幅": 1.69,
        "换手率": 1.08,
        "symbol": "106.GNK"
    },
    {
        "序号": 2470,
        "name": "凯登纸业",
        "最新价": 267.96,
        "涨跌额": 2.62,
        "涨跌幅": 0.99,
        "开盘价": 264.37,
        "最高价": 269.0,
        "最低价": 264.37,
        "昨收价": 265.34,
        "总市值": 3137033444.0,
        "市盈率": 27.34,
        "成交量": 50259.0,
        "成交额": 13463452.0,
        "振幅": 1.74,
        "换手率": 0.43,
        "symbol": "106.KAI"
    },
    {
        "序号": 2471,
        "name": "ProShares Long Online\/Short Sto",
        "最新价": 36.84,
        "涨跌额": 0.36,
        "涨跌幅": 0.99,
        "开盘价": 36.8,
        "最高价": 36.84,
        "最低价": 36.53,
        "昨收价": 36.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 4627.0,
        "成交额": 170161.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.CLIX"
    },
    {
        "序号": 2472,
        "name": "AdvisorShares Gerber Kawasaki E",
        "最新价": 17.4,
        "涨跌额": 0.17,
        "涨跌幅": 0.99,
        "开盘价": 17.19,
        "最高价": 17.41,
        "最低价": 17.19,
        "昨收价": 17.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 2670.0,
        "成交额": 46363.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "107.GK"
    },
    {
        "序号": 2473,
        "name": "Rush Enterprises Inc-B",
        "最新价": 45.04,
        "涨跌额": 0.44,
        "涨跌幅": 0.99,
        "开盘价": 44.99,
        "最高价": 45.1,
        "最低价": 44.51,
        "昨收价": 44.6,
        "总市值": 3580245499.0,
        "市盈率": 9.75,
        "成交量": 10860.0,
        "成交额": 488641.0,
        "振幅": 1.32,
        "换手率": 0.06,
        "symbol": "105.RUSHB"
    },
    {
        "序号": 2474,
        "name": "做空20年+国债ETF-ProShares",
        "最新价": 23.55,
        "涨跌额": 0.23,
        "涨跌幅": 0.99,
        "开盘价": 23.6,
        "最高价": 23.69,
        "最低价": 23.5,
        "昨收价": 23.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 800317.0,
        "成交额": 18879524.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.TBF"
    },
    {
        "序号": 2475,
        "name": "Invesco S&P SmallCap Momentum E",
        "最新价": 52.22,
        "涨跌额": 0.51,
        "涨跌幅": 0.99,
        "开盘价": 52.3,
        "最高价": 52.35,
        "最低价": 51.81,
        "昨收价": 51.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 95275.0,
        "成交额": 4969067.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "107.XSMO"
    },
    {
        "序号": 2476,
        "name": "Old Second Bancorp Inc",
        "最新价": 15.36,
        "涨跌额": 0.15,
        "涨跌幅": 0.99,
        "开盘价": 15.27,
        "最高价": 15.43,
        "最低价": 15.16,
        "昨收价": 15.21,
        "总市值": 686560005.0,
        "市盈率": 7.07,
        "成交量": 127298.0,
        "成交额": 1954238.0,
        "振幅": 1.78,
        "换手率": 0.28,
        "symbol": "105.OSBC"
    },
    {
        "序号": 2477,
        "name": "山脉资源",
        "最新价": 29.7,
        "涨跌额": 0.29,
        "涨跌幅": 0.99,
        "开盘价": 29.67,
        "最高价": 30.04,
        "最低价": 29.35,
        "昨收价": 29.41,
        "总市值": 7166754995.0,
        "市盈率": 5.21,
        "成交量": 2658617.0,
        "成交额": 78997434.0,
        "振幅": 2.35,
        "换手率": 1.1,
        "symbol": "106.RRC"
    },
    {
        "序号": 2478,
        "name": "Howmet Aerospace Inc",
        "最新价": 53.26,
        "涨跌额": 0.52,
        "涨跌幅": 0.99,
        "开盘价": 52.72,
        "最高价": 53.36,
        "最低价": 52.72,
        "昨收价": 52.74,
        "总市值": 21929504294.0,
        "市盈率": 34.26,
        "成交量": 1634764.0,
        "成交额": 86883293.0,
        "振幅": 1.21,
        "换手率": 0.4,
        "symbol": "106.HWM"
    },
    {
        "序号": 2479,
        "name": "Endeavor Group Holdings Inc-A",
        "最新价": 24.6,
        "涨跌额": 0.24,
        "涨跌幅": 0.99,
        "开盘价": 24.42,
        "最高价": 24.85,
        "最低价": 24.32,
        "昨收价": 24.36,
        "总市值": 17070808970.0,
        "市盈率": 125.69,
        "成交量": 3539457.0,
        "成交额": 86939691.0,
        "振幅": 2.18,
        "换手率": 0.51,
        "symbol": "106.EDR"
    },
    {
        "序号": 2480,
        "name": "WisdomTree International Hedged",
        "最新价": 41.01,
        "涨跌额": 0.4,
        "涨跌幅": 0.98,
        "开盘价": 40.81,
        "最高价": 41.01,
        "最低价": 40.81,
        "昨收价": 40.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 171346.0,
        "成交额": 7015217.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.IHDG"
    },
    {
        "序号": 2481,
        "name": "Nu Holdings Ltd-A",
        "最新价": 8.22,
        "涨跌额": 0.08,
        "涨跌幅": 0.98,
        "开盘价": 8.11,
        "最高价": 8.27,
        "最低价": 8.11,
        "昨收价": 8.14,
        "总市值": 39085357734.0,
        "市盈率": 105.06,
        "成交量": 16025488.0,
        "成交额": 131473523.0,
        "振幅": 1.97,
        "换手率": 0.34,
        "symbol": "106.NU"
    },
    {
        "序号": 2482,
        "name": "联拓生物",
        "最新价": 4.11,
        "涨跌额": 0.04,
        "涨跌幅": 0.98,
        "开盘价": 4.08,
        "最高价": 4.23,
        "最低价": 4.08,
        "昨收价": 4.07,
        "总市值": 444137442.0,
        "市盈率": -5.05,
        "成交量": 196502.0,
        "成交额": 813529.0,
        "振幅": 3.69,
        "换手率": 0.18,
        "symbol": "105.LIAN"
    },
    {
        "序号": 2483,
        "name": "Calamos Convertible and High In",
        "最新价": 11.31,
        "涨跌额": 0.11,
        "涨跌幅": 0.98,
        "开盘价": 11.23,
        "最高价": 11.36,
        "最低价": 11.22,
        "昨收价": 11.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 91031.0,
        "成交额": 1025463.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "105.CHY"
    },
    {
        "序号": 2484,
        "name": "Liberty Media Corp Liberty Live",
        "最新价": 33.97,
        "涨跌额": 0.33,
        "涨跌幅": 0.98,
        "开盘价": 33.64,
        "最高价": 34.27,
        "最低价": 33.42,
        "昨收价": 33.64,
        "总市值": 24273318328.0,
        "市盈率": 19.98,
        "成交量": 467969.0,
        "成交额": 15870960.0,
        "振幅": 2.53,
        "换手率": 0.74,
        "symbol": "105.LLYVK"
    },
    {
        "序号": 2485,
        "name": "第一金融银行",
        "最新价": 22.65,
        "涨跌额": 0.22,
        "涨跌幅": 0.98,
        "开盘价": 22.44,
        "最高价": 22.82,
        "最低价": 22.21,
        "昨收价": 22.43,
        "总市值": 2154506211.0,
        "市盈率": 8.03,
        "成交量": 362309.0,
        "成交额": 8214826.0,
        "振幅": 2.72,
        "换手率": 0.38,
        "symbol": "105.FFBC"
    },
    {
        "序号": 2486,
        "name": "SPDR S&P Kensho Intelligent Str",
        "最新价": 32.96,
        "涨跌额": 0.32,
        "涨跌幅": 0.98,
        "开盘价": 32.58,
        "最高价": 32.96,
        "最低价": 32.58,
        "昨收价": 32.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 236.0,
        "成交额": 7688.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.SIMS"
    },
    {
        "序号": 2487,
        "name": "IceCure Medical Ltd",
        "最新价": 1.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.98,
        "开盘价": 1.02,
        "最高价": 1.05,
        "最低价": 1.0,
        "昨收价": 1.02,
        "总市值": 47011450.0,
        "市盈率": -3.0,
        "成交量": 245803.0,
        "成交额": 252712.0,
        "振幅": 4.9,
        "换手率": 0.54,
        "symbol": "105.ICCM"
    },
    {
        "序号": 2488,
        "name": "Freshpet Inc",
        "最新价": 76.25,
        "涨跌额": 0.74,
        "涨跌幅": 0.98,
        "开盘价": 76.45,
        "最高价": 77.13,
        "最低价": 75.64,
        "昨收价": 75.51,
        "总市值": 3678444723.0,
        "市盈率": -70.98,
        "成交量": 800283.0,
        "成交额": 61038931.0,
        "振幅": 1.97,
        "换手率": 1.66,
        "symbol": "105.FRPT"
    },
    {
        "序号": 2489,
        "name": "SoFi Social 50 ETF",
        "最新价": 28.87,
        "涨跌额": 0.28,
        "涨跌幅": 0.98,
        "开盘价": 28.48,
        "最高价": 28.87,
        "最低价": 28.48,
        "昨收价": 28.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 1905.0,
        "成交额": 54654.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.SFYF"
    },
    {
        "序号": 2490,
        "name": "美国银行",
        "最新价": 30.96,
        "涨跌额": 0.3,
        "涨跌幅": 0.98,
        "开盘价": 30.67,
        "最高价": 31.14,
        "最低价": 30.61,
        "昨收价": 30.66,
        "总市值": 245009143153.0,
        "市盈率": 8.03,
        "成交量": 34794305.0,
        "成交额": 1077354944.0,
        "振幅": 1.73,
        "换手率": 0.44,
        "symbol": "106.BAC"
    },
    {
        "序号": 2491,
        "name": "Gabelli Financial Services Oppo",
        "最新价": 32.01,
        "涨跌额": 0.31,
        "涨跌幅": 0.98,
        "开盘价": 31.98,
        "最高价": 32.01,
        "最低价": 31.86,
        "昨收价": 31.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 1874.0,
        "成交额": 59745.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.GABF"
    },
    {
        "序号": 2492,
        "name": "Hess Midstream LP-A",
        "最新价": 31.0,
        "涨跌额": 0.3,
        "涨跌幅": 0.98,
        "开盘价": 30.86,
        "最高价": 31.16,
        "最低价": 30.6,
        "昨收价": 30.7,
        "总市值": 7119780571.0,
        "市盈率": 69.19,
        "成交量": 1079907.0,
        "成交额": 33463101.0,
        "振幅": 1.82,
        "换手率": 0.47,
        "symbol": "106.HESM"
    },
    {
        "序号": 2493,
        "name": "Thermon Group Holdings Inc",
        "最新价": 31.02,
        "涨跌额": 0.3,
        "涨跌幅": 0.98,
        "开盘价": 30.64,
        "最高价": 31.55,
        "最低价": 30.64,
        "昨收价": 30.72,
        "总市值": 1045273495.0,
        "市盈率": 25.01,
        "成交量": 104042.0,
        "成交额": 3231129.0,
        "振幅": 2.96,
        "换手率": 0.31,
        "symbol": "106.THR"
    },
    {
        "序号": 2494,
        "name": "二倍做多基础材料ETF-ProShares",
        "最新价": 22.79,
        "涨跌额": 0.22,
        "涨跌幅": 0.97,
        "开盘价": 22.84,
        "最高价": 22.98,
        "最低价": 22.67,
        "昨收价": 22.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 20006.0,
        "成交额": 456741.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.UYM"
    },
    {
        "序号": 2495,
        "name": "WhiteHorse Finance Inc",
        "最新价": 12.44,
        "涨跌额": 0.12,
        "涨跌幅": 0.97,
        "开盘价": 12.29,
        "最高价": 12.46,
        "最低价": 12.29,
        "昨收价": 12.32,
        "总市值": 289144015.0,
        "市盈率": 18.34,
        "成交量": 25240.0,
        "成交额": 312617.0,
        "振幅": 1.38,
        "换手率": 0.11,
        "symbol": "105.WHF"
    },
    {
        "序号": 2496,
        "name": "Arlington Asset Investment Corp",
        "最新价": 23.88,
        "涨跌额": 0.23,
        "涨跌幅": 0.97,
        "开盘价": 23.8,
        "最高价": 24.49,
        "最低价": 23.57,
        "昨收价": 23.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 768.0,
        "成交额": 18382.0,
        "振幅": 3.89,
        "换手率": null,
        "symbol": "106.AAIN"
    },
    {
        "序号": 2497,
        "name": "EA Bridgeway Omni Small-Cap Val",
        "最新价": 19.75,
        "涨跌额": 0.19,
        "涨跌幅": 0.97,
        "开盘价": 19.74,
        "最高价": 19.78,
        "最低价": 19.56,
        "昨收价": 19.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 288071.0,
        "成交额": 5669174.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "105.BSVO"
    },
    {
        "序号": 2498,
        "name": "ProShares Ultra Communication S",
        "最新价": 47.82,
        "涨跌额": 0.46,
        "涨跌幅": 0.97,
        "开盘价": 47.63,
        "最高价": 47.82,
        "最低价": 47.63,
        "昨收价": 47.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 633.0,
        "成交额": 30149.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.LTL"
    },
    {
        "序号": 2499,
        "name": "联合银行",
        "最新价": 35.36,
        "涨跌额": 0.34,
        "涨跌幅": 0.97,
        "开盘价": 35.16,
        "最高价": 35.53,
        "最低价": 34.83,
        "昨收价": 35.02,
        "总市值": 4771231410.0,
        "市盈率": 12.34,
        "成交量": 668674.0,
        "成交额": 23616170.0,
        "振幅": 2.0,
        "换手率": 0.5,
        "symbol": "105.UBSI"
    },
    {
        "序号": 2500,
        "name": "Lipella Pharmaceuticals Inc",
        "最新价": 1.04,
        "涨跌额": 0.01,
        "涨跌幅": 0.97,
        "开盘价": 1.0,
        "最高价": 1.09,
        "最低价": 1.0,
        "昨收价": 1.03,
        "总市值": 6036103.0,
        "市盈率": -1.41,
        "成交量": 8224.0,
        "成交额": 8634.0,
        "振幅": 8.74,
        "换手率": 0.14,
        "symbol": "105.LIPO"
    },
    {
        "序号": 2501,
        "name": "拉扎德银行",
        "最新价": 13.52,
        "涨跌额": 0.13,
        "涨跌幅": 0.97,
        "开盘价": 13.42,
        "最高价": 13.62,
        "最低价": 13.33,
        "昨收价": 13.39,
        "总市值": 879209318.0,
        "市盈率": 8.95,
        "成交量": 155956.0,
        "成交额": 2108033.0,
        "振幅": 2.17,
        "换手率": 0.24,
        "symbol": "105.LBAI"
    },
    {
        "序号": 2502,
        "name": "KWESST Micro Systems Inc",
        "最新价": 1.04,
        "涨跌额": 0.01,
        "涨跌幅": 0.97,
        "开盘价": 1.1,
        "最高价": 1.1,
        "最低价": 1.02,
        "昨收价": 1.03,
        "总市值": 5841453.0,
        "市盈率": -0.88,
        "成交量": 6243.0,
        "成交额": 6586.0,
        "振幅": 7.77,
        "换手率": 0.11,
        "symbol": "105.KWE"
    },
    {
        "序号": 2503,
        "name": "Otis Worldwide Corp",
        "最新价": 87.5,
        "涨跌额": 0.84,
        "涨跌幅": 0.97,
        "开盘价": 86.7,
        "最高价": 87.77,
        "最低价": 86.7,
        "昨收价": 86.66,
        "总市值": 35810147013.0,
        "市盈率": 25.95,
        "成交量": 3062925.0,
        "成交额": 267960468.0,
        "振幅": 1.23,
        "换手率": 0.75,
        "symbol": "106.OTIS"
    },
    {
        "序号": 2504,
        "name": "The Gabelli Equity Trust",
        "最新价": 5.21,
        "涨跌额": 0.05,
        "涨跌幅": 0.97,
        "开盘价": 5.18,
        "最高价": 5.21,
        "最低价": 5.17,
        "昨收价": 5.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 501627.0,
        "成交额": 2605544.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "106.GAB"
    },
    {
        "序号": 2505,
        "name": "Monroe Capital Corp",
        "最新价": 7.31,
        "涨跌额": 0.07,
        "涨跌幅": 0.97,
        "开盘价": 7.28,
        "最高价": 7.38,
        "最低价": 7.25,
        "昨收价": 7.24,
        "总市值": 158380945.0,
        "市盈率": 48.48,
        "成交量": 52734.0,
        "成交额": 385690.0,
        "振幅": 1.8,
        "换手率": 0.24,
        "symbol": "105.MRCC"
    },
    {
        "序号": 2506,
        "name": "ETRACS 2x Leveraged US Growth F",
        "最新价": 28.23,
        "涨跌额": 0.27,
        "涨跌幅": 0.97,
        "开盘价": 28.23,
        "最高价": 28.23,
        "最低价": 28.23,
        "昨收价": 27.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 83.0,
        "成交额": 2342.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IWFL"
    },
    {
        "序号": 2507,
        "name": "AppFolio Inc-A",
        "最新价": 182.99,
        "涨跌额": 1.75,
        "涨跌幅": 0.97,
        "开盘价": 181.0,
        "最高价": 184.78,
        "最低价": 180.0,
        "昨收价": 181.24,
        "总市值": 6544108143.0,
        "市盈率": -138.45,
        "成交量": 153646.0,
        "成交额": 28141283.0,
        "振幅": 2.64,
        "换手率": 0.43,
        "symbol": "105.APPF"
    },
    {
        "序号": 2508,
        "name": "BJ批发俱乐部",
        "最新价": 65.92,
        "涨跌额": 0.63,
        "涨跌幅": 0.96,
        "开盘价": 65.08,
        "最高价": 66.11,
        "最低价": 65.06,
        "昨收价": 65.29,
        "总市值": 8792475916.0,
        "市盈率": 17.32,
        "成交量": 1194587.0,
        "成交额": 78614805.0,
        "振幅": 1.61,
        "换手率": 0.9,
        "symbol": "106.BJ"
    },
    {
        "序号": 2509,
        "name": "Quanex Building Products Corp",
        "最新价": 32.46,
        "涨跌额": 0.31,
        "涨跌幅": 0.96,
        "开盘价": 32.2,
        "最高价": 32.68,
        "最低价": 32.2,
        "昨收价": 32.15,
        "总市值": 1070736337.0,
        "市盈率": 13.42,
        "成交量": 127840.0,
        "成交额": 4149850.0,
        "振幅": 1.49,
        "换手率": 0.39,
        "symbol": "106.NX"
    },
    {
        "序号": 2510,
        "name": "Whitestone REIT",
        "最新价": 11.54,
        "涨跌额": 0.11,
        "涨跌幅": 0.96,
        "开盘价": 11.38,
        "最高价": 11.54,
        "最低价": 11.34,
        "昨收价": 11.43,
        "总市值": 572216797.0,
        "市盈率": 15.23,
        "成交量": 272333.0,
        "成交额": 3123853.0,
        "振幅": 1.75,
        "换手率": 0.55,
        "symbol": "106.WSR"
    },
    {
        "序号": 2511,
        "name": "马斯可木业",
        "最新价": 64.0,
        "涨跌额": 0.61,
        "涨跌幅": 0.96,
        "开盘价": 63.53,
        "最高价": 64.18,
        "最低价": 63.45,
        "昨收价": 63.39,
        "总市值": 14368058304.0,
        "市盈率": 17.27,
        "成交量": 1094274.0,
        "成交额": 69958560.0,
        "振幅": 1.15,
        "换手率": 0.49,
        "symbol": "106.MAS"
    },
    {
        "序号": 2512,
        "name": "Macquarie Energy Transition ETF",
        "最新价": 25.19,
        "涨跌额": 0.24,
        "涨跌幅": 0.96,
        "开盘价": 25.19,
        "最高价": 25.19,
        "最低价": 25.19,
        "昨收价": 24.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 251.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PWER"
    },
    {
        "序号": 2513,
        "name": "ProShares Ultra FTSE Europe",
        "最新价": 54.6,
        "涨跌额": 0.52,
        "涨跌幅": 0.96,
        "开盘价": 54.53,
        "最高价": 54.6,
        "最低价": 54.53,
        "昨收价": 54.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 633.0,
        "成交额": 34518.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.UPV"
    },
    {
        "序号": 2514,
        "name": "Medalist Diversified REIT Inc",
        "最新价": 5.25,
        "涨跌额": 0.05,
        "涨跌幅": 0.96,
        "开盘价": 5.25,
        "最高价": 5.26,
        "最低价": 5.25,
        "昨收价": 5.2,
        "总市值": 11648753.0,
        "市盈率": -2.3,
        "成交量": 1441.0,
        "成交额": 7566.0,
        "振幅": 0.19,
        "换手率": 0.06,
        "symbol": "105.MDRR"
    },
    {
        "序号": 2515,
        "name": "FSD Pharma Inc-B",
        "最新价": 1.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.96,
        "开盘价": 1.03,
        "最高价": 1.07,
        "最低价": 1.03,
        "昨收价": 1.04,
        "总市值": 41326806.0,
        "市盈率": -1.82,
        "成交量": 50844.0,
        "成交额": 53443.0,
        "振幅": 3.85,
        "换手率": 0.13,
        "symbol": "105.HUGE"
    },
    {
        "序号": 2516,
        "name": "燃料技术",
        "最新价": 1.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.96,
        "开盘价": 1.03,
        "最高价": 1.05,
        "最低价": 1.03,
        "昨收价": 1.04,
        "总市值": 31904562.0,
        "市盈率": -22.77,
        "成交量": 22870.0,
        "成交额": 23757.0,
        "振幅": 1.92,
        "换手率": 0.08,
        "symbol": "105.FTEK"
    },
    {
        "序号": 2517,
        "name": "Cronos Group Inc",
        "最新价": 2.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.96,
        "开盘价": 2.05,
        "最高价": 2.11,
        "最低价": 2.04,
        "昨收价": 2.08,
        "总市值": 800338484.0,
        "市盈率": -7.41,
        "成交量": 1051232.0,
        "成交额": 2192890.0,
        "振幅": 3.37,
        "换手率": 0.28,
        "symbol": "105.CRON"
    },
    {
        "序号": 2518,
        "name": "Brookfield Property Partners LP",
        "最新价": 11.55,
        "涨跌额": 0.11,
        "涨跌幅": 0.96,
        "开盘价": 11.44,
        "最高价": 11.67,
        "最低价": 11.44,
        "昨收价": 11.44,
        "总市值": 132825000.0,
        "市盈率": null,
        "成交量": 12507.0,
        "成交额": 144583.0,
        "振幅": 2.01,
        "换手率": 0.11,
        "symbol": "105.BPYPN"
    },
    {
        "序号": 2519,
        "name": "TechnipFMC plc",
        "最新价": 18.92,
        "涨跌额": 0.18,
        "涨跌幅": 0.96,
        "开盘价": 18.89,
        "最高价": 19.0,
        "最低价": 18.59,
        "昨收价": 18.74,
        "总市值": 8238906379.0,
        "市盈率": -241.61,
        "成交量": 8112717.0,
        "成交额": 152615301.0,
        "振幅": 2.19,
        "换手率": 1.86,
        "symbol": "106.FTI"
    },
    {
        "序号": 2520,
        "name": "ServisFirst Bancshares Inc",
        "最新价": 56.77,
        "涨跌额": 0.54,
        "涨跌幅": 0.96,
        "开盘价": 56.09,
        "最高价": 57.07,
        "最低价": 56.0,
        "昨收价": 56.23,
        "总市值": 3090284601.0,
        "市盈率": 13.29,
        "成交量": 112646.0,
        "成交额": 6381486.0,
        "振幅": 1.9,
        "换手率": 0.21,
        "symbol": "106.SFBS"
    },
    {
        "序号": 2521,
        "name": "科力斯",
        "最新价": 188.32,
        "涨跌额": 1.79,
        "涨跌幅": 0.96,
        "开盘价": 185.55,
        "最高价": 189.01,
        "最低价": 185.55,
        "昨收价": 186.53,
        "总市值": 6927438580.0,
        "市盈率": 49.72,
        "成交量": 278928.0,
        "成交额": 52457139.0,
        "振幅": 1.85,
        "换手率": 0.76,
        "symbol": "105.QLYS"
    },
    {
        "序号": 2522,
        "name": "National Storage Affiliates Tru",
        "最新价": 36.85,
        "涨跌额": 0.35,
        "涨跌幅": 0.96,
        "开盘价": 36.63,
        "最高价": 36.93,
        "最低价": 36.39,
        "昨收价": 36.5,
        "总市值": 3061513919.0,
        "市盈率": 25.75,
        "成交量": 604766.0,
        "成交额": 22208727.0,
        "振幅": 1.48,
        "换手率": 0.73,
        "symbol": "106.NSA"
    },
    {
        "序号": 2523,
        "name": "Invesco S&P 500 Pure Value ETF",
        "最新价": 79.0,
        "涨跌额": 0.75,
        "涨跌幅": 0.96,
        "开盘价": 78.32,
        "最高价": 79.18,
        "最低价": 78.32,
        "昨收价": 78.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 198373.0,
        "成交额": 15664215.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.RPV"
    },
    {
        "序号": 2524,
        "name": "耐克",
        "最新价": 115.91,
        "涨跌额": 1.1,
        "涨跌幅": 0.96,
        "开盘价": 114.5,
        "最高价": 116.29,
        "最低价": 114.47,
        "昨收价": 114.81,
        "总市值": 176404602589.0,
        "市盈率": 34.92,
        "成交量": 6122150.0,
        "成交额": 709352096.0,
        "振幅": 1.59,
        "换手率": 0.4,
        "symbol": "106.NKE"
    },
    {
        "序号": 2525,
        "name": "Mueller Water Products Inc",
        "最新价": 13.71,
        "涨跌额": 0.13,
        "涨跌幅": 0.96,
        "开盘价": 13.6,
        "最高价": 13.76,
        "最低价": 13.6,
        "昨收价": 13.58,
        "总市值": 2145415588.0,
        "市盈率": 28.45,
        "成交量": 783351.0,
        "成交额": 10732656.0,
        "振幅": 1.18,
        "换手率": 0.5,
        "symbol": "106.MWA"
    },
    {
        "序号": 2526,
        "name": "Direxion Work From Home ETF",
        "最新价": 51.7,
        "涨跌额": 0.49,
        "涨跌幅": 0.96,
        "开盘价": 51.13,
        "最高价": 51.74,
        "最低价": 51.13,
        "昨收价": 51.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 1014.0,
        "成交额": 52225.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.WFH"
    },
    {
        "序号": 2527,
        "name": "康索尔能源",
        "最新价": 20.05,
        "涨跌额": 0.19,
        "涨跌幅": 0.96,
        "开盘价": 20.01,
        "最高价": 20.15,
        "最低价": 19.76,
        "昨收价": 19.86,
        "总市值": 3185007883.0,
        "市盈率": 1.34,
        "成交量": 2139238.0,
        "成交额": 42791014.0,
        "振幅": 1.96,
        "换手率": 1.35,
        "symbol": "106.CNX"
    },
    {
        "序号": 2528,
        "name": "二倍做多金融ETF-ProShares",
        "最新价": 50.67,
        "涨跌额": 0.48,
        "涨跌幅": 0.96,
        "开盘价": 49.99,
        "最高价": 50.78,
        "最低价": 49.99,
        "昨收价": 50.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 10079.0,
        "成交额": 508388.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.UYG"
    },
    {
        "序号": 2529,
        "name": "Live Oak Bancshares Inc",
        "最新价": 37.0,
        "涨跌额": 0.35,
        "涨跌幅": 0.95,
        "开盘价": 36.76,
        "最高价": 37.24,
        "最低价": 36.65,
        "昨收价": 36.65,
        "总市值": 1645822345.0,
        "市盈率": 27.65,
        "成交量": 90386.0,
        "成交额": 3344766.0,
        "振幅": 1.61,
        "换手率": 0.2,
        "symbol": "106.LOB"
    },
    {
        "序号": 2530,
        "name": "沃特财务",
        "最新价": 12.69,
        "涨跌额": 0.12,
        "涨跌幅": 0.95,
        "开盘价": 12.5,
        "最高价": 12.76,
        "最低价": 12.5,
        "昨收价": 12.57,
        "总市值": 261116686.0,
        "市盈率": 25.23,
        "成交量": 39972.0,
        "成交额": 505946.0,
        "振幅": 2.07,
        "换手率": 0.19,
        "symbol": "105.WSBF"
    },
    {
        "序号": 2531,
        "name": "First Trust Financials AlphaDEX",
        "最新价": 41.26,
        "涨跌额": 0.39,
        "涨跌幅": 0.95,
        "开盘价": 40.88,
        "最高价": 41.34,
        "最低价": 40.88,
        "昨收价": 40.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 45131.0,
        "成交额": 1857014.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.FXO"
    },
    {
        "序号": 2532,
        "name": "RLI保险",
        "最新价": 134.48,
        "涨跌额": 1.27,
        "涨跌幅": 0.95,
        "开盘价": 133.47,
        "最高价": 134.95,
        "最低价": 133.14,
        "昨收价": 133.21,
        "总市值": 6135753684.0,
        "市盈率": 21.32,
        "成交量": 97877.0,
        "成交额": 13141486.0,
        "振幅": 1.36,
        "换手率": 0.21,
        "symbol": "106.RLI"
    },
    {
        "序号": 2533,
        "name": "MicroSectors FANG+ ETNs",
        "最新价": 36.04,
        "涨跌额": 0.34,
        "涨跌幅": 0.95,
        "开盘价": 35.48,
        "最高价": 36.04,
        "最低价": 35.47,
        "昨收价": 35.7,
        "总市值": 25981566162.0,
        "市盈率": 8.19,
        "成交量": 113701.0,
        "成交额": 4074998.0,
        "振幅": 1.6,
        "换手率": null,
        "symbol": "107.FNGS"
    },
    {
        "序号": 2534,
        "name": "中央太平洋银行",
        "最新价": 19.08,
        "涨跌额": 0.18,
        "涨跌幅": 0.95,
        "开盘价": 18.99,
        "最高价": 19.18,
        "最低价": 18.97,
        "昨收价": 18.9,
        "总市值": 515983665.0,
        "市盈率": 8.06,
        "成交量": 81323.0,
        "成交额": 1550876.0,
        "振幅": 1.11,
        "换手率": 0.3,
        "symbol": "106.CPF"
    },
    {
        "序号": 2535,
        "name": "Alset Capital Acquisition Corp-",
        "最新价": 10.6,
        "涨跌额": 0.1,
        "涨跌幅": 0.95,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.6,
        "昨收价": 10.5,
        "总市值": 48823982.0,
        "市盈率": 55.56,
        "成交量": 222.0,
        "成交额": 2375.0,
        "振幅": 1.9,
        "换手率": 0.0,
        "symbol": "105.ACAX"
    },
    {
        "序号": 2536,
        "name": "Phreesia Inc",
        "最新价": 18.04,
        "涨跌额": 0.17,
        "涨跌幅": 0.95,
        "开盘价": 17.81,
        "最高价": 18.25,
        "最低价": 17.64,
        "昨收价": 17.87,
        "总市值": 1003654354.0,
        "市盈率": -6.96,
        "成交量": 836082.0,
        "成交额": 15029741.0,
        "振幅": 3.41,
        "换手率": 1.5,
        "symbol": "106.PHR"
    },
    {
        "序号": 2537,
        "name": "FNCB Bancorp Inc",
        "最新价": 6.37,
        "涨跌额": 0.06,
        "涨跌幅": 0.95,
        "开盘价": 6.27,
        "最高价": 6.45,
        "最低价": 6.26,
        "昨收价": 6.31,
        "总市值": 126017907.0,
        "市盈率": 8.66,
        "成交量": 5287.0,
        "成交额": 33483.0,
        "振幅": 3.01,
        "换手率": 0.03,
        "symbol": "105.FNCB"
    },
    {
        "序号": 2538,
        "name": "MI 家居",
        "最新价": 114.73,
        "涨跌额": 1.08,
        "涨跌幅": 0.95,
        "开盘价": 112.91,
        "最高价": 115.14,
        "最低价": 112.91,
        "昨收价": 113.65,
        "总市值": 3195060929.0,
        "市盈率": 6.51,
        "成交量": 171696.0,
        "成交额": 19647518.0,
        "振幅": 1.96,
        "换手率": 0.62,
        "symbol": "106.MHO"
    },
    {
        "序号": 2539,
        "name": "Generations Bancorp NY Inc",
        "最新价": 8.51,
        "涨跌额": 0.08,
        "涨跌幅": 0.95,
        "开盘价": 8.43,
        "最高价": 8.51,
        "最低价": 8.43,
        "昨收价": 8.43,
        "总市值": 19027415.0,
        "市盈率": -20.79,
        "成交量": 3479.0,
        "成交额": 29468.0,
        "振幅": 0.95,
        "换手率": 0.16,
        "symbol": "105.GBNY"
    },
    {
        "序号": 2540,
        "name": "探索金融服务",
        "最新价": 103.3,
        "涨跌额": 0.97,
        "涨跌幅": 0.95,
        "开盘价": 102.25,
        "最高价": 104.28,
        "最低价": 102.02,
        "昨收价": 102.33,
        "总市值": 25830986752.0,
        "市盈率": 7.19,
        "成交量": 1648521.0,
        "成交额": 170575358.0,
        "振幅": 2.21,
        "换手率": 0.66,
        "symbol": "106.DFS"
    },
    {
        "序号": 2541,
        "name": "abrdn Global Income Fund Inc",
        "最新价": 6.39,
        "涨跌额": 0.06,
        "涨跌幅": 0.95,
        "开盘价": 6.28,
        "最高价": 6.39,
        "最低价": 6.27,
        "昨收价": 6.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 49466.0,
        "成交额": 313773.0,
        "振幅": 1.9,
        "换手率": null,
        "symbol": "107.FCO"
    },
    {
        "序号": 2542,
        "name": "艾马克技术",
        "最新价": 28.76,
        "涨跌额": 0.27,
        "涨跌幅": 0.95,
        "开盘价": 28.44,
        "最高价": 28.98,
        "最低价": 28.38,
        "昨收价": 28.49,
        "总市值": 7068290959.0,
        "市盈率": 17.39,
        "成交量": 847193.0,
        "成交额": 24359641.0,
        "振幅": 2.11,
        "换手率": 0.34,
        "symbol": "105.AMKR"
    },
    {
        "序号": 2543,
        "name": "Freshworks Inc-A",
        "最新价": 20.26,
        "涨跌额": 0.19,
        "涨跌幅": 0.95,
        "开盘价": 19.86,
        "最高价": 20.58,
        "最低价": 19.72,
        "昨收价": 20.07,
        "总市值": 5982562373.0,
        "市盈率": -36.29,
        "成交量": 1033316.0,
        "成交额": 20994924.0,
        "振幅": 4.29,
        "换手率": 0.35,
        "symbol": "105.FRSH"
    },
    {
        "序号": 2544,
        "name": "Goldman Sachs BDC Inc",
        "最新价": 14.94,
        "涨跌额": 0.14,
        "涨跌幅": 0.95,
        "开盘价": 14.8,
        "最高价": 14.97,
        "最低价": 14.78,
        "昨收价": 14.8,
        "总市值": 1636879064.0,
        "市盈率": 10.99,
        "成交量": 382174.0,
        "成交额": 5690870.0,
        "振幅": 1.28,
        "换手率": 0.35,
        "symbol": "106.GSBD"
    },
    {
        "序号": 2545,
        "name": "肯纳金属",
        "最新价": 24.56,
        "涨跌额": 0.23,
        "涨跌幅": 0.95,
        "开盘价": 24.27,
        "最高价": 24.59,
        "最低价": 24.19,
        "昨收价": 24.33,
        "总市值": 1955057171.0,
        "市盈率": 16.25,
        "成交量": 574605.0,
        "成交额": 14084011.0,
        "振幅": 1.64,
        "换手率": 0.72,
        "symbol": "106.KMT"
    },
    {
        "序号": 2546,
        "name": "Grizzle Growth ETF",
        "最新价": 25.63,
        "涨跌额": 0.24,
        "涨跌幅": 0.95,
        "开盘价": 25.58,
        "最高价": 25.63,
        "最低价": 25.58,
        "昨收价": 25.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 207.0,
        "成交额": 5295.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.DARP"
    },
    {
        "序号": 2547,
        "name": "Invesco Dynamic Energy Explorat",
        "最新价": 29.94,
        "涨跌额": 0.28,
        "涨跌幅": 0.94,
        "开盘价": 29.84,
        "最高价": 30.17,
        "最低价": 29.81,
        "昨收价": 29.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 73142.0,
        "成交额": 2192030.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.PXE"
    },
    {
        "序号": 2548,
        "name": "Mind Medicine (MindMed) Inc",
        "最新价": 3.21,
        "涨跌额": 0.03,
        "涨跌幅": 0.94,
        "开盘价": 3.18,
        "最高价": 3.31,
        "最低价": 3.13,
        "昨收价": 3.18,
        "总市值": 128704013.0,
        "市盈率": -1.68,
        "成交量": 323381.0,
        "成交额": 1038695.0,
        "振幅": 5.66,
        "换手率": 0.81,
        "symbol": "105.MNMD"
    },
    {
        "序号": 2549,
        "name": "iShares North American Natural ",
        "最新价": 39.59,
        "涨跌额": 0.37,
        "涨跌幅": 0.94,
        "开盘价": 39.43,
        "最高价": 39.7,
        "最低价": 39.4,
        "昨收价": 39.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 99190.0,
        "成交额": 3924120.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.IGE"
    },
    {
        "序号": 2550,
        "name": "第一万能金控",
        "最新价": 16.05,
        "涨跌额": 0.15,
        "涨跌幅": 0.94,
        "开盘价": 15.77,
        "最高价": 16.09,
        "最低价": 15.76,
        "昨收价": 15.9,
        "总市值": 2769462585.0,
        "市盈率": 9.34,
        "成交量": 968274.0,
        "成交额": 15504300.0,
        "振幅": 2.08,
        "换手率": 0.56,
        "symbol": "106.FBP"
    },
    {
        "序号": 2551,
        "name": "Loncar Cancer Immunotherapy ETF",
        "最新价": 11.77,
        "涨跌额": 0.11,
        "涨跌幅": 0.94,
        "开盘价": 11.66,
        "最高价": 11.84,
        "最低价": 11.66,
        "昨收价": 11.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1866.0,
        "成交额": 22037.0,
        "振幅": 1.54,
        "换手率": null,
        "symbol": "105.CNCR"
    },
    {
        "序号": 2552,
        "name": "Blackstone Secured Lending Fund",
        "最新价": 27.83,
        "涨跌额": 0.26,
        "涨跌幅": 0.94,
        "开盘价": 27.58,
        "最高价": 28.0,
        "最低价": 27.58,
        "昨收价": 27.57,
        "总市值": 5097115958.0,
        "市盈率": 8.84,
        "成交量": 756157.0,
        "成交额": 21044158.0,
        "振幅": 1.52,
        "换手率": 0.41,
        "symbol": "106.BXSL"
    },
    {
        "序号": 2553,
        "name": "Fidelity Growth Opportunities E",
        "最新价": 18.2,
        "涨跌额": 0.17,
        "涨跌幅": 0.94,
        "开盘价": 17.95,
        "最高价": 18.21,
        "最低价": 17.95,
        "昨收价": 18.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 30941.0,
        "成交额": 561136.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "107.FGRO"
    },
    {
        "序号": 2554,
        "name": "BancFirst银行",
        "最新价": 92.2,
        "涨跌额": 0.86,
        "涨跌幅": 0.94,
        "开盘价": 91.62,
        "最高价": 92.4,
        "最低价": 90.75,
        "昨收价": 91.34,
        "总市值": 3035352435.0,
        "市盈率": 13.76,
        "成交量": 56245.0,
        "成交额": 5167414.0,
        "振幅": 1.81,
        "换手率": 0.17,
        "symbol": "105.BANF"
    },
    {
        "序号": 2555,
        "name": "超视野传播",
        "最新价": 4.29,
        "涨跌额": 0.04,
        "涨跌幅": 0.94,
        "开盘价": 4.22,
        "最高价": 4.38,
        "最低价": 4.22,
        "昨收价": 4.25,
        "总市值": 377506019.0,
        "市盈率": 330.28,
        "成交量": 211072.0,
        "成交额": 904610.0,
        "振幅": 3.76,
        "换手率": 0.24,
        "symbol": "106.EVC"
    },
    {
        "序号": 2556,
        "name": "FI Enhanced Large Cap Growth ET",
        "最新价": 658.26,
        "涨跌额": 6.13,
        "涨跌幅": 0.94,
        "开盘价": 658.26,
        "最高价": 658.26,
        "最低价": 658.26,
        "昨收价": 652.13,
        "总市值": 2128329082751.0,
        "市盈率": 68.91,
        "成交量": 67.0,
        "成交额": 44103.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FBGX"
    },
    {
        "序号": 2557,
        "name": "卡拉制药",
        "最新价": 7.52,
        "涨跌额": 0.07,
        "涨跌幅": 0.94,
        "开盘价": 7.4,
        "最高价": 7.6,
        "最低价": 7.2,
        "昨收价": 7.45,
        "总市值": 20252232.0,
        "市盈率": -0.44,
        "成交量": 49150.0,
        "成交额": 365154.0,
        "振幅": 5.37,
        "换手率": 1.83,
        "symbol": "105.KALA"
    },
    {
        "序号": 2558,
        "name": "查特工业(优先股)",
        "最新价": 51.57,
        "涨跌额": 0.48,
        "涨跌幅": 0.94,
        "开盘价": 50.78,
        "最高价": 51.57,
        "最低价": 50.78,
        "昨收价": 51.09,
        "总市值": 18049500.0,
        "市盈率": null,
        "成交量": 4428.0,
        "成交额": 226526.0,
        "振幅": 1.55,
        "换手率": 1.27,
        "symbol": "106.GTLS_B"
    },
    {
        "序号": 2559,
        "name": "Riverview Bancorp Inc",
        "最新价": 6.45,
        "涨跌额": 0.06,
        "涨跌幅": 0.94,
        "开盘价": 6.39,
        "最高价": 6.45,
        "最低价": 6.3,
        "昨收价": 6.39,
        "总市值": 136166227.0,
        "市盈率": 10.06,
        "成交量": 15050.0,
        "成交额": 96046.0,
        "振幅": 2.35,
        "换手率": 0.07,
        "symbol": "105.RVSB"
    },
    {
        "序号": 2560,
        "name": "阿尔蒂斯美国",
        "最新价": 2.15,
        "涨跌额": 0.02,
        "涨跌幅": 0.94,
        "开盘价": 2.12,
        "最高价": 2.21,
        "最低价": 2.09,
        "昨收价": 2.13,
        "总市值": 977739661.0,
        "市盈率": -44.22,
        "成交量": 1821209.0,
        "成交额": 3933184.0,
        "振幅": 5.63,
        "换手率": 0.4,
        "symbol": "106.ATUS"
    },
    {
        "序号": 2561,
        "name": "ESCO科技",
        "最新价": 106.44,
        "涨跌额": 0.99,
        "涨跌幅": 0.94,
        "开盘价": 105.59,
        "最高价": 106.54,
        "最低价": 105.39,
        "昨收价": 105.45,
        "总市值": 2746574460.0,
        "市盈率": 29.68,
        "成交量": 67878.0,
        "成交额": 7206235.0,
        "振幅": 1.09,
        "换手率": 0.26,
        "symbol": "106.ESE"
    },
    {
        "序号": 2562,
        "name": "Cloudflare Inc-A",
        "最新价": 77.52,
        "涨跌额": 0.72,
        "涨跌幅": 0.94,
        "开盘价": 75.98,
        "最高价": 78.34,
        "最低价": 75.78,
        "昨收价": 76.8,
        "总市值": 26029032029.0,
        "市盈率": -128.86,
        "成交量": 2600933.0,
        "成交额": 201902864.0,
        "振幅": 3.33,
        "换手率": 0.77,
        "symbol": "106.NET"
    },
    {
        "序号": 2563,
        "name": "Zacks Small\/Mid Cap ETF",
        "最新价": 26.92,
        "涨跌额": 0.25,
        "涨跌幅": 0.94,
        "开盘价": 26.99,
        "最高价": 26.99,
        "最低价": 26.92,
        "昨收价": 26.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 987.0,
        "成交额": 26608.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.SMIZ"
    },
    {
        "序号": 2564,
        "name": "Global X MLP & Energy Infrastru",
        "最新价": 44.19,
        "涨跌额": 0.41,
        "涨跌幅": 0.94,
        "开盘价": 43.9,
        "最高价": 44.23,
        "最低价": 43.9,
        "昨收价": 43.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 45433.0,
        "成交额": 2004713.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.MLPX"
    },
    {
        "序号": 2565,
        "name": "Carbon Collective Climate Solut",
        "最新价": 18.33,
        "涨跌额": 0.17,
        "涨跌幅": 0.94,
        "开盘价": 18.45,
        "最高价": 18.45,
        "最低价": 18.23,
        "昨收价": 18.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1323.0,
        "成交额": 24207.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "105.CCSO"
    },
    {
        "序号": 2566,
        "name": "杜科蒙",
        "最新价": 51.77,
        "涨跌额": 0.48,
        "涨跌幅": 0.94,
        "开盘价": 51.18,
        "最高价": 51.9,
        "最低价": 50.81,
        "昨收价": 51.29,
        "总市值": 755654541.0,
        "市盈率": 39.98,
        "成交量": 61857.0,
        "成交额": 3183896.0,
        "振幅": 2.13,
        "换手率": 0.42,
        "symbol": "106.DCO"
    },
    {
        "序号": 2567,
        "name": "艾默生电气",
        "最新价": 89.55,
        "涨跌额": 0.83,
        "涨跌幅": 0.94,
        "开盘价": 88.92,
        "最高价": 89.86,
        "最低价": 88.53,
        "昨收价": 88.72,
        "总市值": 51052455000.0,
        "市盈率": 3.86,
        "成交量": 2366068.0,
        "成交额": 211983232.0,
        "振幅": 1.5,
        "换手率": 0.42,
        "symbol": "106.EMR"
    },
    {
        "序号": 2568,
        "name": "RLJ住房信托",
        "最新价": 10.79,
        "涨跌额": 0.1,
        "涨跌幅": 0.94,
        "开盘价": 10.61,
        "最高价": 10.82,
        "最低价": 10.6,
        "昨收价": 10.69,
        "总市值": 1681560623.0,
        "市盈率": 22.4,
        "成交量": 1469486.0,
        "成交额": 15773983.0,
        "振幅": 2.06,
        "换手率": 0.94,
        "symbol": "106.RLJ"
    },
    {
        "序号": 2569,
        "name": "First Trust Energy AlphaDEX Fun",
        "最新价": 16.2,
        "涨跌额": 0.15,
        "涨跌幅": 0.93,
        "开盘价": 16.18,
        "最高价": 16.28,
        "最低价": 16.09,
        "昨收价": 16.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 3698478.0,
        "成交额": 59746561.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.FXN"
    },
    {
        "序号": 2570,
        "name": "First Trust Large Cap Growth Al",
        "最新价": 104.94,
        "涨跌额": 0.97,
        "涨跌幅": 0.93,
        "开盘价": 104.0,
        "最高价": 105.2,
        "最低价": 104.0,
        "昨收价": 103.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 42361.0,
        "成交额": 4432481.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "105.FTC"
    },
    {
        "序号": 2571,
        "name": "Vesta Real Estate Corp ADR",
        "最新价": 36.83,
        "涨跌额": 0.34,
        "涨跌幅": 0.93,
        "开盘价": 36.08,
        "最高价": 37.02,
        "最低价": 35.58,
        "昨收价": 36.49,
        "总市值": 3080000081.0,
        "市盈率": 10.94,
        "成交量": 1047259.0,
        "成交额": 38091878.0,
        "振幅": 3.95,
        "换手率": 1.25,
        "symbol": "106.VTMX"
    },
    {
        "序号": 2572,
        "name": "Enfusion Inc-A",
        "最新价": 9.75,
        "涨跌额": 0.09,
        "涨跌幅": 0.93,
        "开盘价": 9.52,
        "最高价": 9.88,
        "最低价": 9.52,
        "昨收价": 9.66,
        "总市值": 1242914176.0,
        "市盈率": 210.81,
        "成交量": 200375.0,
        "成交额": 1956045.0,
        "振幅": 3.73,
        "换手率": 0.16,
        "symbol": "106.ENFN"
    },
    {
        "序号": 2573,
        "name": "Invesco S&P 500 High Beta ETF",
        "最新价": 75.94,
        "涨跌额": 0.7,
        "涨跌幅": 0.93,
        "开盘价": 75.18,
        "最高价": 76.33,
        "最低价": 75.18,
        "昨收价": 75.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 435140.0,
        "成交额": 33015557.0,
        "振幅": 1.53,
        "换手率": null,
        "symbol": "107.SPHB"
    },
    {
        "序号": 2574,
        "name": "Community Healthcare Trust Inc",
        "最新价": 28.22,
        "涨跌额": 0.26,
        "涨跌幅": 0.93,
        "开盘价": 27.91,
        "最高价": 28.29,
        "最低价": 27.68,
        "昨收价": 27.96,
        "总市值": 769402666.0,
        "市盈率": 91.76,
        "成交量": 199247.0,
        "成交额": 5594833.0,
        "振幅": 2.18,
        "换手率": 0.73,
        "symbol": "106.CHCT"
    },
    {
        "序号": 2575,
        "name": "西联汇款",
        "最新价": 11.94,
        "涨跌额": 0.11,
        "涨跌幅": 0.93,
        "开盘价": 11.83,
        "最高价": 11.99,
        "最低价": 11.78,
        "昨收价": 11.83,
        "总市值": 4350446556.0,
        "市盈率": 5.81,
        "成交量": 4161408.0,
        "成交额": 49647599.0,
        "振幅": 1.78,
        "换手率": 1.14,
        "symbol": "106.WU"
    },
    {
        "序号": 2576,
        "name": "Roundhill Ball Metaverse ETF",
        "最新价": 10.86,
        "涨跌额": 0.1,
        "涨跌幅": 0.93,
        "开盘价": 10.76,
        "最高价": 10.89,
        "最低价": 10.76,
        "昨收价": 10.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 147485.0,
        "成交额": 1598626.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.METV"
    },
    {
        "序号": 2577,
        "name": "通用电气(US)",
        "最新价": 120.59,
        "涨跌额": 1.11,
        "涨跌幅": 0.93,
        "开盘价": 119.1,
        "最高价": 120.82,
        "最低价": 118.98,
        "昨收价": 119.48,
        "总市值": 131248472925.0,
        "市盈率": 13.16,
        "成交量": 3930961.0,
        "成交额": 473207920.0,
        "振幅": 1.54,
        "换手率": 0.36,
        "symbol": "106.GE"
    },
    {
        "序号": 2578,
        "name": "Overlay Shares Small Cap Equity",
        "最新价": 30.5,
        "涨跌额": 0.28,
        "涨跌幅": 0.93,
        "开盘价": 30.36,
        "最高价": 30.5,
        "最低价": 30.35,
        "昨收价": 30.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 7138.0,
        "成交额": 216859.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.OVS"
    },
    {
        "序号": 2579,
        "name": "欧洲证券汇率对冲ETF-WisdomTree",
        "最新价": 42.59,
        "涨跌额": 0.39,
        "涨跌幅": 0.92,
        "开盘价": 42.46,
        "最高价": 42.7,
        "最低价": 42.46,
        "昨收价": 42.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 119017.0,
        "成交额": 5073554.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.HEDJ"
    },
    {
        "序号": 2580,
        "name": "Kurv Yield Premium Strategy Mic",
        "最新价": 26.22,
        "涨跌额": 0.24,
        "涨跌幅": 0.92,
        "开盘价": 26.22,
        "最高价": 26.22,
        "最低价": 26.22,
        "昨收价": 25.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 84.0,
        "成交额": 2202.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MSFY"
    },
    {
        "序号": 2581,
        "name": "联合通讯",
        "最新价": 4.37,
        "涨跌额": 0.04,
        "涨跌幅": 0.92,
        "开盘价": 4.31,
        "最高价": 4.39,
        "最低价": 4.29,
        "昨收价": 4.33,
        "总市值": 509052494.0,
        "市盈率": -2.14,
        "成交量": 298261.0,
        "成交额": 1297415.0,
        "振幅": 2.31,
        "换手率": 0.26,
        "symbol": "105.CNSL"
    },
    {
        "序号": 2582,
        "name": "iShares GSCI Commodity Dynamic ",
        "最新价": 26.26,
        "涨跌额": 0.24,
        "涨跌幅": 0.92,
        "开盘价": 26.18,
        "最高价": 26.37,
        "最低价": 26.13,
        "昨收价": 26.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 233731.0,
        "成交额": 6132352.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "105.COMT"
    },
    {
        "序号": 2583,
        "name": "Gabelli Commercial Aerospace an",
        "最新价": 27.36,
        "涨跌额": 0.25,
        "涨跌幅": 0.92,
        "开盘价": 27.24,
        "最高价": 27.36,
        "最低价": 27.15,
        "昨收价": 27.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 2005.0,
        "成交额": 54627.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.GCAD"
    },
    {
        "序号": 2584,
        "name": "施乐辉公司",
        "最新价": 26.28,
        "涨跌额": 0.24,
        "涨跌幅": 0.92,
        "开盘价": 26.17,
        "最高价": 26.41,
        "最低价": 26.17,
        "昨收价": 26.04,
        "总市值": 11476461401.0,
        "市盈率": 52.64,
        "成交量": 902922.0,
        "成交额": 23719748.0,
        "振幅": 0.92,
        "换手率": 0.21,
        "symbol": "106.SNN"
    },
    {
        "序号": 2585,
        "name": "USCF Midstream Energy Income Fu",
        "最新价": 36.15,
        "涨跌额": 0.33,
        "涨跌幅": 0.92,
        "开盘价": 35.93,
        "最高价": 36.18,
        "最低价": 35.93,
        "昨收价": 35.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 10307.0,
        "成交额": 372468.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.UMI"
    },
    {
        "序号": 2586,
        "name": "FMC Corp",
        "最新价": 55.88,
        "涨跌额": 0.51,
        "涨跌幅": 0.92,
        "开盘价": 55.95,
        "最高价": 56.57,
        "最低价": 55.36,
        "昨收价": 55.37,
        "总市值": 6971526606.0,
        "市盈率": 14.03,
        "成交量": 1307737.0,
        "成交额": 73257916.0,
        "振幅": 2.19,
        "换手率": 1.05,
        "symbol": "106.FMC"
    },
    {
        "序号": 2587,
        "name": "First Trust Latin America Alpha",
        "最新价": 19.75,
        "涨跌额": 0.18,
        "涨跌幅": 0.92,
        "开盘价": 19.69,
        "最高价": 19.78,
        "最低价": 19.67,
        "昨收价": 19.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 3185.0,
        "成交额": 62727.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.FLN"
    },
    {
        "序号": 2588,
        "name": "Shopify Inc-A",
        "最新价": 72.52,
        "涨跌额": 0.66,
        "涨跌幅": 0.92,
        "开盘价": 71.26,
        "最高价": 73.02,
        "最低价": 71.24,
        "昨收价": 71.86,
        "总市值": 93173196627.0,
        "市盈率": -81.13,
        "成交量": 7457627.0,
        "成交额": 540108192.0,
        "振幅": 2.48,
        "换手率": 0.58,
        "symbol": "106.SHOP"
    },
    {
        "序号": 2589,
        "name": "Global X Adaptive U.S. Factor E",
        "最新价": 35.17,
        "涨跌额": 0.32,
        "涨跌幅": 0.92,
        "开盘价": 34.85,
        "最高价": 35.17,
        "最低价": 34.85,
        "昨收价": 34.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 6639.0,
        "成交额": 232664.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.AUSF"
    },
    {
        "序号": 2590,
        "name": "Affinity World Leaders Equity E",
        "最新价": 26.39,
        "涨跌额": 0.24,
        "涨跌幅": 0.92,
        "开盘价": 26.35,
        "最高价": 26.48,
        "最低价": 26.18,
        "昨收价": 26.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 12446.0,
        "成交额": 327606.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.WLDR"
    },
    {
        "序号": 2591,
        "name": "Vor Biopharma Inc",
        "最新价": 2.2,
        "涨跌额": 0.02,
        "涨跌幅": 0.92,
        "开盘价": 2.17,
        "最高价": 2.4,
        "最低价": 2.06,
        "昨收价": 2.18,
        "总市值": 149172511.0,
        "市盈率": -1.29,
        "成交量": 366134.0,
        "成交额": 813383.0,
        "振幅": 15.6,
        "换手率": 0.54,
        "symbol": "105.VOR"
    },
    {
        "序号": 2592,
        "name": "Scilex Holding Co",
        "最新价": 1.1,
        "涨跌额": 0.01,
        "涨跌幅": 0.92,
        "开盘价": 1.08,
        "最高价": 1.16,
        "最低价": 1.02,
        "昨收价": 1.09,
        "总市值": 171537285.0,
        "市盈率": -1.55,
        "成交量": 893473.0,
        "成交额": 980240.0,
        "振幅": 12.84,
        "换手率": 0.57,
        "symbol": "105.SCLX"
    },
    {
        "序号": 2593,
        "name": "Aris Water Solutions Inc-A",
        "最新价": 7.7,
        "涨跌额": 0.07,
        "涨跌幅": 0.92,
        "开盘价": 7.6,
        "最高价": 7.81,
        "最低价": 7.53,
        "昨收价": 7.63,
        "总市值": 444232204.0,
        "市盈率": 29.0,
        "成交量": 157359.0,
        "成交额": 1207419.0,
        "振幅": 3.67,
        "换手率": 0.27,
        "symbol": "106.ARIS"
    },
    {
        "序号": 2594,
        "name": "海格投资",
        "最新价": 15.46,
        "涨跌额": 0.14,
        "涨跌幅": 0.91,
        "开盘价": 15.28,
        "最高价": 15.5,
        "最低价": 15.24,
        "昨收价": 15.32,
        "总市值": 2336616314.0,
        "市盈率": 8.35,
        "成交量": 566837.0,
        "成交额": 8738636.0,
        "振幅": 1.7,
        "换手率": 0.38,
        "symbol": "106.HTGC"
    },
    {
        "序号": 2595,
        "name": "Central Plains Bancshares Inc",
        "最新价": 9.94,
        "涨跌额": 0.09,
        "涨跌幅": 0.91,
        "开盘价": 9.99,
        "最高价": 10.04,
        "最低价": 9.9,
        "昨收价": 9.85,
        "总市值": 41060301.0,
        "市盈率": 22.59,
        "成交量": 21934.0,
        "成交额": 219232.0,
        "振幅": 1.42,
        "换手率": 0.53,
        "symbol": "105.CPBI"
    },
    {
        "序号": 2596,
        "name": "Ituran Location and Control Ltd",
        "最新价": 26.51,
        "涨跌额": 0.24,
        "涨跌幅": 0.91,
        "开盘价": 26.75,
        "最高价": 26.75,
        "最低价": 26.32,
        "昨收价": 26.27,
        "总市值": 527378806.0,
        "市盈率": 11.54,
        "成交量": 41924.0,
        "成交额": 1112502.0,
        "振幅": 1.64,
        "换手率": 0.21,
        "symbol": "105.ITRN"
    },
    {
        "序号": 2597,
        "name": "Century Communities Inc",
        "最新价": 78.45,
        "涨跌额": 0.71,
        "涨跌幅": 0.91,
        "开盘价": 77.41,
        "最高价": 79.39,
        "最低价": 77.41,
        "昨收价": 77.74,
        "总市值": 2492718547.0,
        "市盈率": 10.08,
        "成交量": 252520.0,
        "成交额": 19854106.0,
        "振幅": 2.55,
        "换手率": 0.79,
        "symbol": "106.CCS"
    },
    {
        "序号": 2598,
        "name": "Largo Inc",
        "最新价": 2.21,
        "涨跌额": 0.02,
        "涨跌幅": 0.91,
        "开盘价": 2.16,
        "最高价": 2.29,
        "最低价": 2.1,
        "昨收价": 2.19,
        "总市值": 141552710.0,
        "市盈率": -4.16,
        "成交量": 56688.0,
        "成交额": 125737.0,
        "振幅": 8.68,
        "换手率": 0.09,
        "symbol": "105.LGO"
    },
    {
        "序号": 2599,
        "name": "Bain Capital Specialty Finance ",
        "最新价": 15.48,
        "涨跌额": 0.14,
        "涨跌幅": 0.91,
        "开盘价": 15.35,
        "最高价": 15.49,
        "最低价": 15.33,
        "昨收价": 15.34,
        "总市值": 999423866.0,
        "市盈率": 7.36,
        "成交量": 156760.0,
        "成交额": 2420682.0,
        "振幅": 1.04,
        "换手率": 0.24,
        "symbol": "106.BCSF"
    },
    {
        "序号": 2600,
        "name": "阿贝克隆比&费奇",
        "最新价": 78.65,
        "涨跌额": 0.71,
        "涨跌幅": 0.91,
        "开盘价": 77.6,
        "最高价": 78.76,
        "最低价": 77.31,
        "昨收价": 77.94,
        "总市值": 3964393991.0,
        "市盈率": 19.06,
        "成交量": 784086.0,
        "成交额": 61458779.0,
        "振幅": 1.86,
        "换手率": 1.56,
        "symbol": "106.ANF"
    },
    {
        "序号": 2601,
        "name": "iShares US Small Cap Value Fact",
        "最新价": 28.86,
        "涨跌额": 0.26,
        "涨跌幅": 0.91,
        "开盘价": 28.67,
        "最高价": 29.0,
        "最低价": 28.65,
        "昨收价": 28.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 8638.0,
        "成交额": 249231.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.SVAL"
    },
    {
        "序号": 2602,
        "name": "Noodles & Co",
        "最新价": 3.33,
        "涨跌额": 0.03,
        "涨跌幅": 0.91,
        "开盘价": 3.2,
        "最高价": 3.39,
        "最低价": 3.2,
        "昨收价": 3.3,
        "总市值": 149613600.0,
        "市盈率": -54.52,
        "成交量": 206710.0,
        "成交额": 688371.0,
        "振幅": 5.76,
        "换手率": 0.46,
        "symbol": "105.NDLS"
    },
    {
        "序号": 2603,
        "name": "Hanover Bancorp Inc",
        "最新价": 17.8,
        "涨跌额": 0.16,
        "涨跌幅": 0.91,
        "开盘价": 17.75,
        "最高价": 17.8,
        "最低价": 17.66,
        "昨收价": 17.64,
        "总市值": 127877336.0,
        "市盈率": 7.32,
        "成交量": 3935.0,
        "成交额": 69814.0,
        "振幅": 0.79,
        "换手率": 0.05,
        "symbol": "105.HNVR"
    },
    {
        "序号": 2604,
        "name": "Teekay Corp",
        "最新价": 6.68,
        "涨跌额": 0.06,
        "涨跌幅": 0.91,
        "开盘价": 6.65,
        "最高价": 6.7,
        "最低价": 6.63,
        "昨收价": 6.62,
        "总市值": 607541511.0,
        "市盈率": 3.94,
        "成交量": 391035.0,
        "成交额": 2611015.0,
        "振幅": 1.06,
        "换手率": 0.43,
        "symbol": "106.TK"
    },
    {
        "序号": 2605,
        "name": "FS Bancorp Inc",
        "最新价": 33.4,
        "涨跌额": 0.3,
        "涨跌幅": 0.91,
        "开盘价": 33.0,
        "最高价": 33.4,
        "最低价": 32.8,
        "昨收价": 33.1,
        "总市值": 260450628.0,
        "市盈率": 7.68,
        "成交量": 6327.0,
        "成交额": 210472.0,
        "振幅": 1.81,
        "换手率": 0.08,
        "symbol": "105.FSBW"
    },
    {
        "序号": 2606,
        "name": "索尔工业",
        "最新价": 108.06,
        "涨跌额": 0.97,
        "涨跌幅": 0.91,
        "开盘价": 106.63,
        "最高价": 108.87,
        "最低价": 105.94,
        "昨收价": 107.09,
        "总市值": 5762119796.0,
        "市盈率": 19.76,
        "成交量": 652448.0,
        "成交额": 70376628.0,
        "振幅": 2.74,
        "换手率": 1.22,
        "symbol": "106.THO"
    },
    {
        "序号": 2607,
        "name": "LifeStance Health Group Inc",
        "最新价": 6.69,
        "涨跌额": 0.06,
        "涨跌幅": 0.9,
        "开盘价": 6.61,
        "最高价": 6.87,
        "最低价": 6.61,
        "昨收价": 6.63,
        "总市值": 2532884115.0,
        "市盈率": -13.48,
        "成交量": 906763.0,
        "成交额": 6118385.0,
        "振幅": 3.92,
        "换手率": 0.24,
        "symbol": "105.LFST"
    },
    {
        "序号": 2608,
        "name": "AFC Gamma Inc",
        "最新价": 12.29,
        "涨跌额": 0.11,
        "涨跌幅": 0.9,
        "开盘价": 12.2,
        "最高价": 12.41,
        "最低价": 12.2,
        "昨收价": 12.18,
        "总市值": 251425096.0,
        "市盈率": 7.6,
        "成交量": 101660.0,
        "成交额": 1251378.0,
        "振幅": 1.72,
        "换手率": 0.5,
        "symbol": "105.AFCG"
    },
    {
        "序号": 2609,
        "name": "Pebblebrook Hotel Trust",
        "最新价": 13.42,
        "涨跌额": 0.12,
        "涨跌幅": 0.9,
        "开盘价": 13.28,
        "最高价": 13.43,
        "最低价": 13.16,
        "昨收价": 13.3,
        "总市值": 1617134975.0,
        "市盈率": -21.27,
        "成交量": 1268936.0,
        "成交额": 16912978.0,
        "振幅": 2.03,
        "换手率": 1.05,
        "symbol": "106.PEB"
    },
    {
        "序号": 2610,
        "name": "TMC the metal company Inc",
        "最新价": 1.12,
        "涨跌额": 0.01,
        "涨跌幅": 0.9,
        "开盘价": 1.11,
        "最高价": 1.14,
        "最低价": 1.09,
        "昨收价": 1.11,
        "总市值": 343131022.0,
        "市盈率": -2.52,
        "成交量": 412303.0,
        "成交额": 457551.0,
        "振幅": 4.5,
        "换手率": 0.13,
        "symbol": "105.TMC"
    },
    {
        "序号": 2611,
        "name": "Kingsbarn Dividend Opportunity ",
        "最新价": 29.12,
        "涨跌额": 0.26,
        "涨跌幅": 0.9,
        "开盘价": 29.12,
        "最高价": 29.12,
        "最低价": 29.12,
        "昨收价": 28.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 174.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.DVDN"
    },
    {
        "序号": 2612,
        "name": "Vanguard U.S. Multifactor ETF",
        "最新价": 108.69,
        "涨跌额": 0.97,
        "涨跌幅": 0.9,
        "开盘价": 107.99,
        "最高价": 108.9,
        "最低价": 107.99,
        "昨收价": 107.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 17640.0,
        "成交额": 1917117.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.VFMF"
    },
    {
        "序号": 2613,
        "name": "Profound Medical Corp",
        "最新价": 10.09,
        "涨跌额": 0.09,
        "涨跌幅": 0.9,
        "开盘价": 10.57,
        "最高价": 10.57,
        "最低价": 10.01,
        "昨收价": 10.0,
        "总市值": 215580246.0,
        "市盈率": -7.39,
        "成交量": 22016.0,
        "成交额": 223888.0,
        "振幅": 5.6,
        "换手率": 0.1,
        "symbol": "105.PROF"
    },
    {
        "序号": 2614,
        "name": "Magyar Bancorp Inc",
        "最新价": 10.09,
        "涨跌额": 0.09,
        "涨跌幅": 0.9,
        "开盘价": 10.02,
        "最高价": 10.1,
        "最低价": 10.02,
        "昨收价": 10.0,
        "总市值": 67220569.0,
        "市盈率": 8.45,
        "成交量": 8715.0,
        "成交额": 87847.0,
        "振幅": 0.8,
        "换手率": 0.13,
        "symbol": "105.MGYR"
    },
    {
        "序号": 2615,
        "name": "Artisan Partners Asset Manageme",
        "最新价": 39.25,
        "涨跌额": 0.35,
        "涨跌幅": 0.9,
        "开盘价": 38.72,
        "最高价": 39.33,
        "最低价": 38.72,
        "昨收价": 38.9,
        "总市值": 3138464030.0,
        "市盈率": 14.92,
        "成交量": 348212.0,
        "成交额": 13643301.0,
        "振幅": 1.57,
        "换手率": 0.44,
        "symbol": "106.APAM"
    },
    {
        "序号": 2616,
        "name": "ConnectOne Bancorp Inc",
        "最新价": 21.31,
        "涨跌额": 0.19,
        "涨跌幅": 0.9,
        "开盘价": 21.11,
        "最高价": 21.6,
        "最低价": 20.84,
        "昨收价": 21.12,
        "总市值": 822701745.0,
        "市盈率": 8.2,
        "成交量": 79038.0,
        "成交额": 1686870.0,
        "振幅": 3.6,
        "换手率": 0.2,
        "symbol": "105.CNOB"
    },
    {
        "序号": 2617,
        "name": "Pacer Lunt Large Cap Multi-Fact",
        "最新价": 39.3,
        "涨跌额": 0.35,
        "涨跌幅": 0.9,
        "开盘价": 38.89,
        "最高价": 39.33,
        "最低价": 38.89,
        "昨收价": 38.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 10649.0,
        "成交额": 416434.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.PALC"
    },
    {
        "序号": 2618,
        "name": "格尔夫波特能源",
        "最新价": 134.85,
        "涨跌额": 1.2,
        "涨跌幅": 0.9,
        "开盘价": 135.08,
        "最高价": 135.61,
        "最低价": 133.58,
        "昨收价": 133.65,
        "总市值": 2511693715.0,
        "市盈率": 1.27,
        "成交量": 159197.0,
        "成交额": 21451348.0,
        "振幅": 1.52,
        "换手率": 0.85,
        "symbol": "106.GPOR"
    },
    {
        "序号": 2619,
        "name": "First Trust Multi Cap Growth Al",
        "最新价": 106.83,
        "涨跌额": 0.95,
        "涨跌幅": 0.9,
        "开盘价": 106.69,
        "最高价": 106.83,
        "最低价": 106.1,
        "昨收价": 105.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 4918.0,
        "成交额": 523268.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "105.FAD"
    },
    {
        "序号": 2620,
        "name": "三倍做空房地产ETF-Direxion",
        "最新价": 42.74,
        "涨跌额": 0.38,
        "涨跌幅": 0.9,
        "开盘价": 42.9,
        "最高价": 43.98,
        "最低价": 42.35,
        "昨收价": 42.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 210440.0,
        "成交额": 9073690.0,
        "振幅": 3.85,
        "换手率": null,
        "symbol": "107.DRV"
    },
    {
        "序号": 2621,
        "name": "First Trust DJ Select MicroCap ",
        "最新价": 57.45,
        "涨跌额": 0.51,
        "涨跌幅": 0.9,
        "开盘价": 57.57,
        "最高价": 57.6,
        "最低价": 57.06,
        "昨收价": 56.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 3212.0,
        "成交额": 184000.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.FDM"
    },
    {
        "序号": 2622,
        "name": "博克金融",
        "最新价": 78.87,
        "涨跌额": 0.7,
        "涨跌幅": 0.9,
        "开盘价": 78.33,
        "最高价": 79.27,
        "最低价": 78.3,
        "昨收价": 78.17,
        "总市值": 5178985931.0,
        "市盈率": 8.4,
        "成交量": 145295.0,
        "成交额": 11450302.0,
        "振幅": 1.24,
        "换手率": 0.22,
        "symbol": "105.BOKF"
    },
    {
        "序号": 2623,
        "name": "Putnam Municipal Opportunities ",
        "最新价": 10.15,
        "涨跌额": 0.09,
        "涨跌幅": 0.89,
        "开盘价": 9.99,
        "最高价": 10.16,
        "最低价": 9.99,
        "昨收价": 10.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 160722.0,
        "成交额": 1624772.0,
        "振幅": 1.69,
        "换手率": null,
        "symbol": "106.PMO"
    },
    {
        "序号": 2624,
        "name": "Goldman Sachs North American Pi",
        "最新价": 41.75,
        "涨跌额": 0.37,
        "涨跌幅": 0.89,
        "开盘价": 41.75,
        "最高价": 41.75,
        "最低价": 41.75,
        "昨收价": 41.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 17.0,
        "成交额": 709.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GPOW"
    },
    {
        "序号": 2625,
        "name": "Zeta Global Holdings Corp-A",
        "最新价": 7.9,
        "涨跌额": 0.07,
        "涨跌幅": 0.89,
        "开盘价": 7.78,
        "最高价": 7.93,
        "最低价": 7.76,
        "昨收价": 7.83,
        "总市值": 1692792495.0,
        "市盈率": -8.3,
        "成交量": 1140185.0,
        "成交额": 8974012.0,
        "振幅": 2.17,
        "换手率": 0.53,
        "symbol": "106.ZETA"
    },
    {
        "序号": 2626,
        "name": "信息科技指数ETF-Vanguard",
        "最新价": 467.48,
        "涨跌额": 4.14,
        "涨跌幅": 0.89,
        "开盘价": 462.27,
        "最高价": 467.89,
        "最低价": 462.0,
        "昨收价": 463.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 323200.0,
        "成交额": 150558144.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.VGT"
    },
    {
        "序号": 2627,
        "name": "Fidelity MSCI Information Techn",
        "最新价": 138.99,
        "涨跌额": 1.23,
        "涨跌幅": 0.89,
        "开盘价": 137.4,
        "最高价": 139.1,
        "最低价": 137.25,
        "昨收价": 137.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 179934.0,
        "成交额": 24915345.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "107.FTEC"
    },
    {
        "序号": 2628,
        "name": "索尼",
        "最新价": 89.3,
        "涨跌额": 0.79,
        "涨跌幅": 0.89,
        "开盘价": 88.37,
        "最高价": 89.48,
        "最低价": 88.37,
        "昨收价": 88.51,
        "总市值": 109912583468.0,
        "市盈率": 18.02,
        "成交量": 647877.0,
        "成交额": 57809269.0,
        "振幅": 1.25,
        "换手率": 0.05,
        "symbol": "106.SONY"
    },
    {
        "序号": 2629,
        "name": "华盛顿联邦储蓄",
        "最新价": 30.56,
        "涨跌额": 0.27,
        "涨跌幅": 0.89,
        "开盘价": 30.22,
        "最高价": 30.92,
        "最低价": 30.22,
        "昨收价": 30.29,
        "总市值": 1980668992.0,
        "市盈率": 7.69,
        "成交量": 335387.0,
        "成交额": 10250211.0,
        "振幅": 2.31,
        "换手率": 0.52,
        "symbol": "105.WAFD"
    },
    {
        "序号": 2630,
        "name": "Pebblebrook Hotel Trust Series ",
        "最新价": 20.42,
        "涨跌额": 0.18,
        "涨跌幅": 0.89,
        "开盘价": 20.28,
        "最高价": 20.42,
        "最低价": 20.26,
        "昨收价": 20.24,
        "总市值": 122520000.0,
        "市盈率": null,
        "成交量": 679.0,
        "成交额": 13780.0,
        "振幅": 0.79,
        "换手率": 0.01,
        "symbol": "106.PEB_F"
    },
    {
        "序号": 2631,
        "name": "InterDigital Inc",
        "最新价": 106.64,
        "涨跌额": 0.94,
        "涨跌幅": 0.89,
        "开盘价": 105.82,
        "最高价": 108.22,
        "最低价": 105.82,
        "昨收价": 105.7,
        "总市值": 2740984556.0,
        "市盈率": 13.22,
        "成交量": 316941.0,
        "成交额": 33864805.0,
        "振幅": 2.27,
        "换手率": 1.23,
        "symbol": "105.IDCC"
    },
    {
        "序号": 2632,
        "name": "Goldman Sachs Access U.S. Small",
        "最新价": 59.03,
        "涨跌额": 0.52,
        "涨跌幅": 0.89,
        "开盘价": 58.54,
        "最高价": 59.16,
        "最低价": 58.54,
        "昨收价": 58.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 15000.0,
        "成交额": 883462.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.GSSC"
    },
    {
        "序号": 2633,
        "name": "Global X Millennial Consumer ET",
        "最新价": 34.07,
        "涨跌额": 0.3,
        "涨跌幅": 0.89,
        "开盘价": 33.6,
        "最高价": 34.16,
        "最低价": 33.6,
        "昨收价": 33.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 18149.0,
        "成交额": 616197.0,
        "振幅": 1.66,
        "换手率": null,
        "symbol": "105.MILN"
    },
    {
        "序号": 2634,
        "name": "维易科精密仪器",
        "最新价": 28.41,
        "涨跌额": 0.25,
        "涨跌幅": 0.89,
        "开盘价": 28.02,
        "最高价": 28.63,
        "最低价": 28.02,
        "昨收价": 28.16,
        "总市值": 1600668777.0,
        "市盈率": 20.81,
        "成交量": 249901.0,
        "成交额": 7107876.0,
        "振幅": 2.17,
        "换手率": 0.44,
        "symbol": "105.VECO"
    },
    {
        "序号": 2635,
        "name": "First Trust S-Network Streaming",
        "最新价": 22.74,
        "涨跌额": 0.2,
        "涨跌幅": 0.89,
        "开盘价": 22.52,
        "最高价": 22.74,
        "最低价": 22.52,
        "昨收价": 22.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 377.0,
        "成交额": 8490.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.BNGE"
    },
    {
        "序号": 2636,
        "name": "BlackRock Future Financial and ",
        "最新价": 21.62,
        "涨跌额": 0.19,
        "涨跌幅": 0.89,
        "开盘价": 21.62,
        "最高价": 21.62,
        "最低价": 21.62,
        "昨收价": 21.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 14.0,
        "成交额": 302.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BPAY"
    },
    {
        "序号": 2637,
        "name": "小型信息科技股ETF",
        "最新价": 44.38,
        "涨跌额": 0.39,
        "涨跌幅": 0.89,
        "开盘价": 43.75,
        "最高价": 44.46,
        "最低价": 43.75,
        "昨收价": 43.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 19766.0,
        "成交额": 873879.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "105.PSCT"
    },
    {
        "序号": 2638,
        "name": "Informatica Inc-A",
        "最新价": 27.33,
        "涨跌额": 0.24,
        "涨跌幅": 0.89,
        "开盘价": 26.79,
        "最高价": 27.72,
        "最低价": 26.52,
        "昨收价": 27.09,
        "总市值": 7956498259.0,
        "市盈率": -41.03,
        "成交量": 1367982.0,
        "成交额": 37465925.0,
        "振幅": 4.43,
        "换手率": 0.47,
        "symbol": "106.INFA"
    },
    {
        "序号": 2639,
        "name": "南方万通金控",
        "最新价": 54.69,
        "涨跌额": 0.48,
        "涨跌幅": 0.89,
        "开盘价": 53.91,
        "最高价": 55.52,
        "最低价": 52.55,
        "昨收价": 54.21,
        "总市值": 645085012.0,
        "市盈率": 8.35,
        "成交量": 15777.0,
        "成交额": 861544.0,
        "振幅": 5.48,
        "换手率": 0.13,
        "symbol": "105.GSBC"
    },
    {
        "序号": 2640,
        "name": "艾伯维",
        "最新价": 149.28,
        "涨跌额": 1.31,
        "涨跌幅": 0.89,
        "开盘价": 148.07,
        "最高价": 150.07,
        "最低价": 148.0,
        "昨收价": 147.97,
        "总市值": 263559426207.0,
        "市盈率": 40.46,
        "成交量": 5695196.0,
        "成交额": 851067520.0,
        "振幅": 1.4,
        "换手率": 0.32,
        "symbol": "106.ABBV"
    },
    {
        "序号": 2641,
        "name": "CubeSmart",
        "最新价": 42.18,
        "涨跌额": 0.37,
        "涨跌幅": 0.88,
        "开盘价": 41.94,
        "最高价": 42.47,
        "最低价": 41.81,
        "昨收价": 41.81,
        "总市值": 9484635546.0,
        "市盈率": 24.96,
        "成交量": 1641789.0,
        "成交额": 69273414.0,
        "振幅": 1.58,
        "换手率": 0.73,
        "symbol": "106.CUBE"
    },
    {
        "序号": 2642,
        "name": "美国独立银行",
        "最新价": 61.6,
        "涨跌额": 0.54,
        "涨跌幅": 0.88,
        "开盘价": 61.13,
        "最高价": 62.08,
        "最低价": 59.54,
        "昨收价": 61.06,
        "总市值": 2701363711.0,
        "市盈率": 10.32,
        "成交量": 137585.0,
        "成交额": 8473390.0,
        "振幅": 4.16,
        "换手率": 0.31,
        "symbol": "105.INDB"
    },
    {
        "序号": 2643,
        "name": "微软",
        "最新价": 374.23,
        "涨跌额": 3.28,
        "涨跌幅": 0.88,
        "开盘价": 369.2,
        "最高价": 374.46,
        "最低价": 368.23,
        "昨收价": 370.95,
        "总市值": 2781375531382.0,
        "市盈率": 36.08,
        "成交量": 20154366.0,
        "成交额": 7512310784.0,
        "振幅": 1.68,
        "换手率": 0.27,
        "symbol": "105.MSFT"
    },
    {
        "序号": 2644,
        "name": "海科航空-A",
        "最新价": 143.83,
        "涨跌额": 1.26,
        "涨跌幅": 0.88,
        "开盘价": 141.55,
        "最高价": 145.08,
        "最低价": 141.55,
        "昨收价": 142.57,
        "总市值": 19875504098.0,
        "市盈率": 50.02,
        "成交量": 287198.0,
        "成交额": 41273280.0,
        "振幅": 2.48,
        "换手率": 0.34,
        "symbol": "106.HEI_A"
    },
    {
        "序号": 2645,
        "name": "Hudson Pacific Properties Inc S",
        "最新价": 12.56,
        "涨跌额": 0.11,
        "涨跌幅": 0.88,
        "开盘价": 12.44,
        "最高价": 12.58,
        "最低价": 12.43,
        "昨收价": 12.45,
        "总市值": 213520000.0,
        "市盈率": null,
        "成交量": 65197.0,
        "成交额": 815220.0,
        "振幅": 1.2,
        "换手率": 0.38,
        "symbol": "106.HPP_C"
    },
    {
        "序号": 2646,
        "name": "Avantis U.S. Small Cap Value ET",
        "最新价": 83.55,
        "涨跌额": 0.73,
        "涨跌幅": 0.88,
        "开盘价": 82.82,
        "最高价": 83.89,
        "最低价": 82.8,
        "昨收价": 82.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 549311.0,
        "成交额": 45833352.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.AVUV"
    },
    {
        "序号": 2647,
        "name": "Fidelity Disruptive Finance ETF",
        "最新价": 27.48,
        "涨跌额": 0.24,
        "涨跌幅": 0.88,
        "开盘价": 27.2,
        "最高价": 27.48,
        "最低价": 27.2,
        "昨收价": 27.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 3061.0,
        "成交额": 83814.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "105.FDFF"
    },
    {
        "序号": 2648,
        "name": "巴西国家钢铁",
        "最新价": 3.44,
        "涨跌额": 0.03,
        "涨跌幅": 0.88,
        "开盘价": 3.39,
        "最高价": 3.46,
        "最低价": 3.39,
        "昨收价": 3.41,
        "总市值": 4561763178.0,
        "市盈率": -23.44,
        "成交量": 1598033.0,
        "成交额": 5479272.0,
        "振幅": 2.05,
        "换手率": 0.12,
        "symbol": "106.SID"
    },
    {
        "序号": 2649,
        "name": "Hammerhead Energy Inc-A",
        "最新价": 14.91,
        "涨跌额": 0.13,
        "涨跌幅": 0.88,
        "开盘价": 14.81,
        "最高价": 14.99,
        "最低价": 14.81,
        "昨收价": 14.78,
        "总市值": 1430421475.0,
        "市盈率": null,
        "成交量": 14867.0,
        "成交额": 221688.0,
        "振幅": 1.22,
        "换手率": 0.02,
        "symbol": "105.HHRS"
    },
    {
        "序号": 2650,
        "name": "Sanara MedTech Inc",
        "最新价": 35.56,
        "涨跌额": 0.31,
        "涨跌幅": 0.88,
        "开盘价": 35.08,
        "最高价": 36.82,
        "最低价": 35.01,
        "昨收价": 35.25,
        "总市值": 303628669.0,
        "市盈率": -37.15,
        "成交量": 15331.0,
        "成交额": 549796.0,
        "振幅": 5.13,
        "换手率": 0.18,
        "symbol": "105.SMTI"
    },
    {
        "序号": 2651,
        "name": "Invesco S&P MidCap Momentum ETF",
        "最新价": 84.97,
        "涨跌额": 0.74,
        "涨跌幅": 0.88,
        "开盘价": 84.36,
        "最高价": 85.24,
        "最低价": 84.36,
        "昨收价": 84.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 40169.0,
        "成交额": 3409250.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "107.XMMO"
    },
    {
        "序号": 2652,
        "name": "Beacon Roofing Supply Inc",
        "最新价": 80.5,
        "涨跌额": 0.7,
        "涨跌幅": 0.88,
        "开盘价": 80.25,
        "最高价": 80.82,
        "最低价": 79.6,
        "昨收价": 79.8,
        "总市值": 5095772682.0,
        "市盈率": 12.33,
        "成交量": 996450.0,
        "成交额": 80100354.0,
        "振幅": 1.53,
        "换手率": 1.57,
        "symbol": "105.BECN"
    },
    {
        "序号": 2653,
        "name": "VanEck BDC Income ETF",
        "最新价": 16.11,
        "涨跌额": 0.14,
        "涨跌幅": 0.88,
        "开盘价": 15.96,
        "最高价": 16.12,
        "最低价": 15.94,
        "昨收价": 15.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 540293.0,
        "成交额": 8679343.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.BIZD"
    },
    {
        "序号": 2654,
        "name": "第一金融",
        "最新价": 21.87,
        "涨跌额": 0.19,
        "涨跌幅": 0.88,
        "开盘价": 21.74,
        "最高价": 22.1,
        "最低价": 21.48,
        "昨收价": 21.68,
        "总市值": 781475658.0,
        "市盈率": 6.71,
        "成交量": 144288.0,
        "成交额": 3161709.0,
        "振幅": 2.86,
        "换手率": 0.4,
        "symbol": "105.PFC"
    },
    {
        "序号": 2655,
        "name": "Bioceres Crop Solutions Corp",
        "最新价": 12.68,
        "涨跌额": 0.11,
        "涨跌幅": 0.88,
        "开盘价": 12.57,
        "最高价": 12.74,
        "最低价": 12.49,
        "昨收价": 12.57,
        "总市值": 796937962.0,
        "市盈率": 42.44,
        "成交量": 51462.0,
        "成交额": 650602.0,
        "振幅": 1.99,
        "换手率": 0.08,
        "symbol": "105.BIOX"
    },
    {
        "序号": 2656,
        "name": "Great Elm Capital Corp Notes",
        "最新价": 24.27,
        "涨跌额": 0.21,
        "涨跌幅": 0.87,
        "开盘价": 24.05,
        "最高价": 24.45,
        "最低价": 24.0,
        "昨收价": 24.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1573.0,
        "成交额": 37972.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "105.GECCO"
    },
    {
        "序号": 2657,
        "name": "LB福斯特",
        "最新价": 19.65,
        "涨跌额": 0.17,
        "涨跌幅": 0.87,
        "开盘价": 19.64,
        "最高价": 19.83,
        "最低价": 19.27,
        "昨收价": 19.48,
        "总市值": 217646701.0,
        "市盈率": -5.18,
        "成交量": 36469.0,
        "成交额": 711948.0,
        "振幅": 2.87,
        "换手率": 0.33,
        "symbol": "105.FSTR"
    },
    {
        "序号": 2658,
        "name": "马丁-玛丽埃塔材料",
        "最新价": 469.39,
        "涨跌额": 4.06,
        "涨跌幅": 0.87,
        "开盘价": 463.54,
        "最高价": 469.67,
        "最低价": 463.54,
        "昨收价": 465.33,
        "总市值": 29011663302.0,
        "市盈率": 27.11,
        "成交量": 254591.0,
        "成交额": 119214288.0,
        "振幅": 1.32,
        "换手率": 0.41,
        "symbol": "106.MLM"
    },
    {
        "序号": 2659,
        "name": "First Trust Germany AlphaDEX Fu",
        "最新价": 38.18,
        "涨跌额": 0.33,
        "涨跌幅": 0.87,
        "开盘价": 38.18,
        "最高价": 38.18,
        "最低价": 38.18,
        "昨收价": 37.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 226.0,
        "成交额": 8628.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FGM"
    },
    {
        "序号": 2660,
        "name": "赫尔默里奇&佩恩",
        "最新价": 35.94,
        "涨跌额": 0.31,
        "涨跌幅": 0.87,
        "开盘价": 36.09,
        "最高价": 37.02,
        "最低价": 35.8,
        "昨收价": 35.63,
        "总市值": 3573402067.0,
        "市盈率": 8.23,
        "成交量": 1316526.0,
        "成交额": 47638855.0,
        "振幅": 3.42,
        "换手率": 1.32,
        "symbol": "106.HP"
    },
    {
        "序号": 2661,
        "name": "Sterling Capital Focus Equity E",
        "最新价": 26.69,
        "涨跌额": 0.23,
        "涨跌幅": 0.87,
        "开盘价": 26.46,
        "最高价": 26.71,
        "最低价": 26.46,
        "昨收价": 26.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 3720.0,
        "成交额": 99245.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.LCG"
    },
    {
        "序号": 2662,
        "name": "Global X Guru Index ETF",
        "最新价": 38.32,
        "涨跌额": 0.33,
        "涨跌幅": 0.87,
        "开盘价": 38.22,
        "最高价": 38.35,
        "最低价": 38.22,
        "昨收价": 37.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 2531.0,
        "成交额": 96891.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.GURU"
    },
    {
        "序号": 2663,
        "name": "火神材料",
        "最新价": 216.03,
        "涨跌额": 1.86,
        "涨跌幅": 0.87,
        "开盘价": 213.73,
        "最高价": 216.83,
        "最低价": 212.67,
        "昨收价": 214.17,
        "总市值": 28704637794.0,
        "市盈率": 34.79,
        "成交量": 563573.0,
        "成交额": 121581844.0,
        "振幅": 1.94,
        "换手率": 0.42,
        "symbol": "106.VMC"
    },
    {
        "序号": 2664,
        "name": "加拿大帝国商业银行",
        "最新价": 43.02,
        "涨跌额": 0.37,
        "涨跌幅": 0.87,
        "开盘价": 42.6,
        "最高价": 43.21,
        "最低价": 42.6,
        "昨收价": 42.65,
        "总市值": 40055009331.0,
        "市盈率": 11.03,
        "成交量": 1153264.0,
        "成交额": 49528815.0,
        "振幅": 1.43,
        "换手率": 0.12,
        "symbol": "106.CM"
    },
    {
        "序号": 2665,
        "name": "瑞穗金融",
        "最新价": 3.49,
        "涨跌额": 0.03,
        "涨跌幅": 0.87,
        "开盘价": 3.45,
        "最高价": 3.49,
        "最低价": 3.44,
        "昨收价": 3.46,
        "总市值": 44232683058.0,
        "市盈率": 116.62,
        "成交量": 684921.0,
        "成交额": 2379203.0,
        "振幅": 1.45,
        "换手率": 0.01,
        "symbol": "106.MFG"
    },
    {
        "序号": 2666,
        "name": "ETRACS 2x Leveraged MSCI US ESG",
        "最新价": 22.11,
        "涨跌额": 0.19,
        "涨跌幅": 0.87,
        "开盘价": 22.11,
        "最高价": 22.11,
        "最低价": 22.11,
        "昨收价": 21.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ESUS"
    },
    {
        "序号": 2667,
        "name": "AB Disruptors ETF",
        "最新价": 58.34,
        "涨跌额": 0.5,
        "涨跌幅": 0.86,
        "开盘价": 58.05,
        "最高价": 58.46,
        "最低价": 57.88,
        "昨收价": 57.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 83693.0,
        "成交额": 4856767.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.FWD"
    },
    {
        "序号": 2668,
        "name": "Horizon Technology Finance Corp",
        "最新价": 12.84,
        "涨跌额": 0.11,
        "涨跌幅": 0.86,
        "开盘价": 12.71,
        "最高价": 12.84,
        "最低价": 12.7,
        "昨收价": 12.73,
        "总市值": 427963992.0,
        "市盈率": -170.84,
        "成交量": 133422.0,
        "成交额": 1703256.0,
        "振幅": 1.1,
        "换手率": 0.4,
        "symbol": "105.HRZN"
    },
    {
        "序号": 2669,
        "name": "Integral Ad Science Holding Cor",
        "最新价": 14.03,
        "涨跌额": 0.12,
        "涨跌幅": 0.86,
        "开盘价": 13.77,
        "最高价": 14.07,
        "最低价": 13.67,
        "昨收价": 13.91,
        "总市值": 2220706127.0,
        "市盈率": 259.43,
        "成交量": 1172884.0,
        "成交额": 16399944.0,
        "振幅": 2.88,
        "换手率": 0.74,
        "symbol": "105.IAS"
    },
    {
        "序号": 2670,
        "name": "The Gorman-Rupp Co",
        "最新价": 33.91,
        "涨跌额": 0.29,
        "涨跌幅": 0.86,
        "开盘价": 33.71,
        "最高价": 34.11,
        "最低价": 33.58,
        "昨收价": 33.62,
        "总市值": 888238472.0,
        "市盈率": 31.27,
        "成交量": 42726.0,
        "成交额": 1448319.0,
        "振幅": 1.58,
        "换手率": 0.16,
        "symbol": "106.GRC"
    },
    {
        "序号": 2671,
        "name": "Biora Therapeutics Inc",
        "最新价": 1.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.86,
        "开盘价": 1.16,
        "最高价": 1.18,
        "最低价": 1.12,
        "昨收价": 1.16,
        "总市值": 27733296.0,
        "市盈率": -0.23,
        "成交量": 307455.0,
        "成交额": 353556.0,
        "振幅": 5.17,
        "换手率": 1.3,
        "symbol": "105.BIOR"
    },
    {
        "序号": 2672,
        "name": "阿根廷电信",
        "最新价": 8.2,
        "涨跌额": 0.07,
        "涨跌幅": 0.86,
        "开盘价": 8.08,
        "最高价": 8.28,
        "最低价": 8.06,
        "昨收价": 8.13,
        "总市值": 3532048338.0,
        "市盈率": -2.16,
        "成交量": 67397.0,
        "成交额": 552526.0,
        "振幅": 2.71,
        "换手率": 0.02,
        "symbol": "106.TEO"
    },
    {
        "序号": 2673,
        "name": "First Trust Dorsey Wright Momen",
        "最新价": 29.29,
        "涨跌额": 0.25,
        "涨跌幅": 0.86,
        "开盘价": 29.27,
        "最高价": 29.29,
        "最低价": 29.15,
        "昨收价": 29.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 4060.0,
        "成交额": 118724.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "105.DDIV"
    },
    {
        "序号": 2674,
        "name": "Janus Henderson Small\/Mid Cap G",
        "最新价": 60.94,
        "涨跌额": 0.52,
        "涨跌幅": 0.86,
        "开盘价": 60.17,
        "最高价": 61.3,
        "最低价": 60.17,
        "昨收价": 60.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 18189.0,
        "成交额": 1108191.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "105.JSMD"
    },
    {
        "序号": 2675,
        "name": "Fidelity Metaverse ETF",
        "最新价": 26.97,
        "涨跌额": 0.23,
        "涨跌幅": 0.86,
        "开盘价": 26.7,
        "最高价": 27.02,
        "最低价": 26.7,
        "昨收价": 26.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 5093.0,
        "成交额": 137115.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "105.FMET"
    },
    {
        "序号": 2676,
        "name": "Putnam BDC Income ETF",
        "最新价": 31.69,
        "涨跌额": 0.27,
        "涨跌幅": 0.86,
        "开盘价": 31.47,
        "最高价": 31.74,
        "最低价": 31.47,
        "昨收价": 31.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 12240.0,
        "成交额": 387272.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.PBDC"
    },
    {
        "序号": 2677,
        "name": "Mirion Technologies Inc-A",
        "最新价": 9.39,
        "涨跌额": 0.08,
        "涨跌幅": 0.86,
        "开盘价": 9.24,
        "最高价": 9.51,
        "最低价": 9.15,
        "昨收价": 9.31,
        "总市值": 2120305551.0,
        "市盈率": -9.01,
        "成交量": 1359128.0,
        "成交额": 12775751.0,
        "振幅": 3.87,
        "换手率": 0.6,
        "symbol": "106.MIR"
    },
    {
        "序号": 2678,
        "name": "Prospect Capital Corp Series A ",
        "最新价": 16.44,
        "涨跌额": 0.14,
        "涨跌幅": 0.86,
        "开盘价": 16.3,
        "最高价": 16.46,
        "最低价": 16.3,
        "昨收价": 16.3,
        "总市值": 97001672.0,
        "市盈率": null,
        "成交量": 12245.0,
        "成交额": 200730.0,
        "振幅": 0.98,
        "换手率": 0.21,
        "symbol": "106.PSEC_A"
    },
    {
        "序号": 2679,
        "name": "ProShares Ether Strategy ETF",
        "最新价": 56.4,
        "涨跌额": 0.48,
        "涨跌幅": 0.86,
        "开盘价": 55.92,
        "最高价": 56.51,
        "最低价": 55.81,
        "昨收价": 55.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 27355.0,
        "成交额": 1536140.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.EETH"
    },
    {
        "序号": 2680,
        "name": "Sigma Lithium Corp",
        "最新价": 29.43,
        "涨跌额": 0.25,
        "涨跌幅": 0.86,
        "开盘价": 29.61,
        "最高价": 30.42,
        "最低价": 29.42,
        "昨收价": 29.18,
        "总市值": 3222585000.0,
        "市盈率": -41.04,
        "成交量": 877844.0,
        "成交额": 26111426.0,
        "振幅": 3.43,
        "换手率": 0.8,
        "symbol": "105.SGML"
    },
    {
        "序号": 2681,
        "name": "半导体ETF-VanEck Vectors",
        "最新价": 162.48,
        "涨跌额": 1.38,
        "涨跌幅": 0.86,
        "开盘价": 160.84,
        "最高价": 162.94,
        "最低价": 160.66,
        "昨收价": 161.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 5683682.0,
        "成交额": 922155664.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "105.SMH"
    },
    {
        "序号": 2682,
        "name": "Optimize AI Smart Sentiment Eve",
        "最新价": 29.44,
        "涨跌额": 0.25,
        "涨跌幅": 0.86,
        "开盘价": 29.44,
        "最高价": 29.44,
        "最低价": 29.44,
        "昨收价": 29.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 12.0,
        "成交额": 353.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OAIE"
    },
    {
        "序号": 2683,
        "name": "Nuveen Growth Opportunities ETF",
        "最新价": 24.75,
        "涨跌额": 0.21,
        "涨跌幅": 0.86,
        "开盘价": 24.45,
        "最高价": 24.75,
        "最低价": 24.45,
        "昨收价": 24.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 11077.0,
        "成交额": 272628.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.NUGO"
    },
    {
        "序号": 2684,
        "name": "Appian Corp-A",
        "最新价": 36.54,
        "涨跌额": 0.31,
        "涨跌幅": 0.86,
        "开盘价": 35.79,
        "最高价": 36.7,
        "最低价": 35.38,
        "昨收价": 36.23,
        "总市值": 2675754811.0,
        "市盈率": -19.7,
        "成交量": 289999.0,
        "成交额": 10546767.0,
        "振幅": 3.64,
        "换手率": 0.4,
        "symbol": "105.APPN"
    },
    {
        "序号": 2685,
        "name": "Invesco S&P MidCap 400 Pure Val",
        "最新价": 107.31,
        "涨跌额": 0.91,
        "涨跌幅": 0.86,
        "开盘价": 106.58,
        "最高价": 107.59,
        "最低价": 106.56,
        "昨收价": 106.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 18698.0,
        "成交额": 2000705.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.RFV"
    },
    {
        "序号": 2686,
        "name": "Invesco KBW High Dividend Yield",
        "最新价": 15.33,
        "涨跌额": 0.13,
        "涨跌幅": 0.86,
        "开盘价": 15.18,
        "最高价": 15.34,
        "最低价": 15.14,
        "昨收价": 15.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 96784.0,
        "成交额": 1478900.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "105.KBWD"
    },
    {
        "序号": 2687,
        "name": "Precigen Inc",
        "最新价": 1.18,
        "涨跌额": 0.01,
        "涨跌幅": 0.85,
        "开盘价": 1.15,
        "最高价": 1.21,
        "最低价": 1.14,
        "昨收价": 1.17,
        "总市值": 293724533.0,
        "市盈率": -3.45,
        "成交量": 832995.0,
        "成交额": 973340.0,
        "振幅": 5.98,
        "换手率": 0.33,
        "symbol": "105.PGEN"
    },
    {
        "序号": 2688,
        "name": "Global X Green Building ETF",
        "最新价": 22.42,
        "涨跌额": 0.19,
        "涨跌幅": 0.85,
        "开盘价": 22.42,
        "最高价": 22.42,
        "最低价": 22.42,
        "昨收价": 22.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 14.0,
        "成交额": 313.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GRNR"
    },
    {
        "序号": 2689,
        "name": "易康",
        "最新价": 8.26,
        "涨跌额": 0.07,
        "涨跌幅": 0.85,
        "开盘价": 8.19,
        "最高价": 8.46,
        "最低价": 8.14,
        "昨收价": 8.19,
        "总市值": 234293421.0,
        "市盈率": -3.92,
        "成交量": 194002.0,
        "成交额": 1605099.0,
        "振幅": 3.91,
        "换手率": 0.68,
        "symbol": "105.EHTH"
    },
    {
        "序号": 2690,
        "name": "第一招商股份",
        "最新价": 34.24,
        "涨跌额": 0.29,
        "涨跌幅": 0.85,
        "开盘价": 34.17,
        "最高价": 34.55,
        "最低价": 33.97,
        "昨收价": 33.95,
        "总市值": 2049158609.0,
        "市盈率": 8.13,
        "成交量": 179987.0,
        "成交额": 6165352.0,
        "振幅": 1.71,
        "换手率": 0.3,
        "symbol": "105.FRME"
    },
    {
        "序号": 2691,
        "name": "科技ETF-SPDR",
        "最新价": 186.66,
        "涨跌额": 1.58,
        "涨跌幅": 0.85,
        "开盘价": 184.68,
        "最高价": 186.81,
        "最低价": 184.45,
        "昨收价": 185.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 6404425.0,
        "成交额": 1191303008.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "107.XLK"
    },
    {
        "序号": 2692,
        "name": "Davis Select Worldwide ETF",
        "最新价": 28.37,
        "涨跌额": 0.24,
        "涨跌幅": 0.85,
        "开盘价": 28.1,
        "最高价": 28.39,
        "最低价": 28.1,
        "昨收价": 28.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 15683.0,
        "成交额": 443860.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.DWLD"
    },
    {
        "序号": 2693,
        "name": "AlphaMark Actively Managed Smal",
        "最新价": 27.19,
        "涨跌额": 0.23,
        "涨跌幅": 0.85,
        "开盘价": 27.22,
        "最高价": 27.24,
        "最低价": 27.09,
        "昨收价": 26.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 2561.0,
        "成交额": 69683.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.SMCP"
    },
    {
        "序号": 2694,
        "name": "Vanguard Russell 2000 Growth ET",
        "最新价": 170.24,
        "涨跌额": 1.44,
        "涨跌幅": 0.85,
        "开盘价": 168.61,
        "最高价": 170.9,
        "最低价": 168.61,
        "昨收价": 168.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 19812.0,
        "成交额": 3369824.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "105.VTWG"
    },
    {
        "序号": 2695,
        "name": "Goldman Sachs Hedge Industry VI",
        "最新价": 93.5,
        "涨跌额": 0.79,
        "涨跌幅": 0.85,
        "开盘价": 92.74,
        "最高价": 93.59,
        "最低价": 92.74,
        "昨收价": 92.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 6691.0,
        "成交额": 624225.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.GVIP"
    },
    {
        "序号": 2696,
        "name": "ProShares Ultra MidCap400",
        "最新价": 52.11,
        "涨跌额": 0.44,
        "涨跌幅": 0.85,
        "开盘价": 51.45,
        "最高价": 52.47,
        "最低价": 51.45,
        "昨收价": 51.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 8498.0,
        "成交额": 442558.0,
        "振幅": 1.97,
        "换手率": null,
        "symbol": "107.MVV"
    },
    {
        "序号": 2697,
        "name": "Amplify Inflation Fighter ETF",
        "最新价": 26.14,
        "涨跌额": 0.22,
        "涨跌幅": 0.85,
        "开盘价": 26.05,
        "最高价": 26.14,
        "最低价": 26.05,
        "昨收价": 25.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 640.0,
        "成交额": 16674.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.IWIN"
    },
    {
        "序号": 2698,
        "name": "WisdomTree U.S. SmallCap Fund",
        "最新价": 46.34,
        "涨跌额": 0.39,
        "涨跌幅": 0.85,
        "开盘价": 45.96,
        "最高价": 46.54,
        "最低价": 45.96,
        "昨收价": 45.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 51168.0,
        "成交额": 2377166.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.EES"
    },
    {
        "序号": 2699,
        "name": "Invesco S&P SmallCap 600 Pure V",
        "最新价": 98.67,
        "涨跌额": 0.83,
        "涨跌幅": 0.85,
        "开盘价": 97.89,
        "最高价": 98.92,
        "最低价": 97.89,
        "昨收价": 97.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 6890.0,
        "成交额": 677418.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.RZV"
    },
    {
        "序号": 2700,
        "name": "德尼克斯投资",
        "最新价": 11.89,
        "涨跌额": 0.1,
        "涨跌幅": 0.85,
        "开盘价": 11.72,
        "最高价": 11.9,
        "最低价": 11.65,
        "昨收价": 11.79,
        "总市值": 673511048.0,
        "市盈率": 52.4,
        "成交量": 748129.0,
        "成交额": 8820391.0,
        "振幅": 2.12,
        "换手率": 1.32,
        "symbol": "106.DX"
    },
    {
        "序号": 2701,
        "name": "iShares Copper and Metals Minin",
        "最新价": 23.8,
        "涨跌额": 0.2,
        "涨跌幅": 0.85,
        "开盘价": 23.39,
        "最高价": 23.8,
        "最低价": 23.39,
        "昨收价": 23.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1429.0,
        "成交额": 33810.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "105.ICOP"
    },
    {
        "序号": 2702,
        "name": "The Gabelli Convertible and Inc",
        "最新价": 3.57,
        "涨跌额": 0.03,
        "涨跌幅": 0.85,
        "开盘价": 3.48,
        "最高价": 3.59,
        "最低价": 3.48,
        "昨收价": 3.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 72201.0,
        "成交额": 255530.0,
        "振幅": 3.11,
        "换手率": null,
        "symbol": "106.GCV"
    },
    {
        "序号": 2703,
        "name": "Morgan Stanley Emerging Markets",
        "最新价": 4.76,
        "涨跌额": 0.04,
        "涨跌幅": 0.85,
        "开盘价": 4.72,
        "最高价": 4.76,
        "最低价": 4.72,
        "昨收价": 4.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 124641.0,
        "成交额": 590996.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "106.EDD"
    },
    {
        "序号": 2704,
        "name": "VanEck Commodity Strategy ETF",
        "最新价": 48.84,
        "涨跌额": 0.41,
        "涨跌幅": 0.85,
        "开盘价": 48.62,
        "最高价": 48.84,
        "最低价": 48.62,
        "昨收价": 48.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 756.0,
        "成交额": 36759.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.PIT"
    },
    {
        "序号": 2705,
        "name": "ProShares Ultra Industrials",
        "最新价": 26.25,
        "涨跌额": 0.22,
        "涨跌幅": 0.85,
        "开盘价": 26.39,
        "最高价": 26.39,
        "最低价": 26.14,
        "昨收价": 26.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1142.0,
        "成交额": 30043.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.UXI"
    },
    {
        "序号": 2706,
        "name": "美国理疗保健",
        "最新价": 89.57,
        "涨跌额": 0.75,
        "涨跌幅": 0.84,
        "开盘价": 88.6,
        "最高价": 90.34,
        "最低价": 88.12,
        "昨收价": 88.82,
        "总市值": 1342389352.0,
        "市盈率": 44.46,
        "成交量": 57694.0,
        "成交额": 5166628.0,
        "振幅": 2.5,
        "换手率": 0.38,
        "symbol": "106.USPH"
    },
    {
        "序号": 2707,
        "name": "Direxion Daily S&P 500 Bull 2X ",
        "最新价": 96.74,
        "涨跌额": 0.81,
        "涨跌幅": 0.84,
        "开盘价": 95.68,
        "最高价": 96.92,
        "最低价": 95.68,
        "昨收价": 95.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 22435.0,
        "成交额": 2157625.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "107.SPUU"
    },
    {
        "序号": 2708,
        "name": "Wilhelmina国际",
        "最新价": 4.78,
        "涨跌额": 0.04,
        "涨跌幅": 0.84,
        "开盘价": 4.59,
        "最高价": 4.78,
        "最低价": 4.59,
        "昨收价": 4.74,
        "总市值": 24652104.0,
        "市盈率": 50.72,
        "成交量": 653.0,
        "成交额": 3039.0,
        "振幅": 4.01,
        "换手率": 0.01,
        "symbol": "105.WHLM"
    },
    {
        "序号": 2709,
        "name": "YieldMax JPM Option Income Stra",
        "最新价": 19.12,
        "涨跌额": 0.16,
        "涨跌幅": 0.84,
        "开盘价": 19.06,
        "最高价": 19.14,
        "最低价": 19.0,
        "昨收价": 18.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 10228.0,
        "成交额": 194813.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.JPMO"
    },
    {
        "序号": 2710,
        "name": "SP Funds S&P Global Technology ",
        "最新价": 20.34,
        "涨跌额": 0.17,
        "涨跌幅": 0.84,
        "开盘价": 20.12,
        "最高价": 20.35,
        "最低价": 20.12,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 63684.0,
        "成交额": 1292318.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "107.SPTE"
    },
    {
        "序号": 2711,
        "name": "Select Water Solutions Inc-A",
        "最新价": 7.18,
        "涨跌额": 0.06,
        "涨跌幅": 0.84,
        "开盘价": 7.16,
        "最高价": 7.22,
        "最低价": 7.11,
        "昨收价": 7.12,
        "总市值": 862590890.0,
        "市盈率": 15.84,
        "成交量": 818475.0,
        "成交额": 5870785.0,
        "振幅": 1.54,
        "换手率": 0.68,
        "symbol": "106.WTTR"
    },
    {
        "序号": 2712,
        "name": "iShares Edge MSCI USA Momentum ",
        "最新价": 150.8,
        "涨跌额": 1.26,
        "涨跌幅": 0.84,
        "开盘价": 148.96,
        "最高价": 150.9,
        "最低价": 148.96,
        "昨收价": 149.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 200736.0,
        "成交额": 30159897.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "107.MTUM"
    },
    {
        "序号": 2713,
        "name": "Special Opportunities Fund Inc ",
        "最新价": 22.74,
        "涨跌额": 0.19,
        "涨跌幅": 0.84,
        "开盘价": 22.55,
        "最高价": 22.74,
        "最低价": 22.55,
        "昨收价": 22.55,
        "总市值": 57818565.0,
        "市盈率": null,
        "成交量": 1027.0,
        "成交额": 23193.0,
        "振幅": 0.84,
        "换手率": 0.04,
        "symbol": "106.SPE_C"
    },
    {
        "序号": 2714,
        "name": "美国力特保险丝",
        "最新价": 243.07,
        "涨跌额": 2.03,
        "涨跌幅": 0.84,
        "开盘价": 240.3,
        "最高价": 244.99,
        "最低价": 240.3,
        "昨收价": 241.04,
        "总市值": 6053151792.0,
        "市盈率": 19.53,
        "成交量": 60603.0,
        "成交额": 14742152.0,
        "振幅": 1.95,
        "换手率": 0.24,
        "symbol": "105.LFUS"
    },
    {
        "序号": 2715,
        "name": "Vanguard U.S. Value Factor ETF",
        "最新价": 106.6,
        "涨跌额": 0.89,
        "涨跌幅": 0.84,
        "开盘价": 105.63,
        "最高价": 106.75,
        "最低价": 105.63,
        "昨收价": 105.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 10229.0,
        "成交额": 1086060.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.VFVA"
    },
    {
        "序号": 2716,
        "name": "First Trust S-Network E-Commerc",
        "最新价": 27.55,
        "涨跌额": 0.23,
        "涨跌幅": 0.84,
        "开盘价": 27.55,
        "最高价": 27.55,
        "最低价": 27.55,
        "昨收价": 27.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 14.0,
        "成交额": 385.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ISHP"
    },
    {
        "序号": 2717,
        "name": "GraniteShares 1.75x Long TSLA D",
        "最新价": 25.19,
        "涨跌额": 0.21,
        "涨跌幅": 0.84,
        "开盘价": 24.52,
        "最高价": 25.4,
        "最低价": 24.52,
        "昨收价": 24.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 29569.0,
        "成交额": 743276.0,
        "振幅": 3.52,
        "换手率": null,
        "symbol": "105.TSLR"
    },
    {
        "序号": 2718,
        "name": "WisdomTree Battery Value Chain ",
        "最新价": 29.99,
        "涨跌额": 0.25,
        "涨跌幅": 0.84,
        "开盘价": 29.8,
        "最高价": 29.99,
        "最低价": 29.8,
        "昨收价": 29.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 602.0,
        "成交额": 17939.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.WBAT"
    },
    {
        "序号": 2719,
        "name": "Goldenstone Acquisition Ltd",
        "最新价": 10.8,
        "涨跌额": 0.09,
        "涨跌幅": 0.84,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.76,
        "昨收价": 10.71,
        "总市值": 82039500.0,
        "市盈率": 100.73,
        "成交量": 104417.0,
        "成交额": 1127682.0,
        "振幅": 0.37,
        "换手率": 1.37,
        "symbol": "105.GDST"
    },
    {
        "序号": 2720,
        "name": "Citizens Financial Group Inc Se",
        "最新价": 19.2,
        "涨跌额": 0.16,
        "涨跌幅": 0.84,
        "开盘价": 19.03,
        "最高价": 19.2,
        "最低价": 18.83,
        "昨收价": 19.04,
        "总市值": 8640000.0,
        "市盈率": null,
        "成交量": 63244.0,
        "成交额": 1199108.0,
        "振幅": 1.94,
        "换手率": 14.05,
        "symbol": "106.CFG_E"
    },
    {
        "序号": 2721,
        "name": "Invesco S&P Spin-Off ETF",
        "最新价": 60.02,
        "涨跌额": 0.5,
        "涨跌幅": 0.84,
        "开盘价": 59.65,
        "最高价": 60.02,
        "最低价": 59.65,
        "昨收价": 59.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 8360.0,
        "成交额": 500158.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.CSD"
    },
    {
        "序号": 2722,
        "name": "Global X Metaverse ETF",
        "最新价": 25.21,
        "涨跌额": 0.21,
        "涨跌幅": 0.84,
        "开盘价": 25.21,
        "最高价": 25.21,
        "最低价": 25.21,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 154.0,
        "成交额": 3882.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.VR"
    },
    {
        "序号": 2723,
        "name": "TriplePoint Venture Growth BDC ",
        "最新价": 10.82,
        "涨跌额": 0.09,
        "涨跌幅": 0.84,
        "开盘价": 10.78,
        "最高价": 10.84,
        "最低价": 10.74,
        "昨收价": 10.73,
        "总市值": 390449027.0,
        "市盈率": -17.11,
        "成交量": 379278.0,
        "成交额": 4087816.0,
        "振幅": 0.93,
        "换手率": 1.05,
        "symbol": "106.TPVG"
    },
    {
        "序号": 2724,
        "name": "ProShares Online Retail ETF",
        "最新价": 33.7,
        "涨跌额": 0.28,
        "涨跌幅": 0.84,
        "开盘价": 33.24,
        "最高价": 33.76,
        "最低价": 33.24,
        "昨收价": 33.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 10155.0,
        "成交额": 339992.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "107.ONLN"
    },
    {
        "序号": 2725,
        "name": "US Cellular Corp Notes",
        "最新价": 19.26,
        "涨跌额": 0.16,
        "涨跌幅": 0.84,
        "开盘价": 19.16,
        "最高价": 19.29,
        "最低价": 19.16,
        "昨收价": 19.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 96563.0,
        "成交额": 1855948.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "106.UZD"
    },
    {
        "序号": 2726,
        "name": "Global X Brazil Active ETF",
        "最新价": 27.71,
        "涨跌额": 0.23,
        "涨跌幅": 0.84,
        "开盘价": 27.71,
        "最高价": 27.71,
        "最低价": 27.71,
        "昨收价": 27.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 51.0,
        "成交额": 1413.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BRAZ"
    },
    {
        "序号": 2727,
        "name": "Popular Inc Trust Preferred Sec",
        "最新价": 25.31,
        "涨跌额": 0.21,
        "涨跌幅": 0.84,
        "开盘价": 25.53,
        "最高价": 25.53,
        "最低价": 25.2,
        "昨收价": 25.1,
        "总市值": 1826226750.0,
        "市盈率": 2.59,
        "成交量": 2139.0,
        "成交额": 54100.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "105.BPOPM"
    },
    {
        "序号": 2728,
        "name": "迪士尼",
        "最新价": 92.82,
        "涨跌额": 0.77,
        "涨跌幅": 0.84,
        "开盘价": 92.06,
        "最高价": 93.54,
        "最低价": 92.06,
        "昨收价": 92.05,
        "总市值": 169889923787.0,
        "市盈率": 72.17,
        "成交量": 9498155.0,
        "成交额": 881742848.0,
        "振幅": 1.61,
        "换手率": 0.52,
        "symbol": "106.DIS"
    },
    {
        "序号": 2729,
        "name": "核能ETF-VanEck Vectors",
        "最新价": 73.63,
        "涨跌额": 0.61,
        "涨跌幅": 0.84,
        "开盘价": 72.71,
        "最高价": 73.65,
        "最低价": 72.13,
        "昨收价": 73.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 30382.0,
        "成交额": 2216473.0,
        "振幅": 2.08,
        "换手率": null,
        "symbol": "107.NLR"
    },
    {
        "序号": 2730,
        "name": "Trinity Industries Inc",
        "最新价": 26.56,
        "涨跌额": 0.22,
        "涨跌幅": 0.84,
        "开盘价": 26.35,
        "最高价": 26.84,
        "最低价": 26.35,
        "昨收价": 26.34,
        "总市值": 2174506854.0,
        "市盈率": 29.19,
        "成交量": 284399.0,
        "成交额": 7554968.0,
        "振幅": 1.86,
        "换手率": 0.35,
        "symbol": "106.TRN"
    },
    {
        "序号": 2731,
        "name": "Invesco Zacks Multi-Asset Incom",
        "最新价": 22.94,
        "涨跌额": 0.19,
        "涨跌幅": 0.84,
        "开盘价": 22.75,
        "最高价": 22.94,
        "最低价": 22.75,
        "昨收价": 22.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 9576.0,
        "成交额": 218832.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.CVY"
    },
    {
        "序号": 2732,
        "name": "First Trust Dorsey Wright Focus",
        "最新价": 48.3,
        "涨跌额": 0.4,
        "涨跌幅": 0.84,
        "开盘价": 47.78,
        "最高价": 48.44,
        "最低价": 47.78,
        "昨收价": 47.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 181817.0,
        "成交额": 8763358.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "105.FV"
    },
    {
        "序号": 2733,
        "name": "Bankwell Financial Group Inc",
        "最新价": 27.78,
        "涨跌额": 0.23,
        "涨跌幅": 0.83,
        "开盘价": 27.61,
        "最高价": 27.78,
        "最低价": 27.22,
        "昨收价": 27.55,
        "总市值": 217840092.0,
        "市盈率": 6.02,
        "成交量": 17674.0,
        "成交额": 486735.0,
        "振幅": 2.03,
        "换手率": 0.23,
        "symbol": "105.BWFG"
    },
    {
        "序号": 2734,
        "name": "Invesco S&P SmallCap 600 Pure G",
        "最新价": 42.32,
        "涨跌额": 0.35,
        "涨跌幅": 0.83,
        "开盘价": 42.3,
        "最高价": 42.33,
        "最低价": 42.3,
        "昨收价": 41.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 3940.0,
        "成交额": 166732.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.RZG"
    },
    {
        "序号": 2735,
        "name": "First Trust Mid Cap Growth Alph",
        "最新价": 64.1,
        "涨跌额": 0.53,
        "涨跌幅": 0.83,
        "开盘价": 63.99,
        "最高价": 64.25,
        "最低价": 63.7,
        "昨收价": 63.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 7765.0,
        "成交额": 496748.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "105.FNY"
    },
    {
        "序号": 2736,
        "name": "查尔斯河",
        "最新价": 204.46,
        "涨跌额": 1.69,
        "涨跌幅": 0.83,
        "开盘价": 202.39,
        "最高价": 204.95,
        "最低价": 201.49,
        "昨收价": 202.77,
        "总市值": 10488144546.0,
        "市盈率": 22.08,
        "成交量": 507749.0,
        "成交额": 103318073.0,
        "振幅": 1.71,
        "换手率": 0.99,
        "symbol": "106.CRL"
    },
    {
        "序号": 2737,
        "name": "PermRock Royalty Trust",
        "最新价": 4.84,
        "涨跌额": 0.04,
        "涨跌幅": 0.83,
        "开盘价": 4.8,
        "最高价": 4.92,
        "最低价": 4.79,
        "昨收价": 4.8,
        "总市值": 58882143.0,
        "市盈率": 7.25,
        "成交量": 23306.0,
        "成交额": 113556.0,
        "振幅": 2.71,
        "换手率": 0.19,
        "symbol": "106.PRT"
    },
    {
        "序号": 2738,
        "name": "Tenable Holdings Inc",
        "最新价": 41.15,
        "涨跌额": 0.34,
        "涨跌幅": 0.83,
        "开盘价": 40.76,
        "最高价": 41.23,
        "最低价": 40.55,
        "昨收价": 40.81,
        "总市值": 4811872411.0,
        "市盈率": -61.59,
        "成交量": 517502.0,
        "成交额": 21243595.0,
        "振幅": 1.67,
        "换手率": 0.44,
        "symbol": "105.TENB"
    },
    {
        "序号": 2739,
        "name": "Element EV, Solar & Battery Mat",
        "最新价": 16.95,
        "涨跌额": 0.14,
        "涨跌幅": 0.83,
        "开盘价": 16.83,
        "最高价": 16.95,
        "最低价": 16.75,
        "昨收价": 16.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 965.0,
        "成交额": 16227.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.CHRG"
    },
    {
        "序号": 2740,
        "name": "第一太阳能",
        "最新价": 145.38,
        "涨跌额": 1.2,
        "涨跌幅": 0.83,
        "开盘价": 150.99,
        "最高价": 153.24,
        "最低价": 142.59,
        "昨收价": 144.18,
        "总市值": 15533011541.0,
        "市盈率": 32.77,
        "成交量": 4314178.0,
        "成交额": 636403264.0,
        "振幅": 7.39,
        "换手率": 4.04,
        "symbol": "105.FSLR"
    },
    {
        "序号": 2741,
        "name": "Arlington Asset Investment Corp",
        "最新价": 20.6,
        "涨跌额": 0.17,
        "涨跌幅": 0.83,
        "开盘价": 20.6,
        "最高价": 20.6,
        "最低价": 20.6,
        "昨收价": 20.43,
        "总市值": 7821161.0,
        "市盈率": null,
        "成交量": 402.0,
        "成交额": 8281.0,
        "振幅": 0.0,
        "换手率": 0.11,
        "symbol": "106.AAIC_B"
    },
    {
        "序号": 2742,
        "name": "Invesco S&P 500 Enhanced Value ",
        "最新价": 42.43,
        "涨跌额": 0.35,
        "涨跌幅": 0.83,
        "开盘价": 42.35,
        "最高价": 42.43,
        "最低价": 42.34,
        "昨收价": 42.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 928.0,
        "成交额": 39292.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.SPVU"
    },
    {
        "序号": 2743,
        "name": "Magic Software Enterprises Ltd",
        "最新价": 9.7,
        "涨跌额": 0.08,
        "涨跌幅": 0.83,
        "开盘价": 9.67,
        "最高价": 9.86,
        "最低价": 9.49,
        "昨收价": 9.62,
        "总市值": 476202634.0,
        "市盈率": 12.5,
        "成交量": 46024.0,
        "成交额": 449640.0,
        "振幅": 3.85,
        "换手率": 0.09,
        "symbol": "105.MGIC"
    },
    {
        "序号": 2744,
        "name": "法如科技",
        "最新价": 19.4,
        "涨跌额": 0.16,
        "涨跌幅": 0.83,
        "开盘价": 19.44,
        "最高价": 19.51,
        "最低价": 19.04,
        "昨收价": 19.24,
        "总市值": 367712586.0,
        "市盈率": -6.09,
        "成交量": 139053.0,
        "成交额": 2682414.0,
        "振幅": 2.44,
        "换手率": 0.73,
        "symbol": "105.FARO"
    },
    {
        "序号": 2745,
        "name": "加拿大皇家银行",
        "最新价": 92.18,
        "涨跌额": 0.76,
        "涨跌幅": 0.83,
        "开盘价": 91.55,
        "最高价": 92.47,
        "最低价": 91.43,
        "昨收价": 91.42,
        "总市值": 129652665252.0,
        "市盈率": 12.0,
        "成交量": 560587.0,
        "成交额": 51632943.0,
        "振幅": 1.14,
        "换手率": 0.04,
        "symbol": "106.RY"
    },
    {
        "序号": 2746,
        "name": "Arcus Biosciences Inc",
        "最新价": 15.77,
        "涨跌额": 0.13,
        "涨跌幅": 0.83,
        "开盘价": 15.66,
        "最高价": 16.02,
        "最低价": 15.4,
        "昨收价": 15.64,
        "总市值": 1180472260.0,
        "市盈率": -4.03,
        "成交量": 496989.0,
        "成交额": 7856623.0,
        "振幅": 3.96,
        "换手率": 0.66,
        "symbol": "106.RCUS"
    },
    {
        "序号": 2747,
        "name": "The Goldman Sachs Group Inc Ser",
        "最新价": 21.86,
        "涨跌额": 0.18,
        "涨跌幅": 0.83,
        "开盘价": 21.74,
        "最高价": 21.86,
        "最低价": 21.57,
        "昨收价": 21.68,
        "总市值": 174880.0,
        "市盈率": null,
        "成交量": 13336.0,
        "成交额": 289748.0,
        "振幅": 1.34,
        "换手率": 166.7,
        "symbol": "106.GS_C"
    },
    {
        "序号": 2748,
        "name": "Horizon Kinetics Energy and Rem",
        "最新价": 25.51,
        "涨跌额": 0.21,
        "涨跌幅": 0.83,
        "开盘价": 25.51,
        "最高价": 25.51,
        "最低价": 25.51,
        "昨收价": 25.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 194.0,
        "成交额": 4948.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NVIR"
    },
    {
        "序号": 2749,
        "name": "SoFi Next 500 ETF",
        "最新价": 12.15,
        "涨跌额": 0.1,
        "涨跌幅": 0.83,
        "开盘价": 12.05,
        "最高价": 12.19,
        "最低价": 12.05,
        "昨收价": 12.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 14630.0,
        "成交额": 177456.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.SFYX"
    },
    {
        "序号": 2750,
        "name": "北美软件ETF-iShares",
        "最新价": 393.69,
        "涨跌额": 3.24,
        "涨跌幅": 0.83,
        "开盘价": 388.03,
        "最高价": 394.26,
        "最低价": 388.03,
        "昨收价": 390.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 285981.0,
        "成交额": 112262795.0,
        "振幅": 1.6,
        "换手率": null,
        "symbol": "107.IGV"
    },
    {
        "序号": 2751,
        "name": "Gabelli Growth Innovators ETF",
        "最新价": 20.66,
        "涨跌额": 0.17,
        "涨跌幅": 0.83,
        "开盘价": 20.66,
        "最高价": 20.66,
        "最低价": 20.66,
        "昨收价": 20.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 123.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GGRW"
    },
    {
        "序号": 2752,
        "name": "Generac Holdings Inc",
        "最新价": 121.55,
        "涨跌额": 1.0,
        "涨跌幅": 0.83,
        "开盘价": 119.97,
        "最高价": 121.62,
        "最低价": 118.63,
        "昨收价": 120.55,
        "总市值": 7467008184.0,
        "市盈率": 39.5,
        "成交量": 1032674.0,
        "成交额": 124503077.0,
        "振幅": 2.48,
        "换手率": 1.68,
        "symbol": "106.GNRC"
    },
    {
        "序号": 2753,
        "name": "二倍做多标普500ETF-ProShares",
        "最新价": 60.86,
        "涨跌额": 0.5,
        "涨跌幅": 0.83,
        "开盘价": 60.17,
        "最高价": 61.01,
        "最低价": 60.09,
        "昨收价": 60.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 3150302.0,
        "成交额": 190973510.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "107.SSO"
    },
    {
        "序号": 2754,
        "name": "SPDR S&P Health Care Services E",
        "最新价": 86.46,
        "涨跌额": 0.71,
        "涨跌幅": 0.83,
        "开盘价": 85.81,
        "最高价": 86.52,
        "最低价": 85.81,
        "昨收价": 85.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 4751.0,
        "成交额": 409860.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.XHS"
    },
    {
        "序号": 2755,
        "name": "N-able Inc",
        "最新价": 12.19,
        "涨跌额": 0.1,
        "涨跌幅": 0.83,
        "开盘价": 12.04,
        "最高价": 12.2,
        "最低价": 12.04,
        "昨收价": 12.09,
        "总市值": 2229884433.0,
        "市盈率": 105.95,
        "成交量": 332817.0,
        "成交额": 4045761.0,
        "振幅": 1.32,
        "换手率": 0.18,
        "symbol": "106.NABL"
    },
    {
        "序号": 2756,
        "name": "Fidelity Disruptive Communicati",
        "最新价": 28.05,
        "涨跌额": 0.23,
        "涨跌幅": 0.83,
        "开盘价": 27.7,
        "最高价": 28.08,
        "最低价": 27.7,
        "昨收价": 27.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 6552.0,
        "成交额": 183510.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "105.FDCF"
    },
    {
        "序号": 2757,
        "name": "iShares U.S. Tech Independence ",
        "最新价": 59.79,
        "涨跌额": 0.49,
        "涨跌幅": 0.83,
        "开盘价": 59.11,
        "最高价": 59.81,
        "最低价": 59.11,
        "昨收价": 59.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 23370.0,
        "成交额": 1390923.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.IETC"
    },
    {
        "序号": 2758,
        "name": "拉美40ETF-iShares",
        "最新价": 28.07,
        "涨跌额": 0.23,
        "涨跌幅": 0.83,
        "开盘价": 27.79,
        "最高价": 28.15,
        "最低价": 27.77,
        "昨收价": 27.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 553471.0,
        "成交额": 15511077.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.ILF"
    },
    {
        "序号": 2759,
        "name": "VanEck Ethereum Strategy ETF",
        "最新价": 21.99,
        "涨跌额": 0.18,
        "涨跌幅": 0.83,
        "开盘价": 22.02,
        "最高价": 22.02,
        "最低价": 21.84,
        "昨收价": 21.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 11675.0,
        "成交额": 255731.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.EFUT"
    },
    {
        "序号": 2760,
        "name": "ProShares UltraShort MSCI Emerg",
        "最新价": 21.99,
        "涨跌额": 0.18,
        "涨跌幅": 0.83,
        "开盘价": 21.96,
        "最高价": 21.99,
        "最低价": 21.96,
        "昨收价": 21.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 333.0,
        "成交额": 7312.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.EEV"
    },
    {
        "序号": 2761,
        "name": "Diversified Healthcare Trust No",
        "最新价": 15.89,
        "涨跌额": 0.13,
        "涨跌幅": 0.82,
        "开盘价": 15.65,
        "最高价": 15.89,
        "最低价": 15.46,
        "昨收价": 15.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 15008.0,
        "成交额": 235679.0,
        "振幅": 2.73,
        "换手率": null,
        "symbol": "105.DHCNL"
    },
    {
        "序号": 2762,
        "name": "法拉利",
        "最新价": 366.75,
        "涨跌额": 3.0,
        "涨跌幅": 0.82,
        "开盘价": 364.84,
        "最高价": 368.25,
        "最低价": 364.8,
        "昨收价": 363.75,
        "总市值": 89492716166.0,
        "市盈率": 68.58,
        "成交量": 387917.0,
        "成交额": 142108782.0,
        "振幅": 0.95,
        "换手率": 0.16,
        "symbol": "106.RACE"
    },
    {
        "序号": 2763,
        "name": "Direxion Daily Electric and Aut",
        "最新价": 39.12,
        "涨跌额": 0.32,
        "涨跌幅": 0.82,
        "开盘价": 38.9,
        "最高价": 39.3,
        "最低价": 37.8,
        "昨收价": 38.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 22213.0,
        "成交额": 853298.0,
        "振幅": 3.87,
        "换手率": null,
        "symbol": "107.EVAV"
    },
    {
        "序号": 2764,
        "name": "大峡谷教育",
        "最新价": 140.67,
        "涨跌额": 1.15,
        "涨跌幅": 0.82,
        "开盘价": 138.74,
        "最高价": 141.91,
        "最低价": 138.74,
        "昨收价": 139.52,
        "总市值": 4221582099.0,
        "市盈率": 21.62,
        "成交量": 179739.0,
        "成交额": 25240398.0,
        "振幅": 2.27,
        "换手率": 0.6,
        "symbol": "105.LOPE"
    },
    {
        "序号": 2765,
        "name": "伊利诺伊机械",
        "最新价": 249.61,
        "涨跌额": 2.04,
        "涨跌幅": 0.82,
        "开盘价": 248.0,
        "最高价": 250.55,
        "最低价": 247.43,
        "昨收价": 247.57,
        "总市值": 75104124507.0,
        "市盈率": 23.87,
        "成交量": 1781170.0,
        "成交额": 444256528.0,
        "振幅": 1.26,
        "换手率": 0.59,
        "symbol": "106.ITW"
    },
    {
        "序号": 2766,
        "name": "First Trust NASDAQ ABA Communit",
        "最新价": 46.51,
        "涨跌额": 0.38,
        "涨跌幅": 0.82,
        "开盘价": 46.5,
        "最高价": 46.73,
        "最低价": 46.39,
        "昨收价": 46.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 9902.0,
        "成交额": 460576.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "105.QABA"
    },
    {
        "序号": 2767,
        "name": "芝加哥期权交易所",
        "最新价": 178.72,
        "涨跌额": 1.46,
        "涨跌幅": 0.82,
        "开盘价": 176.79,
        "最高价": 179.08,
        "最低价": 176.01,
        "昨收价": 177.26,
        "总市值": 18864966354.0,
        "市盈率": 26.6,
        "成交量": 806066.0,
        "成交额": 143661342.0,
        "振幅": 1.73,
        "换手率": 0.76,
        "symbol": "107.CBOE"
    },
    {
        "序号": 2768,
        "name": "ARK Space Exploration & Innovat",
        "最新价": 14.69,
        "涨跌额": 0.12,
        "涨跌幅": 0.82,
        "开盘价": 14.5,
        "最高价": 14.72,
        "最低价": 14.5,
        "昨收价": 14.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 30646.0,
        "成交额": 448523.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "107.ARKX"
    },
    {
        "序号": 2769,
        "name": "CareDx Inc",
        "最新价": 9.81,
        "涨跌额": 0.08,
        "涨跌幅": 0.82,
        "开盘价": 9.67,
        "最高价": 10.14,
        "最低价": 9.52,
        "昨收价": 9.73,
        "总市值": 530705088.0,
        "市盈率": -5.86,
        "成交量": 790249.0,
        "成交额": 7794674.0,
        "振幅": 6.37,
        "换手率": 1.46,
        "symbol": "105.CDNA"
    },
    {
        "序号": 2770,
        "name": "绿色平原能源",
        "最新价": 23.31,
        "涨跌额": 0.19,
        "涨跌幅": 0.82,
        "开盘价": 23.14,
        "最高价": 23.4,
        "最低价": 22.85,
        "昨收价": 23.12,
        "总市值": 1387250058.0,
        "市盈率": -9.96,
        "成交量": 569727.0,
        "成交额": 13215376.0,
        "振幅": 2.38,
        "换手率": 0.96,
        "symbol": "105.GPRE"
    },
    {
        "序号": 2771,
        "name": "SEI Enhanced U.S. Large Cap Mom",
        "最新价": 27.02,
        "涨跌额": 0.22,
        "涨跌幅": 0.82,
        "开盘价": 26.89,
        "最高价": 27.02,
        "最低价": 26.89,
        "昨收价": 26.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 418.0,
        "成交额": 11268.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.SEIM"
    },
    {
        "序号": 2772,
        "name": "SPDR SSGA US Small Cap Low Vola",
        "最新价": 106.88,
        "涨跌额": 0.87,
        "涨跌幅": 0.82,
        "开盘价": 105.96,
        "最高价": 106.9,
        "最低价": 105.96,
        "昨收价": 106.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 7797.0,
        "成交额": 832740.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.SMLV"
    },
    {
        "序号": 2773,
        "name": "Roivant Sciences Ltd",
        "最新价": 9.83,
        "涨跌额": 0.08,
        "涨跌幅": 0.82,
        "开盘价": 9.74,
        "最高价": 9.84,
        "最低价": 9.48,
        "昨收价": 9.75,
        "总市值": 7902546929.0,
        "市盈率": -8.05,
        "成交量": 4957546.0,
        "成交额": 48103658.0,
        "振幅": 3.69,
        "换手率": 0.62,
        "symbol": "105.ROIV"
    },
    {
        "序号": 2774,
        "name": "皇家加勒比邮轮",
        "最新价": 120.47,
        "涨跌额": 0.98,
        "涨跌幅": 0.82,
        "开盘价": 119.3,
        "最高价": 120.58,
        "最低价": 118.85,
        "昨收价": 119.49,
        "总市值": 30868642979.0,
        "市盈率": 33.56,
        "成交量": 2650598.0,
        "成交额": 318317888.0,
        "振幅": 1.45,
        "换手率": 1.03,
        "symbol": "106.RCL"
    },
    {
        "序号": 2775,
        "name": "Xtrackers Semiconductor Select ",
        "最新价": 25.82,
        "涨跌额": 0.21,
        "涨跌幅": 0.82,
        "开盘价": 25.82,
        "最高价": 25.82,
        "最低价": 25.82,
        "昨收价": 25.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 51.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CHPS"
    },
    {
        "序号": 2776,
        "name": "First Trust MLP and Energy Inco",
        "最新价": 8.63,
        "涨跌额": 0.07,
        "涨跌幅": 0.82,
        "开盘价": 8.54,
        "最高价": 8.68,
        "最低价": 8.54,
        "昨收价": 8.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 97959.0,
        "成交额": 844703.0,
        "振幅": 1.64,
        "换手率": null,
        "symbol": "106.FEI"
    },
    {
        "序号": 2777,
        "name": "ETRACS MVIS Business Developmen",
        "最新价": 18.5,
        "涨跌额": 0.15,
        "涨跌幅": 0.82,
        "开盘价": 18.44,
        "最高价": 18.5,
        "最低价": 18.44,
        "昨收价": 18.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 572.0,
        "成交额": 10549.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.BDCZ"
    },
    {
        "序号": 2778,
        "name": "MIND Technology Inc Series A Pf",
        "最新价": 12.34,
        "涨跌额": 0.1,
        "涨跌幅": 0.82,
        "开盘价": 12.46,
        "最高价": 12.5,
        "最低价": 12.34,
        "昨收价": 12.24,
        "总市值": 20768220.0,
        "市盈率": null,
        "成交量": 1911.0,
        "成交额": 23683.0,
        "振幅": 1.31,
        "换手率": 0.11,
        "symbol": "105.MINDP"
    },
    {
        "序号": 2779,
        "name": "意大利ETF-iShares MSCI",
        "最新价": 33.37,
        "涨跌额": 0.27,
        "涨跌幅": 0.82,
        "开盘价": 33.11,
        "最高价": 33.38,
        "最低价": 33.11,
        "昨收价": 33.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 115741.0,
        "成交额": 3855236.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.EWI"
    },
    {
        "序号": 2780,
        "name": "Latham Group Inc",
        "最新价": 2.48,
        "涨跌额": 0.02,
        "涨跌幅": 0.81,
        "开盘价": 2.52,
        "最高价": 2.52,
        "最低价": 2.43,
        "昨收价": 2.46,
        "总市值": 284594744.0,
        "市盈率": -13.22,
        "成交量": 134157.0,
        "成交额": 332530.0,
        "振幅": 3.66,
        "换手率": 0.12,
        "symbol": "105.SWIM"
    },
    {
        "序号": 2781,
        "name": "普雷克萨斯",
        "最新价": 101.73,
        "涨跌额": 0.82,
        "涨跌幅": 0.81,
        "开盘价": 101.05,
        "最高价": 102.27,
        "最低价": 96.46,
        "昨收价": 100.91,
        "总市值": 2794169995.0,
        "市盈率": 20.09,
        "成交量": 113716.0,
        "成交额": 11524560.0,
        "振幅": 5.76,
        "换手率": 0.41,
        "symbol": "105.PLXS"
    },
    {
        "序号": 2782,
        "name": "JPMorgan U.S. Tech Leaders ETF",
        "最新价": 55.83,
        "涨跌额": 0.45,
        "涨跌幅": 0.81,
        "开盘价": 55.27,
        "最高价": 55.94,
        "最低价": 55.27,
        "昨收价": 55.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 17682.0,
        "成交额": 986440.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "105.JTEK"
    },
    {
        "序号": 2783,
        "name": "奥地利ETF-iShares MSCI",
        "最新价": 21.1,
        "涨跌额": 0.17,
        "涨跌幅": 0.81,
        "开盘价": 21.09,
        "最高价": 21.13,
        "最低价": 21.0,
        "昨收价": 20.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 8733.0,
        "成交额": 183800.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.EWO"
    },
    {
        "序号": 2784,
        "name": "卡特彼勒",
        "最新价": 259.5,
        "涨跌额": 2.09,
        "涨跌幅": 0.81,
        "开盘价": 257.38,
        "最高价": 261.64,
        "最低价": 257.0,
        "昨收价": 257.41,
        "总市值": 132107490809.0,
        "市盈率": 14.5,
        "成交量": 3258348.0,
        "成交额": 846323488.0,
        "振幅": 1.8,
        "换手率": 0.64,
        "symbol": "106.CAT"
    },
    {
        "序号": 2785,
        "name": "安费诺",
        "最新价": 93.23,
        "涨跌额": 0.75,
        "涨跌幅": 0.81,
        "开盘价": 92.42,
        "最高价": 93.44,
        "最低价": 92.29,
        "昨收价": 92.48,
        "总市值": 55780462836.0,
        "市盈率": 29.04,
        "成交量": 5882172.0,
        "成交额": 548089088.0,
        "振幅": 1.24,
        "换手率": 0.98,
        "symbol": "106.APH"
    },
    {
        "序号": 2786,
        "name": "First Mid Bancshares Inc",
        "最新价": 32.32,
        "涨跌额": 0.26,
        "涨跌幅": 0.81,
        "开盘价": 32.2,
        "最高价": 32.53,
        "最低价": 32.2,
        "昨收价": 32.06,
        "总市值": 770517365.0,
        "市盈率": 10.78,
        "成交量": 81331.0,
        "成交额": 2629842.0,
        "振幅": 1.03,
        "换手率": 0.34,
        "symbol": "105.FMBH"
    },
    {
        "序号": 2787,
        "name": "布兰迪维因房地产信托",
        "最新价": 4.98,
        "涨跌额": 0.04,
        "涨跌幅": 0.81,
        "开盘价": 4.9,
        "最高价": 5.01,
        "最低价": 4.85,
        "昨收价": 4.94,
        "总市值": 857046352.0,
        "市盈率": -86.96,
        "成交量": 1645407.0,
        "成交额": 8151682.0,
        "振幅": 3.24,
        "换手率": 0.96,
        "symbol": "106.BDN"
    },
    {
        "序号": 2788,
        "name": "Check Point Software Technologi",
        "最新价": 145.72,
        "涨跌额": 1.17,
        "涨跌幅": 0.81,
        "开盘价": 143.82,
        "最高价": 145.9,
        "最低价": 143.82,
        "昨收价": 144.55,
        "总市值": 17048329104.0,
        "市盈率": 20.3,
        "成交量": 865448.0,
        "成交额": 125864272.0,
        "振幅": 1.44,
        "换手率": 0.74,
        "symbol": "105.CHKP"
    },
    {
        "序号": 2789,
        "name": "Heartland Financial USA Inc Ser",
        "最新价": 24.92,
        "涨跌额": 0.2,
        "涨跌幅": 0.81,
        "开盘价": 24.94,
        "最高价": 24.94,
        "最低价": 24.65,
        "昨收价": 24.72,
        "总市值": 286580.0,
        "市盈率": null,
        "成交量": 987.0,
        "成交额": 24457.0,
        "振幅": 1.17,
        "换手率": 8.58,
        "symbol": "105.HTLFP"
    },
    {
        "序号": 2790,
        "name": "ARK 21Shares Active Ethereum Fu",
        "最新价": 29.94,
        "涨跌额": 0.24,
        "涨跌幅": 0.81,
        "开盘价": 29.89,
        "最高价": 29.94,
        "最低价": 29.76,
        "昨收价": 29.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 673.0,
        "成交额": 20078.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.ARKZ"
    },
    {
        "序号": 2791,
        "name": "Invesco International BuyBack A",
        "最新价": 36.19,
        "涨跌额": 0.29,
        "涨跌幅": 0.81,
        "开盘价": 35.92,
        "最高价": 36.23,
        "最低价": 35.92,
        "昨收价": 35.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 8804.0,
        "成交额": 318237.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "105.IPKW"
    },
    {
        "序号": 2792,
        "name": "Atlantica Sustainable Infrastru",
        "最新价": 19.97,
        "涨跌额": 0.16,
        "涨跌幅": 0.81,
        "开盘价": 19.85,
        "最高价": 20.04,
        "最低价": 19.68,
        "昨收价": 19.81,
        "总市值": 2319498506.0,
        "市盈率": 46.32,
        "成交量": 392716.0,
        "成交额": 7810633.0,
        "振幅": 1.82,
        "换手率": 0.34,
        "symbol": "105.AY"
    },
    {
        "序号": 2793,
        "name": "iShares Expanded Tech Sector ET",
        "最新价": 427.09,
        "涨跌额": 3.42,
        "涨跌幅": 0.81,
        "开盘价": 421.74,
        "最高价": 427.46,
        "最低价": 421.74,
        "昨收价": 423.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 12066.0,
        "成交额": 5128050.0,
        "振幅": 1.35,
        "换手率": null,
        "symbol": "107.IGM"
    },
    {
        "序号": 2794,
        "name": "Sprott Uranium Miners ETF",
        "最新价": 48.71,
        "涨跌额": 0.39,
        "涨跌幅": 0.81,
        "开盘价": 48.42,
        "最高价": 49.0,
        "最低价": 48.09,
        "昨收价": 48.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 1032733.0,
        "成交额": 50177287.0,
        "振幅": 1.88,
        "换手率": null,
        "symbol": "107.URNM"
    },
    {
        "序号": 2795,
        "name": "iShares Future Metaverse Tech a",
        "最新价": 31.24,
        "涨跌额": 0.25,
        "涨跌幅": 0.81,
        "开盘价": 31.24,
        "最高价": 31.24,
        "最低价": 31.24,
        "昨收价": 30.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 781.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IVRS"
    },
    {
        "序号": 2796,
        "name": "盈喜集团",
        "最新价": 1.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.81,
        "开盘价": 1.25,
        "最高价": 1.27,
        "最低价": 1.21,
        "昨收价": 1.24,
        "总市值": 5368724.0,
        "市盈率": -23.7,
        "成交量": 4844.0,
        "成交额": 5950.0,
        "振幅": 4.84,
        "换手率": 0.11,
        "symbol": "105.ATXG"
    },
    {
        "序号": 2797,
        "name": "Defiance Next Gen Connectivity ",
        "最新价": 33.77,
        "涨跌额": 0.27,
        "涨跌幅": 0.81,
        "开盘价": 33.43,
        "最高价": 33.84,
        "最低价": 33.43,
        "昨收价": 33.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 87460.0,
        "成交额": 2947342.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.FIVG"
    },
    {
        "序号": 2798,
        "name": "Anterix Inc",
        "最新价": 36.29,
        "涨跌额": 0.29,
        "涨跌幅": 0.81,
        "开盘价": 36.08,
        "最高价": 36.31,
        "最低价": 35.79,
        "昨收价": 36.0,
        "总市值": 681151542.0,
        "市盈率": 91.1,
        "成交量": 292385.0,
        "成交额": 10577019.0,
        "振幅": 1.44,
        "换手率": 1.56,
        "symbol": "105.ATEX"
    },
    {
        "序号": 2799,
        "name": "花旗集团",
        "最新价": 48.89,
        "涨跌额": 0.39,
        "涨跌幅": 0.8,
        "开盘价": 48.51,
        "最高价": 49.17,
        "最低价": 48.42,
        "昨收价": 48.5,
        "总市值": 93569687704.0,
        "市盈率": 6.89,
        "成交量": 17591580.0,
        "成交额": 859602464.0,
        "振幅": 1.55,
        "换手率": 0.92,
        "symbol": "106.C"
    },
    {
        "序号": 2800,
        "name": "Source Capital",
        "最新价": 40.14,
        "涨跌额": 0.32,
        "涨跌幅": 0.8,
        "开盘价": 39.84,
        "最高价": 40.52,
        "最低价": 39.72,
        "昨收价": 39.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 6265.0,
        "成交额": 250681.0,
        "振幅": 2.01,
        "换手率": null,
        "symbol": "106.SOR"
    },
    {
        "序号": 2801,
        "name": "Lindsay Corp",
        "最新价": 120.55,
        "涨跌额": 0.96,
        "涨跌幅": 0.8,
        "开盘价": 119.89,
        "最高价": 121.68,
        "最低价": 119.79,
        "昨收价": 119.59,
        "总市值": 1329779335.0,
        "市盈率": 18.37,
        "成交量": 51198.0,
        "成交额": 6183372.0,
        "振幅": 1.58,
        "换手率": 0.46,
        "symbol": "106.LNN"
    },
    {
        "序号": 2802,
        "name": "阿美特克",
        "最新价": 157.16,
        "涨跌额": 1.25,
        "涨跌幅": 0.8,
        "开盘价": 156.44,
        "最高价": 157.69,
        "最低价": 156.34,
        "昨收价": 155.91,
        "总市值": 36272316934.0,
        "市盈率": 28.4,
        "成交量": 1256021.0,
        "成交额": 197331736.0,
        "振幅": 0.87,
        "换手率": 0.54,
        "symbol": "106.AME"
    },
    {
        "序号": 2803,
        "name": "Neumora Therapeutics Inc",
        "最新价": 12.58,
        "涨跌额": 0.1,
        "涨跌幅": 0.8,
        "开盘价": 12.48,
        "最高价": 12.73,
        "最低价": 12.28,
        "昨收价": 12.48,
        "总市值": 1921007715.0,
        "市盈率": -12.37,
        "成交量": 211640.0,
        "成交额": 2649415.0,
        "振幅": 3.61,
        "换手率": 0.14,
        "symbol": "105.NMRA"
    },
    {
        "序号": 2804,
        "name": "Roundhill Magnificent Seven ETF",
        "最新价": 32.72,
        "涨跌额": 0.26,
        "涨跌幅": 0.8,
        "开盘价": 32.28,
        "最高价": 32.75,
        "最低价": 32.28,
        "昨收价": 32.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 82506.0,
        "成交额": 2691718.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "105.MAGS"
    },
    {
        "序号": 2805,
        "name": "MidCap Financial Investment Cor",
        "最新价": 13.85,
        "涨跌额": 0.11,
        "涨跌幅": 0.8,
        "开盘价": 13.76,
        "最高价": 13.88,
        "最低价": 13.75,
        "昨收价": 13.74,
        "总市值": 903757859.0,
        "市盈率": 30.0,
        "成交量": 264301.0,
        "成交额": 3656215.0,
        "振幅": 0.95,
        "换手率": 0.41,
        "symbol": "105.MFIC"
    },
    {
        "序号": 2806,
        "name": "Davis Select Financial ETF",
        "最新价": 28.96,
        "涨跌额": 0.23,
        "涨跌幅": 0.8,
        "开盘价": 28.92,
        "最高价": 29.0,
        "最低价": 28.91,
        "昨收价": 28.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 14870.0,
        "成交额": 430555.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.DFNL"
    },
    {
        "序号": 2807,
        "name": "T-Rex 2X Long Tesla Daily Targe",
        "最新价": 23.93,
        "涨跌额": 0.19,
        "涨跌幅": 0.8,
        "开盘价": 23.24,
        "最高价": 24.22,
        "最低价": 23.15,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 323043.0,
        "成交额": 7697924.0,
        "振幅": 4.51,
        "换手率": null,
        "symbol": "105.TSLT"
    },
    {
        "序号": 2808,
        "name": "Centogene NV",
        "最新价": 1.26,
        "涨跌额": 0.01,
        "涨跌幅": 0.8,
        "开盘价": 1.25,
        "最高价": 1.28,
        "最低价": 1.25,
        "昨收价": 1.25,
        "总市值": 35110848.0,
        "市盈率": -0.82,
        "成交量": 2898.0,
        "成交额": 3646.0,
        "振幅": 2.4,
        "换手率": 0.01,
        "symbol": "105.CNTG"
    },
    {
        "序号": 2809,
        "name": "盛丰物流",
        "最新价": 15.15,
        "涨跌额": 0.12,
        "涨跌幅": 0.8,
        "开盘价": 15.11,
        "最高价": 15.9,
        "最低价": 14.79,
        "昨收价": 15.03,
        "总市值": 1248360000.0,
        "市盈率": 106.02,
        "成交量": 240928.0,
        "成交额": 3651354.0,
        "振幅": 7.39,
        "换手率": 0.29,
        "symbol": "105.SFWL"
    },
    {
        "序号": 2810,
        "name": "欧洲斯托克50ETF-SPDR",
        "最新价": 46.72,
        "涨跌额": 0.37,
        "涨跌幅": 0.8,
        "开盘价": 46.37,
        "最高价": 46.76,
        "最低价": 46.35,
        "昨收价": 46.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1145951.0,
        "成交额": 53461908.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.FEZ"
    },
    {
        "序号": 2811,
        "name": "Bitwise Ethereum Strategy ETF",
        "最新价": 35.38,
        "涨跌额": 0.28,
        "涨跌幅": 0.8,
        "开盘价": 35.22,
        "最高价": 35.38,
        "最低价": 35.06,
        "昨收价": 35.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 3284.0,
        "成交额": 115723.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.AETH"
    },
    {
        "序号": 2812,
        "name": "ESAB Corp",
        "最新价": 82.15,
        "涨跌额": 0.65,
        "涨跌幅": 0.8,
        "开盘价": 81.07,
        "最高价": 82.3,
        "最低价": 81.0,
        "昨收价": 81.5,
        "总市值": 4951268565.0,
        "市盈率": 22.95,
        "成交量": 222002.0,
        "成交额": 18222880.0,
        "振幅": 1.6,
        "换手率": 0.37,
        "symbol": "106.ESAB"
    },
    {
        "序号": 2813,
        "name": "第一银行(卡罗莱纳州)",
        "最新价": 34.13,
        "涨跌额": 0.27,
        "涨跌幅": 0.8,
        "开盘价": 33.79,
        "最高价": 34.4,
        "最低价": 33.51,
        "昨收价": 33.86,
        "总市值": 1402429618.0,
        "市盈率": 12.42,
        "成交量": 129648.0,
        "成交额": 4419397.0,
        "振幅": 2.63,
        "换手率": 0.32,
        "symbol": "105.FBNC"
    },
    {
        "序号": 2814,
        "name": "REX FANG & Innovation Equity Pr",
        "最新价": 54.36,
        "涨跌额": 0.43,
        "涨跌幅": 0.8,
        "开盘价": 53.81,
        "最高价": 54.36,
        "最低价": 53.81,
        "昨收价": 53.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 26549.0,
        "成交额": 1436676.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "105.FEPI"
    },
    {
        "序号": 2815,
        "name": "Axalta涂层系统",
        "最新价": 32.88,
        "涨跌额": 0.26,
        "涨跌幅": 0.8,
        "开盘价": 32.58,
        "最高价": 33.0,
        "最低价": 32.58,
        "昨收价": 32.62,
        "总市值": 7236330322.0,
        "市盈率": 30.42,
        "成交量": 1810322.0,
        "成交额": 59463267.0,
        "振幅": 1.29,
        "换手率": 0.82,
        "symbol": "106.AXTA"
    },
    {
        "序号": 2816,
        "name": "WalkMe Ltd",
        "最新价": 10.12,
        "涨跌额": 0.08,
        "涨跌幅": 0.8,
        "开盘价": 10.01,
        "最高价": 10.19,
        "最低价": 10.01,
        "昨收价": 10.04,
        "总市值": 893056817.0,
        "市盈率": -13.31,
        "成交量": 33379.0,
        "成交额": 338135.0,
        "振幅": 1.79,
        "换手率": 0.04,
        "symbol": "105.WKME"
    },
    {
        "序号": 2817,
        "name": "帕特森",
        "最新价": 26.58,
        "涨跌额": 0.21,
        "涨跌幅": 0.8,
        "开盘价": 26.32,
        "最高价": 26.63,
        "最低价": 26.06,
        "昨收价": 26.37,
        "总市值": 2462929380.0,
        "市盈率": 12.31,
        "成交量": 874364.0,
        "成交额": 23122250.0,
        "振幅": 2.16,
        "换手率": 0.94,
        "symbol": "105.PDCO"
    },
    {
        "序号": 2818,
        "name": "Recursion Pharmaceuticals Inc-A",
        "最新价": 7.6,
        "涨跌额": 0.06,
        "涨跌幅": 0.8,
        "开盘价": 7.5,
        "最高价": 7.68,
        "最低价": 7.27,
        "昨收价": 7.54,
        "总市值": 1646336632.0,
        "市盈率": -5.63,
        "成交量": 5745171.0,
        "成交额": 42934078.0,
        "振幅": 5.44,
        "换手率": 2.65,
        "symbol": "105.RXRX"
    },
    {
        "序号": 2819,
        "name": "宾夕法尼亚裕益银行",
        "最新价": 20.29,
        "涨跌额": 0.16,
        "涨跌幅": 0.79,
        "开盘价": 20.23,
        "最高价": 20.47,
        "最低价": 20.2,
        "昨收价": 20.13,
        "总市值": 598719917.0,
        "市盈率": 7.61,
        "成交量": 49348.0,
        "成交额": 1002287.0,
        "振幅": 1.34,
        "换手率": 0.17,
        "symbol": "105.UVSP"
    },
    {
        "序号": 2820,
        "name": "欧迪办公",
        "最新价": 46.94,
        "涨跌额": 0.37,
        "涨跌幅": 0.79,
        "开盘价": 46.39,
        "最高价": 47.17,
        "最低价": 46.11,
        "昨收价": 46.57,
        "总市值": 1754527028.0,
        "市盈率": 9.09,
        "成交量": 191288.0,
        "成交额": 8967206.0,
        "振幅": 2.28,
        "换手率": 0.51,
        "symbol": "105.ODP"
    },
    {
        "序号": 2821,
        "name": "全球科技ETF-iShares",
        "最新价": 66.03,
        "涨跌额": 0.52,
        "涨跌幅": 0.79,
        "开盘价": 65.34,
        "最高价": 66.09,
        "最低价": 65.34,
        "昨收价": 65.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 98988.0,
        "成交额": 6517410.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "107.IXN"
    },
    {
        "序号": 2822,
        "name": "SPDR S&P North American Natural",
        "最新价": 49.53,
        "涨跌额": 0.39,
        "涨跌幅": 0.79,
        "开盘价": 49.19,
        "最高价": 49.72,
        "最低价": 49.19,
        "昨收价": 49.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 17183.0,
        "成交额": 848781.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.NANR"
    },
    {
        "序号": 2823,
        "name": "John Hancock Fundamental All Ca",
        "最新价": 11.43,
        "涨跌额": 0.09,
        "涨跌幅": 0.79,
        "开盘价": 11.43,
        "最高价": 11.43,
        "最低价": 11.43,
        "昨收价": 11.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 91.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JHAC"
    },
    {
        "序号": 2824,
        "name": "Eledon Pharmaceuticals Inc",
        "最新价": 1.27,
        "涨跌额": 0.01,
        "涨跌幅": 0.79,
        "开盘价": 1.24,
        "最高价": 1.33,
        "最低价": 1.24,
        "昨收价": 1.26,
        "总市值": 30731625.0,
        "市盈率": -0.34,
        "成交量": 33345.0,
        "成交额": 42683.0,
        "振幅": 7.14,
        "换手率": 0.14,
        "symbol": "105.ELDN"
    },
    {
        "序号": 2825,
        "name": "AvePoint Inc Wt",
        "最新价": 1.27,
        "涨跌额": 0.01,
        "涨跌幅": 0.79,
        "开盘价": 1.27,
        "最高价": 1.28,
        "最低价": 1.27,
        "昨收价": 1.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 536.0,
        "成交额": 681.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.AVPTW"
    },
    {
        "序号": 2826,
        "name": "YieldMax SQ Option Income Strat",
        "最新价": 24.15,
        "涨跌额": 0.19,
        "涨跌幅": 0.79,
        "开盘价": 23.93,
        "最高价": 24.27,
        "最低价": 23.81,
        "昨收价": 23.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 72569.0,
        "成交额": 1750838.0,
        "振幅": 1.92,
        "换手率": null,
        "symbol": "107.SQY"
    },
    {
        "序号": 2827,
        "name": "ETRACS 2x Leveraged US Value Fa",
        "最新价": 29.34,
        "涨跌额": 0.23,
        "涨跌幅": 0.79,
        "开盘价": 29.15,
        "最高价": 29.34,
        "最低价": 29.14,
        "昨收价": 29.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 400.0,
        "成交额": 11659.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.IWDL"
    },
    {
        "序号": 2828,
        "name": "半导体ETF-iShares PHLX",
        "最新价": 521.86,
        "涨跌额": 4.09,
        "涨跌幅": 0.79,
        "开盘价": 516.55,
        "最高价": 524.14,
        "最低价": 516.33,
        "昨收价": 517.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 1402426.0,
        "成交额": 730385904.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "105.SOXX"
    },
    {
        "序号": 2829,
        "name": "Merchants Bancorp Series C Pfd",
        "最新价": 19.16,
        "涨跌额": 0.15,
        "涨跌幅": 0.79,
        "开盘价": 19.0,
        "最高价": 19.18,
        "最低价": 18.75,
        "昨收价": 19.01,
        "总市值": 3758828.0,
        "市盈率": null,
        "成交量": 21212.0,
        "成交额": 402540.0,
        "振幅": 2.26,
        "换手率": 10.81,
        "symbol": "105.MBINN"
    },
    {
        "序号": 2830,
        "name": "Nuveen Small Cap Select ETF",
        "最新价": 23.0,
        "涨跌额": 0.18,
        "涨跌幅": 0.79,
        "开盘价": 23.0,
        "最高价": 23.0,
        "最低价": 23.0,
        "昨收价": 22.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 46.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NSCS"
    },
    {
        "序号": 2831,
        "name": "POINT Biopharma Global Inc",
        "最新价": 14.06,
        "涨跌额": 0.11,
        "涨跌幅": 0.79,
        "开盘价": 14.05,
        "最高价": 14.16,
        "最低价": 13.88,
        "昨收价": 13.95,
        "总市值": 1498363388.0,
        "市盈率": 15.52,
        "成交量": 1566761.0,
        "成交额": 22016500.0,
        "振幅": 2.01,
        "换手率": 1.47,
        "symbol": "105.PNT"
    },
    {
        "序号": 2832,
        "name": "邦纳",
        "最新价": 49.86,
        "涨跌额": 0.39,
        "涨跌幅": 0.79,
        "开盘价": 49.51,
        "最高价": 50.18,
        "最低价": 49.16,
        "昨收价": 49.47,
        "总市值": 1712595967.0,
        "市盈率": 8.77,
        "成交量": 143016.0,
        "成交额": 7126444.0,
        "振幅": 2.06,
        "换手率": 0.42,
        "symbol": "105.BANR"
    },
    {
        "序号": 2833,
        "name": "OneAscent Large Cap Core ETF",
        "最新价": 24.31,
        "涨跌额": 0.19,
        "涨跌幅": 0.79,
        "开盘价": 24.22,
        "最高价": 24.35,
        "最低价": 24.15,
        "昨收价": 24.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 33475.0,
        "成交额": 811268.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.OALC"
    },
    {
        "序号": 2834,
        "name": "赛富时",
        "最新价": 250.81,
        "涨跌额": 1.96,
        "涨跌幅": 0.79,
        "开盘价": 248.55,
        "最高价": 252.09,
        "最低价": 248.0,
        "昨收价": 248.85,
        "总市值": 242784080000.0,
        "市盈率": 93.67,
        "成交量": 4605805.0,
        "成交额": 1155260688.0,
        "振幅": 1.64,
        "换手率": 0.48,
        "symbol": "106.CRM"
    },
    {
        "序号": 2835,
        "name": "ALPS Global Travel Beneficiarie",
        "最新价": 23.04,
        "涨跌额": 0.18,
        "涨跌幅": 0.79,
        "开盘价": 23.02,
        "最高价": 23.04,
        "最低价": 22.99,
        "昨收价": 22.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 521.0,
        "成交额": 11983.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.JRNY"
    },
    {
        "序号": 2836,
        "name": "PayPal Holdings Inc",
        "最新价": 58.94,
        "涨跌额": 0.46,
        "涨跌幅": 0.79,
        "开盘价": 58.26,
        "最高价": 59.74,
        "最低价": 57.4,
        "昨收价": 58.48,
        "总市值": 63545584803.0,
        "市盈率": 16.88,
        "成交量": 15467509.0,
        "成交额": 912068800.0,
        "振幅": 4.0,
        "换手率": 1.43,
        "symbol": "105.PYPL"
    },
    {
        "序号": 2837,
        "name": "Universal Health Realty Income ",
        "最新价": 42.31,
        "涨跌额": 0.33,
        "涨跌幅": 0.79,
        "开盘价": 42.13,
        "最高价": 42.32,
        "最低价": 41.55,
        "昨收价": 41.98,
        "总市值": 584853372.0,
        "市盈率": 33.54,
        "成交量": 45427.0,
        "成交额": 1916060.0,
        "振幅": 1.83,
        "换手率": 0.33,
        "symbol": "106.UHT"
    },
    {
        "序号": 2838,
        "name": "西南资本",
        "最新价": 23.09,
        "涨跌额": 0.18,
        "涨跌幅": 0.79,
        "开盘价": 22.98,
        "最高价": 23.13,
        "最低价": 22.75,
        "昨收价": 22.91,
        "总市值": 922471315.0,
        "市盈率": 13.65,
        "成交量": 675893.0,
        "成交额": 15453670.0,
        "振幅": 1.66,
        "换手率": 1.69,
        "symbol": "105.CSWC"
    },
    {
        "序号": 2839,
        "name": "Community Bank System Inc",
        "最新价": 48.76,
        "涨跌额": 0.38,
        "涨跌幅": 0.79,
        "开盘价": 48.33,
        "最高价": 48.97,
        "最低价": 47.98,
        "昨收价": 48.38,
        "总市值": 2603080149.0,
        "市盈率": 17.27,
        "成交量": 172651.0,
        "成交额": 8413668.0,
        "振幅": 2.05,
        "换手率": 0.32,
        "symbol": "106.CBU"
    },
    {
        "序号": 2840,
        "name": "Virtus Terranova U.S. Quality M",
        "最新价": 29.52,
        "涨跌额": 0.23,
        "涨跌幅": 0.79,
        "开盘价": 29.29,
        "最高价": 29.54,
        "最低价": 29.29,
        "昨收价": 29.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 13234.0,
        "成交额": 389862.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.JOET"
    },
    {
        "序号": 2841,
        "name": "安盛公平控股",
        "最新价": 32.12,
        "涨跌额": 0.25,
        "涨跌幅": 0.78,
        "开盘价": 31.78,
        "最高价": 32.28,
        "最低价": 31.78,
        "昨收价": 31.87,
        "总市值": 10872205909.0,
        "市盈率": 6.42,
        "成交量": 4097184.0,
        "成交额": 131751351.0,
        "振幅": 1.57,
        "换手率": 1.21,
        "symbol": "106.EQH"
    },
    {
        "序号": 2842,
        "name": "Capital Group Dividend Value ET",
        "最新价": 28.27,
        "涨跌额": 0.22,
        "涨跌幅": 0.78,
        "开盘价": 28.1,
        "最高价": 28.32,
        "最低价": 28.08,
        "昨收价": 28.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1197161.0,
        "成交额": 33758933.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.CGDV"
    },
    {
        "序号": 2843,
        "name": "CSW Industrials Inc",
        "最新价": 182.53,
        "涨跌额": 1.42,
        "涨跌幅": 0.78,
        "开盘价": 180.56,
        "最高价": 183.99,
        "最低价": 180.51,
        "昨收价": 181.11,
        "总市值": 2838478215.0,
        "市盈率": 27.47,
        "成交量": 33091.0,
        "成交额": 6031028.0,
        "振幅": 1.92,
        "换手率": 0.21,
        "symbol": "105.CSWI"
    },
    {
        "序号": 2844,
        "name": "Putnam Sustainable Future ETF",
        "最新价": 20.57,
        "涨跌额": 0.16,
        "涨跌幅": 0.78,
        "开盘价": 20.5,
        "最高价": 20.58,
        "最低价": 20.5,
        "昨收价": 20.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 3684.0,
        "成交额": 75634.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.PFUT"
    },
    {
        "序号": 2845,
        "name": "富尔顿金融",
        "最新价": 15.43,
        "涨跌额": 0.12,
        "涨跌幅": 0.78,
        "开盘价": 15.32,
        "最高价": 15.6,
        "最低价": 15.26,
        "昨收价": 15.31,
        "总市值": 2533461483.0,
        "市盈率": 8.39,
        "成交量": 1708368.0,
        "成交额": 26372907.0,
        "振幅": 2.22,
        "换手率": 1.04,
        "symbol": "105.FULT"
    },
    {
        "序号": 2846,
        "name": "AG Mortgage Investment Trust In",
        "最新价": 18.03,
        "涨跌额": 0.14,
        "涨跌幅": 0.78,
        "开盘价": 17.9,
        "最高价": 18.1,
        "最低价": 17.9,
        "昨收价": 17.89,
        "总市值": 82938000.0,
        "市盈率": null,
        "成交量": 30680.0,
        "成交额": 553379.0,
        "振幅": 1.12,
        "换手率": 0.67,
        "symbol": "106.MITT_B"
    },
    {
        "序号": 2847,
        "name": "天地荟",
        "最新价": 1.29,
        "涨跌额": 0.01,
        "涨跌幅": 0.78,
        "开盘价": 1.3,
        "最高价": 1.31,
        "最低价": 1.29,
        "昨收价": 1.28,
        "总市值": 13317016.0,
        "市盈率": 7.55,
        "成交量": 2671.0,
        "成交额": 3474.0,
        "振幅": 1.56,
        "换手率": 0.03,
        "symbol": "105.PETZ"
    },
    {
        "序号": 2848,
        "name": "Ellington投资",
        "最新价": 12.9,
        "涨跌额": 0.1,
        "涨跌幅": 0.78,
        "开盘价": 12.75,
        "最高价": 12.92,
        "最低价": 12.75,
        "昨收价": 12.8,
        "总市值": 880220664.0,
        "市盈率": 9.56,
        "成交量": 915342.0,
        "成交额": 11747996.0,
        "振幅": 1.33,
        "换手率": 1.34,
        "symbol": "106.EFC"
    },
    {
        "序号": 2849,
        "name": "IQ Chaikin U.S. Small Cap ETF",
        "最新价": 33.55,
        "涨跌额": 0.26,
        "涨跌幅": 0.78,
        "开盘价": 33.26,
        "最高价": 33.63,
        "最低价": 33.26,
        "昨收价": 33.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 24378.0,
        "成交额": 814112.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "105.CSML"
    },
    {
        "序号": 2850,
        "name": "百富门-A",
        "最新价": 56.78,
        "涨跌额": 0.44,
        "涨跌幅": 0.78,
        "开盘价": 56.6,
        "最高价": 57.25,
        "最低价": 56.24,
        "昨收价": 56.34,
        "总市值": 27005722167.0,
        "市盈率": 34.62,
        "成交量": 72299.0,
        "成交额": 4109846.0,
        "振幅": 1.79,
        "换手率": 0.04,
        "symbol": "106.BF_A"
    },
    {
        "序号": 2851,
        "name": "Invesco NASDAQ Internet ETF",
        "最新价": 34.85,
        "涨跌额": 0.27,
        "涨跌幅": 0.78,
        "开盘价": 34.38,
        "最高价": 34.87,
        "最低价": 34.32,
        "昨收价": 34.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 36951.0,
        "成交额": 1285150.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "105.PNQI"
    },
    {
        "序号": 2852,
        "name": "Bristow Group Inc",
        "最新价": 25.82,
        "涨跌额": 0.2,
        "涨跌幅": 0.78,
        "开盘价": 25.63,
        "最高价": 26.13,
        "最低价": 25.4,
        "昨收价": 25.62,
        "总市值": 729311720.0,
        "市盈率": 79.14,
        "成交量": 80513.0,
        "成交额": 2074434.0,
        "振幅": 2.85,
        "换手率": 0.29,
        "symbol": "106.VTOL"
    },
    {
        "序号": 2853,
        "name": "二倍做多纳斯达克100ETF",
        "最新价": 69.75,
        "涨跌额": 0.54,
        "涨跌幅": 0.78,
        "开盘价": 68.51,
        "最高价": 69.93,
        "最低价": 68.47,
        "昨收价": 69.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 4000407.0,
        "成交额": 277756432.0,
        "振幅": 2.11,
        "换手率": null,
        "symbol": "107.QLD"
    },
    {
        "序号": 2854,
        "name": "Global X Defense Tech ETF",
        "最新价": 27.14,
        "涨跌额": 0.21,
        "涨跌幅": 0.78,
        "开盘价": 27.07,
        "最高价": 27.19,
        "最低价": 27.07,
        "昨收价": 26.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 2473.0,
        "成交额": 67069.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.SHLD"
    },
    {
        "序号": 2855,
        "name": "Goldman Sachs Small Cap Core Eq",
        "最新价": 42.65,
        "涨跌额": 0.33,
        "涨跌幅": 0.78,
        "开盘价": 42.73,
        "最高价": 42.73,
        "最低价": 42.45,
        "昨收价": 42.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 9581.0,
        "成交额": 408509.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.GSC"
    },
    {
        "序号": 2856,
        "name": "Health Catalyst Inc",
        "最新价": 7.76,
        "涨跌额": 0.06,
        "涨跌幅": 0.78,
        "开盘价": 7.65,
        "最高价": 7.86,
        "最低价": 7.63,
        "昨收价": 7.7,
        "总市值": 446590514.0,
        "市盈率": -3.61,
        "成交量": 379012.0,
        "成交额": 2937815.0,
        "振幅": 2.99,
        "换手率": 0.66,
        "symbol": "105.HCAT"
    },
    {
        "序号": 2857,
        "name": "Invesco FTSE RAFI US 1500 Small",
        "最新价": 34.96,
        "涨跌额": 0.27,
        "涨跌幅": 0.78,
        "开盘价": 34.66,
        "最高价": 35.08,
        "最低价": 34.66,
        "昨收价": 34.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 107336.0,
        "成交额": 3744830.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "105.PRFZ"
    },
    {
        "序号": 2858,
        "name": "PagerDuty Inc",
        "最新价": 22.07,
        "涨跌额": 0.17,
        "涨跌幅": 0.78,
        "开盘价": 21.75,
        "最高价": 22.16,
        "最低价": 21.46,
        "昨收价": 21.9,
        "总市值": 2028063547.0,
        "市盈率": -26.8,
        "成交量": 1411936.0,
        "成交额": 31003340.0,
        "振幅": 3.2,
        "换手率": 1.54,
        "symbol": "106.PD"
    },
    {
        "序号": 2859,
        "name": "Tortoise North American Pipelin",
        "最新价": 25.98,
        "涨跌额": 0.2,
        "涨跌幅": 0.78,
        "开盘价": 25.89,
        "最高价": 26.02,
        "最低价": 25.87,
        "昨收价": 25.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 23671.0,
        "成交额": 614241.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.TPYP"
    },
    {
        "序号": 2860,
        "name": "Invesco Dynamic Semiconductors ",
        "最新价": 44.19,
        "涨跌额": 0.34,
        "涨跌幅": 0.78,
        "开盘价": 43.79,
        "最高价": 44.47,
        "最低价": 43.79,
        "昨收价": 43.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 35402.0,
        "成交额": 1565276.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "107.PSI"
    },
    {
        "序号": 2861,
        "name": "Opthea Ltd ADR",
        "最新价": 2.6,
        "涨跌额": 0.02,
        "涨跌幅": 0.78,
        "开盘价": 2.69,
        "最高价": 2.69,
        "最低价": 2.6,
        "昨收价": 2.58,
        "总市值": 215412240.0,
        "市盈率": -1.51,
        "成交量": 1505.0,
        "成交额": 3996.0,
        "振幅": 3.49,
        "换手率": 0.0,
        "symbol": "105.OPT"
    },
    {
        "序号": 2862,
        "name": "Option Care Health Inc",
        "最新价": 29.9,
        "涨跌额": 0.23,
        "涨跌幅": 0.78,
        "开盘价": 29.63,
        "最高价": 30.11,
        "最低价": 29.59,
        "昨收价": 29.67,
        "总市值": 5296203116.0,
        "市盈率": 20.57,
        "成交量": 1319513.0,
        "成交额": 39367432.0,
        "振幅": 1.75,
        "换手率": 0.74,
        "symbol": "105.OPCH"
    },
    {
        "序号": 2863,
        "name": "Xtrackers MSCI EAFE Hedged Equi",
        "最新价": 36.4,
        "涨跌额": 0.28,
        "涨跌幅": 0.78,
        "开盘价": 36.17,
        "最高价": 36.4,
        "最低价": 36.17,
        "昨收价": 36.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 761103.0,
        "成交额": 27661314.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.DBEF"
    },
    {
        "序号": 2864,
        "name": "Origin Bancorp Inc",
        "最新价": 33.81,
        "涨跌额": 0.26,
        "涨跌幅": 0.77,
        "开盘价": 33.73,
        "最高价": 34.19,
        "最低价": 33.65,
        "昨收价": 33.55,
        "总市值": 1044956068.0,
        "市盈率": 10.46,
        "成交量": 92174.0,
        "成交额": 3117837.0,
        "振幅": 1.61,
        "换手率": 0.3,
        "symbol": "106.OBK"
    },
    {
        "序号": 2865,
        "name": "曼联",
        "最新价": 19.51,
        "涨跌额": 0.15,
        "涨跌幅": 0.77,
        "开盘价": 19.39,
        "最高价": 19.6,
        "最低价": 19.29,
        "昨收价": 19.36,
        "总市值": 3216005846.0,
        "市盈率": -89.76,
        "成交量": 396932.0,
        "成交额": 7749881.0,
        "振幅": 1.6,
        "换手率": 0.24,
        "symbol": "106.MANU"
    },
    {
        "序号": 2866,
        "name": "道格拉斯艾美特",
        "最新价": 14.31,
        "涨跌额": 0.11,
        "涨跌幅": 0.77,
        "开盘价": 14.08,
        "最高价": 14.4,
        "最低价": 13.93,
        "昨收价": 14.2,
        "总市值": 2386016916.0,
        "市盈率": 108.2,
        "成交量": 2284800.0,
        "成交额": 32540950.0,
        "振幅": 3.31,
        "换手率": 1.37,
        "symbol": "106.DEI"
    },
    {
        "序号": 2867,
        "name": "Element Solutions Inc",
        "最新价": 20.82,
        "涨跌额": 0.16,
        "涨跌幅": 0.77,
        "开盘价": 20.65,
        "最高价": 20.91,
        "最低价": 20.63,
        "昨收价": 20.66,
        "总市值": 5028369657.0,
        "市盈率": 93.64,
        "成交量": 897564.0,
        "成交额": 18673743.0,
        "振幅": 1.36,
        "换手率": 0.37,
        "symbol": "106.ESI"
    },
    {
        "序号": 2868,
        "name": "云计算ETF-First Trust",
        "最新价": 83.31,
        "涨跌额": 0.64,
        "涨跌幅": 0.77,
        "开盘价": 81.82,
        "最高价": 83.52,
        "最低价": 81.82,
        "昨收价": 82.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 69715.0,
        "成交额": 5792086.0,
        "振幅": 2.06,
        "换手率": null,
        "symbol": "105.SKYY"
    },
    {
        "序号": 2869,
        "name": "艾涛巴西联合银行",
        "最新价": 6.51,
        "涨跌额": 0.05,
        "涨跌幅": 0.77,
        "开盘价": 6.39,
        "最高价": 6.53,
        "最低价": 6.39,
        "昨收价": 6.46,
        "总市值": 63816746867.0,
        "市盈率": 9.82,
        "成交量": 17808986.0,
        "成交额": 115342392.0,
        "振幅": 2.17,
        "换手率": 0.18,
        "symbol": "106.ITUB"
    },
    {
        "序号": 2870,
        "name": "塞拉银行",
        "最新价": 20.84,
        "涨跌额": 0.16,
        "涨跌幅": 0.77,
        "开盘价": 20.75,
        "最高价": 20.99,
        "最低价": 20.75,
        "昨收价": 20.68,
        "总市值": 306388284.0,
        "市盈率": 8.59,
        "成交量": 9351.0,
        "成交额": 195101.0,
        "振幅": 1.16,
        "换手率": 0.06,
        "symbol": "105.BSRR"
    },
    {
        "序号": 2871,
        "name": "Merrimack Pharmaceuticals Inc",
        "最新价": 13.03,
        "涨跌额": 0.1,
        "涨跌幅": 0.77,
        "开盘价": 13.0,
        "最高价": 13.06,
        "最低价": 12.97,
        "昨收价": 12.93,
        "总市值": 186602018.0,
        "市盈率": -130.22,
        "成交量": 24786.0,
        "成交额": 322561.0,
        "振幅": 0.7,
        "换手率": 0.17,
        "symbol": "105.MACK"
    },
    {
        "序号": 2872,
        "name": "黑石资本投资",
        "最新价": 3.91,
        "涨跌额": 0.03,
        "涨跌幅": 0.77,
        "开盘价": 3.88,
        "最高价": 3.91,
        "最低价": 3.88,
        "昨收价": 3.88,
        "总市值": 283756156.0,
        "市盈率": 18.02,
        "成交量": 130867.0,
        "成交额": 509559.0,
        "振幅": 0.77,
        "换手率": 0.18,
        "symbol": "105.BKCC"
    },
    {
        "序号": 2873,
        "name": "Diamondback Energy Inc",
        "最新价": 148.73,
        "涨跌额": 1.14,
        "涨跌幅": 0.77,
        "开盘价": 148.24,
        "最高价": 150.41,
        "最低价": 148.0,
        "昨收价": 147.59,
        "总市值": 26620425813.0,
        "市盈率": 8.34,
        "成交量": 1596061.0,
        "成交额": 237793596.0,
        "振幅": 1.63,
        "换手率": 0.89,
        "symbol": "105.FANG"
    },
    {
        "序号": 2874,
        "name": "Regency Centers Corp",
        "最新价": 63.96,
        "涨跌额": 0.49,
        "涨跌幅": 0.77,
        "开盘价": 63.24,
        "最高价": 64.0,
        "最低价": 62.61,
        "昨收价": 63.47,
        "总市值": 11805799545.0,
        "市盈率": 31.9,
        "成交量": 1079541.0,
        "成交额": 68648932.0,
        "振幅": 2.19,
        "换手率": 0.58,
        "symbol": "105.REG"
    },
    {
        "序号": 2875,
        "name": "Xtrackers Cybersecurity Select ",
        "最新价": 28.75,
        "涨跌额": 0.22,
        "涨跌幅": 0.77,
        "开盘价": 28.33,
        "最高价": 28.75,
        "最低价": 28.33,
        "昨收价": 28.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 302.0,
        "成交额": 8555.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "105.PSWD"
    },
    {
        "序号": 2876,
        "name": "Builders FirstSource Inc",
        "最新价": 147.7,
        "涨跌额": 1.13,
        "涨跌幅": 0.77,
        "开盘价": 146.57,
        "最高价": 149.12,
        "最低价": 146.43,
        "昨收价": 146.57,
        "总市值": 18218318668.0,
        "市盈率": 11.57,
        "成交量": 1231897.0,
        "成交额": 181868487.0,
        "振幅": 1.84,
        "换手率": 1.0,
        "symbol": "106.BLDR"
    },
    {
        "序号": 2877,
        "name": "Global X MSCI China Information",
        "最新价": 14.39,
        "涨跌额": 0.11,
        "涨跌幅": 0.77,
        "开盘价": 14.37,
        "最高价": 14.39,
        "最低价": 14.37,
        "昨收价": 14.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 308.0,
        "成交额": 4425.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.CHIK"
    },
    {
        "序号": 2878,
        "name": "iShares Focused Value Factor ET",
        "最新价": 54.95,
        "涨跌额": 0.42,
        "涨跌幅": 0.77,
        "开盘价": 54.74,
        "最高价": 55.0,
        "最低价": 54.74,
        "昨收价": 54.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1062.0,
        "成交额": 58295.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FOVL"
    },
    {
        "序号": 2879,
        "name": "NASDAQ网络安全ETF-First Trust",
        "最新价": 51.04,
        "涨跌额": 0.39,
        "涨跌幅": 0.77,
        "开盘价": 50.41,
        "最高价": 51.15,
        "最低价": 50.41,
        "昨收价": 50.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 295709.0,
        "成交额": 15040998.0,
        "振幅": 1.46,
        "换手率": null,
        "symbol": "105.CIBR"
    },
    {
        "序号": 2880,
        "name": "朱伊特卡梅伦贸易",
        "最新价": 5.24,
        "涨跌额": 0.04,
        "涨跌幅": 0.77,
        "开盘价": 5.25,
        "最高价": 5.25,
        "最低价": 5.24,
        "昨收价": 5.2,
        "总市值": 18334231.0,
        "市盈率": -888.89,
        "成交量": 4413.0,
        "成交额": 23166.0,
        "振幅": 0.19,
        "换手率": 0.13,
        "symbol": "105.JCTCF"
    },
    {
        "序号": 2881,
        "name": "Ironwood Pharmaceuticals Inc-A",
        "最新价": 10.48,
        "涨跌额": 0.08,
        "涨跌幅": 0.77,
        "开盘价": 10.37,
        "最高价": 10.6,
        "最低价": 10.26,
        "昨收价": 10.4,
        "总市值": 1636232402.0,
        "市盈率": -1.72,
        "成交量": 1736452.0,
        "成交额": 18234925.0,
        "振幅": 3.27,
        "换手率": 1.11,
        "symbol": "105.IRWD"
    },
    {
        "序号": 2882,
        "name": "American Outdoor Brands Inc",
        "最新价": 7.86,
        "涨跌额": 0.06,
        "涨跌幅": 0.77,
        "开盘价": 7.71,
        "最高价": 8.0,
        "最低价": 7.61,
        "昨收价": 7.8,
        "总市值": 101682187.0,
        "市盈率": -9.47,
        "成交量": 43695.0,
        "成交额": 341523.0,
        "振幅": 5.0,
        "换手率": 0.34,
        "symbol": "105.AOUT"
    },
    {
        "序号": 2883,
        "name": "Cencora Inc",
        "最新价": 201.75,
        "涨跌额": 1.54,
        "涨跌幅": 0.77,
        "开盘价": 200.5,
        "最高价": 201.92,
        "最低价": 199.55,
        "昨收价": 200.21,
        "总市值": 40493714192.0,
        "市盈率": 23.2,
        "成交量": 1441832.0,
        "成交额": 289656144.0,
        "振幅": 1.18,
        "换手率": 0.72,
        "symbol": "106.COR"
    },
    {
        "序号": 2884,
        "name": "WisdomTree Europe Hedged SmallC",
        "最新价": 35.39,
        "涨跌额": 0.27,
        "涨跌幅": 0.77,
        "开盘价": 35.25,
        "最高价": 35.39,
        "最低价": 35.25,
        "昨收价": 35.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 4386.0,
        "成交额": 155150.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.EUSC"
    },
    {
        "序号": 2885,
        "name": "Brookfield Infrastructure Corp-",
        "最新价": 32.81,
        "涨跌额": 0.25,
        "涨跌幅": 0.77,
        "开盘价": 32.47,
        "最高价": 32.97,
        "最低价": 32.36,
        "昨收价": 32.56,
        "总市值": 4689118221.0,
        "市盈率": 5.19,
        "成交量": 502268.0,
        "成交额": 16454763.0,
        "振幅": 1.87,
        "换手率": 0.35,
        "symbol": "106.BIPC"
    },
    {
        "序号": 2886,
        "name": "Roundhill Acquirers Deep Value ",
        "最新价": 34.14,
        "涨跌额": 0.26,
        "涨跌幅": 0.77,
        "开盘价": 33.87,
        "最高价": 34.21,
        "最低价": 33.87,
        "昨收价": 33.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 3058.0,
        "成交额": 103933.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.DEEP"
    },
    {
        "序号": 2887,
        "name": "TriCo Bancshares",
        "最新价": 39.4,
        "涨跌额": 0.3,
        "涨跌幅": 0.77,
        "开盘价": 39.34,
        "最高价": 39.75,
        "最低价": 39.25,
        "昨收价": 39.1,
        "总市值": 1310763219.0,
        "市盈率": 10.27,
        "成交量": 90190.0,
        "成交额": 3557878.0,
        "振幅": 1.28,
        "换手率": 0.27,
        "symbol": "105.TCBK"
    },
    {
        "序号": 2888,
        "name": "Return Stacked U.S. Stocks & Ma",
        "最新价": 19.7,
        "涨跌额": 0.15,
        "涨跌幅": 0.77,
        "开盘价": 19.65,
        "最高价": 19.71,
        "最低价": 19.55,
        "昨收价": 19.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 32367.0,
        "成交额": 634873.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.RSST"
    },
    {
        "序号": 2889,
        "name": "Encore Wire Corp",
        "最新价": 191.93,
        "涨跌额": 1.46,
        "涨跌幅": 0.77,
        "开盘价": 190.85,
        "最高价": 194.47,
        "最低价": 190.73,
        "昨收价": 190.47,
        "总市值": 3027946603.0,
        "市盈率": 6.58,
        "成交量": 176560.0,
        "成交额": 33890615.0,
        "振幅": 1.96,
        "换手率": 1.12,
        "symbol": "105.WIRE"
    },
    {
        "序号": 2890,
        "name": "美国航天国防ETF-iShares",
        "最新价": 122.28,
        "涨跌额": 0.93,
        "涨跌幅": 0.77,
        "开盘价": 121.21,
        "最高价": 122.42,
        "最低价": 121.21,
        "昨收价": 121.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 263508.0,
        "成交额": 32177368.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.ITA"
    },
    {
        "序号": 2891,
        "name": "First Hawaiian Inc",
        "最新价": 21.04,
        "涨跌额": 0.16,
        "涨跌幅": 0.77,
        "开盘价": 20.91,
        "最高价": 21.24,
        "最低价": 20.73,
        "昨收价": 20.88,
        "总市值": 2684958226.0,
        "市盈率": 10.05,
        "成交量": 664627.0,
        "成交额": 13976330.0,
        "振幅": 2.44,
        "换手率": 0.52,
        "symbol": "105.FHB"
    },
    {
        "序号": 2892,
        "name": "Dimensional U.S. Targeted Value",
        "最新价": 48.71,
        "涨跌额": 0.37,
        "涨跌幅": 0.77,
        "开盘价": 48.35,
        "最高价": 48.91,
        "最低价": 48.35,
        "昨收价": 48.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 314597.0,
        "成交额": 15297256.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DFAT"
    },
    {
        "序号": 2893,
        "name": "Simplify Volt RoboCar Disruptio",
        "最新价": 9.22,
        "涨跌额": 0.07,
        "涨跌幅": 0.77,
        "开盘价": 9.19,
        "最高价": 9.27,
        "最低价": 9.14,
        "昨收价": 9.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3253.0,
        "成交额": 29941.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.VCAR"
    },
    {
        "序号": 2894,
        "name": "Vanguard Extended Market ETF",
        "最新价": 154.18,
        "涨跌额": 1.17,
        "涨跌幅": 0.76,
        "开盘价": 152.7,
        "最高价": 154.72,
        "最低价": 152.7,
        "昨收价": 153.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1248185.0,
        "成交额": 192244144.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.VXF"
    },
    {
        "序号": 2895,
        "name": "Northwest Bancshares Inc",
        "最新价": 11.87,
        "涨跌额": 0.09,
        "涨跌幅": 0.76,
        "开盘价": 11.53,
        "最高价": 11.9,
        "最低价": 11.39,
        "昨收价": 11.78,
        "总市值": 1508729916.0,
        "市盈率": 10.73,
        "成交量": 1282224.0,
        "成交额": 15100958.0,
        "振幅": 4.33,
        "换手率": 1.01,
        "symbol": "105.NWBI"
    },
    {
        "序号": 2896,
        "name": "永明金融",
        "最新价": 51.45,
        "涨跌额": 0.39,
        "涨跌幅": 0.76,
        "开盘价": 51.0,
        "最高价": 51.47,
        "最低价": 51.0,
        "昨收价": 51.06,
        "总市值": 30061998330.0,
        "市盈率": 10.7,
        "成交量": 905645.0,
        "成交额": 46570343.0,
        "振幅": 0.92,
        "换手率": 0.15,
        "symbol": "106.SLF"
    },
    {
        "序号": 2897,
        "name": "USA Compression Partners LP",
        "最新价": 23.75,
        "涨跌额": 0.18,
        "涨跌幅": 0.76,
        "开盘价": 23.6,
        "最高价": 23.97,
        "最低价": 23.29,
        "昨收价": 23.57,
        "总市值": 2334607069.0,
        "市盈率": 36.6,
        "成交量": 636349.0,
        "成交额": 15125265.0,
        "振幅": 2.89,
        "换手率": 0.65,
        "symbol": "106.USAC"
    },
    {
        "序号": 2898,
        "name": "PowerSchool Holdings Inc-A",
        "最新价": 22.46,
        "涨跌额": 0.17,
        "涨跌幅": 0.76,
        "开盘价": 22.09,
        "最高价": 22.62,
        "最低价": 22.09,
        "昨收价": 22.29,
        "总市值": 4535584955.0,
        "市盈率": -264.57,
        "成交量": 459643.0,
        "成交额": 10293459.0,
        "振幅": 2.38,
        "换手率": 0.23,
        "symbol": "106.PWSC"
    },
    {
        "序号": 2899,
        "name": "Sixth Street专业贷款",
        "最新价": 21.14,
        "涨跌额": 0.16,
        "涨跌幅": 0.76,
        "开盘价": 21.0,
        "最高价": 21.18,
        "最低价": 21.0,
        "昨收价": 20.98,
        "总市值": 1850732968.0,
        "市盈率": 8.52,
        "成交量": 188213.0,
        "成交额": 3973419.0,
        "振幅": 0.86,
        "换手率": 0.21,
        "symbol": "106.TSLX"
    },
    {
        "序号": 2900,
        "name": "Wix.com Ltd",
        "最新价": 103.18,
        "涨跌额": 0.78,
        "涨跌幅": 0.76,
        "开盘价": 102.41,
        "最高价": 105.42,
        "最低价": 102.41,
        "昨收价": 102.4,
        "总市值": 5897881060.0,
        "市盈率": -670.82,
        "成交量": 235858.0,
        "成交额": 24445960.0,
        "振幅": 2.94,
        "换手率": 0.41,
        "symbol": "105.WIX"
    },
    {
        "序号": 2901,
        "name": "First Trust Mid Cap Core AlphaD",
        "最新价": 97.96,
        "涨跌额": 0.74,
        "涨跌幅": 0.76,
        "开盘价": 97.03,
        "最高价": 98.28,
        "最低价": 97.03,
        "昨收价": 97.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 16822.0,
        "成交额": 1644087.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "105.FNX"
    },
    {
        "序号": 2902,
        "name": "Franklin Universal Trust",
        "最新价": 6.62,
        "涨跌额": 0.05,
        "涨跌幅": 0.76,
        "开盘价": 6.59,
        "最高价": 6.63,
        "最低价": 6.56,
        "昨收价": 6.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 49351.0,
        "成交额": 325516.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "106.FT"
    },
    {
        "序号": 2903,
        "name": "罗素2000ETF-iShares",
        "最新价": 186.8,
        "涨跌额": 1.41,
        "涨跌幅": 0.76,
        "开盘价": 185.15,
        "最高价": 187.75,
        "最低价": 184.86,
        "昨收价": 185.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 36087140.0,
        "成交额": 6732731648.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "107.IWM"
    },
    {
        "序号": 2904,
        "name": "Third Coast Bancshares Inc",
        "最新价": 18.55,
        "涨跌额": 0.14,
        "涨跌幅": 0.76,
        "开盘价": 18.45,
        "最高价": 18.81,
        "最低价": 18.3,
        "昨收价": 18.41,
        "总市值": 252362937.0,
        "市盈率": 8.08,
        "成交量": 9720.0,
        "成交额": 180985.0,
        "振幅": 2.77,
        "换手率": 0.07,
        "symbol": "105.TCBX"
    },
    {
        "序号": 2905,
        "name": "Cullman Bancorp Inc",
        "最新价": 10.6,
        "涨跌额": 0.08,
        "涨跌幅": 0.76,
        "开盘价": 10.46,
        "最高价": 10.6,
        "最低价": 10.46,
        "昨收价": 10.52,
        "总市值": 76920607.0,
        "市盈率": 18.96,
        "成交量": 4599.0,
        "成交额": 48659.0,
        "振幅": 1.33,
        "换手率": 0.06,
        "symbol": "105.CULL"
    },
    {
        "序号": 2906,
        "name": "伊森艾伦室内装饰",
        "最新价": 29.18,
        "涨跌额": 0.22,
        "涨跌幅": 0.76,
        "开盘价": 28.81,
        "最高价": 29.19,
        "最低价": 28.81,
        "昨收价": 28.96,
        "总市值": 741150232.0,
        "市盈率": 8.16,
        "成交量": 271966.0,
        "成交额": 7907531.0,
        "振幅": 1.31,
        "换手率": 1.07,
        "symbol": "106.ETD"
    },
    {
        "序号": 2907,
        "name": "Rithm Capital Corp Series A Pfd",
        "最新价": 23.88,
        "涨跌额": 0.18,
        "涨跌幅": 0.76,
        "开盘价": 23.78,
        "最高价": 23.88,
        "最低价": 23.56,
        "昨收价": 23.7,
        "总市值": 148294800.0,
        "市盈率": null,
        "成交量": 11629.0,
        "成交额": 276052.0,
        "振幅": 1.35,
        "换手率": 0.19,
        "symbol": "106.RITM_A"
    },
    {
        "序号": 2908,
        "name": "Innovator Uncapped Accelerated ",
        "最新价": 29.24,
        "涨跌额": 0.22,
        "涨跌幅": 0.76,
        "开盘价": 28.99,
        "最高价": 29.24,
        "最低价": 28.99,
        "昨收价": 29.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 17082.0,
        "成交额": 496682.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.XUSP"
    },
    {
        "序号": 2909,
        "name": "安博",
        "最新价": 119.85,
        "涨跌额": 0.9,
        "涨跌幅": 0.76,
        "开盘价": 118.57,
        "最高价": 119.92,
        "最低价": 118.04,
        "昨收价": 118.95,
        "总市值": 110737924350.0,
        "市盈率": 36.72,
        "成交量": 4257707.0,
        "成交额": 508678400.0,
        "振幅": 1.58,
        "换手率": 0.46,
        "symbol": "106.PLD"
    },
    {
        "序号": 2910,
        "name": "标普500科技股等权ETF-Guggenheim",
        "最新价": 30.64,
        "涨跌额": 0.23,
        "涨跌幅": 0.76,
        "开盘价": 30.37,
        "最高价": 30.68,
        "最低价": 30.35,
        "昨收价": 30.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 421527.0,
        "成交额": 12887412.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.RSPT"
    },
    {
        "序号": 2911,
        "name": "Green Plains Partners LP",
        "最新价": 11.99,
        "涨跌额": 0.09,
        "涨跌幅": 0.76,
        "开盘价": 11.97,
        "最高价": 12.02,
        "最低价": 11.8,
        "昨收价": 11.9,
        "总市值": 278945348.0,
        "市盈率": 7.29,
        "成交量": 113610.0,
        "成交额": 1349617.0,
        "振幅": 1.85,
        "换手率": 0.49,
        "symbol": "105.GPP"
    },
    {
        "序号": 2912,
        "name": "TriSalus Life Sciences Inc",
        "最新价": 4.0,
        "涨跌额": 0.03,
        "涨跌幅": 0.76,
        "开盘价": 4.0,
        "最高价": 4.28,
        "最低价": 3.92,
        "昨收价": 3.97,
        "总市值": 105266724.0,
        "市盈率": 15.82,
        "成交量": 58512.0,
        "成交额": 237894.0,
        "振幅": 9.07,
        "换手率": 0.22,
        "symbol": "105.TLSI"
    },
    {
        "序号": 2913,
        "name": "Tactile Systems Technology Inc",
        "最新价": 13.34,
        "涨跌额": 0.1,
        "涨跌幅": 0.76,
        "开盘价": 13.24,
        "最高价": 13.5,
        "最低价": 13.06,
        "昨收价": 13.24,
        "总市值": 313457410.0,
        "市盈率": 12.57,
        "成交量": 306142.0,
        "成交额": 4082701.0,
        "振幅": 3.32,
        "换手率": 1.3,
        "symbol": "105.TCMD"
    },
    {
        "序号": 2914,
        "name": "Midwest Holding Inc",
        "最新价": 26.72,
        "涨跌额": 0.2,
        "涨跌幅": 0.75,
        "开盘价": 26.45,
        "最高价": 26.72,
        "最低价": 26.45,
        "昨收价": 26.52,
        "总市值": 100056914.0,
        "市盈率": -10.63,
        "成交量": 5506.0,
        "成交额": 146592.0,
        "振幅": 1.02,
        "换手率": 0.15,
        "symbol": "105.MDWT"
    },
    {
        "序号": 2915,
        "name": "罗素2000成长股ETF-iShares",
        "最新价": 233.8,
        "涨跌额": 1.75,
        "涨跌幅": 0.75,
        "开盘价": 232.09,
        "最高价": 235.0,
        "最低价": 231.3,
        "昨收价": 232.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 683494.0,
        "成交额": 159597611.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "107.IWO"
    },
    {
        "序号": 2916,
        "name": "浸入科技",
        "最新价": 6.68,
        "涨跌额": 0.05,
        "涨跌幅": 0.75,
        "开盘价": 6.61,
        "最高价": 6.7,
        "最低价": 6.58,
        "昨收价": 6.63,
        "总市值": 212691106.0,
        "市盈率": 5.64,
        "成交量": 316122.0,
        "成交额": 2103239.0,
        "振幅": 1.81,
        "换手率": 0.99,
        "symbol": "105.IMMR"
    },
    {
        "序号": 2917,
        "name": "First Trust Multi-Manager Small",
        "最新价": 16.04,
        "涨跌额": 0.12,
        "涨跌幅": 0.75,
        "开盘价": 16.02,
        "最高价": 16.04,
        "最低价": 16.02,
        "昨收价": 15.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 2001.0,
        "成交额": 32062.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.MMSC"
    },
    {
        "序号": 2918,
        "name": "韩国友利",
        "最新价": 29.41,
        "涨跌额": 0.22,
        "涨跌幅": 0.75,
        "开盘价": 29.16,
        "最高价": 29.46,
        "最低价": 29.15,
        "昨收价": 29.19,
        "总市值": 7371082375.0,
        "市盈率": 3.15,
        "成交量": 24496.0,
        "成交额": 718953.0,
        "振幅": 1.06,
        "换手率": 0.01,
        "symbol": "106.WF"
    },
    {
        "序号": 2919,
        "name": "Fidelity Blue Chip Growth ETF",
        "最新价": 32.1,
        "涨跌额": 0.24,
        "涨跌幅": 0.75,
        "开盘价": 31.72,
        "最高价": 32.13,
        "最低价": 31.64,
        "昨收价": 31.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 111638.0,
        "成交额": 3569155.0,
        "振幅": 1.54,
        "换手率": null,
        "symbol": "107.FBCG"
    },
    {
        "序号": 2920,
        "name": "ONE Gas Inc",
        "最新价": 61.55,
        "涨跌额": 0.46,
        "涨跌幅": 0.75,
        "开盘价": 61.12,
        "最高价": 61.72,
        "最低价": 60.68,
        "昨收价": 61.09,
        "总市值": 3413196778.0,
        "市盈率": 15.0,
        "成交量": 486118.0,
        "成交额": 29855072.0,
        "振幅": 1.7,
        "换手率": 0.88,
        "symbol": "106.OGS"
    },
    {
        "序号": 2921,
        "name": "First Trust Active Factor Mid C",
        "最新价": 25.43,
        "涨跌额": 0.19,
        "涨跌幅": 0.75,
        "开盘价": 25.44,
        "最高价": 25.47,
        "最低价": 25.43,
        "昨收价": 25.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 2874.0,
        "成交额": 73159.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.AFMC"
    },
    {
        "序号": 2922,
        "name": "Amdocs Ltd",
        "最新价": 85.73,
        "涨跌额": 0.64,
        "涨跌幅": 0.75,
        "开盘价": 84.84,
        "最高价": 85.83,
        "最低价": 84.84,
        "昨收价": 85.09,
        "总市值": 10204098980.0,
        "市盈率": 18.87,
        "成交量": 581434.0,
        "成交额": 49763176.0,
        "振幅": 1.16,
        "换手率": 0.49,
        "symbol": "105.DOX"
    },
    {
        "序号": 2923,
        "name": "新东方",
        "最新价": 80.39,
        "涨跌额": 0.6,
        "涨跌幅": 0.75,
        "开盘价": 79.05,
        "最高价": 80.97,
        "最低价": 79.05,
        "昨收价": 79.79,
        "总市值": 13305544191.0,
        "市盈率": 48.08,
        "成交量": 904737.0,
        "成交额": 72584706.0,
        "振幅": 2.41,
        "换手率": 0.55,
        "symbol": "106.EDU"
    },
    {
        "序号": 2924,
        "name": "AdvisorShares Psychedelics ETF",
        "最新价": 1.34,
        "涨跌额": 0.01,
        "涨跌幅": 0.75,
        "开盘价": 1.31,
        "最高价": 1.36,
        "最低价": 1.31,
        "昨收价": 1.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 19547.0,
        "成交额": 26089.0,
        "振幅": 3.76,
        "换手率": null,
        "symbol": "107.PSIL"
    },
    {
        "序号": 2925,
        "name": "芬兰ETF-iShares MSCI",
        "最新价": 34.84,
        "涨跌额": 0.26,
        "涨跌幅": 0.75,
        "开盘价": 34.85,
        "最高价": 34.86,
        "最低价": 34.84,
        "昨收价": 34.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 999.0,
        "成交额": 34819.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.EFNL"
    },
    {
        "序号": 2926,
        "name": "First Trust Technology AlphaDEX",
        "最新价": 122.07,
        "涨跌额": 0.91,
        "涨跌幅": 0.75,
        "开盘价": 120.63,
        "最高价": 122.35,
        "最低价": 120.63,
        "昨收价": 121.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 191171.0,
        "成交额": 23245244.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.FXL"
    },
    {
        "序号": 2927,
        "name": "Alerian Energy Infrastructure E",
        "最新价": 22.81,
        "涨跌额": 0.17,
        "涨跌幅": 0.75,
        "开盘价": 22.68,
        "最高价": 22.85,
        "最低价": 22.68,
        "昨收价": 22.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 17353.0,
        "成交额": 395027.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.ENFR"
    },
    {
        "序号": 2928,
        "name": "墨式烧烤",
        "最新价": 2242.95,
        "涨跌额": 16.7,
        "涨跌幅": 0.75,
        "开盘价": 2224.17,
        "最高价": 2244.71,
        "最低价": 2221.45,
        "昨收价": 2226.25,
        "总市值": 61557000147.0,
        "市盈率": 52.6,
        "成交量": 163445.0,
        "成交额": 365832400.0,
        "振幅": 1.04,
        "换手率": 0.6,
        "symbol": "106.CMG"
    },
    {
        "序号": 2929,
        "name": "North American Construction Gro",
        "最新价": 20.15,
        "涨跌额": 0.15,
        "涨跌幅": 0.75,
        "开盘价": 20.12,
        "最高价": 20.54,
        "最低价": 20.04,
        "昨收价": 20.0,
        "总市值": 538752464.0,
        "市盈率": 10.1,
        "成交量": 29710.0,
        "成交额": 598941.0,
        "振幅": 2.5,
        "换手率": 0.11,
        "symbol": "106.NOA"
    },
    {
        "序号": 2930,
        "name": "怡安保险",
        "最新价": 324.1,
        "涨跌额": 2.41,
        "涨跌幅": 0.75,
        "开盘价": 322.14,
        "最高价": 324.21,
        "最低价": 320.36,
        "昨收价": 321.69,
        "总市值": 64890117415.0,
        "市盈率": 23.83,
        "成交量": 978881.0,
        "成交额": 316371936.0,
        "振幅": 1.2,
        "换手率": 0.49,
        "symbol": "106.AON"
    },
    {
        "序号": 2931,
        "name": "DHC Acquisition Corp-A",
        "最新价": 10.76,
        "涨跌额": 0.08,
        "涨跌幅": 0.75,
        "开盘价": 10.57,
        "最高价": 10.99,
        "最低价": 10.55,
        "昨收价": 10.68,
        "总市值": 132438610.0,
        "市盈率": 37.79,
        "成交量": 6470.0,
        "成交额": 69026.0,
        "振幅": 4.12,
        "换手率": 0.05,
        "symbol": "105.DHCA"
    },
    {
        "序号": 2932,
        "name": "Invesco Electric Vehicle Metals",
        "最新价": 17.5,
        "涨跌额": 0.13,
        "涨跌幅": 0.75,
        "开盘价": 17.5,
        "最高价": 17.5,
        "最低价": 17.5,
        "昨收价": 17.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 1214.0,
        "成交额": 21244.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EVMT"
    },
    {
        "序号": 2933,
        "name": "Altair Engineering Inc-A",
        "最新价": 71.35,
        "涨跌额": 0.53,
        "涨跌幅": 0.75,
        "开盘价": 70.32,
        "最高价": 71.9,
        "最低价": 70.23,
        "昨收价": 70.82,
        "总市值": 5826567290.0,
        "市盈率": -352.36,
        "成交量": 219208.0,
        "成交额": 15613465.0,
        "振幅": 2.36,
        "换手率": 0.27,
        "symbol": "105.ALTR"
    },
    {
        "序号": 2934,
        "name": "AdvisorShares Hotel ETF",
        "最新价": 26.93,
        "涨跌额": 0.2,
        "涨跌幅": 0.75,
        "开盘价": 26.89,
        "最高价": 26.97,
        "最低价": 26.84,
        "昨收价": 26.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 989.0,
        "成交额": 26616.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.BEDZ"
    },
    {
        "序号": 2935,
        "name": "SPDR NYSE Technology ETF",
        "最新价": 156.2,
        "涨跌额": 1.16,
        "涨跌幅": 0.75,
        "开盘价": 154.49,
        "最高价": 156.33,
        "最低价": 154.43,
        "昨收价": 155.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 9840.0,
        "成交额": 1531317.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.XNTK"
    },
    {
        "序号": 2936,
        "name": "Unitil Corp",
        "最新价": 51.17,
        "涨跌额": 0.38,
        "涨跌幅": 0.75,
        "开盘价": 50.61,
        "最高价": 51.17,
        "最低价": 49.81,
        "昨收价": 50.79,
        "总市值": 823747145.0,
        "市盈率": 18.64,
        "成交量": 41850.0,
        "成交额": 2131463.0,
        "振幅": 2.68,
        "换手率": 0.26,
        "symbol": "106.UTL"
    },
    {
        "序号": 2937,
        "name": "Kemper Corp",
        "最新价": 47.15,
        "涨跌额": 0.35,
        "涨跌幅": 0.75,
        "开盘价": 46.94,
        "最高价": 47.31,
        "最低价": 46.71,
        "昨收价": 46.8,
        "总市值": 3021709028.0,
        "市盈率": -7.72,
        "成交量": 254577.0,
        "成交额": 11996245.0,
        "振幅": 1.28,
        "换手率": 0.4,
        "symbol": "106.KMPR"
    },
    {
        "序号": 2938,
        "name": "德国汇率对冲ETF-iShares",
        "最新价": 32.34,
        "涨跌额": 0.24,
        "涨跌幅": 0.75,
        "开盘价": 32.18,
        "最高价": 32.34,
        "最低价": 32.18,
        "昨收价": 32.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1399.0,
        "成交额": 45019.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "105.HEWG"
    },
    {
        "序号": 2939,
        "name": "AAM Transformers ETF",
        "最新价": 29.65,
        "涨跌额": 0.22,
        "涨跌幅": 0.75,
        "开盘价": 29.51,
        "最高价": 29.66,
        "最低价": 29.51,
        "昨收价": 29.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 2833.0,
        "成交额": 83843.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.TRFM"
    },
    {
        "序号": 2940,
        "name": "Hartford Large Cap Growth ETF",
        "最新价": 16.19,
        "涨跌额": 0.12,
        "涨跌幅": 0.75,
        "开盘价": 16.08,
        "最高价": 16.19,
        "最低价": 16.08,
        "昨收价": 16.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 395.0,
        "成交额": 6351.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.HFGO"
    },
    {
        "序号": 2941,
        "name": "US Value ETF",
        "最新价": 28.35,
        "涨跌额": 0.21,
        "涨跌幅": 0.75,
        "开盘价": 28.35,
        "最高价": 28.35,
        "最低价": 28.35,
        "昨收价": 28.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 28.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.USVT"
    },
    {
        "序号": 2942,
        "name": "Calamos Convertible Equity Alte",
        "最新价": 25.65,
        "涨跌额": 0.19,
        "涨跌幅": 0.75,
        "开盘价": 25.52,
        "最高价": 25.65,
        "最低价": 25.47,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 10011.0,
        "成交额": 255585.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.CVRT"
    },
    {
        "序号": 2943,
        "name": "Rayliant Quantitative Developed",
        "最新价": 24.35,
        "涨跌额": 0.18,
        "涨跌幅": 0.74,
        "开盘价": 24.19,
        "最高价": 24.35,
        "最低价": 24.19,
        "昨收价": 24.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 700.0,
        "成交额": 16978.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.RAYD"
    },
    {
        "序号": 2944,
        "name": "Achari Ventures Holdings Corp I",
        "最新价": 10.83,
        "涨跌额": 0.08,
        "涨跌幅": 0.74,
        "开盘价": 10.71,
        "最高价": 10.85,
        "最低价": 10.71,
        "昨收价": 10.75,
        "总市值": 33988016.0,
        "市盈率": -15.13,
        "成交量": 3970.0,
        "成交额": 42755.0,
        "振幅": 1.3,
        "换手率": 0.13,
        "symbol": "105.AVHI"
    },
    {
        "序号": 2945,
        "name": "巴西ETF-iShares MSCI",
        "最新价": 33.85,
        "涨跌额": 0.25,
        "涨跌幅": 0.74,
        "开盘价": 33.48,
        "最高价": 34.03,
        "最低价": 33.47,
        "昨收价": 33.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 13372436.0,
        "成交额": 451856752.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "107.EWZ"
    },
    {
        "序号": 2946,
        "name": "英特格",
        "最新价": 106.99,
        "涨跌额": 0.79,
        "涨跌幅": 0.74,
        "开盘价": 105.78,
        "最高价": 107.97,
        "最低价": 105.61,
        "昨收价": 106.2,
        "总市值": 16065498892.0,
        "市盈率": 80.28,
        "成交量": 1662705.0,
        "成交额": 178060064.0,
        "振幅": 2.22,
        "换手率": 1.11,
        "symbol": "105.ENTG"
    },
    {
        "序号": 2947,
        "name": "Argent Mid Cap ETF",
        "最新价": 28.45,
        "涨跌额": 0.21,
        "涨跌幅": 0.74,
        "开盘价": 28.4,
        "最高价": 28.45,
        "最低价": 28.4,
        "昨收价": 28.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 763.0,
        "成交额": 21669.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.AMID"
    },
    {
        "序号": 2948,
        "name": "Advanced Drainage Systems Inc",
        "最新价": 126.23,
        "涨跌额": 0.93,
        "涨跌幅": 0.74,
        "开盘价": 125.5,
        "最高价": 126.8,
        "最低价": 124.87,
        "昨收价": 125.3,
        "总市值": 9834306138.0,
        "市盈率": 20.6,
        "成交量": 257666.0,
        "成交额": 32442543.0,
        "振幅": 1.54,
        "换手率": 0.33,
        "symbol": "106.WMS"
    },
    {
        "序号": 2949,
        "name": "Oaktree Specialty Lending Corp",
        "最新价": 20.37,
        "涨跌额": 0.15,
        "涨跌幅": 0.74,
        "开盘价": 20.24,
        "最高价": 20.43,
        "最低价": 20.15,
        "昨收价": 20.22,
        "总市值": 1573079952.0,
        "市盈率": 13.41,
        "成交量": 663126.0,
        "成交额": 13506019.0,
        "振幅": 1.38,
        "换手率": 0.86,
        "symbol": "105.OCSL"
    },
    {
        "序号": 2950,
        "name": "苹果",
        "最新价": 195.71,
        "涨跌额": 1.44,
        "涨跌幅": 0.74,
        "开盘价": 194.2,
        "最高价": 195.99,
        "最低价": 193.67,
        "昨收价": 194.27,
        "总市值": 3043829093920.0,
        "市盈率": 31.38,
        "成交量": 53406358.0,
        "成交额": 10422979840.0,
        "振幅": 1.19,
        "换手率": 0.34,
        "symbol": "105.AAPL"
    },
    {
        "序号": 2951,
        "name": "VictoryShares US Small Cap Vola",
        "最新价": 62.52,
        "涨跌额": 0.46,
        "涨跌幅": 0.74,
        "开盘价": 62.43,
        "最高价": 62.52,
        "最低价": 62.43,
        "昨收价": 62.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 345.0,
        "成交额": 21538.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "105.CSA"
    },
    {
        "序号": 2952,
        "name": "Vanguard Russell 2000 Value ETF",
        "最新价": 127.82,
        "涨跌额": 0.94,
        "涨跌幅": 0.74,
        "开盘价": 126.92,
        "最高价": 128.3,
        "最低价": 126.5,
        "昨收价": 126.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 40371.0,
        "成交额": 5147553.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "105.VTWV"
    },
    {
        "序号": 2953,
        "name": "WBI BullBear Value 3000 ETF",
        "最新价": 27.21,
        "涨跌额": 0.2,
        "涨跌幅": 0.74,
        "开盘价": 27.05,
        "最高价": 27.21,
        "最低价": 27.02,
        "昨收价": 27.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 10730.0,
        "成交额": 290447.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.WBIF"
    },
    {
        "序号": 2954,
        "name": "Bright Horizons Family Solution",
        "最新价": 91.24,
        "涨跌额": 0.67,
        "涨跌幅": 0.74,
        "开盘价": 90.24,
        "最高价": 91.27,
        "最低价": 88.99,
        "昨收价": 90.57,
        "总市值": 5283243623.0,
        "市盈率": 60.91,
        "成交量": 205938.0,
        "成交额": 18712385.0,
        "振幅": 2.52,
        "换手率": 0.36,
        "symbol": "106.BFAM"
    },
    {
        "序号": 2955,
        "name": "First Trust Rising Dividend Ach",
        "最新价": 49.05,
        "涨跌额": 0.36,
        "涨跌幅": 0.74,
        "开盘价": 48.71,
        "最高价": 49.14,
        "最低价": 48.66,
        "昨收价": 48.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 588666.0,
        "成交额": 28834657.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "105.RDVY"
    },
    {
        "序号": 2956,
        "name": "Academy Sports and Outdoors Inc",
        "最新价": 55.96,
        "涨跌额": 0.41,
        "涨跌幅": 0.74,
        "开盘价": 55.55,
        "最高价": 56.21,
        "最低价": 55.17,
        "昨收价": 55.55,
        "总市值": 4149511449.0,
        "市盈率": 8.16,
        "成交量": 1154909.0,
        "成交额": 64536110.0,
        "振幅": 1.87,
        "换手率": 1.56,
        "symbol": "105.ASO"
    },
    {
        "序号": 2957,
        "name": "第一社区银行股份",
        "最新价": 35.5,
        "涨跌额": 0.26,
        "涨跌幅": 0.74,
        "开盘价": 35.06,
        "最高价": 35.62,
        "最低价": 35.05,
        "昨收价": 35.24,
        "总市值": 658019835.0,
        "市盈率": 13.48,
        "成交量": 25926.0,
        "成交额": 918023.0,
        "振幅": 1.62,
        "换手率": 0.14,
        "symbol": "105.FCBC"
    },
    {
        "序号": 2958,
        "name": "欧元区ETF-iShares MSCI",
        "最新价": 46.45,
        "涨跌额": 0.34,
        "涨跌幅": 0.74,
        "开盘价": 46.1,
        "最高价": 46.47,
        "最低价": 46.1,
        "昨收价": 46.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1840635.0,
        "成交额": 85320813.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.EZU"
    },
    {
        "序号": 2959,
        "name": "Invesco S&P 500 Equal Weight Fi",
        "最新价": 56.03,
        "涨跌额": 0.41,
        "涨跌幅": 0.74,
        "开盘价": 55.84,
        "最高价": 56.09,
        "最低价": 55.72,
        "昨收价": 55.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 11700.0,
        "成交额": 654639.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.RSPF"
    },
    {
        "序号": 2960,
        "name": "罗盘矿物",
        "最新价": 24.6,
        "涨跌额": 0.18,
        "涨跌幅": 0.74,
        "开盘价": 24.34,
        "最高价": 25.0,
        "最低价": 24.34,
        "昨收价": 24.42,
        "总市值": 1013767009.0,
        "市盈率": 65.4,
        "成交量": 212490.0,
        "成交额": 5232526.0,
        "振幅": 2.7,
        "换手率": 0.52,
        "symbol": "106.CMP"
    },
    {
        "序号": 2961,
        "name": "Central Securities Corporation",
        "最新价": 36.91,
        "涨跌额": 0.27,
        "涨跌幅": 0.74,
        "开盘价": 36.74,
        "最高价": 36.99,
        "最低价": 36.6,
        "昨收价": 36.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 11183.0,
        "成交额": 410869.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.CET"
    },
    {
        "序号": 2962,
        "name": "God Bless America ETF",
        "最新价": 28.74,
        "涨跌额": 0.21,
        "涨跌幅": 0.74,
        "开盘价": 28.63,
        "最高价": 28.77,
        "最低价": 28.63,
        "昨收价": 28.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1957.0,
        "成交额": 56122.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.YALL"
    },
    {
        "序号": 2963,
        "name": "VictoryShares Free Cash Flow ET",
        "最新价": 27.38,
        "涨跌额": 0.2,
        "涨跌幅": 0.74,
        "开盘价": 27.2,
        "最高价": 27.38,
        "最低价": 27.2,
        "昨收价": 27.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 7106.0,
        "成交额": 194031.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "105.VFLO"
    },
    {
        "序号": 2964,
        "name": "SuRo Capital Corp",
        "最新价": 4.11,
        "涨跌额": 0.03,
        "涨跌幅": 0.74,
        "开盘价": 4.07,
        "最高价": 4.2,
        "最低价": 4.07,
        "昨收价": 4.08,
        "总市值": 103609434.0,
        "市盈率": 57.62,
        "成交量": 140961.0,
        "成交额": 584292.0,
        "振幅": 3.19,
        "换手率": 0.56,
        "symbol": "105.SSSS"
    },
    {
        "序号": 2965,
        "name": "California BanCorp",
        "最新价": 24.7,
        "涨跌额": 0.18,
        "涨跌幅": 0.73,
        "开盘价": 24.64,
        "最高价": 25.0,
        "最低价": 24.37,
        "昨收价": 24.52,
        "总市值": 207355561.0,
        "市盈率": 8.65,
        "成交量": 5289.0,
        "成交额": 131320.0,
        "振幅": 2.57,
        "换手率": 0.06,
        "symbol": "105.CALB"
    },
    {
        "序号": 2966,
        "name": "WABASH国立",
        "最新价": 24.73,
        "涨跌额": 0.18,
        "涨跌幅": 0.73,
        "开盘价": 24.6,
        "最高价": 25.1,
        "最低价": 24.6,
        "昨收价": 24.55,
        "总市值": 1139739003.0,
        "市盈率": 5.13,
        "成交量": 530343.0,
        "成交额": 13168041.0,
        "振幅": 2.04,
        "换手率": 1.15,
        "symbol": "106.WNC"
    },
    {
        "序号": 2967,
        "name": "SPDR S&P Kensho Final Frontiers",
        "最新价": 42.64,
        "涨跌额": 0.31,
        "涨跌幅": 0.73,
        "开盘价": 42.64,
        "最高价": 42.64,
        "最低价": 42.64,
        "昨收价": 42.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 158.0,
        "成交额": 6736.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ROKT"
    },
    {
        "序号": 2968,
        "name": "FT Cboe Vest Rising Dividend Ac",
        "最新价": 22.01,
        "涨跌额": 0.16,
        "涨跌幅": 0.73,
        "开盘价": 21.85,
        "最高价": 22.05,
        "最低价": 21.85,
        "昨收价": 21.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 161732.0,
        "成交额": 3554950.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.RDVI"
    },
    {
        "序号": 2969,
        "name": "Amplify Thematic All-Stars ETF",
        "最新价": 19.26,
        "涨跌额": 0.14,
        "涨跌幅": 0.73,
        "开盘价": 19.26,
        "最高价": 19.26,
        "最低价": 19.26,
        "昨收价": 19.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 57.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MVPS"
    },
    {
        "序号": 2970,
        "name": "NewMarket Corp",
        "最新价": 549.18,
        "涨跌额": 3.99,
        "涨跌幅": 0.73,
        "开盘价": 545.0,
        "最高价": 550.41,
        "最低价": 544.42,
        "昨收价": 545.19,
        "总市值": 5266719126.0,
        "市盈率": 13.2,
        "成交量": 69032.0,
        "成交额": 37751487.0,
        "振幅": 1.1,
        "换手率": 0.72,
        "symbol": "106.NEU"
    },
    {
        "序号": 2971,
        "name": "Franklin Intelligent Machines E",
        "最新价": 48.2,
        "涨跌额": 0.35,
        "涨跌幅": 0.73,
        "开盘价": 48.02,
        "最高价": 48.22,
        "最低价": 48.02,
        "昨收价": 47.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 10015.0,
        "成交额": 481201.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.IQM"
    },
    {
        "序号": 2972,
        "name": "Stewart Information Services Co",
        "最新价": 53.77,
        "涨跌额": 0.39,
        "涨跌幅": 0.73,
        "开盘价": 53.62,
        "最高价": 53.8,
        "最低价": 53.0,
        "昨收价": 53.38,
        "总市值": 1471268129.0,
        "市盈率": 42.11,
        "成交量": 164225.0,
        "成交额": 8789991.0,
        "振幅": 1.5,
        "换手率": 0.6,
        "symbol": "106.STC"
    },
    {
        "序号": 2973,
        "name": "RCF Acquisition Corp-A",
        "最新价": 11.03,
        "涨跌额": 0.08,
        "涨跌幅": 0.73,
        "开盘价": 10.94,
        "最高价": 11.03,
        "最低价": 10.94,
        "昨收价": 10.95,
        "总市值": 206971685.0,
        "市盈率": 20.01,
        "成交量": 217.0,
        "成交额": 2383.0,
        "振幅": 0.82,
        "换手率": 0.0,
        "symbol": "106.RCFA"
    },
    {
        "序号": 2974,
        "name": "MDU Resources Group Inc",
        "最新价": 19.31,
        "涨跌额": 0.14,
        "涨跌幅": 0.73,
        "开盘价": 19.17,
        "最高价": 19.37,
        "最低价": 19.13,
        "昨收价": 19.17,
        "总市值": 3932256983.0,
        "市盈率": 10.89,
        "成交量": 863623.0,
        "成交额": 16664252.0,
        "振幅": 1.25,
        "换手率": 0.42,
        "symbol": "106.MDU"
    },
    {
        "序号": 2975,
        "name": "艾里德克斯",
        "最新价": 2.76,
        "涨跌额": 0.02,
        "涨跌幅": 0.73,
        "开盘价": 2.72,
        "最高价": 2.8,
        "最低价": 2.72,
        "昨收价": 2.74,
        "总市值": 44843964.0,
        "市盈率": -5.78,
        "成交量": 39286.0,
        "成交额": 108362.0,
        "振幅": 2.92,
        "换手率": 0.24,
        "symbol": "105.IRIX"
    },
    {
        "序号": 2976,
        "name": "高途",
        "最新价": 2.76,
        "涨跌额": 0.02,
        "涨跌幅": 0.73,
        "开盘价": 2.7,
        "最高价": 2.89,
        "最低价": 2.7,
        "昨收价": 2.74,
        "总市值": 719457020.0,
        "市盈率": 28.07,
        "成交量": 2860086.0,
        "成交额": 7948233.0,
        "振幅": 6.93,
        "换手率": 1.1,
        "symbol": "106.GOTU"
    },
    {
        "序号": 2977,
        "name": "Inspire Fidelis Multi Factor ET",
        "最新价": 27.6,
        "涨跌额": 0.2,
        "涨跌幅": 0.73,
        "开盘价": 27.21,
        "最高价": 27.64,
        "最低价": 27.21,
        "昨收价": 27.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 2497.0,
        "成交额": 68780.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.FDLS"
    },
    {
        "序号": 2978,
        "name": "Chatham Lodging信托(优先股)",
        "最新价": 20.7,
        "涨跌额": 0.15,
        "涨跌幅": 0.73,
        "开盘价": 20.56,
        "最高价": 20.7,
        "最低价": 20.32,
        "昨收价": 20.55,
        "总市值": 99360000.0,
        "市盈率": null,
        "成交量": 2775.0,
        "成交额": 57008.0,
        "振幅": 1.85,
        "换手率": 0.06,
        "symbol": "106.CLDT_A"
    },
    {
        "序号": 2979,
        "name": "Adicet Bio Inc",
        "最新价": 1.38,
        "涨跌额": 0.01,
        "涨跌幅": 0.73,
        "开盘价": 1.38,
        "最高价": 1.41,
        "最低价": 1.27,
        "昨收价": 1.37,
        "总市值": 59567040.0,
        "市盈率": -0.42,
        "成交量": 303863.0,
        "成交额": 413231.0,
        "振幅": 10.22,
        "换手率": 0.7,
        "symbol": "105.ACET"
    },
    {
        "序号": 2980,
        "name": "BlackRock Future Tech ETF",
        "最新价": 22.1,
        "涨跌额": 0.16,
        "涨跌幅": 0.73,
        "开盘价": 22.1,
        "最高价": 22.1,
        "最低价": 22.1,
        "昨收价": 21.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 193.0,
        "成交额": 4265.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BTEK"
    },
    {
        "序号": 2981,
        "name": "大众银行(波多黎各)",
        "最新价": 77.43,
        "涨跌额": 0.56,
        "涨跌幅": 0.73,
        "开盘价": 77.05,
        "最高价": 78.04,
        "最低价": 76.68,
        "昨收价": 76.87,
        "总市值": 5586911785.0,
        "市盈率": 7.94,
        "成交量": 292208.0,
        "成交额": 22621107.0,
        "振幅": 1.77,
        "换手率": 0.4,
        "symbol": "105.BPOP"
    },
    {
        "序号": 2982,
        "name": "爵士制药",
        "最新价": 120.42,
        "涨跌额": 0.87,
        "涨跌幅": 0.73,
        "开盘价": 120.54,
        "最高价": 121.88,
        "最低价": 119.52,
        "昨收价": 119.55,
        "总市值": 7581271463.0,
        "市盈率": 94.82,
        "成交量": 504148.0,
        "成交额": 60679281.0,
        "振幅": 1.97,
        "换手率": 0.8,
        "symbol": "105.JAZZ"
    },
    {
        "序号": 2983,
        "name": "Direxion HCM Tactical Enhanced ",
        "最新价": 24.93,
        "涨跌额": 0.18,
        "涨跌幅": 0.73,
        "开盘价": 24.7,
        "最高价": 24.99,
        "最低价": 24.68,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 24835.0,
        "成交额": 617283.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.HCMT"
    },
    {
        "序号": 2984,
        "name": "Thornburg Income Builder Opport",
        "最新价": 15.24,
        "涨跌额": 0.11,
        "涨跌幅": 0.73,
        "开盘价": 15.22,
        "最高价": 15.4,
        "最低价": 15.12,
        "昨收价": 15.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 75043.0,
        "成交额": 1140145.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "105.TBLD"
    },
    {
        "序号": 2985,
        "name": "SGI U.S. Large Cap Core ETF",
        "最新价": 29.12,
        "涨跌额": 0.21,
        "涨跌幅": 0.73,
        "开盘价": 29.03,
        "最高价": 29.15,
        "最低价": 28.96,
        "昨收价": 28.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 10654.0,
        "成交额": 309509.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.SGLC"
    },
    {
        "序号": 2986,
        "name": "Thrivent Small-Mid Cap ESG ETF",
        "最新价": 29.18,
        "涨跌额": 0.21,
        "涨跌幅": 0.72,
        "开盘价": 29.03,
        "最高价": 29.24,
        "最低价": 29.03,
        "昨收价": 28.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 69935.0,
        "成交额": 2038145.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.TSME"
    },
    {
        "序号": 2987,
        "name": "诺瓦瓦克斯医药",
        "最新价": 5.57,
        "涨跌额": 0.04,
        "涨跌幅": 0.72,
        "开盘价": 5.5,
        "最高价": 5.62,
        "最低价": 5.43,
        "昨收价": 5.53,
        "总市值": 661661537.0,
        "市盈率": -1.21,
        "成交量": 5004316.0,
        "成交额": 27825063.0,
        "振幅": 3.44,
        "换手率": 4.21,
        "symbol": "105.NVAX"
    },
    {
        "序号": 2988,
        "name": "Pampa Energia SA ADR",
        "最新价": 47.37,
        "涨跌额": 0.34,
        "涨跌幅": 0.72,
        "开盘价": 46.91,
        "最高价": 47.89,
        "最低价": 46.1,
        "昨收价": 47.03,
        "总市值": 2583598416.0,
        "市盈率": 4.4,
        "成交量": 175539.0,
        "成交额": 8269204.0,
        "振幅": 3.81,
        "换手率": 0.32,
        "symbol": "106.PAM"
    },
    {
        "序号": 2989,
        "name": "ETRACS Monthly Pay 1.5X Leverag",
        "最新价": 18.12,
        "涨跌额": 0.13,
        "涨跌幅": 0.72,
        "开盘价": 18.09,
        "最高价": 18.25,
        "最低价": 18.0,
        "昨收价": 17.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 18397.0,
        "成交额": 333458.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "107.MVRL"
    },
    {
        "序号": 2990,
        "name": "GasLog Partners LP Series C Pfd",
        "最新价": 23.7,
        "涨跌额": 0.17,
        "涨跌幅": 0.72,
        "开盘价": 23.6,
        "最高价": 23.71,
        "最低价": 23.55,
        "昨收价": 23.53,
        "总市值": 72546767.0,
        "市盈率": null,
        "成交量": 3204.0,
        "成交额": 75632.0,
        "振幅": 0.68,
        "换手率": 0.1,
        "symbol": "106.GLOP_C"
    },
    {
        "序号": 2991,
        "name": "美国科技ETF-iShares",
        "最新价": 118.54,
        "涨跌额": 0.85,
        "涨跌幅": 0.72,
        "开盘价": 117.18,
        "最高价": 118.61,
        "最低价": 117.15,
        "昨收价": 117.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 1284115.0,
        "成交额": 151960944.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "107.IYW"
    },
    {
        "序号": 2992,
        "name": "Invesco S&P SmallCap Financials",
        "最新价": 46.08,
        "涨跌额": 0.33,
        "涨跌幅": 0.72,
        "开盘价": 45.7,
        "最高价": 46.08,
        "最低价": 45.7,
        "昨收价": 45.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 927.0,
        "成交额": 42487.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.PSCF"
    },
    {
        "序号": 2993,
        "name": "Willdan Group Inc",
        "最新价": 20.95,
        "涨跌额": 0.15,
        "涨跌幅": 0.72,
        "开盘价": 20.75,
        "最高价": 21.0,
        "最低价": 20.35,
        "昨收价": 20.8,
        "总市值": 285899790.0,
        "市盈率": 115.75,
        "成交量": 9908.0,
        "成交额": 206422.0,
        "振幅": 3.13,
        "换手率": 0.07,
        "symbol": "105.WLDN"
    },
    {
        "序号": 2994,
        "name": "Integer Holdings Corp",
        "最新价": 89.54,
        "涨跌额": 0.64,
        "涨跌幅": 0.72,
        "开盘价": 88.88,
        "最高价": 89.58,
        "最低价": 88.08,
        "昨收价": 88.9,
        "总市值": 2984067972.0,
        "市盈率": 36.21,
        "成交量": 152879.0,
        "成交额": 13643782.0,
        "振幅": 1.69,
        "换手率": 0.46,
        "symbol": "106.ITGR"
    },
    {
        "序号": 2995,
        "name": "Calliditas Therapeutics AB ADR",
        "最新价": 18.19,
        "涨跌额": 0.13,
        "涨跌幅": 0.72,
        "开盘价": 18.1,
        "最高价": 18.35,
        "最低价": 17.9,
        "昨收价": 18.06,
        "总市值": 541880891.0,
        "市盈率": -12.55,
        "成交量": 4867.0,
        "成交额": 88155.0,
        "振幅": 2.49,
        "换手率": 0.02,
        "symbol": "105.CALT"
    },
    {
        "序号": 2996,
        "name": "洲际交易所",
        "最新价": 113.34,
        "涨跌额": 0.81,
        "涨跌幅": 0.72,
        "开盘价": 112.34,
        "最高价": 113.44,
        "最低价": 112.2,
        "昨收价": 112.53,
        "总市值": 64871725106.0,
        "市盈率": 26.81,
        "成交量": 2505801.0,
        "成交额": 283497136.0,
        "振幅": 1.1,
        "换手率": 0.44,
        "symbol": "106.ICE"
    },
    {
        "序号": 2997,
        "name": "Take-Two Interactive Software I",
        "最新价": 155.32,
        "涨跌额": 1.11,
        "涨跌幅": 0.72,
        "开盘价": 154.38,
        "最高价": 156.72,
        "最低价": 154.08,
        "昨收价": 154.21,
        "总市值": 26414902738.0,
        "市盈率": -17.46,
        "成交量": 2078756.0,
        "成交额": 323303920.0,
        "振幅": 1.71,
        "换手率": 1.22,
        "symbol": "105.TTWO"
    },
    {
        "序号": 2998,
        "name": "PGIM Jennison Focused Growth ET",
        "最新价": 70.01,
        "涨跌额": 0.5,
        "涨跌幅": 0.72,
        "开盘价": 69.43,
        "最高价": 70.09,
        "最低价": 69.43,
        "昨收价": 69.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 2752.0,
        "成交额": 192592.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.PJFG"
    },
    {
        "序号": 2999,
        "name": "莱迪思半导体",
        "最新价": 61.71,
        "涨跌额": 0.44,
        "涨跌幅": 0.72,
        "开盘价": 61.19,
        "最高价": 62.27,
        "最低价": 60.78,
        "昨收价": 61.27,
        "总市值": 8517731145.0,
        "市盈率": 40.13,
        "成交量": 1831888.0,
        "成交额": 112922276.0,
        "振幅": 2.43,
        "换手率": 1.33,
        "symbol": "105.LSCC"
    },
    {
        "序号": 3000,
        "name": "Luther Burbank Corp",
        "最新价": 9.82,
        "涨跌额": 0.07,
        "涨跌幅": 0.72,
        "开盘价": 9.75,
        "最高价": 10.0,
        "最低价": 9.63,
        "昨收价": 9.75,
        "总市值": 500986429.0,
        "市盈率": 13.93,
        "成交量": 30007.0,
        "成交额": 293240.0,
        "振幅": 3.79,
        "换手率": 0.06,
        "symbol": "105.LBC"
    },
    {
        "序号": 3001,
        "name": "Golar LNG Ltd",
        "最新价": 21.05,
        "涨跌额": 0.15,
        "涨跌幅": 0.72,
        "开盘价": 21.06,
        "最高价": 21.32,
        "最低价": 20.62,
        "昨收价": 20.9,
        "总市值": 2229195000.0,
        "市盈率": 38.77,
        "成交量": 825816.0,
        "成交额": 17376035.0,
        "振幅": 3.35,
        "换手率": 0.78,
        "symbol": "105.GLNG"
    },
    {
        "序号": 3002,
        "name": "泰科电子",
        "最新价": 133.32,
        "涨跌额": 0.95,
        "涨跌幅": 0.72,
        "开盘价": 132.02,
        "最高价": 133.69,
        "最低价": 131.46,
        "昨收价": 132.37,
        "总市值": 41433092943.0,
        "市盈率": 21.69,
        "成交量": 1445577.0,
        "成交额": 192499162.0,
        "振幅": 1.68,
        "换手率": 0.47,
        "symbol": "106.TEL"
    },
    {
        "序号": 3003,
        "name": "Donnelley Financial Solutions I",
        "最新价": 60.36,
        "涨跌额": 0.43,
        "涨跌幅": 0.72,
        "开盘价": 59.58,
        "最高价": 60.57,
        "最低价": 59.47,
        "昨收价": 59.93,
        "总市值": 1756937150.0,
        "市盈率": 21.3,
        "成交量": 173707.0,
        "成交额": 10477388.0,
        "振幅": 1.84,
        "换手率": 0.6,
        "symbol": "106.DFIN"
    },
    {
        "序号": 3004,
        "name": "Nelnet Inc-A",
        "最新价": 85.66,
        "涨跌额": 0.61,
        "涨跌幅": 0.72,
        "开盘价": 84.82,
        "最高价": 85.72,
        "最低价": 84.39,
        "昨收价": 85.05,
        "总市值": 3197139062.0,
        "市盈率": 24.43,
        "成交量": 64734.0,
        "成交额": 5533020.0,
        "振幅": 1.56,
        "换手率": 0.17,
        "symbol": "106.NNI"
    },
    {
        "序号": 3005,
        "name": "Viatris Inc",
        "最新价": 9.83,
        "涨跌额": 0.07,
        "涨跌幅": 0.72,
        "开盘价": 9.77,
        "最高价": 9.92,
        "最低价": 9.74,
        "昨收价": 9.76,
        "总市值": 11792765930.0,
        "市盈率": 6.44,
        "成交量": 8616661.0,
        "成交额": 84721742.0,
        "振幅": 1.84,
        "换手率": 0.72,
        "symbol": "105.VTRS"
    },
    {
        "序号": 3006,
        "name": "Main Street Capital Corp",
        "最新价": 42.13,
        "涨跌额": 0.3,
        "涨跌幅": 0.72,
        "开盘价": 41.78,
        "最高价": 42.19,
        "最低价": 41.77,
        "昨收价": 41.83,
        "总市值": 3528183801.0,
        "市盈率": 8.92,
        "成交量": 313682.0,
        "成交额": 13198487.0,
        "振幅": 1.0,
        "换手率": 0.37,
        "symbol": "106.MAIN"
    },
    {
        "序号": 3007,
        "name": "NRG Energy Inc",
        "最新价": 47.81,
        "涨跌额": 0.34,
        "涨跌幅": 0.72,
        "开盘价": 47.34,
        "最高价": 47.83,
        "最低价": 47.34,
        "昨收价": 47.47,
        "总市值": 10793797685.0,
        "市盈率": -6.07,
        "成交量": 3289649.0,
        "成交额": 156928556.0,
        "振幅": 1.03,
        "换手率": 1.46,
        "symbol": "106.NRG"
    },
    {
        "序号": 3008,
        "name": "通用汽车",
        "最新价": 33.75,
        "涨跌额": 0.24,
        "涨跌幅": 0.72,
        "开盘价": 33.51,
        "最高价": 34.32,
        "最低价": 33.51,
        "昨收价": 33.51,
        "总市值": 46219990703.0,
        "市盈率": 4.61,
        "成交量": 20280227.0,
        "成交额": 687232544.0,
        "振幅": 2.42,
        "换手率": 1.48,
        "symbol": "106.GM"
    },
    {
        "序号": 3009,
        "name": "全球金融ETF-iShares",
        "最新价": 76.01,
        "涨跌额": 0.54,
        "涨跌幅": 0.72,
        "开盘价": 75.56,
        "最高价": 76.09,
        "最低价": 75.56,
        "昨收价": 75.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 25760.0,
        "成交额": 1953708.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.IXG"
    },
    {
        "序号": 3010,
        "name": "National Presto Industries Inc",
        "最新价": 78.85,
        "涨跌额": 0.56,
        "涨跌幅": 0.72,
        "开盘价": 78.13,
        "最高价": 79.08,
        "最低价": 78.13,
        "昨收价": 78.29,
        "总市值": 558447792.0,
        "市盈率": 23.69,
        "成交量": 10873.0,
        "成交额": 856515.0,
        "振幅": 1.21,
        "换手率": 0.15,
        "symbol": "106.NPK"
    },
    {
        "序号": 3011,
        "name": "费哲金融服务",
        "最新价": 132.36,
        "涨跌额": 0.94,
        "涨跌幅": 0.72,
        "开盘价": 131.5,
        "最高价": 132.58,
        "最低价": 131.46,
        "昨收价": 131.42,
        "总市值": 79440581370.0,
        "市盈率": 26.66,
        "成交量": 2364472.0,
        "成交额": 312542832.0,
        "振幅": 0.85,
        "换手率": 0.39,
        "symbol": "106.FI"
    },
    {
        "序号": 3012,
        "name": "First Trust Eurozone AlphaDEX E",
        "最新价": 39.43,
        "涨跌额": 0.28,
        "涨跌幅": 0.72,
        "开盘价": 39.09,
        "最高价": 39.46,
        "最低价": 39.02,
        "昨收价": 39.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 1016.0,
        "成交额": 39822.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "105.FEUZ"
    },
    {
        "序号": 3013,
        "name": "Evolve Transition Infrastructur",
        "最新价": 1.41,
        "涨跌额": 0.01,
        "涨跌幅": 0.71,
        "开盘价": 1.42,
        "最高价": 1.42,
        "最低价": 1.21,
        "昨收价": 1.4,
        "总市值": 11885477.0,
        "市盈率": -0.19,
        "成交量": 5699.0,
        "成交额": 7536.0,
        "振幅": 15.0,
        "换手率": 0.07,
        "symbol": "107.SNMP"
    },
    {
        "序号": 3014,
        "name": "Putnam Focused Large Cap Growth",
        "最新价": 28.2,
        "涨跌额": 0.2,
        "涨跌幅": 0.71,
        "开盘价": 27.94,
        "最高价": 28.2,
        "最低价": 27.92,
        "昨收价": 28.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2904.0,
        "成交额": 81276.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.PGRO"
    },
    {
        "序号": 3015,
        "name": "First Internet Bancorp Notes",
        "最新价": 21.15,
        "涨跌额": 0.15,
        "涨跌幅": 0.71,
        "开盘价": 21.1,
        "最高价": 21.24,
        "最低价": 20.8,
        "昨收价": 21.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 17885.0,
        "成交额": 376564.0,
        "振幅": 2.1,
        "换手率": null,
        "symbol": "105.INBKZ"
    },
    {
        "序号": 3016,
        "name": "Themes Generative Artificial In",
        "最新价": 25.4,
        "涨跌额": 0.18,
        "涨跌幅": 0.71,
        "开盘价": 25.22,
        "最高价": 25.4,
        "最低价": 25.21,
        "昨收价": 25.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 1655.0,
        "成交额": 41765.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.WISE"
    },
    {
        "序号": 3017,
        "name": "FT Cboe Vest SMID Rising Divide",
        "最新价": 19.77,
        "涨跌额": 0.14,
        "涨跌幅": 0.71,
        "开盘价": 19.83,
        "最高价": 19.83,
        "最低价": 19.72,
        "昨收价": 19.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 4695.0,
        "成交额": 92731.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.SDVD"
    },
    {
        "序号": 3018,
        "name": "VictoryShares US Small Mid Cap ",
        "最新价": 69.21,
        "涨跌额": 0.49,
        "涨跌幅": 0.71,
        "开盘价": 69.32,
        "最高价": 69.32,
        "最低价": 69.08,
        "昨收价": 68.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 2615.0,
        "成交额": 180948.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.USVM"
    },
    {
        "序号": 3019,
        "name": "Navigator Holdings Ltd",
        "最新价": 14.14,
        "涨跌额": 0.1,
        "涨跌幅": 0.71,
        "开盘价": 14.11,
        "最高价": 14.21,
        "最低价": 14.02,
        "昨收价": 14.04,
        "总市值": 1036251144.0,
        "市盈率": 17.93,
        "成交量": 89687.0,
        "成交额": 1264666.0,
        "振幅": 1.35,
        "换手率": 0.12,
        "symbol": "106.NVGS"
    },
    {
        "序号": 3020,
        "name": "Strive U.S. Semiconductor ETF",
        "最新价": 35.44,
        "涨跌额": 0.25,
        "涨跌幅": 0.71,
        "开盘价": 35.1,
        "最高价": 35.6,
        "最低价": 35.1,
        "昨收价": 35.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 4168.0,
        "成交额": 147568.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.SHOC"
    },
    {
        "序号": 3021,
        "name": "Counterpoint Quantitative Equit",
        "最新价": 25.56,
        "涨跌额": 0.18,
        "涨跌幅": 0.71,
        "开盘价": 25.52,
        "最高价": 25.65,
        "最低价": 25.45,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 11698.0,
        "成交额": 298603.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.CPAI"
    },
    {
        "序号": 3022,
        "name": "格拉德斯通投资",
        "最新价": 14.21,
        "涨跌额": 0.1,
        "涨跌幅": 0.71,
        "开盘价": 14.16,
        "最高价": 14.26,
        "最低价": 13.97,
        "昨收价": 14.11,
        "总市值": 481657542.0,
        "市盈率": 6.29,
        "成交量": 182837.0,
        "成交额": 2583007.0,
        "振幅": 2.06,
        "换手率": 0.54,
        "symbol": "105.GAIN"
    },
    {
        "序号": 3023,
        "name": "Knife River Holding Co",
        "最新价": 62.53,
        "涨跌额": 0.44,
        "涨跌幅": 0.71,
        "开盘价": 62.32,
        "最高价": 62.99,
        "最低价": 61.91,
        "昨收价": 62.09,
        "总市值": 3537085361.0,
        "市盈率": null,
        "成交量": 412948.0,
        "成交额": 25823617.0,
        "振幅": 1.74,
        "换手率": 0.73,
        "symbol": "106.KNF"
    },
    {
        "序号": 3024,
        "name": "Royce Quant Small-Cap Quality V",
        "最新价": 38.41,
        "涨跌额": 0.27,
        "涨跌幅": 0.71,
        "开盘价": 38.4,
        "最高价": 38.41,
        "最低价": 38.35,
        "昨收价": 38.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 1685.0,
        "成交额": 64669.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.SQLV"
    },
    {
        "序号": 3025,
        "name": "BrightSpire Capital Inc-A",
        "最新价": 7.12,
        "涨跌额": 0.05,
        "涨跌幅": 0.71,
        "开盘价": 7.03,
        "最高价": 7.13,
        "最低价": 7.01,
        "昨收价": 7.07,
        "总市值": 925493962.0,
        "市盈率": 184.51,
        "成交量": 502070.0,
        "成交额": 3555258.0,
        "振幅": 1.7,
        "换手率": 0.39,
        "symbol": "106.BRSP"
    },
    {
        "序号": 3026,
        "name": "Paycom Software Inc",
        "最新价": 186.57,
        "涨跌额": 1.31,
        "涨跌幅": 0.71,
        "开盘价": 183.98,
        "最高价": 190.77,
        "最低价": 183.62,
        "昨收价": 185.26,
        "总市值": 11236768744.0,
        "市盈率": 33.15,
        "成交量": 1062264.0,
        "成交额": 199407394.0,
        "振幅": 3.86,
        "换手率": 1.76,
        "symbol": "106.PAYC"
    },
    {
        "序号": 3027,
        "name": "iShares Currency Hedged MSCI EA",
        "最新价": 31.34,
        "涨跌额": 0.22,
        "涨跌幅": 0.71,
        "开盘价": 31.21,
        "最高价": 31.37,
        "最低价": 31.12,
        "昨收价": 31.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 471324.0,
        "成交额": 14747516.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.HEFA"
    },
    {
        "序号": 3028,
        "name": "Vanguard Russell 2000 ETF",
        "最新价": 75.51,
        "涨跌额": 0.53,
        "涨跌幅": 0.71,
        "开盘价": 74.87,
        "最高价": 75.89,
        "最低价": 74.74,
        "昨收价": 74.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 4168424.0,
        "成交额": 314242512.0,
        "振幅": 1.53,
        "换手率": null,
        "symbol": "105.VTWO"
    },
    {
        "序号": 3029,
        "name": "Cambria Cannabis ETF",
        "最新价": 5.7,
        "涨跌额": 0.04,
        "涨跌幅": 0.71,
        "开盘价": 5.67,
        "最高价": 5.7,
        "最低价": 5.66,
        "昨收价": 5.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 589.0,
        "成交额": 3336.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.TOKE"
    },
    {
        "序号": 3030,
        "name": "AXS Esoterica NextG Economy ETF",
        "最新价": 48.51,
        "涨跌额": 0.34,
        "涨跌幅": 0.71,
        "开盘价": 48.3,
        "最高价": 48.59,
        "最低价": 48.3,
        "昨收价": 48.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 845.0,
        "成交额": 40938.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.WUGI"
    },
    {
        "序号": 3031,
        "name": "ProShares Short 7 10 Year Treas",
        "最新价": 29.97,
        "涨跌额": 0.21,
        "涨跌幅": 0.71,
        "开盘价": 29.96,
        "最高价": 30.0,
        "最低价": 29.92,
        "昨收价": 29.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 31471.0,
        "成交额": 943519.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.TBX"
    },
    {
        "序号": 3032,
        "name": "JPMorgan BetaBuilders U.S. Smal",
        "最新价": 57.1,
        "涨跌额": 0.4,
        "涨跌幅": 0.71,
        "开盘价": 56.48,
        "最高价": 57.23,
        "最低价": 56.48,
        "昨收价": 56.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 15481.0,
        "成交额": 882260.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.BBSC"
    },
    {
        "序号": 3033,
        "name": "iShares Future Cloud 5G and Tec",
        "最新价": 25.71,
        "涨跌额": 0.18,
        "涨跌幅": 0.71,
        "开盘价": 25.72,
        "最高价": 25.72,
        "最低价": 25.5,
        "昨收价": 25.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 832.0,
        "成交额": 21326.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.IDAT"
    },
    {
        "序号": 3034,
        "name": "Sotherly Hotels Inc",
        "最新价": 1.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.7,
        "开盘价": 1.38,
        "最高价": 1.45,
        "最低价": 1.38,
        "昨收价": 1.42,
        "总市值": 28166431.0,
        "市盈率": 2.9,
        "成交量": 64171.0,
        "成交额": 90632.0,
        "振幅": 4.93,
        "换手率": 0.33,
        "symbol": "105.SOHO"
    },
    {
        "序号": 3035,
        "name": "Engine No. 1 Transform Climate ",
        "最新价": 54.48,
        "涨跌额": 0.38,
        "涨跌幅": 0.7,
        "开盘价": 54.08,
        "最高价": 54.48,
        "最低价": 54.08,
        "昨收价": 54.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 4546.0,
        "成交额": 247207.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.NETZ"
    },
    {
        "序号": 3036,
        "name": "阿瑞斯资本",
        "最新价": 20.08,
        "涨跌额": 0.14,
        "涨跌幅": 0.7,
        "开盘价": 19.9,
        "最高价": 20.12,
        "最低价": 19.89,
        "昨收价": 19.94,
        "总市值": 11434287731.0,
        "市盈率": 8.91,
        "成交量": 6926851.0,
        "成交额": 138682053.0,
        "振幅": 1.15,
        "换手率": 1.22,
        "symbol": "105.ARCC"
    },
    {
        "序号": 3037,
        "name": "First Trust Multi-Manager Large",
        "最新价": 22.95,
        "涨跌额": 0.16,
        "涨跌幅": 0.7,
        "开盘价": 22.81,
        "最高价": 22.97,
        "最低价": 22.81,
        "昨收价": 22.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 5027.0,
        "成交额": 115256.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.MMLG"
    },
    {
        "序号": 3038,
        "name": "合成生物",
        "最新价": 2.87,
        "涨跌额": 0.02,
        "涨跌幅": 0.7,
        "开盘价": 2.84,
        "最高价": 3.06,
        "最低价": 2.78,
        "昨收价": 2.85,
        "总市值": 26373443.0,
        "市盈率": -0.44,
        "成交量": 39713.0,
        "成交额": 117195.0,
        "振幅": 9.82,
        "换手率": 0.43,
        "symbol": "105.SYBX"
    },
    {
        "序号": 3039,
        "name": "斯伦贝谢",
        "最新价": 48.8,
        "涨跌额": 0.34,
        "涨跌幅": 0.7,
        "开盘价": 48.43,
        "最高价": 49.11,
        "最低价": 48.12,
        "昨收价": 48.46,
        "总市值": 69708793198.0,
        "市盈率": 16.78,
        "成交量": 13211005.0,
        "成交额": 643770416.0,
        "振幅": 2.04,
        "换手率": 0.92,
        "symbol": "106.SLB"
    },
    {
        "序号": 3040,
        "name": "Valley National Bancorp Series ",
        "最新价": 20.1,
        "涨跌额": 0.14,
        "涨跌幅": 0.7,
        "开盘价": 19.95,
        "最高价": 20.17,
        "最低价": 19.7,
        "昨收价": 19.96,
        "总市值": 92460000.0,
        "市盈率": null,
        "成交量": 5277.0,
        "成交额": 105436.0,
        "振幅": 2.35,
        "换手率": 0.11,
        "symbol": "105.VLYPP"
    },
    {
        "序号": 3041,
        "name": "Invesco WilderHill Clean Energy",
        "最新价": 27.28,
        "涨跌额": 0.19,
        "涨跌幅": 0.7,
        "开盘价": 27.05,
        "最高价": 27.72,
        "最低价": 26.85,
        "昨收价": 27.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 300219.0,
        "成交额": 8173466.0,
        "振幅": 3.21,
        "换手率": null,
        "symbol": "107.PBW"
    },
    {
        "序号": 3042,
        "name": "瑞士ETF-iShares MSCI",
        "最新价": 45.95,
        "涨跌额": 0.32,
        "涨跌幅": 0.7,
        "开盘价": 45.71,
        "最高价": 45.98,
        "最低价": 45.71,
        "昨收价": 45.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 345498.0,
        "成交额": 15851544.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.EWL"
    },
    {
        "序号": 3043,
        "name": "Invesco S&P SmallCap Quality ET",
        "最新价": 37.41,
        "涨跌额": 0.26,
        "涨跌幅": 0.7,
        "开盘价": 37.21,
        "最高价": 37.52,
        "最低价": 37.14,
        "昨收价": 37.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 26232.0,
        "成交额": 980204.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.XSHQ"
    },
    {
        "序号": 3044,
        "name": "iShares Edge MSCI USA Value Fac",
        "最新价": 96.42,
        "涨跌额": 0.67,
        "涨跌幅": 0.7,
        "开盘价": 95.71,
        "最高价": 96.67,
        "最低价": 95.71,
        "昨收价": 95.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 267023.0,
        "成交额": 25739161.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.VLUE"
    },
    {
        "序号": 3045,
        "name": "互联网指数ETF-First Trust",
        "最新价": 175.62,
        "涨跌额": 1.22,
        "涨跌幅": 0.7,
        "开盘价": 173.19,
        "最高价": 176.01,
        "最低价": 173.19,
        "昨收价": 174.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 371245.0,
        "成交额": 65071722.0,
        "振幅": 1.62,
        "换手率": null,
        "symbol": "107.FDN"
    },
    {
        "序号": 3046,
        "name": "Pebblebrook Hotel Trust Series ",
        "最新价": 20.21,
        "涨跌额": 0.14,
        "涨跌幅": 0.7,
        "开盘价": 19.81,
        "最高价": 20.3,
        "最低价": 19.81,
        "昨收价": 20.07,
        "总市值": 185932000.0,
        "市盈率": null,
        "成交量": 9616.0,
        "成交额": 193632.0,
        "振幅": 2.44,
        "换手率": 0.1,
        "symbol": "106.PEB_G"
    },
    {
        "序号": 3047,
        "name": "ARMOUR Residential REIT Inc Ser",
        "最新价": 20.24,
        "涨跌额": 0.14,
        "涨跌幅": 0.7,
        "开盘价": 20.0,
        "最高价": 20.24,
        "最低价": 19.89,
        "昨收价": 20.1,
        "总市值": 138583280.0,
        "市盈率": null,
        "成交量": 12294.0,
        "成交额": 246287.0,
        "振幅": 1.74,
        "换手率": 0.18,
        "symbol": "106.ARR_C"
    },
    {
        "序号": 3048,
        "name": "Vanguard S&P Small-Cap 600 Grow",
        "最新价": 99.82,
        "涨跌额": 0.69,
        "涨跌幅": 0.7,
        "开盘价": 99.13,
        "最高价": 100.14,
        "最低价": 99.13,
        "昨收价": 99.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 25651.0,
        "成交额": 2556419.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.VIOG"
    },
    {
        "序号": 3049,
        "name": "FS Credit Opportunities Corp",
        "最新价": 5.79,
        "涨跌额": 0.04,
        "涨跌幅": 0.7,
        "开盘价": 5.72,
        "最高价": 5.79,
        "最低价": 5.72,
        "昨收价": 5.75,
        "总市值": 1148480470.0,
        "市盈率": -7.38,
        "成交量": 570852.0,
        "成交额": 3286349.0,
        "振幅": 1.22,
        "换手率": 0.29,
        "symbol": "106.FSCO"
    },
    {
        "序号": 3050,
        "name": "Invesco DWA Developed Markets M",
        "最新价": 30.43,
        "涨跌额": 0.21,
        "涨跌幅": 0.69,
        "开盘价": 30.24,
        "最高价": 30.43,
        "最低价": 30.24,
        "昨收价": 30.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 6629.0,
        "成交额": 200905.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.PIZ"
    },
    {
        "序号": 3051,
        "name": "AXS Real Estate Income ETF",
        "最新价": 24.66,
        "涨跌额": 0.17,
        "涨跌幅": 0.69,
        "开盘价": 24.62,
        "最高价": 24.71,
        "最低价": 24.54,
        "昨收价": 24.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 6273.0,
        "成交额": 154548.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.RINC"
    },
    {
        "序号": 3052,
        "name": "AeroVironment Inc",
        "最新价": 124.79,
        "涨跌额": 0.86,
        "涨跌幅": 0.69,
        "开盘价": 123.53,
        "最高价": 127.16,
        "最低价": 122.27,
        "昨收价": 123.93,
        "总市值": 3510979129.0,
        "市盈率": -28.92,
        "成交量": 439582.0,
        "成交额": 54923347.0,
        "振幅": 3.95,
        "换手率": 1.56,
        "symbol": "105.AVAV"
    },
    {
        "序号": 3053,
        "name": "Goldman Sachs Future Tech Leade",
        "最新价": 26.12,
        "涨跌额": 0.18,
        "涨跌幅": 0.69,
        "开盘价": 25.81,
        "最高价": 26.18,
        "最低价": 25.81,
        "昨收价": 25.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 18749.0,
        "成交额": 488836.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "107.GTEK"
    },
    {
        "序号": 3054,
        "name": "Franklin FTSE Brazil ETF",
        "最新价": 20.32,
        "涨跌额": 0.14,
        "涨跌幅": 0.69,
        "开盘价": 20.11,
        "最高价": 20.38,
        "最低价": 20.11,
        "昨收价": 20.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 10035.0,
        "成交额": 203306.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "107.FLBR"
    },
    {
        "序号": 3055,
        "name": "Donoghue Forlines Yield Enhance",
        "最新价": 27.59,
        "涨跌额": 0.19,
        "涨跌幅": 0.69,
        "开盘价": 27.61,
        "最高价": 27.61,
        "最低价": 27.51,
        "昨收价": 27.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 982.0,
        "成交额": 27049.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.DFRA"
    },
    {
        "序号": 3056,
        "name": "KraneShares Trust KraneShares E",
        "最新价": 20.33,
        "涨跌额": 0.14,
        "涨跌幅": 0.69,
        "开盘价": 20.33,
        "最高价": 20.33,
        "最低价": 20.33,
        "昨收价": 20.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 20.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KMET"
    },
    {
        "序号": 3057,
        "name": "亨廷顿英戈尔斯工业",
        "最新价": 246.89,
        "涨跌额": 1.7,
        "涨跌幅": 0.69,
        "开盘价": 245.57,
        "最高价": 247.25,
        "最低价": 244.9,
        "昨收价": 245.19,
        "总市值": 9807324052.0,
        "市盈率": 18.5,
        "成交量": 235978.0,
        "成交额": 58150983.0,
        "振幅": 0.96,
        "换手率": 0.59,
        "symbol": "106.HII"
    },
    {
        "序号": 3058,
        "name": "Barings BDC Inc",
        "最新价": 8.72,
        "涨跌额": 0.06,
        "涨跌幅": 0.69,
        "开盘价": 8.68,
        "最高价": 8.73,
        "最低价": 8.67,
        "昨收价": 8.66,
        "总市值": 928820968.0,
        "市盈率": 9.59,
        "成交量": 461685.0,
        "成交额": 4016022.0,
        "振幅": 0.69,
        "换手率": 0.43,
        "symbol": "106.BBDC"
    },
    {
        "序号": 3059,
        "name": "韩国国民银行",
        "最新价": 39.3,
        "涨跌额": 0.27,
        "涨跌幅": 0.69,
        "开盘价": 39.23,
        "最高价": 39.5,
        "最低价": 39.12,
        "昨收价": 39.03,
        "总市值": 14969304723.0,
        "市盈率": 4.0,
        "成交量": 159301.0,
        "成交额": 6261602.0,
        "振幅": 0.97,
        "换手率": 0.04,
        "symbol": "106.KB"
    },
    {
        "序号": 3060,
        "name": "SPDR S&P Kensho Future Security",
        "最新价": 52.4,
        "涨跌额": 0.36,
        "涨跌幅": 0.69,
        "开盘价": 52.08,
        "最高价": 52.51,
        "最低价": 52.08,
        "昨收价": 52.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1805.0,
        "成交额": 94343.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.FITE"
    },
    {
        "序号": 3061,
        "name": "CastleArk Large Growth ETF",
        "最新价": 30.57,
        "涨跌额": 0.21,
        "涨跌幅": 0.69,
        "开盘价": 30.51,
        "最高价": 30.57,
        "最低价": 30.51,
        "昨收价": 30.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 2091.0,
        "成交额": 63796.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.CARK"
    },
    {
        "序号": 3062,
        "name": "Timberland Bancorp Inc",
        "最新价": 30.6,
        "涨跌额": 0.21,
        "涨跌幅": 0.69,
        "开盘价": 30.78,
        "最高价": 30.81,
        "最低价": 30.59,
        "昨收价": 30.39,
        "总市值": 248023343.0,
        "市盈率": 9.01,
        "成交量": 24020.0,
        "成交额": 735826.0,
        "振幅": 0.72,
        "换手率": 0.3,
        "symbol": "105.TSBK"
    },
    {
        "序号": 3063,
        "name": "Fidelity New Millennium ETF",
        "最新价": 34.98,
        "涨跌额": 0.24,
        "涨跌幅": 0.69,
        "开盘价": 34.72,
        "最高价": 34.98,
        "最低价": 34.72,
        "昨收价": 34.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 34462.0,
        "成交额": 1202444.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.FMIL"
    },
    {
        "序号": 3064,
        "name": "Maiden Holdings Ltd Notes",
        "最新价": 17.5,
        "涨跌额": 0.12,
        "涨跌幅": 0.69,
        "开盘价": 16.85,
        "最高价": 17.5,
        "最低价": 16.85,
        "昨收价": 17.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 4126.0,
        "成交额": 70036.0,
        "振幅": 3.74,
        "换手率": null,
        "symbol": "106.MHLA"
    },
    {
        "序号": 3065,
        "name": "Fabrinet",
        "最新价": 166.25,
        "涨跌额": 1.14,
        "涨跌幅": 0.69,
        "开盘价": 164.5,
        "最高价": 168.01,
        "最低价": 164.5,
        "昨收价": 165.11,
        "总市值": 6040005143.0,
        "市盈率": 24.32,
        "成交量": 187482.0,
        "成交额": 31153667.0,
        "振幅": 2.13,
        "换手率": 0.52,
        "symbol": "106.FN"
    },
    {
        "序号": 3066,
        "name": "ClearBridge All Cap Growth ETF",
        "最新价": 45.22,
        "涨跌额": 0.31,
        "涨跌幅": 0.69,
        "开盘价": 44.74,
        "最高价": 45.22,
        "最低价": 44.74,
        "昨收价": 44.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 2776.0,
        "成交额": 125010.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "105.CACG"
    },
    {
        "序号": 3067,
        "name": "冰川万通金控",
        "最新价": 37.95,
        "涨跌额": 0.26,
        "涨跌幅": 0.69,
        "开盘价": 37.7,
        "最高价": 38.35,
        "最低价": 37.39,
        "昨收价": 37.69,
        "总市值": 4207987991.0,
        "市盈率": 16.95,
        "成交量": 674886.0,
        "成交额": 25605066.0,
        "振幅": 2.55,
        "换手率": 0.61,
        "symbol": "106.GBCI"
    },
    {
        "序号": 3068,
        "name": "EA Bridgeway Blue Chip ETF",
        "最新价": 10.22,
        "涨跌额": 0.07,
        "涨跌幅": 0.69,
        "开盘价": 10.16,
        "最高价": 10.23,
        "最低价": 10.15,
        "昨收价": 10.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 39266.0,
        "成交额": 400406.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.BBLU"
    },
    {
        "序号": 3069,
        "name": "American Century U.S. Quality G",
        "最新价": 73.03,
        "涨跌额": 0.5,
        "涨跌幅": 0.69,
        "开盘价": 72.32,
        "最高价": 73.13,
        "最低价": 72.32,
        "昨收价": 72.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 31366.0,
        "成交额": 2287612.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "107.QGRO"
    },
    {
        "序号": 3070,
        "name": "YieldMax MSFT Option Income Str",
        "最新价": 21.91,
        "涨跌额": 0.15,
        "涨跌幅": 0.69,
        "开盘价": 21.71,
        "最高价": 21.91,
        "最低价": 21.67,
        "昨收价": 21.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 27376.0,
        "成交额": 596964.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.MSFO"
    },
    {
        "序号": 3071,
        "name": "伊格尔合众银行",
        "最新价": 26.33,
        "涨跌额": 0.18,
        "涨跌幅": 0.69,
        "开盘价": 26.13,
        "最高价": 26.75,
        "最低价": 26.0,
        "昨收价": 26.15,
        "总市值": 787956372.0,
        "市盈率": 6.43,
        "成交量": 178668.0,
        "成交额": 4706351.0,
        "振幅": 2.87,
        "换手率": 0.6,
        "symbol": "105.EGBN"
    },
    {
        "序号": 3072,
        "name": "First Trust Small Cap Growth Al",
        "最新价": 58.58,
        "涨跌额": 0.4,
        "涨跌幅": 0.69,
        "开盘价": 58.18,
        "最高价": 58.8,
        "最低价": 58.18,
        "昨收价": 58.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 10205.0,
        "成交额": 597272.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "105.FYC"
    },
    {
        "序号": 3073,
        "name": "Overlay Shares Large Cap Equity",
        "最新价": 36.62,
        "涨跌额": 0.25,
        "涨跌幅": 0.69,
        "开盘价": 36.48,
        "最高价": 36.65,
        "最低价": 36.4,
        "昨收价": 36.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 17721.0,
        "成交额": 647250.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.OVL"
    },
    {
        "序号": 3074,
        "name": "Xtrackers MSCI All World ex US ",
        "最新价": 23.44,
        "涨跌额": 0.16,
        "涨跌幅": 0.69,
        "开盘价": 23.5,
        "最高价": 23.5,
        "最低价": 23.44,
        "昨收价": 23.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 906.0,
        "成交额": 21289.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.HDAW"
    },
    {
        "序号": 3075,
        "name": "Anfield U.S. Equity Sector Rota",
        "最新价": 13.19,
        "涨跌额": 0.09,
        "涨跌幅": 0.69,
        "开盘价": 13.22,
        "最高价": 13.22,
        "最低价": 13.13,
        "昨收价": 13.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 22801.0,
        "成交额": 300122.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.AESR"
    },
    {
        "序号": 3076,
        "name": "SPS Commerce Inc",
        "最新价": 183.38,
        "涨跌额": 1.25,
        "涨跌幅": 0.69,
        "开盘价": 181.38,
        "最高价": 184.86,
        "最低价": 180.99,
        "昨收价": 182.13,
        "总市值": 6729607355.0,
        "市盈率": 107.28,
        "成交量": 92235.0,
        "成交额": 16913326.0,
        "振幅": 2.12,
        "换手率": 0.25,
        "symbol": "105.SPSC"
    },
    {
        "序号": 3077,
        "name": "西班牙ETF-iShares MSCI",
        "最新价": 30.82,
        "涨跌额": 0.21,
        "涨跌幅": 0.69,
        "开盘价": 30.6,
        "最高价": 30.82,
        "最低价": 30.59,
        "昨收价": 30.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 400296.0,
        "成交额": 12302201.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.EWP"
    },
    {
        "序号": 3078,
        "name": "联邦投资",
        "最新价": 32.32,
        "涨跌额": 0.22,
        "涨跌幅": 0.69,
        "开盘价": 32.17,
        "最高价": 32.4,
        "最低价": 31.9,
        "昨收价": 32.1,
        "总市值": 2787756752.0,
        "市盈率": 10.2,
        "成交量": 992471.0,
        "成交额": 32041771.0,
        "振幅": 1.56,
        "换手率": 1.15,
        "symbol": "106.FHI"
    },
    {
        "序号": 3079,
        "name": "Invesco Mortgage Capital Inc Se",
        "最新价": 22.04,
        "涨跌额": 0.15,
        "涨跌幅": 0.69,
        "开盘价": 21.9,
        "最高价": 22.88,
        "最低价": 21.9,
        "昨收价": 21.89,
        "总市值": 98417725.0,
        "市盈率": null,
        "成交量": 18660.0,
        "成交额": 413538.0,
        "振幅": 4.48,
        "换手率": 0.42,
        "symbol": "106.IVR_B"
    },
    {
        "序号": 3080,
        "name": "CytomX Therapeutics Inc",
        "最新价": 1.47,
        "涨跌额": 0.01,
        "涨跌幅": 0.68,
        "开盘价": 1.45,
        "最高价": 1.49,
        "最低价": 1.42,
        "昨收价": 1.46,
        "总市值": 98473689.0,
        "市盈率": -9.67,
        "成交量": 279430.0,
        "成交额": 408192.0,
        "振幅": 4.79,
        "换手率": 0.42,
        "symbol": "105.CTMX"
    },
    {
        "序号": 3081,
        "name": "Ligand Pharmaceuticals Inc",
        "最新价": 61.82,
        "涨跌额": 0.42,
        "涨跌幅": 0.68,
        "开盘价": 61.4,
        "最高价": 62.3,
        "最低价": 60.51,
        "昨收价": 61.4,
        "总市值": 1077890924.0,
        "市盈率": 65.4,
        "成交量": 101877.0,
        "成交额": 6289647.0,
        "振幅": 2.92,
        "换手率": 0.58,
        "symbol": "105.LGND"
    },
    {
        "序号": 3082,
        "name": "Invesco Dynamic Large Cap Value",
        "最新价": 48.59,
        "涨跌额": 0.33,
        "涨跌幅": 0.68,
        "开盘价": 48.37,
        "最高价": 48.68,
        "最低价": 48.32,
        "昨收价": 48.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 41022.0,
        "成交额": 1991524.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.PWV"
    },
    {
        "序号": 3083,
        "name": "Old National Bancorp",
        "最新价": 16.2,
        "涨跌额": 0.11,
        "涨跌幅": 0.68,
        "开盘价": 16.08,
        "最高价": 16.34,
        "最低价": 16.03,
        "昨收价": 16.09,
        "总市值": 4740460200.0,
        "市盈率": 7.29,
        "成交量": 1343950.0,
        "成交额": 21804673.0,
        "振幅": 1.93,
        "换手率": 0.46,
        "symbol": "105.ONB"
    },
    {
        "序号": 3084,
        "name": "布鲁克赖恩银行",
        "最新价": 10.31,
        "涨跌额": 0.07,
        "涨跌幅": 0.68,
        "开盘价": 10.31,
        "最高价": 10.39,
        "最低价": 10.19,
        "昨收价": 10.24,
        "总市值": 916210883.0,
        "市盈率": 11.2,
        "成交量": 346068.0,
        "成交额": 3569531.0,
        "振幅": 1.95,
        "换手率": 0.39,
        "symbol": "105.BRKL"
    },
    {
        "序号": 3085,
        "name": "SPDR SSGA US Sector Rotation ET",
        "最新价": 44.19,
        "涨跌额": 0.3,
        "涨跌幅": 0.68,
        "开盘价": 43.9,
        "最高价": 44.24,
        "最低价": 43.9,
        "昨收价": 43.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 23591.0,
        "成交额": 1039429.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.XLSR"
    },
    {
        "序号": 3086,
        "name": "Liberty Media Corp Liberty Siri",
        "最新价": 26.52,
        "涨跌额": 0.18,
        "涨跌幅": 0.68,
        "开盘价": 26.28,
        "最高价": 26.63,
        "最低价": 26.21,
        "昨收价": 26.34,
        "总市值": 18949908803.0,
        "市盈率": 15.6,
        "成交量": 570912.0,
        "成交额": 15098094.0,
        "振幅": 1.59,
        "换手率": 0.58,
        "symbol": "105.LSXMA"
    },
    {
        "序号": 3087,
        "name": "Harbor International Compounder",
        "最新价": 25.05,
        "涨跌额": 0.17,
        "涨跌幅": 0.68,
        "开盘价": 24.85,
        "最高价": 25.05,
        "最低价": 24.85,
        "昨收价": 24.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 17513.0,
        "成交额": 438116.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.OSEA"
    },
    {
        "序号": 3088,
        "name": "小型成长股指数ETF-Vanguard",
        "最新价": 225.52,
        "涨跌额": 1.53,
        "涨跌幅": 0.68,
        "开盘价": 223.55,
        "最高价": 226.64,
        "最低价": 223.4,
        "昨收价": 223.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 284990.0,
        "成交额": 64204942.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "107.VBK"
    },
    {
        "序号": 3089,
        "name": "Armada Hoffler Properties Inc S",
        "最新价": 23.59,
        "涨跌额": 0.16,
        "涨跌幅": 0.68,
        "开盘价": 23.47,
        "最高价": 23.68,
        "最低价": 23.47,
        "昨收价": 23.43,
        "总市值": 161436231.0,
        "市盈率": null,
        "成交量": 2836.0,
        "成交额": 66875.0,
        "振幅": 0.9,
        "换手率": 0.04,
        "symbol": "106.AHH_A"
    },
    {
        "序号": 3090,
        "name": "维蒙特工业",
        "最新价": 219.93,
        "涨跌额": 1.49,
        "涨跌幅": 0.68,
        "开盘价": 219.49,
        "最高价": 224.33,
        "最低价": 219.49,
        "昨收价": 218.44,
        "总市值": 4594471637.0,
        "市盈率": 29.6,
        "成交量": 150769.0,
        "成交额": 33317145.0,
        "振幅": 2.22,
        "换手率": 0.72,
        "symbol": "106.VMI"
    },
    {
        "序号": 3091,
        "name": "Invesco S&P SmallCap Industrial",
        "最新价": 107.78,
        "涨跌额": 0.73,
        "涨跌幅": 0.68,
        "开盘价": 107.92,
        "最高价": 107.92,
        "最低价": 107.29,
        "昨收价": 107.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2431.0,
        "成交额": 261669.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "105.PSCI"
    },
    {
        "序号": 3092,
        "name": "Invesco DWA Momentum ETF",
        "最新价": 81.25,
        "涨跌额": 0.55,
        "涨跌幅": 0.68,
        "开盘价": 80.75,
        "最高价": 81.31,
        "最低价": 80.75,
        "昨收价": 80.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 9330.0,
        "成交额": 757247.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "105.PDP"
    },
    {
        "序号": 3093,
        "name": "Principal U.S. Small-Cap Multi-",
        "最新价": 42.9,
        "涨跌额": 0.29,
        "涨跌幅": 0.68,
        "开盘价": 42.78,
        "最高价": 43.05,
        "最低价": 42.69,
        "昨收价": 42.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 21048.0,
        "成交额": 903046.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "105.PSC"
    },
    {
        "序号": 3094,
        "name": "艾伯森",
        "最新价": 22.19,
        "涨跌额": 0.15,
        "涨跌幅": 0.68,
        "开盘价": 22.12,
        "最高价": 22.32,
        "最低价": 22.11,
        "昨收价": 22.04,
        "总市值": 12779059590.0,
        "市盈率": 9.32,
        "成交量": 2348122.0,
        "成交额": 52116022.0,
        "振幅": 0.95,
        "换手率": 0.41,
        "symbol": "106.ACI"
    },
    {
        "序号": 3095,
        "name": "Amplify Seymour Cannabis ETF",
        "最新价": 4.45,
        "涨跌额": 0.03,
        "涨跌幅": 0.68,
        "开盘价": 4.4,
        "最高价": 4.5,
        "最低价": 4.4,
        "昨收价": 4.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 7288.0,
        "成交额": 32216.0,
        "振幅": 2.26,
        "换手率": null,
        "symbol": "107.CNBS"
    },
    {
        "序号": 3096,
        "name": "迪尔",
        "最新价": 363.67,
        "涨跌额": 2.45,
        "涨跌幅": 0.68,
        "开盘价": 361.8,
        "最高价": 364.17,
        "最低价": 360.87,
        "昨收价": 361.22,
        "总市值": 104737169838.0,
        "市盈率": 10.3,
        "成交量": 2060489.0,
        "成交额": 748250928.0,
        "振幅": 0.91,
        "换手率": 0.72,
        "symbol": "106.DE"
    },
    {
        "序号": 3097,
        "name": "DXC Technology Co",
        "最新价": 23.76,
        "涨跌额": 0.16,
        "涨跌幅": 0.68,
        "开盘价": 23.68,
        "最高价": 23.98,
        "最低价": 23.58,
        "昨收价": 23.6,
        "总市值": 4600970986.0,
        "市盈率": -8.19,
        "成交量": 1844291.0,
        "成交额": 43891939.0,
        "振幅": 1.69,
        "换手率": 0.95,
        "symbol": "106.DXC"
    },
    {
        "序号": 3098,
        "name": "Ashford Hospitality Trust Inc S",
        "最新价": 11.9,
        "涨跌额": 0.08,
        "涨跌幅": 0.68,
        "开盘价": 12.2,
        "最高价": 12.2,
        "最低价": 11.83,
        "昨收价": 11.82,
        "总市值": 13975681.0,
        "市盈率": null,
        "成交量": 2532.0,
        "成交额": 30167.0,
        "振幅": 3.13,
        "换手率": 0.22,
        "symbol": "106.AHT_D"
    },
    {
        "序号": 3099,
        "name": "爱尔兰ETF-iShares MSCI",
        "最新价": 58.06,
        "涨跌额": 0.39,
        "涨跌幅": 0.68,
        "开盘价": 57.89,
        "最高价": 58.07,
        "最低价": 57.82,
        "昨收价": 57.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 1112.0,
        "成交额": 64449.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.EIRL"
    },
    {
        "序号": 3100,
        "name": "ICF International Inc",
        "最新价": 139.94,
        "涨跌额": 0.94,
        "涨跌幅": 0.68,
        "开盘价": 138.36,
        "最高价": 140.31,
        "最低价": 137.0,
        "昨收价": 139.0,
        "总市值": 2633238945.0,
        "市盈率": 37.98,
        "成交量": 99956.0,
        "成交额": 13931110.0,
        "振幅": 2.38,
        "换手率": 0.53,
        "symbol": "105.ICFI"
    },
    {
        "序号": 3101,
        "name": "Acruence Active Hedge U.S. Equi",
        "最新价": 17.88,
        "涨跌额": 0.12,
        "涨跌幅": 0.68,
        "开盘价": 17.88,
        "最高价": 17.88,
        "最低价": 17.88,
        "昨收价": 17.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 125.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XVOL"
    },
    {
        "序号": 3102,
        "name": "Silo Pharma Inc",
        "最新价": 1.49,
        "涨跌额": 0.01,
        "涨跌幅": 0.68,
        "开盘价": 1.47,
        "最高价": 1.53,
        "最低价": 1.46,
        "昨收价": 1.48,
        "总市值": 4632108.0,
        "市盈率": -1.09,
        "成交量": 28548.0,
        "成交额": 42943.0,
        "振幅": 4.73,
        "换手率": 0.92,
        "symbol": "105.SILO"
    },
    {
        "序号": 3103,
        "name": "Priority Technology Holdings In",
        "最新价": 2.98,
        "涨跌额": 0.02,
        "涨跌幅": 0.68,
        "开盘价": 2.99,
        "最高价": 3.0,
        "最低价": 2.9,
        "昨收价": 2.96,
        "总市值": 228633291.0,
        "市盈率": -90.84,
        "成交量": 28735.0,
        "成交额": 85380.0,
        "振幅": 3.38,
        "换手率": 0.04,
        "symbol": "105.PRTH"
    },
    {
        "序号": 3104,
        "name": "Texas Capital Texas Equity Inde",
        "最新价": 25.35,
        "涨跌额": 0.17,
        "涨跌幅": 0.68,
        "开盘价": 25.23,
        "最高价": 25.36,
        "最低价": 25.2,
        "昨收价": 25.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 1817.0,
        "成交额": 45991.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.TXS"
    },
    {
        "序号": 3105,
        "name": "SPDR S&P 600 Small Cap Value ET",
        "最新价": 77.62,
        "涨跌额": 0.52,
        "涨跌幅": 0.67,
        "开盘价": 77.04,
        "最高价": 77.93,
        "最低价": 76.96,
        "昨收价": 77.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 186370.0,
        "成交额": 14432875.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.SLYV"
    },
    {
        "序号": 3106,
        "name": "The Mexico Equity and Income Fu",
        "最新价": 10.45,
        "涨跌额": 0.07,
        "涨跌幅": 0.67,
        "开盘价": 10.34,
        "最高价": 10.45,
        "最低价": 10.34,
        "昨收价": 10.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 3067.0,
        "成交额": 31961.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "106.MXE"
    },
    {
        "序号": 3107,
        "name": "Nasdaq-100 Dorsey Wright Moment",
        "最新价": 37.35,
        "涨跌额": 0.25,
        "涨跌幅": 0.67,
        "开盘价": 37.15,
        "最高价": 37.37,
        "最低价": 37.08,
        "昨收价": 37.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 5955.0,
        "成交额": 221631.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "105.QQQA"
    },
    {
        "序号": 3108,
        "name": "Main Sector Rotation ETF",
        "最新价": 44.88,
        "涨跌额": 0.3,
        "涨跌幅": 0.67,
        "开盘价": 44.54,
        "最高价": 44.9,
        "最低价": 44.54,
        "昨收价": 44.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 69535.0,
        "成交额": 3115323.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.SECT"
    },
    {
        "序号": 3109,
        "name": "Franklin FTSE Eurozone ETF",
        "最新价": 23.96,
        "涨跌额": 0.16,
        "涨跌幅": 0.67,
        "开盘价": 23.84,
        "最高价": 23.96,
        "最低价": 23.84,
        "昨收价": 23.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 2336.0,
        "成交额": 55775.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.FLEU"
    },
    {
        "序号": 3110,
        "name": "Adtalem Global Education Inc",
        "最新价": 59.94,
        "涨跌额": 0.4,
        "涨跌幅": 0.67,
        "开盘价": 59.2,
        "最高价": 60.12,
        "最低价": 59.17,
        "昨收价": 59.54,
        "总市值": 2387317533.0,
        "市盈率": 23.09,
        "成交量": 252150.0,
        "成交额": 15086213.0,
        "振幅": 1.6,
        "换手率": 0.63,
        "symbol": "106.ATGE"
    },
    {
        "序号": 3111,
        "name": "卡隆石油",
        "最新价": 29.98,
        "涨跌额": 0.2,
        "涨跌幅": 0.67,
        "开盘价": 30.03,
        "最高价": 30.78,
        "最低价": 29.85,
        "昨收价": 29.78,
        "总市值": 2031859963.0,
        "市盈率": 3.15,
        "成交量": 1587715.0,
        "成交额": 47895982.0,
        "振幅": 3.12,
        "换手率": 2.34,
        "symbol": "106.CPE"
    },
    {
        "序号": 3112,
        "name": "Greystone Housing Impact Invest",
        "最新价": 16.49,
        "涨跌额": 0.11,
        "涨跌幅": 0.67,
        "开盘价": 16.32,
        "最高价": 16.59,
        "最低价": 16.27,
        "昨收价": 16.38,
        "总市值": 378064103.0,
        "市盈率": 7.42,
        "成交量": 27527.0,
        "成交额": 451608.0,
        "振幅": 1.95,
        "换手率": 0.12,
        "symbol": "106.GHI"
    },
    {
        "序号": 3113,
        "name": "Hoya Capital Housing ETF",
        "最新价": 38.98,
        "涨跌额": 0.26,
        "涨跌幅": 0.67,
        "开盘价": 38.91,
        "最高价": 39.03,
        "最低价": 38.74,
        "昨收价": 38.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 2414.0,
        "成交额": 93725.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.HOMZ"
    },
    {
        "序号": 3114,
        "name": "iShares U.S. Financial Services",
        "最新价": 169.42,
        "涨跌额": 1.13,
        "涨跌幅": 0.67,
        "开盘价": 168.78,
        "最高价": 169.78,
        "最低价": 168.56,
        "昨收价": 168.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 14127.0,
        "成交额": 2390953.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.IYG"
    },
    {
        "序号": 3115,
        "name": "Profire Energy Inc",
        "最新价": 1.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.67,
        "开盘价": 1.49,
        "最高价": 1.54,
        "最低价": 1.49,
        "昨收价": 1.49,
        "总市值": 71381928.0,
        "市盈率": 7.67,
        "成交量": 307811.0,
        "成交额": 464061.0,
        "振幅": 3.36,
        "换手率": 0.65,
        "symbol": "105.PFIE"
    },
    {
        "序号": 3116,
        "name": "GreenPower Motor Co Inc",
        "最新价": 3.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.67,
        "开盘价": 3.03,
        "最高价": 3.05,
        "最低价": 2.96,
        "昨收价": 2.98,
        "总市值": 74863128.0,
        "市盈率": -5.23,
        "成交量": 16618.0,
        "成交额": 49969.0,
        "振幅": 3.02,
        "换手率": 0.07,
        "symbol": "105.GP"
    },
    {
        "序号": 3117,
        "name": "美国家庭人寿保险",
        "最新价": 82.55,
        "涨跌额": 0.55,
        "涨跌幅": 0.67,
        "开盘价": 82.0,
        "最高价": 82.61,
        "最低价": 81.82,
        "昨收价": 82.0,
        "总市值": 48240559837.0,
        "市盈率": 11.04,
        "成交量": 1729341.0,
        "成交额": 142492758.0,
        "振幅": 0.96,
        "换手率": 0.3,
        "symbol": "106.AFL"
    },
    {
        "序号": 3118,
        "name": "Fidelity Disruptive Technology ",
        "最新价": 27.02,
        "涨跌额": 0.18,
        "涨跌幅": 0.67,
        "开盘价": 26.75,
        "最高价": 27.04,
        "最低价": 26.75,
        "昨收价": 26.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 18732.0,
        "成交额": 503471.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "105.FDTX"
    },
    {
        "序号": 3119,
        "name": "First Trust Nasdaq Semiconducto",
        "最新价": 73.59,
        "涨跌额": 0.49,
        "涨跌幅": 0.67,
        "开盘价": 72.83,
        "最高价": 73.94,
        "最低价": 72.83,
        "昨收价": 73.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 77972.0,
        "成交额": 5738018.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "105.FTXL"
    },
    {
        "序号": 3120,
        "name": "Sprott Nickel Miners ETF",
        "最新价": 16.53,
        "涨跌额": 0.11,
        "涨跌幅": 0.67,
        "开盘价": 16.46,
        "最高价": 16.53,
        "最低价": 16.46,
        "昨收价": 16.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 837.0,
        "成交额": 13777.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "105.NIKL"
    },
    {
        "序号": 3121,
        "name": "信安金融",
        "最新价": 75.15,
        "涨跌额": 0.5,
        "涨跌幅": 0.67,
        "开盘价": 74.55,
        "最高价": 75.45,
        "最低价": 74.55,
        "昨收价": 74.65,
        "总市值": 17916670292.0,
        "市盈率": 11.68,
        "成交量": 681439.0,
        "成交额": 51165178.0,
        "振幅": 1.21,
        "换手率": 0.29,
        "symbol": "105.PFG"
    },
    {
        "序号": 3122,
        "name": "莫肯特尔银行",
        "最新价": 37.59,
        "涨跌额": 0.25,
        "涨跌幅": 0.67,
        "开盘价": 37.09,
        "最高价": 37.78,
        "最低价": 37.0,
        "昨收价": 37.34,
        "总市值": 602363098.0,
        "市盈率": 7.17,
        "成交量": 30028.0,
        "成交额": 1125318.0,
        "振幅": 2.09,
        "换手率": 0.19,
        "symbol": "105.MBWM"
    },
    {
        "序号": 3123,
        "name": "SPDR S&P SmallCap 600 ESG ETF",
        "最新价": 27.07,
        "涨跌额": 0.18,
        "涨跌幅": 0.67,
        "开盘价": 27.05,
        "最高价": 27.07,
        "最低价": 27.05,
        "昨收价": 26.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 601.0,
        "成交额": 16257.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.ESIX"
    },
    {
        "序号": 3124,
        "name": "二倍做多道指ETF-ProShares",
        "最新价": 73.74,
        "涨跌额": 0.49,
        "涨跌幅": 0.67,
        "开盘价": 73.16,
        "最高价": 73.95,
        "最低价": 73.03,
        "昨收价": 73.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 182909.0,
        "成交额": 13441530.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.DDM"
    },
    {
        "序号": 3125,
        "name": "SPX Technologies Inc",
        "最新价": 88.82,
        "涨跌额": 0.59,
        "涨跌幅": 0.67,
        "开盘价": 88.01,
        "最高价": 89.34,
        "最低价": 87.79,
        "昨收价": 88.23,
        "总市值": 4054465930.0,
        "市盈率": 130.37,
        "成交量": 118348.0,
        "成交额": 10503297.0,
        "振幅": 1.76,
        "换手率": 0.26,
        "symbol": "106.SPXC"
    },
    {
        "序号": 3126,
        "name": "Sparkline Intangible Value ETF",
        "最新价": 25.6,
        "涨跌额": 0.17,
        "涨跌幅": 0.67,
        "开盘价": 25.52,
        "最高价": 25.64,
        "最低价": 25.52,
        "昨收价": 25.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 1585.0,
        "成交额": 40526.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.ITAN"
    },
    {
        "序号": 3127,
        "name": "Timothy Plan US Small Cap Core ",
        "最新价": 33.18,
        "涨跌额": 0.22,
        "涨跌幅": 0.67,
        "开盘价": 33.15,
        "最高价": 33.35,
        "最低价": 32.95,
        "昨收价": 32.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 12241.0,
        "成交额": 406239.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.TPSC"
    },
    {
        "序号": 3128,
        "name": "Nuveen AMT-Free Municipal Value",
        "最新价": 13.58,
        "涨跌额": 0.09,
        "涨跌幅": 0.67,
        "开盘价": 13.41,
        "最高价": 13.64,
        "最低价": 13.41,
        "昨收价": 13.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 95539.0,
        "成交额": 1296336.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "106.NUW"
    },
    {
        "序号": 3129,
        "name": "Innovator IBD Breakout Opportun",
        "最新价": 31.69,
        "涨跌额": 0.21,
        "涨跌幅": 0.67,
        "开盘价": 31.18,
        "最高价": 31.73,
        "最低价": 31.18,
        "昨收价": 31.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1232.0,
        "成交额": 38586.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "107.BOUT"
    },
    {
        "序号": 3130,
        "name": "First Trust Indxx Metaverse ETF",
        "最新价": 33.21,
        "涨跌额": 0.22,
        "涨跌幅": 0.67,
        "开盘价": 33.24,
        "最高价": 33.26,
        "最低价": 33.21,
        "昨收价": 32.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 965.0,
        "成交额": 32091.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "105.ARVR"
    },
    {
        "序号": 3131,
        "name": "Archer Aviation Inc Wt",
        "最新价": 1.51,
        "涨跌额": 0.01,
        "涨跌幅": 0.67,
        "开盘价": 1.41,
        "最高价": 1.59,
        "最低价": 1.41,
        "昨收价": 1.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 47155.0,
        "成交额": 71918.0,
        "振幅": 12.0,
        "换手率": null,
        "symbol": "106.ACHR_WS"
    },
    {
        "序号": 3132,
        "name": "SEI Enhanced U.S. Large Cap Val",
        "最新价": 27.19,
        "涨跌额": 0.18,
        "涨跌幅": 0.67,
        "开盘价": 26.93,
        "最高价": 27.19,
        "最低价": 26.93,
        "昨收价": 27.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 447.0,
        "成交额": 12098.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.SEIV"
    },
    {
        "序号": 3133,
        "name": "花岗岩建筑",
        "最新价": 48.36,
        "涨跌额": 0.32,
        "涨跌幅": 0.67,
        "开盘价": 48.06,
        "最高价": 48.74,
        "最低价": 48.06,
        "昨收价": 48.04,
        "总市值": 2124615065.0,
        "市盈率": 53.58,
        "成交量": 298399.0,
        "成交额": 14437859.0,
        "振幅": 1.42,
        "换手率": 0.68,
        "symbol": "106.GVA"
    },
    {
        "序号": 3134,
        "name": "Freedom Holding Corp",
        "最新价": 80.31,
        "涨跌额": 0.53,
        "涨跌幅": 0.66,
        "开盘价": 79.9,
        "最高价": 81.58,
        "最低价": 79.7,
        "昨收价": 79.78,
        "总市值": 4791229629.0,
        "市盈率": 15.88,
        "成交量": 55934.0,
        "成交额": 4478542.0,
        "振幅": 2.36,
        "换手率": 0.09,
        "symbol": "105.FRHC"
    },
    {
        "序号": 3135,
        "name": "海科航空",
        "最新价": 178.91,
        "涨跌额": 1.18,
        "涨跌幅": 0.66,
        "开盘价": 177.97,
        "最高价": 180.54,
        "最低价": 177.97,
        "昨收价": 177.73,
        "总市值": 24723120616.0,
        "市盈率": 62.22,
        "成交量": 350078.0,
        "成交额": 62721434.0,
        "振幅": 1.45,
        "换手率": 0.64,
        "symbol": "106.HEI"
    },
    {
        "序号": 3136,
        "name": "北方信托",
        "最新价": 80.39,
        "涨跌额": 0.53,
        "涨跌幅": 0.66,
        "开盘价": 80.13,
        "最高价": 80.77,
        "最低价": 79.72,
        "昨收价": 79.86,
        "总市值": 16643653784.0,
        "市盈率": 14.47,
        "成交量": 1280944.0,
        "成交额": 102973894.0,
        "振幅": 1.31,
        "换手率": 0.62,
        "symbol": "105.NTRS"
    },
    {
        "序号": 3137,
        "name": "BlackRock Debt Strategies Fund",
        "最新价": 10.63,
        "涨跌额": 0.07,
        "涨跌幅": 0.66,
        "开盘价": 10.53,
        "最高价": 10.65,
        "最低价": 10.53,
        "昨收价": 10.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 109719.0,
        "成交额": 1162713.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "106.DSU"
    },
    {
        "序号": 3138,
        "name": "Upland Software Inc",
        "最新价": 4.56,
        "涨跌额": 0.03,
        "涨跌幅": 0.66,
        "开盘价": 4.62,
        "最高价": 4.62,
        "最低价": 4.41,
        "昨收价": 4.53,
        "总市值": 143135149.0,
        "市盈率": -0.77,
        "成交量": 95622.0,
        "成交额": 433484.0,
        "振幅": 4.64,
        "换手率": 0.3,
        "symbol": "105.UPLD"
    },
    {
        "序号": 3139,
        "name": "EVgo Inc-A",
        "最新价": 3.04,
        "涨跌额": 0.02,
        "涨跌幅": 0.66,
        "开盘价": 3.0,
        "最高价": 3.11,
        "最低价": 2.97,
        "昨收价": 3.02,
        "总市值": 910035850.0,
        "市盈率": -26.56,
        "成交量": 1908364.0,
        "成交额": 5777133.0,
        "振幅": 4.64,
        "换手率": 0.64,
        "symbol": "105.EVGO"
    },
    {
        "序号": 3140,
        "name": "Altrius Global Dividend ETF",
        "最新价": 30.4,
        "涨跌额": 0.2,
        "涨跌幅": 0.66,
        "开盘价": 30.4,
        "最高价": 30.4,
        "最低价": 30.4,
        "昨收价": 30.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 3070.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DIVD"
    },
    {
        "序号": 3141,
        "name": "Pembina Pipeline Corp",
        "最新价": 33.45,
        "涨跌额": 0.22,
        "涨跌幅": 0.66,
        "开盘价": 33.41,
        "最高价": 33.5,
        "最低价": 33.27,
        "昨收价": 33.23,
        "总市值": 18371843850.0,
        "市盈率": 18.47,
        "成交量": 879138.0,
        "成交额": 29384533.0,
        "振幅": 0.69,
        "换手率": 0.16,
        "symbol": "106.PBA"
    },
    {
        "序号": 3142,
        "name": "Forum Energy Technologies Inc",
        "最新价": 21.31,
        "涨跌额": 0.14,
        "涨跌幅": 0.66,
        "开盘价": 21.04,
        "最高价": 21.58,
        "最低价": 20.58,
        "昨收价": 21.17,
        "总市值": 217212361.0,
        "市盈率": -14.55,
        "成交量": 55401.0,
        "成交额": 1167181.0,
        "振幅": 4.72,
        "换手率": 0.54,
        "symbol": "106.FET"
    },
    {
        "序号": 3143,
        "name": "HCM Defender 500 Index ETF",
        "最新价": 39.58,
        "涨跌额": 0.26,
        "涨跌幅": 0.66,
        "开盘价": 39.5,
        "最高价": 39.62,
        "最低价": 39.3,
        "昨收价": 39.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 10085.0,
        "成交额": 397956.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.LGH"
    },
    {
        "序号": 3144,
        "name": "Evolution Petroleum Corp",
        "最新价": 6.09,
        "涨跌额": 0.04,
        "涨跌幅": 0.66,
        "开盘价": 6.07,
        "最高价": 6.12,
        "最低价": 6.01,
        "昨收价": 6.05,
        "总市值": 203591459.0,
        "市盈率": 7.84,
        "成交量": 278078.0,
        "成交额": 1692252.0,
        "振幅": 1.82,
        "换手率": 0.83,
        "symbol": "107.EPM"
    },
    {
        "序号": 3145,
        "name": "范科工业",
        "最新价": 15.23,
        "涨跌额": 0.1,
        "涨跌幅": 0.66,
        "开盘价": 14.81,
        "最高价": 15.23,
        "最低价": 14.65,
        "昨收价": 15.13,
        "总市值": 223236801.0,
        "市盈率": 18.54,
        "成交量": 29447.0,
        "成交额": 441963.0,
        "振幅": 3.83,
        "换手率": 0.2,
        "symbol": "107.GENC"
    },
    {
        "序号": 3146,
        "name": "Fidelity Enhanced Small Cap ETF",
        "最新价": 25.91,
        "涨跌额": 0.17,
        "涨跌幅": 0.66,
        "开盘价": 25.57,
        "最高价": 25.99,
        "最低价": 25.57,
        "昨收价": 25.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 47700.0,
        "成交额": 1234357.0,
        "振幅": 1.63,
        "换手率": null,
        "symbol": "107.FESM"
    },
    {
        "序号": 3147,
        "name": "Eaton Vance Senior Income Trust",
        "最新价": 6.1,
        "涨跌额": 0.04,
        "涨跌幅": 0.66,
        "开盘价": 6.04,
        "最高价": 6.16,
        "最低价": 6.04,
        "昨收价": 6.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 52658.0,
        "成交额": 321424.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "106.EVF"
    },
    {
        "序号": 3148,
        "name": "New Providence Acquisition Corp",
        "最新价": 10.68,
        "涨跌额": 0.07,
        "涨跌幅": 0.66,
        "开盘价": 10.61,
        "最高价": 10.68,
        "最低价": 10.61,
        "昨收价": 10.61,
        "总市值": 123010905.0,
        "市盈率": 28.62,
        "成交量": 335.0,
        "成交额": 3562.0,
        "振幅": 0.66,
        "换手率": 0.0,
        "symbol": "105.NPAB"
    },
    {
        "序号": 3149,
        "name": "Chemung Financial Corp",
        "最新价": 47.31,
        "涨跌额": 0.31,
        "涨跌幅": 0.66,
        "开盘价": 47.27,
        "最高价": 47.31,
        "最低价": 46.69,
        "昨收价": 47.0,
        "总市值": 223427909.0,
        "市盈率": 7.8,
        "成交量": 9752.0,
        "成交额": 458229.0,
        "振幅": 1.32,
        "换手率": 0.21,
        "symbol": "105.CHMG"
    },
    {
        "序号": 3150,
        "name": "Euclidean Fundamental Value ETF",
        "最新价": 29.0,
        "涨跌额": 0.19,
        "涨跌幅": 0.66,
        "开盘价": 29.0,
        "最高价": 29.0,
        "最低价": 29.0,
        "昨收价": 28.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 21.0,
        "成交额": 609.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ECML"
    },
    {
        "序号": 3151,
        "name": "Progress Software Corp",
        "最新价": 53.44,
        "涨跌额": 0.35,
        "涨跌幅": 0.66,
        "开盘价": 53.15,
        "最高价": 53.79,
        "最低价": 52.99,
        "昨收价": 53.09,
        "总市值": 2328132090.0,
        "市盈率": 29.63,
        "成交量": 283140.0,
        "成交额": 15118145.0,
        "振幅": 1.51,
        "换手率": 0.65,
        "symbol": "105.PRGS"
    },
    {
        "序号": 3152,
        "name": "ARK Israel Innovative Technolog",
        "最新价": 18.33,
        "涨跌额": 0.12,
        "涨跌幅": 0.66,
        "开盘价": 18.14,
        "最高价": 18.39,
        "最低价": 18.14,
        "昨收价": 18.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 9980.0,
        "成交额": 182683.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.IZRL"
    },
    {
        "序号": 3153,
        "name": "万豪国际",
        "最新价": 209.4,
        "涨跌额": 1.37,
        "涨跌幅": 0.66,
        "开盘价": 207.68,
        "最高价": 209.82,
        "最低价": 207.18,
        "昨收价": 208.03,
        "总市值": 61498968271.0,
        "市盈率": 21.15,
        "成交量": 1276280.0,
        "成交额": 266668221.0,
        "振幅": 1.27,
        "换手率": 0.43,
        "symbol": "105.MAR"
    },
    {
        "序号": 3154,
        "name": "凯利服务-A",
        "最新价": 21.4,
        "涨跌额": 0.14,
        "涨跌幅": 0.66,
        "开盘价": 21.26,
        "最高价": 21.43,
        "最低价": 21.04,
        "昨收价": 21.26,
        "总市值": 754894651.0,
        "市盈率": 31.32,
        "成交量": 128688.0,
        "成交额": 2748669.0,
        "振幅": 1.83,
        "换手率": 0.4,
        "symbol": "105.KELYA"
    },
    {
        "序号": 3155,
        "name": "二倍做空欧元ETF-ProShares",
        "最新价": 30.58,
        "涨跌额": 0.2,
        "涨跌幅": 0.66,
        "开盘价": 30.75,
        "最高价": 30.75,
        "最低价": 30.5,
        "昨收价": 30.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 38929.0,
        "成交额": 1191880.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.EUO"
    },
    {
        "序号": 3156,
        "name": "艾姆斯银行控股",
        "最新价": 19.88,
        "涨跌额": 0.13,
        "涨跌幅": 0.66,
        "开盘价": 19.5,
        "最高价": 20.38,
        "最低价": 19.5,
        "昨收价": 19.75,
        "总市值": 178764280.0,
        "市盈率": 13.66,
        "成交量": 22658.0,
        "成交额": 452032.0,
        "振幅": 4.46,
        "换手率": 0.25,
        "symbol": "105.ATLO"
    },
    {
        "序号": 3157,
        "name": "Fidelity Enhanced Mid Cap ETF",
        "最新价": 26.02,
        "涨跌额": 0.17,
        "涨跌幅": 0.66,
        "开盘价": 25.74,
        "最高价": 26.11,
        "最低价": 25.74,
        "昨收价": 25.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 95438.0,
        "成交额": 2480248.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "107.FMDE"
    },
    {
        "序号": 3158,
        "name": "Linde plc",
        "最新价": 401.27,
        "涨跌额": 2.62,
        "涨跌幅": 0.66,
        "开盘价": 398.0,
        "最高价": 401.6,
        "最低价": 397.46,
        "昨收价": 398.65,
        "总市值": 194572005317.0,
        "市盈率": 32.52,
        "成交量": 1483659.0,
        "成交额": 594006608.0,
        "振幅": 1.04,
        "换手率": 0.31,
        "symbol": "105.LIN"
    },
    {
        "序号": 3159,
        "name": "CBIZ Inc",
        "最新价": 58.22,
        "涨跌额": 0.38,
        "涨跌幅": 0.66,
        "开盘价": 57.68,
        "最高价": 58.22,
        "最低价": 57.44,
        "昨收价": 57.84,
        "总市值": 2901791459.0,
        "市盈率": 23.74,
        "成交量": 178662.0,
        "成交额": 10357771.0,
        "振幅": 1.35,
        "换手率": 0.36,
        "symbol": "106.CBZ"
    },
    {
        "序号": 3160,
        "name": "Xtrackers MSCI Germany Hedged E",
        "最新价": 32.18,
        "涨跌额": 0.21,
        "涨跌幅": 0.66,
        "开盘价": 32.18,
        "最高价": 32.18,
        "最低价": 32.18,
        "昨收价": 31.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 16087.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.DBGR"
    },
    {
        "序号": 3161,
        "name": "Invesco S&P 500 Value with Mome",
        "最新价": 49.04,
        "涨跌额": 0.32,
        "涨跌幅": 0.66,
        "开盘价": 49.0,
        "最高价": 49.04,
        "最低价": 48.82,
        "昨收价": 48.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 1968.0,
        "成交额": 96299.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.SPVM"
    },
    {
        "序号": 3162,
        "name": "AptarGroup Inc",
        "最新价": 127.32,
        "涨跌额": 0.83,
        "涨跌幅": 0.66,
        "开盘价": 126.36,
        "最高价": 127.5,
        "最低价": 125.62,
        "昨收价": 126.49,
        "总市值": 8375271169.0,
        "市盈率": 29.79,
        "成交量": 265821.0,
        "成交额": 33770123.0,
        "振幅": 1.49,
        "换手率": 0.4,
        "symbol": "106.ATR"
    },
    {
        "序号": 3163,
        "name": "甲骨文",
        "最新价": 113.61,
        "涨跌额": 0.74,
        "涨跌幅": 0.66,
        "开盘价": 112.01,
        "最高价": 113.64,
        "最低价": 111.59,
        "昨收价": 112.87,
        "总市值": 311220514290.0,
        "市盈率": 33.2,
        "成交量": 7765999.0,
        "成交额": 877678112.0,
        "振幅": 1.82,
        "换手率": 0.28,
        "symbol": "106.ORCL"
    },
    {
        "序号": 3164,
        "name": "ProShares Ultra MSCI EAFE",
        "最新价": 39.99,
        "涨跌额": 0.26,
        "涨跌幅": 0.65,
        "开盘价": 39.9,
        "最高价": 40.0,
        "最低价": 39.86,
        "昨收价": 39.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 13574.0,
        "成交额": 541960.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.EFO"
    },
    {
        "序号": 3165,
        "name": "美国金融ETF-iShares",
        "最新价": 81.56,
        "涨跌额": 0.53,
        "涨跌幅": 0.65,
        "开盘价": 81.01,
        "最高价": 81.78,
        "最低价": 80.99,
        "昨收价": 81.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 183521.0,
        "成交额": 14946759.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.IYF"
    },
    {
        "序号": 3166,
        "name": "Harbor Long-Term Growers ETF",
        "最新价": 20.01,
        "涨跌额": 0.13,
        "涨跌幅": 0.65,
        "开盘价": 19.81,
        "最高价": 20.05,
        "最低价": 19.74,
        "昨收价": 19.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 84760.0,
        "成交额": 1692741.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "106.WINN"
    },
    {
        "序号": 3167,
        "name": "Immatics NV",
        "最新价": 9.25,
        "涨跌额": 0.06,
        "涨跌幅": 0.65,
        "开盘价": 9.25,
        "最高价": 9.35,
        "最低价": 8.96,
        "昨收价": 9.19,
        "总市值": 783069055.0,
        "市盈率": -9.23,
        "成交量": 261313.0,
        "成交额": 2419176.0,
        "振幅": 4.24,
        "换手率": 0.31,
        "symbol": "105.IMTX"
    },
    {
        "序号": 3168,
        "name": "BlueStar Israel Technology ETF",
        "最新价": 43.2,
        "涨跌额": 0.28,
        "涨跌幅": 0.65,
        "开盘价": 42.72,
        "最高价": 43.32,
        "最低价": 42.72,
        "昨收价": 42.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 3881.0,
        "成交额": 167232.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "107.ITEQ"
    },
    {
        "序号": 3169,
        "name": "万基遗传",
        "最新价": 18.52,
        "涨跌额": 0.12,
        "涨跌幅": 0.65,
        "开盘价": 18.23,
        "最高价": 19.02,
        "最低价": 17.86,
        "昨收价": 18.4,
        "总市值": 1642146009.0,
        "市盈率": -5.98,
        "成交量": 464777.0,
        "成交额": 8618317.0,
        "振幅": 6.3,
        "换手率": 0.52,
        "symbol": "105.MYGN"
    },
    {
        "序号": 3170,
        "name": "Green Brick Partners Inc",
        "最新价": 50.95,
        "涨跌额": 0.33,
        "涨跌幅": 0.65,
        "开盘价": 50.35,
        "最高价": 51.42,
        "最低价": 50.34,
        "昨收价": 50.62,
        "总市值": 2312027646.0,
        "市盈率": 8.65,
        "成交量": 231605.0,
        "成交额": 11784030.0,
        "振幅": 2.13,
        "换手率": 0.51,
        "symbol": "106.GRBK"
    },
    {
        "序号": 3171,
        "name": "AdvisorShares Dorsey Wright ADR",
        "最新价": 54.05,
        "涨跌额": 0.35,
        "涨跌幅": 0.65,
        "开盘价": 53.02,
        "最高价": 54.21,
        "最低价": 53.02,
        "昨收价": 53.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 1363.0,
        "成交额": 73407.0,
        "振幅": 2.22,
        "换手率": null,
        "symbol": "105.AADR"
    },
    {
        "序号": 3172,
        "name": "可选消费ETF-FirstTrust AlphaDEX",
        "最新价": 55.64,
        "涨跌额": 0.36,
        "涨跌幅": 0.65,
        "开盘价": 55.06,
        "最高价": 55.82,
        "最低价": 55.06,
        "昨收价": 55.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 102949.0,
        "成交额": 5725407.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.FXD"
    },
    {
        "序号": 3173,
        "name": "iShares Cybersecurity and Tech ",
        "最新价": 43.28,
        "涨跌额": 0.28,
        "涨跌幅": 0.65,
        "开盘价": 42.74,
        "最高价": 43.38,
        "最低价": 42.74,
        "昨收价": 43.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 59179.0,
        "成交额": 2555674.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "107.IHAK"
    },
    {
        "序号": 3174,
        "name": "ProShares Merger ETF",
        "最新价": 40.21,
        "涨跌额": 0.26,
        "涨跌幅": 0.65,
        "开盘价": 40.22,
        "最高价": 40.22,
        "最低价": 40.14,
        "昨收价": 39.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 5227.0,
        "成交额": 210006.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.MRGR"
    },
    {
        "序号": 3175,
        "name": "Janus Henderson Small Cap Growt",
        "最新价": 54.15,
        "涨跌额": 0.35,
        "涨跌幅": 0.65,
        "开盘价": 53.75,
        "最高价": 54.48,
        "最低价": 53.75,
        "昨收价": 53.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 15400.0,
        "成交额": 834946.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "105.JSML"
    },
    {
        "序号": 3176,
        "name": "Radius Recycling-A",
        "最新价": 26.37,
        "涨跌额": 0.17,
        "涨跌幅": 0.65,
        "开盘价": 26.17,
        "最高价": 26.97,
        "最低价": 26.17,
        "昨收价": 26.2,
        "总市值": 725497584.0,
        "市盈率": -28.13,
        "成交量": 111235.0,
        "成交额": 2949591.0,
        "振幅": 3.05,
        "换手率": 0.4,
        "symbol": "105.RDUS"
    },
    {
        "序号": 3177,
        "name": "BNY Mellon US Small Cap Core Eq",
        "最新价": 86.99,
        "涨跌额": 0.56,
        "涨跌幅": 0.65,
        "开盘价": 86.58,
        "最高价": 87.22,
        "最低价": 86.58,
        "昨收价": 86.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 2358.0,
        "成交额": 205018.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.BKSE"
    },
    {
        "序号": 3178,
        "name": "电信业指数ETF-Vanguard",
        "最新价": 113.42,
        "涨跌额": 0.73,
        "涨跌幅": 0.65,
        "开盘价": 112.01,
        "最高价": 113.67,
        "最低价": 112.01,
        "昨收价": 112.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 223915.0,
        "成交额": 25268449.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "107.VOX"
    },
    {
        "序号": 3179,
        "name": "iShares Virtual Work and Life M",
        "最新价": 15.54,
        "涨跌额": 0.1,
        "涨跌幅": 0.65,
        "开盘价": 15.51,
        "最高价": 15.55,
        "最低价": 15.51,
        "昨收价": 15.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 224.0,
        "成交额": 3478.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.IWFH"
    },
    {
        "序号": 3180,
        "name": "Invesco S&P SmallCap 600 QVM Mu",
        "最新价": 23.35,
        "涨跌额": 0.15,
        "涨跌幅": 0.65,
        "开盘价": 23.35,
        "最高价": 23.35,
        "最低价": 23.35,
        "昨收价": 23.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 901.0,
        "成交额": 21035.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.QVMS"
    },
    {
        "序号": 3181,
        "name": "Invesco S&P 500 Equal Weight Co",
        "最新价": 45.16,
        "涨跌额": 0.29,
        "涨跌幅": 0.65,
        "开盘价": 44.84,
        "最高价": 45.22,
        "最低价": 44.84,
        "昨收价": 44.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 39737.0,
        "成交额": 1792484.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.RSPD"
    },
    {
        "序号": 3182,
        "name": "Mission Produce Inc",
        "最新价": 9.35,
        "涨跌额": 0.06,
        "涨跌幅": 0.65,
        "开盘价": 9.28,
        "最高价": 9.48,
        "最低价": 9.24,
        "昨收价": 9.29,
        "总市值": 661906322.0,
        "市盈率": -13.56,
        "成交量": 74130.0,
        "成交额": 692918.0,
        "振幅": 2.58,
        "换手率": 0.1,
        "symbol": "105.AVO"
    },
    {
        "序号": 3183,
        "name": "农产品ETF-PowerShares",
        "最新价": 21.82,
        "涨跌额": 0.14,
        "涨跌幅": 0.65,
        "开盘价": 21.7,
        "最高价": 21.83,
        "最低价": 21.7,
        "昨收价": 21.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 214998.0,
        "成交额": 4680561.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.DBA"
    },
    {
        "序号": 3184,
        "name": "Unusual Whales Subversive Repub",
        "最新价": 26.53,
        "涨跌额": 0.17,
        "涨跌幅": 0.64,
        "开盘价": 26.39,
        "最高价": 26.57,
        "最低价": 26.39,
        "昨收价": 26.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 844.0,
        "成交额": 22328.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.KRUZ"
    },
    {
        "序号": 3185,
        "name": "Apartment Income REIT Corp",
        "最新价": 32.78,
        "涨跌额": 0.21,
        "涨跌幅": 0.64,
        "开盘价": 32.38,
        "最高价": 32.79,
        "最低价": 32.28,
        "昨收价": 32.57,
        "总市值": 4818421067.0,
        "市盈率": 4.91,
        "成交量": 997528.0,
        "成交额": 32607720.0,
        "振幅": 1.57,
        "换手率": 0.68,
        "symbol": "106.AIRC"
    },
    {
        "序号": 3186,
        "name": "PNC金融服务集团",
        "最新价": 140.67,
        "涨跌额": 0.9,
        "涨跌幅": 0.64,
        "开盘价": 139.94,
        "最高价": 141.54,
        "最低价": 139.2,
        "昨收价": 139.77,
        "总市值": 56034643944.0,
        "市盈率": 8.98,
        "成交量": 2444452.0,
        "成交额": 343791904.0,
        "振幅": 1.67,
        "换手率": 0.61,
        "symbol": "106.PNC"
    },
    {
        "序号": 3187,
        "name": "KLX Energy Services Holdings In",
        "最新价": 9.38,
        "涨跌额": 0.06,
        "涨跌幅": 0.64,
        "开盘价": 9.39,
        "最高价": 9.5,
        "最低价": 9.24,
        "昨收价": 9.32,
        "总市值": 153901712.0,
        "市盈率": 3.7,
        "成交量": 110741.0,
        "成交额": 1035201.0,
        "振幅": 2.79,
        "换手率": 0.67,
        "symbol": "105.KLXE"
    },
    {
        "序号": 3188,
        "name": "iShares Morningstar Small-Cap E",
        "最新价": 50.03,
        "涨跌额": 0.32,
        "涨跌幅": 0.64,
        "开盘价": 50.17,
        "最高价": 50.22,
        "最低价": 49.71,
        "昨收价": 49.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 6684.0,
        "成交额": 333912.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.ISCB"
    },
    {
        "序号": 3189,
        "name": "Barron’s 400 ETF",
        "最新价": 59.43,
        "涨跌额": 0.38,
        "涨跌幅": 0.64,
        "开盘价": 59.12,
        "最高价": 59.57,
        "最低价": 59.12,
        "昨收价": 59.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 3127.0,
        "成交额": 185886.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.BFOR"
    },
    {
        "序号": 3190,
        "name": "JPMorgan Active Small Cap Value",
        "最新价": 51.62,
        "涨跌额": 0.33,
        "涨跌幅": 0.64,
        "开盘价": 51.62,
        "最高价": 51.62,
        "最低价": 51.62,
        "昨收价": 51.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 1290.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JPSV"
    },
    {
        "序号": 3191,
        "name": "PowerUp Acquisition Corp Unit C",
        "最新价": 10.95,
        "涨跌额": 0.07,
        "涨跌幅": 0.64,
        "开盘价": 10.94,
        "最高价": 10.95,
        "最低价": 10.94,
        "昨收价": 10.88,
        "总市值": 98453958.0,
        "市盈率": 14.38,
        "成交量": 200.0,
        "成交额": 2189.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.PWUPU"
    },
    {
        "序号": 3192,
        "name": "FT Cboe Vest U.S. Small Cap Mod",
        "最新价": 20.35,
        "涨跌额": 0.13,
        "涨跌幅": 0.64,
        "开盘价": 20.36,
        "最高价": 20.37,
        "最低价": 20.26,
        "昨收价": 20.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 10932.0,
        "成交额": 222282.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.SAUG"
    },
    {
        "序号": 3193,
        "name": "SCE Trust V Preference Securiti",
        "最新价": 23.5,
        "涨跌额": 0.15,
        "涨跌幅": 0.64,
        "开盘价": 23.5,
        "最高价": 23.68,
        "最低价": 23.34,
        "昨收价": 23.35,
        "总市值": 282000000.0,
        "市盈率": null,
        "成交量": 12840.0,
        "成交额": 301703.0,
        "振幅": 1.46,
        "换手率": 0.11,
        "symbol": "106.SCE_K"
    },
    {
        "序号": 3194,
        "name": "HomeTrust Bancshares Inc",
        "最新价": 25.08,
        "涨跌额": 0.16,
        "涨跌幅": 0.64,
        "开盘价": 24.78,
        "最高价": 25.33,
        "最低价": 24.78,
        "昨收价": 24.92,
        "总市值": 435973340.0,
        "市盈率": 8.68,
        "成交量": 25417.0,
        "成交额": 637845.0,
        "振幅": 2.21,
        "换手率": 0.15,
        "symbol": "105.HTBI"
    },
    {
        "序号": 3195,
        "name": "Fiverr International Ltd",
        "最新价": 26.66,
        "涨跌额": 0.17,
        "涨跌幅": 0.64,
        "开盘价": 26.36,
        "最高价": 27.05,
        "最低价": 26.32,
        "昨收价": 26.49,
        "总市值": 1023186353.0,
        "市盈率": -441.22,
        "成交量": 510362.0,
        "成交额": 13608866.0,
        "振幅": 2.76,
        "换手率": 1.33,
        "symbol": "106.FVRR"
    },
    {
        "序号": 3196,
        "name": "Kurv Yield Premium Strategy Tes",
        "最新价": 28.23,
        "涨跌额": 0.18,
        "涨跌幅": 0.64,
        "开盘价": 28.23,
        "最高价": 28.23,
        "最低价": 28.09,
        "昨收价": 28.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 796.0,
        "成交额": 22439.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.TSLP"
    },
    {
        "序号": 3197,
        "name": "Schwab U.S. Small-Cap ETF",
        "最新价": 43.96,
        "涨跌额": 0.28,
        "涨跌幅": 0.64,
        "开盘价": 43.55,
        "最高价": 44.13,
        "最低价": 43.51,
        "昨收价": 43.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 1685697.0,
        "成交额": 73971746.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.SCHA"
    },
    {
        "序号": 3198,
        "name": "Atlantic Union Bankshares Corp ",
        "最新价": 20.43,
        "涨跌额": 0.13,
        "涨跌幅": 0.64,
        "开盘价": 20.48,
        "最高价": 20.7,
        "最低价": 20.18,
        "昨收价": 20.3,
        "总市值": 352418.0,
        "市盈率": null,
        "成交量": 20136.0,
        "成交额": 411686.0,
        "振幅": 2.56,
        "换手率": 116.73,
        "symbol": "106.AUB_A"
    },
    {
        "序号": 3199,
        "name": "Overlay Shares Hedged Large Cap",
        "最新价": 28.29,
        "涨跌额": 0.18,
        "涨跌幅": 0.64,
        "开盘价": 28.12,
        "最高价": 28.29,
        "最低价": 28.12,
        "昨收价": 28.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 8712.0,
        "成交额": 245281.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.OVLH"
    },
    {
        "序号": 3200,
        "name": "Global X Robotics & Artificial ",
        "最新价": 26.72,
        "涨跌额": 0.17,
        "涨跌幅": 0.64,
        "开盘价": 26.51,
        "最高价": 26.77,
        "最低价": 26.38,
        "昨收价": 26.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1092472.0,
        "成交额": 29130093.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "105.BOTZ"
    },
    {
        "序号": 3201,
        "name": "WBI BullBear Quality 3000 ETF",
        "最新价": 29.87,
        "涨跌额": 0.19,
        "涨跌幅": 0.64,
        "开盘价": 29.79,
        "最高价": 29.87,
        "最低价": 29.68,
        "昨收价": 29.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 6377.0,
        "成交额": 189707.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.WBIL"
    },
    {
        "序号": 3202,
        "name": "Pacer US Small Cap Cash Cows Gr",
        "最新价": 22.02,
        "涨跌额": 0.14,
        "涨跌幅": 0.64,
        "开盘价": 22.0,
        "最高价": 22.13,
        "最低价": 21.99,
        "昨收价": 21.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 3441.0,
        "成交额": 75750.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "105.CAFG"
    },
    {
        "序号": 3203,
        "name": "Clearway Energy Inc-A",
        "最新价": 23.6,
        "涨跌额": 0.15,
        "涨跌幅": 0.64,
        "开盘价": 23.25,
        "最高价": 23.77,
        "最低价": 23.2,
        "昨收价": 23.45,
        "总市值": 4769083280.0,
        "市盈率": 88.32,
        "成交量": 346681.0,
        "成交额": 8141028.0,
        "振幅": 2.43,
        "换手率": 1.0,
        "symbol": "106.CWEN_A"
    },
    {
        "序号": 3204,
        "name": "iShares Morningstar Small-Cap G",
        "最新价": 40.99,
        "涨跌额": 0.26,
        "涨跌幅": 0.64,
        "开盘价": 40.79,
        "最高价": 41.14,
        "最低价": 40.63,
        "昨收价": 40.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 67280.0,
        "成交额": 2755784.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.ISCG"
    },
    {
        "序号": 3205,
        "name": "Arbor Realty Trust Inc Series F",
        "最新价": 18.92,
        "涨跌额": 0.12,
        "涨跌幅": 0.64,
        "开盘价": 19.01,
        "最高价": 19.05,
        "最低价": 18.88,
        "昨收价": 18.8,
        "总市值": 214590640.0,
        "市盈率": null,
        "成交量": 7455.0,
        "成交额": 141206.0,
        "振幅": 0.9,
        "换手率": 0.07,
        "symbol": "106.ABR_F"
    },
    {
        "序号": 3206,
        "name": "Seapeak LLC Series A Pfd",
        "最新价": 25.23,
        "涨跌额": 0.16,
        "涨跌幅": 0.64,
        "开盘价": 25.0,
        "最高价": 25.23,
        "最低价": 25.0,
        "昨收价": 25.07,
        "总市值": 125189822.0,
        "市盈率": null,
        "成交量": 6481.0,
        "成交额": 162513.0,
        "振幅": 0.92,
        "换手率": 0.13,
        "symbol": "106.SEAL_A"
    },
    {
        "序号": 3207,
        "name": "Jackson Financial Inc Series A ",
        "最新价": 25.24,
        "涨跌额": 0.16,
        "涨跌幅": 0.64,
        "开盘价": 25.13,
        "最高价": 25.3,
        "最低价": 25.1,
        "昨收价": 25.08,
        "总市值": 555280.0,
        "市盈率": null,
        "成交量": 38915.0,
        "成交额": 980562.0,
        "振幅": 0.8,
        "换手率": 176.89,
        "symbol": "106.JXN_A"
    },
    {
        "序号": 3208,
        "name": "库伦佛寺银行",
        "最新价": 102.57,
        "涨跌额": 0.65,
        "涨跌幅": 0.64,
        "开盘价": 102.32,
        "最高价": 103.48,
        "最低价": 102.13,
        "昨收价": 101.92,
        "总市值": 6569320278.0,
        "市盈率": 9.57,
        "成交量": 231671.0,
        "成交额": 23762548.0,
        "振幅": 1.32,
        "换手率": 0.36,
        "symbol": "106.CFR"
    },
    {
        "序号": 3209,
        "name": "STF Tactical Growth & Income ET",
        "最新价": 22.14,
        "涨跌额": 0.14,
        "涨跌幅": 0.64,
        "开盘价": 21.87,
        "最高价": 22.14,
        "最低价": 21.87,
        "昨收价": 22.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2481.0,
        "成交额": 54650.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "105.TUGN"
    },
    {
        "序号": 3210,
        "name": "Avantis U.S Small Cap Equity ET",
        "最新价": 47.46,
        "涨跌额": 0.3,
        "涨跌幅": 0.64,
        "开盘价": 47.11,
        "最高价": 47.67,
        "最低价": 47.11,
        "昨收价": 47.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 90797.0,
        "成交额": 4303747.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.AVSC"
    },
    {
        "序号": 3211,
        "name": "Telephone and Data Systems Inc ",
        "最新价": 14.24,
        "涨跌额": 0.09,
        "涨跌幅": 0.64,
        "开盘价": 14.09,
        "最高价": 14.29,
        "最低价": 14.02,
        "昨收价": 14.15,
        "总市值": 393024.0,
        "市盈率": null,
        "成交量": 46080.0,
        "成交额": 651858.0,
        "振幅": 1.91,
        "换手率": 166.96,
        "symbol": "106.TDS_V"
    },
    {
        "序号": 3212,
        "name": "Soho House & Co Inc-A",
        "最新价": 6.33,
        "涨跌额": 0.04,
        "涨跌幅": 0.64,
        "开盘价": 6.29,
        "最高价": 6.44,
        "最低价": 6.29,
        "昨收价": 6.29,
        "总市值": 1234458268.0,
        "市盈率": -26.03,
        "成交量": 94774.0,
        "成交额": 602080.0,
        "振幅": 2.38,
        "换手率": 0.05,
        "symbol": "106.SHCO"
    },
    {
        "序号": 3213,
        "name": "OneSpaWorld Holdings Ltd",
        "最新价": 12.66,
        "涨跌额": 0.08,
        "涨跌幅": 0.64,
        "开盘价": 12.58,
        "最高价": 12.69,
        "最低价": 12.5,
        "昨收价": 12.58,
        "总市值": 1264871158.0,
        "市盈率": 632.44,
        "成交量": 578062.0,
        "成交额": 7300150.0,
        "振幅": 1.51,
        "换手率": 0.58,
        "symbol": "105.OSW"
    },
    {
        "序号": 3214,
        "name": "埃森哲",
        "最新价": 337.23,
        "涨跌额": 2.13,
        "涨跌幅": 0.64,
        "开盘价": 335.1,
        "最高价": 337.71,
        "最低价": 334.56,
        "昨收价": 335.1,
        "总市值": 211759381673.0,
        "市盈率": 30.82,
        "成交量": 1274525.0,
        "成交额": 429365504.0,
        "振幅": 0.94,
        "换手率": 0.2,
        "symbol": "106.ACN"
    },
    {
        "序号": 3215,
        "name": "ALPS O'Shares Global Internet G",
        "最新价": 34.84,
        "涨跌额": 0.22,
        "涨跌幅": 0.64,
        "开盘价": 34.36,
        "最高价": 34.91,
        "最低价": 34.36,
        "昨收价": 34.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 24908.0,
        "成交额": 866761.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "107.OGIG"
    },
    {
        "序号": 3216,
        "name": "Saratoga Investment Corp Notes",
        "最新价": 25.35,
        "涨跌额": 0.16,
        "涨跌幅": 0.64,
        "开盘价": 25.1,
        "最高价": 25.35,
        "最低价": 25.1,
        "昨收价": 25.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 638.0,
        "成交额": 16117.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "106.SAJ"
    },
    {
        "序号": 3217,
        "name": "Global X Internet of Things ETF",
        "最新价": 33.32,
        "涨跌额": 0.21,
        "涨跌幅": 0.63,
        "开盘价": 33.02,
        "最高价": 33.53,
        "最低价": 33.02,
        "昨收价": 33.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 61167.0,
        "成交额": 2039259.0,
        "振幅": 1.54,
        "换手率": null,
        "symbol": "105.SNSR"
    },
    {
        "序号": 3218,
        "name": "W. R. Berkley Corp Debentures",
        "最新价": 23.82,
        "涨跌额": 0.15,
        "涨跌幅": 0.63,
        "开盘价": 23.79,
        "最高价": 23.88,
        "最低价": 23.58,
        "昨收价": 23.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 7431.0,
        "成交额": 176746.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "106.WRB_E"
    },
    {
        "序号": 3219,
        "name": "Fidelity Small-Mid Cap Opportun",
        "最新价": 22.25,
        "涨跌额": 0.14,
        "涨跌幅": 0.63,
        "开盘价": 22.08,
        "最高价": 22.28,
        "最低价": 22.08,
        "昨收价": 22.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 14927.0,
        "成交额": 332296.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.FSMO"
    },
    {
        "序号": 3220,
        "name": "Franklin Systematic Style Premi",
        "最新价": 22.25,
        "涨跌额": 0.14,
        "涨跌幅": 0.63,
        "开盘价": 22.18,
        "最高价": 22.25,
        "最低价": 22.0,
        "昨收价": 22.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 7161.0,
        "成交额": 157745.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.FLSP"
    },
    {
        "序号": 3221,
        "name": "CarParts.com Inc",
        "最新价": 3.18,
        "涨跌额": 0.02,
        "涨跌幅": 0.63,
        "开盘价": 3.16,
        "最高价": 3.21,
        "最低价": 3.15,
        "昨收价": 3.16,
        "总市值": 182845570.0,
        "市盈率": -21.87,
        "成交量": 232353.0,
        "成交额": 738152.0,
        "振幅": 1.9,
        "换手率": 0.4,
        "symbol": "105.PRTS"
    },
    {
        "序号": 3222,
        "name": "American Century Mid Cap Growth",
        "最新价": 47.71,
        "涨跌额": 0.3,
        "涨跌幅": 0.63,
        "开盘价": 47.81,
        "最高价": 47.81,
        "最低价": 47.71,
        "昨收价": 47.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1415.0,
        "成交额": 67651.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.MID"
    },
    {
        "序号": 3223,
        "name": "标普小型股ETF-iShares",
        "最新价": 100.35,
        "涨跌额": 0.63,
        "涨跌幅": 0.63,
        "开盘价": 99.53,
        "最高价": 100.82,
        "最低价": 99.44,
        "昨收价": 99.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 4296556.0,
        "成交额": 430553872.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "107.IJR"
    },
    {
        "序号": 3224,
        "name": "Kurv Yield Premium Strategy App",
        "最新价": 27.08,
        "涨跌额": 0.17,
        "涨跌幅": 0.63,
        "开盘价": 27.08,
        "最高价": 27.08,
        "最低价": 27.08,
        "昨收价": 26.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 90.0,
        "成交额": 2437.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AAPY"
    },
    {
        "序号": 3225,
        "name": "纽星能源",
        "最新价": 19.12,
        "涨跌额": 0.12,
        "涨跌幅": 0.63,
        "开盘价": 19.14,
        "最高价": 19.19,
        "最低价": 18.96,
        "昨收价": 19.0,
        "总市值": 2407122782.0,
        "市盈率": 8.16,
        "成交量": 561482.0,
        "成交额": 10728228.0,
        "振幅": 1.21,
        "换手率": 0.45,
        "symbol": "106.NS"
    },
    {
        "序号": 3226,
        "name": "Abeona Therapeutics Inc",
        "最新价": 4.78,
        "涨跌额": 0.03,
        "涨跌幅": 0.63,
        "开盘价": 4.75,
        "最高价": 4.88,
        "最低价": 4.7,
        "昨收价": 4.75,
        "总市值": 118416455.0,
        "市盈率": -2.65,
        "成交量": 111176.0,
        "成交额": 534118.0,
        "振幅": 3.79,
        "换手率": 0.45,
        "symbol": "105.ABEO"
    },
    {
        "序号": 3227,
        "name": "BrightView Holdings Inc",
        "最新价": 7.97,
        "涨跌额": 0.05,
        "涨跌幅": 0.63,
        "开盘价": 7.88,
        "最高价": 8.02,
        "最低价": 7.88,
        "昨收价": 7.92,
        "总市值": 745992000.0,
        "市盈率": -96.88,
        "成交量": 179090.0,
        "成交额": 1428252.0,
        "振幅": 1.77,
        "换手率": 0.19,
        "symbol": "106.BV"
    },
    {
        "序号": 3228,
        "name": "Dimensional US Small Cap Value ",
        "最新价": 27.1,
        "涨跌额": 0.17,
        "涨跌幅": 0.63,
        "开盘价": 26.94,
        "最高价": 27.22,
        "最低价": 26.85,
        "昨收价": 26.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 412235.0,
        "成交额": 11153637.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.DFSV"
    },
    {
        "序号": 3229,
        "name": "W&T海底钻探",
        "最新价": 3.19,
        "涨跌额": 0.02,
        "涨跌幅": 0.63,
        "开盘价": 3.2,
        "最高价": 3.25,
        "最低价": 3.15,
        "昨收价": 3.17,
        "总市值": 467571676.0,
        "市盈率": 7.86,
        "成交量": 2525705.0,
        "成交额": 8051912.0,
        "振幅": 3.15,
        "换手率": 1.72,
        "symbol": "106.WTI"
    },
    {
        "序号": 3230,
        "name": "Capital Group Core Equity ETF",
        "最新价": 27.13,
        "涨跌额": 0.17,
        "涨跌幅": 0.63,
        "开盘价": 26.9,
        "最高价": 27.16,
        "最低价": 26.9,
        "昨收价": 26.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 733036.0,
        "成交额": 19826620.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.CGUS"
    },
    {
        "序号": 3231,
        "name": "Theratechnologies Inc",
        "最新价": 1.6,
        "涨跌额": 0.01,
        "涨跌幅": 0.63,
        "开盘价": 1.58,
        "最高价": 1.63,
        "最低价": 1.55,
        "昨收价": 1.59,
        "总市值": 78722936.0,
        "市盈率": -2.7,
        "成交量": 33642.0,
        "成交额": 53577.0,
        "振幅": 5.03,
        "换手率": 0.07,
        "symbol": "105.THTX"
    },
    {
        "序号": 3232,
        "name": "爱依斯电力(普通单位)",
        "最新价": 72.01,
        "涨跌额": 0.45,
        "涨跌幅": 0.63,
        "开盘价": 71.57,
        "最高价": 72.01,
        "最低价": 71.15,
        "昨收价": 71.56,
        "总市值": 48219986810.0,
        "市盈率": -86.11,
        "成交量": 280965.0,
        "成交额": 20109860.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "106.AESC"
    },
    {
        "序号": 3233,
        "name": "LeaderShares AlphaFactor Tactic",
        "最新价": 33.62,
        "涨跌额": 0.21,
        "涨跌幅": 0.63,
        "开盘价": 33.5,
        "最高价": 33.67,
        "最低价": 33.5,
        "昨收价": 33.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 42448.0,
        "成交额": 1425483.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.LSAT"
    },
    {
        "序号": 3234,
        "name": "YieldMax AMZN Option Income Str",
        "最新价": 20.82,
        "涨跌额": 0.13,
        "涨跌幅": 0.63,
        "开盘价": 20.54,
        "最高价": 20.84,
        "最低价": 20.51,
        "昨收价": 20.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 36463.0,
        "成交额": 755229.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "107.AMZY"
    },
    {
        "序号": 3235,
        "name": "纳图兹家具",
        "最新价": 6.41,
        "涨跌额": 0.04,
        "涨跌幅": 0.63,
        "开盘价": 6.5,
        "最高价": 6.5,
        "最低价": 6.25,
        "昨收价": 6.37,
        "总市值": 70603644.0,
        "市盈率": -5.63,
        "成交量": 7854.0,
        "成交额": 49475.0,
        "振幅": 3.92,
        "换手率": 0.07,
        "symbol": "106.NTZ"
    },
    {
        "序号": 3236,
        "name": "Global X U.S. Cash Flow Kings 1",
        "最新价": 27.26,
        "涨跌额": 0.17,
        "涨跌幅": 0.63,
        "开盘价": 27.16,
        "最高价": 27.26,
        "最低价": 27.16,
        "昨收价": 27.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 268.0,
        "成交额": 7289.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.FLOW"
    },
    {
        "序号": 3237,
        "name": "哈勃集团",
        "最新价": 308.17,
        "涨跌额": 1.92,
        "涨跌幅": 0.63,
        "开盘价": 307.73,
        "最高价": 310.89,
        "最低价": 306.9,
        "昨收价": 306.25,
        "总市值": 16524707149.0,
        "市盈率": 23.83,
        "成交量": 335382.0,
        "成交额": 103516854.0,
        "振幅": 1.3,
        "换手率": 0.63,
        "symbol": "106.HUBB"
    },
    {
        "序号": 3238,
        "name": "Invesco Nasdaq Free Cash Flow A",
        "最新价": 24.08,
        "涨跌额": 0.15,
        "涨跌幅": 0.63,
        "开盘价": 24.08,
        "最高价": 24.08,
        "最低价": 24.06,
        "昨收价": 23.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 914.0,
        "成交额": 21996.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.QOWZ"
    },
    {
        "序号": 3239,
        "name": "ClearBridge Large Cap Growth ES",
        "最新价": 57.86,
        "涨跌额": 0.36,
        "涨跌幅": 0.63,
        "开盘价": 57.66,
        "最高价": 57.95,
        "最低价": 57.55,
        "昨收价": 57.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 9492.0,
        "成交额": 549158.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.LRGE"
    },
    {
        "序号": 3240,
        "name": "Horizon Kinetics Inflation Bene",
        "最新价": 30.57,
        "涨跌额": 0.19,
        "涨跌幅": 0.63,
        "开盘价": 30.47,
        "最高价": 30.65,
        "最低价": 30.4,
        "昨收价": 30.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 51734.0,
        "成交额": 1577318.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.INFL"
    },
    {
        "序号": 3241,
        "name": "墨西哥电视",
        "最新价": 3.22,
        "涨跌额": 0.02,
        "涨跌幅": 0.63,
        "开盘价": 3.17,
        "最高价": 3.24,
        "最低价": 3.12,
        "昨收价": 3.2,
        "总市值": 1793460185.0,
        "市盈率": -2.97,
        "成交量": 1348559.0,
        "成交额": 4295612.0,
        "振幅": 3.75,
        "换手率": 0.24,
        "symbol": "106.TV"
    },
    {
        "序号": 3242,
        "name": "Jet.AI Inc",
        "最新价": 1.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.63,
        "开盘价": 1.52,
        "最高价": 1.88,
        "最低价": 1.52,
        "昨收价": 1.6,
        "总市值": 14514817.0,
        "市盈率": 5.73,
        "成交量": 44117.0,
        "成交额": 72922.0,
        "振幅": 22.5,
        "换手率": 0.49,
        "symbol": "105.JTAI"
    },
    {
        "序号": 3243,
        "name": "GlycoMimetics Inc",
        "最新价": 1.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.63,
        "开盘价": 1.59,
        "最高价": 1.64,
        "最低价": 1.52,
        "昨收价": 1.6,
        "总市值": 103673203.0,
        "市盈率": -2.72,
        "成交量": 39854.0,
        "成交额": 62619.0,
        "振幅": 7.5,
        "换手率": 0.06,
        "symbol": "105.GLYC"
    },
    {
        "序号": 3244,
        "name": "Blend Labs Inc-A",
        "最新价": 1.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.63,
        "开盘价": 1.58,
        "最高价": 1.65,
        "最低价": 1.52,
        "昨收价": 1.6,
        "总市值": 399600295.0,
        "市盈率": -1.69,
        "成交量": 878512.0,
        "成交额": 1397499.0,
        "振幅": 8.13,
        "换手率": 0.35,
        "symbol": "106.BLND"
    },
    {
        "序号": 3245,
        "name": "可口可乐凡萨瓶装",
        "最新价": 88.59,
        "涨跌额": 0.55,
        "涨跌幅": 0.62,
        "开盘价": 87.71,
        "最高价": 89.15,
        "最低价": 87.51,
        "昨收价": 88.04,
        "总市值": 148890184072.0,
        "市盈率": 136.1,
        "成交量": 64910.0,
        "成交额": 5741783.0,
        "振幅": 1.86,
        "换手率": 0.0,
        "symbol": "106.KOF"
    },
    {
        "序号": 3246,
        "name": "iShares S&P Small-Cap 600 Growt",
        "最新价": 116.02,
        "涨跌额": 0.72,
        "涨跌幅": 0.62,
        "开盘价": 115.11,
        "最高价": 116.5,
        "最低价": 115.11,
        "昨收价": 115.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 151418.0,
        "成交额": 17537570.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "105.IJT"
    },
    {
        "序号": 3247,
        "name": "Goldman Sachs Innovate Equity E",
        "最新价": 49.97,
        "涨跌额": 0.31,
        "涨跌幅": 0.62,
        "开盘价": 50.0,
        "最高价": 50.1,
        "最低价": 49.72,
        "昨收价": 49.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 6887.0,
        "成交额": 344397.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.GINN"
    },
    {
        "序号": 3248,
        "name": "罗素2000价值股ETF-iShares",
        "最新价": 145.08,
        "涨跌额": 0.9,
        "涨跌幅": 0.62,
        "开盘价": 143.89,
        "最高价": 145.84,
        "最低价": 143.63,
        "昨收价": 144.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 1742606.0,
        "成交额": 252332798.0,
        "振幅": 1.53,
        "换手率": null,
        "symbol": "107.IWN"
    },
    {
        "序号": 3249,
        "name": "SL Green Realty Corp Series I P",
        "最新价": 19.36,
        "涨跌额": 0.12,
        "涨跌幅": 0.62,
        "开盘价": 19.17,
        "最高价": 19.36,
        "最低价": 19.17,
        "昨收价": 19.24,
        "总市值": 178112000.0,
        "市盈率": null,
        "成交量": 12477.0,
        "成交额": 240741.0,
        "振幅": 0.99,
        "换手率": 0.14,
        "symbol": "106.SLG_I"
    },
    {
        "序号": 3250,
        "name": "Inspire Global Hope ETF",
        "最新价": 33.88,
        "涨跌额": 0.21,
        "涨跌幅": 0.62,
        "开盘价": 33.85,
        "最高价": 33.92,
        "最低价": 33.71,
        "昨收价": 33.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 5576.0,
        "成交额": 188771.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.BLES"
    },
    {
        "序号": 3251,
        "name": "First Trust SMID Cap Rising Div",
        "最新价": 30.66,
        "涨跌额": 0.19,
        "涨跌幅": 0.62,
        "开盘价": 30.5,
        "最高价": 30.81,
        "最低价": 30.44,
        "昨收价": 30.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 847934.0,
        "成交额": 26004681.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "105.SDVY"
    },
    {
        "序号": 3252,
        "name": "BNY Mellon Women’s Opportuniti",
        "最新价": 27.44,
        "涨跌额": 0.17,
        "涨跌幅": 0.62,
        "开盘价": 27.44,
        "最高价": 27.44,
        "最低价": 27.44,
        "昨收价": 27.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 164.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BKWO"
    },
    {
        "序号": 3253,
        "name": "Viveon Health Acquisition Corp ",
        "最新价": 11.3,
        "涨跌额": 0.07,
        "涨跌幅": 0.62,
        "开盘价": 11.22,
        "最高价": 11.3,
        "最低价": 11.22,
        "昨收价": 11.23,
        "总市值": 75129915.0,
        "市盈率": -154.34,
        "成交量": 244.0,
        "成交额": 2747.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.VHAQ_U"
    },
    {
        "序号": 3254,
        "name": "JPMorgan Climate Change Solutio",
        "最新价": 38.77,
        "涨跌额": 0.24,
        "涨跌幅": 0.62,
        "开盘价": 38.77,
        "最高价": 38.77,
        "最低价": 38.77,
        "昨收价": 38.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 271.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TEMP"
    },
    {
        "序号": 3255,
        "name": "iShares ESG MSCI USA Small-Cap ",
        "最新价": 35.54,
        "涨跌额": 0.22,
        "涨跌幅": 0.62,
        "开盘价": 35.33,
        "最高价": 35.71,
        "最低价": 35.33,
        "昨收价": 35.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 112598.0,
        "成交额": 3993949.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.ESML"
    },
    {
        "序号": 3256,
        "name": "WisdomTree Europe Quality Divid",
        "最新价": 30.7,
        "涨跌额": 0.19,
        "涨跌幅": 0.62,
        "开盘价": 30.54,
        "最高价": 30.72,
        "最低价": 30.54,
        "昨收价": 30.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 1800.0,
        "成交额": 55205.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.EUDG"
    },
    {
        "序号": 3257,
        "name": "First Trust S-Network Future Ve",
        "最新价": 53.33,
        "涨跌额": 0.33,
        "涨跌幅": 0.62,
        "开盘价": 53.19,
        "最高价": 53.48,
        "最低价": 53.02,
        "昨收价": 53.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2194.0,
        "成交额": 117004.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "105.CARZ"
    },
    {
        "序号": 3258,
        "name": "巴西特家具",
        "最新价": 17.79,
        "涨跌额": 0.11,
        "涨跌幅": 0.62,
        "开盘价": 17.7,
        "最高价": 17.79,
        "最低价": 17.41,
        "昨收价": 17.68,
        "总市值": 156907658.0,
        "市盈率": 26.34,
        "成交量": 12933.0,
        "成交额": 227644.0,
        "振幅": 2.15,
        "换手率": 0.15,
        "symbol": "105.BSET"
    },
    {
        "序号": 3259,
        "name": "移动支付ETF-PureFunds ISE",
        "最新价": 43.74,
        "涨跌额": 0.27,
        "涨跌幅": 0.62,
        "开盘价": 43.39,
        "最高价": 43.91,
        "最低价": 43.3,
        "昨收价": 43.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 42648.0,
        "成交额": 1862156.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "107.IPAY"
    },
    {
        "序号": 3260,
        "name": "Inspire Small\/Mid Cap ETF",
        "最新价": 32.42,
        "涨跌额": 0.2,
        "涨跌幅": 0.62,
        "开盘价": 32.35,
        "最高价": 32.47,
        "最低价": 32.25,
        "昨收价": 32.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 15004.0,
        "成交额": 485374.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.ISMD"
    },
    {
        "序号": 3261,
        "name": "慧与",
        "最新价": 16.22,
        "涨跌额": 0.1,
        "涨跌幅": 0.62,
        "开盘价": 16.11,
        "最高价": 16.4,
        "最低价": 16.09,
        "昨收价": 16.12,
        "总市值": 20808065742.0,
        "市盈率": 10.28,
        "成交量": 11901285.0,
        "成交额": 193408772.0,
        "振幅": 1.92,
        "换手率": 0.93,
        "symbol": "106.HPE"
    },
    {
        "序号": 3262,
        "name": "Shore Bancshares Inc",
        "最新价": 12.98,
        "涨跌额": 0.08,
        "涨跌幅": 0.62,
        "开盘价": 12.88,
        "最高价": 13.05,
        "最低价": 12.87,
        "昨收价": 12.9,
        "总市值": 430231121.0,
        "市盈率": 34.42,
        "成交量": 38124.0,
        "成交额": 494656.0,
        "振幅": 1.4,
        "换手率": 0.12,
        "symbol": "105.SHBI"
    },
    {
        "序号": 3263,
        "name": "Strive FAANG 2.0 ETF",
        "最新价": 24.34,
        "涨跌额": 0.15,
        "涨跌幅": 0.62,
        "开盘价": 24.35,
        "最高价": 24.35,
        "最低价": 24.34,
        "昨收价": 24.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 168.0,
        "成交额": 4090.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.FTWO"
    },
    {
        "序号": 3264,
        "name": "AbCellera Biologics Inc",
        "最新价": 4.87,
        "涨跌额": 0.03,
        "涨跌幅": 0.62,
        "开盘价": 4.79,
        "最高价": 4.97,
        "最低价": 4.7,
        "昨收价": 4.84,
        "总市值": 1413129074.0,
        "市盈率": -10.94,
        "成交量": 1524555.0,
        "成交额": 7441367.0,
        "振幅": 5.58,
        "换手率": 0.53,
        "symbol": "105.ABCL"
    },
    {
        "序号": 3265,
        "name": "OmniAb Inc",
        "最新价": 4.88,
        "涨跌额": 0.03,
        "涨跌幅": 0.62,
        "开盘价": 4.84,
        "最高价": 4.9,
        "最低价": 4.55,
        "昨收价": 4.85,
        "总市值": 567242348.0,
        "市盈率": -19.07,
        "成交量": 402650.0,
        "成交额": 1949080.0,
        "振幅": 7.22,
        "换手率": 0.35,
        "symbol": "105.OABI"
    },
    {
        "序号": 3266,
        "name": "Avanos Medical Inc",
        "最新价": 21.15,
        "涨跌额": 0.13,
        "涨跌幅": 0.62,
        "开盘价": 21.0,
        "最高价": 21.16,
        "最低价": 20.67,
        "昨收价": 21.02,
        "总市值": 981900784.0,
        "市盈率": -17.72,
        "成交量": 774464.0,
        "成交额": 16200442.0,
        "振幅": 2.33,
        "换手率": 1.67,
        "symbol": "106.AVNS"
    },
    {
        "序号": 3267,
        "name": "Medpace Holdings Inc",
        "最新价": 283.19,
        "涨跌额": 1.74,
        "涨跌幅": 0.62,
        "开盘价": 281.13,
        "最高价": 289.14,
        "最低价": 279.16,
        "昨收价": 281.45,
        "总市值": 8689242241.0,
        "市盈率": 31.81,
        "成交量": 168768.0,
        "成交额": 47908379.0,
        "振幅": 3.55,
        "换手率": 0.55,
        "symbol": "105.MEDP"
    },
    {
        "序号": 3268,
        "name": "WISeKey International Holding L",
        "最新价": 1.63,
        "涨跌额": 0.01,
        "涨跌幅": 0.62,
        "开盘价": 1.61,
        "最高价": 1.75,
        "最低价": 1.59,
        "昨收价": 1.62,
        "总市值": 14837127.0,
        "市盈率": -0.4,
        "成交量": 20620.0,
        "成交额": 33846.0,
        "振幅": 9.88,
        "换手率": 0.23,
        "symbol": "105.WKEY"
    },
    {
        "序号": 3269,
        "name": "特尔尼翁钢铁",
        "最新价": 39.12,
        "涨跌额": 0.24,
        "涨跌幅": 0.62,
        "开盘价": 38.8,
        "最高价": 39.52,
        "最低价": 38.7,
        "昨收价": 38.88,
        "总市值": 7679556348.0,
        "市盈率": 6.43,
        "成交量": 116432.0,
        "成交额": 4557645.0,
        "振幅": 2.11,
        "换手率": 0.06,
        "symbol": "106.TX"
    },
    {
        "序号": 3270,
        "name": "Vontier Corp",
        "最新价": 34.24,
        "涨跌额": 0.21,
        "涨跌幅": 0.62,
        "开盘价": 33.91,
        "最高价": 34.5,
        "最低价": 33.88,
        "昨收价": 34.03,
        "总市值": 5284180277.0,
        "市盈率": 15.62,
        "成交量": 496047.0,
        "成交额": 16968220.0,
        "振幅": 1.82,
        "换手率": 0.32,
        "symbol": "106.VNT"
    },
    {
        "序号": 3271,
        "name": "Privia Health Group Inc",
        "最新价": 21.23,
        "涨跌额": 0.13,
        "涨跌幅": 0.62,
        "开盘价": 21.09,
        "最高价": 21.37,
        "最低价": 20.87,
        "昨收价": 21.1,
        "总市值": 2506506766.0,
        "市盈率": 65.93,
        "成交量": 380857.0,
        "成交额": 8047451.0,
        "振幅": 2.37,
        "换手率": 0.32,
        "symbol": "105.PRVA"
    },
    {
        "序号": 3272,
        "name": "Constellation Energy Corp",
        "最新价": 111.21,
        "涨跌额": 0.68,
        "涨跌幅": 0.62,
        "开盘价": 109.99,
        "最高价": 111.61,
        "最低价": 109.65,
        "昨收价": 110.53,
        "总市值": 35518437078.0,
        "市盈率": 20.97,
        "成交量": 2627281.0,
        "成交额": 292019104.0,
        "振幅": 1.77,
        "换手率": 0.82,
        "symbol": "105.CEG"
    },
    {
        "序号": 3273,
        "name": "SmartETFs Smart Transportation ",
        "最新价": 39.29,
        "涨跌额": 0.24,
        "涨跌幅": 0.61,
        "开盘价": 39.37,
        "最高价": 39.37,
        "最低价": 39.29,
        "昨收价": 39.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 516.0,
        "成交额": 20316.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.MOTO"
    },
    {
        "序号": 3274,
        "name": "墨西哥ETF-iShares MSCI",
        "最新价": 63.93,
        "涨跌额": 0.39,
        "涨跌幅": 0.61,
        "开盘价": 63.9,
        "最高价": 64.07,
        "最低价": 63.44,
        "昨收价": 63.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 1341726.0,
        "成交额": 85595609.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.EWW"
    },
    {
        "序号": 3275,
        "name": "Xtrackers S&P SmallCap 600 ESG ",
        "最新价": 24.6,
        "涨跌额": 0.15,
        "涨跌幅": 0.61,
        "开盘价": 24.6,
        "最高价": 24.6,
        "最低价": 24.6,
        "昨收价": 24.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": 492.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SMLE"
    },
    {
        "序号": 3276,
        "name": "Codere Online Luxembourg SA",
        "最新价": 3.28,
        "涨跌额": 0.02,
        "涨跌幅": 0.61,
        "开盘价": 3.27,
        "最高价": 3.34,
        "最低价": 3.27,
        "昨收价": 3.26,
        "总市值": 148577112.0,
        "市盈率": -4.61,
        "成交量": 579.0,
        "成交额": 1905.0,
        "振幅": 2.15,
        "换手率": 0.0,
        "symbol": "105.CDRO"
    },
    {
        "序号": 3277,
        "name": "VanEck Mortgage REIT Income ETF",
        "最新价": 11.49,
        "涨跌额": 0.07,
        "涨跌幅": 0.61,
        "开盘价": 11.38,
        "最高价": 11.54,
        "最低价": 11.35,
        "昨收价": 11.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 201244.0,
        "成交额": 2305357.0,
        "振幅": 1.66,
        "换手率": null,
        "symbol": "107.MORT"
    },
    {
        "序号": 3278,
        "name": "PROG Holdings Inc",
        "最新价": 29.55,
        "涨跌额": 0.18,
        "涨跌幅": 0.61,
        "开盘价": 29.21,
        "最高价": 29.59,
        "最低价": 28.71,
        "昨收价": 29.37,
        "总市值": 1321542842.0,
        "市盈率": 8.45,
        "成交量": 537069.0,
        "成交额": 15717308.0,
        "振幅": 3.0,
        "换手率": 1.2,
        "symbol": "106.PRG"
    },
    {
        "序号": 3279,
        "name": "USCF Sustainable Battery Metals",
        "最新价": 16.45,
        "涨跌额": 0.1,
        "涨跌幅": 0.61,
        "开盘价": 16.42,
        "最高价": 16.45,
        "最低价": 16.42,
        "昨收价": 16.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 300.0,
        "成交额": 4926.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.ZSB"
    },
    {
        "序号": 3280,
        "name": "科休半导体",
        "最新价": 32.91,
        "涨跌额": 0.2,
        "涨跌幅": 0.61,
        "开盘价": 32.52,
        "最高价": 33.2,
        "最低价": 32.36,
        "昨收价": 32.71,
        "总市值": 1563393894.0,
        "市盈率": 30.17,
        "成交量": 204406.0,
        "成交额": 6710629.0,
        "振幅": 2.57,
        "换手率": 0.43,
        "symbol": "105.COHU"
    },
    {
        "序号": 3281,
        "name": "HF Sinclair Corp",
        "最新价": 52.67,
        "涨跌额": 0.32,
        "涨跌幅": 0.61,
        "开盘价": 52.75,
        "最高价": 53.21,
        "最低价": 52.35,
        "昨收价": 52.35,
        "总市值": 9463016331.0,
        "市盈率": 4.23,
        "成交量": 2107993.0,
        "成交额": 111108808.0,
        "振幅": 1.64,
        "换手率": 1.17,
        "symbol": "106.DINO"
    },
    {
        "序号": 3282,
        "name": "Hull Tactical US ETF",
        "最新价": 34.57,
        "涨跌额": 0.21,
        "涨跌幅": 0.61,
        "开盘价": 34.31,
        "最高价": 34.57,
        "最低价": 34.31,
        "昨收价": 34.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 962.0,
        "成交额": 33170.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.HTUS"
    },
    {
        "序号": 3283,
        "name": "BlackRock Future U.S. Themes ET",
        "最新价": 24.77,
        "涨跌额": 0.15,
        "涨跌幅": 0.61,
        "开盘价": 24.7,
        "最高价": 24.78,
        "最低价": 24.7,
        "昨收价": 24.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 339.0,
        "成交额": 8386.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.BTHM"
    },
    {
        "序号": 3284,
        "name": "应用工业技术",
        "最新价": 165.18,
        "涨跌额": 1.0,
        "涨跌幅": 0.61,
        "开盘价": 164.68,
        "最高价": 165.92,
        "最低价": 163.72,
        "昨收价": 164.18,
        "总市值": 6402378287.0,
        "市盈率": 17.6,
        "成交量": 107481.0,
        "成交额": 17744037.0,
        "振幅": 1.34,
        "换手率": 0.28,
        "symbol": "106.AIT"
    },
    {
        "序号": 3285,
        "name": "ActivePassive U.S. Equity ETF",
        "最新价": 28.11,
        "涨跌额": 0.17,
        "涨跌幅": 0.61,
        "开盘价": 27.98,
        "最高价": 28.13,
        "最低价": 27.94,
        "昨收价": 27.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 43049.0,
        "成交额": 1210189.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.APUE"
    },
    {
        "序号": 3286,
        "name": "IQ Winslow Focused Large Cap Gr",
        "最新价": 34.74,
        "涨跌额": 0.21,
        "涨跌幅": 0.61,
        "开盘价": 34.74,
        "最高价": 34.74,
        "最低价": 34.74,
        "昨收价": 34.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 243.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IWFG"
    },
    {
        "序号": 3287,
        "name": "IQ U.S. Mid Cap R&D Leaders ETF",
        "最新价": 23.19,
        "涨跌额": 0.14,
        "涨跌幅": 0.61,
        "开盘价": 23.19,
        "最高价": 23.19,
        "最低价": 23.19,
        "昨收价": 23.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 46.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MRND"
    },
    {
        "序号": 3288,
        "name": "BlackRock U.S. Equity Factor Ro",
        "最新价": 38.1,
        "涨跌额": 0.23,
        "涨跌幅": 0.61,
        "开盘价": 38.04,
        "最高价": 38.14,
        "最低价": 37.99,
        "昨收价": 37.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 2315.0,
        "成交额": 88088.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.DYNF"
    },
    {
        "序号": 3289,
        "name": "Selective Insurance Group Inc",
        "最新价": 101.09,
        "涨跌额": 0.61,
        "涨跌幅": 0.61,
        "开盘价": 100.25,
        "最高价": 101.49,
        "最低价": 100.25,
        "昨收价": 100.48,
        "总市值": 6124890858.0,
        "市盈率": 18.74,
        "成交量": 272069.0,
        "成交额": 27501897.0,
        "振幅": 1.23,
        "换手率": 0.45,
        "symbol": "105.SIGI"
    },
    {
        "序号": 3290,
        "name": "WR柏克利",
        "最新价": 71.33,
        "涨跌额": 0.43,
        "涨跌幅": 0.61,
        "开盘价": 70.9,
        "最高价": 71.43,
        "最低价": 70.62,
        "昨收价": 70.9,
        "总市值": 18394012185.0,
        "市盈率": 13.46,
        "成交量": 875796.0,
        "成交额": 62346148.0,
        "振幅": 1.14,
        "换手率": 0.34,
        "symbol": "106.WRB"
    },
    {
        "序号": 3291,
        "name": "Arrow Investments Trust Arrow D",
        "最新价": 9.96,
        "涨跌额": 0.06,
        "涨跌幅": 0.61,
        "开盘价": 9.95,
        "最高价": 9.96,
        "最低价": 9.95,
        "昨收价": 9.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 889.0,
        "成交额": 8845.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.DWAT"
    },
    {
        "序号": 3292,
        "name": "iShares Factors US Growth Style",
        "最新价": 39.92,
        "涨跌额": 0.24,
        "涨跌幅": 0.6,
        "开盘价": 39.61,
        "最高价": 39.97,
        "最低价": 39.61,
        "昨收价": 39.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 19159.0,
        "成交额": 762904.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.STLG"
    },
    {
        "序号": 3293,
        "name": "First Interstate BancSystem Inc",
        "最新价": 28.3,
        "涨跌额": 0.17,
        "涨跌幅": 0.6,
        "开盘价": 28.08,
        "最高价": 28.58,
        "最低价": 28.08,
        "昨收价": 28.13,
        "总市值": 2971439608.0,
        "市盈率": 10.54,
        "成交量": 494695.0,
        "成交额": 14028644.0,
        "振幅": 1.78,
        "换手率": 0.47,
        "symbol": "105.FIBK"
    },
    {
        "序号": 3294,
        "name": "Global Net Lease Inc Series D P",
        "最新价": 19.98,
        "涨跌额": 0.12,
        "涨跌幅": 0.6,
        "开盘价": 19.77,
        "最高价": 20.06,
        "最低价": 19.71,
        "昨收价": 19.86,
        "总市值": 158515546.0,
        "市盈率": null,
        "成交量": 22142.0,
        "成交额": 440800.0,
        "振幅": 1.76,
        "换手率": 0.28,
        "symbol": "106.GNL_D"
    },
    {
        "序号": 3295,
        "name": "Schwab Fundamental U.S. Small C",
        "最新价": 51.62,
        "涨跌额": 0.31,
        "涨跌幅": 0.6,
        "开盘价": 51.23,
        "最高价": 51.81,
        "最低价": 51.23,
        "昨收价": 51.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 389503.0,
        "成交额": 20048862.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.FNDA"
    },
    {
        "序号": 3296,
        "name": "Harbor Corporate Culture Leader",
        "最新价": 20.0,
        "涨跌额": 0.12,
        "涨跌幅": 0.6,
        "开盘价": 20.0,
        "最高价": 20.0,
        "最低价": 20.0,
        "昨收价": 19.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 220.0,
        "成交额": 4399.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HAPY"
    },
    {
        "序号": 3297,
        "name": "美国超导",
        "最新价": 10.0,
        "涨跌额": 0.06,
        "涨跌幅": 0.6,
        "开盘价": 9.86,
        "最高价": 10.05,
        "最低价": 9.6,
        "昨收价": 9.94,
        "总市值": 303185110.0,
        "市盈率": -12.46,
        "成交量": 509913.0,
        "成交额": 5032204.0,
        "振幅": 4.53,
        "换手率": 1.68,
        "symbol": "105.AMSC"
    },
    {
        "序号": 3298,
        "name": "Changebridge Long\/Short Equity ",
        "最新价": 20.03,
        "涨跌额": 0.12,
        "涨跌幅": 0.6,
        "开盘价": 20.05,
        "最高价": 20.05,
        "最低价": 20.03,
        "昨收价": 19.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 135.0,
        "成交额": 2706.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.CBLS"
    },
    {
        "序号": 3299,
        "name": "Donoghue Forlines Risk Managed ",
        "最新价": 26.71,
        "涨跌额": 0.16,
        "涨跌幅": 0.6,
        "开盘价": 26.67,
        "最高价": 26.71,
        "最低价": 26.65,
        "昨收价": 26.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1048.0,
        "成交额": 27950.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.DFNV"
    },
    {
        "序号": 3300,
        "name": "维布络",
        "最新价": 5.01,
        "涨跌额": 0.03,
        "涨跌幅": 0.6,
        "开盘价": 5.01,
        "最高价": 5.05,
        "最低价": 5.0,
        "昨收价": 4.98,
        "总市值": 27499098385.0,
        "市盈率": 19.54,
        "成交量": 2027914.0,
        "成交额": 10184774.0,
        "振幅": 1.0,
        "换手率": 0.04,
        "symbol": "106.WIT"
    },
    {
        "序号": 3301,
        "name": "Energy Focus Inc",
        "最新价": 1.67,
        "涨跌额": 0.01,
        "涨跌幅": 0.6,
        "开盘价": 1.68,
        "最高价": 1.68,
        "最低价": 1.67,
        "昨收价": 1.66,
        "总市值": 7262312.0,
        "市盈率": -1.26,
        "成交量": 1442.0,
        "成交额": 2411.0,
        "振幅": 0.6,
        "换手率": 0.03,
        "symbol": "105.EFOI"
    },
    {
        "序号": 3302,
        "name": "Schwab U.S. Large-Cap Growth ET",
        "最新价": 80.25,
        "涨跌额": 0.48,
        "涨跌幅": 0.6,
        "开盘价": 79.48,
        "最高价": 80.28,
        "最低价": 79.41,
        "昨收价": 79.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 874285.0,
        "成交额": 69933435.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.SCHG"
    },
    {
        "序号": 3303,
        "name": "埃创",
        "最新价": 70.23,
        "涨跌额": 0.42,
        "涨跌幅": 0.6,
        "开盘价": 69.48,
        "最高价": 70.75,
        "最低价": 69.48,
        "昨收价": 69.81,
        "总市值": 3194957237.0,
        "市盈率": 42.74,
        "成交量": 219539.0,
        "成交额": 15405301.0,
        "振幅": 1.82,
        "换手率": 0.48,
        "symbol": "105.ITRI"
    },
    {
        "序号": 3304,
        "name": "Schwab Ariel ESG ETF",
        "最新价": 21.76,
        "涨跌额": 0.13,
        "涨跌幅": 0.6,
        "开盘价": 21.72,
        "最高价": 21.76,
        "最低价": 21.69,
        "昨收价": 21.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 1958.0,
        "成交额": 42482.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.SAEF"
    },
    {
        "序号": 3305,
        "name": "爱彼迎",
        "最新价": 140.68,
        "涨跌额": 0.84,
        "涨跌幅": 0.6,
        "开盘价": 138.19,
        "最高价": 141.54,
        "最低价": 137.8,
        "昨收价": 139.84,
        "总市值": 91222266140.0,
        "市盈率": 16.71,
        "成交量": 4411181.0,
        "成交额": 619235584.0,
        "振幅": 2.67,
        "换手率": 0.68,
        "symbol": "105.ABNB"
    },
    {
        "序号": 3306,
        "name": "Matthews Emerging Markets ex Ch",
        "最新价": 26.8,
        "涨跌额": 0.16,
        "涨跌幅": 0.6,
        "开盘价": 26.81,
        "最高价": 26.81,
        "最低价": 26.8,
        "昨收价": 26.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 1492.0,
        "成交额": 40003.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.MEMX"
    },
    {
        "序号": 3307,
        "name": "CVD设备",
        "最新价": 5.03,
        "涨跌额": 0.03,
        "涨跌幅": 0.6,
        "开盘价": 4.99,
        "最高价": 5.13,
        "最低价": 4.66,
        "昨收价": 5.0,
        "总市值": 34327290.0,
        "市盈率": -96.15,
        "成交量": 18905.0,
        "成交额": 94510.0,
        "振幅": 9.4,
        "换手率": 0.28,
        "symbol": "105.CVV"
    },
    {
        "序号": 3308,
        "name": "BlackRock Science and Technolog",
        "最新价": 16.78,
        "涨跌额": 0.1,
        "涨跌幅": 0.6,
        "开盘价": 16.66,
        "最高价": 16.78,
        "最低价": 16.62,
        "昨收价": 16.68,
        "总市值": 1174600000.0,
        "市盈率": null,
        "成交量": 296527.0,
        "成交额": 4953727.0,
        "振幅": 0.96,
        "换手率": 0.42,
        "symbol": "106.BSTZ"
    },
    {
        "序号": 3309,
        "name": "Invesco S&P MidCap 400 GARP ETF",
        "最新价": 92.3,
        "涨跌额": 0.55,
        "涨跌幅": 0.6,
        "开盘价": 92.37,
        "最高价": 92.79,
        "最低价": 92.09,
        "昨收价": 91.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 4317.0,
        "成交额": 398804.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.GRPM"
    },
    {
        "序号": 3310,
        "name": "AltShares Event-Driven ETF",
        "最新价": 10.07,
        "涨跌额": 0.06,
        "涨跌幅": 0.6,
        "开盘价": 10.07,
        "最高价": 10.07,
        "最低价": 10.07,
        "昨收价": 10.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 30.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EVNT"
    },
    {
        "序号": 3311,
        "name": "安高盟",
        "最新价": 1.68,
        "涨跌额": 0.01,
        "涨跌幅": 0.6,
        "开盘价": 1.67,
        "最高价": 1.75,
        "最低价": 1.65,
        "昨收价": 1.67,
        "总市值": 44276135.0,
        "市盈率": 3.86,
        "成交量": 7253.0,
        "成交额": 12159.0,
        "振幅": 5.99,
        "换手率": 0.03,
        "symbol": "105.AGMH"
    },
    {
        "序号": 3312,
        "name": "FlexShares Mornigstar US Market",
        "最新价": 174.84,
        "涨跌额": 1.04,
        "涨跌幅": 0.6,
        "开盘价": 173.56,
        "最高价": 175.06,
        "最低价": 173.56,
        "昨收价": 173.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 25459.0,
        "成交额": 4441154.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.TILT"
    },
    {
        "序号": 3313,
        "name": "WisdomTree U.S. MidCap Fund",
        "最新价": 53.82,
        "涨跌额": 0.32,
        "涨跌幅": 0.6,
        "开盘价": 53.52,
        "最高价": 54.08,
        "最低价": 53.52,
        "昨收价": 53.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 22289.0,
        "成交额": 1201731.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.EZM"
    },
    {
        "序号": 3314,
        "name": "TC Bancshares Inc",
        "最新价": 13.48,
        "涨跌额": 0.08,
        "涨跌幅": 0.6,
        "开盘价": 13.35,
        "最高价": 13.49,
        "最低价": 13.27,
        "昨收价": 13.4,
        "总市值": 61331870.0,
        "市盈率": 405.75,
        "成交量": 7057.0,
        "成交额": 94494.0,
        "振幅": 1.64,
        "换手率": 0.16,
        "symbol": "105.TCBC"
    },
    {
        "序号": 3315,
        "name": "Invesco Russell 1000 Dynamic Mu",
        "最新价": 48.95,
        "涨跌额": 0.29,
        "涨跌幅": 0.6,
        "开盘价": 48.57,
        "最高价": 49.02,
        "最低价": 48.54,
        "昨收价": 48.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 492792.0,
        "成交额": 24075008.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.OMFL"
    },
    {
        "序号": 3316,
        "name": "Expro Group Holdings NV",
        "最新价": 15.21,
        "涨跌额": 0.09,
        "涨跌幅": 0.6,
        "开盘价": 15.28,
        "最高价": 15.77,
        "最低价": 14.89,
        "昨收价": 15.12,
        "总市值": 1683321546.0,
        "市盈率": 846.32,
        "成交量": 1429747.0,
        "成交额": 21808911.0,
        "振幅": 5.82,
        "换手率": 1.29,
        "symbol": "106.XPRO"
    },
    {
        "序号": 3317,
        "name": "JPMorgan U.S. Momentum Factor E",
        "最新价": 43.96,
        "涨跌额": 0.26,
        "涨跌幅": 0.59,
        "开盘价": 43.66,
        "最高价": 43.99,
        "最低价": 43.66,
        "昨收价": 43.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 24275.0,
        "成交额": 1064613.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.JMOM"
    },
    {
        "序号": 3318,
        "name": "Franklin BSP Realty Trust Inc",
        "最新价": 13.53,
        "涨跌额": 0.08,
        "涨跌幅": 0.59,
        "开盘价": 13.41,
        "最高价": 13.63,
        "最低价": 13.38,
        "昨收价": 13.45,
        "总市值": 1110450125.0,
        "市盈率": 7.79,
        "成交量": 179402.0,
        "成交额": 2426532.0,
        "振幅": 1.86,
        "换手率": 0.22,
        "symbol": "106.FBRT"
    },
    {
        "序号": 3319,
        "name": "皮帕克格拉德斯通金融",
        "最新价": 27.07,
        "涨跌额": 0.16,
        "涨跌幅": 0.59,
        "开盘价": 27.01,
        "最高价": 27.51,
        "最低价": 25.86,
        "昨收价": 26.91,
        "总市值": 482347580.0,
        "市盈率": 7.93,
        "成交量": 44869.0,
        "成交额": 1216125.0,
        "振幅": 6.13,
        "换手率": 0.25,
        "symbol": "105.PGC"
    },
    {
        "序号": 3320,
        "name": "约翰威立国际出版-A",
        "最新价": 30.48,
        "涨跌额": 0.18,
        "涨跌幅": 0.59,
        "开盘价": 30.32,
        "最高价": 30.66,
        "最低价": 29.99,
        "昨收价": 30.3,
        "总市值": 1672415563.0,
        "市盈率": -14.56,
        "成交量": 451457.0,
        "成交额": 13758679.0,
        "振幅": 2.21,
        "换手率": 0.98,
        "symbol": "106.WLY"
    },
    {
        "序号": 3321,
        "name": "金融指数ETF-Vanguard",
        "最新价": 88.17,
        "涨跌额": 0.52,
        "涨跌幅": 0.59,
        "开盘价": 87.56,
        "最高价": 88.38,
        "最低价": 87.46,
        "昨收价": 87.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 321320.0,
        "成交额": 28284009.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.VFH"
    },
    {
        "序号": 3322,
        "name": "Fidelity Momentum Factor ETF",
        "最新价": 50.96,
        "涨跌额": 0.3,
        "涨跌幅": 0.59,
        "开盘价": 50.79,
        "最高价": 51.0,
        "最低价": 50.66,
        "昨收价": 50.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 13901.0,
        "成交额": 706813.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.FDMO"
    },
    {
        "序号": 3323,
        "name": "SPDR S&P Kensho Smart Mobility ",
        "最新价": 28.88,
        "涨跌额": 0.17,
        "涨跌幅": 0.59,
        "开盘价": 28.58,
        "最高价": 28.93,
        "最低价": 28.58,
        "昨收价": 28.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 3537.0,
        "成交额": 102080.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.HAIL"
    },
    {
        "序号": 3324,
        "name": "Dynex Capital Inc Series C Pfd",
        "最新价": 23.79,
        "涨跌额": 0.14,
        "涨跌幅": 0.59,
        "开盘价": 23.64,
        "最高价": 23.79,
        "最低价": 23.57,
        "昨收价": 23.65,
        "总市值": 106103400.0,
        "市盈率": null,
        "成交量": 3787.0,
        "成交额": 89574.0,
        "振幅": 0.93,
        "换手率": 0.08,
        "symbol": "106.DX_C"
    },
    {
        "序号": 3325,
        "name": "Invivyd",
        "最新价": 1.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.59,
        "开盘价": 1.66,
        "最高价": 1.75,
        "最低价": 1.65,
        "昨收价": 1.69,
        "总市值": 187195432.0,
        "市盈率": -1.1,
        "成交量": 55188.0,
        "成交额": 94722.0,
        "振幅": 5.92,
        "换手率": 0.05,
        "symbol": "105.IVVD"
    },
    {
        "序号": 3326,
        "name": "Small Cap US Equity Select ETF",
        "最新价": 27.21,
        "涨跌额": 0.16,
        "涨跌幅": 0.59,
        "开盘价": 27.12,
        "最高价": 27.21,
        "最低价": 27.11,
        "昨收价": 27.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2307.0,
        "成交额": 62609.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.RNSC"
    },
    {
        "序号": 3327,
        "name": "Dynamic Short Short-Term Volati",
        "最新价": 27.22,
        "涨跌额": 0.16,
        "涨跌幅": 0.59,
        "开盘价": 27.19,
        "最高价": 27.22,
        "最低价": 27.19,
        "昨收价": 27.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1161.0,
        "成交额": 31579.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.WEIX"
    },
    {
        "序号": 3328,
        "name": "InfraCap MLP ETF",
        "最新价": 35.74,
        "涨跌额": 0.21,
        "涨跌幅": 0.59,
        "开盘价": 35.61,
        "最高价": 35.9,
        "最低价": 35.54,
        "昨收价": 35.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 17484.0,
        "成交额": 624867.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.AMZA"
    },
    {
        "序号": 3329,
        "name": "Amplify Cash Flow Dividend Lead",
        "最新价": 25.53,
        "涨跌额": 0.15,
        "涨跌幅": 0.59,
        "开盘价": 25.58,
        "最高价": 25.58,
        "最低价": 25.53,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 679.0,
        "成交额": 17362.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.COWS"
    },
    {
        "序号": 3330,
        "name": "ALPS Disruptive Technologies ET",
        "最新价": 39.16,
        "涨跌额": 0.23,
        "涨跌幅": 0.59,
        "开盘价": 38.79,
        "最高价": 39.16,
        "最低价": 38.79,
        "昨收价": 38.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 3209.0,
        "成交额": 125013.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.DTEC"
    },
    {
        "序号": 3331,
        "name": "First Trust RiverFront Dynamic ",
        "最新价": 61.36,
        "涨跌额": 0.36,
        "涨跌幅": 0.59,
        "开盘价": 61.36,
        "最高价": 61.36,
        "最低价": 61.36,
        "昨收价": 61.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 122.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RFEU"
    },
    {
        "序号": 3332,
        "name": "iShares U.S. Tech Breakthrough ",
        "最新价": 40.91,
        "涨跌额": 0.24,
        "涨跌幅": 0.59,
        "开盘价": 40.53,
        "最高价": 40.94,
        "最低价": 40.53,
        "昨收价": 40.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 35921.0,
        "成交额": 1467324.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.TECB"
    },
    {
        "序号": 3333,
        "name": "iShares S&P Small-Cap 600 Value",
        "最新价": 95.46,
        "涨跌额": 0.56,
        "涨跌幅": 0.59,
        "开盘价": 94.69,
        "最高价": 95.89,
        "最低价": 94.68,
        "昨收价": 94.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 353227.0,
        "成交额": 33675658.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "107.IJS"
    },
    {
        "序号": 3334,
        "name": "Convergence Long\/Short Equity E",
        "最新价": 17.05,
        "涨跌额": 0.1,
        "涨跌幅": 0.59,
        "开盘价": 16.82,
        "最高价": 17.05,
        "最低价": 16.82,
        "昨收价": 16.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 10004.0,
        "成交额": 169567.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.CLSE"
    },
    {
        "序号": 3335,
        "name": "加图商场",
        "最新价": 6.82,
        "涨跌额": 0.04,
        "涨跌幅": 0.59,
        "开盘价": 6.79,
        "最高价": 6.85,
        "最低价": 6.73,
        "昨收价": 6.78,
        "总市值": 140390818.0,
        "市盈率": -39.95,
        "成交量": 52969.0,
        "成交额": 359298.0,
        "振幅": 1.77,
        "换手率": 0.26,
        "symbol": "106.CATO"
    },
    {
        "序号": 3336,
        "name": "Freedom Day Dividend ETF",
        "最新价": 27.33,
        "涨跌额": 0.16,
        "涨跌幅": 0.59,
        "开盘价": 27.25,
        "最高价": 27.33,
        "最低价": 27.23,
        "昨收价": 27.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 603.0,
        "成交额": 16429.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.MBOX"
    },
    {
        "序号": 3337,
        "name": "AMERISAFE Inc",
        "最新价": 47.86,
        "涨跌额": 0.28,
        "涨跌幅": 0.59,
        "开盘价": 47.38,
        "最高价": 47.87,
        "最低价": 47.09,
        "昨收价": 47.58,
        "总市值": 918038507.0,
        "市盈率": 14.41,
        "成交量": 63967.0,
        "成交额": 3049462.0,
        "振幅": 1.64,
        "换手率": 0.33,
        "symbol": "105.AMSF"
    },
    {
        "序号": 3338,
        "name": "Siebert Financial Corp",
        "最新价": 1.71,
        "涨跌额": 0.01,
        "涨跌幅": 0.59,
        "开盘价": 1.71,
        "最高价": 1.79,
        "最低价": 1.7,
        "昨收价": 1.7,
        "总市值": 67683401.0,
        "市盈率": 11.51,
        "成交量": 24203.0,
        "成交额": 41811.0,
        "振幅": 5.29,
        "换手率": 0.06,
        "symbol": "105.SIEB"
    },
    {
        "序号": 3339,
        "name": "Payoneer Global Inc",
        "最新价": 5.13,
        "涨跌额": 0.03,
        "涨跌幅": 0.59,
        "开盘价": 5.06,
        "最高价": 5.16,
        "最低价": 5.03,
        "昨收价": 5.1,
        "总市值": 1856077446.0,
        "市盈率": 33.05,
        "成交量": 1115251.0,
        "成交额": 5710645.0,
        "振幅": 2.55,
        "换手率": 0.31,
        "symbol": "105.PAYO"
    },
    {
        "序号": 3340,
        "name": "BlackRock Enhanced Internationa",
        "最新价": 5.13,
        "涨跌额": 0.03,
        "涨跌幅": 0.59,
        "开盘价": 5.08,
        "最高价": 5.13,
        "最低价": 5.08,
        "昨收价": 5.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 392288.0,
        "成交额": 2004145.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "106.BGY"
    },
    {
        "序号": 3341,
        "name": "优莎娜",
        "最新价": 49.63,
        "涨跌额": 0.29,
        "涨跌幅": 0.59,
        "开盘价": 49.16,
        "最高价": 49.72,
        "最低价": 49.08,
        "昨收价": 49.34,
        "总市值": 949412272.0,
        "市盈率": 15.87,
        "成交量": 49870.0,
        "成交额": 2472297.0,
        "振幅": 1.3,
        "换手率": 0.26,
        "symbol": "106.USNA"
    },
    {
        "序号": 3342,
        "name": "AB US High Dividend ETF",
        "最新价": 56.48,
        "涨跌额": 0.33,
        "涨跌幅": 0.59,
        "开盘价": 56.48,
        "最高价": 56.48,
        "最低价": 56.48,
        "昨收价": 56.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 50.0,
        "成交额": 2824.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HIDV"
    },
    {
        "序号": 3343,
        "name": "Amplify Lithium & Battery Techn",
        "最新价": 10.27,
        "涨跌额": 0.06,
        "涨跌幅": 0.59,
        "开盘价": 10.2,
        "最高价": 10.35,
        "最低价": 10.19,
        "昨收价": 10.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 25941.0,
        "成交额": 265588.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.BATT"
    },
    {
        "序号": 3344,
        "name": "First Trust NASDAQ Technology D",
        "最新价": 61.63,
        "涨跌额": 0.36,
        "涨跌幅": 0.59,
        "开盘价": 61.21,
        "最高价": 61.73,
        "最低价": 61.15,
        "昨收价": 61.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 187694.0,
        "成交额": 11519356.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "105.TDIV"
    },
    {
        "序号": 3345,
        "name": "Ardmore Shipping Corp",
        "最新价": 13.71,
        "涨跌额": 0.08,
        "涨跌幅": 0.59,
        "开盘价": 13.7,
        "最高价": 13.83,
        "最低价": 13.51,
        "昨收价": 13.63,
        "总市值": 566207220.0,
        "市盈率": 3.94,
        "成交量": 331580.0,
        "成交额": 4534443.0,
        "振幅": 2.35,
        "换手率": 0.8,
        "symbol": "106.ASC"
    },
    {
        "序号": 3346,
        "name": "Voya Global Advantage and Premi",
        "最新价": 8.57,
        "涨跌额": 0.05,
        "涨跌幅": 0.59,
        "开盘价": 8.52,
        "最高价": 8.59,
        "最低价": 8.47,
        "昨收价": 8.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 39373.0,
        "成交额": 336606.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "106.IGA"
    },
    {
        "序号": 3347,
        "name": "PDS Biotechnology Corp",
        "最新价": 5.15,
        "涨跌额": 0.03,
        "涨跌幅": 0.59,
        "开盘价": 5.06,
        "最高价": 5.24,
        "最低价": 5.01,
        "昨收价": 5.12,
        "总市值": 160204979.0,
        "市盈率": -3.13,
        "成交量": 362664.0,
        "成交额": 1858394.0,
        "振幅": 4.49,
        "换手率": 1.17,
        "symbol": "105.PDSB"
    },
    {
        "序号": 3348,
        "name": "Merrill Lynch & Co Inc Trust Ce",
        "最新价": 25.75,
        "涨跌额": 0.15,
        "涨跌幅": 0.59,
        "开盘价": 25.5,
        "最高价": 26.13,
        "最低价": 25.45,
        "昨收价": 25.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 14261.0,
        "成交额": 367625.0,
        "振幅": 2.66,
        "换手率": null,
        "symbol": "107.IPB"
    },
    {
        "序号": 3349,
        "name": "Cambria Shareholder Yield ETF",
        "最新价": 63.52,
        "涨跌额": 0.37,
        "涨跌幅": 0.59,
        "开盘价": 63.16,
        "最高价": 63.75,
        "最低价": 63.16,
        "昨收价": 63.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 61504.0,
        "成交额": 3905114.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.SYLD"
    },
    {
        "序号": 3350,
        "name": "iShares U.S. Small-Cap Equity F",
        "最新价": 54.94,
        "涨跌额": 0.32,
        "涨跌幅": 0.59,
        "开盘价": 54.4,
        "最高价": 55.11,
        "最低价": 54.4,
        "昨收价": 54.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 26953.0,
        "成交额": 1477540.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "107.SMLF"
    },
    {
        "序号": 3351,
        "name": "Digital World Acquisition Corp-",
        "最新价": 17.17,
        "涨跌额": 0.1,
        "涨跌幅": 0.59,
        "开盘价": 17.01,
        "最高价": 17.24,
        "最低价": 16.95,
        "昨收价": 17.07,
        "总市值": 638386283.0,
        "市盈率": -25.4,
        "成交量": 110069.0,
        "成交额": 1879082.0,
        "振幅": 1.7,
        "换手率": 0.3,
        "symbol": "105.DWAC"
    },
    {
        "序号": 3352,
        "name": "MPLX LP",
        "最新价": 36.08,
        "涨跌额": 0.21,
        "涨跌幅": 0.59,
        "开盘价": 35.9,
        "最高价": 36.16,
        "最低价": 35.76,
        "昨收价": 35.87,
        "总市值": 36123910948.0,
        "市盈率": 10.01,
        "成交量": 2213299.0,
        "成交额": 79787193.0,
        "振幅": 1.12,
        "换手率": 0.22,
        "symbol": "106.MPLX"
    },
    {
        "序号": 3353,
        "name": "欧洲金融ETF-iShares MSCI",
        "最新价": 20.62,
        "涨跌额": 0.12,
        "涨跌幅": 0.59,
        "开盘价": 20.46,
        "最高价": 20.64,
        "最低价": 20.46,
        "昨收价": 20.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 189904.0,
        "成交额": 3909824.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.EUFN"
    },
    {
        "序号": 3354,
        "name": "Revolve Group Inc-A",
        "最新价": 15.48,
        "涨跌额": 0.09,
        "涨跌幅": 0.58,
        "开盘价": 15.57,
        "最高价": 16.1,
        "最低价": 15.29,
        "昨收价": 15.39,
        "总市值": 1116941350.0,
        "市盈率": 34.35,
        "成交量": 1794800.0,
        "成交额": 28195869.0,
        "振幅": 5.26,
        "换手率": 2.49,
        "symbol": "106.RVLV"
    },
    {
        "序号": 3355,
        "name": "Nurix Therapeutics Inc",
        "最新价": 8.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.58,
        "开盘价": 8.48,
        "最高价": 8.93,
        "最低价": 8.25,
        "昨收价": 8.55,
        "总市值": 417132405.0,
        "市盈率": -2.81,
        "成交量": 962066.0,
        "成交额": 8294028.0,
        "振幅": 7.95,
        "换手率": 1.98,
        "symbol": "105.NRIX"
    },
    {
        "序号": 3356,
        "name": "虎牙",
        "最新价": 3.44,
        "涨跌额": 0.02,
        "涨跌幅": 0.58,
        "开盘价": 3.39,
        "最高价": 3.51,
        "最低价": 3.39,
        "昨收价": 3.42,
        "总市值": 818485764.0,
        "市盈率": -12.78,
        "成交量": 834295.0,
        "成交额": 2889280.0,
        "振幅": 3.51,
        "换手率": 0.35,
        "symbol": "106.HUYA"
    },
    {
        "序号": 3357,
        "name": "DiamondRock Hospitality Co",
        "最新价": 8.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.58,
        "开盘价": 8.39,
        "最高价": 8.63,
        "最低价": 8.38,
        "昨收价": 8.55,
        "总市值": 1802793894.0,
        "市盈率": 19.24,
        "成交量": 2483886.0,
        "成交额": 21283192.0,
        "振幅": 2.92,
        "换手率": 1.18,
        "symbol": "106.DRH"
    },
    {
        "序号": 3358,
        "name": "Despegar.com Corp",
        "最新价": 8.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.58,
        "开盘价": 8.54,
        "最高价": 8.76,
        "最低价": 8.36,
        "昨收价": 8.55,
        "总市值": 568085427.0,
        "市盈率": 198.08,
        "成交量": 322165.0,
        "成交额": 2763295.0,
        "振幅": 4.68,
        "换手率": 0.49,
        "symbol": "106.DESP"
    },
    {
        "序号": 3359,
        "name": "Enact Holdings Inc",
        "最新价": 27.54,
        "涨跌额": 0.16,
        "涨跌幅": 0.58,
        "开盘价": 27.33,
        "最高价": 27.63,
        "最低价": 27.12,
        "昨收价": 27.38,
        "总市值": 4398231003.0,
        "市盈率": 6.75,
        "成交量": 197545.0,
        "成交额": 5435709.0,
        "振幅": 1.86,
        "换手率": 0.12,
        "symbol": "105.ACT"
    },
    {
        "序号": 3360,
        "name": "Liberty Media Corp Liberty Live",
        "最新价": 32.72,
        "涨跌额": 0.19,
        "涨跌幅": 0.58,
        "开盘价": 32.48,
        "最高价": 33.14,
        "最低价": 32.27,
        "昨收价": 32.53,
        "总市值": 23380128810.0,
        "市盈率": 19.24,
        "成交量": 311966.0,
        "成交额": 10200247.0,
        "振幅": 2.67,
        "换手率": 1.11,
        "symbol": "105.LLYVA"
    },
    {
        "序号": 3361,
        "name": "欧林",
        "最新价": 49.98,
        "涨跌额": 0.29,
        "涨跌幅": 0.58,
        "开盘价": 49.9,
        "最高价": 50.71,
        "最低价": 49.44,
        "昨收价": 49.69,
        "总市值": 6125028858.0,
        "市盈率": 10.14,
        "成交量": 1719866.0,
        "成交额": 85938433.0,
        "振幅": 2.56,
        "换手率": 1.4,
        "symbol": "106.OLN"
    },
    {
        "序号": 3362,
        "name": "The Meet Kevin Pricing Power ET",
        "最新价": 24.14,
        "涨跌额": 0.14,
        "涨跌幅": 0.58,
        "开盘价": 23.98,
        "最高价": 24.21,
        "最低价": 23.91,
        "昨收价": 24.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 6752.0,
        "成交额": 162428.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.PP"
    },
    {
        "序号": 3363,
        "name": "iShares Edge MSCI Multifactor U",
        "最新价": 46.56,
        "涨跌额": 0.27,
        "涨跌幅": 0.58,
        "开盘价": 46.16,
        "最高价": 46.6,
        "最低价": 46.16,
        "昨收价": 46.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 95998.0,
        "成交额": 4457690.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.LRGF"
    },
    {
        "序号": 3364,
        "name": "John Hancock Multifactor Small ",
        "最新价": 34.49,
        "涨跌额": 0.2,
        "涨跌幅": 0.58,
        "开盘价": 34.2,
        "最高价": 34.63,
        "最低价": 34.2,
        "昨收价": 34.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 14499.0,
        "成交额": 499776.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.JHSC"
    },
    {
        "序号": 3365,
        "name": "WBI Power Factor High Dividend ",
        "最新价": 27.6,
        "涨跌额": 0.16,
        "涨跌幅": 0.58,
        "开盘价": 27.38,
        "最高价": 27.62,
        "最低价": 27.38,
        "昨收价": 27.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 10038.0,
        "成交额": 276026.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.WBIY"
    },
    {
        "序号": 3366,
        "name": "Invesco Optimum Yield Diversifi",
        "最新价": 13.8,
        "涨跌额": 0.08,
        "涨跌幅": 0.58,
        "开盘价": 13.79,
        "最高价": 13.86,
        "最低价": 13.73,
        "昨收价": 13.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 3670221.0,
        "成交额": 50621061.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "105.PDBC"
    },
    {
        "序号": 3367,
        "name": "iShares ESG Advanced MSCI USA E",
        "最新价": 37.97,
        "涨跌额": 0.22,
        "涨跌幅": 0.58,
        "开盘价": 37.74,
        "最高价": 38.02,
        "最低价": 37.74,
        "昨收价": 37.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 33694.0,
        "成交额": 1277114.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "105.USXF"
    },
    {
        "序号": 3368,
        "name": "韩国ETF-iShares MSCI",
        "最新价": 62.16,
        "涨跌额": 0.36,
        "涨跌幅": 0.58,
        "开盘价": 62.12,
        "最高价": 62.52,
        "最低价": 61.74,
        "昨收价": 61.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 2245485.0,
        "成交额": 139499900.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.EWY"
    },
    {
        "序号": 3369,
        "name": "ReNew Energy Global plc-A",
        "最新价": 6.91,
        "涨跌额": 0.04,
        "涨跌幅": 0.58,
        "开盘价": 6.91,
        "最高价": 6.93,
        "最低价": 6.74,
        "昨收价": 6.87,
        "总市值": 2577690272.0,
        "市盈率": 70.23,
        "成交量": 332638.0,
        "成交额": 2276464.0,
        "振幅": 2.77,
        "换手率": 0.09,
        "symbol": "105.RNW"
    },
    {
        "序号": 3370,
        "name": "PAR Technology Corp",
        "最新价": 39.75,
        "涨跌额": 0.23,
        "涨跌幅": 0.58,
        "开盘价": 39.46,
        "最高价": 40.26,
        "最低价": 39.46,
        "昨收价": 39.52,
        "总市值": 1113852797.0,
        "市盈率": -17.24,
        "成交量": 207893.0,
        "成交额": 8271811.0,
        "振幅": 2.02,
        "换手率": 0.74,
        "symbol": "106.PAR"
    },
    {
        "序号": 3371,
        "name": "数字房地产信托",
        "最新价": 134.81,
        "涨跌额": 0.78,
        "涨跌幅": 0.58,
        "开盘价": 136.32,
        "最高价": 137.25,
        "最低价": 132.91,
        "昨收价": 134.03,
        "总市值": 40827490253.0,
        "市盈率": 44.17,
        "成交量": 2857360.0,
        "成交额": 384513680.0,
        "振幅": 3.24,
        "换手率": 0.94,
        "symbol": "106.DLR"
    },
    {
        "序号": 3372,
        "name": "Pacer US Small Cap Cash Cows 10",
        "最新价": 45.01,
        "涨跌额": 0.26,
        "涨跌幅": 0.58,
        "开盘价": 44.78,
        "最高价": 45.18,
        "最低价": 44.67,
        "昨收价": 44.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1895356.0,
        "成交额": 85105453.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "107.CALF"
    },
    {
        "序号": 3373,
        "name": "iShares Morningstar Growth ETF",
        "最新价": 65.81,
        "涨跌额": 0.38,
        "涨跌幅": 0.58,
        "开盘价": 65.2,
        "最高价": 65.87,
        "最低价": 65.2,
        "昨收价": 65.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 68040.0,
        "成交额": 4469891.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.ILCG"
    },
    {
        "序号": 3374,
        "name": "联合银行",
        "最新价": 27.72,
        "涨跌额": 0.16,
        "涨跌幅": 0.58,
        "开盘价": 27.53,
        "最高价": 28.01,
        "最低价": 27.52,
        "昨收价": 27.56,
        "总市值": 278799777.0,
        "市盈率": 6.99,
        "成交量": 7381.0,
        "成交额": 204642.0,
        "振幅": 1.78,
        "换手率": 0.07,
        "symbol": "105.UNTY"
    },
    {
        "序号": 3375,
        "name": "Innovator U.S. Small Cap Power ",
        "最新价": 25.99,
        "涨跌额": 0.15,
        "涨跌幅": 0.58,
        "开盘价": 25.87,
        "最高价": 26.01,
        "最低价": 25.84,
        "昨收价": 25.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 156609.0,
        "成交额": 4069540.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.KJUL"
    },
    {
        "序号": 3376,
        "name": "Innovator U.S. Small Cap Power ",
        "最新价": 27.74,
        "涨跌额": 0.16,
        "涨跌幅": 0.58,
        "开盘价": 27.7,
        "最高价": 27.75,
        "最低价": 27.57,
        "昨收价": 27.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 11793.0,
        "成交额": 325979.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.KAPR"
    },
    {
        "序号": 3377,
        "name": "John Hancock U.S. High Dividend",
        "最新价": 29.51,
        "涨跌额": 0.17,
        "涨跌幅": 0.58,
        "开盘价": 29.51,
        "最高价": 29.51,
        "最低价": 29.51,
        "昨收价": 29.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 35.0,
        "成交额": 1032.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JHDV"
    },
    {
        "序号": 3378,
        "name": "First Trust Expanded Technology",
        "最新价": 22.59,
        "涨跌额": 0.13,
        "涨跌幅": 0.58,
        "开盘价": 22.44,
        "最高价": 22.61,
        "最低价": 22.44,
        "昨收价": 22.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 2278.0,
        "成交额": 51270.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.XPND"
    },
    {
        "序号": 3379,
        "name": "Landcadia Holdings IV Inc-A",
        "最新价": 10.43,
        "涨跌额": 0.06,
        "涨跌幅": 0.58,
        "开盘价": 10.39,
        "最高价": 10.43,
        "最低价": 10.39,
        "昨收价": 10.37,
        "总市值": 141415395.0,
        "市盈率": 54.3,
        "成交量": 720.0,
        "成交额": 7485.0,
        "振幅": 0.39,
        "换手率": 0.01,
        "symbol": "105.LCA"
    },
    {
        "序号": 3380,
        "name": "Texas Community Bancshares Inc",
        "最新价": 12.17,
        "涨跌额": 0.07,
        "涨跌幅": 0.58,
        "开盘价": 12.38,
        "最高价": 12.5,
        "最低价": 12.15,
        "昨收价": 12.1,
        "总市值": 39052082.0,
        "市盈率": 5578.87,
        "成交量": 10507.0,
        "成交额": 128565.0,
        "振幅": 2.89,
        "换手率": 0.33,
        "symbol": "105.TCBS"
    },
    {
        "序号": 3381,
        "name": "Hartford US Value ETF",
        "最新价": 40.0,
        "涨跌额": 0.23,
        "涨跌幅": 0.58,
        "开盘价": 39.91,
        "最高价": 40.0,
        "最低价": 39.91,
        "昨收价": 39.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 103.0,
        "成交额": 4110.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.VMAX"
    },
    {
        "序号": 3382,
        "name": "OneMain Holdings Inc",
        "最新价": 45.23,
        "涨跌额": 0.26,
        "涨跌幅": 0.58,
        "开盘价": 44.73,
        "最高价": 45.3,
        "最低价": 44.65,
        "昨收价": 44.97,
        "总市值": 5423263438.0,
        "市盈率": 8.24,
        "成交量": 1082422.0,
        "成交额": 48897949.0,
        "振幅": 1.45,
        "换手率": 0.9,
        "symbol": "106.OMF"
    },
    {
        "序号": 3383,
        "name": "Compass Therapeutics Inc",
        "最新价": 1.74,
        "涨跌额": 0.01,
        "涨跌幅": 0.58,
        "开盘价": 1.72,
        "最高价": 1.76,
        "最低价": 1.71,
        "昨收价": 1.73,
        "总市值": 221826207.0,
        "市盈率": -5.45,
        "成交量": 226861.0,
        "成交额": 394772.0,
        "振幅": 2.89,
        "换手率": 0.18,
        "symbol": "105.CMPX"
    },
    {
        "序号": 3384,
        "name": "Virtus Convertible & Income 202",
        "最新价": 8.7,
        "涨跌额": 0.05,
        "涨跌幅": 0.58,
        "开盘价": 8.7,
        "最高价": 8.73,
        "最低价": 8.65,
        "昨收价": 8.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 55505.0,
        "成交额": 482886.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "106.CBH"
    },
    {
        "序号": 3385,
        "name": "Wingstop Inc",
        "最新价": 247.18,
        "涨跌额": 1.42,
        "涨跌幅": 0.58,
        "开盘价": 244.89,
        "最高价": 248.39,
        "最低价": 244.89,
        "昨收价": 245.76,
        "总市值": 7270779926.0,
        "市盈率": 105.44,
        "成交量": 288187.0,
        "成交额": 71172834.0,
        "振幅": 1.42,
        "换手率": 0.98,
        "symbol": "105.WING"
    },
    {
        "序号": 3386,
        "name": "First Horizon Corp Series C Pfd",
        "最新价": 22.63,
        "涨跌额": 0.13,
        "涨跌幅": 0.58,
        "开盘价": 22.6,
        "最高价": 22.84,
        "最低价": 22.42,
        "昨收价": 22.5,
        "总市值": 130123.0,
        "市盈率": null,
        "成交量": 6037.0,
        "成交额": 136551.0,
        "振幅": 1.87,
        "换手率": 104.99,
        "symbol": "106.FHN_C"
    },
    {
        "序号": 3387,
        "name": "Franklin Exponential Data ETF",
        "最新价": 20.89,
        "涨跌额": 0.12,
        "涨跌幅": 0.58,
        "开盘价": 20.88,
        "最高价": 20.92,
        "最低价": 20.88,
        "昨收价": 20.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 2088.0,
        "成交额": 43632.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.XDAT"
    },
    {
        "序号": 3388,
        "name": "Dimensional U.S. Small Cap ETF",
        "最新价": 55.78,
        "涨跌额": 0.32,
        "涨跌幅": 0.58,
        "开盘价": 55.39,
        "最高价": 56.01,
        "最低价": 55.31,
        "昨收价": 55.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 272513.0,
        "成交额": 15181405.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.DFAS"
    },
    {
        "序号": 3389,
        "name": "R1 RCM Inc",
        "最新价": 10.46,
        "涨跌额": 0.06,
        "涨跌幅": 0.58,
        "开盘价": 10.35,
        "最高价": 10.61,
        "最低价": 10.07,
        "昨收价": 10.4,
        "总市值": 4385963312.0,
        "市盈率": -151.24,
        "成交量": 4424087.0,
        "成交额": 46153995.0,
        "振幅": 5.19,
        "换手率": 1.06,
        "symbol": "105.RCM"
    },
    {
        "序号": 3390,
        "name": "El Pollo Loco Holdings Inc",
        "最新价": 8.72,
        "涨跌额": 0.05,
        "涨跌幅": 0.58,
        "开盘价": 8.64,
        "最高价": 8.77,
        "最低价": 8.61,
        "昨收价": 8.67,
        "总市值": 287352497.0,
        "市盈率": 10.36,
        "成交量": 183835.0,
        "成交额": 1602404.0,
        "振幅": 1.85,
        "换手率": 0.56,
        "symbol": "105.LOCO"
    },
    {
        "序号": 3391,
        "name": "Unusual Whales Subversive Democ",
        "最新价": 29.66,
        "涨跌额": 0.17,
        "涨跌幅": 0.58,
        "开盘价": 29.46,
        "最高价": 29.66,
        "最低价": 29.46,
        "昨收价": 29.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 2234.0,
        "成交额": 66010.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.NANC"
    },
    {
        "序号": 3392,
        "name": "LeaderShares AlphaFactor US Cor",
        "最新价": 33.18,
        "涨跌额": 0.19,
        "涨跌幅": 0.58,
        "开盘价": 33.03,
        "最高价": 33.18,
        "最低价": 33.02,
        "昨收价": 32.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 38469.0,
        "成交额": 1272116.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.LSAF"
    },
    {
        "序号": 3393,
        "name": "First Trust Small Cap Core Alph",
        "最新价": 85.59,
        "涨跌额": 0.49,
        "涨跌幅": 0.58,
        "开盘价": 85.29,
        "最高价": 86.05,
        "最低价": 84.98,
        "昨收价": 85.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 28275.0,
        "成交额": 2417836.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "105.FYX"
    },
    {
        "序号": 3394,
        "name": "奎克化学",
        "最新价": 193.91,
        "涨跌额": 1.11,
        "涨跌幅": 0.58,
        "开盘价": 192.0,
        "最高价": 194.69,
        "最低价": 190.76,
        "昨收价": 192.8,
        "总市值": 3487455062.0,
        "市盈率": 210.18,
        "成交量": 65159.0,
        "成交额": 12600624.0,
        "振幅": 2.04,
        "换手率": 0.36,
        "symbol": "106.KWR"
    },
    {
        "序号": 3395,
        "name": "Pacer US Large Cap Cash Cows Gr",
        "最新价": 22.74,
        "涨跌额": 0.13,
        "涨跌幅": 0.57,
        "开盘价": 22.6,
        "最高价": 22.74,
        "最低价": 22.54,
        "昨收价": 22.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 16418.0,
        "成交额": 372778.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.COWG"
    },
    {
        "序号": 3396,
        "name": "Invesco PHLX Semiconductor ETF",
        "最新价": 29.74,
        "涨跌额": 0.17,
        "涨跌幅": 0.57,
        "开盘价": 29.54,
        "最高价": 29.87,
        "最低价": 29.52,
        "昨收价": 29.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 25859.0,
        "成交额": 768571.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "105.SOXQ"
    },
    {
        "序号": 3397,
        "name": "Global X Information Technology",
        "最新价": 31.5,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.5,
        "最高价": 31.5,
        "最低价": 31.5,
        "昨收价": 31.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 130.0,
        "成交额": 4095.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TYLG"
    },
    {
        "序号": 3398,
        "name": "WisdomTree Dynamic Currency Hed",
        "最新价": 31.52,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.41,
        "最高价": 31.55,
        "最低价": 31.41,
        "昨收价": 31.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 19584.0,
        "成交额": 616467.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.DDWM"
    },
    {
        "序号": 3399,
        "name": "Pacer Trendpilot 100 ETF",
        "最新价": 64.8,
        "涨跌额": 0.37,
        "涨跌幅": 0.57,
        "开盘价": 64.31,
        "最高价": 64.8,
        "最低价": 64.31,
        "昨收价": 64.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 53633.0,
        "成交额": 3465565.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "105.PTNQ"
    },
    {
        "序号": 3400,
        "name": "Simplify US Equity PLUS Upside ",
        "最新价": 31.55,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.55,
        "最高价": 31.55,
        "最低价": 31.54,
        "昨收价": 31.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 247.0,
        "成交额": 7791.0,
        "振幅": 0.03,
        "换手率": null,
        "symbol": "107.SPUC"
    },
    {
        "序号": 3401,
        "name": "Franklin FTSE Switzerland ETF",
        "最新价": 31.55,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.5,
        "最高价": 31.6,
        "最低价": 31.46,
        "昨收价": 31.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 7099.0,
        "成交额": 223709.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.FLSW"
    },
    {
        "序号": 3402,
        "name": "Compass Group Diversified Holdi",
        "最新价": 24.54,
        "涨跌额": 0.14,
        "涨跌幅": 0.57,
        "开盘价": 24.5,
        "最高价": 24.83,
        "最低价": 24.46,
        "昨收价": 24.4,
        "总市值": 112884000.0,
        "市盈率": null,
        "成交量": 6973.0,
        "成交额": 171548.0,
        "振幅": 1.52,
        "换手率": 0.15,
        "symbol": "106.CODI_C"
    },
    {
        "序号": 3403,
        "name": "Invesco S&P 500 Top 50 ETF",
        "最新价": 36.84,
        "涨跌额": 0.21,
        "涨跌幅": 0.57,
        "开盘价": 36.55,
        "最高价": 36.86,
        "最低价": 36.52,
        "昨收价": 36.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 787271.0,
        "成交额": 28916593.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.XLG"
    },
    {
        "序号": 3404,
        "name": "The Middleby Corp",
        "最新价": 135.21,
        "涨跌额": 0.77,
        "涨跌幅": 0.57,
        "开盘价": 134.26,
        "最高价": 137.76,
        "最低价": 133.75,
        "昨收价": 134.44,
        "总市值": 7247687996.0,
        "市盈率": 15.83,
        "成交量": 533125.0,
        "成交额": 72262028.0,
        "振幅": 2.98,
        "换手率": 0.99,
        "symbol": "105.MIDD"
    },
    {
        "序号": 3405,
        "name": "1st Source Corp",
        "最新价": 50.94,
        "涨跌额": 0.29,
        "涨跌幅": 0.57,
        "开盘价": 50.51,
        "最高价": 51.28,
        "最低价": 50.51,
        "昨收价": 50.65,
        "总市值": 1244417488.0,
        "市盈率": 9.76,
        "成交量": 50647.0,
        "成交额": 2579693.0,
        "振幅": 1.52,
        "换手率": 0.21,
        "symbol": "105.SRCE"
    },
    {
        "序号": 3406,
        "name": "Markel Group Inc",
        "最新价": 1374.0,
        "涨跌额": 7.82,
        "涨跌幅": 0.57,
        "开盘价": 1368.13,
        "最高价": 1374.06,
        "最低价": 1353.5,
        "昨收价": 1366.18,
        "总市值": 18162265716.0,
        "市盈率": 9.46,
        "成交量": 56343.0,
        "成交额": 77006402.0,
        "振幅": 1.5,
        "换手率": 0.43,
        "symbol": "106.MKL"
    },
    {
        "序号": 3407,
        "name": "JPMorgan U.S. Value Factor ETF",
        "最新价": 36.92,
        "涨跌额": 0.21,
        "涨跌幅": 0.57,
        "开盘价": 36.73,
        "最高价": 36.97,
        "最低价": 36.71,
        "昨收价": 36.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 36077.0,
        "成交额": 1330007.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.JVAL"
    },
    {
        "序号": 3408,
        "name": "Chewy Inc-A",
        "最新价": 19.34,
        "涨跌额": 0.11,
        "涨跌幅": 0.57,
        "开盘价": 19.58,
        "最高价": 19.6,
        "最低价": 18.51,
        "昨收价": 19.23,
        "总市值": 8343390957.0,
        "市盈率": 730.53,
        "成交量": 14383072.0,
        "成交额": 273439024.0,
        "振幅": 5.67,
        "换手率": 3.33,
        "symbol": "106.CHWY"
    },
    {
        "序号": 3409,
        "name": "Global X Future Analytics Tech ",
        "最新价": 29.89,
        "涨跌额": 0.17,
        "涨跌幅": 0.57,
        "开盘价": 29.62,
        "最高价": 29.92,
        "最低价": 29.59,
        "昨收价": 29.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 283502.0,
        "成交额": 8456245.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "105.AIQ"
    },
    {
        "序号": 3410,
        "name": "Portage Fintech Acquisition Cor",
        "最新价": 10.55,
        "涨跌额": 0.06,
        "涨跌幅": 0.57,
        "开盘价": 10.45,
        "最高价": 10.55,
        "最低价": 10.44,
        "昨收价": 10.49,
        "总市值": 109595668.0,
        "市盈率": 11.52,
        "成交量": 7035.0,
        "成交额": 73756.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "105.PFTAU"
    },
    {
        "序号": 3411,
        "name": "Invesco S&P MidCap Quality ETF",
        "最新价": 84.42,
        "涨跌额": 0.48,
        "涨跌幅": 0.57,
        "开盘价": 83.95,
        "最高价": 84.87,
        "最低价": 83.95,
        "昨收价": 83.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 579104.0,
        "成交额": 48792698.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.XMHQ"
    },
    {
        "序号": 3412,
        "name": "Global X S&P 500 Catholic Value",
        "最新价": 56.28,
        "涨跌额": 0.32,
        "涨跌幅": 0.57,
        "开盘价": 55.98,
        "最高价": 56.31,
        "最低价": 55.98,
        "昨收价": 55.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 16132.0,
        "成交额": 905805.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "105.CATH"
    },
    {
        "序号": 3413,
        "name": "Brandes U.S. Value ETF",
        "最新价": 26.39,
        "涨跌额": 0.15,
        "涨跌幅": 0.57,
        "开盘价": 26.27,
        "最高价": 26.44,
        "最低价": 26.27,
        "昨收价": 26.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 25820.0,
        "成交额": 679084.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.BUSA"
    },
    {
        "序号": 3414,
        "name": "Wag! Group Co",
        "最新价": 1.76,
        "涨跌额": 0.01,
        "涨跌幅": 0.57,
        "开盘价": 1.72,
        "最高价": 1.76,
        "最低价": 1.72,
        "昨收价": 1.75,
        "总市值": 69071661.0,
        "市盈率": -17.06,
        "成交量": 19391.0,
        "成交额": 33676.0,
        "振幅": 2.29,
        "换手率": 0.05,
        "symbol": "105.PET"
    },
    {
        "序号": 3415,
        "name": "Innovator U.S. Small Cap Power ",
        "最新价": 31.71,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.48,
        "最高价": 31.91,
        "最低价": 31.47,
        "昨收价": 31.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 12886.0,
        "成交额": 407639.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "107.KJAN"
    },
    {
        "序号": 3416,
        "name": "Advent Convertible and Income F",
        "最新价": 10.57,
        "涨跌额": 0.06,
        "涨跌幅": 0.57,
        "开盘价": 10.46,
        "最高价": 10.6,
        "最低价": 10.46,
        "昨收价": 10.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 97711.0,
        "成交额": 1028312.0,
        "振幅": 1.33,
        "换手率": null,
        "symbol": "106.AVK"
    },
    {
        "序号": 3417,
        "name": "Avantis U.S. Mid Cap Value ETF",
        "最新价": 52.93,
        "涨跌额": 0.3,
        "涨跌幅": 0.57,
        "开盘价": 52.96,
        "最高价": 52.99,
        "最低价": 52.93,
        "昨收价": 52.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 1196.0,
        "成交额": 63358.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.AVMV"
    },
    {
        "序号": 3418,
        "name": "康塞科",
        "最新价": 26.49,
        "涨跌额": 0.15,
        "涨跌幅": 0.57,
        "开盘价": 26.35,
        "最高价": 26.56,
        "最低价": 26.28,
        "昨收价": 26.34,
        "总市值": 2971400413.0,
        "市盈率": 66.92,
        "成交量": 582665.0,
        "成交额": 15408131.0,
        "振幅": 1.06,
        "换手率": 0.52,
        "symbol": "106.CNO"
    },
    {
        "序号": 3419,
        "name": "Montrose Environmental Group In",
        "最新价": 31.79,
        "涨跌额": 0.18,
        "涨跌幅": 0.57,
        "开盘价": 31.41,
        "最高价": 32.26,
        "最低价": 31.41,
        "昨收价": 31.61,
        "总市值": 959683259.0,
        "市盈率": -23.85,
        "成交量": 98174.0,
        "成交额": 3123359.0,
        "振幅": 2.69,
        "换手率": 0.33,
        "symbol": "106.MEG"
    },
    {
        "序号": 3420,
        "name": "Defiance Nasdaq 100 Enhanced Op",
        "最新价": 17.67,
        "涨跌额": 0.1,
        "涨跌幅": 0.57,
        "开盘价": 17.51,
        "最高价": 17.67,
        "最低价": 17.5,
        "昨收价": 17.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 553283.0,
        "成交额": 9734607.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "105.QQQY"
    },
    {
        "序号": 3421,
        "name": "Orrstown Financial Services Inc",
        "最新价": 24.74,
        "涨跌额": 0.14,
        "涨跌幅": 0.57,
        "开盘价": 24.47,
        "最高价": 24.95,
        "最低价": 24.47,
        "昨收价": 24.6,
        "总市值": 262592117.0,
        "市盈率": 6.98,
        "成交量": 24697.0,
        "成交额": 611801.0,
        "振幅": 1.95,
        "换手率": 0.23,
        "symbol": "105.ORRF"
    },
    {
        "序号": 3422,
        "name": "Direxion Daily TSLA Bull 1.5X S",
        "最新价": 14.14,
        "涨跌额": 0.08,
        "涨跌幅": 0.57,
        "开盘价": 13.84,
        "最高价": 14.29,
        "最低价": 13.76,
        "昨收价": 14.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 15373815.0,
        "成交额": 216524344.0,
        "振幅": 3.77,
        "换手率": null,
        "symbol": "105.TSLL"
    },
    {
        "序号": 3423,
        "name": "GATX Corp",
        "最新价": 113.13,
        "涨跌额": 0.64,
        "涨跌幅": 0.57,
        "开盘价": 112.46,
        "最高价": 113.97,
        "最低价": 112.46,
        "昨收价": 112.49,
        "总市值": 4013825023.0,
        "市盈率": 16.61,
        "成交量": 75170.0,
        "成交额": 8509836.0,
        "振幅": 1.34,
        "换手率": 0.21,
        "symbol": "106.GATX"
    },
    {
        "序号": 3424,
        "name": "Dimensional US Marketwide Value",
        "最新价": 35.37,
        "涨跌额": 0.2,
        "涨跌幅": 0.57,
        "开盘价": 35.22,
        "最高价": 35.48,
        "最低价": 35.18,
        "昨收价": 35.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 260747.0,
        "成交额": 9220624.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.DFUV"
    },
    {
        "序号": 3425,
        "name": "Fidelity Value Factor ETF",
        "最新价": 51.29,
        "涨跌额": 0.29,
        "涨跌幅": 0.57,
        "开盘价": 50.94,
        "最高价": 51.34,
        "最低价": 50.92,
        "昨收价": 51.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 23000.0,
        "成交额": 1176650.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.FVAL"
    },
    {
        "序号": 3426,
        "name": "AAM S&P 500 High Dividend Value",
        "最新价": 28.34,
        "涨跌额": 0.16,
        "涨跌幅": 0.57,
        "开盘价": 28.23,
        "最高价": 28.34,
        "最低价": 28.18,
        "昨收价": 28.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 6098.0,
        "成交额": 172357.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.SPDV"
    },
    {
        "序号": 3427,
        "name": "First Trust Indxx Aerospace & D",
        "最新价": 24.8,
        "涨跌额": 0.14,
        "涨跌幅": 0.57,
        "开盘价": 24.72,
        "最高价": 24.83,
        "最低价": 24.72,
        "昨收价": 24.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 3717.0,
        "成交额": 92060.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.MISL"
    },
    {
        "序号": 3428,
        "name": "Defiance Quantum ETF",
        "最新价": 51.38,
        "涨跌额": 0.29,
        "涨跌幅": 0.57,
        "开盘价": 51.04,
        "最高价": 51.52,
        "最低价": 51.04,
        "昨收价": 51.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 23969.0,
        "成交额": 1228549.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.QTUM"
    },
    {
        "序号": 3429,
        "name": "T. Rowe Price Blue Chip Growth ",
        "最新价": 30.13,
        "涨跌额": 0.17,
        "涨跌幅": 0.57,
        "开盘价": 29.83,
        "最高价": 30.14,
        "最低价": 29.8,
        "昨收价": 29.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 88657.0,
        "成交额": 2661457.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.TCHP"
    },
    {
        "序号": 3430,
        "name": "Wheeler Real Estate Investment ",
        "最新价": 14.18,
        "涨跌额": 0.08,
        "涨跌幅": 0.57,
        "开盘价": 14.17,
        "最高价": 14.3,
        "最低价": 13.82,
        "昨收价": 14.1,
        "总市值": 35675122.0,
        "市盈率": null,
        "成交量": 7191.0,
        "成交额": 101564.0,
        "振幅": 3.4,
        "换手率": 0.29,
        "symbol": "105.WHLRD"
    },
    {
        "序号": 3431,
        "name": "Pacer Trendpilot European Index",
        "最新价": 24.83,
        "涨跌额": 0.14,
        "涨跌幅": 0.57,
        "开盘价": 24.76,
        "最高价": 24.83,
        "最低价": 24.74,
        "昨收价": 24.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 1786.0,
        "成交额": 44285.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.PTEU"
    },
    {
        "序号": 3432,
        "name": "Arrow Investments Trust Arrow D",
        "最新价": 28.39,
        "涨跌额": 0.16,
        "涨跌幅": 0.57,
        "开盘价": 28.39,
        "最高价": 28.39,
        "最低价": 28.39,
        "昨收价": 28.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 56.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.DWCR"
    },
    {
        "序号": 3433,
        "name": "小型股指数ETF-Vanguard",
        "最新价": 200.6,
        "涨跌额": 1.13,
        "涨跌幅": 0.57,
        "开盘价": 199.3,
        "最高价": 201.41,
        "最低价": 199.03,
        "昨收价": 199.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 842670.0,
        "成交额": 168886702.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.VB"
    },
    {
        "序号": 3434,
        "name": "CTS Corp",
        "最新价": 40.85,
        "涨跌额": 0.23,
        "涨跌幅": 0.57,
        "开盘价": 40.47,
        "最高价": 40.92,
        "最低价": 40.22,
        "昨收价": 40.62,
        "总市值": 1272805526.0,
        "市盈率": 21.16,
        "成交量": 121442.0,
        "成交额": 4945517.0,
        "振幅": 1.72,
        "换手率": 0.39,
        "symbol": "106.CTS"
    },
    {
        "序号": 3435,
        "name": "YieldMax MRNA Option Income Str",
        "最新价": 19.55,
        "涨跌额": 0.11,
        "涨跌幅": 0.57,
        "开盘价": 19.31,
        "最高价": 19.73,
        "最低价": 19.31,
        "昨收价": 19.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 5277.0,
        "成交额": 103819.0,
        "振幅": 2.16,
        "换手率": null,
        "symbol": "107.MRNY"
    },
    {
        "序号": 3436,
        "name": "房地产按揭贷款ETF-iShares",
        "最新价": 23.12,
        "涨跌额": 0.13,
        "涨跌幅": 0.57,
        "开盘价": 22.97,
        "最高价": 23.27,
        "最低价": 22.86,
        "昨收价": 22.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 376176.0,
        "成交额": 8690112.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "107.REM"
    },
    {
        "序号": 3437,
        "name": "Goldman Sachs ActiveBeta Europe",
        "最新价": 33.82,
        "涨跌额": 0.19,
        "涨跌幅": 0.56,
        "开盘价": 33.82,
        "最高价": 33.82,
        "最低价": 33.82,
        "昨收价": 33.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 33.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GSEU"
    },
    {
        "序号": 3438,
        "name": "BNY Mellon US Mid Cap Core Equi",
        "最新价": 85.45,
        "涨跌额": 0.48,
        "涨跌幅": 0.56,
        "开盘价": 85.41,
        "最高价": 85.62,
        "最低价": 85.02,
        "昨收价": 84.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 4055.0,
        "成交额": 345813.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.BKMC"
    },
    {
        "序号": 3439,
        "name": "赛诺菲",
        "最新价": 46.32,
        "涨跌额": 0.26,
        "涨跌幅": 0.56,
        "开盘价": 46.21,
        "最高价": 46.48,
        "最低价": 46.12,
        "昨收价": 46.06,
        "总市值": 116947641088.0,
        "市盈率": 11.65,
        "成交量": 1334132.0,
        "成交额": 61731490.0,
        "振幅": 0.78,
        "换手率": 0.05,
        "symbol": "105.SNY"
    },
    {
        "序号": 3440,
        "name": "Principal Price Setters Index E",
        "最新价": 58.81,
        "涨跌额": 0.33,
        "涨跌幅": 0.56,
        "开盘价": 58.72,
        "最高价": 58.81,
        "最低价": 58.67,
        "昨收价": 58.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1319.0,
        "成交额": 77446.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "105.PSET"
    },
    {
        "序号": 3441,
        "name": "Nuveen ESG Mid-Cap Value ETF",
        "最新价": 30.31,
        "涨跌额": 0.17,
        "涨跌幅": 0.56,
        "开盘价": 30.16,
        "最高价": 30.39,
        "最低价": 30.16,
        "昨收价": 30.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 39361.0,
        "成交额": 1191692.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.NUMV"
    },
    {
        "序号": 3442,
        "name": "FT Cboe Vest Nasdaq-100 Buffer ",
        "最新价": 23.18,
        "涨跌额": 0.13,
        "涨跌幅": 0.56,
        "开盘价": 23.04,
        "最高价": 23.18,
        "最低价": 23.04,
        "昨收价": 23.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 29646.0,
        "成交额": 685232.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.QSPT"
    },
    {
        "序号": 3443,
        "name": "The Taiwan Fund",
        "最新价": 33.94,
        "涨跌额": 0.19,
        "涨跌幅": 0.56,
        "开盘价": 33.68,
        "最高价": 34.0,
        "最低价": 33.68,
        "昨收价": 33.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 8909.0,
        "成交额": 301960.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.TWN"
    },
    {
        "序号": 3444,
        "name": "CECO环保",
        "最新价": 19.66,
        "涨跌额": 0.11,
        "涨跌幅": 0.56,
        "开盘价": 19.49,
        "最高价": 19.86,
        "最低价": 19.32,
        "昨收价": 19.55,
        "总市值": 684414517.0,
        "市盈率": 39.5,
        "成交量": 130542.0,
        "成交额": 2565137.0,
        "振幅": 2.76,
        "换手率": 0.37,
        "symbol": "105.CECO"
    },
    {
        "序号": 3445,
        "name": "JPMorgan Active Growth ETF",
        "最新价": 59.0,
        "涨跌额": 0.33,
        "涨跌幅": 0.56,
        "开盘价": 58.59,
        "最高价": 59.08,
        "最低价": 58.43,
        "昨收价": 58.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 99197.0,
        "成交额": 5839407.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.JGRO"
    },
    {
        "序号": 3446,
        "name": "Midland States Bancorp Inc",
        "最新价": 25.05,
        "涨跌额": 0.14,
        "涨跌幅": 0.56,
        "开盘价": 24.85,
        "最高价": 25.11,
        "最低价": 24.8,
        "昨收价": 24.91,
        "总市值": 539537772.0,
        "市盈率": 5.72,
        "成交量": 55834.0,
        "成交额": 1395359.0,
        "振幅": 1.24,
        "换手率": 0.26,
        "symbol": "105.MSBI"
    },
    {
        "序号": 3447,
        "name": "10X Capital Venture Acquisition",
        "最新价": 10.74,
        "涨跌额": 0.06,
        "涨跌幅": 0.56,
        "开盘价": 10.68,
        "最高价": 10.74,
        "最低价": 10.68,
        "昨收价": 10.68,
        "总市值": 141706599.0,
        "市盈率": -113.18,
        "成交量": 3294.0,
        "成交额": 35237.0,
        "振幅": 0.56,
        "换手率": 0.02,
        "symbol": "107.VCXB"
    },
    {
        "序号": 3448,
        "name": "Cambria ETF Trust Cambria Value",
        "最新价": 26.85,
        "涨跌额": 0.15,
        "涨跌幅": 0.56,
        "开盘价": 26.73,
        "最高价": 26.92,
        "最低价": 26.73,
        "昨收价": 26.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 1305.0,
        "成交额": 35042.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.VAMO"
    },
    {
        "序号": 3449,
        "name": "MAX Airlines 3X Leveraged ETNs",
        "最新价": 19.69,
        "涨跌额": 0.11,
        "涨跌幅": 0.56,
        "开盘价": 19.88,
        "最高价": 19.88,
        "最低价": 19.46,
        "昨收价": 19.58,
        "总市值": 14194701380.0,
        "市盈率": 4.47,
        "成交量": 1339.0,
        "成交额": 26263.0,
        "振幅": 2.15,
        "换手率": null,
        "symbol": "107.JETU"
    },
    {
        "序号": 3450,
        "name": "Phillips 66",
        "最新价": 125.32,
        "涨跌额": 0.7,
        "涨跌幅": 0.56,
        "开盘价": 125.42,
        "最高价": 126.4,
        "最低价": 123.29,
        "昨收价": 124.62,
        "总市值": 55135232659.0,
        "市盈率": 7.22,
        "成交量": 4102731.0,
        "成交额": 513693744.0,
        "振幅": 2.5,
        "换手率": 0.93,
        "symbol": "106.PSX"
    },
    {
        "序号": 3451,
        "name": "Sovereign's Capital Flourish Fu",
        "最新价": 26.87,
        "涨跌额": 0.15,
        "涨跌幅": 0.56,
        "开盘价": 26.98,
        "最高价": 26.98,
        "最低价": 26.77,
        "昨收价": 26.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 6616.0,
        "成交额": 177921.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.SOVF"
    },
    {
        "序号": 3452,
        "name": "Capital Group Growth ETF",
        "最新价": 26.87,
        "涨跌额": 0.15,
        "涨跌幅": 0.56,
        "开盘价": 26.65,
        "最高价": 26.9,
        "最低价": 26.62,
        "昨收价": 26.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 1174323.0,
        "成交额": 31482478.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.CGGR"
    },
    {
        "序号": 3453,
        "name": "ACV Auctions Inc-A",
        "最新价": 14.35,
        "涨跌额": 0.08,
        "涨跌幅": 0.56,
        "开盘价": 14.25,
        "最高价": 14.5,
        "最低价": 14.08,
        "昨收价": 14.27,
        "总市值": 2316649851.0,
        "市盈率": -30.27,
        "成交量": 1537900.0,
        "成交额": 22030787.0,
        "振幅": 2.94,
        "换手率": 0.95,
        "symbol": "105.ACVA"
    },
    {
        "序号": 3454,
        "name": "Wedbush ETFMG Global Cloud Tech",
        "最新价": 39.47,
        "涨跌额": 0.22,
        "涨跌幅": 0.56,
        "开盘价": 38.68,
        "最高价": 39.47,
        "最低价": 38.68,
        "昨收价": 39.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 826.0,
        "成交额": 32357.0,
        "振幅": 2.01,
        "换手率": null,
        "symbol": "107.IVES"
    },
    {
        "序号": 3455,
        "name": "埃塞克斯信托",
        "最新价": 226.11,
        "涨跌额": 1.26,
        "涨跌幅": 0.56,
        "开盘价": 223.81,
        "最高价": 226.2,
        "最低价": 223.35,
        "昨收价": 224.85,
        "总市值": 14512780810.0,
        "市盈率": 27.61,
        "成交量": 331942.0,
        "成交额": 74832462.0,
        "振幅": 1.27,
        "换手率": 0.52,
        "symbol": "106.ESS"
    },
    {
        "序号": 3456,
        "name": "First Trust IPOX Europe Equity ",
        "最新价": 21.54,
        "涨跌额": 0.12,
        "涨跌幅": 0.56,
        "开盘价": 21.54,
        "最高价": 21.54,
        "最低价": 21.54,
        "昨收价": 21.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 176.0,
        "成交额": 3790.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FPXE"
    },
    {
        "序号": 3457,
        "name": "Israel Acquisitions Corp Unit C",
        "最新价": 10.78,
        "涨跌额": 0.06,
        "涨跌幅": 0.56,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.78,
        "昨收价": 10.72,
        "总市值": 214836420.0,
        "市盈率": 49.68,
        "成交量": 2400.0,
        "成交额": 25872.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ISRLU"
    },
    {
        "序号": 3458,
        "name": "Transcontinental Realty Investo",
        "最新价": 30.55,
        "涨跌额": 0.17,
        "涨跌幅": 0.56,
        "开盘价": 30.3,
        "最高价": 30.99,
        "最低价": 30.01,
        "昨收价": 30.38,
        "总市值": 263931104.0,
        "市盈率": 3.91,
        "成交量": 3000.0,
        "成交额": 91055.0,
        "振幅": 3.23,
        "换手率": 0.03,
        "symbol": "106.TCI"
    },
    {
        "序号": 3459,
        "name": "Invesco Dynamic Large Cap Growt",
        "最新价": 75.52,
        "涨跌额": 0.42,
        "涨跌幅": 0.56,
        "开盘价": 74.84,
        "最高价": 75.56,
        "最低价": 74.84,
        "昨收价": 75.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 14309.0,
        "成交额": 1078690.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.PWB"
    },
    {
        "序号": 3460,
        "name": "Pacer US Cash Cows 100 ETF",
        "最新价": 50.38,
        "涨跌额": 0.28,
        "涨跌幅": 0.56,
        "开盘价": 50.18,
        "最高价": 50.55,
        "最低价": 50.14,
        "昨收价": 50.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1943063.0,
        "成交额": 97820485.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.COWZ"
    },
    {
        "序号": 3461,
        "name": "Kaltura Inc",
        "最新价": 1.8,
        "涨跌额": 0.01,
        "涨跌幅": 0.56,
        "开盘价": 1.78,
        "最高价": 1.9,
        "最低价": 1.74,
        "昨收价": 1.79,
        "总市值": 254530129.0,
        "市盈率": -5.19,
        "成交量": 213558.0,
        "成交额": 386330.0,
        "振幅": 8.94,
        "换手率": 0.15,
        "symbol": "105.KLTR"
    },
    {
        "序号": 3462,
        "name": "Innoviz Technologies Ltd",
        "最新价": 1.8,
        "涨跌额": 0.01,
        "涨跌幅": 0.56,
        "开盘价": 1.77,
        "最高价": 1.82,
        "最低价": 1.74,
        "昨收价": 1.79,
        "总市值": 296802164.0,
        "市盈率": -2.33,
        "成交量": 1067205.0,
        "成交额": 1914230.0,
        "振幅": 4.47,
        "换手率": 0.65,
        "symbol": "105.INVZ"
    },
    {
        "序号": 3463,
        "name": "First Trust Industrials AlphaDE",
        "最新价": 61.2,
        "涨跌额": 0.34,
        "涨跌幅": 0.56,
        "开盘价": 60.84,
        "最高价": 61.46,
        "最低价": 60.84,
        "昨收价": 60.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 103969.0,
        "成交额": 6367172.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.FXR"
    },
    {
        "序号": 3464,
        "name": "尚乘IDEA集团",
        "最新价": 1.8,
        "涨跌额": 0.01,
        "涨跌幅": 0.56,
        "开盘价": 1.8,
        "最高价": 1.82,
        "最低价": 1.75,
        "昨收价": 1.79,
        "总市值": 140281257.0,
        "市盈率": 0.89,
        "成交量": 13638.0,
        "成交额": 24364.0,
        "振幅": 3.91,
        "换手率": 0.02,
        "symbol": "106.AMTD"
    },
    {
        "序号": 3465,
        "name": "Robinson Alternative Yield Pre-",
        "最新价": 21.62,
        "涨跌额": 0.12,
        "涨跌幅": 0.56,
        "开盘价": 21.6,
        "最高价": 21.77,
        "最低价": 21.55,
        "昨收价": 21.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 22325.0,
        "成交额": 481588.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.SPAX"
    },
    {
        "序号": 3466,
        "name": "AGNC Investment Corp",
        "最新价": 9.01,
        "涨跌额": 0.05,
        "涨跌幅": 0.56,
        "开盘价": 8.9,
        "最高价": 9.07,
        "最低价": 8.87,
        "昨收价": 8.96,
        "总市值": 6046663790.0,
        "市盈率": 19.89,
        "成交量": 10045938.0,
        "成交额": 90455695.0,
        "振幅": 2.23,
        "换手率": 1.5,
        "symbol": "105.AGNC"
    },
    {
        "序号": 3467,
        "name": "Strive Small-Cap ETF",
        "最新价": 27.07,
        "涨跌额": 0.15,
        "涨跌幅": 0.56,
        "开盘价": 26.93,
        "最高价": 27.07,
        "最低价": 26.92,
        "昨收价": 26.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 7941.0,
        "成交额": 214705.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.STXK"
    },
    {
        "序号": 3468,
        "name": "JPMorgan Global Select Equity E",
        "最新价": 50.57,
        "涨跌额": 0.28,
        "涨跌幅": 0.56,
        "开盘价": 50.57,
        "最高价": 50.57,
        "最低价": 50.57,
        "昨收价": 50.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 44.0,
        "成交额": 2224.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.JGLO"
    },
    {
        "序号": 3469,
        "name": "Smith-Midland Corp",
        "最新价": 37.99,
        "涨跌额": 0.21,
        "涨跌幅": 0.56,
        "开盘价": 37.18,
        "最高价": 38.0,
        "最低价": 35.78,
        "昨收价": 37.78,
        "总市值": 199729120.0,
        "市盈率": 351.02,
        "成交量": 29312.0,
        "成交额": 1097411.0,
        "振幅": 5.88,
        "换手率": 0.56,
        "symbol": "105.SMID"
    },
    {
        "序号": 3470,
        "name": "RadNet Inc",
        "最新价": 36.22,
        "涨跌额": 0.2,
        "涨跌幅": 0.56,
        "开盘价": 36.66,
        "最高价": 36.69,
        "最低价": 35.97,
        "昨收价": 36.02,
        "总市值": 2459146215.0,
        "市盈率": 619.59,
        "成交量": 419726.0,
        "成交额": 15243255.0,
        "振幅": 2.0,
        "换手率": 0.62,
        "symbol": "105.RDNT"
    },
    {
        "序号": 3471,
        "name": "Nuveen Short-Term REIT ETF",
        "最新价": 28.99,
        "涨跌额": 0.16,
        "涨跌幅": 0.55,
        "开盘价": 28.77,
        "最高价": 28.99,
        "最低价": 28.72,
        "昨收价": 28.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 13047.0,
        "成交额": 376191.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.NURE"
    },
    {
        "序号": 3472,
        "name": "iShares ESG Aware MSCI USA Grow",
        "最新价": 32.67,
        "涨跌额": 0.18,
        "涨跌幅": 0.55,
        "开盘价": 32.69,
        "最高价": 32.69,
        "最低价": 32.67,
        "昨收价": 32.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 991.0,
        "成交额": 32395.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.EGUS"
    },
    {
        "序号": 3473,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 14.53,
        "涨跌额": 0.08,
        "涨跌幅": 0.55,
        "开盘价": 14.53,
        "最高价": 14.53,
        "最低价": 14.53,
        "昨收价": 14.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 206.0,
        "成交额": 2993.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PCG_H"
    },
    {
        "序号": 3474,
        "name": "WisdomTree U.S. High Dividend F",
        "最新价": 79.96,
        "涨跌额": 0.44,
        "涨跌幅": 0.55,
        "开盘价": 79.67,
        "最高价": 80.08,
        "最低价": 79.51,
        "昨收价": 79.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 29959.0,
        "成交额": 2392079.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.DHS"
    },
    {
        "序号": 3475,
        "name": "Avient Corp",
        "最新价": 36.42,
        "涨跌额": 0.2,
        "涨跌幅": 0.55,
        "开盘价": 36.28,
        "最高价": 36.78,
        "最低价": 36.17,
        "昨收价": 36.22,
        "总市值": 3320099499.0,
        "市盈率": 5.61,
        "成交量": 259051.0,
        "成交额": 9445909.0,
        "振幅": 1.68,
        "换手率": 0.28,
        "symbol": "106.AVNT"
    },
    {
        "序号": 3476,
        "name": "Collegium Pharmaceutical Inc",
        "最新价": 27.33,
        "涨跌额": 0.15,
        "涨跌幅": 0.55,
        "开盘价": 27.12,
        "最高价": 27.57,
        "最低价": 26.83,
        "昨收价": 27.18,
        "总市值": 891255159.0,
        "市盈率": 98.85,
        "成交量": 348277.0,
        "成交额": 9517790.0,
        "振幅": 2.72,
        "换手率": 1.07,
        "symbol": "105.COLL"
    },
    {
        "序号": 3477,
        "name": "Fidelity MSCI Financials Index ",
        "最新价": 51.1,
        "涨跌额": 0.28,
        "涨跌幅": 0.55,
        "开盘价": 50.84,
        "最高价": 51.23,
        "最低价": 50.78,
        "昨收价": 50.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 180879.0,
        "成交额": 9224715.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.FNCL"
    },
    {
        "序号": 3478,
        "name": "Runway Growth Finance Corp",
        "最新价": 12.8,
        "涨跌额": 0.07,
        "涨跌幅": 0.55,
        "开盘价": 12.76,
        "最高价": 12.85,
        "最低价": 12.7,
        "昨收价": 12.73,
        "总市值": 518518643.0,
        "市盈率": 7.67,
        "成交量": 254749.0,
        "成交额": 3257203.0,
        "振幅": 1.18,
        "换手率": 0.63,
        "symbol": "105.RWAY"
    },
    {
        "序号": 3479,
        "name": "Golub Capital BDC Inc",
        "最新价": 14.63,
        "涨跌额": 0.08,
        "涨跌幅": 0.55,
        "开盘价": 14.52,
        "最高价": 14.66,
        "最低价": 14.52,
        "昨收价": 14.55,
        "总市值": 2481171075.0,
        "市盈率": 9.59,
        "成交量": 507105.0,
        "成交额": 7399599.0,
        "振幅": 0.96,
        "换手率": 0.3,
        "symbol": "105.GBDC"
    },
    {
        "序号": 3480,
        "name": "US Bancorp Series B Pfd",
        "最新价": 20.13,
        "涨跌额": 0.11,
        "涨跌幅": 0.55,
        "开盘价": 20.09,
        "最高价": 20.2,
        "最低价": 20.0,
        "昨收价": 20.02,
        "总市值": 805200.0,
        "市盈率": null,
        "成交量": 46805.0,
        "成交额": 940104.0,
        "振幅": 1.0,
        "换手率": 117.01,
        "symbol": "106.USB_H"
    },
    {
        "序号": 3481,
        "name": "iShares Interest Rate Hedged Hi",
        "最新价": 84.18,
        "涨跌额": 0.46,
        "涨跌幅": 0.55,
        "开盘价": 83.75,
        "最高价": 84.18,
        "最低价": 83.75,
        "昨收价": 83.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 31359.0,
        "成交额": 2631467.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.HYGH"
    },
    {
        "序号": 3482,
        "name": "Franklin FTSE Latin America ETF",
        "最新价": 23.79,
        "涨跌额": 0.13,
        "涨跌幅": 0.55,
        "开盘价": 23.63,
        "最高价": 23.83,
        "最低价": 23.63,
        "昨收价": 23.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 5897.0,
        "成交额": 139898.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.FLLA"
    },
    {
        "序号": 3483,
        "name": "Digital Ally Inc",
        "最新价": 1.83,
        "涨跌额": 0.01,
        "涨跌幅": 0.55,
        "开盘价": 1.83,
        "最高价": 1.83,
        "最低价": 1.79,
        "昨收价": 1.82,
        "总市值": 5125376.0,
        "市盈率": -0.18,
        "成交量": 6595.0,
        "成交额": 11945.0,
        "振幅": 2.2,
        "换手率": 0.24,
        "symbol": "105.DGLY"
    },
    {
        "序号": 3484,
        "name": "First Trust Mid Cap Value Alpha",
        "最新价": 47.59,
        "涨跌额": 0.26,
        "涨跌幅": 0.55,
        "开盘价": 47.59,
        "最高价": 47.83,
        "最低价": 47.37,
        "昨收价": 47.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 7247.0,
        "成交额": 344991.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "105.FNK"
    },
    {
        "序号": 3485,
        "name": "第一工业地产信托",
        "最新价": 49.46,
        "涨跌额": 0.27,
        "涨跌幅": 0.55,
        "开盘价": 48.96,
        "最高价": 49.47,
        "最低价": 48.8,
        "昨收价": 49.19,
        "总市值": 6542320165.0,
        "市盈率": 24.45,
        "成交量": 921225.0,
        "成交额": 45408418.0,
        "振幅": 1.36,
        "换手率": 0.7,
        "symbol": "106.FR"
    },
    {
        "序号": 3486,
        "name": "瓦莱罗能源",
        "最新价": 122.74,
        "涨跌额": 0.67,
        "涨跌幅": 0.55,
        "开盘价": 123.31,
        "最高价": 124.19,
        "最低价": 122.1,
        "昨收价": 122.07,
        "总市值": 41787194101.0,
        "市盈率": 3.89,
        "成交量": 2176131.0,
        "成交额": 267364677.0,
        "振幅": 1.71,
        "换手率": 0.64,
        "symbol": "106.VLO"
    },
    {
        "序号": 3487,
        "name": "欧洲ETF-Vanguard FTSE",
        "最新价": 62.38,
        "涨跌额": 0.34,
        "涨跌幅": 0.55,
        "开盘价": 62.02,
        "最高价": 62.45,
        "最低价": 61.97,
        "昨收价": 62.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1396613.0,
        "成交额": 87008126.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.VGK"
    },
    {
        "序号": 3488,
        "name": "HCM Defender 100 Index ETF",
        "最新价": 47.72,
        "涨跌额": 0.26,
        "涨跌幅": 0.55,
        "开盘价": 47.12,
        "最高价": 47.74,
        "最低价": 47.12,
        "昨收价": 47.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 12851.0,
        "成交额": 611829.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "107.QQH"
    },
    {
        "序号": 3489,
        "name": "Global Industrial Co",
        "最新价": 36.71,
        "涨跌额": 0.2,
        "涨跌幅": 0.55,
        "开盘价": 36.94,
        "最高价": 36.94,
        "最低价": 36.02,
        "昨收价": 36.51,
        "总市值": 1397316371.0,
        "市盈率": 20.19,
        "成交量": 38783.0,
        "成交额": 1419820.0,
        "振幅": 2.52,
        "换手率": 0.1,
        "symbol": "106.GIC"
    },
    {
        "序号": 3490,
        "name": "Avantis U.S. Mid Cap Equity ETF",
        "最新价": 53.24,
        "涨跌额": 0.29,
        "涨跌幅": 0.55,
        "开盘价": 53.28,
        "最高价": 53.28,
        "最低价": 53.24,
        "昨收价": 52.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 2575.0,
        "成交额": 137194.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.AVMC"
    },
    {
        "序号": 3491,
        "name": "Adaptive Alpha Opportunities ET",
        "最新价": 23.87,
        "涨跌额": 0.13,
        "涨跌幅": 0.55,
        "开盘价": 23.98,
        "最高价": 23.98,
        "最低价": 23.81,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 17953.0,
        "成交额": 428123.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.AGOX"
    },
    {
        "序号": 3492,
        "name": "美国再保险集团",
        "最新价": 163.64,
        "涨跌额": 0.89,
        "涨跌幅": 0.55,
        "开盘价": 163.0,
        "最高价": 163.91,
        "最低价": 162.42,
        "昨收价": 162.75,
        "总市值": 10784819385.0,
        "市盈率": 9.45,
        "成交量": 204420.0,
        "成交额": 33403500.0,
        "振幅": 0.92,
        "换手率": 0.31,
        "symbol": "106.RGA"
    },
    {
        "序号": 3493,
        "name": "Global X Nasdaq 100 ESG Covered",
        "最新价": 25.75,
        "涨跌额": 0.14,
        "涨跌幅": 0.55,
        "开盘价": 25.75,
        "最高价": 25.75,
        "最低价": 25.75,
        "昨收价": 25.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 103.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.QYLE"
    },
    {
        "序号": 3494,
        "name": "Safe Bulkers Inc",
        "最新价": 3.68,
        "涨跌额": 0.02,
        "涨跌幅": 0.55,
        "开盘价": 3.67,
        "最高价": 3.72,
        "最低价": 3.66,
        "昨收价": 3.66,
        "总市值": 410716807.0,
        "市盈率": 4.85,
        "成交量": 376991.0,
        "成交额": 1389593.0,
        "振幅": 1.64,
        "换手率": 0.34,
        "symbol": "106.SB"
    },
    {
        "序号": 3495,
        "name": "金克地产",
        "最新价": 20.24,
        "涨跌额": 0.11,
        "涨跌幅": 0.55,
        "开盘价": 20.05,
        "最高价": 20.24,
        "最低价": 19.88,
        "昨收价": 20.13,
        "总市值": 12546253241.0,
        "市盈率": 48.74,
        "成交量": 5814715.0,
        "成交额": 116940951.0,
        "振幅": 1.79,
        "换手率": 0.94,
        "symbol": "106.KIM"
    },
    {
        "序号": 3496,
        "name": "CommScope Holding Co Inc",
        "最新价": 1.84,
        "涨跌额": 0.01,
        "涨跌幅": 0.55,
        "开盘价": 1.84,
        "最高价": 1.88,
        "最低价": 1.78,
        "昨收价": 1.83,
        "总市值": 390187587.0,
        "市盈率": -0.19,
        "成交量": 2912781.0,
        "成交额": 5337775.0,
        "振幅": 5.46,
        "换手率": 1.37,
        "symbol": "105.COMM"
    },
    {
        "序号": 3497,
        "name": "American Beacon AHL Trend ETF",
        "最新价": 23.93,
        "涨跌额": 0.13,
        "涨跌幅": 0.55,
        "开盘价": 23.92,
        "最高价": 23.93,
        "最低价": 23.91,
        "昨收价": 23.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 345.0,
        "成交额": 8250.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.AHLT"
    },
    {
        "序号": 3498,
        "name": "二倍做空房地产ETF-ProShares",
        "最新价": 16.57,
        "涨跌额": 0.09,
        "涨跌幅": 0.55,
        "开盘价": 16.6,
        "最高价": 16.87,
        "最低价": 16.46,
        "昨收价": 16.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 64889.0,
        "成交额": 1082821.0,
        "振幅": 2.49,
        "换手率": null,
        "symbol": "107.SRS"
    },
    {
        "序号": 3499,
        "name": "ClimateRock-A",
        "最新价": 11.05,
        "涨跌额": 0.06,
        "涨跌幅": 0.55,
        "开盘价": 11.0,
        "最高价": 11.5,
        "最低价": 10.99,
        "昨收价": 10.99,
        "总市值": 51537344.0,
        "市盈率": 102.39,
        "成交量": 6203.0,
        "成交额": 68702.0,
        "振幅": 4.64,
        "换手率": 0.13,
        "symbol": "105.CLRC"
    },
    {
        "序号": 3500,
        "name": "monday.com Ltd",
        "最新价": 175.03,
        "涨跌额": 0.95,
        "涨跌幅": 0.55,
        "开盘价": 173.85,
        "最高价": 176.75,
        "最低价": 173.5,
        "昨收价": 174.08,
        "总市值": 8450010650.0,
        "市盈率": -538.15,
        "成交量": 535601.0,
        "成交额": 94002210.0,
        "振幅": 1.87,
        "换手率": 1.11,
        "symbol": "105.MNDY"
    },
    {
        "序号": 3501,
        "name": "PIMCO RAFI ESG U.S. ETF",
        "最新价": 31.35,
        "涨跌额": 0.17,
        "涨跌幅": 0.55,
        "开盘价": 31.1,
        "最高价": 31.35,
        "最低价": 31.1,
        "昨收价": 31.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 6505.0,
        "成交额": 203488.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.RAFE"
    },
    {
        "序号": 3502,
        "name": "韩美金融",
        "最新价": 18.45,
        "涨跌额": 0.1,
        "涨跌幅": 0.54,
        "开盘价": 18.41,
        "最高价": 18.71,
        "最低价": 18.37,
        "昨收价": 18.35,
        "总市值": 560776625.0,
        "市盈率": 6.24,
        "成交量": 114324.0,
        "成交额": 2110132.0,
        "振幅": 1.85,
        "换手率": 0.38,
        "symbol": "105.HAFC"
    },
    {
        "序号": 3503,
        "name": "PIMCO Commodity Strategy Active",
        "最新价": 25.83,
        "涨跌额": 0.14,
        "涨跌幅": 0.54,
        "开盘价": 25.8,
        "最高价": 25.86,
        "最低价": 25.78,
        "昨收价": 25.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 10546.0,
        "成交额": 272087.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.CMDT"
    },
    {
        "序号": 3504,
        "name": "Claros Mortgage Trust Inc",
        "最新价": 12.92,
        "涨跌额": 0.07,
        "涨跌幅": 0.54,
        "开盘价": 12.85,
        "最高价": 12.97,
        "最低价": 12.74,
        "昨收价": 12.85,
        "总市值": 1792374675.0,
        "市盈率": -35.37,
        "成交量": 293756.0,
        "成交额": 3787626.0,
        "振幅": 1.79,
        "换手率": 0.21,
        "symbol": "106.CMTG"
    },
    {
        "序号": 3505,
        "name": "KPOP and Korean Entertainment E",
        "最新价": 18.46,
        "涨跌额": 0.1,
        "涨跌幅": 0.54,
        "开盘价": 18.46,
        "最高价": 18.46,
        "最低价": 18.46,
        "昨收价": 18.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 41.0,
        "成交额": 756.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KPOP"
    },
    {
        "序号": 3506,
        "name": "T. Rowe Price Growth ETF",
        "最新价": 27.7,
        "涨跌额": 0.15,
        "涨跌幅": 0.54,
        "开盘价": 27.53,
        "最高价": 27.7,
        "最低价": 27.53,
        "昨收价": 27.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 620.0,
        "成交额": 17099.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.TGRT"
    },
    {
        "序号": 3507,
        "name": "Enhabit Inc",
        "最新价": 11.08,
        "涨跌额": 0.06,
        "涨跌幅": 0.54,
        "开盘价": 10.96,
        "最高价": 11.17,
        "最低价": 10.92,
        "昨收价": 11.02,
        "总市值": 555435924.0,
        "市盈率": -3.28,
        "成交量": 566072.0,
        "成交额": 6263383.0,
        "振幅": 2.27,
        "换手率": 1.13,
        "symbol": "106.EHAB"
    },
    {
        "序号": 3508,
        "name": "First Trust Morningstar ETF",
        "最新价": 35.1,
        "涨跌额": 0.19,
        "涨跌幅": 0.54,
        "开盘价": 34.95,
        "最高价": 35.19,
        "最低价": 34.92,
        "昨收价": 34.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 600983.0,
        "成交额": 21082131.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.FDL"
    },
    {
        "序号": 3509,
        "name": "iShares Morningstar Small-Cap V",
        "最新价": 55.46,
        "涨跌额": 0.3,
        "涨跌幅": 0.54,
        "开盘价": 55.04,
        "最高价": 55.73,
        "最低价": 55.04,
        "昨收价": 55.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 9490.0,
        "成交额": 526111.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.ISCV"
    },
    {
        "序号": 3510,
        "name": "SPDR Portfolio Europe ETF",
        "最新价": 38.84,
        "涨跌额": 0.21,
        "涨跌幅": 0.54,
        "开盘价": 38.74,
        "最高价": 38.89,
        "最低价": 38.72,
        "昨收价": 38.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 9765.0,
        "成交额": 378768.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.SPEU"
    },
    {
        "序号": 3511,
        "name": "PIMCO Municipal Income Fund",
        "最新价": 9.25,
        "涨跌额": 0.05,
        "涨跌幅": 0.54,
        "开盘价": 9.2,
        "最高价": 9.25,
        "最低价": 9.1,
        "昨收价": 9.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 69565.0,
        "成交额": 636521.0,
        "振幅": 1.63,
        "换手率": null,
        "symbol": "106.PMF"
    },
    {
        "序号": 3512,
        "name": "LiveVox Holdings Inc-A",
        "最新价": 3.7,
        "涨跌额": 0.02,
        "涨跌幅": 0.54,
        "开盘价": 3.68,
        "最高价": 3.86,
        "最低价": 3.68,
        "昨收价": 3.68,
        "总市值": 350151698.0,
        "市盈率": -14.27,
        "成交量": 244001.0,
        "成交额": 899783.0,
        "振幅": 4.89,
        "换手率": 0.26,
        "symbol": "105.LVOX"
    },
    {
        "序号": 3513,
        "name": "First Financial Corp",
        "最新价": 40.73,
        "涨跌额": 0.22,
        "涨跌幅": 0.54,
        "开盘价": 40.43,
        "最高价": 40.95,
        "最低价": 40.35,
        "昨收价": 40.51,
        "总市值": 478761925.0,
        "市盈率": 7.39,
        "成交量": 23090.0,
        "成交额": 940206.0,
        "振幅": 1.48,
        "换手率": 0.2,
        "symbol": "105.THFF"
    },
    {
        "序号": 3514,
        "name": "First Trust Switzerland AlphaDE",
        "最新价": 61.17,
        "涨跌额": 0.33,
        "涨跌幅": 0.54,
        "开盘价": 61.09,
        "最高价": 61.24,
        "最低价": 61.0,
        "昨收价": 60.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 729.0,
        "成交额": 44510.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "105.FSZ"
    },
    {
        "序号": 3515,
        "name": "Trinity Capital Inc",
        "最新价": 14.83,
        "涨跌额": 0.08,
        "涨跌幅": 0.54,
        "开盘价": 14.72,
        "最高价": 14.85,
        "最低价": 14.72,
        "昨收价": 14.75,
        "总市值": 646971311.0,
        "市盈率": 11.23,
        "成交量": 365424.0,
        "成交额": 5411838.0,
        "振幅": 0.88,
        "换手率": 0.84,
        "symbol": "105.TRIN"
    },
    {
        "序号": 3516,
        "name": "RiverFront Dynamic US Dividend ",
        "最新价": 46.36,
        "涨跌额": 0.25,
        "涨跌幅": 0.54,
        "开盘价": 46.26,
        "最高价": 46.36,
        "最低价": 46.21,
        "昨收价": 46.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 3353.0,
        "成交额": 155335.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.RFDA"
    },
    {
        "序号": 3517,
        "name": "BlackRock MuniYield Quality Fun",
        "最新价": 11.13,
        "涨跌额": 0.06,
        "涨跌幅": 0.54,
        "开盘价": 11.05,
        "最高价": 11.13,
        "最低价": 11.02,
        "昨收价": 11.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 364151.0,
        "成交额": 4038592.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "106.MYI"
    },
    {
        "序号": 3518,
        "name": "绿光资本",
        "最新价": 11.14,
        "涨跌额": 0.06,
        "涨跌幅": 0.54,
        "开盘价": 11.11,
        "最高价": 11.22,
        "最低价": 11.04,
        "昨收价": 11.08,
        "总市值": 393658714.0,
        "市盈率": 3.79,
        "成交量": 68167.0,
        "成交额": 758607.0,
        "振幅": 1.62,
        "换手率": 0.19,
        "symbol": "105.GLRE"
    },
    {
        "序号": 3519,
        "name": "健康流科技",
        "最新价": 26.0,
        "涨跌额": 0.14,
        "涨跌幅": 0.54,
        "开盘价": 25.89,
        "最高价": 26.02,
        "最低价": 25.73,
        "昨收价": 25.86,
        "总市值": 787418710.0,
        "市盈率": 60.24,
        "成交量": 66583.0,
        "成交额": 1726185.0,
        "振幅": 1.12,
        "换手率": 0.22,
        "symbol": "105.HSTM"
    },
    {
        "序号": 3520,
        "name": "Vanguard Mid-Cap Growth ETF",
        "最新价": 206.17,
        "涨跌额": 1.11,
        "涨跌幅": 0.54,
        "开盘价": 204.82,
        "最高价": 206.53,
        "最低价": 204.82,
        "昨收价": 205.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 119302.0,
        "成交额": 24532684.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.VOT"
    },
    {
        "序号": 3521,
        "name": "Kinsale Capital Group Inc",
        "最新价": 330.73,
        "涨跌额": 1.78,
        "涨跌幅": 0.54,
        "开盘价": 330.11,
        "最高价": 332.29,
        "最低价": 327.19,
        "昨收价": 328.95,
        "总市值": 7664161072.0,
        "市盈率": 28.18,
        "成交量": 187746.0,
        "成交额": 61965934.0,
        "振幅": 1.55,
        "换手率": 0.81,
        "symbol": "106.KNSL"
    },
    {
        "序号": 3522,
        "name": "FTAI Aviation Ltd Series B Pfd",
        "最新价": 24.16,
        "涨跌额": 0.13,
        "涨跌幅": 0.54,
        "开盘价": 24.1,
        "最高价": 24.35,
        "最低价": 24.1,
        "昨收价": 24.03,
        "总市值": 119350400.0,
        "市盈率": null,
        "成交量": 3026.0,
        "成交额": 73346.0,
        "振幅": 1.04,
        "换手率": 0.06,
        "symbol": "105.FTAIO"
    },
    {
        "序号": 3523,
        "name": "BankUnited Inc",
        "最新价": 29.74,
        "涨跌额": 0.16,
        "涨跌幅": 0.54,
        "开盘价": 29.63,
        "最高价": 30.09,
        "最低价": 29.26,
        "昨收价": 29.58,
        "总市值": 2212124873.0,
        "市盈率": 9.96,
        "成交量": 774732.0,
        "成交额": 22970887.0,
        "振幅": 2.81,
        "换手率": 1.04,
        "symbol": "106.BKU"
    },
    {
        "序号": 3524,
        "name": "格林酒店",
        "最新价": 3.72,
        "涨跌额": 0.02,
        "涨跌幅": 0.54,
        "开盘价": 3.71,
        "最高价": 3.79,
        "最低价": 3.55,
        "昨收价": 3.7,
        "总市值": 380105713.0,
        "市盈率": 8.54,
        "成交量": 6182.0,
        "成交额": 23042.0,
        "振幅": 6.49,
        "换手率": 0.01,
        "symbol": "106.GHG"
    },
    {
        "序号": 3525,
        "name": "Calvert US Large-Cap Diversity ",
        "最新价": 59.54,
        "涨跌额": 0.32,
        "涨跌幅": 0.54,
        "开盘价": 59.27,
        "最高价": 59.54,
        "最低价": 59.27,
        "昨收价": 59.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 390.0,
        "成交额": 23116.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.CDEI"
    },
    {
        "序号": 3526,
        "name": "T. Rowe Price Growth Stock ETF",
        "最新价": 29.78,
        "涨跌额": 0.16,
        "涨跌幅": 0.54,
        "开盘价": 29.65,
        "最高价": 29.78,
        "最低价": 29.63,
        "昨收价": 29.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 2366.0,
        "成交额": 70225.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.TGRW"
    },
    {
        "序号": 3527,
        "name": "iShares Russell 2500 ETF",
        "最新价": 57.7,
        "涨跌额": 0.31,
        "涨跌幅": 0.54,
        "开盘价": 57.41,
        "最高价": 57.91,
        "最低价": 57.28,
        "昨收价": 57.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 58549.0,
        "成交额": 3373969.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.SMMD"
    },
    {
        "序号": 3528,
        "name": "Texas Capital Bancshares Inc",
        "最新价": 57.72,
        "涨跌额": 0.31,
        "涨跌幅": 0.54,
        "开盘价": 57.72,
        "最高价": 58.48,
        "最低价": 57.44,
        "昨收价": 57.41,
        "总市值": 2771740259.0,
        "市盈率": 7.18,
        "成交量": 355215.0,
        "成交额": 20533873.0,
        "振幅": 1.81,
        "换手率": 0.74,
        "symbol": "105.TCBI"
    },
    {
        "序号": 3529,
        "name": "人民金服",
        "最新价": 44.72,
        "涨跌额": 0.24,
        "涨跌幅": 0.54,
        "开盘价": 44.34,
        "最高价": 44.85,
        "最低价": 44.34,
        "昨收价": 44.48,
        "总市值": 314866857.0,
        "市盈率": 9.57,
        "成交量": 11007.0,
        "成交额": 490410.0,
        "振幅": 1.15,
        "换手率": 0.16,
        "symbol": "105.PFIS"
    },
    {
        "序号": 3530,
        "name": "Dimensional U.S. Core Equity 2 ",
        "最新价": 27.99,
        "涨跌额": 0.15,
        "涨跌幅": 0.54,
        "开盘价": 27.81,
        "最高价": 28.03,
        "最低价": 27.8,
        "昨收价": 27.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 1804908.0,
        "成交额": 50461593.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.DFAC"
    },
    {
        "序号": 3531,
        "name": "SB Financial Group Inc",
        "最新价": 14.94,
        "涨跌额": 0.08,
        "涨跌幅": 0.54,
        "开盘价": 14.85,
        "最高价": 15.2,
        "最低价": 14.75,
        "昨收价": 14.86,
        "总市值": 101620700.0,
        "市盈率": 8.65,
        "成交量": 12471.0,
        "成交额": 185457.0,
        "振幅": 3.03,
        "换手率": 0.18,
        "symbol": "105.SBFG"
    },
    {
        "序号": 3532,
        "name": "HilleVax Inc",
        "最新价": 14.94,
        "涨跌额": 0.08,
        "涨跌幅": 0.54,
        "开盘价": 14.57,
        "最高价": 15.08,
        "最低价": 14.52,
        "昨收价": 14.86,
        "总市值": 723648795.0,
        "市盈率": -6.71,
        "成交量": 176043.0,
        "成交额": 2631797.0,
        "振幅": 3.77,
        "换手率": 0.36,
        "symbol": "105.HLVX"
    },
    {
        "序号": 3533,
        "name": "SPDR Portfolio S&P 600 Small Ca",
        "最新价": 39.23,
        "涨跌额": 0.21,
        "涨跌幅": 0.54,
        "开盘价": 38.96,
        "最高价": 39.44,
        "最低价": 38.91,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1423529.0,
        "成交额": 55816835.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.SPSM"
    },
    {
        "序号": 3534,
        "name": "Walker & Dunlop Inc",
        "最新价": 93.43,
        "涨跌额": 0.5,
        "涨跌幅": 0.54,
        "开盘价": 92.9,
        "最高价": 93.86,
        "最低价": 90.36,
        "昨收价": 92.93,
        "总市值": 3125250785.0,
        "市盈率": 26.65,
        "成交量": 269036.0,
        "成交额": 24977908.0,
        "振幅": 3.77,
        "换手率": 0.8,
        "symbol": "106.WD"
    },
    {
        "序号": 3535,
        "name": "荷兰全球保险",
        "最新价": 5.61,
        "涨跌额": 0.03,
        "涨跌幅": 0.54,
        "开盘价": 5.57,
        "最高价": 5.62,
        "最低价": 5.56,
        "昨收价": 5.58,
        "总市值": 14898063593.0,
        "市盈率": -8.53,
        "成交量": 1136975.0,
        "成交额": 6354105.0,
        "振幅": 1.08,
        "换手率": 0.04,
        "symbol": "106.AEG"
    },
    {
        "序号": 3536,
        "name": "Innovator Deepwater Frontier Te",
        "最新价": 41.17,
        "涨跌额": 0.22,
        "涨跌幅": 0.54,
        "开盘价": 40.78,
        "最高价": 41.32,
        "最低价": 40.78,
        "昨收价": 40.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 6072.0,
        "成交额": 249709.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.LOUP"
    },
    {
        "序号": 3537,
        "name": "Harbor Dividend Growth Leaders ",
        "最新价": 13.1,
        "涨跌额": 0.07,
        "涨跌幅": 0.54,
        "开盘价": 13.05,
        "最高价": 13.11,
        "最低价": 13.01,
        "昨收价": 13.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 39202.0,
        "成交额": 512275.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "106.GDIV"
    },
    {
        "序号": 3538,
        "name": "Relay Therapeutics Inc",
        "最新价": 9.37,
        "涨跌额": 0.05,
        "涨跌幅": 0.54,
        "开盘价": 9.35,
        "最高价": 9.54,
        "最低价": 9.1,
        "昨收价": 9.32,
        "总市值": 1155028094.0,
        "市盈率": -3.54,
        "成交量": 1402622.0,
        "成交额": 13130148.0,
        "振幅": 4.72,
        "换手率": 1.14,
        "symbol": "105.RLAY"
    },
    {
        "序号": 3539,
        "name": "多尔切斯特",
        "最新价": 30.0,
        "涨跌额": 0.16,
        "涨跌幅": 0.54,
        "开盘价": 30.1,
        "最高价": 30.6,
        "最低价": 29.85,
        "昨收价": 29.84,
        "总市值": 1187497290.0,
        "市盈率": 11.22,
        "成交量": 139968.0,
        "成交额": 4212957.0,
        "振幅": 2.51,
        "换手率": 0.35,
        "symbol": "105.DMLP"
    },
    {
        "序号": 3540,
        "name": "格拉尼特角抵押信托",
        "最新价": 5.63,
        "涨跌额": 0.03,
        "涨跌幅": 0.54,
        "开盘价": 5.56,
        "最高价": 5.66,
        "最低价": 5.54,
        "昨收价": 5.6,
        "总市值": 290383245.0,
        "市盈率": -5.19,
        "成交量": 329510.0,
        "成交额": 1843790.0,
        "振幅": 2.14,
        "换手率": 0.64,
        "symbol": "106.GPMT"
    },
    {
        "序号": 3541,
        "name": "VanEck Robotics ETF",
        "最新价": 37.55,
        "涨跌额": 0.2,
        "涨跌幅": 0.54,
        "开盘价": 37.4,
        "最高价": 37.59,
        "最低价": 37.4,
        "昨收价": 37.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1466.0,
        "成交额": 55053.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "105.IBOT"
    },
    {
        "序号": 3542,
        "name": "FT Cboe Vest International Equi",
        "最新价": 20.66,
        "涨跌额": 0.11,
        "涨跌幅": 0.54,
        "开盘价": 20.55,
        "最高价": 20.66,
        "最低价": 20.53,
        "昨收价": 20.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 47943.0,
        "成交额": 988730.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.YJUN"
    },
    {
        "序号": 3543,
        "name": "Global X Russell 2000 Covered C",
        "最新价": 24.43,
        "涨跌额": 0.13,
        "涨跌幅": 0.53,
        "开盘价": 24.34,
        "最高价": 24.43,
        "最低价": 24.34,
        "昨收价": 24.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 1471.0,
        "成交额": 35848.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.RYLG"
    },
    {
        "序号": 3544,
        "name": "Sterling Check Corp",
        "最新价": 13.16,
        "涨跌额": 0.07,
        "涨跌幅": 0.53,
        "开盘价": 13.09,
        "最高价": 13.32,
        "最低价": 12.74,
        "昨收价": 13.09,
        "总市值": 1240791982.0,
        "市盈率": -279.96,
        "成交量": 175091.0,
        "成交额": 2308071.0,
        "振幅": 4.43,
        "换手率": 0.19,
        "symbol": "105.STER"
    },
    {
        "序号": 3545,
        "name": "Neuberger Berman High Yield Str",
        "最新价": 7.52,
        "涨跌额": 0.04,
        "涨跌幅": 0.53,
        "开盘价": 7.47,
        "最高价": 7.52,
        "最低价": 7.45,
        "昨收价": 7.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 169419.0,
        "成交额": 1268362.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.NHS"
    },
    {
        "序号": 3546,
        "name": "El Paso Energy Capital Trust I ",
        "最新价": 47.0,
        "涨跌额": 0.25,
        "涨跌幅": 0.53,
        "开盘价": 46.8,
        "最高价": 47.0,
        "最低价": 46.41,
        "昨收价": 46.75,
        "总市值": 305500000.0,
        "市盈率": null,
        "成交量": 7385.0,
        "成交额": 344849.0,
        "振幅": 1.26,
        "换手率": 0.11,
        "symbol": "106.EP_C"
    },
    {
        "序号": 3547,
        "name": "Galapagos NV ADR",
        "最新价": 39.49,
        "涨跌额": 0.21,
        "涨跌幅": 0.53,
        "开盘价": 39.31,
        "最高价": 39.56,
        "最低价": 39.01,
        "昨收价": 39.28,
        "总市值": 2599844329.0,
        "市盈率": -15.8,
        "成交量": 120619.0,
        "成交额": 4752510.0,
        "振幅": 1.4,
        "换手率": 0.18,
        "symbol": "105.GLPG"
    },
    {
        "序号": 3548,
        "name": "SEI Investments Co",
        "最新价": 60.24,
        "涨跌额": 0.32,
        "涨跌幅": 0.53,
        "开盘价": 59.84,
        "最高价": 60.44,
        "最低价": 59.77,
        "昨收价": 59.92,
        "总市值": 7926842626.0,
        "市盈率": 17.47,
        "成交量": 441311.0,
        "成交额": 26577573.0,
        "振幅": 1.12,
        "换手率": 0.34,
        "symbol": "105.SEIC"
    },
    {
        "序号": 3549,
        "name": "BV Financial Inc",
        "最新价": 13.19,
        "涨跌额": 0.07,
        "涨跌幅": 0.53,
        "开盘价": 13.22,
        "最高价": 13.22,
        "最低价": 13.1,
        "昨收价": 13.12,
        "总市值": 150046842.0,
        "市盈率": 11.17,
        "成交量": 8770.0,
        "成交额": 115735.0,
        "振幅": 0.91,
        "换手率": 0.08,
        "symbol": "105.BVFL"
    },
    {
        "序号": 3550,
        "name": "YieldMax DIS Option Income Stra",
        "最新价": 20.77,
        "涨跌额": 0.11,
        "涨跌幅": 0.53,
        "开盘价": 20.36,
        "最高价": 20.8,
        "最低价": 20.27,
        "昨收价": 20.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 7820.0,
        "成交额": 161947.0,
        "振幅": 2.57,
        "换手率": null,
        "symbol": "107.DISO"
    },
    {
        "序号": 3551,
        "name": "Pacer Swan SOS Flex (July) ETF",
        "最新价": 24.55,
        "涨跌额": 0.13,
        "涨跌幅": 0.53,
        "开盘价": 24.41,
        "最高价": 24.56,
        "最低价": 24.41,
        "昨收价": 24.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 3119.0,
        "成交额": 76253.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.PSFJ"
    },
    {
        "序号": 3552,
        "name": "Invesco S&P MidCap Value with M",
        "最新价": 47.22,
        "涨跌额": 0.25,
        "涨跌幅": 0.53,
        "开盘价": 46.92,
        "最高价": 47.35,
        "最低价": 46.89,
        "昨收价": 46.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 25509.0,
        "成交额": 1202534.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.XMVM"
    },
    {
        "序号": 3553,
        "name": "标普100ETF-iShares",
        "最新价": 217.38,
        "涨跌额": 1.15,
        "涨跌幅": 0.53,
        "开盘价": 215.56,
        "最高价": 217.53,
        "最低价": 215.56,
        "昨收价": 216.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 202543.0,
        "成交额": 43928512.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.OEF"
    },
    {
        "序号": 3554,
        "name": "穆迪",
        "最新价": 374.61,
        "涨跌额": 1.98,
        "涨跌幅": 0.53,
        "开盘价": 371.99,
        "最高价": 378.32,
        "最低价": 369.99,
        "昨收价": 372.63,
        "总市值": 68556132769.0,
        "市盈率": 45.31,
        "成交量": 1282223.0,
        "成交额": 480546672.0,
        "振幅": 2.24,
        "换手率": 0.7,
        "symbol": "106.MCO"
    },
    {
        "序号": 3555,
        "name": "南茂科技",
        "最新价": 26.5,
        "涨跌额": 0.14,
        "涨跌幅": 0.53,
        "开盘价": 26.8,
        "最高价": 26.8,
        "最低价": 25.36,
        "昨收价": 26.36,
        "总市值": 963593167.0,
        "市盈率": 8.66,
        "成交量": 10887.0,
        "成交额": 286632.0,
        "振幅": 5.46,
        "换手率": 0.03,
        "symbol": "105.IMOS"
    },
    {
        "序号": 3556,
        "name": "Motley Fool 100 Index ETF",
        "最新价": 43.58,
        "涨跌额": 0.23,
        "涨跌幅": 0.53,
        "开盘价": 43.21,
        "最高价": 43.61,
        "最低价": 43.11,
        "昨收价": 43.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 54085.0,
        "成交额": 2350131.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.TMFC"
    },
    {
        "序号": 3557,
        "name": "Distillate Small\/Mid Cash Flow ",
        "最新价": 32.24,
        "涨跌额": 0.17,
        "涨跌幅": 0.53,
        "开盘价": 32.21,
        "最高价": 32.36,
        "最低价": 32.11,
        "昨收价": 32.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 16974.0,
        "成交额": 546420.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "106.DSMC"
    },
    {
        "序号": 3558,
        "name": "Amplify Cash Flow High Income E",
        "最新价": 24.66,
        "涨跌额": 0.13,
        "涨跌幅": 0.53,
        "开盘价": 24.66,
        "最高价": 24.66,
        "最低价": 24.66,
        "昨收价": 24.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 157.0,
        "成交额": 3871.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HCOW"
    },
    {
        "序号": 3559,
        "name": "Global X Conscious Companies ET",
        "最新价": 32.25,
        "涨跌额": 0.17,
        "涨跌幅": 0.53,
        "开盘价": 32.01,
        "最高价": 32.26,
        "最低价": 32.01,
        "昨收价": 32.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 6904.0,
        "成交额": 222007.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "105.KRMA"
    },
    {
        "序号": 3560,
        "name": "GraniteShares 1.25x Long TSLA D",
        "最新价": 17.08,
        "涨跌额": 0.09,
        "涨跌幅": 0.53,
        "开盘价": 16.73,
        "最高价": 17.15,
        "最低价": 16.73,
        "昨收价": 16.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 18010.0,
        "成交额": 306629.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "105.TSL"
    },
    {
        "序号": 3561,
        "name": "欧洲ETF-iShares",
        "最新价": 51.24,
        "涨跌额": 0.27,
        "涨跌幅": 0.53,
        "开盘价": 50.97,
        "最高价": 51.32,
        "最低价": 50.96,
        "昨收价": 50.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 234941.0,
        "成交额": 12025022.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.IEV"
    },
    {
        "序号": 3562,
        "name": "American Equity Investment Life",
        "最新价": 22.79,
        "涨跌额": 0.12,
        "涨跌幅": 0.53,
        "开盘价": 22.65,
        "最高价": 22.8,
        "最低价": 22.64,
        "昨收价": 22.67,
        "总市值": 364640.0,
        "市盈率": null,
        "成交量": 46827.0,
        "成交额": 1062024.0,
        "振幅": 0.71,
        "换手率": 292.67,
        "symbol": "106.AEL_A"
    },
    {
        "序号": 3563,
        "name": "道明尼能源",
        "最新价": 47.49,
        "涨跌额": 0.25,
        "涨跌幅": 0.53,
        "开盘价": 47.37,
        "最高价": 47.6,
        "最低价": 47.18,
        "昨收价": 47.24,
        "总市值": 39739478655.0,
        "市盈率": 23.14,
        "成交量": 6508835.0,
        "成交额": 308476816.0,
        "振幅": 0.89,
        "换手率": 0.78,
        "symbol": "106.D"
    },
    {
        "序号": 3564,
        "name": "巴恩斯",
        "最新价": 28.5,
        "涨跌额": 0.15,
        "涨跌幅": 0.53,
        "开盘价": 28.27,
        "最高价": 28.79,
        "最低价": 27.99,
        "昨收价": 28.35,
        "总市值": 1444234536.0,
        "市盈率": 59.32,
        "成交量": 280984.0,
        "成交额": 7972847.0,
        "振幅": 2.82,
        "换手率": 0.55,
        "symbol": "106.B"
    },
    {
        "序号": 3565,
        "name": "景顺",
        "最新价": 15.21,
        "涨跌额": 0.08,
        "涨跌幅": 0.53,
        "开盘价": 15.17,
        "最高价": 15.27,
        "最低价": 15.06,
        "昨收价": 15.13,
        "总市值": 6837722409.0,
        "市盈率": 8.21,
        "成交量": 7126376.0,
        "成交额": 108070604.0,
        "振幅": 1.39,
        "换手率": 1.59,
        "symbol": "106.IVZ"
    },
    {
        "序号": 3566,
        "name": "金融ETF-SPDR",
        "最新价": 36.13,
        "涨跌额": 0.19,
        "涨跌幅": 0.53,
        "开盘价": 35.9,
        "最高价": 36.19,
        "最低价": 35.86,
        "昨收价": 35.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 33213096.0,
        "成交额": 1196929824.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.XLF"
    },
    {
        "序号": 3567,
        "name": "Liberty Media Corp Liberty Siri",
        "最新价": 26.64,
        "涨跌额": 0.14,
        "涨跌幅": 0.53,
        "开盘价": 26.42,
        "最高价": 26.79,
        "最低价": 26.39,
        "昨收价": 26.5,
        "总市值": 19035654997.0,
        "市盈率": 15.67,
        "成交量": 881378.0,
        "成交额": 23440124.0,
        "振幅": 1.51,
        "换手率": 0.4,
        "symbol": "105.LSXMK"
    },
    {
        "序号": 3568,
        "name": "希尔顿酒店",
        "最新价": 171.33,
        "涨跌额": 0.9,
        "涨跌幅": 0.53,
        "开盘价": 170.82,
        "最高价": 171.65,
        "最低价": 170.26,
        "昨收价": 170.43,
        "总市值": 43935917456.0,
        "市盈率": 33.23,
        "成交量": 1597887.0,
        "成交额": 273543856.0,
        "振幅": 0.82,
        "换手率": 0.62,
        "symbol": "106.HLT"
    },
    {
        "序号": 3569,
        "name": "UMB金融",
        "最新价": 76.27,
        "涨跌额": 0.4,
        "涨跌幅": 0.53,
        "开盘价": 76.32,
        "最高价": 76.95,
        "最低价": 75.99,
        "昨收价": 75.87,
        "总市值": 3701443963.0,
        "市盈率": 9.76,
        "成交量": 208294.0,
        "成交额": 15894623.0,
        "振幅": 1.27,
        "换手率": 0.43,
        "symbol": "105.UMBF"
    },
    {
        "序号": 3570,
        "name": "Invesco AI and Next Gen Softwar",
        "最新价": 36.28,
        "涨跌额": 0.19,
        "涨跌幅": 0.53,
        "开盘价": 35.66,
        "最高价": 36.33,
        "最低价": 35.66,
        "昨收价": 36.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 9535.0,
        "成交额": 344780.0,
        "振幅": 1.86,
        "换手率": null,
        "symbol": "107.IGPT"
    },
    {
        "序号": 3571,
        "name": "Hypatia Women CEO ETF",
        "最新价": 26.74,
        "涨跌额": 0.14,
        "涨跌幅": 0.53,
        "开盘价": 26.74,
        "最高价": 26.74,
        "最低价": 26.74,
        "昨收价": 26.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 160.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.WCEO"
    },
    {
        "序号": 3572,
        "name": "Carlyle Secured Lending Inc",
        "最新价": 15.29,
        "涨跌额": 0.08,
        "涨跌幅": 0.53,
        "开盘价": 15.16,
        "最高价": 15.29,
        "最低价": 15.16,
        "昨收价": 15.21,
        "总市值": 776654648.0,
        "市盈率": 10.34,
        "成交量": 122881.0,
        "成交额": 1871185.0,
        "振幅": 0.85,
        "换手率": 0.24,
        "symbol": "105.CGBD"
    },
    {
        "序号": 3573,
        "name": "Congress Large Cap Growth ETF",
        "最新价": 26.78,
        "涨跌额": 0.14,
        "涨跌幅": 0.53,
        "开盘价": 26.7,
        "最高价": 26.82,
        "最低价": 26.67,
        "昨收价": 26.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 41693.0,
        "成交额": 1112811.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.CAML"
    },
    {
        "序号": 3574,
        "name": "Pacer Metaurus US Large Cap Div",
        "最新价": 32.57,
        "涨跌额": 0.17,
        "涨跌幅": 0.52,
        "开盘价": 32.33,
        "最高价": 32.63,
        "最低价": 32.33,
        "昨收价": 32.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 60900.0,
        "成交额": 1980953.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.QDPL"
    },
    {
        "序号": 3575,
        "name": "鲍尔包装",
        "最新价": 57.51,
        "涨跌额": 0.3,
        "涨跌幅": 0.52,
        "开盘价": 57.04,
        "最高价": 57.69,
        "最低价": 57.0,
        "昨收价": 57.21,
        "总市值": 18132972817.0,
        "市盈率": 29.82,
        "成交量": 2048798.0,
        "成交额": 117709279.0,
        "振幅": 1.21,
        "换手率": 0.65,
        "symbol": "106.BALL"
    },
    {
        "序号": 3576,
        "name": "Vanguard Mega Cap Growth ETF",
        "最新价": 253.21,
        "涨跌额": 1.32,
        "涨跌幅": 0.52,
        "开盘价": 250.82,
        "最高价": 253.39,
        "最低价": 250.69,
        "昨收价": 251.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 302076.0,
        "成交额": 76334887.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.MGK"
    },
    {
        "序号": 3577,
        "name": "辉瑞",
        "最新价": 28.78,
        "涨跌额": 0.15,
        "涨跌幅": 0.52,
        "开盘价": 28.65,
        "最高价": 28.97,
        "最低价": 28.59,
        "昨收价": 28.63,
        "总市值": 162503774544.0,
        "市盈率": 15.5,
        "成交量": 31890740.0,
        "成交额": 917550608.0,
        "振幅": 1.33,
        "换手率": 0.56,
        "symbol": "106.PFE"
    },
    {
        "序号": 3578,
        "name": "Westrock Coffee Co Wt",
        "最新价": 1.92,
        "涨跌额": 0.01,
        "涨跌幅": 0.52,
        "开盘价": 1.92,
        "最高价": 1.92,
        "最低价": 1.92,
        "昨收价": 1.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 14058.0,
        "成交额": 26991.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.WESTW"
    },
    {
        "序号": 3579,
        "name": "Kurv Yield Premium Strategy Ama",
        "最新价": 26.91,
        "涨跌额": 0.14,
        "涨跌幅": 0.52,
        "开盘价": 26.91,
        "最高价": 26.91,
        "最低价": 26.91,
        "昨收价": 26.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 38.0,
        "成交额": 1022.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AMZP"
    },
    {
        "序号": 3580,
        "name": "Public Storage Series L Pfd",
        "最新价": 21.15,
        "涨跌额": 0.11,
        "涨跌幅": 0.52,
        "开盘价": 21.04,
        "最高价": 21.17,
        "最低价": 20.91,
        "昨收价": 21.04,
        "总市值": 477990.0,
        "市盈率": null,
        "成交量": 42478.0,
        "成交额": 894769.0,
        "振幅": 1.24,
        "换手率": 187.96,
        "symbol": "106.PSA_L"
    },
    {
        "序号": 3581,
        "name": "Vanguard Russell 1000 ETF",
        "最新价": 209.64,
        "涨跌额": 1.09,
        "涨跌幅": 0.52,
        "开盘价": 208.24,
        "最高价": 209.85,
        "最低价": 208.24,
        "昨收价": 208.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 55918.0,
        "成交额": 11698257.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "105.VONE"
    },
    {
        "序号": 3582,
        "name": "RCM Technologies Inc",
        "最新价": 26.93,
        "涨跌额": 0.14,
        "涨跌幅": 0.52,
        "开盘价": 26.7,
        "最高价": 27.02,
        "最低价": 26.65,
        "昨收价": 26.79,
        "总市值": 210926343.0,
        "市盈率": 12.85,
        "成交量": 41109.0,
        "成交额": 1104546.0,
        "振幅": 1.38,
        "换手率": 0.52,
        "symbol": "105.RCMT"
    },
    {
        "序号": 3583,
        "name": "Tema Global Royalties ETF",
        "最新价": 25.06,
        "涨跌额": 0.13,
        "涨跌幅": 0.52,
        "开盘价": 25.06,
        "最高价": 25.06,
        "最低价": 25.06,
        "昨收价": 24.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 2506.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ROYA"
    },
    {
        "序号": 3584,
        "name": "RenaissanceRe Holdings Ltd",
        "最新价": 200.67,
        "涨跌额": 1.04,
        "涨跌幅": 0.52,
        "开盘价": 199.94,
        "最高价": 201.17,
        "最低价": 197.4,
        "昨收价": 199.63,
        "总市值": 10268689855.0,
        "市盈率": 7.17,
        "成交量": 347007.0,
        "成交额": 69269311.0,
        "振幅": 1.89,
        "换手率": 0.68,
        "symbol": "106.RNR"
    },
    {
        "序号": 3585,
        "name": "Permianville Royalty Trust",
        "最新价": 1.93,
        "涨跌额": 0.01,
        "涨跌幅": 0.52,
        "开盘价": 1.91,
        "最高价": 1.96,
        "最低价": 1.9,
        "昨收价": 1.92,
        "总市值": 63690000.0,
        "市盈率": 4.59,
        "成交量": 76669.0,
        "成交额": 147243.0,
        "振幅": 3.13,
        "换手率": 0.23,
        "symbol": "106.PVL"
    },
    {
        "序号": 3586,
        "name": "创新软件",
        "最新价": 7.72,
        "涨跌额": 0.04,
        "涨跌幅": 0.52,
        "开盘价": 7.62,
        "最高价": 7.8,
        "最低价": 7.62,
        "昨收价": 7.68,
        "总市值": 134690763.0,
        "市盈率": 26.96,
        "成交量": 8547.0,
        "成交额": 65639.0,
        "振幅": 2.34,
        "换手率": 0.05,
        "symbol": "105.ISSC"
    },
    {
        "序号": 3587,
        "name": "Clough Global Equity Fund",
        "最新价": 5.79,
        "涨跌额": 0.03,
        "涨跌幅": 0.52,
        "开盘价": 5.74,
        "最高价": 5.8,
        "最低价": 5.74,
        "昨收价": 5.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 41414.0,
        "成交额": 239506.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "107.GLQ"
    },
    {
        "序号": 3588,
        "name": "Strive 1000 Growth ETF",
        "最新价": 32.83,
        "涨跌额": 0.17,
        "涨跌幅": 0.52,
        "开盘价": 32.56,
        "最高价": 32.83,
        "最低价": 32.56,
        "昨收价": 32.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 2278.0,
        "成交额": 74536.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.STXG"
    },
    {
        "序号": 3589,
        "name": "Franklin U.S. Small Cap Multifa",
        "最新价": 36.72,
        "涨跌额": 0.19,
        "涨跌幅": 0.52,
        "开盘价": 36.58,
        "最高价": 36.82,
        "最低价": 36.54,
        "昨收价": 36.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 5779.0,
        "成交额": 211920.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.FLQS"
    },
    {
        "序号": 3590,
        "name": "International Seaways Inc",
        "最新价": 44.48,
        "涨跌额": 0.23,
        "涨跌幅": 0.52,
        "开盘价": 44.7,
        "最高价": 44.93,
        "最低价": 44.25,
        "昨收价": 44.25,
        "总市值": 2175472720.0,
        "市盈率": 3.38,
        "成交量": 302926.0,
        "成交额": 13489133.0,
        "振幅": 1.54,
        "换手率": 0.62,
        "symbol": "106.INSW"
    },
    {
        "序号": 3591,
        "name": "SPDR S&P 600 Small Cap Growth E",
        "最新价": 77.47,
        "涨跌额": 0.4,
        "涨跌幅": 0.52,
        "开盘价": 77.09,
        "最高价": 77.92,
        "最低价": 77.07,
        "昨收价": 77.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 101472.0,
        "成交额": 7860395.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.SLYG"
    },
    {
        "序号": 3592,
        "name": "First Trust NASDAQ Clean Edge S",
        "最新价": 98.81,
        "涨跌额": 0.51,
        "涨跌幅": 0.52,
        "开盘价": 98.14,
        "最高价": 99.2,
        "最低价": 98.14,
        "昨收价": 98.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 28982.0,
        "成交额": 2861644.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "105.GRID"
    },
    {
        "序号": 3593,
        "name": "Impact Shares NAACP Minority Em",
        "最新价": 32.95,
        "涨跌额": 0.17,
        "涨跌幅": 0.52,
        "开盘价": 32.78,
        "最高价": 33.0,
        "最低价": 32.78,
        "昨收价": 32.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1722.0,
        "成交额": 56547.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.NACP"
    },
    {
        "序号": 3594,
        "name": "IQ U.S. Large Cap R&D Leaders E",
        "最新价": 27.15,
        "涨跌额": 0.14,
        "涨跌幅": 0.52,
        "开盘价": 27.15,
        "最高价": 27.15,
        "最低价": 27.15,
        "昨收价": 27.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1178.0,
        "成交额": 31982.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LRND"
    },
    {
        "序号": 3595,
        "name": "亨利香恩服务",
        "最新价": 71.91,
        "涨跌额": 0.37,
        "涨跌幅": 0.52,
        "开盘价": 71.6,
        "最高价": 72.31,
        "最低价": 71.5,
        "昨收价": 71.54,
        "总市值": 9343866101.0,
        "市盈率": 21.0,
        "成交量": 1143798.0,
        "成交额": 82154834.0,
        "振幅": 1.13,
        "换手率": 0.88,
        "symbol": "105.HSIC"
    },
    {
        "序号": 3596,
        "name": "iShares U.S. Health Care Provid",
        "最新价": 258.59,
        "涨跌额": 1.33,
        "涨跌幅": 0.52,
        "开盘价": 257.25,
        "最高价": 258.62,
        "最低价": 256.41,
        "昨收价": 257.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 10389.0,
        "成交额": 2669639.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.IHF"
    },
    {
        "序号": 3597,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 25.3,
        "涨跌额": 0.13,
        "涨跌幅": 0.52,
        "开盘价": 25.21,
        "最高价": 25.31,
        "最低价": 25.18,
        "昨收价": 25.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 14494.0,
        "成交额": 365938.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.AUGT"
    },
    {
        "序号": 3598,
        "name": "Fidelity Women's Leadership ETF",
        "最新价": 19.49,
        "涨跌额": 0.1,
        "涨跌幅": 0.52,
        "开盘价": 19.32,
        "最高价": 19.49,
        "最低价": 19.32,
        "昨收价": 19.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 433.0,
        "成交额": 8395.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.FDWM"
    },
    {
        "序号": 3599,
        "name": "EOG能源",
        "最新价": 118.96,
        "涨跌额": 0.61,
        "涨跌幅": 0.52,
        "开盘价": 119.32,
        "最高价": 120.53,
        "最低价": 118.56,
        "昨收价": 118.35,
        "总市值": 69371476654.0,
        "市盈率": 8.8,
        "成交量": 3858359.0,
        "成交额": 459302864.0,
        "振幅": 1.66,
        "换手率": 0.66,
        "symbol": "106.EOG"
    },
    {
        "序号": 3600,
        "name": "iShares Edge MSCI USA Quality F",
        "最新价": 142.51,
        "涨跌额": 0.73,
        "涨跌幅": 0.51,
        "开盘价": 141.35,
        "最高价": 142.72,
        "最低价": 141.35,
        "昨收价": 141.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 949517.0,
        "成交额": 135071518.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.QUAL"
    },
    {
        "序号": 3601,
        "name": "思爱普",
        "最新价": 160.16,
        "涨跌额": 0.82,
        "涨跌幅": 0.51,
        "开盘价": 158.35,
        "最高价": 160.23,
        "最低价": 158.24,
        "昨收价": 159.34,
        "总市值": 196757237797.0,
        "市盈率": 33.83,
        "成交量": 685374.0,
        "成交额": 109306137.0,
        "振幅": 1.25,
        "换手率": 0.06,
        "symbol": "106.SAP"
    },
    {
        "序号": 3602,
        "name": "阿根廷ETF-Global X MSCI",
        "最新价": 50.79,
        "涨跌额": 0.26,
        "涨跌幅": 0.51,
        "开盘价": 50.21,
        "最高价": 50.83,
        "最低价": 50.11,
        "昨收价": 50.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 112411.0,
        "成交额": 5685136.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.ARGT"
    },
    {
        "序号": 3603,
        "name": "Nova Ltd",
        "最新价": 127.0,
        "涨跌额": 0.65,
        "涨跌幅": 0.51,
        "开盘价": 126.54,
        "最高价": 128.11,
        "最低价": 124.42,
        "昨收价": 126.35,
        "总市值": 3648050743.0,
        "市盈率": 27.16,
        "成交量": 84520.0,
        "成交额": 10725932.0,
        "振幅": 2.92,
        "换手率": 0.29,
        "symbol": "105.NVMI"
    },
    {
        "序号": 3604,
        "name": "Avantis U.S. Equity ETF",
        "最新价": 78.19,
        "涨跌额": 0.4,
        "涨跌幅": 0.51,
        "开盘价": 77.72,
        "最高价": 78.3,
        "最低价": 77.7,
        "昨收价": 77.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 232075.0,
        "成交额": 18115608.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.AVUS"
    },
    {
        "序号": 3605,
        "name": "SPDR MSCI USA Gender Diversity ",
        "最新价": 91.88,
        "涨跌额": 0.47,
        "涨跌幅": 0.51,
        "开盘价": 91.49,
        "最高价": 91.96,
        "最低价": 91.49,
        "昨收价": 91.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 3660.0,
        "成交额": 336181.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.SHE"
    },
    {
        "序号": 3606,
        "name": "零久期高收益债ETF-WisdomTree",
        "最新价": 21.52,
        "涨跌额": 0.11,
        "涨跌幅": 0.51,
        "开盘价": 21.43,
        "最高价": 21.52,
        "最低价": 21.41,
        "昨收价": 21.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 14505.0,
        "成交额": 311283.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "105.HYZD"
    },
    {
        "序号": 3607,
        "name": "Xtrackers S&P 500 Growth ESG ET",
        "最新价": 33.28,
        "涨跌额": 0.17,
        "涨跌幅": 0.51,
        "开盘价": 33.31,
        "最高价": 33.31,
        "最低价": 33.28,
        "昨收价": 33.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 231.0,
        "成交额": 7694.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.SNPG"
    },
    {
        "序号": 3608,
        "name": "Cohen & Steers Infrastructure F",
        "最新价": 21.54,
        "涨跌额": 0.11,
        "涨跌幅": 0.51,
        "开盘价": 21.41,
        "最高价": 21.63,
        "最低价": 21.34,
        "昨收价": 21.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 219898.0,
        "成交额": 4719312.0,
        "振幅": 1.35,
        "换手率": null,
        "symbol": "106.UTF"
    },
    {
        "序号": 3609,
        "name": "nCino Inc",
        "最新价": 29.39,
        "涨跌额": 0.15,
        "涨跌幅": 0.51,
        "开盘价": 29.24,
        "最高价": 29.59,
        "最低价": 29.04,
        "昨收价": 29.24,
        "总市值": 3324982044.0,
        "市盈率": -51.38,
        "成交量": 299289.0,
        "成交额": 8786713.0,
        "振幅": 1.88,
        "换手率": 0.26,
        "symbol": "105.NCNO"
    },
    {
        "序号": 3610,
        "name": "Pacer Cash Cows Fund of Funds E",
        "最新价": 35.3,
        "涨跌额": 0.18,
        "涨跌幅": 0.51,
        "开盘价": 35.12,
        "最高价": 35.32,
        "最低价": 35.1,
        "昨收价": 35.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 6488.0,
        "成交额": 228320.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.HERD"
    },
    {
        "序号": 3611,
        "name": "Finward Bancorp",
        "最新价": 21.61,
        "涨跌额": 0.11,
        "涨跌幅": 0.51,
        "开盘价": 21.53,
        "最高价": 22.16,
        "最低价": 21.53,
        "昨收价": 21.5,
        "总市值": 92934172.0,
        "市盈率": 8.58,
        "成交量": 13773.0,
        "成交额": 300347.0,
        "振幅": 2.93,
        "换手率": 0.32,
        "symbol": "105.FNWD"
    },
    {
        "序号": 3612,
        "name": "SIFCO Industries Inc",
        "最新价": 3.93,
        "涨跌额": 0.02,
        "涨跌幅": 0.51,
        "开盘价": 3.88,
        "最高价": 3.94,
        "最低价": 3.88,
        "昨收价": 3.91,
        "总市值": 24001092.0,
        "市盈率": -1.92,
        "成交量": 2670.0,
        "成交额": 10449.0,
        "振幅": 1.53,
        "换手率": 0.04,
        "symbol": "107.SIF"
    },
    {
        "序号": 3613,
        "name": "Lyra Therapeutics Inc",
        "最新价": 3.93,
        "涨跌额": 0.02,
        "涨跌幅": 0.51,
        "开盘价": 3.85,
        "最高价": 4.12,
        "最低价": 3.79,
        "昨收价": 3.91,
        "总市值": 206573089.0,
        "市盈率": -3.34,
        "成交量": 93601.0,
        "成交额": 372675.0,
        "振幅": 8.44,
        "换手率": 0.18,
        "symbol": "105.LYRA"
    },
    {
        "序号": 3614,
        "name": "Synovus Financial Corp Series E",
        "最新价": 23.59,
        "涨跌额": 0.12,
        "涨跌幅": 0.51,
        "开盘价": 23.4,
        "最高价": 23.59,
        "最低价": 23.38,
        "昨收价": 23.47,
        "总市值": 330260000.0,
        "市盈率": null,
        "成交量": 51160.0,
        "成交额": 1202973.0,
        "振幅": 0.89,
        "换手率": 0.37,
        "symbol": "106.SNV_E"
    },
    {
        "序号": 3615,
        "name": "CEVA Inc",
        "最新价": 21.63,
        "涨跌额": 0.11,
        "涨跌幅": 0.51,
        "开盘价": 21.37,
        "最高价": 21.96,
        "最低价": 21.37,
        "昨收价": 21.52,
        "总市值": 509626853.0,
        "市盈率": -37.18,
        "成交量": 229707.0,
        "成交额": 4973361.0,
        "振幅": 2.74,
        "换手率": 0.97,
        "symbol": "105.CEVA"
    },
    {
        "序号": 3616,
        "name": "iShares Morningstar Mid-Cap Val",
        "最新价": 64.95,
        "涨跌额": 0.33,
        "涨跌幅": 0.51,
        "开盘价": 64.53,
        "最高价": 65.04,
        "最低价": 64.53,
        "昨收价": 64.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 22579.0,
        "成交额": 1464877.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.IMCV"
    },
    {
        "序号": 3617,
        "name": "FPA Global Equity ETF",
        "最新价": 25.59,
        "涨跌额": 0.13,
        "涨跌幅": 0.51,
        "开盘价": 25.55,
        "最高价": 25.6,
        "最低价": 25.47,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 4063.0,
        "成交额": 103849.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.FPAG"
    },
    {
        "序号": 3618,
        "name": "JPMorgan Equity Focus ETF",
        "最新价": 51.19,
        "涨跌额": 0.26,
        "涨跌幅": 0.51,
        "开盘价": 50.84,
        "最高价": 51.24,
        "最低价": 50.84,
        "昨收价": 50.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 56778.0,
        "成交额": 2898925.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.JPEF"
    },
    {
        "序号": 3619,
        "name": "奎斯特诊疗",
        "最新价": 135.91,
        "涨跌额": 0.69,
        "涨跌幅": 0.51,
        "开盘价": 135.43,
        "最高价": 136.24,
        "最低价": 135.19,
        "昨收价": 135.22,
        "总市值": 15281040442.0,
        "市盈率": 20.03,
        "成交量": 719326.0,
        "成交额": 97682318.0,
        "振幅": 0.78,
        "换手率": 0.64,
        "symbol": "106.DGX"
    },
    {
        "序号": 3620,
        "name": "JPMorgan Market Expansion Enhan",
        "最新价": 51.24,
        "涨跌额": 0.26,
        "涨跌幅": 0.51,
        "开盘价": 50.84,
        "最高价": 51.44,
        "最低价": 50.84,
        "昨收价": 50.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 33271.0,
        "成交额": 1703656.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.JMEE"
    },
    {
        "序号": 3621,
        "name": "AllianzIM U.S. Large Cap 6 Mont",
        "最新价": 25.64,
        "涨跌额": 0.13,
        "涨跌幅": 0.51,
        "开盘价": 25.63,
        "最高价": 25.66,
        "最低价": 25.5,
        "昨收价": 25.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 21106.0,
        "成交额": 539751.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.SIXJ"
    },
    {
        "序号": 3622,
        "name": "Fomento Económico Mexicano SAB",
        "最新价": 128.21,
        "涨跌额": 0.65,
        "涨跌幅": 0.51,
        "开盘价": 127.68,
        "最高价": 129.01,
        "最低价": 126.32,
        "昨收价": 127.56,
        "总市值": 45876439008.0,
        "市盈率": 11.2,
        "成交量": 251244.0,
        "成交额": 32206113.0,
        "振幅": 2.11,
        "换手率": 0.07,
        "symbol": "106.FMX"
    },
    {
        "序号": 3623,
        "name": "AdvisorShares Insider Advantage",
        "最新价": 100.61,
        "涨跌额": 0.51,
        "涨跌幅": 0.51,
        "开盘价": 100.66,
        "最高价": 100.66,
        "最低价": 100.61,
        "昨收价": 100.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 686.0,
        "成交额": 69049.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.SURE"
    },
    {
        "序号": 3624,
        "name": "Bicycle Therapeutics Ltd ADR",
        "最新价": 15.79,
        "涨跌额": 0.08,
        "涨跌幅": 0.51,
        "开盘价": 15.77,
        "最高价": 16.11,
        "最低价": 15.31,
        "昨收价": 15.71,
        "总市值": 669836243.0,
        "市盈率": -4.15,
        "成交量": 599249.0,
        "成交额": 9476132.0,
        "振幅": 5.09,
        "换手率": 1.41,
        "symbol": "105.BCYC"
    },
    {
        "序号": 3625,
        "name": "iShares Core MSCI Europe ETF",
        "最新价": 53.33,
        "涨跌额": 0.27,
        "涨跌幅": 0.51,
        "开盘价": 53.03,
        "最高价": 53.43,
        "最低价": 53.03,
        "昨收价": 53.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 390991.0,
        "成交额": 20829394.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.IEUR"
    },
    {
        "序号": 3626,
        "name": "Fidus Investment Corp",
        "最新价": 19.76,
        "涨跌额": 0.1,
        "涨跌幅": 0.51,
        "开盘价": 19.7,
        "最高价": 19.81,
        "最低价": 19.67,
        "昨收价": 19.66,
        "总市值": 562240547.0,
        "市盈率": 10.14,
        "成交量": 241512.0,
        "成交额": 4769720.0,
        "振幅": 0.71,
        "换手率": 0.85,
        "symbol": "105.FDUS"
    },
    {
        "序号": 3627,
        "name": "Reservoir Media Inc",
        "最新价": 5.93,
        "涨跌额": 0.03,
        "涨跌幅": 0.51,
        "开盘价": 5.84,
        "最高价": 5.97,
        "最低价": 5.84,
        "昨收价": 5.9,
        "总市值": 384328252.0,
        "市盈率": -304.77,
        "成交量": 39342.0,
        "成交额": 232989.0,
        "振幅": 2.2,
        "换手率": 0.06,
        "symbol": "105.RSVR"
    },
    {
        "序号": 3628,
        "name": "车美仕",
        "最新价": 67.22,
        "涨跌额": 0.34,
        "涨跌幅": 0.51,
        "开盘价": 66.92,
        "最高价": 68.03,
        "最低价": 66.81,
        "昨收价": 66.88,
        "总市值": 10665678353.0,
        "市盈率": 23.52,
        "成交量": 1641879.0,
        "成交额": 110581266.0,
        "振幅": 1.82,
        "换手率": 1.03,
        "symbol": "106.KMX"
    },
    {
        "序号": 3629,
        "name": "特雷克斯",
        "最新价": 51.41,
        "涨跌额": 0.26,
        "涨跌幅": 0.51,
        "开盘价": 51.11,
        "最高价": 51.92,
        "最低价": 50.81,
        "昨收价": 51.15,
        "总市值": 3429047000.0,
        "市盈率": 7.1,
        "成交量": 666852.0,
        "成交额": 34250909.0,
        "振幅": 2.17,
        "换手率": 1.0,
        "symbol": "106.TEX"
    },
    {
        "序号": 3630,
        "name": "InfraCap Equity Income Fund ETF",
        "最新价": 23.75,
        "涨跌额": 0.12,
        "涨跌幅": 0.51,
        "开盘价": 23.77,
        "最高价": 23.8,
        "最低价": 23.5,
        "昨收价": 23.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 14387.0,
        "成交额": 340863.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.ICAP"
    },
    {
        "序号": 3631,
        "name": "Strive 1000 Value ETF",
        "最新价": 25.73,
        "涨跌额": 0.13,
        "涨跌幅": 0.51,
        "开盘价": 25.58,
        "最高价": 25.73,
        "最低价": 25.57,
        "昨收价": 25.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1535.0,
        "成交额": 39338.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.STXV"
    },
    {
        "序号": 3632,
        "name": "Invesco S&P MidCap 400 Pure Gro",
        "最新价": 39.6,
        "涨跌额": 0.2,
        "涨跌幅": 0.51,
        "开盘价": 39.78,
        "最高价": 39.78,
        "最低价": 39.49,
        "昨收价": 39.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 7177.0,
        "成交额": 284367.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.RFG"
    },
    {
        "序号": 3633,
        "name": "Endeavour Silver Corp",
        "最新价": 1.98,
        "涨跌额": 0.01,
        "涨跌幅": 0.51,
        "开盘价": 1.93,
        "最高价": 2.02,
        "最低价": 1.92,
        "昨收价": 1.97,
        "总市值": 408928652.0,
        "市盈率": 37.06,
        "成交量": 4934309.0,
        "成交额": 9788964.0,
        "振幅": 5.08,
        "换手率": 2.39,
        "symbol": "106.EXK"
    },
    {
        "序号": 3634,
        "name": "Xtrackers US National Critical ",
        "最新价": 25.75,
        "涨跌额": 0.13,
        "涨跌幅": 0.51,
        "开盘价": 25.69,
        "最高价": 25.75,
        "最低价": 25.69,
        "昨收价": 25.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 7951.0,
        "成交额": 204351.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.CRTC"
    },
    {
        "序号": 3635,
        "name": "Avantis U.S. Large Cap Equity E",
        "最新价": 53.53,
        "涨跌额": 0.27,
        "涨跌幅": 0.51,
        "开盘价": 53.48,
        "最高价": 53.53,
        "最低价": 53.38,
        "昨收价": 53.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 8530.0,
        "成交额": 455869.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.AVLC"
    },
    {
        "序号": 3636,
        "name": "WisdomTree U.S. MidCap Dividend",
        "最新价": 43.64,
        "涨跌额": 0.22,
        "涨跌幅": 0.51,
        "开盘价": 43.43,
        "最高价": 43.83,
        "最低价": 43.43,
        "昨收价": 43.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 155612.0,
        "成交额": 6788237.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.DON"
    },
    {
        "序号": 3637,
        "name": "毅博",
        "最新价": 83.42,
        "涨跌额": 0.42,
        "涨跌幅": 0.51,
        "开盘价": 83.04,
        "最高价": 84.0,
        "最低价": 82.32,
        "昨收价": 83.0,
        "总市值": 4222923778.0,
        "市盈率": 41.43,
        "成交量": 262566.0,
        "成交额": 21846135.0,
        "振幅": 2.02,
        "换手率": 0.52,
        "symbol": "105.EXPO"
    },
    {
        "序号": 3638,
        "name": "Embotelladora Andina SA ADR-A",
        "最新价": 11.92,
        "涨跌额": 0.06,
        "涨跌幅": 0.51,
        "开盘价": 12.43,
        "最高价": 12.45,
        "最低价": 11.53,
        "昨收价": 11.86,
        "总市值": 940268078.0,
        "市盈率": 10.85,
        "成交量": 1403.0,
        "成交额": 16899.0,
        "振幅": 7.76,
        "换手率": 0.0,
        "symbol": "106.AKO_A"
    },
    {
        "序号": 3639,
        "name": "Akero Therapeutics Inc",
        "最新价": 19.87,
        "涨跌额": 0.1,
        "涨跌幅": 0.51,
        "开盘价": 19.62,
        "最高价": 20.04,
        "最低价": 19.0,
        "昨收价": 19.77,
        "总市值": 1106953388.0,
        "市盈率": -9.25,
        "成交量": 1272700.0,
        "成交额": 25054291.0,
        "振幅": 5.26,
        "换手率": 2.28,
        "symbol": "105.AKRO"
    },
    {
        "序号": 3640,
        "name": "iShares Morningstar U.S. Equity",
        "最新价": 63.6,
        "涨跌额": 0.32,
        "涨跌幅": 0.51,
        "开盘价": 63.23,
        "最高价": 63.64,
        "最低价": 63.23,
        "昨收价": 63.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 68906.0,
        "成交额": 4372979.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.ILCB"
    },
    {
        "序号": 3641,
        "name": "Dimensional US Large Cap Value ",
        "最新价": 25.84,
        "涨跌额": 0.13,
        "涨跌幅": 0.51,
        "开盘价": 25.71,
        "最高价": 25.92,
        "最低价": 25.71,
        "昨收价": 25.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 338156.0,
        "成交额": 8739910.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.DFLV"
    },
    {
        "序号": 3642,
        "name": "GoodRx Holdings Inc-A",
        "最新价": 5.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.51,
        "开盘价": 5.89,
        "最高价": 5.99,
        "最低价": 5.86,
        "昨收价": 5.94,
        "总市值": 2424607777.0,
        "市盈率": 161.33,
        "成交量": 787782.0,
        "成交额": 4678396.0,
        "振幅": 2.19,
        "换手率": 0.19,
        "symbol": "105.GDRX"
    },
    {
        "序号": 3643,
        "name": "Alight Inc-A",
        "最新价": 7.96,
        "涨跌额": 0.04,
        "涨跌幅": 0.51,
        "开盘价": 7.94,
        "最高价": 8.01,
        "最低价": 7.87,
        "昨收价": 7.92,
        "总市值": 4401300456.0,
        "市盈率": -17.75,
        "成交量": 3946624.0,
        "成交额": 31369834.0,
        "振幅": 1.77,
        "换手率": 0.71,
        "symbol": "106.ALIT"
    },
    {
        "序号": 3644,
        "name": "ALPS Sector Dividend Dogs ETF",
        "最新价": 49.78,
        "涨跌额": 0.25,
        "涨跌幅": 0.5,
        "开盘价": 49.56,
        "最高价": 49.91,
        "最低价": 49.56,
        "昨收价": 49.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 43063.0,
        "成交额": 2141657.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.SDOG"
    },
    {
        "序号": 3645,
        "name": "Nationwide Nasdaq-100 Risk-Mana",
        "最新价": 21.91,
        "涨跌额": 0.11,
        "涨跌幅": 0.5,
        "开盘价": 21.77,
        "最高价": 21.91,
        "最低价": 21.74,
        "昨收价": 21.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 46739.0,
        "成交额": 1021770.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.NUSI"
    },
    {
        "序号": 3646,
        "name": "ALPS O’Shares Europe Quality D",
        "最新价": 27.91,
        "涨跌额": 0.14,
        "涨跌幅": 0.5,
        "开盘价": 27.79,
        "最高价": 27.97,
        "最低价": 27.79,
        "昨收价": 27.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 5682.0,
        "成交额": 158387.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.OEUR"
    },
    {
        "序号": 3647,
        "name": "Enterprise Financial Services C",
        "最新价": 41.9,
        "涨跌额": 0.21,
        "涨跌幅": 0.5,
        "开盘价": 41.89,
        "最高价": 42.17,
        "最低价": 41.39,
        "昨收价": 41.69,
        "总市值": 1566458777.0,
        "市盈率": 7.48,
        "成交量": 93775.0,
        "成交额": 3930110.0,
        "振幅": 1.87,
        "换手率": 0.25,
        "symbol": "105.EFSC"
    },
    {
        "序号": 3648,
        "name": "BlackRock MuniHoldings Quality ",
        "最新价": 9.99,
        "涨跌额": 0.05,
        "涨跌幅": 0.5,
        "开盘价": 9.93,
        "最高价": 10.01,
        "最低价": 9.93,
        "昨收价": 9.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 59691.0,
        "成交额": 593987.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "106.MUE"
    },
    {
        "序号": 3649,
        "name": "Ecofin Sustainable and Social I",
        "最新价": 11.99,
        "涨跌额": 0.06,
        "涨跌幅": 0.5,
        "开盘价": 11.9,
        "最高价": 11.99,
        "最低价": 11.85,
        "昨收价": 11.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 19471.0,
        "成交额": 232261.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "106.TEAF"
    },
    {
        "序号": 3650,
        "name": "Neuberger Berman Disrupters ETF",
        "最新价": 23.98,
        "涨跌额": 0.12,
        "涨跌幅": 0.5,
        "开盘价": 23.98,
        "最高价": 23.98,
        "最低价": 23.98,
        "昨收价": 23.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 229.0,
        "成交额": 5491.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NBDS"
    },
    {
        "序号": 3651,
        "name": "First Trust Long\/Short Equity",
        "最新价": 55.98,
        "涨跌额": 0.28,
        "涨跌幅": 0.5,
        "开盘价": 55.4,
        "最高价": 55.98,
        "最低价": 55.4,
        "昨收价": 55.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 69788.0,
        "成交额": 3893229.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "107.FTLS"
    },
    {
        "序号": 3652,
        "name": "StepStone Group Inc-A",
        "最新价": 26.0,
        "涨跌额": 0.13,
        "涨跌幅": 0.5,
        "开盘价": 25.83,
        "最高价": 26.16,
        "最低价": 25.74,
        "昨收价": 25.87,
        "总市值": 2869970870.0,
        "市盈率": 41.38,
        "成交量": 92318.0,
        "成交额": 2403070.0,
        "振幅": 1.62,
        "换手率": 0.08,
        "symbol": "105.STEP"
    },
    {
        "序号": 3653,
        "name": "Paltalk Inc",
        "最新价": 2.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.5,
        "开盘价": 1.92,
        "最高价": 2.04,
        "最低价": 1.91,
        "昨收价": 1.99,
        "总市值": 18444314.0,
        "市盈率": -14.43,
        "成交量": 11855.0,
        "成交额": 23753.0,
        "振幅": 6.53,
        "换手率": 0.13,
        "symbol": "105.PALT"
    },
    {
        "序号": 3654,
        "name": "Mexco Energy Corp",
        "最新价": 10.0,
        "涨跌额": 0.05,
        "涨跌幅": 0.5,
        "开盘价": 10.2,
        "最高价": 10.2,
        "最低价": 9.98,
        "昨收价": 9.95,
        "总市值": 21045000.0,
        "市盈率": 7.29,
        "成交量": 1925.0,
        "成交额": 19411.0,
        "振幅": 2.21,
        "换手率": 0.09,
        "symbol": "107.MXC"
    },
    {
        "序号": 3655,
        "name": "Powell Industries Inc",
        "最新价": 86.02,
        "涨跌额": 0.43,
        "涨跌幅": 0.5,
        "开盘价": 85.78,
        "最高价": 89.9,
        "最低价": 85.78,
        "昨收价": 85.59,
        "总市值": 1028741825.0,
        "市盈率": 18.87,
        "成交量": 202735.0,
        "成交额": 17798660.0,
        "振幅": 4.81,
        "换手率": 1.7,
        "symbol": "105.POWL"
    },
    {
        "序号": 3656,
        "name": "罗素1000成长股ETF-iShares",
        "最新价": 294.1,
        "涨跌额": 1.47,
        "涨跌幅": 0.5,
        "开盘价": 291.6,
        "最高价": 294.41,
        "最低价": 291.51,
        "昨收价": 292.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 965836.0,
        "成交额": 283428896.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.IWF"
    },
    {
        "序号": 3657,
        "name": "SCE Trust IV Pfd",
        "最新价": 22.03,
        "涨跌额": 0.11,
        "涨跌幅": 0.5,
        "开盘价": 21.92,
        "最高价": 22.07,
        "最低价": 21.64,
        "昨收价": 21.92,
        "总市值": 286390000.0,
        "市盈率": null,
        "成交量": 18062.0,
        "成交额": 395317.0,
        "振幅": 1.96,
        "换手率": 0.14,
        "symbol": "106.SCE_J"
    },
    {
        "序号": 3658,
        "name": "布朗保险",
        "最新价": 74.15,
        "涨跌额": 0.37,
        "涨跌幅": 0.5,
        "开盘价": 74.0,
        "最高价": 74.3,
        "最低价": 73.6,
        "昨收价": 73.78,
        "总市值": 21102929169.0,
        "市盈率": 28.25,
        "成交量": 821369.0,
        "成交额": 60805460.0,
        "振幅": 0.95,
        "换手率": 0.29,
        "symbol": "106.BRO"
    },
    {
        "序号": 3659,
        "name": "Dimensional US Core Equity Mark",
        "最新价": 32.08,
        "涨跌额": 0.16,
        "涨跌幅": 0.5,
        "开盘价": 31.87,
        "最高价": 32.11,
        "最低价": 31.86,
        "昨收价": 31.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 339843.0,
        "成交额": 10881554.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.DFAU"
    },
    {
        "序号": 3660,
        "name": "iShares Russell Top 200 Growth ",
        "最新价": 170.57,
        "涨跌额": 0.85,
        "涨跌幅": 0.5,
        "开盘价": 169.11,
        "最高价": 170.76,
        "最低价": 168.94,
        "昨收价": 169.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 242116.0,
        "成交额": 41220123.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.IWY"
    },
    {
        "序号": 3661,
        "name": "Gabelli Love Our Planet & Peopl",
        "最新价": 24.1,
        "涨跌额": 0.12,
        "涨跌幅": 0.5,
        "开盘价": 24.0,
        "最高价": 24.1,
        "最低价": 24.0,
        "昨收价": 23.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 978.0,
        "成交额": 23485.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.LOPP"
    },
    {
        "序号": 3662,
        "name": "STF Tactical Growth ETF",
        "最新价": 28.15,
        "涨跌额": 0.14,
        "涨跌幅": 0.5,
        "开盘价": 28.04,
        "最高价": 28.18,
        "最低价": 28.03,
        "昨收价": 28.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 17762.0,
        "成交额": 499261.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "105.TUG"
    },
    {
        "序号": 3663,
        "name": "Avantis International Large Cap",
        "最新价": 50.27,
        "涨跌额": 0.25,
        "涨跌幅": 0.5,
        "开盘价": 49.97,
        "最高价": 50.28,
        "最低价": 49.97,
        "昨收价": 50.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 18298.0,
        "成交额": 917598.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.AVIV"
    },
    {
        "序号": 3664,
        "name": "DallasNews Corp-A",
        "最新价": 4.03,
        "涨跌额": 0.02,
        "涨跌幅": 0.5,
        "开盘价": 4.07,
        "最高价": 4.09,
        "最低价": 3.98,
        "昨收价": 4.01,
        "总市值": 21570535.0,
        "市盈率": -3.06,
        "成交量": 18107.0,
        "成交额": 72591.0,
        "振幅": 2.74,
        "换手率": 0.34,
        "symbol": "105.DALN"
    },
    {
        "序号": 3665,
        "name": "Mercury Systems Inc",
        "最新价": 36.28,
        "涨跌额": 0.18,
        "涨跌幅": 0.5,
        "开盘价": 35.99,
        "最高价": 36.45,
        "最低价": 35.94,
        "昨收价": 36.1,
        "总市值": 2150571410.0,
        "市盈率": -42.41,
        "成交量": 223453.0,
        "成交额": 8091313.0,
        "振幅": 1.41,
        "换手率": 0.38,
        "symbol": "105.MRCY"
    },
    {
        "序号": 3666,
        "name": "SkyWater Technology Inc",
        "最新价": 8.07,
        "涨跌额": 0.04,
        "涨跌幅": 0.5,
        "开盘价": 8.08,
        "最高价": 8.3,
        "最低价": 7.91,
        "昨收价": 8.03,
        "总市值": 379488651.0,
        "市盈率": -16.17,
        "成交量": 781798.0,
        "成交额": 6273670.0,
        "振幅": 4.86,
        "换手率": 1.66,
        "symbol": "105.SKYT"
    },
    {
        "序号": 3667,
        "name": "Franklin U.S. Equity Index ETF",
        "最新价": 40.37,
        "涨跌额": 0.2,
        "涨跌幅": 0.5,
        "开盘价": 40.14,
        "最高价": 40.4,
        "最低价": 40.14,
        "昨收价": 40.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 40848.0,
        "成交额": 1644323.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.USPX"
    },
    {
        "序号": 3668,
        "name": "工业指数ETF-Vanguard",
        "最新价": 210.01,
        "涨跌额": 1.04,
        "涨跌幅": 0.5,
        "开盘价": 209.0,
        "最高价": 210.93,
        "最低价": 209.0,
        "昨收价": 208.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 50864.0,
        "成交额": 10676442.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.VIS"
    },
    {
        "序号": 3669,
        "name": "Vanguard S&P Small-Cap 600 ETF",
        "最新价": 92.89,
        "涨跌额": 0.46,
        "涨跌幅": 0.5,
        "开盘价": 92.35,
        "最高价": 93.35,
        "最低价": 92.15,
        "昨收价": 92.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 163167.0,
        "成交额": 15170191.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "107.VIOO"
    },
    {
        "序号": 3670,
        "name": "Alpha Architect U.S. Quantitati",
        "最新价": 38.5,
        "涨跌额": 0.19,
        "涨跌幅": 0.5,
        "开盘价": 38.44,
        "最高价": 38.66,
        "最低价": 38.34,
        "昨收价": 38.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 27253.0,
        "成交额": 1047773.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "105.QVAL"
    },
    {
        "序号": 3671,
        "name": "IQ FTSE International Equity Cu",
        "最新价": 24.32,
        "涨跌额": 0.12,
        "涨跌幅": 0.5,
        "开盘价": 24.32,
        "最高价": 24.32,
        "最低价": 24.21,
        "昨收价": 24.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 37932.0,
        "成交额": 921329.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.HFXI"
    },
    {
        "序号": 3672,
        "name": "JPMorgan Carbon Transition U.S.",
        "最新价": 62.83,
        "涨跌额": 0.31,
        "涨跌幅": 0.5,
        "开盘价": 62.83,
        "最高价": 62.83,
        "最低价": 62.83,
        "昨收价": 62.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 44.0,
        "成交额": 2764.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JCTR"
    },
    {
        "序号": 3673,
        "name": "Schwab U.S. Mid Cap ETF",
        "最新价": 71.02,
        "涨跌额": 0.35,
        "涨跌幅": 0.5,
        "开盘价": 70.58,
        "最高价": 71.28,
        "最低价": 70.53,
        "昨收价": 70.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 385056.0,
        "成交额": 27311459.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.SCHM"
    },
    {
        "序号": 3674,
        "name": "MFS Special Value Trust",
        "最新价": 4.06,
        "涨跌额": 0.02,
        "涨跌幅": 0.5,
        "开盘价": 4.03,
        "最高价": 4.08,
        "最低价": 4.03,
        "昨收价": 4.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 9304.0,
        "成交额": 37628.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "106.MFV"
    },
    {
        "序号": 3675,
        "name": "KraneShares California Carbon A",
        "最新价": 30.45,
        "涨跌额": 0.15,
        "涨跌幅": 0.5,
        "开盘价": 30.4,
        "最高价": 30.5,
        "最低价": 30.37,
        "昨收价": 30.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 24443.0,
        "成交额": 744447.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.KCCA"
    },
    {
        "序号": 3676,
        "name": "Fitell Corp",
        "最新价": 2.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.5,
        "开盘价": 2.13,
        "最高价": 2.13,
        "最低价": 1.95,
        "昨收价": 2.02,
        "总市值": 22573600.0,
        "市盈率": -14.17,
        "成交量": 5623.0,
        "成交额": 11390.0,
        "振幅": 8.91,
        "换手率": 0.05,
        "symbol": "105.FTEL"
    },
    {
        "序号": 3677,
        "name": "Adial Pharmaceuticals Inc",
        "最新价": 2.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.5,
        "开盘价": 2.07,
        "最高价": 2.22,
        "最低价": 1.85,
        "昨收价": 2.02,
        "总市值": 2472501.0,
        "市盈率": -0.41,
        "成交量": 236218.0,
        "成交额": 483706.0,
        "振幅": 18.32,
        "换手率": 19.39,
        "symbol": "105.ADIL"
    },
    {
        "序号": 3678,
        "name": "Impact Shares YWCA Women’s Emp",
        "最新价": 32.5,
        "涨跌额": 0.16,
        "涨跌幅": 0.49,
        "开盘价": 32.52,
        "最高价": 32.59,
        "最低价": 32.5,
        "昨收价": 32.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 984.0,
        "成交额": 32029.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.WOMN"
    },
    {
        "序号": 3679,
        "name": "Fidelity Nasdaq Composite Index",
        "最新价": 56.88,
        "涨跌额": 0.28,
        "涨跌幅": 0.49,
        "开盘价": 56.39,
        "最高价": 56.89,
        "最低价": 56.34,
        "昨收价": 56.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 420351.0,
        "成交额": 23747498.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "105.ONEQ"
    },
    {
        "序号": 3680,
        "name": "卡比海运",
        "最新价": 73.15,
        "涨跌额": 0.36,
        "涨跌幅": 0.49,
        "开盘价": 72.85,
        "最高价": 73.49,
        "最低价": 72.34,
        "昨收价": 72.79,
        "总市值": 4323165000.0,
        "市盈率": 21.8,
        "成交量": 414652.0,
        "成交额": 30318113.0,
        "振幅": 1.58,
        "换手率": 0.7,
        "symbol": "106.KEX"
    },
    {
        "序号": 3681,
        "name": "特斯拉",
        "最新价": 243.84,
        "涨跌额": 1.2,
        "涨跌幅": 0.49,
        "开盘价": 240.27,
        "最高价": 245.27,
        "最低价": 239.27,
        "昨收价": 242.64,
        "总市值": 775148191981.0,
        "市盈率": 72.07,
        "成交量": 103126829.0,
        "成交额": 25063991808.0,
        "振幅": 2.47,
        "换手率": 3.24,
        "symbol": "105.TSLA"
    },
    {
        "序号": 3682,
        "name": "Capital City Bank Group Inc",
        "最新价": 28.46,
        "涨跌额": 0.14,
        "涨跌幅": 0.49,
        "开盘价": 28.16,
        "最高价": 28.84,
        "最低价": 28.16,
        "昨收价": 28.32,
        "总市值": 483581904.0,
        "市盈率": 9.21,
        "成交量": 34470.0,
        "成交额": 981167.0,
        "振幅": 2.4,
        "换手率": 0.2,
        "symbol": "105.CCBG"
    },
    {
        "序号": 3683,
        "name": "Armor US Equity Index ETF",
        "最新价": 20.35,
        "涨跌额": 0.1,
        "涨跌幅": 0.49,
        "开盘价": 20.33,
        "最高价": 20.36,
        "最低价": 20.27,
        "昨收价": 20.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 40643.0,
        "成交额": 825304.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.ARMR"
    },
    {
        "序号": 3684,
        "name": "QRAFT AI-Enhanced U.S. Large Ca",
        "最新价": 32.56,
        "涨跌额": 0.16,
        "涨跌幅": 0.49,
        "开盘价": 32.27,
        "最高价": 32.56,
        "最低价": 32.27,
        "昨收价": 32.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1559.0,
        "成交额": 50641.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.AMOM"
    },
    {
        "序号": 3685,
        "name": "LG QRAFT AI-Powered U.S. Large ",
        "最新价": 26.46,
        "涨跌额": 0.13,
        "涨跌幅": 0.49,
        "开盘价": 26.46,
        "最高价": 26.46,
        "最低价": 26.46,
        "昨收价": 26.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 400.0,
        "成交额": 10585.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LQAI"
    },
    {
        "序号": 3686,
        "name": "WBI BullBear Yield 3000 ETF",
        "最新价": 22.44,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.36,
        "最高价": 22.44,
        "最低价": 22.33,
        "昨收价": 22.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 10058.0,
        "成交额": 225028.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.WBIG"
    },
    {
        "序号": 3687,
        "name": "Pure Cycle Corp",
        "最新价": 10.2,
        "涨跌额": 0.05,
        "涨跌幅": 0.49,
        "开盘价": 10.21,
        "最高价": 10.35,
        "最低价": 9.89,
        "昨收价": 10.15,
        "总市值": 245674344.0,
        "市盈率": 52.28,
        "成交量": 33005.0,
        "成交额": 335241.0,
        "振幅": 4.53,
        "换手率": 0.14,
        "symbol": "105.PCYO"
    },
    {
        "序号": 3688,
        "name": "波兰ETF-iShares MSCI",
        "最新价": 22.48,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.35,
        "最高价": 22.53,
        "最低价": 22.35,
        "昨收价": 22.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 139799.0,
        "成交额": 3138705.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.EPOL"
    },
    {
        "序号": 3689,
        "name": "Kyndryl Holdings Inc",
        "最新价": 18.41,
        "涨跌额": 0.09,
        "涨跌幅": 0.49,
        "开盘价": 18.24,
        "最高价": 18.55,
        "最低价": 18.15,
        "昨收价": 18.32,
        "总市值": 4224430381.0,
        "市盈率": -3.75,
        "成交量": 2367391.0,
        "成交额": 43400748.0,
        "振幅": 2.18,
        "换手率": 1.03,
        "symbol": "106.KD"
    },
    {
        "序号": 3690,
        "name": "Vanguard Russell 1000 Growth ET",
        "最新价": 75.7,
        "涨跌额": 0.37,
        "涨跌幅": 0.49,
        "开盘价": 75.05,
        "最高价": 75.76,
        "最低价": 75.0,
        "昨收价": 75.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 638765.0,
        "成交额": 48220876.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "105.VONG"
    },
    {
        "序号": 3691,
        "name": "Entergy Texas Inc Series A Pfd",
        "最新价": 22.51,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.4,
        "最高价": 22.55,
        "最低价": 22.27,
        "昨收价": 22.4,
        "总市值": 31514000.0,
        "市盈率": null,
        "成交量": 4099.0,
        "成交额": 91881.0,
        "振幅": 1.25,
        "换手率": 0.29,
        "symbol": "106.ETI_"
    },
    {
        "序号": 3692,
        "name": "JPMorgan BetaBuilders U.S. Mid ",
        "最新价": 79.82,
        "涨跌额": 0.39,
        "涨跌幅": 0.49,
        "开盘价": 80.1,
        "最高价": 80.1,
        "最低价": 79.5,
        "昨收价": 79.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 13305.0,
        "成交额": 1060722.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.BBMC"
    },
    {
        "序号": 3693,
        "name": "梅莎娜制药",
        "最新价": 2.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.49,
        "开盘价": 2.04,
        "最高价": 2.09,
        "最低价": 1.98,
        "昨收价": 2.04,
        "总市值": 247193334.0,
        "市盈率": -1.25,
        "成交量": 1034502.0,
        "成交额": 2094552.0,
        "振幅": 5.39,
        "换手率": 0.86,
        "symbol": "105.MRSN"
    },
    {
        "序号": 3694,
        "name": "Defiance Next Gen H2 ETF",
        "最新价": 6.15,
        "涨跌额": 0.03,
        "涨跌幅": 0.49,
        "开盘价": 6.12,
        "最高价": 6.22,
        "最低价": 6.1,
        "昨收价": 6.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 29614.0,
        "成交额": 181791.0,
        "振幅": 1.96,
        "换手率": null,
        "symbol": "107.HDRO"
    },
    {
        "序号": 3695,
        "name": "阿姆斯特朗",
        "最新价": 92.26,
        "涨跌额": 0.45,
        "涨跌幅": 0.49,
        "开盘价": 92.07,
        "最高价": 93.62,
        "最低价": 92.06,
        "昨收价": 91.81,
        "总市值": 4076073094.0,
        "市盈率": 18.05,
        "成交量": 578424.0,
        "成交额": 53599796.0,
        "振幅": 1.7,
        "换手率": 1.31,
        "symbol": "106.AWI"
    },
    {
        "序号": 3696,
        "name": "麦克森",
        "最新价": 457.89,
        "涨跌额": 2.23,
        "涨跌幅": 0.49,
        "开盘价": 457.27,
        "最高价": 458.1,
        "最低价": 450.98,
        "昨收价": 455.66,
        "总市值": 60927981257.0,
        "市盈率": 17.47,
        "成交量": 733421.0,
        "成交额": 333855184.0,
        "振幅": 1.56,
        "换手率": 0.55,
        "symbol": "106.MCK"
    },
    {
        "序号": 3697,
        "name": "Intellinetics Inc",
        "最新价": 4.11,
        "涨跌额": 0.02,
        "涨跌幅": 0.49,
        "开盘价": 4.14,
        "最高价": 4.31,
        "最低价": 4.09,
        "昨收价": 4.09,
        "总市值": 16743141.0,
        "市盈率": 25.43,
        "成交量": 76133.0,
        "成交额": 313516.0,
        "振幅": 5.38,
        "换手率": 1.87,
        "symbol": "107.INLX"
    },
    {
        "序号": 3698,
        "name": "Highland Global Allocation Fund",
        "最新价": 8.22,
        "涨跌额": 0.04,
        "涨跌幅": 0.49,
        "开盘价": 8.12,
        "最高价": 8.28,
        "最低价": 8.05,
        "昨收价": 8.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 63425.0,
        "成交额": 517640.0,
        "振幅": 2.81,
        "换手率": null,
        "symbol": "106.HGLB"
    },
    {
        "序号": 3699,
        "name": "Dimensional US Core Equity 1 ET",
        "最新价": 51.38,
        "涨跌额": 0.25,
        "涨跌幅": 0.49,
        "开盘价": 51.13,
        "最高价": 51.41,
        "最低价": 51.0,
        "昨收价": 51.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 51456.0,
        "成交额": 2639238.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.DCOR"
    },
    {
        "序号": 3700,
        "name": "Pacer US Export Leaders ETF",
        "最新价": 43.21,
        "涨跌额": 0.21,
        "涨跌幅": 0.49,
        "开盘价": 43.15,
        "最高价": 43.35,
        "最低价": 42.92,
        "昨收价": 43.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 6005.0,
        "成交额": 259280.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.PEXL"
    },
    {
        "序号": 3701,
        "name": "Coterra Energy Inc",
        "最新价": 24.7,
        "涨跌额": 0.12,
        "涨跌幅": 0.49,
        "开盘价": 24.71,
        "最高价": 24.8,
        "最低价": 24.55,
        "昨收价": 24.58,
        "总市值": 18579134743.0,
        "市盈率": 8.29,
        "成交量": 4829697.0,
        "成交额": 119188317.0,
        "振幅": 1.02,
        "换手率": 0.64,
        "symbol": "106.CTRA"
    },
    {
        "序号": 3702,
        "name": "First Trust Large Cap Core Alph",
        "最新价": 86.7,
        "涨跌额": 0.42,
        "涨跌幅": 0.49,
        "开盘价": 86.22,
        "最高价": 86.87,
        "最低价": 86.22,
        "昨收价": 86.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 95741.0,
        "成交额": 8300392.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.FEX"
    },
    {
        "序号": 3703,
        "name": "Brixmor Property Group Inc",
        "最新价": 22.71,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.56,
        "最高价": 22.73,
        "最低价": 22.21,
        "昨收价": 22.6,
        "总市值": 6826544108.0,
        "市盈率": 20.1,
        "成交量": 2746048.0,
        "成交额": 61942730.0,
        "振幅": 2.3,
        "换手率": 0.91,
        "symbol": "106.BRX"
    },
    {
        "序号": 3704,
        "name": "American Conservative Values ET",
        "最新价": 35.1,
        "涨跌额": 0.17,
        "涨跌幅": 0.49,
        "开盘价": 34.8,
        "最高价": 35.15,
        "最低价": 34.8,
        "昨收价": 34.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 3130.0,
        "成交额": 109705.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.ACVF"
    },
    {
        "序号": 3705,
        "name": "Capital Group Global Growth Equ",
        "最新价": 24.78,
        "涨跌额": 0.12,
        "涨跌幅": 0.49,
        "开盘价": 24.64,
        "最高价": 24.79,
        "最低价": 24.62,
        "昨收价": 24.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 516071.0,
        "成交额": 12763156.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.CGGO"
    },
    {
        "序号": 3706,
        "name": "阿尔法和欧米伽半导体",
        "最新价": 22.72,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.47,
        "最高价": 23.08,
        "最低价": 22.37,
        "昨收价": 22.61,
        "总市值": 631264317.0,
        "市盈率": -80.03,
        "成交量": 140690.0,
        "成交额": 3193410.0,
        "振幅": 3.14,
        "换手率": 0.51,
        "symbol": "105.AOSL"
    },
    {
        "序号": 3707,
        "name": "Janus Henderson U.S. Sustainabl",
        "最新价": 22.73,
        "涨跌额": 0.11,
        "涨跌幅": 0.49,
        "开盘价": 22.73,
        "最高价": 22.73,
        "最低价": 22.73,
        "昨收价": 22.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 31.0,
        "成交额": 704.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SSPX"
    },
    {
        "序号": 3708,
        "name": "Principal U.S. Mega-Cap Multi-F",
        "最新价": 45.47,
        "涨跌额": 0.22,
        "涨跌幅": 0.49,
        "开盘价": 45.21,
        "最高价": 45.52,
        "最低价": 45.2,
        "昨收价": 45.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 42358.0,
        "成交额": 1922258.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "105.USMC"
    },
    {
        "序号": 3709,
        "name": "WidePoint Corp",
        "最新价": 2.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.49,
        "开盘价": 2.09,
        "最高价": 2.09,
        "最低价": 2.02,
        "昨收价": 2.06,
        "总市值": 18306403.0,
        "市盈率": -1.58,
        "成交量": 23743.0,
        "成交额": 49221.0,
        "振幅": 3.4,
        "换手率": 0.27,
        "symbol": "107.WYY"
    },
    {
        "序号": 3710,
        "name": "Vox Royalty Corp",
        "最新价": 2.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.49,
        "开盘价": 2.09,
        "最高价": 2.09,
        "最低价": 2.04,
        "昨收价": 2.06,
        "总市值": 101026983.0,
        "市盈率": 273.85,
        "成交量": 33487.0,
        "成交额": 69021.0,
        "振幅": 2.43,
        "换手率": 0.07,
        "symbol": "105.VOXR"
    },
    {
        "序号": 3711,
        "name": "Astoria US Quality Kings ETF",
        "最新价": 24.84,
        "涨跌额": 0.12,
        "涨跌幅": 0.49,
        "开盘价": 24.78,
        "最高价": 24.87,
        "最低价": 24.78,
        "昨收价": 24.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 5410.0,
        "成交额": 134265.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.ROE"
    },
    {
        "序号": 3712,
        "name": "洛矶山巧克力工厂",
        "最新价": 4.14,
        "涨跌额": 0.02,
        "涨跌幅": 0.49,
        "开盘价": 4.23,
        "最高价": 4.33,
        "最低价": 4.14,
        "昨收价": 4.12,
        "总市值": 26091046.0,
        "市盈率": -6.96,
        "成交量": 10896.0,
        "成交额": 46179.0,
        "振幅": 4.61,
        "换手率": 0.17,
        "symbol": "105.RMCF"
    },
    {
        "序号": 3713,
        "name": "Franklin Limited Duration Incom",
        "最新价": 6.21,
        "涨跌额": 0.03,
        "涨跌幅": 0.49,
        "开盘价": 6.2,
        "最高价": 6.21,
        "最低价": 6.18,
        "昨收价": 6.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 92838.0,
        "成交额": 574630.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.FTF"
    },
    {
        "序号": 3714,
        "name": "US Vegan Climate ETF",
        "最新价": 41.41,
        "涨跌额": 0.2,
        "涨跌幅": 0.49,
        "开盘价": 41.24,
        "最高价": 41.46,
        "最低价": 41.22,
        "昨收价": 41.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 5274.0,
        "成交额": 217955.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.VEGN"
    },
    {
        "序号": 3715,
        "name": "FundX Aggressive ETF",
        "最新价": 55.93,
        "涨跌额": 0.27,
        "涨跌幅": 0.49,
        "开盘价": 55.93,
        "最高价": 55.93,
        "最低价": 55.93,
        "昨收价": 55.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 80.0,
        "成交额": 4474.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XNAV"
    },
    {
        "序号": 3716,
        "name": "Invesco Aerospace & Defense ETF",
        "最新价": 89.11,
        "涨跌额": 0.43,
        "涨跌幅": 0.48,
        "开盘价": 88.7,
        "最高价": 89.2,
        "最低价": 88.52,
        "昨收价": 88.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 95741.0,
        "成交额": 8518699.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.PPA"
    },
    {
        "序号": 3717,
        "name": "Kimball Electronics Inc",
        "最新价": 24.87,
        "涨跌额": 0.12,
        "涨跌幅": 0.48,
        "开盘价": 24.74,
        "最高价": 25.03,
        "最低价": 24.62,
        "昨收价": 24.75,
        "总市值": 617898831.0,
        "市盈率": 10.83,
        "成交量": 71478.0,
        "成交额": 1776651.0,
        "振幅": 1.66,
        "换手率": 0.29,
        "symbol": "105.KE"
    },
    {
        "序号": 3718,
        "name": "iShares Robotics and Artificial",
        "最新价": 33.16,
        "涨跌额": 0.16,
        "涨跌幅": 0.48,
        "开盘价": 32.81,
        "最高价": 33.3,
        "最低价": 32.8,
        "昨收价": 33.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 78028.0,
        "成交额": 2581973.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "107.IRBO"
    },
    {
        "序号": 3719,
        "name": "Capital Group International Foc",
        "最新价": 22.83,
        "涨跌额": 0.11,
        "涨跌幅": 0.48,
        "开盘价": 22.7,
        "最高价": 22.84,
        "最低价": 22.68,
        "昨收价": 22.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 846153.0,
        "成交额": 19290284.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.CGXU"
    },
    {
        "序号": 3720,
        "name": "Invesco S&P 500 QVM Multi-facto",
        "最新价": 27.0,
        "涨跌额": 0.13,
        "涨跌幅": 0.48,
        "开盘价": 27.01,
        "最高价": 27.01,
        "最低价": 27.0,
        "昨收价": 26.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 302.0,
        "成交额": 8156.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.QVML"
    },
    {
        "序号": 3721,
        "name": "iShares Core S&P U.S. Value ETF",
        "最新价": 81.01,
        "涨跌额": 0.39,
        "涨跌幅": 0.48,
        "开盘价": 80.58,
        "最高价": 81.13,
        "最低价": 80.49,
        "昨收价": 80.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 517397.0,
        "成交额": 41843101.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.IUSV"
    },
    {
        "序号": 3722,
        "name": "Immutep Ltd ADR",
        "最新价": 2.08,
        "涨跌额": 0.01,
        "涨跌幅": 0.48,
        "开盘价": 2.11,
        "最高价": 2.15,
        "最低价": 2.05,
        "昨收价": 2.07,
        "总市值": 247277588.0,
        "市盈率": -9.33,
        "成交量": 49818.0,
        "成交额": 103080.0,
        "振幅": 4.83,
        "换手率": 0.04,
        "symbol": "105.IMMP"
    },
    {
        "序号": 3723,
        "name": "成长股指数ETF-Vanguard",
        "最新价": 301.64,
        "涨跌额": 1.45,
        "涨跌幅": 0.48,
        "开盘价": 299.06,
        "最高价": 302.01,
        "最低价": 299.0,
        "昨收价": 300.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 753655.0,
        "成交额": 226912138.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.VUG"
    },
    {
        "序号": 3724,
        "name": "FundX ETF",
        "最新价": 54.12,
        "涨跌额": 0.26,
        "涨跌幅": 0.48,
        "开盘价": 53.94,
        "最高价": 54.13,
        "最低价": 53.91,
        "昨收价": 53.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 3105.0,
        "成交额": 167836.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.XCOR"
    },
    {
        "序号": 3725,
        "name": "First Trust Innovation Leaders ",
        "最新价": 18.75,
        "涨跌额": 0.09,
        "涨跌幅": 0.48,
        "开盘价": 18.67,
        "最高价": 18.81,
        "最低价": 18.67,
        "昨收价": 18.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 4892.0,
        "成交额": 91985.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.ILDR"
    },
    {
        "序号": 3726,
        "name": "FlexShares STOXX US ESG Impact ",
        "最新价": 112.52,
        "涨跌额": 0.54,
        "涨跌幅": 0.48,
        "开盘价": 111.86,
        "最高价": 112.52,
        "最低价": 111.86,
        "昨收价": 111.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 1543.0,
        "成交额": 172661.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.ESG"
    },
    {
        "序号": 3727,
        "name": "USCF Dividend Income Fund",
        "最新价": 25.02,
        "涨跌额": 0.12,
        "涨跌幅": 0.48,
        "开盘价": 25.02,
        "最高价": 25.02,
        "最低价": 25.02,
        "昨收价": 24.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 50.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.UDI"
    },
    {
        "序号": 3728,
        "name": "authID Inc",
        "最新价": 8.35,
        "涨跌额": 0.04,
        "涨跌幅": 0.48,
        "开盘价": 8.22,
        "最高价": 8.87,
        "最低价": 7.85,
        "昨收价": 8.31,
        "总市值": 78907099.0,
        "市盈率": -3.55,
        "成交量": 16053.0,
        "成交额": 137991.0,
        "振幅": 12.27,
        "换手率": 0.17,
        "symbol": "105.AUID"
    },
    {
        "序号": 3729,
        "name": "Angel Oak Mortgage REIT Inc",
        "最新价": 10.44,
        "涨跌额": 0.05,
        "涨跌幅": 0.48,
        "开盘价": 10.39,
        "最高价": 10.5,
        "最低价": 10.33,
        "昨收价": 10.39,
        "总市值": 260637461.0,
        "市盈率": -70.77,
        "成交量": 29681.0,
        "成交额": 309946.0,
        "振幅": 1.64,
        "换手率": 0.12,
        "symbol": "106.AOMR"
    },
    {
        "序号": 3730,
        "name": "空气化工",
        "最新价": 263.27,
        "涨跌额": 1.26,
        "涨跌幅": 0.48,
        "开盘价": 262.78,
        "最高价": 264.72,
        "最低价": 262.54,
        "昨收价": 262.01,
        "总市值": 58505993730.0,
        "市盈率": 25.44,
        "成交量": 744090.0,
        "成交额": 196092283.0,
        "振幅": 0.83,
        "换手率": 0.33,
        "symbol": "106.APD"
    },
    {
        "序号": 3731,
        "name": "T. Rowe Price International Equ",
        "最新价": 25.12,
        "涨跌额": 0.12,
        "涨跌幅": 0.48,
        "开盘价": 24.99,
        "最高价": 25.12,
        "最低价": 24.99,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2298.0,
        "成交额": 57560.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.TOUS"
    },
    {
        "序号": 3732,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 37.72,
        "涨跌额": 0.18,
        "涨跌幅": 0.48,
        "开盘价": 37.53,
        "最高价": 37.76,
        "最低价": 37.52,
        "昨收价": 37.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 22766.0,
        "成交额": 857090.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.FDEC"
    },
    {
        "序号": 3733,
        "name": "BTD Capital Fund",
        "最新价": 25.15,
        "涨跌额": 0.12,
        "涨跌幅": 0.48,
        "开盘价": 25.15,
        "最高价": 25.15,
        "最低价": 25.15,
        "昨收价": 25.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 250.0,
        "成交额": 6286.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.DIP"
    },
    {
        "序号": 3734,
        "name": "FRP Holdings Inc",
        "最新价": 58.7,
        "涨跌额": 0.28,
        "涨跌幅": 0.48,
        "开盘价": 57.37,
        "最高价": 58.91,
        "最低价": 57.37,
        "昨收价": 58.42,
        "总市值": 556306005.0,
        "市盈率": 107.44,
        "成交量": 6616.0,
        "成交额": 387585.0,
        "振幅": 2.64,
        "换手率": 0.07,
        "symbol": "105.FRPH"
    },
    {
        "序号": 3735,
        "name": "Tortoise Pipeline & Energy Fund",
        "最新价": 27.29,
        "涨跌额": 0.13,
        "涨跌幅": 0.48,
        "开盘价": 27.09,
        "最高价": 27.44,
        "最低价": 26.44,
        "昨收价": 27.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 48365.0,
        "成交额": 1319801.0,
        "振幅": 3.68,
        "换手率": null,
        "symbol": "106.TTP"
    },
    {
        "序号": 3736,
        "name": "Nuveen ESG Mid-Cap Growth ETF",
        "最新价": 39.89,
        "涨跌额": 0.19,
        "涨跌幅": 0.48,
        "开盘价": 39.57,
        "最高价": 39.96,
        "最低价": 39.57,
        "昨收价": 39.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 28899.0,
        "成交额": 1150805.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.NUMG"
    },
    {
        "序号": 3737,
        "name": "全市场指数ETF-Vanguard",
        "最新价": 228.85,
        "涨跌额": 1.09,
        "涨跌幅": 0.48,
        "开盘价": 227.36,
        "最高价": 229.09,
        "最低价": 227.27,
        "昨收价": 227.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 2740235.0,
        "成交额": 625902944.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.VTI"
    },
    {
        "序号": 3738,
        "name": "Vanguard ESG U.S. Stock ETF",
        "最新价": 81.91,
        "涨跌额": 0.39,
        "涨跌幅": 0.48,
        "开盘价": 81.27,
        "最高价": 82.01,
        "最低价": 81.27,
        "昨收价": 81.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 195961.0,
        "成交额": 16015356.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.ESGV"
    },
    {
        "序号": 3739,
        "name": "Eagle Materials Inc",
        "最新价": 189.06,
        "涨跌额": 0.9,
        "涨跌幅": 0.48,
        "开盘价": 187.16,
        "最高价": 190.82,
        "最低价": 187.16,
        "昨收价": 188.16,
        "总市值": 6595715234.0,
        "市盈率": 13.49,
        "成交量": 210584.0,
        "成交额": 39834994.0,
        "振幅": 1.95,
        "换手率": 0.6,
        "symbol": "106.EXP"
    },
    {
        "序号": 3740,
        "name": "SPDR Portfolio S&P 400 Mid Cap ",
        "最新价": 46.22,
        "涨跌额": 0.22,
        "涨跌幅": 0.48,
        "开盘价": 45.97,
        "最高价": 46.41,
        "最低价": 45.94,
        "昨收价": 46.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 649374.0,
        "成交额": 29992084.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.SPMD"
    },
    {
        "序号": 3741,
        "name": "iShares ESG Screened S&P Mid-Ca",
        "最新价": 35.72,
        "涨跌额": 0.17,
        "涨跌幅": 0.48,
        "开盘价": 35.73,
        "最高价": 35.79,
        "最低价": 35.58,
        "昨收价": 35.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 23359.0,
        "成交额": 833905.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.XJH"
    },
    {
        "序号": 3742,
        "name": "Franklin U.S. Core Dividend Til",
        "最新价": 35.8,
        "涨跌额": 0.17,
        "涨跌幅": 0.48,
        "开盘价": 35.68,
        "最高价": 35.8,
        "最低价": 35.68,
        "昨收价": 35.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 532.0,
        "成交额": 19018.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.UDIV"
    },
    {
        "序号": 3743,
        "name": "Capri Holdings Ltd",
        "最新价": 48.44,
        "涨跌额": 0.23,
        "涨跌幅": 0.48,
        "开盘价": 48.18,
        "最高价": 48.61,
        "最低价": 48.1,
        "昨收价": 48.21,
        "总市值": 5629730514.0,
        "市盈率": 17.11,
        "成交量": 355094.0,
        "成交额": 17177591.0,
        "振幅": 1.06,
        "换手率": 0.31,
        "symbol": "106.CPRI"
    },
    {
        "序号": 3744,
        "name": "Pacer Lunt MidCap Multi-Factor ",
        "最新价": 35.81,
        "涨跌额": 0.17,
        "涨跌幅": 0.48,
        "开盘价": 35.68,
        "最高价": 35.81,
        "最低价": 35.68,
        "昨收价": 35.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 60646.0,
        "成交额": 2165055.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.PAMC"
    },
    {
        "序号": 3745,
        "name": "Pacer Trendpilot US Mid Cap ETF",
        "最新价": 31.64,
        "涨跌额": 0.15,
        "涨跌幅": 0.48,
        "开盘价": 31.45,
        "最高价": 31.75,
        "最低价": 31.45,
        "昨收价": 31.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 25896.0,
        "成交额": 819071.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.PTMC"
    },
    {
        "序号": 3746,
        "name": "A-Mark Precious Metals Inc",
        "最新价": 29.55,
        "涨跌额": 0.14,
        "涨跌幅": 0.48,
        "开盘价": 29.4,
        "最高价": 29.91,
        "最低价": 29.33,
        "昨收价": 29.41,
        "总市值": 685884577.0,
        "市盈率": 5.27,
        "成交量": 230173.0,
        "成交额": 6800641.0,
        "振幅": 1.97,
        "换手率": 0.99,
        "symbol": "105.AMRK"
    },
    {
        "序号": 3747,
        "name": "ProShares Large Cap Core Plus",
        "最新价": 52.81,
        "涨跌额": 0.25,
        "涨跌幅": 0.48,
        "开盘价": 52.64,
        "最高价": 52.91,
        "最低价": 52.6,
        "昨收价": 52.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 48935.0,
        "成交额": 2586765.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.CSM"
    },
    {
        "序号": 3748,
        "name": "科恩-斯蒂尔斯金融",
        "最新价": 63.38,
        "涨跌额": 0.3,
        "涨跌幅": 0.48,
        "开盘价": 63.02,
        "最高价": 64.13,
        "最低价": 62.75,
        "昨收价": 63.08,
        "总市值": 3114406243.0,
        "市盈率": 23.64,
        "成交量": 162374.0,
        "成交额": 10259965.0,
        "振幅": 2.19,
        "换手率": 0.33,
        "symbol": "106.CNS"
    },
    {
        "序号": 3749,
        "name": "Pacer Trendpilot US Large Cap E",
        "最新价": 42.27,
        "涨跌额": 0.2,
        "涨跌幅": 0.48,
        "开盘价": 42.02,
        "最高价": 42.3,
        "最低价": 42.02,
        "昨收价": 42.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 241508.0,
        "成交额": 10182585.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.PTLC"
    },
    {
        "序号": 3750,
        "name": "做多美元ETF-WisdomTree",
        "最新价": 27.48,
        "涨跌额": 0.13,
        "涨跌幅": 0.48,
        "开盘价": 27.44,
        "最高价": 27.48,
        "最低价": 27.2,
        "昨收价": 27.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 163998.0,
        "成交额": 4495053.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.USDU"
    },
    {
        "序号": 3751,
        "name": "Precision Drilling Corp",
        "最新价": 52.85,
        "涨跌额": 0.25,
        "涨跌幅": 0.48,
        "开盘价": 53.35,
        "最高价": 53.96,
        "最低价": 52.81,
        "昨收价": 52.6,
        "总市值": 719169006.0,
        "市盈率": 6.61,
        "成交量": 65940.0,
        "成交额": 3515018.0,
        "振幅": 2.19,
        "换手率": 0.48,
        "symbol": "106.PDS"
    },
    {
        "序号": 3752,
        "name": "PennyMac Mortgage Investment Tr",
        "最新价": 23.26,
        "涨跌额": 0.11,
        "涨跌幅": 0.48,
        "开盘价": 23.2,
        "最高价": 23.29,
        "最低价": 23.04,
        "昨收价": 23.15,
        "总市值": 106996000.0,
        "市盈率": null,
        "成交量": 6080.0,
        "成交额": 140722.0,
        "振幅": 1.08,
        "换手率": 0.13,
        "symbol": "106.PMT_A"
    },
    {
        "序号": 3753,
        "name": "Vanguard S&P Small-Cap 600 Valu",
        "最新价": 82.47,
        "涨跌额": 0.39,
        "涨跌幅": 0.48,
        "开盘价": 81.77,
        "最高价": 82.79,
        "最低价": 81.77,
        "昨收价": 82.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 47519.0,
        "成交额": 3911289.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "107.VIOV"
    },
    {
        "序号": 3754,
        "name": "Invesco S&P International Devel",
        "最新价": 27.49,
        "涨跌额": 0.13,
        "涨跌幅": 0.48,
        "开盘价": 27.37,
        "最高价": 27.49,
        "最低价": 27.33,
        "昨收价": 27.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 16455.0,
        "成交额": 451282.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.IDHQ"
    },
    {
        "序号": 3755,
        "name": "Brandes International ETF",
        "最新价": 27.49,
        "涨跌额": 0.13,
        "涨跌幅": 0.48,
        "开盘价": 27.52,
        "最高价": 27.52,
        "最低价": 27.35,
        "昨收价": 27.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 14995.0,
        "成交额": 410912.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.BINV"
    },
    {
        "序号": 3756,
        "name": "标普500价值股ETF-iShares",
        "最新价": 167.07,
        "涨跌额": 0.79,
        "涨跌幅": 0.48,
        "开盘价": 166.12,
        "最高价": 167.36,
        "最低价": 166.1,
        "昨收价": 166.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 502394.0,
        "成交额": 83838359.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.IVE"
    },
    {
        "序号": 3757,
        "name": "American Century Sustainable Gr",
        "最新价": 44.45,
        "涨跌额": 0.21,
        "涨跌幅": 0.47,
        "开盘价": 44.44,
        "最高价": 44.47,
        "最低价": 44.43,
        "昨收价": 44.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 2032.0,
        "成交额": 90324.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.ESGY"
    },
    {
        "序号": 3758,
        "name": "1895 Bancorp of Wisconsin Inc",
        "最新价": 6.35,
        "涨跌额": 0.03,
        "涨跌幅": 0.47,
        "开盘价": 6.3,
        "最高价": 6.5,
        "最低价": 6.3,
        "昨收价": 6.32,
        "总市值": 38796239.0,
        "市盈率": -8.65,
        "成交量": 9240.0,
        "成交额": 58463.0,
        "振幅": 3.16,
        "换手率": 0.15,
        "symbol": "105.BCOW"
    },
    {
        "序号": 3759,
        "name": "固瑞克",
        "最新价": 82.63,
        "涨跌额": 0.39,
        "涨跌幅": 0.47,
        "开盘价": 82.05,
        "最高价": 82.97,
        "最低价": 82.05,
        "昨收价": 82.24,
        "总市值": 13946958885.0,
        "市盈率": 26.68,
        "成交量": 468079.0,
        "成交额": 38700504.0,
        "振幅": 1.12,
        "换手率": 0.28,
        "symbol": "106.GGG"
    },
    {
        "序号": 3760,
        "name": "Dimensional US High Profitabili",
        "最新价": 27.55,
        "涨跌额": 0.13,
        "涨跌幅": 0.47,
        "开盘价": 27.42,
        "最高价": 27.58,
        "最低价": 27.38,
        "昨收价": 27.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 897972.0,
        "成交额": 24696447.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.DUHP"
    },
    {
        "序号": 3761,
        "name": "SPDR Russell 1000 Momentum Focu",
        "最新价": 99.61,
        "涨跌额": 0.47,
        "涨跌幅": 0.47,
        "开盘价": 99.27,
        "最高价": 99.61,
        "最低价": 99.27,
        "昨收价": 99.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 141.0,
        "成交额": 13997.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.ONEO"
    },
    {
        "序号": 3762,
        "name": "DRDGOLD Ltd ADR",
        "最新价": 8.48,
        "涨跌额": 0.04,
        "涨跌幅": 0.47,
        "开盘价": 8.34,
        "最高价": 8.51,
        "最低价": 8.22,
        "昨收价": 8.44,
        "总市值": 733171227.0,
        "市盈率": 10.47,
        "成交量": 379969.0,
        "成交额": 3191436.0,
        "振幅": 3.44,
        "换手率": 0.44,
        "symbol": "106.DRD"
    },
    {
        "序号": 3763,
        "name": "VanEck Morningstar SMID Moat ET",
        "最新价": 29.69,
        "涨跌额": 0.14,
        "涨跌幅": 0.47,
        "开盘价": 29.58,
        "最高价": 29.8,
        "最低价": 29.58,
        "昨收价": 29.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 33345.0,
        "成交额": 990331.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.SMOT"
    },
    {
        "序号": 3764,
        "name": "iShares Dow Jones U.S. ETF",
        "最新价": 112.48,
        "涨跌额": 0.53,
        "涨跌幅": 0.47,
        "开盘价": 111.76,
        "最高价": 112.58,
        "最低价": 111.72,
        "昨收价": 111.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 18890.0,
        "成交额": 2120534.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.IYY"
    },
    {
        "序号": 3765,
        "name": "Columbia Research Enhanced Valu",
        "最新价": 21.24,
        "涨跌额": 0.1,
        "涨跌幅": 0.47,
        "开盘价": 21.21,
        "最高价": 21.24,
        "最低价": 21.21,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 934.0,
        "成交额": 19810.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.REVS"
    },
    {
        "序号": 3766,
        "name": "Franklin Focused Growth ETF",
        "最新价": 27.62,
        "涨跌额": 0.13,
        "涨跌幅": 0.47,
        "开盘价": 27.55,
        "最高价": 27.64,
        "最低价": 27.54,
        "昨收价": 27.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 3527.0,
        "成交额": 97360.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.FFOG"
    },
    {
        "序号": 3767,
        "name": "Franklin FTSE South Korea ETF",
        "最新价": 21.27,
        "涨跌额": 0.1,
        "涨跌幅": 0.47,
        "开盘价": 21.25,
        "最高价": 21.44,
        "最低价": 21.19,
        "昨收价": 21.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 35314.0,
        "成交额": 750868.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.FLKR"
    },
    {
        "序号": 3768,
        "name": "Golden Star Acquisition Corp Un",
        "最新价": 10.64,
        "涨跌额": 0.05,
        "涨跌幅": 0.47,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.63,
        "昨收价": 10.59,
        "总市值": 95036480.0,
        "市盈率": 122.38,
        "成交量": 3056.0,
        "成交额": 32514.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.GODNU"
    },
    {
        "序号": 3769,
        "name": "WisdomTree Continuous Commodity",
        "最新价": 17.03,
        "涨跌额": 0.08,
        "涨跌幅": 0.47,
        "开盘价": 16.89,
        "最高价": 17.1,
        "最低价": 16.89,
        "昨收价": 16.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 35766.0,
        "成交额": 608538.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "107.GCC"
    },
    {
        "序号": 3770,
        "name": "SES AI Corp-A",
        "最新价": 2.13,
        "涨跌额": 0.01,
        "涨跌幅": 0.47,
        "开盘价": 2.13,
        "最高价": 2.17,
        "最低价": 2.08,
        "昨收价": 2.12,
        "总市值": 749183562.0,
        "市盈率": -14.61,
        "成交量": 399740.0,
        "成交额": 852065.0,
        "振幅": 4.25,
        "换手率": 0.11,
        "symbol": "106.SES"
    },
    {
        "序号": 3771,
        "name": "木勒工业",
        "最新价": 42.6,
        "涨跌额": 0.2,
        "涨跌幅": 0.47,
        "开盘价": 42.36,
        "最高价": 43.0,
        "最低价": 42.25,
        "昨收价": 42.4,
        "总市值": 4835100000.0,
        "市盈率": 7.77,
        "成交量": 371798.0,
        "成交额": 15832739.0,
        "振幅": 1.77,
        "换手率": 0.33,
        "symbol": "106.MLI"
    },
    {
        "序号": 3772,
        "name": "Dimensional US Sustainability C",
        "最新价": 29.83,
        "涨跌额": 0.14,
        "涨跌幅": 0.47,
        "开盘价": 29.62,
        "最高价": 29.87,
        "最低价": 29.62,
        "昨收价": 29.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 33313.0,
        "成交额": 993304.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.DFSU"
    },
    {
        "序号": 3773,
        "name": "Fidelity Enhanced Large Cap Cor",
        "最新价": 25.58,
        "涨跌额": 0.12,
        "涨跌幅": 0.47,
        "开盘价": 25.47,
        "最高价": 25.64,
        "最低价": 25.45,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 115187.0,
        "成交额": 2943126.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.FELC"
    },
    {
        "序号": 3774,
        "name": "ETC 6 Meridian Small Cap Equity",
        "最新价": 44.78,
        "涨跌额": 0.21,
        "涨跌幅": 0.47,
        "开盘价": 44.92,
        "最高价": 44.92,
        "最低价": 44.75,
        "昨收价": 44.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 1565.0,
        "成交额": 70088.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.SIXS"
    },
    {
        "序号": 3775,
        "name": "卡尼金融储蓄",
        "最新价": 8.53,
        "涨跌额": 0.04,
        "涨跌幅": 0.47,
        "开盘价": 8.48,
        "最高价": 8.58,
        "最低价": 8.37,
        "昨收价": 8.49,
        "总市值": 550518071.0,
        "市盈率": 16.14,
        "成交量": 210860.0,
        "成交额": 1797701.0,
        "振幅": 2.47,
        "换手率": 0.33,
        "symbol": "105.KRNY"
    },
    {
        "序号": 3776,
        "name": "Alpha Dog ETF",
        "最新价": 21.33,
        "涨跌额": 0.1,
        "涨跌幅": 0.47,
        "开盘价": 21.25,
        "最高价": 21.36,
        "最低价": 21.23,
        "昨收价": 21.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 3545.0,
        "成交额": 75343.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.RUFF"
    },
    {
        "序号": 3777,
        "name": "Foundations Dynamic Core ETF",
        "最新价": 10.67,
        "涨跌额": 0.05,
        "涨跌幅": 0.47,
        "开盘价": 10.67,
        "最高价": 10.67,
        "最低价": 10.67,
        "昨收价": 10.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 60.0,
        "成交额": 639.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FDCE"
    },
    {
        "序号": 3778,
        "name": "The Communication Services Sele",
        "最新价": 70.48,
        "涨跌额": 0.33,
        "涨跌幅": 0.47,
        "开盘价": 69.56,
        "最高价": 70.66,
        "最低价": 69.56,
        "昨收价": 70.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 5282507.0,
        "成交额": 371441664.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.XLC"
    },
    {
        "序号": 3779,
        "name": "SPDR Portfolio S&P 500 Value ET",
        "最新价": 44.86,
        "涨跌额": 0.21,
        "涨跌幅": 0.47,
        "开盘价": 44.58,
        "最高价": 44.91,
        "最低价": 44.57,
        "昨收价": 44.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 3271969.0,
        "成交额": 146442313.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.SPYV"
    },
    {
        "序号": 3780,
        "name": "Rush Enterprises Inc-A",
        "最新价": 40.6,
        "涨跌额": 0.19,
        "涨跌幅": 0.47,
        "开盘价": 40.19,
        "最高价": 40.9,
        "最低价": 40.18,
        "昨收价": 40.41,
        "总市值": 3227308332.0,
        "市盈率": 8.79,
        "成交量": 316121.0,
        "成交额": 12835781.0,
        "振幅": 1.78,
        "换手率": 0.51,
        "symbol": "105.RUSHA"
    },
    {
        "序号": 3781,
        "name": "Invesco Agriculture Commodity S",
        "最新价": 32.06,
        "涨跌额": 0.15,
        "涨跌幅": 0.47,
        "开盘价": 32.02,
        "最高价": 32.1,
        "最低价": 32.02,
        "昨收价": 31.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 3144.0,
        "成交额": 100793.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "105.PDBA"
    },
    {
        "序号": 3782,
        "name": "Invesco S&P SmallCap 600 Revenu",
        "最新价": 38.48,
        "涨跌额": 0.18,
        "涨跌幅": 0.47,
        "开盘价": 38.26,
        "最高价": 38.7,
        "最低价": 38.23,
        "昨收价": 38.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 181972.0,
        "成交额": 7000083.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.RWJ"
    },
    {
        "序号": 3783,
        "name": "美国全市场ETF-Schwab",
        "最新价": 53.45,
        "涨跌额": 0.25,
        "涨跌幅": 0.47,
        "开盘价": 53.12,
        "最高价": 53.51,
        "最低价": 53.08,
        "昨收价": 53.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 781366.0,
        "成交额": 41678399.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.SCHB"
    },
    {
        "序号": 3784,
        "name": "iPath Select MLP ETN",
        "最新价": 21.38,
        "涨跌额": 0.1,
        "涨跌幅": 0.47,
        "开盘价": 21.29,
        "最高价": 21.44,
        "最低价": 21.29,
        "昨收价": 21.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 10592.0,
        "成交额": 226555.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.ATMP"
    },
    {
        "序号": 3785,
        "name": "BlackRock Capital Allocation Te",
        "最新价": 14.97,
        "涨跌额": 0.07,
        "涨跌幅": 0.47,
        "开盘价": 14.93,
        "最高价": 14.97,
        "最低价": 14.89,
        "昨收价": 14.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 417603.0,
        "成交额": 6229162.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "106.BCAT"
    },
    {
        "序号": 3786,
        "name": "First Trust NASDAQ-100- Technol",
        "最新价": 162.71,
        "涨跌额": 0.76,
        "涨跌幅": 0.47,
        "开盘价": 161.17,
        "最高价": 163.08,
        "最低价": 161.17,
        "昨收价": 161.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 78479.0,
        "成交额": 12765978.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "105.QTEC"
    },
    {
        "序号": 3787,
        "name": "尤纳姆",
        "最新价": 42.83,
        "涨跌额": 0.2,
        "涨跌幅": 0.47,
        "开盘价": 42.68,
        "最高价": 42.97,
        "最低价": 42.63,
        "昨收价": 42.63,
        "总市值": 8353805018.0,
        "市盈率": 7.27,
        "成交量": 855984.0,
        "成交额": 36642088.0,
        "振幅": 0.8,
        "换手率": 0.44,
        "symbol": "106.UNM"
    },
    {
        "序号": 3788,
        "name": "ProShares UltraShort Utilities",
        "最新价": 12.85,
        "涨跌额": 0.06,
        "涨跌幅": 0.47,
        "开盘价": 12.88,
        "最高价": 12.96,
        "最低价": 12.83,
        "昨收价": 12.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 2483.0,
        "成交额": 31949.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.SDP"
    },
    {
        "序号": 3789,
        "name": "New Mountain Finance Corp",
        "最新价": 12.85,
        "涨跌额": 0.06,
        "涨跌幅": 0.47,
        "开盘价": 12.83,
        "最高价": 12.88,
        "最低价": 12.77,
        "昨收价": 12.79,
        "总市值": 1303499490.0,
        "市盈率": 10.6,
        "成交量": 334545.0,
        "成交额": 4294632.0,
        "振幅": 0.86,
        "换手率": 0.33,
        "symbol": "105.NMFC"
    },
    {
        "序号": 3790,
        "name": "Fidelity MSCI Communication Ser",
        "最新价": 42.86,
        "涨跌额": 0.2,
        "涨跌幅": 0.47,
        "开盘价": 42.38,
        "最高价": 42.97,
        "最低价": 42.38,
        "昨收价": 42.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 101081.0,
        "成交额": 4331096.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "107.FCOM"
    },
    {
        "序号": 3791,
        "name": "ProShares Global Listed Private",
        "最新价": 27.87,
        "涨跌额": 0.13,
        "涨跌幅": 0.47,
        "开盘价": 27.8,
        "最高价": 27.87,
        "最低价": 27.74,
        "昨收价": 27.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 403.0,
        "成交额": 11200.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.PEX"
    },
    {
        "序号": 3792,
        "name": "荷兰ETF-iShares MSCI",
        "最新价": 42.9,
        "涨跌额": 0.2,
        "涨跌幅": 0.47,
        "开盘价": 42.71,
        "最高价": 43.02,
        "最低价": 42.68,
        "昨收价": 42.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 187571.0,
        "成交额": 8036697.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.EWN"
    },
    {
        "序号": 3793,
        "name": "Virtus Artificial Intelligence ",
        "最新价": 17.16,
        "涨跌额": 0.08,
        "涨跌幅": 0.47,
        "开盘价": 17.1,
        "最高价": 17.2,
        "最低价": 17.05,
        "昨收价": 17.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 118482.0,
        "成交额": 2027913.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "106.AIO"
    },
    {
        "序号": 3794,
        "name": "雷诺士",
        "最新价": 420.62,
        "涨跌额": 1.96,
        "涨跌幅": 0.47,
        "开盘价": 420.25,
        "最高价": 424.12,
        "最低价": 417.19,
        "昨收价": 418.66,
        "总市值": 14948719130.0,
        "市盈率": 27.68,
        "成交量": 263823.0,
        "成交额": 111041705.0,
        "振幅": 1.66,
        "换手率": 0.74,
        "symbol": "106.LII"
    },
    {
        "序号": 3795,
        "name": "Avantis Responsible U.S. Equity",
        "最新价": 53.71,
        "涨跌额": 0.25,
        "涨跌幅": 0.47,
        "开盘价": 53.51,
        "最高价": 53.75,
        "最低价": 53.49,
        "昨收价": 53.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 14210.0,
        "成交额": 761208.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.AVSU"
    },
    {
        "序号": 3796,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 30.14,
        "涨跌额": 0.14,
        "涨跌幅": 0.47,
        "开盘价": 30.1,
        "最高价": 30.14,
        "最低价": 30.03,
        "昨收价": 30.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2882.0,
        "成交额": 86660.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.JANT"
    },
    {
        "序号": 3797,
        "name": "WisdomTree International Qualit",
        "最新价": 34.48,
        "涨跌额": 0.16,
        "涨跌幅": 0.47,
        "开盘价": 34.26,
        "最高价": 34.54,
        "最低价": 34.26,
        "昨收价": 34.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 56344.0,
        "成交额": 1941612.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.IQDG"
    },
    {
        "序号": 3798,
        "name": "Vanguard Small-Cap Value ETF",
        "最新价": 170.25,
        "涨跌额": 0.79,
        "涨跌幅": 0.47,
        "开盘价": 169.36,
        "最高价": 170.92,
        "最低价": 169.2,
        "昨收价": 169.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 386236.0,
        "成交额": 65701124.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.VBR"
    },
    {
        "序号": 3799,
        "name": "First Trust Growth Strength ETF",
        "最新价": 25.87,
        "涨跌额": 0.12,
        "涨跌幅": 0.47,
        "开盘价": 25.68,
        "最高价": 25.9,
        "最低价": 25.68,
        "昨收价": 25.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 14825.0,
        "成交额": 382351.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "105.FTGS"
    },
    {
        "序号": 3800,
        "name": "Foundations Dynamic Growth ETF",
        "最新价": 10.78,
        "涨跌额": 0.05,
        "涨跌幅": 0.47,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.78,
        "昨收价": 10.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 45.0,
        "成交额": 484.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FDGR"
    },
    {
        "序号": 3801,
        "name": "ProShares MSCI Europe Dividend ",
        "最新价": 45.28,
        "涨跌额": 0.21,
        "涨跌幅": 0.47,
        "开盘价": 45.28,
        "最高价": 45.28,
        "最低价": 45.28,
        "昨收价": 45.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 271.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EUDV"
    },
    {
        "序号": 3802,
        "name": "迪克体育用品",
        "最新价": 138.09,
        "涨跌额": 0.64,
        "涨跌幅": 0.47,
        "开盘价": 137.17,
        "最高价": 138.82,
        "最低价": 136.58,
        "昨收价": 137.45,
        "总市值": 11288064449.0,
        "市盈率": 11.45,
        "成交量": 794300.0,
        "成交额": 109542918.0,
        "振幅": 1.63,
        "换手率": 0.97,
        "symbol": "106.DKS"
    },
    {
        "序号": 3803,
        "name": "LAMF Global Ventures Corp I-A",
        "最新价": 10.79,
        "涨跌额": 0.05,
        "涨跌幅": 0.47,
        "开盘价": 10.74,
        "最高价": 10.79,
        "最低价": 10.74,
        "昨收价": 10.74,
        "总市值": 134788130.0,
        "市盈率": -81.83,
        "成交量": 1862.0,
        "成交额": 20037.0,
        "振幅": 0.47,
        "换手率": 0.01,
        "symbol": "105.LGVC"
    },
    {
        "序号": 3804,
        "name": "Ares Dynamic Credit Allocation ",
        "最新价": 12.95,
        "涨跌额": 0.06,
        "涨跌幅": 0.47,
        "开盘价": 12.91,
        "最高价": 13.0,
        "最低价": 12.86,
        "昨收价": 12.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 122358.0,
        "成交额": 1582662.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.ARDC"
    },
    {
        "序号": 3805,
        "name": "Flame Acquisition Corp-A",
        "最新价": 10.8,
        "涨跌额": 0.05,
        "涨跌幅": 0.47,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.75,
        "昨收价": 10.75,
        "总市值": 143555566.0,
        "市盈率": -9.54,
        "成交量": 23312.0,
        "成交额": 251583.0,
        "振幅": 1.4,
        "换手率": 0.18,
        "symbol": "106.FLME"
    },
    {
        "序号": 3806,
        "name": "波奇宠物",
        "最新价": 2.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.47,
        "开盘价": 2.13,
        "最高价": 2.23,
        "最低价": 2.1,
        "昨收价": 2.15,
        "总市值": 10185046.0,
        "市盈率": -0.68,
        "成交量": 6271.0,
        "成交额": 13326.0,
        "振幅": 6.05,
        "换手率": 0.13,
        "symbol": "107.BQ"
    },
    {
        "序号": 3807,
        "name": "Pacer CFRA-Stovall Equal Weight",
        "最新价": 34.6,
        "涨跌额": 0.16,
        "涨跌幅": 0.46,
        "开盘价": 34.53,
        "最高价": 34.6,
        "最低价": 34.53,
        "昨收价": 34.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 1531.0,
        "成交额": 52921.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.SZNE"
    },
    {
        "序号": 3808,
        "name": "Vanguard Mega Cap ETF",
        "最新价": 164.39,
        "涨跌额": 0.76,
        "涨跌幅": 0.46,
        "开盘价": 163.3,
        "最高价": 164.52,
        "最低价": 163.3,
        "昨收价": 163.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 68022.0,
        "成交额": 11155527.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.MGC"
    },
    {
        "序号": 3809,
        "name": "Global X DAX Germany ETF",
        "最新价": 30.29,
        "涨跌额": 0.14,
        "涨跌幅": 0.46,
        "开盘价": 30.24,
        "最高价": 30.29,
        "最低价": 30.18,
        "昨收价": 30.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 6264.0,
        "成交额": 189488.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.DAX"
    },
    {
        "序号": 3810,
        "name": "SPDR S&P 1500 Momentum Tilt ETF",
        "最新价": 194.86,
        "涨跌额": 0.9,
        "涨跌幅": 0.46,
        "开盘价": 194.88,
        "最高价": 194.88,
        "最低价": 194.86,
        "昨收价": 193.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 5612.0,
        "成交额": 1093562.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.MMTM"
    },
    {
        "序号": 3811,
        "name": "The Future Fund Active ETF",
        "最新价": 19.49,
        "涨跌额": 0.09,
        "涨跌幅": 0.46,
        "开盘价": 19.48,
        "最高价": 19.52,
        "最低价": 19.37,
        "昨收价": 19.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 537.0,
        "成交额": 10453.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.FFND"
    },
    {
        "序号": 3812,
        "name": "WestRock Co",
        "最新价": 41.16,
        "涨跌额": 0.19,
        "涨跌幅": 0.46,
        "开盘价": 40.98,
        "最高价": 41.38,
        "最低价": 40.98,
        "昨收价": 40.97,
        "总市值": 10556268156.0,
        "市盈率": -6.4,
        "成交量": 2190000.0,
        "成交额": 90217636.0,
        "振幅": 0.98,
        "换手率": 0.85,
        "symbol": "106.WRK"
    },
    {
        "序号": 3813,
        "name": "ProShares S&P 500 Ex-Health Car",
        "最新价": 97.53,
        "涨跌额": 0.45,
        "涨跌幅": 0.46,
        "开盘价": 97.23,
        "最高价": 97.53,
        "最低价": 97.23,
        "昨收价": 97.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 223.0,
        "成交额": 21682.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.SPXV"
    },
    {
        "序号": 3814,
        "name": "AdvisorShares Focused Equity ET",
        "最新价": 56.41,
        "涨跌额": 0.26,
        "涨跌幅": 0.46,
        "开盘价": 56.22,
        "最高价": 56.73,
        "最低价": 56.15,
        "昨收价": 56.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 7237.0,
        "成交额": 408277.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.CWS"
    },
    {
        "序号": 3815,
        "name": "StarTek Inc",
        "最新价": 4.34,
        "涨跌额": 0.02,
        "涨跌幅": 0.46,
        "开盘价": 4.33,
        "最高价": 4.35,
        "最低价": 4.32,
        "昨收价": 4.32,
        "总市值": 175100907.0,
        "市盈率": -8.15,
        "成交量": 390187.0,
        "成交额": 1691484.0,
        "振幅": 0.69,
        "换手率": 0.97,
        "symbol": "106.SRT"
    },
    {
        "序号": 3816,
        "name": "Moderna Inc",
        "最新价": 80.32,
        "涨跌额": 0.37,
        "涨跌幅": 0.46,
        "开盘价": 79.16,
        "最高价": 81.82,
        "最低价": 78.76,
        "昨收价": 79.95,
        "总市值": 30624730559.0,
        "市盈率": -8.84,
        "成交量": 2931622.0,
        "成交额": 236017830.0,
        "振幅": 3.83,
        "换手率": 0.77,
        "symbol": "105.MRNA"
    },
    {
        "序号": 3817,
        "name": "狄乐百货",
        "最新价": 364.83,
        "涨跌额": 1.68,
        "涨跌幅": 0.46,
        "开盘价": 362.02,
        "最高价": 374.67,
        "最低价": 362.02,
        "昨收价": 363.15,
        "总市值": 5918208050.0,
        "市盈率": 7.61,
        "成交量": 78023.0,
        "成交额": 28686447.0,
        "振幅": 3.48,
        "换手率": 0.48,
        "symbol": "106.DDS"
    },
    {
        "序号": 3818,
        "name": "BlackRock MuniHoldings Californ",
        "最新价": 10.86,
        "涨跌额": 0.05,
        "涨跌幅": 0.46,
        "开盘价": 10.78,
        "最高价": 10.89,
        "最低价": 10.78,
        "昨收价": 10.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 440163.0,
        "成交额": 4775783.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.MUC"
    },
    {
        "序号": 3819,
        "name": "罗素中型股ETF-iShares",
        "最新价": 73.85,
        "涨跌额": 0.34,
        "涨跌幅": 0.46,
        "开盘价": 73.42,
        "最高价": 74.06,
        "最低价": 73.42,
        "昨收价": 73.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 1639891.0,
        "成交额": 121030367.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.IWR"
    },
    {
        "序号": 3820,
        "name": "The Goldman Sachs Group Inc Ser",
        "最新价": 21.73,
        "涨跌额": 0.1,
        "涨跌幅": 0.46,
        "开盘价": 21.59,
        "最高价": 21.73,
        "最低价": 21.53,
        "昨收价": 21.63,
        "总市值": 1173398.0,
        "市盈率": null,
        "成交量": 61950.0,
        "成交额": 1341457.0,
        "振幅": 0.92,
        "换手率": 114.72,
        "symbol": "106.GS_D"
    },
    {
        "序号": 3821,
        "name": "WisdomTree U.S. Value Fund",
        "最新价": 65.2,
        "涨跌额": 0.3,
        "涨跌幅": 0.46,
        "开盘价": 65.08,
        "最高价": 65.24,
        "最低价": 64.87,
        "昨收价": 64.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 6266.0,
        "成交额": 407821.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.WTV"
    },
    {
        "序号": 3822,
        "name": "Invesco S&P 500 Quality ETF",
        "最新价": 52.18,
        "涨跌额": 0.24,
        "涨跌幅": 0.46,
        "开盘价": 51.93,
        "最高价": 52.22,
        "最低价": 51.83,
        "昨收价": 51.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 696859.0,
        "成交额": 36279336.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.SPHQ"
    },
    {
        "序号": 3823,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 34.81,
        "涨跌额": 0.16,
        "涨跌幅": 0.46,
        "开盘价": 34.6,
        "最高价": 34.82,
        "最低价": 34.6,
        "昨收价": 34.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 1609.0,
        "成交额": 55912.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.BJUN"
    },
    {
        "序号": 3824,
        "name": "Invesco Next Gen Media and Gami",
        "最新价": 37.03,
        "涨跌额": 0.17,
        "涨跌幅": 0.46,
        "开盘价": 36.78,
        "最高价": 37.03,
        "最低价": 36.75,
        "昨收价": 36.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 5757.0,
        "成交额": 212671.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.GGME"
    },
    {
        "序号": 3825,
        "name": "VSE Corp",
        "最新价": 61.03,
        "涨跌额": 0.28,
        "涨跌幅": 0.46,
        "开盘价": 60.48,
        "最高价": 61.32,
        "最低价": 60.25,
        "昨收价": 60.75,
        "总市值": 961057048.0,
        "市盈率": 29.68,
        "成交量": 40169.0,
        "成交额": 2447862.0,
        "振幅": 1.76,
        "换手率": 0.26,
        "symbol": "105.VSEC"
    },
    {
        "序号": 3826,
        "name": "Kurv Yield Premium Strategy Net",
        "最新价": 26.19,
        "涨跌额": 0.12,
        "涨跌幅": 0.46,
        "开盘价": 26.19,
        "最高价": 26.19,
        "最低价": 26.19,
        "昨收价": 26.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 654.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NFLP"
    },
    {
        "序号": 3827,
        "name": "Rexford Industrial Realty Inc",
        "最新价": 52.42,
        "涨跌额": 0.24,
        "涨跌幅": 0.46,
        "开盘价": 51.95,
        "最高价": 52.52,
        "最低价": 51.82,
        "昨收价": 52.18,
        "总市值": 11065721462.0,
        "市盈率": 51.01,
        "成交量": 1260282.0,
        "成交额": 65825534.0,
        "振幅": 1.34,
        "换手率": 0.6,
        "symbol": "106.REXR"
    },
    {
        "序号": 3828,
        "name": "YETI Holdings Inc",
        "最新价": 45.9,
        "涨跌额": 0.21,
        "涨跌幅": 0.46,
        "开盘价": 45.37,
        "最高价": 46.36,
        "最低价": 45.36,
        "昨收价": 45.69,
        "总市值": 3986547238.0,
        "市盈率": 62.73,
        "成交量": 672248.0,
        "成交额": 30906326.0,
        "振幅": 2.19,
        "换手率": 0.77,
        "symbol": "106.YETI"
    },
    {
        "序号": 3829,
        "name": "JPMorgan International Growth E",
        "最新价": 56.84,
        "涨跌额": 0.26,
        "涨跌幅": 0.46,
        "开盘价": 56.61,
        "最高价": 56.88,
        "最低价": 56.61,
        "昨收价": 56.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 9244.0,
        "成交额": 524716.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.JIG"
    },
    {
        "序号": 3830,
        "name": "YieldMax AAPL Option Income Str",
        "最新价": 19.68,
        "涨跌额": 0.09,
        "涨跌幅": 0.46,
        "开盘价": 19.55,
        "最高价": 19.69,
        "最低价": 19.55,
        "昨收价": 19.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 30137.0,
        "成交额": 591484.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.APLY"
    },
    {
        "序号": 3831,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 37.18,
        "涨跌额": 0.17,
        "涨跌幅": 0.46,
        "开盘价": 37.02,
        "最高价": 37.18,
        "最低价": 36.99,
        "昨收价": 37.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 4387.0,
        "成交额": 162302.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.BAPR"
    },
    {
        "序号": 3832,
        "name": "JPMorgan BetaBuilders Europe ET",
        "最新价": 54.68,
        "涨跌额": 0.25,
        "涨跌幅": 0.46,
        "开盘价": 54.38,
        "最高价": 54.77,
        "最低价": 54.37,
        "昨收价": 54.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 228776.0,
        "成交额": 12499354.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.BBEU"
    },
    {
        "序号": 3833,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 39.42,
        "涨跌额": 0.18,
        "涨跌幅": 0.46,
        "开盘价": 39.21,
        "最高价": 39.45,
        "最低价": 39.21,
        "昨收价": 39.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 20577.0,
        "成交额": 810205.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.BJAN"
    },
    {
        "序号": 3834,
        "name": "CHS Inc Class B Series 4 Pfd",
        "最新价": 26.3,
        "涨跌额": 0.12,
        "涨跌幅": 0.46,
        "开盘价": 26.26,
        "最高价": 26.3,
        "最低价": 26.09,
        "昨收价": 26.18,
        "总市值": 544410000.0,
        "市盈率": null,
        "成交量": 17941.0,
        "成交额": 470062.0,
        "振幅": 0.8,
        "换手率": 0.09,
        "symbol": "105.CHSCL"
    },
    {
        "序号": 3835,
        "name": "可选消费ETF-SPDR",
        "最新价": 173.19,
        "涨跌额": 0.79,
        "涨跌幅": 0.46,
        "开盘价": 171.53,
        "最高价": 173.52,
        "最低价": 171.4,
        "昨收价": 172.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 4048343.0,
        "成交额": 699671568.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.XLY"
    },
    {
        "序号": 3836,
        "name": "T. Rowe Price Small-Mid Cap ETF",
        "最新价": 26.32,
        "涨跌额": 0.12,
        "涨跌幅": 0.46,
        "开盘价": 26.35,
        "最高价": 26.35,
        "最低价": 26.21,
        "昨收价": 26.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 1440.0,
        "成交额": 37862.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.TMSL"
    },
    {
        "序号": 3837,
        "name": "Reverb ETF",
        "最新价": 24.17,
        "涨跌额": 0.11,
        "涨跌幅": 0.46,
        "开盘价": 24.17,
        "最高价": 24.17,
        "最低价": 24.17,
        "昨收价": 24.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 21.0,
        "成交额": 507.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RVRB"
    },
    {
        "序号": 3838,
        "name": "Inspire 100 ETF",
        "最新价": 32.96,
        "涨跌额": 0.15,
        "涨跌幅": 0.46,
        "开盘价": 32.8,
        "最高价": 33.07,
        "最低价": 32.8,
        "昨收价": 32.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 23715.0,
        "成交额": 780994.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.BIBL"
    },
    {
        "序号": 3839,
        "name": "Adaptive Core ETF",
        "最新价": 19.79,
        "涨跌额": 0.09,
        "涨跌幅": 0.46,
        "开盘价": 19.69,
        "最高价": 19.82,
        "最低价": 19.69,
        "昨收价": 19.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 32264.0,
        "成交额": 637709.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.RULE"
    },
    {
        "序号": 3840,
        "name": "Xtrackers S&P MidCap 400 ESG ET",
        "最新价": 26.39,
        "涨跌额": 0.12,
        "涨跌幅": 0.46,
        "开盘价": 26.3,
        "最高价": 26.39,
        "最低价": 26.3,
        "昨收价": 26.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 404.0,
        "成交额": 10625.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.MIDE"
    },
    {
        "序号": 3841,
        "name": "BlackRock U.S. Carbon Transitio",
        "最新价": 50.61,
        "涨跌额": 0.23,
        "涨跌幅": 0.46,
        "开盘价": 50.43,
        "最高价": 50.63,
        "最低价": 50.34,
        "昨收价": 50.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 52738.0,
        "成交额": 2663417.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.LCTU"
    },
    {
        "序号": 3842,
        "name": "iShares ESG Screened S&P 500 ET",
        "最新价": 35.22,
        "涨跌额": 0.16,
        "涨跌幅": 0.46,
        "开盘价": 35.06,
        "最高价": 35.22,
        "最低价": 35.05,
        "昨收价": 35.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 3596.0,
        "成交额": 126261.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.XVV"
    },
    {
        "序号": 3843,
        "name": "Bank First Corp",
        "最新价": 88.16,
        "涨跌额": 0.4,
        "涨跌幅": 0.46,
        "开盘价": 88.0,
        "最高价": 89.4,
        "最低价": 84.85,
        "昨收价": 87.76,
        "总市值": 913856951.0,
        "市盈率": 17.42,
        "成交量": 21302.0,
        "成交额": 1877274.0,
        "振幅": 5.18,
        "换手率": 0.21,
        "symbol": "105.BFC"
    },
    {
        "序号": 3844,
        "name": "SP Funds S&P 500 Sharia Industr",
        "最新价": 33.09,
        "涨跌额": 0.15,
        "涨跌幅": 0.46,
        "开盘价": 32.85,
        "最高价": 33.09,
        "最低价": 32.85,
        "昨收价": 32.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 178918.0,
        "成交额": 5908150.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.SPUS"
    },
    {
        "序号": 3845,
        "name": "MetroCity Bankshares Inc",
        "最新价": 22.06,
        "涨跌额": 0.1,
        "涨跌幅": 0.46,
        "开盘价": 21.99,
        "最高价": 22.49,
        "最低价": 21.96,
        "昨收价": 21.96,
        "总市值": 556046235.0,
        "市盈率": 11.02,
        "成交量": 19445.0,
        "成交额": 430268.0,
        "振幅": 2.41,
        "换手率": 0.08,
        "symbol": "105.MCBS"
    },
    {
        "序号": 3846,
        "name": "道明银行",
        "最新价": 59.62,
        "涨跌额": 0.27,
        "涨跌幅": 0.45,
        "开盘价": 59.51,
        "最高价": 60.11,
        "最低价": 59.09,
        "昨收价": 59.35,
        "总市值": 106762870203.0,
        "市盈率": 13.62,
        "成交量": 4397828.0,
        "成交额": 262268518.0,
        "振幅": 1.72,
        "换手率": 0.25,
        "symbol": "106.TD"
    },
    {
        "序号": 3847,
        "name": "Invesco S&P MidCap 400 QVM Mult",
        "最新价": 24.31,
        "涨跌额": 0.11,
        "涨跌幅": 0.45,
        "开盘价": 24.31,
        "最高价": 24.31,
        "最低价": 24.31,
        "昨收价": 24.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 97.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.QVMM"
    },
    {
        "序号": 3848,
        "name": "Wahed FTSE USA Shariah ETF",
        "最新价": 44.27,
        "涨跌额": 0.2,
        "涨跌幅": 0.45,
        "开盘价": 43.95,
        "最高价": 44.27,
        "最低价": 43.94,
        "昨收价": 44.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 24548.0,
        "成交额": 1083199.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.HLAL"
    },
    {
        "序号": 3849,
        "name": "First Trust Active Factor Large",
        "最新价": 26.57,
        "涨跌额": 0.12,
        "涨跌幅": 0.45,
        "开盘价": 26.55,
        "最高价": 26.57,
        "最低价": 26.55,
        "昨收价": 26.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 590.0,
        "成交额": 15664.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.AFLG"
    },
    {
        "序号": 3850,
        "name": "宏利金融",
        "最新价": 19.94,
        "涨跌额": 0.09,
        "涨跌幅": 0.45,
        "开盘价": 19.85,
        "最高价": 20.01,
        "最低价": 19.84,
        "昨收价": 19.85,
        "总市值": 36021985650.0,
        "市盈率": 3.59,
        "成交量": 2160702.0,
        "成交额": 43071131.0,
        "振幅": 0.86,
        "换手率": 0.12,
        "symbol": "106.MFC"
    },
    {
        "序号": 3851,
        "name": "First Trust Small Cap Value Alp",
        "最新价": 50.97,
        "涨跌额": 0.23,
        "涨跌幅": 0.45,
        "开盘价": 50.83,
        "最高价": 51.18,
        "最低价": 50.68,
        "昨收价": 50.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 10460.0,
        "成交额": 532447.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "105.FYT"
    },
    {
        "序号": 3852,
        "name": "Global X Adaptive U.S. Risk Man",
        "最新价": 28.81,
        "涨跌额": 0.13,
        "涨跌幅": 0.45,
        "开盘价": 28.73,
        "最高价": 28.81,
        "最低价": 28.7,
        "昨收价": 28.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 8209.0,
        "成交额": 236189.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.ONOF"
    },
    {
        "序号": 3853,
        "name": "Goldman Sachs MarketBeta U.S. 1",
        "最新价": 39.95,
        "涨跌额": 0.18,
        "涨跌幅": 0.45,
        "开盘价": 39.95,
        "最高价": 39.95,
        "最低价": 39.95,
        "昨收价": 39.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 199.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GUSA"
    },
    {
        "序号": 3854,
        "name": "盛德财富",
        "最新价": 2.22,
        "涨跌额": 0.01,
        "涨跌幅": 0.45,
        "开盘价": 2.49,
        "最高价": 3.24,
        "最低价": 2.2,
        "昨收价": 2.21,
        "总市值": 20313000.0,
        "市盈率": 70.47,
        "成交量": 1379752.0,
        "成交额": 3780078.0,
        "振幅": 47.06,
        "换手率": 15.08,
        "symbol": "105.PWM"
    },
    {
        "序号": 3855,
        "name": "ETC 6 Meridian Mega Cap Equity ",
        "最新价": 35.57,
        "涨跌额": 0.16,
        "涨跌幅": 0.45,
        "开盘价": 35.68,
        "最高价": 35.68,
        "最低价": 35.5,
        "昨收价": 35.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 300.0,
        "成交额": 10677.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.SIXA"
    },
    {
        "序号": 3856,
        "name": "ProShares S&P Technology Divide",
        "最新价": 66.72,
        "涨跌额": 0.3,
        "涨跌幅": 0.45,
        "开盘价": 66.63,
        "最高价": 66.79,
        "最低价": 66.36,
        "昨收价": 66.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 12011.0,
        "成交额": 800611.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.TDV"
    },
    {
        "序号": 3857,
        "name": "SPDR S&P 1500 Value Tilt ETF",
        "最新价": 153.68,
        "涨跌额": 0.69,
        "涨跌幅": 0.45,
        "开盘价": 153.1,
        "最高价": 153.83,
        "最低价": 153.1,
        "昨收价": 152.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 3096.0,
        "成交额": 474988.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.VLU"
    },
    {
        "序号": 3858,
        "name": "Global X Alternative Income ETF",
        "最新价": 11.14,
        "涨跌额": 0.05,
        "涨跌幅": 0.45,
        "开盘价": 11.19,
        "最高价": 11.19,
        "最低价": 11.06,
        "昨收价": 11.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 15892.0,
        "成交额": 176778.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "105.ALTY"
    },
    {
        "序号": 3859,
        "name": "Invesco KBW Regional Banking ET",
        "最新价": 51.26,
        "涨跌额": 0.23,
        "涨跌幅": 0.45,
        "开盘价": 50.86,
        "最高价": 51.78,
        "最低价": 50.83,
        "昨收价": 51.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 12591.0,
        "成交额": 647085.0,
        "振幅": 1.86,
        "换手率": null,
        "symbol": "105.KBWR"
    },
    {
        "序号": 3860,
        "name": "Invesco ESG NASDAQ 100 ETF",
        "最新价": 26.76,
        "涨跌额": 0.12,
        "涨跌幅": 0.45,
        "开盘价": 26.5,
        "最高价": 26.77,
        "最低价": 26.5,
        "昨收价": 26.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 57073.0,
        "成交额": 1527571.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "105.QQMG"
    },
    {
        "序号": 3861,
        "name": "瞻博网络",
        "最新价": 28.99,
        "涨跌额": 0.13,
        "涨跌幅": 0.45,
        "开盘价": 29.01,
        "最高价": 29.11,
        "最低价": 28.69,
        "昨收价": 28.86,
        "总市值": 9243997206.0,
        "市盈率": 25.24,
        "成交量": 3017343.0,
        "成交额": 87302540.0,
        "振幅": 1.46,
        "换手率": 0.95,
        "symbol": "106.JNPR"
    },
    {
        "序号": 3862,
        "name": "Jacob Forward ETF",
        "最新价": 8.92,
        "涨跌额": 0.04,
        "涨跌幅": 0.45,
        "开盘价": 8.78,
        "最高价": 8.96,
        "最低价": 8.78,
        "昨收价": 8.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 5499.0,
        "成交额": 48746.0,
        "振幅": 2.03,
        "换手率": null,
        "symbol": "107.JFWD"
    },
    {
        "序号": 3863,
        "name": "Liberty Energy Inc-A",
        "最新价": 17.86,
        "涨跌额": 0.08,
        "涨跌幅": 0.45,
        "开盘价": 17.92,
        "最高价": 18.21,
        "最低价": 17.62,
        "昨收价": 17.78,
        "总市值": 3011344720.0,
        "市盈率": 4.88,
        "成交量": 2833248.0,
        "成交额": 50531635.0,
        "振幅": 3.32,
        "换手率": 1.68,
        "symbol": "106.LBRT"
    },
    {
        "序号": 3864,
        "name": "拉夫劳伦",
        "最新价": 131.76,
        "涨跌额": 0.59,
        "涨跌幅": 0.45,
        "开盘价": 130.77,
        "最高价": 132.47,
        "最低价": 130.77,
        "昨收价": 131.17,
        "总市值": 8516054753.0,
        "市盈率": 16.14,
        "成交量": 388711.0,
        "成交额": 51179183.0,
        "振幅": 1.3,
        "换手率": 0.6,
        "symbol": "106.RL"
    },
    {
        "序号": 3865,
        "name": "Revolution Medicines Inc",
        "最新价": 24.59,
        "涨跌额": 0.11,
        "涨跌幅": 0.45,
        "开盘价": 24.26,
        "最高价": 24.9,
        "最低价": 23.88,
        "昨收价": 24.48,
        "总市值": 2694195850.0,
        "市盈率": -8.13,
        "成交量": 1352483.0,
        "成交额": 33264562.0,
        "振幅": 4.17,
        "换手率": 1.23,
        "symbol": "105.RVMD"
    },
    {
        "序号": 3866,
        "name": "First Trust Indxx NextG ETF",
        "最新价": 73.77,
        "涨跌额": 0.33,
        "涨跌幅": 0.45,
        "开盘价": 73.71,
        "最高价": 73.77,
        "最低价": 73.45,
        "昨收价": 73.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 4899.0,
        "成交额": 360501.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.NXTG"
    },
    {
        "序号": 3867,
        "name": "Tactical Advantage ETF",
        "最新价": 20.14,
        "涨跌额": 0.09,
        "涨跌幅": 0.45,
        "开盘价": 20.02,
        "最高价": 20.19,
        "最低价": 20.02,
        "昨收价": 20.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1465.0,
        "成交额": 29512.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.FDAT"
    },
    {
        "序号": 3868,
        "name": "Vital Energy Inc",
        "最新价": 42.57,
        "涨跌额": 0.19,
        "涨跌幅": 0.45,
        "开盘价": 42.22,
        "最高价": 43.34,
        "最低价": 42.16,
        "昨收价": 42.38,
        "总市值": 1172424116.0,
        "市盈率": 2.2,
        "成交量": 692688.0,
        "成交额": 29538300.0,
        "振幅": 2.78,
        "换手率": 2.52,
        "symbol": "106.VTLE"
    },
    {
        "序号": 3869,
        "name": "罗素1000ETF-iShares",
        "最新价": 253.2,
        "涨跌额": 1.13,
        "涨跌幅": 0.45,
        "开盘价": 251.7,
        "最高价": 253.6,
        "最低价": 251.48,
        "昨收价": 252.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1092650.0,
        "成交额": 276251088.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.IWB"
    },
    {
        "序号": 3870,
        "name": "Engine No. 1 Transform 500 ETF",
        "最新价": 53.78,
        "涨跌额": 0.24,
        "涨跌幅": 0.45,
        "开盘价": 53.32,
        "最高价": 53.83,
        "最低价": 53.32,
        "昨收价": 53.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 9299.0,
        "成交额": 498712.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.VOTE"
    },
    {
        "序号": 3871,
        "name": "SPDR S&P 500 ESG ETF",
        "最新价": 44.82,
        "涨跌额": 0.2,
        "涨跌幅": 0.45,
        "开盘价": 44.56,
        "最高价": 44.85,
        "最低价": 44.56,
        "昨收价": 44.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 25237.0,
        "成交额": 1129601.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.EFIV"
    },
    {
        "序号": 3872,
        "name": "PIMCO RAFI Dynamic Multi-Factor",
        "最新价": 40.34,
        "涨跌额": 0.18,
        "涨跌幅": 0.45,
        "开盘价": 40.06,
        "最高价": 40.34,
        "最低价": 40.06,
        "昨收价": 40.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1203.0,
        "成交额": 48304.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.MFUS"
    },
    {
        "序号": 3873,
        "name": "TFS Financial Corp",
        "最新价": 13.45,
        "涨跌额": 0.06,
        "涨跌幅": 0.45,
        "开盘价": 13.39,
        "最高价": 13.54,
        "最低价": 13.37,
        "昨收价": 13.39,
        "总市值": 3770830877.0,
        "市盈率": 50.11,
        "成交量": 268665.0,
        "成交额": 3612403.0,
        "振幅": 1.27,
        "换手率": 0.1,
        "symbol": "105.TFSL"
    },
    {
        "序号": 3874,
        "name": "Motley Fool Next Index ETF",
        "最新价": 15.71,
        "涨跌额": 0.07,
        "涨跌幅": 0.45,
        "开盘价": 15.58,
        "最高价": 15.74,
        "最低价": 15.58,
        "昨收价": 15.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 3909.0,
        "成交额": 61358.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.TMFX"
    },
    {
        "序号": 3875,
        "name": "Sotherly Hotels Inc Series D Pf",
        "最新价": 20.2,
        "涨跌额": 0.09,
        "涨跌幅": 0.45,
        "开盘价": 20.33,
        "最高价": 20.33,
        "最低价": 20.2,
        "昨收价": 20.11,
        "总市值": 23494620.0,
        "市盈率": null,
        "成交量": 3814.0,
        "成交额": 77255.0,
        "振幅": 0.65,
        "换手率": 0.33,
        "symbol": "105.SOHON"
    },
    {
        "序号": 3876,
        "name": "First Trust Managed Futures Str",
        "最新价": 47.16,
        "涨跌额": 0.21,
        "涨跌幅": 0.45,
        "开盘价": 46.83,
        "最高价": 47.38,
        "最低价": 45.36,
        "昨收价": 46.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 113122.0,
        "成交额": 5283375.0,
        "振幅": 4.3,
        "换手率": null,
        "symbol": "107.FMF"
    },
    {
        "序号": 3877,
        "name": "JPMorgan BetaBuilders U.S. Equi",
        "最新价": 83.14,
        "涨跌额": 0.37,
        "涨跌幅": 0.45,
        "开盘价": 82.57,
        "最高价": 83.25,
        "最低价": 82.57,
        "昨收价": 82.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 144310.0,
        "成交额": 11974479.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.BBUS"
    },
    {
        "序号": 3878,
        "name": "American Century Focused Dynami",
        "最新价": 67.43,
        "涨跌额": 0.3,
        "涨跌幅": 0.45,
        "开盘价": 67.23,
        "最高价": 67.64,
        "最低价": 67.23,
        "昨收价": 67.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 6952.0,
        "成交额": 468847.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.FDG"
    },
    {
        "序号": 3879,
        "name": "标普中型股ETF-iShares",
        "最新价": 263.15,
        "涨跌额": 1.17,
        "涨跌幅": 0.45,
        "开盘价": 261.73,
        "最高价": 264.21,
        "最低价": 261.51,
        "昨收价": 261.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 895620.0,
        "成交额": 235465079.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.IJH"
    },
    {
        "序号": 3880,
        "name": "Willamette Valley Vineyards Inc",
        "最新价": 4.5,
        "涨跌额": 0.02,
        "涨跌幅": 0.45,
        "开盘价": 4.41,
        "最高价": 4.5,
        "最低价": 4.36,
        "昨收价": 4.48,
        "总市值": 41867946.0,
        "市盈率": null,
        "成交量": 2218.0,
        "成交额": 9781.0,
        "振幅": 3.13,
        "换手率": 0.02,
        "symbol": "105.WVVIP"
    },
    {
        "序号": 3881,
        "name": "MedAvail Holdings Inc",
        "最新价": 2.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.45,
        "开盘价": 2.3,
        "最高价": 2.4,
        "最低价": 2.25,
        "昨收价": 2.24,
        "总市值": 3631300.0,
        "市盈率": -0.08,
        "成交量": 3964.0,
        "成交额": 9148.0,
        "振幅": 6.7,
        "换手率": 0.25,
        "symbol": "105.MDVL"
    },
    {
        "序号": 3882,
        "name": "Axon Enterprise Inc",
        "最新价": 236.38,
        "涨跌额": 1.05,
        "涨跌幅": 0.45,
        "开盘价": 235.41,
        "最高价": 238.04,
        "最低价": 234.83,
        "昨收价": 235.33,
        "总市值": 17712858972.0,
        "市盈率": 121.21,
        "成交量": 281805.0,
        "成交额": 66630326.0,
        "振幅": 1.36,
        "换手率": 0.38,
        "symbol": "105.AXON"
    },
    {
        "序号": 3883,
        "name": "WisdomTree U.S. Total Dividend ",
        "最新价": 63.09,
        "涨跌额": 0.28,
        "涨跌幅": 0.45,
        "开盘价": 62.92,
        "最高价": 63.2,
        "最低价": 62.81,
        "昨收价": 62.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 27291.0,
        "成交额": 1719747.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.DTD"
    },
    {
        "序号": 3884,
        "name": "纳斯达克100ETF-ProShares",
        "最新价": 392.17,
        "涨跌额": 1.74,
        "涨跌幅": 0.45,
        "开盘价": 388.67,
        "最高价": 392.62,
        "最低价": 388.48,
        "昨收价": 390.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 46687525.0,
        "成交额": 18259770368.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "105.QQQ"
    },
    {
        "序号": 3885,
        "name": "iShares Core S&P Total U.S. Sto",
        "最新价": 101.44,
        "涨跌额": 0.45,
        "涨跌幅": 0.45,
        "开盘价": 100.78,
        "最高价": 101.59,
        "最低价": 100.78,
        "昨收价": 100.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 1203683.0,
        "成交额": 121917786.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.ITOT"
    },
    {
        "序号": 3886,
        "name": "Republic Bancorp Inc-A",
        "最新价": 49.61,
        "涨跌额": 0.22,
        "涨跌幅": 0.45,
        "开盘价": 47.94,
        "最高价": 49.79,
        "最低价": 47.94,
        "昨收价": 49.39,
        "总市值": 961373090.0,
        "市盈率": 10.77,
        "成交量": 19164.0,
        "成交额": 948068.0,
        "振幅": 3.75,
        "换手率": 0.1,
        "symbol": "105.RBCAA"
    },
    {
        "序号": 3887,
        "name": "Defiance S&P 500 Enhanced Optio",
        "最新价": 18.04,
        "涨跌额": 0.08,
        "涨跌幅": 0.45,
        "开盘价": 17.99,
        "最高价": 18.04,
        "最低价": 17.95,
        "昨收价": 17.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 141872.0,
        "成交额": 2550988.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.JEPY"
    },
    {
        "序号": 3888,
        "name": "Innovator Growth Accelerated Pl",
        "最新价": 27.07,
        "涨跌额": 0.12,
        "涨跌幅": 0.45,
        "开盘价": 27.12,
        "最高价": 27.12,
        "最低价": 27.03,
        "昨收价": 26.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 346.0,
        "成交额": 9362.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.QTJL"
    },
    {
        "序号": 3889,
        "name": "Invesco FTSE RAFI US 1000 ETF",
        "最新价": 33.87,
        "涨跌额": 0.15,
        "涨跌幅": 0.44,
        "开盘价": 33.68,
        "最高价": 33.91,
        "最低价": 33.67,
        "昨收价": 33.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 348040.0,
        "成交额": 11766231.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.PRF"
    },
    {
        "序号": 3890,
        "name": "Fidelity Stocks for Inflation E",
        "最新价": 33.91,
        "涨跌额": 0.15,
        "涨跌幅": 0.44,
        "开盘价": 33.76,
        "最高价": 33.95,
        "最低价": 33.74,
        "昨收价": 33.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 10465.0,
        "成交额": 353894.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.FCPI"
    },
    {
        "序号": 3891,
        "name": "标普中型股400ETF-SPDR",
        "最新价": 481.55,
        "涨跌额": 2.13,
        "涨跌幅": 0.44,
        "开盘价": 478.9,
        "最高价": 483.61,
        "最低价": 478.88,
        "昨收价": 479.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 579969.0,
        "成交额": 279103280.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.MDY"
    },
    {
        "序号": 3892,
        "name": "Open Lending Corp-A",
        "最新价": 6.79,
        "涨跌额": 0.03,
        "涨跌幅": 0.44,
        "开盘价": 6.73,
        "最高价": 6.86,
        "最低价": 6.72,
        "昨收价": 6.76,
        "总市值": 813127874.0,
        "市盈率": 35.78,
        "成交量": 384867.0,
        "成交额": 2613386.0,
        "振幅": 2.07,
        "换手率": 0.32,
        "symbol": "105.LPRO"
    },
    {
        "序号": 3893,
        "name": "LKQ Corp",
        "最新价": 45.4,
        "涨跌额": 0.2,
        "涨跌幅": 0.44,
        "开盘价": 45.08,
        "最高价": 45.75,
        "最低价": 45.08,
        "昨收价": 45.2,
        "总市值": 12148963683.0,
        "市盈率": 12.75,
        "成交量": 1637140.0,
        "成交额": 74381583.0,
        "振幅": 1.48,
        "换手率": 0.61,
        "symbol": "105.LKQ"
    },
    {
        "序号": 3894,
        "name": "雅培",
        "最新价": 104.51,
        "涨跌额": 0.46,
        "涨跌幅": 0.44,
        "开盘价": 104.12,
        "最高价": 104.86,
        "最低价": 104.12,
        "昨收价": 104.05,
        "总市值": 181435477597.0,
        "市盈率": 35.15,
        "成交量": 3426782.0,
        "成交额": 358227776.0,
        "振幅": 0.71,
        "换手率": 0.2,
        "symbol": "106.ABT"
    },
    {
        "序号": 3895,
        "name": "班克金融",
        "最新价": 9.09,
        "涨跌额": 0.04,
        "涨跌幅": 0.44,
        "开盘价": 9.15,
        "最高价": 9.15,
        "最低价": 9.0,
        "昨收价": 9.05,
        "总市值": 114055775.0,
        "市盈率": 10.61,
        "成交量": 19611.0,
        "成交额": 177616.0,
        "振幅": 1.66,
        "换手率": 0.16,
        "symbol": "105.BFIN"
    },
    {
        "序号": 3896,
        "name": "FT Cboe Vest U.S. Small Cap Mod",
        "最新价": 20.46,
        "涨跌额": 0.09,
        "涨跌幅": 0.44,
        "开盘价": 20.46,
        "最高价": 20.46,
        "最低价": 20.4,
        "昨收价": 20.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 9894.0,
        "成交额": 202105.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.SNOV"
    },
    {
        "序号": 3897,
        "name": "John Hancock Multifactor Mid Ca",
        "最新价": 50.02,
        "涨跌额": 0.22,
        "涨跌幅": 0.44,
        "开盘价": 49.75,
        "最高价": 50.14,
        "最低价": 49.75,
        "昨收价": 49.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 217374.0,
        "成交额": 10861038.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.JHMM"
    },
    {
        "序号": 3898,
        "name": "智利银行",
        "最新价": 22.74,
        "涨跌额": 0.1,
        "涨跌幅": 0.44,
        "开盘价": 22.61,
        "最高价": 22.89,
        "最低价": 22.6,
        "昨收价": 22.64,
        "总市值": 11485642123.0,
        "市盈率": 7.94,
        "成交量": 76367.0,
        "成交额": 1732527.0,
        "振幅": 1.28,
        "换手率": 0.02,
        "symbol": "106.BCH"
    },
    {
        "序号": 3899,
        "name": "Goldman Sachs JUST U.S. Large C",
        "最新价": 65.96,
        "涨跌额": 0.29,
        "涨跌幅": 0.44,
        "开盘价": 65.66,
        "最高价": 66.0,
        "最低价": 65.66,
        "昨收价": 65.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 3916.0,
        "成交额": 258164.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.JUST"
    },
    {
        "序号": 3900,
        "name": "Vanguard U.S. Quality Factor ET",
        "最新价": 120.57,
        "涨跌额": 0.53,
        "涨跌幅": 0.44,
        "开盘价": 120.42,
        "最高价": 120.87,
        "最低价": 120.14,
        "昨收价": 120.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 11051.0,
        "成交额": 1331933.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.VFQY"
    },
    {
        "序号": 3901,
        "name": "Aemetis Inc",
        "最新价": 4.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.44,
        "开盘价": 4.53,
        "最高价": 4.61,
        "最低价": 4.51,
        "昨收价": 4.53,
        "总市值": 179753510.0,
        "市盈率": -4.14,
        "成交量": 178662.0,
        "成交额": 814728.0,
        "振幅": 2.21,
        "换手率": 0.45,
        "symbol": "105.AMTX"
    },
    {
        "序号": 3902,
        "name": "Strive 500 ETF",
        "最新价": 29.59,
        "涨跌额": 0.13,
        "涨跌幅": 0.44,
        "开盘价": 29.41,
        "最高价": 29.61,
        "最低价": 29.38,
        "昨收价": 29.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 73891.0,
        "成交额": 2180837.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.STRV"
    },
    {
        "序号": 3903,
        "name": "布林克",
        "最新价": 84.23,
        "涨跌额": 0.37,
        "涨跌幅": 0.44,
        "开盘价": 85.16,
        "最高价": 85.51,
        "最低价": 83.85,
        "昨收价": 83.86,
        "总市值": 3794657606.0,
        "市盈率": 27.56,
        "成交量": 259414.0,
        "成交额": 21925049.0,
        "振幅": 1.98,
        "换手率": 0.58,
        "symbol": "106.BCO"
    },
    {
        "序号": 3904,
        "name": "WisdomTree U.S. Multifactor Fun",
        "最新价": 41.0,
        "涨跌额": 0.18,
        "涨跌幅": 0.44,
        "开盘价": 40.78,
        "最高价": 41.02,
        "最低价": 40.78,
        "昨收价": 40.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 8455.0,
        "成交额": 345987.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.USMF"
    },
    {
        "序号": 3905,
        "name": "Franklin FTSE Europe ETF",
        "最新价": 27.36,
        "涨跌额": 0.12,
        "涨跌幅": 0.44,
        "开盘价": 27.22,
        "最高价": 27.42,
        "最低价": 27.22,
        "昨收价": 27.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 88034.0,
        "成交额": 2399646.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.FLEE"
    },
    {
        "序号": 3906,
        "name": "奥本海默控股",
        "最新价": 41.05,
        "涨跌额": 0.18,
        "涨跌幅": 0.44,
        "开盘价": 40.88,
        "最高价": 41.22,
        "最低价": 39.19,
        "昨收价": 40.87,
        "总市值": 425897403.0,
        "市盈率": 10.26,
        "成交量": 29957.0,
        "成交额": 1226803.0,
        "振幅": 4.97,
        "换手率": 0.29,
        "symbol": "106.OPY"
    },
    {
        "序号": 3907,
        "name": "iShares Edge MSCI USA Size Fact",
        "最新价": 125.44,
        "涨跌额": 0.55,
        "涨跌幅": 0.44,
        "开盘价": 125.16,
        "最高价": 125.5,
        "最低价": 125.0,
        "昨收价": 124.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 2382.0,
        "成交额": 298424.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.SIZE"
    },
    {
        "序号": 3908,
        "name": "RBC Bearings Inc Series A Pfd",
        "最新价": 121.01,
        "涨跌额": 0.53,
        "涨跌幅": 0.44,
        "开盘价": 121.66,
        "最高价": 121.66,
        "最低价": 120.65,
        "昨收价": 120.48,
        "总市值": 556646000.0,
        "市盈率": null,
        "成交量": 3145.0,
        "成交额": 381489.0,
        "振幅": 0.84,
        "换手率": 0.07,
        "symbol": "106.RBCP"
    },
    {
        "序号": 3909,
        "name": "VinFast Auto Ltd",
        "最新价": 6.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.44,
        "开盘价": 6.8,
        "最高价": 6.96,
        "最低价": 6.68,
        "昨收价": 6.82,
        "总市值": 15975771157.0,
        "市盈率": -9.11,
        "成交量": 2676412.0,
        "成交额": 18267122.0,
        "振幅": 4.11,
        "换手率": 0.11,
        "symbol": "105.VFS"
    },
    {
        "序号": 3910,
        "name": "FT Cboe Vest Technology Dividen",
        "最新价": 20.56,
        "涨跌额": 0.09,
        "涨跌幅": 0.44,
        "开盘价": 20.46,
        "最高价": 20.6,
        "最低价": 20.46,
        "昨收价": 20.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 4992.0,
        "成交额": 102539.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.TDVI"
    },
    {
        "序号": 3911,
        "name": "VanEck Natural Resources ETF",
        "最新价": 47.99,
        "涨跌额": 0.21,
        "涨跌幅": 0.44,
        "开盘价": 47.74,
        "最高价": 48.08,
        "最低价": 47.74,
        "昨收价": 47.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 48246.0,
        "成交额": 2315342.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.HAP"
    },
    {
        "序号": 3912,
        "name": "Vanguard S&P Mid-Cap 400 ETF",
        "最新价": 89.15,
        "涨跌额": 0.39,
        "涨跌幅": 0.44,
        "开盘价": 88.77,
        "最高价": 89.53,
        "最低价": 88.62,
        "昨收价": 88.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 53350.0,
        "成交额": 4753795.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.IVOO"
    },
    {
        "序号": 3913,
        "name": "MSCI Transformational Changes E",
        "最新价": 36.58,
        "涨跌额": 0.16,
        "涨跌幅": 0.44,
        "开盘价": 36.5,
        "最高价": 36.59,
        "最低价": 36.5,
        "昨收价": 36.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 403.0,
        "成交额": 14720.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.ANEW"
    },
    {
        "序号": 3914,
        "name": "科多勒斯谷万通金控",
        "最新价": 20.59,
        "涨跌额": 0.09,
        "涨跌幅": 0.44,
        "开盘价": 20.62,
        "最高价": 20.7,
        "最低价": 20.5,
        "昨收价": 20.5,
        "总市值": 197892755.0,
        "市盈率": 7.21,
        "成交量": 24153.0,
        "成交额": 497497.0,
        "振幅": 0.98,
        "换手率": 0.25,
        "symbol": "105.CVLY"
    },
    {
        "序号": 3915,
        "name": "Simplify US Equity PLUS Convexi",
        "最新价": 29.78,
        "涨跌额": 0.13,
        "涨跌幅": 0.44,
        "开盘价": 29.75,
        "最高价": 29.78,
        "最低价": 29.63,
        "昨收价": 29.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 7324.0,
        "成交额": 217543.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.SPYC"
    },
    {
        "序号": 3916,
        "name": "TrueShares Structured Outcome (",
        "最新价": 29.79,
        "涨跌额": 0.13,
        "涨跌幅": 0.44,
        "开盘价": 29.78,
        "最高价": 29.79,
        "最低价": 29.74,
        "昨收价": 29.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1509.0,
        "成交额": 44891.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.JANZ"
    },
    {
        "序号": 3917,
        "name": "Xtrackers MSCI Kokusai Equity E",
        "最新价": 82.54,
        "涨跌额": 0.36,
        "涨跌幅": 0.44,
        "开盘价": 82.54,
        "最高价": 82.54,
        "最低价": 82.54,
        "昨收价": 82.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 825.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KOKU"
    },
    {
        "序号": 3918,
        "name": "Acuity Brands Inc",
        "最新价": 188.03,
        "涨跌额": 0.82,
        "涨跌幅": 0.44,
        "开盘价": 187.55,
        "最高价": 189.65,
        "最低价": 187.47,
        "昨收价": 187.21,
        "总市值": 5819112766.0,
        "市盈率": 16.82,
        "成交量": 153496.0,
        "成交额": 28930048.0,
        "振幅": 1.16,
        "换手率": 0.5,
        "symbol": "106.AYI"
    },
    {
        "序号": 3919,
        "name": "UGI公用事业",
        "最新价": 22.94,
        "涨跌额": 0.1,
        "涨跌幅": 0.44,
        "开盘价": 22.93,
        "最高价": 23.05,
        "最低价": 22.55,
        "昨收价": 22.84,
        "总市值": 4838036434.0,
        "市盈率": -3.22,
        "成交量": 1726893.0,
        "成交额": 39429657.0,
        "振幅": 2.19,
        "换手率": 0.82,
        "symbol": "106.UGI"
    },
    {
        "序号": 3920,
        "name": "Seritage Growth Properties-A",
        "最新价": 9.18,
        "涨跌额": 0.04,
        "涨跌幅": 0.44,
        "开盘价": 9.14,
        "最高价": 9.26,
        "最低价": 9.08,
        "昨收价": 9.14,
        "总市值": 515782156.0,
        "市盈率": -7.8,
        "成交量": 349149.0,
        "成交额": 3206582.0,
        "振幅": 1.97,
        "换手率": 0.62,
        "symbol": "106.SRG"
    },
    {
        "序号": 3921,
        "name": "FlexShares Quality Dividend Ind",
        "最新价": 59.68,
        "涨跌额": 0.26,
        "涨跌幅": 0.44,
        "开盘价": 59.39,
        "最高价": 59.75,
        "最低价": 59.34,
        "昨收价": 59.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 64176.0,
        "成交额": 3824490.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.QDF"
    },
    {
        "序号": 3922,
        "name": "Nuveen ESG Small-Cap ETF",
        "最新价": 36.73,
        "涨跌额": 0.16,
        "涨跌幅": 0.44,
        "开盘价": 36.52,
        "最高价": 36.91,
        "最低价": 36.49,
        "昨收价": 36.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 69969.0,
        "成交额": 2566074.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.NUSC"
    },
    {
        "序号": 3923,
        "name": "Global X MSCI SuperDividend EAF",
        "最新价": 13.78,
        "涨跌额": 0.06,
        "涨跌幅": 0.44,
        "开盘价": 13.81,
        "最高价": 13.81,
        "最低价": 13.76,
        "昨收价": 13.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 3870.0,
        "成交额": 53437.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.EFAS"
    },
    {
        "序号": 3924,
        "name": "大宗商品ETF-PowerShares",
        "最新价": 23.0,
        "涨跌额": 0.1,
        "涨跌幅": 0.44,
        "开盘价": 23.04,
        "最高价": 23.12,
        "最低价": 22.93,
        "昨收价": 22.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 1366110.0,
        "成交额": 31452208.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.DBC"
    },
    {
        "序号": 3925,
        "name": "CoreCivic Inc",
        "最新价": 13.81,
        "涨跌额": 0.06,
        "涨跌幅": 0.44,
        "开盘价": 13.75,
        "最高价": 13.98,
        "最低价": 13.73,
        "昨收价": 13.75,
        "总市值": 1568886997.0,
        "市盈率": 23.93,
        "成交量": 833876.0,
        "成交额": 11531176.0,
        "振幅": 1.82,
        "换手率": 0.73,
        "symbol": "106.CXW"
    },
    {
        "序号": 3926,
        "name": "SPDR S&P 400 Mid Cap Value ETF",
        "最新价": 69.07,
        "涨跌额": 0.3,
        "涨跌幅": 0.44,
        "开盘价": 68.59,
        "最高价": 69.24,
        "最低价": 68.54,
        "昨收价": 68.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 132637.0,
        "成交额": 9152876.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.MDYV"
    },
    {
        "序号": 3927,
        "name": "BlackRock California Municipal ",
        "最新价": 11.52,
        "涨跌额": 0.05,
        "涨跌幅": 0.44,
        "开盘价": 11.48,
        "最高价": 11.6,
        "最低价": 11.44,
        "昨收价": 11.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 139194.0,
        "成交额": 1605621.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "106.BFZ"
    },
    {
        "序号": 3928,
        "name": "Jackson Financial Inc-A",
        "最新价": 48.41,
        "涨跌额": 0.21,
        "涨跌幅": 0.44,
        "开盘价": 48.11,
        "最高价": 48.86,
        "最低价": 47.75,
        "昨收价": 48.2,
        "总市值": 3848598147.0,
        "市盈率": 4.51,
        "成交量": 429859.0,
        "成交额": 20745348.0,
        "振幅": 2.3,
        "换手率": 0.54,
        "symbol": "106.JXN"
    },
    {
        "序号": 3929,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 25.36,
        "涨跌额": 0.11,
        "涨跌幅": 0.44,
        "开盘价": 25.32,
        "最高价": 25.39,
        "最低价": 25.25,
        "昨收价": 25.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 35891.0,
        "成交额": 908592.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.AUGW"
    },
    {
        "序号": 3930,
        "name": "Invesco PureBeta MSCI USA ETF",
        "最新价": 46.11,
        "涨跌额": 0.2,
        "涨跌幅": 0.44,
        "开盘价": 45.83,
        "最高价": 46.15,
        "最低价": 45.83,
        "昨收价": 45.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 20787.0,
        "成交额": 958075.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.PBUS"
    },
    {
        "序号": 3931,
        "name": "iShares Core Dividend ETF",
        "最新价": 39.2,
        "涨跌额": 0.17,
        "涨跌幅": 0.44,
        "开盘价": 38.98,
        "最高价": 39.26,
        "最低价": 38.98,
        "昨收价": 39.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 14886.0,
        "成交额": 582702.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.DIVB"
    },
    {
        "序号": 3932,
        "name": "Harrow Health Inc Notes",
        "最新价": 25.37,
        "涨跌额": 0.11,
        "涨跌幅": 0.44,
        "开盘价": 25.37,
        "最高价": 25.41,
        "最低价": 25.25,
        "昨收价": 25.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 1550.0,
        "成交额": 39283.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.HROWM"
    },
    {
        "序号": 3933,
        "name": "Pacer Swan SOS Fund of Funds ET",
        "最新价": 25.4,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.26,
        "最高价": 25.42,
        "最低价": 25.26,
        "昨收价": 25.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 134295.0,
        "成交额": 3400757.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.PSFF"
    },
    {
        "序号": 3934,
        "name": "Global X S&P 500 Covered Call &",
        "最新价": 27.72,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.65,
        "最高价": 27.72,
        "最低价": 27.6,
        "昨收价": 27.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 7121.0,
        "成交额": 196832.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.XYLG"
    },
    {
        "序号": 3935,
        "name": "PMV Pharmaceuticals Inc",
        "最新价": 2.31,
        "涨跌额": 0.01,
        "涨跌幅": 0.43,
        "开盘价": 2.29,
        "最高价": 2.36,
        "最低价": 2.25,
        "昨收价": 2.3,
        "总市值": 118174139.0,
        "市盈率": -1.63,
        "成交量": 248092.0,
        "成交额": 573697.0,
        "振幅": 4.78,
        "换手率": 0.48,
        "symbol": "105.PMVP"
    },
    {
        "序号": 3936,
        "name": "ProShares S&P 500 Ex-Financials",
        "最新价": 99.36,
        "涨跌额": 0.43,
        "涨跌幅": 0.43,
        "开盘价": 99.29,
        "最高价": 99.36,
        "最低价": 98.93,
        "昨收价": 98.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 3974.0,
        "成交额": 394577.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.SPXN"
    },
    {
        "序号": 3937,
        "name": "Innovator Buffer Step-Up Strate",
        "最新价": 27.73,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.61,
        "最高价": 27.78,
        "最低价": 27.61,
        "昨收价": 27.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 33903.0,
        "成交额": 940411.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.BSTP"
    },
    {
        "序号": 3938,
        "name": "Q3 All-Season Active Rotation E",
        "最新价": 27.75,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.67,
        "最高价": 27.75,
        "最低价": 27.63,
        "昨收价": 27.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 7956.0,
        "成交额": 220544.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.QVOY"
    },
    {
        "序号": 3939,
        "name": "Principal Shareholder Yield Ind",
        "最新价": 41.63,
        "涨跌额": 0.18,
        "涨跌幅": 0.43,
        "开盘价": 41.71,
        "最高价": 41.71,
        "最低价": 41.5,
        "昨收价": 41.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 4383.0,
        "成交额": 182237.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "105.PY"
    },
    {
        "序号": 3940,
        "name": "Pacer Developed Markets Interna",
        "最新价": 30.07,
        "涨跌额": 0.13,
        "涨跌幅": 0.43,
        "开盘价": 29.93,
        "最高价": 30.13,
        "最低价": 29.9,
        "昨收价": 29.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 441203.0,
        "成交额": 13254611.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.ICOW"
    },
    {
        "序号": 3941,
        "name": "FT Cboe Vest Buffered Allocatio",
        "最新价": 20.82,
        "涨跌额": 0.09,
        "涨跌幅": 0.43,
        "开盘价": 20.69,
        "最高价": 20.82,
        "最低价": 20.66,
        "昨收价": 20.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 27570.0,
        "成交额": 572059.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.BUFG"
    },
    {
        "序号": 3942,
        "name": "LHA Market State Tactical Beta ",
        "最新价": 27.77,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.6,
        "最高价": 27.82,
        "最低价": 27.56,
        "昨收价": 27.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 19339.0,
        "成交额": 534675.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.MSTB"
    },
    {
        "序号": 3943,
        "name": "利安德巴塞尔工业",
        "最新价": 92.58,
        "涨跌额": 0.4,
        "涨跌幅": 0.43,
        "开盘价": 92.46,
        "最高价": 94.17,
        "最低价": 92.32,
        "昨收价": 92.18,
        "总市值": 30029412944.0,
        "市盈率": 13.16,
        "成交量": 1391611.0,
        "成交额": 129035178.0,
        "振幅": 2.01,
        "换手率": 0.43,
        "symbol": "106.LYB"
    },
    {
        "序号": 3944,
        "name": "JPMorgan International Research",
        "最新价": 57.88,
        "涨跌额": 0.25,
        "涨跌幅": 0.43,
        "开盘价": 57.56,
        "最高价": 57.99,
        "最低价": 57.56,
        "昨收价": 57.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 74430.0,
        "成交额": 4299194.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.JIRE"
    },
    {
        "序号": 3945,
        "name": "Calvert US Select Equity ETF",
        "最新价": 55.59,
        "涨跌额": 0.24,
        "涨跌幅": 0.43,
        "开盘价": 55.59,
        "最高价": 55.59,
        "最低价": 55.59,
        "昨收价": 55.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 389.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CVSE"
    },
    {
        "序号": 3946,
        "name": "iShares Global 100 ETF",
        "最新价": 78.76,
        "涨跌额": 0.34,
        "涨跌幅": 0.43,
        "开盘价": 78.19,
        "最高价": 78.8,
        "最低价": 78.19,
        "昨收价": 78.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 163002.0,
        "成交额": 12810063.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.IOO"
    },
    {
        "序号": 3947,
        "name": "Nationwide Dow Jones Risk-Manag",
        "最新价": 20.85,
        "涨跌额": 0.09,
        "涨跌幅": 0.43,
        "开盘价": 20.86,
        "最高价": 20.86,
        "最低价": 20.69,
        "昨收价": 20.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 2468.0,
        "成交额": 51347.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.NDJI"
    },
    {
        "序号": 3948,
        "name": "Fidelity Enhanced Large Cap Gro",
        "最新价": 25.49,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.25,
        "最高价": 25.53,
        "最低价": 25.25,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 157522.0,
        "成交额": 4005583.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.FELG"
    },
    {
        "序号": 3949,
        "name": "GasLog LP Series A Pfd",
        "最新价": 25.5,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.49,
        "最高价": 25.5,
        "最低价": 25.46,
        "昨收价": 25.39,
        "总市值": 117300000.0,
        "市盈率": null,
        "成交量": 7417.0,
        "成交额": 189006.0,
        "振幅": 0.16,
        "换手率": 0.16,
        "symbol": "106.GLOG_A"
    },
    {
        "序号": 3950,
        "name": "Douglas Elliman Inc",
        "最新价": 2.32,
        "涨跌额": 0.01,
        "涨跌幅": 0.43,
        "开盘价": 2.3,
        "最高价": 2.34,
        "最低价": 2.26,
        "昨收价": 2.31,
        "总市值": 205800982.0,
        "市盈率": -4.46,
        "成交量": 660987.0,
        "成交额": 1526046.0,
        "振幅": 3.46,
        "换手率": 0.75,
        "symbol": "106.DOUG"
    },
    {
        "序号": 3951,
        "name": "Bushido Capital US Equity ETF",
        "最新价": 25.54,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.5,
        "最高价": 25.54,
        "最低价": 25.5,
        "昨收价": 25.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 1311.0,
        "成交额": 33432.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.SMRI"
    },
    {
        "序号": 3952,
        "name": "Vanguard Russell 1000 Value ETF",
        "最新价": 69.7,
        "涨跌额": 0.3,
        "涨跌幅": 0.43,
        "开盘价": 69.46,
        "最高价": 69.81,
        "最低价": 69.38,
        "昨收价": 69.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 952119.0,
        "成交额": 66306326.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.VONV"
    },
    {
        "序号": 3953,
        "name": "QuantumScape Corp-A",
        "最新价": 6.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.43,
        "开盘价": 6.88,
        "最高价": 7.13,
        "最低价": 6.81,
        "昨收价": 6.94,
        "总市值": 3412242163.0,
        "市盈率": -7.74,
        "成交量": 3587125.0,
        "成交额": 24972298.0,
        "振幅": 4.61,
        "换手率": 0.73,
        "symbol": "106.QS"
    },
    {
        "序号": 3954,
        "name": "标普500ETF-iShares",
        "最新价": 462.35,
        "涨跌额": 1.99,
        "涨跌幅": 0.43,
        "开盘价": 459.61,
        "最高价": 462.87,
        "最低价": 459.35,
        "昨收价": 460.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 5019483.0,
        "成交额": 2316426912.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.IVV"
    },
    {
        "序号": 3955,
        "name": "The Gabelli Multimedia Trust In",
        "最新价": 23.25,
        "涨跌额": 0.1,
        "涨跌幅": 0.43,
        "开盘价": 23.9,
        "最高价": 23.9,
        "最低价": 23.25,
        "昨收价": 23.15,
        "总市值": 41647981.0,
        "市盈率": null,
        "成交量": 1300.0,
        "成交额": 30454.0,
        "振幅": 2.81,
        "换手率": 0.07,
        "symbol": "106.GGT_E"
    },
    {
        "序号": 3956,
        "name": "United States Commodity Index F",
        "最新价": 55.84,
        "涨跌额": 0.24,
        "涨跌幅": 0.43,
        "开盘价": 55.21,
        "最高价": 56.17,
        "最低价": 55.21,
        "昨收价": 55.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 15399.0,
        "成交额": 859782.0,
        "振幅": 1.73,
        "换手率": null,
        "symbol": "107.USCI"
    },
    {
        "序号": 3957,
        "name": "Fidelity Magellan ETF",
        "最新价": 23.28,
        "涨跌额": 0.1,
        "涨跌幅": 0.43,
        "开盘价": 23.18,
        "最高价": 23.29,
        "最低价": 23.16,
        "昨收价": 23.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 2106.0,
        "成交额": 48913.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.FMAG"
    },
    {
        "序号": 3958,
        "name": "The Gabelli Multimedia Trust In",
        "最新价": 23.3,
        "涨跌额": 0.1,
        "涨跌幅": 0.43,
        "开盘价": 23.2,
        "最高价": 23.3,
        "最低价": 23.2,
        "昨收价": 23.2,
        "总市值": 37440211.0,
        "市盈率": null,
        "成交量": 1959.0,
        "成交额": 45480.0,
        "振幅": 0.43,
        "换手率": 0.12,
        "symbol": "106.GGT_G"
    },
    {
        "序号": 3959,
        "name": "ANI Pharmaceuticals Inc",
        "最新价": 48.94,
        "涨跌额": 0.21,
        "涨跌幅": 0.43,
        "开盘价": 48.47,
        "最高价": 49.13,
        "最低价": 48.2,
        "昨收价": 48.73,
        "总市值": 1000213550.0,
        "市盈率": 74.75,
        "成交量": 205168.0,
        "成交额": 10007405.0,
        "振幅": 1.91,
        "换手率": 1.0,
        "symbol": "105.ANIP"
    },
    {
        "序号": 3960,
        "name": "Avantor Inc",
        "最新价": 20.98,
        "涨跌额": 0.09,
        "涨跌幅": 0.43,
        "开盘价": 21.25,
        "最高价": 22.58,
        "最低价": 20.62,
        "昨收价": 20.89,
        "总市值": 14190582791.0,
        "市盈率": 38.95,
        "成交量": 15305203.0,
        "成交额": 328803520.0,
        "振幅": 9.38,
        "换手率": 2.26,
        "symbol": "106.AVTR"
    },
    {
        "序号": 3961,
        "name": "Benchmark Electronics Inc",
        "最新价": 25.65,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.51,
        "最高价": 25.73,
        "最低价": 25.28,
        "昨收价": 25.54,
        "总市值": 915239478.0,
        "市盈率": 13.46,
        "成交量": 84901.0,
        "成交额": 2173457.0,
        "振幅": 1.76,
        "换手率": 0.24,
        "symbol": "106.BHE"
    },
    {
        "序号": 3962,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 28.01,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.98,
        "最高价": 28.01,
        "最低价": 27.93,
        "昨收价": 27.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 2011.0,
        "成交额": 56243.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.FEBT"
    },
    {
        "序号": 3963,
        "name": "Schwab Fundamental U.S. Broad M",
        "最新价": 58.36,
        "涨跌额": 0.25,
        "涨跌幅": 0.43,
        "开盘价": 58.12,
        "最高价": 58.44,
        "最低价": 58.07,
        "昨收价": 58.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 38368.0,
        "成交额": 2234564.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.FNDB"
    },
    {
        "序号": 3964,
        "name": "WisdomTree U.S. SmallCap Divide",
        "最新价": 30.36,
        "涨跌额": 0.13,
        "涨跌幅": 0.43,
        "开盘价": 30.26,
        "最高价": 30.52,
        "最低价": 30.17,
        "昨收价": 30.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 111796.0,
        "成交额": 3393293.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DES"
    },
    {
        "序号": 3965,
        "name": "Invesco Raymond James SB-1 Equi",
        "最新价": 58.39,
        "涨跌额": 0.25,
        "涨跌幅": 0.43,
        "开盘价": 58.38,
        "最高价": 58.48,
        "最低价": 58.1,
        "昨收价": 58.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5542.0,
        "成交额": 323366.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.RYJ"
    },
    {
        "序号": 3966,
        "name": "Invesco BuyBack Achievers ETF",
        "最新价": 93.44,
        "涨跌额": 0.4,
        "涨跌幅": 0.43,
        "开盘价": 92.87,
        "最高价": 93.6,
        "最低价": 92.87,
        "昨收价": 93.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 55277.0,
        "成交额": 5160212.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "105.PKW"
    },
    {
        "序号": 3967,
        "name": "标普500ETF-SPDR",
        "最新价": 460.2,
        "涨跌额": 1.97,
        "涨跌幅": 0.43,
        "开盘价": 457.46,
        "最高价": 460.75,
        "最低价": 457.21,
        "昨收价": 458.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 83194404.0,
        "成交额": 38221696256.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.SPY"
    },
    {
        "序号": 3968,
        "name": "Avantis All Equity Markets Valu",
        "最新价": 53.73,
        "涨跌额": 0.23,
        "涨跌幅": 0.43,
        "开盘价": 53.87,
        "最高价": 53.87,
        "最低价": 53.64,
        "昨收价": 53.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 12191.0,
        "成交额": 654360.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.AVGV"
    },
    {
        "序号": 3969,
        "name": "iShares Morningstar Mid-Cap Gro",
        "最新价": 60.77,
        "涨跌额": 0.26,
        "涨跌幅": 0.43,
        "开盘价": 60.43,
        "最高价": 60.92,
        "最低价": 60.43,
        "昨收价": 60.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 462604.0,
        "成交额": 28036004.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.IMCG"
    },
    {
        "序号": 3970,
        "name": "Southside Bancshares Inc",
        "最新价": 30.39,
        "涨跌额": 0.13,
        "涨跌幅": 0.43,
        "开盘价": 30.34,
        "最高价": 30.67,
        "最低价": 30.06,
        "昨收价": 30.26,
        "总市值": 917675099.0,
        "市盈率": 9.46,
        "成交量": 87555.0,
        "成交额": 2661061.0,
        "振幅": 2.02,
        "换手率": 0.29,
        "symbol": "105.SBSI"
    },
    {
        "序号": 3971,
        "name": "Pacira BioSciences Inc",
        "最新价": 28.06,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 27.86,
        "最高价": 28.33,
        "最低价": 27.62,
        "昨收价": 27.94,
        "总市值": 1303041974.0,
        "市盈率": 186.6,
        "成交量": 229619.0,
        "成交额": 6432366.0,
        "振幅": 2.54,
        "换手率": 0.49,
        "symbol": "105.PCRX"
    },
    {
        "序号": 3972,
        "name": "OneAscent Emerging Markets ETF",
        "最新价": 28.06,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 28.03,
        "最高价": 28.09,
        "最低价": 27.94,
        "昨收价": 27.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 20070.0,
        "成交额": 562903.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.OAEM"
    },
    {
        "序号": 3973,
        "name": "VictoryShares US Value Momentum",
        "最新价": 65.48,
        "涨跌额": 0.28,
        "涨跌幅": 0.43,
        "开盘价": 65.14,
        "最高价": 65.51,
        "最低价": 65.14,
        "昨收价": 65.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 550.0,
        "成交额": 35968.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "105.ULVM"
    },
    {
        "序号": 3974,
        "name": "Great Lakes Dredge & Dock Corp",
        "最新价": 7.02,
        "涨跌额": 0.03,
        "涨跌幅": 0.43,
        "开盘价": 6.96,
        "最高价": 7.08,
        "最低价": 6.95,
        "昨收价": 6.99,
        "总市值": 467619434.0,
        "市盈率": -12.05,
        "成交量": 167336.0,
        "成交额": 1175038.0,
        "振幅": 1.86,
        "换手率": 0.25,
        "symbol": "105.GLDD"
    },
    {
        "序号": 3975,
        "name": "Goldman Sachs MarketBeta U.S. E",
        "最新价": 63.22,
        "涨跌额": 0.27,
        "涨跌幅": 0.43,
        "开盘价": 62.82,
        "最高价": 63.29,
        "最低价": 62.82,
        "昨收价": 62.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 35757.0,
        "成交额": 2256116.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.GSUS"
    },
    {
        "序号": 3976,
        "name": "First Trust Europe AlphaDEX Fun",
        "最新价": 35.13,
        "涨跌额": 0.15,
        "涨跌幅": 0.43,
        "开盘价": 34.88,
        "最高价": 35.16,
        "最低价": 34.88,
        "昨收价": 34.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 21975.0,
        "成交额": 770472.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "105.FEP"
    },
    {
        "序号": 3977,
        "name": "iShares Exponential Technologie",
        "最新价": 56.24,
        "涨跌额": 0.24,
        "涨跌幅": 0.43,
        "开盘价": 55.78,
        "最高价": 56.42,
        "最低价": 55.72,
        "昨收价": 56.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 85217.0,
        "成交额": 4785073.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "105.XT"
    },
    {
        "序号": 3978,
        "name": "超威半导体",
        "最新价": 128.92,
        "涨跌额": 0.55,
        "涨跌幅": 0.43,
        "开盘价": 129.54,
        "最高价": 131.0,
        "最低价": 126.89,
        "昨收价": 128.37,
        "总市值": 208270117028.0,
        "市盈率": 1001.3,
        "成交量": 89341014.0,
        "成交额": 11514870016.0,
        "振幅": 3.2,
        "换手率": 5.53,
        "symbol": "105.AMD"
    },
    {
        "序号": 3979,
        "name": "SoFi Select 500 ETF",
        "最新价": 16.41,
        "涨跌额": 0.07,
        "涨跌幅": 0.43,
        "开盘价": 16.31,
        "最高价": 16.43,
        "最低价": 16.31,
        "昨收价": 16.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 118170.0,
        "成交额": 1935838.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.SFY"
    },
    {
        "序号": 3980,
        "name": "FT Cboe Vest U.S. Small Cap Mod",
        "最新价": 21.1,
        "涨跌额": 0.09,
        "涨跌幅": 0.43,
        "开盘价": 21.15,
        "最高价": 21.15,
        "最低价": 21.08,
        "昨收价": 21.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1189.0,
        "成交额": 25129.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.SMAY"
    },
    {
        "序号": 3981,
        "name": "Gotham 1000 Value ETF",
        "最新价": 21.1,
        "涨跌额": 0.09,
        "涨跌幅": 0.43,
        "开盘价": 21.07,
        "最高价": 21.1,
        "最低价": 21.06,
        "昨收价": 21.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 2351.0,
        "成交额": 49580.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.GVLU"
    },
    {
        "序号": 3982,
        "name": "Financial Institutions Inc",
        "最新价": 18.77,
        "涨跌额": 0.08,
        "涨跌幅": 0.43,
        "开盘价": 18.79,
        "最高价": 19.07,
        "最低价": 18.43,
        "昨收价": 18.69,
        "总市值": 289109805.0,
        "市盈率": 5.5,
        "成交量": 59591.0,
        "成交额": 1116212.0,
        "振幅": 3.42,
        "换手率": 0.39,
        "symbol": "105.FISI"
    },
    {
        "序号": 3983,
        "name": "Dimensional US Large Cap Vector",
        "最新价": 53.97,
        "涨跌额": 0.23,
        "涨跌幅": 0.43,
        "开盘价": 53.74,
        "最高价": 54.04,
        "最低价": 53.74,
        "昨收价": 53.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 30861.0,
        "成交额": 1665130.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.DFVX"
    },
    {
        "序号": 3984,
        "name": "Vanguard Large-Cap ETF",
        "最新价": 211.26,
        "涨跌额": 0.9,
        "涨跌幅": 0.43,
        "开盘价": 210.03,
        "最高价": 211.62,
        "最低价": 210.03,
        "昨收价": 210.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 206411.0,
        "成交额": 43525100.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.VV"
    },
    {
        "序号": 3985,
        "name": "Ameris Bancorp",
        "最新价": 47.0,
        "涨跌额": 0.2,
        "涨跌幅": 0.43,
        "开盘价": 46.99,
        "最高价": 47.59,
        "最低价": 46.43,
        "昨收价": 46.8,
        "总市值": 3245446867.0,
        "市盈率": 11.37,
        "成交量": 344929.0,
        "成交额": 16221171.0,
        "振幅": 2.48,
        "换手率": 0.5,
        "symbol": "105.ABCB"
    },
    {
        "序号": 3986,
        "name": "SPDR Portfolio S&P 1500 Composi",
        "最新价": 56.41,
        "涨跌额": 0.24,
        "涨跌幅": 0.43,
        "开盘价": 56.11,
        "最高价": 56.51,
        "最低价": 56.11,
        "昨收价": 56.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 250722.0,
        "成交额": 14123873.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.SPTM"
    },
    {
        "序号": 3987,
        "name": "WisdomTree U.S. Quality Growth ",
        "最新价": 35.26,
        "涨跌额": 0.15,
        "涨跌幅": 0.43,
        "开盘价": 34.99,
        "最高价": 35.29,
        "最低价": 34.99,
        "昨收价": 35.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 47699.0,
        "成交额": 1680557.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.QGRW"
    },
    {
        "序号": 3988,
        "name": "SPDR S&P 500 Fossil Fuel Reserv",
        "最新价": 37.62,
        "涨跌额": 0.16,
        "涨跌幅": 0.43,
        "开盘价": 37.4,
        "最高价": 37.67,
        "最低价": 37.4,
        "昨收价": 37.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 193459.0,
        "成交额": 7255568.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.SPYX"
    },
    {
        "序号": 3989,
        "name": "Innovator Hedged TSLA Strategy ",
        "最新价": 25.87,
        "涨跌额": 0.11,
        "涨跌幅": 0.43,
        "开盘价": 25.87,
        "最高价": 25.87,
        "最低价": 25.87,
        "昨收价": 25.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 17.0,
        "成交额": 439.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TSLH"
    },
    {
        "序号": 3990,
        "name": "SPDR Portfolio S&P 500 ETF",
        "最新价": 54.11,
        "涨跌额": 0.23,
        "涨跌幅": 0.43,
        "开盘价": 53.81,
        "最高价": 54.18,
        "最低价": 53.77,
        "昨收价": 53.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 6538539.0,
        "成交额": 353039456.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.SPLG"
    },
    {
        "序号": 3991,
        "name": "罗素中型价值股ETF-iShares",
        "最新价": 110.66,
        "涨跌额": 0.47,
        "涨跌幅": 0.43,
        "开盘价": 110.07,
        "最高价": 111.0,
        "最低价": 110.07,
        "昨收价": 110.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 618342.0,
        "成交额": 68402800.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.IWS"
    },
    {
        "序号": 3992,
        "name": "BNY Mellon Concentrated Interna",
        "最新价": 47.09,
        "涨跌额": 0.2,
        "涨跌幅": 0.43,
        "开盘价": 46.95,
        "最高价": 47.1,
        "最低价": 46.95,
        "昨收价": 46.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 5051.0,
        "成交额": 237690.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.BKCI"
    },
    {
        "序号": 3993,
        "name": "Innovator IBD 50 ETF",
        "最新价": 23.55,
        "涨跌额": 0.1,
        "涨跌幅": 0.43,
        "开盘价": 23.34,
        "最高价": 23.6,
        "最低价": 23.25,
        "昨收价": 23.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 114098.0,
        "成交额": 2679974.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "107.FFTY"
    },
    {
        "序号": 3994,
        "name": "Pacer Swan SOS Flex (January) E",
        "最新价": 28.28,
        "涨跌额": 0.12,
        "涨跌幅": 0.43,
        "开盘价": 28.28,
        "最高价": 28.28,
        "最低价": 28.28,
        "昨收价": 28.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 28.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PSFD"
    },
    {
        "序号": 3995,
        "name": "Eaton Vance Tax-Managed Diversi",
        "最新价": 11.79,
        "涨跌额": 0.05,
        "涨跌幅": 0.43,
        "开盘价": 11.7,
        "最高价": 11.84,
        "最低价": 11.67,
        "昨收价": 11.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 299304.0,
        "成交额": 3520106.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "106.ETY"
    },
    {
        "序号": 3996,
        "name": "库力索法工业",
        "最新价": 51.89,
        "涨跌额": 0.22,
        "涨跌幅": 0.43,
        "开盘价": 51.46,
        "最高价": 52.6,
        "最低价": 51.45,
        "昨收价": 51.67,
        "总市值": 2943203083.0,
        "市盈率": 51.5,
        "成交量": 447458.0,
        "成交额": 23204499.0,
        "振幅": 2.23,
        "换手率": 0.79,
        "symbol": "105.KLIC"
    },
    {
        "序号": 3997,
        "name": "iShares ESG MSCI USA ETF",
        "最新价": 101.43,
        "涨跌额": 0.43,
        "涨跌幅": 0.43,
        "开盘价": 100.86,
        "最高价": 101.56,
        "最低价": 100.78,
        "昨收价": 101.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 445558.0,
        "成交额": 45132432.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "105.ESGU"
    },
    {
        "序号": 3998,
        "name": "iShares ESG Screened S&P Small-",
        "最新价": 35.39,
        "涨跌额": 0.15,
        "涨跌幅": 0.43,
        "开盘价": 35.3,
        "最高价": 35.49,
        "最低价": 35.1,
        "昨收价": 35.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 6911.0,
        "成交额": 243935.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.XJR"
    },
    {
        "序号": 3999,
        "name": "Global X MSCI Vietnam ETF",
        "最新价": 16.52,
        "涨跌额": 0.07,
        "涨跌幅": 0.43,
        "开盘价": 16.44,
        "最高价": 16.52,
        "最低价": 16.44,
        "昨收价": 16.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 910.0,
        "成交额": 14993.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.VNAM"
    },
    {
        "序号": 4000,
        "name": "SPDR Portfolio S&P 500 High Div",
        "最新价": 37.76,
        "涨跌额": 0.16,
        "涨跌幅": 0.43,
        "开盘价": 37.64,
        "最高价": 37.83,
        "最低价": 37.53,
        "昨收价": 37.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 2389489.0,
        "成交额": 90090407.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.SPYD"
    },
    {
        "序号": 4001,
        "name": "WisdomTree Global ex-US Quality",
        "最新价": 35.41,
        "涨跌额": 0.15,
        "涨跌幅": 0.43,
        "开盘价": 35.19,
        "最高价": 35.48,
        "最低价": 35.19,
        "昨收价": 35.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 61879.0,
        "成交额": 2188496.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.DNL"
    },
    {
        "序号": 4002,
        "name": "iShares Morningstar Mid-Cap ETF",
        "最新价": 63.79,
        "涨跌额": 0.27,
        "涨跌幅": 0.43,
        "开盘价": 63.44,
        "最高价": 63.87,
        "最低价": 63.44,
        "昨收价": 63.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 14364.0,
        "成交额": 914980.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.IMCB"
    },
    {
        "序号": 4003,
        "name": "iShares MSCI USA Equal Weighted",
        "最新价": 80.33,
        "涨跌额": 0.34,
        "涨跌幅": 0.43,
        "开盘价": 79.86,
        "最高价": 80.52,
        "最低价": 79.86,
        "昨收价": 79.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 25814.0,
        "成交额": 2072690.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.EUSA"
    },
    {
        "序号": 4004,
        "name": "标普500ETF-Vanguard",
        "最新价": 422.92,
        "涨跌额": 1.79,
        "涨跌幅": 0.43,
        "开盘价": 420.37,
        "最高价": 423.37,
        "最低价": 420.15,
        "昨收价": 421.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 5125278.0,
        "成交额": 2163827056.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.VOO"
    },
    {
        "序号": 4005,
        "name": "iShares Climate Conscious & Tra",
        "最新价": 54.37,
        "涨跌额": 0.23,
        "涨跌幅": 0.42,
        "开盘价": 54.37,
        "最高价": 54.37,
        "最低价": 54.37,
        "昨收价": 54.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 217.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.USCL"
    },
    {
        "序号": 4006,
        "name": "T. Rowe Price U.S. Equity Resea",
        "最新价": 28.4,
        "涨跌额": 0.12,
        "涨跌幅": 0.42,
        "开盘价": 28.27,
        "最高价": 28.42,
        "最低价": 28.27,
        "昨收价": 28.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 8609.0,
        "成交额": 244050.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.TSPA"
    },
    {
        "序号": 4007,
        "name": "TrueShares Structured Outcome (",
        "最新价": 26.04,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 25.92,
        "最高价": 26.04,
        "最低价": 25.92,
        "昨收价": 25.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 219.0,
        "成交额": 5676.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.MAYZ"
    },
    {
        "序号": 4008,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 35.54,
        "涨跌额": 0.15,
        "涨跌幅": 0.42,
        "开盘价": 35.3,
        "最高价": 35.54,
        "最低价": 35.3,
        "昨收价": 35.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 257048.0,
        "成交额": 9127737.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.BFEB"
    },
    {
        "序号": 4009,
        "name": "Shattuck Labs Inc",
        "最新价": 2.37,
        "涨跌额": 0.01,
        "涨跌幅": 0.42,
        "开盘价": 2.41,
        "最高价": 2.55,
        "最低价": 2.32,
        "昨收价": 2.36,
        "总市值": 100690599.0,
        "市盈率": -1.06,
        "成交量": 27454.0,
        "成交额": 67060.0,
        "振幅": 9.75,
        "换手率": 0.06,
        "symbol": "105.STTK"
    },
    {
        "序号": 4010,
        "name": "GoDaddy Inc-A",
        "最新价": 104.31,
        "涨跌额": 0.44,
        "涨跌幅": 0.42,
        "开盘价": 103.44,
        "最高价": 105.06,
        "最低价": 103.27,
        "昨收价": 103.87,
        "总市值": 14721323602.0,
        "市盈率": 41.53,
        "成交量": 1657202.0,
        "成交额": 172494985.0,
        "振幅": 1.72,
        "换手率": 1.17,
        "symbol": "106.GDDY"
    },
    {
        "序号": 4011,
        "name": "Global X Super Dividend ETF",
        "最新价": 16.6,
        "涨跌额": 0.07,
        "涨跌幅": 0.42,
        "开盘价": 16.54,
        "最高价": 16.65,
        "最低价": 16.52,
        "昨收价": 16.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 146437.0,
        "成交额": 2427448.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.DIV"
    },
    {
        "序号": 4012,
        "name": "保险ETF-SPDR S&P",
        "最新价": 45.06,
        "涨跌额": 0.19,
        "涨跌幅": 0.42,
        "开盘价": 44.98,
        "最高价": 45.18,
        "最低价": 44.82,
        "昨收价": 44.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 547564.0,
        "成交额": 24647514.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.KIE"
    },
    {
        "序号": 4013,
        "name": "FMC Excelsior Focus Equity ETF",
        "最新价": 26.09,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.09,
        "最高价": 26.09,
        "最低价": 26.09,
        "昨收价": 25.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 26.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FMCX"
    },
    {
        "序号": 4014,
        "name": "PrimeEnergy Corp",
        "最新价": 102.01,
        "涨跌额": 0.43,
        "涨跌幅": 0.42,
        "开盘价": 102.01,
        "最高价": 102.01,
        "最低价": 102.01,
        "昨收价": 101.58,
        "总市值": 185716958.0,
        "市盈率": 5.22,
        "成交量": 719.0,
        "成交额": 73345.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.PNRG"
    },
    {
        "序号": 4015,
        "name": "Private Real Estate Strategy vi",
        "最新价": 18.98,
        "涨跌额": 0.08,
        "涨跌幅": 0.42,
        "开盘价": 18.98,
        "最高价": 18.98,
        "最低价": 18.98,
        "昨收价": 18.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 189.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PRVT"
    },
    {
        "序号": 4016,
        "name": "Invesco NASDAQ 100 ETF",
        "最新价": 161.37,
        "涨跌额": 0.68,
        "涨跌幅": 0.42,
        "开盘价": 159.92,
        "最高价": 161.55,
        "最低价": 159.9,
        "昨收价": 160.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 1461699.0,
        "成交额": 235201038.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "105.QQQM"
    },
    {
        "序号": 4017,
        "name": "SPDR Portfolio S&P Sector Neutr",
        "最新价": 30.86,
        "涨跌额": 0.13,
        "涨跌幅": 0.42,
        "开盘价": 30.86,
        "最高价": 30.86,
        "最低价": 30.86,
        "昨收价": 30.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 64.0,
        "成交额": 1975.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SPDG"
    },
    {
        "序号": 4018,
        "name": "卡地纳健康",
        "最新价": 106.83,
        "涨跌额": 0.45,
        "涨跌幅": 0.42,
        "开盘价": 106.69,
        "最高价": 107.03,
        "最低价": 105.67,
        "昨收价": 106.38,
        "总市值": 26330190648.0,
        "市盈率": 168.78,
        "成交量": 1245028.0,
        "成交额": 132754629.0,
        "振幅": 1.28,
        "换手率": 0.51,
        "symbol": "106.CAH"
    },
    {
        "序号": 4019,
        "name": "第一富金融服务",
        "最新价": 113.96,
        "涨跌额": 0.48,
        "涨跌幅": 0.42,
        "开盘价": 113.29,
        "最高价": 114.54,
        "最低价": 113.29,
        "昨收价": 113.48,
        "总市值": 5140497652.0,
        "市盈率": 22.37,
        "成交量": 143881.0,
        "成交额": 16398526.0,
        "振幅": 1.1,
        "换手率": 0.32,
        "symbol": "105.FCFS"
    },
    {
        "序号": 4020,
        "name": "PIMCO High Income Fund",
        "最新价": 4.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.42,
        "开盘价": 4.73,
        "最高价": 4.77,
        "最低价": 4.71,
        "昨收价": 4.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 533638.0,
        "成交额": 2531782.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "106.PHK"
    },
    {
        "序号": 4021,
        "name": "Swan Hedged Equity US Large Cap",
        "最新价": 19.0,
        "涨跌额": 0.08,
        "涨跌幅": 0.42,
        "开盘价": 18.84,
        "最高价": 19.01,
        "最低价": 18.84,
        "昨收价": 18.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 36735.0,
        "成交额": 695892.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.HEGD"
    },
    {
        "序号": 4022,
        "name": "罗素3000ETF-iShares",
        "最新价": 263.8,
        "涨跌额": 1.11,
        "涨跌幅": 0.42,
        "开盘价": 262.17,
        "最高价": 264.19,
        "最低价": 262.17,
        "昨收价": 262.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 123268.0,
        "成交额": 32441274.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.IWV"
    },
    {
        "序号": 4023,
        "name": "Nuveen ESG Large-Cap ETF",
        "最新价": 38.03,
        "涨跌额": 0.16,
        "涨跌幅": 0.42,
        "开盘价": 38.05,
        "最高价": 38.05,
        "最低价": 38.03,
        "昨收价": 37.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 241.0,
        "成交额": 9167.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.NULC"
    },
    {
        "序号": 4024,
        "name": "Putnam Sustainable Leaders ETF",
        "最新价": 26.15,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.08,
        "最高价": 26.15,
        "最低价": 26.08,
        "昨收价": 26.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 3226.0,
        "成交额": 84134.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.PLDR"
    },
    {
        "序号": 4025,
        "name": "芝加哥商业交易所",
        "最新价": 211.62,
        "涨跌额": 0.89,
        "涨跌幅": 0.42,
        "开盘价": 210.65,
        "最高价": 212.39,
        "最低价": 210.45,
        "昨收价": 210.73,
        "总市值": 76181713581.0,
        "市盈率": 24.98,
        "成交量": 2493384.0,
        "成交额": 527452000.0,
        "振幅": 0.92,
        "换手率": 0.69,
        "symbol": "105.CME"
    },
    {
        "序号": 4026,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 35.67,
        "涨跌额": 0.15,
        "涨跌幅": 0.42,
        "开盘价": 35.54,
        "最高价": 35.7,
        "最低价": 35.53,
        "昨收价": 35.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 3743.0,
        "成交额": 133240.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.BSEP"
    },
    {
        "序号": 4027,
        "name": "可选消费指数ETF-Vanguard",
        "最新价": 292.74,
        "涨跌额": 1.23,
        "涨跌幅": 0.42,
        "开盘价": 289.9,
        "最高价": 293.28,
        "最低价": 289.9,
        "昨收价": 291.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 46012.0,
        "成交额": 13447426.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.VCR"
    },
    {
        "序号": 4028,
        "name": "财捷",
        "最新价": 573.9,
        "涨跌额": 2.41,
        "涨跌幅": 0.42,
        "开盘价": 566.16,
        "最高价": 574.63,
        "最低价": 564.39,
        "昨收价": 571.49,
        "总市值": 160655016736.0,
        "市盈率": 62.15,
        "成交量": 1195183.0,
        "成交额": 683818992.0,
        "振幅": 1.79,
        "换手率": 0.43,
        "symbol": "105.INTU"
    },
    {
        "序号": 4029,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 30.96,
        "涨跌额": 0.13,
        "涨跌幅": 0.42,
        "开盘价": 30.87,
        "最高价": 30.96,
        "最低价": 30.87,
        "昨收价": 30.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 5468.0,
        "成交额": 169128.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.GAUG"
    },
    {
        "序号": 4030,
        "name": "Aptus Large Cap Enhanced Yield ",
        "最新价": 26.21,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.17,
        "最高价": 26.25,
        "最低价": 26.13,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 17603.0,
        "成交额": 461287.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.DUBS"
    },
    {
        "序号": 4031,
        "name": "Xtrackers MSCI USA Climate Acti",
        "最新价": 28.6,
        "涨跌额": 0.12,
        "涨跌幅": 0.42,
        "开盘价": 28.6,
        "最高价": 28.6,
        "最低价": 28.6,
        "昨收价": 28.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 65.0,
        "成交额": 1858.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.USCA"
    },
    {
        "序号": 4032,
        "name": "网侦系统",
        "最新价": 21.45,
        "涨跌额": 0.09,
        "涨跌幅": 0.42,
        "开盘价": 21.4,
        "最高价": 21.78,
        "最低价": 21.14,
        "昨收价": 21.36,
        "总市值": 1522866946.0,
        "市盈率": 22.85,
        "成交量": 940483.0,
        "成交额": 20178798.0,
        "振幅": 3.0,
        "换手率": 1.32,
        "symbol": "105.NTCT"
    },
    {
        "序号": 4033,
        "name": "Dimensional U.S. Equity ETF",
        "最新价": 50.05,
        "涨跌额": 0.21,
        "涨跌幅": 0.42,
        "开盘价": 49.71,
        "最高价": 50.12,
        "最低价": 49.71,
        "昨收价": 49.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 192480.0,
        "成交额": 9620671.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.DFUS"
    },
    {
        "序号": 4034,
        "name": "T. Rowe Price Value ETF",
        "最新价": 26.22,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.1,
        "最高价": 26.23,
        "最低价": 26.1,
        "昨收价": 26.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1460.0,
        "成交额": 38193.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.TVAL"
    },
    {
        "序号": 4035,
        "name": "Corbett Road Tactical Opportuni",
        "最新价": 23.85,
        "涨跌额": 0.1,
        "涨跌幅": 0.42,
        "开盘价": 23.85,
        "最高价": 23.85,
        "最低价": 23.85,
        "昨收价": 23.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 119.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OPPX"
    },
    {
        "序号": 4036,
        "name": "iShares S&P Mid-Cap 400 Value E",
        "最新价": 107.35,
        "涨跌额": 0.45,
        "涨跌幅": 0.42,
        "开盘价": 106.64,
        "最高价": 107.81,
        "最低价": 106.64,
        "昨收价": 106.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 143919.0,
        "成交额": 15439495.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.IJJ"
    },
    {
        "序号": 4037,
        "name": "Pacer WealthShield ETF",
        "最新价": 26.27,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.22,
        "最高价": 26.32,
        "最低价": 26.21,
        "昨收价": 26.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1062.0,
        "成交额": 27863.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.PWS"
    },
    {
        "序号": 4038,
        "name": "Energy Vault Holdings Inc",
        "最新价": 2.39,
        "涨跌额": 0.01,
        "涨跌幅": 0.42,
        "开盘价": 2.38,
        "最高价": 2.44,
        "最低价": 2.32,
        "昨收价": 2.38,
        "总市值": 343074273.0,
        "市盈率": -3.45,
        "成交量": 544185.0,
        "成交额": 1291492.0,
        "振幅": 5.04,
        "换手率": 0.38,
        "symbol": "106.NRGV"
    },
    {
        "序号": 4039,
        "name": "First Trust Nasdaq Transportati",
        "最新价": 28.68,
        "涨跌额": 0.12,
        "涨跌幅": 0.42,
        "开盘价": 28.79,
        "最高价": 28.8,
        "最低价": 28.63,
        "昨收价": 28.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 4620.0,
        "成交额": 132683.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "105.FTXR"
    },
    {
        "序号": 4040,
        "name": "IQ Candriam ESG U.S. Large Cap ",
        "最新价": 40.65,
        "涨跌额": 0.17,
        "涨跌幅": 0.42,
        "开盘价": 40.44,
        "最高价": 40.67,
        "最低价": 40.44,
        "昨收价": 40.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1560.0,
        "成交额": 63340.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.IQSU"
    },
    {
        "序号": 4041,
        "name": "铁姆肯",
        "最新价": 74.21,
        "涨跌额": 0.31,
        "涨跌幅": 0.42,
        "开盘价": 73.84,
        "最高价": 74.76,
        "最低价": 73.27,
        "昨收价": 73.9,
        "总市值": 5235618949.0,
        "市盈率": 12.1,
        "成交量": 580007.0,
        "成交额": 42913520.0,
        "振幅": 2.02,
        "换手率": 0.82,
        "symbol": "106.TKR"
    },
    {
        "序号": 4042,
        "name": "iShares Currency Hedged MSCI EA",
        "最新价": 28.74,
        "涨跌额": 0.12,
        "涨跌幅": 0.42,
        "开盘价": 28.63,
        "最高价": 28.75,
        "最低价": 28.63,
        "昨收价": 28.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 23761.0,
        "成交额": 682448.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.HSCZ"
    },
    {
        "序号": 4043,
        "name": "Etho Climate Leadership U.S. ET",
        "最新价": 52.72,
        "涨跌额": 0.22,
        "涨跌幅": 0.42,
        "开盘价": 52.33,
        "最高价": 52.75,
        "最低价": 52.33,
        "昨收价": 52.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 4149.0,
        "成交额": 218403.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.ETHO"
    },
    {
        "序号": 4044,
        "name": "iShares MSCI Kokusai ETF",
        "最新价": 95.95,
        "涨跌额": 0.4,
        "涨跌幅": 0.42,
        "开盘价": 95.36,
        "最高价": 95.95,
        "最低价": 95.36,
        "昨收价": 95.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1456.0,
        "成交额": 139142.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.TOK"
    },
    {
        "序号": 4045,
        "name": "Global X S&P 500 Tail Risk ETF",
        "最新价": 26.39,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.33,
        "最高价": 26.39,
        "最低价": 26.33,
        "昨收价": 26.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 504.0,
        "成交额": 13270.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.XTR"
    },
    {
        "序号": 4046,
        "name": "PTC Therapeutics Inc",
        "最新价": 26.39,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.28,
        "最高价": 26.99,
        "最低价": 25.82,
        "昨收价": 26.28,
        "总市值": 1991472397.0,
        "市盈率": -3.1,
        "成交量": 797401.0,
        "成交额": 21049650.0,
        "振幅": 4.45,
        "换手率": 1.06,
        "symbol": "105.PTCT"
    },
    {
        "序号": 4047,
        "name": "Xtrackers Russell 1000 US Quali",
        "最新价": 43.24,
        "涨跌额": 0.18,
        "涨跌幅": 0.42,
        "开盘价": 43.01,
        "最高价": 43.24,
        "最低价": 43.0,
        "昨收价": 43.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 3874.0,
        "成交额": 167103.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.QARP"
    },
    {
        "序号": 4048,
        "name": "全球机器人自动化ETF-ROBO",
        "最新价": 52.88,
        "涨跌额": 0.22,
        "涨跌幅": 0.42,
        "开盘价": 52.48,
        "最高价": 53.0,
        "最低价": 52.48,
        "昨收价": 52.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 74537.0,
        "成交额": 3937986.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.ROBO"
    },
    {
        "序号": 4049,
        "name": "Global X S&P 500 Collar 95-110 ",
        "最新价": 26.48,
        "涨跌额": 0.11,
        "涨跌幅": 0.42,
        "开盘价": 26.48,
        "最高价": 26.48,
        "最低价": 26.48,
        "昨收价": 26.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 105.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XCLR"
    },
    {
        "序号": 4050,
        "name": "以色列ETF-iShares MSCI",
        "最新价": 55.37,
        "涨跌额": 0.23,
        "涨跌幅": 0.42,
        "开盘价": 55.27,
        "最高价": 55.42,
        "最低价": 55.22,
        "昨收价": 55.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5748.0,
        "成交额": 318068.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.EIS"
    },
    {
        "序号": 4051,
        "name": "Morgan Stanley Series K Pfd",
        "最新价": 24.08,
        "涨跌额": 0.1,
        "涨跌幅": 0.42,
        "开盘价": 23.95,
        "最高价": 24.08,
        "最低价": 23.85,
        "昨收价": 23.98,
        "总市值": 963200.0,
        "市盈率": null,
        "成交量": 170221.0,
        "成交额": 4080246.0,
        "振幅": 0.96,
        "换手率": 425.55,
        "symbol": "106.MS_K"
    },
    {
        "序号": 4052,
        "name": "iShares MSCI Europe Small-Cap E",
        "最新价": 53.0,
        "涨跌额": 0.22,
        "涨跌幅": 0.42,
        "开盘价": 52.67,
        "最高价": 53.11,
        "最低价": 52.67,
        "昨收价": 52.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 4379.0,
        "成交额": 231766.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.IEUS"
    },
    {
        "序号": 4053,
        "name": "Liquidia Corp",
        "最新价": 7.23,
        "涨跌额": 0.03,
        "涨跌幅": 0.42,
        "开盘价": 7.2,
        "最高价": 7.35,
        "最低价": 7.11,
        "昨收价": 7.2,
        "总市值": 469266729.0,
        "市盈率": -8.15,
        "成交量": 296172.0,
        "成交额": 2143173.0,
        "振幅": 3.33,
        "换手率": 0.46,
        "symbol": "105.LQDA"
    },
    {
        "序号": 4054,
        "name": "SPDR S&P Health Care Equipment ",
        "最新价": 77.14,
        "涨跌额": 0.32,
        "涨跌幅": 0.42,
        "开盘价": 76.71,
        "最高价": 77.59,
        "最低价": 76.71,
        "昨收价": 76.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 30373.0,
        "成交额": 2340692.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.XHE"
    },
    {
        "序号": 4055,
        "name": "Vanguard S&P 500 Value ETF",
        "最新价": 161.54,
        "涨跌额": 0.67,
        "涨跌幅": 0.42,
        "开盘价": 160.62,
        "最高价": 161.77,
        "最低价": 160.62,
        "昨收价": 160.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 89538.0,
        "成交额": 14444575.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.VOOV"
    },
    {
        "序号": 4056,
        "name": "LAFFER TENGLER Equity Income ET",
        "最新价": 24.12,
        "涨跌额": 0.1,
        "涨跌幅": 0.42,
        "开盘价": 24.04,
        "最高价": 24.12,
        "最低价": 24.04,
        "昨收价": 24.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 841.0,
        "成交额": 20226.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.TGLR"
    },
    {
        "序号": 4057,
        "name": "FlexShares STOXX Global ESG Sel",
        "最新价": 144.97,
        "涨跌额": 0.6,
        "涨跌幅": 0.42,
        "开盘价": 144.97,
        "最高价": 144.97,
        "最低价": 144.97,
        "昨收价": 144.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 65.0,
        "成交额": 9423.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ESGG"
    },
    {
        "序号": 4058,
        "name": "欧洲小型红利股ETF-WisdomTree",
        "最新价": 55.62,
        "涨跌额": 0.23,
        "涨跌幅": 0.42,
        "开盘价": 55.29,
        "最高价": 55.69,
        "最低价": 55.29,
        "昨收价": 55.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 3131.0,
        "成交额": 173935.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.DFE"
    },
    {
        "序号": 4059,
        "name": "YieldMax PYPL Option Income Str",
        "最新价": 19.36,
        "涨跌额": 0.08,
        "涨跌幅": 0.41,
        "开盘价": 19.38,
        "最高价": 19.43,
        "最低价": 19.3,
        "昨收价": 19.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 8875.0,
        "成交额": 171889.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.PYPY"
    },
    {
        "序号": 4060,
        "name": "惠而浦",
        "最新价": 111.39,
        "涨跌额": 0.46,
        "涨跌幅": 0.41,
        "开盘价": 110.98,
        "最高价": 112.38,
        "最低价": 110.2,
        "昨收价": 110.93,
        "总市值": 6110076895.0,
        "市盈率": -3.79,
        "成交量": 827768.0,
        "成交额": 92195172.0,
        "振幅": 1.97,
        "换手率": 1.51,
        "symbol": "106.WHR"
    },
    {
        "序号": 4061,
        "name": "Procure Space ETF",
        "最新价": 16.96,
        "涨跌额": 0.07,
        "涨跌幅": 0.41,
        "开盘价": 16.89,
        "最高价": 17.05,
        "最低价": 16.8,
        "昨收价": 16.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 3797.0,
        "成交额": 64449.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "105.UFO"
    },
    {
        "序号": 4062,
        "name": "WisdomTree U.S. LargeCap Fund",
        "最新价": 48.5,
        "涨跌额": 0.2,
        "涨跌幅": 0.41,
        "开盘价": 48.21,
        "最高价": 48.58,
        "最低价": 48.21,
        "昨收价": 48.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 35233.0,
        "成交额": 1706466.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.EPS"
    },
    {
        "序号": 4063,
        "name": "VanEck CMCI Commodity Strategy ",
        "最新价": 24.25,
        "涨跌额": 0.1,
        "涨跌幅": 0.41,
        "开盘价": 24.25,
        "最高价": 24.25,
        "最低价": 24.25,
        "昨收价": 24.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 40.0,
        "成交额": 969.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CMCI"
    },
    {
        "序号": 4064,
        "name": "New York Mortgage Trust Inc Ser",
        "最新价": 19.44,
        "涨跌额": 0.08,
        "涨跌幅": 0.41,
        "开盘价": 19.4,
        "最高价": 19.51,
        "最低价": 19.4,
        "昨收价": 19.36,
        "总市值": 111589663.0,
        "市盈率": null,
        "成交量": 15339.0,
        "成交额": 297654.0,
        "振幅": 0.57,
        "换手率": 0.27,
        "symbol": "105.NYMTL"
    },
    {
        "序号": 4065,
        "name": "BNY Mellon US Large Cap Core Eq",
        "最新价": 87.48,
        "涨跌额": 0.36,
        "涨跌幅": 0.41,
        "开盘价": 86.94,
        "最高价": 87.55,
        "最低价": 86.94,
        "昨收价": 87.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 23044.0,
        "成交额": 2009891.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.BKLC"
    },
    {
        "序号": 4066,
        "name": "Franklin FTSE Australia ETF",
        "最新价": 26.74,
        "涨跌额": 0.11,
        "涨跌幅": 0.41,
        "开盘价": 26.74,
        "最高价": 26.74,
        "最低价": 26.74,
        "昨收价": 26.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 345.0,
        "成交额": 9224.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FLAU"
    },
    {
        "序号": 4067,
        "name": "署名银行",
        "最新价": 21.89,
        "涨跌额": 0.09,
        "涨跌幅": 0.41,
        "开盘价": 21.78,
        "最高价": 22.06,
        "最低价": 21.78,
        "昨收价": 21.8,
        "总市值": 956958848.0,
        "市盈率": 9.48,
        "成交量": 55001.0,
        "成交额": 1204789.0,
        "振幅": 1.28,
        "换手率": 0.13,
        "symbol": "106.BY"
    },
    {
        "序号": 4068,
        "name": "伯克希尔哈撒韦-A",
        "最新价": 539999.9,
        "涨跌额": 2219.9,
        "涨跌幅": 0.41,
        "开盘价": 538004.0,
        "最高价": 542143.99,
        "最低价": 534914.01,
        "昨收价": 537780.0,
        "总市值": 308731982827.0,
        "市盈率": 4.03,
        "成交量": 7636.0,
        "成交额": 4112378880.0,
        "振幅": 1.34,
        "换手率": 1.34,
        "symbol": "106.BRK_A"
    },
    {
        "序号": 4069,
        "name": "Boise Cascade Co",
        "最新价": 111.92,
        "涨跌额": 0.46,
        "涨跌幅": 0.41,
        "开盘价": 111.34,
        "最高价": 112.83,
        "最低价": 111.34,
        "昨收价": 111.46,
        "总市值": 4430893214.0,
        "市盈率": 8.8,
        "成交量": 201133.0,
        "成交额": 22534782.0,
        "振幅": 1.34,
        "换手率": 0.51,
        "symbol": "106.BCC"
    },
    {
        "序号": 4070,
        "name": "卡姆登国家银行",
        "最新价": 36.52,
        "涨跌额": 0.15,
        "涨跌幅": 0.41,
        "开盘价": 36.24,
        "最高价": 36.9,
        "最低价": 36.24,
        "昨收价": 36.37,
        "总市值": 531663163.0,
        "市盈率": 10.58,
        "成交量": 22712.0,
        "成交额": 830270.0,
        "振幅": 1.81,
        "换手率": 0.16,
        "symbol": "105.CAC"
    },
    {
        "序号": 4071,
        "name": "JPMorgan Active Value ETF",
        "最新价": 53.62,
        "涨跌额": 0.22,
        "涨跌幅": 0.41,
        "开盘价": 53.35,
        "最高价": 53.7,
        "最低价": 53.35,
        "昨收价": 53.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 96371.0,
        "成交额": 5164023.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.JAVA"
    },
    {
        "序号": 4072,
        "name": "德照科技",
        "最新价": 165.74,
        "涨跌额": 0.68,
        "涨跌幅": 0.41,
        "开盘价": 164.76,
        "最高价": 166.51,
        "最低价": 164.2,
        "昨收价": 165.06,
        "总市值": 8825268494.0,
        "市盈率": 32.28,
        "成交量": 220978.0,
        "成交额": 36599961.0,
        "振幅": 1.4,
        "换手率": 0.42,
        "symbol": "105.TTEK"
    },
    {
        "序号": 4073,
        "name": "iShares Self-Driving EV and Tec",
        "最新价": 34.15,
        "涨跌额": 0.14,
        "涨跌幅": 0.41,
        "开盘价": 33.91,
        "最高价": 34.31,
        "最低价": 33.87,
        "昨收价": 34.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 40542.0,
        "成交额": 1380140.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "107.IDRV"
    },
    {
        "序号": 4074,
        "name": "Franklin FTSE Canada ETF",
        "最新价": 31.74,
        "涨跌额": 0.13,
        "涨跌幅": 0.41,
        "开盘价": 31.63,
        "最高价": 31.8,
        "最低价": 31.57,
        "昨收价": 31.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 15267.0,
        "成交额": 483611.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.FLCA"
    },
    {
        "序号": 4075,
        "name": "标普中型成长股400ETF-iShares",
        "最新价": 75.69,
        "涨跌额": 0.31,
        "涨跌幅": 0.41,
        "开盘价": 75.34,
        "最高价": 76.03,
        "最低价": 75.33,
        "昨收价": 75.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 195154.0,
        "成交额": 14763635.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.IJK"
    },
    {
        "序号": 4076,
        "name": "RPT Realty",
        "最新价": 12.21,
        "涨跌额": 0.05,
        "涨跌幅": 0.41,
        "开盘价": 12.09,
        "最高价": 12.22,
        "最低价": 11.99,
        "昨收价": 12.16,
        "总市值": 1046553154.0,
        "市盈率": 19.52,
        "成交量": 869126.0,
        "成交额": 10551445.0,
        "振幅": 1.89,
        "换手率": 1.01,
        "symbol": "106.RPT"
    },
    {
        "序号": 4077,
        "name": "凯特地产信托",
        "最新价": 22.0,
        "涨跌额": 0.09,
        "涨跌幅": 0.41,
        "开盘价": 21.84,
        "最高价": 22.03,
        "最低价": 21.63,
        "昨收价": 21.91,
        "总市值": 4826521590.0,
        "市盈率": 125.71,
        "成交量": 1023857.0,
        "成交额": 22444163.0,
        "振幅": 1.83,
        "换手率": 0.47,
        "symbol": "106.KRG"
    },
    {
        "序号": 4078,
        "name": "Fidelity Small-Mid Factor ETF",
        "最新价": 34.24,
        "涨跌额": 0.14,
        "涨跌幅": 0.41,
        "开盘价": 34.1,
        "最高价": 34.36,
        "最低价": 34.1,
        "昨收价": 34.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 11422.0,
        "成交额": 390617.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.FSMD"
    },
    {
        "序号": 4079,
        "name": "社交媒体指数ETF-Global X",
        "最新价": 39.15,
        "涨跌额": 0.16,
        "涨跌幅": 0.41,
        "开盘价": 38.85,
        "最高价": 39.35,
        "最低价": 38.85,
        "昨收价": 38.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 16175.0,
        "成交额": 634242.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "105.SOCL"
    },
    {
        "序号": 4080,
        "name": "BlackRock Innovation and Growth",
        "最新价": 7.35,
        "涨跌额": 0.03,
        "涨跌幅": 0.41,
        "开盘价": 7.27,
        "最高价": 7.37,
        "最低价": 7.27,
        "昨收价": 7.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 963231.0,
        "成交额": 7068183.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "106.BIGZ"
    },
    {
        "序号": 4081,
        "name": "Primo Water Corp",
        "最新价": 14.71,
        "涨跌额": 0.06,
        "涨跌幅": 0.41,
        "开盘价": 14.66,
        "最高价": 14.77,
        "最低价": 14.55,
        "昨收价": 14.65,
        "总市值": 2344896740.0,
        "市盈率": 19.87,
        "成交量": 545461.0,
        "成交额": 8024664.0,
        "振幅": 1.5,
        "换手率": 0.34,
        "symbol": "106.PRMW"
    },
    {
        "序号": 4082,
        "name": "First Trust Senior Floating Rat",
        "最新价": 9.81,
        "涨跌额": 0.04,
        "涨跌幅": 0.41,
        "开盘价": 9.74,
        "最高价": 9.84,
        "最低价": 9.74,
        "昨收价": 9.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 50042.0,
        "成交额": 490590.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.FCT"
    },
    {
        "序号": 4083,
        "name": "SPDR MSCI EAFE StrategicFactors",
        "最新价": 71.15,
        "涨跌额": 0.29,
        "涨跌幅": 0.41,
        "开盘价": 70.69,
        "最高价": 71.15,
        "最低价": 70.69,
        "昨收价": 70.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 36904.0,
        "成交额": 2619204.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.QEFA"
    },
    {
        "序号": 4084,
        "name": "Matthews Emerging Markets Equit",
        "最新价": 26.99,
        "涨跌额": 0.11,
        "涨跌幅": 0.41,
        "开盘价": 26.95,
        "最高价": 27.0,
        "最低价": 26.9,
        "昨收价": 26.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 1262.0,
        "成交额": 34040.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.MEM"
    },
    {
        "序号": 4085,
        "name": "VanEck Long\/Flat Trend ETF",
        "最新价": 39.28,
        "涨跌额": 0.16,
        "涨跌幅": 0.41,
        "开盘价": 39.28,
        "最高价": 39.28,
        "最低价": 39.28,
        "昨收价": 39.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 78.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LFEQ"
    },
    {
        "序号": 4086,
        "name": "Pacer Metaurus US Large Cap Div",
        "最新价": 39.29,
        "涨跌额": 0.16,
        "涨跌幅": 0.41,
        "开盘价": 39.29,
        "最高价": 39.29,
        "最低价": 39.29,
        "昨收价": 39.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 117.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TRPL"
    },
    {
        "序号": 4087,
        "name": "Columbia Research Enhanced Core",
        "最新价": 27.06,
        "涨跌额": 0.11,
        "涨跌幅": 0.41,
        "开盘价": 26.89,
        "最高价": 27.07,
        "最低价": 26.88,
        "昨收价": 26.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 40982.0,
        "成交额": 1105269.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.RECS"
    },
    {
        "序号": 4088,
        "name": "ClearBridge Sustainable Infrast",
        "最新价": 24.65,
        "涨跌额": 0.1,
        "涨跌幅": 0.41,
        "开盘价": 24.52,
        "最高价": 24.65,
        "最低价": 24.52,
        "昨收价": 24.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 5413.0,
        "成交额": 132726.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.INFR"
    },
    {
        "序号": 4089,
        "name": "John Hancock Multifactor Large ",
        "最新价": 56.8,
        "涨跌额": 0.23,
        "涨跌幅": 0.41,
        "开盘价": 56.54,
        "最高价": 56.88,
        "最低价": 56.54,
        "昨收价": 56.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 19103.0,
        "成交额": 1082722.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.JHML"
    },
    {
        "序号": 4090,
        "name": "LHA Market State Tactical Q ETF",
        "最新价": 27.17,
        "涨跌额": 0.11,
        "涨跌幅": 0.41,
        "开盘价": 27.18,
        "最高价": 27.18,
        "最低价": 27.17,
        "昨收价": 27.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 8486.0,
        "成交额": 230649.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.MSTQ"
    },
    {
        "序号": 4091,
        "name": "Global X U.S. Infrastructure De",
        "最新价": 32.14,
        "涨跌额": 0.13,
        "涨跌幅": 0.41,
        "开盘价": 31.96,
        "最高价": 32.33,
        "最低价": 31.96,
        "昨收价": 32.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 505585.0,
        "成交额": 16267597.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.PAVE"
    },
    {
        "序号": 4092,
        "name": "巴克尔",
        "最新价": 42.05,
        "涨跌额": 0.17,
        "涨跌幅": 0.41,
        "开盘价": 41.64,
        "最高价": 42.35,
        "最低价": 41.64,
        "昨收价": 41.88,
        "总市值": 2121228481.0,
        "市盈率": 9.3,
        "成交量": 370444.0,
        "成交额": 15580177.0,
        "振幅": 1.7,
        "换手率": 0.73,
        "symbol": "106.BKE"
    },
    {
        "序号": 4093,
        "name": "穆格-A",
        "最新价": 138.6,
        "涨跌额": 0.56,
        "涨跌幅": 0.41,
        "开盘价": 137.77,
        "最高价": 139.18,
        "最低价": 136.89,
        "昨收价": 138.04,
        "总市值": 4419577008.0,
        "市盈率": 25.85,
        "成交量": 185472.0,
        "成交额": 25631253.0,
        "振幅": 1.66,
        "换手率": 0.65,
        "symbol": "106.MOG_A"
    },
    {
        "序号": 4094,
        "name": "GEN Restaurant Group Inc-A",
        "最新价": 7.43,
        "涨跌额": 0.03,
        "涨跌幅": 0.41,
        "开盘价": 7.45,
        "最高价": 7.48,
        "最低价": 7.11,
        "昨收价": 7.4,
        "总市值": 239852035.0,
        "市盈率": 29.17,
        "成交量": 21170.0,
        "成交额": 156385.0,
        "振幅": 5.0,
        "换手率": 0.07,
        "symbol": "105.GENK"
    },
    {
        "序号": 4095,
        "name": "QRAFT AI-Pilot U.S. Large Cap D",
        "最新价": 27.25,
        "涨跌额": 0.11,
        "涨跌幅": 0.41,
        "开盘价": 27.25,
        "最高价": 27.25,
        "最低价": 27.25,
        "昨收价": 27.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 30.0,
        "成交额": 817.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AIDB"
    },
    {
        "序号": 4096,
        "name": "Invesco S&P 100 Equal Weight ET",
        "最新价": 84.29,
        "涨跌额": 0.34,
        "涨跌幅": 0.41,
        "开盘价": 83.93,
        "最高价": 84.43,
        "最低价": 83.91,
        "昨收价": 83.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 46335.0,
        "成交额": 3901342.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.EQWL"
    },
    {
        "序号": 4097,
        "name": "百世集团",
        "最新价": 2.48,
        "涨跌额": 0.01,
        "涨跌幅": 0.4,
        "开盘价": 2.49,
        "最高价": 2.5,
        "最低价": 2.48,
        "昨收价": 2.47,
        "总市值": 49302400.0,
        "市盈率": -0.36,
        "成交量": 2964.0,
        "成交额": 7364.0,
        "振幅": 0.81,
        "换手率": 0.01,
        "symbol": "106.BEST"
    },
    {
        "序号": 4098,
        "name": "Fidelity Disruptors ETF",
        "最新价": 24.81,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 24.57,
        "最高价": 24.9,
        "最低价": 24.57,
        "昨收价": 24.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 19313.0,
        "成交额": 478088.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "105.FDIF"
    },
    {
        "序号": 4099,
        "name": "罗素1000价值股ETF-iShares",
        "最新价": 158.79,
        "涨跌额": 0.64,
        "涨跌幅": 0.4,
        "开盘价": 158.19,
        "最高价": 159.05,
        "最低价": 158.05,
        "昨收价": 158.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3410650.0,
        "成交额": 540862048.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.IWD"
    },
    {
        "序号": 4100,
        "name": "iShares Russell Top 200 ETF",
        "最新价": 111.73,
        "涨跌额": 0.45,
        "涨跌幅": 0.4,
        "开盘价": 111.03,
        "最高价": 111.84,
        "最低价": 111.01,
        "昨收价": 111.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 40158.0,
        "成交额": 4476199.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.IWL"
    },
    {
        "序号": 4101,
        "name": "RH Hedged Multi-Asset Income ET",
        "最新价": 7.45,
        "涨跌额": 0.03,
        "涨跌幅": 0.4,
        "开盘价": 7.43,
        "最高价": 7.46,
        "最低价": 7.43,
        "昨收价": 7.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 7190.0,
        "成交额": 53511.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.AMAX"
    },
    {
        "序号": 4102,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.32,
        "涨跌额": 0.11,
        "涨跌幅": 0.4,
        "开盘价": 27.26,
        "最高价": 27.36,
        "最低价": 27.22,
        "昨收价": 27.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 8969.0,
        "成交额": 244713.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.MAYT"
    },
    {
        "序号": 4103,
        "name": "ReposiTrak",
        "最新价": 9.95,
        "涨跌额": 0.04,
        "涨跌幅": 0.4,
        "开盘价": 9.9,
        "最高价": 10.09,
        "最低价": 9.75,
        "昨收价": 9.91,
        "总市值": 180674140.0,
        "市盈率": 31.79,
        "成交量": 62898.0,
        "成交额": 624205.0,
        "振幅": 3.43,
        "换手率": 0.35,
        "symbol": "106.TRAK"
    },
    {
        "序号": 4104,
        "name": "Xtrackers Net Zero Pathway Pari",
        "最新价": 29.86,
        "涨跌额": 0.12,
        "涨跌幅": 0.4,
        "开盘价": 29.71,
        "最高价": 29.86,
        "最低价": 29.71,
        "昨收价": 29.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 54000.0,
        "成交额": 1604340.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.USNZ"
    },
    {
        "序号": 4105,
        "name": "WhiteHorse Finance Inc Notes",
        "最新价": 24.9,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 24.9,
        "最高价": 24.91,
        "最低价": 24.8,
        "昨收价": 24.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 4008.0,
        "成交额": 99664.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.WHFCL"
    },
    {
        "序号": 4106,
        "name": "GAMCO Natural Resources, Gold &",
        "最新价": 4.98,
        "涨跌额": 0.02,
        "涨跌幅": 0.4,
        "开盘价": 4.96,
        "最高价": 4.99,
        "最低价": 4.96,
        "昨收价": 4.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 48702.0,
        "成交额": 241937.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "106.GNT"
    },
    {
        "序号": 4107,
        "name": "WisdomTree Global High Dividend",
        "最新价": 47.33,
        "涨跌额": 0.19,
        "涨跌幅": 0.4,
        "开盘价": 47.17,
        "最高价": 47.34,
        "最低价": 47.13,
        "昨收价": 47.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5122.0,
        "成交额": 242109.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.DEW"
    },
    {
        "序号": 4108,
        "name": "SmartETFs Dividend Builder ETF",
        "最新价": 24.94,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 24.96,
        "最高价": 24.96,
        "最低价": 24.87,
        "昨收价": 24.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 4600.0,
        "成交额": 114552.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.DIVS"
    },
    {
        "序号": 4109,
        "name": "Tri Continental Corporation",
        "最新价": 27.44,
        "涨跌额": 0.11,
        "涨跌幅": 0.4,
        "开盘价": 27.24,
        "最高价": 27.59,
        "最低价": 27.24,
        "昨收价": 27.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 42575.0,
        "成交额": 1166712.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "106.TY"
    },
    {
        "序号": 4110,
        "name": "Liberty All-Star Growth Fund",
        "最新价": 4.99,
        "涨跌额": 0.02,
        "涨跌幅": 0.4,
        "开盘价": 4.96,
        "最高价": 4.99,
        "最低价": 4.94,
        "昨收价": 4.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 277460.0,
        "成交额": 1376757.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "106.ASG"
    },
    {
        "序号": 4111,
        "name": "Open Text Corp",
        "最新价": 39.93,
        "涨跌额": 0.16,
        "涨跌幅": 0.4,
        "开盘价": 39.59,
        "最高价": 40.14,
        "最低价": 39.59,
        "昨收价": 39.77,
        "总市值": 10841583089.0,
        "市盈率": 31.14,
        "成交量": 322153.0,
        "成交额": 12854902.0,
        "振幅": 1.38,
        "换手率": 0.12,
        "symbol": "105.OTEX"
    },
    {
        "序号": 4112,
        "name": "USCF SummerHaven Dynamic Commod",
        "最新价": 17.49,
        "涨跌额": 0.07,
        "涨跌幅": 0.4,
        "开盘价": 17.31,
        "最高价": 17.56,
        "最低价": 17.31,
        "昨收价": 17.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 2776.0,
        "成交额": 48356.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "107.SDCI"
    },
    {
        "序号": 4113,
        "name": "Xtrackers S&P ESG Value ETF",
        "最新价": 27.49,
        "涨跌额": 0.11,
        "涨跌幅": 0.4,
        "开盘价": 27.53,
        "最高价": 27.54,
        "最低价": 27.49,
        "昨收价": 27.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 2945.0,
        "成交额": 81090.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.SNPV"
    },
    {
        "序号": 4114,
        "name": "Farmland Partners Inc",
        "最新价": 12.5,
        "涨跌额": 0.05,
        "涨跌幅": 0.4,
        "开盘价": 12.39,
        "最高价": 12.5,
        "最低价": 12.29,
        "昨收价": 12.45,
        "总市值": 602324363.0,
        "市盈率": 29.88,
        "成交量": 314149.0,
        "成交额": 3909588.0,
        "振幅": 1.69,
        "换手率": 0.65,
        "symbol": "106.FPI"
    },
    {
        "序号": 4115,
        "name": "Day Hagan\/Ned Davis Research Sm",
        "最新价": 35.03,
        "涨跌额": 0.14,
        "涨跌幅": 0.4,
        "开盘价": 34.84,
        "最高价": 35.05,
        "最低价": 34.84,
        "昨收价": 34.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 47674.0,
        "成交额": 1666872.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.SSUS"
    },
    {
        "序号": 4116,
        "name": "Calvert US Large-Cap Core Respo",
        "最新价": 57.55,
        "涨跌额": 0.23,
        "涨跌幅": 0.4,
        "开盘价": 57.2,
        "最高价": 57.58,
        "最低价": 57.2,
        "昨收价": 57.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 2651.0,
        "成交额": 152295.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.CVLC"
    },
    {
        "序号": 4117,
        "name": "加拿大ETF-iShares MSCI",
        "最新价": 35.06,
        "涨跌额": 0.14,
        "涨跌幅": 0.4,
        "开盘价": 34.9,
        "最高价": 35.2,
        "最低价": 34.89,
        "昨收价": 34.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 922150.0,
        "成交额": 32329276.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.EWC"
    },
    {
        "序号": 4118,
        "name": "FTAI Aviation Ltd Series D Pfd",
        "最新价": 25.1,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 25.06,
        "最高价": 25.1,
        "最低价": 25.06,
        "昨收价": 25.0,
        "总市值": 65260000.0,
        "市盈率": null,
        "成交量": 1092.0,
        "成交额": 27403.0,
        "振幅": 0.16,
        "换手率": 0.04,
        "symbol": "105.FTAIM"
    },
    {
        "序号": 4119,
        "name": "Avantis U.S. Large Cap Value ET",
        "最新价": 55.23,
        "涨跌额": 0.22,
        "涨跌幅": 0.4,
        "开盘价": 54.99,
        "最高价": 55.38,
        "最低价": 54.97,
        "昨收价": 55.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 286779.0,
        "成交额": 15838139.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.AVLV"
    },
    {
        "序号": 4120,
        "name": "ProShares Short MSCI Emerging M",
        "最新价": 15.07,
        "涨跌额": 0.06,
        "涨跌幅": 0.4,
        "开盘价": 15.09,
        "最高价": 15.13,
        "最低价": 15.04,
        "昨收价": 15.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 27837.0,
        "成交额": 419566.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.EUM"
    },
    {
        "序号": 4121,
        "name": "Motley Fool Capital Efficiency ",
        "最新价": 20.1,
        "涨跌额": 0.08,
        "涨跌幅": 0.4,
        "开盘价": 20.05,
        "最高价": 20.12,
        "最低价": 19.96,
        "昨收价": 20.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 8166.0,
        "成交额": 163726.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.TMFE"
    },
    {
        "序号": 4122,
        "name": "Constellium SE-A",
        "最新价": 17.6,
        "涨跌额": 0.07,
        "涨跌幅": 0.4,
        "开盘价": 17.44,
        "最高价": 17.8,
        "最低价": 17.38,
        "昨收价": 17.53,
        "总市值": 2584029958.0,
        "市盈率": 14.89,
        "成交量": 425808.0,
        "成交额": 7506131.0,
        "振幅": 2.4,
        "换手率": 0.29,
        "symbol": "106.CSTM"
    },
    {
        "序号": 4123,
        "name": "Dutch Bros Inc-A",
        "最新价": 27.66,
        "涨跌额": 0.11,
        "涨跌幅": 0.4,
        "开盘价": 27.5,
        "最高价": 27.83,
        "最低价": 27.14,
        "昨收价": 27.55,
        "总市值": 4899011521.0,
        "市盈率": 1993.9,
        "成交量": 781038.0,
        "成交额": 21465912.0,
        "振幅": 2.5,
        "换手率": 0.44,
        "symbol": "106.BROS"
    },
    {
        "序号": 4124,
        "name": "iShares U.S. Consumer Discretio",
        "最新价": 72.97,
        "涨跌额": 0.29,
        "涨跌幅": 0.4,
        "开盘价": 72.4,
        "最高价": 73.07,
        "最低价": 72.4,
        "昨收价": 72.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 110742.0,
        "成交额": 8073004.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.IYC"
    },
    {
        "序号": 4125,
        "name": "二倍做多医疗ETF-ProShares",
        "最新价": 85.71,
        "涨跌额": 0.34,
        "涨跌幅": 0.4,
        "开盘价": 84.7,
        "最高价": 85.71,
        "最低价": 84.7,
        "昨收价": 85.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 719.0,
        "成交额": 61172.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.RXL"
    },
    {
        "序号": 4126,
        "name": "Cambiar Aggressive Value ETF",
        "最新价": 25.24,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 25.17,
        "最高价": 25.24,
        "最低价": 25.17,
        "昨收价": 25.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 1114.0,
        "成交额": 28065.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.CAMX"
    },
    {
        "序号": 4127,
        "name": "Goldman Sachs ActiveBeta U.S. L",
        "最新价": 90.88,
        "涨跌额": 0.36,
        "涨跌幅": 0.4,
        "开盘价": 90.34,
        "最高价": 90.97,
        "最低价": 90.34,
        "昨收价": 90.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 265096.0,
        "成交额": 24042576.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.GSLC"
    },
    {
        "序号": 4128,
        "name": "Parametric Equity Premium Incom",
        "最新价": 25.25,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 25.17,
        "最高价": 25.26,
        "最低价": 25.17,
        "昨收价": 25.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 5595.0,
        "成交额": 141070.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.PAPI"
    },
    {
        "序号": 4129,
        "name": "HCI集团",
        "最新价": 88.4,
        "涨跌额": 0.35,
        "涨跌幅": 0.4,
        "开盘价": 88.0,
        "最高价": 88.77,
        "最低价": 86.64,
        "昨收价": 88.05,
        "总市值": 759195377.0,
        "市盈率": 17.88,
        "成交量": 237452.0,
        "成交额": 20855590.0,
        "振幅": 2.42,
        "换手率": 2.76,
        "symbol": "106.HCI"
    },
    {
        "序号": 4130,
        "name": "Calvert US Mid-Cap Core Respons",
        "最新价": 50.52,
        "涨跌额": 0.2,
        "涨跌幅": 0.4,
        "开盘价": 50.52,
        "最高价": 50.52,
        "最低价": 50.52,
        "昨收价": 50.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 522.0,
        "成交额": 26371.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CVMC"
    },
    {
        "序号": 4131,
        "name": "价值股指数ETF-Vanguard",
        "最新价": 144.16,
        "涨跌额": 0.57,
        "涨跌幅": 0.4,
        "开盘价": 143.72,
        "最高价": 144.43,
        "最低价": 143.48,
        "昨收价": 143.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 1939325.0,
        "成交额": 279188672.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.VTV"
    },
    {
        "序号": 4132,
        "name": "DriveWealth ICE 100 Index ETF",
        "最新价": 25.31,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 25.31,
        "最高价": 25.31,
        "最低价": 25.31,
        "昨收价": 25.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 25.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CETF"
    },
    {
        "序号": 4133,
        "name": "New York Mortgage Trust Inc Ser",
        "最新价": 22.78,
        "涨跌额": 0.09,
        "涨跌幅": 0.4,
        "开盘价": 22.93,
        "最高价": 22.98,
        "最低价": 22.78,
        "昨收价": 22.69,
        "总市值": 167276980.0,
        "市盈率": null,
        "成交量": 4190.0,
        "成交额": 96085.0,
        "振幅": 0.88,
        "换手率": 0.06,
        "symbol": "105.NYMTM"
    },
    {
        "序号": 4134,
        "name": "罗素中型成长股ETF-iShares",
        "最新价": 98.72,
        "涨跌额": 0.39,
        "涨跌幅": 0.4,
        "开盘价": 98.16,
        "最高价": 99.01,
        "最低价": 98.02,
        "昨收价": 98.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 1352149.0,
        "成交额": 133469478.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.IWP"
    },
    {
        "序号": 4135,
        "name": "RiverFront Dynamic US Flex-Cap ",
        "最新价": 45.59,
        "涨跌额": 0.18,
        "涨跌幅": 0.4,
        "开盘价": 45.56,
        "最高价": 45.59,
        "最低价": 45.56,
        "昨收价": 45.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1152.0,
        "成交额": 52498.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.RFFC"
    },
    {
        "序号": 4136,
        "name": "Large Cap US Equity Select ETF",
        "最新价": 32.93,
        "涨跌额": 0.13,
        "涨跌幅": 0.4,
        "开盘价": 32.93,
        "最高价": 32.93,
        "最低价": 32.93,
        "昨收价": 32.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 122.0,
        "成交额": 4017.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RNLC"
    },
    {
        "序号": 4137,
        "name": "SPDR Portfolio S&P 500 Growth E",
        "最新价": 63.37,
        "涨跌额": 0.25,
        "涨跌幅": 0.4,
        "开盘价": 62.94,
        "最高价": 63.41,
        "最低价": 62.93,
        "昨收价": 63.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 1194323.0,
        "成交额": 75532357.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.SPYG"
    },
    {
        "序号": 4138,
        "name": "Clough Global Dividend and Inco",
        "最新价": 5.07,
        "涨跌额": 0.02,
        "涨跌幅": 0.4,
        "开盘价": 5.06,
        "最高价": 5.09,
        "最低价": 5.04,
        "昨收价": 5.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 15790.0,
        "成交额": 79973.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.GLV"
    },
    {
        "序号": 4139,
        "name": "辉盛研究系统",
        "最新价": 443.89,
        "涨跌额": 1.75,
        "涨跌幅": 0.4,
        "开盘价": 439.96,
        "最高价": 444.64,
        "最低价": 439.03,
        "昨收价": 442.14,
        "总市值": 16862695734.0,
        "市盈率": 36.02,
        "成交量": 228773.0,
        "成交额": 101205810.0,
        "振幅": 1.27,
        "换手率": 0.6,
        "symbol": "106.FDS"
    },
    {
        "序号": 4140,
        "name": "安森美半导体",
        "最新价": 76.14,
        "涨跌额": 0.3,
        "涨跌幅": 0.4,
        "开盘价": 75.57,
        "最高价": 77.25,
        "最低价": 75.52,
        "昨收价": 75.84,
        "总市值": 32793314274.0,
        "市盈率": 14.74,
        "成交量": 4584062.0,
        "成交额": 350102720.0,
        "振幅": 2.28,
        "换手率": 1.06,
        "symbol": "105.ON"
    },
    {
        "序号": 4141,
        "name": "Simplify US Equity PLUS Downsid",
        "最新价": 27.92,
        "涨跌额": 0.11,
        "涨跌幅": 0.4,
        "开盘价": 27.84,
        "最高价": 28.01,
        "最低价": 27.78,
        "昨收价": 27.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 76968.0,
        "成交额": 2147591.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.SPD"
    },
    {
        "序号": 4142,
        "name": "宾州中部银行",
        "最新价": 22.85,
        "涨跌额": 0.09,
        "涨跌幅": 0.4,
        "开盘价": 22.72,
        "最高价": 23.08,
        "最低价": 22.46,
        "昨收价": 22.76,
        "总市值": 380876802.0,
        "市盈率": 9.29,
        "成交量": 17902.0,
        "成交额": 408297.0,
        "振幅": 2.72,
        "换手率": 0.11,
        "symbol": "105.MPB"
    },
    {
        "序号": 4143,
        "name": "The Goldman Sachs Group Inc Ser",
        "最新价": 25.4,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "开盘价": 25.35,
        "最高价": 25.4,
        "最低价": 25.26,
        "昨收价": 25.3,
        "总市值": 711200.0,
        "市盈率": null,
        "成交量": 54823.0,
        "成交额": 1386375.0,
        "振幅": 0.55,
        "换手率": 195.8,
        "symbol": "106.GS_K"
    },
    {
        "序号": 4144,
        "name": "CI&T Inc-A",
        "最新价": 5.08,
        "涨跌额": 0.02,
        "涨跌幅": 0.4,
        "开盘价": 4.91,
        "最高价": 5.1,
        "最低价": 4.91,
        "昨收价": 5.06,
        "总市值": 673330139.0,
        "市盈率": 19.81,
        "成交量": 15894.0,
        "成交额": 80247.0,
        "振幅": 3.75,
        "换手率": 0.01,
        "symbol": "106.CINT"
    },
    {
        "序号": 4145,
        "name": "iShares Paris-Aligned Climate M",
        "最新价": 50.81,
        "涨跌额": 0.2,
        "涨跌幅": 0.4,
        "开盘价": 50.71,
        "最高价": 50.81,
        "最低价": 50.71,
        "昨收价": 50.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 1537.0,
        "成交额": 78045.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.PABU"
    },
    {
        "序号": 4146,
        "name": "Simmons First National Corp",
        "最新价": 17.79,
        "涨跌额": 0.07,
        "涨跌幅": 0.4,
        "开盘价": 17.76,
        "最高价": 18.04,
        "最低价": 17.56,
        "昨收价": 17.72,
        "总市值": 2226749341.0,
        "市盈率": 9.5,
        "成交量": 500930.0,
        "成交额": 8918464.0,
        "振幅": 2.71,
        "换手率": 0.4,
        "symbol": "105.SFNC"
    },
    {
        "序号": 4147,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.96,
        "涨跌额": 0.11,
        "涨跌幅": 0.39,
        "开盘价": 27.84,
        "最高价": 27.99,
        "最低价": 27.82,
        "昨收价": 27.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 109384.0,
        "成交额": 3055397.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.DECT"
    },
    {
        "序号": 4148,
        "name": "Tortoise Energy Infrastructure ",
        "最新价": 27.97,
        "涨跌额": 0.11,
        "涨跌幅": 0.39,
        "开盘价": 27.81,
        "最高价": 28.14,
        "最低价": 27.81,
        "昨收价": 27.86,
        "总市值": 3538222313.0,
        "市盈率": null,
        "成交量": 82051.0,
        "成交额": 2297711.0,
        "振幅": 1.18,
        "换手率": 0.06,
        "symbol": "106.TYG"
    },
    {
        "序号": 4149,
        "name": "Logan Capital Broad Innovative ",
        "最新价": 38.16,
        "涨跌额": 0.15,
        "涨跌幅": 0.39,
        "开盘价": 38.13,
        "最高价": 38.16,
        "最低价": 38.13,
        "昨收价": 38.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 456.0,
        "成交额": 17387.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.LCLG"
    },
    {
        "序号": 4150,
        "name": "Gotham Enhanced 500 ETF",
        "最新价": 25.46,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.42,
        "最高价": 25.48,
        "最低价": 25.42,
        "昨收价": 25.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 261.0,
        "成交额": 6642.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.GSPY"
    },
    {
        "序号": 4151,
        "name": "Keros Therapeutics Inc",
        "最新价": 35.65,
        "涨跌额": 0.14,
        "涨跌幅": 0.39,
        "开盘价": 35.89,
        "最高价": 36.85,
        "最低价": 34.97,
        "昨收价": 35.51,
        "总市值": 1068051826.0,
        "市盈率": -7.5,
        "成交量": 325560.0,
        "成交额": 11636355.0,
        "振幅": 5.29,
        "换手率": 1.09,
        "symbol": "105.KROS"
    },
    {
        "序号": 4152,
        "name": "Global X NASDAQ 100 Collar 95-1",
        "最新价": 25.48,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.48,
        "最高价": 25.48,
        "最低价": 25.48,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 15.0,
        "成交额": 382.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.QCLR"
    },
    {
        "序号": 4153,
        "name": "新韩金融",
        "最新价": 28.03,
        "涨跌额": 0.11,
        "涨跌幅": 0.39,
        "开盘价": 27.94,
        "最高价": 28.24,
        "最低价": 27.94,
        "昨收价": 27.92,
        "总市值": 14449404371.0,
        "市盈率": 4.38,
        "成交量": 95758.0,
        "成交额": 2687896.0,
        "振幅": 1.07,
        "换手率": 0.02,
        "symbol": "106.SHG"
    },
    {
        "序号": 4154,
        "name": "iShares MSCI USA ESG Select ETF",
        "最新价": 96.85,
        "涨跌额": 0.38,
        "涨跌幅": 0.39,
        "开盘价": 96.33,
        "最高价": 96.97,
        "最低价": 96.31,
        "昨收价": 96.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 168965.0,
        "成交额": 16343054.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.SUSA"
    },
    {
        "序号": 4155,
        "name": "Invesco DWA Healthcare Momentum",
        "最新价": 35.71,
        "涨跌额": 0.14,
        "涨跌幅": 0.39,
        "开盘价": 35.52,
        "最高价": 36.0,
        "最低价": 35.52,
        "昨收价": 35.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 15084.0,
        "成交额": 539327.0,
        "振幅": 1.35,
        "换手率": null,
        "symbol": "105.PTH"
    },
    {
        "序号": 4156,
        "name": "Alger Weatherbie Enduring Growt",
        "最新价": 20.42,
        "涨跌额": 0.08,
        "涨跌幅": 0.39,
        "开盘价": 20.42,
        "最高价": 20.42,
        "最低价": 20.42,
        "昨收价": 20.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 37.0,
        "成交额": 755.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AWEG"
    },
    {
        "序号": 4157,
        "name": "LGI Homes Inc",
        "最新价": 119.97,
        "涨跌额": 0.47,
        "涨跌幅": 0.39,
        "开盘价": 120.04,
        "最高价": 121.43,
        "最低价": 119.6,
        "昨收价": 119.5,
        "总市值": 2826957244.0,
        "市盈率": 15.6,
        "成交量": 360905.0,
        "成交额": 43427694.0,
        "振幅": 1.53,
        "换手率": 1.53,
        "symbol": "105.LGIH"
    },
    {
        "序号": 4158,
        "name": "Navios Maritime Partners LP",
        "最新价": 25.53,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.29,
        "最高价": 25.75,
        "最低价": 25.29,
        "昨收价": 25.43,
        "总市值": 770607426.0,
        "市盈率": 1.31,
        "成交量": 56741.0,
        "成交额": 1447611.0,
        "振幅": 1.81,
        "换手率": 0.19,
        "symbol": "106.NMM"
    },
    {
        "序号": 4159,
        "name": "Simplify Hedged Equity ETF",
        "最新价": 25.53,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.45,
        "最高价": 25.55,
        "最低价": 25.44,
        "昨收价": 25.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 53119.0,
        "成交额": 1355546.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.HEQT"
    },
    {
        "序号": 4160,
        "name": "Cohen & Steers Tax-Advantaged P",
        "最新价": 17.88,
        "涨跌额": 0.07,
        "涨跌幅": 0.39,
        "开盘价": 17.76,
        "最高价": 17.88,
        "最低价": 17.69,
        "昨收价": 17.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 189289.0,
        "成交额": 3364767.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "106.PTA"
    },
    {
        "序号": 4161,
        "name": "Invesco S&P 500 Downside Hedged",
        "最新价": 33.22,
        "涨跌额": 0.13,
        "涨跌幅": 0.39,
        "开盘价": 33.01,
        "最高价": 33.27,
        "最低价": 33.01,
        "昨收价": 33.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 10475.0,
        "成交额": 347863.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.PHDG"
    },
    {
        "序号": 4162,
        "name": "Sprott Focus Trust",
        "最新价": 7.67,
        "涨跌额": 0.03,
        "涨跌幅": 0.39,
        "开盘价": 7.61,
        "最高价": 7.71,
        "最低价": 7.61,
        "昨收价": 7.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 38452.0,
        "成交额": 294389.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "105.FUND"
    },
    {
        "序号": 4163,
        "name": "Burney U.S. Factor Rotation ETF",
        "最新价": 30.68,
        "涨跌额": 0.12,
        "涨跌幅": 0.39,
        "开盘价": 30.71,
        "最高价": 30.74,
        "最低价": 30.51,
        "昨收价": 30.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 15628.0,
        "成交额": 479214.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.BRNY"
    },
    {
        "序号": 4164,
        "name": "Inspire International ETF",
        "最新价": 28.14,
        "涨跌额": 0.11,
        "涨跌幅": 0.39,
        "开盘价": 28.01,
        "最高价": 28.2,
        "最低价": 28.01,
        "昨收价": 28.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 12707.0,
        "成交额": 357444.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.WWJD"
    },
    {
        "序号": 4165,
        "name": "RiverNorth\/DoubleLine Strategic",
        "最新价": 17.92,
        "涨跌额": 0.07,
        "涨跌幅": 0.39,
        "开盘价": 17.85,
        "最高价": 18.0,
        "最低价": 17.85,
        "昨收价": 17.85,
        "总市值": 43008000.0,
        "市盈率": null,
        "成交量": 17190.0,
        "成交额": 308551.0,
        "振幅": 0.84,
        "换手率": 0.72,
        "symbol": "106.OPP_A"
    },
    {
        "序号": 4166,
        "name": "Carlyle Credit Income Fund",
        "最新价": 7.69,
        "涨跌额": 0.03,
        "涨跌幅": 0.39,
        "开盘价": 7.7,
        "最高价": 7.77,
        "最低价": 7.68,
        "昨收价": 7.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 96006.0,
        "成交额": 741428.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "106.CCIF"
    },
    {
        "序号": 4167,
        "name": "Fidelity Enhanced Large Cap Val",
        "最新价": 25.64,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.45,
        "最高价": 25.69,
        "最低价": 25.45,
        "昨收价": 25.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 82296.0,
        "成交额": 2109166.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.FELV"
    },
    {
        "序号": 4168,
        "name": "JPMorgan U.S. Quality Factor ET",
        "最新价": 46.18,
        "涨跌额": 0.18,
        "涨跌幅": 0.39,
        "开盘价": 45.93,
        "最高价": 46.22,
        "最低价": 45.93,
        "昨收价": 46.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 510766.0,
        "成交额": 23548091.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.JQUA"
    },
    {
        "序号": 4169,
        "name": "寇蒂斯莱特",
        "最新价": 215.59,
        "涨跌额": 0.84,
        "涨跌幅": 0.39,
        "开盘价": 214.75,
        "最高价": 216.6,
        "最低价": 213.73,
        "昨收价": 214.75,
        "总市值": 8244424189.0,
        "市盈率": 23.99,
        "成交量": 119810.0,
        "成交额": 25825198.0,
        "振幅": 1.34,
        "换手率": 0.31,
        "symbol": "106.CW"
    },
    {
        "序号": 4170,
        "name": "Invesco S&P 500 Pure Growth ETF",
        "最新价": 30.8,
        "涨跌额": 0.12,
        "涨跌幅": 0.39,
        "开盘价": 30.7,
        "最高价": 30.89,
        "最低价": 30.66,
        "昨收价": 30.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 149543.0,
        "成交额": 4601854.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.RPG"
    },
    {
        "序号": 4171,
        "name": "First Trust Active Global Quali",
        "最新价": 12.84,
        "涨跌额": 0.05,
        "涨跌幅": 0.39,
        "开盘价": 12.76,
        "最高价": 12.84,
        "最低价": 12.76,
        "昨收价": 12.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 16655.0,
        "成交额": 213505.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.AGQI"
    },
    {
        "序号": 4172,
        "name": "Diversified Healthcare Trust No",
        "最新价": 15.42,
        "涨跌额": 0.06,
        "涨跌幅": 0.39,
        "开盘价": 15.35,
        "最高价": 15.45,
        "最低价": 15.06,
        "昨收价": 15.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 12997.0,
        "成交额": 199847.0,
        "振幅": 2.54,
        "换手率": null,
        "symbol": "105.DHCNI"
    },
    {
        "序号": 4173,
        "name": "加拿大航空电子设备",
        "最新价": 20.58,
        "涨跌额": 0.08,
        "涨跌幅": 0.39,
        "开盘价": 20.43,
        "最高价": 20.67,
        "最低价": 20.43,
        "昨收价": 20.5,
        "总市值": 6550072396.0,
        "市盈率": 29.36,
        "成交量": 195470.0,
        "成交额": 4017423.0,
        "振幅": 1.17,
        "换手率": 0.06,
        "symbol": "106.CAE"
    },
    {
        "序号": 4174,
        "name": "Vanguard Mid-Cap ETF",
        "最新价": 221.25,
        "涨跌额": 0.86,
        "涨跌幅": 0.39,
        "开盘价": 220.4,
        "最高价": 221.81,
        "最低价": 220.17,
        "昨收价": 220.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 622437.0,
        "成交额": 137567606.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.VO"
    },
    {
        "序号": 4175,
        "name": "Virtus Convertible & Income Fun",
        "最新价": 20.59,
        "涨跌额": 0.08,
        "涨跌幅": 0.39,
        "开盘价": 20.7,
        "最高价": 20.7,
        "最低价": 20.49,
        "昨收价": 20.51,
        "总市值": 89772400.0,
        "市盈率": null,
        "成交量": 6003.0,
        "成交额": 123496.0,
        "振幅": 1.02,
        "换手率": 0.14,
        "symbol": "106.NCZ_A"
    },
    {
        "序号": 4176,
        "name": "美国金融",
        "最新价": 115.82,
        "涨跌额": 0.45,
        "涨跌幅": 0.39,
        "开盘价": 115.68,
        "最高价": 115.93,
        "最低价": 114.73,
        "昨收价": 115.37,
        "总市值": 9703145838.0,
        "市盈率": 11.22,
        "成交量": 275931.0,
        "成交额": 31858589.0,
        "振幅": 1.04,
        "换手率": 0.33,
        "symbol": "106.AFG"
    },
    {
        "序号": 4177,
        "name": "Alexis Practical Tactical ETF",
        "最新价": 25.74,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.74,
        "最高价": 25.74,
        "最低价": 25.74,
        "昨收价": 25.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 128.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LEXI"
    },
    {
        "序号": 4178,
        "name": "Sono-Tek Corp",
        "最新价": 5.15,
        "涨跌额": 0.02,
        "涨跌幅": 0.39,
        "开盘价": 5.1,
        "最高价": 5.16,
        "最低价": 5.02,
        "昨收价": 5.13,
        "总市值": 81078937.0,
        "市盈率": 106.29,
        "成交量": 12496.0,
        "成交额": 64091.0,
        "振幅": 2.73,
        "换手率": 0.08,
        "symbol": "105.SOTK"
    },
    {
        "序号": 4179,
        "name": "Immunome Inc",
        "最新价": 7.73,
        "涨跌额": 0.03,
        "涨跌幅": 0.39,
        "开盘价": 7.64,
        "最高价": 7.87,
        "最低价": 7.54,
        "昨收价": 7.7,
        "总市值": 330301717.0,
        "市盈率": -15.04,
        "成交量": 97215.0,
        "成交额": 747314.0,
        "振幅": 4.29,
        "换手率": 0.23,
        "symbol": "105.IMNM"
    },
    {
        "序号": 4180,
        "name": "奈飞",
        "最新价": 453.76,
        "涨跌额": 1.76,
        "涨跌幅": 0.39,
        "开盘价": 450.76,
        "最高价": 455.5,
        "最低价": 450.76,
        "昨收价": 452.0,
        "总市值": 198601526605.0,
        "市盈率": 43.89,
        "成交量": 3458384.0,
        "成交额": 1568868784.0,
        "振幅": 1.05,
        "换手率": 0.79,
        "symbol": "105.NFLX"
    },
    {
        "序号": 4181,
        "name": "Artivion Inc",
        "最新价": 18.07,
        "涨跌额": 0.07,
        "涨跌幅": 0.39,
        "开盘价": 18.1,
        "最高价": 18.54,
        "最低价": 17.96,
        "昨收价": 18.0,
        "总市值": 741748347.0,
        "市盈率": -30.22,
        "成交量": 251138.0,
        "成交额": 4556868.0,
        "振幅": 3.22,
        "换手率": 0.61,
        "symbol": "106.AORT"
    },
    {
        "序号": 4182,
        "name": "SPDR S&P 400 Mid Cap Growth ETF",
        "最新价": 72.33,
        "涨跌额": 0.28,
        "涨跌幅": 0.39,
        "开盘价": 71.92,
        "最高价": 72.65,
        "最低价": 71.92,
        "昨收价": 72.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 102769.0,
        "成交额": 7425271.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.MDYG"
    },
    {
        "序号": 4183,
        "name": "ActivePassive International Equ",
        "最新价": 25.84,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.75,
        "最高价": 26.13,
        "最低价": 25.75,
        "昨收价": 25.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 21037.0,
        "成交额": 545001.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "107.APIE"
    },
    {
        "序号": 4184,
        "name": "Schwab U.S. Large-Cap ETF",
        "最新价": 54.27,
        "涨跌额": 0.21,
        "涨跌幅": 0.39,
        "开盘价": 53.97,
        "最高价": 54.36,
        "最低价": 53.93,
        "昨收价": 54.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1521189.0,
        "成交额": 82399317.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.SCHX"
    },
    {
        "序号": 4185,
        "name": "iShares U.S. Pharmaceutical ETF",
        "最新价": 178.33,
        "涨跌额": 0.69,
        "涨跌幅": 0.39,
        "开盘价": 177.48,
        "最高价": 178.5,
        "最低价": 177.13,
        "昨收价": 177.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 18429.0,
        "成交额": 3275836.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.IHE"
    },
    {
        "序号": 4186,
        "name": "Innovative Industrial Propertie",
        "最新价": 25.85,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.88,
        "最高价": 25.88,
        "最低价": 25.84,
        "昨收价": 25.75,
        "总市值": 15510000.0,
        "市盈率": null,
        "成交量": 1275.0,
        "成交额": 32964.0,
        "振幅": 0.16,
        "换手率": 0.21,
        "symbol": "106.IIPR_A"
    },
    {
        "序号": 4187,
        "name": "Eaton Vance Short Duration Dive",
        "最新价": 10.35,
        "涨跌额": 0.04,
        "涨跌幅": 0.39,
        "开盘价": 10.31,
        "最高价": 10.46,
        "最低价": 10.21,
        "昨收价": 10.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 55468.0,
        "成交额": 574110.0,
        "振幅": 2.42,
        "换手率": null,
        "symbol": "106.EVG"
    },
    {
        "序号": 4188,
        "name": "Gabelli Automation ETF",
        "最新价": 23.29,
        "涨跌额": 0.09,
        "涨跌幅": 0.39,
        "开盘价": 23.29,
        "最高价": 23.29,
        "最低价": 23.29,
        "昨收价": 23.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 110.0,
        "成交额": 2562.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GAST"
    },
    {
        "序号": 4189,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 41.41,
        "涨跌额": 0.16,
        "涨跌幅": 0.39,
        "开盘价": 41.22,
        "最高价": 41.42,
        "最低价": 41.22,
        "昨收价": 41.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 15873.0,
        "成交额": 656190.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FFEB"
    },
    {
        "序号": 4190,
        "name": "The Gabelli Dividend & Income T",
        "最新价": 23.34,
        "涨跌额": 0.09,
        "涨跌幅": 0.39,
        "开盘价": 23.15,
        "最高价": 23.6,
        "最低价": 23.15,
        "昨收价": 23.25,
        "总市值": 46511952.0,
        "市盈率": null,
        "成交量": 4083.0,
        "成交额": 95249.0,
        "振幅": 1.94,
        "换手率": 0.2,
        "symbol": "106.GDV_H"
    },
    {
        "序号": 4191,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 41.51,
        "涨跌额": 0.16,
        "涨跌幅": 0.39,
        "开盘价": 41.3,
        "最高价": 41.57,
        "最低价": 41.3,
        "昨收价": 41.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 71542.0,
        "成交额": 2962633.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.FNOV"
    },
    {
        "序号": 4192,
        "name": "ProShares S&P 500 Ex-Energy ETF",
        "最新价": 98.62,
        "涨跌额": 0.38,
        "涨跌幅": 0.39,
        "开盘价": 97.77,
        "最高价": 98.62,
        "最低价": 97.77,
        "昨收价": 98.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 1314.0,
        "成交额": 128930.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.SPXE"
    },
    {
        "序号": 4193,
        "name": "SS&C Technologies Holdings Inc",
        "最新价": 57.11,
        "涨跌额": 0.22,
        "涨跌幅": 0.39,
        "开盘价": 56.74,
        "最高价": 57.22,
        "最低价": 56.64,
        "昨收价": 56.89,
        "总市值": 14135622141.0,
        "市盈率": 22.79,
        "成交量": 678393.0,
        "成交额": 38679510.0,
        "振幅": 1.02,
        "换手率": 0.27,
        "symbol": "105.SSNC"
    },
    {
        "序号": 4194,
        "name": "Simplify US Equity PLUS QIS ETF",
        "最新价": 25.96,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.91,
        "最高价": 25.96,
        "最低价": 25.91,
        "昨收价": 25.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 677.0,
        "成交额": 17550.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.SPQ"
    },
    {
        "序号": 4195,
        "name": "SPDR MSCI USA Climate Paris Ali",
        "最新价": 25.96,
        "涨跌额": 0.1,
        "涨跌幅": 0.39,
        "开盘价": 25.96,
        "最高价": 25.96,
        "最低价": 25.96,
        "昨收价": 25.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 51.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NZUS"
    },
    {
        "序号": 4196,
        "name": "Global X Autonomous & Electric ",
        "最新价": 23.37,
        "涨跌额": 0.09,
        "涨跌幅": 0.39,
        "开盘价": 23.28,
        "最高价": 23.48,
        "最低价": 23.1,
        "昨收价": 23.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 191487.0,
        "成交额": 4475011.0,
        "振幅": 1.63,
        "换手率": null,
        "symbol": "105.DRIV"
    },
    {
        "序号": 4197,
        "name": "Brookfield Finance Inc Notes",
        "最新价": 15.59,
        "涨跌额": 0.06,
        "涨跌幅": 0.39,
        "开盘价": 15.56,
        "最高价": 15.7,
        "最低价": 15.42,
        "昨收价": 15.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 12734.0,
        "成交额": 198333.0,
        "振幅": 1.8,
        "换手率": null,
        "symbol": "106.BNH"
    },
    {
        "序号": 4198,
        "name": "希腊ETF-Global X MSCI",
        "最新价": 36.43,
        "涨跌额": 0.14,
        "涨跌幅": 0.39,
        "开盘价": 36.11,
        "最高价": 36.44,
        "最低价": 36.05,
        "昨收价": 36.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 10609.0,
        "成交额": 384214.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.GREK"
    },
    {
        "序号": 4199,
        "name": "FT Cboe Vest Nasdaq-100 Buffer ",
        "最新价": 23.42,
        "涨跌额": 0.09,
        "涨跌幅": 0.39,
        "开盘价": 23.19,
        "最高价": 23.44,
        "最低价": 23.19,
        "昨收价": 23.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 24684.0,
        "成交额": 576704.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.QJUN"
    },
    {
        "序号": 4200,
        "name": "格拉德斯通资本",
        "最新价": 10.41,
        "涨跌额": 0.04,
        "涨跌幅": 0.39,
        "开盘价": 10.31,
        "最高价": 10.44,
        "最低价": 10.31,
        "昨收价": 10.37,
        "总市值": 452927618.0,
        "市盈率": 10.62,
        "成交量": 229538.0,
        "成交额": 2382713.0,
        "振幅": 1.25,
        "换手率": 0.53,
        "symbol": "105.GLAD"
    },
    {
        "序号": 4201,
        "name": "诺华制药",
        "最新价": 96.3,
        "涨跌额": 0.37,
        "涨跌幅": 0.39,
        "开盘价": 96.11,
        "最高价": 96.45,
        "最低价": 95.95,
        "昨收价": 95.93,
        "总市值": 197940844513.0,
        "市盈率": 25.26,
        "成交量": 3314303.0,
        "成交额": 318694208.0,
        "振幅": 0.52,
        "换手率": 0.16,
        "symbol": "106.NVS"
    },
    {
        "序号": 4202,
        "name": "iShares Currency Hedged MSCI AC",
        "最新价": 28.65,
        "涨跌额": 0.11,
        "涨跌幅": 0.39,
        "开盘价": 28.6,
        "最高价": 28.65,
        "最低价": 28.55,
        "昨收价": 28.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 22874.0,
        "成交额": 653858.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.HAWX"
    },
    {
        "序号": 4203,
        "name": "Schwab 1000 Index ETF",
        "最新价": 44.28,
        "涨跌额": 0.17,
        "涨跌幅": 0.39,
        "开盘价": 44.03,
        "最高价": 44.35,
        "最低价": 44.02,
        "昨收价": 44.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 150041.0,
        "成交额": 6634684.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.SCHK"
    },
    {
        "序号": 4204,
        "name": "The Swiss Helvetia Fund",
        "最新价": 7.82,
        "涨跌额": 0.03,
        "涨跌幅": 0.39,
        "开盘价": 7.83,
        "最高价": 7.84,
        "最低价": 7.81,
        "昨收价": 7.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 15913.0,
        "成交额": 124552.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.SWZ"
    },
    {
        "序号": 4205,
        "name": "Innovator Gradient Tactical Rot",
        "最新价": 23.5,
        "涨跌额": 0.09,
        "涨跌幅": 0.38,
        "开盘价": 23.5,
        "最高价": 23.53,
        "最低价": 23.43,
        "昨收价": 23.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 22495.0,
        "成交额": 528827.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.IGTR"
    },
    {
        "序号": 4206,
        "name": "SPDR Russell 1000 Yield Focus E",
        "最新价": 96.62,
        "涨跌额": 0.37,
        "涨跌幅": 0.38,
        "开盘价": 96.19,
        "最高价": 96.94,
        "最低价": 96.19,
        "昨收价": 96.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 14284.0,
        "成交额": 1379197.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.ONEY"
    },
    {
        "序号": 4207,
        "name": "邦吉",
        "最新价": 104.5,
        "涨跌额": 0.4,
        "涨跌幅": 0.38,
        "开盘价": 104.22,
        "最高价": 105.41,
        "最低价": 103.82,
        "昨收价": 104.1,
        "总市值": 15182593701.0,
        "市盈率": null,
        "成交量": 700886.0,
        "成交额": 73243964.0,
        "振幅": 1.53,
        "换手率": 0.48,
        "symbol": "106.BG"
    },
    {
        "序号": 4208,
        "name": "Carrols Restaurant Group Inc",
        "最新价": 7.84,
        "涨跌额": 0.03,
        "涨跌幅": 0.38,
        "开盘价": 7.8,
        "最高价": 7.91,
        "最低价": 7.73,
        "昨收价": 7.81,
        "总市值": 427250404.0,
        "市盈率": 45.91,
        "成交量": 486251.0,
        "成交额": 3811517.0,
        "振幅": 2.3,
        "换手率": 0.89,
        "symbol": "105.TAST"
    },
    {
        "序号": 4209,
        "name": "LeaderShares Activist Leaders E",
        "最新价": 31.36,
        "涨跌额": 0.12,
        "涨跌幅": 0.38,
        "开盘价": 31.29,
        "最高价": 31.4,
        "最低价": 31.24,
        "昨收价": 31.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 27291.0,
        "成交额": 853467.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.ACTV"
    },
    {
        "序号": 4210,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 28.75,
        "涨跌额": 0.11,
        "涨跌幅": 0.38,
        "开盘价": 28.66,
        "最高价": 28.75,
        "最低价": 28.66,
        "昨收价": 28.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 3030.0,
        "成交额": 86938.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.XTJL"
    },
    {
        "序号": 4211,
        "name": "礼恩派",
        "最新价": 26.17,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.15,
        "最高价": 26.4,
        "最低价": 26.08,
        "昨收价": 26.07,
        "总市值": 3488969378.0,
        "市盈率": 16.36,
        "成交量": 1416584.0,
        "成交额": 37101313.0,
        "振幅": 1.23,
        "换手率": 1.06,
        "symbol": "106.LEG"
    },
    {
        "序号": 4212,
        "name": "Capital Group Dividend Growers ",
        "最新价": 26.18,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.17,
        "最高价": 26.23,
        "最低价": 26.02,
        "昨收价": 26.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 569213.0,
        "成交额": 14869719.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.CGDG"
    },
    {
        "序号": 4213,
        "name": "网络安全ETF-PureFunds ISE",
        "最新价": 57.63,
        "涨跌额": 0.22,
        "涨跌幅": 0.38,
        "开盘价": 57.21,
        "最高价": 57.81,
        "最低价": 57.21,
        "昨收价": 57.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 63964.0,
        "成交额": 3677039.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.HACK"
    },
    {
        "序号": 4214,
        "name": "Mayville Engineering Co Inc",
        "最新价": 13.1,
        "涨跌额": 0.05,
        "涨跌幅": 0.38,
        "开盘价": 12.81,
        "最高价": 13.47,
        "最低价": 12.77,
        "昨收价": 13.05,
        "总市值": 266039464.0,
        "市盈率": 33.26,
        "成交量": 29248.0,
        "成交额": 383756.0,
        "振幅": 5.36,
        "换手率": 0.14,
        "symbol": "106.MEC"
    },
    {
        "序号": 4215,
        "name": "HireRight Holdings Corp",
        "最新价": 13.1,
        "涨跌额": 0.05,
        "涨跌幅": 0.38,
        "开盘价": 13.01,
        "最高价": 13.29,
        "最低价": 12.92,
        "昨收价": 13.05,
        "总市值": 886146186.0,
        "市盈率": 107.79,
        "成交量": 349498.0,
        "成交额": 4558205.0,
        "振幅": 2.84,
        "换手率": 0.52,
        "symbol": "106.HRT"
    },
    {
        "序号": 4216,
        "name": "FlexShares Quality Dividend Def",
        "最新价": 57.65,
        "涨跌额": 0.22,
        "涨跌幅": 0.38,
        "开盘价": 57.52,
        "最高价": 57.71,
        "最低价": 57.36,
        "昨收价": 57.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 1840.0,
        "成交额": 105842.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.QDEF"
    },
    {
        "序号": 4217,
        "name": "Global X Clean Water ETF",
        "最新价": 15.73,
        "涨跌额": 0.06,
        "涨跌幅": 0.38,
        "开盘价": 15.72,
        "最高价": 15.73,
        "最低价": 15.71,
        "昨收价": 15.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 1607.0,
        "成交额": 25269.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "105.AQWA"
    },
    {
        "序号": 4218,
        "name": "Innovator Laddered Fund of U.S.",
        "最新价": 39.34,
        "涨跌额": 0.15,
        "涨跌幅": 0.38,
        "开盘价": 39.28,
        "最高价": 39.39,
        "最低价": 39.2,
        "昨收价": 39.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 47623.0,
        "成交额": 1870918.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.BUFF"
    },
    {
        "序号": 4219,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 34.14,
        "涨跌额": 0.13,
        "涨跌幅": 0.38,
        "开盘价": 34.01,
        "最高价": 34.15,
        "最低价": 33.99,
        "昨收价": 34.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 13693.0,
        "成交额": 466108.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.FAPR"
    },
    {
        "序号": 4220,
        "name": "Procure Disaster Recovery Strat",
        "最新价": 28.89,
        "涨跌额": 0.11,
        "涨跌幅": 0.38,
        "开盘价": 29.1,
        "最高价": 29.1,
        "最低价": 28.89,
        "昨收价": 28.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1722.0,
        "成交额": 49981.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "105.FIXT"
    },
    {
        "序号": 4221,
        "name": "The Chemours Co",
        "最新价": 28.9,
        "涨跌额": 0.11,
        "涨跌幅": 0.38,
        "开盘价": 28.85,
        "最高价": 29.62,
        "最低价": 28.71,
        "昨收价": 28.79,
        "总市值": 4289866176.0,
        "市盈率": -13.88,
        "成交量": 551743.0,
        "成交额": 16005691.0,
        "振幅": 3.16,
        "换手率": 0.37,
        "symbol": "106.CC"
    },
    {
        "序号": 4222,
        "name": "Bio-Techne Corp",
        "最新价": 68.31,
        "涨跌额": 0.26,
        "涨跌幅": 0.38,
        "开盘价": 68.05,
        "最高价": 69.49,
        "最低价": 67.71,
        "昨收价": 68.05,
        "总市值": 10803252389.0,
        "市盈率": 43.79,
        "成交量": 1229200.0,
        "成交额": 84166878.0,
        "振幅": 2.62,
        "换手率": 0.78,
        "symbol": "105.TECH"
    },
    {
        "序号": 4223,
        "name": "FT Energy Income Partners Strat",
        "最新价": 21.02,
        "涨跌额": 0.08,
        "涨跌幅": 0.38,
        "开盘价": 21.0,
        "最高价": 21.08,
        "最低价": 21.0,
        "昨收价": 20.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 13451.0,
        "成交额": 282759.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.EIPX"
    },
    {
        "序号": 4224,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 34.2,
        "涨跌额": 0.13,
        "涨跌幅": 0.38,
        "开盘价": 34.13,
        "最高价": 34.2,
        "最低价": 34.1,
        "昨收价": 34.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1108.0,
        "成交额": 37854.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.BNOV"
    },
    {
        "序号": 4225,
        "name": "大众信托合众银行",
        "最新价": 42.11,
        "涨跌额": 0.16,
        "涨跌幅": 0.38,
        "开盘价": 41.72,
        "最高价": 42.33,
        "最低价": 41.72,
        "昨收价": 41.95,
        "总市值": 757973262.0,
        "市盈率": 9.27,
        "成交量": 16546.0,
        "成交额": 695785.0,
        "振幅": 1.45,
        "换手率": 0.09,
        "symbol": "105.CTBI"
    },
    {
        "序号": 4226,
        "name": "Capital Group Core Balanced ETF",
        "最新价": 26.32,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.33,
        "最高价": 26.33,
        "最低价": 26.12,
        "昨收价": 26.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 227584.0,
        "成交额": 5978423.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.CGBL"
    },
    {
        "序号": 4227,
        "name": "安西斯",
        "最新价": 287.2,
        "涨跌额": 1.09,
        "涨跌幅": 0.38,
        "开盘价": 284.39,
        "最高价": 287.8,
        "最低价": 284.39,
        "昨收价": 286.11,
        "总市值": 24949869022.0,
        "市盈率": 51.59,
        "成交量": 506907.0,
        "成交额": 145402949.0,
        "振幅": 1.19,
        "换手率": 0.58,
        "symbol": "105.ANSS"
    },
    {
        "序号": 4228,
        "name": "Penumbra Inc",
        "最新价": 224.04,
        "涨跌额": 0.85,
        "涨跌幅": 0.38,
        "开盘价": 223.25,
        "最高价": 224.73,
        "最低价": 221.14,
        "昨收价": 223.19,
        "总市值": 8647129839.0,
        "市盈率": 212.92,
        "成交量": 279165.0,
        "成交额": 62333249.0,
        "振幅": 1.61,
        "换手率": 0.72,
        "symbol": "106.PEN"
    },
    {
        "序号": 4229,
        "name": "德国ETF-iShares MSCI",
        "最新价": 29.0,
        "涨跌额": 0.11,
        "涨跌幅": 0.38,
        "开盘价": 28.79,
        "最高价": 29.03,
        "最低价": 28.79,
        "昨收价": 28.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 2214313.0,
        "成交额": 64081305.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.EWG"
    },
    {
        "序号": 4230,
        "name": "Gladstone Investment Corp Notes",
        "最新价": 23.74,
        "涨跌额": 0.09,
        "涨跌幅": 0.38,
        "开盘价": 23.67,
        "最高价": 23.79,
        "最低价": 23.6,
        "昨收价": 23.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 4166.0,
        "成交额": 98535.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "105.GAINN"
    },
    {
        "序号": 4231,
        "name": "abrdn Bloomberg Industrial Meta",
        "最新价": 21.12,
        "涨跌额": 0.08,
        "涨跌幅": 0.38,
        "开盘价": 21.12,
        "最高价": 21.12,
        "最低价": 21.12,
        "昨收价": 21.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 202.0,
        "成交额": 4265.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BCIM"
    },
    {
        "序号": 4232,
        "name": "TrueShares Structured Outcome (",
        "最新价": 31.72,
        "涨跌额": 0.12,
        "涨跌幅": 0.38,
        "开盘价": 31.72,
        "最高价": 31.72,
        "最低价": 31.72,
        "昨收价": 31.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 63.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FEBZ"
    },
    {
        "序号": 4233,
        "name": "比利时ETF-iShares MSCI",
        "最新价": 18.52,
        "涨跌额": 0.07,
        "涨跌幅": 0.38,
        "开盘价": 18.52,
        "最高价": 18.53,
        "最低价": 18.52,
        "昨收价": 18.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 1624.0,
        "成交额": 30078.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.EWK"
    },
    {
        "序号": 4234,
        "name": "Invesco S&P SmallCap Consumer D",
        "最新价": 95.34,
        "涨跌额": 0.36,
        "涨跌幅": 0.38,
        "开盘价": 94.99,
        "最高价": 95.34,
        "最低价": 94.89,
        "昨收价": 94.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 1364.0,
        "成交额": 129526.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "105.PSCD"
    },
    {
        "序号": 4235,
        "name": "JPMorgan BetaBuilders Canada ET",
        "最新价": 60.92,
        "涨跌额": 0.23,
        "涨跌幅": 0.38,
        "开盘价": 60.61,
        "最高价": 61.15,
        "最低价": 60.61,
        "昨收价": 60.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 230084.0,
        "成交额": 14011625.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.BBCA"
    },
    {
        "序号": 4236,
        "name": "Nationwide S&P 500 Risk-Managed",
        "最新价": 21.19,
        "涨跌额": 0.08,
        "涨跌幅": 0.38,
        "开盘价": 21.19,
        "最高价": 21.19,
        "最低价": 21.19,
        "昨收价": 21.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 170.0,
        "成交额": 3602.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NSPI"
    },
    {
        "序号": 4237,
        "name": "Fidelity MSCI Industrials Index",
        "最新价": 58.29,
        "涨跌额": 0.22,
        "涨跌幅": 0.38,
        "开盘价": 58.0,
        "最高价": 58.53,
        "最低价": 58.0,
        "昨收价": 58.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 30966.0,
        "成交额": 1805666.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.FIDU"
    },
    {
        "序号": 4238,
        "name": "EQT能源",
        "最新价": 37.13,
        "涨跌额": 0.14,
        "涨跌幅": 0.38,
        "开盘价": 37.25,
        "最高价": 37.34,
        "最低价": 36.52,
        "昨收价": 36.99,
        "总市值": 15272757160.0,
        "市盈率": 5.19,
        "成交量": 4769202.0,
        "成交额": 176561731.0,
        "振幅": 2.22,
        "换手率": 1.16,
        "symbol": "106.EQT"
    },
    {
        "序号": 4239,
        "name": "Hartford Disciplined US Equity ",
        "最新价": 45.11,
        "涨跌额": 0.17,
        "涨跌幅": 0.38,
        "开盘价": 45.14,
        "最高价": 45.14,
        "最低价": 44.96,
        "昨收价": 44.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 2764.0,
        "成交额": 124416.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.HDUS"
    },
    {
        "序号": 4240,
        "name": "Workday Inc-A",
        "最新价": 273.41,
        "涨跌额": 1.03,
        "涨跌幅": 0.38,
        "开盘价": 270.06,
        "最高价": 274.85,
        "最低价": 269.55,
        "昨收价": 272.38,
        "总市值": 71906830000.0,
        "市盈率": 1076.32,
        "成交量": 1485123.0,
        "成交额": 405716224.0,
        "振幅": 1.95,
        "换手率": 0.56,
        "symbol": "105.WDAY"
    },
    {
        "序号": 4241,
        "name": "Capital Group International Equ",
        "最新价": 26.55,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.48,
        "最高价": 26.56,
        "最低价": 26.45,
        "昨收价": 26.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 107279.0,
        "成交额": 2846987.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.CGIE"
    },
    {
        "序号": 4242,
        "name": "bleuacacia ltd-A",
        "最新价": 10.62,
        "涨跌额": 0.04,
        "涨跌幅": 0.38,
        "开盘价": 10.62,
        "最高价": 10.62,
        "最低价": 10.62,
        "昨收价": 10.58,
        "总市值": 90100600.0,
        "市盈率": 14.06,
        "成交量": 950.0,
        "成交额": 10089.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.BLEU"
    },
    {
        "序号": 4243,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 15.96,
        "涨跌额": 0.06,
        "涨跌幅": 0.38,
        "开盘价": 15.96,
        "最高价": 15.96,
        "最低价": 15.96,
        "昨收价": 15.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 522.0,
        "成交额": 8331.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PCG_C"
    },
    {
        "序号": 4244,
        "name": "Fidelity Electric Vehicles and ",
        "最新价": 15.96,
        "涨跌额": 0.06,
        "涨跌幅": 0.38,
        "开盘价": 15.83,
        "最高价": 16.07,
        "最低价": 15.83,
        "昨收价": 15.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 15061.0,
        "成交额": 240671.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "107.FDRV"
    },
    {
        "序号": 4245,
        "name": "Actinium Pharmaceuticals Inc",
        "最新价": 5.32,
        "涨跌额": 0.02,
        "涨跌幅": 0.38,
        "开盘价": 5.35,
        "最高价": 5.44,
        "最低价": 5.19,
        "昨收价": 5.3,
        "总市值": 145839506.0,
        "市盈率": -2.91,
        "成交量": 103899.0,
        "成交额": 551556.0,
        "振幅": 4.72,
        "换手率": 0.38,
        "symbol": "107.ATNM"
    },
    {
        "序号": 4246,
        "name": "Innovator U.S. Small Cap Power ",
        "最新价": 26.62,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.53,
        "最高价": 26.63,
        "最低价": 26.51,
        "昨收价": 26.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 21292.0,
        "成交额": 565741.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.KOCT"
    },
    {
        "序号": 4247,
        "name": "Teucrium Sugar Fund ETV",
        "最新价": 13.32,
        "涨跌额": 0.05,
        "涨跌幅": 0.38,
        "开盘价": 13.25,
        "最高价": 13.35,
        "最低价": 13.21,
        "昨收价": 13.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 33879.0,
        "成交额": 450671.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.CANE"
    },
    {
        "序号": 4248,
        "name": "挪威ETF-Global X MSCI",
        "最新价": 23.99,
        "涨跌额": 0.09,
        "涨跌幅": 0.38,
        "开盘价": 23.88,
        "最高价": 23.99,
        "最低价": 23.88,
        "昨收价": 23.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 1644.0,
        "成交额": 39389.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.NORW"
    },
    {
        "序号": 4249,
        "name": "FTAI Aviation Ltd Series C Pfd",
        "最新价": 23.99,
        "涨跌额": 0.09,
        "涨跌幅": 0.38,
        "开盘价": 23.98,
        "最高价": 24.0,
        "最低价": 23.85,
        "昨收价": 23.9,
        "总市值": 100758000.0,
        "市盈率": null,
        "成交量": 4700.0,
        "成交额": 112482.0,
        "振幅": 0.63,
        "换手率": 0.11,
        "symbol": "105.FTAIN"
    },
    {
        "序号": 4250,
        "name": "BlackRock Large Cap Core ETF",
        "最新价": 26.68,
        "涨跌额": 0.1,
        "涨跌幅": 0.38,
        "开盘价": 26.71,
        "最高价": 26.72,
        "最低价": 26.68,
        "昨收价": 26.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 445.0,
        "成交额": 11888.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "105.BLCR"
    },
    {
        "序号": 4251,
        "name": "Vanguard S&P Mid-Cap 400 Value ",
        "最新价": 82.89,
        "涨跌额": 0.31,
        "涨跌幅": 0.38,
        "开盘价": 82.64,
        "最高价": 83.04,
        "最低价": 82.55,
        "昨收价": 82.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 12966.0,
        "成交额": 1073164.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.IVOV"
    },
    {
        "序号": 4252,
        "name": "锂ETF-Global X",
        "最新价": 48.15,
        "涨跌额": 0.18,
        "涨跌幅": 0.38,
        "开盘价": 48.0,
        "最高价": 48.6,
        "最低价": 47.76,
        "昨收价": 47.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 597245.0,
        "成交额": 28753090.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "107.LIT"
    },
    {
        "序号": 4253,
        "name": "Goldman Sachs Nasdaq-100 Core P",
        "最新价": 42.81,
        "涨跌额": 0.16,
        "涨跌幅": 0.38,
        "开盘价": 42.57,
        "最高价": 42.82,
        "最低价": 42.56,
        "昨收价": 42.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 17470.0,
        "成交额": 746112.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.GPIQ"
    },
    {
        "序号": 4254,
        "name": "Pacer Trendpilot International ",
        "最新价": 26.77,
        "涨跌额": 0.1,
        "涨跌幅": 0.37,
        "开盘价": 26.62,
        "最高价": 26.77,
        "最低价": 26.61,
        "昨收价": 26.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 4837.0,
        "成交额": 129161.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.PTIN"
    },
    {
        "序号": 4255,
        "name": "做多美元ETF-Powershares",
        "最新价": 29.46,
        "涨跌额": 0.11,
        "涨跌幅": 0.37,
        "开盘价": 29.51,
        "最高价": 29.51,
        "最低价": 29.39,
        "昨收价": 29.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1460907.0,
        "成交额": 43055841.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.UUP"
    },
    {
        "序号": 4256,
        "name": "Patriot Transportation Holding ",
        "最新价": 16.08,
        "涨跌额": 0.06,
        "涨跌幅": 0.37,
        "开盘价": 16.04,
        "最高价": 16.08,
        "最低价": 16.04,
        "昨收价": 16.02,
        "总市值": 57141422.0,
        "市盈率": 21.83,
        "成交量": 10699.0,
        "成交额": 171731.0,
        "振幅": 0.25,
        "换手率": 0.3,
        "symbol": "105.PATI"
    },
    {
        "序号": 4257,
        "name": "Pacer US Cash Cows Growth ETF",
        "最新价": 34.9,
        "涨跌额": 0.13,
        "涨跌幅": 0.37,
        "开盘价": 34.79,
        "最高价": 35.05,
        "最低价": 34.79,
        "昨收价": 34.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 4062.0,
        "成交额": 141725.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.BUL"
    },
    {
        "序号": 4258,
        "name": "AlphaTime Acquisition Corp",
        "最新价": 10.75,
        "涨跌额": 0.04,
        "涨跌幅": 0.37,
        "开盘价": 10.75,
        "最高价": 10.75,
        "最低价": 10.75,
        "昨收价": 10.71,
        "总市值": 97117650.0,
        "市盈率": 56.73,
        "成交量": 7810.0,
        "成交额": 83957.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "105.ATMC"
    },
    {
        "序号": 4259,
        "name": "Xtrackers Russell US Multifacto",
        "最新价": 45.7,
        "涨跌额": 0.17,
        "涨跌幅": 0.37,
        "开盘价": 45.49,
        "最高价": 45.76,
        "最低价": 45.49,
        "昨收价": 45.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 9383.0,
        "成交额": 427530.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DEUS"
    },
    {
        "序号": 4260,
        "name": "Avantis Inflation Focused Equit",
        "最新价": 56.46,
        "涨跌额": 0.21,
        "涨跌幅": 0.37,
        "开盘价": 56.46,
        "最高价": 56.46,
        "最低价": 56.46,
        "昨收价": 56.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 126.0,
        "成交额": 7113.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AVIE"
    },
    {
        "序号": 4261,
        "name": "Advanced Emissions Solutions In",
        "最新价": 2.69,
        "涨跌额": 0.01,
        "涨跌幅": 0.37,
        "开盘价": 2.7,
        "最高价": 2.7,
        "最低价": 2.66,
        "昨收价": 2.68,
        "总市值": 89256640.0,
        "市盈率": -4.77,
        "成交量": 18906.0,
        "成交额": 50733.0,
        "振幅": 1.49,
        "换手率": 0.06,
        "symbol": "105.ADES"
    },
    {
        "序号": 4262,
        "name": "Regal Rexnord Corp",
        "最新价": 123.76,
        "涨跌额": 0.46,
        "涨跌幅": 0.37,
        "开盘价": 122.85,
        "最高价": 124.55,
        "最低价": 122.79,
        "昨收价": 123.3,
        "总市值": 8211402487.0,
        "市盈率": -695.88,
        "成交量": 381310.0,
        "成交额": 47241670.0,
        "振幅": 1.43,
        "换手率": 0.57,
        "symbol": "106.RRX"
    },
    {
        "序号": 4263,
        "name": "Vanguard S&P 500 Growth ETF",
        "最新价": 263.75,
        "涨跌额": 0.98,
        "涨跌幅": 0.37,
        "开盘价": 262.21,
        "最高价": 263.96,
        "最低价": 262.19,
        "昨收价": 262.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 91845.0,
        "成交额": 24174328.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.VOOG"
    },
    {
        "序号": 4264,
        "name": "Fidelity Sustainable U.S. Equit",
        "最新价": 21.54,
        "涨跌额": 0.08,
        "涨跌幅": 0.37,
        "开盘价": 21.52,
        "最高价": 21.54,
        "最低价": 21.52,
        "昨收价": 21.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 386.0,
        "成交额": 8306.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.FSST"
    },
    {
        "序号": 4265,
        "name": "Schwab Fundamental U.S. Large C",
        "最新价": 59.3,
        "涨跌额": 0.22,
        "涨跌幅": 0.37,
        "开盘价": 59.03,
        "最高价": 59.42,
        "最低价": 59.0,
        "昨收价": 59.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 336104.0,
        "成交额": 19896447.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.FNDX"
    },
    {
        "序号": 4266,
        "name": "Nifty India Financials ETF",
        "最新价": 37.75,
        "涨跌额": 0.14,
        "涨跌幅": 0.37,
        "开盘价": 37.88,
        "最高价": 37.88,
        "最低价": 37.73,
        "昨收价": 37.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 1090.0,
        "成交额": 41192.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.INDF"
    },
    {
        "序号": 4267,
        "name": "Touchstone US Large Cap Focused",
        "最新价": 29.67,
        "涨跌额": 0.11,
        "涨跌幅": 0.37,
        "开盘价": 29.67,
        "最高价": 29.67,
        "最低价": 29.67,
        "昨收价": 29.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 103.0,
        "成交额": 3056.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LCF"
    },
    {
        "序号": 4268,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 40.46,
        "涨跌额": 0.15,
        "涨跌幅": 0.37,
        "开盘价": 40.35,
        "最高价": 40.49,
        "最低价": 40.28,
        "昨收价": 40.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 7259.0,
        "成交额": 293322.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.FMAY"
    },
    {
        "序号": 4269,
        "name": "MFS Intermediate Income Trust",
        "最新价": 2.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.37,
        "开盘价": 2.7,
        "最高价": 2.71,
        "最低价": 2.69,
        "昨收价": 2.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 185208.0,
        "成交额": 500285.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "106.MIN"
    },
    {
        "序号": 4270,
        "name": "Brookstone Growth Stock ETF",
        "最新价": 27.0,
        "涨跌额": 0.1,
        "涨跌幅": 0.37,
        "开盘价": 26.96,
        "最高价": 27.04,
        "最低价": 26.9,
        "昨收价": 26.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 13163.0,
        "成交额": 355328.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.BAMG"
    },
    {
        "序号": 4271,
        "name": "FlexShares US Quality Large Cap",
        "最新价": 51.33,
        "涨跌额": 0.19,
        "涨跌幅": 0.37,
        "开盘价": 51.04,
        "最高价": 51.35,
        "最低价": 51.04,
        "昨收价": 51.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 19341.0,
        "成交额": 989754.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.QLC"
    },
    {
        "序号": 4272,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 40.53,
        "涨跌额": 0.15,
        "涨跌幅": 0.37,
        "开盘价": 40.37,
        "最高价": 40.56,
        "最低价": 40.36,
        "昨收价": 40.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 26279.0,
        "成交额": 1063509.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.FJUL"
    },
    {
        "序号": 4273,
        "name": "US Bancorp Series A Pfd",
        "最新价": 794.94,
        "涨跌额": 2.94,
        "涨跌幅": 0.37,
        "开盘价": 794.94,
        "最高价": 794.94,
        "最低价": 794.94,
        "昨收价": 792.0,
        "总市值": 9944699.0,
        "市盈率": null,
        "成交量": 1207.0,
        "成交额": 959492.0,
        "振幅": 0.0,
        "换手率": 9.65,
        "symbol": "106.USB_A"
    },
    {
        "序号": 4274,
        "name": "GraniteShares Nasdaq Select Dis",
        "最新价": 43.3,
        "涨跌额": 0.16,
        "涨跌幅": 0.37,
        "开盘价": 43.3,
        "最高价": 43.3,
        "最低价": 43.09,
        "昨收价": 43.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 1258.0,
        "成交额": 54383.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.DRUP"
    },
    {
        "序号": 4275,
        "name": "标普500成长股ETF-iShares",
        "最新价": 73.09,
        "涨跌额": 0.27,
        "涨跌幅": 0.37,
        "开盘价": 72.65,
        "最高价": 73.19,
        "最低价": 72.61,
        "昨收价": 72.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 1776171.0,
        "成交额": 129608213.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.IVW"
    },
    {
        "序号": 4276,
        "name": "Everest Group Ltd",
        "最新价": 384.78,
        "涨跌额": 1.42,
        "涨跌幅": 0.37,
        "开盘价": 384.19,
        "最高价": 386.63,
        "最低价": 382.25,
        "昨收价": 383.36,
        "总市值": 16695767347.0,
        "市盈率": 7.56,
        "成交量": 292472.0,
        "成交额": 112492352.0,
        "振幅": 1.14,
        "换手率": 0.67,
        "symbol": "106.EG"
    },
    {
        "序号": 4277,
        "name": "PIMCO Income Strategy Fund",
        "最新价": 8.14,
        "涨跌额": 0.03,
        "涨跌幅": 0.37,
        "开盘价": 8.05,
        "最高价": 8.18,
        "最低价": 8.04,
        "昨收价": 8.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 132797.0,
        "成交额": 1077585.0,
        "振幅": 1.73,
        "换手率": null,
        "symbol": "106.PFL"
    },
    {
        "序号": 4278,
        "name": "Virtus Total Return Fund",
        "最新价": 5.43,
        "涨跌额": 0.02,
        "涨跌幅": 0.37,
        "开盘价": 5.42,
        "最高价": 5.45,
        "最低价": 5.39,
        "昨收价": 5.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 219120.0,
        "成交额": 1188986.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "106.ZTR"
    },
    {
        "序号": 4279,
        "name": "Northrim BanCorp Inc",
        "最新价": 54.3,
        "涨跌额": 0.2,
        "涨跌幅": 0.37,
        "开盘价": 53.7,
        "最高价": 54.74,
        "最低价": 53.7,
        "昨收价": 54.1,
        "总市值": 300031175.0,
        "市盈率": 10.96,
        "成交量": 13258.0,
        "成交额": 718059.0,
        "振幅": 1.92,
        "换手率": 0.24,
        "symbol": "105.NRIM"
    },
    {
        "序号": 4280,
        "name": "海恩斯国际",
        "最新价": 48.87,
        "涨跌额": 0.18,
        "涨跌幅": 0.37,
        "开盘价": 48.92,
        "最高价": 49.62,
        "最低价": 48.63,
        "昨收价": 48.69,
        "总市值": 623284852.0,
        "市盈率": 14.85,
        "成交量": 39056.0,
        "成交额": 1908456.0,
        "振幅": 2.03,
        "换手率": 0.31,
        "symbol": "105.HAYN"
    },
    {
        "序号": 4281,
        "name": "Blue Horizon BNE ETF",
        "最新价": 21.74,
        "涨跌额": 0.08,
        "涨跌幅": 0.37,
        "开盘价": 21.66,
        "最高价": 21.74,
        "最低价": 21.65,
        "昨收价": 21.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1207.0,
        "成交额": 26133.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.BNE"
    },
    {
        "序号": 4282,
        "name": "Timothy Plan US Large\/Mid Cap C",
        "最新价": 21.76,
        "涨跌额": 0.08,
        "涨跌幅": 0.37,
        "开盘价": 21.82,
        "最高价": 21.82,
        "最低价": 21.7,
        "昨收价": 21.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 1467.0,
        "成交额": 31952.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.TPLE"
    },
    {
        "序号": 4283,
        "name": "MUSQ Global Music Industry ETF",
        "最新价": 24.48,
        "涨跌额": 0.09,
        "涨跌幅": 0.37,
        "开盘价": 24.49,
        "最高价": 24.49,
        "最低价": 24.41,
        "昨收价": 24.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 2443.0,
        "成交额": 59820.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.MUSQ"
    },
    {
        "序号": 4284,
        "name": "ProShares Pet Care ETF",
        "最新价": 49.01,
        "涨跌额": 0.18,
        "涨跌幅": 0.37,
        "开盘价": 48.9,
        "最高价": 49.01,
        "最低价": 48.63,
        "昨收价": 48.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 3889.0,
        "成交额": 190075.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.PAWZ"
    },
    {
        "序号": 4285,
        "name": "拉丁美洲出口银行",
        "最新价": 24.51,
        "涨跌额": 0.09,
        "涨跌幅": 0.37,
        "开盘价": 24.43,
        "最高价": 24.66,
        "最低价": 24.43,
        "昨收价": 24.42,
        "总市值": 895591986.0,
        "市盈率": 5.94,
        "成交量": 66017.0,
        "成交额": 1620760.0,
        "振幅": 0.94,
        "换手率": 0.18,
        "symbol": "106.BLX"
    },
    {
        "序号": 4286,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 32.68,
        "涨跌额": 0.12,
        "涨跌幅": 0.37,
        "开盘价": 32.52,
        "最高价": 32.68,
        "最低价": 32.52,
        "昨收价": 32.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 2487.0,
        "成交额": 80912.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.APRT"
    },
    {
        "序号": 4287,
        "name": "Leslie's Inc",
        "最新价": 5.45,
        "涨跌额": 0.02,
        "涨跌幅": 0.37,
        "开盘价": 5.42,
        "最高价": 5.61,
        "最低价": 5.31,
        "昨收价": 5.43,
        "总市值": 1004618502.0,
        "市盈率": 36.88,
        "成交量": 3328125.0,
        "成交额": 18194305.0,
        "振幅": 5.52,
        "换手率": 1.81,
        "symbol": "105.LESL"
    },
    {
        "序号": 4288,
        "name": "JPMorgan Sustainable Consumptio",
        "最新价": 51.78,
        "涨跌额": 0.19,
        "涨跌幅": 0.37,
        "开盘价": 51.78,
        "最高价": 51.78,
        "最低价": 51.78,
        "昨收价": 51.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 87.0,
        "成交额": 4504.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CIRC"
    },
    {
        "序号": 4289,
        "name": "Harbor Disruptive Innovation ET",
        "最新价": 13.63,
        "涨跌额": 0.05,
        "涨跌幅": 0.37,
        "开盘价": 13.64,
        "最高价": 13.64,
        "最低价": 13.61,
        "昨收价": 13.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 3736.0,
        "成交额": 50892.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.INNO"
    },
    {
        "序号": 4290,
        "name": "Western New England Bancorp Inc",
        "最新价": 8.18,
        "涨跌额": 0.03,
        "涨跌幅": 0.37,
        "开盘价": 8.15,
        "最高价": 8.22,
        "最低价": 8.12,
        "昨收价": 8.15,
        "总市值": 177610827.0,
        "市盈率": 8.23,
        "成交量": 16918.0,
        "成交额": 138371.0,
        "振幅": 1.23,
        "换手率": 0.08,
        "symbol": "105.WNEB"
    },
    {
        "序号": 4291,
        "name": "Breakwave Tanker Shipping ETF",
        "最新价": 16.37,
        "涨跌额": 0.06,
        "涨跌幅": 0.37,
        "开盘价": 16.2,
        "最高价": 16.39,
        "最低价": 16.2,
        "昨收价": 16.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 925.0,
        "成交额": 15033.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.BWET"
    },
    {
        "序号": 4292,
        "name": "Chemed Corp",
        "最新价": 573.18,
        "涨跌额": 2.1,
        "涨跌幅": 0.37,
        "开盘价": 572.44,
        "最高价": 575.78,
        "最低价": 570.9,
        "昨收价": 571.08,
        "总市值": 8602022924.0,
        "市盈率": 35.17,
        "成交量": 49220.0,
        "成交额": 28231183.0,
        "振幅": 0.85,
        "换手率": 0.33,
        "symbol": "106.CHE"
    },
    {
        "序号": 4293,
        "name": "亚马逊",
        "最新价": 147.42,
        "涨跌额": 0.54,
        "涨跌幅": 0.37,
        "开盘价": 145.48,
        "最高价": 147.84,
        "最低价": 145.4,
        "昨收价": 146.88,
        "总市值": 1523442788988.0,
        "市盈率": 75.87,
        "成交量": 41905965.0,
        "成交额": 6158322688.0,
        "振幅": 1.66,
        "换手率": 0.41,
        "symbol": "105.AMZN"
    },
    {
        "序号": 4294,
        "name": "基础材料ETF-SPDR",
        "最新价": 81.93,
        "涨跌额": 0.3,
        "涨跌幅": 0.37,
        "开盘价": 81.57,
        "最高价": 82.32,
        "最低价": 81.57,
        "昨收价": 81.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 4123459.0,
        "成交额": 337876992.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.XLB"
    },
    {
        "序号": 4295,
        "name": "Edify Acquisition Corp-A",
        "最新价": 10.93,
        "涨跌额": 0.04,
        "涨跌幅": 0.37,
        "开盘价": 10.85,
        "最高价": 10.93,
        "最低价": 10.85,
        "昨收价": 10.89,
        "总市值": 86242389.0,
        "市盈率": 80.53,
        "成交量": 1854.0,
        "成交额": 20124.0,
        "振幅": 0.73,
        "换手率": 0.02,
        "symbol": "105.EAC"
    },
    {
        "序号": 4296,
        "name": "iShares Core S&P U.S. Growth ET",
        "最新价": 101.22,
        "涨跌额": 0.37,
        "涨跌幅": 0.37,
        "开盘价": 100.65,
        "最高价": 101.32,
        "最低价": 100.61,
        "昨收价": 100.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 397509.0,
        "成交额": 40164653.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.IUSG"
    },
    {
        "序号": 4297,
        "name": "Equity Commonwealth",
        "最新价": 19.15,
        "涨跌额": 0.07,
        "涨跌幅": 0.37,
        "开盘价": 19.01,
        "最高价": 19.17,
        "最低价": 18.92,
        "昨收价": 19.08,
        "总市值": 2043535681.0,
        "市盈率": 23.9,
        "成交量": 1103621.0,
        "成交额": 21056407.0,
        "振幅": 1.31,
        "换手率": 1.03,
        "symbol": "106.EQC"
    },
    {
        "序号": 4298,
        "name": "夏威夷银行",
        "最新价": 65.68,
        "涨跌额": 0.24,
        "涨跌幅": 0.37,
        "开盘价": 65.19,
        "最高价": 66.6,
        "最低价": 64.74,
        "昨收价": 65.44,
        "总市值": 2610817372.0,
        "市盈率": 12.92,
        "成交量": 255432.0,
        "成交额": 16799130.0,
        "振幅": 2.84,
        "换手率": 0.64,
        "symbol": "106.BOH"
    },
    {
        "序号": 4299,
        "name": "CornerCap Fundametrics Large-Ca",
        "最新价": 35.59,
        "涨跌额": 0.13,
        "涨跌幅": 0.37,
        "开盘价": 35.64,
        "最高价": 35.64,
        "最低价": 35.59,
        "昨收价": 35.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 1553.0,
        "成交额": 55348.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.FUNL"
    },
    {
        "序号": 4300,
        "name": "Vanguard Mid-Cap Value ETF",
        "最新价": 139.66,
        "涨跌额": 0.51,
        "涨跌幅": 0.37,
        "开盘价": 139.23,
        "最高价": 139.98,
        "最低价": 138.99,
        "昨收价": 139.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 326940.0,
        "成交额": 45592734.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.VOE"
    },
    {
        "序号": 4301,
        "name": "Franklin International Low Vola",
        "最新价": 27.41,
        "涨跌额": 0.1,
        "涨跌幅": 0.37,
        "开盘价": 27.36,
        "最高价": 27.41,
        "最低价": 27.25,
        "昨收价": 27.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 171984.0,
        "成交额": 4703754.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.LVHI"
    },
    {
        "序号": 4302,
        "name": "Arrow Reverse Cap 500 ETF",
        "最新价": 21.94,
        "涨跌额": 0.08,
        "涨跌幅": 0.37,
        "开盘价": 21.94,
        "最高价": 21.94,
        "最低价": 21.94,
        "昨收价": 21.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 65.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.YPS"
    },
    {
        "序号": 4303,
        "name": "Invesco S&P 500 GARP ETF",
        "最新价": 93.4,
        "涨跌额": 0.34,
        "涨跌幅": 0.37,
        "开盘价": 93.08,
        "最高价": 93.8,
        "最低价": 92.92,
        "昨收价": 93.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 267303.0,
        "成交额": 24969535.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.SPGP"
    },
    {
        "序号": 4304,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 32.98,
        "涨跌额": 0.12,
        "涨跌幅": 0.37,
        "开盘价": 32.87,
        "最高价": 33.03,
        "最低价": 32.87,
        "昨收价": 32.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 6461.0,
        "成交额": 212877.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.BMAY"
    },
    {
        "序号": 4305,
        "name": "Kemper Corp Debentures",
        "最新价": 19.24,
        "涨跌额": 0.07,
        "涨跌幅": 0.37,
        "开盘价": 19.0,
        "最高价": 19.24,
        "最低价": 18.83,
        "昨收价": 19.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 20327.0,
        "成交额": 388705.0,
        "振幅": 2.14,
        "换手率": null,
        "symbol": "106.KMPB"
    },
    {
        "序号": 4306,
        "name": "Invesco S&P 500 Revenue ETF",
        "最新价": 82.49,
        "涨跌额": 0.3,
        "涨跌幅": 0.37,
        "开盘价": 82.27,
        "最高价": 82.67,
        "最低价": 82.19,
        "昨收价": 82.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 122407.0,
        "成交额": 10093784.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.RWL"
    },
    {
        "序号": 4307,
        "name": "Guess Inc",
        "最新价": 22.07,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 21.92,
        "最高价": 22.14,
        "最低价": 21.81,
        "昨收价": 21.99,
        "总市值": 1185165489.0,
        "市盈率": 6.63,
        "成交量": 941673.0,
        "成交额": 20725640.0,
        "振幅": 1.5,
        "换手率": 1.75,
        "symbol": "106.GES"
    },
    {
        "序号": 4308,
        "name": "Atlanticus Holdings Corp Series",
        "最新价": 22.07,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 22.0,
        "最高价": 22.37,
        "最低价": 22.0,
        "昨收价": 21.99,
        "总市值": 71872301.0,
        "市盈率": null,
        "成交量": 6142.0,
        "成交额": 136078.0,
        "振幅": 1.68,
        "换手率": 0.19,
        "symbol": "105.ATLCP"
    },
    {
        "序号": 4309,
        "name": "Gotham Short Strategies ETF",
        "最新价": 8.28,
        "涨跌额": 0.03,
        "涨跌幅": 0.36,
        "开盘价": 8.26,
        "最高价": 8.29,
        "最低价": 8.25,
        "昨收价": 8.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 13996.0,
        "成交额": 115733.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.SHRT"
    },
    {
        "序号": 4310,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 38.65,
        "涨跌额": 0.14,
        "涨跌幅": 0.36,
        "开盘价": 38.63,
        "最高价": 38.65,
        "最低价": 38.56,
        "昨收价": 38.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 5364.0,
        "成交额": 206903.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.BMAR"
    },
    {
        "序号": 4311,
        "name": "Dimensional World Equity ETF",
        "最新价": 52.61,
        "涨跌额": 0.19,
        "涨跌幅": 0.36,
        "开盘价": 52.44,
        "最高价": 52.68,
        "最低价": 52.37,
        "昨收价": 52.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 190259.0,
        "成交额": 9986671.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DFAW"
    },
    {
        "序号": 4312,
        "name": "Natera Inc",
        "最新价": 58.15,
        "涨跌额": 0.21,
        "涨跌幅": 0.36,
        "开盘价": 57.52,
        "最高价": 58.66,
        "最低价": 57.02,
        "昨收价": 57.94,
        "总市值": 6986820657.0,
        "市盈率": -13.99,
        "成交量": 942050.0,
        "成交额": 54725186.0,
        "振幅": 2.83,
        "换手率": 0.78,
        "symbol": "105.NTRA"
    },
    {
        "序号": 4313,
        "name": "CMS能源",
        "最新价": 58.15,
        "涨跌额": 0.21,
        "涨跌幅": 0.36,
        "开盘价": 57.87,
        "最高价": 58.27,
        "最低价": 57.41,
        "昨收价": 57.94,
        "总市值": 16966051421.0,
        "市盈率": 22.65,
        "成交量": 2281686.0,
        "成交额": 132185387.0,
        "振幅": 1.48,
        "换手率": 0.78,
        "symbol": "106.CMS"
    },
    {
        "序号": 4314,
        "name": "National Bank Holdings Corp-A",
        "最新价": 36.07,
        "涨跌额": 0.13,
        "涨跌幅": 0.36,
        "开盘价": 35.88,
        "最高价": 36.27,
        "最低价": 35.88,
        "昨收价": 35.94,
        "总市值": 1362044248.0,
        "市盈率": 10.84,
        "成交量": 146575.0,
        "成交额": 5289070.0,
        "振幅": 1.09,
        "换手率": 0.39,
        "symbol": "106.NBHC"
    },
    {
        "序号": 4315,
        "name": "恩尼斯",
        "最新价": 22.23,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 22.04,
        "最高价": 22.37,
        "最低价": 22.03,
        "昨收价": 22.15,
        "总市值": 575194559.0,
        "市盈率": 12.5,
        "成交量": 56072.0,
        "成交额": 1247134.0,
        "振幅": 1.53,
        "换手率": 0.22,
        "symbol": "106.EBF"
    },
    {
        "序号": 4316,
        "name": "Congress SMid Growth ETF",
        "最新价": 25.05,
        "涨跌额": 0.09,
        "涨跌幅": 0.36,
        "开盘价": 25.02,
        "最高价": 25.09,
        "最低价": 24.97,
        "昨收价": 24.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 23681.0,
        "成交额": 591719.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.CSMD"
    },
    {
        "序号": 4317,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 30.68,
        "涨跌额": 0.11,
        "涨跌幅": 0.36,
        "开盘价": 30.57,
        "最高价": 30.69,
        "最低价": 30.54,
        "昨收价": 30.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 13953.0,
        "成交额": 426832.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.JULW"
    },
    {
        "序号": 4318,
        "name": "First Trust Dividend Strength E",
        "最新价": 44.72,
        "涨跌额": 0.16,
        "涨跌幅": 0.36,
        "开盘价": 44.55,
        "最高价": 44.72,
        "最低价": 44.55,
        "昨收价": 44.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 5667.0,
        "成交额": 252472.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "105.FTDS"
    },
    {
        "序号": 4319,
        "name": "Invesco Russell 1000 Equal Weig",
        "最新价": 41.94,
        "涨跌额": 0.15,
        "涨跌幅": 0.36,
        "开盘价": 41.81,
        "最高价": 42.05,
        "最低价": 41.72,
        "昨收价": 41.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 337577.0,
        "成交额": 14137316.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.EQAL"
    },
    {
        "序号": 4320,
        "name": "VictoryShares US Small Cap High",
        "最新价": 53.14,
        "涨跌额": 0.19,
        "涨跌幅": 0.36,
        "开盘价": 52.96,
        "最高价": 53.34,
        "最低价": 52.86,
        "昨收价": 52.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 15669.0,
        "成交额": 831908.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "105.CSB"
    },
    {
        "序号": 4321,
        "name": "摩托罗拉解决方案",
        "最新价": 324.52,
        "涨跌额": 1.16,
        "涨跌幅": 0.36,
        "开盘价": 324.0,
        "最高价": 325.18,
        "最低价": 322.56,
        "昨收价": 323.36,
        "总市值": 53860093077.0,
        "市盈率": 31.65,
        "成交量": 871435.0,
        "成交额": 282591984.0,
        "振幅": 0.81,
        "换手率": 0.53,
        "symbol": "106.MSI"
    },
    {
        "序号": 4322,
        "name": "iShares MSCI World ETF",
        "最新价": 128.92,
        "涨跌额": 0.46,
        "涨跌幅": 0.36,
        "开盘价": 128.25,
        "最高价": 129.11,
        "最低价": 128.2,
        "昨收价": 128.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 194691.0,
        "成交额": 25074664.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.URTH"
    },
    {
        "序号": 4323,
        "name": "BNY Mellon Global Infrastructur",
        "最新价": 28.03,
        "涨跌额": 0.1,
        "涨跌幅": 0.36,
        "开盘价": 28.02,
        "最高价": 28.03,
        "最低价": 27.99,
        "昨收价": 27.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 376.0,
        "成交额": 10530.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.BKGI"
    },
    {
        "序号": 4324,
        "name": "Valuence Merger Corp I-A",
        "最新价": 11.22,
        "涨跌额": 0.04,
        "涨跌幅": 0.36,
        "开盘价": 11.22,
        "最高价": 11.22,
        "最低价": 11.22,
        "昨收价": 11.18,
        "总市值": 131422194.0,
        "市盈率": 27.8,
        "成交量": 1300.0,
        "成交额": 14586.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.VMCA"
    },
    {
        "序号": 4325,
        "name": "InFinT Acquisition Corp-A",
        "最新价": 11.22,
        "涨跌额": 0.04,
        "涨跌幅": 0.36,
        "开盘价": 11.18,
        "最高价": 11.22,
        "最低价": 11.18,
        "昨收价": 11.18,
        "总市值": 148569720.0,
        "市盈率": 50.61,
        "成交量": 23936.0,
        "成交额": 267715.0,
        "振幅": 0.36,
        "换手率": 0.18,
        "symbol": "106.IFIN"
    },
    {
        "序号": 4326,
        "name": "Corner Growth Acquisition Corp ",
        "最新价": 11.24,
        "涨跌额": 0.04,
        "涨跌幅": 0.36,
        "开盘价": 11.24,
        "最高价": 11.24,
        "最低价": 11.24,
        "昨收价": 11.2,
        "总市值": 72893805.0,
        "市盈率": -28.9,
        "成交量": 952.0,
        "成交额": 10700.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.TRON"
    },
    {
        "序号": 4327,
        "name": "First Internet Bancorp",
        "最新价": 22.48,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 22.31,
        "最高价": 22.81,
        "最低价": 22.29,
        "昨收价": 22.4,
        "总市值": 194309769.0,
        "市盈率": 18.29,
        "成交量": 14110.0,
        "成交额": 316519.0,
        "振幅": 2.32,
        "换手率": 0.16,
        "symbol": "105.INBK"
    },
    {
        "序号": 4328,
        "name": "Fidelity MSCI Consumer Discreti",
        "最新价": 75.88,
        "涨跌额": 0.27,
        "涨跌幅": 0.36,
        "开盘价": 75.2,
        "最高价": 76.01,
        "最低价": 75.17,
        "昨收价": 75.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 114059.0,
        "成交额": 8649476.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.FDIS"
    },
    {
        "序号": 4329,
        "name": "Gibraltar Industries Inc",
        "最新价": 70.31,
        "涨跌额": 0.25,
        "涨跌幅": 0.36,
        "开盘价": 69.98,
        "最高价": 70.92,
        "最低价": 69.77,
        "昨收价": 70.06,
        "总市值": 2139985956.0,
        "市盈率": 22.66,
        "成交量": 99756.0,
        "成交额": 7007990.0,
        "振幅": 1.64,
        "换手率": 0.33,
        "symbol": "105.ROCK"
    },
    {
        "序号": 4330,
        "name": "道琼斯工业ETF-SPDR",
        "最新价": 363.12,
        "涨跌额": 1.29,
        "涨跌幅": 0.36,
        "开盘价": 361.76,
        "最高价": 363.68,
        "最低价": 361.38,
        "昨收价": 361.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 2930386.0,
        "成交额": 1062643360.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.DIA"
    },
    {
        "序号": 4331,
        "name": "First Trust Nasdaq BuyWrite Inc",
        "最新价": 19.71,
        "涨跌额": 0.07,
        "涨跌幅": 0.36,
        "开盘价": 19.64,
        "最高价": 19.71,
        "最低价": 19.62,
        "昨收价": 19.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 70812.0,
        "成交额": 1393567.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "105.FTQI"
    },
    {
        "序号": 4332,
        "name": "Cabana Target Drawdown 16 ETF",
        "最新价": 22.55,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 22.45,
        "最高价": 22.55,
        "最低价": 22.45,
        "昨收价": 22.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 9356.0,
        "成交额": 210895.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "105.TDSE"
    },
    {
        "序号": 4333,
        "name": "WisdomTree U.S. ESG Fund",
        "最新价": 47.92,
        "涨跌额": 0.17,
        "涨跌幅": 0.36,
        "开盘价": 47.75,
        "最高价": 47.92,
        "最低价": 47.63,
        "昨收价": 47.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 678.0,
        "成交额": 32399.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.RESP"
    },
    {
        "序号": 4334,
        "name": "iShares MSCI ACWI Low Carbon Ta",
        "最新价": 160.7,
        "涨跌额": 0.57,
        "涨跌幅": 0.36,
        "开盘价": 159.7,
        "最高价": 160.71,
        "最低价": 159.7,
        "昨收价": 160.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 5701.0,
        "成交额": 914213.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.CRBN"
    },
    {
        "序号": 4335,
        "name": "Xtrackers S&P 500 ESG ETF",
        "最新价": 42.29,
        "涨跌额": 0.15,
        "涨跌幅": 0.36,
        "开盘价": 41.99,
        "最高价": 42.32,
        "最低价": 41.99,
        "昨收价": 42.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 143486.0,
        "成交额": 6049709.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.SNPE"
    },
    {
        "序号": 4336,
        "name": "Overlay Shares Foreign Equity E",
        "最新价": 22.57,
        "涨跌额": 0.08,
        "涨跌幅": 0.36,
        "开盘价": 22.46,
        "最高价": 22.57,
        "最低价": 22.46,
        "昨收价": 22.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 6130.0,
        "成交额": 138271.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.OVF"
    },
    {
        "序号": 4337,
        "name": "iShares MSCI Water Management M",
        "最新价": 28.22,
        "涨跌额": 0.1,
        "涨跌幅": 0.36,
        "开盘价": 28.15,
        "最高价": 28.22,
        "最低价": 28.15,
        "昨收价": 28.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 105.0,
        "成交额": 2955.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "105.IWTR"
    },
    {
        "序号": 4338,
        "name": "THE European Equity Fund",
        "最新价": 8.47,
        "涨跌额": 0.03,
        "涨跌幅": 0.36,
        "开盘价": 8.36,
        "最高价": 8.47,
        "最低价": 8.36,
        "昨收价": 8.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 22918.0,
        "成交额": 193917.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "106.EEA"
    },
    {
        "序号": 4339,
        "name": "First Trust Asia Pacific Ex-Jap",
        "最新价": 25.42,
        "涨跌额": 0.09,
        "涨跌幅": 0.36,
        "开盘价": 25.31,
        "最高价": 25.42,
        "最低价": 25.31,
        "昨收价": 25.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 3603.0,
        "成交额": 91217.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "105.FPA"
    },
    {
        "序号": 4340,
        "name": "iShares Russell Top 200 Value E",
        "最新价": 67.8,
        "涨跌额": 0.24,
        "涨跌幅": 0.36,
        "开盘价": 67.61,
        "最高价": 67.9,
        "最低价": 67.55,
        "昨收价": 67.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 103241.0,
        "成交额": 6995621.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.IWX"
    },
    {
        "序号": 4341,
        "name": "R3 Global Dividend Growth ETF",
        "最新价": 22.65,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.59,
        "最高价": 22.68,
        "最低价": 22.57,
        "昨收价": 22.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 3796.0,
        "成交额": 85765.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.GDVD"
    },
    {
        "序号": 4342,
        "name": "ETC 6 Meridian Quality Growth E",
        "最新价": 25.5,
        "涨跌额": 0.09,
        "涨跌幅": 0.35,
        "开盘价": 25.54,
        "最高价": 25.54,
        "最低价": 25.5,
        "昨收价": 25.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 3212.0,
        "成交额": 81971.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.SXQG"
    },
    {
        "序号": 4343,
        "name": "Marinus Pharmaceuticals Inc",
        "最新价": 8.5,
        "涨跌额": 0.03,
        "涨跌幅": 0.35,
        "开盘价": 8.44,
        "最高价": 8.75,
        "最低价": 8.28,
        "昨收价": 8.47,
        "总市值": 463875439.0,
        "市盈率": -3.46,
        "成交量": 310914.0,
        "成交额": 2627226.0,
        "振幅": 5.55,
        "换手率": 0.57,
        "symbol": "105.MRNS"
    },
    {
        "序号": 4344,
        "name": "RiverFront Strategic Income Fun",
        "最新价": 22.67,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.43,
        "最高价": 22.73,
        "最低价": 22.43,
        "昨收价": 22.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 43005.0,
        "成交额": 973301.0,
        "振幅": 1.33,
        "换手率": null,
        "symbol": "107.RIGS"
    },
    {
        "序号": 4345,
        "name": "达那俄斯",
        "最新价": 68.14,
        "涨跌额": 0.24,
        "涨跌幅": 0.35,
        "开盘价": 68.08,
        "最高价": 69.45,
        "最低价": 67.88,
        "昨收价": 67.9,
        "总市值": 1328526125.0,
        "市盈率": 2.29,
        "成交量": 65282.0,
        "成交额": 4455225.0,
        "振幅": 2.31,
        "换手率": 0.33,
        "symbol": "106.DAC"
    },
    {
        "序号": 4346,
        "name": "SRM Entertainment Inc",
        "最新价": 2.84,
        "涨跌额": 0.01,
        "涨跌幅": 0.35,
        "开盘价": 2.98,
        "最高价": 2.98,
        "最低价": 2.8,
        "昨收价": 2.83,
        "总市值": 27734020.0,
        "市盈率": null,
        "成交量": 37199.0,
        "成交额": 107336.0,
        "振幅": 6.36,
        "换手率": 0.38,
        "symbol": "105.SRM"
    },
    {
        "序号": 4347,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 25.58,
        "涨跌额": 0.09,
        "涨跌幅": 0.35,
        "开盘价": 25.56,
        "最高价": 25.58,
        "最低价": 25.53,
        "昨收价": 25.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 1959.0,
        "成交额": 50040.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.SEPT"
    },
    {
        "序号": 4348,
        "name": "BioCryst Pharmaceuticals Inc",
        "最新价": 5.69,
        "涨跌额": 0.02,
        "涨跌幅": 0.35,
        "开盘价": 5.65,
        "最高价": 5.82,
        "最低价": 5.59,
        "昨收价": 5.67,
        "总市值": 1165365372.0,
        "市盈率": -4.93,
        "成交量": 5120367.0,
        "成交额": 29355502.0,
        "振幅": 4.06,
        "换手率": 2.5,
        "symbol": "105.BCRX"
    },
    {
        "序号": 4349,
        "name": "Adams Diversified Equity Fund",
        "最新价": 17.07,
        "涨跌额": 0.06,
        "涨跌幅": 0.35,
        "开盘价": 16.99,
        "最高价": 17.1,
        "最低价": 16.99,
        "昨收价": 17.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 214383.0,
        "成交额": 3657303.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "106.ADX"
    },
    {
        "序号": 4350,
        "name": "Segall Bryant & Hamill Select E",
        "最新价": 25.64,
        "涨跌额": 0.09,
        "涨跌幅": 0.35,
        "开盘价": 25.63,
        "最高价": 25.65,
        "最低价": 25.56,
        "昨收价": 25.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 112126.0,
        "成交额": 2866660.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.USSE"
    },
    {
        "序号": 4351,
        "name": "Dynatrace Inc",
        "最新价": 54.13,
        "涨跌额": 0.19,
        "涨跌幅": 0.35,
        "开盘价": 53.59,
        "最高价": 54.17,
        "最低价": 53.45,
        "昨收价": 53.94,
        "总市值": 15935573311.0,
        "市盈率": 94.12,
        "成交量": 2036343.0,
        "成交额": 109916071.0,
        "振幅": 1.33,
        "换手率": 0.69,
        "symbol": "106.DT"
    },
    {
        "序号": 4352,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 28.49,
        "涨跌额": 0.1,
        "涨跌幅": 0.35,
        "开盘价": 28.38,
        "最高价": 28.49,
        "最低价": 28.38,
        "昨收价": 28.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1150.0,
        "成交额": 32637.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.MART"
    },
    {
        "序号": 4353,
        "name": "Legacy Housing Corp",
        "最新价": 22.81,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.87,
        "最高价": 22.88,
        "最低价": 22.51,
        "昨收价": 22.73,
        "总市值": 556376890.0,
        "市盈率": 8.3,
        "成交量": 19063.0,
        "成交额": 434429.0,
        "振幅": 1.63,
        "换手率": 0.08,
        "symbol": "105.LEGH"
    },
    {
        "序号": 4354,
        "name": "澳大利亚ETF-iShares MSCI",
        "最新价": 22.82,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.69,
        "最高价": 22.89,
        "最低价": 22.67,
        "昨收价": 22.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 1528920.0,
        "成交额": 34834766.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.EWA"
    },
    {
        "序号": 4355,
        "name": "Vanguard Mega Cap Value ETF",
        "最新价": 105.55,
        "涨跌额": 0.37,
        "涨跌幅": 0.35,
        "开盘价": 105.23,
        "最高价": 105.73,
        "最低价": 105.05,
        "昨收价": 105.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 119227.0,
        "成交额": 12564051.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.MGV"
    },
    {
        "序号": 4356,
        "name": "TrueShares Structured Outcome (",
        "最新价": 34.27,
        "涨跌额": 0.12,
        "涨跌幅": 0.35,
        "开盘价": 34.27,
        "最高价": 34.27,
        "最低价": 34.27,
        "昨收价": 34.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 68.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AUGZ"
    },
    {
        "序号": 4357,
        "name": "VictoryShares US Discovery Enha",
        "最新价": 48.59,
        "涨跌额": 0.17,
        "涨跌幅": 0.35,
        "开盘价": 48.55,
        "最高价": 48.62,
        "最低价": 48.51,
        "昨收价": 48.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 8531.0,
        "成交额": 414288.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "105.CSF"
    },
    {
        "序号": 4358,
        "name": "Xtrackers MSCI USA ESG Leaders ",
        "最新价": 42.88,
        "涨跌额": 0.15,
        "涨跌幅": 0.35,
        "开盘价": 42.57,
        "最高价": 42.92,
        "最低价": 42.57,
        "昨收价": 42.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 15749.0,
        "成交额": 674610.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.USSG"
    },
    {
        "序号": 4359,
        "name": "Strategas Macro Thematic Opport",
        "最新价": 22.87,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.84,
        "最高价": 22.88,
        "最低价": 22.76,
        "昨收价": 22.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 3058.0,
        "成交额": 69741.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.SAMT"
    },
    {
        "序号": 4360,
        "name": "Syntec Optics Holdings Inc-A",
        "最新价": 5.72,
        "涨跌额": 0.02,
        "涨跌幅": 0.35,
        "开盘价": 5.57,
        "最高价": 5.95,
        "最低价": 5.41,
        "昨收价": 5.7,
        "总市值": 209856882.0,
        "市盈率": 1550.87,
        "成交量": 14445.0,
        "成交额": 81311.0,
        "振幅": 9.47,
        "换手率": 0.04,
        "symbol": "105.OPTX"
    },
    {
        "序号": 4361,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 42.94,
        "涨跌额": 0.15,
        "涨跌幅": 0.35,
        "开盘价": 42.67,
        "最高价": 42.94,
        "最低价": 42.67,
        "昨收价": 42.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 19245.0,
        "成交额": 823374.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.FJUN"
    },
    {
        "序号": 4362,
        "name": "American Century Sustainable Eq",
        "最新价": 57.28,
        "涨跌额": 0.2,
        "涨跌幅": 0.35,
        "开盘价": 57.1,
        "最高价": 57.3,
        "最低价": 57.1,
        "昨收价": 57.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 3596.0,
        "成交额": 205893.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.ESGA"
    },
    {
        "序号": 4363,
        "name": "Goldman Sachs S&P 500 Core Prem",
        "最新价": 42.97,
        "涨跌额": 0.15,
        "涨跌幅": 0.35,
        "开盘价": 42.86,
        "最高价": 42.99,
        "最低价": 42.81,
        "昨收价": 42.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 29555.0,
        "成交额": 1268351.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "105.GPIX"
    },
    {
        "序号": 4364,
        "name": "全球可选消费ETF-iShares",
        "最新价": 154.72,
        "涨跌额": 0.54,
        "涨跌幅": 0.35,
        "开盘价": 153.64,
        "最高价": 154.77,
        "最低价": 153.64,
        "昨收价": 154.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 4121.0,
        "成交额": 636889.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.RXI"
    },
    {
        "序号": 4365,
        "name": "iShares U.S. Industrials ETF",
        "最新价": 108.97,
        "涨跌额": 0.38,
        "涨跌幅": 0.35,
        "开盘价": 108.47,
        "最高价": 109.32,
        "最低价": 108.45,
        "昨收价": 108.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 44878.0,
        "成交额": 4886646.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.IYJ"
    },
    {
        "序号": 4366,
        "name": "FIS Knights of Columbus Global ",
        "最新价": 22.99,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 22.99,
        "最高价": 22.99,
        "最低价": 22.99,
        "昨收价": 22.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 14.0,
        "成交额": 321.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KOCG"
    },
    {
        "序号": 4367,
        "name": "AG Mortgage Investment Trust In",
        "最新价": 5.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.35,
        "开盘价": 5.7,
        "最高价": 5.77,
        "最低价": 5.59,
        "昨收价": 5.73,
        "总市值": 116351152.0,
        "市盈率": 3.86,
        "成交量": 263204.0,
        "成交额": 1501657.0,
        "振幅": 3.14,
        "换手率": 1.3,
        "symbol": "106.MITT"
    },
    {
        "序号": 4368,
        "name": "TrueShares Structured Outcome (",
        "最新价": 34.51,
        "涨跌额": 0.12,
        "涨跌幅": 0.35,
        "开盘价": 34.42,
        "最高价": 34.51,
        "最低价": 34.42,
        "昨收价": 34.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1659.0,
        "成交额": 57122.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.NOVZ"
    },
    {
        "序号": 4369,
        "name": "FCF US Quality ETF",
        "最新价": 54.73,
        "涨跌额": 0.19,
        "涨跌幅": 0.35,
        "开盘价": 54.65,
        "最高价": 54.76,
        "最低价": 54.65,
        "昨收价": 54.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 16971.0,
        "成交额": 929109.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.TTAC"
    },
    {
        "序号": 4370,
        "name": "美国网存",
        "最新价": 89.39,
        "涨跌额": 0.31,
        "涨跌幅": 0.35,
        "开盘价": 89.08,
        "最高价": 90.22,
        "最低价": 88.93,
        "昨收价": 89.08,
        "总市值": 18417115291.0,
        "市盈率": 26.61,
        "成交量": 1634087.0,
        "成交额": 146260353.0,
        "振幅": 1.45,
        "换手率": 0.79,
        "symbol": "105.NTAP"
    },
    {
        "序号": 4371,
        "name": "Franklin U.S. Large Cap Multifa",
        "最新价": 46.18,
        "涨跌额": 0.16,
        "涨跌幅": 0.35,
        "开盘价": 45.93,
        "最高价": 46.25,
        "最低价": 45.91,
        "昨收价": 46.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 46530.0,
        "成交额": 2145208.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.FLQL"
    },
    {
        "序号": 4372,
        "name": "亚历山大&鲍德温",
        "最新价": 17.33,
        "涨跌额": 0.06,
        "涨跌幅": 0.35,
        "开盘价": 17.21,
        "最高价": 17.36,
        "最低价": 17.08,
        "昨收价": 17.27,
        "总市值": 1257054339.0,
        "市盈率": -32.82,
        "成交量": 204063.0,
        "成交额": 3525410.0,
        "振幅": 1.62,
        "换手率": 0.28,
        "symbol": "106.ALEX"
    },
    {
        "序号": 4373,
        "name": "MasterBrand Inc",
        "最新价": 14.45,
        "涨跌额": 0.05,
        "涨跌幅": 0.35,
        "开盘价": 14.44,
        "最高价": 14.63,
        "最低价": 14.35,
        "昨收价": 14.4,
        "总市值": 1831715437.0,
        "市盈率": 11.36,
        "成交量": 715045.0,
        "成交额": 10327685.0,
        "振幅": 1.94,
        "换手率": 0.56,
        "symbol": "106.MBC"
    },
    {
        "序号": 4374,
        "name": "Special Opportunities Fund",
        "最新价": 11.57,
        "涨跌额": 0.04,
        "涨跌幅": 0.35,
        "开盘价": 11.51,
        "最高价": 11.59,
        "最低价": 11.51,
        "昨收价": 11.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 11845.0,
        "成交额": 136694.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.SPE"
    },
    {
        "序号": 4375,
        "name": "Nuveen Global High Income Fund",
        "最新价": 11.58,
        "涨跌额": 0.04,
        "涨跌幅": 0.35,
        "开盘价": 11.49,
        "最高价": 11.58,
        "最低价": 11.47,
        "昨收价": 11.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 50374.0,
        "成交额": 580404.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.JGH"
    },
    {
        "序号": 4376,
        "name": "JPMorgan BetaBuilders Developed",
        "最新价": 46.33,
        "涨跌额": 0.16,
        "涨跌幅": 0.35,
        "开盘价": 46.08,
        "最高价": 46.47,
        "最低价": 46.07,
        "昨收价": 46.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 223416.0,
        "成交额": 10332875.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.BBAX"
    },
    {
        "序号": 4377,
        "name": "TrueShares Structured Outcome (",
        "最新价": 29.0,
        "涨跌额": 0.1,
        "涨跌幅": 0.35,
        "开盘价": 28.83,
        "最高价": 29.0,
        "最低价": 28.83,
        "昨收价": 28.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 935.0,
        "成交额": 26956.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.MARZ"
    },
    {
        "序号": 4378,
        "name": "AXS Astoria Inflation Sensitive",
        "最新价": 26.11,
        "涨跌额": 0.09,
        "涨跌幅": 0.35,
        "开盘价": 26.21,
        "最高价": 26.21,
        "最低价": 26.08,
        "昨收价": 26.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1591.0,
        "成交额": 41560.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.PPI"
    },
    {
        "序号": 4379,
        "name": "RiverNorth Patriot ETF",
        "最新价": 23.21,
        "涨跌额": 0.08,
        "涨跌幅": 0.35,
        "开盘价": 23.21,
        "最高价": 23.21,
        "最低价": 23.21,
        "昨收价": 23.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 15.0,
        "成交额": 348.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FLDZ"
    },
    {
        "序号": 4380,
        "name": "Urban Edge Properties",
        "最新价": 17.41,
        "涨跌额": 0.06,
        "涨跌幅": 0.35,
        "开盘价": 17.32,
        "最高价": 17.42,
        "最低价": 17.12,
        "昨收价": 17.35,
        "总市值": 2048155612.0,
        "市盈率": 50.03,
        "成交量": 668227.0,
        "成交额": 11583672.0,
        "振幅": 1.73,
        "换手率": 0.57,
        "symbol": "106.UE"
    },
    {
        "序号": 4381,
        "name": "FT Cboe Vest DJIA Dogs 10 Targe",
        "最新价": 20.35,
        "涨跌额": 0.07,
        "涨跌幅": 0.35,
        "开盘价": 20.41,
        "最高价": 20.41,
        "最低价": 20.35,
        "昨收价": 20.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 5816.0,
        "成交额": 118531.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.DOGG"
    },
    {
        "序号": 4382,
        "name": "Fidelity High Dividend ETF",
        "最新价": 40.71,
        "涨跌额": 0.14,
        "涨跌幅": 0.35,
        "开盘价": 40.55,
        "最高价": 40.8,
        "最低价": 40.5,
        "昨收价": 40.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 195636.0,
        "成交额": 7955588.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.FDVV"
    },
    {
        "序号": 4383,
        "name": "AB US Large Cap Strategic Equit",
        "最新价": 52.37,
        "涨跌额": 0.18,
        "涨跌幅": 0.34,
        "开盘价": 52.37,
        "最高价": 52.37,
        "最低价": 52.37,
        "昨收价": 52.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 5480.0,
        "成交额": 286987.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LRGC"
    },
    {
        "序号": 4384,
        "name": "Performant Financial Corp",
        "最新价": 2.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.34,
        "开盘价": 2.87,
        "最高价": 2.97,
        "最低价": 2.83,
        "昨收价": 2.9,
        "总市值": 223706163.0,
        "市盈率": -24.83,
        "成交量": 68603.0,
        "成交额": 199034.0,
        "振幅": 4.83,
        "换手率": 0.09,
        "symbol": "105.PFMT"
    },
    {
        "序号": 4385,
        "name": "Voya Asia Pacific High Dividend",
        "最新价": 5.82,
        "涨跌额": 0.02,
        "涨跌幅": 0.34,
        "开盘价": 5.79,
        "最高价": 5.84,
        "最低价": 5.79,
        "昨收价": 5.8,
        "总市值": 910814385.0,
        "市盈率": null,
        "成交量": 6293.0,
        "成交额": 36618.0,
        "振幅": 0.86,
        "换手率": 0.0,
        "symbol": "106.IAE"
    },
    {
        "序号": 4386,
        "name": "恩桥",
        "最新价": 34.92,
        "涨跌额": 0.12,
        "涨跌幅": 0.34,
        "开盘价": 34.87,
        "最高价": 34.96,
        "最低价": 34.74,
        "昨收价": 34.8,
        "总市值": 74225058467.0,
        "市盈率": 29.07,
        "成交量": 3169319.0,
        "成交额": 110534032.0,
        "振幅": 0.63,
        "换手率": 0.15,
        "symbol": "106.ENB"
    },
    {
        "序号": 4387,
        "name": "康宁",
        "最新价": 29.11,
        "涨跌额": 0.1,
        "涨跌幅": 0.34,
        "开盘价": 29.08,
        "最高价": 29.38,
        "最低价": 29.01,
        "昨收价": 29.01,
        "总市值": 24836131746.0,
        "市盈率": 42.45,
        "成交量": 3790882.0,
        "成交额": 110526225.0,
        "振幅": 1.28,
        "换手率": 0.44,
        "symbol": "106.GLW"
    },
    {
        "序号": 4388,
        "name": "埃文斯万通金控",
        "最新价": 29.11,
        "涨跌额": 0.1,
        "涨跌幅": 0.34,
        "开盘价": 29.41,
        "最高价": 29.41,
        "最低价": 28.95,
        "昨收价": 29.01,
        "总市值": 159627334.0,
        "市盈率": 7.83,
        "成交量": 11759.0,
        "成交额": 342295.0,
        "振幅": 1.59,
        "换手率": 0.21,
        "symbol": "107.EVBN"
    },
    {
        "序号": 4389,
        "name": "Goldman Sachs Equal Weight U.S.",
        "最新价": 64.11,
        "涨跌额": 0.22,
        "涨跌幅": 0.34,
        "开盘价": 63.89,
        "最高价": 64.22,
        "最低价": 63.86,
        "昨收价": 63.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 15674.0,
        "成交额": 1003006.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.GSEW"
    },
    {
        "序号": 4390,
        "name": "Direxion Daily AMZN Bull 1.5X S",
        "最新价": 26.25,
        "涨跌额": 0.09,
        "涨跌幅": 0.34,
        "开盘价": 25.81,
        "最高价": 26.38,
        "最低价": 25.77,
        "昨收价": 26.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 142896.0,
        "成交额": 3730357.0,
        "振幅": 2.33,
        "换手率": null,
        "symbol": "105.AMZU"
    },
    {
        "序号": 4391,
        "name": "WisdomTree U.S. LargeCap Divide",
        "最新价": 64.24,
        "涨跌额": 0.22,
        "涨跌幅": 0.34,
        "开盘价": 64.03,
        "最高价": 64.37,
        "最低价": 63.94,
        "昨收价": 64.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 146432.0,
        "成交额": 9395903.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.DLN"
    },
    {
        "序号": 4392,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 32.13,
        "涨跌额": 0.11,
        "涨跌幅": 0.34,
        "开盘价": 32.09,
        "最高价": 32.14,
        "最低价": 32.03,
        "昨收价": 32.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 25737.0,
        "成交额": 825531.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.PFEB"
    },
    {
        "序号": 4393,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 29.25,
        "涨跌额": 0.1,
        "涨跌幅": 0.34,
        "开盘价": 29.29,
        "最高价": 29.29,
        "最低价": 29.25,
        "昨收价": 29.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 1711.0,
        "成交额": 50115.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.XDJL"
    },
    {
        "序号": 4394,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 32.18,
        "涨跌额": 0.11,
        "涨跌幅": 0.34,
        "开盘价": 32.11,
        "最高价": 32.18,
        "最低价": 32.11,
        "昨收价": 32.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1798.0,
        "成交额": 57777.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.GAPR"
    },
    {
        "序号": 4395,
        "name": "PBF Energy Inc-A",
        "最新价": 40.98,
        "涨跌额": 0.14,
        "涨跌幅": 0.34,
        "开盘价": 41.28,
        "最高价": 42.0,
        "最低价": 40.93,
        "昨收价": 40.84,
        "总市值": 5007802635.0,
        "市盈率": 1.77,
        "成交量": 1511776.0,
        "成交额": 62232216.0,
        "振幅": 2.62,
        "换手率": 1.24,
        "symbol": "106.PBF"
    },
    {
        "序号": 4396,
        "name": "DXP Enterprises Inc",
        "最新价": 32.2,
        "涨跌额": 0.11,
        "涨跌幅": 0.34,
        "开盘价": 32.1,
        "最高价": 32.44,
        "最低价": 31.75,
        "昨收价": 32.09,
        "总市值": 520880466.0,
        "市盈率": 8.69,
        "成交量": 76545.0,
        "成交额": 2459357.0,
        "振幅": 2.15,
        "换手率": 0.47,
        "symbol": "105.DXPE"
    },
    {
        "序号": 4397,
        "name": "Global X Thematic Growth ETF",
        "最新价": 23.42,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.31,
        "最高价": 23.42,
        "最低价": 23.3,
        "昨收价": 23.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 1296.0,
        "成交额": 30275.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "105.GXTG"
    },
    {
        "序号": 4398,
        "name": "First Trust Dow Jones STOXX Sel",
        "最新价": 11.71,
        "涨跌额": 0.04,
        "涨跌幅": 0.34,
        "开盘价": 11.66,
        "最高价": 11.72,
        "最低价": 11.66,
        "昨收价": 11.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 37945.0,
        "成交额": 443637.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.FDD"
    },
    {
        "序号": 4399,
        "name": "Patrick Industries Inc",
        "最新价": 87.86,
        "涨跌额": 0.3,
        "涨跌幅": 0.34,
        "开盘价": 87.51,
        "最高价": 88.5,
        "最低价": 86.72,
        "昨收价": 87.56,
        "总市值": 1944723464.0,
        "市盈率": 12.77,
        "成交量": 105232.0,
        "成交额": 9235856.0,
        "振幅": 2.03,
        "换手率": 0.48,
        "symbol": "105.PATK"
    },
    {
        "序号": 4400,
        "name": "T. Rowe Price Equity Income ETF",
        "最新价": 35.2,
        "涨跌额": 0.12,
        "涨跌幅": 0.34,
        "开盘价": 35.21,
        "最高价": 35.25,
        "最低价": 35.14,
        "昨收价": 35.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 8288.0,
        "成交额": 291516.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.TEQI"
    },
    {
        "序号": 4401,
        "name": "Oxford Square Capital Corp",
        "最新价": 2.94,
        "涨跌额": 0.01,
        "涨跌幅": 0.34,
        "开盘价": 2.92,
        "最高价": 2.95,
        "最低价": 2.91,
        "昨收价": 2.93,
        "总市值": 170901274.0,
        "市盈率": 96.37,
        "成交量": 191719.0,
        "成交额": 560796.0,
        "振幅": 1.37,
        "换手率": 0.33,
        "symbol": "105.OXSQ"
    },
    {
        "序号": 4402,
        "name": "Franklin FTSE Japan Hedged ETF",
        "最新价": 32.36,
        "涨跌额": 0.11,
        "涨跌幅": 0.34,
        "开盘价": 32.0,
        "最高价": 32.36,
        "最低价": 32.0,
        "昨收价": 32.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 19227.0,
        "成交额": 618503.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "107.FLJH"
    },
    {
        "序号": 4403,
        "name": "Innovator ETFs Trust Innovator ",
        "最新价": 29.45,
        "涨跌额": 0.1,
        "涨跌幅": 0.34,
        "开盘价": 29.39,
        "最高价": 29.45,
        "最低价": 29.34,
        "昨收价": 29.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 10390.0,
        "成交额": 305741.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.XDSQ"
    },
    {
        "序号": 4404,
        "name": "Amplify CWP Enhanced Dividend I",
        "最新价": 35.35,
        "涨跌额": 0.12,
        "涨跌幅": 0.34,
        "开盘价": 35.24,
        "最高价": 35.42,
        "最低价": 35.22,
        "昨收价": 35.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 220485.0,
        "成交额": 7786190.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.DIVO"
    },
    {
        "序号": 4405,
        "name": "ALPS Equal Sector Weight ETF",
        "最新价": 106.09,
        "涨跌额": 0.36,
        "涨跌幅": 0.34,
        "开盘价": 105.61,
        "最高价": 106.1,
        "最低价": 105.61,
        "昨收价": 105.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 6268.0,
        "成交额": 663629.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.EQL"
    },
    {
        "序号": 4406,
        "name": "Hilton Small-MidCap Opportunity",
        "最新价": 20.65,
        "涨跌额": 0.07,
        "涨跌幅": 0.34,
        "开盘价": 20.7,
        "最高价": 20.7,
        "最低价": 20.56,
        "昨收价": 20.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 7195.0,
        "成交额": 148526.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "105.SMCO"
    },
    {
        "序号": 4407,
        "name": "创力",
        "最新价": 5.91,
        "涨跌额": 0.02,
        "涨跌幅": 0.34,
        "开盘价": 5.88,
        "最高价": 6.02,
        "最低价": 5.84,
        "昨收价": 5.89,
        "总市值": 220238112.0,
        "市盈率": -23.91,
        "成交量": 106126.0,
        "成交额": 631603.0,
        "振幅": 3.06,
        "换手率": 0.28,
        "symbol": "105.LTRX"
    },
    {
        "序号": 4408,
        "name": "Fidelity International Value Fa",
        "最新价": 23.67,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.53,
        "最高价": 23.71,
        "最低价": 23.53,
        "昨收价": 23.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 49636.0,
        "成交额": 1172581.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.FIVA"
    },
    {
        "序号": 4409,
        "name": "CNB金融",
        "最新价": 20.75,
        "涨跌额": 0.07,
        "涨跌幅": 0.34,
        "开盘价": 20.64,
        "最高价": 21.11,
        "最低价": 20.6,
        "昨收价": 20.68,
        "总市值": 433629475.0,
        "市盈率": 7.24,
        "成交量": 67997.0,
        "成交额": 1412615.0,
        "振幅": 2.47,
        "换手率": 0.33,
        "symbol": "105.CCNE"
    },
    {
        "序号": 4410,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 35.6,
        "涨跌额": 0.12,
        "涨跌幅": 0.34,
        "开盘价": 35.53,
        "最高价": 35.66,
        "最低价": 35.49,
        "昨收价": 35.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 19790.0,
        "成交额": 703597.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.DJUL"
    },
    {
        "序号": 4411,
        "name": "Neuberger Berman Carbon Transit",
        "最新价": 23.82,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.82,
        "最高价": 23.82,
        "最低价": 23.82,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 166.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NBCT"
    },
    {
        "序号": 4412,
        "name": "SPDR Portfolio MSCI Global Stoc",
        "最新价": 53.61,
        "涨跌额": 0.18,
        "涨跌幅": 0.34,
        "开盘价": 53.32,
        "最高价": 53.71,
        "最低价": 53.32,
        "昨收价": 53.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 21611.0,
        "成交额": 1157096.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.SPGM"
    },
    {
        "序号": 4413,
        "name": "Peloton Interactive Inc-A",
        "最新价": 5.96,
        "涨跌额": 0.02,
        "涨跌幅": 0.34,
        "开盘价": 5.93,
        "最高价": 6.03,
        "最低价": 5.75,
        "昨收价": 5.94,
        "总市值": 2148281815.0,
        "市盈率": -2.12,
        "成交量": 10844279.0,
        "成交额": 63994595.0,
        "振幅": 4.71,
        "换手率": 3.01,
        "symbol": "105.PTON"
    },
    {
        "序号": 4414,
        "name": "儒博实业",
        "最新价": 536.6,
        "涨跌额": 1.8,
        "涨跌幅": 0.34,
        "开盘价": 533.45,
        "最高价": 537.58,
        "最低价": 531.71,
        "昨收价": 534.8,
        "总市值": 57320747982.0,
        "市盈率": 19.61,
        "成交量": 394042.0,
        "成交额": 211077074.0,
        "振幅": 1.1,
        "换手率": 0.37,
        "symbol": "105.ROP"
    },
    {
        "序号": 4415,
        "name": "Eagle Point Credit Co Inc Notes",
        "最新价": 23.85,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 24.0,
        "最高价": 24.0,
        "最低价": 23.4,
        "昨收价": 23.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 568.0,
        "成交额": 13491.0,
        "振幅": 2.52,
        "换手率": null,
        "symbol": "106.ECCW"
    },
    {
        "序号": 4416,
        "name": "猫途鹰",
        "最新价": 17.91,
        "涨跌额": 0.06,
        "涨跌幅": 0.34,
        "开盘价": 17.78,
        "最高价": 18.13,
        "最低价": 17.76,
        "昨收价": 17.85,
        "总市值": 2480795626.0,
        "市盈率": -103.37,
        "成交量": 1948695.0,
        "成交额": 34936561.0,
        "振幅": 2.07,
        "换手率": 1.41,
        "symbol": "105.TRIP"
    },
    {
        "序号": 4417,
        "name": "Ready Capital Corp Notes",
        "最新价": 23.88,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.83,
        "最高价": 23.88,
        "最低价": 23.76,
        "昨收价": 23.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 4383.0,
        "成交额": 104369.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "106.RCC"
    },
    {
        "序号": 4418,
        "name": "燕谷坊",
        "最新价": 2.99,
        "涨跌额": 0.01,
        "涨跌幅": 0.34,
        "开盘价": 2.99,
        "最高价": 3.0,
        "最低价": 2.89,
        "昨收价": 2.98,
        "总市值": 95680000.0,
        "市盈率": 22.08,
        "成交量": 71861.0,
        "成交额": 211256.0,
        "振幅": 3.69,
        "换手率": 0.22,
        "symbol": "105.YGF"
    },
    {
        "序号": 4419,
        "name": "WisdomTree Cybersecurity Fund",
        "最新价": 23.92,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.51,
        "最高价": 24.0,
        "最低价": 23.51,
        "昨收价": 23.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 16138.0,
        "成交额": 385557.0,
        "振幅": 2.06,
        "换手率": null,
        "symbol": "105.WCBR"
    },
    {
        "序号": 4420,
        "name": "Ready Capital Corp Notes",
        "最新价": 23.93,
        "涨跌额": 0.08,
        "涨跌幅": 0.34,
        "开盘价": 23.88,
        "最高价": 23.93,
        "最低价": 23.7,
        "昨收价": 23.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 14372.0,
        "成交额": 342701.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "106.RCB"
    },
    {
        "序号": 4421,
        "name": "Sound Enhanced Fixed Income ETF",
        "最新价": 17.95,
        "涨跌额": 0.06,
        "涨跌幅": 0.34,
        "开盘价": 17.86,
        "最高价": 18.02,
        "最低价": 17.85,
        "昨收价": 17.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 4418.0,
        "成交额": 79251.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.FXED"
    },
    {
        "序号": 4422,
        "name": "KeyCorp Series E Pfd",
        "最新价": 20.95,
        "涨跌额": 0.07,
        "涨跌幅": 0.34,
        "开盘价": 20.78,
        "最高价": 20.98,
        "最低价": 20.49,
        "昨收价": 20.88,
        "总市值": 10475000.0,
        "市盈率": null,
        "成交量": 47084.0,
        "成交额": 977512.0,
        "振幅": 2.35,
        "换手率": 9.42,
        "symbol": "106.KEY_I"
    },
    {
        "序号": 4423,
        "name": "VanEck Morningstar Wide Moat ET",
        "最新价": 80.82,
        "涨跌额": 0.27,
        "涨跌幅": 0.34,
        "开盘价": 80.47,
        "最高价": 81.08,
        "最低价": 80.46,
        "昨收价": 80.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1238088.0,
        "成交额": 100101297.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.MOAT"
    },
    {
        "序号": 4424,
        "name": "iShares MSCI EAFE Growth ETF",
        "最新价": 92.84,
        "涨跌额": 0.31,
        "涨跌幅": 0.34,
        "开盘价": 92.3,
        "最高价": 93.14,
        "最低价": 92.3,
        "昨收价": 92.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 860878.0,
        "成交额": 79832176.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.EFG"
    },
    {
        "序号": 4425,
        "name": "AstroNova Inc",
        "最新价": 14.99,
        "涨跌额": 0.05,
        "涨跌幅": 0.33,
        "开盘价": 14.96,
        "最高价": 15.19,
        "最低价": 14.83,
        "昨收价": 14.94,
        "总市值": 111417657.0,
        "市盈率": 33.3,
        "成交量": 13593.0,
        "成交额": 203812.0,
        "振幅": 2.41,
        "换手率": 0.18,
        "symbol": "105.ALOT"
    },
    {
        "序号": 4426,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 30.01,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 29.88,
        "最高价": 30.01,
        "最低价": 29.88,
        "昨收价": 29.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 3085.0,
        "成交额": 92371.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.XBJL"
    },
    {
        "序号": 4427,
        "name": "iShares U.S. Insurance ETF",
        "最新价": 99.05,
        "涨跌额": 0.33,
        "涨跌幅": 0.33,
        "开盘价": 98.81,
        "最高价": 99.33,
        "最低价": 98.65,
        "昨收价": 98.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 19504.0,
        "成交额": 1929596.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.IAK"
    },
    {
        "序号": 4428,
        "name": "NSTS Bancorp Inc",
        "最新价": 9.01,
        "涨跌额": 0.03,
        "涨跌幅": 0.33,
        "开盘价": 9.0,
        "最高价": 9.03,
        "最低价": 9.0,
        "昨收价": 8.98,
        "总市值": 47890502.0,
        "市盈率": -321.41,
        "成交量": 3562.0,
        "成交额": 32080.0,
        "振幅": 0.33,
        "换手率": 0.07,
        "symbol": "105.NSTS"
    },
    {
        "序号": 4429,
        "name": "Innovator U.S. Equity 5 to 15 B",
        "最新价": 27.04,
        "涨跌额": 0.09,
        "涨跌幅": 0.33,
        "开盘价": 27.02,
        "最高价": 27.04,
        "最低价": 27.02,
        "昨收价": 26.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 366.0,
        "成交额": 9887.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.EALT"
    },
    {
        "序号": 4430,
        "name": "信诺",
        "最新价": 258.8,
        "涨跌额": 0.86,
        "涨跌幅": 0.33,
        "开盘价": 259.47,
        "最高价": 259.7,
        "最低价": 253.95,
        "昨收价": 257.94,
        "总市值": 75730047201.0,
        "市盈率": 14.31,
        "成交量": 2781362.0,
        "成交额": 715433376.0,
        "振幅": 2.23,
        "换手率": 0.95,
        "symbol": "106.CI"
    },
    {
        "序号": 4431,
        "name": "Direxion Daily Pharmaceutical &",
        "最新价": 6.02,
        "涨跌额": 0.02,
        "涨跌幅": 0.33,
        "开盘价": 6.0,
        "最高价": 6.19,
        "最低价": 5.95,
        "昨收价": 6.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 74732.0,
        "成交额": 452939.0,
        "振幅": 4.0,
        "换手率": null,
        "symbol": "107.PILL"
    },
    {
        "序号": 4432,
        "name": "First Trust Indxx Innovative Tr",
        "最新价": 39.13,
        "涨跌额": 0.13,
        "涨跌幅": 0.33,
        "开盘价": 38.9,
        "最高价": 39.17,
        "最低价": 38.9,
        "昨收价": 39.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 13369.0,
        "成交额": 522148.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "105.LEGR"
    },
    {
        "序号": 4433,
        "name": "iShares Edge MSCI Intl Quality ",
        "最新价": 36.12,
        "涨跌额": 0.12,
        "涨跌幅": 0.33,
        "开盘价": 35.91,
        "最高价": 36.19,
        "最低价": 35.91,
        "昨收价": 36.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 484895.0,
        "成交额": 17493088.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.IQLT"
    },
    {
        "序号": 4434,
        "name": "BM Technologies Inc",
        "最新价": 3.01,
        "涨跌额": 0.01,
        "涨跌幅": 0.33,
        "开盘价": 3.01,
        "最高价": 3.03,
        "最低价": 2.81,
        "昨收价": 3.0,
        "总市值": 35728474.0,
        "市盈率": -2.04,
        "成交量": 100729.0,
        "成交额": 287819.0,
        "振幅": 7.33,
        "换手率": 0.85,
        "symbol": "107.BMTX"
    },
    {
        "序号": 4435,
        "name": "Evertec Inc",
        "最新价": 39.14,
        "涨跌额": 0.13,
        "涨跌幅": 0.33,
        "开盘价": 38.9,
        "最高价": 39.31,
        "最低价": 38.79,
        "昨收价": 39.01,
        "总市值": 2529654287.0,
        "市盈率": 26.09,
        "成交量": 223774.0,
        "成交额": 8764262.0,
        "振幅": 1.33,
        "换手率": 0.35,
        "symbol": "106.EVTC"
    },
    {
        "序号": 4436,
        "name": "新思科技",
        "最新价": 535.93,
        "涨跌额": 1.78,
        "涨跌幅": 0.33,
        "开盘价": 531.63,
        "最高价": 539.69,
        "最低价": 531.63,
        "昨收价": 534.15,
        "总市值": 81489764290.0,
        "市盈率": 66.26,
        "成交量": 569163.0,
        "成交额": 305215696.0,
        "振幅": 1.51,
        "换手率": 0.37,
        "symbol": "105.SNPS"
    },
    {
        "序号": 4437,
        "name": "卡莱尔伙伴",
        "最新价": 295.1,
        "涨跌额": 0.98,
        "涨跌幅": 0.33,
        "开盘价": 295.0,
        "最高价": 299.26,
        "最低价": 293.27,
        "昨收价": 294.12,
        "总市值": 14366472520.0,
        "市盈率": 19.52,
        "成交量": 283894.0,
        "成交额": 83761540.0,
        "振幅": 2.04,
        "换手率": 0.58,
        "symbol": "106.CSL"
    },
    {
        "序号": 4438,
        "name": "Invesco Bloomberg MVP Multi-fac",
        "最新价": 39.15,
        "涨跌额": 0.13,
        "涨跌幅": 0.33,
        "开盘价": 39.15,
        "最高价": 39.15,
        "最低价": 39.15,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 78.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BMVP"
    },
    {
        "序号": 4439,
        "name": "住宅房产信托ETF-iShares",
        "最新价": 69.27,
        "涨跌额": 0.23,
        "涨跌幅": 0.33,
        "开盘价": 68.74,
        "最高价": 69.28,
        "最低价": 68.55,
        "昨收价": 69.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 55187.0,
        "成交额": 3812005.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.REZ"
    },
    {
        "序号": 4440,
        "name": "Employers Holdings Inc",
        "最新价": 39.21,
        "涨跌额": 0.13,
        "涨跌幅": 0.33,
        "开盘价": 39.08,
        "最高价": 39.24,
        "最低价": 38.78,
        "昨收价": 39.08,
        "总市值": 1008507745.0,
        "市盈率": 8.43,
        "成交量": 94388.0,
        "成交额": 3694965.0,
        "振幅": 1.18,
        "换手率": 0.37,
        "symbol": "106.EIG"
    },
    {
        "序号": 4441,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.18,
        "涨跌额": 0.09,
        "涨跌幅": 0.33,
        "开盘价": 27.1,
        "最高价": 27.22,
        "最低价": 27.09,
        "昨收价": 27.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 7044.0,
        "成交额": 191251.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.JUNT"
    },
    {
        "序号": 4442,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 30.22,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 30.22,
        "最高价": 30.22,
        "最低价": 30.22,
        "昨收价": 30.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 136.0,
        "成交额": 4109.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XDAP"
    },
    {
        "序号": 4443,
        "name": "Virtus Convertible & Income Fun",
        "最新价": 21.16,
        "涨跌额": 0.07,
        "涨跌幅": 0.33,
        "开盘价": 21.18,
        "最高价": 21.24,
        "最低价": 20.9,
        "昨收价": 21.09,
        "总市值": 84828980.0,
        "市盈率": null,
        "成交量": 3796.0,
        "成交额": 80250.0,
        "振幅": 1.61,
        "换手率": 0.09,
        "symbol": "106.NCV_A"
    },
    {
        "序号": 4444,
        "name": "GraniteShares HIPS US High Inco",
        "最新价": 12.11,
        "涨跌额": 0.04,
        "涨跌幅": 0.33,
        "开盘价": 12.07,
        "最高价": 12.14,
        "最低价": 12.07,
        "昨收价": 12.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 22518.0,
        "成交额": 272649.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.HIPS"
    },
    {
        "序号": 4445,
        "name": "Stock Yards Bancorp Inc",
        "最新价": 48.47,
        "涨跌额": 0.16,
        "涨跌幅": 0.33,
        "开盘价": 48.12,
        "最高价": 49.11,
        "最低价": 47.35,
        "昨收价": 48.31,
        "总市值": 1421232251.0,
        "市盈率": 12.51,
        "成交量": 64066.0,
        "成交额": 3101960.0,
        "振幅": 3.64,
        "换手率": 0.22,
        "symbol": "105.SYBT"
    },
    {
        "序号": 4446,
        "name": "YieldMax AMD Option Income Stra",
        "最新价": 21.21,
        "涨跌额": 0.07,
        "涨跌幅": 0.33,
        "开盘价": 21.18,
        "最高价": 21.26,
        "最低价": 21.12,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 85769.0,
        "成交额": 1818150.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.AMDY"
    },
    {
        "序号": 4447,
        "name": "SPDR MSCI ACWI Climate Paris Al",
        "最新价": 30.32,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 30.15,
        "最高价": 30.32,
        "最低价": 30.15,
        "昨收价": 30.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 3742.0,
        "成交额": 113228.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.NZAC"
    },
    {
        "序号": 4448,
        "name": "LeaderShares Equity Skew ETF",
        "最新价": 30.35,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 30.22,
        "最高价": 30.35,
        "最低价": 30.2,
        "昨收价": 30.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 46065.0,
        "成交额": 1392754.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.SQEW"
    },
    {
        "序号": 4449,
        "name": "Mohr Sector Nav ETF",
        "最新价": 27.33,
        "涨跌额": 0.09,
        "涨跌幅": 0.33,
        "开盘价": 27.25,
        "最高价": 27.37,
        "最低价": 27.25,
        "昨收价": 27.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 34089.0,
        "成交额": 932273.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.SNAV"
    },
    {
        "序号": 4450,
        "name": "AB US Low Volatility Equity ETF",
        "最新价": 57.81,
        "涨跌额": 0.19,
        "涨跌幅": 0.33,
        "开盘价": 57.77,
        "最高价": 57.81,
        "最低价": 57.77,
        "昨收价": 57.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 1869.0,
        "成交额": 108041.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.LOWV"
    },
    {
        "序号": 4451,
        "name": "Vanguard S&P Mid-Cap 400 Growth",
        "最新价": 94.51,
        "涨跌额": 0.31,
        "涨跌幅": 0.33,
        "开盘价": 94.09,
        "最高价": 94.95,
        "最低价": 94.09,
        "昨收价": 94.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 17185.0,
        "成交额": 1623427.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.IVOG"
    },
    {
        "序号": 4452,
        "name": "布林克国际",
        "最新价": 39.77,
        "涨跌额": 0.13,
        "涨跌幅": 0.33,
        "开盘价": 39.55,
        "最高价": 40.0,
        "最低价": 39.14,
        "昨收价": 39.64,
        "总市值": 1757957406.0,
        "市盈率": 12.56,
        "成交量": 885861.0,
        "成交额": 35205108.0,
        "振幅": 2.17,
        "换手率": 2.0,
        "symbol": "106.EAT"
    },
    {
        "序号": 4453,
        "name": "Listed Funds Trust TrueShares S",
        "最新价": 33.7,
        "涨跌额": 0.11,
        "涨跌幅": 0.33,
        "开盘价": 33.7,
        "最高价": 33.7,
        "最低价": 33.7,
        "昨收价": 33.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 98.0,
        "成交额": 3302.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SEPZ"
    },
    {
        "序号": 4454,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 30.64,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 30.61,
        "最高价": 30.64,
        "最低价": 30.56,
        "昨收价": 30.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 7929.0,
        "成交额": 242771.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.USEP"
    },
    {
        "序号": 4455,
        "name": "长岛第一国民银行",
        "最新价": 12.27,
        "涨跌额": 0.04,
        "涨跌幅": 0.33,
        "开盘价": 12.27,
        "最高价": 12.41,
        "最低价": 12.16,
        "昨收价": 12.23,
        "总市值": 277160036.0,
        "市盈率": 9.21,
        "成交量": 49365.0,
        "成交额": 605664.0,
        "振幅": 2.04,
        "换手率": 0.22,
        "symbol": "105.FLIC"
    },
    {
        "序号": 4456,
        "name": "Metalla Royalty & Streaming Ltd",
        "最新价": 3.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.33,
        "开盘价": 3.03,
        "最高价": 3.12,
        "最低价": 3.02,
        "昨收价": 3.06,
        "总市值": 162217348.0,
        "市盈率": -18.52,
        "成交量": 199269.0,
        "成交额": 608007.0,
        "振幅": 3.27,
        "换手率": 0.38,
        "symbol": "107.MTA"
    },
    {
        "序号": 4457,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 36.84,
        "涨跌额": 0.12,
        "涨跌幅": 0.33,
        "开盘价": 36.73,
        "最高价": 36.84,
        "最低价": 36.73,
        "昨收价": 36.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 2281.0,
        "成交额": 83870.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.DFEB"
    },
    {
        "序号": 4458,
        "name": "Hovnanian Enterprises Inc Serie",
        "最新价": 18.48,
        "涨跌额": 0.06,
        "涨跌幅": 0.33,
        "开盘价": 18.5,
        "最高价": 19.05,
        "最低价": 18.48,
        "昨收价": 18.42,
        "总市值": 103488.0,
        "市盈率": null,
        "成交量": 19311.0,
        "成交额": 357948.0,
        "振幅": 3.09,
        "换手率": 344.84,
        "symbol": "105.HOVNP"
    },
    {
        "序号": 4459,
        "name": "Engine No 1 Transform Supply Ch",
        "最新价": 52.39,
        "涨跌额": 0.17,
        "涨跌幅": 0.33,
        "开盘价": 52.29,
        "最高价": 52.43,
        "最低价": 52.29,
        "昨收价": 52.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 5954.0,
        "成交额": 311759.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.SUPP"
    },
    {
        "序号": 4460,
        "name": "WisdomTree U.S. Quality Dividen",
        "最新价": 67.84,
        "涨跌额": 0.22,
        "涨跌幅": 0.33,
        "开盘价": 67.61,
        "最高价": 67.96,
        "最低价": 67.49,
        "昨收价": 67.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 464177.0,
        "成交额": 31457057.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.DGRW"
    },
    {
        "序号": 4461,
        "name": "Dimensional International High ",
        "最新价": 24.67,
        "涨跌额": 0.08,
        "涨跌幅": 0.33,
        "开盘价": 24.54,
        "最高价": 24.71,
        "最低价": 24.54,
        "昨收价": 24.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 369097.0,
        "成交额": 9095027.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.DIHP"
    },
    {
        "序号": 4462,
        "name": "全球ETF-iShares MSCI",
        "最新价": 98.71,
        "涨跌额": 0.32,
        "涨跌幅": 0.33,
        "开盘价": 98.13,
        "最高价": 98.82,
        "最低价": 98.13,
        "昨收价": 98.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 2459771.0,
        "成交额": 242484022.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.ACWI"
    },
    {
        "序号": 4463,
        "name": "Peoples Bancorp Inc",
        "最新价": 30.85,
        "涨跌额": 0.1,
        "涨跌幅": 0.33,
        "开盘价": 30.92,
        "最高价": 30.98,
        "最低价": 30.26,
        "昨收价": 30.75,
        "总市值": 1090987113.0,
        "市盈率": 10.25,
        "成交量": 181670.0,
        "成交额": 5599981.0,
        "振幅": 2.34,
        "换手率": 0.51,
        "symbol": "105.PEBO"
    },
    {
        "序号": 4464,
        "name": "Franklin Disruptive Commerce ET",
        "最新价": 27.78,
        "涨跌额": 0.09,
        "涨跌幅": 0.33,
        "开盘价": 27.78,
        "最高价": 27.78,
        "最低价": 27.78,
        "昨收价": 27.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 123.0,
        "成交额": 3416.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BUYZ"
    },
    {
        "序号": 4465,
        "name": "First Trust RiverFront Dynamic ",
        "最新价": 58.66,
        "涨跌额": 0.19,
        "涨跌幅": 0.32,
        "开盘价": 58.43,
        "最高价": 58.66,
        "最低价": 58.43,
        "昨收价": 58.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 3534.0,
        "成交额": 206903.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "105.RFDI"
    },
    {
        "序号": 4466,
        "name": "Bahl & Gaynor Income Growth ETF",
        "最新价": 24.7,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 24.7,
        "最高价": 24.76,
        "最低价": 24.7,
        "昨收价": 24.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 3513.0,
        "成交额": 86873.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.BGIG"
    },
    {
        "序号": 4467,
        "name": "Bar Harbor Bankshares",
        "最新价": 27.8,
        "涨跌额": 0.09,
        "涨跌幅": 0.32,
        "开盘价": 27.49,
        "最高价": 27.9,
        "最低价": 27.48,
        "昨收价": 27.71,
        "总市值": 421335994.0,
        "市盈率": 8.89,
        "成交量": 26504.0,
        "成交额": 735472.0,
        "振幅": 1.52,
        "换手率": 0.17,
        "symbol": "107.BHB"
    },
    {
        "序号": 4468,
        "name": "iShares Morningstar Value ETF",
        "最新价": 67.98,
        "涨跌额": 0.22,
        "涨跌幅": 0.32,
        "开盘价": 67.67,
        "最高价": 68.09,
        "最低价": 67.67,
        "昨收价": 67.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 19293.0,
        "成交额": 1310373.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.ILCV"
    },
    {
        "序号": 4469,
        "name": "iShares ESG Aware MSCI USA Valu",
        "最新价": 24.73,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 24.69,
        "最高价": 24.73,
        "最低价": 24.68,
        "昨收价": 24.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 1054.0,
        "成交额": 26018.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.EVUS"
    },
    {
        "序号": 4470,
        "name": "Pacer Swan SOS Moderate (Octobe",
        "最新价": 24.77,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 24.64,
        "最高价": 24.78,
        "最低价": 24.64,
        "昨收价": 24.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 746.0,
        "成交额": 18445.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.PSMO"
    },
    {
        "序号": 4471,
        "name": "Star Equity Holdings Inc Series",
        "最新价": 9.3,
        "涨跌额": 0.03,
        "涨跌幅": 0.32,
        "开盘价": 9.25,
        "最高价": 9.3,
        "最低价": 9.25,
        "昨收价": 9.27,
        "总市值": 17815424.0,
        "市盈率": null,
        "成交量": 1655.0,
        "成交额": 15320.0,
        "振幅": 0.54,
        "换手率": 0.09,
        "symbol": "105.STRRP"
    },
    {
        "序号": 4472,
        "name": "Invesco DWA Basic Materials Mom",
        "最新价": 80.6,
        "涨跌额": 0.26,
        "涨跌幅": 0.32,
        "开盘价": 81.02,
        "最高价": 81.02,
        "最低价": 80.46,
        "昨收价": 80.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 21893.0,
        "成交额": 1764520.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.PYZ"
    },
    {
        "序号": 4473,
        "name": "ProShares Decline of the Retail",
        "最新价": 15.51,
        "涨跌额": 0.05,
        "涨跌幅": 0.32,
        "开盘价": 15.47,
        "最高价": 15.51,
        "最低价": 15.36,
        "昨收价": 15.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 3688.0,
        "成交额": 56887.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.EMTY"
    },
    {
        "序号": 4474,
        "name": "ALPS O'Shares U.S. Small-Cap Qu",
        "最新价": 37.23,
        "涨跌额": 0.12,
        "涨跌幅": 0.32,
        "开盘价": 37.17,
        "最高价": 37.32,
        "最低价": 37.09,
        "昨收价": 37.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 51982.0,
        "成交额": 1934164.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.OUSM"
    },
    {
        "序号": 4475,
        "name": "Miller Industries Inc",
        "最新价": 40.42,
        "涨跌额": 0.13,
        "涨跌幅": 0.32,
        "开盘价": 40.11,
        "最高价": 40.53,
        "最低价": 39.72,
        "昨收价": 40.29,
        "总市值": 462632769.0,
        "市盈率": 9.09,
        "成交量": 54156.0,
        "成交额": 2178592.0,
        "振幅": 2.01,
        "换手率": 0.47,
        "symbol": "106.MLR"
    },
    {
        "序号": 4476,
        "name": "Eaton Vance New York Municipal ",
        "最新价": 9.33,
        "涨跌额": 0.03,
        "涨跌幅": 0.32,
        "开盘价": 9.29,
        "最高价": 9.33,
        "最低价": 9.25,
        "昨收价": 9.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 62455.0,
        "成交额": 580960.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.ENX"
    },
    {
        "序号": 4477,
        "name": "Avantis All Equity Markets ETF",
        "最新价": 62.2,
        "涨跌额": 0.2,
        "涨跌幅": 0.32,
        "开盘价": 61.99,
        "最高价": 62.31,
        "最低价": 61.92,
        "昨收价": 62.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 16902.0,
        "成交额": 1050000.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.AVGE"
    },
    {
        "序号": 4478,
        "name": "墨西哥北方中心机场",
        "最新价": 71.55,
        "涨跌额": 0.23,
        "涨跌幅": 0.32,
        "开盘价": 70.88,
        "最高价": 72.38,
        "最低价": 70.88,
        "昨收价": 71.32,
        "总市值": 3453802795.0,
        "市盈率": 17.03,
        "成交量": 54353.0,
        "成交额": 3880590.0,
        "振幅": 2.1,
        "换手率": 0.11,
        "symbol": "105.OMAB"
    },
    {
        "序号": 4479,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 18.71,
        "涨跌额": 0.06,
        "涨跌幅": 0.32,
        "开盘价": 18.69,
        "最高价": 18.98,
        "最低价": 18.51,
        "昨收价": 18.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 48251.0,
        "成交额": 907756.0,
        "振幅": 2.52,
        "换手率": null,
        "symbol": "105.RILYN"
    },
    {
        "序号": 4480,
        "name": "NGL Energy Partners LP Class C ",
        "最新价": 24.95,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 24.9,
        "最高价": 25.0,
        "最低价": 24.85,
        "昨收价": 24.87,
        "总市值": 44910000.0,
        "市盈率": null,
        "成交量": 13406.0,
        "成交额": 334108.0,
        "振幅": 0.6,
        "换手率": 0.74,
        "symbol": "106.NGL_C"
    },
    {
        "序号": 4481,
        "name": "RH Tactical Rotation ETF",
        "最新价": 12.49,
        "涨跌额": 0.04,
        "涨跌幅": 0.32,
        "开盘价": 12.5,
        "最高价": 12.5,
        "最低价": 12.49,
        "昨收价": 12.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 1172.0,
        "成交额": 14645.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.RHRX"
    },
    {
        "序号": 4482,
        "name": "Eaton Vance Limited Duration In",
        "最新价": 9.37,
        "涨跌额": 0.03,
        "涨跌幅": 0.32,
        "开盘价": 9.31,
        "最高价": 9.42,
        "最低价": 9.29,
        "昨收价": 9.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 300667.0,
        "成交额": 2813279.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "107.EVV"
    },
    {
        "序号": 4483,
        "name": "Invesco S&P SmallCap High Divid",
        "最新价": 15.62,
        "涨跌额": 0.05,
        "涨跌幅": 0.32,
        "开盘价": 15.54,
        "最高价": 15.69,
        "最低价": 15.54,
        "昨收价": 15.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 17650.0,
        "成交额": 275206.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.XSHD"
    },
    {
        "序号": 4484,
        "name": "天宝导航",
        "最新价": 46.9,
        "涨跌额": 0.15,
        "涨跌幅": 0.32,
        "开盘价": 46.57,
        "最高价": 47.48,
        "最低价": 46.28,
        "昨收价": 46.75,
        "总市值": 11667198799.0,
        "市盈率": 34.94,
        "成交量": 924986.0,
        "成交额": 43483204.0,
        "振幅": 2.57,
        "换手率": 0.37,
        "symbol": "105.TRMB"
    },
    {
        "序号": 4485,
        "name": "美国铝业",
        "最新价": 25.02,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 25.04,
        "最高价": 25.73,
        "最低价": 24.87,
        "昨收价": 24.94,
        "总市值": 4465367138.0,
        "市盈率": -4.98,
        "成交量": 5133177.0,
        "成交额": 128913504.0,
        "振幅": 3.45,
        "换手率": 2.88,
        "symbol": "106.AA"
    },
    {
        "序号": 4486,
        "name": "赛莱默",
        "最新价": 106.38,
        "涨跌额": 0.34,
        "涨跌幅": 0.32,
        "开盘价": 105.87,
        "最高价": 106.97,
        "最低价": 105.4,
        "昨收价": 106.04,
        "总市值": 25645854236.0,
        "市盈率": 52.13,
        "成交量": 2103836.0,
        "成交额": 223893377.0,
        "振幅": 1.48,
        "换手率": 0.87,
        "symbol": "106.XYL"
    },
    {
        "序号": 4487,
        "name": "布鲁克菲尔德公共建设",
        "最新价": 28.16,
        "涨跌额": 0.09,
        "涨跌幅": 0.32,
        "开盘价": 28.18,
        "最高价": 28.58,
        "最低价": 27.93,
        "昨收价": 28.07,
        "总市值": 13013621097.0,
        "市盈率": 29.92,
        "成交量": 488123.0,
        "成交额": 13786255.0,
        "振幅": 2.32,
        "换手率": 0.11,
        "symbol": "106.BIP"
    },
    {
        "序号": 4488,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 37.56,
        "涨跌额": 0.12,
        "涨跌幅": 0.32,
        "开盘价": 37.39,
        "最高价": 37.59,
        "最低价": 37.39,
        "昨收价": 37.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 23005.0,
        "成交额": 863740.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.BOCT"
    },
    {
        "序号": 4489,
        "name": "VanEck Oil Refiners ETF",
        "最新价": 34.44,
        "涨跌额": 0.11,
        "涨跌幅": 0.32,
        "开盘价": 34.39,
        "最高价": 34.52,
        "最低价": 34.24,
        "昨收价": 34.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 6556.0,
        "成交额": 225245.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.CRAK"
    },
    {
        "序号": 4490,
        "name": "THOR Low Volatility ETF",
        "最新价": 25.07,
        "涨跌额": 0.08,
        "涨跌幅": 0.32,
        "开盘价": 25.0,
        "最高价": 25.08,
        "最低价": 24.96,
        "昨收价": 24.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 5509.0,
        "成交额": 137860.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "106.THLV"
    },
    {
        "序号": 4491,
        "name": "Office Properties Income Trust",
        "最新价": 6.27,
        "涨跌额": 0.02,
        "涨跌幅": 0.32,
        "开盘价": 6.23,
        "最高价": 6.33,
        "最低价": 6.09,
        "昨收价": 6.25,
        "总市值": 305699405.0,
        "市盈率": -11.81,
        "成交量": 638056.0,
        "成交额": 3987060.0,
        "振幅": 3.84,
        "换手率": 1.31,
        "symbol": "105.OPI"
    },
    {
        "序号": 4492,
        "name": "WisdomTree Interest Rate Hedged",
        "最新价": 21.95,
        "涨跌额": 0.07,
        "涨跌幅": 0.32,
        "开盘价": 21.89,
        "最高价": 21.95,
        "最低价": 21.82,
        "昨收价": 21.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 25838.0,
        "成交额": 565406.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "105.AGZD"
    },
    {
        "序号": 4493,
        "name": "美国电信ETF-iShares",
        "最新价": 21.96,
        "涨跌额": 0.07,
        "涨跌幅": 0.32,
        "开盘价": 21.81,
        "最高价": 22.04,
        "最低价": 21.81,
        "昨收价": 21.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 220930.0,
        "成交额": 4852200.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.IYZ"
    },
    {
        "序号": 4494,
        "name": "Franklin FTSE Mexico ETF",
        "最新价": 31.41,
        "涨跌额": 0.1,
        "涨跌幅": 0.32,
        "开盘价": 31.34,
        "最高价": 31.45,
        "最低价": 31.24,
        "昨收价": 31.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 8150.0,
        "成交额": 255579.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.FLMX"
    },
    {
        "序号": 4495,
        "name": "First Trust Indxx Global Natura",
        "最新价": 12.57,
        "涨跌额": 0.04,
        "涨跌幅": 0.32,
        "开盘价": 12.45,
        "最高价": 12.64,
        "最低价": 12.45,
        "昨收价": 12.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 114308.0,
        "成交额": 1435515.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "105.FTRI"
    },
    {
        "序号": 4496,
        "name": "罗斯百货",
        "最新价": 132.08,
        "涨跌额": 0.42,
        "涨跌幅": 0.32,
        "开盘价": 131.02,
        "最高价": 132.3,
        "最低价": 130.4,
        "昨收价": 131.66,
        "总市值": 44466880413.0,
        "市盈率": 25.98,
        "成交量": 1443057.0,
        "成交额": 190416200.0,
        "振幅": 1.44,
        "换手率": 0.43,
        "symbol": "105.ROST"
    },
    {
        "序号": 4497,
        "name": "First Trust Dow 30 Equal Weight",
        "最新价": 31.45,
        "涨跌额": 0.1,
        "涨跌幅": 0.32,
        "开盘价": 31.33,
        "最高价": 31.5,
        "最低价": 31.33,
        "昨收价": 31.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 22808.0,
        "成交额": 716017.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.EDOW"
    },
    {
        "序号": 4498,
        "name": "Global X S&P 500 Risk Managed I",
        "最新价": 18.91,
        "涨跌额": 0.06,
        "涨跌幅": 0.32,
        "开盘价": 18.84,
        "最高价": 18.96,
        "最低价": 18.84,
        "昨收价": 18.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 51766.0,
        "成交额": 978331.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.XRMI"
    },
    {
        "序号": 4499,
        "name": "WisdomTree International High D",
        "最新价": 37.82,
        "涨跌额": 0.12,
        "涨跌幅": 0.32,
        "开盘价": 37.65,
        "最高价": 37.84,
        "最低价": 37.64,
        "昨收价": 37.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 28201.0,
        "成交额": 1064436.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.DTH"
    },
    {
        "序号": 4500,
        "name": "Toews Agility Shares Managed Ri",
        "最新价": 28.37,
        "涨跌额": 0.09,
        "涨跌幅": 0.32,
        "开盘价": 28.38,
        "最高价": 28.39,
        "最低价": 28.28,
        "昨收价": 28.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 26780.0,
        "成交额": 758624.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.MRSK"
    },
    {
        "序号": 4501,
        "name": "通用动力",
        "最新价": 252.29,
        "涨跌额": 0.8,
        "涨跌幅": 0.32,
        "开盘价": 251.75,
        "最高价": 253.19,
        "最低价": 251.75,
        "昨收价": 251.49,
        "总市值": 68849148809.0,
        "市盈率": 20.85,
        "成交量": 968475.0,
        "成交额": 244385518.0,
        "振幅": 0.57,
        "换手率": 0.35,
        "symbol": "106.GD"
    },
    {
        "序号": 4502,
        "name": "太平洋除日本ETF-iShares MSCI",
        "最新价": 41.04,
        "涨跌额": 0.13,
        "涨跌幅": 0.32,
        "开盘价": 40.85,
        "最高价": 41.17,
        "最低价": 40.83,
        "昨收价": 40.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 379908.0,
        "成交额": 15570556.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.EPP"
    },
    {
        "序号": 4503,
        "name": "Goldman Sachs ActiveBeta Intern",
        "最新价": 31.57,
        "涨跌额": 0.1,
        "涨跌幅": 0.32,
        "开盘价": 31.4,
        "最高价": 31.61,
        "最低价": 31.39,
        "昨收价": 31.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 257537.0,
        "成交额": 8118256.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.GSIE"
    },
    {
        "序号": 4504,
        "name": "VictoryShares WestEnd U.S. Sect",
        "最新价": 31.61,
        "涨跌额": 0.1,
        "涨跌幅": 0.32,
        "开盘价": 31.47,
        "最高价": 31.64,
        "最低价": 31.47,
        "昨收价": 31.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 12599.0,
        "成交额": 398051.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "105.MODL"
    },
    {
        "序号": 4505,
        "name": "Vanguard Materials ETF",
        "最新价": 180.26,
        "涨跌额": 0.57,
        "涨跌幅": 0.32,
        "开盘价": 179.37,
        "最高价": 181.11,
        "最低价": 179.37,
        "昨收价": 179.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 36939.0,
        "成交额": 6656895.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.VAW"
    },
    {
        "序号": 4506,
        "name": "Treace Medical Concepts Inc",
        "最新价": 9.5,
        "涨跌额": 0.03,
        "涨跌幅": 0.32,
        "开盘价": 9.43,
        "最高价": 9.88,
        "最低价": 9.22,
        "昨收价": 9.47,
        "总市值": 585920680.0,
        "市盈率": -12.29,
        "成交量": 622953.0,
        "成交额": 5973396.0,
        "振幅": 6.97,
        "换手率": 1.01,
        "symbol": "105.TMCI"
    },
    {
        "序号": 4507,
        "name": "Granite Real Estate Investment ",
        "最新价": 53.93,
        "涨跌额": 0.17,
        "涨跌幅": 0.32,
        "开盘价": 54.0,
        "最高价": 54.27,
        "最低价": 53.93,
        "昨收价": 53.76,
        "总市值": 3437704644.0,
        "市盈率": -255.64,
        "成交量": 4860.0,
        "成交额": 262579.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "106.GRP_U"
    },
    {
        "序号": 4508,
        "name": "WisdomTree Dynamic Currency Hed",
        "最新价": 31.74,
        "涨跌额": 0.1,
        "涨跌幅": 0.32,
        "开盘价": 31.66,
        "最高价": 31.74,
        "最低价": 31.59,
        "昨收价": 31.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 30209.0,
        "成交额": 957160.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.DDLS"
    },
    {
        "序号": 4509,
        "name": "格雷厄姆控股",
        "最新价": 651.65,
        "涨跌额": 2.05,
        "涨跌幅": 0.32,
        "开盘价": 642.7,
        "最高价": 654.28,
        "最低价": 642.7,
        "昨收价": 649.6,
        "总市值": 2961316554.0,
        "市盈率": 18.72,
        "成交量": 17928.0,
        "成交额": 11653168.0,
        "振幅": 1.78,
        "换手率": 0.39,
        "symbol": "106.GHC"
    },
    {
        "序号": 4510,
        "name": "Cabana Target Drawdown 13 ETF",
        "最新价": 22.26,
        "涨跌额": 0.07,
        "涨跌幅": 0.32,
        "开盘价": 22.22,
        "最高价": 22.27,
        "最低价": 22.16,
        "昨收价": 22.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 12416.0,
        "成交额": 276247.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "105.TDSD"
    },
    {
        "序号": 4511,
        "name": "iShares Currency Hedged MSCI Ja",
        "最新价": 35.03,
        "涨跌额": 0.11,
        "涨跌幅": 0.32,
        "开盘价": 34.67,
        "最高价": 35.03,
        "最低价": 34.67,
        "昨收价": 34.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 94066.0,
        "成交额": 3276989.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.HEWJ"
    },
    {
        "序号": 4512,
        "name": "Invesco NASDAQ Next Gen 100 ETF",
        "最新价": 25.48,
        "涨跌额": 0.08,
        "涨跌幅": 0.31,
        "开盘价": 25.38,
        "最高价": 25.57,
        "最低价": 25.37,
        "昨收价": 25.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 98762.0,
        "成交额": 2514562.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.QQQJ"
    },
    {
        "序号": 4513,
        "name": "NEOS S&P 500 High Income ETF",
        "最新价": 47.82,
        "涨跌额": 0.15,
        "涨跌幅": 0.31,
        "开盘价": 47.64,
        "最高价": 47.84,
        "最低价": 47.61,
        "昨收价": 47.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 168912.0,
        "成交额": 8065185.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.SPYI"
    },
    {
        "序号": 4514,
        "name": "微芯科技",
        "最新价": 86.08,
        "涨跌额": 0.27,
        "涨跌幅": 0.31,
        "开盘价": 85.46,
        "最高价": 86.75,
        "最低价": 85.4,
        "昨收价": 85.81,
        "总市值": 46573179252.0,
        "市盈率": 18.5,
        "成交量": 3838699.0,
        "成交额": 330750224.0,
        "振幅": 1.57,
        "换手率": 0.71,
        "symbol": "105.MCHP"
    },
    {
        "序号": 4515,
        "name": "Materion Corp",
        "最新价": 114.93,
        "涨跌额": 0.36,
        "涨跌幅": 0.31,
        "开盘价": 114.26,
        "最高价": 115.89,
        "最低价": 113.88,
        "昨收价": 114.57,
        "总市值": 2372430572.0,
        "市盈率": 22.6,
        "成交量": 56414.0,
        "成交额": 6477326.0,
        "振幅": 1.75,
        "换手率": 0.27,
        "symbol": "106.MTRN"
    },
    {
        "序号": 4516,
        "name": "Summit Hotel Properties Inc",
        "最新价": 6.39,
        "涨跌额": 0.02,
        "涨跌幅": 0.31,
        "开盘价": 6.33,
        "最高价": 6.39,
        "最低价": 6.29,
        "昨收价": 6.37,
        "总市值": 687427912.0,
        "市盈率": -140.43,
        "成交量": 379511.0,
        "成交额": 2415670.0,
        "振幅": 1.57,
        "换手率": 0.35,
        "symbol": "106.INN"
    },
    {
        "序号": 4517,
        "name": "Madison Dividend Value ETF",
        "最新价": 19.19,
        "涨跌额": 0.06,
        "涨跌幅": 0.31,
        "开盘价": 19.18,
        "最高价": 19.19,
        "最低价": 19.18,
        "昨收价": 19.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 1937.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.DIVL"
    },
    {
        "序号": 4518,
        "name": "莱克兰金融",
        "最新价": 60.86,
        "涨跌额": 0.19,
        "涨跌幅": 0.31,
        "开盘价": 60.53,
        "最高价": 61.67,
        "最低价": 60.44,
        "昨收价": 60.67,
        "总市值": 1547774723.0,
        "市盈率": 17.17,
        "成交量": 89354.0,
        "成交额": 5447407.0,
        "振幅": 2.03,
        "换手率": 0.35,
        "symbol": "105.LKFN"
    },
    {
        "序号": 4519,
        "name": "标普500等权ETF-Guggenheim",
        "最新价": 150.57,
        "涨跌额": 0.47,
        "涨跌幅": 0.31,
        "开盘价": 149.97,
        "最高价": 150.95,
        "最低价": 149.89,
        "昨收价": 150.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 5181764.0,
        "成交额": 779692352.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.RSP"
    },
    {
        "序号": 4520,
        "name": "工业ETF-SPDR",
        "最新价": 108.96,
        "涨跌额": 0.34,
        "涨跌幅": 0.31,
        "开盘价": 108.66,
        "最高价": 109.49,
        "最低价": 108.62,
        "昨收价": 108.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 7150079.0,
        "成交额": 779585312.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.XLI"
    },
    {
        "序号": 4521,
        "name": "AXS Change Finance ESG ETF",
        "最新价": 32.05,
        "涨跌额": 0.1,
        "涨跌幅": 0.31,
        "开盘价": 32.0,
        "最高价": 32.05,
        "最低价": 31.93,
        "昨收价": 31.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 3439.0,
        "成交额": 109956.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.CHGX"
    },
    {
        "序号": 4522,
        "name": "Vir Biotechnology Inc",
        "最新价": 9.62,
        "涨跌额": 0.03,
        "涨跌幅": 0.31,
        "开盘价": 9.53,
        "最高价": 9.84,
        "最低价": 9.38,
        "昨收价": 9.59,
        "总市值": 1294094762.0,
        "市盈率": -2.15,
        "成交量": 928722.0,
        "成交额": 8978515.0,
        "振幅": 4.8,
        "换手率": 0.69,
        "symbol": "105.VIR"
    },
    {
        "序号": 4523,
        "name": "Invesco RAFI Strategic US ETF",
        "最新价": 41.77,
        "涨跌额": 0.13,
        "涨跌幅": 0.31,
        "开盘价": 41.52,
        "最高价": 41.82,
        "最低价": 41.52,
        "昨收价": 41.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 39543.0,
        "成交额": 1648265.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "105.IUS"
    },
    {
        "序号": 4524,
        "name": "PotlatchDeltic Corp",
        "最新价": 44.99,
        "涨跌额": 0.14,
        "涨跌幅": 0.31,
        "开盘价": 44.7,
        "最高价": 45.22,
        "最低价": 44.49,
        "昨收价": 44.85,
        "总市值": 3570586360.0,
        "市盈率": 54.03,
        "成交量": 376631.0,
        "成交额": 16905357.0,
        "振幅": 1.63,
        "换手率": 0.47,
        "symbol": "105.PCH"
    },
    {
        "序号": 4525,
        "name": "iShares MSCI Emerging Markets A",
        "最新价": 64.28,
        "涨跌额": 0.2,
        "涨跌幅": 0.31,
        "开盘价": 64.04,
        "最高价": 64.49,
        "最低价": 63.74,
        "昨收价": 64.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 205334.0,
        "成交额": 13171141.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "105.EEMA"
    },
    {
        "序号": 4526,
        "name": "Alta Equipment Group Series A P",
        "最新价": 25.74,
        "涨跌额": 0.08,
        "涨跌幅": 0.31,
        "开盘价": 25.75,
        "最高价": 25.75,
        "最低价": 25.74,
        "昨收价": 25.66,
        "总市值": 30888.0,
        "市盈率": null,
        "成交量": 1427.0,
        "成交额": 36734.0,
        "振幅": 0.04,
        "换手率": 118.92,
        "symbol": "106.ALTG_A"
    },
    {
        "序号": 4527,
        "name": "Cabana Target Leading Sector Ag",
        "最新价": 19.31,
        "涨跌额": 0.06,
        "涨跌幅": 0.31,
        "开盘价": 19.28,
        "最高价": 19.31,
        "最低价": 19.21,
        "昨收价": 19.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 19377.0,
        "成交额": 373497.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.CLSA"
    },
    {
        "序号": 4528,
        "name": "Inspire Faithward Mid Cap Momen",
        "最新价": 25.75,
        "涨跌额": 0.08,
        "涨跌幅": 0.31,
        "开盘价": 25.74,
        "最高价": 25.83,
        "最低价": 25.7,
        "昨收价": 25.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 3398.0,
        "成交额": 87611.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.GLRY"
    },
    {
        "序号": 4529,
        "name": "WisdomTree Target Range Fund",
        "最新价": 22.56,
        "涨跌额": 0.07,
        "涨跌幅": 0.31,
        "开盘价": 22.56,
        "最高价": 22.6,
        "最低价": 22.49,
        "昨收价": 22.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 8666.0,
        "成交额": 195639.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "105.GTR"
    },
    {
        "序号": 4530,
        "name": "Frontline plc",
        "最新价": 19.34,
        "涨跌额": 0.06,
        "涨跌幅": 0.31,
        "开盘价": 19.35,
        "最高价": 19.43,
        "最低价": 19.14,
        "昨收价": 19.28,
        "总市值": 4305526673.0,
        "市盈率": 5.54,
        "成交量": 1379765.0,
        "成交额": 26623991.0,
        "振幅": 1.5,
        "换手率": 0.62,
        "symbol": "106.FRO"
    },
    {
        "序号": 4531,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 29.04,
        "涨跌额": 0.09,
        "涨跌幅": 0.31,
        "开盘价": 29.0,
        "最高价": 29.04,
        "最低价": 28.94,
        "昨收价": 28.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 3690.0,
        "成交额": 106996.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.UFEB"
    },
    {
        "序号": 4532,
        "name": "Opus Small Cap Value ETF",
        "最新价": 32.27,
        "涨跌额": 0.1,
        "涨跌幅": 0.31,
        "开盘价": 32.08,
        "最高价": 32.34,
        "最低价": 32.05,
        "昨收价": 32.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 23404.0,
        "成交额": 755117.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.OSCV"
    },
    {
        "序号": 4533,
        "name": "莱德系统",
        "最新价": 106.51,
        "涨跌额": 0.33,
        "涨跌幅": 0.31,
        "开盘价": 106.33,
        "最高价": 107.63,
        "最低价": 106.19,
        "昨收价": 106.18,
        "总市值": 4720762102.0,
        "市盈率": 9.67,
        "成交量": 134517.0,
        "成交额": 14342246.0,
        "振幅": 1.36,
        "换手率": 0.3,
        "symbol": "106.R"
    },
    {
        "序号": 4534,
        "name": "麦格纳国际",
        "最新价": 54.92,
        "涨跌额": 0.17,
        "涨跌幅": 0.31,
        "开盘价": 54.72,
        "最高价": 55.41,
        "最低价": 54.38,
        "昨收价": 54.75,
        "总市值": 15726969351.0,
        "市盈率": 15.17,
        "成交量": 654420.0,
        "成交额": 35950632.0,
        "振幅": 1.88,
        "换手率": 0.23,
        "symbol": "106.MGA"
    },
    {
        "序号": 4535,
        "name": "Pacer Swan SOS Conservative (Ju",
        "最新价": 22.65,
        "涨跌额": 0.07,
        "涨跌幅": 0.31,
        "开盘价": 22.65,
        "最高价": 22.65,
        "最低价": 22.65,
        "昨收价": 22.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 158.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PSCJ"
    },
    {
        "序号": 4536,
        "name": "Aptus Collared Investment Oppor",
        "最新价": 32.42,
        "涨跌额": 0.1,
        "涨跌幅": 0.31,
        "开盘价": 32.31,
        "最高价": 32.48,
        "最低价": 32.28,
        "昨收价": 32.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 51776.0,
        "成交额": 1677658.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.ACIO"
    },
    {
        "序号": 4537,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 32.43,
        "涨跌额": 0.1,
        "涨跌幅": 0.31,
        "开盘价": 32.33,
        "最高价": 32.44,
        "最低价": 32.33,
        "昨收价": 32.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 5048.0,
        "成交额": 163575.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.GFEB"
    },
    {
        "序号": 4538,
        "name": "VictoryShares US 500 Enhanced V",
        "最新价": 58.39,
        "涨跌额": 0.18,
        "涨跌幅": 0.31,
        "开盘价": 58.15,
        "最高价": 58.46,
        "最低价": 58.11,
        "昨收价": 58.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 36194.0,
        "成交额": 2109018.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "105.CFO"
    },
    {
        "序号": 4539,
        "name": "VanEck Video Gaming and eSports",
        "最新价": 55.27,
        "涨跌额": 0.17,
        "涨跌幅": 0.31,
        "开盘价": 54.96,
        "最高价": 55.34,
        "最低价": 54.93,
        "昨收价": 55.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 14642.0,
        "成交额": 807690.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "105.ESPO"
    },
    {
        "序号": 4540,
        "name": "Vanguard Russell 3000 ETF",
        "最新价": 204.9,
        "涨跌额": 0.63,
        "涨跌幅": 0.31,
        "开盘价": 203.88,
        "最高价": 205.32,
        "最低价": 203.88,
        "昨收价": 204.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 28751.0,
        "成交额": 5882964.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "105.VTHR"
    },
    {
        "序号": 4541,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 35.81,
        "涨跌额": 0.11,
        "涨跌幅": 0.31,
        "开盘价": 35.83,
        "最高价": 35.92,
        "最低价": 35.74,
        "昨收价": 35.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 3821.0,
        "成交额": 136844.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.DOCT"
    },
    {
        "序号": 4542,
        "name": "Simplify Macro Strategy ETF",
        "最新价": 22.79,
        "涨跌额": 0.07,
        "涨跌幅": 0.31,
        "开盘价": 22.76,
        "最高价": 22.86,
        "最低价": 22.75,
        "昨收价": 22.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 11528.0,
        "成交额": 262618.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FIG"
    },
    {
        "序号": 4543,
        "name": "BGSF Inc",
        "最新价": 9.77,
        "涨跌额": 0.03,
        "涨跌幅": 0.31,
        "开盘价": 9.65,
        "最高价": 9.84,
        "最低价": 9.6,
        "昨收价": 9.74,
        "总市值": 106264577.0,
        "市盈率": -11.2,
        "成交量": 4585.0,
        "成交额": 44445.0,
        "振幅": 2.46,
        "换手率": 0.04,
        "symbol": "106.BGSF"
    },
    {
        "序号": 4544,
        "name": "西氏医药服务",
        "最新价": 342.24,
        "涨跌额": 1.05,
        "涨跌幅": 0.31,
        "开盘价": 340.59,
        "最高价": 342.27,
        "最低价": 338.3,
        "昨收价": 341.19,
        "总市值": 25322224661.0,
        "市盈率": 45.27,
        "成交量": 470668.0,
        "成交额": 160566804.0,
        "振幅": 1.16,
        "换手率": 0.64,
        "symbol": "106.WST"
    },
    {
        "序号": 4545,
        "name": "Columbia U.S. ESG Equity Income",
        "最新价": 39.14,
        "涨跌额": 0.12,
        "涨跌幅": 0.31,
        "开盘价": 38.93,
        "最高价": 39.16,
        "最低价": 38.93,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 4337.0,
        "成交额": 169428.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.ESGS"
    },
    {
        "序号": 4546,
        "name": "Procore Technologies Inc",
        "最新价": 58.72,
        "涨跌额": 0.18,
        "涨跌幅": 0.31,
        "开盘价": 58.13,
        "最高价": 59.35,
        "最低价": 57.8,
        "昨收价": 58.54,
        "总市值": 8425875666.0,
        "市盈率": -36.42,
        "成交量": 1801561.0,
        "成交额": 106099765.0,
        "振幅": 2.65,
        "换手率": 1.26,
        "symbol": "106.PCOR"
    },
    {
        "序号": 4547,
        "name": "PennantPark Investment Corp",
        "最新价": 6.53,
        "涨跌额": 0.02,
        "涨跌幅": 0.31,
        "开盘价": 6.53,
        "最高价": 6.54,
        "最低价": 6.48,
        "昨收价": 6.51,
        "总市值": 425915985.0,
        "市盈率": -12.6,
        "成交量": 294183.0,
        "成交额": 1913871.0,
        "振幅": 0.92,
        "换手率": 0.45,
        "symbol": "106.PNNT"
    },
    {
        "序号": 4548,
        "name": "Ionic Inflation Protection ETF",
        "最新价": 19.62,
        "涨跌额": 0.06,
        "涨跌幅": 0.31,
        "开盘价": 19.62,
        "最高价": 19.62,
        "最低价": 19.62,
        "昨收价": 19.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 19.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CPII"
    },
    {
        "序号": 4549,
        "name": "MarketAxess Holdings Inc",
        "最新价": 261.68,
        "涨跌额": 0.8,
        "涨跌幅": 0.31,
        "开盘价": 260.0,
        "最高价": 262.11,
        "最低价": 259.05,
        "昨收价": 260.88,
        "总市值": 9919097633.0,
        "市盈率": 40.05,
        "成交量": 402732.0,
        "成交额": 105053083.0,
        "振幅": 1.17,
        "换手率": 1.06,
        "symbol": "105.MKTX"
    },
    {
        "序号": 4550,
        "name": "Krispy Kreme Inc",
        "最新价": 13.09,
        "涨跌额": 0.04,
        "涨跌幅": 0.31,
        "开盘价": 13.06,
        "最高价": 13.09,
        "最低价": 12.96,
        "昨收价": 13.05,
        "总市值": 2206895460.0,
        "市盈率": -51.02,
        "成交量": 754476.0,
        "成交额": 9836203.0,
        "振幅": 1.0,
        "换手率": 0.45,
        "symbol": "105.DNUT"
    },
    {
        "序号": 4551,
        "name": "SGI Dynamic Tactical ETF",
        "最新价": 26.21,
        "涨跌额": 0.08,
        "涨跌幅": 0.31,
        "开盘价": 26.13,
        "最高价": 26.26,
        "最低价": 26.11,
        "昨收价": 26.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 24106.0,
        "成交额": 631440.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "105.DYTA"
    },
    {
        "序号": 4552,
        "name": "城市控股",
        "最新价": 101.59,
        "涨跌额": 0.31,
        "涨跌幅": 0.31,
        "开盘价": 101.16,
        "最高价": 102.41,
        "最低价": 100.88,
        "昨收价": 101.28,
        "总市值": 1506764289.0,
        "市盈率": 12.82,
        "成交量": 44204.0,
        "成交额": 4490100.0,
        "振幅": 1.51,
        "换手率": 0.3,
        "symbol": "105.CHCO"
    },
    {
        "序号": 4553,
        "name": "Hartford Multifactor U.S. Equit",
        "最新价": 42.65,
        "涨跌额": 0.13,
        "涨跌幅": 0.31,
        "开盘价": 42.5,
        "最高价": 42.71,
        "最低价": 42.5,
        "昨收价": 42.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 11697.0,
        "成交额": 498469.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.ROUS"
    },
    {
        "序号": 4554,
        "name": "Xtrackers MSCI All World ex US ",
        "最新价": 29.54,
        "涨跌额": 0.09,
        "涨跌幅": 0.31,
        "开盘价": 29.5,
        "最高价": 29.59,
        "最低价": 29.5,
        "昨收价": 29.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 2661.0,
        "成交额": 78631.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.DBAW"
    },
    {
        "序号": 4555,
        "name": "Urban Outfitters Inc",
        "最新价": 36.11,
        "涨跌额": 0.11,
        "涨跌幅": 0.31,
        "开盘价": 35.86,
        "最高价": 36.24,
        "最低价": 35.5,
        "昨收价": 36.0,
        "总市值": 3350442662.0,
        "市盈率": 14.85,
        "成交量": 912431.0,
        "成交额": 32813248.0,
        "振幅": 2.06,
        "换手率": 0.98,
        "symbol": "105.URBN"
    },
    {
        "序号": 4556,
        "name": "AdvisorShares Q Dynamic Growth ",
        "最新价": 29.55,
        "涨跌额": 0.09,
        "涨跌幅": 0.31,
        "开盘价": 29.55,
        "最高价": 29.55,
        "最低价": 29.55,
        "昨收价": 29.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 41.0,
        "成交额": 1211.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.QPX"
    },
    {
        "序号": 4557,
        "name": "JPMorgan Nasdaq Equity Premium ",
        "最新价": 49.26,
        "涨跌额": 0.15,
        "涨跌幅": 0.31,
        "开盘价": 49.04,
        "最高价": 49.3,
        "最低价": 48.98,
        "昨收价": 49.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 2265229.0,
        "成交额": 111421857.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.JEPQ"
    },
    {
        "序号": 4558,
        "name": "普利茅斯工业房地产信托",
        "最新价": 22.99,
        "涨跌额": 0.07,
        "涨跌幅": 0.31,
        "开盘价": 22.8,
        "最高价": 23.01,
        "最低价": 22.8,
        "昨收价": 22.92,
        "总市值": 1040301730.0,
        "市盈率": 1171.51,
        "成交量": 133059.0,
        "成交额": 3048625.0,
        "振幅": 0.92,
        "换手率": 0.29,
        "symbol": "106.PLYM"
    },
    {
        "序号": 4559,
        "name": "前沿市场100ETF-iShares MSCI",
        "最新价": 26.3,
        "涨跌额": 0.08,
        "涨跌幅": 0.31,
        "开盘价": 26.18,
        "最高价": 26.3,
        "最低价": 26.15,
        "昨收价": 26.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 334052.0,
        "成交额": 8767145.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.FM"
    },
    {
        "序号": 4560,
        "name": "Franklin BSP Realty Trust Inc S",
        "最新价": 19.74,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 19.69,
        "最高价": 19.75,
        "最低价": 19.65,
        "昨收价": 19.68,
        "总市值": 203895230.0,
        "市盈率": null,
        "成交量": 14515.0,
        "成交额": 286118.0,
        "振幅": 0.51,
        "换手率": 0.14,
        "symbol": "106.FBRT_E"
    },
    {
        "序号": 4561,
        "name": "Enterprise Products Partners LP",
        "最新价": 26.33,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 26.25,
        "最高价": 26.36,
        "最低价": 26.15,
        "昨收价": 26.25,
        "总市值": 57185574149.0,
        "市盈率": 10.62,
        "成交量": 3980223.0,
        "成交额": 104519274.0,
        "振幅": 0.8,
        "换手率": 0.18,
        "symbol": "106.EPD"
    },
    {
        "序号": 4562,
        "name": "Vanguard International Dividend",
        "最新价": 75.72,
        "涨跌额": 0.23,
        "涨跌幅": 0.3,
        "开盘价": 75.41,
        "最高价": 75.8,
        "最低价": 75.35,
        "昨收价": 75.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 567835.0,
        "成交额": 42956504.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "105.VIGI"
    },
    {
        "序号": 4563,
        "name": "Syntax Stratified LargeCap ETF",
        "最新价": 69.15,
        "涨跌额": 0.21,
        "涨跌幅": 0.3,
        "开盘价": 69.65,
        "最高价": 69.65,
        "最低价": 69.03,
        "昨收价": 68.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 1150.0,
        "成交额": 79476.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.SSPY"
    },
    {
        "序号": 4564,
        "name": "ProShares Short Real Estate",
        "最新价": 19.77,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 19.85,
        "最高价": 19.95,
        "最低价": 19.72,
        "昨收价": 19.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 17368.0,
        "成交额": 345062.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "107.REK"
    },
    {
        "序号": 4565,
        "name": "CMS Energy Corp Pfd",
        "最新价": 79.12,
        "涨跌额": 0.24,
        "涨跌幅": 0.3,
        "开盘价": 79.01,
        "最高价": 79.22,
        "最低价": 79.0,
        "昨收价": 78.88,
        "总市值": 29523470.0,
        "市盈率": null,
        "成交量": 369.0,
        "成交额": 29166.0,
        "振幅": 0.28,
        "换手率": 0.1,
        "symbol": "106.CMS_B"
    },
    {
        "序号": 4566,
        "name": "Southern California Edison Co S",
        "最新价": 19.79,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 19.72,
        "最高价": 19.82,
        "最低价": 19.72,
        "昨收价": 19.73,
        "总市值": 3760179.0,
        "市盈率": null,
        "成交量": 25120.0,
        "成交额": 496257.0,
        "振幅": 0.51,
        "换手率": 13.22,
        "symbol": "106.SCE_L"
    },
    {
        "序号": 4567,
        "name": "Timothy Plan US Large\/Mid Cap C",
        "最新价": 36.44,
        "涨跌额": 0.11,
        "涨跌幅": 0.3,
        "开盘价": 36.47,
        "最高价": 36.53,
        "最低价": 36.33,
        "昨收价": 36.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 8252.0,
        "成交额": 300662.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.TPLC"
    },
    {
        "序号": 4568,
        "name": "全球基础设施ETF-iShares",
        "最新价": 46.41,
        "涨跌额": 0.14,
        "涨跌幅": 0.3,
        "开盘价": 46.2,
        "最高价": 46.44,
        "最低价": 46.11,
        "昨收价": 46.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 434823.0,
        "成交额": 20140880.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "105.IGF"
    },
    {
        "序号": 4569,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 36.49,
        "涨跌额": 0.11,
        "涨跌幅": 0.3,
        "开盘价": 36.26,
        "最高价": 36.5,
        "最低价": 36.26,
        "昨收价": 36.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 7979.0,
        "成交额": 290677.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.BJUL"
    },
    {
        "序号": 4570,
        "name": "Allurion Technologies Inc",
        "最新价": 3.32,
        "涨跌额": 0.01,
        "涨跌幅": 0.3,
        "开盘价": 3.24,
        "最高价": 3.4,
        "最低价": 3.02,
        "昨收价": 3.31,
        "总市值": 157725534.0,
        "市盈率": -2.63,
        "成交量": 13091.0,
        "成交额": 43438.0,
        "振幅": 11.48,
        "换手率": 0.03,
        "symbol": "106.ALUR"
    },
    {
        "序号": 4571,
        "name": "TrueShares Structured Outcome (",
        "最新价": 36.53,
        "涨跌额": 0.11,
        "涨跌幅": 0.3,
        "开盘价": 36.55,
        "最高价": 36.58,
        "最低价": 36.53,
        "昨收价": 36.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 447.0,
        "成交额": 16341.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.JULZ"
    },
    {
        "序号": 4572,
        "name": "阿里科",
        "最新价": 29.9,
        "涨跌额": 0.09,
        "涨跌幅": 0.3,
        "开盘价": 29.99,
        "最高价": 29.99,
        "最低价": 29.35,
        "昨收价": 29.81,
        "总市值": 227720822.0,
        "市盈率": 124.1,
        "成交量": 30681.0,
        "成交额": 913001.0,
        "振幅": 2.15,
        "换手率": 0.4,
        "symbol": "105.ALCO"
    },
    {
        "序号": 4573,
        "name": "EAFE指数ETF-iShares MSCI",
        "最新价": 73.14,
        "涨跌额": 0.22,
        "涨跌幅": 0.3,
        "开盘价": 72.74,
        "最高价": 73.25,
        "最低价": 72.72,
        "昨收价": 72.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 13323616.0,
        "成交额": 973260416.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.EFA"
    },
    {
        "序号": 4574,
        "name": "Merit Medical Systems Inc",
        "最新价": 69.82,
        "涨跌额": 0.21,
        "涨跌幅": 0.3,
        "开盘价": 69.81,
        "最高价": 70.09,
        "最低价": 69.43,
        "昨收价": 69.61,
        "总市值": 4032137257.0,
        "市盈率": 40.25,
        "成交量": 646479.0,
        "成交额": 45130651.0,
        "振幅": 0.95,
        "换手率": 1.12,
        "symbol": "105.MMSI"
    },
    {
        "序号": 4575,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 29.95,
        "涨跌额": 0.09,
        "涨跌幅": 0.3,
        "开盘价": 29.93,
        "最高价": 30.02,
        "最低价": 29.86,
        "昨收价": 29.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 119204.0,
        "成交额": 3569458.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.UAUG"
    },
    {
        "序号": 4576,
        "name": "First Trust Specialty Finance a",
        "最新价": 3.33,
        "涨跌额": 0.01,
        "涨跌幅": 0.3,
        "开盘价": 3.34,
        "最高价": 3.34,
        "最低价": 3.31,
        "昨收价": 3.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 42277.0,
        "成交额": 141032.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "106.FGB"
    },
    {
        "序号": 4577,
        "name": "Voya Infrastructure, Industrial",
        "最新价": 10.0,
        "涨跌额": 0.03,
        "涨跌幅": 0.3,
        "开盘价": 9.99,
        "最高价": 10.0,
        "最低价": 9.9,
        "昨收价": 9.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 17823.0,
        "成交额": 177755.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "106.IDE"
    },
    {
        "序号": 4578,
        "name": "FB Financial Corp",
        "最新价": 36.7,
        "涨跌额": 0.11,
        "涨跌幅": 0.3,
        "开盘价": 36.72,
        "最高价": 37.27,
        "最低价": 36.23,
        "昨收价": 36.59,
        "总市值": 1719152780.0,
        "市盈率": 13.33,
        "成交量": 100261.0,
        "成交额": 3685837.0,
        "振幅": 2.84,
        "换手率": 0.21,
        "symbol": "106.FBK"
    },
    {
        "序号": 4579,
        "name": "T. Rowe Price Capital Appreciat",
        "最新价": 26.7,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 26.59,
        "最高价": 26.72,
        "最低价": 26.57,
        "昨收价": 26.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 243704.0,
        "成交额": 6496678.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.TCAF"
    },
    {
        "序号": 4580,
        "name": "Apollo Medical Holdings Inc",
        "最新价": 33.38,
        "涨跌额": 0.1,
        "涨跌幅": 0.3,
        "开盘价": 33.05,
        "最高价": 33.74,
        "最低价": 32.64,
        "昨收价": 33.28,
        "总市值": 1601716935.0,
        "市盈率": 33.02,
        "成交量": 118203.0,
        "成交额": 3929628.0,
        "振幅": 3.31,
        "换手率": 0.25,
        "symbol": "105.AMEH"
    },
    {
        "序号": 4581,
        "name": "LSI Industries Inc",
        "最新价": 13.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.3,
        "开盘价": 13.28,
        "最高价": 13.48,
        "最低价": 13.28,
        "昨收价": 13.32,
        "总市值": 386390559.0,
        "市盈率": 14.04,
        "成交量": 68934.0,
        "成交额": 922285.0,
        "振幅": 1.5,
        "换手率": 0.24,
        "symbol": "105.LYTS"
    },
    {
        "序号": 4582,
        "name": "Star Bulk Carriers Corp",
        "最新价": 20.07,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 20.21,
        "最高价": 20.42,
        "最低价": 20.04,
        "昨收价": 20.01,
        "总市值": 1883806165.0,
        "市盈率": 8.58,
        "成交量": 598683.0,
        "成交额": 12060621.0,
        "振幅": 1.9,
        "换手率": 0.64,
        "symbol": "105.SBLK"
    },
    {
        "序号": 4583,
        "name": "First Guaranty Bancshares Inc",
        "最新价": 10.04,
        "涨跌额": 0.03,
        "涨跌幅": 0.3,
        "开盘价": 10.46,
        "最高价": 10.46,
        "最低价": 9.91,
        "昨收价": 10.01,
        "总市值": 114768073.0,
        "市盈率": 8.8,
        "成交量": 4582.0,
        "成交额": 46911.0,
        "振幅": 5.49,
        "换手率": 0.04,
        "symbol": "105.FGBI"
    },
    {
        "序号": 4584,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 26.78,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 26.74,
        "最高价": 26.78,
        "最低价": 26.74,
        "昨收价": 26.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 7346.0,
        "成交额": 196481.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.MAYW"
    },
    {
        "序号": 4585,
        "name": "iShares Trust iShares Investmen",
        "最新价": 30.14,
        "涨跌额": 0.09,
        "涨跌幅": 0.3,
        "开盘价": 30.02,
        "最高价": 30.14,
        "最低价": 30.02,
        "昨收价": 30.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 79357.0,
        "成交额": 2385524.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.LQDW"
    },
    {
        "序号": 4586,
        "name": "Skillsoft Corp II-A",
        "最新价": 16.75,
        "涨跌额": 0.05,
        "涨跌幅": 0.3,
        "开盘价": 16.7,
        "最高价": 17.2,
        "最低价": 16.16,
        "昨收价": 16.7,
        "总市值": 135152785.0,
        "市盈率": -0.86,
        "成交量": 34839.0,
        "成交额": 585729.0,
        "振幅": 6.23,
        "换手率": 0.43,
        "symbol": "106.SKIL"
    },
    {
        "序号": 4587,
        "name": "百时美施贵宝",
        "最新价": 50.31,
        "涨跌额": 0.15,
        "涨跌幅": 0.3,
        "开盘价": 50.26,
        "最高价": 50.54,
        "最低价": 50.09,
        "昨收价": 50.16,
        "总市值": 102368662000.0,
        "市盈率": 12.36,
        "成交量": 10123073.0,
        "成交额": 509346000.0,
        "振幅": 0.9,
        "换手率": 0.5,
        "symbol": "106.BMY"
    },
    {
        "序号": 4588,
        "name": "Innovator Growth-100 Power Buff",
        "最新价": 53.75,
        "涨跌额": 0.16,
        "涨跌幅": 0.3,
        "开盘价": 53.55,
        "最高价": 53.79,
        "最低价": 53.55,
        "昨收价": 53.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 13187.0,
        "成交额": 707600.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.NJUL"
    },
    {
        "序号": 4589,
        "name": "Vesper U.S. Large Cap Short-Ter",
        "最新价": 23.56,
        "涨跌额": 0.07,
        "涨跌幅": 0.3,
        "开盘价": 23.44,
        "最高价": 23.6,
        "最低价": 23.44,
        "昨收价": 23.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 5855.0,
        "成交额": 137938.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.UTRN"
    },
    {
        "序号": 4590,
        "name": "WisdomTree Emerging Markets Qua",
        "最新价": 23.58,
        "涨跌额": 0.07,
        "涨跌幅": 0.3,
        "开盘价": 23.44,
        "最高价": 23.61,
        "最低价": 23.44,
        "昨收价": 23.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 5749.0,
        "成交额": 135176.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "105.DGRE"
    },
    {
        "序号": 4591,
        "name": "Seagen Inc",
        "最新价": 218.98,
        "涨跌额": 0.65,
        "涨跌幅": 0.3,
        "开盘价": 219.0,
        "最高价": 219.76,
        "最低价": 218.18,
        "昨收价": 218.33,
        "总市值": 41313351915.0,
        "市盈率": -55.07,
        "成交量": 1183163.0,
        "成交额": 259067792.0,
        "振幅": 0.72,
        "换手率": 0.63,
        "symbol": "105.SGEN"
    },
    {
        "序号": 4592,
        "name": "Global X Russell 2000 Covered C",
        "最新价": 16.85,
        "涨跌额": 0.05,
        "涨跌幅": 0.3,
        "开盘价": 16.8,
        "最高价": 16.85,
        "最低价": 16.8,
        "昨收价": 16.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 547157.0,
        "成交额": 9215307.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.RYLD"
    },
    {
        "序号": 4593,
        "name": "MFA Financial Inc Series C Pfd",
        "最新价": 20.22,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 20.15,
        "最高价": 20.22,
        "最低价": 20.09,
        "昨收价": 20.16,
        "总市值": 222420000.0,
        "市盈率": null,
        "成交量": 6945.0,
        "成交额": 140051.0,
        "振幅": 0.64,
        "换手率": 0.06,
        "symbol": "106.MFA_C"
    },
    {
        "序号": 4594,
        "name": "VanEck Israel ETF",
        "最新价": 33.72,
        "涨跌额": 0.1,
        "涨跌幅": 0.3,
        "开盘价": 33.66,
        "最高价": 33.72,
        "最低价": 33.61,
        "昨收价": 33.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 1962.0,
        "成交额": 66001.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.ISRA"
    },
    {
        "序号": 4595,
        "name": "OFS Credit Co Inc",
        "最新价": 6.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.3,
        "开盘价": 6.73,
        "最高价": 6.78,
        "最低价": 6.66,
        "昨收价": 6.73,
        "总市值": 89014275.0,
        "市盈率": null,
        "成交量": 124024.0,
        "成交额": 833803.0,
        "振幅": 1.78,
        "换手率": 0.94,
        "symbol": "105.OCCI"
    },
    {
        "序号": 4596,
        "name": "Fidelity U.S. Multifactor ETF",
        "最新价": 27.01,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 26.94,
        "最高价": 27.04,
        "最低价": 26.88,
        "昨收价": 26.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 37855.0,
        "成交额": 1021171.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.FLRG"
    },
    {
        "序号": 4597,
        "name": "Distillate U.S. Fundamental Sta",
        "最新价": 47.33,
        "涨跌额": 0.14,
        "涨跌幅": 0.3,
        "开盘价": 47.12,
        "最高价": 47.43,
        "最低价": 47.12,
        "昨收价": 47.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 50941.0,
        "成交额": 2411606.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.DSTL"
    },
    {
        "序号": 4598,
        "name": "Innovator Growth-100 Power Buff",
        "最新价": 43.95,
        "涨跌额": 0.13,
        "涨跌幅": 0.3,
        "开盘价": 43.87,
        "最高价": 43.98,
        "最低价": 43.85,
        "昨收价": 43.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 4824.0,
        "成交额": 211781.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.NAPR"
    },
    {
        "序号": 4599,
        "name": "Innovator Laddered Allocation B",
        "最新价": 27.05,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 26.95,
        "最高价": 27.08,
        "最低价": 26.88,
        "昨收价": 26.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 44134.0,
        "成交额": 1193445.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.BUFB"
    },
    {
        "序号": 4600,
        "name": "VanEck Morningstar Global Wide ",
        "最新价": 33.84,
        "涨跌额": 0.1,
        "涨跌幅": 0.3,
        "开盘价": 33.84,
        "最高价": 33.86,
        "最低价": 33.77,
        "昨收价": 33.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 5529.0,
        "成交额": 186911.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.MOTG"
    },
    {
        "序号": 4601,
        "name": "Hennessy Stance ESG ETF",
        "最新价": 27.11,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 27.11,
        "最高价": 27.11,
        "最低价": 27.04,
        "昨收价": 27.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1404.0,
        "成交额": 38008.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.STNC"
    },
    {
        "序号": 4602,
        "name": "Enel Chile SA ADR",
        "最新价": 3.39,
        "涨跌额": 0.01,
        "涨跌幅": 0.3,
        "开盘价": 3.39,
        "最高价": 3.41,
        "最低价": 3.35,
        "昨收价": 3.38,
        "总市值": 4689492580.0,
        "市盈率": 3.18,
        "成交量": 776680.0,
        "成交额": 2630006.0,
        "振幅": 1.78,
        "换手率": 0.06,
        "symbol": "106.ENIC"
    },
    {
        "序号": 4603,
        "name": "SPDR MSCI USA StrategicFactors ",
        "最新价": 128.85,
        "涨跌额": 0.38,
        "涨跌幅": 0.3,
        "开盘价": 128.32,
        "最高价": 129.02,
        "最低价": 128.32,
        "昨收价": 128.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 12550.0,
        "成交额": 1615724.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.QUS"
    },
    {
        "序号": 4604,
        "name": "Equitable Holdings Inc Series A",
        "最新价": 20.35,
        "涨跌额": 0.06,
        "涨跌幅": 0.3,
        "开盘价": 20.26,
        "最高价": 20.4,
        "最低价": 20.09,
        "昨收价": 20.29,
        "总市值": 651200.0,
        "市盈率": null,
        "成交量": 62526.0,
        "成交额": 1265985.0,
        "振幅": 1.53,
        "换手率": 195.39,
        "symbol": "106.EQH_A"
    },
    {
        "序号": 4605,
        "name": "Aptus Drawdown Managed Equity E",
        "最新价": 37.31,
        "涨跌额": 0.11,
        "涨跌幅": 0.3,
        "开盘价": 37.12,
        "最高价": 37.37,
        "最低价": 37.12,
        "昨收价": 37.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 15990.0,
        "成交额": 596261.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.ADME"
    },
    {
        "序号": 4606,
        "name": "JPMorgan Diversified Return U.S",
        "最新价": 88.29,
        "涨跌额": 0.26,
        "涨跌幅": 0.3,
        "开盘价": 88.01,
        "最高价": 88.52,
        "最低价": 88.0,
        "昨收价": 88.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 11560.0,
        "成交额": 1018831.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.JPME"
    },
    {
        "序号": 4607,
        "name": "Brookstone Value Stock ETF",
        "最新价": 27.17,
        "涨跌额": 0.08,
        "涨跌幅": 0.3,
        "开盘价": 27.06,
        "最高价": 27.26,
        "最低价": 27.06,
        "昨收价": 27.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 12409.0,
        "成交额": 337193.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.BAMV"
    },
    {
        "序号": 4608,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 34.02,
        "涨跌额": 0.1,
        "涨跌幅": 0.29,
        "开盘价": 33.93,
        "最高价": 34.06,
        "最低价": 33.93,
        "昨收价": 33.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1904.0,
        "成交额": 64692.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.GJAN"
    },
    {
        "序号": 4609,
        "name": "WisdomTree U.S. SmallCap Qualit",
        "最新价": 44.33,
        "涨跌额": 0.13,
        "涨跌幅": 0.29,
        "开盘价": 44.39,
        "最高价": 44.54,
        "最低价": 44.11,
        "昨收价": 44.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 12921.0,
        "成交额": 572158.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "105.DGRS"
    },
    {
        "序号": 4610,
        "name": "iShares Core MSCI EAFE ETF",
        "最新价": 68.25,
        "涨跌额": 0.2,
        "涨跌幅": 0.29,
        "开盘价": 67.86,
        "最高价": 68.33,
        "最低价": 67.85,
        "昨收价": 68.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 8136780.0,
        "成交额": 554395840.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.IEFA"
    },
    {
        "序号": 4611,
        "name": "IQ Chaikin U.S. Large Cap ETF",
        "最新价": 34.14,
        "涨跌额": 0.1,
        "涨跌幅": 0.29,
        "开盘价": 34.14,
        "最高价": 34.14,
        "最低价": 34.14,
        "昨收价": 34.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 121.0,
        "成交额": 4130.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLRG"
    },
    {
        "序号": 4612,
        "name": "VTEX-A",
        "最新价": 6.83,
        "涨跌额": 0.02,
        "涨跌幅": 0.29,
        "开盘价": 6.72,
        "最高价": 6.87,
        "最低价": 6.44,
        "昨收价": 6.81,
        "总市值": 1282842974.0,
        "市盈率": -74.43,
        "成交量": 235160.0,
        "成交额": 1595267.0,
        "振幅": 6.31,
        "换手率": 0.13,
        "symbol": "106.VTEX"
    },
    {
        "序号": 4613,
        "name": "American Century U.S. Quality V",
        "最新价": 51.24,
        "涨跌额": 0.15,
        "涨跌幅": 0.29,
        "开盘价": 51.17,
        "最高价": 51.35,
        "最低价": 51.17,
        "昨收价": 51.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 3689.0,
        "成交额": 189106.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.VALQ"
    },
    {
        "序号": 4614,
        "name": "ETRACS CMCI Total Return ETN Se",
        "最新价": 23.93,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 23.97,
        "最高价": 23.97,
        "最低价": 23.85,
        "昨收价": 23.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 601.0,
        "成交额": 14377.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.UCIB"
    },
    {
        "序号": 4615,
        "name": "Invesco S&P SmallCap Utilities ",
        "最新价": 51.36,
        "涨跌额": 0.15,
        "涨跌幅": 0.29,
        "开盘价": 51.36,
        "最高价": 51.36,
        "最低价": 51.36,
        "昨收价": 51.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 365.0,
        "成交额": 18746.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PSCU"
    },
    {
        "序号": 4616,
        "name": "First Trust Alerian U.S. NextGe",
        "最新价": 54.82,
        "涨跌额": 0.16,
        "涨跌幅": 0.29,
        "开盘价": 54.82,
        "最高价": 54.82,
        "最低价": 54.82,
        "昨收价": 54.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 46.0,
        "成交额": 2521.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RBLD"
    },
    {
        "序号": 4617,
        "name": "iShares International Small-Cap",
        "最新价": 30.85,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 30.67,
        "最高价": 30.86,
        "最低价": 30.64,
        "昨收价": 30.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 16416.0,
        "成交额": 505326.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.ISCF"
    },
    {
        "序号": 4618,
        "name": "Changebridge Select Equity ETF",
        "最新价": 24.0,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.04,
        "最高价": 24.04,
        "最低价": 23.94,
        "昨收价": 23.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 610.0,
        "成交额": 14627.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.CBSE"
    },
    {
        "序号": 4619,
        "name": "Polen Capital Global Growth ETF",
        "最新价": 10.29,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.24,
        "最高价": 10.29,
        "最低价": 10.23,
        "昨收价": 10.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 34111.0,
        "成交额": 349090.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.PCGG"
    },
    {
        "序号": 4620,
        "name": "Global X Financials Covered Cal",
        "最新价": 24.01,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.01,
        "最高价": 24.01,
        "最低价": 24.01,
        "昨收价": 23.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 75.0,
        "成交额": 1800.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FYLG"
    },
    {
        "序号": 4621,
        "name": "Global X NASDAQ 100 Covered Cal",
        "最新价": 17.18,
        "涨跌额": 0.05,
        "涨跌幅": 0.29,
        "开盘价": 17.12,
        "最高价": 17.18,
        "最低价": 17.1,
        "昨收价": 17.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 2205457.0,
        "成交额": 37823832.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "105.QYLD"
    },
    {
        "序号": 4622,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.51,
        "涨跌额": 0.08,
        "涨跌幅": 0.29,
        "开盘价": 27.47,
        "最高价": 27.51,
        "最低价": 27.47,
        "昨收价": 27.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 1770.0,
        "成交额": 48624.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.FEBW"
    },
    {
        "序号": 4623,
        "name": "elf Beauty Inc",
        "最新价": 127.27,
        "涨跌额": 0.37,
        "涨跌幅": 0.29,
        "开盘价": 127.32,
        "最高价": 128.34,
        "最低价": 124.28,
        "昨收价": 126.9,
        "总市值": 7025204729.0,
        "市盈率": 57.77,
        "成交量": 775998.0,
        "成交额": 98081691.0,
        "振幅": 3.2,
        "换手率": 1.41,
        "symbol": "106.ELF"
    },
    {
        "序号": 4624,
        "name": "SilverBox Corp III-A",
        "最新价": 10.32,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.3,
        "最高价": 10.32,
        "最低价": 10.3,
        "昨收价": 10.29,
        "总市值": 178020000.0,
        "市盈率": 77.31,
        "成交量": 143459.0,
        "成交额": 1478747.0,
        "振幅": 0.19,
        "换手率": 0.83,
        "symbol": "106.SBXC"
    },
    {
        "序号": 4625,
        "name": "Dimensional International Core ",
        "最新价": 27.57,
        "涨跌额": 0.08,
        "涨跌幅": 0.29,
        "开盘价": 27.43,
        "最高价": 27.6,
        "最低价": 27.43,
        "昨收价": 27.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 588081.0,
        "成交额": 16183372.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.DFAI"
    },
    {
        "序号": 4626,
        "name": "Horizon Technology Finance Corp",
        "最新价": 24.15,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.15,
        "最高价": 24.15,
        "最低价": 24.15,
        "昨收价": 24.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 200.0,
        "成交额": 4830.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.HTFC"
    },
    {
        "序号": 4627,
        "name": "JPMorgan BetaBuilders Internati",
        "最新价": 55.29,
        "涨跌额": 0.16,
        "涨跌幅": 0.29,
        "开盘价": 54.93,
        "最高价": 55.33,
        "最低价": 54.93,
        "昨收价": 55.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 64241.0,
        "成交额": 3544255.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.BBIN"
    },
    {
        "序号": 4628,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 31.12,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 31.0,
        "最高价": 31.13,
        "最低价": 30.98,
        "昨收价": 31.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 84513.0,
        "成交额": 2624828.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.GNOV"
    },
    {
        "序号": 4629,
        "name": "AAM Bahl & Gaynor Small\/Mid Cap",
        "最新价": 24.22,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.17,
        "最高价": 24.26,
        "最低价": 24.07,
        "昨收价": 24.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 52878.0,
        "成交额": 1276151.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.SMIG"
    },
    {
        "序号": 4630,
        "name": "摩森康胜-B",
        "最新价": 62.31,
        "涨跌额": 0.18,
        "涨跌幅": 0.29,
        "开盘价": 62.09,
        "最高价": 62.64,
        "最低价": 62.09,
        "昨收价": 62.13,
        "总市值": 12683576105.0,
        "市盈率": 49.72,
        "成交量": 1166303.0,
        "成交额": 72653567.0,
        "振幅": 0.89,
        "换手率": 0.58,
        "symbol": "106.TAP"
    },
    {
        "序号": 4631,
        "name": "Compass Group Diversified Holdi",
        "最新价": 20.77,
        "涨跌额": 0.06,
        "涨跌幅": 0.29,
        "开盘价": 20.7,
        "最高价": 20.97,
        "最低价": 20.7,
        "昨收价": 20.71,
        "总市值": 1491708233.0,
        "市盈率": 15.4,
        "成交量": 140421.0,
        "成交额": 2922632.0,
        "振幅": 1.3,
        "换手率": 0.2,
        "symbol": "106.CODI"
    },
    {
        "序号": 4632,
        "name": "Saia Inc",
        "最新价": 391.5,
        "涨跌额": 1.13,
        "涨跌幅": 0.29,
        "开盘价": 388.59,
        "最高价": 399.99,
        "最低价": 385.5,
        "昨收价": 390.37,
        "总市值": 10393517336.0,
        "市盈率": 30.89,
        "成交量": 327164.0,
        "成交额": 128485599.0,
        "振幅": 3.71,
        "换手率": 1.23,
        "symbol": "105.SAIA"
    },
    {
        "序号": 4633,
        "name": "EnPro Industries Inc",
        "最新价": 135.16,
        "涨跌额": 0.39,
        "涨跌幅": 0.29,
        "开盘价": 134.34,
        "最高价": 136.16,
        "最低价": 134.04,
        "昨收价": 134.77,
        "总市值": 2825839318.0,
        "市盈率": 18.34,
        "成交量": 82513.0,
        "成交额": 11124716.0,
        "振幅": 1.57,
        "换手率": 0.39,
        "symbol": "106.NPO"
    },
    {
        "序号": 4634,
        "name": "Pacer Swan SOS Conservative (Ja",
        "最新价": 24.28,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.22,
        "最高价": 24.28,
        "最低价": 24.22,
        "昨收价": 24.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 4068.0,
        "成交额": 98559.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.PSCX"
    },
    {
        "序号": 4635,
        "name": "Macatawa Bank Corp",
        "最新价": 10.41,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.39,
        "最高价": 10.49,
        "最低价": 10.25,
        "昨收价": 10.38,
        "总市值": 356974380.0,
        "市盈率": 7.79,
        "成交量": 36234.0,
        "成交额": 376564.0,
        "振幅": 2.31,
        "换手率": 0.11,
        "symbol": "105.MCBC"
    },
    {
        "序号": 4636,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 38.21,
        "涨跌额": 0.11,
        "涨跌幅": 0.29,
        "开盘价": 38.0,
        "最高价": 38.22,
        "最低价": 38.0,
        "昨收价": 38.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 6502.0,
        "成交额": 247792.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.FOCT"
    },
    {
        "序号": 4637,
        "name": "iShares Core Dividend Growth ET",
        "最新价": 52.11,
        "涨跌额": 0.15,
        "涨跌幅": 0.29,
        "开盘价": 51.97,
        "最高价": 52.21,
        "最低价": 51.9,
        "昨收价": 51.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 1546479.0,
        "成交额": 80495954.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.DGRO"
    },
    {
        "序号": 4638,
        "name": "abrdn Global Infrastructure Inc",
        "最新价": 17.37,
        "涨跌额": 0.05,
        "涨跌幅": 0.29,
        "开盘价": 17.25,
        "最高价": 17.37,
        "最低价": 17.25,
        "昨收价": 17.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 56347.0,
        "成交额": 975599.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.ASGI"
    },
    {
        "序号": 4639,
        "name": "克利夫兰克里夫",
        "最新价": 17.38,
        "涨跌额": 0.05,
        "涨跌幅": 0.29,
        "开盘价": 17.3,
        "最高价": 17.85,
        "最低价": 17.26,
        "昨收价": 17.33,
        "总市值": 8774488977.0,
        "市盈率": 25.81,
        "成交量": 4344664.0,
        "成交额": 76100177.0,
        "振幅": 3.4,
        "换手率": 0.86,
        "symbol": "106.CLF"
    },
    {
        "序号": 4640,
        "name": "Coya Therapeutics Inc",
        "最新价": 6.96,
        "涨跌额": 0.02,
        "涨跌幅": 0.29,
        "开盘价": 7.03,
        "最高价": 7.48,
        "最低价": 6.81,
        "昨收价": 6.94,
        "总市值": 69811835.0,
        "市盈率": -5.65,
        "成交量": 50801.0,
        "成交额": 359784.0,
        "振幅": 9.65,
        "换手率": 0.51,
        "symbol": "105.COYA"
    },
    {
        "序号": 4641,
        "name": "Perion Network Ltd",
        "最新价": 27.88,
        "涨跌额": 0.08,
        "涨跌幅": 0.29,
        "开盘价": 27.8,
        "最高价": 28.07,
        "最低价": 27.5,
        "昨收价": 27.8,
        "总市值": 1311460925.0,
        "市盈率": 11.24,
        "成交量": 178583.0,
        "成交额": 4959367.0,
        "振幅": 2.05,
        "换手率": 0.38,
        "symbol": "105.PERI"
    },
    {
        "序号": 4642,
        "name": "TrueShares Structured Outcome (",
        "最新价": 31.4,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 31.31,
        "最高价": 31.42,
        "最低价": 31.29,
        "昨收价": 31.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 5443.0,
        "成交额": 170684.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.DECZ"
    },
    {
        "序号": 4643,
        "name": "VanEck Morningstar ESG Moat ETF",
        "最新价": 24.43,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.43,
        "最高价": 24.43,
        "最低价": 24.43,
        "昨收价": 24.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 117.0,
        "成交额": 2857.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MOTE"
    },
    {
        "序号": 4644,
        "name": "CDW Corp",
        "最新价": 213.19,
        "涨跌额": 0.61,
        "涨跌幅": 0.29,
        "开盘价": 211.63,
        "最高价": 213.62,
        "最低价": 210.06,
        "昨收价": 212.58,
        "总市值": 28559011067.0,
        "市盈率": 26.07,
        "成交量": 497288.0,
        "成交额": 105907797.0,
        "振幅": 1.67,
        "换手率": 0.37,
        "symbol": "105.CDW"
    },
    {
        "序号": 4645,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 34.97,
        "涨跌额": 0.1,
        "涨跌幅": 0.29,
        "开盘价": 34.9,
        "最高价": 35.0,
        "最低价": 34.83,
        "昨收价": 34.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 277538.0,
        "成交额": 9707419.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.BAUG"
    },
    {
        "序号": 4646,
        "name": "Ready Capital Corp",
        "最新价": 10.5,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.43,
        "最高价": 10.53,
        "最低价": 10.33,
        "昨收价": 10.47,
        "总市值": 1807309025.0,
        "市盈率": 5.3,
        "成交量": 714747.0,
        "成交额": 7474244.0,
        "振幅": 1.91,
        "换手率": 0.42,
        "symbol": "106.RC"
    },
    {
        "序号": 4647,
        "name": "Centerspace Series C Pfd",
        "最新价": 24.5,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.17,
        "最高价": 24.5,
        "最低价": 24.0,
        "昨收价": 24.43,
        "总市值": 95095599.0,
        "市盈率": null,
        "成交量": 8277.0,
        "成交额": 200414.0,
        "振幅": 2.05,
        "换手率": 0.21,
        "symbol": "106.CSR_C"
    },
    {
        "序号": 4648,
        "name": "Soundwatch Hedged Equity ETF",
        "最新价": 24.51,
        "涨跌额": 0.07,
        "涨跌幅": 0.29,
        "开盘价": 24.48,
        "最高价": 24.52,
        "最低价": 24.48,
        "昨收价": 24.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 1900.0,
        "成交额": 46536.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.SHDG"
    },
    {
        "序号": 4649,
        "name": "First Trust Institutional Prefe",
        "最新价": 17.52,
        "涨跌额": 0.05,
        "涨跌幅": 0.29,
        "开盘价": 17.47,
        "最高价": 17.52,
        "最低价": 17.44,
        "昨收价": 17.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 282804.0,
        "成交额": 4940067.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.FPEI"
    },
    {
        "序号": 4650,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 31.56,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 31.48,
        "最高价": 31.59,
        "最低价": 31.48,
        "昨收价": 31.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 5526.0,
        "成交额": 174101.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.XJUL"
    },
    {
        "序号": 4651,
        "name": "Portage Fintech Acquisition Cor",
        "最新价": 10.52,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.51,
        "最高价": 10.53,
        "最低价": 10.51,
        "昨收价": 10.49,
        "总市值": 109284022.0,
        "市盈率": 11.48,
        "成交量": 9329.0,
        "成交额": 98193.0,
        "振幅": 0.19,
        "换手率": 0.09,
        "symbol": "105.PFTA"
    },
    {
        "序号": 4652,
        "name": "托罗配件",
        "最新价": 84.23,
        "涨跌额": 0.24,
        "涨跌幅": 0.29,
        "开盘价": 84.13,
        "最高价": 85.05,
        "最低价": 84.01,
        "昨收价": 83.99,
        "总市值": 8746786858.0,
        "市盈率": 23.21,
        "成交量": 404394.0,
        "成交额": 34159714.0,
        "振幅": 1.24,
        "换手率": 0.39,
        "symbol": "106.TTC"
    },
    {
        "序号": 4653,
        "name": "XAI Octagon Floating Rate & Alt",
        "最新价": 7.02,
        "涨跌额": 0.02,
        "涨跌幅": 0.29,
        "开盘价": 6.98,
        "最高价": 7.04,
        "最低价": 6.97,
        "昨收价": 7.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 304614.0,
        "成交额": 2135529.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "106.XFLT"
    },
    {
        "序号": 4654,
        "name": "iShares MSCI KLD 400 Social ETF",
        "最新价": 87.82,
        "涨跌额": 0.25,
        "涨跌幅": 0.29,
        "开盘价": 87.27,
        "最高价": 87.96,
        "最低价": 87.27,
        "昨收价": 87.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 168635.0,
        "成交额": 14786034.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.DSI"
    },
    {
        "序号": 4655,
        "name": "Fidelity Quality Factor ETF",
        "最新价": 52.72,
        "涨跌额": 0.15,
        "涨跌幅": 0.29,
        "开盘价": 52.53,
        "最高价": 52.75,
        "最低价": 52.43,
        "昨收价": 52.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 21772.0,
        "成交额": 1144321.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.FQAL"
    },
    {
        "序号": 4656,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 31.64,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 31.52,
        "最高价": 31.64,
        "最低价": 31.52,
        "昨收价": 31.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 9977.0,
        "成交额": 315082.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.XOCT"
    },
    {
        "序号": 4657,
        "name": "Leuthold Core ETF",
        "最新价": 31.64,
        "涨跌额": 0.09,
        "涨跌幅": 0.29,
        "开盘价": 31.69,
        "最高价": 31.69,
        "最低价": 31.64,
        "昨收价": 31.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 880.0,
        "成交额": 27853.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.LCR"
    },
    {
        "序号": 4658,
        "name": "Project Energy Reimagined Acqui",
        "最新价": 10.55,
        "涨跌额": 0.03,
        "涨跌幅": 0.29,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.55,
        "昨收价": 10.52,
        "总市值": 184348305.0,
        "市盈率": 32.95,
        "成交量": 504.0,
        "成交额": 5317.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PEGRU"
    },
    {
        "序号": 4659,
        "name": "精选国际酒店",
        "最新价": 112.62,
        "涨跌额": 0.32,
        "涨跌幅": 0.28,
        "开盘价": 112.53,
        "最高价": 113.15,
        "最低价": 112.31,
        "昨收价": 112.3,
        "总市值": 5608316755.0,
        "市盈率": 19.67,
        "成交量": 289299.0,
        "成交额": 32588266.0,
        "振幅": 0.75,
        "换手率": 0.58,
        "symbol": "106.CHH"
    },
    {
        "序号": 4660,
        "name": "Amplify International Enhanced ",
        "最新价": 28.16,
        "涨跌额": 0.08,
        "涨跌幅": 0.28,
        "开盘价": 28.1,
        "最高价": 28.22,
        "最低价": 28.05,
        "昨收价": 28.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 6978.0,
        "成交额": 196244.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.IDVO"
    },
    {
        "序号": 4661,
        "name": "Green Brick Partners Inc Series",
        "最新价": 17.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.28,
        "开盘价": 17.56,
        "最高价": 17.66,
        "最低价": 17.42,
        "昨收价": 17.55,
        "总市值": 35200.0,
        "市盈率": null,
        "成交量": 14054.0,
        "成交额": 246756.0,
        "振幅": 1.37,
        "换手率": 702.7,
        "symbol": "106.GRBK_A"
    },
    {
        "序号": 4662,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 35.21,
        "涨跌额": 0.1,
        "涨跌幅": 0.28,
        "开盘价": 35.12,
        "最高价": 35.21,
        "最低价": 35.09,
        "昨收价": 35.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 33003.0,
        "成交额": 1159171.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.PMAR"
    },
    {
        "序号": 4663,
        "name": "Cambria Trinity ETF",
        "最新价": 24.66,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 24.6,
        "最高价": 24.66,
        "最低价": 24.59,
        "昨收价": 24.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 1905.0,
        "成交额": 46862.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.TRTY"
    },
    {
        "序号": 4664,
        "name": "Global X PropTech ETF",
        "最新价": 28.2,
        "涨跌额": 0.08,
        "涨跌幅": 0.28,
        "开盘价": 28.2,
        "最高价": 28.2,
        "最低价": 28.2,
        "昨收价": 28.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 56.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PTEC"
    },
    {
        "序号": 4665,
        "name": "Hartford Multifactor Small Cap ",
        "最新价": 38.81,
        "涨跌额": 0.11,
        "涨跌幅": 0.28,
        "开盘价": 38.66,
        "最高价": 38.81,
        "最低价": 38.65,
        "昨收价": 38.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 621.0,
        "成交额": 24013.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.ROSC"
    },
    {
        "序号": 4666,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 38.81,
        "涨跌额": 0.11,
        "涨跌幅": 0.28,
        "开盘价": 38.63,
        "最高价": 38.89,
        "最低价": 38.63,
        "昨收价": 38.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 9147.0,
        "成交额": 354482.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.FJAN"
    },
    {
        "序号": 4667,
        "name": "Fidelity Blue Chip Value ETF",
        "最新价": 28.23,
        "涨跌额": 0.08,
        "涨跌幅": 0.28,
        "开盘价": 28.21,
        "最高价": 28.23,
        "最低价": 28.11,
        "昨收价": 28.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 8360.0,
        "成交额": 235416.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.FBCV"
    },
    {
        "序号": 4668,
        "name": "Fidelity Dividend ETF for Risin",
        "最新价": 42.38,
        "涨跌额": 0.12,
        "涨跌幅": 0.28,
        "开盘价": 42.17,
        "最高价": 42.49,
        "最低价": 42.17,
        "昨收价": 42.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 14904.0,
        "成交额": 630792.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.FDRR"
    },
    {
        "序号": 4669,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 35.33,
        "涨跌额": 0.1,
        "涨跌幅": 0.28,
        "开盘价": 35.25,
        "最高价": 35.36,
        "最低价": 35.23,
        "昨收价": 35.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 42147.0,
        "成交额": 1487525.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.PJUL"
    },
    {
        "序号": 4670,
        "name": "Nuveen ESG Dividend ETF",
        "最新价": 24.74,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 24.74,
        "最高价": 24.74,
        "最低价": 24.74,
        "昨收价": 24.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 202.0,
        "成交额": 4997.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NUDV"
    },
    {
        "序号": 4671,
        "name": "Arcosa Inc",
        "最新价": 77.77,
        "涨跌额": 0.22,
        "涨跌幅": 0.28,
        "开盘价": 77.48,
        "最高价": 78.4,
        "最低价": 76.76,
        "昨收价": 77.55,
        "总市值": 3792062556.0,
        "市盈率": 13.23,
        "成交量": 207121.0,
        "成交额": 16083715.0,
        "振幅": 2.11,
        "换手率": 0.42,
        "symbol": "106.ACA"
    },
    {
        "序号": 4672,
        "name": "Bellevue Life Sciences Acquisit",
        "最新价": 10.64,
        "涨跌额": 0.03,
        "涨跌幅": 0.28,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.61,
        "总市值": 96345200.0,
        "市盈率": 202.53,
        "成交量": 100.0,
        "成交额": 1064.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BLACU"
    },
    {
        "序号": 4673,
        "name": "世界全市场指数ETF-Vanguard",
        "最新价": 99.39,
        "涨跌额": 0.28,
        "涨跌幅": 0.28,
        "开盘价": 98.86,
        "最高价": 99.5,
        "最低价": 98.81,
        "昨收价": 99.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1528220.0,
        "成交额": 151634014.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.VT"
    },
    {
        "序号": 4674,
        "name": "GoPro Inc-A",
        "最新价": 3.55,
        "涨跌额": 0.01,
        "涨跌幅": 0.28,
        "开盘价": 3.52,
        "最高价": 3.58,
        "最低价": 3.51,
        "昨收价": 3.54,
        "总市值": 539742909.0,
        "市盈率": -11.32,
        "成交量": 1036317.0,
        "成交额": 3678106.0,
        "振幅": 1.98,
        "换手率": 0.68,
        "symbol": "105.GPRO"
    },
    {
        "序号": 4675,
        "name": "Innovator Growth Accelerated Pl",
        "最新价": 24.87,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 24.78,
        "最高价": 24.88,
        "最低价": 24.78,
        "昨收价": 24.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 15818.0,
        "成交额": 392766.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.QTOC"
    },
    {
        "序号": 4676,
        "name": "Global X S&P 500 Covered Call E",
        "最新价": 39.2,
        "涨跌额": 0.11,
        "涨跌幅": 0.28,
        "开盘价": 39.05,
        "最高价": 39.2,
        "最低价": 39.05,
        "昨收价": 39.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 400379.0,
        "成交额": 15669865.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.XYLD"
    },
    {
        "序号": 4677,
        "name": "Energy Transfer LP Series E Pfd",
        "最新价": 24.97,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 24.85,
        "最高价": 24.97,
        "最低价": 24.85,
        "昨收价": 24.9,
        "总市值": 799040000.0,
        "市盈率": null,
        "成交量": 21134.0,
        "成交额": 526267.0,
        "振幅": 0.48,
        "换手率": 0.07,
        "symbol": "106.ET_E"
    },
    {
        "序号": 4678,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 35.68,
        "涨跌额": 0.1,
        "涨跌幅": 0.28,
        "开盘价": 35.49,
        "最高价": 35.7,
        "最低价": 35.49,
        "昨收价": 35.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 8649.0,
        "成交额": 308407.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DSEP"
    },
    {
        "序号": 4679,
        "name": "Annaly Capital Management Inc S",
        "最新价": 24.99,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 24.86,
        "最高价": 25.03,
        "最低价": 24.86,
        "昨收价": 24.92,
        "总市值": 719712000.0,
        "市盈率": null,
        "成交量": 77788.0,
        "成交额": 1942147.0,
        "振幅": 0.68,
        "换手率": 0.27,
        "symbol": "106.NLY_F"
    },
    {
        "序号": 4680,
        "name": "做空高收益债ETF-ProShares",
        "最新价": 17.88,
        "涨跌额": 0.05,
        "涨跌幅": 0.28,
        "开盘价": 17.9,
        "最高价": 17.92,
        "最低价": 17.86,
        "昨收价": 17.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 246890.0,
        "成交额": 4415026.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.SJB"
    },
    {
        "序号": 4681,
        "name": "Global X NASDAQ 100 Tail Risk E",
        "最新价": 25.07,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 25.01,
        "最高价": 25.12,
        "最低价": 25.01,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 3101.0,
        "成交额": 77886.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.QTR"
    },
    {
        "序号": 4682,
        "name": "Applied Finance Valuation Large",
        "最新价": 28.7,
        "涨跌额": 0.08,
        "涨跌幅": 0.28,
        "开盘价": 28.6,
        "最高价": 28.74,
        "最低价": 28.6,
        "昨收价": 28.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 14932.0,
        "成交额": 427858.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.VSLU"
    },
    {
        "序号": 4683,
        "name": "VictoryShares US 500 Volatility",
        "最新价": 71.87,
        "涨跌额": 0.2,
        "涨跌幅": 0.28,
        "开盘价": 71.61,
        "最高价": 71.98,
        "最低价": 71.61,
        "昨收价": 71.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 11106.0,
        "成交额": 797763.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.CFA"
    },
    {
        "序号": 4684,
        "name": "Madison Covered Call & Equity S",
        "最新价": 7.2,
        "涨跌额": 0.02,
        "涨跌幅": 0.28,
        "开盘价": 7.18,
        "最高价": 7.28,
        "最低价": 7.15,
        "昨收价": 7.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 22229.0,
        "成交额": 160040.0,
        "振幅": 1.81,
        "换手率": null,
        "symbol": "106.MCN"
    },
    {
        "序号": 4685,
        "name": "ProShares Investment Grade-Inte",
        "最新价": 75.61,
        "涨跌额": 0.21,
        "涨跌幅": 0.28,
        "开盘价": 75.54,
        "最高价": 75.7,
        "最低价": 75.53,
        "昨收价": 75.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 21880.0,
        "成交额": 1654921.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.IGHG"
    },
    {
        "序号": 4686,
        "name": "Invesco Dynamic Pharmaceuticals",
        "最新价": 72.03,
        "涨跌额": 0.2,
        "涨跌幅": 0.28,
        "开盘价": 71.81,
        "最高价": 72.11,
        "最低价": 71.75,
        "昨收价": 71.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 6231.0,
        "成交额": 448346.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.PJP"
    },
    {
        "序号": 4687,
        "name": "Redwood Trust Inc",
        "最新价": 7.21,
        "涨跌额": 0.02,
        "涨跌幅": 0.28,
        "开盘价": 7.16,
        "最高价": 7.24,
        "最低价": 7.15,
        "昨收价": 7.19,
        "总市值": 855367737.0,
        "市盈率": -12.7,
        "成交量": 1737297.0,
        "成交额": 12511423.0,
        "振幅": 1.25,
        "换手率": 1.46,
        "symbol": "106.RWT"
    },
    {
        "序号": 4688,
        "name": "IQ Global Equity R&D Leaders ET",
        "最新价": 25.27,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 25.11,
        "最高价": 25.27,
        "最低价": 25.11,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 283.0,
        "成交额": 7106.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.WRND"
    },
    {
        "序号": 4689,
        "name": "唐纳森",
        "最新价": 61.43,
        "涨跌额": 0.17,
        "涨跌幅": 0.28,
        "开盘价": 61.26,
        "最高价": 61.91,
        "最低价": 61.26,
        "昨收价": 61.26,
        "总市值": 7379937464.0,
        "市盈率": 20.29,
        "成交量": 609910.0,
        "成交额": 37490307.0,
        "振幅": 1.06,
        "换手率": 0.51,
        "symbol": "106.DCI"
    },
    {
        "序号": 4690,
        "name": "Patterson-UTI Energy Inc",
        "最新价": 10.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.28,
        "开盘价": 10.99,
        "最高价": 11.11,
        "最低价": 10.82,
        "昨收价": 10.82,
        "总市值": 4527525574.0,
        "市盈率": 15.92,
        "成交量": 5715670.0,
        "成交额": 62349146.0,
        "振幅": 2.68,
        "换手率": 1.37,
        "symbol": "105.PTEN"
    },
    {
        "序号": 4691,
        "name": "AltEnergy Acquisition Corp-A",
        "最新价": 10.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.28,
        "开盘价": 10.76,
        "最高价": 10.85,
        "最低价": 10.76,
        "昨收价": 10.82,
        "总市值": 79503136.0,
        "市盈率": 12.95,
        "成交量": 8329.0,
        "成交额": 90352.0,
        "振幅": 0.83,
        "换手率": 0.11,
        "symbol": "105.AEAE"
    },
    {
        "序号": 4692,
        "name": "全球私募股权基金ETF-PowerShares",
        "最新价": 57.97,
        "涨跌额": 0.16,
        "涨跌幅": 0.28,
        "开盘价": 57.47,
        "最高价": 58.11,
        "最低价": 57.47,
        "昨收价": 57.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 33859.0,
        "成交额": 1955562.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.PSP"
    },
    {
        "序号": 4693,
        "name": "Northern Revival Acquisition Co",
        "最新价": 10.87,
        "涨跌额": 0.03,
        "涨跌幅": 0.28,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.87,
        "昨收价": 10.84,
        "总市值": 86391977.0,
        "市盈率": 61.43,
        "成交量": 26471.0,
        "成交额": 288496.0,
        "振幅": 0.28,
        "换手率": 0.33,
        "symbol": "105.NRAC"
    },
    {
        "序号": 4694,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 32.62,
        "涨跌额": 0.09,
        "涨跌幅": 0.28,
        "开盘价": 32.57,
        "最高价": 32.65,
        "最低价": 32.57,
        "昨收价": 32.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 10162.0,
        "成交额": 331329.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.JULT"
    },
    {
        "序号": 4695,
        "name": "Pacer Swan SOS Flex (October) E",
        "最新价": 25.38,
        "涨跌额": 0.07,
        "涨跌幅": 0.28,
        "开盘价": 25.35,
        "最高价": 25.42,
        "最低价": 25.35,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 243.0,
        "成交额": 6168.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.PSFO"
    },
    {
        "序号": 4696,
        "name": "哥伦布-麦金农",
        "最新价": 36.26,
        "涨跌额": 0.1,
        "涨跌幅": 0.28,
        "开盘价": 36.26,
        "最高价": 36.71,
        "最低价": 35.91,
        "昨收价": 36.16,
        "总市值": 1042042201.0,
        "市盈率": 20.43,
        "成交量": 97806.0,
        "成交额": 3555224.0,
        "振幅": 2.21,
        "换手率": 0.34,
        "symbol": "105.CMCO"
    },
    {
        "序号": 4697,
        "name": "iShares Core MSCI International",
        "最新价": 61.66,
        "涨跌额": 0.17,
        "涨跌幅": 0.28,
        "开盘价": 61.33,
        "最高价": 61.74,
        "最低价": 61.33,
        "昨收价": 61.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 482023.0,
        "成交额": 29679265.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.IDEV"
    },
    {
        "序号": 4698,
        "name": "Athena Technology Acquisition C",
        "最新价": 10.89,
        "涨跌额": 0.03,
        "涨跌幅": 0.28,
        "开盘价": 10.89,
        "最高价": 10.89,
        "最低价": 10.89,
        "昨收价": 10.86,
        "总市值": 131039795.0,
        "市盈率": 52.58,
        "成交量": 329.0,
        "成交额": 3582.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "107.ATEK"
    },
    {
        "序号": 4699,
        "name": "Invesco KBW Property & Casualty",
        "最新价": 90.82,
        "涨跌额": 0.25,
        "涨跌幅": 0.28,
        "开盘价": 90.92,
        "最高价": 90.93,
        "最低价": 90.4,
        "昨收价": 90.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 44543.0,
        "成交额": 4041884.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "105.KBWP"
    },
    {
        "序号": 4700,
        "name": "Varonis Systems Inc",
        "最新价": 43.62,
        "涨跌额": 0.12,
        "涨跌幅": 0.28,
        "开盘价": 43.34,
        "最高价": 43.67,
        "最低价": 43.0,
        "昨收价": 43.5,
        "总市值": 4752532390.0,
        "市盈率": -42.91,
        "成交量": 1017218.0,
        "成交额": 44293094.0,
        "振幅": 1.54,
        "换手率": 0.93,
        "symbol": "105.VRNS"
    },
    {
        "序号": 4701,
        "name": "爱依斯电力",
        "最新价": 18.22,
        "涨跌额": 0.05,
        "涨跌幅": 0.28,
        "开盘价": 18.15,
        "最高价": 18.26,
        "最低价": 18.03,
        "昨收价": 18.17,
        "总市值": 12200641018.0,
        "市盈率": -21.79,
        "成交量": 6169917.0,
        "成交额": 112155368.0,
        "振幅": 1.27,
        "换手率": 0.92,
        "symbol": "106.AES"
    },
    {
        "序号": 4702,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 32.82,
        "涨跌额": 0.09,
        "涨跌幅": 0.27,
        "开盘价": 32.71,
        "最高价": 32.82,
        "最低价": 32.71,
        "昨收价": 32.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 28217.0,
        "成交额": 925536.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.PJUN"
    },
    {
        "序号": 4703,
        "name": "哈门那",
        "最新价": 481.62,
        "涨跌额": 1.32,
        "涨跌幅": 0.27,
        "开盘价": 482.69,
        "最高价": 482.69,
        "最低价": 475.06,
        "昨收价": 480.3,
        "总市值": 59292584966.0,
        "市盈率": 19.67,
        "成交量": 1083135.0,
        "成交额": 519159216.0,
        "振幅": 1.59,
        "换手率": 0.88,
        "symbol": "106.HUM"
    },
    {
        "序号": 4704,
        "name": "TKB Critical Technologies 1-A",
        "最新价": 10.95,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 10.95,
        "最高价": 10.95,
        "最低价": 10.95,
        "昨收价": 10.92,
        "总市值": 86168134.0,
        "市盈率": 27.45,
        "成交量": 207900.0,
        "成交额": 2276505.0,
        "振幅": 0.0,
        "换手率": 2.64,
        "symbol": "105.USCT"
    },
    {
        "序号": 4705,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 25.55,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 25.49,
        "最高价": 25.55,
        "最低价": 25.48,
        "昨收价": 25.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 2265.0,
        "成交额": 57756.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.SEPW"
    },
    {
        "序号": 4706,
        "name": "The Korea Fund",
        "最新价": 21.91,
        "涨跌额": 0.06,
        "涨跌幅": 0.27,
        "开盘价": 21.82,
        "最高价": 21.92,
        "最低价": 21.82,
        "昨收价": 21.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 5754.0,
        "成交额": 125775.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "106.KF"
    },
    {
        "序号": 4707,
        "name": "联合健康",
        "最新价": 549.77,
        "涨跌额": 1.5,
        "涨跌幅": 0.27,
        "开盘价": 550.22,
        "最高价": 551.73,
        "最低价": 544.54,
        "昨收价": 548.27,
        "总市值": 508496178333.0,
        "市盈率": 23.45,
        "成交量": 2039910.0,
        "成交额": 1119258400.0,
        "振幅": 1.31,
        "换手率": 0.22,
        "symbol": "106.UNH"
    },
    {
        "序号": 4708,
        "name": "WisdomTree Japan Hedged SmallCa",
        "最新价": 29.42,
        "涨跌额": 0.08,
        "涨跌幅": 0.27,
        "开盘价": 29.18,
        "最高价": 29.43,
        "最低价": 29.16,
        "昨收价": 29.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 23570.0,
        "成交额": 689577.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "105.DXJS"
    },
    {
        "序号": 4709,
        "name": "喜满客影城",
        "最新价": 14.71,
        "涨跌额": 0.04,
        "涨跌幅": 0.27,
        "开盘价": 14.68,
        "最高价": 15.09,
        "最低价": 14.58,
        "昨收价": 14.67,
        "总市值": 1788928142.0,
        "市盈率": 16.73,
        "成交量": 3779404.0,
        "成交额": 55879946.0,
        "振幅": 3.48,
        "换手率": 3.11,
        "symbol": "106.CNK"
    },
    {
        "序号": 4710,
        "name": "泛生子",
        "最新价": 3.68,
        "涨跌额": 0.01,
        "涨跌幅": 0.27,
        "开盘价": 3.67,
        "最高价": 3.68,
        "最低价": 3.67,
        "昨收价": 3.67,
        "总市值": 113945857.0,
        "市盈率": -0.98,
        "成交量": 3913.0,
        "成交额": 14371.0,
        "振幅": 0.27,
        "换手率": 0.01,
        "symbol": "105.GTH"
    },
    {
        "序号": 4711,
        "name": "iShares Large Cap Deep Buffer E",
        "最新价": 25.77,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 25.78,
        "最高价": 25.78,
        "最低价": 25.77,
        "昨收价": 25.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 641.0,
        "成交额": 16524.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.IVVB"
    },
    {
        "序号": 4712,
        "name": "股息指数ETF-iShares",
        "最新价": 114.2,
        "涨跌额": 0.31,
        "涨跌幅": 0.27,
        "开盘价": 113.92,
        "最高价": 114.43,
        "最低价": 113.74,
        "昨收价": 113.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 566332.0,
        "成交额": 64597855.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.DVY"
    },
    {
        "序号": 4713,
        "name": "Vericity Inc",
        "最新价": 11.06,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.03,
        "最高价": 11.06,
        "最低价": 11.03,
        "昨收价": 11.03,
        "总市值": 164517500.0,
        "市盈率": -10.79,
        "成交量": 1228.0,
        "成交额": 13555.0,
        "振幅": 0.27,
        "换手率": 0.01,
        "symbol": "105.VERY"
    },
    {
        "序号": 4714,
        "name": "One Liberty Properties Inc",
        "最新价": 22.13,
        "涨跌额": 0.06,
        "涨跌幅": 0.27,
        "开盘价": 22.05,
        "最高价": 22.15,
        "最低价": 21.75,
        "昨收价": 22.07,
        "总市值": 466183742.0,
        "市盈率": 19.81,
        "成交量": 47820.0,
        "成交额": 1053264.0,
        "振幅": 1.81,
        "换手率": 0.23,
        "symbol": "106.OLP"
    },
    {
        "序号": 4715,
        "name": "Aprea Therapeutics Inc",
        "最新价": 3.69,
        "涨跌额": 0.01,
        "涨跌幅": 0.27,
        "开盘价": 3.75,
        "最高价": 3.81,
        "最低价": 3.58,
        "昨收价": 3.68,
        "总市值": 13788323.0,
        "市盈率": -1.04,
        "成交量": 5594.0,
        "成交额": 20744.0,
        "振幅": 6.25,
        "换手率": 0.15,
        "symbol": "105.APRE"
    },
    {
        "序号": 4716,
        "name": "GMO U.S. Quality ETF",
        "最新价": 25.84,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 25.76,
        "最高价": 25.86,
        "最低价": 25.69,
        "昨收价": 25.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 37899.0,
        "成交额": 976607.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.QLTY"
    },
    {
        "序号": 4717,
        "name": "MoonLake Immunotherapeutics-A",
        "最新价": 55.4,
        "涨跌额": 0.15,
        "涨跌幅": 0.27,
        "开盘价": 56.5,
        "最高价": 58.37,
        "最低价": 54.57,
        "昨收价": 55.25,
        "总市值": 3459508677.0,
        "市盈率": -75.74,
        "成交量": 544204.0,
        "成交额": 30618852.0,
        "振幅": 6.88,
        "换手率": 0.87,
        "symbol": "105.MLTX"
    },
    {
        "序号": 4718,
        "name": "三倍做多医疗ETF-Direxion",
        "最新价": 92.44,
        "涨跌额": 0.25,
        "涨跌幅": 0.27,
        "开盘价": 92.08,
        "最高价": 92.97,
        "最低价": 90.85,
        "昨收价": 92.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 14002.0,
        "成交额": 1289806.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.CURE"
    },
    {
        "序号": 4719,
        "name": "亚德诺",
        "最新价": 184.88,
        "涨跌额": 0.5,
        "涨跌幅": 0.27,
        "开盘价": 184.25,
        "最高价": 186.3,
        "最低价": 183.81,
        "昨收价": 184.38,
        "总市值": 91748859029.0,
        "市盈率": 27.68,
        "成交量": 2404187.0,
        "成交额": 445143152.0,
        "振幅": 1.35,
        "换手率": 0.48,
        "symbol": "105.ADI"
    },
    {
        "序号": 4720,
        "name": "Blueprint Medicines Corp",
        "最新价": 73.98,
        "涨跌额": 0.2,
        "涨跌幅": 0.27,
        "开盘价": 73.08,
        "最高价": 74.44,
        "最低价": 71.97,
        "昨收价": 73.78,
        "总市值": 4497222894.0,
        "市盈率": -8.11,
        "成交量": 1057990.0,
        "成交额": 77765605.0,
        "振幅": 3.35,
        "换手率": 1.74,
        "symbol": "105.BPMC"
    },
    {
        "序号": 4721,
        "name": "Sapiens International Corp NV",
        "最新价": 25.92,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 25.74,
        "最高价": 26.08,
        "最低价": 25.74,
        "昨收价": 25.85,
        "总市值": 1444586426.0,
        "市盈率": 24.55,
        "成交量": 80533.0,
        "成交额": 2088860.0,
        "振幅": 1.32,
        "换手率": 0.14,
        "symbol": "105.SPNS"
    },
    {
        "序号": 4722,
        "name": "HCM Acquisition Corp-A",
        "最新价": 11.12,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.12,
        "最高价": 11.12,
        "最低价": 11.12,
        "昨收价": 11.09,
        "总市值": 157257995.0,
        "市盈率": 39.27,
        "成交量": 279.0,
        "成交额": 3102.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.HCMA"
    },
    {
        "序号": 4723,
        "name": "CHS Inc Class B Series 2 Pfd",
        "最新价": 25.95,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 25.89,
        "最高价": 25.95,
        "最低价": 25.76,
        "昨收价": 25.88,
        "总市值": 435960000.0,
        "市盈率": null,
        "成交量": 15974.0,
        "成交额": 413969.0,
        "振幅": 0.73,
        "换手率": 0.1,
        "symbol": "105.CHSCN"
    },
    {
        "序号": 4724,
        "name": "ProShares Trust ProShares MSCI ",
        "最新价": 37.09,
        "涨跌额": 0.1,
        "涨跌幅": 0.27,
        "开盘价": 37.03,
        "最高价": 37.09,
        "最低价": 36.95,
        "昨收价": 36.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 4975.0,
        "成交额": 184279.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.EFAD"
    },
    {
        "序号": 4725,
        "name": "Dimensional International Value",
        "最新价": 33.39,
        "涨跌额": 0.09,
        "涨跌幅": 0.27,
        "开盘价": 33.19,
        "最高价": 33.44,
        "最低价": 33.18,
        "昨收价": 33.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 862616.0,
        "成交额": 28756007.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.DFIV"
    },
    {
        "序号": 4726,
        "name": "The TJX Companies Inc",
        "最新价": 89.13,
        "涨跌额": 0.24,
        "涨跌幅": 0.27,
        "开盘价": 89.03,
        "最高价": 89.3,
        "最低价": 88.43,
        "昨收价": 88.89,
        "总市值": 101579394610.0,
        "市盈率": 24.72,
        "成交量": 5124957.0,
        "成交额": 456041920.0,
        "振幅": 0.98,
        "换手率": 0.45,
        "symbol": "106.TJX"
    },
    {
        "序号": 4727,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 37.14,
        "涨跌额": 0.1,
        "涨跌幅": 0.27,
        "开盘价": 37.12,
        "最高价": 37.18,
        "最低价": 37.04,
        "昨收价": 37.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 3301.0,
        "成交额": 122499.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.FMAR"
    },
    {
        "序号": 4728,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 33.45,
        "涨跌额": 0.09,
        "涨跌幅": 0.27,
        "开盘价": 33.33,
        "最高价": 33.45,
        "最低价": 33.31,
        "昨收价": 33.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 1370.0,
        "成交额": 45661.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.OCTT"
    },
    {
        "序号": 4729,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 26.12,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.06,
        "最高价": 26.12,
        "最低价": 26.02,
        "昨收价": 26.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 4709.0,
        "成交额": 122623.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.XTOC"
    },
    {
        "序号": 4730,
        "name": "Schwab U.S. Large-Cap Value ETF",
        "最新价": 67.17,
        "涨跌额": 0.18,
        "涨跌幅": 0.27,
        "开盘价": 67.03,
        "最高价": 67.29,
        "最低价": 66.92,
        "昨收价": 66.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 447476.0,
        "成交额": 30042141.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.SCHV"
    },
    {
        "序号": 4731,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 29.88,
        "涨跌额": 0.08,
        "涨跌幅": 0.27,
        "开盘价": 29.81,
        "最高价": 29.88,
        "最低价": 29.81,
        "昨收价": 29.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 222.0,
        "成交额": 6617.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.XTAP"
    },
    {
        "序号": 4732,
        "name": "美国基础材料ETF-iShares",
        "最新价": 130.8,
        "涨跌额": 0.35,
        "涨跌幅": 0.27,
        "开盘价": 130.29,
        "最高价": 131.64,
        "最低价": 130.29,
        "昨收价": 130.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 13186.0,
        "成交额": 1726204.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.IYM"
    },
    {
        "序号": 4733,
        "name": "Ladder Capital Corp-A",
        "最新价": 11.22,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.16,
        "最高价": 11.3,
        "最低价": 11.14,
        "昨收价": 11.19,
        "总市值": 1423949151.0,
        "市盈率": 10.07,
        "成交量": 339115.0,
        "成交额": 3802539.0,
        "振幅": 1.43,
        "换手率": 0.27,
        "symbol": "106.LADR"
    },
    {
        "序号": 4734,
        "name": "FlexShares ESG & Climate US Lar",
        "最新价": 52.4,
        "涨跌额": 0.14,
        "涨跌幅": 0.27,
        "开盘价": 52.4,
        "最高价": 52.4,
        "最低价": 52.4,
        "昨收价": 52.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 5292.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FEUS"
    },
    {
        "序号": 4735,
        "name": "Cherry Hill Mortgage Investment",
        "最新价": 22.46,
        "涨跌额": 0.06,
        "涨跌幅": 0.27,
        "开盘价": 22.25,
        "最高价": 22.47,
        "最低价": 22.22,
        "昨收价": 22.4,
        "总市值": 44920000.0,
        "市盈率": null,
        "成交量": 8567.0,
        "成交额": 191907.0,
        "振幅": 1.12,
        "换手率": 0.43,
        "symbol": "106.CHMI_B"
    },
    {
        "序号": 4736,
        "name": "卡姆登物业信托",
        "最新价": 93.65,
        "涨跌额": 0.25,
        "涨跌幅": 0.27,
        "开盘价": 93.01,
        "最高价": 94.35,
        "最低价": 92.85,
        "昨收价": 93.4,
        "总市值": 9999123442.0,
        "市盈率": 44.11,
        "成交量": 1075360.0,
        "成交额": 100683984.0,
        "振幅": 1.61,
        "换手率": 1.01,
        "symbol": "106.CPT"
    },
    {
        "序号": 4737,
        "name": "Innovator Growth Accelerated ET",
        "最新价": 26.24,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.23,
        "最高价": 26.28,
        "最低价": 26.17,
        "昨收价": 26.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 6053.0,
        "成交额": 158764.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.XDQQ"
    },
    {
        "序号": 4738,
        "name": "Repay Holdings Corp-A",
        "最新价": 7.52,
        "涨跌额": 0.02,
        "涨跌幅": 0.27,
        "开盘价": 7.46,
        "最高价": 7.76,
        "最低价": 7.46,
        "昨收价": 7.5,
        "总市值": 711835530.0,
        "市盈率": -16.22,
        "成交量": 801280.0,
        "成交额": 6043999.0,
        "振幅": 4.0,
        "换手率": 0.85,
        "symbol": "105.RPAY"
    },
    {
        "序号": 4739,
        "name": "Ault Disruptive Technologies Co",
        "最新价": 11.28,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.06,
        "最高价": 11.33,
        "最低价": 11.06,
        "昨收价": 11.25,
        "总市值": 34560510.0,
        "市盈率": 22.51,
        "成交量": 1719.0,
        "成交额": 19337.0,
        "振幅": 2.4,
        "换手率": 0.06,
        "symbol": "107.ADRT"
    },
    {
        "序号": 4740,
        "name": "U-Haul Holding Co-N",
        "最新价": 56.41,
        "涨跌额": 0.15,
        "涨跌幅": 0.27,
        "开盘价": 56.18,
        "最高价": 57.34,
        "最低价": 56.18,
        "昨收价": 56.26,
        "总市值": 11060753211.0,
        "市盈率": 14.46,
        "成交量": 107369.0,
        "成交额": 6076333.0,
        "振幅": 2.06,
        "换手率": 0.06,
        "symbol": "106.UHAL_B"
    },
    {
        "序号": 4741,
        "name": "iShares Large Cap Moderate Buff",
        "最新价": 26.36,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.38,
        "最高价": 26.39,
        "最低价": 26.36,
        "昨收价": 26.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 473.0,
        "成交额": 12479.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.IVVM"
    },
    {
        "序号": 4742,
        "name": "First Trust High Income Long\/Sh",
        "最新价": 11.3,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.25,
        "最高价": 11.37,
        "最低价": 11.25,
        "昨收价": 11.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 52123.0,
        "成交额": 589239.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "106.FSD"
    },
    {
        "序号": 4743,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.4,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.4,
        "最高价": 26.4,
        "最低价": 26.4,
        "昨收价": 26.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 154.0,
        "成交额": 4066.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ITDI"
    },
    {
        "序号": 4744,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.4,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.27,
        "最高价": 26.4,
        "最低价": 26.27,
        "昨收价": 26.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 237.0,
        "成交额": 6225.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.ITDH"
    },
    {
        "序号": 4745,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.4,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.4,
        "最高价": 26.4,
        "最低价": 26.4,
        "昨收价": 26.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 13.0,
        "成交额": 343.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ITDG"
    },
    {
        "序号": 4746,
        "name": "Hartford Quality Value ETF",
        "最新价": 22.64,
        "涨跌额": 0.06,
        "涨跌幅": 0.27,
        "开盘价": 22.55,
        "最高价": 22.65,
        "最低价": 22.49,
        "昨收价": 22.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 3531.0,
        "成交额": 79705.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.QUVU"
    },
    {
        "序号": 4747,
        "name": "Panagram BBB-B CLO ETF",
        "最新价": 26.42,
        "涨跌额": 0.07,
        "涨跌幅": 0.27,
        "开盘价": 26.41,
        "最高价": 26.42,
        "最低价": 26.39,
        "昨收价": 26.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 15390.0,
        "成交额": 406366.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.CLOZ"
    },
    {
        "序号": 4748,
        "name": "贝壳",
        "最新价": 15.1,
        "涨跌额": 0.04,
        "涨跌幅": 0.27,
        "开盘价": 14.89,
        "最高价": 15.24,
        "最低价": 14.88,
        "昨收价": 15.06,
        "总市值": 18740684337.0,
        "市盈率": 24.17,
        "成交量": 4385434.0,
        "成交额": 66346579.0,
        "振幅": 2.39,
        "换手率": 0.35,
        "symbol": "106.BEKE"
    },
    {
        "序号": 4749,
        "name": "Veridien Climate Action ETF",
        "最新价": 18.88,
        "涨跌额": 0.05,
        "涨跌幅": 0.27,
        "开盘价": 18.76,
        "最高价": 18.88,
        "最低价": 18.76,
        "昨收价": 18.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 727.0,
        "成交额": 13637.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.CLIA"
    },
    {
        "序号": 4750,
        "name": "Theseus Pharmaceuticals Inc",
        "最新价": 3.78,
        "涨跌额": 0.01,
        "涨跌幅": 0.27,
        "开盘价": 3.73,
        "最高价": 3.82,
        "最低价": 3.73,
        "昨收价": 3.77,
        "总市值": 167189910.0,
        "市盈率": -2.95,
        "成交量": 153705.0,
        "成交额": 578363.0,
        "振幅": 2.39,
        "换手率": 0.35,
        "symbol": "105.THRX"
    },
    {
        "序号": 4751,
        "name": "First Trust Brazil AlphaDEX Fun",
        "最新价": 11.35,
        "涨跌额": 0.03,
        "涨跌幅": 0.27,
        "开盘价": 11.4,
        "最高价": 11.43,
        "最低价": 11.31,
        "昨收价": 11.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 2105.0,
        "成交额": 23958.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "105.FBZ"
    },
    {
        "序号": 4752,
        "name": "Cable One Inc",
        "最新价": 526.4,
        "涨跌额": 1.39,
        "涨跌幅": 0.26,
        "开盘价": 525.99,
        "最高价": 532.58,
        "最低价": 524.1,
        "昨收价": 525.01,
        "总市值": 2956647198.0,
        "市盈率": 39.46,
        "成交量": 50675.0,
        "成交额": 26728455.0,
        "振幅": 1.62,
        "换手率": 0.9,
        "symbol": "106.CABO"
    },
    {
        "序号": 4753,
        "name": "法拉盛金融",
        "最新价": 15.15,
        "涨跌额": 0.04,
        "涨跌幅": 0.26,
        "开盘价": 15.18,
        "最高价": 15.36,
        "最低价": 15.09,
        "昨收价": 15.11,
        "总市值": 437905069.0,
        "市盈率": 13.1,
        "成交量": 87771.0,
        "成交额": 1334948.0,
        "振幅": 1.79,
        "换手率": 0.3,
        "symbol": "105.FFIC"
    },
    {
        "序号": 4754,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 37.9,
        "涨跌额": 0.1,
        "涨跌幅": 0.26,
        "开盘价": 37.8,
        "最高价": 37.93,
        "最低价": 37.76,
        "昨收价": 37.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 28152.0,
        "成交额": 1065974.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.DNOV"
    },
    {
        "序号": 4755,
        "name": "BlackRock Science and Technolog",
        "最新价": 34.14,
        "涨跌额": 0.09,
        "涨跌幅": 0.26,
        "开盘价": 34.04,
        "最高价": 34.15,
        "最低价": 33.94,
        "昨收价": 34.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 77196.0,
        "成交额": 2628806.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "106.BST"
    },
    {
        "序号": 4756,
        "name": "Priority Income Fund Inc Series",
        "最新价": 22.8,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 22.75,
        "最高价": 22.83,
        "最低价": 22.64,
        "昨收价": 22.74,
        "总市值": 27268800.0,
        "市盈率": null,
        "成交量": 1450.0,
        "成交额": 32972.0,
        "振幅": 0.84,
        "换手率": 0.12,
        "symbol": "106.PRIF_H"
    },
    {
        "序号": 4757,
        "name": "Model N Inc",
        "最新价": 22.8,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 22.67,
        "最高价": 23.11,
        "最低价": 22.42,
        "昨收价": 22.74,
        "总市值": 883815985.0,
        "市盈率": -26.05,
        "成交量": 182985.0,
        "成交额": 4158557.0,
        "振幅": 3.03,
        "换手率": 0.47,
        "symbol": "106.MODN"
    },
    {
        "序号": 4758,
        "name": "大众仓储",
        "最新价": 273.81,
        "涨跌额": 0.72,
        "涨跌幅": 0.26,
        "开盘价": 272.7,
        "最高价": 275.43,
        "最低价": 271.49,
        "昨收价": 273.09,
        "总市值": 48145699517.0,
        "市盈率": 22.7,
        "成交量": 748966.0,
        "成交额": 204742402.0,
        "振幅": 1.44,
        "换手率": 0.43,
        "symbol": "106.PSA"
    },
    {
        "序号": 4759,
        "name": "Priority Income Fund Inc Series",
        "最新价": 22.87,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 22.94,
        "最高价": 23.07,
        "最低价": 22.85,
        "昨收价": 22.81,
        "总市值": 28208498.0,
        "市盈率": null,
        "成交量": 4050.0,
        "成交额": 92750.0,
        "振幅": 0.96,
        "换手率": 0.33,
        "symbol": "106.PRIF_F"
    },
    {
        "序号": 4760,
        "name": "VICI Properties Inc",
        "最新价": 30.51,
        "涨跌额": 0.08,
        "涨跌幅": 0.26,
        "开盘价": 30.43,
        "最高价": 30.68,
        "最低价": 30.26,
        "昨收价": 30.43,
        "总市值": 31563574524.0,
        "市盈率": 13.32,
        "成交量": 7503811.0,
        "成交额": 228793045.0,
        "振幅": 1.38,
        "换手率": 0.73,
        "symbol": "106.VICI"
    },
    {
        "序号": 4761,
        "name": "Nationwide Russell 2000 Risk-Ma",
        "最新价": 19.07,
        "涨跌额": 0.05,
        "涨跌幅": 0.26,
        "开盘价": 19.1,
        "最高价": 19.1,
        "最低价": 19.07,
        "昨收价": 19.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 574.0,
        "成交额": 10963.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.NTKI"
    },
    {
        "序号": 4762,
        "name": "Kovitz Core Equity ETF",
        "最新价": 19.07,
        "涨跌额": 0.05,
        "涨跌幅": 0.26,
        "开盘价": 19.01,
        "最高价": 19.1,
        "最低价": 19.0,
        "昨收价": 19.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 17146.0,
        "成交额": 327227.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.EQTY"
    },
    {
        "序号": 4763,
        "name": "Innovator Equity Managed Floor ",
        "最新价": 26.74,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 26.72,
        "最高价": 26.79,
        "最低价": 26.63,
        "昨收价": 26.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 27041.0,
        "成交额": 722848.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.SFLR"
    },
    {
        "序号": 4764,
        "name": "Ecofin Global Water ESG Fund",
        "最新价": 42.05,
        "涨跌额": 0.11,
        "涨跌幅": 0.26,
        "开盘价": 42.0,
        "最高价": 42.05,
        "最低价": 41.9,
        "昨收价": 41.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 2092.0,
        "成交额": 87828.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.EBLU"
    },
    {
        "序号": 4765,
        "name": "Level Four Large Cap Growth Act",
        "最新价": 26.78,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 26.77,
        "最高价": 26.81,
        "最低价": 26.67,
        "昨收价": 26.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 3457.0,
        "成交额": 92425.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.LGRO"
    },
    {
        "序号": 4766,
        "name": "Atlas Corp Series H Pfd",
        "最新价": 22.96,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 23.07,
        "最高价": 23.07,
        "最低价": 22.8,
        "昨收价": 22.9,
        "总市值": 207216411.0,
        "市盈率": null,
        "成交量": 25119.0,
        "成交额": 575094.0,
        "振幅": 1.18,
        "换手率": 0.28,
        "symbol": "106.ATCO_H"
    },
    {
        "序号": 4767,
        "name": "Mohr Growth ETF",
        "最新价": 19.17,
        "涨跌额": 0.05,
        "涨跌幅": 0.26,
        "开盘价": 19.11,
        "最高价": 19.2,
        "最低价": 19.06,
        "昨收价": 19.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 53291.0,
        "成交额": 1021474.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.MOHR"
    },
    {
        "序号": 4768,
        "name": "ClearBridge Focus Value ESG ETF",
        "最新价": 34.53,
        "涨跌额": 0.09,
        "涨跌幅": 0.26,
        "开盘价": 34.53,
        "最高价": 34.53,
        "最低价": 34.53,
        "昨收价": 34.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 345.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CFCV"
    },
    {
        "序号": 4769,
        "name": "SPDR MSCI World StrategicFactor",
        "最新价": 107.43,
        "涨跌额": 0.28,
        "涨跌幅": 0.26,
        "开盘价": 107.2,
        "最高价": 107.63,
        "最低价": 106.97,
        "昨收价": 107.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 8170.0,
        "成交额": 876289.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.QWLD"
    },
    {
        "序号": 4770,
        "name": "Alpha Architect Value Momentum ",
        "最新价": 23.08,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 22.99,
        "最高价": 23.08,
        "最低价": 22.96,
        "昨收价": 23.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2013.0,
        "成交额": 46274.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.VMOT"
    },
    {
        "序号": 4771,
        "name": "ProShares Russell 2000 Dividend",
        "最新价": 61.68,
        "涨跌额": 0.16,
        "涨跌幅": 0.26,
        "开盘价": 61.45,
        "最高价": 61.95,
        "最低价": 61.37,
        "昨收价": 61.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 46051.0,
        "成交额": 2836985.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.SMDV"
    },
    {
        "序号": 4772,
        "name": "SLR Investment Corp",
        "最新价": 15.43,
        "涨跌额": 0.04,
        "涨跌幅": 0.26,
        "开盘价": 15.39,
        "最高价": 15.47,
        "最低价": 15.38,
        "昨收价": 15.39,
        "总市值": 841778003.0,
        "市盈率": 11.73,
        "成交量": 200237.0,
        "成交额": 3088461.0,
        "振幅": 0.58,
        "换手率": 0.37,
        "symbol": "105.SLRC"
    },
    {
        "序号": 4773,
        "name": "AAR CORP",
        "最新价": 69.5,
        "涨跌额": 0.18,
        "涨跌幅": 0.26,
        "开盘价": 69.06,
        "最高价": 69.89,
        "最低价": 68.75,
        "昨收价": 69.32,
        "总市值": 2453002292.0,
        "市盈率": 36.67,
        "成交量": 211232.0,
        "成交额": 14648470.0,
        "振幅": 1.64,
        "换手率": 0.6,
        "symbol": "106.AIR"
    },
    {
        "序号": 4774,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 27.03,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 26.93,
        "最高价": 27.05,
        "最低价": 26.93,
        "昨收价": 26.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 17097.0,
        "成交额": 461621.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.UAPR"
    },
    {
        "序号": 4775,
        "name": "Onto Innovation Inc",
        "最新价": 139.2,
        "涨跌额": 0.36,
        "涨跌幅": 0.26,
        "开盘价": 138.8,
        "最高价": 141.46,
        "最低价": 137.16,
        "昨收价": 138.84,
        "总市值": 6832074226.0,
        "市盈率": 43.5,
        "成交量": 293079.0,
        "成交额": 40805156.0,
        "振幅": 3.1,
        "换手率": 0.6,
        "symbol": "106.ONTO"
    },
    {
        "序号": 4776,
        "name": "Nuveen AMT-Free Municipal Credi",
        "最新价": 11.6,
        "涨跌额": 0.03,
        "涨跌幅": 0.26,
        "开盘价": 11.55,
        "最高价": 11.62,
        "最低价": 11.54,
        "昨收价": 11.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 733455.0,
        "成交额": 8489305.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.NVG"
    },
    {
        "序号": 4777,
        "name": "波特兰通用电气",
        "最新价": 42.54,
        "涨跌额": 0.11,
        "涨跌幅": 0.26,
        "开盘价": 42.55,
        "最高价": 42.59,
        "最低价": 42.09,
        "昨收价": 42.43,
        "总市值": 4301810834.0,
        "市盈率": 20.39,
        "成交量": 728270.0,
        "成交额": 30876338.0,
        "振幅": 1.18,
        "换手率": 0.72,
        "symbol": "106.POR"
    },
    {
        "序号": 4778,
        "name": "Ballast Small\/Mid Cap ETF",
        "最新价": 34.81,
        "涨跌额": 0.09,
        "涨跌幅": 0.26,
        "开盘价": 34.71,
        "最高价": 34.98,
        "最低价": 34.7,
        "昨收价": 34.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 9073.0,
        "成交额": 315725.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.MGMT"
    },
    {
        "序号": 4779,
        "name": "SolarWinds Corp",
        "最新价": 11.62,
        "涨跌额": 0.03,
        "涨跌幅": 0.26,
        "开盘价": 11.56,
        "最高价": 11.7,
        "最低价": 11.46,
        "昨收价": 11.59,
        "总市值": 1927125872.0,
        "市盈率": -101.73,
        "成交量": 389913.0,
        "成交额": 4530042.0,
        "振幅": 2.07,
        "换手率": 0.24,
        "symbol": "106.SWI"
    },
    {
        "序号": 4780,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 30.99,
        "涨跌额": 0.08,
        "涨跌幅": 0.26,
        "开盘价": 30.95,
        "最高价": 31.02,
        "最低价": 30.93,
        "昨收价": 30.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 3370.0,
        "成交额": 104381.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.GSEP"
    },
    {
        "序号": 4781,
        "name": "Macquarie\/First Trust Global In",
        "最新价": 7.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.26,
        "开盘价": 7.71,
        "最高价": 7.78,
        "最低价": 7.68,
        "昨收价": 7.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 23391.0,
        "成交额": 180411.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "106.MFD"
    },
    {
        "序号": 4782,
        "name": "Eaton Vance Tax-Managed Global ",
        "最新价": 7.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.26,
        "开盘价": 7.7,
        "最高价": 7.76,
        "最低价": 7.69,
        "昨收价": 7.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 380359.0,
        "成交额": 2935499.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "106.ETW"
    },
    {
        "序号": 4783,
        "name": "Franklin U.S. Low Volatility ET",
        "最新价": 50.41,
        "涨跌额": 0.13,
        "涨跌幅": 0.26,
        "开盘价": 50.2,
        "最高价": 50.46,
        "最低价": 50.2,
        "昨收价": 50.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 14552.0,
        "成交额": 732487.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.FLLV"
    },
    {
        "序号": 4784,
        "name": "Nutrien Ltd",
        "最新价": 54.42,
        "涨跌额": 0.14,
        "涨跌幅": 0.26,
        "开盘价": 54.21,
        "最高价": 55.02,
        "最低价": 53.88,
        "昨收价": 54.28,
        "总市值": 26913266243.0,
        "市盈率": 12.24,
        "成交量": 899804.0,
        "成交额": 48914998.0,
        "振幅": 2.1,
        "换手率": 0.18,
        "symbol": "106.NTR"
    },
    {
        "序号": 4785,
        "name": "Residential REIT Income ETF",
        "最新价": 15.56,
        "涨跌额": 0.04,
        "涨跌幅": 0.26,
        "开盘价": 15.44,
        "最高价": 15.56,
        "最低价": 15.44,
        "昨收价": 15.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 217.0,
        "成交额": 3350.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.HAUS"
    },
    {
        "序号": 4786,
        "name": "北卡罗莱纳国民银行",
        "最新价": 27.31,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 27.5,
        "最高价": 27.5,
        "最低价": 27.3,
        "昨收价": 27.24,
        "总市值": 151360186.0,
        "市盈率": 9.31,
        "成交量": 2920.0,
        "成交额": 79792.0,
        "振幅": 0.73,
        "换手率": 0.05,
        "symbol": "105.PEBK"
    },
    {
        "序号": 4787,
        "name": "普尔斯玛特",
        "最新价": 70.25,
        "涨跌额": 0.18,
        "涨跌幅": 0.26,
        "开盘价": 69.78,
        "最高价": 70.58,
        "最低价": 69.72,
        "昨收价": 70.07,
        "总市值": 2118872492.0,
        "市盈率": 19.4,
        "成交量": 142360.0,
        "成交额": 9999887.0,
        "振幅": 1.23,
        "换手率": 0.47,
        "symbol": "105.PSMT"
    },
    {
        "序号": 4788,
        "name": "Wells Fargo & Co Series Y Pfd",
        "最新价": 23.42,
        "涨跌额": 0.06,
        "涨跌幅": 0.26,
        "开盘价": 23.3,
        "最高价": 23.42,
        "最低价": 23.27,
        "昨收价": 23.36,
        "总市值": 646392.0,
        "市盈率": null,
        "成交量": 33794.0,
        "成交额": 789307.0,
        "振幅": 0.64,
        "换手率": 122.44,
        "symbol": "106.WFC_Y"
    },
    {
        "序号": 4789,
        "name": "VictoryShares International Hig",
        "最新价": 31.23,
        "涨跌额": 0.08,
        "涨跌幅": 0.26,
        "开盘价": 31.18,
        "最高价": 31.23,
        "最低价": 31.15,
        "昨收价": 31.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3147.0,
        "成交额": 98093.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "105.CID"
    },
    {
        "序号": 4790,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 31.25,
        "涨跌额": 0.08,
        "涨跌幅": 0.26,
        "开盘价": 31.2,
        "最高价": 31.25,
        "最低价": 31.18,
        "昨收价": 31.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 6005.0,
        "成交额": 187313.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.UMAR"
    },
    {
        "序号": 4791,
        "name": "Saba Capital Income & Opportuni",
        "最新价": 7.82,
        "涨跌额": 0.02,
        "涨跌幅": 0.26,
        "开盘价": 7.76,
        "最高价": 7.84,
        "最低价": 7.76,
        "昨收价": 7.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 104669.0,
        "成交额": 816876.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.BRW"
    },
    {
        "序号": 4792,
        "name": "ClearBridge Dividend Strategy E",
        "最新价": 43.03,
        "涨跌额": 0.11,
        "涨跌幅": 0.26,
        "开盘价": 42.93,
        "最高价": 43.11,
        "最低价": 42.93,
        "昨收价": 42.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1896.0,
        "成交额": 81527.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "105.YLDE"
    },
    {
        "序号": 4793,
        "name": "Invesco ESG NASDAQ Next Gen 100",
        "最新价": 19.56,
        "涨跌额": 0.05,
        "涨跌幅": 0.26,
        "开盘价": 19.48,
        "最高价": 19.59,
        "最低价": 19.48,
        "昨收价": 19.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 57374.0,
        "成交额": 1122211.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.QQJG"
    },
    {
        "序号": 4794,
        "name": "Virtus Dividend, Interest & Pre",
        "最新价": 11.74,
        "涨跌额": 0.03,
        "涨跌幅": 0.26,
        "开盘价": 11.68,
        "最高价": 11.82,
        "最低价": 11.68,
        "昨收价": 11.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 189109.0,
        "成交额": 2223126.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "106.NFJ"
    },
    {
        "序号": 4795,
        "name": "WisdomTree International Equity",
        "最新价": 50.9,
        "涨跌额": 0.13,
        "涨跌幅": 0.26,
        "开盘价": 50.62,
        "最高价": 50.92,
        "最低价": 50.62,
        "昨收价": 50.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 18123.0,
        "成交额": 921027.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DWM"
    },
    {
        "序号": 4796,
        "name": "Chicago Atlantic Real Estate Fi",
        "最新价": 15.68,
        "涨跌额": 0.04,
        "涨跌幅": 0.26,
        "开盘价": 15.48,
        "最高价": 15.78,
        "最低价": 15.48,
        "昨收价": 15.64,
        "总市值": 285097539.0,
        "市盈率": 7.8,
        "成交量": 83593.0,
        "成交额": 1310393.0,
        "振幅": 1.92,
        "换手率": 0.46,
        "symbol": "105.REFI"
    },
    {
        "序号": 4797,
        "name": "Global X India Active ETF",
        "最新价": 27.44,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 27.45,
        "最高价": 27.48,
        "最低价": 27.43,
        "昨收价": 27.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 522.0,
        "成交额": 14328.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.NDIA"
    },
    {
        "序号": 4798,
        "name": "First Trust North American Ener",
        "最新价": 27.45,
        "涨跌额": 0.07,
        "涨跌幅": 0.26,
        "开盘价": 27.42,
        "最高价": 27.47,
        "最低价": 27.33,
        "昨收价": 27.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 166805.0,
        "成交额": 4572309.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.EMLP"
    },
    {
        "序号": 4799,
        "name": "VanEck Pharmaceutical ETF",
        "最新价": 78.43,
        "涨跌额": 0.2,
        "涨跌幅": 0.26,
        "开盘价": 78.14,
        "最高价": 78.5,
        "最低价": 78.05,
        "昨收价": 78.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 33124.0,
        "成交额": 2591448.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "105.PPH"
    },
    {
        "序号": 4800,
        "name": "Schwab International Equity ETF",
        "最新价": 35.3,
        "涨跌额": 0.09,
        "涨跌幅": 0.26,
        "开盘价": 35.11,
        "最高价": 35.36,
        "最低价": 35.11,
        "昨收价": 35.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 6908410.0,
        "成交额": 243591985.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.SCHF"
    },
    {
        "序号": 4801,
        "name": "Nuveen ESG Large-Cap Value ETF",
        "最新价": 35.32,
        "涨跌额": 0.09,
        "涨跌幅": 0.26,
        "开盘价": 35.2,
        "最高价": 35.38,
        "最低价": 35.13,
        "昨收价": 35.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 130787.0,
        "成交额": 4615516.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.NULV"
    },
    {
        "序号": 4802,
        "name": "SPDR S&P Global Natural Resourc",
        "最新价": 54.95,
        "涨跌额": 0.14,
        "涨跌幅": 0.26,
        "开盘价": 54.7,
        "最高价": 55.25,
        "最低价": 54.7,
        "昨收价": 54.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 391830.0,
        "成交额": 21549571.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.GNR"
    },
    {
        "序号": 4803,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 31.42,
        "涨跌额": 0.08,
        "涨跌幅": 0.26,
        "开盘价": 31.33,
        "最高价": 31.43,
        "最低价": 31.33,
        "昨收价": 31.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 22593.0,
        "成交额": 708923.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.UDEC"
    },
    {
        "序号": 4804,
        "name": "ProShares S&P 500 Ex-Technology",
        "最新价": 74.76,
        "涨跌额": 0.19,
        "涨跌幅": 0.25,
        "开盘价": 74.74,
        "最高价": 74.78,
        "最低价": 74.56,
        "昨收价": 74.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 1812.0,
        "成交额": 135401.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.SPXT"
    },
    {
        "序号": 4805,
        "name": "First Busey Corp",
        "最新价": 23.61,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 23.64,
        "最高价": 23.87,
        "最低价": 23.34,
        "昨收价": 23.55,
        "总市值": 1304662723.0,
        "市盈率": 9.94,
        "成交量": 141606.0,
        "成交额": 3347197.0,
        "振幅": 2.25,
        "换手率": 0.26,
        "symbol": "105.BUSE"
    },
    {
        "序号": 4806,
        "name": "皮博迪能源",
        "最新价": 23.65,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 23.59,
        "最高价": 23.91,
        "最低价": 23.55,
        "昨收价": 23.59,
        "总市值": 3100515000.0,
        "市盈率": 2.58,
        "成交量": 1775304.0,
        "成交额": 42059697.0,
        "振幅": 1.53,
        "换手率": 1.35,
        "symbol": "106.BTU"
    },
    {
        "序号": 4807,
        "name": "Gladstone Investment Corp Notes",
        "最新价": 23.69,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 23.49,
        "最高价": 23.75,
        "最低价": 23.49,
        "昨收价": 23.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 3027.0,
        "成交额": 71784.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "105.GAINZ"
    },
    {
        "序号": 4808,
        "name": "阿卡迪亚不动产信托",
        "最新价": 15.81,
        "涨跌额": 0.04,
        "涨跌幅": 0.25,
        "开盘价": 15.68,
        "最高价": 15.84,
        "最低价": 15.48,
        "昨收价": 15.77,
        "总市值": 1507342095.0,
        "市盈率": 59.83,
        "成交量": 654303.0,
        "成交额": 10287096.0,
        "振幅": 2.28,
        "换手率": 0.69,
        "symbol": "106.AKR"
    },
    {
        "序号": 4809,
        "name": "WesBanco Inc Series A Pfd",
        "最新价": 23.8,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 23.73,
        "最高价": 23.8,
        "最低价": 23.66,
        "昨收价": 23.74,
        "总市值": 3570000.0,
        "市盈率": null,
        "成交量": 3974.0,
        "成交额": 94398.0,
        "振幅": 0.59,
        "换手率": 2.65,
        "symbol": "105.WSBCP"
    },
    {
        "序号": 4810,
        "name": "Sandy Spring Bancorp Inc",
        "最新价": 23.81,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 23.82,
        "最高价": 24.08,
        "最低价": 23.45,
        "昨收价": 23.75,
        "总市值": 1068953712.0,
        "市盈率": 8.18,
        "成交量": 153086.0,
        "成交额": 3655059.0,
        "振幅": 2.65,
        "换手率": 0.34,
        "symbol": "105.SASR"
    },
    {
        "序号": 4811,
        "name": "WideOpenWest Inc",
        "最新价": 3.97,
        "涨跌额": 0.01,
        "涨跌幅": 0.25,
        "开盘价": 3.99,
        "最高价": 4.0,
        "最低价": 3.86,
        "昨收价": 3.96,
        "总市值": 332021684.0,
        "市盈率": -1.29,
        "成交量": 265448.0,
        "成交额": 1046729.0,
        "振幅": 3.54,
        "换手率": 0.32,
        "symbol": "106.WOW"
    },
    {
        "序号": 4812,
        "name": "JPMorgan Diversified Return U.S",
        "最新价": 99.26,
        "涨跌额": 0.25,
        "涨跌幅": 0.25,
        "开盘价": 99.16,
        "最高价": 99.42,
        "最低价": 98.9,
        "昨收价": 99.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 6231.0,
        "成交额": 617860.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.JPUS"
    },
    {
        "序号": 4813,
        "name": "BrandywineGLOBAL – Dynamic US ",
        "最新价": 11.93,
        "涨跌额": 0.03,
        "涨跌幅": 0.25,
        "开盘价": 11.92,
        "最高价": 11.93,
        "最低价": 11.92,
        "昨收价": 11.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 513.0,
        "成交额": 6114.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.DVAL"
    },
    {
        "序号": 4814,
        "name": "PIMCO Dynamic Income Strategy F",
        "最新价": 19.9,
        "涨跌额": 0.05,
        "涨跌幅": 0.25,
        "开盘价": 20.02,
        "最高价": 20.02,
        "最低价": 19.84,
        "昨收价": 19.85,
        "总市值": 99500.0,
        "市盈率": null,
        "成交量": 136650.0,
        "成交额": 2723146.0,
        "振幅": 0.91,
        "换手率": 2733.0,
        "symbol": "106.PDX"
    },
    {
        "序号": 4815,
        "name": "安德玛-C",
        "最新价": 7.97,
        "涨跌额": 0.02,
        "涨跌幅": 0.25,
        "开盘价": 7.92,
        "最高价": 8.07,
        "最低价": 7.88,
        "昨收价": 7.95,
        "总市值": 3492080781.0,
        "市盈率": 8.51,
        "成交量": 1735042.0,
        "成交额": 13835197.0,
        "振幅": 2.39,
        "换手率": 0.81,
        "symbol": "106.UA"
    },
    {
        "序号": 4816,
        "name": "Mastech Digital Inc",
        "最新价": 7.97,
        "涨跌额": 0.02,
        "涨跌幅": 0.25,
        "开盘价": 8.0,
        "最高价": 8.01,
        "最低价": 7.88,
        "昨收价": 7.95,
        "总市值": 92445130.0,
        "市盈率": -371.27,
        "成交量": 15689.0,
        "成交额": 124687.0,
        "振幅": 1.64,
        "换手率": 0.14,
        "symbol": "107.MHH"
    },
    {
        "序号": 4817,
        "name": "高股息指数ETF-Vanguard",
        "最新价": 107.6,
        "涨跌额": 0.27,
        "涨跌幅": 0.25,
        "开盘价": 107.32,
        "最高价": 107.86,
        "最低价": 107.18,
        "昨收价": 107.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 1165134.0,
        "成交额": 125306510.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.VYM"
    },
    {
        "序号": 4818,
        "name": "First Trust SSI Strategic Conve",
        "最新价": 31.93,
        "涨跌额": 0.08,
        "涨跌幅": 0.25,
        "开盘价": 31.86,
        "最高价": 32.11,
        "最低价": 31.83,
        "昨收价": 31.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 15655.0,
        "成交额": 500192.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.FCVT"
    },
    {
        "序号": 4819,
        "name": "全球医疗ETF-iShares",
        "最新价": 83.86,
        "涨跌额": 0.21,
        "涨跌幅": 0.25,
        "开盘价": 83.74,
        "最高价": 84.0,
        "最低价": 83.41,
        "昨收价": 83.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 76642.0,
        "成交额": 6417961.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.IXJ"
    },
    {
        "序号": 4820,
        "name": "AvePoint Inc-A",
        "最新价": 7.99,
        "涨跌额": 0.02,
        "涨跌幅": 0.25,
        "开盘价": 7.95,
        "最高价": 8.03,
        "最低价": 7.86,
        "昨收价": 7.97,
        "总市值": 1467457638.0,
        "市盈率": -37.91,
        "成交量": 468311.0,
        "成交额": 3739058.0,
        "振幅": 2.13,
        "换手率": 0.25,
        "symbol": "105.AVPT"
    },
    {
        "序号": 4821,
        "name": "Generation Income Properties In",
        "最新价": 4.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.25,
        "开盘价": 4.0,
        "最高价": 4.0,
        "最低价": 3.98,
        "昨收价": 3.99,
        "总市值": 10478028.0,
        "市盈率": -2.18,
        "成交量": 11966.0,
        "成交额": 47808.0,
        "振幅": 0.5,
        "换手率": 0.46,
        "symbol": "105.GIPR"
    },
    {
        "序号": 4822,
        "name": "Global X Nasdaq 100 Covered Cal",
        "最新价": 28.05,
        "涨跌额": 0.07,
        "涨跌幅": 0.25,
        "开盘价": 27.94,
        "最高价": 28.07,
        "最低价": 27.94,
        "昨收价": 27.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 14924.0,
        "成交额": 418137.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "105.QYLG"
    },
    {
        "序号": 4823,
        "name": "思科",
        "最新价": 48.38,
        "涨跌额": 0.12,
        "涨跌幅": 0.25,
        "开盘价": 48.28,
        "最高价": 48.59,
        "最低价": 48.21,
        "昨收价": 48.26,
        "总市值": 196590953205.0,
        "市盈率": 14.48,
        "成交量": 16039943.0,
        "成交额": 776743600.0,
        "振幅": 0.79,
        "换手率": 0.39,
        "symbol": "105.CSCO"
    },
    {
        "序号": 4824,
        "name": "Invesco Next Gen Connectivity E",
        "最新价": 84.91,
        "涨跌额": 0.21,
        "涨跌幅": 0.25,
        "开盘价": 84.91,
        "最高价": 84.91,
        "最低价": 84.91,
        "昨收价": 84.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 103.0,
        "成交额": 8745.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KNCT"
    },
    {
        "序号": 4825,
        "name": "American Century Quality Conver",
        "最新价": 40.44,
        "涨跌额": 0.1,
        "涨跌幅": 0.25,
        "开盘价": 40.46,
        "最高价": 40.46,
        "最低价": 40.44,
        "昨收价": 40.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 1300.0,
        "成交额": 52598.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.QCON"
    },
    {
        "序号": 4826,
        "name": "KB Home",
        "最新价": 56.65,
        "涨跌额": 0.14,
        "涨跌幅": 0.25,
        "开盘价": 55.73,
        "最高价": 56.92,
        "最低价": 55.45,
        "昨收价": 56.51,
        "总市值": 4493402825.0,
        "市盈率": 6.85,
        "成交量": 1222241.0,
        "成交额": 69131186.0,
        "振幅": 2.6,
        "换手率": 1.54,
        "symbol": "106.KBH"
    },
    {
        "序号": 4827,
        "name": "Pacer Swan SOS Moderate (July) ",
        "最新价": 24.36,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 24.34,
        "最高价": 24.36,
        "最低价": 24.26,
        "昨收价": 24.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 3559.0,
        "成交额": 86467.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.PSMJ"
    },
    {
        "序号": 4828,
        "name": "Arlington Asset Investment Corp",
        "最新价": 24.36,
        "涨跌额": 0.06,
        "涨跌幅": 0.25,
        "开盘价": 24.3,
        "最高价": 24.36,
        "最低价": 24.3,
        "昨收价": 24.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 2181.0,
        "成交额": 53088.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "106.AIC"
    },
    {
        "序号": 4829,
        "name": "iShares ESG MSCI EAFE ETF",
        "最新价": 73.25,
        "涨跌额": 0.18,
        "涨跌幅": 0.25,
        "开盘价": 72.85,
        "最高价": 73.38,
        "最低价": 72.85,
        "昨收价": 73.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 256016.0,
        "成交额": 18741075.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "105.ESGD"
    },
    {
        "序号": 4830,
        "name": "American Customer Satisfaction ",
        "最新价": 48.85,
        "涨跌额": 0.12,
        "涨跌幅": 0.25,
        "开盘价": 48.85,
        "最高价": 48.85,
        "最低价": 48.85,
        "昨收价": 48.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 195.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ACSI"
    },
    {
        "序号": 4831,
        "name": "英国ETF-iShares MSCI",
        "最新价": 32.58,
        "涨跌额": 0.08,
        "涨跌幅": 0.25,
        "开盘价": 32.43,
        "最高价": 32.66,
        "最低价": 32.43,
        "昨收价": 32.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 944451.0,
        "成交额": 30751134.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.EWU"
    },
    {
        "序号": 4832,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 32.6,
        "涨跌额": 0.08,
        "涨跌幅": 0.25,
        "开盘价": 32.47,
        "最高价": 32.62,
        "最低价": 32.46,
        "昨收价": 32.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 73669.0,
        "成交额": 2397101.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.PAUG"
    },
    {
        "序号": 4833,
        "name": "iShares ESG MSCI USA Leaders ET",
        "最新价": 81.51,
        "涨跌额": 0.2,
        "涨跌幅": 0.25,
        "开盘价": 81.01,
        "最高价": 81.64,
        "最低价": 81.01,
        "昨收价": 81.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 68762.0,
        "成交额": 5595673.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "105.SUSL"
    },
    {
        "序号": 4834,
        "name": "AlTi Global Inc-A",
        "最新价": 8.16,
        "涨跌额": 0.02,
        "涨跌幅": 0.25,
        "开盘价": 8.18,
        "最高价": 8.74,
        "最低价": 8.04,
        "昨收价": 8.14,
        "总市值": 962803467.0,
        "市盈率": 109.67,
        "成交量": 93902.0,
        "成交额": 783051.0,
        "振幅": 8.6,
        "换手率": 0.08,
        "symbol": "105.ALTI"
    },
    {
        "序号": 4835,
        "name": "伊坎企业",
        "最新价": 16.33,
        "涨跌额": 0.04,
        "涨跌幅": 0.25,
        "开盘价": 16.3,
        "最高价": 16.53,
        "最低价": 16.27,
        "昨收价": 16.29,
        "总市值": 6708412320.0,
        "市盈率": -8.39,
        "成交量": 531047.0,
        "成交额": 8723598.0,
        "振幅": 1.6,
        "换手率": 0.13,
        "symbol": "105.IEP"
    },
    {
        "序号": 4836,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 28.6,
        "涨跌额": 0.07,
        "涨跌幅": 0.25,
        "开盘价": 28.53,
        "最高价": 28.6,
        "最低价": 28.53,
        "昨收价": 28.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 7500.0,
        "成交额": 214209.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.NVBT"
    },
    {
        "序号": 4837,
        "name": "Adasina Social Justice All Cap ",
        "最新价": 16.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.25,
        "开盘价": 16.31,
        "最高价": 16.38,
        "最低价": 16.31,
        "昨收价": 16.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 23588.0,
        "成交额": 385189.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.JSTC"
    },
    {
        "序号": 4838,
        "name": "Vident U.S. Equity Strategy ETF",
        "最新价": 49.09,
        "涨跌额": 0.12,
        "涨跌幅": 0.25,
        "开盘价": 48.94,
        "最高价": 49.16,
        "最低价": 48.9,
        "昨收价": 48.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 4512.0,
        "成交额": 221332.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.VUSE"
    },
    {
        "序号": 4839,
        "name": "Imperial Petroleum Inc Series A",
        "最新价": 20.47,
        "涨跌额": 0.05,
        "涨跌幅": 0.24,
        "开盘价": 20.47,
        "最高价": 20.47,
        "最低价": 20.47,
        "昨收价": 20.42,
        "总市值": 16291623.0,
        "市盈率": null,
        "成交量": 306.0,
        "成交额": 6263.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.IMPPP"
    },
    {
        "序号": 4840,
        "name": "SPDR Portfolio Developed World ",
        "最新价": 32.82,
        "涨跌额": 0.08,
        "涨跌幅": 0.24,
        "开盘价": 32.64,
        "最高价": 32.88,
        "最低价": 32.6,
        "昨收价": 32.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 2060572.0,
        "成交额": 67544177.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.SPDW"
    },
    {
        "序号": 4841,
        "name": "Cedar Realty Trust Inc Series C",
        "最新价": 12.32,
        "涨跌额": 0.03,
        "涨跌幅": 0.24,
        "开盘价": 12.25,
        "最高价": 12.32,
        "最低价": 12.25,
        "昨收价": 12.29,
        "总市值": 61600000.0,
        "市盈率": null,
        "成交量": 9454.0,
        "成交额": 116162.0,
        "振幅": 0.57,
        "换手率": 0.19,
        "symbol": "106.CDR_C"
    },
    {
        "序号": 4842,
        "name": "Invesco S&P MidCap 400 Revenue ",
        "最新价": 98.57,
        "涨跌额": 0.24,
        "涨跌幅": 0.24,
        "开盘价": 98.15,
        "最高价": 99.13,
        "最低价": 98.15,
        "昨收价": 98.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 33395.0,
        "成交额": 3295314.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.RWK"
    },
    {
        "序号": 4843,
        "name": "Franklin FTSE United Kingdom ET",
        "最新价": 24.71,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 24.64,
        "最高价": 24.77,
        "最低价": 24.63,
        "昨收价": 24.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 41925.0,
        "成交额": 1034966.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.FLGB"
    },
    {
        "序号": 4844,
        "name": "Rareview Systematic Equity ETF",
        "最新价": 24.73,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 24.63,
        "最高价": 24.73,
        "最低价": 24.63,
        "昨收价": 24.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 1017.0,
        "成交额": 25094.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.RSEE"
    },
    {
        "序号": 4845,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 37.1,
        "涨跌额": 0.09,
        "涨跌幅": 0.24,
        "开盘价": 37.01,
        "最高价": 37.1,
        "最低价": 37.01,
        "昨收价": 37.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 2151.0,
        "成交额": 79739.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.DJUN"
    },
    {
        "序号": 4846,
        "name": "Merchants Bancorp Series A Pfd",
        "最新价": 24.8,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 24.66,
        "最高价": 24.8,
        "最低价": 24.66,
        "昨收价": 24.74,
        "总市值": 51628640.0,
        "市盈率": null,
        "成交量": 2472.0,
        "成交额": 61244.0,
        "振幅": 0.57,
        "换手率": 0.12,
        "symbol": "105.MBINP"
    },
    {
        "序号": 4847,
        "name": "Nuveen Global Net Zero Transiti",
        "最新价": 28.95,
        "涨跌额": 0.07,
        "涨跌幅": 0.24,
        "开盘价": 28.95,
        "最高价": 28.95,
        "最低价": 28.95,
        "昨收价": 28.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": 578.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NTZG"
    },
    {
        "序号": 4848,
        "name": "Dividend Performers ETF",
        "最新价": 16.55,
        "涨跌额": 0.04,
        "涨跌幅": 0.24,
        "开盘价": 16.55,
        "最高价": 16.55,
        "最低价": 16.55,
        "昨收价": 16.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 51.0,
        "成交额": 843.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IPDP"
    },
    {
        "序号": 4849,
        "name": "环汇",
        "最新价": 120.16,
        "涨跌额": 0.29,
        "涨跌幅": 0.24,
        "开盘价": 119.9,
        "最高价": 120.78,
        "最低价": 119.13,
        "昨收价": 119.87,
        "总市值": 31288287687.0,
        "市盈率": 35.79,
        "成交量": 1534159.0,
        "成交额": 184173030.0,
        "振幅": 1.38,
        "换手率": 0.59,
        "symbol": "106.GPN"
    },
    {
        "序号": 4850,
        "name": "Innovator U.S. Equity Buffer ET",
        "最新价": 37.31,
        "涨跌额": 0.09,
        "涨跌幅": 0.24,
        "开盘价": 37.27,
        "最高价": 37.37,
        "最低价": 37.18,
        "昨收价": 37.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 16351.0,
        "成交额": 609399.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.BDEC"
    },
    {
        "序号": 4851,
        "name": "Eaton Vance Tax-Advantaged Glob",
        "最新价": 16.65,
        "涨跌额": 0.04,
        "涨跌幅": 0.24,
        "开盘价": 16.61,
        "最高价": 16.66,
        "最低价": 16.59,
        "昨收价": 16.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 156793.0,
        "成交额": 2606299.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "106.ETG"
    },
    {
        "序号": 4852,
        "name": "Innovator International Develop",
        "最新价": 29.15,
        "涨跌额": 0.07,
        "涨跌幅": 0.24,
        "开盘价": 29.13,
        "最高价": 29.15,
        "最低价": 29.05,
        "昨收价": 29.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 3428.0,
        "成交额": 99831.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.IJAN"
    },
    {
        "序号": 4853,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 29.18,
        "涨跌额": 0.07,
        "涨跌幅": 0.24,
        "开盘价": 29.09,
        "最高价": 29.18,
        "最低价": 29.08,
        "昨收价": 29.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 23037.0,
        "成交额": 671011.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.APRW"
    },
    {
        "序号": 4854,
        "name": "陶氏杜邦",
        "最新价": 70.89,
        "涨跌额": 0.17,
        "涨跌幅": 0.24,
        "开盘价": 70.84,
        "最高价": 71.43,
        "最低价": 70.71,
        "昨收价": 70.72,
        "总市值": 30485712258.0,
        "市盈率": 6.53,
        "成交量": 2324024.0,
        "成交额": 165011249.0,
        "振幅": 1.02,
        "换手率": 0.54,
        "symbol": "106.DD"
    },
    {
        "序号": 4855,
        "name": "Reality Shares DIVCON Leaders D",
        "最新价": 58.39,
        "涨跌额": 0.14,
        "涨跌幅": 0.24,
        "开盘价": 58.39,
        "最高价": 58.42,
        "最低价": 58.25,
        "昨收价": 58.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 1161.0,
        "成交额": 67762.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.LEAD"
    },
    {
        "序号": 4856,
        "name": "公共服务企业",
        "最新价": 62.57,
        "涨跌额": 0.15,
        "涨跌幅": 0.24,
        "开盘价": 62.38,
        "最高价": 62.58,
        "最低价": 61.81,
        "昨收价": 62.42,
        "总市值": 31179525876.0,
        "市盈率": 11.12,
        "成交量": 2835032.0,
        "成交额": 176687324.0,
        "振幅": 1.23,
        "换手率": 0.57,
        "symbol": "106.PEG"
    },
    {
        "序号": 4857,
        "name": "Global X SuperDividend REIT ETF",
        "最新价": 20.88,
        "涨跌额": 0.05,
        "涨跌幅": 0.24,
        "开盘价": 20.8,
        "最高价": 20.94,
        "最低价": 20.61,
        "昨收价": 20.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 47890.0,
        "成交额": 994901.0,
        "振幅": 1.58,
        "换手率": null,
        "symbol": "105.SRET"
    },
    {
        "序号": 4858,
        "name": "Welltower Inc",
        "最新价": 87.72,
        "涨跌额": 0.21,
        "涨跌幅": 0.24,
        "开盘价": 87.0,
        "最高价": 88.04,
        "最低价": 86.81,
        "昨收价": 87.51,
        "总市值": 48550311294.0,
        "市盈率": 192.31,
        "成交量": 2072769.0,
        "成交额": 181415374.0,
        "振幅": 1.41,
        "换手率": 0.37,
        "symbol": "106.WELL"
    },
    {
        "序号": 4859,
        "name": "JP Morgan Alerian MLP Index ETN",
        "最新价": 25.1,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 25.07,
        "最高价": 25.23,
        "最低价": 25.01,
        "昨收价": 25.04,
        "总市值": 72564309359.0,
        "市盈率": 1.42,
        "成交量": 276641.0,
        "成交额": 6937448.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.AMJ"
    },
    {
        "序号": 4860,
        "name": "Invesco S&P SmallCap Materials ",
        "最新价": 67.03,
        "涨跌额": 0.16,
        "涨跌幅": 0.24,
        "开盘价": 67.55,
        "最高价": 67.55,
        "最低价": 67.03,
        "昨收价": 66.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 288.0,
        "成交额": 19454.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "105.PSCM"
    },
    {
        "序号": 4861,
        "name": "Eaton Vance Senior Floating-Rat",
        "最新价": 12.58,
        "涨跌额": 0.03,
        "涨跌幅": 0.24,
        "开盘价": 12.51,
        "最高价": 12.68,
        "最低价": 12.47,
        "昨收价": 12.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 88020.0,
        "成交额": 1104864.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "106.EFR"
    },
    {
        "序号": 4862,
        "name": "iShares Convertible Bond ETF",
        "最新价": 75.58,
        "涨跌额": 0.18,
        "涨跌幅": 0.24,
        "开盘价": 75.26,
        "最高价": 75.62,
        "最低价": 75.26,
        "昨收价": 75.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 336776.0,
        "成交额": 25436131.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.ICVT"
    },
    {
        "序号": 4863,
        "name": "Sachem Capital Corp Notes",
        "最新价": 25.2,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 25.2,
        "最高价": 25.22,
        "最低价": 25.17,
        "昨收价": 25.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 3362.0,
        "成交额": 84685.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.SCCB"
    },
    {
        "序号": 4864,
        "name": "Vanguard FTSE Developed Markets",
        "最新价": 46.29,
        "涨跌额": 0.11,
        "涨跌幅": 0.24,
        "开盘价": 46.06,
        "最高价": 46.38,
        "最低价": 46.04,
        "昨收价": 46.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 9501365.0,
        "成交额": 439323632.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.VEA"
    },
    {
        "序号": 4865,
        "name": "纽威品牌",
        "最新价": 8.42,
        "涨跌额": 0.02,
        "涨跌幅": 0.24,
        "开盘价": 8.37,
        "最高价": 8.53,
        "最低价": 8.24,
        "昨收价": 8.4,
        "总市值": 3487564000.0,
        "市盈率": -6.33,
        "成交量": 3893262.0,
        "成交额": 32685870.0,
        "振幅": 3.45,
        "换手率": 0.94,
        "symbol": "105.NWL"
    },
    {
        "序号": 4866,
        "name": "iShares iBonds Dec 2027 Term Mu",
        "最新价": 25.26,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 25.23,
        "最高价": 25.28,
        "最低价": 25.19,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 37585.0,
        "成交额": 947800.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.IBMP"
    },
    {
        "序号": 4867,
        "name": "Highland Income Fund",
        "最新价": 8.43,
        "涨跌额": 0.02,
        "涨跌幅": 0.24,
        "开盘价": 8.35,
        "最高价": 8.51,
        "最低价": 8.27,
        "昨收价": 8.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 201726.0,
        "成交额": 1694829.0,
        "振幅": 2.85,
        "换手率": null,
        "symbol": "106.HFRO"
    },
    {
        "序号": 4868,
        "name": "FT Cboe Vest Nasdaq-100 Buffer ",
        "最新价": 25.34,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 25.32,
        "最高价": 25.37,
        "最低价": 25.3,
        "昨收价": 25.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 3722.0,
        "成交额": 94231.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.QMAR"
    },
    {
        "序号": 4869,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 29.57,
        "涨跌额": 0.07,
        "涨跌幅": 0.24,
        "开盘价": 29.55,
        "最高价": 29.58,
        "最低价": 29.51,
        "昨收价": 29.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 12701.0,
        "成交额": 375308.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.UJUN"
    },
    {
        "序号": 4870,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 29.57,
        "涨跌额": 0.07,
        "涨跌幅": 0.24,
        "开盘价": 29.49,
        "最高价": 29.63,
        "最低价": 29.49,
        "昨收价": 29.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 6467.0,
        "成交额": 191157.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.UJUL"
    },
    {
        "序号": 4871,
        "name": "IQ Clean Oceans ETF",
        "最新价": 21.19,
        "涨跌额": 0.05,
        "涨跌幅": 0.24,
        "开盘价": 21.19,
        "最高价": 21.19,
        "最低价": 21.19,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 148.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OCEN"
    },
    {
        "序号": 4872,
        "name": "SPDR S&P Pharmaceuticals ETF",
        "最新价": 38.23,
        "涨跌额": 0.09,
        "涨跌幅": 0.24,
        "开盘价": 38.13,
        "最高价": 38.54,
        "最低价": 38.08,
        "昨收价": 38.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 33233.0,
        "成交额": 1270366.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.XPH"
    },
    {
        "序号": 4873,
        "name": "VanEck Inflation Allocation ETF",
        "最新价": 25.52,
        "涨跌额": 0.06,
        "涨跌幅": 0.24,
        "开盘价": 25.43,
        "最高价": 25.62,
        "最低价": 25.4,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 21646.0,
        "成交额": 551169.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.RAAX"
    },
    {
        "序号": 4874,
        "name": "中通快递",
        "最新价": 21.31,
        "涨跌额": 0.05,
        "涨跌幅": 0.24,
        "开盘价": 21.17,
        "最高价": 21.45,
        "最低价": 21.14,
        "昨收价": 21.26,
        "总市值": 17322188589.0,
        "市盈率": 14.01,
        "成交量": 2138910.0,
        "成交额": 45541880.0,
        "振幅": 1.46,
        "换手率": 0.26,
        "symbol": "106.ZTO"
    },
    {
        "序号": 4875,
        "name": "Elanco Animal Health Inc",
        "最新价": 12.8,
        "涨跌额": 0.03,
        "涨跌幅": 0.23,
        "开盘价": 12.77,
        "最高价": 12.96,
        "最低价": 12.59,
        "昨收价": 12.77,
        "总市值": 6307815846.0,
        "市盈率": -5.51,
        "成交量": 9822397.0,
        "成交额": 125371713.0,
        "振幅": 2.9,
        "换手率": 1.99,
        "symbol": "106.ELAN"
    },
    {
        "序号": 4876,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 34.16,
        "涨跌额": 0.08,
        "涨跌幅": 0.23,
        "开盘价": 34.04,
        "最高价": 34.19,
        "最低价": 34.04,
        "昨收价": 34.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 12865.0,
        "成交额": 439273.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.DJAN"
    },
    {
        "序号": 4877,
        "name": "Putnam PanAgora ESG Internation",
        "最新价": 21.36,
        "涨跌额": 0.05,
        "涨跌幅": 0.23,
        "开盘价": 21.32,
        "最高价": 21.36,
        "最低价": 21.32,
        "昨收价": 21.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 1432.0,
        "成交额": 30530.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.PPIE"
    },
    {
        "序号": 4878,
        "name": "Brookfield Property Partners LP",
        "最新价": 12.83,
        "涨跌额": 0.03,
        "涨跌幅": 0.23,
        "开盘价": 12.8,
        "最高价": 12.94,
        "最低价": 12.8,
        "昨收价": 12.8,
        "总市值": 94428800.0,
        "市盈率": null,
        "成交量": 18019.0,
        "成交额": 232149.0,
        "振幅": 1.09,
        "换手率": 0.24,
        "symbol": "105.BPYPP"
    },
    {
        "序号": 4879,
        "name": "Invesco Zacks Mid-Cap ETF",
        "最新价": 90.1,
        "涨跌额": 0.21,
        "涨跌幅": 0.23,
        "开盘价": 90.35,
        "最高价": 90.43,
        "最低价": 89.78,
        "昨收价": 89.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 5516.0,
        "成交额": 497178.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.CZA"
    },
    {
        "序号": 4880,
        "name": "Calamos Antetokounmpo Global Su",
        "最新价": 25.75,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 25.75,
        "最高价": 25.77,
        "最低价": 25.75,
        "昨收价": 25.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 691.0,
        "成交额": 17795.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.SROI"
    },
    {
        "序号": 4881,
        "name": "Riskified Ltd-A",
        "最新价": 4.31,
        "涨跌额": 0.01,
        "涨跌幅": 0.23,
        "开盘价": 4.26,
        "最高价": 4.39,
        "最低价": 4.23,
        "昨收价": 4.3,
        "总市值": 768363698.0,
        "市盈率": -11.45,
        "成交量": 639009.0,
        "成交额": 2745470.0,
        "振幅": 3.72,
        "换手率": 0.36,
        "symbol": "106.RSKD"
    },
    {
        "序号": 4882,
        "name": "Global X Dow 30 Covered Call & ",
        "最新价": 25.87,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 25.87,
        "最高价": 25.87,
        "最低价": 25.87,
        "昨收价": 25.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 19.0,
        "成交额": 491.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.DYLG"
    },
    {
        "序号": 4883,
        "name": "YieldMax NFLX Option Income Str",
        "最新价": 17.26,
        "涨跌额": 0.04,
        "涨跌幅": 0.23,
        "开盘价": 17.2,
        "最高价": 17.33,
        "最低价": 17.2,
        "昨收价": 17.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 27177.0,
        "成交额": 469908.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.NFLY"
    },
    {
        "序号": 4884,
        "name": "FT Cboe Vest Fund of Buffer ETF",
        "最新价": 25.9,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 25.82,
        "最高价": 25.94,
        "最低价": 25.81,
        "昨收价": 25.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 932327.0,
        "成交额": 24141541.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.BUFR"
    },
    {
        "序号": 4885,
        "name": "Zynex Inc",
        "最新价": 8.66,
        "涨跌额": 0.02,
        "涨跌幅": 0.23,
        "开盘价": 8.66,
        "最高价": 8.68,
        "最低价": 8.54,
        "昨收价": 8.64,
        "总市值": 293606709.0,
        "市盈率": 18.39,
        "成交量": 172818.0,
        "成交额": 1492369.0,
        "振幅": 1.62,
        "换手率": 0.51,
        "symbol": "105.ZYXI"
    },
    {
        "序号": 4886,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 34.64,
        "涨跌额": 0.08,
        "涨跌幅": 0.23,
        "开盘价": 34.55,
        "最高价": 34.7,
        "最低价": 34.55,
        "昨收价": 34.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 22685.0,
        "成交额": 785430.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.DAUG"
    },
    {
        "序号": 4887,
        "name": "泰勒科技",
        "最新价": 403.07,
        "涨跌额": 0.93,
        "涨跌幅": 0.23,
        "开盘价": 400.79,
        "最高价": 404.71,
        "最低价": 400.59,
        "昨收价": 402.14,
        "总市值": 16978995651.0,
        "市盈率": 107.4,
        "成交量": 160875.0,
        "成交额": 64858158.0,
        "振幅": 1.02,
        "换手率": 0.38,
        "symbol": "106.TYL"
    },
    {
        "序号": 4888,
        "name": "New Mountain Finance Corp Notes",
        "最新价": 26.04,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 25.97,
        "最高价": 26.05,
        "最低价": 25.85,
        "昨收价": 25.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 25926.0,
        "成交额": 672590.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "105.NMFCZ"
    },
    {
        "序号": 4889,
        "name": "艾茨克普",
        "最新价": 8.69,
        "涨跌额": 0.02,
        "涨跌幅": 0.23,
        "开盘价": 8.68,
        "最高价": 8.74,
        "最低价": 8.61,
        "昨收价": 8.67,
        "总市值": 475491277.0,
        "市盈率": 12.36,
        "成交量": 189406.0,
        "成交额": 1645882.0,
        "振幅": 1.5,
        "换手率": 0.35,
        "symbol": "105.EZPW"
    },
    {
        "序号": 4890,
        "name": "Capital Link Global Fintech Lea",
        "最新价": 30.42,
        "涨跌额": 0.07,
        "涨跌幅": 0.23,
        "开盘价": 30.4,
        "最高价": 30.5,
        "最低价": 30.4,
        "昨收价": 30.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 459.0,
        "成交额": 13972.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.KOIN"
    },
    {
        "序号": 4891,
        "name": "Morgan Stanley Series A Pfd",
        "最新价": 21.75,
        "涨跌额": 0.05,
        "涨跌幅": 0.23,
        "开盘价": 21.61,
        "最高价": 21.75,
        "最低价": 21.6,
        "昨收价": 21.7,
        "总市值": 957000.0,
        "市盈率": null,
        "成交量": 52673.0,
        "成交额": 1143258.0,
        "振幅": 0.69,
        "换手率": 119.71,
        "symbol": "106.MS_A"
    },
    {
        "序号": 4892,
        "name": "意法半导体",
        "最新价": 47.87,
        "涨跌额": 0.11,
        "涨跌幅": 0.23,
        "开盘价": 47.53,
        "最高价": 48.06,
        "最低价": 47.46,
        "昨收价": 47.76,
        "总市值": 43308291730.0,
        "市盈率": 9.88,
        "成交量": 2754036.0,
        "成交额": 131750387.0,
        "振幅": 1.26,
        "换手率": 0.3,
        "symbol": "106.STM"
    },
    {
        "序号": 4893,
        "name": "罗克韦尔自动化",
        "最新价": 278.53,
        "涨跌额": 0.64,
        "涨跌幅": 0.23,
        "开盘价": 277.61,
        "最高价": 280.6,
        "最低价": 276.69,
        "昨收价": 277.89,
        "总市值": 31939740616.0,
        "市盈率": 23.02,
        "成交量": 741020.0,
        "成交额": 206782039.0,
        "振幅": 1.41,
        "换手率": 0.65,
        "symbol": "106.ROK"
    },
    {
        "序号": 4894,
        "name": "BlackRock Advantage Large Cap I",
        "最新价": 26.16,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 26.08,
        "最高价": 26.2,
        "最低价": 26.07,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 6733.0,
        "成交额": 176022.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.BALI"
    },
    {
        "序号": 4895,
        "name": "AGNC Investment Corp Series F P",
        "最新价": 21.8,
        "涨跌额": 0.05,
        "涨跌幅": 0.23,
        "开盘价": 21.82,
        "最高价": 21.92,
        "最低价": 21.78,
        "昨收价": 21.75,
        "总市值": 501400.0,
        "市盈率": null,
        "成交量": 24361.0,
        "成交额": 532276.0,
        "振幅": 0.64,
        "换手率": 105.92,
        "symbol": "105.AGNCP"
    },
    {
        "序号": 4896,
        "name": "John Hancock Tax-Advantaged Glo",
        "最新价": 4.39,
        "涨跌额": 0.01,
        "涨跌幅": 0.23,
        "开盘价": 4.38,
        "最高价": 4.41,
        "最低价": 4.36,
        "昨收价": 4.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 20336.0,
        "成交额": 89308.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "106.HTY"
    },
    {
        "序号": 4897,
        "name": "Global X Emerging Markets Bond ",
        "最新价": 21.99,
        "涨跌额": 0.05,
        "涨跌幅": 0.23,
        "开盘价": 21.85,
        "最高价": 22.04,
        "最低价": 21.81,
        "昨收价": 21.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 28730.0,
        "成交额": 630493.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.EMBD"
    },
    {
        "序号": 4898,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.43,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 26.32,
        "最高价": 26.45,
        "最低价": 26.3,
        "昨收价": 26.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 2313.0,
        "成交额": 60911.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.ITDF"
    },
    {
        "序号": 4899,
        "name": "真泰克",
        "最新价": 30.88,
        "涨跌额": 0.07,
        "涨跌幅": 0.23,
        "开盘价": 30.72,
        "最高价": 31.06,
        "最低价": 30.52,
        "昨收价": 30.81,
        "总市值": 7202840010.0,
        "市盈率": 18.11,
        "成交量": 809721.0,
        "成交额": 24991631.0,
        "振幅": 1.75,
        "换手率": 0.35,
        "symbol": "105.GNTX"
    },
    {
        "序号": 4900,
        "name": "FT Cboe Vest Fund of Nasdaq-100",
        "最新价": 26.48,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 26.36,
        "最高价": 26.55,
        "最低价": 26.36,
        "昨收价": 26.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 86331.0,
        "成交额": 2285369.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.BUFQ"
    },
    {
        "序号": 4901,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 30.92,
        "涨跌额": 0.07,
        "涨跌幅": 0.23,
        "开盘价": 30.89,
        "最高价": 30.95,
        "最低价": 30.83,
        "昨收价": 30.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 21010.0,
        "成交额": 649118.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.PMAY"
    },
    {
        "序号": 4902,
        "name": "Virtus Private Credit Strategy ",
        "最新价": 22.09,
        "涨跌额": 0.05,
        "涨跌幅": 0.23,
        "开盘价": 22.04,
        "最高价": 22.09,
        "最低价": 22.04,
        "昨收价": 22.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 5300.0,
        "成交额": 116933.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.VPC"
    },
    {
        "序号": 4903,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 35.51,
        "涨跌额": 0.08,
        "涨跌幅": 0.23,
        "开盘价": 35.5,
        "最高价": 35.52,
        "最低价": 35.41,
        "昨收价": 35.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 4275.0,
        "成交额": 151721.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.DMAY"
    },
    {
        "序号": 4904,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 35.56,
        "涨跌额": 0.08,
        "涨跌幅": 0.23,
        "开盘价": 35.43,
        "最高价": 35.57,
        "最低价": 35.43,
        "昨收价": 35.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 25302.0,
        "成交额": 898508.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.POCT"
    },
    {
        "序号": 4905,
        "name": "Brandes U.S. Small-Mid Cap Valu",
        "最新价": 26.7,
        "涨跌额": 0.06,
        "涨跌幅": 0.23,
        "开盘价": 26.72,
        "最高价": 26.72,
        "最低价": 26.6,
        "昨收价": 26.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 8297.0,
        "成交额": 220735.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.BSMC"
    },
    {
        "序号": 4906,
        "name": "康捷国际物流",
        "最新价": 120.37,
        "涨跌额": 0.27,
        "涨跌幅": 0.22,
        "开盘价": 119.93,
        "最高价": 120.89,
        "最低价": 119.49,
        "昨收价": 120.1,
        "总市值": 17500518587.0,
        "市盈率": 21.51,
        "成交量": 1238847.0,
        "成交额": 148960750.0,
        "振幅": 1.17,
        "换手率": 0.85,
        "symbol": "106.EXPD"
    },
    {
        "序号": 4907,
        "name": "哈克特集团",
        "最新价": 22.35,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 22.22,
        "最高价": 22.5,
        "最低价": 22.19,
        "昨收价": 22.3,
        "总市值": 608758170.0,
        "市盈率": 16.9,
        "成交量": 70254.0,
        "成交额": 1569348.0,
        "振幅": 1.39,
        "换手率": 0.26,
        "symbol": "105.HCKT"
    },
    {
        "序号": 4908,
        "name": "股利增长指数ETF-Vanguard",
        "最新价": 165.45,
        "涨跌额": 0.37,
        "涨跌幅": 0.22,
        "开盘价": 165.04,
        "最高价": 165.66,
        "最低价": 164.62,
        "昨收价": 165.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1406145.0,
        "成交额": 232253205.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.VIG"
    },
    {
        "序号": 4909,
        "name": "Brookfield Reinsurance Ltd-A-1",
        "最新价": 35.91,
        "涨跌额": 0.08,
        "涨跌幅": 0.22,
        "开盘价": 36.08,
        "最高价": 36.2,
        "最低价": 35.89,
        "昨收价": 35.83,
        "总市值": 5477414640.0,
        "市盈率": 10.68,
        "成交量": 2129.0,
        "成交额": 76835.0,
        "振幅": 0.87,
        "换手率": 0.01,
        "symbol": "106.BNRE_A"
    },
    {
        "序号": 4910,
        "name": "Alkami Technology Inc",
        "最新价": 22.49,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 22.39,
        "最高价": 22.57,
        "最低价": 21.92,
        "昨收价": 22.44,
        "总市值": 2144686590.0,
        "市盈率": -38.92,
        "成交量": 487125.0,
        "成交额": 10833328.0,
        "振幅": 2.9,
        "换手率": 0.51,
        "symbol": "105.ALKT"
    },
    {
        "序号": 4911,
        "name": "百威英博",
        "最新价": 63.02,
        "涨跌额": 0.14,
        "涨跌幅": 0.22,
        "开盘价": 62.98,
        "最高价": 63.29,
        "最低价": 62.93,
        "昨收价": 62.88,
        "总市值": 125206507815.0,
        "市盈率": 19.9,
        "成交量": 899142.0,
        "成交额": 56702788.0,
        "振幅": 0.57,
        "换手率": 0.05,
        "symbol": "106.BUD"
    },
    {
        "序号": 4912,
        "name": "Franklin FTSE Germany ETF",
        "最新价": 22.59,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 22.57,
        "最高价": 22.59,
        "最低价": 22.53,
        "昨收价": 22.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 2385.0,
        "成交额": 53820.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.FLGR"
    },
    {
        "序号": 4913,
        "name": "Stellantis集团",
        "最新价": 22.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 22.5,
        "最高价": 22.73,
        "最低价": 22.5,
        "昨收价": 22.55,
        "总市值": 70426938911.0,
        "市盈率": 3.28,
        "成交量": 4611818.0,
        "成交额": 104404748.0,
        "振幅": 1.02,
        "换手率": 0.15,
        "symbol": "106.STLA"
    },
    {
        "序号": 4914,
        "name": "American Century Quality Divers",
        "最新价": 45.3,
        "涨跌额": 0.1,
        "涨跌幅": 0.22,
        "开盘价": 45.14,
        "最高价": 45.34,
        "最低价": 45.09,
        "昨收价": 45.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 21459.0,
        "成交额": 970541.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.QINT"
    },
    {
        "序号": 4915,
        "name": "Nuveen Dividend Growth ETF",
        "最新价": 27.18,
        "涨跌额": 0.06,
        "涨跌幅": 0.22,
        "开盘价": 27.12,
        "最高价": 27.23,
        "最低价": 27.12,
        "昨收价": 27.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 3032.0,
        "成交额": 82434.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.NDVG"
    },
    {
        "序号": 4916,
        "name": "Innovator Growth Accelerated Pl",
        "最新价": 31.72,
        "涨跌额": 0.07,
        "涨跌幅": 0.22,
        "开盘价": 31.65,
        "最高价": 31.73,
        "最低价": 31.65,
        "昨收价": 31.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 3062.0,
        "成交额": 97029.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.QTAP"
    },
    {
        "序号": 4917,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 31.82,
        "涨跌额": 0.07,
        "涨跌幅": 0.22,
        "开盘价": 31.7,
        "最高价": 31.82,
        "最低价": 31.7,
        "昨收价": 31.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 8314.0,
        "成交额": 264268.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.UOCT"
    },
    {
        "序号": 4918,
        "name": "First Trust International Equit",
        "最新价": 40.93,
        "涨跌额": 0.09,
        "涨跌幅": 0.22,
        "开盘价": 40.63,
        "最高价": 40.93,
        "最低价": 40.63,
        "昨收价": 40.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 7598.0,
        "成交额": 310271.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "105.FPXI"
    },
    {
        "序号": 4919,
        "name": "Inventiva SA ADR",
        "最新价": 4.55,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.55,
        "最高价": 4.55,
        "最低价": 4.55,
        "昨收价": 4.54,
        "总市值": 191303822.0,
        "市盈率": -2.19,
        "成交量": 2685.0,
        "成交额": 12216.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.IVA"
    },
    {
        "序号": 4920,
        "name": "信息服务",
        "最新价": 4.55,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.49,
        "最高价": 4.63,
        "最低价": 4.47,
        "昨收价": 4.54,
        "总市值": 222374725.0,
        "市盈率": 16.71,
        "成交量": 66246.0,
        "成交额": 302683.0,
        "振幅": 3.52,
        "换手率": 0.14,
        "symbol": "105.III"
    },
    {
        "序号": 4921,
        "name": "Gitlab Inc-A",
        "最新价": 59.2,
        "涨跌额": 0.13,
        "涨跌幅": 0.22,
        "开盘价": 57.9,
        "最高价": 59.49,
        "最低价": 57.21,
        "昨收价": 59.07,
        "总市值": 9217440000.0,
        "市盈率": -21.61,
        "成交量": 2642835.0,
        "成交额": 154504634.0,
        "振幅": 3.86,
        "换手率": 1.7,
        "symbol": "105.GTLB"
    },
    {
        "序号": 4922,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 31.89,
        "涨跌额": 0.07,
        "涨跌幅": 0.22,
        "开盘价": 31.85,
        "最高价": 31.9,
        "最低价": 31.84,
        "昨收价": 31.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 7153.0,
        "成交额": 227951.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.GOCT"
    },
    {
        "序号": 4923,
        "name": "盖尔道钢铁",
        "最新价": 4.56,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.49,
        "最高价": 4.61,
        "最低价": 4.49,
        "昨收价": 4.55,
        "总市值": 7976947536.0,
        "市盈率": 4.9,
        "成交量": 3262812.0,
        "成交额": 14893549.0,
        "振幅": 2.64,
        "换手率": 0.19,
        "symbol": "106.GGB"
    },
    {
        "序号": 4924,
        "name": "Invesco S&P 500 Equal Weight In",
        "最新价": 41.06,
        "涨跌额": 0.09,
        "涨跌幅": 0.22,
        "开盘价": 41.05,
        "最高价": 41.29,
        "最低价": 41.0,
        "昨收价": 40.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 37516.0,
        "成交额": 1541693.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.RSPN"
    },
    {
        "序号": 4925,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 27.46,
        "涨跌额": 0.06,
        "涨跌幅": 0.22,
        "开盘价": 27.29,
        "最高价": 27.47,
        "最低价": 27.29,
        "昨收价": 27.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 10018.0,
        "成交额": 274733.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.XBOC"
    },
    {
        "序号": 4926,
        "name": "Iteris Inc",
        "最新价": 4.58,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.45,
        "最高价": 4.62,
        "最低价": 4.36,
        "昨收价": 4.57,
        "总市值": 196128438.0,
        "市盈率": 2362.99,
        "成交量": 78394.0,
        "成交额": 355369.0,
        "振幅": 5.69,
        "换手率": 0.18,
        "symbol": "105.ITI"
    },
    {
        "序号": 4927,
        "name": "Cerevel Therapeutics Holdings I",
        "最新价": 41.22,
        "涨跌额": 0.09,
        "涨跌幅": 0.22,
        "开盘价": 41.13,
        "最高价": 41.4,
        "最低价": 41.1,
        "昨收价": 41.13,
        "总市值": 7445751246.0,
        "市盈率": -18.95,
        "成交量": 8516027.0,
        "成交额": 351223616.0,
        "振幅": 0.73,
        "换手率": 4.71,
        "symbol": "105.CERE"
    },
    {
        "序号": 4928,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 32.08,
        "涨跌额": 0.07,
        "涨跌幅": 0.22,
        "开盘价": 31.98,
        "最高价": 32.15,
        "最低价": 31.98,
        "昨收价": 32.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 41209.0,
        "成交额": 1320331.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.PAPR"
    },
    {
        "序号": 4929,
        "name": "LXP Industrial Trust",
        "最新价": 9.19,
        "涨跌额": 0.02,
        "涨跌幅": 0.22,
        "开盘价": 9.11,
        "最高价": 9.21,
        "最低价": 9.06,
        "昨收价": 9.17,
        "总市值": 2689311744.0,
        "市盈率": 49.57,
        "成交量": 1545711.0,
        "成交额": 14147433.0,
        "振幅": 1.64,
        "换手率": 0.53,
        "symbol": "106.LXP"
    },
    {
        "序号": 4930,
        "name": "Clough Global Opportunities Fun",
        "最新价": 4.6,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.57,
        "最高价": 4.61,
        "最低价": 4.57,
        "昨收价": 4.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 82877.0,
        "成交额": 380795.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.GLO"
    },
    {
        "序号": 4931,
        "name": "abrdn Global Dynamic Dividend F",
        "最新价": 9.2,
        "涨跌额": 0.02,
        "涨跌幅": 0.22,
        "开盘价": 9.19,
        "最高价": 9.2,
        "最低价": 9.16,
        "昨收价": 9.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 73698.0,
        "成交额": 676206.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "106.AGD"
    },
    {
        "序号": 4932,
        "name": "Citi Trends Inc",
        "最新价": 23.02,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 22.81,
        "最高价": 23.02,
        "最低价": 22.27,
        "昨收价": 22.97,
        "总市值": 196690822.0,
        "市盈率": -22.12,
        "成交量": 58141.0,
        "成交额": 1318388.0,
        "振幅": 3.27,
        "换手率": 0.68,
        "symbol": "105.CTRN"
    },
    {
        "序号": 4933,
        "name": "Alpha Metallurgical Resources I",
        "最新价": 299.32,
        "涨跌额": 0.65,
        "涨跌幅": 0.22,
        "开盘价": 298.11,
        "最高价": 302.62,
        "最低价": 298.0,
        "昨收价": 298.67,
        "总市值": 3976045356.0,
        "市盈率": 5.19,
        "成交量": 129932.0,
        "成交额": 38907303.0,
        "振幅": 1.55,
        "换手率": 0.98,
        "symbol": "106.AMR"
    },
    {
        "序号": 4934,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 36.85,
        "涨跌额": 0.08,
        "涨跌幅": 0.22,
        "开盘价": 36.79,
        "最高价": 36.88,
        "最低价": 36.79,
        "昨收价": 36.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 95013.0,
        "成交额": 3501988.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.PJAN"
    },
    {
        "序号": 4935,
        "name": "ALPS Clean Energy ETF",
        "最新价": 32.25,
        "涨跌额": 0.07,
        "涨跌幅": 0.22,
        "开盘价": 32.18,
        "最高价": 32.78,
        "最低价": 31.85,
        "昨收价": 32.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 82437.0,
        "成交额": 2646447.0,
        "振幅": 2.89,
        "换手率": null,
        "symbol": "107.ACES"
    },
    {
        "序号": 4936,
        "name": "SPDR Russell 1000 Low Volatilit",
        "最新价": 110.6,
        "涨跌额": 0.24,
        "涨跌幅": 0.22,
        "开盘价": 110.31,
        "最高价": 110.7,
        "最低价": 110.25,
        "昨收价": 110.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 4985.0,
        "成交额": 550695.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.ONEV"
    },
    {
        "序号": 4937,
        "name": "Two Harbors Investment Corp",
        "最新价": 13.83,
        "涨跌额": 0.03,
        "涨跌幅": 0.22,
        "开盘价": 13.71,
        "最高价": 13.96,
        "最低价": 13.71,
        "昨收价": 13.8,
        "总市值": 1330278270.0,
        "市盈率": 23.5,
        "成交量": 1100587.0,
        "成交额": 15234531.0,
        "振幅": 1.81,
        "换手率": 1.14,
        "symbol": "106.TWO"
    },
    {
        "序号": 4938,
        "name": "Innovator Power Buffer Step-Up ",
        "最新价": 27.66,
        "涨跌额": 0.06,
        "涨跌幅": 0.22,
        "开盘价": 27.62,
        "最高价": 27.66,
        "最低价": 27.6,
        "昨收价": 27.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 127721.0,
        "成交额": 3527364.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.PSTP"
    },
    {
        "序号": 4939,
        "name": "Fidelity MSCI Materials Index E",
        "最新价": 46.18,
        "涨跌额": 0.1,
        "涨跌幅": 0.22,
        "开盘价": 45.98,
        "最高价": 46.42,
        "最低价": 45.98,
        "昨收价": 46.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 76290.0,
        "成交额": 3523184.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.FMAT"
    },
    {
        "序号": 4940,
        "name": "WisdomTree Alternative Income F",
        "最新价": 18.49,
        "涨跌额": 0.04,
        "涨跌幅": 0.22,
        "开盘价": 18.5,
        "最高价": 18.52,
        "最低价": 18.47,
        "昨收价": 18.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 4241.0,
        "成交额": 78460.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.HYIN"
    },
    {
        "序号": 4941,
        "name": "农业ETF-VanEck Vectors",
        "最新价": 73.99,
        "涨跌额": 0.16,
        "涨跌幅": 0.22,
        "开盘价": 73.69,
        "最高价": 74.27,
        "最低价": 73.64,
        "昨收价": 73.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 37626.0,
        "成交额": 2781154.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.MOO"
    },
    {
        "序号": 4942,
        "name": "Bloom Energy Corp-A",
        "最新价": 13.91,
        "涨跌额": 0.03,
        "涨跌幅": 0.22,
        "开盘价": 13.79,
        "最高价": 14.13,
        "最低价": 13.61,
        "昨收价": 13.88,
        "总市值": 3116518377.0,
        "市盈率": -8.81,
        "成交量": 4092270.0,
        "成交额": 56714598.0,
        "振幅": 3.75,
        "换手率": 1.83,
        "symbol": "106.BE"
    },
    {
        "序号": 4943,
        "name": "iShares iBonds 2024 Term High Y",
        "最新价": 23.19,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 23.25,
        "最高价": 23.26,
        "最低价": 23.16,
        "昨收价": 23.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 78727.0,
        "成交额": 1827080.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.IBHD"
    },
    {
        "序号": 4944,
        "name": "好事达保险",
        "最新价": 139.32,
        "涨跌额": 0.3,
        "涨跌幅": 0.22,
        "开盘价": 139.78,
        "最高价": 139.98,
        "最低价": 138.56,
        "昨收价": 139.02,
        "总市值": 36458241756.0,
        "市盈率": -18.45,
        "成交量": 806297.0,
        "成交额": 112328275.0,
        "振幅": 1.02,
        "换手率": 0.31,
        "symbol": "106.ALL"
    },
    {
        "序号": 4945,
        "name": "Establishment Labs",
        "最新价": 23.23,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "开盘价": 23.14,
        "最高价": 23.67,
        "最低价": 22.77,
        "昨收价": 23.18,
        "总市值": 599525880.0,
        "市盈率": -8.38,
        "成交量": 463367.0,
        "成交额": 10726526.0,
        "振幅": 3.88,
        "换手率": 1.8,
        "symbol": "105.ESTA"
    },
    {
        "序号": 4946,
        "name": "Quipt Home Medical Corp",
        "最新价": 4.65,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "开盘价": 4.67,
        "最高价": 4.73,
        "最低价": 4.6,
        "昨收价": 4.64,
        "总市值": 195660231.0,
        "市盈率": 631.16,
        "成交量": 140217.0,
        "成交额": 652679.0,
        "振幅": 2.8,
        "换手率": 0.33,
        "symbol": "105.QIPT"
    },
    {
        "序号": 4947,
        "name": "FCF International Quality ETF",
        "最新价": 27.97,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 27.65,
        "最高价": 28.01,
        "最低价": 27.65,
        "昨收价": 27.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 12298.0,
        "成交额": 343313.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "107.TTAI"
    },
    {
        "序号": 4948,
        "name": "Alarum Technologies Ltd ADR",
        "最新价": 4.67,
        "涨跌额": 0.01,
        "涨跌幅": 0.21,
        "开盘价": 4.66,
        "最高价": 4.67,
        "最低价": 4.62,
        "昨收价": 4.66,
        "总市值": 27278484.0,
        "市盈率": -2.7,
        "成交量": 7612.0,
        "成交额": 35360.0,
        "振幅": 1.07,
        "换手率": 0.13,
        "symbol": "105.ALAR"
    },
    {
        "序号": 4949,
        "name": "Motley Fool Global Opportunitie",
        "最新价": 28.04,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 27.74,
        "最高价": 28.04,
        "最低价": 27.74,
        "昨收价": 27.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 27070.0,
        "成交额": 756356.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.TMFG"
    },
    {
        "序号": 4950,
        "name": "MSC Industrial Direct Co Inc-A",
        "最新价": 98.43,
        "涨跌额": 0.21,
        "涨跌幅": 0.21,
        "开盘价": 98.48,
        "最高价": 99.21,
        "最低价": 98.08,
        "昨收价": 98.22,
        "总市值": 5634277695.0,
        "市盈率": 16.42,
        "成交量": 379269.0,
        "成交额": 37361254.0,
        "振幅": 1.15,
        "换手率": 0.66,
        "symbol": "106.MSM"
    },
    {
        "序号": 4951,
        "name": "Insperity Inc",
        "最新价": 117.25,
        "涨跌额": 0.25,
        "涨跌幅": 0.21,
        "开盘价": 117.27,
        "最高价": 118.03,
        "最低价": 116.49,
        "昨收价": 117.0,
        "总市值": 4371154688.0,
        "市盈率": 23.0,
        "成交量": 329204.0,
        "成交额": 38619605.0,
        "振幅": 1.32,
        "换手率": 0.88,
        "symbol": "106.NSP"
    },
    {
        "序号": 4952,
        "name": "Calvert International Responsib",
        "最新价": 51.68,
        "涨跌额": 0.11,
        "涨跌幅": 0.21,
        "开盘价": 51.51,
        "最高价": 51.68,
        "最低价": 51.46,
        "昨收价": 51.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 2690.0,
        "成交额": 138785.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.CVIE"
    },
    {
        "序号": 4953,
        "name": "QRAFT AI-Enhanced U.S. Large Ca",
        "最新价": 42.32,
        "涨跌额": 0.09,
        "涨跌幅": 0.21,
        "开盘价": 42.32,
        "最高价": 42.32,
        "最低价": 42.32,
        "昨收价": 42.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 102.0,
        "成交额": 4316.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.QRFT"
    },
    {
        "序号": 4954,
        "name": "Motley Fool Small-Cap Growth ET",
        "最新价": 28.23,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 28.11,
        "最高价": 28.36,
        "最低价": 28.11,
        "昨收价": 28.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 21343.0,
        "成交额": 602012.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.TMFS"
    },
    {
        "序号": 4955,
        "name": "SoFi Weekly Dividend ETF",
        "最新价": 47.08,
        "涨跌额": 0.1,
        "涨跌幅": 0.21,
        "开盘价": 46.95,
        "最高价": 47.13,
        "最低价": 46.95,
        "昨收价": 46.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 3699.0,
        "成交额": 174074.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.WKLY"
    },
    {
        "序号": 4956,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 33.05,
        "涨跌额": 0.07,
        "涨跌幅": 0.21,
        "开盘价": 33.0,
        "最高价": 33.05,
        "最低价": 32.99,
        "昨收价": 32.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 63563.0,
        "成交额": 2097218.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.GMAR"
    },
    {
        "序号": 4957,
        "name": "Elevance Health Inc",
        "最新价": 477.97,
        "涨跌额": 1.01,
        "涨跌幅": 0.21,
        "开盘价": 480.12,
        "最高价": 480.12,
        "最低价": 472.39,
        "昨收价": 476.96,
        "总市值": 112303417756.0,
        "市盈率": 18.33,
        "成交量": 1099158.0,
        "成交额": 524286848.0,
        "振幅": 1.62,
        "换手率": 0.47,
        "symbol": "106.ELV"
    },
    {
        "序号": 4958,
        "name": "AllianzIM U.S. Large Cap 6 Mont",
        "最新价": 28.47,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 28.47,
        "最高价": 28.49,
        "最低价": 28.47,
        "昨收价": 28.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1398.0,
        "成交额": 39808.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.SIXO"
    },
    {
        "序号": 4959,
        "name": "Guru Favorite Stocks ETF",
        "最新价": 23.75,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 23.77,
        "最高价": 23.77,
        "最低价": 23.75,
        "昨收价": 23.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 403.0,
        "成交额": 9579.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.GFGF"
    },
    {
        "序号": 4960,
        "name": "艾芙隆海湾社区",
        "最新价": 175.75,
        "涨跌额": 0.37,
        "涨跌幅": 0.21,
        "开盘价": 175.0,
        "最高价": 175.81,
        "最低价": 173.95,
        "昨收价": 175.38,
        "总市值": 24959155934.0,
        "市盈率": 26.89,
        "成交量": 588550.0,
        "成交额": 103172502.0,
        "振幅": 1.06,
        "换手率": 0.41,
        "symbol": "106.AVB"
    },
    {
        "序号": 4961,
        "name": "Vestis Corp",
        "最新价": 19.02,
        "涨跌额": 0.04,
        "涨跌幅": 0.21,
        "开盘价": 18.9,
        "最高价": 19.39,
        "最低价": 18.9,
        "昨收价": 18.98,
        "总市值": 2491337439.0,
        "市盈率": 11.69,
        "成交量": 1270216.0,
        "成交额": 24319538.0,
        "振幅": 2.58,
        "换手率": 0.97,
        "symbol": "106.VSTS"
    },
    {
        "序号": 4962,
        "name": "威利斯融资租赁",
        "最新价": 47.6,
        "涨跌额": 0.1,
        "涨跌幅": 0.21,
        "开盘价": 47.49,
        "最高价": 47.8,
        "最低价": 47.0,
        "昨收价": 47.5,
        "总市值": 303112611.0,
        "市盈率": 6.43,
        "成交量": 8630.0,
        "成交额": 409912.0,
        "振幅": 1.68,
        "换手率": 0.14,
        "symbol": "105.WLFC"
    },
    {
        "序号": 4963,
        "name": "iShares International Dividend ",
        "最新价": 62.0,
        "涨跌额": 0.13,
        "涨跌幅": 0.21,
        "开盘价": 61.73,
        "最高价": 62.25,
        "最低价": 61.73,
        "昨收价": 61.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 17827.0,
        "成交额": 1104972.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.IGRO"
    },
    {
        "序号": 4964,
        "name": "Chimera Investment Corp Series ",
        "最新价": 23.85,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 23.75,
        "最高价": 23.85,
        "最低价": 23.64,
        "昨收价": 23.8,
        "总市值": 310050000.0,
        "市盈率": null,
        "成交量": 42194.0,
        "成交额": 1003498.0,
        "振幅": 0.88,
        "换手率": 0.32,
        "symbol": "106.CIM_B"
    },
    {
        "序号": 4965,
        "name": "Democratic Large Cap Core ETF",
        "最新价": 28.7,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 28.59,
        "最高价": 28.75,
        "最低价": 28.59,
        "昨收价": 28.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 77492.0,
        "成交额": 2225413.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.DEMZ"
    },
    {
        "序号": 4966,
        "name": "CBRE Global Real Estate Income ",
        "最新价": 4.79,
        "涨跌额": 0.01,
        "涨跌幅": 0.21,
        "开盘价": 4.76,
        "最高价": 4.79,
        "最低价": 4.75,
        "昨收价": 4.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 671155.0,
        "成交额": 3202204.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "106.IGR"
    },
    {
        "序号": 4967,
        "name": "RLJ Lodging Trust Series A Pfd",
        "最新价": 24.0,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 23.95,
        "最高价": 24.12,
        "最低价": 23.86,
        "昨收价": 23.95,
        "总市值": 309107400.0,
        "市盈率": null,
        "成交量": 48475.0,
        "成交额": 1162466.0,
        "振幅": 1.09,
        "换手率": 0.38,
        "symbol": "106.RLJ_A"
    },
    {
        "序号": 4968,
        "name": "LuxUrban Hotels Inc Series A Pf",
        "最新价": 24.01,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 24.01,
        "最高价": 24.01,
        "最低价": 24.01,
        "昨收价": 23.96,
        "总市值": 6722800.0,
        "市盈率": null,
        "成交量": 243.0,
        "成交额": 5834.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "105.LUXHP"
    },
    {
        "序号": 4969,
        "name": "菲利普莫里斯国际",
        "最新价": 91.31,
        "涨跌额": 0.19,
        "涨跌幅": 0.21,
        "开盘价": 91.33,
        "最高价": 91.47,
        "最低价": 90.67,
        "昨收价": 91.12,
        "总市值": 141750191038.0,
        "市盈率": 17.69,
        "成交量": 3877501.0,
        "成交额": 353508912.0,
        "振幅": 0.88,
        "换手率": 0.25,
        "symbol": "106.PM"
    },
    {
        "序号": 4970,
        "name": "First Trust NASDAQ-100 Equal We",
        "最新价": 110.56,
        "涨跌额": 0.23,
        "涨跌幅": 0.21,
        "开盘价": 109.96,
        "最高价": 110.85,
        "最低价": 109.66,
        "昨收价": 110.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 78427.0,
        "成交额": 8666224.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "105.QQEW"
    },
    {
        "序号": 4971,
        "name": "爱迪生国际",
        "最新价": 67.45,
        "涨跌额": 0.14,
        "涨跌幅": 0.21,
        "开盘价": 67.25,
        "最高价": 67.58,
        "最低价": 66.61,
        "昨收价": 67.31,
        "总市值": 25871709692.0,
        "市盈率": 17.77,
        "成交量": 2275797.0,
        "成交额": 152983903.0,
        "振幅": 1.44,
        "换手率": 0.59,
        "symbol": "106.EIX"
    },
    {
        "序号": 4972,
        "name": "多尼戈尔股份-A",
        "最新价": 14.46,
        "涨跌额": 0.03,
        "涨跌幅": 0.21,
        "开盘价": 14.31,
        "最高价": 14.5,
        "最低价": 14.3,
        "昨收价": 14.43,
        "总市值": 480731781.0,
        "市盈率": 48.68,
        "成交量": 30639.0,
        "成交额": 441915.0,
        "振幅": 1.39,
        "换手率": 0.11,
        "symbol": "105.DGICA"
    },
    {
        "序号": 4973,
        "name": "BNY Mellon Sustainable Internat",
        "最新价": 43.45,
        "涨跌额": 0.09,
        "涨跌幅": 0.21,
        "开盘价": 43.45,
        "最高价": 43.45,
        "最低价": 43.45,
        "昨收价": 43.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 86.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BKIS"
    },
    {
        "序号": 4974,
        "name": "Harrow Health Inc Notes",
        "最新价": 24.15,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 24.01,
        "最高价": 24.15,
        "最低价": 23.96,
        "昨收价": 24.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 7162.0,
        "成交额": 172111.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.HROWL"
    },
    {
        "序号": 4975,
        "name": "Annaly Capital Management Inc S",
        "最新价": 24.2,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "开盘价": 24.1,
        "最高价": 24.24,
        "最低价": 24.08,
        "昨收价": 24.15,
        "总市值": 428340000.0,
        "市盈率": null,
        "成交量": 28275.0,
        "成交额": 683761.0,
        "振幅": 0.66,
        "换手率": 0.16,
        "symbol": "106.NLY_I"
    },
    {
        "序号": 4976,
        "name": "Goldman Sachs Future Planet Equ",
        "最新价": 29.04,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 28.96,
        "最高价": 29.04,
        "最低价": 28.96,
        "昨收价": 28.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 866.0,
        "成交额": 25120.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.GSFP"
    },
    {
        "序号": 4977,
        "name": "MaxLinear Inc",
        "最新价": 19.39,
        "涨跌额": 0.04,
        "涨跌幅": 0.21,
        "开盘价": 19.27,
        "最高价": 19.6,
        "最低价": 19.12,
        "昨收价": 19.35,
        "总市值": 1581461992.0,
        "市盈率": -443.36,
        "成交量": 533796.0,
        "成交额": 10317247.0,
        "振幅": 2.48,
        "换手率": 0.65,
        "symbol": "105.MXL"
    },
    {
        "序号": 4978,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 34.01,
        "涨跌额": 0.07,
        "涨跌幅": 0.21,
        "开盘价": 33.86,
        "最高价": 34.04,
        "最低价": 33.86,
        "昨收价": 33.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 41967.0,
        "成交额": 1426292.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.PSEP"
    },
    {
        "序号": 4979,
        "name": "Ziff Davis Inc",
        "最新价": 63.25,
        "涨跌额": 0.13,
        "涨跌幅": 0.21,
        "开盘价": 62.85,
        "最高价": 63.72,
        "最低价": 60.4,
        "昨收价": 63.12,
        "总市值": 2908535627.0,
        "市盈率": 63.85,
        "成交量": 204932.0,
        "成交额": 12940463.0,
        "振幅": 5.26,
        "换手率": 0.45,
        "symbol": "105.ZD"
    },
    {
        "序号": 4980,
        "name": "Pacer Trendpilot Fund of Funds ",
        "最新价": 29.26,
        "涨跌额": 0.06,
        "涨跌幅": 0.21,
        "开盘价": 29.22,
        "最高价": 29.26,
        "最低价": 29.2,
        "昨收价": 29.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 2862.0,
        "成交额": 83632.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.TRND"
    },
    {
        "序号": 4981,
        "name": "First Trust Large Cap Value Alp",
        "最新价": 68.29,
        "涨跌额": 0.14,
        "涨跌幅": 0.21,
        "开盘价": 68.19,
        "最高价": 68.5,
        "最低价": 68.05,
        "昨收价": 68.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 36270.0,
        "成交额": 2475420.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "105.FTA"
    },
    {
        "序号": 4982,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 34.21,
        "涨跌额": 0.07,
        "涨跌幅": 0.21,
        "开盘价": 34.15,
        "最高价": 34.22,
        "最低价": 34.15,
        "昨收价": 34.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 3139.0,
        "成交额": 107313.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.UJAN"
    },
    {
        "序号": 4983,
        "name": "KraneShares Electric Vehicles a",
        "最新价": 24.46,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.42,
        "最高价": 24.68,
        "最低价": 24.36,
        "昨收价": 24.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 28568.0,
        "成交额": 699516.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "107.KARS"
    },
    {
        "序号": 4984,
        "name": "Mid Cap US Equity Select ETF",
        "最新价": 29.41,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 29.5,
        "最高价": 29.5,
        "最低价": 29.39,
        "昨收价": 29.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 529.0,
        "成交额": 15588.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.RNMC"
    },
    {
        "序号": 4985,
        "name": "公平住屋",
        "最新价": 58.82,
        "涨跌额": 0.12,
        "涨跌幅": 0.2,
        "开盘价": 58.45,
        "最高价": 58.85,
        "最低价": 58.15,
        "昨收价": 58.7,
        "总市值": 22335420618.0,
        "市盈率": 32.71,
        "成交量": 1652220.0,
        "成交额": 96922476.0,
        "振幅": 1.19,
        "换手率": 0.44,
        "symbol": "106.EQR"
    },
    {
        "序号": 4986,
        "name": "BNY Mellon International Equity",
        "最新价": 68.65,
        "涨跌额": 0.14,
        "涨跌幅": 0.2,
        "开盘价": 68.35,
        "最高价": 68.68,
        "最低价": 68.35,
        "昨收价": 68.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 9544.0,
        "成交额": 653885.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.BKIE"
    },
    {
        "序号": 4987,
        "name": "Dimensional International Core ",
        "最新价": 24.53,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.44,
        "最高价": 24.57,
        "最低价": 24.39,
        "昨收价": 24.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1097378.0,
        "成交额": 26885771.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.DFIC"
    },
    {
        "序号": 4988,
        "name": "Offerpad Solutions Inc-A",
        "最新价": 9.82,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "开盘价": 9.75,
        "最高价": 10.05,
        "最低价": 9.64,
        "昨收价": 9.8,
        "总市值": 267427255.0,
        "市盈率": -1.2,
        "成交量": 11691.0,
        "成交额": 114651.0,
        "振幅": 4.18,
        "换手率": 0.04,
        "symbol": "106.OPAD"
    },
    {
        "序号": 4989,
        "name": "Annaly Capital Management Inc S",
        "最新价": 24.55,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.41,
        "最高价": 24.62,
        "最低价": 24.35,
        "昨收价": 24.5,
        "总市值": 417350000.0,
        "市盈率": null,
        "成交量": 24521.0,
        "成交额": 600120.0,
        "振幅": 1.1,
        "换手率": 0.14,
        "symbol": "106.NLY_G"
    },
    {
        "序号": 4990,
        "name": "ETRACS Quarterly Pay 1.5X Lever",
        "最新价": 49.12,
        "涨跌额": 0.1,
        "涨跌幅": 0.2,
        "开盘价": 49.12,
        "最高价": 49.12,
        "最低价": 49.12,
        "昨收价": 49.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 137.0,
        "成交额": 6728.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MLPR"
    },
    {
        "序号": 4991,
        "name": "Natixis Vaughan Nelson Select E",
        "最新价": 29.53,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 29.46,
        "最高价": 29.53,
        "最低价": 29.46,
        "昨收价": 29.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 2919.0,
        "成交额": 86191.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.VNSE"
    },
    {
        "序号": 4992,
        "name": "洛克希德马丁",
        "最新价": 448.02,
        "涨跌额": 0.91,
        "涨跌幅": 0.2,
        "开盘价": 448.57,
        "最高价": 450.53,
        "最低价": 447.46,
        "昨收价": 447.11,
        "总市值": 111153186294.0,
        "市盈率": 15.96,
        "成交量": 761176.0,
        "成交额": 341316624.0,
        "振幅": 0.69,
        "换手率": 0.31,
        "symbol": "106.LMT"
    },
    {
        "序号": 4993,
        "name": "VanEck Environmental Services E",
        "最新价": 147.73,
        "涨跌额": 0.3,
        "涨跌幅": 0.2,
        "开盘价": 147.2,
        "最高价": 147.73,
        "最低价": 147.2,
        "昨收价": 147.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 342.0,
        "成交额": 50342.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.EVX"
    },
    {
        "序号": 4994,
        "name": "McElhenny Sheffield Managed Ris",
        "最新价": 24.64,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.55,
        "最高价": 24.64,
        "最低价": 24.55,
        "昨收价": 24.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 2580.0,
        "成交额": 63420.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.MSMR"
    },
    {
        "序号": 4995,
        "name": "FTAI Aviation Ltd Series A Pfd",
        "最新价": 24.65,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.65,
        "最高价": 24.65,
        "最低价": 24.62,
        "昨收价": 24.6,
        "总市值": 103037000.0,
        "市盈率": null,
        "成交量": 2075.0,
        "成交额": 51143.0,
        "振幅": 0.12,
        "换手率": 0.05,
        "symbol": "105.FTAIP"
    },
    {
        "序号": 4996,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 34.59,
        "涨跌额": 0.07,
        "涨跌幅": 0.2,
        "开盘价": 34.49,
        "最高价": 34.63,
        "最低价": 34.49,
        "昨收价": 34.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 15057.0,
        "成交额": 520658.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.XJUN"
    },
    {
        "序号": 4997,
        "name": "Franklin International Core Div",
        "最新价": 29.67,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 29.51,
        "最高价": 29.67,
        "最低价": 29.47,
        "昨收价": 29.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 89574.0,
        "成交额": 2649666.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.DIVI"
    },
    {
        "序号": 4998,
        "name": "晖宝",
        "最新价": 4.95,
        "涨跌额": 0.01,
        "涨跌幅": 0.2,
        "开盘价": 4.69,
        "最高价": 5.19,
        "最低价": 4.61,
        "昨收价": 4.94,
        "总市值": 54450000.0,
        "市盈率": 44.13,
        "成交量": 192698.0,
        "成交额": 941271.0,
        "振幅": 11.74,
        "换手率": 1.75,
        "symbol": "105.FEBO"
    },
    {
        "序号": 4999,
        "name": "西部社区银行",
        "最新价": 14.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.2,
        "开盘价": 14.91,
        "最高价": 14.93,
        "最低价": 14.72,
        "昨收价": 14.82,
        "总市值": 131427133.0,
        "市盈率": 12.87,
        "成交量": 5930.0,
        "成交额": 88068.0,
        "振幅": 1.42,
        "换手率": 0.07,
        "symbol": "105.CWBC"
    },
    {
        "序号": 5000,
        "name": "Great Ajax Corp Notes",
        "最新价": 24.76,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.67,
        "最高价": 24.81,
        "最低价": 24.67,
        "昨收价": 24.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 1027.0,
        "成交额": 25416.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "106.AJXA"
    },
    {
        "序号": 5001,
        "name": "Kinetik Holdings Inc-A",
        "最新价": 34.7,
        "涨跌额": 0.07,
        "涨跌幅": 0.2,
        "开盘价": 34.75,
        "最高价": 35.08,
        "最低价": 34.53,
        "昨收价": 34.63,
        "总市值": 5156827205.0,
        "市盈率": 30.78,
        "成交量": 274329.0,
        "成交额": 9520354.0,
        "振幅": 1.59,
        "换手率": 0.18,
        "symbol": "106.KNTK"
    },
    {
        "序号": 5002,
        "name": "奥多比",
        "最新价": 610.01,
        "涨跌额": 1.23,
        "涨跌幅": 0.2,
        "开盘价": 605.0,
        "最高价": 610.23,
        "最低价": 601.3,
        "昨收价": 608.78,
        "总市值": 277737553000.0,
        "市盈率": 54.24,
        "成交量": 2499653.0,
        "成交额": 1517982320.0,
        "振幅": 1.47,
        "换手率": 0.55,
        "symbol": "105.ADBE"
    },
    {
        "序号": 5003,
        "name": "ProShares On-Demand ETF",
        "最新价": 24.8,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.8,
        "最高价": 24.8,
        "最低价": 24.8,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 99.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OND"
    },
    {
        "序号": 5004,
        "name": "OneAscent International Equity ",
        "最新价": 29.76,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 29.67,
        "最高价": 29.82,
        "最低价": 29.67,
        "昨收价": 29.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 25862.0,
        "成交额": 770423.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.OAIM"
    },
    {
        "序号": 5005,
        "name": "VictoryShares THB Mid Cap ESG E",
        "最新价": 24.85,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.85,
        "最高价": 24.85,
        "最低价": 24.85,
        "昨收价": 24.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 173.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MDCP"
    },
    {
        "序号": 5006,
        "name": "Virtus InfraCap U.S. Preferred ",
        "最新价": 19.89,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 19.8,
        "最高价": 19.92,
        "最低价": 19.71,
        "昨收价": 19.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 200706.0,
        "成交额": 3973119.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.PFFA"
    },
    {
        "序号": 5007,
        "name": "First Trust Multi-Strategy Alte",
        "最新价": 19.99,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 19.95,
        "最高价": 19.99,
        "最低价": 19.95,
        "昨收价": 19.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 347.0,
        "成交额": 6929.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.LALT"
    },
    {
        "序号": 5008,
        "name": "Safe Bulkers Inc Series C Pfd",
        "最新价": 25.0,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.95,
        "最高价": 25.0,
        "最低价": 24.95,
        "昨收价": 24.95,
        "总市值": 20123750.0,
        "市盈率": null,
        "成交量": 3653.0,
        "成交额": 91242.0,
        "振幅": 0.2,
        "换手率": 0.45,
        "symbol": "106.SB_C"
    },
    {
        "序号": 5009,
        "name": "Inter & Co Inc-A",
        "最新价": 5.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.2,
        "开盘价": 5.04,
        "最高价": 5.07,
        "最低价": 4.96,
        "昨收价": 4.99,
        "总市值": 2009365730.0,
        "市盈率": 52.41,
        "成交量": 161765.0,
        "成交额": 811825.0,
        "振幅": 2.2,
        "换手率": 0.04,
        "symbol": "105.INTR"
    },
    {
        "序号": 5010,
        "name": "Innovator International Develop",
        "最新价": 25.0,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 24.93,
        "最高价": 25.04,
        "最低价": 24.89,
        "昨收价": 24.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 27597.0,
        "成交额": 689168.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.IAPR"
    },
    {
        "序号": 5011,
        "name": "马克西姆斯",
        "最新价": 85.08,
        "涨跌额": 0.17,
        "涨跌幅": 0.2,
        "开盘价": 84.86,
        "最高价": 85.85,
        "最低价": 84.6,
        "昨收价": 84.91,
        "总市值": 5189699120.0,
        "市盈率": 32.08,
        "成交量": 205990.0,
        "成交额": 17552103.0,
        "振幅": 1.47,
        "换手率": 0.34,
        "symbol": "106.MMS"
    },
    {
        "序号": 5012,
        "name": "Strategas Global Policy Opportu",
        "最新价": 25.04,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.08,
        "最高价": 25.08,
        "最低价": 25.04,
        "昨收价": 24.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 322.0,
        "成交额": 8075.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.SAGP"
    },
    {
        "序号": 5013,
        "name": "Assurant Inc Notes",
        "最新价": 20.05,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.13,
        "最高价": 20.38,
        "最低价": 20.05,
        "昨收价": 20.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 20990.0,
        "成交额": 423355.0,
        "振幅": 1.65,
        "换手率": null,
        "symbol": "106.AIZN"
    },
    {
        "序号": 5014,
        "name": "FT Cboe Vest International Equi",
        "最新价": 20.09,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.06,
        "最高价": 20.11,
        "最低价": 20.06,
        "昨收价": 20.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 4912.0,
        "成交额": 98620.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.YSEP"
    },
    {
        "序号": 5015,
        "name": "Janus Henderson AAA CLO ETF",
        "最新价": 50.25,
        "涨跌额": 0.1,
        "涨跌幅": 0.2,
        "开盘价": 50.22,
        "最高价": 50.25,
        "最低价": 50.21,
        "昨收价": 50.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 1188456.0,
        "成交额": 59702509.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.JAAA"
    },
    {
        "序号": 5016,
        "name": "Trinity Capital Inc Note",
        "最新价": 25.15,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.15,
        "最高价": 25.18,
        "最低价": 25.14,
        "昨收价": 25.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 2098.0,
        "成交额": 52800.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.TRINL"
    },
    {
        "序号": 5017,
        "name": "Colombier Acquisition Corp II U",
        "最新价": 10.06,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "开盘价": 10.08,
        "最高价": 10.08,
        "最低价": 10.04,
        "昨收价": 10.04,
        "总市值": 188625000.0,
        "市盈率": null,
        "成交量": 122239.0,
        "成交额": 1229110.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "106.CLBR_U"
    },
    {
        "序号": 5018,
        "name": "WillScot Mobile Mini Holdings C",
        "最新价": 40.3,
        "涨跌额": 0.08,
        "涨跌幅": 0.2,
        "开盘价": 40.09,
        "最高价": 40.71,
        "最低价": 40.09,
        "昨收价": 40.22,
        "总市值": 7722700929.0,
        "市盈率": 16.21,
        "成交量": 1232909.0,
        "成交额": 49739813.0,
        "振幅": 1.54,
        "换手率": 0.64,
        "symbol": "105.WSC"
    },
    {
        "序号": 5019,
        "name": "IMGP Berkshire Dividend Growth ",
        "最新价": 10.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "开盘价": 10.14,
        "最高价": 10.14,
        "最低价": 10.07,
        "昨收价": 10.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1009.0,
        "成交额": 10195.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.BDVG"
    },
    {
        "序号": 5020,
        "name": "BlackRock Multi-Sector Income T",
        "最新价": 15.16,
        "涨跌额": 0.03,
        "涨跌幅": 0.2,
        "开盘价": 15.08,
        "最高价": 15.2,
        "最低价": 15.06,
        "昨收价": 15.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 63635.0,
        "成交额": 963168.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.BIT"
    },
    {
        "序号": 5021,
        "name": "Red Violet Inc",
        "最新价": 20.24,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.05,
        "最高价": 20.5,
        "最低价": 19.99,
        "昨收价": 20.2,
        "总市值": 282133557.0,
        "市盈率": 21.61,
        "成交量": 21791.0,
        "成交额": 440203.0,
        "振幅": 2.52,
        "换手率": 0.16,
        "symbol": "105.RDVT"
    },
    {
        "序号": 5022,
        "name": "Xencor Inc",
        "最新价": 20.25,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.16,
        "最高价": 20.58,
        "最低价": 19.77,
        "昨收价": 20.21,
        "总市值": 1232722760.0,
        "市盈率": -10.36,
        "成交量": 510648.0,
        "成交额": 10318465.0,
        "振幅": 4.01,
        "换手率": 0.84,
        "symbol": "105.XNCR"
    },
    {
        "序号": 5023,
        "name": "First Trust Merger Arbitrage ET",
        "最新价": 20.25,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.2,
        "最高价": 20.25,
        "最低价": 20.2,
        "昨收价": 20.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 42387.0,
        "成交额": 856939.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.MARB"
    },
    {
        "序号": 5024,
        "name": "Sunstone Hotel Investors Inc",
        "最新价": 10.15,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "开盘价": 9.9,
        "最高价": 10.15,
        "最低价": 9.9,
        "昨收价": 10.13,
        "总市值": 2085256864.0,
        "市盈率": 21.46,
        "成交量": 3110925.0,
        "成交额": 31310109.0,
        "振幅": 2.47,
        "换手率": 1.51,
        "symbol": "106.SHO"
    },
    {
        "序号": 5025,
        "name": "万宝盛华",
        "最新价": 76.2,
        "涨跌额": 0.15,
        "涨跌幅": 0.2,
        "开盘价": 76.23,
        "最高价": 76.62,
        "最低价": 75.79,
        "昨收价": 76.05,
        "总市值": 3719230941.0,
        "市盈率": 16.75,
        "成交量": 175456.0,
        "成交额": 13375257.0,
        "振幅": 1.09,
        "换手率": 0.36,
        "symbol": "106.MAN"
    },
    {
        "序号": 5026,
        "name": "OrthoPediatrics Corp",
        "最新价": 30.53,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 30.43,
        "最高价": 31.22,
        "最低价": 30.23,
        "昨收价": 30.47,
        "总市值": 712978814.0,
        "市盈率": -32.22,
        "成交量": 81314.0,
        "成交额": 2504949.0,
        "振幅": 3.25,
        "换手率": 0.35,
        "symbol": "105.KIDS"
    },
    {
        "序号": 5027,
        "name": "Phillips Edison & Co Inc",
        "最新价": 35.62,
        "涨跌额": 0.07,
        "涨跌幅": 0.2,
        "开盘价": 35.35,
        "最高价": 36.28,
        "最低价": 35.12,
        "昨收价": 35.55,
        "总市值": 4259368360.0,
        "市盈率": 74.73,
        "成交量": 603859.0,
        "成交额": 21423673.0,
        "振幅": 3.26,
        "换手率": 0.5,
        "symbol": "105.PECO"
    },
    {
        "序号": 5028,
        "name": "FlexShares Developed Markets ex",
        "最新价": 25.46,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.48,
        "最高价": 25.48,
        "最低价": 25.41,
        "昨收价": 25.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1690.0,
        "成交额": 42992.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.QLVD"
    },
    {
        "序号": 5029,
        "name": "VanEck Morningstar Internationa",
        "最新价": 30.7,
        "涨跌额": 0.06,
        "涨跌幅": 0.2,
        "开盘价": 30.58,
        "最高价": 31.0,
        "最低价": 30.58,
        "昨收价": 30.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 50280.0,
        "成交额": 1543912.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.MOTI"
    },
    {
        "序号": 5030,
        "name": "Pacer Swan SOS Moderate (Januar",
        "最新价": 25.63,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.62,
        "最高价": 25.63,
        "最低价": 25.62,
        "昨收价": 25.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 2250.0,
        "成交额": 57640.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.PSMD"
    },
    {
        "序号": 5031,
        "name": "NuStar Energy LP Series B Pfd",
        "最新价": 25.64,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.59,
        "最高价": 25.64,
        "最低价": 25.57,
        "昨收价": 25.59,
        "总市值": 394856000.0,
        "市盈率": null,
        "成交量": 12457.0,
        "成交额": 318800.0,
        "振幅": 0.27,
        "换手率": 0.08,
        "symbol": "106.NS_B"
    },
    {
        "序号": 5032,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 25.65,
        "涨跌额": 0.05,
        "涨跌幅": 0.2,
        "开盘价": 25.59,
        "最高价": 25.67,
        "最低价": 25.59,
        "昨收价": 25.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 14898.0,
        "成交额": 381735.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.XBJA"
    },
    {
        "序号": 5033,
        "name": "布鲁克菲尔德",
        "最新价": 35.91,
        "涨跌额": 0.07,
        "涨跌幅": 0.2,
        "开盘价": 35.66,
        "最高价": 36.17,
        "最低价": 35.66,
        "昨收价": 35.84,
        "总市值": 55854633877.0,
        "市盈率": 485.69,
        "成交量": 1619973.0,
        "成交额": 58152989.0,
        "振幅": 1.42,
        "换手率": 0.1,
        "symbol": "106.BN"
    },
    {
        "序号": 5034,
        "name": "Xtrackers MSCI Japan Hedged Equ",
        "最新价": 61.58,
        "涨跌额": 0.12,
        "涨跌幅": 0.2,
        "开盘价": 60.91,
        "最高价": 61.61,
        "最低价": 60.9,
        "昨收价": 61.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 24716.0,
        "成交额": 1511660.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DBJP"
    },
    {
        "序号": 5035,
        "name": "iShares Core U.S. REIT ETF",
        "最新价": 51.35,
        "涨跌额": 0.1,
        "涨跌幅": 0.2,
        "开盘价": 51.09,
        "最高价": 51.35,
        "最低价": 50.81,
        "昨收价": 51.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 278941.0,
        "成交额": 14246862.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.USRT"
    },
    {
        "序号": 5036,
        "name": "FT Cboe Vest Buffered Allocatio",
        "最新价": 20.55,
        "涨跌额": 0.04,
        "涨跌幅": 0.2,
        "开盘价": 20.52,
        "最高价": 20.55,
        "最低价": 20.5,
        "昨收价": 20.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 71795.0,
        "成交额": 1473944.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.BUFT"
    },
    {
        "序号": 5037,
        "name": "Vanguard U.S. Minimum Volatilit",
        "最新价": 102.8,
        "涨跌额": 0.2,
        "涨跌幅": 0.19,
        "开盘价": 102.52,
        "最高价": 103.02,
        "最低价": 102.52,
        "昨收价": 102.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1378.0,
        "成交额": 141594.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.VFMV"
    },
    {
        "序号": 5038,
        "name": "亘喜生物",
        "最新价": 5.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "开盘价": 5.02,
        "最高价": 5.21,
        "最低价": 4.92,
        "昨收价": 5.13,
        "总市值": 493061839.0,
        "市盈率": -6.94,
        "成交量": 173432.0,
        "成交额": 889735.0,
        "振幅": 5.65,
        "换手率": 0.18,
        "symbol": "105.GRCL"
    },
    {
        "序号": 5039,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 30.89,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 30.82,
        "最高价": 30.89,
        "最低价": 30.82,
        "昨收价": 30.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 362.0,
        "成交额": 11156.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.XAUG"
    },
    {
        "序号": 5040,
        "name": "Northern Star Investment Corp I",
        "最新价": 10.3,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.28,
        "最高价": 10.3,
        "最低价": 10.28,
        "昨收价": 10.28,
        "总市值": 119696187.0,
        "市盈率": 49.3,
        "成交量": 1118.0,
        "成交额": 11504.0,
        "振幅": 0.19,
        "换手率": 0.01,
        "symbol": "107.NSTB"
    },
    {
        "序号": 5041,
        "name": "Denny’s Corp",
        "最新价": 10.3,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.23,
        "最高价": 10.31,
        "最低价": 10.2,
        "昨收价": 10.28,
        "总市值": 546780073.0,
        "市盈率": 18.34,
        "成交量": 394850.0,
        "成交额": 4057646.0,
        "振幅": 1.07,
        "换手率": 0.74,
        "symbol": "105.DENN"
    },
    {
        "序号": 5042,
        "name": "DGA Absolute Return ETF",
        "最新价": 20.69,
        "涨跌额": 0.04,
        "涨跌幅": 0.19,
        "开盘价": 20.73,
        "最高价": 20.74,
        "最低价": 20.69,
        "昨收价": 20.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 249.0,
        "成交额": 5163.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.HF"
    },
    {
        "序号": 5043,
        "name": "VictoryShares US Large Cap High",
        "最新价": 56.9,
        "涨跌额": 0.11,
        "涨跌幅": 0.19,
        "开盘价": 56.89,
        "最高价": 57.0,
        "最低价": 56.75,
        "昨收价": 56.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 10417.0,
        "成交额": 592813.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.CDL"
    },
    {
        "序号": 5044,
        "name": "Nuveen Credit Strategies Income",
        "最新价": 5.18,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "开盘价": 5.17,
        "最高价": 5.19,
        "最低价": 5.17,
        "昨收价": 5.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 416068.0,
        "成交额": 2154907.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.JQC"
    },
    {
        "序号": 5045,
        "name": "JPMorgan Diversified Return U.S",
        "最新价": 41.44,
        "涨跌额": 0.08,
        "涨跌幅": 0.19,
        "开盘价": 41.39,
        "最高价": 41.54,
        "最低价": 41.26,
        "昨收价": 41.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 24425.0,
        "成交额": 1012610.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.JPSE"
    },
    {
        "序号": 5046,
        "name": "EDAP TMS SA ADR",
        "最新价": 5.18,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "开盘价": 5.2,
        "最高价": 5.2,
        "最低价": 4.82,
        "昨收价": 5.17,
        "总市值": 191283746.0,
        "市盈率": -8.56,
        "成交量": 40415.0,
        "成交额": 207526.0,
        "振幅": 7.35,
        "换手率": 0.11,
        "symbol": "105.EDAP"
    },
    {
        "序号": 5047,
        "name": "First Trust Horizon Managed Vol",
        "最新价": 31.1,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 31.06,
        "最高价": 31.14,
        "最低价": 31.02,
        "昨收价": 31.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1889.0,
        "成交额": 58727.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.HSMV"
    },
    {
        "序号": 5048,
        "name": "WSFS金融",
        "最新价": 41.48,
        "涨跌额": 0.08,
        "涨跌幅": 0.19,
        "开盘价": 41.55,
        "最高价": 41.9,
        "最低价": 40.79,
        "昨收价": 41.4,
        "总市值": 2519158548.0,
        "市盈率": 8.7,
        "成交量": 329402.0,
        "成交额": 13656726.0,
        "振幅": 2.68,
        "换手率": 0.54,
        "symbol": "105.WSFS"
    },
    {
        "序号": 5049,
        "name": "Concord Acquisition Corp II-A",
        "最新价": 10.39,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.37,
        "最高价": 10.39,
        "最低价": 10.37,
        "昨收价": 10.37,
        "总市值": 225478138.0,
        "市盈率": 25.84,
        "成交量": 109491.0,
        "成交额": 1136269.0,
        "振幅": 0.19,
        "换手率": 0.5,
        "symbol": "106.CNDA"
    },
    {
        "序号": 5050,
        "name": "Ares Acquisition Corp II-A",
        "最新价": 10.4,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.38,
        "最高价": 10.4,
        "最低价": 10.38,
        "昨收价": 10.38,
        "总市值": 650000000.0,
        "市盈率": 65.23,
        "成交量": 261933.0,
        "成交额": 2720893.0,
        "振幅": 0.19,
        "换手率": 0.42,
        "symbol": "106.AACT"
    },
    {
        "序号": 5051,
        "name": "IQ Merger Arbitrage ETF",
        "最新价": 31.23,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 31.18,
        "最高价": 31.28,
        "最低价": 31.13,
        "昨收价": 31.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 106571.0,
        "成交额": 3327739.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.MNA"
    },
    {
        "序号": 5052,
        "name": "TBG Dividend Focus ETF",
        "最新价": 26.1,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.09,
        "最高价": 26.18,
        "最低价": 26.03,
        "昨收价": 26.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 10601.0,
        "成交额": 276709.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.TBG"
    },
    {
        "序号": 5053,
        "name": "VanEck CLO ETF",
        "最新价": 52.2,
        "涨跌额": 0.1,
        "涨跌幅": 0.19,
        "开盘价": 52.18,
        "最高价": 52.2,
        "最低价": 52.07,
        "昨收价": 52.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 204201.0,
        "成交额": 10633649.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.CLOI"
    },
    {
        "序号": 5054,
        "name": "Ares Acquisition Corp II Unit C",
        "最新价": 10.45,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.45,
        "最高价": 10.45,
        "最低价": 10.45,
        "昨收价": 10.43,
        "总市值": 653125000.0,
        "市盈率": 65.54,
        "成交量": 8600.0,
        "成交额": 89870.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.AACT_U"
    },
    {
        "序号": 5055,
        "name": "Cboe Validus S&P 500 Dynamic Pu",
        "最新价": 20.91,
        "涨跌额": 0.04,
        "涨跌幅": 0.19,
        "开盘价": 20.91,
        "最高价": 20.91,
        "最低价": 20.91,
        "昨收价": 20.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 167.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PUTD"
    },
    {
        "序号": 5056,
        "name": "Strategy Shares Newfound\/ReSolv",
        "最新价": 26.15,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.06,
        "最高价": 26.15,
        "最低价": 26.06,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1267.0,
        "成交额": 33022.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.ROMO"
    },
    {
        "序号": 5057,
        "name": "Arrowroot Acquisition Corp-A",
        "最新价": 10.49,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.49,
        "最高价": 10.49,
        "最低价": 10.49,
        "昨收价": 10.47,
        "总市值": 122033453.0,
        "市盈率": -26.77,
        "成交量": 3292.0,
        "成交额": 34533.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "105.ARRW"
    },
    {
        "序号": 5058,
        "name": "First Trust Intermediate Durati",
        "最新价": 15.78,
        "涨跌额": 0.03,
        "涨跌幅": 0.19,
        "开盘价": 15.7,
        "最高价": 15.79,
        "最低价": 15.66,
        "昨收价": 15.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 165766.0,
        "成交额": 2603882.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "106.FPF"
    },
    {
        "序号": 5059,
        "name": "FT Cboe Vest Laddered Moderate ",
        "最新价": 21.05,
        "涨跌额": 0.04,
        "涨跌幅": 0.19,
        "开盘价": 21.05,
        "最高价": 21.07,
        "最低价": 21.0,
        "昨收价": 21.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 12090.0,
        "成交额": 254324.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.BUFZ"
    },
    {
        "序号": 5060,
        "name": "NVE Corp",
        "最新价": 73.78,
        "涨跌额": 0.14,
        "涨跌幅": 0.19,
        "开盘价": 73.73,
        "最高价": 74.51,
        "最低价": 73.55,
        "昨收价": 73.64,
        "总市值": 356608326.0,
        "市盈率": 16.52,
        "成交量": 22944.0,
        "成交额": 1697083.0,
        "振幅": 1.3,
        "换手率": 0.47,
        "symbol": "105.NVEC"
    },
    {
        "序号": 5061,
        "name": "Golden Arrow Merger Corp-A",
        "最新价": 10.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.54,
        "最高价": 10.55,
        "最低价": 10.54,
        "昨收价": 10.53,
        "总市值": 97988200.0,
        "市盈率": 37.56,
        "成交量": 8200.0,
        "成交额": 86465.0,
        "振幅": 0.09,
        "换手率": 0.09,
        "symbol": "105.GAMC"
    },
    {
        "序号": 5062,
        "name": "AllianceBernstein National Muni",
        "最新价": 10.56,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.46,
        "最高价": 10.57,
        "最低价": 10.46,
        "昨收价": 10.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 85662.0,
        "成交额": 900904.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "106.AFB"
    },
    {
        "序号": 5063,
        "name": "Upbound Group Inc",
        "最新价": 31.75,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 31.59,
        "最高价": 31.8,
        "最低价": 31.18,
        "昨收价": 31.69,
        "总市值": 1723861551.0,
        "市盈率": 197.65,
        "成交量": 254527.0,
        "成交额": 8050374.0,
        "振幅": 1.96,
        "换手率": 0.47,
        "symbol": "105.UPBD"
    },
    {
        "序号": 5064,
        "name": "BlackRock World ex U.S. Carbon ",
        "最新价": 42.36,
        "涨跌额": 0.08,
        "涨跌幅": 0.19,
        "开盘价": 42.39,
        "最高价": 42.39,
        "最低价": 42.14,
        "昨收价": 42.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 3166.0,
        "成交额": 133826.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.LCTD"
    },
    {
        "序号": 5065,
        "name": "能多洁",
        "最新价": 26.49,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.15,
        "最高价": 26.53,
        "最低价": 26.15,
        "昨收价": 26.44,
        "总市值": 13364416311.0,
        "市盈率": 38.01,
        "成交量": 878643.0,
        "成交额": 23213623.0,
        "振幅": 1.44,
        "换手率": 0.17,
        "symbol": "106.RTO"
    },
    {
        "序号": 5066,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.49,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.37,
        "最高价": 26.49,
        "最低价": 26.37,
        "昨收价": 26.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 532.0,
        "成交额": 14028.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.ITDE"
    },
    {
        "序号": 5067,
        "name": "英格尔斯超市",
        "最新价": 84.82,
        "涨跌额": 0.16,
        "涨跌幅": 0.19,
        "开盘价": 84.68,
        "最高价": 86.3,
        "最低价": 84.66,
        "昨收价": 84.66,
        "总市值": 1611102972.0,
        "市盈率": 7.64,
        "成交量": 47981.0,
        "成交额": 4081986.0,
        "振幅": 1.94,
        "换手率": 0.25,
        "symbol": "105.IMKTA"
    },
    {
        "序号": 5068,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 31.84,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 31.86,
        "最高价": 31.87,
        "最低价": 31.77,
        "昨收价": 31.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 17350.0,
        "成交额": 551718.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.DAPR"
    },
    {
        "序号": 5069,
        "name": "Plutonian Acquisition Corp",
        "最新价": 10.62,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.62,
        "最高价": 10.62,
        "最低价": 10.62,
        "昨收价": 10.6,
        "总市值": 53108146.0,
        "市盈率": 46.26,
        "成交量": 4743.0,
        "成交额": 50370.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "105.PLTN"
    },
    {
        "序号": 5070,
        "name": "Avantis International Equity ET",
        "最新价": 58.46,
        "涨跌额": 0.11,
        "涨跌幅": 0.19,
        "开盘价": 58.19,
        "最高价": 58.56,
        "最低价": 58.18,
        "昨收价": 58.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 192232.0,
        "成交额": 11227046.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.AVDE"
    },
    {
        "序号": 5071,
        "name": "First Trust Dorsey Wright Dynam",
        "最新价": 31.9,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 31.8,
        "最高价": 32.0,
        "最低价": 31.79,
        "昨收价": 31.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 30529.0,
        "成交额": 973452.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "105.FVC"
    },
    {
        "序号": 5072,
        "name": "Distoken Acquisition Corp",
        "最新价": 10.64,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.62,
        "总市值": 68411923.0,
        "市盈率": 63.39,
        "成交量": 100000.0,
        "成交额": 1064000.0,
        "振幅": 0.0,
        "换手率": 1.56,
        "symbol": "105.DIST"
    },
    {
        "序号": 5073,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 26.62,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.61,
        "最高价": 26.63,
        "最低价": 26.55,
        "昨收价": 26.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 9216.0,
        "成交额": 245108.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.JUNW"
    },
    {
        "序号": 5074,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 26.7,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.75,
        "最高价": 26.75,
        "最低价": 26.7,
        "昨收价": 26.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 1282.0,
        "成交额": 34280.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.XDOC"
    },
    {
        "序号": 5075,
        "name": "Bridgford Foods Corp",
        "最新价": 10.68,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.25,
        "最高价": 10.68,
        "最低价": 10.25,
        "昨收价": 10.66,
        "总市值": 96940566.0,
        "市盈率": 21.93,
        "成交量": 3661.0,
        "成交额": 38563.0,
        "振幅": 4.03,
        "换手率": 0.04,
        "symbol": "105.BRID"
    },
    {
        "序号": 5076,
        "name": "希尔托普控股",
        "最新价": 32.06,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 32.08,
        "最高价": 32.38,
        "最低价": 31.83,
        "昨收价": 32.0,
        "总市值": 2089344461.0,
        "市盈率": 19.61,
        "成交量": 151425.0,
        "成交额": 4855716.0,
        "振幅": 1.72,
        "换手率": 0.23,
        "symbol": "106.HTH"
    },
    {
        "序号": 5077,
        "name": "Hawthorn Bancshares Inc",
        "最新价": 21.4,
        "涨跌额": 0.04,
        "涨跌幅": 0.19,
        "开盘价": 21.55,
        "最高价": 21.59,
        "最低价": 21.28,
        "昨收价": 21.36,
        "总市值": 150641512.0,
        "市盈率": 11.48,
        "成交量": 3528.0,
        "成交额": 75784.0,
        "振幅": 1.45,
        "换手率": 0.05,
        "symbol": "105.HWBK"
    },
    {
        "序号": 5078,
        "name": "RPM International Inc",
        "最新价": 107.02,
        "涨跌额": 0.2,
        "涨跌幅": 0.19,
        "开盘价": 106.71,
        "最高价": 107.37,
        "最低价": 106.21,
        "昨收价": 106.82,
        "总市值": 13787200920.0,
        "市盈率": 26.99,
        "成交量": 404695.0,
        "成交额": 43266363.0,
        "振幅": 1.09,
        "换手率": 0.31,
        "symbol": "106.RPM"
    },
    {
        "序号": 5079,
        "name": "First Trust International Devel",
        "最新价": 32.2,
        "涨跌额": 0.06,
        "涨跌幅": 0.19,
        "开盘价": 32.19,
        "最高价": 32.21,
        "最低价": 32.09,
        "昨收价": 32.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5715.0,
        "成交额": 183939.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.FICS"
    },
    {
        "序号": 5080,
        "name": "FundX Conservative ETF",
        "最新价": 37.57,
        "涨跌额": 0.07,
        "涨跌幅": 0.19,
        "开盘价": 37.56,
        "最高价": 37.59,
        "最低价": 37.56,
        "昨收价": 37.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 2310.0,
        "成交额": 86779.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.XRLX"
    },
    {
        "序号": 5081,
        "name": "Veritex Holdings Inc",
        "最新价": 21.47,
        "涨跌额": 0.04,
        "涨跌幅": 0.19,
        "开盘价": 21.59,
        "最高价": 21.95,
        "最低价": 21.43,
        "昨收价": 21.43,
        "总市值": 1166302529.0,
        "市盈率": 8.06,
        "成交量": 328937.0,
        "成交额": 7094661.0,
        "振幅": 2.43,
        "换手率": 0.61,
        "symbol": "105.VBTX"
    },
    {
        "序号": 5082,
        "name": "Khosla Ventures Acquisition Co-",
        "最新价": 10.74,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.73,
        "最高价": 10.74,
        "最低价": 10.73,
        "昨收价": 10.72,
        "总市值": 128014957.0,
        "市盈率": 23.73,
        "成交量": 39097.0,
        "成交额": 419528.0,
        "振幅": 0.09,
        "换手率": 0.33,
        "symbol": "105.KVSA"
    },
    {
        "序号": 5083,
        "name": "ProShares Hedge Replication ETF",
        "最新价": 48.33,
        "涨跌额": 0.09,
        "涨跌幅": 0.19,
        "开盘价": 48.3,
        "最高价": 48.4,
        "最低价": 48.3,
        "昨收价": 48.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 1357.0,
        "成交额": 65583.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.HDG"
    },
    {
        "序号": 5084,
        "name": "Blue Ocean Acquisition Corp-A",
        "最新价": 10.77,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.77,
        "最高价": 10.77,
        "最低价": 10.77,
        "昨收价": 10.75,
        "总市值": 255450938.0,
        "市盈率": 46.71,
        "成交量": 143.0,
        "成交额": 1540.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.BOCN"
    },
    {
        "序号": 5085,
        "name": "Revelstone Capital Acquisition ",
        "最新价": 10.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.7,
        "最高价": 10.79,
        "最低价": 10.7,
        "昨收价": 10.77,
        "总市值": 82487597.0,
        "市盈率": 52.14,
        "成交量": 54364.0,
        "成交额": 584889.0,
        "振幅": 0.84,
        "换手率": 0.71,
        "symbol": "105.RCAC"
    },
    {
        "序号": 5086,
        "name": "CF Acquisition Corp VII-A",
        "最新价": 10.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.79,
        "最高价": 10.79,
        "最低价": 10.79,
        "昨收价": 10.77,
        "总市值": 208420514.0,
        "市盈率": 108.92,
        "成交量": 367.0,
        "成交额": 3959.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.CFFS"
    },
    {
        "序号": 5087,
        "name": "SPDR MSCI EAFE Fossil Fuel Rese",
        "最新价": 37.8,
        "涨跌额": 0.07,
        "涨跌幅": 0.19,
        "开盘价": 37.64,
        "最高价": 37.86,
        "最低价": 37.64,
        "昨收价": 37.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 7951.0,
        "成交额": 300330.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.EFAX"
    },
    {
        "序号": 5088,
        "name": "金属采矿ETF-SPDR",
        "最新价": 54.01,
        "涨跌额": 0.1,
        "涨跌幅": 0.19,
        "开盘价": 53.89,
        "最高价": 54.75,
        "最低价": 53.68,
        "昨收价": 53.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 2764979.0,
        "成交额": 149673940.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.XME"
    },
    {
        "序号": 5089,
        "name": "Monterey Capital Acquisition Co",
        "最新价": 10.82,
        "涨跌额": 0.02,
        "涨跌幅": 0.19,
        "开盘价": 10.8,
        "最高价": 10.83,
        "最低价": 10.8,
        "昨收价": 10.8,
        "总市值": 104695673.0,
        "市盈率": -8.45,
        "成交量": 9743.0,
        "成交额": 105267.0,
        "振幅": 0.28,
        "换手率": 0.1,
        "symbol": "105.MCAC"
    },
    {
        "序号": 5090,
        "name": "辛辛那提金融",
        "最新价": 102.8,
        "涨跌额": 0.19,
        "涨跌幅": 0.19,
        "开盘价": 102.77,
        "最高价": 103.11,
        "最低价": 102.1,
        "昨收价": 102.61,
        "总市值": 16130113616.0,
        "市盈率": 9.64,
        "成交量": 478243.0,
        "成交额": 49127183.0,
        "振幅": 0.98,
        "换手率": 0.3,
        "symbol": "105.CINF"
    },
    {
        "序号": 5091,
        "name": "Global X Aging Population ETF",
        "最新价": 27.07,
        "涨跌额": 0.05,
        "涨跌幅": 0.19,
        "开盘价": 26.97,
        "最高价": 27.07,
        "最低价": 26.95,
        "昨收价": 27.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1270.0,
        "成交额": 34271.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.AGNG"
    },
    {
        "序号": 5092,
        "name": "PepperLime Health Acquisition C",
        "最新价": 10.87,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "开盘价": 10.87,
        "最高价": 10.87,
        "最低价": 10.87,
        "昨收价": 10.85,
        "总市值": 55044158.0,
        "市盈率": 67.45,
        "成交量": 1009.0,
        "成交额": 10967.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.PEPL"
    },
    {
        "序号": 5093,
        "name": "美国国际集团",
        "最新价": 65.53,
        "涨跌额": 0.12,
        "涨跌幅": 0.18,
        "开盘价": 65.58,
        "最高价": 65.85,
        "最低价": 65.2,
        "昨收价": 65.41,
        "总市值": 46004703677.0,
        "市盈率": 11.08,
        "成交量": 2922211.0,
        "成交额": 191675633.0,
        "振幅": 0.99,
        "换手率": 0.42,
        "symbol": "106.AIG"
    },
    {
        "序号": 5094,
        "name": "Lakeshore Acquisition II Corp",
        "最新价": 10.95,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "开盘价": 10.95,
        "最高价": 10.95,
        "最低价": 10.95,
        "昨收价": 10.93,
        "总市值": 62737171.0,
        "市盈率": 41.86,
        "成交量": 105.0,
        "成交额": 1149.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.LBBB"
    },
    {
        "序号": 5095,
        "name": "iShares MSCI United Kingdom Sma",
        "最新价": 33.06,
        "涨跌额": 0.06,
        "涨跌幅": 0.18,
        "开盘价": 33.19,
        "最高价": 33.2,
        "最低价": 32.94,
        "昨收价": 33.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2309.0,
        "成交额": 76458.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.EWUS"
    },
    {
        "序号": 5096,
        "name": "Civista Bancshares Inc",
        "最新价": 16.55,
        "涨跌额": 0.03,
        "涨跌幅": 0.18,
        "开盘价": 16.51,
        "最高价": 16.73,
        "最低价": 16.46,
        "昨收价": 16.52,
        "总市值": 259768750.0,
        "市盈率": 5.71,
        "成交量": 27542.0,
        "成交额": 455886.0,
        "振幅": 1.63,
        "换手率": 0.18,
        "symbol": "105.CIVB"
    },
    {
        "序号": 5097,
        "name": "First Trust Alternative Absolut",
        "最新价": 27.59,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 27.55,
        "最高价": 27.66,
        "最低价": 27.54,
        "昨收价": 27.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 20410.0,
        "成交额": 562991.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.FAAR"
    },
    {
        "序号": 5098,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.64,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 27.64,
        "最高价": 27.64,
        "最低价": 27.64,
        "昨收价": 27.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 670.0,
        "成交额": 18517.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MARW"
    },
    {
        "序号": 5099,
        "name": "Invesco Dividend Achievers ETF",
        "最新价": 38.75,
        "涨跌额": 0.07,
        "涨跌幅": 0.18,
        "开盘价": 38.62,
        "最高价": 38.8,
        "最低价": 38.59,
        "昨收价": 38.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 18128.0,
        "成交额": 701294.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "105.PFM"
    },
    {
        "序号": 5100,
        "name": "Pacer Global Cash Cows Dividend",
        "最新价": 33.23,
        "涨跌额": 0.06,
        "涨跌幅": 0.18,
        "开盘价": 33.2,
        "最高价": 33.3,
        "最低价": 33.1,
        "昨收价": 33.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 282968.0,
        "成交额": 9393752.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.GCOW"
    },
    {
        "序号": 5101,
        "name": "Evergreen Corp-A",
        "最新价": 11.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "开盘价": 11.1,
        "最高价": 11.12,
        "最低价": 11.1,
        "昨收价": 11.1,
        "总市值": 121243250.0,
        "市盈率": 31.22,
        "成交量": 659.0,
        "成交额": 7319.0,
        "振幅": 0.18,
        "换手率": 0.01,
        "symbol": "105.EVGR"
    },
    {
        "序号": 5102,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 27.91,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 27.83,
        "最高价": 27.94,
        "最低价": 27.81,
        "昨收价": 27.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 92679.0,
        "成交额": 2583595.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.DECW"
    },
    {
        "序号": 5103,
        "name": "ALPS O'Shares U.S. Quality Divi",
        "最新价": 44.72,
        "涨跌额": 0.08,
        "涨跌幅": 0.18,
        "开盘价": 44.61,
        "最高价": 44.79,
        "最低价": 44.48,
        "昨收价": 44.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 42267.0,
        "成交额": 1884823.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.OUSA"
    },
    {
        "序号": 5104,
        "name": "Alpha Star Acquisition Corp",
        "最新价": 11.19,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "开盘价": 11.19,
        "最高价": 11.19,
        "最低价": 11.19,
        "昨收价": 11.17,
        "总市值": 137284549.0,
        "市盈率": 29.81,
        "成交量": 214.0,
        "成交额": 2394.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ALSA"
    },
    {
        "序号": 5105,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 39.19,
        "涨跌额": 0.07,
        "涨跌幅": 0.18,
        "开盘价": 39.09,
        "最高价": 39.27,
        "最低价": 39.09,
        "昨收价": 39.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 63086.0,
        "成交额": 2468932.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.FSEP"
    },
    {
        "序号": 5106,
        "name": "iShares Trust iShares 20+ Year ",
        "最新价": 28.0,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 27.95,
        "最高价": 28.0,
        "最低价": 27.94,
        "昨收价": 27.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 659977.0,
        "成交额": 18454904.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.TLTW"
    },
    {
        "序号": 5107,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 28.03,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 28.04,
        "最高价": 28.04,
        "最低价": 28.03,
        "昨收价": 27.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 892.0,
        "成交额": 25011.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.NVBW"
    },
    {
        "序号": 5108,
        "name": "Strive 1000 Dividend Growth ETF",
        "最新价": 28.05,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 27.94,
        "最高价": 28.08,
        "最低价": 27.92,
        "昨收价": 28.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 3622.0,
        "成交额": 101232.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "105.STXD"
    },
    {
        "序号": 5109,
        "name": "NatWest Group plc ADR",
        "最新价": 5.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.18,
        "开盘价": 5.58,
        "最高价": 5.66,
        "最低价": 5.58,
        "昨收价": 5.6,
        "总市值": 24691832685.0,
        "市盈率": 5.18,
        "成交量": 1711185.0,
        "成交额": 9611044.0,
        "振幅": 1.43,
        "换手率": 0.04,
        "symbol": "106.NWG"
    },
    {
        "序号": 5110,
        "name": "IQ Healthy Hearts ETF",
        "最新价": 28.08,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 28.06,
        "最高价": 28.08,
        "最低价": 28.06,
        "昨收价": 28.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 305.0,
        "成交额": 8558.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.HART"
    },
    {
        "序号": 5111,
        "name": "Monarch Blue Chips Core ETF",
        "最新价": 28.17,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 28.16,
        "最高价": 28.18,
        "最低价": 28.04,
        "昨收价": 28.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 17687.0,
        "成交额": 497710.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.MBCC"
    },
    {
        "序号": 5112,
        "name": "铿腾电子",
        "最新价": 259.93,
        "涨跌额": 0.46,
        "涨跌幅": 0.18,
        "开盘价": 257.56,
        "最高价": 261.58,
        "最低价": 257.56,
        "昨收价": 259.47,
        "总市值": 70717075660.0,
        "市盈率": 73.85,
        "成交量": 1049287.0,
        "成交额": 272825632.0,
        "振幅": 1.55,
        "换手率": 0.39,
        "symbol": "105.CDNS"
    },
    {
        "序号": 5113,
        "name": "Global X NASDAQ 100 Risk Manage",
        "最新价": 17.01,
        "涨跌额": 0.03,
        "涨跌幅": 0.18,
        "开盘价": 17.01,
        "最高价": 17.01,
        "最低价": 16.98,
        "昨收价": 16.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 3492.0,
        "成交额": 59352.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.QRMI"
    },
    {
        "序号": 5114,
        "name": "星巴克",
        "最新价": 96.61,
        "涨跌额": 0.17,
        "涨跌幅": 0.18,
        "开盘价": 96.08,
        "最高价": 96.86,
        "最低价": 95.81,
        "昨收价": 96.44,
        "总市值": 109816587000.0,
        "市盈率": 26.63,
        "成交量": 7125234.0,
        "成交额": 687788528.0,
        "振幅": 1.09,
        "换手率": 0.63,
        "symbol": "105.SBUX"
    },
    {
        "序号": 5115,
        "name": "iShares Edge MSCI Min Vol EAFE ",
        "最新价": 68.21,
        "涨跌额": 0.12,
        "涨跌幅": 0.18,
        "开盘价": 67.85,
        "最高价": 68.28,
        "最低价": 67.85,
        "昨收价": 68.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 683408.0,
        "成交额": 46559690.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.EFAV"
    },
    {
        "序号": 5116,
        "name": "First Trust Multi Cap Value Alp",
        "最新价": 73.98,
        "涨跌额": 0.13,
        "涨跌幅": 0.18,
        "开盘价": 73.72,
        "最高价": 74.18,
        "最低价": 73.72,
        "昨收价": 73.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 3100.0,
        "成交额": 229188.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.FAB"
    },
    {
        "序号": 5117,
        "name": "T. Rowe Price Dividend Growth E",
        "最新价": 34.16,
        "涨跌额": 0.06,
        "涨跌幅": 0.18,
        "开盘价": 34.11,
        "最高价": 34.18,
        "最低价": 34.01,
        "昨收价": 34.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 23787.0,
        "成交额": 811467.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.TDVG"
    },
    {
        "序号": 5118,
        "name": "IQ Engender Equality ETF",
        "最新价": 22.78,
        "涨跌额": 0.04,
        "涨跌幅": 0.18,
        "开盘价": 22.78,
        "最高价": 22.78,
        "最低价": 22.78,
        "昨收价": 22.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EQUL"
    },
    {
        "序号": 5119,
        "name": "Xtrackers MSCI EAFE ESG Leaders",
        "最新价": 28.48,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 28.38,
        "最高价": 28.48,
        "最低价": 28.29,
        "昨收价": 28.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 3100.0,
        "成交额": 88024.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.EASG"
    },
    {
        "序号": 5120,
        "name": "第一金融银行股份(德克萨斯州)",
        "最新价": 28.52,
        "涨跌额": 0.05,
        "涨跌幅": 0.18,
        "开盘价": 28.47,
        "最高价": 28.89,
        "最低价": 28.05,
        "昨收价": 28.47,
        "总市值": 4070069635.0,
        "市盈率": 19.23,
        "成交量": 339561.0,
        "成交额": 9713264.0,
        "振幅": 2.95,
        "换手率": 0.24,
        "symbol": "105.FFIN"
    },
    {
        "序号": 5121,
        "name": "Simplify Volatility Premium ETF",
        "最新价": 22.82,
        "涨跌额": 0.04,
        "涨跌幅": 0.18,
        "开盘价": 22.78,
        "最高价": 22.82,
        "最低价": 22.75,
        "昨收价": 22.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 277032.0,
        "成交额": 6312895.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.SVOL"
    },
    {
        "序号": 5122,
        "name": "简柏特",
        "最新价": 34.36,
        "涨跌额": 0.06,
        "涨跌幅": 0.17,
        "开盘价": 34.3,
        "最高价": 34.65,
        "最低价": 34.26,
        "昨收价": 34.3,
        "总市值": 6233330030.0,
        "市盈率": 14.51,
        "成交量": 771666.0,
        "成交额": 26591500.0,
        "振幅": 1.14,
        "换手率": 0.43,
        "symbol": "106.G"
    },
    {
        "序号": 5123,
        "name": "Prosperity Bancshares Inc",
        "最新价": 63.04,
        "涨跌额": 0.11,
        "涨跌幅": 0.17,
        "开盘价": 62.87,
        "最高价": 63.64,
        "最低价": 62.79,
        "昨收价": 62.93,
        "总市值": 5907790700.0,
        "市盈率": 12.8,
        "成交量": 465754.0,
        "成交额": 29416413.0,
        "振幅": 1.35,
        "换手率": 0.5,
        "symbol": "106.PB"
    },
    {
        "序号": 5124,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 34.39,
        "涨跌额": 0.06,
        "涨跌幅": 0.17,
        "开盘价": 34.33,
        "最高价": 34.43,
        "最低价": 34.29,
        "昨收价": 34.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 221645.0,
        "成交额": 7619371.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.PDEC"
    },
    {
        "序号": 5125,
        "name": "Franklin U.S. Mid Cap Multifact",
        "最新价": 45.95,
        "涨跌额": 0.08,
        "涨跌幅": 0.17,
        "开盘价": 45.94,
        "最高价": 46.18,
        "最低价": 45.86,
        "昨收价": 45.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 21811.0,
        "成交额": 1002543.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.FLQM"
    },
    {
        "序号": 5126,
        "name": "MainStreet Bancshares Inc Serie",
        "最新价": 23.0,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.21,
        "最高价": 23.21,
        "最低价": 23.0,
        "昨收价": 22.96,
        "总市值": 661250.0,
        "市盈率": null,
        "成交量": 912.0,
        "成交额": 21129.0,
        "振幅": 0.91,
        "换手率": 3.17,
        "symbol": "105.MNSBP"
    },
    {
        "序号": 5127,
        "name": "Blackstone\/GSO Long-Short Credi",
        "最新价": 11.52,
        "涨跌额": 0.02,
        "涨跌幅": 0.17,
        "开盘价": 11.48,
        "最高价": 11.58,
        "最低价": 11.48,
        "昨收价": 11.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 42283.0,
        "成交额": 487080.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "106.BGX"
    },
    {
        "序号": 5128,
        "name": "Point Bridge America First ETF",
        "最新价": 40.4,
        "涨跌额": 0.07,
        "涨跌幅": 0.17,
        "开盘价": 40.28,
        "最高价": 40.4,
        "最低价": 40.28,
        "昨收价": 40.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 543.0,
        "成交额": 21895.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.MAGA"
    },
    {
        "序号": 5129,
        "name": "Global X S&P Catholic Values De",
        "最新价": 28.87,
        "涨跌额": 0.05,
        "涨跌幅": 0.17,
        "开盘价": 28.87,
        "最高价": 28.87,
        "最低价": 28.87,
        "昨收价": 28.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 86.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CEFA"
    },
    {
        "序号": 5130,
        "name": "WisdomTree U.S. AI Enhanced Val",
        "最新价": 92.44,
        "涨跌额": 0.16,
        "涨跌幅": 0.17,
        "开盘价": 92.45,
        "最高价": 92.54,
        "最低价": 92.27,
        "昨收价": 92.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 1897.0,
        "成交额": 175252.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.AIVL"
    },
    {
        "序号": 5131,
        "name": "Invesco ESG S&P 500 Equal Weigh",
        "最新价": 23.12,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.14,
        "最高价": 23.15,
        "最低价": 23.07,
        "昨收价": 23.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 35651.0,
        "成交额": 824921.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.RSPE"
    },
    {
        "序号": 5132,
        "name": "Return Stacked Bonds & Managed ",
        "最新价": 17.34,
        "涨跌额": 0.03,
        "涨跌幅": 0.17,
        "开盘价": 17.22,
        "最高价": 17.34,
        "最低价": 17.19,
        "昨收价": 17.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 23261.0,
        "成交额": 401321.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.RSBT"
    },
    {
        "序号": 5133,
        "name": "SPDR Global Dow ETF",
        "最新价": 115.68,
        "涨跌额": 0.2,
        "涨跌幅": 0.17,
        "开盘价": 115.13,
        "最高价": 115.7,
        "最低价": 115.13,
        "昨收价": 115.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 3478.0,
        "成交额": 401994.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.DGT"
    },
    {
        "序号": 5134,
        "name": "CNO Financial Group Inc Debentu",
        "最新价": 17.38,
        "涨跌额": 0.03,
        "涨跌幅": 0.17,
        "开盘价": 17.38,
        "最高价": 17.38,
        "最低价": 17.38,
        "昨收价": 17.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 447.0,
        "成交额": 7768.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.CNO_A"
    },
    {
        "序号": 5135,
        "name": "scPharmaceuticals Inc",
        "最新价": 5.81,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "开盘价": 5.79,
        "最高价": 6.0,
        "最低价": 5.77,
        "昨收价": 5.8,
        "总市值": 208349488.0,
        "市盈率": -4.15,
        "成交量": 167782.0,
        "成交额": 982133.0,
        "振幅": 3.97,
        "换手率": 0.47,
        "symbol": "105.SCPH"
    },
    {
        "序号": 5136,
        "name": "ALPS International Sector Divid",
        "最新价": 29.05,
        "涨跌额": 0.05,
        "涨跌幅": 0.17,
        "开盘价": 28.91,
        "最高价": 29.07,
        "最低价": 28.91,
        "昨收价": 29.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 16190.0,
        "成交额": 469663.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.IDOG"
    },
    {
        "序号": 5137,
        "name": "Nuveen ESG International Develo",
        "最新价": 29.06,
        "涨跌额": 0.05,
        "涨跌幅": 0.17,
        "开盘价": 28.91,
        "最高价": 29.11,
        "最低价": 28.9,
        "昨收价": 29.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 24508.0,
        "成交额": 711329.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.NUDM"
    },
    {
        "序号": 5138,
        "name": "Chimera Investment Corp Series ",
        "最新价": 23.25,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.12,
        "最高价": 23.28,
        "最低价": 22.86,
        "昨收价": 23.21,
        "总市值": 186000000.0,
        "市盈率": null,
        "成交量": 18379.0,
        "成交额": 425545.0,
        "振幅": 1.81,
        "换手率": 0.23,
        "symbol": "106.CIM_D"
    },
    {
        "序号": 5139,
        "name": "沃茨水工业",
        "最新价": 197.69,
        "涨跌额": 0.34,
        "涨跌幅": 0.17,
        "开盘价": 197.29,
        "最高价": 200.06,
        "最低价": 197.29,
        "昨收价": 197.35,
        "总市值": 6588265374.0,
        "市盈率": 23.96,
        "成交量": 100341.0,
        "成交额": 19868855.0,
        "振幅": 1.4,
        "换手率": 0.3,
        "symbol": "106.WTS"
    },
    {
        "序号": 5140,
        "name": "Iris Energy Ltd",
        "最新价": 5.82,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "开盘价": 5.9,
        "最高价": 6.22,
        "最低价": 5.68,
        "昨收价": 5.81,
        "总市值": 441385325.0,
        "市盈率": -2.57,
        "成交量": 5073758.0,
        "成交额": 29921715.0,
        "振幅": 9.29,
        "换手率": 6.69,
        "symbol": "105.IREN"
    },
    {
        "序号": 5141,
        "name": "Richmond Mutual Bancorporation ",
        "最新价": 11.65,
        "涨跌额": 0.02,
        "涨跌幅": 0.17,
        "开盘价": 11.24,
        "最高价": 11.65,
        "最低价": 11.24,
        "昨收价": 11.63,
        "总市值": 131000394.0,
        "市盈率": 12.07,
        "成交量": 1290.0,
        "成交额": 14684.0,
        "振幅": 3.53,
        "换手率": 0.01,
        "symbol": "105.RMBI"
    },
    {
        "序号": 5142,
        "name": "Hallador Energy Co",
        "最新价": 11.7,
        "涨跌额": 0.02,
        "涨跌幅": 0.17,
        "开盘价": 11.73,
        "最高价": 11.93,
        "最低价": 11.65,
        "昨收价": 11.68,
        "总市值": 387766115.0,
        "市盈率": 4.56,
        "成交量": 109653.0,
        "成交额": 1284620.0,
        "振幅": 2.4,
        "换手率": 0.33,
        "symbol": "105.HNRG"
    },
    {
        "序号": 5143,
        "name": "iShares Core High Dividend ETF",
        "最新价": 100.11,
        "涨跌额": 0.17,
        "涨跌幅": 0.17,
        "开盘价": 100.01,
        "最高价": 100.36,
        "最低价": 99.81,
        "昨收价": 99.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 764676.0,
        "成交额": 76500300.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.HDV"
    },
    {
        "序号": 5144,
        "name": "惠普",
        "最新价": 29.46,
        "涨跌额": 0.05,
        "涨跌幅": 0.17,
        "开盘价": 29.4,
        "最高价": 29.59,
        "最低价": 29.31,
        "昨收价": 29.41,
        "总市值": 29114397994.0,
        "市盈率": 8.92,
        "成交量": 7563303.0,
        "成交额": 222924890.0,
        "振幅": 0.95,
        "换手率": 0.77,
        "symbol": "106.HPQ"
    },
    {
        "序号": 5145,
        "name": "Pulmonx Corp",
        "最新价": 11.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.17,
        "开盘价": 11.66,
        "最高价": 11.97,
        "最低价": 11.28,
        "昨收价": 11.77,
        "总市值": 451652652.0,
        "市盈率": -7.38,
        "成交量": 174018.0,
        "成交额": 2059106.0,
        "振幅": 5.86,
        "换手率": 0.45,
        "symbol": "105.LUNG"
    },
    {
        "序号": 5146,
        "name": "BlackRock Intermediate Muni Inc",
        "最新价": 23.59,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.54,
        "最高价": 23.59,
        "最低价": 23.52,
        "昨收价": 23.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 6199.0,
        "成交额": 145995.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.INMU"
    },
    {
        "序号": 5147,
        "name": "科达章鱼集团",
        "最新价": 5.9,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "开盘价": 5.93,
        "最高价": 6.02,
        "最低价": 5.86,
        "昨收价": 5.89,
        "总市值": 65594401.0,
        "市盈率": 15.81,
        "成交量": 7747.0,
        "成交额": 45802.0,
        "振幅": 2.72,
        "换手率": 0.07,
        "symbol": "105.CODA"
    },
    {
        "序号": 5148,
        "name": "Vident International Equity Str",
        "最新价": 23.62,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.48,
        "最高价": 23.7,
        "最低价": 23.48,
        "昨收价": 23.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 5061.0,
        "成交额": 119478.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.VIDI"
    },
    {
        "序号": 5149,
        "name": "Pacer Swan SOS Conservative (Oc",
        "最新价": 23.64,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.58,
        "最高价": 23.64,
        "最低价": 23.58,
        "昨收价": 23.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 2502.0,
        "成交额": 58999.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.PSCQ"
    },
    {
        "序号": 5150,
        "name": "Rollins Inc",
        "最新价": 41.43,
        "涨跌额": 0.07,
        "涨跌幅": 0.17,
        "开盘价": 41.41,
        "最高价": 41.61,
        "最低价": 41.28,
        "昨收价": 41.36,
        "总市值": 20053688457.0,
        "市盈率": 48.86,
        "成交量": 1412677.0,
        "成交额": 58563694.0,
        "振幅": 0.8,
        "换手率": 0.29,
        "symbol": "106.ROL"
    },
    {
        "序号": 5151,
        "name": "Invesco International Dividend ",
        "最新价": 17.78,
        "涨跌额": 0.03,
        "涨跌幅": 0.17,
        "开盘价": 17.75,
        "最高价": 17.81,
        "最低价": 17.7,
        "昨收价": 17.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 106476.0,
        "成交额": 1890501.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.PID"
    },
    {
        "序号": 5152,
        "name": "Crescent Capital BDC Inc Note",
        "最新价": 23.74,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.7,
        "最高价": 23.74,
        "最低价": 23.49,
        "昨收价": 23.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 1941.0,
        "成交额": 45914.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.FCRX"
    },
    {
        "序号": 5153,
        "name": "艾伦建材",
        "最新价": 65.34,
        "涨跌额": 0.11,
        "涨跌幅": 0.17,
        "开盘价": 64.77,
        "最高价": 66.44,
        "最低价": 64.39,
        "昨收价": 65.23,
        "总市值": 5308538956.0,
        "市盈率": 31.32,
        "成交量": 298892.0,
        "成交额": 19502903.0,
        "振幅": 3.14,
        "换手率": 0.37,
        "symbol": "105.AAON"
    },
    {
        "序号": 5154,
        "name": "Morgan Dempsey Large Cap Value ",
        "最新价": 23.78,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 24.13,
        "最高价": 24.13,
        "最低价": 23.66,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 16775.0,
        "成交额": 399694.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.MDLV"
    },
    {
        "序号": 5155,
        "name": "Schneider National Inc-B",
        "最新价": 23.84,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.77,
        "最高价": 24.03,
        "最低价": 23.62,
        "昨收价": 23.8,
        "总市值": 4206488255.0,
        "市盈率": 13.1,
        "成交量": 531132.0,
        "成交额": 12635041.0,
        "振幅": 1.72,
        "换手率": 0.3,
        "symbol": "106.SNDR"
    },
    {
        "序号": 5156,
        "name": "Putnam Managed Municipal Income",
        "最新价": 5.98,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "开盘价": 5.96,
        "最高价": 6.0,
        "最低价": 5.95,
        "昨收价": 5.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 86891.0,
        "成交额": 518882.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "106.PMM"
    },
    {
        "序号": 5157,
        "name": "Brookfield Reinsurance Ltd-A",
        "最新价": 35.9,
        "涨跌额": 0.06,
        "涨跌幅": 0.17,
        "开盘价": 36.06,
        "最高价": 36.06,
        "最低价": 35.9,
        "昨收价": 35.84,
        "总市值": 5475889322.0,
        "市盈率": 10.67,
        "成交量": 1626.0,
        "成交额": 58441.0,
        "振幅": 0.45,
        "换手率": 0.0,
        "symbol": "106.BNRE"
    },
    {
        "序号": 5158,
        "name": "WisdomTree International LargeC",
        "最新价": 47.94,
        "涨跌额": 0.08,
        "涨跌幅": 0.17,
        "开盘价": 47.72,
        "最高价": 48.0,
        "最低价": 47.72,
        "昨收价": 47.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 40140.0,
        "成交额": 1923273.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DOL"
    },
    {
        "序号": 5159,
        "name": "Morgan Stanley India Investment",
        "最新价": 24.0,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 23.85,
        "最高价": 24.12,
        "最低价": 23.85,
        "昨收价": 23.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 121733.0,
        "成交额": 2920907.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "106.IIF"
    },
    {
        "序号": 5160,
        "name": "医疗ETF-SPDR",
        "最新价": 132.07,
        "涨跌额": 0.22,
        "涨跌幅": 0.17,
        "开盘价": 131.94,
        "最高价": 132.38,
        "最低价": 131.28,
        "昨收价": 131.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 6007674.0,
        "成交额": 792060272.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.XLV"
    },
    {
        "序号": 5161,
        "name": "Summit Midstream Partners LP",
        "最新价": 18.02,
        "涨跌额": 0.03,
        "涨跌幅": 0.17,
        "开盘价": 18.17,
        "最高价": 18.33,
        "最低价": 17.72,
        "昨收价": 17.99,
        "总市值": 186978926.0,
        "市盈率": -3.92,
        "成交量": 16683.0,
        "成交额": 299961.0,
        "振幅": 3.39,
        "换手率": 0.16,
        "symbol": "106.SMLP"
    },
    {
        "序号": 5162,
        "name": "UGI Corp Corporate Units",
        "最新价": 54.2,
        "涨跌额": 0.09,
        "涨跌幅": 0.17,
        "开盘价": 54.36,
        "最高价": 54.48,
        "最低价": 53.57,
        "昨收价": 54.11,
        "总市值": 11430757399.0,
        "市盈率": -7.61,
        "成交量": 2396.0,
        "成交额": 129479.0,
        "振幅": 1.68,
        "换手率": null,
        "symbol": "106.UGIC"
    },
    {
        "序号": 5163,
        "name": "Matthews Korea Active ETF",
        "最新价": 24.14,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "开盘价": 24.04,
        "最高价": 24.17,
        "最低价": 24.03,
        "昨收价": 24.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 6134.0,
        "成交额": 147581.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.MKOR"
    },
    {
        "序号": 5164,
        "name": "美元红利股ETF-Schwab",
        "最新价": 72.52,
        "涨跌额": 0.12,
        "涨跌幅": 0.17,
        "开盘价": 72.47,
        "最高价": 72.76,
        "最低价": 72.26,
        "昨收价": 72.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 6447175.0,
        "成交额": 467444704.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.SCHD"
    },
    {
        "序号": 5165,
        "name": "Invesco S&P SmallCap Low Volati",
        "最新价": 42.32,
        "涨跌额": 0.07,
        "涨跌幅": 0.17,
        "开盘价": 42.22,
        "最高价": 42.44,
        "最低价": 42.09,
        "昨收价": 42.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 56449.0,
        "成交额": 2386967.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.XSLV"
    },
    {
        "序号": 5166,
        "name": "威富",
        "最新价": 18.14,
        "涨跌额": 0.03,
        "涨跌幅": 0.17,
        "开盘价": 18.15,
        "最高价": 18.41,
        "最低价": 18.06,
        "昨收价": 18.11,
        "总市值": 7054344949.0,
        "市盈率": -32.79,
        "成交量": 3925367.0,
        "成交额": 71500393.0,
        "振幅": 1.93,
        "换手率": 1.01,
        "symbol": "106.VFC"
    },
    {
        "序号": 5167,
        "name": "Liberty All-Star Equity Fund",
        "最新价": 6.08,
        "涨跌额": 0.01,
        "涨跌幅": 0.16,
        "开盘价": 6.07,
        "最高价": 6.09,
        "最低价": 6.03,
        "昨收价": 6.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 665814.0,
        "成交额": 4032969.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "106.USA"
    },
    {
        "序号": 5168,
        "name": "iShares Interest Rate Hedged Lo",
        "最新价": 24.33,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 24.24,
        "最高价": 24.38,
        "最低价": 24.24,
        "昨收价": 24.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 15219.0,
        "成交额": 370339.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.IGBH"
    },
    {
        "序号": 5169,
        "name": "Goldman Sachs ActiveBeta World ",
        "最新价": 42.63,
        "涨跌额": 0.07,
        "涨跌幅": 0.16,
        "开盘价": 42.52,
        "最高价": 42.66,
        "最低价": 42.48,
        "昨收价": 42.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 34310.0,
        "成交额": 1458569.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.GLOV"
    },
    {
        "序号": 5170,
        "name": "Ellington Residential Mortgage ",
        "最新价": 6.09,
        "涨跌额": 0.01,
        "涨跌幅": 0.16,
        "开盘价": 6.12,
        "最高价": 6.12,
        "最低价": 6.07,
        "昨收价": 6.08,
        "总市值": 99441704.0,
        "市盈率": 26.17,
        "成交量": 157177.0,
        "成交额": 957122.0,
        "振幅": 0.82,
        "换手率": 0.96,
        "symbol": "106.EARN"
    },
    {
        "序号": 5171,
        "name": "iShares U.S. Healthcare ETF",
        "最新价": 276.0,
        "涨跌额": 0.45,
        "涨跌幅": 0.16,
        "开盘价": 275.81,
        "最高价": 276.61,
        "最低价": 274.41,
        "昨收价": 275.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 63566.0,
        "成交额": 17477437.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.IYH"
    },
    {
        "序号": 5172,
        "name": "Global X Health Care Covered Ca",
        "最新价": 24.54,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 24.54,
        "最高价": 24.54,
        "最低价": 24.54,
        "昨收价": 24.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 11.0,
        "成交额": 269.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HYLG"
    },
    {
        "序号": 5173,
        "name": "Xtrackers S&P ESG Dividend Aris",
        "最新价": 24.55,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 24.56,
        "最高价": 24.6,
        "最低价": 24.46,
        "昨收价": 24.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 4686.0,
        "成交额": 114929.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.SNPD"
    },
    {
        "序号": 5174,
        "name": "iShares ESG Advanced MSCI EAFE ",
        "最新价": 61.48,
        "涨跌额": 0.1,
        "涨跌幅": 0.16,
        "开盘价": 61.17,
        "最高价": 61.67,
        "最低价": 61.17,
        "昨收价": 61.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 13683.0,
        "成交额": 841202.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "105.DMXF"
    },
    {
        "序号": 5175,
        "name": "iShares Global Equity Factor ET",
        "最新价": 36.91,
        "涨跌额": 0.06,
        "涨跌幅": 0.16,
        "开盘价": 36.69,
        "最高价": 36.99,
        "最低价": 36.69,
        "昨收价": 36.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 2968.0,
        "成交额": 109605.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.GLOF"
    },
    {
        "序号": 5176,
        "name": "雅乐科技",
        "最新价": 6.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.16,
        "开盘价": 6.18,
        "最高价": 6.25,
        "最低价": 6.06,
        "昨收价": 6.16,
        "总市值": 963992063.0,
        "市盈率": 9.37,
        "成交量": 1334396.0,
        "成交额": 8233760.0,
        "振幅": 3.08,
        "换手率": 0.85,
        "symbol": "106.YALA"
    },
    {
        "序号": 5177,
        "name": "Pactiv Evergreen Inc",
        "最新价": 12.38,
        "涨跌额": 0.02,
        "涨跌幅": 0.16,
        "开盘价": 12.42,
        "最高价": 12.57,
        "最低价": 12.26,
        "昨收价": 12.36,
        "总市值": 2209279783.0,
        "市盈率": -10.13,
        "成交量": 279017.0,
        "成交额": 3454340.0,
        "振幅": 2.51,
        "换手率": 0.16,
        "symbol": "105.PTVE"
    },
    {
        "序号": 5178,
        "name": "Cannae Holdings Inc",
        "最新价": 18.59,
        "涨跌额": 0.03,
        "涨跌幅": 0.16,
        "开盘价": 18.61,
        "最高价": 18.7,
        "最低价": 18.34,
        "昨收价": 18.56,
        "总市值": 1314535597.0,
        "市盈率": -5.95,
        "成交量": 473019.0,
        "成交额": 8758356.0,
        "振幅": 1.94,
        "换手率": 0.67,
        "symbol": "106.CNNE"
    },
    {
        "序号": 5179,
        "name": "Dynagas LNG Partners LP Series ",
        "最新价": 24.85,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 24.9,
        "最高价": 24.9,
        "最低价": 24.85,
        "昨收价": 24.81,
        "总市值": 54670000.0,
        "市盈率": null,
        "成交量": 1900.0,
        "成交额": 47245.0,
        "振幅": 0.2,
        "换手率": 0.09,
        "symbol": "106.DLNG_B"
    },
    {
        "序号": 5180,
        "name": "佳明",
        "最新价": 124.59,
        "涨跌额": 0.2,
        "涨跌幅": 0.16,
        "开盘价": 124.47,
        "最高价": 124.92,
        "最低价": 124.14,
        "昨收价": 124.39,
        "总市值": 23837938883.0,
        "市盈率": 22.9,
        "成交量": 644590.0,
        "成交额": 80299341.0,
        "振幅": 0.63,
        "换手率": 0.34,
        "symbol": "106.GRMN"
    },
    {
        "序号": 5181,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 31.16,
        "涨跌额": 0.05,
        "涨跌幅": 0.16,
        "开盘价": 31.09,
        "最高价": 31.16,
        "最低价": 31.09,
        "昨收价": 31.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 6508.0,
        "成交额": 202639.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.UNOV"
    },
    {
        "序号": 5182,
        "name": "ABM工业",
        "最新价": 43.67,
        "涨跌额": 0.07,
        "涨跌幅": 0.16,
        "开盘价": 43.48,
        "最高价": 43.97,
        "最低价": 43.44,
        "昨收价": 43.6,
        "总市值": 2861789165.0,
        "市盈率": 12.06,
        "成交量": 312797.0,
        "成交额": 13650978.0,
        "振幅": 1.22,
        "换手率": 0.48,
        "symbol": "106.ABM"
    },
    {
        "序号": 5183,
        "name": "ProShares High Yield Interest R",
        "最新价": 62.4,
        "涨跌额": 0.1,
        "涨跌幅": 0.16,
        "开盘价": 62.5,
        "最高价": 62.57,
        "最低价": 62.22,
        "昨收价": 62.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 9586.0,
        "成交额": 597940.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.HYHG"
    },
    {
        "序号": 5184,
        "name": "戴尔科技",
        "最新价": 68.7,
        "涨跌额": 0.11,
        "涨跌幅": 0.16,
        "开盘价": 68.43,
        "最高价": 69.31,
        "最低价": 68.43,
        "昨收价": 68.59,
        "总市值": 48886996326.0,
        "市盈率": 18.34,
        "成交量": 3003413.0,
        "成交额": 206870379.0,
        "振幅": 1.28,
        "换手率": 0.42,
        "symbol": "106.DELL"
    },
    {
        "序号": 5185,
        "name": "Equity Commonwealth Series D Pf",
        "最新价": 25.04,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 24.96,
        "最高价": 25.15,
        "最低价": 24.96,
        "昨收价": 25.0,
        "总市值": 123076508.0,
        "市盈率": null,
        "成交量": 1854.0,
        "成交额": 46426.0,
        "振幅": 0.76,
        "换手率": 0.04,
        "symbol": "106.EQC_D"
    },
    {
        "序号": 5186,
        "name": "PennyMac Mortgage Investment Tr",
        "最新价": 25.05,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.05,
        "最高价": 25.06,
        "最低价": 25.05,
        "昨收价": 25.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 733.0,
        "成交额": 18365.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "106.PMTU"
    },
    {
        "序号": 5187,
        "name": "WisdomTree PutWrite Strategy Fu",
        "最新价": 31.4,
        "涨跌额": 0.05,
        "涨跌幅": 0.16,
        "开盘价": 31.35,
        "最高价": 31.5,
        "最低价": 31.27,
        "昨收价": 31.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 12940.0,
        "成交额": 405520.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.PUTW"
    },
    {
        "序号": 5188,
        "name": "WisdomTree International Multif",
        "最新价": 25.15,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.11,
        "最高价": 25.15,
        "最低价": 25.03,
        "昨收价": 25.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 38501.0,
        "成交额": 964948.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.DWMF"
    },
    {
        "序号": 5189,
        "name": "AGNC Investment Corp Series C P",
        "最新价": 25.22,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.17,
        "最高价": 25.25,
        "最低价": 25.17,
        "昨收价": 25.18,
        "总市值": 327860.0,
        "市盈率": null,
        "成交量": 42317.0,
        "成交额": 1066375.0,
        "振幅": 0.32,
        "换手率": 325.52,
        "symbol": "105.AGNCN"
    },
    {
        "序号": 5190,
        "name": "WisdomTree 90\/60 U.S. Balanced ",
        "最新价": 37.85,
        "涨跌额": 0.06,
        "涨跌幅": 0.16,
        "开盘价": 37.73,
        "最高价": 37.89,
        "最低价": 37.63,
        "昨收价": 37.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 70972.0,
        "成交额": 2678263.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.NTSX"
    },
    {
        "序号": 5191,
        "name": "Innovator Premium Income 20 Bar",
        "最新价": 25.3,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.26,
        "最高价": 25.3,
        "最低价": 25.26,
        "昨收价": 25.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 1926.0,
        "成交额": 48652.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.JULH"
    },
    {
        "序号": 5192,
        "name": "John Hancock Multifactor Develo",
        "最新价": 31.65,
        "涨跌额": 0.05,
        "涨跌幅": 0.16,
        "开盘价": 31.51,
        "最高价": 31.69,
        "最低价": 31.49,
        "昨收价": 31.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 94841.0,
        "成交额": 2997475.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.JHMD"
    },
    {
        "序号": 5193,
        "name": "National Fuel Gas Co",
        "最新价": 50.72,
        "涨跌额": 0.08,
        "涨跌幅": 0.16,
        "开盘价": 50.7,
        "最高价": 51.12,
        "最低价": 50.35,
        "昨收价": 50.64,
        "总市值": 4657596703.0,
        "市盈率": 9.77,
        "成交量": 350336.0,
        "成交额": 17756765.0,
        "振幅": 1.52,
        "换手率": 0.38,
        "symbol": "106.NFG"
    },
    {
        "序号": 5194,
        "name": "Fidelity Enhanced International",
        "最新价": 25.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.27,
        "最高价": 25.46,
        "最低价": 25.27,
        "昨收价": 25.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 53281.0,
        "成交额": 1350294.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.FENI"
    },
    {
        "序号": 5195,
        "name": "Costamare Inc Pfd-C",
        "最新价": 25.38,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.27,
        "最高价": 25.38,
        "最低价": 25.27,
        "昨收价": 25.34,
        "总市值": 100838166.0,
        "市盈率": null,
        "成交量": 424.0,
        "成交额": 10727.0,
        "振幅": 0.43,
        "换手率": 0.01,
        "symbol": "106.CMRE_C"
    },
    {
        "序号": 5196,
        "name": "Panagram AAA CLO ETF",
        "最新价": 25.41,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.43,
        "最高价": 25.43,
        "最低价": 25.4,
        "昨收价": 25.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 8378.0,
        "成交额": 212924.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.CLOX"
    },
    {
        "序号": 5197,
        "name": "DoubleLine Shiller CAPE U.S. Eq",
        "最新价": 25.42,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.32,
        "最高价": 25.48,
        "最低价": 25.32,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 73570.0,
        "成交额": 1869824.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.CAPE"
    },
    {
        "序号": 5198,
        "name": "ROBO Global Healthcare Technolo",
        "最新价": 25.52,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.36,
        "最高价": 25.62,
        "最低价": 25.36,
        "昨收价": 25.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 15212.0,
        "成交额": 388204.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.HTEC"
    },
    {
        "序号": 5199,
        "name": "Zacks Earnings Consistent Portf",
        "最新价": 25.53,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.49,
        "最高价": 25.55,
        "最低价": 25.43,
        "昨收价": 25.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 4870.0,
        "成交额": 124169.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.ZECP"
    },
    {
        "序号": 5200,
        "name": "IQ Real Return ETF",
        "最新价": 25.59,
        "涨跌额": 0.04,
        "涨跌幅": 0.16,
        "开盘价": 25.47,
        "最高价": 25.59,
        "最低价": 25.47,
        "昨收价": 25.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 149.0,
        "成交额": 3795.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.CPI"
    },
    {
        "序号": 5201,
        "name": "水资源ETF-PowerShares",
        "最新价": 57.69,
        "涨跌额": 0.09,
        "涨跌幅": 0.16,
        "开盘价": 57.46,
        "最高价": 57.97,
        "最低价": 57.46,
        "昨收价": 57.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 64693.0,
        "成交额": 3730693.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "105.PHO"
    },
    {
        "序号": 5202,
        "name": "T. Rowe Price Floating Rate ETF",
        "最新价": 51.36,
        "涨跌额": 0.08,
        "涨跌幅": 0.16,
        "开盘价": 51.26,
        "最高价": 51.37,
        "最低价": 51.24,
        "昨收价": 51.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 19063.0,
        "成交额": 978449.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.TFLR"
    },
    {
        "序号": 5203,
        "name": "斯蒂加斯海运",
        "最新价": 6.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.16,
        "开盘价": 6.45,
        "最高价": 6.46,
        "最低价": 6.34,
        "昨收价": 6.42,
        "总市值": 227038966.0,
        "市盈率": 4.47,
        "成交量": 92856.0,
        "成交额": 594058.0,
        "振幅": 1.87,
        "换手率": 0.26,
        "symbol": "105.GASS"
    },
    {
        "序号": 5204,
        "name": "Invesco S&P 500 High Dividend G",
        "最新价": 25.85,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 25.83,
        "最高价": 25.88,
        "最低价": 25.83,
        "昨收价": 25.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 1197.0,
        "成交额": 30960.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.DIVG"
    },
    {
        "序号": 5205,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 32.33,
        "涨跌额": 0.05,
        "涨跌幅": 0.15,
        "开盘价": 32.23,
        "最高价": 32.33,
        "最低价": 32.22,
        "昨收价": 32.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 1445.0,
        "成交额": 46624.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.OCTW"
    },
    {
        "序号": 5206,
        "name": "CTO Realty Growth Inc Series A ",
        "最新价": 19.47,
        "涨跌额": 0.03,
        "涨跌幅": 0.15,
        "开盘价": 19.6,
        "最高价": 19.6,
        "最低价": 19.47,
        "昨收价": 19.44,
        "总市值": 58277721.0,
        "市盈率": null,
        "成交量": 2025.0,
        "成交额": 39558.0,
        "振幅": 0.67,
        "换手率": 0.07,
        "symbol": "106.CTO_A"
    },
    {
        "序号": 5207,
        "name": "Global X Hydrogen ETF",
        "最新价": 6.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.15,
        "开盘价": 6.49,
        "最高价": 6.62,
        "最低价": 6.44,
        "昨收价": 6.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 30315.0,
        "成交额": 197275.0,
        "振幅": 2.77,
        "换手率": null,
        "symbol": "105.HYDR"
    },
    {
        "序号": 5208,
        "name": "Stellar Bancorp Inc",
        "最新价": 26.06,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 25.9,
        "最高价": 26.52,
        "最低价": 25.9,
        "昨收价": 26.02,
        "总市值": 1388013271.0,
        "市盈率": 13.18,
        "成交量": 163544.0,
        "成交额": 4267231.0,
        "振幅": 2.38,
        "换手率": 0.31,
        "symbol": "106.STEL"
    },
    {
        "序号": 5209,
        "name": "AdvisorShares Dorsey Wright FSM",
        "最新价": 32.61,
        "涨跌额": 0.05,
        "涨跌幅": 0.15,
        "开盘价": 32.51,
        "最高价": 32.61,
        "最低价": 32.51,
        "昨收价": 32.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 406.0,
        "成交额": 13216.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.DWAW"
    },
    {
        "序号": 5210,
        "name": "SmartETFs Sustainable Energy II",
        "最新价": 26.11,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.06,
        "最高价": 26.11,
        "最低价": 26.06,
        "昨收价": 26.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 691.0,
        "成交额": 18019.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.SOLR"
    },
    {
        "序号": 5211,
        "name": "Reinsurance Group of America In",
        "最新价": 26.16,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.11,
        "最高价": 26.16,
        "最低价": 25.95,
        "昨收价": 26.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 79039.0,
        "成交额": 2063091.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "106.RZC"
    },
    {
        "序号": 5212,
        "name": "Invesco FTSE RAFI Developed Mar",
        "最新价": 45.8,
        "涨跌额": 0.07,
        "涨跌幅": 0.15,
        "开盘价": 45.59,
        "最高价": 45.88,
        "最低价": 45.59,
        "昨收价": 45.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 56279.0,
        "成交额": 2572944.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.PXF"
    },
    {
        "序号": 5213,
        "name": "SPDR MSCI ACWI ex-US ETF",
        "最新价": 26.18,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.03,
        "最高价": 26.21,
        "最低价": 26.03,
        "昨收价": 26.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 428829.0,
        "成交额": 11214280.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.CWI"
    },
    {
        "序号": 5214,
        "name": "Nicholas Fixed Income Alternati",
        "最新价": 19.76,
        "涨跌额": 0.03,
        "涨跌幅": 0.15,
        "开盘价": 19.74,
        "最高价": 19.76,
        "最低价": 19.73,
        "昨收价": 19.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 13255.0,
        "成交额": 261743.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.FIAX"
    },
    {
        "序号": 5215,
        "name": "Brookstone Opportunities ETF",
        "最新价": 26.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.31,
        "最高价": 26.4,
        "最低价": 26.31,
        "昨收价": 26.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 4387.0,
        "成交额": 115673.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.BAMO"
    },
    {
        "序号": 5216,
        "name": "Idaho Strategic Resources Inc",
        "最新价": 6.6,
        "涨跌额": 0.01,
        "涨跌幅": 0.15,
        "开盘价": 6.59,
        "最高价": 6.6,
        "最低价": 6.5,
        "昨收价": 6.59,
        "总市值": 80893052.0,
        "市盈率": 56.09,
        "成交量": 16132.0,
        "成交额": 105694.0,
        "振幅": 1.52,
        "换手率": 0.13,
        "symbol": "107.IDR"
    },
    {
        "序号": 5217,
        "name": "Brookstone Active ETF",
        "最新价": 26.4,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.34,
        "最高价": 26.41,
        "最低价": 26.34,
        "昨收价": 26.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 4933.0,
        "成交额": 130206.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.BAMA"
    },
    {
        "序号": 5218,
        "name": "Biohaven Ltd",
        "最新价": 33.06,
        "涨跌额": 0.05,
        "涨跌幅": 0.15,
        "开盘价": 33.5,
        "最高价": 34.49,
        "最低价": 32.89,
        "昨收价": 33.01,
        "总市值": 2652524667.0,
        "市盈率": -5.71,
        "成交量": 1067583.0,
        "成交额": 35736275.0,
        "振幅": 4.85,
        "换手率": 1.33,
        "symbol": "106.BHVN"
    },
    {
        "序号": 5219,
        "name": "Innovator U.S. Equity 10 Buffer",
        "最新价": 26.45,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.47,
        "最高价": 26.47,
        "最低价": 26.42,
        "昨收价": 26.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 13133.0,
        "成交额": 347164.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.ZALT"
    },
    {
        "序号": 5220,
        "name": "Conductor Global Equity Value E",
        "最新价": 13.23,
        "涨跌额": 0.02,
        "涨跌幅": 0.15,
        "开盘价": 13.19,
        "最高价": 13.23,
        "最低价": 13.19,
        "昨收价": 13.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 4818.0,
        "成交额": 63582.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.CGV"
    },
    {
        "序号": 5221,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.51,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.41,
        "最高价": 26.51,
        "最低价": 26.41,
        "昨收价": 26.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 852.0,
        "成交额": 22528.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.ITDD"
    },
    {
        "序号": 5222,
        "name": "Nuveen ESG Large-Cap Growth ETF",
        "最新价": 66.41,
        "涨跌额": 0.1,
        "涨跌幅": 0.15,
        "开盘价": 66.0,
        "最高价": 66.59,
        "最低价": 65.91,
        "昨收价": 66.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 66325.0,
        "成交额": 4403775.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.NULG"
    },
    {
        "序号": 5223,
        "name": "Abrdn Bloomberg All Commodity S",
        "最新价": 19.95,
        "涨跌额": 0.03,
        "涨跌幅": 0.15,
        "开盘价": 20.02,
        "最高价": 20.07,
        "最低价": 19.88,
        "昨收价": 19.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 326016.0,
        "成交额": 6503461.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.BCI"
    },
    {
        "序号": 5224,
        "name": "CHS Inc Class B Pfd",
        "最新价": 26.68,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.65,
        "最高价": 26.68,
        "最低价": 26.6,
        "昨收价": 26.64,
        "总市值": 572527881.0,
        "市盈率": null,
        "成交量": 12659.0,
        "成交额": 337252.0,
        "振幅": 0.3,
        "换手率": 0.06,
        "symbol": "105.CHSCO"
    },
    {
        "序号": 5225,
        "name": "沙特阿拉伯ETF-iShares MSCI",
        "最新价": 40.05,
        "涨跌额": 0.06,
        "涨跌幅": 0.15,
        "开盘价": 39.92,
        "最高价": 40.08,
        "最低价": 39.86,
        "昨收价": 39.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 344774.0,
        "成交额": 13784140.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.KSA"
    },
    {
        "序号": 5226,
        "name": "VictoryShares International Vol",
        "最新价": 40.1,
        "涨跌额": 0.06,
        "涨跌幅": 0.15,
        "开盘价": 40.1,
        "最高价": 40.1,
        "最低价": 40.1,
        "昨收价": 40.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 142.0,
        "成交额": 5693.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CIL"
    },
    {
        "序号": 5227,
        "name": "SRH Total Return Fund, Inc.",
        "最新价": 13.37,
        "涨跌额": 0.02,
        "涨跌幅": 0.15,
        "开盘价": 13.31,
        "最高价": 13.39,
        "最低价": 13.31,
        "昨收价": 13.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 40673.0,
        "成交额": 542791.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "106.STEW"
    },
    {
        "序号": 5228,
        "name": "Day Hagan\/Ned Davis Research Sm",
        "最新价": 26.76,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.75,
        "最高价": 26.76,
        "最低价": 26.75,
        "昨收价": 26.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 1046.0,
        "成交额": 27987.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.SSXU"
    },
    {
        "序号": 5229,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 33.51,
        "涨跌额": 0.05,
        "涨跌幅": 0.15,
        "开盘价": 33.45,
        "最高价": 33.55,
        "最低价": 33.45,
        "昨收价": 33.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 8510.0,
        "成交额": 285108.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.DMAR"
    },
    {
        "序号": 5230,
        "name": "Hartford Multifactor Developed ",
        "最新价": 26.87,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 26.72,
        "最高价": 26.9,
        "最低价": 26.72,
        "昨收价": 26.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 124482.0,
        "成交额": 3339511.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.RODM"
    },
    {
        "序号": 5231,
        "name": "iShares Edge MSCI Multifactor I",
        "最新价": 27.2,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.04,
        "最高价": 27.25,
        "最低价": 27.04,
        "昨收价": 27.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 127985.0,
        "成交额": 3477468.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.INTF"
    },
    {
        "序号": 5232,
        "name": "Innovator International Develop",
        "最新价": 27.24,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.26,
        "最高价": 27.28,
        "最低价": 27.18,
        "昨收价": 27.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 12541.0,
        "成交额": 341533.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.IOCT"
    },
    {
        "序号": 5233,
        "name": "TrueShares Low Volatility Equit",
        "最新价": 27.24,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.24,
        "最高价": 27.25,
        "最低价": 27.24,
        "昨收价": 27.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 1117.0,
        "成交额": 30429.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.DIVZ"
    },
    {
        "序号": 5234,
        "name": "The Gabelli Dividend & Income T",
        "最新价": 20.45,
        "涨跌额": 0.03,
        "涨跌幅": 0.15,
        "开盘价": 20.31,
        "最高价": 20.54,
        "最低价": 20.31,
        "昨收价": 20.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 152029.0,
        "成交额": 3108343.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "106.GDV"
    },
    {
        "序号": 5235,
        "name": "Bank of America Corp Series 2 P",
        "最新价": 20.46,
        "涨跌额": 0.03,
        "涨跌幅": 0.15,
        "开盘价": 20.4,
        "最高价": 20.59,
        "最低价": 20.36,
        "昨收价": 20.43,
        "总市值": 203925.0,
        "市盈率": null,
        "成交量": 17080.0,
        "成交额": 348794.0,
        "振幅": 1.13,
        "换手率": 171.37,
        "symbol": "106.BML_H"
    },
    {
        "序号": 5236,
        "name": "FirstService Corp",
        "最新价": 163.74,
        "涨跌额": 0.24,
        "涨跌幅": 0.15,
        "开盘价": 163.84,
        "最高价": 164.33,
        "最低价": 162.79,
        "昨收价": 163.5,
        "总市值": 7308146345.0,
        "市盈率": 55.21,
        "成交量": 70421.0,
        "成交额": 11504034.0,
        "振幅": 0.94,
        "换手率": 0.16,
        "symbol": "105.FSV"
    },
    {
        "序号": 5237,
        "name": "Touchstone Dividend Select ETF",
        "最新价": 27.29,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.29,
        "最高价": 27.29,
        "最低价": 27.29,
        "昨收价": 27.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 9.0,
        "成交额": 245.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.DVND"
    },
    {
        "序号": 5238,
        "name": "Elme Communities Trust",
        "最新价": 13.65,
        "涨跌额": 0.02,
        "涨跌幅": 0.15,
        "开盘价": 13.51,
        "最高价": 13.67,
        "最低价": 13.41,
        "昨收价": 13.63,
        "总市值": 1198915099.0,
        "市盈率": -22.45,
        "成交量": 333942.0,
        "成交额": 4543165.0,
        "振幅": 1.91,
        "换手率": 0.38,
        "symbol": "106.ELME"
    },
    {
        "序号": 5239,
        "name": "Teekay Tankers Ltd-A",
        "最新价": 47.8,
        "涨跌额": 0.07,
        "涨跌幅": 0.15,
        "开盘价": 48.02,
        "最高价": 48.29,
        "最低价": 47.21,
        "昨收价": 47.73,
        "总市值": 1629010664.0,
        "市盈率": 2.97,
        "成交量": 285219.0,
        "成交额": 13614661.0,
        "振幅": 2.26,
        "换手率": 0.84,
        "symbol": "106.TNK"
    },
    {
        "序号": 5240,
        "name": "Goldman Sachs Future Consumer E",
        "最新价": 27.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.17,
        "最高价": 27.42,
        "最低价": 27.17,
        "昨收价": 27.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 9277.0,
        "成交额": 253538.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.GBUY"
    },
    {
        "序号": 5241,
        "name": "Howard Hughes Holdings Inc",
        "最新价": 75.29,
        "涨跌额": 0.11,
        "涨跌幅": 0.15,
        "开盘价": 75.11,
        "最高价": 76.19,
        "最低价": 74.92,
        "昨收价": 75.18,
        "总市值": 3770440607.0,
        "市盈率": -7.07,
        "成交量": 283004.0,
        "成交额": 21326436.0,
        "振幅": 1.69,
        "换手率": 0.57,
        "symbol": "106.HHH"
    },
    {
        "序号": 5242,
        "name": "Invesco S&P International Devel",
        "最新价": 27.5,
        "涨跌额": 0.04,
        "涨跌幅": 0.15,
        "开盘价": 27.43,
        "最高价": 27.5,
        "最低价": 27.33,
        "昨收价": 27.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 36835.0,
        "成交额": 1009722.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.IDLV"
    },
    {
        "序号": 5243,
        "name": "Invesco S&P 500 High Dividend L",
        "最新价": 41.4,
        "涨跌额": 0.06,
        "涨跌幅": 0.15,
        "开盘价": 41.31,
        "最高价": 41.47,
        "最低价": 41.17,
        "昨收价": 41.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 592920.0,
        "成交额": 24507343.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.SPHD"
    },
    {
        "序号": 5244,
        "name": "RCI Hospitality Holdings Inc",
        "最新价": 62.3,
        "涨跌额": 0.09,
        "涨跌幅": 0.14,
        "开盘价": 62.33,
        "最高价": 63.15,
        "最低价": 62.21,
        "昨收价": 62.21,
        "总市值": 585472910.0,
        "市盈率": 15.54,
        "成交量": 44616.0,
        "成交额": 2794305.0,
        "振幅": 1.51,
        "换手率": 0.47,
        "symbol": "105.RICK"
    },
    {
        "序号": 5245,
        "name": "SonicShares Global Shipping ETF",
        "最新价": 27.71,
        "涨跌额": 0.04,
        "涨跌幅": 0.14,
        "开盘价": 27.61,
        "最高价": 27.72,
        "最低价": 27.57,
        "昨收价": 27.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 25413.0,
        "成交额": 702729.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.BOAT"
    },
    {
        "序号": 5246,
        "name": "埃克斯资本",
        "最新价": 55.43,
        "涨跌额": 0.08,
        "涨跌幅": 0.14,
        "开盘价": 55.3,
        "最高价": 55.53,
        "最低价": 55.01,
        "昨收价": 55.35,
        "总市值": 4724945657.0,
        "市盈率": 8.33,
        "成交量": 400220.0,
        "成交额": 22133217.0,
        "振幅": 0.94,
        "换手率": 0.47,
        "symbol": "106.AXS"
    },
    {
        "序号": 5247,
        "name": "NeoGames SA",
        "最新价": 27.9,
        "涨跌额": 0.04,
        "涨跌幅": 0.14,
        "开盘价": 27.86,
        "最高价": 28.05,
        "最低价": 27.86,
        "昨收价": 27.86,
        "总市值": 938329815.0,
        "市盈率": -72.21,
        "成交量": 59264.0,
        "成交额": 1654326.0,
        "振幅": 0.68,
        "换手率": 0.18,
        "symbol": "105.NGMS"
    },
    {
        "序号": 5248,
        "name": "卡斯信息系统",
        "最新价": 41.86,
        "涨跌额": 0.06,
        "涨跌幅": 0.14,
        "开盘价": 41.67,
        "最高价": 42.18,
        "最低价": 41.67,
        "昨收价": 41.8,
        "总市值": 569149071.0,
        "市盈率": 18.4,
        "成交量": 22163.0,
        "成交额": 927298.0,
        "振幅": 1.22,
        "换手率": 0.16,
        "symbol": "105.CASS"
    },
    {
        "序号": 5249,
        "name": "马库斯",
        "最新价": 14.07,
        "涨跌额": 0.02,
        "涨跌幅": 0.14,
        "开盘价": 14.0,
        "最高价": 14.13,
        "最低价": 13.95,
        "昨收价": 14.05,
        "总市值": 445959259.0,
        "市盈率": 64.49,
        "成交量": 332373.0,
        "成交额": 4671190.0,
        "振幅": 1.28,
        "换手率": 1.05,
        "symbol": "106.MCS"
    },
    {
        "序号": 5250,
        "name": "Textainer Group Holdings Ltd",
        "最新价": 49.27,
        "涨跌额": 0.07,
        "涨跌幅": 0.14,
        "开盘价": 49.16,
        "最高价": 49.3,
        "最低价": 49.15,
        "昨收价": 49.2,
        "总市值": 2012750695.0,
        "市盈率": 8.7,
        "成交量": 357426.0,
        "成交额": 17598626.0,
        "振幅": 0.3,
        "换手率": 0.87,
        "symbol": "106.TGH"
    },
    {
        "序号": 5251,
        "name": "iShares U.S. Consumer Focused E",
        "最新价": 42.31,
        "涨跌额": 0.06,
        "涨跌幅": 0.14,
        "开盘价": 42.31,
        "最高价": 42.31,
        "最低价": 42.31,
        "昨收价": 42.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 161.0,
        "成交额": 6811.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IEDI"
    },
    {
        "序号": 5252,
        "name": "First Trust BuyWrite Income ETF",
        "最新价": 21.19,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.15,
        "最高价": 21.2,
        "最低价": 21.13,
        "昨收价": 21.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 91081.0,
        "成交额": 1928038.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.FTHI"
    },
    {
        "序号": 5253,
        "name": "全球除美国ETF-iShares MSCI",
        "最新价": 49.48,
        "涨跌额": 0.07,
        "涨跌幅": 0.14,
        "开盘价": 49.23,
        "最高价": 49.55,
        "最低价": 49.22,
        "昨收价": 49.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 2454580.0,
        "成交额": 121289291.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "105.ACWX"
    },
    {
        "序号": 5254,
        "name": "公寓投资管理",
        "最新价": 7.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.14,
        "开盘价": 7.1,
        "最高价": 7.18,
        "最低价": 7.05,
        "昨收价": 7.13,
        "总市值": 1040724058.0,
        "市盈率": -4.82,
        "成交量": 672026.0,
        "成交额": 4798618.0,
        "振幅": 1.82,
        "换手率": 0.46,
        "symbol": "106.AIV"
    },
    {
        "序号": 5255,
        "name": "OFS Capital Corp Notes",
        "最新价": 21.5,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.49,
        "最高价": 21.5,
        "最低价": 21.46,
        "昨收价": 21.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 1559.0,
        "成交额": 33491.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "105.OFSSH"
    },
    {
        "序号": 5256,
        "name": "OPENLANE Inc",
        "最新价": 14.34,
        "涨跌额": 0.02,
        "涨跌幅": 0.14,
        "开盘价": 14.33,
        "最高价": 14.5,
        "最低价": 14.21,
        "昨收价": 14.32,
        "总市值": 1548984688.0,
        "市盈率": -11.8,
        "成交量": 2123234.0,
        "成交额": 30450060.0,
        "振幅": 2.03,
        "换手率": 1.97,
        "symbol": "106.KAR"
    },
    {
        "序号": 5257,
        "name": "American Assets Trust Inc",
        "最新价": 21.51,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.37,
        "最高价": 21.53,
        "最低价": 21.11,
        "昨收价": 21.48,
        "总市值": 1306186791.0,
        "市盈率": 26.37,
        "成交量": 414629.0,
        "成交额": 8861416.0,
        "振幅": 1.96,
        "换手率": 0.68,
        "symbol": "106.AAT"
    },
    {
        "序号": 5258,
        "name": "First Trust S&P 500 Diversified",
        "最新价": 28.84,
        "涨跌额": 0.04,
        "涨跌幅": 0.14,
        "开盘价": 28.71,
        "最高价": 28.84,
        "最低价": 28.71,
        "昨收价": 28.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 4444.0,
        "成交额": 127665.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "105.KNGZ"
    },
    {
        "序号": 5259,
        "name": "Global X SuperDividend ETF",
        "最新价": 21.64,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.52,
        "最高价": 21.69,
        "最低价": 21.5,
        "昨收价": 21.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 233215.0,
        "成交额": 5033996.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.SDIV"
    },
    {
        "序号": 5260,
        "name": "Overlay Shares Short Term Bond ",
        "最新价": 21.67,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.61,
        "最高价": 21.67,
        "最低价": 21.56,
        "昨收价": 21.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 4176.0,
        "成交额": 90385.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.OVT"
    },
    {
        "序号": 5261,
        "name": "Innovator U.S. Equity Ultra Buf",
        "最新价": 28.91,
        "涨跌额": 0.04,
        "涨跌幅": 0.14,
        "开盘价": 28.91,
        "最高价": 28.96,
        "最低价": 28.86,
        "昨收价": 28.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 3129.0,
        "成交额": 90461.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.UMAY"
    },
    {
        "序号": 5262,
        "name": "德克斯户外",
        "最新价": 695.38,
        "涨跌额": 0.96,
        "涨跌幅": 0.14,
        "开盘价": 687.65,
        "最高价": 699.66,
        "最低价": 682.29,
        "昨收价": 694.42,
        "总市值": 17892735162.0,
        "市盈率": 29.21,
        "成交量": 279568.0,
        "成交额": 194148530.0,
        "振幅": 2.5,
        "换手率": 1.09,
        "symbol": "106.DECK"
    },
    {
        "序号": 5263,
        "name": "Spirit Airlines Inc",
        "最新价": 14.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.14,
        "开盘价": 14.36,
        "最高价": 14.87,
        "最低价": 14.32,
        "昨收价": 14.53,
        "总市值": 1588387998.0,
        "市盈率": -2.97,
        "成交量": 5014642.0,
        "成交额": 73439941.0,
        "振幅": 3.79,
        "换手率": 4.59,
        "symbol": "106.SAVE"
    },
    {
        "序号": 5264,
        "name": "哥伦比亚户外",
        "最新价": 80.13,
        "涨跌额": 0.11,
        "涨跌幅": 0.14,
        "开盘价": 79.98,
        "最高价": 80.82,
        "最低价": 79.66,
        "昨收价": 80.02,
        "总市值": 4848487850.0,
        "市盈率": 17.09,
        "成交量": 253869.0,
        "成交额": 20355538.0,
        "振幅": 1.45,
        "换手率": 0.42,
        "symbol": "105.COLM"
    },
    {
        "序号": 5265,
        "name": "CrossingBridge Pre-Merger SPAC ",
        "最新价": 21.9,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.88,
        "最高价": 21.9,
        "最低价": 21.88,
        "昨收价": 21.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 1129.0,
        "成交额": 24713.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.SPC"
    },
    {
        "序号": 5266,
        "name": "iShares MSCI EAFE Value ETF",
        "最新价": 51.11,
        "涨跌额": 0.07,
        "涨跌幅": 0.14,
        "开盘价": 50.88,
        "最高价": 51.19,
        "最低价": 50.76,
        "昨收价": 51.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1599032.0,
        "成交额": 81630133.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.EFV"
    },
    {
        "序号": 5267,
        "name": "Timothy Plan High Dividend Stoc",
        "最新价": 21.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 21.92,
        "最高价": 22.01,
        "最低价": 21.92,
        "昨收价": 21.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 713.0,
        "成交额": 15652.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.TPHE"
    },
    {
        "序号": 5268,
        "name": "阿特斯太阳能",
        "最新价": 22.09,
        "涨跌额": 0.03,
        "涨跌幅": 0.14,
        "开盘价": 22.06,
        "最高价": 22.8,
        "最低价": 21.45,
        "昨收价": 22.06,
        "总市值": 1428195395.0,
        "市盈率": 4.04,
        "成交量": 1172426.0,
        "成交额": 25820522.0,
        "振幅": 6.12,
        "换手率": 1.81,
        "symbol": "105.CSIQ"
    },
    {
        "序号": 5269,
        "name": "华盛顿信托银行",
        "最新价": 29.51,
        "涨跌额": 0.04,
        "涨跌幅": 0.14,
        "开盘价": 29.54,
        "最高价": 30.0,
        "最低价": 29.3,
        "昨收价": 29.47,
        "总市值": 502584367.0,
        "市盈率": 9.7,
        "成交量": 46824.0,
        "成交额": 1386138.0,
        "振幅": 2.38,
        "换手率": 0.27,
        "symbol": "105.WASH"
    },
    {
        "序号": 5270,
        "name": "Goldman Sachs MarketBeta Intern",
        "最新价": 51.77,
        "涨跌额": 0.07,
        "涨跌幅": 0.14,
        "开盘价": 51.66,
        "最高价": 51.77,
        "最低价": 51.66,
        "昨收价": 51.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 899.0,
        "成交额": 46447.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.GSID"
    },
    {
        "序号": 5271,
        "name": "Cornerstone Strategic Value Fun",
        "最新价": 7.42,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "开盘价": 7.39,
        "最高价": 7.45,
        "最低价": 7.36,
        "昨收价": 7.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 878825.0,
        "成交额": 6515542.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.CLM"
    },
    {
        "序号": 5272,
        "name": "艾昆纬",
        "最新价": 215.28,
        "涨跌额": 0.29,
        "涨跌幅": 0.13,
        "开盘价": 214.39,
        "最高价": 216.22,
        "最低价": 213.37,
        "昨收价": 214.99,
        "总市值": 39288600000.0,
        "市盈率": 35.2,
        "成交量": 603944.0,
        "成交额": 129976853.0,
        "振幅": 1.33,
        "换手率": 0.33,
        "symbol": "106.IQV"
    },
    {
        "序号": 5273,
        "name": "汉考克惠特尼",
        "最新价": 44.57,
        "涨跌额": 0.06,
        "涨跌幅": 0.13,
        "开盘价": 44.53,
        "最高价": 44.73,
        "最低价": 43.97,
        "昨收价": 44.51,
        "总市值": 3839757424.0,
        "市盈率": 7.9,
        "成交量": 610280.0,
        "成交额": 27101204.0,
        "振幅": 1.71,
        "换手率": 0.71,
        "symbol": "105.HWC"
    },
    {
        "序号": 5274,
        "name": "Bank of Hawaii Corp Series A Pf",
        "最新价": 14.88,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 14.86,
        "最高价": 15.01,
        "最低价": 14.83,
        "昨收价": 14.86,
        "总市值": 2678400.0,
        "市盈率": null,
        "成交量": 7191.0,
        "成交额": 107102.0,
        "振幅": 1.21,
        "换手率": 4.0,
        "symbol": "106.BOH_A"
    },
    {
        "序号": 5275,
        "name": "iShares Core Aggressive Allocat",
        "最新价": 67.05,
        "涨跌额": 0.09,
        "涨跌幅": 0.13,
        "开盘价": 66.71,
        "最高价": 67.14,
        "最低价": 66.71,
        "昨收价": 66.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 259941.0,
        "成交额": 17402206.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.AOA"
    },
    {
        "序号": 5276,
        "name": "Invesco Global Clean Energy ETF",
        "最新价": 14.95,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 14.94,
        "最高价": 15.08,
        "最低价": 14.84,
        "昨收价": 14.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 22048.0,
        "成交额": 329413.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "107.PBD"
    },
    {
        "序号": 5277,
        "name": "Innovator Growth-100 Power Buff",
        "最新价": 44.88,
        "涨跌额": 0.06,
        "涨跌幅": 0.13,
        "开盘价": 44.77,
        "最高价": 44.91,
        "最低价": 44.74,
        "昨收价": 44.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 44543.0,
        "成交额": 1997991.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.NOCT"
    },
    {
        "序号": 5278,
        "name": "瑞典ETF-iShares MSCI",
        "最新价": 37.46,
        "涨跌额": 0.05,
        "涨跌幅": 0.13,
        "开盘价": 37.15,
        "最高价": 37.59,
        "最低价": 37.15,
        "昨收价": 37.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 122738.0,
        "成交额": 4595594.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.EWD"
    },
    {
        "序号": 5279,
        "name": "Belpointe PREP LLC Unit-A",
        "最新价": 67.5,
        "涨跌额": 0.09,
        "涨跌幅": 0.13,
        "开盘价": 67.25,
        "最高价": 67.55,
        "最低价": 66.0,
        "昨收价": 67.41,
        "总市值": 248367060.0,
        "市盈率": -19.24,
        "成交量": 23170.0,
        "成交额": 1555025.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.OZ"
    },
    {
        "序号": 5280,
        "name": "FlexShares International Qualit",
        "最新价": 22.51,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 22.41,
        "最高价": 22.53,
        "最低价": 22.4,
        "昨收价": 22.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 97343.0,
        "成交额": 2187864.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.IQDF"
    },
    {
        "序号": 5281,
        "name": "ClearShares OCIO ETF",
        "最新价": 30.04,
        "涨跌额": 0.04,
        "涨跌幅": 0.13,
        "开盘价": 30.04,
        "最高价": 30.04,
        "最低价": 30.04,
        "昨收价": 30.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 26.0,
        "成交额": 780.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OCIO"
    },
    {
        "序号": 5282,
        "name": "Stride Inc",
        "最新价": 60.25,
        "涨跌额": 0.08,
        "涨跌幅": 0.13,
        "开盘价": 59.97,
        "最高价": 60.78,
        "最低价": 59.93,
        "昨收价": 60.17,
        "总市值": 2611496907.0,
        "市盈率": 16.91,
        "成交量": 509321.0,
        "成交额": 30669956.0,
        "振幅": 1.41,
        "换手率": 1.18,
        "symbol": "106.LRN"
    },
    {
        "序号": 5283,
        "name": "太阳能ETF-VanEck Vectors",
        "最新价": 30.14,
        "涨跌额": 0.04,
        "涨跌幅": 0.13,
        "开盘价": 30.01,
        "最高价": 30.14,
        "最低价": 30.01,
        "昨收价": 30.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 621.0,
        "成交额": 18636.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.KWT"
    },
    {
        "序号": 5284,
        "name": "Chico’s FAS Inc",
        "最新价": 7.56,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "开盘价": 7.56,
        "最高价": 7.57,
        "最低价": 7.55,
        "昨收价": 7.55,
        "总市值": 933337672.0,
        "市盈率": 8.35,
        "成交量": 2037853.0,
        "成交额": 15402988.0,
        "振幅": 0.26,
        "换手率": 1.65,
        "symbol": "106.CHS"
    },
    {
        "序号": 5285,
        "name": "First Trust NASDAQ Clean Edge G",
        "最新价": 37.81,
        "涨跌额": 0.05,
        "涨跌幅": 0.13,
        "开盘价": 37.76,
        "最高价": 38.4,
        "最低价": 37.47,
        "昨收价": 37.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 323376.0,
        "成交额": 12242634.0,
        "振幅": 2.46,
        "换手率": null,
        "symbol": "105.QCLN"
    },
    {
        "序号": 5286,
        "name": "iShares MSCI Norway ETF",
        "最新价": 22.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 22.87,
        "最高价": 22.88,
        "最低价": 22.82,
        "昨收价": 22.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 2364.0,
        "成交额": 54046.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.ENOR"
    },
    {
        "序号": 5287,
        "name": "Oxford Lane Capital Corp Notes",
        "最新价": 22.94,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 22.94,
        "最高价": 22.94,
        "最低价": 22.94,
        "昨收价": 22.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 695.0,
        "成交额": 15943.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OXLCZ"
    },
    {
        "序号": 5288,
        "name": "Plains GP Holdings LP-A",
        "最新价": 15.36,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 15.35,
        "最高价": 15.38,
        "最低价": 15.22,
        "昨收价": 15.34,
        "总市值": 11821843860.0,
        "市盈率": 62.22,
        "成交量": 1503247.0,
        "成交额": 23024096.0,
        "振幅": 1.04,
        "换手率": 0.2,
        "symbol": "105.PAGP"
    },
    {
        "序号": 5289,
        "name": "iShares iBonds 2025 Term High Y",
        "最新价": 23.08,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 23.1,
        "最高价": 23.15,
        "最低价": 23.0,
        "昨收价": 23.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 97579.0,
        "成交额": 2251766.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.IBHE"
    },
    {
        "序号": 5290,
        "name": "FlexShares ESG & Climate Develo",
        "最新价": 46.27,
        "涨跌额": 0.06,
        "涨跌幅": 0.13,
        "开盘价": 46.14,
        "最高价": 46.27,
        "最低价": 46.14,
        "昨收价": 46.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 871.0,
        "成交额": 40187.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.FEDM"
    },
    {
        "序号": 5291,
        "name": "ProShares Nanotechnology ETF",
        "最新价": 38.6,
        "涨跌额": 0.05,
        "涨跌幅": 0.13,
        "开盘价": 38.54,
        "最高价": 38.8,
        "最低价": 38.54,
        "昨收价": 38.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 423.0,
        "成交额": 16378.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.TINY"
    },
    {
        "序号": 5292,
        "name": "Tekla Healthcare Investors",
        "最新价": 15.45,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 15.37,
        "最高价": 15.49,
        "最低价": 15.31,
        "昨收价": 15.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 273408.0,
        "成交额": 4217128.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "106.HQH"
    },
    {
        "序号": 5293,
        "name": "西北天然气",
        "最新价": 38.63,
        "涨跌额": 0.05,
        "涨跌幅": 0.13,
        "开盘价": 38.63,
        "最高价": 38.87,
        "最低价": 38.37,
        "昨收价": 38.58,
        "总市值": 1420744609.0,
        "市盈率": 14.62,
        "成交量": 190583.0,
        "成交额": 7359542.0,
        "振幅": 1.3,
        "换手率": 0.52,
        "symbol": "106.NWN"
    },
    {
        "序号": 5294,
        "name": "CareCloud Inc Series A Pfd",
        "最新价": 15.49,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 15.48,
        "最高价": 16.05,
        "最低价": 15.48,
        "昨收价": 15.47,
        "总市值": 70111318.0,
        "市盈率": null,
        "成交量": 16080.0,
        "成交额": 251727.0,
        "振幅": 3.68,
        "换手率": 0.36,
        "symbol": "105.CCLDP"
    },
    {
        "序号": 5295,
        "name": "American Century Low Volatility",
        "最新价": 46.54,
        "涨跌额": 0.06,
        "涨跌幅": 0.13,
        "开盘价": 46.54,
        "最高价": 46.54,
        "最低价": 46.54,
        "昨收价": 46.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 32.0,
        "成交额": 1489.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LVOL"
    },
    {
        "序号": 5296,
        "name": "Ellsworth Growth and Income Fun",
        "最新价": 7.76,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "开盘价": 7.73,
        "最高价": 7.79,
        "最低价": 7.73,
        "昨收价": 7.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 28592.0,
        "成交额": 221846.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.ECF"
    },
    {
        "序号": 5297,
        "name": "Floor & Decor Holdings Inc-A",
        "最新价": 100.93,
        "涨跌额": 0.13,
        "涨跌幅": 0.13,
        "开盘价": 100.82,
        "最高价": 101.42,
        "最低价": 99.29,
        "昨收价": 100.8,
        "总市值": 10756099200.0,
        "市盈率": 38.67,
        "成交量": 936673.0,
        "成交额": 94423812.0,
        "振幅": 2.11,
        "换手率": 0.88,
        "symbol": "106.FND"
    },
    {
        "序号": 5298,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 31.06,
        "涨跌额": 0.04,
        "涨跌幅": 0.13,
        "开盘价": 30.94,
        "最高价": 31.11,
        "最低价": 30.94,
        "昨收价": 31.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 23767.0,
        "成交额": 737861.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.GJUN"
    },
    {
        "序号": 5299,
        "name": "iShares ESG Aware Aggressive Al",
        "最新价": 31.09,
        "涨跌额": 0.04,
        "涨跌幅": 0.13,
        "开盘价": 31.02,
        "最高价": 31.09,
        "最低价": 31.02,
        "昨收价": 31.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1004.0,
        "成交额": 31154.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.EAOA"
    },
    {
        "序号": 5300,
        "name": "Fidelity Disruptive Automation ",
        "最新价": 23.5,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 23.32,
        "最高价": 23.6,
        "最低价": 23.32,
        "昨收价": 23.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 17111.0,
        "成交额": 401676.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "105.FBOT"
    },
    {
        "序号": 5301,
        "name": "abrdn Total Dynamic Dividend Fu",
        "最新价": 7.84,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "开盘价": 7.83,
        "最高价": 7.85,
        "最低价": 7.8,
        "昨收价": 7.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 298296.0,
        "成交额": 2333574.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "106.AOD"
    },
    {
        "序号": 5302,
        "name": "Freedom 100 Emerging Markets ET",
        "最新价": 31.41,
        "涨跌额": 0.04,
        "涨跌幅": 0.13,
        "开盘价": 31.23,
        "最高价": 31.61,
        "最低价": 31.23,
        "昨收价": 31.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 58340.0,
        "成交额": 1831685.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.FRDM"
    },
    {
        "序号": 5303,
        "name": "SuRo Capital Corp Notes",
        "最新价": 23.58,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 23.49,
        "最高价": 23.58,
        "最低价": 23.49,
        "昨收价": 23.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 11752.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "105.SSSSL"
    },
    {
        "序号": 5304,
        "name": "Flaherty & Crumrine Preferred a",
        "最新价": 7.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "开盘价": 7.84,
        "最高价": 7.89,
        "最低价": 7.82,
        "昨收价": 7.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 15713.0,
        "成交额": 123322.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "106.PFO"
    },
    {
        "序号": 5305,
        "name": "ETRACS Alerian MLP Index ETN Se",
        "最新价": 15.78,
        "涨跌额": 0.02,
        "涨跌幅": 0.13,
        "开盘价": 15.88,
        "最高价": 15.88,
        "最低价": 15.78,
        "昨收价": 15.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 278.0,
        "成交额": 4402.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.AMUB"
    },
    {
        "序号": 5306,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 39.54,
        "涨跌额": 0.05,
        "涨跌幅": 0.13,
        "开盘价": 39.43,
        "最高价": 39.62,
        "最低价": 39.42,
        "昨收价": 39.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 22103.0,
        "成交额": 873097.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.FAUG"
    },
    {
        "序号": 5307,
        "name": "Democracy International Fund",
        "最新价": 23.85,
        "涨跌额": 0.03,
        "涨跌幅": 0.13,
        "开盘价": 23.87,
        "最高价": 23.89,
        "最低价": 23.85,
        "昨收价": 23.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 1334.0,
        "成交额": 31852.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.DMCY"
    },
    {
        "序号": 5308,
        "name": "Alcon Inc",
        "最新价": 71.99,
        "涨跌额": 0.09,
        "涨跌幅": 0.13,
        "开盘价": 71.77,
        "最高价": 72.15,
        "最低价": 71.46,
        "昨收价": 71.9,
        "总市值": 35505468000.0,
        "市盈率": 78.9,
        "成交量": 629873.0,
        "成交额": 45267652.0,
        "振幅": 0.96,
        "换手率": 0.13,
        "symbol": "106.ALC"
    },
    {
        "序号": 5309,
        "name": "SPDR SSgA Global Allocation ETF",
        "最新价": 40.06,
        "涨跌额": 0.05,
        "涨跌幅": 0.12,
        "开盘价": 39.84,
        "最高价": 40.09,
        "最低价": 39.84,
        "昨收价": 40.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 14137.0,
        "成交额": 565293.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.GAL"
    },
    {
        "序号": 5310,
        "name": "Invesco S&P 500 Equal Weight Ma",
        "最新价": 32.17,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 32.09,
        "最高价": 32.4,
        "最低价": 32.09,
        "昨收价": 32.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 23449.0,
        "成交额": 754637.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.RSPM"
    },
    {
        "序号": 5311,
        "name": "西方石油",
        "最新价": 56.47,
        "涨跌额": 0.07,
        "涨跌幅": 0.12,
        "开盘价": 56.95,
        "最高价": 56.95,
        "最低价": 56.33,
        "昨收价": 56.4,
        "总市值": 49563802801.0,
        "市盈率": 9.14,
        "成交量": 8209016.0,
        "成交额": 464413136.0,
        "振幅": 1.1,
        "换手率": 0.94,
        "symbol": "106.OXY"
    },
    {
        "序号": 5312,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 32.3,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 32.22,
        "最高价": 32.35,
        "最低价": 32.12,
        "昨收价": 32.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 22017.0,
        "成交额": 709693.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.GMAY"
    },
    {
        "序号": 5313,
        "name": "共和废品处理",
        "最新价": 161.79,
        "涨跌额": 0.2,
        "涨跌幅": 0.12,
        "开盘价": 162.05,
        "最高价": 162.05,
        "最低价": 160.42,
        "昨收价": 161.59,
        "总市值": 50905039820.0,
        "市盈率": 31.07,
        "成交量": 864607.0,
        "成交额": 139606566.0,
        "振幅": 1.01,
        "换手率": 0.27,
        "symbol": "106.RSG"
    },
    {
        "序号": 5314,
        "name": "Nuveen Floating Rate Income Fun",
        "最新价": 8.09,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "开盘价": 8.05,
        "最高价": 8.15,
        "最低价": 8.05,
        "昨收价": 8.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 531887.0,
        "成交额": 4309608.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "106.JFR"
    },
    {
        "序号": 5315,
        "name": "LHA Market State Alpha Seeker E",
        "最新价": 24.31,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 24.34,
        "最高价": 24.4,
        "最低价": 24.3,
        "昨收价": 24.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 2942.0,
        "成交额": 71597.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.MSVX"
    },
    {
        "序号": 5316,
        "name": "Schwab Fundamental Internationa",
        "最新价": 32.43,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 32.27,
        "最高价": 32.49,
        "最低价": 32.24,
        "昨收价": 32.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1036702.0,
        "成交额": 33552662.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.FNDF"
    },
    {
        "序号": 5317,
        "name": "Cultivar ETF",
        "最新价": 24.33,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 24.33,
        "最高价": 24.33,
        "最低价": 24.33,
        "昨收价": 24.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 72.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CVAR"
    },
    {
        "序号": 5318,
        "name": "Cullinan Oncology Inc",
        "最新价": 8.11,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "开盘价": 8.04,
        "最高价": 8.32,
        "最低价": 7.73,
        "昨收价": 8.1,
        "总市值": 346951023.0,
        "市盈率": -2.22,
        "成交量": 225876.0,
        "成交额": 1830651.0,
        "振幅": 7.28,
        "换手率": 0.53,
        "symbol": "105.CGEM"
    },
    {
        "序号": 5319,
        "name": "BJ’s Restaurants Inc",
        "最新价": 32.75,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 32.49,
        "最高价": 33.3,
        "最低价": 32.33,
        "昨收价": 32.71,
        "总市值": 761011717.0,
        "市盈率": 48.88,
        "成交量": 245244.0,
        "成交额": 8029398.0,
        "振幅": 2.97,
        "换手率": 1.06,
        "symbol": "105.BJRI"
    },
    {
        "序号": 5320,
        "name": "Schwab International Small-Cap ",
        "最新价": 32.88,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 32.78,
        "最高价": 32.99,
        "最低价": 32.72,
        "昨收价": 32.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 403905.0,
        "成交额": 13263600.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.SCHC"
    },
    {
        "序号": 5321,
        "name": "Diamond Hill Investment Group I",
        "最新价": 164.4,
        "涨跌额": 0.2,
        "涨跌幅": 0.12,
        "开盘价": 163.7,
        "最高价": 166.39,
        "最低价": 163.55,
        "昨收价": 164.2,
        "总市值": 473214385.0,
        "市盈率": 10.88,
        "成交量": 9297.0,
        "成交额": 1533723.0,
        "振幅": 1.73,
        "换手率": 0.32,
        "symbol": "105.DHIL"
    },
    {
        "序号": 5322,
        "name": "KraneShares Rockefeller Ocean E",
        "最新价": 24.84,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 24.84,
        "最高价": 24.84,
        "最低价": 24.84,
        "昨收价": 24.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 149.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KSEA"
    },
    {
        "序号": 5323,
        "name": "Sound Equity Income ETF",
        "最新价": 24.87,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 24.98,
        "最高价": 24.98,
        "最低价": 24.74,
        "昨收价": 24.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 2707.0,
        "成交额": 67522.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.DIVY"
    },
    {
        "序号": 5324,
        "name": "VictoryShares US Multi-Factor M",
        "最新价": 41.49,
        "涨跌额": 0.05,
        "涨跌幅": 0.12,
        "开盘价": 41.38,
        "最高价": 41.51,
        "最低价": 41.38,
        "昨收价": 41.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 1197.0,
        "成交额": 49592.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.VSMV"
    },
    {
        "序号": 5325,
        "name": "Invesco BulletShares 2024 Munic",
        "最新价": 24.9,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 24.85,
        "最高价": 24.9,
        "最低价": 24.84,
        "昨收价": 24.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 35828.0,
        "成交额": 891224.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "105.BSMO"
    },
    {
        "序号": 5326,
        "name": "SPDR Blackstone GSO Senior Loan",
        "最新价": 41.73,
        "涨跌额": 0.05,
        "涨跌幅": 0.12,
        "开盘价": 41.68,
        "最高价": 41.75,
        "最低价": 41.65,
        "昨收价": 41.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 2553431.0,
        "成交额": 106521928.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.SRLN"
    },
    {
        "序号": 5327,
        "name": "印尼电信",
        "最新价": 25.16,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.22,
        "最高价": 25.23,
        "最低价": 25.1,
        "昨收价": 25.13,
        "总市值": 24924053697.0,
        "市盈率": 16.71,
        "成交量": 210162.0,
        "成交额": 5288527.0,
        "振幅": 0.52,
        "换手率": 0.02,
        "symbol": "106.TLK"
    },
    {
        "序号": 5328,
        "name": "Scorpio Tankers Inc Notes",
        "最新价": 25.16,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.13,
        "最高价": 25.19,
        "最低价": 25.11,
        "昨收价": 25.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 5182.0,
        "成交额": 130317.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "106.SBBA"
    },
    {
        "序号": 5329,
        "name": "SWK Holdings Corp Note",
        "最新价": 25.23,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.15,
        "最高价": 25.23,
        "最低价": 25.11,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 4623.0,
        "成交额": 116362.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "105.SWKHL"
    },
    {
        "序号": 5330,
        "name": "Saratoga Investment Corp Notes",
        "最新价": 25.27,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.21,
        "最高价": 25.27,
        "最低价": 25.16,
        "昨收价": 25.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 4114.0,
        "成交额": 103800.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "106.SAZ"
    },
    {
        "序号": 5331,
        "name": "Simplify Opportunistic Income E",
        "最新价": 25.27,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.28,
        "最高价": 25.29,
        "最低价": 25.26,
        "昨收价": 25.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 2309.0,
        "成交额": 58356.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.CRDT"
    },
    {
        "序号": 5332,
        "name": "菲律宾ETF-iShares MSCI",
        "最新价": 25.3,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.25,
        "最高价": 25.3,
        "最低价": 25.1,
        "昨收价": 25.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 105187.0,
        "成交额": 2652669.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.EPHE"
    },
    {
        "序号": 5333,
        "name": "Construction Partners Inc-A",
        "最新价": 42.24,
        "涨跌额": 0.05,
        "涨跌幅": 0.12,
        "开盘价": 42.05,
        "最高价": 42.75,
        "最低价": 42.05,
        "昨收价": 42.19,
        "总市值": 2226452195.0,
        "市盈率": 45.44,
        "成交量": 135685.0,
        "成交额": 5740679.0,
        "振幅": 1.66,
        "换手率": 0.26,
        "symbol": "105.ROAD"
    },
    {
        "序号": 5334,
        "name": "安德玛-A",
        "最新价": 8.46,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "开盘价": 8.43,
        "最高价": 8.58,
        "最低价": 8.4,
        "昨收价": 8.45,
        "总市值": 3706775835.0,
        "市盈率": 9.03,
        "成交量": 3397583.0,
        "成交额": 28810845.0,
        "振幅": 2.13,
        "换手率": 1.52,
        "symbol": "106.UAA"
    },
    {
        "序号": 5335,
        "name": "Innovator International Develop",
        "最新价": 25.43,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.38,
        "最高价": 25.43,
        "最低价": 25.34,
        "昨收价": 25.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 77823.0,
        "成交额": 1974140.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.ISEP"
    },
    {
        "序号": 5336,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 33.91,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 33.85,
        "最高价": 33.95,
        "最低价": 33.84,
        "昨收价": 33.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 52060.0,
        "成交额": 1765378.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.XDEC"
    },
    {
        "序号": 5337,
        "name": "Innovator U.S. Equity Power Buf",
        "最新价": 33.91,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 33.82,
        "最高价": 33.96,
        "最低价": 33.82,
        "昨收价": 33.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 13920.0,
        "成交额": 472049.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.PNOV"
    },
    {
        "序号": 5338,
        "name": "DWS Strategic Municipal Income ",
        "最新价": 8.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "开盘价": 8.48,
        "最高价": 8.51,
        "最低价": 8.47,
        "昨收价": 8.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 14171.0,
        "成交额": 120311.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "106.KSM"
    },
    {
        "序号": 5339,
        "name": "安特吉",
        "最新价": 102.01,
        "涨跌额": 0.12,
        "涨跌幅": 0.12,
        "开盘价": 101.76,
        "最高价": 102.19,
        "最低价": 101.06,
        "昨收价": 101.89,
        "总市值": 21572368279.0,
        "市盈率": 14.66,
        "成交量": 1523371.0,
        "成交额": 155008602.0,
        "振幅": 1.11,
        "换手率": 0.72,
        "symbol": "106.ETR"
    },
    {
        "序号": 5340,
        "name": "Anfield Capital Diversified Alt",
        "最新价": 8.52,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "开盘价": 8.52,
        "最高价": 8.54,
        "最低价": 8.5,
        "昨收价": 8.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 24761.0,
        "成交额": 210942.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.DALT"
    },
    {
        "序号": 5341,
        "name": "VictoryShares Nasdaq Next 50 ET",
        "最新价": 25.59,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 25.62,
        "最高价": 25.64,
        "最低价": 25.59,
        "昨收价": 25.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 5206.0,
        "成交额": 133305.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.QQQN"
    },
    {
        "序号": 5342,
        "name": "Arbor Realty Trust Inc Series D",
        "最新价": 17.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.12,
        "开盘价": 16.97,
        "最高价": 17.36,
        "最低价": 16.97,
        "昨收价": 17.08,
        "总市值": 157320000.0,
        "市盈率": null,
        "成交量": 12159.0,
        "成交额": 208288.0,
        "振幅": 2.28,
        "换手率": 0.13,
        "symbol": "106.ABR_D"
    },
    {
        "序号": 5343,
        "name": "Arbor Realty Trust Inc Series E",
        "最新价": 17.16,
        "涨跌额": 0.02,
        "涨跌幅": 0.12,
        "开盘价": 17.24,
        "最高价": 17.24,
        "最低价": 17.05,
        "昨收价": 17.14,
        "总市值": 98670000.0,
        "市盈率": null,
        "成交量": 4296.0,
        "成交额": 73553.0,
        "振幅": 1.11,
        "换手率": 0.07,
        "symbol": "106.ABR_E"
    },
    {
        "序号": 5344,
        "name": "Insteel Industries Inc",
        "最新价": 34.37,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 34.33,
        "最高价": 34.94,
        "最低价": 34.21,
        "昨收价": 34.33,
        "总市值": 668642573.0,
        "市盈率": 20.63,
        "成交量": 76061.0,
        "成交额": 2618743.0,
        "振幅": 2.13,
        "换手率": 0.39,
        "symbol": "106.IIIN"
    },
    {
        "序号": 5345,
        "name": "Selective Insurance Group Inc S",
        "最新价": 17.2,
        "涨跌额": 0.02,
        "涨跌幅": 0.12,
        "开盘价": 17.27,
        "最高价": 17.27,
        "最低价": 16.98,
        "昨收价": 17.18,
        "总市值": 137600.0,
        "市盈率": null,
        "成交量": 2057.0,
        "成交额": 35277.0,
        "振幅": 1.69,
        "换手率": 25.71,
        "symbol": "105.SIGIP"
    },
    {
        "序号": 5346,
        "name": "医疗指数ETF-Vanguard",
        "最新价": 240.81,
        "涨跌额": 0.28,
        "涨跌幅": 0.12,
        "开盘价": 240.69,
        "最高价": 241.39,
        "最低价": 239.54,
        "昨收价": 240.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 141747.0,
        "成交额": 34099582.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.VHT"
    },
    {
        "序号": 5347,
        "name": "K力",
        "最新价": 69.07,
        "涨跌额": 0.08,
        "涨跌幅": 0.12,
        "开盘价": 69.34,
        "最高价": 69.46,
        "最低价": 68.6,
        "昨收价": 68.99,
        "总市值": 1364754130.0,
        "市盈率": 26.03,
        "成交量": 127762.0,
        "成交额": 8830098.0,
        "振幅": 1.25,
        "换手率": 0.65,
        "symbol": "105.KFRC"
    },
    {
        "序号": 5348,
        "name": "VanEck Dynamic High Income ETF",
        "最新价": 26.06,
        "涨跌额": 0.03,
        "涨跌幅": 0.12,
        "开盘价": 26.06,
        "最高价": 26.06,
        "最低价": 26.06,
        "昨收价": 26.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 39.0,
        "成交额": 1016.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.INC"
    },
    {
        "序号": 5349,
        "name": "iShares Trust ETF",
        "最新价": 34.78,
        "涨跌额": 0.04,
        "涨跌幅": 0.12,
        "开盘价": 34.66,
        "最高价": 34.9,
        "最低价": 34.64,
        "昨收价": 34.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 51932.0,
        "成交额": 1804724.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.SMMV"
    },
    {
        "序号": 5350,
        "name": "WisdomTree Managed Futures Stra",
        "最新价": 34.87,
        "涨跌额": 0.04,
        "涨跌幅": 0.11,
        "开盘价": 34.71,
        "最高价": 34.9,
        "最低价": 34.71,
        "昨收价": 34.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 19985.0,
        "成交额": 696039.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.WTMF"
    },
    {
        "序号": 5351,
        "name": "iShares Edge MSCI Intl Value Fa",
        "最新价": 26.2,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 26.06,
        "最高价": 26.22,
        "最低价": 26.05,
        "昨收价": 26.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 160594.0,
        "成交额": 4199956.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.IVLU"
    },
    {
        "序号": 5352,
        "name": "Innovator International Develop",
        "最新价": 26.42,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 26.42,
        "最高价": 26.44,
        "最低价": 26.42,
        "昨收价": 26.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 558.0,
        "成交额": 14745.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.INOV"
    },
    {
        "序号": 5353,
        "name": "AltShares Merger Arbitrage ETF",
        "最新价": 26.47,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 26.45,
        "最高价": 26.47,
        "最低价": 26.45,
        "昨收价": 26.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 5174.0,
        "成交额": 136911.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.ARB"
    },
    {
        "序号": 5354,
        "name": "TriNet Group Inc",
        "最新价": 114.74,
        "涨跌额": 0.13,
        "涨跌幅": 0.11,
        "开盘价": 114.54,
        "最高价": 115.38,
        "最低价": 114.36,
        "昨收价": 114.61,
        "总市值": 5795320391.0,
        "市盈率": 16.28,
        "成交量": 231540.0,
        "成交额": 26578612.0,
        "振幅": 0.89,
        "换手率": 0.46,
        "symbol": "106.TNET"
    },
    {
        "序号": 5355,
        "name": "Invesco Dow Jones Industrial Av",
        "最新价": 44.14,
        "涨跌额": 0.05,
        "涨跌幅": 0.11,
        "开盘价": 44.13,
        "最高价": 44.29,
        "最低价": 43.94,
        "昨收价": 44.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 29744.0,
        "成交额": 1313597.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.DJD"
    },
    {
        "序号": 5356,
        "name": "Direxion NASDAQ-100 Equal Weigh",
        "最新价": 79.51,
        "涨跌额": 0.09,
        "涨跌幅": 0.11,
        "开盘价": 79.11,
        "最高价": 79.82,
        "最低价": 79.0,
        "昨收价": 79.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 161487.0,
        "成交额": 12833200.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "105.QQQE"
    },
    {
        "序号": 5357,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 35.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.11,
        "开盘价": 35.26,
        "最高价": 35.41,
        "最低价": 35.26,
        "昨收价": 35.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 10657.0,
        "成交额": 376471.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.XSEP"
    },
    {
        "序号": 5358,
        "name": "FT Cboe Vest U.S. Equity Deep B",
        "最新价": 35.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.11,
        "开盘价": 35.36,
        "最高价": 35.41,
        "最低价": 35.35,
        "昨收价": 35.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 21835.0,
        "成交额": 772335.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.DDEC"
    },
    {
        "序号": 5359,
        "name": "威达信",
        "最新价": 195.67,
        "涨跌额": 0.22,
        "涨跌幅": 0.11,
        "开盘价": 195.79,
        "最高价": 196.62,
        "最低价": 194.4,
        "昨收价": 195.45,
        "总市值": 96479484139.0,
        "市盈率": 27.84,
        "成交量": 1291238.0,
        "成交额": 252294021.0,
        "振幅": 1.14,
        "换手率": 0.26,
        "symbol": "106.MMC"
    },
    {
        "序号": 5360,
        "name": "国际红利股ETF-iShares",
        "最新价": 26.88,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 26.73,
        "最高价": 26.93,
        "最低价": 26.73,
        "昨收价": 26.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 535137.0,
        "成交额": 14370747.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.IDV"
    },
    {
        "序号": 5361,
        "name": "First Trust Dorsey Wright Momen",
        "最新价": 26.9,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 26.85,
        "最高价": 26.9,
        "最低价": 26.81,
        "昨收价": 26.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 2948.0,
        "成交额": 79162.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.DVOL"
    },
    {
        "序号": 5362,
        "name": "Eaton Vance California Municipa",
        "最新价": 8.99,
        "涨跌额": 0.01,
        "涨跌幅": 0.11,
        "开盘价": 8.95,
        "最高价": 9.03,
        "最低价": 8.95,
        "昨收价": 8.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 100887.0,
        "成交额": 905247.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.EVM"
    },
    {
        "序号": 5363,
        "name": "Bridges Capital Tactical ETF",
        "最新价": 26.99,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 27.0,
        "最高价": 27.04,
        "最低价": 26.99,
        "昨收价": 26.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 2422.0,
        "成交额": 65461.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "105.BDGS"
    },
    {
        "序号": 5364,
        "name": "FRT信托",
        "最新价": 99.12,
        "涨跌额": 0.11,
        "涨跌幅": 0.11,
        "开盘价": 98.67,
        "最高价": 99.3,
        "最低价": 97.93,
        "昨收价": 99.01,
        "总市值": 8090487917.0,
        "市盈率": 28.04,
        "成交量": 368230.0,
        "成交额": 36427184.0,
        "振幅": 1.38,
        "换手率": 0.45,
        "symbol": "106.FRT"
    },
    {
        "序号": 5365,
        "name": "First Trust Water ETF",
        "最新价": 90.19,
        "涨跌额": 0.1,
        "涨跌幅": 0.11,
        "开盘价": 89.89,
        "最高价": 90.68,
        "最低价": 89.88,
        "昨收价": 90.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 36656.0,
        "成交额": 3309576.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.FIW"
    },
    {
        "序号": 5366,
        "name": "IQ Candriam ESG International E",
        "最新价": 27.2,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 27.19,
        "最高价": 27.2,
        "最低价": 27.16,
        "昨收价": 27.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 9202.0,
        "成交额": 250157.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.IQSI"
    },
    {
        "序号": 5367,
        "name": "Trex Co Inc",
        "最新价": 72.96,
        "涨跌额": 0.08,
        "涨跌幅": 0.11,
        "开盘价": 72.76,
        "最高价": 74.25,
        "最低价": 72.76,
        "昨收价": 72.88,
        "总市值": 7923118998.0,
        "市盈率": 40.94,
        "成交量": 457025.0,
        "成交额": 33476512.0,
        "振幅": 2.04,
        "换手率": 0.42,
        "symbol": "106.TREX"
    },
    {
        "序号": 5368,
        "name": "BlackRock Large Cap Value ETF",
        "最新价": 27.37,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 27.35,
        "最高价": 27.38,
        "最低价": 27.26,
        "昨收价": 27.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 1657.0,
        "成交额": 45279.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.BLCV"
    },
    {
        "序号": 5369,
        "name": "新兴市场高收益债ETF",
        "最新价": 18.26,
        "涨跌额": 0.02,
        "涨跌幅": 0.11,
        "开盘价": 18.22,
        "最高价": 18.29,
        "最低价": 18.15,
        "昨收价": 18.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 43817.0,
        "成交额": 798617.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.HYEM"
    },
    {
        "序号": 5370,
        "name": "西班牙毕尔巴鄂比斯开银行(ADR)",
        "最新价": 9.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.11,
        "开盘价": 9.08,
        "最高价": 9.16,
        "最低价": 9.07,
        "昨收价": 9.13,
        "总市值": 54524423266.0,
        "市盈率": 6.83,
        "成交量": 683517.0,
        "成交额": 6233673.0,
        "振幅": 0.99,
        "换手率": 0.01,
        "symbol": "106.BBVA"
    },
    {
        "序号": 5371,
        "name": "富兰克林电子",
        "最新价": 92.05,
        "涨跌额": 0.1,
        "涨跌幅": 0.11,
        "开盘价": 91.95,
        "最高价": 92.82,
        "最低价": 91.56,
        "昨收价": 91.95,
        "总市值": 4246093998.0,
        "市盈率": 21.85,
        "成交量": 117580.0,
        "成交额": 10824636.0,
        "振幅": 1.37,
        "换手率": 0.25,
        "symbol": "105.FELE"
    },
    {
        "序号": 5372,
        "name": "美国股票投资寿险",
        "最新价": 55.38,
        "涨跌额": 0.06,
        "涨跌幅": 0.11,
        "开盘价": 55.41,
        "最高价": 55.46,
        "最低价": 55.16,
        "昨收价": 55.32,
        "总市值": 4376394864.0,
        "市盈率": 496.08,
        "成交量": 303806.0,
        "成交额": 16804075.0,
        "振幅": 0.54,
        "换手率": 0.38,
        "symbol": "106.AEL"
    },
    {
        "序号": 5373,
        "name": "伯克希尔哈撒韦-B",
        "最新价": 353.08,
        "涨跌额": 0.38,
        "涨跌幅": 0.11,
        "开盘价": 353.0,
        "最高价": 353.67,
        "最低价": 351.02,
        "昨收价": 352.7,
        "总市值": 461974847956.0,
        "市盈率": 6.03,
        "成交量": 3289270.0,
        "成交额": 1160238512.0,
        "振幅": 0.75,
        "换手率": 0.25,
        "symbol": "106.BRK_B"
    },
    {
        "序号": 5374,
        "name": "沃那多房产信托",
        "最新价": 27.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 27.63,
        "最高价": 27.98,
        "最低价": 27.21,
        "昨收价": 27.94,
        "总市值": 5323299515.0,
        "市盈率": -16.29,
        "成交量": 2429680.0,
        "成交额": 67132438.0,
        "振幅": 2.76,
        "换手率": 1.28,
        "symbol": "106.VNO"
    },
    {
        "序号": 5375,
        "name": "Humankind US Stock ETF",
        "最新价": 28.32,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 28.32,
        "最高价": 28.32,
        "最低价": 28.32,
        "昨收价": 28.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 24.0,
        "成交额": 679.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HKND"
    },
    {
        "序号": 5376,
        "name": "The Eastern Co",
        "最新价": 18.9,
        "涨跌额": 0.02,
        "涨跌幅": 0.11,
        "开盘价": 18.66,
        "最高价": 19.29,
        "最低价": 18.61,
        "昨收价": 18.88,
        "总市值": 117906006.0,
        "市盈率": 21.65,
        "成交量": 16307.0,
        "成交额": 309293.0,
        "振幅": 3.6,
        "换手率": 0.26,
        "symbol": "105.EML"
    },
    {
        "序号": 5377,
        "name": "Americold Realty Trust Inc",
        "最新价": 28.38,
        "涨跌额": 0.03,
        "涨跌幅": 0.11,
        "开盘价": 28.13,
        "最高价": 28.49,
        "最低价": 28.04,
        "昨收价": 28.35,
        "总市值": 8046294961.0,
        "市盈率": -75.6,
        "成交量": 1927432.0,
        "成交额": 54607178.0,
        "振幅": 1.59,
        "换手率": 0.68,
        "symbol": "106.COLD"
    },
    {
        "序号": 5378,
        "name": "CGI Inc",
        "最新价": 104.13,
        "涨跌额": 0.11,
        "涨跌幅": 0.11,
        "开盘价": 103.84,
        "最高价": 104.5,
        "最低价": 103.61,
        "昨收价": 104.02,
        "总市值": 24191323739.0,
        "市盈率": 19.88,
        "成交量": 99168.0,
        "成交额": 10328851.0,
        "振幅": 0.86,
        "换手率": 0.04,
        "symbol": "106.GIB"
    },
    {
        "序号": 5379,
        "name": "Medallion Financial Corp",
        "最新价": 9.51,
        "涨跌额": 0.01,
        "涨跌幅": 0.11,
        "开盘价": 9.34,
        "最高价": 9.57,
        "最低价": 9.34,
        "昨收价": 9.5,
        "总市值": 222189082.0,
        "市盈率": 4.13,
        "成交量": 25091.0,
        "成交额": 238433.0,
        "振幅": 2.42,
        "换手率": 0.11,
        "symbol": "105.MFIN"
    },
    {
        "序号": 5380,
        "name": "BlackRock Health Sciences Trust",
        "最新价": 38.55,
        "涨跌额": 0.04,
        "涨跌幅": 0.1,
        "开盘价": 38.43,
        "最高价": 38.61,
        "最低价": 38.37,
        "昨收价": 38.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 52879.0,
        "成交额": 2037180.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "106.BME"
    },
    {
        "序号": 5381,
        "name": "Amcor plc",
        "最新价": 9.65,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 9.65,
        "最高价": 9.77,
        "最低价": 9.62,
        "昨收价": 9.64,
        "总市值": 13947561996.0,
        "市盈率": 14.41,
        "成交量": 8469015.0,
        "成交额": 82033519.0,
        "振幅": 1.56,
        "换手率": 0.59,
        "symbol": "106.AMCR"
    },
    {
        "序号": 5382,
        "name": "First Trust CEF Income Opportun",
        "最新价": 19.35,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 19.34,
        "最高价": 19.37,
        "最低价": 19.18,
        "昨收价": 19.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 6107.0,
        "成交额": 117824.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "105.FCEF"
    },
    {
        "序号": 5383,
        "name": "VictoryShares Developed Enhance",
        "最新价": 29.08,
        "涨跌额": 0.03,
        "涨跌幅": 0.1,
        "开盘价": 29.01,
        "最高价": 29.09,
        "最低价": 29.0,
        "昨收价": 29.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 6685.0,
        "成交额": 194191.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.CIZ"
    },
    {
        "序号": 5384,
        "name": "医疗ETF-First Trust AlphaDEX",
        "最新价": 97.53,
        "涨跌额": 0.1,
        "涨跌幅": 0.1,
        "开盘价": 97.34,
        "最高价": 97.77,
        "最低价": 96.98,
        "昨收价": 97.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 32089.0,
        "成交额": 3124002.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.FXH"
    },
    {
        "序号": 5385,
        "name": "OptimizeRx Corp",
        "最新价": 9.82,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 9.77,
        "最高价": 10.0,
        "最低价": 9.68,
        "昨收价": 9.81,
        "总市值": 178257334.0,
        "市盈率": -12.96,
        "成交量": 111103.0,
        "成交额": 1093097.0,
        "振幅": 3.26,
        "换手率": 0.61,
        "symbol": "105.OPRX"
    },
    {
        "序号": 5386,
        "name": "Chimera Investment Corp Series ",
        "最新价": 19.68,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 19.66,
        "最高价": 19.87,
        "最低价": 19.61,
        "昨收价": 19.66,
        "总市值": 204672000.0,
        "市盈率": null,
        "成交量": 17146.0,
        "成交额": 337519.0,
        "振幅": 1.32,
        "换手率": 0.16,
        "symbol": "106.CIM_C"
    },
    {
        "序号": 5387,
        "name": "The Future Fund Long\/Short ETF",
        "最新价": 19.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 19.79,
        "最高价": 19.79,
        "最低价": 19.79,
        "昨收价": 19.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 34.0,
        "成交额": 672.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FFLS"
    },
    {
        "序号": 5388,
        "name": "ProShares S&P Global Core Batte",
        "最新价": 29.69,
        "涨跌额": 0.03,
        "涨跌幅": 0.1,
        "开盘价": 29.64,
        "最高价": 29.72,
        "最低价": 29.54,
        "昨收价": 29.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 777.0,
        "成交额": 23034.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.ION"
    },
    {
        "序号": 5389,
        "name": "福纳",
        "最新价": 19.82,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 19.68,
        "最高价": 20.1,
        "最低价": 19.54,
        "昨收价": 19.8,
        "总市值": 135440783.0,
        "市盈率": 11.84,
        "成交量": 26329.0,
        "成交额": 523093.0,
        "振幅": 2.83,
        "换手率": 0.39,
        "symbol": "105.FONR"
    },
    {
        "序号": 5390,
        "name": "硅谷国家银行",
        "最新价": 9.95,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 9.91,
        "最高价": 10.11,
        "最低价": 9.84,
        "昨收价": 9.94,
        "总市值": 5051456586.0,
        "市盈率": 8.36,
        "成交量": 2723260.0,
        "成交额": 27141166.0,
        "振幅": 2.72,
        "换手率": 0.54,
        "symbol": "105.VLY"
    },
    {
        "序号": 5391,
        "name": "Global X E-commerce ETF",
        "最新价": 19.9,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 19.71,
        "最高价": 19.97,
        "最低价": 19.71,
        "昨收价": 19.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 39872.0,
        "成交额": 793655.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "105.EBIZ"
    },
    {
        "序号": 5392,
        "name": "MDB Capital Holdings LLC-A",
        "最新价": 10.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.01,
        "最高价": 10.27,
        "最低价": 10.01,
        "昨收价": 10.04,
        "总市值": 93421102.0,
        "市盈率": null,
        "成交量": 2360.0,
        "成交额": 23980.0,
        "振幅": 2.59,
        "换手率": 0.03,
        "symbol": "105.MDBH"
    },
    {
        "序号": 5393,
        "name": "BlackRock Future Climate and Su",
        "最新价": 20.11,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.11,
        "最高价": 20.11,
        "最低价": 20.11,
        "昨收价": 20.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 198.0,
        "成交额": 3982.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BECO"
    },
    {
        "序号": 5394,
        "name": "Quetta Acquisition Corp",
        "最新价": 10.09,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.09,
        "最高价": 10.09,
        "最低价": 10.09,
        "昨收价": 10.08,
        "总市值": 90275684.0,
        "市盈率": null,
        "成交量": 50000.0,
        "成交额": 504500.0,
        "振幅": 0.0,
        "换手率": 0.56,
        "symbol": "105.QETA"
    },
    {
        "序号": 5395,
        "name": "国际高收益债ETF-Market Vectors",
        "最新价": 20.28,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.13,
        "最高价": 20.28,
        "最低价": 20.13,
        "昨收价": 20.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 162621.0,
        "成交额": 3292671.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.IHY"
    },
    {
        "序号": 5396,
        "name": "海斯特-耶鲁物料搬运",
        "最新价": 50.7,
        "涨跌额": 0.05,
        "涨跌幅": 0.1,
        "开盘价": 50.65,
        "最高价": 51.5,
        "最低价": 50.42,
        "昨收价": 50.65,
        "总市值": 871311441.0,
        "市盈率": 8.05,
        "成交量": 63625.0,
        "成交额": 3227277.0,
        "振幅": 2.13,
        "换手率": 0.37,
        "symbol": "106.HY"
    },
    {
        "序号": 5397,
        "name": "Agriculture & Natural Solutions",
        "最新价": 10.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.13,
        "最高价": 10.14,
        "最低价": 10.12,
        "昨收价": 10.13,
        "总市值": 380250000.0,
        "市盈率": -197737.91,
        "成交量": 369000.0,
        "成交额": 3737994.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.ANSCU"
    },
    {
        "序号": 5398,
        "name": "99 Acquisition Group Inc-A",
        "最新价": 10.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.16,
        "最高价": 10.19,
        "最低价": 10.16,
        "昨收价": 10.15,
        "总市值": 102362000.0,
        "市盈率": null,
        "成交量": 295259.0,
        "成交额": 3000583.0,
        "振幅": 0.3,
        "换手率": 2.93,
        "symbol": "105.NNAG"
    },
    {
        "序号": 5399,
        "name": "Haymaker Acquisition Corp 4-A",
        "最新价": 10.28,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.28,
        "最高价": 10.28,
        "最低价": 10.28,
        "昨收价": 10.27,
        "总市值": 303749328.0,
        "市盈率": null,
        "成交量": 4340.0,
        "成交额": 44615.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.HYAC"
    },
    {
        "序号": 5400,
        "name": "Unlimited HFND Multi-Strategy R",
        "最新价": 20.57,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.55,
        "最高价": 20.61,
        "最低价": 20.55,
        "昨收价": 20.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 8601.0,
        "成交额": 177039.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.HFND"
    },
    {
        "序号": 5401,
        "name": "Pacer BlueStar Engineering the ",
        "最新价": 20.6,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.6,
        "最高价": 20.6,
        "最低价": 20.6,
        "昨收价": 20.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 114.0,
        "成交额": 2348.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BULD"
    },
    {
        "序号": 5402,
        "name": "Direxion Moonshot Innovators ET",
        "最新价": 10.35,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.45,
        "最高价": 10.57,
        "最低价": 10.27,
        "昨收价": 10.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 16783.0,
        "成交额": 173655.0,
        "振幅": 2.9,
        "换手率": null,
        "symbol": "107.MOON"
    },
    {
        "序号": 5403,
        "name": "National Research Corp",
        "最新价": 41.5,
        "涨跌额": 0.04,
        "涨跌幅": 0.1,
        "开盘价": 41.31,
        "最高价": 41.55,
        "最低价": 41.29,
        "昨收价": 41.46,
        "总市值": 1019170695.0,
        "市盈率": 35.44,
        "成交量": 107232.0,
        "成交额": 4450138.0,
        "振幅": 0.63,
        "换手率": 0.44,
        "symbol": "105.NRC"
    },
    {
        "序号": 5404,
        "name": "Cambria Global Value ETF",
        "最新价": 20.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.62,
        "最高价": 20.94,
        "最低价": 20.62,
        "昨收价": 20.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 5941.0,
        "成交额": 123235.0,
        "振幅": 1.54,
        "换手率": null,
        "symbol": "107.GVAL"
    },
    {
        "序号": 5405,
        "name": "VanEck CEF Muni Income ETF",
        "最新价": 20.8,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.73,
        "最高价": 20.81,
        "最低价": 20.72,
        "昨收价": 20.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 136165.0,
        "成交额": 2827214.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.XMPT"
    },
    {
        "序号": 5406,
        "name": "国际货币快递",
        "最新价": 20.83,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.81,
        "最高价": 21.14,
        "最低价": 20.71,
        "昨收价": 20.81,
        "总市值": 726280089.0,
        "市盈率": 13.19,
        "成交量": 161288.0,
        "成交额": 3361319.0,
        "振幅": 2.07,
        "换手率": 0.46,
        "symbol": "105.IMXI"
    },
    {
        "序号": 5407,
        "name": "Fidelity Low Volatility Factor ",
        "最新价": 52.13,
        "涨跌额": 0.05,
        "涨跌幅": 0.1,
        "开盘价": 52.0,
        "最高价": 52.16,
        "最低价": 51.88,
        "昨收价": 52.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 76061.0,
        "成交额": 3956142.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.FDLO"
    },
    {
        "序号": 5408,
        "name": "Hoya Capital High Dividend Yiel",
        "最新价": 10.44,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.47,
        "最高价": 10.47,
        "最低价": 10.36,
        "昨收价": 10.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 30570.0,
        "成交额": 318492.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.RIET"
    },
    {
        "序号": 5409,
        "name": "FT Cboe Vest U.S. Equity Modera",
        "最新价": 31.36,
        "涨跌额": 0.03,
        "涨跌幅": 0.1,
        "开盘价": 31.32,
        "最高价": 31.41,
        "最低价": 31.3,
        "昨收价": 31.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 1923347.0,
        "成交额": 60220676.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.GJUL"
    },
    {
        "序号": 5410,
        "name": "JPMorgan Hedged Equity Laddered",
        "最新价": 52.36,
        "涨跌额": 0.05,
        "涨跌幅": 0.1,
        "开盘价": 52.27,
        "最高价": 52.41,
        "最低价": 52.23,
        "昨收价": 52.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 36585.0,
        "成交额": 1915516.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.HELO"
    },
    {
        "序号": 5411,
        "name": "Avantis Moderate Allocation ETF",
        "最新价": 52.41,
        "涨跌额": 0.05,
        "涨跌幅": 0.1,
        "开盘价": 52.38,
        "最高价": 52.45,
        "最低价": 52.36,
        "昨收价": 52.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 4089.0,
        "成交额": 214224.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.AVMA"
    },
    {
        "序号": 5412,
        "name": "计算机作业集团",
        "最新价": 10.49,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.48,
        "最高价": 10.49,
        "最低价": 10.48,
        "昨收价": 10.48,
        "总市值": 168831976.0,
        "市盈率": 312.07,
        "成交量": 31754.0,
        "成交额": 332803.0,
        "振幅": 0.1,
        "换手率": 0.2,
        "symbol": "105.CTG"
    },
    {
        "序号": 5413,
        "name": "AAM Low Duration Preferred and ",
        "最新价": 21.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.1,
        "开盘价": 20.98,
        "最高价": 21.0,
        "最低价": 20.88,
        "昨收价": 20.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 43646.0,
        "成交额": 913448.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.PFLD"
    },
    {
        "序号": 5414,
        "name": "Gores Holdings IX Inc-A",
        "最新价": 10.51,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.5,
        "最高价": 10.53,
        "最低价": 10.48,
        "昨收价": 10.5,
        "总市值": 689718750.0,
        "市盈率": 30.2,
        "成交量": 15235.0,
        "成交额": 159970.0,
        "振幅": 0.48,
        "换手率": 0.02,
        "symbol": "105.GHIX"
    },
    {
        "序号": 5415,
        "name": "TMT Acquisition Corp",
        "最新价": 10.53,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "开盘价": 10.51,
        "最高价": 10.53,
        "最低价": 10.51,
        "昨收价": 10.52,
        "总市值": 85714200.0,
        "市盈率": 81.11,
        "成交量": 962.0,
        "成交额": 10114.0,
        "振幅": 0.19,
        "换手率": 0.01,
        "symbol": "105.TMTC"
    },
    {
        "序号": 5416,
        "name": "Concord Acquisition Corp III-A",
        "最新价": 10.54,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.56,
        "最高价": 10.56,
        "最低价": 10.53,
        "昨收价": 10.53,
        "总市值": 132449445.0,
        "市盈率": 21.15,
        "成交量": 27930.0,
        "成交额": 294137.0,
        "振幅": 0.28,
        "换手率": 0.22,
        "symbol": "106.CNDB"
    },
    {
        "序号": 5417,
        "name": "葡萄牙ETF-Global X MSCI",
        "最新价": 10.55,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.55,
        "昨收价": 10.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 433.0,
        "成交额": 4568.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PGAL"
    },
    {
        "序号": 5418,
        "name": "JPMorgan BetaBuilders MSCI US R",
        "最新价": 84.43,
        "涨跌额": 0.08,
        "涨跌幅": 0.09,
        "开盘价": 84.06,
        "最高价": 84.46,
        "最低价": 83.68,
        "昨收价": 84.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 15045.0,
        "成交额": 1264770.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.BBRE"
    },
    {
        "序号": 5419,
        "name": "Screaming Eagle Acquisition Cor",
        "最新价": 10.57,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.55,
        "最高价": 10.62,
        "最低价": 10.55,
        "昨收价": 10.56,
        "总市值": 990937500.0,
        "市盈率": 30.67,
        "成交量": 24352.0,
        "成交额": 257598.0,
        "振幅": 0.66,
        "换手率": 0.03,
        "symbol": "105.SCRM"
    },
    {
        "序号": 5420,
        "name": "M3-Brigade Acquisition II Corp-",
        "最新价": 10.59,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.58,
        "最高价": 10.59,
        "最低价": 10.58,
        "昨收价": 10.58,
        "总市值": 153946629.0,
        "市盈率": 31.01,
        "成交量": 4838.0,
        "成交额": 51191.0,
        "振幅": 0.09,
        "换手率": 0.03,
        "symbol": "106.MBAC"
    },
    {
        "序号": 5421,
        "name": "Pono Capital Three Inc Unit Con",
        "最新价": 10.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.61,
        "最高价": 10.61,
        "最低价": 10.61,
        "昨收价": 10.6,
        "总市值": 181478713.0,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 1061.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PTHRU"
    },
    {
        "序号": 5422,
        "name": "联合火险",
        "最新价": 21.23,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.18,
        "最高价": 21.41,
        "最低价": 21.02,
        "昨收价": 21.21,
        "总市值": 536383402.0,
        "市盈率": -18.38,
        "成交量": 55637.0,
        "成交额": 1178318.0,
        "振幅": 1.84,
        "换手率": 0.22,
        "symbol": "105.UFCS"
    },
    {
        "序号": 5423,
        "name": "FT Cboe Vest International Equi",
        "最新价": 21.25,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.22,
        "最高价": 21.26,
        "最低价": 21.21,
        "昨收价": 21.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 2780.0,
        "成交额": 59040.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.YMAR"
    },
    {
        "序号": 5424,
        "name": "Inception Growth Acquisition Lt",
        "最新价": 10.64,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.63,
        "最高价": 10.64,
        "最低价": 10.63,
        "昨收价": 10.63,
        "总市值": 59460480.0,
        "市盈率": 46.29,
        "成交量": 1847.0,
        "成交额": 19639.0,
        "振幅": 0.09,
        "换手率": 0.03,
        "symbol": "105.IGTA"
    },
    {
        "序号": 5425,
        "name": "Invesco BulletShares 2023 Corpo",
        "最新价": 21.28,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.26,
        "最高价": 21.28,
        "最低价": 21.26,
        "昨收价": 21.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 917465.0,
        "成交额": 19510240.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.BSCN"
    },
    {
        "序号": 5426,
        "name": "Neuberger Berman Commodity Stra",
        "最新价": 21.3,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.34,
        "最高价": 21.34,
        "最低价": 21.25,
        "昨收价": 21.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 58061.0,
        "成交额": 1235645.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.NBCM"
    },
    {
        "序号": 5427,
        "name": "BlueRiver Acquisition Corp-A",
        "最新价": 10.65,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.65,
        "最高价": 10.65,
        "最低价": 10.65,
        "昨收价": 10.64,
        "总市值": 105013558.0,
        "市盈率": -70.02,
        "成交量": 556.0,
        "成交额": 5921.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.BLUA"
    },
    {
        "序号": 5428,
        "name": "ExcelFin Acquisition Corp-A",
        "最新价": 10.68,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.68,
        "最高价": 10.68,
        "最低价": 10.65,
        "昨收价": 10.67,
        "总市值": 84922372.0,
        "市盈率": -52.46,
        "成交量": 2049.0,
        "成交额": 21869.0,
        "振幅": 0.28,
        "换手率": 0.03,
        "symbol": "105.XFIN"
    },
    {
        "序号": 5429,
        "name": "First Trust Bloomberg Emerging ",
        "最新价": 21.37,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.34,
        "最高价": 21.37,
        "最低价": 21.34,
        "昨收价": 21.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 187.0,
        "成交额": 3990.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.EMDM"
    },
    {
        "序号": 5430,
        "name": "Neuberger Berman Next Generatio",
        "最新价": 10.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.64,
        "最高价": 10.74,
        "最低价": 10.63,
        "昨收价": 10.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 156125.0,
        "成交额": 1670045.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.NBXG"
    },
    {
        "序号": 5431,
        "name": "Newbury Street Acquisition Corp",
        "最新价": 10.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.64,
        "最高价": 11.35,
        "最低价": 10.64,
        "昨收价": 10.69,
        "总市值": 62674533.0,
        "市盈率": -104.66,
        "成交量": 3680.0,
        "成交额": 39351.0,
        "振幅": 6.64,
        "换手率": 0.06,
        "symbol": "105.NBST"
    },
    {
        "序号": 5432,
        "name": "Timothy Plan High Dividend Stoc",
        "最新价": 32.19,
        "涨跌额": 0.03,
        "涨跌幅": 0.09,
        "开盘价": 32.16,
        "最高价": 32.3,
        "最低价": 32.14,
        "昨收价": 32.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 11586.0,
        "成交额": 373290.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.TPHD"
    },
    {
        "序号": 5433,
        "name": "Fintech Ecosystem Development C",
        "最新价": 10.73,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.72,
        "最高价": 10.73,
        "最低价": 10.72,
        "昨收价": 10.72,
        "总市值": 74085317.0,
        "市盈率": 41.66,
        "成交量": 1106.0,
        "成交额": 11860.0,
        "振幅": 0.09,
        "换手率": 0.02,
        "symbol": "105.FEXD"
    },
    {
        "序号": 5434,
        "name": "Beard Energy Transition Acquisi",
        "最新价": 10.73,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.72,
        "最高价": 10.73,
        "最低价": 10.72,
        "昨收价": 10.72,
        "总市值": 138198151.0,
        "市盈率": 29.46,
        "成交量": 1052.0,
        "成交额": 11280.0,
        "振幅": 0.09,
        "换手率": 0.01,
        "symbol": "106.BRD"
    },
    {
        "序号": 5435,
        "name": "Magnum Opus Acquisition Ltd-A",
        "最新价": 10.74,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.74,
        "最高价": 10.74,
        "最低价": 10.74,
        "昨收价": 10.73,
        "总市值": 124531557.0,
        "市盈率": 8.02,
        "成交量": 51802.0,
        "成交额": 556349.0,
        "振幅": 0.0,
        "换手率": 0.45,
        "symbol": "106.OPA"
    },
    {
        "序号": 5436,
        "name": "Northern Technologies Internati",
        "最新价": 10.77,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.78,
        "最高价": 10.94,
        "最低价": 10.75,
        "昨收价": 10.76,
        "总市值": 101535241.0,
        "市盈率": 34.86,
        "成交量": 4293.0,
        "成交额": 46475.0,
        "振幅": 1.77,
        "换手率": 0.05,
        "symbol": "105.NTIC"
    },
    {
        "序号": 5437,
        "name": "Main International ETF",
        "最新价": 21.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.5,
        "最高价": 21.55,
        "最低价": 21.5,
        "昨收价": 21.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 3928.0,
        "成交额": 84480.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.INTL"
    },
    {
        "序号": 5438,
        "name": "First Trust DJ Global Select Di",
        "最新价": 21.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.4,
        "最高价": 21.58,
        "最低价": 21.4,
        "昨收价": 21.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 53002.0,
        "成交额": 1140102.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.FGD"
    },
    {
        "序号": 5439,
        "name": "ArcBest Corp",
        "最新价": 107.76,
        "涨跌额": 0.1,
        "涨跌幅": 0.09,
        "开盘价": 107.36,
        "最高价": 110.68,
        "最低价": 106.9,
        "昨收价": 107.66,
        "总市值": 2549802896.0,
        "市盈率": 13.86,
        "成交量": 264645.0,
        "成交额": 28564438.0,
        "振幅": 3.51,
        "换手率": 1.12,
        "symbol": "105.ARCB"
    },
    {
        "序号": 5440,
        "name": "Spring Valley Acquisition Corp ",
        "最新价": 10.79,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.78,
        "最高价": 10.8,
        "最低价": 10.78,
        "昨收价": 10.78,
        "总市值": 330893337.0,
        "市盈率": 35.01,
        "成交量": 105698.0,
        "成交额": 1139623.0,
        "振幅": 0.19,
        "换手率": 0.34,
        "symbol": "105.SVII"
    },
    {
        "序号": 5441,
        "name": "GAMCO Global Gold, Natural Reso",
        "最新价": 21.66,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.66,
        "最高价": 21.66,
        "最低价": 21.66,
        "昨收价": 21.64,
        "总市值": 73835258.0,
        "市盈率": null,
        "成交量": 414.0,
        "成交额": 8965.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.GGN_B"
    },
    {
        "序号": 5442,
        "name": "Battery Future Acquisition Corp",
        "最新价": 10.85,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.82,
        "最高价": 10.85,
        "最低价": 10.82,
        "昨收价": 10.84,
        "总市值": 217671886.0,
        "市盈率": 24.22,
        "成交量": 916.0,
        "成交额": 9925.0,
        "振幅": 0.28,
        "换手率": 0.0,
        "symbol": "106.BFAC"
    },
    {
        "序号": 5443,
        "name": "Aura FAT Projects Acquisition C",
        "最新价": 10.85,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.85,
        "昨收价": 10.84,
        "总市值": 62731402.0,
        "市盈率": null,
        "成交量": 5151.0,
        "成交额": 55888.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "105.AFAR"
    },
    {
        "序号": 5444,
        "name": "Wahed Dow Jones Islamic World E",
        "最新价": 21.72,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.65,
        "最高价": 21.81,
        "最低价": 21.65,
        "昨收价": 21.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 5901.0,
        "成交额": 128375.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "105.UMMA"
    },
    {
        "序号": 5445,
        "name": "Andretti Acquisition Corp-A",
        "最新价": 10.87,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.85,
        "最高价": 10.87,
        "最低价": 10.85,
        "昨收价": 10.86,
        "总市值": 148318987.0,
        "市盈率": 66.34,
        "成交量": 5145.0,
        "成交额": 55874.0,
        "振幅": 0.18,
        "换手率": 0.04,
        "symbol": "106.WNNR"
    },
    {
        "序号": 5446,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 32.64,
        "涨跌额": 0.03,
        "涨跌幅": 0.09,
        "开盘价": 32.6,
        "最高价": 32.66,
        "最低价": 32.59,
        "昨收价": 32.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 6894.0,
        "成交额": 224893.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.XMAR"
    },
    {
        "序号": 5447,
        "name": "Everest Consolidator Acquisitio",
        "最新价": 10.88,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.87,
        "最高价": 10.88,
        "最低价": 10.87,
        "昨收价": 10.87,
        "总市值": 234600000.0,
        "市盈率": -35.12,
        "成交量": 2044.0,
        "成交额": 22218.0,
        "振幅": 0.09,
        "换手率": 0.01,
        "symbol": "106.MNTN"
    },
    {
        "序号": 5448,
        "name": "家得宝",
        "最新价": 326.47,
        "涨跌额": 0.3,
        "涨跌幅": 0.09,
        "开盘价": 326.0,
        "最高价": 327.28,
        "最低价": 325.06,
        "昨收价": 326.17,
        "总市值": 324923134211.0,
        "市盈率": 20.69,
        "成交量": 2889470.0,
        "成交额": 943264144.0,
        "振幅": 0.68,
        "换手率": 0.29,
        "symbol": "106.HD"
    },
    {
        "序号": 5449,
        "name": "Healthcare AI Acquisition Corp-",
        "最新价": 10.9,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.9,
        "昨收价": 10.89,
        "总市值": 58757540.0,
        "市盈率": 8.22,
        "成交量": 230.0,
        "成交额": 2507.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.HAIA"
    },
    {
        "序号": 5450,
        "name": "全球除美国ETF-Vanguard FTSE",
        "最新价": 54.51,
        "涨跌额": 0.05,
        "涨跌幅": 0.09,
        "开盘价": 54.27,
        "最高价": 54.6,
        "最低价": 54.25,
        "昨收价": 54.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 2025475.0,
        "成交额": 110265996.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.VEU"
    },
    {
        "序号": 5451,
        "name": "Aimfinity Investment Corp I Uni",
        "最新价": 10.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.9,
        "最高价": 10.91,
        "最低价": 10.9,
        "昨收价": 10.9,
        "总市值": 70679148.0,
        "市盈率": 42.22,
        "成交量": 649.0,
        "成交额": 7079.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.AIMBU"
    },
    {
        "序号": 5452,
        "name": "AAM S&P Developed Markets High ",
        "最新价": 21.83,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.84,
        "最高价": 21.84,
        "最低价": 21.83,
        "昨收价": 21.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 107.0,
        "成交额": 2336.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.DMDV"
    },
    {
        "序号": 5453,
        "name": "IQ Cleaner Transport ETF",
        "最新价": 21.84,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.84,
        "最高价": 21.84,
        "最低价": 21.84,
        "昨收价": 21.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 109.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CLNR"
    },
    {
        "序号": 5454,
        "name": "Liberty Resources Acquisition C",
        "最新价": 10.93,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.91,
        "最高价": 10.93,
        "最低价": 10.91,
        "昨收价": 10.92,
        "总市值": 78821258.0,
        "市盈率": 149.44,
        "成交量": 6435.0,
        "成交额": 70207.0,
        "振幅": 0.18,
        "换手率": 0.09,
        "symbol": "105.LIBY"
    },
    {
        "序号": 5455,
        "name": "NCR Atleos Corp",
        "最新价": 21.88,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 21.73,
        "最高价": 22.0,
        "最低价": 21.29,
        "昨收价": 21.86,
        "总市值": 1544728000.0,
        "市盈率": null,
        "成交量": 479396.0,
        "成交额": 10424273.0,
        "振幅": 3.25,
        "换手率": 0.68,
        "symbol": "106.NATL"
    },
    {
        "序号": 5456,
        "name": "Acri Capital Acquisition Corp-A",
        "最新价": 10.96,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 10.97,
        "最高价": 10.97,
        "最低价": 10.96,
        "昨收价": 10.95,
        "总市值": 59307848.0,
        "市盈率": null,
        "成交量": 116438.0,
        "成交额": 1277220.0,
        "振幅": 0.09,
        "换手率": 2.15,
        "symbol": "105.ACAC"
    },
    {
        "序号": 5457,
        "name": "艾里逊变速箱",
        "最新价": 54.89,
        "涨跌额": 0.05,
        "涨跌幅": 0.09,
        "开盘价": 54.83,
        "最高价": 55.44,
        "最低价": 54.71,
        "昨收价": 54.84,
        "总市值": 4911815842.0,
        "市盈率": 7.63,
        "成交量": 545554.0,
        "成交额": 29995300.0,
        "振幅": 1.33,
        "换手率": 0.61,
        "symbol": "106.ALSN"
    },
    {
        "序号": 5458,
        "name": "Aurora Technology Acquisition C",
        "最新价": 10.99,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.0,
        "最高价": 11.0,
        "最低价": 10.98,
        "昨收价": 10.98,
        "总市值": 117156609.0,
        "市盈率": 80.01,
        "成交量": 1816.0,
        "成交额": 19946.0,
        "振幅": 0.18,
        "换手率": 0.02,
        "symbol": "105.ATAK"
    },
    {
        "序号": 5459,
        "name": "PIMCO 15 Year US TIPS Index ETF",
        "最新价": 55.03,
        "涨跌额": 0.05,
        "涨跌幅": 0.09,
        "开盘价": 54.55,
        "最高价": 55.03,
        "最低价": 54.35,
        "昨收价": 54.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 86216.0,
        "成交额": 4713067.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "107.LTPZ"
    },
    {
        "序号": 5460,
        "name": "FMQQ The Next Frontier Internet",
        "最新价": 11.02,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.07,
        "最高价": 11.08,
        "最低价": 10.98,
        "昨收价": 11.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 5902.0,
        "成交额": 65033.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.FMQQ"
    },
    {
        "序号": 5461,
        "name": "FT Cboe Vest International Equi",
        "最新价": 22.08,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.06,
        "最高价": 22.09,
        "最低价": 22.02,
        "昨收价": 22.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 2311.0,
        "成交额": 50929.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.YDEC"
    },
    {
        "序号": 5462,
        "name": "Target Global Acquisition I Cor",
        "最新价": 11.04,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.2,
        "最高价": 11.3,
        "最低价": 10.98,
        "昨收价": 11.03,
        "总市值": 108942113.0,
        "市盈率": 19.53,
        "成交量": 18054.0,
        "成交额": 199324.0,
        "振幅": 2.9,
        "换手率": 0.18,
        "symbol": "105.TGAA"
    },
    {
        "序号": 5463,
        "name": "Patria Latin American Opportuni",
        "最新价": 11.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.07,
        "最高价": 11.07,
        "最低价": 11.07,
        "昨收价": 11.06,
        "总市值": 250519425.0,
        "市盈率": 21.54,
        "成交量": 364.0,
        "成交额": 4027.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PLAO"
    },
    {
        "序号": 5464,
        "name": "环球人寿",
        "最新价": 122.36,
        "涨跌额": 0.11,
        "涨跌幅": 0.09,
        "开盘价": 122.41,
        "最高价": 122.83,
        "最低价": 122.04,
        "昨收价": 122.25,
        "总市值": 11516369026.0,
        "市盈率": 14.7,
        "成交量": 488693.0,
        "成交额": 59829174.0,
        "振幅": 0.65,
        "换手率": 0.52,
        "symbol": "106.GL"
    },
    {
        "序号": 5465,
        "name": "Clarivate Plc Series A Pfd",
        "最新价": 33.52,
        "涨跌额": 0.03,
        "涨跌幅": 0.09,
        "开盘价": 33.57,
        "最高价": 34.02,
        "最低价": 33.32,
        "昨收价": 33.49,
        "总市值": 481850000.0,
        "市盈率": null,
        "成交量": 2318.0,
        "成交额": 77858.0,
        "振幅": 2.09,
        "换手率": 0.02,
        "symbol": "106.CLVT_A"
    },
    {
        "序号": 5466,
        "name": "Olympic Steel Inc",
        "最新价": 56.11,
        "涨跌额": 0.05,
        "涨跌幅": 0.09,
        "开盘价": 56.29,
        "最高价": 56.74,
        "最低价": 55.58,
        "昨收价": 56.06,
        "总市值": 624646932.0,
        "市盈率": 15.21,
        "成交量": 61721.0,
        "成交额": 3459721.0,
        "振幅": 2.07,
        "换手率": 0.55,
        "symbol": "105.ZEUS"
    },
    {
        "序号": 5467,
        "name": "Teucrium AiLA Long-Short Agricu",
        "最新价": 22.57,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.57,
        "最高价": 22.57,
        "最低价": 22.57,
        "昨收价": 22.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 50.0,
        "成交额": 1128.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OAIA"
    },
    {
        "序号": 5468,
        "name": "CareTrust REIT Inc",
        "最新价": 22.58,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.51,
        "最高价": 22.7,
        "最低价": 22.49,
        "昨收价": 22.56,
        "总市值": 2689551263.0,
        "市盈率": 64.31,
        "成交量": 1141276.0,
        "成交额": 25778926.0,
        "振幅": 0.93,
        "换手率": 0.96,
        "symbol": "106.CTRE"
    },
    {
        "序号": 5469,
        "name": "KFA Value Line Dynamic Core Equ",
        "最新价": 22.67,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.71,
        "最高价": 22.71,
        "最低价": 22.62,
        "昨收价": 22.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 999.0,
        "成交额": 22630.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.KVLE"
    },
    {
        "序号": 5470,
        "name": "WPP集团(US ADR)",
        "最新价": 45.39,
        "涨跌额": 0.04,
        "涨跌幅": 0.09,
        "开盘价": 44.75,
        "最高价": 45.48,
        "最低价": 44.73,
        "昨收价": 45.35,
        "总市值": 9757376632.0,
        "市盈率": 15.5,
        "成交量": 95420.0,
        "成交额": 4320729.0,
        "振幅": 1.65,
        "换手率": 0.04,
        "symbol": "106.WPP"
    },
    {
        "序号": 5471,
        "name": "Nuveen California AMT-Free Qual",
        "最新价": 11.38,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.36,
        "最高价": 11.44,
        "最低价": 11.36,
        "昨收价": 11.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 158978.0,
        "成交额": 1812593.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.NKX"
    },
    {
        "序号": 5472,
        "name": "Distillate International Fundam",
        "最新价": 22.88,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.82,
        "最高价": 22.89,
        "最低价": 22.8,
        "昨收价": 22.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 1191.0,
        "成交额": 27196.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.DSTX"
    },
    {
        "序号": 5473,
        "name": "Dimensional International Small",
        "最新价": 22.94,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.94,
        "最高价": 22.98,
        "最低价": 22.82,
        "昨收价": 22.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 935210.0,
        "成交额": 21404372.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.DFIS"
    },
    {
        "序号": 5474,
        "name": "Amalgamated Financial Corp",
        "最新价": 22.97,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.9,
        "最高价": 23.14,
        "最低价": 22.86,
        "昨收价": 22.95,
        "总市值": 698141107.0,
        "市盈率": 7.75,
        "成交量": 142531.0,
        "成交额": 3278432.0,
        "振幅": 1.22,
        "换手率": 0.47,
        "symbol": "105.AMAL"
    },
    {
        "序号": 5475,
        "name": "3M公司",
        "最新价": 103.37,
        "涨跌额": 0.09,
        "涨跌幅": 0.09,
        "开盘价": 103.37,
        "最高价": 104.91,
        "最低价": 103.0,
        "昨收价": 103.28,
        "总市值": 57093012218.0,
        "市盈率": -7.72,
        "成交量": 3444015.0,
        "成交额": 356739296.0,
        "振幅": 1.85,
        "换手率": 0.62,
        "symbol": "106.MMM"
    },
    {
        "序号": 5476,
        "name": "Merchants Bancorp Series B Pfd",
        "最新价": 23.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 22.9,
        "最高价": 23.0,
        "最低价": 22.8,
        "昨收价": 22.98,
        "总市值": 2875000.0,
        "市盈率": null,
        "成交量": 11912.0,
        "成交额": 272928.0,
        "振幅": 0.87,
        "换手率": 9.53,
        "symbol": "105.MBINO"
    },
    {
        "序号": 5477,
        "name": "CCC Intelligent Solutions Holdi",
        "最新价": 11.53,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "开盘价": 11.47,
        "最高价": 11.6,
        "最低价": 11.47,
        "昨收价": 11.52,
        "总市值": 6945436534.0,
        "市盈率": -59.56,
        "成交量": 1528041.0,
        "成交额": 17620887.0,
        "振幅": 1.13,
        "换手率": 0.25,
        "symbol": "105.CCCS"
    },
    {
        "序号": 5478,
        "name": "FT Cboe Vest Nasdaq-100 Buffer ",
        "最新价": 23.41,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 23.43,
        "最高价": 23.45,
        "最低价": 23.38,
        "昨收价": 23.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 17669.0,
        "成交额": 413952.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.QDEC"
    },
    {
        "序号": 5479,
        "name": "VanEck Green Infrastructure ETF",
        "最新价": 23.43,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 23.43,
        "最高价": 23.43,
        "最低价": 23.43,
        "昨收价": 23.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 470.0,
        "成交额": 11010.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RNEW"
    },
    {
        "序号": 5480,
        "name": "CBL & Associates Properties Inc",
        "最新价": 23.43,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 23.35,
        "最高价": 23.7,
        "最低价": 23.14,
        "昨收价": 23.41,
        "总市值": 749788983.0,
        "市盈率": -319.74,
        "成交量": 71138.0,
        "成交额": 1664556.0,
        "振幅": 2.39,
        "换手率": 0.22,
        "symbol": "106.CBL"
    },
    {
        "序号": 5481,
        "name": "Horizon Kinetics Blockchain Dev",
        "最新价": 23.43,
        "涨跌额": 0.02,
        "涨跌幅": 0.09,
        "开盘价": 23.4,
        "最高价": 23.47,
        "最低价": 23.36,
        "昨收价": 23.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 890.0,
        "成交额": 20835.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.BCDF"
    },
    {
        "序号": 5482,
        "name": "奥玛特科技",
        "最新价": 70.68,
        "涨跌额": 0.06,
        "涨跌幅": 0.08,
        "开盘价": 70.47,
        "最高价": 70.95,
        "最低价": 70.12,
        "昨收价": 70.62,
        "总市值": 4265826516.0,
        "市盈率": 39.97,
        "成交量": 199121.0,
        "成交额": 14060380.0,
        "振幅": 1.18,
        "换手率": 0.33,
        "symbol": "106.ORA"
    },
    {
        "序号": 5483,
        "name": "Valley National Bancorp Series ",
        "最新价": 23.6,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 23.6,
        "最高价": 23.74,
        "最低价": 23.1,
        "昨收价": 23.58,
        "总市值": 94400000.0,
        "市盈率": null,
        "成交量": 14505.0,
        "成交额": 341505.0,
        "振幅": 2.71,
        "换手率": 0.36,
        "symbol": "105.VLYPO"
    },
    {
        "序号": 5484,
        "name": "Atlas Corp Notes",
        "最新价": 23.71,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 23.75,
        "最高价": 23.86,
        "最低价": 23.71,
        "昨收价": 23.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 695.0,
        "成交额": 16541.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.ATCOL"
    },
    {
        "序号": 5485,
        "name": "iShares India 50 ETF",
        "最新价": 47.86,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "开盘价": 47.82,
        "最高价": 47.95,
        "最低价": 47.73,
        "昨收价": 47.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 70548.0,
        "成交额": 3375667.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "105.INDY"
    },
    {
        "序号": 5486,
        "name": "CrossFirst Bankshares Inc",
        "最新价": 12.01,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 11.93,
        "最高价": 12.1,
        "最低价": 11.36,
        "昨收价": 12.0,
        "总市值": 592056093.0,
        "市盈率": 9.71,
        "成交量": 121802.0,
        "成交额": 1459969.0,
        "振幅": 6.17,
        "换手率": 0.25,
        "symbol": "105.CFB"
    },
    {
        "序号": 5487,
        "name": "Inspire Tactical Balanced ETF",
        "最新价": 24.09,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.07,
        "最高价": 24.09,
        "最低价": 24.07,
        "昨收价": 24.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1092.0,
        "成交额": 26291.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.RISN"
    },
    {
        "序号": 5488,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 24.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.11,
        "最高价": 24.25,
        "最低价": 24.0,
        "昨收价": 24.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 18398.0,
        "成交额": 442726.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "105.RILYO"
    },
    {
        "序号": 5489,
        "name": "Innovator Premium Income 40 Bar",
        "最新价": 24.23,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.27,
        "最高价": 24.28,
        "最低价": 24.23,
        "昨收价": 24.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 3129.0,
        "成交额": 75920.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.OCTQ"
    },
    {
        "序号": 5490,
        "name": "全球工业ETF-iShares",
        "最新价": 121.65,
        "涨跌额": 0.1,
        "涨跌幅": 0.08,
        "开盘价": 121.07,
        "最高价": 121.79,
        "最低价": 121.07,
        "昨收价": 121.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 8675.0,
        "成交额": 1054024.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.EXI"
    },
    {
        "序号": 5491,
        "name": "Western Asset Global Corporate ",
        "最新价": 12.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.09,
        "最高价": 12.18,
        "最低价": 12.08,
        "昨收价": 12.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 52269.0,
        "成交额": 634708.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.GDO"
    },
    {
        "序号": 5492,
        "name": "iShares ESG MSCI USA Min Vol Fa",
        "最新价": 24.47,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.39,
        "最高价": 24.47,
        "最低价": 24.39,
        "昨收价": 24.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 1336.0,
        "成交额": 32585.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.ESMV"
    },
    {
        "序号": 5493,
        "name": "KKR Income Opportunities Fund",
        "最新价": 12.29,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.28,
        "最高价": 12.32,
        "最低价": 12.17,
        "昨收价": 12.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 150065.0,
        "成交额": 1837737.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "106.KIO"
    },
    {
        "序号": 5494,
        "name": "JPMorgan International Value ET",
        "最新价": 49.16,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "开盘价": 49.17,
        "最高价": 49.17,
        "最低价": 49.16,
        "昨收价": 49.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 108.0,
        "成交额": 5310.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "105.JIVE"
    },
    {
        "序号": 5495,
        "name": "SoFi Smart Energy ETF",
        "最新价": 12.34,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.29,
        "最高价": 12.39,
        "最低价": 12.29,
        "昨收价": 12.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 724.0,
        "成交额": 8932.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.ENRG"
    },
    {
        "序号": 5496,
        "name": "Athene Holding Ltd Series C Pfd",
        "最新价": 24.72,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.72,
        "最高价": 24.78,
        "最低价": 24.66,
        "昨收价": 24.7,
        "总市值": 593280.0,
        "市盈率": null,
        "成交量": 28444.0,
        "成交额": 702736.0,
        "振幅": 0.49,
        "换手率": 118.52,
        "symbol": "106.ATH_C"
    },
    {
        "序号": 5497,
        "name": "ALPS Active REIT ETF",
        "最新价": 24.77,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.62,
        "最高价": 24.77,
        "最低价": 24.56,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 5064.0,
        "成交额": 124928.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "105.REIT"
    },
    {
        "序号": 5498,
        "name": "Innovator ETFs Trust Innovator ",
        "最新价": 24.84,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.85,
        "最高价": 24.85,
        "最低价": 24.84,
        "昨收价": 24.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 591.0,
        "成交额": 14685.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.APRQ"
    },
    {
        "序号": 5499,
        "name": "PIMCO Corporate & Income Strate",
        "最新价": 12.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.37,
        "最高价": 12.47,
        "最低价": 12.27,
        "昨收价": 12.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 190331.0,
        "成交额": 2354908.0,
        "振幅": 1.61,
        "换手率": null,
        "symbol": "106.PCN"
    },
    {
        "序号": 5500,
        "name": "BondBloxx CCC-Rated USD High Yi",
        "最新价": 37.42,
        "涨跌额": 0.03,
        "涨跌幅": 0.08,
        "开盘价": 37.39,
        "最高价": 37.46,
        "最低价": 37.36,
        "昨收价": 37.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 12883.0,
        "成交额": 481895.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.XCCC"
    },
    {
        "序号": 5501,
        "name": "Invesco Variable Rate Investmen",
        "最新价": 25.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 24.97,
        "昨收价": 24.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 239821.0,
        "成交额": 5990455.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.VRIG"
    },
    {
        "序号": 5502,
        "name": "Gladstone Capital Corp Notes",
        "最新价": 25.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 24.95,
        "昨收价": 24.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 2884.0,
        "成交额": 72016.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.GLADZ"
    },
    {
        "序号": 5503,
        "name": "格林县万通金控",
        "最新价": 25.01,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 24.9,
        "最高价": 25.2,
        "最低价": 24.68,
        "昨收价": 24.99,
        "总市值": 425840968.0,
        "市盈率": 15.09,
        "成交量": 15147.0,
        "成交额": 378909.0,
        "振幅": 2.08,
        "换手率": 0.09,
        "symbol": "105.GCBC"
    },
    {
        "序号": 5504,
        "name": "Innovator ETFs Trust Innovator ",
        "最新价": 25.09,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.05,
        "最高价": 25.12,
        "最低价": 25.05,
        "昨收价": 25.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1609.0,
        "成交额": 40348.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.APRH"
    },
    {
        "序号": 5505,
        "name": "KKR Real Estate Finance Trust I",
        "最新价": 12.58,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.45,
        "最高价": 12.63,
        "最低价": 12.24,
        "昨收价": 12.57,
        "总市值": 871968359.0,
        "市盈率": 368.85,
        "成交量": 441760.0,
        "成交额": 5549968.0,
        "振幅": 3.1,
        "换手率": 0.64,
        "symbol": "106.KREF"
    },
    {
        "序号": 5506,
        "name": "康耐视",
        "最新价": 38.07,
        "涨跌额": 0.03,
        "涨跌幅": 0.08,
        "开盘价": 37.84,
        "最高价": 38.71,
        "最低价": 37.8,
        "昨收价": 38.04,
        "总市值": 6553449442.0,
        "市盈率": 41.66,
        "成交量": 728123.0,
        "成交额": 27830043.0,
        "振幅": 2.39,
        "换手率": 0.42,
        "symbol": "105.CGNX"
    },
    {
        "序号": 5507,
        "name": "SEI Enhanced Low Volatility U.S",
        "最新价": 25.46,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.36,
        "最高价": 25.48,
        "最低价": 25.36,
        "昨收价": 25.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 134703.0,
        "成交额": 3431949.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.SELV"
    },
    {
        "序号": 5508,
        "name": "Leatherback Long\/Short Alternat",
        "最新价": 25.5,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.53,
        "最高价": 25.55,
        "最低价": 25.43,
        "昨收价": 25.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 6560.0,
        "成交额": 167002.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.LBAY"
    },
    {
        "序号": 5509,
        "name": "KraneShares Asia Pacific High I",
        "最新价": 25.52,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.5,
        "最高价": 25.55,
        "最低价": 25.5,
        "昨收价": 25.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 1666.0,
        "成交额": 42506.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.KHYB"
    },
    {
        "序号": 5510,
        "name": "iShares U.S. Infrastructure ETF",
        "最新价": 38.43,
        "涨跌额": 0.03,
        "涨跌幅": 0.08,
        "开盘价": 38.31,
        "最高价": 38.65,
        "最低价": 38.29,
        "昨收价": 38.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 236728.0,
        "成交额": 9088354.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "107.IFRA"
    },
    {
        "序号": 5511,
        "name": "EnLink Midstream LLC",
        "最新价": 12.82,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.9,
        "最高价": 13.02,
        "最低价": 12.75,
        "昨收价": 12.81,
        "总市值": 5856835256.0,
        "市盈率": 19.39,
        "成交量": 2981131.0,
        "成交额": 38322146.0,
        "振幅": 2.11,
        "换手率": 0.65,
        "symbol": "106.ENLC"
    },
    {
        "序号": 5512,
        "name": "Invesco AAA CLO Floating Rate N",
        "最新价": 25.65,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.64,
        "最高价": 25.67,
        "最低价": 25.63,
        "昨收价": 25.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 41905.0,
        "成交额": 1074812.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.ICLO"
    },
    {
        "序号": 5513,
        "name": "JPMorgan Healthcare Leaders ETF",
        "最新价": 51.38,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "开盘价": 51.5,
        "最高价": 51.5,
        "最低价": 51.38,
        "昨收价": 51.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 107.0,
        "成交额": 5510.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "105.JDOC"
    },
    {
        "序号": 5514,
        "name": "iShares Edge MSCI Intl Size Fac",
        "最新价": 25.9,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.9,
        "最高价": 25.9,
        "最低价": 25.9,
        "昨收价": 25.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 51.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ISZE"
    },
    {
        "序号": 5515,
        "name": "Brookstone Yield ETF",
        "最新价": 25.94,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 25.92,
        "最高价": 25.95,
        "最低价": 25.9,
        "昨收价": 25.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 3334.0,
        "成交额": 86478.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.BAMY"
    },
    {
        "序号": 5516,
        "name": "哈德森科技",
        "最新价": 12.98,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 12.95,
        "最高价": 13.2,
        "最低价": 12.88,
        "昨收价": 12.97,
        "总市值": 590620892.0,
        "市盈率": 11.06,
        "成交量": 216394.0,
        "成交额": 2814935.0,
        "振幅": 2.47,
        "换手率": 0.48,
        "symbol": "105.HDSN"
    },
    {
        "序号": 5517,
        "name": "Avantis Responsible Internation",
        "最新价": 51.96,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "开盘价": 51.96,
        "最高价": 51.98,
        "最低价": 51.75,
        "昨收价": 51.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 7551.0,
        "成交额": 391947.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.AVSD"
    },
    {
        "序号": 5518,
        "name": "Meridian Corp",
        "最新价": 13.08,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 13.15,
        "最高价": 13.15,
        "最低价": 13.0,
        "昨收价": 13.07,
        "总市值": 146204983.0,
        "市盈率": 8.48,
        "成交量": 18512.0,
        "成交额": 242633.0,
        "振幅": 1.15,
        "换手率": 0.17,
        "symbol": "105.MRBK"
    },
    {
        "序号": 5519,
        "name": "MKAM ETF",
        "最新价": 26.16,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 26.13,
        "最高价": 26.16,
        "最低价": 26.13,
        "昨收价": 26.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 9500.0,
        "成交额": 248235.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "105.MKAM"
    },
    {
        "序号": 5520,
        "name": "Star Holdings",
        "最新价": 13.11,
        "涨跌额": 0.01,
        "涨跌幅": 0.08,
        "开盘价": 13.05,
        "最高价": 13.15,
        "最低价": 12.89,
        "昨收价": 13.1,
        "总市值": 174619327.0,
        "市盈率": null,
        "成交量": 33827.0,
        "成交额": 442064.0,
        "振幅": 1.98,
        "换手率": 0.25,
        "symbol": "105.STHO"
    },
    {
        "序号": 5521,
        "name": "欧特克",
        "最新价": 224.01,
        "涨跌额": 0.17,
        "涨跌幅": 0.08,
        "开盘价": 222.07,
        "最高价": 225.31,
        "最低价": 221.9,
        "昨收价": 223.84,
        "总市值": 47919171953.0,
        "市盈率": 52.26,
        "成交量": 1073730.0,
        "成交额": 240398731.0,
        "振幅": 1.52,
        "换手率": 0.5,
        "symbol": "105.ADSK"
    },
    {
        "序号": 5522,
        "name": "SPDR S&P Global Infrastructure ",
        "最新价": 52.75,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "开盘价": 52.63,
        "最高价": 52.81,
        "最低价": 52.58,
        "昨收价": 52.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 10784.0,
        "成交额": 568385.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.GII"
    },
    {
        "序号": 5523,
        "name": "Innovator International Develop",
        "最新价": 26.41,
        "涨跌额": 0.02,
        "涨跌幅": 0.08,
        "开盘价": 26.44,
        "最高价": 26.44,
        "最低价": 26.36,
        "昨收价": 26.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 11296.0,
        "成交额": 297979.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.IJUL"
    },
    {
        "序号": 5524,
        "name": "全球上游自然资源ETF-FlexShares",
        "最新价": 39.63,
        "涨跌额": 0.03,
        "涨跌幅": 0.08,
        "开盘价": 39.45,
        "最高价": 39.79,
        "最低价": 39.43,
        "昨收价": 39.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1105636.0,
        "成交额": 43788732.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.GUNR"
    },
    {
        "序号": 5525,
        "name": "Cambria Global Momentum ETF",
        "最新价": 26.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 26.79,
        "最高价": 26.9,
        "最低价": 26.57,
        "昨收价": 26.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 12135.0,
        "成交额": 324223.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.GMOM"
    },
    {
        "序号": 5526,
        "name": "Main BuyWrite ETF",
        "最新价": 13.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 13.41,
        "最高价": 13.43,
        "最低价": 13.4,
        "昨收价": 13.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 40137.0,
        "成交额": 538390.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.BUYW"
    },
    {
        "序号": 5527,
        "name": "Honeytree U.S. Equity ETF",
        "最新价": 26.97,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 26.9,
        "最高价": 27.05,
        "最低价": 26.9,
        "昨收价": 26.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 477.0,
        "成交额": 12879.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.BEEZ"
    },
    {
        "序号": 5528,
        "name": "SPDR Nuveen Municipal Bond ETF",
        "最新价": 27.09,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 27.05,
        "最高价": 27.12,
        "最低价": 26.97,
        "昨收价": 27.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 9741.0,
        "成交额": 262852.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.MBND"
    },
    {
        "序号": 5529,
        "name": "First Trust High Yield Opportun",
        "最新价": 13.65,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 13.54,
        "最高价": 13.67,
        "最低价": 13.54,
        "昨收价": 13.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 83098.0,
        "成交额": 1133234.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.FTHY"
    },
    {
        "序号": 5530,
        "name": "阿伯房地产信托",
        "最新价": 13.65,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 13.54,
        "最高价": 13.77,
        "最低价": 13.47,
        "昨收价": 13.64,
        "总市值": 2573047413.0,
        "市盈率": 6.99,
        "成交量": 2897375.0,
        "成交额": 39482855.0,
        "振幅": 2.2,
        "换手率": 1.54,
        "symbol": "106.ABR"
    },
    {
        "序号": 5531,
        "name": "Tema Monopolies and Oligopolies",
        "最新价": 27.35,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 27.35,
        "最高价": 27.35,
        "最低价": 27.35,
        "昨收价": 27.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 43.0,
        "成交额": 1176.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TOLL"
    },
    {
        "序号": 5532,
        "name": "Stratus Properties Inc",
        "最新价": 27.35,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 26.8,
        "最高价": 27.7,
        "最低价": 26.8,
        "昨收价": 27.33,
        "总市值": 218888094.0,
        "市盈率": -10.92,
        "成交量": 7728.0,
        "成交额": 211420.0,
        "振幅": 3.29,
        "换手率": 0.1,
        "symbol": "105.STRS"
    },
    {
        "序号": 5533,
        "name": "Western Asset Diversified Incom",
        "最新价": 13.74,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 13.69,
        "最高价": 13.76,
        "最低价": 13.63,
        "昨收价": 13.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 180682.0,
        "成交额": 2471366.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.WDI"
    },
    {
        "序号": 5534,
        "name": "FlexShares US Quality Low Volat",
        "最新价": 55.19,
        "涨跌额": 0.04,
        "涨跌幅": 0.07,
        "开盘价": 55.11,
        "最高价": 55.2,
        "最低价": 55.04,
        "昨收价": 55.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 27259.0,
        "成交额": 1502529.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.QLV"
    },
    {
        "序号": 5535,
        "name": "可转债ETF-SPDR",
        "最新价": 69.41,
        "涨跌额": 0.05,
        "涨跌幅": 0.07,
        "开盘价": 69.21,
        "最高价": 69.5,
        "最低价": 69.21,
        "昨收价": 69.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 188095.0,
        "成交额": 13053519.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.CWB"
    },
    {
        "序号": 5536,
        "name": "Nuveen Dow 30SM Dynamic Overwri",
        "最新价": 13.96,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 13.96,
        "最高价": 13.99,
        "最低价": 13.94,
        "昨收价": 13.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 94954.0,
        "成交额": 1325386.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "106.DIAX"
    },
    {
        "序号": 5537,
        "name": "iShares Environmental Infrastru",
        "最新价": 28.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 28.1,
        "最高价": 28.1,
        "最低价": 28.1,
        "昨收价": 28.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 30.0,
        "成交额": 843.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EFRA"
    },
    {
        "序号": 5538,
        "name": "生化基因",
        "最新价": 239.29,
        "涨跌额": 0.17,
        "涨跌幅": 0.07,
        "开盘价": 237.65,
        "最高价": 240.81,
        "最低价": 236.8,
        "昨收价": 239.12,
        "总市值": 34672669220.0,
        "市盈率": 23.72,
        "成交量": 987954.0,
        "成交额": 236399945.0,
        "振幅": 1.68,
        "换手率": 0.68,
        "symbol": "105.BIIB"
    },
    {
        "序号": 5539,
        "name": "VictoryShares US EQ Income Enha",
        "最新价": 56.42,
        "涨跌额": 0.04,
        "涨跌幅": 0.07,
        "开盘价": 56.38,
        "最高价": 56.46,
        "最低价": 56.37,
        "昨收价": 56.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 86062.0,
        "成交额": 4854129.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.CDC"
    },
    {
        "序号": 5540,
        "name": "JPMorgan Social Advancement ETF",
        "最新价": 56.61,
        "涨跌额": 0.04,
        "涨跌幅": 0.07,
        "开盘价": 56.61,
        "最高价": 56.61,
        "最低价": 56.61,
        "昨收价": 56.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 56.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.UPWD"
    },
    {
        "序号": 5541,
        "name": "EIDP Inc Pfd",
        "最新价": 71.02,
        "涨跌额": 0.05,
        "涨跌幅": 0.07,
        "开盘价": 71.02,
        "最高价": 71.02,
        "最低价": 71.02,
        "昨收价": 70.97,
        "总市值": 118816460.0,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 35510.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "106.CTA_B"
    },
    {
        "序号": 5542,
        "name": "South Plains Financial Inc",
        "最新价": 28.46,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 28.6,
        "最高价": 28.65,
        "最低价": 28.19,
        "昨收价": 28.44,
        "总市值": 469085660.0,
        "市盈率": 7.21,
        "成交量": 12437.0,
        "成交额": 354222.0,
        "振幅": 1.62,
        "换手率": 0.08,
        "symbol": "105.SPFI"
    },
    {
        "序号": 5543,
        "name": "SRH REIT Covered Call ETF",
        "最新价": 57.01,
        "涨跌额": 0.04,
        "涨跌幅": 0.07,
        "开盘价": 57.01,
        "最高价": 57.01,
        "最低价": 57.01,
        "昨收价": 56.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 114.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SRHR"
    },
    {
        "序号": 5544,
        "name": "天睿",
        "最新价": 43.43,
        "涨跌额": 0.03,
        "涨跌幅": 0.07,
        "开盘价": 43.29,
        "最高价": 44.0,
        "最低价": 42.53,
        "昨收价": 43.4,
        "总市值": 4247454000.0,
        "市盈率": 68.51,
        "成交量": 1425817.0,
        "成交额": 61631706.0,
        "振幅": 3.39,
        "换手率": 1.46,
        "symbol": "106.TDC"
    },
    {
        "序号": 5545,
        "name": "Old Republic International Corp",
        "最新价": 28.99,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 29.0,
        "最高价": 29.06,
        "最低价": 28.77,
        "昨收价": 28.97,
        "总市值": 8128149610.0,
        "市盈率": 8.83,
        "成交量": 695072.0,
        "成交额": 20105865.0,
        "振幅": 1.0,
        "换手率": 0.25,
        "symbol": "106.ORI"
    },
    {
        "序号": 5546,
        "name": "WisdomTree International MidCap",
        "最新价": 58.76,
        "涨跌额": 0.04,
        "涨跌幅": 0.07,
        "开盘价": 58.44,
        "最高价": 58.78,
        "最低价": 58.44,
        "昨收价": 58.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 5423.0,
        "成交额": 318051.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.DIM"
    },
    {
        "序号": 5547,
        "name": "SEI Enhanced U.S. Large Cap Qua",
        "最新价": 29.49,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 29.32,
        "最高价": 29.49,
        "最低价": 29.32,
        "昨收价": 29.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 284.0,
        "成交额": 8326.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.SEIQ"
    },
    {
        "序号": 5548,
        "name": "Quadratic Deflation ETF",
        "最新价": 14.83,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 14.79,
        "最高价": 14.83,
        "最低价": 14.75,
        "昨收价": 14.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 64457.0,
        "成交额": 954273.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.BNDD"
    },
    {
        "序号": 5549,
        "name": "Nuveen S&P 500 Dynamic Overwrit",
        "最新价": 14.84,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 14.85,
        "最高价": 14.89,
        "最低价": 14.81,
        "昨收价": 14.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 45717.0,
        "成交额": 679277.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "106.SPXX"
    },
    {
        "序号": 5550,
        "name": "Vornado Realty Trust Series N P",
        "最新价": 14.99,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 14.94,
        "最高价": 15.07,
        "最低价": 14.67,
        "昨收价": 14.98,
        "总市值": 179880000.0,
        "市盈率": null,
        "成交量": 38933.0,
        "成交额": 579423.0,
        "振幅": 2.67,
        "换手率": 0.32,
        "symbol": "106.VNO_N"
    },
    {
        "序号": 5551,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 30.23,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 30.14,
        "最高价": 30.28,
        "最低价": 30.14,
        "昨收价": 30.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 3506.0,
        "成交额": 105968.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.XBAP"
    },
    {
        "序号": 5552,
        "name": "First Trust Multi-Asset Diversi",
        "最新价": 15.24,
        "涨跌额": 0.01,
        "涨跌幅": 0.07,
        "开盘价": 15.24,
        "最高价": 15.28,
        "最低价": 15.2,
        "昨收价": 15.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 46400.0,
        "成交额": 707386.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.MDIV"
    },
    {
        "序号": 5553,
        "name": "FT Cboe Vest U.S. Equity Buffer",
        "最新价": 30.49,
        "涨跌额": 0.02,
        "涨跌幅": 0.07,
        "开盘价": 30.51,
        "最高价": 30.58,
        "最低价": 30.44,
        "昨收价": 30.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 8379.0,
        "成交额": 255639.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.XISE"
    },
    {
        "序号": 5554,
        "name": "First Trust Senior Loan Fund ET",
        "最新价": 45.94,
        "涨跌额": 0.03,
        "涨跌幅": 0.07,
        "开盘价": 45.95,
        "最高价": 45.96,
        "最低价": 45.87,
        "昨收价": 45.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 377450.0,
        "成交额": 17336821.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.FTSL"
    },
    {
        "序号": 5555,
        "name": "Fidelity MSCI Health Care Index",
        "最新价": 62.11,
        "涨跌额": 0.04,
        "涨跌幅": 0.06,
        "开盘价": 62.01,
        "最高价": 62.24,
        "最低价": 61.76,
        "昨收价": 62.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 112568.0,
        "成交额": 6984556.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.FHLC"
    },
    {
        "序号": 5556,
        "name": "iShares Interest Rate Hedged Co",
        "最新价": 93.32,
        "涨跌额": 0.06,
        "涨跌幅": 0.06,
        "开盘价": 93.19,
        "最高价": 93.43,
        "最低价": 93.19,
        "昨收价": 93.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 8837.0,
        "成交额": 825028.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.LQDH"
    },
    {
        "序号": 5557,
        "name": "Global X S&P 500 Quality Divide",
        "最新价": 31.23,
        "涨跌额": 0.02,
        "涨跌幅": 0.06,
        "开盘价": 31.26,
        "最高价": 31.32,
        "最低价": 31.1,
        "昨收价": 31.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 3500.0,
        "成交额": 108977.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.QDIV"
    },
    {
        "序号": 5558,
        "name": "Pacer Pacific Asset Floating Ra",
        "最新价": 46.93,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 47.0,
        "最高价": 47.0,
        "最低价": 46.91,
        "昨收价": 46.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 21490.0,
        "成交额": 1008641.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.FLRT"
    },
    {
        "序号": 5559,
        "name": "Janus Henderson B-BBB CLO ETF",
        "最新价": 47.54,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 47.52,
        "最高价": 47.54,
        "最低价": 47.49,
        "昨收价": 47.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 27278.0,
        "成交额": 1296224.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.JBBB"
    },
    {
        "序号": 5560,
        "name": "银休特",
        "最新价": 194.66,
        "涨跌额": 0.12,
        "涨跌幅": 0.06,
        "开盘价": 194.54,
        "最高价": 198.15,
        "最低价": 192.88,
        "昨收价": 194.54,
        "总市值": 13592644315.0,
        "市盈率": 113.27,
        "成交量": 886968.0,
        "成交额": 173613927.0,
        "振幅": 2.71,
        "换手率": 1.27,
        "symbol": "105.PODD"
    },
    {
        "序号": 5561,
        "name": "UFP Industries Inc",
        "最新价": 114.13,
        "涨跌额": 0.07,
        "涨跌幅": 0.06,
        "开盘价": 113.97,
        "最高价": 115.97,
        "最低价": 113.48,
        "昨收价": 114.06,
        "总市值": 7054664962.0,
        "市盈率": 12.98,
        "成交量": 168999.0,
        "成交额": 19325096.0,
        "振幅": 2.18,
        "换手率": 0.27,
        "symbol": "105.UFPI"
    },
    {
        "序号": 5562,
        "name": "First Trust California Municipa",
        "最新价": 49.29,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 49.3,
        "最高价": 49.95,
        "最低价": 49.23,
        "昨收价": 49.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 41081.0,
        "成交额": 2033476.0,
        "振幅": 1.46,
        "换手率": null,
        "symbol": "105.FCAL"
    },
    {
        "序号": 5563,
        "name": "IQ ARB Global Resources ETF",
        "最新价": 32.87,
        "涨跌额": 0.02,
        "涨跌幅": 0.06,
        "开盘价": 33.03,
        "最高价": 33.03,
        "最低价": 32.82,
        "昨收价": 32.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 14758.0,
        "成交额": 485201.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.GRES"
    },
    {
        "序号": 5564,
        "name": "香港ETF-iShares MSCI",
        "最新价": 16.46,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 16.4,
        "最高价": 16.49,
        "最低价": 16.37,
        "昨收价": 16.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 1820342.0,
        "成交额": 29922749.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.EWH"
    },
    {
        "序号": 5565,
        "name": "FlexShares Morningstar Develope",
        "最新价": 65.88,
        "涨跌额": 0.04,
        "涨跌幅": 0.06,
        "开盘价": 65.64,
        "最高价": 65.93,
        "最低价": 65.63,
        "昨收价": 65.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 6246.0,
        "成交额": 411062.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.TLTD"
    },
    {
        "序号": 5566,
        "name": "Nomad Foods Ltd",
        "最新价": 16.51,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 16.53,
        "最高价": 16.78,
        "最低价": 16.51,
        "昨收价": 16.5,
        "总市值": 2754600384.0,
        "市盈率": 11.76,
        "成交量": 296432.0,
        "成交额": 4918762.0,
        "振幅": 1.64,
        "换手率": 0.18,
        "symbol": "106.NOMD"
    },
    {
        "序号": 5567,
        "name": "iShares U.S. Medical Devices ET",
        "最新价": 50.65,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 50.63,
        "最高价": 50.8,
        "最低价": 50.5,
        "昨收价": 50.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 1027799.0,
        "成交额": 52060320.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.IHI"
    },
    {
        "序号": 5568,
        "name": "Janus Henderson International S",
        "最新价": 16.97,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 16.97,
        "最高价": 16.97,
        "最低价": 16.97,
        "昨收价": 16.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 17.0,
        "成交额": 288.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SXUS"
    },
    {
        "序号": 5569,
        "name": "Mach Natural Resources LP",
        "最新价": 17.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.67,
        "最高价": 18.14,
        "最低价": 16.95,
        "昨收价": 16.99,
        "总市值": 1615000000.0,
        "市盈率": 3.64,
        "成交量": 63118.0,
        "成交额": 1102274.0,
        "振幅": 7.0,
        "换手率": 0.07,
        "symbol": "106.MNR"
    },
    {
        "序号": 5570,
        "name": "Brookfield Renewable Partners L",
        "最新价": 17.04,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.0,
        "最高价": 17.04,
        "最低价": 16.99,
        "昨收价": 17.03,
        "总市值": 136320000.0,
        "市盈率": null,
        "成交量": 520.0,
        "成交额": 8844.0,
        "振幅": 0.29,
        "换手率": 0.01,
        "symbol": "106.BEP_A"
    },
    {
        "序号": 5571,
        "name": "VanEck ETF Trust VanEck Short M",
        "最新价": 17.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.05,
        "最高价": 17.07,
        "最低价": 17.04,
        "昨收价": 17.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 110400.0,
        "成交额": 1882326.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.SMB"
    },
    {
        "序号": 5572,
        "name": "Invesco S&P Global Water Index ",
        "最新价": 51.49,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 51.35,
        "最高价": 51.7,
        "最低价": 51.29,
        "昨收价": 51.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 60224.0,
        "成交额": 3098954.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.CGW"
    },
    {
        "序号": 5573,
        "name": "Capital One Financial Corp Seri",
        "最新价": 17.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.25,
        "最高价": 17.49,
        "最低价": 17.25,
        "昨收价": 17.42,
        "总市值": 2178750.0,
        "市盈率": null,
        "成交量": 3820.0,
        "成交额": 66343.0,
        "振幅": 1.38,
        "换手率": 3.06,
        "symbol": "106.COF_K"
    },
    {
        "序号": 5574,
        "name": "BlackRock Floating Rate Loan ET",
        "最新价": 52.34,
        "涨跌额": 0.03,
        "涨跌幅": 0.06,
        "开盘价": 52.39,
        "最高价": 52.4,
        "最低价": 52.3,
        "昨收价": 52.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 1722.0,
        "成交额": 90177.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.BRLN"
    },
    {
        "序号": 5575,
        "name": "CapStar Financial Holdings Inc",
        "最新价": 17.74,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.73,
        "最高价": 17.95,
        "最低价": 17.67,
        "昨收价": 17.73,
        "总市值": 367430330.0,
        "市盈率": 10.97,
        "成交量": 226496.0,
        "成交额": 4042335.0,
        "振幅": 1.58,
        "换手率": 1.09,
        "symbol": "105.CSTR"
    },
    {
        "序号": 5576,
        "name": "百胜餐饮",
        "最新价": 124.34,
        "涨跌额": 0.07,
        "涨跌幅": 0.06,
        "开盘价": 124.97,
        "最高价": 125.25,
        "最低价": 124.17,
        "昨收价": 124.27,
        "总市值": 34853523950.0,
        "市盈率": 23.16,
        "成交量": 1192943.0,
        "成交额": 148463053.0,
        "振幅": 0.87,
        "换手率": 0.43,
        "symbol": "106.YUM"
    },
    {
        "序号": 5577,
        "name": "PIMCO Dynamic Income Fund",
        "最新价": 17.77,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.73,
        "最高价": 17.81,
        "最低价": 17.67,
        "昨收价": 17.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 1453815.0,
        "成交额": 25807326.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "106.PDI"
    },
    {
        "序号": 5578,
        "name": "First Trust United Kingdom Alph",
        "最新价": 35.75,
        "涨跌额": 0.02,
        "涨跌幅": 0.06,
        "开盘价": 35.63,
        "最高价": 35.75,
        "最低价": 35.63,
        "昨收价": 35.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 319.0,
        "成交额": 11364.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "105.FKU"
    },
    {
        "序号": 5579,
        "name": "ETFMG Treatments, Testing and A",
        "最新价": 17.95,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 17.95,
        "最高价": 17.95,
        "最低价": 17.95,
        "昨收价": 17.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 87.0,
        "成交额": 1561.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GERM"
    },
    {
        "序号": 5580,
        "name": "加元ETF-CurrencyShares",
        "最新价": 71.96,
        "涨跌额": 0.04,
        "涨跌幅": 0.06,
        "开盘价": 71.84,
        "最高价": 72.0,
        "最低价": 71.84,
        "昨收价": 71.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 38019.0,
        "成交额": 2734705.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.FXC"
    },
    {
        "序号": 5581,
        "name": "Eaton Vance Enhanced Equity Inc",
        "最新价": 18.13,
        "涨跌额": 0.01,
        "涨跌幅": 0.06,
        "开盘价": 18.05,
        "最高价": 18.16,
        "最低价": 17.98,
        "昨收价": 18.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 101291.0,
        "成交额": 1828134.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "106.EOS"
    },
    {
        "序号": 5582,
        "name": "基础金属ETF-PowerShares",
        "最新价": 18.21,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 18.01,
        "最高价": 18.35,
        "最低价": 18.01,
        "昨收价": 18.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 31251.0,
        "成交额": 569736.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "107.DBB"
    },
    {
        "序号": 5583,
        "name": "勒梅特微管医疗",
        "最新价": 54.65,
        "涨跌额": 0.03,
        "涨跌幅": 0.05,
        "开盘价": 54.49,
        "最高价": 54.77,
        "最低价": 53.9,
        "昨收价": 54.62,
        "总市值": 1216685793.0,
        "市盈率": 44.62,
        "成交量": 168403.0,
        "成交额": 9169587.0,
        "振幅": 1.59,
        "换手率": 0.76,
        "symbol": "105.LMAT"
    },
    {
        "序号": 5584,
        "name": "PennyMac Mortgage Investment Tr",
        "最新价": 18.26,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 18.34,
        "最高价": 18.34,
        "最低价": 18.0,
        "昨收价": 18.25,
        "总市值": 182600000.0,
        "市盈率": null,
        "成交量": 27935.0,
        "成交额": 508325.0,
        "振幅": 1.86,
        "换手率": 0.28,
        "symbol": "106.PMT_C"
    },
    {
        "序号": 5585,
        "name": "MAA房产信托(优先股)",
        "最新价": 55.25,
        "涨跌额": 0.03,
        "涨跌幅": 0.05,
        "开盘价": 55.24,
        "最高价": 55.37,
        "最低价": 55.24,
        "昨收价": 55.22,
        "总市值": 47948492.0,
        "市盈率": null,
        "成交量": 485.0,
        "成交额": 26814.0,
        "振幅": 0.24,
        "换手率": 0.06,
        "symbol": "106.MAA_I"
    },
    {
        "序号": 5586,
        "name": "国际纸业",
        "最新价": 36.84,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 36.7,
        "最高价": 37.0,
        "最低价": 36.57,
        "昨收价": 36.82,
        "总市值": 12747277811.0,
        "市盈率": 50.19,
        "成交量": 1818244.0,
        "成交额": 66924027.0,
        "振幅": 1.17,
        "换手率": 0.53,
        "symbol": "106.IP"
    },
    {
        "序号": 5587,
        "name": "FTI咨询",
        "最新价": 222.12,
        "涨跌额": 0.12,
        "涨跌幅": 0.05,
        "开盘价": 222.4,
        "最高价": 223.2,
        "最低价": 218.56,
        "昨收价": 222.0,
        "总市值": 7887419229.0,
        "市盈率": 32.76,
        "成交量": 163333.0,
        "成交额": 36170166.0,
        "振幅": 2.09,
        "换手率": 0.46,
        "symbol": "106.FCN"
    },
    {
        "序号": 5588,
        "name": "iShares Emergent Food and AgTec",
        "最新价": 18.52,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 18.52,
        "最高价": 18.52,
        "最低价": 18.52,
        "昨收价": 18.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 9.0,
        "成交额": 166.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IVEG"
    },
    {
        "序号": 5589,
        "name": "Invesco Global Water ETF",
        "最新价": 37.08,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 36.94,
        "最高价": 37.19,
        "最低价": 36.94,
        "昨收价": 37.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 3921.0,
        "成交额": 145225.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "105.PIO"
    },
    {
        "序号": 5590,
        "name": "AdvisorShares STAR Global Buy-W",
        "最新价": 38.09,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 38.08,
        "最高价": 38.13,
        "最低价": 37.98,
        "昨收价": 38.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1834.0,
        "成交额": 69896.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.VEGA"
    },
    {
        "序号": 5591,
        "name": "Hartford Municipal Opportunitie",
        "最新价": 38.41,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 38.46,
        "最高价": 38.46,
        "最低价": 38.27,
        "昨收价": 38.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 76226.0,
        "成交额": 2927046.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.HMOP"
    },
    {
        "序号": 5592,
        "name": "Teladoc Health Inc",
        "最新价": 19.21,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 19.0,
        "最高价": 19.63,
        "最低价": 18.78,
        "昨收价": 19.2,
        "总市值": 3180355829.0,
        "市盈率": -0.79,
        "成交量": 4203202.0,
        "成交额": 80900416.0,
        "振幅": 4.43,
        "换手率": 2.54,
        "symbol": "106.TDOC"
    },
    {
        "序号": 5593,
        "name": "福斯",
        "最新价": 38.52,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 38.5,
        "最高价": 39.18,
        "最低价": 38.24,
        "昨收价": 38.5,
        "总市值": 5054156466.0,
        "市盈率": 20.59,
        "成交量": 525424.0,
        "成交额": 20313637.0,
        "振幅": 2.44,
        "换手率": 0.4,
        "symbol": "106.FLS"
    },
    {
        "序号": 5594,
        "name": "Gladstone Land Corp Series B Pf",
        "最新价": 19.66,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 19.47,
        "最高价": 19.69,
        "最低价": 19.34,
        "昨收价": 19.65,
        "总市值": 117096238.0,
        "市盈率": null,
        "成交量": 5850.0,
        "成交额": 114065.0,
        "振幅": 1.78,
        "换手率": 0.1,
        "symbol": "105.LANDO"
    },
    {
        "序号": 5595,
        "name": "Eagle Point Credit Co Inc Serie",
        "最新价": 19.8,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 19.66,
        "最高价": 19.8,
        "最低价": 19.66,
        "昨收价": 19.79,
        "总市值": 21570853.0,
        "市盈率": null,
        "成交量": 1943.0,
        "成交额": 38326.0,
        "振幅": 0.71,
        "换手率": 0.18,
        "symbol": "106.ECC_D"
    },
    {
        "序号": 5596,
        "name": "Ellington Financial Inc Series ",
        "最新价": 19.9,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 19.9,
        "最高价": 19.9,
        "最低价": 19.77,
        "昨收价": 19.89,
        "总市值": 95926378.0,
        "市盈率": null,
        "成交量": 1509.0,
        "成交额": 29947.0,
        "振幅": 0.65,
        "换手率": 0.03,
        "symbol": "106.EFC_B"
    },
    {
        "序号": 5597,
        "name": "PIMCO Enhanced Short Maturity A",
        "最新价": 99.88,
        "涨跌额": 0.05,
        "涨跌幅": 0.05,
        "开盘价": 99.92,
        "最高价": 99.92,
        "最低价": 99.88,
        "昨收价": 99.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 1555081.0,
        "成交额": 155340472.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.MINT"
    },
    {
        "序号": 5598,
        "name": "FitLife Brands Inc",
        "最新价": 20.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 19.83,
        "最高价": 20.16,
        "最低价": 19.57,
        "昨收价": 20.15,
        "总市值": 92527163.0,
        "市盈率": null,
        "成交量": 7139.0,
        "成交额": 142973.0,
        "振幅": 2.93,
        "换手率": 0.16,
        "symbol": "105.FTLF"
    },
    {
        "序号": 5599,
        "name": "Starwood Property Trust Inc",
        "最新价": 20.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.15,
        "最高价": 20.33,
        "最低价": 20.09,
        "昨收价": 20.16,
        "总市值": 6317769388.0,
        "市盈率": 15.47,
        "成交量": 1532222.0,
        "成交额": 30932333.0,
        "振幅": 1.19,
        "换手率": 0.49,
        "symbol": "106.STWD"
    },
    {
        "序号": 5600,
        "name": "美鹰傲飞",
        "最新价": 20.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.08,
        "最高价": 20.34,
        "最低价": 19.85,
        "昨收价": 20.16,
        "总市值": 3984348520.0,
        "市盈率": 18.25,
        "成交量": 3801982.0,
        "成交额": 76312620.0,
        "振幅": 2.43,
        "换手率": 1.92,
        "symbol": "106.AEO"
    },
    {
        "序号": 5601,
        "name": "Strive Enhanced Income Short Ma",
        "最新价": 20.18,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.14,
        "最高价": 20.19,
        "最低价": 20.14,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 11560.0,
        "成交额": 233178.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "106.BUXX"
    },
    {
        "序号": 5602,
        "name": "Invesco High Yield Equity Divid",
        "最新价": 20.23,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.22,
        "最高价": 20.31,
        "最低价": 20.14,
        "昨收价": 20.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 178443.0,
        "成交额": 3607989.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "105.PEY"
    },
    {
        "序号": 5603,
        "name": "General American Investors Co I",
        "最新价": 41.32,
        "涨跌额": 0.02,
        "涨跌幅": 0.05,
        "开盘价": 41.21,
        "最高价": 41.47,
        "最低价": 41.11,
        "昨收价": 41.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 28815.0,
        "成交额": 1191273.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "106.GAM"
    },
    {
        "序号": 5604,
        "name": "Defiance Hotel, Airline, and Cr",
        "最新价": 20.73,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.72,
        "最高价": 20.79,
        "最低价": 20.63,
        "昨收价": 20.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 48216.0,
        "成交额": 997451.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.CRUZ"
    },
    {
        "序号": 5605,
        "name": "Two Harbors Investment Corp Ser",
        "最新价": 20.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.72,
        "最高价": 20.97,
        "最低价": 20.72,
        "昨收价": 20.9,
        "总市值": 240465000.0,
        "市盈率": null,
        "成交量": 3313.0,
        "成交额": 69202.0,
        "振幅": 1.2,
        "换手率": 0.03,
        "symbol": "106.TWO_B"
    },
    {
        "序号": 5606,
        "name": "First Trust NASDAQ-100 Ex-Techn",
        "最新价": 83.68,
        "涨跌额": 0.04,
        "涨跌幅": 0.05,
        "开盘价": 83.4,
        "最高价": 83.99,
        "最低价": 83.29,
        "昨收价": 83.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 4671.0,
        "成交额": 391105.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "105.QQXT"
    },
    {
        "序号": 5607,
        "name": "高级银团贷款ETF-PowerShares",
        "最新价": 20.99,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.0,
        "最高价": 21.01,
        "最低价": 20.98,
        "昨收价": 20.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 5987670.0,
        "成交额": 125739462.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.BKLN"
    },
    {
        "序号": 5608,
        "name": "Aztlan Global Stock Selection D",
        "最新价": 21.02,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 20.95,
        "最高价": 21.02,
        "最低价": 20.95,
        "昨收价": 21.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 233.0,
        "成交额": 4881.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.AZTD"
    },
    {
        "序号": 5609,
        "name": "新兴市场美元公司债-WisdomTree",
        "最新价": 63.26,
        "涨跌额": 0.03,
        "涨跌幅": 0.05,
        "开盘价": 63.26,
        "最高价": 63.48,
        "最低价": 63.26,
        "昨收价": 63.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 825.0,
        "成交额": 52284.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.EMCB"
    },
    {
        "序号": 5610,
        "name": "Innovator Growth Accelerated Pl",
        "最新价": 21.12,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.08,
        "最高价": 21.12,
        "最低价": 21.08,
        "昨收价": 21.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 942.0,
        "成交额": 19857.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.QTJA"
    },
    {
        "序号": 5611,
        "name": "布朗斯威克",
        "最新价": 84.7,
        "涨跌额": 0.04,
        "涨跌幅": 0.05,
        "开盘价": 84.25,
        "最高价": 85.69,
        "最低价": 84.25,
        "昨收价": 84.66,
        "总市值": 5828431286.0,
        "市盈率": 11.63,
        "成交量": 529960.0,
        "成交额": 44989294.0,
        "振幅": 1.7,
        "换手率": 0.77,
        "symbol": "106.BC"
    },
    {
        "序号": 5612,
        "name": "Virtus LifeSci Biotech Clinical",
        "最新价": 21.24,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.25,
        "最高价": 21.55,
        "最低价": 21.22,
        "昨收价": 21.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 2778.0,
        "成交额": 59184.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "107.BBC"
    },
    {
        "序号": 5613,
        "name": "The 3D Printing ETF",
        "最新价": 21.41,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.35,
        "最高价": 21.57,
        "最低价": 21.25,
        "昨收价": 21.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 14514.0,
        "成交额": 310189.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "107.PRNT"
    },
    {
        "序号": 5614,
        "name": "Invesco S&P 500 BuyWrite ETF",
        "最新价": 21.48,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.45,
        "最高价": 21.5,
        "最低价": 21.45,
        "昨收价": 21.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 18619.0,
        "成交额": 399943.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.PBP"
    },
    {
        "序号": 5615,
        "name": "Neuberger Berman Next Generatio",
        "最新价": 21.53,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.53,
        "最高价": 21.53,
        "最低价": 21.53,
        "昨收价": 21.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 43.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NBCC"
    },
    {
        "序号": 5616,
        "name": "坦能",
        "最新价": 86.99,
        "涨跌额": 0.04,
        "涨跌幅": 0.05,
        "开盘价": 86.8,
        "最高价": 87.23,
        "最低价": 86.09,
        "昨收价": 86.95,
        "总市值": 1624992164.0,
        "市盈率": 15.88,
        "成交量": 168986.0,
        "成交额": 14690725.0,
        "振幅": 1.31,
        "换手率": 0.9,
        "symbol": "106.TNC"
    },
    {
        "序号": 5617,
        "name": "Service Corp International",
        "最新价": 65.26,
        "涨跌额": 0.03,
        "涨跌幅": 0.05,
        "开盘价": 65.31,
        "最高价": 66.06,
        "最低价": 64.77,
        "昨收价": 65.23,
        "总市值": 9641820297.0,
        "市盈率": 19.63,
        "成交量": 973200.0,
        "成交额": 63805285.0,
        "振幅": 1.98,
        "换手率": 0.66,
        "symbol": "106.SCI"
    },
    {
        "序号": 5618,
        "name": "伊克力西斯",
        "最新价": 22.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 21.97,
        "最高价": 22.15,
        "最低价": 21.81,
        "昨收价": 21.99,
        "总市值": 6841430486.0,
        "市盈率": 74.3,
        "成交量": 1426362.0,
        "成交额": 31432481.0,
        "振幅": 1.55,
        "换手率": 0.46,
        "symbol": "105.EXEL"
    },
    {
        "序号": 5619,
        "name": "Formidable ETF",
        "最新价": 22.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.05,
        "开盘价": 22.22,
        "最高价": 22.22,
        "最低价": 22.12,
        "昨收价": 22.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 12278.0,
        "成交额": 271690.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.FORH"
    },
    {
        "序号": 5620,
        "name": "FT Cboe Vest Fund of Deep Buffe",
        "最新价": 22.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 22.24,
        "最高价": 22.32,
        "最低价": 22.17,
        "昨收价": 22.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 79795.0,
        "成交额": 1776063.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.BUFD"
    },
    {
        "序号": 5621,
        "name": "EngageSmart Inc",
        "最新价": 22.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 22.84,
        "最高价": 22.89,
        "最低价": 22.84,
        "昨收价": 22.85,
        "总市值": 3842693785.0,
        "市盈率": 154.47,
        "成交量": 429763.0,
        "成交额": 9828789.0,
        "振幅": 0.22,
        "换手率": 0.26,
        "symbol": "106.ESMT"
    },
    {
        "序号": 5622,
        "name": "Kimco Realty Corp Class L Pfd",
        "最新价": 22.94,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 22.91,
        "最高价": 22.98,
        "最低价": 22.72,
        "昨收价": 22.93,
        "总市值": 204212.0,
        "市盈率": null,
        "成交量": 33459.0,
        "成交额": 766751.0,
        "振幅": 1.13,
        "换手率": 375.86,
        "symbol": "106.KIM_L"
    },
    {
        "序号": 5623,
        "name": "Eagle Point Income Co Inc Serie",
        "最新价": 22.96,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.04,
        "最高价": 23.04,
        "最低价": 22.87,
        "昨收价": 22.95,
        "总市值": 34937061.0,
        "市盈率": null,
        "成交量": 2559.0,
        "成交额": 58626.0,
        "振幅": 0.74,
        "换手率": 0.17,
        "symbol": "106.EICA"
    },
    {
        "序号": 5624,
        "name": "Oxford Lane Capital Corp Series",
        "最新价": 23.44,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.44,
        "最高价": 23.44,
        "最低价": 23.44,
        "昨收价": 23.43,
        "总市值": 56256000.0,
        "市盈率": null,
        "成交量": 155.0,
        "成交额": 3633.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.OXLCN"
    },
    {
        "序号": 5625,
        "name": "Invesco BulletShares 2023 High ",
        "最新价": 23.53,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.49,
        "最高价": 23.54,
        "最低价": 23.49,
        "昨收价": 23.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 265072.0,
        "成交额": 6230845.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "105.BSJN"
    },
    {
        "序号": 5626,
        "name": "Schwab International Dividend E",
        "最新价": 23.56,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.55,
        "最高价": 23.61,
        "最低价": 23.46,
        "昨收价": 23.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 142869.0,
        "成交额": 3362449.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.SCHY"
    },
    {
        "序号": 5627,
        "name": "易诺华国际",
        "最新价": 47.29,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 46.72,
        "最高价": 48.13,
        "最低价": 46.72,
        "昨收价": 47.27,
        "总市值": 1412493944.0,
        "市盈率": 7.39,
        "成交量": 326878.0,
        "成交额": 15521860.0,
        "振幅": 2.98,
        "换手率": 1.09,
        "symbol": "106.ENVA"
    },
    {
        "序号": 5628,
        "name": "Simplify Commodities Strategy N",
        "最新价": 23.69,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.72,
        "最高价": 23.72,
        "最低价": 23.64,
        "昨收价": 23.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 2333.0,
        "成交额": 55219.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.HARD"
    },
    {
        "序号": 5629,
        "name": "iShares iBonds Dec 2024 Term Tr",
        "最新价": 23.85,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.85,
        "最高价": 23.85,
        "最低价": 23.84,
        "昨收价": 23.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 770990.0,
        "成交额": 18384444.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.IBTE"
    },
    {
        "序号": 5630,
        "name": "Ford Motor Co Notes",
        "最新价": 23.85,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.8,
        "最高价": 23.93,
        "最低价": 23.68,
        "昨收价": 23.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 67864.0,
        "成交额": 1615814.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.F_D"
    },
    {
        "序号": 5631,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 24.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.01,
        "最高价": 24.07,
        "最低价": 24.01,
        "昨收价": 24.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 3597.0,
        "成交额": 86496.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.XTJA"
    },
    {
        "序号": 5632,
        "name": "First Trust EIP Carbon Impact E",
        "最新价": 24.06,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 23.95,
        "最高价": 24.06,
        "最低价": 23.93,
        "昨收价": 24.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1368.0,
        "成交额": 32811.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.ECLN"
    },
    {
        "序号": 5633,
        "name": "Innovator Premium Income 15 Buf",
        "最新价": 24.07,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.06,
        "最高价": 24.1,
        "最低价": 24.03,
        "昨收价": 24.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 14555.0,
        "成交额": 350402.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.LOCT"
    },
    {
        "序号": 5634,
        "name": "Franklin Senior Loan ETF",
        "最新价": 24.08,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.08,
        "最高价": 24.08,
        "最低价": 24.05,
        "昨收价": 24.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 73833.0,
        "成交额": 1777423.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.FLBL"
    },
    {
        "序号": 5635,
        "name": "Innovator Premium Income 9 Buff",
        "最新价": 24.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.13,
        "最高价": 24.18,
        "最低价": 24.13,
        "昨收价": 24.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2704.0,
        "成交额": 65295.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.HOCT"
    },
    {
        "序号": 5636,
        "name": "Bank of America Corp Series HH ",
        "最新价": 24.33,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.25,
        "最高价": 24.34,
        "最低价": 24.09,
        "昨收价": 24.32,
        "总市值": 831113.0,
        "市盈率": null,
        "成交量": 89862.0,
        "成交额": 2181076.0,
        "振幅": 1.03,
        "换手率": 263.06,
        "symbol": "106.BAC_K"
    },
    {
        "序号": 5637,
        "name": "Eagle Point Credit Co Inc Notes",
        "最新价": 24.4,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.41,
        "最高价": 24.41,
        "最低价": 24.2,
        "昨收价": 24.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1090.0,
        "成交额": 26547.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "106.ECCX"
    },
    {
        "序号": 5638,
        "name": "Teucrium AiLA Long-Short Base M",
        "最新价": 24.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.43,
        "最高价": 24.43,
        "最低价": 24.43,
        "昨收价": 24.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 84.0,
        "成交额": 2051.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.OAIB"
    },
    {
        "序号": 5639,
        "name": "Arlington Asset Investment Corp",
        "最新价": 24.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.47,
        "最高价": 24.5,
        "最低价": 24.47,
        "昨收价": 24.49,
        "总市值": 27367333.0,
        "市盈率": null,
        "成交量": 1316.0,
        "成交额": 32234.0,
        "振幅": 0.12,
        "换手率": 0.12,
        "symbol": "106.AAIC_C"
    },
    {
        "序号": 5640,
        "name": "FIS Biblically Responsible Risk",
        "最新价": 24.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.78,
        "最高价": 24.78,
        "最低价": 24.58,
        "昨收价": 24.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 7223.0,
        "成交额": 178213.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.PRAY"
    },
    {
        "序号": 5641,
        "name": "Cambria Foreign Shareholder Yie",
        "最新价": 24.76,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.61,
        "最高价": 24.84,
        "最低价": 24.61,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 33996.0,
        "成交额": 841575.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.FYLD"
    },
    {
        "序号": 5642,
        "name": "Customers Bancorp Inc Series F ",
        "最新价": 24.81,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.8,
        "最高价": 24.85,
        "最低价": 24.77,
        "昨收价": 24.8,
        "总市值": 84354000.0,
        "市盈率": null,
        "成交量": 4735.0,
        "成交额": 117432.0,
        "振幅": 0.32,
        "换手率": 0.14,
        "symbol": "106.CUBI_F"
    },
    {
        "序号": 5643,
        "name": "Invesco VRDO Tax-Free ETF",
        "最新价": 24.82,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.82,
        "最高价": 24.82,
        "最低价": 24.82,
        "昨收价": 24.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 119.0,
        "成交额": 2953.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PVI"
    },
    {
        "序号": 5644,
        "name": "iShares iBonds Dec 2024 Term Co",
        "最新价": 24.84,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.84,
        "最高价": 24.85,
        "最低价": 24.83,
        "昨收价": 24.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 418832.0,
        "成交额": 10405981.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.IBDP"
    },
    {
        "序号": 5645,
        "name": "Oxford Lane Capital Corp Series",
        "最新价": 24.89,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.89,
        "最高价": 24.89,
        "最低价": 24.82,
        "昨收价": 24.88,
        "总市值": 67825623.0,
        "市盈率": null,
        "成交量": 2182.0,
        "成交额": 54259.0,
        "振幅": 0.28,
        "换手率": 0.08,
        "symbol": "105.OXLCM"
    },
    {
        "序号": 5646,
        "name": "US Treasury 3 Month Bill ETF",
        "最新价": 49.92,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 49.91,
        "最高价": 49.92,
        "最低价": 49.91,
        "昨收价": 49.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 1066146.0,
        "成交额": 53220229.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "105.TBIL"
    },
    {
        "序号": 5647,
        "name": "Seapeak LLC Series B Pfd",
        "最新价": 25.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 24.92,
        "昨收价": 24.99,
        "总市值": 168179900.0,
        "市盈率": null,
        "成交量": 7626.0,
        "成交额": 190495.0,
        "振幅": 0.32,
        "换手率": 0.11,
        "symbol": "106.SEAL_B"
    },
    {
        "序号": 5648,
        "name": "Xtrackers Municipal Infrastruct",
        "最新价": 25.0,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.14,
        "最高价": 25.14,
        "最低价": 24.94,
        "昨收价": 24.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 18421.0,
        "成交额": 461118.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.RVNU"
    },
    {
        "序号": 5649,
        "name": "LHA Risk-Managed Income ETF",
        "最新价": 25.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 24.98,
        "最高价": 25.03,
        "最低价": 24.98,
        "昨收价": 25.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 3822.0,
        "成交额": 95493.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.RMIF"
    },
    {
        "序号": 5650,
        "name": "Touchstone Ultra Short Income E",
        "最新价": 25.11,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.11,
        "最高价": 25.12,
        "最低价": 25.1,
        "昨收价": 25.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 2598.0,
        "成交额": 65234.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.TUSI"
    },
    {
        "序号": 5651,
        "name": "Innovator Equity Defined Protec",
        "最新价": 25.24,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.19,
        "最高价": 25.26,
        "最低价": 25.16,
        "昨收价": 25.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 45844.0,
        "成交额": 1156421.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.TJUL"
    },
    {
        "序号": 5652,
        "name": "Innovator Premium Income 30 Bar",
        "最新价": 25.24,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.2,
        "最高价": 25.24,
        "最低价": 25.2,
        "昨收价": 25.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 362.0,
        "成交额": 9122.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.JULJ"
    },
    {
        "序号": 5653,
        "name": "Timothy Plan International ETF",
        "最新价": 25.32,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.24,
        "最高价": 25.37,
        "最低价": 25.24,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 5051.0,
        "成交额": 127761.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.TPIF"
    },
    {
        "序号": 5654,
        "name": "浮动利率债ETF-iShares",
        "最新价": 50.69,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 50.68,
        "最高价": 50.7,
        "最低价": 50.67,
        "昨收价": 50.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 641209.0,
        "成交额": 32503839.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.FLOT"
    },
    {
        "序号": 5655,
        "name": "iShares iBonds Dec 2026 Term Mu",
        "最新价": 25.37,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.39,
        "最高价": 25.4,
        "最低价": 25.36,
        "昨收价": 25.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 35158.0,
        "成交额": 892322.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IBMO"
    },
    {
        "序号": 5656,
        "name": "iShares Inflation Hedged High Y",
        "最新价": 25.48,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.39,
        "最高价": 25.48,
        "最低价": 25.31,
        "昨收价": 25.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 2403.0,
        "成交额": 60977.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.HYGI"
    },
    {
        "序号": 5657,
        "name": "iShares Inflation Hedged Corpor",
        "最新价": 25.62,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.57,
        "最高价": 25.62,
        "最低价": 25.53,
        "昨收价": 25.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 8994.0,
        "成交额": 230024.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.LQDI"
    },
    {
        "序号": 5658,
        "name": "Formidable Fortress ETF",
        "最新价": 25.7,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.71,
        "最高价": 25.75,
        "最低价": 25.7,
        "昨收价": 25.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 10357.0,
        "成交额": 266621.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.KONG"
    },
    {
        "序号": 5659,
        "name": "iShares iBonds Dec 2024 Term Mu",
        "最新价": 25.9,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.9,
        "最高价": 25.9,
        "最低价": 25.85,
        "昨收价": 25.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 93719.0,
        "成交额": 2426011.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.IBMM"
    },
    {
        "序号": 5660,
        "name": "NuStar Energy LP Series A Pfd",
        "最新价": 25.93,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 25.92,
        "最高价": 25.93,
        "最低价": 25.83,
        "昨收价": 25.92,
        "总市值": 234925800.0,
        "市盈率": null,
        "成交量": 4220.0,
        "成交额": 109307.0,
        "振幅": 0.39,
        "换手率": 0.05,
        "symbol": "106.NS_A"
    },
    {
        "序号": 5661,
        "name": "Avantis All International Marke",
        "最新价": 52.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 51.98,
        "最高价": 52.17,
        "最低价": 51.98,
        "昨收价": 52.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 3638.0,
        "成交额": 189196.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.AVNM"
    },
    {
        "序号": 5662,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.49,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 26.49,
        "最高价": 26.5,
        "最低价": 26.43,
        "昨收价": 26.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1653.0,
        "成交额": 43740.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.ITDC"
    },
    {
        "序号": 5663,
        "name": "Red River Bancshares Inc",
        "最新价": 53.01,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 53.19,
        "最高价": 53.56,
        "最低价": 52.86,
        "昨收价": 52.99,
        "总市值": 378054916.0,
        "市盈率": 10.28,
        "成交量": 6056.0,
        "成交额": 322448.0,
        "振幅": 1.32,
        "换手率": 0.08,
        "symbol": "105.RRBI"
    },
    {
        "序号": 5664,
        "name": "SPDR SSgA Multi Asset Real Retu",
        "最新价": 26.87,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 26.8,
        "最高价": 26.94,
        "最低价": 26.77,
        "昨收价": 26.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 134343.0,
        "成交额": 3608586.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.RLY"
    },
    {
        "序号": 5665,
        "name": "黑山",
        "最新价": 54.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 54.04,
        "最高价": 54.26,
        "最低价": 53.59,
        "昨收价": 54.08,
        "总市值": 3678347886.0,
        "市盈率": 14.43,
        "成交量": 267128.0,
        "成交额": 14423705.0,
        "振幅": 1.24,
        "换手率": 0.39,
        "symbol": "106.BKH"
    },
    {
        "序号": 5666,
        "name": "Running Oak Efficient Growth ET",
        "最新价": 27.12,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 27.22,
        "最高价": 27.22,
        "最低价": 27.08,
        "昨收价": 27.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 11063.0,
        "成交额": 300275.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.RUNN"
    },
    {
        "序号": 5667,
        "name": "John Hancock International High",
        "最新价": 27.35,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 27.35,
        "最高价": 27.35,
        "最低价": 27.35,
        "昨收价": 27.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 136.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JHID"
    },
    {
        "序号": 5668,
        "name": "Xtrackers FTSE Developed ex US ",
        "最新价": 27.48,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 27.51,
        "最高价": 27.66,
        "最低价": 27.34,
        "昨收价": 27.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 3662.0,
        "成交额": 100652.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DEEF"
    },
    {
        "序号": 5669,
        "name": "世邦魏理仕",
        "最新价": 82.69,
        "涨跌额": 0.03,
        "涨跌幅": 0.04,
        "开盘价": 82.52,
        "最高价": 82.96,
        "最低价": 81.83,
        "昨收价": 82.66,
        "总市值": 25203316715.0,
        "市盈率": 42.72,
        "成交量": 1084620.0,
        "成交额": 89504121.0,
        "振幅": 1.37,
        "换手率": 0.36,
        "symbol": "106.CBRE"
    },
    {
        "序号": 5670,
        "name": "Squarespace Inc-A",
        "最新价": 27.78,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 27.66,
        "最高价": 27.86,
        "最低价": 27.29,
        "昨收价": 27.77,
        "总市值": 3777247211.0,
        "市盈率": -15.34,
        "成交量": 561251.0,
        "成交额": 15488892.0,
        "振幅": 2.05,
        "换手率": 0.41,
        "symbol": "106.SQSP"
    },
    {
        "序号": 5671,
        "name": "Columbia International ESG Equi",
        "最新价": 28.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 28.03,
        "最高价": 28.03,
        "最低价": 28.03,
        "昨收价": 28.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 49.0,
        "成交额": 1373.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ESGN"
    },
    {
        "序号": 5672,
        "name": "Advance Auto Parts Inc",
        "最新价": 56.27,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 56.31,
        "最高价": 56.54,
        "最低价": 54.95,
        "昨收价": 56.25,
        "总市值": 3348176977.0,
        "市盈率": 16.31,
        "成交量": 4234666.0,
        "成交额": 236103085.0,
        "振幅": 2.83,
        "换手率": 7.12,
        "symbol": "106.AAP"
    },
    {
        "序号": 5673,
        "name": "PIMCO RAFI Dynamic Multi-Factor",
        "最新价": 28.26,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 28.22,
        "最高价": 28.27,
        "最低价": 28.17,
        "昨收价": 28.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 6377.0,
        "成交额": 180049.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.MFDX"
    },
    {
        "序号": 5674,
        "name": "iShares ESG Aware Growth Alloca",
        "最新价": 28.27,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 28.21,
        "最高价": 28.27,
        "最低价": 28.19,
        "昨收价": 28.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 6491.0,
        "成交额": 183130.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.EAOR"
    },
    {
        "序号": 5675,
        "name": "Innovator Defined Wealth Shield",
        "最新价": 28.41,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 28.4,
        "最高价": 28.44,
        "最低价": 28.4,
        "昨收价": 28.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 120862.0,
        "成交额": 3434622.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.BALT"
    },
    {
        "序号": 5676,
        "name": "Alarm.com Holdings Inc",
        "最新价": 56.97,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "开盘价": 56.61,
        "最高价": 57.61,
        "最低价": 56.61,
        "昨收价": 56.95,
        "总市值": 2847804054.0,
        "市盈率": 41.99,
        "成交量": 179261.0,
        "成交额": 10204816.0,
        "振幅": 1.76,
        "换手率": 0.36,
        "symbol": "105.ALRM"
    },
    {
        "序号": 5677,
        "name": "WisdomTree International ESG Fu",
        "最新价": 28.49,
        "涨跌额": 0.01,
        "涨跌幅": 0.04,
        "开盘价": 28.44,
        "最高价": 28.49,
        "最低价": 28.42,
        "昨收价": 28.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1010.0,
        "成交额": 28744.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.RESD"
    },
    {
        "序号": 5678,
        "name": "GXO Logistics Inc",
        "最新价": 57.27,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "开盘价": 57.0,
        "最高价": 58.13,
        "最低价": 56.95,
        "昨收价": 57.25,
        "总市值": 6812547696.0,
        "市盈率": 33.73,
        "成交量": 635106.0,
        "成交额": 36498629.0,
        "振幅": 2.06,
        "换手率": 0.53,
        "symbol": "106.GXO"
    },
    {
        "序号": 5679,
        "name": "中点能源",
        "最新价": 28.77,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 28.8,
        "最高价": 28.9,
        "最低价": 28.5,
        "昨收价": 28.76,
        "总市值": 18160301821.0,
        "市盈率": 21.14,
        "成交量": 5594359.0,
        "成交额": 160820198.0,
        "振幅": 1.39,
        "换手率": 0.89,
        "symbol": "106.CNP"
    },
    {
        "序号": 5680,
        "name": "Columbia EM Core ex-China ETF",
        "最新价": 28.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 28.76,
        "最高价": 28.93,
        "最低价": 28.74,
        "昨收价": 28.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 88890.0,
        "成交额": 2565910.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.XCEM"
    },
    {
        "序号": 5681,
        "name": "富达国民信息服务",
        "最新价": 58.94,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "开盘价": 58.95,
        "最高价": 59.21,
        "最低价": 58.43,
        "昨收价": 58.92,
        "总市值": 34920983443.0,
        "市盈率": -1.44,
        "成交量": 15519352.0,
        "成交额": 914487472.0,
        "振幅": 1.32,
        "换手率": 2.62,
        "symbol": "106.FIS"
    },
    {
        "序号": 5682,
        "name": "ETRACS 2x Leveraged MSCI US Min",
        "最新价": 29.67,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 29.67,
        "最高价": 29.67,
        "最低价": 29.67,
        "昨收价": 29.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 59.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.USML"
    },
    {
        "序号": 5683,
        "name": "SPDR Dow Jones REIT ETF",
        "最新价": 90.28,
        "涨跌额": 0.03,
        "涨跌幅": 0.03,
        "开盘价": 89.99,
        "最高价": 90.35,
        "最低价": 89.38,
        "昨收价": 90.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 140703.0,
        "成交额": 12652923.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.RWR"
    },
    {
        "序号": 5684,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": 30.11,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 30.11,
        "最高价": 30.14,
        "最低价": 30.04,
        "昨收价": 30.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 2137.0,
        "成交额": 64336.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.JANW"
    },
    {
        "序号": 5685,
        "name": "盎塞",
        "最新价": 91.19,
        "涨跌额": 0.03,
        "涨跌幅": 0.03,
        "开盘价": 91.43,
        "最高价": 92.17,
        "最低价": 90.58,
        "昨收价": 91.16,
        "总市值": 4304168000.0,
        "市盈率": 19.16,
        "成交量": 180656.0,
        "成交额": 16470448.0,
        "振幅": 1.74,
        "换手率": 0.38,
        "symbol": "106.ASGN"
    },
    {
        "序号": 5686,
        "name": "SPDR Bloomberg Investment Grade",
        "最新价": 30.61,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 30.62,
        "最高价": 30.63,
        "最低价": 30.57,
        "昨收价": 30.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 884741.0,
        "成交额": 27072188.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.FLRN"
    },
    {
        "序号": 5687,
        "name": "Gadsden Dynamic Multi-Asset ETF",
        "最新价": 30.62,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 30.76,
        "最高价": 30.76,
        "最低价": 30.59,
        "昨收价": 30.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 1223.0,
        "成交额": 37477.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.GDMA"
    },
    {
        "序号": 5688,
        "name": "Dimensional International Susta",
        "最新价": 30.62,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 30.45,
        "最高价": 30.71,
        "最低价": 30.45,
        "昨收价": 30.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 45103.0,
        "成交额": 1381793.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.DFSI"
    },
    {
        "序号": 5689,
        "name": "Axcelis Technologies Inc",
        "最新价": 122.69,
        "涨跌额": 0.04,
        "涨跌幅": 0.03,
        "开盘价": 122.21,
        "最高价": 124.41,
        "最低价": 121.35,
        "昨收价": 122.65,
        "总市值": 4017785745.0,
        "市盈率": 17.3,
        "成交量": 1394811.0,
        "成交额": 171086621.0,
        "振幅": 2.49,
        "换手率": 4.26,
        "symbol": "105.ACLS"
    },
    {
        "序号": 5690,
        "name": "iShares Core MSCI Total Interna",
        "最新价": 62.9,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "开盘价": 62.65,
        "最高价": 63.04,
        "最低价": 62.63,
        "昨收价": 62.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 2008679.0,
        "成交额": 126252093.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.IXUS"
    },
    {
        "序号": 5691,
        "name": "QRAFT AI-Enhanced U.S. Next Val",
        "最新价": 31.88,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 31.88,
        "最高价": 31.88,
        "最低价": 31.88,
        "昨收价": 31.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 12.0,
        "成交额": 382.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NVQ"
    },
    {
        "序号": 5692,
        "name": "IQ 500 International ETF",
        "最新价": 32.12,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 32.03,
        "最高价": 32.15,
        "最低价": 32.02,
        "昨收价": 32.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 6000.0,
        "成交额": 192328.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.IQIN"
    },
    {
        "序号": 5693,
        "name": "ETC 6 Meridian Hedged Equity In",
        "最新价": 33.06,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 33.11,
        "最高价": 33.13,
        "最低价": 33.06,
        "昨收价": 33.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 3234.0,
        "成交额": 107134.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.SIXH"
    },
    {
        "序号": 5694,
        "name": "Arrow Reserve Capital Managemen",
        "最新价": 99.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.03,
        "开盘价": 99.97,
        "最高价": 99.97,
        "最低价": 99.97,
        "昨收价": 99.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 11.0,
        "成交额": 1099.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ARCM"
    },
    {
        "序号": 5695,
        "name": "Tortoise Midstream Energy Fund",
        "最新价": 33.87,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 34.06,
        "最高价": 34.37,
        "最低价": 33.8,
        "昨收价": 33.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 22937.0,
        "成交额": 779530.0,
        "振幅": 1.68,
        "换手率": null,
        "symbol": "106.NTG"
    },
    {
        "序号": 5696,
        "name": "洛斯保险",
        "最新价": 68.84,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "开盘价": 68.86,
        "最高价": 68.94,
        "最低价": 68.48,
        "昨收价": 68.82,
        "总市值": 15368574195.0,
        "市盈率": 10.03,
        "成交量": 618350.0,
        "成交额": 42528267.0,
        "振幅": 0.67,
        "换手率": 0.28,
        "symbol": "106.L"
    },
    {
        "序号": 5697,
        "name": "Invesco Bloomberg Pricing Power",
        "最新价": 70.31,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "开盘价": 70.33,
        "最高价": 70.41,
        "最低价": 70.24,
        "昨收价": 70.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 2887.0,
        "成交额": 203134.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.POWA"
    },
    {
        "序号": 5698,
        "name": "STAG Industrial Inc",
        "最新价": 36.22,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 36.1,
        "最高价": 36.23,
        "最低价": 35.79,
        "昨收价": 36.21,
        "总市值": 6576498070.0,
        "市盈率": 36.35,
        "成交量": 1204241.0,
        "成交额": 43389866.0,
        "振幅": 1.22,
        "换手率": 0.66,
        "symbol": "106.STAG"
    },
    {
        "序号": 5699,
        "name": "T-Mobile US Inc",
        "最新价": 156.4,
        "涨跌额": 0.04,
        "涨跌幅": 0.03,
        "开盘价": 156.81,
        "最高价": 157.0,
        "最低价": 154.24,
        "昨收价": 156.36,
        "总市值": 180872699384.0,
        "市盈率": 23.25,
        "成交量": 3791386.0,
        "成交额": 592845968.0,
        "振幅": 1.77,
        "换手率": 0.33,
        "symbol": "105.TMUS"
    },
    {
        "序号": 5700,
        "name": "WisdomTree International AI Enh",
        "最新价": 39.37,
        "涨跌额": 0.01,
        "涨跌幅": 0.03,
        "开盘价": 39.13,
        "最高价": 39.38,
        "最低价": 39.13,
        "昨收价": 39.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 2685.0,
        "成交额": 105546.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.AIVI"
    },
    {
        "序号": 5701,
        "name": "达美航空",
        "最新价": 40.35,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 40.26,
        "最高价": 40.74,
        "最低价": 39.86,
        "昨收价": 40.34,
        "总市值": 25963749522.0,
        "市盈率": 7.63,
        "成交量": 8728373.0,
        "成交额": 352732960.0,
        "振幅": 2.18,
        "换手率": 1.36,
        "symbol": "106.DAL"
    },
    {
        "序号": 5702,
        "name": "Innovator Growth-100 Power Buff",
        "最新价": 41.46,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 41.43,
        "最高价": 41.48,
        "最低价": 41.43,
        "昨收价": 41.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 2733.0,
        "成交额": 113228.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.NJAN"
    },
    {
        "序号": 5703,
        "name": "EastGroup Properties Inc",
        "最新价": 173.14,
        "涨跌额": 0.04,
        "涨跌幅": 0.02,
        "开盘价": 172.49,
        "最高价": 173.57,
        "最低价": 172.12,
        "昨收价": 173.1,
        "总市值": 8021649092.0,
        "市盈率": 45.65,
        "成交量": 233325.0,
        "成交额": 40357993.0,
        "振幅": 0.84,
        "换手率": 0.5,
        "symbol": "106.EGP"
    },
    {
        "序号": 5704,
        "name": "高通",
        "最新价": 132.97,
        "涨跌额": 0.03,
        "涨跌幅": 0.02,
        "开盘价": 131.22,
        "最高价": 133.97,
        "最低价": 131.18,
        "昨收价": 132.94,
        "总市值": 147995610000.0,
        "市盈率": 20.46,
        "成交量": 7678624.0,
        "成交额": 1022111344.0,
        "振幅": 2.1,
        "换手率": 0.69,
        "symbol": "105.QCOM"
    },
    {
        "序号": 5705,
        "name": "美国1-3月国债ETF-SPDR",
        "最新价": 91.53,
        "涨跌额": 0.02,
        "涨跌幅": 0.02,
        "开盘价": 91.53,
        "最高价": 91.54,
        "最低价": 91.53,
        "昨收价": 91.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 5730106.0,
        "成交额": 524492240.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.BIL"
    },
    {
        "序号": 5706,
        "name": "American Century Diversified Mu",
        "最新价": 50.1,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 50.12,
        "最高价": 50.3,
        "最低价": 50.0,
        "昨收价": 50.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 80965.0,
        "成交额": 4059133.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.TAXF"
    },
    {
        "序号": 5707,
        "name": "ClearShares Ultra-Short Maturit",
        "最新价": 100.3,
        "涨跌额": 0.02,
        "涨跌幅": 0.02,
        "开盘价": 100.3,
        "最高价": 100.3,
        "最低价": 100.27,
        "昨收价": 100.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 3557.0,
        "成交额": 356717.0,
        "振幅": 0.03,
        "换手率": null,
        "symbol": "107.OPER"
    },
    {
        "序号": 5708,
        "name": "PGIM Floating Rate Income ETF",
        "最新价": 50.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 50.2,
        "最高价": 50.2,
        "最低价": 50.14,
        "昨收价": 50.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 2426.0,
        "成交额": 121708.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.PFRL"
    },
    {
        "序号": 5709,
        "name": "WisdomTree Floating Rate Treasu",
        "最新价": 50.35,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 50.35,
        "最高价": 50.35,
        "最低价": 50.34,
        "昨收价": 50.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 8336355.0,
        "成交额": 419718624.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.USFR"
    },
    {
        "序号": 5710,
        "name": "PGIM AAA CLO ETF",
        "最新价": 50.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 50.52,
        "最高价": 50.53,
        "最低价": 50.5,
        "昨收价": 50.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 6605.0,
        "成交额": 333662.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.PAAA"
    },
    {
        "序号": 5711,
        "name": "iShares Treasury Floating Rate ",
        "最新价": 50.56,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 50.55,
        "最高价": 50.56,
        "最低价": 50.55,
        "昨收价": 50.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1701420.0,
        "成交额": 86014335.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.TFLO"
    },
    {
        "序号": 5712,
        "name": "BlackRock AAA CLO ETF",
        "最新价": 51.26,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 51.31,
        "最高价": 51.31,
        "最低价": 51.25,
        "昨收价": 51.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 13634.0,
        "成交额": 699050.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.CLOA"
    },
    {
        "序号": 5713,
        "name": "Alpha Architect 1-3 Month Box E",
        "最新价": 104.77,
        "涨跌额": 0.02,
        "涨跌幅": 0.02,
        "开盘价": 104.76,
        "最高价": 104.77,
        "最低价": 104.75,
        "昨收价": 104.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 36360.0,
        "成交额": 3809157.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.BOXX"
    },
    {
        "序号": 5714,
        "name": "Vanguard ESG International Stoc",
        "最新价": 53.2,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 52.91,
        "最高价": 53.31,
        "最低价": 52.91,
        "昨收价": 53.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 124614.0,
        "成交额": 6624768.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.VSGX"
    },
    {
        "序号": 5715,
        "name": "Centerspace",
        "最新价": 54.49,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 54.14,
        "最高价": 54.71,
        "最低价": 53.79,
        "昨收价": 54.48,
        "总市值": 820213395.0,
        "市盈率": 17.05,
        "成交量": 63749.0,
        "成交额": 3463159.0,
        "振幅": 1.69,
        "换手率": 0.42,
        "symbol": "106.CSR"
    },
    {
        "序号": 5716,
        "name": "First Trust RiverFront Dynamic ",
        "最新价": 56.97,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 56.71,
        "最高价": 56.98,
        "最低价": 56.71,
        "昨收价": 56.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 1437.0,
        "成交额": 81737.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "105.RFEM"
    },
    {
        "序号": 5717,
        "name": "Berry Global Group Inc",
        "最新价": 65.42,
        "涨跌额": 0.01,
        "涨跌幅": 0.02,
        "开盘价": 65.25,
        "最高价": 65.79,
        "最低价": 64.59,
        "昨收价": 65.41,
        "总市值": 7556010000.0,
        "市盈率": 12.41,
        "成交量": 734169.0,
        "成交额": 47896707.0,
        "振幅": 1.83,
        "换手率": 0.64,
        "symbol": "106.BERY"
    },
    {
        "序号": 5718,
        "name": "伍德沃德",
        "最新价": 133.55,
        "涨跌额": 0.02,
        "涨跌幅": 0.01,
        "开盘价": 133.81,
        "最高价": 134.55,
        "最低价": 132.44,
        "昨收价": 133.53,
        "总市值": 8020754180.0,
        "市盈率": 34.52,
        "成交量": 322072.0,
        "成交额": 42929139.0,
        "振幅": 1.58,
        "换手率": 0.54,
        "symbol": "105.WWD"
    },
    {
        "序号": 5719,
        "name": "零售ETF-SPDR",
        "最新价": 67.08,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 66.9,
        "最高价": 67.64,
        "最低价": 66.62,
        "昨收价": 67.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 6429424.0,
        "成交额": 431569104.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "107.XRT"
    },
    {
        "序号": 5720,
        "name": "Okta Inc-A",
        "最新价": 72.01,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 71.89,
        "最高价": 73.5,
        "最低价": 71.58,
        "昨收价": 72.0,
        "总市值": 11906513685.0,
        "市盈率": -25.66,
        "成交量": 2784358.0,
        "成交额": 201457959.0,
        "振幅": 2.67,
        "换手率": 1.68,
        "symbol": "105.OKTA"
    },
    {
        "序号": 5721,
        "name": "切萨皮克能源",
        "最新价": 75.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 75.16,
        "最高价": 76.3,
        "最低价": 74.21,
        "昨收价": 75.04,
        "总市值": 9836970636.0,
        "市盈率": 1.81,
        "成交量": 1543807.0,
        "成交额": 115759600.0,
        "振幅": 2.79,
        "换手率": 1.18,
        "symbol": "105.CHK"
    },
    {
        "序号": 5722,
        "name": "FleetCor Technologies Inc",
        "最新价": 256.09,
        "涨跌额": 0.03,
        "涨跌幅": 0.01,
        "开盘价": 255.6,
        "最高价": 258.41,
        "最低价": 254.61,
        "昨收价": 256.06,
        "总市值": 18490754883.0,
        "市盈率": 19.44,
        "成交量": 546414.0,
        "成交额": 139887499.0,
        "振幅": 1.48,
        "换手率": 0.76,
        "symbol": "106.FLT"
    },
    {
        "序号": 5723,
        "name": "PIMCO Enhanced Short Maturity A",
        "最新价": 98.01,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 98.01,
        "最高价": 98.03,
        "最低价": 97.98,
        "昨收价": 98.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 79363.0,
        "成交额": 7777142.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.EMNT"
    },
    {
        "序号": 5724,
        "name": "iShares 0-3 Month Treasury Bond",
        "最新价": 100.41,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 100.41,
        "最高价": 100.41,
        "最低价": 100.4,
        "昨收价": 100.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 2503476.0,
        "成交额": 251367717.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.SGOV"
    },
    {
        "序号": 5725,
        "name": "Amplify Samsung SOFR ETF",
        "最新价": 100.46,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 100.46,
        "最高价": 100.46,
        "最低价": 100.46,
        "昨收价": 100.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 118.0,
        "成交额": 11854.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SOF"
    },
    {
        "序号": 5726,
        "name": "PIMCO Ultra Short Government Ac",
        "最新价": 100.72,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 100.71,
        "最高价": 100.72,
        "最低价": 100.71,
        "昨收价": 100.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 10494.0,
        "成交额": 1056891.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.BILZ"
    },
    {
        "序号": 5727,
        "name": "宜瑞安",
        "最新价": 106.12,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 106.17,
        "最高价": 106.95,
        "最低价": 104.68,
        "昨收价": 106.11,
        "总市值": 6918224704.0,
        "市盈率": 11.05,
        "成交量": 232861.0,
        "成交额": 24726455.0,
        "振幅": 2.14,
        "换手率": 0.36,
        "symbol": "106.INGR"
    },
    {
        "序号": 5728,
        "name": "捷迈邦美",
        "最新价": 117.3,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 117.31,
        "最高价": 117.76,
        "最低价": 116.76,
        "昨收价": 117.29,
        "总市值": 24513437400.0,
        "市盈率": 51.68,
        "成交量": 1357927.0,
        "成交额": 159287652.0,
        "振幅": 0.85,
        "换手率": 0.65,
        "symbol": "106.ZBH"
    },
    {
        "序号": 5729,
        "name": "汉诺威保险",
        "最新价": 126.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 127.13,
        "最高价": 127.97,
        "最低价": 124.91,
        "昨收价": 126.85,
        "总市值": 4536822124.0,
        "市盈率": -53.56,
        "成交量": 122088.0,
        "成交额": 15475101.0,
        "振幅": 2.41,
        "换手率": 0.34,
        "symbol": "106.THG"
    },
    {
        "序号": 5730,
        "name": "Universal Display Corp",
        "最新价": 175.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.01,
        "开盘价": 174.24,
        "最高价": 178.04,
        "最低价": 174.24,
        "昨收价": 175.24,
        "总市值": 8299502819.0,
        "市盈率": 40.26,
        "成交量": 254763.0,
        "成交额": 44783962.0,
        "振幅": 2.17,
        "换手率": 0.54,
        "symbol": "105.OLED"
    },
    {
        "序号": 5731,
        "name": "中盈瑞信",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZYRX"
    },
    {
        "序号": 5732,
        "name": "中元生物",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZYB"
    },
    {
        "序号": 5733,
        "name": "众兴控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZXTY"
    },
    {
        "序号": 5734,
        "name": "Zura Bio Ltd Wt",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.53,
        "最高价": 0.53,
        "最低价": 0.53,
        "昨收价": 0.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ZURAW"
    },
    {
        "序号": 5735,
        "name": "Zura Bio Ltd-A",
        "最新价": 4.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.92,
        "最高价": 4.03,
        "最低价": 3.92,
        "昨收价": 4.0,
        "总市值": 174374712.0,
        "市盈率": -3.4,
        "成交量": 93856.0,
        "成交额": 374055.0,
        "振幅": 2.75,
        "换手率": 0.22,
        "symbol": "105.ZURA"
    },
    {
        "序号": 5736,
        "name": "Zi Toprun Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZTOPW"
    },
    {
        "序号": 5737,
        "name": "Zi Toprun Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZTOPU"
    },
    {
        "序号": 5738,
        "name": "Zi Toprun Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZTOP"
    },
    {
        "序号": 5739,
        "name": "X-Square Municipal Income Tax F",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.81,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ZTAX"
    },
    {
        "序号": 5740,
        "name": "智联招聘",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ZPIN"
    },
    {
        "序号": 5741,
        "name": "Zalatoris II Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.63,
        "总市值": 127612693.0,
        "市盈率": 17.51,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZLSWU"
    },
    {
        "序号": 5742,
        "name": "Zalatoris II Acquisition Corp-A",
        "最新价": 10.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.66,
        "最高价": 10.66,
        "最低价": 10.66,
        "昨收价": 10.66,
        "总市值": 127972842.0,
        "市盈率": 17.56,
        "成交量": 7.0,
        "成交额": 74.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ZLS"
    },
    {
        "序号": 5743,
        "name": "震坤行",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ZKH"
    },
    {
        "序号": 5744,
        "name": "极氪",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ZK"
    },
    {
        "序号": 5745,
        "name": "Zions Bancorp NA Series A Pfd",
        "最新价": 19.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 19.8,
        "最高价": 19.8,
        "最低价": 19.8,
        "昨收价": 19.8,
        "总市值": 1309552.0,
        "市盈率": null,
        "成交量": 288.0,
        "成交额": 5702.0,
        "振幅": 0.0,
        "换手率": 0.44,
        "symbol": "105.ZIONP"
    },
    {
        "序号": 5746,
        "name": "ZEGA Buy and Hedge ETF",
        "最新价": 17.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.65,
        "最高价": 17.7,
        "最低价": 17.65,
        "昨收价": 17.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 3173.0,
        "成交额": 56082.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.ZHDG"
    },
    {
        "序号": 5747,
        "name": "知乎",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.99,
        "最高价": 1.01,
        "最低价": 0.97,
        "昨收价": 1.0,
        "总市值": 615439188.0,
        "市盈率": -4.64,
        "成交量": 5348088.0,
        "成交额": 5314733.0,
        "振幅": 4.0,
        "换手率": 0.87,
        "symbol": "106.ZH"
    },
    {
        "序号": 5748,
        "name": "环保新材",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZGHB"
    },
    {
        "序号": 5749,
        "name": "ZeroFox Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZFOXW"
    },
    {
        "序号": 5750,
        "name": "悦美",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZDYM"
    },
    {
        "序号": 5751,
        "name": "Defiance Vehicle & Technology I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ZCAR"
    },
    {
        "序号": 5752,
        "name": "资本界",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZBJ"
    },
    {
        "序号": 5753,
        "name": "致保科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZBAO"
    },
    {
        "序号": 5754,
        "name": "Zapp Electric Vehicles Group Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ZAPPW"
    },
    {
        "序号": 5755,
        "name": "YUEZHONGHUI INTERNATIONAL HOLDI",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YZH"
    },
    {
        "序号": 5756,
        "name": "YY Group Holding Ltd-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YYGH"
    },
    {
        "序号": 5757,
        "name": "VanEck Future of Food ETF",
        "最新价": 15.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.84,
        "最高价": 15.9,
        "最低价": 15.84,
        "昨收价": 15.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 295.0,
        "成交额": 4672.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.YUMY"
    },
    {
        "序号": 5758,
        "name": "依生生物(权证)",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 15077.0,
        "成交额": 469.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.YSBPW"
    },
    {
        "序号": 5759,
        "name": "Yotta Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YOTAW"
    },
    {
        "序号": 5760,
        "name": "Yotta Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 78878815.0,
        "市盈率": 165.82,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YOTAU"
    },
    {
        "序号": 5761,
        "name": "Yotta Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YOTAR"
    },
    {
        "序号": 5762,
        "name": "AdvisorShares Pure Cannabis ETF",
        "最新价": 3.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.13,
        "最高价": 3.22,
        "最低价": 3.11,
        "昨收价": 3.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 33802.0,
        "成交额": 106452.0,
        "振幅": 3.48,
        "换手率": null,
        "symbol": "107.YOLO"
    },
    {
        "序号": 5763,
        "name": "Yandex NV-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 18.94,
        "总市值": 6846474402.0,
        "市盈率": 33.31,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YNDX"
    },
    {
        "序号": 5764,
        "name": "J-Star Holding Co Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YMAT"
    },
    {
        "序号": 5765,
        "name": "誉凯健康",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YKAI"
    },
    {
        "序号": 5766,
        "name": "星图国际集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YIBO"
    },
    {
        "序号": 5767,
        "name": "1药网",
        "最新价": 1.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.6,
        "最高价": 1.75,
        "最低价": 1.6,
        "昨收价": 1.65,
        "总市值": 138588848.0,
        "市盈率": -3.25,
        "成交量": 207093.0,
        "成交额": 349864.0,
        "振幅": 9.09,
        "换手率": 0.25,
        "symbol": "105.YI"
    },
    {
        "序号": 5768,
        "name": "优创易泊",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YBZN"
    },
    {
        "序号": 5769,
        "name": "有信云",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.YAAS"
    },
    {
        "序号": 5770,
        "name": "Global X S&P 500 ESG Covered Ca",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.56,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.XYLE"
    },
    {
        "序号": 5771,
        "name": "Solitario Zinc Corp",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.53,
        "最高价": 0.54,
        "最低价": 0.53,
        "昨收价": 0.53,
        "总市值": 41915770.0,
        "市盈率": -12.41,
        "成交量": 18943.0,
        "成交额": 10083.0,
        "振幅": 1.89,
        "换手率": 0.02,
        "symbol": "107.XPL"
    },
    {
        "序号": 5772,
        "name": "Xperi Inc",
        "最新价": 9.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.81,
        "最高价": 9.97,
        "最低价": 9.64,
        "昨收价": 9.92,
        "总市值": 431181073.0,
        "市盈率": -1.05,
        "成交量": 377636.0,
        "成交额": 3730969.0,
        "振幅": 3.33,
        "换手率": 0.87,
        "symbol": "106.XPER"
    },
    {
        "序号": 5773,
        "name": "Power & Digital Infrastructure ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XPDBW"
    },
    {
        "序号": 5774,
        "name": "Power & Digital Infrastructure ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.75,
        "总市值": 191303539.0,
        "市盈率": 67.29,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XPDBU"
    },
    {
        "序号": 5775,
        "name": "Power & Digital Infrastructure ",
        "最新价": 10.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.73,
        "最高价": 10.73,
        "最低价": 10.73,
        "昨收价": 10.73,
        "总市值": 190947625.0,
        "市盈率": 67.16,
        "成交量": 277.0,
        "成交额": 2972.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.XPDB"
    },
    {
        "序号": 5776,
        "name": "Xos Inc Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 55064.0,
        "成交额": 1276.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.XOSWW"
    },
    {
        "序号": 5777,
        "name": "FT Cboe Vest U.S. Equity Enhanc",
        "最新价": 30.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 30.99,
        "最高价": 31.02,
        "最低价": 30.93,
        "昨收价": 30.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 25985.0,
        "成交额": 804813.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.XNOV"
    },
    {
        "序号": 5778,
        "name": "Invesco S&P MidCap Low Volatili",
        "最新价": 51.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 51.84,
        "最高价": 52.13,
        "最低价": 51.74,
        "昨收价": 51.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 35624.0,
        "成交额": 1848427.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.XMLV"
    },
    {
        "序号": 5779,
        "name": "XJet Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XJET"
    },
    {
        "序号": 5780,
        "name": "ExcelFin Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XFINW"
    },
    {
        "序号": 5781,
        "name": "ExcelFin Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.71,
        "总市值": 85160918.0,
        "市盈率": -52.6,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XFINU"
    },
    {
        "序号": 5782,
        "name": "Kelly CRISPR & Gene Editing Tec",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 7.38,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.XDNA"
    },
    {
        "序号": 5783,
        "name": "Xenetic Biosciences Inc Wt",
        "最新价": 1.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.12,
        "最高价": 1.12,
        "最低价": 1.12,
        "昨收价": 1.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 2.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.XBIOW"
    },
    {
        "序号": 5784,
        "name": "US Treasury 6 Month Bill ETF",
        "最新价": 50.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.02,
        "最高价": 50.05,
        "最低价": 50.0,
        "昨收价": 50.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1750568.0,
        "成交额": 87533279.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.XBIL"
    },
    {
        "序号": 5785,
        "name": "Wytec International Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WYTCW"
    },
    {
        "序号": 5786,
        "name": "Wytec International Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 4.15,
        "总市值": 74528970.0,
        "市盈率": -20.75,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WYTC"
    },
    {
        "序号": 5787,
        "name": "物芯控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WXT"
    },
    {
        "序号": 5788,
        "name": "WF International Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WXM"
    },
    {
        "序号": 5789,
        "name": "西水资源",
        "最新价": 0.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.62,
        "最高价": 0.62,
        "最低价": 0.6,
        "昨收价": 0.62,
        "总市值": 33998249.0,
        "市盈率": -3.02,
        "成交量": 138523.0,
        "成交额": 84672.0,
        "振幅": 3.23,
        "换手率": 0.25,
        "symbol": "107.WWR"
    },
    {
        "序号": 5790,
        "name": "UBS AG ETRACS Whitney U.S. Crit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 28.29,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WUCT"
    },
    {
        "序号": 5791,
        "name": "58同城",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WUBA"
    },
    {
        "序号": 5792,
        "name": "联代科技",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.23,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.24,
        "总市值": 3256270.0,
        "市盈率": -0.26,
        "成交量": 29845.0,
        "成交额": 7045.0,
        "振幅": 8.33,
        "换手率": 0.22,
        "symbol": "105.WTO"
    },
    {
        "序号": 5793,
        "name": "Welsbach Technology Metals Acqu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.83,
        "总市值": 48267436.0,
        "市盈率": -57.73,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WTMAU"
    },
    {
        "序号": 5794,
        "name": "Welsbach Technology Metals Acqu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WTMAR"
    },
    {
        "序号": 5795,
        "name": "华斯科-B",
        "最新价": 375.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 375.0,
        "最高价": 375.0,
        "最低价": 375.0,
        "昨收价": 375.0,
        "总市值": 14772834375.0,
        "市盈率": 24.98,
        "成交量": 5.0,
        "成交额": 1875.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.WSO_B"
    },
    {
        "序号": 5796,
        "name": "Williams Rowland Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.47,
        "总市值": 87395865.0,
        "市盈率": 94.59,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WRAC_U"
    },
    {
        "序号": 5797,
        "name": "Williams Rowland Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.48,
        "总市值": 87479337.0,
        "市盈率": 94.68,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WRAC"
    },
    {
        "序号": 5798,
        "name": "Worthington Industries Inc WI",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 48.0,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WORw"
    },
    {
        "序号": 5799,
        "name": "沃氪医疗",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WOK"
    },
    {
        "序号": 5800,
        "name": "知食谷",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.08,
        "最低价": 0.07,
        "昨收价": 0.08,
        "总市值": 4875625.0,
        "市盈率": -0.44,
        "成交量": 2505330.0,
        "成交额": 184598.0,
        "振幅": 12.5,
        "换手率": 4.11,
        "symbol": "105.WNW"
    },
    {
        "序号": 5801,
        "name": "Andretti Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.24,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WNNR_WS"
    },
    {
        "序号": 5802,
        "name": "Andretti Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.02,
        "总市值": 150365707.0,
        "市盈率": 67.26,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WNNR_U"
    },
    {
        "序号": 5803,
        "name": "William Penn Bancorp",
        "最新价": 12.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.45,
        "最高价": 12.51,
        "最低价": 12.35,
        "昨收价": 12.43,
        "总市值": 125153208.0,
        "市盈率": 64.05,
        "成交量": 35183.0,
        "成交额": 438359.0,
        "振幅": 1.29,
        "换手率": 0.35,
        "symbol": "105.WMPN"
    },
    {
        "序号": 5804,
        "name": "Western Asset Mortgage Capital ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.18,
        "总市值": 55534952.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WMC"
    },
    {
        "序号": 5805,
        "name": "Sichuan Wanma Benteng Technolog",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WMBT"
    },
    {
        "序号": 5806,
        "name": "WealthTrust DBS Long Term Growt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.52,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WLTG"
    },
    {
        "序号": 5807,
        "name": "Wearable Devices Ltd Wt",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.06,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1392.0,
        "成交额": 84.0,
        "振幅": 14.29,
        "换手率": null,
        "symbol": "105.WLDSW"
    },
    {
        "序号": 5808,
        "name": "Merlyn.AI Bull-Rider Bear-Fight",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 27.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WIZ"
    },
    {
        "序号": 5809,
        "name": "WinVest Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 8.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.WINVW"
    },
    {
        "序号": 5810,
        "name": "WinVest Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WINVR"
    },
    {
        "序号": 5811,
        "name": "Wheeler Real Estate Investment ",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.4,
        "最高价": 1.4,
        "最低价": 1.4,
        "昨收价": 1.4,
        "总市值": 4730799.0,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.WHLRP"
    },
    {
        "序号": 5812,
        "name": "Wheeler Real Estate Investment ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 58.0,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WHLRL"
    },
    {
        "序号": 5813,
        "name": "GeneDx Holdings Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": null,
        "最高价": 0.01,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 13545.0,
        "成交额": 104.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.WGSWW"
    },
    {
        "序号": 5814,
        "name": "WisdomTree U.S. Corporate Bond ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 43.96,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WFIG"
    },
    {
        "序号": 5815,
        "name": "Where Food Comes From Inc",
        "最新价": 13.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 13.58,
        "最高价": 13.58,
        "最低价": 13.57,
        "昨收价": 13.57,
        "总市值": 75230329.0,
        "市盈率": 40.32,
        "成交量": 1829.0,
        "成交额": 24822.0,
        "振幅": 0.07,
        "换手率": 0.03,
        "symbol": "105.WFCF"
    },
    {
        "序号": 5816,
        "name": "微巴士",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WETO"
    },
    {
        "序号": 5817,
        "name": "伟易达",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WETH"
    },
    {
        "序号": 5818,
        "name": "Integrated Wellness Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.WEL_WS"
    },
    {
        "序号": 5819,
        "name": "Integrated Wellness Acquisition",
        "最新价": 11.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.05,
        "最高价": 11.05,
        "最低价": 11.05,
        "昨收价": 11.05,
        "总市值": 91342306.0,
        "市盈率": 49.65,
        "成交量": 2.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.WEL_U"
    },
    {
        "序号": 5820,
        "name": "旺地奇源",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WDQY"
    },
    {
        "序号": 5821,
        "name": "Worldwide Diversified Holdings ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WDHI"
    },
    {
        "序号": 5822,
        "name": "WBI BullBear Trend Switch US 20",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WBIS"
    },
    {
        "序号": 5823,
        "name": "WBI BullBear Trend Switch US 10",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WBIQ"
    },
    {
        "序号": 5824,
        "name": "WBI BullBear Trend Switch US 20",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WBIM"
    },
    {
        "序号": 5825,
        "name": "WBI BullBear Trend Switch US 10",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.WBIK"
    },
    {
        "序号": 5826,
        "name": "Waystar Holding Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WAY"
    },
    {
        "序号": 5827,
        "name": "Western Acquisition Ventures Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WAVSW"
    },
    {
        "序号": 5828,
        "name": "Western Acquisition Ventures Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 38622613.0,
        "市盈率": -100.83,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WAVSU"
    },
    {
        "序号": 5829,
        "name": "Western Acquisition Ventures Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 38160279.0,
        "市盈率": -99.62,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WAVS"
    },
    {
        "序号": 5830,
        "name": "无锡金城幕墙",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WALL"
    },
    {
        "序号": 5831,
        "name": "Waldencast plc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.62,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.WALDW"
    },
    {
        "序号": 5832,
        "name": "西美银行",
        "最新价": 54.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 53.92,
        "最高价": 54.74,
        "最低价": 53.66,
        "昨收价": 54.0,
        "总市值": 1439047944.0,
        "市盈率": 8.9,
        "成交量": 92375.0,
        "成交额": 4993261.0,
        "振幅": 2.0,
        "换手率": 0.35,
        "symbol": "105.WABC"
    },
    {
        "序号": 5833,
        "name": "Vizsla Silver Corp",
        "最新价": 1.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.14,
        "最高价": 1.2,
        "最低价": 1.14,
        "昨收价": 1.15,
        "总市值": 239192892.0,
        "市盈率": -22.34,
        "成交量": 261820.0,
        "成交额": 303974.0,
        "振幅": 5.22,
        "换手率": 0.13,
        "symbol": "107.VZLA"
    },
    {
        "序号": 5834,
        "name": "Vanguard International High Div",
        "最新价": 64.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 64.27,
        "最高价": 64.64,
        "最低价": 64.23,
        "昨收价": 64.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 425303.0,
        "成交额": 27402182.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "105.VYMI"
    },
    {
        "序号": 5835,
        "name": "Virtus WMC Risk-Managed Alterna",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VWRM"
    },
    {
        "序号": 5836,
        "name": "Arch Indices VOI Absolute Incom",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VWI"
    },
    {
        "序号": 5837,
        "name": "Vintage Wine Estates Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 2033.0,
        "成交额": 24.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.VWEWW"
    },
    {
        "序号": 5838,
        "name": "Vintage Wine Estates Inc",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.52,
        "最高价": 0.55,
        "最低价": 0.51,
        "昨收价": 0.51,
        "总市值": 30409476.0,
        "市盈率": -0.15,
        "成交量": 138619.0,
        "成交额": 71995.0,
        "振幅": 7.84,
        "换手率": 0.23,
        "symbol": "105.VWE"
    },
    {
        "序号": 5839,
        "name": "Invesco Senior Income Trust",
        "最新价": 4.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.06,
        "最高价": 4.11,
        "最低价": 4.06,
        "昨收价": 4.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1368847.0,
        "成交额": 5582252.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "106.VVR"
    },
    {
        "序号": 5840,
        "name": "Vitro Biopharma Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VTRO"
    },
    {
        "序号": 5841,
        "name": "Valtech SE-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VTEC"
    },
    {
        "序号": 5842,
        "name": "Vanguard Tax-Exempt Bond ETF",
        "最新价": 50.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.26,
        "最高价": 50.31,
        "最低价": 50.18,
        "昨收价": 50.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 5747838.0,
        "成交额": 288774016.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.VTEB"
    },
    {
        "序号": 5843,
        "name": "Versus Systems Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VSSYW"
    },
    {
        "序号": 5844,
        "name": "Vision Sensing Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.08,
        "总市值": 51136505.0,
        "市盈率": 138.88,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VSACU"
    },
    {
        "序号": 5845,
        "name": "Vision Sensing Acquisition Corp",
        "最新价": 11.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.06,
        "最高价": 11.06,
        "最低价": 11.06,
        "昨收价": 11.06,
        "总市值": 51044200.0,
        "市盈率": 138.63,
        "成交量": 101.0,
        "成交额": 1117.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.VSAC"
    },
    {
        "序号": 5846,
        "name": "Veritiv Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 169.99,
        "总市值": 2306307027.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.VRTV"
    },
    {
        "序号": 5847,
        "name": "Virax Biolabs Group Ltd",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.17,
        "最高价": 0.18,
        "最低价": 0.17,
        "昨收价": 0.17,
        "总市值": 2643005.0,
        "市盈率": -0.48,
        "成交量": 213471.0,
        "成交额": 37441.0,
        "振幅": 5.88,
        "换手率": 1.37,
        "symbol": "105.VRAX"
    },
    {
        "序号": 5848,
        "name": "Invesco Pennsylvania Value Muni",
        "最新价": 9.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.86,
        "最高价": 9.92,
        "最低价": 9.86,
        "昨收价": 9.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 59065.0,
        "成交额": 584359.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "106.VPV"
    },
    {
        "序号": 5849,
        "name": "Natixis Vaughan Nelson Mid Cap ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.75,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VNMC"
    },
    {
        "序号": 5850,
        "name": "威睿",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 142.48,
        "总市值": 61521366250.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.VMW"
    },
    {
        "序号": 5851,
        "name": "Valuence Merger Corp I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VMCAW"
    },
    {
        "序号": 5852,
        "name": "Valuence Merger Corp I Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.13,
        "总市值": 130368005.0,
        "市盈率": 27.58,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VMCAU"
    },
    {
        "序号": 5853,
        "name": "V-Shares MSCI World ESG Materia",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 27.97,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VMAT"
    },
    {
        "序号": 5854,
        "name": "Valens Semiconductor Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.VLN_WS"
    },
    {
        "序号": 5855,
        "name": "Valens Semiconductor Ltd",
        "最新价": 2.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.38,
        "最高价": 2.38,
        "最低价": 2.3,
        "昨收价": 2.35,
        "总市值": 243778702.0,
        "市盈率": -8.19,
        "成交量": 96757.0,
        "成交额": 226687.0,
        "振幅": 3.4,
        "换手率": 0.09,
        "symbol": "106.VLN"
    },
    {
        "序号": 5856,
        "name": "绰耀资本",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VITT"
    },
    {
        "序号": 5857,
        "name": "Pacer BioThreat Strategy ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.69,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VIRS"
    },
    {
        "序号": 5858,
        "name": "7GC & Co Holdings Inc Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.VIIAW"
    },
    {
        "序号": 5859,
        "name": "View Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 49496.0,
        "成交额": 321.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.VIEWW"
    },
    {
        "序号": 5860,
        "name": "Viveon Health Acquisition Corp",
        "最新价": 11.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.37,
        "最高价": 11.4,
        "最低价": 11.3,
        "昨收价": 11.3,
        "总市值": 75129915.0,
        "市盈率": -154.34,
        "成交量": 912.0,
        "成交额": 10360.0,
        "振幅": 0.88,
        "换手率": 0.01,
        "symbol": "107.VHAQ"
    },
    {
        "序号": 5861,
        "name": "Vert Global Sustainable Real Es",
        "最新价": 9.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.48,
        "最高价": 9.61,
        "最低价": 9.48,
        "昨收价": 9.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 60670.0,
        "成交额": 579559.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "105.VGSR"
    },
    {
        "序号": 5862,
        "name": "VinFast Auto Ltd Wt",
        "最新价": 1.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.29,
        "最高价": 1.38,
        "最低价": 1.26,
        "昨收价": 1.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 47871.0,
        "成交额": 61943.0,
        "振幅": 9.02,
        "换手率": null,
        "symbol": "105.VFSWW"
    },
    {
        "序号": 5863,
        "name": "Veritone Inc",
        "最新价": 2.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.18,
        "最高价": 2.26,
        "最低价": 2.13,
        "昨收价": 2.2,
        "总市值": 81515080.0,
        "市盈率": -1.23,
        "成交量": 241691.0,
        "成交额": 534804.0,
        "振幅": 5.91,
        "换手率": 0.65,
        "symbol": "105.VERI"
    },
    {
        "序号": 5864,
        "name": "Verb Technology Co Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 800.0,
        "成交额": 2.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.VERBW"
    },
    {
        "序号": 5865,
        "name": "Verdant Earth Technologies Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VDNT"
    },
    {
        "序号": 5866,
        "name": "V-Shares US Leadership Diversit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.16,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VDNI"
    },
    {
        "序号": 5867,
        "name": "10X Capital Venture Acquisition",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 533560.0,
        "成交额": 26678.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.VCXB_WS"
    },
    {
        "序号": 5868,
        "name": "10X Capital Venture Acquisition",
        "最新价": 10.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.7,
        "最高价": 10.7,
        "最低价": 10.7,
        "昨收价": 10.7,
        "总市值": 141178828.0,
        "市盈率": -112.76,
        "成交量": 4.0,
        "成交额": 42.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.VCXB_U"
    },
    {
        "序号": 5869,
        "name": "10X Capital Venture Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 8.44,
        "总市值": 79683897.0,
        "市盈率": -117.0,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VCXAU"
    },
    {
        "序号": 5870,
        "name": "Virtus Duff & Phelps Clean Ener",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 17.19,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VCLN"
    },
    {
        "序号": 5871,
        "name": "Cambria Venture ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.VCAP"
    },
    {
        "序号": 5872,
        "name": "农村金融信托银行",
        "最新价": 39.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 39.7,
        "最高价": 39.7,
        "最低价": 39.7,
        "昨收价": 39.7,
        "总市值": 58948029.0,
        "市盈率": 24.69,
        "成交量": 39.0,
        "成交额": 1548.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.VBFC"
    },
    {
        "序号": 5873,
        "name": "Vector Acquisition Corp II-A",
        "最新价": 10.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.52,
        "最高价": 10.52,
        "最低价": 10.52,
        "昨收价": 10.52,
        "总市值": 171021810.0,
        "市盈率": 23.69,
        "成交量": 146.0,
        "成交额": 1535.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.VAQC"
    },
    {
        "序号": 5874,
        "name": "Vistas Acquisition Co II Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VACXW"
    },
    {
        "序号": 5875,
        "name": "Vistas Acquisition Co II Inc Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VACXU"
    },
    {
        "序号": 5876,
        "name": "Vistas Acquisition Co II Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.VACX"
    },
    {
        "序号": 5877,
        "name": "UWM Holdings Corp Wt",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.19,
        "最高价": 0.19,
        "最低价": 0.13,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 7881.0,
        "成交额": 1278.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "106.UWMC_WS"
    },
    {
        "序号": 5878,
        "name": "环球技术学院",
        "最新价": 11.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.84,
        "最高价": 11.97,
        "最低价": 11.84,
        "昨收价": 11.88,
        "总市值": 404805999.0,
        "市盈率": 32.85,
        "成交量": 137882.0,
        "成交额": 1639101.0,
        "振幅": 1.09,
        "换手率": 0.4,
        "symbol": "106.UTI"
    },
    {
        "序号": 5879,
        "name": "Uranium Trading Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.UTC"
    },
    {
        "序号": 5880,
        "name": "美国能源",
        "最新价": 1.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.07,
        "最高价": 1.1,
        "最低价": 1.07,
        "昨收价": 1.09,
        "总市值": 27802161.0,
        "市盈率": -1.94,
        "成交量": 96746.0,
        "成交额": 105443.0,
        "振幅": 2.75,
        "换手率": 0.38,
        "symbol": "105.USEG"
    },
    {
        "序号": 5881,
        "name": "USEE ELECTRONIC COMMERCE LTD",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.USEE"
    },
    {
        "序号": 5882,
        "name": "TKB Critical Technologies 1 Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.USCTW"
    },
    {
        "序号": 5883,
        "name": "TKB Critical Technologies 1 Uni",
        "最新价": 10.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.89,
        "最高价": 10.89,
        "最低价": 10.89,
        "昨收价": 10.89,
        "总市值": 85695980.0,
        "市盈率": 27.3,
        "成交量": 2.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.USCTU"
    },
    {
        "序号": 5884,
        "name": "Upexi Inc",
        "最新价": 1.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.1,
        "最高价": 1.15,
        "最低价": 1.08,
        "昨收价": 1.08,
        "总市值": 21931420.0,
        "市盈率": -1.4,
        "成交量": 81304.0,
        "成交额": 90611.0,
        "振幅": 6.48,
        "换手率": 0.4,
        "symbol": "105.UPXI"
    },
    {
        "序号": 5885,
        "name": "UpHealth Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.UPH_WS"
    },
    {
        "序号": 5886,
        "name": "Xtrackers US Green Infrastructu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.33,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UPGR"
    },
    {
        "序号": 5887,
        "name": "大自然药业",
        "最新价": 1.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.43,
        "最高价": 1.49,
        "最低价": 1.37,
        "昨收价": 1.42,
        "总市值": 5147500.0,
        "市盈率": -0.46,
        "成交量": 29596.0,
        "成交额": 42202.0,
        "振幅": 8.45,
        "换手率": 0.82,
        "symbol": "105.UPC"
    },
    {
        "序号": 5888,
        "name": "Unifoil Holdings Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.UNFL"
    },
    {
        "序号": 5889,
        "name": "联华电子",
        "最新价": 7.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.7,
        "最高价": 7.83,
        "最低价": 7.7,
        "昨收价": 7.8,
        "总市值": 19504827670.0,
        "市盈率": 8.95,
        "成交量": 4567560.0,
        "成交额": 35562987.0,
        "振幅": 1.67,
        "换手率": 0.18,
        "symbol": "106.UMC"
    },
    {
        "序号": 5890,
        "name": "UL Solutions Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ULS"
    },
    {
        "序号": 5891,
        "name": "UK Wisdom Ltd Unit Cons of 1 CL",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UKWIU"
    },
    {
        "序号": 5892,
        "name": "UK Wisdom Ltd Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UKWIR"
    },
    {
        "序号": 5893,
        "name": "UK Wisdom Ltd-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UKWI"
    },
    {
        "序号": 5894,
        "name": "Ucommune International Ltd Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 802.0,
        "成交额": 5.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.UKOMW"
    },
    {
        "序号": 5895,
        "name": "Unique Fabricating Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.17,
        "总市值": 1994635.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.UFAB"
    },
    {
        "序号": 5896,
        "name": "VictoryShares Corporate Bond ET",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.81,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UCRD"
    },
    {
        "序号": 5897,
        "name": "优克联",
        "最新价": 2.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.53,
        "最高价": 2.53,
        "最低价": 2.53,
        "昨收价": 2.53,
        "总市值": 93557257.0,
        "市盈率": 26.07,
        "成交量": 280.0,
        "成交额": 708.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.UCL"
    },
    {
        "序号": 5898,
        "name": "有家保险",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.UBXG"
    },
    {
        "序号": 5899,
        "name": "AgEagle天线系统",
        "最新价": 0.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.12,
        "最高价": 0.12,
        "最低价": 0.12,
        "昨收价": 0.12,
        "总市值": 14325460.0,
        "市盈率": -0.22,
        "成交量": 859673.0,
        "成交额": 101033.0,
        "振幅": 0.0,
        "换手率": 0.72,
        "symbol": "107.UAVS"
    },
    {
        "序号": 5900,
        "name": "时代华纳",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TWX"
    },
    {
        "序号": 5901,
        "name": "2U Inc",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.0,
        "最高价": 1.03,
        "最低价": 0.95,
        "昨收价": 1.0,
        "总市值": 82069164.0,
        "市盈率": -0.29,
        "成交量": 1394420.0,
        "成交额": 1398303.0,
        "振幅": 8.0,
        "换手率": 1.7,
        "symbol": "105.TWOU"
    },
    {
        "序号": 5902,
        "name": "Twelve Seas Investment Co II Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 10317.0,
        "成交额": 259.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TWLVW"
    },
    {
        "序号": 5903,
        "name": "Twelve Seas Investment Co II Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.22,
        "总市值": 132845017.0,
        "市盈率": 37.42,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWLVU"
    },
    {
        "序号": 5904,
        "name": "Twelve Seas Investment Co II-A",
        "最新价": 10.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.55,
        "昨收价": 10.55,
        "总市值": 137134534.0,
        "市盈率": 38.63,
        "成交量": 455.0,
        "成交额": 4800.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.TWLV"
    },
    {
        "序号": 5905,
        "name": "富原集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWG"
    },
    {
        "序号": 5906,
        "name": "T20 Holdings Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWEN"
    },
    {
        "序号": 5907,
        "name": "Galliot Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWCGW"
    },
    {
        "序号": 5908,
        "name": "Galliot Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.0,
        "总市值": 333333340.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWCGU"
    },
    {
        "序号": 5909,
        "name": "Galliot Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TWCG"
    },
    {
        "序号": 5910,
        "name": "Turo Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TURO"
    },
    {
        "序号": 5911,
        "name": "180 Degree Capital Corp",
        "最新价": 3.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.9,
        "最高价": 3.92,
        "最低价": 3.86,
        "昨收价": 3.88,
        "总市值": 38800547.0,
        "市盈率": -2.37,
        "成交量": 7564.0,
        "成交额": 29377.0,
        "振幅": 1.55,
        "换手率": 0.08,
        "symbol": "105.TURN"
    },
    {
        "序号": 5912,
        "name": "Clouty TUNE ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.92,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.TUNE"
    },
    {
        "序号": 5913,
        "name": "脂鲤技术",
        "最新价": 4.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.34,
        "最高价": 4.4,
        "最低价": 4.25,
        "昨收价": 4.31,
        "总市值": 560641236.0,
        "市盈率": 20.19,
        "成交量": 1175185.0,
        "成交额": 5076431.0,
        "振幅": 3.48,
        "换手率": 0.9,
        "symbol": "106.TTI"
    },
    {
        "序号": 5914,
        "name": "Innovator Triple Stacker ETF - ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 28.36,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.TSJA"
    },
    {
        "序号": 5915,
        "name": "TCW Strategic Income Fund",
        "最新价": 4.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.75,
        "最高价": 4.76,
        "最低价": 4.72,
        "昨收价": 4.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 61752.0,
        "成交额": 293219.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "106.TSI"
    },
    {
        "序号": 5916,
        "name": "TRX Gold Corp",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.39,
        "最高价": 0.39,
        "最低价": 0.38,
        "昨收价": 0.39,
        "总市值": 108273874.0,
        "市盈率": 48.12,
        "成交量": 175791.0,
        "成交额": 67418.0,
        "振幅": 2.56,
        "换手率": 0.06,
        "symbol": "107.TRX"
    },
    {
        "序号": 5917,
        "name": "Triton International Ltd Series",
        "最新价": 25.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.02,
        "最高价": 25.25,
        "最低价": 25.02,
        "昨收价": 25.15,
        "总市值": 144612500.0,
        "市盈率": null,
        "成交量": 8937.0,
        "成交额": 224675.0,
        "振幅": 0.91,
        "换手率": 0.16,
        "symbol": "106.TRTN_B"
    },
    {
        "序号": 5918,
        "name": "TortoiseEcofin Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TRTL_WS"
    },
    {
        "序号": 5919,
        "name": "TortoiseEcofin Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.64,
        "总市值": 275466429.0,
        "市盈率": 22.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TRTL_U"
    },
    {
        "序号": 5920,
        "name": "TortoiseEcofin Acquisition Corp",
        "最新价": 10.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.6,
        "最高价": 10.62,
        "最低价": 10.6,
        "昨收价": 10.62,
        "总市值": 274948635.0,
        "市盈率": 22.5,
        "成交量": 365.0,
        "成交额": 3872.0,
        "振幅": 0.19,
        "换手率": 0.0,
        "symbol": "106.TRTL"
    },
    {
        "序号": 5921,
        "name": "Tungray Technologies Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TRSG"
    },
    {
        "序号": 5922,
        "name": "Therapix Biosciences Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TRPXW"
    },
    {
        "序号": 5923,
        "name": "Corner Growth Acquisition Corp ",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 87700.0,
        "成交额": 2211.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.TRONW"
    },
    {
        "序号": 5924,
        "name": "Corner Growth Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.15,
        "总市值": 78795350.0,
        "市盈率": -31.24,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TRONU"
    },
    {
        "序号": 5925,
        "name": "Tremor International Ltd ADR",
        "最新价": 4.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.27,
        "最高价": 4.32,
        "最低价": 4.13,
        "昨收价": 4.27,
        "总市值": 310552965.0,
        "市盈率": -15.8,
        "成交量": 40836.0,
        "成交额": 173522.0,
        "振幅": 4.45,
        "换手率": 0.06,
        "symbol": "105.TRMR"
    },
    {
        "序号": 5926,
        "name": "Troika Media Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 39039.0,
        "成交额": 124.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.TRKAW"
    },
    {
        "序号": 5927,
        "name": "Tristar Acquisition I Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 5601.0,
        "成交额": 168.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.TRIS_WS"
    },
    {
        "序号": 5928,
        "name": "Tristar Acquisition I Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 177329414.0,
        "市盈率": 20.65,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TRIS_U"
    },
    {
        "序号": 5929,
        "name": "Tristar Acquisition I Corp-A",
        "最新价": 10.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.77,
        "昨收价": 10.78,
        "总市值": 176347886.0,
        "市盈率": 20.54,
        "成交量": 16393.0,
        "成交额": 176709.0,
        "振幅": 0.09,
        "换手率": 0.1,
        "symbol": "106.TRIS"
    },
    {
        "序号": 5930,
        "name": "Trinity Place Holdings Inc",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.15,
        "总市值": 5729908.0,
        "市盈率": -0.15,
        "成交量": 155698.0,
        "成交额": 21876.0,
        "振幅": 6.67,
        "换手率": 0.41,
        "symbol": "107.TPHS"
    },
    {
        "序号": 5931,
        "name": "Trio Petroleum Corp",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.31,
        "最高价": 0.33,
        "最低价": 0.3,
        "昨收价": 0.31,
        "总市值": 9484363.0,
        "市盈率": -1.6,
        "成交量": 349934.0,
        "成交额": 106201.0,
        "振幅": 9.68,
        "换手率": 1.14,
        "symbol": "107.TPET"
    },
    {
        "序号": 5932,
        "name": "淘你欢",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TONY"
    },
    {
        "序号": 5933,
        "name": "TOMI Environmental Solutions In",
        "最新价": 0.99,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.98,
        "最高价": 0.99,
        "最低价": 0.95,
        "昨收价": 0.99,
        "总市值": 19625715.0,
        "市盈率": -6.81,
        "成交量": 5981.0,
        "成交额": 5802.0,
        "振幅": 4.04,
        "换手率": 0.03,
        "symbol": "105.TOMZ"
    },
    {
        "序号": 5934,
        "name": "The Oncology Institute Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TOIIW"
    },
    {
        "序号": 5935,
        "name": "Tower One Wireless Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TO"
    },
    {
        "序号": 5936,
        "name": "Tsakos Energy Navigation Ltd",
        "最新价": 19.69,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 19.75,
        "最高价": 19.84,
        "最低价": 19.53,
        "昨收价": 19.69,
        "总市值": 580965323.0,
        "市盈率": 1.57,
        "成交量": 225333.0,
        "成交额": 4432384.0,
        "振幅": 1.57,
        "换手率": 0.76,
        "symbol": "106.TNP"
    },
    {
        "序号": 5937,
        "name": "Tenon Medical Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TNONW"
    },
    {
        "序号": 5938,
        "name": "Tandem Diabetes Care Inc",
        "最新价": 24.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.88,
        "最高价": 24.76,
        "最低价": 23.77,
        "昨收价": 24.01,
        "总市值": 1565684969.0,
        "市盈率": -7.51,
        "成交量": 2068552.0,
        "成交额": 50265659.0,
        "振幅": 4.12,
        "换手率": 3.17,
        "symbol": "105.TNDM"
    },
    {
        "序号": 5939,
        "name": "TMT Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 89540000.0,
        "市盈率": 84.73,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TMTCU"
    },
    {
        "序号": 5940,
        "name": "TMC the metal company Inc Wt",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 20173.0,
        "成交额": 3081.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TMCWW"
    },
    {
        "序号": 5941,
        "name": "Timber Pharmaceuticals Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.34,
        "总市值": 1167130.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.TMBR"
    },
    {
        "序号": 5942,
        "name": "Ten-League International Holdin",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TLIH"
    },
    {
        "序号": 5943,
        "name": "TLGY Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TLGYW"
    },
    {
        "序号": 5944,
        "name": "TLGY Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 143750002.0,
        "市盈率": 24.68,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TLGYU"
    },
    {
        "序号": 5945,
        "name": "拓古恩",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TKE"
    },
    {
        "序号": 5946,
        "name": "TechyBird Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TKBDU"
    },
    {
        "序号": 5947,
        "name": "TechyBird Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TKBDR"
    },
    {
        "序号": 5948,
        "name": "TechyBird Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TKBD"
    },
    {
        "序号": 5949,
        "name": "天睿祥",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.75,
        "最高价": 0.75,
        "最低价": 0.69,
        "昨收价": 0.7,
        "总市值": 2292322.0,
        "市盈率": -0.86,
        "成交量": 20849.0,
        "成交额": 14584.0,
        "振幅": 8.57,
        "换手率": 0.64,
        "symbol": "105.TIRX"
    },
    {
        "序号": 5950,
        "name": "Tingo Group Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.69,
        "总市值": 166947554.0,
        "市盈率": 0.6,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TIO"
    },
    {
        "序号": 5951,
        "name": "Interface Inc",
        "最新价": 11.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.04,
        "最高价": 11.24,
        "最低价": 11.04,
        "昨收价": 11.07,
        "总市值": 643244213.0,
        "市盈率": 1859.09,
        "成交量": 205117.0,
        "成交额": 2278210.0,
        "振幅": 1.81,
        "换手率": 0.35,
        "symbol": "105.TILE"
    },
    {
        "序号": 5952,
        "name": "T1V Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.THNK"
    },
    {
        "序号": 5953,
        "name": "ThermoGenesis Holdings Inc",
        "最新价": 0.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.87,
        "最高价": 0.87,
        "最低价": 0.81,
        "昨收价": 0.87,
        "总市值": 2728758.0,
        "市盈率": -0.19,
        "成交量": 22598.0,
        "成交额": 18853.0,
        "振幅": 6.9,
        "换手率": 0.72,
        "symbol": "105.THMO"
    },
    {
        "序号": 5954,
        "name": "International Tower Hill Mines ",
        "最新价": 0.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.47,
        "最高价": 0.5,
        "最低价": 0.45,
        "昨收价": 0.48,
        "总市值": 94025055.0,
        "市盈率": -26.76,
        "成交量": 56642.0,
        "成交额": 26751.0,
        "振幅": 10.42,
        "换手率": 0.03,
        "symbol": "107.THM"
    },
    {
        "序号": 5955,
        "name": "Thunder Bridge Capital Partners",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.THCPW"
    },
    {
        "序号": 5956,
        "name": "Thunder Bridge Capital Partners",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.3,
        "总市值": 103806881.0,
        "市盈率": 27.28,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.THCPU"
    },
    {
        "序号": 5957,
        "name": "TG Venture Acquisition Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 77.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TGVCW"
    },
    {
        "序号": 5958,
        "name": "TG Venture Acquisition Corp-A U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 41358058.0,
        "市盈率": -17.37,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TGVCU"
    },
    {
        "序号": 5959,
        "name": "TG Venture Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.87,
        "总市值": 41472518.0,
        "市盈率": -17.42,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TGVC"
    },
    {
        "序号": 5960,
        "name": "塔吉特",
        "最新价": 135.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 135.23,
        "最高价": 136.4,
        "最低价": 135.11,
        "昨收价": 135.19,
        "总市值": 62412058742.0,
        "市盈率": 17.18,
        "成交量": 2880821.0,
        "成交额": 390479808.0,
        "振幅": 0.95,
        "换手率": 0.62,
        "symbol": "106.TGT"
    },
    {
        "序号": 5961,
        "name": "Treasure Global Inc",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 5878324.0,
        "市盈率": -0.58,
        "成交量": 1020921.0,
        "成交额": 105836.0,
        "振幅": 9.09,
        "换手率": 1.91,
        "symbol": "105.TGL"
    },
    {
        "序号": 5962,
        "name": "Terra Income Fund 6 Inc Notes",
        "最新价": 23.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.5,
        "最高价": 23.5,
        "最低价": 23.5,
        "昨收价": 23.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 72.0,
        "成交额": 1692.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.TFSA"
    },
    {
        "序号": 5963,
        "name": "21世纪福克斯-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TFCFA"
    },
    {
        "序号": 5964,
        "name": "21世纪福克斯-B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TFCF"
    },
    {
        "序号": 5965,
        "name": "Technology & Telecommunication ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TETEW"
    },
    {
        "序号": 5966,
        "name": "Technology & Telecommunication ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.4,
        "总市值": 66709483.0,
        "市盈率": 46.07,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TETEU"
    },
    {
        "序号": 5967,
        "name": "Technology & Telecommunication ",
        "最新价": 11.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.45,
        "最高价": 11.45,
        "最低价": 11.45,
        "昨收价": 11.45,
        "总市值": 67002068.0,
        "市盈率": 46.27,
        "成交量": 154.0,
        "成交额": 1763.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.TETE"
    },
    {
        "序号": 5968,
        "name": "TenX Keane Acquisition Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.21,
        "总市值": 100228610.0,
        "市盈率": 56.73,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TENKU"
    },
    {
        "序号": 5969,
        "name": "TenX Keane Acquisition Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TENKR"
    },
    {
        "序号": 5970,
        "name": "TenX Keane Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 97456900.0,
        "市盈率": 55.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TENK"
    },
    {
        "序号": 5971,
        "name": "Telomir Pharmaceuticals Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TELO"
    },
    {
        "序号": 5972,
        "name": "Trident Digital Tech Holdings L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TDTH"
    },
    {
        "序号": 5973,
        "name": "Cabana Target Drawdown 5 ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TDSA"
    },
    {
        "序号": 5974,
        "name": "Translational Development Acqui",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TDACW"
    },
    {
        "序号": 5975,
        "name": "Translational Development Acqui",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TDACU"
    },
    {
        "序号": 5976,
        "name": "Translational Development Acqui",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TDAC"
    },
    {
        "序号": 5977,
        "name": "The Container Store Group Inc",
        "最新价": 1.96,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.99,
        "最高价": 2.0,
        "最低价": 1.92,
        "昨收价": 1.96,
        "总市值": 101375377.0,
        "市盈率": -0.46,
        "成交量": 134822.0,
        "成交额": 263005.0,
        "振幅": 4.08,
        "换手率": 0.26,
        "symbol": "106.TCS"
    },
    {
        "序号": 5978,
        "name": "Alaunos Therapeutics Inc",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": 14437623.0,
        "市盈率": -0.4,
        "成交量": 2081242.0,
        "成交额": 122626.0,
        "振幅": 0.0,
        "换手率": 0.86,
        "symbol": "105.TCRT"
    },
    {
        "序号": 5979,
        "name": "Trajectory Alpha Acquisition Co",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 810.0,
        "成交额": 31.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.TCOA_WS"
    },
    {
        "序号": 5980,
        "name": "Trajectory Alpha Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.61,
        "总市值": 113215257.0,
        "市盈率": 37.06,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.TCOA_U"
    },
    {
        "序号": 5981,
        "name": "Trajectory Alpha Acquisition Co",
        "最新价": 10.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.62,
        "最高价": 10.62,
        "最低价": 10.62,
        "昨收价": 10.62,
        "总市值": 113321963.0,
        "市盈率": 37.09,
        "成交量": 87.0,
        "成交额": 923.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.TCOA"
    },
    {
        "序号": 5982,
        "name": "TC BioPharm (Holdings) plc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TCBPW"
    },
    {
        "序号": 5983,
        "name": "Trailblazer Merger Corp I Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TBMCR"
    },
    {
        "序号": 5984,
        "name": "Trailblazer Merger Corp I-A",
        "最新价": 10.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.41,
        "最高价": 10.41,
        "最低价": 10.41,
        "昨收价": 10.41,
        "总市值": 93892995.0,
        "市盈率": 141.79,
        "成交量": 514.0,
        "成交额": 5350.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.TBMC"
    },
    {
        "序号": 5985,
        "name": "ToughBuilt Industries Inc Wt-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TBLTW"
    },
    {
        "序号": 5986,
        "name": "TrueBlue Inc",
        "最新价": 14.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.5,
        "最高价": 14.61,
        "最低价": 14.26,
        "昨收价": 14.49,
        "总市值": 451214920.0,
        "市盈率": -98.58,
        "成交量": 368725.0,
        "成交额": 5309612.0,
        "振幅": 2.42,
        "换手率": 1.18,
        "symbol": "106.TBI"
    },
    {
        "序号": 5987,
        "name": "Thunder Bridge Capital Partners",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 10916.0,
        "成交额": 12.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.TBCPW"
    },
    {
        "序号": 5988,
        "name": "摩森康胜-A",
        "最新价": 63.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 63.59,
        "最高价": 63.59,
        "最低价": 63.59,
        "昨收价": 63.59,
        "总市值": 12944127821.0,
        "市盈率": 50.74,
        "成交量": 11.0,
        "成交额": 699.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.TAP_A"
    },
    {
        "序号": 5989,
        "name": "Talkspace Inc Wt",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.12,
        "最高价": 0.14,
        "最低价": 0.1,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 15604.0,
        "成交额": 1692.0,
        "振幅": 30.77,
        "换手率": null,
        "symbol": "105.TALKW"
    },
    {
        "序号": 5990,
        "name": "SynCardia Systems Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.TAHT"
    },
    {
        "序号": 5991,
        "name": "Sizzle Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.94,
        "总市值": 102361784.0,
        "市盈率": -755.57,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SZZLU"
    },
    {
        "序号": 5992,
        "name": "Pacer CFRA-Stovall Small Cap Se",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SZNZ"
    },
    {
        "序号": 5993,
        "name": "Pacer CFRA-Stovall Large Cap Se",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SZNL"
    },
    {
        "序号": 5994,
        "name": "Pacer CFRA-Stovall Global Seaso",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SZNG"
    },
    {
        "序号": 5995,
        "name": "Syntax Stratified U.S. Total Ma",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 43.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SYUS"
    },
    {
        "序号": 5996,
        "name": "Siyata Mobile Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 23208.0,
        "成交额": 1425.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.SYTAW"
    },
    {
        "序号": 5997,
        "name": "Silynxcom Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SYNX"
    },
    {
        "序号": 5998,
        "name": "Putnam BioRevolution ETF",
        "最新价": 27.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 27.32,
        "最高价": 27.51,
        "最低价": 27.32,
        "昨收价": 27.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 320.0,
        "成交额": 8762.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.SYNB"
    },
    {
        "序号": 5999,
        "name": "Syntax Stratified Total Market ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 42.59,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SYII"
    },
    {
        "序号": 6000,
        "name": "60 Degrees Pharmaceuticals Inc ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SXTPW"
    },
    {
        "序号": 6001,
        "name": "Swvl Holdings Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SWVLW"
    },
    {
        "序号": 6002,
        "name": "Clean Energy Special Situations",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SWSSW"
    },
    {
        "序号": 6003,
        "name": "Clean Energy Special Situations",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.5,
        "总市值": 73622231.0,
        "市盈率": 48.83,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SWSSU"
    },
    {
        "序号": 6004,
        "name": "Clean Energy Special Situations",
        "最新价": 10.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.54,
        "最高价": 10.54,
        "最低价": 10.54,
        "昨收价": 10.54,
        "总市值": 73902696.0,
        "市盈率": 49.02,
        "成交量": 19.0,
        "成交额": 200.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.SWSS"
    },
    {
        "序号": 6005,
        "name": "Stran & Co Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SWAGW"
    },
    {
        "序号": 6006,
        "name": "SaverOne 2014 Ltd Wt",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.24,
        "昨收价": 0.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 227.0,
        "成交额": 54.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SVREW"
    },
    {
        "序号": 6007,
        "name": "7天连锁",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SVN"
    },
    {
        "序号": 6008,
        "name": "Spring Valley Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 332426670.0,
        "市盈率": 35.18,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SVIIU"
    },
    {
        "序号": 6009,
        "name": "Spring Valley Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SVIIR"
    },
    {
        "序号": 6010,
        "name": "科兴生物",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 6.47,
        "总市值": 643779512.0,
        "市盈率": -1.82,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SVA"
    },
    {
        "序号": 6011,
        "name": "旭航集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SUNH"
    },
    {
        "序号": 6012,
        "name": "荣志集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SUGP"
    },
    {
        "序号": 6013,
        "name": "ShoulderUp Technology Acquisiti",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SUAC_WS"
    },
    {
        "序号": 6014,
        "name": "ShoulderUp Technology Acquisiti",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 171352103.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SUAC_U"
    },
    {
        "序号": 6015,
        "name": "ShoulderUp Technology Acquisiti",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.7,
        "总市值": 170713920.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SUAC"
    },
    {
        "序号": 6016,
        "name": "State Street Corp Series D Pfd",
        "最新价": 24.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.94,
        "最高价": 24.97,
        "最低价": 24.93,
        "昨收价": 24.94,
        "总市值": 187050.0,
        "市盈率": null,
        "成交量": 119658.0,
        "成交额": 2984540.0,
        "振幅": 0.16,
        "换手率": 1595.44,
        "symbol": "106.STT_D"
    },
    {
        "序号": 6017,
        "name": "Sharps Technology Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.STSSW"
    },
    {
        "序号": 6018,
        "name": "Streamline Health Solutions Inc",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.33,
        "最高价": 0.33,
        "最低价": 0.3,
        "昨收价": 0.31,
        "总市值": 18257472.0,
        "市盈率": -1.7,
        "成交量": 58581.0,
        "成交额": 17973.0,
        "振幅": 9.68,
        "换手率": 0.1,
        "symbol": "105.STRM"
    },
    {
        "序号": 6019,
        "name": "Sarcos Technology and Robotics ",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 9279.0,
        "成交额": 85.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.STRCW"
    },
    {
        "序号": 6020,
        "name": "Sarcos Technology and Robotics ",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.63,
        "最高价": 0.66,
        "最低价": 0.6,
        "昨收价": 0.64,
        "总市值": 16544532.0,
        "市盈率": -0.1,
        "成交量": 43537.0,
        "成交额": 27362.0,
        "振幅": 9.38,
        "换手率": 0.17,
        "symbol": "105.STRC"
    },
    {
        "序号": 6021,
        "name": "Stellaris Growth Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.STLRW"
    },
    {
        "序号": 6022,
        "name": "Stellaris Growth Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.STLRU"
    },
    {
        "序号": 6023,
        "name": "Stellaris Growth Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.STLR"
    },
    {
        "序号": 6024,
        "name": "Semantix Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 900.0,
        "成交额": 9.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.STIXW"
    },
    {
        "序号": 6025,
        "name": "Staffing 360 Solutions Inc",
        "最新价": 0.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.46,
        "最高价": 0.48,
        "最低价": 0.44,
        "昨收价": 0.44,
        "总市值": 3437364.0,
        "市盈率": -0.19,
        "成交量": 6692.0,
        "成交额": 2983.0,
        "振幅": 9.09,
        "换手率": 0.09,
        "symbol": "105.STAF"
    },
    {
        "序号": 6026,
        "name": "System1 Inc Wt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 3600.0,
        "成交额": 277.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.SST_WS"
    },
    {
        "序号": 6027,
        "name": "Syntax Stratified SmallCap ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 41.75,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SSLY"
    },
    {
        "序号": 6028,
        "name": "South State Corp",
        "最新价": 79.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 79.86,
        "最高价": 81.34,
        "最低价": 79.34,
        "昨收价": 79.86,
        "总市值": 6074571504.0,
        "市盈率": 11.44,
        "成交量": 430125.0,
        "成交额": 34424134.0,
        "振幅": 2.5,
        "换手率": 0.57,
        "symbol": "105.SSB"
    },
    {
        "序号": 6029,
        "name": "Surrozen Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SRZNW"
    },
    {
        "序号": 6030,
        "name": "NXG Cushing Midstream Energy Fu",
        "最新价": 0.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.41,
        "最高价": 0.49,
        "最低价": 0.41,
        "昨收价": 0.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 91318.0,
        "成交额": 41386.0,
        "振幅": 17.78,
        "换手率": null,
        "symbol": "106.SRVr"
    },
    {
        "序号": 6031,
        "name": "SRH U.S. Quality ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.33,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SRHQ"
    },
    {
        "序号": 6032,
        "name": "Sempra Energy Notes",
        "最新价": 23.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.97,
        "最高价": 24.07,
        "最低价": 23.84,
        "昨收价": 23.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 88008.0,
        "成交额": 2106577.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "106.SREA"
    },
    {
        "序号": 6033,
        "name": "Sequoia Vaccines Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SQVI"
    },
    {
        "序号": 6034,
        "name": "SeqLL Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SQLLW"
    },
    {
        "序号": 6035,
        "name": "SeqLL Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 6.6,
        "总市值": 2291256.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SQL"
    },
    {
        "序号": 6036,
        "name": "SP Funds Dow Jones Global Sukuk",
        "最新价": 17.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.76,
        "最高价": 17.86,
        "最低价": 17.72,
        "昨收价": 17.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 16785.0,
        "成交额": 298645.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.SPSK"
    },
    {
        "序号": 6037,
        "name": "Steel Partners Holdings LP",
        "最新价": 39.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 39.65,
        "最高价": 39.65,
        "最低价": 39.65,
        "昨收价": 39.65,
        "总市值": 844877069.0,
        "市盈率": 4.63,
        "成交量": 64.0,
        "成交额": 2537.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.SPLP"
    },
    {
        "序号": 6038,
        "name": "ConvexityShares Daily 1.5x SPIK",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 3.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SPKY"
    },
    {
        "序号": 6039,
        "name": "ConvexityShares 1x SPIKES Futur",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 7.4,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SPKX"
    },
    {
        "序号": 6040,
        "name": "Spark I Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SPKLW"
    },
    {
        "序号": 6041,
        "name": "Spark I Acquisition Corp Unit C",
        "最新价": 10.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.16,
        "最高价": 10.16,
        "最低价": 10.16,
        "昨收价": 10.16,
        "总市值": 166848312.0,
        "市盈率": -99.01,
        "成交量": 231.0,
        "成交额": 2346.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SPKLU"
    },
    {
        "序号": 6042,
        "name": "Spark I Acquisition Corp-A",
        "最新价": 10.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.14,
        "最高价": 10.14,
        "最低价": 10.12,
        "昨收价": 10.12,
        "总市值": 166191429.0,
        "市盈率": -98.62,
        "成交量": 3416.0,
        "成交额": 34626.0,
        "振幅": 0.2,
        "换手率": 0.02,
        "symbol": "105.SPKL"
    },
    {
        "序号": 6043,
        "name": "Sovos Brands Inc",
        "最新价": 21.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 21.96,
        "最高价": 22.01,
        "最低价": 21.93,
        "昨收价": 21.97,
        "总市值": 2228974149.0,
        "市盈率": -158.92,
        "成交量": 546942.0,
        "成交额": 12010557.0,
        "振幅": 0.36,
        "换手率": 0.54,
        "symbol": "105.SOVO"
    },
    {
        "序号": 6044,
        "name": "SOS Hydration Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SOSHW"
    },
    {
        "序号": 6045,
        "name": "SOS Hydration Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SOSH"
    },
    {
        "序号": 6046,
        "name": "Electrameccanica Vehicles Corp",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.38,
        "最高价": 0.39,
        "最低价": 0.38,
        "昨收价": 0.39,
        "总市值": 46523931.0,
        "市盈率": -0.42,
        "成交量": 387429.0,
        "成交额": 149550.0,
        "振幅": 2.56,
        "换手率": 0.32,
        "symbol": "105.SOLO"
    },
    {
        "序号": 6047,
        "name": "Sole Elite Group Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SOLE"
    },
    {
        "序号": 6048,
        "name": "Sotherly Hotels Inc Series C Pf",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.37,
        "总市值": 27420261.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SOHOO"
    },
    {
        "序号": 6049,
        "name": "叁腾科技",
        "最新价": 1.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.91,
        "最高价": 1.95,
        "最低价": 1.82,
        "昨收价": 1.82,
        "总市值": 5105692.0,
        "市盈率": -1.99,
        "成交量": 2648.0,
        "成交额": 4961.0,
        "振幅": 7.14,
        "换手率": 0.09,
        "symbol": "105.SNTG"
    },
    {
        "序号": 6050,
        "name": "Sonida Senior Living Inc",
        "最新价": 8.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.74,
        "最高价": 8.74,
        "最低价": 8.74,
        "昨收价": 8.74,
        "总市值": 71474374.0,
        "市盈率": -3.1,
        "成交量": 500.0,
        "成交额": 4370.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.SNDA"
    },
    {
        "序号": 6051,
        "name": "Stryve Foods Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 14592.0,
        "成交额": 77.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SNAXW"
    },
    {
        "序号": 6052,
        "name": "SMX (Security Matters) PLC Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 10500.0,
        "成交额": 69.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SMXWW"
    },
    {
        "序号": 6053,
        "name": "SolarMax Technology Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SMXT"
    },
    {
        "序号": 6054,
        "name": "Samsara Vision Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SMSA"
    },
    {
        "序号": 6055,
        "name": "Defiance Indxx Junior Semicondu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SMHJ"
    },
    {
        "序号": 6056,
        "name": "Syntax Stratified MidCap ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 38.82,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SMDY"
    },
    {
        "序号": 6057,
        "name": "Sol-Wind Renewable Power LP",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SLWD"
    },
    {
        "序号": 6058,
        "name": "Pacer Salt High truBeta US Mark",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SLT_"
    },
    {
        "序号": 6059,
        "name": "Salarius Pharmaceuticals Inc",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.6,
        "最高价": 0.64,
        "最低价": 0.59,
        "昨收价": 0.63,
        "总市值": 2481213.0,
        "市盈率": -0.14,
        "成交量": 38318.0,
        "成交额": 23299.0,
        "振幅": 7.94,
        "换手率": 0.97,
        "symbol": "105.SLRX"
    },
    {
        "序号": 6060,
        "name": "Selina Hospitality Plc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": null,
        "换手率": null,
        "symbol": "105.SLNAW"
    },
    {
        "序号": 6061,
        "name": "Sol-Gel Technologies Ltd",
        "最新价": 1.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.19,
        "最高价": 1.23,
        "最低价": 1.17,
        "昨收价": 1.17,
        "总市值": 32593415.0,
        "市盈率": -1.16,
        "成交量": 18716.0,
        "成交额": 22331.0,
        "振幅": 5.13,
        "换手率": 0.07,
        "symbol": "105.SLGL"
    },
    {
        "序号": 6062,
        "name": "SomaLogic Inc Wt",
        "最新价": 0.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.14,
        "最高价": 0.14,
        "最低价": 0.14,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 19945.0,
        "成交额": 2796.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SLGCW"
    },
    {
        "序号": 6063,
        "name": "Slam Corp Unit Cons of 1 CL A +",
        "最新价": 10.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.88,
        "最高价": 10.88,
        "最低价": 10.88,
        "昨收价": 10.88,
        "总市值": 432046573.0,
        "市盈率": 46.18,
        "成交量": 1510.0,
        "成交额": 16428.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SLAMU"
    },
    {
        "序号": 6064,
        "name": "Social Leverage Acquisition Cor",
        "最新价": 10.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.28,
        "最高价": 10.28,
        "最低价": 10.28,
        "昨收价": 10.28,
        "总市值": 104672338.0,
        "市盈率": 59.83,
        "成交量": 47.0,
        "成交额": 483.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.SLAC"
    },
    {
        "序号": 6065,
        "name": "UltraShort Nasdaq Cloud Computi",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SKYD"
    },
    {
        "序号": 6066,
        "name": "SK Growth Opportunities Corp-A",
        "最新价": 10.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.84,
        "最高价": 10.84,
        "最低价": 10.84,
        "昨收价": 10.84,
        "总市值": 284008000.0,
        "市盈率": 35.58,
        "成交量": 150013.0,
        "成交额": 1626140.0,
        "振幅": 0.0,
        "换手率": 0.57,
        "symbol": "105.SKGR"
    },
    {
        "序号": 6067,
        "name": "Inverse Cramer Tracker ETF",
        "最新价": 22.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.23,
        "最高价": 22.23,
        "最低价": 22.13,
        "昨收价": 22.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 1278.0,
        "成交额": 28306.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.SJIM"
    },
    {
        "序号": 6068,
        "name": "SolarJuice Co Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SJA"
    },
    {
        "序号": 6069,
        "name": "尚高生命科学",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 5602192.0,
        "市盈率": -1.01,
        "成交量": 263608.0,
        "成交额": 28178.0,
        "振幅": 9.09,
        "换手率": 0.52,
        "symbol": "105.SISI"
    },
    {
        "序号": 6070,
        "name": "新浪",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 43.26,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SINA"
    },
    {
        "序号": 6071,
        "name": "Grupo Simec SAB de CV ADR",
        "最新价": 29.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 29.75,
        "最高价": 29.75,
        "最低价": 29.75,
        "昨收价": 29.75,
        "总市值": 4579456056.0,
        "市盈率": 19.21,
        "成交量": 205.0,
        "成交额": 6098.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "107.SIM"
    },
    {
        "序号": 6072,
        "name": "Sidus Space Inc-A",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": 4393396.0,
        "市盈率": -0.29,
        "成交量": 7178827.0,
        "成交额": 361000.0,
        "振幅": 0.0,
        "换手率": 8.17,
        "symbol": "105.SIDU"
    },
    {
        "序号": 6073,
        "name": "短期国债ETF-iShares",
        "最新价": 110.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 110.25,
        "最高价": 110.25,
        "最低价": 110.24,
        "昨收价": 110.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 1789596.0,
        "成交额": 197291256.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "105.SHV"
    },
    {
        "序号": 6074,
        "name": "Syntax Stratified U.S. Total Ma",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SHUS"
    },
    {
        "序号": 6075,
        "name": "SHUAA Partners Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SHUAW"
    },
    {
        "序号": 6076,
        "name": "SHUAA Partners Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SHUAU"
    },
    {
        "序号": 6077,
        "name": "SHUAA Partners Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.96,
        "总市值": 57886468.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SHUA"
    },
    {
        "序号": 6078,
        "name": "Shapeways Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 25840.0,
        "成交额": 168.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SHPWW"
    },
    {
        "序号": 6079,
        "name": "Shuttle Pharmaceuticals Holding",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.5,
        "最高价": 0.52,
        "最低价": 0.49,
        "昨收价": 0.5,
        "总市值": 7992063.0,
        "市盈率": -1.4,
        "成交量": 49138.0,
        "成交额": 24873.0,
        "振幅": 6.0,
        "换手率": 0.31,
        "symbol": "105.SHPH"
    },
    {
        "序号": 6080,
        "name": "SHF Holdings Inc Wt",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.06,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 103674.0,
        "成交额": 6784.0,
        "振幅": 14.29,
        "换手率": null,
        "symbol": "105.SHFSW"
    },
    {
        "序号": 6081,
        "name": "Spree Acquisition Corp 1 Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SHAP_WS"
    },
    {
        "序号": 6082,
        "name": "Spree Acquisition Corp 1 Ltd Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.95,
        "总市值": 284105579.0,
        "市盈率": 164.7,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SHAP_U"
    },
    {
        "序号": 6083,
        "name": "Spree Acquisition Corp 1 Ltd-A",
        "最新价": 10.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.92,
        "最高价": 10.93,
        "最低价": 10.92,
        "昨收价": 10.92,
        "总市值": 283327208.0,
        "市盈率": 164.25,
        "成交量": 1483711.0,
        "成交额": 16202733.0,
        "振幅": 0.09,
        "换手率": 5.72,
        "symbol": "106.SHAP"
    },
    {
        "序号": 6084,
        "name": "Sirius International Insurance ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SGRP_W"
    },
    {
        "序号": 6085,
        "name": "Seaport Global Acquisition II C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SGIIW"
    },
    {
        "序号": 6086,
        "name": "Seaport Global Acquisition II C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.82,
        "总市值": 75550423.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SGIIU"
    },
    {
        "序号": 6087,
        "name": "Seaport Global Acquisition II C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.81,
        "总市值": 75480598.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SGII"
    },
    {
        "序号": 6088,
        "name": "星辉云印",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SFHG"
    },
    {
        "序号": 6089,
        "name": "安科投资",
        "最新价": 0.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.98,
        "最高价": 1.0,
        "最低价": 0.97,
        "昨收价": 0.98,
        "总市值": 16244106.0,
        "市盈率": -1.58,
        "成交量": 45886.0,
        "成交额": 45074.0,
        "振幅": 3.06,
        "换手率": 0.28,
        "symbol": "105.SFE"
    },
    {
        "序号": 6090,
        "name": "SES AI Corp Wt",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.13,
        "最高价": 0.13,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 16.0,
        "成交额": 2.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.SES_WS"
    },
    {
        "序号": 6091,
        "name": "Sprott ESG Gold ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 38.97,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SESG"
    },
    {
        "序号": 6092,
        "name": "SEP Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SEPAW"
    },
    {
        "序号": 6093,
        "name": "SEP Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.75,
        "总市值": 56692682.0,
        "市盈率": -56.25,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SEPAU"
    },
    {
        "序号": 6094,
        "name": "SEP Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.55,
        "总市值": 61344389.0,
        "市盈率": -60.86,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SEPA"
    },
    {
        "序号": 6095,
        "name": "Senseonics Holdings Inc",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.64,
        "最高价": 0.66,
        "最低价": 0.62,
        "昨收价": 0.63,
        "总市值": 332817285.0,
        "市盈率": -10.52,
        "成交量": 1422775.0,
        "成交额": 917254.0,
        "振幅": 6.35,
        "换手率": 0.27,
        "symbol": "107.SENS"
    },
    {
        "序号": 6096,
        "name": "Seneca Foods Corp-B",
        "最新价": 51.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 51.4,
        "最高价": 51.4,
        "最低价": 51.4,
        "昨收价": 51.4,
        "总市值": 367410798.0,
        "市盈率": 6.14,
        "成交量": 87.0,
        "成交额": 4471.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.SENEB"
    },
    {
        "序号": 6097,
        "name": "SDCL EDGE Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 64245.0,
        "成交额": 1927.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.SEDA_WS"
    },
    {
        "序号": 6098,
        "name": "SDCL EDGE Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 197035905.0,
        "市盈率": 76.8,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SEDA_U"
    },
    {
        "序号": 6099,
        "name": "SDCL EDGE Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.76,
        "总市值": 195581765.0,
        "市盈率": 76.24,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SEDA"
    },
    {
        "序号": 6100,
        "name": "寺库",
        "最新价": 0.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.46,
        "最高价": 0.46,
        "最低价": 0.43,
        "昨收价": 0.44,
        "总市值": 3108713.0,
        "市盈率": -0.02,
        "成交量": 56994.0,
        "成交额": 24776.0,
        "振幅": 6.82,
        "换手率": 0.81,
        "symbol": "105.SECO"
    },
    {
        "序号": 6101,
        "name": "Sound Total Return ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SDTR"
    },
    {
        "序号": 6102,
        "name": "Sadot Group Inc",
        "最新价": 0.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.42,
        "最高价": 0.45,
        "最低价": 0.4,
        "昨收价": 0.41,
        "总市值": 19173378.0,
        "市盈率": -2.27,
        "成交量": 181871.0,
        "成交额": 75109.0,
        "振幅": 12.2,
        "换手率": 0.39,
        "symbol": "105.SDOT"
    },
    {
        "序号": 6103,
        "name": "Sound Fixed Income ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SDFI"
    },
    {
        "序号": 6104,
        "name": "Sound Enhanced Equity Income ET",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SDEE"
    },
    {
        "序号": 6105,
        "name": "盛大科技(权证)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SDAWW"
    },
    {
        "序号": 6106,
        "name": "Sol Global Investments Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SCYB_"
    },
    {
        "序号": 6107,
        "name": "374Water Inc",
        "最新价": 1.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.09,
        "最高价": 1.11,
        "最低价": 1.03,
        "昨收价": 1.09,
        "总市值": 144607147.0,
        "市盈率": -21.54,
        "成交量": 176645.0,
        "成交额": 192530.0,
        "振幅": 7.34,
        "换手率": 0.13,
        "symbol": "105.SCWO"
    },
    {
        "序号": 6108,
        "name": "Sculptor Capital Management Gro",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.72,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SCU"
    },
    {
        "序号": 6109,
        "name": "Societal CDMO Inc",
        "最新价": 0.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.36,
        "最高价": 0.36,
        "最低价": 0.34,
        "昨收价": 0.35,
        "总市值": 36675878.0,
        "市盈率": -1.69,
        "成交量": 156621.0,
        "成交额": 54976.0,
        "振幅": 5.71,
        "换手率": 0.15,
        "symbol": "105.SCTL"
    },
    {
        "序号": 6110,
        "name": "Screaming Eagle Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 993750000.0,
        "市盈率": 30.75,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SCRMU"
    },
    {
        "序号": 6111,
        "name": "三彩家",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SCIT"
    },
    {
        "序号": 6112,
        "name": "Global X Scientific Beta Emergi",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SCIM"
    },
    {
        "序号": 6113,
        "name": "ETRACS 2x Leveraged US Dividend",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.78,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SCDL"
    },
    {
        "序号": 6114,
        "name": "Shimmick Construction Co Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SCCI"
    },
    {
        "序号": 6115,
        "name": "Sachem Capital Corp Notes",
        "最新价": 21.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 21.8,
        "最高价": 21.8,
        "最低价": 21.8,
        "昨收价": 21.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 109.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SCCF"
    },
    {
        "序号": 6116,
        "name": "SilverBox Corp III Wt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 2331.0,
        "成交额": 202.0,
        "振幅": 12.5,
        "换手率": null,
        "symbol": "106.SBXC_WS"
    },
    {
        "序号": 6117,
        "name": "SilverBox Corp III Unit Cons of",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.37,
        "总市值": 178882500.0,
        "市盈率": 77.68,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.SBXC_U"
    },
    {
        "序号": 6118,
        "name": "Sterling Bancorp Inc",
        "最新价": 5.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.7,
        "最高价": 5.73,
        "最低价": 5.69,
        "昨收价": 5.7,
        "总市值": 296813997.0,
        "市盈率": -18.46,
        "成交量": 14451.0,
        "成交额": 82398.0,
        "振幅": 0.7,
        "换手率": 0.03,
        "symbol": "105.SBT"
    },
    {
        "序号": 6119,
        "name": "Sunshine Biopharma Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SBFMW"
    },
    {
        "序号": 6120,
        "name": "Splash Beverage Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SBEV_WS"
    },
    {
        "序号": 6121,
        "name": "Splash Beverage Group Inc",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.65,
        "最高价": 0.67,
        "最低价": 0.64,
        "昨收价": 0.64,
        "总市值": 28043377.0,
        "市盈率": -1.42,
        "成交量": 43854.0,
        "成交额": 28667.0,
        "振幅": 4.69,
        "换手率": 0.1,
        "symbol": "107.SBEV"
    },
    {
        "序号": 6122,
        "name": "Saratoga Investment Corp Notes",
        "最新价": 25.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.09,
        "最高价": 25.09,
        "最低价": 25.09,
        "昨收价": 25.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 1197.0,
        "成交额": 30032.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.SAY"
    },
    {
        "序号": 6123,
        "name": "SatixFy Communications Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SATX_WS_A"
    },
    {
        "序号": 6124,
        "name": "Saratoga Investment Corp Notes",
        "最新价": 23.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.59,
        "最高价": 23.59,
        "最低价": 23.5,
        "昨收价": 23.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 2219.0,
        "成交额": 52308.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "106.SAT"
    },
    {
        "序号": 6125,
        "name": "S&W Seed Co",
        "最新价": 0.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.58,
        "最高价": 0.58,
        "最低价": 0.52,
        "昨收价": 0.55,
        "总市值": 23671574.0,
        "市盈率": 1.83,
        "成交量": 80867.0,
        "成交额": 44303.0,
        "振幅": 10.91,
        "换手率": 0.19,
        "symbol": "105.SANW"
    },
    {
        "序号": 6126,
        "name": "Subversive Mental Health ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.62,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.SANE"
    },
    {
        "序号": 6127,
        "name": "Salem Media Group Inc-A",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.58,
        "最高价": 0.59,
        "最低价": 0.57,
        "昨收价": 0.58,
        "总市值": 15785736.0,
        "市盈率": -0.35,
        "成交量": 25278.0,
        "成交额": 14723.0,
        "振幅": 3.45,
        "换手率": 0.09,
        "symbol": "105.SALM"
    },
    {
        "序号": 6128,
        "name": "SAI TECH Global Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SAITW"
    },
    {
        "序号": 6129,
        "name": "Sagaliam Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.05,
        "总市值": 545277024.0,
        "市盈率": -159.52,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SAGAU"
    },
    {
        "序号": 6130,
        "name": "Sagaliam Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SAGAR"
    },
    {
        "序号": 6131,
        "name": "SAG Holdings Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.SAG"
    },
    {
        "序号": 6132,
        "name": "Sachem Capital Corp Pfd",
        "最新价": 19.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 20.0,
        "最高价": 20.1,
        "最低价": 19.75,
        "昨收价": 19.8,
        "总市值": 39520800.0,
        "市盈率": null,
        "成交量": 3951.0,
        "成交额": 78931.0,
        "振幅": 1.77,
        "换手率": 0.2,
        "symbol": "107.SACH_A"
    },
    {
        "序号": 6133,
        "name": "SAB Biotherapeutics Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 46569.0,
        "成交额": 2330.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.SABSW"
    },
    {
        "序号": 6134,
        "name": "软云",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RYET"
    },
    {
        "序号": 6135,
        "name": "Ryde Group Ltd-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RYDE"
    },
    {
        "序号": 6136,
        "name": "Pacer Benchmark Healthcare Real",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RXRE"
    },
    {
        "序号": 6137,
        "name": "Redwoods Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RWODW"
    },
    {
        "序号": 6138,
        "name": "Redwoods Acquisition Corp Unit ",
        "最新价": 10.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.8,
        "昨收价": 10.8,
        "总市值": 55784095.0,
        "市盈率": 28.27,
        "成交量": 98.0,
        "成交额": 1058.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RWODU"
    },
    {
        "序号": 6139,
        "name": "Redwoods Acquisition Corp Rt",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 15.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RWODR"
    },
    {
        "序号": 6140,
        "name": "Redwoods Acquisition Corp",
        "最新价": 10.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.7,
        "最高价": 10.7,
        "最低价": 10.7,
        "昨收价": 10.7,
        "总市值": 55267576.0,
        "市盈率": 28.01,
        "成交量": 2.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.RWOD"
    },
    {
        "序号": 6141,
        "name": "Runway Growth Finance Corp Note",
        "最新价": 24.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.96,
        "最高价": 24.96,
        "最低价": 24.9,
        "昨收价": 24.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 3409.0,
        "成交额": 85048.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "105.RWAYZ"
    },
    {
        "序号": 6142,
        "name": "Revolution Medicines Inc Wt",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.33,
        "最高价": 0.33,
        "最低价": 0.33,
        "昨收价": 0.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 135645.0,
        "成交额": 44762.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RVMDW"
    },
    {
        "序号": 6143,
        "name": "Strategy Shares Drawbridge Dyna",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RULEWI"
    },
    {
        "序号": 6144,
        "name": "零售ETF-VanEck Vectors",
        "最新价": 182.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 181.39,
        "最高价": 182.41,
        "最低价": 181.39,
        "昨收价": 182.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 4184.0,
        "成交额": 761429.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.RTH"
    },
    {
        "序号": 6145,
        "name": "Reservoir Media Inc Wt",
        "最新价": 1.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.25,
        "最高价": 1.25,
        "最低价": 1.25,
        "昨收价": 1.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 12.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RSVRW"
    },
    {
        "序号": 6146,
        "name": "Invesco S&P 500 Equal Weight Re",
        "最新价": 31.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 31.21,
        "最高价": 31.35,
        "最低价": 30.97,
        "昨收价": 31.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 20381.0,
        "成交额": 634630.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.RSPR"
    },
    {
        "序号": 6147,
        "name": "RiverNorth Capital and Income F",
        "最新价": 15.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.68,
        "最高价": 15.68,
        "最低价": 15.6,
        "昨收价": 15.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 2801.0,
        "成交额": 43800.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "106.RSF"
    },
    {
        "序号": 6148,
        "name": "Rigel Resource Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.RRAC_WS"
    },
    {
        "序号": 6149,
        "name": "Rigel Resource Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.99,
        "总市值": 352449663.0,
        "市盈率": 34.69,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.RRAC_U"
    },
    {
        "序号": 6150,
        "name": "Rigel Resource Acquisition Corp",
        "最新价": 10.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.98,
        "最高价": 10.98,
        "最低价": 10.98,
        "昨收价": 10.98,
        "总市值": 352128962.0,
        "市盈率": 34.66,
        "成交量": 50695.0,
        "成交额": 556379.0,
        "振幅": 0.0,
        "换手率": 0.16,
        "symbol": "106.RRAC"
    },
    {
        "序号": 6151,
        "name": "Regents Park Hedged Market Stra",
        "最新价": 9.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.11,
        "最高价": 9.14,
        "最低价": 9.03,
        "昨收价": 9.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 25880.0,
        "成交额": 234981.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.RPHS"
    },
    {
        "序号": 6152,
        "name": "Reneo Pharmaceuticals Inc",
        "最新价": 7.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.0,
        "最高价": 7.19,
        "最低价": 6.82,
        "昨收价": 7.0,
        "总市值": 233182509.0,
        "市盈率": -3.46,
        "成交量": 265515.0,
        "成交额": 1863116.0,
        "振幅": 5.29,
        "换手率": 0.8,
        "symbol": "105.RPHM"
    },
    {
        "序号": 6153,
        "name": "新瑞鹏",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RPET"
    },
    {
        "序号": 6154,
        "name": "Ross Acquisition Corp II Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.92,
        "总市值": 149233790.0,
        "市盈率": 30.11,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ROSS_U"
    },
    {
        "序号": 6155,
        "name": "Ross Acquisition Corp II-A",
        "最新价": 10.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.87,
        "最高价": 10.87,
        "最低价": 10.87,
        "昨收价": 10.87,
        "总市值": 148550485.0,
        "市盈率": 29.97,
        "成交量": 9979.0,
        "成交额": 108471.0,
        "振幅": 0.0,
        "换手率": 0.07,
        "symbol": "106.ROSS"
    },
    {
        "序号": 6156,
        "name": "Rose Hill Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ROSEW"
    },
    {
        "序号": 6157,
        "name": "Rose Hill Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.74,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ROSEU"
    },
    {
        "序号": 6158,
        "name": "Rose Hill Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.66,
        "总市值": 61659747.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ROSE"
    },
    {
        "序号": 6159,
        "name": "罗马绿色金融",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ROMA"
    },
    {
        "序号": 6160,
        "name": "RiskOn International Inc",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.17,
        "昨收价": 0.19,
        "总市值": 675497.0,
        "市盈率": -0.01,
        "成交量": 3184813.0,
        "成交额": 578818.0,
        "振幅": 15.79,
        "换手率": 89.58,
        "symbol": "105.ROI"
    },
    {
        "序号": 6161,
        "name": "Roth CH Acquisition V Co Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 103877.0,
        "成交额": 4926.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "105.ROCLW"
    },
    {
        "序号": 6162,
        "name": "Roth CH Acquisition V Co Unit C",
        "最新价": 10.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.81,
        "最高价": 10.81,
        "最低价": 10.81,
        "昨收价": 10.81,
        "总市值": 63206200.0,
        "市盈率": 88.31,
        "成交量": 5.0,
        "成交额": 54.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ROCLU"
    },
    {
        "序号": 6163,
        "name": "Roth CH Acquisition V Co",
        "最新价": 10.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.64,
        "总市值": 62212208.0,
        "市盈率": 86.92,
        "成交量": 3.0,
        "成交额": 31.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ROCL"
    },
    {
        "序号": 6164,
        "name": "Impact Shares Climate Risk Rein",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ROAR"
    },
    {
        "序号": 6165,
        "name": "RenovoRx Inc",
        "最新价": 0.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.59,
        "最高价": 0.62,
        "最低价": 0.58,
        "昨收价": 0.62,
        "总市值": 6630020.0,
        "市盈率": -0.72,
        "成交量": 50053.0,
        "成交额": 30166.0,
        "振幅": 6.45,
        "换手率": 0.47,
        "symbol": "105.RNXT"
    },
    {
        "序号": 6166,
        "name": "RiverNorth Capital and Income F",
        "最新价": 24.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.9,
        "最高价": 24.9,
        "最低价": 24.9,
        "昨收价": 24.9,
        "总市值": 41234400.0,
        "市盈率": null,
        "成交量": 1429.0,
        "成交额": 35581.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "106.RMPL_"
    },
    {
        "序号": 6167,
        "name": "Reticulate Micro Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RMIC"
    },
    {
        "序号": 6168,
        "name": "RMG Acquisition Corp III Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.57,
        "总市值": 134352923.0,
        "市盈率": -24.22,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RMGCU"
    },
    {
        "序号": 6169,
        "name": "RMG Acquisition Corp III-A",
        "最新价": 10.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.5,
        "最高价": 10.5,
        "最低价": 10.5,
        "昨收价": 10.5,
        "总市值": 133463169.0,
        "市盈率": -24.06,
        "成交量": 168.0,
        "成交额": 1764.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.RMGC"
    },
    {
        "序号": 6170,
        "name": "Royalty Management Holding Corp",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 900.0,
        "成交额": 42.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RMCOW"
    },
    {
        "序号": 6171,
        "name": "The RoyaLand Co Ltd-B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RLND"
    },
    {
        "序号": 6172,
        "name": "ReElement Technologies Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RLMT"
    },
    {
        "序号": 6173,
        "name": "Relmada Therapeutics Inc",
        "最新价": 2.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.56,
        "最高价": 2.57,
        "最低价": 2.53,
        "昨收价": 2.56,
        "总市值": 77053960.0,
        "市盈率": -0.69,
        "成交量": 94538.0,
        "成交额": 241329.0,
        "振幅": 1.56,
        "换手率": 0.31,
        "symbol": "105.RLMD"
    },
    {
        "序号": 6174,
        "name": "RiverNorth Opportunities Fund",
        "最新价": 10.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.83,
        "最高价": 10.85,
        "最低价": 10.83,
        "昨收价": 10.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 76036.0,
        "成交额": 823880.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "106.RIV"
    },
    {
        "序号": 6175,
        "name": "Rithm Capital Corp Series B Pfd",
        "最新价": 22.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.81,
        "最高价": 22.96,
        "最低价": 22.81,
        "昨收价": 22.95,
        "总市值": 259335000.0,
        "市盈率": null,
        "成交量": 21190.0,
        "成交额": 484375.0,
        "振幅": 0.65,
        "换手率": 0.19,
        "symbol": "106.RITM_B"
    },
    {
        "序号": 6176,
        "name": "RMR Real Estate Income Fund",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RIF"
    },
    {
        "序号": 6177,
        "name": "罗致恒富",
        "最新价": 83.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 83.34,
        "最高价": 83.74,
        "最低价": 82.33,
        "昨收价": 83.09,
        "总市值": 8798811894.0,
        "市盈率": 18.66,
        "成交量": 504112.0,
        "成交额": 41941214.0,
        "振幅": 1.7,
        "换手率": 0.48,
        "symbol": "106.RHI"
    },
    {
        "序号": 6178,
        "name": "Regional Health Properties Inc ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.48,
        "总市值": 268446.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RHE_A"
    },
    {
        "序号": 6179,
        "name": "Rigetti Computing Inc Wt",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.17,
        "最高价": 0.18,
        "最低价": 0.17,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 6425.0,
        "成交额": 1093.0,
        "振幅": 5.88,
        "换手率": null,
        "symbol": "105.RGTIW"
    },
    {
        "序号": 6180,
        "name": "RF Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RFACW"
    },
    {
        "序号": 6181,
        "name": "RF Acquisition Corp Unit Cons o",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 77145710.0,
        "市盈率": 233.68,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RFACU"
    },
    {
        "序号": 6182,
        "name": "RF Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RFACR"
    },
    {
        "序号": 6183,
        "name": "RF Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.67,
        "总市值": 76642898.0,
        "市盈率": 232.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RFAC"
    },
    {
        "序号": 6184,
        "name": "Kelly Residential & Apartment R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.7,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RESI"
    },
    {
        "序号": 6185,
        "name": "Rent the Runway Inc-A",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.65,
        "最高价": 0.67,
        "最低价": 0.61,
        "昨收价": 0.63,
        "总市值": 44302656.0,
        "市盈率": -0.39,
        "成交量": 747543.0,
        "成交额": 481198.0,
        "振幅": 9.52,
        "换手率": 1.06,
        "symbol": "105.RENT"
    },
    {
        "序号": 6186,
        "name": "Cartesian Growth Corp II Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RENEW"
    },
    {
        "序号": 6187,
        "name": "Cartesian Growth Corp II Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.93,
        "总市值": 314237500.0,
        "市盈率": 36.01,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RENEU"
    },
    {
        "序号": 6188,
        "name": "RELX",
        "最新价": 38.96,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 38.7,
        "最高价": 38.98,
        "最低价": 38.68,
        "昨收价": 38.96,
        "总市值": 73532783437.0,
        "市盈率": 35.32,
        "成交量": 670841.0,
        "成交额": 26082123.0,
        "振幅": 0.77,
        "换手率": 0.04,
        "symbol": "106.RELX"
    },
    {
        "序号": 6189,
        "name": "Reliance Global Group Inc Wt-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RELIW"
    },
    {
        "序号": 6190,
        "name": "Rekor Systems Inc",
        "最新价": 2.69,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.58,
        "最高价": 2.73,
        "最低价": 2.58,
        "昨收价": 2.69,
        "总市值": 186005680.0,
        "市盈率": -4.47,
        "成交量": 876515.0,
        "成交额": 2340938.0,
        "振幅": 5.58,
        "换手率": 1.27,
        "symbol": "105.REKR"
    },
    {
        "序号": 6191,
        "name": "Research Frontiers Inc",
        "最新价": 1.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.0,
        "最高价": 1.03,
        "最低价": 0.99,
        "昨收价": 1.01,
        "总市值": 33844380.0,
        "市盈率": -15.08,
        "成交量": 42146.0,
        "成交额": 41852.0,
        "振幅": 3.96,
        "换手率": 0.13,
        "symbol": "105.REFR"
    },
    {
        "序号": 6192,
        "name": "Roadzen Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 84830.0,
        "成交额": 3393.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RDZNW"
    },
    {
        "序号": 6193,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDXXW"
    },
    {
        "序号": 6194,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDXXU"
    },
    {
        "序号": 6195,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDXX"
    },
    {
        "序号": 6196,
        "name": "Redwire Corp Wt",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.4,
        "最高价": 0.4,
        "最低价": 0.4,
        "昨收价": 0.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1003.0,
        "成交额": 401.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.RDW_WS"
    },
    {
        "序号": 6197,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDTXW"
    },
    {
        "序号": 6198,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDTXU"
    },
    {
        "序号": 6199,
        "name": "Roman DBDR Tech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RDTX"
    },
    {
        "序号": 6200,
        "name": "Recruiter.com Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RCRTW"
    },
    {
        "序号": 6201,
        "name": "RCF Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 204532309.0,
        "市盈率": 19.78,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.RCFA_U"
    },
    {
        "序号": 6202,
        "name": "Revelstone Capital Acquisition ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.69,
        "总市值": 81723115.0,
        "市盈率": 51.66,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RCACU"
    },
    {
        "序号": 6203,
        "name": "Vicarious Surgical Inc Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 10800.0,
        "成交额": 393.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "106.RBOT_WS"
    },
    {
        "序号": 6204,
        "name": "雷亚电子",
        "最新价": 0.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.74,
        "最高价": 0.75,
        "最低价": 0.63,
        "昨收价": 0.74,
        "总市值": 9213000.0,
        "市盈率": 5.23,
        "成交量": 5164.0,
        "成交额": 3805.0,
        "振幅": 16.22,
        "换手率": 0.04,
        "symbol": "105.RAYA"
    },
    {
        "序号": 6205,
        "name": "雷特控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RAY"
    },
    {
        "序号": 6206,
        "name": "Real Asset Strategies ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.RASF"
    },
    {
        "序号": 6207,
        "name": "FreightCar America Inc",
        "最新价": 2.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.33,
        "最高价": 2.36,
        "最低价": 2.31,
        "昨收价": 2.36,
        "总市值": 42252111.0,
        "市盈率": -1.39,
        "成交量": 28007.0,
        "成交额": 65275.0,
        "振幅": 2.12,
        "换手率": 0.16,
        "symbol": "105.RAIL"
    },
    {
        "序号": 6208,
        "name": "Relativity Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RACYW"
    },
    {
        "序号": 6209,
        "name": "Relativity Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.99,
        "总市值": 57166327.0,
        "市盈率": -47.97,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RACYU"
    },
    {
        "序号": 6210,
        "name": "Relativity Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.28,
        "总市值": 54041763.0,
        "市盈率": -45.35,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.RACY"
    },
    {
        "序号": 6211,
        "name": "ETRACS 2x Leveraged MSCI US Qua",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 32.42,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.QULL"
    },
    {
        "序号": 6212,
        "name": "Simplify Growth Equity Plus Ups",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QQU"
    },
    {
        "序号": 6213,
        "name": "青青草元",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QQCY"
    },
    {
        "序号": 6214,
        "name": "Qomolangma Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QOMOW"
    },
    {
        "序号": 6215,
        "name": "Qomolangma Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 38402320.0,
        "市盈率": 367.12,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QOMOU"
    },
    {
        "序号": 6216,
        "name": "Qomolangma Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QOMOR"
    },
    {
        "序号": 6217,
        "name": "Qomolangma Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.67,
        "总市值": 38152025.0,
        "市盈率": 364.73,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QOMO"
    },
    {
        "序号": 6218,
        "name": "QMMM Holdings Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QMMM"
    },
    {
        "序号": 6219,
        "name": "QMIS TBS Capital Group Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QMIX"
    },
    {
        "序号": 6220,
        "name": "Qiwi plc ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 5.67,
        "总市值": 355582568.0,
        "市盈率": 2.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QIWI"
    },
    {
        "序号": 6221,
        "name": "沁弘",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.QHI"
    },
    {
        "序号": 6222,
        "name": "Quetta Acquisition Corp Unit Co",
        "最新价": 10.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.2,
        "最高价": 10.2,
        "最低价": 10.2,
        "昨收价": 10.2,
        "总市值": 91259859.0,
        "市盈率": null,
        "成交量": 81.0,
        "成交额": 826.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.QETAU"
    },
    {
        "序号": 6223,
        "name": "Quadro Acquisition One Corp Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 504.0,
        "成交额": 25.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.QDROW"
    },
    {
        "序号": 6224,
        "name": "Quadro Acquisition One Corp-A",
        "最新价": 10.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.75,
        "最高价": 10.75,
        "最低价": 10.75,
        "昨收价": 10.75,
        "总市值": 94580145.0,
        "市盈率": 32.81,
        "成交量": 14.0,
        "成交额": 150.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.QDRO"
    },
    {
        "序号": 6225,
        "name": "D-Wave Quantum Inc Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 37494.0,
        "成交额": 3288.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.QBTS_WS"
    },
    {
        "序号": 6226,
        "name": "Paramount Gold Nevada Corp",
        "最新价": 0.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.35,
        "最高价": 0.38,
        "最低价": 0.35,
        "昨收价": 0.36,
        "总市值": 21239998.0,
        "市盈率": -3.18,
        "成交量": 107736.0,
        "成交额": 39041.0,
        "振幅": 8.33,
        "换手率": 0.18,
        "symbol": "107.PZG"
    },
    {
        "序号": 6227,
        "name": "PPLUS Trust Certificates Series",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PYT"
    },
    {
        "序号": 6228,
        "name": "PyroGenesis Canada Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.38,
        "总市值": 67974550.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PYR"
    },
    {
        "序号": 6229,
        "name": "PIMCO New York Municipal Income",
        "最新价": 5.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.82,
        "最高价": 5.85,
        "最低价": 5.78,
        "昨收价": 5.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 63719.0,
        "成交额": 369629.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "106.PYN"
    },
    {
        "序号": 6230,
        "name": "Pyxis Tankers Inc Wt",
        "最新价": 0.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.9,
        "最高价": 0.9,
        "最低价": 0.9,
        "昨收价": 0.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PXSAW"
    },
    {
        "序号": 6231,
        "name": "Pyxis Tankers Inc Series A Pfd",
        "最新价": 24.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.15,
        "最高价": 24.15,
        "最低价": 24.15,
        "昨收价": 24.15,
        "总市值": 9752519.0,
        "市盈率": null,
        "成交量": 188.0,
        "成交额": 4540.0,
        "振幅": 0.0,
        "换手率": 0.05,
        "symbol": "105.PXSAP"
    },
    {
        "序号": 6232,
        "name": "Power REIT Series A Pfd",
        "最新价": 3.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.3,
        "最高价": 3.3,
        "最低价": 3.3,
        "昨收价": 3.3,
        "总市值": 1111915.0,
        "市盈率": null,
        "成交量": 200.0,
        "成交额": 660.0,
        "振幅": 0.0,
        "换手率": 0.06,
        "symbol": "107.PW_A"
    },
    {
        "序号": 6233,
        "name": "PowerUp Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PWUPW"
    },
    {
        "序号": 6234,
        "name": "PowerUp Acquisition Corp-A",
        "最新价": 10.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.87,
        "最高价": 10.87,
        "最低价": 10.87,
        "昨收价": 10.87,
        "总市值": 97734659.0,
        "市盈率": 14.28,
        "成交量": 149.0,
        "成交额": 1619.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PWUP"
    },
    {
        "序号": 6235,
        "name": "Goal Acquisitions Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PUCKW"
    },
    {
        "序号": 6236,
        "name": "Goal Acquisitions Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.91,
        "总市值": 82398866.0,
        "市盈率": -47.81,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PUCKU"
    },
    {
        "序号": 6237,
        "name": "Goal Acquisitions Corp",
        "最新价": 10.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.79,
        "最高价": 10.79,
        "最低价": 10.79,
        "昨收价": 10.79,
        "总市值": 81492554.0,
        "市盈率": -47.28,
        "成交量": 2.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PUCK"
    },
    {
        "序号": 6238,
        "name": "Pono Capital Two Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTWOW"
    },
    {
        "序号": 6239,
        "name": "Pono Capital Two Inc Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.67,
        "总市值": 58574299.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTWOU"
    },
    {
        "序号": 6240,
        "name": "Pono Capital Two Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 58903676.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTWO"
    },
    {
        "序号": 6241,
        "name": "Partners Bancorp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 7.15,
        "总市值": 128596876.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTRS"
    },
    {
        "序号": 6242,
        "name": "Protagenic Therapeutics Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTIXW"
    },
    {
        "序号": 6243,
        "name": "Pono Capital Three Inc-A",
        "最新价": 10.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.57,
        "最高价": 10.57,
        "最低价": 10.57,
        "昨收价": 10.57,
        "总市值": 180794533.0,
        "市盈率": null,
        "成交量": 450.0,
        "成交额": 4756.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PTHR"
    },
    {
        "序号": 6244,
        "name": "飞天兆业",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PTHL"
    },
    {
        "序号": 6245,
        "name": "品钛",
        "最新价": 1.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.15,
        "最高价": 1.18,
        "最低价": 1.1,
        "昨收价": 1.13,
        "总市值": 18021195.0,
        "市盈率": -0.66,
        "成交量": 106763.0,
        "成交额": 122891.0,
        "振幅": 7.08,
        "换手率": 0.67,
        "symbol": "105.PT"
    },
    {
        "序号": 6246,
        "name": "Pacer CFRA-Stovall Small Cap Se",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSRS"
    },
    {
        "序号": 6247,
        "name": "Pacer CFRA-Stovall Large Cap Se",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSRL"
    },
    {
        "序号": 6248,
        "name": "Pacer CFRA-Stovall Global Seaso",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSRG"
    },
    {
        "序号": 6249,
        "name": "Pacer CFRA-Stovall Equal Weight",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSRE"
    },
    {
        "序号": 6250,
        "name": "Pacer Swan SOS Moderate (Septem",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSMS"
    },
    {
        "序号": 6251,
        "name": "Pacer Swan SOS Moderate (April)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.34,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSMR"
    },
    {
        "序号": 6252,
        "name": "PSI International Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PSIT"
    },
    {
        "序号": 6253,
        "name": "PSI Group Holdings Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PSIG"
    },
    {
        "序号": 6254,
        "name": "Pacer Swan SOS Flex (September)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSFS"
    },
    {
        "序号": 6255,
        "name": "Pacer Swan SOS Flex (April) ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSFM"
    },
    {
        "序号": 6256,
        "name": "Prime Skyline Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PSE"
    },
    {
        "序号": 6257,
        "name": "Pacer Swan SOS Conservative (Ap",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.69,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSCW"
    },
    {
        "序号": 6258,
        "name": "Pacer Swan SOS Conservative (Se",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PSCS"
    },
    {
        "序号": 6259,
        "name": "ParaZero Technologies Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PRZOW"
    },
    {
        "序号": 6260,
        "name": "Prospector Capital Corp-A",
        "最新价": 10.96,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.94,
        "最高价": 11.2,
        "最低价": 10.93,
        "昨收价": 10.96,
        "总市值": 113096854.0,
        "市盈率": -76.74,
        "成交量": 50822.0,
        "成交额": 557052.0,
        "振幅": 2.46,
        "换手率": 0.49,
        "symbol": "105.PRSR"
    },
    {
        "序号": 6261,
        "name": "Priam Properties Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PRMI"
    },
    {
        "序号": 6262,
        "name": "Pearl Holdings Acquisition Corp",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 157.0,
        "成交额": 6.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PRLHW"
    },
    {
        "序号": 6263,
        "name": "Pearl Holdings Acquisition Corp",
        "最新价": 10.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.76,
        "最高价": 10.76,
        "最低价": 10.76,
        "昨收价": 10.76,
        "总市值": 269000000.0,
        "市盈率": 32.48,
        "成交量": 4.0,
        "成交额": 43.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PRLHU"
    },
    {
        "序号": 6264,
        "name": "Priority Income Fund Inc Series",
        "最新价": 23.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.56,
        "最高价": 23.56,
        "最低价": 23.56,
        "昨收价": 23.56,
        "总市值": 34680320.0,
        "市盈率": null,
        "成交量": 280.0,
        "成交额": 6596.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "106.PRIF_G"
    },
    {
        "序号": 6265,
        "name": "Priority Income Fund Inc Series",
        "最新价": 23.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.7,
        "最高价": 23.7,
        "最低价": 23.7,
        "昨收价": 23.7,
        "总市值": 25929341.0,
        "市盈率": null,
        "成交量": 451.0,
        "成交额": 10688.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "106.PRIF_D"
    },
    {
        "序号": 6266,
        "name": "IQ S&P U.S. Preferred Stock Low",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PRHD"
    },
    {
        "序号": 6267,
        "name": "Prenetics Global Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PRENW"
    },
    {
        "序号": 6268,
        "name": "Peridot Acquisition Corp III Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PRDT_WS"
    },
    {
        "序号": 6269,
        "name": "Peridot Acquisition Corp III Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PRDT_U"
    },
    {
        "序号": 6270,
        "name": "Peridot Acquisition Corp III-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PRDT"
    },
    {
        "序号": 6271,
        "name": "Papaya Growth Opportunity Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPYAW"
    },
    {
        "序号": 6272,
        "name": "Papaya Growth Opportunity Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.72,
        "总市值": 120038079.0,
        "市盈率": 31.51,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPYAU"
    },
    {
        "序号": 6273,
        "name": "Papaya Growth Opportunity Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.76,
        "总市值": 120485982.0,
        "市盈率": 31.62,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPYA"
    },
    {
        "序号": 6274,
        "name": "U.S. Diversified Real Estate ET",
        "最新价": 28.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 28.73,
        "最高价": 28.85,
        "最低价": 28.68,
        "昨收价": 28.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 1847.0,
        "成交额": 53086.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.PPTY"
    },
    {
        "序号": 6275,
        "name": "PHP Ventures Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPHPW"
    },
    {
        "序号": 6276,
        "name": "PHP Ventures Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.2,
        "总市值": 29086837.0,
        "市盈率": -20.15,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPHPU"
    },
    {
        "序号": 6277,
        "name": "PHP Ventures Acquisition Corp R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PPHPR"
    },
    {
        "序号": 6278,
        "name": "PHP Ventures Acquisition Corp-A",
        "最新价": 11.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.01,
        "最高价": 11.01,
        "最低价": 11.01,
        "昨收价": 11.01,
        "总市值": 28593399.0,
        "市盈率": -19.81,
        "成交量": 25.0,
        "成交额": 275.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PPHP"
    },
    {
        "序号": 6279,
        "name": "Southport Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.66,
        "总市值": 105534693.0,
        "市盈率": 17.84,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PORT_U"
    },
    {
        "序号": 6280,
        "name": "Southport Acquisition Corp-A",
        "最新价": 10.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.63,
        "最高价": 10.63,
        "最低价": 10.63,
        "昨收价": 10.63,
        "总市值": 105237691.0,
        "市盈率": 17.79,
        "成交量": 1182.0,
        "成交额": 12564.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.PORT"
    },
    {
        "序号": 6281,
        "name": "爱国者国家银行",
        "最新价": 4.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.5,
        "最高价": 4.5,
        "最低价": 4.5,
        "昨收价": 4.5,
        "总市值": 17843337.0,
        "市盈率": -5.38,
        "成交量": 422.0,
        "成交额": 1899.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.PNBK"
    },
    {
        "序号": 6282,
        "name": "PennyMac Mortgage Investment Tr",
        "最新价": 22.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.58,
        "最高价": 22.65,
        "最低价": 22.49,
        "昨收价": 22.59,
        "总市值": 176202000.0,
        "市盈率": null,
        "成交量": 12123.0,
        "成交额": 273446.0,
        "振幅": 0.71,
        "换手率": 0.16,
        "symbol": "106.PMT_B"
    },
    {
        "序号": 6283,
        "name": "PIMCO Mortgage Income Trust Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PMTG"
    },
    {
        "序号": 6284,
        "name": "Perfect Moment Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PMNT"
    },
    {
        "序号": 6285,
        "name": "PIMCO Municipal Income Fund II",
        "最新价": 8.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.15,
        "最高价": 8.2,
        "最低价": 8.13,
        "昨收价": 8.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 248691.0,
        "成交额": 2029535.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "106.PML"
    },
    {
        "序号": 6286,
        "name": "Priveterra Acquisition Corp II ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PMGMW"
    },
    {
        "序号": 6287,
        "name": "Priveterra Acquisition Corp II ",
        "最新价": 10.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.8,
        "昨收价": 10.8,
        "总市值": 95277125.0,
        "市盈率": 94.86,
        "成交量": 100.0,
        "成交额": 1080.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PMGMU"
    },
    {
        "序号": 6288,
        "name": "Priveterra Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 96159320.0,
        "市盈率": 95.73,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PMGM"
    },
    {
        "序号": 6289,
        "name": "Plutonian Acquisition Corp Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 59.0,
        "成交额": 2.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PLTNW"
    },
    {
        "序号": 6290,
        "name": "Plutonian Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 54008284.0,
        "市盈率": 47.04,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PLTNU"
    },
    {
        "序号": 6291,
        "name": "Plutonian Acquisition Corp Rt",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PLTNR"
    },
    {
        "序号": 6292,
        "name": "Pulte Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PLTEU"
    },
    {
        "序号": 6293,
        "name": "Plum Acquisition Corp I Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 122022982.0,
        "市盈率": 68.07,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PLMIU"
    },
    {
        "序号": 6294,
        "name": "Plum Acquisition Corp I-A",
        "最新价": 10.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.88,
        "最高价": 10.88,
        "最低价": 10.88,
        "昨收价": 10.88,
        "总市值": 122247702.0,
        "市盈率": 68.19,
        "成交量": 44.0,
        "成交额": 478.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PLMI"
    },
    {
        "序号": 6295,
        "name": "Patria Latin American Opportuni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.06,
        "总市值": 250293120.0,
        "市盈率": 21.52,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PLAOU"
    },
    {
        "序号": 6296,
        "name": "美国绿星球",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.34,
        "最高价": 0.35,
        "最低价": 0.33,
        "昨收价": 0.33,
        "总市值": 23787037.0,
        "市盈率": -0.67,
        "成交量": 44689.0,
        "成交额": 14799.0,
        "振幅": 6.06,
        "换手率": 0.06,
        "symbol": "107.PLAG"
    },
    {
        "序号": 6297,
        "name": "Peakstone Realty Trust",
        "最新价": 17.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.34,
        "最高价": 17.94,
        "最低价": 17.14,
        "昨收价": 17.56,
        "总市值": 632391353.0,
        "市盈率": null,
        "成交量": 155024.0,
        "成交额": 2730563.0,
        "振幅": 4.56,
        "换手率": 0.43,
        "symbol": "106.PKST"
    },
    {
        "序号": 6298,
        "name": "PGIM Jennison Focused Value ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 54.72,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PJFV"
    },
    {
        "序号": 6299,
        "name": "Kidpik Corp",
        "最新价": 0.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.41,
        "最高价": 0.43,
        "最低价": 0.4,
        "昨收价": 0.41,
        "总市值": 3838488.0,
        "市盈率": -0.5,
        "成交量": 62516.0,
        "成交额": 25781.0,
        "振幅": 7.32,
        "换手率": 0.67,
        "symbol": "105.PIK"
    },
    {
        "序号": 6300,
        "name": "P3 Health Partners Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PIIIW"
    },
    {
        "序号": 6301,
        "name": "Pacer International Export Lead",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PIEL"
    },
    {
        "序号": 6302,
        "name": "Invesco DWA Emerging Markets Mo",
        "最新价": 19.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 18.95,
        "最高价": 19.03,
        "最低价": 18.93,
        "昨收价": 19.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 12548.0,
        "成交额": 238076.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.PIE"
    },
    {
        "序号": 6303,
        "name": "Pyrophyte Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PHYT_WS"
    },
    {
        "序号": 6304,
        "name": "Pyrophyte Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.02,
        "总市值": 154336059.0,
        "市盈率": 24.25,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PHYT_U"
    },
    {
        "序号": 6305,
        "name": "Pyrophyte Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.98,
        "总市值": 153775855.0,
        "市盈率": 24.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PHYT"
    },
    {
        "序号": 6306,
        "name": "Pharvaris NV",
        "最新价": 25.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.42,
        "最高价": 25.99,
        "最低价": 24.49,
        "昨收价": 25.42,
        "总市值": 1324602519.0,
        "市盈率": -11.34,
        "成交量": 243885.0,
        "成交额": 6188196.0,
        "振幅": 5.9,
        "换手率": 0.47,
        "symbol": "105.PHVS"
    },
    {
        "序号": 6307,
        "name": "Phunware Inc",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": 13103913.0,
        "市盈率": -0.32,
        "成交量": 5841670.0,
        "成交额": 536142.0,
        "振幅": 11.11,
        "换手率": 4.01,
        "symbol": "105.PHUN"
    },
    {
        "序号": 6308,
        "name": "BiomX Inc Unit Cons of 1 Sh + 1",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.25,
        "总市值": 11494983.0,
        "市盈率": -0.43,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PHGE_U"
    },
    {
        "序号": 6309,
        "name": "BiomX Inc",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.28,
        "昨收价": 0.28,
        "总市值": 12874380.0,
        "市盈率": -0.48,
        "成交量": 50970.0,
        "成交额": 14501.0,
        "振幅": 3.57,
        "换手率": 0.11,
        "symbol": "107.PHGE"
    },
    {
        "序号": 6310,
        "name": "Preston Hollow Community Capita",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PHCC"
    },
    {
        "序号": 6311,
        "name": "Pharming Group NV ADR",
        "最新价": 12.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.0,
        "最高价": 12.0,
        "最低价": 12.0,
        "昨收价": 12.0,
        "总市值": 791014114.0,
        "市盈率": -48.18,
        "成交量": 261.0,
        "成交额": 3131.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.PHAR"
    },
    {
        "序号": 6312,
        "name": "Pegasus Digital Mobility Acquis",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PGSS_WS"
    },
    {
        "序号": 6313,
        "name": "Pegasus Digital Mobility Acquis",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 116910398.0,
        "市盈率": 49.83,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PGSS_U"
    },
    {
        "序号": 6314,
        "name": "PhenixFIN Corp Notes",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 21.3,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PFXNZ"
    },
    {
        "序号": 6315,
        "name": "PhenixFIN Corp",
        "最新价": 38.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 38.0,
        "最高价": 38.0,
        "最低价": 37.95,
        "昨收价": 38.0,
        "总市值": 79366382.0,
        "市盈率": 5.32,
        "成交量": 727.0,
        "成交额": 27619.0,
        "振幅": 0.13,
        "换手率": 0.03,
        "symbol": "105.PFX"
    },
    {
        "序号": 6316,
        "name": "P&F Industries Inc-A",
        "最新价": 12.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.93,
        "最高价": 12.94,
        "最低价": 12.92,
        "昨收价": 12.92,
        "总市值": 41275511.0,
        "市盈率": -55.33,
        "成交量": 5771.0,
        "成交额": 74611.0,
        "振幅": 0.15,
        "换手率": 0.18,
        "symbol": "105.PFIN"
    },
    {
        "序号": 6317,
        "name": "Phoenix Motor Inc",
        "最新价": 1.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.39,
        "最高价": 1.42,
        "最低价": 1.35,
        "昨收价": 1.37,
        "总市值": 29169936.0,
        "市盈率": -2.21,
        "成交量": 127227.0,
        "成交额": 177492.0,
        "振幅": 5.11,
        "换手率": 0.6,
        "symbol": "105.PEV"
    },
    {
        "序号": 6318,
        "name": "Wag! Group Co Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PETWW"
    },
    {
        "序号": 6319,
        "name": "PepperLime Health Acquisition C",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 39.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PEPLW"
    },
    {
        "序号": 6320,
        "name": "PepperLime Health Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.66,
        "总市值": 53980748.0,
        "市盈率": 66.15,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PEPLU"
    },
    {
        "序号": 6321,
        "name": "PepsiCo Inc Notes 2031",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PEP31"
    },
    {
        "序号": 6322,
        "name": "PepsiCo Inc Notes 2027",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PEP27"
    },
    {
        "序号": 6323,
        "name": "Putnam Emerging Markets ex-Chin",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 44.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PEMX"
    },
    {
        "序号": 6324,
        "name": "Project Energy Reimagined Acqui",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PEGRW"
    },
    {
        "序号": 6325,
        "name": "Project Energy Reimagined Acqui",
        "最新价": 10.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.5,
        "最高价": 10.57,
        "最低价": 10.5,
        "昨收价": 10.57,
        "总市值": 184697781.0,
        "市盈率": 33.01,
        "成交量": 881.0,
        "成交额": 9268.0,
        "振幅": 0.66,
        "换手率": 0.01,
        "symbol": "105.PEGR"
    },
    {
        "序号": 6326,
        "name": "PEDEVCO Corp",
        "最新价": 0.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.79,
        "最高价": 0.83,
        "最低价": 0.77,
        "昨收价": 0.79,
        "总市值": 68927711.0,
        "市盈率": 45.92,
        "成交量": 136166.0,
        "成交额": 106139.0,
        "振幅": 7.59,
        "换手率": 0.16,
        "symbol": "107.PED"
    },
    {
        "序号": 6327,
        "name": "PCTEL Inc",
        "最新价": 6.91,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.91,
        "最高价": 6.91,
        "最低价": 6.9,
        "昨收价": 6.91,
        "总市值": 133733659.0,
        "市盈率": 31.37,
        "成交量": 41716.0,
        "成交额": 288212.0,
        "振幅": 0.14,
        "换手率": 0.22,
        "symbol": "105.PCTI"
    },
    {
        "序号": 6328,
        "name": "PIMCO California Municipal Inco",
        "最新价": 9.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.4,
        "最高价": 9.47,
        "最低价": 9.37,
        "昨收价": 9.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 63953.0,
        "成交额": 603189.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "106.PCQ"
    },
    {
        "序号": 6329,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 14.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.0,
        "最高价": 14.0,
        "最低价": 14.0,
        "昨收价": 14.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2837.0,
        "成交额": 39718.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PCG_I"
    },
    {
        "序号": 6330,
        "name": "Pacific Gas and Electric Co Ser",
        "最新价": 16.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.29,
        "最高价": 16.75,
        "最低价": 16.29,
        "昨收价": 16.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1985.0,
        "成交额": 32622.0,
        "振幅": 2.75,
        "换手率": null,
        "symbol": "107.PCG_E"
    },
    {
        "序号": 6331,
        "name": "松下",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PC"
    },
    {
        "序号": 6332,
        "name": "PGIM Portfolio Ballast ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.9,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PBL"
    },
    {
        "序号": 6333,
        "name": "Phoenix Biotech Acquisition Cor",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 28.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PBAXW"
    },
    {
        "序号": 6334,
        "name": "Phoenix Biotech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.94,
        "总市值": 68333505.0,
        "市盈率": -27.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PBAXU"
    },
    {
        "序号": 6335,
        "name": "Phoenix Biotech Acquisition Cor",
        "最新价": 10.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.9,
        "昨收价": 10.9,
        "总市值": 68083656.0,
        "市盈率": -27.06,
        "成交量": 3519.0,
        "成交额": 38357.0,
        "振幅": 0.0,
        "换手率": 0.06,
        "symbol": "105.PBAX"
    },
    {
        "序号": 6336,
        "name": "Pangaea Logistics Solutions Ltd",
        "最新价": 6.99,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.0,
        "最高价": 7.05,
        "最低价": 6.91,
        "昨收价": 6.99,
        "总市值": 324801688.0,
        "市盈率": 7.98,
        "成交量": 153587.0,
        "成交额": 1071248.0,
        "振幅": 2.0,
        "换手率": 0.33,
        "symbol": "105.PANL"
    },
    {
        "序号": 6337,
        "name": "Pacer Benchmark Apartments & Re",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.PAD"
    },
    {
        "序号": 6338,
        "name": "PacWest Bancorp Series A Pfd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.94,
        "总市值": 10747455.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PACWP"
    },
    {
        "序号": 6339,
        "name": "PacWest Bancorp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 7.54,
        "总市值": 894147005.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.PACW"
    },
    {
        "序号": 6340,
        "name": "PROOF Acquisition Corp I Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 17.82,
        "总市值": 237774006.0,
        "市盈率": 142.68,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.PACI_U"
    },
    {
        "序号": 6341,
        "name": "Oxus Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.6,
        "总市值": 76118829.0,
        "市盈率": -56.8,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OXUSU"
    },
    {
        "序号": 6342,
        "name": "Oxford Square Capital Corp Note",
        "最新价": 25.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.31,
        "最高价": 25.31,
        "最低价": 25.31,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 68.0,
        "成交额": 1720.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OXSQL"
    },
    {
        "序号": 6343,
        "name": "Oxford Lane Capital Corp Notes",
        "最新价": 23.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.39,
        "最高价": 23.4,
        "最低价": 23.34,
        "昨收价": 23.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 2445.0,
        "成交额": 57177.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "105.OXLCL"
    },
    {
        "序号": 6344,
        "name": "Oxbridge Re Holdings Ltd",
        "最新价": 1.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.04,
        "最高价": 1.08,
        "最低价": 1.03,
        "昨收价": 1.05,
        "总市值": 6163746.0,
        "市盈率": -0.94,
        "成交量": 3083.0,
        "成交额": 3198.0,
        "振幅": 4.76,
        "换手率": 0.05,
        "symbol": "105.OXBR"
    },
    {
        "序号": 6345,
        "name": "俄亥俄河谷银行",
        "最新价": 23.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.7,
        "最高价": 23.7,
        "最低价": 23.7,
        "昨收价": 23.7,
        "总市值": 113123228.0,
        "市盈率": 8.75,
        "成交量": 585.0,
        "成交额": 13864.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.OVBC"
    },
    {
        "序号": 6346,
        "name": "Ouster Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 2117.0,
        "成交额": 93.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "107.OUST_WS_A"
    },
    {
        "序号": 6347,
        "name": "Ouster Inc Wt",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1861.0,
        "成交额": 176.0,
        "振幅": 10.0,
        "换手率": null,
        "symbol": "106.OUST_WS"
    },
    {
        "序号": 6348,
        "name": "Ontrak Inc",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.55,
        "最高价": 0.55,
        "最低价": 0.51,
        "昨收价": 0.53,
        "总市值": 14608866.0,
        "市盈率": -0.48,
        "成交量": 43346.0,
        "成交额": 22999.0,
        "振幅": 7.55,
        "换手率": 0.16,
        "symbol": "105.OTRK"
    },
    {
        "序号": 6349,
        "name": "OTG EXP Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OTG"
    },
    {
        "序号": 6350,
        "name": "OceanTech Acquisitions I Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OTECW"
    },
    {
        "序号": 6351,
        "name": "OceanTech Acquisitions I Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.13,
        "总市值": 38926897.0,
        "市盈率": -31.2,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OTECU"
    },
    {
        "序号": 6352,
        "name": "OceanTech Acquisitions I Corp-A",
        "最新价": 11.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.14,
        "最高价": 11.14,
        "最低价": 11.14,
        "昨收价": 11.14,
        "总市值": 38961872.0,
        "市盈率": -31.22,
        "成交量": 5.0,
        "成交额": 55.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.OTEC"
    },
    {
        "序号": 6353,
        "name": "Osiris Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 19.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.OSI_WS"
    },
    {
        "序号": 6354,
        "name": "Osiris Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.32,
        "总市值": 91368543.0,
        "市盈率": -80.92,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.OSI_U"
    },
    {
        "序号": 6355,
        "name": "Osiris Acquisition Corp-A",
        "最新价": 10.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.42,
        "最高价": 10.42,
        "最低价": 10.42,
        "昨收价": 10.42,
        "总市值": 92253897.0,
        "市盈率": -81.71,
        "成交量": 20.0,
        "成交额": 208.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.OSI"
    },
    {
        "序号": 6356,
        "name": "ProSomnus Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OSAAW"
    },
    {
        "序号": 6357,
        "name": "Orgenesis Inc",
        "最新价": 0.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.47,
        "最高价": 0.51,
        "最低价": 0.46,
        "昨收价": 0.46,
        "总市值": 14663449.0,
        "市盈率": -0.58,
        "成交量": 75825.0,
        "成交额": 36994.0,
        "振幅": 10.87,
        "换手率": 0.24,
        "symbol": "105.ORGS"
    },
    {
        "序号": 6358,
        "name": "Origin Materials Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 32807.0,
        "成交额": 1296.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ORGNW"
    },
    {
        "序号": 6359,
        "name": "O'Shares Quality Artificial Int",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OQAI"
    },
    {
        "序号": 6360,
        "name": "Ocean Power Technologies Inc",
        "最新价": 0.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.34,
        "最高价": 0.35,
        "最低价": 0.34,
        "昨收价": 0.34,
        "总市值": 19988164.0,
        "市盈率": -0.73,
        "成交量": 135349.0,
        "成交额": 46236.0,
        "振幅": 2.94,
        "换手率": 0.23,
        "symbol": "107.OPTT"
    },
    {
        "序号": 6361,
        "name": "Opti-Harvest Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OPHV"
    },
    {
        "序号": 6362,
        "name": "Magnum Opus Acquisition Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.OPA_WS"
    },
    {
        "序号": 6363,
        "name": "Magnum Opus Acquisition Ltd Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.67,
        "总市值": 123719898.0,
        "市盈率": 7.97,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.OPA_U"
    },
    {
        "序号": 6364,
        "name": "OPAL Fuels Inc-A",
        "最新价": 5.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.58,
        "最高价": 5.7,
        "最低价": 5.55,
        "昨收价": 5.55,
        "总市值": 956677348.0,
        "市盈率": 30.18,
        "成交量": 43849.0,
        "成交额": 246371.0,
        "振幅": 2.7,
        "换手率": 0.03,
        "symbol": "105.OPAL"
    },
    {
        "序号": 6365,
        "name": "Offerpad Solutions Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.OPAD_WS"
    },
    {
        "序号": 6366,
        "name": "Onyx Acquisition Co I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ONYXW"
    },
    {
        "序号": 6367,
        "name": "Onyx Acquisition Co I Unit Cons",
        "最新价": 11.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.0,
        "最高价": 11.0,
        "最低价": 11.0,
        "昨收价": 11.0,
        "总市值": 94867586.0,
        "市盈率": 55.51,
        "成交量": 3.0,
        "成交额": 33.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ONYXU"
    },
    {
        "序号": 6368,
        "name": "Onyx Acquisition Co I-A",
        "最新价": 11.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.0,
        "最高价": 11.0,
        "最低价": 11.0,
        "昨收价": 11.0,
        "总市值": 94867586.0,
        "市盈率": 55.51,
        "成交量": 66.0,
        "成交额": 726.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ONYX"
    },
    {
        "序号": 6369,
        "name": "Onconova Therapeutics Inc",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.63,
        "最高价": 0.65,
        "最低价": 0.63,
        "昨收价": 0.63,
        "总市值": 13232148.0,
        "市盈率": -0.66,
        "成交量": 4289791.0,
        "成交额": 2744372.0,
        "振幅": 3.17,
        "换手率": 20.42,
        "symbol": "105.ONTX"
    },
    {
        "序号": 6370,
        "name": "ONS Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ONS_WS"
    },
    {
        "序号": 6371,
        "name": "ONS Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ONS_U"
    },
    {
        "序号": 6372,
        "name": "ONS Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ONS"
    },
    {
        "序号": 6373,
        "name": "OneMedNet Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 9457.0,
        "成交额": 89.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ONMDW"
    },
    {
        "序号": 6374,
        "name": "Onfolio Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ONFOW"
    },
    {
        "序号": 6375,
        "name": "Onfolio Holdings Inc",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.57,
        "最高价": 0.57,
        "最低价": 0.53,
        "昨收价": 0.53,
        "总市值": 2708403.0,
        "市盈率": -0.32,
        "成交量": 16543.0,
        "成交额": 9210.0,
        "振幅": 7.55,
        "换手率": 0.32,
        "symbol": "105.ONFO"
    },
    {
        "序号": 6376,
        "name": "Oncolytics Biotech Inc",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.4,
        "最高价": 1.42,
        "最低价": 1.37,
        "昨收价": 1.4,
        "总市值": 102758386.0,
        "市盈率": -4.26,
        "成交量": 273853.0,
        "成交额": 383216.0,
        "振幅": 3.57,
        "换手率": 0.37,
        "symbol": "105.ONCY"
    },
    {
        "序号": 6377,
        "name": "Omniq Corp",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.64,
        "最高价": 0.65,
        "最低价": 0.63,
        "昨收价": 0.65,
        "总市值": 6935168.0,
        "市盈率": -0.44,
        "成交量": 2964.0,
        "成交额": 1898.0,
        "振幅": 3.08,
        "换手率": 0.03,
        "symbol": "105.OMQS"
    },
    {
        "序号": 6378,
        "name": "Singular Genomics Systems Inc",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.39,
        "最高价": 0.39,
        "最低价": 0.38,
        "昨收价": 0.39,
        "总市值": 28619959.0,
        "市盈率": -0.31,
        "成交量": 96620.0,
        "成交额": 37361.0,
        "振幅": 2.56,
        "换手率": 0.13,
        "symbol": "105.OMIC"
    },
    {
        "序号": 6379,
        "name": "Olink Holding AB (publ) ADR",
        "最新价": 25.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.85,
        "最高价": 25.87,
        "最低价": 25.84,
        "昨收价": 25.85,
        "总市值": 3214259183.0,
        "市盈率": -172.61,
        "成交量": 565693.0,
        "成交额": 14624034.0,
        "振幅": 0.12,
        "换手率": 0.45,
        "symbol": "105.OLK"
    },
    {
        "序号": 6380,
        "name": "Opus International Small\/Mid Ca",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OISC"
    },
    {
        "序号": 6381,
        "name": "Permex Petroleum Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OILS_WS"
    },
    {
        "序号": 6382,
        "name": "Permex Petroleum Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OILS"
    },
    {
        "序号": 6383,
        "name": "Opy Acquisition Corp I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OHAAW"
    },
    {
        "序号": 6384,
        "name": "Opy Acquisition Corp I Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.46,
        "总市值": 55295043.0,
        "市盈率": -209.01,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OHAAU"
    },
    {
        "序号": 6385,
        "name": "O'Shares U.S. Small Cap Quality",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OGRS"
    },
    {
        "序号": 6386,
        "name": "O'Shares U.S. Large Cap Quality",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OGRO"
    },
    {
        "序号": 6387,
        "name": "Osisko Development Corp Wt",
        "最新价": 0.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.35,
        "最高价": 0.35,
        "最低价": 0.35,
        "昨收价": 0.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 175.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ODVWZ"
    },
    {
        "序号": 6388,
        "name": "Osisko Development Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ODVWW"
    },
    {
        "序号": 6389,
        "name": "Pacer BlueStar Digital Entertai",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.21,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ODDS"
    },
    {
        "序号": 6390,
        "name": "TrueShares Structured Outcome (",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 33.45,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OCTZ"
    },
    {
        "序号": 6391,
        "name": "Innovator Premium Income 30 Bar",
        "最新价": 24.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.37,
        "最高价": 24.42,
        "最低价": 24.37,
        "昨收价": 24.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 252.0,
        "成交额": 6146.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.OCTJ"
    },
    {
        "序号": 6392,
        "name": "Innovator Premium Income 20 Bar",
        "最新价": 24.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.57,
        "最高价": 24.59,
        "最低价": 24.54,
        "昨收价": 24.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 377.0,
        "成交额": 9266.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.OCTH"
    },
    {
        "序号": 6393,
        "name": "Oculis Holding AG Wt",
        "最新价": 1.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.45,
        "最高价": 1.45,
        "最低价": 1.45,
        "昨收价": 1.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 4.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OCSAW"
    },
    {
        "序号": 6394,
        "name": "Ocugen Inc",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.39,
        "最高价": 0.4,
        "最低价": 0.39,
        "昨收价": 0.39,
        "总市值": 100035949.0,
        "市盈率": -1.32,
        "成交量": 797644.0,
        "成交额": 311450.0,
        "振幅": 2.56,
        "换手率": 0.31,
        "symbol": "105.OCGN"
    },
    {
        "序号": 6395,
        "name": "OFS Credit Co Inc Series E Pfd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.47,
        "总市值": 27413400.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OCCIN"
    },
    {
        "序号": 6396,
        "name": "OCA Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OCAXW"
    },
    {
        "序号": 6397,
        "name": "OCA Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.57,
        "总市值": 80735954.0,
        "市盈率": 67.28,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OCAXU"
    },
    {
        "序号": 6398,
        "name": "O'Shares Quality Robotics and A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.OBOT"
    },
    {
        "序号": 6399,
        "name": "Oak Woods Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 2050.0,
        "成交额": 67.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OAKUW"
    },
    {
        "序号": 6400,
        "name": "Oak Woods Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.21,
        "总市值": 84418306.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.OAKUU"
    },
    {
        "序号": 6401,
        "name": "Oak Woods Acquisition Corp-A",
        "最新价": 10.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.51,
        "最高价": 10.51,
        "最低价": 10.51,
        "昨收价": 10.51,
        "总市值": 79146869.0,
        "市盈率": null,
        "成交量": 11750.0,
        "成交额": 123492.0,
        "振幅": 0.0,
        "换手率": 0.16,
        "symbol": "105.OAKU"
    },
    {
        "序号": 6402,
        "name": "NYIAX Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NYX"
    },
    {
        "序号": 6403,
        "name": "纽约抵押信托",
        "最新价": 8.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.9,
        "最高价": 9.0,
        "最低价": 8.88,
        "昨收价": 8.94,
        "总市值": 810708058.0,
        "市盈率": -6.33,
        "成交量": 533981.0,
        "成交额": 4770686.0,
        "振幅": 1.34,
        "换手率": 0.59,
        "symbol": "105.NYMT"
    },
    {
        "序号": 6404,
        "name": "Nxu Inc-A",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": 5143998.0,
        "市盈率": -0.09,
        "成交量": 46558895.0,
        "成交额": 917908.0,
        "振幅": 0.0,
        "换手率": 18.1,
        "symbol": "105.NXU"
    },
    {
        "序号": 6405,
        "name": "NexPoint Residential Trust Inc",
        "最新价": 32.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 32.23,
        "最高价": 32.56,
        "最低价": 31.86,
        "昨收价": 32.31,
        "总市值": 829537053.0,
        "市盈率": 27.93,
        "成交量": 180106.0,
        "成交额": 5816131.0,
        "振幅": 2.17,
        "换手率": 0.7,
        "symbol": "106.NXRT"
    },
    {
        "序号": 6406,
        "name": "Nexalin Technology Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NXLIW"
    },
    {
        "序号": 6407,
        "name": "NextGen Healthcare Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.94,
        "总市值": 1606299642.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NXGN"
    },
    {
        "序号": 6408,
        "name": "NexGel Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.31,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NXGLW"
    },
    {
        "序号": 6409,
        "name": "Nuveen California Select Tax-Fr",
        "最新价": 12.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.6,
        "最高价": 12.64,
        "最低价": 12.5,
        "昨收价": 12.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 29003.0,
        "成交额": 364652.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "106.NXC"
    },
    {
        "序号": 6410,
        "name": "新闻集团-B",
        "最新价": 22.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.92,
        "最高价": 23.1,
        "最低价": 22.71,
        "昨收价": 22.94,
        "总市值": 13122927913.0,
        "市盈率": 94.41,
        "成交量": 855735.0,
        "成交额": 19613477.0,
        "振幅": 1.7,
        "换手率": 0.45,
        "symbol": "105.NWS"
    },
    {
        "序号": 6411,
        "name": "Nuveen Winslow Large-Cap Growth",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.88,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NWLG"
    },
    {
        "序号": 6412,
        "name": "Nuvve Holding Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 8137.0,
        "成交额": 184.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NVVEW"
    },
    {
        "序号": 6413,
        "name": "Nuvve Holding Corp",
        "最新价": 0.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.16,
        "最高价": 0.17,
        "最低价": 0.16,
        "昨收价": 0.16,
        "总市值": 7191829.0,
        "市盈率": -0.23,
        "成交量": 1021769.0,
        "成交额": 165355.0,
        "振幅": 6.25,
        "换手率": 2.27,
        "symbol": "105.NVVE"
    },
    {
        "序号": 6414,
        "name": "Invitae Corp",
        "最新价": 0.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.59,
        "最高价": 0.59,
        "最低价": 0.56,
        "昨收价": 0.57,
        "总市值": 163300919.0,
        "市盈率": -0.11,
        "成交量": 3926176.0,
        "成交额": 2229940.0,
        "振幅": 5.26,
        "换手率": 1.37,
        "symbol": "106.NVTA"
    },
    {
        "序号": 6415,
        "name": "NorthView Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NVACW"
    },
    {
        "序号": 6416,
        "name": "NorthView Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NVACR"
    },
    {
        "序号": 6417,
        "name": "NorthView Acquisition Corp",
        "最新价": 10.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.95,
        "最高价": 10.95,
        "最低价": 10.95,
        "昨收价": 10.95,
        "总市值": 67538308.0,
        "市盈率": 47.95,
        "成交量": 3.0,
        "成交额": 32.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.NVAC"
    },
    {
        "序号": 6418,
        "name": "Nuvation Bio Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 8279.0,
        "成交额": 379.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.NUVB_WS"
    },
    {
        "序号": 6419,
        "name": "Nutex Health Inc",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.19,
        "最高价": 0.2,
        "最低价": 0.18,
        "昨收价": 0.19,
        "总市值": 127448503.0,
        "市盈率": -4.41,
        "成交量": 1428519.0,
        "成交额": 269022.0,
        "振幅": 10.53,
        "换手率": 0.21,
        "symbol": "105.NUTX"
    },
    {
        "序号": 6420,
        "name": "Nubia Brand International Corp ",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NUBIW"
    },
    {
        "序号": 6421,
        "name": "Nubia Brand International Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 75584540.0,
        "市盈率": 63.1,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NUBIU"
    },
    {
        "序号": 6422,
        "name": "NeoTV",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NTV"
    },
    {
        "序号": 6423,
        "name": "Global X Carbon Credits Strateg",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NTRL"
    },
    {
        "序号": 6424,
        "name": "Nutriband Inc Wt",
        "最新价": 0.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.37,
        "最高价": 0.99,
        "最低价": 0.37,
        "昨收价": 0.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 1502.0,
        "成交额": 688.0,
        "振幅": 167.57,
        "换手率": null,
        "symbol": "105.NTRBW"
    },
    {
        "序号": 6425,
        "name": "Network-1 Technologies Inc",
        "最新价": 2.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.15,
        "最高价": 2.22,
        "最低价": 2.15,
        "昨收价": 2.21,
        "总市值": 52223683.0,
        "市盈率": -14.44,
        "成交量": 14685.0,
        "成交额": 31927.0,
        "振幅": 3.17,
        "换手率": 0.06,
        "symbol": "107.NTIP"
    },
    {
        "序号": 6426,
        "name": "网班",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NTCL"
    },
    {
        "序号": 6427,
        "name": "Northern Star Investment Corp I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NSTB_WS"
    },
    {
        "序号": 6428,
        "name": "Northern Star Investment Corp I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.31,
        "总市值": 119812397.0,
        "市盈率": 49.35,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NSTB_U"
    },
    {
        "序号": 6429,
        "name": "National Security Emerging Mark",
        "最新价": 25.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.13,
        "最高价": 25.14,
        "最低价": 25.04,
        "昨收价": 25.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 2522.0,
        "成交额": 63278.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "105.NSI"
    },
    {
        "序号": 6430,
        "name": "National Storage Affiliates Tru",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.NSA_B"
    },
    {
        "序号": 6431,
        "name": "Neuraxis Inc",
        "最新价": 2.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.9,
        "最高价": 3.06,
        "最低价": 2.7,
        "昨收价": 2.8,
        "总市值": 14810639.0,
        "市盈率": -1.59,
        "成交量": 5360.0,
        "成交额": 15211.0,
        "振幅": 12.86,
        "换手率": 0.1,
        "symbol": "107.NRXS"
    },
    {
        "序号": 6432,
        "name": "NRX Pharmaceuticals Inc Wt",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.12,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 14284.0,
        "成交额": 1470.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.NRXPW"
    },
    {
        "序号": 6433,
        "name": "NRX Pharmaceuticals Inc",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.34,
        "最高价": 0.35,
        "最低价": 0.32,
        "昨收价": 0.33,
        "总市值": 28105268.0,
        "市盈率": -0.78,
        "成交量": 145852.0,
        "成交额": 49573.0,
        "振幅": 9.09,
        "换手率": 0.17,
        "symbol": "105.NRXP"
    },
    {
        "序号": 6434,
        "name": "NeuroSense Therapeutics Ltd Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 19657.0,
        "成交额": 1769.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NRSNW"
    },
    {
        "序号": 6435,
        "name": "Northern Revival Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NRACW"
    },
    {
        "序号": 6436,
        "name": "Northern Revival Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.89,
        "总市值": 86550932.0,
        "市盈率": 61.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NRACU"
    },
    {
        "序号": 6437,
        "name": "Nuveen Variable Rate Preferred ",
        "最新价": 16.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.04,
        "最高价": 16.08,
        "最低价": 16.0,
        "昨收价": 16.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 110147.0,
        "成交额": 1768572.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "106.NPFD"
    },
    {
        "序号": 6438,
        "name": "New Providence Acquisition Corp",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 49832.0,
        "成交额": 1998.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NPABW"
    },
    {
        "序号": 6439,
        "name": "New Providence Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.63,
        "总市值": 122435011.0,
        "市盈率": 28.49,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NPABU"
    },
    {
        "序号": 6440,
        "name": "Nova Vision Acquisition Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NOVVW"
    },
    {
        "序号": 6441,
        "name": "Nova Vision Acquisition Corp Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.69,
        "总市值": 41752144.0,
        "市盈率": 192.22,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NOVVU"
    },
    {
        "序号": 6442,
        "name": "Nova Vision Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.25,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NOVVR"
    },
    {
        "序号": 6443,
        "name": "Nova Vision Acquisition Corp",
        "最新价": 11.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.37,
        "最高价": 11.37,
        "最低价": 11.37,
        "昨收价": 11.37,
        "总市值": 40609228.0,
        "市盈率": 186.96,
        "成交量": 31.0,
        "成交额": 352.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.NOVV"
    },
    {
        "序号": 6444,
        "name": "Novusterra Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NOVSW"
    },
    {
        "序号": 6445,
        "name": "Novusterra Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NOVS"
    },
    {
        "序号": 6446,
        "name": "Nordic Realty Trust Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NORT"
    },
    {
        "序号": 6447,
        "name": "Nogin Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 75682.0,
        "成交额": 898.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NOGNW"
    },
    {
        "序号": 6448,
        "name": "Pacer Benchmark Net Lease Real ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NNNL"
    },
    {
        "序号": 6449,
        "name": "99 Acquisition Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NNAGW"
    },
    {
        "序号": 6450,
        "name": "99 Acquisition Group Inc Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.29,
        "总市值": 103671750.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NNAGU"
    },
    {
        "序号": 6451,
        "name": "99 Acquisition Group Inc Rt",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.17,
        "最高价": 0.17,
        "最低价": 0.17,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 14620.0,
        "成交额": 2485.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NNAGR"
    },
    {
        "序号": 6452,
        "name": "Nemaura Medical Inc",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.23,
        "最高价": 0.23,
        "最低价": 0.21,
        "昨收价": 0.21,
        "总市值": 6068874.0,
        "市盈率": -0.59,
        "成交量": 45811.0,
        "成交额": 9820.0,
        "振幅": 9.52,
        "换手率": 0.16,
        "symbol": "105.NMRD"
    },
    {
        "序号": 6453,
        "name": "Niagara Mohawk Power Corp Pfd",
        "最新价": 77.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 77.55,
        "最高价": 77.55,
        "最低价": 77.55,
        "昨收价": 77.55,
        "总市值": 7364691.0,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 387.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.NMK_C"
    },
    {
        "序号": 6454,
        "name": "Navios Maritime Holdings Inc",
        "最新价": 2.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.24,
        "最高价": 2.25,
        "最低价": 2.24,
        "昨收价": 2.25,
        "总市值": 51359513.0,
        "市盈率": 0.66,
        "成交量": 21896.0,
        "成交额": 49198.0,
        "振幅": 0.44,
        "换手率": 0.1,
        "symbol": "106.NM"
    },
    {
        "序号": 6455,
        "name": "NLS Pharmaceutics Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NLSPW"
    },
    {
        "序号": 6456,
        "name": "内克塔治疗",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.5,
        "最高价": 0.52,
        "最低价": 0.5,
        "昨收价": 0.51,
        "总市值": 97292989.0,
        "市盈率": -0.33,
        "成交量": 626380.0,
        "成交额": 319140.0,
        "振幅": 3.92,
        "换手率": 0.33,
        "symbol": "105.NKTR"
    },
    {
        "序号": 6457,
        "name": "NKGen Biotech Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NKGNW"
    },
    {
        "序号": 6458,
        "name": "NKGen Biotech Inc",
        "最新价": 3.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.36,
        "最高价": 3.44,
        "最低价": 3.32,
        "昨收价": 3.4,
        "总市值": 74422518.0,
        "市盈率": -2.98,
        "成交量": 23582.0,
        "成交额": 79925.0,
        "振幅": 3.53,
        "换手率": 0.11,
        "symbol": "105.NKGN"
    },
    {
        "序号": 6459,
        "name": "Near Intelligence Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NIRWW"
    },
    {
        "序号": 6460,
        "name": "NioCorp Developments Ltd Wt",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.4,
        "最高价": 0.4,
        "最低价": 0.39,
        "昨收价": 0.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 11590.0,
        "成交额": 4627.0,
        "振幅": 2.56,
        "换手率": null,
        "symbol": "105.NIOBW"
    },
    {
        "序号": 6461,
        "name": "NiSource Inc Series A Corporate",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 99.69,
        "总市值": 41213385313.0,
        "市盈率": 57.13,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.NIMC"
    },
    {
        "序号": 6462,
        "name": "Enphys Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 117813.0,
        "成交额": 3534.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.NFYS_WS"
    },
    {
        "序号": 6463,
        "name": "Enphys Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 199525973.0,
        "市盈率": 12.78,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.NFYS_U"
    },
    {
        "序号": 6464,
        "name": "Enphys Acquisition Corp-A",
        "最新价": 10.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.63,
        "最高价": 10.63,
        "最低价": 10.63,
        "昨收价": 10.63,
        "总市值": 200090669.0,
        "市盈率": 12.82,
        "成交量": 1204.0,
        "成交额": 12798.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.NFYS"
    },
    {
        "序号": 6465,
        "name": "The NFT Gaming Co Inc",
        "最新价": 0.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.35,
        "最高价": 0.36,
        "最低价": 0.34,
        "昨收价": 0.36,
        "总市值": 4346119.0,
        "市盈率": -1.25,
        "成交量": 81802.0,
        "成交额": 28020.0,
        "振幅": 5.56,
        "换手率": 0.68,
        "symbol": "105.NFTG"
    },
    {
        "序号": 6466,
        "name": "Noble Corp plc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.82,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.NE_WS_A"
    },
    {
        "序号": 6467,
        "name": "Noble Corp plc Wt",
        "最新价": 24.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.33,
        "最高价": 24.33,
        "最低价": 24.33,
        "昨收价": 24.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 95.0,
        "成交额": 2311.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.NE_WS"
    },
    {
        "序号": 6468,
        "name": "NextDecade Corp",
        "最新价": 5.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.02,
        "最高价": 5.11,
        "最低价": 4.95,
        "昨收价": 5.05,
        "总市值": 1295704593.0,
        "市盈率": -24.96,
        "成交量": 833672.0,
        "成交额": 4186919.0,
        "振幅": 3.17,
        "换手率": 0.32,
        "symbol": "105.NEXT"
    },
    {
        "序号": 6469,
        "name": "Newtek Business Services Corp N",
        "最新价": 24.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.68,
        "最高价": 24.73,
        "最低价": 24.66,
        "昨收价": 24.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 13084.0,
        "成交额": 323356.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "105.NEWTL"
    },
    {
        "序号": 6470,
        "name": "Newtek Business Services Corp N",
        "最新价": 24.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.86,
        "最高价": 24.87,
        "最低价": 24.86,
        "昨收价": 24.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 3159.0,
        "成交额": 78553.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.NEWTI"
    },
    {
        "序号": 6471,
        "name": "Nabors Energy Transition Corp I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NETDW"
    },
    {
        "序号": 6472,
        "name": "Nabors Energy Transition Corp I",
        "最新价": 10.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.37,
        "最高价": 10.37,
        "最低价": 10.37,
        "昨收价": 10.37,
        "总市值": 395356250.0,
        "市盈率": null,
        "成交量": 15.0,
        "成交额": 155.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NETDU"
    },
    {
        "序号": 6473,
        "name": "Nabors Energy Transition Corp I",
        "最新价": 10.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.31,
        "最高价": 10.32,
        "最低价": 10.31,
        "昨收价": 10.31,
        "总市值": 393068750.0,
        "市盈率": null,
        "成交量": 5630.0,
        "成交额": 58069.0,
        "振幅": 0.1,
        "换手率": 0.01,
        "symbol": "105.NETD"
    },
    {
        "序号": 6474,
        "name": "NEP Group Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.NEPG"
    },
    {
        "序号": 6475,
        "name": "NeoVolta Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.33,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NEOVW"
    },
    {
        "序号": 6476,
        "name": "New England Realty Associates L",
        "最新价": 69.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 69.5,
        "最高价": 69.5,
        "最低价": 69.5,
        "昨收价": 69.5,
        "总市值": 243571788.0,
        "市盈率": 29.36,
        "成交量": 184.0,
        "成交额": 12788.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.NEN"
    },
    {
        "序号": 6477,
        "name": "东北社区银行",
        "最新价": 17.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.22,
        "最高价": 17.29,
        "最低价": 16.96,
        "昨收价": 17.17,
        "总市值": 243751484.0,
        "市盈率": null,
        "成交量": 42348.0,
        "成交额": 727779.0,
        "振幅": 1.92,
        "换手率": 0.3,
        "symbol": "105.NECB"
    },
    {
        "序号": 6478,
        "name": "Noble Education Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NEATW"
    },
    {
        "序号": 6479,
        "name": "Noble Education Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NEATU"
    },
    {
        "序号": 6480,
        "name": "Noble Education Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NEATR"
    },
    {
        "序号": 6481,
        "name": "Noble Education Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NEAT"
    },
    {
        "序号": 6482,
        "name": "Nasdaq Inc Notes 2029",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NDAQ29"
    },
    {
        "序号": 6483,
        "name": "Nasdaq Inc Notes 2021",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NDAQ21"
    },
    {
        "序号": 6484,
        "name": "NCS Multistage Holdings Inc",
        "最新价": 14.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.03,
        "最高价": 14.03,
        "最低价": 14.03,
        "昨收价": 14.03,
        "总市值": 34220938.0,
        "市盈率": -0.84,
        "成交量": 26.0,
        "成交额": 364.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.NCSM"
    },
    {
        "序号": 6485,
        "name": "Netcapital Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NCPLW"
    },
    {
        "序号": 6486,
        "name": "noco-noco Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 17900.0,
        "成交额": 178.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NCNCW"
    },
    {
        "序号": 6487,
        "name": "Neo-Concept International Group",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NCI"
    },
    {
        "序号": 6488,
        "name": "新世纪储运(英属维京群岛)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NCEW"
    },
    {
        "序号": 6489,
        "name": "Newcourt Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.NCACW"
    },
    {
        "序号": 6490,
        "name": "Newcourt Acquisition Corp Unit ",
        "最新价": 11.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.75,
        "最高价": 11.0,
        "最低价": 10.75,
        "昨收价": 11.0,
        "总市值": 96668231.0,
        "市盈率": 63.79,
        "成交量": 455.0,
        "成交额": 4948.0,
        "振幅": 2.27,
        "换手率": null,
        "symbol": "105.NCACU"
    },
    {
        "序号": 6491,
        "name": "Newbury Street Acquisition Corp",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 2200.0,
        "成交额": 77.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NBSTW"
    },
    {
        "序号": 6492,
        "name": "Newbury Street Acquisition Corp",
        "最新价": 10.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.64,
        "总市值": 62323087.0,
        "市盈率": -104.07,
        "成交量": 2.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.NBSTU"
    },
    {
        "序号": 6493,
        "name": "Neuberger Berman China Equity E",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.19,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.NBCE"
    },
    {
        "序号": 6494,
        "name": "Nuveen Quality Municipal Income",
        "最新价": 11.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.11,
        "最高价": 11.18,
        "最低价": 11.1,
        "昨收价": 11.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 974003.0,
        "成交额": 10843644.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "106.NAD"
    },
    {
        "序号": 6495,
        "name": "Nuveen California Quality Munic",
        "最新价": 10.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.72,
        "最高价": 10.78,
        "最低价": 10.68,
        "昨收价": 10.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 383426.0,
        "成交额": 4115715.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.NAC"
    },
    {
        "序号": 6496,
        "name": "PlayStudios Inc Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 4810.0,
        "成交额": 450.0,
        "振幅": 11.11,
        "换手率": null,
        "symbol": "105.MYPSW"
    },
    {
        "序号": 6497,
        "name": "BlackRock MuniYield New York Qu",
        "最新价": 10.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.08,
        "最高价": 10.14,
        "最低价": 10.07,
        "昨收价": 10.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 55061.0,
        "成交额": 556092.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.MYN"
    },
    {
        "序号": 6498,
        "name": "BlackRock MuniYield Fund",
        "最新价": 10.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.58,
        "最高价": 10.61,
        "最低价": 10.54,
        "昨收价": 10.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 112670.0,
        "成交额": 1191154.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "106.MYD"
    },
    {
        "序号": 6499,
        "name": "Med-X Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MXRXW"
    },
    {
        "序号": 6500,
        "name": "Med-X Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MXRX"
    },
    {
        "序号": 6501,
        "name": "Multi Ways Holdings Ltd",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.24,
        "总市值": 7401600.0,
        "市盈率": -1.44,
        "成交量": 86286.0,
        "成交额": 20762.0,
        "振幅": 8.33,
        "换手率": 0.28,
        "symbol": "107.MWG"
    },
    {
        "序号": 6502,
        "name": "BlackRock MuniVest Fund II",
        "最新价": 10.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.65,
        "最高价": 10.68,
        "最低价": 10.62,
        "昨收价": 10.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 48387.0,
        "成交额": 515648.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "106.MVT"
    },
    {
        "序号": 6503,
        "name": "Maverick Lifestyle Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MVRK"
    },
    {
        "序号": 6504,
        "name": "Movella Holdings Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MVLAW"
    },
    {
        "序号": 6505,
        "name": "Muliang Viagoo Technology Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MULG"
    },
    {
        "序号": 6506,
        "name": "ETRACS 2x Leveraged MSCI US Mom",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 17.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.MTUL"
    },
    {
        "序号": 6507,
        "name": "Monterey Bio Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MTRYW"
    },
    {
        "序号": 6508,
        "name": "Monterey Bio Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.41,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MTRYU"
    },
    {
        "序号": 6509,
        "name": "Monterey Bio Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.14,
        "总市值": 48302260.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MTRY"
    },
    {
        "序号": 6510,
        "name": "Metros Development Co Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MTRS"
    },
    {
        "序号": 6511,
        "name": "寰泰生技",
        "最新价": 8.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.4,
        "最高价": 8.4,
        "最低价": 8.4,
        "昨收价": 8.4,
        "总市值": 15625294.0,
        "市盈率": -2.23,
        "成交量": 208.0,
        "成交额": 1747.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.MTEX"
    },
    {
        "序号": 6512,
        "name": "铭腾国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MTEN"
    },
    {
        "序号": 6513,
        "name": "Maris-Tech Ltd Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 320.0,
        "成交额": 8.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MTEKW"
    },
    {
        "序号": 6514,
        "name": "Maris-Tech Ltd",
        "最新价": 1.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.03,
        "最高价": 1.05,
        "最低价": 1.0,
        "昨收价": 1.04,
        "总市值": 8193641.0,
        "市盈率": -2.23,
        "成交量": 10853.0,
        "成交额": 11049.0,
        "振幅": 4.81,
        "换手率": 0.14,
        "symbol": "105.MTEK"
    },
    {
        "序号": 6515,
        "name": "海川证券",
        "最新价": 1.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.08,
        "最高价": 1.1,
        "最低价": 1.03,
        "昨收价": 1.05,
        "总市值": 209102293.0,
        "市盈率": -12.2,
        "成交量": 51929.0,
        "成交额": 55078.0,
        "振幅": 6.67,
        "换手率": 0.03,
        "symbol": "105.MTC"
    },
    {
        "序号": 6516,
        "name": "Metals Acquisition Ltd",
        "最新价": 9.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.99,
        "最高价": 9.99,
        "最低价": 9.8,
        "昨收价": 9.9,
        "总市值": 479253535.0,
        "市盈率": -116.3,
        "成交量": 17411.0,
        "成交额": 172319.0,
        "振幅": 1.92,
        "换手率": 0.04,
        "symbol": "106.MTAL"
    },
    {
        "序号": 6517,
        "name": "明成集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MSW"
    },
    {
        "序号": 6518,
        "name": "Madison Short-Term Strategic In",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.31,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.MSTI"
    },
    {
        "序号": 6519,
        "name": "Metal Sky Star Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MSSAW"
    },
    {
        "序号": 6520,
        "name": "Metal Sky Star Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 70481576.0,
        "市盈率": 27.1,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MSSAU"
    },
    {
        "序号": 6521,
        "name": "Metal Sky Star Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MSSAR"
    },
    {
        "序号": 6522,
        "name": "Emerson Radio Corp",
        "最新价": 0.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.55,
        "最高价": 0.55,
        "最低价": 0.55,
        "昨收价": 0.55,
        "总市值": 11573459.0,
        "市盈率": 5.97,
        "成交量": 7713.0,
        "成交额": 4243.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "107.MSN"
    },
    {
        "序号": 6523,
        "name": "毛氏翡翠",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MSFC"
    },
    {
        "序号": 6524,
        "name": "Marti Technologies Inc Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.08,
        "最高价": 0.09,
        "最低价": 0.08,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 900.0,
        "成交额": 80.0,
        "振幅": 11.11,
        "换手率": null,
        "symbol": "107.MRT_WS"
    },
    {
        "序号": 6525,
        "name": "MariaDB plc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MRDB_WS"
    },
    {
        "序号": 6526,
        "name": "MariaDB plc",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.25,
        "最高价": 0.31,
        "最低价": 0.25,
        "昨收价": 0.3,
        "总市值": 20306815.0,
        "市盈率": -0.22,
        "成交量": 27064.0,
        "成交额": 7438.0,
        "振幅": 20.0,
        "换手率": 0.04,
        "symbol": "106.MRDB"
    },
    {
        "序号": 6527,
        "name": "BlackRock MuniYield Quality Fun",
        "最新价": 11.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.77,
        "最高价": 11.79,
        "最低价": 11.73,
        "昨收价": 11.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 172921.0,
        "成交额": 2033922.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "106.MQY"
    },
    {
        "序号": 6528,
        "name": "MultiPlan Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 90.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.MPLN_WS"
    },
    {
        "序号": 6529,
        "name": "Akros Monthly Payout ETF",
        "最新价": 22.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.28,
        "最高价": 22.28,
        "最低价": 22.28,
        "昨收价": 22.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 40.0,
        "成交额": 891.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MPAY"
    },
    {
        "序号": 6530,
        "name": "穆格-B",
        "最新价": 141.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 141.83,
        "最高价": 141.83,
        "最低价": 141.83,
        "昨收价": 141.83,
        "总市值": 4522572922.0,
        "市盈率": 26.45,
        "成交量": 73.0,
        "成交额": 10353.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.MOG_B"
    },
    {
        "序号": 6531,
        "name": "Mobiv Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 102119.0,
        "成交额": 3307.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.MOBVW"
    },
    {
        "序号": 6532,
        "name": "Mobiquity Technologies Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MOBQW"
    },
    {
        "序号": 6533,
        "name": "MoneyHero Ltd Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.07,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 138536.0,
        "成交额": 12309.0,
        "振幅": 22.22,
        "换手率": null,
        "symbol": "105.MNYWW"
    },
    {
        "序号": 6534,
        "name": "MN8 Energy Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MNX"
    },
    {
        "序号": 6535,
        "name": "Momentus Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 3555.0,
        "成交额": 34.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MNTSW"
    },
    {
        "序号": 6536,
        "name": "Everest Consolidator Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 234168750.0,
        "市盈率": -35.05,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MNTN_U"
    },
    {
        "序号": 6537,
        "name": "ALPS Intermediate Municipal Bon",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.71,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.MNBD"
    },
    {
        "序号": 6538,
        "name": "元宇宙控股(权证)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MMVWW"
    },
    {
        "序号": 6539,
        "name": "Western Asset Managed Municipal",
        "最新价": 9.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.95,
        "最高价": 9.98,
        "最低价": 9.91,
        "昨收价": 9.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 177845.0,
        "成交额": 1770785.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.MMU"
    },
    {
        "序号": 6540,
        "name": "MFS Multimarket Income Trust",
        "最新价": 4.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.47,
        "最高价": 4.48,
        "最低价": 4.44,
        "昨收价": 4.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 82849.0,
        "成交额": 369040.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "106.MMT"
    },
    {
        "序号": 6541,
        "name": "MoneyLion Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 66784.0,
        "成交额": 3014.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "106.ML_WS"
    },
    {
        "序号": 6542,
        "name": "VanEck ETF Trust VanEck Long Mu",
        "最新价": 17.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.78,
        "最高价": 17.83,
        "最低价": 17.72,
        "昨收价": 17.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 674814.0,
        "成交额": 12010496.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.MLN"
    },
    {
        "序号": 6543,
        "name": "Moolec Science SA Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 6260.0,
        "成交额": 125.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MLECW"
    },
    {
        "序号": 6544,
        "name": "Markforged Holding Corp Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 7300.0,
        "成交额": 418.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.MKFG_WS"
    },
    {
        "序号": 6545,
        "name": "Majestic Ideal控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MJID"
    },
    {
        "序号": 6546,
        "name": "AG Mortgage Investment Trust In",
        "最新价": 20.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 20.85,
        "最高价": 20.85,
        "最低价": 20.75,
        "昨收价": 20.85,
        "总市值": 95910000.0,
        "市盈率": null,
        "成交量": 3706.0,
        "成交额": 77205.0,
        "振幅": 0.48,
        "换手率": 0.08,
        "symbol": "106.MITT_C"
    },
    {
        "序号": 6547,
        "name": "AG Mortgage Investment Trust In",
        "最新价": 18.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 18.8,
        "最高价": 18.8,
        "最低价": 18.8,
        "昨收价": 18.8,
        "总市值": 38916000.0,
        "市盈率": null,
        "成交量": 141.0,
        "成交额": 2650.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.MITT_A"
    },
    {
        "序号": 6548,
        "name": "Coliseum Acquisition Corp Wts",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 550.0,
        "成交额": 30.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MITAW"
    },
    {
        "序号": 6549,
        "name": "Coliseum Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.55,
        "总市值": 101577521.0,
        "市盈率": 23.76,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MITAU"
    },
    {
        "序号": 6550,
        "name": "Coliseum Acquisition Corp-A",
        "最新价": 10.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.64,
        "总市值": 102444059.0,
        "市盈率": 23.96,
        "成交量": 47.0,
        "成交额": 499.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MITA"
    },
    {
        "序号": 6551,
        "name": "Mission Control Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MISN_WS"
    },
    {
        "序号": 6552,
        "name": "Mission Control Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MISN_U"
    },
    {
        "序号": 6553,
        "name": "Mission Control Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MISN"
    },
    {
        "序号": 6554,
        "name": "Pioneer Municipal High Income O",
        "最新价": 10.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.25,
        "最高价": 10.37,
        "最低价": 10.25,
        "昨收价": 10.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 66363.0,
        "成交额": 683443.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "106.MIO"
    },
    {
        "序号": 6555,
        "name": "PIMCO Municipal Income Opportun",
        "最新价": 44.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 44.73,
        "最高价": 44.74,
        "最低价": 44.66,
        "昨收价": 44.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 21371.0,
        "成交额": 954711.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.MINO"
    },
    {
        "序号": 6556,
        "name": "Airspan Networks Holdings Inc",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 8210278.0,
        "市盈率": -0.15,
        "成交量": 169055.0,
        "成交额": 18513.0,
        "振幅": 9.09,
        "换手率": 0.23,
        "symbol": "107.MIMO"
    },
    {
        "序号": 6557,
        "name": "NFT Ltd",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.18,
        "最高价": 0.19,
        "最低价": 0.17,
        "昨收价": 0.18,
        "总市值": 6298539.0,
        "市盈率": 1.66,
        "成交量": 213104.0,
        "成交额": 38452.0,
        "振幅": 11.11,
        "换手率": 0.61,
        "symbol": "107.MI"
    },
    {
        "序号": 6558,
        "name": "BlackRock MuniHoldings Fund",
        "最新价": 11.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.6,
        "最高价": 11.68,
        "最低价": 11.6,
        "昨收价": 11.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 118859.0,
        "成交额": 1383795.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.MHD"
    },
    {
        "序号": 6559,
        "name": "The Marygold Companies Inc",
        "最新价": 1.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.02,
        "最高价": 1.08,
        "最低价": 1.02,
        "昨收价": 1.03,
        "总市值": 40564963.0,
        "市盈率": 241.92,
        "成交量": 2380.0,
        "成交额": 2458.0,
        "振幅": 5.83,
        "换手率": 0.01,
        "symbol": "107.MGLD"
    },
    {
        "序号": 6560,
        "name": "Mistras Group Inc",
        "最新价": 6.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.62,
        "最高价": 6.74,
        "最低价": 6.47,
        "昨收价": 6.59,
        "总市值": 200026929.0,
        "市盈率": -16.53,
        "成交量": 24472.0,
        "成交额": 161786.0,
        "振幅": 4.1,
        "换手率": 0.08,
        "symbol": "106.MG"
    },
    {
        "序号": 6561,
        "name": "移动财经",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MFI"
    },
    {
        "序号": 6562,
        "name": "每日优鲜",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 1.0,
        "总市值": 8143033.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MF"
    },
    {
        "序号": 6563,
        "name": "Medalist Diversified REIT Inc S",
        "最新价": 23.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.45,
        "最高价": 23.45,
        "最低价": 23.45,
        "昨收价": 23.45,
        "总市值": 4690000.0,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 93.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MDRRP"
    },
    {
        "序号": 6564,
        "name": "Mondelez International Inc Note",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLZ45"
    },
    {
        "序号": 6565,
        "name": "Mondelez International Inc Note",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLZ35A"
    },
    {
        "序号": 6566,
        "name": "Mondelez International Inc Note",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLZ35"
    },
    {
        "序号": 6567,
        "name": "Mondelez International Inc Note",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLZ27"
    },
    {
        "序号": 6568,
        "name": "Mondelez International Inc Note",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLZ21"
    },
    {
        "序号": 6569,
        "name": "Medallion Resources Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLW"
    },
    {
        "序号": 6570,
        "name": "MDNA Life Sciences Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLSW"
    },
    {
        "序号": 6571,
        "name": "MDNA Life Sciences Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDLS"
    },
    {
        "序号": 6572,
        "name": "Medallion Resources Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDL"
    },
    {
        "序号": 6573,
        "name": "MediaCo Holding Inc-A",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.63,
        "最高价": 0.63,
        "最低价": 0.63,
        "昨收价": 0.63,
        "总市值": 16606101.0,
        "市盈率": 0.46,
        "成交量": 659.0,
        "成交额": 412.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MDIA"
    },
    {
        "序号": 6574,
        "name": "Spectral AI Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MDAIW"
    },
    {
        "序号": 6575,
        "name": "Martin Currie Sustainable Inter",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 13.87,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MCSE"
    },
    {
        "序号": 6576,
        "name": "Micromobility.com Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 67262.0,
        "成交额": 1231.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.MCOMW"
    },
    {
        "序号": 6577,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 10.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.75,
        "最高价": 10.75,
        "最低价": 10.75,
        "昨收价": 10.75,
        "总市值": 34079876.0,
        "市盈率": -16.57,
        "成交量": 3.0,
        "成交额": 32.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MCAGU"
    },
    {
        "序号": 6578,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 0.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.12,
        "最高价": 0.12,
        "最低价": 0.12,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 50.0,
        "成交额": 6.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MCAGR"
    },
    {
        "序号": 6579,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 10.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.67,
        "最高价": 10.67,
        "最低价": 10.67,
        "昨收价": 10.67,
        "总市值": 33826258.0,
        "市盈率": -16.45,
        "成交量": 1.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MCAG"
    },
    {
        "序号": 6580,
        "name": "Monterey Capital Acquisition Co",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 36071.0,
        "成交额": 324.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MCACW"
    },
    {
        "序号": 6581,
        "name": "Monterey Capital Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.88,
        "总市值": 105276240.0,
        "市盈率": -8.5,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MCACU"
    },
    {
        "序号": 6582,
        "name": "Monterey Capital Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MCACR"
    },
    {
        "序号": 6583,
        "name": "Mountain & Co I Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MCAAW"
    },
    {
        "序号": 6584,
        "name": "Mountain & Co I Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.2,
        "总市值": 193346328.0,
        "市盈率": 37.08,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MCAAU"
    },
    {
        "序号": 6585,
        "name": "Mountain & Co I Acquisition Cor",
        "最新价": 11.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.23,
        "最高价": 11.23,
        "最低价": 11.23,
        "昨收价": 11.23,
        "总市值": 193864220.0,
        "市盈率": 37.18,
        "成交量": 411.0,
        "成交额": 4615.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MCAA"
    },
    {
        "序号": 6586,
        "name": "Nocturne Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.64,
        "总市值": 60428082.0,
        "市盈率": -40.04,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MBTCU"
    },
    {
        "序号": 6587,
        "name": "Nocturne Acquisition Corp Rt",
        "最新价": 0.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.16,
        "最高价": 0.16,
        "最低价": 0.16,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 16.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.MBTCR"
    },
    {
        "序号": 6588,
        "name": "M3-Brigade Acquisition II Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MBAC_WS"
    },
    {
        "序号": 6589,
        "name": "M3-Brigade Acquisition II Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.49,
        "总市值": 152492931.0,
        "市盈率": 30.72,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.MBAC_U"
    },
    {
        "序号": 6590,
        "name": "梅斯物业",
        "最新价": 43.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 43.5,
        "最高价": 43.5,
        "最低价": 43.5,
        "昨收价": 43.5,
        "总市值": 87686430.0,
        "市盈率": -261.73,
        "成交量": 65.0,
        "成交额": 2827.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MAYS"
    },
    {
        "序号": 6591,
        "name": "Pioneer Municipal High Income A",
        "最新价": 7.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.76,
        "最高价": 7.77,
        "最低价": 7.72,
        "昨收价": 7.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 79639.0,
        "成交额": 616158.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "106.MAV"
    },
    {
        "序号": 6592,
        "name": "Mars Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.79,
        "总市值": 100260680.0,
        "市盈率": 116.43,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MARXU"
    },
    {
        "序号": 6593,
        "name": "Mars Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.22,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MARXR"
    },
    {
        "序号": 6594,
        "name": "Mars Acquisition Corp",
        "最新价": 10.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.59,
        "最高价": 10.59,
        "最低价": 10.59,
        "昨收价": 10.59,
        "总市值": 98402280.0,
        "市盈率": 114.27,
        "成交量": 3923.0,
        "成交额": 41544.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.MARX"
    },
    {
        "序号": 6595,
        "name": "Maquia Capital Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.5,
        "总市值": 71012144.0,
        "市盈率": -56.65,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MAQCU"
    },
    {
        "序号": 6596,
        "name": "Maquia Capital Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.46,
        "总市值": 70765145.0,
        "市盈率": -56.45,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MAQC"
    },
    {
        "序号": 6597,
        "name": "WM Technology Inc Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 12912.0,
        "成交额": 694.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "105.MAPSW"
    },
    {
        "序号": 6598,
        "name": "Moringa Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MACAW"
    },
    {
        "序号": 6599,
        "name": "Moringa Acquisition Corp Unit e",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 41796205.0,
        "市盈率": 40.01,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MACAU"
    },
    {
        "序号": 6600,
        "name": "Moringa Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.85,
        "总市值": 41989706.0,
        "市盈率": 40.19,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.MACA"
    },
    {
        "序号": 6601,
        "name": "Lifezone Metals Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.51,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LZM_WS"
    },
    {
        "序号": 6602,
        "name": "Lytus Technologies Holdings PTV",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LYTWW"
    },
    {
        "序号": 6603,
        "name": "LSB Industries Inc",
        "最新价": 9.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.04,
        "最高价": 9.17,
        "最低价": 9.01,
        "昨收价": 9.05,
        "总市值": 673320000.0,
        "市盈率": 6.79,
        "成交量": 285722.0,
        "成交额": 2587455.0,
        "振幅": 1.77,
        "换手率": 0.38,
        "symbol": "106.LXU"
    },
    {
        "序号": 6604,
        "name": "莱斯康制药",
        "最新价": 1.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.25,
        "最高价": 1.3,
        "最低价": 1.24,
        "昨收价": 1.25,
        "总市值": 306155869.0,
        "市盈率": -1.94,
        "成交量": 1953122.0,
        "成交额": 2455761.0,
        "振幅": 4.8,
        "换手率": 0.8,
        "symbol": "105.LXRX"
    },
    {
        "序号": 6605,
        "name": "LiveVox Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LVOXW"
    },
    {
        "序号": 6606,
        "name": "LiveVox Holdings Inc Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 3.94,
        "总市值": 372864240.0,
        "市盈率": -15.19,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LVOXU"
    },
    {
        "序号": 6607,
        "name": "LiveOne Inc Series A Pfd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LVOPP"
    },
    {
        "序号": 6608,
        "name": "ProLung Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LUNG_"
    },
    {
        "序号": 6609,
        "name": "Innovative Eyewear Inc Wt-A",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 18.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LUCYW"
    },
    {
        "序号": 6610,
        "name": "Lottery.com Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 17197.0,
        "成交额": 152.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LTRYW"
    },
    {
        "序号": 6611,
        "name": "Pacer Salt Low truBeta US Marke",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LSLT_"
    },
    {
        "序号": 6612,
        "name": "Natixis Loomis Sayles Focused G",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.82,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LSGR"
    },
    {
        "序号": 6613,
        "name": "Harbor Long-Short Equity ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.93,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LSEQ"
    },
    {
        "序号": 6614,
        "name": "Loop Industries Inc",
        "最新价": 3.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.38,
        "最高价": 3.6,
        "最低价": 3.38,
        "昨收价": 3.52,
        "总市值": 167274578.0,
        "市盈率": -22.79,
        "成交量": 12697.0,
        "成交额": 44765.0,
        "振幅": 6.25,
        "换手率": 0.03,
        "symbol": "105.LOOP"
    },
    {
        "序号": 6615,
        "name": "乐活",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LOHA"
    },
    {
        "序号": 6616,
        "name": "Live Oak Crestview Climate Acqu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LOCC_WS"
    },
    {
        "序号": 6617,
        "name": "Live Oak Crestview Climate Acqu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.39,
        "总市值": 259750000.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LOCC_U"
    },
    {
        "序号": 6618,
        "name": "Live Oak Crestview Climate Acqu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.43,
        "总市值": 260750000.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LOCC"
    },
    {
        "序号": 6619,
        "name": "萝贝",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LOBO"
    },
    {
        "序号": 6620,
        "name": "曼哈顿大桥投资股份",
        "最新价": 4.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.63,
        "最高价": 4.69,
        "最低价": 4.62,
        "昨收价": 4.67,
        "总市值": 53469562.0,
        "市盈率": 10.05,
        "成交量": 12238.0,
        "成交额": 56961.0,
        "振幅": 1.5,
        "换手率": 0.11,
        "symbol": "105.LOAN"
    },
    {
        "序号": 6621,
        "name": "BrasilAgro – Companhia Brasile",
        "最新价": 5.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.0,
        "最高价": 5.01,
        "最低价": 4.94,
        "昨收价": 5.0,
        "总市值": 511885040.0,
        "市盈率": 9.49,
        "成交量": 32605.0,
        "成交额": 161984.0,
        "振幅": 1.4,
        "换手率": 0.03,
        "symbol": "106.LND"
    },
    {
        "序号": 6622,
        "name": "Lemonade Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LMND_WS"
    },
    {
        "序号": 6623,
        "name": "LumiraDx Ltd Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 40615.0,
        "成交额": 321.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LMDXW"
    },
    {
        "序号": 6624,
        "name": "LumiraDx Ltd",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": 31854627.0,
        "市盈率": -0.09,
        "成交量": 4858331.0,
        "成交额": 484994.0,
        "振幅": 0.0,
        "换手率": 1.53,
        "symbol": "105.LMDX"
    },
    {
        "序号": 6625,
        "name": "瑞幸咖啡",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LK"
    },
    {
        "序号": 6626,
        "name": "Lixte Biotechnology Holdings In",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 310.0,
        "成交额": 13.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LIXTW"
    },
    {
        "序号": 6627,
        "name": "LIV Capital Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LIVBW"
    },
    {
        "序号": 6628,
        "name": "LIV Capital Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 73572389.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LIVBU"
    },
    {
        "序号": 6629,
        "name": "LIV Capital Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.97,
        "总市值": 73371737.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LIVB"
    },
    {
        "序号": 6630,
        "name": "MSP Recovery Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LIFWZ"
    },
    {
        "序号": 6631,
        "name": "MSP Recovery Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": null,
        "最高价": 0.01,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 267004.0,
        "成交额": 1364.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LIFWW"
    },
    {
        "序号": 6632,
        "name": "AEye Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 2604.0,
        "成交额": 15.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LIDRW"
    },
    {
        "序号": 6633,
        "name": "AEye Inc-A",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.13,
        "最高价": 0.13,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": 24458608.0,
        "市盈率": -0.29,
        "成交量": 668244.0,
        "成交额": 84909.0,
        "振幅": 0.0,
        "换手率": 0.36,
        "symbol": "105.LIDR"
    },
    {
        "序号": 6634,
        "name": "Liberty Resources Acquisition C",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 668.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LIBYW"
    },
    {
        "序号": 6635,
        "name": "Liberty Resources Acquisition C",
        "最新价": 10.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.89,
        "最高价": 10.89,
        "最低价": 10.89,
        "昨收价": 10.89,
        "总市值": 78532799.0,
        "市盈率": 148.89,
        "成交量": 1.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LIBYU"
    },
    {
        "序号": 6636,
        "name": "乐盟互动",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LIAI"
    },
    {
        "序号": 6637,
        "name": "Leo Holdings Corp II Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LHC_U"
    },
    {
        "序号": 6638,
        "name": "Leo Holdings Corp II-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.87,
        "总市值": 165597943.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LHC"
    },
    {
        "序号": 6639,
        "name": "LAMF Global Ventures Corp I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGVCW"
    },
    {
        "序号": 6640,
        "name": "LAMF Global Ventures Corp I Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.36,
        "总市值": 129416592.0,
        "市盈率": -78.56,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGVCU"
    },
    {
        "序号": 6641,
        "name": "Semper Paratus Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.95,
        "总市值": 157130014.0,
        "市盈率": 46.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGSTU"
    },
    {
        "序号": 6642,
        "name": "六个喵",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGM"
    },
    {
        "序号": 6643,
        "name": "The LGL Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.24,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LGL_WS"
    },
    {
        "序号": 6644,
        "name": "狮子集团控股(权证)",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 6.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LGHLW"
    },
    {
        "序号": 6645,
        "name": "罗科仕",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGCL"
    },
    {
        "序号": 6646,
        "name": "Linkage Global Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LGCB"
    },
    {
        "序号": 6647,
        "name": "LifeMD Inc Series A Pfd",
        "最新价": 19.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 19.0,
        "最高价": 19.0,
        "最低价": 18.99,
        "昨收价": 19.0,
        "总市值": 26600000.0,
        "市盈率": null,
        "成交量": 5798.0,
        "成交额": 110157.0,
        "振幅": 0.05,
        "换手率": 0.41,
        "symbol": "105.LFMDP"
    },
    {
        "序号": 6648,
        "name": "Leafly Holdings Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1820.0,
        "成交额": 36.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LFLYW"
    },
    {
        "序号": 6649,
        "name": "Lianfeng Holding Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LFKG"
    },
    {
        "序号": 6650,
        "name": "Lexaria Bioscience Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.52,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LEXXW"
    },
    {
        "序号": 6651,
        "name": "LeeWay Services Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LEWY"
    },
    {
        "序号": 6652,
        "name": "Lands’ End Inc",
        "最新价": 8.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.54,
        "最高价": 8.71,
        "最低价": 8.32,
        "昨收价": 8.52,
        "总市值": 267917475.0,
        "市盈率": -2.14,
        "成交量": 118987.0,
        "成交额": 1004496.0,
        "振幅": 4.58,
        "换手率": 0.38,
        "symbol": "105.LE"
    },
    {
        "序号": 6653,
        "name": "Learn CW Investment Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LCW_WS"
    },
    {
        "序号": 6654,
        "name": "Learn CW Investment Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.67,
        "总市值": 160993452.0,
        "市盈率": 16.3,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LCW_U"
    },
    {
        "序号": 6655,
        "name": "Learn CW Investment Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 162049642.0,
        "市盈率": 16.41,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.LCW"
    },
    {
        "序号": 6656,
        "name": "Landcadia Holdings IV Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LCAHW"
    },
    {
        "序号": 6657,
        "name": "Landcadia Holdings IV Inc Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.42,
        "总市值": 141279810.0,
        "市盈率": 54.25,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LCAHU"
    },
    {
        "序号": 6658,
        "name": "L Catterton Asia Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 313778326.0,
        "市盈率": 390.38,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LCAAU"
    },
    {
        "序号": 6659,
        "name": "L Catterton Asia Acquisition Co",
        "最新价": 10.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.73,
        "最高价": 10.74,
        "最低价": 10.73,
        "昨收价": 10.74,
        "总市值": 310883692.0,
        "市盈率": 386.78,
        "成交量": 233101.0,
        "成交额": 2501209.0,
        "振幅": 0.09,
        "换手率": 0.81,
        "symbol": "105.LCAA"
    },
    {
        "序号": 6660,
        "name": "自由全球-B",
        "最新价": 16.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.2,
        "最高价": 16.2,
        "最低价": 16.2,
        "昨收价": 16.2,
        "总市值": 6402308510.0,
        "市盈率": null,
        "成交量": 61.0,
        "成交额": 988.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.LBTYB"
    },
    {
        "序号": 6661,
        "name": "自由全球-A",
        "最新价": 16.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.16,
        "最高价": 16.27,
        "最低价": 15.81,
        "昨收价": 16.19,
        "总市值": 6398356468.0,
        "市盈率": null,
        "成交量": 1974523.0,
        "成交额": 31797986.0,
        "振幅": 2.84,
        "换手率": 1.15,
        "symbol": "105.LBTYA"
    },
    {
        "序号": 6662,
        "name": "WHITEWOLF Publicly Listed Priva",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LBO"
    },
    {
        "序号": 6663,
        "name": "利邦",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LBGJ"
    },
    {
        "序号": 6664,
        "name": "Lakeshore Acquisition II Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LBBBW"
    },
    {
        "序号": 6665,
        "name": "Lakeshore Acquisition II Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.93,
        "总市值": 62622582.0,
        "市盈率": 41.79,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LBBBU"
    },
    {
        "序号": 6666,
        "name": "Lakeshore Acquisition II Corp R",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.11,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LBBBR"
    },
    {
        "序号": 6667,
        "name": "Leatherback Long\/Short Absolute",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.LBAR"
    },
    {
        "序号": 6668,
        "name": "Lazydays Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.LAZYW"
    },
    {
        "序号": 6669,
        "name": "Chenghe Acquisition I Co Unit C",
        "最新价": 11.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.7,
        "最高价": 11.7,
        "最低价": 11.7,
        "昨收价": 11.7,
        "总市值": 103550651.0,
        "市盈率": 23.37,
        "成交量": 1.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LATGU"
    },
    {
        "序号": 6670,
        "name": "Chenghe Acquisition I Co-A",
        "最新价": 11.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.01,
        "最高价": 11.01,
        "最低价": 11.01,
        "昨收价": 11.01,
        "总市值": 97443818.0,
        "市盈率": 21.99,
        "成交量": 1.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.LATG"
    },
    {
        "序号": 6671,
        "name": "Keyarch Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KYCHW"
    },
    {
        "序号": 6672,
        "name": "Keyarch Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.78,
        "总市值": 64651088.0,
        "市盈率": 31.51,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KYCHU"
    },
    {
        "序号": 6673,
        "name": "Keyarch Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KYCHR"
    },
    {
        "序号": 6674,
        "name": "Keyarch Acquisition Corp-A",
        "最新价": 10.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.82,
        "最高价": 10.84,
        "最低价": 10.82,
        "昨收价": 10.83,
        "总市值": 64950954.0,
        "市盈率": 31.65,
        "成交量": 31944.0,
        "成交额": 345753.0,
        "振幅": 0.18,
        "换手率": 0.53,
        "symbol": "105.KYCH"
    },
    {
        "序号": 6675,
        "name": "KWESST Micro Systems Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KWESW"
    },
    {
        "序号": 6676,
        "name": "Keen Vision Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KVACW"
    },
    {
        "序号": 6677,
        "name": "Keen Vision Acquisition Corp Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.33,
        "总市值": 200051555.0,
        "市盈率": -643.53,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KVACU"
    },
    {
        "序号": 6678,
        "name": "Keen Vision Acquisition Corp",
        "最新价": 10.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.32,
        "最高价": 10.4,
        "最低价": 10.32,
        "昨收价": 10.32,
        "总市值": 199857894.0,
        "市盈率": -642.91,
        "成交量": 7532.0,
        "成交额": 77744.0,
        "振幅": 0.78,
        "换手率": 0.04,
        "symbol": "105.KVAC"
    },
    {
        "序号": 6679,
        "name": "KraneShares MSCI All China Heal",
        "最新价": 17.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.18,
        "最高价": 17.32,
        "最低价": 17.18,
        "昨收价": 17.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 7080.0,
        "成交额": 122002.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.KURE"
    },
    {
        "序号": 6680,
        "name": "库客音乐",
        "最新价": 1.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.07,
        "最高价": 1.07,
        "最低价": 1.05,
        "昨收价": 1.05,
        "总市值": 34914309.0,
        "市盈率": -0.27,
        "成交量": 2161.0,
        "成交额": 2276.0,
        "振幅": 1.9,
        "换手率": 0.01,
        "symbol": "106.KUKE"
    },
    {
        "序号": 6681,
        "name": "Pasithea Therapeutics Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KTTAW"
    },
    {
        "序号": 6682,
        "name": "Kernel Group Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KRNLW"
    },
    {
        "序号": 6683,
        "name": "Kernel Group Holdings Inc Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.61,
        "总市值": 147847156.0,
        "市盈率": -27.04,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KRNLU"
    },
    {
        "序号": 6684,
        "name": "吉劳埃地产",
        "最新价": 38.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 38.22,
        "最高价": 38.61,
        "最低价": 37.76,
        "昨收价": 38.59,
        "总市值": 4524274543.0,
        "市盈率": 20.79,
        "成交量": 1881680.0,
        "成交额": 72345650.0,
        "振幅": 2.2,
        "换手率": 1.6,
        "symbol": "106.KRC"
    },
    {
        "序号": 6685,
        "name": "Kiora Pharmaceuticals Inc",
        "最新价": 0.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.53,
        "最高价": 0.54,
        "最低价": 0.51,
        "昨收价": 0.54,
        "总市值": 4152190.0,
        "市盈率": -0.33,
        "成交量": 42321.0,
        "成交额": 22292.0,
        "振幅": 5.56,
        "换手率": 0.55,
        "symbol": "105.KPRX"
    },
    {
        "序号": 6686,
        "name": "柯普尼",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KPN"
    },
    {
        "序号": 6687,
        "name": "Katapult Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 67074.0,
        "成交额": 424.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.KPLTW"
    },
    {
        "序号": 6688,
        "name": "KORE Group Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KORE_WS"
    },
    {
        "序号": 6689,
        "name": "群核科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KOOL"
    },
    {
        "序号": 6690,
        "name": "KnightSwan Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KNSW_WS"
    },
    {
        "序号": 6691,
        "name": "KnightSwan Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.58,
        "总市值": 109975365.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KNSW_U"
    },
    {
        "序号": 6692,
        "name": "KnightSwan Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 110183258.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KNSW"
    },
    {
        "序号": 6693,
        "name": "Kayne Anderson Midstream\/Energy",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 6.72,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KMF"
    },
    {
        "序号": 6694,
        "name": "Key Mining Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KMCM"
    },
    {
        "序号": 6695,
        "name": "Kelso Technologies Inc",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.15,
        "总市值": 8148013.0,
        "市盈率": -3.46,
        "成交量": 105068.0,
        "成交额": 15251.0,
        "振幅": 6.67,
        "换手率": 0.19,
        "symbol": "107.KIQ"
    },
    {
        "序号": 6696,
        "name": "Akerna Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1190.0,
        "成交额": 12.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.KERNW"
    },
    {
        "序号": 6697,
        "name": "Global X China Innovation ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.88,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KEJI"
    },
    {
        "序号": 6698,
        "name": "KraneShares S&P Pan Asia Divide",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 27.57,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.KDIV"
    },
    {
        "序号": 6699,
        "name": "Kensington Capital Acquisition ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.77,
        "总市值": 123238234.0,
        "市盈率": 12.57,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.KCGI_U"
    },
    {
        "序号": 6700,
        "name": "Kensington Capital Acquisition ",
        "最新价": 10.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.63,
        "最高价": 10.63,
        "最低价": 10.63,
        "昨收价": 10.63,
        "总市值": 121636252.0,
        "市盈率": 12.41,
        "成交量": 630.0,
        "成交额": 6696.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.KCGI"
    },
    {
        "序号": 6701,
        "name": "Kubient Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KBNTW"
    },
    {
        "序号": 6702,
        "name": "Kubient Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": 1472704.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KBNT"
    },
    {
        "序号": 6703,
        "name": "KraneShares Bloomberg China Bon",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 30.81,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.KBND"
    },
    {
        "序号": 6704,
        "name": "Carbon Strategy ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 33.63,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.KARB"
    },
    {
        "序号": 6705,
        "name": "爱康国宾",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KANG"
    },
    {
        "序号": 6706,
        "name": "Kairous Acquisition Corp Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KACLW"
    },
    {
        "序号": 6707,
        "name": "Kairous Acquisition Corp Ltd Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 48795549.0,
        "市盈率": 208.2,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KACLU"
    },
    {
        "序号": 6708,
        "name": "Kairous Acquisition Corp Ltd Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.KACLR"
    },
    {
        "序号": 6709,
        "name": "Kairous Acquisition Corp Ltd",
        "最新价": 11.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.45,
        "最高价": 11.45,
        "最低价": 11.45,
        "昨收价": 11.45,
        "总市值": 50791731.0,
        "市盈率": 216.72,
        "成交量": 2553.0,
        "成交额": 29231.0,
        "振幅": 0.0,
        "换手率": 0.06,
        "symbol": "105.KACL"
    },
    {
        "序号": 6710,
        "name": "极之龙农林科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JZL"
    },
    {
        "序号": 6711,
        "name": "健永生技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JYB"
    },
    {
        "序号": 6712,
        "name": "Ares Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 28577.0,
        "成交额": 294.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JWSM_WS"
    },
    {
        "序号": 6713,
        "name": "Ares Acquisition Corp Unit Cons",
        "最新价": 10.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.83,
        "最高价": 10.83,
        "最低价": 10.83,
        "昨收价": 10.83,
        "总市值": 303008390.0,
        "市盈率": 19.48,
        "成交量": 600.0,
        "成交额": 6498.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JWSM_U"
    },
    {
        "序号": 6714,
        "name": "JVSPAC Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JVSAU"
    },
    {
        "序号": 6715,
        "name": "JVSPAC Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JVSAR"
    },
    {
        "序号": 6716,
        "name": "JVSPAC Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JVSAC"
    },
    {
        "序号": 6717,
        "name": "Coffee Holding Co Inc",
        "最新价": 0.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.82,
        "最高价": 0.84,
        "最低价": 0.8,
        "昨收价": 0.82,
        "总市值": 4681051.0,
        "市盈率": -0.98,
        "成交量": 10421.0,
        "成交额": 8557.0,
        "振幅": 4.88,
        "换手率": 0.18,
        "symbol": "105.JVA"
    },
    {
        "序号": 6718,
        "name": "TrueShares Structured Outcome (",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.92,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.JUNZ"
    },
    {
        "序号": 6719,
        "name": "Junee Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JUNE"
    },
    {
        "序号": 6720,
        "name": "Innovator Premium Income 40 Bar",
        "最新价": 25.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.16,
        "最高价": 25.16,
        "最低价": 25.16,
        "昨收价": 25.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 30.0,
        "成交额": 754.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JULQ"
    },
    {
        "序号": 6721,
        "name": "Innovator Premium Income 10 Bar",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.28,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.JULD"
    },
    {
        "序号": 6722,
        "name": "Jet.AI Inc Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 17347.0,
        "成交额": 478.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.JTAIW"
    },
    {
        "序号": 6723,
        "name": "Defiance Junior Cloud Computing",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.JSKY"
    },
    {
        "序号": 6724,
        "name": "Nuveen Real Estate Income Fund",
        "最新价": 7.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.33,
        "最高价": 7.37,
        "最低价": 7.29,
        "昨收价": 7.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 42265.0,
        "成交额": 309463.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.JRS"
    },
    {
        "序号": 6725,
        "name": "锦嵘健康",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JR"
    },
    {
        "序号": 6726,
        "name": "Xtrackers Indxx New Energy & En",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.JOLT"
    },
    {
        "序号": 6727,
        "name": "跳跃网络",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JMPW"
    },
    {
        "序号": 6728,
        "name": "聚美优品",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.JMEI"
    },
    {
        "序号": 6729,
        "name": "J-Long Group Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JL"
    },
    {
        "序号": 6730,
        "name": "Nuveen High Income 2023 Target ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.7,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.JHAA"
    },
    {
        "序号": 6731,
        "name": "Jaguar Global Growth Corp I Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 2.04,
        "总市值": null,
        "市盈率": 10.57,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JGGCU"
    },
    {
        "序号": 6732,
        "name": "Jaguar Global Growth Corp I Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JGGCR"
    },
    {
        "序号": 6733,
        "name": "JIUFUYUAN BIOTECHNOLOGY CO LTD",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JFY"
    },
    {
        "序号": 6734,
        "name": "Jeffs’ Brands Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JFBRW"
    },
    {
        "序号": 6735,
        "name": "Defiance Junior Robotics ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.JBOT"
    },
    {
        "序号": 6736,
        "name": "Corporate Backed Trust Certific",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.25,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.JBK"
    },
    {
        "序号": 6737,
        "name": "Jupiter Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 23943.0,
        "成交额": 600.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.JAQCW"
    },
    {
        "序号": 6738,
        "name": "嘉纳科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.JANA"
    },
    {
        "序号": 6739,
        "name": "IX Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IXAQW"
    },
    {
        "序号": 6740,
        "name": "IX Acquisition Corp Unit Cons o",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.88,
        "总市值": 113301284.0,
        "市盈率": 20.44,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IXAQU"
    },
    {
        "序号": 6741,
        "name": "IQ Winslow Large Cap Growth ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 34.82,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IWLG"
    },
    {
        "序号": 6742,
        "name": "Iveda Solutions Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IVDAW"
    },
    {
        "序号": 6743,
        "name": "Swiftmerge Acquisition Corp Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 110.0,
        "成交额": 4.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IVCPW"
    },
    {
        "序号": 6744,
        "name": "Swiftmerge Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.58,
        "总市值": 83284808.0,
        "市盈率": 20.38,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IVCPU"
    },
    {
        "序号": 6745,
        "name": "Swiftmerge Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.64,
        "总市值": 83757122.0,
        "市盈率": 20.49,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IVCP"
    },
    {
        "序号": 6746,
        "name": "Investcorp Europe Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.02,
        "总市值": 304489950.0,
        "市盈率": -67.09,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IVCBU"
    },
    {
        "序号": 6747,
        "name": "Investcorp India Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.96,
        "总市值": 178189828.0,
        "市盈率": 17.86,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IVCAU"
    },
    {
        "序号": 6748,
        "name": "Investcorp India Acquisition Co",
        "最新价": 10.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.98,
        "最高价": 10.98,
        "最低价": 10.98,
        "昨收价": 10.98,
        "总市值": 178514992.0,
        "市盈率": 17.9,
        "成交量": 750528.0,
        "成交额": 8240796.0,
        "振幅": 0.0,
        "换手率": 4.62,
        "symbol": "105.IVCA"
    },
    {
        "序号": 6749,
        "name": "互联网科技包装",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.31,
        "最高价": 0.33,
        "最低价": 0.3,
        "昨收价": 0.33,
        "总市值": 3321754.0,
        "市盈率": -0.19,
        "成交量": 38455.0,
        "成交额": 11752.0,
        "振幅": 9.09,
        "换手率": 0.38,
        "symbol": "107.ITP"
    },
    {
        "序号": 6750,
        "name": "iShares LifePath Target Date 20",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.43,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ITDA"
    },
    {
        "序号": 6751,
        "name": "Banco Itaú Chile ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 3.04,
        "总市值": 1972996222.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ITCL"
    },
    {
        "序号": 6752,
        "name": "Israel Acquisitions Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ISRLW"
    },
    {
        "序号": 6753,
        "name": "Israel Acquisitions Corp-A",
        "最新价": 10.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.65,
        "最高价": 10.65,
        "最低价": 10.65,
        "昨收价": 10.65,
        "总市值": 212245629.0,
        "市盈率": 49.08,
        "成交量": 41.0,
        "成交额": 436.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ISRL"
    },
    {
        "序号": 6754,
        "name": "Inspirato Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 45767.0,
        "成交额": 409.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ISPOW"
    },
    {
        "序号": 6755,
        "name": "Invesco Short Duration Bond ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.78,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ISDB"
    },
    {
        "序号": 6756,
        "name": "IRSA Inversiones y Representaci",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.52,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.IRS_WS"
    },
    {
        "序号": 6757,
        "name": "Integrated Rail and Resources A",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 75.0,
        "成交额": 7.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.IRRX_WS"
    },
    {
        "序号": 6758,
        "name": "Integrated Rail and Resources A",
        "最新价": 10.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.86,
        "最高价": 10.86,
        "最低价": 10.86,
        "昨收价": 10.86,
        "总市值": 132918212.0,
        "市盈率": -602.14,
        "成交量": 100.0,
        "成交额": 1086.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.IRRX_U"
    },
    {
        "序号": 6759,
        "name": "Iris Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IRAAW"
    },
    {
        "序号": 6760,
        "name": "Iris Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.34,
        "总市值": 85957495.0,
        "市盈率": 37.29,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IRAAU"
    },
    {
        "序号": 6761,
        "name": "Iris Acquisition Corp-A",
        "最新价": 10.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.33,
        "最高价": 10.33,
        "最低价": 10.33,
        "昨收价": 10.33,
        "总市值": 85874364.0,
        "市盈率": 37.25,
        "成交量": 3.0,
        "成交额": 30.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.IRAA"
    },
    {
        "序号": 6762,
        "name": "IQ 500 ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IQUS"
    },
    {
        "序号": 6763,
        "name": "IQ Candriam ESG U.S. Mid Cap Eq",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 28.24,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IQSM"
    },
    {
        "序号": 6764,
        "name": "Inflection Point Acquisition Co",
        "最新价": 10.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.38,
        "最高价": 10.38,
        "最低价": 10.35,
        "昨收价": 10.35,
        "总市值": 323437500.0,
        "市盈率": null,
        "成交量": 312.0,
        "成交额": 3235.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "105.IPXXU"
    },
    {
        "序号": 6765,
        "name": "Inflection Point Acquisition Co",
        "最新价": 10.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.3,
        "最高价": 10.3,
        "最低价": 10.3,
        "昨收价": 10.3,
        "总市值": 321875000.0,
        "市盈率": null,
        "成交量": 39517.0,
        "成交额": 407025.0,
        "振幅": 0.0,
        "换手率": 0.13,
        "symbol": "105.IPXX"
    },
    {
        "序号": 6766,
        "name": "InterPrivate III Financial Part",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IPVF_WS"
    },
    {
        "序号": 6767,
        "name": "InterPrivate III Financial Part",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.76,
        "总市值": 84151582.0,
        "市盈率": -78.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IPVF_U"
    },
    {
        "序号": 6768,
        "name": "Renaissance International IPO E",
        "最新价": 13.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 13.66,
        "最高价": 13.71,
        "最低价": 13.66,
        "昨收价": 13.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 532.0,
        "成交额": 7287.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.IPOS"
    },
    {
        "序号": 6769,
        "name": "Immunoprecise Antibodies Ltd",
        "最新价": 1.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.15,
        "最高价": 1.17,
        "最低价": 1.08,
        "昨收价": 1.13,
        "总市值": 29549794.0,
        "市盈率": -1.98,
        "成交量": 164618.0,
        "成交额": 182007.0,
        "振幅": 7.96,
        "换手率": 0.63,
        "symbol": "105.IPA"
    },
    {
        "序号": 6770,
        "name": "Income Opportunity Realty Inves",
        "最新价": 13.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 13.0,
        "最高价": 13.0,
        "最低价": 13.0,
        "昨收价": 13.0,
        "总市值": 54186782.0,
        "市盈率": 9.35,
        "成交量": 176.0,
        "成交额": 2288.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "107.IOR"
    },
    {
        "序号": 6771,
        "name": "Innovative International Acquis",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 48.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IOACW"
    },
    {
        "序号": 6772,
        "name": "Innoviz Technologies Ltd Wt",
        "最新价": 0.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.49,
        "最高价": 0.49,
        "最低价": 0.49,
        "昨收价": 0.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 490.0,
        "成交额": 239.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.INVZW"
    },
    {
        "序号": 6773,
        "name": "Intermedia Cloud Communications",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INTM"
    },
    {
        "序号": 6774,
        "name": "慧悦财经",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INTJ"
    },
    {
        "序号": 6775,
        "name": "Integral Acquisition Corp 1 Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INTEW"
    },
    {
        "序号": 6776,
        "name": "Integral Acquisition Corp 1 Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.76,
        "总市值": 43829160.0,
        "市盈率": 80.2,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INTEU"
    },
    {
        "序号": 6777,
        "name": "Inpixon",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.07,
        "昨收价": 0.07,
        "总市值": 8938199.0,
        "市盈率": -0.18,
        "成交量": 4151443.0,
        "成交额": 282100.0,
        "振幅": 0.0,
        "换手率": 3.25,
        "symbol": "105.INPX"
    },
    {
        "序号": 6778,
        "name": "Inovio制药",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.38,
        "最高价": 0.39,
        "最低价": 0.37,
        "昨收价": 0.38,
        "总市值": 103734841.0,
        "市盈率": -0.63,
        "成交量": 1743331.0,
        "成交额": 659738.0,
        "振幅": 5.26,
        "换手率": 0.64,
        "symbol": "105.INO"
    },
    {
        "序号": 6779,
        "name": "MiNK Therapeutics Inc",
        "最新价": 1.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.01,
        "最高价": 1.05,
        "最低价": 1.01,
        "昨收价": 1.02,
        "总市值": 35209931.0,
        "市盈率": -1.42,
        "成交量": 14353.0,
        "成交额": 14722.0,
        "振幅": 3.92,
        "换手率": 0.04,
        "symbol": "105.INKT"
    },
    {
        "序号": 6780,
        "name": "INNO HOLDINGS INC",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INHD"
    },
    {
        "序号": 6781,
        "name": "indie Semiconductor Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 1.26,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INDIW"
    },
    {
        "序号": 6782,
        "name": "Insight Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INAQW"
    },
    {
        "序号": 6783,
        "name": "Insight Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.59,
        "总市值": 74140008.0,
        "市盈率": 20.67,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INAQU"
    },
    {
        "序号": 6784,
        "name": "Insight Acquisition Corp-A",
        "最新价": 10.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.62,
        "最高价": 10.62,
        "最低价": 10.62,
        "昨收价": 10.62,
        "总市值": 74350036.0,
        "市盈率": 20.73,
        "成交量": 102.0,
        "成交额": 1083.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.INAQ"
    },
    {
        "序号": 6785,
        "name": "Invea Therapeutics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.INAI"
    },
    {
        "序号": 6786,
        "name": "IN8bio Inc",
        "最新价": 0.99,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.0,
        "最高价": 1.01,
        "最低价": 0.92,
        "昨收价": 0.99,
        "总市值": 31671219.0,
        "市盈率": -1.05,
        "成交量": 85763.0,
        "成交额": 82401.0,
        "振幅": 9.09,
        "换手率": 0.27,
        "symbol": "105.INAB"
    },
    {
        "序号": 6787,
        "name": "Invesco Municipal Strategic Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 50.19,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IMSI"
    },
    {
        "序号": 6788,
        "name": "Infinite Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IMCIW"
    },
    {
        "序号": 6789,
        "name": "Infinite Group Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IMCI"
    },
    {
        "序号": 6790,
        "name": "International Media Acquisition",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 204903.0,
        "成交额": 4625.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.IMAQW"
    },
    {
        "序号": 6791,
        "name": "International Media Acquisition",
        "最新价": 10.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.18,
        "最高价": 10.18,
        "最低价": 10.18,
        "昨收价": 10.18,
        "总市值": 86733783.0,
        "市盈率": -145.33,
        "成交量": 4.0,
        "成交额": 40.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IMAQU"
    },
    {
        "序号": 6792,
        "name": "International Media Acquisition",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 11896.0,
        "成交额": 536.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IMAQR"
    },
    {
        "序号": 6793,
        "name": "IMAC Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IMACW"
    },
    {
        "序号": 6794,
        "name": "Insurance Income Strategies Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ILSWB"
    },
    {
        "序号": 6795,
        "name": "Insurance Income Strategies Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ILSWA"
    },
    {
        "序号": 6796,
        "name": "Insurance Income Strategies Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ILSU"
    },
    {
        "序号": 6797,
        "name": "Insurance Income Strategies Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ILS"
    },
    {
        "序号": 6798,
        "name": "Inspira Technologies Oxy B.H.N.",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.13,
        "最高价": 0.13,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.IINNW"
    },
    {
        "序号": 6799,
        "name": "Invesco High Income 2024 Target",
        "最新价": 6.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.82,
        "最高价": 6.82,
        "最低价": 6.79,
        "昨收价": 6.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 46071.0,
        "成交额": 312998.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "106.IHTA"
    },
    {
        "序号": 6800,
        "name": "Invesco High Income 2023 Target",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 6.92,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.IHIT"
    },
    {
        "序号": 6801,
        "name": "Inception Growth Acquisition Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IGTAW"
    },
    {
        "序号": 6802,
        "name": "Inception Growth Acquisition Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 60354623.0,
        "市盈率": 46.99,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IGTAU"
    },
    {
        "序号": 6803,
        "name": "Inception Growth Acquisition Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IGTAR"
    },
    {
        "序号": 6804,
        "name": "IGC Pharma Inc",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.31,
        "最高价": 0.31,
        "最低价": 0.3,
        "昨收价": 0.3,
        "总市值": 19120332.0,
        "市盈率": -1.77,
        "成交量": 80051.0,
        "成交额": 24141.0,
        "振幅": 3.33,
        "换手率": 0.13,
        "symbol": "107.IGC"
    },
    {
        "序号": 6805,
        "name": "InFinT Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.IFIN_WS"
    },
    {
        "序号": 6806,
        "name": "InFinT Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.09,
        "总市值": 146848324.0,
        "市盈率": 50.02,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.IFIN_U"
    },
    {
        "序号": 6807,
        "name": "ETRACS IFED Invest with the Fed",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 31.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IFED"
    },
    {
        "序号": 6808,
        "name": "Aptus International Enhanced Yi",
        "最新价": 19.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 19.69,
        "最高价": 19.72,
        "最低价": 19.63,
        "昨收价": 19.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 21451.0,
        "成交额": 422692.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.IDUB"
    },
    {
        "序号": 6809,
        "name": "Intellicheck Inc",
        "最新价": 1.77,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.78,
        "最高价": 1.81,
        "最低价": 1.71,
        "昨收价": 1.77,
        "总市值": 34257173.0,
        "市盈率": -10.39,
        "成交量": 23915.0,
        "成交额": 42118.0,
        "振幅": 5.65,
        "换手率": 0.12,
        "symbol": "105.IDN"
    },
    {
        "序号": 6810,
        "name": "Innovator International Develop",
        "最新价": 25.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.16,
        "最高价": 25.21,
        "最低价": 25.08,
        "昨收价": 25.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 6696.0,
        "成交额": 168376.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.IDEC"
    },
    {
        "序号": 6811,
        "name": "SeaStar Medical Holding Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 143209.0,
        "成交额": 4446.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ICUCW"
    },
    {
        "序号": 6812,
        "name": "Intercept Pharmaceuticals Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.0,
        "总市值": 794693354.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ICPT"
    },
    {
        "序号": 6813,
        "name": "iCoreConnect Inc",
        "最新价": 0.96,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.97,
        "最高价": 1.0,
        "最低价": 0.93,
        "昨收价": 0.96,
        "总市值": 8593413.0,
        "市盈率": -2.27,
        "成交量": 111525.0,
        "成交额": 106614.0,
        "振幅": 7.29,
        "换手率": 1.25,
        "symbol": "105.ICCT"
    },
    {
        "序号": 6814,
        "name": "iShares iBonds Dec 2023 Term Tr",
        "最新价": 24.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.75,
        "最高价": 24.75,
        "最低价": 24.74,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1114383.0,
        "成交额": 27571405.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.IBTD"
    },
    {
        "序号": 6815,
        "name": "iShares iBonds Dec 2029 Term Mu",
        "最新价": 25.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.17,
        "最高价": 25.25,
        "最低价": 25.13,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 86261.0,
        "成交额": 2168405.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.IBMR"
    },
    {
        "序号": 6816,
        "name": "iShares iBonds Dec 2028 Term Mu",
        "最新价": 25.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.34,
        "最高价": 25.37,
        "最低价": 25.31,
        "昨收价": 25.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 55440.0,
        "成交额": 1404198.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.IBMQ"
    },
    {
        "序号": 6817,
        "name": "iShares iBonds Dec 2025 Term Mu",
        "最新价": 26.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 26.48,
        "最高价": 26.49,
        "最低价": 26.46,
        "昨收价": 26.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 124509.0,
        "成交额": 3294935.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.IBMN"
    },
    {
        "序号": 6818,
        "name": "iShares iBonds Dec 2023 Term Mu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.53,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.IBML"
    },
    {
        "序号": 6819,
        "name": "Blue Room Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBLUW"
    },
    {
        "序号": 6820,
        "name": "Blue Room Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBLUU"
    },
    {
        "序号": 6821,
        "name": "Blue Room Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBLUR"
    },
    {
        "序号": 6822,
        "name": "Blue Room Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBLU"
    },
    {
        "序号": 6823,
        "name": "iShares iBonds 2023 Term High Y",
        "最新价": 23.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.57,
        "最高价": 23.6,
        "最低价": 23.53,
        "昨收价": 23.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 25939.0,
        "成交额": 610936.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.IBHC"
    },
    {
        "序号": 6824,
        "name": "Innovation Beverage Group Ltd W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBGWW"
    },
    {
        "序号": 6825,
        "name": "Innovation Beverage Group Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.IBG"
    },
    {
        "序号": 6826,
        "name": "iShares iBonds Dec 2023 Term Co",
        "最新价": 25.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.3,
        "最高价": 25.33,
        "最低价": 25.29,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 1307740.0,
        "成交额": 33091091.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IBDO"
    },
    {
        "序号": 6827,
        "name": "Hyperfine Inc-A",
        "最新价": 1.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.24,
        "最高价": 1.25,
        "最低价": 1.2,
        "昨收价": 1.2,
        "总市值": 85919522.0,
        "市盈率": -1.84,
        "成交量": 60899.0,
        "成交额": 74279.0,
        "振幅": 4.17,
        "换手率": 0.09,
        "symbol": "105.HYPR"
    },
    {
        "序号": 6828,
        "name": "Hycroft Mining Holding Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 5004.0,
        "成交额": 90.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HYMCW"
    },
    {
        "序号": 6829,
        "name": "Hycroft Mining Holding Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 51674.0,
        "成交额": 1440.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.HYMCL"
    },
    {
        "序号": 6830,
        "name": "HYLETE Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HYLT"
    },
    {
        "序号": 6831,
        "name": "iShares High Yield Low Beta ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HYLO"
    },
    {
        "序号": 6832,
        "name": "Houyi Digital Internet Industry",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HYHL"
    },
    {
        "序号": 6833,
        "name": "iShares High Yield High Beta ET",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HYHI"
    },
    {
        "序号": 6834,
        "name": "SPDR Blackstone High Income ETF",
        "最新价": 27.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 27.86,
        "最高价": 27.86,
        "最低价": 27.76,
        "昨收价": 27.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 33514.0,
        "成交额": 932342.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.HYBL"
    },
    {
        "序号": 6835,
        "name": "Haymaker Acquisition Corp 4 Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.34,
        "总市值": 305522184.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HYAC_U"
    },
    {
        "序号": 6836,
        "name": "鸿绪熵增",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HXSZ"
    },
    {
        "序号": 6837,
        "name": "昊鑫控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HXHX"
    },
    {
        "序号": 6838,
        "name": "HW Electro Co Ltd ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HWEP"
    },
    {
        "序号": 6839,
        "name": "Healthwell Acquisition Corp I W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HWELW"
    },
    {
        "序号": 6840,
        "name": "Healthwell Acquisition Corp I U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.46,
        "总市值": 107815205.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HWELU"
    },
    {
        "序号": 6841,
        "name": "Healthwell Acquisition Corp I-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.47,
        "总市值": 107918279.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HWEL"
    },
    {
        "序号": 6842,
        "name": "哈弗蒂家具-A",
        "最新价": 31.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 31.67,
        "最高价": 31.67,
        "最低价": 31.67,
        "昨收价": 31.67,
        "总市值": 515224123.0,
        "市盈率": 7.92,
        "成交量": 21.0,
        "成交额": 665.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.HVT_A"
    },
    {
        "序号": 6843,
        "name": "Humacyte Inc Wt",
        "最新价": 0.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.48,
        "最高价": 0.48,
        "最低价": 0.47,
        "昨收价": 0.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 3700.0,
        "成交额": 1766.0,
        "振幅": 2.08,
        "换手率": null,
        "symbol": "105.HUMAW"
    },
    {
        "序号": 6844,
        "name": "虎虎科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HUHU"
    },
    {
        "序号": 6845,
        "name": "Hudson Acquisition I Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.88,
        "总市值": 48961697.0,
        "市盈率": 3170.89,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HUDAU"
    },
    {
        "序号": 6846,
        "name": "Hudson Acquisition I Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HUDAR"
    },
    {
        "序号": 6847,
        "name": "Hudson Acquisition I Corp",
        "最新价": 10.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.6,
        "最高价": 10.6,
        "最低价": 10.6,
        "昨收价": 10.6,
        "总市值": 47701654.0,
        "市盈率": 3089.29,
        "成交量": 175.0,
        "成交额": 1855.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.HUDA"
    },
    {
        "序号": 6848,
        "name": "HUB Cyber Security Ltd Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 24829.0,
        "成交额": 579.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HUBCW"
    },
    {
        "序号": 6849,
        "name": "HUB Cyber Security Ltd",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.23,
        "昨收价": 0.24,
        "总市值": 23587934.0,
        "市盈率": -15.82,
        "成交量": 1967700.0,
        "成交额": 454813.0,
        "振幅": 4.17,
        "换手率": 2.0,
        "symbol": "105.HUBC"
    },
    {
        "序号": 6850,
        "name": "HT基金(优先股)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.1,
        "总市值": 100438001.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HT_E"
    },
    {
        "序号": 6851,
        "name": "HT基金(优先股)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.09,
        "总市值": 193235653.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HT_D"
    },
    {
        "序号": 6852,
        "name": "HT基金(优先股)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.09,
        "总市值": 75270000.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HT_C"
    },
    {
        "序号": 6853,
        "name": "Horizon Technology Finance Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.78,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HTFB"
    },
    {
        "序号": 6854,
        "name": "HT基金",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.99,
        "总市值": 402558698.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HT"
    },
    {
        "序号": 6855,
        "name": "Horizon Space Acquisition I Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSPOW"
    },
    {
        "序号": 6856,
        "name": "Horizon Space Acquisition I Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.7,
        "总市值": 92533290.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSPOU"
    },
    {
        "序号": 6857,
        "name": "Horizon Space Acquisition I Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSPOR"
    },
    {
        "序号": 6858,
        "name": "Horizon Space Acquisition I Cor",
        "最新价": 10.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.66,
        "最高价": 10.66,
        "最低价": 10.66,
        "昨收价": 10.66,
        "总市值": 92187371.0,
        "市盈率": null,
        "成交量": 89.0,
        "成交额": 948.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.HSPO"
    },
    {
        "序号": 6859,
        "name": "Heart Test Laboratories Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSCSW"
    },
    {
        "序号": 6860,
        "name": "Hainan Sirius Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSACW"
    },
    {
        "序号": 6861,
        "name": "Hainan Sirius Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSACU"
    },
    {
        "序号": 6862,
        "name": "Hainan Sirius Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSACR"
    },
    {
        "序号": 6863,
        "name": "Hainan Sirius Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HSAC"
    },
    {
        "序号": 6864,
        "name": "华瑞国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HRDG"
    },
    {
        "序号": 6865,
        "name": "Tekla Life Sciences Investors",
        "最新价": 12.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.42,
        "最高价": 12.45,
        "最低价": 12.38,
        "昨收价": 12.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 101195.0,
        "成交额": 1257647.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "106.HQL"
    },
    {
        "序号": 6866,
        "name": "Hartford US Quality Growth ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 40.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HQGO"
    },
    {
        "序号": 6867,
        "name": "HighPeak Energy Inc Wt",
        "最新价": 9.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.0,
        "最高价": 9.0,
        "最低价": 9.0,
        "昨收价": 9.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 313.0,
        "成交额": 2817.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HPKEW"
    },
    {
        "序号": 6868,
        "name": "Hour Loop Inc",
        "最新价": 1.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.43,
        "最高价": 1.43,
        "最低价": 1.42,
        "昨收价": 1.42,
        "总市值": 49817099.0,
        "市盈率": -18.52,
        "成交量": 543.0,
        "成交额": 774.0,
        "振幅": 0.7,
        "换手率": 0.0,
        "symbol": "105.HOUR"
    },
    {
        "序号": 6869,
        "name": "Pacer Benchmark Hotel & Lodging",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 14.62,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HOTL"
    },
    {
        "序号": 6870,
        "name": "HOOKIPA Pharma Inc",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.59,
        "最高价": 0.59,
        "最低价": 0.58,
        "昨收价": 0.58,
        "总市值": 48691062.0,
        "市盈率": -0.7,
        "成交量": 190296.0,
        "成交额": 111571.0,
        "振幅": 1.72,
        "换手率": 0.23,
        "symbol": "105.HOOK"
    },
    {
        "序号": 6871,
        "name": "HarborOne Bancorp Inc",
        "最新价": 11.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.32,
        "最高价": 11.37,
        "最低价": 11.11,
        "昨收价": 11.27,
        "总市值": 515793459.0,
        "市盈率": 15.74,
        "成交量": 123315.0,
        "成交额": 1388916.0,
        "振幅": 2.31,
        "换手率": 0.27,
        "symbol": "105.HONE"
    },
    {
        "序号": 6872,
        "name": "Hall of Fame Resort & Entertain",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 4987.0,
        "成交额": 50.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HOFVW"
    },
    {
        "序号": 6873,
        "name": "HomeUnion Holdings Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HMU"
    },
    {
        "序号": 6874,
        "name": "如家",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HMIN"
    },
    {
        "序号": 6875,
        "name": "Hecla Mining Co Pfd-B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 54.4,
        "总市值": 8583014.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HL_B"
    },
    {
        "序号": 6876,
        "name": "Holley Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.43,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HLLY_WS"
    },
    {
        "序号": 6877,
        "name": "Hartford Longevity Economy ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.8,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HLGE"
    },
    {
        "序号": 6878,
        "name": "Invesco High Yield Select ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HIYS"
    },
    {
        "序号": 6879,
        "name": "Hippo Holdings Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HIPO_WS"
    },
    {
        "序号": 6880,
        "name": "Simplify Enhanced Income ETF",
        "最新价": 24.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.65,
        "最高价": 24.65,
        "最低价": 24.6,
        "昨收价": 24.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 184143.0,
        "成交额": 4532621.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.HIGH"
    },
    {
        "序号": 6881,
        "name": "HH&L Acquisition Co Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HHLA_WS"
    },
    {
        "序号": 6882,
        "name": "HH&L Acquisition Co Unit Cons o",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.68,
        "总市值": 155449376.0,
        "市盈率": -276.17,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HHLA_U"
    },
    {
        "序号": 6883,
        "name": "HH&L Acquisition Co-A",
        "最新价": 10.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.68,
        "最高价": 10.68,
        "最低价": 10.68,
        "昨收价": 10.68,
        "总市值": 155449376.0,
        "市盈率": -276.17,
        "成交量": 10.0,
        "成交额": 106.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.HHLA"
    },
    {
        "序号": 6884,
        "name": "Concord Health Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HHGJ"
    },
    {
        "序号": 6885,
        "name": "HHG Capital Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HHGCW"
    },
    {
        "序号": 6886,
        "name": "HHG Capital Corp Unit Cons of 1",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.3,
        "总市值": 57071339.0,
        "市盈率": 57.16,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HHGCU"
    },
    {
        "序号": 6887,
        "name": "HHG Capital Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HHGCR"
    },
    {
        "序号": 6888,
        "name": "恒光控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HGIA"
    },
    {
        "序号": 6889,
        "name": "HF Foods Group Inc",
        "最新价": 4.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.86,
        "最高价": 4.94,
        "最低价": 4.86,
        "昨收价": 4.92,
        "总市值": 266434684.0,
        "市盈率": -32.28,
        "成交量": 47472.0,
        "成交额": 233584.0,
        "振幅": 1.63,
        "换手率": 0.09,
        "symbol": "105.HFFG"
    },
    {
        "序号": 6890,
        "name": "Home Federal Bancorp Inc of Lou",
        "最新价": 12.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.95,
        "最高价": 12.95,
        "最低价": 12.95,
        "昨收价": 12.95,
        "总市值": 40576895.0,
        "市盈率": 7.72,
        "成交量": 180.0,
        "成交额": 2331.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.HFBL"
    },
    {
        "序号": 6891,
        "name": "霍利能源",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.45,
        "总市值": 2585702110.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.HEP"
    },
    {
        "序号": 6892,
        "name": "ETRACS Monthly Pay 2xLeveraged ",
        "最新价": 11.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.2,
        "最高价": 11.23,
        "最低价": 11.2,
        "昨收价": 11.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 684.0,
        "成交额": 7668.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.HDLB"
    },
    {
        "序号": 6893,
        "name": "Ranger Equity Bear Bear ETF",
        "最新价": 22.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.95,
        "最高价": 23.01,
        "最低价": 22.78,
        "昨收价": 22.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 251679.0,
        "成交额": 5764060.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.HDGE"
    },
    {
        "序号": 6894,
        "name": "HCW Biologics Inc",
        "最新价": 1.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.17,
        "最高价": 1.2,
        "最低价": 1.1,
        "昨收价": 1.1,
        "总市值": 39572027.0,
        "市盈率": -2.01,
        "成交量": 7592.0,
        "成交额": 8445.0,
        "振幅": 9.09,
        "换手率": 0.02,
        "symbol": "105.HCWB"
    },
    {
        "序号": 6895,
        "name": "Hennessy Capital Investment Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCVIW"
    },
    {
        "序号": 6896,
        "name": "Hennessy Capital Investment Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.45,
        "总市值": 388343767.0,
        "市盈率": 64.28,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCVIU"
    },
    {
        "序号": 6897,
        "name": "Hennessy Capital Investment Cor",
        "最新价": 10.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.44,
        "最高价": 10.46,
        "最低价": 10.39,
        "昨收价": 10.46,
        "总市值": 388715388.0,
        "市盈率": 64.35,
        "成交量": 177423.0,
        "成交额": 1854061.0,
        "振幅": 0.67,
        "换手率": 0.48,
        "symbol": "105.HCVI"
    },
    {
        "序号": 6898,
        "name": "HCM Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCMAW"
    },
    {
        "序号": 6899,
        "name": "HCM Acquisition Corp Unit Cons ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.08,
        "总市值": 156692318.0,
        "市盈率": 39.13,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCMAU"
    },
    {
        "序号": 6900,
        "name": "Harbor Custom Development Inc W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCDIZ"
    },
    {
        "序号": 6901,
        "name": "Harbor Custom Development Inc W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HCDIW"
    },
    {
        "序号": 6902,
        "name": "胡贝儿",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HBER"
    },
    {
        "序号": 6903,
        "name": "Harbor Corporate Culture Small ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HAPS"
    },
    {
        "序号": 6904,
        "name": "Harbor Corporate Culture ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 27.2,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.HAPI"
    },
    {
        "序号": 6905,
        "name": "浩希传媒",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HAO"
    },
    {
        "序号": 6906,
        "name": "牵手一生",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HAND"
    },
    {
        "序号": 6907,
        "name": "标志金融服务",
        "最新价": 1.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.51,
        "最高价": 1.51,
        "最低价": 1.45,
        "昨收价": 1.5,
        "总市值": 2727723.0,
        "市盈率": -0.03,
        "成交量": 11526.0,
        "成交额": 17080.0,
        "振幅": 4.0,
        "换手率": 0.63,
        "symbol": "105.HALL"
    },
    {
        "序号": 6908,
        "name": "Healthcare AI Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.89,
        "总市值": 58703634.0,
        "市盈率": 8.22,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.HAIAU"
    },
    {
        "序号": 6909,
        "name": "旋翼物业",
        "最新价": 8.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.95,
        "最高价": 8.95,
        "最低价": 8.95,
        "昨收价": 8.95,
        "总市值": 14090057.0,
        "市盈率": -4.4,
        "成交量": 16.0,
        "成交额": 143.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GYRO"
    },
    {
        "序号": 6910,
        "name": "Goldman Sachs MarketBeta Total ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 41.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GXUS"
    },
    {
        "序号": 6911,
        "name": "Goldman Sachs MarketBeta Russel",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 40.5,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GVUS"
    },
    {
        "序号": 6912,
        "name": "Visionary Education Technology ",
        "最新价": 0.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.12,
        "最高价": 0.13,
        "最低价": 0.12,
        "昨收价": 0.12,
        "总市值": 6199306.0,
        "市盈率": -1.78,
        "成交量": 111820.0,
        "成交额": 13658.0,
        "振幅": 8.33,
        "换手率": 0.22,
        "symbol": "105.GV"
    },
    {
        "序号": 6913,
        "name": "Global Technology Acquisition C",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.06,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 150702.0,
        "成交额": 5688.0,
        "振幅": 200.0,
        "换手率": null,
        "symbol": "105.GTACW"
    },
    {
        "序号": 6914,
        "name": "悦享控股(权证)",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 43.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GSMGW"
    },
    {
        "序号": 6915,
        "name": "Ferroglobe PLC",
        "最新价": 6.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.11,
        "最高价": 6.22,
        "最低价": 6.06,
        "昨收价": 6.11,
        "总市值": 1145218948.0,
        "市盈率": 11.45,
        "成交量": 835327.0,
        "成交额": 5135484.0,
        "振幅": 2.62,
        "换手率": 0.45,
        "symbol": "105.GSM"
    },
    {
        "序号": 6916,
        "name": "Global Systems Dynamics Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GSDWW"
    },
    {
        "序号": 6917,
        "name": "Global Systems Dynamics Inc Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.92,
        "总市值": 36145593.0,
        "市盈率": -43.66,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GSDWU"
    },
    {
        "序号": 6918,
        "name": "Global Systems Dynamics Inc-A",
        "最新价": 11.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.01,
        "最高价": 11.01,
        "最低价": 11.01,
        "昨收价": 11.01,
        "总市值": 36443496.0,
        "市盈率": -44.02,
        "成交量": 100.0,
        "成交额": 1101.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GSD"
    },
    {
        "序号": 6919,
        "name": "全球星",
        "最新价": 1.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.46,
        "最高价": 1.48,
        "最低价": 1.45,
        "昨收价": 1.47,
        "总市值": 2757896403.0,
        "市盈率": -184.17,
        "成交量": 3654834.0,
        "成交额": 5350916.0,
        "振幅": 2.04,
        "换手率": 0.19,
        "symbol": "107.GSAT"
    },
    {
        "序号": 6920,
        "name": "The Gabelli Healthcare & Wellne",
        "最新价": 8.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.95,
        "最高价": 8.99,
        "最低价": 8.9,
        "昨收价": 8.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 35893.0,
        "成交额": 320748.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "106.GRX"
    },
    {
        "序号": 6921,
        "name": "Global Robotic Drone Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GRTRW"
    },
    {
        "序号": 6922,
        "name": "Global Robotic Drone Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GRTRU"
    },
    {
        "序号": 6923,
        "name": "Global Robotic Drone Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GRTR"
    },
    {
        "序号": 6924,
        "name": "大猩猩科技(权证)",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 10235.0,
        "成交额": 326.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GRRRW"
    },
    {
        "序号": 6925,
        "name": "Gold Royalty Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 5140.0,
        "成交额": 103.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GROY_WS"
    },
    {
        "序号": 6926,
        "name": "Grindr Inc",
        "最新价": 7.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.83,
        "最高价": 7.94,
        "最低价": 7.62,
        "昨收价": 7.8,
        "总市值": 1363185619.0,
        "市盈率": -239.37,
        "成交量": 370908.0,
        "成交额": 2887391.0,
        "振幅": 4.1,
        "换手率": 0.21,
        "symbol": "106.GRND"
    },
    {
        "序号": 6927,
        "name": "Eagle Capital Growth Fund",
        "最新价": 8.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.5,
        "最高价": 8.5,
        "最低价": 8.5,
        "昨收价": 8.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 1252.0,
        "成交额": 10641.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GRF"
    },
    {
        "序号": 6928,
        "name": "Grab Holdings Ltd Wt",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 629.0,
        "成交额": 94.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GRABW"
    },
    {
        "序号": 6929,
        "name": "Goldman Sachs ActiveBeta Paris-",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 37.84,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GPAL"
    },
    {
        "序号": 6930,
        "name": "Global Partner Acquisition Corp",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.07,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 21.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GPACW"
    },
    {
        "序号": 6931,
        "name": "Global Partner Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.02,
        "总市值": 125977543.0,
        "市盈率": 20.02,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GPACU"
    },
    {
        "序号": 6932,
        "name": "Goxus Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GOXS"
    },
    {
        "序号": 6933,
        "name": "Canoo Inc Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 35113.0,
        "成交额": 2244.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "105.GOEVW"
    },
    {
        "序号": 6934,
        "name": "Canoo Inc",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.24,
        "昨收价": 0.25,
        "总市值": 196520014.0,
        "市盈率": -0.56,
        "成交量": 24061183.0,
        "成交额": 5944721.0,
        "振幅": 4.0,
        "换手率": 3.06,
        "symbol": "105.GOEV"
    },
    {
        "序号": 6935,
        "name": "Golden Star Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.34,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GODNR"
    },
    {
        "序号": 6936,
        "name": "Golden Star Acquisition Corp",
        "最新价": 10.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.39,
        "最高价": 10.39,
        "最低价": 10.39,
        "昨收价": 10.39,
        "总市值": 92803480.0,
        "市盈率": 119.5,
        "成交量": 2067.0,
        "成交额": 21476.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.GODN"
    },
    {
        "序号": 6937,
        "name": "Genprex Inc",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.23,
        "总市值": 13670009.0,
        "市盈率": -0.43,
        "成交量": 309720.0,
        "成交额": 72636.0,
        "振幅": 8.7,
        "换手率": 0.52,
        "symbol": "105.GNPX"
    },
    {
        "序号": 6938,
        "name": "Aetherium Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.89,
        "总市值": 69636138.0,
        "市盈率": -111.62,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GMFIU"
    },
    {
        "序号": 6939,
        "name": "Aetherium Acquisition Corp-A",
        "最新价": 10.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.87,
        "最高价": 10.87,
        "最低价": 10.87,
        "昨收价": 10.87,
        "总市值": 69508248.0,
        "市盈率": -111.41,
        "成交量": 2.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GMFI"
    },
    {
        "序号": 6940,
        "name": "Esports Entertainment Group Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 1644.0,
        "成交额": 8.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.GMBLZ"
    },
    {
        "序号": 6941,
        "name": "Esports Entertainment Group Inc",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1545.0,
        "成交额": 46.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GMBLW"
    },
    {
        "序号": 6942,
        "name": "Esports Entertainment Group Inc",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": 2487366.0,
        "市盈率": -0.08,
        "成交量": 198564960.0,
        "成交额": 3717195.0,
        "振幅": 0.0,
        "换手率": 159.66,
        "symbol": "105.GMBL"
    },
    {
        "序号": 6943,
        "name": "Xtrackers Indxx Space & Explora",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GLXY"
    },
    {
        "序号": 6944,
        "name": "迦里仕人才",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLXG"
    },
    {
        "序号": 6945,
        "name": "The Gabelli Global Utility & In",
        "最新价": 50.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.51,
        "最高价": 50.51,
        "最低价": 50.51,
        "昨收价": 50.51,
        "总市值": 60865207.0,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 353.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "107.GLU_B"
    },
    {
        "序号": 6946,
        "name": "Global Star Acquisition Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 3300.0,
        "成交额": 34.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GLSTW"
    },
    {
        "序号": 6947,
        "name": "Global Star Acquisition Inc Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.91,
        "总市值": 87947245.0,
        "市盈率": 60.63,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLSTU"
    },
    {
        "序号": 6948,
        "name": "Global Star Acquisition Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.7,
        "总市值": 86254401.0,
        "市盈率": 59.46,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLST"
    },
    {
        "序号": 6949,
        "name": "Globalink Investment Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLLIW"
    },
    {
        "序号": 6950,
        "name": "Globalink Investment Inc Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 88761226.0,
        "市盈率": 50.91,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLLIU"
    },
    {
        "序号": 6951,
        "name": "Globalink Investment Inc Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLLIR"
    },
    {
        "序号": 6952,
        "name": "Globalink Investment Inc",
        "最新价": 10.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.85,
        "昨收价": 10.85,
        "总市值": 88843109.0,
        "市盈率": 50.95,
        "成交量": 26.0,
        "成交额": 282.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GLLI"
    },
    {
        "序号": 6953,
        "name": "Goldman Sachs Access Investment",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GLIG"
    },
    {
        "序号": 6954,
        "name": "GCI Liberty Inc-B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLIBB"
    },
    {
        "序号": 6955,
        "name": "Global Engine Group Holding Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GLE"
    },
    {
        "序号": 6956,
        "name": "Global Lights Acquisition Corp ",
        "最新价": 10.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.26,
        "最高价": 10.26,
        "最低价": 10.26,
        "昨收价": 10.26,
        "总市值": 80310150.0,
        "市盈率": -1193.76,
        "成交量": 620.0,
        "成交额": 6361.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GLACU"
    },
    {
        "序号": 6957,
        "name": "Global Lights Acquisition Corp",
        "最新价": 10.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.06,
        "最高价": 10.06,
        "最低价": 10.05,
        "昨收价": 10.05,
        "总市值": 78666375.0,
        "市盈率": -1169.33,
        "成交量": 12059.0,
        "成交额": 121308.0,
        "振幅": 0.1,
        "换手率": 0.15,
        "symbol": "105.GLAC"
    },
    {
        "序号": 6958,
        "name": "STRATS Trust for Goldman Sachs ",
        "最新价": 22.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.13,
        "最高价": 22.13,
        "最低价": 22.1,
        "昨收价": 22.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 3805.0,
        "成交额": 84095.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "106.GJS"
    },
    {
        "序号": 6959,
        "name": "STRATS Trust for Procter & Gamb",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.4,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GJR"
    },
    {
        "序号": 6960,
        "name": "STRATS Trust For Wal-Mart Store",
        "最新价": 24.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.63,
        "最高价": 24.85,
        "最低价": 24.63,
        "昨收价": 24.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 2390.0,
        "成交额": 59193.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "106.GJO"
    },
    {
        "序号": 6961,
        "name": "STRATS Trust For United States ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 8.56,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GJH"
    },
    {
        "序号": 6962,
        "name": "GigCapital5 Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 94393760.0,
        "市盈率": -19.56,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GIA"
    },
    {
        "序号": 6963,
        "name": "Goose Hollow Multi-Strategy Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.48,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GHMS"
    },
    {
        "序号": 6964,
        "name": "格林希尔事务所",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 14.99,
        "总市值": 282041272.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GHL"
    },
    {
        "序号": 6965,
        "name": "Gores Holdings IX Inc Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.52,
        "总市值": 690375000.0,
        "市盈率": 30.22,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GHIXU"
    },
    {
        "序号": 6966,
        "name": "The Gabelli Global Small and Mi",
        "最新价": 10.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.84,
        "最高价": 11.07,
        "最低价": 10.84,
        "昨收价": 10.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 2968.0,
        "成交额": 32552.0,
        "振幅": 2.12,
        "换手率": null,
        "symbol": "106.GGZ"
    },
    {
        "序号": 6967,
        "name": "Goldman Sachs MarketBeta Russel",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 40.38,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GGUS"
    },
    {
        "序号": 6968,
        "name": "Goldman Sachs Access U.S. Treas",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 39.23,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GGOV"
    },
    {
        "序号": 6969,
        "name": "Gefen Landa Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GFLDW"
    },
    {
        "序号": 6970,
        "name": "Gefen Landa Acquisition Corp Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GFLDU"
    },
    {
        "序号": 6971,
        "name": "Gefen Landa Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GFLD"
    },
    {
        "序号": 6972,
        "name": "承信科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GFCX"
    },
    {
        "序号": 6973,
        "name": "卫安智能(权证)",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.29,
        "昨收价": 0.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 201.0,
        "成交额": 58.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GFAIW"
    },
    {
        "序号": 6974,
        "name": "卫安智能",
        "最新价": 3.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.35,
        "最高价": 3.48,
        "最低价": 3.31,
        "昨收价": 3.37,
        "总市值": 23196462.0,
        "市盈率": -0.89,
        "成交量": 92871.0,
        "成交额": 313782.0,
        "振幅": 5.04,
        "换手率": 1.35,
        "symbol": "105.GFAI"
    },
    {
        "序号": 6975,
        "name": "Getty Images Holdings Inc-A",
        "最新价": 5.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.3,
        "最高价": 5.43,
        "最低价": 5.23,
        "昨收价": 5.37,
        "总市值": 2163988230.0,
        "市盈率": -50.68,
        "成交量": 275240.0,
        "成交额": 1462038.0,
        "振幅": 3.72,
        "换手率": 0.07,
        "symbol": "106.GETY"
    },
    {
        "序号": 6976,
        "name": "Getaround Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GETR_WS"
    },
    {
        "序号": 6977,
        "name": "Gelteq Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GELS"
    },
    {
        "序号": 6978,
        "name": "Great Elm Capital Corp Notes",
        "最新价": 25.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.14,
        "最高价": 25.2,
        "最低价": 25.0,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 3225.0,
        "成交额": 80915.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "105.GECCZ"
    },
    {
        "序号": 6979,
        "name": "Great Elm Capital Corp Notes",
        "最新价": 25.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.22,
        "最高价": 25.25,
        "最低价": 25.22,
        "昨收价": 25.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 972.0,
        "成交额": 24533.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.GECCM"
    },
    {
        "序号": 6980,
        "name": "Goldenstone Acquisition Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDSTW"
    },
    {
        "序号": 6981,
        "name": "Goldenstone Acquisition Ltd Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.79,
        "总市值": 81963538.0,
        "市盈率": 100.63,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDSTU"
    },
    {
        "序号": 6982,
        "name": "Goldenstone Acquisition Ltd Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDSTR"
    },
    {
        "序号": 6983,
        "name": "Green Dot Corp-A",
        "最新价": 9.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.19,
        "最高价": 9.23,
        "最低价": 9.0,
        "昨收价": 9.19,
        "总市值": 482036729.0,
        "市盈率": 13.31,
        "成交量": 521496.0,
        "成交额": 4765432.0,
        "振幅": 2.5,
        "换手率": 0.99,
        "symbol": "106.GDOT"
    },
    {
        "序号": 6984,
        "name": "Gardiner Healthcare Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 29600.0,
        "成交额": 74.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.GDNRW"
    },
    {
        "序号": 6985,
        "name": "Gardiner Healthcare Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 40821654.0,
        "市盈率": -95.75,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDNRU"
    },
    {
        "序号": 6986,
        "name": "Gardiner Healthcare Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 40821654.0,
        "市盈率": -95.75,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDNR"
    },
    {
        "序号": 6987,
        "name": "Gadsden Dynamic Multi-Asset ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GDMA_"
    },
    {
        "序号": 6988,
        "name": "Greifenberg Digital Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDLT"
    },
    {
        "序号": 6989,
        "name": "The GDL Fund",
        "最新价": 7.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.94,
        "最高价": 8.02,
        "最低价": 7.94,
        "昨收价": 7.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 6161.0,
        "成交额": 49297.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "106.GDL"
    },
    {
        "序号": 6990,
        "name": "GDEV Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GDEVW"
    },
    {
        "序号": 6991,
        "name": "Goldman Sachs Defensive Equity ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 43.94,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GDEF"
    },
    {
        "序号": 6992,
        "name": "绿康集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.GDD"
    },
    {
        "序号": 6993,
        "name": "Global Blue Group Holding AG Wt",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.07,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1053.0,
        "成交额": 74.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.GB_WS"
    },
    {
        "序号": 6994,
        "name": "BrandywineGLOBAL - Global Total",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GBND"
    },
    {
        "序号": 6995,
        "name": "Global Indemnity Group LLC-A",
        "最新价": 28.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 29.0,
        "最高价": 29.0,
        "最低价": 28.75,
        "昨收价": 28.75,
        "总市值": 389348169.0,
        "市盈率": 17.8,
        "成交量": 28509.0,
        "成交额": 826494.0,
        "振幅": 0.87,
        "换手率": 0.21,
        "symbol": "106.GBLI"
    },
    {
        "序号": 6996,
        "name": "Global Blockchain Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GBBKW"
    },
    {
        "序号": 6997,
        "name": "Global Blockchain Acquisition C",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 106.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GBBKR"
    },
    {
        "序号": 6998,
        "name": "Marblegate Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.39,
        "总市值": 124465935.0,
        "市盈率": -30.61,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GATEU"
    },
    {
        "序号": 6999,
        "name": "Marblegate Acquisition Corp",
        "最新价": 10.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.5,
        "最高价": 10.5,
        "最低价": 10.5,
        "昨收价": 10.5,
        "总市值": 125783669.0,
        "市盈率": -30.93,
        "成交量": 106.0,
        "成交额": 1113.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GATE"
    },
    {
        "序号": 7000,
        "name": "Generation Asia I Acquisition L",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 202500.0,
        "成交额": 7067.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.GAQ_WS"
    },
    {
        "序号": 7001,
        "name": "Generation Asia I Acquisition L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.83,
        "总市值": 164423540.0,
        "市盈率": 19.59,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GAQ_U"
    },
    {
        "序号": 7002,
        "name": "Generation Asia I Acquisition L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.85,
        "总市值": 164727185.0,
        "市盈率": 19.63,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.GAQ"
    },
    {
        "序号": 7003,
        "name": "Golden Arrow Merger Corp Wt",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.15,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 31.0,
        "成交额": 4.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GAMCW"
    },
    {
        "序号": 7004,
        "name": "Golden Arrow Merger Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.52,
        "总市值": 97709560.0,
        "市盈率": 37.45,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.GAMCU"
    },
    {
        "序号": 7005,
        "name": "Gladstone Investment Corp Notes",
        "最新价": 25.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.43,
        "最高价": 25.45,
        "最低价": 25.4,
        "昨收价": 25.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 8380.0,
        "成交额": 213139.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.GAINL"
    },
    {
        "序号": 7006,
        "name": "富谷集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FVTI"
    },
    {
        "序号": 7007,
        "name": "American Century Multisector Fl",
        "最新价": 50.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.53,
        "最高价": 50.53,
        "最低价": 50.53,
        "昨收价": 50.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 141.0,
        "成交额": 7124.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FUSI"
    },
    {
        "序号": 7008,
        "name": "First Trust Ultra Short Duratio",
        "最新价": 20.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 20.07,
        "最高价": 20.09,
        "最低价": 20.06,
        "昨收价": 20.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 51187.0,
        "成交额": 1027237.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.FUMB"
    },
    {
        "序号": 7009,
        "name": "富达",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FUDA"
    },
    {
        "序号": 7010,
        "name": "FutureTech II Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FTIIW"
    },
    {
        "序号": 7011,
        "name": "FutureTech II Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 91901715.0,
        "市盈率": 32.7,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FTIIU"
    },
    {
        "序号": 7012,
        "name": "FutureTech II Acquisition Corp-",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.85,
        "总市值": 91480148.0,
        "市盈率": 32.55,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FTII"
    },
    {
        "序号": 7013,
        "name": "First Trust Bloomberg Inflation",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.68,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FTIF"
    },
    {
        "序号": 7014,
        "name": "First Trust Emerging Markets Hu",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 21.98,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FTHF"
    },
    {
        "序号": 7015,
        "name": "First Trust Capital Strength ET",
        "最新价": 77.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 77.76,
        "最高价": 78.07,
        "最低价": 77.73,
        "昨收价": 77.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 352493.0,
        "成交额": 27443448.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.FTCS"
    },
    {
        "序号": 7016,
        "name": "FinServ Acquisition Corp II Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FSRXW"
    },
    {
        "序号": 7017,
        "name": "FinServ Acquisition Corp II Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.3,
        "总市值": 96182997.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FSRXU"
    },
    {
        "序号": 7018,
        "name": "FinServ Acquisition Corp II-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.34,
        "总市值": 96556523.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FSRX"
    },
    {
        "序号": 7019,
        "name": "Franklin Street Properties Corp",
        "最新价": 2.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.46,
        "最高价": 2.56,
        "最低价": 2.46,
        "昨收价": 2.49,
        "总市值": 257541579.0,
        "市盈率": -4.72,
        "成交量": 357230.0,
        "成交额": 898673.0,
        "振幅": 4.02,
        "换手率": 0.35,
        "symbol": "107.FSP"
    },
    {
        "序号": 7020,
        "name": "First Seacoast Bancorp Inc",
        "最新价": 7.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.2,
        "最高价": 7.2,
        "最低价": 7.2,
        "昨收价": 7.2,
        "总市值": 36558835.0,
        "市盈率": 25.09,
        "成交量": 52.0,
        "成交额": 374.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.FSEA"
    },
    {
        "序号": 7021,
        "name": "Five Star Bancorp",
        "最新价": 23.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.98,
        "最高价": 24.25,
        "最低价": 23.74,
        "昨收价": 23.89,
        "总市值": 412270279.0,
        "市盈率": 8.21,
        "成交量": 17848.0,
        "成交额": 427721.0,
        "振幅": 2.13,
        "换手率": 0.1,
        "symbol": "105.FSBC"
    },
    {
        "序号": 7022,
        "name": "Forest Road Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FRXB_WS"
    },
    {
        "序号": 7023,
        "name": "Forest Road Acquisition Corp II",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.5,
        "总市值": 138858342.0,
        "市盈率": 27.51,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FRXB_U"
    },
    {
        "序号": 7024,
        "name": "Forest Road Acquisition Corp II",
        "最新价": 10.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.43,
        "最高价": 10.43,
        "最低价": 10.43,
        "昨收价": 10.43,
        "总市值": 137932620.0,
        "市盈率": 27.33,
        "成交量": 840.0,
        "成交额": 8758.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.FRXB"
    },
    {
        "序号": 7025,
        "name": "Fresh Tracks Therapeutics Inc",
        "最新价": 0.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.91,
        "最高价": 0.93,
        "最低价": 0.9,
        "昨收价": 0.92,
        "总市值": 5495442.0,
        "市盈率": -0.61,
        "成交量": 16146.0,
        "成交额": 14702.0,
        "振幅": 3.26,
        "换手率": 0.27,
        "symbol": "105.FRTX"
    },
    {
        "序号": 7026,
        "name": "自动视角控股",
        "最新价": 1.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.28,
        "最高价": 1.38,
        "最低价": 1.24,
        "昨收价": 1.26,
        "总市值": 13565141.0,
        "市盈率": -0.73,
        "成交量": 120538.0,
        "成交额": 158841.0,
        "振幅": 11.11,
        "换手率": 1.12,
        "symbol": "105.FRSX"
    },
    {
        "序号": 7027,
        "name": "Freeline Therapeutics Holdings ",
        "最新价": 6.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.35,
        "最高价": 6.4,
        "最低价": 6.3,
        "昨收价": 6.33,
        "总市值": 27585805.0,
        "市盈率": -0.55,
        "成交量": 55521.0,
        "成交额": 352508.0,
        "振幅": 1.58,
        "换手率": 1.27,
        "symbol": "105.FRLN"
    },
    {
        "序号": 7028,
        "name": "Fortune Rise Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FRLAW"
    },
    {
        "序号": 7029,
        "name": "Fortune Rise Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.28,
        "总市值": 70745881.0,
        "市盈率": 114.0,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FRLAU"
    },
    {
        "序号": 7030,
        "name": "Fortune Rise Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.95,
        "总市值": 68676188.0,
        "市盈率": 110.66,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FRLA"
    },
    {
        "序号": 7031,
        "name": "房地产信托指数ETF-First Trust",
        "最新价": 24.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.73,
        "最高价": 24.81,
        "最低价": 24.61,
        "昨收价": 24.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 20620.0,
        "成交额": 509166.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.FRI"
    },
    {
        "序号": 7032,
        "name": "The Fortegra Group Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FRF"
    },
    {
        "序号": 7033,
        "name": "FREYR Battery Wt",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.24,
        "最高价": 0.25,
        "最低价": 0.24,
        "昨收价": 0.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 2465.0,
        "成交额": 622.0,
        "振幅": 4.0,
        "换手率": null,
        "symbol": "106.FREY_WS"
    },
    {
        "序号": 7034,
        "name": "Whole Earth Brands Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FREEW"
    },
    {
        "序号": 7035,
        "name": "富兰克林金融服务",
        "最新价": 33.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 33.21,
        "最高价": 33.21,
        "最低价": 33.21,
        "昨收价": 33.21,
        "总市值": 144847374.0,
        "市盈率": 10.46,
        "成交量": 675.0,
        "成交额": 22416.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.FRAF"
    },
    {
        "序号": 7036,
        "name": "First Trust Preferred Securitie",
        "最新价": 16.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.46,
        "最高价": 16.54,
        "最低价": 16.46,
        "昨收价": 16.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 1809968.0,
        "成交额": 29893531.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FPE"
    },
    {
        "序号": 7037,
        "name": "FPA Energy Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FPAQW"
    },
    {
        "序号": 7038,
        "name": "FPA Energy Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FPAQU"
    },
    {
        "序号": 7039,
        "name": "FPA Energy Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FPAQR"
    },
    {
        "序号": 7040,
        "name": "FPA Energy Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FPAQ"
    },
    {
        "序号": 7041,
        "name": "First Person Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FP"
    },
    {
        "序号": 7042,
        "name": "FOXO Technologies Inc-A",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.33,
        "最高价": 0.34,
        "最低价": 0.31,
        "昨收价": 0.32,
        "总市值": 2046987.0,
        "市盈率": -0.03,
        "成交量": 223103.0,
        "成交额": 71710.0,
        "振幅": 9.38,
        "换手率": 3.49,
        "symbol": "107.FOXO"
    },
    {
        "序号": 7043,
        "name": "Four Leaf Acquisition Corp Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 80.0,
        "成交额": 4.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FORLW"
    },
    {
        "序号": 7044,
        "name": "Four Leaf Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.56,
        "总市值": 72129658.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FORLU"
    },
    {
        "序号": 7045,
        "name": "Forian Inc",
        "最新价": 2.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.44,
        "最高价": 2.55,
        "最低价": 2.42,
        "昨收价": 2.43,
        "总市值": 75270492.0,
        "市盈率": 12.22,
        "成交量": 4856.0,
        "成交额": 12031.0,
        "振幅": 5.35,
        "换手率": 0.02,
        "symbol": "105.FORA"
    },
    {
        "序号": 7046,
        "name": "爱八方",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FONG"
    },
    {
        "序号": 7047,
        "name": "Finance of America Companies In",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FOA_WS"
    },
    {
        "序号": 7048,
        "name": "Finnovate Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 16.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FNVTW"
    },
    {
        "序号": 7049,
        "name": "Finnovate Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.05,
        "总市值": 100398444.0,
        "市盈率": 27.58,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FNVTU"
    },
    {
        "序号": 7050,
        "name": "Finnovate Acquisition Corp-A",
        "最新价": 11.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.03,
        "最高价": 11.03,
        "最低价": 11.03,
        "昨收价": 11.03,
        "总市值": 100216727.0,
        "市盈率": 27.53,
        "成交量": 2.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.FNVT"
    },
    {
        "序号": 7051,
        "name": "F.N.B. Corp Series E Pfd",
        "最新价": 24.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.76,
        "最高价": 24.82,
        "最低价": 24.65,
        "昨收价": 24.8,
        "总市值": 2749750.0,
        "市盈率": null,
        "成交量": 5353.0,
        "成交额": 132615.0,
        "振幅": 0.69,
        "换手率": 4.83,
        "symbol": "106.FNB_E"
    },
    {
        "序号": 7052,
        "name": "Foremost Lithium Resource & Tec",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.46,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FMSTW"
    },
    {
        "序号": 7053,
        "name": "Franklin FTSE South Africa ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.58,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FLZA"
    },
    {
        "序号": 7054,
        "name": "The Flexi Group Holdings Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLXGW"
    },
    {
        "序号": 7055,
        "name": "The Flexi Group Holdings Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLXG"
    },
    {
        "序号": 7056,
        "name": "Franklin Ultra Short Bond ETF",
        "最新价": 24.71,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.71,
        "最高价": 24.71,
        "最低价": 24.71,
        "昨收价": 24.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 74.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FLUD"
    },
    {
        "序号": 7057,
        "name": "F5 Finishes Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLRZ"
    },
    {
        "序号": 7058,
        "name": "Flame Acquisition Corp Unit Con",
        "最新价": 10.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.78,
        "昨收价": 10.78,
        "总市值": 143289722.0,
        "市盈率": -9.53,
        "成交量": 62.0,
        "成交额": 668.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.FLME_U"
    },
    {
        "序号": 7059,
        "name": "Franklin FTSE Italy ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.89,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FLIY"
    },
    {
        "序号": 7060,
        "name": "Feutune Light Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLFVW"
    },
    {
        "序号": 7061,
        "name": "Feutune Light Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.0,
        "总市值": 87847298.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLFVU"
    },
    {
        "序号": 7062,
        "name": "Feutune Light Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLFVR"
    },
    {
        "序号": 7063,
        "name": "Feutune Light Acquisition Corp-",
        "最新价": 10.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.79,
        "最高价": 10.8,
        "最低价": 10.79,
        "昨收价": 10.8,
        "总市值": 86250074.0,
        "市盈率": null,
        "成交量": 2780.0,
        "成交额": 30000.0,
        "振幅": 0.09,
        "换手率": 0.03,
        "symbol": "105.FLFV"
    },
    {
        "序号": 7064,
        "name": "Franklin FTSE France ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 27.73,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FLFR"
    },
    {
        "序号": 7065,
        "name": "Felicitex Therapeutics Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLCTW"
    },
    {
        "序号": 7066,
        "name": "Felicitex Therapeutics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FLCT"
    },
    {
        "序号": 7067,
        "name": "Empire State Realty Op LP Serie",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.58,
        "总市值": 268895224.0,
        "市盈率": 3.34,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FISK"
    },
    {
        "序号": 7068,
        "name": "Frontier Investment Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FICVW"
    },
    {
        "序号": 7069,
        "name": "Focus Impact Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FIACW"
    },
    {
        "序号": 7070,
        "name": "Focus Impact Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.83,
        "总市值": 124033727.0,
        "市盈率": 114.98,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FIACU"
    },
    {
        "序号": 7071,
        "name": "Focus Impact Acquisition Corp-A",
        "最新价": 10.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.87,
        "昨收价": 10.87,
        "总市值": 124491838.0,
        "市盈率": 115.4,
        "成交量": 4553.0,
        "成交额": 49587.0,
        "振幅": 0.28,
        "换手率": 0.04,
        "symbol": "105.FIAC"
    },
    {
        "序号": 7072,
        "name": "Future Health ESG Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FHLTW"
    },
    {
        "序号": 7073,
        "name": "Future Health ESG Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.27,
        "总市值": 67695085.0,
        "市盈率": 71.26,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FHLTU"
    },
    {
        "序号": 7074,
        "name": "Future Health ESG Corp",
        "最新价": 10.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.58,
        "最高价": 10.58,
        "最低价": 10.58,
        "昨收价": 10.58,
        "总市值": 69738461.0,
        "市盈率": 73.42,
        "成交量": 85.0,
        "成交额": 899.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.FHLT"
    },
    {
        "序号": 7075,
        "name": "FGI Industries Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FGIWW"
    },
    {
        "序号": 7076,
        "name": "FGI Industries Ltd",
        "最新价": 1.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.45,
        "最高价": 1.45,
        "最低价": 1.4,
        "昨收价": 1.45,
        "总市值": 13775000.0,
        "市盈率": 15.28,
        "成交量": 6959.0,
        "成交额": 10027.0,
        "振幅": 3.45,
        "换手率": 0.07,
        "symbol": "105.FGI"
    },
    {
        "序号": 7077,
        "name": "FG Financial Group Inc Series A",
        "最新价": 15.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.25,
        "最高价": 15.25,
        "最低价": 15.25,
        "昨收价": 15.25,
        "总市值": 13642345.0,
        "市盈率": null,
        "成交量": 152.0,
        "成交额": 2318.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.FGFPP"
    },
    {
        "序号": 7078,
        "name": "FG Financial Group Inc",
        "最新价": 1.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.32,
        "最高价": 1.32,
        "最低价": 1.25,
        "昨收价": 1.32,
        "总市值": 13937788.0,
        "市盈率": 3.33,
        "成交量": 8387.0,
        "成交额": 10914.0,
        "振幅": 5.3,
        "换手率": 0.08,
        "symbol": "105.FGF"
    },
    {
        "序号": 7079,
        "name": "FibroGen Inc",
        "最新价": 0.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.61,
        "最高价": 0.65,
        "最低价": 0.59,
        "昨收价": 0.62,
        "总市值": 60970936.0,
        "市盈率": -0.21,
        "成交量": 774248.0,
        "成交额": 485353.0,
        "振幅": 9.68,
        "换手率": 0.79,
        "symbol": "105.FGEN"
    },
    {
        "序号": 7080,
        "name": "法拉第未来(权证)",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 59436.0,
        "成交额": 1002.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FFIEW"
    },
    {
        "序号": 7081,
        "name": "Fintech Ecosystem Development C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FEXDW"
    },
    {
        "序号": 7082,
        "name": "Fintech Ecosystem Development C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.66,
        "总市值": 73602002.0,
        "市盈率": 41.38,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FEXDU"
    },
    {
        "序号": 7083,
        "name": "Fintech Ecosystem Development C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FEXDR"
    },
    {
        "序号": 7084,
        "name": "First Trust Energy Income and G",
        "最新价": 14.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.58,
        "最高价": 14.86,
        "最低价": 14.48,
        "昨收价": 14.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 43894.0,
        "成交额": 644782.0,
        "振幅": 2.59,
        "换手率": null,
        "symbol": "107.FEN"
    },
    {
        "序号": 7085,
        "name": "ETRACS 2x Leveraged IFED Invest",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 32.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FEDL"
    },
    {
        "序号": 7086,
        "name": "Foundations Dynamic Value ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.51,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FDVL"
    },
    {
        "序号": 7087,
        "name": "Fidelity Digital Health ETF",
        "最新价": 17.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.53,
        "最高价": 17.7,
        "最低价": 17.53,
        "昨收价": 17.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 2355.0,
        "成交额": 41496.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.FDHT"
    },
    {
        "序号": 7088,
        "name": "First Digital Health Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FDHA_WS"
    },
    {
        "序号": 7089,
        "name": "First Digital Health Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FDHA_U"
    },
    {
        "序号": 7090,
        "name": "First Digital Health Acquisitio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FDHA"
    },
    {
        "序号": 7091,
        "name": "First Trust Dynamic Europe Equi",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.59,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.FDEU"
    },
    {
        "序号": 7092,
        "name": "分蛋科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FDAN"
    },
    {
        "序号": 7093,
        "name": "First Trust S&P 500 Diversified",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 21.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.FCFY"
    },
    {
        "序号": 7094,
        "name": "Falcon's Beyond Global Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.49,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FBYDW"
    },
    {
        "序号": 7095,
        "name": "伟博控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FBOX"
    },
    {
        "序号": 7096,
        "name": "FibroBiologics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FBLG"
    },
    {
        "序号": 7097,
        "name": "FaZe Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 3432.0,
        "成交额": 26.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.FAZEW"
    },
    {
        "序号": 7098,
        "name": "Fat Projects Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FATPW"
    },
    {
        "序号": 7099,
        "name": "Fat Projects Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.98,
        "总市值": 38162011.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FATPU"
    },
    {
        "序号": 7100,
        "name": "Fat Projects Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.92,
        "总市值": 37953476.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FATP"
    },
    {
        "序号": 7101,
        "name": "FAT Brands Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 3.09,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.FATBW"
    },
    {
        "序号": 7102,
        "name": "First Trust\/abrdn Global Opport",
        "最新价": 6.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.06,
        "最高价": 6.06,
        "最低价": 6.01,
        "昨收价": 6.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 13423.0,
        "成交额": 81080.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "106.FAM"
    },
    {
        "序号": 7103,
        "name": "巴西小型股ETF-iShares MSCI",
        "最新价": 14.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.33,
        "最高价": 14.57,
        "最低价": 14.25,
        "昨收价": 14.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 159128.0,
        "成交额": 2294331.0,
        "振幅": 2.21,
        "换手率": null,
        "symbol": "105.EWZS"
    },
    {
        "序号": 7104,
        "name": "Global X Emerging Markets Inter",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EWEB"
    },
    {
        "序号": 7105,
        "name": "Vertical Aerospace Ltd Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 9525.0,
        "成交额": 376.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.EVTL_WS"
    },
    {
        "序号": 7106,
        "name": "EV Mobility Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.EVMO"
    },
    {
        "序号": 7107,
        "name": "Evolv Technologies Holdings Inc",
        "最新价": 4.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.49,
        "最高价": 4.52,
        "最低价": 4.32,
        "昨收价": 4.45,
        "总市值": 672549068.0,
        "市盈率": -5.72,
        "成交量": 848485.0,
        "成交额": 3783183.0,
        "振幅": 4.49,
        "换手率": 0.56,
        "symbol": "105.EVLV"
    },
    {
        "序号": 7108,
        "name": "Eaton Vance Intermediate Munici",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 52.35,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.EVIM"
    },
    {
        "序号": 7109,
        "name": "Evergreen Corp Unit Cons of 1 C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.07,
        "总市值": 120698092.0,
        "市盈率": 31.08,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EVGRU"
    },
    {
        "序号": 7110,
        "name": "EVgo Inc Wt",
        "最新价": 0.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.21,
        "最高价": 0.23,
        "最低价": 0.19,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 9185.0,
        "成交额": 1902.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.EVGOW"
    },
    {
        "序号": 7111,
        "name": "EVe Mobility Acquisition Corp W",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 6.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EVE_WS"
    },
    {
        "序号": 7112,
        "name": "EVe Mobility Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.87,
        "总市值": 169606469.0,
        "市盈率": 24.06,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.EVE_U"
    },
    {
        "序号": 7113,
        "name": "EVe Mobility Acquisition Corp-A",
        "最新价": 10.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.8,
        "昨收价": 10.8,
        "总市值": 168514247.0,
        "市盈率": 23.9,
        "成交量": 1155.0,
        "成交额": 12474.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.EVE"
    },
    {
        "序号": 7114,
        "name": "Evaxion Biotech A\/S ADR",
        "最新价": 0.69,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.68,
        "最高价": 0.69,
        "最低价": 0.62,
        "昨收价": 0.69,
        "总市值": 19264283.0,
        "市盈率": -0.79,
        "成交量": 41768.0,
        "成交额": 28378.0,
        "振幅": 10.14,
        "换手率": 0.15,
        "symbol": "105.EVAX"
    },
    {
        "序号": 7115,
        "name": "盈兹",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ETZ"
    },
    {
        "序号": 7116,
        "name": "E2open Parent Holdings Inc Wt",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.26,
        "最高价": 0.3,
        "最低价": 0.26,
        "昨收价": 0.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 1294.0,
        "成交额": 353.0,
        "振幅": 13.33,
        "换手率": null,
        "symbol": "106.ETWO_WS"
    },
    {
        "序号": 7117,
        "name": "Eaton Vance Tax-Managed Buy-Wri",
        "最新价": 12.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.49,
        "最高价": 12.52,
        "最低价": 12.43,
        "昨收价": 12.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 194170.0,
        "成交额": 2421639.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "106.ETV"
    },
    {
        "序号": 7118,
        "name": "ETRE REIT LLC-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ESSF"
    },
    {
        "序号": 7119,
        "name": "Estrella Immunopharma Inc Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 17235.0,
        "成交额": 562.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.ESLAW"
    },
    {
        "序号": 7120,
        "name": "ESH Acquisition Corp-A",
        "最新价": 10.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.2,
        "最高价": 10.2,
        "最低价": 10.19,
        "昨收价": 10.2,
        "总市值": 149557500.0,
        "市盈率": 164.85,
        "成交量": 217328.0,
        "成交额": 2216738.0,
        "振幅": 0.1,
        "换手率": 1.48,
        "symbol": "105.ESHA"
    },
    {
        "序号": 7121,
        "name": "ESGL Holdings Ltd Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 10322.0,
        "成交额": 82.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ESGLW"
    },
    {
        "序号": 7122,
        "name": "ESGEN Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.99,
        "总市值": 91311019.0,
        "市盈率": 193.64,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ESACU"
    },
    {
        "序号": 7123,
        "name": "ESGEN Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.26,
        "总市值": 93554329.0,
        "市盈率": 198.4,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ESAC"
    },
    {
        "序号": 7124,
        "name": "ERShares NextGen Entrepreneurs ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.85,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ERSX"
    },
    {
        "序号": 7125,
        "name": "EQRx Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EQRXW"
    },
    {
        "序号": 7126,
        "name": "EQRx Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 2.34,
        "总市值": 1141209064.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EQRX"
    },
    {
        "序号": 7127,
        "name": "一品威客",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EPWK"
    },
    {
        "序号": 7128,
        "name": "Eos Energy Enterprises Inc Wt",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.18,
        "最高价": 0.21,
        "最低价": 0.17,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 153705.0,
        "成交额": 27581.0,
        "振幅": 22.22,
        "换手率": null,
        "symbol": "105.EOSEW"
    },
    {
        "序号": 7129,
        "name": "Accretion Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 258760.0,
        "成交额": 3752.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ENERW"
    },
    {
        "序号": 7130,
        "name": "Accretion Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.59,
        "总市值": 112246545.0,
        "市盈率": 34.23,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ENERU"
    },
    {
        "序号": 7131,
        "name": "Accretion Acquisition Corp Rt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ENERR"
    },
    {
        "序号": 7132,
        "name": "Accretion Acquisition Corp",
        "最新价": 10.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.56,
        "最高价": 10.56,
        "最低价": 10.56,
        "昨收价": 10.56,
        "总市值": 111928566.0,
        "市盈率": 34.14,
        "成交量": 88.0,
        "成交额": 929.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ENER"
    },
    {
        "序号": 7133,
        "name": "Energem Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 19.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ENCPW"
    },
    {
        "序号": 7134,
        "name": "Energem Corp Unit Cons of 1 CL ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.21,
        "总市值": 51790278.0,
        "市盈率": 300.76,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ENCPU"
    },
    {
        "序号": 7135,
        "name": "Endeavor Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ENACW"
    },
    {
        "序号": 7136,
        "name": "Endeavor Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ENACU"
    },
    {
        "序号": 7137,
        "name": "Endeavor Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ENAC"
    },
    {
        "序号": 7138,
        "name": "EMulate Therapeutics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EMTX"
    },
    {
        "序号": 7139,
        "name": "FTAC Emerald Acquisition Corp W",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 35.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EMLDW"
    },
    {
        "序号": 7140,
        "name": "FTAC Emerald Acquisition Corp U",
        "最新价": 10.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.58,
        "最高价": 10.58,
        "最低价": 10.58,
        "昨收价": 10.58,
        "总市值": 266842116.0,
        "市盈率": 49.38,
        "成交量": 1.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EMLDU"
    },
    {
        "序号": 7141,
        "name": "Embrace Change Acquisition Corp",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EMCGW"
    },
    {
        "序号": 7142,
        "name": "Embrace Change Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.26,
        "总市值": 91634308.0,
        "市盈率": 47.6,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EMCGU"
    },
    {
        "序号": 7143,
        "name": "Embrace Change Acquisition Corp",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.11,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EMCGR"
    },
    {
        "序号": 7144,
        "name": "Embrace Change Acquisition Corp",
        "最新价": 10.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.88,
        "最高价": 10.88,
        "最低价": 10.88,
        "昨收价": 10.88,
        "总市值": 88541853.0,
        "市盈率": 45.99,
        "成交量": 3.0,
        "成交额": 32.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.EMCG"
    },
    {
        "序号": 7145,
        "name": "Global X MSCI Emerging Markets ",
        "最新价": 25.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 25.0,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 49.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EMCC"
    },
    {
        "序号": 7146,
        "name": "Xtrackers Indxx Advanced Life S",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ELXR"
    },
    {
        "序号": 7147,
        "name": "Elutia Inc-A",
        "最新价": 2.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.98,
        "最高价": 2.05,
        "最低价": 1.94,
        "昨收价": 2.0,
        "总市值": 46395104.0,
        "市盈率": -1.37,
        "成交量": 117903.0,
        "成交额": 235514.0,
        "振幅": 5.5,
        "换手率": 0.51,
        "symbol": "105.ELUT"
    },
    {
        "序号": 7148,
        "name": "Electriq Power Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": 23618.0,
        "成交额": 236.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ELIQ_WS"
    },
    {
        "序号": 7149,
        "name": "Elate Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ELGPW"
    },
    {
        "序号": 7150,
        "name": "Elate Group Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ELGP"
    },
    {
        "序号": 7151,
        "name": "Elephant Oil Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ELEP"
    },
    {
        "序号": 7152,
        "name": "Energy Hunter Resources Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EHR"
    },
    {
        "序号": 7153,
        "name": "一嗨租车",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.EHIC"
    },
    {
        "序号": 7154,
        "name": "一修哥",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EHGO"
    },
    {
        "序号": 7155,
        "name": "瓦可能源",
        "最新价": 4.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.5,
        "最高价": 4.55,
        "最低价": 4.43,
        "昨收价": 4.49,
        "总市值": 472162819.0,
        "市盈率": 13.84,
        "成交量": 654338.0,
        "成交额": 2932277.0,
        "振幅": 2.67,
        "换手率": 0.62,
        "symbol": "106.EGY"
    },
    {
        "序号": 7156,
        "name": "EG Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.EGGF_WS"
    },
    {
        "序号": 7157,
        "name": "1847 Holdings LLC Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.EFSH_WS"
    },
    {
        "序号": 7158,
        "name": "1847 Holdings LLC",
        "最新价": 0.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.61,
        "最高价": 0.62,
        "最低价": 0.58,
        "昨收价": 0.59,
        "总市值": 1869208.0,
        "市盈率": -0.14,
        "成交量": 68175.0,
        "成交额": 41004.0,
        "振幅": 6.78,
        "换手率": 2.15,
        "symbol": "107.EFSH"
    },
    {
        "序号": 7159,
        "name": "Ellington Financial Inc Series ",
        "最新价": 22.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.85,
        "最高价": 22.85,
        "最低价": 22.85,
        "昨收价": 22.85,
        "总市值": 105110000.0,
        "市盈率": null,
        "成交量": 298.0,
        "成交额": 6809.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.EFC_A"
    },
    {
        "序号": 7160,
        "name": "Invesco S&P Emerging Markets Mo",
        "最新价": 14.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.54,
        "最高价": 14.59,
        "最低价": 14.49,
        "昨收价": 14.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 7267.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.EEMO"
    },
    {
        "序号": 7161,
        "name": "Global X Education ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.54,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EDUT"
    },
    {
        "序号": 7162,
        "name": "Educational Development Corp",
        "最新价": 0.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.87,
        "最高价": 0.89,
        "最低价": 0.87,
        "昨收价": 0.87,
        "总市值": 7456847.0,
        "市盈率": -4.31,
        "成交量": 13398.0,
        "成交额": 11716.0,
        "振幅": 2.3,
        "换手率": 0.16,
        "symbol": "105.EDUC"
    },
    {
        "序号": 7163,
        "name": "Virtus Stone Harbor Emerging Ma",
        "最新价": 4.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.32,
        "最高价": 4.34,
        "最低价": 4.3,
        "昨收价": 4.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 52424.0,
        "成交额": 226507.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.EDF"
    },
    {
        "序号": 7164,
        "name": "Edible Garden AG Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EDBLW"
    },
    {
        "序号": 7165,
        "name": "Okeanis Eco Tankers Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ECO"
    },
    {
        "序号": 7166,
        "name": "BlackRock ESG Capital Allocatio",
        "最新价": 15.91,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.85,
        "最高价": 15.98,
        "最低价": 15.85,
        "昨收价": 15.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 331815.0,
        "成交额": 5288165.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.ECAT"
    },
    {
        "序号": 7167,
        "name": "AdvisorShares Restaurant ETF",
        "最新价": 21.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 21.5,
        "最高价": 21.56,
        "最低价": 21.4,
        "昨收价": 21.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 1474.0,
        "成交额": 31593.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.EATZ"
    },
    {
        "序号": 7168,
        "name": "VegTech Plant-based Innovation ",
        "最新价": 15.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.6,
        "最高价": 15.6,
        "最低价": 15.6,
        "昨收价": 15.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 57.0,
        "成交额": 889.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EATV"
    },
    {
        "序号": 7169,
        "name": "Edify Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EACPW"
    },
    {
        "序号": 7170,
        "name": "Edify Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 84664303.0,
        "市盈率": 79.06,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.EACPU"
    },
    {
        "序号": 7171,
        "name": "敦信金融",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.36,
        "最高价": 0.4,
        "最低价": 0.36,
        "昨收价": 0.38,
        "总市值": 1510206.0,
        "市盈率": -13.38,
        "成交量": 70677.0,
        "成交额": 26917.0,
        "振幅": 10.53,
        "换手率": 1.78,
        "symbol": "107.DXF"
    },
    {
        "序号": 7172,
        "name": "AdvisorShares Dorsey Wright FSM",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 38.47,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DWUS"
    },
    {
        "序号": 7173,
        "name": "Dimensional Ultrashort Fixed In",
        "最新价": 50.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.5,
        "最高价": 50.5,
        "最低价": 50.45,
        "昨收价": 50.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 35674.0,
        "成交额": 1800381.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.DUSB"
    },
    {
        "序号": 7174,
        "name": "VanEck Durable High Dividend ET",
        "最新价": 30.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 30.43,
        "最高价": 30.44,
        "最低价": 30.33,
        "昨收价": 30.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 28388.0,
        "成交额": 861667.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.DURA"
    },
    {
        "序号": 7175,
        "name": "DUET Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DUETW"
    },
    {
        "序号": 7176,
        "name": "DUET Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 83213412.0,
        "市盈率": -1221.98,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DUETU"
    },
    {
        "序号": 7177,
        "name": "DUET Acquisition Corp-A",
        "最新价": 10.91,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.91,
        "最高价": 10.91,
        "最低价": 10.91,
        "昨收价": 10.91,
        "总市值": 83750768.0,
        "市盈率": -1229.87,
        "成交量": 105.0,
        "成交额": 1145.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.DUET"
    },
    {
        "序号": 7178,
        "name": "Merlyn.AI SectorSurfer Momentum",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 20.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DUDE"
    },
    {
        "序号": 7179,
        "name": "Data Storage Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DTSTW"
    },
    {
        "序号": 7180,
        "name": "Diana Shipping Inc Pfd-B",
        "最新价": 25.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.38,
        "最高价": 25.38,
        "最低价": 25.38,
        "昨收价": 25.38,
        "总市值": 65988000.0,
        "市盈率": null,
        "成交量": 299.0,
        "成交额": 7588.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.DSX_B"
    },
    {
        "序号": 7181,
        "name": "黛安娜船舶",
        "最新价": 2.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.93,
        "最高价": 2.98,
        "最低价": 2.93,
        "昨收价": 2.95,
        "总市值": 319290147.0,
        "市盈率": 4.83,
        "成交量": 626396.0,
        "成交额": 1846121.0,
        "振幅": 1.69,
        "换手率": 0.58,
        "symbol": "106.DSX"
    },
    {
        "序号": 7182,
        "name": "德斯维尔工业",
        "最新价": 2.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.5,
        "最高价": 2.56,
        "最低价": 2.5,
        "昨收价": 2.56,
        "总市值": 40794212.0,
        "市盈率": 6.61,
        "成交量": 683.0,
        "成交额": 1739.0,
        "振幅": 2.34,
        "换手率": 0.0,
        "symbol": "105.DSWL"
    },
    {
        "序号": 7183,
        "name": "DSS Inc",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.13,
        "最高价": 0.14,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": 18234353.0,
        "市盈率": -0.24,
        "成交量": 154350.0,
        "成交额": 20328.0,
        "振幅": 7.69,
        "换手率": 0.11,
        "symbol": "107.DSS"
    },
    {
        "序号": 7184,
        "name": "BNY Mellon Strategic Municipal ",
        "最新价": 5.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.59,
        "最高价": 5.6,
        "最低价": 5.58,
        "昨收价": 5.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 94176.0,
        "成交额": 526028.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "106.DSM"
    },
    {
        "序号": 7185,
        "name": "Innovator Double Stacker ETF - ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 29.72,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DSJA"
    },
    {
        "序号": 7186,
        "name": "Direct Selling Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.85,
        "总市值": 123098610.0,
        "市盈率": 55.12,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.DSAQ_U"
    },
    {
        "序号": 7187,
        "name": "Direct Selling Acquisition Corp",
        "最新价": 10.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.9,
        "最高价": 10.9,
        "最低价": 10.88,
        "昨收价": 10.9,
        "总市值": 123665885.0,
        "市盈率": 55.38,
        "成交量": 9637.0,
        "成交额": 105040.0,
        "振幅": 0.18,
        "换手率": 0.08,
        "symbol": "106.DSAQ"
    },
    {
        "序号": 7188,
        "name": "Dermata Therapeutics Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DRMAW"
    },
    {
        "序号": 7189,
        "name": "DP Cap Acquisition Corp I Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.79,
        "总市值": 105843448.0,
        "市盈率": 20.05,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DPCSU"
    },
    {
        "序号": 7190,
        "name": "Docola Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DOCOW"
    },
    {
        "序号": 7191,
        "name": "Docola Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DOCO"
    },
    {
        "序号": 7192,
        "name": "蛋壳公寓",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 2.37,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.DNK"
    },
    {
        "序号": 7193,
        "name": "dMY Squared Technology Group In",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.5,
        "总市值": 82936875.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DMYY_U"
    },
    {
        "序号": 7194,
        "name": "BNY Mellon Municipal Income",
        "最新价": 6.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.37,
        "最高价": 6.41,
        "最低价": 6.37,
        "昨收价": 6.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 16535.0,
        "成交额": 105777.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.DMF"
    },
    {
        "序号": 7195,
        "name": "Subversive Decarbonization ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DKRB"
    },
    {
        "序号": 7196,
        "name": "大宗商品ETN-iPath",
        "最新价": 30.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 29.93,
        "最高价": 30.28,
        "最低价": 29.93,
        "昨收价": 30.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 84898.0,
        "成交额": 2554771.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DJP"
    },
    {
        "序号": 7197,
        "name": "ETRACS Bloomberg Commodity Inde",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.72,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DJCB"
    },
    {
        "序号": 7198,
        "name": "Dimensional International Small",
        "最新价": 24.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.61,
        "最高价": 24.75,
        "最低价": 24.56,
        "昨收价": 24.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 246484.0,
        "成交额": 6073572.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.DISV"
    },
    {
        "序号": 7199,
        "name": "Distoken Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 35.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DISTW"
    },
    {
        "序号": 7200,
        "name": "Distoken Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DISTR"
    },
    {
        "序号": 7201,
        "name": "Disruptive Acquisition Corp I W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DISAW"
    },
    {
        "序号": 7202,
        "name": "Disruptive Acquisition Corp I U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.64,
        "总市值": 91334824.0,
        "市盈率": 30.11,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DISAU"
    },
    {
        "序号": 7203,
        "name": "Disruptive Acquisition Corp I-A",
        "最新价": 10.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.64,
        "最高价": 10.64,
        "最低价": 10.64,
        "昨收价": 10.64,
        "总市值": 91334824.0,
        "市盈率": 30.11,
        "成交量": 65.0,
        "成交额": 691.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.DISA"
    },
    {
        "序号": 7204,
        "name": "Davis Select International ETF",
        "最新价": 17.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.81,
        "最高价": 17.95,
        "最低价": 17.81,
        "昨收价": 17.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 16284.0,
        "成交额": 291806.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.DINT"
    },
    {
        "序号": 7205,
        "name": "DHT控股",
        "最新价": 9.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 9.86,
        "最高价": 9.92,
        "最低价": 9.68,
        "昨收价": 9.79,
        "总市值": 1576185516.0,
        "市盈率": 8.38,
        "成交量": 1575077.0,
        "成交额": 15418952.0,
        "振幅": 2.45,
        "换手率": 0.98,
        "symbol": "106.DHT"
    },
    {
        "序号": 7206,
        "name": "DHC Acquisition Corp Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 9000.0,
        "成交额": 189.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DHCAW"
    },
    {
        "序号": 7207,
        "name": "Digital Health Acquisition Corp",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 21.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DHACW"
    },
    {
        "序号": 7208,
        "name": "Digital Health Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 13.34,
        "总市值": 47810106.0,
        "市盈率": -17.15,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DHACU"
    },
    {
        "序号": 7209,
        "name": "多尼戈尔股份-B",
        "最新价": 13.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 13.75,
        "最高价": 13.75,
        "最低价": 13.75,
        "昨收价": 13.75,
        "总市值": 457127385.0,
        "市盈率": 46.29,
        "成交量": 99.0,
        "成交额": 1361.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.DGICB"
    },
    {
        "序号": 7210,
        "name": "DESRI Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DESR"
    },
    {
        "序号": 7211,
        "name": "Sterling Capital Diverse Multi-",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.91,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DEIF"
    },
    {
        "序号": 7212,
        "name": "Denali Capital Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DECAW"
    },
    {
        "序号": 7213,
        "name": "Denali Capital Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.98,
        "总市值": 72471612.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DECAU"
    },
    {
        "序号": 7214,
        "name": "Denali Capital Acquisition Corp",
        "最新价": 11.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.01,
        "最高价": 11.01,
        "最低价": 11.01,
        "昨收价": 11.01,
        "总市值": 72669622.0,
        "市盈率": null,
        "成交量": 55.0,
        "成交额": 605.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.DECA"
    },
    {
        "序号": 7215,
        "name": "Dakota Gold Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.94,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DC_WS"
    },
    {
        "序号": 7216,
        "name": "Tritium DCFC Ltd Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 17344.0,
        "成交额": 1612.0,
        "振幅": 11.11,
        "换手率": null,
        "symbol": "105.DCFCW"
    },
    {
        "序号": 7217,
        "name": "达艺",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DCCA"
    },
    {
        "序号": 7218,
        "name": "Innovator Double Stacker 9 Buff",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 29.37,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.DBJA"
    },
    {
        "序号": 7219,
        "name": "大爱之水",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DAZS"
    },
    {
        "序号": 7220,
        "name": "Dave Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 45383.0,
        "成交额": 1071.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.DAVEW"
    },
    {
        "序号": 7221,
        "name": "DatChat Inc Wt-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DATSW"
    },
    {
        "序号": 7222,
        "name": "世纪佳缘",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.DATE"
    },
    {
        "序号": 7223,
        "name": "当当网",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.DANG"
    },
    {
        "序号": 7224,
        "name": "Citigroup Inc Series K Pfd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.99,
        "总市值": 1494402.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.C_K"
    },
    {
        "序号": 7225,
        "name": "Cyclo Therapeutics Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.24,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CYTHW"
    },
    {
        "序号": 7226,
        "name": "Cyteir Therapeutics Inc",
        "最新价": 3.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.07,
        "最高价": 3.14,
        "最低价": 3.07,
        "昨收价": 3.09,
        "总市值": 111234030.0,
        "市盈率": -3.35,
        "成交量": 62509.0,
        "成交额": 193946.0,
        "振幅": 2.27,
        "换手率": 0.17,
        "symbol": "105.CYT"
    },
    {
        "序号": 7227,
        "name": "Cyclacel Pharmaceuticals Inc",
        "最新价": 0.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.27,
        "最高价": 0.28,
        "最低价": 0.27,
        "昨收价": 0.27,
        "总市值": 3413562.0,
        "市盈率": -0.14,
        "成交量": 75194.0,
        "成交额": 20499.0,
        "振幅": 3.7,
        "换手率": 0.59,
        "symbol": "105.CYCC"
    },
    {
        "序号": 7228,
        "name": "MFS Investment Grade Municipal ",
        "最新价": 7.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.4,
        "最高价": 7.42,
        "最低价": 7.33,
        "昨收价": 7.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 45306.0,
        "成交额": 334380.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "106.CXH"
    },
    {
        "序号": 7229,
        "name": "CXApp Inc Wt",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.08,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 15635.0,
        "成交额": 1525.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.CXAIW"
    },
    {
        "序号": 7230,
        "name": "CPI Aerostructures Inc",
        "最新价": 2.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.67,
        "最高价": 2.68,
        "最低价": 2.61,
        "昨收价": 2.64,
        "总市值": 33455152.0,
        "市盈率": 3.61,
        "成交量": 9944.0,
        "成交额": 26353.0,
        "振幅": 2.65,
        "换手率": 0.08,
        "symbol": "107.CVU"
    },
    {
        "序号": 7231,
        "name": "Churchill Capital Corp VII Wt",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 102343.0,
        "成交额": 11493.0,
        "振幅": 10.0,
        "换手率": null,
        "symbol": "106.CVII_WS"
    },
    {
        "序号": 7232,
        "name": "Churchill Capital Corp VII Unit",
        "最新价": 10.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.52,
        "最高价": 10.52,
        "最低价": 10.52,
        "昨收价": 10.52,
        "总市值": 973269067.0,
        "市盈率": 67.45,
        "成交量": 101.0,
        "成交额": 1062.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.CVII_U"
    },
    {
        "序号": 7233,
        "name": "Churchill Capital Corp VII-A",
        "最新价": 10.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.48,
        "最高价": 10.5,
        "最低价": 10.48,
        "昨收价": 10.5,
        "总市值": 971418746.0,
        "市盈率": 67.32,
        "成交量": 105275.0,
        "成交额": 1104335.0,
        "振幅": 0.19,
        "换手率": 0.11,
        "symbol": "106.CVII"
    },
    {
        "序号": 7234,
        "name": "CuriosityStream Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 146890.0,
        "成交额": 1565.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CURIW"
    },
    {
        "序号": 7235,
        "name": "Cuentas Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CUENW"
    },
    {
        "序号": 7236,
        "name": "Curative Biotechnology Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CUBT_WS"
    },
    {
        "序号": 7237,
        "name": "Curative Biotechnology Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CUBT"
    },
    {
        "序号": 7238,
        "name": "Innovid Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CTV_WS"
    },
    {
        "序号": 7239,
        "name": "Castor Maritime Inc",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.4,
        "最高价": 0.41,
        "最低价": 0.4,
        "昨收价": 0.4,
        "总市值": 38649550.0,
        "市盈率": 0.82,
        "成交量": 277080.0,
        "成交额": 111193.0,
        "振幅": 2.5,
        "换手率": 0.29,
        "symbol": "105.CTRM"
    },
    {
        "序号": 7240,
        "name": "Cytek Biosciences Inc",
        "最新价": 7.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.62,
        "最高价": 7.81,
        "最低价": 7.1,
        "昨收价": 7.66,
        "总市值": 1038306520.0,
        "市盈率": -73.52,
        "成交量": 707447.0,
        "成交额": 5413987.0,
        "振幅": 9.27,
        "换手率": 0.52,
        "symbol": "105.CTKB"
    },
    {
        "序号": 7241,
        "name": "Carmell Therapeutics Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CTCXW"
    },
    {
        "序号": 7242,
        "name": "21世纪不动产",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CTC"
    },
    {
        "序号": 7243,
        "name": "Constellation Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CSTA_WS"
    },
    {
        "序号": 7244,
        "name": "Constellation Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.03,
        "总市值": 135049588.0,
        "市盈率": 48.91,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CSTA_U"
    },
    {
        "序号": 7245,
        "name": "心灵鸡汤娱乐 (权证)",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1050.0,
        "成交额": 7.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CSSEL"
    },
    {
        "序号": 7246,
        "name": "心灵鸡汤娱乐",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.27,
        "昨收价": 0.29,
        "总市值": 9100860.0,
        "市盈率": -0.05,
        "成交量": 173806.0,
        "成交额": 49262.0,
        "振幅": 6.9,
        "换手率": 0.55,
        "symbol": "105.CSSE"
    },
    {
        "序号": 7247,
        "name": "Consilium Acquisition Corp I Lt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 73430.0,
        "成交额": 3152.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CSLMW"
    },
    {
        "序号": 7248,
        "name": "Consilium Acquisition Corp I Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.72,
        "总市值": 102010845.0,
        "市盈率": 16.7,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CSLMU"
    },
    {
        "序号": 7249,
        "name": "Consilium Acquisition Corp I Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CSLMR"
    },
    {
        "序号": 7250,
        "name": "Consilium Acquisition Corp I Lt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.83,
        "总市值": 103057598.0,
        "市盈率": 16.87,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CSLM"
    },
    {
        "序号": 7251,
        "name": "基岩资本",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CSCA"
    },
    {
        "序号": 7252,
        "name": "Champions Oncology Inc",
        "最新价": 4.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.93,
        "最高价": 5.03,
        "最低价": 4.93,
        "昨收价": 4.97,
        "总市值": 66991699.0,
        "市盈率": -8.84,
        "成交量": 1712.0,
        "成交额": 8519.0,
        "振幅": 2.01,
        "换手率": 0.01,
        "symbol": "105.CSBR"
    },
    {
        "序号": 7253,
        "name": "Ceragon网络",
        "最新价": 1.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.87,
        "最高价": 1.9,
        "最低价": 1.86,
        "昨收价": 1.89,
        "总市值": 159501534.0,
        "市盈率": -21.0,
        "成交量": 81302.0,
        "成交额": 153550.0,
        "振幅": 2.12,
        "换手率": 0.1,
        "symbol": "105.CRNT"
    },
    {
        "序号": 7254,
        "name": "Carmot Therapeutics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CRMO"
    },
    {
        "序号": 7255,
        "name": "Cargo Therapeutics Inc",
        "最新价": 16.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.05,
        "最高价": 16.41,
        "最低价": 15.55,
        "昨收价": 16.05,
        "总市值": 620694331.0,
        "市盈率": -10.96,
        "成交量": 83939.0,
        "成交额": 1348412.0,
        "振幅": 5.36,
        "换手率": 0.22,
        "symbol": "105.CRGX"
    },
    {
        "序号": 7256,
        "name": "Cortigent Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CRGT"
    },
    {
        "序号": 7257,
        "name": "Freightos Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CRGOW"
    },
    {
        "序号": 7258,
        "name": "Cornerstone Total Return Fund",
        "最新价": 7.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.38,
        "最高价": 7.39,
        "最低价": 7.35,
        "昨收价": 7.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 443575.0,
        "成交额": 3269579.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.CRF"
    },
    {
        "序号": 7259,
        "name": "Creative Realities Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CREXW"
    },
    {
        "序号": 7260,
        "name": "Carbon Revolution PLC Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 27939.0,
        "成交额": 1384.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.CREVW"
    },
    {
        "序号": 7261,
        "name": "Cardiff Oncology Inc",
        "最新价": 1.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.29,
        "最高价": 1.31,
        "最低价": 1.26,
        "昨收价": 1.3,
        "总市值": 58080320.0,
        "市盈率": -1.42,
        "成交量": 98463.0,
        "成交额": 126683.0,
        "振幅": 3.85,
        "换手率": 0.22,
        "symbol": "105.CRDF"
    },
    {
        "序号": 7262,
        "name": "Cepton Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2000.0,
        "成交额": 30.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CPTNW"
    },
    {
        "序号": 7263,
        "name": "Cepton Inc",
        "最新价": 4.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.42,
        "最高价": 4.65,
        "最低价": 4.01,
        "昨收价": 4.5,
        "总市值": 71311208.0,
        "市盈率": -1.29,
        "成交量": 29617.0,
        "成交额": 129762.0,
        "振幅": 14.22,
        "换手率": 0.19,
        "symbol": "105.CPTN"
    },
    {
        "序号": 7264,
        "name": "Crown PropTech Acquisitions Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.68,
        "总市值": 118510460.0,
        "市盈率": 10.02,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CPTK_U"
    },
    {
        "序号": 7265,
        "name": "Crown PropTech Acquisitions-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.61,
        "总市值": 117733706.0,
        "市盈率": 9.95,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CPTK"
    },
    {
        "序号": 7266,
        "name": "惠普森医药",
        "最新价": 0.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.12,
        "最高价": 0.12,
        "最低价": 0.11,
        "昨收价": 0.12,
        "总市值": 3954201.0,
        "市盈率": -1.27,
        "成交量": 1597159.0,
        "成交额": 183779.0,
        "振幅": 8.33,
        "换手率": 4.85,
        "symbol": "107.CPHI"
    },
    {
        "序号": 7267,
        "name": "CorEnergy Infrastructure Trust ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 4.2,
        "总市值": 217602.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CORR_A"
    },
    {
        "序号": 7268,
        "name": "Corner Growth Acquisition Corp ",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.08,
        "最低价": 0.05,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 15845.0,
        "成交额": 1091.0,
        "振幅": 42.86,
        "换手率": null,
        "symbol": "105.COOLW"
    },
    {
        "序号": 7269,
        "name": "CONX Corp Unit Cons of 1 CL A +",
        "最新价": 10.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.25,
        "最高价": 10.25,
        "最低价": 10.25,
        "昨收价": 10.25,
        "总市值": 213920257.0,
        "市盈率": -122.24,
        "成交量": 77.0,
        "成交额": 789.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CONXU"
    },
    {
        "序号": 7270,
        "name": "CONX Corp-A",
        "最新价": 10.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.51,
        "最高价": 10.51,
        "最低价": 10.51,
        "昨收价": 10.51,
        "总市值": 219346527.0,
        "市盈率": -125.34,
        "成交量": 115.0,
        "成交额": 1208.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.CONX"
    },
    {
        "序号": 7271,
        "name": "COMSovereign Holding Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.COMSW"
    },
    {
        "序号": 7272,
        "name": "COMSovereign Holding Corp",
        "最新价": 1.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.07,
        "最高价": 1.14,
        "最低价": 1.04,
        "昨收价": 1.04,
        "总市值": 2803394.0,
        "市盈率": -0.04,
        "成交量": 10499.0,
        "成交额": 11410.0,
        "振幅": 9.62,
        "换手率": 0.39,
        "symbol": "105.COMS"
    },
    {
        "序号": 7273,
        "name": "Coeptis Therapeutics Holdings I",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 5858.0,
        "成交额": 239.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.COEPW"
    },
    {
        "序号": 7274,
        "name": "科代诊断",
        "最新价": 1.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.2,
        "最高价": 1.25,
        "最低价": 1.16,
        "昨收价": 1.22,
        "总市值": 37308703.0,
        "市盈率": -0.88,
        "成交量": 58808.0,
        "成交额": 70502.0,
        "振幅": 7.38,
        "换手率": 0.19,
        "symbol": "105.CODX"
    },
    {
        "序号": 7275,
        "name": "Envoy Medical Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 3618.0,
        "成交额": 64.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.COCHW"
    },
    {
        "序号": 7276,
        "name": "COR3 & Co (Holdings) Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.COC"
    },
    {
        "序号": 7277,
        "name": "中创100ETF-VanEck Vectors华夏",
        "最新价": 24.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.18,
        "最高价": 24.2,
        "最低价": 24.15,
        "昨收价": 24.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 5124.0,
        "成交额": 123818.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.CNXT"
    },
    {
        "序号": 7278,
        "name": "Canna-Global Acquisition Corp W",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 36.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CNGLW"
    },
    {
        "序号": 7279,
        "name": "Canna-Global Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.83,
        "总市值": 97246577.0,
        "市盈率": -1331.82,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CNGLU"
    },
    {
        "序号": 7280,
        "name": "Canna-Global Acquisition Corp-A",
        "最新价": 10.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.78,
        "昨收价": 10.78,
        "总市值": 96797609.0,
        "市盈率": -1325.67,
        "成交量": 132.0,
        "成交额": 1422.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.CNGL"
    },
    {
        "序号": 7281,
        "name": "Conifer Holdings Inc Notes",
        "最新价": 16.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 16.02,
        "最高价": 16.02,
        "最低价": 16.02,
        "昨收价": 16.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 30.0,
        "成交额": 480.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CNFRZ"
    },
    {
        "序号": 7282,
        "name": "Concord Acquisition Corp III Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CNDB_WS"
    },
    {
        "序号": 7283,
        "name": "Concord Acquisition Corp III Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.56,
        "总市值": 132700772.0,
        "市盈率": 21.19,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CNDB_U"
    },
    {
        "序号": 7284,
        "name": "Concord Acquisition Corp II Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CNDA_WS"
    },
    {
        "序号": 7285,
        "name": "Concord Acquisition Corp II Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.3,
        "总市值": 223525007.0,
        "市盈率": 25.62,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CNDA_U"
    },
    {
        "序号": 7286,
        "name": "Chardan NexTech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CNAQW"
    },
    {
        "序号": 7287,
        "name": "Chardan NexTech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CNAQU"
    },
    {
        "序号": 7288,
        "name": "Chardan NexTech Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CNAQ"
    },
    {
        "序号": 7289,
        "name": "Chimerix Inc",
        "最新价": 0.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.98,
        "最高价": 0.98,
        "最低价": 0.97,
        "昨收价": 0.97,
        "总市值": 86224561.0,
        "市盈率": -1.02,
        "成交量": 80169.0,
        "成交额": 77831.0,
        "振幅": 1.03,
        "换手率": 0.09,
        "symbol": "105.CMRX"
    },
    {
        "序号": 7290,
        "name": "Comera Life Sciences Holdings I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CMRAW"
    },
    {
        "序号": 7291,
        "name": "CompoSecure Inc Wt",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.39,
        "最高价": 0.39,
        "最低价": 0.39,
        "昨收价": 0.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CMPOW"
    },
    {
        "序号": 7292,
        "name": "iShares Bloomberg Roll Select C",
        "最新价": 48.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 48.96,
        "最高价": 49.19,
        "最低价": 48.82,
        "昨收价": 48.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 37940.0,
        "成交额": 1859572.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.CMDY"
    },
    {
        "序号": 7293,
        "name": "达闼科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CMDS"
    },
    {
        "序号": 7294,
        "name": "Creative Media & Community Trus",
        "最新价": 3.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 3.86,
        "最高价": 3.86,
        "最低价": 3.65,
        "昨收价": 3.66,
        "总市值": 83399472.0,
        "市盈率": -2.13,
        "成交量": 13670.0,
        "成交额": 50715.0,
        "振幅": 5.74,
        "换手率": 0.06,
        "symbol": "105.CMCT"
    },
    {
        "序号": 7295,
        "name": "Capitalworks Emerging Markets A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CMCAW"
    },
    {
        "序号": 7296,
        "name": "Capitalworks Emerging Markets A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.82,
        "总市值": 108182656.0,
        "市盈率": 21.75,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CMCAU"
    },
    {
        "序号": 7297,
        "name": "Capitalworks Emerging Markets A",
        "最新价": 10.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.83,
        "最高价": 10.83,
        "最低价": 10.83,
        "昨收价": 10.83,
        "总市值": 108282640.0,
        "市盈率": 21.77,
        "成交量": 96.0,
        "成交额": 1039.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.CMCA"
    },
    {
        "序号": 7298,
        "name": "欧陆科仪",
        "最新价": 1.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.67,
        "最高价": 1.73,
        "最低价": 1.65,
        "昨收价": 1.73,
        "总市值": 13358077.0,
        "市盈率": 36.2,
        "成交量": 1416.0,
        "成交额": 2419.0,
        "振幅": 4.62,
        "换手率": 0.02,
        "symbol": "105.CLWT"
    },
    {
        "序号": 7299,
        "name": "Clever Leaves Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 6.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLVRW"
    },
    {
        "序号": 7300,
        "name": "Catalyst Bancorp Inc",
        "最新价": 10.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.0,
        "最高价": 11.0,
        "最低价": 10.95,
        "昨收价": 10.95,
        "总市值": 52222247.0,
        "市盈率": 115.28,
        "成交量": 374.0,
        "成交额": 4101.0,
        "振幅": 0.46,
        "换手率": 0.01,
        "symbol": "105.CLST"
    },
    {
        "序号": 7301,
        "name": "Cabana Target Leading Sector Mo",
        "最新价": 20.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 20.28,
        "最高价": 20.29,
        "最低价": 20.23,
        "昨收价": 20.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 17917.0,
        "成交额": 362910.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "105.CLSM"
    },
    {
        "序号": 7302,
        "name": "ClimateRock Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CLRCW"
    },
    {
        "序号": 7303,
        "name": "ClimateRock Unit Cons of 1 CL A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.78,
        "总市值": 50278060.0,
        "市盈率": 99.88,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CLRCU"
    },
    {
        "序号": 7304,
        "name": "Clover Leaf Capital Corp Unit C",
        "最新价": 11.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.44,
        "最高价": 11.44,
        "最低价": 11.44,
        "昨收价": 11.44,
        "总市值": 63181610.0,
        "市盈率": -79.93,
        "成交量": 1.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLOEU"
    },
    {
        "序号": 7305,
        "name": "Clover Leaf Capital Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CLOER"
    },
    {
        "序号": 7306,
        "name": "Clover Leaf Capital Corp-A",
        "最新价": 11.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.6,
        "最高价": 11.6,
        "最低价": 11.6,
        "昨收价": 11.6,
        "总市值": 64065269.0,
        "市盈率": -81.04,
        "成交量": 103.0,
        "成交额": 1194.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.CLOE"
    },
    {
        "序号": 7307,
        "name": "Clean Earth Acquisitions Corp W",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLINW"
    },
    {
        "序号": 7308,
        "name": "Cellebrite DI Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 1.34,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CLBTW"
    },
    {
        "序号": 7309,
        "name": "Colombier Acquisition Corp II W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CLBR_WS"
    },
    {
        "序号": 7310,
        "name": "Colombier Acquisition Corp II-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CLBR"
    },
    {
        "序号": 7311,
        "name": "Chavant Capital Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.2,
        "总市值": 31123814.0,
        "市盈率": -16.14,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CLAYU"
    },
    {
        "序号": 7312,
        "name": "CKX Lands Inc",
        "最新价": 12.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.78,
        "最高价": 12.78,
        "最低价": 12.78,
        "昨收价": 12.78,
        "总市值": 25233177.0,
        "市盈率": -23.09,
        "成交量": 106.0,
        "成交额": 1354.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.CKX"
    },
    {
        "序号": 7313,
        "name": "凯德工程",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CKHL"
    },
    {
        "序号": 7314,
        "name": "影高文化",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CJOY"
    },
    {
        "序号": 7315,
        "name": "Cartica Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CITEW"
    },
    {
        "序号": 7316,
        "name": "Cartica Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 107615682.0,
        "市盈率": 11.49,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CITEU"
    },
    {
        "序号": 7317,
        "name": "CISO Global Inc",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 19819412.0,
        "市盈率": -0.25,
        "成交量": 707124.0,
        "成交额": 75348.0,
        "振幅": 9.09,
        "换手率": 0.39,
        "symbol": "105.CISO"
    },
    {
        "序号": 7318,
        "name": "Cingulate Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CINGW"
    },
    {
        "序号": 7319,
        "name": "MFS Intermediate High Income Fu",
        "最新价": 1.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.67,
        "最高价": 1.68,
        "最低价": 1.67,
        "昨收价": 1.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 14466.0,
        "成交额": 24263.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "106.CIF"
    },
    {
        "序号": 7320,
        "name": "Chesapeake Energy Corp Wt",
        "最新价": 69.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 69.25,
        "最高价": 69.25,
        "最低价": 69.25,
        "昨收价": 69.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 277.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CHKEZ"
    },
    {
        "序号": 7321,
        "name": "Chesapeake Energy Corp Wt",
        "最新价": 73.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 73.52,
        "最高价": 73.52,
        "最低价": 73.52,
        "昨收价": 73.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 147.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CHKEW"
    },
    {
        "序号": 7322,
        "name": "Chesapeake Energy Corp Wt",
        "最新价": 60.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 60.26,
        "最高价": 60.26,
        "最低价": 60.26,
        "昨收价": 60.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 120.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CHKEL"
    },
    {
        "序号": 7323,
        "name": "Global X China Mid Cap ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CHIA"
    },
    {
        "序号": 7324,
        "name": "CorpHousing Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CHG_WS"
    },
    {
        "序号": 7325,
        "name": "Chenghe Acquisition Co Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.02,
        "总市值": 124665745.0,
        "市盈率": 50.59,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CHEAU"
    },
    {
        "序号": 7326,
        "name": "Chenghe Acquisition Co-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.95,
        "总市值": 123873857.0,
        "市盈率": 50.27,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CHEA"
    },
    {
        "序号": 7327,
        "name": "康斯托克控股",
        "最新价": 4.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.27,
        "最高价": 4.35,
        "最低价": 4.12,
        "昨收价": 4.12,
        "总市值": 39765243.0,
        "市盈率": 5.51,
        "成交量": 5316.0,
        "成交额": 22071.0,
        "振幅": 5.58,
        "换手率": 0.06,
        "symbol": "105.CHCI"
    },
    {
        "序号": 7328,
        "name": "Global X China Biotech Innovati",
        "最新价": 7.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.93,
        "最高价": 8.0,
        "最低价": 7.93,
        "昨收价": 7.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 2420.0,
        "成交额": 19228.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.CHB"
    },
    {
        "序号": 7329,
        "name": "Catcha Investment Corp-A",
        "最新价": 11.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.09,
        "最高价": 11.09,
        "最低价": 11.09,
        "昨收价": 11.09,
        "总市值": 107737786.0,
        "市盈率": -44.54,
        "成交量": 28155.0,
        "成交额": 312238.0,
        "振幅": 0.0,
        "换手率": 0.29,
        "symbol": "107.CHAA"
    },
    {
        "序号": 7330,
        "name": "创智环球",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CGTH"
    },
    {
        "序号": 7331,
        "name": "CoreValues Alpha Greater China ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 19.8,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CGRO"
    },
    {
        "序号": 7332,
        "name": "GRD生物科技收购",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CGRD"
    },
    {
        "序号": 7333,
        "name": "Calamos Global Total Return Fun",
        "最新价": 9.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.96,
        "最高价": 9.02,
        "最低价": 8.92,
        "昨收价": 9.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 14733.0,
        "成交额": 132507.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "105.CGO"
    },
    {
        "序号": 7334,
        "name": "ContraFect Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.23,
        "总市值": 2409895.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFRX"
    },
    {
        "序号": 7335,
        "name": "CF Acquisition Corp IV Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFIVW"
    },
    {
        "序号": 7336,
        "name": "CF Acquisition Corp IV Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.4,
        "总市值": 218916641.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFIVU"
    },
    {
        "序号": 7337,
        "name": "CF Acquisition Corp IV-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.58,
        "总市值": 222705583.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFIV"
    },
    {
        "序号": 7338,
        "name": "CF Acquisition Corp VII Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 246207.0,
        "成交额": 7406.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CFFSW"
    },
    {
        "序号": 7339,
        "name": "CF Acquisition Corp VII Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.78,
        "总市值": 208227353.0,
        "市盈率": 108.82,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFFSU"
    },
    {
        "序号": 7340,
        "name": "国会联邦金融",
        "最新价": 5.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.79,
        "最高价": 5.88,
        "最低价": 5.76,
        "昨收价": 5.78,
        "总市值": 781706130.0,
        "市盈率": -7.69,
        "成交量": 654932.0,
        "成交额": 3798757.0,
        "振幅": 2.08,
        "换手率": 0.48,
        "symbol": "105.CFFN"
    },
    {
        "序号": 7341,
        "name": "CF Acquisition Corp VIII Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 16.59,
        "总市值": 124363932.0,
        "市盈率": -22.55,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CFFEU"
    },
    {
        "序号": 7342,
        "name": "新兴市场货币ETF-WisdomTree",
        "最新价": 18.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 17.94,
        "最高价": 18.01,
        "最低价": 17.94,
        "昨收价": 18.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 323347.0,
        "成交额": 5823443.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.CEW"
    },
    {
        "序号": 7343,
        "name": "Cemtrex Inc Series 1 Pfd",
        "最新价": 0.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.81,
        "最高价": 0.85,
        "最低价": 0.81,
        "昨收价": 0.85,
        "总市值": 1992360.0,
        "市盈率": null,
        "成交量": 2108.0,
        "成交额": 1743.0,
        "振幅": 4.71,
        "换手率": 0.09,
        "symbol": "105.CETXP"
    },
    {
        "序号": 7344,
        "name": "Cetus Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CETUW"
    },
    {
        "序号": 7345,
        "name": "Cetus Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 80817019.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CETUU"
    },
    {
        "序号": 7346,
        "name": "Cetus Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CETUR"
    },
    {
        "序号": 7347,
        "name": "Celularity Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2850.0,
        "成交额": 52.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.CELUW"
    },
    {
        "序号": 7348,
        "name": "Celularity Inc-A",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.2,
        "最高价": 0.21,
        "最低价": 0.2,
        "昨收价": 0.21,
        "总市值": 40099120.0,
        "市盈率": -0.48,
        "成交量": 178770.0,
        "成交额": 36572.0,
        "振幅": 4.76,
        "换手率": 0.09,
        "symbol": "105.CELU"
    },
    {
        "序号": 7349,
        "name": "CEA Industries Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 14640.0,
        "成交额": 229.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CEADW"
    },
    {
        "序号": 7350,
        "name": "Cidara Therapeutics Inc",
        "最新价": 0.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.74,
        "最高价": 0.75,
        "最低价": 0.72,
        "昨收价": 0.74,
        "总市值": 66920995.0,
        "市盈率": -2.18,
        "成交量": 413978.0,
        "成交额": 306307.0,
        "振幅": 4.05,
        "换手率": 0.46,
        "symbol": "105.CDTX"
    },
    {
        "序号": 7351,
        "name": "Conduit Pharmaceuticals Inc Wt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 1977.0,
        "成交额": 175.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CDTTW"
    },
    {
        "序号": 7352,
        "name": "城道通环保科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CDTG"
    },
    {
        "序号": 7353,
        "name": "Codere Online Luxembourg SA Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CDROW"
    },
    {
        "序号": 7354,
        "name": "Cadeler AS ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CDLRw"
    },
    {
        "序号": 7355,
        "name": "Compass Digital Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CDAQW"
    },
    {
        "序号": 7356,
        "name": "Compass Digital Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.64,
        "总市值": 111770540.0,
        "市盈率": 16.04,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CDAQU"
    },
    {
        "序号": 7357,
        "name": "秦淮数据",
        "最新价": 8.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 8.46,
        "最高价": 8.54,
        "最低价": 8.38,
        "昨收价": 8.49,
        "总市值": 3112436572.0,
        "市盈率": 27.04,
        "成交量": 1824568.0,
        "成交额": 15433659.0,
        "振幅": 1.88,
        "换手率": 0.5,
        "symbol": "105.CD"
    },
    {
        "序号": 7358,
        "name": "Churchill Capital Corp VI Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CCVI_WS"
    },
    {
        "序号": 7359,
        "name": "Churchill Capital Corp VI Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.48,
        "总市值": 432925729.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CCVI_U"
    },
    {
        "序号": 7360,
        "name": "Churchill Capital Corp VI-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.49,
        "总市值": 433338826.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CCVI"
    },
    {
        "序号": 7361,
        "name": "Cactus Acquisition Corp 1 Ltd W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CCTSW"
    },
    {
        "序号": 7362,
        "name": "CCSC Technology International H",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CCTG"
    },
    {
        "序号": 7363,
        "name": "Chase Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 127.49,
        "总市值": 1214055270.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.CCF"
    },
    {
        "序号": 7364,
        "name": "Chain Bridge I Unit Cons of 1 C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 105820709.0,
        "市盈率": 10.9,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CBRGU"
    },
    {
        "序号": 7365,
        "name": "FreeCast Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CAST"
    },
    {
        "序号": 7366,
        "name": "Captivision Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CAPTW"
    },
    {
        "序号": 7367,
        "name": "Alpha Architect Tail Risk ETF",
        "最新价": 82.96,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 82.94,
        "最高价": 82.96,
        "最低价": 82.94,
        "昨收价": 82.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 816.0,
        "成交额": 67682.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.CAOS"
    },
    {
        "序号": 7368,
        "name": "Cano Health Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.CANO_WS"
    },
    {
        "序号": 7369,
        "name": "CalAmp Corp",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.31,
        "最高价": 0.32,
        "最低价": 0.3,
        "昨收价": 0.32,
        "总市值": 12129162.0,
        "市盈率": -0.58,
        "成交量": 135763.0,
        "成交额": 42430.0,
        "振幅": 6.25,
        "换手率": 0.36,
        "symbol": "105.CAMP"
    },
    {
        "序号": 7370,
        "name": "BlackRock Short-Term California",
        "最新价": 50.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 50.22,
        "最高价": 50.25,
        "最低价": 50.22,
        "昨收价": 50.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 212.0,
        "成交额": 10646.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "105.CALY"
    },
    {
        "序号": 7371,
        "name": "Callodine Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CALQW"
    },
    {
        "序号": 7372,
        "name": "Callodine Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.0,
        "总市值": 312500000.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CALQU"
    },
    {
        "序号": 7373,
        "name": "Callodine Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.CALQ"
    },
    {
        "序号": 7374,
        "name": "Candel Therapeutics Inc",
        "最新价": 0.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.86,
        "最高价": 0.9,
        "最低价": 0.86,
        "昨收价": 0.9,
        "总市值": 26027829.0,
        "市盈率": -0.82,
        "成交量": 2543.0,
        "成交额": 2230.0,
        "振幅": 4.44,
        "换手率": 0.01,
        "symbol": "105.CADL"
    },
    {
        "序号": 7375,
        "name": "博智",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BZFL"
    },
    {
        "序号": 7376,
        "name": "万春医药",
        "最新价": 0.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.86,
        "最高价": 0.91,
        "最低价": 0.86,
        "昨收价": 0.88,
        "总市值": 34253197.0,
        "市盈率": -1.03,
        "成交量": 7911.0,
        "成交额": 7072.0,
        "振幅": 5.68,
        "换手率": 0.02,
        "symbol": "105.BYSI"
    },
    {
        "序号": 7377,
        "name": "Banyan Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BYN_WS"
    },
    {
        "序号": 7378,
        "name": "Banyan Acquisition Corp Unit Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.72,
        "总市值": 120532325.0,
        "市盈率": -43.33,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BYN_U"
    },
    {
        "序号": 7379,
        "name": "byNordic Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BYNOW"
    },
    {
        "序号": 7380,
        "name": "byNordic Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.94,
        "总市值": 112422416.0,
        "市盈率": 25.29,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BYNOU"
    },
    {
        "序号": 7381,
        "name": "byNordic Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.87,
        "总市值": 111703077.0,
        "市盈率": 25.13,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BYNO"
    },
    {
        "序号": 7382,
        "name": "Banyan Acquisition Corp-A",
        "最新价": 10.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.72,
        "最高价": 10.72,
        "最低价": 10.72,
        "昨收价": 10.72,
        "总市值": 120532325.0,
        "市盈率": -43.33,
        "成交量": 603.0,
        "成交额": 6464.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.BYN"
    },
    {
        "序号": 7383,
        "name": "BlackRock Municipal Income Qual",
        "最新价": 11.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.1,
        "最高价": 11.19,
        "最低价": 11.1,
        "昨收价": 11.12,
        "总市值": 293637756.0,
        "市盈率": null,
        "成交量": 89758.0,
        "成交额": 999022.0,
        "振幅": 0.81,
        "换手率": 0.34,
        "symbol": "106.BYM"
    },
    {
        "序号": 7384,
        "name": "Blue Water Biotech Inc",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.26,
        "最高价": 0.27,
        "最低价": 0.24,
        "昨收价": 0.25,
        "总市值": 4663853.0,
        "市盈率": -0.26,
        "成交量": 120706.0,
        "成交额": 30731.0,
        "振幅": 12.0,
        "换手率": 0.65,
        "symbol": "105.BWV"
    },
    {
        "序号": 7385,
        "name": "Brendan Wood TopGun ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.84,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BWTG"
    },
    {
        "序号": 7386,
        "name": "Betterware de México SAPI de C",
        "最新价": 14.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.01,
        "最高价": 14.25,
        "最低价": 13.96,
        "昨收价": 14.1,
        "总市值": 526163299.0,
        "市盈率": 9.92,
        "成交量": 28133.0,
        "成交额": 397160.0,
        "振幅": 2.06,
        "换手率": 0.08,
        "symbol": "105.BWMX"
    },
    {
        "序号": 7387,
        "name": "BrandywineGLOBAL Global Income ",
        "最新价": 8.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.97,
        "最高价": 8.0,
        "最低价": 7.96,
        "昨收价": 8.0,
        "总市值": 168614032.0,
        "市盈率": null,
        "成交量": 86246.0,
        "成交额": 688007.0,
        "振幅": 0.5,
        "换手率": 0.41,
        "symbol": "106.BWG"
    },
    {
        "序号": 7388,
        "name": "Blue World Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.72,
        "总市值": 70776077.0,
        "市盈率": 30.32,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BWAQU"
    },
    {
        "序号": 7389,
        "name": "Blue World Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BWAQR"
    },
    {
        "序号": 7390,
        "name": "Blue World Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.03,
        "总市值": 72822773.0,
        "市盈率": 31.2,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BWAQ"
    },
    {
        "序号": 7391,
        "name": "Nuburu Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 700.0,
        "成交额": 10.0,
        "振幅": 100.0,
        "换手率": null,
        "symbol": "107.BURU_WS"
    },
    {
        "序号": 7392,
        "name": "Bukit Jalil Global Acquisition ",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.07,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 40.0,
        "成交额": 2.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BUJAW"
    },
    {
        "序号": 7393,
        "name": "Bukit Jalil Global Acquisition ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.5,
        "总市值": 81498974.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BUJAU"
    },
    {
        "序号": 7394,
        "name": "Bukit Jalil Global Acquisition ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BUJAR"
    },
    {
        "序号": 7395,
        "name": "Bukit Jalil Global Acquisition ",
        "最新价": 10.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.35,
        "最高价": 10.35,
        "最低价": 10.35,
        "昨收价": 10.35,
        "总市值": 80334702.0,
        "市盈率": null,
        "成交量": 81.0,
        "成交额": 838.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.BUJA"
    },
    {
        "序号": 7396,
        "name": "BlackRock Utilities, Infrastruc",
        "最新价": 20.61,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 20.65,
        "最高价": 20.76,
        "最低价": 20.59,
        "昨收价": 20.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 48143.0,
        "成交额": 994310.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.BUI"
    },
    {
        "序号": 7397,
        "name": "Simplify Stable Income ETF",
        "最新价": 24.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.95,
        "最高价": 24.95,
        "最低价": 24.91,
        "昨收价": 24.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 9906.0,
        "成交额": 246835.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.BUCK"
    },
    {
        "序号": 7398,
        "name": "Clarios International Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BTRY"
    },
    {
        "序号": 7399,
        "name": "Beacon Tactical Risk ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.06,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BTR"
    },
    {
        "序号": 7400,
        "name": "Armlogi Holding Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BTOC"
    },
    {
        "序号": 7401,
        "name": "BTC Digital Ltd Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.02,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 36699.0,
        "成交额": 1219.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.BTCTW"
    },
    {
        "序号": 7402,
        "name": "Beacon Selective Risk ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BSR"
    },
    {
        "序号": 7403,
        "name": "BSQUARE科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 1.89,
        "总市值": 37555296.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BSQR"
    },
    {
        "序号": 7404,
        "name": "Invesco BulletShares 2026 Munic",
        "最新价": 23.61,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.59,
        "最高价": 23.61,
        "最低价": 23.57,
        "昨收价": 23.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 39016.0,
        "成交额": 920588.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "105.BSMQ"
    },
    {
        "序号": 7405,
        "name": "Invesco BulletShares 2023 Munic",
        "最新价": 25.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.05,
        "最高价": 25.05,
        "最低价": 25.04,
        "昨收价": 25.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 28257.0,
        "成交额": 707724.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.BSMN"
    },
    {
        "序号": 7406,
        "name": "贝斯曼",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BSME"
    },
    {
        "序号": 7407,
        "name": "Invesco BulletShares 2025 High ",
        "最新价": 22.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.76,
        "最高价": 22.8,
        "最低价": 22.71,
        "昨收价": 22.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 167311.0,
        "成交额": 3805270.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "105.BSJP"
    },
    {
        "序号": 7408,
        "name": "Invesco BulletShares 2024 High ",
        "最新价": 22.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.68,
        "最高价": 22.68,
        "最低价": 22.64,
        "昨收价": 22.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 191327.0,
        "成交额": 4336471.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.BSJO"
    },
    {
        "序号": 7409,
        "name": "Bogota Financial Corp",
        "最新价": 6.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.85,
        "最高价": 6.9,
        "最低价": 6.85,
        "昨收价": 6.9,
        "总市值": 91958135.0,
        "市盈率": 24.7,
        "成交量": 7026.0,
        "成交额": 48442.0,
        "振幅": 0.72,
        "换手率": 0.05,
        "symbol": "105.BSBK"
    },
    {
        "序号": 7410,
        "name": "Bruush Oral Care Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BRSHW"
    },
    {
        "序号": 7411,
        "name": "Brooge Energy Ltd Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 709.0,
        "成交额": 16.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BROGW"
    },
    {
        "序号": 7412,
        "name": "Brooge Energy Ltd",
        "最新价": 4.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.65,
        "最高价": 4.65,
        "最低价": 4.56,
        "昨收价": 4.56,
        "总市值": 499720614.0,
        "市盈率": 8.23,
        "成交量": 1585.0,
        "成交额": 7304.0,
        "振幅": 1.97,
        "换手率": 0.0,
        "symbol": "105.BROG"
    },
    {
        "序号": 7413,
        "name": "BurTech Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.63,
        "总市值": 185469083.0,
        "市盈率": 68.89,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BRKHU"
    },
    {
        "序号": 7414,
        "name": "BurTech Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.66,
        "总市值": 185992514.0,
        "市盈率": 69.08,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BRKH"
    },
    {
        "序号": 7415,
        "name": "巴西食品",
        "最新价": 2.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.74,
        "最高价": 2.81,
        "最低价": 2.72,
        "昨收价": 2.78,
        "总市值": 4666663866.0,
        "市盈率": -6.24,
        "成交量": 2504219.0,
        "成交额": 6946116.0,
        "振幅": 3.24,
        "换手率": 0.15,
        "symbol": "106.BRFS"
    },
    {
        "序号": 7416,
        "name": "Breeze Holdings Acquisition Cor",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BREZR"
    },
    {
        "序号": 7417,
        "name": "Beard Energy Transition Acquisi",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BRD_WS"
    },
    {
        "序号": 7418,
        "name": "Beard Energy Transition Acquisi",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.55,
        "总市值": 148759426.0,
        "市盈率": 31.71,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BRD_U"
    },
    {
        "序号": 7419,
        "name": "Broad Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.07,
        "总市值": 83174213.0,
        "市盈率": -114.08,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BRACU"
    },
    {
        "序号": 7420,
        "name": "Broad Capital Acquisition Corp ",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 49584.0,
        "成交额": 4962.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BRACR"
    },
    {
        "序号": 7421,
        "name": "Broad Capital Acquisition Corp",
        "最新价": 10.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.98,
        "最高价": 10.98,
        "最低价": 10.98,
        "昨收价": 10.98,
        "总市值": 82497999.0,
        "市盈率": -113.15,
        "成交量": 50033.0,
        "成交额": 549362.0,
        "振幅": 0.0,
        "换手率": 0.67,
        "symbol": "105.BRAC"
    },
    {
        "序号": 7422,
        "name": "Biophytis SA ADR",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.6,
        "最高价": 0.72,
        "最低价": 0.6,
        "昨收价": 0.7,
        "总市值": 3760251.0,
        "市盈率": -0.18,
        "成交量": 8089.0,
        "成交额": 5344.0,
        "振幅": 17.14,
        "换手率": 0.15,
        "symbol": "105.BPTS"
    },
    {
        "序号": 7423,
        "name": "Boxlight Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BOXLW"
    },
    {
        "序号": 7424,
        "name": "Bowen Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.37,
        "总市值": 95056605.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BOWNU"
    },
    {
        "序号": 7425,
        "name": "Bowen Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BOWNR"
    },
    {
        "序号": 7426,
        "name": "Boustead Wavefront Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BOUW"
    },
    {
        "序号": 7427,
        "name": "Global X Funds Global X Founder",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.75,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BOSS"
    },
    {
        "序号": 7428,
        "name": "Blue Ocean Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 392914.0,
        "成交额": 3929.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BOCNW"
    },
    {
        "序号": 7429,
        "name": "Blue Ocean Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 254739375.0,
        "市盈率": 46.58,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BOCNU"
    },
    {
        "序号": 7430,
        "name": "BlackRock New York Municipal In",
        "最新价": 10.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.21,
        "最高价": 10.27,
        "最低价": 10.15,
        "昨收价": 10.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 78146.0,
        "成交额": 796037.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "106.BNY"
    },
    {
        "序号": 7431,
        "name": "Bannix Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 34774.0,
        "成交额": 425.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BNIXW"
    },
    {
        "序号": 7432,
        "name": "Bannix Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.26,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BNIXR"
    },
    {
        "序号": 7433,
        "name": "Bannix Acquisition Corp",
        "最新价": 10.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.67,
        "最高价": 10.67,
        "最低价": 10.67,
        "昨收价": 10.67,
        "总市值": 58296751.0,
        "市盈率": 891.62,
        "成交量": 20001.0,
        "成交额": 213410.0,
        "振幅": 0.0,
        "换手率": 0.37,
        "symbol": "105.BNIX"
    },
    {
        "序号": 7434,
        "name": "Black Mountain Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BMAC_WS"
    },
    {
        "序号": 7435,
        "name": "Black Mountain Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 125538196.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BMAC_U"
    },
    {
        "序号": 7436,
        "name": "Black Mountain Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.6,
        "总市值": 125538196.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BMAC"
    },
    {
        "序号": 7437,
        "name": "BlackRock Limited Duration Inco",
        "最新价": 13.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 13.25,
        "最高价": 13.26,
        "最低价": 13.16,
        "昨收价": 13.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 74519.0,
        "成交额": 984202.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "106.BLW"
    },
    {
        "序号": 7438,
        "name": "BlueRiver Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BLUA_WS"
    },
    {
        "序号": 7439,
        "name": "BlueRiver Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.56,
        "总市值": 104126120.0,
        "市盈率": -69.43,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BLUA_U"
    },
    {
        "序号": 7440,
        "name": "bleuacacia ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BLEUW"
    },
    {
        "序号": 7441,
        "name": "bleuacacia ltd Unit Cons of 1 C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.74,
        "总市值": 91118686.0,
        "市盈率": 14.22,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BLEUU"
    },
    {
        "序号": 7442,
        "name": "bleuacacia ltd Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BLEUR"
    },
    {
        "序号": 7443,
        "name": "Bellevue Life Sciences Acquisit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BLACW"
    },
    {
        "序号": 7444,
        "name": "Bellevue Life Sciences Acquisit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BLACR"
    },
    {
        "序号": 7445,
        "name": "Bellevue Life Sciences Acquisit",
        "最新价": 10.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.46,
        "最高价": 10.46,
        "最低价": 10.46,
        "昨收价": 10.46,
        "总市值": 94715300.0,
        "市盈率": 199.11,
        "成交量": 2.0,
        "成交额": 20.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.BLAC"
    },
    {
        "序号": 7446,
        "name": "汉堡王",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BKW"
    },
    {
        "序号": 7447,
        "name": "BNY Mellon Sustainable US Equit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 46.55,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BKUS"
    },
    {
        "序号": 7448,
        "name": "BlackSky Technology Inc-A",
        "最新价": 1.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.34,
        "最高价": 1.35,
        "最低价": 1.31,
        "昨收价": 1.34,
        "总市值": 191603254.0,
        "市盈率": -2.95,
        "成交量": 609527.0,
        "成交额": 813814.0,
        "振幅": 2.99,
        "换手率": 0.43,
        "symbol": "106.BKSY"
    },
    {
        "序号": 7449,
        "name": "佰康生物",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BKSW"
    },
    {
        "序号": 7450,
        "name": "BNY Mellon Innovators ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 26.35,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BKIV"
    },
    {
        "序号": 7451,
        "name": "BNY Mellon Sustainable Global E",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 38.73,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BKES"
    },
    {
        "序号": 7452,
        "name": "BioVie Inc-A",
        "最新价": 1.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.73,
        "最高价": 1.79,
        "最低价": 1.7,
        "昨收价": 1.74,
        "总市值": 65672289.0,
        "市盈率": -1.3,
        "成交量": 448866.0,
        "成交额": 785820.0,
        "振幅": 5.17,
        "换手率": 1.19,
        "symbol": "105.BIVI"
    },
    {
        "序号": 7453,
        "name": "Bite Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.55,
        "总市值": 89866778.0,
        "市盈率": -137.98,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BITE"
    },
    {
        "序号": 7454,
        "name": "SPDR Bloomberg 3-12 Month T-Bil",
        "最新价": 99.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 99.2,
        "最高价": 99.21,
        "最低价": 99.2,
        "昨收价": 99.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 273059.0,
        "成交额": 27088718.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.BILS"
    },
    {
        "序号": 7455,
        "name": "苏富比",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BID"
    },
    {
        "序号": 7456,
        "name": "bioAffinity Technologies Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BIAFW"
    },
    {
        "序号": 7457,
        "name": "Benson Hill Inc Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 800.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.BHIL_WS"
    },
    {
        "序号": 7458,
        "name": "Crixus BH3 Acquisition Co Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 97.0,
        "成交额": 3.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BHACW"
    },
    {
        "序号": 7459,
        "name": "Crixus BH3 Acquisition Co Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.44,
        "总市值": 84167583.0,
        "市盈率": 29.04,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BHACU"
    },
    {
        "序号": 7460,
        "name": "Crixus BH3 Acquisition Co-A",
        "最新价": 10.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.31,
        "最高价": 10.35,
        "最低价": 10.31,
        "昨收价": 10.35,
        "总市值": 83442000.0,
        "市盈率": 28.79,
        "成交量": 30892.0,
        "成交额": 319728.0,
        "振幅": 0.39,
        "换手率": 0.38,
        "symbol": "105.BHAC"
    },
    {
        "序号": 7461,
        "name": "Bright Green Corp",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.27,
        "最高价": 0.28,
        "最低价": 0.27,
        "昨收价": 0.28,
        "总市值": 51487469.0,
        "市盈率": -5.38,
        "成交量": 150231.0,
        "成交额": 41777.0,
        "振幅": 3.57,
        "换手率": 0.08,
        "symbol": "105.BGXX"
    },
    {
        "序号": 7462,
        "name": "BlackRock Floating Rate Income ",
        "最新价": 12.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 12.19,
        "最高价": 12.24,
        "最低价": 12.14,
        "昨收价": 12.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 48046.0,
        "成交额": 585418.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.BGT"
    },
    {
        "序号": 7463,
        "name": "Blackstone Strategic Credit 202",
        "最新价": 11.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.06,
        "最高价": 11.13,
        "最低价": 11.04,
        "昨收价": 11.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 140481.0,
        "成交额": 1560149.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "106.BGB"
    },
    {
        "序号": 7464,
        "name": "Business First Bancshares Inc",
        "最新价": 21.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 21.83,
        "最高价": 22.22,
        "最低价": 21.78,
        "昨收价": 21.86,
        "总市值": 554023512.0,
        "市盈率": 7.57,
        "成交量": 44335.0,
        "成交额": 972585.0,
        "振幅": 2.01,
        "换手率": 0.17,
        "symbol": "105.BFST"
    },
    {
        "序号": 7465,
        "name": "Biofrontera Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BFRIW"
    },
    {
        "序号": 7466,
        "name": "Bullfrog AI Holdings Inc Wt",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.61,
        "最高价": 0.61,
        "最低价": 0.6,
        "昨收价": 0.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 7033.0,
        "成交额": 4221.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "105.BFRGW"
    },
    {
        "序号": 7467,
        "name": "Butterfly Network Inc Wt",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.BFLY_WS"
    },
    {
        "序号": 7468,
        "name": "BankFlorida",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BFL"
    },
    {
        "序号": 7469,
        "name": "BurgerFi International Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2111.0,
        "成交额": 95.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BFIIW"
    },
    {
        "序号": 7470,
        "name": "Battery Future Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 217471267.0,
        "市盈率": 24.2,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.BFAC_U"
    },
    {
        "序号": 7471,
        "name": "Better Home & Finance Holding C",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.08,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 27639.0,
        "成交额": 2696.0,
        "振幅": 27.27,
        "换手率": null,
        "symbol": "105.BETRW"
    },
    {
        "序号": 7472,
        "name": "Better Home & Finance Holding C",
        "最新价": 0.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.43,
        "最高价": 0.45,
        "最低价": 0.43,
        "昨收价": 0.43,
        "总市值": 317161738.0,
        "市盈率": 1.99,
        "成交量": 930042.0,
        "成交额": 410168.0,
        "振幅": 4.65,
        "换手率": 0.13,
        "symbol": "105.BETR"
    },
    {
        "序号": 7473,
        "name": "Beneficient Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 89597.0,
        "成交额": 635.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BENFW"
    },
    {
        "序号": 7474,
        "name": "Bel Fuse Inc-A",
        "最新价": 54.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 54.05,
        "最高价": 54.05,
        "最低价": 54.05,
        "昨收价": 54.05,
        "总市值": 690345463.0,
        "市盈率": 9.1,
        "成交量": 790.0,
        "成交额": 42699.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.BELFA"
    },
    {
        "序号": 7475,
        "name": "Aberdeen Standard Bloomberg Ene",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BEI"
    },
    {
        "序号": 7476,
        "name": "HeartBeam Inc Wt",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.17,
        "最高价": 0.17,
        "最低价": 0.17,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BEATW"
    },
    {
        "序号": 7477,
        "name": "筑梦之星",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BDS"
    },
    {
        "序号": 7478,
        "name": "Bancroft Fund Ltd Series A Pfd",
        "最新价": 22.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.45,
        "最高价": 22.45,
        "最低价": 22.45,
        "昨收价": 22.45,
        "总市值": 26859472.0,
        "市盈率": null,
        "成交量": 115.0,
        "成交额": 2581.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.BCV_A"
    },
    {
        "序号": 7479,
        "name": "Blockchain Coinvestors Acquisit",
        "最新价": 11.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.19,
        "最高价": 11.19,
        "最低价": 11.19,
        "昨收价": 11.19,
        "总市值": 150324155.0,
        "市盈率": 86.53,
        "成交量": 1.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BCSAU"
    },
    {
        "序号": 7480,
        "name": "Blockchain Coinvestors Acquisit",
        "最新价": 10.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.85,
        "昨收价": 10.85,
        "总市值": 145756665.0,
        "市盈率": 83.9,
        "成交量": 177.0,
        "成交额": 1920.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.BCSA"
    },
    {
        "序号": 7481,
        "name": "BCGF Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BCGFW"
    },
    {
        "序号": 7482,
        "name": "BCGF Acquisition Corp Unit Cons",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BCGFU"
    },
    {
        "序号": 7483,
        "name": "BCGF Acquisition Corp-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BCGF"
    },
    {
        "序号": 7484,
        "name": "BioCardia Inc Wt",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 299.0,
        "成交额": 17.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BCDAW"
    },
    {
        "序号": 7485,
        "name": "Bone Biologics Corp Wt",
        "最新价": 0.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.89,
        "最高价": 0.89,
        "最低价": 0.89,
        "昨收价": 0.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 2.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BBLGW"
    },
    {
        "序号": 7486,
        "name": "比斯利广播",
        "最新价": 0.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.79,
        "最高价": 0.79,
        "最低价": 0.78,
        "昨收价": 0.78,
        "总市值": 23373567.0,
        "市盈率": -0.22,
        "成交量": 9075.0,
        "成交额": 7126.0,
        "振幅": 1.28,
        "换手率": 0.03,
        "symbol": "105.BBGI"
    },
    {
        "序号": 7487,
        "name": "宝露泰奇",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BBCH"
    },
    {
        "序号": 7488,
        "name": "Bayview Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BAYAU"
    },
    {
        "序号": 7489,
        "name": "Bayview Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BAYAR"
    },
    {
        "序号": 7490,
        "name": "Bayview Acquisition Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BAYA"
    },
    {
        "序号": 7491,
        "name": "Banc of California Inc Series F",
        "最新价": 21.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 21.3,
        "最高价": 21.45,
        "最低价": 21.3,
        "昨收价": 21.42,
        "总市值": 10993815.0,
        "市盈率": null,
        "成交量": 59363.0,
        "成交额": 1268731.0,
        "振幅": 0.7,
        "换手率": 11.57,
        "symbol": "106.BANC_F"
    },
    {
        "序号": 7492,
        "name": "Bridger Aerospace Group Holding",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BAERW"
    },
    {
        "序号": 7493,
        "name": "B.A.D. ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.91,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.BAD"
    },
    {
        "序号": 7494,
        "name": "PishPosh Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 5.0,
        "总市值": 33529815.0,
        "市盈率": -8.38,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.BABY"
    },
    {
        "序号": 7495,
        "name": "中驰车福",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AZI"
    },
    {
        "序号": 7496,
        "name": "AllianzIM U.S. Large Cap Buffer",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AZCJ"
    },
    {
        "序号": 7497,
        "name": "A2Z Smart Technologies Corp",
        "最新价": 1.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.26,
        "最高价": 1.26,
        "最低价": 1.19,
        "昨收价": 1.2,
        "总市值": 44357989.0,
        "市盈率": -2.27,
        "成交量": 33910.0,
        "成交额": 41109.0,
        "振幅": 5.83,
        "换手率": 0.09,
        "symbol": "105.AZ"
    },
    {
        "序号": 7498,
        "name": "Aytu BioPharma Inc Series I Pfd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AYTUP"
    },
    {
        "序号": 7499,
        "name": "Avalon Holdings Corp-A",
        "最新价": 2.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.11,
        "最高价": 2.11,
        "最低价": 2.11,
        "昨收价": 2.11,
        "总市值": 8227799.0,
        "市盈率": -4.29,
        "成交量": 280.0,
        "成交额": 590.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "107.AWX"
    },
    {
        "序号": 7500,
        "name": "AERWINS Technologies Inc",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.14,
        "最高价": 0.14,
        "最低价": 0.12,
        "昨收价": 0.13,
        "总市值": 8149468.0,
        "市盈率": -0.9,
        "成交量": 389713.0,
        "成交额": 49548.0,
        "振幅": 15.38,
        "换手率": 0.62,
        "symbol": "105.AWIN"
    },
    {
        "序号": 7501,
        "name": "Avantax Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.99,
        "总市值": 956738266.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AVTA"
    },
    {
        "序号": 7502,
        "name": "Achari Ventures Holdings Corp I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.81,
        "总市值": 33925250.0,
        "市盈率": -15.1,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AVHIU"
    },
    {
        "序号": 7503,
        "name": "黄金矿业",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.51,
        "最高价": 0.51,
        "最低价": 0.49,
        "昨收价": 0.5,
        "总市值": 7042340.0,
        "市盈率": -0.59,
        "成交量": 131267.0,
        "成交额": 65693.0,
        "振幅": 4.0,
        "换手率": 0.93,
        "symbol": "107.AUMN"
    },
    {
        "序号": 7504,
        "name": "Astronics Corp",
        "最新价": 15.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 15.07,
        "最高价": 15.32,
        "最低价": 15.01,
        "昨收价": 15.15,
        "总市值": 514198242.0,
        "市盈率": -12.8,
        "成交量": 104171.0,
        "成交额": 1576537.0,
        "振幅": 2.05,
        "换手率": 0.31,
        "symbol": "105.ATRO"
    },
    {
        "序号": 7505,
        "name": "Agape ATP Corp",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.75,
        "最高价": 0.82,
        "最低价": 0.75,
        "昨收价": 0.75,
        "总市值": 57826509.0,
        "市盈率": -31.09,
        "成交量": 148740.0,
        "成交额": 117114.0,
        "振幅": 9.33,
        "换手率": 0.19,
        "symbol": "105.ATPC"
    },
    {
        "序号": 7506,
        "name": "180 Life Sciences Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ATNFW"
    },
    {
        "序号": 7507,
        "name": "180 Life Sciences Corp",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.23,
        "最高价": 0.25,
        "最低价": 0.22,
        "昨收价": 0.24,
        "总市值": 1794109.0,
        "市盈率": -0.04,
        "成交量": 305587.0,
        "成交额": 71027.0,
        "振幅": 12.5,
        "换手率": 4.09,
        "symbol": "105.ATNF"
    },
    {
        "序号": 7508,
        "name": "AlphaVest Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.86,
        "总市值": 99700230.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ATMVU"
    },
    {
        "序号": 7509,
        "name": "AlphaVest Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ATMVR"
    },
    {
        "序号": 7510,
        "name": "AlphaVest Acquisition Corp",
        "最新价": 10.69,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.69,
        "最高价": 10.69,
        "最低价": 10.69,
        "昨收价": 10.69,
        "总市值": 98139545.0,
        "市盈率": null,
        "成交量": 9189.0,
        "成交额": 98230.0,
        "振幅": 0.0,
        "换手率": 0.1,
        "symbol": "105.ATMV"
    },
    {
        "序号": 7511,
        "name": "AlphaTime Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.85,
        "总市值": 98021070.0,
        "市盈率": 57.25,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ATMCU"
    },
    {
        "序号": 7512,
        "name": "ATI Physical Therapy Inc-A",
        "最新价": 7.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 7.09,
        "最高价": 7.09,
        "最低价": 7.09,
        "昨收价": 7.09,
        "总市值": 29843689.0,
        "市盈率": -0.18,
        "成交量": 633.0,
        "成交额": 4487.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "106.ATIP"
    },
    {
        "序号": 7513,
        "name": "Alger 35 ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 16.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ATFV"
    },
    {
        "序号": 7514,
        "name": "Anfield Tactical Fixed Income E",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ATFI"
    },
    {
        "序号": 7515,
        "name": "Athena Technology Acquisition C",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 180.0,
        "成交额": 5.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ATEK_WS"
    },
    {
        "序号": 7516,
        "name": "Athena Technology Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.8,
        "总市值": 129956821.0,
        "市盈率": 52.15,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ATEK_U"
    },
    {
        "序号": 7517,
        "name": "Atlas Corp Series D Pfd",
        "最新价": 23.71,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 23.62,
        "最高价": 23.71,
        "最低价": 23.6,
        "昨收价": 23.71,
        "总市值": 120772291.0,
        "市盈率": null,
        "成交量": 3591.0,
        "成交额": 85036.0,
        "振幅": 0.46,
        "换手率": 0.07,
        "symbol": "106.ATCO_D"
    },
    {
        "序号": 7518,
        "name": "Aurora Technology Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ATAKW"
    },
    {
        "序号": 7519,
        "name": "Aurora Technology Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.17,
        "总市值": 119075462.0,
        "市盈率": 81.32,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ATAKU"
    },
    {
        "序号": 7520,
        "name": "Aurora Technology Acquisition C",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.17,
        "最高价": 0.17,
        "最低价": 0.15,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 10116.0,
        "成交额": 1718.0,
        "振幅": 11.76,
        "换手率": null,
        "symbol": "105.ATAKR"
    },
    {
        "序号": 7521,
        "name": "Asensus Surgical Inc",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.26,
        "最高价": 0.27,
        "最低价": 0.25,
        "昨收价": 0.26,
        "总市值": 68722355.0,
        "市盈率": -0.87,
        "成交量": 759386.0,
        "成交额": 198576.0,
        "振幅": 7.69,
        "换手率": 0.29,
        "symbol": "107.ASXC"
    },
    {
        "序号": 7522,
        "name": "Abri SPAC 2 Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASPPW"
    },
    {
        "序号": 7523,
        "name": "Abri SPAC 2 Inc Unit Cons of 1 ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.0,
        "总市值": 129500000.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASPPU"
    },
    {
        "序号": 7524,
        "name": "Abri SPAC 2 Inc Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASPPR"
    },
    {
        "序号": 7525,
        "name": "Abri SPAC 2 Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASPP"
    },
    {
        "序号": 7526,
        "name": "Xtrackers MSCI China A Inclusio",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 18.67,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ASHX"
    },
    {
        "序号": 7527,
        "name": "东南亚ETF-Global X",
        "最新价": 14.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.53,
        "最高价": 14.57,
        "最低价": 14.5,
        "昨收价": 14.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 2969.0,
        "成交额": 43161.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.ASEA"
    },
    {
        "序号": 7528,
        "name": "A SPAC II Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASCBW"
    },
    {
        "序号": 7529,
        "name": "A SPAC II Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": 79092922.0,
        "市盈率": 11.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASCBU"
    },
    {
        "序号": 7530,
        "name": "A SPAC II Acquisition Corp Rt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 22.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ASCBR"
    },
    {
        "序号": 7531,
        "name": "A SPAC II Acquisition Corp-A",
        "最新价": 10.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.89,
        "最高价": 10.89,
        "最低价": 10.89,
        "昨收价": 10.89,
        "总市值": 79457742.0,
        "市盈率": 11.59,
        "成交量": 26.0,
        "成交额": 283.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ASCB"
    },
    {
        "序号": 7532,
        "name": "A SPAC I Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASCAW"
    },
    {
        "序号": 7533,
        "name": "A SPAC I Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 9.82,
        "总市值": 36593955.0,
        "市盈率": 49.52,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASCAU"
    },
    {
        "序号": 7534,
        "name": "A SPAC I Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ASCAR"
    },
    {
        "序号": 7535,
        "name": "A SPAC I Acquisition Corp-A",
        "最新价": 10.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.89,
        "最高价": 10.89,
        "最低价": 10.89,
        "昨收价": 10.89,
        "总市值": 40581280.0,
        "市盈率": 54.91,
        "成交量": 6.0,
        "成交额": 65.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ASCA"
    },
    {
        "序号": 7536,
        "name": "ARYA Sciences Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.91,
        "总市值": 86487181.0,
        "市盈率": 86.79,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARYD"
    },
    {
        "序号": 7537,
        "name": "Art’s-Way Manufacturing Co Inc",
        "最新价": 2.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.0,
        "最高价": 2.0,
        "最低价": 2.0,
        "昨收价": 2.0,
        "总市值": 10019148.0,
        "市盈率": 15.04,
        "成交量": 54.0,
        "成交额": 108.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ARTW"
    },
    {
        "序号": 7538,
        "name": "Artelo Biosciences Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARTLW"
    },
    {
        "序号": 7539,
        "name": "Artelo Biosciences Inc",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 1.4,
        "最高价": 1.41,
        "最低价": 1.33,
        "昨收价": 1.4,
        "总市值": 4464543.0,
        "市盈率": -0.47,
        "成交量": 17421.0,
        "成交额": 23928.0,
        "振幅": 5.71,
        "换手率": 0.55,
        "symbol": "105.ARTL"
    },
    {
        "序号": 7540,
        "name": "Arrowroot Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.56,
        "总市值": 122847785.0,
        "市盈率": -26.95,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARRWU"
    },
    {
        "序号": 7541,
        "name": "Arqit Quantum Inc Wt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.11,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 16733.0,
        "成交额": 1410.0,
        "振幅": 37.5,
        "换手率": null,
        "symbol": "105.ARQQW"
    },
    {
        "序号": 7542,
        "name": "Armor International Equity Inde",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ARMI"
    },
    {
        "序号": 7543,
        "name": "Arisz Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARIZW"
    },
    {
        "序号": 7544,
        "name": "Arisz Acquisition Corp Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.7,
        "总市值": 55166568.0,
        "市盈率": 51.49,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARIZU"
    },
    {
        "序号": 7545,
        "name": "Arisz Acquisition Corp Rt",
        "最新价": 0.09,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 500.0,
        "成交额": 45.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ARIZR"
    },
    {
        "序号": 7546,
        "name": "Arisz Acquisition Corp",
        "最新价": 10.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.81,
        "最高价": 10.81,
        "最低价": 10.81,
        "昨收价": 10.81,
        "总市值": 55733701.0,
        "市盈率": 52.02,
        "成交量": 1.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ARIZ"
    },
    {
        "序号": 7547,
        "name": "阿尔戈国际控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 29.99,
        "总市值": 1056113355.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ARGO"
    },
    {
        "序号": 7548,
        "name": "Armor Emerging Markets Equity I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AREE"
    },
    {
        "序号": 7549,
        "name": "American Rebel Holdings Inc Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 6147.0,
        "成交额": 37.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AREBW"
    },
    {
        "序号": 7550,
        "name": "Arco Platform Ltd-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 13.98,
        "总市值": 927434626.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ARCE"
    },
    {
        "序号": 7551,
        "name": "精确射线",
        "最新价": 2.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.62,
        "最高价": 2.69,
        "最低价": 2.62,
        "昨收价": 2.65,
        "总市值": 257241285.0,
        "市盈率": -37.83,
        "成交量": 276337.0,
        "成交额": 731212.0,
        "振幅": 2.64,
        "换手率": 0.28,
        "symbol": "105.ARAY"
    },
    {
        "序号": 7552,
        "name": "Aravive Inc",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.13,
        "最高价": 0.14,
        "最低价": 0.13,
        "昨收价": 0.13,
        "总市值": 9563144.0,
        "市盈率": -0.24,
        "成交量": 978631.0,
        "成交额": 128037.0,
        "振幅": 7.69,
        "换手率": 1.33,
        "symbol": "105.ARAV"
    },
    {
        "序号": 7553,
        "name": "Aquaron Acquisition Corp Unit C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.89,
        "总市值": 49583804.0,
        "市盈率": 55.87,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AQUNU"
    },
    {
        "序号": 7554,
        "name": "Aquaron Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.24,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AQUNR"
    },
    {
        "序号": 7555,
        "name": "APx Acquisition Corp I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APXIW"
    },
    {
        "序号": 7556,
        "name": "APx Acquisition Corp I Unit Con",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.9,
        "总市值": 110216658.0,
        "市盈率": 40.43,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APXIU"
    },
    {
        "序号": 7557,
        "name": "APx Acquisition Corp I-A",
        "最新价": 11.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.11,
        "最高价": 11.11,
        "最低价": 11.11,
        "昨收价": 11.11,
        "总市值": 112340098.0,
        "市盈率": 41.21,
        "成交量": 6.0,
        "成交额": 66.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.APXI"
    },
    {
        "序号": 7558,
        "name": "Alpha Partners Technology Merge",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.64,
        "总市值": 240941832.0,
        "市盈率": 23.54,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APTMU"
    },
    {
        "序号": 7559,
        "name": "TrueShares Structured Outcome (",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 29.57,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.APRZ"
    },
    {
        "序号": 7560,
        "name": "蓝围裙",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.99,
        "总市值": 99978912.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APRN"
    },
    {
        "序号": 7561,
        "name": "Innovator ETFs Trust Innovator ",
        "最新价": 24.93,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.87,
        "最高价": 24.94,
        "最低价": 24.87,
        "昨收价": 24.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 3023.0,
        "成交额": 75250.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.APRJ"
    },
    {
        "序号": 7562,
        "name": "Innovator ETFs Trust Innovator ",
        "最新价": 25.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.22,
        "最高价": 25.22,
        "最低价": 25.22,
        "昨收价": 25.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 11.0,
        "成交额": 277.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.APRD"
    },
    {
        "序号": 7563,
        "name": "声网",
        "最新价": 2.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.55,
        "最高价": 2.7,
        "最低价": 2.55,
        "昨收价": 2.64,
        "总市值": 248820000.0,
        "市盈率": -2.08,
        "成交量": 223666.0,
        "成交额": 591226.0,
        "振幅": 5.68,
        "换手率": 0.24,
        "symbol": "105.API"
    },
    {
        "序号": 7564,
        "name": "Apollo Strategic Growth Capital",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.APGB_WS"
    },
    {
        "序号": 7565,
        "name": "Apollo Strategic Growth Capital",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.66,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.APGB_U"
    },
    {
        "序号": 7566,
        "name": "Apollo Strategic Growth Capital",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.58,
        "总市值": 371994048.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.APGB"
    },
    {
        "序号": 7567,
        "name": "AppTech Payments Corp Wt",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.28,
        "最高价": 0.28,
        "最低价": 0.28,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 8862.0,
        "成交额": 2481.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.APCXW"
    },
    {
        "序号": 7568,
        "name": "AP收购(权证)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.APCA_WS"
    },
    {
        "序号": 7569,
        "name": "AP收购(普通单位)",
        "最新价": 11.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.06,
        "最高价": 11.06,
        "最低价": 11.06,
        "昨收价": 11.06,
        "总市值": 172702276.0,
        "市盈率": 45.51,
        "成交量": 2.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.APCA_U"
    },
    {
        "序号": 7570,
        "name": "AP收购",
        "最新价": 11.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.07,
        "最高价": 11.07,
        "最低价": 11.07,
        "昨收价": 11.07,
        "总市值": 172858426.0,
        "市盈率": 45.55,
        "成交量": 12202.0,
        "成交额": 135076.0,
        "振幅": 0.0,
        "换手率": 0.08,
        "symbol": "106.APCA"
    },
    {
        "序号": 7571,
        "name": "StoneBridge Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APACW"
    },
    {
        "序号": 7572,
        "name": "StoneBridge Acquisition Corp Un",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.93,
        "总市值": 81165841.0,
        "市盈率": 46.32,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.APACU"
    },
    {
        "序号": 7573,
        "name": "StoneBridge Acquisition Corp-A",
        "最新价": 11.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.26,
        "最高价": 11.26,
        "最低价": 11.26,
        "昨收价": 11.26,
        "总市值": 83616411.0,
        "市盈率": 47.72,
        "成交量": 6.0,
        "成交额": 67.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.APAC"
    },
    {
        "序号": 7574,
        "name": "iShares Core Growth Allocation ",
        "最新价": 51.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 51.63,
        "最高价": 51.9,
        "最低价": 51.63,
        "昨收价": 51.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 602852.0,
        "成交额": 31209540.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.AOR"
    },
    {
        "序号": 7575,
        "name": "American Oncology Network Inc W",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.25,
        "昨收价": 0.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AONCW"
    },
    {
        "序号": 7576,
        "name": "Arogo Capital Acquisition Corp ",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 103889.0,
        "成交额": 1039.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AOGOW"
    },
    {
        "序号": 7577,
        "name": "Arogo Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.31,
        "总市值": 49197471.0,
        "市盈率": 30.15,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AOGOU"
    },
    {
        "序号": 7578,
        "name": "Arogo Capital Acquisition Corp-",
        "最新价": 10.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.75,
        "最高价": 10.75,
        "最低价": 10.75,
        "昨收价": 10.75,
        "总市值": 46761522.0,
        "市盈率": 28.66,
        "成交量": 8.0,
        "成交额": 86.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.AOGO"
    },
    {
        "序号": 7579,
        "name": "Agriculture & Natural Solutions",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ANSCW"
    },
    {
        "序号": 7580,
        "name": "Agriculture & Natural Solutions",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ANSC"
    },
    {
        "序号": 7581,
        "name": "ETRACS Alerian Midstream Energy",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 51.98,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AMTR"
    },
    {
        "序号": 7582,
        "name": "美国软件",
        "最新价": 10.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.11,
        "最高价": 10.27,
        "最低价": 10.08,
        "昨收价": 10.15,
        "总市值": 346789468.0,
        "市盈率": 31.42,
        "成交量": 281675.0,
        "成交额": 2860469.0,
        "振幅": 1.87,
        "换手率": 0.82,
        "symbol": "105.AMSWA"
    },
    {
        "序号": 7583,
        "name": "阿玛琳",
        "最新价": 0.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.86,
        "最高价": 0.87,
        "最低价": 0.83,
        "昨收价": 0.86,
        "总市值": 351130961.0,
        "市盈率": -6.69,
        "成交量": 1305762.0,
        "成交额": 1102604.0,
        "振幅": 4.65,
        "换手率": 0.32,
        "symbol": "105.AMRN"
    },
    {
        "序号": 7584,
        "name": "AmpliTech Group Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.13,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AMPGW"
    },
    {
        "序号": 7585,
        "name": "UBS AG ETRACS Alerian Midstream",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 40.14,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AMND"
    },
    {
        "序号": 7586,
        "name": "ETRACS Alerian Midstream Energy",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 38.45,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AMNA"
    },
    {
        "序号": 7587,
        "name": "Prospect Energy Holdings Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AMGSW"
    },
    {
        "序号": 7588,
        "name": "Prospect Energy Holdings Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AMGSU"
    },
    {
        "序号": 7589,
        "name": "Prospect Energy Holdings Corp",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AMGS"
    },
    {
        "序号": 7590,
        "name": "Amphitrite Digital Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AMDI"
    },
    {
        "序号": 7591,
        "name": "Ardagh Metal Packaging SA Wt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.08,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.AMBP_WS"
    },
    {
        "序号": 7592,
        "name": "USCF Aluminum Strategy Fund",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 28.34,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ALUM"
    },
    {
        "序号": 7593,
        "name": "Altitude Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 530.0,
        "成交额": 15.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ALTUW"
    },
    {
        "序号": 7594,
        "name": "Altitude Acquisition Corp Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.27,
        "总市值": 90731804.0,
        "市盈率": -22.85,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALTUU"
    },
    {
        "序号": 7595,
        "name": "Alpha Star Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ALSAW"
    },
    {
        "序号": 7596,
        "name": "Alpha Star Acquisition Corp Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 11.2,
        "总市值": 137407234.0,
        "市盈率": 29.84,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALSAU"
    },
    {
        "序号": 7597,
        "name": "Alpha Star Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALSAR"
    },
    {
        "序号": 7598,
        "name": "ALSP Orchid Acquisition Corp I ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALORW"
    },
    {
        "序号": 7599,
        "name": "ALSP Orchid Acquisition Corp I ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.84,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALORU"
    },
    {
        "序号": 7600,
        "name": "ALSP Orchid Acquisition Corp I-",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.88,
        "总市值": 78595238.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALOR"
    },
    {
        "序号": 7601,
        "name": "阿尔凯默斯 WI",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 22.64,
        "总市值": null,
        "市盈率": 17.6,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALKSV"
    },
    {
        "序号": 7602,
        "name": "Alta Global Group Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ALGG"
    },
    {
        "序号": 7603,
        "name": "奥利",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALEH"
    },
    {
        "序号": 7604,
        "name": "Alchemy Investments Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.73,
        "总市值": 160633465.0,
        "市盈率": 83.09,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ALCYU"
    },
    {
        "序号": 7605,
        "name": "Alchemy Investments Acquisition",
        "最新价": 10.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.42,
        "最高价": 10.44,
        "最低价": 10.41,
        "昨收价": 10.42,
        "总市值": 155992610.0,
        "市盈率": 80.69,
        "成交量": 105977.0,
        "成交额": 1104246.0,
        "振幅": 0.29,
        "换手率": 0.71,
        "symbol": "105.ALCY"
    },
    {
        "序号": 7606,
        "name": "Akoya Biosciences Inc",
        "最新价": 4.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 4.38,
        "最高价": 4.53,
        "最低价": 4.29,
        "昨收价": 4.41,
        "总市值": 216425923.0,
        "市盈率": -3.03,
        "成交量": 128158.0,
        "成交额": 566916.0,
        "振幅": 5.44,
        "换手率": 0.26,
        "symbol": "105.AKYA"
    },
    {
        "序号": 7607,
        "name": "安信龙保险",
        "最新价": 167.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 167.94,
        "最高价": 168.3,
        "最低价": 166.99,
        "昨收价": 167.68,
        "总市值": 8818395162.0,
        "市盈率": 16.7,
        "成交量": 264959.0,
        "成交额": 44409497.0,
        "振幅": 0.78,
        "换手率": 0.5,
        "symbol": "106.AIZ"
    },
    {
        "序号": 7608,
        "name": "Meta Data Ltd ADR",
        "最新价": 0.99,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.99,
        "最高价": 1.01,
        "最低价": 0.99,
        "昨收价": 0.99,
        "总市值": 76204420.0,
        "市盈率": -0.18,
        "成交量": 935.0,
        "成交额": 928.0,
        "振幅": 2.02,
        "换手率": 0.0,
        "symbol": "106.AIU"
    },
    {
        "序号": 7609,
        "name": "AI Transportation Acquisition C",
        "最新价": 10.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.21,
        "最高价": 10.21,
        "最低价": 10.21,
        "昨收价": 10.21,
        "总市值": 80023428.0,
        "市盈率": null,
        "成交量": 12.0,
        "成交额": 122.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AITRU"
    },
    {
        "序号": 7610,
        "name": "AI Transportation Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AITRR"
    },
    {
        "序号": 7611,
        "name": "AI Transportation Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AITR"
    },
    {
        "序号": 7612,
        "name": "AirSculpt Technologies Inc",
        "最新价": 5.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 5.91,
        "最高价": 6.1,
        "最低价": 5.65,
        "昨收价": 5.88,
        "总市值": 337251375.0,
        "市盈率": -47.52,
        "成交量": 37991.0,
        "成交额": 221368.0,
        "振幅": 7.65,
        "换手率": 0.07,
        "symbol": "105.AIRS"
    },
    {
        "序号": 7613,
        "name": "Air Industries Group",
        "最新价": 2.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 2.97,
        "最高价": 3.04,
        "最低价": 2.91,
        "昨收价": 2.97,
        "总市值": 9810044.0,
        "市盈率": -3.06,
        "成交量": 4388.0,
        "成交额": 13058.0,
        "振幅": 4.38,
        "换手率": 0.13,
        "symbol": "107.AIRI"
    },
    {
        "序号": 7614,
        "name": "Ainos Inc Wt",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.28,
        "最高价": 0.28,
        "最低价": 0.28,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 526.0,
        "成交额": 147.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AIMDW"
    },
    {
        "序号": 7615,
        "name": "Ainos Inc",
        "最新价": 0.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.59,
        "最高价": 0.59,
        "最低价": 0.57,
        "昨收价": 0.59,
        "总市值": 11972648.0,
        "市盈率": -1.2,
        "成交量": 19922.0,
        "成交额": 11640.0,
        "振幅": 3.39,
        "换手率": 0.1,
        "symbol": "105.AIMD"
    },
    {
        "序号": 7616,
        "name": "Aimfinity Investment Corp I Wt-",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AIMAW"
    },
    {
        "序号": 7617,
        "name": "Aimfinity Investment Corp I Uni",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.92,
        "总市值": 70743931.0,
        "市盈率": 42.26,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AIMAU"
    },
    {
        "序号": 7618,
        "name": "Aimfinity Investment Corp I-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AIMA"
    },
    {
        "序号": 7619,
        "name": "AIM ImmunoTech Inc",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.57,
        "最高价": 0.58,
        "最低价": 0.53,
        "昨收价": 0.56,
        "总市值": 27351327.0,
        "市盈率": -1.32,
        "成交量": 79191.0,
        "成交额": 43971.0,
        "振幅": 8.93,
        "换手率": 0.16,
        "symbol": "107.AIM"
    },
    {
        "序号": 7620,
        "name": "AIB Acquisition Corp Unit Cons ",
        "最新价": 11.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.36,
        "最高价": 11.36,
        "最低价": 11.36,
        "昨收价": 11.36,
        "总市值": 41032615.0,
        "市盈率": 407.83,
        "成交量": 1.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AIBBU"
    },
    {
        "序号": 7621,
        "name": "Ashford Hospitality Trust Inc S",
        "最新价": 10.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.4,
        "最高价": 10.4,
        "最低价": 10.0,
        "昨收价": 10.0,
        "总市值": 12529230.0,
        "市盈率": null,
        "成交量": 3509.0,
        "成交额": 35873.0,
        "振幅": 4.0,
        "换手率": 0.28,
        "symbol": "106.AHT_I"
    },
    {
        "序号": 7622,
        "name": "Ashford Hospitality Trust Inc S",
        "最新价": 14.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 14.0,
        "最高价": 14.0,
        "最低价": 14.0,
        "昨收价": 14.0,
        "总市值": 21447944.0,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 28.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.AHT_G"
    },
    {
        "序号": 7623,
        "name": "American Healthcare REIT Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.AHR"
    },
    {
        "序号": 7624,
        "name": "Newday Ocean Health ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 21.07,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AHOY"
    },
    {
        "序号": 7625,
        "name": "Aberdeen Standard Bloomberg Agr",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AGRIWI"
    },
    {
        "序号": 7626,
        "name": "AgriFORCE Growing Systems Ltd W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 4.0,
        "振幅": null,
        "换手率": null,
        "symbol": "105.AGRIW"
    },
    {
        "序号": 7627,
        "name": "iShares Interest Rate Hedged U.",
        "最新价": 25.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 25.72,
        "最高价": 25.72,
        "最低价": 25.72,
        "昨收价": 25.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 257.0,
        "成交额": 6608.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AGRH"
    },
    {
        "序号": 7628,
        "name": "ETC Gavekal Asia Pacific Govern",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 73.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AGOV"
    },
    {
        "序号": 7629,
        "name": "联邦农业抵押贷款-A",
        "最新价": 137.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 137.86,
        "最高价": 137.86,
        "最低价": 137.86,
        "昨收价": 137.86,
        "总市值": 330864000.0,
        "市盈率": 7.63,
        "成交量": 200.0,
        "成交额": 27572.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.AGM_A"
    },
    {
        "序号": 7630,
        "name": "AgileThought Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AGILW"
    },
    {
        "序号": 7631,
        "name": "AgileThought Inc-A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.2,
        "总市值": 10480874.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AGIL"
    },
    {
        "序号": 7632,
        "name": "堂堂加",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AGII"
    },
    {
        "序号": 7633,
        "name": "iShares Inflation Hedged U.S. A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 24.22,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AGIH"
    },
    {
        "序号": 7634,
        "name": "AGBA Group Holding Ltd Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AGBAW"
    },
    {
        "序号": 7635,
        "name": "First Trust Active Factor Small",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 25.34,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AFSM"
    },
    {
        "序号": 7636,
        "name": "Global X Emerging Africa ETF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.AFR"
    },
    {
        "序号": 7637,
        "name": "Aimei Health Technology Co Ltd ",
        "最新价": 10.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.17,
        "最高价": 10.17,
        "最低价": 10.15,
        "昨收价": 10.16,
        "总市值": 79908400.0,
        "市盈率": null,
        "成交量": 50881.0,
        "成交额": 517000.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.AFJKU"
    },
    {
        "序号": 7638,
        "name": "Aimei Health Technology Co Ltd ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AFJKR"
    },
    {
        "序号": 7639,
        "name": "Aimei Health Technology Co Ltd",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AFJK"
    },
    {
        "序号": 7640,
        "name": "AFG Holdings Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.AFGL"
    },
    {
        "序号": 7641,
        "name": "Aura FAT Projects Acquisition C",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 49917.0,
        "成交额": 759.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.AFARW"
    },
    {
        "序号": 7642,
        "name": "Aura FAT Projects Acquisition C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.87,
        "总市值": 62847036.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AFARU"
    },
    {
        "序号": 7643,
        "name": "Aeva Technologies Inc Wt",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 60.0,
        "成交额": 1.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.AEVA_WS"
    },
    {
        "序号": 7644,
        "name": "Aeries Technology Inc Wt",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.07,
        "最高价": 0.08,
        "最低价": 0.07,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 678.0,
        "成交额": 49.0,
        "振幅": 12.5,
        "换手率": null,
        "symbol": "105.AERTW"
    },
    {
        "序号": 7645,
        "name": "AEON Biopharma Inc Wt",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 202976.0,
        "成交额": 10147.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AEON_WS"
    },
    {
        "序号": 7646,
        "name": "Aenza SAA ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 1.94,
        "总市值": 154809411.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.AENZ"
    },
    {
        "序号": 7647,
        "name": "Alliance Entertainment Holding ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AENTW"
    },
    {
        "序号": 7648,
        "name": "Alliance Entertainment Holding ",
        "最新价": 0.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.9,
        "最高价": 0.99,
        "最低价": 0.78,
        "昨收价": 0.95,
        "总市值": 105417672.0,
        "市盈率": -3.36,
        "成交量": 32449.0,
        "成交额": 28709.0,
        "振幅": 22.11,
        "换手率": 0.03,
        "symbol": "105.AENT"
    },
    {
        "序号": 7649,
        "name": "AltEnergy Acquisition Corp Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AEAEW"
    },
    {
        "序号": 7650,
        "name": "AltEnergy Acquisition Corp Unit",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.61,
        "总市值": 77744542.0,
        "市盈率": 12.67,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AEAEU"
    },
    {
        "序号": 7651,
        "name": "Advantage Solutions Inc Wt",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.11,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 60697.0,
        "成交额": 6680.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ADVWW"
    },
    {
        "序号": 7652,
        "name": "Advanced Biomed Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ADVB"
    },
    {
        "序号": 7653,
        "name": "Edoc Acquisition Corp Wt",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ADOCW"
    },
    {
        "序号": 7654,
        "name": "Edoc Acquisition Corp Rt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ADOCR"
    },
    {
        "序号": 7655,
        "name": "Edoc Acquisition Corp-A",
        "最新价": 11.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.55,
        "最高价": 11.55,
        "最低价": 11.55,
        "昨收价": 11.55,
        "总市值": 41813391.0,
        "市盈率": -20.91,
        "成交量": 25.0,
        "成交额": 288.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ADOC"
    },
    {
        "序号": 7656,
        "name": "Advent Technologies Holdings In",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 27141.0,
        "成交额": 977.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.ADNWW"
    },
    {
        "序号": 7657,
        "name": "Advent Technologies Holdings In",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.32,
        "最高价": 0.34,
        "最低价": 0.31,
        "昨收价": 0.32,
        "总市值": 20009994.0,
        "市盈率": -0.21,
        "成交量": 352399.0,
        "成交额": 113112.0,
        "振幅": 9.38,
        "换手率": 0.56,
        "symbol": "105.ADN"
    },
    {
        "序号": 7658,
        "name": "Color Star Technology Co Ltd-A",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.3,
        "最高价": 0.3,
        "最低价": 0.29,
        "昨收价": 0.29,
        "总市值": 6876193.0,
        "市盈率": -0.18,
        "成交量": 73927.0,
        "成交额": 21500.0,
        "振幅": 3.45,
        "换手率": 0.31,
        "symbol": "105.ADD"
    },
    {
        "序号": 7659,
        "name": "Acropolis Infrastructure Acquis",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.01,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "106.ACRO_WS"
    },
    {
        "序号": 7660,
        "name": "Acropolis Infrastructure Acquis",
        "最新价": 10.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.44,
        "最高价": 10.44,
        "最低价": 10.44,
        "昨收价": 10.44,
        "总市值": 173573342.0,
        "市盈率": 26.29,
        "成交量": 3.0,
        "成交额": 31.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.ACRO"
    },
    {
        "序号": 7661,
        "name": "abrdn Income Credit Strategies ",
        "最新价": 22.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 22.74,
        "最高价": 22.74,
        "最低价": 22.74,
        "昨收价": 22.74,
        "总市值": 36384000.0,
        "市盈率": null,
        "成交量": 60.0,
        "成交额": 1364.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.ACP_A"
    },
    {
        "序号": 7662,
        "name": "abrdn Income Credit Strategies ",
        "最新价": 6.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 6.4,
        "最高价": 6.57,
        "最低价": 6.4,
        "昨收价": 6.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 256883.0,
        "成交额": 1667642.0,
        "振幅": 2.61,
        "换手率": null,
        "symbol": "106.ACP"
    },
    {
        "序号": 7663,
        "name": "Aclarion Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACONW"
    },
    {
        "序号": 7664,
        "name": "Acer Therapeutics Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.9,
        "总市值": 22017353.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACER"
    },
    {
        "序号": 7665,
        "name": "Ace Global Business Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACBAW"
    },
    {
        "序号": 7666,
        "name": "Ace Global Business Acquisition",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 12.04,
        "总市值": 41311395.0,
        "市盈率": 1866.17,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACBAU"
    },
    {
        "序号": 7667,
        "name": "Ace Global Business Acquisition",
        "最新价": 11.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.75,
        "最高价": 11.75,
        "最低价": 11.75,
        "昨收价": 11.75,
        "总市值": 40316353.0,
        "市盈率": 1821.22,
        "成交量": 2993.0,
        "成交额": 35167.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "105.ACBA"
    },
    {
        "序号": 7668,
        "name": "Alset Capital Acquisition Corp ",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": null,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ACAXW"
    },
    {
        "序号": 7669,
        "name": "Alset Capital Acquisition Corp ",
        "最新价": 11.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 11.27,
        "最高价": 11.27,
        "最低价": 11.27,
        "昨收价": 11.27,
        "总市值": 51910026.0,
        "市盈率": 59.07,
        "成交量": 16.0,
        "成交额": 180.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ACAXU"
    },
    {
        "序号": 7670,
        "name": "Alset Capital Acquisition Corp ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.18,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACAXR"
    },
    {
        "序号": 7671,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 11373.0,
        "成交额": 295.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ACAHW"
    },
    {
        "序号": 7672,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.51,
        "总市值": 127776492.0,
        "市盈率": 11.7,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACAHU"
    },
    {
        "序号": 7673,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": 10.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.52,
        "最高价": 10.52,
        "最低价": 10.52,
        "昨收价": 10.52,
        "总市值": 127898068.0,
        "市盈率": 11.71,
        "成交量": 168.0,
        "成交额": 1767.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.ACAH"
    },
    {
        "序号": 7674,
        "name": "Acri Capital Acquisition Corp W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACACW"
    },
    {
        "序号": 7675,
        "name": "Acri Capital Acquisition Corp U",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.98,
        "总市值": 59416074.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACACU"
    },
    {
        "序号": 7676,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACABW"
    },
    {
        "序号": 7677,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.53,
        "总市值": 115152837.0,
        "市盈率": 23.11,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ACABU"
    },
    {
        "序号": 7678,
        "name": "Abaxx Technologies Inc",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "107.ABXX"
    },
    {
        "序号": 7679,
        "name": "Able View Global Inc Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ABLVW"
    },
    {
        "序号": 7680,
        "name": "Abcam plc ADR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 23.99,
        "总市值": 5521325321.0,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.ABCM"
    },
    {
        "序号": 7681,
        "name": "Almaden Minerals Ltd",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 0.14,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.15,
        "总市值": 20583211.0,
        "市盈率": -2.37,
        "成交量": 163726.0,
        "成交额": 23722.0,
        "振幅": 6.67,
        "换手率": 0.12,
        "symbol": "107.AAU"
    },
    {
        "序号": 7682,
        "name": "Apple Inc Notes 2042",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL42"
    },
    {
        "序号": 7683,
        "name": "Apple Inc Notes 2029",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL29A"
    },
    {
        "序号": 7684,
        "name": "Apple Inc Notes 2029",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL29"
    },
    {
        "序号": 7685,
        "name": "Apple Inc Notes 2027",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL27"
    },
    {
        "序号": 7686,
        "name": "Apple Inc Notes 2026",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL26"
    },
    {
        "序号": 7687,
        "name": "Apple Inc Notes 2025",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL25"
    },
    {
        "序号": 7688,
        "name": "Apple Inc Notes 2024",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL24"
    },
    {
        "序号": 7689,
        "name": "Apple Inc Notes 2022",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": null,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AAPL22"
    },
    {
        "序号": 7690,
        "name": "Armada Acquisition Corp I Wt",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AACIW"
    },
    {
        "序号": 7691,
        "name": "Armada Acquisition Corp I Unit ",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "开盘价": null,
        "最高价": null,
        "最低价": null,
        "昨收价": 10.81,
        "总市值": 87267498.0,
        "市盈率": -272.58,
        "成交量": null,
        "成交额": null,
        "振幅": null,
        "换手率": 0.0,
        "symbol": "105.AACIU"
    },
    {
        "序号": 7692,
        "name": "Armada Acquisition Corp I",
        "最新价": 10.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.8,
        "昨收价": 10.8,
        "总市值": 87186769.0,
        "市盈率": -272.33,
        "成交量": 54.0,
        "成交额": 583.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.AACI"
    },
    {
        "序号": 7693,
        "name": "AXS First Priority CLO Bond ETF",
        "最新价": 24.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "开盘价": 24.95,
        "最高价": 24.95,
        "最低价": 24.95,
        "昨收价": 24.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 631.0,
        "成交额": 15743.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AAA"
    },
    {
        "序号": 7694,
        "name": "Invesco Short Term Treasury ETF",
        "最新价": 105.71,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 105.75,
        "最高价": 105.75,
        "最低价": 105.71,
        "昨收价": 105.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 198688.0,
        "成交额": 21005245.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.TBLL"
    },
    {
        "序号": 7695,
        "name": "MSCI丹麦ETF-iShares",
        "最新价": 105.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 105.39,
        "最高价": 105.58,
        "最低价": 104.93,
        "昨收价": 105.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 5184.0,
        "成交额": 546058.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.EDEN"
    },
    {
        "序号": 7696,
        "name": "Goldman Sachs Access Treasury 0",
        "最新价": 99.86,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 99.86,
        "最高价": 99.87,
        "最低价": 99.86,
        "昨收价": 99.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 694159.0,
        "成交额": 69322412.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.GBIL"
    },
    {
        "序号": 7697,
        "name": "北极星",
        "最新价": 89.86,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 89.71,
        "最高价": 90.71,
        "最低价": 88.85,
        "昨收价": 89.87,
        "总市值": 5074637092.0,
        "市盈率": 8.53,
        "成交量": 527938.0,
        "成交额": 47450199.0,
        "振幅": 2.07,
        "换手率": 0.93,
        "symbol": "106.PII"
    },
    {
        "序号": 7698,
        "name": "日本证券汇率对冲ETF-WisdomTree",
        "最新价": 89.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 88.22,
        "最高价": 89.16,
        "最低价": 88.11,
        "昨收价": 89.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 775151.0,
        "成交额": 68790327.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.DXJ"
    },
    {
        "序号": 7699,
        "name": "笛卡尔物流系统集团",
        "最新价": 82.96,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 82.7,
        "最高价": 83.22,
        "最低价": 81.67,
        "昨收价": 82.97,
        "总市值": 7060571377.0,
        "市盈率": 62.03,
        "成交量": 200860.0,
        "成交额": 16653609.0,
        "振幅": 1.87,
        "换手率": 0.24,
        "symbol": "105.DSGX"
    },
    {
        "序号": 7700,
        "name": "哈特福德金融",
        "最新价": 78.28,
        "涨跌额": -0.01,
        "涨跌幅": -0.01,
        "开盘价": 78.31,
        "最高价": 78.4,
        "最低价": 77.82,
        "昨收价": 78.29,
        "总市值": 23544262136.0,
        "市盈率": 10.14,
        "成交量": 1021800.0,
        "成交额": 79905208.0,
        "振幅": 0.74,
        "换手率": 0.34,
        "symbol": "106.HIG"
    },
    {
        "序号": 7701,
        "name": "First Trust Materials AlphaDEX ",
        "最新价": 64.26,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 64.17,
        "最高价": 65.0,
        "最低价": 64.09,
        "昨收价": 64.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 37267.0,
        "成交额": 2401990.0,
        "振幅": 1.42,
        "换手率": null,
        "symbol": "107.FXZ"
    },
    {
        "序号": 7702,
        "name": "TransUnion",
        "最新价": 62.63,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 62.85,
        "最高价": 64.33,
        "最低价": 62.49,
        "昨收价": 62.64,
        "总市值": 12131431000.0,
        "市盈率": -49.17,
        "成交量": 1958279.0,
        "成交额": 123698633.0,
        "振幅": 2.94,
        "换手率": 1.01,
        "symbol": "106.TRU"
    },
    {
        "序号": 7703,
        "name": "American Century Focused Large ",
        "最新价": 59.56,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 59.86,
        "最高价": 59.86,
        "最低价": 59.43,
        "昨收价": 59.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 2840.0,
        "成交额": 169019.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.FLV"
    },
    {
        "序号": 7704,
        "name": "Vanguard Total International St",
        "最新价": 56.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 55.9,
        "最高价": 56.28,
        "最低价": 55.9,
        "昨收价": 56.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2178968.0,
        "成交额": 122253310.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "105.VXUS"
    },
    {
        "序号": 7705,
        "name": "JPMorgan Diversified Return Int",
        "最新价": 53.41,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 53.21,
        "最高价": 53.49,
        "最低价": 53.16,
        "昨收价": 53.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 37398.0,
        "成交额": 1995357.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.JPIN"
    },
    {
        "序号": 7706,
        "name": "iShares Short-Term National Mun",
        "最新价": 105.02,
        "涨跌额": -0.02,
        "涨跌幅": -0.02,
        "开盘价": 104.97,
        "最高价": 105.05,
        "最低价": 104.88,
        "昨收价": 105.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 378342.0,
        "成交额": 39714272.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.SUB"
    },
    {
        "序号": 7707,
        "name": "约翰宾技术",
        "最新价": 104.94,
        "涨跌额": -0.02,
        "涨跌幅": -0.02,
        "开盘价": 104.71,
        "最高价": 106.38,
        "最低价": 104.13,
        "昨收价": 104.96,
        "总市值": 3341158005.0,
        "市盈率": 5.95,
        "成交量": 131066.0,
        "成交额": 13778436.0,
        "振幅": 2.14,
        "换手率": 0.41,
        "symbol": "106.JBT"
    },
    {
        "序号": 7708,
        "name": "AZZ Inc",
        "最新价": 51.51,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 51.44,
        "最高价": 52.01,
        "最低价": 51.15,
        "昨收价": 51.52,
        "总市值": 1291709625.0,
        "市盈率": 34.56,
        "成交量": 63939.0,
        "成交额": 3290409.0,
        "振幅": 1.67,
        "换手率": 0.25,
        "symbol": "106.AZZ"
    },
    {
        "序号": 7709,
        "name": "iShares Ultra Short-Term Bond E",
        "最新价": 50.35,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.36,
        "最高价": 50.36,
        "最低价": 50.34,
        "昨收价": 50.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 941131.0,
        "成交额": 47387640.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.ICSH"
    },
    {
        "序号": 7710,
        "name": "JPMorgan Municipal ETF",
        "最新价": 50.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.34,
        "最高价": 50.41,
        "最低价": 50.26,
        "昨收价": 50.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 204989.0,
        "成交额": 10313888.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.JMUB"
    },
    {
        "序号": 7711,
        "name": "Eaton Vance Ultra-Short Income ",
        "最新价": 50.31,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.32,
        "最高价": 50.32,
        "最低价": 50.31,
        "昨收价": 50.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 5082.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.EVSB"
    },
    {
        "序号": 7712,
        "name": "BondBloxx Bloomberg Six Month T",
        "最新价": 50.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.22,
        "最高价": 50.25,
        "最低价": 50.2,
        "昨收价": 50.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 93849.0,
        "成交额": 4712246.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.XHLF"
    },
    {
        "序号": 7713,
        "name": "JPMorgan Ultra-Short Income ETF",
        "最新价": 50.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.2,
        "最高价": 50.21,
        "最低价": 50.19,
        "昨收价": 50.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 3539540.0,
        "成交额": 177679969.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.JPST"
    },
    {
        "序号": 7714,
        "name": "Putnam ESG Ultra Short ETF",
        "最新价": 50.18,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.18,
        "最高价": 50.18,
        "最低价": 50.18,
        "昨收价": 50.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 108.0,
        "成交额": 5418.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PULT"
    },
    {
        "序号": 7715,
        "name": "PIMCO Senior Loan Active Exchan",
        "最新价": 50.18,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.2,
        "最高价": 50.21,
        "最低价": 50.14,
        "昨收价": 50.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 42223.0,
        "成交额": 2118485.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.LONZ"
    },
    {
        "序号": 7716,
        "name": "iShares Short Maturity Municipa",
        "最新价": 50.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.01,
        "最高价": 50.02,
        "最低价": 49.96,
        "昨收价": 50.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 38675.0,
        "成交额": 1933295.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.MEAR"
    },
    {
        "序号": 7717,
        "name": "NEOS Enhanced Income Cash Alter",
        "最新价": 50.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 50.0,
        "最高价": 50.03,
        "最低价": 49.98,
        "昨收价": 50.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 80531.0,
        "成交额": 4026785.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.CSHI"
    },
    {
        "序号": 7718,
        "name": "JPMorgan Sustainable Municipal ",
        "最新价": 49.97,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 49.9,
        "最高价": 50.03,
        "最低价": 49.9,
        "昨收价": 49.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 18088.0,
        "成交额": 903559.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.JMSI"
    },
    {
        "序号": 7719,
        "name": "Goldman Sachs Access Ultra Shor",
        "最新价": 49.95,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 49.92,
        "最高价": 49.95,
        "最低价": 49.92,
        "昨收价": 49.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 452755.0,
        "成交额": 22612682.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.GSST"
    },
    {
        "序号": 7720,
        "name": "Short Term Municipal Bond Activ",
        "最新价": 49.8,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 49.79,
        "最高价": 49.88,
        "最低价": 49.77,
        "昨收价": 49.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 25761.0,
        "成交额": 1283660.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.SMMU"
    },
    {
        "序号": 7721,
        "name": "孩之宝",
        "最新价": 48.7,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 48.53,
        "最高价": 49.23,
        "最低价": 48.37,
        "昨收价": 48.71,
        "总市值": 6757813910.0,
        "市盈率": -12.13,
        "成交量": 1529049.0,
        "成交额": 74549054.0,
        "振幅": 1.77,
        "换手率": 1.1,
        "symbol": "105.HAS"
    },
    {
        "序号": 7722,
        "name": "欧扎克银行",
        "最新价": 45.07,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 45.18,
        "最高价": 45.77,
        "最低价": 44.8,
        "昨收价": 45.08,
        "总市值": 5099322379.0,
        "市盈率": 16.58,
        "成交量": 886842.0,
        "成交额": 39979480.0,
        "振幅": 2.15,
        "换手率": 0.78,
        "symbol": "105.OZK"
    },
    {
        "序号": 7723,
        "name": "VictoryShares International Val",
        "最新价": 44.57,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 44.6,
        "最高价": 44.6,
        "最低价": 44.57,
        "昨收价": 44.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 267.0,
        "成交额": 11909.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "105.UIVM"
    },
    {
        "序号": 7724,
        "name": "全球房地产ETF-SPDR",
        "最新价": 41.41,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 41.32,
        "最高价": 41.53,
        "最低价": 41.11,
        "昨收价": 41.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 181488.0,
        "成交额": 7491100.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "107.RWO"
    },
    {
        "序号": 7725,
        "name": "Willis Towers Watson PLC",
        "最新价": 242.66,
        "涨跌额": -0.06,
        "涨跌幅": -0.02,
        "开盘价": 243.4,
        "最高价": 243.97,
        "最低价": 241.54,
        "昨收价": 242.72,
        "总市值": 25057170363.0,
        "市盈率": 24.54,
        "成交量": 271159.0,
        "成交额": 65798228.0,
        "振幅": 1.0,
        "换手率": 0.26,
        "symbol": "105.WTW"
    },
    {
        "序号": 7726,
        "name": "Mobileye Global Inc-A",
        "最新价": 40.21,
        "涨跌额": -0.01,
        "涨跌幅": -0.02,
        "开盘价": 40.62,
        "最高价": 41.98,
        "最低价": 40.11,
        "昨收价": 40.22,
        "总市值": 32415209230.0,
        "市盈率": -540.25,
        "成交量": 5743632.0,
        "成交额": 235202591.0,
        "振幅": 4.65,
        "换手率": 0.71,
        "symbol": "105.MBLY"
    },
    {
        "序号": 7727,
        "name": "A.O.史密斯",
        "最新价": 77.99,
        "涨跌额": -0.02,
        "涨跌幅": -0.03,
        "开盘价": 78.12,
        "最高价": 79.29,
        "最低价": 77.78,
        "昨收价": 78.01,
        "总市值": 11598706180.0,
        "市盈率": 38.77,
        "成交量": 827853.0,
        "成交额": 64891247.0,
        "振幅": 1.94,
        "换手率": 0.56,
        "symbol": "106.AOS"
    },
    {
        "序号": 7728,
        "name": "Soleno Therapeutics Inc",
        "最新价": 37.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 37.01,
        "最高价": 37.94,
        "最低价": 35.5,
        "昨收价": 37.01,
        "总市值": 1128476024.0,
        "市盈率": -34.01,
        "成交量": 196058.0,
        "成交额": 7196976.0,
        "振幅": 6.59,
        "换手率": 0.64,
        "symbol": "105.SLNO"
    },
    {
        "序号": 7729,
        "name": "开市客",
        "最新价": 610.78,
        "涨跌额": -0.17,
        "涨跌幅": -0.03,
        "开盘价": 608.6,
        "最高价": 613.61,
        "最低价": 607.73,
        "昨收价": 610.95,
        "总市值": 271082646814.0,
        "市盈率": 43.08,
        "成交量": 1502329.0,
        "成交额": 917800736.0,
        "振幅": 0.96,
        "换手率": 0.34,
        "symbol": "105.COST"
    },
    {
        "序号": 7730,
        "name": "普林斯顿银行",
        "最新价": 34.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 34.23,
        "最高价": 34.46,
        "最低价": 33.83,
        "昨收价": 34.25,
        "总市值": 215654237.0,
        "市盈率": 19.58,
        "成交量": 13243.0,
        "成交额": 452619.0,
        "振幅": 1.84,
        "换手率": 0.21,
        "symbol": "105.BPRN"
    },
    {
        "序号": 7731,
        "name": "利西亚车行",
        "最新价": 270.47,
        "涨跌额": -0.08,
        "涨跌幅": -0.03,
        "开盘价": 270.93,
        "最高价": 275.33,
        "最低价": 268.12,
        "昨收价": 270.55,
        "总市值": 7443209443.0,
        "市盈率": 7.19,
        "成交量": 87342.0,
        "成交额": 23672440.0,
        "振幅": 2.66,
        "换手率": 0.32,
        "symbol": "106.LAD"
    },
    {
        "序号": 7732,
        "name": "iShares Edge MSCI Intl Momentum",
        "最新价": 33.61,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 33.41,
        "最高价": 33.62,
        "最低价": 33.41,
        "昨收价": 33.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 113913.0,
        "成交额": 3821645.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.IMTM"
    },
    {
        "序号": 7733,
        "name": "ETC 6 Meridian Low Beta Equity ",
        "最新价": 32.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 32.45,
        "最高价": 32.45,
        "最低价": 32.4,
        "昨收价": 32.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 220.0,
        "成交额": 7139.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.SIXL"
    },
    {
        "序号": 7734,
        "name": "维萨",
        "最新价": 255.74,
        "涨跌额": -0.08,
        "涨跌幅": -0.03,
        "开盘价": 255.0,
        "最高价": 256.04,
        "最低价": 253.87,
        "昨收价": 255.82,
        "总市值": 469914296742.0,
        "市盈率": 27.21,
        "成交量": 3732515.0,
        "成交额": 953089024.0,
        "振幅": 0.85,
        "换手率": 0.2,
        "symbol": "106.V"
    },
    {
        "序号": 7735,
        "name": "Abrdn Bloomberg All Commodity L",
        "最新价": 31.7,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 31.67,
        "最高价": 31.81,
        "最低价": 31.6,
        "昨收价": 31.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 20320.0,
        "成交额": 644953.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.BCD"
    },
    {
        "序号": 7736,
        "name": "阿米斯医疗",
        "最新价": 93.7,
        "涨跌额": -0.03,
        "涨跌幅": -0.03,
        "开盘价": 93.8,
        "最高价": 94.17,
        "最低价": 93.41,
        "昨收价": 93.73,
        "总市值": 3057744614.0,
        "市盈率": 1154.74,
        "成交量": 236963.0,
        "成交额": 22211947.0,
        "振幅": 0.81,
        "换手率": 0.73,
        "symbol": "105.AMED"
    },
    {
        "序号": 7737,
        "name": "Omega Healthcare Investors Inc",
        "最新价": 30.52,
        "涨跌额": -0.01,
        "涨跌幅": -0.03,
        "开盘价": 30.41,
        "最高价": 30.62,
        "最低价": 30.18,
        "昨收价": 30.53,
        "总市值": 7477204336.0,
        "市盈率": 32.12,
        "成交量": 1544590.0,
        "成交额": 47093182.0,
        "振幅": 1.44,
        "换手率": 0.63,
        "symbol": "106.OHI"
    },
    {
        "序号": 7738,
        "name": "First Trust Enhanced Short Matu",
        "最新价": 59.64,
        "涨跌额": -0.02,
        "涨跌幅": -0.03,
        "开盘价": 59.64,
        "最高价": 59.65,
        "最低价": 59.62,
        "昨收价": 59.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1623505.0,
        "成交额": 96812289.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "105.FTSM"
    },
    {
        "序号": 7739,
        "name": "Terreno Realty Corp",
        "最新价": 58.91,
        "涨跌额": -0.02,
        "涨跌幅": -0.03,
        "开盘价": 58.57,
        "最高价": 59.07,
        "最低价": 58.47,
        "昨收价": 58.93,
        "总市值": 5030083664.0,
        "市盈率": 32.92,
        "成交量": 523468.0,
        "成交额": 30814705.0,
        "振幅": 1.02,
        "换手率": 0.61,
        "symbol": "106.TRNO"
    },
    {
        "序号": 7740,
        "name": "Simon Property Group Inc Series",
        "最新价": 58.39,
        "涨跌额": -0.02,
        "涨跌幅": -0.03,
        "开盘价": 57.64,
        "最高价": 58.39,
        "最低价": 57.64,
        "昨收价": 58.41,
        "总市值": 46533794.0,
        "市盈率": null,
        "成交量": 405.0,
        "成交额": 23496.0,
        "振幅": 1.28,
        "换手率": 0.05,
        "symbol": "106.SPG_J"
    },
    {
        "序号": 7741,
        "name": "First Trust Horizon Managed Vol",
        "最新价": 28.18,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 28.16,
        "最高价": 28.21,
        "最低价": 28.13,
        "昨收价": 28.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 2840.0,
        "成交额": 79977.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.HDMV"
    },
    {
        "序号": 7742,
        "name": "摩凡陀",
        "最新价": 28.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 27.95,
        "最高价": 28.2,
        "最低价": 27.66,
        "昨收价": 28.01,
        "总市值": 618240308.0,
        "市盈率": 10.79,
        "成交量": 111273.0,
        "成交额": 3109308.0,
        "振幅": 1.93,
        "换手率": 0.5,
        "symbol": "106.MOV"
    },
    {
        "序号": 7743,
        "name": "Invesco S&P 500 Equal Weight He",
        "最新价": 27.9,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 27.88,
        "最高价": 27.95,
        "最低价": 27.78,
        "昨收价": 27.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 52864.0,
        "成交额": 1473765.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.RSPH"
    },
    {
        "序号": 7744,
        "name": "MSCI智利ETF-iShares",
        "最新价": 27.35,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 27.29,
        "最高价": 27.5,
        "最低价": 27.27,
        "昨收价": 27.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 489914.0,
        "成交额": 13421705.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.ECH"
    },
    {
        "序号": 7745,
        "name": "Aramark",
        "最新价": 27.16,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 27.21,
        "最高价": 27.39,
        "最低价": 27.03,
        "昨收价": 27.17,
        "总市值": 7102570561.0,
        "市盈率": 10.54,
        "成交量": 2032846.0,
        "成交额": 55323305.0,
        "振幅": 1.32,
        "换手率": 0.78,
        "symbol": "106.ARMK"
    },
    {
        "序号": 7746,
        "name": "FlexShares International Qualit",
        "最新价": 26.9,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 26.81,
        "最高价": 26.9,
        "最低价": 26.79,
        "昨收价": 26.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 4912.0,
        "成交额": 131948.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.IQDY"
    },
    {
        "序号": 7747,
        "name": "iMGP DBi Hedge Strategy ETF",
        "最新价": 26.85,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 27.06,
        "最高价": 27.06,
        "最低价": 26.85,
        "昨收价": 26.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 220.0,
        "成交额": 5941.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.DBEH"
    },
    {
        "序号": 7748,
        "name": "NuStar Logistics LP Notes",
        "最新价": 26.84,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 26.85,
        "最高价": 26.88,
        "最低价": 26.8,
        "昨收价": 26.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 30689.0,
        "成交额": 823551.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "106.NSS"
    },
    {
        "序号": 7749,
        "name": "亚瑟加拉格尔",
        "最新价": 240.96,
        "涨跌额": -0.09,
        "涨跌幅": -0.04,
        "开盘价": 241.91,
        "最高价": 242.1,
        "最低价": 239.66,
        "昨收价": 241.05,
        "总市值": 52025914560.0,
        "市盈率": 45.75,
        "成交量": 863059.0,
        "成交额": 207700367.0,
        "振幅": 1.01,
        "换手率": 0.4,
        "symbol": "106.AJG"
    },
    {
        "序号": 7750,
        "name": "Apollo Global Management Inc No",
        "最新价": 26.5,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 26.51,
        "最高价": 26.56,
        "最低价": 26.39,
        "昨收价": 26.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 45618.0,
        "成交额": 1208252.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "106.APOS"
    },
    {
        "序号": 7751,
        "name": "iShares MSCI Emerging Markets e",
        "最新价": 52.99,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 52.84,
        "最高价": 53.07,
        "最低价": 52.74,
        "昨收价": 53.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 868076.0,
        "成交额": 45926187.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.EMXC"
    },
    {
        "序号": 7752,
        "name": "iShares LifePath Target Date 20",
        "最新价": 26.45,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 26.48,
        "最高价": 26.48,
        "最低价": 26.45,
        "昨收价": 26.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 2074.0,
        "成交额": 54917.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.ITDB"
    },
    {
        "序号": 7753,
        "name": "Avantis All International Marke",
        "最新价": 52.54,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 52.6,
        "最高价": 52.6,
        "最低价": 52.54,
        "昨收价": 52.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 102.0,
        "成交额": 5365.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.AVNV"
    },
    {
        "序号": 7754,
        "name": "凌云半导体",
        "最新价": 78.53,
        "涨跌额": -0.03,
        "涨跌幅": -0.04,
        "开盘价": 78.52,
        "最高价": 79.81,
        "最低价": 78.5,
        "昨收价": 78.56,
        "总市值": 4232585360.0,
        "市盈率": 30.06,
        "成交量": 545389.0,
        "成交额": 42921549.0,
        "振幅": 1.67,
        "换手率": 1.01,
        "symbol": "105.CRUS"
    },
    {
        "序号": 7755,
        "name": "Alpha Architect International Q",
        "最新价": 26.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.96,
        "最高价": 26.13,
        "最低价": 25.96,
        "昨收价": 26.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 2334.0,
        "成交额": 60763.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.IMOM"
    },
    {
        "序号": 7756,
        "name": "Fidelity Targeted International",
        "最新价": 26.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.98,
        "最高价": 26.02,
        "最低价": 25.9,
        "昨收价": 26.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 10955.0,
        "成交额": 284415.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.FDEV"
    },
    {
        "序号": 7757,
        "name": "Global Partners LP Series A Pfd",
        "最新价": 25.83,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.84,
        "最高价": 25.88,
        "最低价": 25.72,
        "昨收价": 25.84,
        "总市值": 71290800.0,
        "市盈率": null,
        "成交量": 9723.0,
        "成交额": 251110.0,
        "振幅": 0.62,
        "换手率": 0.35,
        "symbol": "106.GLP_A"
    },
    {
        "序号": 7758,
        "name": "Simplify Health Care ETF",
        "最新价": 25.66,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.69,
        "最高价": 25.77,
        "最低价": 25.58,
        "昨收价": 25.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 17899.0,
        "成交额": 458568.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.PINK"
    },
    {
        "序号": 7759,
        "name": "Zions Bancorp NA Series G Pfd",
        "最新价": 25.63,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.33,
        "最高价": 25.63,
        "最低价": 25.33,
        "昨收价": 25.64,
        "总市值": 3546936.0,
        "市盈率": null,
        "成交量": 15184.0,
        "成交额": 386238.0,
        "振幅": 1.17,
        "换手率": 10.97,
        "symbol": "105.ZIONO"
    },
    {
        "序号": 7760,
        "name": "Invesco BulletShares 2032 Munic",
        "最新价": 25.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.52,
        "最高价": 25.55,
        "最低价": 25.46,
        "昨收价": 25.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 16674.0,
        "成交额": 425260.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.BSMW"
    },
    {
        "序号": 7761,
        "name": "美国最小波幅ETF-iShares",
        "最新价": 76.53,
        "涨跌额": -0.03,
        "涨跌幅": -0.04,
        "开盘价": 76.42,
        "最高价": 76.66,
        "最低价": 76.21,
        "昨收价": 76.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 2365178.0,
        "成交额": 180811037.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.USMV"
    },
    {
        "序号": 7762,
        "name": "MAA房产信托",
        "最新价": 127.13,
        "涨跌额": -0.05,
        "涨跌幅": -0.04,
        "开盘价": 126.69,
        "最高价": 128.01,
        "最低价": 126.53,
        "昨收价": 127.18,
        "总市值": 14834482002.0,
        "市盈率": 25.32,
        "成交量": 633612.0,
        "成交额": 80512235.0,
        "振幅": 1.16,
        "换手率": 0.54,
        "symbol": "106.MAA"
    },
    {
        "序号": 7763,
        "name": "First Trust Nasdaq Pharmaceutic",
        "最新价": 25.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.36,
        "最高价": 25.43,
        "最低价": 25.31,
        "昨收价": 25.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 2679.0,
        "成交额": 68011.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "105.FTXH"
    },
    {
        "序号": 7764,
        "name": "Macquarie Tax-Free USA Short Te",
        "最新价": 25.3,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.3,
        "最高价": 25.3,
        "最低价": 25.3,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 253.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.STAX"
    },
    {
        "序号": 7765,
        "name": "Angel Oak UltraShort Income ETF",
        "最新价": 50.55,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 50.56,
        "最高价": 50.56,
        "最低价": 50.54,
        "昨收价": 50.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 20849.0,
        "成交额": 1053851.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.UYLD"
    },
    {
        "序号": 7766,
        "name": "蓝威斯顿控股",
        "最新价": 100.96,
        "涨跌额": -0.04,
        "涨跌幅": -0.04,
        "开盘价": 101.01,
        "最高价": 101.54,
        "最低价": 100.24,
        "昨收价": 101.0,
        "总市值": 14631835069.0,
        "市盈率": 14.46,
        "成交量": 899161.0,
        "成交额": 90712823.0,
        "振幅": 1.29,
        "换手率": 0.62,
        "symbol": "106.LW"
    },
    {
        "序号": 7767,
        "name": "Morgan Stanley Series F Pfd",
        "最新价": 25.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.25,
        "最高价": 25.25,
        "最低价": 25.16,
        "昨收价": 25.21,
        "总市值": 856800.0,
        "市盈率": null,
        "成交量": 185300.0,
        "成交额": 4666804.0,
        "振幅": 0.36,
        "换手率": 545.0,
        "symbol": "106.MS_F"
    },
    {
        "序号": 7768,
        "name": "Splunk Inc",
        "最新价": 150.95,
        "涨跌额": -0.06,
        "涨跌幅": -0.04,
        "开盘价": 151.0,
        "最高价": 151.15,
        "最低价": 150.85,
        "昨收价": 151.01,
        "总市值": 25441021222.0,
        "市盈率": 240.06,
        "成交量": 1201637.0,
        "成交额": 181448378.0,
        "振幅": 0.2,
        "换手率": 0.71,
        "symbol": "105.SPLK"
    },
    {
        "序号": 7769,
        "name": "VanEck IG Floating Rate ETF",
        "最新价": 25.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.14,
        "最高价": 25.15,
        "最低价": 25.13,
        "昨收价": 25.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 283934.0,
        "成交额": 7138906.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.FLTR"
    },
    {
        "序号": 7770,
        "name": "AB Ultra Short Income ETF",
        "最新价": 50.23,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 50.24,
        "最高价": 50.25,
        "最低价": 50.2,
        "昨收价": 50.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 79451.0,
        "成交额": 3990615.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.YEAR"
    },
    {
        "序号": 7771,
        "name": "JPMorgan Chase & Co Series EE P",
        "最新价": 25.09,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.1,
        "最高价": 25.14,
        "最低价": 25.06,
        "昨收价": 25.1,
        "总市值": 4641650.0,
        "市盈率": null,
        "成交量": 215726.0,
        "成交额": 5413139.0,
        "振幅": 0.32,
        "换手率": 116.61,
        "symbol": "106.JPM_C"
    },
    {
        "序号": 7772,
        "name": "博思艾伦",
        "最新价": 125.29,
        "涨跌额": -0.05,
        "涨跌幅": -0.04,
        "开盘价": 125.55,
        "最高价": 125.67,
        "最低价": 123.78,
        "昨收价": 125.34,
        "总市值": 16335460799.0,
        "市盈率": 55.43,
        "成交量": 562879.0,
        "成交额": 70224663.0,
        "振幅": 1.51,
        "换手率": 0.43,
        "symbol": "106.BAH"
    },
    {
        "序号": 7773,
        "name": "Global X 1-3 Month T-Bill ETF",
        "最新价": 25.04,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.05,
        "最高价": 25.05,
        "最低价": 25.04,
        "昨收价": 25.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 87939.0,
        "成交额": 2202528.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.CLIP"
    },
    {
        "序号": 7774,
        "name": "AB Tax-Aware Short Duration Mun",
        "最新价": 25.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.04,
        "最高价": 25.05,
        "最低价": 25.01,
        "昨收价": 25.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 150392.0,
        "成交额": 3763787.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.TAFI"
    },
    {
        "序号": 7775,
        "name": "Morgan Stanley Series I Pfd",
        "最新价": 25.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.97,
        "最高价": 25.05,
        "最低价": 24.85,
        "昨收价": 25.03,
        "总市值": 1000800.0,
        "市盈率": null,
        "成交量": 78349.0,
        "成交额": 1957074.0,
        "振幅": 0.8,
        "换手率": 195.87,
        "symbol": "106.MS_I"
    },
    {
        "序号": 7776,
        "name": "FlexShares Ultra-Short Income F",
        "最新价": 75.01,
        "涨跌额": -0.03,
        "涨跌幅": -0.04,
        "开盘价": 75.02,
        "最高价": 75.03,
        "最低价": 74.99,
        "昨收价": 75.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 110177.0,
        "成交额": 8264528.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.RAVI"
    },
    {
        "序号": 7777,
        "name": "SiriusPoint Ltd Series B Pfd",
        "最新价": 24.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 25.02,
        "最高价": 25.1,
        "最低价": 24.96,
        "昨收价": 25.0,
        "总市值": 199920000.0,
        "市盈率": null,
        "成交量": 6412.0,
        "成交额": 160371.0,
        "振幅": 0.56,
        "换手率": 0.08,
        "symbol": "106.SPNT_B"
    },
    {
        "序号": 7778,
        "name": "The Charles Schwab Corp Series ",
        "最新价": 24.92,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.87,
        "最高价": 24.96,
        "最低价": 24.82,
        "昨收价": 24.93,
        "总市值": 18690000.0,
        "市盈率": null,
        "成交量": 46501.0,
        "成交额": 1157506.0,
        "振幅": 0.56,
        "换手率": 6.2,
        "symbol": "106.SCHW_D"
    },
    {
        "序号": 7779,
        "name": "PGIM Ultra Short Bond ETF",
        "最新价": 49.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 49.39,
        "最高价": 49.4,
        "最低价": 49.38,
        "昨收价": 49.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1302705.0,
        "成交额": 64348157.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.PULS"
    },
    {
        "序号": 7780,
        "name": "BNY Mellon Ultra Short Income E",
        "最新价": 49.3,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 49.3,
        "最高价": 49.31,
        "最低价": 49.3,
        "昨收价": 49.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 229.0,
        "成交额": 11290.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.BKUI"
    },
    {
        "序号": 7781,
        "name": "Avantis International Small Cap",
        "最新价": 49.04,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 48.96,
        "最高价": 49.08,
        "最低价": 48.96,
        "昨收价": 49.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 2986.0,
        "成交额": 146206.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.AVDS"
    },
    {
        "序号": 7782,
        "name": "Aptus Defined Risk ETF",
        "最新价": 24.51,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.44,
        "最高价": 24.53,
        "最低价": 24.36,
        "昨收价": 24.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 51967.0,
        "成交额": 1271162.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.DRSK"
    },
    {
        "序号": 7783,
        "name": "Invesco BulletShares 2025 Munic",
        "最新价": 24.42,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.46,
        "最高价": 24.46,
        "最低价": 24.41,
        "昨收价": 24.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 43068.0,
        "成交额": 1051832.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.BSMP"
    },
    {
        "序号": 7784,
        "name": "Innovator U.S. Equity Accelerat",
        "最新价": 24.39,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.38,
        "最高价": 24.44,
        "最低价": 24.36,
        "昨收价": 24.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 2590.0,
        "成交额": 63238.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.XDJA"
    },
    {
        "序号": 7785,
        "name": "Global X Emerging Markets Great",
        "最新价": 24.39,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.37,
        "最高价": 24.44,
        "最低价": 24.34,
        "昨收价": 24.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 49260.0,
        "成交额": 1201155.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.EMC"
    },
    {
        "序号": 7786,
        "name": "IQ MacKay Municipal Intermediat",
        "最新价": 24.33,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.3,
        "最高价": 24.33,
        "最低价": 24.28,
        "昨收价": 24.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 190781.0,
        "成交额": 4636996.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.MMIT"
    },
    {
        "序号": 7787,
        "name": "全球电信ETF-iShares",
        "最新价": 72.98,
        "涨跌额": -0.03,
        "涨跌幅": -0.04,
        "开盘价": 72.35,
        "最高价": 73.08,
        "最低价": 72.35,
        "昨收价": 73.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 8047.0,
        "成交额": 585938.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.IXP"
    },
    {
        "序号": 7788,
        "name": "Turning Point Brands Inc",
        "最新价": 24.25,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.2,
        "最高价": 24.34,
        "最低价": 23.9,
        "昨收价": 24.26,
        "总市值": 426909780.0,
        "市盈率": 35.47,
        "成交量": 74353.0,
        "成交额": 1799178.0,
        "振幅": 1.81,
        "换手率": 0.42,
        "symbol": "106.TPB"
    },
    {
        "序号": 7789,
        "name": "Dimensional National Municipal ",
        "最新价": 48.14,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 48.07,
        "最高价": 48.14,
        "最低价": 48.05,
        "昨收价": 48.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 90322.0,
        "成交额": 4343958.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.DFNM"
    },
    {
        "序号": 7790,
        "name": "Blueprint Chesapeake Multi-Asse",
        "最新价": 24.06,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 24.0,
        "最高价": 24.22,
        "最低价": 24.0,
        "昨收价": 24.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 16268.0,
        "成交额": 391560.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.TFPN"
    },
    {
        "序号": 7791,
        "name": "MidWestOne Financial Group Inc",
        "最新价": 23.78,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.93,
        "最高价": 24.53,
        "最低价": 23.45,
        "昨收价": 23.79,
        "总市值": 373149530.0,
        "市盈率": 10.93,
        "成交量": 26830.0,
        "成交额": 643339.0,
        "振幅": 4.54,
        "换手率": 0.17,
        "symbol": "105.MOFG"
    },
    {
        "序号": 7792,
        "name": "Alpha Architect High Inflation ",
        "最新价": 23.73,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.74,
        "最高价": 23.74,
        "最低价": 23.72,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 3418.0,
        "成交额": 81091.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.HIDE"
    },
    {
        "序号": 7793,
        "name": "SPDR SSGA US Large Cap Low Vola",
        "最新价": 142.19,
        "涨跌额": -0.06,
        "涨跌幅": -0.04,
        "开盘价": 142.31,
        "最高价": 142.4,
        "最低价": 141.69,
        "昨收价": 142.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 14989.0,
        "成交额": 2128609.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.LGLV"
    },
    {
        "序号": 7794,
        "name": "AGNC Investment Corp Series D P",
        "最新价": 23.68,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.68,
        "最高价": 23.7,
        "最低价": 23.65,
        "昨收价": 23.69,
        "总市值": 222592.0,
        "市盈率": null,
        "成交量": 9539.0,
        "成交额": 226000.0,
        "振幅": 0.21,
        "换手率": 101.48,
        "symbol": "105.AGNCM"
    },
    {
        "序号": 7795,
        "name": "American Homes 4 Rent Series G ",
        "最新价": 23.56,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.42,
        "最高价": 23.56,
        "最低价": 23.42,
        "昨收价": 23.57,
        "总市值": 108376000.0,
        "市盈率": null,
        "成交量": 677.0,
        "成交额": 15913.0,
        "振幅": 0.59,
        "换手率": 0.01,
        "symbol": "106.AMH_G"
    },
    {
        "序号": 7796,
        "name": "OFS Credit Co Inc Series C Pfd",
        "最新价": 23.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.38,
        "最高价": 23.38,
        "最低价": 23.34,
        "昨收价": 23.35,
        "总市值": 21472800.0,
        "市盈率": null,
        "成交量": 558.0,
        "成交额": 13031.0,
        "振幅": 0.17,
        "换手率": 0.06,
        "symbol": "105.OCCIO"
    },
    {
        "序号": 7797,
        "name": "Nuveen NASDAQ 100 Dynamic Overw",
        "最新价": 23.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.15,
        "最高价": 23.25,
        "最低价": 23.05,
        "昨收价": 23.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 98994.0,
        "成交额": 2293322.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "105.QQQX"
    },
    {
        "序号": 7798,
        "name": "VanEck ETF Trust VanEck Interme",
        "最新价": 46.22,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 46.16,
        "最高价": 46.22,
        "最低价": 46.08,
        "昨收价": 46.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 475012.0,
        "成交额": 21924362.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.ITM"
    },
    {
        "序号": 7799,
        "name": "Waste Connections Inc",
        "最新价": 138.33,
        "涨跌额": -0.06,
        "涨跌幅": -0.04,
        "开盘价": 138.57,
        "最高价": 138.84,
        "最低价": 137.41,
        "昨收价": 138.39,
        "总市值": 35639803361.0,
        "市盈率": 42.92,
        "成交量": 499715.0,
        "成交额": 69079165.0,
        "振幅": 1.03,
        "换手率": 0.19,
        "symbol": "106.WCN"
    },
    {
        "序号": 7800,
        "name": "iShares Neuroscience and Health",
        "最新价": 23.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 23.21,
        "最高价": 23.23,
        "最低价": 23.0,
        "昨收价": 23.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 552.0,
        "成交额": 12817.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.IBRN"
    },
    {
        "序号": 7801,
        "name": "iShares Trust iShares Global RE",
        "最新价": 22.89,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 22.84,
        "最高价": 22.94,
        "最低价": 22.71,
        "昨收价": 22.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 525567.0,
        "成交额": 12007795.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.REET"
    },
    {
        "序号": 7802,
        "name": "Regions Financial Corp Series B",
        "最新价": 22.75,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 22.7,
        "最高价": 22.91,
        "最低价": 22.63,
        "昨收价": 22.76,
        "总市值": 11375000.0,
        "市盈率": null,
        "成交量": 100079.0,
        "成交额": 2277383.0,
        "振幅": 1.23,
        "换手率": 20.02,
        "symbol": "106.RF_B"
    },
    {
        "序号": 7803,
        "name": "Arch Capital Group Ltd Series F",
        "最新价": 22.71,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 22.75,
        "最高价": 22.78,
        "最低价": 22.6,
        "昨收价": 22.72,
        "总市值": 181680000.0,
        "市盈率": null,
        "成交量": 48060.0,
        "成交额": 1092838.0,
        "振幅": 0.79,
        "换手率": 0.6,
        "symbol": "105.ACGLO"
    },
    {
        "序号": 7804,
        "name": "Federated Hermes Short Duration",
        "最新价": 22.66,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 22.67,
        "最高价": 22.67,
        "最低价": 22.66,
        "昨收价": 22.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 510.0,
        "成交额": 11558.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.FHYS"
    },
    {
        "序号": 7805,
        "name": "First Trust Global Tactical Com",
        "最新价": 22.51,
        "涨跌额": -0.01,
        "涨跌幅": -0.04,
        "开盘价": 22.6,
        "最高价": 22.68,
        "最低价": 22.47,
        "昨收价": 22.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 1008333.0,
        "成交额": 22738393.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "105.FTGC"
    },
    {
        "序号": 7806,
        "name": "American Century Select High Yi",
        "最新价": 44.58,
        "涨跌额": -0.02,
        "涨跌幅": -0.04,
        "开盘价": 44.55,
        "最高价": 44.58,
        "最低价": 44.49,
        "昨收价": 44.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1296.0,
        "成交额": 57726.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.AHYB"
    },
    {
        "序号": 7807,
        "name": "玉米ETF-Teucrium",
        "最新价": 22.17,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 22.18,
        "最高价": 22.32,
        "最低价": 22.03,
        "昨收价": 22.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 58495.0,
        "成交额": 1297733.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "107.CORN"
    },
    {
        "序号": 7808,
        "name": "Overlay Shares Municipal Bond E",
        "最新价": 21.95,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.88,
        "最高价": 21.95,
        "最低价": 21.88,
        "昨收价": 21.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 716.0,
        "成交额": 15666.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.OVM"
    },
    {
        "序号": 7809,
        "name": "新闻集团-A",
        "最新价": 21.94,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.92,
        "最高价": 22.09,
        "最低价": 21.71,
        "昨收价": 21.95,
        "总市值": 12550873514.0,
        "市盈率": 90.29,
        "成交量": 1801549.0,
        "成交额": 39444792.0,
        "振幅": 1.73,
        "换手率": 0.47,
        "symbol": "105.NWSA"
    },
    {
        "序号": 7810,
        "name": "Janus Henderson U.S. Real Estat",
        "最新价": 21.76,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.7,
        "最高价": 21.8,
        "最低价": 21.61,
        "昨收价": 21.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 13695.0,
        "成交额": 297537.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.JRE"
    },
    {
        "序号": 7811,
        "name": "Global X Dow 30 Covered Call ET",
        "最新价": 21.66,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.7,
        "最高价": 21.71,
        "最低价": 21.61,
        "昨收价": 21.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 28359.0,
        "成交额": 614576.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.DJIA"
    },
    {
        "序号": 7812,
        "name": "Bank of America Corp Series 4 P",
        "最新价": 21.63,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.62,
        "最高价": 21.63,
        "最低价": 21.57,
        "昨收价": 21.64,
        "总市值": 151626.0,
        "市盈率": null,
        "成交量": 1878.0,
        "成交额": 40594.0,
        "振幅": 0.28,
        "换手率": 26.79,
        "symbol": "106.BML_J"
    },
    {
        "序号": 7813,
        "name": "PNM Resources Inc",
        "最新价": 42.64,
        "涨跌额": -0.02,
        "涨跌幅": -0.05,
        "开盘价": 42.6,
        "最高价": 42.74,
        "最低价": 42.23,
        "昨收价": 42.66,
        "总市值": 3659999027.0,
        "市盈率": 23.72,
        "成交量": 282547.0,
        "成交额": 12018382.0,
        "振幅": 1.2,
        "换手率": 0.33,
        "symbol": "106.PNM"
    },
    {
        "序号": 7814,
        "name": "Eaton Vance Tax-Advantaged Divi",
        "最新价": 21.06,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 21.1,
        "最高价": 21.19,
        "最低价": 21.05,
        "昨收价": 21.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 154358.0,
        "成交额": 3256432.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "106.EVT"
    },
    {
        "序号": 7815,
        "name": "MasterCraft Boat Holdings Inc",
        "最新价": 21.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.98,
        "最高价": 21.32,
        "最低价": 20.91,
        "昨收价": 21.01,
        "总市值": 362378961.0,
        "市盈率": 5.1,
        "成交量": 97168.0,
        "成交额": 2045429.0,
        "振幅": 1.95,
        "换手率": 0.56,
        "symbol": "105.MCFT"
    },
    {
        "序号": 7816,
        "name": "Rithm Capital Corp Series C Pfd",
        "最新价": 20.88,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.66,
        "最高价": 20.88,
        "最低价": 20.66,
        "昨收价": 20.89,
        "总市值": 332576640.0,
        "市盈率": null,
        "成交量": 16216.0,
        "成交额": 337948.0,
        "振幅": 1.05,
        "换手率": 0.1,
        "symbol": "106.RITM_C"
    },
    {
        "序号": 7817,
        "name": "亚什兰",
        "最新价": 83.47,
        "涨跌额": -0.04,
        "涨跌幅": -0.05,
        "开盘价": 83.85,
        "最高价": 84.74,
        "最低价": 83.24,
        "昨收价": 83.51,
        "总市值": 4278659846.0,
        "市盈率": 24.04,
        "成交量": 437041.0,
        "成交额": 36589583.0,
        "振幅": 1.8,
        "换手率": 0.85,
        "symbol": "106.ASH"
    },
    {
        "序号": 7818,
        "name": "Invesco BulletShares 2024 Corpo",
        "最新价": 20.83,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.83,
        "最高价": 20.84,
        "最低价": 20.82,
        "昨收价": 20.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 858105.0,
        "成交额": 17874868.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.BSCO"
    },
    {
        "序号": 7819,
        "name": "Coursera Inc",
        "最新价": 20.72,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.55,
        "最高价": 20.91,
        "最低价": 20.55,
        "昨收价": 20.73,
        "总市值": 3160787598.0,
        "市盈率": -21.37,
        "成交量": 1091516.0,
        "成交额": 22622052.0,
        "振幅": 1.74,
        "换手率": 0.72,
        "symbol": "106.COUR"
    },
    {
        "序号": 7820,
        "name": "奥驰亚集团",
        "最新价": 41.3,
        "涨跌额": -0.02,
        "涨跌幅": -0.05,
        "开盘价": 41.34,
        "最高价": 41.43,
        "最低价": 41.17,
        "昨收价": 41.32,
        "总市值": 73045107636.0,
        "市盈率": 8.34,
        "成交量": 7966390.0,
        "成交额": 329010976.0,
        "振幅": 0.63,
        "换手率": 0.45,
        "symbol": "106.MO"
    },
    {
        "序号": 7821,
        "name": "KraneShares Bosera MSCI China A",
        "最新价": 20.62,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.62,
        "最高价": 20.69,
        "最低价": 20.62,
        "昨收价": 20.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 42079.0,
        "成交额": 868753.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.KBA"
    },
    {
        "序号": 7822,
        "name": "CMS Energy Corp Series C Pfd",
        "最新价": 20.59,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.69,
        "最高价": 20.78,
        "最低价": 20.23,
        "昨收价": 20.6,
        "总市值": 189428.0,
        "市盈率": null,
        "成交量": 16576.0,
        "成交额": 340666.0,
        "振幅": 2.67,
        "换手率": 180.17,
        "symbol": "106.CMS_C"
    },
    {
        "序号": 7823,
        "name": "KeyCorp Series H Pfd",
        "最新价": 20.58,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.58,
        "最高价": 20.69,
        "最低价": 20.26,
        "昨收价": 20.59,
        "总市值": 12348000.0,
        "市盈率": null,
        "成交量": 66060.0,
        "成交额": 1351750.0,
        "振幅": 2.09,
        "换手率": 11.01,
        "symbol": "106.KEY_L"
    },
    {
        "序号": 7824,
        "name": "Madison Covered Call ETF",
        "最新价": 20.57,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.57,
        "最高价": 20.57,
        "最低价": 20.57,
        "昨收价": 20.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 202.0,
        "成交额": 4155.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CVRD"
    },
    {
        "序号": 7825,
        "name": "都福",
        "最新价": 143.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.05,
        "开盘价": 143.79,
        "最高价": 145.12,
        "最低价": 143.31,
        "昨收价": 143.69,
        "总市值": 20091022481.0,
        "市盈率": 19.62,
        "成交量": 991035.0,
        "成交额": 142562709.0,
        "振幅": 1.26,
        "换手率": 0.71,
        "symbol": "106.DOV"
    },
    {
        "序号": 7826,
        "name": "GraniteShares Bloomberg Commodi",
        "最新价": 20.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.44,
        "最高价": 20.53,
        "最低价": 20.35,
        "昨收价": 20.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 44325.0,
        "成交额": 905695.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.COMB"
    },
    {
        "序号": 7827,
        "name": "FlexShares International Qualit",
        "最新价": 20.38,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.31,
        "最高价": 20.41,
        "最低价": 20.31,
        "昨收价": 20.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 444.0,
        "成交额": 9031.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.IQDE"
    },
    {
        "序号": 7828,
        "name": "Vanguard Short-Term Tax-Exempt ",
        "最新价": 100.85,
        "涨跌额": -0.05,
        "涨跌幅": -0.05,
        "开盘价": 100.87,
        "最高价": 100.97,
        "最低价": 100.81,
        "昨收价": 100.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 497192.0,
        "成交额": 50142454.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.VTES"
    },
    {
        "序号": 7829,
        "name": "SoFi Enhanced Yield ETF",
        "最新价": 20.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 20.15,
        "最高价": 20.18,
        "最低价": 20.14,
        "昨收价": 20.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2370.0,
        "成交额": 47785.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.THTA"
    },
    {
        "序号": 7830,
        "name": "VEON Ltd ADR",
        "最新价": 20.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 19.96,
        "最高价": 20.69,
        "最低价": 19.67,
        "昨收价": 20.14,
        "总市值": 1411845103.0,
        "市盈率": 3.24,
        "成交量": 140403.0,
        "成交额": 2822925.0,
        "振幅": 5.06,
        "换手率": 0.2,
        "symbol": "105.VEON"
    },
    {
        "序号": 7831,
        "name": "Return Stacked Global Stocks & ",
        "最新价": 20.1,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 19.94,
        "最高价": 20.27,
        "最低价": 19.94,
        "昨收价": 20.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 20451.0,
        "成交额": 410178.0,
        "振幅": 1.64,
        "换手率": null,
        "symbol": "107.RSSB"
    },
    {
        "序号": 7832,
        "name": "United States Lime & Minerals I",
        "最新价": 216.35,
        "涨跌额": -0.11,
        "涨跌幅": -0.05,
        "开盘价": 216.35,
        "最高价": 216.35,
        "最低价": 216.35,
        "昨收价": 216.46,
        "总市值": 1233189375.0,
        "市盈率": 18.04,
        "成交量": 5974.0,
        "成交额": 1292474.0,
        "振幅": 0.0,
        "换手率": 0.1,
        "symbol": "105.USLM"
    },
    {
        "序号": 7833,
        "name": "全球公用事业ETF-iShares",
        "最新价": 58.89,
        "涨跌额": -0.03,
        "涨跌幅": -0.05,
        "开盘价": 58.88,
        "最高价": 58.9,
        "最低价": 58.6,
        "昨收价": 58.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 9839.0,
        "成交额": 577701.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.JXI"
    },
    {
        "序号": 7834,
        "name": "MFA Financial Inc Series B Pfd",
        "最新价": 19.48,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 19.55,
        "最高价": 19.55,
        "最低价": 19.35,
        "昨收价": 19.49,
        "总市值": 155840000.0,
        "市盈率": null,
        "成交量": 9923.0,
        "成交额": 193181.0,
        "振幅": 1.03,
        "换手率": 0.12,
        "symbol": "106.MFA_B"
    },
    {
        "序号": 7835,
        "name": "Invesco S&P 500 Minimum Varianc",
        "最新价": 38.73,
        "涨跌额": -0.02,
        "涨跌幅": -0.05,
        "开盘价": 38.73,
        "最高价": 38.73,
        "最低价": 38.73,
        "昨收价": 38.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 9.0,
        "成交额": 348.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SPMV"
    },
    {
        "序号": 7836,
        "name": "云狄斯快餐",
        "最新价": 19.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 19.2,
        "最高价": 19.29,
        "最低价": 19.07,
        "昨收价": 19.14,
        "总市值": 3945742743.0,
        "市盈率": 19.85,
        "成交量": 1602595.0,
        "成交额": 30683495.0,
        "振幅": 1.15,
        "换手率": 0.78,
        "symbol": "105.WEN"
    },
    {
        "序号": 7837,
        "name": "ProShares Supply Chain Logistic",
        "最新价": 38.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.05,
        "开盘价": 38.24,
        "最高价": 38.24,
        "最低价": 38.24,
        "昨收价": 38.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 306.0,
        "成交额": 11702.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SUPL"
    },
    {
        "序号": 7838,
        "name": "AN2 Therapeutics Inc",
        "最新价": 18.79,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 18.58,
        "最高价": 19.35,
        "最低价": 16.75,
        "昨收价": 18.8,
        "总市值": 558841752.0,
        "市盈率": -9.36,
        "成交量": 69210.0,
        "成交额": 1244886.0,
        "振幅": 13.83,
        "换手率": 0.23,
        "symbol": "105.ANTX"
    },
    {
        "序号": 7839,
        "name": "First Trust Indxx Medical Devic",
        "最新价": 18.66,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 18.69,
        "最高价": 18.7,
        "最低价": 18.64,
        "昨收价": 18.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 1365.0,
        "成交额": 25507.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.MDEV"
    },
    {
        "序号": 7840,
        "name": "TXO Partners LP",
        "最新价": 18.35,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 18.3,
        "最高价": 18.46,
        "最低价": 18.0,
        "昨收价": 18.36,
        "总市值": 564262500.0,
        "市盈率": 8.36,
        "成交量": 43673.0,
        "成交额": 796092.0,
        "振幅": 2.51,
        "换手率": 0.14,
        "symbol": "106.TXO"
    },
    {
        "序号": 7841,
        "name": "ETFB Green SRI REITs ETF",
        "最新价": 18.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 18.34,
        "最高价": 18.34,
        "最低价": 18.34,
        "昨收价": 18.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 36.0,
        "成交额": 660.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RITA"
    },
    {
        "序号": 7842,
        "name": "环球租船",
        "最新价": 18.33,
        "涨跌额": -0.01,
        "涨跌幅": -0.05,
        "开盘价": 18.29,
        "最高价": 18.46,
        "最低价": 18.27,
        "昨收价": 18.34,
        "总市值": 645069892.0,
        "市盈率": 2.06,
        "成交量": 319864.0,
        "成交额": 5869784.0,
        "振幅": 1.04,
        "换手率": 0.91,
        "symbol": "106.GSL"
    },
    {
        "序号": 7843,
        "name": "高力国际集团",
        "最新价": 109.51,
        "涨跌额": -0.06,
        "涨跌幅": -0.05,
        "开盘价": 109.55,
        "最高价": 111.29,
        "最低价": 108.64,
        "昨收价": 109.57,
        "总市值": 5178481612.0,
        "市盈率": 251.21,
        "成交量": 62476.0,
        "成交额": 6855103.0,
        "振幅": 2.42,
        "换手率": 0.13,
        "symbol": "105.CIGI"
    },
    {
        "序号": 7844,
        "name": "InfraCap REIT Preferred ETF",
        "最新价": 17.94,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.93,
        "最高价": 18.0,
        "最低价": 17.9,
        "昨收价": 17.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 2198.0,
        "成交额": 39414.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.PFFR"
    },
    {
        "序号": 7845,
        "name": "第一联合",
        "最新价": 178.46,
        "涨跌额": -0.1,
        "涨跌幅": -0.06,
        "开盘价": 178.87,
        "最高价": 180.76,
        "最低价": 176.9,
        "昨收价": 178.56,
        "总市值": 3340309524.0,
        "市盈率": 32.22,
        "成交量": 87949.0,
        "成交额": 15720034.0,
        "振幅": 2.16,
        "换手率": 0.47,
        "symbol": "106.UNF"
    },
    {
        "序号": 7846,
        "name": "Nuveen Preferred and Income Ter",
        "最新价": 17.84,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.8,
        "最高价": 17.92,
        "最低价": 17.8,
        "昨收价": 17.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 62182.0,
        "成交额": 1109913.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "106.JPI"
    },
    {
        "序号": 7847,
        "name": "Principal Spectrum Tax-Advantag",
        "最新价": 17.82,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.8,
        "最高价": 17.82,
        "最低价": 17.8,
        "昨收价": 17.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 715.0,
        "成交额": 12727.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.PQDI"
    },
    {
        "序号": 7848,
        "name": "墨西哥基金",
        "最新价": 17.71,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.72,
        "最高价": 17.86,
        "最低价": 17.63,
        "昨收价": 17.72,
        "总市值": 266435191.0,
        "市盈率": null,
        "成交量": 9577.0,
        "成交额": 170160.0,
        "振幅": 1.3,
        "换手率": 0.06,
        "symbol": "106.MXF"
    },
    {
        "序号": 7849,
        "name": "阿维斯塔",
        "最新价": 35.14,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 35.21,
        "最高价": 35.41,
        "最低价": 34.93,
        "昨收价": 35.16,
        "总市值": 2718697886.0,
        "市盈率": 16.48,
        "成交量": 296568.0,
        "成交额": 10413238.0,
        "振幅": 1.37,
        "换手率": 0.38,
        "symbol": "106.AVA"
    },
    {
        "序号": 7850,
        "name": "PIMCO Broad U.S. TIPS Index ETF",
        "最新价": 52.45,
        "涨跌额": -0.03,
        "涨跌幅": -0.06,
        "开盘价": 52.35,
        "最高价": 52.47,
        "最低价": 52.26,
        "昨收价": 52.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 5279.0,
        "成交额": 276402.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.TIPZ"
    },
    {
        "序号": 7851,
        "name": "印尼指数ETF-VanEck Vectors",
        "最新价": 17.41,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.41,
        "最高价": 17.42,
        "最低价": 17.37,
        "昨收价": 17.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 11629.0,
        "成交额": 202327.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.IDX"
    },
    {
        "序号": 7852,
        "name": "Stifel Financial Corp Series D ",
        "最新价": 17.32,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.2,
        "最高价": 17.33,
        "最低价": 16.92,
        "昨收价": 17.33,
        "总市值": 207840.0,
        "市盈率": null,
        "成交量": 17451.0,
        "成交额": 299476.0,
        "振幅": 2.37,
        "换手率": 145.43,
        "symbol": "106.SF_D"
    },
    {
        "序号": 7853,
        "name": "钢铁ETF-VanEck Vectors",
        "最新价": 69.0,
        "涨跌额": -0.04,
        "涨跌幅": -0.06,
        "开盘价": 68.74,
        "最高价": 69.77,
        "最低价": 68.74,
        "昨收价": 69.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 5335.0,
        "成交额": 369598.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "107.SLX"
    },
    {
        "序号": 7854,
        "name": "Principal Spectrum Preferred Se",
        "最新价": 17.11,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.1,
        "最高价": 17.17,
        "最低价": 17.07,
        "昨收价": 17.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 577762.0,
        "成交额": 9900476.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.PREF"
    },
    {
        "序号": 7855,
        "name": "Portman Ridge Finance Corp",
        "最新价": 17.05,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 17.05,
        "最高价": 17.25,
        "最低价": 17.03,
        "昨收价": 17.06,
        "总市值": 161072492.0,
        "市盈率": -20.06,
        "成交量": 33861.0,
        "成交额": 579090.0,
        "振幅": 1.29,
        "换手率": 0.36,
        "symbol": "105.PTMN"
    },
    {
        "序号": 7856,
        "name": "VanEck Preferred Securities ex ",
        "最新价": 16.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 16.98,
        "最高价": 17.01,
        "最低价": 16.9,
        "昨收价": 17.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 501564.0,
        "成交额": 8507607.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.PFXF"
    },
    {
        "序号": 7857,
        "name": "Apple Hospitality REIT Inc",
        "最新价": 16.9,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 16.62,
        "最高价": 16.95,
        "最低价": 16.62,
        "昨收价": 16.91,
        "总市值": 3866841714.0,
        "市盈率": 24.31,
        "成交量": 2195725.0,
        "成交额": 36976180.0,
        "振幅": 1.95,
        "换手率": 0.96,
        "symbol": "106.APLE"
    },
    {
        "序号": 7858,
        "name": "JPMorgan Ultra-Short Municipal ",
        "最新价": 50.68,
        "涨跌额": -0.03,
        "涨跌幅": -0.06,
        "开盘价": 50.71,
        "最高价": 50.71,
        "最低价": 50.68,
        "昨收价": 50.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 280294.0,
        "成交额": 14210542.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.JMST"
    },
    {
        "序号": 7859,
        "name": "iShares Trust iShares High Yiel",
        "最新价": 33.76,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 33.75,
        "最高价": 33.78,
        "最低价": 33.74,
        "昨收价": 33.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 10755.0,
        "成交额": 363027.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.HYGW"
    },
    {
        "序号": 7860,
        "name": "Calvert Ultra-Short Investment ",
        "最新价": 50.24,
        "涨跌额": -0.03,
        "涨跌幅": -0.06,
        "开盘价": 50.26,
        "最高价": 50.26,
        "最低价": 50.24,
        "昨收价": 50.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 494.0,
        "成交额": 24819.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.CVSB"
    },
    {
        "序号": 7861,
        "name": "BondBloxx Bloomberg One Year Ta",
        "最新价": 49.71,
        "涨跌额": -0.03,
        "涨跌幅": -0.06,
        "开盘价": 49.71,
        "最高价": 49.72,
        "最低价": 49.7,
        "昨收价": 49.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 29007.0,
        "成交额": 1441862.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.XONE"
    },
    {
        "序号": 7862,
        "name": "Goldman Sachs Future Real Estat",
        "最新价": 33.09,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 33.13,
        "最高价": 33.13,
        "最低价": 32.97,
        "昨收价": 33.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 3092.0,
        "成交额": 102297.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.GREI"
    },
    {
        "序号": 7863,
        "name": "First Trust Horizon Managed Vol",
        "最新价": 33.01,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 32.99,
        "最高价": 33.04,
        "最低价": 32.9,
        "昨收价": 33.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 7456.0,
        "成交额": 245809.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.HUSV"
    },
    {
        "序号": 7864,
        "name": "Air T Inc Pfd",
        "最新价": 16.5,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 16.51,
        "最高价": 16.51,
        "最低价": 16.5,
        "昨收价": 16.51,
        "总市值": 13860000.0,
        "市盈率": null,
        "成交量": 1635.0,
        "成交额": 26980.0,
        "振幅": 0.06,
        "换手率": 0.19,
        "symbol": "105.AIRTP"
    },
    {
        "序号": 7865,
        "name": "Orchard Therapeutics plc ADR",
        "最新价": 16.27,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 16.25,
        "最高价": 16.28,
        "最低价": 16.25,
        "昨收价": 16.28,
        "总市值": 370336990.0,
        "市盈率": -5.08,
        "成交量": 13400.0,
        "成交额": 217993.0,
        "振幅": 0.18,
        "换手率": 0.06,
        "symbol": "105.ORTX"
    },
    {
        "序号": 7866,
        "name": "Knowles Corp",
        "最新价": 16.04,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "开盘价": 16.01,
        "最高价": 16.24,
        "最低价": 15.98,
        "昨收价": 16.05,
        "总市值": 1447724622.0,
        "市盈率": -7.91,
        "成交量": 436061.0,
        "成交额": 7003306.0,
        "振幅": 1.62,
        "换手率": 0.48,
        "symbol": "106.KN"
    },
    {
        "序号": 7867,
        "name": "Malibu Boats Inc-A",
        "最新价": 46.99,
        "涨跌额": -0.03,
        "涨跌幅": -0.06,
        "开盘价": 46.82,
        "最高价": 47.64,
        "最低价": 45.98,
        "昨收价": 47.02,
        "总市值": 958469832.0,
        "市盈率": 10.66,
        "成交量": 153707.0,
        "成交额": 7226221.0,
        "振幅": 3.53,
        "换手率": 0.75,
        "symbol": "105.MBUU"
    },
    {
        "序号": 7868,
        "name": "万威",
        "最新价": 30.88,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 30.71,
        "最高价": 31.2,
        "最低价": 30.33,
        "昨收价": 30.9,
        "总市值": 776950867.0,
        "市盈率": 19.19,
        "成交量": 120712.0,
        "成交额": 3715119.0,
        "振幅": 2.82,
        "换手率": 0.48,
        "symbol": "106.IDT"
    },
    {
        "序号": 7869,
        "name": "惠好",
        "最新价": 30.87,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "开盘价": 30.73,
        "最高价": 30.9,
        "最低价": 30.57,
        "昨收价": 30.89,
        "总市值": 22535130870.0,
        "市盈率": 35.71,
        "成交量": 2799572.0,
        "成交额": 86217925.0,
        "振幅": 1.07,
        "换手率": 0.38,
        "symbol": "106.WY"
    },
    {
        "序号": 7870,
        "name": "康明斯",
        "最新价": 230.58,
        "涨跌额": -0.15,
        "涨跌幅": -0.07,
        "开盘价": 231.31,
        "最高价": 233.32,
        "最低价": 229.8,
        "昨收价": 230.73,
        "总市值": 32683530511.0,
        "市盈率": 11.69,
        "成交量": 501710.0,
        "成交额": 115836382.0,
        "振幅": 1.53,
        "换手率": 0.35,
        "symbol": "106.CMI"
    },
    {
        "序号": 7871,
        "name": "多策略对冲基金复制ETF-IQ",
        "最新价": 30.73,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 30.74,
        "最高价": 30.74,
        "最低价": 30.5,
        "昨收价": 30.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 75979.0,
        "成交额": 2331579.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.QAI"
    },
    {
        "序号": 7872,
        "name": "美国市政债ETF-iShares",
        "最新价": 107.11,
        "涨跌额": -0.07,
        "涨跌幅": -0.07,
        "开盘价": 107.0,
        "最高价": 107.15,
        "最低价": 106.86,
        "昨收价": 107.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 4640064.0,
        "成交额": 496333136.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.MUB"
    },
    {
        "序号": 7873,
        "name": "OSI Systems Inc",
        "最新价": 122.39,
        "涨跌额": -0.08,
        "涨跌幅": -0.07,
        "开盘价": 122.25,
        "最高价": 123.66,
        "最低价": 121.45,
        "昨收价": 122.47,
        "总市值": 2079141982.0,
        "市盈率": 22.26,
        "成交量": 75967.0,
        "成交额": 9291405.0,
        "振幅": 1.8,
        "换手率": 0.45,
        "symbol": "105.OSIS"
    },
    {
        "序号": 7874,
        "name": "Brookfield BRP Holdings (Canada",
        "最新价": 15.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 15.22,
        "最高价": 15.24,
        "最低价": 14.93,
        "昨收价": 15.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 11222.0,
        "成交额": 169425.0,
        "振幅": 2.04,
        "换手率": null,
        "symbol": "106.BEPH"
    },
    {
        "序号": 7875,
        "name": "WisdomTree International SmallC",
        "最新价": 60.47,
        "涨跌额": -0.04,
        "涨跌幅": -0.07,
        "开盘价": 60.24,
        "最高价": 60.59,
        "最低价": 60.23,
        "昨收价": 60.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 58976.0,
        "成交额": 3562719.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.DLS"
    },
    {
        "序号": 7876,
        "name": "SPDR Nuveen Municipal Bond ESG ",
        "最新价": 29.53,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 29.51,
        "最高价": 29.53,
        "最低价": 29.51,
        "昨收价": 29.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 228.0,
        "成交额": 6728.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.MBNE"
    },
    {
        "序号": 7877,
        "name": "Barings Participation Investors",
        "最新价": 14.7,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 14.75,
        "最高价": 14.84,
        "最低价": 14.62,
        "昨收价": 14.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 13879.0,
        "成交额": 204557.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "106.MPV"
    },
    {
        "序号": 7878,
        "name": "Calamos Long\/Short Equity & Dyn",
        "最新价": 14.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 14.58,
        "最高价": 14.74,
        "最低价": 14.53,
        "昨收价": 14.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 54112.0,
        "成交额": 789744.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "105.CPZ"
    },
    {
        "序号": 7879,
        "name": "PennyMac Mortgage Investment Tr",
        "最新价": 14.37,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 14.19,
        "最高价": 14.43,
        "最低价": 14.19,
        "昨收价": 14.38,
        "总市值": 1244787512.0,
        "市盈率": 8.22,
        "成交量": 479249.0,
        "成交额": 6874956.0,
        "振幅": 1.67,
        "换手率": 0.55,
        "symbol": "106.PMT"
    },
    {
        "序号": 7880,
        "name": "First Trust Emerging Markets Lo",
        "最新价": 28.41,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 28.36,
        "最高价": 28.54,
        "最低价": 28.17,
        "昨收价": 28.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 16768.0,
        "成交额": 474703.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "105.FEMB"
    },
    {
        "序号": 7881,
        "name": "Fidelity Investment Grade Bond ",
        "最新价": 42.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.07,
        "开盘价": 42.42,
        "最高价": 42.65,
        "最低价": 42.19,
        "昨收价": 42.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 12818.0,
        "成交额": 543398.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.FIGB"
    },
    {
        "序号": 7882,
        "name": "Axsome Therapeutics Inc",
        "最新价": 70.18,
        "涨跌额": -0.05,
        "涨跌幅": -0.07,
        "开盘价": 70.97,
        "最高价": 72.78,
        "最低价": 69.4,
        "昨收价": 70.23,
        "总市值": 3320885808.0,
        "市盈率": -16.45,
        "成交量": 1358196.0,
        "成交额": 96042010.0,
        "振幅": 4.81,
        "换手率": 2.87,
        "symbol": "105.AXSM"
    },
    {
        "序号": 7883,
        "name": "NETGEAR Inc",
        "最新价": 13.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 14.0,
        "最高价": 14.26,
        "最低价": 13.91,
        "昨收价": 14.0,
        "总市值": 414222285.0,
        "市盈率": -3.8,
        "成交量": 261357.0,
        "成交额": 3656577.0,
        "振幅": 2.5,
        "换手率": 0.88,
        "symbol": "105.NTGR"
    },
    {
        "序号": 7884,
        "name": "WisdomTree Emerging Markets ex-",
        "最新价": 27.97,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 27.97,
        "最高价": 27.97,
        "最低价": 27.97,
        "昨收价": 27.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 177.0,
        "成交额": 4951.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XC"
    },
    {
        "序号": 7885,
        "name": "iShares 0-5 Year High Yield Cor",
        "最新价": 41.74,
        "涨跌额": -0.03,
        "涨跌幅": -0.07,
        "开盘价": 41.71,
        "最高价": 41.75,
        "最低价": 41.64,
        "昨收价": 41.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 781073.0,
        "成交额": 32573323.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.SHYG"
    },
    {
        "序号": 7886,
        "name": "MillerKnoll Inc",
        "最新价": 27.82,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 27.76,
        "最高价": 27.98,
        "最低价": 27.44,
        "昨收价": 27.84,
        "总市值": 2044308383.0,
        "市盈率": 61.95,
        "成交量": 463403.0,
        "成交额": 12854508.0,
        "振幅": 1.94,
        "换手率": 0.63,
        "symbol": "105.MLKN"
    },
    {
        "序号": 7887,
        "name": "GraniteShares 1.75x Long BABA D",
        "最新价": 13.9,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 13.81,
        "最高价": 13.91,
        "最低价": 13.77,
        "昨收价": 13.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 32430.0,
        "成交额": 448798.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "105.BABX"
    },
    {
        "序号": 7888,
        "name": "Principal Focused Blue Chip ETF",
        "最新价": 27.56,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 27.53,
        "最高价": 27.57,
        "最低价": 27.5,
        "昨收价": 27.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 1867.0,
        "成交额": 51406.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.BCHP"
    },
    {
        "序号": 7889,
        "name": "Flaherty & Crumrine Preferred a",
        "最新价": 13.76,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 13.67,
        "最高价": 13.81,
        "最低价": 13.67,
        "昨收价": 13.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 127457.0,
        "成交额": 1754128.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.FFC"
    },
    {
        "序号": 7890,
        "name": "Star Group LP",
        "最新价": 13.74,
        "涨跌额": -0.01,
        "涨跌幅": -0.07,
        "开盘价": 13.4,
        "最高价": 14.47,
        "最低价": 13.03,
        "昨收价": 13.75,
        "总市值": 488995690.0,
        "市盈率": 15.31,
        "成交量": 97156.0,
        "成交额": 1361844.0,
        "振幅": 10.47,
        "换手率": 0.27,
        "symbol": "106.SGU"
    },
    {
        "序号": 7891,
        "name": "潘世奇汽车",
        "最新价": 150.26,
        "涨跌额": -0.11,
        "涨跌幅": -0.07,
        "开盘价": 150.0,
        "最高价": 151.53,
        "最低价": 149.26,
        "昨收价": 150.37,
        "总市值": 10099594573.0,
        "市盈率": 8.7,
        "成交量": 199741.0,
        "成交额": 30019200.0,
        "振幅": 1.51,
        "换手率": 0.3,
        "symbol": "106.PAG"
    },
    {
        "序号": 7892,
        "name": "杜克能源",
        "最新价": 94.39,
        "涨跌额": -0.07,
        "涨跌幅": -0.07,
        "开盘价": 94.45,
        "最高价": 94.74,
        "最低价": 93.8,
        "昨收价": 94.46,
        "总市值": 72747480006.0,
        "市盈率": 60.62,
        "成交量": 3271726.0,
        "成交额": 308650928.0,
        "振幅": 1.0,
        "换手率": 0.42,
        "symbol": "106.DUK"
    },
    {
        "序号": 7893,
        "name": "SPDR SSgA Ultra Short Term Bond",
        "最新价": 40.3,
        "涨跌额": -0.03,
        "涨跌幅": -0.07,
        "开盘价": 40.33,
        "最高价": 40.33,
        "最低价": 40.3,
        "昨收价": 40.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 190115.0,
        "成交额": 7664399.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.ULST"
    },
    {
        "序号": 7894,
        "name": "Goose Hollow Tactical Allocatio",
        "最新价": 26.72,
        "涨跌额": -0.02,
        "涨跌幅": -0.07,
        "开盘价": 26.71,
        "最高价": 26.72,
        "最低价": 26.64,
        "昨收价": 26.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 3106.0,
        "成交额": 82780.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.GHTA"
    },
    {
        "序号": 7895,
        "name": "PHINIA Inc",
        "最新价": 26.6,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 26.74,
        "最高价": 27.08,
        "最低价": 26.27,
        "昨收价": 26.62,
        "总市值": 1241829432.0,
        "市盈率": null,
        "成交量": 585358.0,
        "成交额": 15507053.0,
        "振幅": 3.04,
        "换手率": 1.25,
        "symbol": "106.PHIN"
    },
    {
        "序号": 7896,
        "name": "汽车之家",
        "最新价": 26.45,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 26.21,
        "最高价": 26.54,
        "最低价": 26.21,
        "昨收价": 26.47,
        "总市值": 3259233617.0,
        "市盈率": 11.01,
        "成交量": 424518.0,
        "成交额": 11206958.0,
        "振幅": 1.25,
        "换手率": 0.34,
        "symbol": "106.ATHM"
    },
    {
        "序号": 7897,
        "name": "Strive Emerging Markets Ex-Chin",
        "最新价": 26.37,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 26.31,
        "最高价": 26.38,
        "最低价": 26.28,
        "昨收价": 26.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 12075.0,
        "成交额": 317852.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.STXE"
    },
    {
        "序号": 7898,
        "name": "信达思",
        "最新价": 553.33,
        "涨跌额": -0.42,
        "涨跌幅": -0.08,
        "开盘价": 549.46,
        "最高价": 554.09,
        "最低价": 548.6,
        "昨收价": 553.75,
        "总市值": 56358939113.0,
        "市盈率": 40.8,
        "成交量": 328790.0,
        "成交额": 181780363.0,
        "振幅": 0.99,
        "换手率": 0.32,
        "symbol": "105.CTAS"
    },
    {
        "序号": 7899,
        "name": "韩国电信",
        "最新价": 13.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 13.16,
        "最高价": 13.22,
        "最低价": 13.11,
        "昨收价": 13.14,
        "总市值": 6723680724.0,
        "市盈率": 7.14,
        "成交量": 441794.0,
        "成交额": 5807401.0,
        "振幅": 0.84,
        "换手率": 0.09,
        "symbol": "106.KT"
    },
    {
        "序号": 7900,
        "name": "智利CCU",
        "最新价": 13.03,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 13.08,
        "最高价": 13.19,
        "最低价": 13.0,
        "昨收价": 13.04,
        "总市值": 2407311211.0,
        "市盈率": 18.78,
        "成交量": 87751.0,
        "成交额": 1146134.0,
        "振幅": 1.46,
        "换手率": 0.05,
        "symbol": "106.CCU"
    },
    {
        "序号": 7901,
        "name": "UPAR Ultra Risk Parity ETF",
        "最新价": 12.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 12.9,
        "最高价": 12.99,
        "最低价": 12.84,
        "昨收价": 13.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2919.0,
        "成交额": 37697.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.UPAR"
    },
    {
        "序号": 7902,
        "name": "iShares Fallen Angels USD Bond ",
        "最新价": 25.89,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.86,
        "最高价": 25.91,
        "最低价": 25.82,
        "昨收价": 25.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 592776.0,
        "成交额": 15333063.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.FALN"
    },
    {
        "序号": 7903,
        "name": "Capital Group Short Duration Mu",
        "最新价": 25.69,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.68,
        "最高价": 25.69,
        "最低价": 25.68,
        "昨收价": 25.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 1992.0,
        "成交额": 51155.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.CGSM"
    },
    {
        "序号": 7904,
        "name": "First Trust Developed Markets E",
        "最新价": 51.15,
        "涨跌额": -0.04,
        "涨跌幅": -0.08,
        "开盘价": 50.87,
        "最高价": 51.32,
        "最低价": 50.87,
        "昨收价": 51.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 28038.0,
        "成交额": 1433318.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.FDT"
    },
    {
        "序号": 7905,
        "name": "The RMR Group Inc-A",
        "最新价": 25.49,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.41,
        "最高价": 25.61,
        "最低价": 24.77,
        "昨收价": 25.51,
        "总市值": 808314588.0,
        "市盈率": 14.14,
        "成交量": 102704.0,
        "成交额": 2586360.0,
        "振幅": 3.29,
        "换手率": 0.32,
        "symbol": "105.RMR"
    },
    {
        "序号": 7906,
        "name": "Invesco S&P SmallCap Health Car",
        "最新价": 38.18,
        "涨跌额": -0.03,
        "涨跌幅": -0.08,
        "开盘价": 38.05,
        "最高价": 38.44,
        "最低价": 37.98,
        "昨收价": 38.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 24985.0,
        "成交额": 952162.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "105.PSCH"
    },
    {
        "序号": 7907,
        "name": "First Trust Managed Municipal E",
        "最新价": 50.89,
        "涨跌额": -0.04,
        "涨跌幅": -0.08,
        "开盘价": 50.93,
        "最高价": 50.93,
        "最低价": 50.85,
        "昨收价": 50.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 171547.0,
        "成交额": 8727376.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.FMB"
    },
    {
        "序号": 7908,
        "name": "AGF Global Infrastructure ETF",
        "最新价": 25.44,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.44,
        "最高价": 25.44,
        "最低价": 25.44,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 15.0,
        "成交额": 381.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GLIF"
    },
    {
        "序号": 7909,
        "name": "Capital Southwest Corp Notes",
        "最新价": 25.36,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.34,
        "最高价": 25.4,
        "最低价": 25.34,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 4204.0,
        "成交额": 106664.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "105.CSWCZ"
    },
    {
        "序号": 7910,
        "name": "iShares iBonds Oct 2024 Term TI",
        "最新价": 25.31,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.31,
        "最高价": 25.32,
        "最低价": 25.31,
        "昨收价": 25.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 682.0,
        "成交额": 17262.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.IBIA"
    },
    {
        "序号": 7911,
        "name": "iShares iBonds Oct 2025 Term TI",
        "最新价": 25.27,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.26,
        "最高价": 25.27,
        "最低价": 25.26,
        "昨收价": 25.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 16607.0,
        "成交额": 419600.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.IBIB"
    },
    {
        "序号": 7912,
        "name": "Brookstone Ultra-Short Bond ETF",
        "最新价": 25.21,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 25.23,
        "最高价": 25.25,
        "最低价": 25.19,
        "昨收价": 25.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 10395.0,
        "成交额": 262087.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.BAMU"
    },
    {
        "序号": 7913,
        "name": "Goldman Sachs Community Municip",
        "最新价": 50.22,
        "涨跌额": -0.04,
        "涨跌幅": -0.08,
        "开盘价": 50.22,
        "最高价": 50.22,
        "最低价": 50.22,
        "昨收价": 50.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 108.0,
        "成交额": 5423.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GMUN"
    },
    {
        "序号": 7914,
        "name": "Fidelity Sustainable Low Durati",
        "最新价": 50.12,
        "涨跌额": -0.04,
        "涨跌幅": -0.08,
        "开盘价": 50.12,
        "最高价": 50.12,
        "最低价": 50.12,
        "昨收价": 50.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 135.0,
        "成交额": 6765.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FSLD"
    },
    {
        "序号": 7915,
        "name": "First Financial Northwest Inc",
        "最新价": 12.49,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 12.4,
        "最高价": 12.59,
        "最低价": 12.21,
        "昨收价": 12.5,
        "总市值": 114652080.0,
        "市盈率": 13.75,
        "成交量": 2623.0,
        "成交额": 32545.0,
        "振幅": 3.04,
        "换手率": 0.03,
        "symbol": "105.FFNW"
    },
    {
        "序号": 7916,
        "name": "iShares Environmentally Aware R",
        "最新价": 24.93,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.93,
        "最高价": 24.93,
        "最低价": 24.93,
        "昨收价": 24.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 24.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ERET"
    },
    {
        "序号": 7917,
        "name": "Global X Emerging Markets ETF",
        "最新价": 24.76,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.72,
        "最高价": 24.82,
        "最低价": 24.62,
        "昨收价": 24.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 2741.0,
        "成交额": 67772.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.EMM"
    },
    {
        "序号": 7918,
        "name": "Algonquin Power & Utilities Cor",
        "最新价": 24.76,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.75,
        "最高价": 24.96,
        "最低价": 24.74,
        "昨收价": 24.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 29023.0,
        "成交额": 718729.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "106.AQNB"
    },
    {
        "序号": 7919,
        "name": "Innovator Premium Income 10 Bar",
        "最新价": 24.68,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.72,
        "最高价": 24.73,
        "最低价": 24.68,
        "昨收价": 24.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 4732.0,
        "成交额": 116937.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.OCTD"
    },
    {
        "序号": 7920,
        "name": "GasLog Partners LP Series B Pfd",
        "最新价": 24.6,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.6,
        "最高价": 24.62,
        "最低价": 24.6,
        "昨收价": 24.62,
        "总市值": 86010997.0,
        "市盈率": null,
        "成交量": 1460.0,
        "成交额": 35928.0,
        "振幅": 0.08,
        "换手率": 0.04,
        "symbol": "106.GLOP_B"
    },
    {
        "序号": 7921,
        "name": "全球农业生产商ETF-iShares MSCI",
        "最新价": 36.78,
        "涨跌额": -0.03,
        "涨跌幅": -0.08,
        "开盘价": 36.64,
        "最高价": 36.91,
        "最低价": 36.58,
        "昨收价": 36.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 33273.0,
        "成交额": 1220665.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.VEGI"
    },
    {
        "序号": 7922,
        "name": "JPMorgan Chase & Co Series DD P",
        "最新价": 24.48,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.45,
        "最高价": 24.52,
        "最低价": 24.37,
        "昨收价": 24.5,
        "总市值": 4152420.0,
        "市盈率": null,
        "成交量": 120713.0,
        "成交额": 2948666.0,
        "振幅": 0.61,
        "换手率": 71.16,
        "symbol": "106.JPM_D"
    },
    {
        "序号": 7923,
        "name": "Motley Fool Mid-Cap Growth ETF",
        "最新价": 24.45,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.38,
        "最高价": 24.58,
        "最低价": 24.38,
        "昨收价": 24.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 5884.0,
        "成交额": 143980.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.TMFM"
    },
    {
        "序号": 7924,
        "name": "0-5年抗通胀债ETF-iShares",
        "最新价": 97.58,
        "涨跌额": -0.08,
        "涨跌幅": -0.08,
        "开盘价": 97.5,
        "最高价": 97.58,
        "最低价": 97.45,
        "昨收价": 97.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 505177.0,
        "成交额": 49265931.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.STIP"
    },
    {
        "序号": 7925,
        "name": "First Trust Indxx Global Agricu",
        "最新价": 24.3,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.31,
        "最高价": 24.31,
        "最低价": 24.17,
        "昨收价": 24.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 6565.0,
        "成交额": 159404.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "105.FTAG"
    },
    {
        "序号": 7926,
        "name": "BlackRock TCP Capital Corp",
        "最新价": 12.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 12.1,
        "最高价": 12.18,
        "最低价": 12.09,
        "昨收价": 12.14,
        "总市值": 700716912.0,
        "市盈率": 175.08,
        "成交量": 321365.0,
        "成交额": 3902174.0,
        "振幅": 0.74,
        "换手率": 0.56,
        "symbol": "105.TCPC"
    },
    {
        "序号": 7927,
        "name": "Midland States Bancorp Inc Pfd",
        "最新价": 24.25,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.25,
        "最高价": 24.25,
        "最低价": 24.25,
        "昨收价": 24.27,
        "总市值": 2788750.0,
        "市盈率": null,
        "成交量": 1461.0,
        "成交额": 35429.0,
        "振幅": 0.0,
        "换手率": 1.27,
        "symbol": "105.MSBIP"
    },
    {
        "序号": 7928,
        "name": "PIMCO Dynamic Income Opportunit",
        "最新价": 12.07,
        "涨跌额": -0.01,
        "涨跌幅": -0.08,
        "开盘价": 12.02,
        "最高价": 12.09,
        "最低价": 11.94,
        "昨收价": 12.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 549906.0,
        "成交额": 6603229.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "106.PDO"
    },
    {
        "序号": 7929,
        "name": "环球烟草",
        "最新价": 60.17,
        "涨跌额": -0.05,
        "涨跌幅": -0.08,
        "开盘价": 60.29,
        "最高价": 60.49,
        "最低价": 59.64,
        "昨收价": 60.22,
        "总市值": 1477725921.0,
        "市盈率": 12.17,
        "成交量": 151357.0,
        "成交额": 9105081.0,
        "振幅": 1.41,
        "换手率": 0.62,
        "symbol": "106.UVV"
    },
    {
        "序号": 7930,
        "name": "美国钢铁",
        "最新价": 35.9,
        "涨跌额": -0.03,
        "涨跌幅": -0.08,
        "开盘价": 35.88,
        "最高价": 36.38,
        "最低价": 35.77,
        "昨收价": 35.93,
        "总市值": 8007082150.0,
        "市盈率": 6.97,
        "成交量": 4036234.0,
        "成交额": 145084717.0,
        "振幅": 1.7,
        "换手率": 1.81,
        "symbol": "106.X"
    },
    {
        "序号": 7931,
        "name": "Virtus Seix Senior Loan ETF",
        "最新价": 23.93,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 23.96,
        "最高价": 23.97,
        "最低价": 23.91,
        "昨收价": 23.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 27410.0,
        "成交额": 656127.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.SEIX"
    },
    {
        "序号": 7932,
        "name": "Harbor Health Care ETF",
        "最新价": 23.93,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 24.02,
        "最高价": 24.02,
        "最低价": 23.93,
        "昨收价": 23.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 302.0,
        "成交额": 7248.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.MEDI"
    },
    {
        "序号": 7933,
        "name": "Franklin Dynamic Municipal Bond",
        "最新价": 23.84,
        "涨跌额": -0.02,
        "涨跌幅": -0.08,
        "开盘价": 23.83,
        "最高价": 24.91,
        "最低价": 23.78,
        "昨收价": 23.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 69412.0,
        "成交额": 1655611.0,
        "振幅": 4.74,
        "换手率": null,
        "symbol": "107.FLMI"
    },
    {
        "序号": 7934,
        "name": "AB High Yield ETF",
        "最新价": 35.75,
        "涨跌额": -0.03,
        "涨跌幅": -0.08,
        "开盘价": 35.75,
        "最高价": 35.78,
        "最低价": 35.64,
        "昨收价": 35.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 16371.0,
        "成交额": 583870.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.HYFI"
    },
    {
        "序号": 7935,
        "name": "iShares J.P. Morgan EM High Yie",
        "最新价": 35.48,
        "涨跌额": -0.03,
        "涨跌幅": -0.08,
        "开盘价": 35.37,
        "最高价": 35.51,
        "最低价": 35.37,
        "昨收价": 35.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 48655.0,
        "成交额": 1725835.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.EMHY"
    },
    {
        "序号": 7936,
        "name": "Invesco BulletShares 2028 Munic",
        "最新价": 23.48,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 23.44,
        "最高价": 23.48,
        "最低价": 23.4,
        "昨收价": 23.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 46776.0,
        "成交额": 1096510.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "105.BSMS"
    },
    {
        "序号": 7937,
        "name": "SPDR S&P Global Dividend",
        "最新价": 58.53,
        "涨跌额": -0.05,
        "涨跌幅": -0.09,
        "开盘价": 58.37,
        "最高价": 58.58,
        "最低价": 58.29,
        "昨收价": 58.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 8967.0,
        "成交额": 523724.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.WDIV"
    },
    {
        "序号": 7938,
        "name": "RenaissanceRe Holdings Ltd Seri",
        "最新价": 23.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 23.35,
        "最高价": 23.48,
        "最低价": 23.29,
        "昨收价": 23.4,
        "总市值": 233800.0,
        "市盈率": null,
        "成交量": 16503.0,
        "成交额": 385726.0,
        "振幅": 0.81,
        "换手率": 165.03,
        "symbol": "106.RNR_F"
    },
    {
        "序号": 7939,
        "name": "Medallion Bank Series F Pfd",
        "最新价": 23.33,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 23.66,
        "最高价": 23.66,
        "最低价": 23.2,
        "昨收价": 23.35,
        "总市值": 42927200.0,
        "市盈率": null,
        "成交量": 778.0,
        "成交额": 18146.0,
        "振幅": 1.97,
        "换手率": 0.04,
        "symbol": "105.MBNKP"
    },
    {
        "序号": 7940,
        "name": "SPDR DoubleLine Short Duration ",
        "最新价": 46.62,
        "涨跌额": -0.04,
        "涨跌幅": -0.09,
        "开盘价": 46.6,
        "最高价": 46.63,
        "最低价": 46.59,
        "昨收价": 46.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 11843.0,
        "成交额": 552144.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.STOT"
    },
    {
        "序号": 7941,
        "name": "Tekla World Healthcare Fund",
        "最新价": 11.62,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.59,
        "最高价": 11.69,
        "最低价": 11.56,
        "昨收价": 11.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 140384.0,
        "成交额": 1632352.0,
        "振幅": 1.12,
        "换手率": null,
        "symbol": "106.THW"
    },
    {
        "序号": 7942,
        "name": "Nuveen New Jersey Quality Munic",
        "最新价": 11.59,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.56,
        "最高价": 11.61,
        "最低价": 11.56,
        "昨收价": 11.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 105993.0,
        "成交额": 1226582.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "106.NXJ"
    },
    {
        "序号": 7943,
        "name": "VanEck Digital India ETF",
        "最新价": 34.59,
        "涨跌额": -0.03,
        "涨跌幅": -0.09,
        "开盘价": 34.53,
        "最高价": 34.59,
        "最低价": 34.53,
        "昨收价": 34.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 642.0,
        "成交额": 22168.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.DGIN"
    },
    {
        "序号": 7944,
        "name": "Franklin High Yield Corporate E",
        "最新价": 23.04,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 23.03,
        "最高价": 23.04,
        "最低价": 22.98,
        "昨收价": 23.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 27031.0,
        "成交额": 621906.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.FLHY"
    },
    {
        "序号": 7945,
        "name": "Principal Real Estate Active Op",
        "最新价": 23.01,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.98,
        "最高价": 23.02,
        "最低价": 22.88,
        "昨收价": 23.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1568.0,
        "成交额": 36036.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.BYRE"
    },
    {
        "序号": 7946,
        "name": "Invesco BulletShares 2026 High ",
        "最新价": 23.01,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 23.03,
        "最高价": 23.03,
        "最低价": 22.96,
        "昨收价": 23.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 127591.0,
        "成交额": 2933914.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "105.BSJQ"
    },
    {
        "序号": 7947,
        "name": "BlackRock Municipal Income Fund",
        "最新价": 11.5,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.43,
        "最高价": 11.53,
        "最低价": 11.43,
        "昨收价": 11.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 145943.0,
        "成交额": 1679136.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "106.MUI"
    },
    {
        "序号": 7948,
        "name": "晨星",
        "最新价": 274.76,
        "涨跌额": -0.24,
        "涨跌幅": -0.09,
        "开盘价": 275.0,
        "最高价": 275.97,
        "最低价": 273.33,
        "昨收价": 275.0,
        "总市值": 11732196498.0,
        "市盈率": 165.48,
        "成交量": 72675.0,
        "成交额": 19965307.0,
        "振幅": 0.96,
        "换手率": 0.17,
        "symbol": "105.MORN"
    },
    {
        "序号": 7949,
        "name": "RiverNorth Opportunities Fund I",
        "最新价": 22.68,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.52,
        "最高价": 22.69,
        "最低价": 22.52,
        "昨收价": 22.7,
        "总市值": 88678800.0,
        "市盈率": null,
        "成交量": 1241.0,
        "成交额": 28014.0,
        "振幅": 0.75,
        "换手率": 0.03,
        "symbol": "106.RIV_A"
    },
    {
        "序号": 7950,
        "name": "伊瑞保险",
        "最新价": 305.75,
        "涨跌额": -0.27,
        "涨跌幅": -0.09,
        "开盘价": 307.52,
        "最高价": 307.52,
        "最低价": 302.33,
        "昨收价": 306.02,
        "总市值": 14123084758.0,
        "市盈率": 35.25,
        "成交量": 58763.0,
        "成交额": 17947445.0,
        "振幅": 1.7,
        "换手率": 0.13,
        "symbol": "105.ERIE"
    },
    {
        "序号": 7951,
        "name": "Cabana Target Drawdown 10 ETF",
        "最新价": 22.61,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.59,
        "最高价": 22.63,
        "最低价": 22.53,
        "昨收价": 22.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 34008.0,
        "成交额": 767554.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.TDSC"
    },
    {
        "序号": 7952,
        "name": "阿尼卡医疗",
        "最新价": 22.46,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.36,
        "最高价": 22.98,
        "最低价": 22.23,
        "昨收价": 22.48,
        "总市值": 328834300.0,
        "市盈率": -13.38,
        "成交量": 48944.0,
        "成交额": 1102729.0,
        "振幅": 3.34,
        "换手率": 0.33,
        "symbol": "105.ANIK"
    },
    {
        "序号": 7953,
        "name": "帕沃英蒂格盛",
        "最新价": 78.37,
        "涨跌额": -0.07,
        "涨跌幅": -0.09,
        "开盘价": 78.19,
        "最高价": 79.93,
        "最低价": 77.84,
        "昨收价": 78.44,
        "总市值": 4455928780.0,
        "市盈率": 69.32,
        "成交量": 822388.0,
        "成交额": 64727624.0,
        "振幅": 2.66,
        "换手率": 1.45,
        "symbol": "105.POWI"
    },
    {
        "序号": 7954,
        "name": "Oxus Acquisition Corp-A",
        "最新价": 11.19,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.21,
        "最高价": 11.21,
        "最低价": 11.19,
        "昨收价": 11.2,
        "总市值": 73428422.0,
        "市盈率": -54.8,
        "成交量": 727.0,
        "成交额": 8146.0,
        "振幅": 0.18,
        "换手率": 0.01,
        "symbol": "105.OXUS"
    },
    {
        "序号": 7955,
        "name": "BlackRock MuniHoldings New Jers",
        "最新价": 11.17,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.15,
        "最高价": 11.21,
        "最低价": 11.12,
        "昨收价": 11.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 178221.0,
        "成交额": 1991123.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "106.MUJ"
    },
    {
        "序号": 7956,
        "name": "Goldman Sachs Bloomberg Clean E",
        "最新价": 33.5,
        "涨跌额": -0.03,
        "涨跌幅": -0.09,
        "开盘价": 33.37,
        "最高价": 33.5,
        "最低价": 33.32,
        "昨收价": 33.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1113.0,
        "成交额": 37153.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.GCLN"
    },
    {
        "序号": 7957,
        "name": "Virtus Reaves Utilities ETF",
        "最新价": 44.55,
        "涨跌额": -0.04,
        "涨跌幅": -0.09,
        "开盘价": 44.51,
        "最高价": 44.55,
        "最低价": 44.33,
        "昨收价": 44.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 2584.0,
        "成交额": 114779.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.UTES"
    },
    {
        "序号": 7958,
        "name": "Mairs & Power Minnesota Municip",
        "最新价": 22.11,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.07,
        "最高价": 22.11,
        "最低价": 22.07,
        "昨收价": 22.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 4325.0,
        "成交额": 95516.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.MINN"
    },
    {
        "序号": 7959,
        "name": "Digital Realty Trust Inc Series",
        "最新价": 22.09,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.1,
        "最高价": 22.2,
        "最低价": 22.05,
        "昨收价": 22.11,
        "总市值": 176720000.0,
        "市盈率": null,
        "成交量": 36799.0,
        "成交额": 814234.0,
        "振幅": 0.68,
        "换手率": 0.46,
        "symbol": "106.DLR_J"
    },
    {
        "序号": 7960,
        "name": "Global Partner Acquisition Corp",
        "最新价": 11.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.02,
        "最高价": 11.02,
        "最低价": 11.02,
        "昨收价": 11.03,
        "总市值": 125977543.0,
        "市盈率": 20.02,
        "成交量": 695.0,
        "成交额": 7659.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.GPAC"
    },
    {
        "序号": 7961,
        "name": "Schwab Fundamental Internationa",
        "最新价": 33.03,
        "涨跌额": -0.03,
        "涨跌幅": -0.09,
        "开盘价": 32.92,
        "最高价": 33.12,
        "最低价": 32.87,
        "昨收价": 33.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 228079.0,
        "成交额": 7519947.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.FNDC"
    },
    {
        "序号": 7962,
        "name": "Horizon Kinetics SPAC Active ET",
        "最新价": 99.08,
        "涨跌额": -0.09,
        "涨跌幅": -0.09,
        "开盘价": 99.08,
        "最高价": 99.08,
        "最低价": 99.08,
        "昨收价": 99.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 198.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SPAQ"
    },
    {
        "序号": 7963,
        "name": "iShares Yield Optimized Bond ET",
        "最新价": 22.01,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 22.0,
        "最高价": 22.01,
        "最低价": 21.96,
        "昨收价": 22.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 25787.0,
        "成交额": 566782.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.BYLD"
    },
    {
        "序号": 7964,
        "name": "联合合众银行(俄亥俄州)",
        "最新价": 11.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.01,
        "最高价": 11.03,
        "最低价": 10.89,
        "昨收价": 11.01,
        "总市值": 62881445.0,
        "市盈率": 7.09,
        "成交量": 3184.0,
        "成交额": 34930.0,
        "振幅": 1.27,
        "换手率": 0.06,
        "symbol": "105.UBCP"
    },
    {
        "序号": 7965,
        "name": "Quadro Acquisition One Corp Uni",
        "最新价": 10.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.99,
        "最高价": 10.99,
        "最低价": 10.99,
        "昨收价": 11.0,
        "总市值": 96691701.0,
        "市盈率": 33.55,
        "成交量": 101.0,
        "成交额": 1109.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.QDROU"
    },
    {
        "序号": 7966,
        "name": "Investcorp Europe Acquisition C",
        "最新价": 10.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 11.0,
        "最高价": 11.0,
        "最低价": 10.99,
        "昨收价": 11.0,
        "总市值": 303661030.0,
        "市盈率": -66.9,
        "成交量": 355.0,
        "成交额": 3902.0,
        "振幅": 0.09,
        "换手率": 0.0,
        "symbol": "105.IVCB"
    },
    {
        "序号": 7967,
        "name": "MFA Financial Inc",
        "最新价": 10.97,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.95,
        "最高价": 11.05,
        "最低价": 10.9,
        "昨收价": 10.98,
        "总市值": 1118016293.0,
        "市盈率": -389.28,
        "成交量": 735067.0,
        "成交额": 8062756.0,
        "振幅": 1.37,
        "换手率": 0.72,
        "symbol": "106.MFA"
    },
    {
        "序号": 7968,
        "name": "IX Acquisition Corp-A",
        "最新价": 10.97,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.97,
        "最高价": 10.97,
        "最低价": 10.97,
        "昨收价": 10.98,
        "总市值": 114238519.0,
        "市盈率": 20.61,
        "成交量": 50130.0,
        "成交额": 549926.0,
        "振幅": 0.0,
        "换手率": 0.48,
        "symbol": "105.IXAQ"
    },
    {
        "序号": 7969,
        "name": "Digital Realty Trust Inc Series",
        "最新价": 21.93,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.77,
        "最高价": 21.95,
        "最低价": 21.6,
        "昨收价": 21.95,
        "总市值": 263160000.0,
        "市盈率": null,
        "成交量": 21880.0,
        "成交额": 477490.0,
        "振幅": 1.59,
        "换手率": 0.18,
        "symbol": "106.DLR_L"
    },
    {
        "序号": 7970,
        "name": "Two Harbors Investment Corp Ser",
        "最新价": 21.92,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.9,
        "最高价": 21.93,
        "最低价": 21.72,
        "昨收价": 21.94,
        "总市值": 126040000.0,
        "市盈率": null,
        "成交量": 6667.0,
        "成交额": 145725.0,
        "振幅": 0.96,
        "换手率": 0.12,
        "symbol": "106.TWO_A"
    },
    {
        "序号": 7971,
        "name": "Cartesian Growth Corp II-A",
        "最新价": 10.94,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.94,
        "最高价": 10.95,
        "最低价": 10.94,
        "昨收价": 10.95,
        "总市值": 314525000.0,
        "市盈率": 36.04,
        "成交量": 6970.0,
        "成交额": 76289.0,
        "振幅": 0.09,
        "换手率": 0.02,
        "symbol": "105.RENE"
    },
    {
        "序号": 7972,
        "name": "Integrated Rail and Resources A",
        "最新价": 10.91,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.95,
        "最高价": 10.95,
        "最低价": 10.91,
        "昨收价": 10.92,
        "总市值": 133530174.0,
        "市盈率": -604.91,
        "成交量": 6318.0,
        "成交额": 68958.0,
        "振幅": 0.37,
        "换手率": 0.05,
        "symbol": "106.IRRX"
    },
    {
        "序号": 7973,
        "name": "SK Growth Opportunities Corp Un",
        "最新价": 10.88,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.85,
        "最高价": 10.88,
        "最低价": 10.83,
        "昨收价": 10.89,
        "总市值": 285056000.0,
        "市盈率": 35.71,
        "成交量": 2100.0,
        "成交额": 22787.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "105.SKGRU"
    },
    {
        "序号": 7974,
        "name": "AGNC Investment Corp Series G P",
        "最新价": 21.76,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.78,
        "最高价": 21.86,
        "最低价": 21.62,
        "昨收价": 21.78,
        "总市值": 130560.0,
        "市盈率": null,
        "成交量": 39967.0,
        "成交额": 867740.0,
        "振幅": 1.1,
        "换手率": 666.12,
        "symbol": "105.AGNCL"
    },
    {
        "序号": 7975,
        "name": "Global Technology Acquisition C",
        "最新价": 10.87,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.94,
        "最高价": 11.35,
        "最低价": 10.79,
        "昨收价": 10.88,
        "总市值": 77068257.0,
        "市盈率": 12.06,
        "成交量": 5188.0,
        "成交额": 57057.0,
        "振幅": 5.15,
        "换手率": 0.07,
        "symbol": "105.GTAC"
    },
    {
        "序号": 7976,
        "name": "Rover Group Inc-A",
        "最新价": 10.86,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.88,
        "最高价": 10.89,
        "最低价": 10.86,
        "昨收价": 10.87,
        "总市值": 1959544115.0,
        "市盈率": 180.4,
        "成交量": 2891900.0,
        "成交额": 31426421.0,
        "振幅": 0.28,
        "换手率": 1.6,
        "symbol": "105.ROVR"
    },
    {
        "序号": 7977,
        "name": "Cartica Acquisition Corp-A",
        "最新价": 10.85,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.85,
        "昨收价": 10.86,
        "总市值": 108113903.0,
        "市盈率": 11.54,
        "成交量": 586.0,
        "成交额": 6358.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.CITE"
    },
    {
        "序号": 7978,
        "name": "Mindful Conservative ETF",
        "最新价": 21.66,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.67,
        "最高价": 21.67,
        "最低价": 21.64,
        "昨收价": 21.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 18515.0,
        "成交额": 400850.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.MFUL"
    },
    {
        "序号": 7979,
        "name": "First Trust Dorsey Wright DALI ",
        "最新价": 21.65,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.72,
        "最高价": 21.75,
        "最低价": 21.61,
        "昨收价": 21.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 25704.0,
        "成交额": 556747.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.DALI"
    },
    {
        "序号": 7980,
        "name": "Chain Bridge I-A",
        "最新价": 10.81,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.82,
        "最高价": 10.82,
        "最低价": 10.81,
        "昨收价": 10.82,
        "总市值": 106609679.0,
        "市盈率": 10.98,
        "成交量": 20105.0,
        "成交额": 217535.0,
        "振幅": 0.09,
        "换手率": 0.2,
        "symbol": "105.CBRG"
    },
    {
        "序号": 7981,
        "name": "Great Elm Capital Corp",
        "最新价": 10.7,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.8,
        "最高价": 10.8,
        "最低价": 10.68,
        "昨收价": 10.71,
        "总市值": 81340951.0,
        "市盈率": 5.88,
        "成交量": 30162.0,
        "成交额": 323769.0,
        "振幅": 1.12,
        "换手率": 0.4,
        "symbol": "105.GECC"
    },
    {
        "序号": 7982,
        "name": "Welsbach Technology Metals Acqu",
        "最新价": 10.69,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.69,
        "最高价": 10.69,
        "最低价": 10.69,
        "昨收价": 10.7,
        "总市值": 47643481.0,
        "市盈率": -56.99,
        "成交量": 493.0,
        "成交额": 5270.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.WTMA"
    },
    {
        "序号": 7983,
        "name": "Alpha Partners Technology Merge",
        "最新价": 10.69,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.7,
        "最高价": 10.7,
        "最低价": 10.65,
        "昨收价": 10.7,
        "总市值": 242074077.0,
        "市盈率": 23.65,
        "成交量": 7618.0,
        "成交额": 81197.0,
        "振幅": 0.47,
        "换手率": 0.03,
        "symbol": "105.APTM"
    },
    {
        "序号": 7984,
        "name": "Kernel Group Holdings Inc-A",
        "最新价": 10.67,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.67,
        "最高价": 10.67,
        "最低价": 10.67,
        "昨收价": 10.68,
        "总市值": 148683238.0,
        "市盈率": -27.2,
        "成交量": 179.0,
        "成交额": 1909.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.KRNL"
    },
    {
        "序号": 7985,
        "name": "Eagle Point Credit Co Inc Serie",
        "最新价": 21.34,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "开盘价": 21.49,
        "最高价": 21.49,
        "最低价": 21.19,
        "昨收价": 21.36,
        "总市值": 46362281.0,
        "市盈率": null,
        "成交量": 5154.0,
        "成交额": 109526.0,
        "振幅": 1.4,
        "换手率": 0.24,
        "symbol": "106.ECCC"
    },
    {
        "序号": 7986,
        "name": "Atlantic Coastal Acquisition Co",
        "最新价": 10.65,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.66,
        "最高价": 10.66,
        "最低价": 10.65,
        "昨收价": 10.66,
        "总市值": 116465120.0,
        "市盈率": 23.37,
        "成交量": 1577.0,
        "成交额": 16806.0,
        "振幅": 0.09,
        "换手率": 0.01,
        "symbol": "105.ACAB"
    },
    {
        "序号": 7987,
        "name": "Nuveen Arizona Quality Municipa",
        "最新价": 10.6,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.57,
        "最高价": 10.61,
        "最低价": 10.56,
        "昨收价": 10.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 28046.0,
        "成交额": 296676.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "106.NAZ"
    },
    {
        "序号": 7988,
        "name": "two-A",
        "最新价": 10.58,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.58,
        "最高价": 10.58,
        "最低价": 10.56,
        "昨收价": 10.59,
        "总市值": 109602325.0,
        "市盈率": 37.43,
        "成交量": 1846.0,
        "成交额": 19513.0,
        "振幅": 0.19,
        "换手率": 0.02,
        "symbol": "106.TWOA"
    },
    {
        "序号": 7989,
        "name": "Global Blockchain Acquisition C",
        "最新价": 10.55,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.55,
        "昨收价": 10.56,
        "总市值": 75874334.0,
        "市盈率": 18.58,
        "成交量": 145.0,
        "成交额": 1529.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GBBK"
    },
    {
        "序号": 7990,
        "name": "Compass Digital Acquisition Cor",
        "最新价": 10.55,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.55,
        "最高价": 10.55,
        "最低价": 10.53,
        "昨收价": 10.56,
        "总市值": 110825113.0,
        "市盈率": 15.9,
        "成交量": 3410.0,
        "成交额": 35920.0,
        "振幅": 0.19,
        "换手率": 0.03,
        "symbol": "105.CDAQ"
    },
    {
        "序号": 7991,
        "name": "Global Medical REIT Inc",
        "最新价": 10.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.53,
        "最高价": 10.57,
        "最低价": 10.42,
        "昨收价": 10.54,
        "总市值": 690398850.0,
        "市盈率": 31.64,
        "成交量": 309363.0,
        "成交额": 3251340.0,
        "振幅": 1.42,
        "换手率": 0.47,
        "symbol": "106.GMRE"
    },
    {
        "序号": 7992,
        "name": "Cetus Capital Acquisition Corp-",
        "最新价": 10.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.54,
        "最高价": 10.55,
        "最低价": 10.53,
        "昨收价": 10.54,
        "总市值": 79310644.0,
        "市盈率": null,
        "成交量": 2144.0,
        "成交额": 22603.0,
        "振幅": 0.19,
        "换手率": 0.03,
        "symbol": "105.CETU"
    },
    {
        "序号": 7993,
        "name": "Four Leaf Acquisition Corp-A",
        "最新价": 10.52,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.53,
        "最高价": 10.53,
        "最低价": 10.52,
        "昨收价": 10.53,
        "总市值": 71856439.0,
        "市盈率": null,
        "成交量": 1858.0,
        "成交额": 19554.0,
        "振幅": 0.09,
        "换手率": 0.03,
        "symbol": "105.FORL"
    },
    {
        "序号": 7994,
        "name": "dMY Squared Technology Group In",
        "最新价": 10.52,
        "涨跌额": -0.01,
        "涨跌幅": -0.09,
        "开盘价": 10.52,
        "最高价": 10.52,
        "最低价": 10.52,
        "昨收价": 10.53,
        "总市值": 83094850.0,
        "市盈率": null,
        "成交量": 2613.0,
        "成交额": 27497.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "107.DMYY"
    },
    {
        "序号": 7995,
        "name": "Rithm Capital Corp",
        "最新价": 10.5,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.46,
        "最高价": 10.59,
        "最低价": 10.45,
        "昨收价": 10.51,
        "总市值": 5073747641.0,
        "市盈率": 6.41,
        "成交量": 2738911.0,
        "成交额": 28771971.0,
        "振幅": 1.33,
        "换手率": 0.57,
        "symbol": "106.RITM"
    },
    {
        "序号": 7996,
        "name": "Parsons Corp",
        "最新价": 62.96,
        "涨跌额": -0.06,
        "涨跌幅": -0.1,
        "开盘价": 63.14,
        "最高价": 63.35,
        "最低价": 62.5,
        "昨收价": 63.02,
        "总市值": 6603807474.0,
        "市盈率": 45.74,
        "成交量": 348210.0,
        "成交额": 21902633.0,
        "振幅": 1.35,
        "换手率": 0.33,
        "symbol": "106.PSN"
    },
    {
        "序号": 7997,
        "name": "Vinci Partners Investments Ltd-",
        "最新价": 10.49,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.5,
        "最高价": 10.8,
        "最低价": 10.36,
        "昨收价": 10.5,
        "总市值": 565117972.0,
        "市盈率": 13.06,
        "成交量": 16060.0,
        "成交额": 169969.0,
        "振幅": 4.19,
        "换手率": 0.03,
        "symbol": "105.VINP"
    },
    {
        "序号": 7998,
        "name": "贝莱德",
        "最新价": 744.73,
        "涨跌额": -0.71,
        "涨跌幅": -0.1,
        "开盘价": 743.15,
        "最高价": 747.79,
        "最低价": 742.22,
        "昨收价": 745.44,
        "总市值": 110787875028.0,
        "市盈率": 20.57,
        "成交量": 456111.0,
        "成交额": 339568960.0,
        "振幅": 0.75,
        "换手率": 0.31,
        "symbol": "106.BLK"
    },
    {
        "序号": 7999,
        "name": "Opy Acquisition Corp I-A",
        "最新价": 10.44,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.44,
        "最高价": 10.44,
        "最低价": 10.44,
        "昨收价": 10.45,
        "总市值": 55189317.0,
        "市盈率": -208.61,
        "成交量": 155.0,
        "成交额": 1618.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.OHAA"
    },
    {
        "序号": 8000,
        "name": "AltC Acquisition Corp-A",
        "最新价": 10.36,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.4,
        "最高价": 10.4,
        "最低价": 10.36,
        "昨收价": 10.37,
        "总市值": 446521398.0,
        "市盈率": 36.97,
        "成交量": 2009.0,
        "成交额": 20841.0,
        "振幅": 0.39,
        "换手率": 0.0,
        "symbol": "106.ALCC"
    },
    {
        "序号": 8001,
        "name": "新兴市场美元政府债指数ETF",
        "最新价": 62.12,
        "涨跌额": -0.06,
        "涨跌幅": -0.1,
        "开盘价": 62.12,
        "最高价": 62.16,
        "最低价": 61.89,
        "昨收价": 62.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 503392.0,
        "成交额": 31243777.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "105.VWOB"
    },
    {
        "序号": 8002,
        "name": "史赛克",
        "最新价": 289.1,
        "涨跌额": -0.28,
        "涨跌幅": -0.1,
        "开盘价": 288.85,
        "最高价": 289.85,
        "最低价": 286.4,
        "昨收价": 289.38,
        "总市值": 109840122634.0,
        "市盈率": 42.49,
        "成交量": 1078877.0,
        "成交额": 311426144.0,
        "振幅": 1.19,
        "换手率": 0.28,
        "symbol": "106.SYK"
    },
    {
        "序号": 8003,
        "name": "英特格拉生命科学",
        "最新价": 41.27,
        "涨跌额": -0.04,
        "涨跌幅": -0.1,
        "开盘价": 41.31,
        "最高价": 41.38,
        "最低价": 40.72,
        "昨收价": 41.31,
        "总市值": 3226315390.0,
        "市盈率": 31.99,
        "成交量": 286311.0,
        "成交额": 11797571.0,
        "振幅": 1.6,
        "换手率": 0.37,
        "symbol": "105.IART"
    },
    {
        "序号": 8004,
        "name": "劲量控股",
        "最新价": 30.93,
        "涨跌额": -0.03,
        "涨跌幅": -0.1,
        "开盘价": 31.0,
        "最高价": 31.21,
        "最低价": 30.43,
        "昨收价": 30.96,
        "总市值": 2214010475.0,
        "市盈率": 15.76,
        "成交量": 441312.0,
        "成交额": 13605901.0,
        "振幅": 2.52,
        "换手率": 0.62,
        "symbol": "106.ENR"
    },
    {
        "序号": 8005,
        "name": "BlackRock MuniHoldings New York",
        "最新价": 10.29,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.28,
        "最高价": 10.33,
        "最低价": 10.27,
        "昨收价": 10.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 57251.0,
        "成交额": 589096.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "106.MHN"
    },
    {
        "序号": 8006,
        "name": "Bowen Acquisition Corp",
        "最新价": 10.28,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.28,
        "最高价": 10.28,
        "最低价": 10.28,
        "昨收价": 10.29,
        "总市值": 94231620.0,
        "市盈率": null,
        "成交量": 29785.0,
        "成交额": 306189.0,
        "振幅": 0.0,
        "换手率": 0.32,
        "symbol": "105.BOWN"
    },
    {
        "序号": 8007,
        "name": "Annovis Bio Inc",
        "最新价": 10.28,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.1,
        "最高价": 10.7,
        "最低价": 9.44,
        "昨收价": 10.29,
        "总市值": 105496166.0,
        "市盈率": -2.53,
        "成交量": 288458.0,
        "成交额": 2887522.0,
        "振幅": 12.24,
        "换手率": 2.81,
        "symbol": "106.ANVS"
    },
    {
        "序号": 8008,
        "name": "SPDR DoubleLine Emerging Market",
        "最新价": 41.1,
        "涨跌额": -0.04,
        "涨跌幅": -0.1,
        "开盘价": 41.13,
        "最高价": 41.13,
        "最低价": 41.05,
        "昨收价": 41.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 5455.0,
        "成交额": 224218.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.EMTL"
    },
    {
        "序号": 8009,
        "name": "美国抗通胀债ETF-Schwab",
        "最新价": 51.36,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 51.22,
        "最高价": 51.37,
        "最低价": 51.17,
        "昨收价": 51.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 2457656.0,
        "成交额": 125972276.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.SCHP"
    },
    {
        "序号": 8010,
        "name": "Invesco Trust for Investment Gr",
        "最新价": 10.26,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.24,
        "最高价": 10.27,
        "最低价": 10.23,
        "昨收价": 10.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 29170.0,
        "成交额": 298927.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.VTN"
    },
    {
        "序号": 8011,
        "name": "Nuveen New York AMT-Free Qualit",
        "最新价": 10.26,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.23,
        "最高价": 10.28,
        "最低价": 10.18,
        "昨收价": 10.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 299622.0,
        "成交额": 3070489.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "106.NRK"
    },
    {
        "序号": 8012,
        "name": "Vishay精密集团",
        "最新价": 30.74,
        "涨跌额": -0.03,
        "涨跌幅": -0.1,
        "开盘价": 30.63,
        "最高价": 31.08,
        "最低价": 30.52,
        "昨收价": 30.77,
        "总市值": 416010045.0,
        "市盈率": 13.72,
        "成交量": 100216.0,
        "成交额": 3079099.0,
        "振幅": 1.82,
        "换手率": 0.74,
        "symbol": "106.VPG"
    },
    {
        "序号": 8013,
        "name": "W. R. Berkley Corp Debentures",
        "最新价": 20.49,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 20.5,
        "最高价": 20.57,
        "最低价": 20.39,
        "昨收价": 20.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 3425.0,
        "成交额": 70112.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "106.WRB_G"
    },
    {
        "序号": 8014,
        "name": "Invesco High Income Trust II",
        "最新价": 10.23,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.2,
        "最高价": 10.24,
        "最低价": 10.19,
        "昨收价": 10.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 11550.0,
        "成交额": 117984.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "106.VLT"
    },
    {
        "序号": 8015,
        "name": "Schwab High Yield Bond ETF",
        "最新价": 51.07,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 51.0,
        "最高价": 51.07,
        "最低价": 50.93,
        "昨收价": 51.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 32444.0,
        "成交额": 1654257.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.SCYB"
    },
    {
        "序号": 8016,
        "name": "美国制商银行",
        "最新价": 132.65,
        "涨跌额": -0.13,
        "涨跌幅": -0.1,
        "开盘价": 133.14,
        "最高价": 134.35,
        "最低价": 131.61,
        "昨收价": 132.78,
        "总市值": 22014638172.0,
        "市盈率": 7.28,
        "成交量": 925111.0,
        "成交额": 122835947.0,
        "振幅": 2.06,
        "换手率": 0.56,
        "symbol": "106.MTB"
    },
    {
        "序号": 8017,
        "name": "BlackRock MuniYield Quality Fun",
        "最新价": 10.18,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.2,
        "最高价": 10.23,
        "最低价": 10.17,
        "昨收价": 10.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 49908.0,
        "成交额": 508443.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "106.MQT"
    },
    {
        "序号": 8018,
        "name": "ProShares S&P MidCap 400 Divide",
        "最新价": 71.13,
        "涨跌额": -0.07,
        "涨跌幅": -0.1,
        "开盘价": 71.13,
        "最高价": 71.52,
        "最低价": 70.86,
        "昨收价": 71.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 41928.0,
        "成交额": 2980301.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.REGL"
    },
    {
        "序号": 8019,
        "name": "DoubleLine Commercial Real Esta",
        "最新价": 50.71,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 50.73,
        "最高价": 50.76,
        "最低价": 50.68,
        "昨收价": 50.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 113451.0,
        "成交额": 5752181.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.DCMB"
    },
    {
        "序号": 8020,
        "name": "安达保险",
        "最新价": 222.91,
        "涨跌额": -0.22,
        "涨跌幅": -0.1,
        "开盘价": 222.9,
        "最高价": 223.45,
        "最低价": 221.64,
        "昨收价": 223.13,
        "总市值": 90945028163.0,
        "市盈率": 12.8,
        "成交量": 2550887.0,
        "成交额": 567784144.0,
        "振幅": 0.81,
        "换手率": 0.63,
        "symbol": "106.CB"
    },
    {
        "序号": 8021,
        "name": "Foundations Dynamic Income ETF",
        "最新价": 10.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.13,
        "最高价": 10.13,
        "最低价": 10.13,
        "昨收价": 10.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 16.0,
        "成交额": 162.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FDTB"
    },
    {
        "序号": 8022,
        "name": "Bentley Systems Inc-B",
        "最新价": 50.59,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 50.48,
        "最高价": 51.12,
        "最低价": 50.25,
        "昨收价": 50.64,
        "总市值": 14970702580.0,
        "市盈率": 86.59,
        "成交量": 1196739.0,
        "成交额": 60523483.0,
        "振幅": 1.72,
        "换手率": 0.4,
        "symbol": "105.BSY"
    },
    {
        "序号": 8023,
        "name": "Dimensional California Municipa",
        "最新价": 50.36,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 50.35,
        "最高价": 50.42,
        "最低价": 50.32,
        "昨收价": 50.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 21846.0,
        "成交额": 1100063.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.DFCA"
    },
    {
        "序号": 8024,
        "name": "Neuberger Berman Municipal Fund",
        "最新价": 10.06,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.06,
        "最高价": 10.09,
        "最低价": 10.04,
        "昨收价": 10.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 89759.0,
        "成交额": 902636.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.NBH"
    },
    {
        "序号": 8025,
        "name": "Nuveen Municipal Credit Opportu",
        "最新价": 10.05,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 10.03,
        "最高价": 10.07,
        "最低价": 9.91,
        "昨收价": 10.06,
        "总市值": 472350000.0,
        "市盈率": null,
        "成交量": 195863.0,
        "成交额": 1955590.0,
        "振幅": 1.59,
        "换手率": 0.42,
        "symbol": "106.NMCO"
    },
    {
        "序号": 8026,
        "name": "EPR Properties Series G Pfd",
        "最新价": 19.99,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 20.0,
        "最高价": 20.11,
        "最低价": 19.92,
        "昨收价": 20.01,
        "总市值": 119940000.0,
        "市盈率": null,
        "成交量": 21271.0,
        "成交额": 426291.0,
        "振幅": 0.95,
        "换手率": 0.35,
        "symbol": "106.EPR_G"
    },
    {
        "序号": 8027,
        "name": "Invesco Ultra Short Duration ET",
        "最新价": 49.93,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 49.94,
        "最高价": 49.95,
        "最低价": 49.92,
        "昨收价": 49.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 1232392.0,
        "成交额": 61537014.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.GSY"
    },
    {
        "序号": 8028,
        "name": "百胜中国",
        "最新价": 39.91,
        "涨跌额": -0.04,
        "涨跌幅": -0.1,
        "开盘价": 40.0,
        "最高价": 40.52,
        "最低价": 39.9,
        "昨收价": 39.95,
        "总市值": 16289889415.0,
        "市盈率": 20.8,
        "成交量": 1542697.0,
        "成交额": 61944920.0,
        "振幅": 1.55,
        "换手率": 0.38,
        "symbol": "106.YUMC"
    },
    {
        "序号": 8029,
        "name": "US Treasury 12 Month Bill ETF",
        "最新价": 49.83,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 49.84,
        "最高价": 49.85,
        "最低价": 49.83,
        "昨收价": 49.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 27317.0,
        "成交额": 1361529.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.OBIL"
    },
    {
        "序号": 8030,
        "name": "新兴市场美元国债ETF-PowerShares",
        "最新价": 19.86,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 19.74,
        "最高价": 19.86,
        "最低价": 19.68,
        "昨收价": 19.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 688164.0,
        "成交额": 13649154.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.PCY"
    },
    {
        "序号": 8031,
        "name": "Ryerson Holding Corp",
        "最新价": 29.49,
        "涨跌额": -0.03,
        "涨跌幅": -0.1,
        "开盘价": 29.44,
        "最高价": 30.15,
        "最低价": 29.28,
        "昨收价": 29.52,
        "总市值": 1007712640.0,
        "市盈率": 10.52,
        "成交量": 233901.0,
        "成交额": 6907997.0,
        "振幅": 2.95,
        "换手率": 0.68,
        "symbol": "106.RYI"
    },
    {
        "序号": 8032,
        "name": "T. Rowe Price Ultra Short-Term ",
        "最新价": 49.14,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 49.16,
        "最高价": 49.17,
        "最低价": 49.14,
        "昨收价": 49.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 6766.0,
        "成交额": 332580.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.TBUX"
    },
    {
        "序号": 8033,
        "name": "JPMorgan High Yield Municipal E",
        "最新价": 49.0,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 49.05,
        "最高价": 49.09,
        "最低价": 48.86,
        "昨收价": 49.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 29080.0,
        "成交额": 1424844.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.JMHI"
    },
    {
        "序号": 8034,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 19.52,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 19.57,
        "最高价": 19.83,
        "最低价": 19.22,
        "昨收价": 19.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 26586.0,
        "成交额": 518166.0,
        "振幅": 3.12,
        "换手率": null,
        "symbol": "105.RILYK"
    },
    {
        "序号": 8035,
        "name": "iShares Morningstar Multi-Asset",
        "最新价": 19.51,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 19.49,
        "最高价": 19.52,
        "最低价": 19.43,
        "昨收价": 19.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 43658.0,
        "成交额": 849957.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.IYLD"
    },
    {
        "序号": 8036,
        "name": "Putnam PanAgora ESG Emerging Ma",
        "最新价": 19.5,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 19.51,
        "最高价": 19.51,
        "最低价": 19.5,
        "昨收价": 19.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 428.0,
        "成交额": 8350.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.PPEM"
    },
    {
        "序号": 8037,
        "name": "Janus Henderson Short Duration ",
        "最新价": 48.17,
        "涨跌额": -0.05,
        "涨跌幅": -0.1,
        "开盘价": 48.15,
        "最高价": 48.19,
        "最低价": 48.15,
        "昨收价": 48.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 195319.0,
        "成交额": 9409424.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.VNLA"
    },
    {
        "序号": 8038,
        "name": "KeyCorp Series G Pfd",
        "最新价": 19.26,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "开盘价": 19.25,
        "最高价": 19.43,
        "最低价": 19.13,
        "昨收价": 19.28,
        "总市值": 8667000.0,
        "市盈率": null,
        "成交量": 39913.0,
        "成交额": 768134.0,
        "振幅": 1.56,
        "换手率": 8.87,
        "symbol": "106.KEY_K"
    },
    {
        "序号": 8039,
        "name": "Citigroup Capital VIII Trust Pr",
        "最新价": 28.85,
        "涨跌额": -0.03,
        "涨跌幅": -0.1,
        "开盘价": 28.83,
        "最高价": 29.05,
        "最低价": 28.83,
        "昨收价": 28.88,
        "总市值": 55215493767.0,
        "市盈率": 4.07,
        "成交量": 99255.0,
        "成交额": 2865236.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "106.C_N"
    },
    {
        "序号": 8040,
        "name": "Companhia Paranaense de Energia",
        "最新价": 9.6,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 9.6,
        "最高价": 9.71,
        "最低价": 9.55,
        "昨收价": 9.61,
        "总市值": 28477821600.0,
        "市盈率": 70.03,
        "成交量": 739039.0,
        "成交额": 7099996.0,
        "振幅": 1.66,
        "换手率": 0.02,
        "symbol": "106.ELP"
    },
    {
        "序号": 8041,
        "name": "Invesco California Value Munici",
        "最新价": 9.54,
        "涨跌额": -0.01,
        "涨跌幅": -0.1,
        "开盘价": 9.54,
        "最高价": 9.55,
        "最低价": 9.51,
        "昨收价": 9.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 303395.0,
        "成交额": 2890575.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "106.VCV"
    },
    {
        "序号": 8042,
        "name": "ALPS Medical Breakthroughs ETF",
        "最新价": 28.62,
        "涨跌额": -0.03,
        "涨跌幅": -0.1,
        "开盘价": 28.98,
        "最高价": 29.03,
        "最低价": 28.62,
        "昨收价": 28.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 17340.0,
        "成交额": 498835.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "107.SBIO"
    },
    {
        "序号": 8043,
        "name": "Healthpeak Properties Inc",
        "最新价": 18.75,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 18.65,
        "最高价": 18.83,
        "最低价": 18.55,
        "昨收价": 18.77,
        "总市值": 10257644138.0,
        "市盈率": 42.46,
        "成交量": 6310199.0,
        "成交额": 118129247.0,
        "振幅": 1.49,
        "换手率": 1.15,
        "symbol": "106.PEAK"
    },
    {
        "序号": 8044,
        "name": "First Trust Municipal High Inco",
        "最新价": 46.83,
        "涨跌额": -0.05,
        "涨跌幅": -0.11,
        "开盘价": 46.86,
        "最高价": 46.9,
        "最低价": 46.65,
        "昨收价": 46.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 98024.0,
        "成交额": 4583399.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "105.FMHI"
    },
    {
        "序号": 8045,
        "name": "Highland Income Fund Series A P",
        "最新价": 18.55,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 18.43,
        "最高价": 18.61,
        "最低价": 18.43,
        "昨收价": 18.57,
        "总市值": 107590000.0,
        "市盈率": null,
        "成交量": 2971.0,
        "成交额": 54918.0,
        "振幅": 0.97,
        "换手率": 0.05,
        "symbol": "106.HFRO_A"
    },
    {
        "序号": 8046,
        "name": "巴瑞特商业服务",
        "最新价": 111.07,
        "涨跌额": -0.12,
        "涨跌幅": -0.11,
        "开盘价": 111.42,
        "最高价": 111.69,
        "最低价": 110.21,
        "昨收价": 111.19,
        "总市值": 734911649.0,
        "市盈率": 15.45,
        "成交量": 29607.0,
        "成交额": 3290249.0,
        "振幅": 1.33,
        "换手率": 0.45,
        "symbol": "105.BBSI"
    },
    {
        "序号": 8047,
        "name": "VanEck Office and Commercial RE",
        "最新价": 36.95,
        "涨跌额": -0.04,
        "涨跌幅": -0.11,
        "开盘价": 36.59,
        "最高价": 36.95,
        "最低价": 36.59,
        "昨收价": 36.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 145.0,
        "成交额": 5305.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.DESK"
    },
    {
        "序号": 8048,
        "name": "阿彻丹尼尔斯米德兰",
        "最新价": 73.75,
        "涨跌额": -0.08,
        "涨跌幅": -0.11,
        "开盘价": 74.13,
        "最高价": 74.69,
        "最低价": 73.7,
        "昨收价": 73.83,
        "总市值": 39336867556.0,
        "市盈率": 9.99,
        "成交量": 2903785.0,
        "成交额": 214864566.0,
        "振幅": 1.34,
        "换手率": 0.54,
        "symbol": "106.ADM"
    },
    {
        "序号": 8049,
        "name": "Cohen & Steers Limited Duration",
        "最新价": 18.43,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 18.4,
        "最高价": 18.45,
        "最低价": 18.29,
        "昨收价": 18.45,
        "总市值": 531347589.0,
        "市盈率": null,
        "成交量": 84081.0,
        "成交额": 1544393.0,
        "振幅": 0.87,
        "换手率": 0.29,
        "symbol": "106.LDP"
    },
    {
        "序号": 8050,
        "name": "The Gabelli Global Utility & In",
        "最新价": 45.8,
        "涨跌额": -0.05,
        "涨跌幅": -0.11,
        "开盘价": 45.21,
        "最高价": 46.4,
        "最低价": 45.21,
        "昨收价": 45.85,
        "总市值": 943251.0,
        "市盈率": null,
        "成交量": 2612.0,
        "成交额": 119243.0,
        "振幅": 2.6,
        "换手率": 12.68,
        "symbol": "107.GLU_A"
    },
    {
        "序号": 8051,
        "name": "ETRACS Monthly Pay 1.5X Leverag",
        "最新价": 18.31,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 18.31,
        "最高价": 18.31,
        "最低价": 18.27,
        "昨收价": 18.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 1439.0,
        "成交额": 26317.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.CEFD"
    },
    {
        "序号": 8052,
        "name": "JPMorgan Inflation Managed Bond",
        "最新价": 45.71,
        "涨跌额": -0.05,
        "涨跌幅": -0.11,
        "开盘价": 45.72,
        "最高价": 45.73,
        "最低价": 45.64,
        "昨收价": 45.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 6205.0,
        "成交额": 283389.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.JCPI"
    },
    {
        "序号": 8053,
        "name": "KKR & Co Inc Notes",
        "最新价": 18.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 18.25,
        "最高价": 18.25,
        "最低价": 18.1,
        "昨收价": 18.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 8406.0,
        "成交额": 152521.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.KKRS"
    },
    {
        "序号": 8054,
        "name": "Fidelity Sustainable High Yield",
        "最新价": 45.53,
        "涨跌额": -0.05,
        "涨跌幅": -0.11,
        "开盘价": 45.49,
        "最高价": 45.59,
        "最低价": 45.43,
        "昨收价": 45.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 4652.0,
        "成交额": 211558.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.FSYD"
    },
    {
        "序号": 8055,
        "name": "BondBloxx USD High Yield Bond C",
        "最新价": 36.37,
        "涨跌额": -0.04,
        "涨跌幅": -0.11,
        "开盘价": 36.48,
        "最高价": 36.48,
        "最低价": 36.37,
        "昨收价": 36.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 204.0,
        "成交额": 7438.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.XHYC"
    },
    {
        "序号": 8056,
        "name": "Beyond Meat Inc",
        "最新价": 9.09,
        "涨跌额": -0.01,
        "涨跌幅": -0.11,
        "开盘价": 9.0,
        "最高价": 9.44,
        "最低价": 8.92,
        "昨收价": 9.1,
        "总市值": 586676836.0,
        "市盈率": -2.35,
        "成交量": 2684253.0,
        "成交额": 24589987.0,
        "振幅": 5.71,
        "换手率": 4.16,
        "symbol": "105.BYND"
    },
    {
        "序号": 8057,
        "name": "Roundhill S&P Dividend Monarchs",
        "最新价": 27.22,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 27.24,
        "最高价": 27.34,
        "最低价": 27.18,
        "昨收价": 27.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 1014.0,
        "成交额": 27617.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.KNGS"
    },
    {
        "序号": 8058,
        "name": "WESCO International Inc Series ",
        "最新价": 27.08,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 27.17,
        "最高价": 27.19,
        "最低价": 27.08,
        "昨收价": 27.11,
        "总市值": 585253.0,
        "市盈率": null,
        "成交量": 23212.0,
        "成交额": 629616.0,
        "振幅": 0.41,
        "换手率": 107.4,
        "symbol": "106.WCC_A"
    },
    {
        "序号": 8059,
        "name": "Anfield Universal Fixed Income ",
        "最新价": 9.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.11,
        "开盘价": 9.06,
        "最高价": 9.06,
        "最低价": 9.01,
        "昨收价": 9.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 39801.0,
        "成交额": 359025.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.AFIF"
    },
    {
        "序号": 8060,
        "name": "Euronav NV",
        "最新价": 17.94,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 17.98,
        "最高价": 17.98,
        "最低价": 17.9,
        "昨收价": 17.96,
        "总市值": 3619996564.0,
        "市盈率": 6.15,
        "成交量": 1735926.0,
        "成交额": 31140954.0,
        "振幅": 0.45,
        "换手率": 0.86,
        "symbol": "106.EURN"
    },
    {
        "序号": 8061,
        "name": "Brookstone Dividend Stock ETF",
        "最新价": 26.84,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.83,
        "最高价": 26.89,
        "最低价": 26.74,
        "昨收价": 26.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 11808.0,
        "成交额": 316999.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.BAMD"
    },
    {
        "序号": 8062,
        "name": "The Allstate Corp Series J Pfd",
        "最新价": 26.83,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.81,
        "最高价": 26.89,
        "最低价": 26.63,
        "昨收价": 26.86,
        "总市值": 643920.0,
        "市盈率": null,
        "成交量": 128405.0,
        "成交额": 3437507.0,
        "振幅": 0.97,
        "换手率": 535.02,
        "symbol": "106.ALL_J"
    },
    {
        "序号": 8063,
        "name": "美国食品控股",
        "最新价": 44.64,
        "涨跌额": -0.05,
        "涨跌幅": -0.11,
        "开盘价": 44.65,
        "最高价": 44.93,
        "最低价": 44.41,
        "昨收价": 44.69,
        "总市值": 10974813192.0,
        "市盈率": 24.28,
        "成交量": 1165029.0,
        "成交额": 51993642.0,
        "振幅": 1.16,
        "换手率": 0.47,
        "symbol": "106.USFD"
    },
    {
        "序号": 8064,
        "name": "iShares Broad USD High Yield Co",
        "最新价": 35.66,
        "涨跌额": -0.04,
        "涨跌幅": -0.11,
        "开盘价": 35.64,
        "最高价": 35.68,
        "最低价": 35.55,
        "昨收价": 35.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 5952957.0,
        "成交额": 211989914.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.USHY"
    },
    {
        "序号": 8065,
        "name": "First Trust New York Municipal ",
        "最新价": 26.74,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.83,
        "最高价": 26.83,
        "最低价": 26.66,
        "昨收价": 26.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 1245.0,
        "成交额": 33319.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.FMNY"
    },
    {
        "序号": 8066,
        "name": "iShares New York Muni Bond ETF",
        "最新价": 53.43,
        "涨跌额": -0.06,
        "涨跌幅": -0.11,
        "开盘价": 53.4,
        "最高价": 53.46,
        "最低价": 53.32,
        "昨收价": 53.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 50885.0,
        "成交额": 2717177.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.NYF"
    },
    {
        "序号": 8067,
        "name": "快扣",
        "最新价": 62.29,
        "涨跌额": -0.07,
        "涨跌幅": -0.11,
        "开盘价": 62.31,
        "最高价": 62.47,
        "最低价": 62.0,
        "昨收价": 62.36,
        "总市值": 35604964000.0,
        "市盈率": 31.39,
        "成交量": 3136730.0,
        "成交额": 195272705.0,
        "振幅": 0.75,
        "换手率": 0.55,
        "symbol": "105.FAST"
    },
    {
        "序号": 8068,
        "name": "美国运通",
        "最新价": 168.43,
        "涨跌额": -0.19,
        "涨跌幅": -0.11,
        "开盘价": 168.34,
        "最高价": 169.13,
        "最低价": 167.57,
        "昨收价": 168.62,
        "总市值": 122742617534.0,
        "市盈率": 15.32,
        "成交量": 2681333.0,
        "成交额": 451540224.0,
        "振幅": 0.93,
        "换手率": 0.37,
        "symbol": "106.AXP"
    },
    {
        "序号": 8069,
        "name": "Empire Petroleum Corp",
        "最新价": 8.85,
        "涨跌额": -0.01,
        "涨跌幅": -0.11,
        "开盘价": 8.88,
        "最高价": 8.94,
        "最低价": 8.75,
        "昨收价": 8.86,
        "总市值": 202596678.0,
        "市盈率": -20.34,
        "成交量": 11130.0,
        "成交额": 98535.0,
        "振幅": 2.14,
        "换手率": 0.05,
        "symbol": "107.EP"
    },
    {
        "序号": 8070,
        "name": "汤森路透",
        "最新价": 141.38,
        "涨跌额": -0.16,
        "涨跌幅": -0.11,
        "开盘价": 141.12,
        "最高价": 141.89,
        "最低价": 140.66,
        "昨收价": 141.54,
        "总市值": 64397329173.0,
        "市盈率": 28.81,
        "成交量": 158759.0,
        "成交额": 22463165.0,
        "振幅": 0.87,
        "换手率": 0.03,
        "symbol": "106.TRI"
    },
    {
        "序号": 8071,
        "name": "高收益封基ETF-PowerShares",
        "最新价": 17.64,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 17.69,
        "最高价": 17.71,
        "最低价": 17.62,
        "昨收价": 17.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 106232.0,
        "成交额": 1875002.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.PCEF"
    },
    {
        "序号": 8072,
        "name": "FlexShares Global Quality Real ",
        "最新价": 52.89,
        "涨跌额": -0.06,
        "涨跌幅": -0.11,
        "开盘价": 52.81,
        "最高价": 52.94,
        "最低价": 52.52,
        "昨收价": 52.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 13184.0,
        "成交额": 696262.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.GQRE"
    },
    {
        "序号": 8073,
        "name": "Virtus WMC International Divide",
        "最新价": 26.44,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.42,
        "最高价": 26.44,
        "最低价": 26.42,
        "昨收价": 26.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 633.0,
        "成交额": 16734.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.VWID"
    },
    {
        "序号": 8074,
        "name": "Wells Fargo & Co Series L Pfd",
        "最新价": 1141.0,
        "涨跌额": -1.3,
        "涨跌幅": -0.11,
        "开盘价": 1141.77,
        "最高价": 1144.0,
        "最低价": 1133.5,
        "昨收价": 1142.3,
        "总市值": 4527466321.0,
        "市盈率": null,
        "成交量": 9254.0,
        "成交额": 10557849.0,
        "振幅": 0.92,
        "换手率": 0.23,
        "symbol": "106.WFC_L"
    },
    {
        "序号": 8075,
        "name": "iShares LifePath Retirement ETF",
        "最新价": 26.28,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.28,
        "最高价": 26.28,
        "最低价": 26.28,
        "昨收价": 26.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 67.0,
        "成交额": 1760.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IRTR"
    },
    {
        "序号": 8076,
        "name": "SMART Global Holdings Inc",
        "最新价": 17.51,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 17.42,
        "最高价": 17.9,
        "最低价": 17.42,
        "昨收价": 17.53,
        "总市值": 909098013.0,
        "市盈率": -4.85,
        "成交量": 512597.0,
        "成交额": 9009081.0,
        "振幅": 2.74,
        "换手率": 0.99,
        "symbol": "105.SGH"
    },
    {
        "序号": 8077,
        "name": "RAPT Therapeutics Inc",
        "最新价": 17.48,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "开盘价": 17.34,
        "最高价": 18.11,
        "最低价": 16.99,
        "昨收价": 17.5,
        "总市值": 601245786.0,
        "市盈率": -5.52,
        "成交量": 292764.0,
        "成交额": 5140216.0,
        "振幅": 6.4,
        "换手率": 0.85,
        "symbol": "105.RAPT"
    },
    {
        "序号": 8078,
        "name": "Hartford Multifactor Diversifie",
        "最新价": 26.13,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.06,
        "最高价": 26.13,
        "最低价": 26.06,
        "昨收价": 26.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 1174.0,
        "成交额": 30613.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.RODE"
    },
    {
        "序号": 8079,
        "name": "Monarch ProCap ETF",
        "最新价": 26.07,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.08,
        "最高价": 26.08,
        "最低价": 25.98,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 43516.0,
        "成交额": 1134382.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.MPRO"
    },
    {
        "序号": 8080,
        "name": "PPL能源",
        "最新价": 26.06,
        "涨跌额": -0.03,
        "涨跌幅": -0.11,
        "开盘价": 26.08,
        "最高价": 26.1,
        "最低价": 25.76,
        "昨收价": 26.09,
        "总市值": 19209461577.0,
        "市盈率": 23.51,
        "成交量": 4743118.0,
        "成交额": 123244874.0,
        "振幅": 1.3,
        "换手率": 0.64,
        "symbol": "106.PPL"
    },
    {
        "序号": 8081,
        "name": "Franklin FTSE Hong Kong ETF",
        "最新价": 17.36,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 17.45,
        "最高价": 17.45,
        "最低价": 17.36,
        "昨收价": 17.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 1176.0,
        "成交额": 20496.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.FLHK"
    },
    {
        "序号": 8082,
        "name": "PIMCO Intermediate Municipal Bo",
        "最新价": 52.04,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 52.02,
        "最高价": 52.04,
        "最低价": 51.92,
        "昨收价": 52.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 314191.0,
        "成交额": 16336406.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.MUNI"
    },
    {
        "序号": 8083,
        "name": "Invesco BulletShares 2033 Munic",
        "最新价": 25.99,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 26.0,
        "最高价": 26.02,
        "最低价": 25.99,
        "昨收价": 26.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 2479.0,
        "成交额": 64482.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.BSSX"
    },
    {
        "序号": 8084,
        "name": "Franklin FTSE Saudi Arabia ETF",
        "最新价": 34.65,
        "涨跌额": -0.04,
        "涨跌幅": -0.12,
        "开盘价": 34.65,
        "最高价": 34.65,
        "最低价": 34.65,
        "昨收价": 34.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 434.0,
        "成交额": 15038.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FLSA"
    },
    {
        "序号": 8085,
        "name": "Morgan Stanley Series P Pfd",
        "最新价": 25.96,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.94,
        "最高价": 26.02,
        "最低价": 25.87,
        "昨收价": 25.99,
        "总市值": 1038400.0,
        "市盈率": null,
        "成交量": 51390.0,
        "成交额": 1332276.0,
        "振幅": 0.58,
        "换手率": 128.48,
        "symbol": "106.MS_P"
    },
    {
        "序号": 8086,
        "name": "Travere Therapeutics Inc",
        "最新价": 8.64,
        "涨跌额": -0.01,
        "涨跌幅": -0.12,
        "开盘价": 8.58,
        "最高价": 8.8,
        "最低价": 8.4,
        "昨收价": 8.65,
        "总市值": 649259980.0,
        "市盈率": -7.46,
        "成交量": 1710082.0,
        "成交额": 14814192.0,
        "振幅": 4.62,
        "换手率": 2.28,
        "symbol": "105.TVTX"
    },
    {
        "序号": 8087,
        "name": "iShares US & Intl High Yield Co",
        "最新价": 43.05,
        "涨跌额": -0.05,
        "涨跌幅": -0.12,
        "开盘价": 43.0,
        "最高价": 43.06,
        "最低价": 42.97,
        "昨收价": 43.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 11845.0,
        "成交额": 509480.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.GHYG"
    },
    {
        "序号": 8088,
        "name": "NuStar Energy LP Series C Pfd",
        "最新价": 25.6,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.6,
        "最高价": 25.6,
        "最低价": 25.25,
        "昨收价": 25.63,
        "总市值": 176640000.0,
        "市盈率": null,
        "成交量": 13329.0,
        "成交额": 339211.0,
        "振幅": 1.37,
        "换手率": 0.19,
        "symbol": "106.NS_C"
    },
    {
        "序号": 8089,
        "name": "Virtus Newfleet Short Duration ",
        "最新价": 25.55,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.55,
        "最高价": 25.55,
        "最低价": 25.55,
        "昨收价": 25.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 2555.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SDCP"
    },
    {
        "序号": 8090,
        "name": "First Guaranty Bancshares Serie",
        "最新价": 17.0,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 17.09,
        "最高价": 17.1,
        "最低价": 17.0,
        "昨收价": 17.02,
        "总市值": 586500.0,
        "市盈率": null,
        "成交量": 1914.0,
        "成交额": 32692.0,
        "振幅": 0.59,
        "换手率": 5.55,
        "symbol": "105.FGBIP"
    },
    {
        "序号": 8091,
        "name": "iShares iBonds 2030 Term High Y",
        "最新价": 25.48,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.53,
        "最高价": 25.55,
        "最低价": 25.46,
        "昨收价": 25.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 3458.0,
        "成交额": 88175.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.IBHJ"
    },
    {
        "序号": 8092,
        "name": "燃力士控股",
        "最新价": 50.9,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 50.68,
        "最高价": 51.3,
        "最低价": 50.1,
        "昨收价": 50.96,
        "总市值": 11792258569.0,
        "市盈率": 75.85,
        "成交量": 3450652.0,
        "成交额": 175713892.0,
        "振幅": 2.35,
        "换手率": 1.49,
        "symbol": "105.CELH"
    },
    {
        "序号": 8093,
        "name": "USCF Sustainable Commodity Stra",
        "最新价": 25.43,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.4,
        "最高价": 25.43,
        "最低价": 25.4,
        "昨收价": 25.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 204.0,
        "成交额": 5181.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.ZSC"
    },
    {
        "序号": 8094,
        "name": "Suburban Propane Partners LP",
        "最新价": 16.89,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 16.99,
        "最高价": 17.08,
        "最低价": 16.78,
        "昨收价": 16.91,
        "总市值": 1081213418.0,
        "市盈率": 8.74,
        "成交量": 656970.0,
        "成交额": 11101027.0,
        "振幅": 1.77,
        "换手率": 1.03,
        "symbol": "106.SPH"
    },
    {
        "序号": 8095,
        "name": "达科电子",
        "最新价": 8.43,
        "涨跌额": -0.01,
        "涨跌幅": -0.12,
        "开盘价": 8.44,
        "最高价": 8.8,
        "最低价": 8.37,
        "昨收价": 8.44,
        "总市值": 389298799.0,
        "市盈率": 8.38,
        "成交量": 997680.0,
        "成交额": 8488787.0,
        "振幅": 5.09,
        "换手率": 2.16,
        "symbol": "105.DAKT"
    },
    {
        "序号": 8096,
        "name": "Morgan Stanley Series E Pfd",
        "最新价": 25.27,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.28,
        "最高价": 25.3,
        "最低价": 25.26,
        "昨收价": 25.3,
        "总市值": 871815.0,
        "市盈率": null,
        "成交量": 61381.0,
        "成交额": 1551695.0,
        "振幅": 0.16,
        "换手率": 177.92,
        "symbol": "106.MS_E"
    },
    {
        "序号": 8097,
        "name": "Goose Hollow Enhanced Equity ET",
        "最新价": 25.25,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.25,
        "最高价": 25.25,
        "最低价": 25.25,
        "昨收价": 25.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 8001.0,
        "成交额": 202025.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GHEE"
    },
    {
        "序号": 8098,
        "name": "B. Riley Financial Inc Series A",
        "最新价": 16.83,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 16.77,
        "最高价": 17.25,
        "最低价": 16.75,
        "昨收价": 16.85,
        "总市值": 47696.0,
        "市盈率": null,
        "成交量": 4337.0,
        "成交额": 73388.0,
        "振幅": 2.97,
        "换手率": 153.03,
        "symbol": "105.RILYP"
    },
    {
        "序号": 8099,
        "name": "PMV Adaptive Risk Parity ETF",
        "最新价": 25.22,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.17,
        "最高价": 25.22,
        "最低价": 25.17,
        "昨收价": 25.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 4331.0,
        "成交额": 109062.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.ARP"
    },
    {
        "序号": 8100,
        "name": "Brunswick Corp Notes",
        "最新价": 25.08,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 25.18,
        "最高价": 25.18,
        "最低价": 25.08,
        "昨收价": 25.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1140.0,
        "成交额": 28639.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "106.BC_B"
    },
    {
        "序号": 8101,
        "name": "iRadimed Corp",
        "最新价": 41.73,
        "涨跌额": -0.05,
        "涨跌幅": -0.12,
        "开盘价": 41.25,
        "最高价": 42.39,
        "最低价": 41.25,
        "昨收价": 41.78,
        "总市值": 526030728.0,
        "市盈率": 32.22,
        "成交量": 20929.0,
        "成交额": 873859.0,
        "振幅": 2.73,
        "换手率": 0.17,
        "symbol": "105.IRMD"
    },
    {
        "序号": 8102,
        "name": "First Trust Flexible Municipal ",
        "最新价": 16.6,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 16.58,
        "最高价": 16.65,
        "最低价": 16.58,
        "昨收价": 16.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 3412.0,
        "成交额": 56654.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "105.MFLX"
    },
    {
        "序号": 8103,
        "name": "Fidelity Low Duration Bond Fact",
        "最新价": 49.76,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 50.06,
        "最高价": 50.06,
        "最低价": 49.74,
        "昨收价": 49.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 41881.0,
        "成交额": 2084956.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.FLDR"
    },
    {
        "序号": 8104,
        "name": "短期高收益债ETF-SPDR",
        "最新价": 24.8,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.79,
        "最高价": 24.81,
        "最低价": 24.73,
        "昨收价": 24.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 3190058.0,
        "成交额": 79010855.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.SJNK"
    },
    {
        "序号": 8105,
        "name": "维德思投资",
        "最新价": 206.44,
        "涨跌额": -0.25,
        "涨跌幅": -0.12,
        "开盘价": 206.03,
        "最高价": 209.28,
        "最低价": 205.07,
        "昨收价": 206.69,
        "总市值": 1483269129.0,
        "市盈率": 10.97,
        "成交量": 36553.0,
        "成交额": 7545195.0,
        "振幅": 2.04,
        "换手率": 0.51,
        "symbol": "105.VRTS"
    },
    {
        "序号": 8106,
        "name": "Invesco PureBeta 0-5 Yr US TIPS",
        "最新价": 24.77,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.75,
        "最高价": 24.77,
        "最低价": 24.74,
        "昨收价": 24.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 11634.0,
        "成交额": 288080.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.PBTP"
    },
    {
        "序号": 8107,
        "name": "Air Lease Corp Series A Pfd",
        "最新价": 24.77,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.8,
        "最高价": 24.87,
        "最低价": 24.66,
        "昨收价": 24.8,
        "总市值": 262562000.0,
        "市盈率": null,
        "成交量": 8004.0,
        "成交额": 198281.0,
        "振幅": 0.85,
        "换手率": 0.08,
        "symbol": "106.AL_A"
    },
    {
        "序号": 8108,
        "name": "iShares Edge MSCI Min Vol Globa",
        "最新价": 99.05,
        "涨跌额": -0.12,
        "涨跌幅": -0.12,
        "开盘价": 99.18,
        "最高价": 99.2,
        "最低价": 98.68,
        "昨收价": 99.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 157862.0,
        "成交额": 15625640.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.ACWV"
    },
    {
        "序号": 8109,
        "name": "Textainer Group Holdings Ltd Se",
        "最新价": 24.75,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.72,
        "最高价": 24.8,
        "最低价": 24.72,
        "昨收价": 24.78,
        "总市值": 148500.0,
        "市盈率": null,
        "成交量": 9736.0,
        "成交额": 240902.0,
        "振幅": 0.32,
        "换手率": 162.27,
        "symbol": "106.TGH_A"
    },
    {
        "序号": 8110,
        "name": "Vanguard Ultra-Short Bond ETF",
        "最新价": 49.26,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 49.29,
        "最高价": 49.3,
        "最低价": 49.26,
        "昨收价": 49.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 803391.0,
        "成交额": 39589609.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.VUSB"
    },
    {
        "序号": 8111,
        "name": "Dine Brands Global Inc",
        "最新价": 49.23,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 49.13,
        "最高价": 49.72,
        "最低价": 48.44,
        "昨收价": 49.29,
        "总市值": 760259185.0,
        "市盈率": 10.07,
        "成交量": 172707.0,
        "成交额": 8474070.0,
        "振幅": 2.6,
        "换手率": 1.12,
        "symbol": "106.DIN"
    },
    {
        "序号": 8112,
        "name": "Invesco California AMT-Free Mun",
        "最新价": 24.61,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.6,
        "最高价": 24.63,
        "最低价": 24.49,
        "昨收价": 24.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 227502.0,
        "成交额": 5589842.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.PWZ"
    },
    {
        "序号": 8113,
        "name": "Solaris Oilfield Infrastructure",
        "最新价": 8.18,
        "涨跌额": -0.01,
        "涨跌幅": -0.12,
        "开盘价": 8.25,
        "最高价": 8.25,
        "最低价": 8.11,
        "昨收价": 8.19,
        "总市值": 361991470.0,
        "市盈率": 14.58,
        "成交量": 181194.0,
        "成交额": 1478453.0,
        "振幅": 1.71,
        "换手率": 0.41,
        "symbol": "106.SOI"
    },
    {
        "序号": 8114,
        "name": "Runway Growth Finance Corp Note",
        "最新价": 24.42,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.43,
        "最高价": 24.45,
        "最低价": 24.41,
        "昨收价": 24.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 4160.0,
        "成交额": 101659.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.RWAYL"
    },
    {
        "序号": 8115,
        "name": "抗通胀债ETF-iShares",
        "最新价": 105.49,
        "涨跌额": -0.13,
        "涨跌幅": -0.12,
        "开盘价": 105.23,
        "最高价": 105.51,
        "最低价": 105.11,
        "昨收价": 105.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 1657398.0,
        "成交额": 174527433.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.TIP"
    },
    {
        "序号": 8116,
        "name": "JPMorgan BetaBuilders U.S. TIPS",
        "最新价": 97.23,
        "涨跌额": -0.12,
        "涨跌幅": -0.12,
        "开盘价": 97.23,
        "最高价": 97.23,
        "最低价": 97.23,
        "昨收价": 97.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 194.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BBIP"
    },
    {
        "序号": 8117,
        "name": "Fulton Financial Corp Series A ",
        "最新价": 16.17,
        "涨跌额": -0.02,
        "涨跌幅": -0.12,
        "开盘价": 16.05,
        "最高价": 16.24,
        "最低价": 16.03,
        "昨收价": 16.19,
        "总市值": 3234000.0,
        "市盈率": null,
        "成交量": 12732.0,
        "成交额": 205011.0,
        "振幅": 1.3,
        "换手率": 6.37,
        "symbol": "105.FULTP"
    },
    {
        "序号": 8118,
        "name": "Cambria Global Real Estate ETF",
        "最新价": 24.2,
        "涨跌额": -0.03,
        "涨跌幅": -0.12,
        "开盘价": 24.2,
        "最高价": 24.2,
        "最低价": 24.2,
        "昨收价": 24.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 144.0,
        "成交额": 3484.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BLDG"
    },
    {
        "序号": 8119,
        "name": "Clarivate Plc",
        "最新价": 8.03,
        "涨跌额": -0.01,
        "涨跌幅": -0.12,
        "开盘价": 7.99,
        "最高价": 8.18,
        "最低价": 7.94,
        "昨收价": 8.04,
        "总市值": 5331300742.0,
        "市盈率": 20.82,
        "成交量": 4634808.0,
        "成交额": 37219284.0,
        "振幅": 2.99,
        "换手率": 0.7,
        "symbol": "106.CLVT"
    },
    {
        "序号": 8120,
        "name": "FlexShares High Yield Value-Sco",
        "最新价": 40.11,
        "涨跌额": -0.05,
        "涨跌幅": -0.12,
        "开盘价": 40.04,
        "最高价": 40.11,
        "最低价": 39.99,
        "昨收价": 40.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 190370.0,
        "成交额": 7623442.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.HYGV"
    },
    {
        "序号": 8121,
        "name": "Goldman Sachs Access Inflation ",
        "最新价": 48.09,
        "涨跌额": -0.06,
        "涨跌幅": -0.12,
        "开盘价": 48.0,
        "最高价": 48.09,
        "最低价": 47.95,
        "昨收价": 48.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 5901.0,
        "成交额": 283305.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.GTIP"
    },
    {
        "序号": 8122,
        "name": "默沙东",
        "最新价": 103.75,
        "涨跌额": -0.13,
        "涨跌幅": -0.13,
        "开盘价": 103.79,
        "最高价": 104.01,
        "最低价": 102.65,
        "昨收价": 103.88,
        "总市值": 262904894965.0,
        "市盈率": 57.05,
        "成交量": 7530274.0,
        "成交额": 779307984.0,
        "振幅": 1.31,
        "换手率": 0.3,
        "symbol": "106.MRK"
    },
    {
        "序号": 8123,
        "name": "Xtrackers High Beta High Yield ",
        "最新价": 39.86,
        "涨跌额": -0.05,
        "涨跌幅": -0.13,
        "开盘价": 39.86,
        "最高价": 39.86,
        "最低价": 39.86,
        "昨收价": 39.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 119.0,
        "成交额": 4743.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HYUP"
    },
    {
        "序号": 8124,
        "name": "Western Asset Inflation-Linked ",
        "最新价": 7.96,
        "涨跌额": -0.01,
        "涨跌幅": -0.13,
        "开盘价": 7.97,
        "最高价": 7.97,
        "最低价": 7.92,
        "昨收价": 7.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 56635.0,
        "成交额": 450445.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "106.WIA"
    },
    {
        "序号": 8125,
        "name": "Radcom Ltd",
        "最新价": 7.96,
        "涨跌额": -0.01,
        "涨跌幅": -0.13,
        "开盘价": 7.96,
        "最高价": 7.96,
        "最低价": 7.96,
        "昨收价": 7.97,
        "总市值": 119875602.0,
        "市盈率": 109.48,
        "成交量": 3855.0,
        "成交额": 30666.0,
        "振幅": 0.0,
        "换手率": 0.03,
        "symbol": "105.RDCM"
    },
    {
        "序号": 8126,
        "name": "JPMorgan Sustainable Infrastruc",
        "最新价": 47.75,
        "涨跌额": -0.06,
        "涨跌幅": -0.13,
        "开盘价": 47.75,
        "最高价": 47.75,
        "最低价": 47.75,
        "昨收价": 47.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 205.0,
        "成交额": 9788.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BLLD"
    },
    {
        "序号": 8127,
        "name": "Xtrackers MSCI EAFE High Divide",
        "最新价": 23.71,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 23.62,
        "最高价": 23.77,
        "最低价": 23.62,
        "昨收价": 23.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 246911.0,
        "成交额": 5844964.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.HDEF"
    },
    {
        "序号": 8128,
        "name": "PIMCO Preferred and Capital Sec",
        "最新价": 47.07,
        "涨跌额": -0.06,
        "涨跌幅": -0.13,
        "开盘价": 47.12,
        "最高价": 47.14,
        "最低价": 46.99,
        "昨收价": 47.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 55181.0,
        "成交额": 2600085.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.PRFD"
    },
    {
        "序号": 8129,
        "name": "Citizens Financial Services Inc",
        "最新价": 62.54,
        "涨跌额": -0.08,
        "涨跌幅": -0.13,
        "开盘价": 63.0,
        "最高价": 63.5,
        "最低价": 61.33,
        "昨收价": 62.62,
        "总市值": 294320182.0,
        "市盈率": 16.22,
        "成交量": 28503.0,
        "成交额": 1780179.0,
        "振幅": 3.47,
        "换手率": 0.61,
        "symbol": "105.CZFS"
    },
    {
        "序号": 8130,
        "name": "联合包裹服务",
        "最新价": 156.3,
        "涨跌额": -0.2,
        "涨跌幅": -0.13,
        "开盘价": 156.08,
        "最高价": 157.68,
        "最低价": 155.96,
        "昨收价": 156.5,
        "总市值": 133155969561.0,
        "市盈率": 15.56,
        "成交量": 2845911.0,
        "成交额": 445812176.0,
        "振幅": 1.1,
        "换手率": 0.33,
        "symbol": "106.UPS"
    },
    {
        "序号": 8131,
        "name": "高知特",
        "最新价": 70.33,
        "涨跌额": -0.09,
        "涨跌幅": -0.13,
        "开盘价": 69.94,
        "最高价": 70.56,
        "最低价": 69.94,
        "昨收价": 70.42,
        "总市值": 35264344360.0,
        "市盈率": 16.88,
        "成交量": 1765269.0,
        "成交额": 124128023.0,
        "振幅": 0.88,
        "换手率": 0.35,
        "symbol": "105.CTSH"
    },
    {
        "序号": 8132,
        "name": "Dimensional World ex U.S. Core ",
        "最新价": 23.41,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 23.29,
        "最高价": 23.47,
        "最低价": 23.29,
        "昨收价": 23.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 350767.0,
        "成交额": 8210241.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.DFAX"
    },
    {
        "序号": 8133,
        "name": "Dimensional Short-Duration Fixe",
        "最新价": 46.74,
        "涨跌额": -0.06,
        "涨跌幅": -0.13,
        "开盘价": 46.74,
        "最高价": 46.75,
        "最低价": 46.71,
        "昨收价": 46.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 415955.0,
        "成交额": 19438898.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.DFSD"
    },
    {
        "序号": 8134,
        "name": "FlexShares iBoxx 5 Year Target ",
        "最新价": 23.2,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 23.16,
        "最高价": 23.2,
        "最低价": 23.12,
        "昨收价": 23.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 134456.0,
        "成交额": 3115740.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.TDTF"
    },
    {
        "序号": 8135,
        "name": "Invesco BulletShares 2029 Munic",
        "最新价": 23.18,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 23.16,
        "最高价": 23.2,
        "最低价": 23.11,
        "昨收价": 23.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 25929.0,
        "成交额": 601105.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "105.BSMT"
    },
    {
        "序号": 8136,
        "name": "Virtus Real Asset Income ETF",
        "最新价": 23.12,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 23.1,
        "最高价": 23.14,
        "最低价": 23.03,
        "昨收价": 23.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2371.0,
        "成交额": 54691.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.VRAI"
    },
    {
        "序号": 8137,
        "name": "Compass Group Diversified Holdi",
        "最新价": 22.9,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 22.92,
        "最高价": 22.95,
        "最低价": 22.8,
        "昨收价": 22.93,
        "总市值": 91600000.0,
        "市盈率": null,
        "成交量": 7081.0,
        "成交额": 162024.0,
        "振幅": 0.65,
        "换手率": 0.18,
        "symbol": "106.CODI_A"
    },
    {
        "序号": 8138,
        "name": "BlackRock Enhanced Equity Divid",
        "最新价": 7.63,
        "涨跌额": -0.01,
        "涨跌幅": -0.13,
        "开盘价": 7.63,
        "最高价": 7.65,
        "最低价": 7.6,
        "昨收价": 7.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 489130.0,
        "成交额": 3728896.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "106.BDJ"
    },
    {
        "序号": 8139,
        "name": "First Trust Global Wind Energy ",
        "最新价": 15.25,
        "涨跌额": -0.02,
        "涨跌幅": -0.13,
        "开盘价": 15.26,
        "最高价": 15.32,
        "最低价": 15.19,
        "昨收价": 15.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 22754.0,
        "成交额": 346846.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.FAN"
    },
    {
        "序号": 8140,
        "name": "恩赛因",
        "最新价": 106.7,
        "涨跌额": -0.14,
        "涨跌幅": -0.13,
        "开盘价": 107.15,
        "最高价": 107.83,
        "最低价": 106.34,
        "昨收价": 106.84,
        "总市值": 6023453795.0,
        "市盈率": 24.27,
        "成交量": 213476.0,
        "成交额": 22793655.0,
        "振幅": 1.39,
        "换手率": 0.38,
        "symbol": "105.ENSG"
    },
    {
        "序号": 8141,
        "name": "Pacer Industrial Real Estate ET",
        "最新价": 38.02,
        "涨跌额": -0.05,
        "涨跌幅": -0.13,
        "开盘价": 37.9,
        "最高价": 38.07,
        "最低价": 37.71,
        "昨收价": 38.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 22541.0,
        "成交额": 855034.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.INDS"
    },
    {
        "序号": 8142,
        "name": "TC Energy Corp",
        "最新价": 38.01,
        "涨跌额": -0.05,
        "涨跌幅": -0.13,
        "开盘价": 38.18,
        "最高价": 38.25,
        "最低价": 37.8,
        "昨收价": 38.06,
        "总市值": 39416370000.0,
        "市盈率": 1397.17,
        "成交量": 1202839.0,
        "成交额": 45707300.0,
        "振幅": 1.18,
        "换手率": 0.12,
        "symbol": "106.TRP"
    },
    {
        "序号": 8143,
        "name": "ACRES Commercial Realty Corp Se",
        "最新价": 22.79,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 22.82,
        "最高价": 22.84,
        "最低价": 22.76,
        "昨收价": 22.82,
        "总市值": 109392000.0,
        "市盈率": null,
        "成交量": 8647.0,
        "成交额": 197187.0,
        "振幅": 0.35,
        "换手率": 0.18,
        "symbol": "106.ACR_C"
    },
    {
        "序号": 8144,
        "name": "Roundhill Video Games ETF",
        "最新价": 15.18,
        "涨跌额": -0.02,
        "涨跌幅": -0.13,
        "开盘价": 15.2,
        "最高价": 15.23,
        "最低价": 15.11,
        "昨收价": 15.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 5709.0,
        "成交额": 86580.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.NERD"
    },
    {
        "序号": 8145,
        "name": "iShares iBonds 2026 Term High Y",
        "最新价": 22.74,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 22.77,
        "最高价": 22.79,
        "最低价": 22.67,
        "昨收价": 22.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 619810.0,
        "成交额": 14098574.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.IBHF"
    },
    {
        "序号": 8146,
        "name": "SPDR SSGA Income Allocation ETF",
        "最新价": 30.31,
        "涨跌额": -0.04,
        "涨跌幅": -0.13,
        "开盘价": 30.29,
        "最高价": 30.31,
        "最低价": 30.27,
        "昨收价": 30.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1870.0,
        "成交额": 56627.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.INKM"
    },
    {
        "序号": 8147,
        "name": "U.S. Global Sea to Sky Cargo ET",
        "最新价": 15.07,
        "涨跌额": -0.02,
        "涨跌幅": -0.13,
        "开盘价": 15.0,
        "最高价": 15.11,
        "最低价": 15.0,
        "昨收价": 15.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 3051.0,
        "成交额": 46049.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.SEA"
    },
    {
        "序号": 8148,
        "name": "Global X Health & Wellness ETF",
        "最新价": 22.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.13,
        "开盘价": 22.49,
        "最高价": 22.57,
        "最低价": 22.46,
        "昨收价": 22.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1028.0,
        "成交额": 23103.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "105.BFIT"
    },
    {
        "序号": 8149,
        "name": "Pathward Financial Inc",
        "最新价": 52.41,
        "涨跌额": -0.07,
        "涨跌幅": -0.13,
        "开盘价": 52.61,
        "最高价": 53.13,
        "最低价": 52.18,
        "昨收价": 52.48,
        "总市值": 1362086792.0,
        "市盈率": 8.32,
        "成交量": 126068.0,
        "成交额": 6616159.0,
        "振幅": 1.81,
        "换手率": 0.49,
        "symbol": "105.CASH"
    },
    {
        "序号": 8150,
        "name": "Virtus Global Multi-Sector Inco",
        "最新价": 7.47,
        "涨跌额": -0.01,
        "涨跌幅": -0.13,
        "开盘价": 7.49,
        "最高价": 7.53,
        "最低价": 7.47,
        "昨收价": 7.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 46224.0,
        "成交额": 345933.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "106.VGI"
    },
    {
        "序号": 8151,
        "name": "Virginia National Bankshares Co",
        "最新价": 36.98,
        "涨跌额": -0.05,
        "涨跌幅": -0.14,
        "开盘价": 37.0,
        "最高价": 37.68,
        "最低价": 36.72,
        "昨收价": 37.03,
        "总市值": 198434014.0,
        "市盈率": 8.57,
        "成交量": 9992.0,
        "成交额": 371219.0,
        "振幅": 2.59,
        "换手率": 0.19,
        "symbol": "105.VABK"
    },
    {
        "序号": 8152,
        "name": "奇富科技",
        "最新价": 14.77,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "开盘价": 14.61,
        "最高价": 15.02,
        "最低价": 14.51,
        "昨收价": 14.79,
        "总市值": 2384835606.0,
        "市盈率": 4.14,
        "成交量": 629668.0,
        "成交额": 9305518.0,
        "振幅": 3.45,
        "换手率": 0.39,
        "symbol": "105.QFIN"
    },
    {
        "序号": 8153,
        "name": "Merk Stagflation ETF",
        "最新价": 22.15,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 22.1,
        "最高价": 22.15,
        "最低价": 22.1,
        "昨收价": 22.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 214.0,
        "成交额": 4730.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.STGF"
    },
    {
        "序号": 8154,
        "name": "UMH Properties Inc",
        "最新价": 14.76,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "开盘价": 14.73,
        "最高价": 14.82,
        "最低价": 14.61,
        "昨收价": 14.78,
        "总市值": 980036576.0,
        "市盈率": 2327.88,
        "成交量": 270943.0,
        "成交额": 3987777.0,
        "振幅": 1.42,
        "换手率": 0.41,
        "symbol": "106.UMH"
    },
    {
        "序号": 8155,
        "name": "Invesco BulletShares 2027 High ",
        "最新价": 22.06,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 22.05,
        "最高价": 22.06,
        "最低价": 22.01,
        "昨收价": 22.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 72168.0,
        "成交额": 1590010.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "105.BSJR"
    },
    {
        "序号": 8156,
        "name": "Invesco BulletShares 2030 Munic",
        "最新价": 22.05,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 22.02,
        "最高价": 22.06,
        "最低价": 21.97,
        "昨收价": 22.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 48002.0,
        "成交额": 1055911.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "105.BSMU"
    },
    {
        "序号": 8157,
        "name": "VanEck ETF Trust VanEck Short H",
        "最新价": 21.94,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.99,
        "最高价": 21.99,
        "最低价": 21.87,
        "昨收价": 21.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 75309.0,
        "成交额": 1650653.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.SHYD"
    },
    {
        "序号": 8158,
        "name": "Progyny Inc",
        "最新价": 36.26,
        "涨跌额": -0.05,
        "涨跌幅": -0.14,
        "开盘价": 36.34,
        "最高价": 36.96,
        "最低价": 36.06,
        "昨收价": 36.31,
        "总市值": 3475021627.0,
        "市盈率": 66.86,
        "成交量": 590832.0,
        "成交额": 21499319.0,
        "振幅": 2.48,
        "换手率": 0.62,
        "symbol": "105.PGNY"
    },
    {
        "序号": 8159,
        "name": "PIMCO 1-5 Year U.S. TIPS Index ",
        "最新价": 50.59,
        "涨跌额": -0.07,
        "涨跌幅": -0.14,
        "开盘价": 50.54,
        "最高价": 50.59,
        "最低价": 50.51,
        "昨收价": 50.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 191956.0,
        "成交额": 9701054.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.STPZ"
    },
    {
        "序号": 8160,
        "name": "First Trust Nasdaq Lux Digital ",
        "最新价": 14.4,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "开盘价": 14.48,
        "最高价": 14.49,
        "最低价": 14.4,
        "昨收价": 14.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 1474.0,
        "成交额": 21344.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.EKG"
    },
    {
        "序号": 8161,
        "name": "Associated Banc-Corp Notes",
        "最新价": 21.6,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.49,
        "最高价": 21.82,
        "最低价": 21.45,
        "昨收价": 21.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 52835.0,
        "成交额": 1144593.0,
        "振幅": 1.71,
        "换手率": null,
        "symbol": "106.ASBA"
    },
    {
        "序号": 8162,
        "name": "iShares J.P. Morgan EM Corporat",
        "最新价": 43.19,
        "涨跌额": -0.06,
        "涨跌幅": -0.14,
        "开盘价": 43.22,
        "最高价": 43.25,
        "最低价": 43.08,
        "昨收价": 43.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 16830.0,
        "成交额": 726704.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.CEMB"
    },
    {
        "序号": 8163,
        "name": "Veeva Systems Inc-A",
        "最新价": 172.71,
        "涨跌额": -0.24,
        "涨跌幅": -0.14,
        "开盘价": 174.85,
        "最高价": 175.41,
        "最低价": 171.56,
        "昨收价": 172.95,
        "总市值": 27809012739.0,
        "市盈率": 49.06,
        "成交量": 1388568.0,
        "成交额": 239816653.0,
        "振幅": 2.23,
        "换手率": 0.86,
        "symbol": "106.VEEV"
    },
    {
        "序号": 8164,
        "name": "SPDR S&P Transportation ETF",
        "最新价": 79.04,
        "涨跌额": -0.11,
        "涨跌幅": -0.14,
        "开盘价": 78.96,
        "最高价": 79.48,
        "最低价": 78.82,
        "昨收价": 79.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 16484.0,
        "成交额": 1304565.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.XTN"
    },
    {
        "序号": 8165,
        "name": "IQ MacKay California Municipal ",
        "最新价": 21.53,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.54,
        "最高价": 21.54,
        "最低价": 21.53,
        "昨收价": 21.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 4779.0,
        "成交额": 102927.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.MMCA"
    },
    {
        "序号": 8166,
        "name": "JPMorgan Limited Duration Bond ",
        "最新价": 50.21,
        "涨跌额": -0.07,
        "涨跌幅": -0.14,
        "开盘价": 50.2,
        "最高价": 50.27,
        "最低价": 50.19,
        "昨收价": 50.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 17939.0,
        "成交额": 900896.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.JPLD"
    },
    {
        "序号": 8167,
        "name": "Flaherty & Crumrine Total Retur",
        "最新价": 14.33,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "开盘价": 14.35,
        "最高价": 14.42,
        "最低价": 14.27,
        "昨收价": 14.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 20277.0,
        "成交额": 290592.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.FLC"
    },
    {
        "序号": 8168,
        "name": "Cabana Target Drawdown 7 ETF",
        "最新价": 21.37,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.33,
        "最高价": 21.38,
        "最低价": 21.33,
        "昨收价": 21.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 17254.0,
        "成交额": 368077.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "105.TDSB"
    },
    {
        "序号": 8169,
        "name": "ETRACS Alerian MLP Infrastructu",
        "最新价": 21.33,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.41,
        "最高价": 21.43,
        "最低价": 21.31,
        "昨收价": 21.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 3958.0,
        "成交额": 84451.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.MLPB"
    },
    {
        "序号": 8170,
        "name": "The Gabelli Equity Trust Inc Se",
        "最新价": 21.29,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 21.3,
        "最高价": 21.33,
        "最低价": 21.22,
        "昨收价": 21.32,
        "总市值": 83663547.0,
        "市盈率": null,
        "成交量": 10039.0,
        "成交额": 213544.0,
        "振幅": 0.52,
        "换手率": 0.26,
        "symbol": "106.GAB_K"
    },
    {
        "序号": 8171,
        "name": "堕落天使高收益债ETF",
        "最新价": 28.24,
        "涨跌额": -0.04,
        "涨跌幅": -0.14,
        "开盘价": 28.2,
        "最高价": 28.27,
        "最低价": 28.18,
        "昨收价": 28.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 2468924.0,
        "成交额": 69670280.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "105.ANGL"
    },
    {
        "序号": 8172,
        "name": "Emerging Markets Equity Select ",
        "最新价": 49.27,
        "涨跌额": -0.07,
        "涨跌幅": -0.14,
        "开盘价": 49.41,
        "最高价": 49.41,
        "最低价": 49.1,
        "昨收价": 49.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 752.0,
        "成交额": 37003.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.RNEM"
    },
    {
        "序号": 8173,
        "name": "武田制药",
        "最新价": 14.06,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "开盘价": 13.99,
        "最高价": 14.06,
        "最低价": 13.95,
        "昨收价": 14.08,
        "总市值": 44117140430.0,
        "市盈率": 29.29,
        "成交量": 838166.0,
        "成交额": 11770288.0,
        "振幅": 0.78,
        "换手率": 0.03,
        "symbol": "106.TAK"
    },
    {
        "序号": 8174,
        "name": "Seanergy Maritime Holdings Corp",
        "最新价": 7.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.14,
        "开盘价": 6.98,
        "最高价": 7.2,
        "最低价": 6.93,
        "昨收价": 7.03,
        "总市值": 137935671.0,
        "市盈率": -17.13,
        "成交量": 109495.0,
        "成交额": 773130.0,
        "振幅": 3.84,
        "换手率": 0.56,
        "symbol": "105.SHIP"
    },
    {
        "序号": 8175,
        "name": "PIMCO Income Strategy Fund II",
        "最新价": 7.01,
        "涨跌额": -0.01,
        "涨跌幅": -0.14,
        "开盘价": 6.95,
        "最高价": 7.08,
        "最低价": 6.93,
        "昨收价": 7.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 334998.0,
        "成交额": 2354295.0,
        "振幅": 2.14,
        "换手率": null,
        "symbol": "106.PFN"
    },
    {
        "序号": 8176,
        "name": "Xtrackers USD High Yield Corpor",
        "最新价": 34.83,
        "涨跌额": -0.05,
        "涨跌幅": -0.14,
        "开盘价": 34.81,
        "最高价": 34.85,
        "最低价": 34.74,
        "昨收价": 34.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 2045623.0,
        "成交额": 71170738.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.HYLB"
    },
    {
        "序号": 8177,
        "name": "ALPS Emerging Sector Dividend D",
        "最新价": 20.89,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 20.82,
        "最高价": 20.89,
        "最低价": 20.82,
        "昨收价": 20.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 813.0,
        "成交额": 16945.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.EDOG"
    },
    {
        "序号": 8178,
        "name": "福尼克斯",
        "最新价": 20.88,
        "涨跌额": -0.03,
        "涨跌幅": -0.14,
        "开盘价": 20.87,
        "最高价": 21.21,
        "最低价": 20.81,
        "昨收价": 20.91,
        "总市值": 1306012555.0,
        "市盈率": 11.07,
        "成交量": 291109.0,
        "成交额": 6093311.0,
        "振幅": 1.91,
        "换手率": 0.47,
        "symbol": "105.PLAB"
    },
    {
        "序号": 8179,
        "name": "西格内特珠宝",
        "最新价": 97.23,
        "涨跌额": -0.14,
        "涨跌幅": -0.14,
        "开盘价": 96.57,
        "最高价": 97.58,
        "最低价": 94.66,
        "昨收价": 97.37,
        "总市值": 4308967384.0,
        "市盈率": 9.34,
        "成交量": 669435.0,
        "成交额": 64571762.0,
        "振幅": 3.0,
        "换手率": 1.51,
        "symbol": "106.SIG"
    },
    {
        "序号": 8180,
        "name": "全球除美国小型股指数ETF",
        "最新价": 111.1,
        "涨跌额": -0.16,
        "涨跌幅": -0.14,
        "开盘价": 110.75,
        "最高价": 111.39,
        "最低价": 110.57,
        "昨收价": 111.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 201610.0,
        "成交额": 22370095.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.VSS"
    },
    {
        "序号": 8181,
        "name": "中证500ETF-德银嘉实",
        "最新价": 27.51,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 27.58,
        "最高价": 27.58,
        "最低价": 27.51,
        "昨收价": 27.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 1447.0,
        "成交额": 39828.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.ASHS"
    },
    {
        "序号": 8182,
        "name": "First Trust New Opportunities M",
        "最新价": 6.87,
        "涨跌额": -0.01,
        "涨跌幅": -0.15,
        "开盘价": 6.87,
        "最高价": 6.94,
        "最低价": 6.81,
        "昨收价": 6.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 53690.0,
        "成交额": 368041.0,
        "振幅": 1.89,
        "换手率": null,
        "symbol": "106.FPL"
    },
    {
        "序号": 8183,
        "name": "波士顿科学",
        "最新价": 54.95,
        "涨跌额": -0.08,
        "涨跌幅": -0.15,
        "开盘价": 54.87,
        "最高价": 55.05,
        "最低价": 54.52,
        "昨收价": 55.03,
        "总市值": 80500803596.0,
        "市盈率": 65.55,
        "成交量": 4300356.0,
        "成交额": 235848913.0,
        "振幅": 0.96,
        "换手率": 0.29,
        "symbol": "106.BSX"
    },
    {
        "序号": 8184,
        "name": "Nuveen ESG High Yield Corporate",
        "最新价": 20.6,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.62,
        "最高价": 20.63,
        "最低价": 20.56,
        "昨收价": 20.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 17430.0,
        "成交额": 358726.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.NUHY"
    },
    {
        "序号": 8185,
        "name": "The New America High Income Fun",
        "最新价": 6.85,
        "涨跌额": -0.01,
        "涨跌幅": -0.15,
        "开盘价": 6.85,
        "最高价": 6.87,
        "最低价": 6.84,
        "昨收价": 6.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 27915.0,
        "成交额": 191171.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "106.HYB"
    },
    {
        "序号": 8186,
        "name": "First Trust Low Duration Opport",
        "最新价": 47.78,
        "涨跌额": -0.07,
        "涨跌幅": -0.15,
        "开盘价": 47.85,
        "最高价": 47.85,
        "最低价": 47.76,
        "昨收价": 47.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 531498.0,
        "成交额": 25402435.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "105.LMBS"
    },
    {
        "序号": 8187,
        "name": "WisdomTree International Effici",
        "最新价": 34.12,
        "涨跌额": -0.05,
        "涨跌幅": -0.15,
        "开盘价": 33.99,
        "最高价": 34.12,
        "最低价": 33.98,
        "昨收价": 34.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 11754.0,
        "成交额": 400203.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.NTSI"
    },
    {
        "序号": 8188,
        "name": "帕卡",
        "最新价": 95.38,
        "涨跌额": -0.14,
        "涨跌幅": -0.15,
        "开盘价": 95.2,
        "最高价": 96.11,
        "最低价": 94.88,
        "昨收价": 95.52,
        "总市值": 49890973810.0,
        "市盈率": 12.15,
        "成交量": 2186329.0,
        "成交额": 208637633.0,
        "振幅": 1.29,
        "换手率": 0.42,
        "symbol": "105.PCAR"
    },
    {
        "序号": 8189,
        "name": "Vanguard Short-Term Inflation-P",
        "最新价": 47.61,
        "涨跌额": -0.07,
        "涨跌幅": -0.15,
        "开盘价": 47.61,
        "最高价": 47.62,
        "最低价": 47.57,
        "昨收价": 47.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 1037994.0,
        "成交额": 49413248.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.VTIP"
    },
    {
        "序号": 8190,
        "name": "MagnaChip Semiconductor Corp",
        "最新价": 6.8,
        "涨跌额": -0.01,
        "涨跌幅": -0.15,
        "开盘价": 6.75,
        "最高价": 6.9,
        "最低价": 6.75,
        "昨收价": 6.81,
        "总市值": 263588522.0,
        "市盈率": -9.55,
        "成交量": 312557.0,
        "成交额": 2129042.0,
        "振幅": 2.2,
        "换手率": 0.81,
        "symbol": "106.MX"
    },
    {
        "序号": 8191,
        "name": "BondBloxx USD High Yield Bond T",
        "最新价": 33.98,
        "涨跌额": -0.05,
        "涨跌幅": -0.15,
        "开盘价": 34.05,
        "最高价": 34.05,
        "最低价": 33.98,
        "昨收价": 34.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 154.0,
        "成交额": 5243.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.XHYT"
    },
    {
        "序号": 8192,
        "name": "Rareview Tax Advantaged Income ",
        "最新价": 20.33,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.57,
        "最高价": 20.57,
        "最低价": 20.33,
        "昨收价": 20.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 417.0,
        "成交额": 8549.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.RTAI"
    },
    {
        "序号": 8193,
        "name": "Invesco BulletShares 2025 Corpo",
        "最新价": 20.33,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.33,
        "最高价": 20.34,
        "最低价": 20.32,
        "昨收价": 20.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 1185212.0,
        "成交额": 24088539.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.BSCP"
    },
    {
        "序号": 8194,
        "name": "iShares Core Moderate Allocatio",
        "最新价": 40.63,
        "涨跌额": -0.06,
        "涨跌幅": -0.15,
        "开盘价": 40.56,
        "最高价": 40.66,
        "最低价": 40.49,
        "昨收价": 40.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 262850.0,
        "成交额": 10668498.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.AOM"
    },
    {
        "序号": 8195,
        "name": "DTE Energy Co Series E Debentur",
        "最新价": 20.3,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.32,
        "最高价": 20.39,
        "最低价": 20.2,
        "昨收价": 20.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 19430.0,
        "成交额": 394150.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.DTG"
    },
    {
        "序号": 8196,
        "name": "Harbor Multi-Asset Explorer ETF",
        "最新价": 20.27,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.27,
        "最高价": 20.27,
        "最低价": 20.27,
        "昨收价": 20.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": 405.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MAPP"
    },
    {
        "序号": 8197,
        "name": "Tortoise Power and Energy Infra",
        "最新价": 13.51,
        "涨跌额": -0.02,
        "涨跌幅": -0.15,
        "开盘价": 13.41,
        "最高价": 13.57,
        "最低价": 13.41,
        "昨收价": 13.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 17911.0,
        "成交额": 241291.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "106.TPZ"
    },
    {
        "序号": 8198,
        "name": "Shoe Carnival Inc",
        "最新价": 26.99,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 26.92,
        "最高价": 27.32,
        "最低价": 26.56,
        "昨收价": 27.03,
        "总市值": 732280885.0,
        "市盈率": 9.22,
        "成交量": 151028.0,
        "成交额": 4069036.0,
        "振幅": 2.81,
        "换手率": 0.56,
        "symbol": "105.SCVL"
    },
    {
        "序号": 8199,
        "name": "MEI Pharma Inc",
        "最新价": 6.72,
        "涨跌额": -0.01,
        "涨跌幅": -0.15,
        "开盘价": 6.82,
        "最高价": 6.88,
        "最低价": 6.56,
        "昨收价": 6.73,
        "总市值": 44774399.0,
        "市盈率": 1.09,
        "成交量": 35000.0,
        "成交额": 236829.0,
        "振幅": 4.75,
        "换手率": 0.53,
        "symbol": "105.MEIP"
    },
    {
        "序号": 8200,
        "name": "Pacer Trendpilot US Bond ETF",
        "最新价": 20.1,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.1,
        "最高价": 20.11,
        "最低价": 20.05,
        "昨收价": 20.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 36171.0,
        "成交额": 726411.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.PTBD"
    },
    {
        "序号": 8201,
        "name": "Capital Group Municipal Income ",
        "最新价": 26.74,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 26.74,
        "最高价": 26.74,
        "最低价": 26.66,
        "昨收价": 26.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 362178.0,
        "成交额": 9672971.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.CGMU"
    },
    {
        "序号": 8202,
        "name": "RH Tactical Outlook ETF",
        "最新价": 13.35,
        "涨跌额": -0.02,
        "涨跌幅": -0.15,
        "开盘价": 13.32,
        "最高价": 13.35,
        "最低价": 13.27,
        "昨收价": 13.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 14220.0,
        "成交额": 189023.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.RHTX"
    },
    {
        "序号": 8203,
        "name": "Tanger Factory Outlet Centers I",
        "最新价": 26.6,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 26.54,
        "最高价": 26.76,
        "最低价": 26.34,
        "昨收价": 26.64,
        "总市值": 2864362746.0,
        "市盈率": 30.56,
        "成交量": 646807.0,
        "成交额": 17179053.0,
        "振幅": 1.58,
        "换手率": 0.6,
        "symbol": "106.SKT"
    },
    {
        "序号": 8204,
        "name": "Global X Video Games & Esports ",
        "最新价": 19.95,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 19.89,
        "最高价": 20.07,
        "最低价": 19.84,
        "昨收价": 19.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 24259.0,
        "成交额": 484210.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "105.HERO"
    },
    {
        "序号": 8205,
        "name": "Avantis International Small Cap",
        "最新价": 59.85,
        "涨跌额": -0.09,
        "涨跌幅": -0.15,
        "开盘价": 59.65,
        "最高价": 60.06,
        "最低价": 59.55,
        "昨收价": 59.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 236784.0,
        "成交额": 14162254.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.AVDV"
    },
    {
        "序号": 8206,
        "name": "John Hancock Dynamic Municipal ",
        "最新价": 26.55,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 26.5,
        "最高价": 26.57,
        "最低价": 26.5,
        "昨收价": 26.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 625.0,
        "成交额": 16586.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.JHMU"
    },
    {
        "序号": 8207,
        "name": "BondBloxx USD High Yield Bond H",
        "最新价": 33.17,
        "涨跌额": -0.05,
        "涨跌幅": -0.15,
        "开盘价": 33.17,
        "最高价": 33.17,
        "最低价": 33.17,
        "昨收价": 33.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 20.0,
        "成交额": 663.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XHYH"
    },
    {
        "序号": 8208,
        "name": "John Marshall Bancorp Inc",
        "最新价": 19.86,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 20.58,
        "最高价": 20.58,
        "最低价": 19.55,
        "昨收价": 19.89,
        "总市值": 280544028.0,
        "市盈率": 31.67,
        "成交量": 11575.0,
        "成交额": 230727.0,
        "振幅": 5.18,
        "换手率": 0.08,
        "symbol": "105.JMSB"
    },
    {
        "序号": 8209,
        "name": "CVR Partners LP",
        "最新价": 66.17,
        "涨跌额": -0.1,
        "涨跌幅": -0.15,
        "开盘价": 66.41,
        "最高价": 67.5,
        "最低价": 66.0,
        "昨收价": 66.27,
        "总市值": 699392880.0,
        "市盈率": 2.71,
        "成交量": 51465.0,
        "成交额": 3429549.0,
        "振幅": 2.26,
        "换手率": 0.49,
        "symbol": "106.UAN"
    },
    {
        "序号": 8210,
        "name": "First Trust Short Duration Mana",
        "最新价": 19.8,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 19.82,
        "最高价": 19.82,
        "最低价": 19.8,
        "昨收价": 19.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 27059.0,
        "成交额": 536026.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.FSMB"
    },
    {
        "序号": 8211,
        "name": "詹姆斯哈迪",
        "最新价": 32.86,
        "涨跌额": -0.05,
        "涨跌幅": -0.15,
        "开盘价": 32.29,
        "最高价": 32.87,
        "最低价": 32.11,
        "昨收价": 32.91,
        "总市值": 14385215457.0,
        "市盈率": 29.3,
        "成交量": 35119.0,
        "成交额": 1148903.0,
        "振幅": 2.31,
        "换手率": 0.01,
        "symbol": "106.JHX"
    },
    {
        "序号": 8212,
        "name": "Truist Financial Corp Series R ",
        "最新价": 19.61,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 19.64,
        "最高价": 19.72,
        "最低价": 19.47,
        "昨收价": 19.64,
        "总市值": 725570.0,
        "市盈率": null,
        "成交量": 73839.0,
        "成交额": 1445691.0,
        "振幅": 1.27,
        "换手率": 199.56,
        "symbol": "106.TFC_R"
    },
    {
        "序号": 8213,
        "name": "Bright Health Group Inc",
        "最新价": 6.51,
        "涨跌额": -0.01,
        "涨跌幅": -0.15,
        "开盘价": 6.43,
        "最高价": 6.66,
        "最低价": 6.43,
        "昨收价": 6.52,
        "总市值": 51963386.0,
        "市盈率": -0.03,
        "成交量": 4548.0,
        "成交额": 29634.0,
        "振幅": 3.53,
        "换手率": 0.06,
        "symbol": "106.BHG"
    },
    {
        "序号": 8214,
        "name": "Titan Machinery Inc",
        "最新价": 26.01,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 26.05,
        "最高价": 26.28,
        "最低价": 25.92,
        "昨收价": 26.05,
        "总市值": 594312556.0,
        "市盈率": 5.58,
        "成交量": 161447.0,
        "成交额": 4206631.0,
        "振幅": 1.38,
        "换手率": 0.71,
        "symbol": "105.TITN"
    },
    {
        "序号": 8215,
        "name": "Global Partners LP Series B Pfd",
        "最新价": 25.95,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 25.85,
        "最高价": 25.99,
        "最低价": 25.82,
        "昨收价": 25.99,
        "总市值": 77850000.0,
        "市盈率": null,
        "成交量": 3217.0,
        "成交额": 83408.0,
        "振幅": 0.65,
        "换手率": 0.11,
        "symbol": "106.GLP_B"
    },
    {
        "序号": 8216,
        "name": "桑坦德银行(智利)",
        "最新价": 19.42,
        "涨跌额": -0.03,
        "涨跌幅": -0.15,
        "开盘价": 19.39,
        "最高价": 19.64,
        "最低价": 19.32,
        "昨收价": 19.45,
        "总市值": 9149059456.0,
        "市盈率": 19.42,
        "成交量": 230371.0,
        "成交额": 4480502.0,
        "振幅": 1.65,
        "换手率": 0.05,
        "symbol": "106.BSAC"
    },
    {
        "序号": 8217,
        "name": "IQ MacKay ESG High Income ETF",
        "最新价": 25.87,
        "涨跌额": -0.04,
        "涨跌幅": -0.15,
        "开盘价": 25.98,
        "最高价": 25.98,
        "最低价": 25.87,
        "昨收价": 25.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 534.0,
        "成交额": 13861.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.IQHI"
    },
    {
        "序号": 8218,
        "name": "JPMorgan BetaBuilders USD High ",
        "最新价": 45.03,
        "涨跌额": -0.07,
        "涨跌幅": -0.16,
        "开盘价": 45.02,
        "最高价": 45.06,
        "最低价": 44.92,
        "昨收价": 45.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 64437.0,
        "成交额": 2900229.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.BBHY"
    },
    {
        "序号": 8219,
        "name": "Cohen & Steers REIT and Preferr",
        "最新价": 19.25,
        "涨跌额": -0.03,
        "涨跌幅": -0.16,
        "开盘价": 19.2,
        "最高价": 19.38,
        "最低价": 19.1,
        "昨收价": 19.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 112968.0,
        "成交额": 2176132.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "106.RNP"
    },
    {
        "序号": 8220,
        "name": "iShares ESG Aware Moderate Allo",
        "最新价": 25.66,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 25.64,
        "最高价": 25.66,
        "最低价": 25.64,
        "昨收价": 25.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 202.0,
        "成交额": 5179.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.EAOM"
    },
    {
        "序号": 8221,
        "name": "MarketDesk Focused U.S. Dividen",
        "最新价": 25.64,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 25.67,
        "最高价": 25.67,
        "最低价": 25.6,
        "昨收价": 25.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 9789.0,
        "成交额": 251084.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "105.FDIV"
    },
    {
        "序号": 8222,
        "name": "艺康",
        "最新价": 192.12,
        "涨跌额": -0.3,
        "涨跌幅": -0.16,
        "开盘价": 192.38,
        "最高价": 192.84,
        "最低价": 190.93,
        "昨收价": 192.42,
        "总市值": 54781062218.0,
        "市盈率": 44.48,
        "成交量": 726925.0,
        "成交额": 139499477.0,
        "振幅": 0.99,
        "换手率": 0.25,
        "symbol": "106.ECL"
    },
    {
        "序号": 8223,
        "name": "Carlyle Credit Income Fund Seri",
        "最新价": 25.53,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 25.54,
        "最高价": 25.57,
        "最低价": 25.45,
        "昨收价": 25.57,
        "总市值": 30636000.0,
        "市盈率": null,
        "成交量": 5776.0,
        "成交额": 147361.0,
        "振幅": 0.47,
        "换手率": 0.48,
        "symbol": "106.CCIA"
    },
    {
        "序号": 8224,
        "name": "iShares ESG Advanced High Yield",
        "最新价": 44.54,
        "涨跌额": -0.07,
        "涨跌幅": -0.16,
        "开盘价": 44.52,
        "最高价": 44.54,
        "最低价": 44.36,
        "昨收价": 44.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 14611.0,
        "成交额": 649583.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "105.HYXF"
    },
    {
        "序号": 8225,
        "name": "BondBloxx USD High Yield Bond E",
        "最新价": 38.07,
        "涨跌额": -0.06,
        "涨跌幅": -0.16,
        "开盘价": 38.07,
        "最高价": 38.07,
        "最低价": 38.07,
        "昨收价": 38.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 13.0,
        "成交额": 494.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XHYE"
    },
    {
        "序号": 8226,
        "name": "Invesco DWA Utilities Momentum ",
        "最新价": 31.7,
        "涨跌额": -0.05,
        "涨跌幅": -0.16,
        "开盘价": 31.7,
        "最高价": 31.7,
        "最低价": 31.67,
        "昨收价": 31.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 621.0,
        "成交额": 19679.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "105.PUI"
    },
    {
        "序号": 8227,
        "name": "SPDR Portfolio TIPS ETF",
        "最新价": 25.27,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 25.22,
        "最高价": 25.28,
        "最低价": 25.18,
        "昨收价": 25.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 351827.0,
        "成交额": 8877307.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.SPIP"
    },
    {
        "序号": 8228,
        "name": "Ryan Specialty Holdings Inc-A",
        "最新价": 44.15,
        "涨跌额": -0.07,
        "涨跌幅": -0.16,
        "开盘价": 44.14,
        "最高价": 44.44,
        "最低价": 43.75,
        "昨收价": 44.22,
        "总市值": 11490063681.0,
        "市盈率": 204.87,
        "成交量": 414163.0,
        "成交额": 18273713.0,
        "振幅": 1.56,
        "换手率": 0.16,
        "symbol": "106.RYAN"
    },
    {
        "序号": 8229,
        "name": "Cboe Vest S&P 500 Dividend Aris",
        "最新价": 50.26,
        "涨跌额": -0.08,
        "涨跌幅": -0.16,
        "开盘价": 50.33,
        "最高价": 50.48,
        "最低价": 50.16,
        "昨收价": 50.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 235882.0,
        "成交额": 11861566.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.KNG"
    },
    {
        "序号": 8230,
        "name": "iShares Short Maturity Bond ETF",
        "最新价": 50.24,
        "涨跌额": -0.08,
        "涨跌幅": -0.16,
        "开盘价": 50.3,
        "最高价": 50.3,
        "最低价": 50.23,
        "昨收价": 50.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 628690.0,
        "成交额": 31588690.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.NEAR"
    },
    {
        "序号": 8231,
        "name": "Oddity Tech Ltd-A",
        "最新价": 37.64,
        "涨跌额": -0.06,
        "涨跌幅": -0.16,
        "开盘价": 38.3,
        "最高价": 38.3,
        "最低价": 36.54,
        "昨收价": 37.7,
        "总市值": 2138233058.0,
        "市盈率": 40.53,
        "成交量": 236042.0,
        "成交额": 8844880.0,
        "振幅": 4.67,
        "换手率": 0.42,
        "symbol": "105.ODD"
    },
    {
        "序号": 8232,
        "name": "Radiant Logistics Inc",
        "最新价": 6.25,
        "涨跌额": -0.01,
        "涨跌幅": -0.16,
        "开盘价": 6.26,
        "最高价": 6.33,
        "最低价": 6.21,
        "昨收价": 6.26,
        "总市值": 293166163.0,
        "市盈率": 19.83,
        "成交量": 76184.0,
        "成交额": 476215.0,
        "振幅": 1.92,
        "换手率": 0.16,
        "symbol": "107.RLGT"
    },
    {
        "序号": 8233,
        "name": "International General Insurance",
        "最新价": 12.49,
        "涨跌额": -0.02,
        "涨跌幅": -0.16,
        "开盘价": 12.5,
        "最高价": 12.62,
        "最低价": 12.35,
        "昨收价": 12.51,
        "总市值": 580487226.0,
        "市盈率": 5.59,
        "成交量": 48901.0,
        "成交额": 610889.0,
        "振幅": 2.16,
        "换手率": 0.11,
        "symbol": "105.IGIC"
    },
    {
        "序号": 8234,
        "name": "AMN医疗服务",
        "最新价": 68.69,
        "涨跌额": -0.11,
        "涨跌幅": -0.16,
        "开盘价": 68.86,
        "最高价": 69.13,
        "最低价": 67.74,
        "昨收价": 68.8,
        "总市值": 2595736027.0,
        "市盈率": 9.27,
        "成交量": 445371.0,
        "成交额": 30451055.0,
        "振幅": 2.02,
        "换手率": 1.18,
        "symbol": "106.AMN"
    },
    {
        "序号": 8235,
        "name": "Xtrackers Short Duration High Y",
        "最新价": 43.71,
        "涨跌额": -0.07,
        "涨跌幅": -0.16,
        "开盘价": 43.71,
        "最高价": 43.75,
        "最低价": 43.64,
        "昨收价": 43.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 5853.0,
        "成交额": 255816.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.SHYL"
    },
    {
        "序号": 8236,
        "name": "Invesco BulletShares 2030 High ",
        "最新价": 24.96,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.96,
        "最高价": 24.97,
        "最低价": 24.94,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2355.0,
        "成交额": 58782.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.BSJU"
    },
    {
        "序号": 8237,
        "name": "Bank of America Corp Series GG ",
        "最新价": 24.81,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.8,
        "最高价": 24.87,
        "最低价": 24.72,
        "昨收价": 24.85,
        "总市值": 1339740.0,
        "市盈率": null,
        "成交量": 81292.0,
        "成交额": 2016312.0,
        "振幅": 0.6,
        "换手率": 150.54,
        "symbol": "106.BAC_B"
    },
    {
        "序号": 8238,
        "name": "Invesco FTSE RAFI Developed Mar",
        "最新价": 31.01,
        "涨跌额": -0.05,
        "涨跌幅": -0.16,
        "开盘价": 30.95,
        "最高价": 31.11,
        "最低价": 30.88,
        "昨收价": 31.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 28632.0,
        "成交额": 887256.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.PDN"
    },
    {
        "序号": 8239,
        "name": "Harmony Biosciences Holdings In",
        "最新价": 31.0,
        "涨跌额": -0.05,
        "涨跌幅": -0.16,
        "开盘价": 31.2,
        "最高价": 31.86,
        "最低价": 30.96,
        "昨收价": 31.05,
        "总市值": 1815730264.0,
        "市盈率": 12.04,
        "成交量": 491820.0,
        "成交额": 15335947.0,
        "振幅": 2.9,
        "换手率": 0.84,
        "symbol": "105.HRMY"
    },
    {
        "序号": 8240,
        "name": "TRxADE HEALTH INC",
        "最新价": 6.19,
        "涨跌额": -0.01,
        "涨跌幅": -0.16,
        "开盘价": 6.06,
        "最高价": 6.2,
        "最低价": 6.06,
        "昨收价": 6.2,
        "总市值": 7719976.0,
        "市盈率": -1.89,
        "成交量": 1219.0,
        "成交额": 7494.0,
        "振幅": 2.26,
        "换手率": 0.1,
        "symbol": "105.MEDS"
    },
    {
        "序号": 8241,
        "name": "高收益债ETF-SPDR",
        "最新价": 92.81,
        "涨跌额": -0.15,
        "涨跌幅": -0.16,
        "开盘价": 92.61,
        "最高价": 92.86,
        "最低价": 92.57,
        "昨收价": 92.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 7106327.0,
        "成交额": 658954352.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.JNK"
    },
    {
        "序号": 8242,
        "name": "BondBloxx USD High Yield Bond I",
        "最新价": 37.06,
        "涨跌额": -0.06,
        "涨跌幅": -0.16,
        "开盘价": 37.06,
        "最高价": 37.06,
        "最低价": 37.06,
        "昨收价": 37.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 3743.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XHYI"
    },
    {
        "序号": 8243,
        "name": "FundX Flexible ETF",
        "最新价": 24.56,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.53,
        "最高价": 24.56,
        "最低价": 24.53,
        "昨收价": 24.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 548.0,
        "成交额": 13448.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.XFLX"
    },
    {
        "序号": 8244,
        "name": "Reinsurance Group of America In",
        "最新价": 24.52,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.51,
        "最高价": 24.67,
        "最低价": 24.38,
        "昨收价": 24.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 24888.0,
        "成交额": 610018.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "106.RZB"
    },
    {
        "序号": 8245,
        "name": "ScanSource Inc",
        "最新价": 36.59,
        "涨跌额": -0.06,
        "涨跌幅": -0.16,
        "开盘价": 36.62,
        "最高价": 37.22,
        "最低价": 36.51,
        "昨收价": 36.65,
        "总市值": 913540005.0,
        "市盈率": 11.25,
        "成交量": 185404.0,
        "成交额": 6809519.0,
        "振幅": 1.94,
        "换手率": 0.74,
        "symbol": "105.SCSC"
    },
    {
        "序号": 8246,
        "name": "EuroDry Ltd",
        "最新价": 18.27,
        "涨跌额": -0.03,
        "涨跌幅": -0.16,
        "开盘价": 18.25,
        "最高价": 18.85,
        "最低价": 18.25,
        "昨收价": 18.3,
        "总市值": 50899434.0,
        "市盈率": 16.9,
        "成交量": 5727.0,
        "成交额": 105797.0,
        "振幅": 3.28,
        "换手率": 0.21,
        "symbol": "105.EDRY"
    },
    {
        "序号": 8247,
        "name": "Ford Motor Co Notes",
        "最新价": 24.33,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.28,
        "最高价": 24.43,
        "最低价": 24.18,
        "昨收价": 24.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 43566.0,
        "成交额": 1057371.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.F_B"
    },
    {
        "序号": 8248,
        "name": "CMS Energy Corp Notes",
        "最新价": 24.27,
        "涨跌额": -0.04,
        "涨跌幅": -0.16,
        "开盘价": 24.29,
        "最高价": 24.31,
        "最低价": 24.06,
        "昨收价": 24.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 25640.0,
        "成交额": 620339.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.CMSD"
    },
    {
        "序号": 8249,
        "name": "BlackRock Energy and Resources ",
        "最新价": 12.11,
        "涨跌额": -0.02,
        "涨跌幅": -0.16,
        "开盘价": 12.15,
        "最高价": 12.22,
        "最低价": 12.02,
        "昨收价": 12.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 97087.0,
        "成交额": 1173063.0,
        "振幅": 1.65,
        "换手率": null,
        "symbol": "106.BGR"
    },
    {
        "序号": 8250,
        "name": "JPMorgan Equity Premium Income ",
        "最新价": 54.39,
        "涨跌额": -0.09,
        "涨跌幅": -0.17,
        "开盘价": 54.41,
        "最高价": 54.46,
        "最低价": 54.27,
        "昨收价": 54.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 2869101.0,
        "成交额": 155994757.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.JEPI"
    },
    {
        "序号": 8251,
        "name": "BondBloxx USD High Yield Bond F",
        "最新价": 36.18,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 36.26,
        "最高价": 36.26,
        "最低价": 36.18,
        "昨收价": 36.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 212.0,
        "成交额": 7687.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.XHYF"
    },
    {
        "序号": 8252,
        "name": "康卡斯特",
        "最新价": 42.1,
        "涨跌额": -0.07,
        "涨跌幅": -0.17,
        "开盘价": 41.7,
        "最高价": 42.24,
        "最低价": 41.66,
        "昨收价": 42.17,
        "总市值": 169455857433.0,
        "市盈率": 11.18,
        "成交量": 20117290.0,
        "成交额": 845497792.0,
        "振幅": 1.38,
        "换手率": 0.5,
        "symbol": "105.CMCSA"
    },
    {
        "序号": 8253,
        "name": "IQ MacKay Municipal Insured ETF",
        "最新价": 24.05,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 24.1,
        "最高价": 24.1,
        "最低价": 24.03,
        "昨收价": 24.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 27844.0,
        "成交额": 669860.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.MMIN"
    },
    {
        "序号": 8254,
        "name": "Sachem Capital Corp Notes",
        "最新价": 23.96,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.96,
        "最高价": 23.96,
        "最低价": 23.96,
        "昨收价": 24.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 202.0,
        "成交额": 4839.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SCCC"
    },
    {
        "序号": 8255,
        "name": "葛兰素史克(US)",
        "最新价": 35.94,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 35.92,
        "最高价": 36.09,
        "最低价": 35.84,
        "昨收价": 36.0,
        "总市值": 73947362891.0,
        "市盈率": 9.2,
        "成交量": 1507605.0,
        "成交额": 54226334.0,
        "振幅": 0.69,
        "换手率": 0.07,
        "symbol": "106.GSK"
    },
    {
        "序号": 8256,
        "name": "American Financial Group Inc No",
        "最新价": 17.96,
        "涨跌额": -0.03,
        "涨跌幅": -0.17,
        "开盘价": 17.8,
        "最高价": 18.03,
        "最低价": 17.8,
        "昨收价": 17.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 4946.0,
        "成交额": 88454.0,
        "振幅": 1.28,
        "换手率": null,
        "symbol": "106.AFGE"
    },
    {
        "序号": 8257,
        "name": "Invesco NASDAQ Future Gen 200 E",
        "最新价": 23.92,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.97,
        "最高价": 24.16,
        "最低价": 23.92,
        "昨收价": 23.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 4944.0,
        "成交额": 118711.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "105.QQQS"
    },
    {
        "序号": 8258,
        "name": "房地产信托指数ETF-Vanguard",
        "最新价": 83.64,
        "涨跌额": -0.14,
        "涨跌幅": -0.17,
        "开盘价": 83.49,
        "最高价": 83.85,
        "最低价": 82.79,
        "昨收价": 83.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 3416903.0,
        "成交额": 285015104.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.VNQ"
    },
    {
        "序号": 8259,
        "name": "Invesco S&P International Devel",
        "最新价": 35.79,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 35.53,
        "最高价": 35.79,
        "最低价": 35.53,
        "昨收价": 35.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 11695.0,
        "成交额": 417477.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.IDMO"
    },
    {
        "序号": 8260,
        "name": "National Storage Affiliates Tru",
        "最新价": 23.85,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.88,
        "最高价": 23.98,
        "最低价": 23.55,
        "昨收价": 23.89,
        "总市值": 215069474.0,
        "市盈率": null,
        "成交量": 36499.0,
        "成交额": 869903.0,
        "振幅": 1.8,
        "换手率": 0.4,
        "symbol": "106.NSA_A"
    },
    {
        "序号": 8261,
        "name": "La-Z-Boy Inc",
        "最新价": 35.73,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 35.76,
        "最高价": 36.21,
        "最低价": 35.37,
        "昨收价": 35.79,
        "总市值": 1528512250.0,
        "市盈率": 12.66,
        "成交量": 326164.0,
        "成交额": 11636595.0,
        "振幅": 2.35,
        "换手率": 0.76,
        "symbol": "106.LZB"
    },
    {
        "序号": 8262,
        "name": "Natixis Loomis Sayles Short Dur",
        "最新价": 23.78,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.79,
        "最高价": 23.8,
        "最低价": 23.78,
        "昨收价": 23.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 5373.0,
        "成交额": 127820.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.LSST"
    },
    {
        "序号": 8263,
        "name": "FlexShares Real Assets Allocati",
        "最新价": 29.65,
        "涨跌额": -0.05,
        "涨跌幅": -0.17,
        "开盘价": 29.61,
        "最高价": 29.66,
        "最低价": 29.58,
        "昨收价": 29.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 4971.0,
        "成交额": 147309.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "105.ASET"
    },
    {
        "序号": 8264,
        "name": "小型股ETF-iShares MSCI EAFE",
        "最新价": 59.27,
        "涨跌额": -0.1,
        "涨跌幅": -0.17,
        "开盘价": 59.02,
        "最高价": 59.56,
        "最低价": 58.95,
        "昨收价": 59.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 955870.0,
        "成交额": 56610717.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "105.SCZ"
    },
    {
        "序号": 8265,
        "name": "First Trust Enhanced Equity Inc",
        "最新价": 17.76,
        "涨跌额": -0.03,
        "涨跌幅": -0.17,
        "开盘价": 17.78,
        "最高价": 17.78,
        "最低价": 17.67,
        "昨收价": 17.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 16703.0,
        "成交额": 296050.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "106.FFA"
    },
    {
        "序号": 8266,
        "name": "3D系统",
        "最新价": 5.92,
        "涨跌额": -0.01,
        "涨跌幅": -0.17,
        "开盘价": 5.86,
        "最高价": 6.02,
        "最低价": 5.78,
        "昨收价": 5.93,
        "总市值": 789929570.0,
        "市盈率": -8.27,
        "成交量": 1622715.0,
        "成交额": 9564270.0,
        "振幅": 4.05,
        "换手率": 1.22,
        "symbol": "106.DDD"
    },
    {
        "序号": 8267,
        "name": "Ellington Financial Inc Series ",
        "最新价": 23.6,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.23,
        "最高价": 23.6,
        "最低价": 23.23,
        "昨收价": 23.64,
        "总市值": 94400000.0,
        "市盈率": null,
        "成交量": 2314.0,
        "成交额": 54387.0,
        "振幅": 1.57,
        "换手率": 0.06,
        "symbol": "106.EFC_C"
    },
    {
        "序号": 8268,
        "name": "Avantis Real Estate ETF",
        "最新价": 41.18,
        "涨跌额": -0.07,
        "涨跌幅": -0.17,
        "开盘价": 41.12,
        "最高价": 41.18,
        "最低价": 40.89,
        "昨收价": 41.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 63988.0,
        "成交额": 2626296.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.AVRE"
    },
    {
        "序号": 8269,
        "name": "Xtrackers USD High Yield BB-B e",
        "最新价": 52.78,
        "涨跌额": -0.09,
        "涨跌幅": -0.17,
        "开盘价": 52.78,
        "最高价": 52.78,
        "最低价": 52.78,
        "昨收价": 52.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 52.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BHYB"
    },
    {
        "序号": 8270,
        "name": "胜牌",
        "最新价": 35.05,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 35.24,
        "最高价": 35.48,
        "最低价": 34.77,
        "昨收价": 35.11,
        "总市值": 4579283902.0,
        "市盈率": 3.23,
        "成交量": 933713.0,
        "成交额": 32694248.0,
        "振幅": 2.02,
        "换手率": 0.71,
        "symbol": "106.VVV"
    },
    {
        "序号": 8271,
        "name": "Invesco S&P 500 ex-Rate Sensiti",
        "最新价": 46.62,
        "涨跌额": -0.08,
        "涨跌幅": -0.17,
        "开盘价": 46.63,
        "最高价": 46.66,
        "最低价": 46.62,
        "昨收价": 46.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 949.0,
        "成交额": 44262.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.XRLV"
    },
    {
        "序号": 8272,
        "name": "FlexShares iBoxx 3 Year Target ",
        "最新价": 23.31,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.3,
        "最高价": 23.32,
        "最低价": 23.28,
        "昨收价": 23.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 315119.0,
        "成交额": 7343054.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.TDTT"
    },
    {
        "序号": 8273,
        "name": "SPDR S&P International Dividend",
        "最新价": 34.91,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "开盘价": 34.83,
        "最高价": 35.0,
        "最低价": 34.82,
        "昨收价": 34.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 24909.0,
        "成交额": 868695.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.DWX"
    },
    {
        "序号": 8274,
        "name": "Direxion Hydrogen ETF",
        "最新价": 11.62,
        "涨跌额": -0.02,
        "涨跌幅": -0.17,
        "开盘价": 11.6,
        "最高价": 11.69,
        "最低价": 11.58,
        "昨收价": 11.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 12487.0,
        "成交额": 144999.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.HJEN"
    },
    {
        "序号": 8275,
        "name": "Templeton Emerging Markets Fund",
        "最新价": 11.62,
        "涨跌额": -0.02,
        "涨跌幅": -0.17,
        "开盘价": 11.56,
        "最高价": 11.67,
        "最低价": 11.56,
        "昨收价": 11.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 14358.0,
        "成交额": 166890.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.EMF"
    },
    {
        "序号": 8276,
        "name": "Eaton Vance High Yield ETF",
        "最新价": 52.16,
        "涨跌额": -0.09,
        "涨跌幅": -0.17,
        "开盘价": 52.13,
        "最高价": 52.16,
        "最低价": 52.12,
        "昨收价": 52.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 311.0,
        "成交额": 16209.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.EVHY"
    },
    {
        "序号": 8277,
        "name": "BNY Mellon High Yield Beta ETF",
        "最新价": 46.36,
        "涨跌额": -0.08,
        "涨跌幅": -0.17,
        "开盘价": 46.36,
        "最高价": 46.41,
        "最低价": 46.25,
        "昨收价": 46.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 4854.0,
        "成交额": 225033.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.BKHY"
    },
    {
        "序号": 8278,
        "name": "Sylvamo Corp",
        "最新价": 52.14,
        "涨跌额": -0.09,
        "涨跌幅": -0.17,
        "开盘价": 51.94,
        "最高价": 52.69,
        "最低价": 51.87,
        "昨收价": 52.23,
        "总市值": 2166608875.0,
        "市盈率": 7.27,
        "成交量": 201161.0,
        "成交额": 10509960.0,
        "振幅": 1.57,
        "换手率": 0.48,
        "symbol": "106.SLVM"
    },
    {
        "序号": 8279,
        "name": "Knowledge Leaders Developed Wor",
        "最新价": 40.54,
        "涨跌额": -0.07,
        "涨跌幅": -0.17,
        "开盘价": 40.39,
        "最高价": 40.55,
        "最低价": 40.38,
        "昨收价": 40.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 7036.0,
        "成交额": 284806.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.KLDW"
    },
    {
        "序号": 8280,
        "name": "iShares iBonds Dec 2025 Term Tr",
        "最新价": 23.16,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 23.17,
        "最高价": 23.18,
        "最低价": 23.15,
        "昨收价": 23.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 464881.0,
        "成交额": 10765580.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "105.IBTF"
    },
    {
        "序号": 8281,
        "name": "康沃系统",
        "最新价": 75.24,
        "涨跌额": -0.13,
        "涨跌幅": -0.17,
        "开盘价": 75.35,
        "最高价": 76.05,
        "最低价": 74.79,
        "昨收价": 75.37,
        "总市值": 3294944239.0,
        "市盈率": -181.47,
        "成交量": 141070.0,
        "成交额": 10620712.0,
        "振幅": 1.67,
        "换手率": 0.32,
        "symbol": "105.CVLT"
    },
    {
        "序号": 8282,
        "name": "Schwab Municipal Bond ETF",
        "最新价": 51.86,
        "涨跌额": -0.09,
        "涨跌幅": -0.17,
        "开盘价": 51.94,
        "最高价": 51.95,
        "最低价": 51.76,
        "昨收价": 51.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 44773.0,
        "成交额": 2321225.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.SCMB"
    },
    {
        "序号": 8283,
        "name": "联合治疗",
        "最新价": 241.65,
        "涨跌额": -0.42,
        "涨跌幅": -0.17,
        "开盘价": 243.17,
        "最高价": 244.41,
        "最低价": 240.09,
        "昨收价": 242.07,
        "总市值": 11356003682.0,
        "市盈率": 12.62,
        "成交量": 210218.0,
        "成交额": 50861088.0,
        "振幅": 1.78,
        "换手率": 0.45,
        "symbol": "105.UTHR"
    },
    {
        "序号": 8284,
        "name": "高尔史密斯国际控股",
        "最新价": 57.19,
        "涨跌额": -0.1,
        "涨跌幅": -0.17,
        "开盘价": 57.31,
        "最高价": 57.82,
        "最低价": 56.34,
        "昨收价": 57.29,
        "总市值": 3738952893.0,
        "市盈率": 16.6,
        "成交量": 282225.0,
        "成交额": 16111358.0,
        "振幅": 2.58,
        "换手率": 0.43,
        "symbol": "106.GOLF"
    },
    {
        "序号": 8285,
        "name": "Leidos Holdings Inc",
        "最新价": 108.49,
        "涨跌额": -0.19,
        "涨跌幅": -0.17,
        "开盘价": 108.91,
        "最高价": 109.3,
        "最低价": 108.28,
        "昨收价": 108.68,
        "总市值": 14918072374.0,
        "市盈率": 101.48,
        "成交量": 1002602.0,
        "成交额": 108856519.0,
        "振幅": 0.94,
        "换手率": 0.73,
        "symbol": "106.LDOS"
    },
    {
        "序号": 8286,
        "name": "BlackRock Future Health ETF",
        "最新价": 22.83,
        "涨跌额": -0.04,
        "涨跌幅": -0.17,
        "开盘价": 22.83,
        "最高价": 22.83,
        "最低价": 22.83,
        "昨收价": 22.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 114.0,
        "成交额": 2602.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BMED"
    },
    {
        "序号": 8287,
        "name": "Liquidity Services Inc",
        "最新价": 17.12,
        "涨跌额": -0.03,
        "涨跌幅": -0.17,
        "开盘价": 16.83,
        "最高价": 17.41,
        "最低价": 16.77,
        "昨收价": 17.15,
        "总市值": 526544928.0,
        "市盈率": 25.1,
        "成交量": 292294.0,
        "成交额": 4992530.0,
        "振幅": 3.73,
        "换手率": 0.95,
        "symbol": "105.LQDT"
    },
    {
        "序号": 8288,
        "name": "Invesco Variable Rate Preferred",
        "最新价": 22.78,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.76,
        "最高价": 22.8,
        "最低价": 22.76,
        "昨收价": 22.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 296640.0,
        "成交额": 6757296.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.VRP"
    },
    {
        "序号": 8289,
        "name": "诺和诺德",
        "最新价": 96.64,
        "涨跌额": -0.17,
        "涨跌幅": -0.18,
        "开盘价": 96.4,
        "最高价": 97.05,
        "最低价": 96.22,
        "昨收价": 96.81,
        "总市值": 431004054301.0,
        "市盈率": 38.47,
        "成交量": 3599759.0,
        "成交额": 347924544.0,
        "振幅": 0.86,
        "换手率": 0.08,
        "symbol": "106.NVO"
    },
    {
        "序号": 8290,
        "name": "罗杰斯通信",
        "最新价": 45.47,
        "涨跌额": -0.08,
        "涨跌幅": -0.18,
        "开盘价": 45.59,
        "最高价": 45.59,
        "最低价": 44.98,
        "昨收价": 45.55,
        "总市值": 24033930486.0,
        "市盈率": 31.28,
        "成交量": 234828.0,
        "成交额": 10655261.0,
        "振幅": 1.34,
        "换手率": 0.04,
        "symbol": "106.RCI"
    },
    {
        "序号": 8291,
        "name": "BlackRock MuniYield Pennsylvani",
        "最新价": 11.36,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.33,
        "最高价": 11.39,
        "最低价": 11.33,
        "昨收价": 11.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 21104.0,
        "成交额": 239789.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "106.MPA"
    },
    {
        "序号": 8292,
        "name": "Franklin FTSE Taiwan ETF",
        "最新价": 39.72,
        "涨跌额": -0.07,
        "涨跌幅": -0.18,
        "开盘价": 39.59,
        "最高价": 39.73,
        "最低价": 39.54,
        "昨收价": 39.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 24904.0,
        "成交额": 988116.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FLTW"
    },
    {
        "序号": 8293,
        "name": "Energem Corp-A",
        "最新价": 11.34,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.26,
        "最高价": 11.34,
        "最低价": 11.26,
        "昨收价": 11.36,
        "总市值": 52390879.0,
        "市盈率": 304.25,
        "成交量": 2800.0,
        "成交额": 31736.0,
        "振幅": 0.7,
        "换手率": 0.06,
        "symbol": "105.ENCP"
    },
    {
        "序号": 8294,
        "name": "Build Bond Innovation ETF",
        "最新价": 22.67,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.58,
        "最高价": 22.75,
        "最低价": 22.54,
        "昨收价": 22.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 1264.0,
        "成交额": 28566.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.BFIX"
    },
    {
        "序号": 8295,
        "name": "Xtrackers Risk Managed USD High",
        "最新价": 22.65,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.63,
        "最高价": 22.65,
        "最低价": 22.58,
        "昨收价": 22.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 12106.0,
        "成交额": 273646.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.HYRM"
    },
    {
        "序号": 8296,
        "name": "奥托立夫",
        "最新价": 101.41,
        "涨跌额": -0.18,
        "涨跌幅": -0.18,
        "开盘价": 101.52,
        "最高价": 102.63,
        "最低价": 101.38,
        "昨收价": 101.59,
        "总市值": 8533482348.0,
        "市盈率": 20.46,
        "成交量": 734476.0,
        "成交额": 74793401.0,
        "振幅": 1.23,
        "换手率": 0.87,
        "symbol": "106.ALV"
    },
    {
        "序号": 8297,
        "name": "LeaderShares Dynamic Yield ETF",
        "最新价": 22.48,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.5,
        "最高价": 22.5,
        "最低价": 22.46,
        "昨收价": 22.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 10911.0,
        "成交额": 245249.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.DYLD"
    },
    {
        "序号": 8298,
        "name": "Calamos Global Dynamic Income F",
        "最新价": 5.62,
        "涨跌额": -0.01,
        "涨跌幅": -0.18,
        "开盘价": 5.63,
        "最高价": 5.63,
        "最低价": 5.61,
        "昨收价": 5.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 116950.0,
        "成交额": 657481.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.CHW"
    },
    {
        "序号": 8299,
        "name": "Samsara Inc-A",
        "最新价": 33.67,
        "涨跌额": -0.06,
        "涨跌幅": -0.18,
        "开盘价": 33.4,
        "最高价": 34.69,
        "最低价": 32.27,
        "昨收价": 33.73,
        "总市值": 18188393158.0,
        "市盈率": -80.14,
        "成交量": 4699077.0,
        "成交额": 158362715.0,
        "振幅": 7.17,
        "换手率": 0.87,
        "symbol": "106.IOT"
    },
    {
        "序号": 8300,
        "name": "Nuveen Pennsylvania Quality Mun",
        "最新价": 11.22,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.19,
        "最高价": 11.24,
        "最低价": 11.19,
        "昨收价": 11.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 79014.0,
        "成交额": 887240.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "106.NQP"
    },
    {
        "序号": 8301,
        "name": "BlackRock MuniYield Michigan Qu",
        "最新价": 11.22,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.16,
        "最高价": 11.23,
        "最低价": 11.16,
        "昨收价": 11.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 53032.0,
        "成交额": 594118.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "106.MIY"
    },
    {
        "序号": 8302,
        "name": "Bank of America Corp Series KK ",
        "最新价": 22.41,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.36,
        "最高价": 22.46,
        "最低价": 22.27,
        "昨收价": 22.45,
        "总市值": 1252719.0,
        "市盈率": null,
        "成交量": 102341.0,
        "成交额": 2291055.0,
        "振幅": 0.85,
        "换手率": 183.08,
        "symbol": "106.BAC_M"
    },
    {
        "序号": 8303,
        "name": "International Media Acquisition",
        "最新价": 11.2,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.2,
        "最高价": 11.2,
        "最低价": 11.2,
        "昨收价": 11.22,
        "总市值": 95424202.0,
        "市盈率": -159.89,
        "成交量": 1542.0,
        "成交额": 17270.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.IMAQ"
    },
    {
        "序号": 8304,
        "name": "温德姆酒店及度假村",
        "最新价": 78.32,
        "涨跌额": -0.14,
        "涨跌幅": -0.18,
        "开盘价": 78.37,
        "最高价": 78.87,
        "最低价": 78.07,
        "昨收价": 78.46,
        "总市值": 6497576556.0,
        "市盈率": 21.95,
        "成交量": 1112126.0,
        "成交额": 87172343.0,
        "振幅": 1.02,
        "换手率": 1.34,
        "symbol": "106.WH"
    },
    {
        "序号": 8305,
        "name": "SPDR MSCI Emerging Markets Stra",
        "最新价": 55.85,
        "涨跌额": -0.1,
        "涨跌幅": -0.18,
        "开盘价": 55.64,
        "最高价": 55.85,
        "最低价": 55.56,
        "昨收价": 55.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 1937.0,
        "成交额": 107766.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.QEMM"
    },
    {
        "序号": 8306,
        "name": "Absolute Select Value ETF",
        "最新价": 27.91,
        "涨跌额": -0.05,
        "涨跌幅": -0.18,
        "开盘价": 27.96,
        "最高价": 27.99,
        "最低价": 27.83,
        "昨收价": 27.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 4155.0,
        "成交额": 115954.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.ABEQ"
    },
    {
        "序号": 8307,
        "name": "Rareview Dynamic Fixed Income E",
        "最新价": 22.22,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.22,
        "最高价": 22.24,
        "最低价": 22.2,
        "昨收价": 22.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 1889.0,
        "成交额": 41986.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.RDFI"
    },
    {
        "序号": 8308,
        "name": "Harbor Scientific Alpha High-Yi",
        "最新价": 44.39,
        "涨跌额": -0.08,
        "涨跌幅": -0.18,
        "开盘价": 44.22,
        "最高价": 44.41,
        "最低价": 44.22,
        "昨收价": 44.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 16788.0,
        "成交额": 744526.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.SIHY"
    },
    {
        "序号": 8309,
        "name": "BondBloxx B-Rated USD High Yiel",
        "最新价": 38.77,
        "涨跌额": -0.07,
        "涨跌幅": -0.18,
        "开盘价": 38.83,
        "最高价": 38.83,
        "最低价": 38.77,
        "昨收价": 38.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 213.0,
        "成交额": 8270.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.XB"
    },
    {
        "序号": 8310,
        "name": "US Bancorp Series K Pfd",
        "最新价": 22.15,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.15,
        "最高价": 22.19,
        "最低价": 22.0,
        "昨收价": 22.19,
        "总市值": 509450.0,
        "市盈率": null,
        "成交量": 65582.0,
        "成交额": 1447818.0,
        "振幅": 0.86,
        "换手率": 285.14,
        "symbol": "106.USB_P"
    },
    {
        "序号": 8311,
        "name": "PIMCO Enhanced Low Duration Act",
        "最新价": 94.11,
        "涨跌额": -0.17,
        "涨跌幅": -0.18,
        "开盘价": 94.1,
        "最高价": 94.17,
        "最低价": 94.1,
        "昨收价": 94.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 40323.0,
        "成交额": 3795601.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.LDUR"
    },
    {
        "序号": 8312,
        "name": "霍勒斯曼恩",
        "最新价": 33.21,
        "涨跌额": -0.06,
        "涨跌幅": -0.18,
        "开盘价": 33.34,
        "最高价": 33.54,
        "最低价": 33.1,
        "昨收价": 33.27,
        "总市值": 1356139051.0,
        "市盈率": -40.36,
        "成交量": 156568.0,
        "成交额": 5200256.0,
        "振幅": 1.32,
        "换手率": 0.38,
        "symbol": "106.HMN"
    },
    {
        "序号": 8313,
        "name": "奇景光电",
        "最新价": 5.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.18,
        "开盘价": 5.53,
        "最高价": 5.63,
        "最低价": 5.5,
        "昨收价": 5.54,
        "总市值": 964523383.0,
        "市盈率": 13.94,
        "成交量": 485015.0,
        "成交额": 2691374.0,
        "振幅": 2.35,
        "换手率": 0.28,
        "symbol": "105.HIMX"
    },
    {
        "序号": 8314,
        "name": "Pegasus Digital Mobility Acquis",
        "最新价": 11.05,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 11.05,
        "最高价": 11.05,
        "最低价": 11.05,
        "昨收价": 11.07,
        "总市值": 117441809.0,
        "市盈率": 50.06,
        "成交量": 165305.0,
        "成交额": 1826620.0,
        "振幅": 0.0,
        "换手率": 1.56,
        "symbol": "106.PGSS"
    },
    {
        "序号": 8315,
        "name": "iShares Global Materials ETF",
        "最新价": 82.8,
        "涨跌额": -0.15,
        "涨跌幅": -0.18,
        "开盘价": 82.36,
        "最高价": 83.03,
        "最低价": 82.36,
        "昨收价": 82.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 6964.0,
        "成交额": 576870.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.MXI"
    },
    {
        "序号": 8316,
        "name": "Pacer Lunt Large Cap Alternator",
        "最新价": 33.12,
        "涨跌额": -0.06,
        "涨跌幅": -0.18,
        "开盘价": 33.17,
        "最高价": 33.2,
        "最低价": 33.02,
        "昨收价": 33.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 146203.0,
        "成交额": 4835991.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.ALTL"
    },
    {
        "序号": 8317,
        "name": "Hartford Short Duration ETF",
        "最新价": 38.63,
        "涨跌额": -0.07,
        "涨跌幅": -0.18,
        "开盘价": 38.64,
        "最高价": 38.64,
        "最低价": 38.61,
        "昨收价": 38.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 2821.0,
        "成交额": 108971.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.HSRT"
    },
    {
        "序号": 8318,
        "name": "Fidelity Disruptive Medicine ET",
        "最新价": 22.05,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.11,
        "最高价": 22.26,
        "最低价": 22.0,
        "昨收价": 22.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 6369.0,
        "成交额": 140587.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "105.FMED"
    },
    {
        "序号": 8319,
        "name": "TrueShares Eagle Global Renewab",
        "最新价": 21.98,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 21.98,
        "最高价": 21.98,
        "最低价": 21.98,
        "昨收价": 22.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 78.0,
        "成交额": 1714.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RNWZ"
    },
    {
        "序号": 8320,
        "name": "全球林木ETF-iShares",
        "最新价": 76.91,
        "涨跌额": -0.14,
        "涨跌幅": -0.18,
        "开盘价": 76.93,
        "最高价": 77.09,
        "最低价": 76.75,
        "昨收价": 77.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 8065.0,
        "成交额": 619664.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.WOOD"
    },
    {
        "序号": 8321,
        "name": "Metal Sky Star Acquisition Corp",
        "最新价": 10.98,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 10.98,
        "最高价": 10.98,
        "最低价": 10.98,
        "昨收价": 11.0,
        "总市值": 70353428.0,
        "市盈率": 27.05,
        "成交量": 143.0,
        "成交额": 1569.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.MSSA"
    },
    {
        "序号": 8322,
        "name": "Fluence Energy Inc-A",
        "最新价": 21.93,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 22.25,
        "最高价": 22.53,
        "最低价": 21.63,
        "昨收价": 21.97,
        "总市值": 3901224192.0,
        "市盈率": -56.04,
        "成交量": 4838821.0,
        "成交额": 106439504.0,
        "振幅": 4.1,
        "换手率": 2.72,
        "symbol": "105.FLNC"
    },
    {
        "序号": 8323,
        "name": "iShares iBonds 2027 Term High Y",
        "最新价": 21.92,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 21.98,
        "最高价": 21.98,
        "最低价": 21.82,
        "昨收价": 21.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 28276.0,
        "成交额": 620316.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.IBHG"
    },
    {
        "序号": 8324,
        "name": "City Office REIT Inc",
        "最新价": 5.48,
        "涨跌额": -0.01,
        "涨跌幅": -0.18,
        "开盘价": 5.4,
        "最高价": 5.54,
        "最低价": 5.4,
        "昨收价": 5.49,
        "总市值": 218862711.0,
        "市盈率": -17.6,
        "成交量": 244317.0,
        "成交额": 1340627.0,
        "振幅": 2.55,
        "换手率": 0.61,
        "symbol": "106.CIO"
    },
    {
        "序号": 8325,
        "name": "Sizzle Acquisition Corp",
        "最新价": 10.95,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 10.95,
        "最高价": 10.95,
        "最低价": 10.95,
        "昨收价": 10.97,
        "总市值": 102455350.0,
        "市盈率": -756.26,
        "成交量": 6546.0,
        "成交额": 71677.0,
        "振幅": 0.0,
        "换手率": 0.07,
        "symbol": "105.SZZL"
    },
    {
        "序号": 8326,
        "name": "American Financial Group Inc De",
        "最新价": 21.88,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 21.85,
        "最高价": 21.99,
        "最低价": 21.67,
        "昨收价": 21.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 3717.0,
        "成交额": 81243.0,
        "振幅": 1.46,
        "换手率": null,
        "symbol": "106.AFGD"
    },
    {
        "序号": 8327,
        "name": "爱可品牌",
        "最新价": 5.45,
        "涨跌额": -0.01,
        "涨跌幅": -0.18,
        "开盘价": 5.43,
        "最高价": 5.5,
        "最低价": 5.41,
        "昨收价": 5.46,
        "总市值": 517344803.0,
        "市盈率": 9.17,
        "成交量": 274036.0,
        "成交额": 1494826.0,
        "振幅": 1.65,
        "换手率": 0.29,
        "symbol": "106.ACCO"
    },
    {
        "序号": 8328,
        "name": "Dimensional US Real Estate ETF",
        "最新价": 21.65,
        "涨跌额": -0.04,
        "涨跌幅": -0.18,
        "开盘价": 21.64,
        "最高价": 21.69,
        "最低价": 21.43,
        "昨收价": 21.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 222572.0,
        "成交额": 4801611.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "107.DFAR"
    },
    {
        "序号": 8329,
        "name": "Braze Inc-A",
        "最新价": 54.09,
        "涨跌额": -0.1,
        "涨跌幅": -0.18,
        "开盘价": 54.06,
        "最高价": 55.8,
        "最低价": 53.5,
        "昨收价": 54.19,
        "总市值": 5400477255.0,
        "市盈率": -40.2,
        "成交量": 713342.0,
        "成交额": 38770850.0,
        "振幅": 4.24,
        "换手率": 0.71,
        "symbol": "105.BRZE"
    },
    {
        "序号": 8330,
        "name": "ProShares S&P 500 Dividend Aris",
        "最新价": 91.93,
        "涨跌额": -0.17,
        "涨跌幅": -0.18,
        "开盘价": 92.06,
        "最高价": 92.38,
        "最低价": 91.75,
        "昨收价": 92.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 523449.0,
        "成交额": 48134667.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "107.NOBL"
    },
    {
        "序号": 8331,
        "name": "Integral Acquisition Corp 1-A",
        "最新价": 10.8,
        "涨跌额": -0.02,
        "涨跌幅": -0.18,
        "开盘价": 10.82,
        "最高价": 10.85,
        "最低价": 10.8,
        "昨收价": 10.82,
        "总市值": 43992094.0,
        "市盈率": 80.5,
        "成交量": 4700.0,
        "成交额": 50797.0,
        "振幅": 0.46,
        "换手率": 0.12,
        "symbol": "105.INTE"
    },
    {
        "序号": 8332,
        "name": "Nuveen AMT-Free Quality Municip",
        "最新价": 10.79,
        "涨跌额": -0.02,
        "涨跌幅": -0.19,
        "开盘价": 10.76,
        "最高价": 10.81,
        "最低价": 10.74,
        "昨收价": 10.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 891695.0,
        "成交额": 9601780.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "106.NEA"
    },
    {
        "序号": 8333,
        "name": "台湾ETF-iShares MSCI",
        "最新价": 48.55,
        "涨跌额": -0.09,
        "涨跌幅": -0.19,
        "开盘价": 48.33,
        "最高价": 48.56,
        "最低价": 48.3,
        "昨收价": 48.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 1739514.0,
        "成交额": 84276165.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.EWT"
    },
    {
        "序号": 8334,
        "name": "Global X Interest Rate Volatili",
        "最新价": 21.54,
        "涨跌额": -0.04,
        "涨跌幅": -0.19,
        "开盘价": 21.54,
        "最高价": 21.54,
        "最低价": 21.54,
        "昨收价": 21.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 18.0,
        "成交额": 387.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IRVH"
    },
    {
        "序号": 8335,
        "name": "Allegro MicroSystems Inc",
        "最新价": 26.84,
        "涨跌额": -0.05,
        "涨跌幅": -0.19,
        "开盘价": 26.81,
        "最高价": 27.23,
        "最低价": 26.65,
        "昨收价": 26.89,
        "总市值": 5165997839.0,
        "市盈率": 20.42,
        "成交量": 744343.0,
        "成交额": 19973510.0,
        "振幅": 2.16,
        "换手率": 0.39,
        "symbol": "105.ALGM"
    },
    {
        "序号": 8336,
        "name": "ALPS REIT Dividend Dogs ETF",
        "最新价": 37.29,
        "涨跌额": -0.07,
        "涨跌幅": -0.19,
        "开盘价": 37.29,
        "最高价": 37.29,
        "最低价": 37.29,
        "昨收价": 37.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 23.0,
        "成交额": 857.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RDOG"
    },
    {
        "序号": 8337,
        "name": "Sachem Capital Corp Notes",
        "最新价": 21.25,
        "涨跌额": -0.04,
        "涨跌幅": -0.19,
        "开盘价": 21.25,
        "最高价": 21.25,
        "最低价": 21.25,
        "昨收价": 21.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 1474.0,
        "成交额": 31322.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SCCE"
    },
    {
        "序号": 8338,
        "name": "Invesco BulletShares 2031 Munic",
        "最新价": 21.24,
        "涨跌额": -0.04,
        "涨跌幅": -0.19,
        "开盘价": 21.23,
        "最高价": 21.31,
        "最低价": 21.2,
        "昨收价": 21.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 15430.0,
        "成交额": 327909.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "105.BSMV"
    },
    {
        "序号": 8339,
        "name": "iShares U.S. Utilities ETF",
        "最新价": 79.44,
        "涨跌额": -0.15,
        "涨跌幅": -0.19,
        "开盘价": 79.49,
        "最高价": 79.55,
        "最低价": 78.95,
        "昨收价": 79.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 75140.0,
        "成交额": 5950552.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.IDU"
    },
    {
        "序号": 8340,
        "name": "First Citizens BancShares Inc S",
        "最新价": 21.15,
        "涨跌额": -0.04,
        "涨跌幅": -0.19,
        "开盘价": 21.2,
        "最高价": 21.34,
        "最低价": 20.87,
        "昨收价": 21.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 22011.0,
        "成交额": 463406.0,
        "振幅": 2.22,
        "换手率": null,
        "symbol": "105.FCNCO"
    },
    {
        "序号": 8341,
        "name": "公用事业ETF-SPDR",
        "最新价": 63.41,
        "涨跌额": -0.12,
        "涨跌幅": -0.19,
        "开盘价": 63.43,
        "最高价": 63.7,
        "最低价": 62.94,
        "昨收价": 63.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 12001458.0,
        "成交额": 759576352.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "107.XLU"
    },
    {
        "序号": 8342,
        "name": "SPDR Nuveen Bloomberg Short Ter",
        "最新价": 47.53,
        "涨跌额": -0.09,
        "涨跌幅": -0.19,
        "开盘价": 47.53,
        "最高价": 47.57,
        "最低价": 47.51,
        "昨收价": 47.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 379028.0,
        "成交额": 18020524.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.SHM"
    },
    {
        "序号": 8343,
        "name": "ProShares Smart Materials ETF",
        "最新价": 31.62,
        "涨跌额": -0.06,
        "涨跌幅": -0.19,
        "开盘价": 31.62,
        "最高价": 31.62,
        "最低价": 31.62,
        "昨收价": 31.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 14.0,
        "成交额": 442.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TINT"
    },
    {
        "序号": 8344,
        "name": "FTAC Emerald Acquisition Cor-A",
        "最新价": 10.53,
        "涨跌额": -0.02,
        "涨跌幅": -0.19,
        "开盘价": 10.52,
        "最高价": 10.55,
        "最低价": 10.52,
        "昨收价": 10.55,
        "总市值": 265581047.0,
        "市盈率": 49.15,
        "成交量": 740.0,
        "成交额": 7796.0,
        "振幅": 0.28,
        "换手率": 0.0,
        "symbol": "105.EMLD"
    },
    {
        "序号": 8345,
        "name": "Genenta Science SpA ADR",
        "最新价": 5.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.19,
        "开盘价": 5.24,
        "最高价": 5.24,
        "最低价": 5.24,
        "昨收价": 5.25,
        "总市值": 94949397.0,
        "市盈率": -6.69,
        "成交量": 300.0,
        "成交额": 1570.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.GNTA"
    },
    {
        "序号": 8346,
        "name": "Aspen Insurance Holdings Ltd Pf",
        "最新价": 26.07,
        "涨跌额": -0.05,
        "涨跌幅": -0.19,
        "开盘价": 26.06,
        "最高价": 26.1,
        "最低价": 25.75,
        "昨收价": 26.12,
        "总市值": 286770000.0,
        "市盈率": null,
        "成交量": 14640.0,
        "成交额": 380562.0,
        "振幅": 1.34,
        "换手率": 0.13,
        "symbol": "106.AHL_C"
    },
    {
        "序号": 8347,
        "name": "Neuberger Berman Global Real Es",
        "最新价": 26.06,
        "涨跌额": -0.05,
        "涨跌幅": -0.19,
        "开盘价": 26.06,
        "最高价": 26.06,
        "最低价": 26.06,
        "昨收价": 26.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 260.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.NBGR"
    },
    {
        "序号": 8348,
        "name": "CONMED Corp",
        "最新价": 109.37,
        "涨跌额": -0.21,
        "涨跌幅": -0.19,
        "开盘价": 109.56,
        "最高价": 110.78,
        "最低价": 108.72,
        "昨收价": 109.58,
        "总市值": 3363368005.0,
        "市盈率": 58.02,
        "成交量": 213190.0,
        "成交额": 23327307.0,
        "振幅": 1.88,
        "换手率": 0.69,
        "symbol": "106.CNMD"
    },
    {
        "序号": 8349,
        "name": "InfuSystem Holdings Inc",
        "最新价": 10.4,
        "涨跌额": -0.02,
        "涨跌幅": -0.19,
        "开盘价": 10.43,
        "最高价": 10.49,
        "最低价": 10.1,
        "昨收价": 10.42,
        "总市值": 220308670.0,
        "市盈率": 242.9,
        "成交量": 31808.0,
        "成交额": 328886.0,
        "振幅": 3.74,
        "换手率": 0.15,
        "symbol": "107.INFU"
    },
    {
        "序号": 8350,
        "name": "Parametric Hedged Equity ETF",
        "最新价": 25.94,
        "涨跌额": -0.05,
        "涨跌幅": -0.19,
        "开盘价": 25.94,
        "最高价": 25.94,
        "最低价": 25.94,
        "昨收价": 25.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 181.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PHEQ"
    },
    {
        "序号": 8351,
        "name": "Triumph Financial Inc",
        "最新价": 72.21,
        "涨跌额": -0.14,
        "涨跌幅": -0.19,
        "开盘价": 72.12,
        "最高价": 73.55,
        "最低价": 71.58,
        "昨收价": 72.35,
        "总市值": 1681894451.0,
        "市盈率": 34.31,
        "成交量": 109139.0,
        "成交额": 7902639.0,
        "振幅": 2.72,
        "换手率": 0.47,
        "symbol": "105.TFIN"
    },
    {
        "序号": 8352,
        "name": "Vaxcyte Inc",
        "最新价": 56.67,
        "涨跌额": -0.11,
        "涨跌幅": -0.19,
        "开盘价": 56.79,
        "最高价": 57.21,
        "最低价": 55.78,
        "昨收价": 56.78,
        "总市值": 5392372023.0,
        "市盈率": -18.0,
        "成交量": 700330.0,
        "成交额": 39626453.0,
        "振幅": 2.52,
        "换手率": 0.74,
        "symbol": "105.PCVX"
    },
    {
        "序号": 8353,
        "name": "BancFirst Corp Pfd",
        "最新价": 25.75,
        "涨跌额": -0.05,
        "涨跌幅": -0.19,
        "开盘价": 25.77,
        "最高价": 25.77,
        "最低价": 25.75,
        "昨收价": 25.8,
        "总市值": 25750000.0,
        "市盈率": null,
        "成交量": 746.0,
        "成交额": 19215.0,
        "振幅": 0.08,
        "换手率": 0.07,
        "symbol": "105.BANFP"
    },
    {
        "序号": 8354,
        "name": "First Trust TCW Securitized Plu",
        "最新价": 20.59,
        "涨跌额": -0.04,
        "涨跌幅": -0.19,
        "开盘价": 20.51,
        "最高价": 20.59,
        "最低价": 20.51,
        "昨收价": 20.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 3539.0,
        "成交额": 72737.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.DEED"
    },
    {
        "序号": 8355,
        "name": "SPDR Nuveen Bloomberg Municipal",
        "最新价": 46.19,
        "涨跌额": -0.09,
        "涨跌幅": -0.19,
        "开盘价": 46.15,
        "最高价": 46.42,
        "最低价": 46.11,
        "昨收价": 46.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 1047546.0,
        "成交额": 48427602.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.TFI"
    },
    {
        "序号": 8356,
        "name": "宏盟集团",
        "最新价": 81.94,
        "涨跌额": -0.16,
        "涨跌幅": -0.19,
        "开盘价": 82.05,
        "最高价": 82.2,
        "最低价": 81.44,
        "昨收价": 82.1,
        "总市值": 16218725890.0,
        "市盈率": 11.62,
        "成交量": 1077489.0,
        "成交额": 88264260.0,
        "振幅": 0.93,
        "换手率": 0.54,
        "symbol": "106.OMC"
    },
    {
        "序号": 8357,
        "name": "BlackRock Credit Allocation Inc",
        "最新价": 10.23,
        "涨跌额": -0.02,
        "涨跌幅": -0.2,
        "开盘价": 10.19,
        "最高价": 10.24,
        "最低价": 10.18,
        "昨收价": 10.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 195530.0,
        "成交额": 1994073.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "106.BTZ"
    },
    {
        "序号": 8358,
        "name": "新兴市场基础设施ETF-iShares",
        "最新价": 20.45,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "开盘价": 20.35,
        "最高价": 20.45,
        "最低价": 20.32,
        "昨收价": 20.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 8632.0,
        "成交额": 175557.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.EMIF"
    },
    {
        "序号": 8359,
        "name": "Thunder Bridge Capital Partners",
        "最新价": 10.22,
        "涨跌额": -0.02,
        "涨跌幅": -0.2,
        "开盘价": 10.23,
        "最高价": 10.23,
        "最低价": 10.22,
        "昨收价": 10.24,
        "总市值": 103000614.0,
        "市盈率": 27.07,
        "成交量": 270010.0,
        "成交额": 2759510.0,
        "振幅": 0.1,
        "换手率": 2.68,
        "symbol": "105.THCP"
    },
    {
        "序号": 8360,
        "name": "美国优先股ETF-iShares",
        "最新价": 30.66,
        "涨跌额": -0.06,
        "涨跌幅": -0.2,
        "开盘价": 30.57,
        "最高价": 30.71,
        "最低价": 30.53,
        "昨收价": 30.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 4632799.0,
        "成交额": 141894760.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "105.PFF"
    },
    {
        "序号": 8361,
        "name": "Columbia Research Enhanced Real",
        "最新价": 20.4,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "开盘价": 20.4,
        "最高价": 20.4,
        "最低价": 20.4,
        "昨收价": 20.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 163.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CRED"
    },
    {
        "序号": 8362,
        "name": "美国废物管理",
        "最新价": 172.5,
        "涨跌额": -0.34,
        "涨跌幅": -0.2,
        "开盘价": 172.38,
        "最高价": 173.24,
        "最低价": 171.15,
        "昨收价": 172.84,
        "总市值": 69478761158.0,
        "市盈率": 30.08,
        "成交量": 1638504.0,
        "成交额": 282114416.0,
        "振幅": 1.21,
        "换手率": 0.41,
        "symbol": "106.WM"
    },
    {
        "序号": 8363,
        "name": "公用事业指数ETF-Vanguard",
        "最新价": 136.96,
        "涨跌额": -0.27,
        "涨跌幅": -0.2,
        "开盘价": 137.17,
        "最高价": 137.56,
        "最低价": 135.96,
        "昨收价": 137.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 252842.0,
        "成交额": 34580063.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "107.VPU"
    },
    {
        "序号": 8364,
        "name": "Zions Bancorp NA Notes",
        "最新价": 25.35,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.3,
        "最高价": 25.4,
        "最低价": 25.28,
        "昨收价": 25.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 4010.0,
        "成交额": 101522.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "105.ZIONL"
    },
    {
        "序号": 8365,
        "name": "Touchstone Securitized Income E",
        "最新价": 25.35,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.37,
        "最高价": 25.38,
        "最低价": 25.34,
        "昨收价": 25.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 2472.0,
        "成交额": 62669.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.TSEC"
    },
    {
        "序号": 8366,
        "name": "Hartford Schroders Commodity St",
        "最新价": 15.21,
        "涨跌额": -0.03,
        "涨跌幅": -0.2,
        "开盘价": 15.21,
        "最高价": 15.21,
        "最低价": 15.21,
        "昨收价": 15.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 158.0,
        "成交额": 2402.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HCOM"
    },
    {
        "序号": 8367,
        "name": "iShares iBonds Oct 2032 Term TI",
        "最新价": 25.33,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.28,
        "最高价": 25.33,
        "最低价": 25.22,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 8931.0,
        "成交额": 225519.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.IBII"
    },
    {
        "序号": 8368,
        "name": "iShares iBonds Oct 2031 Term TI",
        "最新价": 25.33,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.28,
        "最高价": 25.33,
        "最低价": 25.22,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 14022.0,
        "成交额": 354223.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.IBIH"
    },
    {
        "序号": 8369,
        "name": "Capital Group Short Duration In",
        "最新价": 25.32,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.34,
        "最高价": 25.36,
        "最低价": 25.29,
        "昨收价": 25.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 78342.0,
        "成交额": 1983378.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.CGSD"
    },
    {
        "序号": 8370,
        "name": "iShares iBonds Oct 2030 Term TI",
        "最新价": 25.31,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.28,
        "最高价": 25.31,
        "最低价": 25.25,
        "昨收价": 25.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 891.0,
        "成交额": 22504.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.IBIG"
    },
    {
        "序号": 8371,
        "name": "Tectonic Financial Inc Series B",
        "最新价": 10.12,
        "涨跌额": -0.02,
        "涨跌幅": -0.2,
        "开盘价": 10.12,
        "最高价": 10.12,
        "最低价": 10.1,
        "昨收价": 10.14,
        "总市值": 17457000.0,
        "市盈率": null,
        "成交量": 1027.0,
        "成交额": 10389.0,
        "振幅": 0.2,
        "换手率": 0.06,
        "symbol": "105.TECTP"
    },
    {
        "序号": 8372,
        "name": "Dimensional Inflation-Protected",
        "最新价": 40.48,
        "涨跌额": -0.08,
        "涨跌幅": -0.2,
        "开盘价": 40.38,
        "最高价": 40.48,
        "最低价": 40.34,
        "昨收价": 40.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 83866.0,
        "成交额": 3389636.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.DFIP"
    },
    {
        "序号": 8373,
        "name": "高收益公司债ETF-iShares iBoxx",
        "最新价": 75.86,
        "涨跌额": -0.15,
        "涨跌幅": -0.2,
        "开盘价": 75.81,
        "最高价": 75.91,
        "最低价": 75.67,
        "昨收价": 76.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 30123193.0,
        "成交额": 2283190160.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.HYG"
    },
    {
        "序号": 8374,
        "name": "iShares iBonds Oct 2026 Term TI",
        "最新价": 25.28,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.29,
        "最高价": 25.29,
        "最低价": 25.25,
        "昨收价": 25.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 5777.0,
        "成交额": 146001.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IBIC"
    },
    {
        "序号": 8375,
        "name": "iShares International Aggregate",
        "最新价": 50.51,
        "涨跌额": -0.1,
        "涨跌幅": -0.2,
        "开盘价": 50.43,
        "最高价": 50.58,
        "最低价": 50.42,
        "昨收价": 50.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 328092.0,
        "成交额": 16560108.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.IAGG"
    },
    {
        "序号": 8376,
        "name": "PGIM Short Duration Multi-Secto",
        "最新价": 50.5,
        "涨跌额": -0.1,
        "涨跌幅": -0.2,
        "开盘价": 50.52,
        "最高价": 50.53,
        "最低价": 50.5,
        "昨收价": 50.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 2103.0,
        "成交额": 106253.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.PSDM"
    },
    {
        "序号": 8377,
        "name": "Eaton Vance California Municipa",
        "最新价": 10.1,
        "涨跌额": -0.02,
        "涨跌幅": -0.2,
        "开盘价": 10.09,
        "最高价": 10.1,
        "最低价": 10.05,
        "昨收价": 10.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 40674.0,
        "成交额": 409802.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.CEV"
    },
    {
        "序号": 8378,
        "name": "iShares Edge High Yield Defensi",
        "最新价": 45.44,
        "涨跌额": -0.09,
        "涨跌幅": -0.2,
        "开盘价": 45.44,
        "最高价": 45.49,
        "最低价": 45.35,
        "昨收价": 45.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 177718.0,
        "成交额": 8068307.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.HYDB"
    },
    {
        "序号": 8379,
        "name": "Teucrium Agricultural Fund ETV",
        "最新价": 30.26,
        "涨跌额": -0.06,
        "涨跌幅": -0.2,
        "开盘价": 30.36,
        "最高价": 30.43,
        "最低价": 30.18,
        "昨收价": 30.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 3015.0,
        "成交额": 91574.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.TAGS"
    },
    {
        "序号": 8380,
        "name": "瑞杰金融(优先股)",
        "最新价": 25.15,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.22,
        "最高价": 25.22,
        "最低价": 25.15,
        "昨收价": 25.2,
        "总市值": 2024575.0,
        "市盈率": null,
        "成交量": 967.0,
        "成交额": 24355.0,
        "振幅": 0.28,
        "换手率": 1.2,
        "symbol": "106.RJF_B"
    },
    {
        "序号": 8381,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 20.07,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "开盘价": 20.09,
        "最高价": 20.5,
        "最低价": 20.07,
        "昨收价": 20.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1817.0,
        "成交额": 36739.0,
        "振幅": 2.14,
        "换手率": null,
        "symbol": "107.PCG_A"
    },
    {
        "序号": 8382,
        "name": "Vanguard Core Bond ETF",
        "最新价": 75.23,
        "涨跌额": -0.15,
        "涨跌幅": -0.2,
        "开盘价": 75.35,
        "最高价": 75.35,
        "最低价": 75.14,
        "昨收价": 75.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 5812.0,
        "成交额": 437274.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "105.VPLS"
    },
    {
        "序号": 8383,
        "name": "Pebblebrook Hotel Trust Series ",
        "最新价": 20.0,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "开盘价": 20.0,
        "最高价": 20.0,
        "最低价": 20.0,
        "昨收价": 20.04,
        "总市值": 88000000.0,
        "市盈率": null,
        "成交量": 652.0,
        "成交额": 13040.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.PEB_E"
    },
    {
        "序号": 8384,
        "name": "Wells Fargo & Co Series R Pfd",
        "最新价": 24.99,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.03,
        "最高价": 25.04,
        "最低价": 24.99,
        "昨收价": 25.04,
        "总市值": 839664.0,
        "市盈率": null,
        "成交量": 75130.0,
        "成交额": 1879662.0,
        "振幅": 0.2,
        "换手率": 223.6,
        "symbol": "106.WFC_R"
    },
    {
        "序号": 8385,
        "name": "NiSource Inc Series B Pfd + Ser",
        "最新价": 24.95,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 25.07,
        "最高价": 25.07,
        "最低价": 24.92,
        "昨收价": 25.0,
        "总市值": 998000.0,
        "市盈率": null,
        "成交量": 65456.0,
        "成交额": 1634571.0,
        "振幅": 0.6,
        "换手率": 163.64,
        "symbol": "106.NI_B"
    },
    {
        "序号": 8386,
        "name": "快验保",
        "最新价": 74.81,
        "涨跌额": -0.15,
        "涨跌幅": -0.2,
        "开盘价": 74.89,
        "最高价": 75.94,
        "最低价": 74.33,
        "昨收价": 74.96,
        "总市值": 814875556.0,
        "市盈率": 6.8,
        "成交量": 117834.0,
        "成交额": 8838536.0,
        "振幅": 2.15,
        "换手率": 1.08,
        "symbol": "106.MED"
    },
    {
        "序号": 8387,
        "name": "ActivePassive Intermediate Muni",
        "最新价": 24.76,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.75,
        "最高价": 24.76,
        "最低价": 24.73,
        "昨收价": 24.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 1671.0,
        "成交额": 41361.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "107.APMU"
    },
    {
        "序号": 8388,
        "name": "Invesco S&P 500 Equal Weight Ut",
        "最新价": 54.47,
        "涨跌额": -0.11,
        "涨跌幅": -0.2,
        "开盘价": 54.44,
        "最高价": 54.55,
        "最低价": 54.15,
        "昨收价": 54.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 40328.0,
        "成交额": 2190312.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.RSPU"
    },
    {
        "序号": 8389,
        "name": "Fulcrum Therapeutics Inc",
        "最新价": 4.95,
        "涨跌额": -0.01,
        "涨跌幅": -0.2,
        "开盘价": 4.97,
        "最高价": 5.03,
        "最低价": 4.87,
        "昨收价": 4.96,
        "总市值": 306021642.0,
        "市盈率": -3.1,
        "成交量": 332058.0,
        "成交额": 1642939.0,
        "振幅": 3.23,
        "换手率": 0.54,
        "symbol": "105.FULC"
    },
    {
        "序号": 8390,
        "name": "Nuveen Municipal High Income Op",
        "最新价": 9.89,
        "涨跌额": -0.02,
        "涨跌幅": -0.2,
        "开盘价": 9.88,
        "最高价": 9.91,
        "最低价": 9.8,
        "昨收价": 9.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 277193.0,
        "成交额": 2728568.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "106.NMZ"
    },
    {
        "序号": 8391,
        "name": "American Homes 4 Rent Series H ",
        "最新价": 24.72,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.69,
        "最高价": 24.81,
        "最低价": 24.55,
        "昨收价": 24.77,
        "总市值": 113712000.0,
        "市盈率": null,
        "成交量": 17795.0,
        "成交额": 438959.0,
        "振幅": 1.05,
        "换手率": 0.39,
        "symbol": "106.AMH_H"
    },
    {
        "序号": 8392,
        "name": "Dimensional Global Real Estate ",
        "最新价": 24.71,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.71,
        "最高价": 24.79,
        "最低价": 24.53,
        "昨收价": 24.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 564397.0,
        "成交额": 13918589.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.DFGR"
    },
    {
        "序号": 8393,
        "name": "Teucrium Agricultural Strategy ",
        "最新价": 34.51,
        "涨跌额": -0.07,
        "涨跌幅": -0.2,
        "开盘价": 34.61,
        "最高价": 34.61,
        "最低价": 34.51,
        "昨收价": 34.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 2201.0,
        "成交额": 76159.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.TILL"
    },
    {
        "序号": 8394,
        "name": "BondBloxx BB-Rated USD High Yie",
        "最新价": 39.41,
        "涨跌额": -0.08,
        "涨跌幅": -0.2,
        "开盘价": 39.4,
        "最高价": 39.41,
        "最低价": 39.4,
        "昨收价": 39.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 435.0,
        "成交额": 17140.0,
        "振幅": 0.03,
        "换手率": null,
        "symbol": "107.XBB"
    },
    {
        "序号": 8395,
        "name": "Kontoor Brands Inc",
        "最新价": 54.18,
        "涨跌额": -0.11,
        "涨跌幅": -0.2,
        "开盘价": 54.38,
        "最高价": 54.78,
        "最低价": 53.66,
        "昨收价": 54.29,
        "总市值": 3043840635.0,
        "市盈率": 14.23,
        "成交量": 350889.0,
        "成交额": 18996157.0,
        "振幅": 2.06,
        "换手率": 0.62,
        "symbol": "106.KTB"
    },
    {
        "序号": 8396,
        "name": "iShares iBonds Dec 2025 Term Co",
        "最新价": 24.58,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.58,
        "最高价": 24.62,
        "最低价": 24.58,
        "昨收价": 24.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 402802.0,
        "成交额": 9903992.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IBDQ"
    },
    {
        "序号": 8397,
        "name": "Timothy Plan Market Neutral ETF",
        "最新价": 24.55,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.61,
        "最高价": 24.61,
        "最低价": 24.54,
        "昨收价": 24.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 1702.0,
        "成交额": 41821.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.TPMN"
    },
    {
        "序号": 8398,
        "name": "BondBloxx USD High Yield Bond S",
        "最新价": 14.67,
        "涨跌额": -0.03,
        "涨跌幅": -0.2,
        "开盘价": 14.72,
        "最高价": 14.72,
        "最低价": 14.67,
        "昨收价": 14.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 1833.0,
        "成交额": 26928.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.HYSA"
    },
    {
        "序号": 8399,
        "name": "John Hancock Multifactor Emergi",
        "最新价": 24.44,
        "涨跌额": -0.05,
        "涨跌幅": -0.2,
        "开盘价": 24.44,
        "最高价": 24.44,
        "最低价": 24.39,
        "昨收价": 24.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 3169.0,
        "成交额": 77394.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.JHEM"
    },
    {
        "序号": 8400,
        "name": "The Southern Co Series 2020C No",
        "最新价": 19.49,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "开盘价": 19.51,
        "最高价": 19.59,
        "最低价": 19.43,
        "昨收价": 19.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 114872.0,
        "成交额": 2240762.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.SOJE"
    },
    {
        "序号": 8401,
        "name": "Textainer Group Holdings Ltd Se",
        "最新价": 24.34,
        "涨跌额": -0.05,
        "涨跌幅": -0.21,
        "开盘价": 24.27,
        "最高价": 24.4,
        "最低价": 24.27,
        "昨收价": 24.39,
        "总市值": 146040.0,
        "市盈率": null,
        "成交量": 22201.0,
        "成交额": 540138.0,
        "振幅": 0.53,
        "换手率": 370.02,
        "symbol": "106.TGH_B"
    },
    {
        "序号": 8402,
        "name": "iShares 0-5 Year Investment Gra",
        "最新价": 48.66,
        "涨跌额": -0.1,
        "涨跌幅": -0.21,
        "开盘价": 48.67,
        "最高价": 48.7,
        "最低价": 48.64,
        "昨收价": 48.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 310997.0,
        "成交额": 15131358.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.SLQD"
    },
    {
        "序号": 8403,
        "name": "Schwab U.S. REIT ETF",
        "最新价": 19.44,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 19.39,
        "最高价": 19.49,
        "最低价": 19.23,
        "昨收价": 19.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 1937314.0,
        "成交额": 37514122.0,
        "振幅": 1.33,
        "换手率": null,
        "symbol": "107.SCHH"
    },
    {
        "序号": 8404,
        "name": "PGIM Active High Yield Bond ETF",
        "最新价": 34.02,
        "涨跌额": -0.07,
        "涨跌幅": -0.21,
        "开盘价": 33.99,
        "最高价": 34.12,
        "最低价": 33.92,
        "昨收价": 34.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 6266.0,
        "成交额": 213141.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.PHYL"
    },
    {
        "序号": 8405,
        "name": "DoubleLine Yield Opportunities ",
        "最新价": 14.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.21,
        "开盘价": 14.57,
        "最高价": 14.68,
        "最低价": 14.44,
        "昨收价": 14.6,
        "总市值": 72850.0,
        "市盈率": null,
        "成交量": 191674.0,
        "成交额": 2787052.0,
        "振幅": 1.64,
        "换手率": 3833.48,
        "symbol": "106.DLY"
    },
    {
        "序号": 8406,
        "name": "红利股ETF-SPDR S&P",
        "最新价": 121.25,
        "涨跌额": -0.25,
        "涨跌幅": -0.21,
        "开盘价": 121.5,
        "最高价": 121.93,
        "最低价": 121.06,
        "昨收价": 121.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 708573.0,
        "成交额": 86000812.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.SDY"
    },
    {
        "序号": 8407,
        "name": "PIMCO 0-5 Year High Yield Corpo",
        "最新价": 91.73,
        "涨跌额": -0.19,
        "涨跌幅": -0.21,
        "开盘价": 91.55,
        "最高价": 91.86,
        "最低价": 91.55,
        "昨收价": 91.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 101887.0,
        "成交额": 9344754.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.HYS"
    },
    {
        "序号": 8408,
        "name": "SPDR Portfolio Short Term Treas",
        "最新价": 28.87,
        "涨跌额": -0.06,
        "涨跌幅": -0.21,
        "开盘价": 28.88,
        "最高价": 28.89,
        "最低价": 28.85,
        "昨收价": 28.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 931325.0,
        "成交额": 26883635.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.SPTS"
    },
    {
        "序号": 8409,
        "name": "Schwab Short-Term U.S. Treasury",
        "最新价": 48.11,
        "涨跌额": -0.1,
        "涨跌幅": -0.21,
        "开盘价": 48.13,
        "最高价": 48.17,
        "最低价": 48.09,
        "昨收价": 48.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 1659313.0,
        "成交额": 79843817.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.SCHO"
    },
    {
        "序号": 8410,
        "name": "旅行动物园",
        "最新价": 9.62,
        "涨跌额": -0.02,
        "涨跌幅": -0.21,
        "开盘价": 9.65,
        "最高价": 9.88,
        "最低价": 9.56,
        "昨收价": 9.64,
        "总市值": 134408485.0,
        "市盈率": 12.11,
        "成交量": 89681.0,
        "成交额": 869250.0,
        "振幅": 3.32,
        "换手率": 0.64,
        "symbol": "105.TZOO"
    },
    {
        "序号": 8411,
        "name": "Invesco Global Short Term High ",
        "最新价": 19.23,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 19.25,
        "最高价": 19.3,
        "最低价": 19.15,
        "昨收价": 19.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 49485.0,
        "成交额": 951932.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.PGHY"
    },
    {
        "序号": 8412,
        "name": "Goldman Sachs Access High Yield",
        "最新价": 43.21,
        "涨跌额": -0.09,
        "涨跌幅": -0.21,
        "开盘价": 43.37,
        "最高价": 43.37,
        "最低价": 43.12,
        "昨收价": 43.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 4561.0,
        "成交额": 197268.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.GHYB"
    },
    {
        "序号": 8413,
        "name": "Credicorp Ltd",
        "最新价": 124.67,
        "涨跌额": -0.26,
        "涨跌幅": -0.21,
        "开盘价": 125.05,
        "最高价": 125.05,
        "最低价": 122.73,
        "昨收价": 124.93,
        "总市值": 9917872510.0,
        "市盈率": 7.25,
        "成交量": 176468.0,
        "成交额": 21908102.0,
        "振幅": 1.86,
        "换手率": 0.22,
        "symbol": "106.BAP"
    },
    {
        "序号": 8414,
        "name": "美国房地产ETF-iShares",
        "最新价": 86.29,
        "涨跌额": -0.18,
        "涨跌幅": -0.21,
        "开盘价": 86.11,
        "最高价": 86.51,
        "最低价": 85.41,
        "昨收价": 86.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 5477757.0,
        "成交额": 471062720.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.IYR"
    },
    {
        "序号": 8415,
        "name": "IQ Ultra Short Duration ETF",
        "最新价": 47.73,
        "涨跌额": -0.1,
        "涨跌幅": -0.21,
        "开盘价": 47.73,
        "最高价": 47.73,
        "最低价": 47.73,
        "昨收价": 47.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 209.0,
        "成交额": 9975.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ULTR"
    },
    {
        "序号": 8416,
        "name": "南方电力",
        "最新价": 71.5,
        "涨跌额": -0.15,
        "涨跌幅": -0.21,
        "开盘价": 71.69,
        "最高价": 72.19,
        "最低价": 70.94,
        "昨收价": 71.65,
        "总市值": 77979283454.0,
        "市盈率": 25.69,
        "成交量": 2711242.0,
        "成交额": 193645881.0,
        "振幅": 1.74,
        "换手率": 0.25,
        "symbol": "106.SO"
    },
    {
        "序号": 8417,
        "name": "Eagle Point Credit Co Inc",
        "最新价": 9.53,
        "涨跌额": -0.02,
        "涨跌幅": -0.21,
        "开盘价": 9.55,
        "最高价": 9.58,
        "最低价": 9.43,
        "昨收价": 9.55,
        "总市值": 131705648.0,
        "市盈率": null,
        "成交量": 661102.0,
        "成交额": 6292939.0,
        "振幅": 1.57,
        "换手率": 4.78,
        "symbol": "106.ECC"
    },
    {
        "序号": 8418,
        "name": "Costamare Inc",
        "最新价": 9.51,
        "涨跌额": -0.02,
        "涨跌幅": -0.21,
        "开盘价": 9.56,
        "最高价": 9.67,
        "最低价": 9.45,
        "昨收价": 9.53,
        "总市值": 1119757889.0,
        "市盈率": 2.35,
        "成交量": 401793.0,
        "成交额": 3832249.0,
        "振幅": 2.31,
        "换手率": 0.34,
        "symbol": "106.CMRE"
    },
    {
        "序号": 8419,
        "name": "网易",
        "最新价": 104.54,
        "涨跌额": -0.22,
        "涨跌幅": -0.21,
        "开盘价": 104.8,
        "最高价": 105.26,
        "最低价": 104.13,
        "昨收价": 104.76,
        "总市值": 67423288687.0,
        "市盈率": 17.78,
        "成交量": 1101107.0,
        "成交额": 115228859.0,
        "振幅": 1.08,
        "换手率": 0.17,
        "symbol": "105.NTES"
    },
    {
        "序号": 8420,
        "name": "SP Funds S&P Global REIT Sharia",
        "最新价": 18.96,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 19.03,
        "最高价": 19.03,
        "最低价": 18.8,
        "昨收价": 19.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 12231.0,
        "成交额": 231753.0,
        "振幅": 1.21,
        "换手率": null,
        "symbol": "107.SPRE"
    },
    {
        "序号": 8421,
        "name": "Preferred-Plus ETF",
        "最新价": 9.48,
        "涨跌额": -0.02,
        "涨跌幅": -0.21,
        "开盘价": 9.48,
        "最高价": 9.48,
        "最低价": 9.48,
        "昨收价": 9.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 18.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IPPP"
    },
    {
        "序号": 8422,
        "name": "房地产ETF-SPDR",
        "最新价": 37.9,
        "涨跌额": -0.08,
        "涨跌幅": -0.21,
        "开盘价": 37.91,
        "最高价": 38.03,
        "最低价": 37.51,
        "昨收价": 37.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 4873815.0,
        "成交额": 184173879.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.XLRE"
    },
    {
        "序号": 8423,
        "name": "BlackRock Health Sciences Term ",
        "最新价": 14.18,
        "涨跌额": -0.03,
        "涨跌幅": -0.21,
        "开盘价": 14.18,
        "最高价": 14.27,
        "最低价": 14.15,
        "昨收价": 14.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 575169.0,
        "成交额": 8169059.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "106.BMEZ"
    },
    {
        "序号": 8424,
        "name": "SL Green Realty Corp",
        "最新价": 42.49,
        "涨跌额": -0.09,
        "涨跌幅": -0.21,
        "开盘价": 42.04,
        "最高价": 42.69,
        "最低价": 41.62,
        "昨收价": 42.58,
        "总市值": 2737065541.0,
        "市盈率": -5.78,
        "成交量": 896563.0,
        "成交额": 37929687.0,
        "振幅": 2.51,
        "换手率": 1.39,
        "symbol": "106.SLG"
    },
    {
        "序号": 8425,
        "name": "Western Asset Short Duration In",
        "最新价": 23.6,
        "涨跌额": -0.05,
        "涨跌幅": -0.21,
        "开盘价": 23.62,
        "最高价": 23.62,
        "最低价": 23.6,
        "昨收价": 23.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 4154.0,
        "成交额": 98114.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.WINC"
    },
    {
        "序号": 8426,
        "name": "Gladstone Land Corp Series C Pf",
        "最新价": 18.87,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 19.2,
        "最高价": 19.2,
        "最低价": 18.8,
        "昨收价": 18.91,
        "总市值": 191653325.0,
        "市盈率": null,
        "成交量": 5288.0,
        "成交额": 100023.0,
        "振幅": 2.12,
        "换手率": 0.05,
        "symbol": "105.LANDP"
    },
    {
        "序号": 8427,
        "name": "Virtus Newfleet ABS\/MBS ETF",
        "最新价": 23.54,
        "涨跌额": -0.05,
        "涨跌幅": -0.21,
        "开盘价": 23.57,
        "最高价": 23.57,
        "最低价": 23.54,
        "昨收价": 23.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 5100.0,
        "成交额": 120203.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.VABS"
    },
    {
        "序号": 8428,
        "name": "Public Storage Series G Pfd",
        "最新价": 23.53,
        "涨跌额": -0.05,
        "涨跌幅": -0.21,
        "开盘价": 23.54,
        "最高价": 23.58,
        "最低价": 23.46,
        "昨收价": 23.58,
        "总市值": 282360.0,
        "市盈率": null,
        "成交量": 10945.0,
        "成交额": 257355.0,
        "振幅": 0.51,
        "换手率": 91.21,
        "symbol": "106.PSA_G"
    },
    {
        "序号": 8429,
        "name": "BRP Inc",
        "最新价": 61.09,
        "涨跌额": -0.13,
        "涨跌幅": -0.21,
        "开盘价": 61.0,
        "最高价": 61.81,
        "最低价": 60.95,
        "昨收价": 61.22,
        "总市值": 4631026355.0,
        "市盈率": 6.8,
        "成交量": 79606.0,
        "成交额": 4885729.0,
        "振幅": 1.4,
        "换手率": 0.11,
        "symbol": "105.DOOO"
    },
    {
        "序号": 8430,
        "name": "Alerian MLP ETF",
        "最新价": 42.29,
        "涨跌额": -0.09,
        "涨跌幅": -0.21,
        "开盘价": 42.46,
        "最高价": 42.61,
        "最低价": 42.25,
        "昨收价": 42.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 1511831.0,
        "成交额": 64064336.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.AMLP"
    },
    {
        "序号": 8431,
        "name": "车之国",
        "最新价": 136.1,
        "涨跌额": -0.29,
        "涨跌幅": -0.21,
        "开盘价": 136.48,
        "最高价": 138.36,
        "最低价": 135.5,
        "昨收价": 136.39,
        "总市值": 5787035831.0,
        "市盈率": 5.3,
        "成交量": 233391.0,
        "成交额": 31874693.0,
        "振幅": 2.1,
        "换手率": 0.55,
        "symbol": "106.AN"
    },
    {
        "序号": 8432,
        "name": "Saba Closed-End Funds ETF",
        "最新价": 18.77,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 18.8,
        "最高价": 18.86,
        "最低价": 18.74,
        "昨收价": 18.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 34027.0,
        "成交额": 639806.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.CEFS"
    },
    {
        "序号": 8433,
        "name": "Duff & Phelps Utility and Infra",
        "最新价": 9.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.21,
        "开盘价": 9.4,
        "最高价": 9.48,
        "最低价": 9.35,
        "昨收价": 9.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 84909.0,
        "成交额": 797956.0,
        "振幅": 1.38,
        "换手率": null,
        "symbol": "106.DPG"
    },
    {
        "序号": 8434,
        "name": "JPMorgan BetaBuilders U.S. Trea",
        "最新价": 98.26,
        "涨跌额": -0.21,
        "涨跌幅": -0.21,
        "开盘价": 98.25,
        "最高价": 98.26,
        "最低价": 98.25,
        "昨收价": 98.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 126.0,
        "成交额": 12378.0,
        "振幅": 0.01,
        "换手率": null,
        "symbol": "107.BBSB"
    },
    {
        "序号": 8435,
        "name": "JPMorgan International Bond Opp",
        "最新价": 46.78,
        "涨跌额": -0.1,
        "涨跌幅": -0.21,
        "开盘价": 46.84,
        "最高价": 46.89,
        "最低价": 46.78,
        "昨收价": 46.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 23595.0,
        "成交额": 1104836.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.JPIB"
    },
    {
        "序号": 8436,
        "name": "铁山",
        "最新价": 65.45,
        "涨跌额": -0.14,
        "涨跌幅": -0.21,
        "开盘价": 65.3,
        "最高价": 65.84,
        "最低价": 64.66,
        "昨收价": 65.59,
        "总市值": 19110717226.0,
        "市盈率": 68.7,
        "成交量": 1067649.0,
        "成交额": 69670956.0,
        "振幅": 1.8,
        "换手率": 0.37,
        "symbol": "106.IRM"
    },
    {
        "序号": 8437,
        "name": "富达国民金融",
        "最新价": 46.72,
        "涨跌额": -0.1,
        "涨跌幅": -0.21,
        "开盘价": 46.78,
        "最高价": 47.07,
        "最低价": 46.69,
        "昨收价": 46.82,
        "总市值": 12715232739.0,
        "市盈率": 30.06,
        "成交量": 1188841.0,
        "成交额": 55636366.0,
        "振幅": 0.81,
        "换手率": 0.44,
        "symbol": "106.FNF"
    },
    {
        "序号": 8438,
        "name": "iShares Trust iShares Internati",
        "最新价": 32.63,
        "涨跌额": -0.07,
        "涨跌幅": -0.21,
        "开盘价": 32.42,
        "最高价": 32.74,
        "最低价": 32.42,
        "昨收价": 32.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 4469.0,
        "成交额": 145754.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.ISVL"
    },
    {
        "序号": 8439,
        "name": "Morgan Stanley Series O Pfd",
        "最新价": 18.62,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 18.59,
        "最高价": 18.65,
        "最低价": 18.51,
        "昨收价": 18.66,
        "总市值": 968240.0,
        "市盈率": null,
        "成交量": 272697.0,
        "成交额": 5064467.0,
        "振幅": 0.75,
        "换手率": 524.42,
        "symbol": "106.MS_O"
    },
    {
        "序号": 8440,
        "name": "AAM S&P Emerging Markets High D",
        "最新价": 18.6,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "开盘价": 18.44,
        "最高价": 18.6,
        "最低价": 18.44,
        "昨收价": 18.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 332.0,
        "成交额": 6140.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.EEMD"
    },
    {
        "序号": 8441,
        "name": "Brookdale Senior Living Inc Equ",
        "最新价": 74.4,
        "涨跌额": -0.16,
        "涨跌幅": -0.21,
        "开盘价": 74.4,
        "最高价": 74.4,
        "最低价": 74.4,
        "昨收价": 74.56,
        "总市值": 14004916872.0,
        "市盈率": -113.41,
        "成交量": 249.0,
        "成交额": 18525.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.BKDT"
    },
    {
        "序号": 8442,
        "name": "Dimensional Emerging Markets Hi",
        "最新价": 23.23,
        "涨跌额": -0.05,
        "涨跌幅": -0.21,
        "开盘价": 23.18,
        "最高价": 23.26,
        "最低价": 23.12,
        "昨收价": 23.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 38571.0,
        "成交额": 893830.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.DEHP"
    },
    {
        "序号": 8443,
        "name": "BondBloxx USD High Yield Bond C",
        "最新价": 37.12,
        "涨跌额": -0.08,
        "涨跌幅": -0.22,
        "开盘价": 37.12,
        "最高价": 37.12,
        "最低价": 37.12,
        "昨收价": 37.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 76.0,
        "成交额": 2821.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.XHYD"
    },
    {
        "序号": 8444,
        "name": "Allspring Multi-Sector Income F",
        "最新价": 9.28,
        "涨跌额": -0.02,
        "涨跌幅": -0.22,
        "开盘价": 9.26,
        "最高价": 9.28,
        "最低价": 9.18,
        "昨收价": 9.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 141600.0,
        "成交额": 1307501.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.ERC"
    },
    {
        "序号": 8445,
        "name": "Dimensional Core Fixed Income E",
        "最新价": 41.68,
        "涨跌额": -0.09,
        "涨跌幅": -0.22,
        "开盘价": 41.58,
        "最高价": 41.68,
        "最低价": 41.5,
        "昨收价": 41.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 743691.0,
        "成交额": 30913537.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.DFCF"
    },
    {
        "序号": 8446,
        "name": "John Hancock Tax-Advantaged Div",
        "最新价": 18.51,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.59,
        "最高价": 18.66,
        "最低价": 18.42,
        "昨收价": 18.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 90517.0,
        "成交额": 1677240.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "106.HTD"
    },
    {
        "序号": 8447,
        "name": "iShares USD Green Bond ETF",
        "最新价": 46.27,
        "涨跌额": -0.1,
        "涨跌幅": -0.22,
        "开盘价": 46.25,
        "最高价": 46.36,
        "最低价": 46.18,
        "昨收价": 46.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 46396.0,
        "成交额": 2144673.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "105.BGRN"
    },
    {
        "序号": 8448,
        "name": "MSA Safety Inc",
        "最新价": 170.95,
        "涨跌额": -0.37,
        "涨跌幅": -0.22,
        "开盘价": 171.82,
        "最高价": 172.44,
        "最低价": 169.49,
        "昨收价": 171.32,
        "总市值": 6720248443.0,
        "市盈率": 199.64,
        "成交量": 73267.0,
        "成交额": 12526583.0,
        "振幅": 1.72,
        "换手率": 0.19,
        "symbol": "106.MSA"
    },
    {
        "序号": 8449,
        "name": "RPAR Risk Parity ETF",
        "最新价": 18.47,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.38,
        "最高价": 18.48,
        "最低价": 18.33,
        "昨收价": 18.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 16139.0,
        "成交额": 296786.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.RPAR"
    },
    {
        "序号": 8450,
        "name": "iMGP RBA Responsible Global All",
        "最新价": 9.23,
        "涨跌额": -0.02,
        "涨跌幅": -0.22,
        "开盘价": 9.23,
        "最高价": 9.23,
        "最低价": 9.23,
        "昨收价": 9.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 115.0,
        "成交额": 1061.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IRBA"
    },
    {
        "序号": 8451,
        "name": "Oxford Lane Capital Corp Series",
        "最新价": 23.05,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 23.05,
        "最高价": 23.09,
        "最低价": 23.0,
        "昨收价": 23.1,
        "总市值": 81246778.0,
        "市盈率": null,
        "成交量": 2104.0,
        "成交额": 48493.0,
        "振幅": 0.39,
        "换手率": 0.06,
        "symbol": "105.OXLCP"
    },
    {
        "序号": 8452,
        "name": "高收益市政债ETF-VanEck Vectors",
        "最新价": 50.63,
        "涨跌额": -0.11,
        "涨跌幅": -0.22,
        "开盘价": 50.63,
        "最高价": 50.82,
        "最低价": 50.43,
        "昨收价": 50.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 1437908.0,
        "成交额": 72696399.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.HYD"
    },
    {
        "序号": 8453,
        "name": "Western Asset Emerging Markets ",
        "最新价": 9.2,
        "涨跌额": -0.02,
        "涨跌幅": -0.22,
        "开盘价": 9.19,
        "最高价": 9.28,
        "最低价": 9.16,
        "昨收价": 9.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 256882.0,
        "成交额": 2369771.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "106.EMD"
    },
    {
        "序号": 8454,
        "name": "SPDR Portfolio High Yield Bond ",
        "最新价": 22.92,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 22.94,
        "最高价": 22.95,
        "最低价": 22.88,
        "昨收价": 22.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 741693.0,
        "成交额": 16997628.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.SPHY"
    },
    {
        "序号": 8455,
        "name": "Eaton Vance Tax-Advantaged Glob",
        "最新价": 22.92,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 22.95,
        "最高价": 23.15,
        "最低价": 22.72,
        "昨收价": 22.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 30958.0,
        "成交额": 711822.0,
        "振幅": 1.87,
        "换手率": null,
        "symbol": "106.ETO"
    },
    {
        "序号": 8456,
        "name": "Innovator Emerging Markets Powe",
        "最新价": 22.91,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 22.88,
        "最高价": 22.95,
        "最低价": 22.88,
        "昨收价": 22.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 743.0,
        "成交额": 17013.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.EOCT"
    },
    {
        "序号": 8457,
        "name": "美洲移动",
        "最新价": 18.32,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.3,
        "最高价": 18.48,
        "最低价": 18.23,
        "昨收价": 18.36,
        "总市值": 57616400000.0,
        "市盈率": 13.27,
        "成交量": 851072.0,
        "成交额": 15585033.0,
        "振幅": 1.36,
        "换手率": 0.03,
        "symbol": "106.AMX"
    },
    {
        "序号": 8458,
        "name": "Putnam ESG High Yield ETF",
        "最新价": 50.3,
        "涨跌额": -0.11,
        "涨跌幅": -0.22,
        "开盘价": 50.3,
        "最高价": 50.3,
        "最低价": 50.3,
        "昨收价": 50.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 80.0,
        "成交额": 4023.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PHYD"
    },
    {
        "序号": 8459,
        "name": "Innovator Emerging Markets Powe",
        "最新价": 22.86,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 22.92,
        "最高价": 22.92,
        "最低价": 22.84,
        "昨收价": 22.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 14552.0,
        "成交额": 332715.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.EJUL"
    },
    {
        "序号": 8460,
        "name": "SPDR Bloomberg 1-10 Year TIPS E",
        "最新价": 18.27,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.25,
        "最高价": 18.28,
        "最低价": 18.23,
        "昨收价": 18.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 154514.0,
        "成交额": 2821180.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.TIPX"
    },
    {
        "序号": 8461,
        "name": "ProShares Russell U.S. Dividend",
        "最新价": 45.65,
        "涨跌额": -0.1,
        "涨跌幅": -0.22,
        "开盘价": 45.64,
        "最高价": 45.73,
        "最低价": 45.64,
        "昨收价": 45.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1611.0,
        "成交额": 73661.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.TMDV"
    },
    {
        "序号": 8462,
        "name": "Xtrackers J.P. Morgan ESG USD H",
        "最新价": 18.23,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.21,
        "最高价": 18.23,
        "最低价": 18.21,
        "昨收价": 18.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 269.0,
        "成交额": 4898.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.ESHY"
    },
    {
        "序号": 8463,
        "name": "Carter Bankshares Inc",
        "最新价": 13.64,
        "涨跌额": -0.03,
        "涨跌幅": -0.22,
        "开盘价": 13.8,
        "最高价": 13.94,
        "最低价": 13.64,
        "昨收价": 13.67,
        "总市值": 313116471.0,
        "市盈率": 7.66,
        "成交量": 41865.0,
        "成交额": 573006.0,
        "振幅": 2.19,
        "换手率": 0.18,
        "symbol": "105.CARE"
    },
    {
        "序号": 8464,
        "name": "Direxion Daily AMZN Bear 1X Sha",
        "最新价": 18.12,
        "涨跌额": -0.04,
        "涨跌幅": -0.22,
        "开盘价": 18.33,
        "最高价": 18.33,
        "最低价": 18.09,
        "昨收价": 18.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 12690.0,
        "成交额": 230867.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "105.AMZD"
    },
    {
        "序号": 8465,
        "name": "1-3年国债ETF-iShares",
        "最新价": 81.48,
        "涨跌额": -0.18,
        "涨跌幅": -0.22,
        "开盘价": 81.51,
        "最高价": 81.56,
        "最低价": 81.45,
        "昨收价": 81.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 3367792.0,
        "成交额": 274442368.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "105.SHY"
    },
    {
        "序号": 8466,
        "name": "雷神技术",
        "最新价": 81.32,
        "涨跌额": -0.18,
        "涨跌幅": -0.22,
        "开盘价": 81.69,
        "最高价": 82.1,
        "最低价": 81.16,
        "昨收价": 81.5,
        "总市值": 116930132415.0,
        "市盈率": 36.64,
        "成交量": 5535033.0,
        "成交额": 451116768.0,
        "振幅": 1.15,
        "换手率": 0.38,
        "symbol": "106.RTX"
    },
    {
        "序号": 8467,
        "name": "可口可乐",
        "最新价": 58.61,
        "涨跌额": -0.13,
        "涨跌幅": -0.22,
        "开盘价": 58.74,
        "最高价": 58.75,
        "最低价": 58.37,
        "昨收价": 58.74,
        "总市值": 253395283404.0,
        "市盈率": 23.52,
        "成交量": 11178670.0,
        "成交额": 654539808.0,
        "振幅": 0.65,
        "换手率": 0.26,
        "symbol": "106.KO"
    },
    {
        "序号": 8468,
        "name": "沃尔弗林集团",
        "最新价": 8.94,
        "涨跌额": -0.02,
        "涨跌幅": -0.22,
        "开盘价": 8.96,
        "最高价": 9.07,
        "最低价": 8.81,
        "昨收价": 8.96,
        "总市值": 711020201.0,
        "市盈率": -2.29,
        "成交量": 659969.0,
        "成交额": 5880117.0,
        "振幅": 2.9,
        "换手率": 0.83,
        "symbol": "106.WWW"
    },
    {
        "序号": 8469,
        "name": "BondBloxx Bloomberg Two Year Ta",
        "最新价": 49.1,
        "涨跌额": -0.11,
        "涨跌幅": -0.22,
        "开盘价": 49.11,
        "最高价": 49.14,
        "最低价": 49.07,
        "昨收价": 49.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 2669.0,
        "成交额": 131021.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.XTWO"
    },
    {
        "序号": 8470,
        "name": "BondBloxx JP Morgan USD Emergin",
        "最新价": 40.17,
        "涨跌额": -0.09,
        "涨跌幅": -0.22,
        "开盘价": 40.19,
        "最高价": 40.19,
        "最低价": 40.09,
        "昨收价": 40.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 878.0,
        "成交额": 35264.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.XEMD"
    },
    {
        "序号": 8471,
        "name": "Entergy New Orleans LLC Bonds",
        "最新价": 22.31,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "开盘价": 22.36,
        "最高价": 22.41,
        "最低价": 22.22,
        "昨收价": 22.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 6440.0,
        "成交额": 143749.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "106.ENO"
    },
    {
        "序号": 8472,
        "name": "Westlake Corp",
        "最新价": 129.25,
        "涨跌额": -0.29,
        "涨跌幅": -0.22,
        "开盘价": 129.87,
        "最高价": 131.16,
        "最低价": 128.8,
        "昨收价": 129.54,
        "总市值": 16562194264.0,
        "市盈率": 13.71,
        "成交量": 212640.0,
        "成交额": 27530872.0,
        "振幅": 1.82,
        "换手率": 0.17,
        "symbol": "106.WLK"
    },
    {
        "序号": 8473,
        "name": "Vanguard Short-Term Treasury ET",
        "最新价": 57.92,
        "涨跌额": -0.13,
        "涨跌幅": -0.22,
        "开盘价": 57.94,
        "最高价": 57.97,
        "最低价": 57.9,
        "昨收价": 58.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 2278179.0,
        "成交额": 131977704.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.VGSH"
    },
    {
        "序号": 8474,
        "name": "能源转换",
        "最新价": 13.3,
        "涨跌额": -0.03,
        "涨跌幅": -0.23,
        "开盘价": 13.31,
        "最高价": 13.39,
        "最低价": 13.23,
        "昨收价": 13.33,
        "总市值": 41829376217.0,
        "市盈率": 11.12,
        "成交量": 17627723.0,
        "成交额": 234291333.0,
        "振幅": 1.2,
        "换手率": 0.56,
        "symbol": "106.ET"
    },
    {
        "序号": 8475,
        "name": "劳氏",
        "最新价": 207.83,
        "涨跌额": -0.47,
        "涨跌幅": -0.23,
        "开盘价": 208.06,
        "最高价": 208.46,
        "最低价": 206.18,
        "昨收价": 208.3,
        "总市值": 119525651658.0,
        "市盈率": 15.6,
        "成交量": 2210351.0,
        "成交额": 458861856.0,
        "振幅": 1.09,
        "换手率": 0.38,
        "symbol": "106.LOW"
    },
    {
        "序号": 8476,
        "name": "iShares MSCI China A ETF",
        "最新价": 26.5,
        "涨跌额": -0.06,
        "涨跌幅": -0.23,
        "开盘价": 26.44,
        "最高价": 26.57,
        "最低价": 26.44,
        "昨收价": 26.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 80099.0,
        "成交额": 2122179.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.CNYA"
    },
    {
        "序号": 8477,
        "name": "First Trust Dow Jones Internati",
        "最新价": 22.02,
        "涨跌额": -0.05,
        "涨跌幅": -0.23,
        "开盘价": 21.56,
        "最高价": 22.11,
        "最低价": 21.56,
        "昨收价": 22.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 3255.0,
        "成交额": 71590.0,
        "振幅": 2.49,
        "换手率": null,
        "symbol": "105.FDNI"
    },
    {
        "序号": 8478,
        "name": "Toro Corp",
        "最新价": 4.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.23,
        "开盘价": 4.42,
        "最高价": 4.49,
        "最低价": 4.15,
        "昨收价": 4.41,
        "总市值": 84484440.0,
        "市盈率": 0.61,
        "成交量": 39443.0,
        "成交额": 172588.0,
        "振幅": 7.71,
        "换手率": 0.21,
        "symbol": "105.TORO"
    },
    {
        "序号": 8479,
        "name": "Logan Ridge Finance Corp",
        "最新价": 21.99,
        "涨跌额": -0.05,
        "涨跌幅": -0.23,
        "开盘价": 22.4,
        "最高价": 22.4,
        "最低价": 21.9,
        "昨收价": 22.04,
        "总市值": 58843921.0,
        "市盈率": -23.24,
        "成交量": 3528.0,
        "成交额": 78141.0,
        "振幅": 2.27,
        "换手率": 0.13,
        "symbol": "105.LRFC"
    },
    {
        "序号": 8480,
        "name": "First Trust VL Dividend",
        "最新价": 39.55,
        "涨跌额": -0.09,
        "涨跌幅": -0.23,
        "开盘价": 39.6,
        "最高价": 39.71,
        "最低价": 39.45,
        "昨收价": 39.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 894874.0,
        "成交额": 35391530.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.FVD"
    },
    {
        "序号": 8481,
        "name": "标普500低波幅ETF-PowerShares",
        "最新价": 61.5,
        "涨跌额": -0.14,
        "涨跌幅": -0.23,
        "开盘价": 61.62,
        "最高价": 61.65,
        "最低价": 61.32,
        "昨收价": 61.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 1055710.0,
        "成交额": 64902011.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.SPLV"
    },
    {
        "序号": 8482,
        "name": "Rayliant Quantamental China Equ",
        "最新价": 13.14,
        "涨跌额": -0.03,
        "涨跌幅": -0.23,
        "开盘价": 13.17,
        "最高价": 13.17,
        "最低价": 13.13,
        "昨收价": 13.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 187316.0,
        "成交额": 2461176.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.RAYC"
    },
    {
        "序号": 8483,
        "name": "US Treasury 2 Year Note ETF",
        "最新价": 48.03,
        "涨跌额": -0.11,
        "涨跌幅": -0.23,
        "开盘价": 48.06,
        "最高价": 48.06,
        "最低价": 48.01,
        "昨收价": 48.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 41567.0,
        "成交额": 1996681.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.UTWO"
    },
    {
        "序号": 8484,
        "name": "LMP Capital and Income Fund",
        "最新价": 13.08,
        "涨跌额": -0.03,
        "涨跌幅": -0.23,
        "开盘价": 13.09,
        "最高价": 13.12,
        "最低价": 13.06,
        "昨收价": 13.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 29734.0,
        "成交额": 389025.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "106.SCD"
    },
    {
        "序号": 8485,
        "name": "切萨皮克气业",
        "最新价": 100.26,
        "涨跌额": -0.23,
        "涨跌幅": -0.23,
        "开盘价": 100.43,
        "最高价": 102.42,
        "最低价": 99.26,
        "昨收价": 100.49,
        "总市值": 2171269661.0,
        "市盈率": 24.66,
        "成交量": 141224.0,
        "成交额": 14141159.0,
        "振幅": 3.14,
        "换手率": 0.65,
        "symbol": "106.CPK"
    },
    {
        "序号": 8486,
        "name": "Harbor Energy Transition Strate",
        "最新价": 13.05,
        "涨跌额": -0.03,
        "涨跌幅": -0.23,
        "开盘价": 13.08,
        "最高价": 13.08,
        "最低价": 13.05,
        "昨收价": 13.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 107.0,
        "成交额": 1399.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.RENW"
    },
    {
        "序号": 8487,
        "name": "Schwab 1-5 Year Corporate Bond ",
        "最新价": 47.7,
        "涨跌额": -0.11,
        "涨跌幅": -0.23,
        "开盘价": 47.71,
        "最高价": 47.76,
        "最低价": 47.67,
        "昨收价": 47.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 64904.0,
        "成交额": 3096265.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.SCHJ"
    },
    {
        "序号": 8488,
        "name": "科斯塔",
        "最新价": 82.39,
        "涨跌额": -0.19,
        "涨跌幅": -0.23,
        "开盘价": 81.89,
        "最高价": 82.79,
        "最低价": 81.73,
        "昨收价": 82.58,
        "总市值": 33645057890.0,
        "市盈率": 83.57,
        "成交量": 1933030.0,
        "成交额": 159137649.0,
        "振幅": 1.28,
        "换手率": 0.47,
        "symbol": "105.CSGP"
    },
    {
        "序号": 8489,
        "name": "Southern States Bancshares Inc",
        "最新价": 25.94,
        "涨跌额": -0.06,
        "涨跌幅": -0.23,
        "开盘价": 25.84,
        "最高价": 26.0,
        "最低价": 25.83,
        "昨收价": 26.0,
        "总市值": 229215308.0,
        "市盈率": 6.81,
        "成交量": 32956.0,
        "成交额": 855799.0,
        "振幅": 0.65,
        "换手率": 0.37,
        "symbol": "105.SSBK"
    },
    {
        "序号": 8490,
        "name": "西班牙电信",
        "最新价": 4.31,
        "涨跌额": -0.01,
        "涨跌幅": -0.23,
        "开盘价": 4.28,
        "最高价": 4.32,
        "最低价": 4.26,
        "昨收价": 4.32,
        "总市值": 24784474605.0,
        "市盈率": 12.31,
        "成交量": 1342571.0,
        "成交额": 5758135.0,
        "振幅": 1.39,
        "换手率": 0.02,
        "symbol": "106.TEF"
    },
    {
        "序号": 8491,
        "name": "马修国际",
        "最新价": 34.48,
        "涨跌额": -0.08,
        "涨跌幅": -0.23,
        "开盘价": 34.27,
        "最高价": 34.81,
        "最低价": 34.21,
        "昨收价": 34.56,
        "总市值": 1047648974.0,
        "市盈率": 26.66,
        "成交量": 136953.0,
        "成交额": 4716378.0,
        "振幅": 1.74,
        "换手率": 0.45,
        "symbol": "105.MATW"
    },
    {
        "序号": 8492,
        "name": "道琼斯运输业指数ETF-iShares",
        "最新价": 253.86,
        "涨跌额": -0.59,
        "涨跌幅": -0.23,
        "开盘价": 254.22,
        "最高价": 256.29,
        "最低价": 253.84,
        "昨收价": 254.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 77043.0,
        "成交额": 19595561.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.IYT"
    },
    {
        "序号": 8493,
        "name": "新兴市场高股息ETF-WisdomTree",
        "最新价": 38.71,
        "涨跌额": -0.09,
        "涨跌幅": -0.23,
        "开盘价": 38.58,
        "最高价": 38.79,
        "最低价": 38.53,
        "昨收价": 38.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 199509.0,
        "成交额": 7717146.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.DEM"
    },
    {
        "序号": 8494,
        "name": "First Western Financial Inc",
        "最新价": 17.2,
        "涨跌额": -0.04,
        "涨跌幅": -0.23,
        "开盘价": 17.11,
        "最高价": 17.41,
        "最低价": 17.05,
        "昨收价": 17.24,
        "总市值": 164462427.0,
        "市盈率": 11.82,
        "成交量": 13723.0,
        "成交额": 235685.0,
        "振幅": 2.09,
        "换手率": 0.14,
        "symbol": "105.MYFW"
    },
    {
        "序号": 8495,
        "name": "BlackRock Flexible Income ETF",
        "最新价": 51.5,
        "涨跌额": -0.12,
        "涨跌幅": -0.23,
        "开盘价": 51.53,
        "最高价": 51.57,
        "最低价": 51.45,
        "昨收价": 51.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 217333.0,
        "成交额": 11193070.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.BINC"
    },
    {
        "序号": 8496,
        "name": "IHS Holding Ltd",
        "最新价": 4.29,
        "涨跌额": -0.01,
        "涨跌幅": -0.23,
        "开盘价": 4.33,
        "最高价": 4.33,
        "最低价": 4.21,
        "昨收价": 4.3,
        "总市值": 1429397970.0,
        "市盈率": -0.81,
        "成交量": 345687.0,
        "成交额": 1477856.0,
        "振幅": 2.79,
        "换手率": 0.1,
        "symbol": "106.IHS"
    },
    {
        "序号": 8497,
        "name": "City Office REIT Inc Series A P",
        "最新价": 17.16,
        "涨跌额": -0.04,
        "涨跌幅": -0.23,
        "开盘价": 17.24,
        "最高价": 17.26,
        "最低价": 17.08,
        "昨收价": 17.2,
        "总市值": 76876800.0,
        "市盈率": null,
        "成交量": 11701.0,
        "成交额": 201743.0,
        "振幅": 1.05,
        "换手率": 0.26,
        "symbol": "106.CIO_A"
    },
    {
        "序号": 8498,
        "name": "国际香料香精",
        "最新价": 76.82,
        "涨跌额": -0.18,
        "涨跌幅": -0.23,
        "开盘价": 76.97,
        "最高价": 77.44,
        "最低价": 76.46,
        "昨收价": 77.0,
        "总市值": 19610543074.0,
        "市盈率": 1089.47,
        "成交量": 1104367.0,
        "成交额": 84859279.0,
        "振幅": 1.27,
        "换手率": 0.43,
        "symbol": "106.IFF"
    },
    {
        "序号": 8499,
        "name": "The Allstate Corp Debentures",
        "最新价": 25.59,
        "涨跌额": -0.06,
        "涨跌幅": -0.23,
        "开盘价": 25.61,
        "最高价": 25.65,
        "最低价": 25.58,
        "昨收价": 25.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 27498.0,
        "成交额": 703979.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "106.ALL_B"
    },
    {
        "序号": 8500,
        "name": "RenaissanceRe Holdings Ltd Seri",
        "最新价": 17.05,
        "涨跌额": -0.04,
        "涨跌幅": -0.23,
        "开盘价": 17.1,
        "最高价": 17.16,
        "最低价": 16.94,
        "昨收价": 17.09,
        "总市值": 341000.0,
        "市盈率": null,
        "成交量": 73417.0,
        "成交额": 1252730.0,
        "振幅": 1.29,
        "换手率": 367.08,
        "symbol": "106.RNR_G"
    },
    {
        "序号": 8501,
        "name": "Minerals Technologies Inc",
        "最新价": 63.84,
        "涨跌额": -0.15,
        "涨跌幅": -0.23,
        "开盘价": 64.28,
        "最高价": 64.48,
        "最低价": 63.01,
        "昨收价": 63.99,
        "总市值": 2077600022.0,
        "市盈率": 32.36,
        "成交量": 135267.0,
        "成交额": 8618024.0,
        "振幅": 2.3,
        "换手率": 0.42,
        "symbol": "106.MTX"
    },
    {
        "序号": 8502,
        "name": "iShares Emerging Markets Equity",
        "最新价": 42.55,
        "涨跌额": -0.1,
        "涨跌幅": -0.23,
        "开盘价": 42.38,
        "最高价": 42.68,
        "最低价": 42.38,
        "昨收价": 42.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 40779.0,
        "成交额": 1734484.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.EMGF"
    },
    {
        "序号": 8503,
        "name": "Fifth Third Bancorp Series I Pf",
        "最新价": 25.51,
        "涨跌额": -0.06,
        "涨跌幅": -0.23,
        "开盘价": 25.57,
        "最高价": 25.6,
        "最低价": 25.47,
        "昨收价": 25.57,
        "总市值": 459180.0,
        "市盈率": null,
        "成交量": 45627.0,
        "成交额": 1164178.0,
        "振幅": 0.51,
        "换手率": 253.48,
        "symbol": "105.FITBI"
    },
    {
        "序号": 8504,
        "name": "Holley Inc",
        "最新价": 4.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.21,
        "最高价": 4.31,
        "最低价": 4.2,
        "昨收价": 4.25,
        "总市值": 502723537.0,
        "市盈率": 182.68,
        "成交量": 198384.0,
        "成交额": 844467.0,
        "振幅": 2.59,
        "换手率": 0.17,
        "symbol": "106.HLLY"
    },
    {
        "序号": 8505,
        "name": "石通瑞吉",
        "最新价": 16.95,
        "涨跌额": -0.04,
        "涨跌幅": -0.24,
        "开盘价": 16.92,
        "最高价": 17.17,
        "最低价": 16.89,
        "昨收价": 16.99,
        "总市值": 466938210.0,
        "市盈率": -58.51,
        "成交量": 53731.0,
        "成交额": 912993.0,
        "振幅": 1.65,
        "换手率": 0.2,
        "symbol": "106.SRI"
    },
    {
        "序号": 8506,
        "name": "Costamare Inc Pfd-E",
        "最新价": 25.4,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.45,
        "最高价": 25.47,
        "最低价": 25.36,
        "昨收价": 25.46,
        "总市值": 116182140.0,
        "市盈率": null,
        "成交量": 8552.0,
        "成交额": 217428.0,
        "振幅": 0.43,
        "换手率": 0.19,
        "symbol": "106.CMRE_E"
    },
    {
        "序号": 8507,
        "name": "国家西方人寿保险",
        "最新价": 482.01,
        "涨跌额": -1.14,
        "涨跌幅": -0.24,
        "开盘价": 482.58,
        "最高价": 483.0,
        "最低价": 482.0,
        "昨收价": 483.15,
        "总市值": 1752598000.0,
        "市盈率": -81.63,
        "成交量": 9746.0,
        "成交额": 4699648.0,
        "振幅": 0.21,
        "换手率": 0.27,
        "symbol": "105.NWLI"
    },
    {
        "序号": 8508,
        "name": "Tsakos Energy Navigation Ltd Se",
        "最新价": 25.32,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.29,
        "最高价": 25.36,
        "最低价": 25.29,
        "昨收价": 25.38,
        "总市值": 120167378.0,
        "市盈率": null,
        "成交量": 2844.0,
        "成交额": 71977.0,
        "振幅": 0.28,
        "换手率": 0.06,
        "symbol": "106.TNP_E"
    },
    {
        "序号": 8509,
        "name": "SPDR Portfolio Short Term Corpo",
        "最新价": 29.54,
        "涨跌额": -0.07,
        "涨跌幅": -0.24,
        "开盘价": 29.55,
        "最高价": 29.57,
        "最低价": 29.53,
        "昨收价": 29.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 2099504.0,
        "成交额": 62043891.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.SPSB"
    },
    {
        "序号": 8510,
        "name": "iShares iBonds Oct 2033 Term TI",
        "最新价": 25.29,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.24,
        "最高价": 25.29,
        "最低价": 25.21,
        "昨收价": 25.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 1767.0,
        "成交额": 44567.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.IBIJ"
    },
    {
        "序号": 8511,
        "name": "CP High Yield Trend ETF",
        "最新价": 21.07,
        "涨跌额": -0.05,
        "涨跌幅": -0.24,
        "开盘价": 21.05,
        "最高价": 21.07,
        "最低价": 21.05,
        "昨收价": 21.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 242.0,
        "成交额": 5094.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.HYTR"
    },
    {
        "序号": 8512,
        "name": "佛罗斯特研究",
        "最新价": 25.26,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.2,
        "最高价": 25.51,
        "最低价": 24.95,
        "昨收价": 25.32,
        "总市值": 485977140.0,
        "市盈率": 233.31,
        "成交量": 44580.0,
        "成交额": 1121042.0,
        "振幅": 2.21,
        "换手率": 0.23,
        "symbol": "105.FORR"
    },
    {
        "序号": 8513,
        "name": "iShares iBonds Oct 2028 Term TI",
        "最新价": 25.24,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.2,
        "最高价": 25.24,
        "最低价": 25.19,
        "昨收价": 25.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 919.0,
        "成交额": 23162.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.IBIE"
    },
    {
        "序号": 8514,
        "name": "American Century Short Duration",
        "最新价": 50.47,
        "涨跌额": -0.12,
        "涨跌幅": -0.24,
        "开盘价": 50.47,
        "最高价": 50.47,
        "最低价": 50.42,
        "昨收价": 50.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 3495.0,
        "成交额": 176317.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.SDSI"
    },
    {
        "序号": 8515,
        "name": "Triton International Ltd Series",
        "最新价": 25.15,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.16,
        "最高价": 25.29,
        "最低价": 25.15,
        "昨收价": 25.21,
        "总市值": 86767500.0,
        "市盈率": null,
        "成交量": 2305.0,
        "成交额": 58031.0,
        "振幅": 0.56,
        "换手率": 0.07,
        "symbol": "106.TRTN_A"
    },
    {
        "序号": 8516,
        "name": "必能宝",
        "最新价": 4.19,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.2,
        "最高价": 4.21,
        "最低价": 4.04,
        "昨收价": 4.2,
        "总市值": 738827879.0,
        "市盈率": -4.75,
        "成交量": 929407.0,
        "成交额": 3855273.0,
        "振幅": 4.05,
        "换手率": 0.53,
        "symbol": "106.PBI"
    },
    {
        "序号": 8517,
        "name": "Physicians Realty Trust",
        "最新价": 12.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.24,
        "开盘价": 12.57,
        "最高价": 12.68,
        "最低价": 12.49,
        "昨收价": 12.6,
        "总市值": 2997787221.0,
        "市盈率": 64.46,
        "成交量": 1945293.0,
        "成交额": 24436800.0,
        "振幅": 1.51,
        "换手率": 0.82,
        "symbol": "106.DOC"
    },
    {
        "序号": 8518,
        "name": "Fidelity MSCI Real Estate Index",
        "最新价": 25.12,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.06,
        "最高价": 25.19,
        "最低价": 24.88,
        "昨收价": 25.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 141416.0,
        "成交额": 3541640.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.FREL"
    },
    {
        "序号": 8519,
        "name": "PIMCO Multisector Bond Active E",
        "最新价": 25.1,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 25.09,
        "最高价": 25.12,
        "最低价": 25.06,
        "昨收价": 25.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 265595.0,
        "成交额": 6665415.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.PYLD"
    },
    {
        "序号": 8520,
        "name": "Avantis Short-Term Fixed Income",
        "最新价": 45.96,
        "涨跌额": -0.11,
        "涨跌幅": -0.24,
        "开盘价": 45.95,
        "最高价": 45.99,
        "最低价": 45.94,
        "昨收价": 46.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 49813.0,
        "成交额": 2289306.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.AVSF"
    },
    {
        "序号": 8521,
        "name": "XBiotech Inc",
        "最新价": 4.17,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.2,
        "最高价": 4.2,
        "最低价": 4.06,
        "昨收价": 4.18,
        "总市值": 126922140.0,
        "市盈率": -5.49,
        "成交量": 20149.0,
        "成交额": 83293.0,
        "振幅": 3.35,
        "换手率": 0.07,
        "symbol": "105.XBIT"
    },
    {
        "序号": 8522,
        "name": "Crescent Capital BDC Inc",
        "最新价": 16.66,
        "涨跌额": -0.04,
        "涨跌幅": -0.24,
        "开盘价": 16.77,
        "最高价": 16.79,
        "最低价": 16.64,
        "昨收价": 16.7,
        "总市值": 617445373.0,
        "市盈率": 11.11,
        "成交量": 59665.0,
        "成交额": 995873.0,
        "振幅": 0.9,
        "换手率": 0.16,
        "symbol": "105.CCAP"
    },
    {
        "序号": 8523,
        "name": "RPT Realty Series D Pfd",
        "最新价": 54.13,
        "涨跌额": -0.13,
        "涨跌幅": -0.24,
        "开盘价": 54.13,
        "最高价": 54.37,
        "最低价": 54.13,
        "昨收价": 54.26,
        "总市值": 100061416.0,
        "市盈率": null,
        "成交量": 562.0,
        "成交额": 30454.0,
        "振幅": 0.44,
        "换手率": 0.03,
        "symbol": "106.RPT_D"
    },
    {
        "序号": 8524,
        "name": "万事达",
        "最新价": 412.16,
        "涨跌额": -0.99,
        "涨跌幅": -0.24,
        "开盘价": 412.22,
        "最高价": 413.67,
        "最低价": 409.23,
        "昨收价": 413.15,
        "总市值": 386513781302.0,
        "市盈率": 35.37,
        "成交量": 1959438.0,
        "成交额": 806926464.0,
        "振幅": 1.07,
        "换手率": 0.21,
        "symbol": "106.MA"
    },
    {
        "序号": 8525,
        "name": "Sportsman’s Warehouse Holdings",
        "最新价": 4.16,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.17,
        "最高价": 4.47,
        "最低价": 4.09,
        "昨收价": 4.17,
        "总市值": 155634727.0,
        "市盈率": -16.86,
        "成交量": 1418459.0,
        "成交额": 6061018.0,
        "振幅": 9.11,
        "换手率": 3.79,
        "symbol": "105.SPWH"
    },
    {
        "序号": 8526,
        "name": "Nuvalent Inc-A",
        "最新价": 70.66,
        "涨跌额": -0.17,
        "涨跌幅": -0.24,
        "开盘价": 70.4,
        "最高价": 71.6,
        "最低价": 69.36,
        "昨收价": 70.83,
        "总市值": 4425442513.0,
        "市盈率": -38.8,
        "成交量": 391589.0,
        "成交额": 27634735.0,
        "振幅": 3.16,
        "换手率": 0.63,
        "symbol": "105.NUVL"
    },
    {
        "序号": 8527,
        "name": "CNA金融",
        "最新价": 41.55,
        "涨跌额": -0.1,
        "涨跌幅": -0.24,
        "开盘价": 41.53,
        "最高价": 41.56,
        "最低价": 41.2,
        "昨收价": 41.65,
        "总市值": 11254670314.0,
        "市盈率": 8.73,
        "成交量": 184850.0,
        "成交额": 7659754.0,
        "振幅": 0.86,
        "换手率": 0.07,
        "symbol": "106.CNA"
    },
    {
        "序号": 8528,
        "name": "JPMorgan Chase & Co Series GG P",
        "最新价": 20.76,
        "涨跌额": -0.05,
        "涨跌幅": -0.24,
        "开盘价": 20.77,
        "最高价": 20.83,
        "最低价": 20.69,
        "昨收价": 20.81,
        "总市值": 1868400.0,
        "市盈率": null,
        "成交量": 69802.0,
        "成交额": 1448929.0,
        "振幅": 0.67,
        "换手率": 77.56,
        "symbol": "106.JPM_J"
    },
    {
        "序号": 8529,
        "name": "Sophia Genetics SA",
        "最新价": 4.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.21,
        "最高价": 4.22,
        "最低价": 3.97,
        "昨收价": 4.16,
        "总市值": 270297825.0,
        "市盈率": -3.92,
        "成交量": 24517.0,
        "成交额": 99235.0,
        "振幅": 6.01,
        "换手率": 0.04,
        "symbol": "105.SOPH"
    },
    {
        "序号": 8530,
        "name": "金山云",
        "最新价": 4.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "开盘价": 4.12,
        "最高价": 4.31,
        "最低价": 4.05,
        "昨收价": 4.16,
        "总市值": 979990284.0,
        "市盈率": -2.86,
        "成交量": 777753.0,
        "成交额": 3281154.0,
        "振幅": 6.25,
        "换手率": 0.33,
        "symbol": "105.KC"
    },
    {
        "序号": 8531,
        "name": "Xtrackers Low Beta High Yield B",
        "最新价": 45.57,
        "涨跌额": -0.11,
        "涨跌幅": -0.24,
        "开盘价": 45.57,
        "最高价": 45.63,
        "最低价": 45.5,
        "昨收价": 45.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 4574.0,
        "成交额": 208407.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.HYDW"
    },
    {
        "序号": 8532,
        "name": "万全保险",
        "最新价": 16.56,
        "涨跌额": -0.04,
        "涨跌幅": -0.24,
        "开盘价": 16.55,
        "最高价": 16.59,
        "最低价": 16.09,
        "昨收价": 16.6,
        "总市值": 483316980.0,
        "市盈率": 6.72,
        "成交量": 189895.0,
        "成交额": 3116410.0,
        "振幅": 3.01,
        "换手率": 0.65,
        "symbol": "106.UVE"
    },
    {
        "序号": 8533,
        "name": "Donoghue Forlines Tactical High",
        "最新价": 20.59,
        "涨跌额": -0.05,
        "涨跌幅": -0.24,
        "开盘价": 20.56,
        "最高价": 20.61,
        "最低价": 20.56,
        "昨收价": 20.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 985.0,
        "成交额": 20268.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.DFHY"
    },
    {
        "序号": 8534,
        "name": "iShares Transition-Enabling Met",
        "最新价": 24.69,
        "涨跌额": -0.06,
        "涨跌幅": -0.24,
        "开盘价": 24.69,
        "最高价": 24.69,
        "最低价": 24.69,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 13.0,
        "成交额": 320.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TMET"
    },
    {
        "序号": 8535,
        "name": "Fidelity Real Estate Investment",
        "最新价": 20.57,
        "涨跌额": -0.05,
        "涨跌幅": -0.24,
        "开盘价": 20.58,
        "最高价": 20.6,
        "最低价": 20.44,
        "昨收价": 20.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 2152.0,
        "成交额": 44217.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.FPRO"
    },
    {
        "序号": 8536,
        "name": "Direct Digital Holdings Inc-A",
        "最新价": 12.29,
        "涨跌额": -0.03,
        "涨跌幅": -0.24,
        "开盘价": 12.1,
        "最高价": 12.58,
        "最低价": 11.95,
        "昨收价": 12.32,
        "总市值": 175383523.0,
        "市盈率": 37.92,
        "成交量": 216316.0,
        "成交额": 2619596.0,
        "振幅": 5.11,
        "换手率": 1.52,
        "symbol": "105.DRCT"
    },
    {
        "序号": 8537,
        "name": "亚太ETF-Vanguard FTSE",
        "最新价": 69.57,
        "涨跌额": -0.17,
        "涨跌幅": -0.24,
        "开盘价": 69.26,
        "最高价": 69.7,
        "最低价": 69.19,
        "昨收价": 69.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 220126.0,
        "成交额": 15289051.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.VPL"
    },
    {
        "序号": 8538,
        "name": "Overlay Shares Core Bond ETF",
        "最新价": 20.34,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 20.27,
        "最高价": 20.34,
        "最低价": 20.21,
        "昨收价": 20.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 4551.0,
        "成交额": 92362.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.OVB"
    },
    {
        "序号": 8539,
        "name": "Madison Aggregate Bond ETF",
        "最新价": 20.33,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 20.33,
        "最高价": 20.33,
        "最低价": 20.33,
        "昨收价": 20.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 40.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MAGG"
    },
    {
        "序号": 8540,
        "name": "Invesco International Developed",
        "最新价": 24.35,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 24.36,
        "最高价": 24.88,
        "最低价": 24.24,
        "昨收价": 24.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 26295.0,
        "成交额": 640084.0,
        "振幅": 2.62,
        "换手率": null,
        "symbol": "107.IMFL"
    },
    {
        "序号": 8541,
        "name": "林肯国民",
        "最新价": 24.33,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 24.19,
        "最高价": 24.61,
        "最低价": 23.78,
        "昨收价": 24.39,
        "总市值": 4127873346.0,
        "市盈率": -1.8,
        "成交量": 1849022.0,
        "成交额": 44949359.0,
        "振幅": 3.4,
        "换手率": 1.09,
        "symbol": "106.LNC"
    },
    {
        "序号": 8542,
        "name": "Angel Oak Income ETF",
        "最新价": 20.27,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 20.29,
        "最高价": 20.29,
        "最低价": 20.25,
        "昨收价": 20.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 45170.0,
        "成交额": 916370.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.CARY"
    },
    {
        "序号": 8543,
        "name": "Fortive Corp",
        "最新价": 68.89,
        "涨跌额": -0.17,
        "涨跌幅": -0.25,
        "开盘价": 69.17,
        "最高价": 69.61,
        "最低价": 68.62,
        "昨收价": 69.06,
        "总市值": 24210258568.0,
        "市盈率": 29.25,
        "成交量": 1970244.0,
        "成交额": 135824417.0,
        "振幅": 1.43,
        "换手率": 0.56,
        "symbol": "106.FTV"
    },
    {
        "序号": 8544,
        "name": "iShares MSCI Global Select Meta",
        "最新价": 40.51,
        "涨跌额": -0.1,
        "涨跌幅": -0.25,
        "开盘价": 40.1,
        "最高价": 40.79,
        "最低价": 40.1,
        "昨收价": 40.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 108533.0,
        "成交额": 4400641.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "107.PICK"
    },
    {
        "序号": 8545,
        "name": "iShares ESG Aware Conservative ",
        "最新价": 24.23,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 24.23,
        "最高价": 24.23,
        "最低价": 24.23,
        "昨收价": 24.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 72.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EAOK"
    },
    {
        "序号": 8546,
        "name": "WisdomTree U.S. High Yield Corp",
        "最新价": 44.39,
        "涨跌额": -0.11,
        "涨跌幅": -0.25,
        "开盘价": 44.41,
        "最高价": 44.48,
        "最低价": 44.34,
        "昨收价": 44.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 16991.0,
        "成交额": 753997.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.WFHY"
    },
    {
        "序号": 8547,
        "name": "Affiliated Managers Group Inc N",
        "最新价": 16.14,
        "涨跌额": -0.04,
        "涨跌幅": -0.25,
        "开盘价": 16.12,
        "最高价": 16.23,
        "最低价": 15.93,
        "昨收价": 16.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 7060.0,
        "成交额": 113446.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "106.MGRD"
    },
    {
        "序号": 8548,
        "name": "Genmab A\/S ADR",
        "最新价": 32.28,
        "涨跌额": -0.08,
        "涨跌幅": -0.25,
        "开盘价": 32.42,
        "最高价": 32.65,
        "最低价": 32.14,
        "昨收价": 32.36,
        "总市值": 21328859898.0,
        "市盈率": 32.8,
        "成交量": 301229.0,
        "成交额": 9751539.0,
        "振幅": 1.58,
        "换手率": 0.05,
        "symbol": "105.GMAB"
    },
    {
        "序号": 8549,
        "name": "iShares ESG 1-5 Year USD Corpor",
        "最新价": 24.17,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 24.17,
        "最高价": 24.19,
        "最低价": 24.15,
        "昨收价": 24.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 86281.0,
        "成交额": 2085107.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "105.SUSB"
    },
    {
        "序号": 8550,
        "name": "IQ CBRE Real Assets ETF",
        "最新价": 24.17,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 24.17,
        "最高价": 24.17,
        "最低价": 24.17,
        "昨收价": 24.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 193.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.IQRA"
    },
    {
        "序号": 8551,
        "name": "Franklin International Aggregat",
        "最新价": 20.12,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 20.11,
        "最高价": 20.12,
        "最低价": 20.09,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 50184.0,
        "成交额": 1009040.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "107.FLIA"
    },
    {
        "序号": 8552,
        "name": "Strategy Shares Nasdaq 7HANDL I",
        "最新价": 20.08,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 20.0,
        "最高价": 20.15,
        "最低价": 20.0,
        "昨收价": 20.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 177444.0,
        "成交额": 3563885.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.HNDL"
    },
    {
        "序号": 8553,
        "name": "Viemed Healthcare Inc",
        "最新价": 8.03,
        "涨跌额": -0.02,
        "涨跌幅": -0.25,
        "开盘价": 8.02,
        "最高价": 8.21,
        "最低价": 7.92,
        "昨收价": 8.05,
        "总市值": 309066678.0,
        "市盈率": 33.58,
        "成交量": 66201.0,
        "成交额": 534073.0,
        "振幅": 3.6,
        "换手率": 0.17,
        "symbol": "105.VMD"
    },
    {
        "序号": 8554,
        "name": "海德思哲",
        "最新价": 28.1,
        "涨跌额": -0.07,
        "涨跌幅": -0.25,
        "开盘价": 28.08,
        "最高价": 28.45,
        "最低价": 27.82,
        "昨收价": 28.17,
        "总市值": 565434270.0,
        "市盈率": 10.17,
        "成交量": 67387.0,
        "成交额": 1891526.0,
        "振幅": 2.24,
        "换手率": 0.33,
        "symbol": "105.HSII"
    },
    {
        "序号": 8555,
        "name": "新纪元能源",
        "最新价": 59.7,
        "涨跌额": -0.15,
        "涨跌幅": -0.25,
        "开盘价": 59.57,
        "最高价": 59.95,
        "最低价": 59.1,
        "昨收价": 59.85,
        "总市值": 122486952138.0,
        "市盈率": 16.07,
        "成交量": 9408385.0,
        "成交额": 560298688.0,
        "振幅": 1.42,
        "换手率": 0.46,
        "symbol": "106.NEE"
    },
    {
        "序号": 8556,
        "name": "Eaton Vance Enhanced Equity Inc",
        "最新价": 15.92,
        "涨跌额": -0.04,
        "涨跌幅": -0.25,
        "开盘价": 15.98,
        "最高价": 15.99,
        "最低价": 15.88,
        "昨收价": 15.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 76285.0,
        "成交额": 1214984.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.EOI"
    },
    {
        "序号": 8557,
        "name": "Hillman Solutions Corp",
        "最新价": 7.95,
        "涨跌额": -0.02,
        "涨跌幅": -0.25,
        "开盘价": 7.95,
        "最高价": 8.05,
        "最低价": 7.51,
        "昨收价": 7.97,
        "总市值": 1549478842.0,
        "市盈率": -115.38,
        "成交量": 989971.0,
        "成交额": 7894520.0,
        "振幅": 6.78,
        "换手率": 0.51,
        "symbol": "105.HLMN"
    },
    {
        "序号": 8558,
        "name": "美敦力",
        "最新价": 79.35,
        "涨跌额": -0.2,
        "涨跌幅": -0.25,
        "开盘价": 79.6,
        "最高价": 79.85,
        "最低价": 79.28,
        "昨收价": 79.55,
        "总市值": 105508069578.0,
        "市盈率": 25.72,
        "成交量": 6241007.0,
        "成交额": 496227632.0,
        "振幅": 0.72,
        "换手率": 0.47,
        "symbol": "106.MDT"
    },
    {
        "序号": 8559,
        "name": "Invesco Active U.S. Real Estate",
        "最新价": 87.02,
        "涨跌额": -0.22,
        "涨跌幅": -0.25,
        "开盘价": 86.6,
        "最高价": 87.08,
        "最低价": 86.36,
        "昨收价": 87.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 14920.0,
        "成交额": 1293890.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.PSR"
    },
    {
        "序号": 8560,
        "name": "Franklin Municipal Green Bond E",
        "最新价": 23.72,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 23.73,
        "最高价": 23.73,
        "最低价": 23.7,
        "昨收价": 23.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 10009.0,
        "成交额": 237238.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.FLMB"
    },
    {
        "序号": 8561,
        "name": "Invesco DWA Consumer Staples Mo",
        "最新价": 86.94,
        "涨跌额": -0.22,
        "涨跌幅": -0.25,
        "开盘价": 87.32,
        "最高价": 87.5,
        "最低价": 86.93,
        "昨收价": 87.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 2676.0,
        "成交额": 232817.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.PSL"
    },
    {
        "序号": 8562,
        "name": "JPMorgan ActiveBuilders Emergin",
        "最新价": 35.51,
        "涨跌额": -0.09,
        "涨跌幅": -0.25,
        "开盘价": 35.42,
        "最高价": 35.58,
        "最低价": 35.36,
        "昨收价": 35.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 18059.0,
        "成交额": 640269.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.JEMA"
    },
    {
        "序号": 8563,
        "name": "iShares iBonds Dec 2026 Term Co",
        "最新价": 23.63,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 23.65,
        "最高价": 23.66,
        "最低价": 23.6,
        "昨收价": 23.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 582748.0,
        "成交额": 13763864.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.IBDR"
    },
    {
        "序号": 8564,
        "name": "KraneShares MSCI Emerging Marke",
        "最新价": 27.55,
        "涨跌额": -0.07,
        "涨跌幅": -0.25,
        "开盘价": 27.56,
        "最高价": 27.67,
        "最低价": 27.45,
        "昨收价": 27.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 5224.0,
        "成交额": 143878.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.KEMX"
    },
    {
        "序号": 8565,
        "name": "Home BancShares Inc",
        "最新价": 23.61,
        "涨跌额": -0.06,
        "涨跌幅": -0.25,
        "开盘价": 23.7,
        "最高价": 23.93,
        "最低价": 23.53,
        "昨收价": 23.67,
        "总市值": 4763822872.0,
        "市盈率": 11.28,
        "成交量": 855242.0,
        "成交额": 20225829.0,
        "振幅": 1.69,
        "换手率": 0.42,
        "symbol": "106.HOMB"
    },
    {
        "序号": 8566,
        "name": "American Century Quality Prefer",
        "最新价": 35.39,
        "涨跌额": -0.09,
        "涨跌幅": -0.25,
        "开盘价": 35.52,
        "最高价": 35.52,
        "最低价": 35.34,
        "昨收价": 35.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 6119.0,
        "成交额": 216403.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.QPFF"
    },
    {
        "序号": 8567,
        "name": "Cabana Target Leading Sector Co",
        "最新价": 19.65,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "开盘价": 19.62,
        "最高价": 19.65,
        "最低价": 19.61,
        "昨收价": 19.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 8545.0,
        "成交额": 167745.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.CLSC"
    },
    {
        "序号": 8568,
        "name": "塞拉尼斯",
        "最新价": 141.35,
        "涨跌额": -0.36,
        "涨跌幅": -0.25,
        "开盘价": 141.96,
        "最高价": 143.47,
        "最低价": 140.91,
        "昨收价": 141.71,
        "总市值": 15386677855.0,
        "市盈率": 7.58,
        "成交量": 607456.0,
        "成交额": 85947556.0,
        "振幅": 1.81,
        "换手率": 0.56,
        "symbol": "106.CE"
    },
    {
        "序号": 8569,
        "name": "Fidelity High Yield Factor ETF",
        "最新价": 47.06,
        "涨跌额": -0.12,
        "涨跌幅": -0.25,
        "开盘价": 47.11,
        "最高价": 47.11,
        "最低价": 46.95,
        "昨收价": 47.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 23083.0,
        "成交额": 1085562.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.FDHY"
    },
    {
        "序号": 8570,
        "name": "Ardagh Metal Packaging SA",
        "最新价": 3.92,
        "涨跌额": -0.01,
        "涨跌幅": -0.25,
        "开盘价": 3.9,
        "最高价": 3.98,
        "最低价": 3.86,
        "昨收价": 3.93,
        "总市值": 2342548519.0,
        "市盈率": 130.14,
        "成交量": 4649727.0,
        "成交额": 18266057.0,
        "振幅": 3.05,
        "换手率": 0.78,
        "symbol": "106.AMBP"
    },
    {
        "序号": 8571,
        "name": "欧力士",
        "最新价": 94.03,
        "涨跌额": -0.24,
        "涨跌幅": -0.25,
        "开盘价": 93.54,
        "最高价": 94.03,
        "最低价": 93.3,
        "昨收价": 94.27,
        "总市值": 21833740085.0,
        "市盈率": 10.55,
        "成交量": 10337.0,
        "成交额": 969166.0,
        "振幅": 0.77,
        "换手率": 0.0,
        "symbol": "106.IX"
    },
    {
        "序号": 8572,
        "name": "First Advantage Corp",
        "最新价": 15.66,
        "涨跌额": -0.04,
        "涨跌幅": -0.25,
        "开盘价": 15.62,
        "最高价": 15.8,
        "最低价": 15.49,
        "昨收价": 15.7,
        "总市值": 2272579310.0,
        "市盈率": 53.31,
        "成交量": 555103.0,
        "成交额": 8700715.0,
        "振幅": 1.97,
        "换手率": 0.38,
        "symbol": "105.FA"
    },
    {
        "序号": 8573,
        "name": "Cool Co Ltd",
        "最新价": 11.72,
        "涨跌额": -0.03,
        "涨跌幅": -0.26,
        "开盘价": 11.65,
        "最高价": 11.92,
        "最低价": 11.65,
        "昨收价": 11.75,
        "总市值": 629228775.0,
        "市盈率": null,
        "成交量": 296304.0,
        "成交额": 3492472.0,
        "振幅": 2.3,
        "换手率": 0.55,
        "symbol": "106.CLCO"
    },
    {
        "序号": 8574,
        "name": "Federated Hermes U.S. Strategic",
        "最新价": 23.42,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 23.44,
        "最高价": 23.47,
        "最低价": 23.37,
        "昨收价": 23.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 22100.0,
        "成交额": 517744.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.FDV"
    },
    {
        "序号": 8575,
        "name": "Brookfield Business Corp-A",
        "最新价": 19.51,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 19.59,
        "最高价": 19.8,
        "最低价": 19.36,
        "昨收价": 19.56,
        "总市值": 1929325053.0,
        "市盈率": 7.45,
        "成交量": 34176.0,
        "成交额": 668407.0,
        "振幅": 2.25,
        "换手率": 0.03,
        "symbol": "106.BBUC"
    },
    {
        "序号": 8576,
        "name": "T. Rowe Price U.S. High Yield E",
        "最新价": 50.7,
        "涨跌额": -0.13,
        "涨跌幅": -0.26,
        "开盘价": 50.75,
        "最高价": 50.77,
        "最低价": 50.7,
        "昨收价": 50.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 2221.0,
        "成交额": 112725.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.THYF"
    },
    {
        "序号": 8577,
        "name": "Mister Car Wash Inc",
        "最新价": 7.8,
        "涨跌额": -0.02,
        "涨跌幅": -0.26,
        "开盘价": 7.8,
        "最高价": 7.99,
        "最低价": 7.76,
        "昨收价": 7.82,
        "总市值": 2449745189.0,
        "市盈率": 28.65,
        "成交量": 2069521.0,
        "成交额": 16312574.0,
        "振幅": 2.94,
        "换手率": 0.66,
        "symbol": "106.MCW"
    },
    {
        "序号": 8578,
        "name": "Franklin Short Duration U.S. Go",
        "最新价": 89.67,
        "涨跌额": -0.23,
        "涨跌幅": -0.26,
        "开盘价": 89.6,
        "最高价": 89.79,
        "最低价": 89.6,
        "昨收价": 89.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 17185.0,
        "成交额": 1541295.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.FTSD"
    },
    {
        "序号": 8579,
        "name": "Intrepid Potash Inc",
        "最新价": 19.45,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 19.37,
        "最高价": 19.86,
        "最低价": 19.23,
        "昨收价": 19.5,
        "总市值": 255896220.0,
        "市盈率": 45.72,
        "成交量": 69003.0,
        "成交额": 1343808.0,
        "振幅": 3.23,
        "换手率": 0.52,
        "symbol": "106.IPI"
    },
    {
        "序号": 8580,
        "name": "The Shyft Group Inc",
        "最新价": 11.63,
        "涨跌额": -0.03,
        "涨跌幅": -0.26,
        "开盘价": 11.68,
        "最高价": 12.0,
        "最低价": 11.61,
        "昨收价": 11.66,
        "总市值": 398790828.0,
        "市盈率": 13.87,
        "成交量": 628083.0,
        "成交额": 7359640.0,
        "振幅": 3.34,
        "换手率": 1.83,
        "symbol": "105.SHYF"
    },
    {
        "序号": 8581,
        "name": "Maiden Holdings North America L",
        "最新价": 19.37,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 19.34,
        "最高价": 19.52,
        "最低价": 19.19,
        "昨收价": 19.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 9616.0,
        "成交额": 186500.0,
        "振幅": 1.7,
        "换手率": null,
        "symbol": "106.MHNC"
    },
    {
        "序号": 8582,
        "name": "诺斯罗普-格鲁曼(US)",
        "最新价": 476.25,
        "涨跌额": -1.23,
        "涨跌幅": -0.26,
        "开盘价": 478.72,
        "最高价": 479.84,
        "最低价": 475.36,
        "昨收价": 477.48,
        "总市值": 71814978131.0,
        "市盈率": 15.37,
        "成交量": 411324.0,
        "成交额": 196089545.0,
        "振幅": 0.94,
        "换手率": 0.27,
        "symbol": "106.NOC"
    },
    {
        "序号": 8583,
        "name": "Hartford Schroders Tax-Aware Bo",
        "最新价": 19.35,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 19.35,
        "最高价": 19.35,
        "最低价": 19.29,
        "昨收价": 19.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 105004.0,
        "成交额": 2028727.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.HTAB"
    },
    {
        "序号": 8584,
        "name": "伊士曼化工",
        "最新价": 84.85,
        "涨跌额": -0.22,
        "涨跌幅": -0.26,
        "开盘价": 85.04,
        "最高价": 86.29,
        "最低价": 84.83,
        "昨收价": 85.07,
        "总市值": 10055846293.0,
        "市盈率": 17.19,
        "成交量": 738328.0,
        "成交额": 62847199.0,
        "振幅": 1.72,
        "换手率": 0.62,
        "symbol": "106.EMN"
    },
    {
        "序号": 8585,
        "name": "大全新能源",
        "最新价": 23.11,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 23.04,
        "最高价": 23.85,
        "最低价": 22.82,
        "昨收价": 23.17,
        "总市值": 1785619118.0,
        "市盈率": 2.52,
        "成交量": 751702.0,
        "成交额": 17470121.0,
        "振幅": 4.45,
        "换手率": 0.97,
        "symbol": "106.DQ"
    },
    {
        "序号": 8586,
        "name": "iShares Core MSCI Pacific ETF",
        "最新价": 57.76,
        "涨跌额": -0.15,
        "涨跌幅": -0.26,
        "开盘价": 57.43,
        "最高价": 57.76,
        "最低价": 57.4,
        "昨收价": 57.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 32499.0,
        "成交额": 1872948.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.IPAC"
    },
    {
        "序号": 8587,
        "name": "CACI国际",
        "最新价": 322.62,
        "涨跌额": -0.84,
        "涨跌幅": -0.26,
        "开盘价": 324.36,
        "最高价": 324.36,
        "最低价": 320.36,
        "昨收价": 323.46,
        "总市值": 7187338361.0,
        "市盈率": 18.83,
        "成交量": 76105.0,
        "成交额": 24533870.0,
        "振幅": 1.24,
        "换手率": 0.34,
        "symbol": "106.CACI"
    },
    {
        "序号": 8588,
        "name": "小赢科技",
        "最新价": 3.84,
        "涨跌额": -0.01,
        "涨跌幅": -0.26,
        "开盘价": 3.77,
        "最高价": 3.89,
        "最低价": 3.77,
        "昨收价": 3.85,
        "总市值": 184896354.0,
        "市盈率": 1.03,
        "成交量": 12006.0,
        "成交额": 45921.0,
        "振幅": 3.12,
        "换手率": 0.02,
        "symbol": "106.XYF"
    },
    {
        "序号": 8589,
        "name": "高收益封基ETF-YieldShares",
        "最新价": 11.51,
        "涨跌额": -0.03,
        "涨跌幅": -0.26,
        "开盘价": 11.52,
        "最高价": 11.53,
        "最低价": 11.49,
        "昨收价": 11.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 140909.0,
        "成交额": 1621245.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.YYY"
    },
    {
        "序号": 8590,
        "name": "Bancroft Fund",
        "最新价": 15.34,
        "涨跌额": -0.04,
        "涨跌幅": -0.26,
        "开盘价": 15.41,
        "最高价": 15.69,
        "最低价": 15.26,
        "昨收价": 15.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 22582.0,
        "成交额": 347977.0,
        "振幅": 2.8,
        "换手率": null,
        "symbol": "107.BCV"
    },
    {
        "序号": 8591,
        "name": "联邦农业抵押贷款-C",
        "最新价": 172.12,
        "涨跌额": -0.45,
        "涨跌幅": -0.26,
        "开盘价": 171.74,
        "最高价": 175.76,
        "最低价": 170.81,
        "昨收价": 172.57,
        "总市值": 1865927446.0,
        "市盈率": 9.53,
        "成交量": 58709.0,
        "成交额": 10183229.0,
        "振幅": 2.87,
        "换手率": 0.6,
        "symbol": "106.AGM"
    },
    {
        "序号": 8592,
        "name": "Williams-Sonoma Inc",
        "最新价": 194.81,
        "涨跌额": -0.51,
        "涨跌幅": -0.26,
        "开盘价": 192.79,
        "最高价": 195.79,
        "最低价": 192.46,
        "昨收价": 195.32,
        "总市值": 12495993746.0,
        "市盈率": 13.15,
        "成交量": 654950.0,
        "成交额": 127384204.0,
        "振幅": 1.7,
        "换手率": 1.02,
        "symbol": "106.WSM"
    },
    {
        "序号": 8593,
        "name": "Cohen & Steers Total Return Rea",
        "最新价": 11.45,
        "涨跌额": -0.03,
        "涨跌幅": -0.26,
        "开盘价": 11.41,
        "最高价": 11.47,
        "最低价": 11.39,
        "昨收价": 11.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 53601.0,
        "成交额": 612660.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.RFI"
    },
    {
        "序号": 8594,
        "name": "Invesco BulletShares 2026 Corpo",
        "最新价": 19.08,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 19.1,
        "最高价": 19.11,
        "最低价": 19.08,
        "昨收价": 19.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 889756.0,
        "成交额": 16983330.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.BSCQ"
    },
    {
        "序号": 8595,
        "name": "MetLife Inc Series A Pfd",
        "最新价": 22.89,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.9,
        "最高价": 23.11,
        "最低价": 22.84,
        "昨收价": 22.95,
        "总市值": 549360000.0,
        "市盈率": null,
        "成交量": 29212.0,
        "成交额": 670367.0,
        "振幅": 1.18,
        "换手率": 0.12,
        "symbol": "106.MET_A"
    },
    {
        "序号": 8596,
        "name": "Matthews India Active ETF",
        "最新价": 26.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.26,
        "开盘价": 26.63,
        "最高价": 26.63,
        "最低价": 26.62,
        "昨收价": 26.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 315.0,
        "成交额": 8388.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.INDE"
    },
    {
        "序号": 8597,
        "name": "iShares iBonds 2028 Term High Y",
        "最新价": 22.81,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.85,
        "最高价": 22.92,
        "最低价": 22.8,
        "昨收价": 22.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 42067.0,
        "成交额": 961157.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.IBHH"
    },
    {
        "序号": 8598,
        "name": "BOSS直聘",
        "最新价": 15.2,
        "涨跌额": -0.04,
        "涨跌幅": -0.26,
        "开盘价": 15.1,
        "最高价": 15.2,
        "最低价": 14.96,
        "昨收价": 15.24,
        "总市值": 6680048135.0,
        "市盈率": 82.24,
        "成交量": 4458158.0,
        "成交额": 67540068.0,
        "振幅": 1.57,
        "换手率": 1.01,
        "symbol": "105.BZ"
    },
    {
        "序号": 8599,
        "name": "阿里巴巴",
        "最新价": 72.14,
        "涨跌额": -0.19,
        "涨跌幅": -0.26,
        "开盘价": 72.02,
        "最高价": 72.44,
        "最低价": 71.83,
        "昨收价": 72.33,
        "总市值": 183482617171.0,
        "市盈率": 9.69,
        "成交量": 16914758.0,
        "成交额": 1220643008.0,
        "振幅": 0.84,
        "换手率": 0.67,
        "symbol": "106.BABA"
    },
    {
        "序号": 8600,
        "name": "Matthews Asia Innovators Active",
        "最新价": 22.78,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.74,
        "最高价": 22.8,
        "最低价": 22.74,
        "昨收价": 22.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 7159.0,
        "成交额": 163019.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.MINV"
    },
    {
        "序号": 8601,
        "name": "Synchronoss Technologies Note",
        "最新价": 18.95,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 18.75,
        "最高价": 18.99,
        "最低价": 18.65,
        "昨收价": 19.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 2723.0,
        "成交额": 51150.0,
        "振幅": 1.79,
        "换手率": null,
        "symbol": "105.SNCRL"
    },
    {
        "序号": 8602,
        "name": "BRT房地产信托",
        "最新价": 18.94,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "开盘价": 18.89,
        "最高价": 19.13,
        "最低价": 18.89,
        "昨收价": 18.99,
        "总市值": 352215608.0,
        "市盈率": 253.21,
        "成交量": 19189.0,
        "成交额": 364251.0,
        "振幅": 1.26,
        "换手率": 0.1,
        "symbol": "106.BRT"
    },
    {
        "序号": 8603,
        "name": "阿卡西亚",
        "最新价": 3.78,
        "涨跌额": -0.01,
        "涨跌幅": -0.26,
        "开盘价": 3.78,
        "最高价": 3.8,
        "最低价": 3.73,
        "昨收价": 3.79,
        "总市值": 377570297.0,
        "市盈率": -14.48,
        "成交量": 93418.0,
        "成交额": 352578.0,
        "振幅": 1.85,
        "换手率": 0.09,
        "symbol": "105.ACTG"
    },
    {
        "序号": 8604,
        "name": "Invesco New York AMT-Free Munic",
        "最新价": 22.67,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.66,
        "最高价": 22.7,
        "最低价": 22.59,
        "昨收价": 22.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 42413.0,
        "成交额": 959295.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.PZT"
    },
    {
        "序号": 8605,
        "name": "iShares iBonds Dec 2026 Term Tr",
        "最新价": 22.65,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.66,
        "最高价": 22.67,
        "最低价": 22.63,
        "昨收价": 22.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 628528.0,
        "成交额": 14235831.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.IBTG"
    },
    {
        "序号": 8606,
        "name": "The Children's Place Inc",
        "最新价": 22.63,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "开盘价": 22.54,
        "最高价": 23.19,
        "最低价": 22.34,
        "昨收价": 22.69,
        "总市值": 282361865.0,
        "市盈率": -3.7,
        "成交量": 255237.0,
        "成交额": 5815935.0,
        "振幅": 3.75,
        "换手率": 2.05,
        "symbol": "105.PLCE"
    },
    {
        "序号": 8607,
        "name": "Relative Sentiment Tactical All",
        "最新价": 26.39,
        "涨跌额": -0.07,
        "涨跌幅": -0.26,
        "开盘价": 26.38,
        "最高价": 26.39,
        "最低价": 26.38,
        "昨收价": 26.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 820.0,
        "成交额": 21631.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "105.MOOD"
    },
    {
        "序号": 8608,
        "name": "iShares BB Rated Corporate Bond",
        "最新价": 45.22,
        "涨跌额": -0.12,
        "涨跌幅": -0.26,
        "开盘价": 45.16,
        "最高价": 45.28,
        "最低价": 45.13,
        "昨收价": 45.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 35711.0,
        "成交额": 1613920.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.HYBB"
    },
    {
        "序号": 8609,
        "name": "优先股ETF-PowerShares",
        "最新价": 11.25,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.22,
        "最高价": 11.29,
        "最低价": 11.21,
        "昨收价": 11.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 5062918.0,
        "成交额": 56923869.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.PGX"
    },
    {
        "序号": 8610,
        "name": "ProShares DJ Brookfield Global ",
        "最新价": 44.96,
        "涨跌额": -0.12,
        "涨跌幅": -0.27,
        "开盘价": 45.0,
        "最高价": 45.1,
        "最低价": 44.78,
        "昨收价": 45.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 8901.0,
        "成交额": 400159.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.TOLZ"
    },
    {
        "序号": 8611,
        "name": "First Trust Limited Duration In",
        "最新价": 18.73,
        "涨跌额": -0.05,
        "涨跌幅": -0.27,
        "开盘价": 18.74,
        "最高价": 18.75,
        "最低价": 18.71,
        "昨收价": 18.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 313417.0,
        "成交额": 5870576.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "107.FSIG"
    },
    {
        "序号": 8612,
        "name": "Fidelity Limited Term Bond ETF",
        "最新价": 48.6,
        "涨跌额": -0.13,
        "涨跌幅": -0.27,
        "开盘价": 48.58,
        "最高价": 48.63,
        "最低价": 48.52,
        "昨收价": 48.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 15194.0,
        "成交额": 738248.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.FLTB"
    },
    {
        "序号": 8613,
        "name": "欧朋浏览器",
        "最新价": 11.2,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.19,
        "最高价": 11.3,
        "最低价": 11.07,
        "昨收价": 11.23,
        "总市值": 993129066.0,
        "市盈率": 14.87,
        "成交量": 189746.0,
        "成交额": 2120690.0,
        "振幅": 2.05,
        "换手率": 0.21,
        "symbol": "105.OPRA"
    },
    {
        "序号": 8614,
        "name": "AerSale Corp",
        "最新价": 14.91,
        "涨跌额": -0.04,
        "涨跌幅": -0.27,
        "开盘价": 14.95,
        "最高价": 15.26,
        "最低价": 14.71,
        "昨收价": 14.95,
        "总市值": 765312408.0,
        "市盈率": 120.29,
        "成交量": 357880.0,
        "成交额": 5348282.0,
        "振幅": 3.68,
        "换手率": 0.7,
        "symbol": "105.ASLE"
    },
    {
        "序号": 8615,
        "name": "宜居生活资产信托",
        "最新价": 70.81,
        "涨跌额": -0.19,
        "涨跌幅": -0.27,
        "开盘价": 70.64,
        "最高价": 70.92,
        "最低价": 70.36,
        "昨收价": 71.0,
        "总市值": 13198468928.0,
        "市盈率": 44.69,
        "成交量": 811564.0,
        "成交额": 57386646.0,
        "振幅": 0.79,
        "换手率": 0.44,
        "symbol": "106.ELS"
    },
    {
        "序号": 8616,
        "name": "First Trust Dorsey Wright Inter",
        "最新价": 18.63,
        "涨跌额": -0.05,
        "涨跌幅": -0.27,
        "开盘价": 18.61,
        "最高价": 18.72,
        "最低价": 18.58,
        "昨收价": 18.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 22875.0,
        "成交额": 426725.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "105.IFV"
    },
    {
        "序号": 8617,
        "name": "美国包装",
        "最新价": 163.88,
        "涨跌额": -0.44,
        "涨跌幅": -0.27,
        "开盘价": 163.83,
        "最高价": 165.15,
        "最低价": 163.41,
        "昨收价": 164.32,
        "总市值": 14687629792.0,
        "市盈率": 18.65,
        "成交量": 507138.0,
        "成交额": 83163745.0,
        "振幅": 1.06,
        "换手率": 0.57,
        "symbol": "106.PKG"
    },
    {
        "序号": 8618,
        "name": "First Trust Low Duration Strate",
        "最新价": 18.62,
        "涨跌额": -0.05,
        "涨跌幅": -0.27,
        "开盘价": 18.63,
        "最高价": 18.64,
        "最低价": 18.61,
        "昨收价": 18.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 16173.0,
        "成交额": 301180.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.LDSF"
    },
    {
        "序号": 8619,
        "name": "Dimensional Global Core Plus Fi",
        "最新价": 52.13,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 52.06,
        "最高价": 52.27,
        "最低价": 52.01,
        "昨收价": 52.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 47920.0,
        "成交额": 2496180.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "105.DFGP"
    },
    {
        "序号": 8620,
        "name": "房地产信托ETF-iShares",
        "最新价": 55.85,
        "涨跌额": -0.15,
        "涨跌幅": -0.27,
        "开盘价": 55.87,
        "最高价": 56.05,
        "最低价": 55.26,
        "昨收价": 56.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 175230.0,
        "成交额": 9745762.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "107.ICF"
    },
    {
        "序号": 8621,
        "name": "科默斯银行",
        "最新价": 52.09,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 52.4,
        "最高价": 52.83,
        "最低价": 51.85,
        "昨收价": 52.23,
        "总市值": 6802232723.0,
        "市盈率": 13.62,
        "成交量": 333137.0,
        "成交额": 17375386.0,
        "振幅": 1.88,
        "换手率": 0.26,
        "symbol": "105.CBSH"
    },
    {
        "序号": 8622,
        "name": "PGIM Short Duration High Yield ",
        "最新价": 14.87,
        "涨跌额": -0.04,
        "涨跌幅": -0.27,
        "开盘价": 14.87,
        "最高价": 14.91,
        "最低价": 14.83,
        "昨收价": 14.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 98473.0,
        "成交额": 1463613.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "106.SDHY"
    },
    {
        "序号": 8623,
        "name": "奥迪富斯",
        "最新价": 11.14,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.0,
        "最高价": 11.31,
        "最低价": 9.92,
        "昨收价": 11.17,
        "总市值": 252266938.0,
        "市盈率": -7.5,
        "成交量": 37106.0,
        "成交额": 410135.0,
        "振幅": 12.44,
        "换手率": 0.16,
        "symbol": "105.VOXX"
    },
    {
        "序号": 8624,
        "name": "SCE Trust VII Pfd",
        "最新价": 25.98,
        "涨跌额": -0.07,
        "涨跌幅": -0.27,
        "开盘价": 25.95,
        "最高价": 26.03,
        "最低价": 25.92,
        "昨收价": 26.05,
        "总市值": 571560000.0,
        "市盈率": null,
        "成交量": 221917.0,
        "成交额": 5762305.0,
        "振幅": 0.42,
        "换手率": 1.01,
        "symbol": "106.SCE_M"
    },
    {
        "序号": 8625,
        "name": "NorthWestern Corp",
        "最新价": 51.87,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 52.12,
        "最高价": 52.15,
        "最低价": 51.55,
        "昨收价": 52.01,
        "总市值": 3176634885.0,
        "市盈率": 17.87,
        "成交量": 356833.0,
        "成交额": 18512150.0,
        "振幅": 1.15,
        "换手率": 0.58,
        "symbol": "105.NWE"
    },
    {
        "序号": 8626,
        "name": "Fidelity MSCI Utilities Index E",
        "最新价": 40.7,
        "涨跌额": -0.11,
        "涨跌幅": -0.27,
        "开盘价": 40.73,
        "最高价": 40.8,
        "最低价": 40.44,
        "昨收价": 40.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 194252.0,
        "成交额": 7888419.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.FUTY"
    },
    {
        "序号": 8627,
        "name": "温尼巴格实业",
        "最新价": 70.19,
        "涨跌额": -0.19,
        "涨跌幅": -0.27,
        "开盘价": 70.15,
        "最高价": 70.95,
        "最低价": 69.51,
        "昨收价": 70.38,
        "总市值": 2099060096.0,
        "市盈率": 9.72,
        "成交量": 533231.0,
        "成交额": 37512559.0,
        "振幅": 2.05,
        "换手率": 1.78,
        "symbol": "106.WGO"
    },
    {
        "序号": 8628,
        "name": "TLGY Acquisition Corp-A",
        "最新价": 11.07,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.05,
        "最高价": 11.07,
        "最低价": 11.05,
        "昨收价": 11.1,
        "总市值": 144664775.0,
        "市盈率": 24.84,
        "成交量": 956.0,
        "成交额": 10575.0,
        "振幅": 0.18,
        "换手率": 0.01,
        "symbol": "105.TLGY"
    },
    {
        "序号": 8629,
        "name": "Pebblebrook Hotel Trust Series ",
        "最新价": 18.45,
        "涨跌额": -0.05,
        "涨跌幅": -0.27,
        "开盘价": 18.55,
        "最高价": 18.55,
        "最低价": 18.0,
        "昨收价": 18.5,
        "总市值": 166050000.0,
        "市盈率": null,
        "成交量": 6111.0,
        "成交额": 112636.0,
        "振幅": 2.97,
        "换手率": 0.07,
        "symbol": "106.PEB_H"
    },
    {
        "序号": 8630,
        "name": "Evergy",
        "最新价": 51.56,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 51.79,
        "最高价": 51.87,
        "最低价": 51.23,
        "昨收价": 51.7,
        "总市值": 11844402231.0,
        "市盈率": 17.4,
        "成交量": 2020257.0,
        "成交额": 104056092.0,
        "振幅": 1.24,
        "换手率": 0.88,
        "symbol": "105.EVRG"
    },
    {
        "序号": 8631,
        "name": "WinVest Acquisition Corp",
        "最新价": 11.01,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.02,
        "最高价": 11.05,
        "最低价": 11.0,
        "昨收价": 11.04,
        "总市值": 45586123.0,
        "市盈率": -46.7,
        "成交量": 1925.0,
        "成交额": 21196.0,
        "振幅": 0.45,
        "换手率": 0.05,
        "symbol": "105.WINV"
    },
    {
        "序号": 8632,
        "name": "Toews Agility Shares Dynamic Ta",
        "最新价": 22.02,
        "涨跌额": -0.06,
        "涨跌幅": -0.27,
        "开盘价": 22.03,
        "最高价": 22.03,
        "最低价": 22.02,
        "昨收价": 22.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1404.0,
        "成交额": 30930.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.THY"
    },
    {
        "序号": 8633,
        "name": "Semper Paratus Acquisition Corp",
        "最新价": 11.01,
        "涨跌额": -0.03,
        "涨跌幅": -0.27,
        "开盘价": 11.01,
        "最高价": 11.01,
        "最低价": 11.01,
        "昨收价": 11.04,
        "总市值": 157991001.0,
        "市盈率": 46.8,
        "成交量": 2585.0,
        "成交额": 28460.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.LGST"
    },
    {
        "序号": 8634,
        "name": "SharkNinja Inc",
        "最新价": 47.58,
        "涨跌额": -0.13,
        "涨跌幅": -0.27,
        "开盘价": 47.87,
        "最高价": 47.99,
        "最低价": 46.9,
        "昨收价": 47.71,
        "总市值": 6616152208.0,
        "市盈率": 40.25,
        "成交量": 945736.0,
        "成交额": 44935342.0,
        "振幅": 2.28,
        "换手率": 0.68,
        "symbol": "106.SN"
    },
    {
        "序号": 8635,
        "name": "SPDR Loomis Sayles Opportunisti",
        "最新价": 25.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.27,
        "开盘价": 25.67,
        "最高价": 25.67,
        "最低价": 25.62,
        "昨收价": 25.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 217.0,
        "成交额": 5570.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.OBND"
    },
    {
        "序号": 8636,
        "name": "Invesco BulletShares 2031 High ",
        "最新价": 25.61,
        "涨跌额": -0.07,
        "涨跌幅": -0.27,
        "开盘价": 25.62,
        "最高价": 25.62,
        "最低价": 25.6,
        "昨收价": 25.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 3250.0,
        "成交额": 83242.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "105.BSJV"
    },
    {
        "序号": 8637,
        "name": "Ramaco Resources Inc Note",
        "最新价": 25.58,
        "涨跌额": -0.07,
        "涨跌幅": -0.27,
        "开盘价": 25.68,
        "最高价": 25.71,
        "最低价": 25.56,
        "昨收价": 25.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 2544.0,
        "成交额": 65308.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "105.METCL"
    },
    {
        "序号": 8638,
        "name": "巴拉德动力系统",
        "最新价": 3.65,
        "涨跌额": -0.01,
        "涨跌幅": -0.27,
        "开盘价": 3.66,
        "最高价": 3.76,
        "最低价": 3.63,
        "昨收价": 3.66,
        "总市值": 1090326821.0,
        "市盈率": -6.78,
        "成交量": 1266854.0,
        "成交额": 4650231.0,
        "振幅": 3.55,
        "换手率": 0.42,
        "symbol": "105.BLDP"
    },
    {
        "序号": 8639,
        "name": "Safehold Inc",
        "最新价": 21.83,
        "涨跌额": -0.06,
        "涨跌幅": -0.27,
        "开盘价": 21.79,
        "最高价": 22.08,
        "最低价": 21.36,
        "昨收价": 21.89,
        "总市值": 1551379075.0,
        "市盈率": 7.34,
        "成交量": 351139.0,
        "成交额": 7613858.0,
        "振幅": 3.29,
        "换手率": 0.49,
        "symbol": "106.SAFE"
    },
    {
        "序号": 8640,
        "name": "YieldMax GOOGL Option Income St",
        "最新价": 18.15,
        "涨跌额": -0.05,
        "涨跌幅": -0.27,
        "开盘价": 18.11,
        "最高价": 18.2,
        "最低价": 18.11,
        "昨收价": 18.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 21097.0,
        "成交额": 383216.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.GOOY"
    },
    {
        "序号": 8641,
        "name": "iShares JP Morgan Broad USD Eme",
        "最新价": 50.82,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 50.82,
        "最高价": 50.82,
        "最低价": 50.82,
        "昨收价": 50.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 9.0,
        "成交额": 457.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BEMB"
    },
    {
        "序号": 8642,
        "name": "Synovus Financial Corp Series D",
        "最新价": 25.39,
        "涨跌额": -0.07,
        "涨跌幅": -0.27,
        "开盘价": 25.48,
        "最高价": 25.5,
        "最低价": 25.32,
        "昨收价": 25.46,
        "总市值": 203120000.0,
        "市盈率": null,
        "成交量": 8373.0,
        "成交额": 212690.0,
        "振幅": 0.71,
        "换手率": 0.1,
        "symbol": "106.SNV_D"
    },
    {
        "序号": 8643,
        "name": "Janus Henderson Securitized Inc",
        "最新价": 50.77,
        "涨跌额": -0.14,
        "涨跌幅": -0.27,
        "开盘价": 50.76,
        "最高价": 50.8,
        "最低价": 50.76,
        "昨收价": 50.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 2836.0,
        "成交额": 143988.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.JSI"
    },
    {
        "序号": 8644,
        "name": "Columbia Short Duration Bond ET",
        "最新价": 18.13,
        "涨跌额": -0.05,
        "涨跌幅": -0.28,
        "开盘价": 18.12,
        "最高价": 18.13,
        "最低价": 18.12,
        "昨收价": 18.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 249.0,
        "成交额": 4511.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.SBND"
    },
    {
        "序号": 8645,
        "name": "ProShares Short MSCI EAFE",
        "最新价": 18.11,
        "涨跌额": -0.05,
        "涨跌幅": -0.28,
        "开盘价": 18.24,
        "最高价": 18.24,
        "最低价": 18.11,
        "昨收价": 18.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 10351.0,
        "成交额": 187754.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.EFZ"
    },
    {
        "序号": 8646,
        "name": "iShares iBonds Oct 2029 Term TI",
        "最新价": 25.34,
        "涨跌额": -0.07,
        "涨跌幅": -0.28,
        "开盘价": 25.3,
        "最高价": 25.34,
        "最低价": 25.3,
        "昨收价": 25.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 634.0,
        "成交额": 16037.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IBIF"
    },
    {
        "序号": 8647,
        "name": "OCA Acquisition Corp-A",
        "最新价": 10.85,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.85,
        "昨收价": 10.88,
        "总市值": 82874654.0,
        "市盈率": 69.06,
        "成交量": 215.0,
        "成交额": 2332.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.OCAX"
    },
    {
        "序号": 8648,
        "name": "iShares Short-Term Corporate Bo",
        "最新价": 50.6,
        "涨跌额": -0.14,
        "涨跌幅": -0.28,
        "开盘价": 50.61,
        "最高价": 50.66,
        "最低价": 50.59,
        "昨收价": 50.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 2735306.0,
        "成交额": 138437997.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "105.IGSB"
    },
    {
        "序号": 8649,
        "name": "Corporacion America Airports SA",
        "最新价": 14.45,
        "涨跌额": -0.04,
        "涨跌幅": -0.28,
        "开盘价": 14.49,
        "最高价": 14.6,
        "最低价": 14.37,
        "昨收价": 14.49,
        "总市值": 2358568116.0,
        "市盈率": 15.2,
        "成交量": 44363.0,
        "成交额": 641954.0,
        "振幅": 1.59,
        "换手率": 0.03,
        "symbol": "106.CAAP"
    },
    {
        "序号": 8650,
        "name": "iShares iBonds Oct 2027 Term TI",
        "最新价": 25.27,
        "涨跌额": -0.07,
        "涨跌幅": -0.28,
        "开盘价": 25.27,
        "最高价": 25.27,
        "最低价": 25.26,
        "昨收价": 25.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 811.0,
        "成交额": 20486.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.IBID"
    },
    {
        "序号": 8651,
        "name": "Ares Acquisition Corp-A",
        "最新价": 10.82,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.82,
        "最高价": 10.82,
        "最低价": 10.82,
        "昨收价": 10.85,
        "总市值": 302728603.0,
        "市盈率": 19.46,
        "成交量": 509.0,
        "成交额": 5507.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "107.JWSM"
    },
    {
        "序号": 8652,
        "name": "DP Cap Acquisition Corp I-A",
        "最新价": 10.81,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.81,
        "最高价": 10.81,
        "最低价": 10.81,
        "昨收价": 10.84,
        "总市值": 106039636.0,
        "市盈率": 20.09,
        "成交量": 406.0,
        "成交额": 4388.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.DPCS"
    },
    {
        "序号": 8653,
        "name": "保罗迪圣电讯",
        "最新价": 10.77,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.72,
        "最高价": 10.8,
        "最低价": 10.68,
        "昨收价": 10.8,
        "总市值": 17841112773.0,
        "市盈率": 19.35,
        "成交量": 777644.0,
        "成交额": 8364093.0,
        "振幅": 1.11,
        "换手率": 0.05,
        "symbol": "106.VIV"
    },
    {
        "序号": 8654,
        "name": "阿斯伯里汽车集团",
        "最新价": 215.21,
        "涨跌额": -0.6,
        "涨跌幅": -0.28,
        "开盘价": 215.84,
        "最高价": 218.32,
        "最低价": 213.51,
        "昨收价": 215.81,
        "总市值": 4428456228.0,
        "市盈率": 4.92,
        "成交量": 62982.0,
        "成交额": 13561700.0,
        "振幅": 2.23,
        "换手率": 0.31,
        "symbol": "106.ABG"
    },
    {
        "序号": 8655,
        "name": "Vacasa Inc-A",
        "最新价": 7.17,
        "涨跌额": -0.02,
        "涨跌幅": -0.28,
        "开盘价": 7.12,
        "最高价": 7.23,
        "最低价": 6.87,
        "昨收价": 7.19,
        "总市值": 159572258.0,
        "市盈率": -0.38,
        "成交量": 186611.0,
        "成交额": 1315576.0,
        "振幅": 5.01,
        "换手率": 0.84,
        "symbol": "105.VCSA"
    },
    {
        "序号": 8656,
        "name": "杰克亨利",
        "最新价": 164.69,
        "涨跌额": -0.46,
        "涨跌幅": -0.28,
        "开盘价": 165.48,
        "最高价": 166.01,
        "最低价": 164.23,
        "昨收价": 165.15,
        "总市值": 11994076587.0,
        "市盈率": 33.15,
        "成交量": 724773.0,
        "成交额": 119549958.0,
        "振幅": 1.08,
        "换手率": 1.0,
        "symbol": "105.JKHY"
    },
    {
        "序号": 8657,
        "name": "iShares California Muni Bond ET",
        "最新价": 57.07,
        "涨跌额": -0.16,
        "涨跌幅": -0.28,
        "开盘价": 57.16,
        "最高价": 57.17,
        "最低价": 57.04,
        "昨收价": 57.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 324434.0,
        "成交额": 18520987.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.CMF"
    },
    {
        "序号": 8658,
        "name": "Corner Growth Acquisition Corp-",
        "最新价": 10.7,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.77,
        "最高价": 10.77,
        "最低价": 10.67,
        "昨收价": 10.73,
        "总市值": 111493337.0,
        "市盈率": -161.97,
        "成交量": 7925.0,
        "成交额": 84807.0,
        "振幅": 0.93,
        "换手率": 0.08,
        "symbol": "105.COOL"
    },
    {
        "序号": 8659,
        "name": "CorMedix Inc",
        "最新价": 3.56,
        "涨跌额": -0.01,
        "涨跌幅": -0.28,
        "开盘价": 3.55,
        "最高价": 3.74,
        "最低价": 3.51,
        "昨收价": 3.57,
        "总市值": 195130870.0,
        "市盈率": -4.9,
        "成交量": 945589.0,
        "成交额": 3409156.0,
        "振幅": 6.44,
        "换手率": 1.73,
        "symbol": "105.CRMD"
    },
    {
        "序号": 8660,
        "name": "Aquaron Acquisition Corp",
        "最新价": 10.66,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.69,
        "最高价": 10.69,
        "最低价": 10.64,
        "昨收价": 10.69,
        "总市值": 48536579.0,
        "市盈率": 54.69,
        "成交量": 64907.0,
        "成交额": 693733.0,
        "振幅": 0.47,
        "换手率": 1.43,
        "symbol": "105.AQU"
    },
    {
        "序号": 8661,
        "name": "皇冠控股",
        "最新价": 88.53,
        "涨跌额": -0.25,
        "涨跌幅": -0.28,
        "开盘价": 88.49,
        "最高价": 89.15,
        "最低价": 88.0,
        "昨收价": 88.78,
        "总市值": 10680824818.0,
        "市盈率": 21.07,
        "成交量": 818934.0,
        "成交额": 72557882.0,
        "振幅": 1.3,
        "换手率": 0.68,
        "symbol": "106.CCK"
    },
    {
        "序号": 8662,
        "name": "Western Asset Global High Incom",
        "最新价": 7.08,
        "涨跌额": -0.02,
        "涨跌幅": -0.28,
        "开盘价": 7.1,
        "最高价": 7.11,
        "最低价": 7.03,
        "昨收价": 7.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 48795.0,
        "成交额": 345620.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "106.EHI"
    },
    {
        "序号": 8663,
        "name": "Touchstone Strategic Income Opp",
        "最新价": 24.74,
        "涨跌额": -0.07,
        "涨跌幅": -0.28,
        "开盘价": 24.72,
        "最高价": 24.74,
        "最低价": 24.72,
        "昨收价": 24.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 141.0,
        "成交额": 3485.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.SIO"
    },
    {
        "序号": 8664,
        "name": "The Gabelli Equity Trust Inc Se",
        "最新价": 21.2,
        "涨跌额": -0.06,
        "涨跌幅": -0.28,
        "开盘价": 21.26,
        "最高价": 21.31,
        "最低价": 21.2,
        "昨收价": 21.26,
        "总市值": 55019809.0,
        "市盈率": null,
        "成交量": 3320.0,
        "成交额": 70542.0,
        "振幅": 0.52,
        "换手率": 0.13,
        "symbol": "106.GAB_G"
    },
    {
        "序号": 8665,
        "name": "iShares Core Conservative Alloc",
        "最新价": 35.32,
        "涨跌额": -0.1,
        "涨跌幅": -0.28,
        "开盘价": 35.23,
        "最高价": 35.39,
        "最低价": 35.23,
        "昨收价": 35.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 261359.0,
        "成交额": 9239362.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.AOK"
    },
    {
        "序号": 8666,
        "name": "Duke Energy Corp Series A Pfd",
        "最新价": 24.7,
        "涨跌额": -0.07,
        "涨跌幅": -0.28,
        "开盘价": 24.68,
        "最高价": 24.85,
        "最低价": 24.56,
        "昨收价": 24.77,
        "总市值": 988000.0,
        "市盈率": null,
        "成交量": 93577.0,
        "成交额": 2310037.0,
        "振幅": 1.17,
        "换手率": 233.94,
        "symbol": "106.DUK_A"
    },
    {
        "序号": 8667,
        "name": "Yotta Acquisition Corp",
        "最新价": 10.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.28,
        "开盘价": 10.6,
        "最高价": 10.6,
        "最低价": 10.57,
        "昨收价": 10.6,
        "总市值": 77198989.0,
        "市盈率": 162.29,
        "成交量": 403.0,
        "成交额": 4264.0,
        "振幅": 0.28,
        "换手率": 0.01,
        "symbol": "105.YOTA"
    },
    {
        "序号": 8668,
        "name": "Franklin U.S. Core Bond ETF",
        "最新价": 21.14,
        "涨跌额": -0.06,
        "涨跌幅": -0.28,
        "开盘价": 21.12,
        "最高价": 21.17,
        "最低价": 21.09,
        "昨收价": 21.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 108274.0,
        "成交额": 2285921.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.FLCB"
    },
    {
        "序号": 8669,
        "name": "Premier Inc-A",
        "最新价": 21.0,
        "涨跌额": -0.06,
        "涨跌幅": -0.28,
        "开盘价": 21.06,
        "最高价": 21.07,
        "最低价": 20.77,
        "昨收价": 21.06,
        "总市值": 2513121471.0,
        "市盈率": 14.19,
        "成交量": 721582.0,
        "成交额": 15111878.0,
        "振幅": 1.42,
        "换手率": 0.6,
        "symbol": "105.PINC"
    },
    {
        "序号": 8670,
        "name": "Dime Community Bancshares Inc P",
        "最新价": 17.48,
        "涨跌额": -0.05,
        "涨跌幅": -0.29,
        "开盘价": 17.51,
        "最高价": 17.8,
        "最低价": 17.15,
        "昨收价": 17.53,
        "总市值": 92630016.0,
        "市盈率": null,
        "成交量": 9357.0,
        "成交额": 163467.0,
        "振幅": 3.71,
        "换手率": 0.18,
        "symbol": "105.DCOMP"
    },
    {
        "序号": 8671,
        "name": "Innovator Emerging Markets Powe",
        "最新价": 24.4,
        "涨跌额": -0.07,
        "涨跌幅": -0.29,
        "开盘价": 24.4,
        "最高价": 24.41,
        "最低价": 24.34,
        "昨收价": 24.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 7591.0,
        "成交额": 185193.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.EAPR"
    },
    {
        "序号": 8672,
        "name": "XAI Octagon Floating Rate & Alt",
        "最新价": 24.38,
        "涨跌额": -0.07,
        "涨跌幅": -0.29,
        "开盘价": 24.46,
        "最高价": 24.46,
        "最低价": 24.35,
        "昨收价": 24.45,
        "总市值": 38910480.0,
        "市盈率": null,
        "成交量": 3336.0,
        "成交额": 81331.0,
        "振幅": 0.45,
        "换手率": 0.21,
        "symbol": "106.XFLT_A"
    },
    {
        "序号": 8673,
        "name": "EPR不动产",
        "最新价": 45.27,
        "涨跌额": -0.13,
        "涨跌幅": -0.29,
        "开盘价": 45.32,
        "最高价": 45.49,
        "最低价": 44.92,
        "昨收价": 45.4,
        "总市值": 3410163477.0,
        "市盈率": 20.08,
        "成交量": 593241.0,
        "成交额": 26790970.0,
        "振幅": 1.26,
        "换手率": 0.79,
        "symbol": "106.EPR"
    },
    {
        "序号": 8674,
        "name": "Bridgewater Bancshares Inc Seri",
        "最新价": 17.4,
        "涨跌额": -0.05,
        "涨跌幅": -0.29,
        "开盘价": 17.4,
        "最高价": 17.45,
        "最低价": 17.35,
        "昨收价": 17.45,
        "总市值": 480240.0,
        "市盈率": null,
        "成交量": 1971.0,
        "成交额": 34306.0,
        "振幅": 0.57,
        "换手率": 7.14,
        "symbol": "105.BWBBP"
    },
    {
        "序号": 8675,
        "name": "高频电子",
        "最新价": 10.42,
        "涨跌额": -0.03,
        "涨跌幅": -0.29,
        "开盘价": 10.34,
        "最高价": 10.58,
        "最低价": 10.31,
        "昨收价": 10.45,
        "总市值": 97844279.0,
        "市盈率": -286.09,
        "成交量": 18964.0,
        "成交额": 198328.0,
        "振幅": 2.58,
        "换手率": 0.2,
        "symbol": "105.FEIM"
    },
    {
        "序号": 8676,
        "name": "FlexShares STOXX Global Broad I",
        "最新价": 52.09,
        "涨跌额": -0.15,
        "涨跌幅": -0.29,
        "开盘价": 52.11,
        "最高价": 52.29,
        "最低价": 51.96,
        "昨收价": 52.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 55391.0,
        "成交额": 2882953.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.NFRA"
    },
    {
        "序号": 8677,
        "name": "短期公司债指数ETF-Vanguard",
        "最新价": 76.34,
        "涨跌额": -0.22,
        "涨跌幅": -0.29,
        "开盘价": 76.37,
        "最高价": 76.42,
        "最低价": 76.33,
        "昨收价": 76.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 3461295.0,
        "成交额": 264325665.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.VCSH"
    },
    {
        "序号": 8678,
        "name": "Bank of America Corp Series 1 P",
        "最新价": 20.78,
        "涨跌额": -0.06,
        "涨跌幅": -0.29,
        "开盘价": 20.7,
        "最高价": 20.78,
        "最低价": 20.64,
        "昨收价": 20.84,
        "总市值": 68055.0,
        "市盈率": null,
        "成交量": 2923.0,
        "成交额": 60507.0,
        "振幅": 0.67,
        "换手率": 89.25,
        "symbol": "106.BML_G"
    },
    {
        "序号": 8679,
        "name": "做空道指ETF-ProShares",
        "最新价": 31.16,
        "涨跌额": -0.09,
        "涨跌幅": -0.29,
        "开盘价": 31.27,
        "最高价": 31.31,
        "最低价": 31.11,
        "昨收价": 31.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 593339.0,
        "成交额": 18522633.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.DOG"
    },
    {
        "序号": 8680,
        "name": "新园能源",
        "最新价": 6.91,
        "涨跌额": -0.02,
        "涨跌幅": -0.29,
        "开盘价": 6.97,
        "最高价": 7.09,
        "最低价": 6.87,
        "昨收价": 6.93,
        "总市值": 587959206.0,
        "市盈率": 24.51,
        "成交量": 943457.0,
        "成交额": 6535849.0,
        "振幅": 3.17,
        "换手率": 1.11,
        "symbol": "106.NR"
    },
    {
        "序号": 8681,
        "name": "Nuveen Massachusetts Quality Mu",
        "最新价": 10.34,
        "涨跌额": -0.03,
        "涨跌幅": -0.29,
        "开盘价": 10.35,
        "最高价": 10.37,
        "最低价": 10.33,
        "昨收价": 10.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 21218.0,
        "成交额": 219615.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.NMT"
    },
    {
        "序号": 8682,
        "name": "WisdomTree Emerging Markets Ex-",
        "最新价": 27.56,
        "涨跌额": -0.08,
        "涨跌幅": -0.29,
        "开盘价": 27.48,
        "最高价": 27.65,
        "最低价": 27.47,
        "昨收价": 27.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 338835.0,
        "成交额": 9330834.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.XSOE"
    },
    {
        "序号": 8683,
        "name": "哈特兰快递",
        "最新价": 13.76,
        "涨跌额": -0.04,
        "涨跌幅": -0.29,
        "开盘价": 13.8,
        "最高价": 13.97,
        "最低价": 13.75,
        "昨收价": 13.8,
        "总市值": 1087407048.0,
        "市盈率": 43.2,
        "成交量": 224467.0,
        "成交额": 3093306.0,
        "振幅": 1.59,
        "换手率": 0.28,
        "symbol": "105.HTLD"
    },
    {
        "序号": 8684,
        "name": "Global X MLP ETF",
        "最新价": 44.67,
        "涨跌额": -0.13,
        "涨跌幅": -0.29,
        "开盘价": 44.73,
        "最高价": 44.83,
        "最低价": 44.51,
        "昨收价": 44.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 75858.0,
        "成交额": 3388166.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.MLPA"
    },
    {
        "序号": 8685,
        "name": "日常消费ETF-FirstTrust AlphaDEX",
        "最新价": 61.65,
        "涨跌额": -0.18,
        "涨跌幅": -0.29,
        "开盘价": 61.83,
        "最高价": 62.0,
        "最低价": 61.56,
        "昨收价": 61.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 25504.0,
        "成交额": 1574732.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.FXG"
    },
    {
        "序号": 8686,
        "name": "Columbia Multi-Sector Municipal",
        "最新价": 20.52,
        "涨跌额": -0.06,
        "涨跌幅": -0.29,
        "开盘价": 20.44,
        "最高价": 20.56,
        "最低价": 20.44,
        "昨收价": 20.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 86002.0,
        "成交额": 1765100.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.MUST"
    },
    {
        "序号": 8687,
        "name": "Goldman Sachs Future Health Car",
        "最新价": 30.75,
        "涨跌额": -0.09,
        "涨跌幅": -0.29,
        "开盘价": 30.7,
        "最高价": 30.78,
        "最低价": 30.7,
        "昨收价": 30.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 2203.0,
        "成交额": 67664.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.GDOC"
    },
    {
        "序号": 8688,
        "name": "Encompass Health Corp",
        "最新价": 64.8,
        "涨跌额": -0.19,
        "涨跌幅": -0.29,
        "开盘价": 64.9,
        "最高价": 65.03,
        "最低价": 64.15,
        "昨收价": 64.99,
        "总市值": 6495603581.0,
        "市盈率": 18.36,
        "成交量": 674501.0,
        "成交额": 43663273.0,
        "振幅": 1.35,
        "换手率": 0.67,
        "symbol": "106.EHC"
    },
    {
        "序号": 8689,
        "name": "Aptus Enhanced Yield ETF",
        "最新价": 23.87,
        "涨跌额": -0.07,
        "涨跌幅": -0.29,
        "开盘价": 23.94,
        "最高价": 23.97,
        "最低价": 23.87,
        "昨收价": 23.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 68129.0,
        "成交额": 1631688.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.JUCY"
    },
    {
        "序号": 8690,
        "name": "Global X AgTech & Food Innovati",
        "最新价": 10.22,
        "涨跌额": -0.03,
        "涨跌幅": -0.29,
        "开盘价": 10.23,
        "最高价": 10.26,
        "最低价": 10.17,
        "昨收价": 10.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 3392.0,
        "成交额": 34636.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "105.KROP"
    },
    {
        "序号": 8691,
        "name": "B&G食品",
        "最新价": 10.22,
        "涨跌额": -0.03,
        "涨跌幅": -0.29,
        "开盘价": 10.18,
        "最高价": 10.45,
        "最低价": 10.12,
        "昨收价": 10.25,
        "总市值": 803541562.0,
        "市盈率": -18.07,
        "成交量": 1165594.0,
        "成交额": 11925180.0,
        "振幅": 3.22,
        "换手率": 1.48,
        "symbol": "106.BGS"
    },
    {
        "序号": 8692,
        "name": "Crane NXT Co",
        "最新价": 51.09,
        "涨跌额": -0.15,
        "涨跌幅": -0.29,
        "开盘价": 51.4,
        "最高价": 51.95,
        "最低价": 50.76,
        "昨收价": 51.24,
        "总市值": 2902073036.0,
        "市盈率": 7.31,
        "成交量": 354159.0,
        "成交额": 18091676.0,
        "振幅": 2.32,
        "换手率": 0.62,
        "symbol": "106.CXT"
    },
    {
        "序号": 8693,
        "name": "JPMorgan Diversified Return Eme",
        "最新价": 51.06,
        "涨跌额": -0.15,
        "涨跌幅": -0.29,
        "开盘价": 50.95,
        "最高价": 51.12,
        "最低价": 50.9,
        "昨收价": 51.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 32430.0,
        "成交额": 1654076.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.JPEM"
    },
    {
        "序号": 8694,
        "name": "Fidelity Preferred Securities &",
        "最新价": 20.42,
        "涨跌额": -0.06,
        "涨跌幅": -0.29,
        "开盘价": 20.49,
        "最高价": 20.49,
        "最低价": 20.38,
        "昨收价": 20.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 7092.0,
        "成交额": 144774.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.FPFD"
    },
    {
        "序号": 8695,
        "name": "Cullen\/Frost Bankers Inc Series",
        "最新价": 16.99,
        "涨跌额": -0.05,
        "涨跌幅": -0.29,
        "开盘价": 16.92,
        "最高价": 17.13,
        "最低价": 16.82,
        "昨收价": 17.04,
        "总市值": 2548500.0,
        "市盈率": null,
        "成交量": 11763.0,
        "成交额": 199396.0,
        "振幅": 1.82,
        "换手率": 7.84,
        "symbol": "106.CFR_B"
    },
    {
        "序号": 8696,
        "name": "陶氏",
        "最新价": 50.92,
        "涨跌额": -0.15,
        "涨跌幅": -0.29,
        "开盘价": 51.04,
        "最高价": 51.78,
        "最低价": 50.9,
        "昨收价": 51.07,
        "总市值": 35715135546.0,
        "市盈率": 27.33,
        "成交量": 3664115.0,
        "成交额": 187041309.0,
        "振幅": 1.72,
        "换手率": 0.52,
        "symbol": "106.DOW"
    },
    {
        "序号": 8697,
        "name": "iShares Edge MSCI Min Vol Emerg",
        "最新价": 54.23,
        "涨跌额": -0.16,
        "涨跌幅": -0.29,
        "开盘价": 54.18,
        "最高价": 54.32,
        "最低价": 54.11,
        "昨收价": 54.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 395338.0,
        "成交额": 21431728.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.EEMV"
    },
    {
        "序号": 8698,
        "name": "Structured Products Corp Credit",
        "最新价": 27.1,
        "涨跌额": -0.08,
        "涨跌幅": -0.29,
        "开盘价": 27.19,
        "最高价": 27.19,
        "最低价": 27.1,
        "昨收价": 27.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 380.0,
        "成交额": 10309.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "106.KTN"
    },
    {
        "序号": 8699,
        "name": "AECOM",
        "最新价": 91.23,
        "涨跌额": -0.27,
        "涨跌幅": -0.3,
        "开盘价": 91.5,
        "最高价": 92.38,
        "最低价": 91.03,
        "昨收价": 91.5,
        "总市值": 12406117182.0,
        "市盈率": 224.21,
        "成交量": 478472.0,
        "成交额": 43741408.0,
        "振幅": 1.48,
        "换手率": 0.35,
        "symbol": "106.ACM"
    },
    {
        "序号": 8700,
        "name": "Spear Alpha ETF",
        "最新价": 20.27,
        "涨跌额": -0.06,
        "涨跌幅": -0.3,
        "开盘价": 19.96,
        "最高价": 20.39,
        "最低价": 19.89,
        "昨收价": 20.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 28771.0,
        "成交额": 581390.0,
        "振幅": 2.46,
        "换手率": null,
        "symbol": "105.SPRX"
    },
    {
        "序号": 8701,
        "name": "Federated Hermes Short Duration",
        "最新价": 23.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.3,
        "开盘价": 23.62,
        "最高价": 23.62,
        "最低价": 23.62,
        "昨收价": 23.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 50.0,
        "成交额": 1180.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FCSH"
    },
    {
        "序号": 8702,
        "name": "Invesco BulletShares 2027 Munic",
        "最新价": 23.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.3,
        "开盘价": 23.61,
        "最高价": 23.66,
        "最低价": 23.61,
        "昨收价": 23.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 26750.0,
        "成交额": 632366.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "105.BSMR"
    },
    {
        "序号": 8703,
        "name": "F\/m Opportunistic Income ETF",
        "最新价": 50.61,
        "涨跌额": -0.15,
        "涨跌幅": -0.3,
        "开盘价": 50.61,
        "最高价": 50.61,
        "最低价": 50.61,
        "昨收价": 50.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 101.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.XFIX"
    },
    {
        "序号": 8704,
        "name": "天然阳光产品",
        "最新价": 16.87,
        "涨跌额": -0.05,
        "涨跌幅": -0.3,
        "开盘价": 16.92,
        "最高价": 17.04,
        "最低价": 16.76,
        "昨收价": 16.92,
        "总市值": 321795942.0,
        "市盈率": 39.89,
        "成交量": 51928.0,
        "成交额": 876772.0,
        "振幅": 1.65,
        "换手率": 0.27,
        "symbol": "105.NATR"
    },
    {
        "序号": 8705,
        "name": "纳斯达克",
        "最新价": 53.97,
        "涨跌额": -0.16,
        "涨跌幅": -0.3,
        "开盘价": 54.06,
        "最高价": 54.39,
        "最低价": 53.62,
        "昨收价": 54.13,
        "总市值": 31138777843.0,
        "市盈率": 28.23,
        "成交量": 2065533.0,
        "成交额": 111458991.0,
        "振幅": 1.42,
        "换手率": 0.36,
        "symbol": "105.NDAQ"
    },
    {
        "序号": 8706,
        "name": "Royce Value Trust",
        "最新价": 13.47,
        "涨跌额": -0.04,
        "涨跌幅": -0.3,
        "开盘价": 13.51,
        "最高价": 13.57,
        "最低价": 13.4,
        "昨收价": 13.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 317365.0,
        "成交额": 4276188.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "106.RVT"
    },
    {
        "序号": 8707,
        "name": "美国先锋",
        "最新价": 10.1,
        "涨跌额": -0.03,
        "涨跌幅": -0.3,
        "开盘价": 10.07,
        "最高价": 10.19,
        "最低价": 10.05,
        "昨收价": 10.13,
        "总市值": 290379434.0,
        "市盈率": 65.43,
        "成交量": 200851.0,
        "成交额": 2030122.0,
        "振幅": 1.38,
        "换手率": 0.7,
        "symbol": "106.AVD"
    },
    {
        "序号": 8708,
        "name": "做空纳斯达克100ETF-ProShares",
        "最新价": 10.07,
        "涨跌额": -0.03,
        "涨跌幅": -0.3,
        "开盘价": 10.16,
        "最高价": 10.17,
        "最低价": 10.06,
        "昨收价": 10.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 23345337.0,
        "成交额": 235914778.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.PSQ"
    },
    {
        "序号": 8709,
        "name": "SPDR S&P International SmallCap",
        "最新价": 30.21,
        "涨跌额": -0.09,
        "涨跌幅": -0.3,
        "开盘价": 30.06,
        "最高价": 30.31,
        "最低价": 30.06,
        "昨收价": 30.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 71376.0,
        "成交额": 2154335.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.GWX"
    },
    {
        "序号": 8710,
        "name": "Reaves Utility Income Fund",
        "最新价": 26.74,
        "涨跌额": -0.08,
        "涨跌幅": -0.3,
        "开盘价": 26.82,
        "最高价": 26.9,
        "最低价": 26.65,
        "昨收价": 26.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 187550.0,
        "成交额": 5015056.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.UTG"
    },
    {
        "序号": 8711,
        "name": "PropertyGuru Group Ltd",
        "最新价": 3.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.3,
        "开盘价": 3.39,
        "最高价": 3.49,
        "最低价": 3.32,
        "昨收价": 3.35,
        "总市值": 543018098.0,
        "市盈率": -25.44,
        "成交量": 19226.0,
        "成交额": 64656.0,
        "振幅": 5.07,
        "换手率": 0.01,
        "symbol": "106.PGRU"
    },
    {
        "序号": 8712,
        "name": "First Trust TCW Opportunistic F",
        "最新价": 43.41,
        "涨跌额": -0.13,
        "涨跌幅": -0.3,
        "开盘价": 43.44,
        "最高价": 43.53,
        "最低价": 43.29,
        "昨收价": 43.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 765127.0,
        "成交额": 33204360.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "105.FIXD"
    },
    {
        "序号": 8713,
        "name": "Vident U.S. Bond Strategy ETF",
        "最新价": 43.24,
        "涨跌额": -0.13,
        "涨跌幅": -0.3,
        "开盘价": 43.19,
        "最高价": 43.28,
        "最低价": 43.16,
        "昨收价": 43.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 7591.0,
        "成交额": 328060.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.VBND"
    },
    {
        "序号": 8714,
        "name": "Rhinebeck Bancorp Inc",
        "最新价": 6.63,
        "涨跌额": -0.02,
        "涨跌幅": -0.3,
        "开盘价": 6.63,
        "最高价": 6.65,
        "最低价": 6.44,
        "昨收价": 6.65,
        "总市值": 73411384.0,
        "市盈率": 17.18,
        "成交量": 4738.0,
        "成交额": 31339.0,
        "振幅": 3.16,
        "换手率": 0.04,
        "symbol": "105.RBKB"
    },
    {
        "序号": 8715,
        "name": "特许通讯",
        "最新价": 367.55,
        "涨跌额": -1.11,
        "涨跌幅": -0.3,
        "开盘价": 364.05,
        "最高价": 370.84,
        "最低价": 363.47,
        "昨收价": 368.66,
        "总市值": 54368101119.0,
        "市盈率": 11.58,
        "成交量": 870608.0,
        "成交额": 318979248.0,
        "振幅": 2.0,
        "换手率": 0.59,
        "symbol": "105.CHTR"
    },
    {
        "序号": 8716,
        "name": "短期国债指数ETF-Vanguard",
        "最新价": 76.12,
        "涨跌额": -0.23,
        "涨跌幅": -0.3,
        "开盘价": 76.17,
        "最高价": 76.19,
        "最低价": 76.08,
        "昨收价": 76.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 2509239.0,
        "成交额": 191023535.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.BSV"
    },
    {
        "序号": 8717,
        "name": "Goldman Sachs Access Investment",
        "最新价": 46.22,
        "涨跌额": -0.14,
        "涨跌幅": -0.3,
        "开盘价": 46.22,
        "最高价": 46.22,
        "最低价": 46.22,
        "昨收价": 46.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 85.0,
        "成交额": 3928.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GSIG"
    },
    {
        "序号": 8718,
        "name": "DigitalBridge Group Inc-A",
        "最新价": 16.5,
        "涨跌额": -0.05,
        "涨跌幅": -0.3,
        "开盘价": 16.49,
        "最高价": 16.64,
        "最低价": 16.12,
        "昨收价": 16.55,
        "总市值": 2696280164.0,
        "市盈率": 41.21,
        "成交量": 894374.0,
        "成交额": 14702536.0,
        "振幅": 3.14,
        "换手率": 0.55,
        "symbol": "106.DBRG"
    },
    {
        "序号": 8719,
        "name": "莫霍克工业",
        "最新价": 92.3,
        "涨跌额": -0.28,
        "涨跌幅": -0.3,
        "开盘价": 92.65,
        "最高价": 93.14,
        "最低价": 91.84,
        "昨收价": 92.58,
        "总市值": 5877848600.0,
        "市盈率": -10.77,
        "成交量": 879277.0,
        "成交额": 81403089.0,
        "振幅": 1.4,
        "换手率": 1.38,
        "symbol": "106.MHK"
    },
    {
        "序号": 8720,
        "name": "Invesco Taxable Municipal Bond ",
        "最新价": 26.36,
        "涨跌额": -0.08,
        "涨跌幅": -0.3,
        "开盘价": 26.39,
        "最高价": 26.48,
        "最低价": 26.14,
        "昨收价": 26.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 177759.0,
        "成交额": 4675281.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "107.BAB"
    },
    {
        "序号": 8721,
        "name": "Adient plc",
        "最新价": 32.89,
        "涨跌额": -0.1,
        "涨跌幅": -0.3,
        "开盘价": 33.0,
        "最高价": 33.35,
        "最低价": 32.6,
        "昨收价": 32.99,
        "总市值": 3081717485.0,
        "市盈率": 15.03,
        "成交量": 1264301.0,
        "成交额": 41781868.0,
        "振幅": 2.27,
        "换手率": 1.35,
        "symbol": "106.ADNT"
    },
    {
        "序号": 8722,
        "name": "SmartFinancial Inc",
        "最新价": 22.98,
        "涨跌额": -0.07,
        "涨跌幅": -0.3,
        "开盘价": 22.33,
        "最高价": 23.49,
        "最低价": 22.33,
        "昨收价": 23.05,
        "总市值": 390534598.0,
        "市盈率": 11.03,
        "成交量": 74658.0,
        "成交额": 1720098.0,
        "振幅": 5.03,
        "换手率": 0.44,
        "symbol": "105.SMBK"
    },
    {
        "序号": 8723,
        "name": "KraneShares China Innovation ET",
        "最新价": 13.13,
        "涨跌额": -0.04,
        "涨跌幅": -0.3,
        "开盘价": 13.13,
        "最高价": 13.13,
        "最低价": 13.13,
        "昨收价": 13.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 10.0,
        "成交额": 131.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KGRO"
    },
    {
        "序号": 8724,
        "name": "VictoryShares Dividend Accelera",
        "最新价": 45.91,
        "涨跌额": -0.14,
        "涨跌幅": -0.3,
        "开盘价": 45.98,
        "最高价": 46.15,
        "最低价": 45.84,
        "昨收价": 46.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 14383.0,
        "成交额": 661285.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "105.VSDA"
    },
    {
        "序号": 8725,
        "name": "Safety Insurance Group Inc",
        "最新价": 78.62,
        "涨跌额": -0.24,
        "涨跌幅": -0.3,
        "开盘价": 78.66,
        "最高价": 78.88,
        "最低价": 78.01,
        "昨收价": 78.86,
        "总市值": 1162939728.0,
        "市盈率": 37.22,
        "成交量": 39882.0,
        "成交额": 3134846.0,
        "振幅": 1.1,
        "换手率": 0.27,
        "symbol": "105.SAFT"
    },
    {
        "序号": 8726,
        "name": "High Income Securities Fund",
        "最新价": 6.55,
        "涨跌额": -0.02,
        "涨跌幅": -0.3,
        "开盘价": 6.56,
        "最高价": 6.62,
        "最低价": 6.51,
        "昨收价": 6.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 56781.0,
        "成交额": 373198.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "106.PCF"
    },
    {
        "序号": 8727,
        "name": "Goldman Sachs ActiveBeta Emergi",
        "最新价": 29.45,
        "涨跌额": -0.09,
        "涨跌幅": -0.3,
        "开盘价": 29.39,
        "最高价": 29.54,
        "最低价": 29.37,
        "昨收价": 29.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 69795.0,
        "成交额": 2055705.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.GEM"
    },
    {
        "序号": 8728,
        "name": "Zurn Elkay Water Solutions Corp",
        "最新价": 29.39,
        "涨跌额": -0.09,
        "涨跌幅": -0.31,
        "开盘价": 29.44,
        "最高价": 29.81,
        "最低价": 29.3,
        "昨收价": 29.48,
        "总市值": 5078902094.0,
        "市盈率": 44.95,
        "成交量": 729709.0,
        "成交额": 21491954.0,
        "振幅": 1.73,
        "换手率": 0.42,
        "symbol": "106.ZWS"
    },
    {
        "序号": 8729,
        "name": "iShares International High Yiel",
        "最新价": 48.96,
        "涨跌额": -0.15,
        "涨跌幅": -0.31,
        "开盘价": 48.96,
        "最高价": 48.96,
        "最低价": 48.96,
        "昨收价": 49.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 142.0,
        "成交额": 6952.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HYXU"
    },
    {
        "序号": 8730,
        "name": "Eaton Vance National Municipal ",
        "最新价": 16.29,
        "涨跌额": -0.05,
        "涨跌幅": -0.31,
        "开盘价": 16.42,
        "最高价": 16.43,
        "最低价": 16.25,
        "昨收价": 16.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 59886.0,
        "成交额": 976587.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "106.EOT"
    },
    {
        "序号": 8731,
        "name": "BIP Bermuda Holdings I Ltd Note",
        "最新价": 16.24,
        "涨跌额": -0.05,
        "涨跌幅": -0.31,
        "开盘价": 16.25,
        "最高价": 16.32,
        "最低价": 16.09,
        "昨收价": 16.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 23791.0,
        "成交额": 385250.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "106.BIPI"
    },
    {
        "序号": 8732,
        "name": "诶吉利斯",
        "最新价": 84.07,
        "涨跌额": -0.26,
        "涨跌幅": -0.31,
        "开盘价": 84.3,
        "最高价": 85.22,
        "最低价": 83.34,
        "昨收价": 84.33,
        "总市值": 2133131650.0,
        "市盈率": 151.72,
        "成交量": 97387.0,
        "成交额": 8179037.0,
        "振幅": 2.23,
        "换手率": 0.38,
        "symbol": "105.AGYS"
    },
    {
        "序号": 8733,
        "name": "Eaton Vance Tax-Managed Buy-Wri",
        "最新价": 12.93,
        "涨跌额": -0.04,
        "涨跌幅": -0.31,
        "开盘价": 12.96,
        "最高价": 13.0,
        "最低价": 12.91,
        "昨收价": 12.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 64259.0,
        "成交额": 832546.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.ETB"
    },
    {
        "序号": 8734,
        "name": "西南航空",
        "最新价": 29.07,
        "涨跌额": -0.09,
        "涨跌幅": -0.31,
        "开盘价": 28.95,
        "最高价": 29.2,
        "最低价": 28.65,
        "昨收价": 29.16,
        "总市值": 17329074097.0,
        "市盈率": 34.87,
        "成交量": 8752783.0,
        "成交额": 253888682.0,
        "振幅": 1.89,
        "换手率": 1.47,
        "symbol": "106.LUV"
    },
    {
        "序号": 8735,
        "name": "iShares iBonds 2029 Term High Y",
        "最新价": 22.57,
        "涨跌额": -0.07,
        "涨跌幅": -0.31,
        "开盘价": 22.65,
        "最高价": 22.65,
        "最低价": 22.53,
        "昨收价": 22.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 5527.0,
        "成交额": 125036.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.IBHI"
    },
    {
        "序号": 8736,
        "name": "FlexShares Emerging Markets Qua",
        "最新价": 22.5,
        "涨跌额": -0.07,
        "涨跌幅": -0.31,
        "开盘价": 22.47,
        "最高价": 22.52,
        "最低价": 22.47,
        "昨收价": 22.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 5231.0,
        "成交额": 117691.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.QLVE"
    },
    {
        "序号": 8737,
        "name": "Pacer Industrials and Logistics",
        "最新价": 25.7,
        "涨跌额": -0.08,
        "涨跌幅": -0.31,
        "开盘价": 25.7,
        "最高价": 25.7,
        "最低价": 25.7,
        "昨收价": 25.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 102.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SHPP"
    },
    {
        "序号": 8738,
        "name": "泰森食品",
        "最新价": 51.38,
        "涨跌额": -0.16,
        "涨跌幅": -0.31,
        "开盘价": 51.64,
        "最高价": 51.69,
        "最低价": 51.18,
        "昨收价": 51.54,
        "总市值": 18252222414.0,
        "市盈率": -28.17,
        "成交量": 2986122.0,
        "成交额": 153437152.0,
        "振幅": 0.99,
        "换手率": 0.84,
        "symbol": "106.TSN"
    },
    {
        "序号": 8739,
        "name": "MainStay MacKay DefinedTerm Mun",
        "最新价": 16.04,
        "涨跌额": -0.05,
        "涨跌幅": -0.31,
        "开盘价": 16.04,
        "最高价": 16.1,
        "最低价": 15.98,
        "昨收价": 16.09,
        "总市值": 441975207.0,
        "市盈率": null,
        "成交量": 99181.0,
        "成交额": 1592236.0,
        "振幅": 0.75,
        "换手率": 0.36,
        "symbol": "106.MMD"
    },
    {
        "序号": 8740,
        "name": "欧元ETF-CurrencyShares",
        "最新价": 99.34,
        "涨跌额": -0.31,
        "涨跌幅": -0.31,
        "开盘价": 99.15,
        "最高价": 99.52,
        "最低价": 99.14,
        "昨收价": 99.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 42745.0,
        "成交额": 4242167.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.FXE"
    },
    {
        "序号": 8741,
        "name": "W. R. Berkley Corp Debentures",
        "最新价": 19.2,
        "涨跌额": -0.06,
        "涨跌幅": -0.31,
        "开盘价": 19.14,
        "最高价": 19.31,
        "最低价": 18.76,
        "昨收价": 19.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 19345.0,
        "成交额": 370726.0,
        "振幅": 2.86,
        "换手率": null,
        "symbol": "106.WRB_H"
    },
    {
        "序号": 8742,
        "name": "iShares Emerging Markets Divide",
        "最新价": 25.53,
        "涨跌额": -0.08,
        "涨跌幅": -0.31,
        "开盘价": 25.5,
        "最高价": 25.67,
        "最低价": 25.47,
        "昨收价": 25.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 88177.0,
        "成交额": 2252970.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.DVYE"
    },
    {
        "序号": 8743,
        "name": "Amplify BlackSwan Growth & Trea",
        "最新价": 25.52,
        "涨跌额": -0.08,
        "涨跌幅": -0.31,
        "开盘价": 25.54,
        "最高价": 25.6,
        "最低价": 25.45,
        "昨收价": 25.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 9531.0,
        "成交额": 243237.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.SWAN"
    },
    {
        "序号": 8744,
        "name": "NexPoint Real Estate Finance In",
        "最新价": 19.14,
        "涨跌额": -0.06,
        "涨跌幅": -0.31,
        "开盘价": 19.62,
        "最高价": 19.62,
        "最低价": 18.81,
        "昨收价": 19.2,
        "总市值": 31485300.0,
        "市盈率": null,
        "成交量": 868.0,
        "成交额": 16806.0,
        "振幅": 4.22,
        "换手率": 0.05,
        "symbol": "106.NREF_A"
    },
    {
        "序号": 8745,
        "name": "Nuveen S&P 500 Buy-Write Income",
        "最新价": 12.74,
        "涨跌额": -0.04,
        "涨跌幅": -0.31,
        "开盘价": 12.75,
        "最高价": 12.77,
        "最低价": 12.72,
        "昨收价": 12.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 192526.0,
        "成交额": 2454763.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "106.BXMX"
    },
    {
        "序号": 8746,
        "name": "自动数据处理",
        "最新价": 229.27,
        "涨跌额": -0.72,
        "涨跌幅": -0.31,
        "开盘价": 229.19,
        "最高价": 232.59,
        "最低价": 228.27,
        "昨收价": 229.99,
        "总市值": 94299847369.0,
        "市盈率": 27.0,
        "成交量": 1772416.0,
        "成交额": 407048144.0,
        "振幅": 1.88,
        "换手率": 0.43,
        "symbol": "105.ADP"
    },
    {
        "序号": 8747,
        "name": "高股息房地产信托ETF-PowerShares",
        "最新价": 19.06,
        "涨跌额": -0.06,
        "涨跌幅": -0.31,
        "开盘价": 19.07,
        "最高价": 19.14,
        "最低价": 18.9,
        "昨收价": 19.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 59374.0,
        "成交额": 1130385.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "105.KBWY"
    },
    {
        "序号": 8748,
        "name": "英镑ETF-CurrencyShares",
        "最新价": 120.68,
        "涨跌额": -0.38,
        "涨跌幅": -0.31,
        "开盘价": 120.3,
        "最高价": 120.88,
        "最低价": 120.3,
        "昨收价": 121.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 47300.0,
        "成交额": 5703805.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.FXB"
    },
    {
        "序号": 8749,
        "name": "仪化宇辉",
        "最新价": 6.34,
        "涨跌额": -0.02,
        "涨跌幅": -0.31,
        "开盘价": 6.3,
        "最高价": 6.4,
        "最低价": 6.24,
        "昨收价": 6.36,
        "总市值": 114859276.0,
        "市盈率": -2.22,
        "成交量": 66098.0,
        "成交额": 416648.0,
        "振幅": 2.52,
        "换手率": 0.36,
        "symbol": "106.UFI"
    },
    {
        "序号": 8750,
        "name": "Data Storage Corp",
        "最新价": 3.17,
        "涨跌额": -0.01,
        "涨跌幅": -0.31,
        "开盘价": 3.2,
        "最高价": 3.29,
        "最低价": 3.11,
        "昨收价": 3.18,
        "总市值": 21745018.0,
        "市盈率": -8.14,
        "成交量": 38209.0,
        "成交额": 121163.0,
        "振幅": 5.66,
        "换手率": 0.56,
        "symbol": "105.DTST"
    },
    {
        "序号": 8751,
        "name": "Energy Transfer LP Series C Pfd",
        "最新价": 25.33,
        "涨跌额": -0.08,
        "涨跌幅": -0.31,
        "开盘价": 25.42,
        "最高价": 25.42,
        "最低价": 25.27,
        "昨收价": 25.41,
        "总市值": 455940000.0,
        "市盈率": null,
        "成交量": 10470.0,
        "成交额": 264903.0,
        "振幅": 0.59,
        "换手率": 0.06,
        "symbol": "106.ET_C"
    },
    {
        "序号": 8752,
        "name": "实用动力",
        "最新价": 28.48,
        "涨跌额": -0.09,
        "涨跌幅": -0.32,
        "开盘价": 28.47,
        "最高价": 28.74,
        "最低价": 28.33,
        "昨收价": 28.57,
        "总市值": 1547667536.0,
        "市盈率": 33.24,
        "成交量": 216441.0,
        "成交额": 6164503.0,
        "振幅": 1.44,
        "换手率": 0.4,
        "symbol": "106.EPAC"
    },
    {
        "序号": 8753,
        "name": "Regency Centers Corp Series B P",
        "最新价": 22.15,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 22.18,
        "最高价": 22.18,
        "最低价": 22.04,
        "昨收价": 22.22,
        "总市值": 97460000.0,
        "市盈率": null,
        "成交量": 3227.0,
        "成交额": 71401.0,
        "振幅": 0.63,
        "换手率": 0.07,
        "symbol": "105.REGCO"
    },
    {
        "序号": 8754,
        "name": "Saul Centers Inc Series D Pfd",
        "最新价": 22.14,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.89,
        "最高价": 22.38,
        "最低价": 21.7,
        "昨收价": 22.21,
        "总市值": 664200.0,
        "市盈率": null,
        "成交量": 7113.0,
        "成交额": 155219.0,
        "振幅": 3.06,
        "换手率": 23.71,
        "symbol": "106.BFS_D"
    },
    {
        "序号": 8755,
        "name": "Virtus Stone Harbor Emerging Ma",
        "最新价": 25.3,
        "涨跌额": -0.08,
        "涨跌幅": -0.32,
        "开盘价": 25.3,
        "最高价": 25.3,
        "最低价": 25.3,
        "昨收价": 25.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 561.0,
        "成交额": 14193.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.VEMY"
    },
    {
        "序号": 8756,
        "name": "Oxford Square Capital Corp Note",
        "最新价": 22.13,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 22.05,
        "最高价": 22.13,
        "最低价": 22.05,
        "昨收价": 22.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 2000.0,
        "成交额": 44108.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.OXSQG"
    },
    {
        "序号": 8757,
        "name": "WisdomTree U.S. Short-Term Corp",
        "最新价": 47.34,
        "涨跌额": -0.15,
        "涨跌幅": -0.32,
        "开盘价": 47.39,
        "最高价": 47.39,
        "最低价": 47.33,
        "昨收价": 47.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 2119.0,
        "成交额": 100339.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "107.SFIG"
    },
    {
        "序号": 8758,
        "name": "印度ETF-iShares MSCI",
        "最新价": 47.31,
        "涨跌额": -0.15,
        "涨跌幅": -0.32,
        "开盘价": 47.3,
        "最高价": 47.34,
        "最低价": 47.18,
        "昨收价": 47.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 1896371.0,
        "成交额": 89632170.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.INDA"
    },
    {
        "序号": 8759,
        "name": "MFS Government Markets Income T",
        "最新价": 3.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.32,
        "开盘价": 3.14,
        "最高价": 3.16,
        "最低价": 3.13,
        "昨收价": 3.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 60992.0,
        "成交额": 191885.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "106.MGF"
    },
    {
        "序号": 8760,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 22.03,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 22.11,
        "最高价": 22.63,
        "最低价": 22.03,
        "昨收价": 22.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 5602.0,
        "成交额": 124497.0,
        "振幅": 2.71,
        "换手率": null,
        "symbol": "105.RILYM"
    },
    {
        "序号": 8761,
        "name": "Harbor Commodity All-Weather St",
        "最新价": 22.0,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.92,
        "最高价": 22.16,
        "最低价": 21.92,
        "昨收价": 22.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 12666.0,
        "成交额": 279173.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.HGER"
    },
    {
        "序号": 8762,
        "name": "The Charles Schwab Corp Series ",
        "最新价": 18.83,
        "涨跌额": -0.06,
        "涨跌幅": -0.32,
        "开盘价": 18.87,
        "最高价": 18.98,
        "最低价": 18.76,
        "昨收价": 18.89,
        "总市值": 11298000.0,
        "市盈率": null,
        "成交量": 63332.0,
        "成交额": 1193900.0,
        "振幅": 1.16,
        "换手率": 10.56,
        "symbol": "106.SCHW_J"
    },
    {
        "序号": 8763,
        "name": "iShares Core 1-5 Year USD Bond ",
        "最新价": 46.99,
        "涨跌额": -0.15,
        "涨跌幅": -0.32,
        "开盘价": 47.03,
        "最高价": 47.04,
        "最低价": 46.97,
        "昨收价": 47.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 374937.0,
        "成交额": 17619644.0,
        "振幅": 0.15,
        "换手率": null,
        "symbol": "105.ISTB"
    },
    {
        "序号": 8764,
        "name": "JPMorgan Chase & Co Series MM P",
        "最新价": 18.74,
        "涨跌额": -0.06,
        "涨跌幅": -0.32,
        "开盘价": 18.73,
        "最高价": 18.8,
        "最低价": 18.58,
        "昨收价": 18.8,
        "总市值": 3748000.0,
        "市盈率": null,
        "成交量": 190913.0,
        "成交额": 3568374.0,
        "振幅": 1.17,
        "换手率": 95.46,
        "symbol": "106.JPM_M"
    },
    {
        "序号": 8765,
        "name": "Karat Packaging Inc",
        "最新价": 21.86,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.94,
        "最高价": 22.03,
        "最低价": 21.38,
        "昨收价": 21.93,
        "总市值": 436422068.0,
        "市盈率": 13.18,
        "成交量": 54752.0,
        "成交额": 1190389.0,
        "振幅": 2.96,
        "换手率": 0.27,
        "symbol": "105.KRT"
    },
    {
        "序号": 8766,
        "name": "WisdomTree Yield Enhanced U.S. ",
        "最新价": 46.84,
        "涨跌额": -0.15,
        "涨跌幅": -0.32,
        "开盘价": 46.84,
        "最高价": 46.86,
        "最低价": 46.81,
        "昨收价": 46.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 9516.0,
        "成交额": 445826.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.SHAG"
    },
    {
        "序号": 8767,
        "name": "Minim Inc",
        "最新价": 3.12,
        "涨跌额": -0.01,
        "涨跌幅": -0.32,
        "开盘价": 3.04,
        "最高价": 3.2,
        "最低价": 2.92,
        "昨收价": 3.13,
        "总市值": 5923220.0,
        "市盈率": -0.32,
        "成交量": 185350.0,
        "成交额": 566765.0,
        "振幅": 8.95,
        "换手率": 9.76,
        "symbol": "105.MINM"
    },
    {
        "序号": 8768,
        "name": "Discipline Fund ETF",
        "最新价": 21.83,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.78,
        "最高价": 21.83,
        "最低价": 21.78,
        "昨收价": 21.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 26904.0,
        "成交额": 587021.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.DSCF"
    },
    {
        "序号": 8769,
        "name": "Invesco Municipal Opportunity T",
        "最新价": 9.34,
        "涨跌额": -0.03,
        "涨跌幅": -0.32,
        "开盘价": 9.36,
        "最高价": 9.37,
        "最低价": 9.29,
        "昨收价": 9.37,
        "总市值": 629451059.0,
        "市盈率": null,
        "成交量": 217416.0,
        "成交额": 2029503.0,
        "振幅": 0.85,
        "换手率": 0.32,
        "symbol": "106.VMO"
    },
    {
        "序号": 8770,
        "name": "Clearway Energy Inc-C",
        "最新价": 24.88,
        "涨跌额": -0.08,
        "涨跌幅": -0.32,
        "开盘价": 24.87,
        "最高价": 25.08,
        "最低价": 24.67,
        "昨收价": 24.96,
        "总市值": 5027745424.0,
        "市盈率": 93.11,
        "成交量": 1059423.0,
        "成交额": 26367524.0,
        "振幅": 1.64,
        "换手率": 0.63,
        "symbol": "106.CWEN"
    },
    {
        "序号": 8771,
        "name": "Tennessee Valley Authority Seri",
        "最新价": 21.76,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.93,
        "最高价": 21.93,
        "最低价": 21.7,
        "昨收价": 21.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 9375.0,
        "成交额": 204180.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.TVE"
    },
    {
        "序号": 8772,
        "name": "ProShares UltraShort MSCI EAFE",
        "最新价": 9.32,
        "涨跌额": -0.03,
        "涨跌幅": -0.32,
        "开盘价": 9.37,
        "最高价": 9.37,
        "最低价": 9.32,
        "昨收价": 9.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 546.0,
        "成交额": 5108.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.EFU"
    },
    {
        "序号": 8773,
        "name": "Putnam Master Intermediate Inco",
        "最新价": 3.1,
        "涨跌额": -0.01,
        "涨跌幅": -0.32,
        "开盘价": 3.11,
        "最高价": 3.12,
        "最低价": 3.08,
        "昨收价": 3.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 35367.0,
        "成交额": 109775.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "106.PIM"
    },
    {
        "序号": 8774,
        "name": "Data I\/O Corp",
        "最新价": 3.1,
        "涨跌额": -0.01,
        "涨跌幅": -0.32,
        "开盘价": 3.19,
        "最高价": 3.19,
        "最低价": 3.06,
        "昨收价": 3.11,
        "总市值": 27964474.0,
        "市盈率": 32.82,
        "成交量": 6382.0,
        "成交额": 19741.0,
        "振幅": 4.18,
        "换手率": 0.07,
        "symbol": "105.DAIO"
    },
    {
        "序号": 8775,
        "name": "BrightSphere Investment Group I",
        "最新价": 18.59,
        "涨跌额": -0.06,
        "涨跌幅": -0.32,
        "开盘价": 18.69,
        "最高价": 18.74,
        "最低价": 18.35,
        "昨收价": 18.65,
        "总市值": 771636397.0,
        "市盈率": 10.51,
        "成交量": 94353.0,
        "成交额": 1751304.0,
        "振幅": 2.09,
        "换手率": 0.23,
        "symbol": "106.BSIG"
    },
    {
        "序号": 8776,
        "name": "史丹利百得",
        "最新价": 92.85,
        "涨跌额": -0.3,
        "涨跌幅": -0.32,
        "开盘价": 92.27,
        "最高价": 93.73,
        "最低价": 91.91,
        "昨收价": 93.15,
        "总市值": 14234964604.0,
        "市盈率": -278.57,
        "成交量": 1636347.0,
        "成交额": 151881626.0,
        "振幅": 1.95,
        "换手率": 1.07,
        "symbol": "106.SWK"
    },
    {
        "序号": 8777,
        "name": "Global X MSCI China Utilities E",
        "最新价": 12.35,
        "涨跌额": -0.04,
        "涨跌幅": -0.32,
        "开盘价": 12.4,
        "最高价": 12.4,
        "最低价": 12.35,
        "昨收价": 12.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 105.0,
        "成交额": 1302.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.CHIU"
    },
    {
        "序号": 8778,
        "name": "Bank of America Corp Series L P",
        "最新价": 1160.23,
        "涨跌额": -3.77,
        "涨跌幅": -0.32,
        "开盘价": 1163.86,
        "最高价": 1163.86,
        "最低价": 1155.01,
        "昨收价": 1164.0,
        "总市值": 3573719562.0,
        "市盈率": null,
        "成交量": 29810.0,
        "成交额": 34531306.0,
        "振幅": 0.76,
        "换手率": 0.97,
        "symbol": "106.BAC_L"
    },
    {
        "序号": 8779,
        "name": "Entergy Arkansas LLC Bonds 2066",
        "最新价": 21.51,
        "涨跌额": -0.07,
        "涨跌幅": -0.32,
        "开盘价": 21.42,
        "最高价": 21.63,
        "最低价": 21.42,
        "昨收价": 21.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 20011.0,
        "成交额": 430174.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "106.EAI"
    },
    {
        "序号": 8780,
        "name": "XPEL Inc",
        "最新价": 49.13,
        "涨跌额": -0.16,
        "涨跌幅": -0.32,
        "开盘价": 49.31,
        "最高价": 49.97,
        "最低价": 49.06,
        "昨收价": 49.29,
        "总市值": 1357410461.0,
        "市盈率": 27.6,
        "成交量": 108413.0,
        "成交额": 5349754.0,
        "振幅": 1.85,
        "换手率": 0.39,
        "symbol": "105.XPEL"
    },
    {
        "序号": 8781,
        "name": "大豆ETF-Teucrium",
        "最新价": 27.63,
        "涨跌额": -0.09,
        "涨跌幅": -0.32,
        "开盘价": 27.82,
        "最高价": 27.98,
        "最低价": 27.43,
        "昨收价": 27.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 46531.0,
        "成交额": 1285943.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.SOYB"
    },
    {
        "序号": 8782,
        "name": "Genesis Energy LP",
        "最新价": 12.28,
        "涨跌额": -0.04,
        "涨跌幅": -0.32,
        "开盘价": 12.43,
        "最高价": 12.62,
        "最低价": 12.24,
        "昨收价": 12.32,
        "总市值": 1503861825.0,
        "市盈率": 10.18,
        "成交量": 484655.0,
        "成交额": 5982694.0,
        "振幅": 3.08,
        "换手率": 0.4,
        "symbol": "106.GEL"
    },
    {
        "序号": 8783,
        "name": "Perdoceo Education Corp",
        "最新价": 18.39,
        "涨跌额": -0.06,
        "涨跌幅": -0.33,
        "开盘价": 18.43,
        "最高价": 18.63,
        "最低价": 18.25,
        "昨收价": 18.45,
        "总市值": 1208000959.0,
        "市盈率": 8.25,
        "成交量": 427917.0,
        "成交额": 7884288.0,
        "振幅": 2.06,
        "换手率": 0.65,
        "symbol": "105.PRDO"
    },
    {
        "序号": 8784,
        "name": "Pioneer Floating Rate Trust",
        "最新价": 9.18,
        "涨跌额": -0.03,
        "涨跌幅": -0.33,
        "开盘价": 9.22,
        "最高价": 9.27,
        "最低价": 9.17,
        "昨收价": 9.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 58119.0,
        "成交额": 535972.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.PHD"
    },
    {
        "序号": 8785,
        "name": "US Treasury 3 Year Note ETF",
        "最新价": 48.94,
        "涨跌额": -0.16,
        "涨跌幅": -0.33,
        "开盘价": 48.99,
        "最高价": 48.99,
        "最低价": 48.93,
        "昨收价": 49.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1983.0,
        "成交额": 97040.0,
        "振幅": 0.12,
        "换手率": null,
        "symbol": "105.UTRE"
    },
    {
        "序号": 8786,
        "name": "Matthews Pacific Tiger Active E",
        "最新价": 24.44,
        "涨跌额": -0.08,
        "涨跌幅": -0.33,
        "开盘价": 24.45,
        "最高价": 24.48,
        "最低价": 24.44,
        "昨收价": 24.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 2129.0,
        "成交额": 52076.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.ASIA"
    },
    {
        "序号": 8787,
        "name": "亚川",
        "最新价": 6.11,
        "涨跌额": -0.02,
        "涨跌幅": -0.33,
        "开盘价": 6.11,
        "最高价": 6.24,
        "最低价": 6.03,
        "昨收价": 6.13,
        "总市值": 480850884.0,
        "市盈率": -4.73,
        "成交量": 1393955.0,
        "成交额": 8485587.0,
        "振幅": 3.43,
        "换手率": 1.77,
        "symbol": "105.ADTN"
    },
    {
        "序号": 8788,
        "name": "iShares CMBS Bond ETF",
        "最新价": 45.78,
        "涨跌额": -0.15,
        "涨跌幅": -0.33,
        "开盘价": 46.27,
        "最高价": 46.27,
        "最低价": 45.57,
        "昨收价": 45.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 66012.0,
        "成交额": 3023468.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "107.CMBS"
    },
    {
        "序号": 8789,
        "name": "Day Hagan\/Ned Davis Research Sm",
        "最新价": 21.34,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.32,
        "最高价": 21.34,
        "最低价": 21.32,
        "昨收价": 21.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 1316.0,
        "成交额": 28061.0,
        "振幅": 0.09,
        "换手率": null,
        "symbol": "107.SSFI"
    },
    {
        "序号": 8790,
        "name": "John Hancock Preferred Income E",
        "最新价": 21.33,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.35,
        "最高价": 21.35,
        "最低价": 21.31,
        "昨收价": 21.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1240.0,
        "成交额": 26432.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.JHPI"
    },
    {
        "序号": 8791,
        "name": "PIMCO RAFI Dynamic Multi-Factor",
        "最新价": 18.25,
        "涨跌额": -0.06,
        "涨跌幅": -0.33,
        "开盘价": 18.22,
        "最高价": 18.27,
        "最低价": 18.22,
        "昨收价": 18.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 4781.0,
        "成交额": 87196.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.MFEM"
    },
    {
        "序号": 8792,
        "name": "Invesco FTSE RAFI Emerging Mark",
        "最新价": 18.24,
        "涨跌额": -0.06,
        "涨跌幅": -0.33,
        "开盘价": 18.16,
        "最高价": 18.28,
        "最低价": 18.15,
        "昨收价": 18.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 206036.0,
        "成交额": 3751922.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.PXH"
    },
    {
        "序号": 8793,
        "name": "Innovator S&P Investment Grade ",
        "最新价": 18.23,
        "涨跌额": -0.06,
        "涨跌幅": -0.33,
        "开盘价": 18.11,
        "最高价": 18.27,
        "最低价": 18.11,
        "昨收价": 18.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 12046.0,
        "成交额": 218993.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.EPRF"
    },
    {
        "序号": 8794,
        "name": "印度收入指数ETF-WisdomTree",
        "最新价": 39.47,
        "涨跌额": -0.13,
        "涨跌幅": -0.33,
        "开盘价": 39.46,
        "最高价": 39.5,
        "最低价": 39.36,
        "昨收价": 39.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 606099.0,
        "成交额": 23892026.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.EPI"
    },
    {
        "序号": 8795,
        "name": "Algonquin Power & Utilities Cor",
        "最新价": 6.07,
        "涨跌额": -0.02,
        "涨跌幅": -0.33,
        "开盘价": 6.07,
        "最高价": 6.13,
        "最低价": 6.0,
        "昨收价": 6.09,
        "总市值": 4183079976.0,
        "市盈率": -18.03,
        "成交量": 2162829.0,
        "成交额": 13090252.0,
        "振幅": 2.13,
        "换手率": 0.31,
        "symbol": "106.AQN"
    },
    {
        "序号": 8796,
        "name": "Old National Bancorp Series C P",
        "最新价": 24.25,
        "涨跌额": -0.08,
        "涨跌幅": -0.33,
        "开盘价": 24.35,
        "最高价": 24.35,
        "最低价": 24.0,
        "昨收价": 24.33,
        "总市值": 2970625.0,
        "市盈率": null,
        "成交量": 6638.0,
        "成交额": 160346.0,
        "振幅": 1.44,
        "换手率": 5.42,
        "symbol": "105.ONBPP"
    },
    {
        "序号": 8797,
        "name": "Hartford Sustainable Income ETF",
        "最新价": 33.34,
        "涨跌额": -0.11,
        "涨跌幅": -0.33,
        "开盘价": 33.33,
        "最高价": 33.34,
        "最低价": 33.33,
        "昨收价": 33.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 340.0,
        "成交额": 11330.0,
        "振幅": 0.03,
        "换手率": null,
        "symbol": "107.HSUN"
    },
    {
        "序号": 8798,
        "name": "Clarus Corp",
        "最新价": 6.06,
        "涨跌额": -0.02,
        "涨跌幅": -0.33,
        "开盘价": 6.0,
        "最高价": 6.14,
        "最低价": 5.95,
        "昨收价": 6.08,
        "总市值": 231185419.0,
        "市盈率": -2.77,
        "成交量": 184140.0,
        "成交额": 1112438.0,
        "振幅": 3.13,
        "换手率": 0.48,
        "symbol": "105.CLAR"
    },
    {
        "序号": 8799,
        "name": "Virtus Newfleet Short Duration ",
        "最新价": 21.2,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.2,
        "最高价": 21.2,
        "最低价": 21.2,
        "昨收价": 21.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 11.0,
        "成交额": 233.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.VSHY"
    },
    {
        "序号": 8800,
        "name": "The Gabelli Equity Trust Inc Se",
        "最新价": 21.19,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.26,
        "最高价": 21.4,
        "最低价": 21.17,
        "昨收价": 21.26,
        "总市值": 87226813.0,
        "市盈率": null,
        "成交量": 14090.0,
        "成交额": 299529.0,
        "振幅": 1.08,
        "换手率": 0.34,
        "symbol": "106.GAB_H"
    },
    {
        "序号": 8801,
        "name": "福沃运输",
        "最新价": 63.53,
        "涨跌额": -0.21,
        "涨跌幅": -0.33,
        "开盘价": 63.39,
        "最高价": 64.58,
        "最低价": 63.17,
        "昨收价": 63.74,
        "总市值": 1630310862.0,
        "市盈率": 15.02,
        "成交量": 221912.0,
        "成交额": 14132843.0,
        "振幅": 2.21,
        "换手率": 0.86,
        "symbol": "105.FWRD"
    },
    {
        "序号": 8802,
        "name": "First Trust Emerging Markets Al",
        "最新价": 21.14,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.09,
        "最高价": 21.2,
        "最低价": 21.07,
        "昨收价": 21.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 95636.0,
        "成交额": 2021976.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.FEM"
    },
    {
        "序号": 8803,
        "name": "Public Storage Series K Pfd",
        "最新价": 21.12,
        "涨跌额": -0.07,
        "涨跌幅": -0.33,
        "开盘价": 21.19,
        "最高价": 21.19,
        "最低价": 21.0,
        "昨收价": 21.19,
        "总市值": 194304.0,
        "市盈率": null,
        "成交量": 34175.0,
        "成交额": 720700.0,
        "振幅": 0.9,
        "换手率": 371.47,
        "symbol": "106.PSA_K"
    },
    {
        "序号": 8804,
        "name": "爱克斯龙电力",
        "最新价": 39.21,
        "涨跌额": -0.13,
        "涨跌幅": -0.33,
        "开盘价": 39.37,
        "最高价": 39.38,
        "最低价": 38.98,
        "昨收价": 39.34,
        "总市值": 39031101081.0,
        "市盈率": 18.21,
        "成交量": 7433414.0,
        "成交额": 291197824.0,
        "振幅": 1.02,
        "换手率": 0.75,
        "symbol": "105.EXC"
    },
    {
        "序号": 8805,
        "name": "Fairlead Tactical Sector ETF",
        "最新价": 24.1,
        "涨跌额": -0.08,
        "涨跌幅": -0.33,
        "开盘价": 24.01,
        "最高价": 24.14,
        "最低价": 23.97,
        "昨收价": 24.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 57584.0,
        "成交额": 1386923.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.TACK"
    },
    {
        "序号": 8806,
        "name": "Cambria Global Tail Risk ETF",
        "最新价": 18.05,
        "涨跌额": -0.06,
        "涨跌幅": -0.33,
        "开盘价": 18.05,
        "最高价": 18.05,
        "最低价": 18.05,
        "昨收价": 18.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 46.0,
        "成交额": 830.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FAIL"
    },
    {
        "序号": 8807,
        "name": "Angel Oak Financial Strategies ",
        "最新价": 12.03,
        "涨跌额": -0.04,
        "涨跌幅": -0.33,
        "开盘价": 12.08,
        "最高价": 12.09,
        "最低价": 12.01,
        "昨收价": 12.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 21518.0,
        "成交额": 259162.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "106.FINS"
    },
    {
        "序号": 8808,
        "name": "AmeriServ Financial Inc",
        "最新价": 3.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.33,
        "开盘价": 3.01,
        "最高价": 3.03,
        "最低价": 2.93,
        "昨收价": 3.01,
        "总市值": 51441810.0,
        "市盈率": 17.61,
        "成交量": 9965.0,
        "成交额": 29970.0,
        "振幅": 3.32,
        "换手率": 0.06,
        "symbol": "105.ASRV"
    },
    {
        "序号": 8809,
        "name": "Simplify Market Neutral Equity ",
        "最新价": 23.94,
        "涨跌额": -0.08,
        "涨跌幅": -0.33,
        "开盘价": 23.99,
        "最高价": 23.99,
        "最低价": 23.91,
        "昨收价": 24.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 23591.0,
        "成交额": 565165.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.EQLS"
    },
    {
        "序号": 8810,
        "name": "Brookfield Finance Inc Notes",
        "最新价": 14.96,
        "涨跌额": -0.05,
        "涨跌幅": -0.33,
        "开盘价": 15.01,
        "最高价": 15.01,
        "最低价": 14.9,
        "昨收价": 15.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 8469.0,
        "成交额": 126520.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "106.BNJ"
    },
    {
        "序号": 8811,
        "name": "Norwood Financial Corp",
        "最新价": 29.9,
        "涨跌额": -0.1,
        "涨跌幅": -0.33,
        "开盘价": 29.96,
        "最高价": 30.04,
        "最低价": 29.69,
        "昨收价": 30.0,
        "总市值": 241273565.0,
        "市盈率": 10.25,
        "成交量": 7827.0,
        "成交额": 233586.0,
        "振幅": 1.17,
        "换手率": 0.1,
        "symbol": "105.NWFL"
    },
    {
        "序号": 8812,
        "name": "GH Research PLC",
        "最新价": 5.98,
        "涨跌额": -0.02,
        "涨跌幅": -0.33,
        "开盘价": 6.01,
        "最高价": 6.09,
        "最低价": 5.91,
        "昨收价": 6.0,
        "总市值": 311084677.0,
        "市盈率": -7.61,
        "成交量": 17019.0,
        "成交额": 102354.0,
        "振幅": 3.0,
        "换手率": 0.03,
        "symbol": "105.GHRS"
    },
    {
        "序号": 8813,
        "name": "前进保险",
        "最新价": 161.17,
        "涨跌额": -0.54,
        "涨跌幅": -0.33,
        "开盘价": 161.81,
        "最高价": 162.35,
        "最低价": 159.82,
        "昨收价": 161.71,
        "总市值": 94291042981.0,
        "市盈率": 34.4,
        "成交量": 2614634.0,
        "成交额": 420997664.0,
        "振幅": 1.56,
        "换手率": 0.45,
        "symbol": "106.PGR"
    },
    {
        "序号": 8814,
        "name": "中国科技指数ETF-Guggenheim",
        "最新价": 35.79,
        "涨跌额": -0.12,
        "涨跌幅": -0.33,
        "开盘价": 35.84,
        "最高价": 36.01,
        "最低价": 35.67,
        "昨收价": 35.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 37414.0,
        "成交额": 1341558.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.CQQQ"
    },
    {
        "序号": 8815,
        "name": "Global X SuperIncome Preferred ",
        "最新价": 8.93,
        "涨跌额": -0.03,
        "涨跌幅": -0.33,
        "开盘价": 8.96,
        "最高价": 8.96,
        "最低价": 8.89,
        "昨收价": 8.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 100978.0,
        "成交额": 900285.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.SPFF"
    },
    {
        "序号": 8816,
        "name": "高收益垃圾公司债ETF-PowerShares",
        "最新价": 17.79,
        "涨跌额": -0.06,
        "涨跌幅": -0.34,
        "开盘价": 17.8,
        "最高价": 17.81,
        "最低价": 17.75,
        "昨收价": 17.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 155002.0,
        "成交额": 2754800.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.PHB"
    },
    {
        "序号": 8817,
        "name": "JPMorgan BetaBuilders 1-5 Year ",
        "最新价": 47.3,
        "涨跌额": -0.16,
        "涨跌幅": -0.34,
        "开盘价": 47.32,
        "最高价": 47.32,
        "最低价": 47.3,
        "昨收价": 47.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 162.0,
        "成交额": 7665.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.BBSA"
    },
    {
        "序号": 8818,
        "name": "Celcuity LLC",
        "最新价": 14.78,
        "涨跌额": -0.05,
        "涨跌幅": -0.34,
        "开盘价": 15.62,
        "最高价": 15.62,
        "最低价": 14.72,
        "昨收价": 14.83,
        "总市值": 357722646.0,
        "市盈率": -6.33,
        "成交量": 115414.0,
        "成交额": 1727293.0,
        "振幅": 6.07,
        "换手率": 0.48,
        "symbol": "105.CELC"
    },
    {
        "序号": 8819,
        "name": "Milestone Pharmaceuticals Inc",
        "最新价": 2.95,
        "涨跌额": -0.01,
        "涨跌幅": -0.34,
        "开盘价": 2.94,
        "最高价": 3.17,
        "最低价": 2.82,
        "昨收价": 2.96,
        "总市值": 98775177.0,
        "市盈率": -1.67,
        "成交量": 201655.0,
        "成交额": 593113.0,
        "振幅": 11.82,
        "换手率": 0.6,
        "symbol": "105.MIST"
    },
    {
        "序号": 8820,
        "name": "第一美国",
        "最新价": 61.9,
        "涨跌额": -0.21,
        "涨跌幅": -0.34,
        "开盘价": 61.82,
        "最高价": 62.31,
        "最低价": 61.54,
        "昨收价": 62.11,
        "总市值": 6386921108.0,
        "市盈率": 26.95,
        "成交量": 770142.0,
        "成交额": 47723478.0,
        "振幅": 1.24,
        "换手率": 0.75,
        "symbol": "106.FAF"
    },
    {
        "序号": 8821,
        "name": "Invesco BulletShares 2029 High ",
        "最新价": 20.62,
        "涨跌额": -0.07,
        "涨跌幅": -0.34,
        "开盘价": 20.66,
        "最高价": 20.66,
        "最低价": 20.58,
        "昨收价": 20.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 5381.0,
        "成交额": 111025.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "105.BSJT"
    },
    {
        "序号": 8822,
        "name": "Brenmiller Energy Ltd",
        "最新价": 5.89,
        "涨跌额": -0.02,
        "涨跌幅": -0.34,
        "开盘价": 5.93,
        "最高价": 6.28,
        "最低价": 5.88,
        "昨收价": 5.91,
        "总市值": 12670449.0,
        "市盈率": -1.03,
        "成交量": 1553.0,
        "成交额": 9264.0,
        "振幅": 6.77,
        "换手率": 0.07,
        "symbol": "105.BNRG"
    },
    {
        "序号": 8823,
        "name": "布罗德里奇金融",
        "最新价": 191.36,
        "涨跌额": -0.65,
        "涨跌幅": -0.34,
        "开盘价": 192.3,
        "最高价": 192.44,
        "最低价": 189.04,
        "昨收价": 192.01,
        "总市值": 22512937192.0,
        "市盈率": 33.55,
        "成交量": 532357.0,
        "成交额": 101672032.0,
        "振幅": 1.77,
        "换手率": 0.45,
        "symbol": "106.BR"
    },
    {
        "序号": 8824,
        "name": "iShares Asia 50 ETF",
        "最新价": 55.77,
        "涨跌额": -0.19,
        "涨跌幅": -0.34,
        "开盘价": 55.7,
        "最高价": 55.95,
        "最低价": 55.61,
        "昨收价": 55.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 28749.0,
        "成交额": 1602306.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.AIA"
    },
    {
        "序号": 8825,
        "name": "The SPAC and New Issue ETF",
        "最新价": 23.47,
        "涨跌额": -0.08,
        "涨跌幅": -0.34,
        "开盘价": 23.55,
        "最高价": 23.55,
        "最低价": 23.47,
        "昨收价": 23.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 859.0,
        "成交额": 20190.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "105.SPCX"
    },
    {
        "序号": 8826,
        "name": "Capital Group U.S. Multi-Sector",
        "最新价": 26.39,
        "涨跌额": -0.09,
        "涨跌幅": -0.34,
        "开盘价": 26.5,
        "最高价": 26.5,
        "最低价": 26.35,
        "昨收价": 26.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 169252.0,
        "成交额": 4465126.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.CGMS"
    },
    {
        "序号": 8827,
        "name": "SPDR Portfolio Intermediate Ter",
        "最新价": 32.2,
        "涨跌额": -0.11,
        "涨跌幅": -0.34,
        "开盘价": 32.22,
        "最高价": 32.26,
        "最低价": 32.18,
        "昨收价": 32.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 4009786.0,
        "成交额": 129117222.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.SPIB"
    },
    {
        "序号": 8828,
        "name": "PGIM Total Return Bond ETF",
        "最新价": 40.93,
        "涨跌额": -0.14,
        "涨跌幅": -0.34,
        "开盘价": 41.0,
        "最高价": 41.0,
        "最低价": 40.84,
        "昨收价": 41.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 43098.0,
        "成交额": 1763441.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.PTRB"
    },
    {
        "序号": 8829,
        "name": "全球日常消费ETF-iShares",
        "最新价": 58.45,
        "涨跌额": -0.2,
        "涨跌幅": -0.34,
        "开盘价": 58.6,
        "最高价": 58.71,
        "最低价": 58.33,
        "昨收价": 58.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 46219.0,
        "成交额": 2702944.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.KXI"
    },
    {
        "序号": 8830,
        "name": "Postal Realty Trust Inc-A",
        "最新价": 14.61,
        "涨跌额": -0.05,
        "涨跌幅": -0.34,
        "开盘价": 14.62,
        "最高价": 14.67,
        "最低价": 14.51,
        "昨收价": 14.66,
        "总市值": 317646179.0,
        "市盈率": 81.43,
        "成交量": 77385.0,
        "成交额": 1128531.0,
        "振幅": 1.09,
        "换手率": 0.36,
        "symbol": "106.PSTL"
    },
    {
        "序号": 8831,
        "name": "Innovator 20+ Year Treasury Bon",
        "最新价": 20.44,
        "涨跌额": -0.07,
        "涨跌幅": -0.34,
        "开盘价": 20.42,
        "最高价": 20.44,
        "最低价": 20.42,
        "昨收价": 20.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 32452.0,
        "成交额": 662990.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.TFJL"
    },
    {
        "序号": 8832,
        "name": "富乐",
        "最新价": 78.84,
        "涨跌额": -0.27,
        "涨跌幅": -0.34,
        "开盘价": 79.21,
        "最高价": 79.77,
        "最低价": 78.41,
        "昨收价": 79.11,
        "总市值": 4259054902.0,
        "市盈率": 28.73,
        "成交量": 252605.0,
        "成交额": 19917497.0,
        "振幅": 1.72,
        "换手率": 0.47,
        "symbol": "106.FUL"
    },
    {
        "序号": 8833,
        "name": "Atrion Corp",
        "最新价": 325.87,
        "涨跌额": -1.12,
        "涨跌幅": -0.34,
        "开盘价": 328.44,
        "最高价": 328.44,
        "最低价": 322.48,
        "昨收价": 326.99,
        "总市值": 573477757.0,
        "市盈率": 26.91,
        "成交量": 4717.0,
        "成交额": 1535880.0,
        "振幅": 1.82,
        "换手率": 0.27,
        "symbol": "105.ATRI"
    },
    {
        "序号": 8834,
        "name": "科蒂",
        "最新价": 11.62,
        "涨跌额": -0.04,
        "涨跌幅": -0.34,
        "开盘价": 11.62,
        "最高价": 11.79,
        "最低价": 11.59,
        "昨收价": 11.66,
        "总市值": 10361217508.0,
        "市盈率": 27.18,
        "成交量": 3307371.0,
        "成交额": 38535574.0,
        "振幅": 1.72,
        "换手率": 0.37,
        "symbol": "106.COTY"
    },
    {
        "序号": 8835,
        "name": "Inspire Corporate Bond ETF",
        "最新价": 23.21,
        "涨跌额": -0.08,
        "涨跌幅": -0.34,
        "开盘价": 23.17,
        "最高价": 23.29,
        "最低价": 23.14,
        "昨收价": 23.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 29277.0,
        "成交额": 679657.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.IBD"
    },
    {
        "序号": 8836,
        "name": "Public Storage Series Q Pfd",
        "最新价": 17.4,
        "涨跌额": -0.06,
        "涨跌幅": -0.34,
        "开盘价": 17.39,
        "最高价": 17.41,
        "最低价": 17.31,
        "昨收价": 17.46,
        "总市值": 87000.0,
        "市盈率": null,
        "成交量": 19269.0,
        "成交额": 334197.0,
        "振幅": 0.57,
        "换手率": 385.38,
        "symbol": "106.PSA_Q"
    },
    {
        "序号": 8837,
        "name": "新兴市场美元债ETF-iShares",
        "最新价": 86.84,
        "涨跌额": -0.3,
        "涨跌幅": -0.34,
        "开盘价": 86.66,
        "最高价": 86.92,
        "最低价": 86.6,
        "昨收价": 87.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 7276288.0,
        "成交额": 631605936.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.EMB"
    },
    {
        "序号": 8838,
        "name": "Avantis Core Municipal Fixed In",
        "最新价": 46.29,
        "涨跌额": -0.16,
        "涨跌幅": -0.34,
        "开盘价": 46.35,
        "最高价": 46.37,
        "最低价": 46.25,
        "昨收价": 46.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 16066.0,
        "成交额": 744087.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.AVMU"
    },
    {
        "序号": 8839,
        "name": "FlexShares Disciplined Duration",
        "最新价": 20.24,
        "涨跌额": -0.07,
        "涨跌幅": -0.34,
        "开盘价": 20.22,
        "最高价": 20.25,
        "最低价": 20.19,
        "昨收价": 20.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 13148.0,
        "成交额": 265888.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.MBSD"
    },
    {
        "序号": 8840,
        "name": "Global X Variable Rate Preferre",
        "最新价": 23.1,
        "涨跌额": -0.08,
        "涨跌幅": -0.35,
        "开盘价": 23.04,
        "最高价": 23.16,
        "最低价": 23.04,
        "昨收价": 23.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 30179.0,
        "成交额": 697428.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.PFFV"
    },
    {
        "序号": 8841,
        "name": "Nuveen Multi-Market Income Fund",
        "最新价": 5.77,
        "涨跌额": -0.02,
        "涨跌幅": -0.35,
        "开盘价": 5.77,
        "最高价": 5.77,
        "最低价": 5.77,
        "昨收价": 5.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 642.0,
        "成交额": 3704.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.JMM"
    },
    {
        "序号": 8842,
        "name": "泰瑞达",
        "最新价": 92.29,
        "涨跌额": -0.32,
        "涨跌幅": -0.35,
        "开盘价": 92.26,
        "最高价": 93.57,
        "最低价": 91.84,
        "昨收价": 92.61,
        "总市值": 14109177807.0,
        "市盈率": 27.99,
        "成交量": 1688281.0,
        "成交额": 156056848.0,
        "振幅": 1.87,
        "换手率": 1.1,
        "symbol": "105.TER"
    },
    {
        "序号": 8843,
        "name": "Dimensional Emerging Core Equit",
        "最新价": 23.05,
        "涨跌额": -0.08,
        "涨跌幅": -0.35,
        "开盘价": 22.99,
        "最高价": 23.1,
        "最低价": 22.94,
        "昨收价": 23.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 604349.0,
        "成交额": 13911472.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.DFAE"
    },
    {
        "序号": 8844,
        "name": "DWS Municipal Income Trust",
        "最新价": 8.64,
        "涨跌额": -0.03,
        "涨跌幅": -0.35,
        "开盘价": 8.62,
        "最高价": 8.67,
        "最低价": 8.62,
        "昨收价": 8.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 48609.0,
        "成交额": 420696.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "106.KTF"
    },
    {
        "序号": 8845,
        "name": "ClearBridge MLP and Midstream T",
        "最新价": 34.55,
        "涨跌额": -0.12,
        "涨跌幅": -0.35,
        "开盘价": 34.67,
        "最高价": 34.78,
        "最低价": 34.49,
        "昨收价": 34.67,
        "总市值": 1323265000.0,
        "市盈率": null,
        "成交量": 8417.0,
        "成交额": 291169.0,
        "振幅": 0.84,
        "换手率": 0.02,
        "symbol": "106.CTR"
    },
    {
        "序号": 8846,
        "name": "明晟",
        "最新价": 506.68,
        "涨跌额": -1.76,
        "涨跌幅": -0.35,
        "开盘价": 507.04,
        "最高价": 509.07,
        "最低价": 500.01,
        "昨收价": 508.44,
        "总市值": 40073924149.0,
        "市盈率": 41.74,
        "成交量": 348289.0,
        "成交额": 175982805.0,
        "振幅": 1.78,
        "换手率": 0.44,
        "symbol": "106.MSCI"
    },
    {
        "序号": 8847,
        "name": "Public Storage Series O Pfd",
        "最新价": 17.26,
        "涨跌额": -0.06,
        "涨跌幅": -0.35,
        "开盘价": 17.31,
        "最高价": 17.34,
        "最低价": 17.21,
        "昨收价": 17.32,
        "总市值": 117368.0,
        "市盈率": null,
        "成交量": 36873.0,
        "成交额": 637006.0,
        "振幅": 0.75,
        "换手率": 542.25,
        "symbol": "106.PSA_O"
    },
    {
        "序号": 8848,
        "name": "金融优先股ETF-PowerShares",
        "最新价": 14.38,
        "涨跌额": -0.05,
        "涨跌幅": -0.35,
        "开盘价": 14.37,
        "最高价": 14.42,
        "最低价": 14.33,
        "昨收价": 14.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 223847.0,
        "成交额": 3216539.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.PGF"
    },
    {
        "序号": 8849,
        "name": "iShares ESG Advanced MSCI EM ET",
        "最新价": 34.42,
        "涨跌额": -0.12,
        "涨跌幅": -0.35,
        "开盘价": 34.35,
        "最高价": 34.43,
        "最低价": 34.32,
        "昨收价": 34.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 3388.0,
        "成交额": 116500.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "105.EMXF"
    },
    {
        "序号": 8850,
        "name": "Central Puerto SA ADR",
        "最新价": 8.6,
        "涨跌额": -0.03,
        "涨跌幅": -0.35,
        "开盘价": 8.63,
        "最高价": 8.66,
        "最低价": 8.35,
        "昨收价": 8.63,
        "总市值": 1293220304.0,
        "市盈率": -14.44,
        "成交量": 301242.0,
        "成交额": 2560374.0,
        "振幅": 3.59,
        "换手率": 0.2,
        "symbol": "106.CEPU"
    },
    {
        "序号": 8851,
        "name": "MSCI全球林木ETF-Guggenheim",
        "最新价": 31.45,
        "涨跌额": -0.11,
        "涨跌幅": -0.35,
        "开盘价": 31.57,
        "最高价": 31.57,
        "最低价": 31.44,
        "昨收价": 31.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 4117.0,
        "成交额": 129528.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.CUT"
    },
    {
        "序号": 8852,
        "name": "自由全球-C",
        "最新价": 17.08,
        "涨跌额": -0.06,
        "涨跌幅": -0.35,
        "开盘价": 17.14,
        "最高价": 17.17,
        "最低价": 16.71,
        "昨收价": 17.14,
        "总市值": 6750088231.0,
        "市盈率": null,
        "成交量": 1978553.0,
        "成交额": 33659639.0,
        "振幅": 2.68,
        "换手率": 0.94,
        "symbol": "105.LBTYK"
    },
    {
        "序号": 8853,
        "name": "Royce Micro-Cap Trust",
        "最新价": 8.53,
        "涨跌额": -0.03,
        "涨跌幅": -0.35,
        "开盘价": 8.56,
        "最高价": 8.6,
        "最低价": 8.45,
        "昨收价": 8.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 125487.0,
        "成交额": 1068877.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "106.RMT"
    },
    {
        "序号": 8854,
        "name": "Simplify MBS ETF",
        "最新价": 51.13,
        "涨跌额": -0.18,
        "涨跌幅": -0.35,
        "开盘价": 51.22,
        "最高价": 51.22,
        "最低价": 51.01,
        "昨收价": 51.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 129071.0,
        "成交额": 6597663.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.MTBA"
    },
    {
        "序号": 8855,
        "name": "Tower半导体",
        "最新价": 28.4,
        "涨跌额": -0.1,
        "涨跌幅": -0.35,
        "开盘价": 28.5,
        "最高价": 28.6,
        "最低价": 28.01,
        "昨收价": 28.5,
        "总市值": 3127493200.0,
        "市盈率": 5.71,
        "成交量": 719424.0,
        "成交额": 20380692.0,
        "振幅": 2.07,
        "换手率": 0.65,
        "symbol": "105.TSEM"
    },
    {
        "序号": 8856,
        "name": "BNY Mellon Emerging Markets Equ",
        "最新价": 53.81,
        "涨跌额": -0.19,
        "涨跌幅": -0.35,
        "开盘价": 53.56,
        "最高价": 53.81,
        "最低价": 53.56,
        "昨收价": 54.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 815.0,
        "成交额": 43689.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.BKEM"
    },
    {
        "序号": 8857,
        "name": "澳元ETF-CurrencyShares",
        "最新价": 65.13,
        "涨跌额": -0.23,
        "涨跌幅": -0.35,
        "开盘价": 64.97,
        "最高价": 65.15,
        "最低价": 64.97,
        "昨收价": 65.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 15957.0,
        "成交额": 1039359.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.FXA"
    },
    {
        "序号": 8858,
        "name": "Everbridge Inc",
        "最新价": 22.65,
        "涨跌额": -0.08,
        "涨跌幅": -0.35,
        "开盘价": 22.56,
        "最高价": 23.12,
        "最低价": 22.43,
        "昨收价": 22.73,
        "总市值": 930214390.0,
        "市盈率": -78.47,
        "成交量": 315650.0,
        "成交额": 7188616.0,
        "振幅": 3.04,
        "换手率": 0.77,
        "symbol": "105.EVBG"
    },
    {
        "序号": 8859,
        "name": "Belite Bio Inc ADR",
        "最新价": 42.41,
        "涨跌额": -0.15,
        "涨跌幅": -0.35,
        "开盘价": 41.97,
        "最高价": 42.55,
        "最低价": 40.81,
        "昨收价": 42.56,
        "总市值": 1170483938.0,
        "市盈率": -37.25,
        "成交量": 87275.0,
        "成交额": 3641219.0,
        "振幅": 4.09,
        "换手率": 0.32,
        "symbol": "105.BLTE"
    },
    {
        "序号": 8860,
        "name": "PIMCO Active Bond ETF",
        "最新价": 90.4,
        "涨跌额": -0.32,
        "涨跌幅": -0.35,
        "开盘价": 90.27,
        "最高价": 90.4,
        "最低价": 90.17,
        "昨收价": 90.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 586501.0,
        "成交额": 52937017.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "106.BOND"
    },
    {
        "序号": 8861,
        "name": "Mirati Therapeutics Inc",
        "最新价": 56.49,
        "涨跌额": -0.2,
        "涨跌幅": -0.35,
        "开盘价": 56.56,
        "最高价": 56.76,
        "最低价": 56.31,
        "昨收价": 56.69,
        "总市值": 3962643912.0,
        "市盈率": -5.46,
        "成交量": 860800.0,
        "成交额": 48686290.0,
        "振幅": 0.79,
        "换手率": 1.23,
        "symbol": "105.MRTX"
    },
    {
        "序号": 8862,
        "name": "iShares Core 5-10 Year USD Bond",
        "最新价": 42.35,
        "涨跌额": -0.15,
        "涨跌幅": -0.35,
        "开盘价": 42.31,
        "最高价": 42.4,
        "最低价": 42.27,
        "昨收价": 42.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 8058.0,
        "成交额": 341234.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.IMTB"
    },
    {
        "序号": 8863,
        "name": "Brookstone Intermediate Bond ET",
        "最新价": 25.39,
        "涨跌额": -0.09,
        "涨跌幅": -0.35,
        "开盘价": 25.41,
        "最高价": 25.43,
        "最低价": 25.38,
        "昨收价": 25.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 9019.0,
        "成交额": 229123.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.BAMB"
    },
    {
        "序号": 8864,
        "name": "iShares Edge U.S. Fixed Income ",
        "最新价": 84.6,
        "涨跌额": -0.3,
        "涨跌幅": -0.35,
        "开盘价": 84.43,
        "最高价": 84.6,
        "最低价": 84.41,
        "昨收价": 84.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 1110.0,
        "成交额": 93724.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.FIBR"
    },
    {
        "序号": 8865,
        "name": "Anfield Dynamic Fixed Income ET",
        "最新价": 8.45,
        "涨跌额": -0.03,
        "涨跌幅": -0.35,
        "开盘价": 8.44,
        "最高价": 8.45,
        "最低价": 8.43,
        "昨收价": 8.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 15384.0,
        "成交额": 129830.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.ADFI"
    },
    {
        "序号": 8866,
        "name": "SPDR DoubleLine Total Return Ta",
        "最新价": 39.36,
        "涨跌额": -0.14,
        "涨跌幅": -0.35,
        "开盘价": 39.36,
        "最高价": 39.44,
        "最低价": 39.31,
        "昨收价": 39.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 343271.0,
        "成交额": 13516267.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.TOTL"
    },
    {
        "序号": 8867,
        "name": "Verisk分析",
        "最新价": 232.92,
        "涨跌额": -0.83,
        "涨跌幅": -0.36,
        "开盘价": 232.36,
        "最高价": 233.19,
        "最低价": 230.18,
        "昨收价": 233.75,
        "总市值": 33770269089.0,
        "市盈率": 67.31,
        "成交量": 935971.0,
        "成交额": 217496529.0,
        "振幅": 1.29,
        "换手率": 0.65,
        "symbol": "105.VRSK"
    },
    {
        "序号": 8868,
        "name": "卡骆驰",
        "最新价": 103.74,
        "涨跌额": -0.37,
        "涨跌幅": -0.36,
        "开盘价": 103.4,
        "最高价": 105.95,
        "最低价": 103.13,
        "昨收价": 104.11,
        "总市值": 6283181470.0,
        "市盈率": 9.28,
        "成交量": 928222.0,
        "成交额": 96736246.0,
        "振幅": 2.71,
        "换手率": 1.53,
        "symbol": "105.CROX"
    },
    {
        "序号": 8869,
        "name": "Xtrackers MSCI Emerging Markets",
        "最新价": 22.41,
        "涨跌额": -0.08,
        "涨跌幅": -0.36,
        "开盘价": 22.41,
        "最高价": 22.61,
        "最低价": 22.35,
        "昨收价": 22.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 7277.0,
        "成交额": 163080.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "107.DBEM"
    },
    {
        "序号": 8870,
        "name": "Janus Henderson Mortgage-Backed",
        "最新价": 44.79,
        "涨跌额": -0.16,
        "涨跌幅": -0.36,
        "开盘价": 44.88,
        "最高价": 44.88,
        "最低价": 44.61,
        "昨收价": 44.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 354299.0,
        "成交额": 15851990.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.JMBS"
    },
    {
        "序号": 8871,
        "name": "固特异轮胎橡胶",
        "最新价": 13.97,
        "涨跌额": -0.05,
        "涨跌幅": -0.36,
        "开盘价": 14.02,
        "最高价": 14.13,
        "最低价": 13.82,
        "昨收价": 14.02,
        "总市值": 3961171986.0,
        "市盈率": -7.89,
        "成交量": 2352990.0,
        "成交额": 32933851.0,
        "振幅": 2.21,
        "换手率": 0.83,
        "symbol": "105.GT"
    },
    {
        "序号": 8872,
        "name": "Wells Fargo & Co Series Z Pfd",
        "最新价": 19.55,
        "涨跌额": -0.07,
        "涨跌幅": -0.36,
        "开盘价": 19.53,
        "最高价": 19.65,
        "最低价": 19.45,
        "昨收价": 19.62,
        "总市值": 1573775.0,
        "市盈率": null,
        "成交量": 149468.0,
        "成交额": 2923338.0,
        "振幅": 1.02,
        "换手率": 185.67,
        "symbol": "106.WFC_Z"
    },
    {
        "序号": 8873,
        "name": "旅行者保险",
        "最新价": 181.26,
        "涨跌额": -0.65,
        "涨跌幅": -0.36,
        "开盘价": 181.99,
        "最高价": 182.51,
        "最低价": 180.65,
        "昨收价": 181.91,
        "总市值": 41399683582.0,
        "市盈率": 18.96,
        "成交量": 853475.0,
        "成交额": 154754085.0,
        "振幅": 1.02,
        "换手率": 0.37,
        "symbol": "106.TRV"
    },
    {
        "序号": 8874,
        "name": "Cohen & Steers Closed-End Oppor",
        "最新价": 11.11,
        "涨跌额": -0.04,
        "涨跌幅": -0.36,
        "开盘价": 11.18,
        "最高价": 11.19,
        "最低价": 11.07,
        "昨收价": 11.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 30336.0,
        "成交额": 337723.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "106.FOF"
    },
    {
        "序号": 8875,
        "name": "Themes Airlines ETF",
        "最新价": 24.98,
        "涨跌额": -0.09,
        "涨跌幅": -0.36,
        "开盘价": 25.07,
        "最高价": 25.07,
        "最低价": 24.98,
        "昨收价": 25.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1041.0,
        "成交额": 26097.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.AIRL"
    },
    {
        "序号": 8876,
        "name": "electroCore Inc",
        "最新价": 5.55,
        "涨跌额": -0.02,
        "涨跌幅": -0.36,
        "开盘价": 5.56,
        "最高价": 5.56,
        "最低价": 5.27,
        "昨收价": 5.57,
        "总市值": 33313431.0,
        "市盈率": -1.62,
        "成交量": 6409.0,
        "成交额": 34471.0,
        "振幅": 5.21,
        "换手率": 0.11,
        "symbol": "105.ECOR"
    },
    {
        "序号": 8877,
        "name": "ClearShares Piton Intermediate ",
        "最新价": 91.46,
        "涨跌额": -0.33,
        "涨跌幅": -0.36,
        "开盘价": 91.46,
        "最高价": 91.46,
        "最低价": 91.46,
        "昨收价": 91.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 731.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PIFI"
    },
    {
        "序号": 8878,
        "name": "iShares Breakthrough Environmen",
        "最新价": 22.16,
        "涨跌额": -0.08,
        "涨跌幅": -0.36,
        "开盘价": 22.16,
        "最高价": 22.16,
        "最低价": 22.16,
        "昨收价": 22.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 40.0,
        "成交额": 886.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ETEC"
    },
    {
        "序号": 8879,
        "name": "Global Medical REIT Inc Series ",
        "最新价": 24.92,
        "涨跌额": -0.09,
        "涨跌幅": -0.36,
        "开盘价": 24.82,
        "最高价": 25.22,
        "最低价": 24.82,
        "昨收价": 25.01,
        "总市值": 77376600.0,
        "市盈率": null,
        "成交量": 12287.0,
        "成交额": 307093.0,
        "振幅": 1.6,
        "换手率": 0.4,
        "symbol": "106.GMRE_A"
    },
    {
        "序号": 8880,
        "name": "SoFi Weekly Income ETF",
        "最新价": 96.89,
        "涨跌额": -0.35,
        "涨跌幅": -0.36,
        "开盘价": 96.89,
        "最高价": 96.89,
        "最低价": 96.89,
        "昨收价": 97.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 700.0,
        "成交额": 67823.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.TGIF"
    },
    {
        "序号": 8881,
        "name": "美国公共教育",
        "最新价": 8.29,
        "涨跌额": -0.03,
        "涨跌幅": -0.36,
        "开盘价": 8.35,
        "最高价": 8.8,
        "最低价": 8.13,
        "昨收价": 8.32,
        "总市值": 147426168.0,
        "市盈率": -2.21,
        "成交量": 79460.0,
        "成交额": 675822.0,
        "振幅": 8.05,
        "换手率": 0.45,
        "symbol": "105.APEI"
    },
    {
        "序号": 8882,
        "name": "ConnectOne Bancorp Inc Series A",
        "最新价": 19.34,
        "涨跌额": -0.07,
        "涨跌幅": -0.36,
        "开盘价": 19.4,
        "最高价": 19.4,
        "最低价": 19.18,
        "昨收价": 19.41,
        "总市值": 2224100.0,
        "市盈率": null,
        "成交量": 4152.0,
        "成交额": 80155.0,
        "振幅": 1.13,
        "换手率": 3.61,
        "symbol": "105.CNOBP"
    },
    {
        "序号": 8883,
        "name": "Clipper Realty Inc",
        "最新价": 5.52,
        "涨跌额": -0.02,
        "涨跌幅": -0.36,
        "开盘价": 5.55,
        "最高价": 5.58,
        "最低价": 5.38,
        "昨收价": 5.54,
        "总市值": 88669019.0,
        "市盈率": -14.57,
        "成交量": 18177.0,
        "成交额": 99619.0,
        "振幅": 3.61,
        "换手率": 0.11,
        "symbol": "106.CLPR"
    },
    {
        "序号": 8884,
        "name": "SPDR Nuveen Bloomberg High Yiel",
        "最新价": 24.83,
        "涨跌额": -0.09,
        "涨跌幅": -0.36,
        "开盘价": 24.9,
        "最高价": 24.9,
        "最低价": 24.76,
        "昨收价": 24.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1052885.0,
        "成交额": 26161525.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.HYMB"
    },
    {
        "序号": 8885,
        "name": "Public Storage Series H PFd",
        "最新价": 24.77,
        "涨跌额": -0.09,
        "涨跌幅": -0.36,
        "开盘价": 24.76,
        "最高价": 24.89,
        "最低价": 24.59,
        "昨收价": 24.86,
        "总市值": 282378.0,
        "市盈率": null,
        "成交量": 24294.0,
        "成交额": 600432.0,
        "振幅": 1.21,
        "换手率": 213.11,
        "symbol": "106.PSA_H"
    },
    {
        "序号": 8886,
        "name": "Priority Income Fund Inc Series",
        "最新价": 21.96,
        "涨跌额": -0.08,
        "涨跌幅": -0.36,
        "开盘价": 22.08,
        "最高价": 22.08,
        "最低价": 21.77,
        "昨收价": 22.04,
        "总市值": 35136000.0,
        "市盈率": null,
        "成交量": 1577.0,
        "成交额": 34582.0,
        "振幅": 1.41,
        "换手率": 0.1,
        "symbol": "106.PRIF_J"
    },
    {
        "序号": 8887,
        "name": "Innovator Emerging Markets Powe",
        "最新价": 27.44,
        "涨跌额": -0.1,
        "涨跌幅": -0.36,
        "开盘价": 27.46,
        "最高价": 27.53,
        "最低价": 27.41,
        "昨收价": 27.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 45379.0,
        "成交额": 1246196.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.EJAN"
    },
    {
        "序号": 8888,
        "name": "可口可乐装瓶",
        "最新价": 806.26,
        "涨跌额": -2.94,
        "涨跌幅": -0.36,
        "开盘价": 809.9,
        "最高价": 821.96,
        "最低价": 795.6,
        "昨收价": 809.2,
        "总市值": 7557630493.0,
        "市盈率": 16.76,
        "成交量": 46766.0,
        "成交额": 37896902.0,
        "振幅": 3.26,
        "换手率": 0.5,
        "symbol": "105.COKE"
    },
    {
        "序号": 8889,
        "name": "携程",
        "最新价": 32.9,
        "涨跌额": -0.12,
        "涨跌幅": -0.36,
        "开盘价": 33.11,
        "最高价": 33.17,
        "最低价": 32.86,
        "昨收价": 33.02,
        "总市值": 21258325788.0,
        "市盈率": 14.23,
        "成交量": 4244864.0,
        "成交额": 139876854.0,
        "振幅": 0.94,
        "换手率": 0.66,
        "symbol": "105.TCOM"
    },
    {
        "序号": 8890,
        "name": "VictoryShares Short-Term Bond E",
        "最新价": 49.33,
        "涨跌额": -0.18,
        "涨跌幅": -0.36,
        "开盘价": 49.32,
        "最高价": 49.35,
        "最低价": 49.28,
        "昨收价": 49.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 52906.0,
        "成交额": 2608577.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "105.USTB"
    },
    {
        "序号": 8891,
        "name": "Invesco BulletShares 2027 Corpo",
        "最新价": 19.15,
        "涨跌额": -0.07,
        "涨跌幅": -0.36,
        "开盘价": 19.17,
        "最高价": 19.17,
        "最低价": 19.14,
        "昨收价": 19.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 623280.0,
        "成交额": 11940401.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.BSCR"
    },
    {
        "序号": 8892,
        "name": "Bragg Gaming Group Inc",
        "最新价": 5.47,
        "涨跌额": -0.02,
        "涨跌幅": -0.36,
        "开盘价": 5.47,
        "最高价": 5.6,
        "最低价": 5.4,
        "昨收价": 5.49,
        "总市值": 124346824.0,
        "市盈率": -28.6,
        "成交量": 45430.0,
        "成交额": 249422.0,
        "振幅": 3.64,
        "换手率": 0.2,
        "symbol": "105.BRAG"
    },
    {
        "序号": 8893,
        "name": "Argo Group International Holdin",
        "最新价": 24.61,
        "涨跌额": -0.09,
        "涨跌幅": -0.36,
        "开盘价": 24.6,
        "最高价": 24.79,
        "最低价": 24.18,
        "昨收价": 24.7,
        "总市值": 147660.0,
        "市盈率": null,
        "成交量": 23582.0,
        "成交额": 581427.0,
        "振幅": 2.47,
        "换手率": 393.03,
        "symbol": "106.ARGO_A"
    },
    {
        "序号": 8894,
        "name": "Core Alternative ETF",
        "最新价": 27.33,
        "涨跌额": -0.1,
        "涨跌幅": -0.36,
        "开盘价": 27.45,
        "最高价": 27.47,
        "最低价": 27.31,
        "昨收价": 27.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 34982.0,
        "成交额": 957379.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.CCOR"
    },
    {
        "序号": 8895,
        "name": "Amplify BlackSwan Tech & Treasu",
        "最新价": 19.12,
        "涨跌额": -0.07,
        "涨跌幅": -0.36,
        "开盘价": 19.12,
        "最高价": 19.12,
        "最低价": 19.12,
        "昨收价": 19.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 19.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.QSWN"
    },
    {
        "序号": 8896,
        "name": "BayCom Corp",
        "最新价": 21.85,
        "涨跌额": -0.08,
        "涨跌幅": -0.36,
        "开盘价": 21.7,
        "最高价": 21.94,
        "最低价": 21.68,
        "昨收价": 21.93,
        "总市值": 252395271.0,
        "市盈率": 7.91,
        "成交量": 17917.0,
        "成交额": 390084.0,
        "振幅": 1.19,
        "换手率": 0.16,
        "symbol": "105.BCML"
    },
    {
        "序号": 8897,
        "name": "iShares Core MSCI Emerging Mark",
        "最新价": 49.15,
        "涨跌额": -0.18,
        "涨跌幅": -0.36,
        "开盘价": 49.02,
        "最高价": 49.28,
        "最低价": 48.93,
        "昨收价": 49.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 8311135.0,
        "成交额": 408103712.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.IEMG"
    },
    {
        "序号": 8898,
        "name": "Haleon plc ADR",
        "最新价": 8.19,
        "涨跌额": -0.03,
        "涨跌幅": -0.36,
        "开盘价": 8.2,
        "最高价": 8.24,
        "最低价": 8.18,
        "昨收价": 8.22,
        "总市值": 37815579838.0,
        "市盈率": 23.41,
        "成交量": 2964698.0,
        "成交额": 24323780.0,
        "振幅": 0.73,
        "换手率": 0.06,
        "symbol": "106.HLN"
    },
    {
        "序号": 8899,
        "name": "abrdn Asia-Pacific Income Fund ",
        "最新价": 2.73,
        "涨跌额": -0.01,
        "涨跌幅": -0.36,
        "开盘价": 2.74,
        "最高价": 2.76,
        "最低价": 2.73,
        "昨收价": 2.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 404009.0,
        "成交额": 1106395.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.FAX"
    },
    {
        "序号": 8900,
        "name": "做空标普500ETF-ProShares",
        "最新价": 13.64,
        "涨跌额": -0.05,
        "涨跌幅": -0.37,
        "开盘价": 13.72,
        "最高价": 13.73,
        "最低价": 13.62,
        "昨收价": 13.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 19743247.0,
        "成交额": 269921840.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.SH"
    },
    {
        "序号": 8901,
        "name": "AnaptysBio Inc",
        "最新价": 16.36,
        "涨跌额": -0.06,
        "涨跌幅": -0.37,
        "开盘价": 16.41,
        "最高价": 16.87,
        "最低价": 16.11,
        "昨收价": 16.42,
        "总市值": 434769912.0,
        "市盈率": -2.94,
        "成交量": 185456.0,
        "成交额": 3046622.0,
        "振幅": 4.63,
        "换手率": 0.7,
        "symbol": "105.ANAB"
    },
    {
        "序号": 8902,
        "name": "JPMorgan Active Bond ETF",
        "最新价": 51.8,
        "涨跌额": -0.19,
        "涨跌幅": -0.37,
        "开盘价": 51.84,
        "最高价": 51.86,
        "最低价": 51.73,
        "昨收价": 51.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 18781.0,
        "成交额": 972520.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.JBND"
    },
    {
        "序号": 8903,
        "name": "Intercorp Financial Services In",
        "最新价": 19.08,
        "涨跌额": -0.07,
        "涨跌幅": -0.37,
        "开盘价": 19.17,
        "最高价": 19.17,
        "最低价": 18.91,
        "昨收价": 19.15,
        "总市值": 2195974440.0,
        "市盈率": 6.87,
        "成交量": 17747.0,
        "成交额": 338002.0,
        "振幅": 1.36,
        "换手率": 0.02,
        "symbol": "106.IFS"
    },
    {
        "序号": 8904,
        "name": "UMH Properties Inc Series D Pfd",
        "最新价": 21.8,
        "涨跌额": -0.08,
        "涨跌幅": -0.37,
        "开盘价": 21.78,
        "最高价": 21.98,
        "最低价": 21.74,
        "昨收价": 21.88,
        "总市值": 243702200.0,
        "市盈率": null,
        "成交量": 13618.0,
        "成交额": 296828.0,
        "振幅": 1.1,
        "换手率": 0.12,
        "symbol": "106.UMH_D"
    },
    {
        "序号": 8905,
        "name": "直觉外科",
        "最新价": 310.42,
        "涨跌额": -1.14,
        "涨跌幅": -0.37,
        "开盘价": 310.52,
        "最高价": 312.88,
        "最低价": 308.58,
        "昨收价": 311.56,
        "总市值": 109290063589.0,
        "市盈率": 72.06,
        "成交量": 1222954.0,
        "成交额": 379581216.0,
        "振幅": 1.38,
        "换手率": 0.35,
        "symbol": "105.ISRG"
    },
    {
        "序号": 8906,
        "name": "Alerus Financial Corp",
        "最新价": 19.06,
        "涨跌额": -0.07,
        "涨跌幅": -0.37,
        "开盘价": 19.27,
        "最高价": 19.34,
        "最低价": 18.97,
        "昨收价": 19.13,
        "总市值": 376930674.0,
        "市盈率": 10.09,
        "成交量": 26644.0,
        "成交额": 509948.0,
        "振幅": 1.93,
        "换手率": 0.13,
        "symbol": "105.ALRS"
    },
    {
        "序号": 8907,
        "name": "MeridianLink Inc",
        "最新价": 21.75,
        "涨跌额": -0.08,
        "涨跌幅": -0.37,
        "开盘价": 21.78,
        "最高价": 22.0,
        "最低价": 21.62,
        "昨收价": 21.83,
        "总市值": 1717957528.0,
        "市盈率": -93.23,
        "成交量": 178111.0,
        "成交额": 3885901.0,
        "振幅": 1.74,
        "换手率": 0.23,
        "symbol": "106.MLNK"
    },
    {
        "序号": 8908,
        "name": "哈弗蒂家具",
        "最新价": 32.62,
        "涨跌额": -0.12,
        "涨跌幅": -0.37,
        "开盘价": 32.72,
        "最高价": 33.0,
        "最低价": 32.39,
        "昨收价": 32.74,
        "总市值": 530679220.0,
        "市盈率": 8.16,
        "成交量": 56747.0,
        "成交额": 1853010.0,
        "振幅": 1.86,
        "换手率": 0.38,
        "symbol": "106.HVT"
    },
    {
        "序号": 8909,
        "name": "必和必拓(US ADR)",
        "最新价": 62.46,
        "涨跌额": -0.23,
        "涨跌幅": -0.37,
        "开盘价": 62.24,
        "最高价": 62.78,
        "最低价": 62.06,
        "昨收价": 62.69,
        "总市值": 158205575964.0,
        "市盈率": 12.24,
        "成交量": 2537104.0,
        "成交额": 158429286.0,
        "振幅": 1.15,
        "换手率": 0.1,
        "symbol": "106.BHP"
    },
    {
        "序号": 8910,
        "name": "Fortis Inc",
        "最新价": 40.71,
        "涨跌额": -0.15,
        "涨跌幅": -0.37,
        "开盘价": 40.8,
        "最高价": 40.86,
        "最低价": 40.48,
        "昨收价": 40.86,
        "总市值": 19885356372.0,
        "市盈率": 17.06,
        "成交量": 442130.0,
        "成交额": 17964217.0,
        "振幅": 0.93,
        "换手率": 0.09,
        "symbol": "106.FTS"
    },
    {
        "序号": 8911,
        "name": "iShares MSCI Indonesia ETF",
        "最新价": 21.7,
        "涨跌额": -0.08,
        "涨跌幅": -0.37,
        "开盘价": 21.75,
        "最高价": 21.75,
        "最低价": 21.62,
        "昨收价": 21.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 281078.0,
        "成交额": 6094017.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.EIDO"
    },
    {
        "序号": 8912,
        "name": "BondBloxx Bloomberg Three Year ",
        "最新价": 48.81,
        "涨跌额": -0.18,
        "涨跌幅": -0.37,
        "开盘价": 48.8,
        "最高价": 48.81,
        "最低价": 48.8,
        "昨收价": 48.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 314.0,
        "成交额": 15323.0,
        "振幅": 0.02,
        "换手率": null,
        "symbol": "107.XTRE"
    },
    {
        "序号": 8913,
        "name": "Invesco Real Assets ESG ETF",
        "最新价": 13.54,
        "涨跌额": -0.05,
        "涨跌幅": -0.37,
        "开盘价": 13.48,
        "最高价": 13.54,
        "最低价": 13.45,
        "昨收价": 13.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 3179.0,
        "成交额": 42880.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.IVRA"
    },
    {
        "序号": 8914,
        "name": "加拿大贝尔",
        "最新价": 40.61,
        "涨跌额": -0.15,
        "涨跌幅": -0.37,
        "开盘价": 40.76,
        "最高价": 40.85,
        "最低价": 40.55,
        "昨收价": 40.76,
        "总市值": 37047457861.0,
        "市盈率": 20.59,
        "成交量": 1326875.0,
        "成交额": 53933877.0,
        "振幅": 0.74,
        "换手率": 0.15,
        "symbol": "106.BCE"
    },
    {
        "序号": 8915,
        "name": "First Trust Tactical High Yield",
        "最新价": 40.59,
        "涨跌额": -0.15,
        "涨跌幅": -0.37,
        "开盘价": 40.58,
        "最高价": 40.7,
        "最低价": 40.5,
        "昨收价": 40.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 176885.0,
        "成交额": 7181116.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "105.HYLS"
    },
    {
        "序号": 8916,
        "name": "BlackRock High Yield Muni Incom",
        "最新价": 21.64,
        "涨跌额": -0.08,
        "涨跌幅": -0.37,
        "开盘价": 21.71,
        "最高价": 21.73,
        "最低价": 21.55,
        "昨收价": 21.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 69961.0,
        "成交额": 1514592.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.HYMU"
    },
    {
        "序号": 8917,
        "name": "SPDR MSCI Emerging Markets Fuel",
        "最新价": 29.72,
        "涨跌额": -0.11,
        "涨跌幅": -0.37,
        "开盘价": 29.73,
        "最高价": 29.82,
        "最低价": 29.65,
        "昨收价": 29.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 7259.0,
        "成交额": 215732.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "107.EEMX"
    },
    {
        "序号": 8918,
        "name": "First Horizon Corp Series F Pfd",
        "最新价": 16.2,
        "涨跌额": -0.06,
        "涨跌幅": -0.37,
        "开盘价": 16.14,
        "最高价": 16.2,
        "最低价": 16.02,
        "昨收价": 16.26,
        "总市值": 24300.0,
        "市盈率": null,
        "成交量": 7923.0,
        "成交额": 127499.0,
        "振幅": 1.11,
        "换手率": 528.2,
        "symbol": "106.FHN_F"
    },
    {
        "序号": 8919,
        "name": "做空美元指数ETF-PowerShares",
        "最新价": 18.88,
        "涨跌额": -0.07,
        "涨跌幅": -0.37,
        "开盘价": 18.87,
        "最高价": 18.93,
        "最低价": 18.84,
        "昨收价": 18.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 131478.0,
        "成交额": 2482132.0,
        "振幅": 0.47,
        "换手率": null,
        "symbol": "107.UDN"
    },
    {
        "序号": 8920,
        "name": "精密科学",
        "最新价": 64.72,
        "涨跌额": -0.24,
        "涨跌幅": -0.37,
        "开盘价": 63.85,
        "最高价": 65.54,
        "最低价": 63.39,
        "昨收价": 64.96,
        "总市值": 11704608376.0,
        "市盈率": -41.49,
        "成交量": 1264568.0,
        "成交额": 81549699.0,
        "振幅": 3.31,
        "换手率": 0.7,
        "symbol": "105.EXAS"
    },
    {
        "序号": 8921,
        "name": "IDEX Corp",
        "最新价": 204.84,
        "涨跌额": -0.76,
        "涨跌幅": -0.37,
        "开盘价": 205.79,
        "最高价": 207.59,
        "最低价": 204.74,
        "昨收价": 205.6,
        "总市值": 15491135409.0,
        "市盈率": 25.09,
        "成交量": 421852.0,
        "成交额": 86687502.0,
        "振幅": 1.39,
        "换手率": 0.56,
        "symbol": "106.IEX"
    },
    {
        "序号": 8922,
        "name": "Qwest Corp Notes",
        "最新价": 10.75,
        "涨跌额": -0.04,
        "涨跌幅": -0.37,
        "开盘价": 10.8,
        "最高价": 10.82,
        "最低价": 10.65,
        "昨收价": 10.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 61091.0,
        "成交额": 656487.0,
        "振幅": 1.58,
        "换手率": null,
        "symbol": "106.CTDD"
    },
    {
        "序号": 8923,
        "name": "金石保险",
        "最新价": 2.68,
        "涨跌额": -0.01,
        "涨跌幅": -0.37,
        "开盘价": 2.7,
        "最高价": 2.7,
        "最低价": 2.63,
        "昨收价": 2.69,
        "总市值": 28835004.0,
        "市盈率": -2.21,
        "成交量": 21682.0,
        "成交额": 58234.0,
        "振幅": 2.6,
        "换手率": 0.2,
        "symbol": "105.KINS"
    },
    {
        "序号": 8924,
        "name": "伯福德资本",
        "最新价": 13.4,
        "涨跌额": -0.05,
        "涨跌幅": -0.37,
        "开盘价": 13.45,
        "最高价": 13.55,
        "最低价": 13.3,
        "昨收价": 13.45,
        "总市值": 2934096709.0,
        "市盈率": null,
        "成交量": 353832.0,
        "成交额": 4746924.0,
        "振幅": 1.86,
        "换手率": 0.16,
        "symbol": "106.BUR"
    },
    {
        "序号": 8925,
        "name": "欧美佳福莱克斯",
        "最新价": 77.71,
        "涨跌额": -0.29,
        "涨跌幅": -0.37,
        "开盘价": 78.12,
        "最高价": 81.19,
        "最低价": 75.84,
        "昨收价": 78.0,
        "总市值": 784429763.0,
        "市盈率": 35.57,
        "成交量": 13463.0,
        "成交额": 1054734.0,
        "振幅": 6.86,
        "换手率": 0.13,
        "symbol": "105.OFLX"
    },
    {
        "序号": 8926,
        "name": "Capital One Financial Corp Seri",
        "最新价": 16.07,
        "涨跌额": -0.06,
        "涨跌幅": -0.37,
        "开盘价": 16.08,
        "最高价": 16.12,
        "最低价": 15.83,
        "昨收价": 16.13,
        "总市值": 6829750.0,
        "市盈率": null,
        "成交量": 67337.0,
        "成交额": 1081309.0,
        "振幅": 1.8,
        "换手率": 15.84,
        "symbol": "106.COF_N"
    },
    {
        "序号": 8927,
        "name": "Karuna Therapeutics Inc",
        "最新价": 205.48,
        "涨跌额": -0.77,
        "涨跌幅": -0.37,
        "开盘价": 204.04,
        "最高价": 206.18,
        "最低价": 200.0,
        "昨收价": 206.25,
        "总市值": 7752168001.0,
        "市盈率": -19.57,
        "成交量": 556792.0,
        "成交额": 113145678.0,
        "振幅": 3.0,
        "换手率": 1.48,
        "symbol": "105.KRTX"
    },
    {
        "序号": 8928,
        "name": "Telephone and Data Systems Inc ",
        "最新价": 15.99,
        "涨跌额": -0.06,
        "涨跌幅": -0.37,
        "开盘价": 15.88,
        "最高价": 16.18,
        "最低价": 15.79,
        "昨收价": 16.05,
        "总市值": 268632.0,
        "市盈率": null,
        "成交量": 16310.0,
        "成交额": 260700.0,
        "振幅": 2.43,
        "换手率": 97.08,
        "symbol": "106.TDS_U"
    },
    {
        "序号": 8929,
        "name": "SPDR Bloomberg Short Term Inter",
        "最新价": 26.63,
        "涨跌额": -0.1,
        "涨跌幅": -0.37,
        "开盘价": 26.73,
        "最高价": 26.74,
        "最低价": 26.51,
        "昨收价": 26.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 23109.0,
        "成交额": 614292.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.BWZ"
    },
    {
        "序号": 8930,
        "name": "Invesco Investment Grade Defens",
        "最新价": 23.92,
        "涨跌额": -0.09,
        "涨跌幅": -0.37,
        "开盘价": 23.94,
        "最高价": 23.94,
        "最低价": 23.9,
        "昨收价": 24.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 126990.0,
        "成交额": 3038485.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.IIGD"
    },
    {
        "序号": 8931,
        "name": "Federated Premier Municipal Inc",
        "最新价": 10.63,
        "涨跌额": -0.04,
        "涨跌幅": -0.37,
        "开盘价": 10.67,
        "最高价": 10.68,
        "最低价": 10.51,
        "昨收价": 10.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 68627.0,
        "成交额": 729968.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "106.FMN"
    },
    {
        "序号": 8932,
        "name": "Southwest Gas Holdings Inc",
        "最新价": 61.03,
        "涨跌额": -0.23,
        "涨跌幅": -0.38,
        "开盘价": 61.33,
        "最高价": 61.82,
        "最低价": 60.95,
        "昨收价": 61.26,
        "总市值": 4364934625.0,
        "市盈率": -21.55,
        "成交量": 261311.0,
        "成交额": 15974607.0,
        "振幅": 1.42,
        "换手率": 0.37,
        "symbol": "106.SWX"
    },
    {
        "序号": 8933,
        "name": "Goldman Sachs Access Emerging M",
        "最新价": 39.78,
        "涨跌额": -0.15,
        "涨跌幅": -0.38,
        "开盘价": 39.75,
        "最高价": 39.82,
        "最低价": 39.7,
        "昨收价": 39.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 3666.0,
        "成交额": 145816.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.GEMD"
    },
    {
        "序号": 8934,
        "name": "SmartETFs Asia Pacific Dividend",
        "最新价": 13.25,
        "涨跌额": -0.05,
        "涨跌幅": -0.38,
        "开盘价": 13.25,
        "最高价": 13.25,
        "最低价": 13.25,
        "昨收价": 13.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 123.0,
        "成交额": 1629.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ADIV"
    },
    {
        "序号": 8935,
        "name": "Invesco BulletShares 2028 High ",
        "最新价": 21.19,
        "涨跌额": -0.08,
        "涨跌幅": -0.38,
        "开盘价": 21.24,
        "最高价": 21.24,
        "最低价": 21.16,
        "昨收价": 21.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 36144.0,
        "成交额": 766782.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "105.BSJS"
    },
    {
        "序号": 8936,
        "name": "Leonardo DRS Inc",
        "最新价": 18.51,
        "涨跌额": -0.07,
        "涨跌幅": -0.38,
        "开盘价": 18.66,
        "最高价": 18.75,
        "最低价": 18.41,
        "昨收价": 18.58,
        "总市值": 4856468848.0,
        "市盈率": 10.62,
        "成交量": 802347.0,
        "成交额": 14868414.0,
        "振幅": 1.83,
        "换手率": 0.31,
        "symbol": "105.DRS"
    },
    {
        "序号": 8937,
        "name": "Principal Active High Yield ETF",
        "最新价": 18.5,
        "涨跌额": -0.07,
        "涨跌幅": -0.38,
        "开盘价": 18.42,
        "最高价": 18.58,
        "最低价": 18.42,
        "昨收价": 18.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 27634.0,
        "成交额": 511376.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.YLD"
    },
    {
        "序号": 8938,
        "name": "伊克赛尔服务控股",
        "最新价": 29.07,
        "涨跌额": -0.11,
        "涨跌幅": -0.38,
        "开盘价": 29.14,
        "最高价": 29.35,
        "最低价": 28.87,
        "昨收价": 29.18,
        "总市值": 4794304837.0,
        "市盈率": 27.22,
        "成交量": 434826.0,
        "成交额": 12655775.0,
        "振幅": 1.64,
        "换手率": 0.26,
        "symbol": "105.EXLS"
    },
    {
        "序号": 8939,
        "name": "WisdomTree Emerging Markets Mul",
        "最新价": 23.77,
        "涨跌额": -0.09,
        "涨跌幅": -0.38,
        "开盘价": 23.77,
        "最高价": 23.77,
        "最低价": 23.77,
        "昨收价": 23.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 131.0,
        "成交额": 3114.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.EMMF"
    },
    {
        "序号": 8940,
        "name": "National Rural Utilities Cooper",
        "最新价": 23.72,
        "涨跌额": -0.09,
        "涨跌幅": -0.38,
        "开盘价": 23.8,
        "最高价": 23.99,
        "最低价": 23.54,
        "昨收价": 23.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 19286.0,
        "成交额": 457106.0,
        "振幅": 1.89,
        "换手率": null,
        "symbol": "106.NRUC"
    },
    {
        "序号": 8941,
        "name": "瑞思迈",
        "最新价": 162.87,
        "涨跌额": -0.62,
        "涨跌幅": -0.38,
        "开盘价": 163.67,
        "最高价": 163.93,
        "最低价": 162.48,
        "昨收价": 163.49,
        "总市值": 23956859382.0,
        "市盈率": 26.43,
        "成交量": 763229.0,
        "成交额": 124452309.0,
        "振幅": 0.89,
        "换手率": 0.52,
        "symbol": "106.RMD"
    },
    {
        "序号": 8942,
        "name": "Bank of America Corp Series LL ",
        "最新价": 21.0,
        "涨跌额": -0.08,
        "涨跌幅": -0.38,
        "开盘价": 21.04,
        "最高价": 21.09,
        "最低价": 20.85,
        "昨收价": 21.08,
        "总市值": 1100400.0,
        "市盈率": null,
        "成交量": 108973.0,
        "成交额": 2287772.0,
        "振幅": 1.14,
        "换手率": 207.96,
        "symbol": "106.BAC_N"
    },
    {
        "序号": 8943,
        "name": "Casella Waste Systems Inc-A",
        "最新价": 83.97,
        "涨跌额": -0.32,
        "涨跌幅": -0.38,
        "开盘价": 84.12,
        "最高价": 84.87,
        "最低价": 83.27,
        "昨收价": 84.29,
        "总市值": 4868809334.0,
        "市盈率": 136.65,
        "成交量": 266887.0,
        "成交额": 22387700.0,
        "振幅": 1.9,
        "换手率": 0.46,
        "symbol": "105.CWST"
    },
    {
        "序号": 8944,
        "name": "Blackstone Senior Floating Rate",
        "最新价": 13.11,
        "涨跌额": -0.05,
        "涨跌幅": -0.38,
        "开盘价": 13.11,
        "最高价": 13.21,
        "最低价": 13.11,
        "昨收价": 13.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 30042.0,
        "成交额": 395143.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "106.BSL"
    },
    {
        "序号": 8945,
        "name": "FlexShares ESG & Climate Emergi",
        "最新价": 44.55,
        "涨跌额": -0.17,
        "涨跌幅": -0.38,
        "开盘价": 44.55,
        "最高价": 44.55,
        "最低价": 44.55,
        "昨收价": 44.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 356.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FEEM"
    },
    {
        "序号": 8946,
        "name": "FlexShares Morningstar Emerging",
        "最新价": 49.78,
        "涨跌额": -0.19,
        "涨跌幅": -0.38,
        "开盘价": 49.9,
        "最高价": 50.02,
        "最低价": 49.76,
        "昨收价": 49.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 228385.0,
        "成交额": 11391045.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.TLTE"
    },
    {
        "序号": 8947,
        "name": "iShares iBonds Dec 2027 Term Co",
        "最新价": 23.57,
        "涨跌额": -0.09,
        "涨跌幅": -0.38,
        "开盘价": 23.58,
        "最高价": 23.62,
        "最低价": 23.54,
        "昨收价": 23.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 459960.0,
        "成交额": 10838476.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.IBDS"
    },
    {
        "序号": 8948,
        "name": "PIMCO New York Municipal Income",
        "最新价": 7.85,
        "涨跌额": -0.03,
        "涨跌幅": -0.38,
        "开盘价": 7.83,
        "最高价": 7.86,
        "最低价": 7.82,
        "昨收价": 7.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 14329.0,
        "成交额": 112410.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "106.PNF"
    },
    {
        "序号": 8949,
        "name": "BlackRock Municipal Income Trus",
        "最新价": 10.46,
        "涨跌额": -0.04,
        "涨跌幅": -0.38,
        "开盘价": 10.48,
        "最高价": 10.54,
        "最低价": 10.45,
        "昨收价": 10.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 104839.0,
        "成交额": 1099399.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "106.BLE"
    },
    {
        "序号": 8950,
        "name": "Antero Resources Corp",
        "最新价": 20.92,
        "涨跌额": -0.08,
        "涨跌幅": -0.38,
        "开盘价": 21.1,
        "最高价": 21.18,
        "最低价": 20.7,
        "昨收价": 21.0,
        "总市值": 6287380480.0,
        "市盈率": 7.16,
        "成交量": 5919756.0,
        "成交额": 123780895.0,
        "振幅": 2.29,
        "换手率": 1.97,
        "symbol": "106.AR"
    },
    {
        "序号": 8951,
        "name": "Global X MSCI Next Emerging & F",
        "最新价": 18.29,
        "涨跌额": -0.07,
        "涨跌幅": -0.38,
        "开盘价": 18.24,
        "最高价": 18.3,
        "最低价": 18.24,
        "昨收价": 18.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 1102.0,
        "成交额": 20141.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.EMFM"
    },
    {
        "序号": 8952,
        "name": "iShares Agency Bond ETF",
        "最新价": 107.08,
        "涨跌额": -0.41,
        "涨跌幅": -0.38,
        "开盘价": 107.14,
        "最高价": 107.26,
        "最低价": 107.05,
        "昨收价": 107.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 21991.0,
        "成交额": 2355149.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.AGZ"
    },
    {
        "序号": 8953,
        "name": "Fidelity Investment Grade Secur",
        "最新价": 41.78,
        "涨跌额": -0.16,
        "涨跌幅": -0.38,
        "开盘价": 41.93,
        "最高价": 42.15,
        "最低价": 41.71,
        "昨收价": 41.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 10280.0,
        "成交额": 430400.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.FSEC"
    },
    {
        "序号": 8954,
        "name": "The Aaron’s Co Inc",
        "最新价": 10.42,
        "涨跌额": -0.04,
        "涨跌幅": -0.38,
        "开盘价": 10.42,
        "最高价": 10.59,
        "最低价": 10.21,
        "昨收价": 10.46,
        "总市值": 316031171.0,
        "市盈率": 33.89,
        "成交量": 481445.0,
        "成交额": 4998578.0,
        "振幅": 3.63,
        "换手率": 1.59,
        "symbol": "106.AAN"
    },
    {
        "序号": 8955,
        "name": "Dimensional Global Credit ETF",
        "最新价": 52.06,
        "涨跌额": -0.2,
        "涨跌幅": -0.38,
        "开盘价": 52.1,
        "最高价": 52.1,
        "最低价": 52.03,
        "昨收价": 52.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 37351.0,
        "成交额": 1944788.0,
        "振幅": 0.13,
        "换手率": null,
        "symbol": "105.DGCB"
    },
    {
        "序号": 8956,
        "name": "枢纽集团",
        "最新价": 80.62,
        "涨跌额": -0.31,
        "涨跌幅": -0.38,
        "开盘价": 80.79,
        "最高价": 81.75,
        "最低价": 79.91,
        "昨收价": 80.93,
        "总市值": 2561045221.0,
        "市盈率": 11.75,
        "成交量": 158171.0,
        "成交额": 12767034.0,
        "振幅": 2.27,
        "换手率": 0.5,
        "symbol": "105.HUBG"
    },
    {
        "序号": 8957,
        "name": "美国航空运输服务",
        "最新价": 15.6,
        "涨跌额": -0.06,
        "涨跌幅": -0.38,
        "开盘价": 15.64,
        "最高价": 15.74,
        "最低价": 15.29,
        "昨收价": 15.66,
        "总市值": 1018257240.0,
        "市盈率": 8.63,
        "成交量": 1410586.0,
        "成交额": 21959095.0,
        "振幅": 2.87,
        "换手率": 2.16,
        "symbol": "105.ATSG"
    },
    {
        "序号": 8958,
        "name": "ARK Genomic Revolution ETF",
        "最新价": 28.6,
        "涨跌额": -0.11,
        "涨跌幅": -0.38,
        "开盘价": 28.52,
        "最高价": 29.31,
        "最低价": 28.04,
        "昨收价": 28.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 3310033.0,
        "成交额": 94778011.0,
        "振幅": 4.42,
        "换手率": null,
        "symbol": "107.ARKG"
    },
    {
        "序号": 8959,
        "name": "力拓(US)",
        "最新价": 70.17,
        "涨跌额": -0.27,
        "涨跌幅": -0.38,
        "开盘价": 69.84,
        "最高价": 70.61,
        "最低价": 69.84,
        "昨收价": 70.44,
        "总市值": 88125940938.0,
        "市盈率": 10.25,
        "成交量": 3047530.0,
        "成交额": 214034857.0,
        "振幅": 1.09,
        "换手率": 0.24,
        "symbol": "106.RIO"
    },
    {
        "序号": 8960,
        "name": "脑再生科技",
        "最新价": 10.39,
        "涨跌额": -0.04,
        "涨跌幅": -0.38,
        "开盘价": 10.13,
        "最高价": 10.39,
        "最低价": 10.13,
        "昨收价": 10.43,
        "总市值": 135203678.0,
        "市盈率": -23.03,
        "成交量": 383.0,
        "成交额": 3928.0,
        "振幅": 2.49,
        "换手率": 0.0,
        "symbol": "105.RGC"
    },
    {
        "序号": 8961,
        "name": "Insight Select Income Fund",
        "最新价": 15.58,
        "涨跌额": -0.06,
        "涨跌幅": -0.38,
        "开盘价": 15.57,
        "最高价": 15.6,
        "最低价": 15.51,
        "昨收价": 15.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 9614.0,
        "成交额": 149633.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "106.INSI"
    },
    {
        "序号": 8962,
        "name": "Eargo Inc",
        "最新价": 2.59,
        "涨跌额": -0.01,
        "涨跌幅": -0.38,
        "开盘价": 2.6,
        "最高价": 2.63,
        "最低价": 2.58,
        "昨收价": 2.6,
        "总市值": 53774588.0,
        "市盈率": -0.5,
        "成交量": 19254.0,
        "成交额": 50096.0,
        "振幅": 1.92,
        "换手率": 0.09,
        "symbol": "105.EAR"
    },
    {
        "序号": 8963,
        "name": "PGIM ACTIVE AGGREGATE BOND ETF",
        "最新价": 41.39,
        "涨跌额": -0.16,
        "涨跌幅": -0.39,
        "开盘价": 41.39,
        "最高价": 41.39,
        "最低价": 41.39,
        "昨收价": 41.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 165.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PAB"
    },
    {
        "序号": 8964,
        "name": "马尼托沃克",
        "最新价": 15.52,
        "涨跌额": -0.06,
        "涨跌幅": -0.39,
        "开盘价": 15.53,
        "最高价": 15.8,
        "最低价": 15.44,
        "昨收价": 15.58,
        "总市值": 544519666.0,
        "市盈率": -5.61,
        "成交量": 308470.0,
        "成交额": 4806045.0,
        "振幅": 2.31,
        "换手率": 0.88,
        "symbol": "106.MTW"
    },
    {
        "序号": 8965,
        "name": "Capital Group Core Bond ETF",
        "最新价": 25.83,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.81,
        "最高价": 25.95,
        "最低价": 25.76,
        "昨收价": 25.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 156861.0,
        "成交额": 4054797.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.CGCB"
    },
    {
        "序号": 8966,
        "name": "新兴市场当地国债ETF-iShares",
        "最新价": 36.12,
        "涨跌额": -0.14,
        "涨跌幅": -0.39,
        "开盘价": 36.11,
        "最高价": 36.24,
        "最低价": 36.09,
        "昨收价": 36.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 50986.0,
        "成交额": 1844155.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.LEMB"
    },
    {
        "序号": 8967,
        "name": "亚洲除日本ETF-iShares MSCI",
        "最新价": 64.39,
        "涨跌额": -0.25,
        "涨跌幅": -0.39,
        "开盘价": 64.27,
        "最高价": 64.55,
        "最低价": 64.13,
        "昨收价": 64.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 796891.0,
        "成交额": 51299772.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "105.AAXJ"
    },
    {
        "序号": 8968,
        "name": "美国西屋制动",
        "最新价": 118.4,
        "涨跌额": -0.46,
        "涨跌幅": -0.39,
        "开盘价": 118.74,
        "最高价": 119.75,
        "最低价": 117.88,
        "昨收价": 118.86,
        "总市值": 21212444544.0,
        "市盈率": 27.98,
        "成交量": 740796.0,
        "成交额": 87847895.0,
        "振幅": 1.57,
        "换手率": 0.41,
        "symbol": "106.WAB"
    },
    {
        "序号": 8969,
        "name": "IQ MacKay ESG Core Plus Bond ET",
        "最新价": 20.58,
        "涨跌额": -0.08,
        "涨跌幅": -0.39,
        "开盘价": 20.6,
        "最高价": 20.6,
        "最低价": 20.58,
        "昨收价": 20.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1966.0,
        "成交额": 40471.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.ESGB"
    },
    {
        "序号": 8970,
        "name": "Nuveen Taxable Municipal Income",
        "最新价": 15.4,
        "涨跌额": -0.06,
        "涨跌幅": -0.39,
        "开盘价": 15.33,
        "最高价": 15.53,
        "最低价": 15.33,
        "昨收价": 15.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 93809.0,
        "成交额": 1445191.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "106.NBB"
    },
    {
        "序号": 8971,
        "name": "纳伯斯工业",
        "最新价": 79.55,
        "涨跌额": -0.31,
        "涨跌幅": -0.39,
        "开盘价": 80.66,
        "最高价": 82.27,
        "最低价": 79.15,
        "昨收价": 79.86,
        "总市值": 752949182.0,
        "市盈率": -11.74,
        "成交量": 302856.0,
        "成交额": 24227783.0,
        "振幅": 3.91,
        "换手率": 3.2,
        "symbol": "106.NBR"
    },
    {
        "序号": 8972,
        "name": "Dillard's Inc Pfd of Dillard's ",
        "最新价": 25.65,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.75,
        "最高价": 25.75,
        "最低价": 25.58,
        "昨收价": 25.75,
        "总市值": 205200000.0,
        "市盈率": null,
        "成交量": 7239.0,
        "成交额": 185878.0,
        "振幅": 0.66,
        "换手率": 0.09,
        "symbol": "106.DDT"
    },
    {
        "序号": 8973,
        "name": "布鲁克菲尔德资产管理",
        "最新价": 35.9,
        "涨跌额": -0.14,
        "涨跌幅": -0.39,
        "开盘价": 36.0,
        "最高价": 36.3,
        "最低价": 35.66,
        "昨收价": 36.04,
        "总市值": 14003325135.0,
        "市盈率": 16.28,
        "成交量": 1305127.0,
        "成交额": 46892003.0,
        "振幅": 1.78,
        "换手率": 0.33,
        "symbol": "106.BAM"
    },
    {
        "序号": 8974,
        "name": "GGM Macro Alignment ETF",
        "最新价": 25.64,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.64,
        "最高价": 25.64,
        "最低价": 25.64,
        "昨收价": 25.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 25.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GGM"
    },
    {
        "序号": 8975,
        "name": "Macquarie Global Listed Infrast",
        "最新价": 25.56,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.56,
        "最高价": 25.56,
        "最低价": 25.56,
        "昨收价": 25.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 25.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BILD"
    },
    {
        "序号": 8976,
        "name": "Principal Investment Grade Corp",
        "最新价": 20.44,
        "涨跌额": -0.08,
        "涨跌幅": -0.39,
        "开盘价": 20.47,
        "最高价": 20.47,
        "最低价": 20.42,
        "昨收价": 20.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 21926.0,
        "成交额": 448399.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.IG"
    },
    {
        "序号": 8977,
        "name": "Emerald Holding Inc",
        "最新价": 5.11,
        "涨跌额": -0.02,
        "涨跌幅": -0.39,
        "开盘价": 5.1,
        "最高价": 5.2,
        "最低价": 5.05,
        "昨收价": 5.13,
        "总市值": 321380282.0,
        "市盈率": 10.01,
        "成交量": 142408.0,
        "成交额": 727735.0,
        "振幅": 2.92,
        "换手率": 0.23,
        "symbol": "106.EEX"
    },
    {
        "序号": 8978,
        "name": "Metropolitan Bank Holding Corp",
        "最新价": 45.96,
        "涨跌额": -0.18,
        "涨跌幅": -0.39,
        "开盘价": 46.5,
        "最高价": 47.0,
        "最低价": 45.62,
        "昨收价": 46.14,
        "总市值": 508443025.0,
        "市盈率": 9.25,
        "成交量": 81513.0,
        "成交额": 3759205.0,
        "振幅": 2.99,
        "换手率": 0.74,
        "symbol": "106.MCB"
    },
    {
        "序号": 8979,
        "name": "Thunder Bridge Capital Partners",
        "最新价": 10.21,
        "涨跌额": -0.04,
        "涨跌幅": -0.39,
        "开盘价": 10.21,
        "最高价": 10.22,
        "最低价": 10.17,
        "昨收价": 10.25,
        "总市值": 122154043.0,
        "市盈率": 96.9,
        "成交量": 10439.0,
        "成交额": 106524.0,
        "振幅": 0.49,
        "换手率": 0.09,
        "symbol": "105.TBCP"
    },
    {
        "序号": 8980,
        "name": "Aztlan North America Nearshorin",
        "最新价": 20.39,
        "涨跌额": -0.08,
        "涨跌幅": -0.39,
        "开盘价": 20.5,
        "最高价": 20.5,
        "最低价": 20.39,
        "昨收价": 20.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 4367.0,
        "成交额": 89147.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.NRSH"
    },
    {
        "序号": 8981,
        "name": "WisdomTree Voya Yield Enhanced ",
        "最新价": 48.41,
        "涨跌额": -0.19,
        "涨跌幅": -0.39,
        "开盘价": 48.41,
        "最高价": 48.41,
        "最低价": 48.41,
        "昨收价": 48.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 7.0,
        "成交额": 338.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.UNIY"
    },
    {
        "序号": 8982,
        "name": "LTC Properties Inc",
        "最新价": 33.11,
        "涨跌额": -0.13,
        "涨跌幅": -0.39,
        "开盘价": 33.13,
        "最高价": 33.37,
        "最低价": 33.06,
        "昨收价": 33.24,
        "总市值": 1371141155.0,
        "市盈率": 17.26,
        "成交量": 188667.0,
        "成交额": 6248859.0,
        "振幅": 0.93,
        "换手率": 0.46,
        "symbol": "106.LTC"
    },
    {
        "序号": 8983,
        "name": "Regions Financial Corp Series C",
        "最新价": 20.37,
        "涨跌额": -0.08,
        "涨跌幅": -0.39,
        "开盘价": 20.39,
        "最高价": 20.59,
        "最低价": 20.35,
        "昨收价": 20.45,
        "总市值": 10185000.0,
        "市盈率": null,
        "成交量": 51370.0,
        "成交额": 1050351.0,
        "振幅": 1.17,
        "换手率": 10.27,
        "symbol": "106.RF_C"
    },
    {
        "序号": 8984,
        "name": "Kingsbarn Tactical Bond ETF",
        "最新价": 22.9,
        "涨跌额": -0.09,
        "涨跌幅": -0.39,
        "开盘价": 22.9,
        "最高价": 22.9,
        "最低价": 22.9,
        "昨收价": 22.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 137.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KDRN"
    },
    {
        "序号": 8985,
        "name": "Franklin U.S. Low Volatility Hi",
        "最新价": 35.59,
        "涨跌额": -0.14,
        "涨跌幅": -0.39,
        "开盘价": 35.72,
        "最高价": 35.73,
        "最低价": 35.49,
        "昨收价": 35.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 133576.0,
        "成交额": 4756472.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "105.LVHD"
    },
    {
        "序号": 8986,
        "name": "Simplify Multi-QIS Alternative ",
        "最新价": 25.41,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.6,
        "最高价": 25.63,
        "最低价": 25.41,
        "昨收价": 25.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 11009.0,
        "成交额": 280934.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.QIS"
    },
    {
        "序号": 8987,
        "name": "Putnam ESG Core Bond ETF",
        "最新价": 48.27,
        "涨跌额": -0.19,
        "涨跌幅": -0.39,
        "开盘价": 48.25,
        "最高价": 48.27,
        "最低价": 48.25,
        "昨收价": 48.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 268.0,
        "成交额": 12931.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.PCRB"
    },
    {
        "序号": 8988,
        "name": "本田汽车",
        "最新价": 30.48,
        "涨跌额": -0.12,
        "涨跌幅": -0.39,
        "开盘价": 30.33,
        "最高价": 30.61,
        "最低价": 30.26,
        "昨收价": 30.6,
        "总市值": 50729101153.0,
        "市盈率": 7.21,
        "成交量": 1065797.0,
        "成交额": 32487276.0,
        "振幅": 1.14,
        "换手率": 0.06,
        "symbol": "106.HMC"
    },
    {
        "序号": 8989,
        "name": "恋上餐厅",
        "最新价": 2.54,
        "涨跌额": -0.01,
        "涨跌幅": -0.39,
        "开盘价": 2.5,
        "最高价": 2.62,
        "最低价": 2.5,
        "昨收价": 2.55,
        "总市值": 29362900.0,
        "市盈率": 2.92,
        "成交量": 16525.0,
        "成交额": 42168.0,
        "振幅": 4.71,
        "换手率": 0.14,
        "symbol": "105.GTIM"
    },
    {
        "序号": 8990,
        "name": "Western Asset Intermediate Muni",
        "最新价": 7.61,
        "涨跌额": -0.03,
        "涨跌幅": -0.39,
        "开盘价": 7.61,
        "最高价": 7.64,
        "最低价": 7.57,
        "昨收价": 7.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 10766.0,
        "成交额": 81911.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "106.SBI"
    },
    {
        "序号": 8991,
        "name": "Nuveen Enhanced Yield 1-5 Year ",
        "最新价": 22.81,
        "涨跌额": -0.09,
        "涨跌幅": -0.39,
        "开盘价": 22.82,
        "最高价": 22.82,
        "最低价": 22.81,
        "昨收价": 22.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 724.0,
        "成交额": 16518.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.NUSA"
    },
    {
        "序号": 8992,
        "name": "Energy Transfer LP Series D Pfd",
        "最新价": 25.34,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.44,
        "最高价": 25.44,
        "最低价": 25.3,
        "昨收价": 25.44,
        "总市值": 451052000.0,
        "市盈率": null,
        "成交量": 20117.0,
        "成交额": 510176.0,
        "振幅": 0.55,
        "换手率": 0.11,
        "symbol": "106.ET_D"
    },
    {
        "序号": 8993,
        "name": "American Century Diversified Co",
        "最新价": 45.57,
        "涨跌额": -0.18,
        "涨跌幅": -0.39,
        "开盘价": 45.56,
        "最高价": 45.62,
        "最低价": 45.54,
        "昨收价": 45.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 33301.0,
        "成交额": 1517851.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.KORP"
    },
    {
        "序号": 8994,
        "name": "Easterly Government Properties ",
        "最新价": 12.65,
        "涨跌额": -0.05,
        "涨跌幅": -0.39,
        "开盘价": 12.62,
        "最高价": 12.66,
        "最低价": 12.48,
        "昨收价": 12.7,
        "总市值": 1204291739.0,
        "市盈率": 39.28,
        "成交量": 747568.0,
        "成交额": 9423345.0,
        "振幅": 1.42,
        "换手率": 0.79,
        "symbol": "106.DEA"
    },
    {
        "序号": 8995,
        "name": "Harbor Scientific Alpha Income ",
        "最新价": 42.97,
        "涨跌额": -0.17,
        "涨跌幅": -0.39,
        "开盘价": 42.97,
        "最高价": 42.97,
        "最低价": 42.97,
        "昨收价": 43.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 85.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SIFI"
    },
    {
        "序号": 8996,
        "name": "Sabine Royalty Trust",
        "最新价": 70.77,
        "涨跌额": -0.28,
        "涨跌幅": -0.39,
        "开盘价": 71.01,
        "最高价": 72.5,
        "最低价": 69.73,
        "昨收价": 71.05,
        "总市值": 1031780246.0,
        "市盈率": 8.94,
        "成交量": 50138.0,
        "成交额": 3567874.0,
        "振幅": 3.9,
        "换手率": 0.34,
        "symbol": "106.SBR"
    },
    {
        "序号": 8997,
        "name": "Prudential Financial Inc Notes",
        "最新价": 25.25,
        "涨跌额": -0.1,
        "涨跌幅": -0.39,
        "开盘价": 25.35,
        "最高价": 25.4,
        "最低价": 25.17,
        "昨收价": 25.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 21571.0,
        "成交额": 545360.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "106.PRH"
    },
    {
        "序号": 8998,
        "name": "Goldman Sachs MarketBeta Emergi",
        "最新价": 40.4,
        "涨跌额": -0.16,
        "涨跌幅": -0.39,
        "开盘价": 40.4,
        "最高价": 40.4,
        "最低价": 40.4,
        "昨收价": 40.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 323.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GSEE"
    },
    {
        "序号": 8999,
        "name": "McEwen Mining Inc",
        "最新价": 7.57,
        "涨跌额": -0.03,
        "涨跌幅": -0.39,
        "开盘价": 7.43,
        "最高价": 7.71,
        "最低价": 7.37,
        "昨收价": 7.6,
        "总市值": 359513448.0,
        "市盈率": -2.98,
        "成交量": 540058.0,
        "成交额": 4068507.0,
        "振幅": 4.47,
        "换手率": 1.14,
        "symbol": "106.MUX"
    },
    {
        "序号": 9000,
        "name": "和利时自动化",
        "最新价": 22.7,
        "涨跌额": -0.09,
        "涨跌幅": -0.39,
        "开盘价": 22.75,
        "最高价": 22.8,
        "最低价": 22.53,
        "昨收价": 22.79,
        "总市值": 1407954084.0,
        "市盈率": 12.02,
        "成交量": 178838.0,
        "成交额": 4059967.0,
        "振幅": 1.18,
        "换手率": 0.29,
        "symbol": "105.HOLI"
    },
    {
        "序号": 9001,
        "name": "DTE Energy Co Series E Debentur",
        "最新价": 22.69,
        "涨跌额": -0.09,
        "涨跌幅": -0.4,
        "开盘价": 22.65,
        "最高价": 22.99,
        "最低价": 22.56,
        "昨收价": 22.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 16146.0,
        "成交额": 366733.0,
        "振幅": 1.89,
        "换手率": null,
        "symbol": "106.DTW"
    },
    {
        "序号": 9002,
        "name": "曼哈顿联合软件",
        "最新价": 221.74,
        "涨跌额": -0.88,
        "涨跌幅": -0.4,
        "开盘价": 221.85,
        "最高价": 223.81,
        "最低价": 221.3,
        "昨收价": 222.62,
        "总市值": 13651457913.0,
        "市盈率": 82.39,
        "成交量": 485828.0,
        "成交额": 107935435.0,
        "振幅": 1.13,
        "换手率": 0.79,
        "symbol": "105.MANH"
    },
    {
        "序号": 9003,
        "name": "iShares Intermediate Government",
        "最新价": 103.09,
        "涨跌额": -0.41,
        "涨跌幅": -0.4,
        "开盘价": 103.22,
        "最高价": 103.29,
        "最低价": 103.05,
        "昨收价": 103.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 262553.0,
        "成交额": 27079553.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.GVI"
    },
    {
        "序号": 9004,
        "name": "Strive Total Return Bond ETF",
        "最新价": 20.11,
        "涨跌额": -0.08,
        "涨跌幅": -0.4,
        "开盘价": 20.12,
        "最高价": 20.12,
        "最低价": 20.07,
        "昨收价": 20.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 4392.0,
        "成交额": 88241.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "106.STXT"
    },
    {
        "序号": 9005,
        "name": "DoubleLine Opportunistic Bond E",
        "最新价": 45.23,
        "涨跌额": -0.18,
        "涨跌幅": -0.4,
        "开盘价": 45.21,
        "最高价": 45.24,
        "最低价": 45.12,
        "昨收价": 45.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 22030.0,
        "成交额": 995452.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.DBND"
    },
    {
        "序号": 9006,
        "name": "BNY Mellon Responsible Horizons",
        "最新价": 45.21,
        "涨跌额": -0.18,
        "涨跌幅": -0.4,
        "开盘价": 45.21,
        "最高价": 45.21,
        "最低价": 45.21,
        "昨收价": 45.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 180.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.RHCB"
    },
    {
        "序号": 9007,
        "name": "JPMorgan Chase & Co Series JJ P",
        "最新价": 20.07,
        "涨跌额": -0.08,
        "涨跌幅": -0.4,
        "开盘价": 20.12,
        "最高价": 20.18,
        "最低价": 19.97,
        "昨收价": 20.15,
        "总市值": 3010500.0,
        "市盈率": null,
        "成交量": 136536.0,
        "成交额": 2740253.0,
        "振幅": 1.04,
        "换手率": 91.02,
        "symbol": "106.JPM_K"
    },
    {
        "序号": 9008,
        "name": "Dropbox Inc-A",
        "最新价": 27.59,
        "涨跌额": -0.11,
        "涨跌幅": -0.4,
        "开盘价": 27.62,
        "最高价": 27.89,
        "最低价": 27.42,
        "昨收价": 27.7,
        "总市值": 9539473704.0,
        "市盈率": 17.2,
        "成交量": 2916882.0,
        "成交额": 80372332.0,
        "振幅": 1.7,
        "换手率": 0.84,
        "symbol": "105.DBX"
    },
    {
        "序号": 9009,
        "name": "Village Super Market Inc-A",
        "最新价": 25.07,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "开盘价": 25.17,
        "最高价": 25.25,
        "最低价": 24.84,
        "昨收价": 25.17,
        "总市值": 371224326.0,
        "市盈率": 7.39,
        "成交量": 24648.0,
        "成交额": 618067.0,
        "振幅": 1.63,
        "换手率": 0.17,
        "symbol": "105.VLGEA"
    },
    {
        "序号": 9010,
        "name": "Customers Bancorp Inc Series E ",
        "最新价": 25.03,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "开盘价": 25.1,
        "最高价": 25.1,
        "最低价": 24.93,
        "昨收价": 25.13,
        "总市值": 57569000.0,
        "市盈率": null,
        "成交量": 2212.0,
        "成交额": 55304.0,
        "振幅": 0.68,
        "换手率": 0.1,
        "symbol": "106.CUBI_E"
    },
    {
        "序号": 9011,
        "name": "印度ETF-PowerShares",
        "最新价": 24.97,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "开盘价": 25.01,
        "最高价": 25.06,
        "最低价": 24.93,
        "昨收价": 25.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 28035.0,
        "成交额": 700299.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.PIN"
    },
    {
        "序号": 9012,
        "name": "Matthews Emerging Markets Susta",
        "最新价": 24.92,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "开盘价": 24.88,
        "最高价": 25.02,
        "最低价": 24.88,
        "昨收价": 25.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 192513.0,
        "成交额": 4796254.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.EMSF"
    },
    {
        "序号": 9013,
        "name": "Abacus Life Inc Notes",
        "最新价": 24.9,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 24.9,
        "昨收价": 25.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 1098.0,
        "成交额": 27439.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "105.ABLLL"
    },
    {
        "序号": 9014,
        "name": "ACNB Corp",
        "最新价": 39.8,
        "涨跌额": -0.16,
        "涨跌幅": -0.4,
        "开盘价": 40.13,
        "最高价": 40.36,
        "最低价": 39.55,
        "昨收价": 39.96,
        "总市值": 338606778.0,
        "市盈率": 8.96,
        "成交量": 11332.0,
        "成交额": 451398.0,
        "振幅": 2.03,
        "换手率": 0.13,
        "symbol": "105.ACNB"
    },
    {
        "序号": 9015,
        "name": "阿勒格尼技术",
        "最新价": 39.75,
        "涨跌额": -0.16,
        "涨跌幅": -0.4,
        "开盘价": 39.03,
        "最高价": 40.34,
        "最低价": 39.03,
        "昨收价": 39.91,
        "总市值": 5071194177.0,
        "市盈率": 16.98,
        "成交量": 1473042.0,
        "成交额": 58588093.0,
        "振幅": 3.28,
        "换手率": 1.15,
        "symbol": "106.ATI"
    },
    {
        "序号": 9016,
        "name": "iShares BBB Rated Corporate Bon",
        "最新价": 84.46,
        "涨跌额": -0.34,
        "涨跌幅": -0.4,
        "开盘价": 84.46,
        "最高价": 84.46,
        "最低价": 84.46,
        "昨收价": 84.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 936.0,
        "成交额": 79050.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LQDB"
    },
    {
        "序号": 9017,
        "name": "视频游戏技术ETF-PureFunds",
        "最新价": 56.97,
        "涨跌额": -0.23,
        "涨跌幅": -0.4,
        "开盘价": 56.94,
        "最高价": 57.04,
        "最低价": 56.81,
        "昨收价": 57.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 1504.0,
        "成交额": 85583.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.GAMR"
    },
    {
        "序号": 9018,
        "name": "FlexShares Credit-Scored US Cor",
        "最新价": 47.0,
        "涨跌额": -0.19,
        "涨跌幅": -0.4,
        "开盘价": 47.02,
        "最高价": 47.1,
        "最低价": 47.0,
        "昨收价": 47.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 22002.0,
        "成交额": 1035086.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "105.SKOR"
    },
    {
        "序号": 9019,
        "name": "Fidelity International High Div",
        "最新价": 19.75,
        "涨跌额": -0.08,
        "涨跌幅": -0.4,
        "开盘价": 19.67,
        "最高价": 19.79,
        "最低价": 19.67,
        "昨收价": 19.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 7983.0,
        "成交额": 157503.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.FIDI"
    },
    {
        "序号": 9020,
        "name": "BlackRock Enhanced Global Divid",
        "最新价": 9.85,
        "涨跌额": -0.04,
        "涨跌幅": -0.4,
        "开盘价": 9.84,
        "最高价": 9.87,
        "最低价": 9.82,
        "昨收价": 9.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 226050.0,
        "成交额": 2224900.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "106.BOE"
    },
    {
        "序号": 9021,
        "name": "Brighthouse Financial Inc Serie",
        "最新价": 17.23,
        "涨跌额": -0.07,
        "涨跌幅": -0.4,
        "开盘价": 17.3,
        "最高价": 17.59,
        "最低价": 16.85,
        "昨收价": 17.3,
        "总市值": 396290.0,
        "市盈率": null,
        "成交量": 52952.0,
        "成交额": 908505.0,
        "振幅": 4.28,
        "换手率": 230.23,
        "symbol": "105.BHFAN"
    },
    {
        "序号": 9022,
        "name": "RiverFront Dynamic Core Income ",
        "最新价": 22.09,
        "涨跌额": -0.09,
        "涨跌幅": -0.41,
        "开盘价": 22.07,
        "最高价": 22.09,
        "最低价": 22.06,
        "昨收价": 22.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 2060.0,
        "成交额": 45461.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.RFCI"
    },
    {
        "序号": 9023,
        "name": "iShares iBonds Dec 2027 Term Tr",
        "最新价": 22.09,
        "涨跌额": -0.09,
        "涨跌幅": -0.41,
        "开盘价": 22.12,
        "最高价": 22.13,
        "最低价": 22.07,
        "昨收价": 22.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 600944.0,
        "成交额": 13279540.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "105.IBTH"
    },
    {
        "序号": 9024,
        "name": "RiverNorth Enhanced Pre-Merger ",
        "最新价": 26.99,
        "涨跌额": -0.11,
        "涨跌幅": -0.41,
        "开盘价": 27.13,
        "最高价": 27.13,
        "最低价": 26.89,
        "昨收价": 27.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1150.0,
        "成交额": 31156.0,
        "振幅": 0.89,
        "换手率": null,
        "symbol": "107.SPCZ"
    },
    {
        "序号": 9025,
        "name": "新泽西能源",
        "最新价": 44.14,
        "涨跌额": -0.18,
        "涨跌幅": -0.41,
        "开盘价": 44.23,
        "最高价": 44.46,
        "最低价": 43.88,
        "昨收价": 44.32,
        "总市值": 4312811875.0,
        "市盈率": 16.29,
        "成交量": 296514.0,
        "成交额": 13079774.0,
        "振幅": 1.31,
        "换手率": 0.3,
        "symbol": "106.NJR"
    },
    {
        "序号": 9026,
        "name": "CMS Energy Corp Notes",
        "最新价": 24.48,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.51,
        "最高价": 24.6,
        "最低价": 24.42,
        "昨收价": 24.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 21861.0,
        "成交额": 535868.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "106.CMSC"
    },
    {
        "序号": 9027,
        "name": "iShares Edge Investment Grade E",
        "最新价": 44.0,
        "涨跌额": -0.18,
        "涨跌幅": -0.41,
        "开盘价": 44.02,
        "最高价": 44.08,
        "最低价": 43.95,
        "昨收价": 44.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 43518.0,
        "成交额": 1915227.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.IGEB"
    },
    {
        "序号": 9028,
        "name": "Astec Industries Inc",
        "最新价": 31.74,
        "涨跌额": -0.13,
        "涨跌幅": -0.41,
        "开盘价": 31.75,
        "最高价": 32.37,
        "最低价": 31.71,
        "昨收价": 31.87,
        "总市值": 721745255.0,
        "市盈率": 41.01,
        "成交量": 107738.0,
        "成交额": 3429637.0,
        "振幅": 2.07,
        "换手率": 0.47,
        "symbol": "105.ASTE"
    },
    {
        "序号": 9029,
        "name": "Sachem Capital Corp Notes",
        "最新价": 24.4,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.38,
        "最高价": 24.6,
        "最低价": 24.38,
        "昨收价": 24.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 1771.0,
        "成交额": 43327.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.SACC"
    },
    {
        "序号": 9030,
        "name": "Direxion Daily Retail Bull 3X S",
        "最新价": 7.32,
        "涨跌额": -0.03,
        "涨跌幅": -0.41,
        "开盘价": 7.34,
        "最高价": 7.51,
        "最低价": 7.19,
        "昨收价": 7.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 598654.0,
        "成交额": 4390522.0,
        "振幅": 4.35,
        "换手率": null,
        "symbol": "107.RETL"
    },
    {
        "序号": 9031,
        "name": "John Hancock Hedged Equity & In",
        "最新价": 9.76,
        "涨跌额": -0.04,
        "涨跌幅": -0.41,
        "开盘价": 9.82,
        "最高价": 9.88,
        "最低价": 9.75,
        "昨收价": 9.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 51605.0,
        "成交额": 504614.0,
        "振幅": 1.33,
        "换手率": null,
        "symbol": "106.HEQ"
    },
    {
        "序号": 9032,
        "name": "Hartford Multifactor Emerging M",
        "最新价": 21.94,
        "涨跌额": -0.09,
        "涨跌幅": -0.41,
        "开盘价": 21.94,
        "最高价": 21.94,
        "最低价": 21.91,
        "昨收价": 22.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 3035.0,
        "成交额": 66561.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.ROAM"
    },
    {
        "序号": 9033,
        "name": "IQ CBRE NextGen Real Estate ETF",
        "最新价": 19.48,
        "涨跌额": -0.08,
        "涨跌幅": -0.41,
        "开盘价": 19.53,
        "最高价": 19.56,
        "最低价": 19.32,
        "昨收价": 19.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 1807.0,
        "成交额": 35189.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.ROOF"
    },
    {
        "序号": 9034,
        "name": "abrdn Emerging Markets Equity I",
        "最新价": 4.87,
        "涨跌额": -0.02,
        "涨跌幅": -0.41,
        "开盘价": 4.85,
        "最高价": 4.88,
        "最低价": 4.85,
        "昨收价": 4.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 74452.0,
        "成交额": 362821.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.AEF"
    },
    {
        "序号": 9035,
        "name": "阿斯利康(US ADR)",
        "最新价": 63.25,
        "涨跌额": -0.26,
        "涨跌幅": -0.41,
        "开盘价": 63.66,
        "最高价": 63.69,
        "最低价": 63.03,
        "昨收价": 63.51,
        "总市值": 196065665692.0,
        "市盈率": 33.25,
        "成交量": 4586856.0,
        "成交额": 290562080.0,
        "振幅": 1.04,
        "换手率": 0.15,
        "symbol": "105.AZN"
    },
    {
        "序号": 9036,
        "name": "VanEck Low Carbon Energy ETF",
        "最新价": 104.54,
        "涨跌额": -0.43,
        "涨跌幅": -0.41,
        "开盘价": 104.33,
        "最高价": 105.15,
        "最低价": 103.87,
        "昨收价": 104.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 2335.0,
        "成交额": 243858.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.SMOG"
    },
    {
        "序号": 9037,
        "name": "Flaherty & Crumrine Preferred a",
        "最新价": 9.72,
        "涨跌额": -0.04,
        "涨跌幅": -0.41,
        "开盘价": 9.66,
        "最高价": 9.78,
        "最低价": 9.66,
        "昨收价": 9.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 15807.0,
        "成交额": 153853.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "106.PFD"
    },
    {
        "序号": 9038,
        "name": "Alpha Architect International Q",
        "最新价": 24.29,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.43,
        "最高价": 24.43,
        "最低价": 24.13,
        "昨收价": 24.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 6237.0,
        "成交额": 151147.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "105.IVAL"
    },
    {
        "序号": 9039,
        "name": "Invesco High Yield Bond Factor ",
        "最新价": 21.86,
        "涨跌额": -0.09,
        "涨跌幅": -0.41,
        "开盘价": 21.9,
        "最高价": 21.9,
        "最低价": 21.83,
        "昨收价": 21.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 16940.0,
        "成交额": 370082.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "105.IHYF"
    },
    {
        "序号": 9040,
        "name": "Invesco Total Return Bond ETF",
        "最新价": 46.1,
        "涨跌额": -0.19,
        "涨跌幅": -0.41,
        "开盘价": 46.07,
        "最高价": 46.14,
        "最低价": 45.99,
        "昨收价": 46.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 157873.0,
        "成交额": 7273694.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.GTO"
    },
    {
        "序号": 9041,
        "name": "DLocal Ltd-A",
        "最新价": 16.98,
        "涨跌额": -0.07,
        "涨跌幅": -0.41,
        "开盘价": 16.89,
        "最高价": 17.42,
        "最低价": 16.83,
        "昨收价": 17.05,
        "总市值": 4916693244.0,
        "市盈率": 35.17,
        "成交量": 662401.0,
        "成交额": 11302561.0,
        "振幅": 3.46,
        "换手率": 0.23,
        "symbol": "105.DLO"
    },
    {
        "序号": 9042,
        "name": "iShares U.S. Consumer Staples E",
        "最新价": 189.2,
        "涨跌额": -0.78,
        "涨跌幅": -0.41,
        "开盘价": 189.86,
        "最高价": 189.94,
        "最低价": 188.7,
        "昨收价": 189.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 65310.0,
        "成交额": 12347806.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.IYK"
    },
    {
        "序号": 9043,
        "name": "Inari Medical Inc",
        "最新价": 63.05,
        "涨跌额": -0.26,
        "涨跌幅": -0.41,
        "开盘价": 63.1,
        "最高价": 64.49,
        "最低价": 62.75,
        "昨收价": 63.31,
        "总市值": 3631078692.0,
        "市盈率": -1311.33,
        "成交量": 397731.0,
        "成交额": 25249956.0,
        "振幅": 2.75,
        "换手率": 0.69,
        "symbol": "105.NARI"
    },
    {
        "序号": 9044,
        "name": "华纳音乐",
        "最新价": 33.93,
        "涨跌额": -0.14,
        "涨跌幅": -0.41,
        "开盘价": 33.78,
        "最高价": 34.18,
        "最低价": 33.61,
        "昨收价": 34.07,
        "总市值": 17507738851.0,
        "市盈率": 40.72,
        "成交量": 1480895.0,
        "成交额": 50161097.0,
        "振幅": 1.67,
        "换手率": 0.29,
        "symbol": "105.WMG"
    },
    {
        "序号": 9045,
        "name": "埃尔比特系统",
        "最新价": 208.35,
        "涨跌额": -0.86,
        "涨跌幅": -0.41,
        "开盘价": 209.9,
        "最高价": 209.9,
        "最低价": 207.69,
        "昨收价": 209.21,
        "总市值": 9239832461.0,
        "市盈率": 34.17,
        "成交量": 10123.0,
        "成交额": 2111324.0,
        "振幅": 1.06,
        "换手率": 0.02,
        "symbol": "105.ESLT"
    },
    {
        "序号": 9046,
        "name": "德州仪器",
        "最新价": 157.03,
        "涨跌额": -0.65,
        "涨跌幅": -0.41,
        "开盘价": 157.29,
        "最高价": 158.32,
        "最低价": 156.29,
        "昨收价": 157.68,
        "总市值": 142615288724.0,
        "市盈率": 20.08,
        "成交量": 4732348.0,
        "成交额": 744174912.0,
        "振幅": 1.29,
        "换手率": 0.52,
        "symbol": "105.TXN"
    },
    {
        "序号": 9047,
        "name": "VictoryShares Core Intermediate",
        "最新价": 45.9,
        "涨跌额": -0.19,
        "涨跌幅": -0.41,
        "开盘价": 45.93,
        "最高价": 45.97,
        "最低价": 45.82,
        "昨收价": 46.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 52313.0,
        "成交额": 2399439.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.UITB"
    },
    {
        "序号": 9048,
        "name": "JPMorgan Short Duration Core Pl",
        "最新价": 45.88,
        "涨跌额": -0.19,
        "涨跌幅": -0.41,
        "开盘价": 46.05,
        "最高价": 46.05,
        "最低价": 45.87,
        "昨收价": 46.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 26569.0,
        "成交额": 1219887.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.JSCP"
    },
    {
        "序号": 9049,
        "name": "Digital Realty Trust Inc Series",
        "最新价": 24.12,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.21,
        "最高价": 24.27,
        "最低价": 24.12,
        "昨收价": 24.22,
        "总市值": 192960000.0,
        "市盈率": null,
        "成交量": 23532.0,
        "成交额": 569940.0,
        "振幅": 0.62,
        "换手率": 0.29,
        "symbol": "106.DLR_K"
    },
    {
        "序号": 9050,
        "name": "First Merchants Corp Series A P",
        "最新价": 24.11,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.0,
        "最高价": 24.11,
        "最低价": 24.0,
        "昨收价": 24.21,
        "总市值": 241100.0,
        "市盈率": null,
        "成交量": 575.0,
        "成交额": 13824.0,
        "振幅": 0.45,
        "换手率": 5.75,
        "symbol": "105.FRMEP"
    },
    {
        "序号": 9051,
        "name": "按揭抵押债ETF-iShares",
        "最新价": 91.58,
        "涨跌额": -0.38,
        "涨跌幅": -0.41,
        "开盘价": 91.56,
        "最高价": 91.66,
        "最低价": 91.28,
        "昨收价": 91.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 2225612.0,
        "成交额": 203654477.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "105.MBB"
    },
    {
        "序号": 9052,
        "name": "Invesco Trust for Investment Gr",
        "最新价": 9.62,
        "涨跌额": -0.04,
        "涨跌幅": -0.41,
        "开盘价": 9.64,
        "最高价": 9.64,
        "最低价": 9.59,
        "昨收价": 9.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 122883.0,
        "成交额": 1182102.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "106.VGM"
    },
    {
        "序号": 9053,
        "name": "Washington Federal Inc Series A",
        "最新价": 14.42,
        "涨跌额": -0.06,
        "涨跌幅": -0.41,
        "开盘价": 14.48,
        "最高价": 14.6,
        "最低价": 14.27,
        "昨收价": 14.48,
        "总市值": 4326000.0,
        "市盈率": null,
        "成交量": 28623.0,
        "成交额": 412340.0,
        "振幅": 2.28,
        "换手率": 9.54,
        "symbol": "105.WAFDP"
    },
    {
        "序号": 9054,
        "name": "Voya Financial Inc Series B Pfd",
        "最新价": 24.03,
        "涨跌额": -0.1,
        "涨跌幅": -0.41,
        "开盘价": 24.06,
        "最高价": 24.17,
        "最低价": 23.92,
        "昨收价": 24.13,
        "总市值": 7209000.0,
        "市盈率": null,
        "成交量": 57821.0,
        "成交额": 1392374.0,
        "振幅": 1.04,
        "换手率": 19.27,
        "symbol": "106.VOYA_B"
    },
    {
        "序号": 9055,
        "name": "Fox Factory Holding Corp",
        "最新价": 62.41,
        "涨跌额": -0.26,
        "涨跌幅": -0.41,
        "开盘价": 62.27,
        "最高价": 62.91,
        "最低价": 62.08,
        "昨收价": 62.67,
        "总市值": 2644994091.0,
        "市盈率": 15.58,
        "成交量": 338491.0,
        "成交额": 21131826.0,
        "振幅": 1.32,
        "换手率": 0.8,
        "symbol": "105.FOXF"
    },
    {
        "序号": 9056,
        "name": "固安捷",
        "最新价": 804.05,
        "涨跌额": -3.35,
        "涨跌幅": -0.41,
        "开盘价": 808.43,
        "最高价": 810.21,
        "最低价": 802.15,
        "昨收价": 807.4,
        "总市值": 39908338308.0,
        "市盈率": 21.95,
        "成交量": 235811.0,
        "成交额": 189734732.0,
        "振幅": 1.0,
        "换手率": 0.48,
        "symbol": "106.GWW"
    },
    {
        "序号": 9057,
        "name": "Nano Dimension Ltd ADR",
        "最新价": 2.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.41,
        "开盘价": 2.41,
        "最高价": 2.5,
        "最低价": 2.37,
        "昨收价": 2.41,
        "总市值": 564256752.0,
        "市盈率": -4.0,
        "成交量": 1266972.0,
        "成交额": 3072554.0,
        "振幅": 5.39,
        "换手率": 0.54,
        "symbol": "105.NNDM"
    },
    {
        "序号": 9058,
        "name": "沪深300ETF-德银嘉实",
        "最新价": 23.96,
        "涨跌额": -0.1,
        "涨跌幅": -0.42,
        "开盘价": 23.98,
        "最高价": 24.04,
        "最低价": 23.94,
        "昨收价": 24.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 4061916.0,
        "成交额": 97405121.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.ASHR"
    },
    {
        "序号": 9059,
        "name": "USCB Financial Holdings Inc-A",
        "最新价": 11.97,
        "涨跌额": -0.05,
        "涨跌幅": -0.42,
        "开盘价": 11.98,
        "最高价": 11.99,
        "最低价": 11.97,
        "昨收价": 12.02,
        "总市值": 233921211.0,
        "市盈率": 12.81,
        "成交量": 5057.0,
        "成交额": 60545.0,
        "振幅": 0.17,
        "换手率": 0.03,
        "symbol": "105.USCB"
    },
    {
        "序号": 9060,
        "name": "National Health Investors Inc",
        "最新价": 55.01,
        "涨跌额": -0.23,
        "涨跌幅": -0.42,
        "开盘价": 55.03,
        "最高价": 55.5,
        "最低价": 54.91,
        "昨收价": 55.24,
        "总市值": 2387975353.0,
        "市盈率": 22.64,
        "成交量": 102748.0,
        "成交额": 5657840.0,
        "振幅": 1.07,
        "换手率": 0.24,
        "symbol": "106.NHI"
    },
    {
        "序号": 9061,
        "name": "法国电信",
        "最新价": 11.95,
        "涨跌额": -0.05,
        "涨跌幅": -0.42,
        "开盘价": 11.93,
        "最高价": 11.97,
        "最低价": 11.9,
        "昨收价": 12.0,
        "总市值": 31777230325.0,
        "市盈率": 16.12,
        "成交量": 221995.0,
        "成交额": 2647104.0,
        "振幅": 0.58,
        "换手率": 0.01,
        "symbol": "106.ORAN"
    },
    {
        "序号": 9062,
        "name": "WisdomTree Yield Enhanced U.S. ",
        "最新价": 43.02,
        "涨跌额": -0.18,
        "涨跌幅": -0.42,
        "开盘价": 43.03,
        "最高价": 43.1,
        "最低价": 42.94,
        "昨收价": 43.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 127614.0,
        "成交额": 5489086.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.AGGY"
    },
    {
        "序号": 9063,
        "name": "JPMorgan Realty Income ETF",
        "最新价": 42.89,
        "涨跌额": -0.18,
        "涨跌幅": -0.42,
        "开盘价": 42.86,
        "最高价": 42.95,
        "最低价": 42.6,
        "昨收价": 43.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 9745.0,
        "成交额": 416138.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "107.JPRE"
    },
    {
        "序号": 9064,
        "name": "Silver Spike Investment Corp",
        "最新价": 9.53,
        "涨跌额": -0.04,
        "涨跌幅": -0.42,
        "开盘价": 9.65,
        "最高价": 9.65,
        "最低价": 9.53,
        "昨收价": 9.57,
        "总市值": 59226139.0,
        "市盈率": 118.32,
        "成交量": 1712.0,
        "成交额": 16414.0,
        "振幅": 1.25,
        "换手率": 0.03,
        "symbol": "105.SSIC"
    },
    {
        "序号": 9065,
        "name": "Roundhill IO Digital Infrastruc",
        "最新价": 9.53,
        "涨跌额": -0.04,
        "涨跌幅": -0.42,
        "开盘价": 9.53,
        "最高价": 9.53,
        "最低价": 9.53,
        "昨收价": 9.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 3.0,
        "成交额": 28.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.BYTE"
    },
    {
        "序号": 9066,
        "name": "Independence Contract Drilling ",
        "最新价": 2.38,
        "涨跌额": -0.01,
        "涨跌幅": -0.42,
        "开盘价": 2.38,
        "最高价": 2.51,
        "最低价": 2.38,
        "昨收价": 2.39,
        "总市值": 33521943.0,
        "市盈率": -4.05,
        "成交量": 23729.0,
        "成交额": 57620.0,
        "振幅": 5.44,
        "换手率": 0.17,
        "symbol": "106.ICD"
    },
    {
        "序号": 9067,
        "name": "KeyCorp Series F Pfd",
        "最新价": 19.02,
        "涨跌额": -0.08,
        "涨跌幅": -0.42,
        "开盘价": 19.08,
        "最高价": 19.14,
        "最低价": 18.84,
        "昨收价": 19.1,
        "总市值": 8083500.0,
        "市盈率": null,
        "成交量": 35397.0,
        "成交额": 671322.0,
        "振幅": 1.57,
        "换手率": 8.33,
        "symbol": "106.KEY_J"
    },
    {
        "序号": 9068,
        "name": "JPMorgan BetaBuilders Emerging ",
        "最新价": 47.52,
        "涨跌额": -0.2,
        "涨跌幅": -0.42,
        "开盘价": 47.6,
        "最高价": 47.6,
        "最低价": 47.52,
        "昨收价": 47.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 974.0,
        "成交额": 46358.0,
        "振幅": 0.17,
        "换手率": null,
        "symbol": "107.BBEM"
    },
    {
        "序号": 9069,
        "name": "John Hancock Mortgage-Backed Se",
        "最新价": 21.38,
        "涨跌额": -0.09,
        "涨跌幅": -0.42,
        "开盘价": 21.36,
        "最高价": 21.4,
        "最低价": 21.35,
        "昨收价": 21.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 2097.0,
        "成交额": 44820.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.JHMB"
    },
    {
        "序号": 9070,
        "name": "Ford Motor Co Notes",
        "最新价": 23.75,
        "涨跌额": -0.1,
        "涨跌幅": -0.42,
        "开盘价": 23.75,
        "最高价": 23.85,
        "最低价": 23.59,
        "昨收价": 23.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 52676.0,
        "成交额": 1249156.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.F_C"
    },
    {
        "序号": 9071,
        "name": "德事隆",
        "最新价": 75.93,
        "涨跌额": -0.32,
        "涨跌幅": -0.42,
        "开盘价": 76.5,
        "最高价": 76.67,
        "最低价": 75.7,
        "昨收价": 76.25,
        "总市值": 14882694350.0,
        "市盈率": 15.68,
        "成交量": 1123796.0,
        "成交额": 85450336.0,
        "振幅": 1.27,
        "换手率": 0.57,
        "symbol": "106.TXT"
    },
    {
        "序号": 9072,
        "name": "American Century Multisector In",
        "最新价": 42.68,
        "涨跌额": -0.18,
        "涨跌幅": -0.42,
        "开盘价": 42.65,
        "最高价": 42.7,
        "最低价": 42.57,
        "昨收价": 42.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 7184.0,
        "成交额": 306490.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.MUSI"
    },
    {
        "序号": 9073,
        "name": "iShares Core Total USD Bond Mar",
        "最新价": 45.01,
        "涨跌额": -0.19,
        "涨跌幅": -0.42,
        "开盘价": 45.01,
        "最高价": 45.07,
        "最低价": 44.92,
        "昨收价": 45.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 1449928.0,
        "成交额": 65233575.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.IUSB"
    },
    {
        "序号": 9074,
        "name": "VictoryShares Core Plus Interme",
        "最新价": 21.32,
        "涨跌额": -0.09,
        "涨跌幅": -0.42,
        "开盘价": 21.3,
        "最高价": 21.32,
        "最低价": 21.29,
        "昨收价": 21.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 9438.0,
        "成交额": 201062.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "105.UBND"
    },
    {
        "序号": 9075,
        "name": "路易斯安那太平洋",
        "最新价": 63.8,
        "涨跌额": -0.27,
        "涨跌幅": -0.42,
        "开盘价": 63.69,
        "最高价": 64.59,
        "最低价": 63.42,
        "昨收价": 64.07,
        "总市值": 4600918051.0,
        "市盈率": 41.45,
        "成交量": 430656.0,
        "成交额": 27492132.0,
        "振幅": 1.83,
        "换手率": 0.6,
        "symbol": "106.LPX"
    },
    {
        "序号": 9076,
        "name": "国际全债市指数ETF-Vanguard",
        "最新价": 49.62,
        "涨跌额": -0.21,
        "涨跌幅": -0.42,
        "开盘价": 49.58,
        "最高价": 49.64,
        "最低价": 49.55,
        "昨收价": 49.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 3054197.0,
        "成交额": 151496646.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "105.BNDX"
    },
    {
        "序号": 9077,
        "name": "NextEra Energy Partners LP",
        "最新价": 25.99,
        "涨跌额": -0.11,
        "涨跌幅": -0.42,
        "开盘价": 26.15,
        "最高价": 26.34,
        "最低价": 25.48,
        "昨收价": 26.1,
        "总市值": 2428311637.0,
        "市盈率": 19.9,
        "成交量": 1214700.0,
        "成交额": 31422576.0,
        "振幅": 3.3,
        "换手率": 1.3,
        "symbol": "106.NEP"
    },
    {
        "序号": 9078,
        "name": "BlackRock Income Trust",
        "最新价": 11.8,
        "涨跌额": -0.05,
        "涨跌幅": -0.42,
        "开盘价": 11.78,
        "最高价": 11.82,
        "最低价": 11.75,
        "昨收价": 11.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 29794.0,
        "成交额": 351538.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "106.BKT"
    },
    {
        "序号": 9079,
        "name": "Vanguard Total Corporate Bond E",
        "最新价": 75.48,
        "涨跌额": -0.32,
        "涨跌幅": -0.42,
        "开盘价": 75.47,
        "最高价": 75.6,
        "最低价": 75.4,
        "昨收价": 75.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 23451.0,
        "成交额": 1770092.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "105.VTC"
    },
    {
        "序号": 9080,
        "name": "沃特世",
        "最新价": 292.14,
        "涨跌额": -1.24,
        "涨跌幅": -0.42,
        "开盘价": 291.07,
        "最高价": 295.66,
        "最低价": 288.45,
        "昨收价": 293.38,
        "总市值": 17273355061.0,
        "市盈率": 26.45,
        "成交量": 473010.0,
        "成交额": 138531179.0,
        "振幅": 2.46,
        "换手率": 0.8,
        "symbol": "106.WAT"
    },
    {
        "序号": 9081,
        "name": "Oaktree Capital Group LLC Serie",
        "最新价": 21.16,
        "涨跌额": -0.09,
        "涨跌幅": -0.42,
        "开盘价": 21.3,
        "最高价": 21.45,
        "最低价": 21.16,
        "昨收价": 21.25,
        "总市值": 198904000.0,
        "市盈率": null,
        "成交量": 7061.0,
        "成交额": 150805.0,
        "振幅": 1.36,
        "换手率": 0.08,
        "symbol": "106.OAK_B"
    },
    {
        "序号": 9082,
        "name": "和黄医药(US ADR)",
        "最新价": 18.8,
        "涨跌额": -0.08,
        "涨跌幅": -0.42,
        "开盘价": 18.8,
        "最高价": 18.83,
        "最低价": 18.41,
        "昨收价": 18.88,
        "总市值": 3275609615.0,
        "市盈率": -111.33,
        "成交量": 102077.0,
        "成交额": 1905377.0,
        "振幅": 2.22,
        "换手率": 0.06,
        "symbol": "105.HCM"
    },
    {
        "序号": 9083,
        "name": "博格华纳",
        "最新价": 32.9,
        "涨跌额": -0.14,
        "涨跌幅": -0.42,
        "开盘价": 33.0,
        "最高价": 33.49,
        "最低价": 32.88,
        "昨收价": 33.04,
        "总市值": 7733323614.0,
        "市盈率": 10.65,
        "成交量": 3019153.0,
        "成交额": 99819466.0,
        "振幅": 1.85,
        "换手率": 1.28,
        "symbol": "106.BWA"
    },
    {
        "序号": 9084,
        "name": "iShares iBonds Dec 2030 Term Co",
        "最新价": 21.1,
        "涨跌额": -0.09,
        "涨跌幅": -0.42,
        "开盘价": 21.12,
        "最高价": 21.12,
        "最低价": 21.05,
        "昨收价": 21.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 180888.0,
        "成交额": 3814489.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.IBDV"
    },
    {
        "序号": 9085,
        "name": "ProShares Ultra High Yield ETF",
        "最新价": 65.55,
        "涨跌额": -0.28,
        "涨跌幅": -0.43,
        "开盘价": 65.55,
        "最高价": 65.55,
        "最低价": 65.55,
        "昨收价": 65.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 308.0,
        "成交额": 20189.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.UJB"
    },
    {
        "序号": 9086,
        "name": "哈特-汉克斯",
        "最新价": 7.02,
        "涨跌额": -0.03,
        "涨跌幅": -0.43,
        "开盘价": 7.02,
        "最高价": 7.51,
        "最低价": 6.85,
        "昨收价": 7.05,
        "总市值": 50658524.0,
        "市盈率": 2.28,
        "成交量": 9348.0,
        "成交额": 66672.0,
        "振幅": 9.36,
        "换手率": 0.13,
        "symbol": "105.HHS"
    },
    {
        "序号": 9087,
        "name": "海伦特洛伊家电",
        "最新价": 109.93,
        "涨跌额": -0.47,
        "涨跌幅": -0.43,
        "开盘价": 110.38,
        "最高价": 111.49,
        "最低价": 109.08,
        "昨收价": 110.4,
        "总市值": 2610040178.0,
        "市盈率": 18.92,
        "成交量": 100819.0,
        "成交额": 11087390.0,
        "振幅": 2.18,
        "换手率": 0.42,
        "symbol": "105.HELE"
    },
    {
        "序号": 9088,
        "name": "Franklin Genomic Advancements E",
        "最新价": 28.04,
        "涨跌额": -0.12,
        "涨跌幅": -0.43,
        "开盘价": 28.06,
        "最高价": 28.06,
        "最低价": 28.04,
        "昨收价": 28.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 740.0,
        "成交额": 20764.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.HELX"
    },
    {
        "序号": 9089,
        "name": "Steel Partners Holdings LP Seri",
        "最新价": 23.35,
        "涨跌额": -0.1,
        "涨跌幅": -0.43,
        "开盘价": 23.52,
        "最高价": 23.52,
        "最低价": 23.35,
        "昨收价": 23.45,
        "总市值": 59312596.0,
        "市盈率": null,
        "成交量": 6482.0,
        "成交额": 151958.0,
        "振幅": 0.72,
        "换手率": 0.26,
        "symbol": "106.SPLP_A"
    },
    {
        "序号": 9090,
        "name": "IQ MacKay Multi-Sector Income E",
        "最新价": 23.3,
        "涨跌额": -0.1,
        "涨跌幅": -0.43,
        "开盘价": 23.3,
        "最高价": 23.3,
        "最低价": 23.3,
        "昨收价": 23.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 23.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MMSB"
    },
    {
        "序号": 9091,
        "name": "Schwab Fundamental Emerging Mar",
        "最新价": 25.63,
        "涨跌额": -0.11,
        "涨跌幅": -0.43,
        "开盘价": 25.57,
        "最高价": 25.7,
        "最低价": 25.53,
        "昨收价": 25.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 541349.0,
        "成交额": 13853275.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.FNDE"
    },
    {
        "序号": 9092,
        "name": "VanEck Green Bond ETF",
        "最新价": 23.29,
        "涨跌额": -0.1,
        "涨跌幅": -0.43,
        "开盘价": 23.31,
        "最高价": 23.37,
        "最低价": 23.25,
        "昨收价": 23.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 30232.0,
        "成交额": 703884.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.GRNB"
    },
    {
        "序号": 9093,
        "name": "Spire Inc",
        "最新价": 62.88,
        "涨跌额": -0.27,
        "涨跌幅": -0.43,
        "开盘价": 63.23,
        "最高价": 63.41,
        "最低价": 62.65,
        "昨收价": 63.15,
        "总市值": 3343759699.0,
        "市盈率": 15.37,
        "成交量": 266416.0,
        "成交额": 16766178.0,
        "振幅": 1.2,
        "换手率": 0.5,
        "symbol": "106.SR"
    },
    {
        "序号": 9094,
        "name": "RB Global Inc",
        "最新价": 62.83,
        "涨跌额": -0.27,
        "涨跌幅": -0.43,
        "开盘价": 63.18,
        "最高价": 63.57,
        "最低价": 62.25,
        "昨收价": 63.1,
        "总市值": 11455968128.0,
        "市盈率": 68.41,
        "成交量": 947855.0,
        "成交额": 59470115.0,
        "振幅": 2.09,
        "换手率": 0.52,
        "symbol": "106.RBA"
    },
    {
        "序号": 9095,
        "name": "Invesco Municipal Trust",
        "最新价": 9.3,
        "涨跌额": -0.04,
        "涨跌幅": -0.43,
        "开盘价": 9.31,
        "最高价": 9.34,
        "最低价": 9.28,
        "昨收价": 9.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 162391.0,
        "成交额": 1511022.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "106.VKQ"
    },
    {
        "序号": 9096,
        "name": "Tsakos Energy Navigation Ltd Se",
        "最新价": 25.57,
        "涨跌额": -0.11,
        "涨跌幅": -0.43,
        "开盘价": 25.6,
        "最高价": 25.68,
        "最低价": 25.5,
        "昨收价": 25.68,
        "总市值": 172524549.0,
        "市盈率": null,
        "成交量": 5447.0,
        "成交额": 139305.0,
        "振幅": 0.7,
        "换手率": 0.08,
        "symbol": "106.TNP_F"
    },
    {
        "序号": 9097,
        "name": "富兰克林资源",
        "最新价": 25.57,
        "涨跌额": -0.11,
        "涨跌幅": -0.43,
        "开盘价": 25.61,
        "最高价": 25.8,
        "最低价": 25.46,
        "昨收价": 25.68,
        "总市值": 12646522724.0,
        "市盈率": 14.33,
        "成交量": 2294435.0,
        "成交额": 58741613.0,
        "振幅": 1.32,
        "换手率": 0.46,
        "symbol": "106.BEN"
    },
    {
        "序号": 9098,
        "name": "Invesco Fundamental Investment ",
        "最新价": 23.24,
        "涨跌额": -0.1,
        "涨跌幅": -0.43,
        "开盘价": 23.23,
        "最高价": 23.26,
        "最低价": 23.2,
        "昨收价": 23.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 4522.0,
        "成交额": 105004.0,
        "振幅": 0.26,
        "换手率": null,
        "symbol": "107.PFIG"
    },
    {
        "序号": 9099,
        "name": "Tiptree Inc",
        "最新价": 18.59,
        "涨跌额": -0.08,
        "涨跌幅": -0.43,
        "开盘价": 18.71,
        "最高价": 18.71,
        "最低价": 18.54,
        "昨收价": 18.67,
        "总市值": 683178187.0,
        "市盈率": 85.92,
        "成交量": 30440.0,
        "成交额": 566650.0,
        "振幅": 0.91,
        "换手率": 0.08,
        "symbol": "105.TIPT"
    },
    {
        "序号": 9100,
        "name": "Direxion Daily S&P 500 Bear 1X ",
        "最新价": 13.94,
        "涨跌额": -0.06,
        "涨跌幅": -0.43,
        "开盘价": 14.02,
        "最高价": 14.03,
        "最低价": 13.92,
        "昨收价": 14.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 10326791.0,
        "成交额": 144327814.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.SPDN"
    },
    {
        "序号": 9101,
        "name": "ChoiceOne Financial Services In",
        "最新价": 27.88,
        "涨跌额": -0.12,
        "涨跌幅": -0.43,
        "开盘价": 27.6,
        "最高价": 28.48,
        "最低价": 27.56,
        "昨收价": 28.0,
        "总市值": 210357221.0,
        "市盈率": 9.29,
        "成交量": 18116.0,
        "成交额": 507675.0,
        "振幅": 3.29,
        "换手率": 0.24,
        "symbol": "105.COFS"
    },
    {
        "序号": 9102,
        "name": "VanEck Moody's Analytics BBB Co",
        "最新价": 20.89,
        "涨跌额": -0.09,
        "涨跌幅": -0.43,
        "开盘价": 20.89,
        "最高价": 20.89,
        "最低价": 20.89,
        "昨收价": 20.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 104.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MBBB"
    },
    {
        "序号": 9103,
        "name": "AXS TSLA Bear Daily ETF",
        "最新价": 32.46,
        "涨跌额": -0.14,
        "涨跌幅": -0.43,
        "开盘价": 32.92,
        "最高价": 33.06,
        "最低价": 32.27,
        "昨收价": 32.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 3471041.0,
        "成交额": 113116812.0,
        "振幅": 2.42,
        "换手率": null,
        "symbol": "105.TSLQ"
    },
    {
        "序号": 9104,
        "name": "Nuveen Municipal Credit Income ",
        "最新价": 11.59,
        "涨跌额": -0.05,
        "涨跌幅": -0.43,
        "开盘价": 11.62,
        "最高价": 11.65,
        "最低价": 11.55,
        "昨收价": 11.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 709226.0,
        "成交额": 8222362.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "106.NZF"
    },
    {
        "序号": 9105,
        "name": "Boot Barn Holdings Inc",
        "最新价": 74.11,
        "涨跌额": -0.32,
        "涨跌幅": -0.43,
        "开盘价": 73.61,
        "最高价": 74.82,
        "最低价": 73.45,
        "昨收价": 74.43,
        "总市值": 2244333604.0,
        "市盈率": 13.93,
        "成交量": 611920.0,
        "成交额": 45371574.0,
        "振幅": 1.84,
        "换手率": 2.02,
        "symbol": "106.BOOT"
    },
    {
        "序号": 9106,
        "name": "VanEck Moody's Analytics IG Cor",
        "最新价": 20.83,
        "涨跌额": -0.09,
        "涨跌幅": -0.43,
        "开盘价": 20.83,
        "最高价": 20.83,
        "最低价": 20.83,
        "昨收价": 20.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 125.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.MIG"
    },
    {
        "序号": 9107,
        "name": "ProShares Short MidCap400",
        "最新价": 23.12,
        "涨跌额": -0.1,
        "涨跌幅": -0.43,
        "开盘价": 23.16,
        "最高价": 23.16,
        "最低价": 23.11,
        "昨收价": 23.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 2690.0,
        "成交额": 62275.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "107.MYY"
    },
    {
        "序号": 9108,
        "name": "Amplify BlackSwan ISWN ETF",
        "最新价": 18.49,
        "涨跌额": -0.08,
        "涨跌幅": -0.43,
        "开盘价": 18.45,
        "最高价": 18.49,
        "最低价": 18.43,
        "昨收价": 18.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 1519.0,
        "成交额": 28037.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.ISWN"
    },
    {
        "序号": 9109,
        "name": "Western Asset Bond ETF",
        "最新价": 25.41,
        "涨跌额": -0.11,
        "涨跌幅": -0.43,
        "开盘价": 25.41,
        "最高价": 25.41,
        "最低价": 25.35,
        "昨收价": 25.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 1012.0,
        "成交额": 25687.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "105.WABF"
    },
    {
        "序号": 9110,
        "name": "Capital One Financial Corp Seri",
        "最新价": 18.46,
        "涨跌额": -0.08,
        "涨跌幅": -0.43,
        "开盘价": 18.46,
        "最高价": 18.5,
        "最低价": 18.16,
        "昨收价": 18.54,
        "总市值": 27690000.0,
        "市盈率": null,
        "成交量": 156765.0,
        "成交额": 2878735.0,
        "振幅": 1.83,
        "换手率": 10.45,
        "symbol": "106.COF_I"
    },
    {
        "序号": 9111,
        "name": "Deep Medicine Acquisition Corp-",
        "最新价": 11.5,
        "涨跌额": -0.05,
        "涨跌幅": -0.43,
        "开盘价": 11.5,
        "最高价": 11.5,
        "最低价": 11.5,
        "昨收价": 11.55,
        "总市值": 50116586.0,
        "市盈率": -81.87,
        "成交量": 801.0,
        "成交额": 9211.0,
        "振幅": 0.0,
        "换手率": 0.02,
        "symbol": "105.DMAQ"
    },
    {
        "序号": 9112,
        "name": "新兴市场ETF-iShares MSCI",
        "最新价": 39.01,
        "涨跌额": -0.17,
        "涨跌幅": -0.43,
        "开盘价": 38.93,
        "最高价": 39.14,
        "最低价": 38.85,
        "昨收价": 39.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 25195432.0,
        "成交额": 981989760.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.EEM"
    },
    {
        "序号": 9113,
        "name": "Franklin FTSE Asia ex Japan ETF",
        "最新价": 20.64,
        "涨跌额": -0.09,
        "涨跌幅": -0.43,
        "开盘价": 20.62,
        "最高价": 20.64,
        "最低价": 20.62,
        "昨收价": 20.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 480.0,
        "成交额": 9900.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.FLAX"
    },
    {
        "序号": 9114,
        "name": "Match Group Inc",
        "最新价": 32.1,
        "涨跌额": -0.14,
        "涨跌幅": -0.43,
        "开盘价": 32.0,
        "最高价": 32.61,
        "最低价": 31.66,
        "昨收价": 32.24,
        "总市值": 8725151237.0,
        "市盈率": 17.23,
        "成交量": 5342119.0,
        "成交额": 171707978.0,
        "振幅": 2.95,
        "换手率": 1.97,
        "symbol": "105.MTCH"
    },
    {
        "序号": 9115,
        "name": "阿什福德信托",
        "最新价": 2.29,
        "涨跌额": -0.01,
        "涨跌幅": -0.43,
        "开盘价": 2.28,
        "最高价": 2.33,
        "最低价": 2.26,
        "昨收价": 2.3,
        "总市值": 79035654.0,
        "市盈率": -0.39,
        "成交量": 198504.0,
        "成交额": 453572.0,
        "振幅": 3.04,
        "换手率": 0.58,
        "symbol": "106.AHT"
    },
    {
        "序号": 9116,
        "name": "Nuveen Enhanced Yield U.S. Aggr",
        "最新价": 20.6,
        "涨跌额": -0.09,
        "涨跌幅": -0.43,
        "开盘价": 20.6,
        "最高价": 20.6,
        "最低价": 20.58,
        "昨收价": 20.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 13793.0,
        "成交额": 283872.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.NUAG"
    },
    {
        "序号": 9117,
        "name": "二倍做空基础材料ETF-ProShares",
        "最新价": 9.15,
        "涨跌额": -0.04,
        "涨跌幅": -0.44,
        "开盘价": 9.18,
        "最高价": 9.18,
        "最低价": 9.15,
        "昨收价": 9.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 1168.0,
        "成交额": 10695.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.SMN"
    },
    {
        "序号": 9118,
        "name": "iShares iBonds Dec 2033 Term Co",
        "最新价": 25.09,
        "涨跌额": -0.11,
        "涨跌幅": -0.44,
        "开盘价": 25.11,
        "最高价": 25.18,
        "最低价": 25.05,
        "昨收价": 25.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 147857.0,
        "成交额": 3707685.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.IBDY"
    },
    {
        "序号": 9119,
        "name": "Invesco MSCI Sustainable Future",
        "最新价": 43.33,
        "涨跌额": -0.19,
        "涨跌幅": -0.44,
        "开盘价": 43.39,
        "最高价": 43.52,
        "最低价": 42.97,
        "昨收价": 43.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 8290.0,
        "成交额": 358698.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "107.ERTH"
    },
    {
        "序号": 9120,
        "name": "Enlight Renewable Energy Ltd",
        "最新价": 18.24,
        "涨跌额": -0.08,
        "涨跌幅": -0.44,
        "开盘价": 18.48,
        "最高价": 18.48,
        "最低价": 18.08,
        "昨收价": 18.32,
        "总市值": 2149716933.0,
        "市盈率": 32.27,
        "成交量": 23690.0,
        "成交额": 431503.0,
        "振幅": 2.18,
        "换手率": 0.02,
        "symbol": "105.ENLT"
    },
    {
        "序号": 9121,
        "name": "BlackRock Municipal 2030 Target",
        "最新价": 20.52,
        "涨跌额": -0.09,
        "涨跌幅": -0.44,
        "开盘价": 20.47,
        "最高价": 20.61,
        "最低价": 20.39,
        "昨收价": 20.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 278611.0,
        "成交额": 5708800.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "106.BTT"
    },
    {
        "序号": 9122,
        "name": "Xtrackers Bloomberg US Investme",
        "最新价": 18.23,
        "涨跌额": -0.08,
        "涨跌幅": -0.44,
        "开盘价": 18.2,
        "最高价": 18.23,
        "最低价": 18.2,
        "昨收价": 18.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 120.0,
        "成交额": 2183.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.ESCR"
    },
    {
        "序号": 9123,
        "name": "Franklin Income Focus ETF",
        "最新价": 25.02,
        "涨跌额": -0.11,
        "涨跌幅": -0.44,
        "开盘价": 25.0,
        "最高价": 25.09,
        "最低价": 24.95,
        "昨收价": 25.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 73717.0,
        "成交额": 1846235.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.INCM"
    },
    {
        "序号": 9124,
        "name": "MetLife Inc Series F Pfd",
        "最新价": 20.41,
        "涨跌额": -0.09,
        "涨跌幅": -0.44,
        "开盘价": 20.45,
        "最高价": 20.45,
        "最低价": 20.26,
        "昨收价": 20.5,
        "总市值": 816400.0,
        "市盈率": null,
        "成交量": 88866.0,
        "成交额": 1810050.0,
        "振幅": 0.93,
        "换手率": 222.16,
        "symbol": "106.MET_F"
    },
    {
        "序号": 9125,
        "name": "Invesco S&P SmallCap Consumer S",
        "最新价": 36.27,
        "涨跌额": -0.16,
        "涨跌幅": -0.44,
        "开盘价": 36.5,
        "最高价": 36.51,
        "最低价": 36.18,
        "昨收价": 36.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 12099.0,
        "成交额": 438460.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "105.PSCC"
    },
    {
        "序号": 9126,
        "name": "麦当劳",
        "最新价": 285.53,
        "涨跌额": -1.26,
        "涨跌幅": -0.44,
        "开盘价": 287.33,
        "最高价": 287.9,
        "最低价": 285.05,
        "昨收价": 286.79,
        "总市值": 207106909826.0,
        "市盈率": 24.85,
        "成交量": 3623843.0,
        "成交额": 1035931280.0,
        "振幅": 0.99,
        "换手率": 0.5,
        "symbol": "106.MCD"
    },
    {
        "序号": 9127,
        "name": "ProShares S&P 500 Bond ETF",
        "最新价": 74.74,
        "涨跌额": -0.33,
        "涨跌幅": -0.44,
        "开盘价": 74.74,
        "最高价": 74.74,
        "最低价": 74.74,
        "昨收价": 75.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 22.0,
        "成交额": 1644.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SPXB"
    },
    {
        "序号": 9128,
        "name": "Janus Henderson Sustainable Cor",
        "最新价": 40.75,
        "涨跌额": -0.18,
        "涨跌幅": -0.44,
        "开盘价": 40.75,
        "最高价": 40.75,
        "最低价": 40.75,
        "昨收价": 40.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 5.0,
        "成交额": 203.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SCRD"
    },
    {
        "序号": 9129,
        "name": "Array Technologies Inc",
        "最新价": 15.81,
        "涨跌额": -0.07,
        "涨跌幅": -0.44,
        "开盘价": 15.85,
        "最高价": 16.36,
        "最低价": 15.55,
        "昨收价": 15.88,
        "总市值": 2390732549.0,
        "市盈率": 21.95,
        "成交量": 3427874.0,
        "成交额": 54283861.0,
        "振幅": 5.1,
        "换手率": 2.27,
        "symbol": "105.ARRY"
    },
    {
        "序号": 9130,
        "name": "菲律宾长途电话",
        "最新价": 22.56,
        "涨跌额": -0.1,
        "涨跌幅": -0.44,
        "开盘价": 22.55,
        "最高价": 22.7,
        "最低价": 22.49,
        "昨收价": 22.66,
        "总市值": 4874218284.0,
        "市盈率": 23.94,
        "成交量": 23909.0,
        "成交额": 539915.0,
        "振幅": 0.93,
        "换手率": 0.01,
        "symbol": "106.PHI"
    },
    {
        "序号": 9131,
        "name": "Viad Corp",
        "最新价": 33.8,
        "涨跌额": -0.15,
        "涨跌幅": -0.44,
        "开盘价": 33.71,
        "最高价": 34.14,
        "最低价": 33.55,
        "昨收价": 33.95,
        "总市值": 707074706.0,
        "市盈率": 27.59,
        "成交量": 77832.0,
        "成交额": 2630896.0,
        "振幅": 1.74,
        "换手率": 0.37,
        "symbol": "106.VVI"
    },
    {
        "序号": 9132,
        "name": "L3Harris Technologies Inc",
        "最新价": 195.81,
        "涨跌额": -0.87,
        "涨跌幅": -0.44,
        "开盘价": 197.01,
        "最高价": 197.19,
        "最低价": 193.34,
        "昨收价": 196.68,
        "总市值": 37113876157.0,
        "市盈率": 24.99,
        "成交量": 1273620.0,
        "成交额": 248796542.0,
        "振幅": 1.96,
        "换手率": 0.67,
        "symbol": "106.LHX"
    },
    {
        "序号": 9133,
        "name": "First Trust TCW Emerging Market",
        "最新价": 15.75,
        "涨跌额": -0.07,
        "涨跌幅": -0.44,
        "开盘价": 15.79,
        "最高价": 15.79,
        "最低价": 15.61,
        "昨收价": 15.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 9658.0,
        "成交额": 152087.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "107.EFIX"
    },
    {
        "序号": 9134,
        "name": "Mobile Infrastructure Corp",
        "最新价": 4.5,
        "涨跌额": -0.02,
        "涨跌幅": -0.44,
        "开盘价": 4.34,
        "最高价": 4.68,
        "最低价": 4.34,
        "昨收价": 4.52,
        "总市值": 67500000.0,
        "市盈率": -4.46,
        "成交量": 30389.0,
        "成交额": 135368.0,
        "振幅": 7.52,
        "换手率": 0.2,
        "symbol": "107.BEEP"
    },
    {
        "序号": 9135,
        "name": "iShares USD Bond Factor ETF",
        "最新价": 83.21,
        "涨跌额": -0.37,
        "涨跌幅": -0.44,
        "开盘价": 83.21,
        "最高价": 83.21,
        "最低价": 83.21,
        "昨收价": 83.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 2.0,
        "成交额": 166.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.USBF"
    },
    {
        "序号": 9136,
        "name": "越南ETF-VanEck Vectors",
        "最新价": 13.48,
        "涨跌额": -0.06,
        "涨跌幅": -0.44,
        "开盘价": 13.46,
        "最高价": 13.52,
        "最低价": 13.46,
        "昨收价": 13.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 330290.0,
        "成交额": 4453059.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.VNM"
    },
    {
        "序号": 9137,
        "name": "Cambria Global Asset Allocation",
        "最新价": 26.96,
        "涨跌额": -0.12,
        "涨跌幅": -0.44,
        "开盘价": 27.18,
        "最高价": 27.18,
        "最低价": 26.86,
        "昨收价": 27.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 2310.0,
        "成交额": 62431.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.GAA"
    },
    {
        "序号": 9138,
        "name": "Forafric Global PLC",
        "最新价": 11.23,
        "涨跌额": -0.05,
        "涨跌幅": -0.44,
        "开盘价": 11.39,
        "最高价": 11.39,
        "最低价": 11.23,
        "昨收价": 11.28,
        "总市值": 301852315.0,
        "市盈率": -15.78,
        "成交量": 2271.0,
        "成交额": 25592.0,
        "振幅": 1.42,
        "换手率": 0.01,
        "symbol": "105.AFRI"
    },
    {
        "序号": 9139,
        "name": "Oramed Pharmaceuticals Inc",
        "最新价": 2.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.44,
        "开盘价": 2.22,
        "最高价": 2.29,
        "最低价": 2.22,
        "昨收价": 2.25,
        "总市值": 90359313.0,
        "市盈率": -5.53,
        "成交量": 86130.0,
        "成交额": 194153.0,
        "振幅": 3.11,
        "换手率": 0.21,
        "symbol": "105.ORMP"
    },
    {
        "序号": 9140,
        "name": "克利尔沃特纸业",
        "最新价": 35.84,
        "涨跌额": -0.16,
        "涨跌幅": -0.44,
        "开盘价": 36.0,
        "最高价": 36.34,
        "最低价": 35.74,
        "昨收价": 36.0,
        "总市值": 593630213.0,
        "市盈率": 7.05,
        "成交量": 52764.0,
        "成交额": 1893519.0,
        "振幅": 1.67,
        "换手率": 0.32,
        "symbol": "106.CLW"
    },
    {
        "序号": 9141,
        "name": "iShares iBonds Dec 2031 Term Co",
        "最新价": 20.14,
        "涨跌额": -0.09,
        "涨跌幅": -0.44,
        "开盘价": 20.18,
        "最高价": 20.18,
        "最低价": 20.1,
        "昨收价": 20.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 427727.0,
        "成交额": 8612003.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.IBDW"
    },
    {
        "序号": 9142,
        "name": "iShares Government\/Credit Bond ",
        "最新价": 102.93,
        "涨跌额": -0.46,
        "涨跌幅": -0.44,
        "开盘价": 102.97,
        "最高价": 103.07,
        "最低价": 102.78,
        "昨收价": 103.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 5619.0,
        "成交额": 578221.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.GBF"
    },
    {
        "序号": 9143,
        "name": "中华电信",
        "最新价": 38.03,
        "涨跌额": -0.17,
        "涨跌幅": -0.45,
        "开盘价": 38.03,
        "最高价": 38.14,
        "最低价": 38.0,
        "昨收价": 38.2,
        "总市值": 29501569211.0,
        "市盈率": 25.27,
        "成交量": 91772.0,
        "成交额": 3491586.0,
        "振幅": 0.37,
        "换手率": 0.01,
        "symbol": "106.CHT"
    },
    {
        "序号": 9144,
        "name": "格拉德斯通商业",
        "最新价": 13.41,
        "涨跌额": -0.06,
        "涨跌幅": -0.45,
        "开盘价": 13.43,
        "最高价": 13.49,
        "最低价": 13.13,
        "昨收价": 13.47,
        "总市值": 535713676.0,
        "市盈率": 285.11,
        "成交量": 222680.0,
        "成交额": 2967205.0,
        "振幅": 2.67,
        "换手率": 0.56,
        "symbol": "105.GOOD"
    },
    {
        "序号": 9145,
        "name": "DTE Energy Co Series G Debentur",
        "最新价": 20.11,
        "涨跌额": -0.09,
        "涨跌幅": -0.45,
        "开盘价": 20.2,
        "最高价": 20.2,
        "最低价": 19.92,
        "昨收价": 20.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 16507.0,
        "成交额": 332247.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "106.DTB"
    },
    {
        "序号": 9146,
        "name": "SCE Trust III Pfd",
        "最新价": 24.55,
        "涨跌额": -0.11,
        "涨跌幅": -0.45,
        "开盘价": 24.51,
        "最高价": 24.62,
        "最低价": 24.33,
        "昨收价": 24.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 15592.0,
        "成交额": 382322.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "106.SCE_H"
    },
    {
        "序号": 9147,
        "name": "HHG Capital Corp",
        "最新价": 11.15,
        "涨跌额": -0.05,
        "涨跌幅": -0.45,
        "开盘价": 11.15,
        "最高价": 11.15,
        "最低价": 11.15,
        "昨收价": 11.2,
        "总市值": 56313755.0,
        "市盈率": 56.4,
        "成交量": 2145.0,
        "成交额": 23916.0,
        "振幅": 0.0,
        "换手率": 0.04,
        "symbol": "105.HHGC"
    },
    {
        "序号": 9148,
        "name": "First Trust Intermediate Govern",
        "最新价": 20.05,
        "涨跌额": -0.09,
        "涨跌幅": -0.45,
        "开盘价": 20.05,
        "最高价": 20.07,
        "最低价": 20.02,
        "昨收价": 20.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 17173.0,
        "成交额": 344263.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.MGOV"
    },
    {
        "序号": 9149,
        "name": "PennyMac Financial Services Inc",
        "最新价": 80.15,
        "涨跌额": -0.36,
        "涨跌幅": -0.45,
        "开盘价": 80.17,
        "最高价": 80.92,
        "最低价": 79.11,
        "昨收价": 80.51,
        "总市值": 4001549023.0,
        "市盈率": 18.26,
        "成交量": 453704.0,
        "成交额": 36267814.0,
        "振幅": 2.25,
        "换手率": 0.91,
        "symbol": "106.PFSI"
    },
    {
        "序号": 9150,
        "name": "First Trust S&P International D",
        "最新价": 15.56,
        "涨跌额": -0.07,
        "涨跌幅": -0.45,
        "开盘价": 15.52,
        "最高价": 15.6,
        "最低价": 15.52,
        "昨收价": 15.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 13808.0,
        "成交额": 214896.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "105.FID"
    },
    {
        "序号": 9151,
        "name": "iShares iBonds Dec 2032 Term Co",
        "最新价": 24.44,
        "涨跌额": -0.11,
        "涨跌幅": -0.45,
        "开盘价": 24.39,
        "最高价": 24.56,
        "最低价": 24.37,
        "昨收价": 24.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 151683.0,
        "成交额": 3706068.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.IBDX"
    },
    {
        "序号": 9152,
        "name": "大健云仓",
        "最新价": 13.33,
        "涨跌额": -0.06,
        "涨跌幅": -0.45,
        "开盘价": 13.72,
        "最高价": 14.11,
        "最低价": 12.9,
        "昨收价": 13.39,
        "总市值": 543247474.0,
        "市盈率": 7.65,
        "成交量": 1278952.0,
        "成交额": 17379064.0,
        "振幅": 9.04,
        "换手率": 3.14,
        "symbol": "105.GCT"
    },
    {
        "序号": 9153,
        "name": "iShares ESG MSCI EM ETF",
        "最新价": 31.04,
        "涨跌额": -0.14,
        "涨跌幅": -0.45,
        "开盘价": 30.99,
        "最高价": 31.14,
        "最低价": 30.93,
        "昨收价": 31.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 965875.0,
        "成交额": 29963074.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "105.ESGE"
    },
    {
        "序号": 9154,
        "name": "Workiva Inc-A",
        "最新价": 95.3,
        "涨跌额": -0.43,
        "涨跌幅": -0.45,
        "开盘价": 95.36,
        "最高价": 97.34,
        "最低价": 94.38,
        "昨收价": 95.73,
        "总市值": 5150777926.0,
        "市盈率": -37.53,
        "成交量": 379790.0,
        "成交额": 36178228.0,
        "振幅": 3.09,
        "换手率": 0.7,
        "symbol": "106.WK"
    },
    {
        "序号": 9155,
        "name": "宠物IQ",
        "最新价": 17.73,
        "涨跌额": -0.08,
        "涨跌幅": -0.45,
        "开盘价": 17.94,
        "最高价": 18.2,
        "最低价": 17.66,
        "昨收价": 17.81,
        "总市值": 521745958.0,
        "市盈率": 40.82,
        "成交量": 193947.0,
        "成交额": 3447761.0,
        "振幅": 3.03,
        "换手率": 0.66,
        "symbol": "105.PETQ"
    },
    {
        "序号": 9156,
        "name": "Wintrust Financial Corp Series ",
        "最新价": 22.16,
        "涨跌额": -0.1,
        "涨跌幅": -0.45,
        "开盘价": 22.22,
        "最高价": 22.42,
        "最低价": 21.92,
        "昨收价": 22.26,
        "总市值": 110800000.0,
        "市盈率": null,
        "成交量": 8626.0,
        "成交额": 191214.0,
        "振幅": 2.25,
        "换手率": 0.17,
        "symbol": "105.WTFCM"
    },
    {
        "序号": 9157,
        "name": "Hartford Total Return Bond ETF",
        "最新价": 33.22,
        "涨跌额": -0.15,
        "涨跌幅": -0.45,
        "开盘价": 33.26,
        "最高价": 33.26,
        "最低价": 33.14,
        "昨收价": 33.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 401499.0,
        "成交额": 13329821.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.HTRB"
    },
    {
        "序号": 9158,
        "name": "Dimensional Global Sustainabili",
        "最新价": 50.92,
        "涨跌额": -0.23,
        "涨跌幅": -0.45,
        "开盘价": 50.91,
        "最高价": 50.94,
        "最低价": 50.84,
        "昨收价": 51.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 4351.0,
        "成交额": 221440.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.DFSB"
    },
    {
        "序号": 9159,
        "name": "Invesco BulletShares 2028 Corpo",
        "最新价": 19.88,
        "涨跌额": -0.09,
        "涨跌幅": -0.45,
        "开盘价": 19.91,
        "最高价": 19.91,
        "最低价": 19.87,
        "昨收价": 19.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 650911.0,
        "成交额": 12944158.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "105.BSCS"
    },
    {
        "序号": 9160,
        "name": "SPDR Portfolio Corporate Bond E",
        "最新价": 28.7,
        "涨跌额": -0.13,
        "涨跌幅": -0.45,
        "开盘价": 28.7,
        "最高价": 28.75,
        "最低价": 28.64,
        "昨收价": 28.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 144367.0,
        "成交额": 4140276.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "107.SPBO"
    },
    {
        "序号": 9161,
        "name": "应用材料",
        "最新价": 147.72,
        "涨跌额": -0.67,
        "涨跌幅": -0.45,
        "开盘价": 147.42,
        "最高价": 148.81,
        "最低价": 145.99,
        "昨收价": 148.39,
        "总市值": 123572780617.0,
        "市盈率": 18.02,
        "成交量": 4990196.0,
        "成交额": 736345792.0,
        "振幅": 1.9,
        "换手率": 0.6,
        "symbol": "105.AMAT"
    },
    {
        "序号": 9162,
        "name": "阿卡迈",
        "最新价": 114.41,
        "涨跌额": -0.52,
        "涨跌幅": -0.45,
        "开盘价": 114.37,
        "最高价": 114.92,
        "最低价": 114.01,
        "昨收价": 114.93,
        "总市值": 17256639695.0,
        "市盈率": 33.49,
        "成交量": 1129623.0,
        "成交额": 129253961.0,
        "振幅": 0.79,
        "换手率": 0.75,
        "symbol": "105.AKAM"
    },
    {
        "序号": 9163,
        "name": "Rave Restaurant Group Inc",
        "最新价": 2.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.45,
        "开盘价": 2.2,
        "最高价": 2.23,
        "最低价": 2.18,
        "昨收价": 2.21,
        "总市值": 32090445.0,
        "市盈率": 18.97,
        "成交量": 31854.0,
        "成交额": 70050.0,
        "振幅": 2.26,
        "换手率": 0.22,
        "symbol": "105.RAVE"
    },
    {
        "序号": 9164,
        "name": "Rayliant Quantamental Emerging ",
        "最新价": 21.99,
        "涨跌额": -0.1,
        "涨跌幅": -0.45,
        "开盘价": 21.9,
        "最高价": 21.99,
        "最低价": 21.9,
        "昨收价": 22.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 1533.0,
        "成交额": 33645.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "107.RAYE"
    },
    {
        "序号": 9165,
        "name": "First Trust High Income Strateg",
        "最新价": 43.87,
        "涨跌额": -0.2,
        "涨跌幅": -0.45,
        "开盘价": 43.85,
        "最高价": 43.87,
        "最低价": 43.85,
        "昨收价": 44.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1045.0,
        "成交额": 45825.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "105.HISF"
    },
    {
        "序号": 9166,
        "name": "American Century Emerging Marke",
        "最新价": 37.26,
        "涨跌额": -0.17,
        "涨跌幅": -0.45,
        "开盘价": 37.26,
        "最高价": 37.26,
        "最低价": 37.26,
        "昨收价": 37.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 6.0,
        "成交额": 223.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AEMB"
    },
    {
        "序号": 9167,
        "name": "Pilgrim's Pride Corp",
        "最新价": 26.28,
        "涨跌额": -0.12,
        "涨跌幅": -0.45,
        "开盘价": 26.44,
        "最高价": 26.46,
        "最低价": 26.1,
        "昨收价": 26.4,
        "总市值": 6222839334.0,
        "市盈率": 194.8,
        "成交量": 269985.0,
        "成交额": 7094398.0,
        "振幅": 1.36,
        "换手率": 0.11,
        "symbol": "105.PPC"
    },
    {
        "序号": 9168,
        "name": "iShares Currency Hedged MSCI Em",
        "最新价": 24.09,
        "涨跌额": -0.11,
        "涨跌幅": -0.45,
        "开盘价": 23.98,
        "最高价": 24.11,
        "最低价": 23.98,
        "昨收价": 24.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 16865.0,
        "成交额": 405205.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.HEEM"
    },
    {
        "序号": 9169,
        "name": "Envela Corp",
        "最新价": 4.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.45,
        "开盘价": 4.39,
        "最高价": 4.45,
        "最低价": 4.33,
        "昨收价": 4.4,
        "总市值": 116946000.0,
        "市盈率": 9.98,
        "成交量": 22178.0,
        "成交额": 97714.0,
        "振幅": 2.73,
        "换手率": 0.08,
        "symbol": "107.ELA"
    },
    {
        "序号": 9170,
        "name": "Columbia Diversified Fixed Inco",
        "最新价": 17.51,
        "涨跌额": -0.08,
        "涨跌幅": -0.45,
        "开盘价": 17.49,
        "最高价": 17.56,
        "最低价": 17.48,
        "昨收价": 17.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 43857.0,
        "成交额": 768534.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.DIAL"
    },
    {
        "序号": 9171,
        "name": "JPMorgan Core Plus Bond ETF",
        "最新价": 45.95,
        "涨跌额": -0.21,
        "涨跌幅": -0.45,
        "开盘价": 45.98,
        "最高价": 46.02,
        "最低价": 45.86,
        "昨收价": 46.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 208334.0,
        "成交额": 9571288.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.JCPB"
    },
    {
        "序号": 9172,
        "name": "FlexShares Core Select Bond Fun",
        "最新价": 21.86,
        "涨跌额": -0.1,
        "涨跌幅": -0.46,
        "开盘价": 21.86,
        "最高价": 21.87,
        "最低价": 21.81,
        "昨收价": 21.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 14735.0,
        "成交额": 321840.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.BNDC"
    },
    {
        "序号": 9173,
        "name": "布雷迪",
        "最新价": 56.8,
        "涨跌额": -0.26,
        "涨跌幅": -0.46,
        "开盘价": 57.03,
        "最高价": 57.3,
        "最低价": 56.7,
        "昨收价": 57.06,
        "总市值": 2745208866.0,
        "市盈率": 15.03,
        "成交量": 257774.0,
        "成交额": 14680288.0,
        "振幅": 1.05,
        "换手率": 0.53,
        "symbol": "106.BRC"
    },
    {
        "序号": 9174,
        "name": "Dimensional Emerging Markets Va",
        "最新价": 23.98,
        "涨跌额": -0.11,
        "涨跌幅": -0.46,
        "开盘价": 23.93,
        "最高价": 24.04,
        "最低价": 23.89,
        "昨收价": 24.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 116184.0,
        "成交额": 2783019.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.DFEV"
    },
    {
        "序号": 9175,
        "name": "Athene Holding Ltd Series D Pfd",
        "最新价": 17.42,
        "涨跌额": -0.08,
        "涨跌幅": -0.46,
        "开盘价": 17.43,
        "最高价": 17.44,
        "最低价": 17.14,
        "昨收价": 17.5,
        "总市值": 383240.0,
        "市盈率": null,
        "成交量": 53723.0,
        "成交额": 925775.0,
        "振幅": 1.71,
        "换手率": 244.2,
        "symbol": "106.ATH_D"
    },
    {
        "序号": 9176,
        "name": "易趣",
        "最新价": 41.29,
        "涨跌额": -0.19,
        "涨跌幅": -0.46,
        "开盘价": 41.36,
        "最高价": 41.72,
        "最低价": 41.16,
        "昨收价": 41.48,
        "总市值": 21429510000.0,
        "市盈率": 7.89,
        "成交量": 7243404.0,
        "成交额": 299506576.0,
        "振幅": 1.35,
        "换手率": 1.4,
        "symbol": "105.EBAY"
    },
    {
        "序号": 9177,
        "name": "Nuveen ESG U.S. Aggregate Bond ",
        "最新价": 21.73,
        "涨跌额": -0.1,
        "涨跌幅": -0.46,
        "开盘价": 21.72,
        "最高价": 21.75,
        "最低价": 21.68,
        "昨收价": 21.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 28514.0,
        "成交额": 619033.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.NUBD"
    },
    {
        "序号": 9178,
        "name": "iShares Broad USD Investment Gr",
        "最新价": 49.94,
        "涨跌额": -0.23,
        "涨跌幅": -0.46,
        "开盘价": 49.94,
        "最高价": 50.04,
        "最低价": 49.87,
        "昨收价": 50.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 865800.0,
        "成交额": 43236814.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "105.USIG"
    },
    {
        "序号": 9179,
        "name": "WisdomTree New Economy Real Est",
        "最新价": 17.36,
        "涨跌额": -0.08,
        "涨跌幅": -0.46,
        "开盘价": 17.3,
        "最高价": 17.37,
        "最低价": 17.2,
        "昨收价": 17.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 48843.0,
        "成交额": 843645.0,
        "振幅": 0.97,
        "换手率": null,
        "symbol": "107.WTRE"
    },
    {
        "序号": 9180,
        "name": "NI Holdings Inc",
        "最新价": 13.01,
        "涨跌额": -0.06,
        "涨跌幅": -0.46,
        "开盘价": 12.99,
        "最高价": 13.1,
        "最低价": 12.95,
        "昨收价": 13.07,
        "总市值": 267688465.0,
        "市盈率": -23.88,
        "成交量": 4981.0,
        "成交额": 64765.0,
        "振幅": 1.15,
        "换手率": 0.02,
        "symbol": "105.NODK"
    },
    {
        "序号": 9181,
        "name": "Slam Corp-A",
        "最新价": 10.82,
        "涨跌额": -0.05,
        "涨跌幅": -0.46,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 10.82,
        "昨收价": 10.87,
        "总市值": 429663964.0,
        "市盈率": 45.93,
        "成交量": 50776.0,
        "成交额": 550216.0,
        "振幅": 0.28,
        "换手率": 0.13,
        "symbol": "105.SLAM"
    },
    {
        "序号": 9182,
        "name": "Direxion Daily TSLA Bear 1X Sha",
        "最新价": 19.47,
        "涨跌额": -0.09,
        "涨跌幅": -0.46,
        "开盘价": 19.77,
        "最高价": 19.83,
        "最低价": 19.36,
        "昨收价": 19.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 1955584.0,
        "成交额": 38183585.0,
        "振幅": 2.4,
        "换手率": null,
        "symbol": "105.TSLS"
    },
    {
        "序号": 9183,
        "name": "阿斯麦",
        "最新价": 696.43,
        "涨跌额": -3.22,
        "涨跌幅": -0.46,
        "开盘价": 695.34,
        "最高价": 702.83,
        "最低价": 694.03,
        "昨收价": 699.65,
        "总市值": 273975562000.0,
        "市盈率": 32.76,
        "成交量": 920784.0,
        "成交额": 642272720.0,
        "振幅": 1.26,
        "换手率": 0.23,
        "symbol": "105.ASML"
    },
    {
        "序号": 9184,
        "name": "Touchstone Climate Transition E",
        "最新价": 23.75,
        "涨跌额": -0.11,
        "涨跌幅": -0.46,
        "开盘价": 23.75,
        "最高价": 23.75,
        "最低价": 23.75,
        "昨收价": 23.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 200.0,
        "成交额": 4750.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HEAT"
    },
    {
        "序号": 9185,
        "name": "SPDR Index Shares Fund SPDR Por",
        "最新价": 34.53,
        "涨跌额": -0.16,
        "涨跌幅": -0.46,
        "开盘价": 34.47,
        "最高价": 34.6,
        "最低价": 34.4,
        "昨收价": 34.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 796773.0,
        "成交额": 27487124.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.SPEM"
    },
    {
        "序号": 9186,
        "name": "Montauk Renewables Inc",
        "最新价": 8.63,
        "涨跌额": -0.04,
        "涨跌幅": -0.46,
        "开盘价": 8.64,
        "最高价": 8.79,
        "最低价": 8.41,
        "昨收价": 8.67,
        "总市值": 1239800635.0,
        "市盈率": 76.92,
        "成交量": 184732.0,
        "成交额": 1584305.0,
        "振幅": 4.38,
        "换手率": 0.13,
        "symbol": "105.MNTK"
    },
    {
        "序号": 9187,
        "name": "巴西小型股ETF-VanEck Vectors",
        "最新价": 17.24,
        "涨跌额": -0.08,
        "涨跌幅": -0.46,
        "开盘价": 17.18,
        "最高价": 17.26,
        "最低价": 17.18,
        "昨收价": 17.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 813.0,
        "成交额": 13983.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.BRF"
    },
    {
        "序号": 9188,
        "name": "Goldman Sachs Access Investment",
        "最新价": 45.14,
        "涨跌额": -0.21,
        "涨跌幅": -0.46,
        "开盘价": 45.14,
        "最高价": 45.2,
        "最低价": 45.09,
        "昨收价": 45.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 20228.0,
        "成交额": 912916.0,
        "振幅": 0.24,
        "换手率": null,
        "symbol": "107.GIGB"
    },
    {
        "序号": 9189,
        "name": "SPDR Portfolio Mortgage Backed ",
        "最新价": 21.48,
        "涨跌额": -0.1,
        "涨跌幅": -0.46,
        "开盘价": 21.49,
        "最高价": 21.5,
        "最低价": 21.41,
        "昨收价": 21.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 625404.0,
        "成交额": 13426627.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.SPMB"
    },
    {
        "序号": 9190,
        "name": "Vanguard Mortgage-Backed Securi",
        "最新价": 45.09,
        "涨跌额": -0.21,
        "涨跌幅": -0.46,
        "开盘价": 45.01,
        "最高价": 45.13,
        "最低价": 44.94,
        "昨收价": 45.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 1426480.0,
        "成交额": 64261275.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "105.VMBS"
    },
    {
        "序号": 9191,
        "name": "汽车地带",
        "最新价": 2620.49,
        "涨跌额": -12.21,
        "涨跌幅": -0.46,
        "开盘价": 2640.0,
        "最高价": 2640.0,
        "最低价": 2610.5,
        "昨收价": 2632.7,
        "总市值": 45402609740.0,
        "市盈率": 17.58,
        "成交量": 110335.0,
        "成交额": 289199392.0,
        "振幅": 1.12,
        "换手率": 0.64,
        "symbol": "106.AZO"
    },
    {
        "序号": 9192,
        "name": "Pioneer Diversified High Income",
        "最新价": 10.73,
        "涨跌额": -0.05,
        "涨跌幅": -0.46,
        "开盘价": 10.77,
        "最高价": 10.83,
        "最低价": 10.73,
        "昨收价": 10.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 9559.0,
        "成交额": 102897.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.HNW"
    },
    {
        "序号": 9193,
        "name": "Public Storage Series F Pfd",
        "最新价": 23.6,
        "涨跌额": -0.11,
        "涨跌幅": -0.46,
        "开盘价": 23.6,
        "最高价": 23.66,
        "最低价": 23.49,
        "昨收价": 23.71,
        "总市值": 264320.0,
        "市盈率": null,
        "成交量": 7121.0,
        "成交额": 167907.0,
        "振幅": 0.72,
        "换手率": 63.58,
        "symbol": "106.PSA_F"
    },
    {
        "序号": 9194,
        "name": "WisdomTree Emerging Markets ESG",
        "最新价": 27.86,
        "涨跌额": -0.13,
        "涨跌幅": -0.46,
        "开盘价": 27.94,
        "最高价": 27.94,
        "最低价": 27.86,
        "昨收价": 27.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 1650.0,
        "成交额": 46007.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.RESE"
    },
    {
        "序号": 9195,
        "name": "国际除美国房地产ETF-SPDR",
        "最新价": 25.71,
        "涨跌额": -0.12,
        "涨跌幅": -0.46,
        "开盘价": 25.6,
        "最高价": 25.75,
        "最低价": 25.57,
        "昨收价": 25.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 34444.0,
        "成交额": 884380.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.RWX"
    },
    {
        "序号": 9196,
        "name": "JPMorgan BetaBuilders USD Inves",
        "最新价": 44.99,
        "涨跌额": -0.21,
        "涨跌幅": -0.46,
        "开盘价": 44.99,
        "最高价": 44.99,
        "最低价": 44.97,
        "昨收价": 45.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 65189.0,
        "成交额": 2931551.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.BBCB"
    },
    {
        "序号": 9197,
        "name": "Invesco National AMT-Free Munic",
        "最新价": 23.56,
        "涨跌额": -0.11,
        "涨跌幅": -0.46,
        "开盘价": 23.63,
        "最高价": 23.63,
        "最低价": 23.52,
        "昨收价": 23.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 677486.0,
        "成交额": 15952122.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.PZA"
    },
    {
        "序号": 9198,
        "name": "Franklin Emerging Market Core D",
        "最新价": 23.56,
        "涨跌额": -0.11,
        "涨跌幅": -0.46,
        "开盘价": 23.57,
        "最高价": 23.57,
        "最低价": 23.56,
        "昨收价": 23.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 234.0,
        "成交额": 5515.0,
        "振幅": 0.04,
        "换手率": null,
        "symbol": "107.DIEM"
    },
    {
        "序号": 9199,
        "name": "BRP Group Inc-A",
        "最新价": 19.25,
        "涨跌额": -0.09,
        "涨跌幅": -0.47,
        "开盘价": 19.35,
        "最高价": 19.76,
        "最低价": 19.19,
        "昨收价": 19.34,
        "总市值": 2247419270.0,
        "市盈率": -21.58,
        "成交量": 224161.0,
        "成交额": 4331973.0,
        "振幅": 2.95,
        "换手率": 0.19,
        "symbol": "105.BRP"
    },
    {
        "序号": 9200,
        "name": "Central Garden & Pet Co",
        "最新价": 44.89,
        "涨跌额": -0.21,
        "涨跌幅": -0.47,
        "开盘价": 45.1,
        "最高价": 45.51,
        "最低价": 44.65,
        "昨收价": 45.1,
        "总市值": 2415704669.0,
        "市盈率": 19.23,
        "成交量": 65864.0,
        "成交额": 2962293.0,
        "振幅": 1.91,
        "换手率": 0.52,
        "symbol": "105.CENT"
    },
    {
        "序号": 9201,
        "name": "Public Storage Series N Pfd",
        "最新价": 17.1,
        "涨跌额": -0.08,
        "涨跌幅": -0.47,
        "开盘价": 17.15,
        "最高价": 17.21,
        "最低价": 17.0,
        "昨收价": 17.18,
        "总市值": 193230.0,
        "市盈率": null,
        "成交量": 14972.0,
        "成交额": 255844.0,
        "振幅": 1.22,
        "换手率": 132.5,
        "symbol": "106.PSA_N"
    },
    {
        "序号": 9202,
        "name": "Select Medical Holdings Corp",
        "最新价": 23.48,
        "涨跌额": -0.11,
        "涨跌幅": -0.47,
        "开盘价": 23.51,
        "最高价": 23.7,
        "最低价": 23.22,
        "昨收价": 23.59,
        "总市值": 3010453872.0,
        "市盈率": 13.4,
        "成交量": 375116.0,
        "成交额": 8805493.0,
        "振幅": 2.03,
        "换手率": 0.29,
        "symbol": "106.SEM"
    },
    {
        "序号": 9203,
        "name": "FlexShares ESG & Climate Invest",
        "最新价": 40.55,
        "涨跌额": -0.19,
        "涨跌幅": -0.47,
        "开盘价": 40.55,
        "最高价": 40.55,
        "最低价": 40.55,
        "昨收价": 40.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 103.0,
        "成交额": 4176.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FEIG"
    },
    {
        "序号": 9204,
        "name": "中期公司债指数ETF-Vanguard",
        "最新价": 78.94,
        "涨跌额": -0.37,
        "涨跌幅": -0.47,
        "开盘价": 78.92,
        "最高价": 79.07,
        "最低价": 78.81,
        "昨收价": 79.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 13959825.0,
        "成交额": 1101974464.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.VCIT"
    },
    {
        "序号": 9205,
        "name": "CMS Energy Corp Notes",
        "最新价": 23.45,
        "涨跌额": -0.11,
        "涨跌幅": -0.47,
        "开盘价": 23.59,
        "最高价": 23.81,
        "最低价": 23.39,
        "昨收价": 23.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 6714.0,
        "成交额": 158212.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "106.CMSA"
    },
    {
        "序号": 9206,
        "name": "Driven Brands Holdings Inc",
        "最新价": 12.79,
        "涨跌额": -0.06,
        "涨跌幅": -0.47,
        "开盘价": 12.87,
        "最高价": 13.12,
        "最低价": 12.77,
        "昨收价": 12.85,
        "总市值": 2097038488.0,
        "市盈率": -2.98,
        "成交量": 796385.0,
        "成交额": 10253209.0,
        "振幅": 2.72,
        "换手率": 0.49,
        "symbol": "105.DRVN"
    },
    {
        "序号": 9207,
        "name": "State Street Corp Series G Pfd",
        "最新价": 23.42,
        "涨跌额": -0.11,
        "涨跌幅": -0.47,
        "开盘价": 23.5,
        "最高价": 23.53,
        "最低价": 23.37,
        "昨收价": 23.53,
        "总市值": 117100.0,
        "市盈率": null,
        "成交量": 35549.0,
        "成交额": 833309.0,
        "振幅": 0.68,
        "换手率": 710.98,
        "symbol": "106.STT_G"
    },
    {
        "序号": 9208,
        "name": "Nuveen California Municipal Val",
        "最新价": 8.51,
        "涨跌额": -0.04,
        "涨跌幅": -0.47,
        "开盘价": 8.56,
        "最高价": 8.58,
        "最低价": 8.51,
        "昨收价": 8.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 74695.0,
        "成交额": 638607.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.NCA"
    },
    {
        "序号": 9209,
        "name": "Fossil Group Inc Notes",
        "最新价": 12.75,
        "涨跌额": -0.06,
        "涨跌幅": -0.47,
        "开盘价": 12.5,
        "最高价": 12.99,
        "最低价": 12.5,
        "昨收价": 12.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 3088.0,
        "成交额": 39009.0,
        "振幅": 3.83,
        "换手率": null,
        "symbol": "105.FOSLL"
    },
    {
        "序号": 9210,
        "name": "AdvisorShares Dorsey Wright Sho",
        "最新价": 8.5,
        "涨跌额": -0.04,
        "涨跌幅": -0.47,
        "开盘价": 8.56,
        "最高价": 8.56,
        "最低价": 8.44,
        "昨收价": 8.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 33746.0,
        "成交额": 286404.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "105.DWSH"
    },
    {
        "序号": 9211,
        "name": "Avantis Responsible Emerging Ma",
        "最新价": 46.65,
        "涨跌额": -0.22,
        "涨跌幅": -0.47,
        "开盘价": 46.72,
        "最高价": 46.72,
        "最低价": 46.59,
        "昨收价": 46.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 1092.0,
        "成交额": 50926.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.AVSE"
    },
    {
        "序号": 9212,
        "name": "OceanPal Inc",
        "最新价": 2.12,
        "涨跌额": -0.01,
        "涨跌幅": -0.47,
        "开盘价": 2.1,
        "最高价": 2.19,
        "最低价": 2.1,
        "昨收价": 2.13,
        "总市值": 15790218.0,
        "市盈率": -3.49,
        "成交量": 39780.0,
        "成交额": 84798.0,
        "振幅": 4.23,
        "换手率": 0.53,
        "symbol": "105.OP"
    },
    {
        "序号": 9213,
        "name": "Algonquin Power & Utilities Cor",
        "最新价": 21.2,
        "涨跌额": -0.1,
        "涨跌幅": -0.47,
        "开盘价": 21.25,
        "最高价": 21.26,
        "最低价": 21.01,
        "昨收价": 21.3,
        "总市值": 14609768615.0,
        "市盈率": -62.96,
        "成交量": 22411.0,
        "成交额": 473124.0,
        "振幅": 1.17,
        "换手率": null,
        "symbol": "106.AQNU"
    },
    {
        "序号": 9214,
        "name": "Bluegreen Vacations Holding Cor",
        "最新价": 74.17,
        "涨跌额": -0.35,
        "涨跌幅": -0.47,
        "开盘价": 74.5,
        "最高价": 74.71,
        "最低价": 74.06,
        "昨收价": 74.52,
        "总市值": 1263692365.0,
        "市盈率": 20.48,
        "成交量": 70945.0,
        "成交额": 5265387.0,
        "振幅": 0.87,
        "换手率": 0.42,
        "symbol": "106.BVH"
    },
    {
        "序号": 9215,
        "name": "CyberArk Software Ltd",
        "最新价": 201.06,
        "涨跌额": -0.95,
        "涨跌幅": -0.47,
        "开盘价": 202.41,
        "最高价": 203.73,
        "最低价": 200.76,
        "昨收价": 202.01,
        "总市值": 8397284170.0,
        "市盈率": -86.02,
        "成交量": 254404.0,
        "成交额": 51337083.0,
        "振幅": 1.47,
        "换手率": 0.61,
        "symbol": "105.CYBR"
    },
    {
        "序号": 9216,
        "name": "Nuveen New York Quality Municip",
        "最新价": 10.58,
        "涨跌额": -0.05,
        "涨跌幅": -0.47,
        "开盘价": 10.63,
        "最高价": 10.63,
        "最低价": 10.53,
        "昨收价": 10.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 74792.0,
        "成交额": 791235.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "106.NAN"
    },
    {
        "序号": 9217,
        "name": "BlackRock Core Bond Trust",
        "最新价": 10.57,
        "涨跌额": -0.05,
        "涨跌幅": -0.47,
        "开盘价": 10.62,
        "最高价": 10.67,
        "最低价": 10.52,
        "昨收价": 10.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 189846.0,
        "成交额": 2010380.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "106.BHK"
    },
    {
        "序号": 9218,
        "name": "The New Germany Fund",
        "最新价": 8.45,
        "涨跌额": -0.04,
        "涨跌幅": -0.47,
        "开盘价": 8.46,
        "最高价": 8.49,
        "最低价": 8.42,
        "昨收价": 8.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 64867.0,
        "成交额": 548995.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.GF"
    },
    {
        "序号": 9219,
        "name": "Franklin Investment Grade Corpo",
        "最新价": 21.1,
        "涨跌额": -0.1,
        "涨跌幅": -0.47,
        "开盘价": 21.08,
        "最高价": 21.14,
        "最低价": 21.08,
        "昨收价": 21.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 36805.0,
        "成交额": 776105.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.FLCO"
    },
    {
        "序号": 9220,
        "name": "西斯科",
        "最新价": 73.83,
        "涨跌额": -0.35,
        "涨跌幅": -0.47,
        "开盘价": 74.08,
        "最高价": 74.23,
        "最低价": 73.61,
        "昨收价": 74.18,
        "总市值": 37237782545.0,
        "市盈率": 20.6,
        "成交量": 3689904.0,
        "成交额": 272589072.0,
        "振幅": 0.84,
        "换手率": 0.73,
        "symbol": "106.SYY"
    },
    {
        "序号": 9221,
        "name": "Impact Shares Affordable Housin",
        "最新价": 16.87,
        "涨跌额": -0.08,
        "涨跌幅": -0.47,
        "开盘价": 16.86,
        "最高价": 16.87,
        "最低价": 16.82,
        "昨收价": 16.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 1163.0,
        "成交额": 19573.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.OWNS"
    },
    {
        "序号": 9222,
        "name": "Federal Agricultural Mortgage C",
        "最新价": 25.28,
        "涨跌额": -0.12,
        "涨跌幅": -0.47,
        "开盘价": 25.39,
        "最高价": 25.43,
        "最低价": 25.28,
        "昨收价": 25.4,
        "总市值": 75840000.0,
        "市盈率": null,
        "成交量": 2082.0,
        "成交额": 52678.0,
        "振幅": 0.59,
        "换手率": 0.07,
        "symbol": "106.AGM_C"
    },
    {
        "序号": 9223,
        "name": "SPDR MarketAxess Investment Gra",
        "最新价": 94.75,
        "涨跌额": -0.45,
        "涨跌幅": -0.47,
        "开盘价": 94.78,
        "最高价": 94.78,
        "最低价": 94.73,
        "昨收价": 95.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 689.0,
        "成交额": 65286.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.LQIG"
    },
    {
        "序号": 9224,
        "name": "Western Asset Municipal High In",
        "最新价": 6.31,
        "涨跌额": -0.03,
        "涨跌幅": -0.47,
        "开盘价": 6.33,
        "最高价": 6.35,
        "最低价": 6.27,
        "昨收价": 6.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 109510.0,
        "成交额": 690547.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "106.MHF"
    },
    {
        "序号": 9225,
        "name": "徕博科",
        "最新价": 216.6,
        "涨跌额": -1.03,
        "涨跌幅": -0.47,
        "开盘价": 217.62,
        "最高价": 217.62,
        "最低价": 215.68,
        "昨收价": 217.63,
        "总市值": 18389340000.0,
        "市盈率": 27.81,
        "成交量": 508685.0,
        "成交额": 110158654.0,
        "振幅": 0.89,
        "换手率": 0.6,
        "symbol": "106.LH"
    },
    {
        "序号": 9226,
        "name": "iShares Genomics Immunology and",
        "最新价": 21.01,
        "涨跌额": -0.1,
        "涨跌幅": -0.47,
        "开盘价": 20.92,
        "最高价": 21.38,
        "最低价": 20.92,
        "昨收价": 21.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 31193.0,
        "成交额": 658926.0,
        "振幅": 2.18,
        "换手率": null,
        "symbol": "107.IDNA"
    },
    {
        "序号": 9227,
        "name": "SoundHound AI Inc-A",
        "最新价": 2.1,
        "涨跌额": -0.01,
        "涨跌幅": -0.47,
        "开盘价": 2.06,
        "最高价": 2.14,
        "最低价": 2.05,
        "昨收价": 2.11,
        "总市值": 518541015.0,
        "市盈率": -5.16,
        "成交量": 6231683.0,
        "成交额": 13024373.0,
        "振幅": 4.27,
        "换手率": 2.52,
        "symbol": "105.SOUN"
    },
    {
        "序号": 9228,
        "name": "GDEV Inc",
        "最新价": 2.1,
        "涨跌额": -0.01,
        "涨跌幅": -0.47,
        "开盘价": 2.1,
        "最高价": 2.12,
        "最低价": 2.1,
        "昨收价": 2.11,
        "总市值": 414517954.0,
        "市盈率": -9.89,
        "成交量": 3794.0,
        "成交额": 7978.0,
        "振幅": 0.95,
        "换手率": 0.0,
        "symbol": "105.GDEV"
    },
    {
        "序号": 9229,
        "name": "iShares Asia \/Pacific Dividend ",
        "最新价": 33.55,
        "涨跌额": -0.16,
        "涨跌幅": -0.47,
        "开盘价": 33.38,
        "最高价": 33.57,
        "最低价": 33.38,
        "昨收价": 33.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 1083.0,
        "成交额": 36273.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.DVYA"
    },
    {
        "序号": 9230,
        "name": "John Hancock Investors Trust",
        "最新价": 12.57,
        "涨跌额": -0.06,
        "涨跌幅": -0.48,
        "开盘价": 12.67,
        "最高价": 12.67,
        "最低价": 12.54,
        "昨收价": 12.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 9840.0,
        "成交额": 124106.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.JHI"
    },
    {
        "序号": 9231,
        "name": "CB金融服务",
        "最新价": 23.04,
        "涨跌额": -0.11,
        "涨跌幅": -0.48,
        "开盘价": 21.21,
        "最高价": 23.25,
        "最低价": 21.21,
        "昨收价": 23.15,
        "总市值": 117980421.0,
        "市盈率": 8.59,
        "成交量": 7043.0,
        "成交额": 161627.0,
        "振幅": 8.81,
        "换手率": 0.14,
        "symbol": "105.CBFV"
    },
    {
        "序号": 9232,
        "name": "Globe Life Inc Debentures",
        "最新价": 18.82,
        "涨跌额": -0.09,
        "涨跌幅": -0.48,
        "开盘价": 18.96,
        "最高价": 18.96,
        "最低价": 18.65,
        "昨收价": 18.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 7618.0,
        "成交额": 143036.0,
        "振幅": 1.64,
        "换手率": null,
        "symbol": "106.GL_D"
    },
    {
        "序号": 9233,
        "name": "Pliant Therapeutics Inc",
        "最新价": 14.63,
        "涨跌额": -0.07,
        "涨跌幅": -0.48,
        "开盘价": 15.05,
        "最高价": 15.28,
        "最低价": 14.62,
        "昨收价": 14.7,
        "总市值": 876277866.0,
        "市盈率": -5.64,
        "成交量": 462307.0,
        "成交额": 6865066.0,
        "振幅": 4.49,
        "换手率": 0.77,
        "symbol": "105.PLRX"
    },
    {
        "序号": 9234,
        "name": "中概股ETF-PowerShares",
        "最新价": 25.05,
        "涨跌额": -0.12,
        "涨跌幅": -0.48,
        "开盘价": 25.05,
        "最高价": 25.21,
        "最低价": 25.0,
        "昨收价": 25.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 29957.0,
        "成交额": 751135.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.PGJ"
    },
    {
        "序号": 9235,
        "name": "Lindblad Expeditions Holdings I",
        "最新价": 8.35,
        "涨跌额": -0.04,
        "涨跌幅": -0.48,
        "开盘价": 8.34,
        "最高价": 8.51,
        "最低价": 8.25,
        "昨收价": 8.39,
        "总市值": 445792105.0,
        "市盈率": -8.85,
        "成交量": 260832.0,
        "成交额": 2174071.0,
        "振幅": 3.1,
        "换手率": 0.49,
        "symbol": "105.LIND"
    },
    {
        "序号": 9236,
        "name": "新兴市场地方债ETF-WisdomTree",
        "最新价": 27.13,
        "涨跌额": -0.13,
        "涨跌幅": -0.48,
        "开盘价": 27.18,
        "最高价": 27.32,
        "最低价": 26.96,
        "昨收价": 27.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 8586.0,
        "成交额": 233257.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "107.ELD"
    },
    {
        "序号": 9237,
        "name": "Aegon Funding Co LLC Notes",
        "最新价": 20.82,
        "涨跌额": -0.1,
        "涨跌幅": -0.48,
        "开盘价": 20.88,
        "最高价": 20.97,
        "最低价": 20.75,
        "昨收价": 20.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 35833.0,
        "成交额": 745975.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "106.AEFC"
    },
    {
        "序号": 9238,
        "name": "Schwab 5-10 Year Corporate Bond",
        "最新价": 43.72,
        "涨跌额": -0.21,
        "涨跌幅": -0.48,
        "开盘价": 43.71,
        "最高价": 43.8,
        "最低价": 43.65,
        "昨收价": 43.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 301886.0,
        "成交额": 13188318.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.SCHI"
    },
    {
        "序号": 9239,
        "name": "Xtrackers J.P. Morgan ESG Emerg",
        "最新价": 16.64,
        "涨跌额": -0.08,
        "涨跌幅": -0.48,
        "开盘价": 16.64,
        "最高价": 16.64,
        "最低价": 16.64,
        "昨收价": 16.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 13.0,
        "成交额": 216.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ESEB"
    },
    {
        "序号": 9240,
        "name": "标普全球",
        "最新价": 415.38,
        "涨跌额": -2.0,
        "涨跌幅": -0.48,
        "开盘价": 415.64,
        "最高价": 418.85,
        "最低价": 413.17,
        "昨收价": 417.38,
        "总市值": 131592384000.0,
        "市盈率": 53.06,
        "成交量": 1651506.0,
        "成交额": 686330288.0,
        "振幅": 1.36,
        "换手率": 0.52,
        "symbol": "106.SPGI"
    },
    {
        "序号": 9241,
        "name": "勇士煤业",
        "最新价": 58.14,
        "涨跌额": -0.28,
        "涨跌幅": -0.48,
        "开盘价": 58.32,
        "最高价": 59.0,
        "最低价": 57.87,
        "昨收价": 58.42,
        "总市值": 3024380183.0,
        "市盈率": 6.73,
        "成交量": 349165.0,
        "成交额": 20371267.0,
        "振幅": 1.93,
        "换手率": 0.67,
        "symbol": "106.HCC"
    },
    {
        "序号": 9242,
        "name": "卡特",
        "最新价": 72.67,
        "涨跌额": -0.35,
        "涨跌幅": -0.48,
        "开盘价": 72.82,
        "最高价": 73.39,
        "最低价": 72.33,
        "昨收价": 73.02,
        "总市值": 2676058797.0,
        "市盈率": 12.98,
        "成交量": 454211.0,
        "成交额": 33019532.0,
        "振幅": 1.45,
        "换手率": 1.23,
        "symbol": "106.CRI"
    },
    {
        "序号": 9243,
        "name": "Vanguard Total World Bond ETF",
        "最新价": 68.51,
        "涨跌额": -0.33,
        "涨跌幅": -0.48,
        "开盘价": 68.47,
        "最高价": 68.58,
        "最低价": 68.42,
        "昨收价": 68.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 41014.0,
        "成交额": 2809669.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "105.BNDW"
    },
    {
        "序号": 9244,
        "name": "挪威邮轮",
        "最新价": 18.68,
        "涨跌额": -0.09,
        "涨跌幅": -0.48,
        "开盘价": 18.83,
        "最高价": 18.96,
        "最低价": 18.59,
        "昨收价": 18.77,
        "总市值": 7946937767.0,
        "市盈率": -37.88,
        "成交量": 15742390.0,
        "成交额": 294822560.0,
        "振幅": 1.97,
        "换手率": 3.7,
        "symbol": "106.NCLH"
    },
    {
        "序号": 9245,
        "name": "FT Cboe Vest Gold Strategy Targ",
        "最新价": 18.68,
        "涨跌额": -0.09,
        "涨跌幅": -0.48,
        "开盘价": 18.69,
        "最高价": 18.7,
        "最低价": 18.58,
        "昨收价": 18.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 21983.0,
        "成交额": 409891.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.IGLD"
    },
    {
        "序号": 9246,
        "name": "RiverNorth Managed Duration Mun",
        "最新价": 14.51,
        "涨跌额": -0.07,
        "涨跌幅": -0.48,
        "开盘价": 14.61,
        "最高价": 14.61,
        "最低价": 14.46,
        "昨收价": 14.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 36219.0,
        "成交额": 526099.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.RMM"
    },
    {
        "序号": 9247,
        "name": "格林布赖尔",
        "最新价": 39.34,
        "涨跌额": -0.19,
        "涨跌幅": -0.48,
        "开盘价": 39.62,
        "最高价": 40.08,
        "最低价": 38.96,
        "昨收价": 39.53,
        "总市值": 1223571091.0,
        "市盈率": 19.58,
        "成交量": 107177.0,
        "成交额": 4223411.0,
        "振幅": 2.83,
        "换手率": 0.34,
        "symbol": "106.GBX"
    },
    {
        "序号": 9248,
        "name": "Schwab US Aggregate Bond ETF",
        "最新价": 45.54,
        "涨跌额": -0.22,
        "涨跌幅": -0.48,
        "开盘价": 45.53,
        "最高价": 45.6,
        "最低价": 45.45,
        "昨收价": 45.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 894092.0,
        "成交额": 40717477.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.SCHZ"
    },
    {
        "序号": 9249,
        "name": "Arbutus Biopharma Corp",
        "最新价": 2.07,
        "涨跌额": -0.01,
        "涨跌幅": -0.48,
        "开盘价": 2.07,
        "最高价": 2.09,
        "最低价": 2.02,
        "昨收价": 2.08,
        "总市值": 347129161.0,
        "市盈率": -4.6,
        "成交量": 324533.0,
        "成交额": 670127.0,
        "振幅": 3.37,
        "换手率": 0.19,
        "symbol": "105.ABUS"
    },
    {
        "序号": 9250,
        "name": "SLM Corp Series B Pfd",
        "最新价": 68.2,
        "涨跌额": -0.33,
        "涨跌幅": -0.48,
        "开盘价": 68.79,
        "最高价": 68.79,
        "最低价": 68.14,
        "昨收价": 68.53,
        "总市值": 171229467.0,
        "市盈率": null,
        "成交量": 16381.0,
        "成交额": 1118398.0,
        "振幅": 0.95,
        "换手率": 0.65,
        "symbol": "105.SLMBP"
    },
    {
        "序号": 9251,
        "name": "Pioneer Municipal High Income T",
        "最新价": 8.26,
        "涨跌额": -0.04,
        "涨跌幅": -0.48,
        "开盘价": 8.24,
        "最高价": 8.29,
        "最低价": 8.23,
        "昨收价": 8.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 67287.0,
        "成交额": 555215.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "106.MHI"
    },
    {
        "序号": 9252,
        "name": "Huntington Bancshares Inc Serie",
        "最新价": 20.63,
        "涨跌额": -0.1,
        "涨跌幅": -0.48,
        "开盘价": 20.89,
        "最高价": 20.93,
        "最低价": 20.57,
        "昨收价": 20.73,
        "总市值": 144410.0,
        "市盈率": null,
        "成交量": 24147.0,
        "成交额": 502495.0,
        "振幅": 1.74,
        "换手率": 344.96,
        "symbol": "105.HBANM"
    },
    {
        "序号": 9253,
        "name": "Xtrackers Emerging Markets Carb",
        "最新价": 26.81,
        "涨跌额": -0.13,
        "涨跌幅": -0.48,
        "开盘价": 26.78,
        "最高价": 26.82,
        "最低价": 26.73,
        "昨收价": 26.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 1408.0,
        "成交额": 37716.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.EMCR"
    },
    {
        "序号": 9254,
        "name": "SPDR Wells Fargo Preferred Stoc",
        "最新价": 32.98,
        "涨跌额": -0.16,
        "涨跌幅": -0.48,
        "开盘价": 32.99,
        "最高价": 33.13,
        "最低价": 32.88,
        "昨收价": 33.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 365045.0,
        "成交额": 12042382.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.PSK"
    },
    {
        "序号": 9255,
        "name": "iShares ESG USD Corporate Bond ",
        "最新价": 22.66,
        "涨跌额": -0.11,
        "涨跌幅": -0.48,
        "开盘价": 22.65,
        "最高价": 22.7,
        "最低价": 22.63,
        "昨收价": 22.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 180944.0,
        "成交额": 4099913.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.SUSC"
    },
    {
        "序号": 9256,
        "name": "免疫子制药",
        "最新价": 2.06,
        "涨跌额": -0.01,
        "涨跌幅": -0.48,
        "开盘价": 2.05,
        "最高价": 2.06,
        "最低价": 2.05,
        "昨收价": 2.07,
        "总市值": 11731615.0,
        "市盈率": -4.66,
        "成交量": 3333.0,
        "成交额": 6834.0,
        "振幅": 0.48,
        "换手率": 0.06,
        "symbol": "105.IMRN"
    },
    {
        "序号": 9257,
        "name": "American Equity Investment Life",
        "最新价": 24.72,
        "涨跌额": -0.12,
        "涨跌幅": -0.48,
        "开盘价": 24.68,
        "最高价": 24.95,
        "最低价": 24.5,
        "昨收价": 24.84,
        "总市值": 296640.0,
        "市盈率": null,
        "成交量": 22720.0,
        "成交额": 561382.0,
        "振幅": 1.81,
        "换手率": 189.33,
        "symbol": "106.AEL_B"
    },
    {
        "序号": 9258,
        "name": "Saul Centers Inc",
        "最新价": 39.13,
        "涨跌额": -0.19,
        "涨跌幅": -0.48,
        "开盘价": 39.27,
        "最高价": 39.27,
        "最低价": 38.42,
        "昨收价": 39.32,
        "总市值": 936921520.0,
        "市盈率": 18.25,
        "成交量": 37035.0,
        "成交额": 1440234.0,
        "振幅": 2.16,
        "换手率": 0.15,
        "symbol": "106.BFS"
    },
    {
        "序号": 9259,
        "name": "Omnicell Inc",
        "最新价": 34.98,
        "涨跌额": -0.17,
        "涨跌幅": -0.48,
        "开盘价": 34.84,
        "最高价": 35.61,
        "最低价": 34.63,
        "昨收价": 35.15,
        "总市值": 1590518772.0,
        "市盈率": -46.23,
        "成交量": 460955.0,
        "成交额": 16152389.0,
        "振幅": 2.79,
        "换手率": 1.01,
        "symbol": "105.OMCL"
    },
    {
        "序号": 9260,
        "name": "Northern Trust Corp Series E Pf",
        "最新价": 22.62,
        "涨跌额": -0.11,
        "涨跌幅": -0.48,
        "开盘价": 22.73,
        "最高价": 22.77,
        "最低价": 22.31,
        "昨收价": 22.73,
        "总市值": 361920.0,
        "市盈率": null,
        "成交量": 19280.0,
        "成交额": 435350.0,
        "振幅": 2.02,
        "换手率": 120.5,
        "symbol": "105.NTRSO"
    },
    {
        "序号": 9261,
        "name": "Fifth Third Bancorp Series K Pf",
        "最新价": 22.62,
        "涨跌额": -0.11,
        "涨跌幅": -0.48,
        "开盘价": 22.88,
        "最高价": 23.9,
        "最低价": 22.39,
        "昨收价": 22.73,
        "总市值": 226200.0,
        "市盈率": null,
        "成交量": 22918.0,
        "成交额": 520937.0,
        "振幅": 6.64,
        "换手率": 229.18,
        "symbol": "105.FITBO"
    },
    {
        "序号": 9262,
        "name": "iShares GNMA Bond ETF",
        "最新价": 43.17,
        "涨跌额": -0.21,
        "涨跌幅": -0.48,
        "开盘价": 43.06,
        "最高价": 43.22,
        "最低价": 43.06,
        "昨收价": 43.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 52425.0,
        "成交额": 2263400.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.GNMA"
    },
    {
        "序号": 9263,
        "name": "普雷斯蒂奇",
        "最新价": 59.58,
        "涨跌额": -0.29,
        "涨跌幅": -0.48,
        "开盘价": 60.15,
        "最高价": 60.27,
        "最低价": 59.44,
        "昨收价": 59.87,
        "总市值": 2956275294.0,
        "市盈率": -36.16,
        "成交量": 158533.0,
        "成交额": 9459187.0,
        "振幅": 1.39,
        "换手率": 0.32,
        "symbol": "106.PBH"
    },
    {
        "序号": 9264,
        "name": "Social Leverage Acquisition Cor",
        "最新价": 10.26,
        "涨跌额": -0.05,
        "涨跌幅": -0.48,
        "开盘价": 9.9,
        "最高价": 10.26,
        "最低价": 9.9,
        "昨收价": 10.31,
        "总市值": 104468695.0,
        "市盈率": 59.71,
        "成交量": 3858.0,
        "成交额": 38706.0,
        "振幅": 3.49,
        "换手率": null,
        "symbol": "105.SLACU"
    },
    {
        "序号": 9265,
        "name": "全债市指数ETF-Vanguard",
        "最新价": 71.82,
        "涨跌额": -0.35,
        "涨跌幅": -0.48,
        "开盘价": 71.87,
        "最高价": 71.95,
        "最低价": 71.71,
        "昨收价": 72.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 7349892.0,
        "成交额": 528015312.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.BND"
    },
    {
        "序号": 9266,
        "name": "美国债ETF-iShares",
        "最新价": 22.57,
        "涨跌额": -0.11,
        "涨跌幅": -0.49,
        "开盘价": 22.58,
        "最高价": 22.62,
        "最低价": 22.53,
        "昨收价": 22.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 17646728.0,
        "成交额": 398247344.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.GOVT"
    },
    {
        "序号": 9267,
        "name": "Ready Capital Corp Series E Pfd",
        "最新价": 18.46,
        "涨跌额": -0.09,
        "涨跌幅": -0.49,
        "开盘价": 18.59,
        "最高价": 18.59,
        "最低价": 18.46,
        "昨收价": 18.55,
        "总市值": 84916000.0,
        "市盈率": null,
        "成交量": 13883.0,
        "成交额": 256981.0,
        "振幅": 0.7,
        "换手率": 0.3,
        "symbol": "106.RC_E"
    },
    {
        "序号": 9268,
        "name": "Eaton Vance Floating-Rate Incom",
        "最新价": 12.3,
        "涨跌额": -0.06,
        "涨跌幅": -0.49,
        "开盘价": 12.34,
        "最高价": 12.39,
        "最低价": 12.3,
        "昨收价": 12.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 60534.0,
        "成交额": 747335.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "106.EFT"
    },
    {
        "序号": 9269,
        "name": "iShares iBonds Dec 2028 Term Co",
        "最新价": 24.59,
        "涨跌额": -0.12,
        "涨跌幅": -0.49,
        "开盘价": 24.62,
        "最高价": 24.62,
        "最低价": 24.57,
        "昨收价": 24.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 373862.0,
        "成交额": 9193867.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.IBDT"
    },
    {
        "序号": 9270,
        "name": "Simplify High Yield PLUS Credit",
        "最新价": 22.52,
        "涨跌额": -0.11,
        "涨跌幅": -0.49,
        "开盘价": 22.55,
        "最高价": 22.55,
        "最低价": 22.47,
        "昨收价": 22.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 13202.0,
        "成交额": 297179.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.CDX"
    },
    {
        "序号": 9271,
        "name": "亚当斯资源与能源",
        "最新价": 26.6,
        "涨跌额": -0.13,
        "涨跌幅": -0.49,
        "开盘价": 26.45,
        "最高价": 26.95,
        "最低价": 26.45,
        "昨收价": 26.73,
        "总市值": 67735331.0,
        "市盈率": -10.96,
        "成交量": 4102.0,
        "成交额": 109087.0,
        "振幅": 1.87,
        "换手率": 0.16,
        "symbol": "107.AE"
    },
    {
        "序号": 9272,
        "name": "Berenson Acquisition Corp I_A",
        "最新价": 10.23,
        "涨跌额": -0.05,
        "涨跌幅": -0.49,
        "开盘价": 10.27,
        "最高价": 10.27,
        "最低价": 10.2,
        "昨收价": 10.28,
        "总市值": 81256563.0,
        "市盈率": 21.97,
        "成交量": 423201.0,
        "成交额": 4318223.0,
        "振幅": 0.68,
        "换手率": 5.33,
        "symbol": "107.BACA"
    },
    {
        "序号": 9273,
        "name": "Sensient Technologies Corp",
        "最新价": 61.37,
        "涨跌额": -0.3,
        "涨跌幅": -0.49,
        "开盘价": 61.77,
        "最高价": 62.27,
        "最低价": 61.14,
        "昨收价": 61.67,
        "总市值": 2592852367.0,
        "市盈率": 20.2,
        "成交量": 114149.0,
        "成交额": 7007544.0,
        "振幅": 1.83,
        "换手率": 0.27,
        "symbol": "106.SXT"
    },
    {
        "序号": 9274,
        "name": "Franklin U.S. Treasury Bond ETF",
        "最新价": 20.42,
        "涨跌额": -0.1,
        "涨跌幅": -0.49,
        "开盘价": 20.46,
        "最高价": 20.46,
        "最低价": 20.39,
        "昨收价": 20.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 24558.0,
        "成交额": 501268.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.FLGV"
    },
    {
        "序号": 9275,
        "name": "iShares Aaa A Rated Corporate B",
        "最新价": 46.95,
        "涨跌额": -0.23,
        "涨跌幅": -0.49,
        "开盘价": 46.96,
        "最高价": 47.02,
        "最低价": 46.87,
        "昨收价": 47.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 125795.0,
        "成交额": 5904207.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.QLTA"
    },
    {
        "序号": 9276,
        "name": "OneAscent Core Plus Bond ETF",
        "最新价": 22.43,
        "涨跌额": -0.11,
        "涨跌幅": -0.49,
        "开盘价": 22.46,
        "最高价": 22.46,
        "最低价": 22.38,
        "昨收价": 22.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 35936.0,
        "成交额": 806370.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.OACP"
    },
    {
        "序号": 9277,
        "name": "First Trust Intermediate Durati",
        "最新价": 20.35,
        "涨跌额": -0.1,
        "涨跌幅": -0.49,
        "开盘价": 20.38,
        "最高价": 20.38,
        "最低价": 20.34,
        "昨收价": 20.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 4782.0,
        "成交额": 97363.0,
        "振幅": 0.2,
        "换手率": null,
        "symbol": "107.FIIG"
    },
    {
        "序号": 9278,
        "name": "林肯国民(优先股)",
        "最新价": 26.45,
        "涨跌额": -0.13,
        "涨跌幅": -0.49,
        "开盘价": 26.65,
        "最高价": 26.67,
        "最低价": 26.37,
        "昨收价": 26.58,
        "总市值": 529000.0,
        "市盈率": null,
        "成交量": 30821.0,
        "成交额": 815691.0,
        "振幅": 1.13,
        "换手率": 154.1,
        "symbol": "106.LNC_D"
    },
    {
        "序号": 9279,
        "name": "亚历山大",
        "最新价": 189.0,
        "涨跌额": -0.93,
        "涨跌幅": -0.49,
        "开盘价": 191.95,
        "最高价": 191.95,
        "最低价": 188.35,
        "昨收价": 189.93,
        "总市值": 965277810.0,
        "市盈率": 9.72,
        "成交量": 11162.0,
        "成交额": 2112056.0,
        "振幅": 1.9,
        "换手率": 0.22,
        "symbol": "106.ALX"
    },
    {
        "序号": 9280,
        "name": "Standard Lithium Ltd",
        "最新价": 2.03,
        "涨跌额": -0.01,
        "涨跌幅": -0.49,
        "开盘价": 2.01,
        "最高价": 2.1,
        "最低价": 2.0,
        "昨收价": 2.04,
        "总市值": 350889960.0,
        "市盈率": -9.25,
        "成交量": 1325436.0,
        "成交额": 2729447.0,
        "振幅": 4.9,
        "换手率": 0.77,
        "symbol": "107.SLI"
    },
    {
        "序号": 9281,
        "name": "Destra Multi-Alternative Fund",
        "最新价": 6.09,
        "涨跌额": -0.03,
        "涨跌幅": -0.49,
        "开盘价": 6.14,
        "最高价": 6.14,
        "最低价": 6.08,
        "昨收价": 6.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 35956.0,
        "成交额": 219329.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "106.DMA"
    },
    {
        "序号": 9282,
        "name": "克罗格",
        "最新价": 44.58,
        "涨跌额": -0.22,
        "涨跌幅": -0.49,
        "开盘价": 44.87,
        "最高价": 45.19,
        "最低价": 44.55,
        "昨收价": 44.8,
        "总市值": 32071897267.0,
        "市盈率": 17.07,
        "成交量": 2819786.0,
        "成交额": 125968569.0,
        "振幅": 1.43,
        "换手率": 0.39,
        "symbol": "106.KR"
    },
    {
        "序号": 9283,
        "name": "Nuveen New York Municipal Value",
        "最新价": 8.1,
        "涨跌额": -0.04,
        "涨跌幅": -0.49,
        "开盘价": 8.12,
        "最高价": 8.14,
        "最低价": 8.06,
        "昨收价": 8.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 69346.0,
        "成交额": 561276.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "106.NNY"
    },
    {
        "序号": 9284,
        "name": "Invesco BulletShares 2030 Corpo",
        "最新价": 16.2,
        "涨跌额": -0.08,
        "涨跌幅": -0.49,
        "开盘价": 16.22,
        "最高价": 16.23,
        "最低价": 16.18,
        "昨收价": 16.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 409271.0,
        "成交额": 6627165.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.BSCU"
    },
    {
        "序号": 9285,
        "name": "尼索思",
        "最新价": 26.3,
        "涨跌额": -0.13,
        "涨跌幅": -0.49,
        "开盘价": 26.44,
        "最高价": 26.48,
        "最低价": 26.09,
        "昨收价": 26.43,
        "总市值": 10872826098.0,
        "市盈率": 15.07,
        "成交量": 4011734.0,
        "成交额": 105311477.0,
        "振幅": 1.48,
        "换手率": 0.97,
        "symbol": "106.NI"
    },
    {
        "序号": 9286,
        "name": "瑞士法郎ETF-CurrencyShares",
        "最新价": 101.14,
        "涨跌额": -0.5,
        "涨跌幅": -0.49,
        "开盘价": 101.03,
        "最高价": 101.41,
        "最低价": 100.91,
        "昨收价": 101.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 6062.0,
        "成交额": 612708.0,
        "振幅": 0.49,
        "换手率": null,
        "symbol": "107.FXF"
    },
    {
        "序号": 9287,
        "name": "KraneShares MSCI One Belt One R",
        "最新价": 20.21,
        "涨跌额": -0.1,
        "涨跌幅": -0.49,
        "开盘价": 20.2,
        "最高价": 20.24,
        "最低价": 20.18,
        "昨收价": 20.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 1248.0,
        "成交额": 25221.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.OBOR"
    },
    {
        "序号": 9288,
        "name": "Sabra Health Care REIT Inc",
        "最新价": 14.14,
        "涨跌额": -0.07,
        "涨跌幅": -0.49,
        "开盘价": 14.19,
        "最高价": 14.26,
        "最低价": 14.06,
        "昨收价": 14.21,
        "总市值": 3269444055.0,
        "市盈率": -37.01,
        "成交量": 1252849.0,
        "成交额": 17696772.0,
        "振幅": 1.41,
        "换手率": 0.54,
        "symbol": "105.SBRA"
    },
    {
        "序号": 9289,
        "name": "AMMO Inc",
        "最新价": 2.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.49,
        "开盘价": 2.02,
        "最高价": 2.05,
        "最低价": 2.0,
        "昨收价": 2.03,
        "总市值": 239290701.0,
        "市盈率": -15.31,
        "成交量": 863937.0,
        "成交额": 1744075.0,
        "振幅": 2.46,
        "换手率": 0.73,
        "symbol": "105.POWW"
    },
    {
        "序号": 9290,
        "name": "iShares Intermediate-Term Corpo",
        "最新价": 50.5,
        "涨跌额": -0.25,
        "涨跌幅": -0.49,
        "开盘价": 50.5,
        "最高价": 50.6,
        "最低价": 50.44,
        "昨收价": 50.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 2243688.0,
        "成交额": 113317461.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "105.IGIB"
    },
    {
        "序号": 9291,
        "name": "GLOBALFOUNDRIES Inc",
        "最新价": 52.5,
        "涨跌额": -0.26,
        "涨跌幅": -0.49,
        "开盘价": 52.38,
        "最高价": 53.33,
        "最低价": 52.19,
        "昨收价": 52.76,
        "总市值": 29042947500.0,
        "市盈率": 20.58,
        "成交量": 2969017.0,
        "成交额": 155901935.0,
        "振幅": 2.16,
        "换手率": 0.54,
        "symbol": "105.GFS"
    },
    {
        "序号": 9292,
        "name": "3-7年ETF-iShares",
        "最新价": 115.09,
        "涨跌额": -0.57,
        "涨跌幅": -0.49,
        "开盘价": 115.19,
        "最高价": 115.29,
        "最低价": 114.96,
        "昨收价": 115.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 2740824.0,
        "成交额": 315495920.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "105.IEI"
    },
    {
        "序号": 9293,
        "name": "Capital Group Core Plus Income ",
        "最新价": 22.2,
        "涨跌额": -0.11,
        "涨跌幅": -0.49,
        "开盘价": 22.18,
        "最高价": 22.2,
        "最低价": 22.12,
        "昨收价": 22.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 371989.0,
        "成交额": 8245156.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.CGCP"
    },
    {
        "序号": 9294,
        "name": "Global X Data Center REITs & Di",
        "最新价": 14.12,
        "涨跌额": -0.07,
        "涨跌幅": -0.49,
        "开盘价": 14.18,
        "最高价": 14.2,
        "最低价": 14.01,
        "昨收价": 14.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 51506.0,
        "成交额": 726149.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "105.VPN"
    },
    {
        "序号": 9295,
        "name": "GAMCO Natural Resources, Gold &",
        "最新价": 22.12,
        "涨跌额": -0.11,
        "涨跌幅": -0.49,
        "开盘价": 22.31,
        "最高价": 22.31,
        "最低价": 22.12,
        "昨收价": 22.23,
        "总市值": 25821517.0,
        "市盈率": null,
        "成交量": 5152.0,
        "成交额": 114310.0,
        "振幅": 0.85,
        "换手率": 0.44,
        "symbol": "106.GNT_A"
    },
    {
        "序号": 9296,
        "name": "新兴市场ETF-Vanguard FTSE",
        "最新价": 40.21,
        "涨跌额": -0.2,
        "涨跌幅": -0.49,
        "开盘价": 40.13,
        "最高价": 40.31,
        "最低价": 40.05,
        "昨收价": 40.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 7744269.0,
        "成交额": 311110720.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "107.VWO"
    },
    {
        "序号": 9297,
        "name": "ProShares Short Financials",
        "最新价": 12.06,
        "涨跌额": -0.06,
        "涨跌幅": -0.5,
        "开盘价": 12.07,
        "最高价": 12.11,
        "最低价": 12.04,
        "昨收价": 12.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 16768.0,
        "成交额": 202352.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.SEF"
    },
    {
        "序号": 9298,
        "name": "EPR Properties Series C Pfd",
        "最新价": 20.1,
        "涨跌额": -0.1,
        "涨跌幅": -0.5,
        "开盘价": 20.03,
        "最高价": 20.15,
        "最低价": 20.03,
        "昨收价": 20.2,
        "总市值": 108397612.0,
        "市盈率": null,
        "成交量": 10318.0,
        "成交额": 207575.0,
        "振幅": 0.59,
        "换手率": 0.19,
        "symbol": "106.EPR_C"
    },
    {
        "序号": 9299,
        "name": "Invesco BulletShares 2029 Corpo",
        "最新价": 18.08,
        "涨跌额": -0.09,
        "涨跌幅": -0.5,
        "开盘价": 18.09,
        "最高价": 18.11,
        "最低价": 18.07,
        "昨收价": 18.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 243724.0,
        "成交额": 4405878.0,
        "振幅": 0.22,
        "换手率": null,
        "symbol": "105.BSCT"
    },
    {
        "序号": 9300,
        "name": "DoubleLine Mortgage ETF",
        "最新价": 48.2,
        "涨跌额": -0.24,
        "涨跌幅": -0.5,
        "开盘价": 48.25,
        "最高价": 48.25,
        "最低价": 48.07,
        "昨收价": 48.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 587734.0,
        "成交额": 28328981.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.DMBS"
    },
    {
        "序号": 9301,
        "name": "Ascent Industries Co",
        "最新价": 8.03,
        "涨跌额": -0.04,
        "涨跌幅": -0.5,
        "开盘价": 7.91,
        "最高价": 8.07,
        "最低价": 7.88,
        "昨收价": 8.07,
        "总市值": 81308030.0,
        "市盈率": -2.16,
        "成交量": 17972.0,
        "成交额": 143174.0,
        "振幅": 2.35,
        "换手率": 0.18,
        "symbol": "105.ACNT"
    },
    {
        "序号": 9302,
        "name": "CVR能源",
        "最新价": 30.09,
        "涨跌额": -0.15,
        "涨跌幅": -0.5,
        "开盘价": 30.55,
        "最高价": 30.79,
        "最低价": 30.01,
        "昨收价": 30.24,
        "总市值": 3024965724.0,
        "市盈率": 3.83,
        "成交量": 741601.0,
        "成交额": 22400049.0,
        "振幅": 2.58,
        "换手率": 0.74,
        "symbol": "106.CVI"
    },
    {
        "序号": 9303,
        "name": "Sprouts Farmers Market Inc",
        "最新价": 46.02,
        "涨跌额": -0.23,
        "涨跌幅": -0.5,
        "开盘价": 46.19,
        "最高价": 46.53,
        "最低价": 45.76,
        "昨收价": 46.25,
        "总市值": 4673828108.0,
        "市盈率": 18.41,
        "成交量": 860011.0,
        "成交额": 39575976.0,
        "振幅": 1.66,
        "换手率": 0.85,
        "symbol": "105.SFM"
    },
    {
        "序号": 9304,
        "name": "Zevia PBC-A",
        "最新价": 2.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.5,
        "开盘价": 2.01,
        "最高价": 2.04,
        "最低价": 1.87,
        "昨收价": 2.01,
        "总市值": 142680670.0,
        "市盈率": -7.66,
        "成交量": 173420.0,
        "成交额": 336830.0,
        "振幅": 8.46,
        "换手率": 0.24,
        "symbol": "106.ZVIA"
    },
    {
        "序号": 9305,
        "name": "Energous Corp",
        "最新价": 2.0,
        "涨跌额": -0.01,
        "涨跌幅": -0.5,
        "开盘价": 2.0,
        "最高价": 2.05,
        "最低价": 1.98,
        "昨收价": 2.01,
        "总市值": 10420722.0,
        "市盈率": -0.5,
        "成交量": 20213.0,
        "成交额": 40694.0,
        "振幅": 3.48,
        "换手率": 0.39,
        "symbol": "105.WATT"
    },
    {
        "序号": 9306,
        "name": "Intapp Inc",
        "最新价": 36.0,
        "涨跌额": -0.18,
        "涨跌幅": -0.5,
        "开盘价": 36.01,
        "最高价": 36.32,
        "最低价": 35.41,
        "昨收价": 36.18,
        "总市值": 2507610456.0,
        "市盈率": -38.76,
        "成交量": 473122.0,
        "成交额": 16990068.0,
        "振幅": 2.52,
        "换手率": 0.68,
        "symbol": "105.INTA"
    },
    {
        "序号": 9307,
        "name": "奥洛兹美医疗",
        "最新价": 39.95,
        "涨跌额": -0.2,
        "涨跌幅": -0.5,
        "开盘价": 40.02,
        "最高价": 41.06,
        "最低价": 39.47,
        "昨收价": 40.15,
        "总市值": 5277319495.0,
        "市盈率": 20.78,
        "成交量": 732927.0,
        "成交额": 29440315.0,
        "振幅": 3.96,
        "换手率": 0.55,
        "symbol": "105.HALO"
    },
    {
        "序号": 9308,
        "name": "Franklin FTSE India ETF",
        "最新价": 33.93,
        "涨跌额": -0.17,
        "涨跌幅": -0.5,
        "开盘价": 33.9,
        "最高价": 34.01,
        "最低价": 33.78,
        "昨收价": 34.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1378736.0,
        "成交额": 46768455.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.FLIN"
    },
    {
        "序号": 9309,
        "name": "SPDR S&P Kensho Clean Power ETF",
        "最新价": 65.85,
        "涨跌额": -0.33,
        "涨跌幅": -0.5,
        "开盘价": 66.12,
        "最高价": 66.81,
        "最低价": 65.2,
        "昨收价": 66.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 35293.0,
        "成交额": 2322751.0,
        "振幅": 2.43,
        "换手率": null,
        "symbol": "107.CNRG"
    },
    {
        "序号": 9310,
        "name": "Dimensional Emerging Markets Co",
        "最新价": 23.91,
        "涨跌额": -0.12,
        "涨跌幅": -0.5,
        "开盘价": 23.87,
        "最高价": 23.97,
        "最低价": 23.83,
        "昨收价": 24.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1309616.0,
        "成交额": 31297921.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.DFEM"
    },
    {
        "序号": 9311,
        "name": "WisdomTree Emerging Markets Sma",
        "最新价": 47.79,
        "涨跌额": -0.24,
        "涨跌幅": -0.5,
        "开盘价": 47.75,
        "最高价": 47.93,
        "最低价": 47.61,
        "昨收价": 48.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 169172.0,
        "成交额": 8073270.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.DGS"
    },
    {
        "序号": 9312,
        "name": "Tenaya Therapeutics Inc",
        "最新价": 1.99,
        "涨跌额": -0.01,
        "涨跌幅": -0.5,
        "开盘价": 2.03,
        "最高价": 2.08,
        "最低价": 1.93,
        "昨收价": 2.0,
        "总市值": 135540928.0,
        "市盈率": -1.06,
        "成交量": 294765.0,
        "成交额": 585904.0,
        "振幅": 7.5,
        "换手率": 0.43,
        "symbol": "105.TNYA"
    },
    {
        "序号": 9313,
        "name": "abrdn Australia Equity Fund Inc",
        "最新价": 3.98,
        "涨跌额": -0.02,
        "涨跌幅": -0.5,
        "开盘价": 3.99,
        "最高价": 4.03,
        "最低价": 3.97,
        "昨收价": 4.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 119064.0,
        "成交额": 474677.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "107.IAF"
    },
    {
        "序号": 9314,
        "name": "VanEck HIP Sustainable Muni ETF",
        "最新价": 45.71,
        "涨跌额": -0.23,
        "涨跌幅": -0.5,
        "开盘价": 45.82,
        "最高价": 45.82,
        "最低价": 45.68,
        "昨收价": 45.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 19448.0,
        "成交额": 889249.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.SMI"
    },
    {
        "序号": 9315,
        "name": "iShares iBonds Dec 2028 Term Tr",
        "最新价": 21.86,
        "涨跌额": -0.11,
        "涨跌幅": -0.5,
        "开盘价": 21.88,
        "最高价": 21.89,
        "最低价": 21.83,
        "昨收价": 21.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 88765.0,
        "成交额": 1940279.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "105.IBTI"
    },
    {
        "序号": 9316,
        "name": "Granite Ridge Resources Inc",
        "最新价": 5.96,
        "涨跌额": -0.03,
        "涨跌幅": -0.5,
        "开盘价": 5.96,
        "最高价": 6.06,
        "最低价": 5.95,
        "昨收价": 5.99,
        "总市值": 792697785.0,
        "市盈率": 2.49,
        "成交量": 522804.0,
        "成交额": 3134274.0,
        "振幅": 1.84,
        "换手率": 0.39,
        "symbol": "106.GRNT"
    },
    {
        "序号": 9317,
        "name": "Capital One Financial Corp Seri",
        "最新价": 17.86,
        "涨跌额": -0.09,
        "涨跌幅": -0.5,
        "开盘价": 17.88,
        "最高价": 17.94,
        "最低价": 17.62,
        "昨收价": 17.95,
        "总市值": 22325000.0,
        "市盈率": null,
        "成交量": 94313.0,
        "成交额": 1679686.0,
        "振幅": 1.78,
        "换手率": 7.55,
        "symbol": "106.COF_J"
    },
    {
        "序号": 9318,
        "name": "英国国家电网",
        "最新价": 67.47,
        "涨跌额": -0.34,
        "涨跌幅": -0.5,
        "开盘价": 67.15,
        "最高价": 67.58,
        "最低价": 67.07,
        "昨收价": 67.81,
        "总市值": 49768274904.0,
        "市盈率": 5.18,
        "成交量": 336180.0,
        "成交额": 22630347.0,
        "振幅": 0.75,
        "换手率": 0.05,
        "symbol": "106.NGG"
    },
    {
        "序号": 9319,
        "name": "人民币债券ETF-VanEckVectors华夏",
        "最新价": 21.82,
        "涨跌额": -0.11,
        "涨跌幅": -0.5,
        "开盘价": 21.85,
        "最高价": 21.85,
        "最低价": 21.81,
        "昨收价": 21.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 2107.0,
        "成交额": 45970.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.CBON"
    },
    {
        "序号": 9320,
        "name": "美国全债市ETF-iShares",
        "最新价": 96.89,
        "涨跌额": -0.49,
        "涨跌幅": -0.5,
        "开盘价": 96.99,
        "最高价": 97.07,
        "最低价": 96.72,
        "昨收价": 97.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 6696783.0,
        "成交额": 648878080.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.AGG"
    },
    {
        "序号": 9321,
        "name": "JPMorgan BetaBuilders U.S. Aggr",
        "最新价": 45.47,
        "涨跌额": -0.23,
        "涨跌幅": -0.5,
        "开盘价": 45.47,
        "最高价": 45.52,
        "最低价": 45.39,
        "昨收价": 45.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 36935.0,
        "成交额": 1679029.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "107.BBAG"
    },
    {
        "序号": 9322,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 17.79,
        "涨跌额": -0.09,
        "涨跌幅": -0.5,
        "开盘价": 17.9,
        "最高价": 17.9,
        "最低价": 17.29,
        "昨收价": 17.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 16464.0,
        "成交额": 291149.0,
        "振幅": 3.41,
        "换手率": null,
        "symbol": "105.RILYT"
    },
    {
        "序号": 9323,
        "name": "忠实航空",
        "最新价": 75.11,
        "涨跌额": -0.38,
        "涨跌幅": -0.5,
        "开盘价": 75.05,
        "最高价": 75.69,
        "最低价": 73.17,
        "昨收价": 75.49,
        "总市值": 1382112630.0,
        "市盈率": 8.03,
        "成交量": 438591.0,
        "成交额": 32731783.0,
        "振幅": 3.34,
        "换手率": 2.38,
        "symbol": "105.ALGT"
    },
    {
        "序号": 9324,
        "name": "BNY Mellon Core Bond ETF",
        "最新价": 41.42,
        "涨跌额": -0.21,
        "涨跌幅": -0.5,
        "开盘价": 41.42,
        "最高价": 41.47,
        "最低价": 41.35,
        "昨收价": 41.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 43160.0,
        "成交额": 1787646.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.BKAG"
    },
    {
        "序号": 9325,
        "name": "加拿大太平洋堪萨斯城",
        "最新价": 72.89,
        "涨跌额": -0.37,
        "涨跌幅": -0.51,
        "开盘价": 73.4,
        "最高价": 74.34,
        "最低价": 72.78,
        "昨收价": 73.26,
        "总市值": 67918223394.0,
        "市盈率": 21.81,
        "成交量": 2698202.0,
        "成交额": 197799511.0,
        "振幅": 2.13,
        "换手率": 0.29,
        "symbol": "106.CP"
    },
    {
        "序号": 9326,
        "name": "AllianceBernstein Global High I",
        "最新价": 9.85,
        "涨跌额": -0.05,
        "涨跌幅": -0.51,
        "开盘价": 9.86,
        "最高价": 9.88,
        "最低价": 9.83,
        "昨收价": 9.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 145011.0,
        "成交额": 1428990.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "106.AWF"
    },
    {
        "序号": 9327,
        "name": "SP Plus Corp",
        "最新价": 51.2,
        "涨跌额": -0.26,
        "涨跌幅": -0.51,
        "开盘价": 51.37,
        "最高价": 51.54,
        "最低价": 51.0,
        "昨收价": 51.46,
        "总市值": 1006060083.0,
        "市盈率": 28.99,
        "成交量": 128553.0,
        "成交额": 6582775.0,
        "振幅": 1.05,
        "换手率": 0.65,
        "symbol": "105.SP"
    },
    {
        "序号": 9328,
        "name": "Schwab Emerging Markets Equity ",
        "最新价": 23.63,
        "涨跌额": -0.12,
        "涨跌幅": -0.51,
        "开盘价": 23.59,
        "最高价": 23.68,
        "最低价": 23.54,
        "昨收价": 23.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1917830.0,
        "成交额": 45252230.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.SCHE"
    },
    {
        "序号": 9329,
        "name": "NextEra Energy Inc Corporate Un",
        "最新价": 37.41,
        "涨跌额": -0.19,
        "涨跌幅": -0.51,
        "开盘价": 37.6,
        "最高价": 37.77,
        "最低价": 37.39,
        "昨收价": 37.6,
        "总市值": 76754386591.0,
        "市盈率": 10.07,
        "成交量": 41541.0,
        "成交额": 1560285.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "106.NEE_R"
    },
    {
        "序号": 9330,
        "name": "加拿大国家铁路",
        "最新价": 117.78,
        "涨跌额": -0.6,
        "涨跌幅": -0.51,
        "开盘价": 118.3,
        "最高价": 119.45,
        "最低价": 117.77,
        "昨收价": 118.38,
        "总市值": 76286106000.0,
        "市盈率": 20.78,
        "成交量": 806755.0,
        "成交额": 95493949.0,
        "振幅": 1.42,
        "换手率": 0.12,
        "symbol": "106.CNI"
    },
    {
        "序号": 9331,
        "name": "约克自来水",
        "最新价": 39.22,
        "涨跌额": -0.2,
        "涨跌幅": -0.51,
        "开盘价": 39.48,
        "最高价": 39.63,
        "最低价": 39.09,
        "昨收价": 39.42,
        "总市值": 561699466.0,
        "市盈率": 24.68,
        "成交量": 20522.0,
        "成交额": 805190.0,
        "振幅": 1.37,
        "换手率": 0.14,
        "symbol": "105.YORW"
    },
    {
        "序号": 9332,
        "name": "NextNav Inc",
        "最新价": 3.92,
        "涨跌额": -0.02,
        "涨跌幅": -0.51,
        "开盘价": 3.94,
        "最高价": 4.09,
        "最低价": 3.9,
        "昨收价": 3.94,
        "总市值": 431732567.0,
        "市盈率": -6.36,
        "成交量": 908490.0,
        "成交额": 3611601.0,
        "振幅": 4.82,
        "换手率": 0.82,
        "symbol": "105.NN"
    },
    {
        "序号": 9333,
        "name": "中期债券指数ETF-Vanguard",
        "最新价": 74.48,
        "涨跌额": -0.38,
        "涨跌幅": -0.51,
        "开盘价": 74.49,
        "最高价": 74.59,
        "最低价": 74.34,
        "昨收价": 74.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 3023371.0,
        "成交额": 225041725.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.BIV"
    },
    {
        "序号": 9334,
        "name": "ShockWave Medical Inc",
        "最新价": 174.2,
        "涨跌额": -0.89,
        "涨跌幅": -0.51,
        "开盘价": 173.88,
        "最高价": 176.6,
        "最低价": 173.4,
        "昨收价": 175.09,
        "总市值": 6427413850.0,
        "市盈率": 26.35,
        "成交量": 378658.0,
        "成交额": 66206447.0,
        "振幅": 1.83,
        "换手率": 1.03,
        "symbol": "105.SWAV"
    },
    {
        "序号": 9335,
        "name": "ProShares Ultra Utilities",
        "最新价": 52.79,
        "涨跌额": -0.27,
        "涨跌幅": -0.51,
        "开盘价": 52.75,
        "最高价": 52.79,
        "最低价": 52.2,
        "昨收价": 53.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 910.0,
        "成交额": 47788.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "107.UPW"
    },
    {
        "序号": 9336,
        "name": "TFI International Inc",
        "最新价": 115.3,
        "涨跌额": -0.59,
        "涨跌幅": -0.51,
        "开盘价": 115.81,
        "最高价": 117.71,
        "最低价": 115.3,
        "昨收价": 115.89,
        "总市值": 9908040310.0,
        "市盈率": 18.8,
        "成交量": 279272.0,
        "成交额": 32459457.0,
        "振幅": 2.08,
        "换手率": 0.32,
        "symbol": "106.TFII"
    },
    {
        "序号": 9337,
        "name": "Fidelity Total Bond ETF",
        "最新价": 44.88,
        "涨跌额": -0.23,
        "涨跌幅": -0.51,
        "开盘价": 44.93,
        "最高价": 44.95,
        "最低价": 44.77,
        "昨收价": 45.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 911107.0,
        "成交额": 40868100.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.FBND"
    },
    {
        "序号": 9338,
        "name": "ActivePassive Core Bond ETF",
        "最新价": 29.26,
        "涨跌额": -0.15,
        "涨跌幅": -0.51,
        "开盘价": 29.28,
        "最高价": 29.28,
        "最低价": 29.25,
        "昨收价": 29.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 23976.0,
        "成交额": 701510.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.APCB"
    },
    {
        "序号": 9339,
        "name": "Eaton Vance Risk-Managed Divers",
        "最新价": 7.8,
        "涨跌额": -0.04,
        "涨跌幅": -0.51,
        "开盘价": 7.8,
        "最高价": 7.82,
        "最低价": 7.73,
        "昨收价": 7.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 221549.0,
        "成交额": 1722428.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "106.ETJ"
    },
    {
        "序号": 9340,
        "name": "Costamare Inc Pfd-D",
        "最新价": 25.35,
        "涨跌额": -0.13,
        "涨跌幅": -0.51,
        "开盘价": 25.42,
        "最高价": 25.42,
        "最低价": 25.35,
        "昨收价": 25.48,
        "总市值": 101058840.0,
        "市盈率": null,
        "成交量": 749.0,
        "成交额": 19004.0,
        "振幅": 0.27,
        "换手率": 0.02,
        "symbol": "106.CMRE_D"
    },
    {
        "序号": 9341,
        "name": "Avantis Emerging Markets Equity",
        "最新价": 54.56,
        "涨跌额": -0.28,
        "涨跌幅": -0.51,
        "开盘价": 54.48,
        "最高价": 54.76,
        "最低价": 54.36,
        "昨收价": 54.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 259262.0,
        "成交额": 14144310.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.AVEM"
    },
    {
        "序号": 9342,
        "name": "Goldman Sachs Access U.S. Aggre",
        "最新价": 40.88,
        "涨跌额": -0.21,
        "涨跌幅": -0.51,
        "开盘价": 40.7,
        "最高价": 40.91,
        "最低价": 40.69,
        "昨收价": 41.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 41610.0,
        "成交额": 1698408.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.GCOR"
    },
    {
        "序号": 9343,
        "name": "Avantis Core Fixed Income ETF",
        "最新价": 40.86,
        "涨跌额": -0.21,
        "涨跌幅": -0.51,
        "开盘价": 40.88,
        "最高价": 40.91,
        "最低价": 40.8,
        "昨收价": 41.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 125189.0,
        "成交额": 5114004.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.AVIG"
    },
    {
        "序号": 9344,
        "name": "二倍做多房地产ETF-ProShares",
        "最新价": 56.39,
        "涨跌额": -0.29,
        "涨跌幅": -0.51,
        "开盘价": 56.5,
        "最高价": 56.5,
        "最低价": 55.6,
        "昨收价": 56.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 1915.0,
        "成交额": 107444.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "107.URE"
    },
    {
        "序号": 9345,
        "name": "Global X CleanTech ETF",
        "最新价": 9.72,
        "涨跌额": -0.05,
        "涨跌幅": -0.51,
        "开盘价": 9.72,
        "最高价": 9.88,
        "最低价": 9.65,
        "昨收价": 9.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 35496.0,
        "成交额": 345977.0,
        "振幅": 2.35,
        "换手率": null,
        "symbol": "105.CTEC"
    },
    {
        "序号": 9346,
        "name": "SPDR S&P Emerging Asia Pacific ",
        "最新价": 99.0,
        "涨跌额": -0.51,
        "涨跌幅": -0.51,
        "开盘价": 98.79,
        "最高价": 99.09,
        "最低价": 98.64,
        "昨收价": 99.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 7209.0,
        "成交额": 712515.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.GMF"
    },
    {
        "序号": 9347,
        "name": "Vanguard Intermediate-Term Trea",
        "最新价": 58.2,
        "涨跌额": -0.3,
        "涨跌幅": -0.51,
        "开盘价": 58.25,
        "最高价": 58.3,
        "最低价": 58.12,
        "昨收价": 58.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 3997643.0,
        "成交额": 232656561.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "105.VGIT"
    },
    {
        "序号": 9348,
        "name": "ProShares S&P Kensho Cleantech ",
        "最新价": 23.26,
        "涨跌额": -0.12,
        "涨跌幅": -0.51,
        "开盘价": 23.49,
        "最高价": 23.49,
        "最低价": 23.26,
        "昨收价": 23.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 1861.0,
        "成交额": 43710.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.CTEX"
    },
    {
        "序号": 9349,
        "name": "JPMorgan BetaBuilders U.S. Trea",
        "最新价": 96.82,
        "涨跌额": -0.5,
        "涨跌幅": -0.51,
        "开盘价": 96.78,
        "最高价": 96.86,
        "最低价": 96.78,
        "昨收价": 97.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 3405.0,
        "成交额": 329800.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.BBIB"
    },
    {
        "序号": 9350,
        "name": "Biglari Holdings Inc-B",
        "最新价": 150.94,
        "涨跌额": -0.78,
        "涨跌幅": -0.51,
        "开盘价": 149.92,
        "最高价": 151.0,
        "最低价": 149.92,
        "昨收价": 151.72,
        "总市值": 312240522.0,
        "市盈率": 15.33,
        "成交量": 694.0,
        "成交额": 104470.0,
        "振幅": 0.71,
        "换手率": 0.03,
        "symbol": "106.BH"
    },
    {
        "序号": 9351,
        "name": "Ecovyst Inc",
        "最新价": 9.67,
        "涨跌额": -0.05,
        "涨跌幅": -0.51,
        "开盘价": 9.67,
        "最高价": 9.82,
        "最低价": 9.63,
        "昨收价": 9.72,
        "总市值": 1122850375.0,
        "市盈率": 16.89,
        "成交量": 370609.0,
        "成交额": 3590050.0,
        "振幅": 1.95,
        "换手率": 0.32,
        "symbol": "106.ECVT"
    },
    {
        "序号": 9352,
        "name": "CNIC ICE U.S. Carbon Neutral Po",
        "最新价": 23.18,
        "涨跌额": -0.12,
        "涨跌幅": -0.52,
        "开盘价": 23.18,
        "最高价": 23.18,
        "最低价": 23.18,
        "昨收价": 23.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 86.0,
        "成交额": 1993.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AMPD"
    },
    {
        "序号": 9353,
        "name": "Pacer CSOP FTSE China A50 ETF",
        "最新价": 13.52,
        "涨跌额": -0.07,
        "涨跌幅": -0.52,
        "开盘价": 13.52,
        "最高价": 13.52,
        "最低价": 13.52,
        "昨收价": 13.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 152.0,
        "成交额": 2055.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.AFTY"
    },
    {
        "序号": 9354,
        "name": "PIMCO Investment Grade Corporat",
        "最新价": 94.6,
        "涨跌额": -0.49,
        "涨跌幅": -0.52,
        "开盘价": 94.65,
        "最高价": 94.8,
        "最低价": 94.52,
        "昨收价": 95.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 69757.0,
        "成交额": 6602204.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.CORP"
    },
    {
        "序号": 9355,
        "name": "Sypris Solutions Inc",
        "最新价": 1.93,
        "涨跌额": -0.01,
        "涨跌幅": -0.52,
        "开盘价": 1.95,
        "最高价": 1.95,
        "最低价": 1.92,
        "昨收价": 1.94,
        "总市值": 43337473.0,
        "市盈率": -114.95,
        "成交量": 5476.0,
        "成交额": 10604.0,
        "振幅": 1.55,
        "换手率": 0.02,
        "symbol": "105.SYPR"
    },
    {
        "序号": 9356,
        "name": "SPDR Portfolio Aggregate Bond E",
        "最新价": 25.04,
        "涨跌额": -0.13,
        "涨跌幅": -0.52,
        "开盘价": 25.07,
        "最高价": 25.1,
        "最低价": 24.99,
        "昨收价": 25.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 1928764.0,
        "成交额": 48284514.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.SPAB"
    },
    {
        "序号": 9357,
        "name": "Credit Suisse High Yield Bond F",
        "最新价": 1.92,
        "涨跌额": -0.01,
        "涨跌幅": -0.52,
        "开盘价": 1.93,
        "最高价": 1.95,
        "最低价": 1.91,
        "昨收价": 1.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 729533.0,
        "成交额": 1407383.0,
        "振幅": 2.07,
        "换手率": null,
        "symbol": "107.DHY"
    },
    {
        "序号": 9358,
        "name": "美国大西洋",
        "最新价": 1.91,
        "涨跌额": -0.01,
        "涨跌幅": -0.52,
        "开盘价": 1.92,
        "最高价": 1.92,
        "最低价": 1.9,
        "昨收价": 1.92,
        "总市值": 38968370.0,
        "市盈率": 12.56,
        "成交量": 5196.0,
        "成交额": 9912.0,
        "振幅": 1.04,
        "换手率": 0.03,
        "symbol": "105.AAME"
    },
    {
        "序号": 9359,
        "name": "Light & Wonder Inc",
        "最新价": 84.03,
        "涨跌额": -0.44,
        "涨跌幅": -0.52,
        "开盘价": 84.06,
        "最高价": 85.15,
        "最低价": 83.56,
        "昨收价": 84.47,
        "总市值": 7566682854.0,
        "市盈率": 60.05,
        "成交量": 552577.0,
        "成交额": 46490180.0,
        "振幅": 1.88,
        "换手率": 0.61,
        "symbol": "105.LNW"
    },
    {
        "序号": 9360,
        "name": "Matrix Service Co",
        "最新价": 9.53,
        "涨跌额": -0.05,
        "涨跌幅": -0.52,
        "开盘价": 9.47,
        "最高价": 9.63,
        "最低价": 9.41,
        "昨收价": 9.58,
        "总市值": 259346580.0,
        "市盈率": -5.29,
        "成交量": 116998.0,
        "成交额": 1113631.0,
        "振幅": 2.3,
        "换手率": 0.43,
        "symbol": "105.MTRX"
    },
    {
        "序号": 9361,
        "name": "Babcock & Wilcox Series A Pfd",
        "最新价": 11.38,
        "涨跌额": -0.06,
        "涨跌幅": -0.52,
        "开盘价": 11.46,
        "最高价": 11.5,
        "最低价": 11.09,
        "昨收价": 11.44,
        "总市值": 87273220.0,
        "市盈率": null,
        "成交量": 21685.0,
        "成交额": 246507.0,
        "振幅": 3.58,
        "换手率": 0.28,
        "symbol": "106.BW_A"
    },
    {
        "序号": 9362,
        "name": "Chuy’s Holdings Inc",
        "最新价": 36.0,
        "涨跌额": -0.19,
        "涨跌幅": -0.53,
        "开盘价": 36.18,
        "最高价": 36.42,
        "最低价": 35.48,
        "昨收价": 36.19,
        "总市值": 624692484.0,
        "市盈率": 21.91,
        "成交量": 66227.0,
        "成交额": 2386307.0,
        "振幅": 2.6,
        "换手率": 0.38,
        "symbol": "105.CHUY"
    },
    {
        "序号": 9363,
        "name": "RBB银行",
        "最新价": 17.04,
        "涨跌额": -0.09,
        "涨跌幅": -0.53,
        "开盘价": 17.07,
        "最高价": 17.26,
        "最低价": 17.0,
        "昨收价": 17.13,
        "总市值": 323679963.0,
        "市盈率": 6.75,
        "成交量": 112080.0,
        "成交额": 1913776.0,
        "振幅": 1.52,
        "换手率": 0.59,
        "symbol": "105.RBB"
    },
    {
        "序号": 9364,
        "name": "VanEck Green Metals ETF",
        "最新价": 22.71,
        "涨跌额": -0.12,
        "涨跌幅": -0.53,
        "开盘价": 22.56,
        "最高价": 22.73,
        "最低价": 22.52,
        "昨收价": 22.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 6634.0,
        "成交额": 150206.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.GMET"
    },
    {
        "序号": 9365,
        "name": "Unity Biotechnology Inc",
        "最新价": 1.89,
        "涨跌额": -0.01,
        "涨跌幅": -0.53,
        "开盘价": 1.88,
        "最高价": 1.95,
        "最低价": 1.85,
        "昨收价": 1.9,
        "总市值": 31723591.0,
        "市盈率": -0.6,
        "成交量": 33191.0,
        "成交额": 63074.0,
        "振幅": 5.26,
        "换手率": 0.2,
        "symbol": "105.UBX"
    },
    {
        "序号": 9366,
        "name": "Wolfspeed Inc",
        "最新价": 39.68,
        "涨跌额": -0.21,
        "涨跌幅": -0.53,
        "开盘价": 39.67,
        "最高价": 40.98,
        "最低价": 39.16,
        "昨收价": 39.89,
        "总市值": 4972908499.0,
        "市盈率": -7.11,
        "成交量": 2747392.0,
        "成交额": 109641396.0,
        "振幅": 4.56,
        "换手率": 2.19,
        "symbol": "106.WOLF"
    },
    {
        "序号": 9367,
        "name": "iShares MSCI China Multisector ",
        "最新价": 17.0,
        "涨跌额": -0.09,
        "涨跌幅": -0.53,
        "开盘价": 17.04,
        "最高价": 17.06,
        "最低价": 17.0,
        "昨收价": 17.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 1766.0,
        "成交额": 30109.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.TCHI"
    },
    {
        "序号": 9368,
        "name": "Preformed Line Products Co",
        "最新价": 126.55,
        "涨跌额": -0.67,
        "涨跌幅": -0.53,
        "开盘价": 128.16,
        "最高价": 130.24,
        "最低价": 123.18,
        "昨收价": 127.22,
        "总市值": 619268502.0,
        "市盈率": 8.42,
        "成交量": 28884.0,
        "成交额": 3653923.0,
        "振幅": 5.55,
        "换手率": 0.59,
        "symbol": "105.PLPC"
    },
    {
        "序号": 9369,
        "name": "Navitas Semiconductor Corp",
        "最新价": 7.54,
        "涨跌额": -0.04,
        "涨跌幅": -0.53,
        "开盘价": 7.52,
        "最高价": 7.7,
        "最低价": 7.42,
        "昨收价": 7.58,
        "总市值": 1345801435.0,
        "市盈率": -11.22,
        "成交量": 5629724.0,
        "成交额": 42598709.0,
        "振幅": 3.69,
        "换手率": 3.15,
        "symbol": "105.NVTS"
    },
    {
        "序号": 9370,
        "name": "Barings Global Short Duration H",
        "最新价": 13.16,
        "涨跌额": -0.07,
        "涨跌幅": -0.53,
        "开盘价": 13.2,
        "最高价": 13.23,
        "最低价": 13.13,
        "昨收价": 13.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 59124.0,
        "成交额": 779257.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "106.BGH"
    },
    {
        "序号": 9371,
        "name": "Schwab Intermediate-Term U.S. T",
        "最新价": 48.78,
        "涨跌额": -0.26,
        "涨跌幅": -0.53,
        "开盘价": 48.82,
        "最高价": 48.99,
        "最低价": 48.72,
        "昨收价": 49.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1535882.0,
        "成交额": 74933819.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.SCHR"
    },
    {
        "序号": 9372,
        "name": "汽车一组",
        "最新价": 283.0,
        "涨跌额": -1.51,
        "涨跌幅": -0.53,
        "开盘价": 284.53,
        "最高价": 287.0,
        "最低价": 279.1,
        "昨收价": 284.51,
        "总市值": 3911836269.0,
        "市盈率": 6.02,
        "成交量": 153885.0,
        "成交额": 43429795.0,
        "振幅": 2.78,
        "换手率": 1.11,
        "symbol": "106.GPI"
    },
    {
        "序号": 9373,
        "name": "iShares iBonds Dec 2029 Term Co",
        "最新价": 22.49,
        "涨跌额": -0.12,
        "涨跌幅": -0.53,
        "开盘价": 22.55,
        "最高价": 22.55,
        "最低价": 22.47,
        "昨收价": 22.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 223197.0,
        "成交额": 5019998.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.IBDU"
    },
    {
        "序号": 9374,
        "name": "ClearBridge Energy Midstream Op",
        "最新价": 33.73,
        "涨跌额": -0.18,
        "涨跌幅": -0.53,
        "开盘价": 33.88,
        "最高价": 34.17,
        "最低价": 32.92,
        "昨收价": 33.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 130387.0,
        "成交额": 4361942.0,
        "振幅": 3.69,
        "换手率": null,
        "symbol": "106.EMO"
    },
    {
        "序号": 9375,
        "name": "新西兰ETF-iShares MSCI",
        "最新价": 46.84,
        "涨跌额": -0.25,
        "涨跌幅": -0.53,
        "开盘价": 46.93,
        "最高价": 47.12,
        "最低价": 46.71,
        "昨收价": 47.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 5982.0,
        "成交额": 280711.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "105.ENZL"
    },
    {
        "序号": 9376,
        "name": "碧迪医疗",
        "最新价": 232.3,
        "涨跌额": -1.24,
        "涨跌幅": -0.53,
        "开盘价": 234.6,
        "最高价": 234.6,
        "最低价": 232.14,
        "昨收价": 233.54,
        "总市值": 67461109841.0,
        "市盈率": 45.46,
        "成交量": 1648293.0,
        "成交额": 383754912.0,
        "振幅": 1.05,
        "换手率": 0.57,
        "symbol": "106.BDX"
    },
    {
        "序号": 9377,
        "name": "JPMorgan Income ETF",
        "最新价": 44.92,
        "涨跌额": -0.24,
        "涨跌幅": -0.53,
        "开盘价": 44.99,
        "最高价": 45.05,
        "最低价": 44.92,
        "昨收价": 45.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 335977.0,
        "成交额": 15113574.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.JPIE"
    },
    {
        "序号": 9378,
        "name": "First Trust TCW Unconstrained P",
        "最新价": 24.33,
        "涨跌额": -0.13,
        "涨跌幅": -0.53,
        "开盘价": 24.32,
        "最高价": 24.36,
        "最低价": 24.32,
        "昨收价": 24.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 423017.0,
        "成交额": 10297959.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.UCON"
    },
    {
        "序号": 9379,
        "name": "新兴市场地方债ETF-SPDR",
        "最新价": 20.58,
        "涨跌额": -0.11,
        "涨跌幅": -0.53,
        "开盘价": 20.56,
        "最高价": 20.65,
        "最低价": 20.55,
        "昨收价": 20.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 252383.0,
        "成交额": 5197586.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.EBND"
    },
    {
        "序号": 9380,
        "name": "毛伊岛菠萝食品",
        "最新价": 14.96,
        "涨跌额": -0.08,
        "涨跌幅": -0.53,
        "开盘价": 15.26,
        "最高价": 15.29,
        "最低价": 14.81,
        "昨收价": 15.04,
        "总市值": 293578466.0,
        "市盈率": -25.16,
        "成交量": 14221.0,
        "成交额": 213284.0,
        "振幅": 3.19,
        "换手率": 0.07,
        "symbol": "106.MLP"
    },
    {
        "序号": 9381,
        "name": "SunCoke Energy Inc",
        "最新价": 9.34,
        "涨跌额": -0.05,
        "涨跌幅": -0.53,
        "开盘价": 9.36,
        "最高价": 9.44,
        "最低价": 9.25,
        "昨收价": 9.39,
        "总市值": 782283067.0,
        "市盈率": 14.1,
        "成交量": 461666.0,
        "成交额": 4306303.0,
        "振幅": 2.02,
        "换手率": 0.55,
        "symbol": "106.SXC"
    },
    {
        "序号": 9382,
        "name": "SPDR Portfolio Intermediate Ter",
        "最新价": 28.02,
        "涨跌额": -0.15,
        "涨跌幅": -0.53,
        "开盘价": 28.04,
        "最高价": 28.09,
        "最低价": 27.98,
        "昨收价": 28.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 1895146.0,
        "成交额": 53116854.0,
        "振幅": 0.39,
        "换手率": null,
        "symbol": "107.SPTI"
    },
    {
        "序号": 9383,
        "name": "Invesco Quality Municipal Incom",
        "最新价": 9.33,
        "涨跌额": -0.05,
        "涨跌幅": -0.53,
        "开盘价": 9.34,
        "最高价": 9.36,
        "最低价": 9.32,
        "昨收价": 9.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 141668.0,
        "成交额": 1322396.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "106.IQI"
    },
    {
        "序号": 9384,
        "name": "W. R. Berkley Corp Debentures",
        "最新价": 22.39,
        "涨跌额": -0.12,
        "涨跌幅": -0.53,
        "开盘价": 22.51,
        "最高价": 22.6,
        "最低价": 22.37,
        "昨收价": 22.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 16924.0,
        "成交额": 379927.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.WRB_F"
    },
    {
        "序号": 9385,
        "name": "Fidelity Tactical Bond ETF",
        "最新价": 48.51,
        "涨跌额": -0.26,
        "涨跌幅": -0.53,
        "开盘价": 48.51,
        "最高价": 48.51,
        "最低价": 48.51,
        "昨收价": 48.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 530.0,
        "成交额": 25710.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FTBD"
    },
    {
        "序号": 9386,
        "name": "Tennessee Valley Authority Seri",
        "最新价": 22.38,
        "涨跌额": -0.12,
        "涨跌幅": -0.53,
        "开盘价": 22.53,
        "最高价": 22.57,
        "最低价": 22.36,
        "昨收价": 22.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 15135.0,
        "成交额": 339939.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.TVC"
    },
    {
        "序号": 9387,
        "name": "US Treasury 5 Year Note ETF",
        "最新价": 48.39,
        "涨跌额": -0.26,
        "涨跌幅": -0.53,
        "开盘价": 48.46,
        "最高价": 48.46,
        "最低价": 48.36,
        "昨收价": 48.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 9935.0,
        "成交额": 480908.0,
        "振幅": 0.21,
        "换手率": null,
        "symbol": "105.UFIV"
    },
    {
        "序号": 9388,
        "name": "WisdomTree Mortgage Plus Bond F",
        "最新价": 42.78,
        "涨跌额": -0.23,
        "涨跌幅": -0.53,
        "开盘价": 42.76,
        "最高价": 42.82,
        "最低价": 42.68,
        "昨收价": 43.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 9826.0,
        "成交额": 420030.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.MTGP"
    },
    {
        "序号": 9389,
        "name": "Plains All American Pipeline LP",
        "最新价": 14.87,
        "涨跌额": -0.08,
        "涨跌幅": -0.54,
        "开盘价": 15.0,
        "最高价": 15.01,
        "最低价": 14.77,
        "昨收价": 14.95,
        "总市值": 10423758653.0,
        "市盈率": 8.83,
        "成交量": 4364046.0,
        "成交额": 64925681.0,
        "振幅": 1.61,
        "换手率": 0.62,
        "symbol": "105.PAA"
    },
    {
        "序号": 9390,
        "name": "US Bancorp Series M Pfd",
        "最新价": 16.7,
        "涨跌额": -0.09,
        "涨跌幅": -0.54,
        "开盘价": 16.73,
        "最高价": 16.77,
        "最低价": 16.5,
        "昨收价": 16.79,
        "总市值": 501000.0,
        "市盈率": null,
        "成交量": 70397.0,
        "成交额": 1172104.0,
        "振幅": 1.61,
        "换手率": 234.66,
        "symbol": "106.USB_R"
    },
    {
        "序号": 9391,
        "name": "默邱利通用",
        "最新价": 38.89,
        "涨跌额": -0.21,
        "涨跌幅": -0.54,
        "开盘价": 39.13,
        "最高价": 39.13,
        "最低价": 38.55,
        "昨收价": 39.1,
        "总市值": 2153383129.0,
        "市盈率": -21.15,
        "成交量": 128667.0,
        "成交额": 5000937.0,
        "振幅": 1.48,
        "换手率": 0.23,
        "symbol": "106.MCY"
    },
    {
        "序号": 9392,
        "name": "John Hancock Preferred Income F",
        "最新价": 14.81,
        "涨跌额": -0.08,
        "涨跌幅": -0.54,
        "开盘价": 14.83,
        "最高价": 14.94,
        "最低价": 14.81,
        "昨收价": 14.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 104291.0,
        "成交额": 1549109.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "106.HPI"
    },
    {
        "序号": 9393,
        "name": "投资级公司债ETF-iShares",
        "最新价": 107.36,
        "涨跌额": -0.58,
        "涨跌幅": -0.54,
        "开盘价": 107.34,
        "最高价": 107.63,
        "最低价": 107.18,
        "昨收价": 107.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 21624193.0,
        "成交额": 2321455808.0,
        "振幅": 0.42,
        "换手率": null,
        "symbol": "107.LQD"
    },
    {
        "序号": 9394,
        "name": "美国辉宝",
        "最新价": 11.08,
        "涨跌额": -0.06,
        "涨跌幅": -0.54,
        "开盘价": 11.1,
        "最高价": 11.17,
        "最低价": 10.93,
        "昨收价": 11.14,
        "总市值": 448779977.0,
        "市盈率": 21.64,
        "成交量": 111115.0,
        "成交额": 1229215.0,
        "振幅": 2.15,
        "换手率": 0.27,
        "symbol": "105.PAHC"
    },
    {
        "序号": 9395,
        "name": "Gen Digital Inc",
        "最新价": 22.15,
        "涨跌额": -0.12,
        "涨跌幅": -0.54,
        "开盘价": 22.16,
        "最高价": 22.26,
        "最低价": 21.97,
        "昨收价": 22.27,
        "总市值": 14191843231.0,
        "市盈率": 10.01,
        "成交量": 2994071.0,
        "成交额": 66272331.0,
        "振幅": 1.3,
        "换手率": 0.47,
        "symbol": "105.GEN"
    },
    {
        "序号": 9396,
        "name": "Eagle Point Credit Co Inc Notes",
        "最新价": 22.13,
        "涨跌额": -0.12,
        "涨跌幅": -0.54,
        "开盘价": 22.13,
        "最高价": 22.13,
        "最低价": 22.13,
        "昨收价": 22.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 428.0,
        "成交额": 9469.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.ECCV"
    },
    {
        "序号": 9397,
        "name": "豪华",
        "最新价": 18.43,
        "涨跌额": -0.1,
        "涨跌幅": -0.54,
        "开盘价": 18.45,
        "最高价": 18.71,
        "最低价": 18.34,
        "昨收价": 18.53,
        "总市值": 805253604.0,
        "市盈率": 26.74,
        "成交量": 166719.0,
        "成交额": 3076076.0,
        "振幅": 2.0,
        "换手率": 0.38,
        "symbol": "106.DLX"
    },
    {
        "序号": 9398,
        "name": "米雷康姆国际通信",
        "最新价": 16.56,
        "涨跌额": -0.09,
        "涨跌幅": -0.54,
        "开盘价": 16.44,
        "最高价": 16.59,
        "最低价": 16.42,
        "昨收价": 16.65,
        "总市值": 2843848800.0,
        "市盈率": 473.97,
        "成交量": 20666.0,
        "成交额": 341110.0,
        "振幅": 1.02,
        "换手率": 0.01,
        "symbol": "105.TIGO"
    },
    {
        "序号": 9399,
        "name": "Public Storage Series S Pfd",
        "最新价": 18.4,
        "涨跌额": -0.1,
        "涨跌幅": -0.54,
        "开盘价": 18.47,
        "最高价": 18.5,
        "最低价": 18.3,
        "昨收价": 18.5,
        "总市值": 184000.0,
        "市盈率": null,
        "成交量": 17700.0,
        "成交额": 325162.0,
        "振幅": 1.08,
        "换手率": 177.0,
        "symbol": "106.PSA_S"
    },
    {
        "序号": 9400,
        "name": "GAMCO Global Gold, Natural Reso",
        "最新价": 3.68,
        "涨跌额": -0.02,
        "涨跌幅": -0.54,
        "开盘价": 3.69,
        "最高价": 3.7,
        "最低价": 3.68,
        "昨收价": 3.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 309590.0,
        "成交额": 1141296.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.GGN"
    },
    {
        "序号": 9401,
        "name": "Invesco International Corporate",
        "最新价": 22.05,
        "涨跌额": -0.12,
        "涨跌幅": -0.54,
        "开盘价": 22.02,
        "最高价": 22.08,
        "最低价": 21.98,
        "昨收价": 22.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 13359.0,
        "成交额": 294227.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.PICB"
    },
    {
        "序号": 9402,
        "name": "ProShares UltraShort Health Car",
        "最新价": 12.86,
        "涨跌额": -0.07,
        "涨跌幅": -0.54,
        "开盘价": 12.89,
        "最高价": 12.89,
        "最低价": 12.86,
        "昨收价": 12.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 362.0,
        "成交额": 4666.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.RXD"
    },
    {
        "序号": 9403,
        "name": "iShares ESG Advanced Total USD ",
        "最新价": 42.23,
        "涨跌额": -0.23,
        "涨跌幅": -0.54,
        "开盘价": 42.29,
        "最高价": 42.37,
        "最低价": 42.14,
        "昨收价": 42.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 15609.0,
        "成交额": 659152.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.EUSB"
    },
    {
        "序号": 9404,
        "name": "iShares ESG Advanced Investment",
        "最新价": 80.72,
        "涨跌额": -0.44,
        "涨跌幅": -0.54,
        "开盘价": 80.72,
        "最高价": 80.72,
        "最低价": 80.72,
        "昨收价": 81.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 113.0,
        "成交额": 9121.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.ELQD"
    },
    {
        "序号": 9405,
        "name": "Seritage Growth Properties Seri",
        "最新价": 23.82,
        "涨跌额": -0.13,
        "涨跌幅": -0.54,
        "开盘价": 23.94,
        "最高价": 23.94,
        "最低价": 23.81,
        "昨收价": 23.95,
        "总市值": 66696000.0,
        "市盈率": null,
        "成交量": 17975.0,
        "成交额": 428181.0,
        "振幅": 0.54,
        "换手率": 0.64,
        "symbol": "106.SRG_A"
    },
    {
        "序号": 9406,
        "name": "Truist Financial Corp Series O ",
        "最新价": 21.97,
        "涨跌额": -0.12,
        "涨跌幅": -0.54,
        "开盘价": 21.96,
        "最高价": 22.23,
        "最低价": 21.81,
        "昨收价": 22.09,
        "总市值": 439400.0,
        "市盈率": null,
        "成交量": 53669.0,
        "成交额": 1180614.0,
        "振幅": 1.9,
        "换手率": 268.35,
        "symbol": "106.TFC_O"
    },
    {
        "序号": 9407,
        "name": "ImmunoGen Inc",
        "最新价": 29.21,
        "涨跌额": -0.16,
        "涨跌幅": -0.54,
        "开盘价": 29.3,
        "最高价": 29.39,
        "最低价": 29.2,
        "昨收价": 29.37,
        "总市值": 7777579444.0,
        "市盈率": -105.79,
        "成交量": 13497248.0,
        "成交额": 395127328.0,
        "振幅": 0.65,
        "换手率": 5.07,
        "symbol": "105.IMGN"
    },
    {
        "序号": 9408,
        "name": "Monarch Ambassador Income ETF",
        "最新价": 21.89,
        "涨跌额": -0.12,
        "涨跌幅": -0.55,
        "开盘价": 21.9,
        "最高价": 21.9,
        "最低价": 21.85,
        "昨收价": 22.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 25060.0,
        "成交额": 548598.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.MAMB"
    },
    {
        "序号": 9409,
        "name": "T. Rowe Price Total Return ETF",
        "最新价": 40.12,
        "涨跌额": -0.22,
        "涨跌幅": -0.55,
        "开盘价": 40.14,
        "最高价": 40.14,
        "最低价": 40.12,
        "昨收价": 40.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 980.0,
        "成交额": 39332.0,
        "振幅": 0.05,
        "换手率": null,
        "symbol": "107.TOTR"
    },
    {
        "序号": 9410,
        "name": "Invesco BulletShares 2032 Corpo",
        "最新价": 20.06,
        "涨跌额": -0.11,
        "涨跌幅": -0.55,
        "开盘价": 20.06,
        "最高价": 20.09,
        "最低价": 20.02,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 135598.0,
        "成交额": 2717362.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.BSCW"
    },
    {
        "序号": 9411,
        "name": "NEOS Enhanced Income Aggregate ",
        "最新价": 47.37,
        "涨跌额": -0.26,
        "涨跌幅": -0.55,
        "开盘价": 47.37,
        "最高价": 47.37,
        "最低价": 47.28,
        "昨收价": 47.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 621.0,
        "成交额": 29392.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.BNDI"
    },
    {
        "序号": 9412,
        "name": "KraneShares China Internet and ",
        "最新价": 16.38,
        "涨跌额": -0.09,
        "涨跌幅": -0.55,
        "开盘价": 16.41,
        "最高价": 16.46,
        "最低价": 16.37,
        "昨收价": 16.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 140799.0,
        "成交额": 2309806.0,
        "振幅": 0.55,
        "换手率": null,
        "symbol": "107.KLIP"
    },
    {
        "序号": 9413,
        "name": "Grove Collaborative Holdings In",
        "最新价": 1.82,
        "涨跌额": -0.01,
        "涨跌幅": -0.55,
        "开盘价": 1.85,
        "最高价": 1.86,
        "最低价": 1.71,
        "昨收价": 1.83,
        "总市值": 68158924.0,
        "市盈率": -1.47,
        "成交量": 54270.0,
        "成交额": 98186.0,
        "振幅": 8.2,
        "换手率": 0.14,
        "symbol": "106.GROV"
    },
    {
        "序号": 9414,
        "name": "ESSA Pharma Inc",
        "最新价": 5.46,
        "涨跌额": -0.03,
        "涨跌幅": -0.55,
        "开盘价": 5.52,
        "最高价": 5.54,
        "最低价": 5.14,
        "昨收价": 5.49,
        "总市值": 240790575.0,
        "市盈率": -8.78,
        "成交量": 34117.0,
        "成交额": 183839.0,
        "振幅": 7.29,
        "换手率": 0.08,
        "symbol": "105.EPIX"
    },
    {
        "序号": 9415,
        "name": "SPDR Bloomberg Emerging Markets",
        "最新价": 23.64,
        "涨跌额": -0.13,
        "涨跌幅": -0.55,
        "开盘价": 23.62,
        "最高价": 23.66,
        "最低价": 23.58,
        "昨收价": 23.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 12028.0,
        "成交额": 284113.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.EMHC"
    },
    {
        "序号": 9416,
        "name": "Vanguard ESG U.S. Corporate Bon",
        "最新价": 61.77,
        "涨跌额": -0.34,
        "涨跌幅": -0.55,
        "开盘价": 61.88,
        "最高价": 62.03,
        "最低价": 61.72,
        "昨收价": 62.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 35595.0,
        "成交额": 2201166.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "107.VCEB"
    },
    {
        "序号": 9417,
        "name": "REV Group Inc",
        "最新价": 16.35,
        "涨跌额": -0.09,
        "涨跌幅": -0.55,
        "开盘价": 16.35,
        "最高价": 16.48,
        "最低价": 16.26,
        "昨收价": 16.44,
        "总市值": 969703899.0,
        "市盈率": 39.91,
        "成交量": 111256.0,
        "成交额": 1821277.0,
        "振幅": 1.34,
        "换手率": 0.19,
        "symbol": "106.REVG"
    },
    {
        "序号": 9418,
        "name": "First Trust Energy Infrastructu",
        "最新价": 16.35,
        "涨跌额": -0.09,
        "涨跌幅": -0.55,
        "开盘价": 16.45,
        "最高价": 16.83,
        "最低价": 16.35,
        "昨收价": 16.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 35997.0,
        "成交额": 596664.0,
        "振幅": 2.92,
        "换手率": null,
        "symbol": "106.FIF"
    },
    {
        "序号": 9419,
        "name": "SPDR SSGA Fixed Income Sector R",
        "最新价": 25.41,
        "涨跌额": -0.14,
        "涨跌幅": -0.55,
        "开盘价": 25.41,
        "最高价": 25.44,
        "最低价": 25.38,
        "昨收价": 25.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 21611.0,
        "成交额": 549156.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.FISR"
    },
    {
        "序号": 9420,
        "name": "Silence Therapeutics plc ADR",
        "最新价": 14.5,
        "涨跌额": -0.08,
        "涨跌幅": -0.55,
        "开盘价": 14.37,
        "最高价": 15.42,
        "最低价": 14.3,
        "昨收价": 14.58,
        "总市值": 560713975.0,
        "市盈率": -10.32,
        "成交量": 593470.0,
        "成交额": 8723092.0,
        "振幅": 7.68,
        "换手率": 1.53,
        "symbol": "105.SLN"
    },
    {
        "序号": 9421,
        "name": "Rithm Capital Corp Series D Pfd",
        "最新价": 21.75,
        "涨跌额": -0.12,
        "涨跌幅": -0.55,
        "开盘价": 21.63,
        "最高价": 21.87,
        "最低价": 21.63,
        "昨收价": 21.87,
        "总市值": 404550000.0,
        "市盈率": null,
        "成交量": 26187.0,
        "成交额": 569987.0,
        "振幅": 1.1,
        "换手率": 0.14,
        "symbol": "106.RITM_D"
    },
    {
        "序号": 9422,
        "name": "Invesco Nasdaq Biotechnology ET",
        "最新价": 19.93,
        "涨跌额": -0.11,
        "涨跌幅": -0.55,
        "开盘价": 20.0,
        "最高价": 20.01,
        "最低价": 19.91,
        "昨收价": 20.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 7293.0,
        "成交额": 145738.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "105.IBBQ"
    },
    {
        "序号": 9423,
        "name": "方舟生物医药",
        "最新价": 1.81,
        "涨跌额": -0.01,
        "涨跌幅": -0.55,
        "开盘价": 1.8,
        "最高价": 1.82,
        "最低价": 1.8,
        "昨收价": 1.82,
        "总市值": 26082359.0,
        "市盈率": -4.81,
        "成交量": 13435.0,
        "成交额": 24332.0,
        "振幅": 1.1,
        "换手率": 0.09,
        "symbol": "105.ABIO"
    },
    {
        "序号": 9424,
        "name": "JPMorgan BetaBuilders Japan ETF",
        "最新价": 52.33,
        "涨跌额": -0.29,
        "涨跌幅": -0.55,
        "开盘价": 52.04,
        "最高价": 52.37,
        "最低价": 52.03,
        "昨收价": 52.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 592673.0,
        "成交额": 30966129.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.BBJP"
    },
    {
        "序号": 9425,
        "name": "Bank of America Corp Series QQ ",
        "最新价": 18.02,
        "涨跌额": -0.1,
        "涨跌幅": -0.55,
        "开盘价": 18.0,
        "最高价": 18.12,
        "最低价": 17.89,
        "昨收价": 18.12,
        "总市值": 937040.0,
        "市盈率": null,
        "成交量": 103632.0,
        "成交额": 1861362.0,
        "振幅": 1.27,
        "换手率": 199.29,
        "symbol": "106.BAC_Q"
    },
    {
        "序号": 9426,
        "name": "Simplify Aggregate Bond PLUS Cr",
        "最新价": 21.62,
        "涨跌额": -0.12,
        "涨跌幅": -0.55,
        "开盘价": 21.63,
        "最高价": 21.63,
        "最低价": 21.55,
        "昨收价": 21.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 8269.0,
        "成交额": 178465.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.AGGH"
    },
    {
        "序号": 9427,
        "name": "ProKidney Corp-A",
        "最新价": 1.8,
        "涨跌额": -0.01,
        "涨跌幅": -0.55,
        "开盘价": 1.77,
        "最高价": 1.89,
        "最低价": 1.71,
        "昨收价": 1.81,
        "总市值": 423782334.0,
        "市盈率": -11.69,
        "成交量": 358601.0,
        "成交额": 649650.0,
        "振幅": 9.94,
        "换手率": 0.15,
        "symbol": "105.PROK"
    },
    {
        "序号": 9428,
        "name": "Entergy Louisiana Inc Bonds",
        "最新价": 21.59,
        "涨跌额": -0.12,
        "涨跌幅": -0.55,
        "开盘价": 21.7,
        "最高价": 21.7,
        "最低价": 21.56,
        "昨收价": 21.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 10781.0,
        "成交额": 233020.0,
        "振幅": 0.64,
        "换手率": null,
        "symbol": "106.ELC"
    },
    {
        "序号": 9429,
        "name": "Janus Henderson Sustainable & I",
        "最新价": 41.28,
        "涨跌额": -0.23,
        "涨跌幅": -0.55,
        "开盘价": 41.28,
        "最高价": 41.28,
        "最低价": 41.28,
        "昨收价": 41.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 8.0,
        "成交额": 330.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JIB"
    },
    {
        "序号": 9430,
        "name": "Graphic Packaging Holding Co",
        "最新价": 23.3,
        "涨跌额": -0.13,
        "涨跌幅": -0.55,
        "开盘价": 23.39,
        "最高价": 23.66,
        "最低价": 23.26,
        "昨收价": 23.43,
        "总市值": 7131031755.0,
        "市盈率": 10.44,
        "成交量": 3322362.0,
        "成交额": 78040571.0,
        "振幅": 1.71,
        "换手率": 1.09,
        "symbol": "106.GPK"
    },
    {
        "序号": 9431,
        "name": "iShares MSCI India Small-Cap ET",
        "最新价": 68.03,
        "涨跌额": -0.38,
        "涨跌幅": -0.56,
        "开盘价": 67.92,
        "最高价": 68.19,
        "最低价": 67.6,
        "昨收价": 68.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 216793.0,
        "成交额": 14725397.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.SMIN"
    },
    {
        "序号": 9432,
        "name": "Rafael Holdings Inc-B",
        "最新价": 1.79,
        "涨跌额": -0.01,
        "涨跌幅": -0.56,
        "开盘价": 1.8,
        "最高价": 1.8,
        "最低价": 1.77,
        "昨收价": 1.8,
        "总市值": 43866877.0,
        "市盈率": -23.38,
        "成交量": 2491.0,
        "成交额": 4442.0,
        "振幅": 1.67,
        "换手率": 0.01,
        "symbol": "106.RFL"
    },
    {
        "序号": 9433,
        "name": "BondBloxx Bloomberg Five Year T",
        "最新价": 48.21,
        "涨跌额": -0.27,
        "涨跌幅": -0.56,
        "开盘价": 48.25,
        "最高价": 48.25,
        "最低价": 48.21,
        "昨收价": 48.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 250.0,
        "成交额": 12058.0,
        "振幅": 0.08,
        "换手率": null,
        "symbol": "107.XFIV"
    },
    {
        "序号": 9434,
        "name": "Matthews Asia Dividend Active E",
        "最新价": 30.32,
        "涨跌额": -0.17,
        "涨跌幅": -0.56,
        "开盘价": 30.23,
        "最高价": 30.32,
        "最低价": 30.23,
        "昨收价": 30.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 295.0,
        "成交额": 8917.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.ADVE"
    },
    {
        "序号": 9435,
        "name": "DTF Tax-Free Income 2028 Term F",
        "最新价": 10.68,
        "涨跌额": -0.06,
        "涨跌幅": -0.56,
        "开盘价": 10.78,
        "最高价": 10.78,
        "最低价": 10.67,
        "昨收价": 10.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 65115.0,
        "成交额": 696630.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.DTF"
    },
    {
        "序号": 9436,
        "name": "BioRestorative Therapies Inc",
        "最新价": 1.78,
        "涨跌额": -0.01,
        "涨跌幅": -0.56,
        "开盘价": 1.76,
        "最高价": 1.79,
        "最低价": 1.7,
        "昨收价": 1.79,
        "总市值": 8378312.0,
        "市盈率": -0.53,
        "成交量": 3322.0,
        "成交额": 5843.0,
        "振幅": 5.03,
        "换手率": 0.07,
        "symbol": "105.BRTX"
    },
    {
        "序号": 9437,
        "name": "PIMCO California Municipal Inco",
        "最新价": 7.11,
        "涨跌额": -0.04,
        "涨跌幅": -0.56,
        "开盘价": 7.15,
        "最高价": 7.24,
        "最低价": 7.06,
        "昨收价": 7.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 108492.0,
        "成交额": 776552.0,
        "振幅": 2.52,
        "换手率": null,
        "symbol": "106.PZC"
    },
    {
        "序号": 9438,
        "name": "Dimensional Emerging Markets Su",
        "最新价": 30.21,
        "涨跌额": -0.17,
        "涨跌幅": -0.56,
        "开盘价": 30.24,
        "最高价": 30.34,
        "最低价": 30.18,
        "昨收价": 30.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 29719.0,
        "成交额": 898350.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.DFSE"
    },
    {
        "序号": 9439,
        "name": "布鲁克菲尔德公共建设(优先股)",
        "最新价": 15.97,
        "涨跌额": -0.09,
        "涨跌幅": -0.56,
        "开盘价": 16.01,
        "最高价": 16.1,
        "最低价": 15.97,
        "昨收价": 16.06,
        "总市值": 127760000.0,
        "市盈率": null,
        "成交量": 9192.0,
        "成交额": 147562.0,
        "振幅": 0.81,
        "换手率": 0.11,
        "symbol": "106.BIP_A"
    },
    {
        "序号": 9440,
        "name": "Bank of America Corp Series 5 P",
        "最新价": 21.29,
        "涨跌额": -0.12,
        "涨跌幅": -0.56,
        "开盘价": 21.23,
        "最高价": 21.43,
        "最低价": 21.23,
        "昨收价": 21.41,
        "总市值": 299252.0,
        "市盈率": null,
        "成交量": 12400.0,
        "成交额": 264865.0,
        "振幅": 0.93,
        "换手率": 88.22,
        "symbol": "106.BML_L"
    },
    {
        "序号": 9441,
        "name": "Amphastar Pharmaceuticals Inc",
        "最新价": 56.77,
        "涨跌额": -0.32,
        "涨跌幅": -0.56,
        "开盘价": 57.09,
        "最高价": 57.24,
        "最低价": 56.1,
        "昨收价": 57.09,
        "总市值": 2719703722.0,
        "市盈率": 20.1,
        "成交量": 238592.0,
        "成交额": 13516655.0,
        "振幅": 2.0,
        "换手率": 0.5,
        "symbol": "105.AMPH"
    },
    {
        "序号": 9442,
        "name": "标记临床研究",
        "最新价": 15.95,
        "涨跌额": -0.09,
        "涨跌幅": -0.56,
        "开盘价": 16.15,
        "最高价": 16.4,
        "最低价": 15.45,
        "昨收价": 16.04,
        "总市值": 183230538.0,
        "市盈率": -6.86,
        "成交量": 20632.0,
        "成交额": 328835.0,
        "振幅": 5.92,
        "换手率": 0.18,
        "symbol": "105.XOMA"
    },
    {
        "序号": 9443,
        "name": "Five Below Inc",
        "最新价": 194.83,
        "涨跌额": -1.1,
        "涨跌幅": -0.56,
        "开盘价": 195.27,
        "最高价": 197.18,
        "最低价": 193.52,
        "昨收价": 195.93,
        "总市值": 10753206989.0,
        "市盈率": 39.79,
        "成交量": 432792.0,
        "成交额": 84383158.0,
        "振幅": 1.87,
        "换手率": 0.78,
        "symbol": "105.FIVE"
    },
    {
        "序号": 9444,
        "name": "New Pacific Metals Corp",
        "最新价": 1.77,
        "涨跌额": -0.01,
        "涨跌幅": -0.56,
        "开盘价": 1.76,
        "最高价": 1.83,
        "最低价": 1.73,
        "昨收价": 1.78,
        "总市值": 302500756.0,
        "市盈率": -39.0,
        "成交量": 86303.0,
        "成交额": 152324.0,
        "振幅": 5.62,
        "换手率": 0.05,
        "symbol": "107.NEWP"
    },
    {
        "序号": 9445,
        "name": "Bakkt Holdings Inc-A",
        "最新价": 1.77,
        "涨跌额": -0.01,
        "涨跌幅": -0.56,
        "开盘价": 1.8,
        "最高价": 1.83,
        "最低价": 1.7,
        "昨收价": 1.78,
        "总市值": 486155898.0,
        "市盈率": -3.3,
        "成交量": 3265529.0,
        "成交额": 5719675.0,
        "振幅": 7.3,
        "换手率": 1.19,
        "symbol": "106.BKKT"
    },
    {
        "序号": 9446,
        "name": "Invesco BulletShares 2031 Corpo",
        "最新价": 15.92,
        "涨跌额": -0.09,
        "涨跌幅": -0.56,
        "开盘价": 15.91,
        "最高价": 15.94,
        "最低价": 15.88,
        "昨收价": 16.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 773311.0,
        "成交额": 12302067.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.BSCV"
    },
    {
        "序号": 9447,
        "name": "富乐客",
        "最新价": 28.19,
        "涨跌额": -0.16,
        "涨跌幅": -0.56,
        "开盘价": 28.4,
        "最高价": 28.44,
        "最低价": 27.88,
        "昨收价": 28.35,
        "总市值": 2654421339.0,
        "市盈率": 34.03,
        "成交量": 2247409.0,
        "成交额": 63278077.0,
        "振幅": 1.98,
        "换手率": 2.39,
        "symbol": "106.FL"
    },
    {
        "序号": 9448,
        "name": "BWX Technologies Inc",
        "最新价": 79.24,
        "涨跌额": -0.45,
        "涨跌幅": -0.56,
        "开盘价": 79.9,
        "最高价": 80.37,
        "最低价": 79.06,
        "昨收价": 79.69,
        "总市值": 7251473321.0,
        "市盈率": 32.53,
        "成交量": 402292.0,
        "成交额": 31941973.0,
        "振幅": 1.64,
        "换手率": 0.44,
        "symbol": "106.BWXT"
    },
    {
        "序号": 9449,
        "name": "Vistra Corp",
        "最新价": 36.96,
        "涨跌额": -0.21,
        "涨跌幅": -0.56,
        "开盘价": 37.1,
        "最高价": 37.2,
        "最低价": 36.45,
        "昨收价": 37.17,
        "总市值": 13215134376.0,
        "市盈率": 9.23,
        "成交量": 3606031.0,
        "成交额": 132787626.0,
        "振幅": 2.02,
        "换手率": 1.01,
        "symbol": "106.VST"
    },
    {
        "序号": 9450,
        "name": "Putnam Premier Income Trust",
        "最新价": 3.52,
        "涨跌额": -0.02,
        "涨跌幅": -0.56,
        "开盘价": 3.52,
        "最高价": 3.56,
        "最低价": 3.52,
        "昨收价": 3.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 114902.0,
        "成交额": 406024.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "106.PPT"
    },
    {
        "序号": 9451,
        "name": "Fidelis Insurance Holdings Ltd",
        "最新价": 12.28,
        "涨跌额": -0.07,
        "涨跌幅": -0.57,
        "开盘价": 12.35,
        "最高价": 12.43,
        "最低价": 12.2,
        "昨收价": 12.35,
        "总市值": 1447993179.0,
        "市盈率": 0.72,
        "成交量": 165537.0,
        "成交额": 2032442.0,
        "振幅": 1.86,
        "换手率": 0.14,
        "symbol": "106.FIHL"
    },
    {
        "序号": 9452,
        "name": "Global X Renewable Energy Produ",
        "最新价": 10.51,
        "涨跌额": -0.06,
        "涨跌幅": -0.57,
        "开盘价": 10.51,
        "最高价": 10.58,
        "最低价": 10.47,
        "昨收价": 10.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 98073.0,
        "成交额": 1035825.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "105.RNRG"
    },
    {
        "序号": 9453,
        "name": "Public Storage Series J Pfd",
        "最新价": 20.95,
        "涨跌额": -0.12,
        "涨跌幅": -0.57,
        "开盘价": 20.9,
        "最高价": 21.15,
        "最低价": 20.8,
        "昨收价": 21.07,
        "总市值": 216833.0,
        "市盈率": null,
        "成交量": 31073.0,
        "成交额": 651168.0,
        "振幅": 1.66,
        "换手率": 300.22,
        "symbol": "106.PSA_J"
    },
    {
        "序号": 9454,
        "name": "Direxion Daily MSCI India Bull ",
        "最新价": 53.99,
        "涨跌额": -0.31,
        "涨跌幅": -0.57,
        "开盘价": 54.18,
        "最高价": 54.18,
        "最低价": 53.71,
        "昨收价": 54.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 25771.0,
        "成交额": 1387623.0,
        "振幅": 0.87,
        "换手率": null,
        "symbol": "107.INDL"
    },
    {
        "序号": 9455,
        "name": "Stifel Financial Corp Notes",
        "最新价": 20.88,
        "涨跌额": -0.12,
        "涨跌幅": -0.57,
        "开盘价": 20.94,
        "最高价": 21.0,
        "最低价": 20.74,
        "昨收价": 21.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 6336.0,
        "成交额": 132039.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "106.SFB"
    },
    {
        "序号": 9456,
        "name": "LINKBANCORP Inc",
        "最新价": 6.96,
        "涨跌额": -0.04,
        "涨跌幅": -0.57,
        "开盘价": 6.89,
        "最高价": 7.18,
        "最低价": 6.72,
        "昨收价": 7.0,
        "总市值": 113052575.0,
        "市盈率": 65.39,
        "成交量": 25521.0,
        "成交额": 179872.0,
        "振幅": 6.57,
        "换手率": 0.16,
        "symbol": "105.LNKB"
    },
    {
        "序号": 9457,
        "name": "John Hancock Corporate Bond ETF",
        "最新价": 20.85,
        "涨跌额": -0.12,
        "涨跌幅": -0.57,
        "开盘价": 20.86,
        "最高价": 20.86,
        "最低价": 20.8,
        "昨收价": 20.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 1366.0,
        "成交额": 28479.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.JHCB"
    },
    {
        "序号": 9458,
        "name": "Direxion Auspice Broad Commodit",
        "最新价": 27.78,
        "涨跌额": -0.16,
        "涨跌幅": -0.57,
        "开盘价": 28.18,
        "最高价": 28.18,
        "最低价": 27.72,
        "昨收价": 27.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 124721.0,
        "成交额": 3462188.0,
        "振幅": 1.65,
        "换手率": null,
        "symbol": "107.COM"
    },
    {
        "序号": 9459,
        "name": "Triton International Ltd Series",
        "最新价": 22.56,
        "涨跌额": -0.13,
        "涨跌幅": -0.57,
        "开盘价": 22.45,
        "最高价": 22.76,
        "最低价": 22.29,
        "昨收价": 22.69,
        "总市值": 135360000.0,
        "市盈率": null,
        "成交量": 7722.0,
        "成交额": 174501.0,
        "振幅": 2.07,
        "换手率": 0.13,
        "symbol": "106.TRTN_D"
    },
    {
        "序号": 9460,
        "name": "Clean Earth Acquisitions Corp U",
        "最新价": 5.2,
        "涨跌额": -0.03,
        "涨跌幅": -0.57,
        "开盘价": 5.2,
        "最高价": 5.2,
        "最低价": 5.2,
        "昨收价": 5.23,
        "总市值": 86861996.0,
        "市盈率": 20.89,
        "成交量": 200.0,
        "成交额": 1040.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLINU"
    },
    {
        "序号": 9461,
        "name": "Pacer Emerging Markets Cash Cow",
        "最新价": 18.99,
        "涨跌额": -0.11,
        "涨跌幅": -0.58,
        "开盘价": 18.97,
        "最高价": 19.05,
        "最低价": 18.93,
        "昨收价": 19.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 9276.0,
        "成交额": 176187.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "105.ECOW"
    },
    {
        "序号": 9462,
        "name": "First Trust India NIFTY 50 Equa",
        "最新价": 51.77,
        "涨跌额": -0.3,
        "涨跌幅": -0.58,
        "开盘价": 51.87,
        "最高价": 51.87,
        "最低价": 51.51,
        "昨收价": 52.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 4627.0,
        "成交额": 239210.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "105.NFTY"
    },
    {
        "序号": 9463,
        "name": "BTC Digital Ltd",
        "最新价": 3.45,
        "涨跌额": -0.02,
        "涨跌幅": -0.58,
        "开盘价": 3.47,
        "最高价": 3.68,
        "最低价": 3.33,
        "昨收价": 3.47,
        "总市值": 5275340.0,
        "市盈率": 0.7,
        "成交量": 12467.0,
        "成交额": 43477.0,
        "振幅": 10.09,
        "换手率": 0.82,
        "symbol": "105.BTCT"
    },
    {
        "序号": 9464,
        "name": "Trajan Wealth Income Opportunit",
        "最新价": 8.62,
        "涨跌额": -0.05,
        "涨跌幅": -0.58,
        "开盘价": 8.69,
        "最高价": 8.69,
        "最低价": 8.61,
        "昨收价": 8.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 20939.0,
        "成交额": 181799.0,
        "振幅": 0.92,
        "换手率": null,
        "symbol": "107.TWIO"
    },
    {
        "序号": 9465,
        "name": "Southern California Bancorp",
        "最新价": 15.51,
        "涨跌额": -0.09,
        "涨跌幅": -0.58,
        "开盘价": 15.65,
        "最高价": 15.7,
        "最低价": 15.43,
        "昨收价": 15.6,
        "总市值": 284132095.0,
        "市盈率": null,
        "成交量": 25467.0,
        "成交额": 398405.0,
        "振幅": 1.73,
        "换手率": 0.14,
        "symbol": "105.BCAL"
    },
    {
        "序号": 9466,
        "name": "JBG史密斯地产",
        "最新价": 15.46,
        "涨跌额": -0.09,
        "涨跌幅": -0.58,
        "开盘价": 15.45,
        "最高价": 15.56,
        "最低价": 15.23,
        "昨收价": 15.55,
        "总市值": 1479525865.0,
        "市盈率": -22.43,
        "成交量": 1426108.0,
        "成交额": 22010665.0,
        "振幅": 2.12,
        "换手率": 1.49,
        "symbol": "106.JBGS"
    },
    {
        "序号": 9467,
        "name": "高露洁",
        "最新价": 77.17,
        "涨跌额": -0.45,
        "涨跌幅": -0.58,
        "开盘价": 77.56,
        "最高价": 77.56,
        "最低价": 76.69,
        "昨收价": 77.62,
        "总市值": 63539637227.0,
        "市盈率": 40.04,
        "成交量": 4317839.0,
        "成交额": 332780432.0,
        "振幅": 1.12,
        "换手率": 0.52,
        "symbol": "106.CL"
    },
    {
        "序号": 9468,
        "name": "Natural Resource Partners LP",
        "最新价": 78.88,
        "涨跌额": -0.46,
        "涨跌幅": -0.58,
        "开盘价": 79.01,
        "最高价": 80.23,
        "最低价": 77.53,
        "昨收价": 79.34,
        "总市值": 996620561.0,
        "市盈率": 3.6,
        "成交量": 5196.0,
        "成交额": 412096.0,
        "振幅": 3.4,
        "换手率": 0.04,
        "symbol": "106.NRP"
    },
    {
        "序号": 9469,
        "name": "IPG光电",
        "最新价": 99.42,
        "涨跌额": -0.58,
        "涨跌幅": -0.58,
        "开盘价": 99.4,
        "最高价": 100.71,
        "最低价": 98.96,
        "昨收价": 100.0,
        "总市值": 4665030377.0,
        "市盈率": 55.17,
        "成交量": 146918.0,
        "成交额": 14638408.0,
        "振幅": 1.75,
        "换手率": 0.31,
        "symbol": "105.IPGP"
    },
    {
        "序号": 9470,
        "name": "NexPoint Diversified Real Estat",
        "最新价": 15.41,
        "涨跌额": -0.09,
        "涨跌幅": -0.58,
        "开盘价": 15.33,
        "最高价": 15.42,
        "最低价": 15.33,
        "昨收价": 15.5,
        "总市值": 51771328.0,
        "市盈率": null,
        "成交量": 31300.0,
        "成交额": 479990.0,
        "振幅": 0.58,
        "换手率": 0.93,
        "symbol": "106.NXDT_A"
    },
    {
        "序号": 9471,
        "name": "Companhia de Saneamento Basico ",
        "最新价": 13.67,
        "涨跌额": -0.08,
        "涨跌幅": -0.58,
        "开盘价": 13.64,
        "最高价": 13.79,
        "最低价": 13.56,
        "昨收价": 13.75,
        "总市值": 9343579909.0,
        "市盈率": 15.52,
        "成交量": 697532.0,
        "成交额": 9528594.0,
        "振幅": 1.67,
        "换手率": 0.1,
        "symbol": "106.SBS"
    },
    {
        "序号": 9472,
        "name": "First Trust Nasdaq Food & Bever",
        "最新价": 23.92,
        "涨跌额": -0.14,
        "涨跌幅": -0.58,
        "开盘价": 24.08,
        "最高价": 24.08,
        "最低价": 23.91,
        "昨收价": 24.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 41539.0,
        "成交额": 995145.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "105.FTXG"
    },
    {
        "序号": 9473,
        "name": "欧文斯伊利诺斯玻璃",
        "最新价": 15.36,
        "涨跌额": -0.09,
        "涨跌幅": -0.58,
        "开盘价": 15.39,
        "最高价": 15.69,
        "最低价": 15.29,
        "昨收价": 15.45,
        "总市值": 2376611067.0,
        "市盈率": 6.25,
        "成交量": 1269121.0,
        "成交额": 19553907.0,
        "振幅": 2.59,
        "换手率": 0.82,
        "symbol": "106.OI"
    },
    {
        "序号": 9474,
        "name": "Epsilon Energy Ltd",
        "最新价": 5.12,
        "涨跌额": -0.03,
        "涨跌幅": -0.58,
        "开盘价": 5.11,
        "最高价": 5.23,
        "最低价": 5.11,
        "昨收价": 5.15,
        "总市值": 113461448.0,
        "市盈率": 8.28,
        "成交量": 56800.0,
        "成交额": 293435.0,
        "振幅": 2.33,
        "换手率": 0.26,
        "symbol": "105.EPSN"
    },
    {
        "序号": 9475,
        "name": "Viavi Solutions Inc",
        "最新价": 8.53,
        "涨跌额": -0.05,
        "涨跌幅": -0.58,
        "开盘价": 8.55,
        "最高价": 8.64,
        "最低价": 8.48,
        "昨收价": 8.58,
        "总市值": 1897025691.0,
        "市盈率": 702.6,
        "成交量": 1399870.0,
        "成交额": 11943942.0,
        "振幅": 1.86,
        "换手率": 0.63,
        "symbol": "105.VIAV"
    },
    {
        "序号": 9476,
        "name": "哔哩哔哩",
        "最新价": 11.93,
        "涨跌额": -0.07,
        "涨跌幅": -0.58,
        "开盘价": 12.0,
        "最高价": 12.04,
        "最低价": 11.79,
        "昨收价": 12.0,
        "总市值": 4954068705.0,
        "市盈率": -6.86,
        "成交量": 3220845.0,
        "成交额": 38381737.0,
        "振幅": 2.08,
        "换手率": 0.78,
        "symbol": "105.BILI"
    },
    {
        "序号": 9477,
        "name": "Bank of America Corp Notes",
        "最新价": 25.56,
        "涨跌额": -0.15,
        "涨跌幅": -0.58,
        "开盘价": 25.68,
        "最高价": 25.74,
        "最低价": 25.52,
        "昨收价": 25.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 17041.0,
        "成交额": 436506.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "106.MER_K"
    },
    {
        "序号": 9478,
        "name": "Pacer Data & Infrastructure Rea",
        "最新价": 28.95,
        "涨跌额": -0.17,
        "涨跌幅": -0.58,
        "开盘价": 29.17,
        "最高价": 29.19,
        "最低价": 28.71,
        "昨收价": 29.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 47736.0,
        "成交额": 1382911.0,
        "振幅": 1.65,
        "换手率": null,
        "symbol": "107.SRVR"
    },
    {
        "序号": 9479,
        "name": "iShares International Developed",
        "最新价": 20.42,
        "涨跌额": -0.12,
        "涨跌幅": -0.58,
        "开盘价": 20.35,
        "最高价": 20.48,
        "最低价": 20.33,
        "昨收价": 20.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 20709.0,
        "成交额": 422805.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "105.IFGL"
    },
    {
        "序号": 9480,
        "name": "Virtus Newfleet Multi-Sector Bo",
        "最新价": 22.06,
        "涨跌额": -0.13,
        "涨跌幅": -0.59,
        "开盘价": 22.05,
        "最高价": 22.12,
        "最低价": 22.0,
        "昨收价": 22.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 4826.0,
        "成交额": 106528.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "107.NFLT"
    },
    {
        "序号": 9481,
        "name": "日本ETF-iShares MSCI",
        "最新价": 62.78,
        "涨跌额": -0.37,
        "涨跌幅": -0.59,
        "开盘价": 62.48,
        "最高价": 62.83,
        "最低价": 62.41,
        "昨收价": 63.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 5921375.0,
        "成交额": 371291104.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.EWJ"
    },
    {
        "序号": 9482,
        "name": "JPMorgan Chase & Co Series LL P",
        "最新价": 20.36,
        "涨跌额": -0.12,
        "涨跌幅": -0.59,
        "开盘价": 20.43,
        "最高价": 20.48,
        "最低价": 20.27,
        "昨收价": 20.48,
        "总市值": 3766600.0,
        "市盈率": null,
        "成交量": 165112.0,
        "成交额": 3367065.0,
        "振幅": 1.03,
        "换手率": 89.25,
        "symbol": "106.JPM_L"
    },
    {
        "序号": 9483,
        "name": "AdvanSix Inc",
        "最新价": 27.14,
        "涨跌额": -0.16,
        "涨跌幅": -0.59,
        "开盘价": 27.3,
        "最高价": 27.76,
        "最低价": 27.03,
        "昨收价": 27.3,
        "总市值": 731522875.0,
        "市盈率": 7.84,
        "成交量": 120535.0,
        "成交额": 3278988.0,
        "振幅": 2.67,
        "换手率": 0.45,
        "symbol": "106.ASIX"
    },
    {
        "序号": 9484,
        "name": "Global X MSCI SuperDividend Eme",
        "最新价": 23.74,
        "涨跌额": -0.14,
        "涨跌幅": -0.59,
        "开盘价": 23.78,
        "最高价": 23.78,
        "最低价": 23.72,
        "昨收价": 23.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 2171.0,
        "成交额": 51522.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.SDEM"
    },
    {
        "序号": 9485,
        "name": "iShares MSCI Qatar ETF",
        "最新价": 16.94,
        "涨跌额": -0.1,
        "涨跌幅": -0.59,
        "开盘价": 17.0,
        "最高价": 17.01,
        "最低价": 16.94,
        "昨收价": 17.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 1484.0,
        "成交额": 25222.0,
        "振幅": 0.41,
        "换手率": null,
        "symbol": "105.QAT"
    },
    {
        "序号": 9486,
        "name": "Fidelity Corporate Bond ETF",
        "最新价": 45.69,
        "涨跌额": -0.27,
        "涨跌幅": -0.59,
        "开盘价": 45.72,
        "最高价": 45.78,
        "最低价": 45.62,
        "昨收价": 45.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 86308.0,
        "成交额": 3943796.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "107.FCOR"
    },
    {
        "序号": 9487,
        "name": "Planet Fitness Inc-A",
        "最新价": 67.65,
        "涨跌额": -0.4,
        "涨跌幅": -0.59,
        "开盘价": 67.98,
        "最高价": 68.31,
        "最低价": 67.2,
        "昨收价": 68.05,
        "总市值": 5963138597.0,
        "市盈率": 43.64,
        "成交量": 891700.0,
        "成交额": 60420564.0,
        "振幅": 1.63,
        "换手率": 1.01,
        "symbol": "106.PLNT"
    },
    {
        "序号": 9488,
        "name": "iShares International Developed",
        "最新价": 27.05,
        "涨跌额": -0.16,
        "涨跌幅": -0.59,
        "开盘价": 26.97,
        "最高价": 27.05,
        "最低价": 26.89,
        "昨收价": 27.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 6853.0,
        "成交额": 184659.0,
        "振幅": 0.59,
        "换手率": null,
        "symbol": "107.WPS"
    },
    {
        "序号": 9489,
        "name": "SKYX Platforms Corp",
        "最新价": 1.69,
        "涨跌额": -0.01,
        "涨跌幅": -0.59,
        "开盘价": 1.7,
        "最高价": 1.73,
        "最低价": 1.64,
        "昨收价": 1.7,
        "总市值": 155761238.0,
        "市盈率": -4.82,
        "成交量": 109656.0,
        "成交额": 186111.0,
        "振幅": 5.29,
        "换手率": 0.12,
        "symbol": "105.SKYX"
    },
    {
        "序号": 9490,
        "name": "EMX Royalty Corp",
        "最新价": 1.69,
        "涨跌额": -0.01,
        "涨跌幅": -0.59,
        "开盘价": 1.68,
        "最高价": 1.7,
        "最低价": 1.68,
        "昨收价": 1.7,
        "总市值": 189641728.0,
        "市盈率": -37.5,
        "成交量": 75804.0,
        "成交额": 128448.0,
        "振幅": 1.18,
        "换手率": 0.07,
        "symbol": "107.EMX"
    },
    {
        "序号": 9491,
        "name": "奥特泰尔",
        "最新价": 75.96,
        "涨跌额": -0.45,
        "涨跌幅": -0.59,
        "开盘价": 76.44,
        "最高价": 77.33,
        "最低价": 75.65,
        "昨收价": 76.41,
        "总市值": 3168331175.0,
        "市盈率": 11.38,
        "成交量": 196826.0,
        "成交额": 14969777.0,
        "振幅": 2.2,
        "换手率": 0.47,
        "symbol": "105.OTTR"
    },
    {
        "序号": 9492,
        "name": "JPMorgan Active China ETF",
        "最新价": 38.79,
        "涨跌额": -0.23,
        "涨跌幅": -0.59,
        "开盘价": 38.79,
        "最高价": 38.79,
        "最低价": 38.79,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 26.0,
        "成交额": 1008.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JCHI"
    },
    {
        "序号": 9493,
        "name": "第一能源",
        "最新价": 37.08,
        "涨跌额": -0.22,
        "涨跌幅": -0.59,
        "开盘价": 37.27,
        "最高价": 37.39,
        "最低价": 36.74,
        "昨收价": 37.3,
        "总市值": 21277053637.0,
        "市盈率": 40.61,
        "成交量": 4199620.0,
        "成交额": 155447930.0,
        "振幅": 1.74,
        "换手率": 0.73,
        "symbol": "106.FE"
    },
    {
        "序号": 9494,
        "name": "KBR科技",
        "最新价": 52.24,
        "涨跌额": -0.31,
        "涨跌幅": -0.59,
        "开盘价": 52.59,
        "最高价": 52.8,
        "最低价": 52.0,
        "昨收价": 52.55,
        "总市值": 7052629020.0,
        "市盈率": -36.54,
        "成交量": 2140202.0,
        "成交额": 111867653.0,
        "振幅": 1.52,
        "换手率": 1.59,
        "symbol": "106.KBR"
    },
    {
        "序号": 9495,
        "name": "Vertex Inc-A",
        "最新价": 28.58,
        "涨跌额": -0.17,
        "涨跌幅": -0.59,
        "开盘价": 28.67,
        "最高价": 28.94,
        "最低价": 28.17,
        "昨收价": 28.75,
        "总市值": 4372350940.0,
        "市盈率": -129.6,
        "成交量": 453475.0,
        "成交额": 12989362.0,
        "振幅": 2.68,
        "换手率": 0.3,
        "symbol": "105.VERX"
    },
    {
        "序号": 9496,
        "name": "斯坦泰克",
        "最新价": 77.32,
        "涨跌额": -0.46,
        "涨跌幅": -0.59,
        "开盘价": 78.11,
        "最高价": 78.34,
        "最低价": 76.94,
        "昨收价": 77.78,
        "总市值": 8579314699.0,
        "市盈率": 34.8,
        "成交量": 79682.0,
        "成交额": 6162778.0,
        "振幅": 1.8,
        "换手率": 0.07,
        "symbol": "106.STN"
    },
    {
        "序号": 9497,
        "name": "HNI Corp",
        "最新价": 40.34,
        "涨跌额": -0.24,
        "涨跌幅": -0.59,
        "开盘价": 40.45,
        "最高价": 40.47,
        "最低价": 39.86,
        "昨收价": 40.58,
        "总市值": 1879014690.0,
        "市盈率": 43.9,
        "成交量": 409220.0,
        "成交额": 16467721.0,
        "振幅": 1.5,
        "换手率": 0.88,
        "symbol": "106.HNI"
    },
    {
        "序号": 9498,
        "name": "新濠影汇",
        "最新价": 5.04,
        "涨跌额": -0.03,
        "涨跌幅": -0.59,
        "开盘价": 5.0,
        "最高价": 5.15,
        "最低价": 5.0,
        "昨收价": 5.07,
        "总市值": 1062009220.0,
        "市盈率": -5.3,
        "成交量": 33709.0,
        "成交额": 172263.0,
        "振幅": 2.96,
        "换手率": 0.02,
        "symbol": "106.MSC"
    },
    {
        "序号": 9499,
        "name": "First Trust Mortgage Income Fun",
        "最新价": 11.76,
        "涨跌额": -0.07,
        "涨跌幅": -0.59,
        "开盘价": 11.77,
        "最高价": 11.79,
        "最低价": 11.61,
        "昨收价": 11.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 2722.0,
        "成交额": 31970.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "106.FMY"
    },
    {
        "序号": 9500,
        "name": "AmpliTech Group Inc",
        "最新价": 1.68,
        "涨跌额": -0.01,
        "涨跌幅": -0.59,
        "开盘价": 1.7,
        "最高价": 1.76,
        "最低价": 1.68,
        "昨收价": 1.69,
        "总市值": 16274350.0,
        "市盈率": -5.75,
        "成交量": 18069.0,
        "成交额": 31203.0,
        "振幅": 4.73,
        "换手率": 0.19,
        "symbol": "105.AMPG"
    },
    {
        "序号": 9501,
        "name": "二倍做空道指ETF-ProShares",
        "最新价": 36.93,
        "涨跌额": -0.22,
        "涨跌幅": -0.59,
        "开盘价": 37.18,
        "最高价": 37.27,
        "最低价": 36.8,
        "昨收价": 37.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 423425.0,
        "成交额": 15673873.0,
        "振幅": 1.27,
        "换手率": null,
        "symbol": "107.DXD"
    },
    {
        "序号": 9502,
        "name": "商用汽车",
        "最新价": 6.71,
        "涨跌额": -0.04,
        "涨跌幅": -0.59,
        "开盘价": 6.77,
        "最高价": 6.85,
        "最低价": 6.69,
        "昨收价": 6.75,
        "总市值": 226174440.0,
        "市盈率": -38.6,
        "成交量": 144929.0,
        "成交额": 974977.0,
        "振幅": 2.37,
        "换手率": 0.43,
        "symbol": "105.CVGI"
    },
    {
        "序号": 9503,
        "name": "航空ETF-US Global",
        "最新价": 18.44,
        "涨跌额": -0.11,
        "涨跌幅": -0.59,
        "开盘价": 18.5,
        "最高价": 18.6,
        "最低价": 18.37,
        "昨收价": 18.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 6311398.0,
        "成交额": 116532934.0,
        "振幅": 1.24,
        "换手率": null,
        "symbol": "107.JETS"
    },
    {
        "序号": 9504,
        "name": "Invesco Advantage Municipal Inc",
        "最新价": 8.36,
        "涨跌额": -0.05,
        "涨跌幅": -0.59,
        "开盘价": 8.39,
        "最高价": 8.39,
        "最低价": 8.31,
        "昨收价": 8.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 109812.0,
        "成交额": 917293.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.VKI"
    },
    {
        "序号": 9505,
        "name": "快而精医药",
        "最新价": 41.77,
        "涨跌额": -0.25,
        "涨跌幅": -0.59,
        "开盘价": 41.54,
        "最高价": 41.78,
        "最低价": 41.37,
        "昨收价": 42.02,
        "总市值": 9531173126.0,
        "市盈率": 28.28,
        "成交量": 1421886.0,
        "成交额": 59214489.0,
        "振幅": 0.98,
        "换手率": 0.62,
        "symbol": "106.QGEN"
    },
    {
        "序号": 9506,
        "name": "Nuveen Core Plus Impact Fund",
        "最新价": 10.02,
        "涨跌额": -0.06,
        "涨跌幅": -0.6,
        "开盘价": 10.0,
        "最高价": 10.06,
        "最低价": 9.98,
        "昨收价": 10.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 41702.0,
        "成交额": 417430.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "106.NPCT"
    },
    {
        "序号": 9507,
        "name": "BlackRock Municipal Income Trus",
        "最新价": 10.02,
        "涨跌额": -0.06,
        "涨跌幅": -0.6,
        "开盘价": 10.0,
        "最高价": 10.07,
        "最低价": 10.0,
        "昨收价": 10.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 178318.0,
        "成交额": 1790765.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "106.BFK"
    },
    {
        "序号": 9508,
        "name": "奥德赛海洋勘探",
        "最新价": 3.33,
        "涨跌额": -0.02,
        "涨跌幅": -0.6,
        "开盘价": 3.35,
        "最高价": 3.4,
        "最低价": 3.07,
        "昨收价": 3.35,
        "总市值": 66539730.0,
        "市盈率": 102.4,
        "成交量": 490401.0,
        "成交额": 1563615.0,
        "振幅": 9.85,
        "换手率": 2.45,
        "symbol": "105.OMEX"
    },
    {
        "序号": 9509,
        "name": "Invesco S&P Emerging Markets Lo",
        "最新价": 23.31,
        "涨跌额": -0.14,
        "涨跌幅": -0.6,
        "开盘价": 23.35,
        "最高价": 23.37,
        "最低价": 23.27,
        "昨收价": 23.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 74780.0,
        "成交额": 1742708.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.EELV"
    },
    {
        "序号": 9510,
        "name": "iShares ESG U.S. Aggregate Bond",
        "最新价": 46.57,
        "涨跌额": -0.28,
        "涨跌幅": -0.6,
        "开盘价": 46.64,
        "最高价": 46.71,
        "最低价": 46.54,
        "昨收价": 46.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 619899.0,
        "成交额": 28884100.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "107.EAGG"
    },
    {
        "序号": 9511,
        "name": "Kurv Yield Premium Strategy Goo",
        "最新价": 26.54,
        "涨跌额": -0.16,
        "涨跌幅": -0.6,
        "开盘价": 26.54,
        "最高价": 26.54,
        "最低价": 26.54,
        "昨收价": 26.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 88.0,
        "成交额": 2335.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GOOP"
    },
    {
        "序号": 9512,
        "name": "Avantis Emerging Markets Value ",
        "最新价": 44.7,
        "涨跌额": -0.27,
        "涨跌幅": -0.6,
        "开盘价": 44.63,
        "最高价": 44.82,
        "最低价": 44.56,
        "昨收价": 44.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 28636.0,
        "成交额": 1279009.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "107.AVES"
    },
    {
        "序号": 9513,
        "name": "Goldman Sachs ActiveBeta Japan ",
        "最新价": 34.7,
        "涨跌额": -0.21,
        "涨跌幅": -0.6,
        "开盘价": 34.7,
        "最高价": 34.7,
        "最低价": 34.7,
        "昨收价": 34.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 39.0,
        "成交额": 1353.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GSJY"
    },
    {
        "序号": 9514,
        "name": "The AZEK Co Inc-A",
        "最新价": 34.69,
        "涨跌额": -0.21,
        "涨跌幅": -0.6,
        "开盘价": 34.21,
        "最高价": 35.24,
        "最低价": 34.02,
        "昨收价": 34.9,
        "总市值": 5123692637.0,
        "市盈率": 75.4,
        "成交量": 1627992.0,
        "成交额": 56631500.0,
        "振幅": 3.5,
        "换手率": 1.1,
        "symbol": "106.AZEK"
    },
    {
        "序号": 9515,
        "name": "星座品牌-A",
        "最新价": 234.44,
        "涨跌额": -1.42,
        "涨跌幅": -0.6,
        "开盘价": 235.56,
        "最高价": 236.63,
        "最低价": 234.18,
        "昨收价": 235.86,
        "总市值": 43063327554.0,
        "市盈率": 28.39,
        "成交量": 901547.0,
        "成交额": 211512017.0,
        "振幅": 1.04,
        "换手率": 0.49,
        "symbol": "106.STZ"
    },
    {
        "序号": 9516,
        "name": "The Real Good Food Co Inc-A",
        "最新价": 1.65,
        "涨跌额": -0.01,
        "涨跌幅": -0.6,
        "开盘价": 1.61,
        "最高价": 1.68,
        "最低价": 1.6,
        "昨收价": 1.66,
        "总市值": 55578937.0,
        "市盈率": -3.66,
        "成交量": 92100.0,
        "成交额": 150498.0,
        "振幅": 4.82,
        "换手率": 0.27,
        "symbol": "105.RGF"
    },
    {
        "序号": 9517,
        "name": "Cambria ETF Trust Cambria Emerg",
        "最新价": 29.69,
        "涨跌额": -0.18,
        "涨跌幅": -0.6,
        "开盘价": 29.61,
        "最高价": 29.75,
        "最低价": 29.48,
        "昨收价": 29.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 40800.0,
        "成交额": 1208777.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.EYLD"
    },
    {
        "序号": 9518,
        "name": "SJW Group",
        "最新价": 67.56,
        "涨跌额": -0.41,
        "涨跌幅": -0.6,
        "开盘价": 68.18,
        "最高价": 68.48,
        "最低价": 67.43,
        "昨收价": 67.97,
        "总市值": 2157393683.0,
        "市盈率": 21.67,
        "成交量": 68857.0,
        "成交额": 4657831.0,
        "振幅": 1.54,
        "换手率": 0.22,
        "symbol": "106.SJW"
    },
    {
        "序号": 9519,
        "name": "Fidelity Sustainable Core Plus ",
        "最新价": 46.13,
        "涨跌额": -0.28,
        "涨跌幅": -0.6,
        "开盘价": 46.13,
        "最高价": 46.13,
        "最低价": 46.13,
        "昨收价": 46.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 25228.0,
        "成交额": 1163767.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.FSBD"
    },
    {
        "序号": 9520,
        "name": "Bank of America Corp Series E P",
        "最新价": 21.41,
        "涨跌额": -0.13,
        "涨跌幅": -0.6,
        "开盘价": 21.54,
        "最高价": 21.6,
        "最低价": 21.33,
        "昨收价": 21.54,
        "总市值": 271714.0,
        "市盈率": null,
        "成交量": 9527.0,
        "成交额": 204495.0,
        "振幅": 1.25,
        "换手率": 75.07,
        "symbol": "106.BAC_E"
    },
    {
        "序号": 9521,
        "name": "宣伟",
        "最新价": 289.82,
        "涨跌额": -1.76,
        "涨跌幅": -0.6,
        "开盘价": 290.92,
        "最高价": 292.44,
        "最低价": 288.27,
        "昨收价": 291.58,
        "总市值": 74184092204.0,
        "市盈率": 30.67,
        "成交量": 1265646.0,
        "成交额": 366821488.0,
        "振幅": 1.43,
        "换手率": 0.49,
        "symbol": "106.SHW"
    },
    {
        "序号": 9522,
        "name": "SITE Centers Corp Class A Pfd",
        "最新价": 23.05,
        "涨跌额": -0.14,
        "涨跌幅": -0.6,
        "开盘价": 23.31,
        "最高价": 23.31,
        "最低价": 23.05,
        "昨收价": 23.19,
        "总市值": 8067500.0,
        "市盈率": null,
        "成交量": 17830.0,
        "成交额": 413361.0,
        "振幅": 1.12,
        "换手率": 5.09,
        "symbol": "106.SITC_A"
    },
    {
        "序号": 9523,
        "name": "新兴市场地方货币债ETF",
        "最新价": 24.69,
        "涨跌额": -0.15,
        "涨跌幅": -0.6,
        "开盘价": 24.76,
        "最高价": 24.81,
        "最低价": 24.67,
        "昨收价": 24.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 1466230.0,
        "成交额": 36222082.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "107.EMLC"
    },
    {
        "序号": 9524,
        "name": "Gaming and Leisure Properties I",
        "最新价": 46.08,
        "涨跌额": -0.28,
        "涨跌幅": -0.6,
        "开盘价": 46.05,
        "最高价": 46.6,
        "最低价": 45.94,
        "昨收价": 46.36,
        "总市值": 12304084838.0,
        "市盈率": 17.16,
        "成交量": 1073790.0,
        "成交额": 49541199.0,
        "振幅": 1.42,
        "换手率": 0.4,
        "symbol": "105.GLPI"
    },
    {
        "序号": 9525,
        "name": "Invesco Equal Weight 0-30 Year ",
        "最新价": 27.94,
        "涨跌额": -0.17,
        "涨跌幅": -0.6,
        "开盘价": 27.91,
        "最高价": 27.98,
        "最低价": 27.85,
        "昨收价": 28.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 52313.0,
        "成交额": 1459524.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "105.GOVI"
    },
    {
        "序号": 9526,
        "name": "iShares iBonds Dec 2029 Term Tr",
        "最新价": 21.36,
        "涨跌额": -0.13,
        "涨跌幅": -0.6,
        "开盘价": 21.4,
        "最高价": 21.41,
        "最低价": 21.34,
        "昨收价": 21.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 29797.0,
        "成交额": 636846.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "105.IBTJ"
    },
    {
        "序号": 9527,
        "name": "Affiliated Managers Group Inc N",
        "最新价": 21.32,
        "涨跌额": -0.13,
        "涨跌幅": -0.61,
        "开盘价": 21.39,
        "最高价": 21.63,
        "最低价": 21.19,
        "昨收价": 21.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 29302.0,
        "成交额": 625104.0,
        "振幅": 2.05,
        "换手率": null,
        "symbol": "106.MGR"
    },
    {
        "序号": 9528,
        "name": "长期公司债指数ETF-Vanguard",
        "最新价": 77.06,
        "涨跌额": -0.47,
        "涨跌幅": -0.61,
        "开盘价": 76.98,
        "最高价": 77.34,
        "最低价": 76.86,
        "昨收价": 77.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 1364793.0,
        "成交额": 105144179.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "105.VCLT"
    },
    {
        "序号": 9529,
        "name": "New York Community Bancorp Inc ",
        "最新价": 22.94,
        "涨跌额": -0.14,
        "涨跌幅": -0.61,
        "开盘价": 23.17,
        "最高价": 23.17,
        "最低价": 22.87,
        "昨收价": 23.08,
        "总市值": 11814100.0,
        "市盈率": null,
        "成交量": 32146.0,
        "成交额": 739225.0,
        "振幅": 1.3,
        "换手率": 6.24,
        "symbol": "106.NYCB_A"
    },
    {
        "序号": 9530,
        "name": "Innovator 20+ Year Treasury Bon",
        "最新价": 19.61,
        "涨跌额": -0.12,
        "涨跌幅": -0.61,
        "开盘价": 19.54,
        "最高价": 19.63,
        "最低价": 19.49,
        "昨收价": 19.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 3387.0,
        "成交额": 66243.0,
        "振幅": 0.71,
        "换手率": null,
        "symbol": "107.TBJL"
    },
    {
        "序号": 9531,
        "name": "科帕特",
        "最新价": 47.39,
        "涨跌额": -0.29,
        "涨跌幅": -0.61,
        "开盘价": 47.21,
        "最高价": 47.63,
        "最低价": 47.08,
        "昨收价": 47.68,
        "总市值": 45505368036.0,
        "市盈率": 34.36,
        "成交量": 4750475.0,
        "成交额": 225025367.0,
        "振幅": 1.15,
        "换手率": 0.49,
        "symbol": "105.CPRT"
    },
    {
        "序号": 9532,
        "name": "Credit Suisse X-Links Gold Shar",
        "最新价": 142.12,
        "涨跌额": -0.87,
        "涨跌幅": -0.61,
        "开盘价": 141.7,
        "最高价": 142.3,
        "最低价": 141.65,
        "昨收价": 142.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 2745.0,
        "成交额": 389342.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "105.GLDI"
    },
    {
        "序号": 9533,
        "name": "Velocity Financial Inc",
        "最新价": 14.7,
        "涨跌额": -0.09,
        "涨跌幅": -0.61,
        "开盘价": 14.91,
        "最高价": 15.27,
        "最低价": 14.31,
        "昨收价": 14.79,
        "总市值": 481894900.0,
        "市盈率": 11.11,
        "成交量": 20727.0,
        "成交额": 307158.0,
        "振幅": 6.49,
        "换手率": 0.06,
        "symbol": "106.VEL"
    },
    {
        "序号": 9534,
        "name": "Hartford Core Bond ETF",
        "最新价": 34.3,
        "涨跌额": -0.21,
        "涨跌幅": -0.61,
        "开盘价": 34.36,
        "最高价": 34.36,
        "最低价": 34.26,
        "昨收价": 34.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 13255.0,
        "成交额": 454421.0,
        "振幅": 0.29,
        "换手率": null,
        "symbol": "107.HCRB"
    },
    {
        "序号": 9535,
        "name": "Energy Transfer LP Series I Pfd",
        "最新价": 9.8,
        "涨跌额": -0.06,
        "涨跌幅": -0.61,
        "开盘价": 9.75,
        "最高价": 9.84,
        "最低价": 9.75,
        "昨收价": 9.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 34294.0,
        "成交额": 336149.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "106.ET_I"
    },
    {
        "序号": 9536,
        "name": "联合安全银行",
        "最新价": 8.14,
        "涨跌额": -0.05,
        "涨跌幅": -0.61,
        "开盘价": 7.91,
        "最高价": 8.21,
        "最低价": 7.82,
        "昨收价": 8.19,
        "总市值": 139487813.0,
        "市盈率": 7.07,
        "成交量": 13926.0,
        "成交额": 112984.0,
        "振幅": 4.76,
        "换手率": 0.08,
        "symbol": "105.UBFO"
    },
    {
        "序号": 9537,
        "name": "iShares 1-3 Year International ",
        "最新价": 69.85,
        "涨跌额": -0.43,
        "涨跌幅": -0.61,
        "开盘价": 69.84,
        "最高价": 69.88,
        "最低价": 69.77,
        "昨收价": 70.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 2683.0,
        "成交额": 187305.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.ISHG"
    },
    {
        "序号": 9538,
        "name": "First Trust Utilities AlphaDEX ",
        "最新价": 30.86,
        "涨跌额": -0.19,
        "涨跌幅": -0.61,
        "开盘价": 30.96,
        "最高价": 31.0,
        "最低价": 30.71,
        "昨收价": 31.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 51157.0,
        "成交额": 1576002.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.FXU"
    },
    {
        "序号": 9539,
        "name": "Wintrust Financial Corp Series ",
        "最新价": 24.35,
        "涨跌额": -0.15,
        "涨跌幅": -0.61,
        "开盘价": 24.52,
        "最高价": 24.52,
        "最低价": 24.35,
        "昨收价": 24.5,
        "总市值": 280025.0,
        "市盈率": null,
        "成交量": 7649.0,
        "成交额": 186947.0,
        "振幅": 0.69,
        "换手率": 66.51,
        "symbol": "105.WTFCP"
    },
    {
        "序号": 9540,
        "name": "Delaware Investments National M",
        "最新价": 9.73,
        "涨跌额": -0.06,
        "涨跌幅": -0.61,
        "开盘价": 9.74,
        "最高价": 9.77,
        "最低价": 9.71,
        "昨收价": 9.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 19371.0,
        "成交额": 188737.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.VFL"
    },
    {
        "序号": 9541,
        "name": "Truist Financial Corp Series I ",
        "最新价": 21.05,
        "涨跌额": -0.13,
        "涨跌幅": -0.61,
        "开盘价": 21.14,
        "最高价": 21.15,
        "最低价": 20.83,
        "昨收价": 21.18,
        "总市值": 105250.0,
        "市盈率": null,
        "成交量": 11778.0,
        "成交额": 247595.0,
        "振幅": 1.51,
        "换手率": 235.56,
        "symbol": "106.TFC_I"
    },
    {
        "序号": 9542,
        "name": "Duke Energy Corp Debentures",
        "最新价": 24.23,
        "涨跌额": -0.15,
        "涨跌幅": -0.62,
        "开盘价": 24.27,
        "最高价": 24.3,
        "最低价": 24.17,
        "昨收价": 24.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 19261.0,
        "成交额": 466453.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "106.DUKB"
    },
    {
        "序号": 9543,
        "name": "Conduent Inc",
        "最新价": 3.23,
        "涨跌额": -0.02,
        "涨跌幅": -0.62,
        "开盘价": 3.23,
        "最高价": 3.29,
        "最低价": 3.21,
        "昨收价": 3.25,
        "总市值": 701837327.0,
        "市盈率": -1.11,
        "成交量": 983586.0,
        "成交额": 3187868.0,
        "振幅": 2.46,
        "换手率": 0.45,
        "symbol": "105.CNDT"
    },
    {
        "序号": 9544,
        "name": "阿里特",
        "最新价": 61.35,
        "涨跌额": -0.38,
        "涨跌幅": -0.62,
        "开盘价": 61.73,
        "最高价": 61.97,
        "最低价": 61.04,
        "昨收价": 61.73,
        "总市值": 3526238797.0,
        "市盈率": 14.26,
        "成交量": 373006.0,
        "成交额": 22880886.0,
        "振幅": 1.51,
        "换手率": 0.65,
        "symbol": "106.ALE"
    },
    {
        "序号": 9545,
        "name": "二倍做多欧元ETF-ProShares",
        "最新价": 11.29,
        "涨跌额": -0.07,
        "涨跌幅": -0.62,
        "开盘价": 11.22,
        "最高价": 11.29,
        "最低价": 11.22,
        "昨收价": 11.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 23644.0,
        "成交额": 265789.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.ULE"
    },
    {
        "序号": 9546,
        "name": "Zumiez Inc",
        "最新价": 19.35,
        "涨跌额": -0.12,
        "涨跌幅": -0.62,
        "开盘价": 19.46,
        "最高价": 19.49,
        "最低价": 19.05,
        "昨收价": 19.47,
        "总市值": 383788519.0,
        "市盈率": -21.7,
        "成交量": 207772.0,
        "成交额": 4011751.0,
        "振幅": 2.26,
        "换手率": 1.05,
        "symbol": "105.ZUMZ"
    },
    {
        "序号": 9547,
        "name": "Oscar Health Inc-A",
        "最新价": 8.06,
        "涨跌额": -0.05,
        "涨跌幅": -0.62,
        "开盘价": 8.05,
        "最高价": 8.22,
        "最低价": 8.0,
        "昨收价": 8.11,
        "总市值": 1822456764.0,
        "市盈率": -5.26,
        "成交量": 1154076.0,
        "成交额": 9368044.0,
        "振幅": 2.71,
        "换手率": 0.51,
        "symbol": "106.OSCR"
    },
    {
        "序号": 9548,
        "name": "Federal Realty Investment Trust",
        "最新价": 22.5,
        "涨跌额": -0.14,
        "涨跌幅": -0.62,
        "开盘价": 22.52,
        "最高价": 22.52,
        "最低价": 22.31,
        "昨收价": 22.64,
        "总市值": 135000.0,
        "市盈率": null,
        "成交量": 5191.0,
        "成交额": 116679.0,
        "振幅": 0.93,
        "换手率": 86.52,
        "symbol": "106.FRT_C"
    },
    {
        "序号": 9549,
        "name": "Federal Agricultural Mortgage C",
        "最新价": 19.28,
        "涨跌额": -0.12,
        "涨跌幅": -0.62,
        "开盘价": 19.26,
        "最高价": 19.28,
        "最低价": 18.87,
        "昨收价": 19.4,
        "总市值": 96400000.0,
        "市盈率": null,
        "成交量": 8098.0,
        "成交额": 154489.0,
        "振幅": 2.11,
        "换手率": 0.16,
        "symbol": "106.AGM_G"
    },
    {
        "序号": 9550,
        "name": "MFS High Yield Municipal Trust",
        "最新价": 3.21,
        "涨跌额": -0.02,
        "涨跌幅": -0.62,
        "开盘价": 3.24,
        "最高价": 3.24,
        "最低价": 3.17,
        "昨收价": 3.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 78498.0,
        "成交额": 251366.0,
        "振幅": 2.17,
        "换手率": null,
        "symbol": "106.CMU"
    },
    {
        "序号": 9551,
        "name": "PDF Solutions Inc",
        "最新价": 30.45,
        "涨跌额": -0.19,
        "涨跌幅": -0.62,
        "开盘价": 30.49,
        "最高价": 30.96,
        "最低价": 30.39,
        "昨收价": 30.64,
        "总市值": 1165059721.0,
        "市盈率": 431.34,
        "成交量": 151787.0,
        "成交额": 4640563.0,
        "振幅": 1.86,
        "换手率": 0.4,
        "symbol": "105.PDFS"
    },
    {
        "序号": 9552,
        "name": "新兴市场互联网电商ETF",
        "最新价": 30.42,
        "涨跌额": -0.19,
        "涨跌幅": -0.62,
        "开盘价": 30.45,
        "最高价": 30.58,
        "最低价": 30.34,
        "昨收价": 30.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 53081.0,
        "成交额": 1615814.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.EMQQ"
    },
    {
        "序号": 9553,
        "name": "Xtrackers International Real Es",
        "最新价": 20.74,
        "涨跌额": -0.13,
        "涨跌幅": -0.62,
        "开盘价": 20.67,
        "最高价": 20.82,
        "最低价": 20.67,
        "昨收价": 20.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 71935.0,
        "成交额": 1492801.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.HAUZ"
    },
    {
        "序号": 9554,
        "name": "US Treasury 7 Year Note ETF",
        "最新价": 47.77,
        "涨跌额": -0.3,
        "涨跌幅": -0.62,
        "开盘价": 47.77,
        "最高价": 47.77,
        "最低价": 47.77,
        "昨收价": 48.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 12.0,
        "成交额": 573.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.USVN"
    },
    {
        "序号": 9555,
        "name": "ETRACS 2xMonthly Pay Leveraged ",
        "最新价": 9.55,
        "涨跌额": -0.06,
        "涨跌幅": -0.62,
        "开盘价": 9.53,
        "最高价": 9.58,
        "最低价": 9.53,
        "昨收价": 9.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 14423.0,
        "成交额": 137836.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.PFFL"
    },
    {
        "序号": 9556,
        "name": "First Trust Core Investment Gra",
        "最新价": 20.69,
        "涨跌额": -0.13,
        "涨跌幅": -0.62,
        "开盘价": 20.7,
        "最高价": 20.7,
        "最低价": 20.68,
        "昨收价": 20.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 4000.0,
        "成交额": 82734.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "107.FTCB"
    },
    {
        "序号": 9557,
        "name": "Zscaler Inc",
        "最新价": 198.8,
        "涨跌额": -1.25,
        "涨跌幅": -0.62,
        "开盘价": 198.0,
        "最高价": 202.73,
        "最低价": 197.59,
        "昨收价": 200.05,
        "总市值": 29488688866.0,
        "市盈率": -175.89,
        "成交量": 2034754.0,
        "成交额": 405345664.0,
        "振幅": 2.57,
        "换手率": 1.37,
        "symbol": "105.ZS"
    },
    {
        "序号": 9558,
        "name": "BlackRock Taxable Municipal Bon",
        "最新价": 15.89,
        "涨跌额": -0.1,
        "涨跌幅": -0.63,
        "开盘价": 15.91,
        "最高价": 15.97,
        "最低价": 15.89,
        "昨收价": 15.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 181562.0,
        "成交额": 2889028.0,
        "振幅": 0.5,
        "换手率": null,
        "symbol": "106.BBN"
    },
    {
        "序号": 9559,
        "name": "赫克",
        "最新价": 20.65,
        "涨跌额": -0.13,
        "涨跌幅": -0.63,
        "开盘价": 20.62,
        "最高价": 20.87,
        "最低价": 20.44,
        "昨收价": 20.78,
        "总市值": 133443150.0,
        "市盈率": 39.35,
        "成交量": 5150.0,
        "成交额": 106114.0,
        "振幅": 2.07,
        "换手率": 0.08,
        "symbol": "105.HURC"
    },
    {
        "序号": 9560,
        "name": "Global X U.S. Preferred ETF",
        "最新价": 19.05,
        "涨跌额": -0.12,
        "涨跌幅": -0.63,
        "开盘价": 19.08,
        "最高价": 19.14,
        "最低价": 19.02,
        "昨收价": 19.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 798629.0,
        "成交额": 15230038.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.PFFD"
    },
    {
        "序号": 9561,
        "name": "iShares ESG MSCI EM Leaders ETF",
        "最新价": 42.86,
        "涨跌额": -0.27,
        "涨跌幅": -0.63,
        "开盘价": 42.81,
        "最高价": 43.0,
        "最低价": 42.81,
        "昨收价": 43.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 1078.0,
        "成交额": 46224.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.LDEM"
    },
    {
        "序号": 9562,
        "name": "Affiliated Managers Group Inc N",
        "最新价": 17.44,
        "涨跌额": -0.11,
        "涨跌幅": -0.63,
        "开盘价": 17.52,
        "最高价": 17.56,
        "最低价": 17.38,
        "昨收价": 17.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 27494.0,
        "成交额": 479039.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.MGRB"
    },
    {
        "序号": 9563,
        "name": "ProShares UltraShort Industrial",
        "最新价": 12.68,
        "涨跌额": -0.08,
        "涨跌幅": -0.63,
        "开盘价": 12.62,
        "最高价": 12.69,
        "最低价": 12.58,
        "昨收价": 12.76,
        "总市值": null,
        "市盈率": null,
        "成交量": 4563.0,
        "成交额": 57746.0,
        "振幅": 0.86,
        "换手率": null,
        "symbol": "107.SIJ"
    },
    {
        "序号": 9564,
        "name": "查特工业",
        "最新价": 123.63,
        "涨跌额": -0.78,
        "涨跌幅": -0.63,
        "开盘价": 124.26,
        "最高价": 126.16,
        "最低价": 121.29,
        "昨收价": 124.41,
        "总市值": 5285259521.0,
        "市盈率": -123.2,
        "成交量": 404690.0,
        "成交额": 50057650.0,
        "振幅": 3.91,
        "换手率": 0.95,
        "symbol": "106.GTLS"
    },
    {
        "序号": 9565,
        "name": "Crinetics Pharmaceuticals Inc",
        "最新价": 33.27,
        "涨跌额": -0.21,
        "涨跌幅": -0.63,
        "开盘价": 32.95,
        "最高价": 33.56,
        "最低价": 32.75,
        "昨收价": 33.48,
        "总市值": 2222411280.0,
        "市盈率": -11.14,
        "成交量": 529140.0,
        "成交额": 17599092.0,
        "振幅": 2.42,
        "换手率": 0.79,
        "symbol": "105.CRNX"
    },
    {
        "序号": 9566,
        "name": "GFL Environmental Inc",
        "最新价": 28.46,
        "涨跌额": -0.18,
        "涨跌幅": -0.63,
        "开盘价": 28.71,
        "最高价": 28.81,
        "最低价": 28.21,
        "昨收价": 28.64,
        "总市值": 10529772616.0,
        "市盈率": -117.13,
        "成交量": 604197.0,
        "成交额": 17203357.0,
        "振幅": 2.09,
        "换手率": 0.16,
        "symbol": "106.GFL"
    },
    {
        "序号": 9567,
        "name": "MAX Airlines -3X Inverse Levera",
        "最新价": 26.86,
        "涨跌额": -0.17,
        "涨跌幅": -0.63,
        "开盘价": 26.86,
        "最高价": 26.86,
        "最低价": 26.86,
        "昨收价": 27.03,
        "总市值": 19363620064.0,
        "市盈率": 6.1,
        "成交量": 37.0,
        "成交额": 993.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JETD"
    },
    {
        "序号": 9568,
        "name": "USCF Energy Commodity Strategy ",
        "最新价": 34.72,
        "涨跌额": -0.22,
        "涨跌幅": -0.63,
        "开盘价": 34.68,
        "最高价": 34.72,
        "最低价": 34.68,
        "昨收价": 34.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 561.0,
        "成交额": 19455.0,
        "振幅": 0.11,
        "换手率": null,
        "symbol": "107.USE"
    },
    {
        "序号": 9569,
        "name": "Entergy Mississippi LLC Bonds",
        "最新价": 22.09,
        "涨跌额": -0.14,
        "涨跌幅": -0.63,
        "开盘价": 22.14,
        "最高价": 22.41,
        "最低价": 22.09,
        "昨收价": 22.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 4818.0,
        "成交额": 106840.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "106.EMP"
    },
    {
        "序号": 9570,
        "name": "日常消费指数ETF-Vanguard",
        "最新价": 186.03,
        "涨跌额": -1.18,
        "涨跌幅": -0.63,
        "开盘价": 187.11,
        "最高价": 187.23,
        "最低价": 185.89,
        "昨收价": 187.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 96706.0,
        "成交额": 18018917.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.VDC"
    },
    {
        "序号": 9571,
        "name": "强生",
        "最新价": 154.42,
        "涨跌额": -0.98,
        "涨跌幅": -0.63,
        "开盘价": 155.42,
        "最高价": 155.85,
        "最低价": 153.84,
        "昨收价": 155.4,
        "总市值": 371731964500.0,
        "市盈率": 10.74,
        "成交量": 6254673.0,
        "成交额": 965782512.0,
        "振幅": 1.29,
        "换手率": 0.26,
        "symbol": "106.JNJ"
    },
    {
        "序号": 9572,
        "name": "BlackRock 2037 Municipal Target",
        "最新价": 23.6,
        "涨跌额": -0.15,
        "涨跌幅": -0.63,
        "开盘价": 23.74,
        "最高价": 24.19,
        "最低价": 23.41,
        "昨收价": 23.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 65530.0,
        "成交额": 1551018.0,
        "振幅": 3.28,
        "换手率": null,
        "symbol": "106.BMN"
    },
    {
        "序号": 9573,
        "name": "Constellation Acquisition Corp ",
        "最新价": 11.01,
        "涨跌额": -0.07,
        "涨跌幅": -0.63,
        "开盘价": 11.03,
        "最高价": 11.06,
        "最低价": 11.01,
        "昨收价": 11.08,
        "总市值": 134804711.0,
        "市盈率": 48.82,
        "成交量": 2517.0,
        "成交额": 27743.0,
        "振幅": 0.45,
        "换手率": 0.02,
        "symbol": "106.CSTA"
    },
    {
        "序号": 9574,
        "name": "Chicken Soup for the Soul Enter",
        "最新价": 6.29,
        "涨跌额": -0.04,
        "涨跌幅": -0.63,
        "开盘价": 6.45,
        "最高价": 6.54,
        "最低价": 6.28,
        "昨收价": 6.33,
        "总市值": 25622258.0,
        "市盈率": null,
        "成交量": 19523.0,
        "成交额": 124331.0,
        "振幅": 4.11,
        "换手率": 0.48,
        "symbol": "105.CSSEP"
    },
    {
        "序号": 9575,
        "name": "VictoryShares Emerging Markets ",
        "最新价": 42.44,
        "涨跌额": -0.27,
        "涨跌幅": -0.63,
        "开盘价": 42.44,
        "最高价": 42.44,
        "最低价": 42.44,
        "昨收价": 42.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 238.0,
        "成交额": 10100.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.UEVM"
    },
    {
        "序号": 9576,
        "name": "康西哥",
        "最新价": 73.77,
        "涨跌额": -0.47,
        "涨跌幅": -0.63,
        "开盘价": 74.63,
        "最高价": 74.63,
        "最低价": 73.41,
        "昨收价": 74.24,
        "总市值": 39408007770.0,
        "市盈率": 16.12,
        "成交量": 2693987.0,
        "成交额": 198929367.0,
        "振幅": 1.64,
        "换手率": 0.5,
        "symbol": "106.CNC"
    },
    {
        "序号": 9577,
        "name": "Vector Group Ltd",
        "最新价": 10.96,
        "涨跌额": -0.07,
        "涨跌幅": -0.63,
        "开盘价": 11.05,
        "最高价": 11.05,
        "最低价": 10.81,
        "昨收价": 11.03,
        "总市值": 1709025899.0,
        "市盈率": 9.84,
        "成交量": 660396.0,
        "成交额": 7214586.0,
        "振幅": 2.18,
        "换手率": 0.42,
        "symbol": "106.VGR"
    },
    {
        "序号": 9578,
        "name": "实耐宝",
        "最新价": 278.22,
        "涨跌额": -1.78,
        "涨跌幅": -0.64,
        "开盘价": 279.78,
        "最高价": 280.93,
        "最低价": 277.68,
        "昨收价": 280.0,
        "总市值": 14684440193.0,
        "市盈率": 14.76,
        "成交量": 222261.0,
        "成交额": 61956273.0,
        "振幅": 1.16,
        "换手率": 0.42,
        "symbol": "106.SNA"
    },
    {
        "序号": 9579,
        "name": "Sonnet BioTherapuetics Holdings",
        "最新价": 1.56,
        "涨跌额": -0.01,
        "涨跌幅": -0.64,
        "开盘价": 1.6,
        "最高价": 1.67,
        "最低价": 1.4,
        "昨收价": 1.57,
        "总市值": 4768415.0,
        "市盈率": -0.21,
        "成交量": 122856.0,
        "成交额": 188788.0,
        "振幅": 17.2,
        "换手率": 4.02,
        "symbol": "105.SONN"
    },
    {
        "序号": 9580,
        "name": "MongoDB Inc-A",
        "最新价": 381.79,
        "涨跌额": -2.45,
        "涨跌幅": -0.64,
        "开盘价": 380.37,
        "最高价": 382.17,
        "最低价": 373.23,
        "昨收价": 384.24,
        "总市值": 27556699648.0,
        "市盈率": -148.52,
        "成交量": 3107964.0,
        "成交额": 1175842720.0,
        "振幅": 2.33,
        "换手率": 4.31,
        "symbol": "105.MDB"
    },
    {
        "序号": 9581,
        "name": "ARKO Corp",
        "最新价": 7.78,
        "涨跌额": -0.05,
        "涨跌幅": -0.64,
        "开盘价": 7.81,
        "最高价": 7.86,
        "最低价": 7.74,
        "昨收价": 7.83,
        "总市值": 908775592.0,
        "市盈率": 19.71,
        "成交量": 173246.0,
        "成交额": 1352633.0,
        "振幅": 1.53,
        "换手率": 0.15,
        "symbol": "105.ARKO"
    },
    {
        "序号": 9582,
        "name": "UVA Unconstrained Medium-Term F",
        "最新价": 21.78,
        "涨跌额": -0.14,
        "涨跌幅": -0.64,
        "开盘价": 21.71,
        "最高价": 21.78,
        "最低价": 21.71,
        "昨收价": 21.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 4825.0,
        "成交额": 104750.0,
        "振幅": 0.32,
        "换手率": null,
        "symbol": "107.FFIU"
    },
    {
        "序号": 9583,
        "name": "MFS Charter Income Trust",
        "最新价": 6.22,
        "涨跌额": -0.04,
        "涨跌幅": -0.64,
        "开盘价": 6.23,
        "最高价": 6.27,
        "最低价": 6.22,
        "昨收价": 6.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 29675.0,
        "成交额": 185168.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "106.MCR"
    },
    {
        "序号": 9584,
        "name": "T. Rowe Price QM U.S. Bond ETF",
        "最新价": 41.93,
        "涨跌额": -0.27,
        "涨跌幅": -0.64,
        "开盘价": 41.99,
        "最高价": 41.99,
        "最低价": 41.93,
        "昨收价": 42.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 319.0,
        "成交额": 13381.0,
        "振幅": 0.14,
        "换手率": null,
        "symbol": "107.TAGG"
    },
    {
        "序号": 9585,
        "name": "美盛",
        "最新价": 35.68,
        "涨跌额": -0.23,
        "涨跌幅": -0.64,
        "开盘价": 36.0,
        "最高价": 36.23,
        "最低价": 35.31,
        "昨收价": 35.91,
        "总市值": 11661489034.0,
        "市盈率": 8.82,
        "成交量": 3011577.0,
        "成交额": 107514335.0,
        "振幅": 2.56,
        "换手率": 0.92,
        "symbol": "106.MOS"
    },
    {
        "序号": 9586,
        "name": "Fidelity MSCI Consumer Staples ",
        "最新价": 43.41,
        "涨跌额": -0.28,
        "涨跌幅": -0.64,
        "开盘价": 43.68,
        "最高价": 43.68,
        "最低价": 43.35,
        "昨收价": 43.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 128166.0,
        "成交额": 5571814.0,
        "振幅": 0.76,
        "换手率": null,
        "symbol": "107.FSTA"
    },
    {
        "序号": 9587,
        "name": "Alpha Tau Medical Ltd",
        "最新价": 3.1,
        "涨跌额": -0.02,
        "涨跌幅": -0.64,
        "开盘价": 3.12,
        "最高价": 3.18,
        "最低价": 3.02,
        "昨收价": 3.12,
        "总市值": 215368858.0,
        "市盈率": -9.41,
        "成交量": 21348.0,
        "成交额": 65455.0,
        "振幅": 5.13,
        "换手率": 0.03,
        "symbol": "105.DRTS"
    },
    {
        "序号": 9588,
        "name": "Bank of America Corp Series NN ",
        "最新价": 18.6,
        "涨跌额": -0.12,
        "涨跌幅": -0.64,
        "开盘价": 18.67,
        "最高价": 18.71,
        "最低价": 18.51,
        "昨收价": 18.72,
        "总市值": 818400.0,
        "市盈率": null,
        "成交量": 93666.0,
        "成交额": 1741932.0,
        "振幅": 1.07,
        "换手率": 212.88,
        "symbol": "106.BAC_O"
    },
    {
        "序号": 9589,
        "name": "GraniteShares 1x Short AMD Dail",
        "最新价": 20.14,
        "涨跌额": -0.13,
        "涨跌幅": -0.64,
        "开盘价": 20.06,
        "最高价": 20.35,
        "最低价": 20.06,
        "昨收价": 20.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 6530.0,
        "成交额": 131564.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "105.AMDS"
    },
    {
        "序号": 9590,
        "name": "Global Blue Group Holding AG",
        "最新价": 4.64,
        "涨跌额": -0.03,
        "涨跌幅": -0.64,
        "开盘价": 4.63,
        "最高价": 4.74,
        "最低价": 4.61,
        "昨收价": 4.67,
        "总市值": 883844795.0,
        "市盈率": 131.5,
        "成交量": 15036.0,
        "成交额": 70600.0,
        "振幅": 2.78,
        "换手率": 0.01,
        "symbol": "106.GB"
    },
    {
        "序号": 9591,
        "name": "SPDR S&P Emerging Markets Small",
        "最新价": 55.65,
        "涨跌额": -0.36,
        "涨跌幅": -0.64,
        "开盘价": 55.6,
        "最高价": 55.7,
        "最低价": 55.45,
        "昨收价": 56.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 23379.0,
        "成交额": 1299789.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.EWX"
    },
    {
        "序号": 9592,
        "name": "泰利福",
        "最新价": 231.08,
        "涨跌额": -1.5,
        "涨跌幅": -0.64,
        "开盘价": 232.68,
        "最高价": 234.1,
        "最低价": 230.92,
        "昨收价": 232.58,
        "总市值": 10859064797.0,
        "市盈率": 26.89,
        "成交量": 235614.0,
        "成交额": 54586938.0,
        "振幅": 1.37,
        "换手率": 0.5,
        "symbol": "106.TFX"
    },
    {
        "序号": 9593,
        "name": "7-10年ETF-iShares",
        "最新价": 93.95,
        "涨跌额": -0.61,
        "涨跌幅": -0.65,
        "开盘价": 94.03,
        "最高价": 94.15,
        "最低价": 93.73,
        "昨收价": 94.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 13825359.0,
        "成交额": 1298733088.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "105.IEF"
    },
    {
        "序号": 9594,
        "name": "Academy Veteran Impact ETF",
        "最新价": 20.02,
        "涨跌额": -0.13,
        "涨跌幅": -0.65,
        "开盘价": 20.02,
        "最高价": 20.02,
        "最低价": 20.02,
        "昨收价": 20.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 66.0,
        "成交额": 1321.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.VETZ"
    },
    {
        "序号": 9595,
        "name": "野马生物",
        "最新价": 1.54,
        "涨跌额": -0.01,
        "涨跌幅": -0.65,
        "开盘价": 1.5,
        "最高价": 1.66,
        "最低价": 1.5,
        "昨收价": 1.55,
        "总市值": 14194473.0,
        "市盈率": -0.23,
        "成交量": 81969.0,
        "成交额": 131049.0,
        "振幅": 10.32,
        "换手率": 0.89,
        "symbol": "105.MBIO"
    },
    {
        "序号": 9596,
        "name": "Lifezone Metals Ltd",
        "最新价": 9.24,
        "涨跌额": -0.06,
        "涨跌幅": -0.65,
        "开盘价": 9.53,
        "最高价": 9.7,
        "最低价": 8.79,
        "昨收价": 9.3,
        "总市值": 738447855.0,
        "市盈率": 1823.42,
        "成交量": 77848.0,
        "成交额": 734141.0,
        "振幅": 9.78,
        "换手率": 0.1,
        "symbol": "106.LZM"
    },
    {
        "序号": 9597,
        "name": "Sphere 3D Corp",
        "最新价": 1.54,
        "涨跌额": -0.01,
        "涨跌幅": -0.65,
        "开盘价": 1.59,
        "最高价": 1.7,
        "最低价": 1.42,
        "昨收价": 1.55,
        "总市值": 22107217.0,
        "市盈率": -0.17,
        "成交量": 2408449.0,
        "成交额": 3762431.0,
        "振幅": 18.06,
        "换手率": 16.78,
        "symbol": "105.ANY"
    },
    {
        "序号": 9598,
        "name": "NXG NextGen Infrastructure Inco",
        "最新价": 33.87,
        "涨跌额": -0.22,
        "涨跌幅": -0.65,
        "开盘价": 34.18,
        "最高价": 34.84,
        "最低价": 33.77,
        "昨收价": 34.09,
        "总市值": 205055348.0,
        "市盈率": null,
        "成交量": 33584.0,
        "成交额": 1142081.0,
        "振幅": 3.14,
        "换手率": 0.55,
        "symbol": "106.NXG"
    },
    {
        "序号": 9599,
        "name": "做空罗素2000ETF-ProShares",
        "最新价": 23.08,
        "涨跌额": -0.15,
        "涨跌幅": -0.65,
        "开盘价": 23.27,
        "最高价": 23.31,
        "最低价": 22.95,
        "昨收价": 23.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 2060615.0,
        "成交额": 47599952.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "107.RWM"
    },
    {
        "序号": 9600,
        "name": "中国金融指数ETF-Global X",
        "最新价": 10.76,
        "涨跌额": -0.07,
        "涨跌幅": -0.65,
        "开盘价": 10.76,
        "最高价": 10.78,
        "最低价": 10.74,
        "昨收价": 10.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 6097.0,
        "成交额": 65638.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "107.CHIX"
    },
    {
        "序号": 9601,
        "name": "Principal Real Estate Income Fu",
        "最新价": 9.2,
        "涨跌额": -0.06,
        "涨跌幅": -0.65,
        "开盘价": 9.21,
        "最高价": 9.25,
        "最低价": 9.11,
        "昨收价": 9.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 20280.0,
        "成交额": 185986.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "106.PGZ"
    },
    {
        "序号": 9602,
        "name": "莱帝运输",
        "最新价": 179.36,
        "涨跌额": -1.17,
        "涨跌幅": -0.65,
        "开盘价": 180.45,
        "最高价": 182.12,
        "最低价": 179.18,
        "昨收价": 180.53,
        "总市值": 6447336260.0,
        "市盈率": 21.51,
        "成交量": 151056.0,
        "成交额": 27187910.0,
        "振幅": 1.63,
        "换手率": 0.42,
        "symbol": "105.LSTR"
    },
    {
        "序号": 9603,
        "name": "Fidelity Emerging Markets Multi",
        "最新价": 22.98,
        "涨跌额": -0.15,
        "涨跌幅": -0.65,
        "开盘价": 23.0,
        "最高价": 23.04,
        "最低价": 22.85,
        "昨收价": 23.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 5408.0,
        "成交额": 124242.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.FDEM"
    },
    {
        "序号": 9604,
        "name": "沛齐",
        "最新价": 124.02,
        "涨跌额": -0.81,
        "涨跌幅": -0.65,
        "开盘价": 124.44,
        "最高价": 125.16,
        "最低价": 123.84,
        "昨收价": 124.83,
        "总市值": 44800040636.0,
        "市盈率": 28.05,
        "成交量": 1707821.0,
        "成交额": 212045905.0,
        "振幅": 1.06,
        "换手率": 0.47,
        "symbol": "105.PAYX"
    },
    {
        "序号": 9605,
        "name": "Eaton Vance Tax-Managed Global ",
        "最新价": 7.65,
        "涨跌额": -0.05,
        "涨跌幅": -0.65,
        "开盘价": 7.65,
        "最高价": 7.68,
        "最低价": 7.64,
        "昨收价": 7.7,
        "总市值": 2306153777.0,
        "市盈率": null,
        "成交量": 631903.0,
        "成交额": 4836718.0,
        "振幅": 0.52,
        "换手率": 0.21,
        "symbol": "106.EXG"
    },
    {
        "序号": 9606,
        "name": "Franklin FTSE Japan ETF",
        "最新价": 27.53,
        "涨跌额": -0.18,
        "涨跌幅": -0.65,
        "开盘价": 27.42,
        "最高价": 27.53,
        "最低价": 27.36,
        "昨收价": 27.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 214444.0,
        "成交额": 5893605.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "107.FLJP"
    },
    {
        "序号": 9607,
        "name": "SPDR Portfolio Long Term Corpor",
        "最新价": 22.88,
        "涨跌额": -0.15,
        "涨跌幅": -0.65,
        "开盘价": 22.87,
        "最高价": 22.96,
        "最低价": 22.81,
        "昨收价": 23.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1400258.0,
        "成交额": 32004226.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.SPLB"
    },
    {
        "序号": 9608,
        "name": "丹纳赫",
        "最新价": 217.49,
        "涨跌额": -1.43,
        "涨跌幅": -0.65,
        "开盘价": 219.0,
        "最高价": 220.0,
        "最低价": 215.68,
        "昨收价": 218.92,
        "总市值": 160709256501.0,
        "市盈率": 27.16,
        "成交量": 2527540.0,
        "成交额": 550033968.0,
        "振幅": 1.97,
        "换手率": 0.34,
        "symbol": "106.DHR"
    },
    {
        "序号": 9609,
        "name": "Seaboard Corp",
        "最新价": 3618.0,
        "涨跌额": -23.79,
        "涨跌幅": -0.65,
        "开盘价": 3627.0,
        "最高价": 3649.99,
        "最低价": 3578.1,
        "昨收价": 3641.79,
        "总市值": 3513276990.0,
        "市盈率": 9.13,
        "成交量": 2407.0,
        "成交额": 8717728.0,
        "振幅": 1.97,
        "换手率": 0.25,
        "symbol": "107.SEB"
    },
    {
        "序号": 9610,
        "name": "Stran & Co Inc",
        "最新价": 1.52,
        "涨跌额": -0.01,
        "涨跌幅": -0.65,
        "开盘价": 1.42,
        "最高价": 1.6,
        "最低价": 1.4,
        "昨收价": 1.53,
        "总市值": 28231098.0,
        "市盈率": 516.02,
        "成交量": 88302.0,
        "成交额": 133839.0,
        "振幅": 13.07,
        "换手率": 0.48,
        "symbol": "105.SWAG"
    },
    {
        "序号": 9611,
        "name": "Dimensional Global ex US Core F",
        "最新价": 51.66,
        "涨跌额": -0.34,
        "涨跌幅": -0.65,
        "开盘价": 51.75,
        "最高价": 51.84,
        "最低价": 51.66,
        "昨收价": 52.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 28338.0,
        "成交额": 1467635.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.DFGX"
    },
    {
        "序号": 9612,
        "name": "BlackRock Floating Rate Income ",
        "最新价": 12.13,
        "涨跌额": -0.08,
        "涨跌幅": -0.66,
        "开盘价": 12.21,
        "最高价": 12.28,
        "最低价": 12.11,
        "昨收价": 12.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 149616.0,
        "成交额": 1822310.0,
        "振幅": 1.39,
        "换手率": null,
        "symbol": "106.FRA"
    },
    {
        "序号": 9613,
        "name": "Wells Fargo & Co Series AA Pfd",
        "最新价": 19.67,
        "涨跌额": -0.13,
        "涨跌幅": -0.66,
        "开盘价": 19.69,
        "最高价": 19.75,
        "最低价": 19.56,
        "昨收价": 19.8,
        "总市值": 920556.0,
        "市盈率": null,
        "成交量": 118155.0,
        "成交额": 2321152.0,
        "振幅": 0.96,
        "换手率": 252.47,
        "symbol": "106.WFC_A"
    },
    {
        "序号": 9614,
        "name": "邓白氏",
        "最新价": 10.59,
        "涨跌额": -0.07,
        "涨跌幅": -0.66,
        "开盘价": 10.64,
        "最高价": 10.73,
        "最低价": 10.55,
        "昨收价": 10.66,
        "总市值": 4648581825.0,
        "市盈率": -179.48,
        "成交量": 3336954.0,
        "成交额": 35417814.0,
        "振幅": 1.69,
        "换手率": 0.76,
        "symbol": "106.DNB"
    },
    {
        "序号": 9615,
        "name": "Liberty Broadband Corp-A",
        "最新价": 75.64,
        "涨跌额": -0.5,
        "涨跌幅": -0.66,
        "开盘价": 76.02,
        "最高价": 76.37,
        "最低价": 75.16,
        "昨收价": 76.14,
        "总市值": 11048441392.0,
        "市盈率": 16.71,
        "成交量": 92209.0,
        "成交额": 6972483.0,
        "振幅": 1.59,
        "换手率": 0.46,
        "symbol": "105.LBRDA"
    },
    {
        "序号": 9616,
        "name": "布鲁克菲尔德公共建设(优先股)",
        "最新价": 15.12,
        "涨跌额": -0.1,
        "涨跌幅": -0.66,
        "开盘价": 15.22,
        "最高价": 15.22,
        "最低价": 15.06,
        "昨收价": 15.22,
        "总市值": 120960000.0,
        "市盈率": null,
        "成交量": 18785.0,
        "成交额": 284641.0,
        "振幅": 1.05,
        "换手率": 0.23,
        "symbol": "106.BIP_B"
    },
    {
        "序号": 9617,
        "name": "AEON Biopharma Inc-A",
        "最新价": 6.04,
        "涨跌额": -0.04,
        "涨跌幅": -0.66,
        "开盘价": 6.06,
        "最高价": 6.11,
        "最低价": 5.8,
        "昨收价": 6.08,
        "总市值": 224443984.0,
        "市盈率": 222.48,
        "成交量": 13243.0,
        "成交额": 80006.0,
        "振幅": 5.1,
        "换手率": 0.04,
        "symbol": "107.AEON"
    },
    {
        "序号": 9618,
        "name": "BlackRock Enhanced Capital and ",
        "最新价": 18.1,
        "涨跌额": -0.12,
        "涨跌幅": -0.66,
        "开盘价": 18.17,
        "最高价": 18.19,
        "最低价": 18.03,
        "昨收价": 18.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 127114.0,
        "成交额": 2300801.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "106.CII"
    },
    {
        "序号": 9619,
        "name": "美国车桥",
        "最新价": 7.54,
        "涨跌额": -0.05,
        "涨跌幅": -0.66,
        "开盘价": 7.58,
        "最高价": 7.65,
        "最低价": 7.52,
        "昨收价": 7.59,
        "总市值": 882640302.0,
        "市盈率": -1471.07,
        "成交量": 1051606.0,
        "成交额": 7960418.0,
        "振幅": 1.71,
        "换手率": 0.9,
        "symbol": "106.AXL"
    },
    {
        "序号": 9620,
        "name": "Sachem Capital Corp Notes",
        "最新价": 21.08,
        "涨跌额": -0.14,
        "涨跌幅": -0.66,
        "开盘价": 21.08,
        "最高价": 21.08,
        "最低价": 21.08,
        "昨收价": 21.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 958.0,
        "成交额": 20195.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.SCCD"
    },
    {
        "序号": 9621,
        "name": "BlackRock Virginia Municipal Bo",
        "最新价": 10.54,
        "涨跌额": -0.07,
        "涨跌幅": -0.66,
        "开盘价": 10.62,
        "最高价": 10.63,
        "最低价": 10.54,
        "昨收价": 10.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 11743.0,
        "成交额": 124352.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "106.BHV"
    },
    {
        "序号": 9622,
        "name": "PartnerRe Ltd Series J Pfd",
        "最新价": 18.05,
        "涨跌额": -0.12,
        "涨跌幅": -0.66,
        "开盘价": 18.19,
        "最高价": 18.19,
        "最低价": 18.01,
        "昨收价": 18.17,
        "总市值": 144400000.0,
        "市盈率": null,
        "成交量": 33999.0,
        "成交额": 614058.0,
        "振幅": 0.99,
        "换手率": 0.42,
        "symbol": "106.PRE_J"
    },
    {
        "序号": 9623,
        "name": "First Trust Developed Markets e",
        "最新价": 39.01,
        "涨跌额": -0.26,
        "涨跌幅": -0.66,
        "开盘价": 39.25,
        "最高价": 39.25,
        "最低价": 39.01,
        "昨收价": 39.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 1212.0,
        "成交额": 47541.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "105.FDTS"
    },
    {
        "序号": 9624,
        "name": "马来西亚ETF-iShares MSCI",
        "最新价": 21.0,
        "涨跌额": -0.14,
        "涨跌幅": -0.66,
        "开盘价": 21.03,
        "最高价": 21.05,
        "最低价": 20.96,
        "昨收价": 21.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 439806.0,
        "成交额": 9233676.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.EWM"
    },
    {
        "序号": 9625,
        "name": "Spire Inc Series A Pfd",
        "最新价": 23.93,
        "涨跌额": -0.16,
        "涨跌幅": -0.66,
        "开盘价": 23.96,
        "最高价": 24.09,
        "最低价": 23.91,
        "昨收价": 24.09,
        "总市值": 239300.0,
        "市盈率": null,
        "成交量": 3279.0,
        "成交额": 78560.0,
        "振幅": 0.75,
        "换手率": 32.79,
        "symbol": "106.SR_A"
    },
    {
        "序号": 9626,
        "name": "安赛乐米塔尔",
        "最新价": 25.35,
        "涨跌额": -0.17,
        "涨跌幅": -0.67,
        "开盘价": 25.47,
        "最高价": 25.73,
        "最低价": 25.33,
        "昨收价": 25.52,
        "总市值": 21243300000.0,
        "市盈率": 5.12,
        "成交量": 1370289.0,
        "成交额": 34903611.0,
        "振幅": 1.57,
        "换手率": 0.16,
        "symbol": "106.MT"
    },
    {
        "序号": 9627,
        "name": "JPMorgan USD Emerging Markets S",
        "最新价": 38.76,
        "涨跌额": -0.26,
        "涨跌幅": -0.67,
        "开盘价": 38.9,
        "最高价": 38.9,
        "最低价": 38.73,
        "昨收价": 39.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 629035.0,
        "成交额": 24385962.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.JPMB"
    },
    {
        "序号": 9628,
        "name": "Gold Royalty Corp",
        "最新价": 1.49,
        "涨跌额": -0.01,
        "涨跌幅": -0.67,
        "开盘价": 1.5,
        "最高价": 1.5,
        "最低价": 1.42,
        "昨收价": 1.5,
        "总市值": 216005725.0,
        "市盈率": -17.89,
        "成交量": 581879.0,
        "成交额": 853268.0,
        "振幅": 5.33,
        "换手率": 0.4,
        "symbol": "107.GROY"
    },
    {
        "序号": 9629,
        "name": "Equus Total Return Inc",
        "最新价": 1.49,
        "涨跌额": -0.01,
        "涨跌幅": -0.67,
        "开盘价": 1.43,
        "最高价": 1.5,
        "最低价": 1.43,
        "昨收价": 1.5,
        "总市值": 20142038.0,
        "市盈率": 1.85,
        "成交量": 3823.0,
        "成交额": 5560.0,
        "振幅": 4.67,
        "换手率": 0.03,
        "symbol": "106.EQS"
    },
    {
        "序号": 9630,
        "name": "Capital One Financial Corp Seri",
        "最新价": 16.39,
        "涨跌额": -0.11,
        "涨跌幅": -0.67,
        "开盘价": 16.4,
        "最高价": 16.56,
        "最低价": 16.33,
        "昨收价": 16.5,
        "总市值": 11063250.0,
        "市盈率": null,
        "成交量": 128734.0,
        "成交额": 2114819.0,
        "振幅": 1.39,
        "换手率": 19.07,
        "symbol": "106.COF_L"
    },
    {
        "序号": 9631,
        "name": "iShares Long-Term Corporate Bon",
        "最新价": 50.63,
        "涨跌额": -0.34,
        "涨跌幅": -0.67,
        "开盘价": 50.6,
        "最高价": 50.81,
        "最低价": 50.49,
        "昨收价": 50.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 798257.0,
        "成交额": 40406098.0,
        "振幅": 0.63,
        "换手率": null,
        "symbol": "107.IGLB"
    },
    {
        "序号": 9632,
        "name": "三倍做多房地产ETF-Direxion",
        "最新价": 8.93,
        "涨跌额": -0.06,
        "涨跌幅": -0.67,
        "开盘价": 8.95,
        "最高价": 9.01,
        "最低价": 8.64,
        "昨收价": 8.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 796651.0,
        "成交额": 7026297.0,
        "振幅": 4.12,
        "换手率": null,
        "symbol": "107.DRN"
    },
    {
        "序号": 9633,
        "name": "BlackRock MuniAssets Fund",
        "最新价": 10.39,
        "涨跌额": -0.07,
        "涨跌幅": -0.67,
        "开盘价": 10.45,
        "最高价": 10.47,
        "最低价": 10.26,
        "昨收价": 10.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 129253.0,
        "成交额": 1342891.0,
        "振幅": 2.01,
        "换手率": null,
        "symbol": "106.MUA"
    },
    {
        "序号": 9634,
        "name": "WisdomTree Emerging Markets Eff",
        "最新价": 26.71,
        "涨跌额": -0.18,
        "涨跌幅": -0.67,
        "开盘价": 26.68,
        "最高价": 26.72,
        "最低价": 26.63,
        "昨收价": 26.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 12607.0,
        "成交额": 336460.0,
        "振幅": 0.33,
        "换手率": null,
        "symbol": "107.NTSE"
    },
    {
        "序号": 9635,
        "name": "BlackRock Corporate High Yield ",
        "最新价": 8.9,
        "涨跌额": -0.06,
        "涨跌幅": -0.67,
        "开盘价": 8.97,
        "最高价": 9.01,
        "最低价": 8.87,
        "昨收价": 8.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 500877.0,
        "成交额": 4479701.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "106.HYT"
    },
    {
        "序号": 9636,
        "name": "iShares iBonds Dec 2030 Term Tr",
        "最新价": 19.28,
        "涨跌额": -0.13,
        "涨跌幅": -0.67,
        "开盘价": 19.31,
        "最高价": 19.32,
        "最低价": 19.25,
        "昨收价": 19.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 27100.0,
        "成交额": 522504.0,
        "振幅": 0.36,
        "换手率": null,
        "symbol": "105.IBTK"
    },
    {
        "序号": 9637,
        "name": "安飞士",
        "最新价": 189.75,
        "涨跌额": -1.28,
        "涨跌幅": -0.67,
        "开盘价": 191.34,
        "最高价": 195.38,
        "最低价": 188.58,
        "昨收价": 191.03,
        "总市值": 6872998506.0,
        "市盈率": 3.83,
        "成交量": 1313124.0,
        "成交额": 250927990.0,
        "振幅": 3.56,
        "换手率": 3.63,
        "symbol": "105.CAR"
    },
    {
        "序号": 9638,
        "name": "Central Garden & Pet Co-A",
        "最新价": 39.97,
        "涨跌额": -0.27,
        "涨跌幅": -0.67,
        "开盘价": 40.24,
        "最高价": 40.62,
        "最低价": 39.84,
        "昨收价": 40.24,
        "总市值": 2150940424.0,
        "市盈率": 17.12,
        "成交量": 146614.0,
        "成交额": 5876350.0,
        "振幅": 1.94,
        "换手率": 0.36,
        "symbol": "105.CENTA"
    },
    {
        "序号": 9639,
        "name": "哈莫尼黄金",
        "最新价": 5.92,
        "涨跌额": -0.04,
        "涨跌幅": -0.67,
        "开盘价": 5.81,
        "最高价": 5.96,
        "最低价": 5.8,
        "昨收价": 5.96,
        "总市值": 3669954289.0,
        "市盈率": 13.93,
        "成交量": 4374072.0,
        "成交额": 25776974.0,
        "振幅": 2.68,
        "换手率": 0.71,
        "symbol": "106.HMY"
    },
    {
        "序号": 9640,
        "name": "FutureFuel Corp",
        "最新价": 5.92,
        "涨跌额": -0.04,
        "涨跌幅": -0.67,
        "开盘价": 5.96,
        "最高价": 6.02,
        "最低价": 5.88,
        "昨收价": 5.96,
        "总市值": 259078399.0,
        "市盈率": 8.96,
        "成交量": 279936.0,
        "成交额": 1662602.0,
        "振幅": 2.35,
        "换手率": 0.64,
        "symbol": "106.FF"
    },
    {
        "序号": 9641,
        "name": "Digihost Technology Inc",
        "最新价": 1.48,
        "涨跌额": -0.01,
        "涨跌幅": -0.67,
        "开盘价": 1.5,
        "最高价": 1.56,
        "最低价": 1.4,
        "昨收价": 1.49,
        "总市值": 42493141.0,
        "市盈率": -1.94,
        "成交量": 258413.0,
        "成交额": 384496.0,
        "振幅": 10.74,
        "换手率": 0.9,
        "symbol": "105.DGHI"
    },
    {
        "序号": 9642,
        "name": "美国工商五金",
        "最新价": 45.78,
        "涨跌额": -0.31,
        "涨跌幅": -0.67,
        "开盘价": 46.09,
        "最高价": 46.88,
        "最低价": 45.68,
        "昨收价": 46.09,
        "总市值": 5356673805.0,
        "市盈率": 6.23,
        "成交量": 640520.0,
        "成交额": 29471254.0,
        "振幅": 2.6,
        "换手率": 0.55,
        "symbol": "106.CMC"
    },
    {
        "序号": 9643,
        "name": "BondBloxx Bloomberg Seven Year ",
        "最新价": 47.21,
        "涨跌额": -0.32,
        "涨跌幅": -0.67,
        "开盘价": 47.24,
        "最高价": 47.24,
        "最低价": 47.21,
        "昨收价": 47.53,
        "总市值": null,
        "市盈率": null,
        "成交量": 552.0,
        "成交额": 26063.0,
        "振幅": 0.06,
        "换手率": null,
        "symbol": "107.XSVN"
    },
    {
        "序号": 9644,
        "name": "美国全球投资者",
        "最新价": 2.95,
        "涨跌额": -0.02,
        "涨跌幅": -0.67,
        "开盘价": 3.0,
        "最高价": 3.02,
        "最低价": 2.87,
        "昨收价": 2.97,
        "总市值": 42212538.0,
        "市盈率": 13.41,
        "成交量": 29112.0,
        "成交额": 86371.0,
        "振幅": 5.05,
        "换手率": 0.2,
        "symbol": "105.GROW"
    },
    {
        "序号": 9645,
        "name": "Entergy New Orleans LLC Bonds",
        "最新价": 20.61,
        "涨跌额": -0.14,
        "涨跌幅": -0.67,
        "开盘价": 20.73,
        "最高价": 20.81,
        "最低价": 20.58,
        "昨收价": 20.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 7956.0,
        "成交额": 164904.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "106.ENJ"
    },
    {
        "序号": 9646,
        "name": "Lazard Global Total Return and ",
        "最新价": 14.71,
        "涨跌额": -0.1,
        "涨跌幅": -0.68,
        "开盘价": 14.74,
        "最高价": 14.76,
        "最低价": 14.64,
        "昨收价": 14.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 78935.0,
        "成交额": 1159399.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "106.LGI"
    },
    {
        "序号": 9647,
        "name": "夏威夷电力",
        "最新价": 13.22,
        "涨跌额": -0.09,
        "涨跌幅": -0.68,
        "开盘价": 13.35,
        "最高价": 13.37,
        "最低价": 13.0,
        "昨收价": 13.31,
        "总市值": 1455840576.0,
        "市盈率": 6.94,
        "成交量": 2697929.0,
        "成交额": 35609766.0,
        "振幅": 2.78,
        "换手率": 2.45,
        "symbol": "106.HE"
    },
    {
        "序号": 9648,
        "name": "Invesco BulletShares 2033 Corpo",
        "最新价": 20.54,
        "涨跌额": -0.14,
        "涨跌幅": -0.68,
        "开盘价": 20.55,
        "最高价": 20.58,
        "最低价": 20.51,
        "昨收价": 20.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 901940.0,
        "成交额": 18560731.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "105.BSCX"
    },
    {
        "序号": 9649,
        "name": "Limoneira Co",
        "最新价": 19.07,
        "涨跌额": -0.13,
        "涨跌幅": -0.68,
        "开盘价": 19.27,
        "最高价": 19.27,
        "最低价": 18.75,
        "昨收价": 19.2,
        "总市值": 342857814.0,
        "市盈率": 33.62,
        "成交量": 45008.0,
        "成交额": 855532.0,
        "振幅": 2.71,
        "换手率": 0.25,
        "symbol": "105.LMNR"
    },
    {
        "序号": 9650,
        "name": "依特香水",
        "最新价": 131.77,
        "涨跌额": -0.9,
        "涨跌幅": -0.68,
        "开盘价": 132.53,
        "最高价": 133.25,
        "最低价": 130.61,
        "昨收价": 132.67,
        "总市值": 4214110675.0,
        "市盈率": 26.53,
        "成交量": 83823.0,
        "成交额": 11038396.0,
        "振幅": 1.99,
        "换手率": 0.26,
        "symbol": "105.IPAR"
    },
    {
        "序号": 9651,
        "name": "US Treasury 10 Year Note ETF",
        "最新价": 43.91,
        "涨跌额": -0.3,
        "涨跌幅": -0.68,
        "开盘价": 43.97,
        "最高价": 44.0,
        "最低价": 43.81,
        "昨收价": 44.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 21179.0,
        "成交额": 929966.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "105.UTEN"
    },
    {
        "序号": 9652,
        "name": "Bank of America Corp Series PP ",
        "最新价": 17.54,
        "涨跌额": -0.12,
        "涨跌幅": -0.68,
        "开盘价": 17.57,
        "最高价": 17.62,
        "最低价": 17.49,
        "昨收价": 17.66,
        "总市值": 641964.0,
        "市盈率": null,
        "成交量": 52919.0,
        "成交额": 928227.0,
        "振幅": 0.74,
        "换手率": 144.59,
        "symbol": "106.BAC_P"
    },
    {
        "序号": 9653,
        "name": "ProShares Ultra MSCI Emerging M",
        "最新价": 46.71,
        "涨跌额": -0.32,
        "涨跌幅": -0.68,
        "开盘价": 46.68,
        "最高价": 46.71,
        "最低价": 46.43,
        "昨收价": 47.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 6663.0,
        "成交额": 310849.0,
        "振幅": 0.6,
        "换手率": null,
        "symbol": "107.EET"
    },
    {
        "序号": 9654,
        "name": "GraniteShares 1.5x Short TSLA D",
        "最新价": 20.43,
        "涨跌额": -0.14,
        "涨跌幅": -0.68,
        "开盘价": 20.6,
        "最高价": 20.6,
        "最低价": 20.29,
        "昨收价": 20.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 2342.0,
        "成交额": 48042.0,
        "振幅": 1.51,
        "换手率": null,
        "symbol": "105.TSDD"
    },
    {
        "序号": 9655,
        "name": "Safe Bulkers Inc Series D Pfd",
        "最新价": 24.8,
        "涨跌额": -0.17,
        "涨跌幅": -0.68,
        "开盘价": 24.98,
        "最高价": 24.98,
        "最低价": 24.8,
        "昨收价": 24.97,
        "总市值": 79237240.0,
        "市盈率": null,
        "成交量": 1602.0,
        "成交额": 39792.0,
        "振幅": 0.72,
        "换手率": 0.05,
        "symbol": "106.SB_D"
    },
    {
        "序号": 9656,
        "name": "Simulations Plus Inc",
        "最新价": 39.37,
        "涨跌额": -0.27,
        "涨跌幅": -0.68,
        "开盘价": 39.61,
        "最高价": 39.75,
        "最低价": 38.95,
        "昨收价": 39.64,
        "总市值": 784974099.0,
        "市盈率": 78.8,
        "成交量": 55693.0,
        "成交额": 2190123.0,
        "振幅": 2.02,
        "换手率": 0.28,
        "symbol": "105.SLP"
    },
    {
        "序号": 9657,
        "name": "Avangrid Inc",
        "最新价": 32.07,
        "涨跌额": -0.22,
        "涨跌幅": -0.68,
        "开盘价": 32.22,
        "最高价": 32.48,
        "最低价": 31.75,
        "昨收价": 32.29,
        "总市值": 12403743244.0,
        "市盈率": 23.14,
        "成交量": 556243.0,
        "成交额": 17792830.0,
        "振幅": 2.26,
        "换手率": 0.14,
        "symbol": "106.AGR"
    },
    {
        "序号": 9658,
        "name": "New York Mortgage Trust Inc Ser",
        "最新价": 20.4,
        "涨跌额": -0.14,
        "涨跌幅": -0.68,
        "开盘价": 20.48,
        "最高价": 20.65,
        "最低价": 20.38,
        "昨收价": 20.54,
        "总市值": 124589287.0,
        "市盈率": null,
        "成交量": 9286.0,
        "成交额": 190673.0,
        "振幅": 1.31,
        "换手率": 0.15,
        "symbol": "105.NYMTN"
    },
    {
        "序号": 9659,
        "name": "Xtrackers MSCI Emerging Markets",
        "最新价": 23.29,
        "涨跌额": -0.16,
        "涨跌幅": -0.68,
        "开盘价": 23.29,
        "最高价": 23.29,
        "最低价": 23.22,
        "昨收价": 23.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 2366.0,
        "成交额": 54993.0,
        "振幅": 0.3,
        "换手率": null,
        "symbol": "107.EMSG"
    },
    {
        "序号": 9660,
        "name": "Cemtrex Inc",
        "最新价": 5.81,
        "涨跌额": -0.04,
        "涨跌幅": -0.68,
        "开盘价": 5.73,
        "最高价": 5.84,
        "最低价": 5.67,
        "昨收价": 5.85,
        "总市值": 6075999.0,
        "市盈率": -0.54,
        "成交量": 4370.0,
        "成交额": 24923.0,
        "振幅": 2.91,
        "换手率": 0.42,
        "symbol": "105.CETX"
    },
    {
        "序号": 9661,
        "name": "Zillow Group Inc-C",
        "最新价": 46.41,
        "涨跌额": -0.32,
        "涨跌幅": -0.68,
        "开盘价": 46.02,
        "最高价": 46.98,
        "最低价": 45.73,
        "昨收价": 46.73,
        "总市值": 10841522563.0,
        "市盈率": -69.05,
        "成交量": 2533359.0,
        "成交额": 117511124.0,
        "振幅": 2.67,
        "换手率": 1.48,
        "symbol": "105.Z"
    },
    {
        "序号": 9662,
        "name": "Snap One Holdings Corp",
        "最新价": 7.25,
        "涨跌额": -0.05,
        "涨跌幅": -0.68,
        "开盘价": 7.18,
        "最高价": 7.46,
        "最低价": 7.15,
        "昨收价": 7.3,
        "总市值": 554845364.0,
        "市盈率": -28.27,
        "成交量": 33263.0,
        "成交额": 240341.0,
        "振幅": 4.25,
        "换手率": 0.04,
        "symbol": "105.SNPO"
    },
    {
        "序号": 9663,
        "name": "Altisource Portfolio Solutions",
        "最新价": 4.35,
        "涨跌额": -0.03,
        "涨跌幅": -0.68,
        "开盘价": 4.36,
        "最高价": 4.44,
        "最低价": 4.01,
        "昨收价": 4.38,
        "总市值": 115242949.0,
        "市盈率": -2.12,
        "成交量": 143553.0,
        "成交额": 607480.0,
        "振幅": 9.82,
        "换手率": 0.54,
        "symbol": "105.ASPS"
    },
    {
        "序号": 9664,
        "name": "Nuveen ESG Emerging Markets Equ",
        "最新价": 26.08,
        "涨跌额": -0.18,
        "涨跌幅": -0.69,
        "开盘价": 26.09,
        "最高价": 26.2,
        "最低价": 25.89,
        "昨收价": 26.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 117983.0,
        "成交额": 3077303.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "107.NUEM"
    },
    {
        "序号": 9665,
        "name": "BNY Mellon Strategic Municipals",
        "最新价": 5.79,
        "涨跌额": -0.04,
        "涨跌幅": -0.69,
        "开盘价": 5.82,
        "最高价": 5.84,
        "最低价": 5.78,
        "昨收价": 5.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 203239.0,
        "成交额": 1180623.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.LEO"
    },
    {
        "序号": 9666,
        "name": "拉姆研究",
        "最新价": 703.17,
        "涨跌额": -4.86,
        "涨跌幅": -0.69,
        "开盘价": 699.46,
        "最高价": 711.17,
        "最低价": 699.02,
        "昨收价": 708.03,
        "总市值": 92672180640.0,
        "市盈率": 23.33,
        "成交量": 794390.0,
        "成交额": 558984336.0,
        "振幅": 1.72,
        "换手率": 0.6,
        "symbol": "105.LRCX"
    },
    {
        "序号": 9667,
        "name": "NASDAQ生物技术ETF-iShares",
        "最新价": 123.88,
        "涨跌额": -0.86,
        "涨跌幅": -0.69,
        "开盘价": 124.3,
        "最高价": 125.06,
        "最低价": 123.63,
        "昨收价": 124.74,
        "总市值": null,
        "市盈率": null,
        "成交量": 1283036.0,
        "成交额": 159288296.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "105.IBB"
    },
    {
        "序号": 9668,
        "name": "StoneCo Ltd-A",
        "最新价": 15.84,
        "涨跌额": -0.11,
        "涨跌幅": -0.69,
        "开盘价": 15.95,
        "最高价": 16.17,
        "最低价": 15.75,
        "昨收价": 15.95,
        "总市值": 4976869582.0,
        "市盈率": 22.94,
        "成交量": 4099692.0,
        "成交额": 65337348.0,
        "振幅": 2.63,
        "换手率": 1.3,
        "symbol": "105.STNE"
    },
    {
        "序号": 9669,
        "name": "Public Storage Series M Pfd",
        "最新价": 18.72,
        "涨跌额": -0.13,
        "涨跌幅": -0.69,
        "开盘价": 18.85,
        "最高价": 18.85,
        "最低价": 18.7,
        "昨收价": 18.85,
        "总市值": 172224.0,
        "市盈率": null,
        "成交量": 19973.0,
        "成交额": 374735.0,
        "振幅": 0.8,
        "换手率": 217.1,
        "symbol": "106.PSA_M"
    },
    {
        "序号": 9670,
        "name": "PPG工业",
        "最新价": 143.98,
        "涨跌额": -1.0,
        "涨跌幅": -0.69,
        "开盘价": 144.98,
        "最高价": 145.86,
        "最低价": 143.76,
        "昨收价": 144.98,
        "总市值": 33950484000.0,
        "市盈率": 23.94,
        "成交量": 1136587.0,
        "成交额": 164084921.0,
        "振幅": 1.45,
        "换手率": 0.48,
        "symbol": "106.PPG"
    },
    {
        "序号": 9671,
        "name": "树屋食品",
        "最新价": 41.67,
        "涨跌额": -0.29,
        "涨跌幅": -0.69,
        "开盘价": 41.97,
        "最高价": 41.97,
        "最低价": 41.36,
        "昨收价": 41.96,
        "总市值": 2304351000.0,
        "市盈率": 103.8,
        "成交量": 339772.0,
        "成交额": 14122437.0,
        "振幅": 1.45,
        "换手率": 0.61,
        "symbol": "106.THS"
    },
    {
        "序号": 9672,
        "name": "Webster Financial Corp Series G",
        "最新价": 21.55,
        "涨跌额": -0.15,
        "涨跌幅": -0.69,
        "开盘价": 21.79,
        "最高价": 21.79,
        "最低价": 21.52,
        "昨收价": 21.7,
        "总市值": 2909250.0,
        "市盈率": null,
        "成交量": 9987.0,
        "成交额": 216210.0,
        "振幅": 1.24,
        "换手率": 7.4,
        "symbol": "106.WBS_G"
    },
    {
        "序号": 9673,
        "name": "索尼克汽车",
        "最新价": 50.25,
        "涨跌额": -0.35,
        "涨跌幅": -0.69,
        "开盘价": 50.56,
        "最高价": 51.38,
        "最低价": 49.88,
        "昨收价": 50.6,
        "总市值": 1702804364.0,
        "市盈率": -33.13,
        "成交量": 130488.0,
        "成交额": 6569548.0,
        "振幅": 2.96,
        "换手率": 0.39,
        "symbol": "106.SAH"
    },
    {
        "序号": 9674,
        "name": "纽柯钢铁",
        "最新价": 162.09,
        "涨跌额": -1.13,
        "涨跌幅": -0.69,
        "开盘价": 162.61,
        "最高价": 165.06,
        "最低价": 161.85,
        "昨收价": 163.22,
        "总市值": 39848043348.0,
        "市盈率": 7.98,
        "成交量": 928537.0,
        "成交额": 150930601.0,
        "振幅": 1.97,
        "换手率": 0.38,
        "symbol": "106.NUE"
    },
    {
        "序号": 9675,
        "name": "Nuveen New York Select Tax-Free",
        "最新价": 11.47,
        "涨跌额": -0.08,
        "涨跌幅": -0.69,
        "开盘价": 11.48,
        "最高价": 11.51,
        "最低价": 11.36,
        "昨收价": 11.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 21687.0,
        "成交额": 248061.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "106.NXN"
    },
    {
        "序号": 9676,
        "name": "iShares iBonds Dec 2031 Term Tr",
        "最新价": 20.04,
        "涨跌额": -0.14,
        "涨跌幅": -0.69,
        "开盘价": 20.06,
        "最高价": 20.08,
        "最低价": 20.01,
        "昨收价": 20.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 21254.0,
        "成交额": 425825.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.IBTL"
    },
    {
        "序号": 9677,
        "name": "日常消费ETF-SPDR",
        "最新价": 70.11,
        "涨跌额": -0.49,
        "涨跌幅": -0.69,
        "开盘价": 70.47,
        "最高价": 70.53,
        "最低价": 70.01,
        "昨收价": 70.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 8191287.0,
        "成交额": 574934896.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.XLP"
    },
    {
        "序号": 9678,
        "name": "Avantis Emerging Markets Small ",
        "最新价": 51.46,
        "涨跌额": -0.36,
        "涨跌幅": -0.69,
        "开盘价": 51.51,
        "最高价": 51.57,
        "最低价": 51.44,
        "昨收价": 51.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 656.0,
        "成交额": 33801.0,
        "振幅": 0.25,
        "换手率": null,
        "symbol": "107.AVEE"
    },
    {
        "序号": 9679,
        "name": "Royalty Pharma plc-A",
        "最新价": 28.57,
        "涨跌额": -0.2,
        "涨跌幅": -0.7,
        "开盘价": 28.66,
        "最高价": 28.78,
        "最低价": 28.5,
        "昨收价": 28.77,
        "总市值": 17120139807.0,
        "市盈率": 92.84,
        "成交量": 1790863.0,
        "成交额": 51256022.0,
        "振幅": 0.97,
        "换手率": 0.3,
        "symbol": "105.RPRX"
    },
    {
        "序号": 9680,
        "name": "Allspring Global Dividend Oppor",
        "最新价": 4.28,
        "涨跌额": -0.03,
        "涨跌幅": -0.7,
        "开盘价": 4.29,
        "最高价": 4.31,
        "最低价": 4.26,
        "昨收价": 4.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 111268.0,
        "成交额": 476474.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "106.EOD"
    },
    {
        "序号": 9681,
        "name": "First Citizens BancShares Inc S",
        "最新价": 19.95,
        "涨跌额": -0.14,
        "涨跌幅": -0.7,
        "开盘价": 20.09,
        "最高价": 20.2,
        "最低价": 19.85,
        "昨收价": 20.09,
        "总市值": 6882750.0,
        "市盈率": null,
        "成交量": 31972.0,
        "成交额": 639702.0,
        "振幅": 1.74,
        "换手率": 9.27,
        "symbol": "105.FCNCP"
    },
    {
        "序号": 9682,
        "name": "PIMCO California Municipal Inco",
        "最新价": 5.69,
        "涨跌额": -0.04,
        "涨跌幅": -0.7,
        "开盘价": 5.73,
        "最高价": 5.82,
        "最低价": 5.68,
        "昨收价": 5.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 172629.0,
        "成交额": 989046.0,
        "振幅": 2.44,
        "换手率": null,
        "symbol": "106.PCK"
    },
    {
        "序号": 9683,
        "name": "美国电力",
        "最新价": 79.64,
        "涨跌额": -0.56,
        "涨跌幅": -0.7,
        "开盘价": 80.12,
        "最高价": 80.44,
        "最低价": 79.23,
        "昨收价": 80.2,
        "总市值": 41880766950.0,
        "市盈率": 18.56,
        "成交量": 2708029.0,
        "成交额": 215695913.0,
        "振幅": 1.51,
        "换手率": 0.51,
        "symbol": "105.AEP"
    },
    {
        "序号": 9684,
        "name": "iShares iBonds Dec 2033 Term Tr",
        "最新价": 24.16,
        "涨跌额": -0.17,
        "涨跌幅": -0.7,
        "开盘价": 24.21,
        "最高价": 24.22,
        "最低价": 24.13,
        "昨收价": 24.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 25930.0,
        "成交额": 626392.0,
        "振幅": 0.37,
        "换手率": null,
        "symbol": "105.IBTO"
    },
    {
        "序号": 9685,
        "name": "H&R布洛克",
        "最新价": 45.43,
        "涨跌额": -0.32,
        "涨跌幅": -0.7,
        "开盘价": 45.75,
        "最高价": 45.96,
        "最低价": 45.41,
        "昨收价": 45.75,
        "总市值": 6538789146.0,
        "市盈率": 11.7,
        "成交量": 1976470.0,
        "成交额": 89988451.0,
        "振幅": 1.2,
        "换手率": 1.37,
        "symbol": "106.HRB"
    },
    {
        "序号": 9686,
        "name": "怪物饮料",
        "最新价": 53.92,
        "涨跌额": -0.38,
        "涨跌幅": -0.7,
        "开盘价": 54.16,
        "最高价": 54.35,
        "最低价": 53.7,
        "昨收价": 54.3,
        "总市值": 56100597484.0,
        "市盈率": 35.83,
        "成交量": 4935825.0,
        "成交额": 266542720.0,
        "振幅": 1.2,
        "换手率": 0.47,
        "symbol": "105.MNST"
    },
    {
        "序号": 9687,
        "name": "Cohen & Steers Quality Income R",
        "最新价": 11.35,
        "涨跌额": -0.08,
        "涨跌幅": -0.7,
        "开盘价": 11.37,
        "最高价": 11.44,
        "最低价": 11.25,
        "昨收价": 11.43,
        "总市值": null,
        "市盈率": null,
        "成交量": 367305.0,
        "成交额": 4165626.0,
        "振幅": 1.66,
        "换手率": null,
        "symbol": "106.RQI"
    },
    {
        "序号": 9688,
        "name": "Prime Medicine Inc",
        "最新价": 8.5,
        "涨跌额": -0.06,
        "涨跌幅": -0.7,
        "开盘价": 8.59,
        "最高价": 8.74,
        "最低价": 8.17,
        "昨收价": 8.56,
        "总市值": 827256559.0,
        "市盈率": -4.82,
        "成交量": 570042.0,
        "成交额": 4869394.0,
        "振幅": 6.66,
        "换手率": 0.59,
        "symbol": "105.PRME"
    },
    {
        "序号": 9689,
        "name": "Western Asset Investment Grade ",
        "最新价": 17.0,
        "涨跌额": -0.12,
        "涨跌幅": -0.7,
        "开盘价": 17.09,
        "最高价": 17.29,
        "最低价": 16.81,
        "昨收价": 17.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 19115.0,
        "成交额": 324225.0,
        "振幅": 2.8,
        "换手率": null,
        "symbol": "106.IGI"
    },
    {
        "序号": 9690,
        "name": "PGIM Global High Yield Fund",
        "最新价": 11.32,
        "涨跌额": -0.08,
        "涨跌幅": -0.7,
        "开盘价": 11.33,
        "最高价": 11.39,
        "最低价": 11.3,
        "昨收价": 11.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 144708.0,
        "成交额": 1640334.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "106.GHY"
    },
    {
        "序号": 9691,
        "name": "联合航空",
        "最新价": 40.97,
        "涨跌额": -0.29,
        "涨跌幅": -0.7,
        "开盘价": 41.3,
        "最高价": 41.58,
        "最低价": 40.7,
        "昨收价": 41.26,
        "总市值": 13438853008.0,
        "市盈率": 4.7,
        "成交量": 8115561.0,
        "成交额": 333079904.0,
        "振幅": 2.13,
        "换手率": 2.47,
        "symbol": "105.UAL"
    },
    {
        "序号": 9692,
        "name": "Nocera Inc",
        "最新价": 1.41,
        "涨跌额": -0.01,
        "涨跌幅": -0.7,
        "开盘价": 1.38,
        "最高价": 1.41,
        "最低价": 1.36,
        "昨收价": 1.42,
        "总市值": 14155406.0,
        "市盈率": -3.28,
        "成交量": 2568.0,
        "成交额": 3520.0,
        "振幅": 3.52,
        "换手率": 0.03,
        "symbol": "105.NCRA"
    },
    {
        "序号": 9693,
        "name": "Fidelity Clean Energy ETF",
        "最新价": 15.5,
        "涨跌额": -0.11,
        "涨跌幅": -0.7,
        "开盘价": 15.45,
        "最高价": 15.61,
        "最低价": 15.39,
        "昨收价": 15.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 5579.0,
        "成交额": 86424.0,
        "振幅": 1.41,
        "换手率": null,
        "symbol": "107.FRNW"
    },
    {
        "序号": 9694,
        "name": "Direxion Daily Utilities Bull 3",
        "最新价": 21.13,
        "涨跌额": -0.15,
        "涨跌幅": -0.7,
        "开盘价": 21.1,
        "最高价": 21.25,
        "最低价": 20.65,
        "昨收价": 21.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 142537.0,
        "成交额": 2983105.0,
        "振幅": 2.82,
        "换手率": null,
        "symbol": "107.UTSL"
    },
    {
        "序号": 9695,
        "name": "Rexford Industrial Realty Inc S",
        "最新价": 21.13,
        "涨跌额": -0.15,
        "涨跌幅": -0.7,
        "开盘价": 21.2,
        "最高价": 21.2,
        "最低价": 21.06,
        "昨收价": 21.28,
        "总市值": 72898500.0,
        "市盈率": null,
        "成交量": 1614.0,
        "成交额": 34136.0,
        "振幅": 0.66,
        "换手率": 0.05,
        "symbol": "106.REXR_C"
    },
    {
        "序号": 9696,
        "name": "iShares iBonds Dec 2032 Term Tr",
        "最新价": 22.53,
        "涨跌额": -0.16,
        "涨跌幅": -0.71,
        "开盘价": 22.54,
        "最高价": 22.56,
        "最低价": 22.48,
        "昨收价": 22.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 54426.0,
        "成交额": 1225877.0,
        "振幅": 0.35,
        "换手率": null,
        "symbol": "105.IBTM"
    },
    {
        "序号": 9697,
        "name": "iTeos Therapeutics Inc",
        "最新价": 9.84,
        "涨跌额": -0.07,
        "涨跌幅": -0.71,
        "开盘价": 9.9,
        "最高价": 10.02,
        "最低价": 9.66,
        "昨收价": 9.91,
        "总市值": 352123987.0,
        "市盈率": -5.71,
        "成交量": 384311.0,
        "成交额": 3792170.0,
        "振幅": 3.63,
        "换手率": 1.07,
        "symbol": "105.ITOS"
    },
    {
        "序号": 9698,
        "name": "Eaton Vance Municipal Bond Fund",
        "最新价": 9.84,
        "涨跌额": -0.07,
        "涨跌幅": -0.71,
        "开盘价": 9.91,
        "最高价": 9.91,
        "最低价": 9.82,
        "昨收价": 9.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 221906.0,
        "成交额": 2185669.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "107.EIM"
    },
    {
        "序号": 9699,
        "name": "VanEck India Growth Leaders ETF",
        "最新价": 42.14,
        "涨跌额": -0.3,
        "涨跌幅": -0.71,
        "开盘价": 42.35,
        "最高价": 42.44,
        "最低价": 42.06,
        "昨收价": 42.44,
        "总市值": null,
        "市盈率": null,
        "成交量": 175245.0,
        "成交额": 7402738.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "107.GLIN"
    },
    {
        "序号": 9700,
        "name": "Bumble Inc-A",
        "最新价": 14.04,
        "涨跌额": -0.1,
        "涨跌幅": -0.71,
        "开盘价": 14.06,
        "最高价": 14.28,
        "最低价": 13.91,
        "昨收价": 14.14,
        "总市值": 1919989333.0,
        "市盈率": -21.63,
        "成交量": 2374820.0,
        "成交额": 33411874.0,
        "振幅": 2.62,
        "换手率": 1.74,
        "symbol": "105.BMBL"
    },
    {
        "序号": 9701,
        "name": "长期债券指数ETF-Vanguard",
        "最新价": 71.58,
        "涨跌额": -0.51,
        "涨跌幅": -0.71,
        "开盘价": 71.48,
        "最高价": 71.74,
        "最低价": 71.24,
        "昨收价": 72.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 2075358.0,
        "成交额": 148206392.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.BLV"
    },
    {
        "序号": 9702,
        "name": "Arteris Inc",
        "最新价": 5.61,
        "涨跌额": -0.04,
        "涨跌幅": -0.71,
        "开盘价": 5.63,
        "最高价": 5.78,
        "最低价": 5.53,
        "昨收价": 5.65,
        "总市值": 206753089.0,
        "市盈率": -6.16,
        "成交量": 54325.0,
        "成交额": 305817.0,
        "振幅": 4.42,
        "换手率": 0.15,
        "symbol": "105.AIP"
    },
    {
        "序号": 9703,
        "name": "Humacyte Inc",
        "最新价": 2.8,
        "涨跌额": -0.02,
        "涨跌幅": -0.71,
        "开盘价": 2.81,
        "最高价": 2.85,
        "最低价": 2.72,
        "昨收价": 2.82,
        "总市值": 290010717.0,
        "市盈率": -3.24,
        "成交量": 319498.0,
        "成交额": 893042.0,
        "振幅": 4.61,
        "换手率": 0.31,
        "symbol": "105.HUMA"
    },
    {
        "序号": 9704,
        "name": "iShares MSCI Global Sustainable",
        "最新价": 76.95,
        "涨跌额": -0.55,
        "涨跌幅": -0.71,
        "开盘价": 77.1,
        "最高价": 77.33,
        "最低价": 76.77,
        "昨收价": 77.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 8335.0,
        "成交额": 642082.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "105.SDG"
    },
    {
        "序号": 9705,
        "name": "AT&T Inc Notes",
        "最新价": 22.36,
        "涨跌额": -0.16,
        "涨跌幅": -0.71,
        "开盘价": 22.52,
        "最高价": 22.57,
        "最低价": 22.28,
        "昨收价": 22.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 34708.0,
        "成交额": 777173.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "106.TBB"
    },
    {
        "序号": 9706,
        "name": "Orion Office REIT Inc",
        "最新价": 5.59,
        "涨跌额": -0.04,
        "涨跌幅": -0.71,
        "开盘价": 5.63,
        "最高价": 5.64,
        "最低价": 5.48,
        "昨收价": 5.63,
        "总市值": 311810502.0,
        "市盈率": -5.19,
        "成交量": 297435.0,
        "成交额": 1656837.0,
        "振幅": 2.84,
        "换手率": 0.53,
        "symbol": "106.ONL"
    },
    {
        "序号": 9707,
        "name": "晶科能源",
        "最新价": 32.14,
        "涨跌额": -0.23,
        "涨跌幅": -0.71,
        "开盘价": 32.39,
        "最高价": 33.34,
        "最低价": 31.55,
        "昨收价": 32.37,
        "总市值": 1657515073.0,
        "市盈率": 2.9,
        "成交量": 650584.0,
        "成交额": 20967616.0,
        "振幅": 5.53,
        "换手率": 1.26,
        "symbol": "106.JKS"
    },
    {
        "序号": 9708,
        "name": "NCR Voyix Corp",
        "最新价": 15.37,
        "涨跌额": -0.11,
        "涨跌幅": -0.71,
        "开盘价": 15.41,
        "最高价": 15.62,
        "最低价": 15.14,
        "昨收价": 15.48,
        "总市值": 2170244000.0,
        "市盈率": -19.38,
        "成交量": 1585642.0,
        "成交额": 24362134.0,
        "振幅": 3.1,
        "换手率": 1.12,
        "symbol": "106.VYX"
    },
    {
        "序号": 9709,
        "name": "Corcept Therapeutics Inc",
        "最新价": 26.54,
        "涨跌额": -0.19,
        "涨跌幅": -0.71,
        "开盘价": 26.74,
        "最高价": 26.98,
        "最低价": 26.46,
        "昨收价": 26.73,
        "总市值": 2735779215.0,
        "市盈率": 29.94,
        "成交量": 369620.0,
        "成交额": 9844138.0,
        "振幅": 1.95,
        "换手率": 0.36,
        "symbol": "105.CORT"
    },
    {
        "序号": 9710,
        "name": "野村控股",
        "最新价": 4.19,
        "涨跌额": -0.03,
        "涨跌幅": -0.71,
        "开盘价": 4.15,
        "最高价": 4.2,
        "最低价": 4.12,
        "昨收价": 4.22,
        "总市值": 12630917702.0,
        "市盈率": 13.09,
        "成交量": 5808648.0,
        "成交额": 24258070.0,
        "振幅": 1.9,
        "换手率": 0.19,
        "symbol": "106.NMR"
    },
    {
        "序号": 9711,
        "name": "食品饮料ETF-PowerShares",
        "最新价": 44.69,
        "涨跌额": -0.32,
        "涨跌幅": -0.71,
        "开盘价": 44.91,
        "最高价": 45.07,
        "最低价": 44.61,
        "昨收价": 45.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 15457.0,
        "成交额": 691299.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.PBJ"
    },
    {
        "序号": 9712,
        "name": "哈雷戴维森",
        "最新价": 32.1,
        "涨跌额": -0.23,
        "涨跌幅": -0.71,
        "开盘价": 32.25,
        "最高价": 32.73,
        "最低价": 32.1,
        "昨收价": 32.33,
        "总市值": 4470554224.0,
        "市盈率": 6.19,
        "成交量": 1098958.0,
        "成交额": 35513006.0,
        "振幅": 1.95,
        "换手率": 0.79,
        "symbol": "106.HOG"
    },
    {
        "序号": 9713,
        "name": "Invesco S&P 500 Equal Weight Co",
        "最新价": 30.69,
        "涨跌额": -0.22,
        "涨跌幅": -0.71,
        "开盘价": 30.91,
        "最高价": 30.93,
        "最低价": 30.67,
        "昨收价": 30.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 67891.0,
        "成交额": 2090028.0,
        "振幅": 0.84,
        "换手率": null,
        "symbol": "107.RSPS"
    },
    {
        "序号": 9714,
        "name": "First Horizon Corp Series B Pfd",
        "最新价": 22.3,
        "涨跌额": -0.16,
        "涨跌幅": -0.71,
        "开盘价": 22.69,
        "最高价": 22.69,
        "最低价": 22.25,
        "昨收价": 22.46,
        "总市值": 178400.0,
        "市盈率": null,
        "成交量": 4015.0,
        "成交额": 89936.0,
        "振幅": 1.96,
        "换手率": 50.19,
        "symbol": "106.FHN_B"
    },
    {
        "序号": 9715,
        "name": "Steelcase Inc-A",
        "最新价": 12.53,
        "涨跌额": -0.09,
        "涨跌幅": -0.71,
        "开盘价": 12.62,
        "最高价": 12.68,
        "最低价": 12.47,
        "昨收价": 12.62,
        "总市值": 1429193502.0,
        "市盈率": 25.48,
        "成交量": 412407.0,
        "成交额": 5174074.0,
        "振幅": 1.66,
        "换手率": 0.36,
        "symbol": "106.SCS"
    },
    {
        "序号": 9716,
        "name": "Public Storage Series P Pfd",
        "最新价": 18.09,
        "涨跌额": -0.13,
        "涨跌幅": -0.71,
        "开盘价": 18.18,
        "最高价": 18.24,
        "最低价": 18.03,
        "昨收价": 18.22,
        "总市值": 436874.0,
        "市盈率": null,
        "成交量": 28626.0,
        "成交额": 518901.0,
        "振幅": 1.15,
        "换手率": 118.53,
        "symbol": "106.PSA_P"
    },
    {
        "序号": 9717,
        "name": "InnovAge Holding Corp",
        "最新价": 5.56,
        "涨跌额": -0.04,
        "涨跌幅": -0.71,
        "开盘价": 5.56,
        "最高价": 5.69,
        "最低价": 5.5,
        "昨收价": 5.6,
        "总市值": 755519710.0,
        "市盈率": -19.93,
        "成交量": 16461.0,
        "成交额": 91676.0,
        "振幅": 3.39,
        "换手率": 0.01,
        "symbol": "105.INNV"
    },
    {
        "序号": 9718,
        "name": "Ads-Tec Energy PLC",
        "最新价": 6.95,
        "涨跌额": -0.05,
        "涨跌幅": -0.71,
        "开盘价": 6.95,
        "最高价": 6.95,
        "最低价": 6.81,
        "昨收价": 7.0,
        "总市值": 339214891.0,
        "市盈率": -16.83,
        "成交量": 48263.0,
        "成交额": 333551.0,
        "振幅": 2.0,
        "换手率": 0.1,
        "symbol": "105.ADSE"
    },
    {
        "序号": 9719,
        "name": "Enstar Group Ltd Series E Pfd",
        "最新价": 23.6,
        "涨跌额": -0.17,
        "涨跌幅": -0.72,
        "开盘价": 23.79,
        "最高价": 23.79,
        "最低价": 23.55,
        "昨收价": 23.77,
        "总市值": 103840.0,
        "市盈率": null,
        "成交量": 5815.0,
        "成交额": 137222.0,
        "振幅": 1.01,
        "换手率": 132.16,
        "symbol": "105.ESGRO"
    },
    {
        "序号": 9720,
        "name": "iShares JPX-Nikkei 400 ETF",
        "最新价": 66.62,
        "涨跌额": -0.48,
        "涨跌幅": -0.72,
        "开盘价": 66.38,
        "最高价": 66.66,
        "最低价": 66.22,
        "昨收价": 67.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 7856.0,
        "成交额": 522338.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.JPXN"
    },
    {
        "序号": 9721,
        "name": "亨斯曼材料",
        "最新价": 24.97,
        "涨跌额": -0.18,
        "涨跌幅": -0.72,
        "开盘价": 25.23,
        "最高价": 25.3,
        "最低价": 24.93,
        "昨收价": 25.15,
        "总市值": 4351956453.0,
        "市盈率": 53.73,
        "成交量": 1054456.0,
        "成交额": 26404934.0,
        "振幅": 1.47,
        "换手率": 0.61,
        "symbol": "106.HUN"
    },
    {
        "序号": 9722,
        "name": "FlexShares Credit-Scored US Lon",
        "最新价": 42.95,
        "涨跌额": -0.31,
        "涨跌幅": -0.72,
        "开盘价": 42.96,
        "最高价": 43.14,
        "最低价": 42.95,
        "昨收价": 43.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 905.0,
        "成交额": 38929.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.LKOR"
    },
    {
        "序号": 9723,
        "name": "环球物流控股",
        "最新价": 26.3,
        "涨跌额": -0.19,
        "涨跌幅": -0.72,
        "开盘价": 26.28,
        "最高价": 26.92,
        "最低价": 25.93,
        "昨收价": 26.49,
        "总市值": 691275065.0,
        "市盈率": 6.59,
        "成交量": 28222.0,
        "成交额": 744060.0,
        "振幅": 3.74,
        "换手率": 0.11,
        "symbol": "105.ULH"
    },
    {
        "序号": 9724,
        "name": "AGF U.S. Market Neutral Anti-Be",
        "最新价": 19.34,
        "涨跌额": -0.14,
        "涨跌幅": -0.72,
        "开盘价": 19.55,
        "最高价": 19.55,
        "最低价": 19.18,
        "昨收价": 19.48,
        "总市值": null,
        "市盈率": null,
        "成交量": 309635.0,
        "成交额": 5973170.0,
        "振幅": 1.9,
        "换手率": null,
        "symbol": "107.BTAL"
    },
    {
        "序号": 9725,
        "name": "普普文化",
        "最新价": 1.38,
        "涨跌额": -0.01,
        "涨跌幅": -0.72,
        "开盘价": 1.39,
        "最高价": 1.39,
        "最低价": 1.31,
        "昨收价": 1.39,
        "总市值": 3318900.0,
        "市盈率": -0.14,
        "成交量": 1689.0,
        "成交额": 2278.0,
        "振幅": 5.76,
        "换手率": 0.07,
        "symbol": "105.CPOP"
    },
    {
        "序号": 9726,
        "name": "Revvity Inc",
        "最新价": 91.02,
        "涨跌额": -0.66,
        "涨跌幅": -0.72,
        "开盘价": 91.43,
        "最高价": 92.4,
        "最低价": 90.37,
        "昨收价": 91.68,
        "总市值": 11232531354.0,
        "市盈率": 15.13,
        "成交量": 550520.0,
        "成交额": 50138567.0,
        "振幅": 2.21,
        "换手率": 0.45,
        "symbol": "106.RVTY"
    },
    {
        "序号": 9727,
        "name": "Catalent Inc",
        "最新价": 37.18,
        "涨跌额": -0.27,
        "涨跌幅": -0.72,
        "开盘价": 37.21,
        "最高价": 37.87,
        "最低价": 37.11,
        "昨收价": 37.45,
        "总市值": 6716242493.0,
        "市盈率": -6.78,
        "成交量": 1247523.0,
        "成交额": 46515772.0,
        "振幅": 2.03,
        "换手率": 0.69,
        "symbol": "106.CTLT"
    },
    {
        "序号": 9728,
        "name": "大自然林业",
        "最新价": 8.24,
        "涨跌额": -0.06,
        "涨跌幅": -0.72,
        "开盘价": 8.4,
        "最高价": 8.55,
        "最低价": 8.24,
        "昨收价": 8.3,
        "总市值": 114600890.0,
        "市盈率": 23.98,
        "成交量": 3067.0,
        "成交额": 25557.0,
        "振幅": 3.73,
        "换手率": 0.02,
        "symbol": "105.NWGL"
    },
    {
        "序号": 9729,
        "name": "BlackRock MuniVest Fund",
        "最新价": 6.85,
        "涨跌额": -0.05,
        "涨跌幅": -0.72,
        "开盘价": 6.89,
        "最高价": 6.92,
        "最低价": 6.83,
        "昨收价": 6.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 128342.0,
        "成交额": 881524.0,
        "振幅": 1.3,
        "换手率": null,
        "symbol": "106.MVF"
    },
    {
        "序号": 9730,
        "name": "微博",
        "最新价": 9.57,
        "涨跌额": -0.07,
        "涨跌幅": -0.73,
        "开盘价": 9.56,
        "最高价": 9.81,
        "最低价": 9.51,
        "昨收价": 9.64,
        "总市值": 2321786715.0,
        "市盈率": 5.79,
        "成交量": 4142883.0,
        "成交额": 39824458.0,
        "振幅": 3.11,
        "换手率": 1.71,
        "symbol": "105.WB"
    },
    {
        "序号": 9731,
        "name": "亿滋国际",
        "最新价": 71.09,
        "涨跌额": -0.52,
        "涨跌幅": -0.73,
        "开盘价": 71.5,
        "最高价": 71.79,
        "最低价": 71.05,
        "昨收价": 71.61,
        "总市值": 96746067138.0,
        "市盈率": 21.07,
        "成交量": 5321883.0,
        "成交额": 379355856.0,
        "振幅": 1.03,
        "换手率": 0.39,
        "symbol": "105.MDLZ"
    },
    {
        "序号": 9732,
        "name": "Kimco Realty Corp Class M Pfd",
        "最新价": 23.23,
        "涨跌额": -0.17,
        "涨跌幅": -0.73,
        "开盘价": 23.33,
        "最高价": 23.35,
        "最低价": 23.11,
        "昨收价": 23.4,
        "总市值": 243102.0,
        "市盈率": null,
        "成交量": 11232.0,
        "成交额": 261167.0,
        "振幅": 1.03,
        "换手率": 107.33,
        "symbol": "106.KIM_M"
    },
    {
        "序号": 9733,
        "name": "AGNC Investment Corp Series E P",
        "最新价": 23.23,
        "涨跌额": -0.17,
        "涨跌幅": -0.73,
        "开盘价": 23.32,
        "最高价": 23.48,
        "最低价": 23.03,
        "昨收价": 23.4,
        "总市值": 374003.0,
        "市盈率": null,
        "成交量": 37230.0,
        "成交额": 866268.0,
        "振幅": 1.92,
        "换手率": 231.24,
        "symbol": "105.AGNCO"
    },
    {
        "序号": 9734,
        "name": "Ready Capital Corp Series C Pfd",
        "最新价": 17.76,
        "涨跌额": -0.13,
        "涨跌幅": -0.73,
        "开盘价": 17.62,
        "最高价": 18.0,
        "最低价": 17.62,
        "昨收价": 17.89,
        "总市值": 5943881.0,
        "市盈率": null,
        "成交量": 1072.0,
        "成交额": 19107.0,
        "振幅": 2.12,
        "换手率": 0.32,
        "symbol": "106.RC_C"
    },
    {
        "序号": 9735,
        "name": "biote Corp-A",
        "最新价": 5.46,
        "涨跌额": -0.04,
        "涨跌幅": -0.73,
        "开盘价": 5.5,
        "最高价": 5.63,
        "最低价": 5.44,
        "昨收价": 5.5,
        "总市值": 397266428.0,
        "市盈率": -142.08,
        "成交量": 44759.0,
        "成交额": 246731.0,
        "振幅": 3.45,
        "换手率": 0.06,
        "symbol": "105.BTMD"
    },
    {
        "序号": 9736,
        "name": "讯鸟软件",
        "最新价": 1.36,
        "涨跌额": -0.01,
        "涨跌幅": -0.73,
        "开盘价": 1.37,
        "最高价": 1.38,
        "最低价": 1.32,
        "昨收价": 1.37,
        "总市值": 6338001.0,
        "市盈率": -0.52,
        "成交量": 17773.0,
        "成交额": 23783.0,
        "振幅": 4.38,
        "换手率": 0.38,
        "symbol": "105.IFBD"
    },
    {
        "序号": 9737,
        "name": "Arcos Dorados Holdings Inc-A",
        "最新价": 12.21,
        "涨跌额": -0.09,
        "涨跌幅": -0.73,
        "开盘价": 12.23,
        "最高价": 12.43,
        "最低价": 12.16,
        "昨收价": 12.3,
        "总市值": 2572097171.0,
        "市盈率": 14.29,
        "成交量": 768030.0,
        "成交额": 9412678.0,
        "振幅": 2.2,
        "换手率": 0.36,
        "symbol": "106.ARCO"
    },
    {
        "序号": 9738,
        "name": "Citigroup Inc Series J Pfd",
        "最新价": 25.73,
        "涨跌额": -0.19,
        "涨跌幅": -0.73,
        "开盘价": 25.86,
        "最高价": 25.9,
        "最低价": 25.71,
        "昨收价": 25.92,
        "总市值": 977740.0,
        "市盈率": null,
        "成交量": 97198.0,
        "成交额": 2505654.0,
        "振幅": 0.73,
        "换手率": 255.78,
        "symbol": "106.C_J"
    },
    {
        "序号": 9739,
        "name": "Neuberger Berman Energy Infrast",
        "最新价": 6.77,
        "涨跌额": -0.05,
        "涨跌幅": -0.73,
        "开盘价": 6.83,
        "最高价": 6.92,
        "最低价": 6.75,
        "昨收价": 6.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 160421.0,
        "成交额": 1090583.0,
        "振幅": 2.49,
        "换手率": null,
        "symbol": "107.NML"
    },
    {
        "序号": 9740,
        "name": "Standex International Corp",
        "最新价": 139.33,
        "涨跌额": -1.03,
        "涨跌幅": -0.73,
        "开盘价": 140.73,
        "最高价": 141.62,
        "最低价": 138.04,
        "昨收价": 140.36,
        "总市值": 1657968342.0,
        "市盈率": 11.88,
        "成交量": 50642.0,
        "成交额": 7071176.0,
        "振幅": 2.55,
        "换手率": 0.43,
        "symbol": "106.SXI"
    },
    {
        "序号": 9741,
        "name": "捷蓝航空",
        "最新价": 5.41,
        "涨跌额": -0.04,
        "涨跌幅": -0.73,
        "开盘价": 5.47,
        "最高价": 5.61,
        "最低价": 5.32,
        "昨收价": 5.45,
        "总市值": 1803095254.0,
        "市盈率": -9.85,
        "成交量": 16716764.0,
        "成交额": 90840433.0,
        "振幅": 5.32,
        "换手率": 5.02,
        "symbol": "105.JBLU"
    },
    {
        "序号": 9742,
        "name": "安可资本",
        "最新价": 45.97,
        "涨跌额": -0.34,
        "涨跌幅": -0.73,
        "开盘价": 46.35,
        "最高价": 46.85,
        "最低价": 45.02,
        "昨收价": 46.31,
        "总市值": 1081609604.0,
        "市盈率": -122.24,
        "成交量": 135220.0,
        "成交额": 6245187.0,
        "振幅": 3.95,
        "换手率": 0.57,
        "symbol": "105.ECPG"
    },
    {
        "序号": 9743,
        "name": "Check-Cap Ltd",
        "最新价": 2.7,
        "涨跌额": -0.02,
        "涨跌幅": -0.74,
        "开盘价": 2.67,
        "最高价": 2.87,
        "最低价": 2.62,
        "昨收价": 2.72,
        "总市值": 15795983.0,
        "市盈率": -0.9,
        "成交量": 7373.0,
        "成交额": 20578.0,
        "振幅": 9.19,
        "换手率": 0.13,
        "symbol": "105.CHEK"
    },
    {
        "序号": 9744,
        "name": "Paramount Group Inc",
        "最新价": 5.39,
        "涨跌额": -0.04,
        "涨跌幅": -0.74,
        "开盘价": 5.39,
        "最高价": 5.43,
        "最低价": 5.28,
        "昨收价": 5.43,
        "总市值": 1171535403.0,
        "市盈率": -12.72,
        "成交量": 1955588.0,
        "成交额": 10467311.0,
        "振幅": 2.76,
        "换手率": 0.9,
        "symbol": "106.PGRE"
    },
    {
        "序号": 9745,
        "name": "Athene Holding Ltd Series E Pfd",
        "最新价": 25.59,
        "涨跌额": -0.19,
        "涨跌幅": -0.74,
        "开盘价": 25.7,
        "最高价": 25.79,
        "最低价": 25.5,
        "昨收价": 25.78,
        "总市值": 511800.0,
        "市盈率": null,
        "成交量": 24851.0,
        "成交额": 635159.0,
        "振幅": 1.12,
        "换手率": 124.25,
        "symbol": "106.ATH_E"
    },
    {
        "序号": 9746,
        "name": "思泰瑞医疗",
        "最新价": 200.5,
        "涨跌额": -1.49,
        "涨跌幅": -0.74,
        "开盘价": 202.64,
        "最高价": 202.64,
        "最低价": 197.98,
        "昨收价": 201.99,
        "总市值": 19809432281.0,
        "市盈率": 36.02,
        "成交量": 597401.0,
        "成交额": 119440672.0,
        "振幅": 2.31,
        "换手率": 0.6,
        "symbol": "106.STE"
    },
    {
        "序号": 9747,
        "name": "Retail Opportunity Investments ",
        "最新价": 13.42,
        "涨跌额": -0.1,
        "涨跌幅": -0.74,
        "开盘价": 13.44,
        "最高价": 13.55,
        "最低价": 13.27,
        "昨收价": 13.52,
        "总市值": 1690944089.0,
        "市盈率": 46.08,
        "成交量": 658095.0,
        "成交额": 8816349.0,
        "振幅": 2.07,
        "换手率": 0.52,
        "symbol": "105.ROIC"
    },
    {
        "序号": 9748,
        "name": "卡夫亨氏",
        "最新价": 36.23,
        "涨跌额": -0.27,
        "涨跌幅": -0.74,
        "开盘价": 36.52,
        "最高价": 36.54,
        "最低价": 36.08,
        "昨收价": 36.5,
        "总市值": 44437496884.0,
        "市盈率": 14.87,
        "成交量": 6113227.0,
        "成交额": 221760841.0,
        "振幅": 1.26,
        "换手率": 0.5,
        "symbol": "105.KHC"
    },
    {
        "序号": 9749,
        "name": "American International Group In",
        "最新价": 24.1,
        "涨跌额": -0.18,
        "涨跌幅": -0.74,
        "开盘价": 24.22,
        "最高价": 24.39,
        "最低价": 23.97,
        "昨收价": 24.28,
        "总市值": 482000.0,
        "市盈率": null,
        "成交量": 43677.0,
        "成交额": 1052994.0,
        "振幅": 1.73,
        "换手率": 218.38,
        "symbol": "106.AIG_A"
    },
    {
        "序号": 9750,
        "name": "Arhaus Inc-A",
        "最新价": 9.37,
        "涨跌额": -0.07,
        "涨跌幅": -0.74,
        "开盘价": 9.36,
        "最高价": 9.4,
        "最低价": 9.2,
        "昨收价": 9.44,
        "总市值": 1314450829.0,
        "市盈率": 9.32,
        "成交量": 641574.0,
        "成交额": 5979725.0,
        "振幅": 2.12,
        "换手率": 0.46,
        "symbol": "105.ARHS"
    },
    {
        "序号": 9751,
        "name": "全球除美国房地产指数ETF",
        "最新价": 41.49,
        "涨跌额": -0.31,
        "涨跌幅": -0.74,
        "开盘价": 41.36,
        "最高价": 41.64,
        "最低价": 41.26,
        "昨收价": 41.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 181927.0,
        "成交额": 7534009.0,
        "振幅": 0.91,
        "换手率": null,
        "symbol": "105.VNQI"
    },
    {
        "序号": 9752,
        "name": "联合太平洋",
        "最新价": 229.77,
        "涨跌额": -1.72,
        "涨跌幅": -0.74,
        "开盘价": 231.64,
        "最高价": 232.92,
        "最低价": 229.51,
        "昨收价": 231.49,
        "总市值": 140067216426.0,
        "市盈率": 22.01,
        "成交量": 2544779.0,
        "成交额": 585861728.0,
        "振幅": 1.47,
        "换手率": 0.42,
        "symbol": "106.UNP"
    },
    {
        "序号": 9753,
        "name": "US Bancorp Series L Pfd",
        "最新价": 16.03,
        "涨跌额": -0.12,
        "涨跌幅": -0.74,
        "开盘价": 16.1,
        "最高价": 16.16,
        "最低价": 15.95,
        "昨收价": 16.15,
        "总市值": 3206000.0,
        "市盈率": null,
        "成交量": 35973.0,
        "成交额": 577270.0,
        "振幅": 1.3,
        "换手率": 17.99,
        "symbol": "106.USB_Q"
    },
    {
        "序号": 9754,
        "name": "金砖四国ETF-iShares MSCI",
        "最新价": 33.38,
        "涨跌额": -0.25,
        "涨跌幅": -0.74,
        "开盘价": 33.43,
        "最高价": 33.49,
        "最低价": 33.32,
        "昨收价": 33.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 7954.0,
        "成交额": 265623.0,
        "振幅": 0.51,
        "换手率": null,
        "symbol": "107.BKF"
    },
    {
        "序号": 9755,
        "name": "Direxion Daily AAPL Bear 1X Sha",
        "最新价": 20.02,
        "涨跌额": -0.15,
        "涨跌幅": -0.74,
        "开盘价": 20.21,
        "最高价": 20.24,
        "最低价": 20.01,
        "昨收价": 20.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 93789.0,
        "成交额": 1884683.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "105.AAPD"
    },
    {
        "序号": 9756,
        "name": "Bread Financial Holdings Inc",
        "最新价": 30.69,
        "涨跌额": -0.23,
        "涨跌幅": -0.74,
        "开盘价": 30.52,
        "最高价": 31.19,
        "最低价": 30.38,
        "昨收价": 30.92,
        "总市值": 1513956881.0,
        "市盈率": 2.8,
        "成交量": 579277.0,
        "成交额": 17847963.0,
        "振幅": 2.62,
        "换手率": 1.17,
        "symbol": "106.BFH"
    },
    {
        "序号": 9757,
        "name": "Piedmont Office Realty Trust In",
        "最新价": 6.67,
        "涨跌额": -0.05,
        "涨跌幅": -0.74,
        "开盘价": 6.67,
        "最高价": 6.72,
        "最低价": 6.57,
        "昨收价": 6.72,
        "总市值": 825168531.0,
        "市盈率": 14.95,
        "成交量": 762443.0,
        "成交额": 5074854.0,
        "振幅": 2.23,
        "换手率": 0.62,
        "symbol": "106.PDM"
    },
    {
        "序号": 9758,
        "name": "AXS Green Alpha ETF",
        "最新价": 30.67,
        "涨跌额": -0.23,
        "涨跌幅": -0.74,
        "开盘价": 30.8,
        "最高价": 30.81,
        "最低价": 30.47,
        "昨收价": 30.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 4424.0,
        "成交额": 135650.0,
        "振幅": 1.1,
        "换手率": null,
        "symbol": "107.NXTE"
    },
    {
        "序号": 9759,
        "name": "ACM Research Inc-A",
        "最新价": 16.0,
        "涨跌额": -0.12,
        "涨跌幅": -0.74,
        "开盘价": 16.23,
        "最高价": 16.28,
        "最低价": 15.87,
        "昨收价": 16.12,
        "总市值": 969724720.0,
        "市盈率": 13.57,
        "成交量": 456916.0,
        "成交额": 7322129.0,
        "振幅": 2.54,
        "换手率": 0.75,
        "symbol": "105.ACMR"
    },
    {
        "序号": 9760,
        "name": "ProShares Short Ether Strategy ",
        "最新价": 30.66,
        "涨跌额": -0.23,
        "涨跌幅": -0.74,
        "开盘价": 30.78,
        "最高价": 30.9,
        "最低价": 30.66,
        "昨收价": 30.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 556.0,
        "成交额": 17119.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.SETH"
    },
    {
        "序号": 9761,
        "name": "Triton International Ltd Series",
        "最新价": 23.97,
        "涨跌额": -0.18,
        "涨跌幅": -0.75,
        "开盘价": 23.85,
        "最高价": 24.0,
        "最低价": 23.75,
        "昨收价": 24.15,
        "总市值": 167790000.0,
        "市盈率": null,
        "成交量": 15879.0,
        "成交额": 378684.0,
        "振幅": 1.04,
        "换手率": 0.23,
        "symbol": "106.TRTN_C"
    },
    {
        "序号": 9762,
        "name": "SYLA Technologies Co Ltd ADR",
        "最新价": 3.99,
        "涨跌额": -0.03,
        "涨跌幅": -0.75,
        "开盘价": 4.02,
        "最高价": 4.17,
        "最低价": 3.99,
        "昨收价": 4.02,
        "总市值": 103497009.0,
        "市盈率": 26.13,
        "成交量": 14213.0,
        "成交额": 57730.0,
        "振幅": 4.48,
        "换手率": 0.05,
        "symbol": "105.SYT"
    },
    {
        "序号": 9763,
        "name": "希尔威金属矿业",
        "最新价": 2.66,
        "涨跌额": -0.02,
        "涨跌幅": -0.75,
        "开盘价": 2.65,
        "最高价": 2.72,
        "最低价": 2.63,
        "昨收价": 2.68,
        "总市值": 470341171.0,
        "市盈率": 14.51,
        "成交量": 1121926.0,
        "成交额": 3005296.0,
        "振幅": 3.36,
        "换手率": 0.63,
        "symbol": "107.SVM"
    },
    {
        "序号": 9764,
        "name": "Organigram Holdings Inc",
        "最新价": 1.33,
        "涨跌额": -0.01,
        "涨跌幅": -0.75,
        "开盘价": 1.32,
        "最高价": 1.38,
        "最低价": 1.31,
        "昨收价": 1.34,
        "总市值": 107944968.0,
        "市盈率": -0.66,
        "成交量": 541472.0,
        "成交额": 725620.0,
        "振幅": 5.22,
        "换手率": 0.67,
        "symbol": "105.OGI"
    },
    {
        "序号": 9765,
        "name": "Concrete Pumping Holdings Inc",
        "最新价": 7.98,
        "涨跌额": -0.06,
        "涨跌幅": -0.75,
        "开盘价": 8.08,
        "最高价": 8.14,
        "最低价": 7.96,
        "昨收价": 8.04,
        "总市值": 436583741.0,
        "市盈率": 14.11,
        "成交量": 32707.0,
        "成交额": 262040.0,
        "振幅": 2.24,
        "换手率": 0.06,
        "symbol": "105.BBCP"
    },
    {
        "序号": 9766,
        "name": "IDACORP Inc",
        "最新价": 99.66,
        "涨跌额": -0.75,
        "涨跌幅": -0.75,
        "开盘价": 100.21,
        "最高价": 100.48,
        "最低价": 98.79,
        "昨收价": 100.41,
        "总市值": 5323534333.0,
        "市盈率": 19.57,
        "成交量": 371264.0,
        "成交额": 36952941.0,
        "振幅": 1.68,
        "换手率": 0.7,
        "symbol": "106.IDA"
    },
    {
        "序号": 9767,
        "name": "Nuveen Preferred & Income Oppor",
        "最新价": 6.63,
        "涨跌额": -0.05,
        "涨跌幅": -0.75,
        "开盘价": 6.66,
        "最高价": 6.67,
        "最低价": 6.61,
        "昨收价": 6.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 959128.0,
        "成交额": 6370419.0,
        "振幅": 0.9,
        "换手率": null,
        "symbol": "106.JPC"
    },
    {
        "序号": 9768,
        "name": "Tri-Continental Corp Pfd",
        "最新价": 43.7,
        "涨跌额": -0.33,
        "涨跌幅": -0.75,
        "开盘价": 44.38,
        "最高价": 44.38,
        "最低价": 43.7,
        "昨收价": 44.03,
        "总市值": 32894738.0,
        "市盈率": null,
        "成交量": 1154.0,
        "成交额": 50827.0,
        "振幅": 1.54,
        "换手率": 0.15,
        "symbol": "106.TY_"
    },
    {
        "序号": 9769,
        "name": "Eagle Point Income Co Inc Serie",
        "最新价": 25.15,
        "涨跌额": -0.19,
        "涨跌幅": -0.75,
        "开盘价": 25.0,
        "最高价": 25.43,
        "最低价": 24.85,
        "昨收价": 25.34,
        "总市值": 28432075.0,
        "市盈率": null,
        "成交量": 8201.0,
        "成交额": 206249.0,
        "振幅": 2.29,
        "换手率": 0.73,
        "symbol": "106.EICB"
    },
    {
        "序号": 9770,
        "name": "iShares MSCI Emerging Markets S",
        "最新价": 56.73,
        "涨跌额": -0.43,
        "涨跌幅": -0.75,
        "开盘价": 56.82,
        "最高价": 56.94,
        "最低价": 56.69,
        "昨收价": 57.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 22513.0,
        "成交额": 1278025.0,
        "振幅": 0.44,
        "换手率": null,
        "symbol": "107.EEMS"
    },
    {
        "序号": 9771,
        "name": "Subversive Food Security ETF",
        "最新价": 21.09,
        "涨跌额": -0.16,
        "涨跌幅": -0.75,
        "开盘价": 21.09,
        "最高价": 21.09,
        "最低价": 21.09,
        "昨收价": 21.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 4.0,
        "成交额": 84.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KCAL"
    },
    {
        "序号": 9772,
        "name": "InPoint Commercial Real Estate ",
        "最新价": 18.44,
        "涨跌额": -0.14,
        "涨跌幅": -0.75,
        "开盘价": 18.82,
        "最高价": 18.82,
        "最低价": 18.44,
        "昨收价": 18.58,
        "总市值": 65361557.0,
        "市盈率": null,
        "成交量": 4030.0,
        "成交额": 74968.0,
        "振幅": 2.05,
        "换手率": 0.11,
        "symbol": "106.ICR_A"
    },
    {
        "序号": 9773,
        "name": "Kennedy-Wilson Holdings Inc",
        "最新价": 11.85,
        "涨跌额": -0.09,
        "涨跌幅": -0.75,
        "开盘价": 11.89,
        "最高价": 12.02,
        "最低价": 11.69,
        "昨收价": 11.94,
        "总市值": 1651781418.0,
        "市盈率": -45.38,
        "成交量": 1311309.0,
        "成交额": 15505148.0,
        "振幅": 2.76,
        "换手率": 0.94,
        "symbol": "106.KW"
    },
    {
        "序号": 9774,
        "name": "Heritage Global Inc",
        "最新价": 2.63,
        "涨跌额": -0.02,
        "涨跌幅": -0.75,
        "开盘价": 2.69,
        "最高价": 2.69,
        "最低价": 2.62,
        "昨收价": 2.65,
        "总市值": 97709560.0,
        "市盈率": 5.57,
        "成交量": 69921.0,
        "成交额": 185306.0,
        "振幅": 2.64,
        "换手率": 0.19,
        "symbol": "105.HGBL"
    },
    {
        "序号": 9775,
        "name": "Brookfield BRP Holdings (Canada",
        "最新价": 15.78,
        "涨跌额": -0.12,
        "涨跌幅": -0.75,
        "开盘价": 15.87,
        "最高价": 15.94,
        "最低价": 15.6,
        "昨收价": 15.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 8760.0,
        "成交额": 138301.0,
        "振幅": 2.14,
        "换手率": null,
        "symbol": "106.BEPI"
    },
    {
        "序号": 9776,
        "name": "威瑞信",
        "最新价": 212.97,
        "涨跌额": -1.62,
        "涨跌幅": -0.75,
        "开盘价": 214.1,
        "最高价": 215.27,
        "最低价": 212.11,
        "昨收价": 214.59,
        "总市值": 21744237000.0,
        "市盈率": 29.69,
        "成交量": 477958.0,
        "成交额": 101817049.0,
        "振幅": 1.47,
        "换手率": 0.47,
        "symbol": "105.VRSN"
    },
    {
        "序号": 9777,
        "name": "John Hancock Income Securities ",
        "最新价": 10.51,
        "涨跌额": -0.08,
        "涨跌幅": -0.76,
        "开盘价": 10.52,
        "最高价": 10.55,
        "最低价": 10.51,
        "昨收价": 10.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 6273.0,
        "成交额": 66033.0,
        "振幅": 0.38,
        "换手率": null,
        "symbol": "106.JHS"
    },
    {
        "序号": 9778,
        "name": "国际主权债ETF-SPDR",
        "最新价": 22.33,
        "涨跌额": -0.17,
        "涨跌幅": -0.76,
        "开盘价": 22.38,
        "最高价": 22.45,
        "最低价": 22.23,
        "昨收价": 22.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 221460.0,
        "成交额": 4938894.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.BWX"
    },
    {
        "序号": 9779,
        "name": "Invesco MSCI Green Building ETF",
        "最新价": 17.07,
        "涨跌额": -0.13,
        "涨跌幅": -0.76,
        "开盘价": 16.98,
        "最高价": 17.07,
        "最低价": 16.98,
        "昨收价": 17.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 968.0,
        "成交额": 16461.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.GBLD"
    },
    {
        "序号": 9780,
        "name": "10-20年国债ETF-iShares",
        "最新价": 103.54,
        "涨跌额": -0.79,
        "涨跌幅": -0.76,
        "开盘价": 103.54,
        "最高价": 103.87,
        "最低价": 103.09,
        "昨收价": 104.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 879253.0,
        "成交额": 90995446.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.TLH"
    },
    {
        "序号": 9781,
        "name": "艾奥特通讯",
        "最新价": 1.31,
        "涨跌额": -0.01,
        "涨跌幅": -0.76,
        "开盘价": 1.31,
        "最高价": 1.38,
        "最低价": 1.28,
        "昨收价": 1.32,
        "总市值": 50115309.0,
        "市盈率": -0.98,
        "成交量": 82945.0,
        "成交额": 109978.0,
        "振幅": 7.58,
        "换手率": 0.22,
        "symbol": "105.ALLT"
    },
    {
        "序号": 9782,
        "name": "二倍做空纳斯达克100ETF",
        "最新价": 11.78,
        "涨跌额": -0.09,
        "涨跌幅": -0.76,
        "开盘价": 11.99,
        "最高价": 12.01,
        "最低价": 11.75,
        "昨收价": 11.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 23091080.0,
        "成交额": 273562464.0,
        "振幅": 2.19,
        "换手率": null,
        "symbol": "107.QID"
    },
    {
        "序号": 9783,
        "name": "Rexford Industrial Realty Inc S",
        "最新价": 22.2,
        "涨跌额": -0.17,
        "涨跌幅": -0.76,
        "开盘价": 22.21,
        "最高价": 22.25,
        "最低价": 22.2,
        "昨收价": 22.37,
        "总市值": 66600000.0,
        "市盈率": null,
        "成交量": 6567.0,
        "成交额": 146026.0,
        "振幅": 0.22,
        "换手率": 0.22,
        "symbol": "106.REXR_B"
    },
    {
        "序号": 9784,
        "name": "Paylocity Holding Corp",
        "最新价": 152.35,
        "涨跌额": -1.17,
        "涨跌幅": -0.76,
        "开盘价": 152.28,
        "最高价": 156.78,
        "最低价": 150.05,
        "昨收价": 153.52,
        "总市值": 8558549344.0,
        "市盈率": 59.03,
        "成交量": 323160.0,
        "成交额": 49718917.0,
        "振幅": 4.38,
        "换手率": 0.58,
        "symbol": "105.PCTY"
    },
    {
        "序号": 9785,
        "name": "Global X Telemedicine & Digital",
        "最新价": 9.11,
        "涨跌额": -0.07,
        "涨跌幅": -0.76,
        "开盘价": 9.12,
        "最高价": 9.2,
        "最低价": 9.08,
        "昨收价": 9.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 22987.0,
        "成交额": 210022.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "105.EDOC"
    },
    {
        "序号": 9786,
        "name": "再鼎医药",
        "最新价": 27.29,
        "涨跌额": -0.21,
        "涨跌幅": -0.76,
        "开盘价": 26.97,
        "最高价": 27.44,
        "最低价": 26.52,
        "昨收价": 27.5,
        "总市值": 2697309296.0,
        "市盈率": -8.96,
        "成交量": 437859.0,
        "成交额": 11854137.0,
        "振幅": 3.35,
        "换手率": 0.44,
        "symbol": "105.ZLAB"
    },
    {
        "序号": 9787,
        "name": "百思买",
        "最新价": 73.99,
        "涨跌额": -0.57,
        "涨跌幅": -0.76,
        "开盘价": 74.93,
        "最高价": 74.96,
        "最低价": 73.78,
        "昨收价": 74.56,
        "总市值": 15937147820.0,
        "市盈率": 12.49,
        "成交量": 2834089.0,
        "成交额": 210332256.0,
        "振幅": 1.58,
        "换手率": 1.32,
        "symbol": "106.BBY"
    },
    {
        "序号": 9788,
        "name": "HighPeak Energy Inc",
        "最新价": 14.24,
        "涨跌额": -0.11,
        "涨跌幅": -0.77,
        "开盘价": 14.43,
        "最高价": 14.72,
        "最低价": 14.19,
        "昨收价": 14.35,
        "总市值": 1828713944.0,
        "市盈率": 9.69,
        "成交量": 414062.0,
        "成交额": 5965384.0,
        "振幅": 3.69,
        "换手率": 0.32,
        "symbol": "105.HPK"
    },
    {
        "序号": 9789,
        "name": "WisdomTree Efficient Gold Plus ",
        "最新价": 25.86,
        "涨跌额": -0.2,
        "涨跌幅": -0.77,
        "开盘价": 26.34,
        "最高价": 26.34,
        "最低价": 25.65,
        "昨收价": 26.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 2796.0,
        "成交额": 72307.0,
        "振幅": 2.65,
        "换手率": null,
        "symbol": "107.GDE"
    },
    {
        "序号": 9790,
        "name": "iShares Core 10+ Year USD Bond ",
        "最新价": 50.4,
        "涨跌额": -0.39,
        "涨跌幅": -0.77,
        "开盘价": 50.42,
        "最高价": 50.52,
        "最低价": 50.25,
        "昨收价": 50.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 73852.0,
        "成交额": 3721319.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.ILTB"
    },
    {
        "序号": 9791,
        "name": "WisdomTree BioRevolution Fund",
        "最新价": 15.5,
        "涨跌额": -0.12,
        "涨跌幅": -0.77,
        "开盘价": 15.53,
        "最高价": 15.53,
        "最低价": 15.5,
        "昨收价": 15.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 214.0,
        "成交额": 3323.0,
        "振幅": 0.19,
        "换手率": null,
        "symbol": "107.WDNA"
    },
    {
        "序号": 9792,
        "name": "欢聚",
        "最新价": 37.44,
        "涨跌额": -0.29,
        "涨跌幅": -0.77,
        "开盘价": 37.51,
        "最高价": 37.66,
        "最低价": 37.08,
        "昨收价": 37.73,
        "总市值": 2276183164.0,
        "市盈率": -18.73,
        "成交量": 815905.0,
        "成交额": 30505188.0,
        "振幅": 1.54,
        "换手率": 1.34,
        "symbol": "105.YY"
    },
    {
        "序号": 9793,
        "name": "Brookfield Business Partners LP",
        "最新价": 16.77,
        "涨跌额": -0.13,
        "涨跌幅": -0.77,
        "开盘价": 16.78,
        "最高价": 17.1,
        "最低价": 16.77,
        "昨收价": 16.9,
        "总市值": 1250352954.0,
        "市盈率": 156.29,
        "成交量": 4274.0,
        "成交额": 72590.0,
        "振幅": 1.95,
        "换手率": 0.01,
        "symbol": "106.BBU"
    },
    {
        "序号": 9794,
        "name": "Synchrony Financial Series A Pf",
        "最新价": 16.76,
        "涨跌额": -0.13,
        "涨跌幅": -0.77,
        "开盘价": 16.7,
        "最高价": 16.83,
        "最低价": 16.51,
        "昨收价": 16.89,
        "总市值": 12570000.0,
        "市盈率": null,
        "成交量": 121382.0,
        "成交额": 2025165.0,
        "振幅": 1.89,
        "换手率": 16.18,
        "symbol": "106.SYF_A"
    },
    {
        "序号": 9795,
        "name": "Invesco Value Municipal Income ",
        "最新价": 11.59,
        "涨跌额": -0.09,
        "涨跌幅": -0.77,
        "开盘价": 11.62,
        "最高价": 11.68,
        "最低价": 11.57,
        "昨收价": 11.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 150449.0,
        "成交额": 1747760.0,
        "振幅": 0.94,
        "换手率": null,
        "symbol": "106.IIM"
    },
    {
        "序号": 9796,
        "name": "LXP Industrial Trust Series C P",
        "最新价": 46.3,
        "涨跌额": -0.36,
        "涨跌幅": -0.77,
        "开盘价": 46.05,
        "最高价": 46.45,
        "最低价": 46.04,
        "昨收价": 46.66,
        "总市值": 89609020.0,
        "市盈率": null,
        "成交量": 3468.0,
        "成交额": 160113.0,
        "振幅": 0.88,
        "换手率": 0.18,
        "symbol": "106.LXP_C"
    },
    {
        "序号": 9797,
        "name": "奥麦罗制药",
        "最新价": 2.57,
        "涨跌额": -0.02,
        "涨跌幅": -0.77,
        "开盘价": 2.56,
        "最高价": 2.64,
        "最低价": 2.5,
        "昨收价": 2.59,
        "总市值": 161564312.0,
        "市盈率": 8.08,
        "成交量": 353370.0,
        "成交额": 908370.0,
        "振幅": 5.41,
        "换手率": 0.56,
        "symbol": "105.OMER"
    },
    {
        "序号": 9798,
        "name": "Keurig Dr Pepper Inc",
        "最新价": 32.11,
        "涨跌额": -0.25,
        "涨跌幅": -0.77,
        "开盘价": 32.33,
        "最高价": 32.33,
        "最低价": 32.08,
        "昨收价": 32.36,
        "总市值": 44900572267.0,
        "市盈率": 23.13,
        "成交量": 8254180.0,
        "成交额": 265469195.0,
        "振幅": 0.77,
        "换手率": 0.59,
        "symbol": "105.KDP"
    },
    {
        "序号": 9799,
        "name": "CF Bankshares Inc",
        "最新价": 16.67,
        "涨跌额": -0.13,
        "涨跌幅": -0.77,
        "开盘价": 16.8,
        "最高价": 17.27,
        "最低价": 16.51,
        "昨收价": 16.8,
        "总市值": 109272900.0,
        "市盈率": 6.29,
        "成交量": 3079.0,
        "成交额": 51680.0,
        "振幅": 4.52,
        "换手率": 0.05,
        "symbol": "105.CFBK"
    },
    {
        "序号": 9800,
        "name": "Wells Fargo & Co Series CC Pfd",
        "最新价": 17.94,
        "涨跌额": -0.14,
        "涨跌幅": -0.77,
        "开盘价": 18.07,
        "最高价": 18.08,
        "最低价": 17.88,
        "昨收价": 18.08,
        "总市值": 753480.0,
        "市盈率": null,
        "成交量": 82959.0,
        "成交额": 1490984.0,
        "振幅": 1.11,
        "换手率": 197.52,
        "symbol": "106.WFC_C"
    },
    {
        "序号": 9801,
        "name": "BondBloxx Bloomberg Ten Year Ta",
        "最新价": 46.13,
        "涨跌额": -0.36,
        "涨跌幅": -0.77,
        "开盘价": 46.16,
        "最高价": 46.21,
        "最低价": 46.01,
        "昨收价": 46.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 1630.0,
        "成交额": 75191.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.XTEN"
    },
    {
        "序号": 9802,
        "name": "Thunder Bridge Capital Partners",
        "最新价": 10.24,
        "涨跌额": -0.08,
        "涨跌幅": -0.78,
        "开盘价": 10.25,
        "最高价": 10.25,
        "最低价": 10.24,
        "昨收价": 10.32,
        "总市值": 122512968.0,
        "市盈率": 97.19,
        "成交量": 500.0,
        "成交额": 5122.0,
        "振幅": 0.1,
        "换手率": null,
        "symbol": "105.TBCPU"
    },
    {
        "序号": 9803,
        "name": "维视图像",
        "最新价": 2.56,
        "涨跌额": -0.02,
        "涨跌幅": -0.78,
        "开盘价": 2.57,
        "最高价": 2.64,
        "最低价": 2.51,
        "昨收价": 2.58,
        "总市值": 486363502.0,
        "市盈率": -6.35,
        "成交量": 1697982.0,
        "成交额": 4342948.0,
        "振幅": 5.04,
        "换手率": 0.89,
        "symbol": "105.MVIS"
    },
    {
        "序号": 9804,
        "name": "Zillow Group Inc-A",
        "最新价": 44.66,
        "涨跌额": -0.35,
        "涨跌幅": -0.78,
        "开盘价": 44.13,
        "最高价": 45.25,
        "最低价": 44.03,
        "昨收价": 45.01,
        "总市值": 10432717036.0,
        "市盈率": -66.45,
        "成交量": 414944.0,
        "成交额": 18539988.0,
        "振幅": 2.71,
        "换手率": 0.67,
        "symbol": "105.ZG"
    },
    {
        "序号": 9805,
        "name": "CSG系统国际",
        "最新价": 52.22,
        "涨跌额": -0.41,
        "涨跌幅": -0.78,
        "开盘价": 52.3,
        "最高价": 52.56,
        "最低价": 51.25,
        "昨收价": 52.63,
        "总市值": 1549425103.0,
        "市盈率": 21.02,
        "成交量": 395052.0,
        "成交额": 20575365.0,
        "振幅": 2.49,
        "换手率": 1.33,
        "symbol": "105.CSGS"
    },
    {
        "序号": 9806,
        "name": "Kolibri Global Energy Inc",
        "最新价": 3.82,
        "涨跌额": -0.03,
        "涨跌幅": -0.78,
        "开盘价": 3.95,
        "最高价": 4.0,
        "最低价": 3.78,
        "昨收价": 3.85,
        "总市值": 136089742.0,
        "市盈率": null,
        "成交量": 18235.0,
        "成交额": 70767.0,
        "振幅": 5.71,
        "换手率": 0.05,
        "symbol": "105.KGEI"
    },
    {
        "序号": 9807,
        "name": "Rivian Automotive Inc-A",
        "最新价": 19.04,
        "涨跌额": -0.15,
        "涨跌幅": -0.78,
        "开盘价": 19.04,
        "最高价": 19.55,
        "最低价": 18.62,
        "昨收价": 19.19,
        "总市值": 18234942952.0,
        "市盈率": -3.24,
        "成交量": 34974804.0,
        "成交额": 665177872.0,
        "振幅": 4.85,
        "换手率": 3.65,
        "symbol": "105.RIVN"
    },
    {
        "序号": 9808,
        "name": "FAT Brands Inc Series B Pfd",
        "最新价": 15.18,
        "涨跌额": -0.12,
        "涨跌幅": -0.78,
        "开盘价": 15.3,
        "最高价": 15.3,
        "最低价": 14.98,
        "昨收价": 15.3,
        "总市值": 52526625.0,
        "市盈率": null,
        "成交量": 7639.0,
        "成交额": 115393.0,
        "振幅": 2.09,
        "换手率": 0.22,
        "symbol": "105.FATBP"
    },
    {
        "序号": 9809,
        "name": "Principal Healthcare Innovators",
        "最新价": 31.53,
        "涨跌额": -0.25,
        "涨跌幅": -0.79,
        "开盘价": 31.57,
        "最高价": 31.58,
        "最低价": 31.53,
        "昨收价": 31.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1935.0,
        "成交额": 61089.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "105.BTEC"
    },
    {
        "序号": 9810,
        "name": "The GDL Fund Series C Pfd",
        "最新价": 49.15,
        "涨跌额": -0.39,
        "涨跌幅": -0.79,
        "开盘价": 49.48,
        "最高价": 49.48,
        "最低价": 49.15,
        "昨收价": 49.54,
        "总市值": 33861008.0,
        "市盈率": null,
        "成交量": 1389.0,
        "成交额": 68593.0,
        "振幅": 0.67,
        "换手率": 0.2,
        "symbol": "106.GDL_C"
    },
    {
        "序号": 9811,
        "name": "Full House Resorts Inc",
        "最新价": 5.04,
        "涨跌额": -0.04,
        "涨跌幅": -0.79,
        "开盘价": 5.06,
        "最高价": 5.15,
        "最低价": 4.92,
        "昨收价": 5.08,
        "总市值": 174320244.0,
        "市盈率": -8.98,
        "成交量": 99036.0,
        "成交额": 495020.0,
        "振幅": 4.53,
        "换手率": 0.29,
        "symbol": "105.FLL"
    },
    {
        "序号": 9812,
        "name": "BNY Mellon Municipal Bond Infra",
        "最新价": 10.08,
        "涨跌额": -0.08,
        "涨跌幅": -0.79,
        "开盘价": 10.03,
        "最高价": 10.13,
        "最低价": 10.03,
        "昨收价": 10.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 79170.0,
        "成交额": 797532.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "106.DMB"
    },
    {
        "序号": 9813,
        "name": "戴斯控股",
        "最新价": 2.52,
        "涨跌额": -0.02,
        "涨跌幅": -0.79,
        "开盘价": 2.5,
        "最高价": 2.53,
        "最低价": 2.5,
        "昨收价": 2.54,
        "总市值": 117981035.0,
        "市盈率": 31.94,
        "成交量": 42864.0,
        "成交额": 107722.0,
        "振幅": 1.18,
        "换手率": 0.09,
        "symbol": "106.DHX"
    },
    {
        "序号": 9814,
        "name": "Broadstone Net Lease Inc",
        "最新价": 16.38,
        "涨跌额": -0.13,
        "涨跌幅": -0.79,
        "开盘价": 16.44,
        "最高价": 16.53,
        "最低价": 16.21,
        "昨收价": 16.51,
        "总市值": 3067269300.0,
        "市盈率": 16.69,
        "成交量": 881364.0,
        "成交额": 14405656.0,
        "振幅": 1.94,
        "换手率": 0.47,
        "symbol": "106.BNL"
    },
    {
        "序号": 9815,
        "name": "Arrival",
        "最新价": 1.26,
        "涨跌额": -0.01,
        "涨跌幅": -0.79,
        "开盘价": 1.29,
        "最高价": 1.31,
        "最低价": 1.25,
        "昨收价": 1.27,
        "总市值": 23152636.0,
        "市盈率": -0.16,
        "成交量": 47675.0,
        "成交额": 60718.0,
        "振幅": 4.72,
        "换手率": 0.26,
        "symbol": "105.ARVL"
    },
    {
        "序号": 9816,
        "name": "US Treasury 30 Year Bond ETF",
        "最新价": 45.34,
        "涨跌额": -0.36,
        "涨跌幅": -0.79,
        "开盘价": 45.29,
        "最高价": 45.41,
        "最低价": 45.05,
        "昨收价": 45.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 2805.0,
        "成交额": 126885.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.UTHY"
    },
    {
        "序号": 9817,
        "name": "Western Asset Total Return ETF",
        "最新价": 20.14,
        "涨跌额": -0.16,
        "涨跌幅": -0.79,
        "开盘价": 20.14,
        "最高价": 20.21,
        "最低价": 20.1,
        "昨收价": 20.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 15901.0,
        "成交额": 320768.0,
        "振幅": 0.54,
        "换手率": null,
        "symbol": "105.WBND"
    },
    {
        "序号": 9818,
        "name": "Oxford Square Capital Corp Note",
        "最新价": 23.91,
        "涨跌额": -0.19,
        "涨跌幅": -0.79,
        "开盘价": 24.07,
        "最高价": 24.07,
        "最低价": 23.74,
        "昨收价": 24.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1225.0,
        "成交额": 29219.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "105.OXSQZ"
    },
    {
        "序号": 9819,
        "name": "二倍做空标普500ETF-ProShares",
        "最新价": 32.6,
        "涨跌额": -0.26,
        "涨跌幅": -0.79,
        "开盘价": 32.99,
        "最高价": 33.02,
        "最低价": 32.52,
        "昨收价": 32.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 6923370.0,
        "成交额": 226547734.0,
        "振幅": 1.52,
        "换手率": null,
        "symbol": "107.SDS"
    },
    {
        "序号": 9820,
        "name": "Newtek Business Services Corp N",
        "最新价": 23.8,
        "涨跌额": -0.19,
        "涨跌幅": -0.79,
        "开盘价": 24.0,
        "最高价": 24.0,
        "最低价": 23.76,
        "昨收价": 23.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 2488.0,
        "成交额": 59398.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "105.NEWTZ"
    },
    {
        "序号": 9821,
        "name": "U-Haul Holding Co",
        "最新价": 58.82,
        "涨跌额": -0.47,
        "涨跌幅": -0.79,
        "开盘价": 59.89,
        "最高价": 60.37,
        "最低价": 58.62,
        "昨收价": 59.29,
        "总市值": 11533300902.0,
        "市盈率": 15.07,
        "成交量": 78104.0,
        "成交额": 4631987.0,
        "振幅": 2.95,
        "换手率": 0.4,
        "symbol": "106.UHAL"
    },
    {
        "序号": 9822,
        "name": "First Trust China AlphaDEX Fund",
        "最新价": 17.52,
        "涨跌额": -0.14,
        "涨跌幅": -0.79,
        "开盘价": 17.55,
        "最高价": 17.6,
        "最低价": 17.45,
        "昨收价": 17.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 3156.0,
        "成交额": 55242.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "105.FCA"
    },
    {
        "序号": 9823,
        "name": "土耳其移动通信",
        "最新价": 5.0,
        "涨跌额": -0.04,
        "涨跌幅": -0.79,
        "开盘价": 4.98,
        "最高价": 5.08,
        "最低价": 4.96,
        "昨收价": 5.04,
        "总市值": 4400000000.0,
        "市盈率": 11.83,
        "成交量": 151759.0,
        "成交额": 760120.0,
        "振幅": 2.38,
        "换手率": 0.02,
        "symbol": "106.TKC"
    },
    {
        "序号": 9824,
        "name": "Merchants Bancorp Series D Pfd",
        "最新价": 25.0,
        "涨跌额": -0.2,
        "涨跌幅": -0.79,
        "开盘价": 25.0,
        "最高价": 25.0,
        "最低价": 24.86,
        "昨收价": 25.2,
        "总市值": 3562500.0,
        "市盈率": null,
        "成交量": 2183.0,
        "成交额": 54507.0,
        "振幅": 0.56,
        "换手率": 1.53,
        "symbol": "105.MBINM"
    },
    {
        "序号": 9825,
        "name": "Global X MSCI China Communicati",
        "最新价": 13.75,
        "涨跌额": -0.11,
        "涨跌幅": -0.79,
        "开盘价": 13.75,
        "最高价": 13.75,
        "最低价": 13.75,
        "昨收价": 13.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 19.0,
        "成交额": 261.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CHIC"
    },
    {
        "序号": 9826,
        "name": "California Water Service Group",
        "最新价": 52.33,
        "涨跌额": -0.42,
        "涨跌幅": -0.8,
        "开盘价": 52.82,
        "最高价": 53.43,
        "最低价": 52.08,
        "昨收价": 52.75,
        "总市值": 3020016630.0,
        "市盈率": 73.03,
        "成交量": 151174.0,
        "成交额": 7932741.0,
        "振幅": 2.56,
        "换手率": 0.26,
        "symbol": "106.CWT"
    },
    {
        "序号": 9827,
        "name": "诺信",
        "最新价": 239.16,
        "涨跌额": -1.92,
        "涨跌幅": -0.8,
        "开盘价": 241.63,
        "最高价": 242.76,
        "最低价": 239.05,
        "昨收价": 241.08,
        "总市值": 13635587103.0,
        "市盈率": 27.22,
        "成交量": 235039.0,
        "成交额": 56368502.0,
        "振幅": 1.54,
        "换手率": 0.41,
        "symbol": "105.NDSN"
    },
    {
        "序号": 9828,
        "name": "Moolec Science SA",
        "最新价": 2.49,
        "涨跌额": -0.02,
        "涨跌幅": -0.8,
        "开盘价": 2.7,
        "最高价": 2.7,
        "最低价": 2.39,
        "昨收价": 2.51,
        "总市值": 93533782.0,
        "市盈率": -155.15,
        "成交量": 4218.0,
        "成交额": 10405.0,
        "振幅": 12.35,
        "换手率": 0.01,
        "symbol": "105.MLEC"
    },
    {
        "序号": 9829,
        "name": "Public Storage Series I PFd",
        "最新价": 22.39,
        "涨跌额": -0.18,
        "涨跌幅": -0.8,
        "开盘价": 22.45,
        "最高价": 22.56,
        "最低价": 22.28,
        "昨收价": 22.57,
        "总市值": 283234.0,
        "市盈率": null,
        "成交量": 45603.0,
        "成交额": 1021331.0,
        "振幅": 1.24,
        "换手率": 360.5,
        "symbol": "106.PSA_I"
    },
    {
        "序号": 9830,
        "name": "PIMCO Municipal Income Fund III",
        "最新价": 7.46,
        "涨跌额": -0.06,
        "涨跌幅": -0.8,
        "开盘价": 7.51,
        "最高价": 7.51,
        "最低价": 7.44,
        "昨收价": 7.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 79522.0,
        "成交额": 593797.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "106.PMX"
    },
    {
        "序号": 9831,
        "name": "Nuveen Select Maturities Munici",
        "最新价": 8.7,
        "涨跌额": -0.07,
        "涨跌幅": -0.8,
        "开盘价": 8.8,
        "最高价": 8.8,
        "最低价": 8.66,
        "昨收价": 8.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 84548.0,
        "成交额": 738549.0,
        "振幅": 1.6,
        "换手率": null,
        "symbol": "106.NIM"
    },
    {
        "序号": 9832,
        "name": "超科林半导体",
        "最新价": 28.55,
        "涨跌额": -0.23,
        "涨跌幅": -0.8,
        "开盘价": 28.41,
        "最高价": 29.03,
        "最低价": 27.61,
        "昨收价": 28.78,
        "总市值": 1279918740.0,
        "市盈率": 2133.2,
        "成交量": 183585.0,
        "成交额": 5234443.0,
        "振幅": 4.93,
        "换手率": 0.41,
        "symbol": "105.UCTT"
    },
    {
        "序号": 9833,
        "name": "OptiNose Inc",
        "最新价": 1.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.8,
        "开盘价": 1.23,
        "最高价": 1.25,
        "最低价": 1.19,
        "昨收价": 1.25,
        "总市值": 139266860.0,
        "市盈率": -3.42,
        "成交量": 322133.0,
        "成交额": 392806.0,
        "振幅": 4.8,
        "换手率": 0.29,
        "symbol": "105.OPTN"
    },
    {
        "序号": 9834,
        "name": "The Honest Co Inc",
        "最新价": 2.48,
        "涨跌额": -0.02,
        "涨跌幅": -0.8,
        "开盘价": 2.52,
        "最高价": 2.52,
        "最低价": 2.4,
        "昨收价": 2.5,
        "总市值": 236763919.0,
        "市盈率": -4.47,
        "成交量": 570170.0,
        "成交额": 1409819.0,
        "振幅": 4.8,
        "换手率": 0.6,
        "symbol": "105.HNST"
    },
    {
        "序号": 9835,
        "name": "Japan Smaller Capitalization Fu",
        "最新价": 7.43,
        "涨跌额": -0.06,
        "涨跌幅": -0.8,
        "开盘价": 7.46,
        "最高价": 7.47,
        "最低价": 7.43,
        "昨收价": 7.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 58675.0,
        "成交额": 436721.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "106.JOF"
    },
    {
        "序号": 9836,
        "name": "First Trust Emerging Markets Sm",
        "最新价": 37.12,
        "涨跌额": -0.3,
        "涨跌幅": -0.8,
        "开盘价": 37.12,
        "最高价": 37.31,
        "最低价": 37.01,
        "昨收价": 37.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 23554.0,
        "成交额": 874516.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "105.FEMS"
    },
    {
        "序号": 9837,
        "name": "爱点击",
        "最新价": 3.7,
        "涨跌额": -0.03,
        "涨跌幅": -0.8,
        "开盘价": 3.71,
        "最高价": 3.73,
        "最低价": 3.7,
        "昨收价": 3.73,
        "总市值": 36638719.0,
        "市盈率": -0.18,
        "成交量": 14125.0,
        "成交额": 52435.0,
        "振幅": 0.8,
        "换手率": 0.14,
        "symbol": "105.ICLK"
    },
    {
        "序号": 9838,
        "name": "Quoin Pharmaceuticals Ltd ADR",
        "最新价": 4.93,
        "涨跌额": -0.04,
        "涨跌幅": -0.8,
        "开盘价": 5.03,
        "最高价": 5.11,
        "最低价": 4.87,
        "昨收价": 4.97,
        "总市值": 4866980.0,
        "市盈率": -0.56,
        "成交量": 16354.0,
        "成交额": 80864.0,
        "振幅": 4.83,
        "换手率": 1.66,
        "symbol": "105.QNRX"
    },
    {
        "序号": 9839,
        "name": "Gladstone Land Corp Series D Pf",
        "最新价": 23.37,
        "涨跌额": -0.19,
        "涨跌幅": -0.81,
        "开盘价": 23.5,
        "最高价": 23.62,
        "最低价": 23.32,
        "昨收价": 23.56,
        "总市值": 56438550.0,
        "市盈率": null,
        "成交量": 18255.0,
        "成交额": 428248.0,
        "振幅": 1.27,
        "换手率": 0.76,
        "symbol": "105.LANDM"
    },
    {
        "序号": 9840,
        "name": "安进",
        "最新价": 269.12,
        "涨跌额": -2.19,
        "涨跌幅": -0.81,
        "开盘价": 271.17,
        "最高价": 271.97,
        "最低价": 268.82,
        "昨收价": 271.31,
        "总市值": 144027110626.0,
        "市盈率": 19.04,
        "成交量": 2608218.0,
        "成交额": 702851328.0,
        "振幅": 1.16,
        "换手率": 0.49,
        "symbol": "105.AMGN"
    },
    {
        "序号": 9841,
        "name": "Bloomin’ Brands Inc",
        "最新价": 24.56,
        "涨跌额": -0.2,
        "涨跌幅": -0.81,
        "开盘价": 24.74,
        "最高价": 24.99,
        "最低价": 24.49,
        "昨收价": 24.76,
        "总市值": 2132152896.0,
        "市盈率": 8.13,
        "成交量": 659417.0,
        "成交额": 16238877.0,
        "振幅": 2.02,
        "换手率": 0.76,
        "symbol": "105.BLMN"
    },
    {
        "序号": 9842,
        "name": "Vanguard Long-Term Treasury ETF",
        "最新价": 58.84,
        "涨跌额": -0.48,
        "涨跌幅": -0.81,
        "开盘价": 58.81,
        "最高价": 58.96,
        "最低价": 58.49,
        "昨收价": 59.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 4791459.0,
        "成交额": 281722512.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "105.VGLT"
    },
    {
        "序号": 9843,
        "name": "Expensify Inc-A",
        "最新价": 2.45,
        "涨跌额": -0.02,
        "涨跌幅": -0.81,
        "开盘价": 2.47,
        "最高价": 2.58,
        "最低价": 2.4,
        "昨收价": 2.47,
        "总市值": 205152509.0,
        "市盈率": -5.45,
        "成交量": 464284.0,
        "成交额": 1136490.0,
        "振幅": 7.29,
        "换手率": 0.55,
        "symbol": "105.EXFY"
    },
    {
        "序号": 9844,
        "name": "Cambridge Bancorp",
        "最新价": 63.6,
        "涨跌额": -0.52,
        "涨跌幅": -0.81,
        "开盘价": 63.83,
        "最高价": 64.62,
        "最低价": 63.04,
        "昨收价": 64.12,
        "总市值": 499030468.0,
        "市盈率": 13.35,
        "成交量": 28715.0,
        "成交额": 1823490.0,
        "振幅": 2.46,
        "换手率": 0.37,
        "symbol": "105.CATC"
    },
    {
        "序号": 9845,
        "name": "Voya Global Equity Dividend and",
        "最新价": 4.89,
        "涨跌额": -0.04,
        "涨跌幅": -0.81,
        "开盘价": 4.93,
        "最高价": 4.93,
        "最低价": 4.88,
        "昨收价": 4.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 148169.0,
        "成交额": 726964.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "106.IGD"
    },
    {
        "序号": 9846,
        "name": "DNP Select Income Fund",
        "最新价": 8.55,
        "涨跌额": -0.07,
        "涨跌幅": -0.81,
        "开盘价": 8.56,
        "最高价": 8.66,
        "最低价": 8.51,
        "昨收价": 8.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 1639110.0,
        "成交额": 14049370.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "106.DNP"
    },
    {
        "序号": 9847,
        "name": "沃纳企业",
        "最新价": 40.27,
        "涨跌额": -0.33,
        "涨跌幅": -0.81,
        "开盘价": 40.54,
        "最高价": 40.88,
        "最低价": 40.08,
        "昨收价": 40.6,
        "总市值": 2552914435.0,
        "市盈率": 17.14,
        "成交量": 377030.0,
        "成交额": 15215393.0,
        "振幅": 1.97,
        "换手率": 0.59,
        "symbol": "105.WERN"
    },
    {
        "序号": 9848,
        "name": "Beamr Imaging Ltd",
        "最新价": 1.22,
        "涨跌额": -0.01,
        "涨跌幅": -0.81,
        "开盘价": 1.22,
        "最高价": 1.28,
        "最低价": 1.22,
        "昨收价": 1.23,
        "总市值": 15717133.0,
        "市盈率": -11.76,
        "成交量": 11049.0,
        "成交额": 13643.0,
        "振幅": 4.88,
        "换手率": 0.09,
        "symbol": "105.BMR"
    },
    {
        "序号": 9849,
        "name": "iShares MSCI Japan Value ETF",
        "最新价": 29.27,
        "涨跌额": -0.24,
        "涨跌幅": -0.81,
        "开盘价": 29.17,
        "最高价": 29.29,
        "最低价": 29.12,
        "昨收价": 29.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 46889.0,
        "成交额": 1371057.0,
        "振幅": 0.58,
        "换手率": null,
        "symbol": "105.EWJV"
    },
    {
        "序号": 9850,
        "name": "MercadoLibre Inc",
        "最新价": 1577.98,
        "涨跌额": -12.96,
        "涨跌幅": -0.81,
        "开盘价": 1577.69,
        "最高价": 1594.01,
        "最低价": 1573.0,
        "昨收价": 1590.94,
        "总市值": 79781938195.0,
        "市盈率": 80.83,
        "成交量": 336618.0,
        "成交额": 532780752.0,
        "振幅": 1.32,
        "换手率": 0.67,
        "symbol": "105.MELI"
    },
    {
        "序号": 9851,
        "name": "Overseas Shipholding Group Inc-",
        "最新价": 4.87,
        "涨跌额": -0.04,
        "涨跌幅": -0.81,
        "开盘价": 4.89,
        "最高价": 4.97,
        "最低价": 4.83,
        "昨收价": 4.91,
        "总市值": 352449088.0,
        "市盈率": 6.76,
        "成交量": 348441.0,
        "成交额": 1695944.0,
        "振幅": 2.85,
        "换手率": 0.48,
        "symbol": "106.OSG"
    },
    {
        "序号": 9852,
        "name": "纽约社区银行",
        "最新价": 9.74,
        "涨跌额": -0.08,
        "涨跌幅": -0.81,
        "开盘价": 9.79,
        "最高价": 9.91,
        "最低价": 9.63,
        "昨收价": 9.82,
        "总市值": 7037034221.0,
        "市盈率": 2.52,
        "成交量": 11111301.0,
        "成交额": 108050247.0,
        "振幅": 2.85,
        "换手率": 1.54,
        "symbol": "106.NYCB"
    },
    {
        "序号": 9853,
        "name": "Western Asset Inflation-Linked ",
        "最新价": 8.52,
        "涨跌额": -0.07,
        "涨跌幅": -0.81,
        "开盘价": 8.54,
        "最高价": 8.57,
        "最低价": 8.47,
        "昨收价": 8.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 174938.0,
        "成交额": 1487718.0,
        "振幅": 1.16,
        "换手率": null,
        "symbol": "106.WIW"
    },
    {
        "序号": 9854,
        "name": "abrdn Global Premier Properties",
        "最新价": 3.65,
        "涨跌额": -0.03,
        "涨跌幅": -0.82,
        "开盘价": 3.66,
        "最高价": 3.69,
        "最低价": 3.65,
        "昨收价": 3.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 336184.0,
        "成交额": 1229486.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.AWP"
    },
    {
        "序号": 9855,
        "name": "ClearBridge MLP and Midstream F",
        "最新价": 37.68,
        "涨跌额": -0.31,
        "涨跌幅": -0.82,
        "开盘价": 37.93,
        "最高价": 37.93,
        "最低价": 37.54,
        "昨收价": 37.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 16870.0,
        "成交额": 636056.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "106.CEM"
    },
    {
        "序号": 9856,
        "name": "PIMCO New York Municipal Income",
        "最新价": 7.29,
        "涨跌额": -0.06,
        "涨跌幅": -0.82,
        "开盘价": 7.34,
        "最高价": 7.34,
        "最低价": 7.28,
        "昨收价": 7.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 34246.0,
        "成交额": 250057.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.PNI"
    },
    {
        "序号": 9857,
        "name": "PGIM High Yield Bond Fund",
        "最新价": 12.14,
        "涨跌额": -0.1,
        "涨跌幅": -0.82,
        "开盘价": 12.21,
        "最高价": 12.21,
        "最低价": 12.14,
        "昨收价": 12.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 111763.0,
        "成交额": 1360791.0,
        "振幅": 0.57,
        "换手率": null,
        "symbol": "106.ISD"
    },
    {
        "序号": 9858,
        "name": "Cactus Acquisition Corp 1 Ltd-A",
        "最新价": 10.92,
        "涨跌额": -0.09,
        "涨跌幅": -0.82,
        "开盘价": 11.0,
        "最高价": 11.3,
        "最低价": 10.92,
        "昨收价": 11.01,
        "总市值": 55417591.0,
        "市盈率": 25.03,
        "成交量": 3250.0,
        "成交额": 35764.0,
        "振幅": 3.45,
        "换手率": 0.06,
        "symbol": "105.CCTS"
    },
    {
        "序号": 9859,
        "name": "20年+国债ETF-iShares",
        "最新价": 94.54,
        "涨跌额": -0.78,
        "涨跌幅": -0.82,
        "开盘价": 94.42,
        "最高价": 94.72,
        "最低价": 93.91,
        "昨收价": 95.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 44809246.0,
        "成交额": 4228639440.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "105.TLT"
    },
    {
        "序号": 9860,
        "name": "Nuveen Municipal Value Fund",
        "最新价": 8.47,
        "涨跌额": -0.07,
        "涨跌幅": -0.82,
        "开盘价": 8.48,
        "最高价": 8.51,
        "最低价": 8.45,
        "昨收价": 8.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 774760.0,
        "成交额": 6575402.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.NUV"
    },
    {
        "序号": 9861,
        "name": "RiverNorth Flexible Municipal I",
        "最新价": 13.3,
        "涨跌额": -0.11,
        "涨跌幅": -0.82,
        "开盘价": 13.46,
        "最高价": 13.48,
        "最低价": 13.3,
        "昨收价": 13.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 74122.0,
        "成交额": 992426.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "106.RFMZ"
    },
    {
        "序号": 9862,
        "name": "波士顿地产",
        "最新价": 64.08,
        "涨跌额": -0.53,
        "涨跌幅": -0.82,
        "开盘价": 64.14,
        "最高价": 64.47,
        "最低价": 63.05,
        "昨收价": 64.61,
        "总市值": 10056726734.0,
        "市盈率": 52.35,
        "成交量": 3591376.0,
        "成交额": 229694210.0,
        "振幅": 2.2,
        "换手率": 2.29,
        "symbol": "106.BXP"
    },
    {
        "序号": 9863,
        "name": "奥莱利",
        "最新价": 945.88,
        "涨跌额": -7.83,
        "涨跌幅": -0.82,
        "开盘价": 952.99,
        "最高价": 957.98,
        "最低价": 941.93,
        "昨收价": 953.71,
        "总市值": 55960318089.0,
        "市盈率": 24.09,
        "成交量": 422768.0,
        "成交额": 399955808.0,
        "振幅": 1.68,
        "换手率": 0.71,
        "symbol": "105.ORLY"
    },
    {
        "序号": 9864,
        "name": "SPDR Portfolio Long Term Treasu",
        "最新价": 27.74,
        "涨跌额": -0.23,
        "涨跌幅": -0.82,
        "开盘价": 27.72,
        "最高价": 27.79,
        "最低价": 27.58,
        "昨收价": 27.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 4293893.0,
        "成交额": 118899694.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.SPTL"
    },
    {
        "序号": 9865,
        "name": "土耳其ETF-iShares MSCI",
        "最新价": 34.92,
        "涨跌额": -0.29,
        "涨跌幅": -0.82,
        "开盘价": 35.07,
        "最高价": 35.07,
        "最低价": 34.83,
        "昨收价": 35.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 33075.0,
        "成交额": 1155059.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "105.TUR"
    },
    {
        "序号": 9866,
        "name": "VanEck Biotech ETF",
        "最新价": 153.96,
        "涨跌额": -1.28,
        "涨跌幅": -0.82,
        "开盘价": 154.61,
        "最高价": 155.17,
        "最低价": 153.91,
        "昨收价": 155.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 9761.0,
        "成交额": 1508342.0,
        "振幅": 0.81,
        "换手率": null,
        "symbol": "105.BBH"
    },
    {
        "序号": 9867,
        "name": "Brighthouse Financial Inc Serie",
        "最新价": 21.65,
        "涨跌额": -0.18,
        "涨跌幅": -0.82,
        "开盘价": 21.78,
        "最高价": 21.9,
        "最低价": 21.4,
        "昨收价": 21.83,
        "总市值": 348565.0,
        "市盈率": null,
        "成交量": 45428.0,
        "成交额": 981075.0,
        "振幅": 2.29,
        "换手率": 282.16,
        "symbol": "105.BHFAO"
    },
    {
        "序号": 9868,
        "name": "文斯控股",
        "最新价": 1.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.83,
        "开盘价": 1.25,
        "最高价": 1.33,
        "最低价": 1.2,
        "昨收价": 1.21,
        "总市值": 15003296.0,
        "市盈率": 0.78,
        "成交量": 13114.0,
        "成交额": 16379.0,
        "振幅": 10.74,
        "换手率": 0.1,
        "symbol": "106.VNCE"
    },
    {
        "序号": 9869,
        "name": "aTyr Pharma Inc",
        "最新价": 1.2,
        "涨跌额": -0.01,
        "涨跌幅": -0.83,
        "开盘价": 1.22,
        "最高价": 1.24,
        "最低价": 1.15,
        "昨收价": 1.21,
        "总市值": 70272073.0,
        "市盈率": -1.63,
        "成交量": 117304.0,
        "成交额": 140988.0,
        "振幅": 7.44,
        "换手率": 0.2,
        "symbol": "105.LIFE"
    },
    {
        "序号": 9870,
        "name": "Liberty Broadband Corp-C",
        "最新价": 75.6,
        "涨跌额": -0.63,
        "涨跌幅": -0.83,
        "开盘价": 75.81,
        "最高价": 76.41,
        "最低价": 75.15,
        "昨收价": 76.23,
        "总市值": 11042598748.0,
        "市盈率": 16.71,
        "成交量": 1107544.0,
        "成交额": 83694953.0,
        "振幅": 1.65,
        "换手率": 0.88,
        "symbol": "105.LBRDK"
    },
    {
        "序号": 9871,
        "name": "Eaton Vance Municipal Income Tr",
        "最新价": 9.6,
        "涨跌额": -0.08,
        "涨跌幅": -0.83,
        "开盘价": 9.67,
        "最高价": 9.73,
        "最低价": 9.59,
        "昨收价": 9.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 150167.0,
        "成交额": 1446596.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "106.EVN"
    },
    {
        "序号": 9872,
        "name": "Eton Pharmaceuticals Inc",
        "最新价": 3.6,
        "涨跌额": -0.03,
        "涨跌幅": -0.83,
        "开盘价": 3.62,
        "最高价": 3.69,
        "最低价": 3.57,
        "昨收价": 3.63,
        "总市值": 92370226.0,
        "市盈率": 41.38,
        "成交量": 11526.0,
        "成交额": 41799.0,
        "振幅": 3.31,
        "换手率": 0.04,
        "symbol": "105.ETON"
    },
    {
        "序号": 9873,
        "name": "Prudential Financial Inc Notes",
        "最新价": 19.18,
        "涨跌额": -0.16,
        "涨跌幅": -0.83,
        "开盘价": 19.3,
        "最高价": 19.4,
        "最低价": 19.18,
        "昨收价": 19.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 20167.0,
        "成交额": 388632.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "106.PFH"
    },
    {
        "序号": 9874,
        "name": "Stifel Financial Corp Series C ",
        "最新价": 23.97,
        "涨跌额": -0.2,
        "涨跌幅": -0.83,
        "开盘价": 24.17,
        "最高价": 24.55,
        "最低价": 23.79,
        "昨收价": 24.17,
        "总市值": 215730.0,
        "市盈率": null,
        "成交量": 15782.0,
        "成交额": 378838.0,
        "振幅": 3.14,
        "换手率": 175.36,
        "symbol": "106.SF_C"
    },
    {
        "序号": 9875,
        "name": "KraneShares Emerging Markets Co",
        "最新价": 14.35,
        "涨跌额": -0.12,
        "涨跌幅": -0.83,
        "开盘价": 14.36,
        "最高价": 14.4,
        "最低价": 14.28,
        "昨收价": 14.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 10398.0,
        "成交额": 149257.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.KEMQ"
    },
    {
        "序号": 9876,
        "name": "Apollo Senior Floating Rate Fun",
        "最新价": 13.12,
        "涨跌额": -0.11,
        "涨跌幅": -0.83,
        "开盘价": 13.25,
        "最高价": 13.31,
        "最低价": 13.08,
        "昨收价": 13.23,
        "总市值": null,
        "市盈率": null,
        "成交量": 201938.0,
        "成交额": 2660014.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "106.AFT"
    },
    {
        "序号": 9877,
        "name": "PB Bankshares Inc",
        "最新价": 11.92,
        "涨跌额": -0.1,
        "涨跌幅": -0.83,
        "开盘价": 12.13,
        "最高价": 12.14,
        "最低价": 11.92,
        "昨收价": 12.02,
        "总市值": 32362407.0,
        "市盈率": 12.36,
        "成交量": 7733.0,
        "成交额": 93373.0,
        "振幅": 1.83,
        "换手率": 0.28,
        "symbol": "105.PBBK"
    },
    {
        "序号": 9878,
        "name": "巴西电力ADR",
        "最新价": 8.34,
        "涨跌额": -0.07,
        "涨跌幅": -0.83,
        "开盘价": 8.39,
        "最高价": 8.48,
        "最低价": 8.28,
        "昨收价": 8.41,
        "总市值": 16906501214.0,
        "市盈率": 25.47,
        "成交量": 1258526.0,
        "成交额": 10509296.0,
        "振幅": 2.38,
        "换手率": 0.06,
        "symbol": "106.EBR"
    },
    {
        "序号": 9879,
        "name": "Seven Hills Realty Trust",
        "最新价": 11.9,
        "涨跌额": -0.1,
        "涨跌幅": -0.83,
        "开盘价": 12.0,
        "最高价": 12.04,
        "最低价": 11.84,
        "昨收价": 12.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 43101.0,
        "成交额": 516228.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "105.SEVN"
    },
    {
        "序号": 9880,
        "name": "ProShares UltraShort MidCap400",
        "最新价": 14.28,
        "涨跌额": -0.12,
        "涨跌幅": -0.83,
        "开盘价": 14.27,
        "最高价": 14.28,
        "最低价": 14.27,
        "昨收价": 14.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 891.0,
        "成交额": 12714.0,
        "振幅": 0.07,
        "换手率": null,
        "symbol": "107.MZZ"
    },
    {
        "序号": 9881,
        "name": "Babcock & Wilcox Enterprises In",
        "最新价": 17.76,
        "涨跌额": -0.15,
        "涨跌幅": -0.84,
        "开盘价": 18.1,
        "最高价": 18.1,
        "最低价": 17.5,
        "昨收价": 17.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 8688.0,
        "成交额": 154097.0,
        "振幅": 3.35,
        "换手率": null,
        "symbol": "106.BWNB"
    },
    {
        "序号": 9882,
        "name": "Xtrackers MSCI All China Equity",
        "最新价": 24.86,
        "涨跌额": -0.21,
        "涨跌幅": -0.84,
        "开盘价": 24.86,
        "最高价": 24.86,
        "最低价": 24.86,
        "昨收价": 25.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 53.0,
        "成交额": 1317.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CN"
    },
    {
        "序号": 9883,
        "name": "First Horizon Corp Series D Pfd",
        "最新价": 23.65,
        "涨跌额": -0.2,
        "涨跌幅": -0.84,
        "开盘价": 23.88,
        "最高价": 23.88,
        "最低价": 23.63,
        "昨收价": 23.85,
        "总市值": 236500.0,
        "市盈率": null,
        "成交量": 8393.0,
        "成交额": 199121.0,
        "振幅": 1.05,
        "换手率": 83.93,
        "symbol": "106.FHN_D"
    },
    {
        "序号": 9884,
        "name": "中国民营企业ETF-WisdomTree",
        "最新价": 27.13,
        "涨跌额": -0.23,
        "涨跌幅": -0.84,
        "开盘价": 27.14,
        "最高价": 27.22,
        "最低价": 27.09,
        "昨收价": 27.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 43730.0,
        "成交额": 1185725.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "105.CXSE"
    },
    {
        "序号": 9885,
        "name": "US Treasury 20 Year Bond ETF",
        "最新价": 46.0,
        "涨跌额": -0.39,
        "涨跌幅": -0.84,
        "开盘价": 46.04,
        "最高价": 46.06,
        "最低价": 45.8,
        "昨收价": 46.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 754819.0,
        "成交额": 34670975.0,
        "振幅": 0.56,
        "换手率": null,
        "symbol": "105.UTWY"
    },
    {
        "序号": 9886,
        "name": "日本小型红利股ETF-WisdomTree",
        "最新价": 71.93,
        "涨跌额": -0.61,
        "涨跌幅": -0.84,
        "开盘价": 71.69,
        "最高价": 72.0,
        "最低价": 71.62,
        "昨收价": 72.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 13235.0,
        "成交额": 950508.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.DFJ"
    },
    {
        "序号": 9887,
        "name": "Jacobs Solutions Inc",
        "最新价": 127.29,
        "涨跌额": -1.08,
        "涨跌幅": -0.84,
        "开盘价": 128.96,
        "最高价": 128.99,
        "最低价": 127.21,
        "昨收价": 128.37,
        "总市值": 16041650077.0,
        "市盈率": 24.09,
        "成交量": 789131.0,
        "成交额": 100684398.0,
        "振幅": 1.39,
        "换手率": 0.63,
        "symbol": "106.J"
    },
    {
        "序号": 9888,
        "name": "Scully Royalty Ltd",
        "最新价": 5.89,
        "涨跌额": -0.05,
        "涨跌幅": -0.84,
        "开盘价": 5.76,
        "最高价": 5.92,
        "最低价": 5.36,
        "昨收价": 5.94,
        "总市值": 87303058.0,
        "市盈率": -5.06,
        "成交量": 5485.0,
        "成交额": 31094.0,
        "振幅": 9.43,
        "换手率": 0.04,
        "symbol": "106.SRL"
    },
    {
        "序号": 9889,
        "name": "MetLife Inc Series E Pfd",
        "最新价": 23.55,
        "涨跌额": -0.2,
        "涨跌幅": -0.84,
        "开盘价": 23.66,
        "最高价": 23.77,
        "最低价": 23.53,
        "昨收价": 23.75,
        "总市值": 758310.0,
        "市盈率": null,
        "成交量": 34176.0,
        "成交额": 806375.0,
        "振幅": 1.01,
        "换手率": 106.14,
        "symbol": "106.MET_E"
    },
    {
        "序号": 9890,
        "name": "SiteOne Landscape Supply Inc",
        "最新价": 145.73,
        "涨跌额": -1.24,
        "涨跌幅": -0.84,
        "开盘价": 145.31,
        "最高价": 146.68,
        "最低价": 142.41,
        "昨收价": 146.97,
        "总市值": 6576126582.0,
        "市盈率": 37.39,
        "成交量": 418223.0,
        "成交额": 60709663.0,
        "振幅": 2.91,
        "换手率": 0.93,
        "symbol": "106.SITE"
    },
    {
        "序号": 9891,
        "name": "New Found Gold Corp",
        "最新价": 3.52,
        "涨跌额": -0.03,
        "涨跌幅": -0.85,
        "开盘价": 3.49,
        "最高价": 3.52,
        "最低价": 3.43,
        "昨收价": 3.55,
        "总市值": 657793002.0,
        "市盈率": -10.48,
        "成交量": 218628.0,
        "成交额": 760668.0,
        "振幅": 2.54,
        "换手率": 0.12,
        "symbol": "107.NFGC"
    },
    {
        "序号": 9892,
        "name": "Matthews China Active ETF",
        "最新价": 18.71,
        "涨跌额": -0.16,
        "涨跌幅": -0.85,
        "开盘价": 18.76,
        "最高价": 18.76,
        "最低价": 18.67,
        "昨收价": 18.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 2065.0,
        "成交额": 38669.0,
        "振幅": 0.48,
        "换手率": null,
        "symbol": "107.MCH"
    },
    {
        "序号": 9893,
        "name": "ProShares Short SmallCap600",
        "最新价": 17.53,
        "涨跌额": -0.15,
        "涨跌幅": -0.85,
        "开盘价": 17.64,
        "最高价": 17.64,
        "最低价": 17.53,
        "昨收价": 17.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 2900.0,
        "成交额": 51056.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.SBB"
    },
    {
        "序号": 9894,
        "name": "美国联合能源",
        "最新价": 51.38,
        "涨跌额": -0.44,
        "涨跌幅": -0.85,
        "开盘价": 51.7,
        "最高价": 51.85,
        "最低价": 51.13,
        "昨收价": 51.82,
        "总市值": 13111101490.0,
        "市盈率": 19.03,
        "成交量": 1831601.0,
        "成交额": 94063046.0,
        "振幅": 1.39,
        "换手率": 0.72,
        "symbol": "105.LNT"
    },
    {
        "序号": 9895,
        "name": "家乐氏",
        "最新价": 53.7,
        "涨跌额": -0.46,
        "涨跌幅": -0.85,
        "开盘价": 54.15,
        "最高价": 54.21,
        "最低价": 53.44,
        "昨收价": 54.16,
        "总市值": 18393310199.0,
        "市盈率": 22.27,
        "成交量": 1643221.0,
        "成交额": 88259054.0,
        "振幅": 1.42,
        "换手率": 0.48,
        "symbol": "106.K"
    },
    {
        "序号": 9896,
        "name": "PIMCO Global StocksPlus & Incom",
        "最新价": 7.0,
        "涨跌额": -0.06,
        "涨跌幅": -0.85,
        "开盘价": 6.98,
        "最高价": 7.08,
        "最低价": 6.95,
        "昨收价": 7.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 36069.0,
        "成交额": 252582.0,
        "振幅": 1.84,
        "换手率": null,
        "symbol": "106.PGP"
    },
    {
        "序号": 9897,
        "name": "Monro Inc",
        "最新价": 30.33,
        "涨跌额": -0.26,
        "涨跌幅": -0.85,
        "开盘价": 30.48,
        "最高价": 31.01,
        "最低价": 30.18,
        "昨收价": 30.59,
        "总市值": 953748324.0,
        "市盈率": 27.14,
        "成交量": 352470.0,
        "成交额": 10726114.0,
        "振幅": 2.71,
        "换手率": 1.12,
        "symbol": "105.MNRO"
    },
    {
        "序号": 9898,
        "name": "Empire State Realty Trust Inc-A",
        "最新价": 9.33,
        "涨跌额": -0.08,
        "涨跌幅": -0.85,
        "开盘价": 9.39,
        "最高价": 9.39,
        "最低价": 9.1,
        "昨收价": 9.41,
        "总市值": 1515937616.0,
        "市盈率": 26.72,
        "成交量": 1253264.0,
        "成交额": 11582242.0,
        "振幅": 3.08,
        "换手率": 0.77,
        "symbol": "106.ESRT"
    },
    {
        "序号": 9899,
        "name": "Kura Sushi USA Inc-A",
        "最新价": 60.64,
        "涨跌额": -0.52,
        "涨跌幅": -0.85,
        "开盘价": 61.16,
        "最高价": 61.93,
        "最低价": 59.76,
        "昨收价": 61.16,
        "总市值": 676049103.0,
        "市盈率": 450.1,
        "成交量": 60919.0,
        "成交额": 3703557.0,
        "振幅": 3.55,
        "换手率": 0.55,
        "symbol": "105.KRUS"
    },
    {
        "序号": 9900,
        "name": "Brookfield Real Assets Income F",
        "最新价": 12.82,
        "涨跌额": -0.11,
        "涨跌幅": -0.85,
        "开盘价": 12.85,
        "最高价": 12.91,
        "最低价": 12.78,
        "昨收价": 12.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 175124.0,
        "成交额": 2245912.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "106.RA"
    },
    {
        "序号": 9901,
        "name": "英美烟草",
        "最新价": 29.13,
        "涨跌额": -0.25,
        "涨跌幅": -0.85,
        "开盘价": 29.28,
        "最高价": 29.42,
        "最低价": 29.12,
        "昨收价": 29.38,
        "总市值": 65146566613.0,
        "市盈率": 6.17,
        "成交量": 6443266.0,
        "成交额": 188273260.0,
        "振幅": 1.02,
        "换手率": 0.29,
        "symbol": "106.BTI"
    },
    {
        "序号": 9902,
        "name": "Argo Group International Holdin",
        "最新价": 23.29,
        "涨跌额": -0.2,
        "涨跌幅": -0.85,
        "开盘价": 23.4,
        "最高价": 23.5,
        "最低价": 23.26,
        "昨收价": 23.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 7410.0,
        "成交额": 173617.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "106.ARGD"
    },
    {
        "序号": 9903,
        "name": "VirnetX Holding Corp",
        "最新价": 8.13,
        "涨跌额": -0.07,
        "涨跌幅": -0.85,
        "开盘价": 8.15,
        "最高价": 8.33,
        "最低价": 8.02,
        "昨收价": 8.2,
        "总市值": 29417844.0,
        "市盈率": -0.65,
        "成交量": 28240.0,
        "成交额": 232807.0,
        "振幅": 3.78,
        "换手率": 0.78,
        "symbol": "106.VHC"
    },
    {
        "序号": 9904,
        "name": "Smart Powerr Corp",
        "最新价": 1.16,
        "涨跌额": -0.01,
        "涨跌幅": -0.85,
        "开盘价": 1.22,
        "最高价": 1.25,
        "最低价": 1.14,
        "昨收价": 1.17,
        "总市值": 9237595.0,
        "市盈率": -2.39,
        "成交量": 25323.0,
        "成交额": 30804.0,
        "振幅": 9.4,
        "换手率": 0.32,
        "symbol": "105.CREG"
    },
    {
        "序号": 9905,
        "name": "iShares International Treasury ",
        "最新价": 39.43,
        "涨跌额": -0.34,
        "涨跌幅": -0.85,
        "开盘价": 39.39,
        "最高价": 39.82,
        "最低价": 39.3,
        "昨收价": 39.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 60792.0,
        "成交额": 2396398.0,
        "振幅": 1.31,
        "换手率": null,
        "symbol": "105.IGOV"
    },
    {
        "序号": 9906,
        "name": "CTO Realty Growth Inc",
        "最新价": 17.38,
        "涨跌额": -0.15,
        "涨跌幅": -0.86,
        "开盘价": 17.54,
        "最高价": 17.57,
        "最低价": 17.24,
        "昨收价": 17.53,
        "总市值": 394290397.0,
        "市盈率": -85.98,
        "成交量": 79802.0,
        "成交额": 1384856.0,
        "振幅": 1.88,
        "换手率": 0.35,
        "symbol": "106.CTO"
    },
    {
        "序号": 9907,
        "name": "TransAct Technologies Inc",
        "最新价": 6.94,
        "涨跌额": -0.06,
        "涨跌幅": -0.86,
        "开盘价": 6.91,
        "最高价": 7.15,
        "最低价": 6.86,
        "昨收价": 7.0,
        "总市值": 69109339.0,
        "市盈率": 13.63,
        "成交量": 22442.0,
        "成交额": 155896.0,
        "振幅": 4.14,
        "换手率": 0.23,
        "symbol": "105.TACT"
    },
    {
        "序号": 9908,
        "name": "Jupiter Acquisition Corp-A",
        "最新价": 10.41,
        "涨跌额": -0.09,
        "涨跌幅": -0.86,
        "开盘价": 10.41,
        "最高价": 10.41,
        "最低价": 10.41,
        "昨收价": 10.5,
        "总市值": 62576509.0,
        "市盈率": 52.39,
        "成交量": 846.0,
        "成交额": 8802.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.JAQC"
    },
    {
        "序号": 9909,
        "name": "BrandywineGLOBAL – U.S. Fixed ",
        "最新价": 24.28,
        "涨跌额": -0.21,
        "涨跌幅": -0.86,
        "开盘价": 24.28,
        "最高价": 24.28,
        "最低价": 24.28,
        "昨收价": 24.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 1.0,
        "成交额": 24.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.USFI"
    },
    {
        "序号": 9910,
        "name": "Global X Wind Energy ETF",
        "最新价": 12.7,
        "涨跌额": -0.11,
        "涨跌幅": -0.86,
        "开盘价": 12.7,
        "最高价": 12.7,
        "最低价": 12.7,
        "昨收价": 12.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 520.0,
        "成交额": 6604.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.WNDY"
    },
    {
        "序号": 9911,
        "name": "RXO Inc",
        "最新价": 20.78,
        "涨跌额": -0.18,
        "涨跌幅": -0.86,
        "开盘价": 20.89,
        "最高价": 21.36,
        "最低价": 20.71,
        "昨收价": 20.96,
        "总市值": 2431281549.0,
        "市盈率": -1215.64,
        "成交量": 522651.0,
        "成交额": 10917696.0,
        "振幅": 3.1,
        "换手率": 0.45,
        "symbol": "106.RXO"
    },
    {
        "序号": 9912,
        "name": "Concentrix Corp",
        "最新价": 94.58,
        "涨跌额": -0.82,
        "涨跌幅": -0.86,
        "开盘价": 95.75,
        "最高价": 97.02,
        "最低价": 93.72,
        "昨收价": 95.4,
        "总市值": 6299148873.0,
        "市盈率": 18.03,
        "成交量": 300373.0,
        "成交额": 28403915.0,
        "振幅": 3.46,
        "换手率": 0.45,
        "symbol": "105.CNXC"
    },
    {
        "序号": 9913,
        "name": "Rayonier Advanced Materials Inc",
        "最新价": 3.46,
        "涨跌额": -0.03,
        "涨跌幅": -0.86,
        "开盘价": 3.52,
        "最高价": 3.57,
        "最低价": 3.44,
        "昨收价": 3.49,
        "总市值": 226088226.0,
        "市盈率": -6.17,
        "成交量": 368730.0,
        "成交额": 1280185.0,
        "振幅": 3.72,
        "换手率": 0.56,
        "symbol": "106.RYAM"
    },
    {
        "序号": 9914,
        "name": "Pacific Gas and Electric Co Pfd",
        "最新价": 16.11,
        "涨跌额": -0.14,
        "涨跌幅": -0.86,
        "开盘价": 16.11,
        "最高价": 16.11,
        "最低价": 16.11,
        "昨收价": 16.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 205.0,
        "成交额": 3302.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.PCG_D"
    },
    {
        "序号": 9915,
        "name": "TransAlta Corp",
        "最新价": 8.05,
        "涨跌额": -0.07,
        "涨跌幅": -0.86,
        "开盘价": 8.13,
        "最高价": 8.18,
        "最低价": 8.05,
        "昨收价": 8.12,
        "总市值": 2494695000.0,
        "市盈率": 5.41,
        "成交量": 260953.0,
        "成交额": 2112981.0,
        "振幅": 1.6,
        "换手率": 0.08,
        "symbol": "106.TAC"
    },
    {
        "序号": 9916,
        "name": "ProMIS Neurosciences Inc",
        "最新价": 1.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.86,
        "开盘价": 1.15,
        "最高价": 1.19,
        "最低价": 1.15,
        "昨收价": 1.16,
        "总市值": 21718042.0,
        "市盈率": -1.29,
        "成交量": 3572.0,
        "成交额": 4134.0,
        "振幅": 3.45,
        "换手率": 0.02,
        "symbol": "105.PMN"
    },
    {
        "序号": 9917,
        "name": "P3 Health Partners Inc-A",
        "最新价": 1.15,
        "涨跌额": -0.01,
        "涨跌幅": -0.86,
        "开盘价": 1.15,
        "最高价": 1.18,
        "最低价": 1.13,
        "昨收价": 1.16,
        "总市值": 360052119.0,
        "市盈率": -2.85,
        "成交量": 176018.0,
        "成交额": 202805.0,
        "振幅": 4.31,
        "换手率": 0.06,
        "symbol": "105.PIII"
    },
    {
        "序号": 9918,
        "name": "Cadence Bank Series A Pfd",
        "最新价": 18.39,
        "涨跌额": -0.16,
        "涨跌幅": -0.86,
        "开盘价": 18.65,
        "最高价": 18.65,
        "最低价": 18.39,
        "昨收价": 18.55,
        "总市值": 126891000.0,
        "市盈率": null,
        "成交量": 3259.0,
        "成交额": 60406.0,
        "振幅": 1.4,
        "换手率": 0.05,
        "symbol": "106.CADE_A"
    },
    {
        "序号": 9919,
        "name": "Arch Capital Group Ltd Series G",
        "最新价": 19.52,
        "涨跌额": -0.17,
        "涨跌幅": -0.86,
        "开盘价": 19.62,
        "最高价": 19.62,
        "最低价": 19.39,
        "昨收价": 19.69,
        "总市值": 390400.0,
        "市盈率": null,
        "成交量": 28492.0,
        "成交额": 556140.0,
        "振幅": 1.17,
        "换手率": 142.46,
        "symbol": "105.ACGLN"
    },
    {
        "序号": 9920,
        "name": "梅特勒-托利多",
        "最新价": 1090.52,
        "涨跌额": -9.5,
        "涨跌幅": -0.86,
        "开盘价": 1095.94,
        "最高价": 1109.87,
        "最低价": 1086.88,
        "昨收价": 1100.02,
        "总市值": 23646619757.0,
        "市盈率": 27.19,
        "成交量": 129594.0,
        "成交额": 141583788.0,
        "振幅": 2.09,
        "换手率": 0.6,
        "symbol": "106.MTD"
    },
    {
        "序号": 9921,
        "name": "Brookfield Renewable Partners L",
        "最新价": 25.24,
        "涨跌额": -0.22,
        "涨跌幅": -0.86,
        "开盘价": 25.25,
        "最高价": 25.5,
        "最低价": 24.96,
        "昨收价": 25.46,
        "总市值": 7281145648.0,
        "市盈率": -74.3,
        "成交量": 497569.0,
        "成交额": 12536060.0,
        "振幅": 2.12,
        "换手率": 0.17,
        "symbol": "106.BEP"
    },
    {
        "序号": 9922,
        "name": "Grindrod Shipping Holdings Ltd",
        "最新价": 9.17,
        "涨跌额": -0.08,
        "涨跌幅": -0.86,
        "开盘价": 9.24,
        "最高价": 9.33,
        "最低价": 8.93,
        "昨收价": 9.25,
        "总市值": 178558313.0,
        "市盈率": -15.1,
        "成交量": 16675.0,
        "成交额": 152054.0,
        "振幅": 4.32,
        "换手率": 0.09,
        "symbol": "105.GRIN"
    },
    {
        "序号": 9923,
        "name": "Sweetgreen Inc-A",
        "最新价": 10.31,
        "涨跌额": -0.09,
        "涨跌幅": -0.87,
        "开盘价": 10.33,
        "最高价": 10.47,
        "最低价": 10.16,
        "昨收价": 10.4,
        "总市值": 1159111380.0,
        "市盈率": -8.57,
        "成交量": 1198051.0,
        "成交额": 12350778.0,
        "振幅": 2.98,
        "换手率": 1.07,
        "symbol": "106.SG"
    },
    {
        "序号": 9924,
        "name": "ImmunityBio Inc",
        "最新价": 3.43,
        "涨跌额": -0.03,
        "涨跌幅": -0.87,
        "开盘价": 3.39,
        "最高价": 3.54,
        "最低价": 3.35,
        "昨收价": 3.46,
        "总市值": 2290229412.0,
        "市盈率": -5.0,
        "成交量": 2498706.0,
        "成交额": 8544690.0,
        "振幅": 5.49,
        "换手率": 0.37,
        "symbol": "105.IBRX"
    },
    {
        "序号": 9925,
        "name": "SAI TECH Global Corp-A",
        "最新价": 1.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.87,
        "开盘价": 1.28,
        "最高价": 1.28,
        "最低价": 1.06,
        "昨收价": 1.15,
        "总市值": 27182084.0,
        "市盈率": -3.07,
        "成交量": 17412.0,
        "成交额": 19453.0,
        "振幅": 19.13,
        "换手率": 0.07,
        "symbol": "105.SAI"
    },
    {
        "序号": 9926,
        "name": "Rain Oncology Inc",
        "最新价": 1.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.87,
        "开盘价": 1.12,
        "最高价": 1.16,
        "最低价": 1.09,
        "昨收价": 1.15,
        "总市值": 41468265.0,
        "市盈率": -0.57,
        "成交量": 171228.0,
        "成交额": 191412.0,
        "振幅": 6.09,
        "换手率": 0.47,
        "symbol": "105.RAIN"
    },
    {
        "序号": 9927,
        "name": "爱奇艺",
        "最新价": 4.56,
        "涨跌额": -0.04,
        "涨跌幅": -0.87,
        "开盘价": 4.61,
        "最高价": 4.62,
        "最低价": 4.49,
        "昨收价": 4.6,
        "总市值": 4362524111.0,
        "市盈率": 17.75,
        "成交量": 7889400.0,
        "成交额": 35812939.0,
        "振幅": 2.83,
        "换手率": 0.82,
        "symbol": "105.IQ"
    },
    {
        "序号": 9928,
        "name": "农米良品",
        "最新价": 1.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.87,
        "开盘价": 1.14,
        "最高价": 1.15,
        "最低价": 1.06,
        "昨收价": 1.15,
        "总市值": 6764571.0,
        "市盈率": 1.54,
        "成交量": 16703.0,
        "成交额": 18586.0,
        "振幅": 7.83,
        "换手率": 0.28,
        "symbol": "105.FAMI"
    },
    {
        "序号": 9929,
        "name": "二倍做多沪深300ETF-Direxion",
        "最新价": 13.68,
        "涨跌额": -0.12,
        "涨跌幅": -0.87,
        "开盘价": 13.67,
        "最高价": 13.76,
        "最低价": 13.66,
        "昨收价": 13.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 31288.0,
        "成交额": 428620.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "107.CHAU"
    },
    {
        "序号": 9930,
        "name": "因塞特医疗",
        "最新价": 54.7,
        "涨跌额": -0.48,
        "涨跌幅": -0.87,
        "开盘价": 55.17,
        "最高价": 55.17,
        "最低价": 54.3,
        "昨收价": 55.18,
        "总市值": 12258775319.0,
        "市盈率": 28.85,
        "成交量": 1517309.0,
        "成交额": 83048062.0,
        "振幅": 1.58,
        "换手率": 0.68,
        "symbol": "105.INCY"
    },
    {
        "序号": 9931,
        "name": "Global-E Online Ltd",
        "最新价": 34.15,
        "涨跌额": -0.3,
        "涨跌幅": -0.87,
        "开盘价": 34.01,
        "最高价": 35.03,
        "最低价": 33.82,
        "昨收价": 34.45,
        "总市值": 5609258630.0,
        "市盈率": -40.02,
        "成交量": 1542116.0,
        "成交额": 52931471.0,
        "振幅": 3.51,
        "换手率": 0.94,
        "symbol": "105.GLBE"
    },
    {
        "序号": 9932,
        "name": "ATAC Credit Rotation ETF",
        "最新价": 14.78,
        "涨跌额": -0.13,
        "涨跌幅": -0.87,
        "开盘价": 14.74,
        "最高价": 14.78,
        "最低价": 14.74,
        "昨收价": 14.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 182.0,
        "成交额": 2682.0,
        "振幅": 0.27,
        "换手率": null,
        "symbol": "107.JOJO"
    },
    {
        "序号": 9933,
        "name": "美洲国家水务",
        "最新价": 81.58,
        "涨跌额": -0.72,
        "涨跌幅": -0.87,
        "开盘价": 82.3,
        "最高价": 82.63,
        "最低价": 81.32,
        "昨收价": 82.3,
        "总市值": 3016876695.0,
        "市盈率": 24.5,
        "成交量": 112598.0,
        "成交额": 9194441.0,
        "振幅": 1.59,
        "换手率": 0.3,
        "symbol": "106.AWR"
    },
    {
        "序号": 9934,
        "name": "尚德机构",
        "最新价": 7.93,
        "涨跌额": -0.07,
        "涨跌幅": -0.88,
        "开盘价": 7.84,
        "最高价": 7.93,
        "最低价": 7.59,
        "昨收价": 8.0,
        "总市值": 109022687.0,
        "市盈率": 1.15,
        "成交量": 4940.0,
        "成交额": 38102.0,
        "振幅": 4.25,
        "换手率": 0.04,
        "symbol": "106.STG"
    },
    {
        "序号": 9935,
        "name": "Kornit Digital Ltd",
        "最新价": 18.12,
        "涨跌额": -0.16,
        "涨跌幅": -0.88,
        "开盘价": 18.23,
        "最高价": 19.03,
        "最低价": 17.79,
        "昨收价": 18.28,
        "总市值": 908097807.0,
        "市盈率": -11.83,
        "成交量": 628057.0,
        "成交额": 11451579.0,
        "振幅": 6.78,
        "换手率": 1.25,
        "symbol": "105.KRNT"
    },
    {
        "序号": 9936,
        "name": "赛默飞世尔科技",
        "最新价": 489.77,
        "涨跌额": -4.33,
        "涨跌幅": -0.88,
        "开盘价": 492.81,
        "最高价": 495.68,
        "最低价": 488.02,
        "昨收价": 494.1,
        "总市值": 189233571656.0,
        "市盈率": 31.85,
        "成交量": 1871843.0,
        "成交额": 918416752.0,
        "振幅": 1.55,
        "换手率": 0.48,
        "symbol": "106.TMO"
    },
    {
        "序号": 9937,
        "name": "LENSAR Inc",
        "最新价": 2.26,
        "涨跌额": -0.02,
        "涨跌幅": -0.88,
        "开盘价": 2.25,
        "最高价": 2.34,
        "最低价": 2.25,
        "昨收价": 2.28,
        "总市值": 25421626.0,
        "市盈率": -1.96,
        "成交量": 46327.0,
        "成交额": 106470.0,
        "振幅": 3.95,
        "换手率": 0.41,
        "symbol": "105.LNSR"
    },
    {
        "序号": 9938,
        "name": "GasLog Partners LP Series A Pfd",
        "最新价": 22.6,
        "涨跌额": -0.2,
        "涨跌幅": -0.88,
        "开盘价": 22.9,
        "最高价": 22.9,
        "最低价": 22.52,
        "昨收价": 22.8,
        "总市值": 114920638.0,
        "市盈率": null,
        "成交量": 8280.0,
        "成交额": 187524.0,
        "振幅": 1.67,
        "换手率": 0.16,
        "symbol": "106.GLOP_A"
    },
    {
        "序号": 9939,
        "name": "Frontdoor Inc",
        "最新价": 34.98,
        "涨跌额": -0.31,
        "涨跌幅": -0.88,
        "开盘价": 35.29,
        "最高价": 35.67,
        "最低价": 34.86,
        "昨收价": 35.29,
        "总市值": 2785168325.0,
        "市盈率": 16.29,
        "成交量": 669329.0,
        "成交额": 23501381.0,
        "振幅": 2.3,
        "换手率": 0.84,
        "symbol": "105.FTDR"
    },
    {
        "序号": 9940,
        "name": "Utz Brands Inc-A",
        "最新价": 13.53,
        "涨跌额": -0.12,
        "涨跌幅": -0.88,
        "开盘价": 13.63,
        "最高价": 13.72,
        "最低价": 13.42,
        "昨收价": 13.65,
        "总市值": 1900835342.0,
        "市盈率": 107.82,
        "成交量": 429897.0,
        "成交额": 5824193.0,
        "振幅": 2.2,
        "换手率": 0.31,
        "symbol": "106.UTZ"
    },
    {
        "序号": 9941,
        "name": "EPR Properties Series E Pfd",
        "最新价": 27.04,
        "涨跌额": -0.24,
        "涨跌幅": -0.88,
        "开盘价": 27.25,
        "最高价": 27.25,
        "最低价": 26.98,
        "昨收价": 27.28,
        "总市值": 93179299.0,
        "市盈率": null,
        "成交量": 74923.0,
        "成交额": 2022801.0,
        "振幅": 0.99,
        "换手率": 2.17,
        "symbol": "106.EPR_E"
    },
    {
        "序号": 9942,
        "name": "Direxion Daily MSFT Bear 1X Sha",
        "最新价": 15.77,
        "涨跌额": -0.14,
        "涨跌幅": -0.88,
        "开盘价": 15.98,
        "最高价": 16.0,
        "最低价": 15.75,
        "昨收价": 15.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 24468.0,
        "成交额": 388937.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "105.MSFD"
    },
    {
        "序号": 9943,
        "name": "阿诺医药集团",
        "最新价": 11.26,
        "涨跌额": -0.1,
        "涨跌幅": -0.88,
        "开盘价": 10.7,
        "最高价": 11.26,
        "最低价": 10.6,
        "昨收价": 11.36,
        "总市值": 415497021.0,
        "市盈率": -3.39,
        "成交量": 1508.0,
        "成交额": 16199.0,
        "振幅": 5.81,
        "换手率": 0.0,
        "symbol": "105.ANL"
    },
    {
        "序号": 9944,
        "name": "Graphite Bio Inc",
        "最新价": 2.25,
        "涨跌额": -0.02,
        "涨跌幅": -0.88,
        "开盘价": 2.26,
        "最高价": 2.29,
        "最低价": 2.24,
        "昨收价": 2.27,
        "总市值": 130492082.0,
        "市盈率": -1.03,
        "成交量": 258817.0,
        "成交额": 585328.0,
        "振幅": 2.2,
        "换手率": 0.45,
        "symbol": "105.GRPH"
    },
    {
        "序号": 9945,
        "name": "Mr. Cooper Group Inc",
        "最新价": 64.0,
        "涨跌额": -0.57,
        "涨跌幅": -0.88,
        "开盘价": 64.55,
        "最高价": 64.87,
        "最低价": 63.41,
        "昨收价": 64.57,
        "总市值": 4215061952.0,
        "市盈率": 9.26,
        "成交量": 414484.0,
        "成交额": 26530298.0,
        "振幅": 2.26,
        "换手率": 0.63,
        "symbol": "105.COOP"
    },
    {
        "序号": 9946,
        "name": "NETLease Corporate Real Estate ",
        "最新价": 23.57,
        "涨跌额": -0.21,
        "涨跌幅": -0.88,
        "开盘价": 23.72,
        "最高价": 23.87,
        "最低价": 23.52,
        "昨收价": 23.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 7114.0,
        "成交额": 168050.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "107.NETL"
    },
    {
        "序号": 9947,
        "name": "Bank OZK Inc Series A Pfd",
        "最新价": 15.71,
        "涨跌额": -0.14,
        "涨跌幅": -0.88,
        "开盘价": 15.85,
        "最高价": 15.93,
        "最低价": 15.71,
        "昨收价": 15.85,
        "总市值": 219940000.0,
        "市盈率": null,
        "成交量": 22750.0,
        "成交额": 359632.0,
        "振幅": 1.39,
        "换手率": 0.16,
        "symbol": "105.OZKAP"
    },
    {
        "序号": 9948,
        "name": "Mainz Biomed NV",
        "最新价": 1.12,
        "涨跌额": -0.01,
        "涨跌幅": -0.88,
        "开盘价": 1.13,
        "最高价": 1.21,
        "最低价": 1.07,
        "昨收价": 1.13,
        "总市值": 22801486.0,
        "市盈率": -0.78,
        "成交量": 256616.0,
        "成交额": 283449.0,
        "振幅": 12.39,
        "换手率": 1.26,
        "symbol": "105.MYNZ"
    },
    {
        "序号": 9949,
        "name": "BNY Mellon High Yield Strategie",
        "最新价": 2.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.88,
        "开盘价": 2.25,
        "最高价": 2.27,
        "最低价": 2.22,
        "昨收价": 2.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 1222886.0,
        "成交额": 2749253.0,
        "振幅": 2.21,
        "换手率": null,
        "symbol": "106.DHF"
    },
    {
        "序号": 9950,
        "name": "Schwab Long-Term U.S. Treasury ",
        "最新价": 33.53,
        "涨跌额": -0.3,
        "涨跌幅": -0.89,
        "开盘价": 33.52,
        "最高价": 33.6,
        "最低价": 33.35,
        "昨收价": 33.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 194237.0,
        "成交额": 6505373.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.SCHQ"
    },
    {
        "序号": 9951,
        "name": "Capital Product Partners LP",
        "最新价": 13.41,
        "涨跌额": -0.12,
        "涨跌幅": -0.89,
        "开盘价": 13.4,
        "最高价": 13.68,
        "最低价": 13.34,
        "昨收价": 13.53,
        "总市值": 267548596.0,
        "市盈率": 4.81,
        "成交量": 93389.0,
        "成交额": 1264778.0,
        "振幅": 2.51,
        "换手率": 0.47,
        "symbol": "105.CPLP"
    },
    {
        "序号": 9952,
        "name": "Tema Oncology ETF",
        "最新价": 24.57,
        "涨跌额": -0.22,
        "涨跌幅": -0.89,
        "开盘价": 24.57,
        "最高价": 24.57,
        "最低价": 24.57,
        "昨收价": 24.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 52.0,
        "成交额": 1277.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CANC"
    },
    {
        "序号": 9953,
        "name": "好未来",
        "最新价": 11.14,
        "涨跌额": -0.1,
        "涨跌幅": -0.89,
        "开盘价": 11.02,
        "最高价": 11.36,
        "最低价": 10.99,
        "昨收价": 11.24,
        "总市值": 7112697296.0,
        "市盈率": -72.48,
        "成交量": 7141566.0,
        "成交额": 79776988.0,
        "振幅": 3.29,
        "换手率": 1.12,
        "symbol": "106.TAL"
    },
    {
        "序号": 9954,
        "name": "Ellsworth Growth and Income Fun",
        "最新价": 22.25,
        "涨跌额": -0.2,
        "涨跌幅": -0.89,
        "开盘价": 22.29,
        "最高价": 22.29,
        "最低价": 22.24,
        "昨收价": 22.45,
        "总市值": 25972759.0,
        "市盈率": null,
        "成交量": 809.0,
        "成交额": 18004.0,
        "振幅": 0.22,
        "换手率": 0.07,
        "symbol": "107.ECF_A"
    },
    {
        "序号": 9955,
        "name": "Sensata科技控股",
        "最新价": 33.36,
        "涨跌额": -0.3,
        "涨跌幅": -0.89,
        "开盘价": 33.64,
        "最高价": 34.09,
        "最低价": 33.34,
        "昨收价": 33.66,
        "总市值": 5049202967.0,
        "市盈率": 16.21,
        "成交量": 4871520.0,
        "成交额": 163798030.0,
        "振幅": 2.23,
        "换手率": 3.22,
        "symbol": "106.ST"
    },
    {
        "序号": 9956,
        "name": "Carlyle Secured Lending Inc Not",
        "最新价": 25.54,
        "涨跌额": -0.23,
        "涨跌幅": -0.89,
        "开盘价": 25.8,
        "最高价": 25.88,
        "最低价": 25.54,
        "昨收价": 25.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 16789.0,
        "成交额": 432907.0,
        "振幅": 1.32,
        "换手率": null,
        "symbol": "105.CGBDL"
    },
    {
        "序号": 9957,
        "name": "Syndax Pharmaceuticals Inc",
        "最新价": 16.65,
        "涨跌额": -0.15,
        "涨跌幅": -0.89,
        "开盘价": 16.85,
        "最高价": 17.0,
        "最低价": 16.51,
        "昨收价": 16.8,
        "总市值": 1159517122.0,
        "市盈率": -6.59,
        "成交量": 1455417.0,
        "成交额": 24325124.0,
        "振幅": 2.92,
        "换手率": 2.09,
        "symbol": "105.SNDX"
    },
    {
        "序号": 9958,
        "name": "Nuveen Municipal Income Fund",
        "最新价": 8.88,
        "涨跌额": -0.08,
        "涨跌幅": -0.89,
        "开盘价": 8.9,
        "最高价": 9.0,
        "最低价": 8.87,
        "昨收价": 8.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 30972.0,
        "成交额": 276760.0,
        "振幅": 1.45,
        "换手率": null,
        "symbol": "106.NMI"
    },
    {
        "序号": 9959,
        "name": "NEW YORK COMMUNITY CAPITAL TRUS",
        "最新价": 38.84,
        "涨跌额": -0.35,
        "涨跌幅": -0.89,
        "开盘价": 39.2,
        "最高价": 39.41,
        "最低价": 38.66,
        "昨收价": 39.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 19994.0,
        "成交额": 777686.0,
        "振幅": 1.91,
        "换手率": null,
        "symbol": "106.NYCB_U"
    },
    {
        "序号": 9960,
        "name": "Matthews Japan Active ETF",
        "最新价": 25.46,
        "涨跌额": -0.23,
        "涨跌幅": -0.9,
        "开盘价": 25.46,
        "最高价": 25.46,
        "最低价": 25.46,
        "昨收价": 25.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 72.0,
        "成交额": 1833.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.JPAN"
    },
    {
        "序号": 9961,
        "name": "国际公司债ETF-SPDR",
        "最新价": 28.73,
        "涨跌额": -0.26,
        "涨跌幅": -0.9,
        "开盘价": 28.7,
        "最高价": 28.81,
        "最低价": 28.66,
        "昨收价": 28.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 19738.0,
        "成交额": 567500.0,
        "振幅": 0.52,
        "换手率": null,
        "symbol": "107.IBND"
    },
    {
        "序号": 9962,
        "name": "ProShares UltraShort Consumer D",
        "最新价": 16.56,
        "涨跌额": -0.15,
        "涨跌幅": -0.9,
        "开盘价": 16.69,
        "最高价": 16.69,
        "最低价": 16.5,
        "昨收价": 16.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 2140.0,
        "成交额": 35411.0,
        "振幅": 1.14,
        "换手率": null,
        "symbol": "107.SCC"
    },
    {
        "序号": 9963,
        "name": "Bank of America Corp Series SS ",
        "最新价": 19.87,
        "涨跌额": -0.18,
        "涨跌幅": -0.9,
        "开盘价": 20.02,
        "最高价": 20.02,
        "最低价": 19.82,
        "昨收价": 20.05,
        "总市值": 556360.0,
        "市盈率": null,
        "成交量": 57163.0,
        "成交额": 1137294.0,
        "振幅": 1.0,
        "换手率": 204.15,
        "symbol": "106.BAC_S"
    },
    {
        "序号": 9964,
        "name": "Achieve Life Sciences Inc",
        "最新价": 5.51,
        "涨跌额": -0.05,
        "涨跌幅": -0.9,
        "开盘价": 5.5,
        "最高价": 5.6,
        "最低价": 5.36,
        "昨收价": 5.56,
        "总市值": 116623338.0,
        "市盈率": -3.28,
        "成交量": 30328.0,
        "成交额": 167385.0,
        "振幅": 4.32,
        "换手率": 0.14,
        "symbol": "105.ACHV"
    },
    {
        "序号": 9965,
        "name": "Nuveen Missouri Quality Municip",
        "最新价": 9.9,
        "涨跌额": -0.09,
        "涨跌幅": -0.9,
        "开盘价": 9.86,
        "最高价": 9.92,
        "最低价": 9.85,
        "昨收价": 9.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 10187.0,
        "成交额": 100620.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.NOM"
    },
    {
        "序号": 9966,
        "name": "Cohen & Steers Real Estate Oppo",
        "最新价": 13.19,
        "涨跌额": -0.12,
        "涨跌幅": -0.9,
        "开盘价": 13.22,
        "最高价": 13.31,
        "最低价": 13.16,
        "昨收价": 13.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 186262.0,
        "成交额": 2456498.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "106.RLTY"
    },
    {
        "序号": 9967,
        "name": "阿曼瑞恩",
        "最新价": 78.02,
        "涨跌额": -0.71,
        "涨跌幅": -0.9,
        "开盘价": 78.8,
        "最高价": 79.05,
        "最低价": 77.78,
        "昨收价": 78.73,
        "总市值": 20514972645.0,
        "市盈率": 17.73,
        "成交量": 1619441.0,
        "成交额": 126404795.0,
        "振幅": 1.61,
        "换手率": 0.62,
        "symbol": "106.AEE"
    },
    {
        "序号": 9968,
        "name": "Skyward Specialty Insurance Gro",
        "最新价": 32.96,
        "涨跌额": -0.3,
        "涨跌幅": -0.9,
        "开盘价": 33.26,
        "最高价": 33.5,
        "最低价": 32.63,
        "昨收价": 33.26,
        "总市值": 1312717235.0,
        "市盈率": 17.02,
        "成交量": 382219.0,
        "成交额": 12567614.0,
        "振幅": 2.62,
        "换手率": 0.96,
        "symbol": "105.SKWD"
    },
    {
        "序号": 9969,
        "name": "Tilly’s Inc-A",
        "最新价": 7.69,
        "涨跌额": -0.07,
        "涨跌幅": -0.9,
        "开盘价": 7.72,
        "最高价": 7.77,
        "最低价": 7.68,
        "昨收价": 7.76,
        "总市值": 230497930.0,
        "市盈率": -16.41,
        "成交量": 55188.0,
        "成交额": 425636.0,
        "振幅": 1.16,
        "换手率": 0.18,
        "symbol": "106.TLYS"
    },
    {
        "序号": 9970,
        "name": "桑普拉能源",
        "最新价": 72.38,
        "涨跌额": -0.66,
        "涨跌幅": -0.9,
        "开盘价": 73.22,
        "最高价": 73.33,
        "最低价": 72.22,
        "昨收价": 73.04,
        "总市值": 46791564900.0,
        "市盈率": 16.86,
        "成交量": 2521660.0,
        "成交额": 182851863.0,
        "振幅": 1.52,
        "换手率": 0.39,
        "symbol": "106.SRE"
    },
    {
        "序号": 9971,
        "name": "三倍做空道指ETF-ProShares",
        "最新价": 20.83,
        "涨跌额": -0.19,
        "涨跌幅": -0.9,
        "开盘价": 21.05,
        "最高价": 21.12,
        "最低价": 20.72,
        "昨收价": 21.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 8419560.0,
        "成交额": 175942832.0,
        "振幅": 1.9,
        "换手率": null,
        "symbol": "107.SDOW"
    },
    {
        "序号": 9972,
        "name": "Wells Fargo & Co Series DD Pfd",
        "最新价": 17.53,
        "涨跌额": -0.16,
        "涨跌幅": -0.9,
        "开盘价": 17.6,
        "最高价": 17.66,
        "最低价": 17.45,
        "昨收价": 17.69,
        "总市值": 876500.0,
        "市盈率": null,
        "成交量": 110017.0,
        "成交额": 1929229.0,
        "振幅": 1.19,
        "换手率": 220.03,
        "symbol": "106.WFC_D"
    },
    {
        "序号": 9973,
        "name": "89bio Inc",
        "最新价": 9.86,
        "涨跌额": -0.09,
        "涨跌幅": -0.9,
        "开盘价": 9.91,
        "最高价": 10.01,
        "最低价": 9.6,
        "昨收价": 9.95,
        "总市值": 745795768.0,
        "市盈率": -5.89,
        "成交量": 1699059.0,
        "成交额": 16724984.0,
        "振幅": 4.12,
        "换手率": 2.25,
        "symbol": "105.ETNB"
    },
    {
        "序号": 9974,
        "name": "PROS Holdings Inc",
        "最新价": 37.24,
        "涨跌额": -0.34,
        "涨跌幅": -0.9,
        "开盘价": 37.5,
        "最高价": 38.57,
        "最低价": 37.23,
        "昨收价": 37.58,
        "总市值": 1722775579.0,
        "市盈率": -27.13,
        "成交量": 310007.0,
        "成交额": 11671821.0,
        "振幅": 3.57,
        "换手率": 0.67,
        "symbol": "106.PRO"
    },
    {
        "序号": 9975,
        "name": "Cemig ADR Pfd",
        "最新价": 2.19,
        "涨跌额": -0.02,
        "涨跌幅": -0.9,
        "开盘价": 2.2,
        "最高价": 2.23,
        "最低价": 2.17,
        "昨收价": 2.21,
        "总市值": 3209495510.0,
        "市盈率": 0.01,
        "成交量": 3671490.0,
        "成交额": 8043862.0,
        "振幅": 2.71,
        "换手率": 0.25,
        "symbol": "106.CIG"
    },
    {
        "序号": 9976,
        "name": "阿梅雷斯克",
        "最新价": 31.75,
        "涨跌额": -0.29,
        "涨跌幅": -0.91,
        "开盘价": 31.8,
        "最高价": 32.62,
        "最低价": 30.93,
        "昨收价": 32.04,
        "总市值": 1658446169.0,
        "市盈率": 35.54,
        "成交量": 410002.0,
        "成交额": 13032903.0,
        "振幅": 5.27,
        "换手率": 0.78,
        "symbol": "106.AMRC"
    },
    {
        "序号": 9977,
        "name": "美国水业",
        "最新价": 131.35,
        "涨跌额": -1.2,
        "涨跌幅": -0.91,
        "开盘价": 132.16,
        "最高价": 132.21,
        "最低价": 130.91,
        "昨收价": 132.55,
        "总市值": 25574501356.0,
        "市盈率": 27.8,
        "成交量": 714782.0,
        "成交额": 93960577.0,
        "振幅": 0.98,
        "换手率": 0.37,
        "symbol": "106.AWK"
    },
    {
        "序号": 9978,
        "name": "康尼格拉",
        "最新价": 29.52,
        "涨跌额": -0.27,
        "涨跌幅": -0.91,
        "开盘价": 29.81,
        "最高价": 29.89,
        "最低价": 29.46,
        "昨收价": 29.79,
        "总市值": 14109611847.0,
        "市盈率": 13.05,
        "成交量": 3289595.0,
        "成交额": 97330627.0,
        "振幅": 1.44,
        "换手率": 0.69,
        "symbol": "106.CAG"
    },
    {
        "序号": 9979,
        "name": "Gladstone Land Corp",
        "最新价": 14.21,
        "涨跌额": -0.13,
        "涨跌幅": -0.91,
        "开盘价": 14.25,
        "最高价": 14.37,
        "最低价": 14.05,
        "昨收价": 14.34,
        "总市值": 509264261.0,
        "市盈率": 36.75,
        "成交量": 202050.0,
        "成交额": 2867578.0,
        "振幅": 2.23,
        "换手率": 0.56,
        "symbol": "105.LAND"
    },
    {
        "序号": 9980,
        "name": "Everspin Technologies Inc",
        "最新价": 8.73,
        "涨跌额": -0.08,
        "涨跌幅": -0.91,
        "开盘价": 8.79,
        "最高价": 8.95,
        "最低价": 8.65,
        "昨收价": 8.81,
        "总市值": 183534614.0,
        "市盈率": 23.84,
        "成交量": 76255.0,
        "成交额": 669634.0,
        "振幅": 3.41,
        "换手率": 0.36,
        "symbol": "105.MRAM"
    },
    {
        "序号": 9981,
        "name": "Essential Utilities Inc",
        "最新价": 36.0,
        "涨跌额": -0.33,
        "涨跌幅": -0.91,
        "开盘价": 36.25,
        "最高价": 36.39,
        "最低价": 35.94,
        "昨收价": 36.33,
        "总市值": 9833969412.0,
        "市盈率": 20.59,
        "成交量": 937288.0,
        "成交额": 33795750.0,
        "振幅": 1.24,
        "换手率": 0.34,
        "symbol": "106.WTRG"
    },
    {
        "序号": 9982,
        "name": "Box Inc-A",
        "最新价": 23.99,
        "涨跌额": -0.22,
        "涨跌幅": -0.91,
        "开盘价": 24.22,
        "最高价": 24.77,
        "最低价": 23.98,
        "昨收价": 24.21,
        "总市值": 3444757038.0,
        "市盈率": 68.45,
        "成交量": 2827457.0,
        "成交额": 68496783.0,
        "振幅": 3.26,
        "换手率": 1.97,
        "symbol": "106.BOX"
    },
    {
        "序号": 9983,
        "name": "Seres Therapeutics Inc",
        "最新价": 1.09,
        "涨跌额": -0.01,
        "涨跌幅": -0.91,
        "开盘价": 1.13,
        "最高价": 1.17,
        "最低价": 1.07,
        "昨收价": 1.1,
        "总市值": 140890347.0,
        "市盈率": -1.0,
        "成交量": 1865287.0,
        "成交额": 2083835.0,
        "振幅": 9.09,
        "换手率": 1.44,
        "symbol": "105.MCRB"
    },
    {
        "序号": 9984,
        "name": "Gentherm Inc",
        "最新价": 45.63,
        "涨跌额": -0.42,
        "涨跌幅": -0.91,
        "开盘价": 46.07,
        "最高价": 46.63,
        "最低价": 45.56,
        "昨收价": 46.05,
        "总市值": 1496495032.0,
        "市盈率": 82.9,
        "成交量": 123581.0,
        "成交额": 5662869.0,
        "振幅": 2.32,
        "换手率": 0.38,
        "symbol": "105.THRM"
    },
    {
        "序号": 9985,
        "name": "Exela Technologies Inc Series B",
        "最新价": 2.17,
        "涨跌额": -0.02,
        "涨跌幅": -0.91,
        "开盘价": 2.13,
        "最高价": 2.17,
        "最低价": 2.13,
        "昨收价": 2.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 4318.0,
        "成交额": 9328.0,
        "振幅": 1.83,
        "换手率": null,
        "symbol": "105.XELAP"
    },
    {
        "序号": 9986,
        "name": "花苑食品",
        "最新价": 21.7,
        "涨跌额": -0.2,
        "涨跌幅": -0.91,
        "开盘价": 21.87,
        "最高价": 21.91,
        "最低价": 21.67,
        "昨收价": 21.9,
        "总市值": 4581308904.0,
        "市盈率": 33.6,
        "成交量": 926728.0,
        "成交额": 20143232.0,
        "振幅": 1.1,
        "换手率": 0.44,
        "symbol": "106.FLO"
    },
    {
        "序号": 9987,
        "name": "二倍做空金融ETF-ProShares",
        "最新价": 16.27,
        "涨跌额": -0.15,
        "涨跌幅": -0.91,
        "开盘价": 16.39,
        "最高价": 16.41,
        "最低价": 16.21,
        "昨收价": 16.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 19721.0,
        "成交额": 321310.0,
        "振幅": 1.22,
        "换手率": null,
        "symbol": "107.SKF"
    },
    {
        "序号": 9988,
        "name": "Global X Disruptive Materials E",
        "最新价": 14.08,
        "涨跌额": -0.13,
        "涨跌幅": -0.91,
        "开盘价": 14.07,
        "最高价": 14.08,
        "最低价": 14.04,
        "昨收价": 14.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 3582.0,
        "成交额": 50311.0,
        "振幅": 0.28,
        "换手率": null,
        "symbol": "105.DMAT"
    },
    {
        "序号": 9989,
        "name": "T-Rex 2X Inverse Tesla Daily Ta",
        "最新价": 27.07,
        "涨跌额": -0.25,
        "涨跌幅": -0.92,
        "开盘价": 27.99,
        "最高价": 27.99,
        "最低价": 26.93,
        "昨收价": 27.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 31080.0,
        "成交额": 846890.0,
        "振幅": 3.88,
        "换手率": null,
        "symbol": "105.TSLZ"
    },
    {
        "序号": 9990,
        "name": "Western Asset Investment Grade ",
        "最新价": 11.9,
        "涨跌额": -0.11,
        "涨跌幅": -0.92,
        "开盘价": 12.01,
        "最高价": 12.01,
        "最低价": 11.83,
        "昨收价": 12.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 5670.0,
        "成交额": 67448.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "106.PAI"
    },
    {
        "序号": 9991,
        "name": "PlayAGS Inc",
        "最新价": 7.57,
        "涨跌额": -0.07,
        "涨跌幅": -0.92,
        "开盘价": 7.56,
        "最高价": 7.68,
        "最低价": 7.45,
        "昨收价": 7.64,
        "总市值": 293051384.0,
        "市盈率": 100.98,
        "成交量": 248129.0,
        "成交额": 1880931.0,
        "振幅": 3.01,
        "换手率": 0.64,
        "symbol": "106.AGS"
    },
    {
        "序号": 9992,
        "name": "Ribbon Communications Inc",
        "最新价": 2.16,
        "涨跌额": -0.02,
        "涨跌幅": -0.92,
        "开盘价": 2.17,
        "最高价": 2.22,
        "最低价": 2.15,
        "昨收价": 2.18,
        "总市值": 370938435.0,
        "市盈率": -7.03,
        "成交量": 296584.0,
        "成交额": 644116.0,
        "振幅": 3.21,
        "换手率": 0.17,
        "symbol": "105.RBBN"
    },
    {
        "序号": 9993,
        "name": "Virtus Convertible & Income Fun",
        "最新价": 3.24,
        "涨跌额": -0.03,
        "涨跌幅": -0.92,
        "开盘价": 3.24,
        "最高价": 3.26,
        "最低价": 3.17,
        "昨收价": 3.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 1433904.0,
        "成交额": 4604404.0,
        "振幅": 2.75,
        "换手率": null,
        "symbol": "106.NCV"
    },
    {
        "序号": 9994,
        "name": "Hims & Hers Health Inc-A",
        "最新价": 8.64,
        "涨跌额": -0.08,
        "涨跌幅": -0.92,
        "开盘价": 8.67,
        "最高价": 8.77,
        "最低价": 8.6,
        "昨收价": 8.72,
        "总市值": 1831623909.0,
        "市盈率": -51.31,
        "成交量": 1907421.0,
        "成交额": 16520006.0,
        "振幅": 1.95,
        "换手率": 0.9,
        "symbol": "106.HIMS"
    },
    {
        "序号": 9995,
        "name": "Hercules Capital Inc Notes",
        "最新价": 23.76,
        "涨跌额": -0.22,
        "涨跌幅": -0.92,
        "开盘价": 24.03,
        "最高价": 24.03,
        "最低价": 23.61,
        "昨收价": 23.98,
        "总市值": null,
        "市盈率": null,
        "成交量": 5821.0,
        "成交额": 138293.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "106.HCXY"
    },
    {
        "序号": 9996,
        "name": "Columbia Emerging Markets Consu",
        "最新价": 20.47,
        "涨跌额": -0.19,
        "涨跌幅": -0.92,
        "开盘价": 20.47,
        "最高价": 20.54,
        "最低价": 20.47,
        "昨收价": 20.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 4193.0,
        "成交额": 86042.0,
        "振幅": 0.34,
        "换手率": null,
        "symbol": "107.ECON"
    },
    {
        "序号": 9997,
        "name": "iShares MSCI Japan Sm Cap",
        "最新价": 69.95,
        "涨跌额": -0.65,
        "涨跌幅": -0.92,
        "开盘价": 69.83,
        "最高价": 69.95,
        "最低价": 69.67,
        "昨收价": 70.6,
        "总市值": null,
        "市盈率": null,
        "成交量": 7422.0,
        "成交额": 518020.0,
        "振幅": 0.4,
        "换手率": null,
        "symbol": "107.SCJ"
    },
    {
        "序号": 9998,
        "name": "宝洁",
        "最新价": 145.15,
        "涨跌额": -1.35,
        "涨跌幅": -0.92,
        "开盘价": 145.92,
        "最高价": 146.24,
        "最低价": 144.52,
        "昨收价": 146.5,
        "总市值": 342102033527.0,
        "市盈率": 22.46,
        "成交量": 6385269.0,
        "成交额": 926753488.0,
        "振幅": 1.17,
        "换手率": 0.27,
        "symbol": "106.PG"
    },
    {
        "序号": 9999,
        "name": "HireQuest Inc",
        "最新价": 16.12,
        "涨跌额": -0.15,
        "涨跌幅": -0.92,
        "开盘价": 15.82,
        "最高价": 16.18,
        "最低价": 15.82,
        "昨收价": 16.27,
        "总市值": 224068000.0,
        "市盈率": 25.36,
        "成交量": 15408.0,
        "成交额": 247306.0,
        "振幅": 2.21,
        "换手率": 0.11,
        "symbol": "105.HQI"
    },
    {
        "序号": 10000,
        "name": "霍金斯材料",
        "最新价": 62.3,
        "涨跌额": -0.58,
        "涨跌幅": -0.92,
        "开盘价": 63.28,
        "最高价": 63.6,
        "最低价": 61.78,
        "昨收价": 62.88,
        "总市值": 1304795625.0,
        "市盈率": 18.91,
        "成交量": 61666.0,
        "成交额": 3844249.0,
        "振幅": 2.89,
        "换手率": 0.29,
        "symbol": "105.HWKN"
    },
    {
        "序号": 10001,
        "name": "Guggenheim Active Allocation Fu",
        "最新价": 13.95,
        "涨跌额": -0.13,
        "涨跌幅": -0.92,
        "开盘价": 14.12,
        "最高价": 14.13,
        "最低价": 13.83,
        "昨收价": 14.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 98926.0,
        "成交额": 1379858.0,
        "振幅": 2.13,
        "换手率": null,
        "symbol": "106.GUG"
    },
    {
        "序号": 10002,
        "name": "德州公路酒吧",
        "最新价": 113.52,
        "涨跌额": -1.06,
        "涨跌幅": -0.93,
        "开盘价": 114.2,
        "最高价": 114.76,
        "最低价": 112.87,
        "昨收价": 114.58,
        "总市值": 7581220009.0,
        "市盈率": 25.94,
        "成交量": 1311598.0,
        "成交额": 148827622.0,
        "振幅": 1.65,
        "换手率": 1.96,
        "symbol": "105.TXRH"
    },
    {
        "序号": 10003,
        "name": "RiverNorth Managed Duration Mun",
        "最新价": 14.98,
        "涨跌额": -0.14,
        "涨跌幅": -0.93,
        "开盘价": 15.03,
        "最高价": 15.14,
        "最低价": 14.9,
        "昨收价": 15.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 40852.0,
        "成交额": 614728.0,
        "振幅": 1.59,
        "换手率": null,
        "symbol": "106.RMMZ"
    },
    {
        "序号": 10004,
        "name": "纽勤",
        "最新价": 18.18,
        "涨跌额": -0.17,
        "涨跌幅": -0.93,
        "开盘价": 18.23,
        "最高价": 18.41,
        "最低价": 17.94,
        "昨收价": 18.35,
        "总市值": 3932526381.0,
        "市盈率": -147.97,
        "成交量": 1549522.0,
        "成交额": 28197504.0,
        "振幅": 2.56,
        "换手率": 0.72,
        "symbol": "105.NEOG"
    },
    {
        "序号": 10005,
        "name": "NexPoint Real Estate Finance In",
        "最新价": 16.03,
        "涨跌额": -0.15,
        "涨跌幅": -0.93,
        "开盘价": 16.2,
        "最高价": 16.33,
        "最低价": 16.0,
        "昨收价": 16.18,
        "总市值": 276227565.0,
        "市盈率": -79.72,
        "成交量": 65575.0,
        "成交额": 1058156.0,
        "振幅": 2.04,
        "换手率": 0.38,
        "symbol": "106.NREF"
    },
    {
        "序号": 10006,
        "name": "EG Acquisition Corp Unit Cons o",
        "最新价": 10.68,
        "涨跌额": -0.1,
        "涨跌幅": -0.93,
        "开盘价": 11.85,
        "最高价": 11.85,
        "最低价": 10.68,
        "昨收价": 10.78,
        "总市值": 105270934.0,
        "市盈率": 103.95,
        "成交量": 913.0,
        "成交额": 9881.0,
        "振幅": 10.85,
        "换手率": null,
        "symbol": "106.EGGF_U"
    },
    {
        "序号": 10007,
        "name": "Tootsie Roll Industries Inc",
        "最新价": 34.12,
        "涨跌额": -0.32,
        "涨跌幅": -0.93,
        "开盘价": 34.35,
        "最高价": 34.57,
        "最低价": 34.03,
        "昨收价": 34.44,
        "总市值": 2375578796.0,
        "市盈率": 27.04,
        "成交量": 48272.0,
        "成交额": 1650669.0,
        "振幅": 1.57,
        "换手率": 0.07,
        "symbol": "106.TR"
    },
    {
        "序号": 10008,
        "name": "SPDR S&P Emerging Markets Divid",
        "最新价": 30.92,
        "涨跌额": -0.29,
        "涨跌幅": -0.93,
        "开盘价": 31.0,
        "最高价": 31.0,
        "最低价": 30.86,
        "昨收价": 31.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 74561.0,
        "成交额": 2307392.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.EDIV"
    },
    {
        "序号": 10009,
        "name": "Franklin FTSE China ETF",
        "最新价": 15.99,
        "涨跌额": -0.15,
        "涨跌幅": -0.93,
        "开盘价": 16.04,
        "最高价": 16.04,
        "最低价": 15.92,
        "昨收价": 16.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 148134.0,
        "成交额": 2366089.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.FLCH"
    },
    {
        "序号": 10010,
        "name": "Verve Therapeutics Inc",
        "最新价": 12.79,
        "涨跌额": -0.12,
        "涨跌幅": -0.93,
        "开盘价": 12.81,
        "最高价": 13.43,
        "最低价": 12.52,
        "昨收价": 12.91,
        "总市值": 975272647.0,
        "市盈率": -5.06,
        "成交量": 1167334.0,
        "成交额": 15069918.0,
        "振幅": 7.05,
        "换手率": 1.53,
        "symbol": "105.VERV"
    },
    {
        "序号": 10011,
        "name": "Public Storage Series R Pfd",
        "最新价": 18.11,
        "涨跌额": -0.17,
        "涨跌幅": -0.93,
        "开盘价": 18.25,
        "最高价": 18.27,
        "最低价": 18.1,
        "昨收价": 18.28,
        "总市值": 353145.0,
        "市盈率": null,
        "成交量": 36129.0,
        "成交额": 656440.0,
        "振幅": 0.93,
        "换手率": 185.28,
        "symbol": "106.PSA_R"
    },
    {
        "序号": 10012,
        "name": "Citizens Financial Group Inc Se",
        "最新价": 24.43,
        "涨跌额": -0.23,
        "涨跌幅": -0.93,
        "开盘价": 24.5,
        "最高价": 24.66,
        "最低价": 24.35,
        "昨收价": 24.66,
        "总市值": 7329000.0,
        "市盈率": null,
        "成交量": 26167.0,
        "成交额": 641422.0,
        "振幅": 1.26,
        "换手率": 8.72,
        "symbol": "106.CFG_D"
    },
    {
        "序号": 10013,
        "name": "Tema Cardiovascular and Metabol",
        "最新价": 27.6,
        "涨跌额": -0.26,
        "涨跌幅": -0.93,
        "开盘价": 27.89,
        "最高价": 27.95,
        "最低价": 27.55,
        "昨收价": 27.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 15074.0,
        "成交额": 418148.0,
        "振幅": 1.44,
        "换手率": null,
        "symbol": "105.HRTS"
    },
    {
        "序号": 10014,
        "name": "First Trust Amex Biotech Index ",
        "最新价": 144.36,
        "涨跌额": -1.36,
        "涨跌幅": -0.93,
        "开盘价": 145.27,
        "最高价": 146.1,
        "最低价": 144.36,
        "昨收价": 145.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 17119.0,
        "成交额": 2484190.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "107.FBT"
    },
    {
        "序号": 10015,
        "name": "Atmos Energy Corp",
        "最新价": 113.46,
        "涨跌额": -1.07,
        "涨跌幅": -0.93,
        "开盘价": 114.45,
        "最高价": 114.48,
        "最低价": 112.96,
        "昨收价": 114.53,
        "总市值": 16848368414.0,
        "市盈率": 19.02,
        "成交量": 885521.0,
        "成交额": 100467429.0,
        "振幅": 1.33,
        "换手率": 0.6,
        "symbol": "106.ATO"
    },
    {
        "序号": 10016,
        "name": "Platinum Group Metals Ltd",
        "最新价": 1.06,
        "涨跌额": -0.01,
        "涨跌幅": -0.93,
        "开盘价": 1.06,
        "最高价": 1.06,
        "最低价": 1.03,
        "昨收价": 1.07,
        "总市值": 108519276.0,
        "市盈率": -19.16,
        "成交量": 121213.0,
        "成交额": 126797.0,
        "振幅": 2.8,
        "换手率": 0.12,
        "symbol": "107.PLG"
    },
    {
        "序号": 10017,
        "name": "First Trust Long Duration Oppor",
        "最新价": 21.2,
        "涨跌额": -0.2,
        "涨跌幅": -0.93,
        "开盘价": 21.33,
        "最高价": 21.36,
        "最低价": 21.14,
        "昨收价": 21.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 75870.0,
        "成交额": 1612277.0,
        "振幅": 1.03,
        "换手率": null,
        "symbol": "107.LGOV"
    },
    {
        "序号": 10018,
        "name": "Allspring Income Opportunities ",
        "最新价": 6.35,
        "涨跌额": -0.06,
        "涨跌幅": -0.94,
        "开盘价": 6.38,
        "最高价": 6.41,
        "最低价": 6.33,
        "昨收价": 6.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 267355.0,
        "成交额": 1700447.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.EAD"
    },
    {
        "序号": 10019,
        "name": "MainStay CBRE Global Infrastruc",
        "最新价": 12.69,
        "涨跌额": -0.12,
        "涨跌幅": -0.94,
        "开盘价": 12.82,
        "最高价": 12.85,
        "最低价": 12.66,
        "昨收价": 12.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 185717.0,
        "成交额": 2363301.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "106.MEGI"
    },
    {
        "序号": 10020,
        "name": "argenx SE ADR",
        "最新价": 453.48,
        "涨跌额": -4.29,
        "涨跌幅": -0.94,
        "开盘价": 462.2,
        "最高价": 462.2,
        "最低价": 452.29,
        "昨收价": 457.77,
        "总市值": 26266925214.0,
        "市盈率": -112.01,
        "成交量": 157275.0,
        "成交额": 71569085.0,
        "振幅": 2.16,
        "换手率": 0.27,
        "symbol": "105.ARGX"
    },
    {
        "序号": 10021,
        "name": "Columbia India Consumer ETF",
        "最新价": 58.09,
        "涨跌额": -0.55,
        "涨跌幅": -0.94,
        "开盘价": 58.14,
        "最高价": 58.15,
        "最低价": 57.9,
        "昨收价": 58.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 27243.0,
        "成交额": 1581376.0,
        "振幅": 0.43,
        "换手率": null,
        "symbol": "107.INCO"
    },
    {
        "序号": 10022,
        "name": "韩国SK电信",
        "最新价": 21.11,
        "涨跌额": -0.2,
        "涨跌幅": -0.94,
        "开盘价": 21.21,
        "最高价": 21.26,
        "最低价": 21.07,
        "昨收价": 21.31,
        "总市值": 8301542367.0,
        "市盈率": 10.73,
        "成交量": 341323.0,
        "成交额": 7211945.0,
        "振幅": 0.89,
        "换手率": 0.09,
        "symbol": "106.SKM"
    },
    {
        "序号": 10023,
        "name": "Global X MSCI China Consumer St",
        "最新价": 16.83,
        "涨跌额": -0.16,
        "涨跌幅": -0.94,
        "开盘价": 16.96,
        "最高价": 16.96,
        "最低价": 16.83,
        "昨收价": 16.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 586.0,
        "成交额": 9897.0,
        "振幅": 0.77,
        "换手率": null,
        "symbol": "107.CHIS"
    },
    {
        "序号": 10024,
        "name": "卡博特",
        "最新价": 77.78,
        "涨跌额": -0.74,
        "涨跌幅": -0.94,
        "开盘价": 78.41,
        "最高价": 79.0,
        "最低价": 77.46,
        "昨收价": 78.52,
        "总市值": 4301938531.0,
        "市盈率": 9.67,
        "成交量": 236666.0,
        "成交额": 18423781.0,
        "振幅": 1.96,
        "换手率": 0.43,
        "symbol": "106.CBT"
    },
    {
        "序号": 10025,
        "name": "Zenvia Inc-A",
        "最新价": 1.05,
        "涨跌额": -0.01,
        "涨跌幅": -0.94,
        "开盘价": 1.09,
        "最高价": 1.14,
        "最低价": 1.05,
        "昨收价": 1.06,
        "总市值": 43978694.0,
        "市盈率": -1.2,
        "成交量": 30299.0,
        "成交额": 32811.0,
        "振幅": 8.49,
        "换手率": 0.07,
        "symbol": "105.ZENV"
    },
    {
        "序号": 10026,
        "name": "POET Technologies Inc",
        "最新价": 1.05,
        "涨跌额": -0.01,
        "涨跌幅": -0.94,
        "开盘价": 1.08,
        "最高价": 1.08,
        "最低价": 1.02,
        "昨收价": 1.06,
        "总市值": 44417147.0,
        "市盈率": -2.11,
        "成交量": 110224.0,
        "成交额": 114810.0,
        "振幅": 5.66,
        "换手率": 0.26,
        "symbol": "105.POET"
    },
    {
        "序号": 10027,
        "name": "Reynolds Consumer Products Inc",
        "最新价": 26.24,
        "涨跌额": -0.25,
        "涨跌幅": -0.94,
        "开盘价": 26.44,
        "最高价": 26.53,
        "最低价": 26.24,
        "昨收价": 26.49,
        "总市值": 5510642221.0,
        "市盈率": 20.64,
        "成交量": 204807.0,
        "成交额": 5385326.0,
        "振幅": 1.09,
        "换手率": 0.1,
        "symbol": "105.REYN"
    },
    {
        "序号": 10028,
        "name": "味好美",
        "最新价": 67.09,
        "涨跌额": -0.64,
        "涨跌幅": -0.94,
        "开盘价": 67.91,
        "最高价": 68.0,
        "最低价": 66.79,
        "昨收价": 67.73,
        "总市值": 18002380596.0,
        "市盈率": 27.75,
        "成交量": 991861.0,
        "成交额": 66607428.0,
        "振幅": 1.79,
        "换手率": 0.39,
        "symbol": "106.MKC"
    },
    {
        "序号": 10029,
        "name": "ETFMG Prime Junior Silver Miner",
        "最新价": 9.43,
        "涨跌额": -0.09,
        "涨跌幅": -0.95,
        "开盘价": 9.38,
        "最高价": 9.59,
        "最低价": 9.31,
        "昨收价": 9.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 1205273.0,
        "成交额": 11362825.0,
        "振幅": 2.94,
        "换手率": null,
        "symbol": "107.SILJ"
    },
    {
        "序号": 10030,
        "name": "CompX国际",
        "最新价": 23.03,
        "涨跌额": -0.22,
        "涨跌幅": -0.95,
        "开盘价": 23.22,
        "最高价": 23.44,
        "最低价": 22.7,
        "昨收价": 23.25,
        "总市值": 283585824.0,
        "市盈率": 13.73,
        "成交量": 4894.0,
        "成交额": 113139.0,
        "振幅": 3.18,
        "换手率": 0.04,
        "symbol": "107.CIX"
    },
    {
        "序号": 10031,
        "name": "US Bancorp Series O Pfd",
        "最新价": 18.83,
        "涨跌额": -0.18,
        "涨跌幅": -0.95,
        "开盘价": 18.95,
        "最高价": 19.05,
        "最低价": 18.81,
        "昨收价": 19.01,
        "总市值": 338940.0,
        "市盈率": null,
        "成交量": 33874.0,
        "成交额": 640926.0,
        "振幅": 1.26,
        "换手率": 188.19,
        "symbol": "106.USB_S"
    },
    {
        "序号": 10032,
        "name": "拼多多",
        "最新价": 138.96,
        "涨跌额": -1.33,
        "涨跌幅": -0.95,
        "开盘价": 138.5,
        "最高价": 139.26,
        "最低价": 136.22,
        "昨收价": 140.29,
        "总市值": 184620463277.0,
        "市盈率": 28.25,
        "成交量": 11905761.0,
        "成交额": 1649050624.0,
        "振幅": 2.17,
        "换手率": 0.9,
        "symbol": "105.PDD"
    },
    {
        "序号": 10033,
        "name": "英诺斯派材料",
        "最新价": 109.58,
        "涨跌额": -1.05,
        "涨跌幅": -0.95,
        "开盘价": 110.3,
        "最高价": 111.55,
        "最低价": 108.79,
        "昨收价": 110.63,
        "总市值": 2724794254.0,
        "市盈率": 21.49,
        "成交量": 62476.0,
        "成交额": 6860069.0,
        "振幅": 2.49,
        "换手率": 0.25,
        "symbol": "105.IOSP"
    },
    {
        "序号": 10034,
        "name": "SMX (Security Matters) PLC-A",
        "最新价": 1.04,
        "涨跌额": -0.01,
        "涨跌幅": -0.95,
        "开盘价": 1.06,
        "最高价": 1.07,
        "最低价": 1.02,
        "昨收价": 1.05,
        "总市值": 4103924.0,
        "市盈率": null,
        "成交量": 63688.0,
        "成交额": 66175.0,
        "振幅": 4.76,
        "换手率": 1.61,
        "symbol": "105.SMX"
    },
    {
        "序号": 10035,
        "name": "Rigetti Computing Inc",
        "最新价": 1.04,
        "涨跌额": -0.01,
        "涨跌幅": -0.95,
        "开盘价": 1.04,
        "最高价": 1.08,
        "最低价": 1.01,
        "昨收价": 1.05,
        "总市值": 146204632.0,
        "市盈率": -1.71,
        "成交量": 2255839.0,
        "成交额": 2345385.0,
        "振幅": 6.67,
        "换手率": 1.6,
        "symbol": "105.RGTI"
    },
    {
        "序号": 10036,
        "name": "Natural Grocers by Vitamin Cott",
        "最新价": 16.64,
        "涨跌额": -0.16,
        "涨跌幅": -0.95,
        "开盘价": 16.87,
        "最高价": 16.87,
        "最低价": 16.37,
        "昨收价": 16.8,
        "总市值": 378600152.0,
        "市盈率": 16.29,
        "成交量": 61854.0,
        "成交额": 1026855.0,
        "振幅": 2.98,
        "换手率": 0.27,
        "symbol": "106.NGVC"
    },
    {
        "序号": 10037,
        "name": "Coeptis Therapeutics Holdings I",
        "最新价": 1.04,
        "涨跌额": -0.01,
        "涨跌幅": -0.95,
        "开盘价": 1.05,
        "最高价": 1.08,
        "最低价": 1.0,
        "昨收价": 1.05,
        "总市值": 35472357.0,
        "市盈率": -1.76,
        "成交量": 65228.0,
        "成交额": 67695.0,
        "振幅": 7.62,
        "换手率": 0.19,
        "symbol": "105.COEP"
    },
    {
        "序号": 10038,
        "name": "Akari Therapeutics PLC ADR",
        "最新价": 3.12,
        "涨跌额": -0.03,
        "涨跌幅": -0.95,
        "开盘价": 3.16,
        "最高价": 3.26,
        "最低价": 3.06,
        "昨收价": 3.15,
        "总市值": 17637287.0,
        "市盈率": -1.78,
        "成交量": 6794.0,
        "成交额": 21580.0,
        "振幅": 6.35,
        "换手率": 0.12,
        "symbol": "105.AKTX"
    },
    {
        "序号": 10039,
        "name": "可口可乐欧洲太平洋合伙(US)",
        "最新价": 63.41,
        "涨跌额": -0.61,
        "涨跌幅": -0.95,
        "开盘价": 63.58,
        "最高价": 63.89,
        "最低价": 63.21,
        "昨收价": 64.02,
        "总市值": 29109041904.0,
        "市盈率": 15.83,
        "成交量": 1424552.0,
        "成交额": 90442425.0,
        "振幅": 1.06,
        "换手率": 0.31,
        "symbol": "105.CCEP"
    },
    {
        "序号": 10040,
        "name": "Vasta Platform Ltd-A",
        "最新价": 4.15,
        "涨跌额": -0.04,
        "涨跌幅": -0.95,
        "开盘价": 4.12,
        "最高价": 4.16,
        "最低价": 4.1,
        "昨收价": 4.19,
        "总市值": 342666276.0,
        "市盈率": -22.42,
        "成交量": 6748.0,
        "成交额": 27987.0,
        "振幅": 1.43,
        "换手率": 0.01,
        "symbol": "105.VSTA"
    },
    {
        "序号": 10041,
        "name": "The Hartford Financial Services",
        "最新价": 24.86,
        "涨跌额": -0.24,
        "涨跌幅": -0.96,
        "开盘价": 24.93,
        "最高价": 25.0,
        "最低价": 24.74,
        "昨收价": 25.1,
        "总市值": 343068.0,
        "市盈率": null,
        "成交量": 28565.0,
        "成交额": 710461.0,
        "振幅": 1.04,
        "换手率": 206.99,
        "symbol": "106.HIG_G"
    },
    {
        "序号": 10042,
        "name": "BlackRock Investment Quality Mu",
        "最新价": 11.39,
        "涨跌额": -0.11,
        "涨跌幅": -0.96,
        "开盘价": 11.42,
        "最高价": 11.5,
        "最低价": 11.32,
        "昨收价": 11.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 84483.0,
        "成交额": 961863.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "106.BKN"
    },
    {
        "序号": 10043,
        "name": "杰龙",
        "最新价": 2.07,
        "涨跌额": -0.02,
        "涨跌幅": -0.96,
        "开盘价": 2.07,
        "最高价": 2.1,
        "最低价": 2.02,
        "昨收价": 2.09,
        "总市值": 1124142377.0,
        "市盈率": -6.43,
        "成交量": 3457201.0,
        "成交额": 7131007.0,
        "振幅": 3.83,
        "换手率": 0.64,
        "symbol": "105.GERN"
    },
    {
        "序号": 10044,
        "name": "Arcturus Therapeutics Holdings ",
        "最新价": 23.79,
        "涨跌额": -0.23,
        "涨跌幅": -0.96,
        "开盘价": 24.4,
        "最高价": 24.7,
        "最低价": 23.75,
        "昨收价": 24.02,
        "总市值": 635748068.0,
        "市盈率": 6.4,
        "成交量": 329477.0,
        "成交额": 7900554.0,
        "振幅": 3.96,
        "换手率": 1.23,
        "symbol": "105.ARCT"
    },
    {
        "序号": 10045,
        "name": "Goosehead Insurance Inc-A",
        "最新价": 71.2,
        "涨跌额": -0.69,
        "涨跌幅": -0.96,
        "开盘价": 71.17,
        "最高价": 72.42,
        "最低价": 69.98,
        "昨收价": 71.89,
        "总市值": 2695991418.0,
        "市盈率": 244.65,
        "成交量": 300230.0,
        "成交额": 21192285.0,
        "振幅": 3.39,
        "换手率": 0.79,
        "symbol": "105.GSHD"
    },
    {
        "序号": 10046,
        "name": "Sprott Junior Gold Miners ETF",
        "最新价": 28.89,
        "涨跌额": -0.28,
        "涨跌幅": -0.96,
        "开盘价": 28.61,
        "最高价": 29.2,
        "最低价": 28.44,
        "昨收价": 29.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 26936.0,
        "成交额": 773915.0,
        "振幅": 2.61,
        "换手率": null,
        "symbol": "107.SGDJ"
    },
    {
        "序号": 10047,
        "name": "Quest Resource Holding Corp",
        "最新价": 6.19,
        "涨跌额": -0.06,
        "涨跌幅": -0.96,
        "开盘价": 6.29,
        "最高价": 6.84,
        "最低价": 6.16,
        "昨收价": 6.25,
        "总市值": 124171072.0,
        "市盈率": -14.98,
        "成交量": 104014.0,
        "成交额": 660520.0,
        "振幅": 10.88,
        "换手率": 0.52,
        "symbol": "105.QRHC"
    },
    {
        "序号": 10048,
        "name": "Schrodinger Inc",
        "最新价": 31.95,
        "涨跌额": -0.31,
        "涨跌幅": -0.96,
        "开盘价": 31.75,
        "最高价": 32.49,
        "最低价": 31.35,
        "昨收价": 32.26,
        "总市值": 2301446842.0,
        "市盈率": 52.09,
        "成交量": 532083.0,
        "成交额": 16960625.0,
        "振幅": 3.53,
        "换手率": 0.74,
        "symbol": "105.SDGR"
    },
    {
        "序号": 10049,
        "name": "Kenvue Inc",
        "最新价": 20.61,
        "涨跌额": -0.2,
        "涨跌幅": -0.96,
        "开盘价": 20.88,
        "最高价": 21.0,
        "最低价": 20.6,
        "昨收价": 20.81,
        "总市值": 39468048702.0,
        "市盈率": 18.55,
        "成交量": 24535970.0,
        "成交额": 509188976.0,
        "振幅": 1.92,
        "换手率": 1.28,
        "symbol": "106.KVUE"
    },
    {
        "序号": 10050,
        "name": "Pioneer High Income Trust",
        "最新价": 7.21,
        "涨跌额": -0.07,
        "涨跌幅": -0.96,
        "开盘价": 7.24,
        "最高价": 7.31,
        "最低价": 7.17,
        "昨收价": 7.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 114414.0,
        "成交额": 826855.0,
        "振幅": 1.92,
        "换手率": null,
        "symbol": "106.PHT"
    },
    {
        "序号": 10051,
        "name": "Alithya Group inc-A",
        "最新价": 1.03,
        "涨跌额": -0.01,
        "涨跌幅": -0.96,
        "开盘价": 1.02,
        "最高价": 1.03,
        "最低价": 1.01,
        "昨收价": 1.04,
        "总市值": 98508952.0,
        "市盈率": -3.16,
        "成交量": 62622.0,
        "成交额": 63645.0,
        "振幅": 1.92,
        "换手率": 0.07,
        "symbol": "105.ALYA"
    },
    {
        "序号": 10052,
        "name": "爱迪生联合电气",
        "最新价": 91.55,
        "涨跌额": -0.89,
        "涨跌幅": -0.96,
        "开盘价": 92.39,
        "最高价": 92.53,
        "最低价": 90.86,
        "昨收价": 92.44,
        "总市值": 31604870767.0,
        "市盈率": 13.31,
        "成交量": 1910749.0,
        "成交额": 174766278.0,
        "振幅": 1.81,
        "换手率": 0.55,
        "symbol": "106.ED"
    },
    {
        "序号": 10053,
        "name": "Pediatrix Medical Group Inc",
        "最新价": 9.25,
        "涨跌额": -0.09,
        "涨跌幅": -0.96,
        "开盘价": 9.18,
        "最高价": 9.32,
        "最低价": 9.15,
        "昨收价": 9.34,
        "总市值": 777221223.0,
        "市盈率": 8.31,
        "成交量": 493707.0,
        "成交额": 4564042.0,
        "振幅": 1.82,
        "换手率": 0.59,
        "symbol": "106.MD"
    },
    {
        "序号": 10054,
        "name": "Vizio Holding Corp-A",
        "最新价": 7.19,
        "涨跌额": -0.07,
        "涨跌幅": -0.96,
        "开盘价": 7.19,
        "最高价": 7.34,
        "最低价": 7.15,
        "昨收价": 7.26,
        "总市值": 1417843410.0,
        "市盈率": 66.57,
        "成交量": 500685.0,
        "成交额": 3610004.0,
        "振幅": 2.62,
        "换手率": 0.25,
        "symbol": "106.VZIO"
    },
    {
        "序号": 10055,
        "name": "RGC Resources Inc",
        "最新价": 20.54,
        "涨跌额": -0.2,
        "涨跌幅": -0.96,
        "开盘价": 20.59,
        "最高价": 20.95,
        "最低价": 20.44,
        "昨收价": 20.74,
        "总市值": 206052391.0,
        "市盈率": 18.24,
        "成交量": 17788.0,
        "成交额": 367461.0,
        "振幅": 2.46,
        "换手率": 0.18,
        "symbol": "105.RGCO"
    },
    {
        "序号": 10056,
        "name": "ACRES Commercial Realty Corp Se",
        "最新价": 20.5,
        "涨跌额": -0.2,
        "涨跌幅": -0.97,
        "开盘价": 20.66,
        "最高价": 20.75,
        "最低价": 20.2,
        "昨收价": 20.7,
        "总市值": 94461069.0,
        "市盈率": null,
        "成交量": 3375.0,
        "成交额": 69127.0,
        "振幅": 2.66,
        "换手率": 0.07,
        "symbol": "106.ACR_D"
    },
    {
        "序号": 10057,
        "name": "米德尔赛克斯水务公司",
        "最新价": 67.59,
        "涨跌额": -0.66,
        "涨跌幅": -0.97,
        "开盘价": 68.35,
        "最高价": 69.36,
        "最低价": 67.32,
        "昨收价": 68.25,
        "总市值": 1203554583.0,
        "市盈率": 36.55,
        "成交量": 64869.0,
        "成交额": 4393083.0,
        "振幅": 2.99,
        "换手率": 0.36,
        "symbol": "105.MSEX"
    },
    {
        "序号": 10058,
        "name": "通用配件",
        "最新价": 132.98,
        "涨跌额": -1.3,
        "涨跌幅": -0.97,
        "开盘价": 134.8,
        "最高价": 135.48,
        "最低价": 132.8,
        "昨收价": 134.28,
        "总市值": 18643347990.0,
        "市盈率": 14.9,
        "成交量": 1188424.0,
        "成交额": 158527595.0,
        "振幅": 2.0,
        "换手率": 0.85,
        "symbol": "106.GPC"
    },
    {
        "序号": 10059,
        "name": "Penns Woods Bancorp Inc",
        "最新价": 20.4,
        "涨跌额": -0.2,
        "涨跌幅": -0.97,
        "开盘价": 20.48,
        "最高价": 20.54,
        "最低价": 20.33,
        "昨收价": 20.6,
        "总市值": 146136420.0,
        "市盈率": 9.39,
        "成交量": 53057.0,
        "成交额": 1084029.0,
        "振幅": 1.02,
        "换手率": 0.74,
        "symbol": "105.PWOD"
    },
    {
        "序号": 10060,
        "name": "LQR House Inc",
        "最新价": 1.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.97,
        "开盘价": 1.07,
        "最高价": 1.07,
        "最低价": 1.0,
        "昨收价": 1.03,
        "总市值": 3431291.0,
        "市盈率": -0.36,
        "成交量": 435492.0,
        "成交额": 450026.0,
        "振幅": 6.8,
        "换手率": 12.95,
        "symbol": "105.LQR"
    },
    {
        "序号": 10061,
        "name": "Inspira Technologies Oxy B.H.N.",
        "最新价": 1.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.97,
        "开盘价": 1.02,
        "最高价": 1.03,
        "最低价": 0.99,
        "昨收价": 1.03,
        "总市值": 12233165.0,
        "市盈率": -1.07,
        "成交量": 70189.0,
        "成交额": 70814.0,
        "振幅": 3.88,
        "换手率": 0.59,
        "symbol": "105.IINN"
    },
    {
        "序号": 10062,
        "name": "中比能源",
        "最新价": 1.02,
        "涨跌额": -0.01,
        "涨跌幅": -0.97,
        "开盘价": 1.03,
        "最高价": 1.05,
        "最低价": 1.01,
        "昨收价": 1.03,
        "总市值": 91276934.0,
        "市盈率": -10.89,
        "成交量": 60403.0,
        "成交额": 62474.0,
        "振幅": 3.88,
        "换手率": 0.07,
        "symbol": "105.CBAT"
    },
    {
        "序号": 10063,
        "name": "Guidewire Software Inc",
        "最新价": 97.89,
        "涨跌额": -0.96,
        "涨跌幅": -0.97,
        "开盘价": 100.55,
        "最高价": 101.74,
        "最低价": 96.89,
        "昨收价": 98.85,
        "总市值": 8020181148.0,
        "市盈率": -115.22,
        "成交量": 1221496.0,
        "成交额": 120481036.0,
        "振幅": 4.91,
        "换手率": 1.49,
        "symbol": "106.GWRE"
    },
    {
        "序号": 10064,
        "name": "Invesco Dynamic Biotechnology &",
        "最新价": 60.07,
        "涨跌额": -0.59,
        "涨跌幅": -0.97,
        "开盘价": 60.42,
        "最高价": 60.97,
        "最低价": 60.07,
        "昨收价": 60.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 53816.0,
        "成交额": 3250735.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "107.PBE"
    },
    {
        "序号": 10065,
        "name": "波士顿啤酒",
        "最新价": 346.03,
        "涨跌额": -3.4,
        "涨跌幅": -0.97,
        "开盘价": 348.52,
        "最高价": 351.63,
        "最低价": 345.62,
        "昨收价": 349.43,
        "总市值": 4218469724.0,
        "市盈率": 50.85,
        "成交量": 58207.0,
        "成交额": 20179054.0,
        "振幅": 1.72,
        "换手率": 0.48,
        "symbol": "106.SAM"
    },
    {
        "序号": 10066,
        "name": "QuidelOrtho Corp",
        "最新价": 66.02,
        "涨跌额": -0.65,
        "涨跌幅": -0.97,
        "开盘价": 66.73,
        "最高价": 67.36,
        "最低价": 65.82,
        "昨收价": 66.67,
        "总市值": 4411371762.0,
        "市盈率": 334.19,
        "成交量": 710318.0,
        "成交额": 47029383.0,
        "振幅": 2.31,
        "换手率": 1.06,
        "symbol": "105.QDEL"
    },
    {
        "序号": 10067,
        "name": "KraneShares MSCI All China Inde",
        "最新价": 18.28,
        "涨跌额": -0.18,
        "涨跌幅": -0.98,
        "开盘价": 18.28,
        "最高价": 18.28,
        "最低价": 18.28,
        "昨收价": 18.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 115.0,
        "成交额": 2102.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KALL"
    },
    {
        "序号": 10068,
        "name": "ProShares MSCI Emerging Markets",
        "最新价": 42.51,
        "涨跌额": -0.42,
        "涨跌幅": -0.98,
        "开盘价": 42.6,
        "最高价": 42.6,
        "最低价": 42.5,
        "昨收价": 42.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 1663.0,
        "成交额": 70761.0,
        "振幅": 0.23,
        "换手率": null,
        "symbol": "107.EMDV"
    },
    {
        "序号": 10069,
        "name": "GoldMining Inc",
        "最新价": 1.01,
        "涨跌额": -0.01,
        "涨跌幅": -0.98,
        "开盘价": 1.01,
        "最高价": 1.03,
        "最低价": 1.0,
        "昨收价": 1.02,
        "总市值": 184662986.0,
        "市盈率": -9.47,
        "成交量": 509694.0,
        "成交额": 517089.0,
        "振幅": 2.94,
        "换手率": 0.28,
        "symbol": "107.GLDG"
    },
    {
        "序号": 10070,
        "name": "Sonoco Products Co",
        "最新价": 54.49,
        "涨跌额": -0.54,
        "涨跌幅": -0.98,
        "开盘价": 54.97,
        "最高价": 55.33,
        "最低价": 54.32,
        "昨收价": 55.03,
        "总市值": 5337642274.0,
        "市盈率": 10.87,
        "成交量": 384403.0,
        "成交额": 20990221.0,
        "振幅": 1.84,
        "换手率": 0.39,
        "symbol": "106.SON"
    },
    {
        "序号": 10071,
        "name": "Grocery Outlet Holding Corp",
        "最新价": 29.25,
        "涨跌额": -0.29,
        "涨跌幅": -0.98,
        "开盘价": 29.68,
        "最高价": 29.76,
        "最低价": 29.1,
        "昨收价": 29.54,
        "总市值": 2903625153.0,
        "市盈率": 35.75,
        "成交量": 496881.0,
        "成交额": 14570359.0,
        "振幅": 2.23,
        "换手率": 0.5,
        "symbol": "105.GO"
    },
    {
        "序号": 10072,
        "name": "中国工业指数ETF-Global X",
        "最新价": 10.08,
        "涨跌额": -0.1,
        "涨跌幅": -0.98,
        "开盘价": 10.08,
        "最高价": 10.08,
        "最低价": 10.08,
        "昨收价": 10.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 66.0,
        "成交额": 665.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CHII"
    },
    {
        "序号": 10073,
        "name": "ATAC US Rotation ETF",
        "最新价": 17.13,
        "涨跌额": -0.17,
        "涨跌幅": -0.98,
        "开盘价": 17.06,
        "最高价": 17.15,
        "最低价": 17.03,
        "昨收价": 17.3,
        "总市值": null,
        "市盈率": null,
        "成交量": 19248.0,
        "成交额": 329411.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.RORO"
    },
    {
        "序号": 10074,
        "name": "American Financial Group Inc No",
        "最新价": 20.15,
        "涨跌额": -0.2,
        "涨跌幅": -0.98,
        "开盘价": 20.15,
        "最高价": 20.4,
        "最低价": 20.06,
        "昨收价": 20.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 4764.0,
        "成交额": 96241.0,
        "振幅": 1.67,
        "换手率": null,
        "symbol": "106.AFGC"
    },
    {
        "序号": 10075,
        "name": "Neuberger Berman Real Estate Se",
        "最新价": 3.02,
        "涨跌额": -0.03,
        "涨跌幅": -0.98,
        "开盘价": 3.04,
        "最高价": 3.05,
        "最低价": 3.02,
        "昨收价": 3.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 119647.0,
        "成交额": 363149.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.NRO"
    },
    {
        "序号": 10076,
        "name": "美国天然气基金",
        "最新价": 5.02,
        "涨跌额": -0.05,
        "涨跌幅": -0.99,
        "开盘价": 5.08,
        "最高价": 5.14,
        "最低价": 5.0,
        "昨收价": 5.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 15711008.0,
        "成交额": 79559297.0,
        "振幅": 2.76,
        "换手率": null,
        "symbol": "107.UNG"
    },
    {
        "序号": 10077,
        "name": "品尼高西方资本",
        "最新价": 75.3,
        "涨跌额": -0.75,
        "涨跌幅": -0.99,
        "开盘价": 76.05,
        "最高价": 76.24,
        "最低价": 75.15,
        "昨收价": 76.05,
        "总市值": 8538895454.0,
        "市盈率": 17.88,
        "成交量": 1228794.0,
        "成交额": 92965435.0,
        "振幅": 1.43,
        "换手率": 1.08,
        "symbol": "106.PNW"
    },
    {
        "序号": 10078,
        "name": "JPMorgan BetaBuilders U.S. Trea",
        "最新价": 90.32,
        "涨跌额": -0.9,
        "涨跌幅": -0.99,
        "开盘价": 90.33,
        "最高价": 90.48,
        "最低价": 90.2,
        "昨收价": 91.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 1545.0,
        "成交额": 139561.0,
        "振幅": 0.31,
        "换手率": null,
        "symbol": "107.BBLB"
    },
    {
        "序号": 10079,
        "name": "Agree Realty Corp Series A Pfd",
        "最新价": 18.02,
        "涨跌额": -0.18,
        "涨跌幅": -0.99,
        "开盘价": 18.09,
        "最高价": 18.15,
        "最低价": 17.98,
        "昨收价": 18.2,
        "总市值": 126140.0,
        "市盈率": null,
        "成交量": 5192.0,
        "成交额": 93742.0,
        "振幅": 0.93,
        "换手率": 74.17,
        "symbol": "106.ADC_A"
    },
    {
        "序号": 10080,
        "name": "ICL Group Ltd",
        "最新价": 5.0,
        "涨跌额": -0.05,
        "涨跌幅": -0.99,
        "开盘价": 5.04,
        "最高价": 5.07,
        "最低价": 4.96,
        "昨收价": 5.05,
        "总市值": 6569229530.0,
        "市盈率": 4.67,
        "成交量": 556711.0,
        "成交额": 2786203.0,
        "振幅": 2.18,
        "换手率": 0.04,
        "symbol": "106.ICL"
    },
    {
        "序号": 10081,
        "name": "日元ETF-CurrencyShares",
        "最新价": 63.98,
        "涨跌额": -0.64,
        "涨跌幅": -0.99,
        "开盘价": 64.25,
        "最高价": 64.53,
        "最低价": 63.98,
        "昨收价": 64.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 188158.0,
        "成交额": 12067455.0,
        "振幅": 0.85,
        "换手率": null,
        "symbol": "107.FXY"
    },
    {
        "序号": 10082,
        "name": "瑞安航空(US ADR)",
        "最新价": 124.9,
        "涨跌额": -1.25,
        "涨跌幅": -0.99,
        "开盘价": 124.43,
        "最高价": 125.16,
        "最低价": 123.15,
        "昨收价": 126.15,
        "总市值": 28464735679.0,
        "市盈率": 11.43,
        "成交量": 910240.0,
        "成交额": 113205759.0,
        "振幅": 1.59,
        "换手率": 0.4,
        "symbol": "105.RYAAY"
    },
    {
        "序号": 10083,
        "name": "Vornado Realty Trust Series M P",
        "最新价": 14.95,
        "涨跌额": -0.15,
        "涨跌幅": -0.99,
        "开盘价": 15.0,
        "最高价": 15.09,
        "最低价": 14.73,
        "昨收价": 15.1,
        "总市值": 191061000.0,
        "市盈率": null,
        "成交量": 27074.0,
        "成交额": 403399.0,
        "振幅": 2.38,
        "换手率": 0.21,
        "symbol": "106.VNO_M"
    },
    {
        "序号": 10084,
        "name": "Talis Biomedical Corp",
        "最新价": 7.97,
        "涨跌额": -0.08,
        "涨跌幅": -0.99,
        "开盘价": 8.01,
        "最高价": 8.15,
        "最低价": 7.22,
        "昨收价": 8.05,
        "总市值": 14514390.0,
        "市盈率": -0.19,
        "成交量": 3523.0,
        "成交额": 27840.0,
        "振幅": 11.55,
        "换手率": 0.19,
        "symbol": "105.TLIS"
    },
    {
        "序号": 10085,
        "name": "AT&T Inc Series A Pfd",
        "最新价": 19.91,
        "涨跌额": -0.2,
        "涨跌幅": -0.99,
        "开盘价": 19.98,
        "最高价": 20.1,
        "最低价": 19.9,
        "昨收价": 20.11,
        "总市值": 955680.0,
        "市盈率": null,
        "成交量": 202625.0,
        "成交额": 4060150.0,
        "振幅": 0.99,
        "换手率": 422.14,
        "symbol": "106.T_A"
    },
    {
        "序号": 10086,
        "name": "Direxion Daily Transportation B",
        "最新价": 29.86,
        "涨跌额": -0.3,
        "涨跌幅": -0.99,
        "开盘价": 30.02,
        "最高价": 30.88,
        "最低价": 29.86,
        "昨收价": 30.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 17626.0,
        "成交额": 532785.0,
        "振幅": 3.38,
        "换手率": null,
        "symbol": "107.TPOR"
    },
    {
        "序号": 10087,
        "name": "美国电话电报",
        "最新价": 16.92,
        "涨跌额": -0.17,
        "涨跌幅": -0.99,
        "开盘价": 17.09,
        "最高价": 17.11,
        "最低价": 16.81,
        "昨收价": 17.09,
        "总市值": 120978340397.0,
        "市盈率": -10.7,
        "成交量": 42137245.0,
        "成交额": 713325328.0,
        "振幅": 1.76,
        "换手率": 0.59,
        "symbol": "106.T"
    },
    {
        "序号": 10088,
        "name": "美国无线电话",
        "最新价": 42.78,
        "涨跌额": -0.43,
        "涨跌幅": -1.0,
        "开盘价": 43.0,
        "最高价": 43.94,
        "最低价": 42.09,
        "昨收价": 43.21,
        "总市值": 3647953272.0,
        "市盈率": 304.0,
        "成交量": 210638.0,
        "成交额": 9029842.0,
        "振幅": 4.28,
        "换手率": 0.25,
        "symbol": "106.USM"
    },
    {
        "序号": 10089,
        "name": "ATS Corp",
        "最新价": 38.8,
        "涨跌额": -0.39,
        "涨跌幅": -1.0,
        "开盘价": 39.21,
        "最高价": 39.69,
        "最低价": 38.67,
        "昨收价": 39.19,
        "总市值": 3837315460.0,
        "市盈率": 32.76,
        "成交量": 37190.0,
        "成交额": 1450935.0,
        "振幅": 2.6,
        "换手率": 0.04,
        "symbol": "106.ATS"
    },
    {
        "序号": 10090,
        "name": "血管造影动力",
        "最新价": 6.96,
        "涨跌额": -0.07,
        "涨跌幅": -1.0,
        "开盘价": 7.01,
        "最高价": 7.13,
        "最低价": 6.81,
        "昨收价": 7.03,
        "总市值": 277332621.0,
        "市盈率": 43.02,
        "成交量": 301067.0,
        "成交额": 2101743.0,
        "振幅": 4.55,
        "换手率": 0.76,
        "symbol": "105.ANGO"
    },
    {
        "序号": 10091,
        "name": "Paymentus Holdings Inc-A",
        "最新价": 16.9,
        "涨跌额": -0.17,
        "涨跌幅": -1.0,
        "开盘价": 16.87,
        "最高价": 17.09,
        "最低价": 16.0,
        "昨收价": 17.07,
        "总市值": 2090485114.0,
        "市盈率": 150.64,
        "成交量": 240629.0,
        "成交额": 4027732.0,
        "振幅": 6.39,
        "换手率": 0.19,
        "symbol": "106.PAY"
    },
    {
        "序号": 10092,
        "name": "Eastern Bankshares Inc",
        "最新价": 12.92,
        "涨跌额": -0.13,
        "涨跌幅": -1.0,
        "开盘价": 13.06,
        "最高价": 13.1,
        "最低价": 12.82,
        "昨收价": 13.05,
        "总市值": 2279436750.0,
        "市盈率": -51.77,
        "成交量": 1487307.0,
        "成交额": 19269089.0,
        "振幅": 2.15,
        "换手率": 0.84,
        "symbol": "105.EBC"
    },
    {
        "序号": 10093,
        "name": "Global Net Lease Inc Series E P",
        "最新价": 19.87,
        "涨跌额": -0.2,
        "涨跌幅": -1.0,
        "开盘价": 20.12,
        "最高价": 20.25,
        "最低价": 19.71,
        "昨收价": 20.07,
        "总市值": 91306127.0,
        "市盈率": null,
        "成交量": 5099.0,
        "成交额": 102063.0,
        "振幅": 2.69,
        "换手率": 0.11,
        "symbol": "106.GNL_E"
    },
    {
        "序号": 10094,
        "name": "米德尔菲尔德银行",
        "最新价": 29.75,
        "涨跌额": -0.3,
        "涨跌幅": -1.0,
        "开盘价": 30.0,
        "最高价": 30.02,
        "最低价": 28.74,
        "昨收价": 30.05,
        "总市值": 240754136.0,
        "市盈率": 13.89,
        "成交量": 8607.0,
        "成交额": 256500.0,
        "振幅": 4.26,
        "换手率": 0.11,
        "symbol": "105.MBCN"
    },
    {
        "序号": 10095,
        "name": "FIGZ Inc-A",
        "最新价": 6.94,
        "涨跌额": -0.07,
        "涨跌幅": -1.0,
        "开盘价": 6.99,
        "最高价": 7.12,
        "最低价": 6.9,
        "昨收价": 7.01,
        "总市值": 1173796178.0,
        "市盈率": 73.23,
        "成交量": 1589881.0,
        "成交额": 11100660.0,
        "振幅": 3.14,
        "换手率": 0.94,
        "symbol": "106.FIGS"
    },
    {
        "序号": 10096,
        "name": "The China Fund",
        "最新价": 9.91,
        "涨跌额": -0.1,
        "涨跌幅": -1.0,
        "开盘价": 9.89,
        "最高价": 9.95,
        "最低价": 9.88,
        "昨收价": 10.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 15523.0,
        "成交额": 153789.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.CHN"
    },
    {
        "序号": 10097,
        "name": "Georgia Power Co Notes",
        "最新价": 22.78,
        "涨跌额": -0.23,
        "涨跌幅": -1.0,
        "开盘价": 23.0,
        "最高价": 23.12,
        "最低价": 22.76,
        "昨收价": 23.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 19167.0,
        "成交额": 437737.0,
        "振幅": 1.56,
        "换手率": null,
        "symbol": "106.GPJA"
    },
    {
        "序号": 10098,
        "name": "Franklin Wireless Corp",
        "最新价": 2.97,
        "涨跌额": -0.03,
        "涨跌幅": -1.0,
        "开盘价": 3.0,
        "最高价": 3.0,
        "最低价": 2.95,
        "昨收价": 3.0,
        "总市值": 34999312.0,
        "市盈率": -17.55,
        "成交量": 6749.0,
        "成交额": 20192.0,
        "振幅": 1.67,
        "换手率": 0.06,
        "symbol": "105.FKWL"
    },
    {
        "序号": 10099,
        "name": "阿拉斯加航空",
        "最新价": 35.61,
        "涨跌额": -0.36,
        "涨跌幅": -1.0,
        "开盘价": 35.96,
        "最高价": 36.08,
        "最低价": 35.42,
        "昨收价": 35.97,
        "总市值": 4559970072.0,
        "市盈率": 17.61,
        "成交量": 4252258.0,
        "成交额": 151839336.0,
        "振幅": 1.83,
        "换手率": 3.32,
        "symbol": "106.ALK"
    },
    {
        "序号": 10100,
        "name": "Delek Logistics Partners LP",
        "最新价": 49.42,
        "涨跌额": -0.5,
        "涨跌幅": -1.0,
        "开盘价": 49.6,
        "最高价": 50.5,
        "最低价": 48.74,
        "昨收价": 49.92,
        "总市值": 2154536262.0,
        "市盈率": 14.68,
        "成交量": 50286.0,
        "成交额": 2490568.0,
        "振幅": 3.53,
        "换手率": 0.12,
        "symbol": "106.DKL"
    },
    {
        "序号": 10101,
        "name": "The ONE Group Hospitality Inc",
        "最新价": 4.94,
        "涨跌额": -0.05,
        "涨跌幅": -1.0,
        "开盘价": 4.94,
        "最高价": 4.98,
        "最低价": 4.85,
        "昨收价": 4.99,
        "总市值": 154490314.0,
        "市盈率": 29.97,
        "成交量": 29346.0,
        "成交额": 144607.0,
        "振幅": 2.61,
        "换手率": 0.09,
        "symbol": "105.STKS"
    },
    {
        "序号": 10102,
        "name": "Voya Emerging Markets High Divi",
        "最新价": 4.94,
        "涨跌额": -0.05,
        "涨跌幅": -1.0,
        "开盘价": 4.97,
        "最高价": 4.98,
        "最低价": 4.94,
        "昨收价": 4.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 25975.0,
        "成交额": 128909.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "106.IHD"
    },
    {
        "序号": 10103,
        "name": "Western Asset Mortgage Opportun",
        "最新价": 10.82,
        "涨跌额": -0.11,
        "涨跌幅": -1.01,
        "开盘价": 10.86,
        "最高价": 10.89,
        "最低价": 10.76,
        "昨收价": 10.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 29527.0,
        "成交额": 319923.0,
        "振幅": 1.19,
        "换手率": null,
        "symbol": "106.DMO"
    },
    {
        "序号": 10104,
        "name": "Global Net Lease Inc Series A P",
        "最新价": 19.65,
        "涨跌额": -0.2,
        "涨跌幅": -1.01,
        "开盘价": 19.54,
        "最高价": 19.99,
        "最低价": 19.54,
        "昨收价": 19.85,
        "总市值": 133609527.0,
        "市盈率": null,
        "成交量": 8232.0,
        "成交额": 162799.0,
        "振幅": 2.27,
        "换手率": 0.12,
        "symbol": "106.GNL_A"
    },
    {
        "序号": 10105,
        "name": "Equitable Holdings Inc Series C",
        "最新价": 15.71,
        "涨跌额": -0.16,
        "涨跌幅": -1.01,
        "开盘价": 15.81,
        "最高价": 15.91,
        "最低价": 15.59,
        "昨收价": 15.87,
        "总市值": 188520.0,
        "市盈率": null,
        "成交量": 18211.0,
        "成交额": 286698.0,
        "振幅": 2.02,
        "换手率": 151.76,
        "symbol": "106.EQH_C"
    },
    {
        "序号": 10106,
        "name": "LegalZoom.com Inc",
        "最新价": 10.8,
        "涨跌额": -0.11,
        "涨跌幅": -1.01,
        "开盘价": 10.86,
        "最高价": 11.03,
        "最低价": 10.76,
        "昨收价": 10.91,
        "总市值": 2029687772.0,
        "市盈率": 244.1,
        "成交量": 955056.0,
        "成交额": 10333824.0,
        "振幅": 2.47,
        "换手率": 0.51,
        "symbol": "105.LZ"
    },
    {
        "序号": 10107,
        "name": "Corner Growth Acquisition Corp ",
        "最新价": 10.8,
        "涨跌额": -0.11,
        "涨跌幅": -1.01,
        "开盘价": 10.91,
        "最高价": 10.91,
        "最低价": 10.8,
        "昨收价": 10.91,
        "总市值": 112535330.0,
        "市盈率": -163.49,
        "成交量": 7255.0,
        "成交额": 78935.0,
        "振幅": 1.01,
        "换手率": null,
        "symbol": "105.COOLU"
    },
    {
        "序号": 10108,
        "name": "Orion Engineered Carbons SA",
        "最新价": 25.52,
        "涨跌额": -0.26,
        "涨跌幅": -1.01,
        "开盘价": 25.75,
        "最高价": 26.24,
        "最低价": 25.16,
        "昨收价": 25.78,
        "总市值": 1481286453.0,
        "市盈率": 13.37,
        "成交量": 236293.0,
        "成交额": 6035065.0,
        "振幅": 4.19,
        "换手率": 0.41,
        "symbol": "106.OEC"
    },
    {
        "序号": 10109,
        "name": "AXIS Capital Holdings Ltd Serie",
        "最新价": 20.6,
        "涨跌额": -0.21,
        "涨跌幅": -1.01,
        "开盘价": 20.85,
        "最高价": 20.85,
        "最低价": 20.49,
        "昨收价": 20.81,
        "总市值": 4944000.0,
        "市盈率": null,
        "成交量": 25801.0,
        "成交额": 533103.0,
        "振幅": 1.73,
        "换手率": 10.75,
        "symbol": "106.AXS_E"
    },
    {
        "序号": 10110,
        "name": "Cambria Tail Risk ETF",
        "最新价": 12.74,
        "涨跌额": -0.13,
        "涨跌幅": -1.01,
        "开盘价": 12.76,
        "最高价": 12.81,
        "最低价": 12.71,
        "昨收价": 12.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 30784.0,
        "成交额": 392273.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "107.TAIL"
    },
    {
        "序号": 10111,
        "name": "CompoSecure Inc-A",
        "最新价": 4.9,
        "涨跌额": -0.05,
        "涨跌幅": -1.01,
        "开盘价": 4.99,
        "最高价": 5.01,
        "最低价": 4.86,
        "昨收价": 4.95,
        "总市值": 388644681.0,
        "市盈率": 20.44,
        "成交量": 66968.0,
        "成交额": 328819.0,
        "振幅": 3.03,
        "换手率": 0.08,
        "symbol": "105.CMPO"
    },
    {
        "序号": 10112,
        "name": "淡水河谷",
        "最新价": 14.68,
        "涨跌额": -0.15,
        "涨跌幅": -1.01,
        "开盘价": 14.68,
        "最高价": 14.87,
        "最低价": 14.67,
        "昨收价": 14.83,
        "总市值": 63165900948.0,
        "市盈率": 6.8,
        "成交量": 16258336.0,
        "成交额": 240014930.0,
        "振幅": 1.35,
        "换手率": 0.38,
        "symbol": "106.VALE"
    },
    {
        "序号": 10113,
        "name": "Enterprise Bancorp Inc",
        "最新价": 29.35,
        "涨跌额": -0.3,
        "涨跌幅": -1.01,
        "开盘价": 29.57,
        "最高价": 29.68,
        "最低价": 28.23,
        "昨收价": 29.65,
        "总市值": 359742891.0,
        "市盈率": 8.47,
        "成交量": 13554.0,
        "成交额": 395711.0,
        "振幅": 4.89,
        "换手率": 0.11,
        "symbol": "105.EBTC"
    },
    {
        "序号": 10114,
        "name": "Trio-Tech International",
        "最新价": 4.88,
        "涨跌额": -0.05,
        "涨跌幅": -1.01,
        "开盘价": 4.86,
        "最高价": 4.99,
        "最低价": 4.86,
        "昨收价": 4.93,
        "总市值": 19995458.0,
        "市盈率": 22.42,
        "成交量": 7134.0,
        "成交额": 35136.0,
        "振幅": 2.64,
        "换手率": 0.17,
        "symbol": "107.TRT"
    },
    {
        "序号": 10115,
        "name": "Cadre Holdings Inc",
        "最新价": 32.19,
        "涨跌额": -0.33,
        "涨跌幅": -1.01,
        "开盘价": 32.46,
        "最高价": 32.68,
        "最低价": 31.88,
        "昨收价": 32.52,
        "总市值": 1209894338.0,
        "市盈率": 33.94,
        "成交量": 155913.0,
        "成交额": 5026846.0,
        "振幅": 2.46,
        "换手率": 0.41,
        "symbol": "106.CDRE"
    },
    {
        "序号": 10116,
        "name": "骇维金属加工",
        "最新价": 1.95,
        "涨跌额": -0.02,
        "涨跌幅": -1.02,
        "开盘价": 1.95,
        "最高价": 1.96,
        "最低价": 1.95,
        "昨收价": 1.97,
        "总市值": 7979059.0,
        "市盈率": -6.64,
        "成交量": 3325.0,
        "成交额": 6505.0,
        "振幅": 0.51,
        "换手率": 0.08,
        "symbol": "105.HIHO"
    },
    {
        "序号": 10117,
        "name": "Old National Bancorp Series A P",
        "最新价": 24.35,
        "涨跌额": -0.25,
        "涨跌幅": -1.02,
        "开盘价": 24.7,
        "最高价": 24.7,
        "最低价": 24.25,
        "昨收价": 24.6,
        "总市值": 2629800.0,
        "市盈率": null,
        "成交量": 5592.0,
        "成交额": 136646.0,
        "振幅": 1.83,
        "换手率": 5.18,
        "symbol": "105.ONBPO"
    },
    {
        "序号": 10118,
        "name": "华迪国际",
        "最新价": 2.92,
        "涨跌额": -0.03,
        "涨跌幅": -1.02,
        "开盘价": 3.04,
        "最高价": 3.04,
        "最低价": 2.82,
        "昨收价": 2.95,
        "总市值": 41636811.0,
        "市盈率": 12.36,
        "成交量": 46198.0,
        "成交额": 133048.0,
        "振幅": 7.46,
        "换手率": 0.32,
        "symbol": "105.HUDI"
    },
    {
        "序号": 10119,
        "name": "Datadog Inc-A",
        "最新价": 113.83,
        "涨跌额": -1.17,
        "涨跌幅": -1.02,
        "开盘价": 113.28,
        "最高价": 114.39,
        "最低价": 112.25,
        "昨收价": 115.0,
        "总市值": 37399264939.0,
        "市盈率": -1085.33,
        "成交量": 3127618.0,
        "成交额": 354917984.0,
        "振幅": 1.86,
        "换手率": 0.95,
        "symbol": "105.DDOG"
    },
    {
        "序号": 10120,
        "name": "Healthcare Trust Inc Series A P",
        "最新价": 12.62,
        "涨跌额": -0.13,
        "涨跌幅": -1.02,
        "开盘价": 12.65,
        "最高价": 13.22,
        "最低价": 12.61,
        "昨收价": 12.75,
        "总市值": 59818800.0,
        "市盈率": null,
        "成交量": 15275.0,
        "成交额": 194593.0,
        "振幅": 4.78,
        "换手率": 0.32,
        "symbol": "105.HTIA"
    },
    {
        "序号": 10121,
        "name": "Ascendis Pharma AS ADR",
        "最新价": 107.69,
        "涨跌额": -1.11,
        "涨跌幅": -1.02,
        "开盘价": 107.67,
        "最高价": 111.41,
        "最低价": 106.49,
        "昨收价": 108.8,
        "总市值": 6209035808.0,
        "市盈率": -9.29,
        "成交量": 1471632.0,
        "成交额": 160056642.0,
        "振幅": 4.52,
        "换手率": 2.55,
        "symbol": "105.ASND"
    },
    {
        "序号": 10122,
        "name": "联合利华(英国)",
        "最新价": 47.45,
        "涨跌额": -0.49,
        "涨跌幅": -1.02,
        "开盘价": 47.67,
        "最高价": 47.77,
        "最低价": 47.31,
        "昨收价": 47.94,
        "总市值": 118576798060.0,
        "市盈率": 13.21,
        "成交量": 2264965.0,
        "成交额": 107619638.0,
        "振幅": 0.96,
        "换手率": 0.09,
        "symbol": "106.UL"
    },
    {
        "序号": 10123,
        "name": "Science Applications Internatio",
        "最新价": 127.67,
        "涨跌额": -1.32,
        "涨跌幅": -1.02,
        "开盘价": 129.38,
        "最高价": 129.82,
        "最低价": 127.27,
        "昨收价": 128.99,
        "总市值": 6646825248.0,
        "市盈率": 12.98,
        "成交量": 390313.0,
        "成交额": 49959971.0,
        "振幅": 1.98,
        "换手率": 0.75,
        "symbol": "106.SAIC"
    },
    {
        "序号": 10124,
        "name": "Werewolf Therapeutics Inc",
        "最新价": 2.9,
        "涨跌额": -0.03,
        "涨跌幅": -1.02,
        "开盘价": 2.88,
        "最高价": 2.96,
        "最低价": 2.85,
        "昨收价": 2.93,
        "总市值": 104957519.0,
        "市盈率": -2.81,
        "成交量": 33774.0,
        "成交额": 98316.0,
        "振幅": 3.75,
        "换手率": 0.09,
        "symbol": "105.HOWL"
    },
    {
        "序号": 10125,
        "name": "Brookfield Property Partners LP",
        "最新价": 12.56,
        "涨跌额": -0.13,
        "涨跌幅": -1.02,
        "开盘价": 12.56,
        "最高价": 12.79,
        "最低价": 12.56,
        "昨收价": 12.69,
        "总市值": 125600000.0,
        "市盈率": null,
        "成交量": 23957.0,
        "成交额": 303802.0,
        "振幅": 1.81,
        "换手率": 0.24,
        "symbol": "105.BPYPO"
    },
    {
        "序号": 10126,
        "name": "First Horizon Corp Series E Pfd",
        "最新价": 22.2,
        "涨跌额": -0.23,
        "涨跌幅": -1.03,
        "开盘价": 22.4,
        "最高价": 22.4,
        "最低价": 21.98,
        "昨收价": 22.43,
        "总市值": 33300.0,
        "市盈率": null,
        "成交量": 10697.0,
        "成交额": 237814.0,
        "振幅": 1.87,
        "换手率": 713.13,
        "symbol": "106.FHN_E"
    },
    {
        "序号": 10127,
        "name": "哈尼斯品牌服装",
        "最新价": 3.86,
        "涨跌额": -0.04,
        "涨跌幅": -1.03,
        "开盘价": 3.88,
        "最高价": 3.92,
        "最低价": 3.79,
        "昨收价": 3.9,
        "总市值": 1351156415.0,
        "市盈率": -2.63,
        "成交量": 5901552.0,
        "成交额": 22754572.0,
        "振幅": 3.33,
        "换手率": 1.69,
        "symbol": "106.HBI"
    },
    {
        "序号": 10128,
        "name": "Eaton Vance Municipal Income 20",
        "最新价": 17.34,
        "涨跌额": -0.18,
        "涨跌幅": -1.03,
        "开盘价": 17.47,
        "最高价": 17.58,
        "最低价": 17.32,
        "昨收价": 17.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 57503.0,
        "成交额": 1000864.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "106.ETX"
    },
    {
        "序号": 10129,
        "name": "Healthcare Trust Inc Series B P",
        "最新价": 13.47,
        "涨跌额": -0.14,
        "涨跌幅": -1.03,
        "开盘价": 13.33,
        "最高价": 13.48,
        "最低价": 13.2,
        "昨收价": 13.61,
        "总市值": 49569600.0,
        "市盈率": null,
        "成交量": 8176.0,
        "成交额": 108806.0,
        "振幅": 2.06,
        "换手率": 0.22,
        "symbol": "105.HTIBP"
    },
    {
        "序号": 10130,
        "name": "美国汽车行",
        "最新价": 60.58,
        "涨跌额": -0.63,
        "涨跌幅": -1.03,
        "开盘价": 61.18,
        "最高价": 61.84,
        "最低价": 59.75,
        "昨收价": 61.21,
        "总市值": 387278126.0,
        "市盈率": -19.68,
        "成交量": 115630.0,
        "成交额": 7008088.0,
        "振幅": 3.41,
        "换手率": 1.81,
        "symbol": "105.CRMT"
    },
    {
        "序号": 10131,
        "name": "易昆尼克斯",
        "最新价": 801.77,
        "涨跌额": -8.34,
        "涨跌幅": -1.03,
        "开盘价": 804.41,
        "最高价": 808.53,
        "最低价": 796.13,
        "昨收价": 810.11,
        "总市值": 75272912860.0,
        "市盈率": 86.48,
        "成交量": 562934.0,
        "成交额": 451615952.0,
        "振幅": 1.53,
        "换手率": 0.6,
        "symbol": "105.EQIX"
    },
    {
        "序号": 10132,
        "name": "Service Properties Trust",
        "最新价": 7.68,
        "涨跌额": -0.08,
        "涨跌幅": -1.03,
        "开盘价": 7.74,
        "最高价": 7.77,
        "最低价": 7.59,
        "昨收价": 7.76,
        "总市值": 1273115443.0,
        "市盈率": -61.02,
        "成交量": 588614.0,
        "成交额": 4518045.0,
        "振幅": 2.32,
        "换手率": 0.36,
        "symbol": "105.SVC"
    },
    {
        "序号": 10133,
        "name": "Lyell Immunopharma Inc",
        "最新价": 1.92,
        "涨跌额": -0.02,
        "涨跌幅": -1.03,
        "开盘价": 1.9,
        "最高价": 2.03,
        "最低价": 1.84,
        "昨收价": 1.94,
        "总市值": 483588419.0,
        "市盈率": -2.54,
        "成交量": 878347.0,
        "成交额": 1706140.0,
        "振幅": 9.79,
        "换手率": 0.35,
        "symbol": "105.LYEL"
    },
    {
        "序号": 10134,
        "name": "Cementos Pacasmayo SAA ADR",
        "最新价": 4.8,
        "涨跌额": -0.05,
        "涨跌幅": -1.03,
        "开盘价": 4.88,
        "最高价": 4.88,
        "最低价": 4.71,
        "昨收价": 4.85,
        "总市值": 410982572.0,
        "市盈率": 8.79,
        "成交量": 17295.0,
        "成交额": 82523.0,
        "振幅": 3.51,
        "换手率": 0.02,
        "symbol": "106.CPAC"
    },
    {
        "序号": 10135,
        "name": "Prudential Financial Inc Notes",
        "最新价": 23.92,
        "涨跌额": -0.25,
        "涨跌幅": -1.03,
        "开盘价": 24.15,
        "最高价": 24.27,
        "最低价": 23.91,
        "昨收价": 24.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 40926.0,
        "成交额": 984453.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "106.PRS"
    },
    {
        "序号": 10136,
        "name": "威瑞森通讯",
        "最新价": 38.25,
        "涨跌额": -0.4,
        "涨跌幅": -1.03,
        "开盘价": 38.66,
        "最高价": 38.68,
        "最低价": 38.01,
        "昨收价": 38.65,
        "总市值": 160806888457.0,
        "市盈率": 7.7,
        "成交量": 21850377.0,
        "成交额": 836289520.0,
        "振幅": 1.73,
        "换手率": 0.52,
        "symbol": "106.VZ"
    },
    {
        "序号": 10137,
        "name": "Atlanticus Holdings Corp Notes",
        "最新价": 22.95,
        "涨跌额": -0.24,
        "涨跌幅": -1.03,
        "开盘价": 23.12,
        "最高价": 23.14,
        "最低价": 22.92,
        "昨收价": 23.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 2685.0,
        "成交额": 61851.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "105.ATLCL"
    },
    {
        "序号": 10138,
        "name": "Morgan Stanley Series L Pfd",
        "最新价": 21.03,
        "涨跌额": -0.22,
        "涨跌幅": -1.04,
        "开盘价": 21.14,
        "最高价": 21.15,
        "最低价": 21.01,
        "昨收价": 21.25,
        "总市值": 420600.0,
        "市盈率": null,
        "成交量": 29787.0,
        "成交额": 627907.0,
        "振幅": 0.66,
        "换手率": 148.93,
        "symbol": "106.MS_L"
    },
    {
        "序号": 10139,
        "name": "vTv Therapeutics Inc-A",
        "最新价": 11.46,
        "涨跌额": -0.12,
        "涨跌幅": -1.04,
        "开盘价": 11.42,
        "最高价": 12.14,
        "最低价": 11.29,
        "昨收价": 11.58,
        "总市值": 29961442.0,
        "市盈率": -1.39,
        "成交量": 4513.0,
        "成交额": 52188.0,
        "振幅": 7.34,
        "换手率": 0.17,
        "symbol": "105.VTVT"
    },
    {
        "序号": 10140,
        "name": "Horizon Kinetics Medical ETF",
        "最新价": 28.65,
        "涨跌额": -0.3,
        "涨跌幅": -1.04,
        "开盘价": 28.95,
        "最高价": 28.95,
        "最低价": 28.65,
        "昨收价": 28.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 127.0,
        "成交额": 3676.0,
        "振幅": 1.04,
        "换手率": null,
        "symbol": "105.MEDX"
    },
    {
        "序号": 10141,
        "name": "中概互联网ETF-KraneShares",
        "最新价": 26.69,
        "涨跌额": -0.28,
        "涨跌幅": -1.04,
        "开盘价": 26.71,
        "最高价": 26.89,
        "最低价": 26.64,
        "昨收价": 26.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 16627219.0,
        "成交额": 444419568.0,
        "振幅": 0.93,
        "换手率": null,
        "symbol": "107.KWEB"
    },
    {
        "序号": 10142,
        "name": "Yelp Inc",
        "最新价": 43.83,
        "涨跌额": -0.46,
        "涨跌幅": -1.04,
        "开盘价": 43.87,
        "最高价": 44.03,
        "最低价": 42.57,
        "昨收价": 44.29,
        "总市值": 3001161553.0,
        "市盈率": 32.65,
        "成交量": 756058.0,
        "成交额": 32923107.0,
        "振幅": 3.3,
        "换手率": 1.1,
        "symbol": "106.YELP"
    },
    {
        "序号": 10143,
        "name": "埃克西尔能源",
        "最新价": 60.91,
        "涨跌额": -0.64,
        "涨跌幅": -1.04,
        "开盘价": 61.41,
        "最高价": 61.59,
        "最低价": 60.42,
        "昨收价": 61.55,
        "总市值": 33611131990.0,
        "市盈率": 19.31,
        "成交量": 4343741.0,
        "成交额": 264424540.0,
        "振幅": 1.9,
        "换手率": 0.79,
        "symbol": "105.XEL"
    },
    {
        "序号": 10144,
        "name": "百特国际",
        "最新价": 36.15,
        "涨跌额": -0.38,
        "涨跌幅": -1.04,
        "开盘价": 36.63,
        "最高价": 36.67,
        "最低价": 36.08,
        "昨收价": 36.53,
        "总市值": 18339744850.0,
        "市盈率": 7.08,
        "成交量": 3086527.0,
        "成交额": 111742570.0,
        "振幅": 1.62,
        "换手率": 0.61,
        "symbol": "106.BAX"
    },
    {
        "序号": 10145,
        "name": "Hudson Pacific Properties Inc",
        "最新价": 7.61,
        "涨跌额": -0.08,
        "涨跌幅": -1.04,
        "开盘价": 7.6,
        "最高价": 7.73,
        "最低价": 7.44,
        "昨收价": 7.69,
        "总市值": 1072535912.0,
        "市盈率": -12.58,
        "成交量": 3260376.0,
        "成交额": 24667117.0,
        "振幅": 3.77,
        "换手率": 2.31,
        "symbol": "106.HPP"
    },
    {
        "序号": 10146,
        "name": "德纳维制药",
        "最新价": 13.31,
        "涨跌额": -0.14,
        "涨跌幅": -1.04,
        "开盘价": 13.31,
        "最高价": 13.48,
        "最低价": 13.22,
        "昨收价": 13.45,
        "总市值": 1720461914.0,
        "市盈率": 28.15,
        "成交量": 1058643.0,
        "成交额": 14107220.0,
        "振幅": 1.93,
        "换手率": 0.82,
        "symbol": "105.DVAX"
    },
    {
        "序号": 10147,
        "name": "CB乡村店",
        "最新价": 73.19,
        "涨跌额": -0.77,
        "涨跌幅": -1.04,
        "开盘价": 73.44,
        "最高价": 74.0,
        "最低价": 73.05,
        "昨收价": 73.96,
        "总市值": 1623728347.0,
        "市盈率": 18.58,
        "成交量": 309419.0,
        "成交额": 22710717.0,
        "振幅": 1.28,
        "换手率": 1.39,
        "symbol": "105.CBRL"
    },
    {
        "序号": 10148,
        "name": "ARB IOT Group Ltd",
        "最新价": 0.95,
        "涨跌额": -0.01,
        "涨跌幅": -1.04,
        "开盘价": 0.96,
        "最高价": 0.96,
        "最低价": 0.95,
        "昨收价": 0.96,
        "总市值": 25115625.0,
        "市盈率": 4.53,
        "成交量": 4049.0,
        "成交额": 3850.0,
        "振幅": 1.04,
        "换手率": 0.02,
        "symbol": "105.ARBB"
    },
    {
        "序号": 10149,
        "name": "Helios Technologies Inc",
        "最新价": 39.81,
        "涨跌额": -0.42,
        "涨跌幅": -1.04,
        "开盘价": 40.2,
        "最高价": 40.8,
        "最低价": 39.76,
        "昨收价": 40.23,
        "总市值": 1315839492.0,
        "市盈率": 25.5,
        "成交量": 120222.0,
        "成交额": 4816249.0,
        "振幅": 2.59,
        "换手率": 0.36,
        "symbol": "106.HLIO"
    },
    {
        "序号": 10150,
        "name": "美泰",
        "最新价": 18.9,
        "涨跌额": -0.2,
        "涨跌幅": -1.05,
        "开盘价": 19.03,
        "最高价": 19.2,
        "最低价": 18.85,
        "昨收价": 19.1,
        "总市值": 6676096518.0,
        "市盈率": 80.27,
        "成交量": 3890563.0,
        "成交额": 73732598.0,
        "振幅": 1.83,
        "换手率": 1.1,
        "symbol": "105.MAT"
    },
    {
        "序号": 10151,
        "name": "MakeMyTrip Ltd",
        "最新价": 44.39,
        "涨跌额": -0.47,
        "涨跌幅": -1.05,
        "开盘价": 44.85,
        "最高价": 44.91,
        "最低价": 43.38,
        "昨收价": 44.86,
        "总市值": 4711131696.0,
        "市盈率": 179.23,
        "成交量": 814748.0,
        "成交额": 35992605.0,
        "振幅": 3.41,
        "换手率": 0.77,
        "symbol": "105.MMYT"
    },
    {
        "序号": 10152,
        "name": "沃尔玛",
        "最新价": 150.86,
        "涨跌额": -1.6,
        "涨跌幅": -1.05,
        "开盘价": 152.0,
        "最高价": 152.21,
        "最低价": 150.24,
        "昨收价": 152.46,
        "总市值": 406150376435.0,
        "市盈率": 24.93,
        "成交量": 11356487.0,
        "成交额": 1714008704.0,
        "振幅": 1.29,
        "换手率": 0.42,
        "symbol": "106.WMT"
    },
    {
        "序号": 10153,
        "name": "KraneShares Global Carbon Strat",
        "最新价": 33.85,
        "涨跌额": -0.36,
        "涨跌幅": -1.05,
        "开盘价": 33.94,
        "最高价": 34.12,
        "最低价": 33.82,
        "昨收价": 34.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 68708.0,
        "成交额": 2329980.0,
        "振幅": 0.88,
        "换手率": null,
        "symbol": "107.KRBN"
    },
    {
        "序号": 10154,
        "name": "Nuveen Real Asset Income and Gr",
        "最新价": 11.27,
        "涨跌额": -0.12,
        "涨跌幅": -1.05,
        "开盘价": 11.31,
        "最高价": 11.34,
        "最低价": 11.27,
        "昨收价": 11.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 62592.0,
        "成交额": 707871.0,
        "振幅": 0.61,
        "换手率": null,
        "symbol": "106.JRI"
    },
    {
        "序号": 10155,
        "name": "Shutterstock Inc",
        "最新价": 45.04,
        "涨跌额": -0.48,
        "涨跌幅": -1.05,
        "开盘价": 45.48,
        "最高价": 46.37,
        "最低价": 44.84,
        "昨收价": 45.52,
        "总市值": 1608495819.0,
        "市盈率": 13.59,
        "成交量": 419105.0,
        "成交额": 19026797.0,
        "振幅": 3.36,
        "换手率": 1.17,
        "symbol": "106.SSTK"
    },
    {
        "序号": 10156,
        "name": "太平洋航空",
        "最新价": 148.01,
        "涨跌额": -1.58,
        "涨跌幅": -1.06,
        "开盘价": 148.33,
        "最高价": 149.5,
        "最低价": 146.05,
        "昨收价": 149.59,
        "总市值": 7478611745.0,
        "市盈率": 14.71,
        "成交量": 137900.0,
        "成交额": 20354921.0,
        "振幅": 2.31,
        "换手率": 0.27,
        "symbol": "106.PAC"
    },
    {
        "序号": 10157,
        "name": "Orla Mining Ltd",
        "最新价": 2.81,
        "涨跌额": -0.03,
        "涨跌幅": -1.06,
        "开盘价": 2.81,
        "最高价": 2.9,
        "最低价": 2.77,
        "昨收价": 2.84,
        "总市值": 883550399.0,
        "市盈率": 17.63,
        "成交量": 594940.0,
        "成交额": 1675766.0,
        "振幅": 4.58,
        "换手率": 0.19,
        "symbol": "107.ORLA"
    },
    {
        "序号": 10158,
        "name": "Brilliant Earth Group Inc-A",
        "最新价": 2.81,
        "涨跌额": -0.03,
        "涨跌幅": -1.06,
        "开盘价": 2.8,
        "最高价": 2.89,
        "最低价": 2.75,
        "昨收价": 2.84,
        "总市值": 272814279.0,
        "市盈率": 259.82,
        "成交量": 40846.0,
        "成交额": 114240.0,
        "振幅": 4.93,
        "换手率": 0.04,
        "symbol": "105.BRLT"
    },
    {
        "序号": 10159,
        "name": "Lottery.com Inc",
        "最新价": 1.87,
        "涨跌额": -0.02,
        "涨跌幅": -1.06,
        "开盘价": 1.82,
        "最高价": 1.92,
        "最低价": 1.82,
        "昨收价": 1.89,
        "总市值": 5374954.0,
        "市盈率": -0.36,
        "成交量": 3530.0,
        "成交额": 6638.0,
        "振幅": 5.29,
        "换手率": 0.12,
        "symbol": "105.LTRY"
    },
    {
        "序号": 10160,
        "name": "禾赛",
        "最新价": 9.35,
        "涨跌额": -0.1,
        "涨跌幅": -1.06,
        "开盘价": 9.45,
        "最高价": 9.49,
        "最低价": 9.24,
        "昨收价": 9.45,
        "总市值": 1173748445.0,
        "市盈率": -17.64,
        "成交量": 238900.0,
        "成交额": 2226506.0,
        "振幅": 2.65,
        "换手率": 0.19,
        "symbol": "105.HSAI"
    },
    {
        "序号": 10161,
        "name": "叮咚买菜",
        "最新价": 1.87,
        "涨跌额": -0.02,
        "涨跌幅": -1.06,
        "开盘价": 1.87,
        "最高价": 1.92,
        "最低价": 1.85,
        "昨收价": 1.89,
        "总市值": 441745772.0,
        "市盈率": -8.33,
        "成交量": 156188.0,
        "成交额": 293878.0,
        "振幅": 3.7,
        "换手率": 0.07,
        "symbol": "106.DDL"
    },
    {
        "序号": 10162,
        "name": "华住",
        "最新价": 34.57,
        "涨跌额": -0.37,
        "涨跌幅": -1.06,
        "开盘价": 34.44,
        "最高价": 34.76,
        "最低价": 33.95,
        "昨收价": 34.94,
        "总市值": 11020018113.0,
        "市盈率": 24.87,
        "成交量": 1624083.0,
        "成交额": 55858543.0,
        "振幅": 2.32,
        "换手率": 0.51,
        "symbol": "105.HTHT"
    },
    {
        "序号": 10163,
        "name": "NeuroPace Inc",
        "最新价": 8.4,
        "涨跌额": -0.09,
        "涨跌幅": -1.06,
        "开盘价": 8.41,
        "最高价": 8.68,
        "最低价": 8.31,
        "昨收价": 8.49,
        "总市值": 220782862.0,
        "市盈率": -5.83,
        "成交量": 26296.0,
        "成交额": 222271.0,
        "振幅": 4.36,
        "换手率": 0.1,
        "symbol": "105.NPCE"
    },
    {
        "序号": 10164,
        "name": "百事",
        "最新价": 165.68,
        "涨跌额": -1.78,
        "涨跌幅": -1.06,
        "开盘价": 167.04,
        "最高价": 167.12,
        "最低价": 165.27,
        "昨收价": 167.46,
        "总市值": 227787461058.0,
        "市盈率": 27.48,
        "成交量": 5115158.0,
        "成交额": 848429888.0,
        "振幅": 1.1,
        "换手率": 0.37,
        "symbol": "105.PEP"
    },
    {
        "序号": 10165,
        "name": "Aspen Insurance Holdings Ltd Pf",
        "最新价": 20.46,
        "涨跌额": -0.22,
        "涨跌幅": -1.06,
        "开盘价": 20.78,
        "最高价": 20.78,
        "最低价": 20.45,
        "昨收价": 20.68,
        "总市值": 204600000.0,
        "市盈率": null,
        "成交量": 7183.0,
        "成交额": 147715.0,
        "振幅": 1.6,
        "换手率": 0.07,
        "symbol": "106.AHL_D"
    },
    {
        "序号": 10166,
        "name": "再生元制药",
        "最新价": 840.14,
        "涨跌额": -9.04,
        "涨跌幅": -1.06,
        "开盘价": 844.33,
        "最高价": 848.02,
        "最低价": 839.55,
        "昨收价": 849.18,
        "总市值": 91530719138.0,
        "市盈率": 22.93,
        "成交量": 388118.0,
        "成交额": 326857888.0,
        "振幅": 1.0,
        "换手率": 0.36,
        "symbol": "105.REGN"
    },
    {
        "序号": 10167,
        "name": "Knight-Swift Transportation Hol",
        "最新价": 55.69,
        "涨跌额": -0.6,
        "涨跌幅": -1.07,
        "开盘价": 56.36,
        "最高价": 56.8,
        "最低价": 55.64,
        "昨收价": 56.29,
        "总市值": 8986639610.0,
        "市盈率": 23.87,
        "成交量": 1410992.0,
        "成交额": 78972156.0,
        "振幅": 2.06,
        "换手率": 0.87,
        "symbol": "106.KNX"
    },
    {
        "序号": 10168,
        "name": "惠顿贵金属",
        "最新价": 47.31,
        "涨跌额": -0.51,
        "涨跌幅": -1.07,
        "开盘价": 46.95,
        "最高价": 47.73,
        "最低价": 46.78,
        "昨收价": 47.82,
        "总市值": 21431247431.0,
        "市盈率": 40.03,
        "成交量": 1997905.0,
        "成交额": 94555555.0,
        "振幅": 1.99,
        "换手率": 0.44,
        "symbol": "106.WPM"
    },
    {
        "序号": 10169,
        "name": "Intra-Cellular Therapies Inc",
        "最新价": 60.29,
        "涨跌额": -0.65,
        "涨跌幅": -1.07,
        "开盘价": 61.04,
        "最高价": 61.74,
        "最低价": 59.77,
        "昨收价": 60.94,
        "总市值": 5802196617.0,
        "市盈率": -37.4,
        "成交量": 731002.0,
        "成交额": 44104127.0,
        "振幅": 3.23,
        "换手率": 0.76,
        "symbol": "105.ITCI"
    },
    {
        "序号": 10170,
        "name": "Potbelly Corp",
        "最新价": 9.27,
        "涨跌额": -0.1,
        "涨跌幅": -1.07,
        "开盘价": 9.46,
        "最高价": 9.46,
        "最低价": 9.2,
        "昨收价": 9.37,
        "总市值": 272155677.0,
        "市盈率": 54.02,
        "成交量": 92353.0,
        "成交额": 858674.0,
        "振幅": 2.77,
        "换手率": 0.31,
        "symbol": "105.PBPB"
    },
    {
        "序号": 10171,
        "name": "OGE Energy Corp",
        "最新价": 35.2,
        "涨跌额": -0.38,
        "涨跌幅": -1.07,
        "开盘价": 35.49,
        "最高价": 35.51,
        "最低价": 35.05,
        "昨收价": 35.58,
        "总市值": 7050115213.0,
        "市盈率": 16.83,
        "成交量": 1032955.0,
        "成交额": 36354865.0,
        "振幅": 1.29,
        "换手率": 0.52,
        "symbol": "106.OGE"
    },
    {
        "序号": 10172,
        "name": "WisdomTree Inc",
        "最新价": 6.48,
        "涨跌额": -0.07,
        "涨跌幅": -1.07,
        "开盘价": 6.51,
        "最高价": 6.56,
        "最低价": 6.42,
        "昨收价": 6.55,
        "总市值": 974173120.0,
        "市盈率": 17.65,
        "成交量": 1032783.0,
        "成交额": 6679426.0,
        "振幅": 2.14,
        "换手率": 0.69,
        "symbol": "106.WT"
    },
    {
        "序号": 10173,
        "name": "柯达",
        "最新价": 3.7,
        "涨跌额": -0.04,
        "涨跌幅": -1.07,
        "开盘价": 3.74,
        "最高价": 3.79,
        "最低价": 3.69,
        "昨收价": 3.74,
        "总市值": 294345974.0,
        "市盈率": 3.82,
        "成交量": 339251.0,
        "成交额": 1264514.0,
        "振幅": 2.67,
        "换手率": 0.43,
        "symbol": "106.KODK"
    },
    {
        "序号": 10174,
        "name": "Brighthouse Financial Inc Serie",
        "最新价": 14.79,
        "涨跌额": -0.16,
        "涨跌幅": -1.07,
        "开盘价": 14.93,
        "最高价": 15.0,
        "最低价": 14.42,
        "昨收价": 14.95,
        "总市值": 207060.0,
        "市盈率": null,
        "成交量": 56027.0,
        "成交额": 821118.0,
        "振幅": 3.88,
        "换手率": 400.19,
        "symbol": "105.BHFAM"
    },
    {
        "序号": 10175,
        "name": "First US Bancshares Inc",
        "最新价": 9.23,
        "涨跌额": -0.1,
        "涨跌幅": -1.07,
        "开盘价": 9.33,
        "最高价": 9.8,
        "最低价": 9.23,
        "昨收价": 9.33,
        "总市值": 54224229.0,
        "市盈率": 6.43,
        "成交量": 16890.0,
        "成交额": 158487.0,
        "振幅": 6.11,
        "换手率": 0.29,
        "symbol": "105.FUSB"
    },
    {
        "序号": 10176,
        "name": "福泰制药",
        "最新价": 350.15,
        "涨跌额": -3.8,
        "涨跌幅": -1.07,
        "开盘价": 354.0,
        "最高价": 354.35,
        "最低价": 348.46,
        "昨收价": 353.95,
        "总市值": 90227766878.0,
        "市盈率": 26.0,
        "成交量": 1394568.0,
        "成交额": 488866752.0,
        "振幅": 1.66,
        "换手率": 0.54,
        "symbol": "105.VRTX"
    },
    {
        "序号": 10177,
        "name": "Direxion Daily S&P 500 Bear 3X ",
        "最新价": 12.9,
        "涨跌额": -0.14,
        "涨跌幅": -1.07,
        "开盘价": 13.12,
        "最高价": 13.14,
        "最低价": 12.84,
        "昨收价": 13.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 29302482.0,
        "成交额": 380158816.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.SPXS"
    },
    {
        "序号": 10178,
        "name": "Regional Health Properties Inc",
        "最新价": 1.84,
        "涨跌额": -0.02,
        "涨跌幅": -1.08,
        "开盘价": 1.88,
        "最高价": 1.88,
        "最低价": 1.84,
        "昨收价": 1.86,
        "总市值": 3464772.0,
        "市盈率": -0.59,
        "成交量": 1065.0,
        "成交额": 1976.0,
        "振幅": 2.15,
        "换手率": 0.06,
        "symbol": "107.RHE"
    },
    {
        "序号": 10179,
        "name": "康乃德生物",
        "最新价": 0.92,
        "涨跌额": -0.01,
        "涨跌幅": -1.08,
        "开盘价": 0.91,
        "最高价": 0.93,
        "最低价": 0.9,
        "昨收价": 0.93,
        "总市值": 50665834.0,
        "市盈率": -0.6,
        "成交量": 81462.0,
        "成交额": 74905.0,
        "振幅": 3.23,
        "换手率": 0.15,
        "symbol": "105.CNTB"
    },
    {
        "序号": 10180,
        "name": "FT Cboe Vest Gold Strategy Quar",
        "最新价": 18.39,
        "涨跌额": -0.2,
        "涨跌幅": -1.08,
        "开盘价": 18.48,
        "最高价": 18.48,
        "最低价": 18.35,
        "昨收价": 18.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 2477.0,
        "成交额": 45591.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "107.BGLD"
    },
    {
        "序号": 10181,
        "name": "美国航空",
        "最新价": 13.76,
        "涨跌额": -0.15,
        "涨跌幅": -1.08,
        "开盘价": 13.87,
        "最高价": 13.98,
        "最低价": 13.71,
        "昨收价": 13.91,
        "总市值": 8992717968.0,
        "市盈率": 5.6,
        "成交量": 30196932.0,
        "成交额": 417488368.0,
        "振幅": 1.94,
        "换手率": 4.62,
        "symbol": "105.AAL"
    },
    {
        "序号": 10182,
        "name": "Orange County Bancorp Inc",
        "最新价": 50.44,
        "涨跌额": -0.55,
        "涨跌幅": -1.08,
        "开盘价": 50.0,
        "最高价": 50.75,
        "最低价": 46.76,
        "昨收价": 50.99,
        "总市值": 285009102.0,
        "市盈率": 9.37,
        "成交量": 12595.0,
        "成交额": 630108.0,
        "振幅": 7.83,
        "换手率": 0.22,
        "symbol": "105.OBT"
    },
    {
        "序号": 10183,
        "name": "美国生牌",
        "最新价": 6.4,
        "涨跌额": -0.07,
        "涨跌幅": -1.08,
        "开盘价": 6.38,
        "最高价": 6.48,
        "最低价": 6.35,
        "昨收价": 6.47,
        "总市值": 139611110.0,
        "市盈率": -17.79,
        "成交量": 23568.0,
        "成交额": 151290.0,
        "振幅": 2.01,
        "换手率": 0.11,
        "symbol": "105.LCUT"
    },
    {
        "序号": 10184,
        "name": "Osisko Gold Royalties Ltd",
        "最新价": 13.7,
        "涨跌额": -0.15,
        "涨跌幅": -1.08,
        "开盘价": 13.66,
        "最高价": 13.94,
        "最低价": 13.6,
        "昨收价": 13.85,
        "总市值": 2536773707.0,
        "市盈率": 84.64,
        "成交量": 786198.0,
        "成交额": 10783933.0,
        "振幅": 2.45,
        "换手率": 0.42,
        "symbol": "106.OR"
    },
    {
        "序号": 10185,
        "name": "CureVac NV",
        "最新价": 5.48,
        "涨跌额": -0.06,
        "涨跌幅": -1.08,
        "开盘价": 5.52,
        "最高价": 5.62,
        "最低价": 5.45,
        "昨收价": 5.54,
        "总市值": 1227101920.0,
        "市盈率": -3.71,
        "成交量": 887800.0,
        "成交额": 4925681.0,
        "振幅": 3.07,
        "换手率": 0.4,
        "symbol": "105.CVAC"
    },
    {
        "序号": 10186,
        "name": "Royce Global Value Trust",
        "最新价": 9.11,
        "涨跌额": -0.1,
        "涨跌幅": -1.09,
        "开盘价": 9.2,
        "最高价": 9.2,
        "最低价": 8.99,
        "昨收价": 9.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 59883.0,
        "成交额": 542434.0,
        "振幅": 2.28,
        "换手率": null,
        "symbol": "106.RGT"
    },
    {
        "序号": 10187,
        "name": "Finance of America Companies In",
        "最新价": 0.91,
        "涨跌额": -0.01,
        "涨跌幅": -1.09,
        "开盘价": 0.93,
        "最高价": 0.96,
        "最低价": 0.89,
        "昨收价": 0.92,
        "总市值": 80028991.0,
        "市盈率": -0.4,
        "成交量": 31874.0,
        "成交额": 29059.0,
        "振幅": 7.61,
        "换手率": 0.04,
        "symbol": "106.FOA"
    },
    {
        "序号": 10188,
        "name": "BayFirst Financial Corp",
        "最新价": 10.92,
        "涨跌额": -0.12,
        "涨跌幅": -1.09,
        "开盘价": 11.19,
        "最高价": 11.2,
        "最低价": 10.9,
        "昨收价": 11.04,
        "总市值": 44888298.0,
        "市盈率": 8.36,
        "成交量": 24364.0,
        "成交额": 272789.0,
        "振幅": 2.72,
        "换手率": 0.59,
        "symbol": "105.BAFN"
    },
    {
        "序号": 10189,
        "name": "Energy Recovery Inc",
        "最新价": 18.18,
        "涨跌额": -0.2,
        "涨跌幅": -1.09,
        "开盘价": 18.23,
        "最高价": 18.57,
        "最低价": 17.81,
        "昨收价": 18.38,
        "总市值": 1027496022.0,
        "市盈率": 66.62,
        "成交量": 294332.0,
        "成交额": 5353776.0,
        "振幅": 4.13,
        "换手率": 0.52,
        "symbol": "105.ERII"
    },
    {
        "序号": 10190,
        "name": "KraneShares CICC China Consumer",
        "最新价": 14.54,
        "涨跌额": -0.16,
        "涨跌幅": -1.09,
        "开盘价": 14.54,
        "最高价": 14.54,
        "最低价": 14.54,
        "昨收价": 14.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 50.0,
        "成交额": 727.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.KBUY"
    },
    {
        "序号": 10191,
        "name": "中汽系统",
        "最新价": 3.63,
        "涨跌额": -0.04,
        "涨跌幅": -1.09,
        "开盘价": 3.7,
        "最高价": 3.76,
        "最低价": 3.53,
        "昨收价": 3.67,
        "总市值": 109574098.0,
        "市盈率": 3.52,
        "成交量": 21824.0,
        "成交额": 79492.0,
        "振幅": 6.27,
        "换手率": 0.07,
        "symbol": "105.CAAS"
    },
    {
        "序号": 10192,
        "name": "卡拉沃养殖",
        "最新价": 27.22,
        "涨跌额": -0.3,
        "涨跌幅": -1.09,
        "开盘价": 27.4,
        "最高价": 27.82,
        "最低价": 27.14,
        "昨收价": 27.52,
        "总市值": 483456080.0,
        "市盈率": -128.82,
        "成交量": 117730.0,
        "成交额": 3231701.0,
        "振幅": 2.47,
        "换手率": 0.66,
        "symbol": "105.CVGW"
    },
    {
        "序号": 10193,
        "name": "Subversive Cannabis ETF",
        "最新价": 19.96,
        "涨跌额": -0.22,
        "涨跌幅": -1.09,
        "开盘价": 19.96,
        "最高价": 19.96,
        "最低价": 19.96,
        "昨收价": 20.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 106.0,
        "成交额": 2115.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.LGLZ"
    },
    {
        "序号": 10194,
        "name": "Triple Flag Precious Metals Cor",
        "最新价": 13.6,
        "涨跌额": -0.15,
        "涨跌幅": -1.09,
        "开盘价": 13.52,
        "最高价": 13.75,
        "最低价": 13.45,
        "昨收价": 13.75,
        "总市值": 2741348763.0,
        "市盈率": 65.29,
        "成交量": 121020.0,
        "成交额": 1639899.0,
        "振幅": 2.18,
        "换手率": 0.06,
        "symbol": "106.TFPM"
    },
    {
        "序号": 10195,
        "name": "abrdn Japan Equity Fund Inc",
        "最新价": 5.44,
        "涨跌额": -0.06,
        "涨跌幅": -1.09,
        "开盘价": 5.48,
        "最高价": 5.49,
        "最低价": 5.43,
        "昨收价": 5.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 54094.0,
        "成交额": 294765.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "106.JEQ"
    },
    {
        "序号": 10196,
        "name": "Enstar Group Ltd Series D Pfd",
        "最新价": 24.45,
        "涨跌额": -0.27,
        "涨跌幅": -1.09,
        "开盘价": 24.68,
        "最高价": 24.81,
        "最低价": 24.43,
        "昨收价": 24.72,
        "总市值": 391200.0,
        "市盈率": null,
        "成交量": 10151.0,
        "成交额": 249108.0,
        "振幅": 1.54,
        "换手率": 63.44,
        "symbol": "105.ESGRP"
    },
    {
        "序号": 10197,
        "name": "Algoma Steel Group Inc",
        "最新价": 8.15,
        "涨跌额": -0.09,
        "涨跌幅": -1.09,
        "开盘价": 8.26,
        "最高价": 8.35,
        "最低价": 8.1,
        "昨收价": 8.24,
        "总市值": 844366593.0,
        "市盈率": 14.95,
        "成交量": 156813.0,
        "成交额": 1284280.0,
        "振幅": 3.03,
        "换手率": 0.15,
        "symbol": "105.ASTL"
    },
    {
        "序号": 10198,
        "name": "Steel Dynamics Inc",
        "最新价": 113.16,
        "涨跌额": -1.25,
        "涨跌幅": -1.09,
        "开盘价": 114.05,
        "最高价": 115.57,
        "最低价": 113.08,
        "昨收价": 114.41,
        "总市值": 18311151745.0,
        "市盈率": 6.88,
        "成交量": 971625.0,
        "成交额": 110400506.0,
        "振幅": 2.18,
        "换手率": 0.6,
        "symbol": "105.STLD"
    },
    {
        "序号": 10199,
        "name": "WEC能源",
        "最新价": 84.07,
        "涨跌额": -0.93,
        "涨跌幅": -1.09,
        "开盘价": 84.96,
        "最高价": 85.05,
        "最低价": 83.41,
        "昨收价": 85.0,
        "总市值": 26518581021.0,
        "市盈率": 19.4,
        "成交量": 1712901.0,
        "成交额": 143912780.0,
        "振幅": 1.93,
        "换手率": 0.54,
        "symbol": "106.WEC"
    },
    {
        "序号": 10200,
        "name": "智利矿业化工",
        "最新价": 51.43,
        "涨跌额": -0.57,
        "涨跌幅": -1.1,
        "开盘价": 53.0,
        "最高价": 53.63,
        "最低价": 51.24,
        "昨收价": 52.0,
        "总市值": 14690385792.0,
        "市盈率": 4.96,
        "成交量": 2136226.0,
        "成交额": 111937217.0,
        "振幅": 4.6,
        "换手率": 0.75,
        "symbol": "106.SQM"
    },
    {
        "序号": 10201,
        "name": "VersaBank",
        "最新价": 8.12,
        "涨跌额": -0.09,
        "涨跌幅": -1.1,
        "开盘价": 7.86,
        "最高价": 8.17,
        "最低价": 7.86,
        "昨收价": 8.21,
        "总市值": 210506323.0,
        "市盈率": 7.93,
        "成交量": 8815.0,
        "成交额": 70606.0,
        "振幅": 3.78,
        "换手率": 0.03,
        "symbol": "105.VBNK"
    },
    {
        "序号": 10202,
        "name": "MAIA Biotechnology Inc",
        "最新价": 0.9,
        "涨跌额": -0.01,
        "涨跌幅": -1.1,
        "开盘价": 0.9,
        "最高价": 0.91,
        "最低价": 0.87,
        "昨收价": 0.91,
        "总市值": 15249379.0,
        "市盈率": -0.86,
        "成交量": 197316.0,
        "成交额": 176346.0,
        "振幅": 4.4,
        "换手率": 1.16,
        "symbol": "107.MAIA"
    },
    {
        "序号": 10203,
        "name": "Lument Finance Trust Inc Pfd",
        "最新价": 18.0,
        "涨跌额": -0.2,
        "涨跌幅": -1.1,
        "开盘价": 18.16,
        "最高价": 18.2,
        "最低价": 17.8,
        "昨收价": 18.2,
        "总市值": 43200000.0,
        "市盈率": null,
        "成交量": 6024.0,
        "成交额": 108771.0,
        "振幅": 2.2,
        "换手率": 0.25,
        "symbol": "106.LFT_A"
    },
    {
        "序号": 10204,
        "name": "Azenta Inc",
        "最新价": 57.6,
        "涨跌额": -0.64,
        "涨跌幅": -1.1,
        "开盘价": 58.14,
        "最高价": 58.7,
        "最低价": 57.32,
        "昨收价": 58.24,
        "总市值": 3232062144.0,
        "市盈率": -226.7,
        "成交量": 282404.0,
        "成交额": 16305282.0,
        "振幅": 2.37,
        "换手率": 0.5,
        "symbol": "105.AZTA"
    },
    {
        "序号": 10205,
        "name": "唯品会",
        "最新价": 15.27,
        "涨跌额": -0.17,
        "涨跌幅": -1.1,
        "开盘价": 15.32,
        "最高价": 15.5,
        "最低价": 15.23,
        "昨收价": 15.44,
        "总市值": 8273621864.0,
        "市盈率": 7.87,
        "成交量": 3585630.0,
        "成交额": 55009051.0,
        "振幅": 1.75,
        "换手率": 0.66,
        "symbol": "106.VIPS"
    },
    {
        "序号": 10206,
        "name": "Silvercrest Asset Management Gr",
        "最新价": 16.16,
        "涨跌额": -0.18,
        "涨跌幅": -1.1,
        "开盘价": 16.23,
        "最高价": 16.34,
        "最低价": 16.13,
        "昨收价": 16.34,
        "总市值": 224414938.0,
        "市盈率": 19.41,
        "成交量": 10443.0,
        "成交额": 169404.0,
        "振幅": 1.29,
        "换手率": 0.08,
        "symbol": "105.SAMG"
    },
    {
        "序号": 10207,
        "name": "Costamare Inc Pfd-B",
        "最新价": 24.23,
        "涨跌额": -0.27,
        "涨跌幅": -1.1,
        "开盘价": 24.19,
        "最高价": 24.4,
        "最低价": 24.19,
        "昨收价": 24.5,
        "总市值": 47748825.0,
        "市盈率": null,
        "成交量": 1580.0,
        "成交额": 38323.0,
        "振幅": 0.86,
        "换手率": 0.08,
        "symbol": "106.CMRE_B"
    },
    {
        "序号": 10208,
        "name": "科帕控股",
        "最新价": 97.52,
        "涨跌额": -1.09,
        "涨跌幅": -1.11,
        "开盘价": 97.75,
        "最高价": 98.58,
        "最低价": 96.85,
        "昨收价": 98.61,
        "总市值": 4104045040.0,
        "市盈率": 9.9,
        "成交量": 202479.0,
        "成交额": 19753911.0,
        "振幅": 1.75,
        "换手率": 0.48,
        "symbol": "106.CPA"
    },
    {
        "序号": 10209,
        "name": "Edgewell Personal Care Co",
        "最新价": 34.86,
        "涨跌额": -0.39,
        "涨跌幅": -1.11,
        "开盘价": 35.15,
        "最高价": 35.18,
        "最低价": 34.58,
        "昨收价": 35.25,
        "总市值": 1743743599.0,
        "市盈率": 15.2,
        "成交量": 378627.0,
        "成交额": 13182274.0,
        "振幅": 1.7,
        "换手率": 0.76,
        "symbol": "106.EPC"
    },
    {
        "序号": 10210,
        "name": "医疗保健服务",
        "最新价": 9.81,
        "涨跌额": -0.11,
        "涨跌幅": -1.11,
        "开盘价": 9.88,
        "最高价": 10.01,
        "最低价": 9.79,
        "昨收价": 9.92,
        "总市值": 724458690.0,
        "市盈率": 22.68,
        "成交量": 455042.0,
        "成交额": 4483452.0,
        "振幅": 2.22,
        "换手率": 0.62,
        "symbol": "105.HCSG"
    },
    {
        "序号": 10211,
        "name": "国际抗通胀债ETF-SPDR",
        "最新价": 40.12,
        "涨跌额": -0.45,
        "涨跌幅": -1.11,
        "开盘价": 40.33,
        "最高价": 40.4,
        "最低价": 40.1,
        "昨收价": 40.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 29741.0,
        "成交额": 1198244.0,
        "振幅": 0.74,
        "换手率": null,
        "symbol": "107.WIP"
    },
    {
        "序号": 10212,
        "name": "Vericel Corp",
        "最新价": 33.86,
        "涨跌额": -0.38,
        "涨跌幅": -1.11,
        "开盘价": 34.09,
        "最高价": 34.39,
        "最低价": 33.39,
        "昨收价": 34.24,
        "总市值": 1615953026.0,
        "市盈率": -157.61,
        "成交量": 241710.0,
        "成交额": 8172624.0,
        "振幅": 2.92,
        "换手率": 0.51,
        "symbol": "105.VCEL"
    },
    {
        "序号": 10213,
        "name": "Terra Property Trust Inc Notes",
        "最新价": 18.7,
        "涨跌额": -0.21,
        "涨跌幅": -1.11,
        "开盘价": 18.86,
        "最高价": 18.86,
        "最低价": 18.5,
        "昨收价": 18.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 608.0,
        "成交额": 11307.0,
        "振幅": 1.9,
        "换手率": null,
        "symbol": "106.TPTA"
    },
    {
        "序号": 10214,
        "name": "BondBloxx Bloomberg Twenty Year",
        "最新价": 42.6,
        "涨跌额": -0.48,
        "涨跌幅": -1.11,
        "开盘价": 42.5,
        "最高价": 42.6,
        "最低价": 42.32,
        "昨收价": 43.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 2091.0,
        "成交额": 88833.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.XTWY"
    },
    {
        "序号": 10215,
        "name": "Neoleukin Therapeutics Inc",
        "最新价": 3.55,
        "涨跌额": -0.04,
        "涨跌幅": -1.11,
        "开盘价": 3.5,
        "最高价": 3.65,
        "最低价": 3.46,
        "昨收价": 3.59,
        "总市值": 33362549.0,
        "市盈率": -0.97,
        "成交量": 16913.0,
        "成交额": 59659.0,
        "振幅": 5.29,
        "换手率": 0.18,
        "symbol": "105.NLTX"
    },
    {
        "序号": 10216,
        "name": "Unum Group Notes",
        "最新价": 23.93,
        "涨跌额": -0.27,
        "涨跌幅": -1.12,
        "开盘价": 24.2,
        "最高价": 24.2,
        "最低价": 23.84,
        "昨收价": 24.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 21706.0,
        "成交额": 519390.0,
        "振幅": 1.49,
        "换手率": null,
        "symbol": "106.UNMA"
    },
    {
        "序号": 10217,
        "name": "Fifth Third Bancorp Series A Pf",
        "最新价": 23.93,
        "涨跌额": -0.27,
        "涨跌幅": -1.12,
        "开盘价": 24.36,
        "最高价": 24.36,
        "最低价": 23.93,
        "昨收价": 24.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 5113.0,
        "成交额": 123611.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "105.FITBP"
    },
    {
        "序号": 10218,
        "name": "西南能源",
        "最新价": 6.2,
        "涨跌额": -0.07,
        "涨跌幅": -1.12,
        "开盘价": 6.33,
        "最高价": 6.35,
        "最低价": 6.16,
        "昨收价": 6.27,
        "总市值": 6829070922.0,
        "市盈率": 1.33,
        "成交量": 11145434.0,
        "成交额": 69389294.0,
        "振幅": 3.03,
        "换手率": 1.01,
        "symbol": "106.SWN"
    },
    {
        "序号": 10219,
        "name": "三倍做空标普500ETF-ProShares",
        "最新价": 9.74,
        "涨跌额": -0.11,
        "涨跌幅": -1.12,
        "开盘价": 9.91,
        "最高价": 9.93,
        "最低价": 9.7,
        "昨收价": 9.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 25236447.0,
        "成交额": 247273523.0,
        "振幅": 2.34,
        "换手率": null,
        "symbol": "107.SPXU"
    },
    {
        "序号": 10220,
        "name": "Reliance Steel & Aluminum Co",
        "最新价": 264.64,
        "涨跌额": -2.99,
        "涨跌幅": -1.12,
        "开盘价": 266.89,
        "最高价": 269.0,
        "最低价": 264.52,
        "昨收价": 267.63,
        "总市值": 15209186043.0,
        "市盈率": 10.76,
        "成交量": 173218.0,
        "成交额": 45942796.0,
        "振幅": 1.67,
        "换手率": 0.3,
        "symbol": "106.RS"
    },
    {
        "序号": 10221,
        "name": "Catalyst Pharmaceuticals Inc",
        "最新价": 14.15,
        "涨跌额": -0.16,
        "涨跌幅": -1.12,
        "开盘价": 14.19,
        "最高价": 14.43,
        "最低价": 14.02,
        "昨收价": 14.31,
        "总市值": 1508552102.0,
        "市盈率": 24.32,
        "成交量": 1150211.0,
        "成交额": 16352234.0,
        "振幅": 2.87,
        "换手率": 1.08,
        "symbol": "105.CPRX"
    },
    {
        "序号": 10222,
        "name": "Evotec SE ADR",
        "最新价": 9.72,
        "涨跌额": -0.11,
        "涨跌幅": -1.12,
        "开盘价": 9.76,
        "最高价": 9.84,
        "最低价": 9.61,
        "昨收价": 9.83,
        "总市值": 3444490863.0,
        "市盈率": -30.57,
        "成交量": 31245.0,
        "成交额": 305113.0,
        "振幅": 2.34,
        "换手率": 0.01,
        "symbol": "105.EVO"
    },
    {
        "序号": 10223,
        "name": "XP Inc-A",
        "最新价": 23.83,
        "涨跌额": -0.27,
        "涨跌幅": -1.12,
        "开盘价": 23.92,
        "最高价": 24.56,
        "最低价": 23.74,
        "昨收价": 24.1,
        "总市值": 13035651813.0,
        "市盈率": 17.69,
        "成交量": 5913510.0,
        "成交额": 142347434.0,
        "振幅": 3.4,
        "换手率": 1.08,
        "symbol": "105.XP"
    },
    {
        "序号": 10224,
        "name": "The Southern Co Series 2017B No",
        "最新价": 22.94,
        "涨跌额": -0.26,
        "涨跌幅": -1.12,
        "开盘价": 23.15,
        "最高价": 23.23,
        "最低价": 22.92,
        "昨收价": 23.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 14615.0,
        "成交额": 336850.0,
        "振幅": 1.34,
        "换手率": null,
        "symbol": "106.SOJC"
    },
    {
        "序号": 10225,
        "name": "Nuveen Virginia Quality Municip",
        "最新价": 10.57,
        "涨跌额": -0.12,
        "涨跌幅": -1.12,
        "开盘价": 10.66,
        "最高价": 10.66,
        "最低价": 10.5,
        "昨收价": 10.69,
        "总市值": null,
        "市盈率": null,
        "成交量": 68977.0,
        "成交额": 729627.0,
        "振幅": 1.5,
        "换手率": null,
        "symbol": "106.NPV"
    },
    {
        "序号": 10226,
        "name": "iShares Global Clean Energy ETF",
        "最新价": 14.09,
        "涨跌额": -0.16,
        "涨跌幅": -1.12,
        "开盘价": 14.23,
        "最高价": 14.35,
        "最低价": 14.01,
        "昨收价": 14.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 2718909.0,
        "成交额": 38466063.0,
        "振幅": 2.39,
        "换手率": null,
        "symbol": "105.ICLN"
    },
    {
        "序号": 10227,
        "name": "休斯敦能源",
        "最新价": 1.76,
        "涨跌额": -0.02,
        "涨跌幅": -1.12,
        "开盘价": 1.77,
        "最高价": 1.79,
        "最低价": 1.73,
        "昨收价": 1.78,
        "总市值": 19195181.0,
        "市盈率": -116.59,
        "成交量": 77922.0,
        "成交额": 137425.0,
        "振幅": 3.37,
        "换手率": 0.71,
        "symbol": "107.HUSA"
    },
    {
        "序号": 10228,
        "name": "COPT Defense Properties",
        "最新价": 24.64,
        "涨跌额": -0.28,
        "涨跌幅": -1.12,
        "开盘价": 24.77,
        "最高价": 24.86,
        "最低价": 24.57,
        "昨收价": 24.92,
        "总市值": 2773140586.0,
        "市盈率": -48.96,
        "成交量": 865380.0,
        "成交额": 21351533.0,
        "振幅": 1.16,
        "换手率": 0.77,
        "symbol": "106.CDP"
    },
    {
        "序号": 10229,
        "name": "Alliance Resource Partners LP",
        "最新价": 19.35,
        "涨跌额": -0.22,
        "涨跌幅": -1.12,
        "开盘价": 19.42,
        "最高价": 19.58,
        "最低价": 19.3,
        "昨收价": 19.57,
        "总市值": 2459877206.0,
        "市盈率": 3.4,
        "成交量": 679179.0,
        "成交额": 13194977.0,
        "振幅": 1.43,
        "换手率": 0.53,
        "symbol": "105.ARLP"
    },
    {
        "序号": 10230,
        "name": "泰勒斯",
        "最新价": 18.46,
        "涨跌额": -0.21,
        "涨跌幅": -1.12,
        "开盘价": 18.7,
        "最高价": 18.7,
        "最低价": 18.38,
        "昨收价": 18.67,
        "总市值": 27006980000.0,
        "市盈率": 44.92,
        "成交量": 1554487.0,
        "成交额": 28739904.0,
        "振幅": 1.71,
        "换手率": 0.11,
        "symbol": "106.TU"
    },
    {
        "序号": 10231,
        "name": "ProShares Ultra Consumer Staple",
        "最新价": 14.91,
        "涨跌额": -0.17,
        "涨跌幅": -1.13,
        "开盘价": 15.08,
        "最高价": 15.08,
        "最低价": 14.91,
        "昨收价": 15.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 6409.0,
        "成交额": 95933.0,
        "振幅": 1.13,
        "换手率": null,
        "symbol": "107.UGE"
    },
    {
        "序号": 10232,
        "name": "餐饮品牌国际",
        "最新价": 71.91,
        "涨跌额": -0.82,
        "涨跌幅": -1.13,
        "开盘价": 72.84,
        "最高价": 73.19,
        "最低价": 71.81,
        "昨收价": 72.73,
        "总市值": 22437975475.0,
        "市盈率": 24.63,
        "成交量": 895950.0,
        "成交额": 64580390.0,
        "振幅": 1.9,
        "换手率": 0.29,
        "symbol": "106.QSR"
    },
    {
        "序号": 10233,
        "name": "Athene Holding Ltd Series A Pfd",
        "最新价": 23.66,
        "涨跌额": -0.27,
        "涨跌幅": -1.13,
        "开盘价": 23.94,
        "最高价": 23.96,
        "最低价": 23.59,
        "昨收价": 23.93,
        "总市值": 816270.0,
        "市盈率": null,
        "成交量": 27890.0,
        "成交额": 661647.0,
        "振幅": 1.55,
        "换手率": 80.84,
        "symbol": "106.ATH_A"
    },
    {
        "序号": 10234,
        "name": "Pinnacle Financial Partners Inc",
        "最新价": 22.69,
        "涨跌额": -0.26,
        "涨跌幅": -1.13,
        "开盘价": 22.5,
        "最高价": 23.25,
        "最低价": 22.4,
        "昨收价": 22.95,
        "总市值": 5105250.0,
        "市盈率": null,
        "成交量": 24193.0,
        "成交额": 547173.0,
        "振幅": 3.7,
        "换手率": 10.75,
        "symbol": "105.PNFPP"
    },
    {
        "序号": 10235,
        "name": "黑石集团",
        "最新价": 112.57,
        "涨跌额": -1.29,
        "涨跌幅": -1.13,
        "开盘价": 113.25,
        "最高价": 114.63,
        "最低价": 111.7,
        "昨收价": 113.86,
        "总市值": 79986013164.0,
        "市盈率": 44.51,
        "成交量": 3436317.0,
        "成交额": 387991840.0,
        "振幅": 2.57,
        "换手率": 0.48,
        "symbol": "106.BX"
    },
    {
        "序号": 10236,
        "name": "The Southern Co Series 2020A No",
        "最新价": 21.8,
        "涨跌额": -0.25,
        "涨跌幅": -1.13,
        "开盘价": 22.07,
        "最高价": 22.13,
        "最低价": 21.76,
        "昨收价": 22.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 94366.0,
        "成交额": 2070771.0,
        "振幅": 1.68,
        "换手率": null,
        "symbol": "106.SOJD"
    },
    {
        "序号": 10237,
        "name": "马尔登运输",
        "最新价": 19.16,
        "涨跌额": -0.22,
        "涨跌幅": -1.14,
        "开盘价": 19.44,
        "最高价": 19.55,
        "最低价": 19.15,
        "昨收价": 19.38,
        "总市值": 1557879827.0,
        "市盈率": 18.66,
        "成交量": 194430.0,
        "成交额": 3737444.0,
        "振幅": 2.06,
        "换手率": 0.24,
        "symbol": "105.MRTN"
    },
    {
        "序号": 10238,
        "name": "国际银行",
        "最新价": 48.75,
        "涨跌额": -0.56,
        "涨跌幅": -1.14,
        "开盘价": 49.3,
        "最高价": 49.61,
        "最低价": 48.14,
        "昨收价": 49.31,
        "总市值": 3025350071.0,
        "市盈率": 7.36,
        "成交量": 271503.0,
        "成交额": 13275214.0,
        "振幅": 2.98,
        "换手率": 0.44,
        "symbol": "105.IBOC"
    },
    {
        "序号": 10239,
        "name": "The LGL Group Inc",
        "最新价": 5.22,
        "涨跌额": -0.06,
        "涨跌幅": -1.14,
        "开盘价": 5.25,
        "最高价": 5.39,
        "最低价": 5.2,
        "昨收价": 5.28,
        "总市值": 27942331.0,
        "市盈率": 120.96,
        "成交量": 9463.0,
        "成交额": 50188.0,
        "振幅": 3.6,
        "换手率": 0.18,
        "symbol": "107.LGL"
    },
    {
        "序号": 10240,
        "name": "Summit Hotel Properties Inc Ser",
        "最新价": 18.26,
        "涨跌额": -0.21,
        "涨跌幅": -1.14,
        "开盘价": 18.47,
        "最高价": 18.55,
        "最低价": 18.25,
        "昨收价": 18.47,
        "总市值": 73040000.0,
        "市盈率": null,
        "成交量": 2567.0,
        "成交额": 47142.0,
        "振幅": 1.62,
        "换手率": 0.06,
        "symbol": "106.INN_F"
    },
    {
        "序号": 10241,
        "name": "NextEra Energy Inc Series N Deb",
        "最新价": 24.22,
        "涨跌额": -0.28,
        "涨跌幅": -1.14,
        "开盘价": 24.5,
        "最高价": 24.56,
        "最低价": 24.2,
        "昨收价": 24.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 65524.0,
        "成交额": 1594854.0,
        "振幅": 1.47,
        "换手率": null,
        "symbol": "106.NEE_N"
    },
    {
        "序号": 10242,
        "name": "MFS High Income Municipal Trust",
        "最新价": 3.46,
        "涨跌额": -0.04,
        "涨跌幅": -1.14,
        "开盘价": 3.49,
        "最高价": 3.49,
        "最低价": 3.44,
        "昨收价": 3.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 88300.0,
        "成交额": 305746.0,
        "振幅": 1.43,
        "换手率": null,
        "symbol": "106.CXE"
    },
    {
        "序号": 10243,
        "name": "Oaktree Capital Group LLC Serie",
        "最新价": 21.56,
        "涨跌额": -0.25,
        "涨跌幅": -1.15,
        "开盘价": 21.64,
        "最高价": 21.85,
        "最低价": 21.55,
        "昨收价": 21.81,
        "总市值": 155232000.0,
        "市盈率": null,
        "成交量": 3884.0,
        "成交额": 84073.0,
        "振幅": 1.38,
        "换手率": 0.05,
        "symbol": "106.OAK_A"
    },
    {
        "序号": 10244,
        "name": "Federal Agricultural Mortgage C",
        "最新价": 20.69,
        "涨跌额": -0.24,
        "涨跌幅": -1.15,
        "开盘价": 20.93,
        "最高价": 20.94,
        "最低价": 20.42,
        "昨收价": 20.93,
        "总市值": 99312000.0,
        "市盈率": null,
        "成交量": 20803.0,
        "成交额": 432922.0,
        "振幅": 2.48,
        "换手率": 0.43,
        "symbol": "106.AGM_F"
    },
    {
        "序号": 10245,
        "name": "阿尔凯默斯",
        "最新价": 25.86,
        "涨跌额": -0.3,
        "涨跌幅": -1.15,
        "开盘价": 26.09,
        "最高价": 26.36,
        "最低价": 25.75,
        "昨收价": 26.16,
        "总市值": 4315550056.0,
        "市盈率": 20.1,
        "成交量": 2265926.0,
        "成交额": 58814538.0,
        "振幅": 2.33,
        "换手率": 1.36,
        "symbol": "105.ALKS"
    },
    {
        "序号": 10246,
        "name": "中国ETF-iShares MSCI",
        "最新价": 40.45,
        "涨跌额": -0.47,
        "涨跌幅": -1.15,
        "开盘价": 40.53,
        "最高价": 40.66,
        "最低价": 40.38,
        "昨收价": 40.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 3990464.0,
        "成交额": 161558461.0,
        "振幅": 0.68,
        "换手率": null,
        "symbol": "105.MCHI"
    },
    {
        "序号": 10247,
        "name": "Kezar Life Sciences Inc",
        "最新价": 0.86,
        "涨跌额": -0.01,
        "涨跌幅": -1.15,
        "开盘价": 0.88,
        "最高价": 0.89,
        "最低价": 0.85,
        "昨收价": 0.87,
        "总市值": 62515948.0,
        "市盈率": -0.71,
        "成交量": 853295.0,
        "成交额": 741884.0,
        "振幅": 4.6,
        "换手率": 1.17,
        "symbol": "105.KZR"
    },
    {
        "序号": 10248,
        "name": "优点互动",
        "最新价": 1.72,
        "涨跌额": -0.02,
        "涨跌幅": -1.15,
        "开盘价": 1.73,
        "最高价": 1.77,
        "最低价": 1.68,
        "昨收价": 1.74,
        "总市值": 20592435.0,
        "市盈率": -0.06,
        "成交量": 219164.0,
        "成交额": 376558.0,
        "振幅": 5.17,
        "换手率": 1.83,
        "symbol": "105.IDEX"
    },
    {
        "序号": 10249,
        "name": "Huntington Bancshares Inc Serie",
        "最新价": 18.06,
        "涨跌额": -0.21,
        "涨跌幅": -1.15,
        "开盘价": 18.22,
        "最高价": 18.33,
        "最低价": 17.97,
        "昨收价": 18.27,
        "总市值": 9030000.0,
        "市盈率": null,
        "成交量": 46869.0,
        "成交额": 849047.0,
        "振幅": 1.97,
        "换手率": 9.37,
        "symbol": "105.HBANP"
    },
    {
        "序号": 10250,
        "name": "安捷伦",
        "最新价": 127.2,
        "涨跌额": -1.48,
        "涨跌幅": -1.15,
        "开盘价": 128.09,
        "最高价": 129.0,
        "最低价": 126.97,
        "昨收价": 128.68,
        "总市值": 37158076255.0,
        "市盈率": 29.97,
        "成交量": 1438074.0,
        "成交额": 183359900.0,
        "振幅": 1.58,
        "换手率": 0.49,
        "symbol": "106.A"
    },
    {
        "序号": 10251,
        "name": "中国能源指数ETF-Global X",
        "最新价": 15.45,
        "涨跌额": -0.18,
        "涨跌幅": -1.15,
        "开盘价": 15.54,
        "最高价": 15.54,
        "最低价": 15.41,
        "昨收价": 15.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 2461.0,
        "成交额": 38054.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.CHIE"
    },
    {
        "序号": 10252,
        "name": "NACCO Industries Inc-A",
        "最新价": 34.31,
        "涨跌额": -0.4,
        "涨跌幅": -1.15,
        "开盘价": 35.0,
        "最高价": 35.0,
        "最低价": 33.87,
        "昨收价": 34.71,
        "总市值": 257512401.0,
        "市盈率": 14.18,
        "成交量": 18182.0,
        "成交额": 623003.0,
        "振幅": 3.26,
        "换手率": 0.24,
        "symbol": "106.NC"
    },
    {
        "序号": 10253,
        "name": "三倍做多新兴市场ETF-Direxion",
        "最新价": 26.58,
        "涨跌额": -0.31,
        "涨跌幅": -1.15,
        "开盘价": 26.42,
        "最高价": 26.78,
        "最低价": 26.23,
        "昨收价": 26.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 181099.0,
        "成交额": 4801278.0,
        "振幅": 2.05,
        "换手率": null,
        "symbol": "107.EDC"
    },
    {
        "序号": 10254,
        "name": "NXG Cushing Midstream Energy Fu",
        "最新价": 34.22,
        "涨跌额": -0.4,
        "涨跌幅": -1.16,
        "开盘价": 34.55,
        "最高价": 34.7,
        "最低价": 34.05,
        "昨收价": 34.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 36455.0,
        "成交额": 1255771.0,
        "振幅": 1.88,
        "换手率": null,
        "symbol": "106.SRV"
    },
    {
        "序号": 10255,
        "name": "DoubleLine Income Solutions Fun",
        "最新价": 11.97,
        "涨跌额": -0.14,
        "涨跌幅": -1.16,
        "开盘价": 12.08,
        "最高价": 12.08,
        "最低价": 11.95,
        "昨收价": 12.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 279299.0,
        "成交额": 3355742.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "106.DSL"
    },
    {
        "序号": 10256,
        "name": "ProShares UltraShort FTSE Europ",
        "最新价": 9.4,
        "涨跌额": -0.11,
        "涨跌幅": -1.16,
        "开盘价": 9.5,
        "最高价": 9.51,
        "最低价": 9.38,
        "昨收价": 9.51,
        "总市值": null,
        "市盈率": null,
        "成交量": 152784.0,
        "成交额": 1441602.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.EPV"
    },
    {
        "序号": 10257,
        "name": "Maravai LifeSciences Holdings I",
        "最新价": 5.12,
        "涨跌额": -0.06,
        "涨跌幅": -1.16,
        "开盘价": 5.19,
        "最高价": 5.19,
        "最低价": 5.02,
        "昨收价": 5.18,
        "总市值": 1286567209.0,
        "市盈率": 52.38,
        "成交量": 1421392.0,
        "成交额": 7270715.0,
        "振幅": 3.28,
        "换手率": 0.57,
        "symbol": "105.MRVI"
    },
    {
        "序号": 10258,
        "name": "CytoMed Therapeutics Ltd",
        "最新价": 3.41,
        "涨跌额": -0.04,
        "涨跌幅": -1.16,
        "开盘价": 3.45,
        "最高价": 3.5,
        "最低价": 3.33,
        "昨收价": 3.45,
        "总市值": 39315008.0,
        "市盈率": -12.24,
        "成交量": 7990.0,
        "成交额": 27436.0,
        "振幅": 4.93,
        "换手率": 0.07,
        "symbol": "105.GDTC"
    },
    {
        "序号": 10259,
        "name": "American Financial Group Inc De",
        "最新价": 22.98,
        "涨跌额": -0.27,
        "涨跌幅": -1.16,
        "开盘价": 23.19,
        "最高价": 23.19,
        "最低价": 22.9,
        "昨收价": 23.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 9867.0,
        "成交额": 227202.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "106.AFGB"
    },
    {
        "序号": 10260,
        "name": "The Gabelli Dividend & Income T",
        "最新价": 18.72,
        "涨跌额": -0.22,
        "涨跌幅": -1.16,
        "开盘价": 18.93,
        "最高价": 18.96,
        "最低价": 18.71,
        "昨收价": 18.94,
        "总市值": 109047725.0,
        "市盈率": null,
        "成交量": 24305.0,
        "成交额": 457850.0,
        "振幅": 1.32,
        "换手率": 0.42,
        "symbol": "106.GDV_K"
    },
    {
        "序号": 10261,
        "name": "Global Technology Acquisition C",
        "最新价": 11.06,
        "涨跌额": -0.13,
        "涨跌幅": -1.16,
        "开盘价": 10.95,
        "最高价": 11.29,
        "最低价": 10.95,
        "昨收价": 11.19,
        "总市值": 78415356.0,
        "市盈率": 12.27,
        "成交量": 31425.0,
        "成交额": 350484.0,
        "振幅": 3.04,
        "换手率": null,
        "symbol": "105.GTACU"
    },
    {
        "序号": 10262,
        "name": "EnviroStar Inc",
        "最新价": 26.37,
        "涨跌额": -0.31,
        "涨跌幅": -1.16,
        "开盘价": 26.65,
        "最高价": 26.69,
        "最低价": 26.26,
        "昨收价": 26.68,
        "总市值": 368453454.0,
        "市盈率": 45.19,
        "成交量": 10576.0,
        "成交额": 280029.0,
        "振幅": 1.61,
        "换手率": 0.08,
        "symbol": "107.EVI"
    },
    {
        "序号": 10263,
        "name": "Performance Food Group Co",
        "最新价": 65.45,
        "涨跌额": -0.77,
        "涨跌幅": -1.16,
        "开盘价": 66.19,
        "最高价": 66.59,
        "最低价": 65.18,
        "昨收价": 66.22,
        "总市值": 10184081719.0,
        "市盈率": 24.12,
        "成交量": 802203.0,
        "成交额": 52544524.0,
        "振幅": 2.13,
        "换手率": 0.52,
        "symbol": "106.PFGC"
    },
    {
        "序号": 10264,
        "name": "Lead Real Estate Co Ltd ADR",
        "最新价": 6.8,
        "涨跌额": -0.08,
        "涨跌幅": -1.16,
        "开盘价": 6.87,
        "最高价": 6.89,
        "最低价": 6.6,
        "昨收价": 6.88,
        "总市值": 92764920.0,
        "市盈率": 24.92,
        "成交量": 2658.0,
        "成交额": 17882.0,
        "振幅": 4.22,
        "换手率": 0.02,
        "symbol": "105.LRE"
    },
    {
        "序号": 10265,
        "name": "Hayward Holdings Inc",
        "最新价": 12.75,
        "涨跌额": -0.15,
        "涨跌幅": -1.16,
        "开盘价": 12.8,
        "最高价": 13.03,
        "最低价": 12.63,
        "昨收价": 12.9,
        "总市值": 2724662990.0,
        "市盈率": 41.52,
        "成交量": 1549957.0,
        "成交额": 19768172.0,
        "振幅": 3.1,
        "换手率": 0.73,
        "symbol": "106.HAYW"
    },
    {
        "序号": 10266,
        "name": "Citius Pharmaceuticals Inc",
        "最新价": 0.85,
        "涨跌额": -0.01,
        "涨跌幅": -1.16,
        "开盘价": 0.86,
        "最高价": 0.87,
        "最低价": 0.84,
        "昨收价": 0.86,
        "总市值": 135029128.0,
        "市盈率": -4.41,
        "成交量": 368204.0,
        "成交额": 316831.0,
        "振幅": 3.49,
        "换手率": 0.23,
        "symbol": "105.CTXR"
    },
    {
        "序号": 10267,
        "name": "Context Therapeutics Inc",
        "最新价": 0.85,
        "涨跌额": -0.01,
        "涨跌幅": -1.16,
        "开盘价": 0.89,
        "最高价": 0.95,
        "最低价": 0.79,
        "昨收价": 0.86,
        "总市值": 13571145.0,
        "市盈率": -0.65,
        "成交量": 19375.0,
        "成交额": 16877.0,
        "振幅": 18.6,
        "换手率": 0.12,
        "symbol": "105.CNTX"
    },
    {
        "序号": 10268,
        "name": "史提宾",
        "最新价": 88.3,
        "涨跌额": -1.04,
        "涨跌幅": -1.16,
        "开盘价": 89.52,
        "最高价": 90.58,
        "最低价": 86.59,
        "昨收价": 89.34,
        "总市值": 1975039742.0,
        "市盈率": 37.81,
        "成交量": 93559.0,
        "成交额": 8292152.0,
        "振幅": 4.47,
        "换手率": 0.42,
        "symbol": "106.SCL"
    },
    {
        "序号": 10269,
        "name": "Brighthouse Financial Inc Serie",
        "最新价": 21.2,
        "涨跌额": -0.25,
        "涨跌幅": -1.17,
        "开盘价": 21.5,
        "最高价": 21.59,
        "最低价": 21.16,
        "昨收价": 21.45,
        "总市值": 360400.0,
        "市盈率": null,
        "成交量": 40137.0,
        "成交额": 855865.0,
        "振幅": 2.0,
        "换手率": 236.1,
        "symbol": "105.BHFAP"
    },
    {
        "序号": 10270,
        "name": "玉柴国际",
        "最新价": 8.47,
        "涨跌额": -0.1,
        "涨跌幅": -1.17,
        "开盘价": 8.57,
        "最高价": 8.88,
        "最低价": 8.46,
        "昨收价": 8.57,
        "总市值": 346069716.0,
        "市盈率": 8.22,
        "成交量": 14192.0,
        "成交额": 121693.0,
        "振幅": 4.9,
        "换手率": 0.03,
        "symbol": "106.CYD"
    },
    {
        "序号": 10271,
        "name": "Zevra Therapeutics Inc",
        "最新价": 5.08,
        "涨跌额": -0.06,
        "涨跌幅": -1.17,
        "开盘价": 5.13,
        "最高价": 5.28,
        "最低价": 5.04,
        "昨收价": 5.14,
        "总市值": 210745898.0,
        "市盈率": -5.28,
        "成交量": 137392.0,
        "成交额": 706812.0,
        "振幅": 4.67,
        "换手率": 0.33,
        "symbol": "105.ZVRA"
    },
    {
        "序号": 10272,
        "name": "Taitron Components Inc-A",
        "最新价": 3.37,
        "涨跌额": -0.04,
        "涨跌幅": -1.17,
        "开盘价": 3.44,
        "最高价": 3.44,
        "最低价": 3.37,
        "昨收价": 3.41,
        "总市值": 20291377.0,
        "市盈率": 11.44,
        "成交量": 4660.0,
        "成交额": 15792.0,
        "振幅": 2.05,
        "换手率": 0.08,
        "symbol": "105.TAIT"
    },
    {
        "序号": 10273,
        "name": "艾利丹尼森",
        "最新价": 191.71,
        "涨跌额": -2.28,
        "涨跌幅": -1.18,
        "开盘价": 193.78,
        "最高价": 194.89,
        "最低价": 191.44,
        "昨收价": 193.99,
        "总市值": 15438651305.0,
        "市盈率": 31.98,
        "成交量": 330446.0,
        "成交额": 63524805.0,
        "振幅": 1.78,
        "换手率": 0.41,
        "symbol": "106.AVY"
    },
    {
        "序号": 10274,
        "name": "SCYNEXIS Inc",
        "最新价": 1.68,
        "涨跌额": -0.02,
        "涨跌幅": -1.18,
        "开盘价": 1.69,
        "最高价": 1.74,
        "最低价": 1.66,
        "昨收价": 1.7,
        "总市值": 62509102.0,
        "市盈率": 0.87,
        "成交量": 140586.0,
        "成交额": 237346.0,
        "振幅": 4.71,
        "换手率": 0.38,
        "symbol": "105.SCYX"
    },
    {
        "序号": 10275,
        "name": "Allspring Utilities and High In",
        "最新价": 9.24,
        "涨跌额": -0.11,
        "涨跌幅": -1.18,
        "开盘价": 9.32,
        "最高价": 9.32,
        "最低价": 9.22,
        "昨收价": 9.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 27426.0,
        "成交额": 254482.0,
        "振幅": 1.07,
        "换手率": null,
        "symbol": "107.ERH"
    },
    {
        "序号": 10276,
        "name": "Companhia Brasileira de Distrib",
        "最新价": 0.84,
        "涨跌额": -0.01,
        "涨跌幅": -1.18,
        "开盘价": 0.85,
        "最高价": 0.85,
        "最低价": 0.8,
        "昨收价": 0.85,
        "总市值": 226782679.0,
        "市盈率": -0.53,
        "成交量": 568262.0,
        "成交额": 471899.0,
        "振幅": 5.88,
        "换手率": 0.21,
        "symbol": "106.CBD"
    },
    {
        "序号": 10277,
        "name": "Carisma Therapeutics Inc",
        "最新价": 2.52,
        "涨跌额": -0.03,
        "涨跌幅": -1.18,
        "开盘价": 2.56,
        "最高价": 2.61,
        "最低价": 2.46,
        "昨收价": 2.55,
        "总市值": 101699151.0,
        "市盈率": -2.46,
        "成交量": 132946.0,
        "成交额": 336115.0,
        "振幅": 5.88,
        "换手率": 0.33,
        "symbol": "105.CARM"
    },
    {
        "序号": 10278,
        "name": "乌龙制药",
        "最新价": 14.27,
        "涨跌额": -0.17,
        "涨跌幅": -1.18,
        "开盘价": 14.37,
        "最高价": 14.61,
        "最低价": 13.93,
        "昨收价": 14.44,
        "总市值": 440462386.0,
        "市盈率": -4.19,
        "成交量": 144742.0,
        "成交额": 2057404.0,
        "振幅": 4.71,
        "换手率": 0.47,
        "symbol": "105.URGN"
    },
    {
        "序号": 10279,
        "name": "ProPhase Labs Inc",
        "最新价": 5.03,
        "涨跌额": -0.06,
        "涨跌幅": -1.18,
        "开盘价": 5.01,
        "最高价": 5.33,
        "最低价": 4.95,
        "昨收价": 5.09,
        "总市值": 90766496.0,
        "市盈率": -8.67,
        "成交量": 49559.0,
        "成交额": 259241.0,
        "振幅": 7.47,
        "换手率": 0.27,
        "symbol": "105.PRPH"
    },
    {
        "序号": 10280,
        "name": "MFS Municipal Income Trust",
        "最新价": 5.02,
        "涨跌额": -0.06,
        "涨跌幅": -1.18,
        "开盘价": 5.07,
        "最高价": 5.07,
        "最低价": 5.01,
        "昨收价": 5.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 208458.0,
        "成交额": 1046850.0,
        "振幅": 1.18,
        "换手率": null,
        "symbol": "106.MFM"
    },
    {
        "序号": 10281,
        "name": "IDEAYA生物科学",
        "最新价": 30.89,
        "涨跌额": -0.37,
        "涨跌幅": -1.18,
        "开盘价": 31.39,
        "最高价": 32.13,
        "最低价": 30.81,
        "昨收价": 31.26,
        "总市值": 1991153090.0,
        "市盈率": -19.29,
        "成交量": 895697.0,
        "成交额": 28036336.0,
        "振幅": 4.22,
        "换手率": 1.39,
        "symbol": "105.IDYA"
    },
    {
        "序号": 10282,
        "name": "Huntington Bancshares Inc Serie",
        "最新价": 23.35,
        "涨跌额": -0.28,
        "涨跌幅": -1.18,
        "开盘价": 23.55,
        "最高价": 23.77,
        "最低价": 23.28,
        "昨收价": 23.63,
        "总市值": 7588750.0,
        "市盈率": null,
        "成交量": 29585.0,
        "成交额": 695656.0,
        "振幅": 2.07,
        "换手率": 9.1,
        "symbol": "105.HBANL"
    },
    {
        "序号": 10283,
        "name": "RiverNorth\/DoubleLine Strategic",
        "最新价": 8.32,
        "涨跌额": -0.1,
        "涨跌幅": -1.19,
        "开盘价": 8.37,
        "最高价": 8.4,
        "最低价": 8.26,
        "昨收价": 8.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 65056.0,
        "成交额": 541974.0,
        "振幅": 1.66,
        "换手率": null,
        "symbol": "106.OPP"
    },
    {
        "序号": 10284,
        "name": "科佩斯控股",
        "最新价": 44.08,
        "涨跌额": -0.53,
        "涨跌幅": -1.19,
        "开盘价": 44.36,
        "最高价": 44.95,
        "最低价": 43.83,
        "昨收价": 44.61,
        "总市值": 917880749.0,
        "市盈率": 10.19,
        "成交量": 91193.0,
        "成交额": 4025951.0,
        "振幅": 2.51,
        "换手率": 0.44,
        "symbol": "106.KOP"
    },
    {
        "序号": 10285,
        "name": "奥科",
        "最新价": 11.64,
        "涨跌额": -0.14,
        "涨跌幅": -1.19,
        "开盘价": 11.76,
        "最高价": 11.82,
        "最低价": 11.51,
        "昨收价": 11.78,
        "总市值": 362175469.0,
        "市盈率": 28.58,
        "成交量": 121411.0,
        "成交额": 1411083.0,
        "振幅": 2.63,
        "换手率": 0.39,
        "symbol": "105.AUDC"
    },
    {
        "序号": 10286,
        "name": "Columbia Seligman Premium Techn",
        "最新价": 29.9,
        "涨跌额": -0.36,
        "涨跌幅": -1.19,
        "开盘价": 30.18,
        "最高价": 30.35,
        "最低价": 29.83,
        "昨收价": 30.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 31141.0,
        "成交额": 936456.0,
        "振幅": 1.72,
        "换手率": null,
        "symbol": "106.STK"
    },
    {
        "序号": 10287,
        "name": "美华国际医疗",
        "最新价": 1.66,
        "涨跌额": -0.02,
        "涨跌幅": -1.19,
        "开盘价": 1.68,
        "最高价": 1.7,
        "最低价": 1.6,
        "昨收价": 1.68,
        "总市值": 39740400.0,
        "市盈率": 5.89,
        "成交量": 282869.0,
        "成交额": 466132.0,
        "振幅": 5.95,
        "换手率": 1.18,
        "symbol": "105.MHUA"
    },
    {
        "序号": 10288,
        "name": "PRA Group Inc",
        "最新价": 20.73,
        "涨跌额": -0.25,
        "涨跌幅": -1.19,
        "开盘价": 21.0,
        "最高价": 21.44,
        "最低价": 20.7,
        "昨收价": 20.98,
        "总市值": 813531126.0,
        "市盈率": -13.85,
        "成交量": 233017.0,
        "成交额": 4901826.0,
        "振幅": 3.53,
        "换手率": 0.59,
        "symbol": "105.PRAA"
    },
    {
        "序号": 10289,
        "name": "二倍做多天然气ETF-ProShares",
        "最新价": 29.83,
        "涨跌额": -0.36,
        "涨跌幅": -1.19,
        "开盘价": 30.39,
        "最高价": 31.11,
        "最低价": 29.46,
        "昨收价": 30.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 5867259.0,
        "成交额": 177526627.0,
        "振幅": 5.47,
        "换手率": null,
        "symbol": "107.BOIL"
    },
    {
        "序号": 10290,
        "name": "能源方案",
        "最新价": 59.65,
        "涨跌额": -0.72,
        "涨跌幅": -1.19,
        "开盘价": 60.45,
        "最高价": 60.68,
        "最低价": 59.47,
        "昨收价": 60.37,
        "总市值": 20837308367.0,
        "市盈率": 17.86,
        "成交量": 1923481.0,
        "成交额": 114973746.0,
        "振幅": 2.0,
        "换手率": 0.55,
        "symbol": "106.ES"
    },
    {
        "序号": 10291,
        "name": "Apogee Therapeutics Inc",
        "最新价": 21.48,
        "涨跌额": -0.26,
        "涨跌幅": -1.2,
        "开盘价": 21.67,
        "最高价": 22.94,
        "最低价": 20.62,
        "昨收价": 21.74,
        "总市值": 1088483878.0,
        "市盈率": null,
        "成交量": 437172.0,
        "成交额": 9517114.0,
        "振幅": 10.67,
        "换手率": 0.86,
        "symbol": "105.APGE"
    },
    {
        "序号": 10292,
        "name": "自流资源",
        "最新价": 43.78,
        "涨跌额": -0.53,
        "涨跌幅": -1.2,
        "开盘价": 44.38,
        "最高价": 44.64,
        "最低价": 43.47,
        "昨收价": 44.31,
        "总市值": 449926929.0,
        "市盈率": 28.95,
        "成交量": 36299.0,
        "成交额": 1599658.0,
        "振幅": 2.64,
        "换手率": 0.35,
        "symbol": "105.ARTNA"
    },
    {
        "序号": 10293,
        "name": "Puma Biotechnology Inc",
        "最新价": 4.13,
        "涨跌额": -0.05,
        "涨跌幅": -1.2,
        "开盘价": 4.25,
        "最高价": 4.3,
        "最低价": 4.11,
        "昨收价": 4.18,
        "总市值": 196469601.0,
        "市盈率": 52.86,
        "成交量": 155772.0,
        "成交额": 655110.0,
        "振幅": 4.55,
        "换手率": 0.33,
        "symbol": "105.PBYI"
    },
    {
        "序号": 10294,
        "name": "因特瓦克",
        "最新价": 4.13,
        "涨跌额": -0.05,
        "涨跌幅": -1.2,
        "开盘价": 4.12,
        "最高价": 4.22,
        "最低价": 4.02,
        "昨收价": 4.18,
        "总市值": 108922365.0,
        "市盈率": -8.06,
        "成交量": 29443.0,
        "成交额": 121914.0,
        "振幅": 4.78,
        "换手率": 0.11,
        "symbol": "105.IVAC"
    },
    {
        "序号": 10295,
        "name": "Nuveen Dynamic Municipal Opport",
        "最新价": 9.91,
        "涨跌额": -0.12,
        "涨跌幅": -1.2,
        "开盘价": 9.95,
        "最高价": 10.04,
        "最低价": 9.9,
        "昨收价": 10.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 249309.0,
        "成交额": 2481672.0,
        "振幅": 1.4,
        "换手率": null,
        "symbol": "106.NDMO"
    },
    {
        "序号": 10296,
        "name": "希悦尔",
        "最新价": 33.81,
        "涨跌额": -0.41,
        "涨跌幅": -1.2,
        "开盘价": 34.16,
        "最高价": 34.5,
        "最低价": 33.74,
        "昨收价": 34.22,
        "总市值": 4883368109.0,
        "市盈率": 15.66,
        "成交量": 1489483.0,
        "成交额": 50618804.0,
        "振幅": 2.22,
        "换手率": 1.03,
        "symbol": "106.SEE"
    },
    {
        "序号": 10297,
        "name": "三倍做空纳斯达克100ETF",
        "最新价": 15.66,
        "涨跌额": -0.19,
        "涨跌幅": -1.2,
        "开盘价": 16.07,
        "最高价": 16.1,
        "最低价": 15.6,
        "昨收价": 15.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 128284558.0,
        "成交额": 2023324832.0,
        "振幅": 3.15,
        "换手率": null,
        "symbol": "105.SQQQ"
    },
    {
        "序号": 10298,
        "name": "Shake Shack Inc-A",
        "最新价": 60.98,
        "涨跌额": -0.74,
        "涨跌幅": -1.2,
        "开盘价": 61.24,
        "最高价": 62.08,
        "最低价": 60.84,
        "昨收价": 61.72,
        "总市值": 2579598767.0,
        "市盈率": 1109.98,
        "成交量": 621520.0,
        "成交额": 38066719.0,
        "振幅": 2.01,
        "换手率": 1.47,
        "symbol": "106.SHAK"
    },
    {
        "序号": 10299,
        "name": "iShares 25+ Year Treasury STRIP",
        "最新价": 11.53,
        "涨跌额": -0.14,
        "涨跌幅": -1.2,
        "开盘价": 11.51,
        "最高价": 11.58,
        "最低价": 11.43,
        "昨收价": 11.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 205624.0,
        "成交额": 2364935.0,
        "振幅": 1.29,
        "换手率": null,
        "symbol": "107.GOVZ"
    },
    {
        "序号": 10300,
        "name": "JB亨特运输服务",
        "最新价": 186.62,
        "涨跌额": -2.27,
        "涨跌幅": -1.2,
        "开盘价": 188.5,
        "最高价": 189.79,
        "最低价": 185.69,
        "昨收价": 188.89,
        "总市值": 19248510269.0,
        "市盈率": 24.8,
        "成交量": 721008.0,
        "成交额": 134802298.0,
        "振幅": 2.17,
        "换手率": 0.7,
        "symbol": "105.JBHT"
    },
    {
        "序号": 10301,
        "name": "Nuveen Mortgage and Income Fund",
        "最新价": 17.24,
        "涨跌额": -0.21,
        "涨跌幅": -1.2,
        "开盘价": 17.51,
        "最高价": 17.51,
        "最低价": 17.13,
        "昨收价": 17.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 20846.0,
        "成交额": 360967.0,
        "振幅": 2.18,
        "换手率": null,
        "symbol": "106.JLS"
    },
    {
        "序号": 10302,
        "name": "Ollie's Bargain Outlet Holdings",
        "最新价": 72.2,
        "涨跌额": -0.88,
        "涨跌幅": -1.2,
        "开盘价": 72.69,
        "最高价": 73.84,
        "最低价": 71.35,
        "昨收价": 73.08,
        "总市值": 4446962038.0,
        "市盈率": 28.14,
        "成交量": 1172100.0,
        "成交额": 84447234.0,
        "振幅": 3.41,
        "换手率": 1.9,
        "symbol": "105.OLLI"
    },
    {
        "序号": 10303,
        "name": "Myers Industries Inc",
        "最新价": 18.05,
        "涨跌额": -0.22,
        "涨跌幅": -1.2,
        "开盘价": 18.21,
        "最高价": 18.34,
        "最低价": 17.98,
        "昨收价": 18.27,
        "总市值": 664934311.0,
        "市盈率": 13.36,
        "成交量": 164885.0,
        "成交额": 2984260.0,
        "振幅": 1.97,
        "换手率": 0.45,
        "symbol": "106.MYE"
    },
    {
        "序号": 10304,
        "name": "SpartanNash Co",
        "最新价": 22.94,
        "涨跌额": -0.28,
        "涨跌幅": -1.21,
        "开盘价": 23.31,
        "最高价": 23.46,
        "最低价": 22.93,
        "昨收价": 23.22,
        "总市值": 794310805.0,
        "市盈率": 18.65,
        "成交量": 144205.0,
        "成交额": 3329029.0,
        "振幅": 2.28,
        "换手率": 0.42,
        "symbol": "105.SPTN"
    },
    {
        "序号": 10305,
        "name": "USCF Gold Strategy Plus Income ",
        "最新价": 25.39,
        "涨跌额": -0.31,
        "涨跌幅": -1.21,
        "开盘价": 25.56,
        "最高价": 25.56,
        "最低价": 25.39,
        "昨收价": 25.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 472.0,
        "成交额": 12064.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.GLDX"
    },
    {
        "序号": 10306,
        "name": "布鲁克",
        "最新价": 67.97,
        "涨跌额": -0.83,
        "涨跌幅": -1.21,
        "开盘价": 68.41,
        "最高价": 69.73,
        "最低价": 67.78,
        "昨收价": 68.8,
        "总市值": 9407810080.0,
        "市盈率": 29.48,
        "成交量": 782576.0,
        "成交额": 53349820.0,
        "振幅": 2.83,
        "换手率": 0.57,
        "symbol": "105.BRKR"
    },
    {
        "序号": 10307,
        "name": "美国超长期国债ETF-Vanguard",
        "最新价": 76.65,
        "涨跌额": -0.94,
        "涨跌幅": -1.21,
        "开盘价": 76.59,
        "最高价": 76.85,
        "最低价": 75.96,
        "昨收价": 77.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 1033839.0,
        "成交额": 79070238.0,
        "振幅": 1.15,
        "换手率": null,
        "symbol": "107.EDV"
    },
    {
        "序号": 10308,
        "name": "西尔格控股",
        "最新价": 42.32,
        "涨跌额": -0.52,
        "涨跌幅": -1.21,
        "开盘价": 42.66,
        "最高价": 42.88,
        "最低价": 42.27,
        "昨收价": 42.84,
        "总市值": 4507013812.0,
        "市盈率": 15.75,
        "成交量": 401072.0,
        "成交额": 17021869.0,
        "振幅": 1.42,
        "换手率": 0.38,
        "symbol": "106.SLGN"
    },
    {
        "序号": 10309,
        "name": "中国ETF-SPDR S&P",
        "最新价": 67.42,
        "涨跌额": -0.83,
        "涨跌幅": -1.22,
        "开盘价": 67.62,
        "最高价": 67.9,
        "最低价": 67.36,
        "昨收价": 68.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 134777.0,
        "成交额": 9104754.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.GXC"
    },
    {
        "序号": 10310,
        "name": "AT&T Inc Notes",
        "最新价": 23.52,
        "涨跌额": -0.29,
        "涨跌幅": -1.22,
        "开盘价": 23.78,
        "最高价": 23.87,
        "最低价": 23.5,
        "昨收价": 23.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 49153.0,
        "成交额": 1161831.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "106.TBC"
    },
    {
        "序号": 10311,
        "name": "ClearOne通讯",
        "最新价": 0.81,
        "涨跌额": -0.01,
        "涨跌幅": -1.22,
        "开盘价": 0.81,
        "最高价": 0.83,
        "最低价": 0.8,
        "昨收价": 0.82,
        "总市值": 19408154.0,
        "市盈率": 0.93,
        "成交量": 14846.0,
        "成交额": 12014.0,
        "振幅": 3.66,
        "换手率": 0.06,
        "symbol": "105.CLRO"
    },
    {
        "序号": 10312,
        "name": "金宝汤",
        "最新价": 42.91,
        "涨跌额": -0.53,
        "涨跌幅": -1.22,
        "开盘价": 43.43,
        "最高价": 43.53,
        "最低价": 42.73,
        "昨收价": 43.44,
        "总市值": 12791463920.0,
        "市盈率": 16.09,
        "成交量": 2844400.0,
        "成交额": 122218261.0,
        "振幅": 1.84,
        "换手率": 0.95,
        "symbol": "106.CPB"
    },
    {
        "序号": 10313,
        "name": "Tradeweb Markets Inc-A",
        "最新价": 90.35,
        "涨跌额": -1.12,
        "涨跌幅": -1.22,
        "开盘价": 91.39,
        "最高价": 92.0,
        "最低价": 89.86,
        "昨收价": 91.47,
        "总市值": 21208710030.0,
        "市盈率": 58.19,
        "成交量": 1424550.0,
        "成交额": 128857546.0,
        "振幅": 2.34,
        "换手率": 0.61,
        "symbol": "105.TW"
    },
    {
        "序号": 10314,
        "name": "MGE Energy Inc",
        "最新价": 72.6,
        "涨跌额": -0.9,
        "涨跌幅": -1.22,
        "开盘价": 73.12,
        "最高价": 73.26,
        "最低价": 71.68,
        "昨收价": 73.5,
        "总市值": 2625460662.0,
        "市盈率": 22.12,
        "成交量": 141425.0,
        "成交额": 10261734.0,
        "振幅": 2.15,
        "换手率": 0.39,
        "symbol": "105.MGEE"
    },
    {
        "序号": 10315,
        "name": "豪洛捷",
        "最新价": 68.37,
        "涨跌额": -0.85,
        "涨跌幅": -1.23,
        "开盘价": 69.2,
        "最高价": 69.2,
        "最低价": 68.19,
        "昨收价": 69.22,
        "总市值": 16408991573.0,
        "市盈率": 35.98,
        "成交量": 1672566.0,
        "成交额": 114507984.0,
        "振幅": 1.46,
        "换手率": 0.7,
        "symbol": "105.HOLX"
    },
    {
        "序号": 10316,
        "name": "PIMCO Corporate & Income Opport",
        "最新价": 13.67,
        "涨跌额": -0.17,
        "涨跌幅": -1.23,
        "开盘价": 13.85,
        "最高价": 13.87,
        "最低价": 13.46,
        "昨收价": 13.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 598136.0,
        "成交额": 8175457.0,
        "振幅": 2.96,
        "换手率": null,
        "symbol": "106.PTY"
    },
    {
        "序号": 10317,
        "name": "Brighthouse Financial Inc Deben",
        "最新价": 20.81,
        "涨跌额": -0.26,
        "涨跌幅": -1.23,
        "开盘价": 20.96,
        "最高价": 21.1,
        "最低价": 20.68,
        "昨收价": 21.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 24055.0,
        "成交额": 500714.0,
        "振幅": 1.99,
        "换手率": null,
        "symbol": "105.BHFAL"
    },
    {
        "序号": 10318,
        "name": "Skeena Resources Ltd",
        "最新价": 4.0,
        "涨跌额": -0.05,
        "涨跌幅": -1.23,
        "开盘价": 4.0,
        "最高价": 4.01,
        "最低价": 3.89,
        "昨收价": 4.05,
        "总市值": 355841844.0,
        "市盈率": -5.15,
        "成交量": 216059.0,
        "成交额": 857384.0,
        "振幅": 2.96,
        "换手率": 0.24,
        "symbol": "106.SKE"
    },
    {
        "序号": 10319,
        "name": "NOVAGOLD Resources Inc",
        "最新价": 4.0,
        "涨跌额": -0.05,
        "涨跌幅": -1.23,
        "开盘价": 4.0,
        "最高价": 4.08,
        "最低价": 3.93,
        "昨收价": 4.05,
        "总市值": 1336724596.0,
        "市盈率": -27.48,
        "成交量": 1288715.0,
        "成交额": 5140761.0,
        "振幅": 3.7,
        "换手率": 0.39,
        "symbol": "107.NG"
    },
    {
        "序号": 10320,
        "name": "Evogene Ltd",
        "最新价": 0.8,
        "涨跌额": -0.01,
        "涨跌幅": -1.23,
        "开盘价": 0.81,
        "最高价": 0.85,
        "最低价": 0.78,
        "昨收价": 0.81,
        "总市值": 40439850.0,
        "市盈率": -1.99,
        "成交量": 12917.0,
        "成交额": 10503.0,
        "振幅": 8.64,
        "换手率": 0.03,
        "symbol": "105.EVGN"
    },
    {
        "序号": 10321,
        "name": "布莱克维",
        "最新价": 2.4,
        "涨跌额": -0.03,
        "涨跌幅": -1.23,
        "开盘价": 2.43,
        "最高价": 2.45,
        "最低价": 2.37,
        "昨收价": 2.43,
        "总市值": 104332870.0,
        "市盈率": -4.05,
        "成交量": 79019.0,
        "成交额": 190250.0,
        "振幅": 3.29,
        "换手率": 0.18,
        "symbol": "105.BCOV"
    },
    {
        "序号": 10322,
        "name": "Saul Centers Inc Series E Pfd",
        "最新价": 22.31,
        "涨跌额": -0.28,
        "涨跌幅": -1.24,
        "开盘价": 22.46,
        "最高价": 22.46,
        "最低价": 21.7,
        "昨收价": 22.59,
        "总市值": 981640.0,
        "市盈率": null,
        "成交量": 3401.0,
        "成交额": 75327.0,
        "振幅": 3.36,
        "换手率": 7.73,
        "symbol": "106.BFS_E"
    },
    {
        "序号": 10323,
        "name": "Brookfield Renewable Corp-A",
        "最新价": 27.08,
        "涨跌额": -0.34,
        "涨跌幅": -1.24,
        "开盘价": 27.23,
        "最高价": 27.36,
        "最低价": 26.83,
        "昨收价": 27.42,
        "总市值": 9999355831.0,
        "市盈率": 6.58,
        "成交量": 747242.0,
        "成交额": 20205833.0,
        "振幅": 1.93,
        "换手率": 0.2,
        "symbol": "106.BEPC"
    },
    {
        "序号": 10324,
        "name": "Valneva SE ADR",
        "最新价": 11.15,
        "涨跌额": -0.14,
        "涨跌幅": -1.24,
        "开盘价": 11.15,
        "最高价": 11.3,
        "最低价": 10.91,
        "昨收价": 11.29,
        "总市值": 770705617.0,
        "市盈率": -6.0,
        "成交量": 2896.0,
        "成交额": 31986.0,
        "振幅": 3.45,
        "换手率": 0.0,
        "symbol": "105.VALN"
    },
    {
        "序号": 10325,
        "name": "CNB Financial Corp Series A Pfd",
        "最新价": 21.48,
        "涨跌额": -0.27,
        "涨跌幅": -1.24,
        "开盘价": 21.45,
        "最高价": 21.5,
        "最低价": 21.45,
        "昨收价": 21.75,
        "总市值": 1296855.0,
        "市盈率": null,
        "成交量": 1409.0,
        "成交额": 30281.0,
        "振幅": 0.23,
        "换手率": 2.33,
        "symbol": "105.CCNEP"
    },
    {
        "序号": 10326,
        "name": "Braskem SA ADR Pfd",
        "最新价": 7.16,
        "涨跌额": -0.09,
        "涨跌幅": -1.24,
        "开盘价": 7.13,
        "最高价": 7.44,
        "最低价": 7.12,
        "昨收价": 7.25,
        "总市值": 2852289978.0,
        "市盈率": -4.06,
        "成交量": 1689664.0,
        "成交额": 12201994.0,
        "振幅": 4.41,
        "换手率": 0.42,
        "symbol": "106.BAK"
    },
    {
        "序号": 10327,
        "name": "沃达丰(US)",
        "最新价": 8.73,
        "涨跌额": -0.11,
        "涨跌幅": -1.24,
        "开盘价": 8.8,
        "最高价": 8.82,
        "最低价": 8.72,
        "昨收价": 8.84,
        "总市值": 23639430013.0,
        "市盈率": 2.04,
        "成交量": 6036179.0,
        "成交额": 52857635.0,
        "振幅": 1.13,
        "换手率": 0.22,
        "symbol": "105.VOD"
    },
    {
        "序号": 10328,
        "name": "First Trust Japan AlphaDEX Fund",
        "最新价": 49.91,
        "涨跌额": -0.63,
        "涨跌幅": -1.25,
        "开盘价": 49.74,
        "最高价": 50.11,
        "最低价": 49.74,
        "昨收价": 50.54,
        "总市值": null,
        "市盈率": null,
        "成交量": 11306.0,
        "成交额": 564674.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "105.FJP"
    },
    {
        "序号": 10329,
        "name": "逸仙电商",
        "最新价": 0.79,
        "涨跌额": -0.01,
        "涨跌幅": -1.25,
        "开盘价": 0.8,
        "最高价": 0.8,
        "最低价": 0.76,
        "昨收价": 0.8,
        "总市值": 423778524.0,
        "市盈率": -9.33,
        "成交量": 176344.0,
        "成交额": 137405.0,
        "振幅": 5.0,
        "换手率": 0.03,
        "symbol": "106.YSG"
    },
    {
        "序号": 10330,
        "name": "Yoshitsu Co Ltd ADR",
        "最新价": 0.79,
        "涨跌额": -0.01,
        "涨跌幅": -1.25,
        "开盘价": 0.81,
        "最高价": 0.82,
        "最低价": 0.77,
        "昨收价": 0.8,
        "总市值": 28440000.0,
        "市盈率": -3.53,
        "成交量": 168400.0,
        "成交额": 135118.0,
        "振幅": 6.25,
        "换手率": 0.47,
        "symbol": "105.TKLF"
    },
    {
        "序号": 10331,
        "name": "都乐",
        "最新价": 11.84,
        "涨跌额": -0.15,
        "涨跌幅": -1.25,
        "开盘价": 12.03,
        "最高价": 12.13,
        "最低价": 11.72,
        "昨收价": 11.99,
        "总市值": 1123959360.0,
        "市盈率": 10.36,
        "成交量": 1328571.0,
        "成交额": 15805548.0,
        "振幅": 3.42,
        "换手率": 1.4,
        "symbol": "106.DOLE"
    },
    {
        "序号": 10332,
        "name": "CF实业",
        "最新价": 76.56,
        "涨跌额": -0.97,
        "涨跌幅": -1.25,
        "开盘价": 77.56,
        "最高价": 78.27,
        "最低价": 75.72,
        "昨收价": 77.53,
        "总市值": 14627285717.0,
        "市盈率": 6.93,
        "成交量": 1790008.0,
        "成交额": 137038125.0,
        "振幅": 3.29,
        "换手率": 0.94,
        "symbol": "106.CF"
    },
    {
        "序号": 10333,
        "name": "Aspen Insurance Holdings Ltd Pf",
        "最新价": 19.73,
        "涨跌额": -0.25,
        "涨跌幅": -1.25,
        "开盘价": 19.96,
        "最高价": 20.0,
        "最低价": 19.61,
        "昨收价": 19.98,
        "总市值": 197300.0,
        "市盈率": null,
        "成交量": 18532.0,
        "成交额": 365091.0,
        "振幅": 1.95,
        "换手率": 185.32,
        "symbol": "106.AHL_E"
    },
    {
        "序号": 10334,
        "name": "John Hancock Preferred Income F",
        "最新价": 14.98,
        "涨跌额": -0.19,
        "涨跌幅": -1.25,
        "开盘价": 15.08,
        "最高价": 15.32,
        "最低价": 14.98,
        "昨收价": 15.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 44821.0,
        "成交额": 677404.0,
        "振幅": 2.24,
        "换手率": null,
        "symbol": "106.HPF"
    },
    {
        "序号": 10335,
        "name": "iShares Gold Strategy ETF",
        "最新价": 59.85,
        "涨跌额": -0.76,
        "涨跌幅": -1.25,
        "开盘价": 59.75,
        "最高价": 59.85,
        "最低价": 59.75,
        "昨收价": 60.61,
        "总市值": null,
        "市盈率": null,
        "成交量": 1506.0,
        "成交额": 89983.0,
        "振幅": 0.16,
        "换手率": null,
        "symbol": "107.IAUF"
    },
    {
        "序号": 10336,
        "name": "美国25年+零息票国债ETF-PIMCO",
        "最新价": 80.31,
        "涨跌额": -1.02,
        "涨跌幅": -1.25,
        "开盘价": 80.11,
        "最高价": 80.57,
        "最低价": 79.57,
        "昨收价": 81.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 416258.0,
        "成交额": 33326672.0,
        "振幅": 1.23,
        "换手率": null,
        "symbol": "107.ZROZ"
    },
    {
        "序号": 10337,
        "name": "Franklin Responsibly Sourced Go",
        "最新价": 26.77,
        "涨跌额": -0.34,
        "涨跌幅": -1.25,
        "开盘价": 26.82,
        "最高价": 26.82,
        "最低价": 26.77,
        "昨收价": 27.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 189.0,
        "成交额": 5068.0,
        "振幅": 0.18,
        "换手率": null,
        "symbol": "107.FGDL"
    },
    {
        "序号": 10338,
        "name": "科磊",
        "最新价": 534.43,
        "涨跌额": -6.8,
        "涨跌幅": -1.26,
        "开盘价": 535.53,
        "最高价": 539.18,
        "最低价": 532.0,
        "昨收价": 541.23,
        "总市值": 72646307640.0,
        "市盈率": 23.41,
        "成交量": 1038337.0,
        "成交额": 555713152.0,
        "振幅": 1.33,
        "换手率": 0.76,
        "symbol": "105.KLAC"
    },
    {
        "序号": 10339,
        "name": "塞德斯医疗",
        "最新价": 31.43,
        "涨跌额": -0.4,
        "涨跌幅": -1.26,
        "开盘价": 31.87,
        "最高价": 32.66,
        "最低价": 31.18,
        "昨收价": 31.83,
        "总市值": 1718881462.0,
        "市盈率": -13.79,
        "成交量": 344526.0,
        "成交额": 10894750.0,
        "振幅": 4.65,
        "换手率": 0.63,
        "symbol": "105.CLDX"
    },
    {
        "序号": 10340,
        "name": "ProShares UltraShort SmallCap60",
        "最新价": 21.99,
        "涨跌额": -0.28,
        "涨跌幅": -1.26,
        "开盘价": 21.85,
        "最高价": 22.0,
        "最低价": 21.85,
        "昨收价": 22.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 963.0,
        "成交额": 21156.0,
        "振幅": 0.67,
        "换手率": null,
        "symbol": "107.SDD"
    },
    {
        "序号": 10341,
        "name": "G1 Therapeutics Inc",
        "最新价": 3.14,
        "涨跌额": -0.04,
        "涨跌幅": -1.26,
        "开盘价": 3.14,
        "最高价": 3.39,
        "最低价": 2.94,
        "昨收价": 3.18,
        "总市值": 162789121.0,
        "市盈率": -2.3,
        "成交量": 3437049.0,
        "成交额": 10810008.0,
        "振幅": 14.15,
        "换手率": 6.63,
        "symbol": "105.GTHX"
    },
    {
        "序号": 10342,
        "name": "American Resources Corp-A",
        "最新价": 1.57,
        "涨跌额": -0.02,
        "涨跌幅": -1.26,
        "开盘价": 1.56,
        "最高价": 1.6,
        "最低价": 1.56,
        "昨收价": 1.59,
        "总市值": 122881291.0,
        "市盈率": 14.62,
        "成交量": 124251.0,
        "成交额": 196430.0,
        "振幅": 2.52,
        "换手率": 0.16,
        "symbol": "105.AREC"
    },
    {
        "序号": 10343,
        "name": "Spyre Therapeutics Inc",
        "最新价": 19.61,
        "涨跌额": -0.25,
        "涨跌幅": -1.26,
        "开盘价": 20.39,
        "最高价": 21.83,
        "最低价": 19.61,
        "昨收价": 19.86,
        "总市值": 79399458.0,
        "市盈率": -0.27,
        "成交量": 369167.0,
        "成交额": 7435729.0,
        "振幅": 11.18,
        "换手率": 9.12,
        "symbol": "105.SYRE"
    },
    {
        "序号": 10344,
        "name": "Acme United Corp",
        "最新价": 39.99,
        "涨跌额": -0.51,
        "涨跌幅": -1.26,
        "开盘价": 40.5,
        "最高价": 40.5,
        "最低价": 39.09,
        "昨收价": 40.5,
        "总市值": 143366949.0,
        "市盈率": 23.94,
        "成交量": 5418.0,
        "成交额": 216490.0,
        "振幅": 3.48,
        "换手率": 0.15,
        "symbol": "107.ACU"
    },
    {
        "序号": 10345,
        "name": "Aptiv PLC",
        "最新价": 79.83,
        "涨跌额": -1.02,
        "涨跌幅": -1.26,
        "开盘价": 80.78,
        "最高价": 81.64,
        "最低价": 79.76,
        "昨收价": 80.85,
        "总市值": 22580885355.0,
        "市盈率": 9.9,
        "成交量": 2421724.0,
        "成交额": 194490576.0,
        "振幅": 2.33,
        "换手率": 0.86,
        "symbol": "106.APTV"
    },
    {
        "序号": 10346,
        "name": "高平电子",
        "最新价": 1.56,
        "涨跌额": -0.02,
        "涨跌幅": -1.27,
        "开盘价": 1.58,
        "最高价": 1.59,
        "最低价": 1.55,
        "昨收价": 1.58,
        "总市值": 178540649.0,
        "市盈率": -9.2,
        "成交量": 218777.0,
        "成交额": 342042.0,
        "振幅": 2.53,
        "换手率": 0.19,
        "symbol": "105.KOPN"
    },
    {
        "序号": 10347,
        "name": "佳益净科",
        "最新价": 0.78,
        "涨跌额": -0.01,
        "涨跌幅": -1.27,
        "开盘价": 0.86,
        "最高价": 0.86,
        "最低价": 0.78,
        "昨收价": 0.79,
        "总市值": 3905199.0,
        "市盈率": 3.95,
        "成交量": 2612.0,
        "成交额": 2149.0,
        "振幅": 10.13,
        "换手率": 0.05,
        "symbol": "105.JCSE"
    },
    {
        "序号": 10348,
        "name": "Flotek工业",
        "最新价": 3.12,
        "涨跌额": -0.04,
        "涨跌幅": -1.27,
        "开盘价": 3.18,
        "最高价": 3.24,
        "最低价": 3.0,
        "昨收价": 3.16,
        "总市值": 92547808.0,
        "市盈率": 25.84,
        "成交量": 52878.0,
        "成交额": 162202.0,
        "振幅": 7.59,
        "换手率": 0.18,
        "symbol": "106.FTK"
    },
    {
        "序号": 10349,
        "name": "棒约翰",
        "最新价": 70.14,
        "涨跌额": -0.9,
        "涨跌幅": -1.27,
        "开盘价": 70.85,
        "最高价": 71.25,
        "最低价": 69.99,
        "昨收价": 71.04,
        "总市值": 2297495880.0,
        "市盈率": 28.89,
        "成交量": 455213.0,
        "成交额": 32029011.0,
        "振幅": 1.77,
        "换手率": 1.39,
        "symbol": "105.PZZA"
    },
    {
        "序号": 10350,
        "name": "Target Global Acquisition I Cor",
        "最新价": 10.91,
        "涨跌额": -0.14,
        "涨跌幅": -1.27,
        "开盘价": 11.32,
        "最高价": 11.32,
        "最低价": 10.91,
        "昨收价": 11.05,
        "总市值": 107659280.0,
        "市盈率": 19.3,
        "成交量": 5556.0,
        "成交额": 60791.0,
        "振幅": 3.71,
        "换手率": null,
        "symbol": "105.TGAAU"
    },
    {
        "序号": 10351,
        "name": "Townsquare Media Inc-A",
        "最新价": 10.13,
        "涨跌额": -0.13,
        "涨跌幅": -1.27,
        "开盘价": 10.19,
        "最高价": 10.29,
        "最低价": 10.0,
        "昨收价": 10.26,
        "总市值": 166712854.0,
        "市盈率": -4.26,
        "成交量": 27382.0,
        "成交额": 278153.0,
        "振幅": 2.83,
        "换手率": 0.17,
        "symbol": "106.TSQ"
    },
    {
        "序号": 10352,
        "name": "Sprott Physical Gold Trust",
        "最新价": 15.58,
        "涨跌额": -0.2,
        "涨跌幅": -1.27,
        "开盘价": 15.6,
        "最高价": 15.67,
        "最低价": 15.5,
        "昨收价": 15.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1621088.0,
        "成交额": 25227454.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.PHYS"
    },
    {
        "序号": 10353,
        "name": "Eltek Ltd",
        "最新价": 12.45,
        "涨跌额": -0.16,
        "涨跌幅": -1.27,
        "开盘价": 12.37,
        "最高价": 12.73,
        "最低价": 12.31,
        "昨收价": 12.61,
        "总市值": 73628864.0,
        "市盈率": 12.64,
        "成交量": 21349.0,
        "成交额": 267329.0,
        "振幅": 3.33,
        "换手率": 0.36,
        "symbol": "105.ELTK"
    },
    {
        "序号": 10354,
        "name": "康宝莱",
        "最新价": 14.0,
        "涨跌额": -0.18,
        "涨跌幅": -1.27,
        "开盘价": 14.17,
        "最高价": 14.42,
        "最低价": 13.92,
        "昨收价": 14.18,
        "总市值": 1387574314.0,
        "市盈率": 7.44,
        "成交量": 1185904.0,
        "成交额": 16713257.0,
        "振幅": 3.53,
        "换手率": 1.2,
        "symbol": "106.HLF"
    },
    {
        "序号": 10355,
        "name": "皇家黄金",
        "最新价": 118.19,
        "涨跌额": -1.52,
        "涨跌幅": -1.27,
        "开盘价": 117.92,
        "最高价": 120.0,
        "最低价": 116.71,
        "昨收价": 119.71,
        "总市值": 7764138898.0,
        "市盈率": 33.32,
        "成交量": 323093.0,
        "成交额": 38201616.0,
        "振幅": 2.75,
        "换手率": 0.49,
        "symbol": "105.RGLD"
    },
    {
        "序号": 10356,
        "name": "百老汇金融",
        "最新价": 6.22,
        "涨跌额": -0.08,
        "涨跌幅": -1.27,
        "开盘价": 6.25,
        "最高价": 6.63,
        "最低价": 6.18,
        "昨收价": 6.3,
        "总市值": 56099343.0,
        "市盈率": 12.09,
        "成交量": 8491.0,
        "成交额": 53462.0,
        "振幅": 7.14,
        "换手率": 0.09,
        "symbol": "105.BYFC"
    },
    {
        "序号": 10357,
        "name": "B2Gold Corp",
        "最新价": 3.11,
        "涨跌额": -0.04,
        "涨跌幅": -1.27,
        "开盘价": 3.13,
        "最高价": 3.14,
        "最低价": 3.06,
        "昨收价": 3.15,
        "总市值": 4044629174.0,
        "市盈率": 14.39,
        "成交量": 7835446.0,
        "成交额": 24281150.0,
        "振幅": 2.54,
        "换手率": 0.6,
        "symbol": "107.BTG"
    },
    {
        "序号": 10358,
        "name": "PIMCO Access Income Fund",
        "最新价": 13.99,
        "涨跌额": -0.18,
        "涨跌幅": -1.27,
        "开盘价": 14.1,
        "最高价": 14.2,
        "最低价": 13.92,
        "昨收价": 14.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 241083.0,
        "成交额": 3392866.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "106.PAXS"
    },
    {
        "序号": 10359,
        "name": "格拉德斯通商业(优先股)",
        "最新价": 20.2,
        "涨跌额": -0.26,
        "涨跌幅": -1.27,
        "开盘价": 20.45,
        "最高价": 20.55,
        "最低价": 20.16,
        "昨收价": 20.46,
        "总市值": 48480000.0,
        "市盈率": null,
        "成交量": 9849.0,
        "成交额": 200421.0,
        "振幅": 1.91,
        "换手率": 0.41,
        "symbol": "105.GOODN"
    },
    {
        "序号": 10360,
        "name": "Voyager Therapeutics Inc",
        "最新价": 7.76,
        "涨跌额": -0.1,
        "涨跌幅": -1.27,
        "开盘价": 7.79,
        "最高价": 7.92,
        "最低价": 7.69,
        "昨收价": 7.86,
        "总市值": 341404017.0,
        "市盈率": 6.53,
        "成交量": 140986.0,
        "成交额": 1097128.0,
        "振幅": 2.93,
        "换手率": 0.32,
        "symbol": "105.VYGR"
    },
    {
        "序号": 10361,
        "name": "金融壹账通",
        "最新价": 3.1,
        "涨跌额": -0.04,
        "涨跌幅": -1.27,
        "开盘价": 3.15,
        "最高价": 3.21,
        "最低价": 3.1,
        "昨收价": 3.14,
        "总市值": 120898001.0,
        "市盈率": -1.82,
        "成交量": 10480.0,
        "成交额": 33032.0,
        "振幅": 3.5,
        "换手率": 0.03,
        "symbol": "106.OCFT"
    },
    {
        "序号": 10362,
        "name": "Nordic American Tankers Ltd",
        "最新价": 3.87,
        "涨跌额": -0.05,
        "涨跌幅": -1.28,
        "开盘价": 3.91,
        "最高价": 3.93,
        "最低价": 3.86,
        "昨收价": 3.92,
        "总市值": 808042238.0,
        "市盈率": 6.89,
        "成交量": 2301953.0,
        "成交额": 8933044.0,
        "振幅": 1.79,
        "换手率": 1.1,
        "symbol": "106.NAT"
    },
    {
        "序号": 10363,
        "name": "金佰利",
        "最新价": 119.94,
        "涨跌额": -1.55,
        "涨跌幅": -1.28,
        "开盘价": 121.32,
        "最高价": 121.44,
        "最低价": 119.59,
        "昨收价": 121.49,
        "总市值": 40532646059.0,
        "市盈率": 23.0,
        "成交量": 1795507.0,
        "成交额": 215513242.0,
        "振幅": 1.52,
        "换手率": 0.53,
        "symbol": "106.KMB"
    },
    {
        "序号": 10364,
        "name": "如瑞迪博士",
        "最新价": 68.86,
        "涨跌额": -0.89,
        "涨跌幅": -1.28,
        "开盘价": 69.24,
        "最高价": 69.57,
        "最低价": 68.51,
        "昨收价": 69.75,
        "总市值": 11484798849.0,
        "市盈率": 18.69,
        "成交量": 409773.0,
        "成交额": 28211153.0,
        "振幅": 1.52,
        "换手率": 0.25,
        "symbol": "106.RDY"
    },
    {
        "序号": 10365,
        "name": "ADDvantage Technologies Group I",
        "最新价": 3.09,
        "涨跌额": -0.04,
        "涨跌幅": -1.28,
        "开盘价": 3.1,
        "最高价": 3.2,
        "最低价": 3.09,
        "昨收价": 3.13,
        "总市值": 4618645.0,
        "市盈率": -0.52,
        "成交量": 3997.0,
        "成交额": 12531.0,
        "振幅": 3.51,
        "换手率": 0.27,
        "symbol": "105.AEY"
    },
    {
        "序号": 10366,
        "name": "艾斯欧艾斯",
        "最新价": 3.86,
        "涨跌额": -0.05,
        "涨跌幅": -1.28,
        "开盘价": 3.78,
        "最高价": 4.0,
        "最低价": 3.78,
        "昨收价": 3.91,
        "总市值": 24628899.0,
        "市盈率": -0.11,
        "成交量": 98930.0,
        "成交额": 385124.0,
        "振幅": 5.63,
        "换手率": 1.55,
        "symbol": "106.SOS"
    },
    {
        "序号": 10367,
        "name": "丘奇&德怀特",
        "最新价": 92.94,
        "涨跌额": -1.21,
        "涨跌幅": -1.29,
        "开盘价": 93.91,
        "最高价": 93.91,
        "最低价": 92.56,
        "昨收价": 94.15,
        "总市值": 22898718172.0,
        "市盈率": 52.38,
        "成交量": 963105.0,
        "成交额": 89526833.0,
        "振幅": 1.43,
        "换手率": 0.39,
        "symbol": "106.CHD"
    },
    {
        "序号": 10368,
        "name": "Big 5体育用品",
        "最新价": 6.14,
        "涨跌额": -0.08,
        "涨跌幅": -1.29,
        "开盘价": 6.19,
        "最高价": 6.24,
        "最低价": 6.07,
        "昨收价": 6.22,
        "总市值": 137808751.0,
        "市盈率": 39.41,
        "成交量": 193546.0,
        "成交额": 1187907.0,
        "振幅": 2.73,
        "换手率": 0.86,
        "symbol": "105.BGFV"
    },
    {
        "序号": 10369,
        "name": "Day One Biopharmaceuticals Hold",
        "最新价": 12.27,
        "涨跌额": -0.16,
        "涨跌幅": -1.29,
        "开盘价": 12.36,
        "最高价": 12.56,
        "最低价": 12.15,
        "昨收价": 12.43,
        "总市值": 1068016788.0,
        "市盈率": -6.12,
        "成交量": 378135.0,
        "成交额": 4670211.0,
        "振幅": 3.3,
        "换手率": 0.43,
        "symbol": "105.DAWN"
    },
    {
        "序号": 10370,
        "name": "Satellogic Inc-A",
        "最新价": 2.3,
        "涨跌额": -0.03,
        "涨跌幅": -1.29,
        "开盘价": 2.24,
        "最高价": 2.3,
        "最低价": 1.8,
        "昨收价": 2.33,
        "总市值": 211223591.0,
        "市盈率": -3.62,
        "成交量": 310537.0,
        "成交额": 639662.0,
        "振幅": 21.46,
        "换手率": 0.34,
        "symbol": "105.SATL"
    },
    {
        "序号": 10371,
        "name": "Acasti Pharma Inc-A",
        "最新价": 2.3,
        "涨跌额": -0.03,
        "涨跌幅": -1.29,
        "开盘价": 2.34,
        "最高价": 2.34,
        "最低价": 2.22,
        "昨收价": 2.33,
        "总市值": 21618629.0,
        "市盈率": -0.54,
        "成交量": 7443.0,
        "成交额": 17122.0,
        "振幅": 5.15,
        "换手率": 0.08,
        "symbol": "105.ACST"
    },
    {
        "序号": 10372,
        "name": "Aberdeen Standard Physical Gold",
        "最新价": 19.16,
        "涨跌额": -0.25,
        "涨跌幅": -1.29,
        "开盘价": 19.18,
        "最高价": 19.27,
        "最低价": 19.08,
        "昨收价": 19.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 2431054.0,
        "成交额": 46626806.0,
        "振幅": 0.98,
        "换手率": null,
        "symbol": "107.SGOL"
    },
    {
        "序号": 10373,
        "name": "SPDR Gold MiniShares Trust",
        "最新价": 39.73,
        "涨跌额": -0.52,
        "涨跌幅": -1.29,
        "开盘价": 39.78,
        "最高价": 39.96,
        "最低价": 39.56,
        "昨收价": 40.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 3103215.0,
        "成交额": 123408439.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.GLDM"
    },
    {
        "序号": 10374,
        "name": "JJSF食品",
        "最新价": 170.29,
        "涨跌额": -2.23,
        "涨跌幅": -1.29,
        "开盘价": 172.74,
        "最高价": 173.68,
        "最低价": 169.99,
        "昨收价": 172.52,
        "总市值": 3293807760.0,
        "市盈率": 41.74,
        "成交量": 56877.0,
        "成交额": 9719955.0,
        "振幅": 2.14,
        "换手率": 0.29,
        "symbol": "105.JJSF"
    },
    {
        "序号": 10375,
        "name": "Goldman Sachs Physical Gold ETF",
        "最新价": 19.85,
        "涨跌额": -0.26,
        "涨跌幅": -1.29,
        "开盘价": 19.88,
        "最高价": 19.96,
        "最低价": 19.76,
        "昨收价": 20.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 1951536.0,
        "成交额": 38735329.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.AAAU"
    },
    {
        "序号": 10376,
        "name": "ProShares Ultra MSCI Japan",
        "最新价": 34.34,
        "涨跌额": -0.45,
        "涨跌幅": -1.29,
        "开盘价": 34.33,
        "最高价": 34.34,
        "最低价": 34.11,
        "昨收价": 34.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 2522.0,
        "成交额": 86297.0,
        "振幅": 0.66,
        "换手率": null,
        "symbol": "107.EZJ"
    },
    {
        "序号": 10377,
        "name": "Volaris航空",
        "最新价": 8.39,
        "涨跌额": -0.11,
        "涨跌幅": -1.29,
        "开盘价": 8.42,
        "最高价": 8.58,
        "最低价": 8.24,
        "昨收价": 8.5,
        "总市值": 966967874.0,
        "市盈率": -7.66,
        "成交量": 452448.0,
        "成交额": 3802360.0,
        "振幅": 4.0,
        "换手率": 0.39,
        "symbol": "106.VLRS"
    },
    {
        "序号": 10378,
        "name": "Brunswick Corp Notes",
        "最新价": 23.62,
        "涨跌额": -0.31,
        "涨跌幅": -1.3,
        "开盘价": 23.93,
        "最高价": 24.16,
        "最低价": 23.59,
        "昨收价": 23.93,
        "总市值": null,
        "市盈率": null,
        "成交量": 25153.0,
        "成交额": 599934.0,
        "振幅": 2.38,
        "换手率": null,
        "symbol": "106.BC_C"
    },
    {
        "序号": 10379,
        "name": "Corbus Pharmaceuticals Holdings",
        "最新价": 5.33,
        "涨跌额": -0.07,
        "涨跌幅": -1.3,
        "开盘价": 5.07,
        "最高价": 5.99,
        "最低价": 5.07,
        "昨收价": 5.4,
        "总市值": 23578230.0,
        "市盈率": -0.5,
        "成交量": 10349.0,
        "成交额": 57212.0,
        "振幅": 17.04,
        "换手率": 0.23,
        "symbol": "105.CRBP"
    },
    {
        "序号": 10380,
        "name": "Nautilus Biotechnology Inc",
        "最新价": 3.04,
        "涨跌额": -0.04,
        "涨跌幅": -1.3,
        "开盘价": 3.07,
        "最高价": 3.07,
        "最低价": 2.91,
        "昨收价": 3.08,
        "总市值": 379904249.0,
        "市盈率": -6.33,
        "成交量": 41467.0,
        "成交额": 125076.0,
        "振幅": 5.19,
        "换手率": 0.03,
        "symbol": "105.NAUT"
    },
    {
        "序号": 10381,
        "name": "Movano Inc",
        "最新价": 0.76,
        "涨跌额": -0.01,
        "涨跌幅": -1.3,
        "开盘价": 0.72,
        "最高价": 0.83,
        "最低价": 0.72,
        "昨收价": 0.77,
        "总市值": 41831354.0,
        "市盈率": -1.34,
        "成交量": 84397.0,
        "成交额": 65288.0,
        "振幅": 14.29,
        "换手率": 0.15,
        "symbol": "105.MOVE"
    },
    {
        "序号": 10382,
        "name": "全球合伙",
        "最新价": 37.16,
        "涨跌额": -0.49,
        "涨跌幅": -1.3,
        "开盘价": 37.65,
        "最高价": 39.4,
        "最低价": 36.16,
        "昨收价": 37.65,
        "总市值": 1263275121.0,
        "市盈率": 8.17,
        "成交量": 568472.0,
        "成交额": 21492446.0,
        "振幅": 8.61,
        "换手率": 1.67,
        "symbol": "106.GLP"
    },
    {
        "序号": 10383,
        "name": "消毒循环",
        "最新价": 48.45,
        "涨跌额": -0.64,
        "涨跌幅": -1.3,
        "开盘价": 47.52,
        "最高价": 48.72,
        "最低价": 47.4,
        "昨收价": 49.09,
        "总市值": 4482758294.0,
        "市盈率": -996.17,
        "成交量": 568787.0,
        "成交额": 27419955.0,
        "振幅": 2.69,
        "换手率": 0.61,
        "symbol": "105.SRCL"
    },
    {
        "序号": 10384,
        "name": "John Hancock Premium Dividend F",
        "最新价": 10.58,
        "涨跌额": -0.14,
        "涨跌幅": -1.31,
        "开盘价": 10.75,
        "最高价": 10.79,
        "最低价": 10.52,
        "昨收价": 10.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 200948.0,
        "成交额": 2135204.0,
        "振幅": 2.52,
        "换手率": null,
        "symbol": "106.PDT"
    },
    {
        "序号": 10385,
        "name": "迅雷",
        "最新价": 1.51,
        "涨跌额": -0.02,
        "涨跌幅": -1.31,
        "开盘价": 1.53,
        "最高价": 1.56,
        "最低价": 1.51,
        "昨收价": 1.53,
        "总市值": 98628444.0,
        "市盈率": 6.12,
        "成交量": 25264.0,
        "成交额": 38894.0,
        "振幅": 3.27,
        "换手率": 0.04,
        "symbol": "105.XNET"
    },
    {
        "序号": 10386,
        "name": "The Beauty Health Co-A",
        "最新价": 3.02,
        "涨跌额": -0.04,
        "涨跌幅": -1.31,
        "开盘价": 3.09,
        "最高价": 3.11,
        "最低价": 2.93,
        "昨收价": 3.06,
        "总市值": 396425854.0,
        "市盈率": -4.72,
        "成交量": 4378058.0,
        "成交额": 13188258.0,
        "振幅": 5.88,
        "换手率": 3.34,
        "symbol": "105.SKIN"
    },
    {
        "序号": 10387,
        "name": "谷歌-C",
        "最新价": 136.64,
        "涨跌额": -1.81,
        "涨跌幅": -1.31,
        "开盘价": 135.66,
        "最高价": 137.99,
        "最低价": 135.57,
        "昨收价": 138.45,
        "总市值": 1710186240000.0,
        "市盈率": 25.63,
        "成交量": 23016139.0,
        "成交额": 3144903152.0,
        "振幅": 1.75,
        "换手率": 0.35,
        "symbol": "105.GOOG"
    },
    {
        "序号": 10388,
        "name": "NNN REIT Inc",
        "最新价": 40.75,
        "涨跌额": -0.54,
        "涨跌幅": -1.31,
        "开盘价": 41.18,
        "最高价": 41.3,
        "最低价": 40.53,
        "昨收价": 41.29,
        "总市值": 7434449193.0,
        "市盈率": 19.24,
        "成交量": 1420032.0,
        "成交额": 57918233.0,
        "振幅": 1.86,
        "换手率": 0.78,
        "symbol": "106.NNN"
    },
    {
        "序号": 10389,
        "name": "Worthington Industries Inc",
        "最新价": 51.17,
        "涨跌额": -0.68,
        "涨跌幅": -1.31,
        "开盘价": 51.68,
        "最高价": 52.36,
        "最低价": 50.62,
        "昨收价": 51.85,
        "总市值": 2556731616.0,
        "市盈率": 8.86,
        "成交量": 430432.0,
        "成交额": 22021007.0,
        "振幅": 3.36,
        "换手率": 0.86,
        "symbol": "106.WOR"
    },
    {
        "序号": 10390,
        "name": "cbdMD Inc Series A Pfd",
        "最新价": 0.75,
        "涨跌额": -0.01,
        "涨跌幅": -1.32,
        "开盘价": 0.7,
        "最高价": 0.76,
        "最低价": 0.69,
        "昨收价": 0.76,
        "总市值": 3750000.0,
        "市盈率": null,
        "成交量": 9320.0,
        "成交额": 6593.0,
        "振幅": 9.21,
        "换手率": 0.19,
        "symbol": "107.YCBD_A"
    },
    {
        "序号": 10391,
        "name": "AT&T Inc Series C Pfd",
        "最新价": 18.75,
        "涨跌额": -0.25,
        "涨跌幅": -1.32,
        "开盘价": 18.97,
        "最高价": 19.0,
        "最低价": 18.67,
        "昨收价": 19.0,
        "总市值": 1312500.0,
        "市盈率": null,
        "成交量": 161597.0,
        "成交额": 3051966.0,
        "振幅": 1.74,
        "换手率": 230.85,
        "symbol": "106.T_C"
    },
    {
        "序号": 10392,
        "name": "Life Time Group Holdings Inc",
        "最新价": 14.24,
        "涨跌额": -0.19,
        "涨跌幅": -1.32,
        "开盘价": 14.41,
        "最高价": 14.53,
        "最低价": 14.05,
        "昨收价": 14.43,
        "总市值": 2796862750.0,
        "市盈率": 42.31,
        "成交量": 533441.0,
        "成交额": 7598029.0,
        "振幅": 3.33,
        "换手率": 0.27,
        "symbol": "106.LTH"
    },
    {
        "序号": 10393,
        "name": "三倍做空金融ETF-Direxion",
        "最新价": 15.71,
        "涨跌额": -0.21,
        "涨跌幅": -1.32,
        "开盘价": 15.92,
        "最高价": 16.02,
        "最低价": 15.59,
        "昨收价": 15.92,
        "总市值": null,
        "市盈率": null,
        "成交量": 1190316.0,
        "成交额": 18779528.0,
        "振幅": 2.7,
        "换手率": null,
        "symbol": "107.FAZ"
    },
    {
        "序号": 10394,
        "name": "Fortrea Holdings Inc",
        "最新价": 30.66,
        "涨跌额": -0.41,
        "涨跌幅": -1.32,
        "开盘价": 30.98,
        "最高价": 31.4,
        "最低价": 30.5,
        "昨收价": 31.07,
        "总市值": 2722608000.0,
        "市盈率": null,
        "成交量": 707054.0,
        "成交额": 21830092.0,
        "振幅": 2.9,
        "换手率": 0.8,
        "symbol": "105.FTRE"
    },
    {
        "序号": 10395,
        "name": "Supernus Pharmaceuticals Inc",
        "最新价": 26.91,
        "涨跌额": -0.36,
        "涨跌幅": -1.32,
        "开盘价": 27.34,
        "最高价": 27.59,
        "最低价": 26.86,
        "昨收价": 27.27,
        "总市值": 1470169913.0,
        "市盈率": 57.38,
        "成交量": 219499.0,
        "成交额": 5933591.0,
        "振幅": 2.68,
        "换手率": 0.4,
        "symbol": "105.SUPN"
    },
    {
        "序号": 10396,
        "name": "Ardelyx Inc",
        "最新价": 5.23,
        "涨跌额": -0.07,
        "涨跌幅": -1.32,
        "开盘价": 5.28,
        "最高价": 5.34,
        "最低价": 5.18,
        "昨收价": 5.3,
        "总市值": 1214080218.0,
        "市盈率": -45.69,
        "成交量": 4487080.0,
        "成交额": 23599332.0,
        "振幅": 3.02,
        "换手率": 1.93,
        "symbol": "105.ARDX"
    },
    {
        "序号": 10397,
        "name": "韦斯市场",
        "最新价": 62.61,
        "涨跌额": -0.84,
        "涨跌幅": -1.32,
        "开盘价": 63.42,
        "最高价": 64.25,
        "最低价": 62.54,
        "昨收价": 63.45,
        "总市值": 1684111516.0,
        "市盈率": 15.01,
        "成交量": 74282.0,
        "成交额": 4673615.0,
        "振幅": 2.7,
        "换手率": 0.28,
        "symbol": "106.WMK"
    },
    {
        "序号": 10398,
        "name": "VanEck Merk Gold Trust",
        "最新价": 19.37,
        "涨跌额": -0.26,
        "涨跌幅": -1.32,
        "开盘价": 19.4,
        "最高价": 19.5,
        "最低价": 19.3,
        "昨收价": 19.63,
        "总市值": null,
        "市盈率": null,
        "成交量": 978544.0,
        "成交额": 18992674.0,
        "振幅": 1.02,
        "换手率": null,
        "symbol": "107.OUNZ"
    },
    {
        "序号": 10399,
        "name": "Sun Country Airlines Holdings I",
        "最新价": 17.1,
        "涨跌额": -0.23,
        "涨跌幅": -1.33,
        "开盘价": 17.37,
        "最高价": 17.56,
        "最低价": 17.02,
        "昨收价": 17.33,
        "总市值": 925688852.0,
        "市盈率": 12.54,
        "成交量": 1444094.0,
        "成交额": 24733487.0,
        "振幅": 3.12,
        "换手率": 2.67,
        "symbol": "105.SNCY"
    },
    {
        "序号": 10400,
        "name": "达登饭店",
        "最新价": 158.95,
        "涨跌额": -2.14,
        "涨跌幅": -1.33,
        "开盘价": 161.04,
        "最高价": 161.37,
        "最低价": 158.57,
        "昨收价": 161.09,
        "总市值": 19124021088.0,
        "市盈率": 19.45,
        "成交量": 781354.0,
        "成交额": 124405100.0,
        "振幅": 1.74,
        "换手率": 0.65,
        "symbol": "106.DRI"
    },
    {
        "序号": 10401,
        "name": "iShares Gold Trust Micro ETF",
        "最新价": 20.0,
        "涨跌额": -0.27,
        "涨跌幅": -1.33,
        "开盘价": 20.08,
        "最高价": 20.11,
        "最低价": 19.91,
        "昨收价": 20.27,
        "总市值": null,
        "市盈率": null,
        "成交量": 516487.0,
        "成交额": 10345384.0,
        "振幅": 0.99,
        "换手率": null,
        "symbol": "107.IAUM"
    },
    {
        "序号": 10402,
        "name": "UltraPro Short MidCap400",
        "最新价": 16.28,
        "涨跌额": -0.22,
        "涨跌幅": -1.33,
        "开盘价": 16.41,
        "最高价": 16.41,
        "最低价": 16.23,
        "昨收价": 16.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 7008.0,
        "成交额": 114643.0,
        "振幅": 1.09,
        "换手率": null,
        "symbol": "107.SMDD"
    },
    {
        "序号": 10403,
        "name": "Priority Income Fund Inc Series",
        "最新价": 22.2,
        "涨跌额": -0.3,
        "涨跌幅": -1.33,
        "开盘价": 22.26,
        "最高价": 22.26,
        "最低价": 22.2,
        "昨收价": 22.5,
        "总市值": 23088000.0,
        "市盈率": null,
        "成交量": 601.0,
        "成交额": 13361.0,
        "振幅": 0.27,
        "换手率": 0.06,
        "symbol": "106.PRIF_L"
    },
    {
        "序号": 10404,
        "name": "福沃德工业",
        "最新价": 0.74,
        "涨跌额": -0.01,
        "涨跌幅": -1.33,
        "开盘价": 0.76,
        "最高价": 0.77,
        "最低价": 0.72,
        "昨收价": 0.75,
        "总市值": 7445277.0,
        "市盈率": -2.84,
        "成交量": 32120.0,
        "成交额": 23904.0,
        "振幅": 6.67,
        "换手率": 0.32,
        "symbol": "105.FORD"
    },
    {
        "序号": 10405,
        "name": "Foremost Lithium Resource & Tec",
        "最新价": 3.7,
        "涨跌额": -0.05,
        "涨跌幅": -1.33,
        "开盘价": 3.86,
        "最高价": 3.87,
        "最低价": 3.7,
        "昨收价": 3.75,
        "总市值": 17872543.0,
        "市盈率": 172.31,
        "成交量": 5313.0,
        "成交额": 20095.0,
        "振幅": 4.53,
        "换手率": 0.11,
        "symbol": "105.FMST"
    },
    {
        "序号": 10406,
        "name": "BlackRock Enhanced Government F",
        "最新价": 9.62,
        "涨跌额": -0.13,
        "涨跌幅": -1.33,
        "开盘价": 9.67,
        "最高价": 9.68,
        "最低价": 9.6,
        "昨收价": 9.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 1811.0,
        "成交额": 17441.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "106.EGF"
    },
    {
        "序号": 10407,
        "name": "美国图文",
        "最新价": 2.96,
        "涨跌额": -0.04,
        "涨跌幅": -1.33,
        "开盘价": 2.98,
        "最高价": 3.01,
        "最低价": 2.95,
        "昨收价": 3.0,
        "总市值": 127166916.0,
        "市盈率": 11.31,
        "成交量": 58020.0,
        "成交额": 172224.0,
        "振幅": 2.0,
        "换手率": 0.14,
        "symbol": "106.ARC"
    },
    {
        "序号": 10408,
        "name": "黄金ETF-SPDR",
        "最新价": 185.64,
        "涨跌额": -2.51,
        "涨跌幅": -1.33,
        "开盘价": 185.93,
        "最高价": 186.78,
        "最低价": 184.9,
        "昨收价": 188.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 7883204.0,
        "成交额": 1464352816.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.GLD"
    },
    {
        "序号": 10409,
        "name": "冠城",
        "最新价": 116.07,
        "涨跌额": -1.57,
        "涨跌幅": -1.33,
        "开盘价": 118.25,
        "最高价": 119.17,
        "最低价": 114.49,
        "昨收价": 117.64,
        "总市值": 50338266328.0,
        "市盈率": 32.41,
        "成交量": 4391175.0,
        "成交额": 509765760.0,
        "振幅": 3.98,
        "换手率": 1.01,
        "symbol": "106.CCI"
    },
    {
        "序号": 10410,
        "name": "Essential Properties Realty Tru",
        "最新价": 24.31,
        "涨跌额": -0.33,
        "涨跌幅": -1.34,
        "开盘价": 24.53,
        "最高价": 24.64,
        "最低价": 24.13,
        "昨收价": 24.64,
        "总市值": 3792948837.0,
        "市盈率": 21.43,
        "成交量": 1103628.0,
        "成交额": 26816669.0,
        "振幅": 2.07,
        "换手率": 0.71,
        "symbol": "106.EPRT"
    },
    {
        "序号": 10411,
        "name": "GraniteShares Gold Trust",
        "最新价": 19.8,
        "涨跌额": -0.27,
        "涨跌幅": -1.35,
        "开盘价": 19.84,
        "最高价": 19.92,
        "最低价": 19.73,
        "昨收价": 20.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1102786.0,
        "成交额": 21848400.0,
        "振幅": 0.95,
        "换手率": null,
        "symbol": "107.BAR"
    },
    {
        "序号": 10412,
        "name": "John Hancock Preferred Income F",
        "最新价": 13.18,
        "涨跌额": -0.18,
        "涨跌幅": -1.35,
        "开盘价": 13.36,
        "最高价": 13.4,
        "最低价": 13.18,
        "昨收价": 13.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 82902.0,
        "成交额": 1099142.0,
        "振幅": 1.65,
        "换手率": null,
        "symbol": "106.HPS"
    },
    {
        "序号": 10413,
        "name": "二倍做多NASDAQ生物技术ProShares",
        "最新价": 47.55,
        "涨跌额": -0.65,
        "涨跌幅": -1.35,
        "开盘价": 47.79,
        "最高价": 48.26,
        "最低价": 47.41,
        "昨收价": 48.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 4892.0,
        "成交额": 234008.0,
        "振幅": 1.76,
        "换手率": null,
        "symbol": "105.BIB"
    },
    {
        "序号": 10414,
        "name": "泰国ETF-iShares MSCI",
        "最新价": 60.69,
        "涨跌额": -0.83,
        "涨跌幅": -1.35,
        "开盘价": 60.69,
        "最高价": 60.98,
        "最低价": 60.33,
        "昨收价": 61.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 183773.0,
        "成交额": 11116531.0,
        "振幅": 1.06,
        "换手率": null,
        "symbol": "107.THD"
    },
    {
        "序号": 10415,
        "name": "Summit Therapeutics Inc",
        "最新价": 2.19,
        "涨跌额": -0.03,
        "涨跌幅": -1.35,
        "开盘价": 2.19,
        "最高价": 2.25,
        "最低价": 2.17,
        "昨收价": 2.22,
        "总市值": 1534845947.0,
        "市盈率": -2.57,
        "成交量": 701103.0,
        "成交额": 1545836.0,
        "振幅": 3.6,
        "换手率": 0.1,
        "symbol": "105.SMMT"
    },
    {
        "序号": 10416,
        "name": "Markforged Holding Corp",
        "最新价": 0.73,
        "涨跌额": -0.01,
        "涨跌幅": -1.35,
        "开盘价": 0.74,
        "最高价": 0.74,
        "最低价": 0.7,
        "昨收价": 0.74,
        "总市值": 144843005.0,
        "市盈率": -1.45,
        "成交量": 364808.0,
        "成交额": 261700.0,
        "振幅": 5.41,
        "换手率": 0.18,
        "symbol": "106.MKFG"
    },
    {
        "序号": 10417,
        "name": "Glatfelter Corp",
        "最新价": 1.46,
        "涨跌额": -0.02,
        "涨跌幅": -1.35,
        "开盘价": 1.48,
        "最高价": 1.54,
        "最低价": 1.45,
        "昨收价": 1.48,
        "总市值": 65826902.0,
        "市盈率": -0.63,
        "成交量": 151050.0,
        "成交额": 223718.0,
        "振幅": 6.08,
        "换手率": 0.34,
        "symbol": "106.GLT"
    },
    {
        "序号": 10418,
        "name": "CBL International Ltd",
        "最新价": 1.46,
        "涨跌额": -0.02,
        "涨跌幅": -1.35,
        "开盘价": 1.43,
        "最高价": 1.47,
        "最低价": 1.42,
        "昨收价": 1.48,
        "总市值": 36500000.0,
        "市盈率": 9.73,
        "成交量": 10263.0,
        "成交额": 14795.0,
        "振幅": 3.38,
        "换手率": 0.04,
        "symbol": "105.BANL"
    },
    {
        "序号": 10419,
        "name": "纽约时报",
        "最新价": 46.68,
        "涨跌额": -0.64,
        "涨跌幅": -1.35,
        "开盘价": 47.09,
        "最高价": 47.48,
        "最低价": 46.66,
        "昨收价": 47.32,
        "总市值": 7674523568.0,
        "市盈率": 39.7,
        "成交量": 1225133.0,
        "成交额": 57390370.0,
        "振幅": 1.73,
        "换手率": 0.75,
        "symbol": "106.NYT"
    },
    {
        "序号": 10420,
        "name": "Sun Communities Inc",
        "最新价": 127.64,
        "涨跌额": -1.75,
        "涨跌幅": -1.35,
        "开盘价": 128.35,
        "最高价": 129.04,
        "最低价": 126.94,
        "昨收价": 129.39,
        "总市值": 15884190178.0,
        "市盈率": 66.49,
        "成交量": 660809.0,
        "成交额": 84284509.0,
        "振幅": 1.62,
        "换手率": 0.53,
        "symbol": "106.SUI"
    },
    {
        "序号": 10421,
        "name": "黄金ETF-iShares",
        "最新价": 37.9,
        "涨跌额": -0.52,
        "涨跌幅": -1.35,
        "开盘价": 37.97,
        "最高价": 38.13,
        "最低价": 37.76,
        "昨收价": 38.42,
        "总市值": null,
        "市盈率": null,
        "成交量": 6003940.0,
        "成交额": 227885060.0,
        "振幅": 0.96,
        "换手率": null,
        "symbol": "107.IAU"
    },
    {
        "序号": 10422,
        "name": "Sonder Holdings Inc",
        "最新价": 2.91,
        "涨跌额": -0.04,
        "涨跌幅": -1.36,
        "开盘价": 2.92,
        "最高价": 2.99,
        "最低价": 2.76,
        "昨收价": 2.95,
        "总市值": 32198388.0,
        "市盈率": -0.13,
        "成交量": 47398.0,
        "成交额": 134958.0,
        "振幅": 7.8,
        "换手率": 0.43,
        "symbol": "105.SOND"
    },
    {
        "序号": 10423,
        "name": "Breeze Holdings Acquisition Cor",
        "最新价": 10.9,
        "涨跌额": -0.15,
        "涨跌幅": -1.36,
        "开盘价": 10.92,
        "最高价": 10.92,
        "最低价": 10.9,
        "昨收价": 11.05,
        "总市值": 46862108.0,
        "市盈率": -10.92,
        "成交量": 41825.0,
        "成交额": 455924.0,
        "振幅": 0.18,
        "换手率": 0.97,
        "symbol": "105.BREZ"
    },
    {
        "序号": 10424,
        "name": "Guggenheim Taxable Municipal Ma",
        "最新价": 15.98,
        "涨跌额": -0.22,
        "涨跌幅": -1.36,
        "开盘价": 16.05,
        "最高价": 16.14,
        "最低价": 15.96,
        "昨收价": 16.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 51452.0,
        "成交额": 824377.0,
        "振幅": 1.11,
        "换手率": null,
        "symbol": "106.GBAB"
    },
    {
        "序号": 10425,
        "name": "Templeton Emerging Markets Inco",
        "最新价": 5.08,
        "涨跌额": -0.07,
        "涨跌幅": -1.36,
        "开盘价": 5.12,
        "最高价": 5.12,
        "最低价": 5.08,
        "昨收价": 5.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 155088.0,
        "成交额": 790694.0,
        "振幅": 0.78,
        "换手率": null,
        "symbol": "106.TEI"
    },
    {
        "序号": 10426,
        "name": "ProShares Ultra 7-10 Year Treas",
        "最新价": 43.53,
        "涨跌额": -0.6,
        "涨跌幅": -1.36,
        "开盘价": 43.64,
        "最高价": 43.64,
        "最低价": 43.32,
        "昨收价": 44.13,
        "总市值": null,
        "市盈率": null,
        "成交量": 10393.0,
        "成交额": 452028.0,
        "振幅": 0.73,
        "换手率": null,
        "symbol": "107.UST"
    },
    {
        "序号": 10427,
        "name": "Mega Matrix Corp",
        "最新价": 1.45,
        "涨跌额": -0.02,
        "涨跌幅": -1.36,
        "开盘价": 1.49,
        "最高价": 1.55,
        "最低价": 1.44,
        "昨收价": 1.47,
        "总市值": 46000715.0,
        "市盈率": -3.18,
        "成交量": 80221.0,
        "成交额": 119102.0,
        "振幅": 7.48,
        "换手率": 0.25,
        "symbol": "107.MPU"
    },
    {
        "序号": 10428,
        "name": "Grindr Inc Wt",
        "最新价": 1.45,
        "涨跌额": -0.02,
        "涨跌幅": -1.36,
        "开盘价": 1.49,
        "最高价": 1.55,
        "最低价": 1.42,
        "昨收价": 1.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 20446.0,
        "成交额": 30679.0,
        "振幅": 8.84,
        "换手率": null,
        "symbol": "106.GRND_WS"
    },
    {
        "序号": 10429,
        "name": "The Allstate Corp Series I Pfd",
        "最新价": 20.27,
        "涨跌额": -0.28,
        "涨跌幅": -1.36,
        "开盘价": 20.54,
        "最高价": 20.64,
        "最低价": 20.27,
        "昨收价": 20.55,
        "总市值": 243240.0,
        "市盈率": null,
        "成交量": 14959.0,
        "成交额": 305370.0,
        "振幅": 1.8,
        "换手率": 124.66,
        "symbol": "106.ALL_I"
    },
    {
        "序号": 10430,
        "name": "拜切",
        "最新价": 133.13,
        "涨跌额": -1.84,
        "涨跌幅": -1.36,
        "开盘价": 135.16,
        "最高价": 136.36,
        "最低价": 131.74,
        "昨收价": 134.97,
        "总市值": 4292251785.0,
        "市盈率": 41.55,
        "成交量": 83703.0,
        "成交额": 11140326.0,
        "振幅": 3.42,
        "换手率": 0.26,
        "symbol": "105.BCPC"
    },
    {
        "序号": 10431,
        "name": "Oxford Lane Capital Corp Series",
        "最新价": 21.6,
        "涨跌额": -0.3,
        "涨跌幅": -1.37,
        "开盘价": 21.87,
        "最高价": 21.87,
        "最低价": 21.6,
        "昨收价": 21.9,
        "总市值": 51840000.0,
        "市盈率": null,
        "成交量": 13440.0,
        "成交额": 290870.0,
        "振幅": 1.23,
        "换手率": 0.56,
        "symbol": "105.OXLCO"
    },
    {
        "序号": 10432,
        "name": "Bioventus Inc-A",
        "最新价": 5.04,
        "涨跌额": -0.07,
        "涨跌幅": -1.37,
        "开盘价": 5.01,
        "最高价": 5.26,
        "最低价": 4.98,
        "昨收价": 5.11,
        "总市值": 396912938.0,
        "市盈率": -2.18,
        "成交量": 153148.0,
        "成交额": 778911.0,
        "振幅": 5.48,
        "换手率": 0.19,
        "symbol": "105.BVS"
    },
    {
        "序号": 10433,
        "name": "Bio Rad实验室-B",
        "最新价": 305.75,
        "涨跌额": -4.25,
        "涨跌幅": -1.37,
        "开盘价": 305.75,
        "最高价": 305.75,
        "最低价": 305.75,
        "昨收价": 310.0,
        "总市值": 8911135728.0,
        "市盈率": -55.94,
        "成交量": 104.0,
        "成交额": 31798.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "106.BIO_B"
    },
    {
        "序号": 10434,
        "name": "金罗斯黄金",
        "最新价": 5.75,
        "涨跌额": -0.08,
        "涨跌幅": -1.37,
        "开盘价": 5.69,
        "最高价": 5.81,
        "最低价": 5.66,
        "昨收价": 5.83,
        "总市值": 7059271360.0,
        "市盈率": 28.83,
        "成交量": 14437576.0,
        "成交额": 82826224.0,
        "振幅": 2.57,
        "换手率": 1.18,
        "symbol": "106.KGC"
    },
    {
        "序号": 10435,
        "name": "中国大型股ETF-iShares",
        "最新价": 23.7,
        "涨跌额": -0.33,
        "涨跌幅": -1.37,
        "开盘价": 23.8,
        "最高价": 23.87,
        "最低价": 23.67,
        "昨收价": 24.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 31180970.0,
        "成交额": 740502080.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "107.FXI"
    },
    {
        "序号": 10436,
        "name": "Cherry Hill Mortgage Investment",
        "最新价": 20.82,
        "涨跌额": -0.29,
        "涨跌幅": -1.37,
        "开盘价": 20.92,
        "最高价": 21.07,
        "最低价": 20.82,
        "昨收价": 21.11,
        "总市值": 57913641.0,
        "市盈率": null,
        "成交量": 5426.0,
        "成交额": 113868.0,
        "振幅": 1.18,
        "换手率": 0.2,
        "symbol": "106.CHMI_A"
    },
    {
        "序号": 10437,
        "name": "巴西航空工业",
        "最新价": 18.66,
        "涨跌额": -0.26,
        "涨跌幅": -1.37,
        "开盘价": 18.82,
        "最高价": 18.97,
        "最低价": 18.56,
        "昨收价": 18.92,
        "总市值": 3427062040.0,
        "市盈率": -601.24,
        "成交量": 982825.0,
        "成交额": 18393820.0,
        "振幅": 2.17,
        "换手率": 0.54,
        "symbol": "106.ERJ"
    },
    {
        "序号": 10438,
        "name": "Lumos Pharma Inc",
        "最新价": 2.87,
        "涨跌额": -0.04,
        "涨跌幅": -1.37,
        "开盘价": 2.85,
        "最高价": 3.03,
        "最低价": 2.85,
        "昨收价": 2.91,
        "总市值": 22714850.0,
        "市盈率": -0.69,
        "成交量": 18862.0,
        "成交额": 55489.0,
        "振幅": 6.19,
        "换手率": 0.24,
        "symbol": "105.LUMO"
    },
    {
        "序号": 10439,
        "name": "标记临床研究(优先股)",
        "最新价": 22.24,
        "涨跌额": -0.31,
        "涨跌幅": -1.37,
        "开盘价": 22.45,
        "最高价": 22.45,
        "最低价": 22.06,
        "昨收价": 22.55,
        "总市值": 35584.0,
        "市盈率": null,
        "成交量": 8198.0,
        "成交额": 182520.0,
        "振幅": 1.73,
        "换手率": 512.38,
        "symbol": "105.XOMAO"
    },
    {
        "序号": 10440,
        "name": "Weyco Group Inc",
        "最新价": 29.36,
        "涨跌额": -0.41,
        "涨跌幅": -1.38,
        "开盘价": 29.62,
        "最高价": 29.98,
        "最低价": 29.18,
        "昨收价": 29.77,
        "总市值": 278850182.0,
        "市盈率": 8.75,
        "成交量": 12626.0,
        "成交额": 370564.0,
        "振幅": 2.69,
        "换手率": 0.13,
        "symbol": "105.WEYS"
    },
    {
        "序号": 10441,
        "name": "龙运国际",
        "最新价": 1.43,
        "涨跌额": -0.02,
        "涨跌幅": -1.38,
        "开盘价": 1.4,
        "最高价": 1.46,
        "最低价": 1.1,
        "昨收价": 1.45,
        "总市值": 46758671.0,
        "市盈率": -3.75,
        "成交量": 109246.0,
        "成交额": 139712.0,
        "振幅": 24.83,
        "换手率": 0.33,
        "symbol": "105.MATH"
    },
    {
        "序号": 10442,
        "name": "和信超媒体",
        "最新价": 1.43,
        "涨跌额": -0.02,
        "涨跌幅": -1.38,
        "开盘价": 1.42,
        "最高价": 1.43,
        "最低价": 1.41,
        "昨收价": 1.45,
        "总市值": 15804696.0,
        "市盈率": -15.28,
        "成交量": 2835.0,
        "成交额": 4006.0,
        "振幅": 1.38,
        "换手率": 0.03,
        "symbol": "105.GIGM"
    },
    {
        "序号": 10443,
        "name": "EG Acquisition Corp-A",
        "最新价": 10.72,
        "涨跌额": -0.15,
        "涨跌幅": -1.38,
        "开盘价": 10.74,
        "最高价": 10.74,
        "最低价": 10.72,
        "昨收价": 10.87,
        "总市值": 105665207.0,
        "市盈率": 104.34,
        "成交量": 511.0,
        "成交额": 5483.0,
        "振幅": 0.18,
        "换手率": 0.01,
        "symbol": "106.EGGF"
    },
    {
        "序号": 10444,
        "name": "European Wax Center Inc-A",
        "最新价": 14.24,
        "涨跌额": -0.2,
        "涨跌幅": -1.39,
        "开盘价": 14.43,
        "最高价": 14.82,
        "最低价": 13.75,
        "昨收价": 14.44,
        "总市值": 889165379.0,
        "市盈率": 121.02,
        "成交量": 636200.0,
        "成交额": 9072572.0,
        "振幅": 7.41,
        "换手率": 1.02,
        "symbol": "105.EWCZ"
    },
    {
        "序号": 10445,
        "name": "通用磨坊",
        "最新价": 65.47,
        "涨跌额": -0.92,
        "涨跌幅": -1.39,
        "开盘价": 66.43,
        "最高价": 66.52,
        "最低价": 65.46,
        "昨收价": 66.39,
        "总市值": 38056351123.0,
        "市盈率": 15.55,
        "成交量": 2452661.0,
        "成交额": 161178913.0,
        "振幅": 1.6,
        "换手率": 0.42,
        "symbol": "106.GIS"
    },
    {
        "序号": 10446,
        "name": "Vornado Realty Trust Series L P",
        "最新价": 15.63,
        "涨跌额": -0.22,
        "涨跌幅": -1.39,
        "开盘价": 15.84,
        "最高价": 15.84,
        "最低价": 15.4,
        "昨收价": 15.85,
        "总市值": 187560000.0,
        "市盈率": null,
        "成交量": 43749.0,
        "成交额": 684879.0,
        "振幅": 2.78,
        "换手率": 0.36,
        "symbol": "106.VNO_L"
    },
    {
        "序号": 10447,
        "name": "Vuzix Corp",
        "最新价": 2.13,
        "涨跌额": -0.03,
        "涨跌幅": -1.39,
        "开盘价": 2.14,
        "最高价": 2.25,
        "最低价": 2.13,
        "昨收价": 2.16,
        "总市值": 134887805.0,
        "市盈率": -3.29,
        "成交量": 431533.0,
        "成交额": 934636.0,
        "振幅": 5.56,
        "换手率": 0.68,
        "symbol": "105.VUZI"
    },
    {
        "序号": 10448,
        "name": "兰卡斯特食品",
        "最新价": 166.42,
        "涨跌额": -2.35,
        "涨跌幅": -1.39,
        "开盘价": 167.16,
        "最高价": 169.46,
        "最低价": 166.33,
        "昨收价": 168.77,
        "总市值": 4579379140.0,
        "市盈率": 38.93,
        "成交量": 129037.0,
        "成交额": 21567639.0,
        "振幅": 1.85,
        "换手率": 0.47,
        "symbol": "105.LANC"
    },
    {
        "序号": 10449,
        "name": "配方系统",
        "最新价": 59.77,
        "涨跌额": -0.85,
        "涨跌幅": -1.4,
        "开盘价": 59.77,
        "最高价": 59.77,
        "最低价": 59.77,
        "昨收价": 60.62,
        "总市值": 915536957.0,
        "市盈率": 14.39,
        "成交量": 466.0,
        "成交额": 27852.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.FORTY"
    },
    {
        "序号": 10450,
        "name": "Tutor Perini Corp",
        "最新价": 8.42,
        "涨跌额": -0.12,
        "涨跌幅": -1.41,
        "开盘价": 8.51,
        "最高价": 8.71,
        "最低价": 8.41,
        "昨收价": 8.54,
        "总市值": 438026663.0,
        "市盈率": -2.02,
        "成交量": 135109.0,
        "成交额": 1144010.0,
        "振幅": 3.51,
        "换手率": 0.26,
        "symbol": "106.TPC"
    },
    {
        "序号": 10451,
        "name": "Hycroft Mining Holding Corp-A",
        "最新价": 2.1,
        "涨跌额": -0.03,
        "涨跌幅": -1.41,
        "开盘价": 2.1,
        "最高价": 2.15,
        "最低价": 2.06,
        "昨收价": 2.13,
        "总市值": 42447896.0,
        "市盈率": -0.74,
        "成交量": 90962.0,
        "成交额": 190793.0,
        "振幅": 4.23,
        "换手率": 0.45,
        "symbol": "105.HYMC"
    },
    {
        "序号": 10452,
        "name": "CXApp Inc-A",
        "最新价": 1.4,
        "涨跌额": -0.02,
        "涨跌幅": -1.41,
        "开盘价": 1.41,
        "最高价": 1.44,
        "最低价": 1.38,
        "昨收价": 1.42,
        "总市值": 21356145.0,
        "市盈率": 1.21,
        "成交量": 123926.0,
        "成交额": 173788.0,
        "振幅": 4.23,
        "换手率": 0.81,
        "symbol": "105.CXAI"
    },
    {
        "序号": 10453,
        "name": "Abacus Life Inc",
        "最新价": 7.0,
        "涨跌额": -0.1,
        "涨跌幅": -1.41,
        "开盘价": 7.33,
        "最高价": 7.33,
        "最低价": 6.88,
        "昨收价": 7.1,
        "总市值": 443448761.0,
        "市盈率": -69.16,
        "成交量": 30108.0,
        "成交额": 214173.0,
        "振幅": 6.34,
        "换手率": 0.05,
        "symbol": "105.ABL"
    },
    {
        "序号": 10454,
        "name": "Blue Owl Capital Inc-A",
        "最新价": 13.26,
        "涨跌额": -0.19,
        "涨跌幅": -1.41,
        "开盘价": 13.4,
        "最高价": 13.47,
        "最低价": 13.22,
        "昨收价": 13.45,
        "总市值": 18682072291.0,
        "市盈率": 493.23,
        "成交量": 6029638.0,
        "成交额": 80233954.0,
        "振幅": 1.86,
        "换手率": 0.43,
        "symbol": "106.OWL"
    },
    {
        "序号": 10455,
        "name": "恐慌指数期货中期合约ETN-iPath",
        "最新价": 14.65,
        "涨跌额": -0.21,
        "涨跌幅": -1.41,
        "开盘价": 14.85,
        "最高价": 14.85,
        "最低价": 14.62,
        "昨收价": 14.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 13186.0,
        "成交额": 194043.0,
        "振幅": 1.55,
        "换手率": null,
        "symbol": "107.VXZ"
    },
    {
        "序号": 10456,
        "name": "Simplify Short Term Treasury Fu",
        "最新价": 22.32,
        "涨跌额": -0.32,
        "涨跌幅": -1.41,
        "开盘价": 22.41,
        "最高价": 22.43,
        "最低价": 22.29,
        "昨收价": 22.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 530471.0,
        "成交额": 11859334.0,
        "振幅": 0.62,
        "换手率": null,
        "symbol": "107.TUA"
    },
    {
        "序号": 10457,
        "name": "Webster Financial Corp Series F",
        "最新价": 18.1,
        "涨跌额": -0.26,
        "涨跌幅": -1.42,
        "开盘价": 18.35,
        "最高价": 18.65,
        "最低价": 18.04,
        "昨收价": 18.36,
        "总市值": 108600.0,
        "市盈率": null,
        "成交量": 7585.0,
        "成交额": 138562.0,
        "振幅": 3.32,
        "换手率": 126.42,
        "symbol": "106.WBS_F"
    },
    {
        "序号": 10458,
        "name": "Western Midstream Partners LP",
        "最新价": 27.84,
        "涨跌额": -0.4,
        "涨跌幅": -1.42,
        "开盘价": 28.24,
        "最高价": 28.37,
        "最低价": 27.78,
        "昨收价": 28.24,
        "总市值": 10565735713.0,
        "市盈率": 9.87,
        "成交量": 1451757.0,
        "成交额": 40651850.0,
        "振幅": 2.09,
        "换手率": 0.38,
        "symbol": "106.WES"
    },
    {
        "序号": 10459,
        "name": "谷歌-A",
        "最新价": 134.99,
        "涨跌额": -1.94,
        "涨跌幅": -1.42,
        "开盘价": 134.2,
        "最高价": 136.4,
        "最低价": 134.03,
        "昨收价": 136.93,
        "总市值": 1689534840000.0,
        "市盈率": 25.32,
        "成交量": 32260112.0,
        "成交额": 4356134144.0,
        "振幅": 1.73,
        "换手率": 0.55,
        "symbol": "105.GOOGL"
    },
    {
        "序号": 10460,
        "name": "ICU医疗",
        "最新价": 86.25,
        "涨跌额": -1.24,
        "涨跌幅": -1.42,
        "开盘价": 87.99,
        "最高价": 88.89,
        "最低价": 85.68,
        "昨收价": 87.49,
        "总市值": 2082081555.0,
        "市盈率": -74.24,
        "成交量": 194568.0,
        "成交额": 16821742.0,
        "振幅": 3.67,
        "换手率": 0.81,
        "symbol": "105.ICUI"
    },
    {
        "序号": 10461,
        "name": "KraneShares Dynamic Emerging Ma",
        "最新价": 24.31,
        "涨跌额": -0.35,
        "涨跌幅": -1.42,
        "开盘价": 24.42,
        "最高价": 24.42,
        "最低价": 24.31,
        "昨收价": 24.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 1668.0,
        "成交额": 40684.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.KEM"
    },
    {
        "序号": 10462,
        "name": "二倍做空罗素2000ETF-ProShares",
        "最新价": 13.88,
        "涨跌额": -0.2,
        "涨跌幅": -1.42,
        "开盘价": 14.14,
        "最高价": 14.17,
        "最低价": 13.74,
        "昨收价": 14.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 2330520.0,
        "成交额": 32494590.0,
        "振幅": 3.05,
        "换手率": null,
        "symbol": "107.TWM"
    },
    {
        "序号": 10463,
        "name": "Dakota Gold Corp",
        "最新价": 2.77,
        "涨跌额": -0.04,
        "涨跌幅": -1.42,
        "开盘价": 2.76,
        "最高价": 2.86,
        "最低价": 2.72,
        "昨收价": 2.81,
        "总市值": 240270553.0,
        "市盈率": -24.35,
        "成交量": 143137.0,
        "成交额": 395167.0,
        "振幅": 4.98,
        "换手率": 0.17,
        "symbol": "107.DC"
    },
    {
        "序号": 10464,
        "name": "Acumen Pharmaceuticals Inc",
        "最新价": 2.77,
        "涨跌额": -0.04,
        "涨跌幅": -1.42,
        "开盘价": 2.83,
        "最高价": 2.84,
        "最低价": 2.71,
        "昨收价": 2.81,
        "总市值": 160411977.0,
        "市盈率": -3.29,
        "成交量": 307290.0,
        "成交额": 848072.0,
        "振幅": 4.63,
        "换手率": 0.53,
        "symbol": "105.ABOS"
    },
    {
        "序号": 10465,
        "name": "VS Media Holdings Ltd-A",
        "最新价": 0.69,
        "涨跌额": -0.01,
        "涨跌幅": -1.43,
        "开盘价": 0.72,
        "最高价": 0.78,
        "最低价": 0.68,
        "昨收价": 0.7,
        "总市值": 15180000.0,
        "市盈率": -3.79,
        "成交量": 441609.0,
        "成交额": 316139.0,
        "振幅": 14.29,
        "换手率": 2.01,
        "symbol": "105.VSME"
    },
    {
        "序号": 10466,
        "name": "CNS Pharmaceuticals Inc",
        "最新价": 2.07,
        "涨跌额": -0.03,
        "涨跌幅": -1.43,
        "开盘价": 2.08,
        "最高价": 2.1,
        "最低价": 1.92,
        "昨收价": 2.1,
        "总市值": 10864598.0,
        "市盈率": -0.58,
        "成交量": 45832.0,
        "成交额": 92434.0,
        "振幅": 8.57,
        "换手率": 0.87,
        "symbol": "105.CNSP"
    },
    {
        "序号": 10467,
        "name": "JM斯马克",
        "最新价": 119.33,
        "涨跌额": -1.73,
        "涨跌幅": -1.43,
        "开盘价": 121.05,
        "最高价": 121.57,
        "最低价": 119.19,
        "昨收价": 121.06,
        "总市值": 12666140609.0,
        "市盈率": -924.54,
        "成交量": 1515718.0,
        "成交额": 181607684.0,
        "振幅": 1.97,
        "换手率": 1.43,
        "symbol": "106.SJM"
    },
    {
        "序号": 10468,
        "name": "易美味",
        "最新价": 39.79,
        "涨跌额": -0.58,
        "涨跌幅": -1.44,
        "开盘价": 40.07,
        "最高价": 40.28,
        "最低价": 39.49,
        "昨收价": 40.37,
        "总市值": 3971445192.0,
        "市盈率": 29.73,
        "成交量": 406425.0,
        "成交额": 16179510.0,
        "振幅": 1.96,
        "换手率": 0.41,
        "symbol": "105.SMPL"
    },
    {
        "序号": 10469,
        "name": "Auburn National Bancorporation ",
        "最新价": 20.5,
        "涨跌额": -0.3,
        "涨跌幅": -1.44,
        "开盘价": 20.79,
        "最高价": 21.24,
        "最低价": 20.25,
        "昨收价": 20.8,
        "总市值": 71619087.0,
        "市盈率": 7.27,
        "成交量": 3543.0,
        "成交额": 72410.0,
        "振幅": 4.76,
        "换手率": 0.1,
        "symbol": "105.AUBN"
    },
    {
        "序号": 10470,
        "name": "Nkarta Inc",
        "最新价": 2.73,
        "涨跌额": -0.04,
        "涨跌幅": -1.44,
        "开盘价": 2.8,
        "最高价": 2.82,
        "最低价": 2.66,
        "昨收价": 2.77,
        "总市值": 133967494.0,
        "市盈率": -1.1,
        "成交量": 414692.0,
        "成交额": 1133001.0,
        "振幅": 5.78,
        "换手率": 0.85,
        "symbol": "105.NKTX"
    },
    {
        "序号": 10471,
        "name": "Brookfield Property Partners LP",
        "最新价": 12.96,
        "涨跌额": -0.19,
        "涨跌幅": -1.44,
        "开盘价": 13.17,
        "最高价": 13.18,
        "最低价": 12.9,
        "昨收价": 13.15,
        "总市值": 347905446.0,
        "市盈率": null,
        "成交量": 30801.0,
        "成交额": 400313.0,
        "振幅": 2.13,
        "换手率": 0.11,
        "symbol": "105.BPYPM"
    },
    {
        "序号": 10472,
        "name": "Templeton Dragon Fund",
        "最新价": 8.17,
        "涨跌额": -0.12,
        "涨跌幅": -1.45,
        "开盘价": 8.24,
        "最高价": 8.33,
        "最低价": 8.06,
        "昨收价": 8.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 80066.0,
        "成交额": 655640.0,
        "振幅": 3.26,
        "换手率": null,
        "symbol": "106.TDF"
    },
    {
        "序号": 10473,
        "name": "Osisko Development Corp",
        "最新价": 2.72,
        "涨跌额": -0.04,
        "涨跌幅": -1.45,
        "开盘价": 2.71,
        "最高价": 2.8,
        "最低价": 2.7,
        "昨收价": 2.76,
        "总市值": 228746100.0,
        "市盈率": -2.82,
        "成交量": 55670.0,
        "成交额": 152054.0,
        "振幅": 3.62,
        "换手率": 0.07,
        "symbol": "106.ODV"
    },
    {
        "序号": 10474,
        "name": "AdvisorShares Pure US Cannabis ",
        "最新价": 7.46,
        "涨跌额": -0.11,
        "涨跌幅": -1.45,
        "开盘价": 7.68,
        "最高价": 7.68,
        "最低价": 7.28,
        "昨收价": 7.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 4226377.0,
        "成交额": 31578304.0,
        "振幅": 5.28,
        "换手率": null,
        "symbol": "107.MSOS"
    },
    {
        "序号": 10475,
        "name": "The Bancorp Inc",
        "最新价": 39.29,
        "涨跌额": -0.58,
        "涨跌幅": -1.45,
        "开盘价": 39.8,
        "最高价": 40.41,
        "最低价": 38.73,
        "昨收价": 39.87,
        "总市值": 2108133789.0,
        "市盈率": 11.18,
        "成交量": 705719.0,
        "成交额": 27870361.0,
        "振幅": 4.21,
        "换手率": 1.32,
        "symbol": "105.TBBK"
    },
    {
        "序号": 10476,
        "name": "Global X Gold Explorers ETF",
        "最新价": 24.37,
        "涨跌额": -0.36,
        "涨跌幅": -1.46,
        "开盘价": 24.47,
        "最高价": 24.47,
        "最低价": 24.13,
        "昨收价": 24.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 4260.0,
        "成交额": 103513.0,
        "振幅": 1.37,
        "换手率": null,
        "symbol": "107.GOEX"
    },
    {
        "序号": 10477,
        "name": "Stifel Financial Corp Series B ",
        "最新价": 23.64,
        "涨跌额": -0.35,
        "涨跌幅": -1.46,
        "开盘价": 23.98,
        "最高价": 23.98,
        "最低价": 23.64,
        "昨收价": 23.99,
        "总市值": 151296.0,
        "市盈率": null,
        "成交量": 9758.0,
        "成交额": 231997.0,
        "振幅": 1.42,
        "换手率": 152.47,
        "symbol": "106.SF_B"
    },
    {
        "序号": 10478,
        "name": "Sprott Gold Miners ETF",
        "最新价": 24.29,
        "涨跌额": -0.36,
        "涨跌幅": -1.46,
        "开盘价": 24.19,
        "最高价": 24.61,
        "最低价": 24.01,
        "昨收价": 24.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 24396.0,
        "成交额": 590750.0,
        "振幅": 2.43,
        "换手率": null,
        "symbol": "107.SGDM"
    },
    {
        "序号": 10479,
        "name": "高乐氏",
        "最新价": 141.55,
        "涨跌额": -2.1,
        "涨跌幅": -1.46,
        "开盘价": 143.45,
        "最高价": 143.45,
        "最低价": 141.54,
        "昨收价": 143.65,
        "总市值": 17560565322.0,
        "市盈率": 204.19,
        "成交量": 1233425.0,
        "成交额": 175279291.0,
        "振幅": 1.33,
        "换手率": 0.99,
        "symbol": "106.CLX"
    },
    {
        "序号": 10480,
        "name": "英频杰",
        "最新价": 82.56,
        "涨跌额": -1.23,
        "涨跌幅": -1.47,
        "开盘价": 82.97,
        "最高价": 85.76,
        "最低价": 82.55,
        "昨收价": 83.79,
        "总市值": 2233731967.0,
        "市盈率": -78.92,
        "成交量": 253833.0,
        "成交额": 21167739.0,
        "振幅": 3.83,
        "换手率": 0.94,
        "symbol": "105.PI"
    },
    {
        "序号": 10481,
        "name": "蔚来",
        "最新价": 7.38,
        "涨跌额": -0.11,
        "涨跌幅": -1.47,
        "开盘价": 7.37,
        "最高价": 7.57,
        "最低价": 7.3,
        "昨收价": 7.49,
        "总市值": 12455534565.0,
        "市盈率": -4.11,
        "成交量": 42786187.0,
        "成交额": 317976128.0,
        "振幅": 3.6,
        "换手率": 2.54,
        "symbol": "106.NIO"
    },
    {
        "序号": 10482,
        "name": "Ark Restaurants Corp",
        "最新价": 15.42,
        "涨跌额": -0.23,
        "涨跌幅": -1.47,
        "开盘价": 15.54,
        "最高价": 15.75,
        "最低价": 15.42,
        "昨收价": 15.65,
        "总市值": 55576101.0,
        "市盈率": 10.69,
        "成交量": 5439.0,
        "成交额": 85026.0,
        "振幅": 2.11,
        "换手率": 0.15,
        "symbol": "105.ARKR"
    },
    {
        "序号": 10483,
        "name": "ReWalk Robotics Ltd",
        "最新价": 0.67,
        "涨跌额": -0.01,
        "涨跌幅": -1.47,
        "开盘价": 0.68,
        "最高价": 0.69,
        "最低价": 0.66,
        "昨收价": 0.68,
        "总市值": 40216511.0,
        "市盈率": -1.84,
        "成交量": 119010.0,
        "成交额": 80032.0,
        "振幅": 4.41,
        "换手率": 0.2,
        "symbol": "105.RWLK"
    },
    {
        "序号": 10484,
        "name": "Global X Solar ETF",
        "最新价": 11.39,
        "涨跌额": -0.17,
        "涨跌幅": -1.47,
        "开盘价": 11.65,
        "最高价": 11.65,
        "最低价": 11.34,
        "昨收价": 11.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 4498.0,
        "成交额": 52055.0,
        "振幅": 2.68,
        "换手率": null,
        "symbol": "105.RAYS"
    },
    {
        "序号": 10485,
        "name": "Texas Capital Bancshares Inc Se",
        "最新价": 18.04,
        "涨跌额": -0.27,
        "涨跌幅": -1.47,
        "开盘价": 18.31,
        "最高价": 18.48,
        "最低价": 17.92,
        "昨收价": 18.31,
        "总市值": 5412000.0,
        "市盈率": null,
        "成交量": 24205.0,
        "成交额": 439466.0,
        "振幅": 3.06,
        "换手率": 8.07,
        "symbol": "105.TCBIO"
    },
    {
        "序号": 10486,
        "name": "荷美尔食品",
        "最新价": 31.38,
        "涨跌额": -0.47,
        "涨跌幅": -1.48,
        "开盘价": 31.89,
        "最高价": 31.89,
        "最低价": 31.36,
        "昨收价": 31.85,
        "总市值": 17159840957.0,
        "市盈率": 21.62,
        "成交量": 2133703.0,
        "成交额": 67183501.0,
        "振幅": 1.66,
        "换手率": 0.39,
        "symbol": "106.HRL"
    },
    {
        "序号": 10487,
        "name": "Old Dominion Freight Line Inc",
        "最新价": 369.45,
        "涨跌额": -5.54,
        "涨跌幅": -1.48,
        "开盘价": 373.8,
        "最高价": 380.54,
        "最低价": 369.02,
        "昨收价": 374.99,
        "总市值": 40312039470.0,
        "市盈率": 32.49,
        "成交量": 1032999.0,
        "成交额": 382892128.0,
        "振幅": 3.07,
        "换手率": 0.95,
        "symbol": "105.ODFL"
    },
    {
        "序号": 10488,
        "name": "OceanFirst Financial Corp Serie",
        "最新价": 24.0,
        "涨跌额": -0.36,
        "涨跌幅": -1.48,
        "开盘价": 23.96,
        "最高价": 24.0,
        "最低价": 23.96,
        "昨收价": 24.36,
        "总市值": 1376880.0,
        "市盈率": null,
        "成交量": 1026.0,
        "成交额": 24603.0,
        "振幅": 0.16,
        "换手率": 1.79,
        "symbol": "105.OCFCP"
    },
    {
        "序号": 10489,
        "name": "The Allstate Corp Series H Pfd",
        "最新价": 21.29,
        "涨跌额": -0.32,
        "涨跌幅": -1.48,
        "开盘价": 21.5,
        "最高价": 21.67,
        "最低价": 21.26,
        "昨收价": 21.61,
        "总市值": 979340.0,
        "市盈率": null,
        "成交量": 97125.0,
        "成交额": 2077282.0,
        "振幅": 1.9,
        "换手率": 211.14,
        "symbol": "106.ALL_H"
    },
    {
        "序号": 10490,
        "name": "Cogent Biosciences Inc",
        "最新价": 8.64,
        "涨跌额": -0.13,
        "涨跌幅": -1.48,
        "开盘价": 8.98,
        "最高价": 9.55,
        "最低价": 8.58,
        "昨收价": 8.77,
        "总市值": 744113511.0,
        "市盈率": -4.19,
        "成交量": 1434999.0,
        "成交额": 12661126.0,
        "振幅": 11.06,
        "换手率": 1.67,
        "symbol": "105.COGT"
    },
    {
        "序号": 10491,
        "name": "小麦ETF-Teucrium",
        "最新价": 5.98,
        "涨跌额": -0.09,
        "涨跌幅": -1.48,
        "开盘价": 6.01,
        "最高价": 6.06,
        "最低价": 5.94,
        "昨收价": 6.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 1265803.0,
        "成交额": 7586052.0,
        "振幅": 1.98,
        "换手率": null,
        "symbol": "107.WEAT"
    },
    {
        "序号": 10492,
        "name": "KraneShares Hang Seng TECH Inde",
        "最新价": 11.28,
        "涨跌额": -0.17,
        "涨跌幅": -1.48,
        "开盘价": 11.37,
        "最高价": 11.37,
        "最低价": 11.25,
        "昨收价": 11.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 4230.0,
        "成交额": 47684.0,
        "振幅": 1.05,
        "换手率": null,
        "symbol": "107.KTEC"
    },
    {
        "序号": 10493,
        "name": "天狼星XM",
        "最新价": 4.64,
        "涨跌额": -0.07,
        "涨跌幅": -1.49,
        "开盘价": 4.63,
        "最高价": 4.68,
        "最低价": 4.57,
        "昨收价": 4.71,
        "总市值": 17812680769.0,
        "市盈率": 14.01,
        "成交量": 9489872.0,
        "成交额": 43817508.0,
        "振幅": 2.34,
        "换手率": 0.25,
        "symbol": "105.SIRI"
    },
    {
        "序号": 10494,
        "name": "生物技术ETF-SPDR S&P",
        "最新价": 79.32,
        "涨跌额": -1.2,
        "涨跌幅": -1.49,
        "开盘价": 80.21,
        "最高价": 81.13,
        "最低价": 79.22,
        "昨收价": 80.52,
        "总市值": null,
        "市盈率": null,
        "成交量": 9324833.0,
        "成交额": 744858720.0,
        "振幅": 2.37,
        "换手率": null,
        "symbol": "107.XBI"
    },
    {
        "序号": 10495,
        "name": "SSR Mining Inc",
        "最新价": 11.23,
        "涨跌额": -0.17,
        "涨跌幅": -1.49,
        "开盘价": 11.26,
        "最高价": 11.37,
        "最低价": 10.98,
        "昨收价": 11.4,
        "总市值": 2289702331.0,
        "市盈率": 10.71,
        "成交量": 2640780.0,
        "成交额": 29462872.0,
        "振幅": 3.42,
        "换手率": 1.3,
        "symbol": "105.SSRM"
    },
    {
        "序号": 10496,
        "name": "Hippo Holdings Inc",
        "最新价": 8.57,
        "涨跌额": -0.13,
        "涨跌幅": -1.49,
        "开盘价": 8.63,
        "最高价": 8.76,
        "最低价": 8.43,
        "昨收价": 8.7,
        "总市值": 204713647.0,
        "市盈率": -0.7,
        "成交量": 67433.0,
        "成交额": 576738.0,
        "振幅": 3.79,
        "换手率": 0.28,
        "symbol": "106.HIPO"
    },
    {
        "序号": 10497,
        "name": "Qurate Retail Inc Series A Pfd",
        "最新价": 32.95,
        "涨跌额": -0.5,
        "涨跌幅": -1.49,
        "开盘价": 33.39,
        "最高价": 33.63,
        "最低价": 32.68,
        "昨收价": 33.45,
        "总市值": 417580984.0,
        "市盈率": null,
        "成交量": 26766.0,
        "成交额": 884211.0,
        "振幅": 2.84,
        "换手率": 0.21,
        "symbol": "105.QRTEP"
    },
    {
        "序号": 10498,
        "name": "BOS科技",
        "最新价": 2.63,
        "涨跌额": -0.04,
        "涨跌幅": -1.5,
        "开盘价": 2.71,
        "最高价": 2.84,
        "最低价": 2.63,
        "昨收价": 2.67,
        "总市值": 15117287.0,
        "市盈率": 7.16,
        "成交量": 3712.0,
        "成交额": 10086.0,
        "振幅": 7.87,
        "换手率": 0.06,
        "symbol": "105.BOSC"
    },
    {
        "序号": 10499,
        "name": "Vapotherm Inc",
        "最新价": 1.97,
        "涨跌额": -0.03,
        "涨跌幅": -1.5,
        "开盘价": 2.03,
        "最高价": 2.05,
        "最低价": 1.86,
        "昨收价": 2.0,
        "总市值": 12092516.0,
        "市盈率": -0.17,
        "成交量": 33467.0,
        "成交额": 65679.0,
        "振幅": 9.5,
        "换手率": 0.55,
        "symbol": "106.VAPO"
    },
    {
        "序号": 10500,
        "name": "Modiv Inc Series A Pfd",
        "最新价": 22.97,
        "涨跌额": -0.35,
        "涨跌幅": -1.5,
        "开盘价": 22.97,
        "最高价": 22.97,
        "最低价": 22.97,
        "昨收价": 23.32,
        "总市值": 45940000.0,
        "市盈率": null,
        "成交量": 201.0,
        "成交额": 4616.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "106.MDV_A"
    },
    {
        "序号": 10501,
        "name": "普信集团",
        "最新价": 98.44,
        "涨跌额": -1.5,
        "涨跌幅": -1.5,
        "开盘价": 99.49,
        "最高价": 100.53,
        "最低价": 98.39,
        "昨收价": 99.94,
        "总市值": 21998418695.0,
        "市盈率": 13.6,
        "成交量": 1879199.0,
        "成交额": 185717239.0,
        "振幅": 2.14,
        "换手率": 0.84,
        "symbol": "105.TROW"
    },
    {
        "序号": 10502,
        "name": "The Real Brokerage Inc",
        "最新价": 1.31,
        "涨跌额": -0.02,
        "涨跌幅": -1.5,
        "开盘价": 1.33,
        "最高价": 1.34,
        "最低价": 1.28,
        "昨收价": 1.33,
        "总市值": 238158000.0,
        "市盈率": -10.66,
        "成交量": 160675.0,
        "成交额": 211388.0,
        "振幅": 4.51,
        "换手率": 0.09,
        "symbol": "105.REAX"
    },
    {
        "序号": 10503,
        "name": "梯瓦制药",
        "最新价": 9.82,
        "涨跌额": -0.15,
        "涨跌幅": -1.5,
        "开盘价": 9.93,
        "最高价": 9.98,
        "最低价": 9.8,
        "昨收价": 9.97,
        "总市值": 11007937204.0,
        "市盈率": -4.98,
        "成交量": 4402439.0,
        "成交额": 43395577.0,
        "振幅": 1.81,
        "换手率": 0.39,
        "symbol": "106.TEVA"
    },
    {
        "序号": 10504,
        "name": "Customers Bancorp Inc Notes",
        "最新价": 19.6,
        "涨跌额": -0.3,
        "涨跌幅": -1.51,
        "开盘价": 19.75,
        "最高价": 19.75,
        "最低价": 19.5,
        "昨收价": 19.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 1268.0,
        "成交额": 24769.0,
        "振幅": 1.26,
        "换手率": null,
        "symbol": "106.CUBB"
    },
    {
        "序号": 10505,
        "name": "SHL Telemedicine Ltd ADR",
        "最新价": 8.49,
        "涨跌额": -0.13,
        "涨跌幅": -1.51,
        "开盘价": 8.49,
        "最高价": 8.5,
        "最低价": 8.47,
        "昨收价": 8.62,
        "总市值": 139130316.0,
        "市盈率": -1830.66,
        "成交量": 1632.0,
        "成交额": 13856.0,
        "振幅": 0.35,
        "换手率": 0.01,
        "symbol": "105.SHLT"
    },
    {
        "序号": 10506,
        "name": "U.S. Global GO GOLD and Preciou",
        "最新价": 16.32,
        "涨跌额": -0.25,
        "涨跌幅": -1.51,
        "开盘价": 16.35,
        "最高价": 16.49,
        "最低价": 16.13,
        "昨收价": 16.57,
        "总市值": null,
        "市盈率": null,
        "成交量": 30062.0,
        "成交额": 489316.0,
        "振幅": 2.17,
        "换手率": null,
        "symbol": "107.GOAU"
    },
    {
        "序号": 10507,
        "name": "BellRing Brands Inc",
        "最新价": 53.44,
        "涨跌额": -0.82,
        "涨跌幅": -1.51,
        "开盘价": 53.94,
        "最高价": 54.11,
        "最低价": 52.98,
        "昨收价": 54.26,
        "总市值": 7005143442.0,
        "市盈率": null,
        "成交量": 1133238.0,
        "成交额": 60497015.0,
        "振幅": 2.08,
        "换手率": 0.86,
        "symbol": "106.BRBR"
    },
    {
        "序号": 10508,
        "name": "Knightscope Inc-A",
        "最新价": 0.65,
        "涨跌额": -0.01,
        "涨跌幅": -1.52,
        "开盘价": 0.65,
        "最高价": 0.66,
        "最低价": 0.62,
        "昨收价": 0.66,
        "总市值": 55077952.0,
        "市盈率": -2.02,
        "成交量": 1012993.0,
        "成交额": 645962.0,
        "振幅": 6.06,
        "换手率": 1.2,
        "symbol": "105.KSCP"
    },
    {
        "序号": 10509,
        "name": "Cyclo Therapeutics Inc",
        "最新价": 1.3,
        "涨跌额": -0.02,
        "涨跌幅": -1.52,
        "开盘价": 1.35,
        "最高价": 1.35,
        "最低价": 1.25,
        "昨收价": 1.32,
        "总市值": 29598843.0,
        "市盈率": -1.53,
        "成交量": 20578.0,
        "成交额": 26244.0,
        "振幅": 7.58,
        "换手率": 0.09,
        "symbol": "105.CYTH"
    },
    {
        "序号": 10510,
        "name": "Complete Solaria Inc",
        "最新价": 1.3,
        "涨跌额": -0.02,
        "涨跌幅": -1.52,
        "开盘价": 1.33,
        "最高价": 1.33,
        "最低价": 1.21,
        "昨收价": 1.32,
        "总市值": 56129550.0,
        "市盈率": -0.25,
        "成交量": 22150.0,
        "成交额": 27824.0,
        "振幅": 9.09,
        "换手率": 0.05,
        "symbol": "105.CSLR"
    },
    {
        "序号": 10511,
        "name": "Seneca Foods Corp-A",
        "最新价": 50.0,
        "涨跌额": -0.77,
        "涨跌幅": -1.52,
        "开盘价": 50.75,
        "最高价": 51.94,
        "最低价": 49.84,
        "昨收价": 50.77,
        "总市值": 357403500.0,
        "市盈率": 5.98,
        "成交量": 29879.0,
        "成交额": 1507609.0,
        "振幅": 4.14,
        "换手率": 0.55,
        "symbol": "105.SENEA"
    },
    {
        "序号": 10512,
        "name": "Realty Income Corp",
        "最新价": 53.86,
        "涨跌额": -0.83,
        "涨跌幅": -1.52,
        "开盘价": 54.3,
        "最高价": 54.57,
        "最低价": 53.56,
        "昨收价": 54.69,
        "总市值": 38991056209.0,
        "市盈率": 44.25,
        "成交量": 6647990.0,
        "成交额": 358265600.0,
        "振幅": 1.85,
        "换手率": 0.92,
        "symbol": "106.O"
    },
    {
        "序号": 10513,
        "name": "Altimmune Inc",
        "最新价": 6.48,
        "涨跌额": -0.1,
        "涨跌幅": -1.52,
        "开盘价": 6.51,
        "最高价": 7.41,
        "最低价": 6.37,
        "昨收价": 6.58,
        "总市值": 348163084.0,
        "市盈率": -4.44,
        "成交量": 28143018.0,
        "成交额": 194212659.0,
        "振幅": 15.81,
        "换手率": 52.38,
        "symbol": "105.ALT"
    },
    {
        "序号": 10514,
        "name": "二倍做空MSCI巴西ETF-ProShares",
        "最新价": 11.66,
        "涨跌额": -0.18,
        "涨跌幅": -1.52,
        "开盘价": 11.88,
        "最高价": 11.88,
        "最低价": 11.56,
        "昨收价": 11.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 10263.0,
        "成交额": 120312.0,
        "振幅": 2.7,
        "换手率": null,
        "symbol": "107.BZQ"
    },
    {
        "序号": 10515,
        "name": "SilverCrest Metals Inc",
        "最新价": 6.47,
        "涨跌额": -0.1,
        "涨跌幅": -1.52,
        "开盘价": 6.48,
        "最高价": 6.66,
        "最低价": 6.35,
        "昨收价": 6.57,
        "总市值": 943380115.0,
        "市盈率": 10.97,
        "成交量": 1622450.0,
        "成交额": 10502181.0,
        "振幅": 4.72,
        "换手率": 1.11,
        "symbol": "107.SILV"
    },
    {
        "序号": 10516,
        "name": "Superior Group of Companies Inc",
        "最新价": 12.91,
        "涨跌额": -0.2,
        "涨跌幅": -1.53,
        "开盘价": 13.01,
        "最高价": 13.33,
        "最低价": 12.71,
        "昨收价": 13.11,
        "总市值": 213090214.0,
        "市盈率": 28.76,
        "成交量": 33769.0,
        "成交额": 436995.0,
        "振幅": 4.73,
        "换手率": 0.2,
        "symbol": "105.SGC"
    },
    {
        "序号": 10517,
        "name": "Navient Corp Notes",
        "最新价": 19.36,
        "涨跌额": -0.3,
        "涨跌幅": -1.53,
        "开盘价": 19.54,
        "最高价": 19.67,
        "最低价": 19.34,
        "昨收价": 19.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 10675.0,
        "成交额": 207795.0,
        "振幅": 1.68,
        "换手率": null,
        "symbol": "105.JSM"
    },
    {
        "序号": 10518,
        "name": "KraneShares Global Carbon Offse",
        "最新价": 1.29,
        "涨跌额": -0.02,
        "涨跌幅": -1.53,
        "开盘价": 1.3,
        "最高价": 1.31,
        "最低价": 1.24,
        "昨收价": 1.31,
        "总市值": null,
        "市盈率": null,
        "成交量": 4123.0,
        "成交额": 5302.0,
        "振幅": 5.34,
        "换手率": null,
        "symbol": "107.KSET"
    },
    {
        "序号": 10519,
        "name": "标记临床研究(优先股)",
        "最新价": 22.53,
        "涨跌额": -0.35,
        "涨跌幅": -1.53,
        "开盘价": 22.82,
        "最高价": 23.06,
        "最低价": 22.53,
        "昨收价": 22.88,
        "总市值": 22169520.0,
        "市盈率": null,
        "成交量": 2987.0,
        "成交额": 68035.0,
        "振幅": 2.32,
        "换手率": 0.3,
        "symbol": "105.XOMAP"
    },
    {
        "序号": 10520,
        "name": "Grupo Aeroportuario del Sureste",
        "最新价": 231.9,
        "涨跌额": -3.62,
        "涨跌幅": -1.54,
        "开盘价": 235.15,
        "最高价": 238.24,
        "最低价": 231.01,
        "昨收价": 235.52,
        "总市值": 6957000000.0,
        "市盈率": 11.4,
        "成交量": 70854.0,
        "成交额": 16481091.0,
        "振幅": 3.07,
        "换手率": 0.24,
        "symbol": "106.ASR"
    },
    {
        "序号": 10521,
        "name": "Inhibrx Inc",
        "最新价": 24.97,
        "涨跌额": -0.39,
        "涨跌幅": -1.54,
        "开盘价": 25.08,
        "最高价": 25.57,
        "最低价": 23.83,
        "昨收价": 25.36,
        "总市值": 1180847930.0,
        "市盈率": -6.26,
        "成交量": 348372.0,
        "成交额": 8704724.0,
        "振幅": 6.86,
        "换手率": 0.74,
        "symbol": "105.INBX"
    },
    {
        "序号": 10522,
        "name": "Consolidated Water Co Ltd-A",
        "最新价": 35.85,
        "涨跌额": -0.56,
        "涨跌幅": -1.54,
        "开盘价": 36.21,
        "最高价": 36.57,
        "最低价": 35.61,
        "昨收价": 36.41,
        "总市值": 564546584.0,
        "市盈率": 26.54,
        "成交量": 140683.0,
        "成交额": 5065375.0,
        "振幅": 2.64,
        "换手率": 0.89,
        "symbol": "105.CWCO"
    },
    {
        "序号": 10523,
        "name": "Tempest Therapeutics Inc",
        "最新价": 3.2,
        "涨跌额": -0.05,
        "涨跌幅": -1.54,
        "开盘价": 3.28,
        "最高价": 3.3,
        "最低价": 3.14,
        "昨收价": 3.25,
        "总市值": 61591984.0,
        "市盈率": -1.98,
        "成交量": 636806.0,
        "成交额": 2037510.0,
        "振幅": 4.92,
        "换手率": 3.31,
        "symbol": "105.TPST"
    },
    {
        "序号": 10524,
        "name": "Lipocine Inc",
        "最新价": 2.56,
        "涨跌额": -0.04,
        "涨跌幅": -1.54,
        "开盘价": 2.55,
        "最高价": 2.57,
        "最低价": 2.5,
        "昨收价": 2.6,
        "总市值": 13608525.0,
        "市盈率": -0.83,
        "成交量": 6601.0,
        "成交额": 16726.0,
        "振幅": 2.69,
        "换手率": 0.12,
        "symbol": "105.LPCN"
    },
    {
        "序号": 10525,
        "name": "Galiano Gold Inc",
        "最新价": 0.64,
        "涨跌额": -0.01,
        "涨跌幅": -1.54,
        "开盘价": 0.66,
        "最高价": 0.66,
        "最低价": 0.64,
        "昨收价": 0.65,
        "总市值": 143963810.0,
        "市盈率": 2.39,
        "成交量": 129047.0,
        "成交额": 82704.0,
        "振幅": 3.08,
        "换手率": 0.06,
        "symbol": "107.GAU"
    },
    {
        "序号": 10526,
        "name": "DURECT Corp",
        "最新价": 0.64,
        "涨跌额": -0.01,
        "涨跌幅": -1.54,
        "开盘价": 0.65,
        "最高价": 0.66,
        "最低价": 0.63,
        "昨收价": 0.65,
        "总市值": 19090490.0,
        "市盈率": -0.52,
        "成交量": 182015.0,
        "成交额": 116823.0,
        "振幅": 4.62,
        "换手率": 0.61,
        "symbol": "105.DRRX"
    },
    {
        "序号": 10527,
        "name": "Creative Realities Inc",
        "最新价": 1.92,
        "涨跌额": -0.03,
        "涨跌幅": -1.54,
        "开盘价": 1.95,
        "最高价": 1.97,
        "最低价": 1.92,
        "昨收价": 1.95,
        "总市值": 19985332.0,
        "市盈率": -3.51,
        "成交量": 14087.0,
        "成交额": 27416.0,
        "振幅": 2.56,
        "换手率": 0.14,
        "symbol": "105.CREX"
    },
    {
        "序号": 10528,
        "name": "ASP Isotopes Inc",
        "最新价": 1.92,
        "涨跌额": -0.03,
        "涨跌幅": -1.54,
        "开盘价": 2.03,
        "最高价": 2.03,
        "最低价": 1.9,
        "昨收价": 1.95,
        "总市值": 93644690.0,
        "市盈率": -6.97,
        "成交量": 200435.0,
        "成交额": 392157.0,
        "振幅": 6.67,
        "换手率": 0.41,
        "symbol": "105.ASPI"
    },
    {
        "序号": 10529,
        "name": "Surgery Partners Inc",
        "最新价": 31.98,
        "涨跌额": -0.5,
        "涨跌幅": -1.54,
        "开盘价": 32.33,
        "最高价": 32.53,
        "最低价": 31.95,
        "昨收价": 32.48,
        "总市值": 4045255062.0,
        "市盈率": -117.94,
        "成交量": 787725.0,
        "成交额": 25294571.0,
        "振幅": 1.79,
        "换手率": 0.62,
        "symbol": "105.SGRY"
    },
    {
        "序号": 10530,
        "name": "Amylyx Pharmaceuticals Inc",
        "最新价": 14.0,
        "涨跌额": -0.22,
        "涨跌幅": -1.55,
        "开盘价": 14.23,
        "最高价": 14.39,
        "最低价": 13.6,
        "昨收价": 14.22,
        "总市值": 945248542.0,
        "市盈率": 514.84,
        "成交量": 1290400.0,
        "成交额": 17944216.0,
        "振幅": 5.56,
        "换手率": 1.91,
        "symbol": "105.AMLX"
    },
    {
        "序号": 10531,
        "name": "SCE Trust II Pfd",
        "最新价": 19.68,
        "涨跌额": -0.31,
        "涨跌幅": -1.55,
        "开盘价": 19.98,
        "最高价": 20.01,
        "最低价": 19.65,
        "昨收价": 19.99,
        "总市值": 314880000.0,
        "市盈率": null,
        "成交量": 7468.0,
        "成交额": 147748.0,
        "振幅": 1.8,
        "换手率": 0.05,
        "symbol": "106.SCE_G"
    },
    {
        "序号": 10532,
        "name": "雅诗兰黛",
        "最新价": 133.73,
        "涨跌额": -2.11,
        "涨跌幅": -1.55,
        "开盘价": 135.43,
        "最高价": 136.04,
        "最低价": 133.51,
        "昨收价": 135.84,
        "总市值": 47854824882.0,
        "市盈率": 87.33,
        "成交量": 1659882.0,
        "成交额": 222938486.0,
        "振幅": 1.86,
        "换手率": 0.46,
        "symbol": "106.EL"
    },
    {
        "序号": 10533,
        "name": "Galectin Therapeutics Inc",
        "最新价": 1.9,
        "涨跌额": -0.03,
        "涨跌幅": -1.55,
        "开盘价": 1.93,
        "最高价": 1.93,
        "最低价": 1.84,
        "昨收价": 1.93,
        "总市值": 117512448.0,
        "市盈率": -2.82,
        "成交量": 90954.0,
        "成交额": 170360.0,
        "振幅": 4.66,
        "换手率": 0.15,
        "symbol": "105.GALT"
    },
    {
        "序号": 10534,
        "name": "丰田汽车(US ADR)",
        "最新价": 187.28,
        "涨跌额": -2.96,
        "涨跌幅": -1.56,
        "开盘价": 186.18,
        "最高价": 187.59,
        "最低价": 185.6,
        "昨收价": 190.24,
        "总市值": 253087426717.0,
        "市盈率": 9.11,
        "成交量": 471299.0,
        "成交额": 88152824.0,
        "振幅": 1.05,
        "换手率": 0.03,
        "symbol": "106.TM"
    },
    {
        "序号": 10535,
        "name": "Sarepta Therapeutics Inc",
        "最新价": 87.03,
        "涨跌额": -1.38,
        "涨跌幅": -1.56,
        "开盘价": 88.13,
        "最高价": 89.65,
        "最低价": 87.01,
        "昨收价": 88.41,
        "总市值": 8141367647.0,
        "市盈率": -11.78,
        "成交量": 614084.0,
        "成交额": 53950706.0,
        "振幅": 2.99,
        "换手率": 0.66,
        "symbol": "105.SRPT"
    },
    {
        "序号": 10536,
        "name": "SR Bancorp Inc",
        "最新价": 9.45,
        "涨跌额": -0.15,
        "涨跌幅": -1.56,
        "开盘价": 9.7,
        "最高价": 9.7,
        "最低价": 9.45,
        "昨收价": 9.6,
        "总市值": 89849939.0,
        "市盈率": 11.77,
        "成交量": 14172.0,
        "成交额": 136025.0,
        "振幅": 2.6,
        "换手率": 0.15,
        "symbol": "105.SRBK"
    },
    {
        "序号": 10537,
        "name": "Confluent Inc-A",
        "最新价": 22.68,
        "涨跌额": -0.36,
        "涨跌幅": -1.56,
        "开盘价": 22.8,
        "最高价": 23.13,
        "最低价": 22.43,
        "昨收价": 23.04,
        "总市值": 6982606769.0,
        "市盈率": -15.36,
        "成交量": 3802451.0,
        "成交额": 86480256.0,
        "振幅": 3.04,
        "换手率": 1.24,
        "symbol": "105.CFLT"
    },
    {
        "序号": 10538,
        "name": "Groupon Inc-A",
        "最新价": 11.31,
        "涨跌额": -0.18,
        "涨跌幅": -1.57,
        "开盘价": 11.39,
        "最高价": 11.7,
        "最低价": 11.22,
        "昨收价": 11.49,
        "总市值": 360261705.0,
        "市盈率": -2.6,
        "成交量": 711497.0,
        "成交额": 8144509.0,
        "振幅": 4.18,
        "换手率": 2.23,
        "symbol": "105.GRPN"
    },
    {
        "序号": 10539,
        "name": "Chegg Inc",
        "最新价": 10.68,
        "涨跌额": -0.17,
        "涨跌幅": -1.57,
        "开盘价": 10.8,
        "最高价": 11.02,
        "最低价": 10.66,
        "昨收价": 10.85,
        "总市值": 1236903474.0,
        "市盈率": 119.24,
        "成交量": 1623161.0,
        "成交额": 17504847.0,
        "振幅": 3.32,
        "换手率": 1.4,
        "symbol": "106.CHGG"
    },
    {
        "序号": 10540,
        "name": "Whole Earth Brands Inc",
        "最新价": 3.14,
        "涨跌额": -0.05,
        "涨跌幅": -1.57,
        "开盘价": 3.2,
        "最高价": 3.21,
        "最低价": 3.05,
        "昨收价": 3.19,
        "总市值": 134551873.0,
        "市盈率": -1.48,
        "成交量": 73054.0,
        "成交额": 228686.0,
        "振幅": 5.02,
        "换手率": 0.17,
        "symbol": "105.FREE"
    },
    {
        "序号": 10541,
        "name": "The Herzfeld Caribbean Basin Fu",
        "最新价": 2.51,
        "涨跌额": -0.04,
        "涨跌幅": -1.57,
        "开盘价": 2.55,
        "最高价": 2.57,
        "最低价": 2.47,
        "昨收价": 2.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 178295.0,
        "成交额": 447955.0,
        "振幅": 3.92,
        "换手率": null,
        "symbol": "105.CUBA"
    },
    {
        "序号": 10542,
        "name": "ULTA美妆",
        "最新价": 482.16,
        "涨跌额": -7.69,
        "涨跌幅": -1.57,
        "开盘价": 489.38,
        "最高价": 489.38,
        "最低价": 481.98,
        "昨收价": 489.85,
        "总市值": 23414421037.0,
        "市盈率": 18.92,
        "成交量": 586135.0,
        "成交额": 283781440.0,
        "振幅": 1.51,
        "换手率": 1.21,
        "symbol": "105.ULTA"
    },
    {
        "序号": 10543,
        "name": "雾麻科技",
        "最新价": 11.27,
        "涨跌额": -0.18,
        "涨跌幅": -1.57,
        "开盘价": 11.39,
        "最高价": 11.72,
        "最低价": 11.07,
        "昨收价": 11.45,
        "总市值": 611611540.0,
        "市盈率": -111.78,
        "成交量": 81855.0,
        "成交额": 921314.0,
        "振幅": 5.68,
        "换手率": 0.15,
        "symbol": "105.ISPR"
    },
    {
        "序号": 10544,
        "name": "ProShares UltraShort Technology",
        "最新价": 8.76,
        "涨跌额": -0.14,
        "涨跌幅": -1.57,
        "开盘价": 8.85,
        "最高价": 8.9,
        "最低价": 8.75,
        "昨收价": 8.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 11432.0,
        "成交额": 100588.0,
        "振幅": 1.69,
        "换手率": null,
        "symbol": "107.REW"
    },
    {
        "序号": 10545,
        "name": "Liberty Broadband Corp Series A",
        "最新价": 21.9,
        "涨跌额": -0.35,
        "涨跌幅": -1.57,
        "开盘价": 22.5,
        "最高价": 22.5,
        "最低价": 21.85,
        "昨收价": 22.25,
        "总市值": 157325483.0,
        "市盈率": null,
        "成交量": 35233.0,
        "成交额": 774076.0,
        "振幅": 2.92,
        "换手率": 0.49,
        "symbol": "105.LBRDP"
    },
    {
        "序号": 10546,
        "name": "Defiance Pure Electric Vehicle ",
        "最新价": 22.51,
        "涨跌额": -0.36,
        "涨跌幅": -1.57,
        "开盘价": 22.34,
        "最高价": 22.65,
        "最低价": 22.34,
        "昨收价": 22.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 1651.0,
        "成交额": 37277.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.EVXX"
    },
    {
        "序号": 10547,
        "name": "Western Alliance Bancorp Series",
        "最新价": 16.25,
        "涨跌额": -0.26,
        "涨跌幅": -1.57,
        "开盘价": 16.49,
        "最高价": 16.52,
        "最低价": 16.11,
        "昨收价": 16.51,
        "总市值": 487500.0,
        "市盈率": null,
        "成交量": 90376.0,
        "成交额": 1477144.0,
        "振幅": 2.48,
        "换手率": 301.25,
        "symbol": "106.WAL_A"
    },
    {
        "序号": 10548,
        "name": "Office Properties Income Trust ",
        "最新价": 12.5,
        "涨跌额": -0.2,
        "涨跌幅": -1.57,
        "开盘价": 12.79,
        "最高价": 12.93,
        "最低价": 12.31,
        "昨收价": 12.7,
        "总市值": null,
        "市盈率": null,
        "成交量": 66224.0,
        "成交额": 831538.0,
        "振幅": 4.88,
        "换手率": null,
        "symbol": "105.OPINL"
    },
    {
        "序号": 10549,
        "name": "The Scotts Miracle-Gro Co",
        "最新价": 60.6,
        "涨跌额": -0.97,
        "涨跌幅": -1.58,
        "开盘价": 61.57,
        "最高价": 61.81,
        "最低价": 59.95,
        "昨收价": 61.57,
        "总市值": 3427106710.0,
        "市盈率": -9.02,
        "成交量": 597111.0,
        "成交额": 36289956.0,
        "振幅": 3.02,
        "换手率": 1.06,
        "symbol": "106.SMG"
    },
    {
        "序号": 10550,
        "name": "Brunswick Corp Notes",
        "最新价": 24.36,
        "涨跌额": -0.39,
        "涨跌幅": -1.58,
        "开盘价": 24.66,
        "最高价": 24.84,
        "最低价": 24.36,
        "昨收价": 24.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 7291.0,
        "成交额": 179507.0,
        "振幅": 1.94,
        "换手率": null,
        "symbol": "106.BC_A"
    },
    {
        "序号": 10551,
        "name": "尚乘数科",
        "最新价": 4.37,
        "涨跌额": -0.07,
        "涨跌幅": -1.58,
        "开盘价": 4.44,
        "最高价": 4.5,
        "最低价": 4.18,
        "昨收价": 4.44,
        "总市值": 836616201.0,
        "市盈率": 20.04,
        "成交量": 194776.0,
        "成交额": 842663.0,
        "振幅": 7.21,
        "换手率": 0.1,
        "symbol": "106.HKD"
    },
    {
        "序号": 10552,
        "name": "Ionis Pharmaceuticals Inc",
        "最新价": 48.69,
        "涨跌额": -0.78,
        "涨跌幅": -1.58,
        "开盘价": 49.33,
        "最高价": 49.46,
        "最低价": 48.51,
        "昨收价": 49.47,
        "总市值": 6985657474.0,
        "市盈率": -17.06,
        "成交量": 742018.0,
        "成交额": 36231924.0,
        "振幅": 1.92,
        "换手率": 0.52,
        "symbol": "105.IONS"
    },
    {
        "序号": 10553,
        "name": "Duluth Holdings Inc-B",
        "最新价": 4.99,
        "涨跌额": -0.08,
        "涨跌幅": -1.58,
        "开盘价": 5.05,
        "最高价": 5.1,
        "最低价": 4.93,
        "昨收价": 5.07,
        "总市值": 172031552.0,
        "市盈率": -19.35,
        "成交量": 65815.0,
        "成交额": 329255.0,
        "振幅": 3.35,
        "换手率": 0.19,
        "symbol": "105.DLTH"
    },
    {
        "序号": 10554,
        "name": "LiveRamp Holdings Inc",
        "最新价": 34.3,
        "涨跌额": -0.55,
        "涨跌幅": -1.58,
        "开盘价": 34.72,
        "最高价": 34.9,
        "最低价": 34.05,
        "昨收价": 34.85,
        "总市值": 2247134762.0,
        "市盈率": -38.9,
        "成交量": 450830.0,
        "成交额": 15490764.0,
        "振幅": 2.44,
        "换手率": 0.69,
        "symbol": "106.RAMP"
    },
    {
        "序号": 10555,
        "name": "阿联酋ETF-iShares MSCI",
        "最新价": 14.33,
        "涨跌额": -0.23,
        "涨跌幅": -1.58,
        "开盘价": 14.48,
        "最高价": 14.48,
        "最低价": 14.25,
        "昨收价": 14.56,
        "总市值": null,
        "市盈率": null,
        "成交量": 58018.0,
        "成交额": 827775.0,
        "振幅": 1.58,
        "换手率": null,
        "symbol": "105.UAE"
    },
    {
        "序号": 10556,
        "name": "The Gabelli Global Utility and ",
        "最新价": 13.08,
        "涨跌额": -0.21,
        "涨跌幅": -1.58,
        "开盘价": 13.04,
        "最高价": 13.35,
        "最低价": 12.92,
        "昨收价": 13.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 29527.0,
        "成交额": 384013.0,
        "振幅": 3.24,
        "换手率": null,
        "symbol": "107.GLU"
    },
    {
        "序号": 10557,
        "name": "Alpine Income Property Trust In",
        "最新价": 16.75,
        "涨跌额": -0.27,
        "涨跌幅": -1.59,
        "开盘价": 16.93,
        "最高价": 17.03,
        "最低价": 16.63,
        "昨收价": 17.02,
        "总市值": 229474347.0,
        "市盈率": 30.83,
        "成交量": 80996.0,
        "成交额": 1357720.0,
        "振幅": 2.35,
        "换手率": 0.59,
        "symbol": "106.PINE"
    },
    {
        "序号": 10558,
        "name": "Reading International Inc-A",
        "最新价": 1.86,
        "涨跌额": -0.03,
        "涨跌幅": -1.59,
        "开盘价": 1.91,
        "最高价": 1.94,
        "最低价": 1.83,
        "昨收价": 1.89,
        "总市值": 41428569.0,
        "市盈率": -1.31,
        "成交量": 41025.0,
        "成交额": 76027.0,
        "振幅": 5.82,
        "换手率": 0.2,
        "symbol": "105.RDI"
    },
    {
        "序号": 10559,
        "name": "Eightco Holdings Inc",
        "最新价": 0.62,
        "涨跌额": -0.01,
        "涨跌幅": -1.59,
        "开盘价": 0.63,
        "最高价": 0.65,
        "最低价": 0.62,
        "昨收价": 0.63,
        "总市值": 2688143.0,
        "市盈率": -0.04,
        "成交量": 32551.0,
        "成交额": 20498.0,
        "振幅": 4.76,
        "换手率": 0.75,
        "symbol": "105.OCTO"
    },
    {
        "序号": 10560,
        "name": "箩筐技术",
        "最新价": 0.62,
        "涨跌额": -0.01,
        "涨跌幅": -1.59,
        "开盘价": 0.62,
        "最高价": 0.68,
        "最低价": 0.6,
        "昨收价": 0.63,
        "总市值": 10178306.0,
        "市盈率": -0.19,
        "成交量": 95870.0,
        "成交额": 62131.0,
        "振幅": 12.7,
        "换手率": 0.58,
        "symbol": "105.LKCO"
    },
    {
        "序号": 10561,
        "name": "Post Holdings Inc",
        "最新价": 86.66,
        "涨跌额": -1.4,
        "涨跌幅": -1.59,
        "开盘价": 88.0,
        "最高价": 88.1,
        "最低价": 86.46,
        "昨收价": 88.06,
        "总市值": 5230308318.0,
        "市盈率": 17.36,
        "成交量": 538519.0,
        "成交额": 46749604.0,
        "振幅": 1.86,
        "换手率": 0.89,
        "symbol": "106.POST"
    },
    {
        "序号": 10562,
        "name": "Athene Holding Ltd Series B Pfd",
        "最新价": 20.41,
        "涨跌额": -0.33,
        "涨跌幅": -1.59,
        "开盘价": 20.85,
        "最高价": 20.85,
        "最低价": 20.41,
        "昨收价": 20.74,
        "总市值": 244920.0,
        "市盈率": null,
        "成交量": 24893.0,
        "成交额": 512645.0,
        "振幅": 2.12,
        "换手率": 207.44,
        "symbol": "106.ATH_B"
    },
    {
        "序号": 10563,
        "name": "ADMA Biologics Inc",
        "最新价": 3.71,
        "涨跌额": -0.06,
        "涨跌幅": -1.59,
        "开盘价": 3.75,
        "最高价": 3.77,
        "最低价": 3.7,
        "昨收价": 3.77,
        "总市值": 838340048.0,
        "市盈率": -36.73,
        "成交量": 1100202.0,
        "成交额": 4101271.0,
        "振幅": 1.86,
        "换手率": 0.49,
        "symbol": "105.ADMA"
    },
    {
        "序号": 10564,
        "name": "公民保险",
        "最新价": 3.09,
        "涨跌额": -0.05,
        "涨跌幅": -1.59,
        "开盘价": 3.06,
        "最高价": 3.15,
        "最低价": 3.06,
        "昨收价": 3.14,
        "总市值": 153117376.0,
        "市盈率": -28.94,
        "成交量": 40945.0,
        "成交额": 127272.0,
        "振幅": 2.87,
        "换手率": 0.08,
        "symbol": "106.CIA"
    },
    {
        "序号": 10565,
        "name": "Barnwell Industries Inc",
        "最新价": 2.47,
        "涨跌额": -0.04,
        "涨跌幅": -1.59,
        "开盘价": 2.51,
        "最高价": 2.51,
        "最低价": 2.47,
        "昨收价": 2.51,
        "总市值": 24677222.0,
        "市盈率": -24.48,
        "成交量": 1964.0,
        "成交额": 4901.0,
        "振幅": 1.59,
        "换手率": 0.02,
        "symbol": "107.BRN"
    },
    {
        "序号": 10566,
        "name": "Murphy USA Inc",
        "最新价": 354.06,
        "涨跌额": -5.74,
        "涨跌幅": -1.6,
        "开盘价": 359.37,
        "最高价": 359.37,
        "最低价": 353.82,
        "昨收价": 359.8,
        "总市值": 7533345596.0,
        "市盈率": 14.36,
        "成交量": 195424.0,
        "成交额": 69424369.0,
        "振幅": 1.54,
        "换手率": 0.92,
        "symbol": "106.MUSA"
    },
    {
        "序号": 10567,
        "name": "Arbe Robotics Ltd",
        "最新价": 1.85,
        "涨跌额": -0.03,
        "涨跌幅": -1.6,
        "开盘价": 1.9,
        "最高价": 1.9,
        "最低价": 1.84,
        "昨收价": 1.88,
        "总市值": 143382252.0,
        "市盈率": -3.16,
        "成交量": 54634.0,
        "成交额": 101382.0,
        "振幅": 3.19,
        "换手率": 0.07,
        "symbol": "105.ARBE"
    },
    {
        "序号": 10568,
        "name": "Western Asset High Yield Define",
        "最新价": 11.71,
        "涨跌额": -0.19,
        "涨跌幅": -1.6,
        "开盘价": 11.88,
        "最高价": 11.95,
        "最低价": 11.63,
        "昨收价": 11.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 106585.0,
        "成交额": 1254150.0,
        "振幅": 2.69,
        "换手率": null,
        "symbol": "106.HYI"
    },
    {
        "序号": 10569,
        "name": "起司工坊",
        "最新价": 33.27,
        "涨跌额": -0.54,
        "涨跌幅": -1.6,
        "开盘价": 33.68,
        "最高价": 33.91,
        "最低价": 33.16,
        "昨收价": 33.81,
        "总市值": 1689221802.0,
        "市盈率": 19.79,
        "成交量": 661507.0,
        "成交额": 22085046.0,
        "振幅": 2.22,
        "换手率": 1.3,
        "symbol": "105.CAKE"
    },
    {
        "序号": 10570,
        "name": "东方文化",
        "最新价": 1.23,
        "涨跌额": -0.02,
        "涨跌幅": -1.6,
        "开盘价": 1.22,
        "最高价": 1.24,
        "最低价": 1.22,
        "昨收价": 1.25,
        "总市值": 7585904.0,
        "市盈率": -1.68,
        "成交量": 4748.0,
        "成交额": 5805.0,
        "振幅": 1.6,
        "换手率": 0.08,
        "symbol": "105.OCG"
    },
    {
        "序号": 10571,
        "name": "Sendas Distribuidora SA ADR",
        "最新价": 12.27,
        "涨跌额": -0.2,
        "涨跌幅": -1.6,
        "开盘价": 12.2,
        "最高价": 12.47,
        "最低价": 12.17,
        "昨收价": 12.47,
        "总市值": 3316491532.0,
        "市盈率": 20.08,
        "成交量": 686586.0,
        "成交额": 8454858.0,
        "振幅": 2.41,
        "换手率": 0.25,
        "symbol": "106.ASAI"
    },
    {
        "序号": 10572,
        "name": "南非ETF-iShares MSCI",
        "最新价": 39.22,
        "涨跌额": -0.64,
        "涨跌幅": -1.61,
        "开盘价": 39.28,
        "最高价": 39.5,
        "最低价": 38.96,
        "昨收价": 39.86,
        "总市值": null,
        "市盈率": null,
        "成交量": 297270.0,
        "成交额": 11648552.0,
        "振幅": 1.35,
        "换手率": null,
        "symbol": "107.EZA"
    },
    {
        "序号": 10573,
        "name": "Repligen Corp",
        "最新价": 159.27,
        "涨跌额": -2.6,
        "涨跌幅": -1.61,
        "开盘价": 160.48,
        "最高价": 164.71,
        "最低价": 158.33,
        "昨收价": 161.87,
        "总市值": 8892364711.0,
        "市盈率": 76.79,
        "成交量": 472497.0,
        "成交额": 75862066.0,
        "振幅": 3.94,
        "换手率": 0.85,
        "symbol": "105.RGEN"
    },
    {
        "序号": 10574,
        "name": "霍尼韦尔(US)",
        "最新价": 194.61,
        "涨跌额": -3.18,
        "涨跌幅": -1.61,
        "开盘价": 196.04,
        "最高价": 197.23,
        "最低价": 193.01,
        "昨收价": 197.79,
        "总市值": 128296767829.0,
        "市盈率": 23.7,
        "成交量": 4545331.0,
        "成交额": 886056480.0,
        "振幅": 2.13,
        "换手率": 0.69,
        "symbol": "105.HON"
    },
    {
        "序号": 10575,
        "name": "如新集团",
        "最新价": 17.74,
        "涨跌额": -0.29,
        "涨跌幅": -1.61,
        "开盘价": 18.0,
        "最高价": 18.0,
        "最低价": 17.6,
        "昨收价": 18.03,
        "总市值": 876520414.0,
        "市盈率": 14.98,
        "成交量": 391182.0,
        "成交额": 6943142.0,
        "振幅": 2.22,
        "换手率": 0.79,
        "symbol": "106.NUS"
    },
    {
        "序号": 10576,
        "name": "Sotherly Hotels Inc Series B Pf",
        "最新价": 20.76,
        "涨跌额": -0.34,
        "涨跌幅": -1.61,
        "开盘价": 20.76,
        "最高价": 20.76,
        "最低价": 20.76,
        "昨收价": 21.1,
        "总市值": 30394716.0,
        "市盈率": null,
        "成交量": 123.0,
        "成交额": 2553.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.SOHOB"
    },
    {
        "序号": 10577,
        "name": "CSX运输",
        "最新价": 32.96,
        "涨跌额": -0.54,
        "涨跌幅": -1.61,
        "开盘价": 33.45,
        "最高价": 33.49,
        "最低价": 32.93,
        "昨收价": 33.5,
        "总市值": 65133278914.0,
        "市盈率": 16.93,
        "成交量": 15756848.0,
        "成交额": 521557344.0,
        "振幅": 1.67,
        "换手率": 0.8,
        "symbol": "105.CSX"
    },
    {
        "序号": 10578,
        "name": "祖睿",
        "最新价": 8.54,
        "涨跌额": -0.14,
        "涨跌幅": -1.61,
        "开盘价": 8.62,
        "最高价": 8.76,
        "最低价": 8.49,
        "昨收价": 8.68,
        "总市值": 1222022760.0,
        "市盈率": -7.87,
        "成交量": 779965.0,
        "成交额": 6700240.0,
        "振幅": 3.11,
        "换手率": 0.55,
        "symbol": "106.ZUO"
    },
    {
        "序号": 10579,
        "name": "德康医疗",
        "最新价": 116.97,
        "涨跌额": -1.92,
        "涨跌幅": -1.61,
        "开盘价": 118.48,
        "最高价": 119.39,
        "最低价": 116.92,
        "昨收价": 118.89,
        "总市值": 45194159177.0,
        "市盈率": 119.88,
        "成交量": 2375826.0,
        "成交额": 279607520.0,
        "振幅": 2.08,
        "换手率": 0.61,
        "symbol": "105.DXCM"
    },
    {
        "序号": 10580,
        "name": "Evoke Pharma Inc",
        "最新价": 1.21,
        "涨跌额": -0.02,
        "涨跌幅": -1.63,
        "开盘价": 1.23,
        "最高价": 1.24,
        "最低价": 1.2,
        "昨收价": 1.23,
        "总市值": 4045115.0,
        "市盈率": -0.53,
        "成交量": 10062.0,
        "成交额": 12311.0,
        "振幅": 3.25,
        "换手率": 0.3,
        "symbol": "105.EVOK"
    },
    {
        "序号": 10581,
        "name": "Smartsheet Inc-A",
        "最新价": 44.16,
        "涨跌额": -0.73,
        "涨跌幅": -1.63,
        "开盘价": 45.38,
        "最高价": 47.1,
        "最低价": 42.76,
        "昨收价": 44.89,
        "总市值": 6017097638.0,
        "市盈率": -43.48,
        "成交量": 4096798.0,
        "成交额": 181145413.0,
        "振幅": 9.67,
        "换手率": 3.01,
        "symbol": "106.SMAR"
    },
    {
        "序号": 10582,
        "name": "PROCEPT BioRobotics Corp",
        "最新价": 39.32,
        "涨跌额": -0.65,
        "涨跌幅": -1.63,
        "开盘价": 39.75,
        "最高价": 40.05,
        "最低价": 38.82,
        "昨收价": 39.97,
        "总市值": 1985605778.0,
        "市盈率": -18.63,
        "成交量": 417697.0,
        "成交额": 16481201.0,
        "振幅": 3.08,
        "换手率": 0.83,
        "symbol": "105.PRCT"
    },
    {
        "序号": 10583,
        "name": "Strategy Shares Gold-Hedged Bon",
        "最新价": 19.89,
        "涨跌额": -0.33,
        "涨跌幅": -1.63,
        "开盘价": 19.89,
        "最高价": 19.89,
        "最低价": 19.89,
        "昨收价": 20.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 108.0,
        "成交额": 2147.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.GLDB"
    },
    {
        "序号": 10584,
        "name": "West Fraser Timber Co Ltd",
        "最新价": 75.31,
        "涨跌额": -1.25,
        "涨跌幅": -1.63,
        "开盘价": 76.36,
        "最高价": 76.64,
        "最低价": 74.56,
        "昨收价": 76.56,
        "总市值": 6292587072.0,
        "市盈率": -58.81,
        "成交量": 119229.0,
        "成交额": 8984276.0,
        "振幅": 2.72,
        "换手率": 0.14,
        "symbol": "106.WFG"
    },
    {
        "序号": 10585,
        "name": "First Trust Alerian Disruptive ",
        "最新价": 40.96,
        "涨跌额": -0.68,
        "涨跌幅": -1.63,
        "开盘价": 40.65,
        "最高价": 41.56,
        "最低价": 40.65,
        "昨收价": 41.64,
        "总市值": null,
        "市盈率": null,
        "成交量": 1817.0,
        "成交额": 74691.0,
        "振幅": 2.19,
        "换手率": null,
        "symbol": "107.DTRE"
    },
    {
        "序号": 10586,
        "name": "纽蒙特",
        "最新价": 39.08,
        "涨跌额": -0.65,
        "涨跌幅": -1.64,
        "开盘价": 39.02,
        "最高价": 39.57,
        "最低价": 38.73,
        "昨收价": 39.73,
        "总市值": 31060791542.0,
        "市盈率": -38.21,
        "成交量": 9035524.0,
        "成交额": 353573408.0,
        "振幅": 2.11,
        "换手率": 1.14,
        "symbol": "106.NEM"
    },
    {
        "序号": 10587,
        "name": "ZeroFox Holdings Inc",
        "最新价": 0.6,
        "涨跌额": -0.01,
        "涨跌幅": -1.64,
        "开盘价": 0.62,
        "最高价": 0.65,
        "最低价": 0.59,
        "昨收价": 0.61,
        "总市值": 74734036.0,
        "市盈率": 8.95,
        "成交量": 200916.0,
        "成交额": 121330.0,
        "振幅": 9.84,
        "换手率": 0.16,
        "symbol": "105.ZFOX"
    },
    {
        "序号": 10588,
        "name": "Steakholder Foods Ltd ADR",
        "最新价": 0.6,
        "涨跌额": -0.01,
        "涨跌幅": -1.64,
        "开盘价": 0.61,
        "最高价": 0.64,
        "最低价": 0.55,
        "昨收价": 0.61,
        "总市值": 15036511.0,
        "市盈率": -0.47,
        "成交量": 100655.0,
        "成交额": 59660.0,
        "振幅": 14.75,
        "换手率": 0.4,
        "symbol": "105.STKH"
    },
    {
        "序号": 10589,
        "name": "Super Group (SGHC) Ltd",
        "最新价": 3.0,
        "涨跌额": -0.05,
        "涨跌幅": -1.64,
        "开盘价": 3.02,
        "最高价": 3.04,
        "最低价": 2.95,
        "昨收价": 3.05,
        "总市值": 1495534929.0,
        "市盈率": 24.11,
        "成交量": 416639.0,
        "成交额": 1251709.0,
        "振幅": 2.95,
        "换手率": 0.08,
        "symbol": "106.SGHC"
    },
    {
        "序号": 10590,
        "name": "沙尘暴黄金",
        "最新价": 4.8,
        "涨跌额": -0.08,
        "涨跌幅": -1.64,
        "开盘价": 4.84,
        "最高价": 4.89,
        "最低价": 4.72,
        "昨收价": 4.88,
        "总市值": 1421675621.0,
        "市盈率": 94.03,
        "成交量": 1924955.0,
        "成交额": 9235825.0,
        "振幅": 3.48,
        "换手率": 0.65,
        "symbol": "106.SAND"
    },
    {
        "序号": 10591,
        "name": "Pluri Inc",
        "最新价": 0.6,
        "涨跌额": -0.01,
        "涨跌幅": -1.64,
        "开盘价": 0.63,
        "最高价": 0.63,
        "最低价": 0.58,
        "昨收价": 0.61,
        "总市值": 24888103.0,
        "市盈率": -0.92,
        "成交量": 26824.0,
        "成交额": 16147.0,
        "振幅": 8.2,
        "换手率": 0.06,
        "symbol": "105.PLUR"
    },
    {
        "序号": 10592,
        "name": "Forza X1 Inc",
        "最新价": 0.6,
        "涨跌额": -0.01,
        "涨跌幅": -1.64,
        "开盘价": 0.61,
        "最高价": 0.61,
        "最低价": 0.58,
        "昨收价": 0.61,
        "总市值": 9457650.0,
        "市盈率": -1.59,
        "成交量": 32990.0,
        "成交额": 19708.0,
        "振幅": 4.92,
        "换手率": 0.21,
        "symbol": "105.FRZA"
    },
    {
        "序号": 10593,
        "name": "Standard Motor Products Inc",
        "最新价": 36.58,
        "涨跌额": -0.61,
        "涨跌幅": -1.64,
        "开盘价": 37.07,
        "最高价": 37.25,
        "最低价": 36.54,
        "昨收价": 37.19,
        "总市值": 794857501.0,
        "市盈率": 22.3,
        "成交量": 89101.0,
        "成交额": 3272542.0,
        "振幅": 1.91,
        "换手率": 0.41,
        "symbol": "106.SMP"
    },
    {
        "序号": 10594,
        "name": "Regions Financial Corp Series E",
        "最新价": 16.16,
        "涨跌额": -0.27,
        "涨跌幅": -1.64,
        "开盘价": 16.35,
        "最高价": 16.62,
        "最低价": 16.13,
        "昨收价": 16.43,
        "总市值": 6464000.0,
        "市盈率": null,
        "成交量": 40698.0,
        "成交额": 664166.0,
        "振幅": 2.98,
        "换手率": 10.17,
        "symbol": "106.RF_E"
    },
    {
        "序号": 10595,
        "name": "矽晶电信",
        "最新价": 17.34,
        "涨跌额": -0.29,
        "涨跌幅": -1.64,
        "开盘价": 17.55,
        "最高价": 17.82,
        "最低价": 17.34,
        "昨收价": 17.63,
        "总市值": 117154051.0,
        "市盈率": 8.26,
        "成交量": 12858.0,
        "成交额": 225257.0,
        "振幅": 2.72,
        "换手率": 0.19,
        "symbol": "105.SILC"
    },
    {
        "序号": 10596,
        "name": "Cohen & Steers Select Preferred",
        "最新价": 19.09,
        "涨跌额": -0.32,
        "涨跌幅": -1.65,
        "开盘价": 19.34,
        "最高价": 19.5,
        "最低价": 19.02,
        "昨收价": 19.41,
        "总市值": null,
        "市盈率": null,
        "成交量": 30114.0,
        "成交额": 579405.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "106.PSF"
    },
    {
        "序号": 10597,
        "name": "日日煮",
        "最新价": 5.96,
        "涨跌额": -0.1,
        "涨跌幅": -1.65,
        "开盘价": 5.82,
        "最高价": 6.15,
        "最低价": 5.82,
        "昨收价": 6.06,
        "总市值": 118741485.0,
        "市盈率": -16.55,
        "成交量": 194327.0,
        "成交额": 1165297.0,
        "振幅": 5.45,
        "换手率": 0.98,
        "symbol": "107.DDC"
    },
    {
        "序号": 10598,
        "name": "iShares MSCI China Small-Cap ET",
        "最新价": 25.59,
        "涨跌额": -0.43,
        "涨跌幅": -1.65,
        "开盘价": 25.59,
        "最高价": 25.7,
        "最低价": 25.58,
        "昨收价": 26.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 3614.0,
        "成交额": 92621.0,
        "振幅": 0.46,
        "换手率": null,
        "symbol": "107.ECNS"
    },
    {
        "序号": 10599,
        "name": "Arch Resources Inc-A",
        "最新价": 164.1,
        "涨跌额": -2.76,
        "涨跌幅": -1.65,
        "开盘价": 166.97,
        "最高价": 168.77,
        "最低价": 163.55,
        "昨收价": 166.86,
        "总市值": 3014428386.0,
        "市盈率": 3.68,
        "成交量": 295913.0,
        "成交额": 48783906.0,
        "振幅": 3.13,
        "换手率": 1.61,
        "symbol": "106.ARCH"
    },
    {
        "序号": 10600,
        "name": "Luminar Technologies Inc-A",
        "最新价": 2.37,
        "涨跌额": -0.04,
        "涨跌幅": -1.66,
        "开盘价": 2.42,
        "最高价": 2.46,
        "最低价": 2.33,
        "昨收价": 2.41,
        "总市值": 973044475.0,
        "市盈率": -1.71,
        "成交量": 8895157.0,
        "成交额": 21132027.0,
        "振幅": 5.39,
        "换手率": 2.17,
        "symbol": "105.LAZR"
    },
    {
        "序号": 10601,
        "name": "Invesco DB Precious Metals Fund",
        "最新价": 50.85,
        "涨跌额": -0.86,
        "涨跌幅": -1.66,
        "开盘价": 51.04,
        "最高价": 51.25,
        "最低价": 50.84,
        "昨收价": 51.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 3938.0,
        "成交额": 200373.0,
        "振幅": 0.79,
        "换手率": null,
        "symbol": "107.DBP"
    },
    {
        "序号": 10602,
        "name": "Spirit Realty Capital Inc",
        "最新价": 41.31,
        "涨跌额": -0.7,
        "涨跌幅": -1.67,
        "开盘价": 41.86,
        "最高价": 41.87,
        "最低价": 41.08,
        "昨收价": 42.01,
        "总市值": 5838392616.0,
        "市盈率": 22.55,
        "成交量": 1159340.0,
        "成交额": 47917590.0,
        "振幅": 1.88,
        "换手率": 0.82,
        "symbol": "106.SRC"
    },
    {
        "序号": 10603,
        "name": "大猩猩科技",
        "最新价": 0.59,
        "涨跌额": -0.01,
        "涨跌幅": -1.67,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.58,
        "昨收价": 0.6,
        "总市值": 40739554.0,
        "市盈率": -0.47,
        "成交量": 289109.0,
        "成交额": 171483.0,
        "振幅": 3.33,
        "换手率": 0.42,
        "symbol": "105.GRRR"
    },
    {
        "序号": 10604,
        "name": "Cheniere Energy Partners LP",
        "最新价": 58.31,
        "涨跌额": -0.99,
        "涨跌幅": -1.67,
        "开盘价": 59.5,
        "最高价": 59.99,
        "最低价": 58.12,
        "昨收价": 59.3,
        "总市值": 28224321262.0,
        "市盈率": 4.82,
        "成交量": 256999.0,
        "成交额": 15123572.0,
        "振幅": 3.15,
        "换手率": 0.05,
        "symbol": "107.CQP"
    },
    {
        "序号": 10605,
        "name": "满帮",
        "最新价": 7.06,
        "涨跌额": -0.12,
        "涨跌幅": -1.67,
        "开盘价": 7.12,
        "最高价": 7.17,
        "最低价": 7.0,
        "昨收价": 7.18,
        "总市值": 7557168940.0,
        "市盈率": 29.6,
        "成交量": 7579483.0,
        "成交额": 53552453.0,
        "振幅": 2.37,
        "换手率": 0.71,
        "symbol": "106.YMM"
    },
    {
        "序号": 10606,
        "name": "Ohmyhome Ltd",
        "最新价": 1.76,
        "涨跌额": -0.03,
        "涨跌幅": -1.68,
        "开盘价": 1.74,
        "最高价": 1.78,
        "最低价": 1.71,
        "昨收价": 1.79,
        "总市值": 33528000.0,
        "市盈率": -9.74,
        "成交量": 8196.0,
        "成交额": 14254.0,
        "振幅": 3.91,
        "换手率": 0.04,
        "symbol": "105.OMH"
    },
    {
        "序号": 10607,
        "name": "Niagara Mohawk Power Corp Pfd",
        "最新价": 82.61,
        "涨跌额": -1.41,
        "涨跌幅": -1.68,
        "开盘价": 82.61,
        "最高价": 82.61,
        "最低价": 82.61,
        "昨收价": 84.02,
        "总市值": 11329053.0,
        "市盈率": null,
        "成交量": 123.0,
        "成交额": 10161.0,
        "振幅": 0.0,
        "换手率": 0.09,
        "symbol": "106.NMK_B"
    },
    {
        "序号": 10608,
        "name": "BRC Inc-A",
        "最新价": 4.1,
        "涨跌额": -0.07,
        "涨跌幅": -1.68,
        "开盘价": 4.17,
        "最高价": 4.24,
        "最低价": 4.07,
        "昨收价": 4.17,
        "总市值": 869371409.0,
        "市盈率": -49.83,
        "成交量": 466616.0,
        "成交额": 1918625.0,
        "振幅": 4.08,
        "换手率": 0.22,
        "symbol": "106.BRCC"
    },
    {
        "序号": 10609,
        "name": "American Oncology Network Inc-A",
        "最新价": 5.84,
        "涨跌额": -0.1,
        "涨跌幅": -1.68,
        "开盘价": 6.49,
        "最高价": 6.51,
        "最低价": 5.7,
        "昨收价": 5.94,
        "总市值": 202412356.0,
        "市盈率": -10.34,
        "成交量": 14110.0,
        "成交额": 87341.0,
        "振幅": 13.64,
        "换手率": 0.04,
        "symbol": "105.AONC"
    },
    {
        "序号": 10610,
        "name": "李尔",
        "最新价": 133.08,
        "涨跌额": -2.29,
        "涨跌幅": -1.69,
        "开盘价": 135.28,
        "最高价": 136.7,
        "最低价": 132.96,
        "昨收价": 135.37,
        "总市值": 7753223633.0,
        "市盈率": 13.78,
        "成交量": 688150.0,
        "成交额": 92238854.0,
        "振幅": 2.76,
        "换手率": 1.18,
        "symbol": "106.LEA"
    },
    {
        "序号": 10611,
        "name": "Flaherty & Crumrine Dynamic Pre",
        "最新价": 17.43,
        "涨跌额": -0.3,
        "涨跌幅": -1.69,
        "开盘价": 17.59,
        "最高价": 17.75,
        "最低价": 17.43,
        "昨收价": 17.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 69411.0,
        "成交额": 1218105.0,
        "振幅": 1.8,
        "换手率": null,
        "symbol": "106.DFP"
    },
    {
        "序号": 10612,
        "name": "Cresud Wt",
        "最新价": 0.58,
        "涨跌额": -0.01,
        "涨跌幅": -1.69,
        "开盘价": 0.58,
        "最高价": 0.58,
        "最低价": 0.54,
        "昨收价": 0.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 35486.0,
        "成交额": 19362.0,
        "振幅": 6.78,
        "换手率": null,
        "symbol": "105.CRESW"
    },
    {
        "序号": 10613,
        "name": "Global X MSCI China Health Care",
        "最新价": 13.32,
        "涨跌额": -0.23,
        "涨跌幅": -1.7,
        "开盘价": 13.43,
        "最高价": 13.48,
        "最低价": 13.31,
        "昨收价": 13.55,
        "总市值": null,
        "市盈率": null,
        "成交量": 19869.0,
        "成交额": 265607.0,
        "振幅": 1.25,
        "换手率": null,
        "symbol": "107.CHIH"
    },
    {
        "序号": 10614,
        "name": "United States 12 Month Natural ",
        "最新价": 8.67,
        "涨跌额": -0.15,
        "涨跌幅": -1.7,
        "开盘价": 8.74,
        "最高价": 8.84,
        "最低价": 8.59,
        "昨收价": 8.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 50022.0,
        "成交额": 436542.0,
        "振幅": 2.83,
        "换手率": null,
        "symbol": "107.UNL"
    },
    {
        "序号": 10615,
        "name": "斯特姆-鲁格",
        "最新价": 43.85,
        "涨跌额": -0.76,
        "涨跌幅": -1.7,
        "开盘价": 44.54,
        "最高价": 44.68,
        "最低价": 43.65,
        "昨收价": 44.61,
        "总市值": 777139606.0,
        "市盈率": 13.65,
        "成交量": 112458.0,
        "成交额": 4946533.0,
        "振幅": 2.31,
        "换手率": 0.63,
        "symbol": "106.RGR"
    },
    {
        "序号": 10616,
        "name": "Build-A-Bear Workshop Inc",
        "最新价": 23.07,
        "涨跌额": -0.4,
        "涨跌幅": -1.7,
        "开盘价": 23.62,
        "最高价": 24.0,
        "最低价": 22.85,
        "昨收价": 23.47,
        "总市值": 330032268.0,
        "市盈率": 6.47,
        "成交量": 271333.0,
        "成交额": 6281397.0,
        "振幅": 4.9,
        "换手率": 1.9,
        "symbol": "106.BBW"
    },
    {
        "序号": 10617,
        "name": "Hamilton Beach Brands Holding C",
        "最新价": 15.55,
        "涨跌额": -0.27,
        "涨跌幅": -1.71,
        "开盘价": 15.82,
        "最高价": 15.89,
        "最低价": 15.33,
        "昨收价": 15.82,
        "总市值": 217593809.0,
        "市盈率": 17.04,
        "成交量": 25186.0,
        "成交额": 391803.0,
        "振幅": 3.54,
        "换手率": 0.18,
        "symbol": "106.HBB"
    },
    {
        "序号": 10618,
        "name": "二倍做多20年+国债ETF-ProShares",
        "最新价": 20.7,
        "涨跌额": -0.36,
        "涨跌幅": -1.71,
        "开盘价": 20.68,
        "最高价": 20.78,
        "最低价": 20.39,
        "昨收价": 21.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 153314.0,
        "成交额": 3158222.0,
        "振幅": 1.85,
        "换手率": null,
        "symbol": "107.UBT"
    },
    {
        "序号": 10619,
        "name": "Nuveen Minnesota Quality Munici",
        "最新价": 10.91,
        "涨跌额": -0.19,
        "涨跌幅": -1.71,
        "开盘价": 10.99,
        "最高价": 10.99,
        "最低价": 10.91,
        "昨收价": 11.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1238.0,
        "成交额": 13523.0,
        "振幅": 0.72,
        "换手率": null,
        "symbol": "106.NMS"
    },
    {
        "序号": 10620,
        "name": "iShares MSCI Global Gold Miners",
        "最新价": 23.53,
        "涨跌额": -0.41,
        "涨跌幅": -1.71,
        "开盘价": 23.51,
        "最高价": 23.78,
        "最低价": 23.26,
        "昨收价": 23.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 78077.0,
        "成交额": 1838458.0,
        "振幅": 2.17,
        "换手率": null,
        "symbol": "105.RING"
    },
    {
        "序号": 10621,
        "name": "Federal Agricultural Mortgage C",
        "最新价": 22.94,
        "涨跌额": -0.4,
        "涨跌幅": -1.71,
        "开盘价": 23.12,
        "最高价": 23.12,
        "最低价": 22.89,
        "昨收价": 23.34,
        "总市值": 91760000.0,
        "市盈率": null,
        "成交量": 3494.0,
        "成交额": 80265.0,
        "振幅": 0.99,
        "换手率": 0.09,
        "symbol": "106.AGM_D"
    },
    {
        "序号": 10622,
        "name": "中国消费者指数ETF-Global X",
        "最新价": 17.2,
        "涨跌额": -0.3,
        "涨跌幅": -1.71,
        "开盘价": 17.25,
        "最高价": 17.32,
        "最低价": 17.2,
        "昨收价": 17.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 29710.0,
        "成交额": 512641.0,
        "振幅": 0.69,
        "换手率": null,
        "symbol": "107.CHIQ"
    },
    {
        "序号": 10623,
        "name": "NanoString Technologies Inc",
        "最新价": 0.57,
        "涨跌额": -0.01,
        "涨跌幅": -1.72,
        "开盘价": 0.59,
        "最高价": 0.59,
        "最低价": 0.57,
        "昨收价": 0.58,
        "总市值": 27427434.0,
        "市盈率": -0.16,
        "成交量": 463595.0,
        "成交额": 267985.0,
        "振幅": 3.45,
        "换手率": 0.96,
        "symbol": "105.NSTG"
    },
    {
        "序号": 10624,
        "name": "Sachem Capital Corp Notes",
        "最新价": 22.75,
        "涨跌额": -0.4,
        "涨跌幅": -1.73,
        "开盘价": 23.15,
        "最高价": 23.15,
        "最低价": 22.75,
        "昨收价": 23.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 2033.0,
        "成交额": 46665.0,
        "振幅": 1.73,
        "换手率": null,
        "symbol": "107.SCCG"
    },
    {
        "序号": 10625,
        "name": "India Internet & Ecommerce ETF",
        "最新价": 13.06,
        "涨跌额": -0.23,
        "涨跌幅": -1.73,
        "开盘价": 13.1,
        "最高价": 13.1,
        "最低价": 13.0,
        "昨收价": 13.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 8728.0,
        "成交额": 113908.0,
        "振幅": 0.75,
        "换手率": null,
        "symbol": "107.INQQ"
    },
    {
        "序号": 10626,
        "name": "Hagerty Inc-A",
        "最新价": 8.49,
        "涨跌额": -0.15,
        "涨跌幅": -1.74,
        "开盘价": 8.6,
        "最高价": 8.71,
        "最低价": 8.34,
        "昨收价": 8.64,
        "总市值": 2849434533.0,
        "市盈率": 2706.02,
        "成交量": 77502.0,
        "成交额": 655747.0,
        "振幅": 4.28,
        "换手率": 0.02,
        "symbol": "106.HGTY"
    },
    {
        "序号": 10627,
        "name": "奇美拉投资",
        "最新价": 5.09,
        "涨跌额": -0.09,
        "涨跌幅": -1.74,
        "开盘价": 5.11,
        "最高价": 5.19,
        "最低价": 5.0,
        "昨收价": 5.18,
        "总市值": 1154219797.0,
        "市盈率": 5.99,
        "成交量": 6190531.0,
        "成交额": 31157439.0,
        "振幅": 3.67,
        "换手率": 2.73,
        "symbol": "106.CIM"
    },
    {
        "序号": 10628,
        "name": "Quadratic Interest Rate Volatil",
        "最新价": 20.35,
        "涨跌额": -0.36,
        "涨跌幅": -1.74,
        "开盘价": 20.45,
        "最高价": 20.45,
        "最低价": 20.34,
        "昨收价": 20.71,
        "总市值": null,
        "市盈率": null,
        "成交量": 510027.0,
        "成交额": 10392439.0,
        "振幅": 0.53,
        "换手率": null,
        "symbol": "107.IVOL"
    },
    {
        "序号": 10629,
        "name": "电话数据系统",
        "最新价": 18.59,
        "涨跌额": -0.33,
        "涨跌幅": -1.74,
        "开盘价": 18.79,
        "最高价": 19.24,
        "最低价": 18.37,
        "昨收价": 18.92,
        "总市值": 2100670000.0,
        "市盈率": -110.56,
        "成交量": 1009748.0,
        "成交额": 18865383.0,
        "振幅": 4.6,
        "换手率": 0.89,
        "symbol": "106.TDS"
    },
    {
        "序号": 10630,
        "name": "Virtus LifeSci Biotech Products",
        "最新价": 52.88,
        "涨跌额": -0.94,
        "涨跌幅": -1.75,
        "开盘价": 53.63,
        "最高价": 53.8,
        "最低价": 52.88,
        "昨收价": 53.82,
        "总市值": null,
        "市盈率": null,
        "成交量": 3768.0,
        "成交额": 200718.0,
        "振幅": 1.71,
        "换手率": null,
        "symbol": "107.BBP"
    },
    {
        "序号": 10631,
        "name": "盟军集团",
        "最新价": 2.25,
        "涨跌额": -0.04,
        "涨跌幅": -1.75,
        "开盘价": 2.11,
        "最高价": 2.35,
        "最低价": 2.07,
        "昨收价": 2.29,
        "总市值": 228595496.0,
        "市盈率": -1104.33,
        "成交量": 29392.0,
        "成交额": 65599.0,
        "振幅": 12.23,
        "换手率": 0.03,
        "symbol": "105.TROO"
    },
    {
        "序号": 10632,
        "name": "Pitney Bowes Inc Notes",
        "最新价": 17.37,
        "涨跌额": -0.31,
        "涨跌幅": -1.75,
        "开盘价": 17.5,
        "最高价": 17.7,
        "最低价": 17.14,
        "昨收价": 17.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 18742.0,
        "成交额": 326899.0,
        "振幅": 3.17,
        "换手率": null,
        "symbol": "106.PBI_B"
    },
    {
        "序号": 10633,
        "name": "Rapid Micro Biosystems Inc-A",
        "最新价": 1.12,
        "涨跌额": -0.02,
        "涨跌幅": -1.75,
        "开盘价": 1.14,
        "最高价": 1.14,
        "最低价": 1.12,
        "昨收价": 1.14,
        "总市值": 47486774.0,
        "市盈率": -0.82,
        "成交量": 1909.0,
        "成交额": 2163.0,
        "振幅": 1.75,
        "换手率": 0.0,
        "symbol": "105.RPID"
    },
    {
        "序号": 10634,
        "name": "Elevation Oncology Inc",
        "最新价": 0.56,
        "涨跌额": -0.01,
        "涨跌幅": -1.75,
        "开盘价": 0.57,
        "最高价": 0.6,
        "最低价": 0.54,
        "昨收价": 0.57,
        "总市值": 23752255.0,
        "市盈率": -0.42,
        "成交量": 96168.0,
        "成交额": 53528.0,
        "振幅": 10.53,
        "换手率": 0.23,
        "symbol": "105.ELEV"
    },
    {
        "序号": 10635,
        "name": "Akoustis Technologies Inc",
        "最新价": 0.56,
        "涨跌额": -0.01,
        "涨跌幅": -1.75,
        "开盘价": 0.56,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.57,
        "总市值": 40590880.0,
        "市盈率": -0.63,
        "成交量": 532228.0,
        "成交额": 302988.0,
        "振幅": 8.77,
        "换手率": 0.73,
        "symbol": "105.AKTS"
    },
    {
        "序号": 10636,
        "name": "好时",
        "最新价": 185.74,
        "涨跌额": -3.32,
        "涨跌幅": -1.76,
        "开盘价": 188.5,
        "最高价": 188.65,
        "最低价": 184.5,
        "昨收价": 189.06,
        "总市值": 37983492696.0,
        "市盈率": 19.9,
        "成交量": 1505125.0,
        "成交额": 279596832.0,
        "振幅": 2.2,
        "换手率": 0.74,
        "symbol": "106.HSY"
    },
    {
        "序号": 10637,
        "name": "iPath Series B Carbon ETN",
        "最新价": 27.39,
        "涨跌额": -0.49,
        "涨跌幅": -1.76,
        "开盘价": 27.42,
        "最高价": 27.53,
        "最低价": 27.3,
        "昨收价": 27.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 5631.0,
        "成交额": 154351.0,
        "振幅": 0.82,
        "换手率": null,
        "symbol": "107.GRN"
    },
    {
        "序号": 10638,
        "name": "Ovid Therapeutics Inc",
        "最新价": 3.35,
        "涨跌额": -0.06,
        "涨跌幅": -1.76,
        "开盘价": 3.39,
        "最高价": 3.44,
        "最低价": 3.34,
        "昨收价": 3.41,
        "总市值": 236779846.0,
        "市盈率": -4.88,
        "成交量": 81955.0,
        "成交额": 275637.0,
        "振幅": 2.93,
        "换手率": 0.12,
        "symbol": "105.OVID"
    },
    {
        "序号": 10639,
        "name": "OKYO Pharma Ltd",
        "最新价": 1.67,
        "涨跌额": -0.03,
        "涨跌幅": -1.76,
        "开盘价": 1.66,
        "最高价": 1.72,
        "最低价": 1.66,
        "昨收价": 1.7,
        "总市值": 54594388.0,
        "市盈率": -4.11,
        "成交量": 11499.0,
        "成交额": 19354.0,
        "振幅": 3.53,
        "换手率": 0.04,
        "symbol": "105.OKYO"
    },
    {
        "序号": 10640,
        "name": "NextPlat Corp",
        "最新价": 1.67,
        "涨跌额": -0.03,
        "涨跌幅": -1.76,
        "开盘价": 1.6,
        "最高价": 1.77,
        "最低价": 1.58,
        "昨收价": 1.7,
        "总市值": 31270075.0,
        "市盈率": -10.35,
        "成交量": 10811.0,
        "成交额": 18427.0,
        "振幅": 11.18,
        "换手率": 0.06,
        "symbol": "105.NXPL"
    },
    {
        "序号": 10641,
        "name": "John B. Sanfilippo & Son Inc",
        "最新价": 97.96,
        "涨跌额": -1.76,
        "涨跌幅": -1.76,
        "开盘价": 100.25,
        "最高价": 100.31,
        "最低价": 97.63,
        "昨收价": 99.72,
        "总市值": 1133441968.0,
        "市盈率": 17.46,
        "成交量": 38744.0,
        "成交额": 3801292.0,
        "振幅": 2.69,
        "换手率": 0.33,
        "symbol": "105.JBSS"
    },
    {
        "序号": 10642,
        "name": "达美乐比萨(US)",
        "最新价": 394.9,
        "涨跌额": -7.1,
        "涨跌幅": -1.77,
        "开盘价": 400.77,
        "最高价": 401.7,
        "最低价": 392.52,
        "昨收价": 402.0,
        "总市值": 13774500187.0,
        "市盈率": 26.48,
        "成交量": 653129.0,
        "成交额": 258800701.0,
        "振幅": 2.28,
        "换手率": 1.87,
        "symbol": "106.DPZ"
    },
    {
        "序号": 10643,
        "name": "Ocuphire Pharma Inc",
        "最新价": 2.78,
        "涨跌额": -0.05,
        "涨跌幅": -1.77,
        "开盘价": 2.84,
        "最高价": 2.92,
        "最低价": 2.71,
        "昨收价": 2.83,
        "总市值": 62932528.0,
        "市盈率": 2.19,
        "成交量": 228546.0,
        "成交额": 637967.0,
        "振幅": 7.42,
        "换手率": 1.01,
        "symbol": "105.OCUP"
    },
    {
        "序号": 10644,
        "name": "雷克兰工业",
        "最新价": 15.01,
        "涨跌额": -0.27,
        "涨跌幅": -1.77,
        "开盘价": 15.2,
        "最高价": 15.31,
        "最低价": 14.85,
        "昨收价": 15.28,
        "总市值": 110545003.0,
        "市盈率": 16.79,
        "成交量": 17744.0,
        "成交额": 267467.0,
        "振幅": 3.01,
        "换手率": 0.24,
        "symbol": "105.LAKE"
    },
    {
        "序号": 10645,
        "name": "罗宾逊全球物流",
        "最新价": 84.5,
        "涨跌额": -1.52,
        "涨跌幅": -1.77,
        "开盘价": 86.08,
        "最高价": 86.24,
        "最低价": 84.46,
        "昨收价": 86.02,
        "总市值": 9862206250.0,
        "市盈率": 25.27,
        "成交量": 1069984.0,
        "成交额": 90867697.0,
        "振幅": 2.07,
        "换手率": 0.92,
        "symbol": "105.CHRW"
    },
    {
        "序号": 10646,
        "name": "Oxford Lane Capital Corp.",
        "最新价": 5.0,
        "涨跌额": -0.09,
        "涨跌幅": -1.77,
        "开盘价": 5.08,
        "最高价": 5.08,
        "最低价": 4.98,
        "昨收价": 5.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 2056599.0,
        "成交额": 10300647.0,
        "振幅": 1.96,
        "换手率": null,
        "symbol": "105.OXLC"
    },
    {
        "序号": 10647,
        "name": "NetSTREIT Corp",
        "最新价": 16.62,
        "涨跌额": -0.3,
        "涨跌幅": -1.77,
        "开盘价": 16.84,
        "最高价": 16.89,
        "最低价": 16.61,
        "昨收价": 16.92,
        "总市值": 1141814326.0,
        "市盈率": 148.65,
        "成交量": 446498.0,
        "成交额": 7455754.0,
        "振幅": 1.65,
        "换手率": 0.65,
        "symbol": "106.NTST"
    },
    {
        "序号": 10648,
        "name": "中国原材料指数ETF-Global X",
        "最新价": 13.84,
        "涨跌额": -0.25,
        "涨跌幅": -1.77,
        "开盘价": 13.84,
        "最高价": 13.84,
        "最低价": 13.84,
        "昨收价": 14.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 25.0,
        "成交额": 346.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CHIM"
    },
    {
        "序号": 10649,
        "name": "理想汽车",
        "最新价": 34.87,
        "涨跌额": -0.63,
        "涨跌幅": -1.77,
        "开盘价": 34.75,
        "最高价": 35.19,
        "最低价": 34.58,
        "昨收价": 35.5,
        "总市值": 36997423373.0,
        "市盈率": 42.39,
        "成交量": 3617192.0,
        "成交额": 126051057.0,
        "振幅": 1.72,
        "换手率": 0.34,
        "symbol": "105.LI"
    },
    {
        "序号": 10650,
        "name": "Seelos Therapeutics Inc",
        "最新价": 1.66,
        "涨跌额": -0.03,
        "涨跌幅": -1.78,
        "开盘价": 1.7,
        "最高价": 1.72,
        "最低价": 1.63,
        "昨收价": 1.69,
        "总市值": 12237230.0,
        "市盈率": -0.24,
        "成交量": 440602.0,
        "成交额": 737013.0,
        "振幅": 5.33,
        "换手率": 5.98,
        "symbol": "105.SEEL"
    },
    {
        "序号": 10651,
        "name": "太阳能ETF-Guggenheim",
        "最新价": 45.82,
        "涨跌额": -0.83,
        "涨跌幅": -1.78,
        "开盘价": 46.48,
        "最高价": 47.24,
        "最低价": 45.31,
        "昨收价": 46.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 877068.0,
        "成交额": 40397922.0,
        "振幅": 4.14,
        "换手率": null,
        "symbol": "107.TAN"
    },
    {
        "序号": 10652,
        "name": "uniQure NV",
        "最新价": 7.17,
        "涨跌额": -0.13,
        "涨跌幅": -1.78,
        "开盘价": 7.23,
        "最高价": 7.27,
        "最低价": 6.95,
        "昨收价": 7.3,
        "总市值": 342808304.0,
        "市盈率": -1.5,
        "成交量": 1345363.0,
        "成交额": 9596403.0,
        "振幅": 4.38,
        "换手率": 2.81,
        "symbol": "105.QURE"
    },
    {
        "序号": 10653,
        "name": "京东",
        "最新价": 26.45,
        "涨跌额": -0.48,
        "涨跌幅": -1.78,
        "开盘价": 26.79,
        "最高价": 26.92,
        "最低价": 26.44,
        "昨收价": 26.93,
        "总市值": 41629876241.0,
        "市盈率": 12.43,
        "成交量": 9210493.0,
        "成交额": 245114866.0,
        "振幅": 1.78,
        "换手率": 0.59,
        "symbol": "105.JD"
    },
    {
        "序号": 10654,
        "name": "iPower Inc",
        "最新价": 0.55,
        "涨跌额": -0.01,
        "涨跌幅": -1.79,
        "开盘价": 0.55,
        "最高价": 0.55,
        "最低价": 0.55,
        "昨收价": 0.56,
        "总市值": 16370406.0,
        "市盈率": -1.8,
        "成交量": 2804.0,
        "成交额": 1542.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.IPW"
    },
    {
        "序号": 10655,
        "name": "Rareview Inflation\/Deflation ET",
        "最新价": 19.8,
        "涨跌额": -0.36,
        "涨跌幅": -1.79,
        "开盘价": 19.89,
        "最高价": 19.89,
        "最低价": 19.8,
        "昨收价": 20.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 652.0,
        "成交额": 12945.0,
        "振幅": 0.45,
        "换手率": null,
        "symbol": "107.FLTN"
    },
    {
        "序号": 10656,
        "name": "Ocwen Financial Corp",
        "最新价": 26.39,
        "涨跌额": -0.48,
        "涨跌幅": -1.79,
        "开盘价": 26.51,
        "最高价": 27.16,
        "最低价": 25.56,
        "昨收价": 26.87,
        "总市值": 202596241.0,
        "市盈率": -2.11,
        "成交量": 13032.0,
        "成交额": 343717.0,
        "振幅": 5.95,
        "换手率": 0.17,
        "symbol": "106.OCN"
    },
    {
        "序号": 10657,
        "name": "Deciphera Pharmaceuticals Inc",
        "最新价": 14.8,
        "涨跌额": -0.27,
        "涨跌幅": -1.79,
        "开盘价": 15.01,
        "最高价": 15.46,
        "最低价": 14.78,
        "昨收价": 15.07,
        "总市值": 1185822442.0,
        "市盈率": -6.12,
        "成交量": 975080.0,
        "成交额": 14623105.0,
        "振幅": 4.51,
        "换手率": 1.22,
        "symbol": "105.DCPH"
    },
    {
        "序号": 10658,
        "name": "Xenon制药",
        "最新价": 38.34,
        "涨跌额": -0.7,
        "涨跌幅": -1.79,
        "开盘价": 39.37,
        "最高价": 40.34,
        "最低价": 38.25,
        "昨收价": 39.04,
        "总市值": 2832990640.0,
        "市盈率": -16.18,
        "成交量": 318215.0,
        "成交额": 12370721.0,
        "振幅": 5.35,
        "换手率": 0.43,
        "symbol": "105.XENE"
    },
    {
        "序号": 10659,
        "name": "ProShares Trust VIX Mid-Term Fu",
        "最新价": 16.93,
        "涨跌额": -0.31,
        "涨跌幅": -1.8,
        "开盘价": 17.2,
        "最高价": 17.2,
        "最低价": 16.93,
        "昨收价": 17.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 45401.0,
        "成交额": 773479.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.VIXM"
    },
    {
        "序号": 10660,
        "name": "Western Asset High Income Oppor",
        "最新价": 3.81,
        "涨跌额": -0.07,
        "涨跌幅": -1.8,
        "开盘价": 3.86,
        "最高价": 3.86,
        "最低价": 3.79,
        "昨收价": 3.88,
        "总市值": null,
        "市盈率": null,
        "成交量": 418962.0,
        "成交额": 1602496.0,
        "振幅": 1.8,
        "换手率": null,
        "symbol": "106.HIO"
    },
    {
        "序号": 10661,
        "name": "CareCloud Inc Series B Pfd",
        "最新价": 13.6,
        "涨跌额": -0.25,
        "涨跌幅": -1.81,
        "开盘价": 14.19,
        "最高价": 14.19,
        "最低价": 13.36,
        "昨收价": 13.85,
        "总市值": 19902131.0,
        "市盈率": null,
        "成交量": 3228.0,
        "成交额": 44033.0,
        "振幅": 5.99,
        "换手率": 0.22,
        "symbol": "105.CCLDO"
    },
    {
        "序号": 10662,
        "name": "iShares MSCI Global Silver Mine",
        "最新价": 9.78,
        "涨跌额": -0.18,
        "涨跌幅": -1.81,
        "开盘价": 9.75,
        "最高价": 9.95,
        "最低价": 9.66,
        "昨收价": 9.96,
        "总市值": null,
        "市盈率": null,
        "成交量": 63690.0,
        "成交额": 623542.0,
        "振幅": 2.91,
        "换手率": null,
        "symbol": "107.SLVP"
    },
    {
        "序号": 10663,
        "name": "Hancock Whitney Corp Notes",
        "最新价": 23.85,
        "涨跌额": -0.44,
        "涨跌幅": -1.81,
        "开盘价": 24.25,
        "最高价": 24.25,
        "最低价": 23.76,
        "昨收价": 24.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 4012.0,
        "成交额": 96140.0,
        "振幅": 2.02,
        "换手率": null,
        "symbol": "105.HWCPZ"
    },
    {
        "序号": 10664,
        "name": "Precipio Inc",
        "最新价": 6.5,
        "涨跌额": -0.12,
        "涨跌幅": -1.81,
        "开盘价": 6.48,
        "最高价": 6.5,
        "最低价": 6.4,
        "昨收价": 6.62,
        "总市值": 9230813.0,
        "市盈率": -1.02,
        "成交量": 1767.0,
        "成交额": 11364.0,
        "振幅": 1.51,
        "换手率": 0.12,
        "symbol": "105.PRPO"
    },
    {
        "序号": 10665,
        "name": "犹他医疗产品",
        "最新价": 80.51,
        "涨跌额": -1.49,
        "涨跌幅": -1.82,
        "开盘价": 82.0,
        "最高价": 82.66,
        "最低价": 80.33,
        "昨收价": 82.0,
        "总市值": 292213058.0,
        "市盈率": 17.29,
        "成交量": 11292.0,
        "成交额": 919985.0,
        "振幅": 2.84,
        "换手率": 0.31,
        "symbol": "105.UTMD"
    },
    {
        "序号": 10666,
        "name": "小牛电动",
        "最新价": 2.16,
        "涨跌额": -0.04,
        "涨跌幅": -1.82,
        "开盘价": 2.18,
        "最高价": 2.24,
        "最低价": 2.14,
        "昨收价": 2.2,
        "总市值": 166620061.0,
        "市盈率": -6.64,
        "成交量": 330697.0,
        "成交额": 718972.0,
        "振幅": 4.55,
        "换手率": 0.43,
        "symbol": "105.NIU"
    },
    {
        "序号": 10667,
        "name": "莱克肖尔万通金控",
        "最新价": 10.8,
        "涨跌额": -0.2,
        "涨跌幅": -1.82,
        "开盘价": 10.99,
        "最高价": 11.02,
        "最低价": 10.8,
        "昨收价": 11.0,
        "总市值": 61460381.0,
        "市盈率": 11.68,
        "成交量": 15516.0,
        "成交额": 169708.0,
        "振幅": 2.0,
        "换手率": 0.27,
        "symbol": "105.LSBK"
    },
    {
        "序号": 10668,
        "name": "HeartCore Enterprises Inc",
        "最新价": 0.54,
        "涨跌额": -0.01,
        "涨跌幅": -1.82,
        "开盘价": 0.53,
        "最高价": 0.55,
        "最低价": 0.5,
        "昨收价": 0.55,
        "总市值": 11255053.0,
        "市盈率": -4.08,
        "成交量": 43770.0,
        "成交额": 23175.0,
        "振幅": 9.09,
        "换手率": 0.21,
        "symbol": "105.HTCR"
    },
    {
        "序号": 10669,
        "name": "Dyadic International Inc",
        "最新价": 1.62,
        "涨跌额": -0.03,
        "涨跌幅": -1.82,
        "开盘价": 1.65,
        "最高价": 1.69,
        "最低价": 1.62,
        "昨收价": 1.65,
        "总市值": 46673919.0,
        "市盈率": -6.79,
        "成交量": 14862.0,
        "成交额": 24787.0,
        "振幅": 4.24,
        "换手率": 0.05,
        "symbol": "105.DYAI"
    },
    {
        "序号": 10670,
        "name": "Biotricity Inc",
        "最新价": 1.08,
        "涨跌额": -0.02,
        "涨跌幅": -1.82,
        "开盘价": 1.1,
        "最高价": 1.11,
        "最低价": 1.02,
        "昨收价": 1.1,
        "总市值": 9470142.0,
        "市盈率": -0.58,
        "成交量": 19162.0,
        "成交额": 20586.0,
        "振幅": 8.18,
        "换手率": 0.22,
        "symbol": "105.BTCY"
    },
    {
        "序号": 10671,
        "name": "Arcutis Biotherapeutics Inc",
        "最新价": 2.16,
        "涨跌额": -0.04,
        "涨跌幅": -1.82,
        "开盘价": 2.2,
        "最高价": 2.22,
        "最低价": 2.12,
        "昨收价": 2.2,
        "总市值": 203865280.0,
        "市盈率": -0.76,
        "成交量": 1984199.0,
        "成交额": 4308043.0,
        "振幅": 4.55,
        "换手率": 2.1,
        "symbol": "105.ARQT"
    },
    {
        "序号": 10672,
        "name": "ARKO Corp Wt",
        "最新价": 1.08,
        "涨跌额": -0.02,
        "涨跌幅": -1.82,
        "开盘价": 1.08,
        "最高价": 1.08,
        "最低价": 1.08,
        "昨收价": 1.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 1061.0,
        "成交额": 1145.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ARKOW"
    },
    {
        "序号": 10673,
        "name": "牛津工业",
        "最新价": 93.18,
        "涨跌额": -1.73,
        "涨跌幅": -1.82,
        "开盘价": 94.37,
        "最高价": 94.37,
        "最低价": 91.61,
        "昨收价": 94.91,
        "总市值": 1455946445.0,
        "市盈率": 9.53,
        "成交量": 204998.0,
        "成交额": 19031150.0,
        "振幅": 2.91,
        "换手率": 1.31,
        "symbol": "106.OXM"
    },
    {
        "序号": 10674,
        "name": "Forge Global Holdings Inc",
        "最新价": 3.23,
        "涨跌额": -0.06,
        "涨跌幅": -1.82,
        "开盘价": 3.25,
        "最高价": 3.27,
        "最低价": 3.18,
        "昨收价": 3.29,
        "总市值": 566130492.0,
        "市盈率": -6.25,
        "成交量": 332353.0,
        "成交额": 1072059.0,
        "振幅": 2.74,
        "换手率": 0.19,
        "symbol": "106.FRGE"
    },
    {
        "序号": 10675,
        "name": "TSR软件",
        "最新价": 8.61,
        "涨跌额": -0.16,
        "涨跌幅": -1.82,
        "开盘价": 8.66,
        "最高价": 8.66,
        "最低价": 8.41,
        "昨收价": 8.77,
        "总市值": 18457360.0,
        "市盈率": 10.29,
        "成交量": 4699.0,
        "成交额": 40072.0,
        "振幅": 2.85,
        "换手率": 0.22,
        "symbol": "105.TSRI"
    },
    {
        "序号": 10676,
        "name": "Simplify Intermediate Term Trea",
        "最新价": 13.42,
        "涨跌额": -0.25,
        "涨跌幅": -1.83,
        "开盘价": 13.45,
        "最高价": 13.45,
        "最低价": 13.34,
        "昨收价": 13.67,
        "总市值": null,
        "市盈率": null,
        "成交量": 24594.0,
        "成交额": 329716.0,
        "振幅": 0.8,
        "换手率": null,
        "symbol": "107.TYA"
    },
    {
        "序号": 10677,
        "name": "Axonics Inc",
        "最新价": 56.82,
        "涨跌额": -1.06,
        "涨跌幅": -1.83,
        "开盘价": 57.63,
        "最高价": 58.31,
        "最低价": 56.58,
        "昨收价": 57.88,
        "总市值": 2878616431.0,
        "市盈率": -239.94,
        "成交量": 566509.0,
        "成交额": 32400043.0,
        "振幅": 2.99,
        "换手率": 1.12,
        "symbol": "105.AXNX"
    },
    {
        "序号": 10678,
        "name": "Victoria’s Secret & Co",
        "最新价": 26.25,
        "涨跌额": -0.49,
        "涨跌幅": -1.83,
        "开盘价": 26.51,
        "最高价": 26.73,
        "最低价": 25.97,
        "昨收价": 26.74,
        "总市值": 2032667306.0,
        "市盈率": 20.13,
        "成交量": 2012086.0,
        "成交额": 53022062.0,
        "振幅": 2.84,
        "换手率": 2.6,
        "symbol": "106.VSCO"
    },
    {
        "序号": 10679,
        "name": "Roundhill Cannabis ETF",
        "最新价": 32.13,
        "涨跌额": -0.6,
        "涨跌幅": -1.83,
        "开盘价": 32.1,
        "最高价": 32.66,
        "最低价": 32.1,
        "昨收价": 32.73,
        "总市值": null,
        "市盈率": null,
        "成交量": 4289.0,
        "成交额": 138212.0,
        "振幅": 1.71,
        "换手率": null,
        "symbol": "107.WEED"
    },
    {
        "序号": 10680,
        "name": "NanoVibronix Inc",
        "最新价": 1.07,
        "涨跌额": -0.02,
        "涨跌幅": -1.83,
        "开盘价": 1.08,
        "最高价": 1.1,
        "最低价": 1.03,
        "昨收价": 1.09,
        "总市值": 2189599.0,
        "市盈率": -0.44,
        "成交量": 14684.0,
        "成交额": 15758.0,
        "振幅": 6.42,
        "换手率": 0.72,
        "symbol": "105.NAOV"
    },
    {
        "序号": 10681,
        "name": "SecureWorks Corp-A",
        "最新价": 5.88,
        "涨跌额": -0.11,
        "涨跌幅": -1.84,
        "开盘价": 6.08,
        "最高价": 6.08,
        "最低价": 5.61,
        "昨收价": 5.99,
        "总市值": 507617342.0,
        "市盈率": -4.31,
        "成交量": 33376.0,
        "成交额": 196616.0,
        "振幅": 7.85,
        "换手率": 0.04,
        "symbol": "105.SCWX"
    },
    {
        "序号": 10682,
        "name": "Western Asset Premier Bond Fund",
        "最新价": 10.69,
        "涨跌额": -0.2,
        "涨跌幅": -1.84,
        "开盘价": 10.81,
        "最高价": 10.83,
        "最低价": 10.56,
        "昨收价": 10.89,
        "总市值": null,
        "市盈率": null,
        "成交量": 21793.0,
        "成交额": 233713.0,
        "振幅": 2.48,
        "换手率": null,
        "symbol": "106.WEA"
    },
    {
        "序号": 10683,
        "name": "二倍做多日元ETF-ProShares",
        "最新价": 26.19,
        "涨跌额": -0.49,
        "涨跌幅": -1.84,
        "开盘价": 26.38,
        "最高价": 26.58,
        "最低价": 26.16,
        "昨收价": 26.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 135316.0,
        "成交额": 3558047.0,
        "振幅": 1.57,
        "换手率": null,
        "symbol": "107.YCL"
    },
    {
        "序号": 10684,
        "name": "美元树",
        "最新价": 124.41,
        "涨跌额": -2.33,
        "涨跌幅": -1.84,
        "开盘价": 126.28,
        "最高价": 127.26,
        "最低价": 124.35,
        "昨收价": 126.74,
        "总市值": 27105447682.0,
        "市盈率": 23.29,
        "成交量": 1580432.0,
        "成交额": 197751170.0,
        "振幅": 2.3,
        "换手率": 0.73,
        "symbol": "105.DLTR"
    },
    {
        "序号": 10685,
        "name": "万物新生",
        "最新价": 1.6,
        "涨跌额": -0.03,
        "涨跌幅": -1.84,
        "开盘价": 1.65,
        "最高价": 1.7,
        "最低价": 1.6,
        "昨收价": 1.63,
        "总市值": 357141175.0,
        "市盈率": -1.08,
        "成交量": 200217.0,
        "成交额": 329054.0,
        "振幅": 6.13,
        "换手率": 0.09,
        "symbol": "106.RERE"
    },
    {
        "序号": 10686,
        "name": "美莎比信托",
        "最新价": 18.13,
        "涨跌额": -0.34,
        "涨跌幅": -1.84,
        "开盘价": 18.3,
        "最高价": 18.41,
        "最低价": 18.09,
        "昨收价": 18.47,
        "总市值": 237865781.0,
        "市盈率": 35.1,
        "成交量": 44856.0,
        "成交额": 814994.0,
        "振幅": 1.73,
        "换手率": 0.34,
        "symbol": "106.MSB"
    },
    {
        "序号": 10687,
        "name": "Mama’s Creations Inc",
        "最新价": 3.73,
        "涨跌额": -0.07,
        "涨跌幅": -1.84,
        "开盘价": 3.79,
        "最高价": 3.8,
        "最低价": 3.67,
        "昨收价": 3.8,
        "总市值": 138309657.0,
        "市盈率": 22.72,
        "成交量": 212114.0,
        "成交额": 787744.0,
        "振幅": 3.42,
        "换手率": 0.57,
        "symbol": "105.MAMA"
    },
    {
        "序号": 10688,
        "name": "Enovis Corp",
        "最新价": 49.53,
        "涨跌额": -0.93,
        "涨跌幅": -1.84,
        "开盘价": 50.28,
        "最高价": 50.48,
        "最低价": 49.44,
        "昨收价": 50.46,
        "总市值": 2704047903.0,
        "市盈率": -36.14,
        "成交量": 493906.0,
        "成交额": 24598374.0,
        "振幅": 2.06,
        "换手率": 0.9,
        "symbol": "106.ENOV"
    },
    {
        "序号": 10689,
        "name": "登士柏",
        "最新价": 31.88,
        "涨跌额": -0.6,
        "涨跌幅": -1.85,
        "开盘价": 32.45,
        "最高价": 32.64,
        "最低价": 31.77,
        "昨收价": 32.48,
        "总市值": 6754109265.0,
        "市盈率": -31.56,
        "成交量": 3564845.0,
        "成交额": 113875188.0,
        "振幅": 2.68,
        "换手率": 1.68,
        "symbol": "105.XRAY"
    },
    {
        "序号": 10690,
        "name": "康迪车业",
        "最新价": 2.65,
        "涨跌额": -0.05,
        "涨跌幅": -1.85,
        "开盘价": 2.69,
        "最高价": 2.73,
        "最低价": 2.63,
        "昨收价": 2.7,
        "总市值": 231935420.0,
        "市盈率": -52.94,
        "成交量": 170030.0,
        "成交额": 452855.0,
        "振幅": 3.7,
        "换手率": 0.19,
        "symbol": "105.KNDI"
    },
    {
        "序号": 10691,
        "name": "Annexon, Inc.",
        "最新价": 2.65,
        "涨跌额": -0.05,
        "涨跌幅": -1.85,
        "开盘价": 2.6,
        "最高价": 2.77,
        "最低价": 2.6,
        "昨收价": 2.7,
        "总市值": 141083310.0,
        "市盈率": -1.0,
        "成交量": 175206.0,
        "成交额": 472085.0,
        "振幅": 6.3,
        "换手率": 0.33,
        "symbol": "105.ANNX"
    },
    {
        "序号": 10692,
        "name": "吉登运动服",
        "最新价": 36.52,
        "涨跌额": -0.69,
        "涨跌幅": -1.85,
        "开盘价": 37.28,
        "最高价": 37.33,
        "最低价": 36.41,
        "昨收价": 37.21,
        "总市值": 6288736659.0,
        "市盈率": 13.55,
        "成交量": 519532.0,
        "成交额": 19061143.0,
        "振幅": 2.47,
        "换手率": 0.3,
        "symbol": "106.GIL"
    },
    {
        "序号": 10693,
        "name": "Sprott Physical Gold and Silver",
        "最新价": 18.48,
        "涨跌额": -0.35,
        "涨跌幅": -1.86,
        "开盘价": 18.57,
        "最高价": 18.68,
        "最低价": 18.35,
        "昨收价": 18.83,
        "总市值": 3972223147.0,
        "市盈率": 9.18,
        "成交量": 1057526.0,
        "成交额": 19492549.0,
        "振幅": 1.75,
        "换手率": 0.49,
        "symbol": "107.CEF"
    },
    {
        "序号": 10694,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 9.46,
        "涨跌额": -0.18,
        "涨跌幅": -1.87,
        "开盘价": 9.78,
        "最高价": 9.79,
        "最低价": 9.45,
        "昨收价": 9.64,
        "总市值": 31355085.0,
        "市盈率": 86.77,
        "成交量": 657.0,
        "成交额": 6320.0,
        "振幅": 3.53,
        "换手率": null,
        "symbol": "105.MCAFU"
    },
    {
        "序号": 10695,
        "name": "Associated Banc-Corp Series E P",
        "最新价": 18.39,
        "涨跌额": -0.35,
        "涨跌幅": -1.87,
        "开盘价": 18.68,
        "最高价": 18.7,
        "最低价": 18.39,
        "昨收价": 18.74,
        "总市值": 1839000.0,
        "市盈率": null,
        "成交量": 4228.0,
        "成交额": 78720.0,
        "振幅": 1.65,
        "换手率": 4.23,
        "symbol": "106.ASB_E"
    },
    {
        "序号": 10696,
        "name": "Frontier Group Holdings Inc",
        "最新价": 4.72,
        "涨跌额": -0.09,
        "涨跌幅": -1.87,
        "开盘价": 4.81,
        "最高价": 4.81,
        "最低价": 4.52,
        "昨收价": 4.81,
        "总市值": 1043420763.0,
        "市盈率": 15.81,
        "成交量": 7766733.0,
        "成交额": 36255725.0,
        "振幅": 6.03,
        "换手率": 3.51,
        "symbol": "105.ULCC"
    },
    {
        "序号": 10697,
        "name": "Ramaco Resources Inc-A",
        "最新价": 16.78,
        "涨跌额": -0.32,
        "涨跌幅": -1.87,
        "开盘价": 17.1,
        "最高价": 17.86,
        "最低价": 16.69,
        "昨收价": 17.1,
        "总市值": 884070996.0,
        "市盈率": 13.26,
        "成交量": 1582335.0,
        "成交额": 27040442.0,
        "振幅": 6.84,
        "换手率": 3.6,
        "symbol": "105.METC"
    },
    {
        "序号": 10698,
        "name": "康斯托克能源",
        "最新价": 8.89,
        "涨跌额": -0.17,
        "涨跌幅": -1.88,
        "开盘价": 9.15,
        "最高价": 9.15,
        "最低价": 8.89,
        "昨收价": 9.06,
        "总市值": 2475237926.0,
        "市盈率": 3.97,
        "成交量": 3253043.0,
        "成交额": 29184571.0,
        "振幅": 2.87,
        "换手率": 1.17,
        "symbol": "106.CRK"
    },
    {
        "序号": 10699,
        "name": "瓦利化工",
        "最新价": 14.64,
        "涨跌额": -0.28,
        "涨跌幅": -1.88,
        "开盘价": 15.1,
        "最高价": 15.45,
        "最低价": 14.42,
        "昨收价": 14.92,
        "总市值": 414143538.0,
        "市盈率": -16.3,
        "成交量": 7405.0,
        "成交额": 109609.0,
        "振幅": 6.9,
        "换手率": 0.03,
        "symbol": "106.VHI"
    },
    {
        "序号": 10700,
        "name": "AXS Short Innovation Daily ETF",
        "最新价": 31.31,
        "涨跌额": -0.6,
        "涨跌幅": -1.88,
        "开盘价": 32.0,
        "最高价": 32.23,
        "最低价": 30.97,
        "昨收价": 31.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 1459702.0,
        "成交额": 45922164.0,
        "振幅": 3.95,
        "换手率": null,
        "symbol": "105.SARK"
    },
    {
        "序号": 10701,
        "name": "Medical Properties Trust Inc",
        "最新价": 4.69,
        "涨跌额": -0.09,
        "涨跌幅": -1.88,
        "开盘价": 4.71,
        "最高价": 4.8,
        "最低价": 4.64,
        "昨收价": 4.78,
        "总市值": 2809310000.0,
        "市盈率": -85.11,
        "成交量": 10741625.0,
        "成交额": 50334371.0,
        "振幅": 3.35,
        "换手率": 1.79,
        "symbol": "106.MPW"
    },
    {
        "序号": 10702,
        "name": "abrdn Physical Precious Metals ",
        "最新价": 87.52,
        "涨跌额": -1.68,
        "涨跌幅": -1.88,
        "开盘价": 88.14,
        "最高价": 88.53,
        "最低价": 87.32,
        "昨收价": 89.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 23099.0,
        "成交额": 2028402.0,
        "振幅": 1.36,
        "换手率": null,
        "symbol": "107.GLTR"
    },
    {
        "序号": 10703,
        "name": "Remark Holdings Inc",
        "最新价": 0.52,
        "涨跌额": -0.01,
        "涨跌幅": -1.89,
        "开盘价": 0.53,
        "最高价": 0.53,
        "最低价": 0.51,
        "昨收价": 0.53,
        "总市值": 10884565.0,
        "市盈率": -0.37,
        "成交量": 78461.0,
        "成交额": 40909.0,
        "振幅": 3.77,
        "换手率": 0.37,
        "symbol": "105.MARK"
    },
    {
        "序号": 10704,
        "name": "Biodesix Inc",
        "最新价": 1.56,
        "涨跌额": -0.03,
        "涨跌幅": -1.89,
        "开盘价": 1.62,
        "最高价": 1.64,
        "最低价": 1.54,
        "昨收价": 1.59,
        "总市值": 141143020.0,
        "市盈率": -2.23,
        "成交量": 69640.0,
        "成交额": 109998.0,
        "振幅": 6.29,
        "换手率": 0.08,
        "symbol": "105.BDSX"
    },
    {
        "序号": 10705,
        "name": "Direxion Daily NVDA Bear 1X Sha",
        "最新价": 23.34,
        "涨跌额": -0.45,
        "涨跌幅": -1.89,
        "开盘价": 23.83,
        "最高价": 23.83,
        "最低价": 23.28,
        "昨收价": 23.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 17710.0,
        "成交额": 413029.0,
        "振幅": 2.31,
        "换手率": null,
        "symbol": "105.NVDD"
    },
    {
        "序号": 10706,
        "name": "戴尔蒙特新鲜制造",
        "最新价": 23.85,
        "涨跌额": -0.46,
        "涨跌幅": -1.89,
        "开盘价": 24.32,
        "最高价": 24.32,
        "最低价": 23.74,
        "昨收价": 24.31,
        "总市值": 1147841734.0,
        "市盈率": 10.12,
        "成交量": 167431.0,
        "成交额": 4006024.0,
        "振幅": 2.39,
        "换手率": 0.35,
        "symbol": "106.FDP"
    },
    {
        "序号": 10707,
        "name": "李维斯",
        "最新价": 15.03,
        "涨跌额": -0.29,
        "涨跌幅": -1.89,
        "开盘价": 15.2,
        "最高价": 15.29,
        "最低价": 14.71,
        "昨收价": 15.32,
        "总市值": 5968937141.0,
        "市盈率": 21.84,
        "成交量": 2527521.0,
        "成交额": 37776234.0,
        "振幅": 3.79,
        "换手率": 0.64,
        "symbol": "106.LEVI"
    },
    {
        "序号": 10708,
        "name": "i-80 Gold Corp",
        "最新价": 1.55,
        "涨跌额": -0.03,
        "涨跌幅": -1.9,
        "开盘价": 1.54,
        "最高价": 1.58,
        "最低价": 1.5,
        "昨收价": 1.58,
        "总市值": 462675519.0,
        "市盈率": -4.76,
        "成交量": 1525934.0,
        "成交额": 2345184.0,
        "振幅": 5.06,
        "换手率": 0.51,
        "symbol": "107.IAUX"
    },
    {
        "序号": 10709,
        "name": "CareCloud Inc",
        "最新价": 1.03,
        "涨跌额": -0.02,
        "涨跌幅": -1.9,
        "开盘价": 1.02,
        "最高价": 1.05,
        "最低价": 1.02,
        "昨收价": 1.05,
        "总市值": 16349314.0,
        "市盈率": -3.65,
        "成交量": 34442.0,
        "成交额": 35569.0,
        "振幅": 2.86,
        "换手率": 0.22,
        "symbol": "105.CCLD"
    },
    {
        "序号": 10710,
        "name": "三倍做空罗素2000ETF-ProShares",
        "最新价": 39.02,
        "涨跌额": -0.76,
        "涨跌幅": -1.91,
        "开盘价": 40.01,
        "最高价": 40.22,
        "最低价": 38.39,
        "昨收价": 39.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1120991.0,
        "成交额": 43854897.0,
        "振幅": 4.6,
        "换手率": null,
        "symbol": "107.SRTY"
    },
    {
        "序号": 10711,
        "name": "Worksport Ltd",
        "最新价": 1.54,
        "涨跌额": -0.03,
        "涨跌幅": -1.91,
        "开盘价": 1.56,
        "最高价": 1.65,
        "最低价": 1.52,
        "昨收价": 1.57,
        "总市值": 29927921.0,
        "市盈率": -2.03,
        "成交量": 86935.0,
        "成交额": 135599.0,
        "振幅": 8.28,
        "换手率": 0.45,
        "symbol": "105.WKSP"
    },
    {
        "序号": 10712,
        "name": "TDCX Inc ADR",
        "最新价": 4.62,
        "涨跌额": -0.09,
        "涨跌幅": -1.91,
        "开盘价": 4.65,
        "最高价": 4.75,
        "最低价": 4.57,
        "昨收价": 4.71,
        "总市值": 669523294.0,
        "市盈率": 7.72,
        "成交量": 81943.0,
        "成交额": 383592.0,
        "振幅": 3.82,
        "换手率": 0.06,
        "symbol": "106.TDCX"
    },
    {
        "序号": 10713,
        "name": "金矿小型股ETF-VanEck Vectors",
        "最新价": 36.44,
        "涨跌额": -0.71,
        "涨跌幅": -1.91,
        "开盘价": 36.42,
        "最高价": 37.06,
        "最低价": 36.08,
        "昨收价": 37.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 8173029.0,
        "成交额": 298125072.0,
        "振幅": 2.64,
        "换手率": null,
        "symbol": "107.GDXJ"
    },
    {
        "序号": 10714,
        "name": "Global X Silver Miners ETF",
        "最新价": 26.63,
        "涨跌额": -0.52,
        "涨跌幅": -1.92,
        "开盘价": 26.62,
        "最高价": 27.06,
        "最低价": 26.45,
        "昨收价": 27.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 337531.0,
        "成交额": 9009943.0,
        "振幅": 2.25,
        "换手率": null,
        "symbol": "107.SIL"
    },
    {
        "序号": 10715,
        "name": "聪链",
        "最新价": 8.7,
        "涨跌额": -0.17,
        "涨跌幅": -1.92,
        "开盘价": 8.8,
        "最高价": 8.87,
        "最低价": 8.7,
        "昨收价": 8.87,
        "总市值": 521460739.0,
        "市盈率": 42.83,
        "成交量": 18688.0,
        "成交额": 164272.0,
        "振幅": 1.92,
        "换手率": 0.03,
        "symbol": "105.ICG"
    },
    {
        "序号": 10716,
        "name": "巴西电力-优先股",
        "最新价": 9.21,
        "涨跌额": -0.18,
        "涨跌幅": -1.92,
        "开盘价": 9.31,
        "最高价": 9.41,
        "最低价": 9.19,
        "昨收价": 9.39,
        "总市值": 2578260230.0,
        "市盈率": 28.12,
        "成交量": 46532.0,
        "成交额": 434017.0,
        "振幅": 2.34,
        "换手率": 0.02,
        "symbol": "106.EBR_B"
    },
    {
        "序号": 10717,
        "name": "Global X MSCI China Real Estate",
        "最新价": 13.79,
        "涨跌额": -0.27,
        "涨跌幅": -1.92,
        "开盘价": 13.91,
        "最高价": 13.96,
        "最低价": 13.57,
        "昨收价": 14.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 11924.0,
        "成交额": 163275.0,
        "振幅": 2.77,
        "换手率": null,
        "symbol": "107.CHIR"
    },
    {
        "序号": 10718,
        "name": "Jasper Therapeutics Inc-A",
        "最新价": 0.51,
        "涨跌额": -0.01,
        "涨跌幅": -1.92,
        "开盘价": 0.5,
        "最高价": 0.53,
        "最低价": 0.48,
        "昨收价": 0.52,
        "总市值": 56903383.0,
        "市盈率": -0.93,
        "成交量": 2008543.0,
        "成交额": 1008007.0,
        "振幅": 9.62,
        "换手率": 1.8,
        "symbol": "105.JSPR"
    },
    {
        "序号": 10719,
        "name": "New Concept Energy Inc",
        "最新价": 1.02,
        "涨跌额": -0.02,
        "涨跌幅": -1.92,
        "开盘价": 1.03,
        "最高价": 1.03,
        "最低价": 1.02,
        "昨收价": 1.04,
        "总市值": 5234573.0,
        "市盈率": 180.5,
        "成交量": 29649.0,
        "成交额": 30384.0,
        "振幅": 0.96,
        "换手率": 0.58,
        "symbol": "107.GBR"
    },
    {
        "序号": 10720,
        "name": "CEA Industries Inc",
        "最新价": 0.51,
        "涨跌额": -0.01,
        "涨跌幅": -1.92,
        "开盘价": 0.5,
        "最高价": 0.51,
        "最低价": 0.5,
        "昨收价": 0.52,
        "总市值": 4118950.0,
        "市盈率": -1.29,
        "成交量": 11534.0,
        "成交额": 5781.0,
        "振幅": 1.92,
        "换手率": 0.14,
        "symbol": "105.CEAD"
    },
    {
        "序号": 10721,
        "name": "加拿大鹅",
        "最新价": 11.72,
        "涨跌额": -0.23,
        "涨跌幅": -1.92,
        "开盘价": 11.87,
        "最高价": 12.16,
        "最低价": 11.71,
        "昨收价": 11.95,
        "总市值": 1187942669.0,
        "市盈率": 30.05,
        "成交量": 1112284.0,
        "成交额": 13153295.0,
        "振幅": 3.77,
        "换手率": 1.1,
        "symbol": "106.GOOS"
    },
    {
        "序号": 10722,
        "name": "Afya Ltd-A",
        "最新价": 21.36,
        "涨跌额": -0.42,
        "涨跌幅": -1.93,
        "开盘价": 21.63,
        "最高价": 21.76,
        "最低价": 21.28,
        "昨收价": 21.78,
        "总市值": 2001919670.0,
        "市盈率": 27.85,
        "成交量": 300629.0,
        "成交额": 6436118.0,
        "振幅": 2.2,
        "换手率": 0.32,
        "symbol": "105.AFYA"
    },
    {
        "序号": 10723,
        "name": "达达",
        "最新价": 3.05,
        "涨跌额": -0.06,
        "涨跌幅": -1.93,
        "开盘价": 3.1,
        "最高价": 3.11,
        "最低价": 3.04,
        "昨收价": 3.11,
        "总市值": 799134168.0,
        "市盈率": -5.27,
        "成交量": 857674.0,
        "成交额": 2630116.0,
        "振幅": 2.25,
        "换手率": 0.33,
        "symbol": "105.DADA"
    },
    {
        "序号": 10724,
        "name": "TG Therapeutics Inc",
        "最新价": 15.72,
        "涨跌额": -0.31,
        "涨跌幅": -1.93,
        "开盘价": 16.2,
        "最高价": 16.7,
        "最低价": 15.64,
        "昨收价": 16.03,
        "总市值": 2380166348.0,
        "市盈率": -91.88,
        "成交量": 4324269.0,
        "成交额": 69380631.0,
        "振幅": 6.61,
        "换手率": 2.86,
        "symbol": "105.TGTX"
    },
    {
        "序号": 10725,
        "name": "Barinthus Biotherapeutics plc A",
        "最新价": 3.04,
        "涨跌额": -0.06,
        "涨跌幅": -1.94,
        "开盘价": 3.01,
        "最高价": 3.1,
        "最低价": 3.01,
        "昨收价": 3.1,
        "总市值": 117238427.0,
        "市盈率": -1.52,
        "成交量": 3672.0,
        "成交额": 11262.0,
        "振幅": 2.9,
        "换手率": 0.01,
        "symbol": "105.BRNS"
    },
    {
        "序号": 10726,
        "name": "达纳",
        "最新价": 13.67,
        "涨跌额": -0.27,
        "涨跌幅": -1.94,
        "开盘价": 13.87,
        "最高价": 14.0,
        "最低价": 13.67,
        "昨收价": 13.94,
        "总市值": 1973102538.0,
        "市盈率": -19.34,
        "成交量": 1624424.0,
        "成交额": 22344177.0,
        "振幅": 2.37,
        "换手率": 1.13,
        "symbol": "106.DAN"
    },
    {
        "序号": 10727,
        "name": "Dorman Products Inc",
        "最新价": 74.82,
        "涨跌额": -1.48,
        "涨跌幅": -1.94,
        "开盘价": 76.53,
        "最高价": 77.46,
        "最低价": 74.78,
        "昨收价": 76.3,
        "总市值": 2355940764.0,
        "市盈率": 24.34,
        "成交量": 103615.0,
        "成交额": 7812142.0,
        "振幅": 3.51,
        "换手率": 0.33,
        "symbol": "105.DORM"
    },
    {
        "序号": 10728,
        "name": "Vicor Corp",
        "最新价": 38.39,
        "涨跌额": -0.76,
        "涨跌幅": -1.94,
        "开盘价": 38.6,
        "最高价": 39.88,
        "最低价": 38.29,
        "昨收价": 39.15,
        "总市值": 1706446249.0,
        "市盈率": 32.2,
        "成交量": 210123.0,
        "成交额": 8129703.0,
        "振幅": 4.06,
        "换手率": 0.47,
        "symbol": "105.VICR"
    },
    {
        "序号": 10729,
        "name": "ASA Gold and Precious Metals Li",
        "最新价": 15.13,
        "涨跌额": -0.3,
        "涨跌幅": -1.94,
        "开盘价": 15.26,
        "最高价": 15.38,
        "最低价": 15.1,
        "昨收价": 15.43,
        "总市值": 291856263.0,
        "市盈率": null,
        "成交量": 44039.0,
        "成交额": 671573.0,
        "振幅": 1.81,
        "换手率": 0.23,
        "symbol": "106.ASA"
    },
    {
        "序号": 10730,
        "name": "Bio Rad实验室-A",
        "最新价": 304.13,
        "涨跌额": -6.05,
        "涨跌幅": -1.95,
        "开盘价": 308.2,
        "最高价": 310.93,
        "最低价": 303.16,
        "昨收价": 310.18,
        "总市值": 8863920552.0,
        "市盈率": -55.64,
        "成交量": 218355.0,
        "成交额": 66571792.0,
        "振幅": 2.5,
        "换手率": 0.91,
        "symbol": "106.BIO"
    },
    {
        "序号": 10731,
        "name": "Direxion Daily CSI China Intern",
        "最新价": 32.17,
        "涨跌额": -0.64,
        "涨跌幅": -1.95,
        "开盘价": 32.2,
        "最高价": 32.64,
        "最低价": 32.04,
        "昨收价": 32.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 186553.0,
        "成交额": 6027443.0,
        "振幅": 1.83,
        "换手率": null,
        "symbol": "107.CWEB"
    },
    {
        "序号": 10732,
        "name": "Tivic Health Systems Inc",
        "最新价": 2.01,
        "涨跌额": -0.04,
        "涨跌幅": -1.95,
        "开盘价": 2.09,
        "最高价": 2.19,
        "最低价": 1.94,
        "昨收价": 2.05,
        "总市值": 2946845.0,
        "市盈率": -0.36,
        "成交量": 125491.0,
        "成交额": 256823.0,
        "振幅": 12.2,
        "换手率": 8.56,
        "symbol": "105.TIVC"
    },
    {
        "序号": 10733,
        "name": "科尔黛伦矿业",
        "最新价": 3.01,
        "涨跌额": -0.06,
        "涨跌幅": -1.95,
        "开盘价": 2.97,
        "最高价": 3.09,
        "最低价": 2.95,
        "昨收价": 3.07,
        "总市值": 1151655128.0,
        "市盈率": -39.69,
        "成交量": 4810193.0,
        "成交额": 14525221.0,
        "振幅": 4.56,
        "换手率": 1.26,
        "symbol": "106.CDE"
    },
    {
        "序号": 10734,
        "name": "Reviva Pharmaceuticals Holdings",
        "最新价": 4.01,
        "涨跌额": -0.08,
        "涨跌幅": -1.96,
        "开盘价": 4.0,
        "最高价": 4.09,
        "最低价": 3.87,
        "昨收价": 4.09,
        "总市值": 111953426.0,
        "市盈率": -2.97,
        "成交量": 387583.0,
        "成交额": 1547540.0,
        "振幅": 5.38,
        "换手率": 1.39,
        "symbol": "105.RVPH"
    },
    {
        "序号": 10735,
        "name": "普拉格能源",
        "最新价": 4.01,
        "涨跌额": -0.08,
        "涨跌幅": -1.96,
        "开盘价": 4.06,
        "最高价": 4.27,
        "最低价": 3.95,
        "昨收价": 4.09,
        "总市值": 2428064355.0,
        "市盈率": -2.56,
        "成交量": 25637647.0,
        "成交额": 104210505.0,
        "振幅": 7.82,
        "换手率": 4.23,
        "symbol": "105.PLUG"
    },
    {
        "序号": 10736,
        "name": "Richtech Robotics Inc-B",
        "最新价": 5.01,
        "涨跌额": -0.1,
        "涨跌幅": -1.96,
        "开盘价": 5.17,
        "最高价": 5.3,
        "最低价": 5.0,
        "昨收价": 5.11,
        "总市值": 321976898.0,
        "市盈率": -280.22,
        "成交量": 40703.0,
        "成交额": 208658.0,
        "振幅": 5.87,
        "换手率": 0.06,
        "symbol": "105.RR"
    },
    {
        "序号": 10737,
        "name": "ProQR Therapeutics NV",
        "最新价": 2.0,
        "涨跌额": -0.04,
        "涨跌幅": -1.96,
        "开盘价": 2.05,
        "最高价": 2.13,
        "最低价": 1.91,
        "昨收价": 2.04,
        "总市值": 162314986.0,
        "市盈率": -3.78,
        "成交量": 215618.0,
        "成交额": 438735.0,
        "振幅": 10.78,
        "换手率": 0.27,
        "symbol": "105.PRQR"
    },
    {
        "序号": 10738,
        "name": "NeuroBo Pharmaceuticals Inc",
        "最新价": 0.5,
        "涨跌额": -0.01,
        "涨跌幅": -1.96,
        "开盘价": 0.51,
        "最高价": 0.51,
        "最低价": 0.49,
        "昨收价": 0.51,
        "总市值": 19406259.0,
        "市盈率": -1.64,
        "成交量": 72382.0,
        "成交额": 36307.0,
        "振幅": 3.92,
        "换手率": 0.19,
        "symbol": "105.NRBO"
    },
    {
        "序号": 10739,
        "name": "Edible Garden AG Inc",
        "最新价": 0.5,
        "涨跌额": -0.01,
        "涨跌幅": -1.96,
        "开盘价": 0.51,
        "最高价": 0.52,
        "最低价": 0.5,
        "昨收价": 0.51,
        "总市值": 2852822.0,
        "市盈率": -0.32,
        "成交量": 20465.0,
        "成交额": 10367.0,
        "振幅": 3.92,
        "换手率": 0.36,
        "symbol": "105.EDBL"
    },
    {
        "序号": 10740,
        "name": "CRT信托",
        "最新价": 18.5,
        "涨跌额": -0.37,
        "涨跌幅": -1.96,
        "开盘价": 18.72,
        "最高价": 19.03,
        "最低价": 18.5,
        "昨收价": 18.87,
        "总市值": 111000000.0,
        "市盈率": 9.33,
        "成交量": 28726.0,
        "成交额": 540242.0,
        "振幅": 2.81,
        "换手率": 0.48,
        "symbol": "106.CRT"
    },
    {
        "序号": 10741,
        "name": "Avino Silver & Gold Mines Ltd",
        "最新价": 0.5,
        "涨跌额": -0.01,
        "涨跌幅": -1.96,
        "开盘价": 0.51,
        "最高价": 0.52,
        "最低价": 0.49,
        "昨收价": 0.51,
        "总市值": 62903074.0,
        "市盈率": 49.34,
        "成交量": 1397046.0,
        "成交额": 710619.0,
        "振幅": 5.88,
        "换手率": 1.11,
        "symbol": "107.ASM"
    },
    {
        "序号": 10742,
        "name": "富兰克林柯维",
        "最新价": 39.93,
        "涨跌额": -0.8,
        "涨跌幅": -1.96,
        "开盘价": 40.39,
        "最高价": 40.48,
        "最低价": 39.82,
        "昨收价": 40.73,
        "总市值": 536395183.0,
        "市盈率": 30.17,
        "成交量": 38311.0,
        "成交额": 1534111.0,
        "振幅": 1.62,
        "换手率": 0.29,
        "symbol": "106.FC"
    },
    {
        "序号": 10743,
        "name": "环球墨非",
        "最新价": 12.92,
        "涨跌额": -0.26,
        "涨跌幅": -1.97,
        "开盘价": 13.2,
        "最高价": 13.3,
        "最低价": 12.12,
        "昨收价": 13.18,
        "总市值": 350469923.0,
        "市盈率": -2470.41,
        "成交量": 279827.0,
        "成交额": 3641580.0,
        "振幅": 8.95,
        "换手率": 1.03,
        "symbol": "105.GMM"
    },
    {
        "序号": 10744,
        "name": "TaskUs Inc-A",
        "最新价": 12.42,
        "涨跌额": -0.25,
        "涨跌幅": -1.97,
        "开盘价": 12.67,
        "最高价": 12.8,
        "最低价": 12.33,
        "昨收价": 12.67,
        "总市值": 1109350314.0,
        "市盈率": 24.57,
        "成交量": 208434.0,
        "成交额": 2600979.0,
        "振幅": 3.71,
        "换手率": 0.23,
        "symbol": "105.TASK"
    },
    {
        "序号": 10745,
        "name": "Denali Therapeutics Inc",
        "最新价": 18.37,
        "涨跌额": -0.37,
        "涨跌幅": -1.97,
        "开盘价": 18.7,
        "最高价": 18.72,
        "最低价": 18.01,
        "昨收价": 18.74,
        "总市值": 2538676098.0,
        "市盈率": -20.4,
        "成交量": 800514.0,
        "成交额": 14700303.0,
        "振幅": 3.79,
        "换手率": 0.58,
        "symbol": "105.DNLI"
    },
    {
        "序号": 10746,
        "name": "Caleres Inc",
        "最新价": 28.29,
        "涨跌额": -0.57,
        "涨跌幅": -1.98,
        "开盘价": 28.68,
        "最高价": 28.77,
        "最低价": 27.85,
        "昨收价": 28.86,
        "总市值": 1004357238.0,
        "市盈率": 6.42,
        "成交量": 376654.0,
        "成交额": 10656939.0,
        "振幅": 3.19,
        "换手率": 1.06,
        "symbol": "106.CAL"
    },
    {
        "序号": 10747,
        "name": "Olaplex Holdings Inc",
        "最新价": 2.48,
        "涨跌额": -0.05,
        "涨跌幅": -1.98,
        "开盘价": 2.55,
        "最高价": 2.59,
        "最低价": 2.47,
        "昨收价": 2.53,
        "总市值": 1623737969.0,
        "市盈率": 20.02,
        "成交量": 1838686.0,
        "成交额": 4618454.0,
        "振幅": 4.74,
        "换手率": 0.28,
        "symbol": "105.OLPX"
    },
    {
        "序号": 10748,
        "name": "Grom Social Enterprises Inc",
        "最新价": 0.99,
        "涨跌额": -0.02,
        "涨跌幅": -1.98,
        "开盘价": 1.03,
        "最高价": 1.05,
        "最低价": 0.98,
        "昨收价": 1.01,
        "总市值": 1948151.0,
        "市盈率": -0.14,
        "成交量": 59708.0,
        "成交额": 60003.0,
        "振幅": 6.93,
        "换手率": 3.03,
        "symbol": "105.GROM"
    },
    {
        "序号": 10749,
        "name": "Guardant Health Inc",
        "最新价": 25.74,
        "涨跌额": -0.52,
        "涨跌幅": -1.98,
        "开盘价": 26.0,
        "最高价": 26.06,
        "最低价": 25.38,
        "昨收价": 26.26,
        "总市值": 3037120258.0,
        "市盈率": -7.02,
        "成交量": 1093347.0,
        "成交额": 28154735.0,
        "振幅": 2.59,
        "换手率": 0.93,
        "symbol": "105.GH"
    },
    {
        "序号": 10750,
        "name": "Allogene Therapeutics Inc",
        "最新价": 2.47,
        "涨跌额": -0.05,
        "涨跌幅": -1.98,
        "开盘价": 2.64,
        "最高价": 2.66,
        "最低价": 2.45,
        "昨收价": 2.52,
        "总市值": 415643355.0,
        "市盈率": -1.25,
        "成交量": 2649541.0,
        "成交额": 6714129.0,
        "振幅": 8.33,
        "换手率": 1.57,
        "symbol": "105.ALLO"
    },
    {
        "序号": 10751,
        "name": "Pyxis Tankers Inc",
        "最新价": 3.94,
        "涨跌额": -0.08,
        "涨跌幅": -1.99,
        "开盘价": 3.98,
        "最高价": 4.08,
        "最低价": 3.91,
        "昨收价": 4.02,
        "总市值": 42106418.0,
        "市盈率": 1.92,
        "成交量": 53335.0,
        "成交额": 212672.0,
        "振幅": 4.23,
        "换手率": 0.5,
        "symbol": "105.PXS"
    },
    {
        "序号": 10752,
        "name": "麦斯莫医疗",
        "最新价": 104.36,
        "涨跌额": -2.12,
        "涨跌幅": -1.99,
        "开盘价": 106.49,
        "最高价": 106.89,
        "最低价": 104.2,
        "昨收价": 106.48,
        "总市值": 5514513789.0,
        "市盈率": 62.31,
        "成交量": 549978.0,
        "成交额": 57734539.0,
        "振幅": 2.53,
        "换手率": 1.04,
        "symbol": "105.MASI"
    },
    {
        "序号": 10753,
        "name": "阿索尔达治疗",
        "最新价": 12.27,
        "涨跌额": -0.25,
        "涨跌幅": -2.0,
        "开盘价": 12.82,
        "最高价": 13.4,
        "最低价": 12.01,
        "昨收价": 12.52,
        "总市值": 15240542.0,
        "市盈率": -0.96,
        "成交量": 11640.0,
        "成交额": 152357.0,
        "振幅": 11.1,
        "换手率": 0.94,
        "symbol": "105.ACOR"
    },
    {
        "序号": 10754,
        "name": "亿邦国际",
        "最新价": 7.85,
        "涨跌额": -0.16,
        "涨跌幅": -2.0,
        "开盘价": 7.9,
        "最高价": 7.96,
        "最低价": 7.5,
        "昨收价": 8.01,
        "总市值": 49302836.0,
        "市盈率": -1.18,
        "成交量": 13822.0,
        "成交额": 106384.0,
        "振幅": 5.74,
        "换手率": 0.22,
        "symbol": "105.EBON"
    },
    {
        "序号": 10755,
        "name": "Transphorm Inc",
        "最新价": 2.94,
        "涨跌额": -0.06,
        "涨跌幅": -2.0,
        "开盘价": 2.99,
        "最高价": 3.0,
        "最低价": 2.93,
        "昨收价": 3.0,
        "总市值": 182288726.0,
        "市盈率": -5.39,
        "成交量": 28816.0,
        "成交额": 85733.0,
        "振幅": 2.33,
        "换手率": 0.05,
        "symbol": "105.TGAN"
    },
    {
        "序号": 10756,
        "name": "Fluent Inc",
        "最新价": 0.49,
        "涨跌额": -0.01,
        "涨跌幅": -2.0,
        "开盘价": 0.5,
        "最高价": 0.52,
        "最低价": 0.49,
        "昨收价": 0.5,
        "总市值": 39806207.0,
        "市盈率": -0.31,
        "成交量": 38065.0,
        "成交额": 19179.0,
        "振幅": 6.0,
        "换手率": 0.05,
        "symbol": "105.FLNT"
    },
    {
        "序号": 10757,
        "name": "Ekso Bionics Holdings Inc",
        "最新价": 1.47,
        "涨跌额": -0.03,
        "涨跌幅": -2.0,
        "开盘价": 1.5,
        "最高价": 1.57,
        "最低价": 1.45,
        "昨收价": 1.5,
        "总市值": 20925182.0,
        "市盈率": -1.38,
        "成交量": 95754.0,
        "成交额": 142803.0,
        "振幅": 8.0,
        "换手率": 0.67,
        "symbol": "105.EKSO"
    },
    {
        "序号": 10758,
        "name": "Cross Country Healthcare Inc",
        "最新价": 20.47,
        "涨跌额": -0.42,
        "涨跌幅": -2.01,
        "开盘价": 20.77,
        "最高价": 20.96,
        "最低价": 19.86,
        "昨收价": 20.89,
        "总市值": 719537859.0,
        "市盈率": 7.03,
        "成交量": 271773.0,
        "成交额": 5567847.0,
        "振幅": 5.27,
        "换手率": 0.77,
        "symbol": "105.CCRN"
    },
    {
        "序号": 10759,
        "name": "百度",
        "最新价": 113.45,
        "涨跌额": -2.33,
        "涨跌幅": -2.01,
        "开盘价": 114.46,
        "最高价": 114.46,
        "最低价": 112.91,
        "昨收价": 115.78,
        "总市值": 39661804836.0,
        "市盈率": 12.45,
        "成交量": 1911263.0,
        "成交额": 217111569.0,
        "振幅": 1.34,
        "换手率": 0.55,
        "symbol": "105.BIDU"
    },
    {
        "序号": 10760,
        "name": "第一资本",
        "最新价": 27.22,
        "涨跌额": -0.56,
        "涨跌幅": -2.02,
        "开盘价": 26.2,
        "最高价": 27.71,
        "最低价": 26.2,
        "昨收价": 27.78,
        "总市值": 91204965.0,
        "市盈率": 6.9,
        "成交量": 4197.0,
        "成交额": 113512.0,
        "振幅": 5.44,
        "换手率": 0.13,
        "symbol": "105.FCAP"
    },
    {
        "序号": 10761,
        "name": "Playtika Holding Corp",
        "最新价": 8.24,
        "涨跌额": -0.17,
        "涨跌幅": -2.02,
        "开盘价": 8.35,
        "最高价": 8.39,
        "最低价": 8.19,
        "昨收价": 8.41,
        "总市值": 3025955045.0,
        "市盈率": 10.61,
        "成交量": 630718.0,
        "成交额": 5202076.0,
        "振幅": 2.38,
        "换手率": 0.17,
        "symbol": "105.PLTK"
    },
    {
        "序号": 10762,
        "name": "Flux Power Holdings Inc",
        "最新价": 3.39,
        "涨跌额": -0.07,
        "涨跌幅": -2.02,
        "开盘价": 3.48,
        "最高价": 3.57,
        "最低价": 3.32,
        "昨收价": 3.46,
        "总市值": 56044412.0,
        "市盈率": -8.35,
        "成交量": 38819.0,
        "成交额": 133264.0,
        "振幅": 7.23,
        "换手率": 0.23,
        "symbol": "105.FLUX"
    },
    {
        "序号": 10763,
        "name": "美国电塔",
        "最新价": 206.45,
        "涨跌额": -4.27,
        "涨跌幅": -2.03,
        "开盘价": 209.8,
        "最高价": 210.29,
        "最低价": 204.05,
        "昨收价": 210.72,
        "总市值": 96239788611.0,
        "市盈率": 134.68,
        "成交量": 2364324.0,
        "成交额": 488051392.0,
        "振幅": 2.96,
        "换手率": 0.51,
        "symbol": "106.AMT"
    },
    {
        "序号": 10764,
        "name": "Ranger Energy Services Inc-A",
        "最新价": 10.12,
        "涨跌额": -0.21,
        "涨跌幅": -2.03,
        "开盘价": 10.34,
        "最高价": 10.44,
        "最低价": 10.11,
        "昨收价": 10.33,
        "总市值": 247056656.0,
        "市盈率": 8.43,
        "成交量": 196584.0,
        "成交额": 2001771.0,
        "振幅": 3.19,
        "换手率": 0.81,
        "symbol": "106.RNGR"
    },
    {
        "序号": 10765,
        "name": "Direxion Daily Global Clean Ene",
        "最新价": 7.22,
        "涨跌额": -0.15,
        "涨跌幅": -2.04,
        "开盘价": 7.44,
        "最高价": 7.45,
        "最低价": 7.11,
        "昨收价": 7.37,
        "总市值": null,
        "市盈率": null,
        "成交量": 14499.0,
        "成交额": 105005.0,
        "振幅": 4.61,
        "换手率": null,
        "symbol": "107.KLNE"
    },
    {
        "序号": 10766,
        "name": "三倍做空小型股ETF-Direxion",
        "最新价": 25.48,
        "涨跌额": -0.53,
        "涨跌幅": -2.04,
        "开盘价": 26.17,
        "最高价": 26.29,
        "最低价": 25.09,
        "昨收价": 26.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 12998065.0,
        "成交额": 332581744.0,
        "振幅": 4.61,
        "换手率": null,
        "symbol": "107.TZA"
    },
    {
        "序号": 10767,
        "name": "Panbela Therapeutics Inc",
        "最新价": 0.48,
        "涨跌额": -0.01,
        "涨跌幅": -2.04,
        "开盘价": 0.48,
        "最高价": 0.5,
        "最低价": 0.47,
        "昨收价": 0.49,
        "总市值": 2461047.0,
        "市盈率": -0.1,
        "成交量": 100911.0,
        "成交额": 48698.0,
        "振幅": 6.12,
        "换手率": 1.97,
        "symbol": "105.PBLA"
    },
    {
        "序号": 10768,
        "name": "BurgerFi International Inc",
        "最新价": 0.96,
        "涨跌额": -0.02,
        "涨跌幅": -2.04,
        "开盘价": 0.97,
        "最高价": 0.97,
        "最低价": 0.95,
        "昨收价": 0.98,
        "总市值": 25755948.0,
        "市盈率": -0.56,
        "成交量": 92215.0,
        "成交额": 88696.0,
        "振幅": 2.04,
        "换手率": 0.34,
        "symbol": "105.BFI"
    },
    {
        "序号": 10769,
        "name": "Advanced Health Intelligence Lt",
        "最新价": 1.92,
        "涨跌额": -0.04,
        "涨跌幅": -2.04,
        "开盘价": 2.02,
        "最高价": 2.02,
        "最低价": 1.9,
        "昨收价": 1.96,
        "总市值": 14928272.0,
        "市盈率": -1.75,
        "成交量": 17946.0,
        "成交额": 35160.0,
        "振幅": 6.12,
        "换手率": 0.23,
        "symbol": "105.AHI"
    },
    {
        "序号": 10770,
        "name": "Aeries Technology Inc-A",
        "最新价": 2.4,
        "涨跌额": -0.05,
        "涨跌幅": -2.04,
        "开盘价": 2.44,
        "最高价": 2.51,
        "最低价": 2.3,
        "昨收价": 2.45,
        "总市值": 36618401.0,
        "市盈率": -41.27,
        "成交量": 17309.0,
        "成交额": 40910.0,
        "振幅": 8.57,
        "换手率": 0.11,
        "symbol": "105.AERT"
    },
    {
        "序号": 10771,
        "name": "Apellis Pharmaceuticals Inc",
        "最新价": 62.73,
        "涨跌额": -1.31,
        "涨跌幅": -2.05,
        "开盘价": 63.5,
        "最高价": 63.5,
        "最低价": 61.35,
        "昨收价": 64.04,
        "总市值": 7433501550.0,
        "市盈率": -12.27,
        "成交量": 1637092.0,
        "成交额": 102477021.0,
        "振幅": 3.36,
        "换手率": 1.38,
        "symbol": "105.APLS"
    },
    {
        "序号": 10772,
        "name": "Howmet Aerospace Inc Pfd",
        "最新价": 55.5,
        "涨跌额": -1.16,
        "涨跌幅": -2.05,
        "开盘价": 54.21,
        "最高价": 55.5,
        "最低价": 54.15,
        "昨收价": 56.66,
        "总市值": 30304332.0,
        "市盈率": null,
        "成交量": 1254.0,
        "成交额": 68773.0,
        "振幅": 2.38,
        "换手率": 0.23,
        "symbol": "107.HWM_"
    },
    {
        "序号": 10773,
        "name": "Park-Ohio Holdings Corp",
        "最新价": 23.23,
        "涨跌额": -0.49,
        "涨跌幅": -2.07,
        "开盘价": 23.75,
        "最高价": 24.11,
        "最低价": 23.06,
        "昨收价": 23.72,
        "总市值": 304040698.0,
        "市盈率": -178.85,
        "成交量": 61859.0,
        "成交额": 1443178.0,
        "振幅": 4.43,
        "换手率": 0.47,
        "symbol": "105.PKOH"
    },
    {
        "序号": 10774,
        "name": "Western Asset High Income Fund ",
        "最新价": 4.74,
        "涨跌额": -0.1,
        "涨跌幅": -2.07,
        "开盘价": 4.82,
        "最高价": 4.84,
        "最低价": 4.74,
        "昨收价": 4.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 168554.0,
        "成交额": 806489.0,
        "振幅": 2.07,
        "换手率": null,
        "symbol": "106.HIX"
    },
    {
        "序号": 10775,
        "name": "亚历山大房地产",
        "最新价": 119.44,
        "涨跌额": -2.52,
        "涨跌幅": -2.07,
        "开盘价": 121.69,
        "最高价": 121.69,
        "最低价": 118.65,
        "昨收价": 121.96,
        "总市值": 20755740465.0,
        "市盈率": 84.24,
        "成交量": 1258990.0,
        "成交额": 150513657.0,
        "振幅": 2.49,
        "换手率": 0.72,
        "symbol": "106.ARE"
    },
    {
        "序号": 10776,
        "name": "Landos Biopharma Inc",
        "最新价": 4.26,
        "涨跌额": -0.09,
        "涨跌幅": -2.07,
        "开盘价": 4.38,
        "最高价": 4.45,
        "最低价": 3.96,
        "昨收价": 4.35,
        "总市值": 13277266.0,
        "市盈率": -0.63,
        "成交量": 33727.0,
        "成交额": 143026.0,
        "振幅": 11.26,
        "换手率": 1.08,
        "symbol": "105.LABP"
    },
    {
        "序号": 10777,
        "name": "Direxion Daily Dow Jones Intern",
        "最新价": 10.4,
        "涨跌额": -0.22,
        "涨跌幅": -2.07,
        "开盘价": 10.8,
        "最高价": 10.85,
        "最低价": 10.33,
        "昨收价": 10.62,
        "总市值": null,
        "市盈率": null,
        "成交量": 204009.0,
        "成交额": 2135251.0,
        "振幅": 4.9,
        "换手率": null,
        "symbol": "107.WEBS"
    },
    {
        "序号": 10778,
        "name": "二倍做空能源ETF-Direxion",
        "最新价": 29.72,
        "涨跌额": -0.63,
        "涨跌幅": -2.08,
        "开盘价": 29.99,
        "最高价": 30.08,
        "最低价": 29.54,
        "昨收价": 30.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 356286.0,
        "成交额": 10630855.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "107.ERY"
    },
    {
        "序号": 10779,
        "name": "Sachem Capital Corp",
        "最新价": 3.77,
        "涨跌额": -0.08,
        "涨跌幅": -2.08,
        "开盘价": 3.83,
        "最高价": 3.83,
        "最低价": 3.76,
        "昨收价": 3.85,
        "总市值": 170993737.0,
        "市盈率": 7.34,
        "成交量": 166590.0,
        "成交额": 630628.0,
        "振幅": 1.82,
        "换手率": 0.37,
        "symbol": "107.SACH"
    },
    {
        "序号": 10780,
        "name": "Vivani Medical Inc",
        "最新价": 0.94,
        "涨跌额": -0.02,
        "涨跌幅": -2.08,
        "开盘价": 0.94,
        "最高价": 0.96,
        "最低价": 0.93,
        "昨收价": 0.96,
        "总市值": 47963556.0,
        "市盈率": -1.78,
        "成交量": 10625.0,
        "成交额": 10018.0,
        "振幅": 3.13,
        "换手率": 0.02,
        "symbol": "105.VANI"
    },
    {
        "序号": 10781,
        "name": "Safe & Green Holdings Corp",
        "最新价": 0.47,
        "涨跌额": -0.01,
        "涨跌幅": -2.08,
        "开盘价": 0.47,
        "最高价": 0.5,
        "最低价": 0.45,
        "昨收价": 0.48,
        "总市值": 7746902.0,
        "市盈率": -0.47,
        "成交量": 2103634.0,
        "成交额": 1014368.0,
        "振幅": 10.42,
        "换手率": 12.76,
        "symbol": "105.SGBX"
    },
    {
        "序号": 10782,
        "name": "未来金融科技",
        "最新价": 0.94,
        "涨跌额": -0.02,
        "涨跌幅": -2.08,
        "开盘价": 0.96,
        "最高价": 0.96,
        "最低价": 0.88,
        "昨收价": 0.96,
        "总市值": 14048182.0,
        "市盈率": -1.22,
        "成交量": 62458.0,
        "成交额": 57931.0,
        "振幅": 8.33,
        "换手率": 0.42,
        "symbol": "105.FTFT"
    },
    {
        "序号": 10783,
        "name": "C3is Inc",
        "最新价": 0.47,
        "涨跌额": -0.01,
        "涨跌幅": -2.08,
        "开盘价": 0.49,
        "最高价": 0.5,
        "最低价": 0.46,
        "昨收价": 0.48,
        "总市值": 3072828.0,
        "市盈率": null,
        "成交量": 139416.0,
        "成交额": 66196.0,
        "振幅": 8.33,
        "换手率": 2.13,
        "symbol": "105.CISS"
    },
    {
        "序号": 10784,
        "name": "赫克拉矿业",
        "最新价": 4.69,
        "涨跌额": -0.1,
        "涨跌幅": -2.09,
        "开盘价": 4.68,
        "最高价": 4.82,
        "最低价": 4.63,
        "昨收价": 4.79,
        "总市值": 2899512165.0,
        "市盈率": -63.4,
        "成交量": 5151587.0,
        "成交额": 24234924.0,
        "振幅": 3.97,
        "换手率": 0.83,
        "symbol": "106.HL"
    },
    {
        "序号": 10785,
        "name": "TScan Therapeutics Inc",
        "最新价": 6.56,
        "涨跌额": -0.14,
        "涨跌幅": -2.09,
        "开盘价": 6.7,
        "最高价": 6.9,
        "最低价": 6.56,
        "昨收价": 6.7,
        "总市值": 313729901.0,
        "市盈率": -3.55,
        "成交量": 64591.0,
        "成交额": 430723.0,
        "振幅": 5.07,
        "换手率": 0.14,
        "symbol": "105.TCRX"
    },
    {
        "序号": 10786,
        "name": "Anavex Life Sciences Corp",
        "最新价": 7.96,
        "涨跌额": -0.17,
        "涨跌幅": -2.09,
        "开盘价": 8.12,
        "最高价": 8.24,
        "最低价": 7.93,
        "昨收价": 8.13,
        "总市值": 653408628.0,
        "市盈率": -13.75,
        "成交量": 694745.0,
        "成交额": 5585274.0,
        "振幅": 3.81,
        "换手率": 0.85,
        "symbol": "105.AVXL"
    },
    {
        "序号": 10787,
        "name": "Benitec Biopharma Inc",
        "最新价": 3.27,
        "涨跌额": -0.07,
        "涨跌幅": -2.1,
        "开盘价": 3.39,
        "最高价": 3.4,
        "最低价": 3.25,
        "昨收价": 3.34,
        "总市值": 8411859.0,
        "市盈率": -0.41,
        "成交量": 5048.0,
        "成交额": 16854.0,
        "振幅": 4.49,
        "换手率": 0.2,
        "symbol": "105.BNTC"
    },
    {
        "序号": 10788,
        "name": "搜狐",
        "最新价": 9.34,
        "涨跌额": -0.2,
        "涨跌幅": -2.1,
        "开盘价": 9.4,
        "最高价": 9.77,
        "最低价": 9.26,
        "昨收价": 9.54,
        "总市值": 315106718.0,
        "市盈率": -4.65,
        "成交量": 92489.0,
        "成交额": 876896.0,
        "振幅": 5.35,
        "换手率": 0.27,
        "symbol": "105.SOHU"
    },
    {
        "序号": 10789,
        "name": "Centerra Gold Inc",
        "最新价": 5.58,
        "涨跌额": -0.12,
        "涨跌幅": -2.11,
        "开盘价": 5.6,
        "最高价": 5.67,
        "最低价": 5.51,
        "昨收价": 5.7,
        "总市值": 1204204243.0,
        "市盈率": -6.6,
        "成交量": 155439.0,
        "成交额": 866946.0,
        "振幅": 2.81,
        "换手率": 0.07,
        "symbol": "106.CGAU"
    },
    {
        "序号": 10790,
        "name": "拜玛林制药",
        "最新价": 92.94,
        "涨跌额": -2.0,
        "涨跌幅": -2.11,
        "开盘价": 94.27,
        "最高价": 94.33,
        "最低价": 92.36,
        "昨收价": 94.94,
        "总市值": 17506937813.0,
        "市盈率": 119.08,
        "成交量": 1793126.0,
        "成交额": 166923016.0,
        "振幅": 2.07,
        "换手率": 0.95,
        "symbol": "105.BMRN"
    },
    {
        "序号": 10791,
        "name": "金矿ETF-VanEck Vectors",
        "最新价": 29.68,
        "涨跌额": -0.64,
        "涨跌幅": -2.11,
        "开盘价": 29.68,
        "最高价": 30.11,
        "最低价": 29.36,
        "昨收价": 30.32,
        "总市值": null,
        "市盈率": null,
        "成交量": 27561000.0,
        "成交额": 818347504.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "107.GDX"
    },
    {
        "序号": 10792,
        "name": "NeoVolta Inc",
        "最新价": 1.85,
        "涨跌额": -0.04,
        "涨跌幅": -2.12,
        "开盘价": 1.88,
        "最高价": 1.88,
        "最低价": 1.78,
        "昨收价": 1.89,
        "总市值": 61486768.0,
        "市盈率": -25.94,
        "成交量": 10696.0,
        "成交额": 19358.0,
        "振幅": 5.29,
        "换手率": 0.03,
        "symbol": "105.NEOV"
    },
    {
        "序号": 10793,
        "name": "Spok Holdings Inc",
        "最新价": 14.77,
        "涨跌额": -0.32,
        "涨跌幅": -2.12,
        "开盘价": 15.0,
        "最高价": 15.27,
        "最低价": 14.69,
        "昨收价": 15.09,
        "总市值": 295122885.0,
        "市盈率": 8.08,
        "成交量": 184332.0,
        "成交额": 2736741.0,
        "振幅": 3.84,
        "换手率": 0.92,
        "symbol": "105.SPOK"
    },
    {
        "序号": 10794,
        "name": "NLS Pharmaceutics Ltd",
        "最新价": 0.46,
        "涨跌额": -0.01,
        "涨跌幅": -2.13,
        "开盘价": 0.47,
        "最高价": 0.49,
        "最低价": 0.45,
        "昨收价": 0.47,
        "总市值": 16409019.0,
        "市盈率": -1.06,
        "成交量": 209848.0,
        "成交额": 97530.0,
        "振幅": 8.51,
        "换手率": 0.59,
        "symbol": "105.NLSP"
    },
    {
        "序号": 10795,
        "name": "Global X Genomics & Biotechnolo",
        "最新价": 10.58,
        "涨跌额": -0.23,
        "涨跌幅": -2.13,
        "开盘价": 10.8,
        "最高价": 10.98,
        "最低价": 10.58,
        "昨收价": 10.81,
        "总市值": null,
        "市盈率": null,
        "成交量": 114311.0,
        "成交额": 1226928.0,
        "振幅": 3.7,
        "换手率": null,
        "symbol": "105.GNOM"
    },
    {
        "序号": 10796,
        "name": "Agile Therapeutics Inc",
        "最新价": 2.3,
        "涨跌额": -0.05,
        "涨跌幅": -2.13,
        "开盘价": 2.35,
        "最高价": 2.42,
        "最低价": 2.25,
        "昨收价": 2.35,
        "总市值": 5776811.0,
        "市盈率": -0.41,
        "成交量": 33035.0,
        "成交额": 77456.0,
        "振幅": 7.23,
        "换手率": 1.32,
        "symbol": "105.AGRX"
    },
    {
        "序号": 10797,
        "name": "Direxion Daily 7-10 Year Treasu",
        "最新价": 26.2,
        "涨跌额": -0.57,
        "涨跌幅": -2.13,
        "开盘价": 26.33,
        "最高价": 26.35,
        "最低价": 26.03,
        "昨收价": 26.77,
        "总市值": null,
        "市盈率": null,
        "成交量": 146952.0,
        "成交额": 3864762.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "107.TYD"
    },
    {
        "序号": 10798,
        "name": "韩国电力",
        "最新价": 7.34,
        "涨跌额": -0.16,
        "涨跌幅": -2.13,
        "开盘价": 7.39,
        "最高价": 7.39,
        "最低价": 7.31,
        "昨收价": 7.5,
        "总市值": 9424032650.0,
        "市盈率": -0.87,
        "成交量": 104277.0,
        "成交额": 765694.0,
        "振幅": 1.07,
        "换手率": 0.01,
        "symbol": "106.KEP"
    },
    {
        "序号": 10799,
        "name": "Summit State Bank",
        "最新价": 12.82,
        "涨跌额": -0.28,
        "涨跌幅": -2.14,
        "开盘价": 13.03,
        "最高价": 13.24,
        "最低价": 12.7,
        "昨收价": 13.1,
        "总市值": 86313201.0,
        "市盈率": 26.22,
        "成交量": 3421.0,
        "成交额": 44452.0,
        "振幅": 4.12,
        "换手率": 0.05,
        "symbol": "105.SSBI"
    },
    {
        "序号": 10800,
        "name": "Vornado Realty Trust Series O P",
        "最新价": 12.77,
        "涨跌额": -0.28,
        "涨跌幅": -2.15,
        "开盘价": 12.86,
        "最高价": 13.11,
        "最低价": 12.61,
        "昨收价": 13.05,
        "总市值": 153240000.0,
        "市盈率": null,
        "成交量": 38592.0,
        "成交额": 495635.0,
        "振幅": 3.83,
        "换手率": 0.32,
        "symbol": "106.VNO_O"
    },
    {
        "序号": 10801,
        "name": "Global Net Lease Inc",
        "最新价": 9.1,
        "涨跌额": -0.2,
        "涨跌幅": -2.15,
        "开盘价": 9.24,
        "最高价": 9.26,
        "最低价": 9.04,
        "昨收价": 9.3,
        "总市值": 2096083207.0,
        "市盈率": -11.91,
        "成交量": 1618206.0,
        "成交额": 14765981.0,
        "振幅": 2.37,
        "换手率": 0.7,
        "symbol": "106.GNL"
    },
    {
        "序号": 10802,
        "name": "卡弗储蓄",
        "最新价": 1.82,
        "涨跌额": -0.04,
        "涨跌幅": -2.15,
        "开盘价": 1.83,
        "最高价": 1.9,
        "最低价": 1.74,
        "昨收价": 1.86,
        "总市值": 8951392.0,
        "市盈率": -1.6,
        "成交量": 48467.0,
        "成交额": 88037.0,
        "振幅": 8.6,
        "换手率": 0.99,
        "symbol": "105.CARV"
    },
    {
        "序号": 10803,
        "name": "enGene Holdings Inc-A",
        "最新价": 7.25,
        "涨跌额": -0.16,
        "涨跌幅": -2.16,
        "开盘价": 7.49,
        "最高价": 7.74,
        "最低价": 7.15,
        "昨收价": 7.41,
        "总市值": 243252210.0,
        "市盈率": -75.4,
        "成交量": 66922.0,
        "成交额": 496541.0,
        "振幅": 7.96,
        "换手率": 0.2,
        "symbol": "105.ENGN"
    },
    {
        "序号": 10804,
        "name": "CONSOL Energy Inc",
        "最新价": 101.72,
        "涨跌额": -2.25,
        "涨跌幅": -2.16,
        "开盘价": 103.95,
        "最高价": 104.8,
        "最低价": 99.47,
        "昨收价": 103.97,
        "总市值": 3154323163.0,
        "市盈率": 4.56,
        "成交量": 639260.0,
        "成交额": 64853740.0,
        "振幅": 5.13,
        "换手率": 2.06,
        "symbol": "106.CEIX"
    },
    {
        "序号": 10805,
        "name": "Verint Systems Inc",
        "最新价": 27.98,
        "涨跌额": -0.62,
        "涨跌幅": -2.17,
        "开盘价": 28.45,
        "最高价": 28.68,
        "最低价": 27.35,
        "昨收价": 28.6,
        "总市值": 1763172823.0,
        "市盈率": 77.15,
        "成交量": 813297.0,
        "成交额": 22751107.0,
        "振幅": 4.65,
        "换手率": 1.29,
        "symbol": "105.VRNT"
    },
    {
        "序号": 10806,
        "name": "Investcorp Credit Management BD",
        "最新价": 3.61,
        "涨跌额": -0.08,
        "涨跌幅": -2.17,
        "开盘价": 3.7,
        "最高价": 3.71,
        "最低价": 3.58,
        "昨收价": 3.69,
        "总市值": 51965647.0,
        "市盈率": -484.0,
        "成交量": 40061.0,
        "成交额": 144613.0,
        "振幅": 3.52,
        "换手率": 0.28,
        "symbol": "105.ICMB"
    },
    {
        "序号": 10807,
        "name": "Daseke Inc",
        "最新价": 4.5,
        "涨跌额": -0.1,
        "涨跌幅": -2.17,
        "开盘价": 4.57,
        "最高价": 4.7,
        "最低价": 4.49,
        "昨收价": 4.6,
        "总市值": 209257461.0,
        "市盈率": 12.84,
        "成交量": 191766.0,
        "成交额": 876048.0,
        "振幅": 4.57,
        "换手率": 0.41,
        "symbol": "105.DSKE"
    },
    {
        "序号": 10808,
        "name": "Guggenheim Strategic Opportunit",
        "最新价": 13.48,
        "涨跌额": -0.3,
        "涨跌幅": -2.18,
        "开盘价": 13.81,
        "最高价": 13.92,
        "最低价": 13.41,
        "昨收价": 13.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 1124341.0,
        "成交额": 15232858.0,
        "振幅": 3.7,
        "换手率": null,
        "symbol": "106.GOF"
    },
    {
        "序号": 10809,
        "name": "爱美医疗",
        "最新价": 11.22,
        "涨跌额": -0.25,
        "涨跌幅": -2.18,
        "开盘价": 11.41,
        "最高价": 11.48,
        "最低价": 11.19,
        "昨收价": 11.47,
        "总市值": 3290348454.0,
        "市盈率": -18.95,
        "成交量": 2809785.0,
        "成交额": 31648474.0,
        "振幅": 2.53,
        "换手率": 0.96,
        "symbol": "105.FOLD"
    },
    {
        "序号": 10810,
        "name": "Cytokinetics Inc",
        "最新价": 34.1,
        "涨跌额": -0.76,
        "涨跌幅": -2.18,
        "开盘价": 34.6,
        "最高价": 35.6,
        "最低价": 33.58,
        "昨收价": 34.86,
        "总市值": 3343629363.0,
        "市盈率": -6.35,
        "成交量": 1596250.0,
        "成交额": 54932502.0,
        "振幅": 5.79,
        "换手率": 1.63,
        "symbol": "105.CYTK"
    },
    {
        "序号": 10811,
        "name": "Direxion Daily GOOGL Bull 1.5X ",
        "最新价": 30.49,
        "涨跌额": -0.68,
        "涨跌幅": -2.18,
        "开盘价": 30.25,
        "最高价": 30.91,
        "最低价": 30.18,
        "昨收价": 31.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 193755.0,
        "成交额": 5904670.0,
        "振幅": 2.34,
        "换手率": null,
        "symbol": "105.GGLL"
    },
    {
        "序号": 10812,
        "name": "Sensus Healthcare Inc",
        "最新价": 2.24,
        "涨跌额": -0.05,
        "涨跌幅": -2.18,
        "开盘价": 2.26,
        "最高价": 2.35,
        "最低价": 2.23,
        "昨收价": 2.29,
        "总市值": 36696585.0,
        "市盈率": -40.96,
        "成交量": 20517.0,
        "成交额": 46855.0,
        "振幅": 5.24,
        "换手率": 0.13,
        "symbol": "105.SRTS"
    },
    {
        "序号": 10813,
        "name": "Cemig ADR",
        "最新价": 3.13,
        "涨跌额": -0.07,
        "涨跌幅": -2.19,
        "开盘价": 3.11,
        "最高价": 3.15,
        "最低价": 3.09,
        "昨收价": 3.2,
        "总市值": 2303203063.0,
        "市盈率": 0.01,
        "成交量": 22067.0,
        "成交额": 68845.0,
        "振幅": 1.88,
        "换手率": 0.0,
        "symbol": "106.CIG_C"
    },
    {
        "序号": 10814,
        "name": "二倍做空油气ETF-ProShares",
        "最新价": 12.5,
        "涨跌额": -0.28,
        "涨跌幅": -2.19,
        "开盘价": 12.61,
        "最高价": 12.65,
        "最低价": 12.42,
        "昨收价": 12.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 135966.0,
        "成交额": 1701637.0,
        "振幅": 1.8,
        "换手率": null,
        "symbol": "107.DUG"
    },
    {
        "序号": 10815,
        "name": "优信",
        "最新价": 0.89,
        "涨跌额": -0.02,
        "涨跌幅": -2.2,
        "开盘价": 0.88,
        "最高价": 0.91,
        "最低价": 0.87,
        "昨收价": 0.91,
        "总市值": 41947045.0,
        "市盈率": -0.9,
        "成交量": 183703.0,
        "成交额": 163299.0,
        "振幅": 4.4,
        "换手率": 0.39,
        "symbol": "105.UXIN"
    },
    {
        "序号": 10816,
        "name": "宏力型钢",
        "最新价": 1.78,
        "涨跌额": -0.04,
        "涨跌幅": -2.2,
        "开盘价": 1.92,
        "最高价": 2.17,
        "最低价": 1.68,
        "昨收价": 1.82,
        "总市值": 22021938.0,
        "市盈率": 11.83,
        "成交量": 203133.0,
        "成交额": 374811.0,
        "振幅": 26.92,
        "换手率": 1.64,
        "symbol": "105.HLP"
    },
    {
        "序号": 10817,
        "name": "赛安诺科技",
        "最新价": 0.89,
        "涨跌额": -0.02,
        "涨跌幅": -2.2,
        "开盘价": 0.89,
        "最高价": 0.89,
        "最低价": 0.89,
        "昨收价": 0.91,
        "总市值": 5773074.0,
        "市盈率": -1.38,
        "成交量": 439.0,
        "成交额": 390.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.CYAN"
    },
    {
        "序号": 10818,
        "name": "Terns Pharmaceuticals Inc",
        "最新价": 6.66,
        "涨跌额": -0.15,
        "涨跌幅": -2.2,
        "开盘价": 6.8,
        "最高价": 6.91,
        "最低价": 6.45,
        "昨收价": 6.81,
        "总市值": 411551517.0,
        "市盈率": -4.84,
        "成交量": 438656.0,
        "成交额": 2931998.0,
        "振幅": 6.75,
        "换手率": 0.71,
        "symbol": "105.TERN"
    },
    {
        "序号": 10819,
        "name": "金田",
        "最新价": 14.09,
        "涨跌额": -0.32,
        "涨跌幅": -2.22,
        "开盘价": 14.05,
        "最高价": 14.28,
        "最低价": 13.91,
        "昨收价": 14.41,
        "总市值": 12589990055.0,
        "市盈率": 19.1,
        "成交量": 4208362.0,
        "成交额": 59381801.0,
        "振幅": 2.57,
        "换手率": 0.47,
        "symbol": "106.GFI"
    },
    {
        "序号": 10820,
        "name": "X4制药",
        "最新价": 0.88,
        "涨跌额": -0.02,
        "涨跌幅": -2.22,
        "开盘价": 0.92,
        "最高价": 0.94,
        "最低价": 0.88,
        "昨收价": 0.9,
        "总市值": 147216264.0,
        "市盈率": -1.32,
        "成交量": 1954415.0,
        "成交额": 1771163.0,
        "振幅": 6.67,
        "换手率": 1.17,
        "symbol": "105.XFOR"
    },
    {
        "序号": 10821,
        "name": "PharmaCyte Biotech Inc",
        "最新价": 2.2,
        "涨跌额": -0.05,
        "涨跌幅": -2.22,
        "开盘价": 2.28,
        "最高价": 2.29,
        "最低价": 2.18,
        "昨收价": 2.25,
        "总市值": 19311822.0,
        "市盈率": -3.24,
        "成交量": 3468.0,
        "成交额": 7716.0,
        "振幅": 4.89,
        "换手率": 0.04,
        "symbol": "105.PMCB"
    },
    {
        "序号": 10822,
        "name": "Orion Energy Systems Inc",
        "最新价": 0.88,
        "涨跌额": -0.02,
        "涨跌幅": -2.22,
        "开盘价": 0.9,
        "最高价": 0.91,
        "最低价": 0.88,
        "昨收价": 0.9,
        "总市值": 28610754.0,
        "市盈率": -0.71,
        "成交量": 110252.0,
        "成交额": 99029.0,
        "振幅": 3.33,
        "换手率": 0.34,
        "symbol": "105.OESX"
    },
    {
        "序号": 10823,
        "name": "简普科技",
        "最新价": 0.88,
        "涨跌额": -0.02,
        "涨跌幅": -2.22,
        "开盘价": 0.9,
        "最高价": 0.98,
        "最低价": 0.88,
        "昨收价": 0.9,
        "总市值": 18675711.0,
        "市盈率": -2.64,
        "成交量": 4959.0,
        "成交额": 4493.0,
        "振幅": 11.11,
        "换手率": 0.02,
        "symbol": "106.JT"
    },
    {
        "序号": 10824,
        "name": "InnSuites Hospitality Trust",
        "最新价": 1.32,
        "涨跌额": -0.03,
        "涨跌幅": -2.22,
        "开盘价": 1.33,
        "最高价": 1.35,
        "最低价": 1.32,
        "昨收价": 1.35,
        "总市值": 11887664.0,
        "市盈率": 26.49,
        "成交量": 1525.0,
        "成交额": 2019.0,
        "振幅": 2.22,
        "换手率": 0.02,
        "symbol": "107.IHT"
    },
    {
        "序号": 10825,
        "name": "SolarEdge Technologies Inc",
        "最新价": 78.4,
        "涨跌额": -1.79,
        "涨跌幅": -2.23,
        "开盘价": 79.99,
        "最高价": 81.4,
        "最低价": 76.4,
        "昨收价": 80.19,
        "总市值": 4454000354.0,
        "市盈率": 20.47,
        "成交量": 2643695.0,
        "成交额": 207381232.0,
        "振幅": 6.24,
        "换手率": 4.65,
        "symbol": "105.SEDG"
    },
    {
        "序号": 10826,
        "name": "W. P. Carey Inc",
        "最新价": 62.56,
        "涨跌额": -1.43,
        "涨跌幅": -2.23,
        "开盘价": 63.58,
        "最高价": 63.93,
        "最低价": 62.36,
        "昨收价": 63.99,
        "总市值": 13680112437.0,
        "市盈率": 17.68,
        "成交量": 2346584.0,
        "成交额": 147147450.0,
        "振幅": 2.45,
        "换手率": 1.07,
        "symbol": "106.WPC"
    },
    {
        "序号": 10827,
        "name": "契诺物流",
        "最新价": 41.78,
        "涨跌额": -0.96,
        "涨跌幅": -2.25,
        "开盘价": 42.29,
        "最高价": 42.68,
        "最低价": 41.68,
        "昨收价": 42.74,
        "总市值": 540914588.0,
        "市盈率": 10.03,
        "成交量": 36024.0,
        "成交额": 1514814.0,
        "振幅": 2.34,
        "换手率": 0.28,
        "symbol": "105.CVLG"
    },
    {
        "序号": 10828,
        "name": "Ascent Solar Technologies Inc",
        "最新价": 0.87,
        "涨跌额": -0.02,
        "涨跌幅": -2.25,
        "开盘价": 0.89,
        "最高价": 0.91,
        "最低价": 0.85,
        "昨收价": 0.89,
        "总市值": 2963849.0,
        "市盈率": -0.17,
        "成交量": 27299.0,
        "成交额": 23702.0,
        "振幅": 6.74,
        "换手率": 0.8,
        "symbol": "105.ASTI"
    },
    {
        "序号": 10829,
        "name": "神经分泌生物科学",
        "最新价": 117.28,
        "涨跌额": -2.72,
        "涨跌幅": -2.27,
        "开盘价": 119.73,
        "最高价": 120.32,
        "最低价": 117.23,
        "昨收价": 120.0,
        "总市值": 11522985529.0,
        "市盈率": 60.33,
        "成交量": 528392.0,
        "成交额": 62349836.0,
        "振幅": 2.58,
        "换手率": 0.54,
        "symbol": "105.NBIX"
    },
    {
        "序号": 10830,
        "name": "Triton International Ltd Series",
        "最新价": 18.96,
        "涨跌额": -0.44,
        "涨跌幅": -2.27,
        "开盘价": 19.16,
        "最高价": 19.16,
        "最低价": 18.87,
        "昨收价": 19.4,
        "总市值": 132720000.0,
        "市盈率": null,
        "成交量": 15720.0,
        "成交额": 299359.0,
        "振幅": 1.49,
        "换手率": 0.22,
        "symbol": "106.TRTN_E"
    },
    {
        "序号": 10831,
        "name": "SurgePays Inc",
        "最新价": 5.6,
        "涨跌额": -0.13,
        "涨跌幅": -2.27,
        "开盘价": 5.75,
        "最高价": 5.83,
        "最低价": 5.57,
        "昨收价": 5.73,
        "总市值": 79677931.0,
        "市盈率": 3.86,
        "成交量": 241752.0,
        "成交额": 1374245.0,
        "振幅": 4.54,
        "换手率": 1.7,
        "symbol": "105.SURG"
    },
    {
        "序号": 10832,
        "name": "Outlook Therapeutics Inc",
        "最新价": 0.43,
        "涨跌额": -0.01,
        "涨跌幅": -2.27,
        "开盘价": 0.44,
        "最高价": 0.44,
        "最低价": 0.42,
        "昨收价": 0.44,
        "总市值": 111905357.0,
        "市盈率": -1.85,
        "成交量": 927902.0,
        "成交额": 396906.0,
        "振幅": 4.55,
        "换手率": 0.36,
        "symbol": "105.OTLK"
    },
    {
        "序号": 10833,
        "name": "MultiPlan Corp-A",
        "最新价": 1.29,
        "涨跌额": -0.03,
        "涨跌幅": -2.27,
        "开盘价": 1.31,
        "最高价": 1.34,
        "最低价": 1.28,
        "昨收价": 1.32,
        "总市值": 837837269.0,
        "市盈率": -1.18,
        "成交量": 774942.0,
        "成交额": 1011912.0,
        "振幅": 4.55,
        "换手率": 0.12,
        "symbol": "106.MPLN"
    },
    {
        "序号": 10834,
        "name": "Indaptus Therapeutics Inc",
        "最新价": 2.15,
        "涨跌额": -0.05,
        "涨跌幅": -2.27,
        "开盘价": 2.19,
        "最高价": 2.24,
        "最低价": 2.15,
        "昨收价": 2.2,
        "总市值": 18062251.0,
        "市盈率": -1.2,
        "成交量": 5207.0,
        "成交额": 11487.0,
        "振幅": 4.09,
        "换手率": 0.06,
        "symbol": "105.INDP"
    },
    {
        "序号": 10835,
        "name": "勒莫国航",
        "最新价": 3.44,
        "涨跌额": -0.08,
        "涨跌幅": -2.27,
        "开盘价": 3.47,
        "最高价": 3.55,
        "最低价": 3.43,
        "昨收价": 3.52,
        "总市值": 5505035275.0,
        "市盈率": 202.15,
        "成交量": 265339.0,
        "成交额": 920408.0,
        "振幅": 3.41,
        "换手率": 0.02,
        "symbol": "106.GOL"
    },
    {
        "序号": 10836,
        "name": "Electra Battery Materials Corp",
        "最新价": 0.43,
        "涨跌额": -0.01,
        "涨跌幅": -2.27,
        "开盘价": 0.46,
        "最高价": 0.46,
        "最低价": 0.42,
        "昨收价": 0.44,
        "总市值": 24011771.0,
        "市盈率": -3.58,
        "成交量": 140557.0,
        "成交额": 61632.0,
        "振幅": 9.09,
        "换手率": 0.25,
        "symbol": "105.ELBM"
    },
    {
        "序号": 10837,
        "name": "AGBA Group Holding Ltd",
        "最新价": 0.43,
        "涨跌额": -0.01,
        "涨跌幅": -2.27,
        "开盘价": 0.45,
        "最高价": 0.45,
        "最低价": 0.41,
        "昨收价": 0.44,
        "总市值": 29051659.0,
        "市盈率": -0.44,
        "成交量": 66578.0,
        "成交额": 28093.0,
        "振幅": 9.09,
        "换手率": 0.1,
        "symbol": "105.AGBA"
    },
    {
        "序号": 10838,
        "name": "Chicken Soup for the Soul Enter",
        "最新价": 17.18,
        "涨跌额": -0.4,
        "涨跌幅": -2.28,
        "开盘价": 17.25,
        "最高价": 17.75,
        "最低价": 17.0,
        "昨收价": 17.58,
        "总市值": null,
        "市盈率": null,
        "成交量": 2402.0,
        "成交额": 41290.0,
        "振幅": 4.27,
        "换手率": null,
        "symbol": "105.CSSEN"
    },
    {
        "序号": 10839,
        "name": "Nubia Brand International Corp-",
        "最新价": 10.7,
        "涨跌额": -0.25,
        "涨跌幅": -2.28,
        "开盘价": 10.7,
        "最高价": 10.7,
        "最低价": 10.7,
        "昨收价": 10.95,
        "总市值": 76297602.0,
        "市盈率": 63.69,
        "成交量": 221.0,
        "成交额": 2364.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.NUBI"
    },
    {
        "序号": 10840,
        "name": "ETFMG U.S. Alternative Harvest ",
        "最新价": 1.71,
        "涨跌额": -0.04,
        "涨跌幅": -2.29,
        "开盘价": 1.73,
        "最高价": 1.74,
        "最低价": 1.71,
        "昨收价": 1.75,
        "总市值": null,
        "市盈率": null,
        "成交量": 3890.0,
        "成交额": 6706.0,
        "振幅": 1.71,
        "换手率": null,
        "symbol": "107.MJUS"
    },
    {
        "序号": 10841,
        "name": "福斯尔",
        "最新价": 1.28,
        "涨跌额": -0.03,
        "涨跌幅": -2.29,
        "开盘价": 1.27,
        "最高价": 1.34,
        "最低价": 1.26,
        "昨收价": 1.31,
        "总市值": 67171249.0,
        "市盈率": -0.49,
        "成交量": 704679.0,
        "成交额": 921477.0,
        "振幅": 6.11,
        "换手率": 1.34,
        "symbol": "105.FOSL"
    },
    {
        "序号": 10842,
        "name": "Cuentas Inc",
        "最新价": 1.28,
        "涨跌额": -0.03,
        "涨跌幅": -2.29,
        "开盘价": 1.37,
        "最高价": 1.37,
        "最低价": 1.21,
        "昨收价": 1.31,
        "总市值": 3494474.0,
        "市盈率": -0.36,
        "成交量": 50235.0,
        "成交额": 64187.0,
        "振幅": 12.21,
        "换手率": 1.84,
        "symbol": "105.CUEN"
    },
    {
        "序号": 10843,
        "name": "Reviva Pharmaceuticals Holdings",
        "最新价": 0.85,
        "涨跌额": -0.02,
        "涨跌幅": -2.3,
        "开盘价": 0.9,
        "最高价": 0.96,
        "最低价": 0.77,
        "昨收价": 0.87,
        "总市值": null,
        "市盈率": null,
        "成交量": 19382.0,
        "成交额": 16286.0,
        "振幅": 21.84,
        "换手率": null,
        "symbol": "105.RVPHW"
    },
    {
        "序号": 10844,
        "name": "Direxion Daily Semiconductor Be",
        "最新价": 8.07,
        "涨跌额": -0.19,
        "涨跌幅": -2.3,
        "开盘价": 8.33,
        "最高价": 8.34,
        "最低价": 7.95,
        "昨收价": 8.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 77853405.0,
        "成交额": 631539296.0,
        "振幅": 4.72,
        "换手率": null,
        "symbol": "107.SOXS"
    },
    {
        "序号": 10845,
        "name": "Hoth Therapeutics Inc",
        "最新价": 1.27,
        "涨跌额": -0.03,
        "涨跌幅": -2.31,
        "开盘价": 1.3,
        "最高价": 1.35,
        "最低价": 1.22,
        "昨收价": 1.3,
        "总市值": 5519976.0,
        "市盈率": -0.57,
        "成交量": 40990.0,
        "成交额": 51404.0,
        "振幅": 10.0,
        "换手率": 0.94,
        "symbol": "105.HOTH"
    },
    {
        "序号": 10846,
        "name": "先进能源工业",
        "最新价": 96.32,
        "涨跌额": -2.28,
        "涨跌幅": -2.31,
        "开盘价": 98.69,
        "最高价": 100.24,
        "最低价": 96.21,
        "昨收价": 98.6,
        "总市值": 3590872882.0,
        "市盈率": 26.69,
        "成交量": 451666.0,
        "成交额": 43906173.0,
        "振幅": 4.09,
        "换手率": 1.21,
        "symbol": "105.AEIS"
    },
    {
        "序号": 10847,
        "name": "KraneShares European Carbon All",
        "最新价": 25.34,
        "涨跌额": -0.6,
        "涨跌幅": -2.31,
        "开盘价": 25.5,
        "最高价": 25.5,
        "最低价": 25.22,
        "昨收价": 25.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 4577.0,
        "成交额": 115965.0,
        "振幅": 1.08,
        "换手率": null,
        "symbol": "107.KEUA"
    },
    {
        "序号": 10848,
        "name": "Uniti Group Inc",
        "最新价": 5.49,
        "涨跌额": -0.13,
        "涨跌幅": -2.31,
        "开盘价": 5.6,
        "最高价": 5.65,
        "最低价": 5.43,
        "昨收价": 5.62,
        "总市值": 1310309840.0,
        "市盈率": -39.12,
        "成交量": 1450028.0,
        "成交额": 8023306.0,
        "振幅": 3.91,
        "换手率": 0.61,
        "symbol": "105.UNIT"
    },
    {
        "序号": 10849,
        "name": "Virtus Stone Harbor Emerging Ma",
        "最新价": 5.06,
        "涨跌额": -0.12,
        "涨跌幅": -2.32,
        "开盘价": 5.1,
        "最高价": 5.11,
        "最低价": 5.05,
        "昨收价": 5.18,
        "总市值": 48642559.0,
        "市盈率": null,
        "成交量": 27916.0,
        "成交额": 141805.0,
        "振幅": 1.16,
        "换手率": 0.29,
        "symbol": "106.EDI"
    },
    {
        "序号": 10850,
        "name": "Ultragenyx Pharmaceutical Inc",
        "最新价": 40.46,
        "涨跌额": -0.96,
        "涨跌幅": -2.32,
        "开盘价": 42.39,
        "最高价": 42.42,
        "最低价": 40.34,
        "昨收价": 41.42,
        "总市值": 3322346601.0,
        "市盈率": -5.23,
        "成交量": 604216.0,
        "成交额": 24566042.0,
        "振幅": 5.02,
        "换手率": 0.74,
        "symbol": "105.RARE"
    },
    {
        "序号": 10851,
        "name": "LL Flooring Holdings Inc",
        "最新价": 3.37,
        "涨跌额": -0.08,
        "涨跌幅": -2.32,
        "开盘价": 3.43,
        "最高价": 3.45,
        "最低价": 3.36,
        "昨收价": 3.45,
        "总市值": 104007474.0,
        "市盈率": -1.03,
        "成交量": 81804.0,
        "成交额": 279246.0,
        "振幅": 2.61,
        "换手率": 0.27,
        "symbol": "106.LL"
    },
    {
        "序号": 10852,
        "name": "Trilogy Metals Inc",
        "最新价": 0.42,
        "涨跌额": -0.01,
        "涨跌幅": -2.33,
        "开盘价": 0.43,
        "最高价": 0.43,
        "最低价": 0.42,
        "昨收价": 0.43,
        "总市值": 65471214.0,
        "市盈率": -3.82,
        "成交量": 204078.0,
        "成交额": 86669.0,
        "振幅": 2.33,
        "换手率": 0.13,
        "symbol": "107.TMQ"
    },
    {
        "序号": 10853,
        "name": "Porch Group Inc",
        "最新价": 2.1,
        "涨跌额": -0.05,
        "涨跌幅": -2.33,
        "开盘价": 2.15,
        "最高价": 2.17,
        "最低价": 2.04,
        "昨收价": 2.15,
        "总市值": 207636694.0,
        "市盈率": -1.24,
        "成交量": 1384243.0,
        "成交额": 2903079.0,
        "振幅": 6.05,
        "换手率": 1.4,
        "symbol": "105.PRCH"
    },
    {
        "序号": 10854,
        "name": "Aquestive Therapeutics Inc",
        "最新价": 2.1,
        "涨跌额": -0.05,
        "涨跌幅": -2.33,
        "开盘价": 2.13,
        "最高价": 2.24,
        "最低价": 2.08,
        "昨收价": 2.15,
        "总市值": 140211294.0,
        "市盈率": -11.58,
        "成交量": 351909.0,
        "成交额": 754623.0,
        "振幅": 7.44,
        "换手率": 0.53,
        "symbol": "105.AQST"
    },
    {
        "序号": 10855,
        "name": "Envista Holdings Corp",
        "最新价": 23.45,
        "涨跌额": -0.56,
        "涨跌幅": -2.33,
        "开盘价": 23.91,
        "最高价": 23.91,
        "最低价": 23.19,
        "昨收价": 24.01,
        "总市值": 4019385577.0,
        "市盈率": 21.08,
        "成交量": 3795533.0,
        "成交额": 89165971.0,
        "振幅": 3.0,
        "换手率": 2.21,
        "symbol": "106.NVST"
    },
    {
        "序号": 10856,
        "name": "Brookfield Infrastructure Corp ",
        "最新价": 15.86,
        "涨跌额": -0.38,
        "涨跌幅": -2.34,
        "开盘价": 16.1,
        "最高价": 16.5,
        "最低价": 15.86,
        "昨收价": 16.24,
        "总市值": null,
        "市盈率": null,
        "成交量": 10939.0,
        "成交额": 176072.0,
        "振幅": 3.94,
        "换手率": null,
        "symbol": "106.BIPH"
    },
    {
        "序号": 10857,
        "name": "Psychemedics Corp",
        "最新价": 2.92,
        "涨跌额": -0.07,
        "涨跌幅": -2.34,
        "开盘价": 2.99,
        "最高价": 2.99,
        "最低价": 2.91,
        "昨收价": 2.99,
        "总市值": 16941142.0,
        "市盈率": -4.35,
        "成交量": 2236.0,
        "成交额": 6585.0,
        "振幅": 2.68,
        "换手率": 0.04,
        "symbol": "105.PMD"
    },
    {
        "序号": 10858,
        "name": "五点控股",
        "最新价": 2.5,
        "涨跌额": -0.06,
        "涨跌幅": -2.34,
        "开盘价": 2.56,
        "最高价": 2.58,
        "最低价": 2.48,
        "昨收价": 2.56,
        "总市值": 371083705.0,
        "市盈率": 10.05,
        "成交量": 128625.0,
        "成交额": 325206.0,
        "振幅": 3.91,
        "换手率": 0.09,
        "symbol": "106.FPH"
    },
    {
        "序号": 10859,
        "name": "Shoals Technologies Group Inc-A",
        "最新价": 13.29,
        "涨跌额": -0.32,
        "涨跌幅": -2.35,
        "开盘价": 13.58,
        "最高价": 13.65,
        "最低价": 13.13,
        "昨收价": 13.61,
        "总市值": 2260064109.0,
        "市盈率": 16.62,
        "成交量": 4248889.0,
        "成交额": 56470236.0,
        "振幅": 3.82,
        "换手率": 2.5,
        "symbol": "105.SHLS"
    },
    {
        "序号": 10860,
        "name": "Vicinity Motor Corp",
        "最新价": 0.83,
        "涨跌额": -0.02,
        "涨跌幅": -2.35,
        "开盘价": 0.86,
        "最高价": 0.86,
        "最低价": 0.83,
        "昨收价": 0.85,
        "总市值": 37904196.0,
        "市盈率": -3.34,
        "成交量": 28491.0,
        "成交额": 23827.0,
        "振幅": 3.53,
        "换手率": 0.06,
        "symbol": "105.VEV"
    },
    {
        "序号": 10861,
        "name": "Caribou Biosciences Inc",
        "最新价": 5.81,
        "涨跌额": -0.14,
        "涨跌幅": -2.35,
        "开盘价": 5.95,
        "最高价": 6.17,
        "最低价": 5.76,
        "昨收价": 5.95,
        "总市值": 513784900.0,
        "市盈率": -5.43,
        "成交量": 1300648.0,
        "成交额": 7690792.0,
        "振幅": 6.89,
        "换手率": 1.47,
        "symbol": "105.CRBU"
    },
    {
        "序号": 10862,
        "name": "Arrowhead Pharmaceuticals Inc",
        "最新价": 23.95,
        "涨跌额": -0.58,
        "涨跌幅": -2.36,
        "开盘价": 24.44,
        "最高价": 25.1,
        "最低价": 23.92,
        "昨收价": 24.53,
        "总市值": 2573001430.0,
        "市盈率": -12.53,
        "成交量": 913008.0,
        "成交额": 22100737.0,
        "振幅": 4.81,
        "换手率": 0.85,
        "symbol": "105.ARWR"
    },
    {
        "序号": 10863,
        "name": "ProShares UltraShort Semiconduc",
        "最新价": 6.19,
        "涨跌额": -0.15,
        "涨跌幅": -2.37,
        "开盘价": 6.34,
        "最高价": 6.34,
        "最低价": 6.15,
        "昨收价": 6.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 24134.0,
        "成交额": 149466.0,
        "振幅": 3.0,
        "换手率": null,
        "symbol": "107.SSG"
    },
    {
        "序号": 10864,
        "name": "Civeo Corp",
        "最新价": 23.0,
        "涨跌额": -0.56,
        "涨跌幅": -2.38,
        "开盘价": 23.61,
        "最高价": 23.82,
        "最低价": 22.67,
        "昨收价": 23.56,
        "总市值": 339155838.0,
        "市盈率": -60.93,
        "成交量": 61603.0,
        "成交额": 1422979.0,
        "振幅": 4.88,
        "换手率": 0.42,
        "symbol": "106.CVEO"
    },
    {
        "序号": 10865,
        "name": "新氧",
        "最新价": 1.23,
        "涨跌额": -0.03,
        "涨跌幅": -2.38,
        "开盘价": 1.25,
        "最高价": 1.25,
        "最低价": 1.2,
        "昨收价": 1.26,
        "总市值": 121112392.0,
        "市盈率": 25.49,
        "成交量": 66987.0,
        "成交额": 82543.0,
        "振幅": 3.97,
        "换手率": 0.07,
        "symbol": "105.SY"
    },
    {
        "序号": 10866,
        "name": "Marpai Inc-A",
        "最新价": 2.05,
        "涨跌额": -0.05,
        "涨跌幅": -2.38,
        "开盘价": 2.08,
        "最高价": 2.1,
        "最低价": 2.0,
        "昨收价": 2.1,
        "总市值": 16011781.0,
        "市盈率": -0.5,
        "成交量": 88438.0,
        "成交额": 179593.0,
        "振幅": 4.76,
        "换手率": 1.13,
        "symbol": "105.MRAI"
    },
    {
        "序号": 10867,
        "name": "Cybin Inc",
        "最新价": 0.41,
        "涨跌额": -0.01,
        "涨跌幅": -2.38,
        "开盘价": 0.41,
        "最高价": 0.42,
        "最低价": 0.4,
        "昨收价": 0.42,
        "总市值": 166423392.0,
        "市盈率": -4.39,
        "成交量": 2833795.0,
        "成交额": 1160639.0,
        "振幅": 4.76,
        "换手率": 0.7,
        "symbol": "107.CYBN"
    },
    {
        "序号": 10868,
        "name": "Global Net Lease Inc Series B P",
        "最新价": 18.74,
        "涨跌额": -0.46,
        "涨跌幅": -2.4,
        "开盘价": 19.2,
        "最高价": 19.44,
        "最低价": 18.55,
        "昨收价": 19.2,
        "总市值": 88000922.0,
        "市盈率": null,
        "成交量": 12617.0,
        "成交额": 239013.0,
        "振幅": 4.64,
        "换手率": 0.27,
        "symbol": "106.GNL_B"
    },
    {
        "序号": 10869,
        "name": "必乐透",
        "最新价": 6.11,
        "涨跌额": -0.15,
        "涨跌幅": -2.4,
        "开盘价": 6.21,
        "最高价": 6.59,
        "最低价": 6.06,
        "昨收价": 6.26,
        "总市值": 178513157.0,
        "市盈率": -0.39,
        "成交量": 1711126.0,
        "成交额": 10762421.0,
        "振幅": 8.47,
        "换手率": 5.86,
        "symbol": "106.BIG"
    },
    {
        "序号": 10870,
        "name": "Urgently Inc",
        "最新价": 3.66,
        "涨跌额": -0.09,
        "涨跌幅": -2.4,
        "开盘价": 3.75,
        "最高价": 3.95,
        "最低价": 3.47,
        "昨收价": 3.75,
        "总市值": 48069946.0,
        "市盈率": -0.62,
        "成交量": 61008.0,
        "成交额": 227438.0,
        "振幅": 12.8,
        "换手率": 0.46,
        "symbol": "105.ULY"
    },
    {
        "序号": 10871,
        "name": "RiverNorth Flexible Municipal I",
        "最新价": 15.02,
        "涨跌额": -0.37,
        "涨跌幅": -2.4,
        "开盘价": 15.32,
        "最高价": 15.34,
        "最低价": 15.02,
        "昨收价": 15.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 20380.0,
        "成交额": 308582.0,
        "振幅": 2.08,
        "换手率": null,
        "symbol": "106.RFM"
    },
    {
        "序号": 10872,
        "name": "Digital Brands Group Inc",
        "最新价": 4.45,
        "涨跌额": -0.11,
        "涨跌幅": -2.41,
        "开盘价": 4.75,
        "最高价": 4.76,
        "最低价": 4.25,
        "昨收价": 4.56,
        "总市值": 3817473.0,
        "市盈率": -0.17,
        "成交量": 86053.0,
        "成交额": 392713.0,
        "振幅": 11.18,
        "换手率": 10.03,
        "symbol": "105.DBGI"
    },
    {
        "序号": 10873,
        "name": "埃及ETF-VanEck Vectors",
        "最新价": 25.47,
        "涨跌额": -0.63,
        "涨跌幅": -2.41,
        "开盘价": 25.59,
        "最高价": 25.9,
        "最低价": 25.25,
        "昨收价": 26.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 3307.0,
        "成交额": 84200.0,
        "振幅": 2.49,
        "换手率": null,
        "symbol": "107.EGPT"
    },
    {
        "序号": 10874,
        "name": "Casey’s General Stores Inc",
        "最新价": 268.01,
        "涨跌额": -6.64,
        "涨跌幅": -2.42,
        "开盘价": 271.01,
        "最高价": 272.41,
        "最低价": 267.87,
        "昨收价": 274.65,
        "总市值": 9987831114.0,
        "市盈率": 21.57,
        "成交量": 343541.0,
        "成交额": 92412657.0,
        "振幅": 1.65,
        "换手率": 0.92,
        "symbol": "105.CASY"
    },
    {
        "序号": 10875,
        "name": "伊格尔矿业",
        "最新价": 51.24,
        "涨跌额": -1.27,
        "涨跌幅": -2.42,
        "开盘价": 51.51,
        "最高价": 52.29,
        "最低价": 50.96,
        "昨收价": 52.51,
        "总市值": 25418294970.0,
        "市盈率": 10.1,
        "成交量": 3092947.0,
        "成交额": 159386369.0,
        "振幅": 2.53,
        "换手率": 0.62,
        "symbol": "106.AEM"
    },
    {
        "序号": 10876,
        "name": "Viridian Therapeutics Inc",
        "最新价": 18.14,
        "涨跌额": -0.45,
        "涨跌幅": -2.42,
        "开盘价": 18.58,
        "最高价": 18.82,
        "最低价": 18.12,
        "昨收价": 18.59,
        "总市值": 953910747.0,
        "市盈率": -4.4,
        "成交量": 696741.0,
        "成交额": 12755544.0,
        "振幅": 3.77,
        "换手率": 1.32,
        "symbol": "105.VRDN"
    },
    {
        "序号": 10877,
        "name": "DocGo Inc",
        "最新价": 5.24,
        "涨跌额": -0.13,
        "涨跌幅": -2.42,
        "开盘价": 5.37,
        "最高价": 5.38,
        "最低价": 5.21,
        "昨收价": 5.37,
        "总市值": 544416764.0,
        "市盈率": 74.04,
        "成交量": 882683.0,
        "成交额": 4641189.0,
        "振幅": 3.17,
        "换手率": 0.85,
        "symbol": "105.DCGO"
    },
    {
        "序号": 10878,
        "name": "F&G Annuities & Life Inc",
        "最新价": 43.84,
        "涨跌额": -1.09,
        "涨跌幅": -2.43,
        "开盘价": 44.95,
        "最高价": 46.39,
        "最低价": 43.39,
        "昨收价": 44.93,
        "总市值": 5501777301.0,
        "市盈率": -61.82,
        "成交量": 217190.0,
        "成交额": 9642466.0,
        "振幅": 6.68,
        "换手率": 0.17,
        "symbol": "106.FG"
    },
    {
        "序号": 10879,
        "name": "Enanta Pharmaceuticals Inc",
        "最新价": 9.25,
        "涨跌额": -0.23,
        "涨跌幅": -2.43,
        "开盘价": 9.44,
        "最高价": 9.57,
        "最低价": 9.15,
        "昨收价": 9.48,
        "总市值": 194795056.0,
        "市盈率": -1.46,
        "成交量": 154352.0,
        "成交额": 1442473.0,
        "振幅": 4.43,
        "换手率": 0.73,
        "symbol": "105.ENTA"
    },
    {
        "序号": 10880,
        "name": "AXS 1.25X NVDA Bear Daily ETF",
        "最新价": 34.98,
        "涨跌额": -0.87,
        "涨跌幅": -2.43,
        "开盘价": 35.87,
        "最高价": 35.88,
        "最低价": 34.77,
        "昨收价": 35.85,
        "总市值": null,
        "市盈率": null,
        "成交量": 528292.0,
        "成交额": 18530902.0,
        "振幅": 3.1,
        "换手率": null,
        "symbol": "105.NVDS"
    },
    {
        "序号": 10881,
        "name": "雾芯科技",
        "最新价": 2.01,
        "涨跌额": -0.05,
        "涨跌幅": -2.43,
        "开盘价": 2.05,
        "最高价": 2.09,
        "最低价": 1.98,
        "昨收价": 2.06,
        "总市值": 3157289046.0,
        "市盈率": 198.77,
        "成交量": 9539002.0,
        "成交额": 19180039.0,
        "振幅": 5.34,
        "换手率": 0.61,
        "symbol": "106.RLX"
    },
    {
        "序号": 10882,
        "name": "百济神州",
        "最新价": 175.0,
        "涨跌额": -4.36,
        "涨跌幅": -2.43,
        "开盘价": 177.0,
        "最高价": 178.38,
        "最低价": 174.0,
        "昨收价": 179.36,
        "总市值": 18228347496.0,
        "市盈率": -19.0,
        "成交量": 238529.0,
        "成交额": 41859727.0,
        "振幅": 2.44,
        "换手率": 0.23,
        "symbol": "105.BGNE"
    },
    {
        "序号": 10883,
        "name": "二倍做空每日标普油气出口与生产",
        "最新价": 12.41,
        "涨跌额": -0.31,
        "涨跌幅": -2.44,
        "开盘价": 12.48,
        "最高价": 12.59,
        "最低价": 12.28,
        "昨收价": 12.72,
        "总市值": null,
        "市盈率": null,
        "成交量": 1595445.0,
        "成交额": 19853619.0,
        "振幅": 2.44,
        "换手率": null,
        "symbol": "107.DRIP"
    },
    {
        "序号": 10884,
        "name": "Universal Security Instruments ",
        "最新价": 2.0,
        "涨跌额": -0.05,
        "涨跌幅": -2.44,
        "开盘价": 2.05,
        "最高价": 2.09,
        "最低价": 2.0,
        "昨收价": 2.05,
        "总市值": 4625774.0,
        "市盈率": 7.65,
        "成交量": 19848.0,
        "成交额": 40342.0,
        "振幅": 4.39,
        "换手率": 0.86,
        "symbol": "107.UUU"
    },
    {
        "序号": 10885,
        "name": "途牛",
        "最新价": 0.8,
        "涨跌额": -0.02,
        "涨跌幅": -2.44,
        "开盘价": 0.82,
        "最高价": 0.83,
        "最低价": 0.8,
        "昨收价": 0.82,
        "总市值": 99039615.0,
        "市盈率": -26.27,
        "成交量": 290615.0,
        "成交额": 235278.0,
        "振幅": 3.66,
        "换手率": 0.23,
        "symbol": "105.TOUR"
    },
    {
        "序号": 10886,
        "name": "QVC Inc Notes",
        "最新价": 10.4,
        "涨跌额": -0.26,
        "涨跌幅": -2.44,
        "开盘价": 10.6,
        "最高价": 10.75,
        "最低价": 10.28,
        "昨收价": 10.66,
        "总市值": null,
        "市盈率": null,
        "成交量": 19483.0,
        "成交额": 204702.0,
        "振幅": 4.41,
        "换手率": null,
        "symbol": "106.QVCD"
    },
    {
        "序号": 10887,
        "name": "OpGen Inc",
        "最新价": 0.4,
        "涨跌额": -0.01,
        "涨跌幅": -2.44,
        "开盘价": 0.41,
        "最高价": 0.42,
        "最低价": 0.4,
        "昨收价": 0.41,
        "总市值": 4005410.0,
        "市盈率": -0.15,
        "成交量": 174792.0,
        "成交额": 70651.0,
        "振幅": 4.88,
        "换手率": 1.75,
        "symbol": "105.OPGN"
    },
    {
        "序号": 10888,
        "name": "Precision BioSciences Inc",
        "最新价": 0.4,
        "涨跌额": -0.01,
        "涨跌幅": -2.44,
        "开盘价": 0.4,
        "最高价": 0.41,
        "最低价": 0.39,
        "昨收价": 0.41,
        "总市值": 48767532.0,
        "市盈率": -0.66,
        "成交量": 376093.0,
        "成交额": 151380.0,
        "振幅": 4.88,
        "换手率": 0.31,
        "symbol": "105.DTIL"
    },
    {
        "序号": 10889,
        "name": "American Battery Technology Co",
        "最新价": 4.0,
        "涨跌额": -0.1,
        "涨跌幅": -2.44,
        "开盘价": 4.11,
        "最高价": 4.21,
        "最低价": 4.0,
        "昨收价": 4.1,
        "总市值": 191027404.0,
        "市盈率": -7.31,
        "成交量": 480487.0,
        "成交额": 1968976.0,
        "振幅": 5.12,
        "换手率": 1.01,
        "symbol": "105.ABAT"
    },
    {
        "序号": 10890,
        "name": "inTEST Corp",
        "最新价": 13.16,
        "涨跌额": -0.33,
        "涨跌幅": -2.45,
        "开盘价": 13.45,
        "最高价": 13.5,
        "最低价": 13.05,
        "昨收价": 13.49,
        "总市值": 160042866.0,
        "市盈率": 13.54,
        "成交量": 134544.0,
        "成交额": 1780017.0,
        "振幅": 3.34,
        "换手率": 1.11,
        "symbol": "107.INTT"
    },
    {
        "序号": 10891,
        "name": "San Juan Basin Royalty Trust",
        "最新价": 5.97,
        "涨跌额": -0.15,
        "涨跌幅": -2.45,
        "开盘价": 6.1,
        "最高价": 6.18,
        "最低价": 5.96,
        "昨收价": 6.12,
        "总市值": 278254512.0,
        "市盈率": 3.62,
        "成交量": 386079.0,
        "成交额": 2328659.0,
        "振幅": 3.59,
        "换手率": 0.83,
        "symbol": "106.SJT"
    },
    {
        "序号": 10892,
        "name": "曼恩凯德生物医疗",
        "最新价": 3.58,
        "涨跌额": -0.09,
        "涨跌幅": -2.45,
        "开盘价": 3.66,
        "最高价": 3.7,
        "最低价": 3.57,
        "昨收价": 3.67,
        "总市值": 965077261.0,
        "市盈率": -30.85,
        "成交量": 2215159.0,
        "成交额": 8029252.0,
        "振幅": 3.54,
        "换手率": 0.82,
        "symbol": "105.MNKD"
    },
    {
        "序号": 10893,
        "name": "Core Molding Technologies Inc",
        "最新价": 18.65,
        "涨跌额": -0.47,
        "涨跌幅": -2.46,
        "开盘价": 19.1,
        "最高价": 19.13,
        "最低价": 18.36,
        "昨收价": 19.12,
        "总市值": 168609130.0,
        "市盈率": 7.34,
        "成交量": 123288.0,
        "成交额": 2308990.0,
        "振幅": 4.03,
        "换手率": 1.36,
        "symbol": "107.CMT"
    },
    {
        "序号": 10894,
        "name": "American Well Corp-A",
        "最新价": 1.19,
        "涨跌额": -0.03,
        "涨跌幅": -2.46,
        "开盘价": 1.21,
        "最高价": 1.23,
        "最低价": 1.16,
        "昨收价": 1.22,
        "总市值": 342207391.0,
        "市盈率": -0.5,
        "成交量": 1402129.0,
        "成交额": 1654432.0,
        "振幅": 5.74,
        "换手率": 0.49,
        "symbol": "106.AMWL"
    },
    {
        "序号": 10895,
        "name": "MacroGenics Inc",
        "最新价": 8.71,
        "涨跌额": -0.22,
        "涨跌幅": -2.46,
        "开盘价": 8.84,
        "最高价": 9.1,
        "最低价": 8.66,
        "昨收价": 8.93,
        "总市值": 540276483.0,
        "市盈率": 10.85,
        "成交量": 379432.0,
        "成交额": 3343954.0,
        "振幅": 4.93,
        "换手率": 0.61,
        "symbol": "105.MGNX"
    },
    {
        "序号": 10896,
        "name": "Espey Mfg & Electronics Corp",
        "最新价": 17.42,
        "涨跌额": -0.44,
        "涨跌幅": -2.46,
        "开盘价": 17.92,
        "最高价": 18.12,
        "最低价": 17.31,
        "昨收价": 17.86,
        "总市值": 47149547.0,
        "市盈率": 11.78,
        "成交量": 12016.0,
        "成交额": 212931.0,
        "振幅": 4.54,
        "换手率": 0.44,
        "symbol": "107.ESP"
    },
    {
        "序号": 10897,
        "name": "Aldeyra Therapeutics Inc",
        "最新价": 3.16,
        "涨跌额": -0.08,
        "涨跌幅": -2.47,
        "开盘价": 3.22,
        "最高价": 3.34,
        "最低价": 3.15,
        "昨收价": 3.24,
        "总市值": 185877782.0,
        "市盈率": -4.07,
        "成交量": 792883.0,
        "成交额": 2545389.0,
        "振幅": 5.86,
        "换手率": 1.35,
        "symbol": "105.ALDX"
    },
    {
        "序号": 10898,
        "name": "Mammoth Energy Services Inc",
        "最新价": 4.73,
        "涨跌额": -0.12,
        "涨跌幅": -2.47,
        "开盘价": 4.89,
        "最高价": 4.95,
        "最低价": 4.66,
        "昨收价": 4.85,
        "总市值": 226764014.0,
        "市盈率": 29.99,
        "成交量": 58467.0,
        "成交额": 279763.0,
        "振幅": 5.98,
        "换手率": 0.12,
        "symbol": "105.TUSK"
    },
    {
        "序号": 10899,
        "name": "水滴",
        "最新价": 1.18,
        "涨跌额": -0.03,
        "涨跌幅": -2.48,
        "开盘价": 1.18,
        "最高价": 1.23,
        "最低价": 1.15,
        "昨收价": 1.21,
        "总市值": 450408444.0,
        "市盈率": 13.05,
        "成交量": 151676.0,
        "成交额": 180997.0,
        "振幅": 6.61,
        "换手率": 0.04,
        "symbol": "106.WDH"
    },
    {
        "序号": 10900,
        "name": "MicroSectors FANG & Innovation ",
        "最新价": 4.72,
        "涨跌额": -0.12,
        "涨跌幅": -2.48,
        "开盘价": 4.92,
        "最高价": 4.92,
        "最低价": 4.72,
        "昨收价": 4.84,
        "总市值": 3402691240.0,
        "市盈率": 1.07,
        "成交量": 588800.0,
        "成交额": 2818033.0,
        "振幅": 4.13,
        "换手率": null,
        "symbol": "107.BERZ"
    },
    {
        "序号": 10901,
        "name": "Shenandoah Telecommunications C",
        "最新价": 20.82,
        "涨跌额": -0.53,
        "涨跌幅": -2.48,
        "开盘价": 21.29,
        "最高价": 21.46,
        "最低价": 20.46,
        "昨收价": 21.35,
        "总市值": 1046506182.0,
        "市盈率": 288.61,
        "成交量": 139702.0,
        "成交额": 2903740.0,
        "振幅": 4.68,
        "换手率": 0.28,
        "symbol": "105.SHEN"
    },
    {
        "序号": 10902,
        "name": "Direxion Technology Bear 3X Sha",
        "最新价": 10.97,
        "涨跌额": -0.28,
        "涨跌幅": -2.49,
        "开盘价": 11.35,
        "最高价": 11.36,
        "最低价": 10.94,
        "昨收价": 11.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 4819350.0,
        "成交额": 53489874.0,
        "振幅": 3.73,
        "换手率": null,
        "symbol": "107.TECS"
    },
    {
        "序号": 10903,
        "name": "Associated Banc-Corp Series F P",
        "最新价": 18.02,
        "涨跌额": -0.46,
        "涨跌幅": -2.49,
        "开盘价": 18.33,
        "最高价": 18.33,
        "最低价": 18.02,
        "昨收价": 18.48,
        "总市值": 1802000.0,
        "市盈率": null,
        "成交量": 2919.0,
        "成交额": 53082.0,
        "振幅": 1.68,
        "换手率": 2.92,
        "symbol": "106.ASB_F"
    },
    {
        "序号": 10904,
        "name": "MAX Auto Industry -3x Inverse L",
        "最新价": 21.93,
        "涨跌额": -0.56,
        "涨跌幅": -2.49,
        "开盘价": 21.93,
        "最高价": 21.93,
        "最低价": 21.93,
        "昨收价": 22.49,
        "总市值": 15809537901.0,
        "市盈率": 4.98,
        "成交量": 110.0,
        "成交额": 2411.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CARD"
    },
    {
        "序号": 10905,
        "name": "巴里克黄金",
        "最新价": 16.79,
        "涨跌额": -0.43,
        "涨跌幅": -2.5,
        "开盘价": 16.87,
        "最高价": 17.03,
        "最低价": 16.67,
        "昨收价": 17.22,
        "总市值": 29475229222.0,
        "市盈率": 508.19,
        "成交量": 18257387.0,
        "成交额": 307180480.0,
        "振幅": 2.09,
        "换手率": 1.04,
        "symbol": "106.GOLD"
    },
    {
        "序号": 10906,
        "name": "二倍做多黄金ETN-DB",
        "最新价": 41.35,
        "涨跌额": -1.06,
        "涨跌幅": -2.5,
        "开盘价": 41.45,
        "最高价": 41.86,
        "最低价": 40.99,
        "昨收价": 42.41,
        "总市值": 83084555000.0,
        "市盈率": 13.85,
        "成交量": 9951.0,
        "成交额": 411730.0,
        "振幅": 2.05,
        "换手率": null,
        "symbol": "107.DGP"
    },
    {
        "序号": 10907,
        "name": "众巢医学",
        "最新价": 0.78,
        "涨跌额": -0.02,
        "涨跌幅": -2.5,
        "开盘价": 0.78,
        "最高价": 0.78,
        "最低价": 0.78,
        "昨收价": 0.8,
        "总市值": 19249675.0,
        "市盈率": -6.55,
        "成交量": 2195.0,
        "成交额": 1710.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.ZCMD"
    },
    {
        "序号": 10908,
        "name": "吉来特卫星网络",
        "最新价": 6.63,
        "涨跌额": -0.17,
        "涨跌幅": -2.5,
        "开盘价": 6.57,
        "最高价": 6.73,
        "最低价": 6.55,
        "昨收价": 6.8,
        "总市值": 375401659.0,
        "市盈率": 26.74,
        "成交量": 95954.0,
        "成交额": 638469.0,
        "振幅": 2.65,
        "换手率": 0.17,
        "symbol": "105.GILT"
    },
    {
        "序号": 10909,
        "name": "医道国际",
        "最新价": 0.39,
        "涨跌额": -0.01,
        "涨跌幅": -2.5,
        "开盘价": 0.38,
        "最高价": 0.39,
        "最低价": 0.36,
        "昨收价": 0.4,
        "总市值": 39953882.0,
        "市盈率": null,
        "成交量": 116799.0,
        "成交额": 44275.0,
        "振幅": 7.5,
        "换手率": 0.11,
        "symbol": "105.ETAO"
    },
    {
        "序号": 10910,
        "name": "Digital World Acquisition Corp ",
        "最新价": 5.84,
        "涨跌额": -0.15,
        "涨跌幅": -2.5,
        "开盘价": 5.99,
        "最高价": 5.99,
        "最低价": 5.65,
        "昨收价": 5.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 9741.0,
        "成交额": 56775.0,
        "振幅": 5.68,
        "换手率": null,
        "symbol": "105.DWACW"
    },
    {
        "序号": 10911,
        "name": "Farmer Bros Co",
        "最新价": 3.11,
        "涨跌额": -0.08,
        "涨跌幅": -2.51,
        "开盘价": 3.12,
        "最高价": 3.21,
        "最低价": 3.1,
        "昨收价": 3.19,
        "总市值": 64527117.0,
        "市盈率": -0.88,
        "成交量": 26768.0,
        "成交额": 84244.0,
        "振幅": 3.45,
        "换手率": 0.13,
        "symbol": "105.FARM"
    },
    {
        "序号": 10912,
        "name": "EzFill Holdings Inc",
        "最新价": 1.94,
        "涨跌额": -0.05,
        "涨跌幅": -2.51,
        "开盘价": 1.76,
        "最高价": 1.94,
        "最低价": 1.76,
        "昨收价": 1.99,
        "总市值": 8713570.0,
        "市盈率": -0.65,
        "成交量": 1422.0,
        "成交额": 2605.0,
        "振幅": 9.05,
        "换手率": 0.03,
        "symbol": "105.EZFL"
    },
    {
        "序号": 10913,
        "name": "Maxeon Solar Technologies Ltd",
        "最新价": 4.26,
        "涨跌额": -0.11,
        "涨跌幅": -2.52,
        "开盘价": 4.33,
        "最高价": 4.49,
        "最低价": 4.04,
        "昨收价": 4.37,
        "总市值": 224843022.0,
        "市盈率": -1.36,
        "成交量": 2690487.0,
        "成交额": 11353227.0,
        "振幅": 10.3,
        "换手率": 5.1,
        "symbol": "105.MAXN"
    },
    {
        "序号": 10914,
        "name": "Bluerock Homes Trust Inc-A",
        "最新价": 14.69,
        "涨跌额": -0.38,
        "涨跌幅": -2.52,
        "开盘价": 15.0,
        "最高价": 15.25,
        "最低价": 14.41,
        "昨收价": 15.07,
        "总市值": 56993586.0,
        "市盈率": -14.46,
        "成交量": 12171.0,
        "成交额": 181323.0,
        "振幅": 5.57,
        "换手率": 0.31,
        "symbol": "107.BHM"
    },
    {
        "序号": 10915,
        "name": "Ambipar Emergency Response-A",
        "最新价": 6.55,
        "涨跌额": -0.17,
        "涨跌幅": -2.53,
        "开盘价": 6.99,
        "最高价": 6.99,
        "最低价": 6.55,
        "昨收价": 6.72,
        "总市值": 363065524.0,
        "市盈率": 106.91,
        "成交量": 2275.0,
        "成交额": 15761.0,
        "振幅": 6.55,
        "换手率": 0.0,
        "symbol": "107.AMBI"
    },
    {
        "序号": 10916,
        "name": "燃石医学",
        "最新价": 0.77,
        "涨跌额": -0.02,
        "涨跌幅": -2.53,
        "开盘价": 0.78,
        "最高价": 0.8,
        "最低价": 0.76,
        "昨收价": 0.79,
        "总市值": 79060052.0,
        "市盈率": -0.78,
        "成交量": 28736.0,
        "成交额": 22500.0,
        "振幅": 5.06,
        "换手率": 0.03,
        "symbol": "105.BNR"
    },
    {
        "序号": 10917,
        "name": "Bionexus Gene Lab Corp",
        "最新价": 0.77,
        "涨跌额": -0.02,
        "涨跌幅": -2.53,
        "开盘价": 0.77,
        "最高价": 0.85,
        "最低价": 0.75,
        "昨收价": 0.79,
        "总市值": 13604101.0,
        "市盈率": -4.4,
        "成交量": 331597.0,
        "成交额": 266223.0,
        "振幅": 12.66,
        "换手率": 1.88,
        "symbol": "105.BGLC"
    },
    {
        "序号": 10918,
        "name": "Qwest Corp Notes",
        "最新价": 9.99,
        "涨跌额": -0.26,
        "涨跌幅": -2.54,
        "开盘价": 10.15,
        "最高价": 10.18,
        "最低价": 9.92,
        "昨收价": 10.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 192186.0,
        "成交额": 1939770.0,
        "振幅": 2.54,
        "换手率": null,
        "symbol": "106.CTBB"
    },
    {
        "序号": 10919,
        "name": "市民金融控股",
        "最新价": 9.55,
        "涨跌额": -0.25,
        "涨跌幅": -2.55,
        "开盘价": 9.75,
        "最高价": 9.8,
        "最低价": 9.55,
        "昨收价": 9.8,
        "总市值": 53636983.0,
        "市盈率": 10.5,
        "成交量": 9886.0,
        "成交额": 96319.0,
        "振幅": 2.55,
        "换手率": 0.18,
        "symbol": "105.CIZN"
    },
    {
        "序号": 10920,
        "name": "Intellia Therapeutics Inc",
        "最新价": 29.02,
        "涨跌额": -0.76,
        "涨跌幅": -2.55,
        "开盘价": 29.53,
        "最高价": 30.56,
        "最低价": 28.85,
        "昨收价": 29.78,
        "总市值": 2598882937.0,
        "市盈率": -5.62,
        "成交量": 1252674.0,
        "成交额": 36840554.0,
        "振幅": 5.74,
        "换手率": 1.4,
        "symbol": "105.NTLA"
    },
    {
        "序号": 10921,
        "name": "Transcat Inc",
        "最新价": 96.58,
        "涨跌额": -2.53,
        "涨跌幅": -2.55,
        "开盘价": 98.2,
        "最高价": 100.2,
        "最低价": 96.32,
        "昨收价": 99.11,
        "总市值": 852446372.0,
        "市盈率": 98.34,
        "成交量": 51725.0,
        "成交额": 5065941.0,
        "振幅": 3.91,
        "换手率": 0.59,
        "symbol": "105.TRNS"
    },
    {
        "序号": 10922,
        "name": "Vista Outdoor Inc",
        "最新价": 27.02,
        "涨跌额": -0.71,
        "涨跌幅": -2.56,
        "开盘价": 27.59,
        "最高价": 27.84,
        "最低价": 26.92,
        "昨收价": 27.73,
        "总市值": 1569098091.0,
        "市盈率": -12.39,
        "成交量": 576716.0,
        "成交额": 15676015.0,
        "振幅": 3.32,
        "换手率": 0.99,
        "symbol": "106.VSTO"
    },
    {
        "序号": 10923,
        "name": "Telesis Bio Inc",
        "最新价": 0.38,
        "涨跌额": -0.01,
        "涨跌幅": -2.56,
        "开盘价": 0.38,
        "最高价": 0.4,
        "最低价": 0.37,
        "昨收价": 0.39,
        "总市值": 11393809.0,
        "市盈率": -0.3,
        "成交量": 43039.0,
        "成交额": 16127.0,
        "振幅": 7.69,
        "换手率": 0.14,
        "symbol": "105.TBIO"
    },
    {
        "序号": 10924,
        "name": "SatixFy Communications Ltd",
        "最新价": 0.38,
        "涨跌额": -0.01,
        "涨跌幅": -2.56,
        "开盘价": 0.39,
        "最高价": 0.39,
        "最低价": 0.37,
        "昨收价": 0.39,
        "总市值": 30689365.0,
        "市盈率": -0.07,
        "成交量": 83433.0,
        "成交额": 31538.0,
        "振幅": 5.13,
        "换手率": 0.1,
        "symbol": "107.SATX"
    },
    {
        "序号": 10925,
        "name": "LogicMark Inc",
        "最新价": 1.14,
        "涨跌额": -0.03,
        "涨跌幅": -2.56,
        "开盘价": 1.16,
        "最高价": 1.17,
        "最低价": 1.06,
        "昨收价": 1.17,
        "总市值": 1617679.0,
        "市盈率": -0.2,
        "成交量": 96846.0,
        "成交额": 109601.0,
        "振幅": 9.4,
        "换手率": 6.82,
        "symbol": "105.LGMK"
    },
    {
        "序号": 10926,
        "name": "埃尔拉多黄金",
        "最新价": 12.49,
        "涨跌额": -0.33,
        "涨跌幅": -2.57,
        "开盘价": 12.61,
        "最高价": 12.8,
        "最低价": 12.24,
        "昨收价": 12.82,
        "总市值": 2533810291.0,
        "市盈率": 45.33,
        "成交量": 2349357.0,
        "成交额": 29314334.0,
        "振幅": 4.37,
        "换手率": 1.16,
        "symbol": "106.EGO"
    },
    {
        "序号": 10927,
        "name": "Longboard Pharmaceuticals Inc",
        "最新价": 4.54,
        "涨跌额": -0.12,
        "涨跌幅": -2.58,
        "开盘价": 4.62,
        "最高价": 4.78,
        "最低价": 4.41,
        "昨收价": 4.66,
        "总市值": 108311910.0,
        "市盈率": -2.13,
        "成交量": 72113.0,
        "成交额": 336338.0,
        "振幅": 7.94,
        "换手率": 0.3,
        "symbol": "105.LBPH"
    },
    {
        "序号": 10928,
        "name": "Southland Holdings Inc",
        "最新价": 5.29,
        "涨跌额": -0.14,
        "涨跌幅": -2.58,
        "开盘价": 5.5,
        "最高价": 5.82,
        "最低价": 5.0,
        "昨收价": 5.43,
        "总市值": 253348595.0,
        "市盈率": -4.8,
        "成交量": 93459.0,
        "成交额": 491505.0,
        "振幅": 15.1,
        "换手率": 0.2,
        "symbol": "107.SLND"
    },
    {
        "序号": 10929,
        "name": "新蛋",
        "最新价": 1.13,
        "涨跌额": -0.03,
        "涨跌幅": -2.59,
        "开盘价": 1.17,
        "最高价": 1.22,
        "最低价": 1.12,
        "昨收价": 1.16,
        "总市值": 428326500.0,
        "市盈率": -6.31,
        "成交量": 838884.0,
        "成交额": 987357.0,
        "振幅": 8.62,
        "换手率": 0.22,
        "symbol": "105.NEGG"
    },
    {
        "序号": 10930,
        "name": "Captivision Inc",
        "最新价": 3.39,
        "涨跌额": -0.09,
        "涨跌幅": -2.59,
        "开盘价": 3.53,
        "最高价": 3.63,
        "最低价": 3.33,
        "昨收价": 3.48,
        "总市值": 97692376.0,
        "市盈率": 17.57,
        "成交量": 332178.0,
        "成交额": 1142026.0,
        "振幅": 8.62,
        "换手率": 1.15,
        "symbol": "105.CAPT"
    },
    {
        "序号": 10931,
        "name": "MicroSectors U.S. Big Oil Index",
        "最新价": 260.55,
        "涨跌额": -6.93,
        "涨跌幅": -2.59,
        "开盘价": 261.0,
        "最高价": 263.47,
        "最低价": 257.3,
        "昨收价": 267.48,
        "总市值": 187832881899.0,
        "市盈率": 59.2,
        "成交量": 27121.0,
        "成交额": 7076497.0,
        "振幅": 2.31,
        "换手率": null,
        "symbol": "107.NRGD"
    },
    {
        "序号": 10932,
        "name": "cbdMD Inc",
        "最新价": 0.75,
        "涨跌额": -0.02,
        "涨跌幅": -2.6,
        "开盘价": 0.79,
        "最高价": 0.81,
        "最低价": 0.75,
        "昨收价": 0.77,
        "总市值": 2141423.0,
        "市盈率": -0.1,
        "成交量": 30517.0,
        "成交额": 23126.0,
        "振幅": 7.79,
        "换手率": 1.07,
        "symbol": "107.YCBD"
    },
    {
        "序号": 10933,
        "name": "三倍做多20年+国债ETF-Direxion",
        "最新价": 56.93,
        "涨跌额": -1.52,
        "涨跌幅": -2.6,
        "开盘价": 56.79,
        "最高价": 57.26,
        "最低价": 55.79,
        "昨收价": 58.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 9397373.0,
        "成交额": 531549952.0,
        "振幅": 2.51,
        "换手率": null,
        "symbol": "107.TMF"
    },
    {
        "序号": 10934,
        "name": "二倍做多黄金ETF-ProShares",
        "最新价": 60.58,
        "涨跌额": -1.62,
        "涨跌幅": -2.6,
        "开盘价": 60.77,
        "最高价": 61.28,
        "最低价": 60.06,
        "昨收价": 62.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 214023.0,
        "成交额": 12967541.0,
        "振幅": 1.96,
        "换手率": null,
        "symbol": "107.UGL"
    },
    {
        "序号": 10935,
        "name": "MicroSectors FANG Index -3X Inv",
        "最新价": 6.73,
        "涨跌额": -0.18,
        "涨跌幅": -2.6,
        "开盘价": 7.03,
        "最高价": 7.03,
        "最低价": 6.7,
        "昨收价": 6.91,
        "总市值": 4851718654.0,
        "市盈率": 1.53,
        "成交量": 7908299.0,
        "成交额": 53969619.0,
        "振幅": 4.78,
        "换手率": null,
        "symbol": "107.FNGD"
    },
    {
        "序号": 10936,
        "name": "Mynaric AG ADR",
        "最新价": 4.86,
        "涨跌额": -0.13,
        "涨跌幅": -2.61,
        "开盘价": 5.1,
        "最高价": 5.1,
        "最低价": 4.76,
        "昨收价": 4.99,
        "总市值": 121181476.0,
        "市盈率": -1.54,
        "成交量": 5576.0,
        "成交额": 27072.0,
        "振幅": 6.81,
        "换手率": 0.02,
        "symbol": "105.MYNA"
    },
    {
        "序号": 10937,
        "name": "AdvisorShares MSOS 2x Daily ETF",
        "最新价": 4.47,
        "涨跌额": -0.12,
        "涨跌幅": -2.61,
        "开盘价": 4.68,
        "最高价": 4.68,
        "最低价": 4.21,
        "昨收价": 4.59,
        "总市值": null,
        "市盈率": null,
        "成交量": 405246.0,
        "成交额": 1814535.0,
        "振幅": 10.24,
        "换手率": null,
        "symbol": "107.MSOX"
    },
    {
        "序号": 10938,
        "name": "Solo Brands Inc-A",
        "最新价": 5.21,
        "涨跌额": -0.14,
        "涨跌幅": -2.62,
        "开盘价": 5.32,
        "最高价": 5.37,
        "最低价": 5.21,
        "昨收价": 5.35,
        "总市值": 473334195.0,
        "市盈率": 19.88,
        "成交量": 282407.0,
        "成交额": 1488040.0,
        "振幅": 2.99,
        "换手率": 0.31,
        "symbol": "106.DTC"
    },
    {
        "序号": 10939,
        "name": "二倍做多FTSE中国50ETF-ProShares",
        "最新价": 12.98,
        "涨跌额": -0.35,
        "涨跌幅": -2.63,
        "开盘价": 13.1,
        "最高价": 13.13,
        "最低价": 12.97,
        "昨收价": 13.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 27126.0,
        "成交额": 352623.0,
        "振幅": 1.2,
        "换手率": null,
        "symbol": "107.XPP"
    },
    {
        "序号": 10940,
        "name": "Protagonist Therapeutics Inc",
        "最新价": 20.36,
        "涨跌额": -0.55,
        "涨跌幅": -2.63,
        "开盘价": 20.82,
        "最高价": 20.88,
        "最低价": 20.18,
        "昨收价": 20.91,
        "总市值": 1174326564.0,
        "市盈率": -8.36,
        "成交量": 686119.0,
        "成交额": 14030886.0,
        "振幅": 3.35,
        "换手率": 1.19,
        "symbol": "105.PTGX"
    },
    {
        "序号": 10941,
        "name": "Telos Corp",
        "最新价": 4.44,
        "涨跌额": -0.12,
        "涨跌幅": -2.63,
        "开盘价": 4.6,
        "最高价": 4.66,
        "最低价": 4.27,
        "昨收价": 4.56,
        "总市值": 309127048.0,
        "市盈率": -7.42,
        "成交量": 513609.0,
        "成交额": 2268631.0,
        "振幅": 8.55,
        "换手率": 0.74,
        "symbol": "105.TLS"
    },
    {
        "序号": 10942,
        "name": "Oatly Group AB ADR",
        "最新价": 1.11,
        "涨跌额": -0.03,
        "涨跌幅": -2.63,
        "开盘价": 1.14,
        "最高价": 1.15,
        "最低价": 1.05,
        "昨收价": 1.14,
        "总市值": 658453110.0,
        "市盈率": -2.71,
        "成交量": 2508847.0,
        "成交额": 2725153.0,
        "振幅": 8.77,
        "换手率": 0.42,
        "symbol": "105.OTLY"
    },
    {
        "序号": 10943,
        "name": "NuCana plc ADR",
        "最新价": 0.37,
        "涨跌额": -0.01,
        "涨跌幅": -2.63,
        "开盘价": 0.35,
        "最高价": 0.37,
        "最低价": 0.35,
        "昨收价": 0.38,
        "总市值": 19533410.0,
        "市盈率": -0.44,
        "成交量": 123948.0,
        "成交额": 44135.0,
        "振幅": 5.26,
        "换手率": 0.23,
        "symbol": "105.NCNA"
    },
    {
        "序号": 10944,
        "name": "Movella Holdings Inc",
        "最新价": 0.37,
        "涨跌额": -0.01,
        "涨跌幅": -2.63,
        "开盘价": 0.38,
        "最高价": 0.4,
        "最低价": 0.36,
        "昨收价": 0.38,
        "总市值": 18835749.0,
        "市盈率": 1.32,
        "成交量": 188985.0,
        "成交额": 69709.0,
        "振幅": 10.53,
        "换手率": 0.37,
        "symbol": "105.MVLA"
    },
    {
        "序号": 10945,
        "name": "Iveda Solutions Inc",
        "最新价": 0.74,
        "涨跌额": -0.02,
        "涨跌幅": -2.63,
        "开盘价": 0.75,
        "最高价": 0.75,
        "最低价": 0.72,
        "昨收价": 0.76,
        "总市值": 11891719.0,
        "市盈率": -3.86,
        "成交量": 21309.0,
        "成交额": 15889.0,
        "振幅": 3.95,
        "换手率": 0.13,
        "symbol": "105.IVDA"
    },
    {
        "序号": 10946,
        "name": "Ambipar Emergency Response Wt",
        "最新价": 0.37,
        "涨跌额": -0.01,
        "涨跌幅": -2.63,
        "开盘价": 0.4,
        "最高价": 0.4,
        "最低价": 0.37,
        "昨收价": 0.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 357.0,
        "成交额": 134.0,
        "振幅": 7.89,
        "换手率": null,
        "symbol": "107.AMBI_WS"
    },
    {
        "序号": 10947,
        "name": "Pool Corp",
        "最新价": 354.12,
        "涨跌额": -9.62,
        "涨跌幅": -2.64,
        "开盘价": 360.25,
        "最高价": 361.87,
        "最低价": 353.51,
        "昨收价": 363.74,
        "总市值": 13697067326.0,
        "市盈率": 25.19,
        "成交量": 331417.0,
        "成交额": 118264563.0,
        "振幅": 2.3,
        "换手率": 0.86,
        "symbol": "105.POOL"
    },
    {
        "序号": 10948,
        "name": "Davis Commodities Ltd",
        "最新价": 1.1,
        "涨跌额": -0.03,
        "涨跌幅": -2.65,
        "开盘价": 1.1,
        "最高价": 1.17,
        "最低价": 1.1,
        "昨收价": 1.13,
        "总市值": 26771250.0,
        "市盈率": 5.8,
        "成交量": 102210.0,
        "成交额": 115124.0,
        "振幅": 6.19,
        "换手率": 0.42,
        "symbol": "105.DTCK"
    },
    {
        "序号": 10949,
        "name": "Electrovaya Inc",
        "最新价": 2.93,
        "涨跌额": -0.08,
        "涨跌幅": -2.66,
        "开盘价": 3.01,
        "最高价": 3.02,
        "最低价": 2.87,
        "昨收价": 3.01,
        "总市值": 96633867.0,
        "市盈率": null,
        "成交量": 6584.0,
        "成交额": 19556.0,
        "振幅": 4.98,
        "换手率": 0.02,
        "symbol": "105.ELVA"
    },
    {
        "序号": 10950,
        "name": "ProShares Short Bitcoin Strateg",
        "最新价": 12.45,
        "涨跌额": -0.34,
        "涨跌幅": -2.66,
        "开盘价": 12.67,
        "最高价": 12.68,
        "最低价": 12.42,
        "昨收价": 12.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 2096770.0,
        "成交额": 26406809.0,
        "振幅": 2.03,
        "换手率": null,
        "symbol": "107.BITI"
    },
    {
        "序号": 10951,
        "name": "Eagle制药",
        "最新价": 5.85,
        "涨跌额": -0.16,
        "涨跌幅": -2.66,
        "开盘价": 6.05,
        "最高价": 6.06,
        "最低价": 5.81,
        "昨收价": 6.01,
        "总市值": 75973780.0,
        "市盈率": 6.36,
        "成交量": 146603.0,
        "成交额": 861682.0,
        "振幅": 4.16,
        "换手率": 1.13,
        "symbol": "105.EGRX"
    },
    {
        "序号": 10952,
        "name": "Biodexa Pharmaceuticals PLC ADR",
        "最新价": 2.92,
        "涨跌额": -0.08,
        "涨跌幅": -2.67,
        "开盘价": 3.56,
        "最高价": 3.56,
        "最低价": 2.77,
        "昨收价": 3.0,
        "总市值": 2777663.0,
        "市盈率": -0.29,
        "成交量": 92107.0,
        "成交额": 281473.0,
        "振幅": 26.33,
        "换手率": 9.68,
        "symbol": "105.BDRX"
    },
    {
        "序号": 10953,
        "name": "Applied UV Inc Series A Pfd",
        "最新价": 2.19,
        "涨跌额": -0.06,
        "涨跌幅": -2.67,
        "开盘价": 2.21,
        "最高价": 2.21,
        "最低价": 2.19,
        "昨收价": 2.25,
        "总市值": 1208880.0,
        "市盈率": null,
        "成交量": 489.0,
        "成交额": 1078.0,
        "振幅": 0.89,
        "换手率": 0.09,
        "symbol": "105.AUVIP"
    },
    {
        "序号": 10954,
        "name": "格拉德斯通商业(优先股)",
        "最新价": 18.6,
        "涨跌额": -0.51,
        "涨跌幅": -2.67,
        "开盘价": 19.13,
        "最高价": 19.13,
        "最低价": 18.48,
        "昨收价": 19.11,
        "总市值": 74400000.0,
        "市盈率": null,
        "成交量": 9788.0,
        "成交额": 184128.0,
        "振幅": 3.4,
        "换手率": 0.24,
        "symbol": "105.GOODO"
    },
    {
        "序号": 10955,
        "name": "Fortuna Silver Mines Inc",
        "最新价": 3.64,
        "涨跌额": -0.1,
        "涨跌幅": -2.67,
        "开盘价": 3.67,
        "最高价": 3.72,
        "最低价": 3.58,
        "昨收价": 3.74,
        "总市值": 1115515419.0,
        "市盈率": -10.02,
        "成交量": 4246010.0,
        "成交额": 15507107.0,
        "振幅": 3.74,
        "换手率": 1.39,
        "symbol": "106.FSM"
    },
    {
        "序号": 10956,
        "name": "Star Equity Holdings Inc",
        "最新价": 1.09,
        "涨跌额": -0.03,
        "涨跌幅": -2.68,
        "开盘价": 1.09,
        "最高价": 1.11,
        "最低价": 1.06,
        "昨收价": 1.12,
        "总市值": 17250577.0,
        "市盈率": 0.68,
        "成交量": 58874.0,
        "成交额": 63517.0,
        "振幅": 4.46,
        "换手率": 0.37,
        "symbol": "105.STRR"
    },
    {
        "序号": 10957,
        "name": "Immunovant Inc",
        "最新价": 39.19,
        "涨跌额": -1.08,
        "涨跌幅": -2.68,
        "开盘价": 39.63,
        "最高价": 40.26,
        "最低价": 38.57,
        "昨收价": 40.27,
        "总市值": 5669301037.0,
        "市盈率": -22.21,
        "成交量": 972778.0,
        "成交额": 38364569.0,
        "振幅": 4.2,
        "换手率": 0.67,
        "symbol": "105.IMVT"
    },
    {
        "序号": 10958,
        "name": "Natura &Co Holding SA ADR",
        "最新价": 6.89,
        "涨跌额": -0.19,
        "涨跌幅": -2.68,
        "开盘价": 6.8,
        "最高价": 6.92,
        "最低价": 6.72,
        "昨收价": 7.08,
        "总市值": 4777691587.0,
        "市盈率": 4.62,
        "成交量": 601584.0,
        "成交额": 4117327.0,
        "振幅": 2.82,
        "换手率": 0.09,
        "symbol": "106.NTCO"
    },
    {
        "序号": 10959,
        "name": "Ocular Therapeutix Inc",
        "最新价": 3.62,
        "涨跌额": -0.1,
        "涨跌幅": -2.69,
        "开盘价": 3.73,
        "最高价": 3.74,
        "最低价": 3.5,
        "昨收价": 3.72,
        "总市值": 287495426.0,
        "市盈率": -4.29,
        "成交量": 1015147.0,
        "成交额": 3650815.0,
        "振幅": 6.45,
        "换手率": 1.28,
        "symbol": "105.OCUL"
    },
    {
        "序号": 10960,
        "name": "Performance Shipping Inc",
        "最新价": 2.17,
        "涨跌额": -0.06,
        "涨跌幅": -2.69,
        "开盘价": 2.23,
        "最高价": 2.28,
        "最低价": 2.09,
        "昨收价": 2.23,
        "总市值": 25464262.0,
        "市盈率": 0.37,
        "成交量": 67710.0,
        "成交额": 150015.0,
        "振幅": 8.52,
        "换手率": 0.58,
        "symbol": "105.PSHG"
    },
    {
        "序号": 10961,
        "name": "PCM Fund",
        "最新价": 7.95,
        "涨跌额": -0.22,
        "涨跌幅": -2.69,
        "开盘价": 8.05,
        "最高价": 8.19,
        "最低价": 7.95,
        "昨收价": 8.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 14793.0,
        "成交额": 118927.0,
        "振幅": 2.94,
        "换手率": null,
        "symbol": "106.PCM"
    },
    {
        "序号": 10962,
        "name": "Greenfire Resources Ltd",
        "最新价": 5.77,
        "涨跌额": -0.16,
        "涨跌幅": -2.7,
        "开盘价": 6.01,
        "最高价": 6.01,
        "最低价": 5.71,
        "昨收价": 5.93,
        "总市值": 396067312.0,
        "市盈率": -4.06,
        "成交量": 6136.0,
        "成交额": 36024.0,
        "振幅": 5.06,
        "换手率": 0.01,
        "symbol": "106.GFR"
    },
    {
        "序号": 10963,
        "name": "Direxion Daily S&P 500 High Bet",
        "最新价": 35.69,
        "涨跌额": -0.99,
        "涨跌幅": -2.7,
        "开盘价": 36.66,
        "最高价": 36.67,
        "最低价": 35.2,
        "昨收价": 36.68,
        "总市值": null,
        "市盈率": null,
        "成交量": 112965.0,
        "成交额": 4023251.0,
        "振幅": 4.01,
        "换手率": null,
        "symbol": "107.HIBS"
    },
    {
        "序号": 10964,
        "name": "Vista Gold Corp",
        "最新价": 0.36,
        "涨跌额": -0.01,
        "涨跌幅": -2.7,
        "开盘价": 0.37,
        "最高价": 0.37,
        "最低价": 0.35,
        "昨收价": 0.37,
        "总市值": 43591858.0,
        "市盈率": -6.79,
        "成交量": 218693.0,
        "成交额": 78708.0,
        "振幅": 5.41,
        "换手率": 0.18,
        "symbol": "107.VGZ"
    },
    {
        "序号": 10965,
        "name": "IM Cannabis Corp",
        "最新价": 0.36,
        "涨跌额": -0.01,
        "涨跌幅": -2.7,
        "开盘价": 0.37,
        "最高价": 0.38,
        "最低价": 0.35,
        "昨收价": 0.37,
        "总市值": 4821889.0,
        "市盈率": -0.16,
        "成交量": 33911.0,
        "成交额": 12543.0,
        "振幅": 8.11,
        "换手率": 0.25,
        "symbol": "105.IMCC"
    },
    {
        "序号": 10966,
        "name": "格伦伯尼万通金控",
        "最新价": 5.4,
        "涨跌额": -0.15,
        "涨跌幅": -2.7,
        "开盘价": 5.5,
        "最高价": 5.51,
        "最低价": 5.4,
        "昨收价": 5.55,
        "总市值": 15536254.0,
        "市盈率": 7.43,
        "成交量": 2690.0,
        "成交额": 14645.0,
        "振幅": 1.98,
        "换手率": 0.09,
        "symbol": "105.GLBZ"
    },
    {
        "序号": 10967,
        "name": "Nyxoah SA",
        "最新价": 4.66,
        "涨跌额": -0.13,
        "涨跌幅": -2.71,
        "开盘价": 4.83,
        "最高价": 4.83,
        "最低价": 4.66,
        "昨收价": 4.79,
        "总市值": 133620770.0,
        "市盈率": -2.68,
        "成交量": 24888.0,
        "成交额": 118357.0,
        "振幅": 3.55,
        "换手率": 0.09,
        "symbol": "105.NYXH"
    },
    {
        "序号": 10968,
        "name": "STRATTEC SECURITY CORP",
        "最新价": 21.11,
        "涨跌额": -0.59,
        "涨跌幅": -2.72,
        "开盘价": 21.56,
        "最高价": 21.76,
        "最低价": 21.01,
        "昨收价": 21.7,
        "总市值": 85890679.0,
        "市盈率": -32.66,
        "成交量": 12295.0,
        "成交额": 264505.0,
        "振幅": 3.46,
        "换手率": 0.3,
        "symbol": "105.STRT"
    },
    {
        "序号": 10969,
        "name": "Flywire Corp",
        "最新价": 21.81,
        "涨跌额": -0.61,
        "涨跌幅": -2.72,
        "开盘价": 22.0,
        "最高价": 22.43,
        "最低价": 21.58,
        "昨收价": 22.42,
        "总市值": 2648021630.0,
        "市盈率": -241.12,
        "成交量": 1696818.0,
        "成交额": 37001676.0,
        "振幅": 3.79,
        "换手率": 1.4,
        "symbol": "105.FLYW"
    },
    {
        "序号": 10970,
        "name": "Nerdy Inc-A",
        "最新价": 2.86,
        "涨跌额": -0.08,
        "涨跌幅": -2.72,
        "开盘价": 2.92,
        "最高价": 2.94,
        "最低价": 2.84,
        "昨收价": 2.94,
        "总市值": 490901185.0,
        "市盈率": -11.35,
        "成交量": 801114.0,
        "成交额": 2314327.0,
        "振幅": 3.4,
        "换手率": 0.47,
        "symbol": "106.NRDY"
    },
    {
        "序号": 10971,
        "name": "Zymeworks Inc",
        "最新价": 9.29,
        "涨跌额": -0.26,
        "涨跌幅": -2.72,
        "开盘价": 9.46,
        "最高价": 9.53,
        "最低价": 9.2,
        "昨收价": 9.55,
        "总市值": 650318459.0,
        "市盈率": 3.49,
        "成交量": 336195.0,
        "成交额": 3132323.0,
        "振幅": 3.46,
        "换手率": 0.48,
        "symbol": "105.ZYME"
    },
    {
        "序号": 10972,
        "name": "羚羊控股",
        "最新价": 2.5,
        "涨跌额": -0.07,
        "涨跌幅": -2.72,
        "开盘价": 2.5,
        "最高价": 2.5,
        "最低价": 2.5,
        "昨收价": 2.57,
        "总市值": 6250000.0,
        "市盈率": 7.4,
        "成交量": 1578.0,
        "成交额": 3945.0,
        "振幅": 0.0,
        "换手率": 0.06,
        "symbol": "105.AEHL"
    },
    {
        "序号": 10973,
        "name": "QVC Inc Notes",
        "最新价": 10.71,
        "涨跌额": -0.3,
        "涨跌幅": -2.72,
        "开盘价": 10.96,
        "最高价": 11.15,
        "最低价": 10.71,
        "昨收价": 11.01,
        "总市值": null,
        "市盈率": null,
        "成交量": 27097.0,
        "成交额": 294789.0,
        "振幅": 4.0,
        "换手率": null,
        "symbol": "106.QVCC"
    },
    {
        "序号": 10974,
        "name": "Grupo Aval Acciones y Valores S",
        "最新价": 2.14,
        "涨跌额": -0.06,
        "涨跌幅": -2.73,
        "开盘价": 2.17,
        "最高价": 2.2,
        "最低价": 2.12,
        "昨收价": 2.2,
        "总市值": 2540551906.0,
        "市盈率": 53.48,
        "成交量": 101319.0,
        "成交额": 217635.0,
        "振幅": 3.64,
        "换手率": 0.01,
        "symbol": "106.AVAL"
    },
    {
        "序号": 10975,
        "name": "J.Jill Inc",
        "最新价": 25.32,
        "涨跌额": -0.71,
        "涨跌幅": -2.73,
        "开盘价": 25.79,
        "最高价": 26.01,
        "最低价": 24.54,
        "昨收价": 26.03,
        "总市值": 268460491.0,
        "市盈率": 8.27,
        "成交量": 149887.0,
        "成交额": 3759160.0,
        "振幅": 5.65,
        "换手率": 1.41,
        "symbol": "106.JILL"
    },
    {
        "序号": 10976,
        "name": "Organon & Co",
        "最新价": 11.02,
        "涨跌额": -0.31,
        "涨跌幅": -2.74,
        "开盘价": 11.35,
        "最高价": 11.5,
        "最低价": 10.96,
        "昨收价": 11.33,
        "总市值": 2816783200.0,
        "市盈率": 4.82,
        "成交量": 3874910.0,
        "成交额": 42987689.0,
        "振幅": 4.77,
        "换手率": 1.52,
        "symbol": "106.OGN"
    },
    {
        "序号": 10977,
        "name": "嘉楠科技",
        "最新价": 1.42,
        "涨跌额": -0.04,
        "涨跌幅": -2.74,
        "开盘价": 1.47,
        "最高价": 1.51,
        "最低价": 1.41,
        "昨收价": 1.46,
        "总市值": 267634195.0,
        "市盈率": -0.95,
        "成交量": 5517343.0,
        "成交额": 8052355.0,
        "振幅": 6.85,
        "换手率": 2.93,
        "symbol": "105.CAN"
    },
    {
        "序号": 10978,
        "name": "Diversified Healthcare Trust",
        "最新价": 2.48,
        "涨跌额": -0.07,
        "涨跌幅": -2.75,
        "开盘价": 2.54,
        "最高价": 2.56,
        "最低价": 2.42,
        "昨收价": 2.55,
        "总市值": 596316866.0,
        "市盈率": -2.33,
        "成交量": 2090713.0,
        "成交额": 5143287.0,
        "振幅": 5.49,
        "换手率": 0.87,
        "symbol": "105.DHC"
    },
    {
        "序号": 10979,
        "name": "Astrotech Corp",
        "最新价": 7.78,
        "涨跌额": -0.22,
        "涨跌幅": -2.75,
        "开盘价": 7.85,
        "最高价": 7.99,
        "最低价": 7.75,
        "昨收价": 8.0,
        "总市值": 13239452.0,
        "市盈率": -1.32,
        "成交量": 4375.0,
        "成交额": 34597.0,
        "振幅": 3.0,
        "换手率": 0.26,
        "symbol": "105.ASTC"
    },
    {
        "序号": 10980,
        "name": "B. Riley Financial Inc Notes",
        "最新价": 15.55,
        "涨跌额": -0.44,
        "涨跌幅": -2.75,
        "开盘价": 15.99,
        "最高价": 15.99,
        "最低价": 15.4,
        "昨收价": 15.99,
        "总市值": null,
        "市盈率": null,
        "成交量": 72909.0,
        "成交额": 1140910.0,
        "振幅": 3.69,
        "换手率": null,
        "symbol": "105.RILYZ"
    },
    {
        "序号": 10981,
        "name": "Alamos Gold Inc-A",
        "最新价": 13.77,
        "涨跌额": -0.39,
        "涨跌幅": -2.75,
        "开盘价": 13.79,
        "最高价": 14.15,
        "最低价": 13.59,
        "昨收价": 14.16,
        "总市值": 5457953954.0,
        "市盈率": 26.82,
        "成交量": 4149275.0,
        "成交额": 57276305.0,
        "振幅": 3.95,
        "换手率": 1.05,
        "symbol": "106.AGI"
    },
    {
        "序号": 10982,
        "name": "Methode Electronics Inc",
        "最新价": 21.52,
        "涨跌额": -0.61,
        "涨跌幅": -2.76,
        "开盘价": 21.57,
        "最高价": 22.04,
        "最低价": 21.3,
        "昨收价": 22.13,
        "总市值": 766099648.0,
        "市盈率": -29.02,
        "成交量": 805828.0,
        "成交额": 17305215.0,
        "振幅": 3.34,
        "换手率": 2.26,
        "symbol": "106.MEI"
    },
    {
        "序号": 10983,
        "name": "SBA通信-A",
        "最新价": 244.18,
        "涨跌额": -6.94,
        "涨跌幅": -2.76,
        "开盘价": 249.12,
        "最高价": 251.08,
        "最低价": 240.11,
        "昨收价": 251.12,
        "总市值": 26343855230.0,
        "市盈率": 53.16,
        "成交量": 1105795.0,
        "成交额": 270030896.0,
        "振幅": 4.37,
        "换手率": 1.02,
        "symbol": "105.SBAC"
    },
    {
        "序号": 10984,
        "name": "Regulus Therapeutics Inc",
        "最新价": 1.4,
        "涨跌额": -0.04,
        "涨跌幅": -2.78,
        "开盘价": 1.44,
        "最高价": 1.44,
        "最低价": 1.35,
        "昨收价": 1.44,
        "总市值": 28311741.0,
        "市盈率": -0.98,
        "成交量": 11999.0,
        "成交额": 16781.0,
        "振幅": 6.25,
        "换手率": 0.06,
        "symbol": "105.RGLS"
    },
    {
        "序号": 10985,
        "name": "Energy Services of America Corp",
        "最新价": 3.85,
        "涨跌额": -0.11,
        "涨跌幅": -2.78,
        "开盘价": 3.98,
        "最高价": 4.04,
        "最低价": 3.85,
        "昨收价": 3.96,
        "总市值": 63783662.0,
        "市盈率": 18.77,
        "成交量": 31909.0,
        "成交额": 125465.0,
        "振幅": 4.8,
        "换手率": 0.19,
        "symbol": "105.ESOA"
    },
    {
        "序号": 10986,
        "name": "Cresud ADR",
        "最新价": 9.1,
        "涨跌额": -0.26,
        "涨跌幅": -2.78,
        "开盘价": 9.3,
        "最高价": 9.34,
        "最低价": 8.94,
        "昨收价": 9.36,
        "总市值": 546027802.0,
        "市盈率": 1.9,
        "成交量": 417458.0,
        "成交额": 3785698.0,
        "振幅": 4.27,
        "换手率": 0.7,
        "symbol": "105.CRESY"
    },
    {
        "序号": 10987,
        "name": "Finch Therapeutics Group Inc",
        "最新价": 3.84,
        "涨跌额": -0.11,
        "涨跌幅": -2.78,
        "开盘价": 3.93,
        "最高价": 4.13,
        "最低价": 3.4,
        "昨收价": 3.95,
        "总市值": 6166130.0,
        "市盈率": -0.06,
        "成交量": 32996.0,
        "成交额": 118986.0,
        "振幅": 18.48,
        "换手率": 2.05,
        "symbol": "105.FNCH"
    },
    {
        "序号": 10988,
        "name": "CaliberCos Inc-A",
        "最新价": 1.39,
        "涨跌额": -0.04,
        "涨跌幅": -2.8,
        "开盘价": 1.42,
        "最高价": 1.45,
        "最低价": 1.35,
        "昨收价": 1.43,
        "总市值": 29578565.0,
        "市盈率": -2.31,
        "成交量": 17625.0,
        "成交额": 24220.0,
        "振幅": 6.99,
        "换手率": 0.08,
        "symbol": "105.CWD"
    },
    {
        "序号": 10989,
        "name": "PSQ Holdings Inc-A",
        "最新价": 5.21,
        "涨跌额": -0.15,
        "涨跌幅": -2.8,
        "开盘价": 5.39,
        "最高价": 5.39,
        "最低价": 4.92,
        "昨收价": 5.36,
        "总市值": 143919753.0,
        "市盈率": -3.88,
        "成交量": 172443.0,
        "成交额": 887207.0,
        "振幅": 8.77,
        "换手率": 0.62,
        "symbol": "106.PSQH"
    },
    {
        "序号": 10990,
        "name": "Lineage Cell Therapeutics Inc",
        "最新价": 1.04,
        "涨跌额": -0.03,
        "涨跌幅": -2.8,
        "开盘价": 1.07,
        "最高价": 1.1,
        "最低价": 1.02,
        "昨收价": 1.07,
        "总市值": 181986138.0,
        "市盈率": -7.89,
        "成交量": 536021.0,
        "成交额": 567452.0,
        "振幅": 7.48,
        "换手率": 0.31,
        "symbol": "107.LCTX"
    },
    {
        "序号": 10991,
        "name": "Aris Mining Corp",
        "最新价": 3.12,
        "涨跌额": -0.09,
        "涨跌幅": -2.8,
        "开盘价": 3.13,
        "最高价": 3.23,
        "最低价": 3.05,
        "昨收价": 3.21,
        "总市值": 428114762.0,
        "市盈率": null,
        "成交量": 203477.0,
        "成交额": 635748.0,
        "振幅": 5.61,
        "换手率": 0.15,
        "symbol": "107.ARMN"
    },
    {
        "序号": 10992,
        "name": "诺福克南方",
        "最新价": 221.0,
        "涨跌额": -6.39,
        "涨跌幅": -2.81,
        "开盘价": 227.46,
        "最高价": 227.64,
        "最低价": 220.87,
        "昨收价": 227.39,
        "总市值": 49976129814.0,
        "市盈率": 23.91,
        "成交量": 1480611.0,
        "成交额": 329292320.0,
        "振幅": 2.98,
        "换手率": 0.65,
        "symbol": "106.NSC"
    },
    {
        "序号": 10993,
        "name": "Safe and Green Development Corp",
        "最新价": 1.72,
        "涨跌额": -0.05,
        "涨跌幅": -2.82,
        "开盘价": 1.78,
        "最高价": 1.8,
        "最低价": 1.68,
        "昨收价": 1.77,
        "总市值": 17200000.0,
        "市盈率": -4.81,
        "成交量": 306737.0,
        "成交额": 534258.0,
        "振幅": 6.78,
        "换手率": 3.07,
        "symbol": "105.SGD"
    },
    {
        "序号": 10994,
        "name": "Cocrystal Pharma Inc",
        "最新价": 1.72,
        "涨跌额": -0.05,
        "涨跌幅": -2.82,
        "开盘价": 1.8,
        "最高价": 1.8,
        "最低价": 1.71,
        "昨收价": 1.77,
        "总市值": 17498919.0,
        "市盈率": -0.97,
        "成交量": 8063.0,
        "成交额": 14093.0,
        "振幅": 5.08,
        "换手率": 0.08,
        "symbol": "105.COCP"
    },
    {
        "序号": 10995,
        "name": "Invesco Bond Fund",
        "最新价": 15.46,
        "涨跌额": -0.45,
        "涨跌幅": -2.83,
        "开盘价": 15.77,
        "最高价": 15.96,
        "最低价": 15.26,
        "昨收价": 15.91,
        "总市值": null,
        "市盈率": null,
        "成交量": 40106.0,
        "成交额": 624747.0,
        "振幅": 4.4,
        "换手率": null,
        "symbol": "106.VBF"
    },
    {
        "序号": 10996,
        "name": "Sprott Physical Silver Trust",
        "最新价": 7.85,
        "涨跌额": -0.23,
        "涨跌幅": -2.85,
        "开盘价": 7.96,
        "最高价": 8.03,
        "最低价": 7.81,
        "昨收价": 8.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 4462858.0,
        "成交额": 35205946.0,
        "振幅": 2.72,
        "换手率": null,
        "symbol": "107.PSLV"
    },
    {
        "序号": 10997,
        "name": "艾格里不动产",
        "最新价": 58.89,
        "涨跌额": -1.73,
        "涨跌幅": -2.85,
        "开盘价": 60.36,
        "最高价": 60.57,
        "最低价": 58.81,
        "昨收价": 60.62,
        "总市值": 5919606134.0,
        "市盈率": 35.89,
        "成交量": 1226096.0,
        "成交额": 72554671.0,
        "振幅": 2.9,
        "换手率": 1.22,
        "symbol": "106.ADC"
    },
    {
        "序号": 10998,
        "name": "NightHawk Biosciences Inc",
        "最新价": 0.34,
        "涨跌额": -0.01,
        "涨跌幅": -2.86,
        "开盘价": 0.34,
        "最高价": 0.36,
        "最低价": 0.31,
        "昨收价": 0.35,
        "总市值": 8867843.0,
        "市盈率": -0.16,
        "成交量": 116053.0,
        "成交额": 37751.0,
        "振幅": 14.29,
        "换手率": 0.44,
        "symbol": "107.NHWK"
    },
    {
        "序号": 10999,
        "name": "美第奇新星生物技术",
        "最新价": 1.7,
        "涨跌额": -0.05,
        "涨跌幅": -2.86,
        "开盘价": 1.74,
        "最高价": 1.77,
        "最低价": 1.7,
        "昨收价": 1.75,
        "总市值": 83378618.0,
        "市盈率": -8.76,
        "成交量": 21961.0,
        "成交额": 37936.0,
        "振幅": 4.0,
        "换手率": 0.04,
        "symbol": "105.MNOV"
    },
    {
        "序号": 11000,
        "name": "ClearSign Technologies Corp",
        "最新价": 1.02,
        "涨跌额": -0.03,
        "涨跌幅": -2.86,
        "开盘价": 1.05,
        "最高价": 1.08,
        "最低价": 1.02,
        "昨收价": 1.05,
        "总市值": 39337153.0,
        "市盈率": -7.08,
        "成交量": 24543.0,
        "成交额": 25992.0,
        "振幅": 5.71,
        "换手率": 0.06,
        "symbol": "105.CLIR"
    },
    {
        "序号": 11001,
        "name": "Gatos Silver Inc",
        "最新价": 5.76,
        "涨跌额": -0.17,
        "涨跌幅": -2.87,
        "开盘价": 5.81,
        "最高价": 5.97,
        "最低价": 5.72,
        "昨收价": 5.93,
        "总市值": 398374404.0,
        "市盈率": 75.54,
        "成交量": 211728.0,
        "成交额": 1227086.0,
        "振幅": 4.22,
        "换手率": 0.31,
        "symbol": "106.GATO"
    },
    {
        "序号": 11002,
        "name": "Credit Suisse X-Links Silver Sh",
        "最新价": 72.06,
        "涨跌额": -2.13,
        "涨跌幅": -2.87,
        "开盘价": 73.1,
        "最高价": 73.25,
        "最低价": 71.82,
        "昨收价": 74.19,
        "总市值": null,
        "市盈率": null,
        "成交量": 19132.0,
        "成交额": 1386091.0,
        "振幅": 1.93,
        "换手率": null,
        "symbol": "105.SLVO"
    },
    {
        "序号": 11003,
        "name": "Kymera Therapeutics Inc",
        "最新价": 23.0,
        "涨跌额": -0.68,
        "涨跌幅": -2.87,
        "开盘价": 23.55,
        "最高价": 25.19,
        "最低价": 22.8,
        "昨收价": 23.68,
        "总市值": 1276209579.0,
        "市盈率": -7.62,
        "成交量": 1797823.0,
        "成交额": 42576552.0,
        "振幅": 10.09,
        "换手率": 3.24,
        "symbol": "105.KYMR"
    },
    {
        "序号": 11004,
        "name": "MicroSectors Travel -3X Inverse",
        "最新价": 3.72,
        "涨跌额": -0.11,
        "涨跌幅": -2.87,
        "开盘价": 3.87,
        "最高价": 3.87,
        "最低价": 3.72,
        "昨收价": 3.83,
        "总市值": 2681782079.0,
        "市盈率": 0.85,
        "成交量": 61826.0,
        "成交额": 231169.0,
        "振幅": 3.92,
        "换手率": null,
        "symbol": "107.FLYD"
    },
    {
        "序号": 11005,
        "name": "Sprott Physical Platinum & Pall",
        "最新价": 9.13,
        "涨跌额": -0.27,
        "涨跌幅": -2.87,
        "开盘价": 9.41,
        "最高价": 9.43,
        "最低价": 9.06,
        "昨收价": 9.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 139335.0,
        "成交额": 1283885.0,
        "振幅": 3.94,
        "换手率": null,
        "symbol": "107.SPPP"
    },
    {
        "序号": 11006,
        "name": "泛美白银",
        "最新价": 14.87,
        "涨跌额": -0.44,
        "涨跌幅": -2.87,
        "开盘价": 15.13,
        "最高价": 15.21,
        "最低价": 14.8,
        "昨收价": 15.31,
        "总市值": 5419207930.0,
        "市盈率": -24.09,
        "成交量": 2193603.0,
        "成交额": 32784061.0,
        "振幅": 2.68,
        "换手率": 0.6,
        "symbol": "106.PAAS"
    },
    {
        "序号": 11007,
        "name": "库珀医疗",
        "最新价": 335.0,
        "涨跌额": -9.95,
        "涨跌幅": -2.88,
        "开盘价": 344.0,
        "最高价": 346.01,
        "最低价": 330.77,
        "昨收价": 344.95,
        "总市值": 16591203970.0,
        "市盈率": 56.39,
        "成交量": 577801.0,
        "成交额": 195046998.0,
        "振幅": 4.42,
        "换手率": 1.17,
        "symbol": "105.COO"
    },
    {
        "序号": 11008,
        "name": "Arcellx Inc",
        "最新价": 56.22,
        "涨跌额": -1.67,
        "涨跌幅": -2.88,
        "开盘价": 57.89,
        "最高价": 59.09,
        "最低价": 56.19,
        "昨收价": 57.89,
        "总市值": 2737137433.0,
        "市盈率": -21.13,
        "成交量": 1293401.0,
        "成交额": 74891040.0,
        "振幅": 5.01,
        "换手率": 2.66,
        "symbol": "105.ACLX"
    },
    {
        "序号": 11009,
        "name": "Kamada Ltd",
        "最新价": 5.72,
        "涨跌额": -0.17,
        "涨跌幅": -2.89,
        "开盘价": 5.85,
        "最高价": 5.85,
        "最低价": 5.7,
        "昨收价": 5.89,
        "总市值": 328764396.0,
        "市盈率": 53.28,
        "成交量": 48127.0,
        "成交额": 278820.0,
        "振幅": 2.55,
        "换手率": 0.08,
        "symbol": "105.KMDA"
    },
    {
        "序号": 11010,
        "name": "运鸿绿色CTI",
        "最新价": 2.35,
        "涨跌额": -0.07,
        "涨跌幅": -2.89,
        "开盘价": 2.39,
        "最高价": 2.4,
        "最低价": 2.35,
        "昨收价": 2.42,
        "总市值": 47227374.0,
        "市盈率": -59.18,
        "成交量": 15319.0,
        "成交额": 36291.0,
        "振幅": 2.07,
        "换手率": 0.08,
        "symbol": "105.YHGJ"
    },
    {
        "序号": 11011,
        "name": "MicroSectors Energy 3X Inverse ",
        "最新价": 25.15,
        "涨跌额": -0.75,
        "涨跌幅": -2.9,
        "开盘价": 25.15,
        "最高价": 25.15,
        "最低价": 25.15,
        "昨收价": 25.9,
        "总市值": 18130865399.0,
        "市盈率": 5.71,
        "成交量": 65.0,
        "成交额": 1635.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.WTID"
    },
    {
        "序号": 11012,
        "name": "克劳福德-A",
        "最新价": 11.06,
        "涨跌额": -0.33,
        "涨跌幅": -2.9,
        "开盘价": 11.37,
        "最高价": 11.43,
        "最低价": 11.03,
        "昨收价": 11.39,
        "总市值": 540688672.0,
        "市盈率": 31.18,
        "成交量": 48237.0,
        "成交额": 536953.0,
        "振幅": 3.51,
        "换手率": 0.17,
        "symbol": "106.CRD_A"
    },
    {
        "序号": 11013,
        "name": "恐慌指数期货短期合约ETN-iPath",
        "最新价": 16.75,
        "涨跌额": -0.5,
        "涨跌幅": -2.9,
        "开盘价": 17.1,
        "最高价": 17.12,
        "最低价": 16.72,
        "昨收价": 17.25,
        "总市值": null,
        "市盈率": null,
        "成交量": 7037603.0,
        "成交额": 118833719.0,
        "振幅": 2.32,
        "换手率": null,
        "symbol": "107.VXX"
    },
    {
        "序号": 11014,
        "name": "Atara Biotherapeutics Inc",
        "最新价": 0.67,
        "涨跌额": -0.02,
        "涨跌幅": -2.9,
        "开盘价": 0.68,
        "最高价": 0.7,
        "最低价": 0.64,
        "昨收价": 0.69,
        "总市值": 68287908.0,
        "市盈率": -0.24,
        "成交量": 1830066.0,
        "成交额": 1222301.0,
        "振幅": 8.7,
        "换手率": 1.8,
        "symbol": "105.ATRA"
    },
    {
        "序号": 11015,
        "name": "MicroSectors Oil & Gas Exp. & P",
        "最新价": 22.75,
        "涨跌额": -0.68,
        "涨跌幅": -2.9,
        "开盘价": 22.97,
        "最高价": 23.05,
        "最低价": 22.51,
        "昨收价": 23.43,
        "总市值": 16400683413.0,
        "市盈率": 5.17,
        "成交量": 83279.0,
        "成交额": 1896356.0,
        "振幅": 2.3,
        "换手率": null,
        "symbol": "107.OILD"
    },
    {
        "序号": 11016,
        "name": "NexImmune Inc",
        "最新价": 3.68,
        "涨跌额": -0.11,
        "涨跌幅": -2.9,
        "开盘价": 3.58,
        "最高价": 3.83,
        "最低价": 3.37,
        "昨收价": 3.79,
        "总市值": 3893900.0,
        "市盈率": -0.09,
        "成交量": 59762.0,
        "成交额": 211726.0,
        "振幅": 12.14,
        "换手率": 5.65,
        "symbol": "105.NEXI"
    },
    {
        "序号": 11017,
        "name": "WisdomTree Efficient Gold Plus ",
        "最新价": 22.41,
        "涨跌额": -0.67,
        "涨跌幅": -2.9,
        "开盘价": 22.48,
        "最高价": 22.48,
        "最低价": 22.33,
        "昨收价": 23.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 1664.0,
        "成交额": 37353.0,
        "振幅": 0.65,
        "换手率": null,
        "symbol": "107.GDMN"
    },
    {
        "序号": 11018,
        "name": "KraneShares MSCI China Clean Te",
        "最新价": 21.73,
        "涨跌额": -0.65,
        "涨跌幅": -2.9,
        "开盘价": 22.03,
        "最高价": 22.05,
        "最低价": 21.66,
        "昨收价": 22.38,
        "总市值": null,
        "市盈率": null,
        "成交量": 13097.0,
        "成交额": 287196.0,
        "振幅": 1.74,
        "换手率": null,
        "symbol": "107.KGRN"
    },
    {
        "序号": 11019,
        "name": "恐慌指数期货短期合约ETF",
        "最新价": 16.7,
        "涨跌额": -0.5,
        "涨跌幅": -2.91,
        "开盘价": 17.09,
        "最高价": 17.09,
        "最低价": 16.68,
        "昨收价": 17.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 2257535.0,
        "成交额": 38050734.0,
        "振幅": 2.38,
        "换手率": null,
        "symbol": "107.VIXY"
    },
    {
        "序号": 11020,
        "name": "Summit Hotel Properties Inc Ser",
        "最新价": 18.7,
        "涨跌额": -0.56,
        "涨跌幅": -2.91,
        "开盘价": 19.11,
        "最高价": 19.34,
        "最低价": 18.7,
        "昨收价": 19.26,
        "总市值": 119680000.0,
        "市盈率": null,
        "成交量": 8492.0,
        "成交额": 159887.0,
        "振幅": 3.32,
        "换手率": 0.13,
        "symbol": "106.INN_E"
    },
    {
        "序号": 11021,
        "name": "Warby Parker Inc-A",
        "最新价": 10.68,
        "涨跌额": -0.32,
        "涨跌幅": -2.91,
        "开盘价": 10.94,
        "最高价": 10.98,
        "最低价": 10.28,
        "昨收价": 11.0,
        "总市值": 1256393117.0,
        "市盈率": -19.51,
        "成交量": 2083714.0,
        "成交额": 21973347.0,
        "振幅": 6.36,
        "换手率": 1.77,
        "symbol": "106.WRBY"
    },
    {
        "序号": 11022,
        "name": "晖阳新能源",
        "最新价": 1.0,
        "涨跌额": -0.03,
        "涨跌幅": -2.91,
        "开盘价": 1.04,
        "最高价": 1.06,
        "最低价": 0.96,
        "昨收价": 1.03,
        "总市值": 25361550.0,
        "市盈率": -1.13,
        "成交量": 81062.0,
        "成交额": 80072.0,
        "振幅": 9.71,
        "换手率": 0.32,
        "symbol": "105.EPOW"
    },
    {
        "序号": 11023,
        "name": "宜人金科",
        "最新价": 2.99,
        "涨跌额": -0.09,
        "涨跌幅": -2.92,
        "开盘价": 3.02,
        "最高价": 3.09,
        "最低价": 2.96,
        "昨收价": 3.08,
        "总市值": 264952718.0,
        "市盈率": 0.94,
        "成交量": 54742.0,
        "成交额": 166014.0,
        "振幅": 4.22,
        "换手率": 0.06,
        "symbol": "106.YRD"
    },
    {
        "序号": 11024,
        "name": "Warrantee Inc ADR",
        "最新价": 0.33,
        "涨跌额": -0.01,
        "涨跌幅": -2.94,
        "开盘价": 0.33,
        "最高价": 0.34,
        "最低价": 0.31,
        "昨收价": 0.34,
        "总市值": 7393320.0,
        "市盈率": 285.33,
        "成交量": 90442.0,
        "成交额": 29064.0,
        "振幅": 8.82,
        "换手率": 0.4,
        "symbol": "105.WRNT"
    },
    {
        "序号": 11025,
        "name": "FiscalNote Holdings Inc Wt",
        "最新价": 0.33,
        "涨跌额": -0.01,
        "涨跌幅": -2.94,
        "开盘价": 0.35,
        "最高价": 0.35,
        "最低价": 0.33,
        "昨收价": 0.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 3068.0,
        "成交额": 1069.0,
        "振幅": 5.88,
        "换手率": null,
        "symbol": "106.NOTE_WS"
    },
    {
        "序号": 11026,
        "name": "AgeX Therapeutics Inc",
        "最新价": 0.33,
        "涨跌额": -0.01,
        "涨跌幅": -2.94,
        "开盘价": 0.35,
        "最高价": 0.36,
        "最低价": 0.33,
        "昨收价": 0.34,
        "总市值": 12523916.0,
        "市盈率": -0.89,
        "成交量": 63523.0,
        "成交额": 22217.0,
        "振幅": 8.82,
        "换手率": 0.17,
        "symbol": "107.AGE"
    },
    {
        "序号": 11027,
        "name": "Ault Disruptive Technologies Co",
        "最新价": 11.55,
        "涨跌额": -0.35,
        "涨跌幅": -2.94,
        "开盘价": 11.86,
        "最高价": 11.94,
        "最低价": 11.35,
        "昨收价": 11.9,
        "总市值": 35387756.0,
        "市盈率": 23.05,
        "成交量": 1778.0,
        "成交额": 20787.0,
        "振幅": 4.96,
        "换手率": null,
        "symbol": "107.ADRT_U"
    },
    {
        "序号": 11028,
        "name": "TransMedics Group Inc",
        "最新价": 72.9,
        "涨跌额": -2.21,
        "涨跌幅": -2.94,
        "开盘价": 74.85,
        "最高价": 76.01,
        "最低价": 72.17,
        "昨收价": 75.11,
        "总市值": 2380017184.0,
        "市盈率": -66.52,
        "成交量": 579065.0,
        "成交额": 42605079.0,
        "振幅": 5.11,
        "换手率": 1.77,
        "symbol": "105.TMDX"
    },
    {
        "序号": 11029,
        "name": "培瑞克",
        "最新价": 29.65,
        "涨跌额": -0.9,
        "涨跌幅": -2.95,
        "开盘价": 30.51,
        "最高价": 30.82,
        "最低价": 29.55,
        "昨收价": 30.55,
        "总市值": 4017767399.0,
        "市盈率": -979.94,
        "成交量": 1710617.0,
        "成交额": 51118146.0,
        "振幅": 4.16,
        "换手率": 1.26,
        "symbol": "106.PRGO"
    },
    {
        "序号": 11030,
        "name": "NovoCure Ltd",
        "最新价": 12.5,
        "涨跌额": -0.38,
        "涨跌幅": -2.95,
        "开盘价": 12.85,
        "最高价": 12.89,
        "最低价": 12.12,
        "昨收价": 12.88,
        "总市值": 1335763913.0,
        "市盈率": -6.77,
        "成交量": 955388.0,
        "成交额": 11864259.0,
        "振幅": 5.98,
        "换手率": 0.89,
        "symbol": "105.NVCR"
    },
    {
        "序号": 11031,
        "name": "Absci Corp",
        "最新价": 2.3,
        "涨跌额": -0.07,
        "涨跌幅": -2.95,
        "开盘价": 2.34,
        "最高价": 2.49,
        "最低价": 2.22,
        "昨收价": 2.37,
        "总市值": 213758497.0,
        "市盈率": -2.01,
        "成交量": 931828.0,
        "成交额": 2186362.0,
        "振幅": 11.39,
        "换手率": 1.0,
        "symbol": "105.ABSI"
    },
    {
        "序号": 11032,
        "name": "MAG Silver Corp",
        "最新价": 10.84,
        "涨跌额": -0.33,
        "涨跌幅": -2.95,
        "开盘价": 10.91,
        "最高价": 11.12,
        "最低价": 10.8,
        "昨收价": 11.17,
        "总市值": 1115962965.0,
        "市盈率": 34.72,
        "成交量": 970960.0,
        "成交额": 10611181.0,
        "振幅": 2.86,
        "换手率": 0.94,
        "symbol": "107.MAG"
    },
    {
        "序号": 11033,
        "name": "每日期刊",
        "最新价": 315.0,
        "涨跌额": -9.6,
        "涨跌幅": -2.96,
        "开盘价": 322.06,
        "最高价": 322.06,
        "最低价": 315.0,
        "昨收价": 324.6,
        "总市值": 433763190.0,
        "市盈率": -25.68,
        "成交量": 2050.0,
        "成交额": 649187.0,
        "振幅": 2.17,
        "换手率": 0.15,
        "symbol": "105.DJCO"
    },
    {
        "序号": 11034,
        "name": "Nuvectis Pharma Inc",
        "最新价": 8.19,
        "涨跌额": -0.25,
        "涨跌幅": -2.96,
        "开盘价": 8.32,
        "最高价": 8.5,
        "最低价": 8.02,
        "昨收价": 8.44,
        "总市值": 141904805.0,
        "市盈率": -6.35,
        "成交量": 50208.0,
        "成交额": 412012.0,
        "振幅": 5.69,
        "换手率": 0.29,
        "symbol": "105.NVCT"
    },
    {
        "序号": 11035,
        "name": "PainReform Ltd",
        "最新价": 2.62,
        "涨跌额": -0.08,
        "涨跌幅": -2.96,
        "开盘价": 2.7,
        "最高价": 2.7,
        "最低价": 2.61,
        "昨收价": 2.7,
        "总市值": 4082872.0,
        "市盈率": -0.54,
        "成交量": 6625.0,
        "成交额": 17421.0,
        "振幅": 3.33,
        "换手率": 0.43,
        "symbol": "105.PRFX"
    },
    {
        "序号": 11036,
        "name": "Heritage Insurance Holdings Inc",
        "最新价": 8.16,
        "涨跌额": -0.25,
        "涨跌幅": -2.97,
        "开盘价": 8.41,
        "最高价": 8.41,
        "最低价": 7.86,
        "昨收价": 8.41,
        "总市值": 318366749.0,
        "市盈率": 11.85,
        "成交量": 400174.0,
        "成交额": 3235430.0,
        "振幅": 6.54,
        "换手率": 1.03,
        "symbol": "106.HRTG"
    },
    {
        "序号": 11037,
        "name": "Kiniksa Pharmaceuticals Ltd-A",
        "最新价": 17.27,
        "涨跌额": -0.53,
        "涨跌幅": -2.98,
        "开盘价": 17.86,
        "最高价": 17.96,
        "最低价": 17.24,
        "昨收价": 17.8,
        "总市值": 1214743374.0,
        "市盈率": -181.52,
        "成交量": 574940.0,
        "成交额": 10076710.0,
        "振幅": 4.04,
        "换手率": 0.82,
        "symbol": "105.KNSA"
    },
    {
        "序号": 11038,
        "name": "Venus Concept Inc",
        "最新价": 1.3,
        "涨跌额": -0.04,
        "涨跌幅": -2.99,
        "开盘价": 1.35,
        "最高价": 1.35,
        "最低价": 1.28,
        "昨收价": 1.34,
        "总市值": 7187894.0,
        "市盈率": -0.2,
        "成交量": 7312.0,
        "成交额": 9605.0,
        "振幅": 5.22,
        "换手率": 0.13,
        "symbol": "105.VERO"
    },
    {
        "序号": 11039,
        "name": "Marti Technologies Inc-A",
        "最新价": 0.65,
        "涨跌额": -0.02,
        "涨跌幅": -2.99,
        "开盘价": 0.69,
        "最高价": 0.69,
        "最低价": 0.63,
        "昨收价": 0.67,
        "总市值": 31573487.0,
        "市盈率": 26.12,
        "成交量": 50113.0,
        "成交额": 32853.0,
        "振幅": 8.96,
        "换手率": 0.1,
        "symbol": "107.MRT"
    },
    {
        "序号": 11040,
        "name": "蘑菇街",
        "最新价": 1.95,
        "涨跌额": -0.06,
        "涨跌幅": -2.99,
        "开盘价": 1.93,
        "最高价": 1.95,
        "最低价": 1.93,
        "昨收价": 2.01,
        "总市值": 16020888.0,
        "市盈率": -0.62,
        "成交量": 496.0,
        "成交额": 963.0,
        "振幅": 1.0,
        "换手率": 0.01,
        "symbol": "106.MOGU"
    },
    {
        "序号": 11041,
        "name": "Quince Therapeutics Inc",
        "最新价": 0.97,
        "涨跌额": -0.03,
        "涨跌幅": -3.0,
        "开盘价": 1.0,
        "最高价": 1.02,
        "最低价": 0.96,
        "昨收价": 1.0,
        "总市值": 41582879.0,
        "市盈率": -1.48,
        "成交量": 48047.0,
        "成交额": 46947.0,
        "振幅": 6.0,
        "换手率": 0.11,
        "symbol": "105.QNCX"
    },
    {
        "序号": 11042,
        "name": "MaxCyte Inc",
        "最新价": 4.85,
        "涨跌额": -0.15,
        "涨跌幅": -3.0,
        "开盘价": 5.03,
        "最高价": 5.09,
        "最低价": 4.56,
        "昨收价": 5.0,
        "总市值": 502345681.0,
        "市盈率": -13.41,
        "成交量": 2786667.0,
        "成交额": 13441048.0,
        "振幅": 10.6,
        "换手率": 2.69,
        "symbol": "105.MXCT"
    },
    {
        "序号": 11043,
        "name": "Jupiter Acquisition Corp Unit C",
        "最新价": 10.0,
        "涨跌额": -0.31,
        "涨跌幅": -3.01,
        "开盘价": 10.0,
        "最高价": 10.0,
        "最低价": 10.0,
        "昨收价": 10.31,
        "总市值": 60111920.0,
        "市盈率": 50.32,
        "成交量": 200.0,
        "成交额": 2000.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.JAQCU"
    },
    {
        "序号": 11044,
        "name": "Integrated Media Technology Ltd",
        "最新价": 2.9,
        "涨跌额": -0.09,
        "涨跌幅": -3.01,
        "开盘价": 2.99,
        "最高价": 2.99,
        "最低价": 2.9,
        "昨收价": 2.99,
        "总市值": 6090000.0,
        "市盈率": -0.71,
        "成交量": 1738.0,
        "成交额": 5170.0,
        "振幅": 3.01,
        "换手率": 0.08,
        "symbol": "105.IMTE"
    },
    {
        "序号": 11045,
        "name": "Sprott Inc.",
        "最新价": 32.19,
        "涨跌额": -1.0,
        "涨跌幅": -3.01,
        "开盘价": 32.76,
        "最高价": 33.04,
        "最低价": 31.79,
        "昨收价": 33.19,
        "总市值": 810150613.0,
        "市盈率": 20.53,
        "成交量": 110029.0,
        "成交额": 3558782.0,
        "振幅": 3.77,
        "换手率": 0.44,
        "symbol": "106.SII"
    },
    {
        "序号": 11046,
        "name": "来福威食品",
        "最新价": 12.86,
        "涨跌额": -0.4,
        "涨跌幅": -3.02,
        "开盘价": 13.25,
        "最高价": 13.7,
        "最低价": 12.81,
        "昨收价": 13.26,
        "总市值": 188926093.0,
        "市盈率": 23.28,
        "成交量": 43309.0,
        "成交额": 568949.0,
        "振幅": 6.71,
        "换手率": 0.29,
        "symbol": "105.LWAY"
    },
    {
        "序号": 11047,
        "name": "基瓦尼科技",
        "最新价": 22.1,
        "涨跌额": -0.69,
        "涨跌幅": -3.03,
        "开盘价": 23.13,
        "最高价": 23.13,
        "最低价": 22.0,
        "昨收价": 22.79,
        "总市值": 64126929.0,
        "市盈率": 9.25,
        "成交量": 12032.0,
        "成交额": 269599.0,
        "振幅": 4.96,
        "换手率": 0.41,
        "symbol": "105.KEQU"
    },
    {
        "序号": 11048,
        "name": "Verastem Inc",
        "最新价": 7.36,
        "涨跌额": -0.23,
        "涨跌幅": -3.03,
        "开盘价": 7.59,
        "最高价": 7.8,
        "最低价": 7.31,
        "昨收价": 7.59,
        "总市值": 185968329.0,
        "市盈率": -2.42,
        "成交量": 67087.0,
        "成交额": 501202.0,
        "振幅": 6.46,
        "换手率": 0.27,
        "symbol": "105.VSTM"
    },
    {
        "序号": 11049,
        "name": "VolitionRx Ltd",
        "最新价": 0.64,
        "涨跌额": -0.02,
        "涨跌幅": -3.03,
        "开盘价": 0.67,
        "最高价": 0.7,
        "最低价": 0.63,
        "昨收价": 0.66,
        "总市值": 50358959.0,
        "市盈率": -1.49,
        "成交量": 137039.0,
        "成交额": 91441.0,
        "振幅": 10.61,
        "换手率": 0.17,
        "symbol": "107.VNRX"
    },
    {
        "序号": 11050,
        "name": "Interactive Strength Inc",
        "最新价": 0.96,
        "涨跌额": -0.03,
        "涨跌幅": -3.03,
        "开盘价": 0.99,
        "最高价": 1.0,
        "最低价": 0.96,
        "昨收价": 0.99,
        "总市值": 13740658.0,
        "市盈率": -0.23,
        "成交量": 7234.0,
        "成交额": 7046.0,
        "振幅": 4.04,
        "换手率": 0.05,
        "symbol": "105.TRNR"
    },
    {
        "序号": 11051,
        "name": "Gamida Cell Ltd",
        "最新价": 0.32,
        "涨跌额": -0.01,
        "涨跌幅": -3.03,
        "开盘价": 0.33,
        "最高价": 0.33,
        "最低价": 0.32,
        "昨收价": 0.33,
        "总市值": 42444324.0,
        "市盈率": -0.55,
        "成交量": 1342587.0,
        "成交额": 433221.0,
        "振幅": 3.03,
        "换手率": 1.01,
        "symbol": "105.GMDA"
    },
    {
        "序号": 11052,
        "name": "BioSig Technologies Inc",
        "最新价": 0.32,
        "涨跌额": -0.01,
        "涨跌幅": -3.03,
        "开盘价": 0.33,
        "最高价": 0.33,
        "最低价": 0.31,
        "昨收价": 0.33,
        "总市值": 28194421.0,
        "市盈率": -0.94,
        "成交量": 240163.0,
        "成交额": 76191.0,
        "振幅": 6.06,
        "换手率": 0.27,
        "symbol": "105.BSGM"
    },
    {
        "序号": 11053,
        "name": "Greenwich LifeSciences Inc",
        "最新价": 11.78,
        "涨跌额": -0.37,
        "涨跌幅": -3.05,
        "开盘价": 11.93,
        "最高价": 11.96,
        "最低价": 11.5,
        "昨收价": 12.15,
        "总市值": 151351384.0,
        "市盈率": -16.94,
        "成交量": 15082.0,
        "成交额": 176419.0,
        "振幅": 3.79,
        "换手率": 0.12,
        "symbol": "105.GLSI"
    },
    {
        "序号": 11054,
        "name": "钯金ETF-ETFS",
        "最新价": 87.04,
        "涨跌额": -2.74,
        "涨跌幅": -3.05,
        "开盘价": 88.86,
        "最高价": 89.17,
        "最低价": 86.95,
        "昨收价": 89.78,
        "总市值": null,
        "市盈率": null,
        "成交量": 45652.0,
        "成交额": 4005896.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "107.PALL"
    },
    {
        "序号": 11055,
        "name": "IO Biotech Inc",
        "最新价": 1.27,
        "涨跌额": -0.04,
        "涨跌幅": -3.05,
        "开盘价": 1.33,
        "最高价": 1.33,
        "最低价": 1.18,
        "昨收价": 1.31,
        "总市值": 83668761.0,
        "市盈率": -1.05,
        "成交量": 121455.0,
        "成交额": 152260.0,
        "振幅": 11.45,
        "换手率": 0.18,
        "symbol": "105.IOBT"
    },
    {
        "序号": 11056,
        "name": "Inozyme Pharma Inc",
        "最新价": 3.81,
        "涨跌额": -0.12,
        "涨跌幅": -3.05,
        "开盘价": 3.9,
        "最高价": 3.99,
        "最低价": 3.8,
        "昨收价": 3.93,
        "总市值": 235308450.0,
        "市盈率": -3.45,
        "成交量": 278900.0,
        "成交额": 1077652.0,
        "振幅": 4.83,
        "换手率": 0.45,
        "symbol": "105.INZY"
    },
    {
        "序号": 11057,
        "name": "The Oncology Institute Inc",
        "最新价": 1.26,
        "涨跌额": -0.04,
        "涨跌幅": -3.08,
        "开盘价": 1.28,
        "最高价": 1.3,
        "最低价": 1.26,
        "昨收价": 1.3,
        "总市值": 92923714.0,
        "市盈率": -1.23,
        "成交量": 381855.0,
        "成交额": 487421.0,
        "振幅": 3.08,
        "换手率": 0.52,
        "symbol": "105.TOI"
    },
    {
        "序号": 11058,
        "name": "西港燃料",
        "最新价": 6.9,
        "涨跌额": -0.22,
        "涨跌幅": -3.09,
        "开盘价": 7.04,
        "最高价": 7.13,
        "最低价": 6.85,
        "昨收价": 7.12,
        "总市值": 118507307.0,
        "市盈率": -2.25,
        "成交量": 23387.0,
        "成交额": 163115.0,
        "振幅": 3.93,
        "换手率": 0.14,
        "symbol": "105.WPRT"
    },
    {
        "序号": 11059,
        "name": "Sharecare Inc",
        "最新价": 0.94,
        "涨跌额": -0.03,
        "涨跌幅": -3.09,
        "开盘价": 0.97,
        "最高价": 0.98,
        "最低价": 0.89,
        "昨收价": 0.97,
        "总市值": 331278432.0,
        "市盈率": -2.8,
        "成交量": 1308716.0,
        "成交额": 1210871.0,
        "振幅": 9.28,
        "换手率": 0.37,
        "symbol": "105.SHCR"
    },
    {
        "序号": 11060,
        "name": "Qurate Retail Inc-A",
        "最新价": 0.94,
        "涨跌额": -0.03,
        "涨跌幅": -3.09,
        "开盘价": 0.97,
        "最高价": 0.99,
        "最低价": 0.93,
        "昨收价": 0.97,
        "总市值": 366033575.0,
        "市盈率": 4.75,
        "成交量": 3024976.0,
        "成交额": 2869729.0,
        "振幅": 6.19,
        "换手率": 0.79,
        "symbol": "105.QRTEA"
    },
    {
        "序号": 11061,
        "name": "肯塔基第一银行",
        "最新价": 4.38,
        "涨跌额": -0.14,
        "涨跌幅": -3.1,
        "开盘价": 4.01,
        "最高价": 4.5,
        "最低价": 4.01,
        "昨收价": 4.52,
        "总市值": 35419812.0,
        "市盈率": 92.0,
        "成交量": 4906.0,
        "成交额": 20697.0,
        "振幅": 10.84,
        "换手率": 0.06,
        "symbol": "105.KFFB"
    },
    {
        "序号": 11062,
        "name": "Manitex International Inc",
        "最新价": 7.19,
        "涨跌额": -0.23,
        "涨跌幅": -3.1,
        "开盘价": 7.45,
        "最高价": 7.45,
        "最低价": 7.1,
        "昨收价": 7.42,
        "总市值": 145612692.0,
        "市盈率": 54.84,
        "成交量": 73140.0,
        "成交额": 534358.0,
        "振幅": 4.72,
        "换手率": 0.36,
        "symbol": "105.MNTX"
    },
    {
        "序号": 11063,
        "name": "Amtech Systems Inc",
        "最新价": 6.55,
        "涨跌额": -0.21,
        "涨跌幅": -3.11,
        "开盘价": 6.75,
        "最高价": 6.85,
        "最低价": 6.54,
        "昨收价": 6.76,
        "总市值": 92796149.0,
        "市盈率": 25.66,
        "成交量": 42964.0,
        "成交额": 285973.0,
        "振幅": 4.59,
        "换手率": 0.3,
        "symbol": "105.ASYS"
    },
    {
        "序号": 11064,
        "name": "克劳福德-B",
        "最新价": 10.26,
        "涨跌额": -0.33,
        "涨跌幅": -3.12,
        "开盘价": 10.51,
        "最高价": 10.6,
        "最低价": 10.25,
        "昨收价": 10.59,
        "总市值": 501579184.0,
        "市盈率": 28.93,
        "成交量": 24478.0,
        "成交额": 255156.0,
        "振幅": 3.31,
        "换手率": 0.12,
        "symbol": "106.CRD_B"
    },
    {
        "序号": 11065,
        "name": "Northern Dynasty Minerals Ltd",
        "最新价": 0.31,
        "涨跌额": -0.01,
        "涨跌幅": -3.13,
        "开盘价": 0.32,
        "最高价": 0.33,
        "最低价": 0.31,
        "昨收价": 0.32,
        "总市值": 164276133.0,
        "市盈率": -9.23,
        "成交量": 1039822.0,
        "成交额": 332701.0,
        "振幅": 6.25,
        "换手率": 0.2,
        "symbol": "107.NAK"
    },
    {
        "序号": 11066,
        "name": "Mobile Global Esports Inc",
        "最新价": 0.31,
        "涨跌额": -0.01,
        "涨跌幅": -3.13,
        "开盘价": 0.31,
        "最高价": 0.33,
        "最低价": 0.29,
        "昨收价": 0.32,
        "总市值": 6330694.0,
        "市盈率": -1.48,
        "成交量": 50629.0,
        "成交额": 15375.0,
        "振幅": 12.5,
        "换手率": 0.25,
        "symbol": "105.MGAM"
    },
    {
        "序号": 11067,
        "name": "Eiger BioPharmaceuticals Inc",
        "最新价": 0.31,
        "涨跌额": -0.01,
        "涨跌幅": -3.13,
        "开盘价": 0.34,
        "最高价": 0.34,
        "最低价": 0.3,
        "昨收价": 0.32,
        "总市值": 13759252.0,
        "市盈率": -0.16,
        "成交量": 100997.0,
        "成交额": 31130.0,
        "振幅": 12.5,
        "换手率": 0.23,
        "symbol": "105.EIGR"
    },
    {
        "序号": 11068,
        "name": "Aterian Inc",
        "最新价": 0.31,
        "涨跌额": -0.01,
        "涨跌幅": -3.13,
        "开盘价": 0.32,
        "最高价": 0.33,
        "最低价": 0.31,
        "昨收价": 0.32,
        "总市值": 27966112.0,
        "市盈率": -0.32,
        "成交量": 386687.0,
        "成交额": 120864.0,
        "振幅": 6.25,
        "换手率": 0.43,
        "symbol": "105.ATER"
    },
    {
        "序号": 11069,
        "name": "Falcon's Beyond Global Inc-A",
        "最新价": 11.42,
        "涨跌额": -0.37,
        "涨跌幅": -3.14,
        "开盘价": 12.26,
        "最高价": 12.35,
        "最低价": 11.42,
        "昨收价": 11.79,
        "总市值": 1557420772.0,
        "市盈率": -320.82,
        "成交量": 8374.0,
        "成交额": 100700.0,
        "振幅": 7.89,
        "换手率": 0.01,
        "symbol": "105.FBYD"
    },
    {
        "序号": 11070,
        "name": "Barnes & Noble Education Inc",
        "最新价": 1.23,
        "涨跌额": -0.04,
        "涨跌幅": -3.15,
        "开盘价": 1.27,
        "最高价": 1.36,
        "最低价": 1.15,
        "昨收价": 1.27,
        "总市值": 65373890.0,
        "市盈率": -0.67,
        "成交量": 1074066.0,
        "成交额": 1318438.0,
        "振幅": 16.54,
        "换手率": 2.02,
        "symbol": "106.BNED"
    },
    {
        "序号": 11071,
        "name": "白银ETF-iShares",
        "最新价": 21.1,
        "涨跌额": -0.69,
        "涨跌幅": -3.17,
        "开盘价": 21.35,
        "最高价": 21.56,
        "最低价": 21.02,
        "昨收价": 21.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 28746451.0,
        "成交额": 611294880.0,
        "振幅": 2.48,
        "换手率": null,
        "symbol": "107.SLV"
    },
    {
        "序号": 11072,
        "name": "Accelerate Diagnostics Inc",
        "最新价": 4.89,
        "涨跌额": -0.16,
        "涨跌幅": -3.17,
        "开盘价": 5.23,
        "最高价": 5.23,
        "最低价": 4.87,
        "昨收价": 5.05,
        "总市值": 71152458.0,
        "市盈率": -1.13,
        "成交量": 60627.0,
        "成交额": 307863.0,
        "振幅": 7.13,
        "换手率": 0.42,
        "symbol": "105.AXDX"
    },
    {
        "序号": 11073,
        "name": "Vera Therapeutics Inc-A",
        "最新价": 13.7,
        "涨跌额": -0.45,
        "涨跌幅": -3.18,
        "开盘价": 14.04,
        "最高价": 14.24,
        "最低价": 13.65,
        "昨收价": 14.15,
        "总市值": 608731347.0,
        "市盈率": -5.92,
        "成交量": 267617.0,
        "成交额": 3703213.0,
        "振幅": 4.17,
        "换手率": 0.6,
        "symbol": "105.VERA"
    },
    {
        "序号": 11074,
        "name": "Moatable Inc ADR",
        "最新价": 0.91,
        "涨跌额": -0.03,
        "涨跌幅": -3.19,
        "开盘价": 0.91,
        "最高价": 0.99,
        "最低价": 0.91,
        "昨收价": 0.94,
        "总市值": 16925286.0,
        "市盈率": -0.61,
        "成交量": 2558.0,
        "成交额": 2424.0,
        "振幅": 8.51,
        "换手率": 0.01,
        "symbol": "106.MTBL"
    },
    {
        "序号": 11075,
        "name": "Moving iMage Technologies Inc",
        "最新价": 0.91,
        "涨跌额": -0.03,
        "涨跌幅": -3.19,
        "开盘价": 0.94,
        "最高价": 0.94,
        "最低价": 0.91,
        "昨收价": 0.94,
        "总市值": 9724058.0,
        "市盈率": -7.69,
        "成交量": 17749.0,
        "成交额": 16555.0,
        "振幅": 3.19,
        "换手率": 0.17,
        "symbol": "107.MITQ"
    },
    {
        "序号": 11076,
        "name": "Petco Health and Wellness Co In",
        "最新价": 3.33,
        "涨跌额": -0.11,
        "涨跌幅": -3.2,
        "开盘价": 3.42,
        "最高价": 3.48,
        "最低价": 3.3,
        "昨收价": 3.44,
        "总市值": 1019910335.0,
        "市盈率": -0.83,
        "成交量": 3007692.0,
        "成交额": 10127330.0,
        "振幅": 5.23,
        "换手率": 0.98,
        "symbol": "105.WOOF"
    },
    {
        "序号": 11077,
        "name": "Motorsport Games Inc-A",
        "最新价": 2.42,
        "涨跌额": -0.08,
        "涨跌幅": -3.2,
        "开盘价": 2.45,
        "最高价": 2.59,
        "最低价": 2.42,
        "昨收价": 2.5,
        "总市值": 8283002.0,
        "市盈率": -0.38,
        "成交量": 6196.0,
        "成交额": 15478.0,
        "振幅": 6.8,
        "换手率": 0.18,
        "symbol": "105.MSGM"
    },
    {
        "序号": 11078,
        "name": "Tucows Inc",
        "最新价": 23.81,
        "涨跌额": -0.79,
        "涨跌幅": -3.21,
        "开盘价": 24.6,
        "最高价": 24.63,
        "最低价": 23.52,
        "昨收价": 24.6,
        "总市值": 259414236.0,
        "市盈率": -3.01,
        "成交量": 36494.0,
        "成交额": 882433.0,
        "振幅": 4.51,
        "换手率": 0.33,
        "symbol": "105.TCX"
    },
    {
        "序号": 11079,
        "name": "The Alkaline Water Co Inc",
        "最新价": 0.3,
        "涨跌额": -0.01,
        "涨跌幅": -3.23,
        "开盘价": 0.31,
        "最高价": 0.31,
        "最低价": 0.3,
        "昨收价": 0.31,
        "总市值": 3907128.0,
        "市盈率": -0.17,
        "成交量": 137827.0,
        "成交额": 41510.0,
        "振幅": 3.23,
        "换手率": 1.06,
        "symbol": "105.WTER"
    },
    {
        "序号": 11080,
        "name": "Snail Inc-A",
        "最新价": 1.2,
        "涨跌额": -0.04,
        "涨跌幅": -3.23,
        "开盘价": 1.26,
        "最高价": 1.28,
        "最低价": 1.2,
        "昨收价": 1.24,
        "总市值": 45600000.0,
        "市盈率": -3.32,
        "成交量": 11260.0,
        "成交额": 14141.0,
        "振幅": 6.45,
        "换手率": 0.03,
        "symbol": "105.SNAL"
    },
    {
        "序号": 11081,
        "name": "Lixte Biotechnology Holdings In",
        "最新价": 2.1,
        "涨跌额": -0.07,
        "涨跌幅": -3.23,
        "开盘价": 2.15,
        "最高价": 2.24,
        "最低价": 2.05,
        "昨收价": 2.17,
        "总市值": 4723509.0,
        "市盈率": -0.83,
        "成交量": 5628.0,
        "成交额": 11878.0,
        "振幅": 8.76,
        "换手率": 0.25,
        "symbol": "105.LIXT"
    },
    {
        "序号": 11082,
        "name": "九紫新能",
        "最新价": 0.6,
        "涨跌额": -0.02,
        "涨跌幅": -3.23,
        "开盘价": 0.66,
        "最高价": 0.66,
        "最低价": 0.58,
        "昨收价": 0.62,
        "总市值": 2439365.0,
        "市盈率": -0.13,
        "成交量": 25264.0,
        "成交额": 15565.0,
        "振幅": 12.9,
        "换手率": 0.62,
        "symbol": "105.JZXN"
    },
    {
        "序号": 11083,
        "name": "Intelligent Bio Solutions Inc",
        "最新价": 0.3,
        "涨跌额": -0.01,
        "涨跌幅": -3.23,
        "开盘价": 0.31,
        "最高价": 0.32,
        "最低价": 0.28,
        "昨收价": 0.31,
        "总市值": 2620314.0,
        "市盈率": -0.22,
        "成交量": 684872.0,
        "成交额": 202777.0,
        "振幅": 12.9,
        "换手率": 7.84,
        "symbol": "105.INBS"
    },
    {
        "序号": 11084,
        "name": "四季教育",
        "最新价": 9.3,
        "涨跌额": -0.31,
        "涨跌幅": -3.23,
        "开盘价": 9.28,
        "最高价": 9.3,
        "最低价": 9.27,
        "昨收价": 9.61,
        "总市值": 19705728.0,
        "市盈率": -51.29,
        "成交量": 488.0,
        "成交额": 4530.0,
        "振幅": 0.31,
        "换手率": 0.02,
        "symbol": "106.FEDU"
    },
    {
        "序号": 11085,
        "name": "Bolt Biotherapeutics Inc",
        "最新价": 0.9,
        "涨跌额": -0.03,
        "涨跌幅": -3.23,
        "开盘价": 0.89,
        "最高价": 0.93,
        "最低价": 0.88,
        "昨收价": 0.93,
        "总市值": 34169283.0,
        "市盈率": -0.48,
        "成交量": 100154.0,
        "成交额": 90829.0,
        "振幅": 5.38,
        "换手率": 0.26,
        "symbol": "105.BOLT"
    },
    {
        "序号": 11086,
        "name": "Armata Pharmaceuticals Inc",
        "最新价": 2.7,
        "涨跌额": -0.09,
        "涨跌幅": -3.23,
        "开盘价": 2.7,
        "最高价": 2.7,
        "最低价": 2.58,
        "昨收价": 2.79,
        "总市值": 97595750.0,
        "市盈率": -1.64,
        "成交量": 9351.0,
        "成交额": 24602.0,
        "振幅": 4.3,
        "换手率": 0.03,
        "symbol": "107.ARMP"
    },
    {
        "序号": 11087,
        "name": "医美国际",
        "最新价": 0.6,
        "涨跌额": -0.02,
        "涨跌幅": -3.23,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.6,
        "昨收价": 0.62,
        "总市值": 28534224.0,
        "市盈率": -2.45,
        "成交量": 186.0,
        "成交额": 111.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.AIH"
    },
    {
        "序号": 11088,
        "name": "南非萨索尔",
        "最新价": 9.55,
        "涨跌额": -0.32,
        "涨跌幅": -3.24,
        "开盘价": 9.45,
        "最高价": 9.67,
        "最低价": 9.43,
        "昨收价": 9.87,
        "总市值": 6118375695.0,
        "市盈率": 12.72,
        "成交量": 1681291.0,
        "成交额": 16042757.0,
        "振幅": 2.43,
        "换手率": 0.26,
        "symbol": "106.SSL"
    },
    {
        "序号": 11089,
        "name": "BridgeBio Pharma Inc",
        "最新价": 32.52,
        "涨跌额": -1.09,
        "涨跌幅": -3.24,
        "开盘价": 34.0,
        "最高价": 34.49,
        "最低价": 32.16,
        "昨收价": 33.61,
        "总市值": 5657408531.0,
        "市盈率": -9.23,
        "成交量": 1256112.0,
        "成交额": 41510028.0,
        "振幅": 6.93,
        "换手率": 0.72,
        "symbol": "105.BBIO"
    },
    {
        "序号": 11090,
        "name": "Equinox Gold Corp",
        "最新价": 5.06,
        "涨跌额": -0.17,
        "涨跌幅": -3.25,
        "开盘价": 5.13,
        "最高价": 5.18,
        "最低价": 4.97,
        "昨收价": 5.23,
        "总市值": 1583683521.0,
        "市盈率": 33.29,
        "成交量": 2889651.0,
        "成交额": 14679741.0,
        "振幅": 4.02,
        "换手率": 0.92,
        "symbol": "107.EQX"
    },
    {
        "序号": 11091,
        "name": "BPT信托",
        "最新价": 2.97,
        "涨跌额": -0.1,
        "涨跌幅": -3.26,
        "开盘价": 3.07,
        "最高价": 3.11,
        "最低价": 2.92,
        "昨收价": 3.07,
        "总市值": 63558000.0,
        "市盈率": 3.04,
        "成交量": 351138.0,
        "成交额": 1060875.0,
        "振幅": 6.19,
        "换手率": 1.64,
        "symbol": "106.BPT"
    },
    {
        "序号": 11092,
        "name": "夏威夷控股",
        "最新价": 13.31,
        "涨跌额": -0.45,
        "涨跌幅": -3.27,
        "开盘价": 13.76,
        "最高价": 13.89,
        "最低价": 13.27,
        "昨收价": 13.76,
        "总市值": 687245359.0,
        "市盈率": -3.28,
        "成交量": 15618436.0,
        "成交额": 209223690.0,
        "振幅": 4.51,
        "换手率": 30.25,
        "symbol": "105.HA"
    },
    {
        "序号": 11093,
        "name": "United Homes Group Inc-A",
        "最新价": 6.79,
        "涨跌额": -0.23,
        "涨跌幅": -3.28,
        "开盘价": 7.0,
        "最高价": 7.0,
        "最低价": 6.54,
        "昨收价": 7.02,
        "总市值": 328338415.0,
        "市盈率": 2.34,
        "成交量": 7929.0,
        "成交额": 54060.0,
        "振幅": 6.55,
        "换手率": 0.02,
        "symbol": "105.UHG"
    },
    {
        "序号": 11094,
        "name": "Tiziana Life Sciences Ltd",
        "最新价": 0.59,
        "涨跌额": -0.02,
        "涨跌幅": -3.28,
        "开盘价": 0.57,
        "最高价": 0.6,
        "最低价": 0.57,
        "昨收价": 0.61,
        "总市值": 60340842.0,
        "市盈率": -3.92,
        "成交量": 83061.0,
        "成交额": 48677.0,
        "振幅": 4.92,
        "换手率": 0.08,
        "symbol": "105.TLSA"
    },
    {
        "序号": 11095,
        "name": "Katapult Holdings Inc",
        "最新价": 10.62,
        "涨跌额": -0.36,
        "涨跌幅": -3.28,
        "开盘价": 10.75,
        "最高价": 11.39,
        "最低价": 10.01,
        "昨收价": 10.98,
        "总市值": 43172159.0,
        "市盈率": -1.41,
        "成交量": 21610.0,
        "成交额": 230406.0,
        "振幅": 12.57,
        "换手率": 0.53,
        "symbol": "105.KPLT"
    },
    {
        "序号": 11096,
        "name": "怪兽充电",
        "最新价": 0.59,
        "涨跌额": -0.02,
        "涨跌幅": -3.28,
        "开盘价": 0.61,
        "最高价": 0.63,
        "最低价": 0.55,
        "昨收价": 0.61,
        "总市值": 153293246.0,
        "市盈率": -4.12,
        "成交量": 28090.0,
        "成交额": 16375.0,
        "振幅": 13.11,
        "换手率": 0.01,
        "symbol": "105.EM"
    },
    {
        "序号": 11097,
        "name": "网易有道",
        "最新价": 3.83,
        "涨跌额": -0.13,
        "涨跌幅": -3.28,
        "开盘价": 3.95,
        "最高价": 4.02,
        "最低价": 3.8,
        "昨收价": 3.96,
        "总市值": 475741286.0,
        "市盈率": -5.62,
        "成交量": 122623.0,
        "成交额": 475323.0,
        "振幅": 5.56,
        "换手率": 0.1,
        "symbol": "106.DAO"
    },
    {
        "序号": 11098,
        "name": "Silk Road Medical Inc",
        "最新价": 9.72,
        "涨跌额": -0.33,
        "涨跌幅": -3.28,
        "开盘价": 10.05,
        "最高价": 10.15,
        "最低价": 9.69,
        "昨收价": 10.05,
        "总市值": 378550221.0,
        "市盈率": -6.84,
        "成交量": 1045543.0,
        "成交额": 10253231.0,
        "振幅": 4.58,
        "换手率": 2.68,
        "symbol": "105.SILK"
    },
    {
        "序号": 11099,
        "name": "Elevai Labs Inc",
        "最新价": 2.06,
        "涨跌额": -0.07,
        "涨跌幅": -3.29,
        "开盘价": 2.13,
        "最高价": 2.45,
        "最低价": 1.54,
        "昨收价": 2.13,
        "总市值": 35699007.0,
        "市盈率": -10.16,
        "成交量": 349520.0,
        "成交额": 706716.0,
        "振幅": 42.72,
        "换手率": 2.02,
        "symbol": "105.ELAB"
    },
    {
        "序号": 11100,
        "name": "Dyne Therapeutics Inc",
        "最新价": 11.18,
        "涨跌额": -0.38,
        "涨跌幅": -3.29,
        "开盘价": 11.47,
        "最高价": 11.57,
        "最低价": 11.07,
        "昨收价": 11.56,
        "总市值": 686179186.0,
        "市盈率": -3.3,
        "成交量": 480049.0,
        "成交额": 5424553.0,
        "振幅": 4.33,
        "换手率": 0.78,
        "symbol": "105.DYN"
    },
    {
        "序号": 11101,
        "name": "Aberdeen Standard Physical Silv",
        "最新价": 22.05,
        "涨跌额": -0.75,
        "涨跌幅": -3.29,
        "开盘价": 22.34,
        "最高价": 22.54,
        "最低价": 21.98,
        "昨收价": 22.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 1510377.0,
        "成交额": 33577668.0,
        "振幅": 2.46,
        "换手率": null,
        "symbol": "107.SIVR"
    },
    {
        "序号": 11102,
        "name": "Bionano Genomics Inc",
        "最新价": 1.47,
        "涨跌额": -0.05,
        "涨跌幅": -3.29,
        "开盘价": 1.52,
        "最高价": 1.57,
        "最低价": 1.45,
        "昨收价": 1.52,
        "总市值": 57095295.0,
        "市盈率": -0.25,
        "成交量": 1049007.0,
        "成交额": 1571672.0,
        "振幅": 7.89,
        "换手率": 2.7,
        "symbol": "105.BNGO"
    },
    {
        "序号": 11103,
        "name": "Virtus Diversified Income & Con",
        "最新价": 19.69,
        "涨跌额": -0.67,
        "涨跌幅": -3.29,
        "开盘价": 20.36,
        "最高价": 20.37,
        "最低价": 19.61,
        "昨收价": 20.36,
        "总市值": null,
        "市盈率": null,
        "成交量": 43970.0,
        "成交额": 878403.0,
        "振幅": 3.73,
        "换手率": null,
        "symbol": "106.ACV"
    },
    {
        "序号": 11104,
        "name": "CAVA Group Inc",
        "最新价": 32.24,
        "涨跌额": -1.1,
        "涨跌幅": -3.3,
        "开盘价": 33.0,
        "最高价": 33.69,
        "最低价": 31.78,
        "昨收价": 33.34,
        "总市值": 3662834728.0,
        "市盈率": -481.0,
        "成交量": 2675745.0,
        "成交额": 86948430.0,
        "振幅": 5.73,
        "换手率": 2.36,
        "symbol": "106.CAVA"
    },
    {
        "序号": 11105,
        "name": "NovaBay Pharmaceuticals Inc",
        "最新价": 0.29,
        "涨跌额": -0.01,
        "涨跌幅": -3.33,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.28,
        "昨收价": 0.3,
        "总市值": 1893498.0,
        "市盈率": -0.14,
        "成交量": 112210.0,
        "成交额": 32182.0,
        "振幅": 3.33,
        "换手率": 1.72,
        "symbol": "107.NBY"
    },
    {
        "序号": 11106,
        "name": "慧择",
        "最新价": 0.87,
        "涨跌额": -0.03,
        "涨跌幅": -3.33,
        "开盘价": 0.9,
        "最高价": 0.9,
        "最低价": 0.87,
        "昨收价": 0.9,
        "总市值": 44515124.0,
        "市盈率": 5.34,
        "成交量": 19159.0,
        "成交额": 16849.0,
        "振幅": 3.33,
        "换手率": 0.04,
        "symbol": "105.HUIZ"
    },
    {
        "序号": 11107,
        "name": "黄金资源",
        "最新价": 0.29,
        "涨跌额": -0.01,
        "涨跌幅": -3.33,
        "开盘价": 0.3,
        "最高价": 0.3,
        "最低价": 0.28,
        "昨收价": 0.3,
        "总市值": 25721271.0,
        "市盈率": -1.58,
        "成交量": 891343.0,
        "成交额": 254790.0,
        "振幅": 6.67,
        "换手率": 1.0,
        "symbol": "107.GORO"
    },
    {
        "序号": 11108,
        "name": "基因技术",
        "最新价": 0.58,
        "涨跌额": -0.02,
        "涨跌幅": -3.33,
        "开盘价": 0.58,
        "最高价": 0.6,
        "最低价": 0.53,
        "昨收价": 0.6,
        "总市值": 11156936.0,
        "市盈率": -1.43,
        "成交量": 243909.0,
        "成交额": 135731.0,
        "振幅": 11.67,
        "换手率": 1.27,
        "symbol": "105.GENE"
    },
    {
        "序号": 11109,
        "name": "Dynatronics Corp",
        "最新价": 0.58,
        "涨跌额": -0.02,
        "涨跌幅": -3.33,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.57,
        "昨收价": 0.6,
        "总市值": 2627885.0,
        "市盈率": -0.55,
        "成交量": 11707.0,
        "成交额": 6772.0,
        "振幅": 5.0,
        "换手率": 0.26,
        "symbol": "105.DYNT"
    },
    {
        "序号": 11110,
        "name": "Caravelle International Group",
        "最新价": 0.58,
        "涨跌额": -0.02,
        "涨跌幅": -3.33,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.58,
        "昨收价": 0.6,
        "总市值": 30609256.0,
        "市盈率": 2.5,
        "成交量": 3312.0,
        "成交额": 1965.0,
        "振幅": 3.33,
        "换手率": 0.01,
        "symbol": "105.CACO"
    },
    {
        "序号": 11111,
        "name": "Aethlon Medical Inc",
        "最新价": 2.02,
        "涨跌额": -0.07,
        "涨跌幅": -3.35,
        "开盘价": 2.05,
        "最高价": 2.22,
        "最低价": 2.0,
        "昨收价": 2.09,
        "总市值": 5035674.0,
        "市盈率": -0.43,
        "成交量": 28101.0,
        "成交额": 58897.0,
        "振幅": 10.53,
        "换手率": 1.13,
        "symbol": "105.AEMD"
    },
    {
        "序号": 11112,
        "name": "Merus NV",
        "最新价": 23.93,
        "涨跌额": -0.83,
        "涨跌幅": -3.35,
        "开盘价": 24.56,
        "最高价": 24.92,
        "最低价": 23.76,
        "昨收价": 24.76,
        "总市值": 1381546239.0,
        "市盈率": -7.82,
        "成交量": 344335.0,
        "成交额": 8297963.0,
        "振幅": 4.68,
        "换手率": 0.6,
        "symbol": "105.MRUS"
    },
    {
        "序号": 11113,
        "name": "CollPlant Biotechnologies Ltd",
        "最新价": 5.17,
        "涨跌额": -0.18,
        "涨跌幅": -3.36,
        "开盘价": 5.45,
        "最高价": 5.45,
        "最低价": 5.15,
        "昨收价": 5.35,
        "总市值": 59210314.0,
        "市盈率": -8.8,
        "成交量": 2502.0,
        "成交额": 12966.0,
        "振幅": 5.61,
        "换手率": 0.02,
        "symbol": "105.CLGN"
    },
    {
        "序号": 11114,
        "name": "Mesa Royalty Trust",
        "最新价": 15.72,
        "涨跌额": -0.55,
        "涨跌幅": -3.38,
        "开盘价": 16.28,
        "最高价": 16.3,
        "最低价": 15.64,
        "昨收价": 16.27,
        "总市值": 29295635.0,
        "市盈率": 7.98,
        "成交量": 6026.0,
        "成交额": 96085.0,
        "振幅": 4.06,
        "换手率": 0.32,
        "symbol": "106.MTR"
    },
    {
        "序号": 11115,
        "name": "JOANN Inc",
        "最新价": 0.57,
        "涨跌额": -0.02,
        "涨跌幅": -3.39,
        "开盘价": 0.59,
        "最高价": 0.59,
        "最低价": 0.56,
        "昨收价": 0.59,
        "总市值": 23889641.0,
        "市盈率": -0.1,
        "成交量": 69603.0,
        "成交额": 39303.0,
        "振幅": 5.08,
        "换手率": 0.17,
        "symbol": "105.JOAN"
    },
    {
        "序号": 11116,
        "name": "Generation Bio Co",
        "最新价": 1.71,
        "涨跌额": -0.06,
        "涨跌幅": -3.39,
        "开盘价": 1.77,
        "最高价": 1.93,
        "最低价": 1.6,
        "昨收价": 1.77,
        "总市值": 112993409.0,
        "市盈率": -0.91,
        "成交量": 688980.0,
        "成交额": 1194521.0,
        "振幅": 18.64,
        "换手率": 1.04,
        "symbol": "105.GBIO"
    },
    {
        "序号": 11117,
        "name": "MEDIROM Healthcare Technologies",
        "最新价": 4.53,
        "涨跌额": -0.16,
        "涨跌幅": -3.41,
        "开盘价": 4.52,
        "最高价": 4.54,
        "最低价": 4.4,
        "昨收价": 4.69,
        "总市值": 22117730.0,
        "市盈率": 19.75,
        "成交量": 2357.0,
        "成交额": 10600.0,
        "振幅": 2.99,
        "换手率": 0.05,
        "symbol": "105.MRM"
    },
    {
        "序号": 11118,
        "name": "urban-gro Inc",
        "最新价": 1.13,
        "涨跌额": -0.04,
        "涨跌幅": -3.42,
        "开盘价": 1.16,
        "最高价": 1.17,
        "最低价": 1.02,
        "昨收价": 1.17,
        "总市值": 13187755.0,
        "市盈率": -0.73,
        "成交量": 165455.0,
        "成交额": 179180.0,
        "振幅": 12.82,
        "换手率": 1.42,
        "symbol": "105.UGRO"
    },
    {
        "序号": 11119,
        "name": "IAMGOLD Corp",
        "最新价": 2.26,
        "涨跌额": -0.08,
        "涨跌幅": -3.42,
        "开盘价": 2.32,
        "最高价": 2.32,
        "最低价": 2.21,
        "昨收价": 2.34,
        "总市值": 1087738000.0,
        "市盈率": 8.52,
        "成交量": 5777515.0,
        "成交额": 13039796.0,
        "振幅": 4.7,
        "换手率": 1.2,
        "symbol": "106.IAG"
    },
    {
        "序号": 11120,
        "name": "Akebia Therapeutics Inc",
        "最新价": 1.13,
        "涨跌额": -0.04,
        "涨跌幅": -3.42,
        "开盘价": 1.17,
        "最高价": 1.18,
        "最低价": 1.12,
        "昨收价": 1.17,
        "总市值": 212880039.0,
        "市盈率": -3.74,
        "成交量": 889359.0,
        "成交额": 1018251.0,
        "振幅": 5.13,
        "换手率": 0.47,
        "symbol": "105.AKBA"
    },
    {
        "序号": 11121,
        "name": "GSE Systems Inc",
        "最新价": 3.95,
        "涨跌额": -0.14,
        "涨跌幅": -3.42,
        "开盘价": 4.2,
        "最高价": 4.2,
        "最低价": 3.87,
        "昨收价": 4.09,
        "总市值": 10298602.0,
        "市盈率": -1.29,
        "成交量": 80325.0,
        "成交额": 319991.0,
        "振幅": 8.07,
        "换手率": 3.08,
        "symbol": "105.GVP"
    },
    {
        "序号": 11122,
        "name": "WaveDancer Inc",
        "最新价": 1.69,
        "涨跌额": -0.06,
        "涨跌幅": -3.43,
        "开盘价": 1.75,
        "最高价": 1.81,
        "最低价": 1.51,
        "昨收价": 1.75,
        "总市值": 3402274.0,
        "市盈率": -0.32,
        "成交量": 46570.0,
        "成交额": 79593.0,
        "振幅": 17.14,
        "换手率": 2.31,
        "symbol": "105.WAVD"
    },
    {
        "序号": 11123,
        "name": "Liberty Media Corp Liberty Siri",
        "最新价": 26.17,
        "涨跌额": -0.93,
        "涨跌幅": -3.43,
        "开盘价": 26.17,
        "最高价": 26.17,
        "最低价": 26.17,
        "昨收价": 27.1,
        "总市值": 18699815738.0,
        "市盈率": 15.39,
        "成交量": 275.0,
        "成交额": 7196.0,
        "振幅": 0.0,
        "换手率": 0.0,
        "symbol": "105.LSXMB"
    },
    {
        "序号": 11124,
        "name": "Lesaka Technologies Inc",
        "最新价": 3.37,
        "涨跌额": -0.12,
        "涨跌幅": -3.44,
        "开盘价": 3.41,
        "最高价": 3.6,
        "最低价": 3.35,
        "昨收价": 3.49,
        "总市值": 210235839.0,
        "市盈率": -7.0,
        "成交量": 20086.0,
        "成交额": 68899.0,
        "振幅": 7.16,
        "换手率": 0.03,
        "symbol": "105.LSAK"
    },
    {
        "序号": 11125,
        "name": "Indivior PLC",
        "最新价": 15.15,
        "涨跌额": -0.54,
        "涨跌幅": -3.44,
        "开盘价": 14.93,
        "最高价": 15.15,
        "最低价": 14.87,
        "昨收价": 15.69,
        "总市值": 2079684147.0,
        "市盈率": null,
        "成交量": 60273.0,
        "成交额": 904708.0,
        "振幅": 1.78,
        "换手率": 0.04,
        "symbol": "105.INDV"
    },
    {
        "序号": 11126,
        "name": "National Vision Holdings Inc",
        "最新价": 18.78,
        "涨跌额": -0.67,
        "涨跌幅": -3.44,
        "开盘价": 19.45,
        "最高价": 19.51,
        "最低价": 18.52,
        "昨收价": 19.45,
        "总市值": 1469452725.0,
        "市盈率": -24.83,
        "成交量": 599170.0,
        "成交额": 11271636.0,
        "振幅": 5.09,
        "换手率": 0.77,
        "symbol": "105.EYE"
    },
    {
        "序号": 11127,
        "name": "SoundHound AI Inc Wt",
        "最新价": 0.28,
        "涨跌额": -0.01,
        "涨跌幅": -3.45,
        "开盘价": 0.33,
        "最高价": 0.33,
        "最低价": 0.27,
        "昨收价": 0.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 18165.0,
        "成交额": 5209.0,
        "振幅": 20.69,
        "换手率": null,
        "symbol": "105.SOUNW"
    },
    {
        "序号": 11128,
        "name": "Freight Technologies Inc",
        "最新价": 0.28,
        "涨跌额": -0.01,
        "涨跌幅": -3.45,
        "开盘价": 0.29,
        "最高价": 0.3,
        "最低价": 0.28,
        "昨收价": 0.29,
        "总市值": 3004731.0,
        "市盈率": -0.37,
        "成交量": 76689.0,
        "成交额": 21860.0,
        "振幅": 6.9,
        "换手率": 0.71,
        "symbol": "105.FRGT"
    },
    {
        "序号": 11129,
        "name": "Homology Medicines Inc",
        "最新价": 0.56,
        "涨跌额": -0.02,
        "涨跌幅": -3.45,
        "开盘价": 0.55,
        "最高价": 0.59,
        "最低价": 0.55,
        "昨收价": 0.58,
        "总市值": 32443226.0,
        "市盈率": -0.25,
        "成交量": 174761.0,
        "成交额": 99028.0,
        "振幅": 6.9,
        "换手率": 0.3,
        "symbol": "105.FIXX"
    },
    {
        "序号": 11130,
        "name": "GoHealth Inc-A",
        "最新价": 13.98,
        "涨跌额": -0.5,
        "涨跌幅": -3.45,
        "开盘价": 14.12,
        "最高价": 14.4,
        "最低价": 13.98,
        "昨收价": 14.48,
        "总市值": 312748230.0,
        "市盈率": -2.53,
        "成交量": 9131.0,
        "成交额": 129563.0,
        "振幅": 2.9,
        "换手率": 0.04,
        "symbol": "105.GOCO"
    },
    {
        "序号": 11131,
        "name": "PetMed Express Inc",
        "最新价": 7.25,
        "涨跌额": -0.26,
        "涨跌幅": -3.46,
        "开盘价": 7.52,
        "最高价": 7.59,
        "最低价": 7.18,
        "昨收价": 7.51,
        "总市值": 153315794.0,
        "市盈率": -25.22,
        "成交量": 272944.0,
        "成交额": 1992078.0,
        "振幅": 5.46,
        "换手率": 1.29,
        "symbol": "105.PETS"
    },
    {
        "序号": 11132,
        "name": "Coherus BioSciences Inc",
        "最新价": 2.22,
        "涨跌额": -0.08,
        "涨跌幅": -3.48,
        "开盘价": 2.16,
        "最高价": 2.3,
        "最低价": 2.06,
        "昨收价": 2.3,
        "总市值": 247228417.0,
        "市盈率": -1.14,
        "成交量": 2708561.0,
        "成交额": 5982861.0,
        "振幅": 10.43,
        "换手率": 2.43,
        "symbol": "105.CHRS"
    },
    {
        "序号": 11133,
        "name": "Altus Power Inc-A",
        "最新价": 5.27,
        "涨跌额": -0.19,
        "涨跌幅": -3.48,
        "开盘价": 5.41,
        "最高价": 5.47,
        "最低价": 5.09,
        "昨收价": 5.46,
        "总市值": 843126963.0,
        "市盈率": 9.84,
        "成交量": 1179649.0,
        "成交额": 6222206.0,
        "振幅": 6.96,
        "换手率": 0.74,
        "symbol": "106.AMPS"
    },
    {
        "序号": 11134,
        "name": "Legend Biotech Corp ADR",
        "最新价": 59.28,
        "涨跌额": -2.14,
        "涨跌幅": -3.48,
        "开盘价": 61.54,
        "最高价": 61.74,
        "最低价": 58.65,
        "昨收价": 61.42,
        "总市值": 10806057123.0,
        "市盈率": -21.22,
        "成交量": 1119800.0,
        "成交额": 66808542.0,
        "振幅": 5.03,
        "换手率": 0.61,
        "symbol": "105.LEGN"
    },
    {
        "序号": 11135,
        "name": "味好美",
        "最新价": 66.53,
        "涨跌额": -2.41,
        "涨跌幅": -3.5,
        "开盘价": 67.65,
        "最高价": 67.99,
        "最低价": 66.53,
        "昨收价": 68.94,
        "总市值": 17852114787.0,
        "市盈率": 27.52,
        "成交量": 2098.0,
        "成交额": 140254.0,
        "振幅": 2.12,
        "换手率": 0.01,
        "symbol": "106.MKC_V"
    },
    {
        "序号": 11136,
        "name": "New Gold Inc",
        "最新价": 1.38,
        "涨跌额": -0.05,
        "涨跌幅": -3.5,
        "开盘价": 1.41,
        "最高价": 1.44,
        "最低价": 1.38,
        "昨收价": 1.43,
        "总市值": 944619660.0,
        "市盈率": -17.49,
        "成交量": 7371314.0,
        "成交额": 10328399.0,
        "振幅": 4.2,
        "换手率": 1.08,
        "symbol": "107.NGD"
    },
    {
        "序号": 11137,
        "name": "AngloGold Ashanti plc",
        "最新价": 17.37,
        "涨跌额": -0.63,
        "涨跌幅": -3.5,
        "开盘价": 17.02,
        "最高价": 17.58,
        "最低价": 17.02,
        "昨收价": 18.0,
        "总市值": 7324994971.0,
        "市盈率": null,
        "成交量": 2457371.0,
        "成交额": 42565317.0,
        "振幅": 3.11,
        "换手率": 0.58,
        "symbol": "106.AU"
    },
    {
        "序号": 11138,
        "name": "鑫苑置业",
        "最新价": 2.75,
        "涨跌额": -0.1,
        "涨跌幅": -3.51,
        "开盘价": 2.73,
        "最高价": 2.84,
        "最低价": 2.73,
        "昨收价": 2.85,
        "总市值": 14955394.0,
        "市盈率": -0.06,
        "成交量": 2133.0,
        "成交额": 5891.0,
        "振幅": 3.86,
        "换手率": 0.04,
        "symbol": "106.XIN"
    },
    {
        "序号": 11139,
        "name": "猎豹移动",
        "最新价": 2.19,
        "涨跌额": -0.08,
        "涨跌幅": -3.52,
        "开盘价": 2.27,
        "最高价": 2.42,
        "最低价": 2.19,
        "昨收价": 2.27,
        "总市值": 65592071.0,
        "市盈率": -0.65,
        "成交量": 29733.0,
        "成交额": 69339.0,
        "振幅": 10.13,
        "换手率": 0.1,
        "symbol": "106.CMCM"
    },
    {
        "序号": 11140,
        "name": "二倍做空原油ETF-ProShares",
        "最新价": 21.07,
        "涨跌额": -0.77,
        "涨跌幅": -3.53,
        "开盘价": 21.28,
        "最高价": 21.43,
        "最低价": 20.85,
        "昨收价": 21.84,
        "总市值": null,
        "市盈率": null,
        "成交量": 1613043.0,
        "成交额": 34082836.0,
        "振幅": 2.66,
        "换手率": null,
        "symbol": "107.SCO"
    },
    {
        "序号": 11141,
        "name": "Prenetics Global Ltd-A",
        "最新价": 4.1,
        "涨跌额": -0.15,
        "涨跌幅": -3.53,
        "开盘价": 4.25,
        "最高价": 4.3,
        "最低价": 4.1,
        "昨收价": 4.25,
        "总市值": 49274321.0,
        "市盈率": -1.12,
        "成交量": 6448.0,
        "成交额": 27400.0,
        "振幅": 4.71,
        "换手率": 0.05,
        "symbol": "105.PRE"
    },
    {
        "序号": 11142,
        "name": "Getty Realty Corp",
        "最新价": 28.94,
        "涨跌额": -1.06,
        "涨跌幅": -3.53,
        "开盘价": 29.87,
        "最高价": 30.11,
        "最低价": 28.91,
        "昨收价": 30.0,
        "总市值": 1525189079.0,
        "市盈率": 21.5,
        "成交量": 633867.0,
        "成交额": 18495815.0,
        "振幅": 4.0,
        "换手率": 1.2,
        "symbol": "106.GTY"
    },
    {
        "序号": 11143,
        "name": "Assertio Holdings Inc",
        "最新价": 1.09,
        "涨跌额": -0.04,
        "涨跌幅": -3.54,
        "开盘价": 1.13,
        "最高价": 1.16,
        "最低价": 1.09,
        "昨收价": 1.13,
        "总市值": 103188690.0,
        "市盈率": -0.55,
        "成交量": 812476.0,
        "成交额": 902767.0,
        "振幅": 6.19,
        "换手率": 0.86,
        "symbol": "105.ASRT"
    },
    {
        "序号": 11144,
        "name": "High Tide Inc",
        "最新价": 1.63,
        "涨跌额": -0.06,
        "涨跌幅": -3.55,
        "开盘价": 1.69,
        "最高价": 1.72,
        "最低价": 1.6,
        "昨收价": 1.69,
        "总市值": 122391521.0,
        "市盈率": -2.69,
        "成交量": 203167.0,
        "成交额": 339016.0,
        "振幅": 7.1,
        "换手率": 0.27,
        "symbol": "105.HITI"
    },
    {
        "序号": 11145,
        "name": "ENGlobal Corp",
        "最新价": 1.9,
        "涨跌额": -0.07,
        "涨跌幅": -3.55,
        "开盘价": 1.97,
        "最高价": 1.97,
        "最低价": 1.89,
        "昨收价": 1.97,
        "总市值": 9737500.0,
        "市盈率": -0.43,
        "成交量": 8161.0,
        "成交额": 15719.0,
        "振幅": 4.06,
        "换手率": 0.16,
        "symbol": "105.ENG"
    },
    {
        "序号": 11146,
        "name": "SomaLogic Inc",
        "最新价": 2.44,
        "涨跌额": -0.09,
        "涨跌幅": -3.56,
        "开盘价": 2.51,
        "最高价": 2.54,
        "最低价": 2.35,
        "昨收价": 2.53,
        "总市值": 460377085.0,
        "市盈率": -3.53,
        "成交量": 1820057.0,
        "成交额": 4400014.0,
        "振幅": 7.51,
        "换手率": 0.96,
        "symbol": "105.SLGC"
    },
    {
        "序号": 11147,
        "name": "The InterGroup Corp",
        "最新价": 20.03,
        "涨跌额": -0.74,
        "涨跌幅": -3.56,
        "开盘价": 20.8,
        "最高价": 21.5,
        "最低价": 20.03,
        "昨收价": 20.77,
        "总市值": 44163186.0,
        "市盈率": -5.69,
        "成交量": 2754.0,
        "成交额": 57579.0,
        "振幅": 7.08,
        "换手率": 0.12,
        "symbol": "105.INTG"
    },
    {
        "序号": 11148,
        "name": "Eco Wave Power Global AB (publ)",
        "最新价": 1.35,
        "涨跌额": -0.05,
        "涨跌幅": -3.57,
        "开盘价": 1.4,
        "最高价": 1.4,
        "最低价": 1.31,
        "昨收价": 1.4,
        "总市值": 7491630.0,
        "市盈率": -3.86,
        "成交量": 12488.0,
        "成交额": 16880.0,
        "振幅": 6.43,
        "换手率": 0.23,
        "symbol": "105.WAVE"
    },
    {
        "序号": 11149,
        "name": "Vicarious Surgical Inc-A",
        "最新价": 0.27,
        "涨跌额": -0.01,
        "涨跌幅": -3.57,
        "开盘价": 0.28,
        "最高价": 0.29,
        "最低价": 0.26,
        "昨收价": 0.28,
        "总市值": 47220152.0,
        "市盈率": -0.68,
        "成交量": 784503.0,
        "成交额": 211908.0,
        "振幅": 10.71,
        "换手率": 0.45,
        "symbol": "106.RBOT"
    },
    {
        "序号": 11150,
        "name": "Landsea Homes Corp Wt",
        "最新价": 0.27,
        "涨跌额": -0.01,
        "涨跌幅": -3.57,
        "开盘价": 0.28,
        "最高价": 0.28,
        "最低价": 0.26,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 25372.0,
        "成交额": 6853.0,
        "振幅": 7.14,
        "换手率": null,
        "symbol": "105.LSEAW"
    },
    {
        "序号": 11151,
        "name": "荔枝",
        "最新价": 2.43,
        "涨跌额": -0.09,
        "涨跌幅": -3.57,
        "开盘价": 2.62,
        "最高价": 2.62,
        "最低价": 2.41,
        "昨收价": 2.52,
        "总市值": 12859021.0,
        "市盈率": 2.55,
        "成交量": 38144.0,
        "成交额": 94467.0,
        "振幅": 8.33,
        "换手率": 0.72,
        "symbol": "105.LIZI"
    },
    {
        "序号": 11152,
        "name": "聚好商城",
        "最新价": 2.7,
        "涨跌额": -0.1,
        "涨跌幅": -3.57,
        "开盘价": 2.69,
        "最高价": 3.14,
        "最低价": 2.61,
        "昨收价": 2.8,
        "总市值": 5766873.0,
        "市盈率": -0.54,
        "成交量": 16568.0,
        "成交额": 44389.0,
        "振幅": 18.93,
        "换手率": 0.78,
        "symbol": "105.JWEL"
    },
    {
        "序号": 11153,
        "name": "Aurora Innovation Inc Wt",
        "最新价": 0.27,
        "涨跌额": -0.01,
        "涨跌幅": -3.57,
        "开盘价": 0.3,
        "最高价": 0.3,
        "最低价": 0.27,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 39007.0,
        "成交额": 11303.0,
        "振幅": 10.71,
        "换手率": null,
        "symbol": "105.AUROW"
    },
    {
        "序号": 11154,
        "name": "Alvotech",
        "最新价": 9.17,
        "涨跌额": -0.34,
        "涨跌幅": -3.58,
        "开盘价": 9.54,
        "最高价": 9.59,
        "最低价": 9.08,
        "昨收价": 9.51,
        "总市值": 2439430928.0,
        "市盈率": -4.1,
        "成交量": 131603.0,
        "成交额": 1206246.0,
        "振幅": 5.36,
        "换手率": 0.05,
        "symbol": "105.ALVO"
    },
    {
        "序号": 11155,
        "name": "Illumina Inc",
        "最新价": 112.94,
        "涨跌额": -4.19,
        "涨跌幅": -3.58,
        "开盘价": 116.22,
        "最高价": 117.52,
        "最低价": 112.9,
        "昨收价": 117.13,
        "总市值": 17934872000.0,
        "市盈率": -15.94,
        "成交量": 2338832.0,
        "成交额": 266847469.0,
        "振幅": 3.94,
        "换手率": 1.47,
        "symbol": "105.ILMN"
    },
    {
        "序号": 11156,
        "name": "Editas Medicine Inc",
        "最新价": 10.51,
        "涨跌额": -0.39,
        "涨跌幅": -3.58,
        "开盘价": 10.81,
        "最高价": 11.18,
        "最低价": 10.41,
        "昨收价": 10.9,
        "总市值": 858390461.0,
        "市盈率": -4.4,
        "成交量": 2625910.0,
        "成交额": 28104205.0,
        "振幅": 7.06,
        "换手率": 3.22,
        "symbol": "105.EDIT"
    },
    {
        "序号": 11157,
        "name": "理查森电子",
        "最新价": 13.41,
        "涨跌额": -0.5,
        "涨跌幅": -3.59,
        "开盘价": 13.89,
        "最高价": 14.15,
        "最低价": 13.37,
        "昨收价": 13.91,
        "总市值": 191447074.0,
        "市盈率": 11.11,
        "成交量": 62393.0,
        "成交额": 852048.0,
        "振幅": 5.61,
        "换手率": 0.44,
        "symbol": "105.RELL"
    },
    {
        "序号": 11158,
        "name": "ChromaDex Corp",
        "最新价": 1.34,
        "涨跌额": -0.05,
        "涨跌幅": -3.6,
        "开盘价": 1.4,
        "最高价": 1.41,
        "最低价": 1.33,
        "昨收价": 1.39,
        "总市值": 100666720.0,
        "市盈率": -15.56,
        "成交量": 85643.0,
        "成交额": 117872.0,
        "振幅": 5.76,
        "换手率": 0.11,
        "symbol": "105.CDXC"
    },
    {
        "序号": 11159,
        "name": "Can-Fite BioPharma Ltd ADR",
        "最新价": 2.14,
        "涨跌额": -0.08,
        "涨跌幅": -3.6,
        "开盘价": 2.3,
        "最高价": 2.32,
        "最低价": 1.98,
        "昨收价": 2.22,
        "总市值": 8737173.0,
        "市盈率": -0.97,
        "成交量": 30570.0,
        "成交额": 65839.0,
        "振幅": 15.32,
        "换手率": 0.75,
        "symbol": "107.CANF"
    },
    {
        "序号": 11160,
        "name": "Alzamend Neuro Inc",
        "最新价": 1.07,
        "涨跌额": -0.04,
        "涨跌幅": -3.6,
        "开盘价": 1.11,
        "最高价": 1.11,
        "最低价": 1.03,
        "昨收价": 1.11,
        "总市值": 6919321.0,
        "市盈率": -0.45,
        "成交量": 66391.0,
        "成交额": 71454.0,
        "振幅": 7.21,
        "换手率": 1.03,
        "symbol": "105.ALZN"
    },
    {
        "序号": 11161,
        "name": "Save Foods Inc",
        "最新价": 2.94,
        "涨跌额": -0.11,
        "涨跌幅": -3.61,
        "开盘价": 2.66,
        "最高价": 2.99,
        "最低价": 2.52,
        "昨收价": 3.05,
        "总市值": 4312521.0,
        "市盈率": -0.7,
        "成交量": 1073576.0,
        "成交额": 2998721.0,
        "振幅": 15.41,
        "换手率": 73.19,
        "symbol": "105.SVFD"
    },
    {
        "序号": 11162,
        "name": "Tenon Medical Inc",
        "最新价": 1.6,
        "涨跌额": -0.06,
        "涨跌幅": -3.61,
        "开盘价": 1.62,
        "最高价": 1.7,
        "最低价": 1.58,
        "昨收价": 1.66,
        "总市值": 3953674.0,
        "市盈率": -0.19,
        "成交量": 48064.0,
        "成交额": 78117.0,
        "振幅": 7.23,
        "换手率": 1.95,
        "symbol": "105.TNON"
    },
    {
        "序号": 11163,
        "name": "IZEA Worldwide Inc",
        "最新价": 2.4,
        "涨跌额": -0.09,
        "涨跌幅": -3.61,
        "开盘价": 2.47,
        "最高价": 2.48,
        "最低价": 2.38,
        "昨收价": 2.49,
        "总市值": 37089998.0,
        "市盈率": -5.5,
        "成交量": 21290.0,
        "成交额": 51788.0,
        "振幅": 4.02,
        "换手率": 0.14,
        "symbol": "105.IZEA"
    },
    {
        "序号": 11164,
        "name": "Centessa Pharmaceuticals Plc AD",
        "最新价": 6.65,
        "涨跌额": -0.25,
        "涨跌幅": -3.62,
        "开盘价": 6.91,
        "最高价": 7.09,
        "最低价": 6.45,
        "昨收价": 6.9,
        "总市值": 649235144.0,
        "市盈率": -4.12,
        "成交量": 90461.0,
        "成交额": 611494.0,
        "振幅": 9.28,
        "换手率": 0.09,
        "symbol": "105.CNTA"
    },
    {
        "序号": 11165,
        "name": "华富教育",
        "最新价": 1.86,
        "涨跌额": -0.07,
        "涨跌幅": -3.63,
        "开盘价": 1.88,
        "最高价": 1.88,
        "最低价": 1.82,
        "昨收价": 1.93,
        "总市值": 8203640.0,
        "市盈率": 9.06,
        "成交量": 2967.0,
        "成交额": 5463.0,
        "振幅": 3.11,
        "换手率": 0.07,
        "symbol": "105.WAFU"
    },
    {
        "序号": 11166,
        "name": "Spectral AI Inc",
        "最新价": 2.92,
        "涨跌额": -0.11,
        "涨跌幅": -3.63,
        "开盘价": 2.99,
        "最高价": 3.08,
        "最低价": 2.86,
        "昨收价": 3.03,
        "总市值": 47464410.0,
        "市盈率": -9.31,
        "成交量": 40016.0,
        "成交额": 117133.0,
        "振幅": 7.26,
        "换手率": 0.25,
        "symbol": "105.MDAI"
    },
    {
        "序号": 11167,
        "name": "Exagen Inc",
        "最新价": 1.59,
        "涨跌额": -0.06,
        "涨跌幅": -3.64,
        "开盘价": 1.61,
        "最高价": 1.7,
        "最低价": 1.59,
        "昨收价": 1.65,
        "总市值": 27102865.0,
        "市盈率": -0.83,
        "成交量": 29931.0,
        "成交额": 48552.0,
        "振幅": 6.67,
        "换手率": 0.18,
        "symbol": "105.XGN"
    },
    {
        "序号": 11168,
        "name": "Soluna Holdings Inc Series A Pf",
        "最新价": 3.71,
        "涨跌额": -0.14,
        "涨跌幅": -3.64,
        "开盘价": 3.69,
        "最高价": 4.11,
        "最低价": 3.64,
        "昨收价": 3.85,
        "总市值": 11357219.0,
        "市盈率": null,
        "成交量": 9418.0,
        "成交额": 36870.0,
        "振幅": 12.21,
        "换手率": 0.31,
        "symbol": "105.SLNHP"
    },
    {
        "序号": 11169,
        "name": "安博科-匹兹堡",
        "最新价": 2.65,
        "涨跌额": -0.1,
        "涨跌幅": -3.64,
        "开盘价": 2.75,
        "最高价": 2.82,
        "最低价": 2.5,
        "昨收价": 2.75,
        "总市值": 52644235.0,
        "市盈率": 36.43,
        "成交量": 64661.0,
        "成交额": 167213.0,
        "振幅": 11.64,
        "换手率": 0.33,
        "symbol": "106.AP"
    },
    {
        "序号": 11170,
        "name": "GraniteShares 1.5x Short NVDA D",
        "最新价": 23.3,
        "涨跌额": -0.88,
        "涨跌幅": -3.64,
        "开盘价": 24.05,
        "最高价": 24.18,
        "最低价": 23.29,
        "昨收价": 24.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 44842.0,
        "成交额": 1053898.0,
        "振幅": 3.68,
        "换手率": null,
        "symbol": "105.NVD"
    },
    {
        "序号": 11171,
        "name": "E2open Parent Holdings Inc-A",
        "最新价": 3.7,
        "涨跌额": -0.14,
        "涨跌幅": -3.65,
        "开盘价": 3.8,
        "最高价": 3.94,
        "最低价": 3.67,
        "昨收价": 3.84,
        "总市值": 1256770782.0,
        "市盈率": -2.0,
        "成交量": 2812521.0,
        "成交额": 10570961.0,
        "振幅": 7.03,
        "换手率": 0.83,
        "symbol": "106.ETWO"
    },
    {
        "序号": 11172,
        "name": "Doma Holdings Inc",
        "最新价": 5.28,
        "涨跌额": -0.2,
        "涨跌幅": -3.65,
        "开盘价": 5.37,
        "最高价": 5.65,
        "最低价": 5.28,
        "昨收价": 5.48,
        "总市值": 70945998.0,
        "市盈率": -0.33,
        "成交量": 14485.0,
        "成交额": 78454.0,
        "振幅": 6.75,
        "换手率": 0.11,
        "symbol": "106.DOMA"
    },
    {
        "序号": 11173,
        "name": "Vivos Therapeutics Inc",
        "最新价": 15.31,
        "涨跌额": -0.58,
        "涨跌幅": -3.65,
        "开盘价": 15.58,
        "最高价": 16.21,
        "最低价": 15.12,
        "昨收价": 15.89,
        "总市值": 20392583.0,
        "市盈率": -1.32,
        "成交量": 216706.0,
        "成交额": 3374610.0,
        "振幅": 6.86,
        "换手率": 16.27,
        "symbol": "105.VVOS"
    },
    {
        "序号": 11174,
        "name": "云集",
        "最新价": 0.79,
        "涨跌额": -0.03,
        "涨跌幅": -3.66,
        "开盘价": 0.78,
        "最高价": 0.84,
        "最低价": 0.78,
        "昨收价": 0.82,
        "总市值": 15532110.0,
        "市盈率": -0.79,
        "成交量": 5055.0,
        "成交额": 4012.0,
        "振幅": 7.32,
        "换手率": 0.03,
        "symbol": "105.YJ"
    },
    {
        "序号": 11175,
        "name": "Village Farms国际",
        "最新价": 0.79,
        "涨跌额": -0.03,
        "涨跌幅": -3.66,
        "开盘价": 0.82,
        "最高价": 0.84,
        "最低价": 0.78,
        "昨收价": 0.82,
        "总市值": 87088754.0,
        "市盈率": -1.49,
        "成交量": 403244.0,
        "成交额": 325220.0,
        "振幅": 7.32,
        "换手率": 0.37,
        "symbol": "105.VFF"
    },
    {
        "序号": 11176,
        "name": "La Rosa Holdings Corp",
        "最新价": 1.58,
        "涨跌额": -0.06,
        "涨跌幅": -3.66,
        "开盘价": 1.63,
        "最高价": 1.69,
        "最低价": 1.58,
        "昨收价": 1.64,
        "总市值": 18004084.0,
        "市盈率": -6.57,
        "成交量": 10699.0,
        "成交额": 17502.0,
        "振幅": 6.71,
        "换手率": 0.09,
        "symbol": "105.LRHC"
    },
    {
        "序号": 11177,
        "name": "Nicholas Financial Inc",
        "最新价": 6.84,
        "涨跌额": -0.26,
        "涨跌幅": -3.66,
        "开盘价": 7.02,
        "最高价": 7.09,
        "最低价": 6.66,
        "昨收价": 7.1,
        "总市值": 49856760.0,
        "市盈率": -1.31,
        "成交量": 6099.0,
        "成交额": 42192.0,
        "振幅": 6.06,
        "换手率": 0.08,
        "symbol": "105.NICK"
    },
    {
        "序号": 11178,
        "name": "NewAmsterdam Pharma Co NV Wt",
        "最新价": 2.1,
        "涨跌额": -0.08,
        "涨跌幅": -3.67,
        "开盘价": 2.16,
        "最高价": 2.16,
        "最低价": 1.81,
        "昨收价": 2.18,
        "总市值": null,
        "市盈率": null,
        "成交量": 12242.0,
        "成交额": 24288.0,
        "振幅": 16.06,
        "换手率": null,
        "symbol": "105.NAMSW"
    },
    {
        "序号": 11179,
        "name": "BriaCell Therapeutics Corp",
        "最新价": 4.72,
        "涨跌额": -0.18,
        "涨跌幅": -3.67,
        "开盘价": 4.76,
        "最高价": 4.97,
        "最低价": 4.64,
        "昨收价": 4.9,
        "总市值": 75433747.0,
        "市盈率": -3.72,
        "成交量": 79281.0,
        "成交额": 379145.0,
        "振幅": 6.73,
        "换手率": 0.5,
        "symbol": "105.BCTX"
    },
    {
        "序号": 11180,
        "name": "Comcast Corp Debentures",
        "最新价": 55.0,
        "涨跌额": -2.1,
        "涨跌幅": -3.68,
        "开盘价": 54.6,
        "最高价": 55.0,
        "最低价": 54.6,
        "昨收价": 57.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 674.0,
        "成交额": 36978.0,
        "振幅": 0.7,
        "换手率": null,
        "symbol": "106.CCZ"
    },
    {
        "序号": 11181,
        "name": "Nanobiotix SA ADR",
        "最新价": 5.74,
        "涨跌额": -0.22,
        "涨跌幅": -3.69,
        "开盘价": 5.52,
        "最高价": 5.75,
        "最低价": 5.52,
        "昨收价": 5.96,
        "总市值": 265372093.0,
        "市盈率": -4.15,
        "成交量": 35968.0,
        "成交额": 202607.0,
        "振幅": 3.86,
        "换手率": 0.08,
        "symbol": "105.NBTX"
    },
    {
        "序号": 11182,
        "name": "Four Corners Property Trust Inc",
        "最新价": 24.22,
        "涨跌额": -0.93,
        "涨跌幅": -3.7,
        "开盘价": 25.04,
        "最高价": 25.4,
        "最低价": 24.18,
        "昨收价": 25.15,
        "总市值": 2193489023.0,
        "市盈率": 23.39,
        "成交量": 1333237.0,
        "成交额": 32694337.0,
        "振幅": 4.85,
        "换手率": 1.47,
        "symbol": "106.FCPT"
    },
    {
        "序号": 11183,
        "name": "Surrozen Inc",
        "最新价": 0.52,
        "涨跌额": -0.02,
        "涨跌幅": -3.7,
        "开盘价": 0.55,
        "最高价": 0.6,
        "最低价": 0.52,
        "昨收价": 0.54,
        "总市值": 15899024.0,
        "市盈率": -0.46,
        "成交量": 159640.0,
        "成交额": 88310.0,
        "振幅": 14.81,
        "换手率": 0.52,
        "symbol": "105.SRZN"
    },
    {
        "序号": 11184,
        "name": "Earlyworks Co Ltd ADR",
        "最新价": 0.52,
        "涨跌额": -0.02,
        "涨跌幅": -3.7,
        "开盘价": 0.54,
        "最高价": 0.54,
        "最低价": 0.49,
        "昨收价": 0.54,
        "总市值": 7820488.0,
        "市盈率": -2.74,
        "成交量": 18384.0,
        "成交额": 9304.0,
        "振幅": 9.26,
        "换手率": 0.12,
        "symbol": "105.ELWS"
    },
    {
        "序号": 11185,
        "name": "Bio-Path Holdings Inc",
        "最新价": 0.52,
        "涨跌额": -0.02,
        "涨跌幅": -3.7,
        "开盘价": 0.52,
        "最高价": 0.57,
        "最低价": 0.51,
        "昨收价": 0.54,
        "总市值": 6423385.0,
        "市盈率": -0.39,
        "成交量": 30884.0,
        "成交额": 16389.0,
        "振幅": 11.11,
        "换手率": 0.25,
        "symbol": "105.BPTH"
    },
    {
        "序号": 11186,
        "name": "Techprecision Corp",
        "最新价": 5.71,
        "涨跌额": -0.22,
        "涨跌幅": -3.71,
        "开盘价": 5.94,
        "最高价": 5.94,
        "最低价": 5.35,
        "昨收价": 5.93,
        "总市值": 50033487.0,
        "市盈率": -26.0,
        "成交量": 64135.0,
        "成交额": 361754.0,
        "振幅": 9.95,
        "换手率": 0.73,
        "symbol": "105.TPCS"
    },
    {
        "序号": 11187,
        "name": "First Majestic Silver Corp",
        "最新价": 5.71,
        "涨跌额": -0.22,
        "涨跌幅": -3.71,
        "开盘价": 5.72,
        "最高价": 5.85,
        "最低价": 5.64,
        "昨收价": 5.93,
        "总市值": 1638434977.0,
        "市盈率": -10.1,
        "成交量": 4777853.0,
        "成交额": 27371038.0,
        "振幅": 3.54,
        "换手率": 1.67,
        "symbol": "106.AG"
    },
    {
        "序号": 11188,
        "name": "阿卡迪亚",
        "最新价": 20.98,
        "涨跌额": -0.81,
        "涨跌幅": -3.72,
        "开盘价": 21.73,
        "最高价": 21.73,
        "最低价": 20.87,
        "昨收价": 21.79,
        "总市值": 3444504309.0,
        "市盈率": -23.15,
        "成交量": 1440135.0,
        "成交额": 30418050.0,
        "振幅": 3.95,
        "换手率": 0.88,
        "symbol": "105.ACAD"
    },
    {
        "序号": 11189,
        "name": "LanzaTech Global Inc",
        "最新价": 4.38,
        "涨跌额": -0.17,
        "涨跌幅": -3.74,
        "开盘价": 4.55,
        "最高价": 4.65,
        "最低价": 4.33,
        "昨收价": 4.55,
        "总市值": 858721899.0,
        "市盈率": -13.89,
        "成交量": 93218.0,
        "成交额": 413006.0,
        "振幅": 7.03,
        "换手率": 0.05,
        "symbol": "105.LNZA"
    },
    {
        "序号": 11190,
        "name": "AppTech Payments Corp",
        "最新价": 1.54,
        "涨跌额": -0.06,
        "涨跌幅": -3.75,
        "开盘价": 1.67,
        "最高价": 1.67,
        "最低价": 1.48,
        "昨收价": 1.6,
        "总市值": 31864051.0,
        "市盈率": -1.6,
        "成交量": 70840.0,
        "成交额": 110830.0,
        "振幅": 11.88,
        "换手率": 0.34,
        "symbol": "105.APCX"
    },
    {
        "序号": 11191,
        "name": "Contango ORE Inc",
        "最新价": 21.26,
        "涨跌额": -0.83,
        "涨跌幅": -3.76,
        "开盘价": 22.1,
        "最高价": 22.1,
        "最低价": 21.24,
        "昨收价": 22.09,
        "总市值": 199846721.0,
        "市盈率": -4.36,
        "成交量": 17751.0,
        "成交额": 381047.0,
        "振幅": 3.89,
        "换手率": 0.19,
        "symbol": "107.CTGO"
    },
    {
        "序号": 11192,
        "name": "Geospace Technologies Corp",
        "最新价": 11.47,
        "涨跌额": -0.45,
        "涨跌幅": -3.78,
        "开盘价": 11.87,
        "最高价": 12.03,
        "最低价": 11.3,
        "昨收价": 11.92,
        "总市值": 151375199.0,
        "市盈率": 12.4,
        "成交量": 33596.0,
        "成交额": 387588.0,
        "振幅": 6.12,
        "换手率": 0.25,
        "symbol": "105.GEOS"
    },
    {
        "序号": 11193,
        "name": "Xtant Medical Holdings Inc",
        "最新价": 1.27,
        "涨跌额": -0.05,
        "涨跌幅": -3.79,
        "开盘价": 1.32,
        "最高价": 1.36,
        "最低价": 1.26,
        "昨收价": 1.32,
        "总市值": 165240018.0,
        "市盈率": 60.0,
        "成交量": 41002.0,
        "成交额": 53601.0,
        "振幅": 7.58,
        "换手率": 0.03,
        "symbol": "107.XTNT"
    },
    {
        "序号": 11194,
        "name": "AudioEye Inc",
        "最新价": 4.3,
        "涨跌额": -0.17,
        "涨跌幅": -3.8,
        "开盘价": 4.34,
        "最高价": 4.48,
        "最低价": 4.21,
        "昨收价": 4.47,
        "总市值": 51122059.0,
        "市盈率": -7.04,
        "成交量": 18033.0,
        "成交额": 78507.0,
        "振幅": 6.04,
        "换手率": 0.15,
        "symbol": "105.AEYE"
    },
    {
        "序号": 11195,
        "name": "Immix Biopharma Inc",
        "最新价": 4.8,
        "涨跌额": -0.19,
        "涨跌幅": -3.81,
        "开盘价": 5.02,
        "最高价": 5.02,
        "最低价": 4.67,
        "昨收价": 4.99,
        "总市值": 95362675.0,
        "市盈率": -6.75,
        "成交量": 144307.0,
        "成交额": 699055.0,
        "振幅": 7.01,
        "换手率": 0.73,
        "symbol": "105.IMMX"
    },
    {
        "序号": 11196,
        "name": "Hennessy Advisors Inc Notes",
        "最新价": 24.0,
        "涨跌额": -0.95,
        "涨跌幅": -3.81,
        "开盘价": 24.37,
        "最高价": 24.37,
        "最低价": 24.0,
        "昨收价": 24.95,
        "总市值": null,
        "市盈率": null,
        "成交量": 1431.0,
        "成交额": 34512.0,
        "振幅": 1.48,
        "换手率": null,
        "symbol": "105.HNNAZ"
    },
    {
        "序号": 11197,
        "name": "SCWorx Corp",
        "最新价": 2.02,
        "涨跌额": -0.08,
        "涨跌幅": -3.81,
        "开盘价": 2.18,
        "最高价": 2.18,
        "最低价": 2.02,
        "昨收价": 2.1,
        "总市值": 2446291.0,
        "市盈率": -1.46,
        "成交量": 14542.0,
        "成交额": 30335.0,
        "振幅": 7.62,
        "换手率": 1.2,
        "symbol": "105.WORX"
    },
    {
        "序号": 11198,
        "name": "达乐",
        "最新价": 127.22,
        "涨跌额": -5.08,
        "涨跌幅": -3.84,
        "开盘价": 133.01,
        "最高价": 135.13,
        "最低价": 125.78,
        "昨收价": 132.3,
        "总市值": 27924440654.0,
        "市盈率": 14.55,
        "成交量": 6688719.0,
        "成交额": 857482144.0,
        "振幅": 7.07,
        "换手率": 3.05,
        "symbol": "106.DG"
    },
    {
        "序号": 11199,
        "name": "Yield10 Bioscience Inc",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.22,
        "昨收价": 0.26,
        "总市值": 2995249.0,
        "市盈率": -0.21,
        "成交量": 1013697.0,
        "成交额": 237863.0,
        "振幅": 15.38,
        "换手率": 8.46,
        "symbol": "105.YTEN"
    },
    {
        "序号": 11200,
        "name": "United States Antimony Corp",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.25,
        "最高价": 0.26,
        "最低价": 0.24,
        "昨收价": 0.26,
        "总市值": 26911829.0,
        "市盈率": -7.58,
        "成交量": 378150.0,
        "成交额": 93481.0,
        "振幅": 7.69,
        "换手率": 0.35,
        "symbol": "107.UAMY"
    },
    {
        "序号": 11201,
        "name": "Synaptogenix Inc",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.24,
        "昨收价": 0.26,
        "总市值": 5097708.0,
        "市盈率": -9.1,
        "成交量": 295626.0,
        "成交额": 75309.0,
        "振幅": 7.69,
        "换手率": 1.45,
        "symbol": "105.SNPX"
    },
    {
        "序号": 11202,
        "name": "ReShape Lifesciences Inc",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.23,
        "昨收价": 0.26,
        "总市值": 3283870.0,
        "市盈率": -0.13,
        "成交量": 1831984.0,
        "成交额": 450960.0,
        "振幅": 11.54,
        "换手率": 13.95,
        "symbol": "105.RSLS"
    },
    {
        "序号": 11203,
        "name": "Quantum-Si Inc Wt",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.25,
        "昨收价": 0.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 1796.0,
        "成交额": 448.0,
        "振幅": 3.85,
        "换手率": null,
        "symbol": "105.QSIAW"
    },
    {
        "序号": 11204,
        "name": "昆腾",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.25,
        "昨收价": 0.26,
        "总市值": 23760152.0,
        "市盈率": -0.62,
        "成交量": 474561.0,
        "成交额": 119796.0,
        "振幅": 3.85,
        "换手率": 0.5,
        "symbol": "105.QMCO"
    },
    {
        "序号": 11205,
        "name": "Procaps Group SA",
        "最新价": 3.0,
        "涨跌额": -0.12,
        "涨跌幅": -3.85,
        "开盘价": 3.02,
        "最高价": 3.03,
        "最低价": 2.91,
        "昨收价": 3.12,
        "总市值": 338472549.0,
        "市盈率": 5.08,
        "成交量": 13680.0,
        "成交额": 41162.0,
        "振幅": 3.85,
        "换手率": 0.01,
        "symbol": "105.PROC"
    },
    {
        "序号": 11206,
        "name": "天境生物",
        "最新价": 1.5,
        "涨跌额": -0.06,
        "涨跌幅": -3.85,
        "开盘价": 1.56,
        "最高价": 1.56,
        "最低价": 1.48,
        "昨收价": 1.56,
        "总市值": 123935015.0,
        "市盈率": -0.4,
        "成交量": 238257.0,
        "成交额": 358059.0,
        "振幅": 5.13,
        "换手率": 0.29,
        "symbol": "105.IMAB"
    },
    {
        "序号": 11207,
        "name": "Connexa Sports Technologies Inc",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.25,
        "最高价": 0.26,
        "最低价": 0.23,
        "昨收价": 0.26,
        "总市值": 859044.0,
        "市盈率": -0.02,
        "成交量": 364474.0,
        "成交额": 89796.0,
        "振幅": 11.54,
        "换手率": 10.61,
        "symbol": "105.CNXA"
    },
    {
        "序号": 11208,
        "name": "Perspective Therapeutics Inc",
        "最新价": 0.25,
        "涨跌额": -0.01,
        "涨跌幅": -3.85,
        "开盘价": 0.26,
        "最高价": 0.26,
        "最低价": 0.25,
        "昨收价": 0.26,
        "总市值": 70142757.0,
        "市盈率": -7.33,
        "成交量": 240656.0,
        "成交额": 61011.0,
        "振幅": 3.85,
        "换手率": 0.09,
        "symbol": "107.CATX"
    },
    {
        "序号": 11209,
        "name": "Byrna Technologies Inc",
        "最新价": 5.71,
        "涨跌额": -0.23,
        "涨跌幅": -3.87,
        "开盘价": 5.94,
        "最高价": 6.0,
        "最低价": 5.61,
        "昨收价": 5.94,
        "总市值": 125488824.0,
        "市盈率": -16.74,
        "成交量": 48668.0,
        "成交额": 281783.0,
        "振幅": 6.57,
        "换手率": 0.22,
        "symbol": "105.BYRN"
    },
    {
        "序号": 11210,
        "name": "Enphase Energy Inc",
        "最新价": 103.01,
        "涨跌额": -4.16,
        "涨跌幅": -3.88,
        "开盘价": 107.38,
        "最高价": 108.46,
        "最低价": 101.64,
        "昨收价": 107.17,
        "总市值": 14066140760.0,
        "市盈率": 24.6,
        "成交量": 4408879.0,
        "成交额": 459471184.0,
        "振幅": 6.36,
        "换手率": 3.23,
        "symbol": "105.ENPH"
    },
    {
        "序号": 11211,
        "name": "MeiraGTx Holdings plc",
        "最新价": 4.7,
        "涨跌额": -0.19,
        "涨跌幅": -3.89,
        "开盘价": 4.79,
        "最高价": 4.95,
        "最低价": 4.63,
        "昨收价": 4.89,
        "总市值": 298906610.0,
        "市盈率": -2.27,
        "成交量": 339149.0,
        "成交额": 1605805.0,
        "振幅": 6.54,
        "换手率": 0.53,
        "symbol": "105.MGTX"
    },
    {
        "序号": 11212,
        "name": "Leap Therapeutics Inc",
        "最新价": 2.72,
        "涨跌额": -0.11,
        "涨跌幅": -3.89,
        "开盘价": 2.81,
        "最高价": 2.89,
        "最低价": 2.71,
        "昨收价": 2.83,
        "总市值": 69537926.0,
        "市盈率": -0.86,
        "成交量": 90680.0,
        "成交额": 253267.0,
        "振幅": 6.36,
        "换手率": 0.35,
        "symbol": "105.LPTX"
    },
    {
        "序号": 11213,
        "name": "T-Rex 2X Inverse NVIDIA Daily T",
        "最新价": 22.73,
        "涨跌额": -0.92,
        "涨跌幅": -3.89,
        "开盘价": 22.95,
        "最高价": 22.97,
        "最低价": 22.55,
        "昨收价": 23.65,
        "总市值": null,
        "市盈率": null,
        "成交量": 14396.0,
        "成交额": 326401.0,
        "振幅": 1.78,
        "换手率": null,
        "symbol": "107.NVDQ"
    },
    {
        "序号": 11214,
        "name": "GameSquare Holdings Inc",
        "最新价": 1.72,
        "涨跌额": -0.07,
        "涨跌幅": -3.91,
        "开盘价": 1.73,
        "最高价": 1.8,
        "最低价": 1.72,
        "昨收价": 1.79,
        "总市值": 22232424.0,
        "市盈率": -1.29,
        "成交量": 25609.0,
        "成交额": 44552.0,
        "振幅": 4.47,
        "换手率": 0.2,
        "symbol": "105.GAME"
    },
    {
        "序号": 11215,
        "name": "ThredUp Inc-A",
        "最新价": 2.21,
        "涨跌额": -0.09,
        "涨跌幅": -3.91,
        "开盘价": 2.26,
        "最高价": 2.29,
        "最低价": 2.18,
        "昨收价": 2.3,
        "总市值": 237023632.0,
        "市盈率": -3.11,
        "成交量": 701953.0,
        "成交额": 1563675.0,
        "振幅": 4.78,
        "换手率": 0.65,
        "symbol": "105.TDUP"
    },
    {
        "序号": 11216,
        "name": "VivoPower International PLC",
        "最新价": 1.71,
        "涨跌额": -0.07,
        "涨跌幅": -3.93,
        "开盘价": 1.78,
        "最高价": 1.78,
        "最低价": 1.71,
        "昨收价": 1.78,
        "总市值": 4939892.0,
        "市盈率": -0.2,
        "成交量": 36741.0,
        "成交额": 64492.0,
        "振幅": 3.93,
        "换手率": 1.27,
        "symbol": "105.VVPR"
    },
    {
        "序号": 11217,
        "name": "Reliance Global Group Inc",
        "最新价": 0.73,
        "涨跌额": -0.03,
        "涨跌幅": -3.95,
        "开盘价": 0.68,
        "最高价": 0.76,
        "最低价": 0.68,
        "昨收价": 0.76,
        "总市值": 1805917.0,
        "市盈率": -0.08,
        "成交量": 227219.0,
        "成交额": 165104.0,
        "振幅": 10.53,
        "换手率": 9.18,
        "symbol": "105.RELI"
    },
    {
        "序号": 11218,
        "name": "AcelRx Pharmaceuticals Inc",
        "最新价": 0.73,
        "涨跌额": -0.03,
        "涨跌幅": -3.95,
        "开盘价": 0.75,
        "最高价": 0.78,
        "最低价": 0.73,
        "昨收价": 0.76,
        "总市值": 12375156.0,
        "市盈率": -0.58,
        "成交量": 55621.0,
        "成交额": 42216.0,
        "振幅": 6.58,
        "换手率": 0.33,
        "symbol": "105.ACRX"
    },
    {
        "序号": 11219,
        "name": "Aspira Women’s Health Inc",
        "最新价": 3.63,
        "涨跌额": -0.15,
        "涨跌幅": -3.97,
        "开盘价": 3.8,
        "最高价": 3.85,
        "最低价": 3.4,
        "昨收价": 3.78,
        "总市值": 37522707.0,
        "市盈率": -2.42,
        "成交量": 48823.0,
        "成交额": 180105.0,
        "振幅": 11.9,
        "换手率": 0.47,
        "symbol": "105.AWH"
    },
    {
        "序号": 11220,
        "name": "Rhythm Pharmaceuticals Inc",
        "最新价": 36.49,
        "涨跌额": -1.51,
        "涨跌幅": -3.97,
        "开盘价": 37.34,
        "最高价": 38.64,
        "最低价": 36.45,
        "昨收价": 38.0,
        "总市值": 2156429606.0,
        "市盈率": -11.62,
        "成交量": 462616.0,
        "成交额": 17303796.0,
        "振幅": 5.76,
        "换手率": 0.78,
        "symbol": "105.RYTM"
    },
    {
        "序号": 11221,
        "name": "亿咖通科技",
        "最新价": 2.41,
        "涨跌额": -0.1,
        "涨跌幅": -3.98,
        "开盘价": 2.53,
        "最高价": 2.56,
        "最低价": 2.36,
        "昨收价": 2.51,
        "总市值": 813122890.0,
        "市盈率": -2.55,
        "成交量": 82266.0,
        "成交额": 200956.0,
        "振幅": 7.97,
        "换手率": 0.02,
        "symbol": "105.ECX"
    },
    {
        "序号": 11222,
        "name": "MicroSectors Gold 3X Leveraged ",
        "最新价": 25.74,
        "涨跌额": -1.07,
        "涨跌幅": -3.99,
        "开盘价": 25.89,
        "最高价": 26.13,
        "最低价": 25.5,
        "昨收价": 26.81,
        "总市值": 18556201804.0,
        "市盈率": 5.85,
        "成交量": 8957.0,
        "成交额": 230912.0,
        "振幅": 2.35,
        "换手率": null,
        "symbol": "107.SHNY"
    },
    {
        "序号": 11223,
        "name": "Zedge Inc-B",
        "最新价": 1.92,
        "涨跌额": -0.08,
        "涨跌幅": -4.0,
        "开盘价": 2.0,
        "最高价": 2.02,
        "最低价": 1.92,
        "昨收价": 2.0,
        "总市值": 27560780.0,
        "市盈率": -4.52,
        "成交量": 41647.0,
        "成交额": 83087.0,
        "振幅": 5.0,
        "换手率": 0.29,
        "symbol": "107.ZDGE"
    },
    {
        "序号": 11224,
        "name": "Zapp Electric Vehicles Group Lt",
        "最新价": 0.24,
        "涨跌额": -0.01,
        "涨跌幅": -4.0,
        "开盘价": 0.25,
        "最高价": 0.26,
        "最低价": 0.23,
        "昨收价": 0.25,
        "总市值": 13311668.0,
        "市盈率": -7.76,
        "成交量": 80229.0,
        "成交额": 19248.0,
        "振幅": 12.0,
        "换手率": 0.14,
        "symbol": "105.ZAPP"
    },
    {
        "序号": 11225,
        "name": "Americas Gold and Silver Corp",
        "最新价": 0.24,
        "涨跌额": -0.01,
        "涨跌幅": -4.0,
        "开盘价": 0.24,
        "最高价": 0.25,
        "最低价": 0.24,
        "昨收价": 0.25,
        "总市值": 52098634.0,
        "市盈率": -1.43,
        "成交量": 317177.0,
        "成交额": 78456.0,
        "振幅": 4.0,
        "换手率": 0.15,
        "symbol": "107.USAS"
    },
    {
        "序号": 11226,
        "name": "Lavoro Ltd Wt",
        "最新价": 0.48,
        "涨跌额": -0.02,
        "涨跌幅": -4.0,
        "开盘价": 0.48,
        "最高价": 0.5,
        "最低价": 0.46,
        "昨收价": 0.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 24708.0,
        "成交额": 11711.0,
        "振幅": 8.0,
        "换手率": null,
        "symbol": "105.LVROW"
    },
    {
        "序号": 11227,
        "name": "Gamer Pakistan Inc",
        "最新价": 0.48,
        "涨跌额": -0.02,
        "涨跌幅": -4.0,
        "开盘价": 0.53,
        "最高价": 0.55,
        "最低价": 0.48,
        "昨收价": 0.5,
        "总市值": 12278073.0,
        "市盈率": -25.75,
        "成交量": 53265.0,
        "成交额": 27395.0,
        "振幅": 14.0,
        "换手率": 0.21,
        "symbol": "105.GPAK"
    },
    {
        "序号": 11228,
        "name": "Camber Energy Inc",
        "最新价": 0.24,
        "涨跌额": -0.01,
        "涨跌幅": -4.0,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.25,
        "总市值": 25807500.0,
        "市盈率": -0.22,
        "成交量": 3134083.0,
        "成交额": 753007.0,
        "振幅": 8.0,
        "换手率": 2.91,
        "symbol": "107.CEI"
    },
    {
        "序号": 11229,
        "name": "Alset Inc",
        "最新价": 0.96,
        "涨跌额": -0.04,
        "涨跌幅": -4.0,
        "开盘价": 1.0,
        "最高价": 1.03,
        "最低价": 0.96,
        "昨收价": 1.0,
        "总市值": 8865714.0,
        "市盈率": -0.23,
        "成交量": 44617.0,
        "成交额": 44210.0,
        "振幅": 7.0,
        "换手率": 0.48,
        "symbol": "105.AEI"
    },
    {
        "序号": 11230,
        "name": "三倍做多FTSE中国ETF-Direxion",
        "最新价": 19.41,
        "涨跌额": -0.81,
        "涨跌幅": -4.01,
        "开盘价": 19.65,
        "最高价": 19.8,
        "最低价": 19.3,
        "昨收价": 20.22,
        "总市值": null,
        "市盈率": null,
        "成交量": 5472549.0,
        "成交额": 106847922.0,
        "振幅": 2.47,
        "换手率": null,
        "symbol": "107.YINN"
    },
    {
        "序号": 11231,
        "name": "Agiliti Inc",
        "最新价": 7.42,
        "涨跌额": -0.31,
        "涨跌幅": -4.01,
        "开盘价": 7.7,
        "最高价": 7.86,
        "最低价": 7.4,
        "昨收价": 7.73,
        "总市值": 1000545723.0,
        "市盈率": -96.74,
        "成交量": 241490.0,
        "成交额": 1821145.0,
        "振幅": 5.95,
        "换手率": 0.18,
        "symbol": "106.AGTI"
    },
    {
        "序号": 11232,
        "name": "Kineta Inc",
        "最新价": 4.29,
        "涨跌额": -0.18,
        "涨跌幅": -4.03,
        "开盘价": 4.3,
        "最高价": 4.48,
        "最低价": 3.9,
        "昨收价": 4.47,
        "总市值": 44588402.0,
        "市盈率": -0.76,
        "成交量": 25619.0,
        "成交额": 109361.0,
        "振幅": 12.98,
        "换手率": 0.25,
        "symbol": "105.KA"
    },
    {
        "序号": 11233,
        "name": "INVO BioScience Inc",
        "最新价": 1.43,
        "涨跌额": -0.06,
        "涨跌幅": -4.03,
        "开盘价": 1.45,
        "最高价": 1.47,
        "最低价": 1.33,
        "昨收价": 1.49,
        "总市值": 3538901.0,
        "市盈率": -0.4,
        "成交量": 151849.0,
        "成交额": 214028.0,
        "振幅": 9.4,
        "换手率": 6.14,
        "symbol": "105.INVO"
    },
    {
        "序号": 11234,
        "name": "FG Group Holdings Inc",
        "最新价": 1.43,
        "涨跌额": -0.06,
        "涨跌幅": -4.03,
        "开盘价": 1.37,
        "最高价": 1.45,
        "最低价": 1.37,
        "昨收价": 1.49,
        "总市值": 28182703.0,
        "市盈率": -2.53,
        "成交量": 14916.0,
        "成交额": 21196.0,
        "振幅": 5.37,
        "换手率": 0.08,
        "symbol": "107.FGH"
    },
    {
        "序号": 11235,
        "name": "Embotelladora Andina SA ADR-B",
        "最新价": 14.0,
        "涨跌额": -0.59,
        "涨跌幅": -4.04,
        "开盘价": 14.63,
        "最高价": 15.16,
        "最低价": 12.5,
        "昨收价": 14.59,
        "总市值": 1104323040.0,
        "市盈率": 12.75,
        "成交量": 22265.0,
        "成交额": 306353.0,
        "振幅": 18.23,
        "换手率": 0.03,
        "symbol": "106.AKO_B"
    },
    {
        "序号": 11236,
        "name": "Immunic Inc",
        "最新价": 1.18,
        "涨跌额": -0.05,
        "涨跌幅": -4.07,
        "开盘价": 1.23,
        "最高价": 1.24,
        "最低价": 1.18,
        "昨收价": 1.23,
        "总市值": 53271552.0,
        "市盈率": -0.41,
        "成交量": 236947.0,
        "成交额": 285715.0,
        "振幅": 4.88,
        "换手率": 0.52,
        "symbol": "105.IMUX"
    },
    {
        "序号": 11237,
        "name": "Actelis Networks Inc",
        "最新价": 1.18,
        "涨跌额": -0.05,
        "涨跌幅": -4.07,
        "开盘价": 1.23,
        "最高价": 1.23,
        "最低价": 1.1,
        "昨收价": 1.23,
        "总市值": 3205394.0,
        "市盈率": -0.47,
        "成交量": 36527.0,
        "成交额": 41935.0,
        "振幅": 10.57,
        "换手率": 1.34,
        "symbol": "105.ASNS"
    },
    {
        "序号": 11238,
        "name": "Rocket Pharmaceuticals Inc",
        "最新价": 25.23,
        "涨跌额": -1.07,
        "涨跌幅": -4.07,
        "开盘价": 26.14,
        "最高价": 26.59,
        "最低价": 25.22,
        "昨收价": 26.3,
        "总市值": 2274708921.0,
        "市盈率": -9.0,
        "成交量": 1272874.0,
        "成交额": 32880905.0,
        "振幅": 5.21,
        "换手率": 1.41,
        "symbol": "105.RCKT"
    },
    {
        "序号": 11239,
        "name": "Genasys Inc",
        "最新价": 1.65,
        "涨跌额": -0.07,
        "涨跌幅": -4.07,
        "开盘价": 1.75,
        "最高价": 1.75,
        "最低价": 1.61,
        "昨收价": 1.72,
        "总市值": 72644750.0,
        "市盈率": -3.95,
        "成交量": 144961.0,
        "成交额": 241581.0,
        "振幅": 8.14,
        "换手率": 0.33,
        "symbol": "105.GNSS"
    },
    {
        "序号": 11240,
        "name": "Alnylam Pharmaceuticals Inc",
        "最新价": 170.04,
        "涨跌额": -7.22,
        "涨跌幅": -4.07,
        "开盘价": 175.03,
        "最高价": 175.03,
        "最低价": 170.0,
        "昨收价": 177.26,
        "总市值": 21338817307.0,
        "市盈率": -41.85,
        "成交量": 467361.0,
        "成交额": 80096244.0,
        "振幅": 2.84,
        "换手率": 0.37,
        "symbol": "105.ALNY"
    },
    {
        "序号": 11241,
        "name": "Intuitive Machines Inc-A",
        "最新价": 3.06,
        "涨跌额": -0.13,
        "涨跌幅": -4.08,
        "开盘价": 3.22,
        "最高价": 3.22,
        "最低价": 3.02,
        "昨收价": 3.19,
        "总市值": 281326926.0,
        "市盈率": 4.22,
        "成交量": 339374.0,
        "成交额": 1047977.0,
        "振幅": 6.27,
        "换手率": 0.37,
        "symbol": "105.LUNR"
    },
    {
        "序号": 11242,
        "name": "Replimune Group Inc",
        "最新价": 7.29,
        "涨跌额": -0.31,
        "涨跌幅": -4.08,
        "开盘价": 7.6,
        "最高价": 7.6,
        "最低价": 7.05,
        "昨收价": 7.6,
        "总市值": 430542610.0,
        "市盈率": -2.17,
        "成交量": 1701584.0,
        "成交额": 12297277.0,
        "振幅": 7.24,
        "换手率": 2.88,
        "symbol": "105.REPL"
    },
    {
        "序号": 11243,
        "name": "Akanda Corp",
        "最新价": 0.47,
        "涨跌额": -0.02,
        "涨跌幅": -4.08,
        "开盘价": 0.49,
        "最高价": 0.49,
        "最低价": 0.47,
        "昨收价": 0.49,
        "总市值": 1825849.0,
        "市盈率": -0.16,
        "成交量": 22502.0,
        "成交额": 10828.0,
        "振幅": 4.08,
        "换手率": 0.58,
        "symbol": "105.AKAN"
    },
    {
        "序号": 11244,
        "name": "Live Ventures Inc",
        "最新价": 23.7,
        "涨跌额": -1.01,
        "涨跌幅": -4.09,
        "开盘价": 24.5,
        "最高价": 24.65,
        "最低价": 23.7,
        "昨收价": 24.71,
        "总市值": 74994621.0,
        "市盈率": 19.6,
        "成交量": 1841.0,
        "成交额": 44666.0,
        "振幅": 3.84,
        "换手率": 0.06,
        "symbol": "105.LIVE"
    },
    {
        "序号": 11245,
        "name": "CymaBay Therapeutics Inc",
        "最新价": 20.88,
        "涨跌额": -0.89,
        "涨跌幅": -4.09,
        "开盘价": 21.62,
        "最高价": 21.88,
        "最低价": 20.72,
        "昨收价": 21.77,
        "总市值": 2367862011.0,
        "市盈率": -26.28,
        "成交量": 2041326.0,
        "成交额": 43179025.0,
        "振幅": 5.33,
        "换手率": 1.8,
        "symbol": "105.CBAY"
    },
    {
        "序号": 11246,
        "name": "Shapeways Holdings Inc",
        "最新价": 2.34,
        "涨跌额": -0.1,
        "涨跌幅": -4.1,
        "开盘价": 2.34,
        "最高价": 2.43,
        "最低价": 2.34,
        "昨收价": 2.44,
        "总市值": 15322023.0,
        "市盈率": -0.38,
        "成交量": 12177.0,
        "成交额": 28891.0,
        "振幅": 3.69,
        "换手率": 0.19,
        "symbol": "105.SHPW"
    },
    {
        "序号": 11247,
        "name": "二倍做多金矿指数ETF-Direxion",
        "最新价": 31.55,
        "涨跌额": -1.35,
        "涨跌幅": -4.1,
        "开盘价": 31.48,
        "最高价": 32.41,
        "最低价": 30.79,
        "昨收价": 32.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 3191159.0,
        "成交额": 100719670.0,
        "振幅": 4.92,
        "换手率": null,
        "symbol": "107.NUGT"
    },
    {
        "序号": 11248,
        "name": "Qualigen Therapeutics Inc",
        "最新价": 0.7,
        "涨跌额": -0.03,
        "涨跌幅": -4.11,
        "开盘价": 0.71,
        "最高价": 0.74,
        "最低价": 0.7,
        "昨收价": 0.73,
        "总市值": 3626741.0,
        "市盈率": -0.21,
        "成交量": 17858.0,
        "成交额": 12536.0,
        "振幅": 5.48,
        "换手率": 0.34,
        "symbol": "105.QLGN"
    },
    {
        "序号": 11249,
        "name": "RayzeBio Inc",
        "最新价": 25.4,
        "涨跌额": -1.09,
        "涨跌幅": -4.11,
        "开盘价": 27.0,
        "最高价": 27.12,
        "最低价": 25.22,
        "昨收价": 26.49,
        "总市值": 1521955351.0,
        "市盈率": -23.97,
        "成交量": 139727.0,
        "成交额": 3643497.0,
        "振幅": 7.17,
        "换手率": 0.23,
        "symbol": "105.RYZB"
    },
    {
        "序号": 11250,
        "name": "Protalix BioTherapeutics Inc",
        "最新价": 1.63,
        "涨跌额": -0.07,
        "涨跌幅": -4.12,
        "开盘价": 1.67,
        "最高价": 1.69,
        "最低价": 1.62,
        "昨收价": 1.7,
        "总市值": 118911962.0,
        "市盈率": 11.2,
        "成交量": 196461.0,
        "成交额": 323132.0,
        "振幅": 4.12,
        "换手率": 0.27,
        "symbol": "107.PLX"
    },
    {
        "序号": 11251,
        "name": "M-tron Industries Inc",
        "最新价": 30.95,
        "涨跌额": -1.33,
        "涨跌幅": -4.12,
        "开盘价": 32.28,
        "最高价": 32.99,
        "最低价": 30.0,
        "昨收价": 32.28,
        "总市值": 86284267.0,
        "市盈率": 23.93,
        "成交量": 70129.0,
        "成交额": 2176297.0,
        "振幅": 9.26,
        "换手率": 2.52,
        "symbol": "107.MPTI"
    },
    {
        "序号": 11252,
        "name": "Exscientia plc ADR",
        "最新价": 5.58,
        "涨跌额": -0.24,
        "涨跌幅": -4.12,
        "开盘价": 5.82,
        "最高价": 5.85,
        "最低价": 5.38,
        "昨收价": 5.82,
        "总市值": 660170509.0,
        "市盈率": -3.59,
        "成交量": 824787.0,
        "成交额": 4597015.0,
        "振幅": 8.08,
        "换手率": 0.7,
        "symbol": "105.EXAI"
    },
    {
        "序号": 11253,
        "name": "Collective Audience Inc",
        "最新价": 1.86,
        "涨跌额": -0.08,
        "涨跌幅": -4.12,
        "开盘价": 1.92,
        "最高价": 1.96,
        "最低价": 1.72,
        "昨收价": 1.94,
        "总市值": 24533891.0,
        "市盈率": -14.85,
        "成交量": 1162184.0,
        "成交额": 2125119.0,
        "振幅": 12.37,
        "换手率": 8.81,
        "symbol": "105.CAUD"
    },
    {
        "序号": 11254,
        "name": "908 Devices Inc",
        "最新价": 6.97,
        "涨跌额": -0.3,
        "涨跌幅": -4.13,
        "开盘价": 7.25,
        "最高价": 7.32,
        "最低价": 6.89,
        "昨收价": 7.27,
        "总市值": 225682418.0,
        "市盈率": -5.82,
        "成交量": 92025.0,
        "成交额": 648073.0,
        "振幅": 5.91,
        "换手率": 0.28,
        "symbol": "105.MASS"
    },
    {
        "序号": 11255,
        "name": "Lulu’s Fashion Lounge Holdings",
        "最新价": 2.31,
        "涨跌额": -0.1,
        "涨跌幅": -4.15,
        "开盘价": 2.41,
        "最高价": 2.41,
        "最低价": 2.2,
        "昨收价": 2.41,
        "总市值": 93493903.0,
        "市盈率": -5.39,
        "成交量": 17600.0,
        "成交额": 40459.0,
        "振幅": 8.71,
        "换手率": 0.04,
        "symbol": "105.LVLU"
    },
    {
        "序号": 11256,
        "name": "MicroSectors U.S. Big Banks Ind",
        "最新价": 8.07,
        "涨跌额": -0.35,
        "涨跌幅": -4.16,
        "开盘价": 8.38,
        "最高价": 8.45,
        "最低价": 7.97,
        "昨收价": 8.42,
        "总市值": 5817736929.0,
        "市盈率": 1.83,
        "成交量": 499797.0,
        "成交额": 4064525.0,
        "振幅": 5.7,
        "换手率": null,
        "symbol": "107.BNKD"
    },
    {
        "序号": 11257,
        "name": "22nd Century Group Inc",
        "最新价": 0.23,
        "涨跌额": -0.01,
        "涨跌幅": -4.17,
        "开盘价": 0.23,
        "最高价": 0.23,
        "最低价": 0.21,
        "昨收价": 0.24,
        "总市值": 7101979.0,
        "市盈率": -0.05,
        "成交量": 1064532.0,
        "成交额": 236590.0,
        "振幅": 8.33,
        "换手率": 3.45,
        "symbol": "105.XXII"
    },
    {
        "序号": 11258,
        "name": "Sunworks Inc",
        "最新价": 0.23,
        "涨跌额": -0.01,
        "涨跌幅": -4.17,
        "开盘价": 0.24,
        "最高价": 0.25,
        "最低价": 0.23,
        "昨收价": 0.24,
        "总市值": 13060354.0,
        "市盈率": -0.21,
        "成交量": 1907766.0,
        "成交额": 446427.0,
        "振幅": 8.33,
        "换手率": 3.36,
        "symbol": "105.SUNW"
    },
    {
        "序号": 11259,
        "name": "Synchronoss Technologies Inc",
        "最新价": 0.46,
        "涨跌额": -0.02,
        "涨跌幅": -4.17,
        "开盘价": 0.49,
        "最高价": 0.49,
        "最低价": 0.45,
        "昨收价": 0.48,
        "总市值": 42934915.0,
        "市盈率": -1.2,
        "成交量": 549139.0,
        "成交额": 257785.0,
        "振幅": 8.33,
        "换手率": 0.59,
        "symbol": "105.SNCR"
    },
    {
        "序号": 11260,
        "name": "Better Therapeutics Inc",
        "最新价": 0.23,
        "涨跌额": -0.01,
        "涨跌幅": -4.17,
        "开盘价": 0.24,
        "最高价": 0.25,
        "最低价": 0.22,
        "昨收价": 0.24,
        "总市值": 10902782.0,
        "市盈率": -0.35,
        "成交量": 491747.0,
        "成交额": 113841.0,
        "振幅": 12.5,
        "换手率": 1.04,
        "symbol": "105.BTTX"
    },
    {
        "序号": 11261,
        "name": "DatChat Inc",
        "最新价": 1.82,
        "涨跌额": -0.08,
        "涨跌幅": -4.21,
        "开盘价": 1.85,
        "最高价": 1.92,
        "最低价": 1.74,
        "昨收价": 1.9,
        "总市值": 3828044.0,
        "市盈率": -0.36,
        "成交量": 2929.0,
        "成交额": 5311.0,
        "振幅": 9.47,
        "换手率": 0.14,
        "symbol": "105.DATS"
    },
    {
        "序号": 11262,
        "name": "Sage Therapeutics Inc",
        "最新价": 20.01,
        "涨跌额": -0.88,
        "涨跌幅": -4.21,
        "开盘价": 20.76,
        "最高价": 20.95,
        "最低价": 19.98,
        "昨收价": 20.89,
        "总市值": 1199949995.0,
        "市盈率": -1.83,
        "成交量": 779309.0,
        "成交额": 15695162.0,
        "振幅": 4.64,
        "换手率": 1.3,
        "symbol": "105.SAGE"
    },
    {
        "序号": 11263,
        "name": "Longeveron Inc-A",
        "最新价": 2.27,
        "涨跌额": -0.1,
        "涨跌幅": -4.22,
        "开盘价": 2.37,
        "最高价": 2.37,
        "最低价": 2.25,
        "昨收价": 2.37,
        "总市值": 53915027.0,
        "市盈率": -2.72,
        "成交量": 46300.0,
        "成交额": 106845.0,
        "振幅": 5.06,
        "换手率": 0.19,
        "symbol": "105.LGVN"
    },
    {
        "序号": 11264,
        "name": "Ciena科技",
        "最新价": 44.35,
        "涨跌额": -1.96,
        "涨跌幅": -4.23,
        "开盘价": 46.55,
        "最高价": 46.92,
        "最低价": 43.59,
        "昨收价": 46.31,
        "总市值": 6423207750.0,
        "市盈率": 25.21,
        "成交量": 4528087.0,
        "成交额": 201685218.0,
        "振幅": 7.19,
        "换手率": 3.13,
        "symbol": "106.CIEN"
    },
    {
        "序号": 11265,
        "name": "Molecular Partners AG ADR",
        "最新价": 4.29,
        "涨跌额": -0.19,
        "涨跌幅": -4.24,
        "开盘价": 4.32,
        "最高价": 4.32,
        "最低价": 4.26,
        "昨收价": 4.48,
        "总市值": 154631789.0,
        "市盈率": -2.68,
        "成交量": 629.0,
        "成交额": 2706.0,
        "振幅": 1.34,
        "换手率": 0.0,
        "symbol": "105.MOLN"
    },
    {
        "序号": 11266,
        "name": "TPI Composites Inc",
        "最新价": 2.25,
        "涨跌额": -0.1,
        "涨跌幅": -4.26,
        "开盘价": 2.35,
        "最高价": 2.4,
        "最低价": 2.18,
        "昨收价": 2.35,
        "总市值": 95785263.0,
        "市盈率": -0.51,
        "成交量": 954179.0,
        "成交额": 2143038.0,
        "振幅": 9.36,
        "换手率": 2.24,
        "symbol": "105.TPIC"
    },
    {
        "序号": 11267,
        "name": "奥斯汀科技",
        "最新价": 0.9,
        "涨跌额": -0.04,
        "涨跌幅": -4.26,
        "开盘价": 0.93,
        "最高价": 0.94,
        "最低价": 0.87,
        "昨收价": 0.94,
        "总市值": 12605625.0,
        "市盈率": -2.1,
        "成交量": 218162.0,
        "成交额": 197339.0,
        "振幅": 7.45,
        "换手率": 1.56,
        "symbol": "105.OST"
    },
    {
        "序号": 11268,
        "name": "LanzaTech Global Inc Wt",
        "最新价": 0.45,
        "涨跌额": -0.02,
        "涨跌幅": -4.26,
        "开盘价": 0.45,
        "最高价": 0.53,
        "最低价": 0.45,
        "昨收价": 0.47,
        "总市值": null,
        "市盈率": null,
        "成交量": 39477.0,
        "成交额": 18983.0,
        "振幅": 17.02,
        "换手率": null,
        "symbol": "105.LNZAW"
    },
    {
        "序号": 11269,
        "name": "Fathom Holdings Inc",
        "最新价": 2.25,
        "涨跌额": -0.1,
        "涨跌幅": -4.26,
        "开盘价": 2.28,
        "最高价": 2.37,
        "最低价": 2.18,
        "昨收价": 2.35,
        "总市值": 45393669.0,
        "市盈率": -1.78,
        "成交量": 36549.0,
        "成交额": 83132.0,
        "振幅": 8.09,
        "换手率": 0.18,
        "symbol": "105.FTHM"
    },
    {
        "序号": 11270,
        "name": "Anghami Inc",
        "最新价": 1.35,
        "涨跌额": -0.06,
        "涨跌幅": -4.26,
        "开盘价": 1.41,
        "最高价": 1.48,
        "最低价": 1.35,
        "昨收价": 1.41,
        "总市值": 37333765.0,
        "市盈率": -0.61,
        "成交量": 71221.0,
        "成交额": 99379.0,
        "振幅": 9.22,
        "换手率": 0.26,
        "symbol": "105.ANGH"
    },
    {
        "序号": 11271,
        "name": "LuxUrban Hotels Inc",
        "最新价": 4.49,
        "涨跌额": -0.2,
        "涨跌幅": -4.26,
        "开盘价": 4.66,
        "最高价": 4.88,
        "最低价": 4.44,
        "昨收价": 4.69,
        "总市值": 165663893.0,
        "市盈率": -5.02,
        "成交量": 123883.0,
        "成交额": 567335.0,
        "振幅": 9.38,
        "换手率": 0.34,
        "symbol": "105.LUXH"
    },
    {
        "序号": 11272,
        "name": "二倍做多小金矿指数ETF-Direxion",
        "最新价": 31.41,
        "涨跌额": -1.42,
        "涨跌幅": -4.33,
        "开盘价": 31.38,
        "最高价": 32.55,
        "最低价": 30.82,
        "昨收价": 32.83,
        "总市值": null,
        "市盈率": null,
        "成交量": 2169171.0,
        "成交额": 68524089.0,
        "振幅": 5.27,
        "换手率": null,
        "symbol": "107.JNUG"
    },
    {
        "序号": 11273,
        "name": "1.5倍做多恐慌指数短期期货ETF",
        "最新价": 9.51,
        "涨跌额": -0.43,
        "涨跌幅": -4.33,
        "开盘价": 9.82,
        "最高价": 9.82,
        "最低价": 9.48,
        "昨收价": 9.94,
        "总市值": null,
        "市盈率": null,
        "成交量": 18580801.0,
        "成交额": 178783591.0,
        "振幅": 3.42,
        "换手率": null,
        "symbol": "107.UVXY"
    },
    {
        "序号": 11274,
        "name": "Gates Industrial Corp plc",
        "最新价": 11.72,
        "涨跌额": -0.53,
        "涨跌幅": -4.33,
        "开盘价": 11.22,
        "最高价": 11.8,
        "最低价": 11.22,
        "昨收价": 12.25,
        "总市值": 3095354913.0,
        "市盈率": 12.14,
        "成交量": 9861355.0,
        "成交额": 114701959.0,
        "振幅": 4.73,
        "换手率": 3.73,
        "symbol": "106.GTES"
    },
    {
        "序号": 11275,
        "name": "Courtside Group Inc",
        "最新价": 1.99,
        "涨跌额": -0.09,
        "涨跌幅": -4.33,
        "开盘价": 2.02,
        "最高价": 2.15,
        "最低价": 1.87,
        "昨收价": 2.08,
        "总市值": 45889076.0,
        "市盈率": -12.32,
        "成交量": 33640.0,
        "成交额": 68108.0,
        "振幅": 13.46,
        "换手率": 0.15,
        "symbol": "105.PODC"
    },
    {
        "序号": 11276,
        "name": "Kura Oncology Inc",
        "最新价": 10.81,
        "涨跌额": -0.49,
        "涨跌幅": -4.34,
        "开盘价": 11.27,
        "最高价": 11.44,
        "最低价": 10.38,
        "昨收价": 11.3,
        "总市值": 802881520.0,
        "市盈率": -5.62,
        "成交量": 1362181.0,
        "成交额": 14724154.0,
        "振幅": 9.38,
        "换手率": 1.83,
        "symbol": "105.KURA"
    },
    {
        "序号": 11277,
        "name": "Rumble Inc Wt",
        "最新价": 1.1,
        "涨跌额": -0.05,
        "涨跌幅": -4.35,
        "开盘价": 1.14,
        "最高价": 1.15,
        "最低价": 1.01,
        "昨收价": 1.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 22312.0,
        "成交额": 24932.0,
        "振幅": 12.17,
        "换手率": null,
        "symbol": "105.RUMBW"
    },
    {
        "序号": 11278,
        "name": "Polar Power Inc",
        "最新价": 0.44,
        "涨跌额": -0.02,
        "涨跌幅": -4.35,
        "开盘价": 0.47,
        "最高价": 0.47,
        "最低价": 0.42,
        "昨收价": 0.46,
        "总市值": 7463109.0,
        "市盈率": -1.58,
        "成交量": 163573.0,
        "成交额": 72211.0,
        "振幅": 10.87,
        "换手率": 0.96,
        "symbol": "105.POLA"
    },
    {
        "序号": 11279,
        "name": "Aclarion Inc",
        "最新价": 0.22,
        "涨跌额": -0.01,
        "涨跌幅": -4.35,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.21,
        "昨收价": 0.23,
        "总市值": 1934462.0,
        "市盈率": -0.38,
        "成交量": 28627.0,
        "成交额": 6300.0,
        "振幅": 13.04,
        "换手率": 0.33,
        "symbol": "105.ACON"
    },
    {
        "序号": 11280,
        "name": "Janux Therapeutics Inc",
        "最新价": 9.01,
        "涨跌额": -0.41,
        "涨跌幅": -4.35,
        "开盘价": 9.37,
        "最高价": 9.48,
        "最低价": 8.93,
        "昨收价": 9.42,
        "总市值": 415977554.0,
        "市盈率": -6.65,
        "成交量": 67195.0,
        "成交额": 616968.0,
        "振幅": 5.84,
        "换手率": 0.15,
        "symbol": "105.JANX"
    },
    {
        "序号": 11281,
        "name": "格涅斯科",
        "最新价": 30.49,
        "涨跌额": -1.39,
        "涨跌幅": -4.36,
        "开盘价": 31.81,
        "最高价": 31.96,
        "最低价": 30.42,
        "昨收价": 31.88,
        "总市值": 350300128.0,
        "市盈率": -69.13,
        "成交量": 115365.0,
        "成交额": 3552797.0,
        "振幅": 4.83,
        "换手率": 1.0,
        "symbol": "106.GCO"
    },
    {
        "序号": 11282,
        "name": "Barfresh Food Group Inc",
        "最新价": 1.75,
        "涨跌额": -0.08,
        "涨跌幅": -4.37,
        "开盘价": 1.9,
        "最高价": 1.9,
        "最低价": 1.72,
        "昨收价": 1.83,
        "总市值": 24368355.0,
        "市盈率": -5.99,
        "成交量": 8725.0,
        "成交额": 16384.0,
        "振幅": 9.84,
        "换手率": 0.06,
        "symbol": "105.BRFH"
    },
    {
        "序号": 11283,
        "name": "格瑞夫-A",
        "最新价": 64.36,
        "涨跌额": -2.97,
        "涨跌幅": -4.41,
        "开盘价": 66.91,
        "最高价": 67.12,
        "最低价": 64.33,
        "昨收价": 67.33,
        "总市值": 3012394321.0,
        "市盈率": 7.71,
        "成交量": 276275.0,
        "成交额": 17894759.0,
        "振幅": 4.14,
        "换手率": 1.08,
        "symbol": "106.GEF"
    },
    {
        "序号": 11284,
        "name": "阿凯迪亚生物科学",
        "最新价": 3.25,
        "涨跌额": -0.15,
        "涨跌幅": -4.41,
        "开盘价": 3.16,
        "最高价": 3.44,
        "最低价": 3.07,
        "昨收价": 3.4,
        "总市值": 3608595.0,
        "市盈率": -0.23,
        "成交量": 43820.0,
        "成交额": 143826.0,
        "振幅": 10.88,
        "换手率": 3.95,
        "symbol": "105.RKDA"
    },
    {
        "序号": 11285,
        "name": "Air T Inc",
        "最新价": 16.85,
        "涨跌额": -0.78,
        "涨跌幅": -4.42,
        "开盘价": 17.52,
        "最高价": 17.52,
        "最低价": 16.15,
        "昨收价": 17.63,
        "总市值": 47542342.0,
        "市盈率": -4.04,
        "成交量": 5691.0,
        "成交额": 94870.0,
        "振幅": 7.77,
        "换手率": 0.2,
        "symbol": "105.AIRT"
    },
    {
        "序号": 11286,
        "name": "Cedar Realty Trust Inc Series B",
        "最新价": 13.14,
        "涨跌额": -0.61,
        "涨跌幅": -4.44,
        "开盘价": 13.61,
        "最高价": 13.75,
        "最低价": 12.75,
        "昨收价": 13.75,
        "总市值": 19047862.0,
        "市盈率": null,
        "成交量": 3719.0,
        "成交额": 49505.0,
        "振幅": 7.27,
        "换手率": 0.26,
        "symbol": "106.CDR_B"
    },
    {
        "序号": 11287,
        "name": "Wallbox NV Wt",
        "最新价": 0.43,
        "涨跌额": -0.02,
        "涨跌幅": -4.44,
        "开盘价": 0.43,
        "最高价": 0.43,
        "最低价": 0.43,
        "昨收价": 0.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 2350.0,
        "成交额": 998.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.WBX_WS"
    },
    {
        "序号": 11288,
        "name": "Blue Ridge Bankshares Inc",
        "最新价": 2.57,
        "涨跌额": -0.12,
        "涨跌幅": -4.46,
        "开盘价": 2.64,
        "最高价": 2.69,
        "最低价": 2.5,
        "昨收价": 2.69,
        "总市值": 49324650.0,
        "市盈率": -1.24,
        "成交量": 122738.0,
        "成交额": 318817.0,
        "振幅": 7.06,
        "换手率": 0.64,
        "symbol": "107.BRBS"
    },
    {
        "序号": 11289,
        "name": "Galecto Inc",
        "最新价": 0.64,
        "涨跌额": -0.03,
        "涨跌幅": -4.48,
        "开盘价": 0.67,
        "最高价": 0.67,
        "最低价": 0.61,
        "昨收价": 0.67,
        "总市值": 17352126.0,
        "市盈率": -0.38,
        "成交量": 55493.0,
        "成交额": 35186.0,
        "振幅": 8.96,
        "换手率": 0.2,
        "symbol": "105.GLTO"
    },
    {
        "序号": 11290,
        "name": "CuriosityStream Inc-A",
        "最新价": 0.64,
        "涨跌额": -0.03,
        "涨跌幅": -4.48,
        "开盘价": 0.67,
        "最高价": 0.69,
        "最低价": 0.61,
        "昨收价": 0.67,
        "总市值": 33968609.0,
        "市盈率": -0.58,
        "成交量": 102012.0,
        "成交额": 66584.0,
        "振幅": 11.94,
        "换手率": 0.19,
        "symbol": "105.CURI"
    },
    {
        "序号": 11291,
        "name": "三倍做多生物技术ETF-Direxion",
        "最新价": 88.63,
        "涨跌额": -4.16,
        "涨跌幅": -4.48,
        "开盘价": 92.0,
        "最高价": 94.94,
        "最低价": 88.4,
        "昨收价": 92.79,
        "总市值": null,
        "市盈率": null,
        "成交量": 2436075.0,
        "成交额": 221743268.0,
        "振幅": 7.05,
        "换手率": null,
        "symbol": "107.LABU"
    },
    {
        "序号": 11292,
        "name": "Verrica制药",
        "最新价": 3.83,
        "涨跌额": -0.18,
        "涨跌幅": -4.49,
        "开盘价": 4.04,
        "最高价": 4.04,
        "最低价": 3.75,
        "昨收价": 4.01,
        "总市值": 161107238.0,
        "市盈率": -3.33,
        "成交量": 90459.0,
        "成交额": 350253.0,
        "振幅": 7.23,
        "换手率": 0.22,
        "symbol": "105.VRCA"
    },
    {
        "序号": 11293,
        "name": "Alpha Teknova Inc",
        "最新价": 2.97,
        "涨跌额": -0.14,
        "涨跌幅": -4.5,
        "开盘价": 3.0,
        "最高价": 3.14,
        "最低价": 2.88,
        "昨收价": 3.11,
        "总市值": 120961507.0,
        "市盈率": -3.07,
        "成交量": 32024.0,
        "成交额": 97533.0,
        "振幅": 8.36,
        "换手率": 0.08,
        "symbol": "105.TKNO"
    },
    {
        "序号": 11294,
        "name": "格瑞夫-B",
        "最新价": 64.4,
        "涨跌额": -3.04,
        "涨跌幅": -4.51,
        "开盘价": 65.5,
        "最高价": 66.46,
        "最低价": 64.18,
        "昨收价": 67.44,
        "总市值": 3014266536.0,
        "市盈率": 7.71,
        "成交量": 14018.0,
        "成交额": 912444.0,
        "振幅": 3.38,
        "换手率": 0.07,
        "symbol": "106.GEF_B"
    },
    {
        "序号": 11295,
        "name": "VistaGen Therapeutics Inc",
        "最新价": 4.65,
        "涨跌额": -0.22,
        "涨跌幅": -4.52,
        "开盘价": 4.95,
        "最高价": 5.04,
        "最低价": 4.57,
        "昨收价": 4.87,
        "总市值": 125657127.0,
        "市盈率": -3.54,
        "成交量": 771846.0,
        "成交额": 3703601.0,
        "振幅": 9.65,
        "换手率": 2.86,
        "symbol": "105.VTGN"
    },
    {
        "序号": 11296,
        "name": "Precision Optics Corp Inc",
        "最新价": 6.11,
        "涨跌额": -0.29,
        "涨跌幅": -4.53,
        "开盘价": 6.4,
        "最高价": 6.4,
        "最低价": 6.11,
        "昨收价": 6.4,
        "总市值": 37066425.0,
        "市盈率": -82.31,
        "成交量": 1222.0,
        "成交额": 7767.0,
        "振幅": 4.53,
        "换手率": 0.02,
        "symbol": "105.POCI"
    },
    {
        "序号": 11297,
        "name": "Imunon Inc",
        "最新价": 0.84,
        "涨跌额": -0.04,
        "涨跌幅": -4.55,
        "开盘价": 0.86,
        "最高价": 0.89,
        "最低价": 0.84,
        "昨收价": 0.88,
        "总市值": 7895403.0,
        "市盈率": -0.28,
        "成交量": 39312.0,
        "成交额": 33916.0,
        "振幅": 5.68,
        "换手率": 0.42,
        "symbol": "105.IMNN"
    },
    {
        "序号": 11298,
        "name": "MicroCloud Hologram Inc",
        "最新价": 0.42,
        "涨跌额": -0.02,
        "涨跌幅": -4.55,
        "开盘价": 0.44,
        "最高价": 0.44,
        "最低价": 0.41,
        "昨收价": 0.44,
        "总市值": 21341055.0,
        "市盈率": -0.72,
        "成交量": 331838.0,
        "成交额": 140386.0,
        "振幅": 6.82,
        "换手率": 0.65,
        "symbol": "105.HOLO"
    },
    {
        "序号": 11299,
        "name": "Tritium DCFC Ltd",
        "最新价": 0.21,
        "涨跌额": -0.01,
        "涨跌幅": -4.55,
        "开盘价": 0.22,
        "最高价": 0.22,
        "最低价": 0.2,
        "昨收价": 0.22,
        "总市值": 42351099.0,
        "市盈率": -0.35,
        "成交量": 3704696.0,
        "成交额": 769992.0,
        "振幅": 9.09,
        "换手率": 1.84,
        "symbol": "105.DCFC"
    },
    {
        "序号": 11300,
        "name": "BioCardia Inc",
        "最新价": 0.63,
        "涨跌额": -0.03,
        "涨跌幅": -4.55,
        "开盘价": 0.65,
        "最高价": 0.67,
        "最低价": 0.59,
        "昨收价": 0.66,
        "总市值": 14880242.0,
        "市盈率": -1.19,
        "成交量": 511114.0,
        "成交额": 331048.0,
        "振幅": 12.12,
        "换手率": 2.16,
        "symbol": "105.BCDA"
    },
    {
        "序号": 11301,
        "name": "Bone Biologics Corp",
        "最新价": 0.42,
        "涨跌额": -0.02,
        "涨跌幅": -4.55,
        "开盘价": 0.43,
        "最高价": 0.45,
        "最低价": 0.4,
        "昨收价": 0.44,
        "总市值": 1794851.0,
        "市盈率": -0.28,
        "成交量": 174327.0,
        "成交额": 74538.0,
        "振幅": 11.36,
        "换手率": 4.08,
        "symbol": "105.BBLG"
    },
    {
        "序号": 11302,
        "name": "Prothena Corp plc",
        "最新价": 36.08,
        "涨跌额": -1.72,
        "涨跌幅": -4.55,
        "开盘价": 37.41,
        "最高价": 38.28,
        "最低价": 35.88,
        "昨收价": 37.8,
        "总市值": 1936245792.0,
        "市盈率": -26.45,
        "成交量": 560736.0,
        "成交额": 20441292.0,
        "振幅": 6.35,
        "换手率": 1.04,
        "symbol": "105.PRTA"
    },
    {
        "序号": 11303,
        "name": "Dominari Holdings Inc",
        "最新价": 2.09,
        "涨跌额": -0.1,
        "涨跌幅": -4.57,
        "开盘价": 2.13,
        "最高价": 2.15,
        "最低价": 1.93,
        "昨收价": 2.19,
        "总市值": 11171702.0,
        "市盈率": -0.48,
        "成交量": 15680.0,
        "成交额": 31614.0,
        "振幅": 10.05,
        "换手率": 0.29,
        "symbol": "105.DOMH"
    },
    {
        "序号": 11304,
        "name": "Alpine 4 Holdings Inc-A",
        "最新价": 0.83,
        "涨跌额": -0.04,
        "涨跌幅": -4.6,
        "开盘价": 0.91,
        "最高价": 0.92,
        "最低价": 0.83,
        "昨收价": 0.87,
        "总市值": 22127138.0,
        "市盈率": -1.07,
        "成交量": 52804.0,
        "成交额": 45312.0,
        "振幅": 10.34,
        "换手率": 0.2,
        "symbol": "105.ALPP"
    },
    {
        "序号": 11305,
        "name": "ACELYRIN INC",
        "最新价": 6.83,
        "涨跌额": -0.33,
        "涨跌幅": -4.61,
        "开盘价": 6.96,
        "最高价": 7.33,
        "最低价": 6.79,
        "昨收价": 7.16,
        "总市值": 664828744.0,
        "市盈率": -2.17,
        "成交量": 765883.0,
        "成交额": 5326005.0,
        "振幅": 7.54,
        "换手率": 0.79,
        "symbol": "105.SLRN"
    },
    {
        "序号": 11306,
        "name": "太阳电力",
        "最新价": 4.76,
        "涨跌额": -0.23,
        "涨跌幅": -4.61,
        "开盘价": 4.94,
        "最高价": 5.11,
        "最低价": 4.49,
        "昨收价": 4.99,
        "总市值": 833912525.0,
        "市盈率": -8.72,
        "成交量": 7689098.0,
        "成交额": 36548077.0,
        "振幅": 12.42,
        "换手率": 4.39,
        "symbol": "105.SPWR"
    },
    {
        "序号": 11307,
        "name": "Sana Biotechnology Inc",
        "最新价": 3.72,
        "涨跌额": -0.18,
        "涨跌幅": -4.62,
        "开盘价": 3.92,
        "最高价": 3.99,
        "最低价": 3.71,
        "昨收价": 3.9,
        "总市值": 733335534.0,
        "市盈率": -2.66,
        "成交量": 718505.0,
        "成交额": 2727005.0,
        "振幅": 7.18,
        "换手率": 0.36,
        "symbol": "105.SANA"
    },
    {
        "序号": 11308,
        "name": "1stdibs.com Inc",
        "最新价": 4.74,
        "涨跌额": -0.23,
        "涨跌幅": -4.63,
        "开盘价": 4.74,
        "最高价": 5.01,
        "最低价": 4.67,
        "昨收价": 4.97,
        "总市值": 189511116.0,
        "市盈率": -7.12,
        "成交量": 58288.0,
        "成交额": 281097.0,
        "振幅": 6.84,
        "换手率": 0.15,
        "symbol": "105.DIBS"
    },
    {
        "序号": 11309,
        "name": "兼职精灵",
        "最新价": 8.24,
        "涨跌额": -0.4,
        "涨跌幅": -4.63,
        "开盘价": 8.47,
        "最高价": 8.52,
        "最低价": 7.85,
        "昨收价": 8.64,
        "总市值": 4180836.0,
        "市盈率": -0.13,
        "成交量": 74018.0,
        "成交额": 600528.0,
        "振幅": 7.75,
        "换手率": 14.59,
        "symbol": "105.PIXY"
    },
    {
        "序号": 11310,
        "name": "Gossamer Bio Inc",
        "最新价": 1.03,
        "涨跌额": -0.05,
        "涨跌幅": -4.63,
        "开盘价": 1.07,
        "最高价": 1.11,
        "最低价": 1.03,
        "昨收价": 1.08,
        "总市值": 232171593.0,
        "市盈率": -1.24,
        "成交量": 940364.0,
        "成交额": 997216.0,
        "振幅": 7.41,
        "换手率": 0.42,
        "symbol": "105.GOSS"
    },
    {
        "序号": 11311,
        "name": "联合天然食品",
        "最新价": 15.0,
        "涨跌额": -0.73,
        "涨跌幅": -4.64,
        "开盘价": 15.83,
        "最高价": 15.92,
        "最低价": 14.9,
        "昨收价": 15.73,
        "总市值": 891119535.0,
        "市盈率": -11.0,
        "成交量": 1174798.0,
        "成交额": 17787619.0,
        "振幅": 6.48,
        "换手率": 1.98,
        "symbol": "106.UNFI"
    },
    {
        "序号": 11312,
        "name": "Impel Pharmaceuticals Inc",
        "最新价": 0.41,
        "涨跌额": -0.02,
        "涨跌幅": -4.65,
        "开盘价": 0.41,
        "最高价": 0.45,
        "最低价": 0.39,
        "昨收价": 0.43,
        "总市值": 9799013.0,
        "市盈率": -0.13,
        "成交量": 144056.0,
        "成交额": 60293.0,
        "振幅": 13.95,
        "换手率": 0.6,
        "symbol": "105.IMPL"
    },
    {
        "序号": 11313,
        "name": "Esports Entertainment Group Inc",
        "最新价": 4.9,
        "涨跌额": -0.24,
        "涨跌幅": -4.67,
        "开盘价": 5.0,
        "最高价": 5.16,
        "最低价": 4.9,
        "昨收价": 5.14,
        "总市值": 4096155.0,
        "市盈率": null,
        "成交量": 14435.0,
        "成交额": 71959.0,
        "振幅": 5.06,
        "换手率": 1.73,
        "symbol": "105.GMBLP"
    },
    {
        "序号": 11314,
        "name": "Larimar Therapeutics Inc",
        "最新价": 3.47,
        "涨跌额": -0.17,
        "涨跌幅": -4.67,
        "开盘价": 3.55,
        "最高价": 3.64,
        "最低价": 3.44,
        "昨收价": 3.64,
        "总市值": 152353483.0,
        "市盈率": -4.56,
        "成交量": 82137.0,
        "成交额": 287404.0,
        "振幅": 5.49,
        "换手率": 0.19,
        "symbol": "105.LRMR"
    },
    {
        "序号": 11315,
        "name": "美世国际",
        "最新价": 8.57,
        "涨跌额": -0.42,
        "涨跌幅": -4.67,
        "开盘价": 8.94,
        "最高价": 8.99,
        "最低价": 8.55,
        "昨收价": 8.99,
        "总市值": 570118102.0,
        "市盈率": -4.23,
        "成交量": 189897.0,
        "成交额": 1645804.0,
        "振幅": 4.89,
        "换手率": 0.29,
        "symbol": "105.MERC"
    },
    {
        "序号": 11316,
        "name": "ADC Therapeutics SA",
        "最新价": 1.02,
        "涨跌额": -0.05,
        "涨跌幅": -4.67,
        "开盘价": 1.09,
        "最高价": 1.13,
        "最低价": 0.94,
        "昨收价": 1.07,
        "总市值": 82255378.0,
        "市盈率": -0.46,
        "成交量": 436460.0,
        "成交额": 447374.0,
        "振幅": 17.76,
        "换手率": 0.54,
        "symbol": "106.ADCT"
    },
    {
        "序号": 11317,
        "name": "Standard BioTools Inc",
        "最新价": 2.24,
        "涨跌额": -0.11,
        "涨跌幅": -4.68,
        "开盘价": 2.34,
        "最高价": 2.34,
        "最低价": 2.17,
        "昨收价": 2.35,
        "总市值": 186283339.0,
        "市盈率": -2.46,
        "成交量": 752273.0,
        "成交额": 1661564.0,
        "振幅": 7.23,
        "换手率": 0.9,
        "symbol": "105.LAB"
    },
    {
        "序号": 11318,
        "name": "Fate Therapeutics Inc",
        "最新价": 2.24,
        "涨跌额": -0.11,
        "涨跌幅": -4.68,
        "开盘价": 2.33,
        "最高价": 2.41,
        "最低价": 2.24,
        "昨收价": 2.35,
        "总市值": 220868404.0,
        "市盈率": -1.28,
        "成交量": 783731.0,
        "成交额": 1803997.0,
        "振幅": 7.23,
        "换手率": 0.79,
        "symbol": "105.FATE"
    },
    {
        "序号": 11319,
        "name": "Spectaire Holdings Inc",
        "最新价": 1.62,
        "涨跌额": -0.08,
        "涨跌幅": -4.71,
        "开盘价": 1.52,
        "最高价": 1.75,
        "最低价": 1.52,
        "昨收价": 1.7,
        "总市值": 24858680.0,
        "市盈率": -2.94,
        "成交量": 19805.0,
        "成交额": 33122.0,
        "振幅": 13.53,
        "换手率": 0.13,
        "symbol": "105.SPEC"
    },
    {
        "序号": 11320,
        "name": "Agios Pharmaceuticals Inc",
        "最新价": 22.05,
        "涨跌额": -1.09,
        "涨跌幅": -4.71,
        "开盘价": 23.05,
        "最高价": 23.14,
        "最低价": 22.01,
        "昨收价": 23.14,
        "总市值": 1232402812.0,
        "市盈率": -5.61,
        "成交量": 392101.0,
        "成交额": 8778227.0,
        "振幅": 4.88,
        "换手率": 0.7,
        "symbol": "105.AGIO"
    },
    {
        "序号": 11321,
        "name": "Danimer Scientific Inc-A",
        "最新价": 1.01,
        "涨跌额": -0.05,
        "涨跌幅": -4.72,
        "开盘价": 1.06,
        "最高价": 1.09,
        "最低价": 0.97,
        "昨收价": 1.06,
        "总市值": 103055620.0,
        "市盈率": -0.72,
        "成交量": 1225134.0,
        "成交额": 1246628.0,
        "振幅": 11.32,
        "换手率": 1.2,
        "symbol": "106.DNMR"
    },
    {
        "序号": 11322,
        "name": "Personalis Inc",
        "最新价": 1.41,
        "涨跌额": -0.07,
        "涨跌幅": -4.73,
        "开盘价": 1.5,
        "最高价": 1.5,
        "最低价": 1.38,
        "昨收价": 1.48,
        "总市值": 69134268.0,
        "市盈率": -0.61,
        "成交量": 316703.0,
        "成交额": 451223.0,
        "振幅": 8.11,
        "换手率": 0.65,
        "symbol": "105.PSNL"
    },
    {
        "序号": 11323,
        "name": "Trevena Inc",
        "最新价": 0.6,
        "涨跌额": -0.03,
        "涨跌幅": -4.76,
        "开盘价": 0.61,
        "最高价": 0.64,
        "最低价": 0.6,
        "昨收价": 0.63,
        "总市值": 9104668.0,
        "市盈率": -0.3,
        "成交量": 85296.0,
        "成交额": 52925.0,
        "振幅": 6.35,
        "换手率": 0.56,
        "symbol": "105.TRVN"
    },
    {
        "序号": 11324,
        "name": "TMT Acquisition Corp Rt",
        "最新价": 0.2,
        "涨跌额": -0.01,
        "涨跌幅": -4.76,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.19,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 122929.0,
        "成交额": 24375.0,
        "振幅": 4.76,
        "换手率": null,
        "symbol": "105.TMTCR"
    },
    {
        "序号": 11325,
        "name": "FaZe Holdings Inc",
        "最新价": 0.2,
        "涨跌额": -0.01,
        "涨跌幅": -4.76,
        "开盘价": 0.21,
        "最高价": 0.21,
        "最低价": 0.2,
        "昨收价": 0.21,
        "总市值": 15500237.0,
        "市盈率": -0.28,
        "成交量": 214248.0,
        "成交额": 43150.0,
        "振幅": 4.76,
        "换手率": 0.28,
        "symbol": "105.FAZE"
    },
    {
        "序号": 11326,
        "name": "诗思(香港)",
        "最新价": 0.4,
        "涨跌额": -0.02,
        "涨跌幅": -4.76,
        "开盘价": 0.42,
        "最高价": 0.42,
        "最低价": 0.36,
        "昨收价": 0.42,
        "总市值": 12137982.0,
        "市盈率": -0.57,
        "成交量": 399676.0,
        "成交额": 155547.0,
        "振幅": 14.29,
        "换手率": 1.32,
        "symbol": "105.CTHR"
    },
    {
        "序号": 11327,
        "name": "Alchemy Investments Acquisition",
        "最新价": 0.2,
        "涨跌额": -0.01,
        "涨跌幅": -4.76,
        "开盘价": 0.21,
        "最高价": 0.22,
        "最低价": 0.2,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 816.0,
        "成交额": 175.0,
        "振幅": 9.52,
        "换手率": null,
        "symbol": "105.ALCYW"
    },
    {
        "序号": 11328,
        "name": "ABVC BioPharma Inc",
        "最新价": 1.19,
        "涨跌额": -0.06,
        "涨跌幅": -4.8,
        "开盘价": 1.25,
        "最高价": 1.25,
        "最低价": 1.16,
        "昨收价": 1.25,
        "总市值": 8606009.0,
        "市盈率": -0.7,
        "成交量": 281581.0,
        "成交额": 334624.0,
        "振幅": 7.2,
        "换手率": 3.89,
        "symbol": "105.ABVC"
    },
    {
        "序号": 11329,
        "name": "Incannex Healthcare Inc",
        "最新价": 5.53,
        "涨跌额": -0.28,
        "涨跌幅": -4.82,
        "开盘价": 5.71,
        "最高价": 6.15,
        "最低价": 5.3,
        "昨收价": 5.81,
        "总市值": 87499918.0,
        "市盈率": -6.59,
        "成交量": 66417.0,
        "成交额": 372128.0,
        "振幅": 14.63,
        "换手率": 0.42,
        "symbol": "105.IXHL"
    },
    {
        "序号": 11330,
        "name": "Maison Solutions Inc-A",
        "最新价": 13.8,
        "涨跌额": -0.7,
        "涨跌幅": -4.83,
        "开盘价": 14.44,
        "最高价": 14.5,
        "最低价": 11.26,
        "昨收价": 14.5,
        "总市值": 255300000.0,
        "市盈率": 230.53,
        "成交量": 4281127.0,
        "成交额": 60188233.0,
        "振幅": 22.34,
        "换手率": 23.14,
        "symbol": "105.MSS"
    },
    {
        "序号": 11331,
        "name": "广船国际技术",
        "最新价": 2.95,
        "涨跌额": -0.15,
        "涨跌幅": -4.84,
        "开盘价": 3.01,
        "最高价": 3.3,
        "最低价": 2.73,
        "昨收价": 3.1,
        "总市值": 74387622.0,
        "市盈率": -4.15,
        "成交量": 940768.0,
        "成交额": 2838190.0,
        "振幅": 18.39,
        "换手率": 3.73,
        "symbol": "105.GSIT"
    },
    {
        "序号": 11332,
        "name": "Clene Inc",
        "最新价": 0.39,
        "涨跌额": -0.02,
        "涨跌幅": -4.88,
        "开盘价": 0.41,
        "最高价": 0.42,
        "最低价": 0.38,
        "昨收价": 0.41,
        "总市值": 50082086.0,
        "市盈率": -1.24,
        "成交量": 297120.0,
        "成交额": 117365.0,
        "振幅": 9.76,
        "换手率": 0.23,
        "symbol": "105.CLNN"
    },
    {
        "序号": 11333,
        "name": "Iovance Biotherapeutics Inc",
        "最新价": 6.4,
        "涨跌额": -0.33,
        "涨跌幅": -4.9,
        "开盘价": 6.67,
        "最高价": 6.83,
        "最低价": 6.33,
        "昨收价": 6.73,
        "总市值": 1637878067.0,
        "市盈率": -3.78,
        "成交量": 7213438.0,
        "成交额": 46751859.0,
        "振幅": 7.43,
        "换手率": 2.82,
        "symbol": "105.IOVA"
    },
    {
        "序号": 11334,
        "name": "Designer Brands Inc-A",
        "最新价": 8.33,
        "涨跌额": -0.43,
        "涨跌幅": -4.91,
        "开盘价": 8.77,
        "最高价": 8.77,
        "最低价": 8.31,
        "昨收价": 8.76,
        "总市值": 473620526.0,
        "市盈率": 4.56,
        "成交量": 2180491.0,
        "成交额": 18343184.0,
        "振幅": 5.25,
        "换手率": 3.84,
        "symbol": "106.DBI"
    },
    {
        "序号": 11335,
        "name": "Plus Therapeutics Inc",
        "最新价": 2.12,
        "涨跌额": -0.11,
        "涨跌幅": -4.93,
        "开盘价": 2.28,
        "最高价": 2.3,
        "最低价": 2.12,
        "昨收价": 2.23,
        "总市值": 9588031.0,
        "市盈率": -0.63,
        "成交量": 45812.0,
        "成交额": 102542.0,
        "振幅": 8.07,
        "换手率": 1.01,
        "symbol": "105.PSTV"
    },
    {
        "序号": 11336,
        "name": "游戏驿站",
        "最新价": 15.55,
        "涨跌额": -0.81,
        "涨跌幅": -4.95,
        "开盘价": 15.83,
        "最高价": 16.48,
        "最低价": 15.47,
        "昨收价": 16.36,
        "总市值": 4750747598.0,
        "市盈率": -579.36,
        "成交量": 10401387.0,
        "成交额": 165836871.0,
        "振幅": 6.17,
        "换手率": 3.4,
        "symbol": "106.GME"
    },
    {
        "序号": 11337,
        "name": "REGENXBIO Inc",
        "最新价": 19.58,
        "涨跌额": -1.02,
        "涨跌幅": -4.95,
        "开盘价": 20.59,
        "最高价": 20.59,
        "最低价": 19.57,
        "昨收价": 20.6,
        "总市值": 861362968.0,
        "市盈率": -3.31,
        "成交量": 228265.0,
        "成交额": 4513638.0,
        "振幅": 4.95,
        "换手率": 0.52,
        "symbol": "105.RGNX"
    },
    {
        "序号": 11338,
        "name": "Verb Technology Co Inc",
        "最新价": 0.19,
        "涨跌额": -0.01,
        "涨跌幅": -5.0,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.19,
        "昨收价": 0.2,
        "总市值": 3117575.0,
        "市盈率": -0.09,
        "成交量": 786646.0,
        "成交额": 149601.0,
        "振幅": 5.0,
        "换手率": 4.79,
        "symbol": "105.VERB"
    },
    {
        "序号": 11339,
        "name": "United Homes Group Inc Wt",
        "最新价": 0.76,
        "涨跌额": -0.04,
        "涨跌幅": -5.0,
        "开盘价": 0.66,
        "最高价": 0.76,
        "最低价": 0.66,
        "昨收价": 0.8,
        "总市值": null,
        "市盈率": null,
        "成交量": 246.0,
        "成交额": 172.0,
        "振幅": 12.5,
        "换手率": null,
        "symbol": "105.UHGWW"
    },
    {
        "序号": 11340,
        "name": "TRACON Pharmaceuticals Inc",
        "最新价": 0.19,
        "涨跌额": -0.01,
        "涨跌幅": -5.0,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.18,
        "昨收价": 0.2,
        "总市值": 5917424.0,
        "市盈率": -0.54,
        "成交量": 1253570.0,
        "成交额": 237974.0,
        "振幅": 10.0,
        "换手率": 4.03,
        "symbol": "105.TCON"
    },
    {
        "序号": 11341,
        "name": "SportsMap Tech Acquisition Corp",
        "最新价": 10.45,
        "涨跌额": -0.55,
        "涨跌幅": -5.0,
        "开盘价": 10.45,
        "最高价": 10.45,
        "最低价": 10.45,
        "昨收价": 11.0,
        "总市值": 54182665.0,
        "市盈率": -44.67,
        "成交量": 225.0,
        "成交额": 2351.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SMAPU"
    },
    {
        "序号": 11342,
        "name": "Nexalin Technology Inc",
        "最新价": 0.38,
        "涨跌额": -0.02,
        "涨跌幅": -5.0,
        "开盘价": 0.41,
        "最高价": 0.42,
        "最低价": 0.38,
        "昨收价": 0.4,
        "总市值": 2825894.0,
        "市盈率": -0.61,
        "成交量": 6228.0,
        "成交额": 2410.0,
        "振幅": 10.0,
        "换手率": 0.08,
        "symbol": "105.NXL"
    },
    {
        "序号": 11343,
        "name": "Nuwellis Inc",
        "最新价": 0.57,
        "涨跌额": -0.03,
        "涨跌幅": -5.0,
        "开盘价": 0.57,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.6,
        "总市值": 1860284.0,
        "市盈率": -0.11,
        "成交量": 128832.0,
        "成交额": 73254.0,
        "振幅": 8.33,
        "换手率": 3.95,
        "symbol": "105.NUWE"
    },
    {
        "序号": 11344,
        "name": "IGM Biosciences Inc",
        "最新价": 6.65,
        "涨跌额": -0.35,
        "涨跌幅": -5.0,
        "开盘价": 7.0,
        "最高价": 7.1,
        "最低价": 6.43,
        "昨收价": 7.0,
        "总市值": 388320472.0,
        "市盈率": -1.63,
        "成交量": 390330.0,
        "成交额": 2652622.0,
        "振幅": 9.57,
        "换手率": 0.67,
        "symbol": "105.IGMS"
    },
    {
        "序号": 11345,
        "name": "Applied Molecular Transport Inc",
        "最新价": 0.19,
        "涨跌额": -0.01,
        "涨跌幅": -5.0,
        "开盘价": 0.2,
        "最高价": 0.2,
        "最低价": 0.18,
        "昨收价": 0.2,
        "总市值": 7951308.0,
        "市盈率": -0.11,
        "成交量": 92367.0,
        "成交额": 17361.0,
        "振幅": 10.0,
        "换手率": 0.22,
        "symbol": "105.AMTI"
    },
    {
        "序号": 11346,
        "name": "Gaia Inc-A",
        "最新价": 2.84,
        "涨跌额": -0.15,
        "涨跌幅": -5.02,
        "开盘价": 2.98,
        "最高价": 3.06,
        "最低价": 2.8,
        "昨收价": 2.99,
        "总市值": 65925428.0,
        "市盈率": -15.08,
        "成交量": 71916.0,
        "成交额": 209892.0,
        "振幅": 8.7,
        "换手率": 0.31,
        "symbol": "105.GAIA"
    },
    {
        "序号": 11347,
        "name": "Motus GI Holdings Inc",
        "最新价": 3.97,
        "涨跌额": -0.21,
        "涨跌幅": -5.02,
        "开盘价": 4.38,
        "最高价": 4.38,
        "最低价": 3.86,
        "昨收价": 4.18,
        "总市值": 2740281.0,
        "市盈率": -0.2,
        "成交量": 45847.0,
        "成交额": 184919.0,
        "振幅": 12.44,
        "换手率": 6.64,
        "symbol": "105.MOTS"
    },
    {
        "序号": 11348,
        "name": "Cheetah Net Supply Chain Servic",
        "最新价": 1.51,
        "涨跌额": -0.08,
        "涨跌幅": -5.03,
        "开盘价": 1.63,
        "最高价": 1.63,
        "最低价": 1.5,
        "昨收价": 1.59,
        "总市值": 27053160.0,
        "市盈率": 112.1,
        "成交量": 4509.0,
        "成交额": 7107.0,
        "振幅": 8.18,
        "换手率": 0.03,
        "symbol": "105.CTNT"
    },
    {
        "序号": 11349,
        "name": "Azul SA ADR",
        "最新价": 9.75,
        "涨跌额": -0.52,
        "涨跌幅": -5.06,
        "开盘价": 9.98,
        "最高价": 10.18,
        "最低价": 9.69,
        "昨收价": 10.27,
        "总市值": 1101806520.0,
        "市盈率": -7.81,
        "成交量": 1781238.0,
        "成交额": 17542719.0,
        "振幅": 4.77,
        "换手率": 1.58,
        "symbol": "106.AZUL"
    },
    {
        "序号": 11350,
        "name": "Pulmatrix Inc",
        "最新价": 1.86,
        "涨跌额": -0.1,
        "涨跌幅": -5.1,
        "开盘价": 1.92,
        "最高价": 1.93,
        "最低价": 1.85,
        "昨收价": 1.96,
        "总市值": 6793250.0,
        "市盈率": -0.42,
        "成交量": 9936.0,
        "成交额": 18816.0,
        "振幅": 4.08,
        "换手率": 0.27,
        "symbol": "105.PULM"
    },
    {
        "序号": 11351,
        "name": "Nauticus Robotics Inc",
        "最新价": 0.93,
        "涨跌额": -0.05,
        "涨跌幅": -5.1,
        "开盘价": 1.0,
        "最高价": 1.0,
        "最低价": 0.91,
        "昨收价": 0.98,
        "总市值": 46533316.0,
        "市盈率": -2.4,
        "成交量": 89036.0,
        "成交额": 83736.0,
        "振幅": 9.18,
        "换手率": 0.18,
        "symbol": "105.KITT"
    },
    {
        "序号": 11352,
        "name": "Bullfrog AI Holdings Inc",
        "最新价": 2.41,
        "涨跌额": -0.13,
        "涨跌幅": -5.12,
        "开盘价": 2.5,
        "最高价": 2.54,
        "最低价": 2.38,
        "昨收价": 2.54,
        "总市值": 14688092.0,
        "市盈率": -3.09,
        "成交量": 58285.0,
        "成交额": 143622.0,
        "振幅": 6.3,
        "换手率": 0.96,
        "symbol": "105.BFRG"
    },
    {
        "序号": 11353,
        "name": "Taboola.com Ltd Wt",
        "最新价": 0.37,
        "涨跌额": -0.02,
        "涨跌幅": -5.13,
        "开盘价": 0.33,
        "最高价": 0.4,
        "最低价": 0.3,
        "昨收价": 0.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 2112.0,
        "成交额": 730.0,
        "振幅": 25.64,
        "换手率": null,
        "symbol": "105.TBLAW"
    },
    {
        "序号": 11354,
        "name": "KORE Group Holdings Inc",
        "最新价": 0.55,
        "涨跌额": -0.03,
        "涨跌幅": -5.17,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.58,
        "总市值": 47690820.0,
        "市盈率": -0.24,
        "成交量": 19857.0,
        "成交额": 11444.0,
        "振幅": 8.62,
        "换手率": 0.02,
        "symbol": "106.KORE"
    },
    {
        "序号": 11355,
        "name": "Local Bounti Corp",
        "最新价": 3.08,
        "涨跌额": -0.17,
        "涨跌幅": -5.23,
        "开盘价": 3.25,
        "最高价": 3.37,
        "最低价": 3.07,
        "昨收价": 3.25,
        "总市值": 25476953.0,
        "市盈率": -0.3,
        "成交量": 8526.0,
        "成交额": 27678.0,
        "振幅": 9.23,
        "换手率": 0.1,
        "symbol": "106.LOCL"
    },
    {
        "序号": 11356,
        "name": "Usio Inc",
        "最新价": 1.63,
        "涨跌额": -0.09,
        "涨跌幅": -5.23,
        "开盘价": 1.74,
        "最高价": 1.76,
        "最低价": 1.63,
        "昨收价": 1.72,
        "总市值": 42880876.0,
        "市盈率": -65.78,
        "成交量": 47349.0,
        "成交额": 79970.0,
        "振幅": 7.56,
        "换手率": 0.18,
        "symbol": "105.USIO"
    },
    {
        "序号": 11357,
        "name": "Safety Shot Inc",
        "最新价": 3.8,
        "涨跌额": -0.21,
        "涨跌幅": -5.24,
        "开盘价": 4.05,
        "最高价": 4.14,
        "最低价": 3.64,
        "昨收价": 4.01,
        "总市值": 157404888.0,
        "市盈率": -8.78,
        "成交量": 4329757.0,
        "成交额": 16509730.0,
        "振幅": 12.47,
        "换手率": 10.45,
        "symbol": "105.SHOT"
    },
    {
        "序号": 11358,
        "name": "中进医疗",
        "最新价": 27.5,
        "涨跌额": -1.52,
        "涨跌幅": -5.24,
        "开盘价": 28.51,
        "最高价": 30.45,
        "最低价": 27.46,
        "昨收价": 29.02,
        "总市值": 214427263.0,
        "市盈率": 66.04,
        "成交量": 36937.0,
        "成交额": 1060849.0,
        "振幅": 10.3,
        "换手率": 0.47,
        "symbol": "105.ZJYL"
    },
    {
        "序号": 11359,
        "name": "Stryve Foods Inc-A",
        "最新价": 2.53,
        "涨跌额": -0.14,
        "涨跌幅": -5.24,
        "开盘价": 2.8,
        "最高价": 2.88,
        "最低价": 2.5,
        "昨收价": 2.67,
        "总市值": 6071383.0,
        "市盈率": -0.33,
        "成交量": 42081.0,
        "成交额": 114472.0,
        "振幅": 14.23,
        "换手率": 1.75,
        "symbol": "105.SNAX"
    },
    {
        "序号": 11360,
        "name": "Versus Systems Inc",
        "最新价": 0.18,
        "涨跌额": -0.01,
        "涨跌幅": -5.26,
        "开盘价": 0.18,
        "最高价": 0.19,
        "最低价": 0.17,
        "昨收价": 0.19,
        "总市值": 7128209.0,
        "市盈率": -0.42,
        "成交量": 778845.0,
        "成交额": 136914.0,
        "振幅": 10.53,
        "换手率": 1.97,
        "symbol": "105.VS"
    },
    {
        "序号": 11361,
        "name": "ToughBuilt Industries Inc",
        "最新价": 0.18,
        "涨跌额": -0.01,
        "涨跌幅": -5.26,
        "开盘价": 0.18,
        "最高价": 0.19,
        "最低价": 0.18,
        "昨收价": 0.19,
        "总市值": 6644740.0,
        "市盈率": -0.13,
        "成交量": 496697.0,
        "成交额": 90760.0,
        "振幅": 5.26,
        "换手率": 1.35,
        "symbol": "105.TBLT"
    },
    {
        "序号": 11362,
        "name": "SuperCom Ltd",
        "最新价": 0.36,
        "涨跌额": -0.02,
        "涨跌幅": -5.26,
        "开盘价": 0.38,
        "最高价": 0.38,
        "最低价": 0.36,
        "昨收价": 0.38,
        "总市值": 3328326.0,
        "市盈率": -0.68,
        "成交量": 187953.0,
        "成交额": 69573.0,
        "振幅": 5.26,
        "换手率": 2.03,
        "symbol": "105.SPCB"
    },
    {
        "序号": 11363,
        "name": "The Singing Machine Co Inc",
        "最新价": 1.08,
        "涨跌额": -0.06,
        "涨跌幅": -5.26,
        "开盘价": 1.13,
        "最高价": 1.13,
        "最低价": 1.01,
        "昨收价": 1.14,
        "总市值": 6931506.0,
        "市盈率": -0.95,
        "成交量": 46422.0,
        "成交额": 49279.0,
        "振幅": 10.53,
        "换手率": 0.72,
        "symbol": "105.MICS"
    },
    {
        "序号": 11364,
        "name": "Aptevo Therapeutics Inc",
        "最新价": 0.18,
        "涨跌额": -0.01,
        "涨跌幅": -5.26,
        "开盘价": 0.19,
        "最高价": 0.19,
        "最低价": 0.18,
        "昨收价": 0.19,
        "总市值": 3171019.0,
        "市盈率": -0.2,
        "成交量": 196744.0,
        "成交额": 36912.0,
        "振幅": 5.26,
        "换手率": 1.12,
        "symbol": "105.APVO"
    },
    {
        "序号": 11365,
        "name": "Addex Therapeutics Ltd ADR",
        "最新价": 5.4,
        "涨跌额": -0.3,
        "涨跌幅": -5.26,
        "开盘价": 5.41,
        "最高价": 5.68,
        "最低价": 5.35,
        "昨收价": 5.7,
        "总市值": 5358325.0,
        "市盈率": -0.4,
        "成交量": 3093.0,
        "成交额": 16751.0,
        "振幅": 5.79,
        "换手率": 0.31,
        "symbol": "105.ADXN"
    },
    {
        "序号": 11366,
        "name": "Adaptimmune Therapeutics plc AD",
        "最新价": 0.54,
        "涨跌额": -0.03,
        "涨跌幅": -5.26,
        "开盘价": 0.53,
        "最高价": 0.56,
        "最低价": 0.53,
        "昨收价": 0.57,
        "总市值": 122645662.0,
        "市盈率": -1.29,
        "成交量": 263717.0,
        "成交额": 144381.0,
        "振幅": 5.26,
        "换手率": 0.12,
        "symbol": "105.ADAP"
    },
    {
        "序号": 11367,
        "name": "Astria Therapeutics Inc",
        "最新价": 5.39,
        "涨跌额": -0.3,
        "涨跌幅": -5.27,
        "开盘价": 5.69,
        "最高价": 5.75,
        "最低价": 5.34,
        "昨收价": 5.69,
        "总市值": 195636469.0,
        "市盈率": -3.57,
        "成交量": 410431.0,
        "成交额": 2255937.0,
        "振幅": 7.21,
        "换手率": 1.13,
        "symbol": "105.ATXS"
    },
    {
        "序号": 11368,
        "name": "SciSparc Ltd",
        "最新价": 6.1,
        "涨跌额": -0.34,
        "涨跌幅": -5.28,
        "开盘价": 6.36,
        "最高价": 6.46,
        "最低价": 5.74,
        "昨收价": 6.44,
        "总市值": 3195046.0,
        "市盈率": 11.13,
        "成交量": 159480.0,
        "成交额": 990328.0,
        "振幅": 11.18,
        "换手率": 30.45,
        "symbol": "105.SPRC"
    },
    {
        "序号": 11369,
        "name": "Alpha Pro Tech Ltd",
        "最新价": 5.02,
        "涨跌额": -0.28,
        "涨跌幅": -5.28,
        "开盘价": 5.3,
        "最高价": 5.35,
        "最低价": 5.0,
        "昨收价": 5.3,
        "总市值": 58468422.0,
        "市盈率": 15.84,
        "成交量": 58979.0,
        "成交额": 303735.0,
        "振幅": 6.6,
        "换手率": 0.51,
        "symbol": "107.APT"
    },
    {
        "序号": 11370,
        "name": "Vanda Pharmaceuticals Inc",
        "最新价": 3.76,
        "涨跌额": -0.21,
        "涨跌幅": -5.29,
        "开盘价": 3.96,
        "最高价": 3.97,
        "最低价": 3.76,
        "昨收价": 3.97,
        "总市值": 216320316.0,
        "市盈率": 18.38,
        "成交量": 822078.0,
        "成交额": 3162431.0,
        "振幅": 5.29,
        "换手率": 1.43,
        "symbol": "105.VNDA"
    },
    {
        "序号": 11371,
        "name": "Globavend Holdings Ltd",
        "最新价": 1.25,
        "涨跌额": -0.07,
        "涨跌幅": -5.3,
        "开盘价": 1.31,
        "最高价": 1.35,
        "最低价": 1.25,
        "昨收价": 1.32,
        "总市值": 18281250.0,
        "市盈率": 20.94,
        "成交量": 34526.0,
        "成交额": 44767.0,
        "振幅": 7.58,
        "换手率": 0.24,
        "symbol": "105.GVH"
    },
    {
        "序号": 11372,
        "name": "小鹏汽车",
        "最新价": 15.15,
        "涨跌额": -0.85,
        "涨跌幅": -5.31,
        "开盘价": 15.48,
        "最高价": 15.58,
        "最低价": 14.93,
        "昨收价": 16.0,
        "总市值": 14282254450.0,
        "市盈率": -8.84,
        "成交量": 14219369.0,
        "成交额": 216338508.0,
        "振幅": 4.06,
        "换手率": 1.51,
        "symbol": "106.XPEV"
    },
    {
        "序号": 11373,
        "name": "Nikola Corp",
        "最新价": 0.71,
        "涨跌额": -0.04,
        "涨跌幅": -5.33,
        "开盘价": 0.76,
        "最高价": 0.77,
        "最低价": 0.68,
        "昨收价": 0.75,
        "总市值": 727252788.0,
        "市盈率": -0.7,
        "成交量": 258967765.0,
        "成交额": 188392160.0,
        "振幅": 12.0,
        "换手率": 25.28,
        "symbol": "105.NKLA"
    },
    {
        "序号": 11374,
        "name": "Rubicon Technologies Inc-A",
        "最新价": 1.77,
        "涨跌额": -0.1,
        "涨跌幅": -5.35,
        "开盘价": 1.87,
        "最高价": 1.96,
        "最低价": 1.75,
        "昨收价": 1.87,
        "总市值": 74066229.0,
        "市盈率": -1.31,
        "成交量": 176885.0,
        "成交额": 329482.0,
        "振幅": 11.23,
        "换手率": 0.42,
        "symbol": "106.RBT"
    },
    {
        "序号": 11375,
        "name": "宏利营造",
        "最新价": 0.53,
        "涨跌额": -0.03,
        "涨跌幅": -5.36,
        "开盘价": 0.56,
        "最高价": 0.59,
        "最低价": 0.52,
        "昨收价": 0.56,
        "总市值": 8001055.0,
        "市盈率": -13.4,
        "成交量": 28213.0,
        "成交额": 15219.0,
        "振幅": 12.5,
        "换手率": 0.19,
        "symbol": "105.WLGS"
    },
    {
        "序号": 11376,
        "name": "Research Solutions Inc",
        "最新价": 2.65,
        "涨跌额": -0.15,
        "涨跌幅": -5.36,
        "开盘价": 2.85,
        "最高价": 2.85,
        "最低价": 2.6,
        "昨收价": 2.8,
        "总市值": 78503825.0,
        "市盈率": -124.41,
        "成交量": 41847.0,
        "成交额": 110967.0,
        "振幅": 8.93,
        "换手率": 0.14,
        "symbol": "105.RSSS"
    },
    {
        "序号": 11377,
        "name": "瑞图生态",
        "最新价": 0.53,
        "涨跌额": -0.03,
        "涨跌幅": -5.36,
        "开盘价": 0.53,
        "最高价": 0.56,
        "最低价": 0.51,
        "昨收价": 0.56,
        "总市值": 19849497.0,
        "市盈率": -1.36,
        "成交量": 92684.0,
        "成交额": 48642.0,
        "振幅": 8.93,
        "换手率": 0.25,
        "symbol": "105.RETO"
    },
    {
        "序号": 11378,
        "name": "iHeartMedia Inc-A",
        "最新价": 2.65,
        "涨跌额": -0.15,
        "涨跌幅": -5.36,
        "开盘价": 2.75,
        "最高价": 2.8,
        "最低价": 2.46,
        "昨收价": 2.8,
        "总市值": 383340703.0,
        "市盈率": -0.37,
        "成交量": 1084373.0,
        "成交额": 2841135.0,
        "振幅": 12.14,
        "换手率": 0.75,
        "symbol": "105.IHRT"
    },
    {
        "序号": 11379,
        "name": "Cingulate Inc",
        "最新价": 3.53,
        "涨跌额": -0.2,
        "涨跌幅": -5.36,
        "开盘价": 3.66,
        "最高价": 3.82,
        "最低价": 3.42,
        "昨收价": 3.73,
        "总市值": 3307611.0,
        "市盈率": -0.16,
        "成交量": 33989.0,
        "成交额": 122303.0,
        "振幅": 10.72,
        "换手率": 3.63,
        "symbol": "105.CING"
    },
    {
        "序号": 11380,
        "name": "Agrify Corp",
        "最新价": 1.41,
        "涨跌额": -0.08,
        "涨跌幅": -5.37,
        "开盘价": 1.54,
        "最高价": 1.6,
        "最低价": 1.41,
        "昨收价": 1.49,
        "总市值": 2328306.0,
        "市盈率": -0.01,
        "成交量": 13219.0,
        "成交额": 20217.0,
        "振幅": 12.75,
        "换手率": 0.8,
        "symbol": "105.AGFY"
    },
    {
        "序号": 11381,
        "name": "Scinai Immunotherapeutics Ltd A",
        "最新价": 0.7,
        "涨跌额": -0.04,
        "涨跌幅": -5.41,
        "开盘价": 0.74,
        "最高价": 0.75,
        "最低价": 0.7,
        "昨收价": 0.74,
        "总市值": 3046349.0,
        "市盈率": -0.4,
        "成交量": 14630.0,
        "成交额": 10702.0,
        "振幅": 6.76,
        "换手率": 0.34,
        "symbol": "105.SCNI"
    },
    {
        "序号": 11382,
        "name": "Marin Software Inc",
        "最新价": 0.35,
        "涨跌额": -0.02,
        "涨跌幅": -5.41,
        "开盘价": 0.36,
        "最高价": 0.36,
        "最低价": 0.34,
        "昨收价": 0.37,
        "总市值": 6314205.0,
        "市盈率": -0.29,
        "成交量": 55265.0,
        "成交额": 19599.0,
        "振幅": 5.41,
        "换手率": 0.31,
        "symbol": "105.MRIN"
    },
    {
        "序号": 11383,
        "name": "泰和诚医疗",
        "最新价": 1.05,
        "涨跌额": -0.06,
        "涨跌幅": -5.41,
        "开盘价": 1.11,
        "最高价": 1.11,
        "最低价": 1.05,
        "昨收价": 1.11,
        "总市值": 45588090.0,
        "市盈率": -0.69,
        "成交量": 2822.0,
        "成交额": 3115.0,
        "振幅": 5.41,
        "换手率": 0.01,
        "symbol": "106.CCM"
    },
    {
        "序号": 11384,
        "name": "万国数据",
        "最新价": 8.73,
        "涨跌额": -0.5,
        "涨跌幅": -5.42,
        "开盘价": 9.02,
        "最高价": 9.15,
        "最低价": 8.71,
        "昨收价": 9.23,
        "总市值": 1663537501.0,
        "市盈率": -8.94,
        "成交量": 780455.0,
        "成交额": 6890045.0,
        "振幅": 4.77,
        "换手率": 0.41,
        "symbol": "105.GDS"
    },
    {
        "序号": 11385,
        "name": "CSI Compressco LP",
        "最新价": 1.57,
        "涨跌额": -0.09,
        "涨跌幅": -5.42,
        "开盘价": 1.62,
        "最高价": 1.84,
        "最低价": 1.55,
        "昨收价": 1.66,
        "总市值": 222932194.0,
        "市盈率": -21.48,
        "成交量": 133541.0,
        "成交额": 224574.0,
        "振幅": 17.47,
        "换手率": 0.09,
        "symbol": "105.CCLP"
    },
    {
        "序号": 11386,
        "name": "Cactus Acquisition Corp 1 Ltd U",
        "最新价": 10.8,
        "涨跌额": -0.62,
        "涨跌幅": -5.43,
        "开盘价": 10.85,
        "最高价": 11.0,
        "最低价": 10.8,
        "昨收价": 11.42,
        "总市值": 54808607.0,
        "市盈率": 24.76,
        "成交量": 6499.0,
        "成交额": 70627.0,
        "振幅": 1.75,
        "换手率": null,
        "symbol": "105.CCTSU"
    },
    {
        "序号": 11387,
        "name": "Planet Labs PBC-A",
        "最新价": 2.43,
        "涨跌额": -0.14,
        "涨跌幅": -5.45,
        "开盘价": 2.49,
        "最高价": 2.56,
        "最低价": 2.34,
        "昨收价": 2.57,
        "总市值": 698931063.0,
        "市盈率": -4.71,
        "成交量": 3319619.0,
        "成交额": 8089697.0,
        "振幅": 8.56,
        "换手率": 1.15,
        "symbol": "106.PL"
    },
    {
        "序号": 11388,
        "name": "Fortress Biotech Inc",
        "最新价": 3.12,
        "涨跌额": -0.18,
        "涨跌幅": -5.45,
        "开盘价": 3.36,
        "最高价": 3.85,
        "最低价": 3.03,
        "昨收价": 3.3,
        "总市值": 46262583.0,
        "市盈率": -0.59,
        "成交量": 1397642.0,
        "成交额": 4854220.0,
        "振幅": 24.85,
        "换手率": 9.43,
        "symbol": "105.FBIO"
    },
    {
        "序号": 11389,
        "name": "Motorcar Parts of America Inc",
        "最新价": 9.35,
        "涨跌额": -0.54,
        "涨跌幅": -5.46,
        "开盘价": 9.38,
        "最高价": 9.81,
        "最低价": 9.31,
        "昨收价": 9.89,
        "总市值": 183329611.0,
        "市盈率": -207.62,
        "成交量": 46725.0,
        "成交额": 443463.0,
        "振幅": 5.06,
        "换手率": 0.24,
        "symbol": "105.MPAA"
    },
    {
        "序号": 11390,
        "name": "Healthcare Triangle Inc",
        "最新价": 3.46,
        "涨跌额": -0.2,
        "涨跌幅": -5.46,
        "开盘价": 3.53,
        "最高价": 3.65,
        "最低价": 3.41,
        "昨收价": 3.66,
        "总市值": 14800399.0,
        "市盈率": -1.25,
        "成交量": 9806.0,
        "成交额": 34489.0,
        "振幅": 6.56,
        "换手率": 0.23,
        "symbol": "105.HCTI"
    },
    {
        "序号": 11391,
        "name": "Alpha Technology Group Ltd",
        "最新价": 14.3,
        "涨跌额": -0.83,
        "涨跌幅": -5.49,
        "开盘价": 14.76,
        "最高价": 15.2,
        "最低价": 13.55,
        "昨收价": 15.13,
        "总市值": 218253750.0,
        "市盈率": null,
        "成交量": 18056.0,
        "成交额": 261135.0,
        "振幅": 10.91,
        "换手率": 0.12,
        "symbol": "105.ATGL"
    },
    {
        "序号": 11392,
        "name": "Wallbox NV-A",
        "最新价": 1.88,
        "涨跌额": -0.11,
        "涨跌幅": -5.53,
        "开盘价": 1.96,
        "最高价": 2.01,
        "最低价": 1.84,
        "昨收价": 1.99,
        "总市值": 373568226.0,
        "市盈率": -2.77,
        "成交量": 1168856.0,
        "成交额": 2234339.0,
        "振幅": 8.54,
        "换手率": 0.59,
        "symbol": "106.WBX"
    },
    {
        "序号": 11393,
        "name": "XWELL Inc",
        "最新价": 2.04,
        "涨跌额": -0.12,
        "涨跌幅": -5.56,
        "开盘价": 2.12,
        "最高价": 2.19,
        "最低价": 2.04,
        "昨收价": 2.16,
        "总市值": 8526447.0,
        "市盈率": -0.25,
        "成交量": 9908.0,
        "成交额": 21004.0,
        "振幅": 6.94,
        "换手率": 0.24,
        "symbol": "105.XWEL"
    },
    {
        "序号": 11394,
        "name": "Sensei Biotherapeutics Inc",
        "最新价": 0.68,
        "涨跌额": -0.04,
        "涨跌幅": -5.56,
        "开盘价": 0.72,
        "最高价": 0.73,
        "最低价": 0.68,
        "昨收价": 0.72,
        "总市值": 16997632.0,
        "市盈率": -0.44,
        "成交量": 29735.0,
        "成交额": 20487.0,
        "振幅": 6.94,
        "换手率": 0.12,
        "symbol": "105.SNSE"
    },
    {
        "序号": 11395,
        "name": "Nuburu Inc-A",
        "最新价": 0.17,
        "涨跌额": -0.01,
        "涨跌幅": -5.56,
        "开盘价": 0.18,
        "最高价": 0.18,
        "最低价": 0.17,
        "昨收价": 0.18,
        "总市值": 6046978.0,
        "市盈率": 1.14,
        "成交量": 462543.0,
        "成交额": 80963.0,
        "振幅": 5.56,
        "换手率": 1.3,
        "symbol": "107.BURU"
    },
    {
        "序号": 11396,
        "name": "生物钥匙国际",
        "最新价": 0.17,
        "涨跌额": -0.01,
        "涨跌幅": -5.56,
        "开盘价": 0.17,
        "最高价": 0.19,
        "最低价": 0.16,
        "昨收价": 0.18,
        "总市值": 2334384.0,
        "市盈率": -0.26,
        "成交量": 783328.0,
        "成交额": 134342.0,
        "振幅": 16.67,
        "换手率": 5.7,
        "symbol": "105.BKYI"
    },
    {
        "序号": 11397,
        "name": "National Beverage Corp",
        "最新价": 46.58,
        "涨跌额": -2.75,
        "涨跌幅": -5.57,
        "开盘价": 49.08,
        "最高价": 49.92,
        "最低价": 46.5,
        "昨收价": 49.33,
        "总市值": 4350234109.0,
        "市盈率": 26.51,
        "成交量": 238026.0,
        "成交额": 11350975.0,
        "振幅": 6.93,
        "换手率": 0.25,
        "symbol": "105.FIZZ"
    },
    {
        "序号": 11398,
        "name": "SAB Biotherapeutics Inc",
        "最新价": 0.84,
        "涨跌额": -0.05,
        "涨跌幅": -5.62,
        "开盘价": 0.89,
        "最高价": 0.89,
        "最低价": 0.83,
        "昨收价": 0.89,
        "总市值": 43948091.0,
        "市盈率": -1.62,
        "成交量": 32908.0,
        "成交额": 28106.0,
        "振幅": 6.74,
        "换手率": 0.06,
        "symbol": "105.SABS"
    },
    {
        "序号": 11399,
        "name": "Mesa Air Group Inc",
        "最新价": 1.17,
        "涨跌额": -0.07,
        "涨跌幅": -5.65,
        "开盘价": 1.25,
        "最高价": 1.28,
        "最低价": 1.14,
        "昨收价": 1.24,
        "总市值": 47771795.0,
        "市盈率": -0.23,
        "成交量": 534790.0,
        "成交额": 634602.0,
        "振幅": 11.29,
        "换手率": 1.31,
        "symbol": "105.MESA"
    },
    {
        "序号": 11400,
        "name": "Wave Life Sciences Ltd",
        "最新价": 4.51,
        "涨跌额": -0.27,
        "涨跌幅": -5.65,
        "开盘价": 4.63,
        "最高价": 4.81,
        "最低价": 4.46,
        "昨收价": 4.78,
        "总市值": 446733098.0,
        "市盈率": -5.26,
        "成交量": 2789421.0,
        "成交额": 12704142.0,
        "振幅": 7.32,
        "换手率": 2.82,
        "symbol": "105.WVE"
    },
    {
        "序号": 11401,
        "name": "Esperion Therapeutics Inc",
        "最新价": 1.49,
        "涨跌额": -0.09,
        "涨跌幅": -5.7,
        "开盘价": 1.57,
        "最高价": 1.6,
        "最低价": 1.48,
        "昨收价": 1.58,
        "总市值": 169439500.0,
        "市盈率": -0.81,
        "成交量": 2888452.0,
        "成交额": 4414094.0,
        "振幅": 7.59,
        "换手率": 2.54,
        "symbol": "105.ESPR"
    },
    {
        "序号": 11402,
        "name": "SenesTech Inc",
        "最新价": 0.66,
        "涨跌额": -0.04,
        "涨跌幅": -5.71,
        "开盘价": 0.69,
        "最高价": 0.69,
        "最低价": 0.64,
        "昨收价": 0.7,
        "总市值": 622115.0,
        "市盈率": -0.08,
        "成交量": 196960.0,
        "成交额": 129232.0,
        "振幅": 7.14,
        "换手率": 20.9,
        "symbol": "105.SNES"
    },
    {
        "序号": 11403,
        "name": "My Size Inc",
        "最新价": 0.66,
        "涨跌额": -0.04,
        "涨跌幅": -5.71,
        "开盘价": 0.72,
        "最高价": 0.75,
        "最低价": 0.65,
        "昨收价": 0.7,
        "总市值": 1967983.0,
        "市盈率": -0.26,
        "成交量": 21153.0,
        "成交额": 14268.0,
        "振幅": 14.29,
        "换手率": 0.71,
        "symbol": "105.MYSZ"
    },
    {
        "序号": 11404,
        "name": "Digital World Acquisition Corp ",
        "最新价": 19.8,
        "涨跌额": -1.2,
        "涨跌幅": -5.71,
        "开盘价": 19.2,
        "最高价": 19.8,
        "最低价": 19.2,
        "昨收价": 21.0,
        "总市值": 736170554.0,
        "市盈率": -29.29,
        "成交量": 1146.0,
        "成交额": 22465.0,
        "振幅": 2.86,
        "换手率": null,
        "symbol": "105.DWACU"
    },
    {
        "序号": 11405,
        "name": "Blade Air Mobility Inc Wt",
        "最新价": 0.33,
        "涨跌额": -0.02,
        "涨跌幅": -5.71,
        "开盘价": 0.37,
        "最高价": 0.39,
        "最低价": 0.33,
        "昨收价": 0.35,
        "总市值": null,
        "市盈率": null,
        "成交量": 2113.0,
        "成交额": 762.0,
        "振幅": 17.14,
        "换手率": null,
        "symbol": "105.BLDEW"
    },
    {
        "序号": 11406,
        "name": "Navios Maritime Holdings Inc AD",
        "最新价": 16.0,
        "涨跌额": -0.97,
        "涨跌幅": -5.72,
        "开盘价": 15.9,
        "最高价": 16.0,
        "最低价": 14.4,
        "昨收价": 16.97,
        "总市值": 82368.0,
        "市盈率": null,
        "成交量": 1760.0,
        "成交额": 26969.0,
        "振幅": 9.43,
        "换手率": 34.19,
        "symbol": "106.NM_G"
    },
    {
        "序号": 11407,
        "name": "Xenetic Biosciences Inc",
        "最新价": 3.94,
        "涨跌额": -0.24,
        "涨跌幅": -5.74,
        "开盘价": 3.97,
        "最高价": 4.01,
        "最低价": 3.92,
        "昨收价": 4.18,
        "总市值": 6070295.0,
        "市盈率": -1.37,
        "成交量": 3798.0,
        "成交额": 15062.0,
        "振幅": 2.15,
        "换手率": 0.25,
        "symbol": "105.XBIO"
    },
    {
        "序号": 11408,
        "name": "Protagenic Therapeutics Inc",
        "最新价": 0.82,
        "涨跌额": -0.05,
        "涨跌幅": -5.75,
        "开盘价": 0.77,
        "最高价": 0.87,
        "最低价": 0.77,
        "昨收价": 0.87,
        "总市值": 3636808.0,
        "市盈率": -0.93,
        "成交量": 6370.0,
        "成交额": 5442.0,
        "振幅": 11.49,
        "换手率": 0.14,
        "symbol": "105.PTIX"
    },
    {
        "序号": 11409,
        "name": "Fresh Vine Wine Inc",
        "最新价": 0.65,
        "涨跌额": -0.04,
        "涨跌幅": -5.8,
        "开盘价": 0.68,
        "最高价": 0.69,
        "最低价": 0.61,
        "昨收价": 0.69,
        "总市值": 10384548.0,
        "市盈率": -0.84,
        "成交量": 284560.0,
        "成交额": 185498.0,
        "振幅": 11.59,
        "换手率": 1.78,
        "symbol": "107.VINE"
    },
    {
        "序号": 11410,
        "name": "房多多",
        "最新价": 0.65,
        "涨跌额": -0.04,
        "涨跌幅": -5.8,
        "开盘价": 0.69,
        "最高价": 0.73,
        "最低价": 0.62,
        "昨收价": 0.69,
        "总市值": 2977441.0,
        "市盈率": -0.68,
        "成交量": 44399.0,
        "成交额": 29618.0,
        "振幅": 15.94,
        "换手率": 0.97,
        "symbol": "105.DUO"
    },
    {
        "序号": 11411,
        "name": "BIOLASE Inc",
        "最新价": 1.13,
        "涨跌额": -0.07,
        "涨跌幅": -5.83,
        "开盘价": 1.2,
        "最高价": 1.21,
        "最低价": 1.13,
        "昨收价": 1.2,
        "总市值": 3858971.0,
        "市盈率": -0.15,
        "成交量": 82282.0,
        "成交额": 96207.0,
        "振幅": 6.67,
        "换手率": 2.41,
        "symbol": "105.BIOL"
    },
    {
        "序号": 11412,
        "name": "TherapeuticsMD Inc",
        "最新价": 2.08,
        "涨跌额": -0.13,
        "涨跌幅": -5.88,
        "开盘价": 2.16,
        "最高价": 2.27,
        "最低价": 2.01,
        "昨收价": 2.21,
        "总市值": 21996499.0,
        "市盈率": 0.32,
        "成交量": 85242.0,
        "成交额": 178386.0,
        "振幅": 11.76,
        "换手率": 0.81,
        "symbol": "105.TXMD"
    },
    {
        "序号": 11413,
        "name": "CytoSorbents Corp",
        "最新价": 1.28,
        "涨跌额": -0.08,
        "涨跌幅": -5.88,
        "开盘价": 1.4,
        "最高价": 1.4,
        "最低价": 1.2,
        "昨收价": 1.36,
        "总市值": 56881292.0,
        "市盈率": -2.43,
        "成交量": 182121.0,
        "成交额": 230042.0,
        "振幅": 14.71,
        "换手率": 0.41,
        "symbol": "105.CTSO"
    },
    {
        "序号": 11414,
        "name": "奥瑞金种业",
        "最新价": 1.9,
        "涨跌额": -0.12,
        "涨跌幅": -5.94,
        "开盘价": 1.96,
        "最高价": 2.03,
        "最低价": 1.85,
        "昨收价": 2.02,
        "总市值": 11962506.0,
        "市盈率": -9.65,
        "成交量": 33820.0,
        "成交额": 65099.0,
        "振幅": 8.91,
        "换手率": 0.54,
        "symbol": "105.SEED"
    },
    {
        "序号": 11415,
        "name": "CFSB Bancorp Inc",
        "最新价": 6.01,
        "涨跌额": -0.38,
        "涨跌幅": -5.95,
        "开盘价": 6.37,
        "最高价": 6.45,
        "最低价": 6.01,
        "昨收价": 6.39,
        "总市值": 39862178.0,
        "市盈率": 43.14,
        "成交量": 2780.0,
        "成交额": 17755.0,
        "振幅": 6.89,
        "换手率": 0.04,
        "symbol": "105.CFSB"
    },
    {
        "序号": 11416,
        "name": "Asset Entities Inc-B",
        "最新价": 0.63,
        "涨跌额": -0.04,
        "涨跌幅": -5.97,
        "开盘价": 0.68,
        "最高价": 0.73,
        "最低价": 0.6,
        "昨收价": 0.67,
        "总市值": 8835378.0,
        "市盈率": -2.32,
        "成交量": 286531.0,
        "成交额": 187873.0,
        "振幅": 19.4,
        "换手率": 2.04,
        "symbol": "105.ASST"
    },
    {
        "序号": 11417,
        "name": "VS Trust 2x Long VIX Futures ET",
        "最新价": 16.3,
        "涨跌额": -1.04,
        "涨跌幅": -6.0,
        "开盘价": 17.07,
        "最高价": 17.07,
        "最低价": 16.28,
        "昨收价": 17.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 2837800.0,
        "成交额": 47141615.0,
        "振幅": 4.56,
        "换手率": null,
        "symbol": "107.UVIX"
    },
    {
        "序号": 11418,
        "name": "SurgePays Inc Wt",
        "最新价": 1.41,
        "涨跌额": -0.09,
        "涨跌幅": -6.0,
        "开盘价": 1.5,
        "最高价": 1.53,
        "最低价": 1.36,
        "昨收价": 1.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 1900.0,
        "成交额": 2684.0,
        "振幅": 11.33,
        "换手率": null,
        "symbol": "105.SURGW"
    },
    {
        "序号": 11419,
        "name": "Austin Gold Corp",
        "最新价": 0.78,
        "涨跌额": -0.05,
        "涨跌幅": -6.02,
        "开盘价": 0.83,
        "最高价": 0.83,
        "最低价": 0.78,
        "昨收价": 0.83,
        "总市值": 10351965.0,
        "市盈率": -3.45,
        "成交量": 5477.0,
        "成交额": 4422.0,
        "振幅": 6.02,
        "换手率": 0.04,
        "symbol": "107.AUST"
    },
    {
        "序号": 11420,
        "name": "Akso Health Group ADR",
        "最新价": 0.78,
        "涨跌额": -0.05,
        "涨跌幅": -6.02,
        "开盘价": 0.8,
        "最高价": 0.83,
        "最低价": 0.76,
        "昨收价": 0.83,
        "总市值": 18584423.0,
        "市盈率": -13.67,
        "成交量": 16015.0,
        "成交额": 12620.0,
        "振幅": 8.43,
        "换手率": 0.07,
        "symbol": "105.AHG"
    },
    {
        "序号": 11421,
        "name": "Clearside Biomedical Inc",
        "最新价": 1.09,
        "涨跌额": -0.07,
        "涨跌幅": -6.03,
        "开盘价": 1.07,
        "最高价": 1.17,
        "最低价": 1.06,
        "昨收价": 1.16,
        "总市值": 68025664.0,
        "市盈率": -1.82,
        "成交量": 190075.0,
        "成交额": 208251.0,
        "振幅": 9.48,
        "换手率": 0.3,
        "symbol": "105.CLSD"
    },
    {
        "序号": 11422,
        "name": "KULR Technology Group Inc",
        "最新价": 0.31,
        "涨跌额": -0.02,
        "涨跌幅": -6.06,
        "开盘价": 0.32,
        "最高价": 0.32,
        "最低价": 0.3,
        "昨收价": 0.33,
        "总市值": 39644249.0,
        "市盈率": -1.73,
        "成交量": 574178.0,
        "成交额": 175907.0,
        "振幅": 6.06,
        "换手率": 0.45,
        "symbol": "107.KULR"
    },
    {
        "序号": 11423,
        "name": "Generation Income Properties In",
        "最新价": 0.31,
        "涨跌额": -0.02,
        "涨跌幅": -6.06,
        "开盘价": 0.31,
        "最高价": 0.31,
        "最低价": 0.31,
        "昨收价": 0.33,
        "总市值": null,
        "市盈率": null,
        "成交量": 900.0,
        "成交额": 275.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.GIPRW"
    },
    {
        "序号": 11424,
        "name": "Alterity Therapeutics Ltd ADR",
        "最新价": 2.63,
        "涨跌额": -0.17,
        "涨跌幅": -6.07,
        "开盘价": 2.75,
        "最高价": 2.84,
        "最低价": 2.61,
        "昨收价": 2.8,
        "总市值": 10694885.0,
        "市盈率": -1.17,
        "成交量": 73298.0,
        "成交额": 200001.0,
        "振幅": 8.21,
        "换手率": 1.8,
        "symbol": "105.ATHE"
    },
    {
        "序号": 11425,
        "name": "凯信远达",
        "最新价": 7.04,
        "涨跌额": -0.46,
        "涨跌幅": -6.13,
        "开盘价": 7.51,
        "最高价": 8.36,
        "最低价": 6.1,
        "昨收价": 7.5,
        "总市值": 94182352.0,
        "市盈率": -3.48,
        "成交量": 268287.0,
        "成交额": 2104429.0,
        "振幅": 30.13,
        "换手率": 2.01,
        "symbol": "105.CASI"
    },
    {
        "序号": 11426,
        "name": "Neonode Inc",
        "最新价": 1.53,
        "涨跌额": -0.1,
        "涨跌幅": -6.13,
        "开盘价": 1.63,
        "最高价": 1.63,
        "最低价": 1.49,
        "昨收价": 1.63,
        "总市值": 23500006.0,
        "市盈率": -4.39,
        "成交量": 47431.0,
        "成交额": 73716.0,
        "振幅": 8.59,
        "换手率": 0.31,
        "symbol": "105.NEON"
    },
    {
        "序号": 11427,
        "name": "ESGL Holdings Ltd-A",
        "最新价": 0.61,
        "涨跌额": -0.04,
        "涨跌幅": -6.15,
        "开盘价": 0.7,
        "最高价": 0.72,
        "最低价": 0.59,
        "昨收价": 0.65,
        "总市值": 7736654.0,
        "市盈率": -8.93,
        "成交量": 86288.0,
        "成交额": 56911.0,
        "振幅": 20.0,
        "换手率": 0.68,
        "symbol": "105.ESGL"
    },
    {
        "序号": 11428,
        "name": "Lucid Diagnostics Inc",
        "最新价": 1.37,
        "涨跌额": -0.09,
        "涨跌幅": -6.16,
        "开盘价": 1.4,
        "最高价": 1.49,
        "最低价": 1.37,
        "昨收价": 1.46,
        "总市值": 61194206.0,
        "市盈率": -1.08,
        "成交量": 16824.0,
        "成交额": 23876.0,
        "振幅": 8.22,
        "换手率": 0.04,
        "symbol": "105.LUCD"
    },
    {
        "序号": 11429,
        "name": "Processa Pharmaceuticals Inc",
        "最新价": 0.76,
        "涨跌额": -0.05,
        "涨跌幅": -6.17,
        "开盘价": 0.84,
        "最高价": 0.87,
        "最低价": 0.76,
        "昨收价": 0.81,
        "总市值": 18719920.0,
        "市盈率": -0.86,
        "成交量": 576972.0,
        "成交额": 466127.0,
        "振幅": 13.58,
        "换手率": 2.34,
        "symbol": "105.PCSA"
    },
    {
        "序号": 11430,
        "name": "毫微",
        "最新价": 1.82,
        "涨跌额": -0.12,
        "涨跌幅": -6.19,
        "开盘价": 1.92,
        "最高价": 1.94,
        "最低价": 1.7,
        "昨收价": 1.94,
        "总市值": 118894917.0,
        "市盈率": -3.87,
        "成交量": 21028.0,
        "成交额": 39195.0,
        "振幅": 12.37,
        "换手率": 0.03,
        "symbol": "105.NA"
    },
    {
        "序号": 11431,
        "name": "华钦科技",
        "最新价": 1.06,
        "涨跌额": -0.07,
        "涨跌幅": -6.19,
        "开盘价": 1.13,
        "最高价": 1.13,
        "最低价": 1.06,
        "昨收价": 1.13,
        "总市值": 27121289.0,
        "市盈率": 140.87,
        "成交量": 14571.0,
        "成交额": 16122.0,
        "振幅": 6.19,
        "换手率": 0.06,
        "symbol": "105.CLPS"
    },
    {
        "序号": 11432,
        "name": "Xos Inc",
        "最新价": 6.18,
        "涨跌额": -0.41,
        "涨跌幅": -6.22,
        "开盘价": 6.04,
        "最高价": 6.97,
        "最低价": 6.0,
        "昨收价": 6.59,
        "总市值": 36643877.0,
        "市盈率": -0.45,
        "成交量": 57658.0,
        "成交额": 365920.0,
        "振幅": 14.72,
        "换手率": 0.97,
        "symbol": "105.XOS"
    },
    {
        "序号": 11433,
        "name": "Oblong Inc",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.14,
        "昨收价": 0.16,
        "总市值": 2242188.0,
        "市盈率": -0.51,
        "成交量": 864237.0,
        "成交额": 128767.0,
        "振幅": 6.25,
        "换手率": 5.78,
        "symbol": "105.OBLG"
    },
    {
        "序号": 11434,
        "name": "InVivo Therapeutics Holdings Co",
        "最新价": 0.75,
        "涨跌额": -0.05,
        "涨跌幅": -6.25,
        "开盘价": 0.79,
        "最高价": 0.83,
        "最低价": 0.72,
        "昨收价": 0.8,
        "总市值": 2329085.0,
        "市盈率": -0.25,
        "成交量": 16085.0,
        "成交额": 12524.0,
        "振幅": 13.75,
        "换手率": 0.52,
        "symbol": "105.NVIV"
    },
    {
        "序号": 11435,
        "name": "Near Intelligence Inc-A",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.16,
        "最高价": 0.17,
        "最低价": 0.15,
        "昨收价": 0.16,
        "总市值": 7761825.0,
        "市盈率": 0.18,
        "成交量": 3614055.0,
        "成交额": 558013.0,
        "振幅": 12.5,
        "换手率": 6.98,
        "symbol": "105.NIR"
    },
    {
        "序号": 11436,
        "name": "Mullen Automotive Inc",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.16,
        "最高价": 0.16,
        "最低价": 0.15,
        "昨收价": 0.16,
        "总市值": 61964646.0,
        "市盈率": -0.06,
        "成交量": 67873462.0,
        "成交额": 10287594.0,
        "振幅": 6.25,
        "换手率": 16.43,
        "symbol": "105.MULN"
    },
    {
        "序号": 11437,
        "name": "Innovative Eyewear Inc",
        "最新价": 0.45,
        "涨跌额": -0.03,
        "涨跌幅": -6.25,
        "开盘价": 0.47,
        "最高价": 0.48,
        "最低价": 0.45,
        "昨收价": 0.48,
        "总市值": 5812758.0,
        "市盈率": -0.95,
        "成交量": 43861.0,
        "成交额": 20367.0,
        "振幅": 6.25,
        "换手率": 0.34,
        "symbol": "105.LUCY"
    },
    {
        "序号": 11438,
        "name": "Lexaria Bioscience Corp",
        "最新价": 1.35,
        "涨跌额": -0.09,
        "涨跌幅": -6.25,
        "开盘价": 1.4,
        "最高价": 1.43,
        "最低价": 1.34,
        "昨收价": 1.44,
        "总市值": 13873465.0,
        "市盈率": -2.08,
        "成交量": 146368.0,
        "成交额": 201851.0,
        "振幅": 6.25,
        "换手率": 1.42,
        "symbol": "105.LEXX"
    },
    {
        "序号": 11439,
        "name": "Fusion Fuel Green PLC Wt",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.16,
        "最高价": 0.16,
        "最低价": 0.15,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 13213.0,
        "成交额": 2109.0,
        "振幅": 6.25,
        "换手率": null,
        "symbol": "105.HTOOW"
    },
    {
        "序号": 11440,
        "name": "Bruush Oral Care Inc",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.16,
        "最高价": 0.16,
        "最低价": 0.14,
        "昨收价": 0.16,
        "总市值": 2458829.0,
        "市盈率": -0.33,
        "成交量": 1726582.0,
        "成交额": 263268.0,
        "振幅": 12.5,
        "换手率": 10.53,
        "symbol": "105.BRSH"
    },
    {
        "序号": 11441,
        "name": "大道制药",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "开盘价": 0.15,
        "最高价": 0.16,
        "最低价": 0.14,
        "昨收价": 0.16,
        "总市值": 1987143.0,
        "市盈率": -0.19,
        "成交量": 1204391.0,
        "成交额": 177993.0,
        "振幅": 12.5,
        "换手率": 9.09,
        "symbol": "105.ATXI"
    },
    {
        "序号": 11442,
        "name": "The Beachbody Co Inc-A",
        "最新价": 11.24,
        "涨跌额": -0.75,
        "涨跌幅": -6.26,
        "开盘价": 11.97,
        "最高价": 11.97,
        "最低价": 11.18,
        "昨收价": 11.99,
        "总市值": 70699600.0,
        "市盈率": -0.53,
        "成交量": 17272.0,
        "成交额": 196209.0,
        "振幅": 6.59,
        "换手率": 0.27,
        "symbol": "106.BODY"
    },
    {
        "序号": 11443,
        "name": "Ideal Power Inc",
        "最新价": 8.63,
        "涨跌额": -0.58,
        "涨跌幅": -6.3,
        "开盘价": 9.12,
        "最高价": 9.22,
        "最低价": 8.59,
        "昨收价": 9.21,
        "总市值": 51308345.0,
        "市盈率": -5.44,
        "成交量": 10395.0,
        "成交额": 91568.0,
        "振幅": 6.84,
        "换手率": 0.17,
        "symbol": "105.IPWR"
    },
    {
        "序号": 11444,
        "name": "Avidity Biosciences Inc",
        "最新价": 7.54,
        "涨跌额": -0.51,
        "涨跌幅": -6.34,
        "开盘价": 8.0,
        "最高价": 8.1,
        "最低价": 7.51,
        "昨收价": 8.05,
        "总市值": 558723455.0,
        "市盈率": -2.76,
        "成交量": 1059378.0,
        "成交额": 8087402.0,
        "振幅": 7.33,
        "换手率": 1.43,
        "symbol": "105.RNA"
    },
    {
        "序号": 11445,
        "name": "Smart for Life Inc",
        "最新价": 1.03,
        "涨跌额": -0.07,
        "涨跌幅": -6.36,
        "开盘价": 1.22,
        "最高价": 1.22,
        "最低价": 1.03,
        "昨收价": 1.1,
        "总市值": 820566.0,
        "市盈率": -0.04,
        "成交量": 186557.0,
        "成交额": 199065.0,
        "振幅": 17.27,
        "换手率": 23.42,
        "symbol": "105.SMFL"
    },
    {
        "序号": 11446,
        "name": "Praxis Precision Medicines Inc",
        "最新价": 16.83,
        "涨跌额": -1.15,
        "涨跌幅": -6.4,
        "开盘价": 17.59,
        "最高价": 18.1,
        "最低价": 16.59,
        "昨收价": 17.98,
        "总市值": 144233270.0,
        "市盈率": -1.05,
        "成交量": 40479.0,
        "成交额": 689998.0,
        "振幅": 8.4,
        "换手率": 0.47,
        "symbol": "105.PRAX"
    },
    {
        "序号": 11447,
        "name": "Carbon Revolution PLC",
        "最新价": 32.77,
        "涨跌额": -2.24,
        "涨跌幅": -6.4,
        "开盘价": 35.99,
        "最高价": 35.99,
        "最低价": 32.51,
        "昨收价": 35.01,
        "总市值": 61449780.0,
        "市盈率": 153.97,
        "成交量": 3131.0,
        "成交额": 106718.0,
        "振幅": 9.94,
        "换手率": 0.17,
        "symbol": "105.CREV"
    },
    {
        "序号": 11448,
        "name": "安皮奥制药",
        "最新价": 1.9,
        "涨跌额": -0.13,
        "涨跌幅": -6.4,
        "开盘价": 2.04,
        "最高价": 2.05,
        "最低价": 1.87,
        "昨收价": 2.03,
        "总市值": 1580840.0,
        "市盈率": -0.18,
        "成交量": 113751.0,
        "成交额": 222684.0,
        "振幅": 8.87,
        "换手率": 13.67,
        "symbol": "107.AMPE"
    },
    {
        "序号": 11449,
        "name": "见知教育",
        "最新价": 0.73,
        "涨跌额": -0.05,
        "涨跌幅": -6.41,
        "开盘价": 0.7,
        "最高价": 0.74,
        "最低价": 0.7,
        "昨收价": 0.78,
        "总市值": 44205150.0,
        "市盈率": -1.55,
        "成交量": 2817.0,
        "成交额": 2061.0,
        "振幅": 5.13,
        "换手率": 0.0,
        "symbol": "105.JZ"
    },
    {
        "序号": 11450,
        "name": "The Gabelli Multimedia Trust",
        "最新价": 5.68,
        "涨跌额": -0.39,
        "涨跌幅": -6.43,
        "开盘价": 6.04,
        "最高价": 6.14,
        "最低价": 5.67,
        "昨收价": 6.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 108053.0,
        "成交额": 634026.0,
        "振幅": 7.74,
        "换手率": null,
        "symbol": "106.GGT"
    },
    {
        "序号": 11451,
        "name": "MicroSectors Gold Miners 3X Lev",
        "最新价": 29.22,
        "涨跌额": -2.01,
        "涨跌幅": -6.44,
        "开盘价": 29.14,
        "最高价": 30.6,
        "最低价": 28.29,
        "昨收价": 31.23,
        "总市值": 21064965684.0,
        "市盈率": 6.64,
        "成交量": 1421470.0,
        "成交额": 41889396.0,
        "振幅": 7.4,
        "换手率": null,
        "symbol": "107.GDXU"
    },
    {
        "序号": 11452,
        "name": "SEALSQ Corp",
        "最新价": 1.01,
        "涨跌额": -0.07,
        "涨跌幅": -6.48,
        "开盘价": 1.1,
        "最高价": 1.13,
        "最低价": 0.98,
        "昨收价": 1.08,
        "总市值": 9091212.0,
        "市盈率": 1.79,
        "成交量": 311764.0,
        "成交额": 320335.0,
        "振幅": 13.89,
        "换手率": 3.46,
        "symbol": "105.LAES"
    },
    {
        "序号": 11453,
        "name": "Rezolute Inc",
        "最新价": 0.86,
        "涨跌额": -0.06,
        "涨跌幅": -6.52,
        "开盘价": 0.93,
        "最高价": 0.94,
        "最低价": 0.81,
        "昨收价": 0.92,
        "总市值": 34077733.0,
        "市盈率": -0.6,
        "成交量": 9661.0,
        "成交额": 8610.0,
        "振幅": 14.13,
        "换手率": 0.02,
        "symbol": "105.RZLT"
    },
    {
        "序号": 11454,
        "name": "图森未来",
        "最新价": 1.0,
        "涨跌额": -0.07,
        "涨跌幅": -6.54,
        "开盘价": 1.07,
        "最高价": 1.11,
        "最低价": 1.0,
        "昨收价": 1.07,
        "总市值": 229890683.0,
        "市盈率": -0.64,
        "成交量": 952741.0,
        "成交额": 983099.0,
        "振幅": 10.28,
        "换手率": 0.41,
        "symbol": "105.TSP"
    },
    {
        "序号": 11455,
        "name": "CareMax Inc-A",
        "最新价": 0.71,
        "涨跌额": -0.05,
        "涨跌幅": -6.58,
        "开盘价": 0.77,
        "最高价": 0.8,
        "最低价": 0.7,
        "昨收价": 0.76,
        "总市值": 79589762.0,
        "市盈率": -0.38,
        "成交量": 191052.0,
        "成交额": 140379.0,
        "振幅": 13.16,
        "换手率": 0.17,
        "symbol": "105.CMAX"
    },
    {
        "序号": 11456,
        "name": "二倍做多白银ETF-ProShares",
        "最新价": 25.68,
        "涨跌额": -1.81,
        "涨跌幅": -6.58,
        "开盘价": 26.38,
        "最高价": 26.86,
        "最低价": 25.51,
        "昨收价": 27.49,
        "总市值": null,
        "市盈率": null,
        "成交量": 2409763.0,
        "成交额": 62596962.0,
        "振幅": 4.91,
        "换手率": null,
        "symbol": "107.AGQ"
    },
    {
        "序号": 11457,
        "name": "Pixie Dust Technologies Inc ADR",
        "最新价": 7.21,
        "涨跌额": -0.51,
        "涨跌幅": -6.61,
        "开盘价": 7.69,
        "最高价": 7.69,
        "最低价": 7.2,
        "昨收价": 7.72,
        "总市值": 106003345.0,
        "市盈率": -7.22,
        "成交量": 9414.0,
        "成交额": 70358.0,
        "振幅": 6.35,
        "换手率": 0.06,
        "symbol": "105.PXDT"
    },
    {
        "序号": 11458,
        "name": "Titan Pharmaceuticals Inc",
        "最新价": 0.28,
        "涨跌额": -0.02,
        "涨跌幅": -6.67,
        "开盘价": 0.3,
        "最高价": 0.31,
        "最低价": 0.27,
        "昨收价": 0.3,
        "总市值": 4218563.0,
        "市盈率": -0.68,
        "成交量": 69576.0,
        "成交额": 19653.0,
        "振幅": 13.33,
        "换手率": 0.46,
        "symbol": "105.TTNP"
    },
    {
        "序号": 11459,
        "name": "Sientra Inc",
        "最新价": 0.7,
        "涨跌额": -0.05,
        "涨跌幅": -6.67,
        "开盘价": 0.76,
        "最高价": 0.76,
        "最低价": 0.68,
        "昨收价": 0.75,
        "总市值": 8370398.0,
        "市盈率": -0.14,
        "成交量": 232619.0,
        "成交额": 164380.0,
        "振幅": 10.67,
        "换手率": 1.95,
        "symbol": "105.SIEN"
    },
    {
        "序号": 11460,
        "name": "研控科技",
        "最新价": 0.28,
        "涨跌额": -0.02,
        "涨跌幅": -6.67,
        "开盘价": 0.29,
        "最高价": 0.3,
        "最低价": 0.28,
        "昨收价": 0.3,
        "总市值": 13664901.0,
        "市盈率": -1.67,
        "成交量": 94388.0,
        "成交额": 27118.0,
        "振幅": 6.67,
        "换手率": 0.19,
        "symbol": "105.RCON"
    },
    {
        "序号": 11461,
        "name": "Mill City Ventures III Ltd",
        "最新价": 2.8,
        "涨跌额": -0.2,
        "涨跌幅": -6.67,
        "开盘价": 2.97,
        "最高价": 3.0,
        "最低价": 2.75,
        "昨收价": 3.0,
        "总市值": 17878714.0,
        "市盈率": -30.1,
        "成交量": 3248.0,
        "成交额": 9275.0,
        "振幅": 8.33,
        "换手率": 0.05,
        "symbol": "105.MCVT"
    },
    {
        "序号": 11462,
        "name": "Acrivon Therapeutics Inc",
        "最新价": 5.03,
        "涨跌额": -0.36,
        "涨跌幅": -6.68,
        "开盘价": 5.31,
        "最高价": 5.42,
        "最低价": 4.82,
        "昨收价": 5.39,
        "总市值": 111637364.0,
        "市盈率": -2.23,
        "成交量": 22205.0,
        "成交额": 113621.0,
        "振幅": 11.13,
        "换手率": 0.1,
        "symbol": "105.ACRV"
    },
    {
        "序号": 11463,
        "name": "Soligenix Inc",
        "最新价": 0.69,
        "涨跌额": -0.05,
        "涨跌幅": -6.76,
        "开盘价": 0.68,
        "最高价": 0.74,
        "最低价": 0.63,
        "昨收价": 0.74,
        "总市值": 7160984.0,
        "市盈率": -0.89,
        "成交量": 405516.0,
        "成交额": 273093.0,
        "振幅": 14.86,
        "换手率": 3.91,
        "symbol": "105.SNGX"
    },
    {
        "序号": 11464,
        "name": "Next.e.GO NV",
        "最新价": 0.55,
        "涨跌额": -0.04,
        "涨跌幅": -6.78,
        "开盘价": 0.57,
        "最高价": 0.57,
        "最低价": 0.54,
        "昨收价": 0.59,
        "总市值": 51323975.0,
        "市盈率": -49.81,
        "成交量": 62104.0,
        "成交额": 34798.0,
        "振幅": 5.08,
        "换手率": 0.07,
        "symbol": "105.EGOX"
    },
    {
        "序号": 11465,
        "name": "PHAXIAM Therapeutics SA ADR",
        "最新价": 4.52,
        "涨跌额": -0.33,
        "涨跌幅": -6.8,
        "开盘价": 4.52,
        "最高价": 4.52,
        "最低价": 4.52,
        "昨收价": 4.85,
        "总市值": 27459475.0,
        "市盈率": -2.2,
        "成交量": 478.0,
        "成交额": 2160.0,
        "振幅": 0.0,
        "换手率": 0.01,
        "symbol": "105.PHXM"
    },
    {
        "序号": 11466,
        "name": "Avalon GloboCare Corp",
        "最新价": 0.41,
        "涨跌额": -0.03,
        "涨跌幅": -6.82,
        "开盘价": 0.46,
        "最高价": 0.46,
        "最低价": 0.4,
        "昨收价": 0.44,
        "总市值": 4509809.0,
        "市盈率": -0.47,
        "成交量": 24340.0,
        "成交额": 10413.0,
        "振幅": 13.64,
        "换手率": 0.22,
        "symbol": "105.ALBT"
    },
    {
        "序号": 11467,
        "name": "百家云",
        "最新价": 1.5,
        "涨跌额": -0.11,
        "涨跌幅": -6.83,
        "开盘价": 1.5,
        "最高价": 1.61,
        "最低价": 1.5,
        "昨收价": 1.61,
        "总市值": 131539070.0,
        "市盈率": 132.0,
        "成交量": 6570.0,
        "成交额": 9972.0,
        "振幅": 6.83,
        "换手率": 0.01,
        "symbol": "105.RTC"
    },
    {
        "序号": 11468,
        "name": "iBio Inc",
        "最新价": 1.36,
        "涨跌额": -0.1,
        "涨跌幅": -6.85,
        "开盘价": 1.46,
        "最高价": 1.46,
        "最低价": 1.3,
        "昨收价": 1.46,
        "总市值": 4970849.0,
        "市盈率": -0.09,
        "成交量": 362704.0,
        "成交额": 497927.0,
        "振幅": 10.96,
        "换手率": 9.92,
        "symbol": "107.IBIO"
    },
    {
        "序号": 11469,
        "name": "海恩时富",
        "最新价": 10.06,
        "涨跌额": -0.74,
        "涨跌幅": -6.85,
        "开盘价": 10.79,
        "最高价": 10.83,
        "最低价": 10.05,
        "昨收价": 10.8,
        "总市值": 902472721.0,
        "市盈率": -6.74,
        "成交量": 862602.0,
        "成交额": 8827435.0,
        "振幅": 7.22,
        "换手率": 0.96,
        "symbol": "105.HAIN"
    },
    {
        "序号": 11470,
        "name": "DHC Acquisition Corp Unit Cons ",
        "最新价": 11.01,
        "涨跌额": -0.81,
        "涨跌幅": -6.85,
        "开盘价": 11.2,
        "最高价": 11.22,
        "最低价": 10.7,
        "昨收价": 11.82,
        "总市值": 135515715.0,
        "市盈率": 38.66,
        "成交量": 3100.0,
        "成交额": 33712.0,
        "振幅": 4.4,
        "换手率": null,
        "symbol": "105.DHCAU"
    },
    {
        "序号": 11471,
        "name": "UpHealth Inc",
        "最新价": 0.27,
        "涨跌额": -0.02,
        "涨跌幅": -6.9,
        "开盘价": 0.28,
        "最高价": 0.3,
        "最低价": 0.25,
        "昨收价": 0.29,
        "总市值": 4799384.0,
        "市盈率": -0.06,
        "成交量": 463913.0,
        "成交额": 126485.0,
        "振幅": 17.24,
        "换手率": 2.61,
        "symbol": "106.UPH"
    },
    {
        "序号": 11472,
        "name": "Jaguar Health Inc",
        "最新价": 0.27,
        "涨跌额": -0.02,
        "涨跌幅": -6.9,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.27,
        "昨收价": 0.29,
        "总市值": 13704009.0,
        "市盈率": -0.35,
        "成交量": 2700258.0,
        "成交额": 740351.0,
        "振幅": 6.9,
        "换手率": 5.32,
        "symbol": "105.JAGX"
    },
    {
        "序号": 11473,
        "name": "名创优品",
        "最新价": 19.55,
        "涨跌额": -1.45,
        "涨跌幅": -6.9,
        "开盘价": 20.39,
        "最高价": 20.5,
        "最低价": 19.0,
        "昨收价": 21.0,
        "总市值": 6176283335.0,
        "市盈率": 22.69,
        "成交量": 3599623.0,
        "成交额": 70297674.0,
        "振幅": 7.14,
        "换手率": 1.14,
        "symbol": "106.MNSO"
    },
    {
        "序号": 11474,
        "name": "Wheeler Real Estate Investment ",
        "最新价": 0.4,
        "涨跌额": -0.03,
        "涨跌幅": -6.98,
        "开盘价": 0.41,
        "最高价": 0.45,
        "最低价": 0.38,
        "昨收价": 0.43,
        "总市值": 21507915.0,
        "市盈率": -0.87,
        "成交量": 2650736.0,
        "成交额": 1110740.0,
        "振幅": 16.28,
        "换手率": 4.93,
        "symbol": "105.WHLR"
    },
    {
        "序号": 11475,
        "name": "Kazia Therapeutics Ltd ADR",
        "最新价": 0.4,
        "涨跌额": -0.03,
        "涨跌幅": -6.98,
        "开盘价": 0.41,
        "最高价": 0.46,
        "最低价": 0.35,
        "昨收价": 0.43,
        "总市值": 10544618.0,
        "市盈率": -0.78,
        "成交量": 127331.0,
        "成交额": 49387.0,
        "振幅": 25.58,
        "换手率": 0.48,
        "symbol": "105.KZIA"
    },
    {
        "序号": 11476,
        "name": "Turnstone Biologics Corp",
        "最新价": 2.13,
        "涨跌额": -0.16,
        "涨跌幅": -6.99,
        "开盘价": 2.33,
        "最高价": 2.5,
        "最低价": 2.11,
        "昨收价": 2.29,
        "总市值": 49188250.0,
        "市盈率": -0.94,
        "成交量": 23597.0,
        "成交额": 52579.0,
        "振幅": 17.03,
        "换手率": 0.1,
        "symbol": "105.TSBX"
    },
    {
        "序号": 11477,
        "name": "Strong Global Entertainment Inc",
        "最新价": 1.33,
        "涨跌额": -0.1,
        "涨跌幅": -6.99,
        "开盘价": 1.34,
        "最高价": 1.5,
        "最低价": 1.32,
        "昨收价": 1.43,
        "总市值": 10477663.0,
        "市盈率": 14.57,
        "成交量": 13257.0,
        "成交额": 17781.0,
        "振幅": 12.59,
        "换手率": 0.17,
        "symbol": "107.SGE"
    },
    {
        "序号": 11478,
        "name": "Allbirds Inc-A",
        "最新价": 1.06,
        "涨跌额": -0.08,
        "涨跌幅": -7.02,
        "开盘价": 1.09,
        "最高价": 1.16,
        "最低价": 1.05,
        "昨收价": 1.14,
        "总市值": 162022600.0,
        "市盈率": -1.34,
        "成交量": 1187271.0,
        "成交额": 1304824.0,
        "振幅": 9.65,
        "换手率": 0.78,
        "symbol": "105.BIRD"
    },
    {
        "序号": 11479,
        "name": "Sunrun Inc",
        "最新价": 12.89,
        "涨跌额": -0.98,
        "涨跌幅": -7.07,
        "开盘价": 13.72,
        "最高价": 14.03,
        "最低价": 12.64,
        "昨收价": 13.87,
        "总市值": 2808458995.0,
        "市盈率": -2.36,
        "成交量": 16527773.0,
        "成交额": 217327814.0,
        "振幅": 10.02,
        "换手率": 7.59,
        "symbol": "105.RUN"
    },
    {
        "序号": 11480,
        "name": "道森地探",
        "最新价": 1.44,
        "涨跌额": -0.11,
        "涨跌幅": -7.1,
        "开盘价": 1.6,
        "最高价": 1.6,
        "最低价": 1.38,
        "昨收价": 1.55,
        "总市值": 44369754.0,
        "市盈率": -3.04,
        "成交量": 6143.0,
        "成交额": 9153.0,
        "振幅": 14.19,
        "换手率": 0.02,
        "symbol": "105.DWSN"
    },
    {
        "序号": 11481,
        "name": "WiSA Technologies Inc",
        "最新价": 0.13,
        "涨跌额": -0.01,
        "涨跌幅": -7.14,
        "开盘价": 0.14,
        "最高价": 0.14,
        "最低价": 0.13,
        "昨收价": 0.14,
        "总市值": 3009749.0,
        "市盈率": -0.19,
        "成交量": 2096605.0,
        "成交额": 280966.0,
        "振幅": 7.14,
        "换手率": 9.06,
        "symbol": "105.WISA"
    },
    {
        "序号": 11482,
        "name": "INNOVATE Corp",
        "最新价": 1.17,
        "涨跌额": -0.09,
        "涨跌幅": -7.14,
        "开盘价": 1.26,
        "最高价": 1.26,
        "最低价": 1.16,
        "昨收价": 1.26,
        "总市值": 92704939.0,
        "市盈率": -2.93,
        "成交量": 73199.0,
        "成交额": 87318.0,
        "振幅": 7.94,
        "换手率": 0.09,
        "symbol": "106.VATE"
    },
    {
        "序号": 11483,
        "name": "NuScale Power Corp Wt",
        "最新价": 0.26,
        "涨跌额": -0.02,
        "涨跌幅": -7.14,
        "开盘价": 0.3,
        "最高价": 0.32,
        "最低价": 0.26,
        "昨收价": 0.28,
        "总市值": null,
        "市盈率": null,
        "成交量": 99607.0,
        "成交额": 28529.0,
        "振幅": 21.43,
        "换手率": null,
        "symbol": "106.SMR_WS"
    },
    {
        "序号": 11484,
        "name": "OppFi Inc Wt",
        "最新价": 0.13,
        "涨跌额": -0.01,
        "涨跌幅": -7.14,
        "开盘价": 0.15,
        "最高价": 0.17,
        "最低价": 0.13,
        "昨收价": 0.14,
        "总市值": null,
        "市盈率": null,
        "成交量": 26625.0,
        "成交额": 4008.0,
        "振幅": 28.57,
        "换手率": null,
        "symbol": "106.OPFI_WS"
    },
    {
        "序号": 11485,
        "name": "Galera Therapeutics Inc",
        "最新价": 0.13,
        "涨跌额": -0.01,
        "涨跌幅": -7.14,
        "开盘价": 0.14,
        "最高价": 0.14,
        "最低价": 0.13,
        "昨收价": 0.14,
        "总市值": 7070982.0,
        "市盈率": -0.1,
        "成交量": 643590.0,
        "成交额": 84526.0,
        "振幅": 7.14,
        "换手率": 1.18,
        "symbol": "105.GRTX"
    },
    {
        "序号": 11486,
        "name": "森淼科技",
        "最新价": 0.39,
        "涨跌额": -0.03,
        "涨跌幅": -7.14,
        "开盘价": 0.44,
        "最高价": 0.46,
        "最低价": 0.37,
        "昨收价": 0.42,
        "总市值": 3731536.0,
        "市盈率": -1.0,
        "成交量": 109348.0,
        "成交额": 45328.0,
        "振幅": 21.43,
        "换手率": 1.14,
        "symbol": "105.AIHS"
    },
    {
        "序号": 11487,
        "name": "元宇宙控股",
        "最新价": 1.16,
        "涨跌额": -0.09,
        "涨跌幅": -7.2,
        "开盘价": 1.19,
        "最高价": 1.22,
        "最低价": 1.07,
        "昨收价": 1.25,
        "总市值": 38336740.0,
        "市盈率": null,
        "成交量": 19490.0,
        "成交额": 22158.0,
        "振幅": 12.0,
        "换手率": 0.06,
        "symbol": "105.MMV"
    },
    {
        "序号": 11488,
        "name": "迈欧莫",
        "最新价": 3.98,
        "涨跌额": -0.31,
        "涨跌幅": -7.23,
        "开盘价": 4.15,
        "最高价": 4.46,
        "最低价": 3.93,
        "昨收价": 4.29,
        "总市值": 105534488.0,
        "市盈率": -13.44,
        "成交量": 803240.0,
        "成交额": 3326331.0,
        "振幅": 12.35,
        "换手率": 3.03,
        "symbol": "107.MYO"
    },
    {
        "序号": 11489,
        "name": "bioAffinity Technologies Inc",
        "最新价": 1.41,
        "涨跌额": -0.11,
        "涨跌幅": -7.24,
        "开盘价": 1.45,
        "最高价": 1.49,
        "最低价": 1.39,
        "昨收价": 1.52,
        "总市值": 13398163.0,
        "市盈率": -1.85,
        "成交量": 37210.0,
        "成交额": 52678.0,
        "振幅": 6.58,
        "换手率": 0.39,
        "symbol": "105.BIAF"
    },
    {
        "序号": 11490,
        "name": "Mesoblast Ltd ADR",
        "最新价": 1.02,
        "涨跌额": -0.08,
        "涨跌幅": -7.27,
        "开盘价": 1.05,
        "最高价": 1.06,
        "最低价": 0.98,
        "昨收价": 1.1,
        "总市值": 166097784.0,
        "市盈率": -2.03,
        "成交量": 409604.0,
        "成交额": 420779.0,
        "振幅": 7.27,
        "换手率": 0.25,
        "symbol": "105.MESO"
    },
    {
        "序号": 11491,
        "name": "iSpecimen Inc",
        "最新价": 0.51,
        "涨跌额": -0.04,
        "涨跌幅": -7.27,
        "开盘价": 0.53,
        "最高价": 0.55,
        "最低价": 0.51,
        "昨收价": 0.55,
        "总市值": 4628662.0,
        "市盈率": -0.45,
        "成交量": 60180.0,
        "成交额": 31594.0,
        "振幅": 7.27,
        "换手率": 0.66,
        "symbol": "105.ISPC"
    },
    {
        "序号": 11492,
        "name": "迪克希",
        "最新价": 0.89,
        "涨跌额": -0.07,
        "涨跌幅": -7.29,
        "开盘价": 0.9,
        "最高价": 0.98,
        "最低价": 0.89,
        "昨收价": 0.96,
        "总市值": 13836975.0,
        "市盈率": -0.57,
        "成交量": 35160.0,
        "成交额": 32765.0,
        "振幅": 9.38,
        "换手率": 0.23,
        "symbol": "105.DXYN"
    },
    {
        "序号": 11493,
        "name": "多尼斯",
        "最新价": 3.05,
        "涨跌额": -0.24,
        "涨跌幅": -7.29,
        "开盘价": 3.19,
        "最高价": 3.28,
        "最低价": 3.05,
        "昨收价": 3.29,
        "总市值": 32396377.0,
        "市盈率": -4.5,
        "成交量": 2282.0,
        "成交额": 7297.0,
        "振幅": 6.99,
        "换手率": 0.02,
        "symbol": "105.DOGZ"
    },
    {
        "序号": 11494,
        "name": "海银控股",
        "最新价": 6.16,
        "涨跌额": -0.49,
        "涨跌幅": -7.37,
        "开盘价": 6.46,
        "最高价": 6.46,
        "最低价": 5.87,
        "昨收价": 6.65,
        "总市值": 172480000.0,
        "市盈率": 9.6,
        "成交量": 8223.0,
        "成交额": 50111.0,
        "振幅": 8.87,
        "换手率": 0.03,
        "symbol": "105.HYW"
    },
    {
        "序号": 11495,
        "name": "Nortech Systems Inc",
        "最新价": 8.9,
        "涨跌额": -0.71,
        "涨跌幅": -7.39,
        "开盘价": 8.93,
        "最高价": 8.93,
        "最低价": 8.6,
        "昨收价": 9.61,
        "总市值": 24380455.0,
        "市盈率": 11.39,
        "成交量": 2978.0,
        "成交额": 26148.0,
        "振幅": 3.43,
        "换手率": 0.11,
        "symbol": "105.NSYS"
    },
    {
        "序号": 11496,
        "name": "团车",
        "最新价": 0.25,
        "涨跌额": -0.02,
        "涨跌幅": -7.41,
        "开盘价": 0.25,
        "最高价": 0.27,
        "最低价": 0.24,
        "昨收价": 0.27,
        "总市值": 6442750.0,
        "市盈率": -0.35,
        "成交量": 20395.0,
        "成交额": 5236.0,
        "振幅": 11.11,
        "换手率": 0.08,
        "symbol": "105.TC"
    },
    {
        "序号": 11497,
        "name": "Bionomics Ltd ADR",
        "最新价": 1.25,
        "涨跌额": -0.1,
        "涨跌幅": -7.41,
        "开盘价": 1.3,
        "最高价": 1.33,
        "最低价": 1.24,
        "昨收价": 1.35,
        "总市值": 13183544.0,
        "市盈率": -0.62,
        "成交量": 119403.0,
        "成交额": 152804.0,
        "振幅": 6.67,
        "换手率": 1.13,
        "symbol": "105.BNOX"
    },
    {
        "序号": 11498,
        "name": "艾吉纳斯",
        "最新价": 0.75,
        "涨跌额": -0.06,
        "涨跌幅": -7.41,
        "开盘价": 0.8,
        "最高价": 0.81,
        "最低价": 0.74,
        "昨收价": 0.81,
        "总市值": 286121603.0,
        "市盈率": -1.06,
        "成交量": 7171479.0,
        "成交额": 5621618.0,
        "振幅": 8.64,
        "换手率": 1.88,
        "symbol": "105.AGEN"
    },
    {
        "序号": 11499,
        "name": "Minerva Surgical Inc",
        "最新价": 2.11,
        "涨跌额": -0.17,
        "涨跌幅": -7.46,
        "开盘价": 2.28,
        "最高价": 2.28,
        "最低价": 1.91,
        "昨收价": 2.28,
        "总市值": 18733808.0,
        "市盈率": -0.56,
        "成交量": 27545.0,
        "成交额": 56863.0,
        "振幅": 16.23,
        "换手率": 0.31,
        "symbol": "105.UTRS"
    },
    {
        "序号": 11500,
        "name": "Presto Automation Inc",
        "最新价": 0.62,
        "涨跌额": -0.05,
        "涨跌幅": -7.46,
        "开盘价": 0.68,
        "最高价": 0.68,
        "最低价": 0.58,
        "昨收价": 0.67,
        "总市值": 41689265.0,
        "市盈率": -0.65,
        "成交量": 209871.0,
        "成交额": 130780.0,
        "振幅": 14.93,
        "换手率": 0.31,
        "symbol": "105.PRST"
    },
    {
        "序号": 11501,
        "name": "NeuroSense Therapeutics Ltd",
        "最新价": 0.74,
        "涨跌额": -0.06,
        "涨跌幅": -7.5,
        "开盘价": 0.76,
        "最高价": 0.78,
        "最低价": 0.7,
        "昨收价": 0.8,
        "总市值": 10112871.0,
        "市盈率": -0.88,
        "成交量": 350439.0,
        "成交额": 256902.0,
        "振幅": 10.0,
        "换手率": 2.56,
        "symbol": "105.NRSN"
    },
    {
        "序号": 11502,
        "name": "Desktop Metal Inc-A",
        "最新价": 0.74,
        "涨跌额": -0.06,
        "涨跌幅": -7.5,
        "开盘价": 0.8,
        "最高价": 0.82,
        "最低价": 0.74,
        "昨收价": 0.8,
        "总市值": 240304824.0,
        "市盈率": -0.52,
        "成交量": 3382058.0,
        "成交额": 2563635.0,
        "振幅": 10.0,
        "换手率": 1.04,
        "symbol": "106.DM"
    },
    {
        "序号": 11503,
        "name": "Aditxt Inc",
        "最新价": 3.43,
        "涨跌额": -0.28,
        "涨跌幅": -7.55,
        "开盘价": 3.57,
        "最高价": 3.75,
        "最低价": 3.42,
        "昨收价": 3.71,
        "总市值": 1515549.0,
        "市盈率": -0.05,
        "成交量": 191236.0,
        "成交额": 685693.0,
        "振幅": 8.89,
        "换手率": 43.28,
        "symbol": "105.ADTX"
    },
    {
        "序号": 11504,
        "name": "DoubleDown Interactive Co Ltd A",
        "最新价": 8.2,
        "涨跌额": -0.67,
        "涨跌幅": -7.55,
        "开盘价": 8.98,
        "最高价": 9.09,
        "最低价": 8.2,
        "昨收价": 8.87,
        "总市值": 406338208.0,
        "市盈率": -3.4,
        "成交量": 39445.0,
        "成交额": 344504.0,
        "振幅": 10.03,
        "换手率": 0.08,
        "symbol": "105.DDI"
    },
    {
        "序号": 11505,
        "name": "36氪",
        "最新价": 0.61,
        "涨跌额": -0.05,
        "涨跌幅": -7.58,
        "开盘价": 0.68,
        "最高价": 0.68,
        "最低价": 0.6,
        "昨收价": 0.66,
        "总市值": 23777031.0,
        "市盈率": -1.86,
        "成交量": 70897.0,
        "成交额": 44762.0,
        "振幅": 12.12,
        "换手率": 0.18,
        "symbol": "105.KRKR"
    },
    {
        "序号": 11506,
        "name": "Xilio Therapeutics Inc",
        "最新价": 0.85,
        "涨跌额": -0.07,
        "涨跌幅": -7.61,
        "开盘价": 0.92,
        "最高价": 0.94,
        "最低价": 0.85,
        "昨收价": 0.92,
        "总市值": 23410700.0,
        "市盈率": -0.29,
        "成交量": 30833.0,
        "成交额": 28001.0,
        "振幅": 9.78,
        "换手率": 0.11,
        "symbol": "105.XLO"
    },
    {
        "序号": 11507,
        "name": "SilverSun Technologies Inc",
        "最新价": 8.86,
        "涨跌额": -0.73,
        "涨跌幅": -7.61,
        "开盘价": 9.26,
        "最高价": 9.4,
        "最低价": 8.53,
        "昨收价": 9.59,
        "总市值": 46569728.0,
        "市盈率": -30.86,
        "成交量": 345925.0,
        "成交额": 3101850.0,
        "振幅": 9.07,
        "换手率": 6.58,
        "symbol": "105.SSNT"
    },
    {
        "序号": 11508,
        "name": "Siyata Mobile Inc",
        "最新价": 3.02,
        "涨跌额": -0.25,
        "涨跌幅": -7.65,
        "开盘价": 3.26,
        "最高价": 3.44,
        "最低价": 2.96,
        "昨收价": 3.27,
        "总市值": 1683602.0,
        "市盈率": -0.11,
        "成交量": 55769.0,
        "成交额": 174381.0,
        "振幅": 14.68,
        "换手率": 10.0,
        "symbol": "105.SYTA"
    },
    {
        "序号": 11509,
        "name": "TriSalus Life Sciences Inc Wt",
        "最新价": 0.24,
        "涨跌额": -0.02,
        "涨跌幅": -7.69,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.24,
        "昨收价": 0.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 273.0,
        "成交额": 65.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TLSIW"
    },
    {
        "序号": 11510,
        "name": "GeoVax Labs Inc",
        "最新价": 0.36,
        "涨跌额": -0.03,
        "涨跌幅": -7.69,
        "开盘价": 0.39,
        "最高价": 0.43,
        "最低价": 0.36,
        "昨收价": 0.39,
        "总市值": 9610303.0,
        "市盈率": -0.4,
        "成交量": 1533619.0,
        "成交额": 605126.0,
        "振幅": 17.95,
        "换手率": 5.74,
        "symbol": "105.GOVX"
    },
    {
        "序号": 11511,
        "name": "GlucoTrack Inc",
        "最新价": 0.24,
        "涨跌额": -0.02,
        "涨跌幅": -7.69,
        "开盘价": 0.25,
        "最高价": 0.25,
        "最低价": 0.24,
        "昨收价": 0.26,
        "总市值": 5014126.0,
        "市盈率": -0.84,
        "成交量": 98851.0,
        "成交额": 24035.0,
        "振幅": 3.85,
        "换手率": 0.47,
        "symbol": "105.GCTK"
    },
    {
        "序号": 11512,
        "name": "Atreca Inc-A",
        "最新价": 0.24,
        "涨跌额": -0.02,
        "涨跌幅": -7.69,
        "开盘价": 0.27,
        "最高价": 0.27,
        "最低价": 0.24,
        "昨收价": 0.26,
        "总市值": 9509778.0,
        "市盈率": -0.1,
        "成交量": 1086768.0,
        "成交额": 265945.0,
        "振幅": 11.54,
        "换手率": 2.74,
        "symbol": "105.BCEL"
    },
    {
        "序号": 11513,
        "name": "VYNE Therapeutics Inc",
        "最新价": 2.99,
        "涨跌额": -0.25,
        "涨跌幅": -7.72,
        "开盘价": 3.2,
        "最高价": 3.39,
        "最低价": 2.91,
        "昨收价": 3.24,
        "总市值": 41732399.0,
        "市盈率": -1.3,
        "成交量": 81795.0,
        "成交额": 253762.0,
        "振幅": 14.81,
        "换手率": 0.59,
        "symbol": "105.VYNE"
    },
    {
        "序号": 11514,
        "name": "ReNew Energy Global plc Wt",
        "最新价": 0.83,
        "涨跌额": -0.07,
        "涨跌幅": -7.78,
        "开盘价": 0.84,
        "最高价": 0.9,
        "最低价": 0.79,
        "昨收价": 0.9,
        "总市值": null,
        "市盈率": null,
        "成交量": 123097.0,
        "成交额": 101149.0,
        "振幅": 12.22,
        "换手率": null,
        "symbol": "105.RNWWW"
    },
    {
        "序号": 11515,
        "name": "Vision Marine Technologies Inc",
        "最新价": 1.42,
        "涨跌额": -0.12,
        "涨跌幅": -7.79,
        "开盘价": 1.51,
        "最高价": 1.55,
        "最低价": 1.34,
        "昨收价": 1.54,
        "总市值": 16542033.0,
        "市盈率": -1.06,
        "成交量": 53837.0,
        "成交额": 76495.0,
        "振幅": 13.64,
        "换手率": 0.46,
        "symbol": "105.VMAR"
    },
    {
        "序号": 11516,
        "name": "Acurx Pharmaceuticals Inc",
        "最新价": 4.0,
        "涨跌额": -0.34,
        "涨跌幅": -7.83,
        "开盘价": 4.37,
        "最高价": 4.66,
        "最低价": 3.93,
        "昨收价": 4.34,
        "总市值": 55071908.0,
        "市盈率": -4.33,
        "成交量": 345318.0,
        "成交额": 1476712.0,
        "振幅": 16.82,
        "换手率": 2.51,
        "symbol": "105.ACXP"
    },
    {
        "序号": 11517,
        "name": "Snow Lake Resources Ltd",
        "最新价": 0.47,
        "涨跌额": -0.04,
        "涨跌幅": -7.84,
        "开盘价": 0.49,
        "最高价": 0.5,
        "最低价": 0.46,
        "昨收价": 0.51,
        "总市值": 9550301.0,
        "市盈率": -0.82,
        "成交量": 98567.0,
        "成交额": 47833.0,
        "振幅": 7.84,
        "换手率": 0.49,
        "symbol": "105.LITM"
    },
    {
        "序号": 11518,
        "name": "Cosmos Health Inc",
        "最新价": 1.05,
        "涨跌额": -0.09,
        "涨跌幅": -7.89,
        "开盘价": 1.14,
        "最高价": 1.14,
        "最低价": 1.02,
        "昨收价": 1.14,
        "总市值": 14074414.0,
        "市盈率": -0.9,
        "成交量": 302069.0,
        "成交额": 321586.0,
        "振幅": 10.53,
        "换手率": 2.25,
        "symbol": "105.COSM"
    },
    {
        "序号": 11519,
        "name": "依特钠",
        "最新价": 2.2,
        "涨跌额": -0.19,
        "涨跌幅": -7.95,
        "开盘价": 2.29,
        "最高价": 2.42,
        "最低价": 2.17,
        "昨收价": 2.39,
        "总市值": 10682927.0,
        "市盈率": -0.47,
        "成交量": 21393.0,
        "成交额": 49000.0,
        "振幅": 10.46,
        "换手率": 0.44,
        "symbol": "105.AEZS"
    },
    {
        "序号": 11520,
        "name": "Harbor Custom Development Inc",
        "最新价": 0.81,
        "涨跌额": -0.07,
        "涨跌幅": -7.95,
        "开盘价": 0.93,
        "最高价": 0.93,
        "最低价": 0.8,
        "昨收价": 0.88,
        "总市值": 1886751.0,
        "市盈率": -0.05,
        "成交量": 274643.0,
        "成交额": 228000.0,
        "振幅": 14.77,
        "换手率": 11.79,
        "symbol": "105.HCDI"
    },
    {
        "序号": 11521,
        "name": "宝盛",
        "最新价": 4.05,
        "涨跌额": -0.35,
        "涨跌幅": -7.95,
        "开盘价": 4.25,
        "最高价": 4.4,
        "最低价": 3.91,
        "昨收价": 4.4,
        "总市值": 6214672.0,
        "市盈率": -0.36,
        "成交量": 9765.0,
        "成交额": 40239.0,
        "振幅": 11.14,
        "换手率": 0.64,
        "symbol": "105.BAOS"
    },
    {
        "序号": 11522,
        "name": "Innovative International Acquis",
        "最新价": 8.0,
        "涨跌额": -0.7,
        "涨跌幅": -8.05,
        "开盘价": 8.72,
        "最高价": 8.72,
        "最低价": 8.0,
        "昨收价": 8.7,
        "总市值": 92563368.0,
        "市盈率": -21.23,
        "成交量": 7492.0,
        "成交额": 60617.0,
        "振幅": 8.28,
        "换手率": 0.06,
        "symbol": "105.IOAC"
    },
    {
        "序号": 11523,
        "name": "CRISPR Therapeutics AG",
        "最新价": 64.54,
        "涨跌额": -5.67,
        "涨跌幅": -8.08,
        "开盘价": 71.07,
        "最高价": 76.97,
        "最低价": 61.55,
        "昨收价": 70.21,
        "总市值": 5126570065.0,
        "市盈率": -14.5,
        "成交量": 17230195.0,
        "成交额": 1166092688.0,
        "振幅": 21.96,
        "换手率": 21.69,
        "symbol": "105.CRSP"
    },
    {
        "序号": 11524,
        "name": "Frontier Investment Corp-A",
        "最新价": 11.0,
        "涨跌额": -0.97,
        "涨跌幅": -8.1,
        "开盘价": 11.99,
        "最高价": 13.0,
        "最低价": 10.99,
        "昨收价": 11.97,
        "总市值": 85070656.0,
        "市盈率": 17.24,
        "成交量": 5593.0,
        "成交额": 66044.0,
        "振幅": 16.79,
        "换手率": 0.07,
        "symbol": "105.FICV"
    },
    {
        "序号": 11525,
        "name": "CSP Inc",
        "最新价": 22.5,
        "涨跌额": -1.99,
        "涨跌幅": -8.13,
        "开盘价": 26.62,
        "最高价": 26.62,
        "最低价": 22.01,
        "昨收价": 24.49,
        "总市值": 106370393.0,
        "市盈率": 20.41,
        "成交量": 29091.0,
        "成交额": 675218.0,
        "振幅": 18.82,
        "换手率": 0.62,
        "symbol": "105.CSPI"
    },
    {
        "序号": 11526,
        "name": "复朗集团",
        "最新价": 3.05,
        "涨跌额": -0.27,
        "涨跌幅": -8.13,
        "开盘价": 3.38,
        "最高价": 3.57,
        "最低价": 2.97,
        "昨收价": 3.32,
        "总市值": 399461764.0,
        "市盈率": -1.28,
        "成交量": 104028.0,
        "成交额": 329539.0,
        "振幅": 18.07,
        "换手率": 0.08,
        "symbol": "106.LANV"
    },
    {
        "序号": 11527,
        "name": "Beam Therapeutics Inc",
        "最新价": 26.4,
        "涨跌额": -2.35,
        "涨跌幅": -8.17,
        "开盘价": 27.49,
        "最高价": 28.79,
        "最低价": 26.29,
        "昨收价": 28.75,
        "总市值": 2151707105.0,
        "市盈率": -6.86,
        "成交量": 1780125.0,
        "成交额": 47941087.0,
        "振幅": 8.7,
        "换手率": 2.18,
        "symbol": "105.BEAM"
    },
    {
        "序号": 11528,
        "name": "SIMPPLE LTD",
        "最新价": 6.28,
        "涨跌额": -0.56,
        "涨跌幅": -8.19,
        "开盘价": 6.84,
        "最高价": 6.96,
        "最低价": 6.16,
        "昨收价": 6.84,
        "总市值": 101899035.0,
        "市盈率": -173.87,
        "成交量": 29682.0,
        "成交额": 189401.0,
        "振幅": 11.7,
        "换手率": 0.18,
        "symbol": "105.SPPL"
    },
    {
        "序号": 11529,
        "name": "Calidi Biotherapeutics Inc",
        "最新价": 2.13,
        "涨跌额": -0.19,
        "涨跌幅": -8.19,
        "开盘价": 2.34,
        "最高价": 2.63,
        "最低价": 2.13,
        "昨收价": 2.32,
        "总市值": 75479492.0,
        "市盈率": 352.4,
        "成交量": 88489.0,
        "成交额": 204116.0,
        "振幅": 21.55,
        "换手率": 0.25,
        "symbol": "107.CLDI"
    },
    {
        "序号": 11530,
        "name": "Semantix Inc-A",
        "最新价": 0.78,
        "涨跌额": -0.07,
        "涨跌幅": -8.24,
        "开盘价": 0.82,
        "最高价": 0.86,
        "最低价": 0.78,
        "昨收价": 0.85,
        "总市值": 61186331.0,
        "市盈率": -2.02,
        "成交量": 10841.0,
        "成交额": 8725.0,
        "振幅": 9.41,
        "换手率": 0.01,
        "symbol": "105.STIX"
    },
    {
        "序号": 11531,
        "name": "Organovo Holdings Inc",
        "最新价": 1.11,
        "涨跌额": -0.1,
        "涨跌幅": -8.26,
        "开盘价": 1.17,
        "最高价": 1.22,
        "最低价": 1.1,
        "昨收价": 1.21,
        "总市值": 9809175.0,
        "市盈率": -0.52,
        "成交量": 304210.0,
        "成交额": 347296.0,
        "振幅": 9.92,
        "换手率": 3.44,
        "symbol": "105.ONVO"
    },
    {
        "序号": 11532,
        "name": "Volcon Inc",
        "最新价": 0.11,
        "涨跌额": -0.01,
        "涨跌幅": -8.33,
        "开盘价": 0.13,
        "最高价": 0.13,
        "最低价": 0.1,
        "昨收价": 0.12,
        "总市值": 1115985.0,
        "市盈率": -0.02,
        "成交量": 6401821.0,
        "成交额": 718961.0,
        "振幅": 25.0,
        "换手率": 63.1,
        "symbol": "105.VLCN"
    },
    {
        "序号": 11533,
        "name": "Theriva Biologics Inc",
        "最新价": 0.55,
        "涨跌额": -0.05,
        "涨跌幅": -8.33,
        "开盘价": 0.6,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.6,
        "总市值": 9373521.0,
        "市盈率": -0.49,
        "成交量": 37543.0,
        "成交额": 21953.0,
        "振幅": 8.33,
        "换手率": 0.22,
        "symbol": "107.TOVX"
    },
    {
        "序号": 11534,
        "name": "ProSomnus Inc",
        "最新价": 0.55,
        "涨跌额": -0.05,
        "涨跌幅": -8.33,
        "开盘价": 0.59,
        "最高价": 0.6,
        "最低价": 0.55,
        "昨收价": 0.6,
        "总市值": 9019229.0,
        "市盈率": -0.37,
        "成交量": 66803.0,
        "成交额": 37855.0,
        "振幅": 8.33,
        "换手率": 0.41,
        "symbol": "105.OSA"
    },
    {
        "序号": 11535,
        "name": "Ryvyl Inc",
        "最新价": 2.96,
        "涨跌额": -0.27,
        "涨跌幅": -8.36,
        "开盘价": 3.27,
        "最高价": 3.27,
        "最低价": 2.81,
        "昨收价": 3.23,
        "总市值": 15942977.0,
        "市盈率": -0.41,
        "成交量": 27898.0,
        "成交额": 84223.0,
        "振幅": 14.24,
        "换手率": 0.52,
        "symbol": "105.RVYL"
    },
    {
        "序号": 11536,
        "name": "苏轩堂",
        "最新价": 1.96,
        "涨跌额": -0.18,
        "涨跌幅": -8.41,
        "开盘价": 2.14,
        "最高价": 2.2,
        "最低价": 1.9,
        "昨收价": 2.14,
        "总市值": 1568000.0,
        "市盈率": -0.26,
        "成交量": 125564.0,
        "成交额": 263300.0,
        "振幅": 14.02,
        "换手率": 15.7,
        "symbol": "105.SXTC"
    },
    {
        "序号": 11537,
        "name": "Nabors Energy Transition Corp-A",
        "最新价": 10.04,
        "涨跌额": -0.93,
        "涨跌幅": -8.48,
        "开盘价": 10.85,
        "最高价": 10.85,
        "最低价": 9.06,
        "昨收价": 10.97,
        "总市值": 168176436.0,
        "市盈率": -894.89,
        "成交量": 68270.0,
        "成交额": 671962.0,
        "振幅": 16.32,
        "换手率": 0.41,
        "symbol": "106.NETC"
    },
    {
        "序号": 11538,
        "name": "TC BioPharm (Holdings) plc ADR",
        "最新价": 0.32,
        "涨跌额": -0.03,
        "涨跌幅": -8.57,
        "开盘价": 0.36,
        "最高价": 0.36,
        "最低价": 0.32,
        "昨收价": 0.35,
        "总市值": 2996348.0,
        "市盈率": -1.61,
        "成交量": 567907.0,
        "成交额": 190358.0,
        "振幅": 11.43,
        "换手率": 6.07,
        "symbol": "105.TCBP"
    },
    {
        "序号": 11539,
        "name": "九洲大药房",
        "最新价": 0.21,
        "涨跌额": -0.02,
        "涨跌幅": -8.7,
        "开盘价": 0.23,
        "最高价": 0.23,
        "最低价": 0.2,
        "昨收价": 0.23,
        "总市值": 7068014.0,
        "市盈率": -0.33,
        "成交量": 207052.0,
        "成交额": 44055.0,
        "振幅": 13.04,
        "换手率": 0.62,
        "symbol": "105.CJJD"
    },
    {
        "序号": 11540,
        "name": "Cibus Inc",
        "最新价": 11.48,
        "涨跌额": -1.1,
        "涨跌幅": -8.74,
        "开盘价": 12.2,
        "最高价": 12.2,
        "最低价": 11.48,
        "昨收价": 12.58,
        "总市值": 245012864.0,
        "市盈率": -4.59,
        "成交量": 52772.0,
        "成交额": 618294.0,
        "振幅": 5.72,
        "换手率": 0.25,
        "symbol": "105.CBUS"
    },
    {
        "序号": 11541,
        "name": "NuZee Inc",
        "最新价": 2.4,
        "涨跌额": -0.23,
        "涨跌幅": -8.75,
        "开盘价": 2.63,
        "最高价": 2.75,
        "最低价": 2.32,
        "昨收价": 2.63,
        "总市值": 2898574.0,
        "市盈率": -0.31,
        "成交量": 70850.0,
        "成交额": 175295.0,
        "振幅": 16.35,
        "换手率": 5.87,
        "symbol": "105.NUZE"
    },
    {
        "序号": 11542,
        "name": "eFFECTOR Therapeutics Inc",
        "最新价": 0.52,
        "涨跌额": -0.05,
        "涨跌幅": -8.77,
        "开盘价": 0.58,
        "最高价": 0.6,
        "最低价": 0.51,
        "昨收价": 0.57,
        "总市值": 33603710.0,
        "市盈率": -0.93,
        "成交量": 14199461.0,
        "成交额": 7872281.0,
        "振幅": 15.79,
        "换手率": 21.97,
        "symbol": "105.EFTR"
    },
    {
        "序号": 11543,
        "name": "SportsMap Tech Acquisition Corp",
        "最新价": 10.98,
        "涨跌额": -1.07,
        "涨跌幅": -8.88,
        "开盘价": 11.51,
        "最高价": 13.69,
        "最低价": 10.45,
        "昨收价": 12.05,
        "总市值": 56930685.0,
        "市盈率": -46.94,
        "成交量": 21209.0,
        "成交额": 236925.0,
        "振幅": 26.89,
        "换手率": 0.41,
        "symbol": "105.SMAP"
    },
    {
        "序号": 11544,
        "name": "ATA Creativity Global ADR",
        "最新价": 0.81,
        "涨跌额": -0.08,
        "涨跌幅": -8.99,
        "开盘价": 0.89,
        "最高价": 0.89,
        "最低价": 0.8,
        "昨收价": 0.89,
        "总市值": 25435138.0,
        "市盈率": -4.44,
        "成交量": 20894.0,
        "成交额": 17222.0,
        "振幅": 10.11,
        "换手率": 0.07,
        "symbol": "105.AACG"
    },
    {
        "序号": 11545,
        "name": "KalVista Pharmaceuticals Inc",
        "最新价": 8.16,
        "涨跌额": -0.81,
        "涨跌幅": -9.03,
        "开盘价": 8.9,
        "最高价": 8.9,
        "最低价": 7.92,
        "昨收价": 8.97,
        "总市值": 281999065.0,
        "市盈率": -2.8,
        "成交量": 380886.0,
        "成交额": 3136889.0,
        "振幅": 10.93,
        "换手率": 1.1,
        "symbol": "105.KALV"
    },
    {
        "序号": 11546,
        "name": "Smith & Wesson Brands Inc",
        "最新价": 12.61,
        "涨跌额": -1.26,
        "涨跌幅": -9.08,
        "开盘价": 12.89,
        "最高价": 13.89,
        "最低价": 12.48,
        "昨收价": 13.87,
        "总市值": 575512216.0,
        "市盈率": 19.49,
        "成交量": 2883148.0,
        "成交额": 36869920.0,
        "振幅": 10.17,
        "换手率": 6.32,
        "symbol": "105.SWBI"
    },
    {
        "序号": 11547,
        "name": "Windtree Therapeutics Inc",
        "最新价": 0.8,
        "涨跌额": -0.08,
        "涨跌幅": -9.09,
        "开盘价": 0.9,
        "最高价": 0.92,
        "最低价": 0.75,
        "昨收价": 0.88,
        "总市值": 4118575.0,
        "市盈率": -0.17,
        "成交量": 220404.0,
        "成交额": 180055.0,
        "振幅": 19.32,
        "换手率": 4.28,
        "symbol": "105.WINT"
    },
    {
        "序号": 11548,
        "name": "Plum Acquisition Corp I Wt",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 101.0,
        "成交额": 10.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PLMIW"
    },
    {
        "序号": 11549,
        "name": "Lucy Scientific Discovery Inc",
        "最新价": 0.3,
        "涨跌额": -0.03,
        "涨跌幅": -9.09,
        "开盘价": 0.31,
        "最高价": 0.34,
        "最低价": 0.3,
        "昨收价": 0.33,
        "总市值": 5293889.0,
        "市盈率": -0.55,
        "成交量": 152429.0,
        "成交额": 48144.0,
        "振幅": 12.12,
        "换手率": 0.86,
        "symbol": "105.LSDI"
    },
    {
        "序号": 11550,
        "name": "Terran Orbita Corp Wt",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.12,
        "最高价": 0.12,
        "最低价": 0.09,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 228433.0,
        "成交额": 22615.0,
        "振幅": 27.27,
        "换手率": null,
        "symbol": "106.LLAP_WS"
    },
    {
        "序号": 11551,
        "name": "Pasithea Therapeutics Corp",
        "最新价": 0.3,
        "涨跌额": -0.03,
        "涨跌幅": -9.09,
        "开盘价": 0.31,
        "最高价": 0.33,
        "最低价": 0.3,
        "昨收价": 0.33,
        "总市值": 6250987.0,
        "市盈率": -0.39,
        "成交量": 8334.0,
        "成交额": 2625.0,
        "振幅": 9.09,
        "换手率": 0.04,
        "symbol": "105.KTTA"
    },
    {
        "序号": 11552,
        "name": "极光",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.11,
        "最高价": 0.11,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 11969428.0,
        "市盈率": -1.07,
        "成交量": 458594.0,
        "成交额": 48127.0,
        "振幅": 9.09,
        "换手率": 0.38,
        "symbol": "105.JG"
    },
    {
        "序号": 11553,
        "name": "ESS Tech Inc Wt",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 7247.0,
        "成交额": 704.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.GWH_WS"
    },
    {
        "序号": 11554,
        "name": "易电行",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.12,
        "最高价": 0.12,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": 7607246.0,
        "市盈率": -0.85,
        "成交量": 4120457.0,
        "成交额": 437477.0,
        "振幅": 18.18,
        "换手率": 5.42,
        "symbol": "105.EZGO"
    },
    {
        "序号": 11555,
        "name": "车车(权证)",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.06,
        "最高价": 0.1,
        "最低价": 0.06,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 962.0,
        "成交额": 65.0,
        "振幅": 36.36,
        "换手率": null,
        "symbol": "105.CCGWW"
    },
    {
        "序号": 11556,
        "name": "Bitcoin Depot Inc Wt",
        "最新价": 0.1,
        "涨跌额": -0.01,
        "涨跌幅": -9.09,
        "开盘价": 0.11,
        "最高价": 0.12,
        "最低价": 0.1,
        "昨收价": 0.11,
        "总市值": null,
        "市盈率": null,
        "成交量": 291133.0,
        "成交额": 29331.0,
        "振幅": 18.18,
        "换手率": null,
        "symbol": "105.BTMWW"
    },
    {
        "序号": 11557,
        "name": "Brainstorm Cell Therapeutics In",
        "最新价": 0.2,
        "涨跌额": -0.02,
        "涨跌幅": -9.09,
        "开盘价": 0.22,
        "最高价": 0.23,
        "最低价": 0.2,
        "昨收价": 0.22,
        "总市值": 9796318.0,
        "市盈率": -0.59,
        "成交量": 3180978.0,
        "成交额": 645712.0,
        "振幅": 13.64,
        "换手率": 6.49,
        "symbol": "105.BCLI"
    },
    {
        "序号": 11558,
        "name": "SunLink Health Systems Inc",
        "最新价": 0.89,
        "涨跌额": -0.09,
        "涨跌幅": -9.18,
        "开盘价": 0.85,
        "最高价": 0.92,
        "最低价": 0.81,
        "昨收价": 0.98,
        "总市值": 6266137.0,
        "市盈率": -3.96,
        "成交量": 97657.0,
        "成交额": 83847.0,
        "振幅": 11.22,
        "换手率": 1.39,
        "symbol": "107.SSY"
    },
    {
        "序号": 11559,
        "name": "AgriFORCE Growing Systems Ltd",
        "最新价": 0.59,
        "涨跌额": -0.06,
        "涨跌幅": -9.23,
        "开盘价": 0.66,
        "最高价": 0.67,
        "最低价": 0.55,
        "昨收价": 0.65,
        "总市值": 833971.0,
        "市盈率": -0.08,
        "成交量": 479245.0,
        "成交额": 292108.0,
        "振幅": 18.46,
        "换手率": 33.9,
        "symbol": "105.AGRI"
    },
    {
        "序号": 11560,
        "name": "HNR Acquisition Corp-A",
        "最新价": 1.74,
        "涨跌额": -0.18,
        "涨跌幅": -9.38,
        "开盘价": 2.0,
        "最高价": 2.1,
        "最低价": 1.74,
        "昨收价": 1.92,
        "总市值": 12000796.0,
        "市盈率": -36.85,
        "成交量": 54653.0,
        "成交额": 103974.0,
        "振幅": 18.75,
        "换手率": 0.79,
        "symbol": "107.HNRA"
    },
    {
        "序号": 11561,
        "name": "Dare Bioscience Inc",
        "最新价": 0.29,
        "涨跌额": -0.03,
        "涨跌幅": -9.38,
        "开盘价": 0.32,
        "最高价": 0.33,
        "最低价": 0.27,
        "昨收价": 0.32,
        "总市值": 28583080.0,
        "市盈率": -0.7,
        "成交量": 5025538.0,
        "成交额": 1443263.0,
        "振幅": 18.75,
        "换手率": 5.1,
        "symbol": "105.DARE"
    },
    {
        "序号": 11562,
        "name": "Vaxxinity Inc-A",
        "最新价": 0.77,
        "涨跌额": -0.08,
        "涨跌幅": -9.41,
        "开盘价": 0.84,
        "最高价": 0.87,
        "最低价": 0.75,
        "昨收价": 0.85,
        "总市值": 97594361.0,
        "市盈率": -1.48,
        "成交量": 570422.0,
        "成交额": 445694.0,
        "振幅": 14.12,
        "换手率": 0.45,
        "symbol": "105.VAXX"
    },
    {
        "序号": 11563,
        "name": "BioXcel Therapeutics Inc",
        "最新价": 3.17,
        "涨跌额": -0.33,
        "涨跌幅": -9.43,
        "开盘价": 3.52,
        "最高价": 3.52,
        "最低价": 3.15,
        "昨收价": 3.5,
        "总市值": 92794367.0,
        "市盈率": -0.44,
        "成交量": 905427.0,
        "成交额": 3000397.0,
        "振幅": 10.57,
        "换手率": 3.09,
        "symbol": "105.BTAI"
    },
    {
        "序号": 11564,
        "name": "iSun Inc",
        "最新价": 0.19,
        "涨跌额": -0.02,
        "涨跌幅": -9.52,
        "开盘价": 0.2,
        "最高价": 0.21,
        "最低价": 0.19,
        "昨收价": 0.21,
        "总市值": 8317914.0,
        "市盈率": -0.17,
        "成交量": 1705705.0,
        "成交额": 330663.0,
        "振幅": 9.52,
        "换手率": 3.9,
        "symbol": "105.ISUN"
    },
    {
        "序号": 11565,
        "name": "U.S. GoldMining Inc Wt",
        "最新价": 2.17,
        "涨跌额": -0.23,
        "涨跌幅": -9.58,
        "开盘价": 2.44,
        "最高价": 2.44,
        "最低价": 2.17,
        "昨收价": 2.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 2342.0,
        "成交额": 5494.0,
        "振幅": 11.25,
        "换手率": null,
        "symbol": "105.USGOW"
    },
    {
        "序号": 11566,
        "name": "EyePoint Pharmaceuticals Inc",
        "最新价": 19.15,
        "涨跌额": -2.03,
        "涨跌幅": -9.58,
        "开盘价": 20.32,
        "最高价": 21.28,
        "最低价": 18.49,
        "昨收价": 21.18,
        "总市值": 901469743.0,
        "市盈率": -9.0,
        "成交量": 2410514.0,
        "成交额": 47031653.0,
        "振幅": 13.17,
        "换手率": 5.12,
        "symbol": "105.EYPT"
    },
    {
        "序号": 11567,
        "name": "SOBR Safe Inc",
        "最新价": 0.56,
        "涨跌额": -0.06,
        "涨跌幅": -9.68,
        "开盘价": 0.63,
        "最高价": 0.63,
        "最低价": 0.52,
        "昨收价": 0.62,
        "总市值": 10384959.0,
        "市盈率": -0.93,
        "成交量": 46435.0,
        "成交额": 26767.0,
        "振幅": 17.74,
        "换手率": 0.25,
        "symbol": "105.SOBR"
    },
    {
        "序号": 11568,
        "name": "Immuneering Corp-A",
        "最新价": 7.37,
        "涨跌额": -0.8,
        "涨跌幅": -9.79,
        "开盘价": 7.95,
        "最高价": 8.12,
        "最低价": 7.25,
        "昨收价": 8.17,
        "总市值": 215713422.0,
        "市盈率": -4.18,
        "成交量": 71549.0,
        "成交额": 531536.0,
        "振幅": 10.65,
        "换手率": 0.24,
        "symbol": "105.IMRX"
    },
    {
        "序号": 11569,
        "name": "碳博士控股",
        "最新价": 2.11,
        "涨跌额": -0.23,
        "涨跌幅": -9.83,
        "开盘价": 2.33,
        "最高价": 2.33,
        "最低价": 2.1,
        "昨收价": 2.34,
        "总市值": 8011858.0,
        "市盈率": 2.73,
        "成交量": 12148.0,
        "成交额": 26630.0,
        "振幅": 9.83,
        "换手率": 0.32,
        "symbol": "105.TANH"
    },
    {
        "序号": 11570,
        "name": "The Arena Group Holdings Inc",
        "最新价": 2.84,
        "涨跌额": -0.31,
        "涨跌幅": -9.84,
        "开盘价": 3.08,
        "最高价": 3.13,
        "最低价": 2.77,
        "昨收价": 3.15,
        "总市值": 67691090.0,
        "市盈率": -1.06,
        "成交量": 26803.0,
        "成交额": 78497.0,
        "振幅": 11.43,
        "换手率": 0.11,
        "symbol": "107.AREN"
    },
    {
        "序号": 11571,
        "name": "约翰逊户外",
        "最新价": 49.1,
        "涨跌额": -5.37,
        "涨跌幅": -9.86,
        "开盘价": 48.26,
        "最高价": 50.2,
        "最低价": 47.0,
        "昨收价": 54.47,
        "总市值": 503311039.0,
        "市盈率": 11.14,
        "成交量": 150100.0,
        "成交额": 7239617.0,
        "振幅": 5.87,
        "换手率": 1.46,
        "symbol": "105.JOUT"
    },
    {
        "序号": 11572,
        "name": "Cartesian Therapeutics Inc",
        "最新价": 0.73,
        "涨跌额": -0.08,
        "涨跌幅": -9.88,
        "开盘价": 0.8,
        "最高价": 0.83,
        "最低价": 0.72,
        "昨收价": 0.81,
        "总市值": 113299053.0,
        "市盈率": -3.13,
        "成交量": 566043.0,
        "成交额": 419620.0,
        "振幅": 13.58,
        "换手率": 0.36,
        "symbol": "105.RNAC"
    },
    {
        "序号": 11573,
        "name": "BT Brands Inc",
        "最新价": 1.46,
        "涨跌额": -0.16,
        "涨跌幅": -9.88,
        "开盘价": 1.5,
        "最高价": 1.5,
        "最低价": 1.46,
        "昨收价": 1.62,
        "总市值": 9119332.0,
        "市盈率": -12.72,
        "成交量": 809.0,
        "成交额": 1197.0,
        "振幅": 2.47,
        "换手率": 0.01,
        "symbol": "105.BTBD"
    },
    {
        "序号": 11574,
        "name": "Tenax Therapeutics Inc",
        "最新价": 0.27,
        "涨跌额": -0.03,
        "涨跌幅": -10.0,
        "开盘价": 0.29,
        "最高价": 0.29,
        "最低价": 0.26,
        "昨收价": 0.3,
        "总市值": 6442857.0,
        "市盈率": -0.92,
        "成交量": 1110419.0,
        "成交额": 302983.0,
        "振幅": 10.0,
        "换手率": 4.65,
        "symbol": "105.TENX"
    },
    {
        "序号": 11575,
        "name": "Sonoma Pharmaceuticals Inc",
        "最新价": 0.18,
        "涨跌额": -0.02,
        "涨跌幅": -10.0,
        "开盘价": 0.2,
        "最高价": 0.21,
        "最低价": 0.18,
        "昨收价": 0.2,
        "总市值": 2462280.0,
        "市盈率": -0.4,
        "成交量": 1030982.0,
        "成交额": 195133.0,
        "振幅": 15.0,
        "换手率": 7.54,
        "symbol": "105.SNOA"
    },
    {
        "序号": 11576,
        "name": "Procaps Group SA Wt",
        "最新价": 0.09,
        "涨跌额": -0.01,
        "涨跌幅": -10.0,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.09,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 6221.0,
        "成交额": 560.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PROCW"
    },
    {
        "序号": 11577,
        "name": "Mountain Crest Acquisition Corp",
        "最新价": 0.18,
        "涨跌额": -0.02,
        "涨跌幅": -10.0,
        "开盘价": 0.18,
        "最高价": 0.19,
        "最低价": 0.18,
        "昨收价": 0.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 1910.0,
        "成交额": 349.0,
        "振幅": 5.0,
        "换手率": null,
        "symbol": "105.MCAFR"
    },
    {
        "序号": 11578,
        "name": "复朗集团(权证)",
        "最新价": 0.09,
        "涨跌额": -0.01,
        "涨跌幅": -10.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 39346.0,
        "成交额": 3579.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "106.LANV_WS"
    },
    {
        "序号": 11579,
        "name": "PARTS iD Inc-A",
        "最新价": 0.09,
        "涨跌额": -0.01,
        "涨跌幅": -10.0,
        "开盘价": 0.09,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.1,
        "总市值": 3863930.0,
        "市盈率": -0.17,
        "成交量": 1249066.0,
        "成交额": 114983.0,
        "振幅": 20.0,
        "换手率": 2.91,
        "symbol": "107.ID"
    },
    {
        "序号": 11580,
        "name": "Galmed Pharmaceuticals Ltd",
        "最新价": 0.36,
        "涨跌额": -0.04,
        "涨跌幅": -10.0,
        "开盘价": 0.41,
        "最高价": 0.41,
        "最低价": 0.33,
        "昨收价": 0.4,
        "总市值": 1442460.0,
        "市盈率": -0.19,
        "成交量": 213767.0,
        "成交额": 78596.0,
        "振幅": 20.0,
        "换手率": 5.34,
        "symbol": "105.GLMD"
    },
    {
        "序号": 11581,
        "name": "中北能",
        "最新价": 0.09,
        "涨跌额": -0.01,
        "涨跌幅": -10.0,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.09,
        "昨收价": 0.1,
        "总市值": 5353816.0,
        "市盈率": -1.82,
        "成交量": 293452.0,
        "成交额": 27250.0,
        "振幅": 10.0,
        "换手率": 0.49,
        "symbol": "105.CNEY"
    },
    {
        "序号": 11582,
        "name": "Better Choice Co Inc",
        "最新价": 0.27,
        "涨跌额": -0.03,
        "涨跌幅": -10.0,
        "开盘价": 0.28,
        "最高价": 0.3,
        "最低价": 0.26,
        "昨收价": 0.3,
        "总市值": 8661910.0,
        "市盈率": -0.27,
        "成交量": 291264.0,
        "成交额": 79519.0,
        "振幅": 13.33,
        "换手率": 0.91,
        "symbol": "107.BTTR"
    },
    {
        "序号": 11583,
        "name": "Polished.com Inc",
        "最新价": 1.16,
        "涨跌额": -0.13,
        "涨跌幅": -10.08,
        "开盘价": 1.25,
        "最高价": 1.33,
        "最低价": 1.13,
        "昨收价": 1.29,
        "总市值": 2446843.0,
        "市盈率": -0.02,
        "成交量": 195685.0,
        "成交额": 239150.0,
        "振幅": 15.5,
        "换手率": 9.28,
        "symbol": "107.POL"
    },
    {
        "序号": 11584,
        "name": "MSP Recovery Inc-A",
        "最新价": 2.93,
        "涨跌额": -0.33,
        "涨跌幅": -10.12,
        "开盘价": 3.18,
        "最高价": 3.3,
        "最低价": 2.91,
        "昨收价": 3.26,
        "总市值": 406030434.0,
        "市盈率": -11.17,
        "成交量": 425312.0,
        "成交额": 1316228.0,
        "振幅": 11.96,
        "换手率": 0.31,
        "symbol": "105.LIFW"
    },
    {
        "序号": 11585,
        "name": "Milestone Scientific Inc",
        "最新价": 0.71,
        "涨跌额": -0.08,
        "涨跌幅": -10.13,
        "开盘价": 0.82,
        "最高价": 0.82,
        "最低价": 0.7,
        "昨收价": 0.79,
        "总市值": 50444251.0,
        "市盈率": -7.24,
        "成交量": 494726.0,
        "成交额": 358584.0,
        "振幅": 15.19,
        "换手率": 0.7,
        "symbol": "107.MLSS"
    },
    {
        "序号": 11586,
        "name": "Sagimet Biosciences Inc-A",
        "最新价": 3.63,
        "涨跌额": -0.41,
        "涨跌幅": -10.15,
        "开盘价": 4.04,
        "最高价": 4.04,
        "最低价": 3.55,
        "昨收价": 4.04,
        "总市值": 83112088.0,
        "市盈率": -3.09,
        "成交量": 81090.0,
        "成交额": 304278.0,
        "振幅": 12.13,
        "换手率": 0.35,
        "symbol": "105.SGMT"
    },
    {
        "序号": 11587,
        "name": "Dianthus Therapeutics Inc",
        "最新价": 9.5,
        "涨跌额": -1.09,
        "涨跌幅": -10.29,
        "开盘价": 10.5,
        "最高价": 10.5,
        "最低价": 9.36,
        "昨收价": 10.59,
        "总市值": 140768739.0,
        "市盈率": -1.55,
        "成交量": 14875.0,
        "成交额": 145984.0,
        "振幅": 10.76,
        "换手率": 0.1,
        "symbol": "105.DNTH"
    },
    {
        "序号": 11588,
        "name": "Adit EdTech Acquisition Corp",
        "最新价": 10.0,
        "涨跌额": -1.15,
        "涨跌幅": -10.31,
        "开盘价": 10.43,
        "最高价": 10.96,
        "最低价": 10.0,
        "昨收价": 11.15,
        "总市值": 89000260.0,
        "市盈率": -54.09,
        "成交量": 5107.0,
        "成交额": 52585.0,
        "振幅": 8.61,
        "换手率": 0.06,
        "symbol": "107.ADEX"
    },
    {
        "序号": 11589,
        "name": "金太阳教育",
        "最新价": 0.52,
        "涨跌额": -0.06,
        "涨跌幅": -10.34,
        "开盘价": 0.56,
        "最高价": 0.58,
        "最低价": 0.5,
        "昨收价": 0.58,
        "总市值": 9924455.0,
        "市盈率": -1.61,
        "成交量": 47736.0,
        "成交额": 25903.0,
        "振幅": 13.79,
        "换手率": 0.25,
        "symbol": "105.GSUN"
    },
    {
        "序号": 11590,
        "name": "PaxMedica Inc",
        "最新价": 0.69,
        "涨跌额": -0.08,
        "涨跌幅": -10.39,
        "开盘价": 0.75,
        "最高价": 0.76,
        "最低价": 0.68,
        "昨收价": 0.77,
        "总市值": 3125713.0,
        "市盈率": -0.18,
        "成交量": 484265.0,
        "成交额": 347047.0,
        "振幅": 10.39,
        "换手率": 10.69,
        "symbol": "105.PXMD"
    },
    {
        "序号": 11591,
        "name": "MarketWise Inc-A",
        "最新价": 2.74,
        "涨跌额": -0.32,
        "涨跌幅": -10.46,
        "开盘价": 3.03,
        "最高价": 3.11,
        "最低价": 2.51,
        "昨收价": 3.06,
        "总市值": 897321888.0,
        "市盈率": 151.09,
        "成交量": 308526.0,
        "成交额": 855011.0,
        "振幅": 19.61,
        "换手率": 0.09,
        "symbol": "105.MKTW"
    },
    {
        "序号": 11592,
        "name": "Know Labs Inc",
        "最新价": 0.51,
        "涨跌额": -0.06,
        "涨跌幅": -10.53,
        "开盘价": 0.57,
        "最高价": 0.58,
        "最低价": 0.5,
        "昨收价": 0.57,
        "总市值": 40982816.0,
        "市盈率": -2.29,
        "成交量": 104477.0,
        "成交额": 54383.0,
        "振幅": 14.04,
        "换手率": 0.13,
        "symbol": "107.KNW"
    },
    {
        "序号": 11593,
        "name": "Harpoon Therapeutics Inc",
        "最新价": 8.94,
        "涨跌额": -1.06,
        "涨跌幅": -10.6,
        "开盘价": 10.27,
        "最高价": 10.27,
        "最低价": 8.81,
        "昨收价": 10.0,
        "总市值": 151374512.0,
        "市盈率": -4.96,
        "成交量": 208386.0,
        "成交额": 1954160.0,
        "振幅": 14.6,
        "换手率": 1.23,
        "symbol": "105.HARP"
    },
    {
        "序号": 11594,
        "name": "MoneyHero Ltd-A",
        "最新价": 2.94,
        "涨跌额": -0.35,
        "涨跌幅": -10.64,
        "开盘价": 3.16,
        "最高价": 3.29,
        "最低价": 2.82,
        "昨收价": 3.29,
        "总市值": 113294385.0,
        "市盈率": -278.89,
        "成交量": 282319.0,
        "成交额": 851648.0,
        "振幅": 14.29,
        "换手率": 0.73,
        "symbol": "105.MNY"
    },
    {
        "序号": 11595,
        "name": "GRI Bio Inc",
        "最新价": 0.5,
        "涨跌额": -0.06,
        "涨跌幅": -10.71,
        "开盘价": 0.54,
        "最高价": 0.54,
        "最低价": 0.49,
        "昨收价": 0.56,
        "总市值": 2260117.0,
        "市盈率": -0.13,
        "成交量": 236672.0,
        "成交额": 120122.0,
        "振幅": 8.93,
        "换手率": 5.24,
        "symbol": "105.GRI"
    },
    {
        "序号": 11596,
        "name": "Signing Day Sports Inc",
        "最新价": 1.08,
        "涨跌额": -0.13,
        "涨跌幅": -10.74,
        "开盘价": 1.18,
        "最高价": 1.21,
        "最低价": 1.06,
        "昨收价": 1.21,
        "总市值": 9652664.0,
        "市盈率": -2.1,
        "成交量": 174160.0,
        "成交额": 196755.0,
        "振幅": 12.4,
        "换手率": 1.95,
        "symbol": "107.SGN"
    },
    {
        "序号": 11597,
        "name": "Aligos Therapeutics Inc",
        "最新价": 0.58,
        "涨跌额": -0.07,
        "涨跌幅": -10.77,
        "开盘价": 0.68,
        "最高价": 0.72,
        "最低价": 0.55,
        "昨收价": 0.65,
        "总市值": 43460472.0,
        "市盈率": -0.53,
        "成交量": 2633978.0,
        "成交额": 1580224.0,
        "振幅": 26.15,
        "换手率": 3.52,
        "symbol": "105.ALGS"
    },
    {
        "序号": 11598,
        "name": "Lexeo Therapeutics Inc",
        "最新价": 11.59,
        "涨跌额": -1.41,
        "涨跌幅": -10.85,
        "开盘价": 12.95,
        "最高价": 12.95,
        "最低价": 11.26,
        "昨收价": 13.0,
        "总市值": 291712512.0,
        "市盈率": -4.58,
        "成交量": 69311.0,
        "成交额": 834804.0,
        "振幅": 13.0,
        "换手率": 0.28,
        "symbol": "105.LXEO"
    },
    {
        "序号": 11599,
        "name": "Adverum Biotechnologies Inc",
        "最新价": 0.9,
        "涨跌额": -0.11,
        "涨跌幅": -10.89,
        "开盘价": 1.02,
        "最高价": 1.04,
        "最低价": 0.88,
        "昨收价": 1.01,
        "总市值": 90924457.0,
        "市盈率": -0.72,
        "成交量": 408462.0,
        "成交额": 395778.0,
        "振幅": 15.84,
        "换手率": 0.4,
        "symbol": "105.ADVM"
    },
    {
        "序号": 11600,
        "name": "SharpLink Gaming Ltd",
        "最新价": 1.21,
        "涨跌额": -0.15,
        "涨跌幅": -11.03,
        "开盘价": 1.38,
        "最高价": 1.41,
        "最低价": 1.21,
        "昨收价": 1.36,
        "总市值": 3465118.0,
        "市盈率": -0.33,
        "成交量": 9487.0,
        "成交额": 11939.0,
        "振幅": 14.71,
        "换手率": 0.33,
        "symbol": "105.SBET"
    },
    {
        "序号": 11601,
        "name": "Pyxis Oncology Inc",
        "最新价": 1.85,
        "涨跌额": -0.23,
        "涨跌幅": -11.06,
        "开盘价": 2.11,
        "最高价": 2.11,
        "最低价": 1.79,
        "昨收价": 2.08,
        "总市值": 81997635.0,
        "市盈率": -0.87,
        "成交量": 271464.0,
        "成交额": 525007.0,
        "振幅": 15.38,
        "换手率": 0.61,
        "symbol": "105.PYXS"
    },
    {
        "序号": 11602,
        "name": "Lifecore Biomedical Inc",
        "最新价": 6.27,
        "涨跌额": -0.78,
        "涨跌幅": -11.06,
        "开盘价": 7.07,
        "最高价": 7.07,
        "最低价": 6.25,
        "昨收价": 7.05,
        "总市值": 185046510.0,
        "市盈率": -1.81,
        "成交量": 135873.0,
        "成交额": 883845.0,
        "振幅": 11.63,
        "换手率": 0.46,
        "symbol": "105.LFCR"
    },
    {
        "序号": 11603,
        "name": "Pineapple Financial Inc",
        "最新价": 3.2,
        "涨跌额": -0.4,
        "涨跌幅": -11.11,
        "开盘价": 3.36,
        "最高价": 3.6,
        "最低价": 2.95,
        "昨收价": 3.6,
        "总市值": 22982330.0,
        "市盈率": -8.03,
        "成交量": 206119.0,
        "成交额": 662024.0,
        "振幅": 18.06,
        "换手率": 2.87,
        "symbol": "107.PAPL"
    },
    {
        "序号": 11604,
        "name": "Kensington Capital Acquisition ",
        "最新价": 0.08,
        "涨跌额": -0.01,
        "涨跌幅": -11.11,
        "开盘价": 0.09,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 40486.0,
        "成交额": 3711.0,
        "振幅": 22.22,
        "换手率": null,
        "symbol": "106.KCGI_WS"
    },
    {
        "序号": 11605,
        "name": "Heart Test Laboratories Inc",
        "最新价": 0.16,
        "涨跌额": -0.02,
        "涨跌幅": -11.11,
        "开盘价": 0.18,
        "最高价": 0.18,
        "最低价": 0.15,
        "昨收价": 0.18,
        "总市值": 7775541.0,
        "市盈率": -1.26,
        "成交量": 7745558.0,
        "成交额": 1268011.0,
        "振幅": 16.67,
        "换手率": 15.94,
        "symbol": "105.HSCS"
    },
    {
        "序号": 11606,
        "name": "Getaround Inc",
        "最新价": 0.16,
        "涨跌额": -0.02,
        "涨跌幅": -11.11,
        "开盘价": 0.17,
        "最高价": 0.18,
        "最低价": 0.16,
        "昨收价": 0.18,
        "总市值": 14870588.0,
        "市盈率": -0.11,
        "成交量": 649794.0,
        "成交额": 106871.0,
        "振幅": 11.11,
        "换手率": 0.7,
        "symbol": "106.GETR"
    },
    {
        "序号": 11607,
        "name": "Cardio Diagnostics Holdings Inc",
        "最新价": 0.08,
        "涨跌额": -0.01,
        "涨跌幅": -11.11,
        "开盘价": 0.1,
        "最高价": 0.13,
        "最低价": 0.08,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 6002.0,
        "成交额": 543.0,
        "振幅": 55.56,
        "换手率": null,
        "symbol": "105.CDIOW"
    },
    {
        "序号": 11608,
        "name": "Syros Pharmaceuticals Inc",
        "最新价": 4.14,
        "涨跌额": -0.52,
        "涨跌幅": -11.16,
        "开盘价": 4.57,
        "最高价": 4.65,
        "最低价": 4.05,
        "昨收价": 4.66,
        "总市值": 87217674.0,
        "市盈率": -0.83,
        "成交量": 334089.0,
        "成交额": 1459986.0,
        "振幅": 12.88,
        "换手率": 1.59,
        "symbol": "105.SYRS"
    },
    {
        "序号": 11609,
        "name": "BriaCell Therapeutics Corp Wt",
        "最新价": 1.75,
        "涨跌额": -0.22,
        "涨跌幅": -11.17,
        "开盘价": 1.65,
        "最高价": 1.75,
        "最低价": 1.65,
        "昨收价": 1.97,
        "总市值": null,
        "市盈率": null,
        "成交量": 1640.0,
        "成交额": 2823.0,
        "振幅": 5.08,
        "换手率": null,
        "symbol": "105.BCTXW"
    },
    {
        "序号": 11610,
        "name": "Nabors Energy Transition Corp U",
        "最新价": 9.35,
        "涨跌额": -1.18,
        "涨跌幅": -11.21,
        "开盘价": 11.05,
        "最高价": 11.05,
        "最低价": 9.35,
        "昨收价": 10.53,
        "总市值": 156618493.0,
        "市盈率": -833.39,
        "成交量": 14634.0,
        "成交额": 139647.0,
        "振幅": 16.14,
        "换手率": null,
        "symbol": "106.NETC_U"
    },
    {
        "序号": 11611,
        "name": "reAlpha Tech Corp",
        "最新价": 2.9,
        "涨跌额": -0.37,
        "涨跌幅": -11.31,
        "开盘价": 3.35,
        "最高价": 3.35,
        "最低价": 2.86,
        "昨收价": 3.27,
        "总市值": 127954064.0,
        "市盈率": 356.72,
        "成交量": 689990.0,
        "成交额": 2079537.0,
        "振幅": 14.98,
        "换手率": 1.56,
        "symbol": "105.AIRE"
    },
    {
        "序号": 11612,
        "name": "IMAC Holdings Inc",
        "最新价": 1.56,
        "涨跌额": -0.2,
        "涨跌幅": -11.36,
        "开盘价": 1.72,
        "最高价": 1.79,
        "最低价": 1.56,
        "昨收价": 1.76,
        "总市值": 1775781.0,
        "市盈率": -0.12,
        "成交量": 20864.0,
        "成交额": 34059.0,
        "振幅": 13.07,
        "换手率": 1.83,
        "symbol": "105.BACK"
    },
    {
        "序号": 11613,
        "name": "Micromobility.com Inc-A",
        "最新价": 0.93,
        "涨跌额": -0.12,
        "涨跌幅": -11.43,
        "开盘价": 1.08,
        "最高价": 1.08,
        "最低价": 0.91,
        "昨收价": 1.05,
        "总市值": 1767000.0,
        "市盈率": -0.02,
        "成交量": 1028433.0,
        "成交额": 1023669.0,
        "振幅": 16.19,
        "换手率": 54.13,
        "symbol": "105.MCOM"
    },
    {
        "序号": 11614,
        "name": "Yoshiharu Global Co-A",
        "最新价": 4.78,
        "涨跌额": -0.62,
        "涨跌幅": -11.48,
        "开盘价": 5.25,
        "最高价": 5.53,
        "最低价": 4.6,
        "昨收价": 5.4,
        "总市值": 6185320.0,
        "市盈率": -1.48,
        "成交量": 27420.0,
        "成交额": 135341.0,
        "振幅": 17.22,
        "换手率": 2.12,
        "symbol": "105.YOSH"
    },
    {
        "序号": 11615,
        "name": "Madrigal Pharmaceuticals Inc",
        "最新价": 208.32,
        "涨跌额": -27.29,
        "涨跌幅": -11.58,
        "开盘价": 226.0,
        "最高价": 227.94,
        "最低价": 194.88,
        "昨收价": 235.61,
        "总市值": 4112788015.0,
        "市盈率": -11.84,
        "成交量": 1003645.0,
        "成交额": 211983206.0,
        "振幅": 14.03,
        "换手率": 5.08,
        "symbol": "105.MDGL"
    },
    {
        "序号": 11616,
        "name": "Gaucho Group Holdings Inc",
        "最新价": 0.53,
        "涨跌额": -0.07,
        "涨跌幅": -11.67,
        "开盘价": 0.62,
        "最高价": 0.62,
        "最低价": 0.48,
        "昨收价": 0.6,
        "总市值": 1305149.0,
        "市盈率": -0.07,
        "成交量": 235779.0,
        "成交额": 126010.0,
        "振幅": 23.33,
        "换手率": 9.57,
        "symbol": "105.VINO"
    },
    {
        "序号": 11617,
        "name": "Lilium NV Wt",
        "最新价": 0.15,
        "涨跌额": -0.02,
        "涨跌幅": -11.76,
        "开盘价": 0.14,
        "最高价": 0.18,
        "最低价": 0.14,
        "昨收价": 0.17,
        "总市值": null,
        "市盈率": null,
        "成交量": 7642.0,
        "成交额": 1208.0,
        "振幅": 23.53,
        "换手率": null,
        "symbol": "105.LILMW"
    },
    {
        "序号": 11618,
        "name": "Solid Biosciences Inc",
        "最新价": 5.06,
        "涨跌额": -0.7,
        "涨跌幅": -12.15,
        "开盘价": 5.12,
        "最高价": 5.6,
        "最低价": 4.91,
        "昨收价": 5.76,
        "总市值": 102097846.0,
        "市盈率": -1.12,
        "成交量": 1106046.0,
        "成交额": 5735394.0,
        "振幅": 11.98,
        "换手率": 5.48,
        "symbol": "105.SLDB"
    },
    {
        "序号": 11619,
        "name": "Clearmind Medicine Inc",
        "最新价": 3.3,
        "涨跌额": -0.46,
        "涨跌幅": -12.23,
        "开盘价": 3.66,
        "最高价": 3.72,
        "最低价": 3.21,
        "昨收价": 3.76,
        "总市值": 5587385.0,
        "市盈率": -0.91,
        "成交量": 329759.0,
        "成交额": 1110067.0,
        "振幅": 13.56,
        "换手率": 19.48,
        "symbol": "105.CMND"
    },
    {
        "序号": 11620,
        "name": "Avid Bioservices Inc",
        "最新价": 4.64,
        "涨跌额": -0.65,
        "涨跌幅": -12.29,
        "开盘价": 4.3,
        "最高价": 4.84,
        "最低价": 4.07,
        "昨收价": 5.29,
        "总市值": 293430064.0,
        "市盈率": -25.63,
        "成交量": 5269271.0,
        "成交额": 23946726.0,
        "振幅": 14.56,
        "换手率": 8.33,
        "symbol": "105.CDMO"
    },
    {
        "序号": 11621,
        "name": "Virpax Pharmaceuticals Inc",
        "最新价": 0.35,
        "涨跌额": -0.05,
        "涨跌幅": -12.5,
        "开盘价": 0.38,
        "最高价": 0.4,
        "最低价": 0.35,
        "昨收价": 0.4,
        "总市值": 4099999.0,
        "市盈率": -0.3,
        "成交量": 49807.0,
        "成交额": 17926.0,
        "振幅": 12.5,
        "换手率": 0.43,
        "symbol": "105.VRPX"
    },
    {
        "序号": 11622,
        "name": "Quetta Acquisition Corp Rt",
        "最新价": 1.05,
        "涨跌额": -0.15,
        "涨跌幅": -12.5,
        "开盘价": 1.04,
        "最高价": 1.05,
        "最低价": 1.04,
        "昨收价": 1.2,
        "总市值": null,
        "市盈率": null,
        "成交量": 952.0,
        "成交额": 998.0,
        "振幅": 0.83,
        "换手率": null,
        "symbol": "105.QETAR"
    },
    {
        "序号": 11623,
        "name": "OneMedNet Corp",
        "最新价": 1.19,
        "涨跌额": -0.17,
        "涨跌幅": -12.5,
        "开盘价": 1.38,
        "最高价": 1.44,
        "最低价": 1.19,
        "昨收价": 1.36,
        "总市值": 38393377.0,
        "市盈率": -68.08,
        "成交量": 30971.0,
        "成交额": 40547.0,
        "振幅": 18.38,
        "换手率": 0.1,
        "symbol": "105.ONMD"
    },
    {
        "序号": 11624,
        "name": "Crown Electrokinetics Corp",
        "最新价": 0.14,
        "涨跌额": -0.02,
        "涨跌幅": -12.5,
        "开盘价": 0.16,
        "最高价": 0.16,
        "最低价": 0.14,
        "昨收价": 0.16,
        "总市值": 1769118.0,
        "市盈率": -0.08,
        "成交量": 4780743.0,
        "成交额": 704677.0,
        "振幅": 12.5,
        "换手率": 37.83,
        "symbol": "105.CRKN"
    },
    {
        "序号": 11625,
        "name": "Acutus Medical Inc",
        "最新价": 0.21,
        "涨跌额": -0.03,
        "涨跌幅": -12.5,
        "开盘价": 0.22,
        "最高价": 0.24,
        "最低价": 0.19,
        "昨收价": 0.24,
        "总市值": 6153794.0,
        "市盈率": -0.19,
        "成交量": 572843.0,
        "成交额": 121777.0,
        "振幅": 20.83,
        "换手率": 1.95,
        "symbol": "105.AFIB"
    },
    {
        "序号": 11626,
        "name": "Adit EdTech Acquisition Corp Wt",
        "最新价": 0.07,
        "涨跌额": -0.01,
        "涨跌幅": -12.5,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.07,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 63646.0,
        "成交额": 5204.0,
        "振幅": 12.5,
        "换手率": null,
        "symbol": "107.ADEX_WS"
    },
    {
        "序号": 11627,
        "name": "STRATA Skin Sciences Inc",
        "最新价": 0.55,
        "涨跌额": -0.08,
        "涨跌幅": -12.7,
        "开盘价": 0.61,
        "最高价": 0.66,
        "最低价": 0.52,
        "昨收价": 0.63,
        "总市值": 19276858.0,
        "市盈率": -2.68,
        "成交量": 43153.0,
        "成交额": 26121.0,
        "振幅": 22.22,
        "换手率": 0.12,
        "symbol": "105.SSKN"
    },
    {
        "序号": 11628,
        "name": "MIRA Pharmaceuticals Inc",
        "最新价": 1.58,
        "涨跌额": -0.23,
        "涨跌幅": -12.71,
        "开盘价": 1.8,
        "最高价": 1.81,
        "最低价": 1.56,
        "昨收价": 1.81,
        "总市值": 23353798.0,
        "市盈率": -3.1,
        "成交量": 224696.0,
        "成交额": 363666.0,
        "振幅": 13.81,
        "换手率": 1.52,
        "symbol": "105.MIRA"
    },
    {
        "序号": 11629,
        "name": "居里",
        "最新价": 11.54,
        "涨跌额": -1.69,
        "涨跌幅": -12.77,
        "开盘价": 13.6,
        "最高价": 13.65,
        "最低价": 11.5,
        "昨收价": 13.23,
        "总市值": 67890547.0,
        "市盈率": -1.44,
        "成交量": 80995.0,
        "成交额": 990874.0,
        "振幅": 16.25,
        "换手率": 1.38,
        "symbol": "105.CRIS"
    },
    {
        "序号": 11630,
        "name": "Roadzen Inc",
        "最新价": 2.79,
        "涨跌额": -0.41,
        "涨跌幅": -12.81,
        "开盘价": 3.15,
        "最高价": 3.34,
        "最低价": 2.79,
        "昨收价": 3.2,
        "总市值": 190949913.0,
        "市盈率": 17.19,
        "成交量": 4896.0,
        "成交额": 15057.0,
        "振幅": 17.19,
        "换手率": 0.01,
        "symbol": "105.RDZN"
    },
    {
        "序号": 11631,
        "name": "Journey Medical Corp",
        "最新价": 5.82,
        "涨跌额": -0.86,
        "涨跌幅": -12.87,
        "开盘价": 6.7,
        "最高价": 6.87,
        "最低价": 5.54,
        "昨收价": 6.68,
        "总市值": 107719173.0,
        "市盈率": -8.72,
        "成交量": 536671.0,
        "成交额": 3313848.0,
        "振幅": 19.91,
        "换手率": 2.9,
        "symbol": "105.DERM"
    },
    {
        "序号": 11632,
        "name": "Tidewater Inc Wt",
        "最新价": 2.61,
        "涨跌额": -0.39,
        "涨跌幅": -13.0,
        "开盘价": 2.64,
        "最高价": 2.64,
        "最低价": 2.61,
        "昨收价": 3.0,
        "总市值": null,
        "市盈率": null,
        "成交量": 867.0,
        "成交额": 2269.0,
        "振幅": 1.0,
        "换手率": null,
        "symbol": "107.TDW_WS"
    },
    {
        "序号": 11633,
        "name": "Adecoagro SA",
        "最新价": 10.44,
        "涨跌额": -1.56,
        "涨跌幅": -13.0,
        "开盘价": 11.06,
        "最高价": 11.14,
        "最低价": 10.15,
        "昨收价": 12.0,
        "总市值": 1113301399.0,
        "市盈率": 7.57,
        "成交量": 2470302.0,
        "成交额": 25762165.0,
        "振幅": 8.25,
        "换手率": 2.32,
        "symbol": "106.AGRO"
    },
    {
        "序号": 11634,
        "name": "Xponential Fitness Inc-A",
        "最新价": 10.6,
        "涨跌额": -1.59,
        "涨跌幅": -13.04,
        "开盘价": 12.29,
        "最高价": 12.3,
        "最低价": 10.24,
        "昨收价": 12.19,
        "总市值": 503044889.0,
        "市盈率": 105.53,
        "成交量": 2666565.0,
        "成交额": 28550942.0,
        "振幅": 16.9,
        "换手率": 5.62,
        "symbol": "106.XPOF"
    },
    {
        "序号": 11635,
        "name": "Verde Clean Fuels Inc Wt",
        "最新价": 0.13,
        "涨跌额": -0.02,
        "涨跌幅": -13.33,
        "开盘价": 0.13,
        "最高价": 0.14,
        "最低价": 0.09,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 48200.0,
        "成交额": 5559.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.VGASW"
    },
    {
        "序号": 11636,
        "name": "Charge Enterprises Inc",
        "最新价": 0.13,
        "涨跌额": -0.02,
        "涨跌幅": -13.33,
        "开盘价": 0.14,
        "最高价": 0.15,
        "最低价": 0.12,
        "昨收价": 0.15,
        "总市值": 27955183.0,
        "市盈率": -0.63,
        "成交量": 2518139.0,
        "成交额": 324586.0,
        "振幅": 20.0,
        "换手率": 1.17,
        "symbol": "105.CRGE"
    },
    {
        "序号": 11637,
        "name": "CONX Corp Wt",
        "最新价": 0.25,
        "涨跌额": -0.04,
        "涨跌幅": -13.79,
        "开盘价": 0.23,
        "最高价": 0.25,
        "最低价": 0.21,
        "昨收价": 0.29,
        "总市值": null,
        "市盈率": null,
        "成交量": 7201.0,
        "成交额": 1625.0,
        "振幅": 13.79,
        "换手率": null,
        "symbol": "105.CONXW"
    },
    {
        "序号": 11638,
        "name": "诺华家具",
        "最新价": 1.81,
        "涨跌额": -0.29,
        "涨跌幅": -13.81,
        "开盘价": 2.0,
        "最高价": 2.03,
        "最低价": 1.81,
        "昨收价": 2.1,
        "总市值": 2958856.0,
        "市盈率": -0.25,
        "成交量": 7812.0,
        "成交额": 15024.0,
        "振幅": 10.48,
        "换手率": 0.48,
        "symbol": "105.NVFY"
    },
    {
        "序号": 11639,
        "name": "乔治香颂",
        "最新价": 7.19,
        "涨跌额": -1.16,
        "涨跌幅": -13.89,
        "开盘价": 8.32,
        "最高价": 8.45,
        "最低价": 7.06,
        "昨收价": 8.35,
        "总市值": 89084100.0,
        "市盈率": -72.77,
        "成交量": 624511.0,
        "成交额": 4952355.0,
        "振幅": 16.65,
        "换手率": 5.04,
        "symbol": "105.CHSN"
    },
    {
        "序号": 11640,
        "name": "一盈证券",
        "最新价": 7.53,
        "涨跌额": -1.22,
        "涨跌幅": -13.94,
        "开盘价": 8.63,
        "最高价": 8.8,
        "最低价": 6.72,
        "昨收价": 8.75,
        "总市值": 114832500.0,
        "市盈率": -554.29,
        "成交量": 207314.0,
        "成交额": 1564894.0,
        "振幅": 23.77,
        "换手率": 1.36,
        "symbol": "105.GSIW"
    },
    {
        "序号": 11641,
        "name": "RH",
        "最新价": 242.01,
        "涨跌额": -39.39,
        "涨跌幅": -14.0,
        "开盘价": 252.1,
        "最高价": 253.89,
        "最低价": 239.0,
        "昨收价": 281.4,
        "总市值": 4409280382.0,
        "市盈率": 19.77,
        "成交量": 3732228.0,
        "成交额": 907996496.0,
        "振幅": 5.29,
        "换手率": 20.48,
        "symbol": "106.RH"
    },
    {
        "序号": 11642,
        "name": "Sera Prognostics Inc-A",
        "最新价": 4.34,
        "涨跌额": -0.71,
        "涨跌幅": -14.06,
        "开盘价": 5.14,
        "最高价": 5.8,
        "最低价": 4.2,
        "昨收价": 5.05,
        "总市值": 136000462.0,
        "市盈率": -3.57,
        "成交量": 1890679.0,
        "成交额": 9003188.0,
        "振幅": 31.68,
        "换手率": 6.03,
        "symbol": "105.SERA"
    },
    {
        "序号": 11643,
        "name": "Wearable Devices Ltd",
        "最新价": 0.3,
        "涨跌额": -0.05,
        "涨跌幅": -14.29,
        "开盘价": 0.35,
        "最高价": 0.37,
        "最低价": 0.29,
        "昨收价": 0.35,
        "总市值": 6116228.0,
        "市盈率": -0.73,
        "成交量": 729196.0,
        "成交额": 238943.0,
        "振幅": 22.86,
        "换手率": 3.58,
        "symbol": "105.WLDS"
    },
    {
        "序号": 11644,
        "name": "Slam Corp Wt",
        "最新价": 0.06,
        "涨跌额": -0.01,
        "涨跌幅": -14.29,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 264953.0,
        "成交额": 15897.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SLAMW"
    },
    {
        "序号": 11645,
        "name": "NWTN Inc Wt",
        "最新价": 0.18,
        "涨跌额": -0.03,
        "涨跌幅": -14.29,
        "开盘价": 0.19,
        "最高价": 0.19,
        "最低价": 0.15,
        "昨收价": 0.21,
        "总市值": null,
        "市盈率": null,
        "成交量": 1642.0,
        "成交额": 291.0,
        "振幅": 19.05,
        "换手率": null,
        "symbol": "105.NWTNW"
    },
    {
        "序号": 11646,
        "name": "Brilliant Acquisition Corp Wt",
        "最新价": 0.06,
        "涨跌额": -0.01,
        "涨跌幅": -14.29,
        "开盘价": 0.06,
        "最高价": 0.07,
        "最低价": 0.06,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 79380.0,
        "成交额": 4786.0,
        "振幅": 14.29,
        "换手率": null,
        "symbol": "105.BRLIW"
    },
    {
        "序号": 11647,
        "name": "Navios Maritime Holdings Inc AD",
        "最新价": 15.25,
        "涨跌额": -2.63,
        "涨跌幅": -14.71,
        "开盘价": 16.5,
        "最高价": 16.5,
        "最低价": 14.6,
        "昨收价": 17.88,
        "总市值": 180560.0,
        "市盈率": null,
        "成交量": 2839.0,
        "成交额": 44173.0,
        "振幅": 10.63,
        "换手率": 23.98,
        "symbol": "106.NM_H"
    },
    {
        "序号": 11648,
        "name": "华夏博雅",
        "最新价": 0.23,
        "涨跌额": -0.04,
        "涨跌幅": -14.81,
        "开盘价": 0.25,
        "最高价": 0.28,
        "最低价": 0.22,
        "昨收价": 0.27,
        "总市值": 11407617.0,
        "市盈率": -4.05,
        "成交量": 618445.0,
        "成交额": 153579.0,
        "振幅": 22.22,
        "换手率": 1.25,
        "symbol": "105.CLEU"
    },
    {
        "序号": 11649,
        "name": "海王星科技",
        "最新价": 0.4,
        "涨跌额": -0.07,
        "涨跌幅": -14.89,
        "开盘价": 0.45,
        "最高价": 0.49,
        "最低价": 0.37,
        "昨收价": 0.47,
        "总市值": 1812815.0,
        "市盈率": -0.05,
        "成交量": 434312.0,
        "成交额": 174687.0,
        "振幅": 25.53,
        "换手率": 9.58,
        "symbol": "105.NEPT"
    },
    {
        "序号": 11650,
        "name": "普益财富",
        "最新价": 2.16,
        "涨跌额": -0.38,
        "涨跌幅": -14.96,
        "开盘价": 2.4,
        "最高价": 2.49,
        "最低价": 2.09,
        "昨收价": 2.54,
        "总市值": 130279700.0,
        "市盈率": -21.6,
        "成交量": 13729.0,
        "成交额": 31290.0,
        "振幅": 15.75,
        "换手率": 0.02,
        "symbol": "105.PUYI"
    },
    {
        "序号": 11651,
        "name": "Mangoceuticals Inc",
        "最新价": 0.68,
        "涨跌额": -0.12,
        "涨跌幅": -15.0,
        "开盘价": 0.79,
        "最高价": 0.79,
        "最低价": 0.65,
        "昨收价": 0.8,
        "总市值": 11586860.0,
        "市盈率": -1.58,
        "成交量": 130383.0,
        "成交额": 92507.0,
        "振幅": 17.5,
        "换手率": 0.77,
        "symbol": "105.MGRX"
    },
    {
        "序号": 11652,
        "name": "Scilex Holding Co Wt",
        "最新价": 0.33,
        "涨跌额": -0.06,
        "涨跌幅": -15.38,
        "开盘价": 0.43,
        "最高价": 0.43,
        "最低价": 0.33,
        "昨收价": 0.39,
        "总市值": null,
        "市盈率": null,
        "成交量": 2611.0,
        "成交额": 1073.0,
        "振幅": 25.64,
        "换手率": null,
        "symbol": "105.SCLXW"
    },
    {
        "序号": 11653,
        "name": "Delta Apparel Inc",
        "最新价": 7.5,
        "涨跌额": -1.37,
        "涨跌幅": -15.45,
        "开盘价": 8.45,
        "最高价": 8.45,
        "最低价": 7.04,
        "昨收价": 8.87,
        "总市值": 52507650.0,
        "市盈率": -3.07,
        "成交量": 179743.0,
        "成交额": 1315002.0,
        "振幅": 15.9,
        "换手率": 2.57,
        "symbol": "107.DLA"
    },
    {
        "序号": 11654,
        "name": "Simplify Tail Risk Strategy ETF",
        "最新价": 0.38,
        "涨跌额": -0.07,
        "涨跌幅": -15.56,
        "开盘价": 0.45,
        "最高价": 0.45,
        "最低价": 0.37,
        "昨收价": 0.45,
        "总市值": null,
        "市盈率": null,
        "成交量": 441032.0,
        "成交额": 175848.0,
        "振幅": 17.78,
        "换手率": null,
        "symbol": "107.CYA"
    },
    {
        "序号": 11655,
        "name": "Sunnova Energy International In",
        "最新价": 10.41,
        "涨跌额": -2.0,
        "涨跌幅": -16.12,
        "开盘价": 12.21,
        "最高价": 12.45,
        "最低价": 9.56,
        "昨收价": 12.41,
        "总市值": 1274354297.0,
        "市盈率": -5.07,
        "成交量": 14837889.0,
        "成交额": 156829412.0,
        "振幅": 23.29,
        "换手率": 12.12,
        "symbol": "106.NOVA"
    },
    {
        "序号": 11656,
        "name": "Centro Electric Group Ltd",
        "最新价": 1.35,
        "涨跌额": -0.26,
        "涨跌幅": -16.15,
        "开盘价": 1.39,
        "最高价": 1.45,
        "最低价": 1.26,
        "昨收价": 1.61,
        "总市值": 41100629.0,
        "市盈率": -0.36,
        "成交量": 841663.0,
        "成交额": 1117610.0,
        "振幅": 11.8,
        "换手率": 2.76,
        "symbol": "105.CENN"
    },
    {
        "序号": 11657,
        "name": "Grom Social Enterprises Inc Wt",
        "最新价": 2.01,
        "涨跌额": -0.39,
        "涨跌幅": -16.25,
        "开盘价": 2.4,
        "最高价": 3.6,
        "最低价": 2.01,
        "昨收价": 2.4,
        "总市值": null,
        "市盈率": null,
        "成交量": 1434.0,
        "成交额": 3607.0,
        "振幅": 66.25,
        "换手率": null,
        "symbol": "105.GROMW"
    },
    {
        "序号": 11658,
        "name": "Electriq Power Holdings Inc-A",
        "最新价": 0.36,
        "涨跌额": -0.07,
        "涨跌幅": -16.28,
        "开盘价": 0.43,
        "最高价": 0.43,
        "最低价": 0.35,
        "昨收价": 0.43,
        "总市值": 15031564.0,
        "市盈率": 0.36,
        "成交量": 322711.0,
        "成交额": 123514.0,
        "振幅": 18.6,
        "换手率": 0.77,
        "symbol": "106.ELIQ"
    },
    {
        "序号": 11659,
        "name": "Hempacco Co Inc",
        "最新价": 0.41,
        "涨跌额": -0.08,
        "涨跌幅": -16.33,
        "开盘价": 0.42,
        "最高价": 0.48,
        "最低价": 0.33,
        "昨收价": 0.49,
        "总市值": 11949670.0,
        "市盈率": -1.07,
        "成交量": 2460796.0,
        "成交额": 992527.0,
        "振幅": 30.61,
        "换手率": 8.44,
        "symbol": "105.HPCO"
    },
    {
        "序号": 11660,
        "name": "HashiCorp Inc-A",
        "最新价": 20.81,
        "涨跌额": -4.09,
        "涨跌幅": -16.43,
        "开盘价": 19.8,
        "最高价": 21.2,
        "最低价": 19.27,
        "昨收价": 24.9,
        "总市值": 4075934023.0,
        "市盈率": -19.56,
        "成交量": 17560936.0,
        "成交额": 356062416.0,
        "振幅": 7.75,
        "换手率": 8.97,
        "symbol": "105.HCP"
    },
    {
        "序号": 11661,
        "name": "Target Global Acquisition I Cor",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 126706.0,
        "成交额": 6728.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.TGAAW"
    },
    {
        "序号": 11662,
        "name": "Spring Valley Acquisition Corp ",
        "最新价": 0.1,
        "涨跌额": -0.02,
        "涨跌幅": -16.67,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.1,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 50000.0,
        "成交额": 5000.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.SVIIW"
    },
    {
        "序号": 11663,
        "name": "RMG Acquisition Corp III Wt",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 54.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.RMGCW"
    },
    {
        "序号": 11664,
        "name": "Prospector Capital Corp Wt",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.04,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 6363.0,
        "成交额": 323.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.PRSRW"
    },
    {
        "序号": 11665,
        "name": "CareMax Inc Wt",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 1612.0,
        "成交额": 84.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "105.CMAXW"
    },
    {
        "序号": 11666,
        "name": "Breeze Holdings Acquisition Cor",
        "最新价": 0.1,
        "涨跌额": -0.02,
        "涨跌幅": -16.67,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.08,
        "昨收价": 0.12,
        "总市值": null,
        "市盈率": null,
        "成交量": 26438.0,
        "成交额": 2410.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "105.BREZW"
    },
    {
        "序号": 11667,
        "name": "Bark Inc Wt",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 65604.0,
        "成交额": 3285.0,
        "振幅": 16.67,
        "换手率": null,
        "symbol": "106.BARK_WS"
    },
    {
        "序号": 11668,
        "name": "Avalo Therapeutics Inc",
        "最新价": 0.05,
        "涨跌额": -0.01,
        "涨跌幅": -16.67,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.05,
        "昨收价": 0.06,
        "总市值": 9619121.0,
        "市盈率": -0.29,
        "成交量": 15407814.0,
        "成交额": 867692.0,
        "振幅": 16.67,
        "换手率": 8.01,
        "symbol": "105.AVTX"
    },
    {
        "序号": 11669,
        "name": "喜玛庆国际",
        "最新价": 3.05,
        "涨跌额": -0.62,
        "涨跌幅": -16.89,
        "开盘价": 3.42,
        "最高价": 3.55,
        "最低价": 2.88,
        "昨收价": 3.67,
        "总市值": 18587578.0,
        "市盈率": -0.86,
        "成交量": 78517.0,
        "成交额": 241454.0,
        "振幅": 18.26,
        "换手率": 1.29,
        "symbol": "105.SGMA"
    },
    {
        "序号": 11670,
        "name": "Applied UV Inc",
        "最新价": 0.09,
        "涨跌额": -0.02,
        "涨跌幅": -18.18,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.08,
        "昨收价": 0.11,
        "总市值": 1246758.0,
        "市盈率": -0.06,
        "成交量": 7231218.0,
        "成交额": 625275.0,
        "振幅": 9.09,
        "换手率": 52.2,
        "symbol": "105.AUVI"
    },
    {
        "序号": 11671,
        "name": "Safety Shot Inc Wt",
        "最新价": 1.91,
        "涨跌额": -0.43,
        "涨跌幅": -18.38,
        "开盘价": 2.5,
        "最高价": 2.5,
        "最低价": 1.69,
        "昨收价": 2.34,
        "总市值": null,
        "市盈率": null,
        "成交量": 6605.0,
        "成交额": 13221.0,
        "振幅": 34.62,
        "换手率": null,
        "symbol": "105.SHOTW"
    },
    {
        "序号": 11672,
        "name": "Applied DNA Sciences Inc",
        "最新价": 0.88,
        "涨跌额": -0.2,
        "涨跌幅": -18.52,
        "开盘价": 0.97,
        "最高价": 1.0,
        "最低价": 0.88,
        "昨收价": 1.08,
        "总市值": 12044930.0,
        "市盈率": -1.21,
        "成交量": 158899.0,
        "成交额": 146012.0,
        "振幅": 11.11,
        "换手率": 1.16,
        "symbol": "105.APDN"
    },
    {
        "序号": 11673,
        "name": "XBP Europe Holdings Inc",
        "最新价": 16.07,
        "涨跌额": -3.73,
        "涨跌幅": -18.84,
        "开盘价": 19.41,
        "最高价": 21.8,
        "最低价": 16.0,
        "昨收价": 19.8,
        "总市值": 484769259.0,
        "市盈率": -21.84,
        "成交量": 88972.0,
        "成交额": 1712097.0,
        "振幅": 29.29,
        "换手率": 0.29,
        "symbol": "105.XBP"
    },
    {
        "序号": 11674,
        "name": "Velo3D Inc",
        "最新价": 0.64,
        "涨跌额": -0.15,
        "涨跌幅": -18.99,
        "开盘价": 0.81,
        "最高价": 0.81,
        "最低价": 0.63,
        "昨收价": 0.79,
        "总市值": 128507860.0,
        "市盈率": -2.37,
        "成交量": 7898049.0,
        "成交额": 5352756.0,
        "振幅": 22.78,
        "换手率": 3.93,
        "symbol": "106.VLD"
    },
    {
        "序号": 11675,
        "name": "一起教育科技",
        "最新价": 0.34,
        "涨跌额": -0.08,
        "涨跌幅": -19.05,
        "开盘价": 0.43,
        "最高价": 0.43,
        "最低价": 0.34,
        "昨收价": 0.42,
        "总市值": 16545744.0,
        "市盈率": -0.37,
        "成交量": 181898.0,
        "成交额": 68176.0,
        "振幅": 21.43,
        "换手率": 0.37,
        "symbol": "105.YQ"
    },
    {
        "序号": 11676,
        "name": "Kintara Therapeutics Inc",
        "最新价": 0.21,
        "涨跌额": -0.05,
        "涨跌幅": -19.23,
        "开盘价": 0.24,
        "最高价": 0.24,
        "最低价": 0.21,
        "昨收价": 0.26,
        "总市值": 711440.0,
        "市盈率": -0.05,
        "成交量": 1501265.0,
        "成交额": 332760.0,
        "振幅": 11.54,
        "换手率": 44.31,
        "symbol": "105.KTRA"
    },
    {
        "序号": 11677,
        "name": "HUB Cyber Security Ltd Wt",
        "最新价": 0.21,
        "涨跌额": -0.05,
        "涨跌幅": -19.23,
        "开盘价": 0.21,
        "最高价": 0.21,
        "最低价": 0.21,
        "昨收价": 0.26,
        "总市值": null,
        "市盈率": null,
        "成交量": 100.0,
        "成交额": 21.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.HUBCZ"
    },
    {
        "序号": 11678,
        "name": "Altamira Therapeutics Ltd",
        "最新价": 0.41,
        "涨跌额": -0.1,
        "涨跌幅": -19.61,
        "开盘价": 0.49,
        "最高价": 0.54,
        "最低价": 0.39,
        "昨收价": 0.51,
        "总市值": 3619892.0,
        "市盈率": -0.14,
        "成交量": 9849088.0,
        "成交额": 4500078.0,
        "振幅": 29.41,
        "换手率": 111.55,
        "symbol": "105.CYTO"
    },
    {
        "序号": 11679,
        "name": "Velo3D Inc Wt",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.05,
        "最高价": 0.06,
        "最低价": 0.03,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 82400.0,
        "成交额": 2891.0,
        "振幅": 60.0,
        "换手率": null,
        "symbol": "106.VLD_WS"
    },
    {
        "序号": 11680,
        "name": "Oxus Acquisition Corp Wt",
        "最新价": 0.08,
        "涨跌额": -0.02,
        "涨跌幅": -20.0,
        "开盘价": 0.04,
        "最高价": 0.08,
        "最低价": 0.04,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 2884.0,
        "成交额": 120.0,
        "振幅": 40.0,
        "换手率": null,
        "symbol": "105.OXUSW"
    },
    {
        "序号": 11681,
        "name": "Semper Paratus Acquisition Corp",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 64326.0,
        "成交额": 2701.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.LGSTW"
    },
    {
        "序号": 11682,
        "name": "EF Hutton Acquisition Corp I Wt",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 94000.0,
        "成交额": 3966.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.EFHTW"
    },
    {
        "序号": 11683,
        "name": "Dragonfly Energy Holdings Corp ",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.03,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 878.0,
        "成交额": 31.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.DFLIW"
    },
    {
        "序号": 11684,
        "name": "BM Technologies Inc Wt",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 16000.0,
        "成交额": 725.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "107.BMTX_WS"
    },
    {
        "序号": 11685,
        "name": "冠科美博(权证)",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 49402.0,
        "成交额": 2222.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "105.APLMW"
    },
    {
        "序号": 11686,
        "name": "Allurion Technologies Inc Wt",
        "最新价": 0.4,
        "涨跌额": -0.1,
        "涨跌幅": -20.0,
        "开盘价": 0.45,
        "最高价": 0.5,
        "最低价": 0.38,
        "昨收价": 0.5,
        "总市值": null,
        "市盈率": null,
        "成交量": 39080.0,
        "成交额": 16942.0,
        "振幅": 24.0,
        "换手率": null,
        "symbol": "106.ALUR_WS"
    },
    {
        "序号": 11687,
        "name": "AdTheorent Holding Co Inc Wt",
        "最新价": 0.04,
        "涨跌额": -0.01,
        "涨跌幅": -20.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 40.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.ADTHW"
    },
    {
        "序号": 11688,
        "name": "Ares Acquisition Corp II Wt",
        "最新价": 0.12,
        "涨跌额": -0.03,
        "涨跌幅": -20.0,
        "开盘价": 0.15,
        "最高价": 0.15,
        "最低价": 0.12,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 113303.0,
        "成交额": 16589.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "106.AACT_WS"
    },
    {
        "序号": 11689,
        "name": "Veradigm Inc",
        "最新价": 10.07,
        "涨跌额": -2.52,
        "涨跌幅": -20.02,
        "开盘价": 12.25,
        "最高价": 12.25,
        "最低价": 9.86,
        "昨收价": 12.59,
        "总市值": 1100245944.0,
        "市盈率": 18.15,
        "成交量": 8746625.0,
        "成交额": 89384894.0,
        "振幅": 18.98,
        "换手率": 8.01,
        "symbol": "105.MDRX"
    },
    {
        "序号": 11690,
        "name": "AquaBounty Technologies Inc",
        "最新价": 3.01,
        "涨跌额": -0.77,
        "涨跌幅": -20.37,
        "开盘价": 3.75,
        "最高价": 3.97,
        "最低价": 2.93,
        "昨收价": 3.78,
        "总市值": 11578784.0,
        "市盈率": -0.46,
        "成交量": 197325.0,
        "成交额": 654314.0,
        "振幅": 27.51,
        "换手率": 5.13,
        "symbol": "105.AQB"
    },
    {
        "序号": 11691,
        "name": "Cano Health Inc-A",
        "最新价": 8.59,
        "涨跌额": -2.21,
        "涨跌幅": -20.46,
        "开盘价": 9.62,
        "最高价": 10.55,
        "最低价": 8.39,
        "昨收价": 10.8,
        "总市值": 46441852.0,
        "市盈率": -0.08,
        "成交量": 461105.0,
        "成交额": 4175836.0,
        "振幅": 20.0,
        "换手率": 8.53,
        "symbol": "106.CANO"
    },
    {
        "序号": 11692,
        "name": "ZyVersa Therapeutics Inc",
        "最新价": 0.9,
        "涨跌额": -0.25,
        "涨跌幅": -21.74,
        "开盘价": 1.15,
        "最高价": 1.15,
        "最低价": 0.89,
        "昨收价": 1.15,
        "总市值": 1118967.0,
        "市盈率": null,
        "成交量": 952286.0,
        "成交额": 935781.0,
        "振幅": 22.61,
        "换手率": 76.59,
        "symbol": "105.ZVSA"
    },
    {
        "序号": 11693,
        "name": "Webuy Global Ltd",
        "最新价": 1.14,
        "涨跌额": -0.32,
        "涨跌幅": -21.92,
        "开盘价": 1.44,
        "最高价": 1.57,
        "最低价": 1.03,
        "昨收价": 1.46,
        "总市值": 59715024.0,
        "市盈率": -9.77,
        "成交量": 4268105.0,
        "成交额": 5312064.0,
        "振幅": 36.99,
        "换手率": 8.15,
        "symbol": "105.WBUY"
    },
    {
        "序号": 11694,
        "name": "Celgene Corp CVR",
        "最新价": 0.07,
        "涨跌额": -0.02,
        "涨跌幅": -22.22,
        "开盘价": 0.09,
        "最高价": 0.09,
        "最低价": 0.07,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 5200.0,
        "成交额": 351.0,
        "振幅": 22.22,
        "换手率": null,
        "symbol": "106.CELGr"
    },
    {
        "序号": 11695,
        "name": "COMSovereign Holding Corp Serie",
        "最新价": 0.9,
        "涨跌额": -0.26,
        "涨跌幅": -22.41,
        "开盘价": 1.05,
        "最高价": 1.07,
        "最低价": 0.9,
        "昨收价": 1.16,
        "总市值": 288000.0,
        "市盈率": null,
        "成交量": 7942.0,
        "成交额": 7982.0,
        "振幅": 14.66,
        "换手率": 2.48,
        "symbol": "105.COMSP"
    },
    {
        "序号": 11696,
        "name": "Cazoo Group Ltd-A",
        "最新价": 23.25,
        "涨跌额": -6.74,
        "涨跌幅": -22.47,
        "开盘价": 28.79,
        "最高价": 31.19,
        "最低价": 21.04,
        "昨收价": 29.99,
        "总市值": 113715797.0,
        "市盈率": -0.16,
        "成交量": 14556.0,
        "成交额": 374686.0,
        "振幅": 33.84,
        "换手率": 0.3,
        "symbol": "106.CZOO"
    },
    {
        "序号": 11697,
        "name": "Oncocyte Corp",
        "最新价": 2.95,
        "涨跌额": -0.89,
        "涨跌幅": -23.18,
        "开盘价": 3.75,
        "最高价": 3.75,
        "最低价": 2.83,
        "昨收价": 3.84,
        "总市值": 24369248.0,
        "市盈率": -0.43,
        "成交量": 40801.0,
        "成交额": 133296.0,
        "振幅": 23.96,
        "换手率": 0.49,
        "symbol": "105.OCX"
    },
    {
        "序号": 11698,
        "name": "FLJ Group Ltd ADR",
        "最新价": 1.51,
        "涨跌额": -0.47,
        "涨跌幅": -23.74,
        "开盘价": 2.22,
        "最高价": 2.33,
        "最低价": 1.3,
        "昨收价": 1.98,
        "总市值": 71420.0,
        "市盈率": 0.0,
        "成交量": 6762355.0,
        "成交额": 11268269.0,
        "振幅": 52.02,
        "换手率": 14297.28,
        "symbol": "105.FLJ"
    },
    {
        "序号": 11699,
        "name": "Vivid Seats Inc Wt",
        "最新价": 1.56,
        "涨跌额": -0.51,
        "涨跌幅": -24.64,
        "开盘价": 1.4,
        "最高价": 1.56,
        "最低价": 1.4,
        "昨收价": 2.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 301.0,
        "成交额": 453.0,
        "振幅": 7.73,
        "换手率": null,
        "symbol": "105.SEATW"
    },
    {
        "序号": 11700,
        "name": "XBP Europe Holdings Inc Wt",
        "最新价": 0.12,
        "涨跌额": -0.04,
        "涨跌幅": -25.0,
        "开盘价": 0.17,
        "最高价": 0.2,
        "最低价": 0.12,
        "昨收价": 0.16,
        "总市值": null,
        "市盈率": null,
        "成交量": 25947.0,
        "成交额": 4798.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.XBPEW"
    },
    {
        "序号": 11701,
        "name": "Phunware Inc Wt",
        "最新价": 0.03,
        "涨跌额": -0.01,
        "涨跌幅": -25.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.02,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 22359.0,
        "成交额": 615.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.PHUNW"
    },
    {
        "序号": 11702,
        "name": "PAVmed Inc Wt",
        "最新价": 0.06,
        "涨跌额": -0.02,
        "涨跌幅": -25.0,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.08,
        "总市值": null,
        "市盈率": null,
        "成交量": 200.0,
        "成交额": 11.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.PAVMZ"
    },
    {
        "序号": 11703,
        "name": "Nvni Group Ltd Wt",
        "最新价": 0.03,
        "涨跌额": -0.01,
        "涨跌幅": -25.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 17555.0,
        "成交额": 700.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.NVNIW"
    },
    {
        "序号": 11704,
        "name": "Kaival Brands Innovations Group",
        "最新价": 0.24,
        "涨跌额": -0.08,
        "涨跌幅": -25.0,
        "开盘价": 0.32,
        "最高价": 0.32,
        "最低价": 0.23,
        "昨收价": 0.32,
        "总市值": 14078662.0,
        "市盈率": -1.23,
        "成交量": 1662479.0,
        "成交额": 440698.0,
        "振幅": 28.13,
        "换手率": 2.83,
        "symbol": "105.KAVL"
    },
    {
        "序号": 11705,
        "name": "Blue World Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": -0.01,
        "涨跌幅": -25.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 800.0,
        "成交额": 22.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.BWAQW"
    },
    {
        "序号": 11706,
        "name": "Bite Acquisition Corp Wt",
        "最新价": 0.03,
        "涨跌额": -0.01,
        "涨跌幅": -25.0,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.03,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 26852.0,
        "成交额": 937.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "107.BITE_WS"
    },
    {
        "序号": 11707,
        "name": "Rocket Pharmaceuticals Inc Wt",
        "最新价": 0.33,
        "涨跌额": -0.13,
        "涨跌幅": -28.26,
        "开盘价": 0.4,
        "最高价": 0.46,
        "最低价": 0.33,
        "昨收价": 0.46,
        "总市值": null,
        "市盈率": null,
        "成交量": 16100.0,
        "成交额": 6524.0,
        "振幅": 28.26,
        "换手率": null,
        "symbol": "105.RCKTW"
    },
    {
        "序号": 11708,
        "name": "Rail Vision Ltd Wt",
        "最新价": 0.05,
        "涨跌额": -0.02,
        "涨跌幅": -28.57,
        "开盘价": 0.1,
        "最高价": 0.1,
        "最低价": 0.05,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 9931.0,
        "成交额": 981.0,
        "振幅": 71.43,
        "换手率": null,
        "symbol": "105.RVSNW"
    },
    {
        "序号": 11709,
        "name": "Oxbridge Re Holdings Ltd Wt",
        "最新价": 0.05,
        "涨跌额": -0.02,
        "涨跌幅": -28.57,
        "开盘价": 0.07,
        "最高价": 0.07,
        "最低价": 0.04,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 6540.0,
        "成交额": 373.0,
        "振幅": 42.86,
        "换手率": null,
        "symbol": "105.OXBRW"
    },
    {
        "序号": 11710,
        "name": "Syntec Optics Holdings Inc Wt",
        "最新价": 0.05,
        "涨跌额": -0.02,
        "涨跌幅": -28.57,
        "开盘价": 0.08,
        "最高价": 0.08,
        "最低价": 0.05,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 13498.0,
        "成交额": 899.0,
        "振幅": 42.86,
        "换手率": null,
        "symbol": "105.OPTXW"
    },
    {
        "序号": 11711,
        "name": "康姆泰克通讯",
        "最新价": 8.79,
        "涨跌额": -3.71,
        "涨跌幅": -29.68,
        "开盘价": 11.6,
        "最高价": 11.6,
        "最低价": 8.74,
        "昨收价": 12.5,
        "总市值": 250329971.0,
        "市盈率": -14.52,
        "成交量": 2409894.0,
        "成交额": 23202578.0,
        "振幅": 22.88,
        "换手率": 8.46,
        "symbol": "105.CMTL"
    },
    {
        "序号": 11712,
        "name": "Everest Consolidator Acquisitio",
        "最新价": 0.07,
        "涨跌额": -0.03,
        "涨跌幅": -30.0,
        "开盘价": 0.07,
        "最高价": 0.08,
        "最低价": 0.06,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 120500.0,
        "成交额": 7624.0,
        "振幅": 20.0,
        "换手率": null,
        "symbol": "106.MNTN_WS"
    },
    {
        "序号": 11713,
        "name": "Sharecare Inc Wt",
        "最新价": 0.02,
        "涨跌额": -0.01,
        "涨跌幅": -33.33,
        "开盘价": 0.02,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 21051.0,
        "成交额": 443.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.SHCRW"
    },
    {
        "序号": 11714,
        "name": "Ocean Biomedical Inc Wt",
        "最新价": 0.02,
        "涨跌额": -0.01,
        "涨跌幅": -33.33,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 1877.0,
        "成交额": 39.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.OCEAW"
    },
    {
        "序号": 11715,
        "name": "HNR Acquisition Corp Wt",
        "最新价": 0.06,
        "涨跌额": -0.03,
        "涨跌幅": -33.33,
        "开盘价": 0.06,
        "最高价": 0.06,
        "最低价": 0.06,
        "昨收价": 0.09,
        "总市值": null,
        "市盈率": null,
        "成交量": 1000.0,
        "成交额": 63.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.HNRA_WS"
    },
    {
        "序号": 11716,
        "name": "Calidi Biotherapeutics Inc Wt",
        "最新价": 0.04,
        "涨跌额": -0.02,
        "涨跌幅": -33.33,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 113.0,
        "成交额": 4.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "107.CLDI_WS"
    },
    {
        "序号": 11717,
        "name": "Chavant Capital Acquisition Cor",
        "最新价": 0.02,
        "涨跌额": -0.01,
        "涨跌幅": -33.33,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 78567.0,
        "成交额": 1956.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.CLAYW"
    },
    {
        "序号": 11718,
        "name": "Achari Ventures Holdings Corp I",
        "最新价": 0.02,
        "涨跌额": -0.01,
        "涨跌幅": -33.33,
        "开盘价": 0.01,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.03,
        "总市值": null,
        "市盈率": null,
        "成交量": 124839.0,
        "成交额": 1953.0,
        "振幅": 33.33,
        "换手率": null,
        "symbol": "105.AVHIW"
    },
    {
        "序号": 11719,
        "name": "Troika Media Group Inc",
        "最新价": 0.34,
        "涨跌额": -0.2,
        "涨跌幅": -37.04,
        "开盘价": 0.4,
        "最高价": 0.51,
        "最低价": 0.31,
        "昨收价": 0.54,
        "总市值": 5670099.0,
        "市盈率": -0.16,
        "成交量": 7034713.0,
        "成交额": 2902964.0,
        "振幅": 37.04,
        "换手率": 42.18,
        "symbol": "105.TRKA"
    },
    {
        "序号": 11720,
        "name": "Nauticus Robotics Inc Wt",
        "最新价": 0.06,
        "涨跌额": -0.04,
        "涨跌幅": -40.0,
        "开盘价": 0.06,
        "最高价": 0.1,
        "最低价": 0.06,
        "昨收价": 0.1,
        "总市值": null,
        "市盈率": null,
        "成交量": 36145.0,
        "成交额": 3027.0,
        "振幅": 40.0,
        "换手率": null,
        "symbol": "105.KITTW"
    },
    {
        "序号": 11721,
        "name": "Clene Inc Wt",
        "最新价": 0.03,
        "涨跌额": -0.02,
        "涨跌幅": -40.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.03,
        "昨收价": 0.05,
        "总市值": null,
        "市盈率": null,
        "成交量": 3340.0,
        "成交额": 87.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.CLNNW"
    },
    {
        "序号": 11722,
        "name": "蓝鸟生物",
        "最新价": 2.86,
        "涨跌额": -1.95,
        "涨跌幅": -40.54,
        "开盘价": 5.1,
        "最高价": 5.53,
        "最低价": 2.69,
        "昨收价": 4.81,
        "总市值": 312701563.0,
        "市盈率": -3.43,
        "成交量": 54126886.0,
        "成交额": 175449286.0,
        "振幅": 59.04,
        "换手率": 49.5,
        "symbol": "105.BLUE"
    },
    {
        "序号": 11723,
        "name": "金生游乐",
        "最新价": 1.36,
        "涨跌额": -0.96,
        "涨跌幅": -41.38,
        "开盘价": 1.82,
        "最高价": 1.82,
        "最低价": 1.33,
        "昨收价": 2.32,
        "总市值": 70380000.0,
        "市盈率": 4.86,
        "成交量": 7824443.0,
        "成交额": 11824834.0,
        "振幅": 21.12,
        "换手率": 15.12,
        "symbol": "105.GDHG"
    },
    {
        "序号": 11724,
        "name": "Vistra Corp Wt",
        "最新价": 0.04,
        "涨跌额": -0.03,
        "涨跌幅": -42.86,
        "开盘价": 0.04,
        "最高价": 0.04,
        "最低价": 0.04,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 335.0,
        "成交额": 12.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "106.VST_WS_A"
    },
    {
        "序号": 11725,
        "name": "BlackSky Technology Inc Wt",
        "最新价": 0.04,
        "涨跌额": -0.03,
        "涨跌幅": -42.86,
        "开盘价": 0.05,
        "最高价": 0.07,
        "最低价": 0.04,
        "昨收价": 0.07,
        "总市值": null,
        "市盈率": null,
        "成交量": 29590.0,
        "成交额": 1576.0,
        "振幅": 42.86,
        "换手率": null,
        "symbol": "106.BKSY_WS"
    },
    {
        "序号": 11726,
        "name": "Cyngn Inc",
        "最新价": 0.21,
        "涨跌额": -0.19,
        "涨跌幅": -47.5,
        "开盘价": 0.21,
        "最高价": 0.23,
        "最低价": 0.2,
        "昨收价": 0.4,
        "总市值": 8778618.0,
        "市盈率": -0.38,
        "成交量": 31876027.0,
        "成交额": 6674527.0,
        "振幅": 7.5,
        "换手率": 76.25,
        "symbol": "105.CYN"
    },
    {
        "序号": 11727,
        "name": "Vision Sensing Acquisition Corp",
        "最新价": 0.01,
        "涨跌额": -0.01,
        "涨跌幅": -50.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 1511.0,
        "成交额": 18.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.VSACW"
    },
    {
        "序号": 11728,
        "name": "Revelation Biosciences Inc Wt",
        "最新价": 0.01,
        "涨跌额": -0.01,
        "涨跌幅": -50.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 38460.0,
        "成交额": 421.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.REVBW"
    },
    {
        "序号": 11729,
        "name": "Presto Automation Inc Wt",
        "最新价": 0.02,
        "涨跌额": -0.02,
        "涨跌幅": -50.0,
        "开盘价": 0.03,
        "最高价": 0.03,
        "最低价": 0.02,
        "昨收价": 0.04,
        "总市值": null,
        "市盈率": null,
        "成交量": 5616.0,
        "成交额": 140.0,
        "振幅": 25.0,
        "换手率": null,
        "symbol": "105.PRSTW"
    },
    {
        "序号": 11730,
        "name": "DP Cap Acquisition Corp I Wt",
        "最新价": 0.01,
        "涨跌额": -0.01,
        "涨跌幅": -50.0,
        "开盘价": 0.01,
        "最高价": 0.01,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 657.0,
        "成交额": 7.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.DPCSW"
    },
    {
        "序号": 11731,
        "name": "Complete Solaria Inc Wt",
        "最新价": 0.01,
        "涨跌额": -0.01,
        "涨跌幅": -50.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": null,
        "市盈率": null,
        "成交量": 15889.0,
        "成交额": 226.0,
        "振幅": 50.0,
        "换手率": null,
        "symbol": "105.CSLRW"
    },
    {
        "序号": 11732,
        "name": "Bit Brother Ltd-A",
        "最新价": 0.01,
        "涨跌额": -0.01,
        "涨跌幅": -50.0,
        "开盘价": 0.02,
        "最高价": 0.02,
        "最低价": 0.01,
        "昨收价": 0.02,
        "总市值": 6241438.0,
        "市盈率": -0.07,
        "成交量": 671316832.0,
        "成交额": 10108688.0,
        "振幅": 50.0,
        "换手率": 107.56,
        "symbol": "105.BETS"
    },
    {
        "序号": 11733,
        "name": "African Agriculture Holdings In",
        "最新价": 0.03,
        "涨跌额": -0.03,
        "涨跌幅": -50.0,
        "开盘价": 0.05,
        "最高价": 0.07,
        "最低价": 0.03,
        "昨收价": 0.06,
        "总市值": null,
        "市盈率": null,
        "成交量": 28625.0,
        "成交额": 1612.0,
        "振幅": 66.67,
        "换手率": null,
        "symbol": "105.AAGRW"
    },
    {
        "序号": 11734,
        "name": "African Agriculture Holdings In",
        "最新价": 2.3,
        "涨跌额": -2.4,
        "涨跌幅": -51.06,
        "开盘价": 3.89,
        "最高价": 3.98,
        "最低价": 2.21,
        "昨收价": 4.7,
        "总市值": 144287841.0,
        "市盈率": -31.88,
        "成交量": 1148978.0,
        "成交额": 3248693.0,
        "振幅": 37.66,
        "换手率": 1.83,
        "symbol": "105.AAGR"
    },
    {
        "序号": 11735,
        "name": "EUDA Health Holdings Ltd Wt",
        "最新价": 0.05,
        "涨跌额": -0.1,
        "涨跌幅": -66.67,
        "开盘价": 0.05,
        "最高价": 0.05,
        "最低价": 0.05,
        "昨收价": 0.15,
        "总市值": null,
        "市盈率": null,
        "成交量": 3661.0,
        "成交额": 192.0,
        "振幅": 0.0,
        "换手率": null,
        "symbol": "105.EUDAW"
    }
]