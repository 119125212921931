import KLineModel from '../PF/KLineModel'
import dayjs from 'dayjs'

function getkLineModels(code, market, startDate, endDate, period, callback) {
    // 走日线接口
    if (['1d', '1w', '1M'].indexOf(period) != -1) {
        // 调用tx接口
        requestTXDay(code, market, startDate, endDate, period, callback)
    }
    // 走分钟接口
    if (['m1', 'm5', 'm15', 'm30', 'm60'].indexOf(period) != -1) {
        requestTXMin(code, market, startDate, endDate, period, callback)
    }
}

// 腾讯日，周，月线 (A股，美股)
// https://web.ifzq.gtimg.cn/appstock/app/fqkline/get?param=sh600519,day,2020-3-1,2021-3-1,500,qfq
function requestTXDay(code, market, startDate, endDate, period, callback) {
    // 根据市场拼接符号
    let symbol = `sh${code}`
    if (market == 'us') {
        symbol = `us${code}.OQ`
    } else if (market == 'hk') {
        symbol = `hk${code}`
    }
    // 根据period拼接frequency
    let frequency = 'day'
    if (period == '1d') {
        frequency = 'day'
    } else if (period == '1w') {
        frequency = 'week'
    } else if (period == '1M') {
        frequency = 'month'
    }

    // 根据周期与日期算count
    if (endDate == null) {
        endDate = new Date()
    }
    let count = 0
    if (frequency == 'day') {
        count = getDaysDifference(endDate, startDate)
    } else if (frequency == 'week') {
        count = getWeeksDifference(endDate, startDate)
    } else if (frequency == 'month') {
        count = getMonthsDifference(endDate, startDate)
    }
    // 结束日期
    let end_date = dayjs(endDate).format('YYYY-MM-DD')
    fetch(`https://web.ifzq.gtimg.cn/appstock/app/fqkline/get?param=${symbol},${frequency},,${end_date},${count},qfq`)
        .then((response) => response.json())
        .then(data => {
            let frequencyKey = `qfq${frequency}`
            let items = data['data'][symbol][frequencyKey]
            if (items == null) {
                items = data['data'][symbol][frequency]
            }
            // console.log(responseJson)
            // let items = data['data']['items']
            let kLineModels = []
            for (let i = 0; i < items.length; i++) {
                let item = items[i]

                let trade_date = item[0]
                let open = parseFloat(item[1])
                let close = parseFloat(item[2])
                let high = parseFloat(item[3])
                let low = parseFloat(item[4])
                let volume = parseFloat(item[4])

                let kline = new KLineModel(trade_date, open, close, high, low, volume)
                kLineModels.push(kline)
            }
            callback(kLineModels)
        })
}
// 腾讯历史分钟线 A股与美股分开了
// 向前获取多少数据count
function requestTXMin(code, market, startDate, endDate, period, callback) {

    let frequency = period

    // 根据周期与日期算count
    if (endDate == null) {
        endDate = new Date()
    }
    let count = 0
    if (frequency == 'm5') {
        count = countSomeMinutes(endDate, startDate, 5)
    } else if (frequency == 'm15') {
        count = countSomeMinutes(endDate, startDate, 15)
    } else if (frequency == 'm30') {
        count = countSomeMinutes(endDate, startDate, 30)
    } else if (frequency == 'm60') {
        count = countSomeMinutes(endDate, startDate, 60)
    }
    // 上证
    if (market == 'sh') {
        let symbol = `sh${code}`
        // 结束日期
        let end_date = dayjs(endDate).format('YYYY-MM-DD')
        fetch(`https://ifzq.gtimg.cn/appstock/app/kline/mkline?param=${symbol},${frequency},,${count}`)
            .then((response) => response.json())
            .then(data => {
                let items = data['data'][symbol][frequency]

                let kLineModels = []
                for (let i = 0; i < items.length; i++) {
                    let item = items[i]

                    let trade_date = item[0]
                    let open = item[1]
                    let close = item[2]
                    let high = item[3]
                    let low = item[4]
                    let volume = item[4]

                    let kline = new KLineModel(trade_date, open, close, high, low, volume)
                    kLineModels.push(kline)
                }
                callback(kLineModels)
            })
    } else if (market == 'us' || market == 'hk') {
        // 美股的分钟现在还无法确定？？？
        // 港股哪能当天的实收的
        let end_date = dayjs(endDate).format('YYYY-MM-DD')
        let symbol = `hk${code}`
        if (market == 'us') {
            symbol = `us${code}.OQ`
        }
        // let us_tencent_minuts = `https://web.ifzq.gtimg.cn/appstock/app/minute/query?code=${symbol}`

        // fetch(us_tencent_minuts)
        //     .then((response) => response.json())
        //     .then(data => {
        //         let items = data['data'][symbol][frequency]
        //         // console.log(responseJson)
        //         // let items = data['data']['items']
        //         let kLineModels = []
        //         for (let i = 0; i < items.length; i++) {
        //             let item = items[i]

        //             let trade_date = item[0]
        //             let open = item[1]
        //             let close = item[2]
        //             let high = item[3]
        //             let low = item[4]
        //             let volume = item[4]

        //             let kline = new KLineModel(trade_date, open, close, high, low, volume)
        //             kLineModels.push(kline)
        //         }
        //         callback(kLineModels)
        //     })
    }
}
function getDaysDifference(date1, date2) {
    // 确保date1和date2是Date对象
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        console.error("输入的必须是Date对象");
        return;
    }

    // 计算两个日期之间的毫秒差
    let timeDiff = Math.abs(date1.getTime() - date2.getTime());

    // 计算天数差
    let daysDiff = Math.ceil(timeDiff / (24 * 60 * 60 * 1000));

    // 一周只有5天交易数据
    let realDaysDiff = daysDiff - Math.floor(daysDiff / 7) * 2

    return realDaysDiff;
}

function getWeeksDifference(date1, date2) {
    // 计算两个日期之间相差的毫秒数
    const diff = Math.abs(date1.getTime() - date2.getTime());

    // 计算相差的秒数
    const diffSeconds = Math.floor(diff / 1000);

    // 计算相差的分钟数
    const diffMinutes = Math.floor(diffSeconds / 60);

    // 计算相差的小时数
    const diffHours = Math.floor(diffMinutes / 60);

    // 计算相差的天数
    const diffDays = Math.floor(diffHours / 24);

    // 计算相差的周数
    const diffWeeks = Math.floor(diffDays / 7);

    return diffWeeks;
}

function getMonthsDifference(date1, date2) {
    // 计算两个日期之间相差的毫秒数
    const diff = Math.abs(date1.getTime() - date2.getTime());

    // 计算相差的秒数
    const diffSeconds = Math.floor(diff / 1000);

    // 计算相差的分钟数
    const diffMinutes = Math.floor(diffSeconds / 60);

    // 计算相差的小时数
    const diffHours = Math.floor(diffMinutes / 60);

    // 计算相差的天数
    const diffDays = Math.floor(diffHours / 24);

    // 计算相差的月数
    const diffMonths = Math.floor(diffDays / 30);

    return diffMonths;
}

function countSomeMinutes(date1, date2, minutes) {
    // 确保 date1 是较早的日期
    if (date1 > date2) {
        [date1, date2] = [date2, date1];
    }

    // 计算两个日期之间的秒数差
    const diffSeconds = Math.abs(date2.getTime() - date1.getTime()) / 1000;

    // 计算有多少个5分钟
    const diffMinutes = Math.floor(diffSeconds / minutes);

    return diffMinutes;
}

export { getkLineModels }