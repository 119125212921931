export default class PFPoint {
    constructor(type, pfPrice, col) {
        // type
        // 1 表示x
        // 0 表示o
        this.type = type
        this.pfPrice = pfPrice
        this.col = col // 所属列
        this.volume = 0 // 成交量
        this.putIndex = 0 // 在同一列生成的顺序
    }
}