import PFMeasureDisplayPoint from "./PFMeasureDisplayPoint"
import PFOneBaseModel from './PFOneBaseModel'
// 表示一个测量
export default class PFOneMeasure extends PFOneBaseModel {
    constructor(startNode, endNode, direction, displayNodes, pointWidth) {
        super()
        // leftNode表示一定是左边的点,rightNode表示一定是右边的点
        this.leftNode = startNode
        this.rightNode = endNode
        // 调整左右节点
        if (startNode.j > endNode.j) {
            this.leftNode = endNode
            this.rightNode = startNode
        }
        // 方向
        this.direction = direction
        // 全部的显示数据
        this.displayNodes = displayNodes
        // 每格的宽度
        this.pointWidth = pointWidth
        if(this.pointWidth == null){
            this.pointWidth = 21
        }
       

        // 线条的宽度
        this.lineHeight = 2

        // 开始计数点
        this.measureStartNode = null
        // 结束计数点
        this.measureEndNode = null

        // 向上
        // 最低价
        this.upMeasureLowestNode = null
        // 向上的最大目标位
        this.upMeasureMaxTargetNode = null
        // 最上的最小目标位
        this.upMeasureMinTargetNode = null

        // 向下
        // 最高价
        this.downMeasureHightestNode = null
        // 向下的最大目标位
        this.downMeasureMaxTargetNode = null
        // 向下的最小目标位
        this.downMeasureMinTargetNode = null

        // 显示开始点与结束点
        this.measureStartNodePoint = null
        this.measureEndNodePoint = null

        // 向上测试的低点
        this.upMeasureLowestNodeEndPoint = null
        // 向上测试的最大目标点
        this.upMeasureMaxTargetNodeStartPoint = null
        // 向上测试的最小目标点
        this.upMeasureMinTargetNodeStartPoint = null

        // 向下测试的最高点
        this.downMeasureHighestNodeEndPoint = null
        // 向下测试的最大目标点
        this.downMeasureMaxTargetNodeStartPoint = null
        // 向下测试的最小欧目标点
        this.downMeasureMinTargetNodeStartPoint = null
    }
    // 使用displayNodes开始计算
    caculateTarget() {
        // 测试的启点
        this.measureStartNode = this.displayNodes[this.rightNode.i][this.leftNode.j]
        // 测试的结束点
        this.measureEndNode = this.rightNode

        if (this.direction == 1) {
            let minRangePriceNode = this.measureEndNode
            for (let j = this.measureStartNode.j; j <= this.measureEndNode.j; j++) {
                // 开始向下寻找最小值
                let i = minRangePriceNode.i
                while (i < this.displayNodes.length) {
                    let node = this.displayNodes[i][j]
                    if (node.pfPoint == null || node.pfPoint == 'undefine') {
                        break
                    }
                    if (node.i > minRangePriceNode.i) {
                        minRangePriceNode = node
                    }
                    i += 1
                }
            }
            // 区间最小值
            this.upMeasureLowestNode = minRangePriceNode

            // 最大目标位
            let maxDiff = this.measureEndNode.j - this.measureStartNode.j
            // 向上计数
            let targetI = this.measureEndNode.i - maxDiff
            if (targetI <= 0) {
                targetI = 0
            }
            // 最大目标位
            this.upMeasureMaxTargetNode = this.displayNodes[targetI][this.measureEndNode.j]

            // 最小目标位
            let minDiff = maxDiff - (this.upMeasureLowestNode.i - this.measureEndNode.i)
            let minTargetI = this.measureEndNode.i - minDiff
            if (minTargetI <= 0) {
                minTargetI = 0
            }
            this.upMeasureMinTargetNode = this.displayNodes[minTargetI][this.measureEndNode.j]
        } else {
            // 向下测试
            let maxRangePriceNode = this.measureEndNode
            for (let j = this.measureStartNode.j; j <= this.measureEndNode.j; j++) {
                // 开始向上寻找最大值
                let i = maxRangePriceNode.i
                while (i >= 0) {
                    let node = this.displayNodes[i][j]
                    if (node.pfPoint == null || node.pfPoint == 'undefine') {
                        break
                    }
                    if (node.i < maxRangePriceNode.i) {
                        maxRangePriceNode = node
                    }
                    i -= 1
                }
            }
            // 区间最大值
            this.downMeasureHightestNode = maxRangePriceNode

            // 最大目标位
            let maxDiff = this.measureEndNode.j - this.measureStartNode.j
            // 向下计数
            let targetI = this.measureEndNode.i + maxDiff
            if (targetI > this.displayNodes.length) {
                targetI = this.displayNodes.length - 1
            }
            this.downMeasureMaxTargetNode = this.displayNodes[targetI][this.measureEndNode.j]

            // 最小目标位
            let minDiff = maxDiff - (this.measureEndNode.i - this.downMeasureHightestNode.i)
            let minTargetI = this.measureEndNode.i + minDiff
            if (minTargetI > this.displayNodes.length) {
                minTargetI = this.displayNodes.length - 1
            }
            this.downMeasureMinTargetNode = this.displayNodes[minTargetI][this.measureEndNode.j]
        }
    }
    // 渲染层的计算
    displayTarget() {
        let pointWidth = this.pointWidth

        // 目标测算起点
        let measureStartNodePointX = this.measureStartNode.j * pointWidth + pointWidth / 2.0
        let measureStartNodePointY = this.measureStartNode.i * pointWidth + pointWidth / 2.0
        this.measureStartNodePoint = new PFMeasureDisplayPoint(measureStartNodePointX, measureStartNodePointY)

        // 目标测试终点
        let measureEndNodePointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
        let measureEndNodePointY = this.measureEndNode.i * pointWidth + pointWidth / 2.0
        this.measureEndNodePoint = new PFMeasureDisplayPoint(measureEndNodePointX, measureEndNodePointY)

        // 方向向上
        if (this.direction == 1) {
            // 最低点的终点
            let upMeasureLowestEndPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let upMeasureLowestEndPointY = this.upMeasureLowestNode.i * pointWidth + pointWidth / 2.0
            this.upMeasureLowestNodeEndPoint = new PFMeasureDisplayPoint(upMeasureLowestEndPointX, upMeasureLowestEndPointY)

            // 最大目标位的起始点
            let upMeasureMaxTargetNodeStartPointX = this.upMeasureMaxTargetNode.j * pointWidth + pointWidth / 2.0
            let upMeasureMaxTargetNodeStartPointY = this.upMeasureMaxTargetNode.i * pointWidth + pointWidth / 2.0
            this.upMeasureMaxTargetNodeStartPoint = new PFMeasureDisplayPoint(upMeasureMaxTargetNodeStartPointX, upMeasureMaxTargetNodeStartPointY)

            // 最小目标位的起始点
            let upMeasureMinTargetNodeStartPointX = this.upMeasureMinTargetNode.j * pointWidth + pointWidth / 2.0
            let upMeasureMinTargetNodeStartPointY = this.upMeasureMinTargetNode.i * pointWidth + pointWidth / 2.0
            this.upMeasureMinTargetNodeStartPoint = new PFMeasureDisplayPoint(upMeasureMinTargetNodeStartPointX, upMeasureMinTargetNodeStartPointY)

        } else {
            // 最高点的终点
            let downMeasureHighestEndPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let downMeasureHighestEndPointY = this.downMeasureHightestNode.i * pointWidth + pointWidth / 2.0
            this.downMeasureHighestNodeEndPoint = new PFMeasureDisplayPoint(downMeasureHighestEndPointX, downMeasureHighestEndPointY)

            // 最大目标位的起始点
            let downMeasureMaxTargetNodeStartPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let downMeasureMaxTargetNodeStartPointY = this.downMeasureMaxTargetNode.i * pointWidth + pointWidth / 2.0
            this.downMeasureMaxTargetNodeStartPoint = new PFMeasureDisplayPoint(downMeasureMaxTargetNodeStartPointX, downMeasureMaxTargetNodeStartPointY)

            // 最小目标位的起始点
            let donwMeasureMinTargetNodeStartPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let donwMeasureMinTargetNodeStartPointY = this.downMeasureMinTargetNode.i * pointWidth + pointWidth / 2.0
            this.downMeasureMinTargetNodeStartPoint = new PFMeasureDisplayPoint(donwMeasureMinTargetNodeStartPointX, donwMeasureMinTargetNodeStartPointY)
        }
    }
    isPointIn(point) {
        super.isPointIn(point)
        let x = point.x
        let y = point.y
        let pointWidth = this.pointWidth
        let lineHeight = this.lineHeight
        // 目标测算起点
        let measureStartNodePointX = this.measureStartNode.j * pointWidth + pointWidth / 2.0
        let measureStartNodePointY = this.measureStartNode.i * pointWidth + pointWidth / 2.0


        // 目标测试终点
        let measureEndNodePointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
        let measureEndNodePointY = this.measureEndNode.i * pointWidth + pointWidth / 2.0


        // 方向向上
        if (this.direction == 1) {
            // 最低点
            let upMeasureLowestEndPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let upMeasureLowestEndPointY = this.upMeasureLowestNode.i * pointWidth + pointWidth / 2.0

            // 最大目标位的起始点
            let upMeasureMaxTargetNodeStartPointX = this.upMeasureMaxTargetNode.j * pointWidth + pointWidth / 2.0
            let upMeasureMaxTargetNodeStartPointY = this.upMeasureMaxTargetNode.i * pointWidth + pointWidth / 2.0

            // 最小目标位的起始点
            let upMeasureMinTargetNodeStartPointX = this.upMeasureMinTargetNode.j * pointWidth + pointWidth / 2.0
            let upMeasureMinTargetNodeStartPointY = this.upMeasureMinTargetNode.i * pointWidth + pointWidth / 2.0

            // 最左边点到最右边点
            var xValid = x > measureStartNodePointX && x < measureEndNodePointX
            var yValid = y > measureStartNodePointY - lineHeight / 2.0 && measureStartNodePointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }

            // 最低点到最高点的竖线
            xValid = x > upMeasureLowestEndPointX - lineHeight / 2.0 && x < upMeasureLowestEndPointX + lineHeight / 2.0
            yValid = y > upMeasureMaxTargetNodeStartPointY && y < upMeasureLowestEndPointY
            if (xValid && yValid) {
                return true
            }
            // 最大目标位的横线
            xValid = x > upMeasureMaxTargetNodeStartPointX && x < upMeasureMaxTargetNodeStartPointX + pointWidth * 2
            yValid = y > upMeasureMaxTargetNodeStartPointY - lineHeight / 2.0 && y < upMeasureMaxTargetNodeStartPointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }
            // 最小目标位横线
            xValid = x > upMeasureMinTargetNodeStartPointX && x < upMeasureMinTargetNodeStartPointX + pointWidth * 2
            yValid = y > upMeasureMinTargetNodeStartPointY - lineHeight / 2.0 && y < upMeasureMinTargetNodeStartPointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }
            // 最低价的横线
            xValid = x > upMeasureLowestEndPointX - pointWidth * 2 && x < upMeasureLowestEndPointX
            yValid = y > upMeasureLowestEndPointY - lineHeight / 2.0 && y < upMeasureLowestEndPointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }

            return false

        } else {
            // 最高点的终点
            let downMeasureHighestEndPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let downMeasureHighestEndPointY = this.downMeasureHightestNode.i * pointWidth + pointWidth / 2.0
            // this.downMeasureHighestNodeEndPoint = new PFMeasureDisplayPoint(downMeasureHighestEndPointX, downMeasureHighestEndPointY)

            // 最大目标位的起始点
            let downMeasureMaxTargetNodeStartPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let downMeasureMaxTargetNodeStartPointY = this.downMeasureMaxTargetNode.i * pointWidth + pointWidth / 2.0
            // this.downMeasureMaxTargetNodeStartPoint = new PFMeasureDisplayPoint(downMeasureMaxTargetNodeStartPointX, downMeasureMaxTargetNodeStartPointY)

            // 最小目标位的起始点
            let donwMeasureMinTargetNodeStartPointX = this.measureEndNode.j * pointWidth + pointWidth / 2.0
            let donwMeasureMinTargetNodeStartPointY = this.downMeasureMinTargetNode.i * pointWidth + pointWidth / 2.0
            // this.downMeasureMinTargetNodeStartPoint = new PFMeasureDisplayPoint(donwMeasureMinTargetNodeStartPointX, donwMeasureMinTargetNodeStartPointY)


            // 1. 最左边点到最右边点
            var xValid = x > measureStartNodePointX && x < measureEndNodePointX
            var yValid = y > measureStartNodePointY - lineHeight / 2.0 && y < measureStartNodePointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }

            // 2.最低点到最高点的竖线
            xValid = x > downMeasureMaxTargetNodeStartPointX - lineHeight / 2.0 && x < downMeasureMaxTargetNodeStartPointX + lineHeight / 2.0
            yValid = y > downMeasureHighestEndPointY && y < downMeasureMaxTargetNodeStartPointY
            if (xValid && yValid) {
                return true
            }
            // 3. 最高价的小横线
            xValid = x > downMeasureHighestEndPointX - pointWidth * 2 && x < downMeasureHighestEndPointX
            yValid = y > downMeasureHighestEndPointY - lineHeight / 2.0 && y < downMeasureHighestEndPointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }

            // 4. 最小目标位横线
            xValid = x > donwMeasureMinTargetNodeStartPointX && x < donwMeasureMinTargetNodeStartPointX + pointWidth * 2
            yValid = y > donwMeasureMinTargetNodeStartPointY - lineHeight / 2.0 && donwMeasureMinTargetNodeStartPointY + lineHeight / 2.0
            if (xValid && yValid) {
                return true
            }

            // 5. 最大目标横线
            xValid = x > downMeasureMaxTargetNodeStartPointX - pointWidth * 2 && x < downMeasureMaxTargetNodeStartPointX
            yValid = y > downMeasureMaxTargetNodeStartPointY - lineHeight / 2.0 && y < downMeasureMaxTargetNodeStartPointY
            if (xValid && yValid) {
                return true
            }
            return false
        }
    }

    draw(context, selected) {
        super.draw(context, selected)
        let measure = this
        if (measure.direction == 1) {
            this.drawUpMeasure(context, measure, selected)
        } else {
            this.drawDownMeasure(context, measure, selected)
        }
    }
    drawUpMeasure(context, measure, selected) {

        let measureColor = `rgb(181,98,101`
        let lineWidth = 2

        // 绘制5条线
        if (context != null) {
            // 从左到右的一条线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.measureStartNodePoint.x, measure.measureStartNodePoint.y)
            context.lineTo(measure.measureEndNodePoint.x, measure.measureEndNodePoint.y)
            context.closePath()
            context.stroke()

            // 从上到下一条线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.upMeasureMaxTargetNodeStartPoint.x, measure.upMeasureMaxTargetNodeStartPoint.y)
            context.lineTo(measure.upMeasureLowestNodeEndPoint.x, measure.upMeasureLowestNodeEndPoint.y)
            context.closePath()
            context.stroke()


            // 最大目标位小横线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.upMeasureMaxTargetNodeStartPoint.x, measure.upMeasureMaxTargetNodeStartPoint.y)
            context.lineTo(measure.upMeasureMaxTargetNodeStartPoint.x + this.pointWidth * 2, measure.upMeasureMaxTargetNodeStartPoint.y)
            context.closePath()
            context.stroke()

            // 最小目标位小横向
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.upMeasureMinTargetNodeStartPoint.x, measure.upMeasureMinTargetNodeStartPoint.y)
            context.lineTo(measure.upMeasureMinTargetNodeStartPoint.x + this.pointWidth * 2, measure.upMeasureMinTargetNodeStartPoint.y)
            context.closePath()
            context.stroke()

            // 最低价小横线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.upMeasureLowestNodeEndPoint.x, measure.upMeasureLowestNodeEndPoint.y)
            context.lineTo(measure.upMeasureLowestNodeEndPoint.x - this.pointWidth * 2, measure.upMeasureLowestNodeEndPoint.y)
            context.closePath()
            context.stroke()
            if (selected) {
                this.drawSelectedDot(context, measure)
            }
        }
    }
    drawDownMeasure(context, measure, selected) {
        let measureColor = `rgb(124,164,219)`
        let lineWidth = 2

        // 绘制5条线
        if (context != null) {
            // 从左到右的一条线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.measureStartNodePoint.x, measure.measureStartNodePoint.y)
            context.lineTo(measure.measureEndNodePoint.x, measure.measureEndNodePoint.y)
            context.closePath()
            context.stroke()

            // 从上到下
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.downMeasureHighestNodeEndPoint.x, measure.downMeasureHighestNodeEndPoint.y)
            context.lineTo(measure.downMeasureMaxTargetNodeStartPoint.x, measure.downMeasureMaxTargetNodeStartPoint.y)
            context.closePath()
            context.stroke()

            // 最高价格的小横线
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.downMeasureHighestNodeEndPoint.x - this.pointWidth * 2, measure.downMeasureHighestNodeEndPoint.y)
            context.lineTo(measure.downMeasureHighestNodeEndPoint.x, measure.downMeasureHighestNodeEndPoint.y)
            context.closePath()
            context.stroke()

            // 最小目标位
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.downMeasureMinTargetNodeStartPoint.x, measure.downMeasureMinTargetNodeStartPoint.y)
            context.lineTo(measure.downMeasureMinTargetNodeStartPoint.x + this.pointWidth * 2, measure.downMeasureMinTargetNodeStartPoint.y)
            context.closePath()
            context.stroke()

            // 最大目标位
            context.beginPath()
            context.strokeStyle = measureColor
            context.lineWidth = lineWidth
            context.moveTo(measure.downMeasureMaxTargetNodeStartPoint.x, measure.downMeasureMaxTargetNodeStartPoint.y)
            context.lineTo(measure.downMeasureMaxTargetNodeStartPoint.x + this.pointWidth * 2, measure.downMeasureMaxTargetNodeStartPoint.y)
            context.closePath()
            context.stroke()

            if (selected) {
                this.drawOneDot(context,measure.measureStartNodePoint)
                this.drawOneDot(context,measure.measureEndNodePoint)
            }
        }
    }

    drawSelectedDot(context, measure) {
        this.drawOneDot(context, measure.measureStartNodePoint)
        this.drawOneDot(context, measure.measureEndNodePoint)
    }
}