
export default class  KLineModel {
    constructor(tradeTime,open,close,high,low, volume) {
        this.tradeTime = tradeTime // 交易发生的日期
        this.open = open // 开盘
        this.close = close // 收盘
        this.high = high // 最高价 
        this.low = low // 最低价
        this.volume = volume // 成交量
    }
}
