import React, { Component } from 'react'
import KLinePointView from './KLinePointView'
import KLineDataLogic from './KLineDataLogic'
import KLineVolumeView from './KLineVolumeView'
import PFEditView from '../PF/PFEditView'
import PFScaleView from '../PF/PFScaleView'
import { Empty } from 'antd'
import KLineWaveResultView from './KLineWaveResultView'
import './KLineContentView.css'
import PFOperateMode from '../PF/PFOperateMode'
import { SwapOutlined } from '@ant-design/icons';
import KLineVolumeYTitleView from './KLineVolumeYTitleView'
export default class KLineContentView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      'refresh': false,
    }
  }
  // 组件挂载了
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    if (this.pointContainer != null) {
      this.pointContainer.addEventListener("scroll", this.handleScroll);
    }
    if (this.volumeContainer != null) {
      this.volumeContainer.addEventListener("scroll", this.handleVolumeScroll);
    }
  }
  // props更新了
  componentWillReceiveProps(nextProps) {
    let needRefresh =  
    (nextProps.pfConfigData.waveBase != this.props.pfConfigData.waveBase) || nextProps.kLineLogic != this.props.kLineLogic
    
    if(needRefresh){
      // 计算新的Props
      this.handleNextPropsResize(nextProps)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleScroll = () => {
    var scrollLeft = this.pointContainer.scrollLeft;    // 获取垂直滚动位置
    if (this.volumeContainer != null) {
      this.volumeContainer.scrollTo({ left: scrollLeft });
    }
  }
  handleVolumeScroll = () => {
    var scrollLeft = this.volumeContainer.scrollLeft;    // 获取垂直滚动位置
    if (this.pointContainer != null) {
      this.pointContainer.scrollTo({ left: scrollLeft });
    }
  }
  setVolumeScrollCallBack(volumeContainer) {
    this.volumeContainer = volumeContainer
  }
  handleNextPropsResize = (nextProps) => {
    let { kLineLogic } = nextProps
    this.reCaculate(kLineLogic)
  }
  handleResize = () => {
    let { kLineLogic } = this.props
    this.reCaculate(kLineLogic)
  }
  reCaculate(kLineLogic) {
    // 导航栏高度
    let navHeight = 44
    // 导航栏与成交量容器高度
    let pointAndVolumeContainerHeight = window.innerHeight - navHeight - 5
    // 坐标宽度
    let priceAxisYContainerWeight = 70
    let rightPriceAxisYContainerWeight = priceAxisYContainerWeight
    // 点数图容器宽度
    let pointContainerWidth = window.innerWidth - priceAxisYContainerWeight - rightPriceAxisYContainerWeight - 10 * 2
    // 成交量高度
    let volumeContainerHeight = pointAndVolumeContainerHeight / 5.0
    // 成交量与点数图分割线高度
    let pointVolumeSeparateLineHeight = 1
    // 点数图容器高度
    let pointContainerHeight = pointAndVolumeContainerHeight - volumeContainerHeight - pointVolumeSeparateLineHeight
    // 依据宽度重新计算
    kLineLogic.caculateKLineDisplay(pointContainerWidth, pointContainerHeight)

    this.setState({
      'pointContainerWidth': pointContainerWidth,
      'pointContainerHeight': pointContainerHeight,
      'volumeContainerHeight': volumeContainerHeight,
      'pointVolumeSeparateLineHeight': pointVolumeSeparateLineHeight,
      'priceAxisYContainerWeight': priceAxisYContainerWeight,
      'rightPriceAxisYContainerWeight': rightPriceAxisYContainerWeight
    })
    this.refreshView()
  }

  refreshView() {
    let { refresh } = this.state
    this.setState({
      'refresh': !refresh
    })
  }

  // 缩放显示
  scalePointWidthCallBack(plusOrSub) {
    this.props.kLineLogic.makeScale(plusOrSub)
    this.handleResize()
  }
  // 切换成交量
  switchWaveVolume() {
    let { kLineLogic } = this.props
    kLineLogic.isWaveVolume = !kLineLogic.isWaveVolume
    this.refreshView()
  }

  operateModeResetCallBack() {
    this.props.pfConfigDataChangedCallBack({ 'operateMode': PFOperateMode.NONE })
  }

  // 已经选择
  hadSelectElementCallBack(selectedElement) {
    this.setState({
      'selectedElement': selectedElement
    })
  }

  // 删除当前选中的measure
  deleteElementCallBack() {
    let { selectedElement } = this.state
    this.props.kLineLogic.deleteElement(selectedElement)
    this.setState({
      'selectedElement': null
    })
  }

  render() {
    let {
      pointContainerWidth,
      pointContainerHeight,
      volumeContainerHeight,
      priceAxisYContainerWeight,
      rightPriceAxisYContainerWeight,
      pointVolumeSeparateLineHeight,
      selectedMeasure,
      selectedElement
    } = this.state

    let { kLineLogic } = this.props
    let { mode, operateMode } = this.props.pfConfigData


    // 真实的点数图高度
    let pointContentRealHeight = pointContainerHeight
    // 真实的点数图宽度
    let pointContentRealWidth = kLineLogic.displayNodes.length * kLineLogic.pointWidth
    
    // 是否显示编辑
    let isDisplayEdit = false
    if (selectedMeasure != null) {
      isDisplayEdit = true
    }

    if (selectedElement != null) {
      isDisplayEdit = true
    }

    return (
      <div className='point_content_points_frame'>
        <div className='point_content_y_points_y' style={{ height: pointContainerHeight }}>

          <div className='price-axis-y-left' style={{ width: priceAxisYContainerWeight, height: pointContentRealHeight }}>
            {
              kLineLogic.priceTitles.map((title) => (
                <div className='price_axis_y_item_left' style={{ height: 21, width: priceAxisYContainerWeight }}>
                  {title.price}
                </div>
              )
              )
            }
          </div>

          <div className='point_container'
            style={{ width: pointContentRealWidth, height: pointContentRealHeight }}
            ref={(scrollableRef) => { this.pointContainer = scrollableRef }}
          >
            <div className='point_content_points_inner'>
              <div className='point_content_row'>
                {
                  kLineLogic.displayNodes.map((node, index) => (
                    <KLinePointView node={node} index={index} realHeight={pointContentRealHeight} realWidth={kLineLogic.pointWidth}></KLinePointView>
                  ))
                }
              </div>
            </div>

            <KLineWaveResultView
              kLineLogic={kLineLogic}
              operateMode={operateMode}
              realWidth={pointContentRealWidth}
              realHeight={pointContentRealHeight}
              selectedElement={selectedElement}
              hadSelectElementCallBack={(selectedElement) => this.hadSelectElementCallBack(selectedElement)}
              operateModeResetCallBack={() => this.operateModeResetCallBack()}
            ></KLineWaveResultView>

          </div>

          {
            // 编辑
            isDisplayEdit ? <PFEditView selectedElement={selectedElement} deleteElementCallBack={() => this.deleteElementCallBack()}></PFEditView> : Empty
          }


          {/* 缩放 */}
          <PFScaleView
            rightMargin={rightPriceAxisYContainerWeight}
            bottomMargin={volumeContainerHeight + pointVolumeSeparateLineHeight + 10}
            scalePointWidthCallBack={(value) => this.scalePointWidthCallBack(value)}
          ></PFScaleView>

          <div className='price-axis-y-right' style={{ width: rightPriceAxisYContainerWeight, height: pointContentRealHeight }} >
            {
              kLineLogic.priceTitles.map((title) => (
                <div className='price_axis_y_item_right' style={{ height: 21, width: priceAxisYContainerWeight }}>
                  {title.price}
                  {/* <div style={{ width: priceAxisYContainerWeight }}>{title.price}</div> */}
                </div>
              )
              )
            }
          </div>

        </div>

        <div className='point_content_points_volume_line' style={{ height: pointVolumeSeparateLineHeight }}></div>

        <div className='point_volume_container'>
          <KLineVolumeYTitleView rightPriceAxisYContainerWeight={rightPriceAxisYContainerWeight} isRightY={false}></KLineVolumeYTitleView>

          <KLineVolumeView kLineLogic={kLineLogic}
            pointContentRealWidth={pointContentRealWidth}
            volumeViewHeight={volumeContainerHeight}
            setVolumeScrollCallBack={(scrollableRef) => { this.setVolumeScrollCallBack(scrollableRef) }}>
          </KLineVolumeView>

          <div className='KLineVolumeViewSwitch'>
            <SwapOutlined onClick={() => this.switchWaveVolume()} />
          </div>
          {/* 右边的title */}
          <KLineVolumeYTitleView rightPriceAxisYContainerWeight={rightPriceAxisYContainerWeight} isRightY={true}></KLineVolumeYTitleView>
        </div>
      </div>
    )
  }
}
