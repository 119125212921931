export const stock_symbol_hk = 
[
    {
        "序号": 1,
        "symbol": "00096",
        "name": "YUSEI",
        "最新价": 1.68,
        "涨跌额": 1.16,
        "涨跌幅": 223.08,
        "今开": 0.52,
        "最高": 1.68,
        "最低": 0.52,
        "昨收": 0.52,
        "成交量": 62000.0,
        "成交额": 34560.0
    },
    {
        "序号": 2,
        "symbol": "02323",
        "name": "融科控股",
        "最新价": 0.023,
        "涨跌额": 0.007,
        "涨跌幅": 43.75,
        "今开": 0.014,
        "最高": 0.023,
        "最低": 0.014,
        "昨收": 0.016,
        "成交量": 2500000.0,
        "成交额": 41430.0
    },
    {
        "序号": 3,
        "symbol": "02246",
        "name": "快狗打车",
        "最新价": 0.67,
        "涨跌额": 0.195,
        "涨跌幅": 41.05,
        "今开": 0.53,
        "最高": 0.74,
        "最低": 0.46,
        "昨收": 0.475,
        "成交量": 23869575.0,
        "成交额": 15708103.0
    },
    {
        "序号": 4,
        "symbol": "08621",
        "name": "METROPOLIS CAP",
        "最新价": 0.039,
        "涨跌额": 0.011,
        "涨跌幅": 39.29,
        "今开": 0.033,
        "最高": 0.04,
        "最低": 0.033,
        "昨收": 0.028,
        "成交量": 1632000.0,
        "成交额": 63832.0
    },
    {
        "序号": 5,
        "symbol": "01004",
        "name": "中国智慧能源",
        "最新价": 0.027,
        "涨跌额": 0.007,
        "涨跌幅": 35.0,
        "今开": 0.025,
        "最高": 0.039,
        "最低": 0.025,
        "昨收": 0.02,
        "成交量": 101910000.0,
        "成交额": 3290768.0
    },
    {
        "序号": 6,
        "symbol": "01822",
        "name": "中木国际",
        "最新价": 0.044,
        "涨跌额": 0.011,
        "涨跌幅": 33.33,
        "今开": 0.037,
        "最高": 0.044,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 870000.0,
        "成交额": 33870.0
    },
    {
        "序号": 7,
        "symbol": "08262",
        "name": "宏强控股",
        "最新价": 1.33,
        "涨跌额": 0.3,
        "涨跌幅": 29.13,
        "今开": 1.05,
        "最高": 1.33,
        "最低": 1.03,
        "昨收": 1.03,
        "成交量": 109370000.0,
        "成交额": 134517400.0
    },
    {
        "序号": 8,
        "symbol": "00627",
        "name": "福晟国际",
        "最新价": 0.91,
        "涨跌额": 0.2,
        "涨跌幅": 28.17,
        "今开": 0.91,
        "最高": 0.91,
        "最低": 0.91,
        "昨收": 0.71,
        "成交量": 25050.0,
        "成交额": 21980.0
    },
    {
        "序号": 9,
        "symbol": "00591",
        "name": "中国高精密",
        "最新价": 0.16,
        "涨跌额": 0.033,
        "涨跌幅": 25.98,
        "今开": 0.123,
        "最高": 0.166,
        "最低": 0.123,
        "昨收": 0.127,
        "成交量": 25072450.0,
        "成交额": 3881966.0
    },
    {
        "序号": 10,
        "symbol": "08519",
        "name": "佳民集团",
        "最新价": 0.085,
        "涨跌额": 0.017,
        "涨跌幅": 25.0,
        "今开": 0.08,
        "最高": 0.093,
        "最低": 0.077,
        "昨收": 0.068,
        "成交量": 7300000.0,
        "成交额": 600820.0
    },
    {
        "序号": 11,
        "symbol": "00802",
        "name": "中国钱包",
        "最新价": 0.051,
        "涨跌额": 0.01,
        "涨跌幅": 24.39,
        "今开": 0.038,
        "最高": 0.052,
        "最低": 0.038,
        "昨收": 0.041,
        "成交量": 15970557.0,
        "成交额": 641556.0
    },
    {
        "序号": 12,
        "symbol": "08026",
        "name": "朗华国际集团",
        "最新价": 0.54,
        "涨跌额": 0.105,
        "涨跌幅": 24.14,
        "今开": 0.48,
        "最高": 0.54,
        "最低": 0.415,
        "昨收": 0.435,
        "成交量": 19000.0,
        "成交额": 9040.0
    },
    {
        "序号": 13,
        "symbol": "08245",
        "name": "善裕集团控股",
        "最新价": 0.089,
        "涨跌额": 0.017,
        "涨跌幅": 23.61,
        "今开": 0.084,
        "最高": 0.09,
        "最低": 0.084,
        "昨收": 0.072,
        "成交量": 58180000.0,
        "成交额": 5229900.0
    },
    {
        "序号": 14,
        "symbol": "08300",
        "name": "皇玺集团",
        "最新价": 0.27,
        "涨跌额": 0.051,
        "涨跌幅": 23.29,
        "今开": 0.219,
        "最高": 0.27,
        "最低": 0.218,
        "昨收": 0.219,
        "成交量": 7640000.0,
        "成交额": 1788280.0
    },
    {
        "序号": 15,
        "symbol": "08020",
        "name": "宏海控股集团",
        "最新价": 0.039,
        "涨跌额": 0.007,
        "涨跌幅": 21.88,
        "今开": 0.03,
        "最高": 0.042,
        "最低": 0.024,
        "昨收": 0.032,
        "成交量": 2110000.0,
        "成交额": 61560.0
    },
    {
        "序号": 16,
        "symbol": "00356",
        "name": "鼎立资本",
        "最新价": 0.074,
        "涨跌额": 0.013,
        "涨跌幅": 21.31,
        "今开": 0.074,
        "最高": 0.074,
        "最低": 0.074,
        "昨收": 0.061,
        "成交量": 30000.0,
        "成交额": 2220.0
    },
    {
        "序号": 17,
        "symbol": "09890",
        "name": "中旭未来",
        "最新价": 35.8,
        "涨跌额": 6.15,
        "涨跌幅": 20.74,
        "今开": 29.65,
        "最高": 36.5,
        "最低": 29.65,
        "昨收": 29.65,
        "成交量": 686600.0,
        "成交额": 22720890.0
    },
    {
        "序号": 18,
        "symbol": "08635",
        "name": "连成科技集团",
        "最新价": 0.1,
        "涨跌额": 0.017,
        "涨跌幅": 20.48,
        "今开": 0.083,
        "最高": 0.1,
        "最低": 0.083,
        "昨收": 0.083,
        "成交量": 1560000.0,
        "成交额": 140876.0
    },
    {
        "序号": 19,
        "symbol": "08280",
        "name": "中国数字视频",
        "最新价": 0.03,
        "涨跌额": 0.005,
        "涨跌幅": 20.0,
        "今开": 0.029,
        "最高": 0.031,
        "最低": 0.027,
        "昨收": 0.025,
        "成交量": 2024000.0,
        "成交额": 60584.0
    },
    {
        "序号": 20,
        "symbol": "02017",
        "name": "沧海控股",
        "最新价": 0.3,
        "涨跌额": 0.045,
        "涨跌幅": 17.65,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.3,
        "昨收": 0.255,
        "成交量": 8000.0,
        "成交额": 2380.0
    },
    {
        "序号": 21,
        "symbol": "08455",
        "name": "礼建德集团",
        "最新价": 0.027,
        "涨跌额": 0.004,
        "涨跌幅": 17.39,
        "今开": 0.027,
        "最高": 0.027,
        "最低": 0.023,
        "昨收": 0.023,
        "成交量": 40000.0,
        "成交额": 1040.0
    },
    {
        "序号": 22,
        "symbol": "08668",
        "name": "瀛海集团",
        "最新价": 0.138,
        "涨跌额": 0.02,
        "涨跌幅": 16.95,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.138,
        "昨收": 0.118,
        "成交量": 50000.0,
        "成交额": 6900.0
    },
    {
        "序号": 23,
        "symbol": "00628",
        "name": "国美金融科技",
        "最新价": 0.275,
        "涨跌额": 0.039,
        "涨跌幅": 16.53,
        "今开": 0.275,
        "最高": 0.29,
        "最低": 0.24,
        "昨收": 0.236,
        "成交量": 1332000.0,
        "成交额": 352640.0
    },
    {
        "序号": 24,
        "symbol": "02616",
        "name": "基石药业-B",
        "最新价": 2.38,
        "涨跌额": 0.33,
        "涨跌幅": 16.1,
        "今开": 2.05,
        "最高": 2.61,
        "最低": 2.0,
        "昨收": 2.05,
        "成交量": 24552778.0,
        "成交额": 58765391.0
    },
    {
        "序号": 25,
        "symbol": "06193",
        "name": "泰林科建",
        "最新价": 0.405,
        "涨跌额": 0.055,
        "涨跌幅": 15.71,
        "今开": 0.34,
        "最高": 0.43,
        "最低": 0.34,
        "昨收": 0.35,
        "成交量": 504000.0,
        "成交额": 188770.0
    },
    {
        "序号": 26,
        "symbol": "08513",
        "name": "官酝控股",
        "最新价": 0.067,
        "涨跌额": 0.009,
        "涨跌幅": 15.52,
        "今开": 0.053,
        "最高": 0.072,
        "最低": 0.053,
        "昨收": 0.058,
        "成交量": 204000.0,
        "成交额": 11160.0
    },
    {
        "序号": 27,
        "symbol": "01783",
        "name": "晋景新能",
        "最新价": 1.9,
        "涨跌额": 0.25,
        "涨跌幅": 15.15,
        "今开": 1.68,
        "最高": 1.93,
        "最低": 1.65,
        "昨收": 1.65,
        "成交量": 7780000.0,
        "成交额": 14164200.0
    },
    {
        "序号": 28,
        "symbol": "02521",
        "name": "升辉清洁",
        "最新价": 0.385,
        "涨跌额": 0.05,
        "涨跌幅": 14.93,
        "今开": 0.335,
        "最高": 0.385,
        "最低": 0.33,
        "昨收": 0.335,
        "成交量": 38527500.0,
        "成交额": 13782600.0
    },
    {
        "序号": 29,
        "symbol": "00499",
        "name": "青岛控股",
        "最新价": 0.077,
        "涨跌额": 0.01,
        "涨跌幅": 14.93,
        "今开": 0.078,
        "最高": 0.08,
        "最低": 0.077,
        "昨收": 0.067,
        "成交量": 236000.0,
        "成交额": 18460.0
    },
    {
        "序号": 30,
        "symbol": "00519",
        "name": "实力建业",
        "最新价": 0.07,
        "涨跌额": 0.009,
        "涨跌幅": 14.75,
        "今开": 0.065,
        "最高": 0.07,
        "最低": 0.065,
        "昨收": 0.061,
        "成交量": 205000.0,
        "成交额": 14305.0
    },
    {
        "序号": 31,
        "symbol": "03623",
        "name": "中国金融发展",
        "最新价": 0.86,
        "涨跌额": 0.11,
        "涨跌幅": 14.67,
        "今开": 0.7,
        "最高": 0.89,
        "最低": 0.7,
        "昨收": 0.75,
        "成交量": 20000.0,
        "成交额": 15920.0
    },
    {
        "序号": 32,
        "symbol": "06839",
        "name": "云南水务",
        "最新价": 0.285,
        "涨跌额": 0.035,
        "涨跌幅": 14.0,
        "今开": 0.28,
        "最高": 0.29,
        "最低": 0.28,
        "昨收": 0.25,
        "成交量": 18000.0,
        "成交额": 5210.0
    },
    {
        "序号": 33,
        "symbol": "00871",
        "name": "中国疏浚环保",
        "最新价": 0.057,
        "涨跌额": 0.007,
        "涨跌幅": 14.0,
        "今开": 0.056,
        "最高": 0.057,
        "最低": 0.056,
        "昨收": 0.05,
        "成交量": 320000.0,
        "成交额": 18100.0
    },
    {
        "序号": 34,
        "symbol": "00776",
        "name": "帝国科技集团",
        "最新价": 5.7,
        "涨跌额": 0.7,
        "涨跌幅": 14.0,
        "今开": 5.5,
        "最高": 5.7,
        "最低": 5.5,
        "昨收": 5.0,
        "成交量": 1000.0,
        "成交额": 5600.0
    },
    {
        "序号": 35,
        "symbol": "08292",
        "name": "盛良物流",
        "最新价": 0.082,
        "涨跌额": 0.01,
        "涨跌幅": 13.89,
        "今开": 0.082,
        "最高": 0.082,
        "最低": 0.082,
        "昨收": 0.072,
        "成交量": 10000.0,
        "成交额": 820.0
    },
    {
        "序号": 36,
        "symbol": "00641",
        "name": "中国恒天立信国际",
        "最新价": 0.239,
        "涨跌额": 0.029,
        "涨跌幅": 13.81,
        "今开": 0.229,
        "最高": 0.239,
        "最低": 0.2,
        "昨收": 0.21,
        "成交量": 296000.0,
        "成交额": 60796.0
    },
    {
        "序号": 37,
        "symbol": "02147",
        "name": "正味集团",
        "最新价": 0.46,
        "涨跌额": 0.055,
        "涨跌幅": 13.58,
        "今开": 0.425,
        "最高": 0.48,
        "最低": 0.415,
        "昨收": 0.405,
        "成交量": 62354400.0,
        "成交额": 27473944.0
    },
    {
        "序号": 38,
        "symbol": "00264",
        "name": "中联发展控股",
        "最新价": 0.92,
        "涨跌额": 0.11,
        "涨跌幅": 13.58,
        "今开": 0.81,
        "最高": 0.92,
        "最低": 0.81,
        "昨收": 0.81,
        "成交量": 1528000.0,
        "成交额": 1255360.0
    },
    {
        "序号": 39,
        "symbol": "09860",
        "name": "艾迪康控股",
        "最新价": 11.78,
        "涨跌额": 1.3,
        "涨跌幅": 12.4,
        "今开": 10.5,
        "最高": 12.48,
        "最低": 10.5,
        "昨收": 10.48,
        "成交量": 1351000.0,
        "成交额": 15748320.0
    },
    {
        "序号": 40,
        "symbol": "01529",
        "name": "乐氏国际控股",
        "最新价": 0.167,
        "涨跌额": 0.018,
        "涨跌幅": 12.08,
        "今开": 0.147,
        "最高": 0.167,
        "最低": 0.147,
        "昨收": 0.149,
        "成交量": 9190000.0,
        "成交额": 1383200.0
    },
    {
        "序号": 41,
        "symbol": "08416",
        "name": "HM INTL HLDGS",
        "最新价": 0.093,
        "涨跌额": 0.01,
        "涨跌幅": 12.05,
        "今开": 0.083,
        "最高": 0.093,
        "最低": 0.08,
        "昨收": 0.083,
        "成交量": 75000.0,
        "成交额": 6365.0
    },
    {
        "序号": 42,
        "symbol": "00487",
        "name": "实德环球",
        "最新价": 0.056,
        "涨跌额": 0.006,
        "涨跌幅": 12.0,
        "今开": 0.045,
        "最高": 0.056,
        "最低": 0.045,
        "昨收": 0.05,
        "成交量": 24000.0,
        "成交额": 1212.0
    },
    {
        "序号": 43,
        "symbol": "00033",
        "name": "INTL GENIUS",
        "最新价": 5.1,
        "涨跌额": 0.54,
        "涨跌幅": 11.84,
        "今开": 4.6,
        "最高": 5.1,
        "最低": 4.6,
        "昨收": 4.56,
        "成交量": 1148000.0,
        "成交额": 5566440.0
    },
    {
        "序号": 44,
        "symbol": "00632",
        "name": "中港石油",
        "最新价": 0.076,
        "涨跌额": 0.008,
        "涨跌幅": 11.76,
        "今开": 0.056,
        "最高": 0.079,
        "最低": 0.056,
        "昨收": 0.068,
        "成交量": 64000.0,
        "成交额": 3710.0
    },
    {
        "序号": 45,
        "symbol": "02309",
        "name": "大象未来集团",
        "最新价": 1.28,
        "涨跌额": 0.13,
        "涨跌幅": 11.3,
        "今开": 1.09,
        "最高": 1.35,
        "最低": 1.09,
        "昨收": 1.15,
        "成交量": 574370.0,
        "成交额": 711225.0
    },
    {
        "序号": 46,
        "symbol": "01572",
        "name": "中国艺术金融",
        "最新价": 0.079,
        "涨跌额": 0.008,
        "涨跌幅": 11.27,
        "今开": 0.079,
        "最高": 0.079,
        "最低": 0.079,
        "昨收": 0.071,
        "成交量": 4000.0,
        "成交额": 316.0
    },
    {
        "序号": 47,
        "symbol": "00736",
        "name": "中国置业投资",
        "最新价": 0.238,
        "涨跌额": 0.024,
        "涨跌幅": 11.21,
        "今开": 0.203,
        "最高": 0.24,
        "最低": 0.203,
        "昨收": 0.214,
        "成交量": 50655.0,
        "成交额": 11415.0
    },
    {
        "序号": 48,
        "symbol": "01640",
        "name": "瑞诚中国传媒",
        "最新价": 0.5,
        "涨跌额": 0.05,
        "涨跌幅": 11.11,
        "今开": 0.44,
        "最高": 0.5,
        "最低": 0.44,
        "昨收": 0.45,
        "成交量": 388000.0,
        "成交额": 192910.0
    },
    {
        "序号": 49,
        "symbol": "00174",
        "name": "盛洋投资",
        "最新价": 0.355,
        "涨跌额": 0.035,
        "涨跌幅": 10.94,
        "今开": 0.34,
        "最高": 0.365,
        "最低": 0.34,
        "昨收": 0.32,
        "成交量": 10000.0,
        "成交额": 3500.0
    },
    {
        "序号": 50,
        "symbol": "08158",
        "name": "中国再生医学",
        "最新价": 0.51,
        "涨跌额": 0.05,
        "涨跌幅": 10.87,
        "今开": 0.46,
        "最高": 0.51,
        "最低": 0.46,
        "昨收": 0.46,
        "成交量": 25500.0,
        "成交额": 12355.0
    },
    {
        "序号": 51,
        "symbol": "01132",
        "name": "橙天嘉禾",
        "最新价": 0.041,
        "涨跌额": 0.004,
        "涨跌幅": 10.81,
        "今开": 0.036,
        "最高": 0.042,
        "最低": 0.034,
        "昨收": 0.037,
        "成交量": 175000.0,
        "成交额": 6195.0
    },
    {
        "序号": 52,
        "symbol": "01315",
        "name": "绿色经济",
        "最新价": 0.166,
        "涨跌额": 0.016,
        "涨跌幅": 10.67,
        "今开": 0.15,
        "最高": 0.171,
        "最低": 0.123,
        "昨收": 0.15,
        "成交量": 1210000.0,
        "成交额": 173850.0
    },
    {
        "序号": 53,
        "symbol": "08482",
        "name": "万励达",
        "最新价": 0.125,
        "涨跌额": 0.012,
        "涨跌幅": 10.62,
        "今开": 0.111,
        "最高": 0.135,
        "最低": 0.109,
        "昨收": 0.113,
        "成交量": 98700000.0,
        "成交额": 11641970.0
    },
    {
        "序号": 54,
        "symbol": "02195",
        "name": "盈汇企业控股",
        "最新价": 0.188,
        "涨跌额": 0.018,
        "涨跌幅": 10.59,
        "今开": 0.162,
        "最高": 0.188,
        "最低": 0.162,
        "昨收": 0.17,
        "成交量": 2725000.0,
        "成交额": 490090.0
    },
    {
        "序号": 55,
        "symbol": "03903",
        "name": "瀚华金控",
        "最新价": 0.375,
        "涨跌额": 0.035,
        "涨跌幅": 10.29,
        "今开": 0.35,
        "最高": 0.375,
        "最低": 0.35,
        "昨收": 0.34,
        "成交量": 4000.0,
        "成交额": 1450.0
    },
    {
        "序号": 56,
        "symbol": "09916",
        "name": "兴业物联",
        "最新价": 0.54,
        "涨跌额": 0.05,
        "涨跌幅": 10.2,
        "今开": 0.58,
        "最高": 0.61,
        "最低": 0.54,
        "昨收": 0.49,
        "成交量": 220000.0,
        "成交额": 130480.0
    },
    {
        "序号": 57,
        "symbol": "00352",
        "name": "富阳",
        "最新价": 0.163,
        "涨跌额": 0.015,
        "涨跌幅": 10.14,
        "今开": 0.165,
        "最高": 0.165,
        "最低": 0.163,
        "昨收": 0.148,
        "成交量": 2000.0,
        "成交额": 330.0
    },
    {
        "序号": 58,
        "symbol": "00484",
        "name": "云游控股",
        "最新价": 0.87,
        "涨跌额": 0.08,
        "涨跌幅": 10.13,
        "今开": 0.79,
        "最高": 0.89,
        "最低": 0.79,
        "昨收": 0.79,
        "成交量": 60100.0,
        "成交额": 50466.0
    },
    {
        "序号": 59,
        "symbol": "06663",
        "name": "国际永胜集团",
        "最新价": 0.33,
        "涨跌额": 0.03,
        "涨跌幅": 10.0,
        "今开": 0.27,
        "最高": 0.34,
        "最低": 0.265,
        "昨收": 0.3,
        "成交量": 90000.0,
        "成交额": 26900.0
    },
    {
        "序号": 60,
        "symbol": "02326",
        "name": "新源万恒控股",
        "最新价": 0.011,
        "涨跌额": 0.001,
        "涨跌幅": 10.0,
        "今开": 0.01,
        "最高": 0.011,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 570000.0,
        "成交额": 6200.0
    },
    {
        "序号": 61,
        "symbol": "00690",
        "name": "联康生物科技集团",
        "最新价": 0.055,
        "涨跌额": 0.005,
        "涨跌幅": 10.0,
        "今开": 0.05,
        "最高": 0.055,
        "最低": 0.047,
        "昨收": 0.05,
        "成交量": 13400000.0,
        "成交额": 665380.0
    },
    {
        "序号": 62,
        "symbol": "00559",
        "name": "德泰新能源集团",
        "最新价": 0.011,
        "涨跌额": 0.001,
        "涨跌幅": 10.0,
        "今开": 0.01,
        "最高": 0.011,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 630000.0,
        "成交额": 6330.0
    },
    {
        "序号": 63,
        "symbol": "08326",
        "name": "同景新能源",
        "最新价": 0.113,
        "涨跌额": 0.01,
        "涨跌幅": 9.71,
        "今开": 0.114,
        "最高": 0.118,
        "最低": 0.104,
        "昨收": 0.103,
        "成交量": 281000.0,
        "成交额": 31448.0
    },
    {
        "序号": 64,
        "symbol": "02329",
        "name": "国瑞健康",
        "最新价": 0.034,
        "涨跌额": 0.003,
        "涨跌幅": 9.68,
        "今开": 0.031,
        "最高": 0.035,
        "最低": 0.03,
        "昨收": 0.031,
        "成交量": 2085000.0,
        "成交额": 62743.0
    },
    {
        "序号": 65,
        "symbol": "01059",
        "name": "看通集团",
        "最新价": 0.455,
        "涨跌额": 0.04,
        "涨跌幅": 9.64,
        "今开": 0.45,
        "最高": 0.455,
        "最低": 0.395,
        "昨收": 0.415,
        "成交量": 56122.0,
        "成交额": 23508.0
    },
    {
        "序号": 66,
        "symbol": "08168",
        "name": "宝积资本",
        "最新价": 0.137,
        "涨跌额": 0.012,
        "涨跌幅": 9.6,
        "今开": 0.135,
        "最高": 0.138,
        "最低": 0.122,
        "昨收": 0.125,
        "成交量": 1430000.0,
        "成交额": 182140.0
    },
    {
        "序号": 67,
        "symbol": "08059",
        "name": "朝威控股",
        "最新价": 0.023,
        "涨跌额": 0.002,
        "涨跌幅": 9.52,
        "今开": 0.022,
        "最高": 0.023,
        "最低": 0.02,
        "昨收": 0.021,
        "成交量": 725000.0,
        "成交额": 15890.0
    },
    {
        "序号": 68,
        "symbol": "01660",
        "name": "兆邦基生活",
        "最新价": 0.219,
        "涨跌额": 0.019,
        "涨跌幅": 9.5,
        "今开": 0.21,
        "最高": 0.225,
        "最低": 0.204,
        "昨收": 0.2,
        "成交量": 5600000.0,
        "成交额": 1182184.0
    },
    {
        "序号": 69,
        "symbol": "08511",
        "name": "民富国际",
        "最新价": 0.061,
        "涨跌额": 0.005,
        "涨跌幅": 8.93,
        "今开": 0.053,
        "最高": 0.063,
        "最低": 0.053,
        "昨收": 0.056,
        "成交量": 560000.0,
        "成交额": 32460.0
    },
    {
        "序号": 70,
        "symbol": "02517",
        "name": "锅圈",
        "最新价": 8.6,
        "涨跌额": 0.69,
        "涨跌幅": 8.72,
        "今开": 7.98,
        "最高": 8.8,
        "最低": 7.84,
        "昨收": 7.91,
        "成交量": 1013382.0,
        "成交额": 8709618.0
    },
    {
        "序号": 71,
        "symbol": "08366",
        "name": "浙江联合投资",
        "最新价": 0.025,
        "涨跌额": 0.002,
        "涨跌幅": 8.7,
        "今开": 0.023,
        "最高": 0.025,
        "最低": 0.023,
        "昨收": 0.023,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 72,
        "symbol": "08305",
        "name": "洢人壹方控股",
        "最新价": 0.063,
        "涨跌额": 0.005,
        "涨跌幅": 8.62,
        "今开": 0.058,
        "最高": 0.063,
        "最低": 0.058,
        "昨收": 0.058,
        "成交量": 2100000.0,
        "成交额": 121850.0
    },
    {
        "序号": 73,
        "symbol": "02383",
        "name": "TOM集团",
        "最新价": 0.63,
        "涨跌额": 0.05,
        "涨跌幅": 8.62,
        "今开": 0.58,
        "最高": 0.63,
        "最低": 0.53,
        "昨收": 0.58,
        "成交量": 18000.0,
        "成交额": 10580.0
    },
    {
        "序号": 74,
        "symbol": "01247",
        "name": "米格国际控股",
        "最新价": 0.9,
        "涨跌额": 0.07,
        "涨跌幅": 8.43,
        "今开": 0.78,
        "最高": 0.98,
        "最低": 0.7,
        "昨收": 0.83,
        "成交量": 821200.0,
        "成交额": 738968.0
    },
    {
        "序号": 75,
        "symbol": "09886",
        "name": "叮当健康",
        "最新价": 2.21,
        "涨跌额": 0.17,
        "涨跌幅": 8.33,
        "今开": 2.19,
        "最高": 2.5,
        "最低": 2.1,
        "昨收": 2.04,
        "成交量": 12452000.0,
        "成交额": 28843550.0
    },
    {
        "序号": 76,
        "symbol": "08401",
        "name": "源想集团",
        "最新价": 0.13,
        "涨跌额": 0.01,
        "涨跌幅": 8.33,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.12,
        "成交量": 18000.0,
        "成交额": 2340.0
    },
    {
        "序号": 77,
        "symbol": "08226",
        "name": "树熊金融集团",
        "最新价": 0.13,
        "涨跌额": 0.01,
        "涨跌幅": 8.33,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.12,
        "成交量": 5000.0,
        "成交额": 650.0
    },
    {
        "序号": 78,
        "symbol": "02399",
        "name": "中国安储能源",
        "最新价": 0.91,
        "涨跌额": 0.07,
        "涨跌幅": 8.33,
        "今开": 0.85,
        "最高": 0.91,
        "最低": 0.85,
        "昨收": 0.84,
        "成交量": 203980000.0,
        "成交额": 180294040.0
    },
    {
        "序号": 79,
        "symbol": "00986",
        "name": "中国环保能源",
        "最新价": 0.039,
        "涨跌额": 0.003,
        "涨跌幅": 8.33,
        "今开": 0.033,
        "最高": 0.045,
        "最低": 0.032,
        "昨收": 0.036,
        "成交量": 991300.0,
        "成交额": 38499.0
    },
    {
        "序号": 80,
        "symbol": "01736",
        "name": "中国育儿网络",
        "最新价": 0.079,
        "涨跌额": 0.006,
        "涨跌幅": 8.22,
        "今开": 0.071,
        "最高": 0.08,
        "最低": 0.07,
        "昨收": 0.073,
        "成交量": 636000.0,
        "成交额": 46120.0
    },
    {
        "序号": 81,
        "symbol": "00547",
        "name": "数字王国",
        "最新价": 0.158,
        "涨跌额": 0.012,
        "涨跌幅": 8.22,
        "今开": 0.146,
        "最高": 0.159,
        "最低": 0.129,
        "昨收": 0.146,
        "成交量": 15360000.0,
        "成交额": 2089035.0
    },
    {
        "序号": 82,
        "symbol": "09885",
        "name": "药师帮",
        "最新价": 31.9,
        "涨跌额": 2.4,
        "涨跌幅": 8.14,
        "今开": 29.5,
        "最高": 31.9,
        "最低": 27.45,
        "昨收": 29.5,
        "成交量": 13422800.0,
        "成交额": 418607536.0
    },
    {
        "序号": 83,
        "symbol": "00542",
        "name": "富元国际集团",
        "最新价": 0.214,
        "涨跌额": 0.016,
        "涨跌幅": 8.08,
        "今开": 0.198,
        "最高": 0.22,
        "最低": 0.198,
        "昨收": 0.198,
        "成交量": 1600000.0,
        "成交额": 326696.0
    },
    {
        "序号": 84,
        "symbol": "01769",
        "name": "思考乐教育",
        "最新价": 1.9,
        "涨跌额": 0.14,
        "涨跌幅": 7.95,
        "今开": 1.9,
        "最高": 2.02,
        "最低": 1.82,
        "昨收": 1.76,
        "成交量": 12458000.0,
        "成交额": 23801920.0
    },
    {
        "序号": 85,
        "symbol": "00913",
        "name": "港湾数字",
        "最新价": 0.95,
        "涨跌额": 0.07,
        "涨跌幅": 7.95,
        "今开": 0.73,
        "最高": 0.95,
        "最低": 0.72,
        "昨收": 0.88,
        "成交量": 243000.0,
        "成交额": 217210.0
    },
    {
        "序号": 86,
        "symbol": "01449",
        "name": "立德教育",
        "最新价": 0.34,
        "涨跌额": 0.025,
        "涨跌幅": 7.94,
        "今开": 0.315,
        "最高": 0.345,
        "最低": 0.305,
        "昨收": 0.315,
        "成交量": 46000.0,
        "成交额": 14785.0
    },
    {
        "序号": 87,
        "symbol": "00130",
        "name": "慕诗国际",
        "最新价": 0.178,
        "涨跌额": 0.013,
        "涨跌幅": 7.88,
        "今开": 0.165,
        "最高": 0.178,
        "最低": 0.165,
        "昨收": 0.165,
        "成交量": 280000.0,
        "成交额": 47122.0
    },
    {
        "序号": 88,
        "symbol": "00989",
        "name": "华音国际控股",
        "最新价": 0.151,
        "涨跌额": 0.011,
        "涨跌幅": 7.86,
        "今开": 0.14,
        "最高": 0.155,
        "最低": 0.14,
        "昨收": 0.14,
        "成交量": 1840000.0,
        "成交额": 277475.0
    },
    {
        "序号": 89,
        "symbol": "01850",
        "name": "鸿盛昌资源",
        "最新价": 0.69,
        "涨跌额": 0.05,
        "涨跌幅": 7.81,
        "今开": 0.66,
        "最高": 0.69,
        "最低": 0.6,
        "昨收": 0.64,
        "成交量": 3810000.0,
        "成交额": 2448400.0
    },
    {
        "序号": 90,
        "symbol": "00595",
        "name": "AV CONCEPT HOLD",
        "最新价": 0.345,
        "涨跌额": 0.025,
        "涨跌幅": 7.81,
        "今开": 0.35,
        "最高": 0.35,
        "最低": 0.325,
        "昨收": 0.32,
        "成交量": 74000.0,
        "成交额": 24190.0
    },
    {
        "序号": 91,
        "symbol": "02298",
        "name": "都市丽人",
        "最新价": 0.249,
        "涨跌额": 0.018,
        "涨跌幅": 7.79,
        "今开": 0.231,
        "最高": 0.25,
        "最低": 0.231,
        "昨收": 0.231,
        "成交量": 242000.0,
        "成交额": 58851.0
    },
    {
        "序号": 92,
        "symbol": "00645",
        "name": "安域亚洲",
        "最新价": 0.111,
        "涨跌额": 0.008,
        "涨跌幅": 7.77,
        "今开": 0.119,
        "最高": 0.119,
        "最低": 0.111,
        "昨收": 0.103,
        "成交量": 12000.0,
        "成交额": 1420.0
    },
    {
        "序号": 93,
        "symbol": "01748",
        "name": "信源企业集团",
        "最新价": 5.15,
        "涨跌额": 0.37,
        "涨跌幅": 7.74,
        "今开": 5.0,
        "最高": 5.15,
        "最低": 5.0,
        "昨收": 4.78,
        "成交量": 4000.0,
        "成交额": 20300.0
    },
    {
        "序号": 94,
        "symbol": "01751",
        "name": "景联集团",
        "最新价": 0.42,
        "涨跌额": 0.03,
        "涨跌幅": 7.69,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.42,
        "昨收": 0.39,
        "成交量": 10000.0,
        "成交额": 4200.0
    },
    {
        "序号": 95,
        "symbol": "01323",
        "name": "华盛国际控股",
        "最新价": 0.014,
        "涨跌额": 0.001,
        "涨跌幅": 7.69,
        "今开": 0.013,
        "最高": 0.017,
        "最低": 0.013,
        "昨收": 0.013,
        "成交量": 62746000.0,
        "成交额": 903292.0
    },
    {
        "序号": 96,
        "symbol": "01894",
        "name": "恒益控股",
        "最新价": 0.495,
        "涨跌额": 0.035,
        "涨跌幅": 7.61,
        "今开": 0.49,
        "最高": 0.51,
        "最低": 0.45,
        "昨收": 0.46,
        "成交量": 110940000.0,
        "成交额": 51926350.0
    },
    {
        "序号": 97,
        "symbol": "08537",
        "name": "创辉珠宝",
        "最新价": 0.029,
        "涨跌额": 0.002,
        "涨跌幅": 7.41,
        "今开": 0.029,
        "最高": 0.029,
        "最低": 0.029,
        "昨收": 0.027,
        "成交量": 610000.0,
        "成交额": 17690.0
    },
    {
        "序号": 98,
        "symbol": "08042",
        "name": "高奥士国际",
        "最新价": 0.044,
        "涨跌额": 0.003,
        "涨跌幅": 7.32,
        "今开": 0.04,
        "最高": 0.044,
        "最低": 0.04,
        "昨收": 0.041,
        "成交量": 100000.0,
        "成交额": 4220.0
    },
    {
        "序号": 99,
        "symbol": "02442",
        "name": "怡俊集团控股",
        "最新价": 1.48,
        "涨跌额": 0.1,
        "涨跌幅": 7.25,
        "今开": 1.41,
        "最高": 1.5,
        "最低": 1.39,
        "昨收": 1.38,
        "成交量": 238000.0,
        "成交额": 346640.0
    },
    {
        "序号": 100,
        "symbol": "08483",
        "name": "名仕快相",
        "最新价": 0.09,
        "涨跌额": 0.006,
        "涨跌幅": 7.14,
        "今开": 0.09,
        "最高": 0.09,
        "最低": 0.09,
        "昨收": 0.084,
        "成交量": 8000.0,
        "成交额": 720.0
    },
    {
        "序号": 101,
        "symbol": "08295",
        "name": "金慧科技",
        "最新价": 0.03,
        "涨跌额": 0.002,
        "涨跌幅": 7.14,
        "今开": 0.028,
        "最高": 0.03,
        "最低": 0.028,
        "昨收": 0.028,
        "成交量": 340000.0,
        "成交额": 9800.0
    },
    {
        "序号": 102,
        "symbol": "01259",
        "name": "未来发展控股",
        "最新价": 0.03,
        "涨跌额": 0.002,
        "涨跌幅": 7.14,
        "今开": 0.029,
        "最高": 0.031,
        "最低": 0.026,
        "昨收": 0.028,
        "成交量": 876000.0,
        "成交额": 25946.0
    },
    {
        "序号": 103,
        "symbol": "01189",
        "name": "大湾区聚变力量",
        "最新价": 0.045,
        "涨跌额": 0.003,
        "涨跌幅": 7.14,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.043,
        "昨收": 0.042,
        "成交量": 320250.0,
        "成交额": 14008.0
    },
    {
        "序号": 104,
        "symbol": "02260",
        "name": "环龙控股",
        "最新价": 1.81,
        "涨跌额": 0.12,
        "涨跌幅": 7.1,
        "今开": 1.76,
        "最高": 1.81,
        "最低": 1.74,
        "昨收": 1.69,
        "成交量": 502000.0,
        "成交额": 884240.0
    },
    {
        "序号": 105,
        "symbol": "08296",
        "name": "中国生命集团",
        "最新价": 0.091,
        "涨跌额": 0.006,
        "涨跌幅": 7.06,
        "今开": 0.091,
        "最高": 0.091,
        "最低": 0.091,
        "昨收": 0.085,
        "成交量": 4000.0,
        "成交额": 364.0
    },
    {
        "序号": 106,
        "symbol": "01889",
        "name": "三爱健康集团",
        "最新价": 0.061,
        "涨跌额": 0.004,
        "涨跌幅": 7.02,
        "今开": 0.062,
        "最高": 0.063,
        "最低": 0.059,
        "昨收": 0.057,
        "成交量": 135000.0,
        "成交额": 8070.0
    },
    {
        "序号": 107,
        "symbol": "00718",
        "name": "太和控股",
        "最新价": 0.031,
        "涨跌额": 0.002,
        "涨跌幅": 6.9,
        "今开": 0.026,
        "最高": 0.031,
        "最低": 0.026,
        "昨收": 0.029,
        "成交量": 60000.0,
        "成交额": 1660.0
    },
    {
        "序号": 108,
        "symbol": "00674",
        "name": "中国唐商",
        "最新价": 0.093,
        "涨跌额": 0.006,
        "涨跌幅": 6.9,
        "今开": 0.091,
        "最高": 0.094,
        "最低": 0.091,
        "昨收": 0.087,
        "成交量": 68000.0,
        "成交额": 6316.0
    },
    {
        "序号": 109,
        "symbol": "02048",
        "name": "易居企业控股",
        "最新价": 0.203,
        "涨跌额": 0.013,
        "涨跌幅": 6.84,
        "今开": 0.195,
        "最高": 0.203,
        "最低": 0.195,
        "昨收": 0.19,
        "成交量": 3921300.0,
        "成交额": 782339.0
    },
    {
        "序号": 110,
        "symbol": "00919",
        "name": "现代健康科技",
        "最新价": 0.095,
        "涨跌额": 0.006,
        "涨跌幅": 6.74,
        "今开": 0.097,
        "最高": 0.097,
        "最低": 0.095,
        "昨收": 0.089,
        "成交量": 4000.0,
        "成交额": 388.0
    },
    {
        "序号": 111,
        "symbol": "00585",
        "name": "元汇集团",
        "最新价": 1.27,
        "涨跌额": 0.08,
        "涨跌幅": 6.72,
        "今开": 1.31,
        "最高": 1.31,
        "最低": 1.23,
        "昨收": 1.19,
        "成交量": 248000.0,
        "成交额": 315280.0
    },
    {
        "序号": 112,
        "symbol": "00244",
        "name": "先施",
        "最新价": 0.255,
        "涨跌额": 0.016,
        "涨跌幅": 6.69,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.25,
        "昨收": 0.239,
        "成交量": 2000.0,
        "成交额": 505.0
    },
    {
        "序号": 113,
        "symbol": "01075",
        "name": "首都信息",
        "最新价": 0.32,
        "涨跌额": 0.02,
        "涨跌幅": 6.67,
        "今开": 0.305,
        "最高": 0.32,
        "最低": 0.3,
        "昨收": 0.3,
        "成交量": 786000.0,
        "成交额": 241590.0
    },
    {
        "序号": 114,
        "symbol": "00918",
        "name": "国能国际资产",
        "最新价": 0.32,
        "涨跌额": 0.02,
        "涨跌幅": 6.67,
        "今开": 0.28,
        "最高": 0.32,
        "最低": 0.28,
        "昨收": 0.3,
        "成交量": 102000.0,
        "成交额": 30000.0
    },
    {
        "序号": 115,
        "symbol": "01228",
        "name": "北海康成-B",
        "最新价": 0.81,
        "涨跌额": 0.05,
        "涨跌幅": 6.58,
        "今开": 0.8,
        "最高": 1.0,
        "最低": 0.76,
        "昨收": 0.76,
        "成交量": 53240.0,
        "成交额": 42050.0
    },
    {
        "序号": 116,
        "symbol": "02369",
        "name": "酷派集团",
        "最新价": 0.049,
        "涨跌额": 0.003,
        "涨跌幅": 6.52,
        "今开": 0.045,
        "最高": 0.05,
        "最低": 0.045,
        "昨收": 0.046,
        "成交量": 2613000.0,
        "成交额": 125366.0
    },
    {
        "序号": 117,
        "symbol": "01152",
        "name": "正乾金融控股",
        "最新价": 0.099,
        "涨跌额": 0.006,
        "涨跌幅": 6.45,
        "今开": 0.099,
        "最高": 0.099,
        "最低": 0.099,
        "昨收": 0.093,
        "成交量": 54000.0,
        "成交额": 4946.0
    },
    {
        "序号": 118,
        "symbol": "00538",
        "name": "味千(中国)",
        "最新价": 0.83,
        "涨跌额": 0.05,
        "涨跌幅": 6.41,
        "今开": 0.79,
        "最高": 0.84,
        "最低": 0.78,
        "昨收": 0.78,
        "成交量": 7795000.0,
        "成交额": 6248955.0
    },
    {
        "序号": 119,
        "symbol": "08532",
        "name": "宝发控股",
        "最新价": 0.05,
        "涨跌额": 0.003,
        "涨跌幅": 6.38,
        "今开": 0.05,
        "最高": 0.05,
        "最低": 0.05,
        "昨收": 0.047,
        "成交量": 8000.0,
        "成交额": 400.0
    },
    {
        "序号": 120,
        "symbol": "08238",
        "name": "惠陶集团",
        "最新价": 0.05,
        "涨跌额": 0.003,
        "涨跌幅": 6.38,
        "今开": 0.046,
        "最高": 0.05,
        "最低": 0.046,
        "昨收": 0.047,
        "成交量": 1024000.0,
        "成交额": 49448.0
    },
    {
        "序号": 121,
        "symbol": "00603",
        "name": "中油燃气",
        "最新价": 0.25,
        "涨跌额": 0.015,
        "涨跌幅": 6.38,
        "今开": 0.244,
        "最高": 0.255,
        "最低": 0.244,
        "昨收": 0.235,
        "成交量": 700000.0,
        "成交额": 175140.0
    },
    {
        "序号": 122,
        "symbol": "09982",
        "name": "中原建业",
        "最新价": 0.335,
        "涨跌额": 0.02,
        "涨跌幅": 6.35,
        "今开": 0.32,
        "最高": 0.34,
        "最低": 0.32,
        "昨收": 0.315,
        "成交量": 1954000.0,
        "成交额": 639270.0
    },
    {
        "序号": 123,
        "symbol": "01720",
        "name": "普天通信集团",
        "最新价": 0.067,
        "涨跌额": 0.004,
        "涨跌幅": 6.35,
        "今开": 0.063,
        "最高": 0.067,
        "最低": 0.063,
        "昨收": 0.063,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 124,
        "symbol": "08163",
        "name": "声扬集团",
        "最新价": 0.218,
        "涨跌额": 0.013,
        "涨跌幅": 6.34,
        "今开": 0.207,
        "最高": 0.219,
        "最低": 0.205,
        "昨收": 0.205,
        "成交量": 800000.0,
        "成交额": 170800.0
    },
    {
        "序号": 125,
        "symbol": "06868",
        "name": "天福",
        "最新价": 4.26,
        "涨跌额": 0.25,
        "涨跌幅": 6.23,
        "今开": 4.01,
        "最高": 4.26,
        "最低": 4.01,
        "昨收": 4.01,
        "成交量": 19000.0,
        "成交额": 78770.0
    },
    {
        "序号": 126,
        "symbol": "02019",
        "name": "德信中国",
        "最新价": 0.12,
        "涨跌额": 0.007,
        "涨跌幅": 6.19,
        "今开": 0.113,
        "最高": 0.127,
        "最低": 0.113,
        "昨收": 0.113,
        "成交量": 170000.0,
        "成交额": 19686.0
    },
    {
        "序号": 127,
        "symbol": "08391",
        "name": "基石科技控股",
        "最新价": 0.7,
        "涨跌额": 0.04,
        "涨跌幅": 6.06,
        "今开": 0.66,
        "最高": 0.7,
        "最低": 0.62,
        "昨收": 0.66,
        "成交量": 692000.0,
        "成交额": 454960.0
    },
    {
        "序号": 128,
        "symbol": "01715",
        "name": "火山邑动国际控股",
        "最新价": 0.07,
        "涨跌额": 0.004,
        "涨跌幅": 6.06,
        "今开": 0.063,
        "最高": 0.07,
        "最低": 0.058,
        "昨收": 0.066,
        "成交量": 2590000.0,
        "成交额": 163810.0
    },
    {
        "序号": 129,
        "symbol": "00528",
        "name": "金达控股",
        "最新价": 1.23,
        "涨跌额": 0.07,
        "涨跌幅": 6.03,
        "今开": 1.16,
        "最高": 1.23,
        "最低": 1.16,
        "昨收": 1.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 130,
        "symbol": "01588",
        "name": "畅捷通",
        "最新价": 3.34,
        "涨跌额": 0.19,
        "涨跌幅": 6.03,
        "今开": 3.17,
        "最高": 3.34,
        "最低": 3.17,
        "昨收": 3.15,
        "成交量": 4400.0,
        "成交额": 13982.0
    },
    {
        "序号": 131,
        "symbol": "01796",
        "name": "耀高控股",
        "最新价": 3.2,
        "涨跌额": 0.18,
        "涨跌幅": 5.96,
        "今开": 3.04,
        "最高": 3.2,
        "最低": 3.04,
        "昨收": 3.02,
        "成交量": 48000.0,
        "成交额": 148960.0
    },
    {
        "序号": 132,
        "symbol": "02322",
        "name": "香港潮商集团",
        "最新价": 1.07,
        "涨跌额": 0.06,
        "涨跌幅": 5.94,
        "今开": 0.96,
        "最高": 1.08,
        "最低": 0.96,
        "昨收": 1.01,
        "成交量": 11368000.0,
        "成交额": 11647040.0
    },
    {
        "序号": 133,
        "symbol": "01473",
        "name": "环联连讯",
        "最新价": 0.197,
        "涨跌额": 0.011,
        "涨跌幅": 5.91,
        "今开": 0.193,
        "最高": 0.197,
        "最低": 0.181,
        "昨收": 0.186,
        "成交量": 1020000.0,
        "成交额": 189112.0
    },
    {
        "序号": 134,
        "symbol": "08347",
        "name": "F8企业",
        "最新价": 0.09,
        "涨跌额": 0.005,
        "涨跌幅": 5.88,
        "今开": 0.087,
        "最高": 0.09,
        "最低": 0.087,
        "昨收": 0.085,
        "成交量": 16000.0,
        "成交额": 1416.0
    },
    {
        "序号": 135,
        "symbol": "02236",
        "name": "惠生工程",
        "最新价": 0.36,
        "涨跌额": 0.02,
        "涨跌幅": 5.88,
        "今开": 0.345,
        "最高": 0.38,
        "最低": 0.34,
        "昨收": 0.34,
        "成交量": 6301000.0,
        "成交额": 2319980.0
    },
    {
        "序号": 136,
        "symbol": "02139",
        "name": "甘肃银行",
        "最新价": 0.36,
        "涨跌额": 0.02,
        "涨跌幅": 5.88,
        "今开": 0.35,
        "最高": 0.37,
        "最低": 0.335,
        "昨收": 0.34,
        "成交量": 400000.0,
        "成交额": 141640.0
    },
    {
        "序号": 137,
        "symbol": "00814",
        "name": "北京京客隆",
        "最新价": 0.45,
        "涨跌额": 0.025,
        "涨跌幅": 5.88,
        "今开": 0.44,
        "最高": 0.45,
        "最低": 0.425,
        "昨收": 0.425,
        "成交量": 34000.0,
        "成交额": 14965.0
    },
    {
        "序号": 138,
        "symbol": "00387",
        "name": "力丰(集团)",
        "最新价": 0.45,
        "涨跌额": 0.025,
        "涨跌幅": 5.88,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.45,
        "昨收": 0.425,
        "成交量": 4000.0,
        "成交额": 1800.0
    },
    {
        "序号": 139,
        "symbol": "03638",
        "name": "华邦科技",
        "最新价": 0.275,
        "涨跌额": 0.015,
        "涨跌幅": 5.77,
        "今开": 0.265,
        "最高": 0.275,
        "最低": 0.265,
        "昨收": 0.26,
        "成交量": 288000.0,
        "成交额": 76560.0
    },
    {
        "序号": 140,
        "symbol": "02060",
        "name": "浦江国际",
        "最新价": 0.28,
        "涨跌额": 0.015,
        "涨跌幅": 5.66,
        "今开": 0.27,
        "最高": 0.28,
        "最低": 0.26,
        "昨收": 0.265,
        "成交量": 1428000.0,
        "成交额": 383205.0
    },
    {
        "序号": 141,
        "symbol": "08357",
        "name": "REPUBLIC HC",
        "最新价": 0.095,
        "涨跌额": 0.005,
        "涨跌幅": 5.56,
        "今开": 0.09,
        "最高": 0.095,
        "最低": 0.083,
        "昨收": 0.09,
        "成交量": 45000.0,
        "成交额": 4065.0
    },
    {
        "序号": 142,
        "symbol": "03689",
        "name": "康华医疗",
        "最新价": 1.9,
        "涨跌额": 0.1,
        "涨跌幅": 5.56,
        "今开": 1.8,
        "最高": 1.9,
        "最低": 1.8,
        "昨收": 1.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 143,
        "symbol": "01936",
        "name": "利特米",
        "最新价": 0.38,
        "涨跌额": 0.02,
        "涨跌幅": 5.56,
        "今开": 0.35,
        "最高": 0.38,
        "最低": 0.35,
        "昨收": 0.36,
        "成交量": 1964000.0,
        "成交额": 719730.0
    },
    {
        "序号": 144,
        "symbol": "00947",
        "name": "摩比发展",
        "最新价": 0.19,
        "涨跌额": 0.01,
        "涨跌幅": 5.56,
        "今开": 0.189,
        "最高": 0.195,
        "最低": 0.183,
        "昨收": 0.18,
        "成交量": 6000.0,
        "成交额": 1141.0
    },
    {
        "序号": 145,
        "symbol": "02496",
        "name": "友芝友生物-B",
        "最新价": 8.88,
        "涨跌额": 0.46,
        "涨跌幅": 5.46,
        "今开": 8.2,
        "最高": 8.88,
        "最低": 8.2,
        "昨收": 8.42,
        "成交量": 5600.0,
        "成交额": 46052.0
    },
    {
        "序号": 146,
        "symbol": "00650",
        "name": "普达特科技",
        "最新价": 0.485,
        "涨跌额": 0.025,
        "涨跌幅": 5.43,
        "今开": 0.47,
        "最高": 0.52,
        "最低": 0.46,
        "昨收": 0.46,
        "成交量": 156920000.0,
        "成交额": 77444270.0
    },
    {
        "序号": 147,
        "symbol": "02011",
        "name": "中国恒泰集团",
        "最新价": 1.18,
        "涨跌额": 0.06,
        "涨跌幅": 5.36,
        "今开": 1.18,
        "最高": 1.18,
        "最低": 1.18,
        "昨收": 1.12,
        "成交量": 20000.0,
        "成交额": 23600.0
    },
    {
        "序号": 148,
        "symbol": "01173",
        "name": "威高国际",
        "最新价": 0.059,
        "涨跌额": 0.003,
        "涨跌幅": 5.36,
        "今开": 0.059,
        "最高": 0.059,
        "最低": 0.059,
        "昨收": 0.056,
        "成交量": 20000.0,
        "成交额": 1180.0
    },
    {
        "序号": 149,
        "symbol": "00223",
        "name": "易生活控股",
        "最新价": 0.295,
        "涨跌额": 0.015,
        "涨跌幅": 5.36,
        "今开": 0.3,
        "最高": 0.305,
        "最低": 0.285,
        "昨收": 0.28,
        "成交量": 1121400.0,
        "成交额": 330678.0
    },
    {
        "序号": 150,
        "symbol": "01680",
        "name": "澳门励骏",
        "最新价": 0.198,
        "涨跌额": 0.01,
        "涨跌幅": 5.32,
        "今开": 0.187,
        "最高": 0.199,
        "最低": 0.187,
        "昨收": 0.188,
        "成交量": 175000.0,
        "成交额": 33580.0
    },
    {
        "序号": 151,
        "symbol": "01523",
        "name": "珩湾科技",
        "最新价": 2.0,
        "涨跌额": 0.1,
        "涨跌幅": 5.26,
        "今开": 1.9,
        "最高": 2.12,
        "最低": 1.9,
        "昨收": 1.9,
        "成交量": 345376.0,
        "成交额": 707053.0
    },
    {
        "序号": 152,
        "symbol": "02008",
        "name": "凤凰卫视",
        "最新价": 0.243,
        "涨跌额": 0.012,
        "涨跌幅": 5.19,
        "今开": 0.242,
        "最高": 0.244,
        "最低": 0.233,
        "昨收": 0.231,
        "成交量": 174000.0,
        "成交额": 42322.0
    },
    {
        "序号": 153,
        "symbol": "01762",
        "name": "万咖壹联",
        "最新价": 0.163,
        "涨跌额": 0.008,
        "涨跌幅": 5.16,
        "今开": 0.155,
        "最高": 0.165,
        "最低": 0.153,
        "昨收": 0.155,
        "成交量": 152000.0,
        "成交额": 24064.0
    },
    {
        "序号": 154,
        "symbol": "00557",
        "name": "天元医疗",
        "最新价": 0.51,
        "涨跌额": 0.025,
        "涨跌幅": 5.15,
        "今开": 0.485,
        "最高": 0.51,
        "最低": 0.485,
        "昨收": 0.485,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 155,
        "symbol": "08373",
        "name": "靛蓝星",
        "最新价": 0.082,
        "涨跌额": 0.004,
        "涨跌幅": 5.13,
        "今开": 0.074,
        "最高": 0.083,
        "最低": 0.073,
        "昨收": 0.078,
        "成交量": 685000.0,
        "成交额": 51965.0
    },
    {
        "序号": 156,
        "symbol": "01626",
        "name": "嘉耀控股",
        "最新价": 4.1,
        "涨跌额": 0.2,
        "涨跌幅": 5.13,
        "今开": 3.92,
        "最高": 4.1,
        "最低": 3.8,
        "昨收": 3.9,
        "成交量": 868000.0,
        "成交额": 3373310.0
    },
    {
        "序号": 157,
        "symbol": "01023",
        "name": "时代集团控股",
        "最新价": 0.82,
        "涨跌额": 0.04,
        "涨跌幅": 5.13,
        "今开": 0.79,
        "最高": 0.83,
        "最低": 0.79,
        "昨收": 0.78,
        "成交量": 494000.0,
        "成交额": 404860.0
    },
    {
        "序号": 158,
        "symbol": "00040",
        "name": "金山科技工业",
        "最新价": 0.52,
        "涨跌额": 0.025,
        "涨跌幅": 5.05,
        "今开": 0.485,
        "最高": 0.52,
        "最低": 0.485,
        "昨收": 0.495,
        "成交量": 114000.0,
        "成交额": 58240.0
    },
    {
        "序号": 159,
        "symbol": "01798",
        "name": "大唐新能源",
        "最新价": 1.67,
        "涨跌额": 0.08,
        "涨跌幅": 5.03,
        "今开": 1.59,
        "最高": 1.68,
        "最低": 1.59,
        "昨收": 1.59,
        "成交量": 17758000.0,
        "成交额": 29291925.0
    },
    {
        "序号": 160,
        "symbol": "08048",
        "name": "御德国际控股",
        "最新价": 0.042,
        "涨跌额": 0.002,
        "涨跌幅": 5.0,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.041,
        "昨收": 0.04,
        "成交量": 1306000.0,
        "成交额": 54840.0
    },
    {
        "序号": 161,
        "symbol": "01756",
        "name": "中国职业教育",
        "最新价": 0.63,
        "涨跌额": 0.03,
        "涨跌幅": 5.0,
        "今开": 0.6,
        "最高": 0.64,
        "最低": 0.58,
        "昨收": 0.6,
        "成交量": 383000.0,
        "成交额": 227210.0
    },
    {
        "序号": 162,
        "symbol": "08536",
        "name": "TL NATURAL GAS",
        "最新价": 0.85,
        "涨跌额": 0.04,
        "涨跌幅": 4.94,
        "今开": 0.81,
        "最高": 0.86,
        "最低": 0.81,
        "昨收": 0.81,
        "成交量": 405000.0,
        "成交额": 339850.0
    },
    {
        "序号": 163,
        "symbol": "06966",
        "name": "中国万桐园",
        "最新价": 0.64,
        "涨跌额": 0.03,
        "涨跌幅": 4.92,
        "今开": 0.61,
        "最高": 0.64,
        "最低": 0.61,
        "昨收": 0.61,
        "成交量": 110000.0,
        "成交额": 67100.0
    },
    {
        "序号": 164,
        "symbol": "08431",
        "name": "浩柏国际",
        "最新价": 0.215,
        "涨跌额": 0.01,
        "涨跌幅": 4.88,
        "今开": 0.21,
        "最高": 0.218,
        "最低": 0.21,
        "昨收": 0.205,
        "成交量": 90000.0,
        "成交额": 18980.0
    },
    {
        "序号": 165,
        "symbol": "08056",
        "name": "生活概念",
        "最新价": 0.022,
        "涨跌额": 0.001,
        "涨跌幅": 4.76,
        "今开": 0.021,
        "最高": 0.023,
        "最低": 0.021,
        "昨收": 0.021,
        "成交量": 8355000.0,
        "成交额": 177150.0
    },
    {
        "序号": 166,
        "symbol": "06918",
        "name": "奇士达",
        "最新价": 2.2,
        "涨跌额": 0.1,
        "涨跌幅": 4.76,
        "今开": 2.12,
        "最高": 2.24,
        "最低": 2.09,
        "昨收": 2.1,
        "成交量": 14896000.0,
        "成交额": 32070400.0
    },
    {
        "序号": 167,
        "symbol": "01937",
        "name": "佳辰控股",
        "最新价": 0.33,
        "涨跌额": 0.015,
        "涨跌幅": 4.76,
        "今开": 0.32,
        "最高": 0.33,
        "最低": 0.32,
        "昨收": 0.315,
        "成交量": 25000.0,
        "成交额": 8100.0
    },
    {
        "序号": 168,
        "symbol": "01884",
        "name": "EPRINT集团",
        "最新价": 0.33,
        "涨跌额": 0.015,
        "涨跌幅": 4.76,
        "今开": 0.325,
        "最高": 0.335,
        "最低": 0.325,
        "昨收": 0.315,
        "成交量": 16000.0,
        "成交额": 5240.0
    },
    {
        "序号": 169,
        "symbol": "08367",
        "name": "倩碧控股",
        "最新价": 0.089,
        "涨跌额": 0.004,
        "涨跌幅": 4.71,
        "今开": 0.088,
        "最高": 0.089,
        "最低": 0.088,
        "昨收": 0.085,
        "成交量": 400000.0,
        "成交额": 35300.0
    },
    {
        "序号": 170,
        "symbol": "01903",
        "name": "JBB BUILDERS",
        "最新价": 0.67,
        "涨跌额": 0.03,
        "涨跌幅": 4.69,
        "今开": 0.67,
        "最高": 0.67,
        "最低": 0.67,
        "昨收": 0.64,
        "成交量": 8000.0,
        "成交额": 5380.0
    },
    {
        "序号": 171,
        "symbol": "01917",
        "name": "豆盟科技",
        "最新价": 0.09,
        "涨跌额": 0.004,
        "涨跌幅": 4.65,
        "今开": 0.08,
        "最高": 0.09,
        "最低": 0.08,
        "昨收": 0.086,
        "成交量": 510000.0,
        "成交额": 44010.0
    },
    {
        "序号": 172,
        "symbol": "00984",
        "name": "永旺",
        "最新价": 0.68,
        "涨跌额": 0.03,
        "涨跌幅": 4.62,
        "今开": 0.64,
        "最高": 0.68,
        "最低": 0.63,
        "昨收": 0.65,
        "成交量": 4000.0,
        "成交额": 2575.0
    },
    {
        "序号": 173,
        "symbol": "08283",
        "name": "中食民安",
        "最新价": 0.091,
        "涨跌额": 0.004,
        "涨跌幅": 4.6,
        "今开": 0.095,
        "最高": 0.096,
        "最低": 0.09,
        "昨收": 0.087,
        "成交量": 275000.0,
        "成交额": 25380.0
    },
    {
        "序号": 174,
        "symbol": "02371",
        "name": "创联控股",
        "最新价": 0.023,
        "涨跌额": 0.001,
        "涨跌幅": 4.55,
        "今开": 0.022,
        "最高": 0.023,
        "最低": 0.022,
        "昨收": 0.022,
        "成交量": 1168000.0,
        "成交额": 25700.0
    },
    {
        "序号": 175,
        "symbol": "01697",
        "name": "山东国信",
        "最新价": 0.345,
        "涨跌额": 0.015,
        "涨跌幅": 4.55,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.345,
        "昨收": 0.33,
        "成交量": 1800.0,
        "成交额": 621.0
    },
    {
        "序号": 176,
        "symbol": "01586",
        "name": "力鸿检验",
        "最新价": 1.61,
        "涨跌额": 0.07,
        "涨跌幅": 4.55,
        "今开": 1.6,
        "最高": 1.61,
        "最低": 1.55,
        "昨收": 1.54,
        "成交量": 532000.0,
        "成交额": 848760.0
    },
    {
        "序号": 177,
        "symbol": "03738",
        "name": "阜博集团",
        "最新价": 2.55,
        "涨跌额": 0.11,
        "涨跌幅": 4.51,
        "今开": 2.47,
        "最高": 2.59,
        "最低": 2.44,
        "昨收": 2.44,
        "成交量": 15361000.0,
        "成交额": 39007880.0
    },
    {
        "序号": 178,
        "symbol": "02457",
        "name": "步阳国际",
        "最新价": 0.35,
        "涨跌额": 0.015,
        "涨跌幅": 4.48,
        "今开": 0.34,
        "最高": 0.36,
        "最低": 0.34,
        "昨收": 0.335,
        "成交量": 375000.0,
        "成交额": 131275.0
    },
    {
        "序号": 179,
        "symbol": "01498",
        "name": "培力农本方",
        "最新价": 0.94,
        "涨跌额": 0.04,
        "涨跌幅": 4.44,
        "今开": 0.9,
        "最高": 0.94,
        "最低": 0.9,
        "昨收": 0.9,
        "成交量": 55000.0,
        "成交额": 50865.0
    },
    {
        "序号": 180,
        "symbol": "01338",
        "name": "霸王集团",
        "最新价": 0.047,
        "涨跌额": 0.002,
        "涨跌幅": 4.44,
        "今开": 0.045,
        "最高": 0.048,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 102000.0,
        "成交额": 4596.0
    },
    {
        "序号": 181,
        "symbol": "00486",
        "name": "俄铝",
        "最新价": 2.6,
        "涨跌额": 0.11,
        "涨跌幅": 4.42,
        "今开": 2.5,
        "最高": 2.62,
        "最低": 2.5,
        "昨收": 2.49,
        "成交量": 214000.0,
        "成交额": 537560.0
    },
    {
        "序号": 182,
        "symbol": "01973",
        "name": "天图投资",
        "最新价": 5.21,
        "涨跌额": 0.22,
        "涨跌幅": 4.41,
        "今开": 4.81,
        "最高": 5.25,
        "最低": 4.62,
        "昨收": 4.99,
        "成交量": 600000.0,
        "成交额": 3005644.0
    },
    {
        "序号": 183,
        "symbol": "02252",
        "name": "微创机器人-B",
        "最新价": 19.18,
        "涨跌额": 0.8,
        "涨跌幅": 4.35,
        "今开": 18.3,
        "最高": 19.24,
        "最低": 18.06,
        "昨收": 18.38,
        "成交量": 1130000.0,
        "成交额": 21258030.0
    },
    {
        "序号": 184,
        "symbol": "06820",
        "name": "友谊时光",
        "最新价": 1.44,
        "涨跌额": 0.06,
        "涨跌幅": 4.35,
        "今开": 1.38,
        "最高": 1.45,
        "最低": 1.33,
        "昨收": 1.38,
        "成交量": 13784000.0,
        "成交额": 19306180.0
    },
    {
        "序号": 185,
        "symbol": "01685",
        "name": "博耳电力",
        "最新价": 0.24,
        "涨跌额": 0.01,
        "涨跌幅": 4.35,
        "今开": 0.23,
        "最高": 0.249,
        "最低": 0.204,
        "昨收": 0.23,
        "成交量": 97000.0,
        "成交额": 20740.0
    },
    {
        "序号": 186,
        "symbol": "01082",
        "name": "源宇宙教育",
        "最新价": 1.68,
        "涨跌额": 0.07,
        "涨跌幅": 4.35,
        "今开": 1.59,
        "最高": 1.77,
        "最低": 1.59,
        "昨收": 1.61,
        "成交量": 348000.0,
        "成交额": 598240.0
    },
    {
        "序号": 187,
        "symbol": "00146",
        "name": "TAI PING CARPET",
        "最新价": 0.96,
        "涨跌额": 0.04,
        "涨跌幅": 4.35,
        "今开": 0.94,
        "最高": 0.96,
        "最低": 0.94,
        "昨收": 0.92,
        "成交量": 19000.0,
        "成交额": 18080.0
    },
    {
        "序号": 188,
        "symbol": "06996",
        "name": "德琪医药-B",
        "最新价": 2.17,
        "涨跌额": 0.09,
        "涨跌幅": 4.33,
        "今开": 2.01,
        "最高": 2.17,
        "最低": 1.95,
        "昨收": 2.08,
        "成交量": 605000.0,
        "成交额": 1223690.0
    },
    {
        "序号": 189,
        "symbol": "08328",
        "name": "信义储电",
        "最新价": 2.19,
        "涨跌额": 0.09,
        "涨跌幅": 4.29,
        "今开": 2.1,
        "最高": 2.24,
        "最低": 2.1,
        "昨收": 2.1,
        "成交量": 140000.0,
        "成交额": 298840.0
    },
    {
        "序号": 190,
        "symbol": "00428",
        "name": "中国天弓控股",
        "最新价": 0.195,
        "涨跌额": 0.008,
        "涨跌幅": 4.28,
        "今开": 0.187,
        "最高": 0.195,
        "最低": 0.185,
        "昨收": 0.187,
        "成交量": 1154000.0,
        "成交额": 218242.0
    },
    {
        "序号": 191,
        "symbol": "03332",
        "name": "中生联合",
        "最新价": 0.245,
        "涨跌额": 0.01,
        "涨跌幅": 4.26,
        "今开": 0.231,
        "最高": 0.245,
        "最低": 0.231,
        "昨收": 0.235,
        "成交量": 12000.0,
        "成交额": 2772.0
    },
    {
        "序号": 192,
        "symbol": "00245",
        "name": "中薇金融",
        "最新价": 0.049,
        "涨跌额": 0.002,
        "涨跌幅": 4.26,
        "今开": 0.047,
        "最高": 0.053,
        "最低": 0.045,
        "昨收": 0.047,
        "成交量": 163321800.0,
        "成交额": 8045853.0
    },
    {
        "序号": 193,
        "symbol": "03681",
        "name": "中国抗体-B",
        "最新价": 1.48,
        "涨跌额": 0.06,
        "涨跌幅": 4.23,
        "今开": 1.45,
        "最高": 1.61,
        "最低": 1.28,
        "昨收": 1.42,
        "成交量": 705600.0,
        "成交额": 1040460.0
    },
    {
        "序号": 194,
        "symbol": "00328",
        "name": "ALCO HOLDINGS",
        "最新价": 2.48,
        "涨跌额": 0.1,
        "涨跌幅": 4.2,
        "今开": 2.38,
        "最高": 2.48,
        "最低": 2.31,
        "昨收": 2.38,
        "成交量": 6664600.0,
        "成交额": 15783475.0
    },
    {
        "序号": 195,
        "symbol": "09908",
        "name": "嘉兴燃气",
        "最新价": 8.0,
        "涨跌额": 0.32,
        "涨跌幅": 4.17,
        "今开": 8.0,
        "最高": 8.0,
        "最低": 8.0,
        "昨收": 7.68,
        "成交量": 5000.0,
        "成交额": 40000.0
    },
    {
        "序号": 196,
        "symbol": "02552",
        "name": "华领医药-B",
        "最新价": 2.25,
        "涨跌额": 0.09,
        "涨跌幅": 4.17,
        "今开": 2.17,
        "最高": 2.32,
        "最低": 2.16,
        "昨收": 2.16,
        "成交量": 2498000.0,
        "成交额": 5639520.0
    },
    {
        "序号": 197,
        "symbol": "00102",
        "name": "凯升控股",
        "最新价": 0.05,
        "涨跌额": 0.002,
        "涨跌幅": 4.17,
        "今开": 0.048,
        "最高": 0.052,
        "最低": 0.048,
        "昨收": 0.048,
        "成交量": 2128000.0,
        "成交额": 107000.0
    },
    {
        "序号": 198,
        "symbol": "08172",
        "name": "拉近网娱",
        "最新价": 0.051,
        "涨跌额": 0.002,
        "涨跌幅": 4.08,
        "今开": 0.045,
        "最高": 0.052,
        "最低": 0.045,
        "昨收": 0.049,
        "成交量": 1040000.0,
        "成交额": 50900.0
    },
    {
        "序号": 199,
        "symbol": "02362",
        "name": "金川国际",
        "最新价": 0.51,
        "涨跌额": 0.02,
        "涨跌幅": 4.08,
        "今开": 0.495,
        "最高": 0.51,
        "最低": 0.485,
        "昨收": 0.49,
        "成交量": 15452000.0,
        "成交额": 7616380.0
    },
    {
        "序号": 200,
        "symbol": "02167",
        "name": "天润云",
        "最新价": 2.55,
        "涨跌额": 0.1,
        "涨跌幅": 4.08,
        "今开": 2.16,
        "最高": 2.55,
        "最低": 2.16,
        "昨收": 2.45,
        "成交量": 36000.0,
        "成交额": 89932.0
    },
    {
        "序号": 201,
        "symbol": "00243",
        "name": "QPL INT'L",
        "最新价": 0.18,
        "涨跌额": 0.007,
        "涨跌幅": 4.05,
        "今开": 0.17,
        "最高": 0.18,
        "最低": 0.17,
        "昨收": 0.173,
        "成交量": 18000.0,
        "成交额": 3150.0
    },
    {
        "序号": 202,
        "symbol": "08018",
        "name": "汇财金融投资",
        "最新价": 0.129,
        "涨跌额": 0.005,
        "涨跌幅": 4.03,
        "今开": 0.12,
        "最高": 0.13,
        "最低": 0.115,
        "昨收": 0.124,
        "成交量": 401000.0,
        "成交额": 47160.0
    },
    {
        "序号": 203,
        "symbol": "08187",
        "name": "积木集团",
        "最新价": 0.104,
        "涨跌额": 0.004,
        "涨跌幅": 4.0,
        "今开": 0.101,
        "最高": 0.106,
        "最低": 0.101,
        "昨收": 0.1,
        "成交量": 90000.0,
        "成交额": 9490.0
    },
    {
        "序号": 204,
        "symbol": "00689",
        "name": "长盈集团(控股)",
        "最新价": 0.026,
        "涨跌额": 0.001,
        "涨跌幅": 4.0,
        "今开": 0.024,
        "最高": 0.026,
        "最低": 0.023,
        "昨收": 0.025,
        "成交量": 2490000.0,
        "成交额": 59700.0
    },
    {
        "序号": 205,
        "symbol": "03068",
        "name": "FA南方以太币",
        "最新价": 13.8,
        "涨跌额": 0.53,
        "涨跌幅": 3.99,
        "今开": 14.0,
        "最高": 14.0,
        "最低": 13.79,
        "昨收": 13.27,
        "成交量": 409100.0,
        "成交额": 5677389.0
    },
    {
        "序号": 206,
        "symbol": "06889",
        "name": "DYNAM JAPAN",
        "最新价": 4.46,
        "涨跌额": 0.17,
        "涨跌幅": 3.96,
        "今开": 4.35,
        "最高": 4.46,
        "最低": 4.3,
        "昨收": 4.29,
        "成交量": 32058.0,
        "成交额": 138814.0
    },
    {
        "序号": 207,
        "symbol": "08035",
        "name": "骏高控股",
        "最新价": 0.159,
        "涨跌额": 0.006,
        "涨跌幅": 3.92,
        "今开": 0.159,
        "最高": 0.159,
        "最低": 0.159,
        "昨收": 0.153,
        "成交量": 20000.0,
        "成交额": 3180.0
    },
    {
        "序号": 208,
        "symbol": "06117",
        "name": "日照港裕廊",
        "最新价": 0.53,
        "涨跌额": 0.02,
        "涨跌幅": 3.92,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.53,
        "昨收": 0.51,
        "成交量": 12000.0,
        "成交额": 6360.0
    },
    {
        "序号": 209,
        "symbol": "00508",
        "name": "鼎亿集团投资",
        "最新价": 0.265,
        "涨跌额": 0.01,
        "涨跌幅": 3.92,
        "今开": 0.27,
        "最高": 0.28,
        "最低": 0.246,
        "昨收": 0.255,
        "成交量": 255000.0,
        "成交额": 64280.0
    },
    {
        "序号": 210,
        "symbol": "01621",
        "name": "域高国际控股",
        "最新价": 0.08,
        "涨跌额": 0.003,
        "涨跌幅": 3.9,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.08,
        "昨收": 0.077,
        "成交量": 8000.0,
        "成交额": 640.0
    },
    {
        "序号": 211,
        "symbol": "02158",
        "name": "医渡科技",
        "最新价": 5.08,
        "涨跌额": 0.19,
        "涨跌幅": 3.89,
        "今开": 4.96,
        "最高": 5.15,
        "最低": 4.91,
        "昨收": 4.89,
        "成交量": 3771500.0,
        "成交额": 19029447.0
    },
    {
        "序号": 212,
        "symbol": "09955",
        "name": "智云健康",
        "最新价": 6.46,
        "涨跌额": 0.24,
        "涨跌幅": 3.86,
        "今开": 6.5,
        "最高": 6.5,
        "最低": 6.23,
        "昨收": 6.22,
        "成交量": 530800.0,
        "成交额": 3389852.0
    },
    {
        "序号": 213,
        "symbol": "08545",
        "name": "佰悦集团",
        "最新价": 0.027,
        "涨跌额": 0.001,
        "涨跌幅": 3.85,
        "今开": 0.027,
        "最高": 0.028,
        "最低": 0.027,
        "昨收": 0.026,
        "成交量": 1376000.0,
        "成交额": 37176.0
    },
    {
        "序号": 214,
        "symbol": "06169",
        "name": "宇华教育",
        "最新价": 0.54,
        "涨跌额": 0.02,
        "涨跌幅": 3.85,
        "今开": 0.53,
        "最高": 0.54,
        "最低": 0.51,
        "昨收": 0.52,
        "成交量": 2442000.0,
        "成交额": 1268920.0
    },
    {
        "序号": 215,
        "symbol": "02136",
        "name": "利福中国",
        "最新价": 0.81,
        "涨跌额": 0.03,
        "涨跌幅": 3.85,
        "今开": 0.79,
        "最高": 0.82,
        "最低": 0.78,
        "昨收": 0.78,
        "成交量": 207000.0,
        "成交额": 164685.0
    },
    {
        "序号": 216,
        "symbol": "01127",
        "name": "狮子山集团",
        "最新价": 1.09,
        "涨跌额": 0.04,
        "涨跌幅": 3.81,
        "今开": 1.09,
        "最高": 1.09,
        "最低": 1.07,
        "昨收": 1.05,
        "成交量": 2189440.0,
        "成交额": 2364432.0
    },
    {
        "序号": 217,
        "symbol": "00687",
        "name": "泰升集团",
        "最新价": 0.41,
        "涨跌额": 0.015,
        "涨跌幅": 3.8,
        "今开": 0.395,
        "最高": 0.41,
        "最低": 0.395,
        "昨收": 0.395,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 218,
        "symbol": "02863",
        "name": "高丰集团控股",
        "最新价": 0.275,
        "涨跌额": 0.01,
        "涨跌幅": 3.77,
        "今开": 0.25,
        "最高": 0.285,
        "最低": 0.25,
        "昨收": 0.265,
        "成交量": 240000.0,
        "成交额": 63960.0
    },
    {
        "序号": 219,
        "symbol": "00434",
        "name": "博雅互动",
        "最新价": 0.55,
        "涨跌额": 0.02,
        "涨跌幅": 3.77,
        "今开": 0.53,
        "最高": 0.55,
        "最低": 0.52,
        "昨收": 0.53,
        "成交量": 581000.0,
        "成交额": 309040.0
    },
    {
        "序号": 220,
        "symbol": "08021",
        "name": "汇隆控股",
        "最新价": 0.028,
        "涨跌额": 0.001,
        "涨跌幅": 3.7,
        "今开": 0.028,
        "最高": 0.029,
        "最低": 0.025,
        "昨收": 0.027,
        "成交量": 13670000.0,
        "成交额": 366520.0
    },
    {
        "序号": 221,
        "symbol": "01792",
        "name": "CMON",
        "最新价": 0.028,
        "涨跌额": 0.001,
        "涨跌幅": 3.7,
        "今开": 0.028,
        "最高": 0.028,
        "最低": 0.028,
        "昨收": 0.027,
        "成交量": 210000.0,
        "成交额": 5880.0
    },
    {
        "序号": 222,
        "symbol": "06888",
        "name": "英达公路再生科技",
        "最新价": 0.197,
        "涨跌额": 0.007,
        "涨跌幅": 3.68,
        "今开": 0.19,
        "最高": 0.198,
        "最低": 0.188,
        "昨收": 0.19,
        "成交量": 101000.0,
        "成交额": 19513.0
    },
    {
        "序号": 223,
        "symbol": "00150",
        "name": "HYPEBEAST",
        "最新价": 0.226,
        "涨跌额": 0.008,
        "涨跌幅": 3.67,
        "今开": 0.221,
        "最高": 0.227,
        "最低": 0.221,
        "昨收": 0.218,
        "成交量": 81200.0,
        "成交额": 18099.0
    },
    {
        "序号": 224,
        "symbol": "02121",
        "name": "创新奇智",
        "最新价": 10.18,
        "涨跌额": 0.36,
        "涨跌幅": 3.67,
        "今开": 10.02,
        "最高": 10.78,
        "最低": 9.98,
        "昨收": 9.82,
        "成交量": 3230800.0,
        "成交额": 33505063.0
    },
    {
        "序号": 225,
        "symbol": "01745",
        "name": "LVJI TECH",
        "最新价": 0.57,
        "涨跌额": 0.02,
        "涨跌幅": 3.64,
        "今开": 0.56,
        "最高": 0.59,
        "最低": 0.55,
        "昨收": 0.55,
        "成交量": 528000.0,
        "成交额": 302940.0
    },
    {
        "序号": 226,
        "symbol": "01277",
        "name": "力量发展",
        "最新价": 0.57,
        "涨跌额": 0.02,
        "涨跌幅": 3.64,
        "今开": 0.55,
        "最高": 0.58,
        "最低": 0.55,
        "昨收": 0.55,
        "成交量": 1770000.0,
        "成交额": 997020.0
    },
    {
        "序号": 227,
        "symbol": "00993",
        "name": "华融金控",
        "最新价": 0.057,
        "涨跌额": 0.002,
        "涨跌幅": 3.64,
        "今开": 0.061,
        "最高": 0.061,
        "最低": 0.051,
        "昨收": 0.055,
        "成交量": 57000.0,
        "成交额": 2917.0
    },
    {
        "序号": 228,
        "symbol": "02381",
        "name": "蚬壳电业",
        "最新价": 0.086,
        "涨跌额": 0.003,
        "涨跌幅": 3.61,
        "今开": 0.083,
        "最高": 0.087,
        "最低": 0.083,
        "昨收": 0.083,
        "成交量": 200000.0,
        "成交额": 16880.0
    },
    {
        "序号": 229,
        "symbol": "00367",
        "name": "庄士机构国际",
        "最新价": 0.43,
        "涨跌额": 0.015,
        "涨跌幅": 3.61,
        "今开": 0.425,
        "最高": 0.455,
        "最低": 0.425,
        "昨收": 0.415,
        "成交量": 412000.0,
        "成交额": 179720.0
    },
    {
        "序号": 230,
        "symbol": "00195",
        "name": "绿科科技国际",
        "最新价": 0.43,
        "涨跌额": 0.015,
        "涨跌幅": 3.61,
        "今开": 0.405,
        "最高": 0.43,
        "最低": 0.405,
        "昨收": 0.415,
        "成交量": 324000.0,
        "成交额": 135410.0
    },
    {
        "序号": 231,
        "symbol": "02390",
        "name": "知乎-W",
        "最新价": 14.64,
        "涨跌额": 0.5,
        "涨跌幅": 3.54,
        "今开": 14.8,
        "最高": 14.8,
        "最低": 14.44,
        "昨收": 14.14,
        "成交量": 19800.0,
        "成交额": 289174.0
    },
    {
        "序号": 232,
        "symbol": "01913",
        "name": "普拉达",
        "最新价": 44.15,
        "涨跌额": 1.5,
        "涨跌幅": 3.52,
        "今开": 43.0,
        "最高": 44.5,
        "最低": 42.85,
        "昨收": 42.65,
        "成交量": 625212.0,
        "成交额": 27250571.0
    },
    {
        "序号": 233,
        "symbol": "01740",
        "name": "新石文化",
        "最新价": 0.059,
        "涨跌额": 0.002,
        "涨跌幅": 3.51,
        "今开": 0.057,
        "最高": 0.06,
        "最低": 0.057,
        "昨收": 0.057,
        "成交量": 15000.0,
        "成交额": 870.0
    },
    {
        "序号": 234,
        "symbol": "00488",
        "name": "丽新发展",
        "最新价": 0.89,
        "涨跌额": 0.03,
        "涨跌幅": 3.49,
        "今开": 0.83,
        "最高": 0.89,
        "最低": 0.83,
        "昨收": 0.86,
        "成交量": 2600.0,
        "成交额": 2170.0
    },
    {
        "序号": 235,
        "symbol": "00217",
        "name": "中国诚通发展集团",
        "最新价": 0.089,
        "涨跌额": 0.003,
        "涨跌幅": 3.49,
        "今开": 0.092,
        "最高": 0.092,
        "最低": 0.084,
        "昨收": 0.086,
        "成交量": 112000.0,
        "成交额": 9880.0
    },
    {
        "序号": 236,
        "symbol": "00432",
        "name": "盈大地产",
        "最新价": 0.238,
        "涨跌额": 0.008,
        "涨跌幅": 3.48,
        "今开": 0.23,
        "最高": 0.238,
        "最低": 0.228,
        "昨收": 0.23,
        "成交量": 406679.0,
        "成交额": 93898.0
    },
    {
        "序号": 237,
        "symbol": "01028",
        "name": "千百度",
        "最新价": 0.149,
        "涨跌额": 0.005,
        "涨跌幅": 3.47,
        "今开": 0.146,
        "最高": 0.149,
        "最低": 0.144,
        "昨收": 0.144,
        "成交量": 4677000.0,
        "成交额": 688588.0
    },
    {
        "序号": 238,
        "symbol": "02385",
        "name": "读书郎",
        "最新价": 6.99,
        "涨跌额": 0.23,
        "涨跌幅": 3.4,
        "今开": 6.77,
        "最高": 6.99,
        "最低": 6.7,
        "昨收": 6.76,
        "成交量": 201600.0,
        "成交额": 1384404.0
    },
    {
        "序号": 239,
        "symbol": "01645",
        "name": "海纳智能",
        "最新价": 1.23,
        "涨跌额": 0.04,
        "涨跌幅": 3.36,
        "今开": 1.22,
        "最高": 1.23,
        "最低": 1.2,
        "昨收": 1.19,
        "成交量": 5904000.0,
        "成交额": 7153400.0
    },
    {
        "序号": 240,
        "symbol": "08286",
        "name": "长城微光",
        "最新价": 0.062,
        "涨跌额": 0.002,
        "涨跌幅": 3.33,
        "今开": 0.062,
        "最高": 0.062,
        "最低": 0.062,
        "昨收": 0.06,
        "成交量": 10000.0,
        "成交额": 620.0
    },
    {
        "序号": 241,
        "symbol": "08220",
        "name": "比高集团",
        "最新价": 0.62,
        "涨跌额": 0.02,
        "涨跌幅": 3.33,
        "今开": 0.6,
        "最高": 0.62,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 50250.0,
        "成交额": 30132.0
    },
    {
        "序号": 242,
        "symbol": "01978",
        "name": "叙福楼集团",
        "最新价": 0.93,
        "涨跌额": 0.03,
        "涨跌幅": 3.33,
        "今开": 0.9,
        "最高": 0.93,
        "最低": 0.87,
        "昨收": 0.9,
        "成交量": 172000.0,
        "成交额": 155120.0
    },
    {
        "序号": 243,
        "symbol": "01617",
        "name": "南方通信",
        "最新价": 0.093,
        "涨跌额": 0.003,
        "涨跌幅": 3.33,
        "今开": 0.093,
        "最高": 0.093,
        "最低": 0.093,
        "昨收": 0.09,
        "成交量": 4000.0,
        "成交额": 372.0
    },
    {
        "序号": 244,
        "symbol": "01278",
        "name": "中国新城镇",
        "最新价": 0.062,
        "涨跌额": 0.002,
        "涨跌幅": 3.33,
        "今开": 0.062,
        "最高": 0.062,
        "最低": 0.062,
        "昨收": 0.06,
        "成交量": 267500.0,
        "成交额": 16585.0
    },
    {
        "序号": 245,
        "symbol": "01451",
        "name": "万成集团股份",
        "最新价": 0.94,
        "涨跌额": 0.03,
        "涨跌幅": 3.3,
        "今开": 0.94,
        "最高": 0.94,
        "最低": 0.94,
        "昨收": 0.91,
        "成交量": 2000.0,
        "成交额": 1880.0
    },
    {
        "序号": 246,
        "symbol": "01406",
        "name": "清晰医疗",
        "最新价": 0.63,
        "涨跌额": 0.02,
        "涨跌幅": 3.28,
        "今开": 0.61,
        "最高": 0.63,
        "最低": 0.59,
        "昨收": 0.61,
        "成交量": 97500.0,
        "成交额": 59225.0
    },
    {
        "序号": 247,
        "symbol": "08001",
        "name": "东方汇财证券",
        "最新价": 0.221,
        "涨跌额": 0.007,
        "涨跌幅": 3.27,
        "今开": 0.217,
        "最高": 0.225,
        "最低": 0.207,
        "昨收": 0.214,
        "成交量": 6912000.0,
        "成交额": 1502346.0
    },
    {
        "序号": 248,
        "symbol": "03983",
        "name": "中海石油化学",
        "最新价": 1.91,
        "涨跌额": 0.06,
        "涨跌幅": 3.24,
        "今开": 1.86,
        "最高": 1.93,
        "最低": 1.85,
        "昨收": 1.85,
        "成交量": 4848000.0,
        "成交额": 9187230.0
    },
    {
        "序号": 249,
        "symbol": "02501",
        "name": "迈越科技",
        "最新价": 1.28,
        "涨跌额": 0.04,
        "涨跌幅": 3.23,
        "今开": 1.24,
        "最高": 1.3,
        "最低": 1.22,
        "昨收": 1.24,
        "成交量": 270000.0,
        "成交额": 344220.0
    },
    {
        "序号": 250,
        "symbol": "02110",
        "name": "天成控股",
        "最新价": 0.192,
        "涨跌额": 0.006,
        "涨跌幅": 3.23,
        "今开": 0.186,
        "最高": 0.192,
        "最低": 0.186,
        "昨收": 0.186,
        "成交量": 133000.0,
        "成交额": 25253.0
    },
    {
        "序号": 251,
        "symbol": "01426",
        "name": "春泉产业信托",
        "最新价": 1.92,
        "涨跌额": 0.06,
        "涨跌幅": 3.23,
        "今开": 1.86,
        "最高": 1.92,
        "最低": 1.86,
        "昨收": 1.86,
        "成交量": 38000.0,
        "成交额": 72760.0
    },
    {
        "序号": 252,
        "symbol": "01401",
        "name": "SPROCOMM INTEL",
        "最新价": 2.9,
        "涨跌额": 0.09,
        "涨跌幅": 3.2,
        "今开": 2.9,
        "最高": 2.9,
        "最低": 2.9,
        "昨收": 2.81,
        "成交量": 2000.0,
        "成交额": 5800.0
    },
    {
        "序号": 253,
        "symbol": "01935",
        "name": "嘉宏教育",
        "最新价": 0.65,
        "涨跌额": 0.02,
        "涨跌幅": 3.17,
        "今开": 0.63,
        "最高": 0.67,
        "最低": 0.63,
        "昨收": 0.63,
        "成交量": 110000.0,
        "成交额": 73100.0
    },
    {
        "序号": 254,
        "symbol": "02232",
        "name": "晶苑国际",
        "最新价": 2.94,
        "涨跌额": 0.09,
        "涨跌幅": 3.16,
        "今开": 2.86,
        "最高": 2.99,
        "最低": 2.85,
        "昨收": 2.85,
        "成交量": 907000.0,
        "成交额": 2634755.0
    },
    {
        "序号": 255,
        "symbol": "01033",
        "name": "中石化油服",
        "最新价": 0.49,
        "涨跌额": 0.015,
        "涨跌幅": 3.16,
        "今开": 0.48,
        "最高": 0.49,
        "最低": 0.475,
        "昨收": 0.475,
        "成交量": 4440000.0,
        "成交额": 2144670.0
    },
    {
        "序号": 256,
        "symbol": "00716",
        "name": "胜狮货柜",
        "最新价": 0.49,
        "涨跌额": 0.015,
        "涨跌幅": 3.16,
        "今开": 0.49,
        "最高": 0.5,
        "最低": 0.49,
        "昨收": 0.475,
        "成交量": 915100.0,
        "成交额": 453429.0
    },
    {
        "序号": 257,
        "symbol": "00612",
        "name": "鼎益丰控股",
        "最新价": 0.98,
        "涨跌额": 0.03,
        "涨跌幅": 3.16,
        "今开": 0.95,
        "最高": 1.08,
        "最低": 0.94,
        "昨收": 0.95,
        "成交量": 2448000.0,
        "成交额": 2427600.0
    },
    {
        "序号": 258,
        "symbol": "08013",
        "name": "ECI TECH",
        "最新价": 0.033,
        "涨跌额": 0.001,
        "涨跌幅": 3.13,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.032,
        "成交量": 20000.0,
        "成交额": 660.0
    },
    {
        "序号": 259,
        "symbol": "00911",
        "name": "前海健康",
        "最新价": 0.033,
        "涨跌额": 0.001,
        "涨跌幅": 3.13,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.03,
        "昨收": 0.032,
        "成交量": 1505000.0,
        "成交额": 49650.0
    },
    {
        "序号": 260,
        "symbol": "02282",
        "name": "美高梅中国",
        "最新价": 9.27,
        "涨跌额": 0.28,
        "涨跌幅": 3.11,
        "今开": 9.18,
        "最高": 9.3,
        "最低": 8.94,
        "昨收": 8.99,
        "成交量": 5222604.0,
        "成交额": 47973221.0
    },
    {
        "序号": 261,
        "symbol": "08132",
        "name": "百能国际能源",
        "最新价": 0.135,
        "涨跌额": 0.004,
        "涨跌幅": 3.05,
        "今开": 0.125,
        "最高": 0.138,
        "最低": 0.124,
        "昨收": 0.131,
        "成交量": 520000.0,
        "成交额": 68450.0
    },
    {
        "序号": 262,
        "symbol": "02283",
        "name": "东江集团控股",
        "最新价": 1.35,
        "涨跌额": 0.04,
        "涨跌幅": 3.05,
        "今开": 1.31,
        "最高": 1.42,
        "最低": 1.3,
        "昨收": 1.31,
        "成交量": 2746000.0,
        "成交额": 3824580.0
    },
    {
        "序号": 263,
        "symbol": "00279",
        "name": "裕承科金",
        "最新价": 0.034,
        "涨跌额": 0.001,
        "涨跌幅": 3.03,
        "今开": 0.034,
        "最高": 0.034,
        "最低": 0.032,
        "昨收": 0.033,
        "成交量": 6510000.0,
        "成交额": 214596.0
    },
    {
        "序号": 264,
        "symbol": "02349",
        "name": "中国城市基础设施",
        "最新价": 0.069,
        "涨跌额": 0.002,
        "涨跌幅": 2.99,
        "今开": 0.07,
        "最高": 0.07,
        "最低": 0.069,
        "昨收": 0.067,
        "成交量": 20000.0,
        "成交额": 1384.0
    },
    {
        "序号": 265,
        "symbol": "06100",
        "name": "同道猎聘",
        "最新价": 5.56,
        "涨跌额": 0.16,
        "涨跌幅": 2.96,
        "今开": 5.4,
        "最高": 5.61,
        "最低": 5.35,
        "昨收": 5.4,
        "成交量": 640400.0,
        "成交额": 3538152.0
    },
    {
        "序号": 266,
        "symbol": "01253",
        "name": "中国绿地博大绿泽",
        "最新价": 0.105,
        "涨跌额": 0.003,
        "涨跌幅": 2.94,
        "今开": 0.12,
        "最高": 0.12,
        "最低": 0.105,
        "昨收": 0.102,
        "成交量": 72000.0,
        "成交额": 8180.0
    },
    {
        "序号": 267,
        "symbol": "02696",
        "name": "复宏汉霖",
        "最新价": 12.74,
        "涨跌额": 0.36,
        "涨跌幅": 2.91,
        "今开": 12.38,
        "最高": 12.84,
        "最低": 12.34,
        "昨收": 12.38,
        "成交量": 113100.0,
        "成交额": 1421684.0
    },
    {
        "序号": 268,
        "symbol": "02425",
        "name": "澳亚集团",
        "最新价": 2.13,
        "涨跌额": 0.06,
        "涨跌幅": 2.9,
        "今开": 2.01,
        "最高": 2.13,
        "最低": 2.01,
        "昨收": 2.07,
        "成交量": 329000.0,
        "成交额": 686730.0
    },
    {
        "序号": 269,
        "symbol": "00004",
        "name": "九龙仓集团",
        "最新价": 23.25,
        "涨跌额": 0.65,
        "涨跌幅": 2.88,
        "今开": 22.7,
        "最高": 23.7,
        "最低": 22.6,
        "昨收": 22.6,
        "成交量": 5536366.0,
        "成交额": 128541288.0
    },
    {
        "序号": 270,
        "symbol": "00072",
        "name": "超媒体控股",
        "最新价": 0.216,
        "涨跌额": 0.006,
        "涨跌幅": 2.86,
        "今开": 0.203,
        "最高": 0.22,
        "最低": 0.2,
        "昨收": 0.21,
        "成交量": 170000.0,
        "成交额": 34736.0
    },
    {
        "序号": 271,
        "symbol": "00222",
        "name": "闽信集团",
        "最新价": 2.9,
        "涨跌额": 0.08,
        "涨跌幅": 2.84,
        "今开": 2.82,
        "最高": 2.9,
        "最低": 2.82,
        "昨收": 2.82,
        "成交量": 32000.0,
        "成交额": 90400.0
    },
    {
        "序号": 272,
        "symbol": "06288",
        "name": "FAST RETAIL-DRS",
        "最新价": 20.15,
        "涨跌额": 0.55,
        "涨跌幅": 2.81,
        "今开": 20.15,
        "最高": 20.15,
        "最低": 20.15,
        "昨收": 19.6,
        "成交量": 600.0,
        "成交额": 12090.0
    },
    {
        "序号": 273,
        "symbol": "00029",
        "name": "达力集团",
        "最新价": 8.5,
        "涨跌额": 0.23,
        "涨跌幅": 2.78,
        "今开": 8.3,
        "最高": 8.5,
        "最低": 8.12,
        "昨收": 8.27,
        "成交量": 34000.0,
        "成交额": 281040.0
    },
    {
        "序号": 274,
        "symbol": "01490",
        "name": "车市科技",
        "最新价": 0.148,
        "涨跌额": 0.004,
        "涨跌幅": 2.78,
        "今开": 0.15,
        "最高": 0.16,
        "最低": 0.148,
        "昨收": 0.144,
        "成交量": 916000.0,
        "成交额": 144680.0
    },
    {
        "序号": 275,
        "symbol": "00925",
        "name": "北京建设",
        "最新价": 0.037,
        "涨跌额": 0.001,
        "涨跌幅": 2.78,
        "今开": 0.036,
        "最高": 0.037,
        "最低": 0.035,
        "昨收": 0.036,
        "成交量": 150000.0,
        "成交额": 5516.0
    },
    {
        "序号": 276,
        "symbol": "01729",
        "name": "汇聚科技",
        "最新价": 1.38,
        "涨跌额": 0.037,
        "涨跌幅": 2.76,
        "今开": 1.35,
        "最高": 1.38,
        "最低": 1.33,
        "昨收": 1.343,
        "成交量": 640000.0,
        "成交额": 862960.0
    },
    {
        "序号": 277,
        "symbol": "01662",
        "name": "义合控股",
        "最新价": 1.12,
        "涨跌额": 0.03,
        "涨跌幅": 2.75,
        "今开": 1.1,
        "最高": 1.12,
        "最低": 1.07,
        "昨收": 1.09,
        "成交量": 76000.0,
        "成交额": 83960.0
    },
    {
        "序号": 278,
        "symbol": "01797",
        "name": "东方甄选",
        "最新价": 33.65,
        "涨跌额": 0.9,
        "涨跌幅": 2.75,
        "今开": 32.8,
        "最高": 34.35,
        "最低": 32.8,
        "昨收": 32.75,
        "成交量": 13898958.0,
        "成交额": 468639376.0
    },
    {
        "序号": 279,
        "symbol": "01557",
        "name": "剑虹集团控股",
        "最新价": 0.75,
        "涨跌额": 0.02,
        "涨跌幅": 2.74,
        "今开": 0.81,
        "最高": 0.81,
        "最低": 0.75,
        "昨收": 0.73,
        "成交量": 104000.0,
        "成交额": 78640.0
    },
    {
        "序号": 280,
        "symbol": "00876",
        "name": "佳兆业健康",
        "最新价": 0.038,
        "涨跌额": 0.001,
        "涨跌幅": 2.7,
        "今开": 0.037,
        "最高": 0.038,
        "最低": 0.037,
        "昨收": 0.037,
        "成交量": 230000.0,
        "成交额": 8510.0
    },
    {
        "序号": 281,
        "symbol": "00720",
        "name": "意达利控股",
        "最新价": 0.152,
        "涨跌额": 0.004,
        "涨跌幅": 2.7,
        "今开": 0.145,
        "最高": 0.154,
        "最低": 0.145,
        "昨收": 0.148,
        "成交量": 300000.0,
        "成交额": 44900.0
    },
    {
        "序号": 282,
        "symbol": "00675",
        "name": "坚宝国际",
        "最新价": 0.38,
        "涨跌额": 0.01,
        "涨跌幅": 2.7,
        "今开": 0.36,
        "最高": 0.38,
        "最低": 0.36,
        "昨收": 0.37,
        "成交量": 50000.0,
        "成交额": 18000.0
    },
    {
        "序号": 283,
        "symbol": "08053",
        "name": "比优集团",
        "最新价": 0.39,
        "涨跌额": 0.01,
        "涨跌幅": 2.63,
        "今开": 0.395,
        "最高": 0.395,
        "最低": 0.38,
        "昨收": 0.38,
        "成交量": 720000.0,
        "成交额": 281100.0
    },
    {
        "序号": 284,
        "symbol": "03193",
        "name": "南方中证5G",
        "最新价": 5.265,
        "涨跌额": 0.135,
        "涨跌幅": 2.63,
        "今开": 5.155,
        "最高": 5.265,
        "最低": 5.155,
        "昨收": 5.13,
        "成交量": 300.0,
        "成交额": 1546.0
    },
    {
        "序号": 285,
        "symbol": "02738",
        "name": "华津国际控股",
        "最新价": 0.78,
        "涨跌额": 0.02,
        "涨跌幅": 2.63,
        "今开": 0.74,
        "最高": 0.82,
        "最低": 0.72,
        "昨收": 0.76,
        "成交量": 156000.0,
        "成交额": 119200.0
    },
    {
        "序号": 286,
        "symbol": "00643",
        "name": "恒富控股",
        "最新价": 0.39,
        "涨跌额": 0.01,
        "涨跌幅": 2.63,
        "今开": 0.39,
        "最高": 0.39,
        "最低": 0.39,
        "昨收": 0.38,
        "成交量": 10000.0,
        "成交额": 3900.0
    },
    {
        "序号": 287,
        "symbol": "02245",
        "name": "力勤资源",
        "最新价": 7.04,
        "涨跌额": 0.18,
        "涨跌幅": 2.62,
        "今开": 7.05,
        "最高": 7.32,
        "最低": 6.95,
        "昨收": 6.86,
        "成交量": 419600.0,
        "成交额": 2954484.0
    },
    {
        "序号": 288,
        "symbol": "09990",
        "name": "祖龙娱乐",
        "最新价": 1.96,
        "涨跌额": 0.05,
        "涨跌幅": 2.62,
        "今开": 1.89,
        "最高": 1.99,
        "最低": 1.84,
        "昨收": 1.91,
        "成交量": 495000.0,
        "成交额": 959470.0
    },
    {
        "序号": 289,
        "symbol": "01599",
        "name": "城建设计",
        "最新价": 1.96,
        "涨跌额": 0.05,
        "涨跌幅": 2.62,
        "今开": 1.91,
        "最高": 1.98,
        "最低": 1.91,
        "昨收": 1.91,
        "成交量": 153000.0,
        "成交额": 299150.0
    },
    {
        "序号": 290,
        "symbol": "09807",
        "name": "GX中国机智-U",
        "最新价": 5.7,
        "涨跌额": 0.145,
        "涨跌幅": 2.61,
        "今开": 5.595,
        "最高": 5.73,
        "最低": 5.595,
        "昨收": 5.555,
        "成交量": 23500.0,
        "成交额": 133063.0
    },
    {
        "序号": 291,
        "symbol": "00991",
        "name": "大唐发电",
        "最新价": 1.18,
        "涨跌额": 0.03,
        "涨跌幅": 2.61,
        "今开": 1.15,
        "最高": 1.19,
        "最低": 1.15,
        "昨收": 1.15,
        "成交量": 12518006.0,
        "成交额": 14684267.0
    },
    {
        "序号": 292,
        "symbol": "02866",
        "name": "中远海发",
        "最新价": 0.79,
        "涨跌额": 0.02,
        "涨跌幅": 2.6,
        "今开": 0.78,
        "最高": 0.79,
        "最低": 0.77,
        "昨收": 0.77,
        "成交量": 7331350.0,
        "成交额": 5703329.0
    },
    {
        "序号": 293,
        "symbol": "06611",
        "name": "三巽集团",
        "最新价": 0.159,
        "涨跌额": 0.004,
        "涨跌幅": 2.58,
        "今开": 0.18,
        "最高": 0.18,
        "最低": 0.159,
        "昨收": 0.155,
        "成交量": 2000.0,
        "成交额": 340.0
    },
    {
        "序号": 294,
        "symbol": "06826",
        "name": "昊海生物科技",
        "最新价": 41.8,
        "涨跌额": 1.05,
        "涨跌幅": 2.58,
        "今开": 40.25,
        "最高": 41.8,
        "最低": 40.25,
        "昨收": 40.75,
        "成交量": 130400.0,
        "成交额": 5389350.0
    },
    {
        "序号": 295,
        "symbol": "09889",
        "name": "东莞农商银行",
        "最新价": 7.18,
        "涨跌额": 0.18,
        "涨跌幅": 2.57,
        "今开": 7.0,
        "最高": 7.18,
        "最低": 7.0,
        "昨收": 7.0,
        "成交量": 158000.0,
        "成交额": 1106590.0
    },
    {
        "序号": 296,
        "symbol": "02832",
        "name": "博时科创50",
        "最新价": 5.99,
        "涨跌额": 0.15,
        "涨跌幅": 2.57,
        "今开": 5.84,
        "最高": 6.0,
        "最低": 5.84,
        "昨收": 5.84,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 297,
        "symbol": "08310",
        "name": "大丰港",
        "最新价": 0.4,
        "涨跌额": 0.01,
        "涨跌幅": 2.56,
        "今开": 0.41,
        "最高": 0.41,
        "最低": 0.36,
        "昨收": 0.39,
        "成交量": 1810000.0,
        "成交额": 712450.0
    },
    {
        "序号": 298,
        "symbol": "01862",
        "name": "景瑞控股",
        "最新价": 0.04,
        "涨跌额": 0.001,
        "涨跌幅": 2.56,
        "今开": 0.038,
        "最高": 0.045,
        "最低": 0.037,
        "昨收": 0.039,
        "成交量": 67000.0,
        "成交额": 2819.0
    },
    {
        "序号": 299,
        "symbol": "00618",
        "name": "北大资源",
        "最新价": 0.122,
        "涨跌额": 0.003,
        "涨跌幅": 2.52,
        "今开": 0.121,
        "最高": 0.125,
        "最低": 0.119,
        "昨收": 0.119,
        "成交量": 11398000.0,
        "成交额": 1386566.0
    },
    {
        "序号": 300,
        "symbol": "08316",
        "name": "中国红包",
        "最新价": 0.41,
        "涨跌额": 0.01,
        "涨跌幅": 2.5,
        "今开": 0.4,
        "最高": 0.41,
        "最低": 0.39,
        "昨收": 0.4,
        "成交量": 1810000.0,
        "成交额": 712500.0
    },
    {
        "序号": 301,
        "symbol": "01146",
        "name": "中国服饰控股",
        "最新价": 0.041,
        "涨跌额": 0.001,
        "涨跌幅": 2.5,
        "今开": 0.039,
        "最高": 0.041,
        "最低": 0.039,
        "昨收": 0.04,
        "成交量": 3256000.0,
        "成交额": 131878.0
    },
    {
        "序号": 302,
        "symbol": "00418",
        "name": "方正控股",
        "最新价": 0.41,
        "涨跌额": 0.01,
        "涨跌幅": 2.5,
        "今开": 0.4,
        "最高": 0.42,
        "最低": 0.4,
        "昨收": 0.4,
        "成交量": 158000.0,
        "成交额": 63680.0
    },
    {
        "序号": 303,
        "symbol": "02169",
        "name": "沧港铁路",
        "最新价": 8.25,
        "涨跌额": 0.2,
        "涨跌幅": 2.48,
        "今开": 8.2,
        "最高": 8.6,
        "最低": 8.03,
        "昨收": 8.05,
        "成交量": 528000.0,
        "成交额": 4386840.0
    },
    {
        "序号": 304,
        "symbol": "02798",
        "name": "久泰邦达能源",
        "最新价": 0.83,
        "涨跌额": 0.02,
        "涨跌幅": 2.47,
        "今开": 0.84,
        "最高": 0.84,
        "最低": 0.82,
        "昨收": 0.81,
        "成交量": 20000.0,
        "成交额": 16500.0
    },
    {
        "序号": 305,
        "symbol": "02722",
        "name": "重庆机电",
        "最新价": 0.83,
        "涨跌额": 0.02,
        "涨跌幅": 2.47,
        "今开": 0.81,
        "最高": 0.85,
        "最低": 0.81,
        "昨收": 0.81,
        "成交量": 7068000.0,
        "成交额": 5887920.0
    },
    {
        "序号": 306,
        "symbol": "01992",
        "name": "复星旅游文化",
        "最新价": 5.81,
        "涨跌额": 0.14,
        "涨跌幅": 2.47,
        "今开": 5.67,
        "最高": 5.83,
        "最低": 5.63,
        "昨收": 5.67,
        "成交量": 215600.0,
        "成交额": 1235408.0
    },
    {
        "序号": 307,
        "symbol": "01073",
        "name": "大禹金融",
        "最新价": 0.208,
        "涨跌额": 0.005,
        "涨跌幅": 2.46,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.208,
        "昨收": 0.203,
        "成交量": 5000.0,
        "成交额": 1100.0
    },
    {
        "序号": 308,
        "symbol": "02190",
        "name": "归创通桥-B",
        "最新价": 12.56,
        "涨跌额": 0.3,
        "涨跌幅": 2.45,
        "今开": 12.54,
        "最高": 12.86,
        "最低": 12.2,
        "昨收": 12.26,
        "成交量": 379000.0,
        "成交额": 4702025.0
    },
    {
        "序号": 309,
        "symbol": "02078",
        "name": "荣阳实业",
        "最新价": 0.126,
        "涨跌额": 0.003,
        "涨跌幅": 2.44,
        "今开": 0.125,
        "最高": 0.13,
        "最低": 0.125,
        "昨收": 0.123,
        "成交量": 322000.0,
        "成交额": 41810.0
    },
    {
        "序号": 310,
        "symbol": "00163",
        "name": "英皇国际",
        "最新价": 0.42,
        "涨跌额": 0.01,
        "涨跌幅": 2.44,
        "今开": 0.4,
        "最高": 0.42,
        "最低": 0.39,
        "昨收": 0.41,
        "成交量": 169680.0,
        "成交额": 67618.0
    },
    {
        "序号": 311,
        "symbol": "02306",
        "name": "乐华娱乐",
        "最新价": 5.08,
        "涨跌额": 0.12,
        "涨跌幅": 2.42,
        "今开": 4.9,
        "最高": 5.09,
        "最低": 4.9,
        "昨收": 4.96,
        "成交量": 1452000.0,
        "成交额": 7289580.0
    },
    {
        "序号": 312,
        "symbol": "00933",
        "name": "非凡领越",
        "最新价": 0.85,
        "涨跌额": 0.02,
        "涨跌幅": 2.41,
        "今开": 0.84,
        "最高": 0.86,
        "最低": 0.81,
        "昨收": 0.83,
        "成交量": 4794000.0,
        "成交额": 3983880.0
    },
    {
        "序号": 313,
        "symbol": "00956",
        "name": "新天绿色能源",
        "最新价": 2.57,
        "涨跌额": 0.06,
        "涨跌幅": 2.39,
        "今开": 2.49,
        "最高": 2.63,
        "最低": 2.49,
        "昨收": 2.51,
        "成交量": 15688136.0,
        "成交额": 40428140.0
    },
    {
        "序号": 314,
        "symbol": "03337",
        "name": "安东油田服务",
        "最新价": 0.43,
        "涨跌额": 0.01,
        "涨跌幅": 2.38,
        "今开": 0.435,
        "最高": 0.435,
        "最低": 0.425,
        "昨收": 0.42,
        "成交量": 1878000.0,
        "成交额": 809650.0
    },
    {
        "序号": 315,
        "symbol": "02807",
        "name": "GX中国机智",
        "最新价": 44.36,
        "涨跌额": 1.02,
        "涨跌幅": 2.35,
        "今开": 43.78,
        "最高": 44.7,
        "最低": 43.58,
        "昨收": 43.34,
        "成交量": 24200.0,
        "成交额": 1073384.0
    },
    {
        "序号": 316,
        "symbol": "01223",
        "name": "新沣集团",
        "最新价": 0.87,
        "涨跌额": 0.02,
        "涨跌幅": 2.35,
        "今开": 0.86,
        "最高": 0.88,
        "最低": 0.85,
        "昨收": 0.85,
        "成交量": 2490000.0,
        "成交额": 2172200.0
    },
    {
        "序号": 317,
        "symbol": "08491",
        "name": "COOL LINK",
        "最新价": 0.44,
        "涨跌额": 0.01,
        "涨跌幅": 2.33,
        "今开": 0.435,
        "最高": 0.44,
        "最低": 0.43,
        "昨收": 0.43,
        "成交量": 190000.0,
        "成交额": 82975.0
    },
    {
        "序号": 318,
        "symbol": "00204",
        "name": "资本界金控",
        "最新价": 0.88,
        "涨跌额": 0.02,
        "涨跌幅": 2.33,
        "今开": 0.86,
        "最高": 0.92,
        "最低": 0.85,
        "昨收": 0.86,
        "成交量": 6480400.0,
        "成交额": 5692264.0
    },
    {
        "序号": 319,
        "symbol": "02172",
        "name": "微创脑科学",
        "最新价": 12.42,
        "涨跌额": 0.28,
        "涨跌幅": 2.31,
        "今开": 12.0,
        "最高": 12.42,
        "最低": 12.0,
        "昨收": 12.14,
        "成交量": 576000.0,
        "成交额": 7041140.0
    },
    {
        "序号": 320,
        "symbol": "07266",
        "name": "南方两倍做多纳斯达克100",
        "最新价": 16.97,
        "涨跌额": 0.38,
        "涨跌幅": 2.29,
        "今开": 17.01,
        "最高": 17.04,
        "最低": 16.96,
        "昨收": 16.59,
        "成交量": 124200.0,
        "成交额": 2112018.0
    },
    {
        "序号": 321,
        "symbol": "06623",
        "name": "陆控",
        "最新价": 13.5,
        "涨跌额": 0.3,
        "涨跌幅": 2.27,
        "今开": 13.42,
        "最高": 13.5,
        "最低": 13.42,
        "昨收": 13.2,
        "成交量": 700.0,
        "成交额": 9410.0
    },
    {
        "序号": 322,
        "symbol": "03889",
        "name": "大成糖业",
        "最新价": 0.09,
        "涨跌额": 0.002,
        "涨跌幅": 2.27,
        "今开": 0.088,
        "最高": 0.09,
        "最低": 0.088,
        "昨收": 0.088,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 323,
        "symbol": "01429",
        "name": "天任集团",
        "最新价": 0.045,
        "涨跌额": 0.001,
        "涨跌幅": 2.27,
        "今开": 0.043,
        "最高": 0.045,
        "最低": 0.043,
        "昨收": 0.044,
        "成交量": 8000.0,
        "成交额": 344.0
    },
    {
        "序号": 324,
        "symbol": "01286",
        "name": "鹰普精密",
        "最新价": 2.26,
        "涨跌额": 0.05,
        "涨跌幅": 2.26,
        "今开": 2.16,
        "最高": 2.26,
        "最低": 2.16,
        "昨收": 2.21,
        "成交量": 27000.0,
        "成交额": 60560.0
    },
    {
        "序号": 325,
        "symbol": "01732",
        "name": "象兴国际",
        "最新价": 0.138,
        "涨跌额": 0.003,
        "涨跌幅": 2.22,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.137,
        "昨收": 0.135,
        "成交量": 3740000.0,
        "成交额": 504600.0
    },
    {
        "序号": 326,
        "symbol": "01167",
        "name": "加科思-B",
        "最新价": 3.68,
        "涨跌额": 0.08,
        "涨跌幅": 2.22,
        "今开": 3.46,
        "最高": 3.76,
        "最低": 3.46,
        "昨收": 3.6,
        "成交量": 2653374.0,
        "成交额": 9759082.0
    },
    {
        "序号": 327,
        "symbol": "00013",
        "name": "和黄医药",
        "最新价": 29.95,
        "涨跌额": 0.65,
        "涨跌幅": 2.22,
        "今开": 29.65,
        "最高": 31.3,
        "最低": 29.0,
        "昨收": 29.3,
        "成交量": 5759000.0,
        "成交额": 172855946.0
    },
    {
        "序号": 328,
        "symbol": "01712",
        "name": "龙资源",
        "最新价": 1.39,
        "涨跌额": 0.03,
        "涨跌幅": 2.21,
        "今开": 1.3,
        "最高": 1.39,
        "最低": 1.3,
        "昨收": 1.36,
        "成交量": 25000.0,
        "成交额": 32500.0
    },
    {
        "序号": 329,
        "symbol": "00798",
        "name": "中电光谷",
        "最新价": 0.465,
        "涨跌额": 0.01,
        "涨跌幅": 2.2,
        "今开": 0.46,
        "最高": 0.465,
        "最低": 0.46,
        "昨收": 0.455,
        "成交量": 328000.0,
        "成交额": 150920.0
    },
    {
        "序号": 330,
        "symbol": "06682",
        "name": "第四范式",
        "最新价": 52.2,
        "涨跌额": 1.1,
        "涨跌幅": 2.15,
        "今开": 50.35,
        "最高": 52.8,
        "最低": 49.9,
        "昨收": 51.1,
        "成交量": 156500.0,
        "成交额": 8115640.0
    },
    {
        "序号": 331,
        "symbol": "07261",
        "name": "华夏两倍做多纳斯达克100",
        "最新价": 21.88,
        "涨跌额": 0.46,
        "涨跌幅": 2.15,
        "今开": 21.98,
        "最高": 21.98,
        "最低": 21.88,
        "昨收": 21.42,
        "成交量": 9800.0,
        "成交额": 214652.0
    },
    {
        "序号": 332,
        "symbol": "03877",
        "name": "中国船舶租赁",
        "最新价": 1.43,
        "涨跌额": 0.03,
        "涨跌幅": 2.14,
        "今开": 1.41,
        "最高": 1.43,
        "最低": 1.4,
        "昨收": 1.4,
        "成交量": 492000.0,
        "成交额": 696118.0
    },
    {
        "序号": 333,
        "symbol": "00078",
        "name": "REGAL INT'L",
        "最新价": 2.86,
        "涨跌额": 0.06,
        "涨跌幅": 2.14,
        "今开": 2.86,
        "最高": 2.86,
        "最低": 2.86,
        "昨收": 2.8,
        "成交量": 2000.0,
        "成交额": 5720.0
    },
    {
        "序号": 334,
        "symbol": "82832",
        "name": "博时科创50-R",
        "最新价": 5.505,
        "涨跌额": 0.115,
        "涨跌幅": 2.13,
        "今开": 5.505,
        "最高": 5.505,
        "最低": 5.505,
        "昨收": 5.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 335,
        "symbol": "01655",
        "name": "OKURA HOLDINGS",
        "最新价": 0.48,
        "涨跌额": 0.01,
        "涨跌幅": 2.13,
        "今开": 0.48,
        "最高": 0.5,
        "最低": 0.47,
        "昨收": 0.47,
        "成交量": 2220000.0,
        "成交额": 1061375.0
    },
    {
        "序号": 336,
        "symbol": "00276",
        "name": "蒙古能源",
        "最新价": 0.48,
        "涨跌额": 0.01,
        "涨跌幅": 2.13,
        "今开": 0.47,
        "最高": 0.48,
        "最低": 0.455,
        "昨收": 0.47,
        "成交量": 15975.0,
        "成交额": 7324.0
    },
    {
        "序号": 337,
        "symbol": "02317",
        "name": "味丹国际",
        "最新价": 0.485,
        "涨跌额": 0.01,
        "涨跌幅": 2.11,
        "今开": 0.48,
        "最高": 0.485,
        "最低": 0.48,
        "昨收": 0.475,
        "成交量": 92000.0,
        "成交额": 44160.0
    },
    {
        "序号": 338,
        "symbol": "01961",
        "name": "多牛科技",
        "最新价": 1.46,
        "涨跌额": 0.03,
        "涨跌幅": 2.1,
        "今开": 1.45,
        "最高": 1.48,
        "最低": 1.4,
        "昨收": 1.43,
        "成交量": 1450000.0,
        "成交额": 2095460.0
    },
    {
        "序号": 339,
        "symbol": "02382",
        "name": "舜宇光学科技",
        "最新价": 68.2,
        "涨跌额": 1.4,
        "涨跌幅": 2.1,
        "今开": 67.5,
        "最高": 70.5,
        "最低": 66.6,
        "昨收": 66.8,
        "成交量": 7788873.0,
        "成交额": 534690512.0
    },
    {
        "序号": 340,
        "symbol": "09911",
        "name": "赤子城科技",
        "最新价": 1.95,
        "涨跌额": 0.04,
        "涨跌幅": 2.09,
        "今开": 1.88,
        "最高": 1.97,
        "最低": 1.88,
        "昨收": 1.91,
        "成交量": 1174000.0,
        "成交额": 2265050.0
    },
    {
        "序号": 341,
        "symbol": "00763",
        "name": "中兴通讯",
        "最新价": 16.72,
        "涨跌额": 0.34,
        "涨跌幅": 2.08,
        "今开": 16.36,
        "最高": 17.1,
        "最低": 16.14,
        "昨收": 16.38,
        "成交量": 9446520.0,
        "成交额": 158745850.0
    },
    {
        "序号": 342,
        "symbol": "02291",
        "name": "心泰医疗",
        "最新价": 27.05,
        "涨跌额": 0.55,
        "涨跌幅": 2.08,
        "今开": 26.8,
        "最高": 27.15,
        "最低": 26.4,
        "昨收": 26.5,
        "成交量": 76000.0,
        "成交额": 2035300.0
    },
    {
        "序号": 343,
        "symbol": "00027",
        "name": "银河娱乐",
        "最新价": 41.9,
        "涨跌额": 0.85,
        "涨跌幅": 2.07,
        "今开": 41.15,
        "最高": 42.0,
        "最低": 40.9,
        "昨收": 41.05,
        "成交量": 6656406.0,
        "成交额": 277419056.0
    },
    {
        "序号": 344,
        "symbol": "83151",
        "name": "PP科创50-R",
        "最新价": 5.44,
        "涨跌额": 0.11,
        "涨跌幅": 2.06,
        "今开": 5.425,
        "最高": 5.455,
        "最低": 5.425,
        "昨收": 5.33,
        "成交量": 371200.0,
        "成交额": 2018754.0
    },
    {
        "序号": 345,
        "symbol": "06108",
        "name": "新锐医药",
        "最新价": 0.05,
        "涨跌额": 0.001,
        "涨跌幅": 2.04,
        "今开": 0.049,
        "最高": 0.052,
        "最低": 0.048,
        "昨收": 0.049,
        "成交量": 4320000.0,
        "成交额": 213840.0
    },
    {
        "序号": 346,
        "symbol": "02433",
        "name": "中天湖南集团",
        "最新价": 0.25,
        "涨跌额": 0.005,
        "涨跌幅": 2.04,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.23,
        "昨收": 0.245,
        "成交量": 1112000.0,
        "成交额": 259102.0
    },
    {
        "序号": 347,
        "symbol": "00413",
        "name": "南华集团控股",
        "最新价": 0.05,
        "涨跌额": 0.001,
        "涨跌幅": 2.04,
        "今开": 0.043,
        "最高": 0.05,
        "最低": 0.043,
        "昨收": 0.049,
        "成交量": 336627.0,
        "成交额": 15223.0
    },
    {
        "序号": 348,
        "symbol": "09901",
        "name": "新东方-S",
        "最新价": 62.85,
        "涨跌额": 1.25,
        "涨跌幅": 2.03,
        "今开": 62.1,
        "最高": 63.8,
        "最低": 62.1,
        "昨收": 61.6,
        "成交量": 2255075.0,
        "成交额": 142183719.0
    },
    {
        "序号": 349,
        "symbol": "00113",
        "name": "迪生创建",
        "最新价": 4.53,
        "涨跌额": 0.09,
        "涨跌幅": 2.03,
        "今开": 4.44,
        "最高": 4.53,
        "最低": 4.44,
        "昨收": 4.44,
        "成交量": 14500.0,
        "成交额": 64630.0
    },
    {
        "序号": 350,
        "symbol": "81299",
        "name": "友邦保险-R",
        "最新价": 58.6,
        "涨跌额": 1.15,
        "涨跌幅": 2.0,
        "今开": 58.2,
        "最高": 59.1,
        "最低": 58.2,
        "昨收": 57.45,
        "成交量": 265600.0,
        "成交额": 15574460.0
    },
    {
        "序号": 351,
        "symbol": "08500",
        "name": "天泓文创",
        "最新价": 0.51,
        "涨跌额": 0.01,
        "涨跌幅": 2.0,
        "今开": 0.51,
        "最高": 0.51,
        "最低": 0.51,
        "昨收": 0.5,
        "成交量": 2000.0,
        "成交额": 1020.0
    },
    {
        "序号": 352,
        "symbol": "08279",
        "name": "亚博科技控股",
        "最新价": 0.204,
        "涨跌额": 0.004,
        "涨跌幅": 2.0,
        "今开": 0.195,
        "最高": 0.204,
        "最低": 0.193,
        "昨收": 0.2,
        "成交量": 4048000.0,
        "成交额": 801380.0
    },
    {
        "序号": 353,
        "symbol": "02209",
        "name": "喆丽控股",
        "最新价": 0.51,
        "涨跌额": 0.01,
        "涨跌幅": 2.0,
        "今开": 0.5,
        "最高": 0.51,
        "最低": 0.47,
        "昨收": 0.5,
        "成交量": 186000.0,
        "成交额": 91870.0
    },
    {
        "序号": 354,
        "symbol": "01869",
        "name": "利宝阁集团",
        "最新价": 0.204,
        "涨跌额": 0.004,
        "涨跌幅": 2.0,
        "今开": 0.204,
        "最高": 0.205,
        "最低": 0.2,
        "昨收": 0.2,
        "成交量": 520000.0,
        "成交额": 106260.0
    },
    {
        "序号": 355,
        "symbol": "01297",
        "name": "中国擎天软件",
        "最新价": 0.255,
        "涨跌额": 0.005,
        "涨跌幅": 2.0,
        "今开": 0.255,
        "最高": 0.27,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 1231000.0,
        "成交额": 320030.0
    },
    {
        "序号": 356,
        "symbol": "01250",
        "name": "山高新能源",
        "最新价": 2.05,
        "涨跌额": 0.04,
        "涨跌幅": 1.99,
        "今开": 2.05,
        "最高": 2.09,
        "最低": 2.03,
        "昨收": 2.01,
        "成交量": 239600.0,
        "成交额": 493918.0
    },
    {
        "序号": 357,
        "symbol": "02189",
        "name": "嘉涛(香港)控股",
        "最新价": 0.52,
        "涨跌额": 0.01,
        "涨跌幅": 1.96,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.5,
        "昨收": 0.51,
        "成交量": 527237.0,
        "成交额": 267323.0
    },
    {
        "序号": 358,
        "symbol": "01459",
        "name": "巨匠建设",
        "最新价": 0.52,
        "涨跌额": 0.01,
        "涨跌幅": 1.96,
        "今开": 0.51,
        "最高": 0.52,
        "最低": 0.51,
        "昨收": 0.51,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 359,
        "symbol": "00533",
        "name": "金利来集团",
        "最新价": 1.05,
        "涨跌额": 0.02,
        "涨跌幅": 1.94,
        "今开": 1.04,
        "最高": 1.05,
        "最低": 1.01,
        "昨收": 1.03,
        "成交量": 465000.0,
        "成交额": 483520.0
    },
    {
        "序号": 360,
        "symbol": "01299",
        "name": "友邦保险",
        "最新价": 63.85,
        "涨跌额": 1.2,
        "涨跌幅": 1.92,
        "今开": 62.4,
        "最高": 64.6,
        "最低": 62.25,
        "昨收": 62.65,
        "成交量": 32337037.0,
        "成交额": 2059575824.0
    },
    {
        "序号": 361,
        "symbol": "09669",
        "name": "北森控股",
        "最新价": 3.2,
        "涨跌额": 0.06,
        "涨跌幅": 1.91,
        "今开": 3.24,
        "最高": 3.31,
        "最低": 3.14,
        "昨收": 3.14,
        "成交量": 3691200.0,
        "成交额": 11928412.0
    },
    {
        "序号": 362,
        "symbol": "00103",
        "name": "首佳科技",
        "最新价": 0.215,
        "涨跌额": 0.004,
        "涨跌幅": 1.9,
        "今开": 0.213,
        "最高": 0.216,
        "最低": 0.21,
        "昨收": 0.211,
        "成交量": 3260000.0,
        "成交额": 697388.0
    },
    {
        "序号": 363,
        "symbol": "03132",
        "name": "三星环球半导体",
        "最新价": 16.16,
        "涨跌额": 0.3,
        "涨跌幅": 1.89,
        "今开": 15.86,
        "最高": 16.18,
        "最低": 15.86,
        "昨收": 15.86,
        "成交量": 32600.0,
        "成交额": 526236.0
    },
    {
        "序号": 364,
        "symbol": "01200",
        "name": "美联集团",
        "最新价": 0.54,
        "涨跌额": 0.01,
        "涨跌幅": 1.89,
        "今开": 0.53,
        "最高": 0.54,
        "最低": 0.52,
        "昨收": 0.53,
        "成交量": 74000.0,
        "成交额": 39220.0
    },
    {
        "序号": 365,
        "symbol": "00034",
        "name": "九龙建业",
        "最新价": 5.94,
        "涨跌额": 0.11,
        "涨跌幅": 1.89,
        "今开": 5.83,
        "最高": 5.96,
        "最低": 5.74,
        "昨收": 5.83,
        "成交量": 337000.0,
        "成交额": 1969970.0
    },
    {
        "序号": 366,
        "symbol": "09989",
        "name": "海普瑞",
        "最新价": 3.79,
        "涨跌额": 0.07,
        "涨跌幅": 1.88,
        "今开": 3.71,
        "最高": 3.79,
        "最低": 3.68,
        "昨收": 3.72,
        "成交量": 276500.0,
        "成交额": 1038715.0
    },
    {
        "序号": 367,
        "symbol": "03109",
        "name": "南方科创板50",
        "最新价": 8.13,
        "涨跌额": 0.15,
        "涨跌幅": 1.88,
        "今开": 8.0,
        "最高": 8.15,
        "最低": 8.0,
        "昨收": 7.98,
        "成交量": 141200.0,
        "成交额": 1150072.0
    },
    {
        "序号": 368,
        "symbol": "03800",
        "name": "协鑫科技",
        "最新价": 1.09,
        "涨跌额": 0.02,
        "涨跌幅": 1.87,
        "今开": 1.08,
        "最高": 1.1,
        "最低": 1.07,
        "昨收": 1.07,
        "成交量": 48322761.0,
        "成交额": 52662509.0
    },
    {
        "序号": 369,
        "symbol": "03076",
        "name": "富邦台湾半导体",
        "最新价": 6.02,
        "涨跌额": 0.11,
        "涨跌幅": 1.86,
        "今开": 6.0,
        "最高": 6.02,
        "最低": 6.0,
        "昨收": 5.91,
        "成交量": 2000.0,
        "成交额": 12000.0
    },
    {
        "序号": 370,
        "symbol": "00455",
        "name": "天大药业",
        "最新价": 0.219,
        "涨跌额": 0.004,
        "涨跌幅": 1.86,
        "今开": 0.215,
        "最高": 0.219,
        "最低": 0.213,
        "昨收": 0.215,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 371,
        "symbol": "08451",
        "name": "日光控股",
        "最新价": 0.22,
        "涨跌额": 0.004,
        "涨跌幅": 1.85,
        "今开": 0.216,
        "最高": 0.221,
        "最低": 0.216,
        "昨收": 0.216,
        "成交量": 820000.0,
        "成交额": 177980.0
    },
    {
        "序号": 372,
        "symbol": "00343",
        "name": "文化传信",
        "最新价": 0.275,
        "涨跌额": 0.005,
        "涨跌幅": 1.85,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.255,
        "昨收": 0.27,
        "成交量": 2317600.0,
        "成交额": 608094.0
    },
    {
        "序号": 373,
        "symbol": "00339",
        "name": "核心经济投资",
        "最新价": 0.275,
        "涨跌额": 0.005,
        "涨跌幅": 1.85,
        "今开": 0.27,
        "最高": 0.275,
        "最低": 0.27,
        "昨收": 0.27,
        "成交量": 110000.0,
        "成交额": 30050.0
    },
    {
        "序号": 374,
        "symbol": "00196",
        "name": "宏华集团",
        "最新价": 0.11,
        "涨跌额": 0.002,
        "涨跌幅": 1.85,
        "今开": 0.111,
        "最高": 0.112,
        "最低": 0.108,
        "昨收": 0.108,
        "成交量": 739000.0,
        "成交额": 80794.0
    },
    {
        "序号": 375,
        "symbol": "00762",
        "name": "中国联通",
        "最新价": 4.97,
        "涨跌额": 0.09,
        "涨跌幅": 1.84,
        "今开": 4.91,
        "最高": 4.97,
        "最低": 4.86,
        "昨收": 4.88,
        "成交量": 10437686.0,
        "成交额": 51503099.0
    },
    {
        "序号": 376,
        "symbol": "08350",
        "name": "骏溢环球金融",
        "最新价": 0.28,
        "涨跌额": 0.005,
        "涨跌幅": 1.82,
        "今开": 0.28,
        "最高": 0.285,
        "最低": 0.27,
        "昨收": 0.275,
        "成交量": 600000.0,
        "成交额": 166920.0
    },
    {
        "序号": 377,
        "symbol": "00916",
        "name": "龙源电力",
        "最新价": 5.6,
        "涨跌额": 0.1,
        "涨跌幅": 1.82,
        "今开": 5.54,
        "最高": 5.63,
        "最低": 5.43,
        "昨收": 5.5,
        "成交量": 11780502.0,
        "成交额": 65738276.0
    },
    {
        "序号": 378,
        "symbol": "00759",
        "name": "CEC INT'L HOLD",
        "最新价": 0.28,
        "涨跌额": 0.005,
        "涨跌幅": 1.82,
        "今开": 0.275,
        "最高": 0.28,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 379,
        "symbol": "00291",
        "name": "华润啤酒",
        "最新价": 33.65,
        "涨跌额": 0.6,
        "涨跌幅": 1.82,
        "今开": 33.3,
        "最高": 34.25,
        "最低": 33.15,
        "昨收": 33.05,
        "成交量": 7527809.0,
        "成交额": 252931455.0
    },
    {
        "序号": 380,
        "symbol": "00856",
        "name": "伟仕佳杰",
        "最新价": 3.94,
        "涨跌额": 0.07,
        "涨跌幅": 1.81,
        "今开": 3.87,
        "最高": 3.98,
        "最低": 3.87,
        "昨收": 3.87,
        "成交量": 3282000.0,
        "成交额": 12901060.0
    },
    {
        "序号": 381,
        "symbol": "09699",
        "name": "顺丰同城",
        "最新价": 10.18,
        "涨跌额": 0.18,
        "涨跌幅": 1.8,
        "今开": 10.0,
        "最高": 10.2,
        "最低": 9.8,
        "昨收": 10.0,
        "成交量": 1195200.0,
        "成交额": 11990306.0
    },
    {
        "序号": 382,
        "symbol": "09961",
        "name": "携程集团-S",
        "最新价": 260.8,
        "涨跌额": 4.6,
        "涨跌幅": 1.8,
        "今开": 256.8,
        "最高": 263.6,
        "最低": 256.0,
        "昨收": 256.2,
        "成交量": 1198988.0,
        "成交额": 312438048.0
    },
    {
        "序号": 383,
        "symbol": "00168",
        "name": "青岛啤酒股份",
        "最新价": 51.2,
        "涨跌额": 0.9,
        "涨跌幅": 1.79,
        "今开": 50.75,
        "最高": 51.9,
        "最低": 50.05,
        "昨收": 50.3,
        "成交量": 4032618.0,
        "成交额": 205835326.0
    },
    {
        "序号": 384,
        "symbol": "01647",
        "name": "雄岸科技",
        "最新价": 0.057,
        "涨跌额": 0.001,
        "涨跌幅": 1.79,
        "今开": 0.056,
        "最高": 0.058,
        "最低": 0.056,
        "昨收": 0.056,
        "成交量": 440000.0,
        "成交额": 25330.0
    },
    {
        "序号": 385,
        "symbol": "01148",
        "name": "新晨动力",
        "最新价": 0.285,
        "涨跌额": 0.005,
        "涨跌幅": 1.79,
        "今开": 0.295,
        "最高": 0.305,
        "最低": 0.285,
        "昨收": 0.28,
        "成交量": 784000.0,
        "成交额": 232795.0
    },
    {
        "序号": 386,
        "symbol": "01793",
        "name": "伟工控股",
        "最新价": 0.172,
        "涨跌额": 0.003,
        "涨跌幅": 1.78,
        "今开": 0.172,
        "最高": 0.172,
        "最低": 0.172,
        "昨收": 0.169,
        "成交量": 200000.0,
        "成交额": 34400.0
    },
    {
        "序号": 387,
        "symbol": "09966",
        "name": "康宁杰瑞制药-B",
        "最新价": 6.38,
        "涨跌额": 0.11,
        "涨跌幅": 1.75,
        "今开": 6.37,
        "最高": 6.44,
        "最低": 6.11,
        "昨收": 6.27,
        "成交量": 5017668.0,
        "成交额": 31487708.0
    },
    {
        "序号": 388,
        "symbol": "08509",
        "name": "威扬酒业控股",
        "最新价": 0.58,
        "涨跌额": 0.01,
        "涨跌幅": 1.75,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.54,
        "昨收": 0.57,
        "成交量": 80000.0,
        "成交额": 43680.0
    },
    {
        "序号": 389,
        "symbol": "01086",
        "name": "好孩子国际",
        "最新价": 0.58,
        "涨跌额": 0.01,
        "涨跌幅": 1.75,
        "今开": 0.55,
        "最高": 0.58,
        "最低": 0.55,
        "昨收": 0.57,
        "成交量": 510000.0,
        "成交额": 291990.0
    },
    {
        "序号": 390,
        "symbol": "06622",
        "name": "兆科眼科-B",
        "最新价": 4.07,
        "涨跌额": 0.07,
        "涨跌幅": 1.75,
        "今开": 4.0,
        "最高": 4.07,
        "最低": 3.95,
        "昨收": 4.0,
        "成交量": 161000.0,
        "成交额": 650990.0
    },
    {
        "序号": 391,
        "symbol": "01522",
        "name": "京投交通科技",
        "最新价": 0.233,
        "涨跌额": 0.004,
        "涨跌幅": 1.75,
        "今开": 0.237,
        "最高": 0.237,
        "最低": 0.228,
        "昨收": 0.229,
        "成交量": 20000.0,
        "成交额": 4668.0
    },
    {
        "序号": 392,
        "symbol": "09151",
        "name": "PP科创50-U",
        "最新价": 0.759,
        "涨跌额": 0.013,
        "涨跌幅": 1.74,
        "今开": 0.757,
        "最高": 0.76,
        "最低": 0.757,
        "昨收": 0.746,
        "成交量": 453800.0,
        "成交额": 344311.0
    },
    {
        "序号": 393,
        "symbol": "06088",
        "name": "FIT HON TENG",
        "最新价": 1.17,
        "涨跌额": 0.02,
        "涨跌幅": 1.74,
        "今开": 1.16,
        "最高": 1.18,
        "最低": 1.14,
        "昨收": 1.15,
        "成交量": 2489000.0,
        "成交额": 2892020.0
    },
    {
        "序号": 394,
        "symbol": "08179",
        "name": "百利达集团控股",
        "最新价": 0.059,
        "涨跌额": 0.001,
        "涨跌幅": 1.72,
        "今开": 0.056,
        "最高": 0.062,
        "最低": 0.056,
        "昨收": 0.058,
        "成交量": 828000.0,
        "成交额": 48300.0
    },
    {
        "序号": 395,
        "symbol": "03768",
        "name": "滇池水务",
        "最新价": 0.59,
        "涨跌额": 0.01,
        "涨跌幅": 1.72,
        "今开": 0.58,
        "最高": 0.59,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 396,
        "symbol": "02841",
        "name": "GX中国医疗科技",
        "最新价": 48.6,
        "涨跌额": 0.82,
        "涨跌幅": 1.72,
        "今开": 48.12,
        "最高": 48.8,
        "最低": 48.12,
        "昨收": 47.78,
        "成交量": 60323.0,
        "成交额": 2932158.0
    },
    {
        "序号": 397,
        "symbol": "02814",
        "name": "三星FANG",
        "最新价": 23.78,
        "涨跌额": 0.4,
        "涨跌幅": 1.71,
        "今开": 23.78,
        "最高": 23.8,
        "最低": 23.78,
        "昨收": 23.38,
        "成交量": 1000.0,
        "成交额": 23784.0
    },
    {
        "序号": 398,
        "symbol": "00431",
        "name": "大中华金融",
        "最新价": 0.119,
        "涨跌额": 0.002,
        "涨跌幅": 1.71,
        "今开": 0.118,
        "最高": 0.119,
        "最低": 0.114,
        "昨收": 0.117,
        "成交量": 468000.0,
        "成交额": 55024.0
    },
    {
        "序号": 399,
        "symbol": "09919",
        "name": "艾德韦宣集团",
        "最新价": 1.2,
        "涨跌额": 0.02,
        "涨跌幅": 1.69,
        "今开": 1.17,
        "最高": 1.2,
        "最低": 1.17,
        "昨收": 1.18,
        "成交量": 428000.0,
        "成交额": 506300.0
    },
    {
        "序号": 400,
        "symbol": "01957",
        "name": "MBV INTL",
        "最新价": 0.6,
        "涨跌额": 0.01,
        "涨跌幅": 1.69,
        "今开": 0.61,
        "最高": 0.61,
        "最低": 0.6,
        "昨收": 0.59,
        "成交量": 17500.0,
        "成交额": 10650.0
    },
    {
        "序号": 401,
        "symbol": "01788",
        "name": "国泰君安国际",
        "最新价": 0.6,
        "涨跌额": 0.01,
        "涨跌幅": 1.69,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.59,
        "昨收": 0.59,
        "成交量": 3782000.0,
        "成交额": 2262130.0
    },
    {
        "序号": 402,
        "symbol": "01778",
        "name": "彩生活",
        "最新价": 0.3,
        "涨跌额": 0.005,
        "涨跌幅": 1.69,
        "今开": 0.3,
        "最高": 0.31,
        "最低": 0.295,
        "昨收": 0.295,
        "成交量": 35000.0,
        "成交额": 10515.0
    },
    {
        "序号": 403,
        "symbol": "00722",
        "name": "联合医务",
        "最新价": 0.6,
        "涨跌额": 0.01,
        "涨跌幅": 1.69,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.59,
        "成交量": 502000.0,
        "成交额": 301200.0
    },
    {
        "序号": 404,
        "symbol": "03047",
        "name": "F山证铁矿石",
        "最新价": 24.02,
        "涨跌额": 0.4,
        "涨跌幅": 1.69,
        "今开": 24.02,
        "最高": 24.1,
        "最低": 23.98,
        "昨收": 23.62,
        "成交量": 4600.0,
        "成交额": 110528.0
    },
    {
        "序号": 405,
        "symbol": "00696",
        "name": "中国民航信息网络",
        "最新价": 13.24,
        "涨跌额": 0.22,
        "涨跌幅": 1.69,
        "今开": 13.22,
        "最高": 13.46,
        "最低": 13.0,
        "昨收": 13.02,
        "成交量": 2469000.0,
        "成交额": 32830120.0
    },
    {
        "序号": 406,
        "symbol": "06862",
        "name": "海底捞",
        "最新价": 14.5,
        "涨跌额": 0.24,
        "涨跌幅": 1.68,
        "今开": 14.26,
        "最高": 14.6,
        "最低": 14.04,
        "昨收": 14.26,
        "成交量": 16140957.0,
        "成交额": 232556108.0
    },
    {
        "序号": 407,
        "symbol": "00041",
        "name": "鹰君",
        "最新价": 12.12,
        "涨跌额": 0.2,
        "涨跌幅": 1.68,
        "今开": 12.0,
        "最高": 12.18,
        "最低": 11.92,
        "昨收": 11.92,
        "成交量": 70316.0,
        "成交额": 851393.0
    },
    {
        "序号": 408,
        "symbol": "08200",
        "name": "修身堂",
        "最新价": 0.61,
        "涨跌额": 0.01,
        "涨跌幅": 1.67,
        "今开": 0.6,
        "最高": 0.61,
        "最低": 0.59,
        "昨收": 0.6,
        "成交量": 41500.0,
        "成交额": 24670.0
    },
    {
        "序号": 409,
        "symbol": "06136",
        "name": "康达环保",
        "最新价": 0.244,
        "涨跌额": 0.004,
        "涨跌幅": 1.67,
        "今开": 0.244,
        "最高": 0.244,
        "最低": 0.244,
        "昨收": 0.24,
        "成交量": 729000.0,
        "成交额": 174996.0
    },
    {
        "序号": 410,
        "symbol": "02343",
        "name": "太平洋航运",
        "最新价": 2.44,
        "涨跌额": 0.04,
        "涨跌幅": 1.67,
        "今开": 2.37,
        "最高": 2.46,
        "最低": 2.35,
        "昨收": 2.4,
        "成交量": 8057461.0,
        "成交额": 19656192.0
    },
    {
        "序号": 411,
        "symbol": "01405",
        "name": "达势股份",
        "最新价": 61.0,
        "涨跌额": 1.0,
        "涨跌幅": 1.67,
        "今开": 60.0,
        "最高": 61.5,
        "最低": 59.85,
        "昨收": 60.0,
        "成交量": 98400.0,
        "成交额": 5980700.0
    },
    {
        "序号": 412,
        "symbol": "01300",
        "name": "俊知集团",
        "最新价": 0.305,
        "涨跌额": 0.005,
        "涨跌幅": 1.67,
        "今开": 0.31,
        "最高": 0.32,
        "最低": 0.3,
        "昨收": 0.3,
        "成交量": 154000.0,
        "成交额": 47450.0
    },
    {
        "序号": 413,
        "symbol": "01563",
        "name": "友联国际教育租赁",
        "最新价": 1.23,
        "涨跌额": 0.02,
        "涨跌幅": 1.65,
        "今开": 1.22,
        "最高": 1.33,
        "最低": 1.2,
        "昨收": 1.21,
        "成交量": 46545000.0,
        "成交额": 58900530.0
    },
    {
        "序号": 414,
        "symbol": "80291",
        "name": "华润啤酒-R",
        "最新价": 30.8,
        "涨跌额": 0.5,
        "涨跌幅": 1.65,
        "今开": 30.85,
        "最高": 30.85,
        "最低": 30.8,
        "昨收": 30.3,
        "成交量": 4000.0,
        "成交额": 123400.0
    },
    {
        "序号": 415,
        "symbol": "89988",
        "name": "阿里巴巴-SWR",
        "最新价": 64.95,
        "涨跌额": 1.05,
        "涨跌幅": 1.64,
        "今开": 64.25,
        "最高": 65.0,
        "最低": 63.7,
        "昨收": 63.9,
        "成交量": 50500.0,
        "成交额": 3260865.0
    },
    {
        "序号": 416,
        "symbol": "01070",
        "name": "TCL电子",
        "最新价": 2.48,
        "涨跌额": 0.04,
        "涨跌幅": 1.64,
        "今开": 2.45,
        "最高": 2.48,
        "最低": 2.42,
        "昨收": 2.44,
        "成交量": 4533330.0,
        "成交额": 11112952.0
    },
    {
        "序号": 417,
        "symbol": "00775",
        "name": "长江生命科技",
        "最新价": 0.62,
        "涨跌额": 0.01,
        "涨跌幅": 1.64,
        "今开": 0.61,
        "最高": 0.63,
        "最低": 0.61,
        "昨收": 0.61,
        "成交量": 1506000.0,
        "成交额": 930300.0
    },
    {
        "序号": 418,
        "symbol": "01975",
        "name": "新兴印刷",
        "最新价": 0.63,
        "涨跌额": 0.01,
        "涨跌幅": 1.61,
        "今开": 0.64,
        "最高": 0.64,
        "最低": 0.63,
        "昨收": 0.62,
        "成交量": 8000.0,
        "成交额": 5120.0
    },
    {
        "序号": 419,
        "symbol": "02187",
        "name": "智欣集团控股",
        "最新价": 1.27,
        "涨跌额": 0.02,
        "涨跌幅": 1.6,
        "今开": 1.25,
        "最高": 1.33,
        "最低": 1.23,
        "昨收": 1.25,
        "成交量": 5478000.0,
        "成交额": 7009160.0
    },
    {
        "序号": 420,
        "symbol": "01795",
        "name": "亚东集团",
        "最新价": 4.45,
        "涨跌额": 0.07,
        "涨跌幅": 1.6,
        "今开": 4.36,
        "最高": 4.45,
        "最低": 4.3,
        "昨收": 4.38,
        "成交量": 690000.0,
        "成交额": 2991300.0
    },
    {
        "序号": 421,
        "symbol": "03868",
        "name": "信义能源",
        "最新价": 1.28,
        "涨跌额": 0.02,
        "涨跌幅": 1.59,
        "今开": 1.27,
        "最高": 1.29,
        "最低": 1.26,
        "昨收": 1.26,
        "成交量": 4845000.0,
        "成交额": 6185580.0
    },
    {
        "序号": 422,
        "symbol": "01206",
        "name": "同方泰德",
        "最新价": 0.32,
        "涨跌额": 0.005,
        "涨跌幅": 1.59,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.315,
        "成交量": 200000.0,
        "成交额": 64000.0
    },
    {
        "序号": 423,
        "symbol": "02848",
        "name": "TR韩国",
        "最新价": 577.8,
        "涨跌额": 9.0,
        "涨跌幅": 1.58,
        "今开": 577.8,
        "最高": 577.8,
        "最低": 577.8,
        "昨收": 568.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 424,
        "symbol": "00116",
        "name": "周生生",
        "最新价": 9.09,
        "涨跌额": 0.14,
        "涨跌幅": 1.56,
        "今开": 8.94,
        "最高": 9.15,
        "最低": 8.94,
        "昨收": 8.95,
        "成交量": 149000.0,
        "成交额": 1347740.0
    },
    {
        "序号": 425,
        "symbol": "02127",
        "name": "汇森家居",
        "最新价": 0.13,
        "涨跌额": 0.002,
        "涨跌幅": 1.56,
        "今开": 0.132,
        "最高": 0.132,
        "最低": 0.126,
        "昨收": 0.128,
        "成交量": 1528000.0,
        "成交额": 196530.0
    },
    {
        "序号": 426,
        "symbol": "00737",
        "name": "湾区发展",
        "最新价": 1.3,
        "涨跌额": 0.02,
        "涨跌幅": 1.56,
        "今开": 1.3,
        "最高": 1.35,
        "最低": 1.28,
        "昨收": 1.28,
        "成交量": 260500.0,
        "成交额": 347267.0
    },
    {
        "序号": 427,
        "symbol": "01450",
        "name": "交个朋友控股",
        "最新价": 1.31,
        "涨跌额": 0.02,
        "涨跌幅": 1.55,
        "今开": 1.29,
        "最高": 1.32,
        "最低": 1.27,
        "昨收": 1.29,
        "成交量": 862000.0,
        "成交额": 1118300.0
    },
    {
        "序号": 428,
        "symbol": "03151",
        "name": "PP科创50",
        "最新价": 5.92,
        "涨跌额": 0.09,
        "涨跌幅": 1.54,
        "今开": 5.905,
        "最高": 5.94,
        "最低": 5.905,
        "昨收": 5.83,
        "成交量": 402000.0,
        "成交额": 2381106.0
    },
    {
        "序号": 429,
        "symbol": "00697",
        "name": "首程控股",
        "最新价": 1.32,
        "涨跌额": 0.02,
        "涨跌幅": 1.54,
        "今开": 1.3,
        "最高": 1.33,
        "最低": 1.29,
        "昨收": 1.3,
        "成交量": 1564180.0,
        "成交额": 2053008.0
    },
    {
        "序号": 430,
        "symbol": "00199",
        "name": "德祥地产",
        "最新价": 0.66,
        "涨跌额": 0.01,
        "涨跌幅": 1.54,
        "今开": 0.64,
        "最高": 0.66,
        "最低": 0.64,
        "昨收": 0.65,
        "成交量": 7432.0,
        "成交额": 4811.0
    },
    {
        "序号": 431,
        "symbol": "02378",
        "name": "保诚",
        "最新价": 86.05,
        "涨跌额": 1.3,
        "涨跌幅": 1.53,
        "今开": 85.1,
        "最高": 86.2,
        "最低": 85.1,
        "昨收": 84.75,
        "成交量": 59450.0,
        "成交额": 5102435.0
    },
    {
        "序号": 432,
        "symbol": "08017",
        "name": "捷利交易宝",
        "最新价": 0.335,
        "涨跌额": 0.005,
        "涨跌幅": 1.52,
        "今开": 0.325,
        "最高": 0.355,
        "最低": 0.325,
        "昨收": 0.33,
        "成交量": 804000.0,
        "成交额": 274340.0
    },
    {
        "序号": 433,
        "symbol": "03999",
        "name": "大成食品",
        "最新价": 0.67,
        "涨跌额": 0.01,
        "涨跌幅": 1.52,
        "今开": 0.67,
        "最高": 0.67,
        "最低": 0.65,
        "昨收": 0.66,
        "成交量": 152000.0,
        "成交额": 100360.0
    },
    {
        "序号": 434,
        "symbol": "02389",
        "name": "北京健康",
        "最新价": 0.067,
        "涨跌额": 0.001,
        "涨跌幅": 1.52,
        "今开": 0.068,
        "最高": 0.07,
        "最低": 0.067,
        "昨收": 0.066,
        "成交量": 1908000.0,
        "成交额": 133026.0
    },
    {
        "序号": 435,
        "symbol": "02116",
        "name": "江苏创新",
        "最新价": 0.335,
        "涨跌额": 0.005,
        "涨跌幅": 1.52,
        "今开": 0.31,
        "最高": 0.335,
        "最低": 0.31,
        "昨收": 0.33,
        "成交量": 78000.0,
        "成交额": 24320.0
    },
    {
        "序号": 436,
        "symbol": "01521",
        "name": "方达控股",
        "最新价": 2.01,
        "涨跌额": 0.03,
        "涨跌幅": 1.52,
        "今开": 2.0,
        "最高": 2.08,
        "最低": 1.97,
        "昨收": 1.98,
        "成交量": 7116000.0,
        "成交额": 14374460.0
    },
    {
        "序号": 437,
        "symbol": "00280",
        "name": "景福集团",
        "最新价": 0.335,
        "涨跌额": 0.005,
        "涨跌幅": 1.52,
        "今开": 0.33,
        "最高": 0.34,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 562000.0,
        "成交额": 188610.0
    },
    {
        "序号": 438,
        "symbol": "00154",
        "name": "北京控股环境集团",
        "最新价": 0.335,
        "涨跌额": 0.005,
        "涨跌幅": 1.52,
        "今开": 0.33,
        "最高": 0.335,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 439,
        "symbol": "08120",
        "name": "国农金融投资",
        "最新价": 0.135,
        "涨跌额": 0.002,
        "涨跌幅": 1.5,
        "今开": 0.132,
        "最高": 0.135,
        "最低": 0.132,
        "昨收": 0.133,
        "成交量": 40000.0,
        "成交额": 5330.0
    },
    {
        "序号": 440,
        "symbol": "01159",
        "name": "星光文化",
        "最新价": 0.203,
        "涨跌额": 0.003,
        "涨跌幅": 1.5,
        "今开": 0.209,
        "最高": 0.22,
        "最低": 0.2,
        "昨收": 0.2,
        "成交量": 164000.0,
        "成交额": 33712.0
    },
    {
        "序号": 441,
        "symbol": "01202",
        "name": "四威科技",
        "最新价": 0.68,
        "涨跌额": 0.01,
        "涨跌幅": 1.49,
        "今开": 0.67,
        "最高": 0.68,
        "最低": 0.67,
        "昨收": 0.67,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 442,
        "symbol": "02315",
        "name": "百奥赛图-B",
        "最新价": 11.0,
        "涨跌额": 0.16,
        "涨跌幅": 1.48,
        "今开": 10.84,
        "最高": 11.04,
        "最低": 9.22,
        "昨收": 10.84,
        "成交量": 254000.0,
        "成交额": 2641080.0
    },
    {
        "序号": 443,
        "symbol": "08659",
        "name": "易和国际控股",
        "最新价": 0.69,
        "涨跌额": 0.01,
        "涨跌幅": 1.47,
        "今开": 0.68,
        "最高": 0.71,
        "最低": 0.66,
        "昨收": 0.68,
        "成交量": 845000.0,
        "成交额": 576650.0
    },
    {
        "序号": 444,
        "symbol": "00320",
        "name": "金宝通",
        "最新价": 0.345,
        "涨跌额": 0.005,
        "涨跌幅": 1.47,
        "今开": 0.34,
        "最高": 0.365,
        "最低": 0.34,
        "昨收": 0.34,
        "成交量": 3406000.0,
        "成交额": 1168650.0
    },
    {
        "序号": 445,
        "symbol": "03322",
        "name": "永嘉集团",
        "最新价": 0.139,
        "涨跌额": 0.002,
        "涨跌幅": 1.46,
        "今开": 0.137,
        "最高": 0.139,
        "最低": 0.132,
        "昨收": 0.137,
        "成交量": 114000.0,
        "成交额": 15252.0
    },
    {
        "序号": 446,
        "symbol": "02276",
        "name": "康耐特光学",
        "最新价": 6.3,
        "涨跌额": 0.09,
        "涨跌幅": 1.45,
        "今开": 6.21,
        "最高": 6.3,
        "最低": 6.12,
        "昨收": 6.21,
        "成交量": 310500.0,
        "成交额": 1930110.0
    },
    {
        "序号": 447,
        "symbol": "01193",
        "name": "华润燃气",
        "最新价": 24.55,
        "涨跌额": 0.35,
        "涨跌幅": 1.45,
        "今开": 24.1,
        "最高": 25.0,
        "最低": 24.05,
        "昨收": 24.2,
        "成交量": 2524913.0,
        "成交额": 62325997.0
    },
    {
        "序号": 448,
        "symbol": "01066",
        "name": "威高股份",
        "最新价": 7.02,
        "涨跌额": 0.1,
        "涨跌幅": 1.45,
        "今开": 6.68,
        "最高": 7.05,
        "最低": 6.68,
        "昨收": 6.92,
        "成交量": 6892800.0,
        "成交额": 48250282.0
    },
    {
        "序号": 449,
        "symbol": "03368",
        "name": "百盛集团",
        "最新价": 0.141,
        "涨跌额": 0.002,
        "涨跌幅": 1.44,
        "今开": 0.146,
        "最高": 0.146,
        "最低": 0.136,
        "昨收": 0.139,
        "成交量": 65500.0,
        "成交额": 9500.0
    },
    {
        "序号": 450,
        "symbol": "01726",
        "name": "HKE HOLDINGS",
        "最新价": 2.12,
        "涨跌额": 0.03,
        "涨跌幅": 1.44,
        "今开": 2.05,
        "最高": 2.12,
        "最低": 2.02,
        "昨收": 2.09,
        "成交量": 455000.0,
        "成交额": 935800.0
    },
    {
        "序号": 451,
        "symbol": "00998",
        "name": "中信银行",
        "最新价": 3.55,
        "涨跌额": 0.05,
        "涨跌幅": 1.43,
        "今开": 3.5,
        "最高": 3.55,
        "最低": 3.49,
        "昨收": 3.5,
        "成交量": 20799217.0,
        "成交额": 73422038.0
    },
    {
        "序号": 452,
        "symbol": "00563",
        "name": "上实城市开发",
        "最新价": 0.355,
        "涨跌额": 0.005,
        "涨跌幅": 1.43,
        "今开": 0.345,
        "最高": 0.355,
        "最低": 0.345,
        "昨收": 0.35,
        "成交量": 120000.0,
        "成交额": 41810.0
    },
    {
        "序号": 453,
        "symbol": "00272",
        "name": "瑞安房地产",
        "最新价": 0.71,
        "涨跌额": 0.01,
        "涨跌幅": 1.43,
        "今开": 0.7,
        "最高": 0.71,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 2757000.0,
        "成交额": 1936940.0
    },
    {
        "序号": 454,
        "symbol": "03798",
        "name": "家乡互动",
        "最新价": 2.14,
        "涨跌额": 0.03,
        "涨跌幅": 1.42,
        "今开": 2.13,
        "最高": 2.15,
        "最低": 2.13,
        "昨收": 2.11,
        "成交量": 184000.0,
        "成交额": 393980.0
    },
    {
        "序号": 455,
        "symbol": "00316",
        "name": "东方海外国际",
        "最新价": 93.2,
        "涨跌额": 1.3,
        "涨跌幅": 1.41,
        "今开": 92.7,
        "最高": 93.9,
        "最低": 92.5,
        "昨收": 91.9,
        "成交量": 610500.0,
        "成交额": 56873887.0
    },
    {
        "序号": 456,
        "symbol": "06677",
        "name": "远洋服务",
        "最新价": 0.72,
        "涨跌额": 0.01,
        "涨跌幅": 1.41,
        "今开": 0.71,
        "最高": 0.75,
        "最低": 0.7,
        "昨收": 0.71,
        "成交量": 167500.0,
        "成交额": 120060.0
    },
    {
        "序号": 457,
        "symbol": "00218",
        "name": "申万宏源香港",
        "最新价": 0.36,
        "涨跌额": 0.005,
        "涨跌幅": 1.41,
        "今开": 0.355,
        "最高": 0.36,
        "最低": 0.355,
        "昨收": 0.355,
        "成交量": 60000.0,
        "成交额": 21350.0
    },
    {
        "序号": 458,
        "symbol": "09686",
        "name": "东软熙康",
        "最新价": 1.46,
        "涨跌额": 0.02,
        "涨跌幅": 1.39,
        "今开": 1.43,
        "最高": 1.46,
        "最低": 1.37,
        "昨收": 1.44,
        "成交量": 37000.0,
        "成交额": 53070.0
    },
    {
        "序号": 459,
        "symbol": "06823",
        "name": "香港电讯-SS",
        "最新价": 8.77,
        "涨跌额": 0.12,
        "涨跌幅": 1.39,
        "今开": 8.65,
        "最高": 8.79,
        "最低": 8.63,
        "昨收": 8.65,
        "成交量": 8011834.0,
        "成交额": 69901716.0
    },
    {
        "序号": 460,
        "symbol": "03896",
        "name": "金山云",
        "最新价": 2.2,
        "涨跌额": 0.03,
        "涨跌幅": 1.38,
        "今开": 2.21,
        "最高": 2.25,
        "最低": 2.16,
        "昨收": 2.17,
        "成交量": 12696000.0,
        "成交额": 28017380.0
    },
    {
        "序号": 461,
        "symbol": "02834",
        "name": "安硕纳指一百",
        "最新价": 303.0,
        "涨跌额": 4.1,
        "涨跌幅": 1.37,
        "今开": 302.4,
        "最高": 303.0,
        "最低": 302.4,
        "昨收": 298.9,
        "成交量": 2300.0,
        "成交额": 696340.0
    },
    {
        "序号": 462,
        "symbol": "02440",
        "name": "濠暻科技",
        "最新价": 1.48,
        "涨跌额": 0.02,
        "涨跌幅": 1.37,
        "今开": 1.47,
        "最高": 1.48,
        "最低": 1.38,
        "昨收": 1.46,
        "成交量": 148000.0,
        "成交额": 208510.0
    },
    {
        "序号": 463,
        "symbol": "02009",
        "name": "金隅集团",
        "最新价": 0.74,
        "涨跌额": 0.01,
        "涨跌幅": 1.37,
        "今开": 0.73,
        "最高": 0.74,
        "最低": 0.72,
        "昨收": 0.73,
        "成交量": 3992000.0,
        "成交额": 2919620.0
    },
    {
        "序号": 464,
        "symbol": "01987",
        "name": "BENG SOON MACH",
        "最新价": 0.148,
        "涨跌额": 0.002,
        "涨跌幅": 1.37,
        "今开": 0.14,
        "最高": 0.148,
        "最低": 0.14,
        "昨收": 0.146,
        "成交量": 388000.0,
        "成交额": 54948.0
    },
    {
        "序号": 465,
        "symbol": "01637",
        "name": "顺兴集团控股",
        "最新价": 0.222,
        "涨跌额": 0.003,
        "涨跌幅": 1.37,
        "今开": 0.238,
        "最高": 0.238,
        "最低": 0.222,
        "昨收": 0.219,
        "成交量": 32000.0,
        "成交额": 7616.0
    },
    {
        "序号": 466,
        "symbol": "00064",
        "name": "结好控股",
        "最新价": 0.148,
        "涨跌额": 0.002,
        "涨跌幅": 1.37,
        "今开": 0.146,
        "最高": 0.149,
        "最低": 0.145,
        "昨收": 0.146,
        "成交量": 258000.0,
        "成交额": 37528.0
    },
    {
        "序号": 467,
        "symbol": "06600",
        "name": "赛生药业",
        "最新价": 13.42,
        "涨跌额": 0.18,
        "涨跌幅": 1.36,
        "今开": 12.76,
        "最高": 13.42,
        "最低": 12.76,
        "昨收": 13.24,
        "成交量": 2445000.0,
        "成交额": 32342430.0
    },
    {
        "序号": 468,
        "symbol": "01128",
        "name": "永利澳门",
        "最新价": 6.02,
        "涨跌额": 0.08,
        "涨跌幅": 1.35,
        "今开": 5.93,
        "最高": 6.08,
        "最低": 5.93,
        "昨收": 5.94,
        "成交量": 7422000.0,
        "成交额": 44614554.0
    },
    {
        "序号": 469,
        "symbol": "00568",
        "name": "山东墨龙",
        "最新价": 2.26,
        "涨跌额": 0.03,
        "涨跌幅": 1.35,
        "今开": 2.26,
        "最高": 2.28,
        "最低": 2.25,
        "昨收": 2.23,
        "成交量": 314000.0,
        "成交额": 711532.0
    },
    {
        "序号": 470,
        "symbol": "06908",
        "name": "宏光半导体",
        "最新价": 0.76,
        "涨跌额": 0.01,
        "涨跌幅": 1.33,
        "今开": 0.71,
        "最高": 0.76,
        "最低": 0.71,
        "昨收": 0.75,
        "成交量": 130000.0,
        "成交额": 98370.0
    },
    {
        "序号": 471,
        "symbol": "06638",
        "name": "金融壹账通",
        "最新价": 0.76,
        "涨跌额": 0.01,
        "涨跌幅": 1.33,
        "今开": 0.78,
        "最高": 0.78,
        "最低": 0.76,
        "昨收": 0.75,
        "成交量": 50500.0,
        "成交额": 38675.0
    },
    {
        "序号": 472,
        "symbol": "00142",
        "name": "第一太平",
        "最新价": 3.04,
        "涨跌额": 0.04,
        "涨跌幅": 1.33,
        "今开": 3.0,
        "最高": 3.06,
        "最低": 2.99,
        "昨收": 3.0,
        "成交量": 1667500.0,
        "成交额": 5060495.0
    },
    {
        "序号": 473,
        "symbol": "03119",
        "name": "GX亚洲半导体",
        "最新价": 50.28,
        "涨跌额": 0.66,
        "涨跌幅": 1.33,
        "今开": 50.48,
        "最高": 50.48,
        "最低": 50.28,
        "昨收": 49.62,
        "成交量": 2012.0,
        "成交额": 101407.0
    },
    {
        "序号": 474,
        "symbol": "03390",
        "name": "满贯集团",
        "最新价": 4.6,
        "涨跌额": 0.06,
        "涨跌幅": 1.32,
        "今开": 4.6,
        "最高": 4.6,
        "最低": 4.46,
        "昨收": 4.54,
        "成交量": 1032000.0,
        "成交额": 4657600.0
    },
    {
        "序号": 475,
        "symbol": "01909",
        "name": "火岩控股",
        "最新价": 0.154,
        "涨跌额": 0.002,
        "涨跌幅": 1.32,
        "今开": 0.152,
        "最高": 0.154,
        "最低": 0.145,
        "昨收": 0.152,
        "成交量": 1424000.0,
        "成交额": 212896.0
    },
    {
        "序号": 476,
        "symbol": "01543",
        "name": "中盈盛达融资担保",
        "最新价": 0.77,
        "涨跌额": 0.01,
        "涨跌幅": 1.32,
        "今开": 0.76,
        "最高": 0.79,
        "最低": 0.76,
        "昨收": 0.76,
        "成交量": 70000.0,
        "成交额": 54220.0
    },
    {
        "序号": 477,
        "symbol": "06122",
        "name": "九台农商银行",
        "最新价": 1.56,
        "涨跌额": 0.02,
        "涨跌幅": 1.3,
        "今开": 1.54,
        "最高": 1.56,
        "最低": 1.54,
        "昨收": 1.54,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 478,
        "symbol": "03788",
        "name": "中国罕王",
        "最新价": 0.78,
        "涨跌额": 0.01,
        "涨跌幅": 1.3,
        "今开": 0.77,
        "最高": 0.78,
        "最低": 0.75,
        "昨收": 0.77,
        "成交量": 92000.0,
        "成交额": 70180.0
    },
    {
        "序号": 479,
        "symbol": "07505",
        "name": "XI二南方美油气",
        "最新价": 8.24,
        "涨跌额": 0.105,
        "涨跌幅": 1.29,
        "今开": 8.24,
        "最高": 8.24,
        "最低": 8.24,
        "昨收": 8.135,
        "成交量": 1500.0,
        "成交额": 12360.0
    },
    {
        "序号": 480,
        "symbol": "02436",
        "name": "凌雄科技",
        "最新价": 7.85,
        "涨跌额": 0.1,
        "涨跌幅": 1.29,
        "今开": 7.9,
        "最高": 7.9,
        "最低": 7.8,
        "昨收": 7.75,
        "成交量": 1500.0,
        "成交额": 11760.0
    },
    {
        "序号": 481,
        "symbol": "03112",
        "name": "A潘渡区块链主题",
        "最新价": 11.0,
        "涨跌额": 0.14,
        "涨跌幅": 1.29,
        "今开": 11.0,
        "最高": 11.0,
        "最低": 11.0,
        "昨收": 10.86,
        "成交量": 5000.0,
        "成交额": 55000.0
    },
    {
        "序号": 482,
        "symbol": "08525",
        "name": "百应控股",
        "最新价": 0.395,
        "涨跌额": 0.005,
        "涨跌幅": 1.28,
        "今开": 0.36,
        "最高": 0.405,
        "最低": 0.355,
        "昨收": 0.39,
        "成交量": 22000.0,
        "成交额": 7980.0
    },
    {
        "序号": 483,
        "symbol": "00617",
        "name": "百利保控股",
        "最新价": 0.79,
        "涨跌额": 0.01,
        "涨跌幅": 1.28,
        "今开": 0.77,
        "最高": 0.79,
        "最低": 0.77,
        "昨收": 0.78,
        "成交量": 20000.0,
        "成交额": 15520.0
    },
    {
        "序号": 484,
        "symbol": "01038",
        "name": "长江基建集团",
        "最新价": 39.8,
        "涨跌额": 0.5,
        "涨跌幅": 1.27,
        "今开": 39.6,
        "最高": 40.3,
        "最低": 39.3,
        "昨收": 39.3,
        "成交量": 2438826.0,
        "成交额": 97106656.0
    },
    {
        "序号": 485,
        "symbol": "83690",
        "name": "美团-WR",
        "最新价": 79.95,
        "涨跌额": 1.0,
        "涨跌幅": 1.27,
        "今开": 79.2,
        "最高": 79.95,
        "最低": 77.5,
        "昨收": 78.95,
        "成交量": 7500.0,
        "成交额": 592855.0
    },
    {
        "序号": 486,
        "symbol": "02422",
        "name": "润歌互动",
        "最新价": 0.4,
        "涨跌额": 0.005,
        "涨跌幅": 1.27,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.4,
        "昨收": 0.395,
        "成交量": 52000.0,
        "成交额": 20960.0
    },
    {
        "序号": 487,
        "symbol": "00572",
        "name": "未来世界控股",
        "最新价": 0.8,
        "涨跌额": 0.01,
        "涨跌幅": 1.27,
        "今开": 0.86,
        "最高": 0.86,
        "最低": 0.78,
        "昨收": 0.79,
        "成交量": 56420.0,
        "成交额": 44795.0
    },
    {
        "序号": 488,
        "symbol": "09047",
        "name": "F山证铁矿石-U",
        "最新价": 3.06,
        "涨跌额": 0.038,
        "涨跌幅": 1.26,
        "今开": 3.022,
        "最高": 3.08,
        "最低": 3.022,
        "昨收": 3.022,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 489,
        "symbol": "01568",
        "name": "承达集团",
        "最新价": 0.405,
        "涨跌额": 0.005,
        "涨跌幅": 1.25,
        "今开": 0.4,
        "最高": 0.405,
        "最低": 0.395,
        "昨收": 0.4,
        "成交量": 164000.0,
        "成交额": 65140.0
    },
    {
        "序号": 490,
        "symbol": "00152",
        "name": "深圳国际",
        "最新价": 5.69,
        "涨跌额": 0.07,
        "涨跌幅": 1.25,
        "今开": 5.62,
        "最高": 5.77,
        "最低": 5.6,
        "昨收": 5.62,
        "成交量": 7027039.0,
        "成交额": 40139218.0
    },
    {
        "序号": 491,
        "symbol": "03393",
        "name": "威胜控股",
        "最新价": 3.26,
        "涨跌额": 0.04,
        "涨跌幅": 1.24,
        "今开": 3.24,
        "最高": 3.29,
        "最低": 3.2,
        "昨收": 3.22,
        "成交量": 604000.0,
        "成交额": 1957880.0
    },
    {
        "序号": 492,
        "symbol": "01569",
        "name": "民生教育",
        "最新价": 0.245,
        "涨跌额": 0.003,
        "涨跌幅": 1.24,
        "今开": 0.242,
        "最高": 0.245,
        "最低": 0.242,
        "昨收": 0.242,
        "成交量": 36000.0,
        "成交额": 8766.0
    },
    {
        "序号": 493,
        "symbol": "03191",
        "name": "GX中国半导",
        "最新价": 36.02,
        "涨跌额": 0.44,
        "涨跌幅": 1.24,
        "今开": 35.82,
        "最高": 36.04,
        "最低": 35.64,
        "昨收": 35.58,
        "成交量": 41169.0,
        "成交额": 1480475.0
    },
    {
        "序号": 494,
        "symbol": "00037",
        "name": "远东酒店实业",
        "最新价": 0.82,
        "涨跌额": 0.01,
        "涨跌幅": 1.23,
        "今开": 0.81,
        "最高": 0.82,
        "最低": 0.8,
        "昨收": 0.81,
        "成交量": 600000.0,
        "成交额": 487780.0
    },
    {
        "序号": 495,
        "symbol": "08460",
        "name": "基地锦标集团",
        "最新价": 0.247,
        "涨跌额": 0.003,
        "涨跌幅": 1.23,
        "今开": 0.245,
        "最高": 0.255,
        "最低": 0.201,
        "昨收": 0.244,
        "成交量": 290000.0,
        "成交额": 67440.0
    },
    {
        "序号": 496,
        "symbol": "00662",
        "name": "亚洲金融",
        "最新价": 3.3,
        "涨跌额": 0.04,
        "涨跌幅": 1.23,
        "今开": 3.2,
        "最高": 3.3,
        "最低": 3.2,
        "昨收": 3.26,
        "成交量": 20000.0,
        "成交额": 64000.0
    },
    {
        "序号": 497,
        "symbol": "83060",
        "name": "F中金碳期货-R",
        "最新价": 54.76,
        "涨跌额": 0.66,
        "涨跌幅": 1.22,
        "今开": 54.1,
        "最高": 55.08,
        "最低": 54.1,
        "昨收": 54.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 498,
        "symbol": "08036",
        "name": "电子交易集团",
        "最新价": 0.083,
        "涨跌额": 0.001,
        "涨跌幅": 1.22,
        "今开": 0.083,
        "最高": 0.083,
        "最低": 0.083,
        "昨收": 0.082,
        "成交量": 150000.0,
        "成交额": 12450.0
    },
    {
        "序号": 499,
        "symbol": "06093",
        "name": "和泓服务",
        "最新价": 2.5,
        "涨跌额": 0.03,
        "涨跌幅": 1.21,
        "今开": 2.58,
        "最高": 2.58,
        "最低": 2.43,
        "昨收": 2.47,
        "成交量": 72000.0,
        "成交额": 178200.0
    },
    {
        "序号": 500,
        "symbol": "02257",
        "name": "圣诺医药-B",
        "最新价": 41.8,
        "涨跌额": 0.5,
        "涨跌幅": 1.21,
        "今开": 41.0,
        "最高": 41.8,
        "最低": 39.85,
        "昨收": 41.3,
        "成交量": 69250.0,
        "成交额": 2864762.0
    },
    {
        "序号": 501,
        "symbol": "02518",
        "name": "汽车之家-S",
        "最新价": 50.5,
        "涨跌额": 0.6,
        "涨跌幅": 1.2,
        "今开": 50.95,
        "最高": 51.35,
        "最低": 50.5,
        "昨收": 49.9,
        "成交量": 4800.0,
        "成交额": 244195.0
    },
    {
        "序号": 502,
        "symbol": "09060",
        "name": "F中金碳期货-U",
        "最新价": 7.64,
        "涨跌额": 0.09,
        "涨跌幅": 1.19,
        "今开": 7.55,
        "最高": 7.685,
        "最低": 7.55,
        "昨收": 7.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 503,
        "symbol": "01321",
        "name": "中国新城市",
        "最新价": 0.85,
        "涨跌额": 0.01,
        "涨跌幅": 1.19,
        "今开": 0.78,
        "最高": 0.85,
        "最低": 0.78,
        "昨收": 0.84,
        "成交量": 6000.0,
        "成交额": 4940.0
    },
    {
        "序号": 504,
        "symbol": "02411",
        "name": "百果园集团",
        "最新价": 5.98,
        "涨跌额": 0.07,
        "涨跌幅": 1.18,
        "今开": 5.88,
        "最高": 6.09,
        "最低": 5.87,
        "昨收": 5.91,
        "成交量": 5142500.0,
        "成交额": 30933400.0
    },
    {
        "序号": 505,
        "symbol": "03086",
        "name": "华夏纳指",
        "最新价": 32.48,
        "涨跌额": 0.38,
        "涨跌幅": 1.18,
        "今开": 32.46,
        "最高": 32.56,
        "最低": 32.46,
        "昨收": 32.1,
        "成交量": 66290.0,
        "成交额": 2156733.0
    },
    {
        "序号": 506,
        "symbol": "01671",
        "name": "天保能源",
        "最新价": 0.43,
        "涨跌额": 0.005,
        "涨跌幅": 1.18,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.43,
        "昨收": 0.425,
        "成交量": 2000.0,
        "成交额": 860.0
    },
    {
        "序号": 507,
        "symbol": "00254",
        "name": "国家联合资源",
        "最新价": 0.086,
        "涨跌额": 0.001,
        "涨跌幅": 1.18,
        "今开": 0.086,
        "最高": 0.086,
        "最低": 0.085,
        "昨收": 0.085,
        "成交量": 660600.0,
        "成交额": 56345.0
    },
    {
        "序号": 508,
        "symbol": "01410",
        "name": "安领国际",
        "最新价": 0.44,
        "涨跌额": 0.005,
        "涨跌幅": 1.15,
        "今开": 0.44,
        "最高": 0.455,
        "最低": 0.44,
        "昨收": 0.435,
        "成交量": 1140000.0,
        "成交额": 505750.0
    },
    {
        "序号": 509,
        "symbol": "09988",
        "name": "阿里巴巴-SW",
        "最新价": 70.5,
        "涨跌额": 0.8,
        "涨跌幅": 1.15,
        "今开": 69.7,
        "最高": 71.0,
        "最低": 69.3,
        "昨收": 69.7,
        "成交量": 52123073.0,
        "成交额": 3665442208.0
    },
    {
        "序号": 510,
        "symbol": "01942",
        "name": "马可数字科技",
        "最新价": 1.77,
        "涨跌额": 0.02,
        "涨跌幅": 1.14,
        "今开": 1.76,
        "最高": 1.9,
        "最低": 1.75,
        "昨收": 1.75,
        "成交量": 92434776.0,
        "成交额": 168018870.0
    },
    {
        "序号": 511,
        "symbol": "03601",
        "name": "鲁大师",
        "最新价": 0.89,
        "涨跌额": 0.01,
        "涨跌幅": 1.14,
        "今开": 0.87,
        "最高": 0.9,
        "最低": 0.87,
        "昨收": 0.88,
        "成交量": 40000.0,
        "成交额": 35300.0
    },
    {
        "序号": 512,
        "symbol": "09814",
        "name": "三星FANG-U",
        "最新价": 3.034,
        "涨跌额": 0.034,
        "涨跌幅": 1.13,
        "今开": 3.0,
        "最高": 3.042,
        "最低": 3.0,
        "昨收": 3.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 513,
        "symbol": "02076",
        "name": "BOSS直聘-W",
        "最新价": 58.1,
        "涨跌额": 0.65,
        "涨跌幅": 1.13,
        "今开": 58.1,
        "最高": 58.1,
        "最低": 58.1,
        "昨收": 57.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 514,
        "symbol": "01530",
        "name": "三生制药",
        "最新价": 7.16,
        "涨跌额": 0.08,
        "涨跌幅": 1.13,
        "今开": 7.08,
        "最高": 7.22,
        "最低": 6.97,
        "昨收": 7.08,
        "成交量": 6064000.0,
        "成交额": 43350882.0
    },
    {
        "序号": 515,
        "symbol": "03175",
        "name": "F三星原油期",
        "最新价": 6.29,
        "涨跌额": 0.07,
        "涨跌幅": 1.13,
        "今开": 6.215,
        "最高": 6.305,
        "最低": 6.215,
        "昨收": 6.22,
        "成交量": 538800.0,
        "成交额": 3375835.0
    },
    {
        "序号": 516,
        "symbol": "09633",
        "name": "农夫山泉",
        "最新价": 44.95,
        "涨跌额": 0.5,
        "涨跌幅": 1.12,
        "今开": 44.65,
        "最高": 45.1,
        "最低": 44.0,
        "昨收": 44.45,
        "成交量": 3545078.0,
        "成交额": 158658748.0
    },
    {
        "序号": 517,
        "symbol": "02023",
        "name": "中国绿岛科技",
        "最新价": 0.9,
        "涨跌额": 0.01,
        "涨跌幅": 1.12,
        "今开": 0.9,
        "最高": 0.9,
        "最低": 0.9,
        "昨收": 0.89,
        "成交量": 16000.0,
        "成交额": 14400.0
    },
    {
        "序号": 518,
        "symbol": "01180",
        "name": "汇彩控股",
        "最新价": 0.9,
        "涨跌额": 0.01,
        "涨跌幅": 1.12,
        "今开": 0.91,
        "最高": 0.91,
        "最低": 0.89,
        "昨收": 0.89,
        "成交量": 48000.0,
        "成交额": 43520.0
    },
    {
        "序号": 519,
        "symbol": "00728",
        "name": "中国电信",
        "最新价": 3.61,
        "涨跌额": 0.04,
        "涨跌幅": 1.12,
        "今开": 3.58,
        "最高": 3.62,
        "最低": 3.54,
        "昨收": 3.57,
        "成交量": 39217607.0,
        "成交额": 140591976.0
    },
    {
        "序号": 520,
        "symbol": "03060",
        "name": "F中金碳期货",
        "最新价": 59.68,
        "涨跌额": 0.66,
        "涨跌幅": 1.12,
        "今开": 59.02,
        "最高": 60.02,
        "最低": 59.02,
        "昨收": 59.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 521,
        "symbol": "07232",
        "name": "富邦两倍做多富时台湾",
        "最新价": 9.1,
        "涨跌额": 0.1,
        "涨跌幅": 1.11,
        "今开": 9.1,
        "最高": 9.1,
        "最低": 9.1,
        "昨收": 9.0,
        "成交量": 100.0,
        "成交额": 910.0
    },
    {
        "序号": 522,
        "symbol": "03882",
        "name": "天彩控股",
        "最新价": 0.91,
        "涨跌额": 0.01,
        "涨跌幅": 1.11,
        "今开": 0.96,
        "最高": 0.96,
        "最低": 0.91,
        "昨收": 0.9,
        "成交量": 1000.0,
        "成交额": 960.0
    },
    {
        "序号": 523,
        "symbol": "02421",
        "name": "嘉创地产",
        "最新价": 0.91,
        "涨跌额": 0.01,
        "涨跌幅": 1.11,
        "今开": 0.94,
        "最高": 0.94,
        "最低": 0.91,
        "昨收": 0.9,
        "成交量": 6000.0,
        "成交额": 5560.0
    },
    {
        "序号": 524,
        "symbol": "02285",
        "name": "泉峰控股",
        "最新价": 18.3,
        "涨跌额": 0.2,
        "涨跌幅": 1.1,
        "今开": 18.4,
        "最高": 18.72,
        "最低": 17.56,
        "昨收": 18.1,
        "成交量": 319100.0,
        "成交额": 5808622.0
    },
    {
        "序号": 525,
        "symbol": "01730",
        "name": "LHN",
        "最新价": 1.83,
        "涨跌额": 0.02,
        "涨跌幅": 1.1,
        "今开": 1.82,
        "最高": 1.83,
        "最低": 1.82,
        "昨收": 1.81,
        "成交量": 8000.0,
        "成交额": 14620.0
    },
    {
        "序号": 526,
        "symbol": "89618",
        "name": "京东集团-SWR",
        "最新价": 96.55,
        "涨跌额": 1.05,
        "涨跌幅": 1.1,
        "今开": 94.75,
        "最高": 96.95,
        "最低": 94.75,
        "昨收": 95.5,
        "成交量": 2000.0,
        "成交额": 192450.0
    },
    {
        "序号": 527,
        "symbol": "01290",
        "name": "中国汇融",
        "最新价": 0.92,
        "涨跌额": 0.01,
        "涨跌幅": 1.1,
        "今开": 0.91,
        "最高": 0.92,
        "最低": 0.87,
        "昨收": 0.91,
        "成交量": 56000.0,
        "成交额": 50900.0
    },
    {
        "序号": 528,
        "symbol": "03328",
        "name": "交通银行",
        "最新价": 4.63,
        "涨跌额": 0.05,
        "涨跌幅": 1.09,
        "今开": 4.57,
        "最高": 4.65,
        "最低": 4.54,
        "昨收": 4.58,
        "成交量": 20543053.0,
        "成交额": 94808571.0
    },
    {
        "序号": 529,
        "symbol": "00327",
        "name": "百富环球",
        "最新价": 5.63,
        "涨跌额": 0.06,
        "涨跌幅": 1.08,
        "今开": 5.6,
        "最高": 5.65,
        "最低": 5.56,
        "昨收": 5.57,
        "成交量": 574000.0,
        "成交额": 3219410.0
    },
    {
        "序号": 530,
        "symbol": "01288",
        "name": "农业银行",
        "最新价": 2.83,
        "涨跌额": 0.03,
        "涨跌幅": 1.07,
        "今开": 2.82,
        "最高": 2.83,
        "最低": 2.8,
        "昨收": 2.8,
        "成交量": 55053636.0,
        "成交额": 155224289.0
    },
    {
        "序号": 531,
        "symbol": "01811",
        "name": "中广核新能源",
        "最新价": 1.89,
        "涨跌额": 0.02,
        "涨跌幅": 1.07,
        "今开": 1.89,
        "最高": 1.9,
        "最低": 1.86,
        "昨收": 1.87,
        "成交量": 1426000.0,
        "成交额": 2679560.0
    },
    {
        "序号": 532,
        "symbol": "06890",
        "name": "康利国际控股",
        "最新价": 0.475,
        "涨跌额": 0.005,
        "涨跌幅": 1.06,
        "今开": 0.47,
        "最高": 0.475,
        "最低": 0.47,
        "昨收": 0.47,
        "成交量": 2000.0,
        "成交额": 940.0
    },
    {
        "序号": 533,
        "symbol": "02255",
        "name": "海昌海洋公园",
        "最新价": 0.95,
        "涨跌额": 0.01,
        "涨跌幅": 1.06,
        "今开": 0.95,
        "最高": 0.96,
        "最低": 0.93,
        "昨收": 0.94,
        "成交量": 8694000.0,
        "成交额": 8220122.0
    },
    {
        "序号": 534,
        "symbol": "02380",
        "name": "中国电力",
        "最新价": 2.86,
        "涨跌额": 0.03,
        "涨跌幅": 1.06,
        "今开": 2.83,
        "最高": 2.89,
        "最低": 2.82,
        "昨收": 2.83,
        "成交量": 24444993.0,
        "成交额": 69878460.0
    },
    {
        "序号": 535,
        "symbol": "03422",
        "name": "GX创新蓝筹十强",
        "最新价": 53.44,
        "涨跌额": 0.56,
        "涨跌幅": 1.06,
        "今开": 53.44,
        "最高": 53.44,
        "最低": 53.44,
        "昨收": 52.88,
        "成交量": 250.0,
        "成交额": 13360.0
    },
    {
        "序号": 536,
        "symbol": "06683",
        "name": "巨星传奇",
        "最新价": 8.62,
        "涨跌额": 0.09,
        "涨跌幅": 1.06,
        "今开": 8.53,
        "最高": 8.7,
        "最低": 8.45,
        "昨收": 8.53,
        "成交量": 745000.0,
        "成交额": 6409495.0
    },
    {
        "序号": 537,
        "symbol": "80883",
        "name": "中国海洋石油-R",
        "最新价": 11.56,
        "涨跌额": 0.12,
        "涨跌幅": 1.05,
        "今开": 11.52,
        "最高": 11.64,
        "最低": 11.52,
        "昨收": 11.44,
        "成交量": 347000.0,
        "成交额": 4022740.0
    },
    {
        "序号": 538,
        "symbol": "03600",
        "name": "现代牙科",
        "最新价": 3.87,
        "涨跌额": 0.04,
        "涨跌幅": 1.04,
        "今开": 3.83,
        "最高": 3.88,
        "最低": 3.77,
        "昨收": 3.83,
        "成交量": 875000.0,
        "成交额": 3350775.0
    },
    {
        "序号": 539,
        "symbol": "01891",
        "name": "兴合控股",
        "最新价": 0.194,
        "涨跌额": 0.002,
        "涨跌幅": 1.04,
        "今开": 0.194,
        "最高": 0.194,
        "最低": 0.194,
        "昨收": 0.192,
        "成交量": 12000.0,
        "成交额": 2328.0
    },
    {
        "序号": 540,
        "symbol": "01477",
        "name": "欧康维视生物-B",
        "最新价": 6.83,
        "涨跌额": 0.07,
        "涨跌幅": 1.04,
        "今开": 6.76,
        "最高": 6.9,
        "最低": 6.73,
        "昨收": 6.76,
        "成交量": 829500.0,
        "成交额": 5653734.0
    },
    {
        "序号": 541,
        "symbol": "02182",
        "name": "天长集团",
        "最新价": 0.49,
        "涨跌额": 0.005,
        "涨跌幅": 1.03,
        "今开": 0.49,
        "最高": 0.49,
        "最低": 0.49,
        "昨收": 0.485,
        "成交量": 220000.0,
        "成交额": 107800.0
    },
    {
        "序号": 542,
        "symbol": "02490",
        "name": "乐舱物流",
        "最新价": 5.89,
        "涨跌额": 0.06,
        "涨跌幅": 1.03,
        "今开": 5.83,
        "最高": 6.23,
        "最低": 5.83,
        "昨收": 5.83,
        "成交量": 99000.0,
        "成交额": 600610.0
    },
    {
        "序号": 543,
        "symbol": "02181",
        "name": "迈博药业-B",
        "最新价": 0.495,
        "涨跌额": 0.005,
        "涨跌幅": 1.02,
        "今开": 0.495,
        "最高": 0.495,
        "最低": 0.48,
        "昨收": 0.49,
        "成交量": 848000.0,
        "成交额": 419730.0
    },
    {
        "序号": 544,
        "symbol": "03097",
        "name": "FGX原油",
        "最新价": 4.764,
        "涨跌额": 0.048,
        "涨跌幅": 1.02,
        "今开": 4.764,
        "最高": 4.764,
        "最低": 4.764,
        "昨收": 4.716,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 545,
        "symbol": "08512",
        "name": "凯富善集团控股",
        "最新价": 0.2,
        "涨跌额": 0.002,
        "涨跌幅": 1.01,
        "今开": 0.198,
        "最高": 0.2,
        "最低": 0.198,
        "昨收": 0.198,
        "成交量": 540000.0,
        "成交额": 107870.0
    },
    {
        "序号": 546,
        "symbol": "03303",
        "name": "巨涛海洋石油服务",
        "最新价": 0.5,
        "涨跌额": 0.005,
        "涨跌幅": 1.01,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.495,
        "昨收": 0.495,
        "成交量": 80000.0,
        "成交额": 39930.0
    },
    {
        "序号": 547,
        "symbol": "02203",
        "name": "脑洞科技",
        "最新价": 0.1,
        "涨跌额": 0.001,
        "涨跌幅": 1.01,
        "今开": 0.095,
        "最高": 0.1,
        "最低": 0.09,
        "昨收": 0.099,
        "成交量": 80000.0,
        "成交额": 7550.0
    },
    {
        "序号": 548,
        "symbol": "09834",
        "name": "安硕纳指一百-U",
        "最新价": 38.68,
        "涨跌额": 0.38,
        "涨跌幅": 0.99,
        "今开": 38.3,
        "最高": 38.76,
        "最低": 38.3,
        "昨收": 38.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 549,
        "symbol": "03074",
        "name": "安硕MS台湾",
        "最新价": 168.5,
        "涨跌额": 1.65,
        "涨跌幅": 0.99,
        "今开": 170.0,
        "最高": 170.0,
        "最低": 168.5,
        "昨收": 166.85,
        "成交量": 9100.0,
        "成交额": 1546210.0
    },
    {
        "序号": 550,
        "symbol": "09187",
        "name": "三星高息房托-U",
        "最新价": 2.048,
        "涨跌额": 0.02,
        "涨跌幅": 0.99,
        "今开": 2.048,
        "最高": 2.048,
        "最低": 2.048,
        "昨收": 2.028,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 551,
        "symbol": "00902",
        "name": "华能国际电力股份",
        "最新价": 4.1,
        "涨跌额": 0.04,
        "涨跌幅": 0.99,
        "今开": 4.06,
        "最高": 4.18,
        "最低": 4.06,
        "昨收": 4.06,
        "成交量": 39606455.0,
        "成交额": 163424749.0
    },
    {
        "序号": 552,
        "symbol": "01305",
        "name": "伟志控股",
        "最新价": 1.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.98,
        "今开": 1.02,
        "最高": 1.04,
        "最低": 1.02,
        "昨收": 1.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 553,
        "symbol": "06609",
        "name": "心玮医疗-B",
        "最新价": 25.9,
        "涨跌额": 0.25,
        "涨跌幅": 0.97,
        "今开": 24.5,
        "最高": 25.9,
        "最低": 24.3,
        "昨收": 25.65,
        "成交量": 1150.0,
        "成交额": 29337.0
    },
    {
        "序号": 554,
        "symbol": "09626",
        "name": "哔哩哔哩-W",
        "最新价": 93.45,
        "涨跌额": 0.9,
        "涨跌幅": 0.97,
        "今开": 93.5,
        "最高": 95.45,
        "最低": 92.75,
        "昨收": 92.55,
        "成交量": 2017140.0,
        "成交额": 189244844.0
    },
    {
        "序号": 555,
        "symbol": "00215",
        "name": "和记电讯香港",
        "最新价": 1.04,
        "涨跌额": 0.01,
        "涨跌幅": 0.97,
        "今开": 1.04,
        "最高": 1.06,
        "最低": 1.02,
        "昨收": 1.03,
        "成交量": 2208000.0,
        "成交额": 2299440.0
    },
    {
        "序号": 556,
        "symbol": "03185",
        "name": "GX金融科技",
        "最新价": 33.72,
        "涨跌额": 0.32,
        "涨跌幅": 0.96,
        "今开": 33.76,
        "最高": 33.78,
        "最低": 33.72,
        "昨收": 33.4,
        "成交量": 14700.0,
        "成交额": 496426.0
    },
    {
        "序号": 557,
        "symbol": "01199",
        "name": "中远海运港口",
        "最新价": 5.28,
        "涨跌额": 0.05,
        "涨跌幅": 0.96,
        "今开": 5.26,
        "最高": 5.34,
        "最低": 5.2,
        "昨收": 5.23,
        "成交量": 5044000.0,
        "成交额": 26644980.0
    },
    {
        "序号": 558,
        "symbol": "01143",
        "name": "中国储能科技发展",
        "最新价": 2.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.95,
        "今开": 2.1,
        "最高": 2.13,
        "最低": 2.07,
        "昨收": 2.1,
        "成交量": 202800.0,
        "成交额": 427664.0
    },
    {
        "序号": 559,
        "symbol": "02815",
        "name": "GX中国小巨人",
        "最新价": 51.88,
        "涨跌额": 0.48,
        "涨跌幅": 0.93,
        "今开": 51.48,
        "最高": 51.88,
        "最低": 51.48,
        "昨收": 51.4,
        "成交量": 1049.0,
        "成交额": 54241.0
    },
    {
        "序号": 560,
        "symbol": "01478",
        "name": "丘钛科技",
        "最新价": 4.36,
        "涨跌额": 0.04,
        "涨跌幅": 0.93,
        "今开": 4.3,
        "最高": 4.56,
        "最低": 4.27,
        "昨收": 4.32,
        "成交量": 4404000.0,
        "成交额": 19421860.0
    },
    {
        "序号": 561,
        "symbol": "09086",
        "name": "华夏纳指-U",
        "最新价": 4.15,
        "涨跌额": 0.038,
        "涨跌幅": 0.92,
        "今开": 4.15,
        "最高": 4.15,
        "最低": 4.15,
        "昨收": 4.112,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 562,
        "symbol": "00227",
        "name": "第一上海",
        "最新价": 0.11,
        "涨跌额": 0.001,
        "涨跌幅": 0.92,
        "今开": 0.109,
        "最高": 0.11,
        "最低": 0.109,
        "昨收": 0.109,
        "成交量": 656000.0,
        "成交额": 71648.0
    },
    {
        "序号": 563,
        "symbol": "06836",
        "name": "天韵国际控股",
        "最新价": 3.32,
        "涨跌额": 0.03,
        "涨跌幅": 0.91,
        "今开": 3.29,
        "最高": 3.38,
        "最低": 3.29,
        "昨收": 3.29,
        "成交量": 346000.0,
        "成交额": 1155080.0
    },
    {
        "序号": 564,
        "symbol": "01140",
        "name": "华科智能投资",
        "最新价": 0.111,
        "涨跌额": 0.001,
        "涨跌幅": 0.91,
        "今开": 0.111,
        "最高": 0.111,
        "最低": 0.111,
        "昨收": 0.11,
        "成交量": 32000.0,
        "成交额": 3552.0
    },
    {
        "序号": 565,
        "symbol": "01045",
        "name": "亚太卫星",
        "最新价": 2.22,
        "涨跌额": 0.02,
        "涨跌幅": 0.91,
        "今开": 2.22,
        "最高": 2.22,
        "最低": 2.22,
        "昨收": 2.2,
        "成交量": 2000.0,
        "成交额": 4440.0
    },
    {
        "序号": 566,
        "symbol": "09899",
        "name": "云音乐",
        "最新价": 89.9,
        "涨跌额": 0.8,
        "涨跌幅": 0.9,
        "今开": 88.65,
        "最高": 90.0,
        "最低": 88.65,
        "昨收": 89.1,
        "成交量": 41150.0,
        "成交额": 3692985.0
    },
    {
        "序号": 567,
        "symbol": "01502",
        "name": "金融街物业",
        "最新价": 2.25,
        "涨跌额": 0.02,
        "涨跌幅": 0.9,
        "今开": 2.23,
        "最高": 2.25,
        "最低": 2.23,
        "昨收": 2.23,
        "成交量": 8000.0,
        "成交额": 17860.0
    },
    {
        "序号": 568,
        "symbol": "09826",
        "name": "GX中国云算-U",
        "最新价": 6.215,
        "涨跌额": 0.055,
        "涨跌幅": 0.89,
        "今开": 6.175,
        "最高": 6.235,
        "最低": 6.135,
        "昨收": 6.16,
        "成交量": 20279.0,
        "成交额": 125141.0
    },
    {
        "序号": 569,
        "symbol": "02153",
        "name": "达丰设备",
        "最新价": 1.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.88,
        "今开": 1.12,
        "最高": 1.14,
        "最低": 1.12,
        "昨收": 1.13,
        "成交量": 232000.0,
        "成交额": 260680.0
    },
    {
        "序号": 570,
        "symbol": "01361",
        "name": "361度",
        "最新价": 3.42,
        "涨跌额": 0.03,
        "涨跌幅": 0.88,
        "今开": 3.38,
        "最高": 3.55,
        "最低": 3.33,
        "昨收": 3.39,
        "成交量": 2804000.0,
        "成交额": 9722916.0
    },
    {
        "序号": 571,
        "symbol": "00086",
        "name": "新鸿基公司",
        "最新价": 2.28,
        "涨跌额": 0.02,
        "涨跌幅": 0.88,
        "今开": 2.27,
        "最高": 2.31,
        "最低": 2.26,
        "昨收": 2.26,
        "成交量": 637000.0,
        "成交额": 1455600.0
    },
    {
        "序号": 572,
        "symbol": "03187",
        "name": "三星高息房托",
        "最新价": 16.05,
        "涨跌额": 0.14,
        "涨跌幅": 0.88,
        "今开": 16.06,
        "最高": 16.07,
        "最低": 16.05,
        "昨收": 15.91,
        "成交量": 4600.0,
        "成交额": 73876.0
    },
    {
        "序号": 573,
        "symbol": "02198",
        "name": "中国三江化工",
        "最新价": 1.15,
        "涨跌额": 0.01,
        "涨跌幅": 0.88,
        "今开": 1.14,
        "最高": 1.18,
        "最低": 1.14,
        "昨收": 1.14,
        "成交量": 50000.0,
        "成交额": 57810.0
    },
    {
        "序号": 574,
        "symbol": "09191",
        "name": "GX中国半导-U",
        "最新价": 4.616,
        "涨跌额": 0.04,
        "涨跌幅": 0.87,
        "今开": 4.568,
        "最高": 4.64,
        "最低": 4.566,
        "昨收": 4.576,
        "成交量": 66050.0,
        "成交额": 303764.0
    },
    {
        "序号": 575,
        "symbol": "01919",
        "name": "中远海控",
        "最新价": 6.98,
        "涨跌额": 0.06,
        "涨跌幅": 0.87,
        "今开": 6.97,
        "最高": 6.99,
        "最低": 6.9,
        "昨收": 6.92,
        "成交量": 11660200.0,
        "成交额": 80959517.0
    },
    {
        "序号": 576,
        "symbol": "02066",
        "name": "盛京银行",
        "最新价": 1.17,
        "涨跌额": 0.01,
        "涨跌幅": 0.86,
        "今开": 1.16,
        "最高": 1.17,
        "最低": 1.16,
        "昨收": 1.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 577,
        "symbol": "00953",
        "name": "邵氏兄弟控股",
        "最新价": 0.117,
        "涨跌额": 0.001,
        "涨跌幅": 0.86,
        "今开": 0.117,
        "最高": 0.121,
        "最低": 0.116,
        "昨收": 0.116,
        "成交量": 348000.0,
        "成交额": 41474.0
    },
    {
        "序号": 578,
        "symbol": "02830",
        "name": "南方沙特",
        "最新价": 78.4,
        "涨跌额": 0.66,
        "涨跌幅": 0.85,
        "今开": 78.8,
        "最高": 78.8,
        "最低": 78.34,
        "昨收": 77.74,
        "成交量": 23400.0,
        "成交额": 1838422.0
    },
    {
        "序号": 579,
        "symbol": "01658",
        "name": "邮储银行",
        "最新价": 3.57,
        "涨跌额": 0.03,
        "涨跌幅": 0.85,
        "今开": 3.56,
        "最高": 3.63,
        "最低": 3.53,
        "昨收": 3.54,
        "成交量": 40012650.0,
        "成交额": 143761472.0
    },
    {
        "序号": 580,
        "symbol": "09074",
        "name": "安硕MS台湾-U",
        "最新价": 21.56,
        "涨跌额": 0.18,
        "涨跌幅": 0.84,
        "今开": 21.38,
        "最高": 21.74,
        "最低": 21.38,
        "昨收": 21.38,
        "成交量": 9.0,
        "成交额": 195.0
    },
    {
        "序号": 581,
        "symbol": "03336",
        "name": "巨腾国际",
        "最新价": 1.2,
        "涨跌额": 0.01,
        "涨跌幅": 0.84,
        "今开": 1.28,
        "最高": 1.28,
        "最低": 1.13,
        "昨收": 1.19,
        "成交量": 102000.0,
        "成交额": 122060.0
    },
    {
        "序号": 582,
        "symbol": "01995",
        "name": "旭辉永升服务",
        "最新价": 1.2,
        "涨跌额": 0.01,
        "涨跌幅": 0.84,
        "今开": 1.19,
        "最高": 1.22,
        "最低": 1.18,
        "昨收": 1.19,
        "成交量": 8116000.0,
        "成交额": 9764940.0
    },
    {
        "序号": 583,
        "symbol": "01823",
        "name": "华昱高速",
        "最新价": 1.21,
        "涨跌额": 0.01,
        "涨跌幅": 0.83,
        "今开": 1.2,
        "最高": 1.21,
        "最低": 1.2,
        "昨收": 1.2,
        "成交量": 14000.0,
        "成交额": 16900.0
    },
    {
        "序号": 584,
        "symbol": "00323",
        "name": "马鞍山钢铁股份",
        "最新价": 1.21,
        "涨跌额": 0.01,
        "涨跌幅": 0.83,
        "今开": 1.23,
        "最高": 1.23,
        "最低": 1.21,
        "昨收": 1.2,
        "成交量": 998000.0,
        "成交额": 1215834.0
    },
    {
        "序号": 585,
        "symbol": "09969",
        "name": "诺诚健华",
        "最新价": 6.09,
        "涨跌额": 0.05,
        "涨跌幅": 0.83,
        "今开": 6.13,
        "最高": 6.17,
        "最低": 5.98,
        "昨收": 6.04,
        "成交量": 2100000.0,
        "成交额": 12739071.0
    },
    {
        "序号": 586,
        "symbol": "01925",
        "name": "旷世控股",
        "最新价": 1.22,
        "涨跌额": 0.01,
        "涨跌幅": 0.83,
        "今开": 1.22,
        "最高": 1.23,
        "最低": 1.2,
        "昨收": 1.21,
        "成交量": 154000.0,
        "成交额": 187960.0
    },
    {
        "序号": 587,
        "symbol": "00400",
        "name": "硬蛋创新",
        "最新价": 1.23,
        "涨跌额": 0.01,
        "涨跌幅": 0.82,
        "今开": 1.2,
        "最高": 1.24,
        "最低": 1.03,
        "昨收": 1.22,
        "成交量": 1113000.0,
        "成交额": 1284610.0
    },
    {
        "序号": 588,
        "symbol": "02137",
        "name": "腾盛博药-B",
        "最新价": 2.49,
        "涨跌额": 0.02,
        "涨跌幅": 0.81,
        "今开": 2.5,
        "最高": 2.53,
        "最低": 2.41,
        "昨收": 2.47,
        "成交量": 3735500.0,
        "成交额": 9263600.0
    },
    {
        "序号": 589,
        "symbol": "01412",
        "name": "隽思集团",
        "最新价": 1.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.81,
        "今开": 1.25,
        "最高": 1.25,
        "最低": 1.25,
        "昨收": 1.24,
        "成交量": 10000.0,
        "成交额": 12500.0
    },
    {
        "序号": 590,
        "symbol": "00906",
        "name": "中粮包装",
        "最新价": 6.35,
        "涨跌额": 0.05,
        "涨跌幅": 0.79,
        "今开": 6.35,
        "最高": 6.4,
        "最低": 6.25,
        "昨收": 6.3,
        "成交量": 5339000.0,
        "成交额": 33680795.0
    },
    {
        "序号": 591,
        "symbol": "03034",
        "name": "A南方元宇宙",
        "最新价": 7.0,
        "涨跌额": 0.055,
        "涨跌幅": 0.79,
        "今开": 7.01,
        "最高": 7.01,
        "最低": 7.0,
        "昨收": 6.945,
        "成交量": 2000.0,
        "成交额": 14010.0
    },
    {
        "序号": 592,
        "symbol": "03117",
        "name": "GX中国游戏娱乐",
        "最新价": 33.28,
        "涨跌额": 0.26,
        "涨跌幅": 0.79,
        "今开": 33.28,
        "最高": 33.28,
        "最低": 33.28,
        "昨收": 33.02,
        "成交量": 100.0,
        "成交额": 3328.0
    },
    {
        "序号": 593,
        "symbol": "03838",
        "name": "中国淀粉",
        "最新价": 0.129,
        "涨跌额": 0.001,
        "涨跌幅": 0.78,
        "今开": 0.129,
        "最高": 0.129,
        "最低": 0.129,
        "昨收": 0.128,
        "成交量": 50000.0,
        "成交额": 6450.0
    },
    {
        "序号": 594,
        "symbol": "01428",
        "name": "耀才证券金融",
        "最新价": 1.29,
        "涨跌额": 0.01,
        "涨跌幅": 0.78,
        "今开": 1.28,
        "最高": 1.29,
        "最低": 1.27,
        "昨收": 1.28,
        "成交量": 1940000.0,
        "成交额": 2491220.0
    },
    {
        "序号": 595,
        "symbol": "00020",
        "name": "商汤-W",
        "最新价": 1.29,
        "涨跌额": 0.01,
        "涨跌幅": 0.78,
        "今开": 1.3,
        "最高": 1.33,
        "最低": 1.27,
        "昨收": 1.28,
        "成交量": 162711586.0,
        "成交额": 211327035.0
    },
    {
        "序号": 596,
        "symbol": "09618",
        "name": "京东集团-SW",
        "最新价": 104.8,
        "涨跌额": 0.8,
        "涨跌幅": 0.77,
        "今开": 103.4,
        "最高": 105.8,
        "最低": 102.8,
        "昨收": 104.0,
        "成交量": 10925791.0,
        "成交额": 1143705312.0
    },
    {
        "序号": 597,
        "symbol": "01689",
        "name": "华禧控股",
        "最新价": 1.31,
        "涨跌额": 0.01,
        "涨跌幅": 0.77,
        "今开": 1.3,
        "最高": 1.31,
        "最低": 1.22,
        "昨收": 1.3,
        "成交量": 648000.0,
        "成交额": 822160.0
    },
    {
        "序号": 598,
        "symbol": "00544",
        "name": "大同集团",
        "最新价": 0.132,
        "涨跌额": 0.001,
        "涨跌幅": 0.76,
        "今开": 0.127,
        "最高": 0.132,
        "最低": 0.127,
        "昨收": 0.131,
        "成交量": 320000.0,
        "成交额": 40640.0
    },
    {
        "序号": 599,
        "symbol": "01571",
        "name": "信邦控股",
        "最新价": 2.66,
        "涨跌额": 0.02,
        "涨跌幅": 0.76,
        "今开": 2.69,
        "最高": 2.73,
        "最低": 2.66,
        "昨收": 2.64,
        "成交量": 281000.0,
        "成交额": 757980.0
    },
    {
        "序号": 600,
        "symbol": "01503",
        "name": "招商局商业房托",
        "最新价": 1.33,
        "涨跌额": 0.01,
        "涨跌幅": 0.76,
        "今开": 1.34,
        "最高": 1.34,
        "最低": 1.31,
        "昨收": 1.32,
        "成交量": 62000.0,
        "成交额": 82360.0
    },
    {
        "序号": 601,
        "symbol": "00730",
        "name": "首惠产业金融",
        "最新价": 0.133,
        "涨跌额": 0.001,
        "涨跌幅": 0.76,
        "今开": 0.13,
        "最高": 0.133,
        "最低": 0.126,
        "昨收": 0.132,
        "成交量": 44000.0,
        "成交额": 5721.0
    },
    {
        "序号": 602,
        "symbol": "00003",
        "name": "香港中华煤气",
        "最新价": 5.35,
        "涨跌额": 0.04,
        "涨跌幅": 0.75,
        "今开": 5.33,
        "最高": 5.4,
        "最低": 5.31,
        "昨收": 5.31,
        "成交量": 11443599.0,
        "成交额": 61216792.0
    },
    {
        "序号": 603,
        "symbol": "01772",
        "name": "赣锋锂业",
        "最新价": 26.8,
        "涨跌额": 0.2,
        "涨跌幅": 0.75,
        "今开": 27.3,
        "最高": 27.45,
        "最低": 26.45,
        "昨收": 26.6,
        "成交量": 5247142.0,
        "成交额": 141116236.0
    },
    {
        "序号": 604,
        "symbol": "01691",
        "name": "JS环球生活",
        "最新价": 1.34,
        "涨跌额": 0.01,
        "涨跌幅": 0.75,
        "今开": 1.31,
        "最高": 1.35,
        "最低": 1.3,
        "昨收": 1.33,
        "成交量": 5175400.0,
        "成交额": 6870367.0
    },
    {
        "序号": 605,
        "symbol": "00001",
        "name": "长和",
        "最新价": 40.2,
        "涨跌额": 0.3,
        "涨跌幅": 0.75,
        "今开": 39.8,
        "最高": 40.4,
        "最低": 39.65,
        "昨收": 39.9,
        "成交量": 5275343.0,
        "成交额": 211796936.0
    },
    {
        "序号": 606,
        "symbol": "02826",
        "name": "GX中国云算",
        "最新价": 48.48,
        "涨跌额": 0.36,
        "涨跌幅": 0.75,
        "今开": 48.18,
        "最高": 48.62,
        "最低": 48.0,
        "昨收": 48.12,
        "成交量": 29200.0,
        "成交额": 1409804.0
    },
    {
        "序号": 607,
        "symbol": "00002",
        "name": "中电控股",
        "最新价": 61.2,
        "涨跌额": 0.45,
        "涨跌幅": 0.74,
        "今开": 61.05,
        "最高": 61.5,
        "最低": 60.55,
        "昨收": 60.75,
        "成交量": 3062106.0,
        "成交额": 187180647.0
    },
    {
        "序号": 608,
        "symbol": "07552",
        "name": "南方两倍做空恒生科技",
        "最新价": 6.83,
        "涨跌额": 0.05,
        "涨跌幅": 0.74,
        "今开": 6.76,
        "最高": 6.95,
        "最低": 6.68,
        "昨收": 6.78,
        "成交量": 55091900.0,
        "成交额": 376255280.0
    },
    {
        "序号": 609,
        "symbol": "07588",
        "name": "南方两倍看空恒生国指",
        "最新价": 6.21,
        "涨跌额": 0.045,
        "涨跌幅": 0.73,
        "今开": 6.13,
        "最高": 6.26,
        "最低": 6.1,
        "昨收": 6.165,
        "成交量": 570500.0,
        "成交额": 3525234.0
    },
    {
        "序号": 610,
        "symbol": "00006",
        "name": "电能实业",
        "最新价": 41.6,
        "涨跌额": 0.3,
        "涨跌幅": 0.73,
        "今开": 41.2,
        "最高": 41.8,
        "最低": 41.05,
        "昨收": 41.3,
        "成交量": 3870006.0,
        "成交额": 160785422.0
    },
    {
        "序号": 611,
        "symbol": "01836",
        "name": "九兴控股",
        "最新价": 9.73,
        "涨跌额": 0.07,
        "涨跌幅": 0.72,
        "今开": 9.66,
        "最高": 9.79,
        "最低": 9.66,
        "昨收": 9.66,
        "成交量": 410500.0,
        "成交额": 3988150.0
    },
    {
        "序号": 612,
        "symbol": "01419",
        "name": "盈健医疗",
        "最新价": 1.39,
        "涨跌额": 0.01,
        "涨跌幅": 0.72,
        "今开": 1.39,
        "最高": 1.39,
        "最低": 1.35,
        "昨收": 1.38,
        "成交量": 382000.0,
        "成交额": 525580.0
    },
    {
        "序号": 613,
        "symbol": "00639",
        "name": "首钢资源",
        "最新价": 2.79,
        "涨跌额": 0.02,
        "涨跌幅": 0.72,
        "今开": 2.77,
        "最高": 2.79,
        "最低": 2.76,
        "昨收": 2.77,
        "成交量": 3237587.0,
        "成交额": 8994402.0
    },
    {
        "序号": 614,
        "symbol": "02886",
        "name": "滨海投资",
        "最新价": 1.4,
        "涨跌额": 0.01,
        "涨跌幅": 0.72,
        "今开": 1.38,
        "最高": 1.44,
        "最低": 1.35,
        "昨收": 1.39,
        "成交量": 2341700.0,
        "成交额": 3222748.0
    },
    {
        "序号": 615,
        "symbol": "03010",
        "name": "安硕亚洲除日",
        "最新价": 47.8,
        "涨跌额": 0.34,
        "涨跌幅": 0.72,
        "今开": 47.94,
        "最高": 47.94,
        "最低": 47.68,
        "昨收": 47.46,
        "成交量": 19059.0,
        "成交额": 911445.0
    },
    {
        "序号": 616,
        "symbol": "00035",
        "name": "远东发展",
        "最新价": 1.41,
        "涨跌额": 0.01,
        "涨跌幅": 0.71,
        "今开": 1.38,
        "最高": 1.41,
        "最低": 1.37,
        "昨收": 1.4,
        "成交量": 611256.0,
        "成交额": 854578.0
    },
    {
        "序号": 617,
        "symbol": "06806",
        "name": "申万宏源",
        "最新价": 1.42,
        "涨跌额": 0.01,
        "涨跌幅": 0.71,
        "今开": 1.41,
        "最高": 1.42,
        "最低": 1.39,
        "昨收": 1.41,
        "成交量": 2993600.0,
        "成交额": 4207544.0
    },
    {
        "序号": 618,
        "symbol": "03737",
        "name": "中智药业",
        "最新价": 1.42,
        "涨跌额": 0.01,
        "涨跌幅": 0.71,
        "今开": 1.44,
        "最高": 1.44,
        "最低": 1.41,
        "昨收": 1.41,
        "成交量": 419000.0,
        "成交额": 598760.0
    },
    {
        "序号": 619,
        "symbol": "06885",
        "name": "金马能源",
        "最新价": 1.43,
        "涨跌额": 0.01,
        "涨跌幅": 0.7,
        "今开": 1.39,
        "最高": 1.43,
        "最低": 1.38,
        "昨收": 1.42,
        "成交量": 18000.0,
        "成交额": 25240.0
    },
    {
        "序号": 620,
        "symbol": "00909",
        "name": "明源云",
        "最新价": 2.86,
        "涨跌额": 0.02,
        "涨跌幅": 0.7,
        "今开": 2.86,
        "最高": 3.0,
        "最低": 2.82,
        "昨收": 2.84,
        "成交量": 14395000.0,
        "成交额": 41624242.0
    },
    {
        "序号": 621,
        "symbol": "09858",
        "name": "优然牧业",
        "最新价": 1.44,
        "涨跌额": 0.01,
        "涨跌幅": 0.7,
        "今开": 1.45,
        "最高": 1.46,
        "最低": 1.43,
        "昨收": 1.43,
        "成交量": 3590000.0,
        "成交额": 5200250.0
    },
    {
        "序号": 622,
        "symbol": "03171",
        "name": "A三星区块链",
        "最新价": 20.22,
        "涨跌额": 0.14,
        "涨跌幅": 0.7,
        "今开": 20.1,
        "最高": 20.22,
        "最低": 20.1,
        "昨收": 20.08,
        "成交量": 150.0,
        "成交额": 3021.0
    },
    {
        "序号": 623,
        "symbol": "00636",
        "name": "嘉里物流",
        "最新价": 7.28,
        "涨跌额": 0.05,
        "涨跌幅": 0.69,
        "今开": 7.27,
        "最高": 7.42,
        "最低": 7.17,
        "昨收": 7.23,
        "成交量": 371025.0,
        "成交额": 2691006.0
    },
    {
        "序号": 624,
        "symbol": "00525",
        "name": "广深铁路股份",
        "最新价": 1.48,
        "涨跌额": 0.01,
        "涨跌幅": 0.68,
        "今开": 1.46,
        "最高": 1.5,
        "最低": 1.46,
        "昨收": 1.47,
        "成交量": 5428734.0,
        "成交额": 8049387.0
    },
    {
        "序号": 625,
        "symbol": "03056",
        "name": "A潘渡创新主题",
        "最新价": 10.4,
        "涨跌额": 0.07,
        "涨跌幅": 0.68,
        "今开": 10.4,
        "最高": 10.4,
        "最低": 10.4,
        "昨收": 10.33,
        "成交量": 5200.0,
        "成交额": 54080.0
    },
    {
        "序号": 626,
        "symbol": "03618",
        "name": "重庆农村商业银行",
        "最新价": 2.98,
        "涨跌额": 0.02,
        "涨跌幅": 0.68,
        "今开": 2.92,
        "最高": 2.98,
        "最低": 2.92,
        "昨收": 2.96,
        "成交量": 8090000.0,
        "成交额": 24041560.0
    },
    {
        "序号": 627,
        "symbol": "06601",
        "name": "朝云集团",
        "最新价": 1.5,
        "涨跌额": 0.01,
        "涨跌幅": 0.67,
        "今开": 1.48,
        "最高": 1.5,
        "最低": 1.47,
        "昨收": 1.49,
        "成交量": 566000.0,
        "成交额": 842460.0
    },
    {
        "序号": 628,
        "symbol": "06058",
        "name": "兴证国际",
        "最新价": 0.15,
        "涨跌额": 0.001,
        "涨跌幅": 0.67,
        "今开": 0.149,
        "最高": 0.15,
        "最低": 0.149,
        "昨收": 0.149,
        "成交量": 712000.0,
        "成交额": 106098.0
    },
    {
        "序号": 629,
        "symbol": "00472",
        "name": "新丝路文旅",
        "最新价": 0.151,
        "涨跌额": 0.001,
        "涨跌幅": 0.67,
        "今开": 0.15,
        "最高": 0.151,
        "最低": 0.15,
        "昨收": 0.15,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 630,
        "symbol": "01899",
        "name": "兴达国际",
        "最新价": 1.53,
        "涨跌额": 0.01,
        "涨跌幅": 0.66,
        "今开": 1.52,
        "最高": 1.53,
        "最低": 1.5,
        "昨收": 1.52,
        "成交量": 2076290.0,
        "成交额": 3135966.0
    },
    {
        "序号": 631,
        "symbol": "00883",
        "name": "中国海洋石油",
        "最新价": 12.54,
        "涨跌额": 0.08,
        "涨跌幅": 0.64,
        "今开": 12.48,
        "最高": 12.78,
        "最低": 12.42,
        "昨收": 12.46,
        "成交量": 88245482.0,
        "成交额": 1115309648.0
    },
    {
        "序号": 632,
        "symbol": "03690",
        "name": "美团-W",
        "最新价": 86.65,
        "涨跌额": 0.55,
        "涨跌幅": 0.64,
        "今开": 86.35,
        "最高": 87.55,
        "最低": 84.2,
        "昨收": 86.1,
        "成交量": 49376170.0,
        "成交额": 4248423040.0
    },
    {
        "序号": 633,
        "symbol": "00341",
        "name": "大家乐集团",
        "最新价": 9.52,
        "涨跌额": 0.06,
        "涨跌幅": 0.63,
        "今开": 9.55,
        "最高": 9.65,
        "最低": 9.47,
        "昨收": 9.46,
        "成交量": 388000.0,
        "成交额": 3700240.0
    },
    {
        "序号": 634,
        "symbol": "03006",
        "name": "AGX元宇宙",
        "最新价": 63.62,
        "涨跌额": 0.4,
        "涨跌幅": 0.63,
        "今开": 63.62,
        "最高": 63.62,
        "最低": 63.62,
        "昨收": 63.22,
        "成交量": 500.0,
        "成交额": 31810.0
    },
    {
        "序号": 635,
        "symbol": "06060",
        "name": "众安在线",
        "最新价": 19.12,
        "涨跌额": 0.12,
        "涨跌幅": 0.63,
        "今开": 19.2,
        "最高": 19.58,
        "最低": 19.02,
        "昨收": 19.0,
        "成交量": 1641298.0,
        "成交额": 31535418.0
    },
    {
        "序号": 636,
        "symbol": "09075",
        "name": "GX亚洲美债-U",
        "最新价": 7.3,
        "涨跌额": 0.045,
        "涨跌幅": 0.62,
        "今开": 7.3,
        "最高": 7.3,
        "最低": 7.3,
        "昨收": 7.255,
        "成交量": 200.0,
        "成交额": 1460.0
    },
    {
        "序号": 637,
        "symbol": "07234",
        "name": "博时两倍做多中国创业板",
        "最新价": 3.9,
        "涨跌额": 0.024,
        "涨跌幅": 0.62,
        "今开": 3.89,
        "最高": 3.926,
        "最低": 3.862,
        "昨收": 3.876,
        "成交量": 27400.0,
        "成交额": 106834.0
    },
    {
        "序号": 638,
        "symbol": "03087",
        "name": "XTR富时越南",
        "最新价": 211.3,
        "涨跌额": 1.3,
        "涨跌幅": 0.62,
        "今开": 210.0,
        "最高": 211.3,
        "最低": 210.0,
        "昨收": 210.0,
        "成交量": 630.0,
        "成交额": 132709.0
    },
    {
        "序号": 639,
        "symbol": "00887",
        "name": "英皇钟表珠宝",
        "最新价": 0.163,
        "涨跌额": 0.001,
        "涨跌幅": 0.62,
        "今开": 0.163,
        "最高": 0.163,
        "最低": 0.163,
        "昨收": 0.162,
        "成交量": 60000.0,
        "成交额": 9780.0
    },
    {
        "序号": 640,
        "symbol": "03001",
        "name": "PP中地美债",
        "最新价": 73.85,
        "涨跌额": 0.45,
        "涨跌幅": 0.61,
        "今开": 73.55,
        "最高": 74.0,
        "最低": 73.4,
        "昨收": 73.4,
        "成交量": 4835.0,
        "成交额": 356145.0
    },
    {
        "序号": 641,
        "symbol": "01953",
        "name": "RIMBACO",
        "最新价": 0.166,
        "涨跌额": 0.001,
        "涨跌幅": 0.61,
        "今开": 0.165,
        "最高": 0.166,
        "最低": 0.165,
        "昨收": 0.165,
        "成交量": 45000.0,
        "成交额": 7425.0
    },
    {
        "序号": 642,
        "symbol": "03888",
        "name": "金山软件",
        "最新价": 25.1,
        "涨跌额": 0.15,
        "涨跌幅": 0.6,
        "今开": 25.5,
        "最高": 25.6,
        "最低": 24.75,
        "昨收": 24.95,
        "成交量": 5609019.0,
        "成交额": 140654184.0
    },
    {
        "序号": 643,
        "symbol": "03899",
        "name": "中集安瑞科",
        "最新价": 6.71,
        "涨跌额": 0.04,
        "涨跌幅": 0.6,
        "今开": 6.7,
        "最高": 6.71,
        "最低": 6.55,
        "昨收": 6.67,
        "成交量": 1213064.0,
        "成交额": 8043206.0
    },
    {
        "序号": 644,
        "symbol": "01997",
        "name": "九龙仓置业",
        "最新价": 25.25,
        "涨跌额": 0.15,
        "涨跌幅": 0.6,
        "今开": 25.05,
        "最高": 25.65,
        "最低": 25.05,
        "昨收": 25.1,
        "成交量": 2126260.0,
        "成交额": 53848023.0
    },
    {
        "序号": 645,
        "symbol": "02698",
        "name": "魏桥纺织",
        "最新价": 3.37,
        "涨跌额": 0.02,
        "涨跌幅": 0.6,
        "今开": 3.35,
        "最高": 3.38,
        "最低": 3.35,
        "昨收": 3.35,
        "成交量": 4078000.0,
        "成交额": 13698911.0
    },
    {
        "序号": 646,
        "symbol": "03958",
        "name": "东方证券",
        "最新价": 3.38,
        "涨跌额": 0.02,
        "涨跌幅": 0.6,
        "今开": 3.34,
        "最高": 3.38,
        "最低": 3.32,
        "昨收": 3.36,
        "成交量": 1864000.0,
        "成交额": 6264676.0
    },
    {
        "序号": 647,
        "symbol": "03998",
        "name": "波司登",
        "最新价": 3.39,
        "涨跌额": 0.02,
        "涨跌幅": 0.59,
        "今开": 3.39,
        "最高": 3.45,
        "最低": 3.37,
        "昨收": 3.37,
        "成交量": 30030406.0,
        "成交额": 102326986.0
    },
    {
        "序号": 648,
        "symbol": "02148",
        "name": "VESYNC",
        "最新价": 5.12,
        "涨跌额": 0.03,
        "涨跌幅": 0.59,
        "今开": 5.09,
        "最高": 5.16,
        "最低": 5.02,
        "昨收": 5.09,
        "成交量": 437000.0,
        "成交额": 2229850.0
    },
    {
        "序号": 649,
        "symbol": "00010",
        "name": "恒隆集团",
        "最新价": 10.4,
        "涨跌额": 0.06,
        "涨跌幅": 0.58,
        "今开": 10.58,
        "最高": 10.58,
        "最低": 10.36,
        "昨收": 10.34,
        "成交量": 273000.0,
        "成交额": 2843820.0
    },
    {
        "序号": 650,
        "symbol": "02328",
        "name": "中国财险",
        "最新价": 8.85,
        "涨跌额": 0.05,
        "涨跌幅": 0.57,
        "今开": 8.8,
        "最高": 8.92,
        "最低": 8.72,
        "昨收": 8.8,
        "成交量": 18776310.0,
        "成交额": 166034379.0
    },
    {
        "序号": 651,
        "symbol": "00626",
        "name": "大众金融控股",
        "最新价": 1.77,
        "涨跌额": 0.01,
        "涨跌幅": 0.57,
        "今开": 1.75,
        "最高": 1.77,
        "最低": 1.75,
        "昨收": 1.76,
        "成交量": 4000.0,
        "成交额": 7040.0
    },
    {
        "序号": 652,
        "symbol": "03147",
        "name": "X南方中创业",
        "最新价": 7.18,
        "涨跌额": 0.04,
        "涨跌幅": 0.56,
        "今开": 7.15,
        "最高": 7.21,
        "最低": 7.13,
        "昨收": 7.14,
        "成交量": 87800.0,
        "成交额": 630260.0
    },
    {
        "序号": 653,
        "symbol": "01816",
        "name": "中广核电力",
        "最新价": 1.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.54,
        "今开": 1.85,
        "最高": 1.87,
        "最低": 1.83,
        "昨收": 1.85,
        "成交量": 11418000.0,
        "成交额": 21172460.0
    },
    {
        "序号": 654,
        "symbol": "00185",
        "name": "正商实业",
        "最新价": 0.186,
        "涨跌额": 0.001,
        "涨跌幅": 0.54,
        "今开": 0.2,
        "最高": 0.2,
        "最低": 0.186,
        "昨收": 0.185,
        "成交量": 24000.0,
        "成交额": 4680.0
    },
    {
        "序号": 655,
        "symbol": "08043",
        "name": "ATLINKS",
        "最新价": 0.187,
        "涨跌额": 0.001,
        "涨跌幅": 0.54,
        "今开": 0.186,
        "最高": 0.187,
        "最低": 0.186,
        "昨收": 0.186,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 656,
        "symbol": "06628",
        "name": "创胜集团-B",
        "最新价": 3.8,
        "涨跌额": 0.02,
        "涨跌幅": 0.53,
        "今开": 3.7,
        "最高": 3.84,
        "最低": 3.64,
        "昨收": 3.78,
        "成交量": 41000.0,
        "成交额": 152350.0
    },
    {
        "序号": 657,
        "symbol": "00386",
        "name": "中国石油化工股份",
        "最新价": 3.9,
        "涨跌额": 0.02,
        "涨跌幅": 0.52,
        "今开": 3.93,
        "最高": 3.95,
        "最低": 3.88,
        "昨收": 3.88,
        "成交量": 45630834.0,
        "成交额": 178340971.0
    },
    {
        "序号": 658,
        "symbol": "01651",
        "name": "津上机床中国",
        "最新价": 7.84,
        "涨跌额": 0.04,
        "涨跌幅": 0.51,
        "今开": 7.84,
        "最高": 7.84,
        "最低": 7.84,
        "昨收": 7.8,
        "成交量": 3000.0,
        "成交额": 23520.0
    },
    {
        "序号": 659,
        "symbol": "00685",
        "name": "世界华文媒体",
        "最新价": 0.196,
        "涨跌额": 0.001,
        "涨跌幅": 0.51,
        "今开": 0.196,
        "最高": 0.196,
        "最低": 0.196,
        "昨收": 0.195,
        "成交量": 20000.0,
        "成交额": 3920.0
    },
    {
        "序号": 660,
        "symbol": "00354",
        "name": "中国软件国际",
        "最新价": 5.9,
        "涨跌额": 0.03,
        "涨跌幅": 0.51,
        "今开": 5.85,
        "最高": 6.0,
        "最低": 5.81,
        "昨收": 5.87,
        "成交量": 15551348.0,
        "成交额": 91936770.0
    },
    {
        "序号": 661,
        "symbol": "00052",
        "name": "大快活集团",
        "最新价": 9.9,
        "涨跌额": 0.05,
        "涨跌幅": 0.51,
        "今开": 9.88,
        "最高": 9.9,
        "最低": 9.88,
        "昨收": 9.85,
        "成交量": 27000.0,
        "成交额": 267105.0
    },
    {
        "序号": 662,
        "symbol": "82839",
        "name": "华夏A50-R",
        "最新价": 17.83,
        "涨跌额": 0.09,
        "涨跌幅": 0.51,
        "今开": 17.85,
        "最高": 17.85,
        "最低": 17.83,
        "昨收": 17.74,
        "成交量": 1000.0,
        "成交额": 17850.0
    },
    {
        "序号": 663,
        "symbol": "82830",
        "name": "南方沙特-R",
        "最新价": 71.86,
        "涨跌额": 0.36,
        "涨跌幅": 0.5,
        "今开": 71.5,
        "最高": 72.0,
        "最低": 71.5,
        "昨收": 71.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 664,
        "symbol": "00110",
        "name": "中国长远",
        "最新价": 0.2,
        "涨跌额": 0.001,
        "涨跌幅": 0.5,
        "今开": 0.199,
        "最高": 0.2,
        "最低": 0.199,
        "昨收": 0.199,
        "成交量": 68000.0,
        "成交额": 13580.0
    },
    {
        "序号": 665,
        "symbol": "09010",
        "name": "安硕亚洲除日-U",
        "最新价": 6.1,
        "涨跌额": 0.03,
        "涨跌幅": 0.49,
        "今开": 6.07,
        "最高": 6.13,
        "最低": 6.07,
        "昨收": 6.07,
        "成交量": 289400.0,
        "成交额": 1755028.0
    },
    {
        "序号": 666,
        "symbol": "03162",
        "name": "南方智能驾驶",
        "最新价": 4.51,
        "涨跌额": 0.022,
        "涨跌幅": 0.49,
        "今开": 4.488,
        "最高": 4.51,
        "最低": 4.488,
        "昨收": 4.488,
        "成交量": 1000.0,
        "成交额": 4488.0
    },
    {
        "序号": 667,
        "symbol": "02145",
        "name": "上美股份",
        "最新价": 20.5,
        "涨跌额": 0.1,
        "涨跌幅": 0.49,
        "今开": 20.4,
        "最高": 20.6,
        "最低": 20.05,
        "昨收": 20.4,
        "成交量": 112700.0,
        "成交额": 2298135.0
    },
    {
        "序号": 668,
        "symbol": "00435",
        "name": "阳光房地产基金",
        "最新价": 2.05,
        "涨跌额": 0.01,
        "涨跌幅": 0.49,
        "今开": 2.07,
        "最高": 2.07,
        "最低": 2.04,
        "昨收": 2.04,
        "成交量": 251889.0,
        "成交额": 515983.0
    },
    {
        "序号": 669,
        "symbol": "00403",
        "name": "星光集团",
        "最新价": 0.205,
        "涨跌额": 0.001,
        "涨跌幅": 0.49,
        "今开": 0.198,
        "最高": 0.205,
        "最低": 0.198,
        "昨收": 0.204,
        "成交量": 4000.0,
        "成交额": 794.0
    },
    {
        "序号": 670,
        "symbol": "01114",
        "name": "华晨中国",
        "最新价": 4.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.49,
        "今开": 4.1,
        "最高": 4.16,
        "最低": 4.04,
        "昨收": 4.1,
        "成交量": 13033200.0,
        "成交额": 53590250.0
    },
    {
        "序号": 671,
        "symbol": "06978",
        "name": "永泰生物-B",
        "最新价": 4.14,
        "涨跌额": 0.02,
        "涨跌幅": 0.49,
        "今开": 4.11,
        "最高": 4.14,
        "最低": 3.97,
        "昨收": 4.12,
        "成交量": 65000.0,
        "成交额": 264630.0
    },
    {
        "序号": 672,
        "symbol": "02146",
        "name": "荣万家",
        "最新价": 2.09,
        "涨跌额": 0.01,
        "涨跌幅": 0.48,
        "今开": 2.09,
        "最高": 2.09,
        "最低": 2.08,
        "昨收": 2.08,
        "成交量": 12500.0,
        "成交额": 26025.0
    },
    {
        "序号": 673,
        "symbol": "01068",
        "name": "雨润食品",
        "最新价": 0.21,
        "涨跌额": 0.001,
        "涨跌幅": 0.48,
        "今开": 0.209,
        "最高": 0.225,
        "最低": 0.209,
        "昨收": 0.209,
        "成交量": 288000.0,
        "成交额": 60538.0
    },
    {
        "序号": 674,
        "symbol": "83111",
        "name": "易方达A50-R",
        "最新价": 1.716,
        "涨跌额": 0.008,
        "涨跌幅": 0.47,
        "今开": 1.718,
        "最高": 1.718,
        "最低": 1.716,
        "昨收": 1.708,
        "成交量": 119500.0,
        "成交额": 205301.0
    },
    {
        "序号": 675,
        "symbol": "08436",
        "name": "德宝集团控股",
        "最新价": 0.219,
        "涨跌额": 0.001,
        "涨跌幅": 0.46,
        "今开": 0.219,
        "最高": 0.219,
        "最低": 0.219,
        "昨收": 0.218,
        "成交量": 5000.0,
        "成交额": 1095.0
    },
    {
        "序号": 676,
        "symbol": "01093",
        "name": "石药集团",
        "最新价": 6.63,
        "涨跌额": 0.03,
        "涨跌幅": 0.45,
        "今开": 6.61,
        "最高": 6.67,
        "最低": 6.54,
        "昨收": 6.6,
        "成交量": 25335833.0,
        "成交额": 167524320.0
    },
    {
        "序号": 677,
        "symbol": "00334",
        "name": "华显光电",
        "最新价": 0.221,
        "涨跌额": 0.001,
        "涨跌幅": 0.45,
        "今开": 0.221,
        "最高": 0.221,
        "最低": 0.221,
        "昨收": 0.22,
        "成交量": 8000.0,
        "成交额": 1768.0
    },
    {
        "序号": 678,
        "symbol": "02588",
        "name": "中银航空租赁",
        "最新价": 55.5,
        "涨跌额": 0.25,
        "涨跌幅": 0.45,
        "今开": 54.5,
        "最高": 56.1,
        "最低": 54.5,
        "昨收": 55.25,
        "成交量": 232308.0,
        "成交额": 12878249.0
    },
    {
        "序号": 679,
        "symbol": "03021",
        "name": "富邦富时台湾",
        "最新价": 6.75,
        "涨跌额": 0.03,
        "涨跌幅": 0.45,
        "今开": 6.75,
        "最高": 6.75,
        "最低": 6.75,
        "昨收": 6.72,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 680,
        "symbol": "00743",
        "name": "亚洲水泥(中国)",
        "最新价": 2.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.45,
        "今开": 2.24,
        "最高": 2.27,
        "最低": 2.24,
        "昨收": 2.24,
        "成交量": 163500.0,
        "成交额": 369115.0
    },
    {
        "序号": 681,
        "symbol": "00151",
        "name": "中国旺旺",
        "最新价": 4.53,
        "涨跌额": 0.02,
        "涨跌幅": 0.44,
        "今开": 4.43,
        "最高": 4.6,
        "最低": 4.43,
        "昨收": 4.51,
        "成交量": 5159360.0,
        "成交额": 23371128.0
    },
    {
        "序号": 682,
        "symbol": "00087",
        "name": "太古股份公司B",
        "最新价": 9.11,
        "涨跌额": 0.04,
        "涨跌幅": 0.44,
        "今开": 9.05,
        "最高": 9.18,
        "最低": 9.04,
        "昨收": 9.07,
        "成交量": 627170.0,
        "成交额": 5721361.0
    },
    {
        "序号": 683,
        "symbol": "00780",
        "name": "同程旅行",
        "最新价": 13.7,
        "涨跌额": 0.06,
        "涨跌幅": 0.44,
        "今开": 13.78,
        "最高": 13.94,
        "最低": 13.66,
        "昨收": 13.64,
        "成交量": 8835053.0,
        "成交额": 121579170.0
    },
    {
        "序号": 684,
        "symbol": "08297",
        "name": "海纳星空科技",
        "最新价": 0.232,
        "涨跌额": 0.001,
        "涨跌幅": 0.43,
        "今开": 0.22,
        "最高": 0.235,
        "最低": 0.22,
        "昨收": 0.231,
        "成交量": 2682500.0,
        "成交额": 608127.0
    },
    {
        "序号": 685,
        "symbol": "09991",
        "name": "宝尊电商-W",
        "最新价": 7.0,
        "涨跌额": 0.03,
        "涨跌幅": 0.43,
        "今开": 7.14,
        "最高": 7.19,
        "最低": 7.0,
        "昨收": 6.97,
        "成交量": 51400.0,
        "成交额": 365862.0
    },
    {
        "序号": 686,
        "symbol": "00107",
        "name": "四川成渝高速公路",
        "最新价": 2.34,
        "涨跌额": 0.01,
        "涨跌幅": 0.43,
        "今开": 2.32,
        "最高": 2.34,
        "最低": 2.3,
        "昨收": 2.33,
        "成交量": 598000.0,
        "成交额": 1387280.0
    },
    {
        "序号": 687,
        "symbol": "00314",
        "name": "思派健康",
        "最新价": 7.08,
        "涨跌额": 0.03,
        "涨跌幅": 0.43,
        "今开": 7.14,
        "最高": 7.32,
        "最低": 7.02,
        "昨收": 7.05,
        "成交量": 310600.0,
        "成交额": 2214424.0
    },
    {
        "序号": 688,
        "symbol": "02005",
        "name": "石四药集团",
        "最新价": 4.76,
        "涨跌额": 0.02,
        "涨跌幅": 0.42,
        "今开": 4.72,
        "最高": 4.77,
        "最低": 4.64,
        "昨收": 4.74,
        "成交量": 3760356.0,
        "成交额": 17792071.0
    },
    {
        "序号": 689,
        "symbol": "01225",
        "name": "隆成金融",
        "最新价": 0.238,
        "涨跌额": 0.001,
        "涨跌幅": 0.42,
        "今开": 0.245,
        "最高": 0.248,
        "最低": 0.23,
        "昨收": 0.237,
        "成交量": 5438000.0,
        "成交额": 1316500.0
    },
    {
        "序号": 690,
        "symbol": "00267",
        "name": "中信股份",
        "最新价": 7.16,
        "涨跌额": 0.03,
        "涨跌幅": 0.42,
        "今开": 7.18,
        "最高": 7.25,
        "最低": 7.12,
        "昨收": 7.13,
        "成交量": 7638163.0,
        "成交额": 54858874.0
    },
    {
        "序号": 691,
        "symbol": "02499",
        "name": "佛朗斯股份",
        "最新价": 14.46,
        "涨跌额": 0.06,
        "涨跌幅": 0.42,
        "今开": 14.6,
        "最高": 14.6,
        "最低": 14.4,
        "昨收": 14.4,
        "成交量": 21400.0,
        "成交额": 309912.0
    },
    {
        "序号": 692,
        "symbol": "09839",
        "name": "华夏A50-U",
        "最新价": 2.488,
        "涨跌额": 0.01,
        "涨跌幅": 0.4,
        "今开": 2.49,
        "最高": 2.49,
        "最低": 2.488,
        "昨收": 2.478,
        "成交量": 3000.0,
        "成交额": 7470.0
    },
    {
        "序号": 693,
        "symbol": "03668",
        "name": "兖煤澳大利亚",
        "最新价": 25.0,
        "涨跌额": 0.1,
        "涨跌幅": 0.4,
        "今开": 24.95,
        "最高": 25.45,
        "最低": 24.85,
        "昨收": 24.9,
        "成交量": 2764900.0,
        "成交额": 69419572.0
    },
    {
        "序号": 694,
        "symbol": "00823",
        "name": "领展房产基金",
        "最新价": 37.9,
        "涨跌额": 0.15,
        "涨跌幅": 0.4,
        "今开": 38.0,
        "最高": 38.5,
        "最低": 37.8,
        "昨收": 37.75,
        "成交量": 4269983.0,
        "成交额": 162301658.0
    },
    {
        "序号": 695,
        "symbol": "09877",
        "name": "健世科技-B",
        "最新价": 15.78,
        "涨跌额": 0.06,
        "涨跌幅": 0.38,
        "今开": 16.44,
        "最高": 16.5,
        "最低": 15.54,
        "昨收": 15.72,
        "成交量": 808000.0,
        "成交额": 12754584.0
    },
    {
        "序号": 696,
        "symbol": "00438",
        "name": "彩虹新能源",
        "最新价": 2.63,
        "涨跌额": 0.01,
        "涨跌幅": 0.38,
        "今开": 2.63,
        "最高": 2.63,
        "最低": 2.63,
        "昨收": 2.62,
        "成交量": 1800.0,
        "成交额": 4734.0
    },
    {
        "序号": 697,
        "symbol": "02420",
        "name": "子不语",
        "最新价": 5.28,
        "涨跌额": 0.02,
        "涨跌幅": 0.38,
        "今开": 5.29,
        "最高": 5.3,
        "最低": 5.28,
        "昨收": 5.26,
        "成交量": 40000.0,
        "成交额": 211840.0
    },
    {
        "序号": 698,
        "symbol": "00089",
        "name": "大生地产",
        "最新价": 2.73,
        "涨跌额": 0.01,
        "涨跌幅": 0.37,
        "今开": 2.72,
        "最高": 2.73,
        "最低": 2.72,
        "昨收": 2.72,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 699,
        "symbol": "00187",
        "name": "京城机电股份",
        "最新价": 2.76,
        "涨跌额": 0.01,
        "涨跌幅": 0.36,
        "今开": 2.77,
        "最高": 2.86,
        "最低": 2.75,
        "昨收": 2.75,
        "成交量": 1956000.0,
        "成交额": 5476000.0
    },
    {
        "序号": 700,
        "symbol": "09888",
        "name": "百度集团-SW",
        "最新价": 111.8,
        "涨跌额": 0.4,
        "涨跌幅": 0.36,
        "今开": 111.8,
        "最高": 113.5,
        "最低": 111.0,
        "昨收": 111.4,
        "成交量": 12170730.0,
        "成交额": 1361121104.0
    },
    {
        "序号": 701,
        "symbol": "03173",
        "name": "PP中新经济",
        "最新价": 7.095,
        "涨跌额": 0.025,
        "涨跌幅": 0.35,
        "今开": 7.095,
        "最高": 7.095,
        "最低": 7.095,
        "昨收": 7.07,
        "成交量": 1500.0,
        "成交额": 10642.0
    },
    {
        "序号": 702,
        "symbol": "01686",
        "name": "新意网集团",
        "最新价": 2.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.35,
        "今开": 2.85,
        "最高": 2.92,
        "最低": 2.81,
        "昨收": 2.85,
        "成交量": 951000.0,
        "成交额": 2717360.0
    },
    {
        "序号": 703,
        "symbol": "00945",
        "name": "宏利金融-S",
        "最新价": 153.8,
        "涨跌额": 0.5,
        "涨跌幅": 0.33,
        "今开": 153.9,
        "最高": 154.3,
        "最低": 153.0,
        "昨收": 153.3,
        "成交量": 23317.0,
        "成交额": 3587611.0
    },
    {
        "序号": 704,
        "symbol": "01763",
        "name": "中国同辐",
        "最新价": 12.44,
        "涨跌额": 0.04,
        "涨跌幅": 0.32,
        "今开": 12.76,
        "最高": 12.76,
        "最低": 12.22,
        "昨收": 12.4,
        "成交量": 87000.0,
        "成交额": 1084096.0
    },
    {
        "序号": 705,
        "symbol": "03111",
        "name": "易方达A50",
        "最新价": 1.868,
        "涨跌额": 0.006,
        "涨跌幅": 0.32,
        "今开": 1.862,
        "最高": 1.872,
        "最低": 1.862,
        "昨收": 1.862,
        "成交量": 1057400.0,
        "成交额": 1973571.0
    },
    {
        "序号": 706,
        "symbol": "03072",
        "name": "日兴环球联网",
        "最新价": 110.4,
        "涨跌额": 0.35,
        "涨跌幅": 0.32,
        "今开": 110.4,
        "最高": 110.4,
        "最低": 110.4,
        "昨收": 110.05,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 707,
        "symbol": "06099",
        "name": "招商证券",
        "最新价": 6.31,
        "涨跌额": 0.02,
        "涨跌幅": 0.32,
        "今开": 6.29,
        "最高": 6.32,
        "最低": 6.24,
        "昨收": 6.29,
        "成交量": 1067680.0,
        "成交额": 6727132.0
    },
    {
        "序号": 708,
        "symbol": "06896",
        "name": "金嗓子",
        "最新价": 3.16,
        "涨跌额": 0.01,
        "涨跌幅": 0.32,
        "今开": 3.24,
        "最高": 3.25,
        "最低": 2.96,
        "昨收": 3.15,
        "成交量": 463000.0,
        "成交额": 1434630.0
    },
    {
        "序号": 709,
        "symbol": "03003",
        "name": "南方明晟A50",
        "最新价": 4.428,
        "涨跌额": 0.014,
        "涨跌幅": 0.32,
        "今开": 4.442,
        "最高": 4.442,
        "最低": 4.428,
        "昨收": 4.414,
        "成交量": 2200.0,
        "成交额": 9756.0
    },
    {
        "序号": 710,
        "symbol": "02210",
        "name": "京城佳业",
        "最新价": 3.22,
        "涨跌额": 0.01,
        "涨跌幅": 0.31,
        "今开": 3.33,
        "最高": 3.33,
        "最低": 3.22,
        "昨收": 3.21,
        "成交量": 48400.0,
        "成交额": 161048.0
    },
    {
        "序号": 711,
        "symbol": "00088",
        "name": "TAI CHEUNG HOLD",
        "最新价": 3.23,
        "涨跌额": 0.01,
        "涨跌幅": 0.31,
        "今开": 3.22,
        "最高": 3.23,
        "最低": 3.21,
        "昨收": 3.22,
        "成交量": 17000.0,
        "成交额": 54740.0
    },
    {
        "序号": 712,
        "symbol": "03194",
        "name": "南方云计算ETF",
        "最新价": 12.96,
        "涨跌额": 0.04,
        "涨跌幅": 0.31,
        "今开": 12.96,
        "最高": 12.96,
        "最低": 12.96,
        "昨收": 12.92,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 713,
        "symbol": "00799",
        "name": "IGG",
        "最新价": 3.25,
        "涨跌额": 0.01,
        "涨跌幅": 0.31,
        "今开": 3.24,
        "最高": 3.25,
        "最低": 3.19,
        "昨收": 3.24,
        "成交量": 1206520.0,
        "成交额": 3889359.0
    },
    {
        "序号": 714,
        "symbol": "83147",
        "name": "X南方中创业-R",
        "最新价": 6.575,
        "涨跌额": 0.02,
        "涨跌幅": 0.31,
        "今开": 6.575,
        "最高": 6.575,
        "最低": 6.575,
        "昨收": 6.555,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 715,
        "symbol": "09999",
        "name": "网易-S",
        "最新价": 165.0,
        "涨跌额": 0.5,
        "涨跌幅": 0.3,
        "今开": 163.0,
        "最高": 166.9,
        "最低": 163.0,
        "昨收": 164.5,
        "成交量": 8354210.0,
        "成交额": 1380038128.0
    },
    {
        "序号": 716,
        "symbol": "03189",
        "name": "易方达白酒",
        "最新价": 2.006,
        "涨跌额": 0.006,
        "涨跌幅": 0.3,
        "今开": 2.012,
        "最高": 2.014,
        "最低": 2.006,
        "昨收": 2.0,
        "成交量": 57200.0,
        "成交额": 115089.0
    },
    {
        "序号": 717,
        "symbol": "03172",
        "name": "A三星亚太元宇宙",
        "最新价": 13.74,
        "涨跌额": 0.04,
        "涨跌幅": 0.29,
        "今开": 13.74,
        "最高": 13.74,
        "最低": 13.74,
        "昨收": 13.7,
        "成交量": 10000.0,
        "成交额": 137400.0
    },
    {
        "序号": 718,
        "symbol": "09072",
        "name": "日兴环球联网-U",
        "最新价": 14.13,
        "涨跌额": 0.04,
        "涨跌幅": 0.28,
        "今开": 14.13,
        "最高": 14.13,
        "最低": 14.13,
        "昨收": 14.09,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 719,
        "symbol": "03012",
        "name": "东汇香港35",
        "最新价": 14.27,
        "涨跌额": 0.04,
        "涨跌幅": 0.28,
        "今开": 14.3,
        "最高": 14.3,
        "最低": 14.24,
        "昨收": 14.23,
        "成交量": 4500.0,
        "成交额": 64320.0
    },
    {
        "序号": 720,
        "symbol": "06821",
        "name": "凯莱英",
        "最新价": 89.55,
        "涨跌额": 0.25,
        "涨跌幅": 0.28,
        "今开": 89.35,
        "最高": 90.75,
        "最低": 87.65,
        "昨收": 89.3,
        "成交量": 224400.0,
        "成交额": 20025915.0
    },
    {
        "序号": 721,
        "symbol": "03036",
        "name": "TR台湾",
        "最新价": 399.1,
        "涨跌额": 1.1,
        "涨跌幅": 0.28,
        "今开": 399.1,
        "最高": 399.1,
        "最低": 399.1,
        "昨收": 398.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 722,
        "symbol": "01113",
        "name": "长实集团",
        "最新价": 37.45,
        "涨跌额": 0.1,
        "涨跌幅": 0.27,
        "今开": 37.75,
        "最高": 37.85,
        "最低": 37.3,
        "昨收": 37.35,
        "成交量": 2707436.0,
        "成交额": 101494995.0
    },
    {
        "序号": 723,
        "symbol": "00489",
        "name": "东风集团股份",
        "最新价": 3.86,
        "涨跌额": 0.01,
        "涨跌幅": 0.26,
        "今开": 3.88,
        "最高": 3.88,
        "最低": 3.82,
        "昨收": 3.85,
        "成交量": 8478000.0,
        "成交额": 32590910.0
    },
    {
        "序号": 724,
        "symbol": "02386",
        "name": "中石化炼化工程",
        "最新价": 3.89,
        "涨跌额": 0.01,
        "涨跌幅": 0.26,
        "今开": 3.9,
        "最高": 3.92,
        "最低": 3.84,
        "昨收": 3.88,
        "成交量": 2346000.0,
        "成交额": 9092578.0
    },
    {
        "序号": 725,
        "symbol": "00440",
        "name": "大新金融",
        "最新价": 15.66,
        "涨跌额": 0.04,
        "涨跌幅": 0.26,
        "今开": 15.7,
        "最高": 15.98,
        "最低": 15.66,
        "昨收": 15.62,
        "成交量": 80400.0,
        "成交额": 1263588.0
    },
    {
        "序号": 726,
        "symbol": "00083",
        "name": "信和置业",
        "最新价": 8.0,
        "涨跌额": 0.02,
        "涨跌幅": 0.25,
        "今开": 7.98,
        "最高": 8.2,
        "最低": 7.91,
        "昨收": 7.98,
        "成交量": 10528920.0,
        "成交额": 84326063.0
    },
    {
        "序号": 727,
        "symbol": "00005",
        "name": "汇丰控股",
        "最新价": 60.0,
        "涨跌额": 0.15,
        "涨跌幅": 0.25,
        "今开": 60.2,
        "最高": 60.3,
        "最低": 59.6,
        "昨收": 59.85,
        "成交量": 9904620.0,
        "成交额": 594052976.0
    },
    {
        "序号": 728,
        "symbol": "03020",
        "name": "XTR美国",
        "最新价": 1042.0,
        "涨跌额": 2.5,
        "涨跌幅": 0.24,
        "今开": 1042.0,
        "最高": 1042.0,
        "最低": 1042.0,
        "昨收": 1039.5,
        "成交量": 15.0,
        "成交额": 15630.0
    },
    {
        "序号": 729,
        "symbol": "83010",
        "name": "安硕亚洲除日-R",
        "最新价": 43.74,
        "涨跌额": 0.1,
        "涨跌幅": 0.23,
        "今开": 43.64,
        "最高": 43.92,
        "最低": 43.64,
        "昨收": 43.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 730,
        "symbol": "80992",
        "name": "联想集团-R",
        "最新价": 8.78,
        "涨跌额": 0.02,
        "涨跌幅": 0.23,
        "今开": 8.75,
        "最高": 8.84,
        "最低": 8.75,
        "昨收": 8.76,
        "成交量": 124000.0,
        "成交额": 1089860.0
    },
    {
        "序号": 731,
        "symbol": "01515",
        "name": "华润医疗",
        "最新价": 4.39,
        "涨跌额": 0.01,
        "涨跌幅": 0.23,
        "今开": 4.38,
        "最高": 4.46,
        "最低": 4.34,
        "昨收": 4.38,
        "成交量": 2129000.0,
        "成交额": 9381050.0
    },
    {
        "序号": 732,
        "symbol": "83001",
        "name": "PP中地美债-R",
        "最新价": 67.8,
        "涨跌额": 0.15,
        "涨跌幅": 0.22,
        "今开": 67.45,
        "最高": 67.8,
        "最低": 67.45,
        "昨收": 67.65,
        "成交量": 2500.0,
        "成交额": 168950.0
    },
    {
        "序号": 733,
        "symbol": "01833",
        "name": "平安好医生",
        "最新价": 18.12,
        "涨跌额": 0.04,
        "涨跌幅": 0.22,
        "今开": 18.2,
        "最高": 18.74,
        "最低": 18.0,
        "昨收": 18.08,
        "成交量": 8471840.0,
        "成交额": 154620335.0
    },
    {
        "序号": 734,
        "symbol": "01368",
        "name": "特步国际",
        "最新价": 4.55,
        "涨跌额": 0.01,
        "涨跌幅": 0.22,
        "今开": 4.56,
        "最高": 4.64,
        "最低": 4.5,
        "昨收": 4.54,
        "成交量": 8576284.0,
        "成交额": 39207921.0
    },
    {
        "序号": 735,
        "symbol": "00317",
        "name": "中船防务",
        "最新价": 9.21,
        "涨跌额": 0.02,
        "涨跌幅": 0.22,
        "今开": 9.23,
        "最高": 9.29,
        "最低": 9.19,
        "昨收": 9.19,
        "成交量": 254000.0,
        "成交额": 2341680.0
    },
    {
        "序号": 736,
        "symbol": "06185",
        "name": "康希诺生物",
        "最新价": 23.25,
        "涨跌额": 0.05,
        "涨跌幅": 0.22,
        "今开": 23.35,
        "最高": 23.6,
        "最低": 22.95,
        "昨收": 23.2,
        "成交量": 1231000.0,
        "成交额": 28625570.0
    },
    {
        "序号": 737,
        "symbol": "82388",
        "name": "中银香港-R",
        "最新价": 18.8,
        "涨跌额": 0.04,
        "涨跌幅": 0.21,
        "今开": 18.92,
        "最高": 18.92,
        "最低": 18.8,
        "昨收": 18.76,
        "成交量": 94500.0,
        "成交额": 1780470.0
    },
    {
        "序号": 738,
        "symbol": "03898",
        "name": "时代电气",
        "最新价": 23.8,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "今开": 24.05,
        "最高": 24.05,
        "最低": 23.5,
        "昨收": 23.75,
        "成交量": 1052196.0,
        "成交额": 25018407.0
    },
    {
        "序号": 739,
        "symbol": "06698",
        "name": "星空华文",
        "最新价": 23.9,
        "涨跌额": 0.05,
        "涨跌幅": 0.21,
        "今开": 23.85,
        "最高": 24.9,
        "最低": 22.9,
        "昨收": 23.85,
        "成交量": 367200.0,
        "成交额": 8938700.0
    },
    {
        "序号": 740,
        "symbol": "00992",
        "name": "联想集团",
        "最新价": 9.56,
        "涨跌额": 0.02,
        "涨跌幅": 0.21,
        "今开": 9.54,
        "最高": 9.76,
        "最低": 9.47,
        "昨收": 9.54,
        "成交量": 42162442.0,
        "成交额": 405758352.0
    },
    {
        "序号": 741,
        "symbol": "02839",
        "name": "华夏A50",
        "最新价": 19.4,
        "涨跌额": 0.04,
        "涨跌幅": 0.21,
        "今开": 19.45,
        "最高": 19.45,
        "最低": 19.4,
        "昨收": 19.36,
        "成交量": 21200.0,
        "成交额": 411980.0
    },
    {
        "序号": 742,
        "symbol": "09898",
        "name": "微博-SW",
        "最新价": 74.05,
        "涨跌额": 0.15,
        "涨跌幅": 0.2,
        "今开": 75.0,
        "最高": 75.2,
        "最低": 73.6,
        "昨收": 73.9,
        "成交量": 49160.0,
        "成交额": 3647370.0
    },
    {
        "序号": 743,
        "symbol": "83059",
        "name": "GX亚洲绿债-R",
        "最新价": 49.56,
        "涨跌额": 0.1,
        "涨跌幅": 0.2,
        "今开": 49.46,
        "最高": 49.56,
        "最低": 49.46,
        "昨收": 49.46,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 744,
        "symbol": "01821",
        "name": "ESR",
        "最新价": 9.95,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "今开": 10.0,
        "最高": 10.04,
        "最低": 9.83,
        "昨收": 9.93,
        "成交量": 6224432.0,
        "成交额": 61823538.0
    },
    {
        "序号": 745,
        "symbol": "02804",
        "name": "PP越南",
        "最新价": 60.06,
        "涨跌额": 0.12,
        "涨跌幅": 0.2,
        "今开": 60.06,
        "最高": 60.06,
        "最低": 60.06,
        "昨收": 59.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 746,
        "symbol": "06979",
        "name": "珍酒李渡",
        "最新价": 10.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.2,
        "今开": 10.04,
        "最高": 10.3,
        "最低": 10.0,
        "昨收": 10.1,
        "成交量": 2875096.0,
        "成交额": 29210604.0
    },
    {
        "序号": 747,
        "symbol": "00753",
        "name": "中国国航",
        "最新价": 5.15,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "今开": 5.22,
        "最高": 5.29,
        "最低": 5.13,
        "昨收": 5.14,
        "成交量": 7118000.0,
        "成交额": 36945020.0
    },
    {
        "序号": 748,
        "symbol": "00200",
        "name": "新濠国际发展",
        "最新价": 5.23,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "今开": 5.29,
        "最高": 5.3,
        "最低": 5.18,
        "昨收": 5.22,
        "成交量": 2345000.0,
        "成交额": 12222781.0
    },
    {
        "序号": 749,
        "symbol": "00900",
        "name": "AEON CREDIT",
        "最新价": 5.35,
        "涨跌额": 0.01,
        "涨跌幅": 0.19,
        "今开": 5.34,
        "最高": 5.48,
        "最低": 5.33,
        "昨收": 5.34,
        "成交量": 54000.0,
        "成交额": 289160.0
    },
    {
        "序号": 750,
        "symbol": "00017",
        "name": "新世界发展",
        "最新价": 11.02,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "今开": 11.04,
        "最高": 11.28,
        "最低": 10.96,
        "昨收": 11.0,
        "成交量": 11275850.0,
        "成交额": 124869181.0
    },
    {
        "序号": 751,
        "symbol": "82823",
        "name": "安硕A50-R",
        "最新价": 11.1,
        "涨跌额": 0.02,
        "涨跌幅": 0.18,
        "今开": 11.13,
        "最高": 11.13,
        "最低": 11.1,
        "昨收": 11.08,
        "成交量": 31000.0,
        "成交额": 345398.0
    },
    {
        "序号": 752,
        "symbol": "00331",
        "name": "丰盛生活服务",
        "最新价": 5.56,
        "涨跌额": 0.01,
        "涨跌幅": 0.18,
        "今开": 5.54,
        "最高": 5.59,
        "最低": 5.54,
        "昨收": 5.55,
        "成交量": 38000.0,
        "成交额": 210840.0
    },
    {
        "序号": 753,
        "symbol": "03038",
        "name": "恒生A股低碳",
        "最新价": 23.0,
        "涨跌额": 0.04,
        "涨跌幅": 0.17,
        "今开": 23.0,
        "最高": 23.0,
        "最低": 23.0,
        "昨收": 22.96,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 754,
        "symbol": "01475",
        "name": "日清食品",
        "最新价": 5.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "今开": 5.89,
        "最高": 5.91,
        "最低": 5.85,
        "昨收": 5.9,
        "成交量": 217000.0,
        "成交额": 1278900.0
    },
    {
        "序号": 755,
        "symbol": "03848",
        "name": "浩森金融科技",
        "最新价": 6.06,
        "涨跌额": 0.01,
        "涨跌幅": 0.17,
        "今开": 6.06,
        "最高": 6.06,
        "最低": 6.06,
        "昨收": 6.05,
        "成交量": 1000.0,
        "成交额": 6060.0
    },
    {
        "序号": 756,
        "symbol": "03050",
        "name": "GX中国全球领导",
        "最新价": 37.52,
        "涨跌额": 0.06,
        "涨跌幅": 0.16,
        "今开": 37.62,
        "最高": 37.76,
        "最低": 37.5,
        "昨收": 37.46,
        "成交量": 135561.0,
        "成交额": 5098599.0
    },
    {
        "序号": 757,
        "symbol": "03190",
        "name": "富邦沪深港高股息",
        "最新价": 9.615,
        "涨跌额": 0.015,
        "涨跌幅": 0.16,
        "今开": 9.64,
        "最高": 9.64,
        "最低": 9.61,
        "昨收": 9.6,
        "成交量": 50800.0,
        "成交额": 488904.0
    },
    {
        "序号": 758,
        "symbol": "02810",
        "name": "PP新兴东盟",
        "最新价": 65.34,
        "涨跌额": 0.1,
        "涨跌幅": 0.15,
        "今开": 65.5,
        "最高": 65.5,
        "最低": 65.34,
        "昨收": 65.24,
        "成交量": 150.0,
        "成交额": 9825.0
    },
    {
        "序号": 759,
        "symbol": "01519",
        "name": "极兔速递-W",
        "最新价": 13.12,
        "涨跌额": 0.02,
        "涨跌幅": 0.15,
        "今开": 13.04,
        "最高": 13.2,
        "最低": 12.82,
        "昨收": 13.1,
        "成交量": 3277200.0,
        "成交额": 42555414.0
    },
    {
        "序号": 760,
        "symbol": "09922",
        "name": "九毛九",
        "最新价": 6.71,
        "涨跌额": 0.01,
        "涨跌幅": 0.15,
        "今开": 6.63,
        "最高": 6.79,
        "最低": 6.58,
        "昨收": 6.7,
        "成交量": 12207000.0,
        "成交额": 81413500.0
    },
    {
        "序号": 761,
        "symbol": "07500",
        "name": "南方两倍看空恒指",
        "最新价": 6.78,
        "涨跌额": 0.01,
        "涨跌幅": 0.15,
        "今开": 6.75,
        "最高": 6.85,
        "最低": 6.665,
        "昨收": 6.77,
        "成交量": 26376000.0,
        "成交额": 178675508.0
    },
    {
        "序号": 762,
        "symbol": "04273",
        "name": "政府绿债二六一零",
        "最新价": 102.5,
        "涨跌额": 0.15,
        "涨跌幅": 0.15,
        "今开": 102.35,
        "最高": 102.55,
        "最低": 102.3,
        "昨收": 102.35,
        "成交量": 30100.0,
        "成交额": 3083435.0
    },
    {
        "序号": 763,
        "symbol": "01839",
        "name": "中集车辆",
        "最新价": 6.88,
        "涨跌额": 0.01,
        "涨跌幅": 0.15,
        "今开": 6.87,
        "最高": 6.92,
        "最低": 6.87,
        "昨收": 6.87,
        "成交量": 1450500.0,
        "成交额": 9989325.0
    },
    {
        "序号": 764,
        "symbol": "02877",
        "name": "神威药业",
        "最新价": 6.91,
        "涨跌额": 0.01,
        "涨跌幅": 0.14,
        "今开": 6.91,
        "最高": 7.12,
        "最低": 6.81,
        "昨收": 6.9,
        "成交量": 490000.0,
        "成交额": 3418730.0
    },
    {
        "序号": 765,
        "symbol": "02840",
        "name": "SPDR金ETF",
        "最新价": 1472.0,
        "涨跌额": 2.0,
        "涨跌幅": 0.14,
        "今开": 1470.0,
        "最高": 1472.5,
        "最低": 1468.0,
        "昨收": 1470.0,
        "成交量": 9425.0,
        "成交额": 13848682.0
    },
    {
        "序号": 766,
        "symbol": "09115",
        "name": "安硕恒生指数-U",
        "最新价": 7.755,
        "涨跌额": 0.01,
        "涨跌幅": 0.13,
        "今开": 7.785,
        "最高": 7.785,
        "最低": 7.755,
        "昨收": 7.745,
        "成交量": 284.0,
        "成交额": 2207.0
    },
    {
        "序号": 767,
        "symbol": "02883",
        "name": "中海油田服务",
        "最新价": 8.03,
        "涨跌额": 0.01,
        "涨跌幅": 0.12,
        "今开": 8.03,
        "最高": 8.19,
        "最低": 7.94,
        "昨收": 8.02,
        "成交量": 3666900.0,
        "成交额": 29432700.0
    },
    {
        "序号": 768,
        "symbol": "03134",
        "name": "南方太阳能",
        "最新价": 4.836,
        "涨跌额": 0.006,
        "涨跌幅": 0.12,
        "今开": 4.844,
        "最高": 4.844,
        "最低": 4.836,
        "昨收": 4.83,
        "成交量": 15000.0,
        "成交额": 72640.0
    },
    {
        "序号": 769,
        "symbol": "03139",
        "name": "AGX电车",
        "最新价": 49.1,
        "涨跌额": 0.06,
        "涨跌幅": 0.12,
        "今开": 49.1,
        "最高": 49.1,
        "最低": 49.1,
        "昨收": 49.04,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 770,
        "symbol": "03165",
        "name": "华夏欧优股对冲",
        "最新价": 16.41,
        "涨跌额": 0.02,
        "涨跌幅": 0.12,
        "今开": 16.4,
        "最高": 16.42,
        "最低": 16.4,
        "昨收": 16.39,
        "成交量": 20000.0,
        "成交额": 328320.0
    },
    {
        "序号": 771,
        "symbol": "83053",
        "name": "A南方港元-R",
        "最新价": 1001.65,
        "涨跌额": 1.15,
        "涨跌幅": 0.11,
        "今开": 1001.65,
        "最高": 1001.65,
        "最低": 1001.65,
        "昨收": 1000.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 772,
        "symbol": "09173",
        "name": "PP中新经济-U",
        "最新价": 0.908,
        "涨跌额": 0.001,
        "涨跌幅": 0.11,
        "今开": 0.907,
        "最高": 0.91,
        "最低": 0.907,
        "昨收": 0.907,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 773,
        "symbol": "00062",
        "name": "载通",
        "最新价": 9.14,
        "涨跌额": 0.01,
        "涨跌幅": 0.11,
        "今开": 9.13,
        "最高": 9.14,
        "最低": 9.0,
        "昨收": 9.13,
        "成交量": 6000.0,
        "成交额": 54744.0
    },
    {
        "序号": 774,
        "symbol": "01735",
        "name": "中环新能源",
        "最新价": 18.58,
        "涨跌额": 0.02,
        "涨跌幅": 0.11,
        "今开": 18.52,
        "最高": 18.84,
        "最低": 18.36,
        "昨收": 18.56,
        "成交量": 468000.0,
        "成交额": 8697680.0
    },
    {
        "序号": 775,
        "symbol": "02819",
        "name": "ABF港债指数",
        "最新价": 95.7,
        "涨跌额": 0.1,
        "涨跌幅": 0.1,
        "今开": 95.6,
        "最高": 95.7,
        "最低": 95.6,
        "昨收": 95.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 776,
        "symbol": "01776",
        "name": "广发证券",
        "最新价": 9.67,
        "涨跌额": 0.01,
        "涨跌幅": 0.1,
        "今开": 9.35,
        "最高": 9.74,
        "最低": 9.35,
        "昨收": 9.66,
        "成交量": 3897400.0,
        "成交额": 37635953.0
    },
    {
        "序号": 777,
        "symbol": "83199",
        "name": "工银南方国债-R",
        "最新价": 102.7,
        "涨跌额": 0.1,
        "涨跌幅": 0.1,
        "今开": 102.6,
        "最高": 102.7,
        "最低": 102.6,
        "昨收": 102.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 778,
        "symbol": "82822",
        "name": "南方A50-R",
        "最新价": 10.54,
        "涨跌额": 0.01,
        "涨跌幅": 0.09,
        "今开": 10.55,
        "最高": 10.59,
        "最低": 10.53,
        "昨收": 10.53,
        "成交量": 166600.0,
        "成交额": 1762860.0
    },
    {
        "序号": 779,
        "symbol": "02821",
        "name": "沛富基金",
        "最新价": 107.2,
        "涨跌额": 0.1,
        "涨跌幅": 0.09,
        "今开": 107.25,
        "最高": 107.25,
        "最低": 106.85,
        "昨收": 107.1,
        "成交量": 1120.0,
        "成交额": 119859.0
    },
    {
        "序号": 780,
        "symbol": "82817",
        "name": "PP国债-R",
        "最新价": 111.8,
        "涨跌额": 0.1,
        "涨跌幅": 0.09,
        "今开": 111.8,
        "最高": 111.8,
        "最低": 111.8,
        "昨收": 111.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 781,
        "symbol": "07300",
        "name": "南方一倍看空恒指",
        "最新价": 6.2,
        "涨跌额": 0.005,
        "涨跌幅": 0.08,
        "今开": 6.15,
        "最高": 6.215,
        "最低": 6.15,
        "昨收": 6.195,
        "成交量": 258300.0,
        "成交额": 1602726.0
    },
    {
        "序号": 782,
        "symbol": "09196",
        "name": "A博时美元-U",
        "最新价": 1021.2,
        "涨跌额": 0.8,
        "涨跌幅": 0.08,
        "今开": 1020.4,
        "最高": 1021.2,
        "最低": 1020.4,
        "昨收": 1020.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 783,
        "symbol": "82829",
        "name": "安硕中国国债-R",
        "最新价": 52.06,
        "涨跌额": 0.04,
        "涨跌幅": 0.08,
        "今开": 52.02,
        "最高": 52.1,
        "最低": 52.02,
        "昨收": 52.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 784,
        "symbol": "03091",
        "name": "A日兴元宇宙",
        "最新价": 81.98,
        "涨跌额": 0.06,
        "涨跌幅": 0.07,
        "今开": 81.92,
        "最高": 82.18,
        "最低": 81.92,
        "昨收": 81.92,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 785,
        "symbol": "07248",
        "name": "南方两倍做多富时中国A50",
        "最新价": 2.738,
        "涨跌额": 0.002,
        "涨跌幅": 0.07,
        "今开": 2.736,
        "最高": 2.774,
        "最低": 2.73,
        "昨收": 2.736,
        "成交量": 118400.0,
        "成交额": 325678.0
    },
    {
        "序号": 786,
        "symbol": "07374",
        "name": "南方一倍看空黄金",
        "最新价": 6.86,
        "涨跌额": 0.005,
        "涨跌幅": 0.07,
        "今开": 6.865,
        "最高": 6.865,
        "最低": 6.855,
        "昨收": 6.855,
        "成交量": 26000.0,
        "成交额": 178410.0
    },
    {
        "序号": 787,
        "symbol": "80016",
        "name": "新鸿基地产-R",
        "最新价": 70.9,
        "涨跌额": 0.05,
        "涨跌幅": 0.07,
        "今开": 70.9,
        "最高": 70.9,
        "最低": 70.9,
        "昨收": 70.85,
        "成交量": 500.0,
        "成交额": 35450.0
    },
    {
        "序号": 788,
        "symbol": "02313",
        "name": "申洲国际",
        "最新价": 78.25,
        "涨跌额": 0.05,
        "涨跌幅": 0.06,
        "今开": 79.15,
        "最高": 79.7,
        "最低": 78.05,
        "昨收": 78.2,
        "成交量": 2652719.0,
        "成交额": 208640618.0
    },
    {
        "序号": 789,
        "symbol": "03081",
        "name": "价值黄金",
        "最新价": 48.2,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "今开": 48.18,
        "最高": 48.26,
        "最低": 48.18,
        "昨收": 48.18,
        "成交量": 10700.0,
        "成交额": 515684.0
    },
    {
        "序号": 790,
        "symbol": "03071",
        "name": "A中金港元",
        "最新价": 1056.0,
        "涨跌额": 0.4,
        "涨跌幅": 0.04,
        "今开": 1055.5,
        "最高": 1056.0,
        "最低": 1055.5,
        "昨收": 1055.6,
        "成交量": 45.0,
        "成交额": 47497.0
    },
    {
        "序号": 791,
        "symbol": "03075",
        "name": "GX亚洲美债",
        "最新价": 56.66,
        "涨跌额": 0.02,
        "涨跌幅": 0.04,
        "今开": 56.66,
        "最高": 56.66,
        "最低": 56.66,
        "昨收": 56.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 792,
        "symbol": "83122",
        "name": "A南方人民币-R",
        "最新价": 162.75,
        "涨跌额": 0.05,
        "涨跌幅": 0.03,
        "今开": 162.75,
        "最高": 162.75,
        "最低": 162.75,
        "昨收": 162.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 793,
        "symbol": "03116",
        "name": "GX亚太高股息率",
        "最新价": 66.92,
        "涨跌额": 0.02,
        "涨跌幅": 0.03,
        "今开": 66.9,
        "最高": 67.08,
        "最低": 66.9,
        "昨收": 66.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 794,
        "symbol": "03152",
        "name": "A博时港元",
        "最新价": 1033.05,
        "涨跌额": 0.2,
        "涨跌幅": 0.02,
        "今开": 1033.4,
        "最高": 1033.4,
        "最低": 1033.0,
        "昨收": 1032.85,
        "成交量": 435.0,
        "成交额": 449405.0
    },
    {
        "序号": 795,
        "symbol": "03011",
        "name": "A工银中金美",
        "最新价": 8598.15,
        "涨跌额": 1.65,
        "涨跌幅": 0.02,
        "今开": 8598.15,
        "最高": 8598.15,
        "最低": 8598.15,
        "昨收": 8596.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 796,
        "symbol": "03077",
        "name": "PP美国库",
        "最新价": 3955.15,
        "涨跌额": 0.65,
        "涨跌幅": 0.02,
        "今开": 3954.5,
        "最高": 3955.15,
        "最低": 3954.5,
        "昨收": 3954.5,
        "成交量": 100.0,
        "成交额": 395495.0
    },
    {
        "序号": 797,
        "symbol": "03053",
        "name": "A南方港元",
        "最新价": 1093.05,
        "涨跌额": 0.1,
        "涨跌幅": 0.01,
        "今开": 1092.8,
        "最高": 1093.05,
        "最低": 1092.8,
        "昨收": 1092.95,
        "成交量": 531.0,
        "成交额": 580358.0
    },
    {
        "序号": 798,
        "symbol": "09011",
        "name": "A工银中金美-U",
        "最新价": 1100.7,
        "涨跌额": 0.1,
        "涨跌幅": 0.01,
        "今开": 1100.7,
        "最高": 1100.7,
        "最低": 1100.7,
        "昨收": 1100.6,
        "成交量": 4.0,
        "成交额": 4402.0
    },
    {
        "序号": 799,
        "symbol": "89003",
        "name": "国债二八零八-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 800,
        "symbol": "89002",
        "name": "国债三三零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 801,
        "symbol": "89001",
        "name": "国债二六零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 802,
        "symbol": "89000",
        "name": "国债二五零六BR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 803,
        "symbol": "87001",
        "name": "汇贤产业信托",
        "最新价": 0.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.86,
        "最高": 0.86,
        "最低": 0.85,
        "昨收": 0.85,
        "成交量": 985124.0,
        "成交额": 845555.0
    },
    {
        "序号": 804,
        "symbol": "86664",
        "name": "国债三二零八-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 805,
        "symbol": "86663",
        "name": "国债二七零六BR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 806,
        "symbol": "86662",
        "name": "国债二五零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 807,
        "symbol": "86661",
        "name": "国债二四零六BR",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 808,
        "symbol": "86660",
        "name": "国债二六零九-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 809,
        "symbol": "86658",
        "name": "国债二五零七-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 810,
        "symbol": "86656",
        "name": "国债二四零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 811,
        "symbol": "86652",
        "name": "国债二七十二-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 812,
        "symbol": "86646",
        "name": "国债四六十二-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 813,
        "symbol": "86645",
        "name": "国债三一十二-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 814,
        "symbol": "86644",
        "name": "国债二六十二-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 815,
        "symbol": "86641",
        "name": "国债三六零七-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 816,
        "symbol": "86640",
        "name": "国债二六零七-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 817,
        "symbol": "86635",
        "name": "国债三五十一-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 818,
        "symbol": "86634",
        "name": "国债二五十一-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 819,
        "symbol": "86631",
        "name": "国债四五零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 820,
        "symbol": "86630",
        "name": "国债三零零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 821,
        "symbol": "86629",
        "name": "国债二五零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 822,
        "symbol": "86624",
        "name": "国债二四十一-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 823,
        "symbol": "86621",
        "name": "国债三四零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 824,
        "symbol": "86620",
        "name": "国债二九零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 825,
        "symbol": "86619",
        "name": "国债二四零五-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 826,
        "symbol": "86612",
        "name": "国债四三零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 827,
        "symbol": "86611",
        "name": "国债二八零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 828,
        "symbol": "86605",
        "name": "国债二七零六-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 829,
        "symbol": "86028",
        "name": "BOCOM N2403-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 830,
        "symbol": "86026",
        "name": "SAIC-GM N2503-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 831,
        "symbol": "86025",
        "name": "AGRBK N2403-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 832,
        "symbol": "86023",
        "name": "BOCOM N2412-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 833,
        "symbol": "86022",
        "name": "HKGB GR 2611-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 834,
        "symbol": "86021",
        "name": "HKGB GR 2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 835,
        "symbol": "86019",
        "name": "SZ GOVT B2610-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 836,
        "symbol": "86018",
        "name": "SZ GOVT B2410-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 837,
        "symbol": "86009",
        "name": "GLP HLD N2407-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 838,
        "symbol": "86007",
        "name": "CDB N2406-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 839,
        "symbol": "86004",
        "name": "VKEREHK N2405-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 840,
        "symbol": "86002",
        "name": "BOCL N2604B-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 841,
        "symbol": "85993",
        "name": "CHA STY B2409-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 842,
        "symbol": "85989",
        "name": "CDB N2408-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 843,
        "symbol": "85968",
        "name": "MTRC N2403B-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 844,
        "symbol": "85964",
        "name": "EXIMCHN B2405-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 845,
        "symbol": "85962",
        "name": "MTRC N2403-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 846,
        "symbol": "85955",
        "name": "ICBC(S) N2402-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 847,
        "symbol": "85953",
        "name": "FE HORI N2402-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 848,
        "symbol": "85952",
        "name": "HKMC N2402-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 849,
        "symbol": "85934",
        "name": "CDB N2811-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 850,
        "symbol": "85924",
        "name": "ADBC B2508-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 851,
        "symbol": "85909",
        "name": "ADBC B2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 852,
        "symbol": "85905",
        "name": "CDB B3208-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 853,
        "symbol": "85904",
        "name": "CDB B2701-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 854,
        "symbol": "85723",
        "name": "CHARMLI N2512-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 855,
        "symbol": "85701",
        "name": "CCBASIA N2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 856,
        "symbol": "84465",
        "name": "CQWS IP B2612-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 857,
        "symbol": "84464",
        "name": "CQ XSQ B2611-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 858,
        "symbol": "84463",
        "name": "DJY UR B2611-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 859,
        "symbol": "84462",
        "name": "ITG HLG B2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 860,
        "symbol": "84461",
        "name": "ICBC N2510-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 861,
        "symbol": "84460",
        "name": "JX CID B2610-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 862,
        "symbol": "84459",
        "name": "BOCL N2510-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 863,
        "symbol": "84458",
        "name": "JY SAHG B2610-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 864,
        "symbol": "84457",
        "name": "MTRC N2810-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 865,
        "symbol": "84456",
        "name": "CHENGDA B2610-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 866,
        "symbol": "84455",
        "name": "SX XXFX B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 867,
        "symbol": "84454",
        "name": "GSHA TO B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 868,
        "symbol": "84453",
        "name": "JZBD B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 869,
        "symbol": "84452",
        "name": "HN GOVT B2809-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 870,
        "symbol": "84451",
        "name": "HN GOVT B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 871,
        "symbol": "84450",
        "name": "HN GOVT B2509-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 872,
        "symbol": "84449",
        "name": "BOCL N2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 873,
        "symbol": "84448",
        "name": "ZIBO CJ B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 874,
        "symbol": "84447",
        "name": "CMSI GE N2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 875,
        "symbol": "84446",
        "name": "CEBHK N2509-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 876,
        "symbol": "84445",
        "name": "HKMC N2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 877,
        "symbol": "84444",
        "name": "UGAOXIN B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 878,
        "symbol": "84443",
        "name": "SZ GOVT B2809-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 879,
        "symbol": "84442",
        "name": "SZ GOVT B2609-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 880,
        "symbol": "84441",
        "name": "SZ GOVT B2509-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 881,
        "symbol": "84440",
        "name": "PZHCTG B2608-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 882,
        "symbol": "84439",
        "name": "SD COM B2608-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 883,
        "symbol": "84438",
        "name": "MC DZXI B2608-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 884,
        "symbol": "84437",
        "name": "SP MTNF N2807-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 885,
        "symbol": "84436",
        "name": "SP MTNF N2507-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 886,
        "symbol": "84435",
        "name": "CSI MTN N2607-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 887,
        "symbol": "84434",
        "name": "HKGB GR 3306-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 888,
        "symbol": "84433",
        "name": "HKGB GR 2806-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 889,
        "symbol": "84432",
        "name": "HKGB GR 2506-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 890,
        "symbol": "84431",
        "name": "CCB N2505-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 891,
        "symbol": "84430",
        "name": "BOCL N2505-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 892,
        "symbol": "84429",
        "name": "HT FN N2605-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 893,
        "symbol": "84428",
        "name": "CSCIF A N2604-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 894,
        "symbol": "84427",
        "name": "CSCIF A N2504-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 895,
        "symbol": "84426",
        "name": "BOCL N2503-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 896,
        "symbol": "84425",
        "name": "HKMC N2503-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 897,
        "symbol": "84424",
        "name": "BOCOM N2502-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 898,
        "symbol": "84423",
        "name": "HKMC N2502-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 899,
        "symbol": "84422",
        "name": "AGRBK N2501-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 900,
        "symbol": "84421",
        "name": "MTRC N2501-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 901,
        "symbol": "84420",
        "name": "ICBC N2501-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 902,
        "symbol": "84419",
        "name": "CMBC N2501-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 903,
        "symbol": "84418",
        "name": "HKGB GR 2801-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 904,
        "symbol": "84417",
        "name": "HKGB GR 2501-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 905,
        "symbol": "84416",
        "name": "BOCOM N2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 906,
        "symbol": "84415",
        "name": "SZ GOVT B2711-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 907,
        "symbol": "84414",
        "name": "SZ GOVT B2511-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 908,
        "symbol": "84413",
        "name": "SZ GOVT B2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 909,
        "symbol": "84412",
        "name": "HN GOVT B2511-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 910,
        "symbol": "84411",
        "name": "HN GOVT B2411-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 911,
        "symbol": "84410",
        "name": "HN GOVT B2711-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 912,
        "symbol": "84409",
        "name": "HKMC N2510-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 913,
        "symbol": "84408",
        "name": "MTRC N2510-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 914,
        "symbol": "84407",
        "name": "PIONREW B2509-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 915,
        "symbol": "84406",
        "name": "MTRC N2409-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 916,
        "symbol": "84405",
        "name": "BOCL N2508-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 917,
        "symbol": "84404",
        "name": "CDB N2707-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 918,
        "symbol": "84403",
        "name": "HKMC N2406-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 919,
        "symbol": "84402",
        "name": "VKEREHK N2506-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 920,
        "symbol": "84401",
        "name": "ICBC N2406-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 921,
        "symbol": "84400",
        "name": "CCB N2405-R",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 922,
        "symbol": "83196",
        "name": "A博时美元-R",
        "最新价": 7286.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7286.55,
        "最高": 7295.8,
        "最低": 7286.55,
        "昨收": 7286.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 923,
        "symbol": "83192",
        "name": "A博时人民币-R",
        "最新价": 1023.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1023.85,
        "最高": 1024.6,
        "最低": 1023.85,
        "昨收": 1023.85,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 924,
        "symbol": "83189",
        "name": "易方达白酒-R",
        "最新价": 1.836,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.836,
        "最高": 1.85,
        "最低": 1.828,
        "昨收": 1.836,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 925,
        "symbol": "83168",
        "name": "恒生人币金ETF",
        "最新价": 31.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 31.6,
        "最高": 31.6,
        "最低": 31.6,
        "昨收": 31.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 926,
        "symbol": "83167",
        "name": "工银南方中国-R",
        "最新价": 46.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 46.84,
        "最高": 46.88,
        "最低": 46.48,
        "昨收": 46.84,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 927,
        "symbol": "83161",
        "name": "A华夏人民币-R",
        "最新价": 101.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 101.2,
        "最高": 101.2,
        "最低": 101.2,
        "昨收": 101.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 928,
        "symbol": "83129",
        "name": "中银大湾气候-R",
        "最新价": 7.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.27,
        "最高": 7.28,
        "最低": 7.24,
        "昨收": 7.27,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 929,
        "symbol": "83128",
        "name": "恒生A股龙头-R",
        "最新价": 42.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 42.16,
        "最高": 42.32,
        "最低": 42.16,
        "昨收": 42.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 930,
        "symbol": "83125",
        "name": "安硕短期政银-R",
        "最新价": 50.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 50.7,
        "最高": 50.72,
        "最低": 50.7,
        "昨收": 50.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 931,
        "symbol": "83115",
        "name": "安硕恒生指数-R",
        "最新价": 55.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 55.6,
        "最高": 55.9,
        "最低": 55.26,
        "昨收": 55.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 932,
        "symbol": "83108",
        "name": "嘉实ESG领-R",
        "最新价": 6.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.6,
        "最高": 6.6,
        "最低": 6.59,
        "昨收": 6.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 933,
        "symbol": "83081",
        "name": "价值黄金-R",
        "最新价": 44.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 44.2,
        "最高": 44.22,
        "最低": 44.2,
        "昨收": 44.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 934,
        "symbol": "83079",
        "name": "中金中国国债-R",
        "最新价": 118.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 118.5,
        "最高": 118.5,
        "最低": 118.5,
        "昨收": 118.5,
        "成交量": 220.0,
        "成交额": 26070.0
    },
    {
        "序号": 935,
        "symbol": "83038",
        "name": "恒生A股低碳-R",
        "最新价": 21.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 21.2,
        "最高": 21.2,
        "最低": 21.14,
        "昨收": 21.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 936,
        "symbol": "83031",
        "name": "海通AESG-R",
        "最新价": 7.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.29,
        "最高": 7.305,
        "最低": 7.285,
        "昨收": 7.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 937,
        "symbol": "83012",
        "name": "东汇香港35-R",
        "最新价": 13.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 13.05,
        "最高": 13.07,
        "最低": 13.05,
        "昨收": 13.05,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 938,
        "symbol": "83005",
        "name": "X南方中五百-R",
        "最新价": 14.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 14.67,
        "最高": 14.68,
        "最低": 14.67,
        "昨收": 14.67,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 939,
        "symbol": "82843",
        "name": "东汇A50-R",
        "最新价": 11.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 11.14,
        "最高": 11.21,
        "最低": 11.14,
        "昨收": 11.14,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 940,
        "symbol": "82813",
        "name": "华夏政银国债-R",
        "最新价": 108.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 108.55,
        "最高": 108.55,
        "最低": 108.55,
        "昨收": 108.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 941,
        "symbol": "82811",
        "name": "海通沪深三百-R",
        "最新价": 12.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 12.07,
        "最高": 12.11,
        "最低": 12.07,
        "昨收": 12.07,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 942,
        "symbol": "82800",
        "name": "盈富基金-R",
        "最新价": 15.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 15.12,
        "最高": 15.18,
        "最低": 15.01,
        "昨收": 15.1,
        "成交量": 108500.0,
        "成交额": 1636680.0
    },
    {
        "序号": 943,
        "symbol": "80737",
        "name": "湾区发展-R",
        "最新价": 1.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.22,
        "最高": 1.22,
        "最低": 1.22,
        "昨收": 1.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 944,
        "symbol": "80020",
        "name": "商汤-WR",
        "最新价": 1.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.18,
        "最高": 1.22,
        "最低": 1.16,
        "昨收": 1.18,
        "成交量": 2174000.0,
        "成交额": 2599190.0
    },
    {
        "序号": 945,
        "symbol": "40979",
        "name": "HZ NCI B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 946,
        "symbol": "40978",
        "name": "YANGSHAN B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 947,
        "symbol": "40977",
        "name": "XIAN AC B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 948,
        "symbol": "40976",
        "name": "HZ BAY B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 949,
        "symbol": "40975",
        "name": "ZCHK TECH B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 950,
        "symbol": "40974",
        "name": "FUYUAN W B2411B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 951,
        "symbol": "40972",
        "name": "CALC BD N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 952,
        "symbol": "40971",
        "name": "CCB N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 953,
        "symbol": "40970",
        "name": "WXGY CDG B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 954,
        "symbol": "40969",
        "name": "SAN SHUI N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 955,
        "symbol": "40968",
        "name": "JJ PORT B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 956,
        "symbol": "40967",
        "name": "HUANGSHI B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 957,
        "symbol": "40966",
        "name": "JMYT DEV B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 958,
        "symbol": "40965",
        "name": "PINGDU SO B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 959,
        "symbol": "40964",
        "name": "YUYAO EDZ B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 960,
        "symbol": "40963",
        "name": "BOCOM N2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 961,
        "symbol": "40962",
        "name": "CEBI SD B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 962,
        "symbol": "40961",
        "name": "XIHUI I B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 963,
        "symbol": "40959",
        "name": "BAOJI INV N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 964,
        "symbol": "40958",
        "name": "JF INTL B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 965,
        "symbol": "40957",
        "name": "YI HONG B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 966,
        "symbol": "40956",
        "name": "CDJK B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 967,
        "symbol": "40955",
        "name": "QD DAIC B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 968,
        "symbol": "40953",
        "name": "CEBHK N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 969,
        "symbol": "40952",
        "name": "HAFOO N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 970,
        "symbol": "40951",
        "name": "YANKUANG B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 971,
        "symbol": "40950",
        "name": "HY INTL B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 972,
        "symbol": "40949",
        "name": "CIMCENRIC B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 973,
        "symbol": "40948",
        "name": "CDCCIC B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 974,
        "symbol": "40947",
        "name": "FUYUAN W B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 975,
        "symbol": "40946",
        "name": "MENGNIU B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 976,
        "symbol": "40945",
        "name": "LUHENG B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 977,
        "symbol": "40943",
        "name": "HAIXI OSI B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 978,
        "symbol": "40942",
        "name": "BOEN INV B2411B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 979,
        "symbol": "40941",
        "name": "BOEN INV B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 980,
        "symbol": "40940",
        "name": "SINOCHEM N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 981,
        "symbol": "40939",
        "name": "SINOCHEM N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 982,
        "symbol": "40938",
        "name": "SINOCHEM N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 983,
        "symbol": "40937",
        "name": "GTJA HOLD N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 984,
        "symbol": "40936",
        "name": "TFTZI N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 985,
        "symbol": "40935",
        "name": "YUEYANG B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 986,
        "symbol": "40934",
        "name": "TAIZHOU B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 987,
        "symbol": "40933",
        "name": "JINAN HT B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 988,
        "symbol": "40932",
        "name": "SY UCHK B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 989,
        "symbol": "40931",
        "name": "RONGSHI N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 990,
        "symbol": "40930",
        "name": "CGWIH V B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 991,
        "symbol": "40928",
        "name": "YANCOAL B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 992,
        "symbol": "40927",
        "name": "YC HT INV N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 993,
        "symbol": "40926",
        "name": "EVE BT B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 994,
        "symbol": "40924",
        "name": "TS FIN B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 995,
        "symbol": "40922",
        "name": "SF HLDG N3111",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 996,
        "symbol": "40921",
        "name": "SF HLDG N2811",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 997,
        "symbol": "40920",
        "name": "SF HLDG N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 998,
        "symbol": "40919",
        "name": "AVIC IFI B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 999,
        "symbol": "40918",
        "name": "WUHAN FIN N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1000,
        "symbol": "40917",
        "name": "MINGHU B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1001,
        "symbol": "40915",
        "name": "CNCBINV B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1002,
        "symbol": "40914",
        "name": "ZZ METRO B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1003,
        "symbol": "40913",
        "name": "PJ SOCIG B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1004,
        "symbol": "40912",
        "name": "ZJ XC INV B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1005,
        "symbol": "40911",
        "name": "HAICHUAN B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1006,
        "symbol": "40910",
        "name": "AMIPEACE N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1007,
        "symbol": "40909",
        "name": "CBHB N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1008,
        "symbol": "40908",
        "name": "YCIC IHK N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1009,
        "symbol": "40907",
        "name": "ZHOUSHAN B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1010,
        "symbol": "40906",
        "name": "SHAANXI B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1011,
        "symbol": "40905",
        "name": "ICBCIL FN N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1012,
        "symbol": "40904",
        "name": "ICBCILFN N2411B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1013,
        "symbol": "40903",
        "name": "DAHSING B N3111",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1014,
        "symbol": "40902",
        "name": "WH METRO N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1015,
        "symbol": "40901",
        "name": "SUZHOU IN B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1016,
        "symbol": "40900",
        "name": "HCG N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1017,
        "symbol": "40898",
        "name": "ICBC N2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1018,
        "symbol": "40897",
        "name": "ICBC N2410B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1019,
        "symbol": "40896",
        "name": "ICBC N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1020,
        "symbol": "40895",
        "name": "ICBC N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1021,
        "symbol": "40893",
        "name": "CQ SNCI N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1022,
        "symbol": "40892",
        "name": "HBSD INV B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1023,
        "symbol": "40891",
        "name": "GOUCHENG B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1024,
        "symbol": "40890",
        "name": "HNXJ NAD B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1025,
        "symbol": "40889",
        "name": "HUALU INT B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1026,
        "symbol": "40888",
        "name": "ZQGL INV B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1027,
        "symbol": "40887",
        "name": "FE HORI N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1028,
        "symbol": "40886",
        "name": "CDHT INV N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1029,
        "symbol": "40885",
        "name": "RUDONG JX B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1030,
        "symbol": "40884",
        "name": "EASTCRT N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1031,
        "symbol": "40883",
        "name": "DONGFENG N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1032,
        "symbol": "40882",
        "name": "AIA GRP SDS5110",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1033,
        "symbol": "40881",
        "name": "ZHUJI DEV B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1034,
        "symbol": "40879",
        "name": "GZ URBAN N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1035,
        "symbol": "40878",
        "name": "20211WST N5304",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1036,
        "symbol": "40876",
        "name": "ZJ HZ NX B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1037,
        "symbol": "40875",
        "name": "XINGCHENG N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1038,
        "symbol": "40874",
        "name": "SOAR WISE N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1039,
        "symbol": "40873",
        "name": "HB CONST B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1040,
        "symbol": "40872",
        "name": "PANTHER GSPCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1041,
        "symbol": "40871",
        "name": "GEPIC B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1042,
        "symbol": "40870",
        "name": "SE GLOBAL N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1043,
        "symbol": "40869",
        "name": "BOCL N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1044,
        "symbol": "40868",
        "name": "XINHU BVI N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1045,
        "symbol": "40867",
        "name": "ZB INTL N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1046,
        "symbol": "40866",
        "name": "JINGRUI N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1047,
        "symbol": "40865",
        "name": "ACDG B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1048,
        "symbol": "40864",
        "name": "ICBC UCB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1049,
        "symbol": "40863",
        "name": "WEIFANG B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1050,
        "symbol": "40862",
        "name": "PINGHU B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1051,
        "symbol": "40861",
        "name": "SIHAI INT B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1052,
        "symbol": "40860",
        "name": "BJ EN INT B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1053,
        "symbol": "40859",
        "name": "ZENSUN EN N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1054,
        "symbol": "40857",
        "name": "SINOCHEM N3109",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1055,
        "symbol": "40856",
        "name": "SINOCHEM N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1056,
        "symbol": "40855",
        "name": "SINOCHEM N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1057,
        "symbol": "40854",
        "name": "SANDS N3108",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1058,
        "symbol": "40853",
        "name": "SANDS N2903",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1059,
        "symbol": "40852",
        "name": "SANDS N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1060,
        "symbol": "40851",
        "name": "EHI CAR N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1061,
        "symbol": "40850",
        "name": "PF PT V N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1062,
        "symbol": "40849",
        "name": "GZ METRO N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1063,
        "symbol": "40847",
        "name": "RADIANCE N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1064,
        "symbol": "40846",
        "name": "TZ OCMC B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1065,
        "symbol": "40844",
        "name": "ANJI QCLF B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1066,
        "symbol": "40843",
        "name": "CMSI GEM N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1067,
        "symbol": "40842",
        "name": "WXC INV B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1068,
        "symbol": "40841",
        "name": "CMB ILM N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1069,
        "symbol": "40840",
        "name": "CMB ILM N2409B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1070,
        "symbol": "40839",
        "name": "CMB ILM N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1071,
        "symbol": "40838",
        "name": "CCBL1CORP N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1072,
        "symbol": "40837",
        "name": "CEBLUX N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1073,
        "symbol": "40835",
        "name": "AIA GRP SDS3309",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1074,
        "symbol": "40834",
        "name": "CDB N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1075,
        "symbol": "40833",
        "name": "CON RD B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1076,
        "symbol": "40832",
        "name": "QUANCHENG B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1077,
        "symbol": "40831",
        "name": "SHK&CO N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1078,
        "symbol": "40830",
        "name": "ZJ DEQING B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1079,
        "symbol": "40829",
        "name": "STATEGRI N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1080,
        "symbol": "40827",
        "name": "CHANG DEV B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1081,
        "symbol": "40825",
        "name": "YC HI TEC B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1082,
        "symbol": "40823",
        "name": "INVENTIVE N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1083,
        "symbol": "40822",
        "name": "INVENTIVE N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1084,
        "symbol": "40821",
        "name": "TZ URBAN B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1085,
        "symbol": "40820",
        "name": "HF21 I B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1086,
        "symbol": "40819",
        "name": "LZH RAIL B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1087,
        "symbol": "40818",
        "name": "PINGAN LE B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1088,
        "symbol": "40817",
        "name": "SDG FIN B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1089,
        "symbol": "40816",
        "name": "SDG FIN B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1090,
        "symbol": "40815",
        "name": "ANHUI THG B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1091,
        "symbol": "40814",
        "name": "BAIDU N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1092,
        "symbol": "40813",
        "name": "BAIDU N3108",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1093,
        "symbol": "40812",
        "name": "C INT DEV B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1094,
        "symbol": "40811",
        "name": "XINGSHENG B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1095,
        "symbol": "40810",
        "name": "PCGI III N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1096,
        "symbol": "40809",
        "name": "YLDKING N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1097,
        "symbol": "40807",
        "name": "SX C INV B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1098,
        "symbol": "40806",
        "name": "HF21 I SGPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 40000.0,
        "成交额": 3920000.0
    },
    {
        "序号": 1099,
        "symbol": "40804",
        "name": "ZJ DEQING B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1100,
        "symbol": "40803",
        "name": "GEMDALE N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1101,
        "symbol": "40802",
        "name": "ZJ BARON B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1102,
        "symbol": "40801",
        "name": "YINCHENG B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1103,
        "symbol": "40800",
        "name": "BOCL N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1104,
        "symbol": "40799",
        "name": "YCIC IHK N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1105,
        "symbol": "40798",
        "name": "XIAN AERO B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1106,
        "symbol": "40794",
        "name": "CQWS DEV B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1107,
        "symbol": "40793",
        "name": "ICBCIL FN N3108",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1108,
        "symbol": "40792",
        "name": "ICBCIL FN N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1109,
        "symbol": "40791",
        "name": "ICBCIL FN N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1110,
        "symbol": "40790",
        "name": "CNCB INTL UCS C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1111,
        "symbol": "40789",
        "name": "CKI FIN GPCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1112,
        "symbol": "40788",
        "name": "NANCHANG B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1113,
        "symbol": "40787",
        "name": "PINGAN RE N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1114,
        "symbol": "40786",
        "name": "PINGAN RE N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1115,
        "symbol": "40785",
        "name": "XIAN AERO B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1116,
        "symbol": "40784",
        "name": "CHALCOHKI B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1117,
        "symbol": "40783",
        "name": "CHALCOHKI B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1118,
        "symbol": "40782",
        "name": "LAI SUN N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1119,
        "symbol": "40781",
        "name": "SDIS XINH B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1120,
        "symbol": "40780",
        "name": "VERTEX B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1121,
        "symbol": "40779",
        "name": "JIAYUAN B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1122,
        "symbol": "40778",
        "name": "CSSC B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1123,
        "symbol": "40776",
        "name": "ANTONOIL N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1124,
        "symbol": "40775",
        "name": "HZMGS HT N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1125,
        "symbol": "40774",
        "name": "HORSE GAL N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1126,
        "symbol": "40772",
        "name": "CLP-PF N3107B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1127,
        "symbol": "40771",
        "name": "CLP-PF N3107",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1128,
        "symbol": "40770",
        "name": "CCBL1CORP N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1129,
        "symbol": "40769",
        "name": "JS DSQ B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1130,
        "symbol": "40768",
        "name": "EXP LEAD B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1131,
        "symbol": "40767",
        "name": "ZJ EN INT N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1132,
        "symbol": "40766",
        "name": "SWHY INTL B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1133,
        "symbol": "40765",
        "name": "CHMOD DA B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1134,
        "symbol": "40764",
        "name": "XIAOMI BT B5107",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1135,
        "symbol": "40763",
        "name": "XIAOMI BT B3107",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1136,
        "symbol": "40762",
        "name": "BRT GAL B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1137,
        "symbol": "40761",
        "name": "ZHEJIANG B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1138,
        "symbol": "40760",
        "name": "SINOOCEAN N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1139,
        "symbol": "40759",
        "name": "BLUESTAR SGPS C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1140,
        "symbol": "40757",
        "name": "SPD BK N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1141,
        "symbol": "40756",
        "name": "WESTCHINA N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1142,
        "symbol": "40755",
        "name": "WUHAN UC B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1143,
        "symbol": "40754",
        "name": "LOGAN GRP N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1144,
        "symbol": "40753",
        "name": "RUDONG JX B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1145,
        "symbol": "40752",
        "name": "SZEXP B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1146,
        "symbol": "40751",
        "name": "BOCOM(HK) B3107",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1147,
        "symbol": "40750",
        "name": "KM PRHDC B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1148,
        "symbol": "40748",
        "name": "YB INTL B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1149,
        "symbol": "40747",
        "name": "ZY ZHICH B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1150,
        "symbol": "40745",
        "name": "CK PPT FN N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1151,
        "symbol": "40744",
        "name": "CK PPT FN N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1152,
        "symbol": "40743",
        "name": "BCEG HK B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1153,
        "symbol": "40742",
        "name": "NWD (MTN) N5106",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1154,
        "symbol": "40741",
        "name": "BJ ENERGY B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1155,
        "symbol": "40738",
        "name": "HZ FUYANG B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1156,
        "symbol": "40737",
        "name": "BOCOMI N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1157,
        "symbol": "40736",
        "name": "DEQING B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1158,
        "symbol": "40735",
        "name": "JN HTIC B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1159,
        "symbol": "40733",
        "name": "PMR BJ B2606B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1160,
        "symbol": "40732",
        "name": "QNB FIN N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1161,
        "symbol": "40731",
        "name": "LS FIN B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1162,
        "symbol": "40730",
        "name": "BOCOM LM N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1163,
        "symbol": "40728",
        "name": "AGRBK N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1164,
        "symbol": "40727",
        "name": "AGRBK N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1165,
        "symbol": "40726",
        "name": "GANZHOU H B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1166,
        "symbol": "40725",
        "name": "PMR BJ B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1167,
        "symbol": "40724",
        "name": "CEBHK N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1168,
        "symbol": "40723",
        "name": "GOUCHENG B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1169,
        "symbol": "40722",
        "name": "UNI TREK B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1170,
        "symbol": "40721",
        "name": "AIA GROUP RSPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1171,
        "symbol": "40720",
        "name": "MICROPORT B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1172,
        "symbol": "40719",
        "name": "CSCIF A N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1173,
        "symbol": "40718",
        "name": "INDSTRLB N2406B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1174,
        "symbol": "40717",
        "name": "INDSTRLB N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1175,
        "symbol": "40716",
        "name": "GSHA TOUR B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1176,
        "symbol": "40715",
        "name": "ZHENROPPT N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1177,
        "symbol": "40712",
        "name": "CSC FIN SGPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1178,
        "symbol": "40711",
        "name": "NWD FIN GSPCSD",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1179,
        "symbol": "40710",
        "name": "JZBD B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1180,
        "symbol": "40707",
        "name": "TIMES CHN N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1181,
        "symbol": "40706",
        "name": "CHALCO CH B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1182,
        "symbol": "40705",
        "name": "YANGO JT N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1183,
        "symbol": "40704",
        "name": "SFG INTL B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1184,
        "symbol": "40703",
        "name": "HTUTB N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1185,
        "symbol": "40702",
        "name": "WEIMOB B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1186,
        "symbol": "40701",
        "name": "WEALTHY V B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1187,
        "symbol": "40700",
        "name": "AAC N3106",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1188,
        "symbol": "40699",
        "name": "AAC N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1189,
        "symbol": "40698",
        "name": "CKI FIN GPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1190,
        "symbol": "40697",
        "name": "JJM DEV B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1191,
        "symbol": "40696",
        "name": "LEGEND FT N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1192,
        "symbol": "40695",
        "name": "JINKE PPT N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1193,
        "symbol": "40694",
        "name": "GUOREN N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1194,
        "symbol": "40692",
        "name": "NN CIG N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1195,
        "symbol": "40691",
        "name": "CLP-PF N3605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1196,
        "symbol": "40690",
        "name": "REDSUNPPT N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1197,
        "symbol": "40689",
        "name": "HAIXI OSI B2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1198,
        "symbol": "40688",
        "name": "HTISEC B2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1199,
        "symbol": "40687",
        "name": "CRCC HA N2605B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1200,
        "symbol": "40686",
        "name": "CRCC HA N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1201,
        "symbol": "40685",
        "name": "CP MTN N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1202,
        "symbol": "40684",
        "name": "JIAYUAN N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1203,
        "symbol": "40683",
        "name": "KWG GROUP N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1204,
        "symbol": "40682",
        "name": "CIFI HLDG N2805",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1205,
        "symbol": "40681",
        "name": "CIFI HLDG N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1206,
        "symbol": "40679",
        "name": "EHI CAR N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1207,
        "symbol": "40678",
        "name": "ENN CE N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1208,
        "symbol": "40676",
        "name": "TAL Y IN B3105",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1209,
        "symbol": "40675",
        "name": "TAL Y IN B2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1210,
        "symbol": "40674",
        "name": "GUANGYING B2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1211,
        "symbol": "40673",
        "name": "SHANXI SE B2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1212,
        "symbol": "40672",
        "name": "PETRONAS N6104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1213,
        "symbol": "40671",
        "name": "PETRONAS N3201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1214,
        "symbol": "40670",
        "name": "SINOOCEAN N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1215,
        "symbol": "40669",
        "name": "BOCL N2404D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1216,
        "symbol": "40668",
        "name": "BOCL N2404C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1217,
        "symbol": "40667",
        "name": "BOCL N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1218,
        "symbol": "40666",
        "name": "BOCL N2404B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1219,
        "symbol": "40664",
        "name": "HYD INTL B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1220,
        "symbol": "40663",
        "name": "MYS WSB C5104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1221,
        "symbol": "40662",
        "name": "MYS WSB C3104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1222,
        "symbol": "40661",
        "name": "MEITUAN B2804",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 4000.0,
        "成交额": 344000.0
    },
    {
        "序号": 1223,
        "symbol": "40660",
        "name": "MEITUAN B2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1224,
        "symbol": "40658",
        "name": "TENCENT N6104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1225,
        "symbol": "40657",
        "name": "TENCENT N5104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1226,
        "symbol": "40656",
        "name": "TENCENT N4104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1227,
        "symbol": "40655",
        "name": "TENCENT N3104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1228,
        "symbol": "40653",
        "name": "MCC(HK)CL SGPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1229,
        "symbol": "40652",
        "name": "CCB B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1230,
        "symbol": "40651",
        "name": "CCB N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1231,
        "symbol": "40650",
        "name": "CCB N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1232,
        "symbol": "40649",
        "name": "GTJA HOLD N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1233,
        "symbol": "40646",
        "name": "XD INC B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1234,
        "symbol": "40645",
        "name": "CDJZ FIN N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1235,
        "symbol": "40644",
        "name": "PION REW B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1236,
        "symbol": "40643",
        "name": "PION REW B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1237,
        "symbol": "40642",
        "name": "LOGAN GRP N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1238,
        "symbol": "40641",
        "name": "FRANSHION N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1239,
        "symbol": "40640",
        "name": "SI FA INV B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1240,
        "symbol": "40639",
        "name": "AIA GROUP RSPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1241,
        "symbol": "40638",
        "name": "HMZN N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1242,
        "symbol": "40635",
        "name": "PWRCN RB SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1243,
        "symbol": "40634",
        "name": "MGM CHINA N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1244,
        "symbol": "40633",
        "name": "NEW MOMEN B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1245,
        "symbol": "40632",
        "name": "SCI HK N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1246,
        "symbol": "40631",
        "name": "SCI HK N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1247,
        "symbol": "40630",
        "name": "SOAR WISE N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1248,
        "symbol": "40629",
        "name": "GLP HOLD N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1249,
        "symbol": "40628",
        "name": "CHINA SCE N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1250,
        "symbol": "40627",
        "name": "ANJI QCLF B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1251,
        "symbol": "40626",
        "name": "NANCHANG B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1252,
        "symbol": "40625",
        "name": "PETRONAS N2803",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1253,
        "symbol": "40624",
        "name": "SHUIFA B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1254,
        "symbol": "40623",
        "name": "DAFENG PT B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1255,
        "symbol": "40622",
        "name": "YAN GANG B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1256,
        "symbol": "40621",
        "name": "HAICHUAN B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1257,
        "symbol": "40620",
        "name": "SX FIN B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1258,
        "symbol": "40618",
        "name": "SXSY CIO N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1259,
        "symbol": "40617",
        "name": "SXSY CIO N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1260,
        "symbol": "40616",
        "name": "RUDONG JX B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1261,
        "symbol": "40615",
        "name": "BAIC FI N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1262,
        "symbol": "40614",
        "name": "ZJ SP B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1263,
        "symbol": "40613",
        "name": "ZHESHANG N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1264,
        "symbol": "40612",
        "name": "XIAN AERO B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1265,
        "symbol": "40611",
        "name": "JIAN CHG N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1266,
        "symbol": "40610",
        "name": "CEBHK N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1267,
        "symbol": "40609",
        "name": "HLP FIN N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1268,
        "symbol": "40607",
        "name": "HUATONG N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1269,
        "symbol": "40606",
        "name": "FE HORI N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1270,
        "symbol": "40605",
        "name": "GUOTAI JA N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1271,
        "symbol": "40604",
        "name": "CAPCO FC N3103",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1272,
        "symbol": "40603",
        "name": "CDBL FND2 N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1273,
        "symbol": "40602",
        "name": "CDBL FND2 N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1274,
        "symbol": "40600",
        "name": "TEQU MF B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1275,
        "symbol": "40598",
        "name": "HX PHAR N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1276,
        "symbol": "40597",
        "name": "JINCHUAN B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1277,
        "symbol": "40596",
        "name": "BLUE BR B2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1278,
        "symbol": "40594",
        "name": "SWSI B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1279,
        "symbol": "40592",
        "name": "COGO FIN N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1280,
        "symbol": "40591",
        "name": "CISI FIN B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1281,
        "symbol": "40590",
        "name": "CHINA SCE N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1282,
        "symbol": "40589",
        "name": "CMB ILM N3102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1283,
        "symbol": "40588",
        "name": "CMB ILM N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1284,
        "symbol": "40586",
        "name": "HLP FIN N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1285,
        "symbol": "40585",
        "name": "SANDS N3006A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1286,
        "symbol": "40584",
        "name": "SANDS N2601A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1287,
        "symbol": "40583",
        "name": "ZJ XC INV B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1288,
        "symbol": "40582",
        "name": "AIR AUTH N5102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1289,
        "symbol": "40581",
        "name": "AIR AUTH N3102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1290,
        "symbol": "40580",
        "name": "CPFIN III B2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1291,
        "symbol": "40579",
        "name": "CITBK N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1292,
        "symbol": "40578",
        "name": "CITBK N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1293,
        "symbol": "40577",
        "name": "YX REIT N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1294,
        "symbol": "40576",
        "name": "XIAN AERO B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1295,
        "symbol": "40575",
        "name": "HN SFIHK B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1296,
        "symbol": "40574",
        "name": "BOCL N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1297,
        "symbol": "40572",
        "name": "ZHENROPPT N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1298,
        "symbol": "40571",
        "name": "HX PHAR B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1299,
        "symbol": "40564",
        "name": "MAPLELEAF B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1300,
        "symbol": "40563",
        "name": "AVIC IFI B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1301,
        "symbol": "40562",
        "name": "CICC16MTN N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1302,
        "symbol": "40561",
        "name": "CICC16MTN N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1303,
        "symbol": "40560",
        "name": "CHAM PATH N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1304,
        "symbol": "40559",
        "name": "CHAM PATH N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1305,
        "symbol": "40558",
        "name": "GR HEALTH N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1306,
        "symbol": "40556",
        "name": "FEC FIN N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1307,
        "symbol": "40555",
        "name": "RUDONG JX B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1308,
        "symbol": "40551",
        "name": "WHEEL MTN N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1309,
        "symbol": "40550",
        "name": "XINGCHENG N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1310,
        "symbol": "40548",
        "name": "WESTWOOD N3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1311,
        "symbol": "40547",
        "name": "WESTWOOD N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1312,
        "symbol": "40546",
        "name": "HSPHARM B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1313,
        "symbol": "40545",
        "name": "ENRONG B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1314,
        "symbol": "40544",
        "name": "CHGHK TM B3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1315,
        "symbol": "40543",
        "name": "CHGHK TM B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1316,
        "symbol": "40542",
        "name": "CINDA20 N3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1317,
        "symbol": "40541",
        "name": "CINDA20 N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1318,
        "symbol": "40540",
        "name": "CINDA20 N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1319,
        "symbol": "40539",
        "name": "CINDA20 N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1320,
        "symbol": "40538",
        "name": "AGRBK N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1321,
        "symbol": "40537",
        "name": "AGRBK N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1322,
        "symbol": "40535",
        "name": "FJZHNLNG N2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1323,
        "symbol": "40534",
        "name": "NWD (MTN) N3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1324,
        "symbol": "40533",
        "name": "CX URBAN B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1325,
        "symbol": "40532",
        "name": "BOCL N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1326,
        "symbol": "40531",
        "name": "REDSUNPPT N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1327,
        "symbol": "40530",
        "name": "HAIDILAO N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1328,
        "symbol": "40529",
        "name": "ZS GROUP B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1329,
        "symbol": "40528",
        "name": "TIMES CHN N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1330,
        "symbol": "40527",
        "name": "LOGAN GRP N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1331,
        "symbol": "40526",
        "name": "ZHEJIANG B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1332,
        "symbol": "40523",
        "name": "CDB N2401C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1333,
        "symbol": "40522",
        "name": "SINOPEC N5101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1334,
        "symbol": "40521",
        "name": "SINOPEC N3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1335,
        "symbol": "40520",
        "name": "SINOPEC N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1336,
        "symbol": "40519",
        "name": "CIFI HLDG N2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1337,
        "symbol": "40518",
        "name": "STANC PSCCSF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1338,
        "symbol": "40517",
        "name": "YUZHOU N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1339,
        "symbol": "40516",
        "name": "ZHENROPPT N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1340,
        "symbol": "40514",
        "name": "VIVA BIOT B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1341,
        "symbol": "40513",
        "name": "GOUCHENG B2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1342,
        "symbol": "40512",
        "name": "PANTHER GSPCSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1343,
        "symbol": "40511",
        "name": "XIAOMI BT B2712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1344,
        "symbol": "40510",
        "name": "JN HTIC B2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1345,
        "symbol": "40509",
        "name": "GZ DDH B2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1346,
        "symbol": "40508",
        "name": "LOGAN GRP N2612",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1347,
        "symbol": "40505",
        "name": "JN LIXIA B2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1348,
        "symbol": "40504",
        "name": "NWD FIN GSPCSC",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1349,
        "symbol": "40503",
        "name": "CHGHK TM GPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1350,
        "symbol": "40502",
        "name": "CHGHK TM GPSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1351,
        "symbol": "40501",
        "name": "AIR AUTH SPCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1352,
        "symbol": "40500",
        "name": "AIR AUTH SPCSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1353,
        "symbol": "40493",
        "name": "CN GZB GP SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1354,
        "symbol": "40489",
        "name": "OMNI T3 N2903H",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1355,
        "symbol": "40488",
        "name": "OMNI T3 N2903N",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1356,
        "symbol": "40487",
        "name": "OMNI T3 N2903M",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1357,
        "symbol": "40486",
        "name": "OMNI T3 N2903L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1358,
        "symbol": "40485",
        "name": "OMNI T3 N2903K",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1359,
        "symbol": "40484",
        "name": "OMNI T3 N2903J",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1360,
        "symbol": "40483",
        "name": "OMNI T3 N2903I",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1361,
        "symbol": "40482",
        "name": "OMNI T3 N2903G",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1362,
        "symbol": "40481",
        "name": "OMNI T3 N2903F",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1363,
        "symbol": "40480",
        "name": "OMNI T3 N2903E",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1364,
        "symbol": "40479",
        "name": "OMNI T3 N2903D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1365,
        "symbol": "40478",
        "name": "OMNI T3 N2903C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1366,
        "symbol": "40477",
        "name": "OMNI T3 N2903B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1367,
        "symbol": "40476",
        "name": "OMNI T3 N2903A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1368,
        "symbol": "40475",
        "name": "BOCOM UCB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1369,
        "symbol": "40471",
        "name": "YILI HI B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1370,
        "symbol": "40468",
        "name": "JOY TREA B3011",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1371,
        "symbol": "40467",
        "name": "JOY TREA B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1372,
        "symbol": "40465",
        "name": "KWG GROUP N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1373,
        "symbol": "40464",
        "name": "CIFI HLDG N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1374,
        "symbol": "40459",
        "name": "RONGSHI N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1375,
        "symbol": "40455",
        "name": "HF20 I B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1376,
        "symbol": "40454",
        "name": "LENOVO N3011",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1377,
        "symbol": "40452",
        "name": "POLY PROP N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1378,
        "symbol": "40447",
        "name": "GREENTOWN N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1379,
        "symbol": "40445",
        "name": "SINOCHEM N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1380,
        "symbol": "40444",
        "name": "CHINA SCE N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1381,
        "symbol": "40443",
        "name": "WENS FOOD N3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1382,
        "symbol": "40442",
        "name": "WENS FOOD N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1383,
        "symbol": "40440",
        "name": "HUBEI S&T B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1384,
        "symbol": "40438",
        "name": "SINO TREN B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 58000.0,
        "成交额": 5438950.0
    },
    {
        "序号": 1385,
        "symbol": "40437",
        "name": "PEAK RE PSGCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1386,
        "symbol": "40435",
        "name": "MEITUAN N3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1387,
        "symbol": "40434",
        "name": "MEITUAN N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1388,
        "symbol": "40433",
        "name": "CDB N3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1389,
        "symbol": "40432",
        "name": "CDB N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1390,
        "symbol": "40427",
        "name": "BLOSSOM SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1391,
        "symbol": "40426",
        "name": "BLOSSOM N3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1392,
        "symbol": "40425",
        "name": "AGRBK N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1393,
        "symbol": "40420",
        "name": "AMIPEACE N3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1394,
        "symbol": "40419",
        "name": "AMIPEACE N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1395,
        "symbol": "40415",
        "name": "BEA UCS D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1396,
        "symbol": "40414",
        "name": "OCEAN LR B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1397,
        "symbol": "40411",
        "name": "LOGAN GRP N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1398,
        "symbol": "40409",
        "name": "CMHI FIN GSPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1399,
        "symbol": "40405",
        "name": "HUARONG19 SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1400,
        "symbol": "40404",
        "name": "HUARONG19 N3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1401,
        "symbol": "40402",
        "name": "CCBL1CORP N3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1402,
        "symbol": "40401",
        "name": "CCBL1CORP N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1403,
        "symbol": "40399",
        "name": "CDB LEAS B3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1404,
        "symbol": "40396",
        "name": "DJ HAIYU SGPSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1405,
        "symbol": "40390",
        "name": "TAL Y(E) B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1406,
        "symbol": "40389",
        "name": "TIMES CHN N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1407,
        "symbol": "40388",
        "name": "AVIC IFI B3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1408,
        "symbol": "40387",
        "name": "HN WCI B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1409,
        "symbol": "40385",
        "name": "LOGAN GRP N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1410,
        "symbol": "40384",
        "name": "AIA GRP T2S4009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1411,
        "symbol": "40383",
        "name": "ENNENERGY N3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1412,
        "symbol": "40382",
        "name": "CON RD B3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1413,
        "symbol": "40381",
        "name": "CON RD B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1414,
        "symbol": "40380",
        "name": "GZ METRO N3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 26000.0,
        "成交额": 2119780.0
    },
    {
        "序号": 1415,
        "symbol": "40379",
        "name": "GZ METRO N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1416,
        "symbol": "40378",
        "name": "PANTHER GSPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1417,
        "symbol": "40375",
        "name": "ZHENROPPT N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1418,
        "symbol": "40373",
        "name": "FPC RES B2709",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1419,
        "symbol": "40372",
        "name": "BOCOM N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1420,
        "symbol": "40370",
        "name": "CM BANK N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1421,
        "symbol": "40369",
        "name": "ZA ONLINE N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1422,
        "symbol": "40368",
        "name": "INVENTIVE N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1423,
        "symbol": "40366",
        "name": "TME N3009",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1424,
        "symbol": "40365",
        "name": "TME N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1425,
        "symbol": "40364",
        "name": "HS TOUR B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1426,
        "symbol": "40362",
        "name": "GUOHUI B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1427,
        "symbol": "40361",
        "name": "JICZHIXIN B3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1428,
        "symbol": "40360",
        "name": "JICZHIXIN B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1429,
        "symbol": "40359",
        "name": "HKE FIN N3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1430,
        "symbol": "40357",
        "name": "WYNN MAC N2808",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1431,
        "symbol": "40354",
        "name": "ICBCIL FN N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1432,
        "symbol": "40353",
        "name": "EL GLO SGPCSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1433,
        "symbol": "40350",
        "name": "CGWIH V B3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1434,
        "symbol": "40349",
        "name": "MTR CORP N3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1435,
        "symbol": "40347",
        "name": "YD HOLD N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1436,
        "symbol": "40343",
        "name": "YUZHOU N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1437,
        "symbol": "40342",
        "name": "CMB ILM N3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1438,
        "symbol": "40341",
        "name": "CMB ILM N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1439,
        "symbol": "40338",
        "name": "KWG GROUP N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1440,
        "symbol": "40335",
        "name": "STATEGRI N3008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1441,
        "symbol": "40334",
        "name": "STATEGRI N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1442,
        "symbol": "40333",
        "name": "CCB N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1443,
        "symbol": "40330",
        "name": "CSCIF A N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1444,
        "symbol": "40329",
        "name": "CH BANK NCSCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 20000.0,
        "成交额": 1910000.0
    },
    {
        "序号": 1445,
        "symbol": "40324",
        "name": "SPIC MTN N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1446,
        "symbol": "40323",
        "name": "HORSE GAL N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1447,
        "symbol": "40321",
        "name": "GRNL GLB N3101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1448,
        "symbol": "40320",
        "name": "GRNL GLB N2904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1449,
        "symbol": "40319",
        "name": "GHKF N3007",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1450,
        "symbol": "40317",
        "name": "CCBL1CORP N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 60000.0,
        "成交额": 5677500.0
    },
    {
        "序号": 1451,
        "symbol": "40316",
        "name": "CIFI HLDG N2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1452,
        "symbol": "40315",
        "name": "BOCOM N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1453,
        "symbol": "40311",
        "name": "ICBC(HK) N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1454,
        "symbol": "40309",
        "name": "HFI INTL B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1455,
        "symbol": "40304",
        "name": "ZA ONLINE N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1456,
        "symbol": "40302",
        "name": "BOCOM LM N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1457,
        "symbol": "40300",
        "name": "GREENTOWN N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1458,
        "symbol": "40299",
        "name": "SHA PG B3007",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1459,
        "symbol": "40298",
        "name": "SHA PG B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1460,
        "symbol": "40296",
        "name": "TIMES CHN N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1461,
        "symbol": "40295",
        "name": "UNI TREK B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1462,
        "symbol": "40292",
        "name": "EDU TECH B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1463,
        "symbol": "40290",
        "name": "CLP-PF N3506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1464,
        "symbol": "40289",
        "name": "CLP-PF N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1465,
        "symbol": "40284",
        "name": "CCB B3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1466,
        "symbol": "40279",
        "name": "BOCL N2506A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1467,
        "symbol": "40278",
        "name": "COSL SG N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1468,
        "symbol": "40277",
        "name": "COSL SG N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1469,
        "symbol": "40276",
        "name": "STANC PSCCSE",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1470,
        "symbol": "40271",
        "name": "CNPC GLB B3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1471,
        "symbol": "40270",
        "name": "CNPC GLB B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1472,
        "symbol": "40268",
        "name": "HENGJIAN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1473,
        "symbol": "40262",
        "name": "NWD FIN GSPCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1474,
        "symbol": "40261",
        "name": "CHD OVS SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1475,
        "symbol": "40260",
        "name": "CAPCO FC N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1476,
        "symbol": "40259",
        "name": "WYNN MAC N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1477,
        "symbol": "40258",
        "name": "MGM CHINA N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1478,
        "symbol": "40257",
        "name": "MENGNIU B3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1479,
        "symbol": "40256",
        "name": "MENGNIU B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1480,
        "symbol": "40254",
        "name": "GRNL GLB N2812",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1481,
        "symbol": "40253",
        "name": "CHAMP MTN N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1482,
        "symbol": "40252",
        "name": "YONGA INV B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1483,
        "symbol": "40248",
        "name": "HKE FIN N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1484,
        "symbol": "40247",
        "name": "SANDS N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1485,
        "symbol": "40246",
        "name": "SANDS N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1486,
        "symbol": "40245",
        "name": "BLUE BR B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1487,
        "symbol": "40242",
        "name": "TENCENT N6006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 6500.0,
        "成交额": 389350.0
    },
    {
        "序号": 1488,
        "symbol": "40241",
        "name": "TENCENT N5006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1489,
        "symbol": "40240",
        "name": "TENCENT N3006",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 50000.0,
        "成交额": 4185500.0
    },
    {
        "序号": 1490,
        "symbol": "40239",
        "name": "TENCENT N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1491,
        "symbol": "40238",
        "name": "CITIC SEC N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1492,
        "symbol": "40236",
        "name": "HYSAN MTN N2706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1493,
        "symbol": "40232",
        "name": "BEA N3005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1494,
        "symbol": "40227",
        "name": "HLAND MTN N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1495,
        "symbol": "40225",
        "name": "ZHENROPPT N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1496,
        "symbol": "40224",
        "name": "ZS GROUP B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1497,
        "symbol": "40223",
        "name": "NWD (MTN) N3005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1498,
        "symbol": "40222",
        "name": "SINOPEC N5005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1499,
        "symbol": "40221",
        "name": "SINOPEC N3005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1500,
        "symbol": "40220",
        "name": "SINOPEC N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1501,
        "symbol": "40219",
        "name": "AMTD IDEA SPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1502,
        "symbol": "40217",
        "name": "SHKP(CM)L N3005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1503,
        "symbol": "40212",
        "name": "WHARFREIC N3005",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1504,
        "symbol": "40211",
        "name": "WHARFREIC N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1505,
        "symbol": "40210",
        "name": "KINGSOFT B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1506,
        "symbol": "40209",
        "name": "XIAOMI BT N3004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1507,
        "symbol": "40205",
        "name": "LENOVO N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1508,
        "symbol": "40204",
        "name": "SUN EXP N3004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1509,
        "symbol": "40203",
        "name": "SUN EXP N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1510,
        "symbol": "40202",
        "name": "PETRONAS N6004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1511,
        "symbol": "40201",
        "name": "PETRONAS N5004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1512,
        "symbol": "40200",
        "name": "PETRONAS N3004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1513,
        "symbol": "40199",
        "name": "AIA GROUP N3004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1514,
        "symbol": "40198",
        "name": "IFC DEV N3004",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1515,
        "symbol": "40193",
        "name": "CINDA20 N3003",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1516,
        "symbol": "40192",
        "name": "CINDA20 N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1517,
        "symbol": "40191",
        "name": "CINDA20 N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1518,
        "symbol": "40188",
        "name": "MCC(HK)CL GPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1519,
        "symbol": "40186",
        "name": "HAITNG FH B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1520,
        "symbol": "40179",
        "name": "CHINA OVS N3503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1521,
        "symbol": "40178",
        "name": "CHINA OVS N3003",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1522,
        "symbol": "40177",
        "name": "CHINA OVS N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1523,
        "symbol": "40176",
        "name": "BOCOM (HK) SCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1524,
        "symbol": "40175",
        "name": "MINMET FN SGPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1525,
        "symbol": "40174",
        "name": "GRNL GLB N3003",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1526,
        "symbol": "40173",
        "name": "EL GLO SGPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1527,
        "symbol": "40170",
        "name": "BOCOM LM N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1528,
        "symbol": "40167",
        "name": "HUARONG19 N3002",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1529,
        "symbol": "40166",
        "name": "HUARONG19 N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1530,
        "symbol": "40162",
        "name": "CITIC LTD N3002",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1531,
        "symbol": "40161",
        "name": "CITIC LTD N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1532,
        "symbol": "40159",
        "name": "YUZHOU N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1533,
        "symbol": "40158",
        "name": "CCCI TSR SGPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1534,
        "symbol": "40157",
        "name": "CCCI TSR SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1535,
        "symbol": "40156",
        "name": "SINOSING B3002",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1536,
        "symbol": "40155",
        "name": "SINOSING B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1537,
        "symbol": "40154",
        "name": "CZ INTL B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1538,
        "symbol": "40152",
        "name": "SF HLDG N3002",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1539,
        "symbol": "40151",
        "name": "FE HORI N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1540,
        "symbol": "40150",
        "name": "S BPHARM B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1541,
        "symbol": "40149",
        "name": "ZJCXJK B2612",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1542,
        "symbol": "40148",
        "name": "GZ DDH B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1543,
        "symbol": "40146",
        "name": "CSSC B3002",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1544,
        "symbol": "40145",
        "name": "CSSC B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1545,
        "symbol": "40142",
        "name": "20201WST N5108",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1546,
        "symbol": "40141",
        "name": "SPAC MTN N3001",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1547,
        "symbol": "40138",
        "name": "SOAR WIND GPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1548,
        "symbol": "40130",
        "name": "SHKP(CM)L N3001",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1549,
        "symbol": "40123",
        "name": "BOCL N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1550,
        "symbol": "40120",
        "name": "CIFI HLDG N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1551,
        "symbol": "40118",
        "name": "ZZ URBAN N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1552,
        "symbol": "40117",
        "name": "KWG GROUP N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1553,
        "symbol": "40116",
        "name": "ZHENROPPT N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1554,
        "symbol": "40115",
        "name": "SINOOCEAN N3001",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1555,
        "symbol": "40114",
        "name": "LOGAN GRP N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1556,
        "symbol": "40113",
        "name": "C PLAZA N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1557,
        "symbol": "40112",
        "name": "YUZHOU N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1558,
        "symbol": "40110",
        "name": "NWD (MTN) N4912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1559,
        "symbol": "40109",
        "name": "YUHUA EDU B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1560,
        "symbol": "40107",
        "name": "BOSIDENG B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1561,
        "symbol": "40106",
        "name": "TJ SCIM B3912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1562,
        "symbol": "40105",
        "name": "TJ SCIM B2912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1563,
        "symbol": "40104",
        "name": "TJ SCIM B2612",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1564,
        "symbol": "40103",
        "name": "TJ SCIM B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1565,
        "symbol": "40102",
        "name": "WYNN MAC N2912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1566,
        "symbol": "40101",
        "name": "KM RT B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1567,
        "symbol": "40097",
        "name": "PB ISSR5 B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1568,
        "symbol": "40095",
        "name": "CHGHK TM B2912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1569,
        "symbol": "40094",
        "name": "CHGHK TM B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1570,
        "symbol": "40092",
        "name": "BOCOM LM N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1571,
        "symbol": "40091",
        "name": "CR LAND SPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1572,
        "symbol": "40089",
        "name": "AMIPEACE N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1573,
        "symbol": "40083",
        "name": "CSCFIN III SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1574,
        "symbol": "40079",
        "name": "YUZHOU N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1575,
        "symbol": "40076",
        "name": "CHINA OVS N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1576,
        "symbol": "40075",
        "name": "AAC N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1577,
        "symbol": "40074",
        "name": "PCGI II N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1578,
        "symbol": "40069",
        "name": "NANYANG N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1579,
        "symbol": "40068",
        "name": "ICBCIL FN N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1580,
        "symbol": "40065",
        "name": "SHK&CO N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1581,
        "symbol": "40062",
        "name": "C PLAZA SPCS C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1582,
        "symbol": "40060",
        "name": "HUARONG19 N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1583,
        "symbol": "40059",
        "name": "HUARONG19 N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1584,
        "symbol": "40057",
        "name": "HTISEC B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1585,
        "symbol": "40056",
        "name": "VKEREHK N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1586,
        "symbol": "40055",
        "name": "VKEREHK N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1587,
        "symbol": "40054",
        "name": "GZ METRO N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1588,
        "symbol": "40052",
        "name": "FWD GHL N3312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1589,
        "symbol": "40050",
        "name": "SINOPEC N4911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1590,
        "symbol": "40049",
        "name": "SINOPEC N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1591,
        "symbol": "40048",
        "name": "SINOPEC N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1592,
        "symbol": "40046",
        "name": "CIFI HLDG N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1593,
        "symbol": "40044",
        "name": "CLP PHKF PSGCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1594,
        "symbol": "40043",
        "name": "YUZHOU N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1595,
        "symbol": "40042",
        "name": "HUAYI FNI N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1596,
        "symbol": "40040",
        "name": "PCGI INT N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1597,
        "symbol": "40037",
        "name": "CNCB INTL N3312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 20000.0,
        "成交额": 2022000.0
    },
    {
        "序号": 1598,
        "symbol": "40035",
        "name": "SE GLOBAL N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1599,
        "symbol": "40033",
        "name": "CITIC SEC N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1600,
        "symbol": "40030",
        "name": "SUN EXP N2910",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1601,
        "symbol": "40029",
        "name": "H&H INTL N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1602,
        "symbol": "40017",
        "name": "HKINTQD B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1603,
        "symbol": "40016",
        "name": "CNOOC F13 N4909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1604,
        "symbol": "40015",
        "name": "CNOOC F13 N2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1605,
        "symbol": "40007",
        "name": "GRNL GLB N2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1606,
        "symbol": "40001",
        "name": "CCB N2611C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1607,
        "symbol": "40000",
        "name": "JOY TREA B2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1608,
        "symbol": "09986",
        "name": "大山教育",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 2.92,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1609,
        "symbol": "09978",
        "name": "方圆生活服务",
        "最新价": 0.086,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.086,
        "最高": 0.086,
        "最低": 0.086,
        "昨收": 0.086,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1610,
        "symbol": "09977",
        "name": "凤祥股份",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.52,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1611,
        "symbol": "09968",
        "name": "汇景控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.051,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1612,
        "symbol": "09938",
        "name": "华和控股",
        "最新价": 0.092,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.092,
        "最高": 0.092,
        "最低": 0.092,
        "昨收": 0.092,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1613,
        "symbol": "09930",
        "name": "宏信建发",
        "最新价": 3.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.8,
        "最高": 3.8,
        "最低": 3.78,
        "昨收": 3.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1614,
        "symbol": "09918",
        "name": "丽年国际",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.56,
        "昨收": 0.56,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1615,
        "symbol": "09906",
        "name": "宏力医疗管理",
        "最新价": 2.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.59,
        "最高": 2.59,
        "最低": 2.59,
        "昨收": 2.59,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1616,
        "symbol": "09882",
        "name": "永联丰控股",
        "最新价": 2.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.36,
        "最高": 2.36,
        "最低": 2.36,
        "昨收": 2.36,
        "成交量": 5000.0,
        "成交额": 11800.0
    },
    {
        "序号": 1617,
        "symbol": "09829",
        "name": "安硕中国国债-U",
        "最新价": 7.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.27,
        "最高": 7.275,
        "最低": 7.27,
        "昨收": 7.27,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1618,
        "symbol": "09817",
        "name": "PP国债-U",
        "最新价": 15.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 15.65,
        "最高": 15.65,
        "最低": 15.65,
        "昨收": 15.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1619,
        "symbol": "09813",
        "name": "华夏政银国债-U",
        "最新价": 15.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 15.19,
        "最高": 15.19,
        "最低": 15.19,
        "昨收": 15.19,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1620,
        "symbol": "09810",
        "name": "PP新兴东盟-U",
        "最新价": 8.335,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 8.335,
        "最高": 8.35,
        "最低": 8.335,
        "昨收": 8.335,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1621,
        "symbol": "09804",
        "name": "PP越南-U",
        "最新价": 7.705,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.705,
        "最高": 7.72,
        "最低": 7.705,
        "昨收": 7.705,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1622,
        "symbol": "09689",
        "name": "金泰丰国际控股",
        "最新价": 0.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.45,
        "昨收": 0.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1623,
        "symbol": "09677",
        "name": "威海银行",
        "最新价": 2.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.78,
        "最高": 2.78,
        "最低": 2.78,
        "昨收": 2.78,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1624,
        "symbol": "09668",
        "name": "渤海银行",
        "最新价": 1.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.22,
        "最高": 1.24,
        "最低": 1.21,
        "昨收": 1.22,
        "成交量": 1127500.0,
        "成交额": 1381635.0
    },
    {
        "序号": 1625,
        "symbol": "09638",
        "name": "法拉帝",
        "最新价": 24.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 25.0,
        "最高": 25.0,
        "最低": 24.75,
        "昨收": 24.8,
        "成交量": 125600.0,
        "成交额": 3127700.0
    },
    {
        "序号": 1626,
        "symbol": "09636",
        "name": "九方财富",
        "最新价": 13.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 12.88,
        "最高": 13.4,
        "最低": 12.88,
        "昨收": 13.3,
        "成交量": 239000.0,
        "成交额": 3149550.0
    },
    {
        "序号": 1627,
        "symbol": "09616",
        "name": "东软教育",
        "最新价": 2.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.64,
        "最高": 2.7,
        "最低": 2.62,
        "昨收": 2.63,
        "成交量": 108800.0,
        "成交额": 285768.0
    },
    {
        "序号": 1628,
        "symbol": "09600",
        "name": "新纽科技",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.54,
        "昨收": 0.56,
        "成交量": 63200.0,
        "成交额": 35376.0
    },
    {
        "序号": 1629,
        "symbol": "09181",
        "name": "PP亚洲创科-U",
        "最新价": 9.895,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 9.895,
        "最高": 9.92,
        "最低": 9.895,
        "昨收": 9.895,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1630,
        "symbol": "09177",
        "name": "PP国债对冲-U",
        "最新价": 15.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 15.8,
        "最高": 15.8,
        "最低": 15.8,
        "昨收": 15.8,
        "成交量": 3800.0,
        "成交额": 60040.0
    },
    {
        "序号": 1631,
        "symbol": "09167",
        "name": "工银南方中国-U",
        "最新价": 6.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.54,
        "最高": 6.54,
        "最低": 6.49,
        "昨收": 6.54,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1632,
        "symbol": "09141",
        "name": "华夏亚投债-U",
        "最新价": 1.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.8,
        "最高": 1.8,
        "最低": 1.8,
        "昨收": 1.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1633,
        "symbol": "09125",
        "name": "安硕短期政银-U",
        "最新价": 7.075,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.075,
        "最高": 7.08,
        "最低": 7.075,
        "昨收": 7.075,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1634,
        "symbol": "09091",
        "name": "A日兴元宇宙-U",
        "最新价": 10.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 10.48,
        "最高": 10.52,
        "最低": 10.48,
        "昨收": 10.48,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1635,
        "symbol": "09081",
        "name": "价值黄金-U",
        "最新价": 6.175,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.175,
        "最高": 6.175,
        "最低": 6.17,
        "昨收": 6.175,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1636,
        "symbol": "09078",
        "name": "PP美国库A-U",
        "最新价": 541.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 541.5,
        "最高": 541.5,
        "最低": 541.5,
        "昨收": 541.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1637,
        "symbol": "09077",
        "name": "PP美国库-U",
        "最新价": 505.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 505.0,
        "最高": 505.0,
        "最低": 505.0,
        "昨收": 505.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1638,
        "symbol": "09031",
        "name": "海通AESG-U",
        "最新价": 1.018,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.018,
        "最高": 1.02,
        "最低": 1.018,
        "昨收": 1.018,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1639,
        "symbol": "09022",
        "name": "安硕新兴市场-U",
        "最新价": 56.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 56.06,
        "最高": 56.24,
        "最低": 56.0,
        "昨收": 56.06,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1640,
        "symbol": "09001",
        "name": "PP中地美债-U",
        "最新价": 9.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 9.35,
        "最高": 9.45,
        "最低": 9.35,
        "昨收": 9.45,
        "成交量": 1000.0,
        "成交额": 9375.0
    },
    {
        "序号": 1641,
        "symbol": "08657",
        "name": "TRUE PARTNER",
        "最新价": 0.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.44,
        "最高": 0.44,
        "最低": 0.44,
        "昨收": 0.44,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1642,
        "symbol": "08646",
        "name": "中国宏光",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1643,
        "symbol": "08631",
        "name": "申港控股",
        "最新价": 0.054,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.054,
        "最高": 0.054,
        "最低": 0.053,
        "昨收": 0.054,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1644,
        "symbol": "08627",
        "name": "旅橙文化",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.1,
        "昨收": 0.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1645,
        "symbol": "08623",
        "name": "中国蜀塔",
        "最新价": 0.034,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.034,
        "最高": 0.034,
        "最低": 0.034,
        "昨收": 0.034,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1646,
        "symbol": "08616",
        "name": "新威工程集团",
        "最新价": 0.053,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.053,
        "最高": 0.053,
        "最低": 0.053,
        "昨收": 0.053,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1647,
        "symbol": "08612",
        "name": "维亮控股",
        "最新价": 0.028,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.028,
        "最高": 0.028,
        "最低": 0.028,
        "昨收": 0.028,
        "成交量": 110000.0,
        "成交额": 3080.0
    },
    {
        "序号": 1648,
        "symbol": "08611",
        "name": "MINDTELL TECH",
        "最新价": 0.098,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.098,
        "最高": 0.098,
        "最低": 0.098,
        "昨收": 0.098,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1649,
        "symbol": "08609",
        "name": "永续农业",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 30000.0,
        "成交额": 8400.0
    },
    {
        "序号": 1650,
        "symbol": "08607",
        "name": "纳尼亚集团",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.05,
        "最低": 0.05,
        "昨收": 0.05,
        "成交量": 455000.0,
        "成交额": 22740.0
    },
    {
        "序号": 1651,
        "symbol": "08603",
        "name": "亮晴控股",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.53,
        "昨收": 0.53,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1652,
        "symbol": "08601",
        "name": "宝燵控股",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1653,
        "symbol": "08527",
        "name": "聚利宝控股",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.44,
        "最高": 0.46,
        "最低": 0.405,
        "昨收": 0.405,
        "成交量": 145000.0,
        "成交额": 64125.0
    },
    {
        "序号": 1654,
        "symbol": "08526",
        "name": "荣丰集团亚洲",
        "最新价": 0.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.16,
        "最高": 0.16,
        "最低": 0.16,
        "昨收": 0.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1655,
        "symbol": "08523",
        "name": "常满控股",
        "最新价": 0.315,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.315,
        "最高": 0.315,
        "最低": 0.31,
        "昨收": 0.315,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1656,
        "symbol": "08521",
        "name": "智纺国际控股",
        "最新价": 0.335,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.335,
        "最高": 0.335,
        "最低": 0.335,
        "昨收": 0.335,
        "成交量": 60000.0,
        "成交额": 20100.0
    },
    {
        "序号": 1657,
        "symbol": "08502",
        "name": "远航港口",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.31,
        "昨收": 0.31,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1658,
        "symbol": "08501",
        "name": "庄皇集团公司",
        "最新价": 0.425,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.425,
        "最高": 0.425,
        "最低": 0.425,
        "昨收": 0.425,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1659,
        "symbol": "08496",
        "name": "环球友饮智能",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.49,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1660,
        "symbol": "08495",
        "name": "1957 & CO.",
        "最新价": 0.199,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.199,
        "最高": 0.199,
        "最低": 0.199,
        "昨收": 0.199,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1661,
        "symbol": "08490",
        "name": "骏码半导体",
        "最新价": 0.122,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.122,
        "最高": 0.122,
        "最低": 0.122,
        "昨收": 0.122,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1662,
        "symbol": "08489",
        "name": "裕程物流",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.18,
        "最高": 0.18,
        "最低": 0.18,
        "昨收": 0.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1663,
        "symbol": "08481",
        "name": "盛龙锦秀国际",
        "最新价": 0.229,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.232,
        "最低": 0.229,
        "昨收": 0.229,
        "成交量": 110000.0,
        "成交额": 25375.0
    },
    {
        "序号": 1664,
        "symbol": "08480",
        "name": "飞霓控股",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.29,
        "最高": 0.295,
        "最低": 0.29,
        "昨收": 0.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1665,
        "symbol": "08476",
        "name": "大洋环球控股",
        "最新价": 0.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.89,
        "最高": 0.9,
        "最低": 0.88,
        "昨收": 0.88,
        "成交量": 164000.0,
        "成交额": 146320.0
    },
    {
        "序号": 1666,
        "symbol": "08475",
        "name": "千盛集团控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.32,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1667,
        "symbol": "08473",
        "name": "弥明生活百货",
        "最新价": 0.133,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.133,
        "最高": 0.133,
        "最低": 0.133,
        "昨收": 0.133,
        "成交量": 10000.0,
        "成交额": 1330.0
    },
    {
        "序号": 1668,
        "symbol": "08472",
        "name": "立高控股",
        "最新价": 1.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.1,
        "最高": 1.1,
        "最低": 1.04,
        "昨收": 1.1,
        "成交量": 62500.0,
        "成交额": 68440.0
    },
    {
        "序号": 1669,
        "symbol": "08462",
        "name": "中安控股集团",
        "最新价": 0.056,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.056,
        "最高": 0.056,
        "最低": 0.056,
        "昨收": 0.056,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1670,
        "symbol": "08456",
        "name": "民信国际控股",
        "最新价": 0.222,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.222,
        "最高": 0.222,
        "最低": 0.222,
        "昨收": 0.222,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1671,
        "symbol": "08452",
        "name": "富银融资股份",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.4,
        "最低": 0.4,
        "昨收": 0.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1672,
        "symbol": "08450",
        "name": "钜京控股",
        "最新价": 0.044,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.044,
        "最高": 0.044,
        "最低": 0.044,
        "昨收": 0.044,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1673,
        "symbol": "08447",
        "name": "MS CONCEPT",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.043,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1674,
        "symbol": "08445",
        "name": "怡康泰工程集团",
        "最新价": 0.375,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.375,
        "最高": 0.375,
        "最低": 0.375,
        "昨收": 0.375,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1675,
        "symbol": "08439",
        "name": "新百利融资",
        "最新价": 0.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.72,
        "最高": 0.72,
        "最低": 0.72,
        "昨收": 0.72,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1676,
        "symbol": "08437",
        "name": "德斯控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.103,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1677,
        "symbol": "08432",
        "name": "太平洋酒吧",
        "最新价": 0.058,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.058,
        "最高": 0.058,
        "最低": 0.058,
        "昨收": 0.058,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1678,
        "symbol": "08430",
        "name": "春能控股",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.26,
        "昨收": 0.26,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1679,
        "symbol": "08429",
        "name": "华美乐乐",
        "最新价": 0.036,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.036,
        "最高": 0.036,
        "最低": 0.036,
        "昨收": 0.036,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1680,
        "symbol": "08427",
        "name": "瑞强集团",
        "最新价": 0.082,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.082,
        "最高": 0.082,
        "最低": 0.082,
        "昨收": 0.082,
        "成交量": 18000.0,
        "成交额": 1476.0
    },
    {
        "序号": 1681,
        "symbol": "08426",
        "name": "雅居投资控股",
        "最新价": 0.159,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.159,
        "最高": 0.159,
        "最低": 0.159,
        "昨收": 0.159,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1682,
        "symbol": "08423",
        "name": "CHI HO DEV",
        "最新价": 0.062,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.063,
        "最高": 0.065,
        "最低": 0.062,
        "昨收": 0.062,
        "成交量": 910000.0,
        "成交额": 56860.0
    },
    {
        "序号": 1683,
        "symbol": "08422",
        "name": "WT集团",
        "最新价": 0.074,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.074,
        "最高": 0.074,
        "最低": 0.074,
        "昨收": 0.074,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1684,
        "symbol": "08420",
        "name": "NEXION TECH",
        "最新价": 0.031,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.031,
        "最高": 0.031,
        "最低": 0.031,
        "昨收": 0.031,
        "成交量": 70000.0,
        "成交额": 2170.0
    },
    {
        "序号": 1685,
        "symbol": "08419",
        "name": "AV策划推广",
        "最新价": 0.121,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.121,
        "最高": 0.121,
        "最低": 0.121,
        "昨收": 0.121,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1686,
        "symbol": "08418",
        "name": "傲迪玛汽车",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.62,
        "最高": 0.64,
        "最低": 0.62,
        "昨收": 0.63,
        "成交量": 166000.0,
        "成交额": 104300.0
    },
    {
        "序号": 1687,
        "symbol": "08417",
        "name": "大地教育",
        "最新价": 0.018,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.018,
        "最高": 0.018,
        "最低": 0.018,
        "昨收": 0.018,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1688,
        "symbol": "08412",
        "name": "新爱德集团",
        "最新价": 1.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.02,
        "最高": 1.02,
        "最低": 1.01,
        "昨收": 1.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1689,
        "symbol": "08411",
        "name": "K W NELSON GP",
        "最新价": 0.106,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.106,
        "最高": 0.106,
        "最低": 0.106,
        "昨收": 0.106,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1690,
        "symbol": "08403",
        "name": "天平道合",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.53,
        "昨收": 0.53,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1691,
        "symbol": "08402",
        "name": "高原之宝",
        "最新价": 0.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.88,
        "最高": 0.93,
        "最低": 0.86,
        "昨收": 0.88,
        "成交量": 1790000.0,
        "成交额": 1583000.0
    },
    {
        "序号": 1692,
        "symbol": "08400",
        "name": "亚洲先锋娱乐",
        "最新价": 0.041,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.041,
        "最高": 0.041,
        "最低": 0.041,
        "昨收": 0.041,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1693,
        "symbol": "08385",
        "name": "万里印刷",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.23,
        "昨收": 0.23,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1694,
        "symbol": "08383",
        "name": "东骏控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.056,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1695,
        "symbol": "08377",
        "name": "维港育马",
        "最新价": 0.178,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.178,
        "最高": 0.178,
        "最低": 0.178,
        "昨收": 0.178,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1696,
        "symbol": "08375",
        "name": "弘浩国际控股",
        "最新价": 0.135,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.135,
        "最高": 0.135,
        "最低": 0.135,
        "昨收": 0.135,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1697,
        "symbol": "08371",
        "name": "尝高美集团",
        "最新价": 1.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.3,
        "最高": 1.3,
        "最低": 1.3,
        "昨收": 1.3,
        "成交量": 40000.0,
        "成交额": 52000.0
    },
    {
        "序号": 1698,
        "symbol": "08368",
        "name": "中国创意控股",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1699,
        "symbol": "08365",
        "name": "亦辰集团",
        "最新价": 0.036,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.036,
        "最高": 0.036,
        "最低": 0.036,
        "昨收": 0.036,
        "成交量": 475000.0,
        "成交额": 17100.0
    },
    {
        "序号": 1700,
        "symbol": "08363",
        "name": "SDM教育",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.08,
        "昨收": 0.08,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1701,
        "symbol": "08362",
        "name": "运兴泰集团",
        "最新价": 0.028,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.028,
        "最高": 0.028,
        "最低": 0.028,
        "昨收": 0.028,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1702,
        "symbol": "08360",
        "name": "利骏集团香港",
        "最新价": 0.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.93,
        "最高": 0.95,
        "最低": 0.89,
        "昨收": 0.95,
        "成交量": 510800.0,
        "成交额": 475752.0
    },
    {
        "序号": 1703,
        "symbol": "08356",
        "name": "中国新华电视",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.012,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1704,
        "symbol": "08353",
        "name": "安科系统",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.26,
        "昨收": 0.26,
        "成交量": 498000.0,
        "成交额": 129480.0
    },
    {
        "序号": 1705,
        "symbol": "08349",
        "name": "硅鑫集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.07,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1706,
        "symbol": "08348",
        "name": "滨海泰达物流",
        "最新价": 0.325,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.325,
        "最高": 0.325,
        "最低": 0.325,
        "昨收": 0.325,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1707,
        "symbol": "08341",
        "name": "艾硕控股",
        "最新价": 0.229,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.229,
        "最高": 0.229,
        "最低": 0.229,
        "昨收": 0.229,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1708,
        "symbol": "08340",
        "name": "紫荆国际金融",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.27,
        "昨收": 0.275,
        "成交量": 60000.0,
        "成交额": 16450.0
    },
    {
        "序号": 1709,
        "symbol": "08337",
        "name": "直通电讯",
        "最新价": 0.131,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.131,
        "最高": 0.131,
        "最低": 0.131,
        "昨收": 0.131,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1710,
        "symbol": "08333",
        "name": "阿仕特朗金融",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.26,
        "昨收": 0.26,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1711,
        "symbol": "08331",
        "name": "倍搏集团",
        "最新价": 0.135,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.135,
        "最高": 0.135,
        "最低": 0.135,
        "昨收": 0.135,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1712,
        "symbol": "08325",
        "name": "中国支付通",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.036,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1713,
        "symbol": "08321",
        "name": "泰锦控股",
        "最新价": 0.184,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.184,
        "最高": 0.184,
        "最低": 0.184,
        "昨收": 0.184,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1714,
        "symbol": "08319",
        "name": "思博系统",
        "最新价": 0.101,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.101,
        "最高": 0.101,
        "最低": 0.101,
        "昨收": 0.101,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1715,
        "symbol": "08315",
        "name": "长城汇理",
        "最新价": 0.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.12,
        "最高": 0.12,
        "最低": 0.12,
        "昨收": 0.12,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1716,
        "symbol": "08313",
        "name": "杰地集团",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 190000.0,
        "成交额": 6270.0
    },
    {
        "序号": 1717,
        "symbol": "08309",
        "name": "万成环球控股",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.08,
        "昨收": 0.08,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1718,
        "symbol": "08308",
        "name": "古兜控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.237,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1719,
        "symbol": "08304",
        "name": "STOCK8304",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1720,
        "symbol": "08303",
        "name": "STOCK8303",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1721,
        "symbol": "08302",
        "name": "STOCK8302",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1722,
        "symbol": "08299",
        "name": "大唐潼金",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.149,
        "最高": 0.156,
        "最低": 0.148,
        "昨收": 0.15,
        "成交量": 21672000.0,
        "成交额": 3281160.0
    },
    {
        "序号": 1723,
        "symbol": "08293",
        "name": "星亚控股",
        "最新价": 0.187,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.187,
        "最高": 0.187,
        "最低": 0.187,
        "昨收": 0.187,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1724,
        "symbol": "08291",
        "name": "万成金属包装",
        "最新价": 0.201,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.201,
        "最高": 0.201,
        "最低": 0.201,
        "昨收": 0.201,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1725,
        "symbol": "08290",
        "name": "亚势备份",
        "最新价": 0.022,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.022,
        "最高": 0.022,
        "最低": 0.022,
        "昨收": 0.022,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1726,
        "symbol": "08285",
        "name": "森浩集团",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.043,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1727,
        "symbol": "08281",
        "name": "中国金典集团",
        "最新价": 0.199,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.199,
        "最高": 0.199,
        "最低": 0.199,
        "昨收": 0.199,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1728,
        "symbol": "08277",
        "name": "骏东控股",
        "最新价": 0.062,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.062,
        "最高": 0.062,
        "最低": 0.062,
        "昨收": 0.062,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1729,
        "symbol": "08271",
        "name": "环球数码创意",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.079,
        "昨收": 0.08,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1730,
        "symbol": "08270",
        "name": "中国煤层气",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.8,
        "昨收": 0.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1731,
        "symbol": "08268",
        "name": "智城发展控股",
        "最新价": 0.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.45,
        "昨收": 0.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1732,
        "symbol": "08267",
        "name": "蓝港互动",
        "最新价": 0.193,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.193,
        "最高": 0.193,
        "最低": 0.193,
        "昨收": 0.193,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1733,
        "symbol": "08257",
        "name": "靖洋集团",
        "最新价": 0.067,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.067,
        "最高": 0.067,
        "最低": 0.067,
        "昨收": 0.067,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1734,
        "symbol": "08247",
        "name": "中生北控生物科技",
        "最新价": 0.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.84,
        "最高": 0.84,
        "最低": 0.84,
        "昨收": 0.84,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1735,
        "symbol": "08246",
        "name": "中华燃气",
        "最新价": 0.152,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.152,
        "最高": 0.152,
        "最低": 0.152,
        "昨收": 0.152,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1736,
        "symbol": "08239",
        "name": "首都金融控股",
        "最新价": 0.415,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.415,
        "最高": 0.415,
        "最低": 0.415,
        "昨收": 0.415,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1737,
        "symbol": "08237",
        "name": "华星控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.022,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1738,
        "symbol": "08229",
        "name": "未来数据集团",
        "最新价": 0.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.67,
        "最高": 0.72,
        "最低": 0.67,
        "昨收": 0.68,
        "成交量": 20000.0,
        "成交额": 13750.0
    },
    {
        "序号": 1739,
        "symbol": "08227",
        "name": "海天天线",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1740,
        "symbol": "08225",
        "name": "中国医疗集团",
        "最新价": 0.138,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.138,
        "昨收": 0.138,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1741,
        "symbol": "08222",
        "name": "壹照明",
        "最新价": 0.045,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1742,
        "symbol": "08221",
        "name": "PF GROUP",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.025,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1743,
        "symbol": "08219",
        "name": "恒伟集团控股",
        "最新价": 0.265,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.265,
        "最低": 0.25,
        "昨收": 0.265,
        "成交量": 130000.0,
        "成交额": 33650.0
    },
    {
        "序号": 1744,
        "symbol": "08218",
        "name": "毅高国际控股",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.18,
        "最高": 0.18,
        "最低": 0.18,
        "昨收": 0.18,
        "成交量": 1800.0,
        "成交额": 315.0
    },
    {
        "序号": 1745,
        "symbol": "08217",
        "name": "万民好物",
        "最新价": 0.118,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.118,
        "最高": 0.118,
        "最低": 0.118,
        "昨收": 0.118,
        "成交量": 1250.0,
        "成交额": 125.0
    },
    {
        "序号": 1746,
        "symbol": "08215",
        "name": "FIRST CREDIT",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.108,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1747,
        "symbol": "08213",
        "name": "荣晖控股",
        "最新价": 0.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.42,
        "昨收": 0.42,
        "成交量": 330000.0,
        "成交额": 138600.0
    },
    {
        "序号": 1748,
        "symbol": "08211",
        "name": "浙江永安",
        "最新价": 0.046,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.046,
        "最高": 0.046,
        "最低": 0.046,
        "昨收": 0.046,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1749,
        "symbol": "08210",
        "name": "衍汇亚洲",
        "最新价": 0.044,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.044,
        "最高": 0.044,
        "最低": 0.044,
        "昨收": 0.044,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1750,
        "symbol": "08208",
        "name": "WMCH GLOBAL",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.06,
        "最高": 0.06,
        "最低": 0.06,
        "昨收": 0.06,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1751,
        "symbol": "08206",
        "name": "神通机器人教育",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.022,
        "最高": 0.022,
        "最低": 0.02,
        "昨收": 0.02,
        "成交量": 166000.0,
        "成交额": 3544.0
    },
    {
        "序号": 1752,
        "symbol": "08205",
        "name": "交大慧谷",
        "最新价": 0.159,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.159,
        "最高": 0.159,
        "最低": 0.159,
        "昨收": 0.159,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1753,
        "symbol": "08203",
        "name": "凯顺控股",
        "最新价": 0.211,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.211,
        "最高": 0.211,
        "最低": 0.211,
        "昨收": 0.211,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1754,
        "symbol": "08198",
        "name": "加幂科技",
        "最新价": 0.238,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.238,
        "最高": 0.238,
        "最低": 0.238,
        "昨收": 0.238,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1755,
        "symbol": "08196",
        "name": "中国天亿福",
        "最新价": 0.365,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.365,
        "最高": 0.365,
        "最低": 0.365,
        "昨收": 0.365,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1756,
        "symbol": "08193",
        "name": "亚太金融投资",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.145,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1757,
        "symbol": "08191",
        "name": "鸿伟亚洲",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.249,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1758,
        "symbol": "08189",
        "name": "泰达生物",
        "最新价": 0.097,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.097,
        "最高": 0.097,
        "最低": 0.097,
        "昨收": 0.097,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1759,
        "symbol": "08188",
        "name": "骏杰集团控股",
        "最新价": 0.073,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.073,
        "最高": 0.073,
        "最低": 0.073,
        "昨收": 0.073,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1760,
        "symbol": "08181",
        "name": "时时服务",
        "最新价": 0.071,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.071,
        "最高": 0.071,
        "最低": 0.071,
        "昨收": 0.071,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1761,
        "symbol": "08176",
        "name": "超人智能",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1762,
        "symbol": "08173",
        "name": "客思控股",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.53,
        "昨收": 0.53,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1763,
        "symbol": "08170",
        "name": "全民国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.38,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1764,
        "symbol": "08169",
        "name": "环康集团",
        "最新价": 0.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.03,
        "最高": 0.03,
        "最低": 0.03,
        "昨收": 0.03,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1765,
        "symbol": "08166",
        "name": "中国农业生态",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.05,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1766,
        "symbol": "08162",
        "name": "港银控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.188,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1767,
        "symbol": "08161",
        "name": "医汇集团",
        "最新价": 0.445,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.445,
        "最高": 0.445,
        "最低": 0.445,
        "昨收": 0.445,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1768,
        "symbol": "08160",
        "name": "GOLDWAY EDU",
        "最新价": 0.073,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.073,
        "最高": 0.073,
        "最低": 0.073,
        "昨收": 0.073,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1769,
        "symbol": "08159",
        "name": "新华联合投资",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.123,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1770,
        "symbol": "08156",
        "name": "国药科技股份",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.13,
        "成交量": 20000.0,
        "成交额": 2600.0
    },
    {
        "序号": 1771,
        "symbol": "08152",
        "name": "明梁控股",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.06,
        "最高": 0.06,
        "最低": 0.06,
        "昨收": 0.06,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1772,
        "symbol": "08151",
        "name": "宝申控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.063,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1773,
        "symbol": "08150",
        "name": "无缝绿色",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.08,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1774,
        "symbol": "08149",
        "name": "浩德控股",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.13,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1775,
        "symbol": "08148",
        "name": "悟喜生活",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.066,
        "最高": 0.07,
        "最低": 0.063,
        "昨收": 0.07,
        "成交量": 120000.0,
        "成交额": 8000.0
    },
    {
        "序号": 1776,
        "symbol": "08146",
        "name": "怡园酒业",
        "最新价": 0.156,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.156,
        "最高": 0.156,
        "最低": 0.156,
        "昨收": 0.156,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1777,
        "symbol": "08143",
        "name": "金威医疗",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.21,
        "最高": 0.21,
        "最低": 0.21,
        "昨收": 0.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1778,
        "symbol": "08136",
        "name": "英马斯集团",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.04,
        "最高": 0.04,
        "最低": 0.04,
        "昨收": 0.04,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1779,
        "symbol": "08133",
        "name": "吉盛集团控股",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.76,
        "最高": 0.8,
        "最低": 0.76,
        "昨收": 0.8,
        "成交量": 262000.0,
        "成交额": 199200.0
    },
    {
        "序号": 1780,
        "symbol": "08130",
        "name": "大地国际集团",
        "最新价": 0.022,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.021,
        "最高": 0.022,
        "最低": 0.021,
        "昨收": 0.022,
        "成交量": 100000.0,
        "成交额": 2180.0
    },
    {
        "序号": 1781,
        "symbol": "08128",
        "name": "中国恒有源集团",
        "最新价": 0.072,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.072,
        "最高": 0.072,
        "最低": 0.072,
        "昨收": 0.072,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1782,
        "symbol": "08126",
        "name": "G.A.控股",
        "最新价": 0.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.13,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1783,
        "symbol": "08123",
        "name": "华亿金控",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.01,
        "最高": 0.01,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1784,
        "symbol": "08118",
        "name": "濠亮环球",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1785,
        "symbol": "08117",
        "name": "中国基础能源",
        "最新价": 0.036,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.036,
        "最高": 0.036,
        "最低": 0.036,
        "昨收": 0.036,
        "成交量": 30000.0,
        "成交额": 1080.0
    },
    {
        "序号": 1786,
        "symbol": "08115",
        "name": "上海青浦消防",
        "最新价": 0.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.43,
        "昨收": 0.43,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1787,
        "symbol": "08113",
        "name": "扬宇科技",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.08,
        "昨收": 0.08,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1788,
        "symbol": "08112",
        "name": "基石金融",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1789,
        "symbol": "08111",
        "name": "中国科技产业集团",
        "最新价": 0.081,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.081,
        "最高": 0.081,
        "最低": 0.081,
        "昨收": 0.081,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1790,
        "symbol": "08107",
        "name": "威诚国际控股",
        "最新价": 5.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.75,
        "最高": 5.76,
        "最低": 5.74,
        "昨收": 5.75,
        "成交量": 170000.0,
        "成交额": 977500.0
    },
    {
        "序号": 1791,
        "symbol": "08103",
        "name": "HMVOD视频",
        "最新价": 1.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.24,
        "最高": 1.24,
        "最低": 1.24,
        "昨收": 1.24,
        "成交量": 51000.0,
        "成交额": 63240.0
    },
    {
        "序号": 1792,
        "symbol": "08100",
        "name": "智易控股",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.19,
        "最高": 0.19,
        "最低": 0.19,
        "昨收": 0.19,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1793,
        "symbol": "08098",
        "name": "昌利控股",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.043,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1794,
        "symbol": "08096",
        "name": "赏之味",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.31,
        "昨收": 0.31,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1795,
        "symbol": "08095",
        "name": "北大青鸟环宇",
        "最新价": 0.315,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.315,
        "最低": 0.3,
        "昨收": 0.315,
        "成交量": 1161000.0,
        "成交额": 354325.0
    },
    {
        "序号": 1796,
        "symbol": "08093",
        "name": "万星控股",
        "最新价": 0.121,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.121,
        "最高": 0.121,
        "最低": 0.121,
        "昨收": 0.121,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1797,
        "symbol": "08092",
        "name": "ITE HOLDINGS",
        "最新价": 0.026,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.026,
        "最高": 0.026,
        "最低": 0.026,
        "昨收": 0.026,
        "成交量": 4000.0,
        "成交额": 104.0
    },
    {
        "序号": 1798,
        "symbol": "08091",
        "name": "奥传思维控股",
        "最新价": 0.037,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.037,
        "最低": 0.037,
        "昨收": 0.037,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1799,
        "symbol": "08087",
        "name": "中国三三传媒",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.11,
        "最高": 0.11,
        "最低": 0.11,
        "昨收": 0.11,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1800,
        "symbol": "08082",
        "name": "光尚文化控股",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 2010000.0,
        "成交额": 66330.0
    },
    {
        "序号": 1801,
        "symbol": "08081",
        "name": "恒泰裕集团",
        "最新价": 0.105,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.104,
        "最高": 0.105,
        "最低": 0.095,
        "昨收": 0.105,
        "成交量": 10272000.0,
        "成交额": 1076880.0
    },
    {
        "序号": 1802,
        "symbol": "08080",
        "name": "北亚策略",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.26,
        "昨收": 0.26,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1803,
        "symbol": "08076",
        "name": "新利软件",
        "最新价": 0.014,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.014,
        "最高": 0.014,
        "最低": 0.014,
        "昨收": 0.014,
        "成交量": 10000.0,
        "成交额": 140.0
    },
    {
        "序号": 1804,
        "symbol": "08072",
        "name": "罗马元宇宙集团",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.04,
        "最高": 0.04,
        "最低": 0.04,
        "昨收": 0.04,
        "成交量": 180000.0,
        "成交额": 7200.0
    },
    {
        "序号": 1805,
        "symbol": "08071",
        "name": "中彩网通控股",
        "最新价": 0.023,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.023,
        "最高": 0.023,
        "最低": 0.023,
        "昨收": 0.023,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1806,
        "symbol": "08067",
        "name": "东方大学城控股",
        "最新价": 0.365,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.365,
        "最高": 0.365,
        "最低": 0.365,
        "昨收": 0.365,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1807,
        "symbol": "08065",
        "name": "高萌科技",
        "最新价": 0.114,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.114,
        "最高": 0.114,
        "最低": 0.113,
        "昨收": 0.114,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1808,
        "symbol": "08063",
        "name": "环球大通集团",
        "最新价": 0.048,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.048,
        "最高": 0.048,
        "最低": 0.048,
        "昨收": 0.048,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1809,
        "symbol": "08062",
        "name": "俊盟国际",
        "最新价": 0.239,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.239,
        "最高": 0.239,
        "最低": 0.239,
        "昨收": 0.239,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1810,
        "symbol": "08060",
        "name": "国联通信",
        "最新价": 0.065,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.065,
        "昨收": 0.065,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1811,
        "symbol": "08051",
        "name": "讯智海",
        "最新价": 3.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.3,
        "最高": 3.3,
        "最低": 3.3,
        "昨收": 3.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1812,
        "symbol": "08050",
        "name": "量子思维",
        "最新价": 0.138,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.138,
        "昨收": 0.138,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1813,
        "symbol": "08045",
        "name": "南大苏富特",
        "最新价": 0.131,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.131,
        "最高": 0.131,
        "最低": 0.131,
        "昨收": 0.131,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1814,
        "symbol": "08041",
        "name": "荟萃国际(控股)",
        "最新价": 0.248,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.26,
        "最低": 0.248,
        "昨收": 0.248,
        "成交量": 140000.0,
        "成交额": 35020.0
    },
    {
        "序号": 1815,
        "symbol": "08039",
        "name": "中国来骑哦",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.62,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1816,
        "symbol": "08037",
        "name": "中国生物科技服务",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1817,
        "symbol": "08033",
        "name": "爱达利网络",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.145,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1818,
        "symbol": "08031",
        "name": "易通讯集团",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.39,
        "最高": 0.39,
        "最低": 0.39,
        "昨收": 0.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1819,
        "symbol": "08030",
        "name": "汇联金融服务",
        "最新价": 0.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.35,
        "最高": 0.35,
        "最低": 0.35,
        "昨收": 0.35,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1820,
        "symbol": "08029",
        "name": "帝国金融集团",
        "最新价": 0.045,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1821,
        "symbol": "08028",
        "name": "天时资源",
        "最新价": 0.019,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.019,
        "最高": 0.019,
        "最低": 0.019,
        "昨收": 0.019,
        "成交量": 1720000.0,
        "成交额": 32680.0
    },
    {
        "序号": 1822,
        "symbol": "08027",
        "name": "吉辉控股",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.23,
        "昨收": 0.23,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1823,
        "symbol": "08023",
        "name": "邝文记",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.39,
        "最高": 0.39,
        "最低": 0.39,
        "昨收": 0.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1824,
        "symbol": "08019",
        "name": "皓文控股",
        "最新价": 0.219,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.219,
        "最高": 0.219,
        "最低": 0.219,
        "昨收": 0.219,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1825,
        "symbol": "08011",
        "name": "百田石油",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.159,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1826,
        "symbol": "08009",
        "name": "华夏能源控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.45,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1827,
        "symbol": "08006",
        "name": "华泰瑞银",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.17,
        "昨收": 0.17,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1828,
        "symbol": "08005",
        "name": "裕兴科技",
        "最新价": 0.129,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.129,
        "最高": 0.129,
        "最低": 0.129,
        "昨收": 0.129,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1829,
        "symbol": "08003",
        "name": "世大控股",
        "最新价": 0.345,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.345,
        "昨收": 0.345,
        "成交量": 6000.0,
        "成交额": 2070.0
    },
    {
        "序号": 1830,
        "symbol": "07373",
        "name": "华夏一倍看空沪深三百",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 5.86,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1831,
        "symbol": "07362",
        "name": "未来一倍看空恒生国指",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 7.5,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1832,
        "symbol": "07336",
        "name": "未来一倍看空恒指",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 6.32,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1833,
        "symbol": "07322",
        "name": "GX一倍看空标普500",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 6.6,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1834,
        "symbol": "07231",
        "name": "未来两倍看多恒指",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 3.428,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1835,
        "symbol": "07230",
        "name": "未来两倍看多恒生国指",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.848,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1836,
        "symbol": "06999",
        "name": "领地控股",
        "最新价": 0.194,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.194,
        "最高": 0.194,
        "最低": 0.194,
        "昨收": 0.194,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1837,
        "symbol": "06963",
        "name": "阳光保险",
        "最新价": 4.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.0,
        "最高": 4.05,
        "最低": 3.98,
        "昨收": 4.02,
        "成交量": 887000.0,
        "成交额": 3553480.0
    },
    {
        "序号": 1838,
        "symbol": "06933",
        "name": "新娱科控股",
        "最新价": 0.248,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.248,
        "最高": 0.248,
        "最低": 0.248,
        "昨收": 0.248,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1839,
        "symbol": "06913",
        "name": "华南职业教育",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.4,
        "最低": 0.37,
        "昨收": 0.38,
        "成交量": 6000.0,
        "成交额": 2300.0
    },
    {
        "序号": 1840,
        "symbol": "06909",
        "name": "百得利控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.86,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1841,
        "symbol": "06899",
        "name": "联众",
        "最新价": 0.175,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.175,
        "最高": 0.175,
        "最低": 0.175,
        "昨收": 0.175,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1842,
        "symbol": "06898",
        "name": "中国铝罐",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.51,
        "最高": 0.51,
        "最低": 0.51,
        "昨收": 0.51,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1843,
        "symbol": "06882",
        "name": "东瀛游",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.53,
        "昨收": 0.56,
        "成交量": 34000.0,
        "成交额": 18680.0
    },
    {
        "序号": 1844,
        "symbol": "06880",
        "name": "腾邦控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.134,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1845,
        "symbol": "06877",
        "name": "CLSA PREMIUM",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.07,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1846,
        "symbol": "06869",
        "name": "长飞光纤光缆",
        "最新价": 8.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 8.56,
        "最高": 8.64,
        "最低": 8.48,
        "昨收": 8.6,
        "成交量": 2299000.0,
        "成交额": 19603175.0
    },
    {
        "序号": 1847,
        "symbol": "06866",
        "name": "佐力小贷",
        "最新价": 0.305,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.305,
        "最高": 0.305,
        "最低": 0.305,
        "昨收": 0.305,
        "成交量": 6000.0,
        "成交额": 1890.0
    },
    {
        "序号": 1848,
        "symbol": "06860",
        "name": "指尖悦动",
        "最新价": 0.08,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.076,
        "最高": 0.08,
        "最低": 0.075,
        "昨收": 0.08,
        "成交量": 285000.0,
        "成交额": 21635.0
    },
    {
        "序号": 1849,
        "symbol": "06838",
        "name": "盈利时",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.75,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1850,
        "symbol": "06833",
        "name": "兴科蓉医药",
        "最新价": 0.265,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.265,
        "最低": 0.246,
        "昨收": 0.265,
        "成交量": 40000.0,
        "成交额": 10084.0
    },
    {
        "序号": 1851,
        "symbol": "06829",
        "name": "龙升集团控股",
        "最新价": 0.121,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.121,
        "最高": 0.121,
        "最低": 0.121,
        "昨收": 0.121,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1852,
        "symbol": "06828",
        "name": "北京燃气蓝天",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 56000.0,
        "成交额": 3072.0
    },
    {
        "序号": 1853,
        "symbol": "06822",
        "name": "科劲国际",
        "最新价": 0.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.68,
        "最高": 0.68,
        "最低": 0.68,
        "昨收": 0.68,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1854,
        "symbol": "06818",
        "name": "中国光大银行",
        "最新价": 2.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.2,
        "最高": 2.21,
        "最低": 2.18,
        "昨收": 2.2,
        "成交量": 5995000.0,
        "成交额": 13170981.0
    },
    {
        "序号": 1855,
        "symbol": "06812",
        "name": "永顺控股香港",
        "最新价": 0.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.2,
        "最高": 0.2,
        "最低": 0.2,
        "昨收": 0.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1856,
        "symbol": "06811",
        "name": "太兴集团",
        "最新价": 0.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.81,
        "最高": 0.81,
        "最低": 0.79,
        "昨收": 0.79,
        "成交量": 257000.0,
        "成交额": 204050.0
    },
    {
        "序号": 1857,
        "symbol": "06808",
        "name": "高鑫零售",
        "最新价": 1.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.3,
        "最高": 1.32,
        "最低": 1.28,
        "昨收": 1.29,
        "成交量": 2719000.0,
        "成交额": 3531510.0
    },
    {
        "序号": 1858,
        "symbol": "06805",
        "name": "金茂源环保",
        "最新价": 0.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.94,
        "最高": 0.94,
        "最低": 0.94,
        "昨收": 0.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1859,
        "symbol": "06772",
        "name": "PRC B3311",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1860,
        "symbol": "06771",
        "name": "PRC B2811",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1861,
        "symbol": "06770",
        "name": "PRC B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1862,
        "symbol": "06769",
        "name": "PRC B5110",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1863,
        "symbol": "06768",
        "name": "PRC B3110",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1864,
        "symbol": "06767",
        "name": "PRC B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1865,
        "symbol": "06766",
        "name": "PRC B2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1866,
        "symbol": "06765",
        "name": "PRC B3511B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1867,
        "symbol": "06764",
        "name": "PRC B3011",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1868,
        "symbol": "06763",
        "name": "PRC B2511B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1869,
        "symbol": "06762",
        "name": "PRC B5010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1870,
        "symbol": "06761",
        "name": "PRC B3010",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1871,
        "symbol": "06760",
        "name": "PRC B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1872,
        "symbol": "06758",
        "name": "PRC B3912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1873,
        "symbol": "06757",
        "name": "PRC B2912",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1874,
        "symbol": "06756",
        "name": "PRC B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1875,
        "symbol": "06754",
        "name": "PRC B4810",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1876,
        "symbol": "06753",
        "name": "PRC B2810",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1877,
        "symbol": "06751",
        "name": "PRC B2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1878,
        "symbol": "06686",
        "name": "诺亚控股",
        "最新价": 19.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 19.58,
        "最高": 19.58,
        "最低": 19.58,
        "昨收": 19.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1879,
        "symbol": "06678",
        "name": "百德医疗",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.4,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1880,
        "symbol": "06668",
        "name": "星盛商业",
        "最新价": 1.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.16,
        "最高": 1.16,
        "最低": 1.15,
        "昨收": 1.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1881,
        "symbol": "06633",
        "name": "青瓷游戏",
        "最新价": 3.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.8,
        "最高": 3.8,
        "最低": 3.8,
        "昨收": 3.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1882,
        "symbol": "06199",
        "name": "贵州银行",
        "最新价": 1.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.44,
        "最高": 1.44,
        "最低": 1.44,
        "昨收": 1.44,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1883,
        "symbol": "06196",
        "name": "郑州银行",
        "最新价": 0.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.81,
        "最高": 0.82,
        "最低": 0.8,
        "昨收": 0.81,
        "成交量": 10540000.0,
        "成交额": 8518080.0
    },
    {
        "序号": 1884,
        "symbol": "06190",
        "name": "九江银行",
        "最新价": 6.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.4,
        "最高": 6.4,
        "最低": 6.4,
        "昨收": 6.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1885,
        "symbol": "06188",
        "name": "迪信通",
        "最新价": 1.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.81,
        "最高": 1.81,
        "最低": 1.81,
        "昨收": 1.81,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1886,
        "symbol": "06182",
        "name": "乙德投资控股",
        "最新价": 0.125,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.125,
        "最高": 0.125,
        "最低": 0.125,
        "昨收": 0.125,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1887,
        "symbol": "06162",
        "name": "天瑞汽车内饰",
        "最新价": 0.076,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.076,
        "最高": 0.076,
        "最低": 0.075,
        "昨收": 0.076,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1888,
        "symbol": "06123",
        "name": "圆通国际快递",
        "最新价": 1.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.23,
        "最高": 1.23,
        "最低": 1.15,
        "昨收": 1.2,
        "成交量": 94000.0,
        "成交额": 113320.0
    },
    {
        "序号": 1889,
        "symbol": "06119",
        "name": "天源集团",
        "最新价": 0.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.34,
        "最高": 0.34,
        "最低": 0.34,
        "昨收": 0.34,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1890,
        "symbol": "06116",
        "name": "拉夏贝尔",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.25,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1891,
        "symbol": "06113",
        "name": "UTS MARKETING",
        "最新价": 0.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.9,
        "最高": 0.9,
        "最低": 0.9,
        "昨收": 0.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1892,
        "symbol": "06111",
        "name": "大发地产",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.093,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1893,
        "symbol": "06083",
        "name": "环宇物流(亚洲)",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.29,
        "昨收": 0.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1894,
        "symbol": "06080",
        "name": "荣智控股",
        "最新价": 0.065,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.065,
        "昨收": 0.065,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1895,
        "symbol": "06068",
        "name": "光正教育",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.3,
        "最高": 0.315,
        "最低": 0.285,
        "昨收": 0.29,
        "成交量": 3162000.0,
        "成交额": 952480.0
    },
    {
        "序号": 1896,
        "symbol": "06038",
        "name": "信越控股",
        "最新价": 0.182,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.182,
        "最高": 0.182,
        "最低": 0.182,
        "昨收": 0.182,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1897,
        "symbol": "06033",
        "name": "电讯数码控股",
        "最新价": 0.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.97,
        "最高": 0.97,
        "最低": 0.97,
        "昨收": 0.97,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 1898,
        "symbol": "06029",
        "name": "CCB N2611B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1899,
        "symbol": "06028",
        "name": "MGM CHINA N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1900,
        "symbol": "06027",
        "name": "JOY TREA B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1901,
        "symbol": "06026",
        "name": "MGM CHINA N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1902,
        "symbol": "06025",
        "name": "MTRC(CI) N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1903,
        "symbol": "06024",
        "name": "CCB N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1904,
        "symbol": "06023",
        "name": "WANDA PPT B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1905,
        "symbol": "06022",
        "name": "WH METRO N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1906,
        "symbol": "06021",
        "name": "PTEROSAUR B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1907,
        "symbol": "06020",
        "name": "BEA UCS C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1908,
        "symbol": "06011",
        "name": "CTNA INV B2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1909,
        "symbol": "06008",
        "name": "ICBC(HK) N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1910,
        "symbol": "06007",
        "name": "LUSO BANK B3405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1911,
        "symbol": "06004",
        "name": "PION REW N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1912,
        "symbol": "06003",
        "name": "XIANPORT B2610B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1913,
        "symbol": "06000",
        "name": "IFC DEV N2904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1914,
        "symbol": "05997",
        "name": "AGRBK N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1915,
        "symbol": "05991",
        "name": "AGRBK N2511B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1916,
        "symbol": "05989",
        "name": "CHINA OVS N4310",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1917,
        "symbol": "05986",
        "name": "STANC PSCCSD",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1918,
        "symbol": "05983",
        "name": "AGRBK N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1919,
        "symbol": "05982",
        "name": "BOCL N2904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1920,
        "symbol": "05981",
        "name": "JINAN ST B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1921,
        "symbol": "05980",
        "name": "SINOPEC N4310",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1922,
        "symbol": "05978",
        "name": "BOCL N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1923,
        "symbol": "05977",
        "name": "CGNPC INT N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1924,
        "symbol": "05976",
        "name": "HF19 I B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1925,
        "symbol": "05974",
        "name": "BOCL N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1926,
        "symbol": "05973",
        "name": "RZ XLS B2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1927,
        "symbol": "05971",
        "name": "CMB ILM N2907",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1928,
        "symbol": "05970",
        "name": "CMB ILM N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1929,
        "symbol": "05969",
        "name": "DAHSING B N3311",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1930,
        "symbol": "05963",
        "name": "TENCENT N4904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1931,
        "symbol": "05962",
        "name": "TENCENT N2904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 60000.0,
        "成交额": 5625900.0
    },
    {
        "序号": 1932,
        "symbol": "05961",
        "name": "TENCENT N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1933,
        "symbol": "05960",
        "name": "TENCENT N2404B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1934,
        "symbol": "05958",
        "name": "MTR CORP N4306",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1935,
        "symbol": "05957",
        "name": "TENCENT N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1936,
        "symbol": "05955",
        "name": "STATEGRID N4305",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1937,
        "symbol": "05949",
        "name": "GX FN INV B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1938,
        "symbol": "05948",
        "name": "CHINA SCE N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1939,
        "symbol": "05944",
        "name": "AIA GROUP N2904",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1940,
        "symbol": "05942",
        "name": "CNOOC F13 N4305",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1941,
        "symbol": "05940",
        "name": "RW INTL B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 40000.0,
        "成交额": 3919000.0
    },
    {
        "序号": 1942,
        "symbol": "05939",
        "name": "ITG HLG B2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1943,
        "symbol": "05938",
        "name": "FJ ZLG N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1944,
        "symbol": "05936",
        "name": "LINK CB B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1945,
        "symbol": "05934",
        "name": "JN HTIC B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1946,
        "symbol": "05932",
        "name": "CDWJ B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1947,
        "symbol": "05931",
        "name": "VERTEX B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1948,
        "symbol": "05930",
        "name": "SINO CAP N4304",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 60000.0,
        "成交额": 5138700.0
    },
    {
        "序号": 1949,
        "symbol": "05928",
        "name": "JN LIXIA B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1950,
        "symbol": "05926",
        "name": "CHINA EDU B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1951,
        "symbol": "05925",
        "name": "CIFI HLDG N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1952,
        "symbol": "05921",
        "name": "XIAN PORT B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1953,
        "symbol": "05919",
        "name": "ICBC N2610D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1954,
        "symbol": "05918",
        "name": "ICBC N2610C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1955,
        "symbol": "05916",
        "name": "POLY RE B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1956,
        "symbol": "05915",
        "name": "ICBC N2610B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1957,
        "symbol": "05914",
        "name": "BOCOM N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1958,
        "symbol": "05911",
        "name": "TR SOCO B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1959,
        "symbol": "05910",
        "name": "SR CITY B2610B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1960,
        "symbol": "05908",
        "name": "CITIC TEL B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1961,
        "symbol": "05905",
        "name": "HKMC FRN N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1962,
        "symbol": "05903",
        "name": "JOY TREA B2903",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1963,
        "symbol": "05901",
        "name": "JOY TREA B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1964,
        "symbol": "05897",
        "name": "CINDA FIN N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1965,
        "symbol": "05896",
        "name": "BOCL N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1966,
        "symbol": "05895",
        "name": "MYS SSB C4504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1967,
        "symbol": "05894",
        "name": "MYS SSB C2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1968,
        "symbol": "05893",
        "name": "SR CITY B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1969,
        "symbol": "05892",
        "name": "CDUCI B2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1970,
        "symbol": "05891",
        "name": "CITIC LTD N3504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1971,
        "symbol": "05890",
        "name": "HKMC N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1972,
        "symbol": "05887",
        "name": "PETRONAS N4503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1973,
        "symbol": "05886",
        "name": "PETRONAS N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1974,
        "symbol": "05884",
        "name": "MTRC N2509E",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1975,
        "symbol": "05882",
        "name": "MTRC N2509D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1976,
        "symbol": "05881",
        "name": "MTRC(CI) N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1977,
        "symbol": "05879",
        "name": "AIA GROUP N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1978,
        "symbol": "05878",
        "name": "HKMC N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1979,
        "symbol": "05876",
        "name": "AMTD GRP N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1980,
        "symbol": "05875",
        "name": "HKINTQD B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1981,
        "symbol": "05874",
        "name": "MTRC N2509C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1982,
        "symbol": "05873",
        "name": "TENCENT N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1983,
        "symbol": "05872",
        "name": "JINSHINE B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1984,
        "symbol": "05871",
        "name": "UGAOXIN B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1985,
        "symbol": "05870",
        "name": "CEBSYD N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1986,
        "symbol": "05869",
        "name": "SINOOCEAN N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1987,
        "symbol": "05868",
        "name": "MTRC N2509B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1988,
        "symbol": "05867",
        "name": "BOCL N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1989,
        "symbol": "05866",
        "name": "TRT URB B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1990,
        "symbol": "05864",
        "name": "MTRC N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1991,
        "symbol": "05863",
        "name": "SHENHUA B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1992,
        "symbol": "05862",
        "name": "ZENSUN EN N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1993,
        "symbol": "05861",
        "name": "AMTD GRP N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1994,
        "symbol": "05860",
        "name": "CEBHK N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1995,
        "symbol": "05859",
        "name": "HUARONG F N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1996,
        "symbol": "05858",
        "name": "AIA GROUP RSPSC",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1997,
        "symbol": "05857",
        "name": "HKMC N2809",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1998,
        "symbol": "05856",
        "name": "NWD FIN GSPCSA",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 1999,
        "symbol": "05855",
        "name": "SKYFAME B3111",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2000,
        "symbol": "05854",
        "name": "HKMC N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2001,
        "symbol": "05852",
        "name": "VKEREHK N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2002,
        "symbol": "05850",
        "name": "CDBL FND1 N2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2003,
        "symbol": "05849",
        "name": "TZ URBAN B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2004,
        "symbol": "05848",
        "name": "XIANJIN B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2005,
        "symbol": "05847",
        "name": "ZHAOHAI B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2006,
        "symbol": "05846",
        "name": "LEAD AFFL N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2007,
        "symbol": "05845",
        "name": "BABA N3411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2008,
        "symbol": "05844",
        "name": "BABA N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2009,
        "symbol": "05842",
        "name": "HAIYAN B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2010,
        "symbol": "05841",
        "name": "ICBCIL FN N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2011,
        "symbol": "05839",
        "name": "BLK PEONY B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2012,
        "symbol": "05838",
        "name": "PY INV B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2013,
        "symbol": "05837",
        "name": "BOCOM N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2014,
        "symbol": "05836",
        "name": "ZJ BARON B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2015,
        "symbol": "05835",
        "name": "FUYUAN W B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2016,
        "symbol": "05833",
        "name": "YB INTL B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2017,
        "symbol": "05832",
        "name": "CLIO HK B3308",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2018,
        "symbol": "05831",
        "name": "CNCB INTL N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2019,
        "symbol": "05829",
        "name": "YNANEIOF B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2020,
        "symbol": "05828",
        "name": "BOCL N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2021,
        "symbol": "05827",
        "name": "PION REW N2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2022,
        "symbol": "05826",
        "name": "CINDA20 N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2023,
        "symbol": "05825",
        "name": "HZQTNACI B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2024,
        "symbol": "05824",
        "name": "CICC16MTN N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2025,
        "symbol": "05823",
        "name": "KING PWR N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2026,
        "symbol": "05822",
        "name": "NEIJ IH B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2027,
        "symbol": "05821",
        "name": "SKYFAME B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2028,
        "symbol": "05820",
        "name": "HUBEINTD B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2029,
        "symbol": "05819",
        "name": "CR LAND N2910",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2030,
        "symbol": "05818",
        "name": "LUYE PHA B2807",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2031,
        "symbol": "05817",
        "name": "LUSO BANK B3306",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2032,
        "symbol": "05816",
        "name": "LICHENG B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2033,
        "symbol": "05815",
        "name": "NEW MOMEN B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2034,
        "symbol": "05814",
        "name": "CZ INTL B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2035,
        "symbol": "05813",
        "name": "HLAND MTN N2903",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2036,
        "symbol": "05812",
        "name": "H&H INTL N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2037,
        "symbol": "05810",
        "name": "HKMC FRN N2402B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2038,
        "symbol": "05808",
        "name": "JINJIANG B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2039,
        "symbol": "05807",
        "name": "SHKP(CM)L N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2040,
        "symbol": "05806",
        "name": "BOCL N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2041,
        "symbol": "05805",
        "name": "MENGNIU B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2042,
        "symbol": "05804",
        "name": "ZT B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2043,
        "symbol": "05803",
        "name": "FWD GHL N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2044,
        "symbol": "05802",
        "name": "CCB B2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2045,
        "symbol": "05801",
        "name": "XC INTL B2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2046,
        "symbol": "05800",
        "name": "HCG N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2047,
        "symbol": "05799",
        "name": "BJSF AD B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2048,
        "symbol": "05798",
        "name": "YUZHOU N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2049,
        "symbol": "05797",
        "name": "BAUHINIA N4410S",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2050,
        "symbol": "05796",
        "name": "CHARM LGT N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2051,
        "symbol": "05795",
        "name": "BAUHINIA N4410D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2052,
        "symbol": "05794",
        "name": "LINK FIN N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2053,
        "symbol": "05793",
        "name": "FWD GHL CS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2054,
        "symbol": "05792",
        "name": "BAUHINIA N4410C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2055,
        "symbol": "05791",
        "name": "BAUHINIA N4410B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2056,
        "symbol": "05790",
        "name": "BAUHINIA N4410A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2057,
        "symbol": "05789",
        "name": "CCB N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2058,
        "symbol": "05788",
        "name": "GNEPI B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2059,
        "symbol": "05787",
        "name": "CR LAND N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2060,
        "symbol": "05786",
        "name": "CR LAND N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2061,
        "symbol": "05785",
        "name": "GLP HOLD N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2062,
        "symbol": "05784",
        "name": "ACDG B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2063,
        "symbol": "05783",
        "name": "CINDA FIN N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 2000.0,
        "成交额": 191484.0
    },
    {
        "序号": 2064,
        "symbol": "05782",
        "name": "SINOOCEAN N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2065,
        "symbol": "05781",
        "name": "FEC FIN PERP",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2066,
        "symbol": "05780",
        "name": "EXIMCHINA N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2067,
        "symbol": "05779",
        "name": "JSFX IND B2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2068,
        "symbol": "05778",
        "name": "AIA GROUP N3304",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2069,
        "symbol": "05777",
        "name": "LNTDIG B2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2070,
        "symbol": "05776",
        "name": "CITIC PAC N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2071,
        "symbol": "05775",
        "name": "CINDA FIN N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2072,
        "symbol": "05774",
        "name": "IBRD N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2073,
        "symbol": "05772",
        "name": "HKSTP N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2074,
        "symbol": "05771",
        "name": "ZZ TRAN B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2075,
        "symbol": "05769",
        "name": "GRNL GLB B3007",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2076,
        "symbol": "05768",
        "name": "SHENGZHOU B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2077,
        "symbol": "05767",
        "name": "UETD CD N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2078,
        "symbol": "05766",
        "name": "20191WST N5008",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2079,
        "symbol": "05765",
        "name": "YCDFHC B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2080,
        "symbol": "05763",
        "name": "GHKF N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2081,
        "symbol": "05761",
        "name": "AIR AUTH N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2082,
        "symbol": "05760",
        "name": "CHINA OVS N3406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2083,
        "symbol": "05759",
        "name": "BEA N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2084,
        "symbol": "05758",
        "name": "HKMC FRN N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2085,
        "symbol": "05757",
        "name": "JINJIANG B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2086,
        "symbol": "05756",
        "name": "C TAIPING PSCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2087,
        "symbol": "05755",
        "name": "CH AGRI-P N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2088,
        "symbol": "05754",
        "name": "WYNN MAC B2903",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2089,
        "symbol": "05752",
        "name": "NANYANG NCSCS C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2090,
        "symbol": "05750",
        "name": "ICBCMACAU N2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2091,
        "symbol": "05749",
        "name": "TOWNGAS PSGSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2092,
        "symbol": "05748",
        "name": "CINDA FIN N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 4000.0,
        "成交额": 399260.0
    },
    {
        "序号": 2093,
        "symbol": "05747",
        "name": "WHARFREIC N2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2094,
        "symbol": "05746",
        "name": "CHINA OVS N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2095,
        "symbol": "05745",
        "name": "CHALCO CH SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2096,
        "symbol": "05743",
        "name": "STATEGRID N4405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2097,
        "symbol": "05742",
        "name": "STATEGRID N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2098,
        "symbol": "05741",
        "name": "SHA PG B2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2099,
        "symbol": "05740",
        "name": "DTCX INV B2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2100,
        "symbol": "05739",
        "name": "CNOOC F14 N4404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2101,
        "symbol": "05738",
        "name": "CNOOC F14 N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2102,
        "symbol": "05735",
        "name": "GZSL ADI B2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2103,
        "symbol": "05734",
        "name": "SHA PG B2409",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2104,
        "symbol": "05733",
        "name": "SANDS N2808B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2105,
        "symbol": "05732",
        "name": "LOGAN GRP N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2106,
        "symbol": "05729",
        "name": "BOCOM LM N2409B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2107,
        "symbol": "05728",
        "name": "BOCOM LM N2409A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2108,
        "symbol": "05727",
        "name": "SANDS N2508B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2109,
        "symbol": "05726",
        "name": "CEBHK N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2110,
        "symbol": "05721",
        "name": "SINOPEC N2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2111,
        "symbol": "05720",
        "name": "HYSAN MTN N2909",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2112,
        "symbol": "05718",
        "name": "CICC16MTN N2603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2113,
        "symbol": "05717",
        "name": "GZ URBAN N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2114,
        "symbol": "05715",
        "name": "GZ URBAN N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2115,
        "symbol": "05713",
        "name": "SHACOMBNK N3302",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2116,
        "symbol": "05710",
        "name": "HKJY FLO B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 8000.0,
        "成交额": 757096.0
    },
    {
        "序号": 2117,
        "symbol": "05708",
        "name": "AIA GROUP N4403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2118,
        "symbol": "05706",
        "name": "CELESTIAL GSPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2119,
        "symbol": "05703",
        "name": "BOCOM N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2120,
        "symbol": "05702",
        "name": "BOCOM N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2121,
        "symbol": "05701",
        "name": "CR LAND N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2122,
        "symbol": "05699",
        "name": "CQ NANAN B2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2123,
        "symbol": "05698",
        "name": "HKMC N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2124,
        "symbol": "05697",
        "name": "CHINDATA N2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2125,
        "symbol": "05695",
        "name": "ZJAJ CT B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2126,
        "symbol": "05693",
        "name": "MTR CORP N4607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2127,
        "symbol": "05692",
        "name": "C RAILWAY N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2128,
        "symbol": "05691",
        "name": "HUANTAIHU B2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2129,
        "symbol": "05690",
        "name": "XIAN AERO B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2130,
        "symbol": "05689",
        "name": "MINMET FN B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2131,
        "symbol": "05685",
        "name": "LINK FIN N2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2132,
        "symbol": "05683",
        "name": "TAISHN CT N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2133,
        "symbol": "05682",
        "name": "GLDDRAGON N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2134,
        "symbol": "05681",
        "name": "ZIBO HT B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2135,
        "symbol": "05680",
        "name": "ICBC N2601A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2136,
        "symbol": "05679",
        "name": "ICBC N2601B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2137,
        "symbol": "05678",
        "name": "ICBC N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2138,
        "symbol": "05677",
        "name": "JY GRDMRK N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2139,
        "symbol": "05676",
        "name": "SUNNY OPT B2607",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2140,
        "symbol": "05675",
        "name": "CD SFEP B2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2141,
        "symbol": "05674",
        "name": "MTRC(CI) N4606B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2142,
        "symbol": "05673",
        "name": "AIR AUTH N3301",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2143,
        "symbol": "05672",
        "name": "CITIC LTD N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2144,
        "symbol": "05670",
        "name": "MTR CORP N4606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2145,
        "symbol": "05669",
        "name": "AIR AUTH N3001",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2146,
        "symbol": "05668",
        "name": "AIR AUTH N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2147,
        "symbol": "05667",
        "name": "MTRC(CI) N4606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2148,
        "symbol": "05666",
        "name": "AIR AUTH N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2149,
        "symbol": "05665",
        "name": "HUARONG F N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2150,
        "symbol": "05664",
        "name": "PZ CT B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2151,
        "symbol": "05663",
        "name": "CHENGDA B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2152,
        "symbol": "05662",
        "name": "LINK CB B2712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2153,
        "symbol": "05661",
        "name": "KM RT B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2154,
        "symbol": "05660",
        "name": "CDB N2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2155,
        "symbol": "05659",
        "name": "DEYANG N2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2156,
        "symbol": "05656",
        "name": "TFI N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2157,
        "symbol": "05655",
        "name": "JINGFA B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2158,
        "symbol": "05654",
        "name": "BOCL N2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2159,
        "symbol": "05653",
        "name": "GL ETDZ B2512",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2160,
        "symbol": "05652",
        "name": "STATEGRID N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2161,
        "symbol": "05650",
        "name": "STATEGRID N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2162,
        "symbol": "05649",
        "name": "GAOXIN B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2163,
        "symbol": "05648",
        "name": "ICBCIL FN N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2164,
        "symbol": "05647",
        "name": "SR INVT B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2165,
        "symbol": "05644",
        "name": "LINJIANG N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2166,
        "symbol": "05643",
        "name": "DX VOYAGE B2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2167,
        "symbol": "05641",
        "name": "PROVENHNR B2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2168,
        "symbol": "05640",
        "name": "HKE FIN N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2169,
        "symbol": "05638",
        "name": "SINOPEC N4605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2170,
        "symbol": "05637",
        "name": "SINOPEC N2605",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2171,
        "symbol": "05636",
        "name": "ZY DAYU N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2172,
        "symbol": "05635",
        "name": "SINOPEC N4908",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2173,
        "symbol": "05634",
        "name": "MYS SGB C4604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2174,
        "symbol": "05633",
        "name": "MYS SGB C2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2175,
        "symbol": "05632",
        "name": "SINOPEC N2908",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2176,
        "symbol": "05631",
        "name": "CITIC LTD N4604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2177,
        "symbol": "05630",
        "name": "CITIC LTD N3604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2178,
        "symbol": "05629",
        "name": "SSLIFE N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 60000.0,
        "成交额": 5416500.0
    },
    {
        "序号": 2179,
        "symbol": "05628",
        "name": "BOCOM N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2180,
        "symbol": "05627",
        "name": "SINOPEC N2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2181,
        "symbol": "05626",
        "name": "SKYFAME B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2182,
        "symbol": "05624",
        "name": "SXYS EDC B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2183,
        "symbol": "05623",
        "name": "SINOOCEAN N2908",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2184,
        "symbol": "05621",
        "name": "BOCL N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2185,
        "symbol": "05620",
        "name": "CGWIH IV UGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2186,
        "symbol": "05619",
        "name": "CICC16MTN N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2187,
        "symbol": "05618",
        "name": "AIA GROUP N4603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2188,
        "symbol": "05617",
        "name": "CGWIH IV B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2189,
        "symbol": "05614",
        "name": "NJ YANGZI B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2190,
        "symbol": "05612",
        "name": "CITIC LTD N4102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2191,
        "symbol": "05611",
        "name": "CITIC LTD N3602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2192,
        "symbol": "05610",
        "name": "ZHENJIANG B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2193,
        "symbol": "05607",
        "name": "KWG GROUP N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2194,
        "symbol": "05605",
        "name": "JN HTIC B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2195,
        "symbol": "05604",
        "name": "SP MTN FN N2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2196,
        "symbol": "05602",
        "name": "SKYFAME B3211",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2197,
        "symbol": "05598",
        "name": "TOP LUX B4012",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2198,
        "symbol": "05597",
        "name": "SHENGZHOU B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2199,
        "symbol": "05596",
        "name": "ZY SINCER B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2200,
        "symbol": "05594",
        "name": "CEL DYNAS N2906",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2201,
        "symbol": "05593",
        "name": "BABA N3411B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2202,
        "symbol": "05592",
        "name": "BABA N2411B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2203,
        "symbol": "05590",
        "name": "YICHUNDIG B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2204,
        "symbol": "05589",
        "name": "JOHNSON E N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2205,
        "symbol": "05588",
        "name": "CDB N2901",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2206,
        "symbol": "05587",
        "name": "XCTRSPRT B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2207,
        "symbol": "05586",
        "name": "DTHM UCG B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2208,
        "symbol": "05583",
        "name": "XL BVI B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2209,
        "symbol": "05581",
        "name": "CRCC CA GPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2210,
        "symbol": "05580",
        "name": "SKYFAME B2409B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2211,
        "symbol": "05579",
        "name": "CDB N2401B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2212,
        "symbol": "05577",
        "name": "HUARONG F N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2213,
        "symbol": "05576",
        "name": "ZJ TRANS B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 160000.0,
        "成交额": 15445000.0
    },
    {
        "序号": 2214,
        "symbol": "05574",
        "name": "ORIENT ZS B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2215,
        "symbol": "05571",
        "name": "HKMC N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2216,
        "symbol": "05568",
        "name": "CNCLN ERG B2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2217,
        "symbol": "05567",
        "name": "SKYFAME B3111B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2218,
        "symbol": "05566",
        "name": "ICBC N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2219,
        "symbol": "05565",
        "name": "SP MTN N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2220,
        "symbol": "05564",
        "name": "CHIYU BNK USCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 2500.0,
        "成交额": 248250.0
    },
    {
        "序号": 2221,
        "symbol": "05562",
        "name": "LENOVO B2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2222,
        "symbol": "05560",
        "name": "AIA GROUP N2710",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2223,
        "symbol": "05559",
        "name": "CD SFEP B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2224,
        "symbol": "05558",
        "name": "WUHAN JX B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2225,
        "symbol": "05557",
        "name": "SYNGENTA N2604",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2226,
        "symbol": "05555",
        "name": "CM FIN N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2227,
        "symbol": "05554",
        "name": "TONGLING B2510",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2228,
        "symbol": "05553",
        "name": "MINMET FN B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2229,
        "symbol": "05551",
        "name": "COSL SG N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2230,
        "symbol": "05550",
        "name": "ORNTL CAP B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2231,
        "symbol": "05549",
        "name": "LICHENG B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2232,
        "symbol": "05547",
        "name": "JGSU ZGC B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2233,
        "symbol": "05546",
        "name": "CMBWL NCSCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2234,
        "symbol": "05545",
        "name": "XIAN WY B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2235,
        "symbol": "05544",
        "name": "HKXY INV N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2236,
        "symbol": "05543",
        "name": "GXLZDC B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2237,
        "symbol": "05542",
        "name": "TENCENT N3507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2238,
        "symbol": "05541",
        "name": "SXSY CIO N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2239,
        "symbol": "05540",
        "name": "JINANEDI B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2240,
        "symbol": "05539",
        "name": "JINKO PT B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2241,
        "symbol": "05538",
        "name": "BOCL N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2242,
        "symbol": "05536",
        "name": "BOCOM LM N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2243,
        "symbol": "05535",
        "name": "LS FIN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2244,
        "symbol": "05532",
        "name": "QINGLUN B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2245,
        "symbol": "05531",
        "name": "JSTENGHAI B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2246,
        "symbol": "05530",
        "name": "SHACOMBNK N2901",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2247,
        "symbol": "05529",
        "name": "FZ DEIG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2248,
        "symbol": "05528",
        "name": "STANCHART B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2249,
        "symbol": "05527",
        "name": "SQED B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2250,
        "symbol": "05526",
        "name": "GZ URBAN N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2251,
        "symbol": "05524",
        "name": "DAHSING B N2901",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2252,
        "symbol": "05523",
        "name": "ZHAOHAI B2509B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2253,
        "symbol": "05522",
        "name": "ZJ TRANS B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2254,
        "symbol": "05521",
        "name": "BSAMHK N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2255,
        "symbol": "05520",
        "name": "ZHAOHAI B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2256,
        "symbol": "05519",
        "name": "CGNPC INT B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2257,
        "symbol": "05516",
        "name": "PROVENHNR B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2258,
        "symbol": "05515",
        "name": "ZZ UDG B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2259,
        "symbol": "05514",
        "name": "JC SOCIO B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2260,
        "symbol": "05511",
        "name": "KUNLUN N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2261,
        "symbol": "05510",
        "name": "XIAN QJCF B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2262,
        "symbol": "05509",
        "name": "CQFL DEV B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2263,
        "symbol": "05508",
        "name": "CNOOCF15U N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2264,
        "symbol": "05507",
        "name": "CNOOCF15A N4505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2265,
        "symbol": "05506",
        "name": "ZHENJIANG B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2266,
        "symbol": "05505",
        "name": "CLP-PF N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2267,
        "symbol": "05502",
        "name": "SINOPEC N4504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2268,
        "symbol": "05501",
        "name": "SINOPEC N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2269,
        "symbol": "05500",
        "name": "CDXS B2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2270,
        "symbol": "05499",
        "name": "RDMINTAI B2508B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2271,
        "symbol": "05485",
        "name": "WEIFANG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2272,
        "symbol": "05483",
        "name": "HTSEC B2312B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2273,
        "symbol": "05482",
        "name": "HTSEC B2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2274,
        "symbol": "05478",
        "name": "CDB N2312",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2275,
        "symbol": "05476",
        "name": "VKEREHK N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2276,
        "symbol": "05463",
        "name": "ZZ URBAN N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2277,
        "symbol": "05459",
        "name": "YI HONG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2278,
        "symbol": "05458",
        "name": "DTHM UCG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2279,
        "symbol": "05456",
        "name": "RD MINTAI B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2280,
        "symbol": "05454",
        "name": "RED STAR B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2281,
        "symbol": "05449",
        "name": "RONGSHI N2705",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2282,
        "symbol": "05447",
        "name": "FRANSHION N2907",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2283,
        "symbol": "05444",
        "name": "HUARONG17 N4704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2284,
        "symbol": "05443",
        "name": "HUARONG17 N2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2285,
        "symbol": "05440",
        "name": "LENOVO B2908",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2286,
        "symbol": "05439",
        "name": "CDUCI B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2287,
        "symbol": "05436",
        "name": "CDJJ INV B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2288,
        "symbol": "05435",
        "name": "XIAN WY B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2289,
        "symbol": "05434",
        "name": "LNTDIG B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2290,
        "symbol": "05433",
        "name": "BOCL N2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2291,
        "symbol": "05430",
        "name": "TJLG INV N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2292,
        "symbol": "05429",
        "name": "ANHUI BY B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2293,
        "symbol": "05428",
        "name": "JSJURONG B2508B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2294,
        "symbol": "05426",
        "name": "SDSC IDL B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2295,
        "symbol": "05425",
        "name": "RH INT FN GSPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2296,
        "symbol": "05424",
        "name": "SUZHOUCT B2508B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2297,
        "symbol": "05423",
        "name": "SINOPEC N4704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2298,
        "symbol": "05422",
        "name": "SINOPEC N2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2299,
        "symbol": "05420",
        "name": "BZ BEDI B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2300,
        "symbol": "05419",
        "name": "WXCK IDG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2301,
        "symbol": "05418",
        "name": "NWD (MTN) N2907",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2302,
        "symbol": "05417",
        "name": "SD CITY B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2303,
        "symbol": "05415",
        "name": "SANDS N3108A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2304,
        "symbol": "05414",
        "name": "SANDS N2903A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2305,
        "symbol": "05413",
        "name": "SANDS N2703A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2306,
        "symbol": "05412",
        "name": "BBGX B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2307,
        "symbol": "05409",
        "name": "PHX CHARM B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2308,
        "symbol": "05408",
        "name": "MY INV B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2309,
        "symbol": "05407",
        "name": "AZURE NVA N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2310,
        "symbol": "05405",
        "name": "SUZHOU CT B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2311,
        "symbol": "05402",
        "name": "JINAN HT B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2312,
        "symbol": "05401",
        "name": "JSJURONG B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2313,
        "symbol": "05398",
        "name": "CINDA FIN N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2314,
        "symbol": "05397",
        "name": "CINDA FIN N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2315,
        "symbol": "05395",
        "name": "TXJJ B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2316,
        "symbol": "05393",
        "name": "XA IN HOR B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2317,
        "symbol": "05392",
        "name": "HENGYUAN B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2318,
        "symbol": "05391",
        "name": "CALC BD3 B2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2319,
        "symbol": "05387",
        "name": "GDDIG B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2320,
        "symbol": "05386",
        "name": "LENOVO N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2321,
        "symbol": "05384",
        "name": "CITIC LTD N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2322,
        "symbol": "05382",
        "name": "LENOVO N3207",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2323,
        "symbol": "05380",
        "name": "TH GOR I N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2324,
        "symbol": "05379",
        "name": "SKYFAME B3311B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2325,
        "symbol": "05378",
        "name": "PROVENGLY N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2326,
        "symbol": "05376",
        "name": "ZZ JLJ GP B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2327,
        "symbol": "05373",
        "name": "PY INTL B2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2328,
        "symbol": "05370",
        "name": "HKMC N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2329,
        "symbol": "05368",
        "name": "DJY MJ B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2330,
        "symbol": "05367",
        "name": "SKYFAME B2609B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2331,
        "symbol": "05366",
        "name": "MENGNIU B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2332,
        "symbol": "05364",
        "name": "BL SKYVIEW SPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2333,
        "symbol": "05363",
        "name": "DL DETA N2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2334,
        "symbol": "05362",
        "name": "FJ ZLG N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2335,
        "symbol": "05360",
        "name": "HUAIAN TH B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2336,
        "symbol": "05359",
        "name": "CDB N2401",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2337,
        "symbol": "05358",
        "name": "GYCIDC B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2338,
        "symbol": "05357",
        "name": "CDB N3701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2339,
        "symbol": "05356",
        "name": "CDB N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2340,
        "symbol": "05353",
        "name": "CDB N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2341,
        "symbol": "05352",
        "name": "GOHL CAP N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2342,
        "symbol": "05346",
        "name": "XA BAQIAO B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2343,
        "symbol": "05345",
        "name": "CGXCIG B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2344,
        "symbol": "05344",
        "name": "YEAHKA B2707",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2345,
        "symbol": "05343",
        "name": "NWCL N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2346,
        "symbol": "05339",
        "name": "CD EDI B2507",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2347,
        "symbol": "05338",
        "name": "C RAILWAY B2707",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2348,
        "symbol": "05337",
        "name": "BEA N2807",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2349,
        "symbol": "05336",
        "name": "CDDAGANG B2506B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2350,
        "symbol": "05335",
        "name": "CDDAGANG B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2351,
        "symbol": "05334",
        "name": "CD ECI B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2352,
        "symbol": "05333",
        "name": "CHARM LGT N2612",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2353,
        "symbol": "05331",
        "name": "LYCT DEV B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2354,
        "symbol": "05330",
        "name": "XIAN WY B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2355,
        "symbol": "05328",
        "name": "LS ETD B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2356,
        "symbol": "05326",
        "name": "XIXIAN JH B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2357,
        "symbol": "05325",
        "name": "SPIC2016 B2612",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2358,
        "symbol": "05322",
        "name": "NEW TOWN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2359,
        "symbol": "05321",
        "name": "NWD (MTN) N2706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2360,
        "symbol": "05320",
        "name": "XF BVI B2506B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2361,
        "symbol": "05319",
        "name": "SHANHAIHK B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2362,
        "symbol": "05318",
        "name": "CHANG DEV B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2363,
        "symbol": "05317",
        "name": "BOCL N2506B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2364,
        "symbol": "05315",
        "name": "GUOHUI B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2365,
        "symbol": "05312",
        "name": "NWD FIN GSPCSE",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2366,
        "symbol": "05311",
        "name": "SKYFAME B3311",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2367,
        "symbol": "05310",
        "name": "SKYFAME B2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2368,
        "symbol": "05309",
        "name": "GRNL GLB N2806",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2369,
        "symbol": "05308",
        "name": "HUARONG F N2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2370,
        "symbol": "05306",
        "name": "HKMC N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2371,
        "symbol": "05305",
        "name": "JZBD B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2372,
        "symbol": "05302",
        "name": "XF BVI B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2373,
        "symbol": "05299",
        "name": "ZS INAP B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2374,
        "symbol": "05296",
        "name": "CDBL FND1 N2710",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2375,
        "symbol": "05294",
        "name": "JMYT DEV B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2376,
        "symbol": "05293",
        "name": "SAN SHUI N2506B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2377,
        "symbol": "05292",
        "name": "SAN SHUI B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2378,
        "symbol": "05291",
        "name": "SAN SHUI N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2379,
        "symbol": "05290",
        "name": "XIANJIN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2380,
        "symbol": "05289",
        "name": "FRANSHION N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2381,
        "symbol": "05288",
        "name": "CDJJ INV B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2382,
        "symbol": "05287",
        "name": "YUZHOU SPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2383,
        "symbol": "05286",
        "name": "WUHAN JX B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2384,
        "symbol": "05285",
        "name": "MTR CORP N4709",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2385,
        "symbol": "05284",
        "name": "HZWX SCID B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2386,
        "symbol": "05280",
        "name": "WYNN MAC N2710",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2387,
        "symbol": "05279",
        "name": "WYNN MAC N2410",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2388,
        "symbol": "05276",
        "name": "SINOOCEAN PSGCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2389,
        "symbol": "05275",
        "name": "CMHI FIN N2706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2390,
        "symbol": "05274",
        "name": "SINOPEC N4709",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2391,
        "symbol": "05273",
        "name": "SINOPEC N2709",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2392,
        "symbol": "05271",
        "name": "MDJ CIG N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2393,
        "symbol": "05268",
        "name": "ZZ TRAN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2394,
        "symbol": "05266",
        "name": "CGWIH III N2708",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2395,
        "symbol": "05264",
        "name": "XN URBAN B2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2396,
        "symbol": "05263",
        "name": "ICBC N2506C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2397,
        "symbol": "05262",
        "name": "PHX LEAD GPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2398,
        "symbol": "05261",
        "name": "CIFI HLDG SPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2399,
        "symbol": "05260",
        "name": "ICBC N2506B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2400,
        "symbol": "05259",
        "name": "ICBC N2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2401,
        "symbol": "05257",
        "name": "ICBC N2506",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2402,
        "symbol": "05253",
        "name": "WXCK IDG B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2403,
        "symbol": "05252",
        "name": "MTR CORP N4707",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2404,
        "symbol": "05251",
        "name": "SIHAI INT B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2405,
        "symbol": "05248",
        "name": "SOAR WISE N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2406,
        "symbol": "05247",
        "name": "SDG FIN PCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2407,
        "symbol": "05246",
        "name": "TZHY TTI B2505B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2408,
        "symbol": "05245",
        "name": "CAPCO FC N2707",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2409,
        "symbol": "05243",
        "name": "CD DAGANG B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2410,
        "symbol": "05241",
        "name": "TZHY TTI B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2411,
        "symbol": "05240",
        "name": "DFZQ N2505B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2412,
        "symbol": "05237",
        "name": "CCB N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2413,
        "symbol": "05235",
        "name": "ENNENERGY N2705",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2414,
        "symbol": "05234",
        "name": "INDSTRLB N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2415,
        "symbol": "05233",
        "name": "CSCEC II N2707",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2416,
        "symbol": "05231",
        "name": "SP MTN N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2417,
        "symbol": "05228",
        "name": "CSJXNDCD B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2418,
        "symbol": "05227",
        "name": "MTR CORP N4706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2419,
        "symbol": "05225",
        "name": "WXHUSHENG B2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2420,
        "symbol": "05224",
        "name": "DFZQ N2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2421,
        "symbol": "05222",
        "name": "XIAN CB B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2422,
        "symbol": "05221",
        "name": "FWD GHL SPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2423,
        "symbol": "05219",
        "name": "CDJJ INV B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2424,
        "symbol": "05216",
        "name": "CDAPXC B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2425,
        "symbol": "05215",
        "name": "HTUTB N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2426,
        "symbol": "05211",
        "name": "MTRC(CI) N3205",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2427,
        "symbol": "05210",
        "name": "BOCL N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2428,
        "symbol": "05209",
        "name": "BOCL N2504B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2429,
        "symbol": "05207",
        "name": "NANYANG NCSCS B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2430,
        "symbol": "05205",
        "name": "CGWIH VI B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2431,
        "symbol": "05202",
        "name": "SINOOCEAN N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2432,
        "symbol": "05200",
        "name": "TCCL FIN B2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2433,
        "symbol": "05195",
        "name": "SZ COMMUN B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2434,
        "symbol": "05191",
        "name": "PY INTL B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2435,
        "symbol": "05190",
        "name": "CNCB INTL UCS D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2436,
        "symbol": "05184",
        "name": "BEA N3204",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2437,
        "symbol": "05183",
        "name": "CSI MTN N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2438,
        "symbol": "05167",
        "name": "CSGIF BVI N2809",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2439,
        "symbol": "05162",
        "name": "SINOPEC N4809",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2440,
        "symbol": "05161",
        "name": "SINOPEC N2809",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2441,
        "symbol": "05160",
        "name": "SINOPEC N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2442,
        "symbol": "05154",
        "name": "CGNPC INT B4809",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2443,
        "symbol": "05148",
        "name": "FSGAOMING B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2444,
        "symbol": "05144",
        "name": "DJ HAIYU SGPSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2445,
        "symbol": "05143",
        "name": "TUSPARK B2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2446,
        "symbol": "05142",
        "name": "SANDS N2808",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2447,
        "symbol": "05141",
        "name": "SANDS N2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2448,
        "symbol": "05135",
        "name": "CMHI FIN N2808",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2449,
        "symbol": "05124",
        "name": "YZ ETDZ B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2450,
        "symbol": "05123",
        "name": "STGRD N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2451,
        "symbol": "05121",
        "name": "STGRD N2704",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2452,
        "symbol": "05120",
        "name": "JSTENGHAI B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2453,
        "symbol": "05118",
        "name": "DQCT CTD N2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2454,
        "symbol": "05117",
        "name": "NJ FTC B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2455,
        "symbol": "05116",
        "name": "DQCT CTD N2504D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2456,
        "symbol": "05114",
        "name": "FUYUAN W B2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2457,
        "symbol": "05113",
        "name": "KNWL CTY B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2458,
        "symbol": "05111",
        "name": "PX CCI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2459,
        "symbol": "05110",
        "name": "QDJZ UDI B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2460,
        "symbol": "05109",
        "name": "CHIYUBANK B3204",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2461,
        "symbol": "05108",
        "name": "FJ SHISHI B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2462,
        "symbol": "05106",
        "name": "HN AIG B2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2463,
        "symbol": "05103",
        "name": "GSHA TOUR B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2464,
        "symbol": "05102",
        "name": "LINYI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2465,
        "symbol": "05099",
        "name": "ZHUZHOU C B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2466,
        "symbol": "05098",
        "name": "HZ WATER B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2467,
        "symbol": "05095",
        "name": "HZWX EDI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2468,
        "symbol": "05094",
        "name": "TENCENT N3801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2469,
        "symbol": "05093",
        "name": "TENCENT N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2470,
        "symbol": "05086",
        "name": "HUANGYAN B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2471,
        "symbol": "05085",
        "name": "WHARFREIC N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2472,
        "symbol": "05084",
        "name": "CITIC LTD N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2473,
        "symbol": "05082",
        "name": "SP MTN FN N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2474,
        "symbol": "05081",
        "name": "XIANGTAN B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2475,
        "symbol": "05076",
        "name": "JJ PORT B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2476,
        "symbol": "05075",
        "name": "CHARM LGT N2712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2477,
        "symbol": "05074",
        "name": "RD MINTAI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2478,
        "symbol": "05073",
        "name": "BOCL N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2479,
        "symbol": "05072",
        "name": "NANXUN B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2480,
        "symbol": "05071",
        "name": "BINJIANG B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2481,
        "symbol": "05070",
        "name": "DFGUANGYI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2482,
        "symbol": "05068",
        "name": "CICC16MTN N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2483,
        "symbol": "05066",
        "name": "WNDING ZY B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2484,
        "symbol": "05065",
        "name": "BOCOM N2403A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2485,
        "symbol": "05064",
        "name": "BOCOM N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2486,
        "symbol": "05063",
        "name": "LYLMTG B2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2487,
        "symbol": "05062",
        "name": "CDCCIC B2712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2488,
        "symbol": "05061",
        "name": "SZ CIDGC B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2489,
        "symbol": "05060",
        "name": "CKI FIN B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2490,
        "symbol": "05059",
        "name": "CGNPC INT B2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2491,
        "symbol": "05057",
        "name": "NHSOAI B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2492,
        "symbol": "05056",
        "name": "KEQIAO HK B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2493,
        "symbol": "05055",
        "name": "CINDA FIN N3712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2494,
        "symbol": "05054",
        "name": "NJ YANGZI B2712",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 40000.0,
        "成交额": 3786000.0
    },
    {
        "序号": 2495,
        "symbol": "05052",
        "name": "TF SEC N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2496,
        "symbol": "05051",
        "name": "PINGDU SO B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2497,
        "symbol": "05049",
        "name": "SWHY INTL B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2498,
        "symbol": "05046",
        "name": "HEN RAIL B2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2499,
        "symbol": "05045",
        "name": "ZJ TZH B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2500,
        "symbol": "05044",
        "name": "ZHOUKOU B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2501,
        "symbol": "05040",
        "name": "XJ FN IN B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2502,
        "symbol": "05038",
        "name": "CSCFIN II N2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2503,
        "symbol": "05035",
        "name": "XJ FN IN B2503D",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2504,
        "symbol": "05033",
        "name": "CLP-PF N2911",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2505,
        "symbol": "05032",
        "name": "JICZHIXIN B2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2506,
        "symbol": "05023",
        "name": "YANAN TO B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2507,
        "symbol": "05022",
        "name": "XJ FN IN B2503C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2508,
        "symbol": "05019",
        "name": "FWD GHL SPCSB",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2509,
        "symbol": "05018",
        "name": "XJ FN IN B2503B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2510,
        "symbol": "05017",
        "name": "XJ FN IN B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2511,
        "symbol": "05016",
        "name": "DJ HAIYU SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2512,
        "symbol": "05015",
        "name": "ICBCIL FN N2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2513,
        "symbol": "05012",
        "name": "AVICIFI B2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2514,
        "symbol": "05010",
        "name": "HB UN DEV B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2515,
        "symbol": "05008",
        "name": "KWG GROUP N2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2516,
        "symbol": "05006",
        "name": "VKEREHK N2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2517,
        "symbol": "05004",
        "name": "HUARONG17 N2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2518,
        "symbol": "05003",
        "name": "HUARONG17 N4711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2519,
        "symbol": "05002",
        "name": "HUARONG17 N2711",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2520,
        "symbol": "04621",
        "name": "CINDA 21USDPREF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2521,
        "symbol": "04620",
        "name": "ICBC 20USDPREF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2522,
        "symbol": "04619",
        "name": "BOC 20USDPREF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2523,
        "symbol": "04618",
        "name": "GRCB 19USDPREF",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2524,
        "symbol": "04599",
        "name": "TUSPARK B2405B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2525,
        "symbol": "04598",
        "name": "CSGIF BVI N2705",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2526,
        "symbol": "04596",
        "name": "ZHENROPPT SPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2527,
        "symbol": "04595",
        "name": "BOCL N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2528,
        "symbol": "04593",
        "name": "HY CNUC B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2529,
        "symbol": "04591",
        "name": "SHA PG B2906",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2530,
        "symbol": "04587",
        "name": "SHA PG B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2531,
        "symbol": "04581",
        "name": "ZOOMWE B2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2532,
        "symbol": "04580",
        "name": "CHINA OVS N4211",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2533,
        "symbol": "04576",
        "name": "CLP-PF N2710",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2534,
        "symbol": "04573",
        "name": "PION REW B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2535,
        "symbol": "04572",
        "name": "UHI CPTL B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2536,
        "symbol": "04565",
        "name": "XI YANG B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": 40000.0,
        "成交额": 3968000.0
    },
    {
        "序号": 2537,
        "symbol": "04564",
        "name": "JZBD B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2538,
        "symbol": "04562",
        "name": "CQ NANAN B2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2539,
        "symbol": "04560",
        "name": "LS ETD B2503B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2540,
        "symbol": "04555",
        "name": "SINOPEC N4205",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2541,
        "symbol": "04553",
        "name": "LS ETD B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2542,
        "symbol": "04551",
        "name": "HUARONG19 N2905",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2543,
        "symbol": "04550",
        "name": "WXC INV B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2544,
        "symbol": "04549",
        "name": "HUARONG19 N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2545,
        "symbol": "04547",
        "name": "CNOOC F12 N4205",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2546,
        "symbol": "04541",
        "name": "L&M PAPER SPCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2547,
        "symbol": "04540",
        "name": "BI HAI N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2548,
        "symbol": "04538",
        "name": "CM BANK N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2549,
        "symbol": "04533",
        "name": "CHD OVSDM SGPS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2550,
        "symbol": "04532",
        "name": "AGRBK N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2551,
        "symbol": "04528",
        "name": "RONGSHI N2905",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2552,
        "symbol": "04527",
        "name": "RONGSHI N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2553,
        "symbol": "04526",
        "name": "NWD (MTN) N2905",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2554,
        "symbol": "04525",
        "name": "SINOPEC N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2555,
        "symbol": "04523",
        "name": "CLP-PF N2610",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2556,
        "symbol": "04522",
        "name": "WHARF FIN N2609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2557,
        "symbol": "04521",
        "name": "AGRBK N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2558,
        "symbol": "04518",
        "name": "CDBL FND2 N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2559,
        "symbol": "04516",
        "name": "STATEGRID N4705",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2560,
        "symbol": "04515",
        "name": "STATEGRID N2705",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2561,
        "symbol": "04514",
        "name": "CNPC OVS N4104",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2562,
        "symbol": "04513",
        "name": "CDBL FND2 N2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2563,
        "symbol": "04510",
        "name": "QZ SOCO B2503",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2564,
        "symbol": "04509",
        "name": "HUAIAN IN N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2565,
        "symbol": "04507",
        "name": "CNOOC F11 N4101",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2566,
        "symbol": "04506",
        "name": "FUGAO WT B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2567,
        "symbol": "04505",
        "name": "SUN EXP N2703",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2568,
        "symbol": "04504",
        "name": "BOCL N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2569,
        "symbol": "04503",
        "name": "BOCL N2502A",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2570,
        "symbol": "04502",
        "name": "SINOCHEM N4011",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2571,
        "symbol": "04501",
        "name": "MIDEA INV N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2572,
        "symbol": "04500",
        "name": "PHX CHARM B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2573,
        "symbol": "04498",
        "name": "HKMC N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2574,
        "symbol": "04497",
        "name": "CHALCO CH B2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2575,
        "symbol": "04496",
        "name": "HB CONST B2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2576,
        "symbol": "04495",
        "name": "BJ COMCI N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2577,
        "symbol": "04493",
        "name": "CITIC LTD N3202",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2578,
        "symbol": "04492",
        "name": "CITIC LT N2702B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2579,
        "symbol": "04490",
        "name": "BOCHK N2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2580,
        "symbol": "04488",
        "name": "CDB N2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2581,
        "symbol": "04487",
        "name": "BLUESTAR N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2582,
        "symbol": "04486",
        "name": "CDB N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2583,
        "symbol": "04485",
        "name": "BOCL N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2584,
        "symbol": "04484",
        "name": "HTISEC B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2585,
        "symbol": "04483",
        "name": "BOCL N2402B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2586,
        "symbol": "04482",
        "name": "STATEGRI N2805",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2587,
        "symbol": "04478",
        "name": "CNOOCF15U N2805",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2588,
        "symbol": "04476",
        "name": "CHINA OVS N2804",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2589,
        "symbol": "04471",
        "name": "LS FIN B2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2590,
        "symbol": "04469",
        "name": "CINDA20 N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2591,
        "symbol": "04467",
        "name": "ICBCIL FN N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2592,
        "symbol": "04466",
        "name": "CITIC LTD N4804",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2593,
        "symbol": "04464",
        "name": "ICBCIL FN N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2594,
        "symbol": "04463",
        "name": "GREENTOWN B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2595,
        "symbol": "04459",
        "name": "HEN RAIL B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2596,
        "symbol": "04458",
        "name": "HCG N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2597,
        "symbol": "04457",
        "name": "AIA GROUP N2804",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2598,
        "symbol": "04456",
        "name": "CFD STATE B2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2599,
        "symbol": "04454",
        "name": "QUZHOUCOM B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2600,
        "symbol": "04452",
        "name": "FU GANG B2501C",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2601,
        "symbol": "04451",
        "name": "FU GANG B2501B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2602,
        "symbol": "04450",
        "name": "FU GANG B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2603,
        "symbol": "04448",
        "name": "HZNX NT B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2604,
        "symbol": "04447",
        "name": "JSGK INV B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2605,
        "symbol": "04446",
        "name": "CITIC LTD N3803",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2606,
        "symbol": "04444",
        "name": "HZMGS HT N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2607,
        "symbol": "04442",
        "name": "XIN YUE B2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2608,
        "symbol": "04441",
        "name": "BOCL N2803",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2609,
        "symbol": "04438",
        "name": "CHINA OVS N2907",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2610,
        "symbol": "04437",
        "name": "CHINA OVS N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2611,
        "symbol": "04432",
        "name": "CCB B3201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2612,
        "symbol": "04431",
        "name": "LUHENG B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2613,
        "symbol": "04429",
        "name": "FZ DEIG B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2614,
        "symbol": "04428",
        "name": "YUANZHOU B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2615,
        "symbol": "04427",
        "name": "GRNL GLB N2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2616,
        "symbol": "04426",
        "name": "ZCHK TECH B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2617,
        "symbol": "04425",
        "name": "BJ GAS SG N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2618,
        "symbol": "04424",
        "name": "CINDA FIN N4802",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2619,
        "symbol": "04423",
        "name": "CINDA FIN N2802",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2620,
        "symbol": "04422",
        "name": "CINDA FIN N2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2621,
        "symbol": "04419",
        "name": "CH BANK NCSCS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2622,
        "symbol": "04416",
        "name": "FWD GHL SPCSC",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2623,
        "symbol": "04415",
        "name": "TH UNIC B2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2624,
        "symbol": "04413",
        "name": "AGRBK N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2625,
        "symbol": "04412",
        "name": "AGRBK N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2626,
        "symbol": "04411",
        "name": "CCTHK N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2627,
        "symbol": "04410",
        "name": "CCTHK N2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2628,
        "symbol": "04408",
        "name": "LINK FIN N3201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2629,
        "symbol": "04407",
        "name": "GZ DDH B2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2630,
        "symbol": "04406",
        "name": "AZORB IV N2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2631,
        "symbol": "04404",
        "name": "JS DSQ B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2632,
        "symbol": "04403",
        "name": "FWD GHL N2407",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2633,
        "symbol": "04401",
        "name": "SAIL VAN B2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2634,
        "symbol": "04396",
        "name": "STOCK 4396",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2635,
        "symbol": "04328",
        "name": "WUXIXIXI B2501B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2636,
        "symbol": "04327",
        "name": "WUXIXIXI B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2637,
        "symbol": "04325",
        "name": "AIR AUTH N2701",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2638,
        "symbol": "04323",
        "name": "HBAP N2509",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2639,
        "symbol": "04320",
        "name": "AIR AUTH N6201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2640,
        "symbol": "04319",
        "name": "CCBL1CORP N2905",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2641,
        "symbol": "04318",
        "name": "AIR AUTH N5201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2642,
        "symbol": "04317",
        "name": "CITIC LTD N4609",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2643,
        "symbol": "04316",
        "name": "AIR AUTH N3201",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2644,
        "symbol": "04315",
        "name": "JJ PORT B2501B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2645,
        "symbol": "04314",
        "name": "CITIC LTD N3109",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2646,
        "symbol": "04313",
        "name": "YC HI TEC B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2647,
        "symbol": "04308",
        "name": "ZJCX B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2648,
        "symbol": "04306",
        "name": "CLP-PF N2403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2649,
        "symbol": "04303",
        "name": "CCBL1CORP N2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2650,
        "symbol": "04302",
        "name": "JJ PORT B2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2651,
        "symbol": "04274",
        "name": "HKGB FRN 2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2652,
        "symbol": "04272",
        "name": "HKGB FRN 2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2653,
        "symbol": "04271",
        "name": "HKGB GREEN 3206",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2654,
        "symbol": "04270",
        "name": "HKGB GREEN 2706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2655,
        "symbol": "04269",
        "name": "HKGB GREEN 3306",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2656,
        "symbol": "04268",
        "name": "HKGB GREEN 2806",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2657,
        "symbol": "04267",
        "name": "HKGB GREEN 2606",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2658,
        "symbol": "04266",
        "name": "HKGB FRN 2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2659,
        "symbol": "04265",
        "name": "HKGB FRN 2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2660,
        "symbol": "04264",
        "name": "HKGB GREEN 3001",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2661,
        "symbol": "04263",
        "name": "HKGB GREEN 2501",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2662,
        "symbol": "04262",
        "name": "HKGB GREEN 5301",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2663,
        "symbol": "04261",
        "name": "HKGB GREEN 3301",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2664,
        "symbol": "04260",
        "name": "HKGB GREEN 2801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2665,
        "symbol": "04259",
        "name": "HKGB GREEN 2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2666,
        "symbol": "04258",
        "name": "HKGB 3.74 3801",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2667,
        "symbol": "04255",
        "name": "HKGB 3.30 4205",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2668,
        "symbol": "04253",
        "name": "HKGB 1.79 2504",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2669,
        "symbol": "04252",
        "name": "政府绿债二五零五",
        "最新价": 97.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 97.5,
        "最高": 97.5,
        "最低": 97.45,
        "昨收": 97.5,
        "成交量": 62700.0,
        "成交额": 6112740.0
    },
    {
        "序号": 2670,
        "symbol": "04250",
        "name": "HKGB GREEN 4111",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2671,
        "symbol": "04249",
        "name": "HKGB GREEN 2611",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2672,
        "symbol": "04248",
        "name": "HKGB GREEN 3111",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2673,
        "symbol": "04245",
        "name": "HKGB 0.36 2404",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2674,
        "symbol": "04244",
        "name": "HKGB 1.59 3603",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2675,
        "symbol": "04243",
        "name": "HKGB GREEN 5102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2676,
        "symbol": "04242",
        "name": "HKGB GREEN 3102",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2677,
        "symbol": "04241",
        "name": "HKGB GREEN 2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2678,
        "symbol": "04238",
        "name": "HKGB GREEN 2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2679,
        "symbol": "04237",
        "name": "HKGB 2.02 3403",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2680,
        "symbol": "04236",
        "name": "HKGB 1.97 2901",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2681,
        "symbol": "04235",
        "name": "HKGB 1.25 2706",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2682,
        "symbol": "04233",
        "name": "HKGB SUKUK 2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2683,
        "symbol": "04232",
        "name": "HKGB 1.89 3203",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2684,
        "symbol": "04230",
        "name": "HKGB 1.68 2601",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2685,
        "symbol": "04227",
        "name": "HKGB 2.13 3007",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2686,
        "symbol": "04221",
        "name": "HKGB 2.22 2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2687,
        "symbol": "04199",
        "name": "EFN 2.95 2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2688,
        "symbol": "04193",
        "name": "EFN 2.60 2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2689,
        "symbol": "04187",
        "name": "EFN 1.73 2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2690,
        "symbol": "04096",
        "name": "EFN 4.08 2511",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2691,
        "symbol": "04095",
        "name": "EFN 3.98 2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2692,
        "symbol": "04094",
        "name": "EFN 3.31 2505",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2693,
        "symbol": "04093",
        "name": "EFN 3.75 2502",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2694,
        "symbol": "04092",
        "name": "EFN 4.55 2411",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2695,
        "symbol": "04091",
        "name": "EFN 2.84 2408",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2696,
        "symbol": "04090",
        "name": "EFN 2.35 2405",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2697,
        "symbol": "04088",
        "name": "EFN 0.94 2402",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2698,
        "symbol": "04057",
        "name": "EFN 1.84 2412",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2699,
        "symbol": "04054",
        "name": "EFN 2.24 2908",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2700,
        "symbol": "04051",
        "name": "EFN 1.96 2406",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2701,
        "symbol": "04048",
        "name": "EFN 2.48 2902",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2702,
        "symbol": "04042",
        "name": "EFN 2.49 2808",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2703,
        "symbol": "04036",
        "name": "EFN 1.49 2802",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2704,
        "symbol": "04030",
        "name": "EFN 0.80 2708",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2705,
        "symbol": "04024",
        "name": "EFN 1.51 2702",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2706,
        "symbol": "04018",
        "name": "EFN 2.07 2608",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2707,
        "symbol": "04012",
        "name": "EFN 3.32 2602",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2708,
        "symbol": "04006",
        "name": "EFN 2.39 2508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2709,
        "symbol": "03996",
        "name": "中国能源建设",
        "最新价": 0.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.73,
        "最高": 0.74,
        "最低": 0.73,
        "昨收": 0.73,
        "成交量": 4202000.0,
        "成交额": 3100970.0
    },
    {
        "序号": 2710,
        "symbol": "03991",
        "name": "长虹佳华",
        "最新价": 0.425,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.42,
        "昨收": 0.425,
        "成交量": 152000.0,
        "成交额": 64640.0
    },
    {
        "序号": 2711,
        "symbol": "03988",
        "name": "中国银行",
        "最新价": 2.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.84,
        "最高": 2.84,
        "最低": 2.8,
        "昨收": 2.82,
        "成交量": 129232878.0,
        "成交额": 364982384.0
    },
    {
        "序号": 2712,
        "symbol": "03963",
        "name": "融众金融",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2713,
        "symbol": "03919",
        "name": "金力集团",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2714,
        "symbol": "03913",
        "name": "合景悠活",
        "最新价": 0.445,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.46,
        "最高": 0.46,
        "最低": 0.445,
        "昨收": 0.445,
        "成交量": 384781.0,
        "成交额": 172256.0
    },
    {
        "序号": 2715,
        "symbol": "03886",
        "name": "康健国际医疗",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.295,
        "昨收": 0.31,
        "成交量": 568000.0,
        "成交额": 171070.0
    },
    {
        "序号": 2716,
        "symbol": "03869",
        "name": "弘和仁爱医疗",
        "最新价": 6.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.88,
        "最高": 6.88,
        "最低": 6.88,
        "昨收": 6.88,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2717,
        "symbol": "03839",
        "name": "正大企业国际",
        "最新价": 0.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.91,
        "最高": 0.92,
        "最低": 0.91,
        "昨收": 0.92,
        "成交量": 13800.0,
        "成交额": 12543.0
    },
    {
        "序号": 2718,
        "symbol": "03830",
        "name": "童园国际",
        "最新价": 0.027,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.027,
        "最高": 0.027,
        "最低": 0.027,
        "昨收": 0.027,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2719,
        "symbol": "03828",
        "name": "明辉国际",
        "最新价": 0.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.59,
        "最高": 0.59,
        "最低": 0.59,
        "昨收": 0.59,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2720,
        "symbol": "03818",
        "name": "中国动向",
        "最新价": 0.236,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.231,
        "最高": 0.24,
        "最低": 0.231,
        "昨收": 0.236,
        "成交量": 1898000.0,
        "成交额": 451935.0
    },
    {
        "序号": 2721,
        "symbol": "03816",
        "name": "KFM金德",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.34,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 668000.0,
        "成交额": 217520.0
    },
    {
        "序号": 2722,
        "symbol": "03813",
        "name": "宝胜国际",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.66,
        "最低": 0.65,
        "昨收": 0.65,
        "成交量": 120000.0,
        "成交额": 78790.0
    },
    {
        "序号": 2723,
        "symbol": "03778",
        "name": "中国织材控股",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.3,
        "昨收": 0.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2724,
        "symbol": "03728",
        "name": "正利控股",
        "最新价": 0.098,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.098,
        "最高": 0.098,
        "最低": 0.098,
        "昨收": 0.098,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2725,
        "symbol": "03718",
        "name": "北控城市资源",
        "最新价": 0.425,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.425,
        "最高": 0.425,
        "最低": 0.415,
        "昨收": 0.425,
        "成交量": 100000.0,
        "成交额": 42420.0
    },
    {
        "序号": 2726,
        "symbol": "03708",
        "name": "中国供应链产业",
        "最新价": 0.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.11,
        "最高": 0.11,
        "最低": 0.11,
        "昨收": 0.11,
        "成交量": 150000.0,
        "成交额": 16500.0
    },
    {
        "序号": 2727,
        "symbol": "03700",
        "name": "映宇宙",
        "最新价": 0.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.86,
        "最高": 0.89,
        "最低": 0.84,
        "昨收": 0.86,
        "成交量": 10182000.0,
        "成交额": 8828880.0
    },
    {
        "序号": 2728,
        "symbol": "03699",
        "name": "光大永年",
        "最新价": 0.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.44,
        "最高": 0.44,
        "最低": 0.44,
        "昨收": 0.44,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2729,
        "symbol": "03698",
        "name": "徽商银行",
        "最新价": 2.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.35,
        "最高": 2.37,
        "最低": 2.35,
        "昨收": 2.37,
        "成交量": 26700.0,
        "成交额": 63031.0
    },
    {
        "序号": 2730,
        "symbol": "03688",
        "name": "莱蒙国际",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.52,
        "昨收": 0.52,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2731,
        "symbol": "03683",
        "name": "荣丰亿控股",
        "最新价": 0.178,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.178,
        "最高": 0.178,
        "最低": 0.178,
        "昨收": 0.178,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2732,
        "symbol": "03666",
        "name": "上海小南国",
        "最新价": 0.045,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.049,
        "最高": 0.049,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 8000.0,
        "成交额": 376.0
    },
    {
        "序号": 2733,
        "symbol": "03628",
        "name": "仁恒实业控股",
        "最新价": 0.131,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.131,
        "最高": 0.131,
        "最低": 0.131,
        "昨收": 0.131,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2734,
        "symbol": "03626",
        "name": "HSSP INTL",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.33,
        "最高": 0.33,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2735,
        "symbol": "03616",
        "name": "恒达集团控股",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.21,
        "最高": 0.21,
        "最低": 0.21,
        "昨收": 0.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2736,
        "symbol": "03611",
        "name": "花房集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 2.3,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2737,
        "symbol": "03608",
        "name": "永盛新材料",
        "最新价": 0.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.97,
        "最高": 0.97,
        "最低": 0.97,
        "昨收": 0.97,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2738,
        "symbol": "03603",
        "name": "信基沙溪",
        "最新价": 0.227,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.227,
        "最高": 0.227,
        "最低": 0.226,
        "昨收": 0.227,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2739,
        "symbol": "03588",
        "name": "富途控股-W",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2740,
        "symbol": "03405",
        "name": "富邦亚洲电池储能",
        "最新价": 5.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.64,
        "最高": 5.7,
        "最低": 5.64,
        "昨收": 5.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2741,
        "symbol": "03399",
        "name": "粤运交通",
        "最新价": 1.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.17,
        "最高": 1.17,
        "最低": 1.14,
        "昨收": 1.17,
        "成交量": 102000.0,
        "成交额": 119310.0
    },
    {
        "序号": 2742,
        "symbol": "03395",
        "name": "吉星新能源",
        "最新价": 0.237,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.237,
        "最高": 0.237,
        "最低": 0.237,
        "昨收": 0.237,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2743,
        "symbol": "03369",
        "name": "秦港股份",
        "最新价": 1.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.31,
        "最高": 1.31,
        "最低": 1.29,
        "昨收": 1.29,
        "成交量": 389500.0,
        "成交额": 506260.0
    },
    {
        "序号": 2744,
        "symbol": "03363",
        "name": "正业国际",
        "最新价": 0.465,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.465,
        "最高": 0.465,
        "最低": 0.465,
        "昨收": 0.465,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2745,
        "symbol": "03348",
        "name": "中国鹏飞集团",
        "最新价": 1.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.16,
        "最高": 1.16,
        "最低": 1.16,
        "昨收": 1.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2746,
        "symbol": "03339",
        "name": "中国龙工",
        "最新价": 1.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.14,
        "最高": 1.16,
        "最低": 1.14,
        "昨收": 1.15,
        "成交量": 1714244.0,
        "成交额": 1971215.0
    },
    {
        "序号": 2747,
        "symbol": "03329",
        "name": "交银国际",
        "最新价": 0.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.37,
        "最高": 0.37,
        "最低": 0.365,
        "昨收": 0.37,
        "成交量": 200000.0,
        "成交额": 73850.0
    },
    {
        "序号": 2748,
        "symbol": "03326",
        "name": "保发集团",
        "最新价": 0.175,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.175,
        "最高": 0.175,
        "最低": 0.175,
        "昨收": 0.175,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2749,
        "symbol": "03321",
        "name": "伟鸿集团控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.99,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2750,
        "symbol": "03318",
        "name": "中国波顿",
        "最新价": 1.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.22,
        "最高": 1.25,
        "最低": 1.16,
        "昨收": 1.25,
        "成交量": 134000.0,
        "成交额": 158800.0
    },
    {
        "序号": 2751,
        "symbol": "03316",
        "name": "滨江服务",
        "最新价": 15.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 15.48,
        "最高": 15.48,
        "最低": 15.1,
        "昨收": 15.2,
        "成交量": 79853.0,
        "成交额": 1219827.0
    },
    {
        "序号": 2752,
        "symbol": "03313",
        "name": "雅高控股",
        "最新价": 0.305,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.3,
        "昨收": 0.305,
        "成交量": 216500.0,
        "成交额": 65985.0
    },
    {
        "序号": 2753,
        "symbol": "03300",
        "name": "中国玻璃",
        "最新价": 0.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.67,
        "最高": 0.67,
        "最低": 0.66,
        "昨收": 0.67,
        "成交量": 544000.0,
        "成交额": 362620.0
    },
    {
        "序号": 2754,
        "symbol": "03199",
        "name": "工银南方国债",
        "最新价": 112.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 112.05,
        "最高": 112.05,
        "最低": 112.0,
        "昨收": 112.0,
        "成交量": 100.0,
        "成交额": 11205.0
    },
    {
        "序号": 2755,
        "symbol": "03196",
        "name": "A博时美元",
        "最新价": 7956.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7956.0,
        "最高": 7974.6,
        "最低": 7956.0,
        "昨收": 7956.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2756,
        "symbol": "03192",
        "name": "A博时人民币",
        "最新价": 1116.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1116.1,
        "最高": 1116.35,
        "最低": 1116.1,
        "昨收": 1116.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2757,
        "symbol": "03181",
        "name": "PP亚洲创科",
        "最新价": 77.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 77.32,
        "最高": 77.54,
        "最低": 77.32,
        "昨收": 77.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2758,
        "symbol": "03167",
        "name": "工银南方中国",
        "最新价": 50.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 50.82,
        "最高": 51.14,
        "最低": 50.72,
        "昨收": 50.82,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2759,
        "symbol": "03155",
        "name": "A嘉实生活科技",
        "最新价": 36.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 36.74,
        "最高": 36.86,
        "最低": 36.62,
        "昨收": 36.74,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2760,
        "symbol": "03145",
        "name": "华夏亚洲高息股",
        "最新价": 9.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 9.54,
        "最高": 9.57,
        "最低": 9.54,
        "昨收": 9.54,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2761,
        "symbol": "03143",
        "name": "华夏香港银行股",
        "最新价": 6.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.11,
        "最高": 6.11,
        "最低": 6.11,
        "昨收": 6.11,
        "成交量": 200.0,
        "成交额": 1222.0
    },
    {
        "序号": 2762,
        "symbol": "03137",
        "name": "AGX美元",
        "最新价": 997.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 997.65,
        "最高": 997.65,
        "最低": 997.65,
        "昨收": 997.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2763,
        "symbol": "03128",
        "name": "恒生A股龙头",
        "最新价": 46.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 46.02,
        "最高": 46.02,
        "最低": 46.02,
        "昨收": 46.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2764,
        "symbol": "03125",
        "name": "安硕短期政银",
        "最新价": 55.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 55.3,
        "最高": 55.36,
        "最低": 55.3,
        "昨收": 55.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2765,
        "symbol": "03122",
        "name": "A南方人民币",
        "最新价": 178.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 178.0,
        "最高": 178.0,
        "最低": 177.9,
        "昨收": 178.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2766,
        "symbol": "03118",
        "name": "嘉实明晟A股",
        "最新价": 14.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 14.03,
        "最高": 14.03,
        "最低": 13.45,
        "昨收": 14.03,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2767,
        "symbol": "03096",
        "name": "A南方美元",
        "最新价": 857.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 857.7,
        "最高": 857.7,
        "最低": 857.7,
        "昨收": 857.7,
        "成交量": 31.0,
        "成交额": 26588.0
    },
    {
        "序号": 2768,
        "symbol": "03079",
        "name": "中金中国国债",
        "最新价": 129.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 129.7,
        "最高": 129.7,
        "最低": 129.7,
        "昨收": 129.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2769,
        "symbol": "03070",
        "name": "平安香港高息股",
        "最新价": 24.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 24.14,
        "最高": 24.24,
        "最低": 24.14,
        "昨收": 24.16,
        "成交量": 1400.0,
        "成交额": 33846.0
    },
    {
        "序号": 2770,
        "symbol": "03059",
        "name": "GX亚洲绿债",
        "最新价": 53.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 53.98,
        "最高": 54.04,
        "最低": 53.98,
        "昨收": 53.98,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2771,
        "symbol": "03051",
        "name": "AGX亚洲创新者",
        "最新价": 32.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 32.6,
        "最高": 32.7,
        "最低": 32.6,
        "昨收": 32.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2772,
        "symbol": "03041",
        "name": "GX中国政银债券",
        "最新价": 55.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 55.34,
        "最高": 55.34,
        "最低": 55.34,
        "昨收": 55.34,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2773,
        "symbol": "03037",
        "name": "南方恒指ETF",
        "最新价": 16.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 16.48,
        "最高": 16.51,
        "最低": 16.44,
        "昨收": 16.51,
        "成交量": 10500.0,
        "成交额": 172900.0
    },
    {
        "序号": 2774,
        "symbol": "03030",
        "name": "惠理EMQQ",
        "最新价": 7.325,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.325,
        "最高": 7.355,
        "最低": 7.315,
        "昨收": 7.325,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2775,
        "symbol": "03024",
        "name": "标智上证50",
        "最新价": 21.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 21.26,
        "最高": 21.26,
        "最低": 21.26,
        "昨收": 21.26,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2776,
        "symbol": "03022",
        "name": "安硕新兴市场",
        "最新价": 438.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 438.0,
        "最高": 439.6,
        "最低": 438.0,
        "昨收": 438.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2777,
        "symbol": "03007",
        "name": "TR富时50",
        "最新价": 186.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 186.6,
        "最高": 186.6,
        "最低": 185.4,
        "昨收": 186.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2778,
        "symbol": "03005",
        "name": "X南方中五百",
        "最新价": 16.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 16.02,
        "最高": 16.02,
        "最低": 16.02,
        "昨收": 16.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2779,
        "symbol": "02993",
        "name": "TEAMWAY INTL GP",
        "最新价": 0.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.22,
        "昨收": 0.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2780,
        "symbol": "02990",
        "name": "宏基集团控股-旧",
        "最新价": 1.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.55,
        "最高": 1.55,
        "最低": 1.55,
        "昨收": 1.55,
        "成交量": 6000.0,
        "成交额": 8340.0
    },
    {
        "序号": 2781,
        "symbol": "02989",
        "name": "伟俊集团控股-旧",
        "最新价": 0.187,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.187,
        "最高": 0.187,
        "最低": 0.187,
        "昨收": 0.187,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2782,
        "symbol": "02906",
        "name": "京玖康疗-旧",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.38,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2783,
        "symbol": "02885",
        "name": "彼岸控股",
        "最新价": 0.495,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.49,
        "最高": 0.495,
        "最低": 0.49,
        "昨收": 0.495,
        "成交量": 14000.0,
        "成交额": 6870.0
    },
    {
        "序号": 2784,
        "symbol": "02882",
        "name": "香港资源控股",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.66,
        "最低": 0.65,
        "昨收": 0.65,
        "成交量": 720000.0,
        "成交额": 471100.0
    },
    {
        "序号": 2785,
        "symbol": "02858",
        "name": "易鑫集团",
        "最新价": 0.61,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.61,
        "最高": 0.61,
        "最低": 0.6,
        "昨收": 0.61,
        "成交量": 1380500.0,
        "成交额": 834545.0
    },
    {
        "序号": 2786,
        "symbol": "02849",
        "name": "GX自动驾驶电车",
        "最新价": 47.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 47.58,
        "最高": 47.58,
        "最低": 47.58,
        "昨收": 47.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2787,
        "symbol": "02843",
        "name": "东汇A50",
        "最新价": 12.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 12.33,
        "最高": 12.33,
        "最低": 12.33,
        "昨收": 12.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2788,
        "symbol": "02838",
        "name": "恒生富时中国50",
        "最新价": 104.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 104.65,
        "最高": 105.35,
        "最低": 104.45,
        "昨收": 104.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2789,
        "symbol": "02829",
        "name": "安硕中国国债",
        "最新价": 56.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 56.76,
        "最高": 56.84,
        "最低": 56.76,
        "昨收": 56.76,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2790,
        "symbol": "02817",
        "name": "PP国债",
        "最新价": 122.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 122.0,
        "最高": 122.05,
        "最低": 122.0,
        "昨收": 122.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2791,
        "symbol": "02813",
        "name": "华夏政银国债",
        "最新价": 118.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 118.55,
        "最高": 118.55,
        "最低": 118.55,
        "昨收": 118.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2792,
        "symbol": "02803",
        "name": "PP中国基石",
        "最新价": 7.515,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.515,
        "最高": 7.535,
        "最低": 7.515,
        "昨收": 7.515,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2793,
        "symbol": "02789",
        "name": "远大中国",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.052,
        "最低": 0.05,
        "昨收": 0.05,
        "成交量": 350000.0,
        "成交额": 17504.0
    },
    {
        "序号": 2794,
        "symbol": "02779",
        "name": "中国新华教育",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.52,
        "昨收": 0.52,
        "成交量": 209000.0,
        "成交额": 108680.0
    },
    {
        "序号": 2795,
        "symbol": "02768",
        "name": "佳源国际控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.173,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2796,
        "symbol": "02718",
        "name": "东正金融",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.15,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2797,
        "symbol": "02700",
        "name": "格林国际控股",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.33,
        "最高": 0.33,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2798,
        "symbol": "02699",
        "name": "新明中国",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.012,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2799,
        "symbol": "02683",
        "name": "华新手袋国际控股",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.38,
        "最高": 0.38,
        "最低": 0.38,
        "昨收": 0.38,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2800,
        "symbol": "02682",
        "name": "润利海事",
        "最新价": 0.192,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.192,
        "最高": 0.192,
        "最低": 0.192,
        "昨收": 0.192,
        "成交量": 200000.0,
        "成交额": 38400.0
    },
    {
        "序号": 2801,
        "symbol": "02680",
        "name": "创陞控股",
        "最新价": 0.345,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.345,
        "昨收": 0.345,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2802,
        "symbol": "02663",
        "name": "应力控股",
        "最新价": 0.255,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.255,
        "昨收": 0.255,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2803,
        "symbol": "02638",
        "name": "港灯-SS",
        "最新价": 4.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.46,
        "最高": 4.47,
        "最低": 4.44,
        "昨收": 4.46,
        "成交量": 1744509.0,
        "成交额": 7769520.0
    },
    {
        "序号": 2804,
        "symbol": "02633",
        "name": "雅各臣科研制药",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.63,
        "最高": 0.63,
        "最低": 0.62,
        "昨收": 0.63,
        "成交量": 518000.0,
        "成交额": 325260.0
    },
    {
        "序号": 2805,
        "symbol": "02623",
        "name": "爱德新能源",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.53,
        "最低": 0.5,
        "昨收": 0.53,
        "成交量": 24500.0,
        "成交额": 12240.0
    },
    {
        "序号": 2806,
        "symbol": "02608",
        "name": "阳光100中国",
        "最新价": 0.085,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.077,
        "最高": 0.085,
        "最低": 0.077,
        "昨收": 0.085,
        "成交量": 16000.0,
        "成交额": 1300.0
    },
    {
        "序号": 2807,
        "symbol": "02599",
        "name": "祥生控股集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.175,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2808,
        "symbol": "02568",
        "name": "CNOOC F03 N3305",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2809,
        "symbol": "02558",
        "name": "晋商银行",
        "最新价": 1.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.25,
        "最高": 1.25,
        "最低": 1.25,
        "昨收": 1.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2810,
        "symbol": "02527",
        "name": "PTT N3508",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2811,
        "symbol": "02520",
        "name": "山西安装",
        "最新价": 2.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.07,
        "最高": 2.07,
        "最低": 2.06,
        "昨收": 2.06,
        "成交量": 92000.0,
        "成交额": 190100.0
    },
    {
        "序号": 2812,
        "symbol": "02500",
        "name": "启明医疗-B",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 5.62,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2813,
        "symbol": "02497",
        "name": "富景中国控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2814,
        "symbol": "02481",
        "name": "慧居科技",
        "最新价": 2.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.65,
        "最高": 2.65,
        "最低": 2.65,
        "昨收": 2.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2815,
        "symbol": "02451",
        "name": "绿源集团控股",
        "最新价": 7.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.45,
        "最高": 7.49,
        "最低": 7.35,
        "昨收": 7.37,
        "成交量": 301500.0,
        "成交额": 2231290.0
    },
    {
        "序号": 2816,
        "symbol": "02450",
        "name": "淮北绿金股份",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2817,
        "symbol": "02418",
        "name": "德银天下",
        "最新价": 1.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.94,
        "最高": 1.94,
        "最低": 1.94,
        "昨收": 1.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2818,
        "symbol": "02409",
        "name": "洲际船务",
        "最新价": 3.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.25,
        "最高": 3.25,
        "最低": 3.25,
        "昨收": 3.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2819,
        "symbol": "02393",
        "name": "巨星医疗控股",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.144,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2820,
        "symbol": "02392",
        "name": "玄武云",
        "最新价": 1.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.7,
        "最高": 1.72,
        "最低": 1.7,
        "昨收": 1.72,
        "成交量": 46000.0,
        "成交额": 78960.0
    },
    {
        "序号": 2821,
        "symbol": "02377",
        "name": "博奇环保",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.76,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2822,
        "symbol": "02376",
        "name": "鲁商服务",
        "最新价": 1.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.82,
        "最高": 1.82,
        "最低": 1.82,
        "昨收": 1.82,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2823,
        "symbol": "02372",
        "name": "伟立控股",
        "最新价": 0.325,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.325,
        "最高": 0.325,
        "最低": 0.325,
        "昨收": 0.325,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2824,
        "symbol": "02370",
        "name": "力高健康生活",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.68,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2825,
        "symbol": "02368",
        "name": "鹰美",
        "最新价": 4.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.28,
        "最高": 4.32,
        "最低": 4.25,
        "昨收": 4.28,
        "成交量": 44000.0,
        "成交额": 187940.0
    },
    {
        "序号": 2826,
        "symbol": "02363",
        "name": "通达宏泰",
        "最新价": 0.064,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.064,
        "最高": 0.064,
        "最低": 0.064,
        "昨收": 0.064,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2827,
        "symbol": "02360",
        "name": "优品360",
        "最新价": 1.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.88,
        "最高": 1.88,
        "最低": 1.88,
        "昨收": 1.88,
        "成交量": 2000.0,
        "成交额": 3760.0
    },
    {
        "序号": 2828,
        "symbol": "02355",
        "name": "宝业集团",
        "最新价": 4.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.15,
        "最高": 4.18,
        "最低": 4.15,
        "昨收": 4.15,
        "成交量": 36000.0,
        "成交额": 149760.0
    },
    {
        "序号": 2829,
        "symbol": "02352",
        "name": "东原仁知服务",
        "最新价": 6.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.8,
        "最高": 6.8,
        "最低": 6.8,
        "昨收": 6.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2830,
        "symbol": "02350",
        "name": "数科集团",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.51,
        "最高": 0.51,
        "最低": 0.5,
        "昨收": 0.51,
        "成交量": 1345000.0,
        "成交额": 685900.0
    },
    {
        "序号": 2831,
        "symbol": "02348",
        "name": "东瑞制药",
        "最新价": 1.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.04,
        "最高": 1.04,
        "最低": 1.02,
        "昨收": 1.03,
        "成交量": 204000.0,
        "成交额": 210800.0
    },
    {
        "序号": 2832,
        "symbol": "02346",
        "name": "星宇控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.82,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2833,
        "symbol": "02342",
        "name": "京信通信",
        "最新价": 0.87,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.86,
        "最高": 0.88,
        "最低": 0.85,
        "昨收": 0.87,
        "成交量": 766073.0,
        "成交额": 664722.0
    },
    {
        "序号": 2834,
        "symbol": "02341",
        "name": "中怡国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.9,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2835,
        "symbol": "02340",
        "name": "昇柏控股",
        "最新价": 0.255,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.25,
        "昨收": 0.255,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2836,
        "symbol": "02339",
        "name": "京西国际",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 18000.0,
        "成交额": 4500.0
    },
    {
        "序号": 2837,
        "symbol": "02336",
        "name": "海亮国际",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.17,
        "昨收": 0.17,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2838,
        "symbol": "02327",
        "name": "美瑞健康国际",
        "最新价": 0.238,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.24,
        "最高": 0.24,
        "最低": 0.227,
        "昨收": 0.238,
        "成交量": 720000.0,
        "成交额": 166398.0
    },
    {
        "序号": 2839,
        "symbol": "02324",
        "name": "首都创投",
        "最新价": 0.143,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.143,
        "最高": 0.143,
        "最低": 0.143,
        "昨收": 0.143,
        "成交量": 64.0,
        "成交额": 8.0
    },
    {
        "序号": 2840,
        "symbol": "02321",
        "name": "双财庄",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.395,
        "最高": 0.4,
        "最低": 0.395,
        "昨收": 0.4,
        "成交量": 30000.0,
        "成交额": 11925.0
    },
    {
        "序号": 2841,
        "symbol": "02320",
        "name": "合丰集团",
        "最新价": 0.091,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.091,
        "最高": 0.091,
        "最低": 0.091,
        "昨收": 0.091,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2842,
        "symbol": "02312",
        "name": "中国金融租赁",
        "最新价": 0.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.16,
        "最高": 0.16,
        "最低": 0.16,
        "昨收": 0.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2843,
        "symbol": "02310",
        "name": "时代环球集团",
        "最新价": 0.028,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.028,
        "最高": 0.028,
        "最低": 0.028,
        "昨收": 0.028,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2844,
        "symbol": "02307",
        "name": "锦兴国际控股",
        "最新价": 0.249,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.249,
        "最高": 0.249,
        "最低": 0.249,
        "昨收": 0.249,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2845,
        "symbol": "02302",
        "name": "中核国际",
        "最新价": 0.93,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.9,
        "最高": 0.93,
        "最低": 0.9,
        "昨收": 0.93,
        "成交量": 52000.0,
        "成交额": 46860.0
    },
    {
        "序号": 2846,
        "symbol": "02299",
        "name": "百宏实业",
        "最新价": 4.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.12,
        "最高": 4.12,
        "最低": 4.12,
        "昨收": 4.12,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2847,
        "symbol": "02295",
        "name": "丰城控股",
        "最新价": 1.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.27,
        "最高": 1.27,
        "最低": 1.27,
        "昨收": 1.27,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2848,
        "symbol": "02293",
        "name": "百本医护",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.75,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2849,
        "symbol": "02292",
        "name": "晋安实业",
        "最新价": 1.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.19,
        "最高": 1.19,
        "最低": 1.19,
        "昨收": 1.19,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2850,
        "symbol": "02288",
        "name": "宏基资本",
        "最新价": 0.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.27,
        "最高": 0.27,
        "最低": 0.27,
        "昨收": 0.27,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2851,
        "symbol": "02286",
        "name": "辰兴发展",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.3,
        "昨收": 0.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2852,
        "symbol": "02281",
        "name": "兴泸水务",
        "最新价": 0.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.59,
        "最高": 0.59,
        "最低": 0.59,
        "昨收": 0.59,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2853,
        "symbol": "02280",
        "name": "慧聪集团",
        "最新价": 0.248,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.248,
        "最高": 0.248,
        "最低": 0.248,
        "昨收": 0.248,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2854,
        "symbol": "02270",
        "name": "德商产投服务",
        "最新价": 1.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.11,
        "最高": 1.11,
        "最低": 1.11,
        "昨收": 1.11,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2855,
        "symbol": "02266",
        "name": "黎氏企业",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.33,
        "最高": 0.33,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2856,
        "symbol": "02263",
        "name": "富石金融",
        "最新价": 0.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.14,
        "最高": 0.14,
        "最低": 0.14,
        "昨收": 0.14,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2857,
        "symbol": "02262",
        "name": "梁志天设计集团",
        "最新价": 0.092,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.092,
        "最高": 0.093,
        "最低": 0.092,
        "昨收": 0.092,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2858,
        "symbol": "02258",
        "name": "华滋国际海洋",
        "最新价": 0.198,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.198,
        "最高": 0.198,
        "最低": 0.198,
        "昨收": 0.198,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2859,
        "symbol": "02239",
        "name": "国微控股",
        "最新价": 1.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.7,
        "最高": 1.7,
        "最低": 1.7,
        "昨收": 1.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2860,
        "symbol": "02237",
        "name": "中国石墨",
        "最新价": 0.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.53,
        "最高": 0.54,
        "最低": 0.53,
        "昨收": 0.54,
        "成交量": 1180000.0,
        "成交额": 626800.0
    },
    {
        "序号": 2861,
        "symbol": "02233",
        "name": "西部水泥",
        "最新价": 0.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.67,
        "最高": 0.68,
        "最低": 0.66,
        "昨收": 0.67,
        "成交量": 4984000.0,
        "成交额": 3333020.0
    },
    {
        "序号": 2862,
        "symbol": "02231",
        "name": "景业名邦集团",
        "最新价": 1.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.11,
        "最高": 1.11,
        "最低": 1.11,
        "昨收": 1.11,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2863,
        "symbol": "02230",
        "name": "羚邦集团",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.18,
        "最高": 0.185,
        "最低": 0.18,
        "昨收": 0.18,
        "成交量": 240000.0,
        "成交额": 44130.0
    },
    {
        "序号": 2864,
        "symbol": "02227",
        "name": "守益控股",
        "最新价": 0.134,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.134,
        "最高": 0.134,
        "最低": 0.134,
        "昨收": 0.134,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2865,
        "symbol": "02226",
        "name": "老恒和酿造",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.5,
        "昨收": 0.5,
        "成交量": 62000.0,
        "成交额": 31000.0
    },
    {
        "序号": 2866,
        "symbol": "02225",
        "name": "今海国际",
        "最新价": 2.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.5,
        "最高": 2.5,
        "最低": 2.5,
        "昨收": 2.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2867,
        "symbol": "02223",
        "name": "卡撒天娇",
        "最新价": 0.345,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.35,
        "最低": 0.345,
        "昨收": 0.345,
        "成交量": 8000.0,
        "成交额": 2770.0
    },
    {
        "序号": 2868,
        "symbol": "02221",
        "name": "创业集团控股",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.61,
        "最高": 0.61,
        "最低": 0.57,
        "昨收": 0.6,
        "成交量": 1560000.0,
        "成交额": 922320.0
    },
    {
        "序号": 2869,
        "symbol": "02217",
        "name": "谭仔国际",
        "最新价": 1.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.25,
        "最高": 1.25,
        "最低": 1.22,
        "昨收": 1.24,
        "成交量": 536000.0,
        "成交额": 662700.0
    },
    {
        "序号": 2870,
        "symbol": "02215",
        "name": "德信服务集团",
        "最新价": 2.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.49,
        "最高": 2.49,
        "最低": 2.49,
        "昨收": 2.49,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2871,
        "symbol": "02208",
        "name": "金风科技",
        "最新价": 3.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.38,
        "最高": 3.41,
        "最低": 3.35,
        "昨收": 3.36,
        "成交量": 3055200.0,
        "成交额": 10313970.0
    },
    {
        "序号": 2872,
        "symbol": "02207",
        "name": "融信服务",
        "最新价": 0.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.43,
        "昨收": 0.43,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2873,
        "symbol": "02205",
        "name": "康桥悦生活",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.668,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2874,
        "symbol": "02199",
        "name": "维珍妮",
        "最新价": 2.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.15,
        "最高": 2.15,
        "最低": 2.15,
        "昨收": 2.15,
        "成交量": 8000.0,
        "成交额": 17200.0
    },
    {
        "序号": 2875,
        "symbol": "02193",
        "name": "万景控股",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.38,
        "最高": 0.38,
        "最低": 0.38,
        "昨收": 0.38,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2876,
        "symbol": "02191",
        "name": "顺丰房托",
        "最新价": 2.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.57,
        "最高": 2.58,
        "最低": 2.57,
        "昨收": 2.58,
        "成交量": 291000.0,
        "成交额": 750330.0
    },
    {
        "序号": 2877,
        "symbol": "02188",
        "name": "泰坦能源技术",
        "最新价": 0.305,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.305,
        "最高": 0.31,
        "最低": 0.3,
        "昨收": 0.305,
        "成交量": 914000.0,
        "成交额": 278770.0
    },
    {
        "序号": 2878,
        "symbol": "02186",
        "name": "绿叶制药",
        "最新价": 3.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.76,
        "最高": 3.78,
        "最低": 3.72,
        "昨收": 3.76,
        "成交量": 3833500.0,
        "成交额": 14383245.0
    },
    {
        "序号": 2879,
        "symbol": "02183",
        "name": "三盛控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 3.98,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2880,
        "symbol": "02180",
        "name": "万宝盛华",
        "最新价": 5.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.9,
        "最高": 5.9,
        "最低": 5.9,
        "昨收": 5.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2881,
        "symbol": "02178",
        "name": "百勤油服",
        "最新价": 0.072,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.072,
        "最高": 0.072,
        "最低": 0.072,
        "昨收": 0.072,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2882,
        "symbol": "02176",
        "name": "赛迪顾问",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.52,
        "昨收": 0.52,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2883,
        "symbol": "02175",
        "name": "中国通才教育",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 3.11,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2884,
        "symbol": "02165",
        "name": "领悦服务集团",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.67,
        "最高": 0.67,
        "最低": 0.63,
        "昨收": 0.64,
        "成交量": 34000.0,
        "成交额": 22140.0
    },
    {
        "序号": 2885,
        "symbol": "02163",
        "name": "远大住工",
        "最新价": 2.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.45,
        "最高": 2.45,
        "最低": 2.41,
        "昨收": 2.45,
        "成交量": 2700.0,
        "成交额": 6591.0
    },
    {
        "序号": 2886,
        "symbol": "02152",
        "name": "苏新服务",
        "最新价": 6.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.0,
        "最高": 6.0,
        "最低": 6.0,
        "昨收": 6.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2887,
        "symbol": "02130",
        "name": "嘉泓物流",
        "最新价": 6.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.03,
        "最高": 6.05,
        "最低": 6.02,
        "昨收": 6.03,
        "成交量": 103000.0,
        "成交额": 621520.0
    },
    {
        "序号": 2888,
        "symbol": "02129",
        "name": "LEGION CONSO",
        "最新价": 0.101,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.101,
        "最高": 0.101,
        "最低": 0.101,
        "昨收": 0.101,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2889,
        "symbol": "02125",
        "name": "稻草熊娱乐",
        "最新价": 0.475,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.48,
        "最高": 0.48,
        "最低": 0.475,
        "昨收": 0.475,
        "成交量": 14000.0,
        "成交额": 6685.0
    },
    {
        "序号": 2890,
        "symbol": "02122",
        "name": "凯知乐国际",
        "最新价": 0.054,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.054,
        "最高": 0.054,
        "最低": 0.054,
        "昨收": 0.054,
        "成交量": 12000.0,
        "成交额": 684.0
    },
    {
        "序号": 2891,
        "symbol": "02117",
        "name": "大唐集团控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.5,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2892,
        "symbol": "02113",
        "name": "世纪集团国际",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2893,
        "symbol": "02111",
        "name": "超盈国际控股",
        "最新价": 1.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.05,
        "最高": 1.05,
        "最低": 1.05,
        "昨收": 1.05,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2894,
        "symbol": "02102",
        "name": "德利机械",
        "最新价": 0.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.14,
        "最高": 0.14,
        "最低": 0.14,
        "昨收": 0.14,
        "成交量": 20000.0,
        "成交额": 2800.0
    },
    {
        "序号": 2895,
        "symbol": "02088",
        "name": "西王置业",
        "最新价": 0.071,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.071,
        "最高": 0.071,
        "最低": 0.071,
        "昨收": 0.071,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2896,
        "symbol": "02086",
        "name": "高维科技",
        "最新价": 0.77,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.77,
        "最高": 0.77,
        "最低": 0.77,
        "昨收": 0.77,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2897,
        "symbol": "02080",
        "name": "奥克斯国际",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.35,
        "最低": 0.26,
        "昨收": 0.26,
        "成交量": 2000.0,
        "成交额": 700.0
    },
    {
        "序号": 2898,
        "symbol": "02051",
        "name": "51信用卡",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.1,
        "昨收": 0.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2899,
        "symbol": "02039",
        "name": "中集集团",
        "最新价": 4.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.8,
        "最高": 4.8,
        "最低": 4.64,
        "昨收": 4.74,
        "成交量": 147186.0,
        "成交额": 691725.0
    },
    {
        "序号": 2900,
        "symbol": "02038",
        "name": "富智康集团",
        "最新价": 0.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.55,
        "最高": 0.57,
        "最低": 0.54,
        "昨收": 0.55,
        "成交量": 3374000.0,
        "成交额": 1879760.0
    },
    {
        "序号": 2901,
        "symbol": "02033",
        "name": "时计宝",
        "最新价": 0.485,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.485,
        "最高": 0.485,
        "最低": 0.485,
        "昨收": 0.485,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2902,
        "symbol": "02030",
        "name": "卡宾",
        "最新价": 1.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.05,
        "最高": 1.05,
        "最低": 1.01,
        "昨收": 1.01,
        "成交量": 32000.0,
        "成交额": 32880.0
    },
    {
        "序号": 2903,
        "symbol": "02028",
        "name": "映美控股",
        "最新价": 0.106,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.106,
        "最高": 0.106,
        "最低": 0.106,
        "昨收": 0.106,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2904,
        "symbol": "02022",
        "name": "游莱互动",
        "最新价": 0.042,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.042,
        "最高": 0.042,
        "最低": 0.042,
        "昨收": 0.042,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2905,
        "symbol": "02013",
        "name": "微盟集团",
        "最新价": 3.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.13,
        "最高": 3.19,
        "最低": 3.1,
        "昨收": 3.13,
        "成交量": 21356660.0,
        "成交额": 66930168.0
    },
    {
        "序号": 2906,
        "symbol": "02012",
        "name": "阳光油砂",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.46,
        "最高": 0.52,
        "最低": 0.46,
        "昨收": 0.52,
        "成交量": 75220.0,
        "成交额": 37704.0
    },
    {
        "序号": 2907,
        "symbol": "02003",
        "name": "维信金科",
        "最新价": 2.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.2,
        "最高": 2.2,
        "最低": 2.2,
        "昨收": 2.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2908,
        "symbol": "02001",
        "name": "新高教集团",
        "最新价": 2.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.1,
        "最高": 2.13,
        "最低": 2.08,
        "昨收": 2.11,
        "成交量": 634000.0,
        "成交额": 1335705.0
    },
    {
        "序号": 2909,
        "symbol": "02000",
        "name": "晨讯科技",
        "最新价": 0.285,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.285,
        "最低": 0.275,
        "昨收": 0.285,
        "成交量": 116395.0,
        "成交额": 32516.0
    },
    {
        "序号": 2910,
        "symbol": "01993",
        "name": "雅仕维",
        "最新价": 0.92,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.92,
        "最高": 0.92,
        "最低": 0.92,
        "昨收": 0.92,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2911,
        "symbol": "01991",
        "name": "大洋集团",
        "最新价": 0.152,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.152,
        "最高": 0.152,
        "最低": 0.15,
        "昨收": 0.152,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2912,
        "symbol": "01989",
        "name": "松龄护老集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.9,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2913,
        "symbol": "01986",
        "name": "彩客新能源",
        "最新价": 1.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.06,
        "最高": 1.06,
        "最低": 1.04,
        "昨收": 1.06,
        "成交量": 150000.0,
        "成交额": 157870.0
    },
    {
        "序号": 2914,
        "symbol": "01985",
        "name": "美高域",
        "最新价": 0.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.89,
        "最高": 0.89,
        "最低": 0.89,
        "昨收": 0.89,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2915,
        "symbol": "01983",
        "name": "泸州银行",
        "最新价": 2.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.48,
        "最高": 2.48,
        "最低": 2.48,
        "昨收": 2.48,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2916,
        "symbol": "01981",
        "name": "华夏视听教育",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.77,
        "昨收": 0.8,
        "成交量": 16000.0,
        "成交额": 12720.0
    },
    {
        "序号": 2917,
        "symbol": "01969",
        "name": "中国春来",
        "最新价": 6.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.06,
        "最高": 6.15,
        "最低": 5.98,
        "昨收": 6.07,
        "成交量": 663000.0,
        "成交额": 4011700.0
    },
    {
        "序号": 2918,
        "symbol": "01965",
        "name": "朗诗绿色生活",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2919,
        "symbol": "01962",
        "name": "训修实业",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2920,
        "symbol": "01960",
        "name": "TBKS HLDGS",
        "最新价": 0.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.36,
        "最高": 0.36,
        "最低": 0.36,
        "昨收": 0.36,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2921,
        "symbol": "01959",
        "name": "世纪联合控股",
        "最新价": 0.183,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.183,
        "最高": 0.183,
        "最低": 0.183,
        "昨收": 0.183,
        "成交量": 2000.0,
        "成交额": 366.0
    },
    {
        "序号": 2922,
        "symbol": "01958",
        "name": "北京汽车",
        "最新价": 2.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.25,
        "最高": 2.25,
        "最低": 2.19,
        "昨收": 2.24,
        "成交量": 7651000.0,
        "成交额": 16971142.0
    },
    {
        "序号": 2923,
        "symbol": "01955",
        "name": "庄臣控股",
        "最新价": 0.415,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.415,
        "最高": 0.415,
        "最低": 0.415,
        "昨收": 0.415,
        "成交量": 2000.0,
        "成交额": 830.0
    },
    {
        "序号": 2924,
        "symbol": "01950",
        "name": "帝王实业控股",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.232,
        "最高": 0.233,
        "最低": 0.23,
        "昨收": 0.23,
        "成交量": 84000.0,
        "成交额": 19544.0
    },
    {
        "序号": 2925,
        "symbol": "01948",
        "name": "优矩控股",
        "最新价": 1.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.84,
        "最高": 1.84,
        "最低": 1.84,
        "昨收": 1.84,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2926,
        "symbol": "01947",
        "name": "美皓集团",
        "最新价": 0.435,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.415,
        "最高": 0.435,
        "最低": 0.415,
        "昨收": 0.435,
        "成交量": 15000.0,
        "成交额": 6400.0
    },
    {
        "序号": 2927,
        "symbol": "01945",
        "name": "清科创业",
        "最新价": 1.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.03,
        "最高": 1.03,
        "最低": 1.03,
        "昨收": 1.03,
        "成交量": 11200.0,
        "成交额": 11536.0
    },
    {
        "序号": 2928,
        "symbol": "01943",
        "name": "银涛控股",
        "最新价": 1.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.14,
        "最高": 1.15,
        "最低": 1.11,
        "昨收": 1.14,
        "成交量": 40000.0,
        "成交额": 45550.0
    },
    {
        "序号": 2929,
        "symbol": "01941",
        "name": "烨星集团",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.169,
        "昨收": 0.17,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2930,
        "symbol": "01939",
        "name": "东京中央拍卖",
        "最新价": 0.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.68,
        "最高": 0.68,
        "最低": 0.68,
        "昨收": 0.68,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2931,
        "symbol": "01938",
        "name": "珠江钢管",
        "最新价": 0.174,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.174,
        "最高": 0.18,
        "最低": 0.174,
        "昨收": 0.174,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2932,
        "symbol": "01933",
        "name": "元力控股",
        "最新价": 0.176,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.176,
        "最高": 0.176,
        "最低": 0.176,
        "昨收": 0.176,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2933,
        "symbol": "01932",
        "name": "中漆集团",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.28,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2934,
        "symbol": "01931",
        "name": "华检医疗",
        "最新价": 1.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.85,
        "最高": 1.86,
        "最低": 1.79,
        "昨收": 1.85,
        "成交量": 103000.0,
        "成交额": 190730.0
    },
    {
        "序号": 2935,
        "symbol": "01928",
        "name": "金沙中国有限公司",
        "最新价": 21.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 21.25,
        "最高": 21.45,
        "最低": 20.95,
        "昨收": 21.05,
        "成交量": 15569453.0,
        "成交额": 329051792.0
    },
    {
        "序号": 2936,
        "symbol": "01927",
        "name": "久久王",
        "最新价": 0.115,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.115,
        "最高": 0.115,
        "最低": 0.114,
        "昨收": 0.115,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2937,
        "symbol": "01922",
        "name": "银城生活服务",
        "最新价": 2.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.0,
        "最高": 2.0,
        "最低": 2.0,
        "昨收": 2.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2938,
        "symbol": "01920",
        "name": "恒新丰控股",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 30000.0,
        "成交额": 17400.0
    },
    {
        "序号": 2939,
        "symbol": "01911",
        "name": "华兴资本控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 7.27,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2940,
        "symbol": "01902",
        "name": "银城国际控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.046,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2941,
        "symbol": "01900",
        "name": "中国智能交通",
        "最新价": 0.123,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.123,
        "最高": 0.123,
        "最低": 0.123,
        "昨收": 0.123,
        "成交量": 5000.0,
        "成交额": 615.0
    },
    {
        "序号": 2942,
        "symbol": "01897",
        "name": "美亨实业",
        "最新价": 0.355,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.355,
        "最高": 0.36,
        "最低": 0.355,
        "昨收": 0.355,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2943,
        "symbol": "01895",
        "name": "鑫苑服务",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.87,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2944,
        "symbol": "01872",
        "name": "冠轈控股",
        "最新价": 0.067,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.067,
        "最高": 0.067,
        "最低": 0.067,
        "昨收": 0.067,
        "成交量": 10000.0,
        "成交额": 670.0
    },
    {
        "序号": 2945,
        "symbol": "01871",
        "name": "向中国际",
        "最新价": 0.207,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.207,
        "最高": 0.207,
        "最低": 0.207,
        "昨收": 0.207,
        "成交量": 28000.0,
        "成交额": 5600.0
    },
    {
        "序号": 2946,
        "symbol": "01868",
        "name": "同方友友",
        "最新价": 0.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.42,
        "昨收": 0.42,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2947,
        "symbol": "01867",
        "name": "标准发展集团",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2948,
        "symbol": "01863",
        "name": "中国龙天集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.37,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2949,
        "symbol": "01859",
        "name": "煜盛文化",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.08,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2950,
        "symbol": "01856",
        "name": "依波路",
        "最新价": 1.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.9,
        "最高": 1.9,
        "最低": 1.9,
        "昨收": 1.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2951,
        "symbol": "01853",
        "name": "春城热力",
        "最新价": 1.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.9,
        "最高": 1.9,
        "最低": 1.9,
        "昨收": 1.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2952,
        "symbol": "01849",
        "name": "秀商时代控股",
        "最新价": 0.103,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.103,
        "最高": 0.103,
        "最低": 0.103,
        "昨收": 0.103,
        "成交量": 10000.0,
        "成交额": 1030.0
    },
    {
        "序号": 2953,
        "symbol": "01847",
        "name": "云南建投混凝土",
        "最新价": 0.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.57,
        "最高": 0.57,
        "最低": 0.57,
        "昨收": 0.57,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2954,
        "symbol": "01845",
        "name": "维港环保科技",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.24,
        "最高": 0.24,
        "最低": 0.24,
        "昨收": 0.24,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2955,
        "symbol": "01841",
        "name": "优越集团控股",
        "最新价": 0.234,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.234,
        "最高": 0.234,
        "最低": 0.234,
        "昨收": 0.234,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2956,
        "symbol": "01837",
        "name": "五谷磨房",
        "最新价": 0.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.46,
        "最高": 0.46,
        "最低": 0.46,
        "昨收": 0.46,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2957,
        "symbol": "01835",
        "name": "瑞威资管",
        "最新价": 1.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.22,
        "最高": 1.22,
        "最低": 1.22,
        "昨收": 1.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2958,
        "symbol": "01832",
        "name": "海天地悦旅",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 1000.0,
        "成交额": 580.0
    },
    {
        "序号": 2959,
        "symbol": "01831",
        "name": "十方控股",
        "最新价": 0.082,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.082,
        "最高": 0.082,
        "最低": 0.082,
        "昨收": 0.082,
        "成交量": 96253.0,
        "成交额": 7861.0
    },
    {
        "序号": 2960,
        "symbol": "01827",
        "name": "卓珈控股",
        "最新价": 1.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.46,
        "最高": 1.46,
        "最低": 1.46,
        "昨收": 1.46,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2961,
        "symbol": "01826",
        "name": "丰展控股",
        "最新价": 0.067,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.067,
        "最高": 0.067,
        "最低": 0.067,
        "昨收": 0.067,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2962,
        "symbol": "01817",
        "name": "慕尚集团控股",
        "最新价": 0.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.82,
        "最高": 0.84,
        "最低": 0.82,
        "昨收": 0.83,
        "成交量": 10500.0,
        "成交额": 8715.0
    },
    {
        "序号": 2963,
        "symbol": "01812",
        "name": "晨鸣纸业",
        "最新价": 2.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.05,
        "最高": 2.05,
        "最低": 2.0,
        "昨收": 2.02,
        "成交量": 399500.0,
        "成交额": 804490.0
    },
    {
        "序号": 2964,
        "symbol": "01803",
        "name": "北京体育文化",
        "最新价": 0.108,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.108,
        "最高": 0.108,
        "最低": 0.108,
        "昨收": 0.108,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2965,
        "symbol": "01802",
        "name": "文业集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.72,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2966,
        "symbol": "01800",
        "name": "中国交通建设",
        "最新价": 3.44,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.43,
        "最高": 3.45,
        "最低": 3.41,
        "昨收": 3.44,
        "成交量": 2417568.0,
        "成交额": 8286508.0
    },
    {
        "序号": 2967,
        "symbol": "01790",
        "name": "达力环保",
        "最新价": 0.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.54,
        "最高": 0.54,
        "最低": 0.54,
        "昨收": 0.54,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2968,
        "symbol": "01786",
        "name": "铁建装备",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.61,
        "最高": 0.63,
        "最低": 0.61,
        "昨收": 0.63,
        "成交量": 11000.0,
        "成交额": 6820.0
    },
    {
        "序号": 2969,
        "symbol": "01785",
        "name": "成都高速",
        "最新价": 1.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.89,
        "最高": 1.89,
        "最低": 1.89,
        "昨收": 1.89,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2970,
        "symbol": "01777",
        "name": "花样年控股",
        "最新价": 0.062,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.06,
        "最高": 0.062,
        "最低": 0.06,
        "昨收": 0.062,
        "成交量": 96000.0,
        "成交额": 5850.0
    },
    {
        "序号": 2971,
        "symbol": "01775",
        "name": "精英汇集团",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.19,
        "最高": 0.19,
        "最低": 0.19,
        "昨收": 0.19,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2972,
        "symbol": "01771",
        "name": "新丰泰集团",
        "最新价": 0.76,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.71,
        "最高": 0.76,
        "最低": 0.71,
        "昨收": 0.76,
        "成交量": 1000.0,
        "成交额": 710.0
    },
    {
        "序号": 2973,
        "symbol": "01767",
        "name": "TS WONDERS",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.15,
        "最高": 0.15,
        "最低": 0.15,
        "昨收": 0.15,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2974,
        "symbol": "01761",
        "name": "宝宝树集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.265,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2975,
        "symbol": "01759",
        "name": "中油洁能控股",
        "最新价": 0.375,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.375,
        "最高": 0.375,
        "最低": 0.375,
        "昨收": 0.375,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2976,
        "symbol": "01757",
        "name": "俊裕地基",
        "最新价": 0.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.22,
        "昨收": 0.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2977,
        "symbol": "01752",
        "name": "澳洲成峰高教",
        "最新价": 0.054,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.054,
        "最高": 0.054,
        "最低": 0.053,
        "昨收": 0.054,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2978,
        "symbol": "01750",
        "name": "全达电器集团控股",
        "最新价": 0.026,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.026,
        "最高": 0.026,
        "最低": 0.026,
        "昨收": 0.026,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2979,
        "symbol": "01749",
        "name": "杉杉品牌",
        "最新价": 0.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.41,
        "最高": 0.41,
        "最低": 0.41,
        "昨收": 0.41,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2980,
        "symbol": "01747",
        "name": "HOME CONTROL",
        "最新价": 0.335,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.47,
        "最低": 0.335,
        "昨收": 0.335,
        "成交量": 108000.0,
        "成交额": 39080.0
    },
    {
        "序号": 2981,
        "symbol": "01746",
        "name": "万顺集团控股",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.145,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2982,
        "symbol": "01742",
        "name": "HPC HOLDINGS",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.04,
        "最高": 0.04,
        "最低": 0.038,
        "昨收": 0.04,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2983,
        "symbol": "01739",
        "name": "齐屹科技",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.395,
        "最高": 0.4,
        "最低": 0.395,
        "昨收": 0.4,
        "成交量": 15500.0,
        "成交额": 6125.0
    },
    {
        "序号": 2984,
        "symbol": "01731",
        "name": "其利工业集团",
        "最新价": 0.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.68,
        "最高": 0.68,
        "最低": 0.68,
        "昨收": 0.68,
        "成交量": 4000.0,
        "成交额": 2720.0
    },
    {
        "序号": 2985,
        "symbol": "01728",
        "name": "正通汽车",
        "最新价": 0.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.335,
        "最高": 0.345,
        "最低": 0.335,
        "昨收": 0.34,
        "成交量": 648000.0,
        "成交额": 219642.0
    },
    {
        "序号": 2986,
        "symbol": "01723",
        "name": "港亚控股",
        "最新价": 0.195,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.195,
        "最高": 0.195,
        "最低": 0.195,
        "昨收": 0.195,
        "成交量": 304000.0,
        "成交额": 59280.0
    },
    {
        "序号": 2987,
        "symbol": "01722",
        "name": "建鹏控股",
        "最新价": 0.078,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.078,
        "最高": 0.078,
        "最低": 0.078,
        "昨收": 0.078,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2988,
        "symbol": "01721",
        "name": "FSM HOLDINGS",
        "最新价": 0.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.48,
        "最高": 0.48,
        "最低": 0.48,
        "昨收": 0.48,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2989,
        "symbol": "01711",
        "name": "欧化",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.1,
        "昨收": 0.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2990,
        "symbol": "01710",
        "name": "致丰工业电子",
        "最新价": 0.233,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.226,
        "最高": 0.233,
        "最低": 0.226,
        "昨收": 0.233,
        "成交量": 28000.0,
        "成交额": 6356.0
    },
    {
        "序号": 2991,
        "symbol": "01708",
        "name": "三宝科技",
        "最新价": 0.97,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.97,
        "最高": 0.97,
        "最低": 0.97,
        "昨收": 0.97,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2992,
        "symbol": "01707",
        "name": "致浩达控股",
        "最新价": 0.193,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.193,
        "最高": 0.193,
        "最低": 0.193,
        "昨收": 0.193,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2993,
        "symbol": "01706",
        "name": "双运控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.058,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2994,
        "symbol": "01703",
        "name": "维力生活科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.048,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2995,
        "symbol": "01702",
        "name": "东光化工",
        "最新价": 2.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.3,
        "最高": 2.3,
        "最低": 2.3,
        "昨收": 2.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2996,
        "symbol": "01701",
        "name": "途屹控股",
        "最新价": 0.139,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.139,
        "最高": 0.139,
        "最低": 0.138,
        "昨收": 0.139,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2997,
        "symbol": "01699",
        "name": "普甜食品",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.04,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 2998,
        "symbol": "01695",
        "name": "椰丰集团",
        "最新价": 0.053,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.053,
        "最高": 0.053,
        "最低": 0.053,
        "昨收": 0.053,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 2999,
        "symbol": "01693",
        "name": "璋利国际",
        "最新价": 0.039,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.039,
        "最高": 0.039,
        "最低": 0.039,
        "昨收": 0.039,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3000,
        "symbol": "01692",
        "name": "登辉控股",
        "最新价": 3.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.14,
        "最高": 3.15,
        "最低": 3.14,
        "昨收": 3.14,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3001,
        "symbol": "01690",
        "name": "立基工程控股",
        "最新价": 0.066,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.066,
        "最高": 0.066,
        "最低": 0.066,
        "昨收": 0.066,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3002,
        "symbol": "01687",
        "name": "PRC B2710",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3003,
        "symbol": "01682",
        "name": "杭品生活科技",
        "最新价": 0.069,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.069,
        "最高": 0.069,
        "最低": 0.069,
        "昨收": 0.069,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3004,
        "symbol": "01676",
        "name": "中国升海集团",
        "最新价": 1.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.8,
        "最高": 1.81,
        "最低": 1.76,
        "昨收": 1.8,
        "成交量": 2824000.0,
        "成交额": 5047680.0
    },
    {
        "序号": 3005,
        "symbol": "01669",
        "name": "环球信贷集团",
        "最新价": 0.495,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.495,
        "最低": 0.495,
        "昨收": 0.495,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3006,
        "symbol": "01667",
        "name": "迪米生活控股",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.21,
        "最高": 0.21,
        "最低": 0.21,
        "昨收": 0.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3007,
        "symbol": "01665",
        "name": "槟杰科达",
        "最新价": 1.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.03,
        "最高": 1.04,
        "最低": 1.01,
        "昨收": 1.04,
        "成交量": 1866000.0,
        "成交额": 1934240.0
    },
    {
        "序号": 3008,
        "symbol": "01663",
        "name": "汉港控股",
        "最新价": 0.134,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.134,
        "最高": 0.134,
        "最低": 0.134,
        "昨收": 0.134,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3009,
        "symbol": "01657",
        "name": "桦欣控股",
        "最新价": 2.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.3,
        "最高": 2.3,
        "最低": 2.3,
        "昨收": 2.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3010,
        "symbol": "01656",
        "name": "亿仕登控股",
        "最新价": 2.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.38,
        "最高": 2.38,
        "最低": 2.38,
        "昨收": 2.38,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3011,
        "symbol": "01653",
        "name": "MOS HOUSE",
        "最新价": 0.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.37,
        "最高": 0.37,
        "最低": 0.37,
        "昨收": 0.37,
        "成交量": 60000.0,
        "成交额": 21400.0
    },
    {
        "序号": 3012,
        "symbol": "01652",
        "name": "福森药业",
        "最新价": 1.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.39,
        "最高": 1.39,
        "最低": 1.38,
        "昨收": 1.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3013,
        "symbol": "01636",
        "name": "中国金属利用",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.048,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3014,
        "symbol": "01633",
        "name": "上谕集团",
        "最新价": 0.104,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.104,
        "最高": 0.104,
        "最低": 0.104,
        "昨收": 0.104,
        "成交量": 30000.0,
        "成交额": 3120.0
    },
    {
        "序号": 3015,
        "symbol": "01632",
        "name": "民商创科",
        "最新价": 0.226,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.226,
        "最高": 0.226,
        "最低": 0.226,
        "昨收": 0.226,
        "成交量": 36000.0,
        "成交额": 8136.0
    },
    {
        "序号": 3016,
        "symbol": "01630",
        "name": "建成控股",
        "最新价": 0.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.04,
        "最低": 0.037,
        "昨收": 0.04,
        "成交量": 66000.0,
        "成交额": 2460.0
    },
    {
        "序号": 3017,
        "symbol": "01629",
        "name": "冠均国际控股",
        "最新价": 0.185,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.181,
        "最高": 0.185,
        "最低": 0.181,
        "昨收": 0.185,
        "成交量": 4000.0,
        "成交额": 724.0
    },
    {
        "序号": 3018,
        "symbol": "01628",
        "name": "禹洲集团",
        "最新价": 0.113,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.128,
        "最高": 0.128,
        "最低": 0.113,
        "昨收": 0.113,
        "成交量": 291681.0,
        "成交额": 33660.0
    },
    {
        "序号": 3019,
        "symbol": "01622",
        "name": "力高集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.33,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3020,
        "symbol": "01620",
        "name": "富盈环球集团",
        "最新价": 0.069,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.069,
        "最高": 0.069,
        "最低": 0.069,
        "昨收": 0.069,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3021,
        "symbol": "01616",
        "name": "一元宇宙",
        "最新价": 0.036,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.036,
        "最高": 0.036,
        "最低": 0.036,
        "昨收": 0.036,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3022,
        "symbol": "01615",
        "name": "奥邦建筑",
        "最新价": 0.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.415,
        "最高": 0.43,
        "最低": 0.405,
        "昨收": 0.41,
        "成交量": 565000.0,
        "成交额": 235600.0
    },
    {
        "序号": 3023,
        "symbol": "01613",
        "name": "协同通信",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3024,
        "symbol": "01610",
        "name": "中粮家佳康",
        "最新价": 1.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.91,
        "最高": 1.92,
        "最低": 1.88,
        "昨收": 1.9,
        "成交量": 13420000.0,
        "成交额": 25601190.0
    },
    {
        "序号": 3025,
        "symbol": "01609",
        "name": "创建集团控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.66,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3026,
        "symbol": "01601",
        "name": "中关村科技租赁",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 50000.0,
        "成交额": 29000.0
    },
    {
        "序号": 3027,
        "symbol": "01600",
        "name": "天伦燃气",
        "最新价": 3.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.45,
        "最高": 3.46,
        "最低": 3.38,
        "昨收": 3.45,
        "成交量": 1285500.0,
        "成交额": 4403755.0
    },
    {
        "序号": 3028,
        "symbol": "01597",
        "name": "纳泉能源科技",
        "最新价": 8.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 8.0,
        "最高": 8.0,
        "最低": 8.0,
        "昨收": 8.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3029,
        "symbol": "01593",
        "name": "辰林教育",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.76,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3030,
        "symbol": "01591",
        "name": "汛和集团",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.02,
        "最高": 0.02,
        "最低": 0.02,
        "昨收": 0.02,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3031,
        "symbol": "01587",
        "name": "欣融国际",
        "最新价": 0.485,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.485,
        "最高": 0.485,
        "最低": 0.485,
        "昨收": 0.485,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3032,
        "symbol": "01583",
        "name": "亲亲食品",
        "最新价": 0.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.82,
        "最高": 0.82,
        "最低": 0.82,
        "昨收": 0.82,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3033,
        "symbol": "01582",
        "name": "华营建筑",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.5,
        "昨收": 0.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3034,
        "symbol": "01581",
        "name": "进升集团控股",
        "最新价": 0.122,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.122,
        "最高": 0.122,
        "最低": 0.122,
        "昨收": 0.122,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3035,
        "symbol": "01580",
        "name": "大森控股",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.144,
        "最高": 0.145,
        "最低": 0.144,
        "昨收": 0.145,
        "成交量": 270000.0,
        "成交额": 39090.0
    },
    {
        "序号": 3036,
        "symbol": "01578",
        "name": "天津银行",
        "最新价": 1.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.79,
        "最高": 1.79,
        "最低": 1.77,
        "昨收": 1.79,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3037,
        "symbol": "01577",
        "name": "汇鑫小贷",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.5,
        "昨收": 0.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3038,
        "symbol": "01576",
        "name": "齐鲁高速",
        "最新价": 2.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.12,
        "最高": 2.18,
        "最低": 2.12,
        "昨收": 2.18,
        "成交量": 9000.0,
        "成交额": 19300.0
    },
    {
        "序号": 3039,
        "symbol": "01570",
        "name": "伟业控股",
        "最新价": 2.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.18,
        "最高": 2.18,
        "最低": 2.18,
        "昨收": 2.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3040,
        "symbol": "01560",
        "name": "星星集团",
        "最新价": 0.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.22,
        "昨收": 0.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3041,
        "symbol": "01559",
        "name": "均安控股",
        "最新价": 0.117,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.117,
        "最高": 0.117,
        "最低": 0.117,
        "昨收": 0.117,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3042,
        "symbol": "01556",
        "name": "建业建荣",
        "最新价": 0.199,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.2,
        "最高": 0.201,
        "最低": 0.199,
        "昨收": 0.199,
        "成交量": 270000.0,
        "成交额": 53790.0
    },
    {
        "序号": 3043,
        "symbol": "01555",
        "name": "MI能源",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.037,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3044,
        "symbol": "01553",
        "name": "迈科管业",
        "最新价": 1.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.38,
        "最高": 1.38,
        "最低": 1.38,
        "昨收": 1.38,
        "成交量": 10000.0,
        "成交额": 13800.0
    },
    {
        "序号": 3045,
        "symbol": "01552",
        "name": "BHCC HOLDING",
        "最新价": 0.127,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.127,
        "最高": 0.127,
        "最低": 0.127,
        "昨收": 0.127,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3046,
        "symbol": "01551",
        "name": "广州农商银行",
        "最新价": 2.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.04,
        "最高": 2.04,
        "最低": 2.03,
        "昨收": 2.04,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3047,
        "symbol": "01547",
        "name": "IBI GROUP HLDGS",
        "最新价": 0.194,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.194,
        "最高": 0.194,
        "最低": 0.194,
        "昨收": 0.194,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3048,
        "symbol": "01546",
        "name": "德莱建业",
        "最新价": 0.092,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.092,
        "最高": 0.092,
        "最低": 0.092,
        "昨收": 0.092,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3049,
        "symbol": "01545",
        "name": "设计都会",
        "最新价": 0.071,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.071,
        "最高": 0.071,
        "最低": 0.071,
        "昨收": 0.071,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3050,
        "symbol": "01542",
        "name": "台州水务",
        "最新价": 2.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.2,
        "最高": 2.2,
        "最低": 2.2,
        "昨收": 2.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3051,
        "symbol": "01540",
        "name": "澳狮环球",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.405,
        "最高": 0.405,
        "最低": 0.405,
        "昨收": 0.405,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3052,
        "symbol": "01539",
        "name": "知行集团控股",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.61,
        "最低": 0.58,
        "昨收": 0.6,
        "成交量": 2544000.0,
        "成交额": 1504100.0
    },
    {
        "序号": 3053,
        "symbol": "01538",
        "name": "中奥到家",
        "最新价": 0.355,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.355,
        "最高": 0.355,
        "最低": 0.355,
        "昨收": 0.355,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3054,
        "symbol": "01536",
        "name": "煜荣集团",
        "最新价": 0.111,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.111,
        "最高": 0.111,
        "最低": 0.111,
        "昨收": 0.111,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3055,
        "symbol": "01527",
        "name": "天洁环境",
        "最新价": 0.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.85,
        "最高": 0.85,
        "最低": 0.85,
        "昨收": 0.85,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3056,
        "symbol": "01525",
        "name": "建桥教育",
        "最新价": 3.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.81,
        "最高": 3.81,
        "最低": 3.8,
        "昨收": 3.81,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3057,
        "symbol": "01518",
        "name": "新世纪医疗",
        "最新价": 0.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.9,
        "最高": 0.9,
        "最低": 0.9,
        "昨收": 0.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3058,
        "symbol": "01500",
        "name": "现恒建筑",
        "最新价": 0.133,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.133,
        "最高": 0.133,
        "最低": 0.133,
        "昨收": 0.133,
        "成交量": 20000.0,
        "成交额": 2660.0
    },
    {
        "序号": 3059,
        "symbol": "01497",
        "name": "燕之屋",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3060,
        "symbol": "01496",
        "name": "亚积邦租赁",
        "最新价": 0.136,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.136,
        "最高": 0.136,
        "最低": 0.136,
        "昨收": 0.136,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3061,
        "symbol": "01495",
        "name": "集一控股",
        "最新价": 0.138,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.138,
        "昨收": 0.138,
        "成交量": 40000.0,
        "成交额": 5520.0
    },
    {
        "序号": 3062,
        "symbol": "01488",
        "name": "百福控股",
        "最新价": 1.03,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.03,
        "最高": 1.03,
        "最低": 1.03,
        "昨收": 1.03,
        "成交量": 100000.0,
        "成交额": 103000.0
    },
    {
        "序号": 3063,
        "symbol": "01486",
        "name": "思城控股",
        "最新价": 0.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.47,
        "最高": 0.47,
        "最低": 0.47,
        "昨收": 0.47,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3064,
        "symbol": "01485",
        "name": "TENNESSEE B2025",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3065,
        "symbol": "01483",
        "name": "网誉科技",
        "最新价": 1.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.99,
        "最高": 1.01,
        "最低": 0.99,
        "昨收": 1.01,
        "成交量": 34000.0,
        "成交额": 34240.0
    },
    {
        "序号": 3066,
        "symbol": "01481",
        "name": "竣球控股",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 140000.0,
        "成交额": 37800.0
    },
    {
        "序号": 3067,
        "symbol": "01476",
        "name": "恒投证券",
        "最新价": 2.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.27,
        "最高": 2.27,
        "最低": 2.27,
        "昨收": 2.27,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3068,
        "symbol": "01472",
        "name": "生兴控股",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.07,
        "最高": 0.07,
        "最低": 0.07,
        "昨收": 0.07,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3069,
        "symbol": "01470",
        "name": "富一国际控股",
        "最新价": 0.061,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.061,
        "最高": 0.061,
        "最低": 0.061,
        "昨收": 0.061,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3070,
        "symbol": "01469",
        "name": "结好金融",
        "最新价": 0.68,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.68,
        "最高": 0.68,
        "最低": 0.68,
        "昨收": 0.68,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3071,
        "symbol": "01468",
        "name": "京基金融国际",
        "最新价": 1.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.05,
        "最高": 1.09,
        "最低": 1.02,
        "昨收": 1.05,
        "成交量": 11980000.0,
        "成交额": 12614500.0
    },
    {
        "序号": 3072,
        "symbol": "01466",
        "name": "钱唐控股",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.65,
        "最低": 0.65,
        "昨收": 0.65,
        "成交量": 385.0,
        "成交额": 211.0
    },
    {
        "序号": 3073,
        "symbol": "01463",
        "name": "C-LINK SQ",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.0,
        "最高": 1.0,
        "最低": 1.0,
        "昨收": 1.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3074,
        "symbol": "01461",
        "name": "中泰期货",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3075,
        "symbol": "01455",
        "name": "科利实业控股",
        "最新价": 0.172,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.172,
        "最高": 0.172,
        "最低": 0.172,
        "昨收": 0.172,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3076,
        "symbol": "01446",
        "name": "鸿福堂",
        "最新价": 0.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.219,
        "昨收": 0.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3077,
        "symbol": "01443",
        "name": "富临集团控股",
        "最新价": 0.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.21,
        "最高": 0.21,
        "最低": 0.21,
        "昨收": 0.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3078,
        "symbol": "01442",
        "name": "鹰辉物流",
        "最新价": 0.89,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.89,
        "最高": 0.9,
        "最低": 0.85,
        "昨收": 0.89,
        "成交量": 260000.0,
        "成交额": 224000.0
    },
    {
        "序号": 3079,
        "symbol": "01439",
        "name": "移动互联(中国)",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.024,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3080,
        "symbol": "01433",
        "name": "常达控股",
        "最新价": 0.038,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.038,
        "最高": 0.038,
        "最低": 0.038,
        "昨收": 0.038,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3081,
        "symbol": "01432",
        "name": "中国圣牧",
        "最新价": 0.265,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.26,
        "最高": 0.265,
        "最低": 0.26,
        "昨收": 0.265,
        "成交量": 150000.0,
        "成交额": 39345.0
    },
    {
        "序号": 3082,
        "symbol": "01431",
        "name": "原生态牧业",
        "最新价": 0.117,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.117,
        "最高": 0.117,
        "最低": 0.117,
        "昨收": 0.117,
        "成交量": 123000.0,
        "成交额": 14391.0
    },
    {
        "序号": 3083,
        "symbol": "01425",
        "name": "捷隆控股",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.57,
        "昨收": 0.58,
        "成交量": 3645000.0,
        "成交额": 2084850.0
    },
    {
        "序号": 3084,
        "symbol": "01421",
        "name": "工盖有限公司",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.405,
        "最高": 0.405,
        "最低": 0.405,
        "昨收": 0.405,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3085,
        "symbol": "01420",
        "name": "川控股",
        "最新价": 0.079,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.079,
        "最高": 0.079,
        "最低": 0.079,
        "昨收": 0.079,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3086,
        "symbol": "01418",
        "name": "盛诺集团",
        "最新价": 0.073,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.073,
        "最高": 0.073,
        "最低": 0.073,
        "昨收": 0.073,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3087,
        "symbol": "01402",
        "name": "超智能控股",
        "最新价": 0.183,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.183,
        "最高": 0.183,
        "最低": 0.183,
        "昨收": 0.183,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3088,
        "symbol": "01395",
        "name": "强泰环保",
        "最新价": 0.077,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.077,
        "最高": 0.077,
        "最低": 0.077,
        "昨收": 0.077,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3089,
        "symbol": "01389",
        "name": "美捷汇控股",
        "最新价": 0.028,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.028,
        "最高": 0.028,
        "最低": 0.028,
        "昨收": 0.028,
        "成交量": 36000.0,
        "成交额": 1008.0
    },
    {
        "序号": 3090,
        "symbol": "01388",
        "name": "安莉芳控股",
        "最新价": 0.445,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.445,
        "最高": 0.445,
        "最低": 0.445,
        "昨收": 0.445,
        "成交量": 10000.0,
        "成交额": 4450.0
    },
    {
        "序号": 3091,
        "symbol": "01387",
        "name": "中国地利",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.66,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3092,
        "symbol": "01383",
        "name": "LET GROUP",
        "最新价": 0.045,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3093,
        "symbol": "01380",
        "name": "中国金石",
        "最新价": 0.225,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.227,
        "最高": 0.228,
        "最低": 0.222,
        "昨收": 0.225,
        "成交量": 300000.0,
        "成交额": 67410.0
    },
    {
        "序号": 3094,
        "symbol": "01379",
        "name": "温岭工量刃具",
        "最新价": 2.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.01,
        "最高": 2.01,
        "最低": 2.01,
        "昨收": 2.01,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3095,
        "symbol": "01375",
        "name": "中州证券",
        "最新价": 1.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.1,
        "最高": 1.11,
        "最低": 1.09,
        "昨收": 1.1,
        "成交量": 770000.0,
        "成交额": 842810.0
    },
    {
        "序号": 3096,
        "symbol": "01372",
        "name": "中国碳中和",
        "最新价": 0.495,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.51,
        "最高": 0.51,
        "最低": 0.48,
        "昨收": 0.495,
        "成交量": 1236000.0,
        "成交额": 613140.0
    },
    {
        "序号": 3097,
        "symbol": "01370",
        "name": "奥威控股",
        "最新价": 0.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.66,
        "最高": 0.66,
        "最低": 0.66,
        "昨收": 0.66,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3098,
        "symbol": "01362",
        "name": "新龙移动",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.31,
        "昨收": 0.31,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3099,
        "symbol": "01360",
        "name": "诺发集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.012,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3100,
        "symbol": "01355",
        "name": "朸浚国际",
        "最新价": 0.224,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.224,
        "最高": 0.224,
        "最低": 0.224,
        "昨收": 0.224,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3101,
        "symbol": "01351",
        "name": "辉煌明天",
        "最新价": 0.199,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.199,
        "最高": 0.199,
        "最低": 0.199,
        "昨收": 0.199,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3102,
        "symbol": "01343",
        "name": "伟源控股",
        "最新价": 0.097,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.097,
        "最高": 0.097,
        "最低": 0.097,
        "昨收": 0.097,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3103,
        "symbol": "01340",
        "name": "惠生国际",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3104,
        "symbol": "01329",
        "name": "首创钜大",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3105,
        "symbol": "01328",
        "name": "金涌投资",
        "最新价": 0.038,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.038,
        "最高": 0.038,
        "最低": 0.038,
        "昨收": 0.038,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3106,
        "symbol": "01327",
        "name": "励时集团",
        "最新价": 0.065,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.065,
        "昨收": 0.065,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3107,
        "symbol": "01326",
        "name": "传递娱乐",
        "最新价": 0.034,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.034,
        "最高": 0.034,
        "最低": 0.034,
        "昨收": 0.034,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3108,
        "symbol": "01319",
        "name": "霭华押业信贷",
        "最新价": 0.232,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.232,
        "最高": 0.232,
        "最低": 0.232,
        "昨收": 0.232,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3109,
        "symbol": "01317",
        "name": "枫叶教育",
        "最新价": 0.285,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.285,
        "最高": 0.295,
        "最低": 0.285,
        "昨收": 0.285,
        "成交量": 1018000.0,
        "成交额": 294610.0
    },
    {
        "序号": 3110,
        "symbol": "01301",
        "name": "德基科技控股",
        "最新价": 1.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.01,
        "最高": 1.01,
        "最低": 1.01,
        "昨收": 1.01,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3111,
        "symbol": "01298",
        "name": "云能国际",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.8,
        "昨收": 0.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3112,
        "symbol": "01293",
        "name": "广汇宝信",
        "最新价": 0.186,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.182,
        "最高": 0.188,
        "最低": 0.18,
        "昨收": 0.186,
        "成交量": 1695500.0,
        "成交额": 308604.0
    },
    {
        "序号": 3113,
        "symbol": "01285",
        "name": "嘉士利集团",
        "最新价": 1.35,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.35,
        "最高": 1.35,
        "最低": 1.35,
        "昨收": 1.35,
        "成交量": 1000.0,
        "成交额": 1350.0
    },
    {
        "序号": 3114,
        "symbol": "01282",
        "name": "中泽丰",
        "最新价": 0.126,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.126,
        "最高": 0.126,
        "最低": 0.126,
        "昨收": 0.126,
        "成交量": 40000.0,
        "成交额": 5040.0
    },
    {
        "序号": 3115,
        "symbol": "01281",
        "name": "鑫达投资控股",
        "最新价": 0.046,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.046,
        "最高": 0.046,
        "最低": 0.046,
        "昨收": 0.046,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3116,
        "symbol": "01273",
        "name": "香港信贷",
        "最新价": 0.345,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.345,
        "昨收": 0.345,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3117,
        "symbol": "01272",
        "name": "大唐环境",
        "最新价": 0.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.85,
        "最高": 0.85,
        "最低": 0.85,
        "昨收": 0.85,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3118,
        "symbol": "01270",
        "name": "朗廷-SS",
        "最新价": 0.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.64,
        "最高": 0.66,
        "最低": 0.64,
        "昨收": 0.66,
        "成交量": 60000.0,
        "成交额": 38980.0
    },
    {
        "序号": 3119,
        "symbol": "01266",
        "name": "西王特钢",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.01,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3120,
        "symbol": "01262",
        "name": "蜡笔小新食品",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.17,
        "昨收": 0.17,
        "成交量": 6000.0,
        "成交额": 1021.0
    },
    {
        "序号": 3121,
        "symbol": "01260",
        "name": "皓天财经集团",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.18,
        "最高": 0.18,
        "最低": 0.18,
        "昨收": 0.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3122,
        "symbol": "01257",
        "name": "中国光大绿色环保",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.76,
        "最高": 0.76,
        "最低": 0.74,
        "昨收": 0.75,
        "成交量": 132000.0,
        "成交额": 99220.0
    },
    {
        "序号": 3123,
        "symbol": "01246",
        "name": "保集健康",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.1,
        "昨收": 0.1,
        "成交量": 150000.0,
        "成交额": 15000.0
    },
    {
        "序号": 3124,
        "symbol": "01245",
        "name": "NIRAKU",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.28,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3125,
        "symbol": "01241",
        "name": "双桦控股",
        "最新价": 0.062,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.062,
        "最高": 0.062,
        "最低": 0.062,
        "昨收": 0.062,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3126,
        "symbol": "01237",
        "name": "中科生物",
        "最新价": 0.044,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.044,
        "最高": 0.044,
        "最低": 0.044,
        "昨收": 0.044,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3127,
        "symbol": "01235",
        "name": "专业旅运",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.15,
        "最高": 0.15,
        "最低": 0.15,
        "昨收": 0.15,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3128,
        "symbol": "01232",
        "name": "金轮天地控股",
        "最新价": 0.034,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.034,
        "最高": 0.034,
        "最低": 0.034,
        "昨收": 0.034,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3129,
        "symbol": "01229",
        "name": "南南资源",
        "最新价": 0.118,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.115,
        "最高": 0.127,
        "最低": 0.115,
        "昨收": 0.118,
        "成交量": 428000.0,
        "成交额": 50412.0
    },
    {
        "序号": 3130,
        "symbol": "01226",
        "name": "中国投融资",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.405,
        "最高": 0.405,
        "最低": 0.405,
        "昨收": 0.405,
        "成交量": 10000.0,
        "成交额": 4050.0
    },
    {
        "序号": 3131,
        "symbol": "01224",
        "name": "中渝置地",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.4,
        "最高": 1.4,
        "最低": 1.4,
        "昨收": 1.4,
        "成交量": 12000.0,
        "成交额": 16800.0
    },
    {
        "序号": 3132,
        "symbol": "01222",
        "name": "WANG ON GROUP",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.043,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3133,
        "symbol": "01218",
        "name": "永义国际",
        "最新价": 3.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.6,
        "最高": 3.6,
        "最低": 3.6,
        "昨收": 3.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3134,
        "symbol": "01217",
        "name": "中国创新投资",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.01,
        "最高": 0.01,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3135,
        "symbol": "01216",
        "name": "中原银行",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.33,
        "最高": 0.33,
        "最低": 0.33,
        "昨收": 0.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3136,
        "symbol": "01215",
        "name": "开源控股",
        "最新价": 0.019,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.019,
        "最高": 0.019,
        "最低": 0.019,
        "昨收": 0.019,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3137,
        "symbol": "01213",
        "name": "万保刚集团",
        "最新价": 0.475,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.475,
        "最高": 0.475,
        "最低": 0.475,
        "昨收": 0.475,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3138,
        "symbol": "01210",
        "name": "克莉丝汀",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.057,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3139,
        "symbol": "01207",
        "name": "上置集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.013,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3140,
        "symbol": "01205",
        "name": "中信资源",
        "最新价": 0.355,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.36,
        "最高": 0.36,
        "最低": 0.35,
        "昨收": 0.355,
        "成交量": 2649000.0,
        "成交额": 940735.0
    },
    {
        "序号": 3141,
        "symbol": "01203",
        "name": "广南(集团)",
        "最新价": 0.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.55,
        "最高": 0.57,
        "最低": 0.55,
        "昨收": 0.57,
        "成交量": 70000.0,
        "成交额": 39540.0
    },
    {
        "序号": 3142,
        "symbol": "01201",
        "name": "天臣控股",
        "最新价": 0.086,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.086,
        "最高": 0.086,
        "最低": 0.086,
        "昨收": 0.086,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3143,
        "symbol": "01198",
        "name": "皇朝家居",
        "最新价": 1.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.32,
        "最高": 1.32,
        "最低": 1.32,
        "昨收": 1.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3144,
        "symbol": "01194",
        "name": "湾区黄金",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.35,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3145,
        "symbol": "01188",
        "name": "正道集团",
        "最新价": 0.012,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.012,
        "最高": 0.012,
        "最低": 0.011,
        "昨收": 0.012,
        "成交量": 4616000.0,
        "成交额": 54394.0
    },
    {
        "序号": 3146,
        "symbol": "01185",
        "name": "中国航天万源",
        "最新价": 0.062,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.062,
        "最高": 0.063,
        "最低": 0.062,
        "昨收": 0.062,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3147,
        "symbol": "01184",
        "name": "S.A.S. DRAGON",
        "最新价": 3.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.45,
        "最高": 3.45,
        "最低": 3.4,
        "昨收": 3.45,
        "成交量": 6000.0,
        "成交额": 20600.0
    },
    {
        "序号": 3148,
        "symbol": "01182",
        "name": "胜龙国际",
        "最新价": 0.147,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.147,
        "最高": 0.147,
        "最低": 0.147,
        "昨收": 0.147,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3149,
        "symbol": "01181",
        "name": "唐宫中国",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.5,
        "最低": 0.495,
        "昨收": 0.5,
        "成交量": 844000.0,
        "成交额": 421860.0
    },
    {
        "序号": 3150,
        "symbol": "01179",
        "name": "华住集团-S",
        "最新价": 27.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 27.6,
        "最高": 27.6,
        "最低": 26.8,
        "昨收": 27.0,
        "成交量": 1871400.0,
        "成交额": 50417821.0
    },
    {
        "序号": 3151,
        "symbol": "01172",
        "name": "融太集团",
        "最新价": 0.048,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.048,
        "最高": 0.048,
        "最低": 0.048,
        "昨收": 0.048,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3152,
        "symbol": "01170",
        "name": "信星集团",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.75,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3153,
        "symbol": "01168",
        "name": "百仕达控股",
        "最新价": 0.107,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.104,
        "最高": 0.107,
        "最低": 0.102,
        "昨收": 0.107,
        "成交量": 148000.0,
        "成交额": 15298.0
    },
    {
        "序号": 3154,
        "symbol": "01165",
        "name": "顺风清洁能源",
        "最新价": 0.018,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.017,
        "最高": 0.019,
        "最低": 0.016,
        "昨收": 0.018,
        "成交量": 5858000.0,
        "成交额": 104514.0
    },
    {
        "序号": 3155,
        "symbol": "01163",
        "name": "虎视传媒",
        "最新价": 0.165,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.155,
        "最高": 0.165,
        "最低": 0.154,
        "昨收": 0.165,
        "成交量": 360000.0,
        "成交额": 58767.0
    },
    {
        "序号": 3156,
        "symbol": "01161",
        "name": "奥思集团",
        "最新价": 1.41,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.4,
        "最高": 1.42,
        "最低": 1.39,
        "昨收": 1.41,
        "成交量": 148000.0,
        "成交额": 208260.0
    },
    {
        "序号": 3157,
        "symbol": "01160",
        "name": "金石资本集团",
        "最新价": 0.43,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.43,
        "昨收": 0.43,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3158,
        "symbol": "01157",
        "name": "中联重科",
        "最新价": 4.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.05,
        "最高": 4.07,
        "最低": 3.98,
        "昨收": 4.0,
        "成交量": 1913400.0,
        "成交额": 7669645.0
    },
    {
        "序号": 3159,
        "symbol": "01153",
        "name": "佳源服务",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.275,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3160,
        "symbol": "01145",
        "name": "勇利投资",
        "最新价": 0.065,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.065,
        "昨收": 0.065,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3161,
        "symbol": "01142",
        "name": "能源及能量环球",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.52,
        "昨收": 0.52,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3162,
        "symbol": "01141",
        "name": "民银资本",
        "最新价": 0.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.285,
        "最低": 0.27,
        "昨收": 0.27,
        "成交量": 178000.0,
        "成交额": 48420.0
    },
    {
        "序号": 3163,
        "symbol": "01130",
        "name": "中国环境资源",
        "最新价": 0.058,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.058,
        "最高": 0.058,
        "最低": 0.053,
        "昨收": 0.058,
        "成交量": 1936000.0,
        "成交额": 107536.0
    },
    {
        "序号": 3164,
        "symbol": "01129",
        "name": "中国水业集团",
        "最新价": 0.128,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.128,
        "最高": 0.128,
        "最低": 0.128,
        "昨收": 0.128,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3165,
        "symbol": "01126",
        "name": "德林国际",
        "最新价": 3.79,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.79,
        "最高": 3.79,
        "最低": 3.79,
        "昨收": 3.79,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3166,
        "symbol": "01124",
        "name": "沿海家园",
        "最新价": 0.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.018,
        "最高": 0.02,
        "最低": 0.018,
        "昨收": 0.02,
        "成交量": 175088.0,
        "成交额": 3256.0
    },
    {
        "序号": 3167,
        "symbol": "01123",
        "name": "中港照相",
        "最新价": 0.151,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.151,
        "最高": 0.151,
        "最低": 0.151,
        "昨收": 0.151,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3168,
        "symbol": "01121",
        "name": "金阳新能源",
        "最新价": 3.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.9,
        "最高": 3.93,
        "最低": 3.86,
        "昨收": 3.9,
        "成交量": 788000.0,
        "成交额": 3071560.0
    },
    {
        "序号": 3169,
        "symbol": "01120",
        "name": "雅视光学",
        "最新价": 0.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.78,
        "最高": 0.78,
        "最低": 0.78,
        "昨收": 0.78,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3170,
        "symbol": "01118",
        "name": "高力集团",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.75,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3171,
        "symbol": "01116",
        "name": "MAYER HOLDINGS",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.85,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3172,
        "symbol": "01107",
        "name": "当代置业",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.05,
        "最低": 0.05,
        "昨收": 0.05,
        "成交量": 2000.0,
        "成交额": 100.0
    },
    {
        "序号": 3173,
        "symbol": "01105",
        "name": "星岛",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.39,
        "最高": 0.39,
        "最低": 0.39,
        "昨收": 0.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3174,
        "symbol": "01104",
        "name": "亚太资源",
        "最新价": 0.93,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.93,
        "最高": 0.93,
        "最低": 0.91,
        "昨收": 0.93,
        "成交量": 70000.0,
        "成交额": 64200.0
    },
    {
        "序号": 3175,
        "symbol": "01103",
        "name": "大生农业金融",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.014,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3176,
        "symbol": "01102",
        "name": "环能国际",
        "最新价": 0.037,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.037,
        "最低": 0.033,
        "昨收": 0.037,
        "成交量": 42000.0,
        "成交额": 1344.0
    },
    {
        "序号": 3177,
        "symbol": "01101",
        "name": "华荣能源",
        "最新价": 0.025,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.024,
        "最高": 0.027,
        "最低": 0.024,
        "昨收": 0.025,
        "成交量": 119500.0,
        "成交额": 2937.0
    },
    {
        "序号": 3178,
        "symbol": "01094",
        "name": "承辉国际",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.64,
        "最高": 0.64,
        "最低": 0.64,
        "昨收": 0.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3179,
        "symbol": "01091",
        "name": "南方锰业",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.495,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3180,
        "symbol": "01090",
        "name": "大明国际",
        "最新价": 1.11,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.11,
        "最高": 1.11,
        "最低": 1.11,
        "昨收": 1.11,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3181,
        "symbol": "01087",
        "name": "威讯控股",
        "最新价": 0.33,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.325,
        "最高": 0.33,
        "最低": 0.32,
        "昨收": 0.33,
        "成交量": 8050.0,
        "成交额": 2605.0
    },
    {
        "序号": 3182,
        "symbol": "01085",
        "name": "亨鑫科技",
        "最新价": 1.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.38,
        "最高": 1.44,
        "最低": 1.38,
        "昨收": 1.39,
        "成交量": 66000.0,
        "成交额": 91780.0
    },
    {
        "序号": 3183,
        "symbol": "01084",
        "name": "绿新亲水胶体",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3184,
        "symbol": "01079",
        "name": "松景科技",
        "最新价": 0.037,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.037,
        "最低": 0.037,
        "昨收": 0.037,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3185,
        "symbol": "01076",
        "name": "博华太平洋",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.064,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3186,
        "symbol": "01071",
        "name": "华电国际电力股份",
        "最新价": 3.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.36,
        "最高": 3.38,
        "最低": 3.31,
        "昨收": 3.34,
        "成交量": 7024000.0,
        "成交额": 23522174.0
    },
    {
        "序号": 3187,
        "symbol": "01069",
        "name": "宝沙发展",
        "最新价": 0.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.061,
        "最高": 0.061,
        "最低": 0.06,
        "昨收": 0.06,
        "成交量": 276000.0,
        "成交额": 16532.0
    },
    {
        "序号": 3188,
        "symbol": "01064",
        "name": "中华国际",
        "最新价": 0.077,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.077,
        "最高": 0.077,
        "最低": 0.077,
        "昨收": 0.077,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3189,
        "symbol": "01063",
        "name": "新确科技",
        "最新价": 0.229,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.229,
        "最高": 0.229,
        "最低": 0.229,
        "昨收": 0.229,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3190,
        "symbol": "01060",
        "name": "阿里影业",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.52,
        "最低": 0.495,
        "昨收": 0.5,
        "成交量": 93380000.0,
        "成交额": 46964930.0
    },
    {
        "序号": 3191,
        "symbol": "01058",
        "name": "南粤控股",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.49,
        "昨收": 0.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3192,
        "symbol": "01053",
        "name": "重庆钢铁股份",
        "最新价": 0.71,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.72,
        "最高": 0.72,
        "最低": 0.71,
        "昨收": 0.71,
        "成交量": 258000.0,
        "成交额": 184780.0
    },
    {
        "序号": 3193,
        "symbol": "01049",
        "name": "时富投资",
        "最新价": 1.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.02,
        "最高": 1.02,
        "最低": 1.02,
        "昨收": 1.02,
        "成交量": 6.0,
        "成交额": 4.0
    },
    {
        "序号": 3194,
        "symbol": "01047",
        "name": "毅兴行",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.4,
        "最低": 0.4,
        "昨收": 0.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3195,
        "symbol": "01046",
        "name": "寰宇娱乐文化",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.28,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3196,
        "symbol": "01039",
        "name": "畅由联盟",
        "最新价": 0.139,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.139,
        "最高": 0.139,
        "最低": 0.139,
        "昨收": 0.139,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3197,
        "symbol": "01037",
        "name": "云智汇科技",
        "最新价": 0.164,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.164,
        "最高": 0.164,
        "最低": 0.15,
        "昨收": 0.164,
        "成交量": 1150000.0,
        "成交额": 182380.0
    },
    {
        "序号": 3198,
        "symbol": "01036",
        "name": "万科海外",
        "最新价": 1.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.26,
        "最高": 1.26,
        "最低": 1.25,
        "昨收": 1.26,
        "成交量": 28000.0,
        "成交额": 35130.0
    },
    {
        "序号": 3199,
        "symbol": "01027",
        "name": "中国集成控股",
        "最新价": 1.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.04,
        "最高": 1.05,
        "最低": 1.04,
        "昨收": 1.04,
        "成交量": 62825.0,
        "成交额": 65416.0
    },
    {
        "序号": 3200,
        "symbol": "01026",
        "name": "环球实业科技",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.17,
        "昨收": 0.17,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3201,
        "symbol": "01022",
        "name": "飞鱼科技",
        "最新价": 0.229,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.229,
        "昨收": 0.229,
        "成交量": 34500.0,
        "成交额": 7920.0
    },
    {
        "序号": 3202,
        "symbol": "01018",
        "name": "STOCK1018",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3203,
        "symbol": "01017",
        "name": "STOCK1017",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3204,
        "symbol": "01016",
        "name": "STOCK1016",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3205,
        "symbol": "01015",
        "name": "STOCK1015",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3206,
        "symbol": "01013",
        "name": "伟俊集团控股-新",
        "最新价": 0.186,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.186,
        "最高": 0.186,
        "最低": 0.172,
        "昨收": 0.186,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3207,
        "symbol": "01012",
        "name": "P ONTARIO B2024",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3208,
        "symbol": "01011",
        "name": "泰凌医药",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.068,
        "最高": 0.072,
        "最低": 0.067,
        "昨收": 0.07,
        "成交量": 14750500.0,
        "成交额": 1037983.0
    },
    {
        "序号": 3209,
        "symbol": "01010",
        "name": "巴克1798集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.23,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3210,
        "symbol": "01009",
        "name": "国际娱乐",
        "最新价": 0.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.83,
        "最高": 0.83,
        "最低": 0.83,
        "昨收": 0.83,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3211,
        "symbol": "01008",
        "name": "力图控股",
        "最新价": 0.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.151,
        "最高": 0.152,
        "最低": 0.144,
        "昨收": 0.15,
        "成交量": 228000.0,
        "成交额": 33890.0
    },
    {
        "序号": 3212,
        "symbol": "01007",
        "name": "龙辉国际控股",
        "最新价": 0.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.78,
        "最高": 0.78,
        "最低": 0.78,
        "昨收": 0.78,
        "成交量": 100.0,
        "成交额": 73.0
    },
    {
        "序号": 3213,
        "symbol": "01005",
        "name": "MATRIX HOLDINGS",
        "最新价": 1.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.25,
        "最高": 1.25,
        "最低": 1.25,
        "昨收": 1.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3214,
        "symbol": "01003",
        "name": "欢喜传媒",
        "最新价": 0.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.66,
        "最高": 0.66,
        "最低": 0.66,
        "昨收": 0.66,
        "成交量": 110000.0,
        "成交额": 72600.0
    },
    {
        "序号": 3215,
        "symbol": "01002",
        "name": "威铖国际",
        "最新价": 0.088,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.088,
        "最高": 0.088,
        "最低": 0.068,
        "昨收": 0.088,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3216,
        "symbol": "01001",
        "name": "沪港联合",
        "最新价": 0.305,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.305,
        "最高": 0.305,
        "最低": 0.305,
        "昨收": 0.305,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3217,
        "symbol": "01000",
        "name": "北青传媒",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.405,
        "最高": 0.405,
        "最低": 0.405,
        "昨收": 0.405,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3218,
        "symbol": "00988",
        "name": "丝路物流控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.186,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3219,
        "symbol": "00987",
        "name": "中国再生能源投资",
        "最新价": 0.117,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.117,
        "最高": 0.117,
        "最低": 0.117,
        "昨收": 0.117,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3220,
        "symbol": "00983",
        "name": "瑞安建业",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3221,
        "symbol": "00982",
        "name": "华发物业服务",
        "最新价": 0.148,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.148,
        "最低": 0.145,
        "昨收": 0.148,
        "成交量": 1280000.0,
        "成交额": 187600.0
    },
    {
        "序号": 3222,
        "symbol": "00980",
        "name": "联华超市",
        "最新价": 0.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.335,
        "最高": 0.34,
        "最低": 0.33,
        "昨收": 0.34,
        "成交量": 237000.0,
        "成交额": 79595.0
    },
    {
        "序号": 3223,
        "symbol": "00978",
        "name": "招商局置地",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.3,
        "最高": 0.305,
        "最低": 0.29,
        "昨收": 0.3,
        "成交量": 464000.0,
        "成交额": 139080.0
    },
    {
        "序号": 3224,
        "symbol": "00976",
        "name": "齐合环保",
        "最新价": 0.405,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.44,
        "最高": 0.44,
        "最低": 0.4,
        "昨收": 0.405,
        "成交量": 182000.0,
        "成交额": 75410.0
    },
    {
        "序号": 3225,
        "symbol": "00974",
        "name": "中国顺客隆",
        "最新价": 0.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.53,
        "昨收": 0.53,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3226,
        "symbol": "00969",
        "name": "华联国际",
        "最新价": 0.159,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.159,
        "最高": 0.159,
        "最低": 0.159,
        "昨收": 0.159,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3227,
        "symbol": "00954",
        "name": "常茂生物",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.56,
        "昨收": 0.56,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3228,
        "symbol": "00952",
        "name": "华富建业金融",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.179,
        "最高": 0.18,
        "最低": 0.179,
        "昨收": 0.18,
        "成交量": 40790.0,
        "成交额": 7304.0
    },
    {
        "序号": 3229,
        "symbol": "00943",
        "name": "中证国际",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.01,
        "最高": 0.01,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 200000.0,
        "成交额": 2000.0
    },
    {
        "序号": 3230,
        "symbol": "00936",
        "name": "佳兆业资本",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3231,
        "symbol": "00927",
        "name": "富士高实业",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.8,
        "昨收": 0.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3232,
        "symbol": "00924",
        "name": "坤集团",
        "最新价": 0.57,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.57,
        "最高": 0.57,
        "最低": 0.57,
        "昨收": 0.57,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3233,
        "symbol": "00922",
        "name": "安贤园中国",
        "最新价": 0.165,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.165,
        "最高": 0.165,
        "最低": 0.165,
        "昨收": 0.165,
        "成交量": 150000.0,
        "成交额": 24750.0
    },
    {
        "序号": 3234,
        "symbol": "00910",
        "name": "中国三迪",
        "最新价": 0.104,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.104,
        "最高": 0.104,
        "最低": 0.104,
        "昨收": 0.104,
        "成交量": 900.0,
        "成交额": 81.0
    },
    {
        "序号": 3235,
        "symbol": "00905",
        "name": "胡桃资本",
        "最新价": 0.117,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.117,
        "最高": 0.117,
        "最低": 0.117,
        "昨收": 0.117,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3236,
        "symbol": "00904",
        "name": "中绿",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.053,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3237,
        "symbol": "00901",
        "name": "金石投资集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.024,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3238,
        "symbol": "00899",
        "name": "亚洲资源",
        "最新价": 0.095,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.095,
        "最高": 0.095,
        "最低": 0.095,
        "昨收": 0.095,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3239,
        "symbol": "00898",
        "name": "万事昌国际",
        "最新价": 0.82,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.82,
        "最高": 0.82,
        "最低": 0.82,
        "昨收": 0.82,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3240,
        "symbol": "00894",
        "name": "万裕科技",
        "最新价": 0.445,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.445,
        "最高": 0.445,
        "最低": 0.445,
        "昨收": 0.445,
        "成交量": 4000.0,
        "成交额": 1780.0
    },
    {
        "序号": 3241,
        "symbol": "00893",
        "name": "中国铁钛",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.054,
        "昨收": 0.055,
        "成交量": 517000.0,
        "成交额": 27935.0
    },
    {
        "序号": 3242,
        "symbol": "00889",
        "name": "连达科技控股",
        "最新价": 0.32,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.32,
        "昨收": 0.32,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3243,
        "symbol": "00888",
        "name": "贝森金融",
        "最新价": 0.039,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.039,
        "最高": 0.039,
        "最低": 0.039,
        "昨收": 0.039,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3244,
        "symbol": "00882",
        "name": "天津发展",
        "最新价": 1.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.47,
        "最高": 1.47,
        "最低": 1.45,
        "昨收": 1.45,
        "成交量": 102000.0,
        "成交额": 148828.0
    },
    {
        "序号": 3245,
        "symbol": "00880",
        "name": "澳博控股",
        "最新价": 2.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.3,
        "最高": 2.34,
        "最低": 2.29,
        "昨收": 2.31,
        "成交量": 7756000.0,
        "成交额": 17934170.0
    },
    {
        "序号": 3246,
        "symbol": "00872",
        "name": "泰坦智华科技",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.06,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3247,
        "symbol": "00869",
        "name": "彩星玩具",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.64,
        "最高": 0.66,
        "最低": 0.64,
        "昨收": 0.64,
        "成交量": 564000.0,
        "成交额": 363920.0
    },
    {
        "序号": 3248,
        "symbol": "00865",
        "name": "建德国际控股",
        "最新价": 0.021,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.021,
        "最高": 0.022,
        "最低": 0.02,
        "昨收": 0.021,
        "成交量": 1656000.0,
        "成交额": 35264.0
    },
    {
        "序号": 3249,
        "symbol": "00864",
        "name": "永利地产发展",
        "最新价": 0.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.18,
        "最高": 0.18,
        "最低": 0.18,
        "昨收": 0.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3250,
        "symbol": "00862",
        "name": "远见控股",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 70000.0,
        "成交额": 2310.0
    },
    {
        "序号": 3251,
        "symbol": "00860",
        "name": "APOLLO出行",
        "最新价": 0.039,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.04,
        "最低": 0.037,
        "昨收": 0.039,
        "成交量": 2254000.0,
        "成交额": 86592.0
    },
    {
        "序号": 3252,
        "symbol": "00859",
        "name": "中昌国际控股",
        "最新价": 0.158,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.158,
        "最高": 0.158,
        "最低": 0.158,
        "昨收": 0.158,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3253,
        "symbol": "00858",
        "name": "精优药业",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.034,
        "最高": 0.037,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 3360000.0,
        "成交额": 114070.0
    },
    {
        "序号": 3254,
        "symbol": "00857",
        "name": "中国石油股份",
        "最新价": 4.86,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.88,
        "最高": 4.92,
        "最低": 4.83,
        "昨收": 4.86,
        "成交量": 63143123.0,
        "成交额": 307546640.0
    },
    {
        "序号": 3255,
        "symbol": "00854",
        "name": "威雅利",
        "最新价": 1.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.81,
        "最高": 1.81,
        "最低": 1.81,
        "昨收": 1.81,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3256,
        "symbol": "00851",
        "name": "盛源控股",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.28,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3257,
        "symbol": "00848",
        "name": "茂业国际",
        "最新价": 0.168,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.168,
        "最高": 0.168,
        "最低": 0.168,
        "昨收": 0.168,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3258,
        "symbol": "00846",
        "name": "明发集团",
        "最新价": 0.205,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.205,
        "最高": 0.205,
        "最低": 0.204,
        "昨收": 0.205,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3259,
        "symbol": "00845",
        "name": "恒盛地产",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.01,
        "最高": 0.01,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 10000.0,
        "成交额": 100.0
    },
    {
        "序号": 3260,
        "symbol": "00844",
        "name": "广泰国际控股",
        "最新价": 0.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.45,
        "最高": 0.48,
        "最低": 0.45,
        "昨收": 0.48,
        "成交量": 12000.0,
        "成交额": 5640.0
    },
    {
        "序号": 3261,
        "symbol": "00840",
        "name": "天业节水",
        "最新价": 0.155,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.155,
        "最高": 0.155,
        "最低": 0.155,
        "昨收": 0.155,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3262,
        "symbol": "00838",
        "name": "亿和控股",
        "最新价": 0.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.78,
        "最高": 0.79,
        "最低": 0.76,
        "昨收": 0.78,
        "成交量": 1882000.0,
        "成交额": 1468020.0
    },
    {
        "序号": 3263,
        "symbol": "00837",
        "name": "谭木匠",
        "最新价": 4.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.8,
        "最高": 4.85,
        "最低": 4.8,
        "昨收": 4.8,
        "成交量": 167500.0,
        "成交额": 804025.0
    },
    {
        "序号": 3264,
        "symbol": "00834",
        "name": "康大食品",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.24,
        "最高": 0.24,
        "最低": 0.24,
        "昨收": 0.24,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3265,
        "symbol": "00833",
        "name": "华讯",
        "最新价": 0.47,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.47,
        "最高": 0.47,
        "最低": 0.47,
        "昨收": 0.47,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3266,
        "symbol": "00831",
        "name": "利亚零售",
        "最新价": 0.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.64,
        "最高": 0.64,
        "最低": 0.64,
        "昨收": 0.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3267,
        "symbol": "00830",
        "name": "中国建筑兴业",
        "最新价": 2.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.38,
        "最高": 2.38,
        "最低": 2.35,
        "昨收": 2.38,
        "成交量": 68000.0,
        "成交额": 161020.0
    },
    {
        "序号": 3268,
        "symbol": "00829",
        "name": "神冠控股",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.27,
        "最高": 0.28,
        "最低": 0.27,
        "昨收": 0.28,
        "成交量": 66000.0,
        "成交额": 18090.0
    },
    {
        "序号": 3269,
        "symbol": "00822",
        "name": "嘉瑞国际",
        "最新价": 0.39,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.39,
        "最高": 0.39,
        "最低": 0.39,
        "昨收": 0.39,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3270,
        "symbol": "00821",
        "name": "汇盈控股",
        "最新价": 0.158,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.158,
        "最高": 0.158,
        "最低": 0.158,
        "昨收": 0.158,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3271,
        "symbol": "00820",
        "name": "汇丰中国翔龙基金",
        "最新价": 12.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 12.7,
        "最高": 12.7,
        "最低": 12.7,
        "昨收": 12.7,
        "成交量": 1000.0,
        "成交额": 12700.0
    },
    {
        "序号": 3272,
        "symbol": "00818",
        "name": "高阳科技",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.5,
        "最低": 0.49,
        "昨收": 0.5,
        "成交量": 189000.0,
        "成交额": 94110.0
    },
    {
        "序号": 3273,
        "symbol": "00815",
        "name": "中国白银集团",
        "最新价": 0.152,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.152,
        "最高": 0.153,
        "最低": 0.144,
        "昨收": 0.152,
        "成交量": 2516000.0,
        "成交额": 372686.0
    },
    {
        "序号": 3274,
        "symbol": "00812",
        "name": "西证国际证券",
        "最新价": 0.022,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.022,
        "最高": 0.022,
        "最低": 0.022,
        "昨收": 0.022,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3275,
        "symbol": "00807",
        "name": "上海实业环境",
        "最新价": 1.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.03,
        "最高": 1.04,
        "最低": 1.0,
        "昨收": 1.02,
        "成交量": 378000.0,
        "成交额": 385280.0
    },
    {
        "序号": 3276,
        "symbol": "00804",
        "name": "鼎石资本",
        "最新价": 0.175,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.175,
        "最高": 0.175,
        "最低": 0.175,
        "昨收": 0.175,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3277,
        "symbol": "00797",
        "name": "第七大道",
        "最新价": 2.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.16,
        "最高": 2.16,
        "最低": 2.16,
        "昨收": 2.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3278,
        "symbol": "00794",
        "name": "锦胜集团(控股)",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.19,
        "最高": 0.19,
        "最低": 0.19,
        "昨收": 0.19,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3279,
        "symbol": "00788",
        "name": "中国铁塔",
        "最新价": 0.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.81,
        "最高": 0.81,
        "最低": 0.8,
        "昨收": 0.81,
        "成交量": 64940639.0,
        "成交额": 52259803.0
    },
    {
        "序号": 3280,
        "symbol": "00784",
        "name": "凌锐控股",
        "最新价": 0.102,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.102,
        "最高": 0.102,
        "最低": 0.102,
        "昨收": 0.102,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3281,
        "symbol": "00770",
        "name": "SHANGHAI GROWTH",
        "最新价": 0.14,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.14,
        "最高": 0.14,
        "最低": 0.14,
        "昨收": 0.14,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3282,
        "symbol": "00768",
        "name": "开明投资",
        "最新价": 0.019,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.019,
        "最高": 0.019,
        "最低": 0.019,
        "昨收": 0.019,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3283,
        "symbol": "00767",
        "name": "中基长寿科学",
        "最新价": 0.155,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.155,
        "最高": 0.155,
        "最低": 0.155,
        "昨收": 0.155,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3284,
        "symbol": "00765",
        "name": "PERFECTECH INTL",
        "最新价": 1.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.29,
        "最高": 1.29,
        "最低": 1.29,
        "昨收": 1.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3285,
        "symbol": "00764",
        "name": "永恒策略",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.05,
        "最低": 0.05,
        "昨收": 0.05,
        "成交量": 225.0,
        "成交额": 10.0
    },
    {
        "序号": 3286,
        "symbol": "00760",
        "name": "新天地产集团",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.17,
        "最高": 0.17,
        "最低": 0.17,
        "昨收": 0.17,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3287,
        "symbol": "00758",
        "name": "新华联资本",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.168,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3288,
        "symbol": "00751",
        "name": "创维集团",
        "最新价": 2.95,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.94,
        "最高": 3.01,
        "最低": 2.87,
        "昨收": 2.95,
        "成交量": 6621434.0,
        "成交额": 19491730.0
    },
    {
        "序号": 3289,
        "symbol": "00750",
        "name": "水发兴业能源",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.365,
        "最高": 0.38,
        "最低": 0.36,
        "昨收": 0.38,
        "成交量": 204200.0,
        "成交额": 75559.0
    },
    {
        "序号": 3290,
        "symbol": "00747",
        "name": "渖阳公用发展股份",
        "最新价": 0.065,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.064,
        "昨收": 0.065,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3291,
        "symbol": "00746",
        "name": "理文化工",
        "最新价": 2.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.94,
        "最高": 2.94,
        "最低": 2.93,
        "昨收": 2.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3292,
        "symbol": "00745",
        "name": "中国国家文化产业",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.23,
        "昨收": 0.23,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3293,
        "symbol": "00738",
        "name": "莱尔斯丹",
        "最新价": 0.415,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.415,
        "最高": 0.415,
        "最低": 0.415,
        "昨收": 0.415,
        "成交量": 200.0,
        "成交额": 76.0
    },
    {
        "序号": 3294,
        "symbol": "00731",
        "name": "建发新胜",
        "最新价": 0.225,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.225,
        "最低": 0.22,
        "昨收": 0.225,
        "成交量": 40000.0,
        "成交额": 8900.0
    },
    {
        "序号": 3295,
        "symbol": "00727",
        "name": "皇冠环球集团",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.042,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3296,
        "symbol": "00725",
        "name": "恒都集团",
        "最新价": 0.59,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.59,
        "最高": 0.59,
        "最低": 0.59,
        "昨收": 0.59,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3297,
        "symbol": "00724",
        "name": "瑞鑫国际集团",
        "最新价": 0.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.16,
        "最高": 0.16,
        "最低": 0.16,
        "昨收": 0.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3298,
        "symbol": "00723",
        "name": "信保环球控股",
        "最新价": 0.011,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.011,
        "最高": 0.011,
        "最低": 0.011,
        "昨收": 0.011,
        "成交量": 20000.0,
        "成交额": 60.0
    },
    {
        "序号": 3299,
        "symbol": "00721",
        "name": "中国金融国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.027,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3300,
        "symbol": "00715",
        "name": "中泛控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.025,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3301,
        "symbol": "00711",
        "name": "亚洲联合基建控股",
        "最新价": 0.52,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.52,
        "最高": 0.52,
        "最低": 0.51,
        "昨收": 0.52,
        "成交量": 1920000.0,
        "成交额": 998360.0
    },
    {
        "序号": 3302,
        "symbol": "00704",
        "name": "和嘉控股",
        "最新价": 0.182,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.182,
        "最高": 0.182,
        "最低": 0.182,
        "昨收": 0.182,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3303,
        "symbol": "00703",
        "name": "FUTURE BRIGHT",
        "最新价": 0.19,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.19,
        "最高": 0.19,
        "最低": 0.19,
        "昨收": 0.19,
        "成交量": 30000.0,
        "成交额": 5700.0
    },
    {
        "序号": 3304,
        "symbol": "00701",
        "name": "CNT GROUP",
        "最新价": 0.315,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.315,
        "最高": 0.315,
        "最低": 0.315,
        "昨收": 0.315,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3305,
        "symbol": "00695",
        "name": "东吴水泥",
        "最新价": 2.99,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.97,
        "最高": 3.0,
        "最低": 2.96,
        "昨收": 2.99,
        "成交量": 108000.0,
        "成交额": 321520.0
    },
    {
        "序号": 3306,
        "symbol": "00693",
        "name": "陈唱国际",
        "最新价": 1.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.48,
        "最高": 1.48,
        "最低": 1.48,
        "昨收": 1.48,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3307,
        "symbol": "00691",
        "name": "山水水泥",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3308,
        "symbol": "00681",
        "name": "中民控股",
        "最新价": 0.025,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.027,
        "最高": 0.027,
        "最低": 0.025,
        "昨收": 0.025,
        "成交量": 132528.0,
        "成交额": 3554.0
    },
    {
        "序号": 3309,
        "symbol": "00679",
        "name": "亚洲联网科技",
        "最新价": 0.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.9,
        "最高": 0.9,
        "最低": 0.9,
        "昨收": 0.9,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3310,
        "symbol": "00676",
        "name": "创信国际",
        "最新价": 0.67,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.67,
        "最高": 0.67,
        "最低": 0.67,
        "昨收": 0.67,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3311,
        "symbol": "00666",
        "name": "瑞浦兰钧",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": null,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3312,
        "symbol": "00665",
        "name": "海通国际",
        "最新价": 1.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.5,
        "最高": 1.51,
        "最低": 1.5,
        "昨收": 1.5,
        "成交量": 15943541.0,
        "成交额": 23933904.0
    },
    {
        "序号": 3313,
        "symbol": "00663",
        "name": "金山能源",
        "最新价": 0.118,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.118,
        "最高": 0.118,
        "最低": 0.117,
        "昨收": 0.118,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3314,
        "symbol": "00661",
        "name": "中国大冶有色金属",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.054,
        "最高": 0.055,
        "最低": 0.053,
        "昨收": 0.055,
        "成交量": 908500.0,
        "成交额": 48194.0
    },
    {
        "序号": 3315,
        "symbol": "00660",
        "name": "玮俊生物科技",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.145,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3316,
        "symbol": "00658",
        "name": "中国高速传动",
        "最新价": 1.62,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.62,
        "最高": 1.65,
        "最低": 1.6,
        "昨收": 1.62,
        "成交量": 4289000.0,
        "成交额": 6978500.0
    },
    {
        "序号": 3317,
        "symbol": "00657",
        "name": "环科国际",
        "最新价": 0.026,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.026,
        "最高": 0.026,
        "最低": 0.026,
        "昨收": 0.026,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3318,
        "symbol": "00653",
        "name": "卓悦控股",
        "最新价": 0.045,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.045,
        "最高": 0.045,
        "最低": 0.045,
        "昨收": 0.045,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3319,
        "symbol": "00648",
        "name": "京玖康疗-新",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.38,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3320,
        "symbol": "00646",
        "name": "中国环保科技",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3321,
        "symbol": "00640",
        "name": "星谦发展",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.65,
        "最低": 0.65,
        "昨收": 0.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3322,
        "symbol": "00638",
        "name": "建溢集团",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.51,
        "最高": 0.55,
        "最低": 0.495,
        "昨收": 0.51,
        "成交量": 234000.0,
        "成交额": 120270.0
    },
    {
        "序号": 3323,
        "symbol": "00637",
        "name": "利记",
        "最新价": 0.173,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.174,
        "最高": 0.174,
        "最低": 0.172,
        "昨收": 0.173,
        "成交量": 50000.0,
        "成交额": 8640.0
    },
    {
        "序号": 3324,
        "symbol": "00635",
        "name": "彩星集团",
        "最新价": 0.56,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.56,
        "昨收": 0.56,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3325,
        "symbol": "00630",
        "name": "隽泰控股",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3326,
        "symbol": "00623",
        "name": "中视金桥",
        "最新价": 0.93,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.93,
        "最高": 0.93,
        "最低": 0.93,
        "昨收": 0.93,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3327,
        "symbol": "00622",
        "name": "威华达控股",
        "最新价": 0.2,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.2,
        "最高": 0.2,
        "最低": 0.2,
        "昨收": 0.2,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3328,
        "symbol": "00621",
        "name": "坛金矿业",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.029,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3329,
        "symbol": "00619",
        "name": "南华金融",
        "最新价": 0.265,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.265,
        "最高": 0.265,
        "最低": 0.265,
        "昨收": 0.265,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3330,
        "symbol": "00611",
        "name": "中国核能科技",
        "最新价": 0.265,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.265,
        "最高": 0.265,
        "最低": 0.26,
        "昨收": 0.265,
        "成交量": 182000.0,
        "成交额": 47920.0
    },
    {
        "序号": 3331,
        "symbol": "00609",
        "name": "天德化工",
        "最新价": 1.36,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.36,
        "最高": 1.36,
        "最低": 1.36,
        "昨收": 1.36,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3332,
        "symbol": "00608",
        "name": "达利国际",
        "最新价": 1.49,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.49,
        "最高": 1.49,
        "最低": 1.49,
        "昨收": 1.49,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3333,
        "symbol": "00606",
        "name": "中骏商管",
        "最新价": 0.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.55,
        "最高": 0.55,
        "最低": 0.51,
        "昨收": 0.55,
        "成交量": 1000000.0,
        "成交额": 540010.0
    },
    {
        "序号": 3334,
        "symbol": "00605",
        "name": "中国金融投资管理",
        "最新价": 0.295,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.295,
        "最高": 0.295,
        "最低": 0.295,
        "昨收": 0.295,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3335,
        "symbol": "00602",
        "name": "佳华百货控股",
        "最新价": 0.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.05,
        "最低": 0.05,
        "昨收": 0.05,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3336,
        "symbol": "00600",
        "name": "中国基建投资",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.39,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3337,
        "symbol": "00599",
        "name": "怡邦行控股",
        "最新价": 0.156,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.156,
        "最高": 0.156,
        "最低": 0.156,
        "昨收": 0.156,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3338,
        "symbol": "00592",
        "name": "BOSSINI INT'L",
        "最新价": 0.17,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.175,
        "最高": 0.175,
        "最低": 0.166,
        "昨收": 0.17,
        "成交量": 60000.0,
        "成交额": 10140.0
    },
    {
        "序号": 3339,
        "symbol": "00589",
        "name": "建中建设",
        "最新价": 0.245,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.245,
        "最高": 0.245,
        "最低": 0.245,
        "昨收": 0.245,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3340,
        "symbol": "00588",
        "name": "北京北辰实业股份",
        "最新价": 0.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.71,
        "最高": 0.72,
        "最低": 0.71,
        "昨收": 0.72,
        "成交量": 360000.0,
        "成交额": 258060.0
    },
    {
        "序号": 3341,
        "symbol": "00582",
        "name": "神话世界",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.042,
        "最高": 0.046,
        "最低": 0.041,
        "昨收": 0.043,
        "成交量": 67200.0,
        "成交额": 2898.0
    },
    {
        "序号": 3342,
        "symbol": "00575",
        "name": "励晶太平洋",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3343,
        "symbol": "00574",
        "name": "百信国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.062,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3344,
        "symbol": "00573",
        "name": "稻香控股",
        "最新价": 0.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.74,
        "最高": 0.74,
        "最低": 0.74,
        "昨收": 0.74,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3345,
        "symbol": "00571",
        "name": "丰德丽控股",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3346,
        "symbol": "00567",
        "name": "大昌微线集团",
        "最新价": 0.076,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.076,
        "最高": 0.076,
        "最低": 0.076,
        "昨收": 0.076,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3347,
        "symbol": "00565",
        "name": "锦艺集团控股",
        "最新价": 0.26,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.265,
        "最高": 0.265,
        "最低": 0.2,
        "昨收": 0.26,
        "成交量": 6760000.0,
        "成交额": 1414495.0
    },
    {
        "序号": 3348,
        "symbol": "00560",
        "name": "珠江船务",
        "最新价": 0.78,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.78,
        "最高": 0.78,
        "最低": 0.78,
        "昨收": 0.78,
        "成交量": 64000.0,
        "成交额": 49920.0
    },
    {
        "序号": 3349,
        "symbol": "00556",
        "name": "泛亚环保",
        "最新价": 0.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.27,
        "最高": 0.27,
        "最低": 0.255,
        "昨收": 0.27,
        "成交量": 10000.0,
        "成交额": 2660.0
    },
    {
        "序号": 3350,
        "symbol": "00543",
        "name": "太平洋网络",
        "最新价": 0.46,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.46,
        "最高": 0.46,
        "最低": 0.46,
        "昨收": 0.46,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3351,
        "symbol": "00540",
        "name": "迅捷环球控股",
        "最新价": 0.091,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.091,
        "最高": 0.091,
        "最低": 0.091,
        "昨收": 0.091,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3352,
        "symbol": "00532",
        "name": "WKK INTL (HOLD)",
        "最新价": 0.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.54,
        "最高": 0.54,
        "最低": 0.51,
        "昨收": 0.51,
        "成交量": 60000.0,
        "成交额": 32400.0
    },
    {
        "序号": 3353,
        "symbol": "00529",
        "name": "SIS INT'L",
        "最新价": 1.54,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.54,
        "最高": 1.54,
        "最低": 1.54,
        "昨收": 1.54,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3354,
        "symbol": "00527",
        "name": "瑞风新能源",
        "最新价": 0.275,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.275,
        "昨收": 0.275,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3355,
        "symbol": "00526",
        "name": "利时集团控股",
        "最新价": 0.041,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.039,
        "最高": 0.041,
        "最低": 0.039,
        "昨收": 0.041,
        "成交量": 572000.0,
        "成交额": 22790.0
    },
    {
        "序号": 3356,
        "symbol": "00524",
        "name": "长城天下",
        "最新价": 0.27,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.27,
        "最高": 0.27,
        "最低": 0.27,
        "昨收": 0.27,
        "成交量": 11.0,
        "成交额": 2.0
    },
    {
        "序号": 3357,
        "symbol": "00521",
        "name": "CWT INT'L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.06,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3358,
        "symbol": "00518",
        "name": "同得仕(集团)",
        "最新价": 0.24,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.24,
        "最高": 0.24,
        "最低": 0.24,
        "昨收": 0.24,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3359,
        "symbol": "00515",
        "name": "中华银科技",
        "最新价": 0.073,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.073,
        "最高": 0.073,
        "最低": 0.072,
        "昨收": 0.073,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3360,
        "symbol": "00510",
        "name": "时富金融服务集团",
        "最新价": 0.37,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.37,
        "最高": 0.37,
        "最低": 0.37,
        "昨收": 0.37,
        "成交量": 2.0,
        "成交额": null
    },
    {
        "序号": 3361,
        "symbol": "00505",
        "name": "兴业合金",
        "最新价": 1.06,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.06,
        "最高": 1.06,
        "最低": 1.06,
        "昨收": 1.06,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3362,
        "symbol": "00503",
        "name": "朗生医药",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.79,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3363,
        "symbol": "00500",
        "name": "先丰服务集团",
        "最新价": 0.185,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.185,
        "最高": 0.185,
        "最低": 0.185,
        "昨收": 0.185,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3364,
        "symbol": "00496",
        "name": "卡森国际",
        "最新价": 0.3,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.295,
        "昨收": 0.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3365,
        "symbol": "00495",
        "name": "PALADIN",
        "最新价": 0.041,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.041,
        "最高": 0.041,
        "最低": 0.041,
        "昨收": 0.041,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3366,
        "symbol": "00491",
        "name": "英皇文化产业",
        "最新价": 0.037,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.037,
        "最高": 0.037,
        "最低": 0.037,
        "昨收": 0.037,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3367,
        "symbol": "00485",
        "name": "中国华星",
        "最新价": 0.123,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.123,
        "最高": 0.123,
        "最低": 0.123,
        "昨收": 0.123,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3368,
        "symbol": "00482",
        "name": "圣马丁国际",
        "最新价": 0.079,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.079,
        "最高": 0.079,
        "最低": 0.079,
        "昨收": 0.079,
        "成交量": 52200.0,
        "成交额": 3903.0
    },
    {
        "序号": 3369,
        "symbol": "00480",
        "name": "香港兴业国际",
        "最新价": 1.53,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.53,
        "最高": 1.53,
        "最低": 1.53,
        "昨收": 1.53,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3370,
        "symbol": "00475",
        "name": "中发展控股",
        "最新价": 0.6,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.6,
        "最低": 0.6,
        "昨收": 0.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3371,
        "symbol": "00467",
        "name": "联合能源集团",
        "最新价": 0.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.73,
        "最高": 0.75,
        "最低": 0.72,
        "昨收": 0.73,
        "成交量": 250326000.0,
        "成交额": 182817220.0
    },
    {
        "序号": 3372,
        "symbol": "00465",
        "name": "富通科技",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3373,
        "symbol": "00464",
        "name": "中国海外诺信",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.55,
        "最高": 0.55,
        "最低": 0.5,
        "昨收": 0.5,
        "成交量": 84000.0,
        "成交额": 42600.0
    },
    {
        "序号": 3374,
        "symbol": "00458",
        "name": "联亚集团",
        "最新价": 2.05,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.05,
        "最高": 2.05,
        "最低": 2.05,
        "昨收": 2.05,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3375,
        "symbol": "00456",
        "name": "新城市建设发展",
        "最新价": 0.7,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.7,
        "昨收": 0.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3376,
        "symbol": "00450",
        "name": "鸿兴印刷集团",
        "最新价": 1.04,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.03,
        "最高": 1.05,
        "最低": 1.03,
        "昨收": 1.04,
        "成交量": 44000.0,
        "成交额": 45720.0
    },
    {
        "序号": 3377,
        "symbol": "00444",
        "name": "SINCEREWATCH HK",
        "最新价": 0.036,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.036,
        "最高": 0.042,
        "最低": 0.035,
        "昨收": 0.036,
        "成交量": 1330000.0,
        "成交额": 48430.0
    },
    {
        "序号": 3378,
        "symbol": "00442",
        "name": "域能控股",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.4,
        "最高": 1.4,
        "最低": 1.4,
        "昨收": 1.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3379,
        "symbol": "00436",
        "name": "新宇环保",
        "最新价": 0.066,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.066,
        "最高": 0.066,
        "最低": 0.066,
        "昨收": 0.066,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3380,
        "symbol": "00433",
        "name": "北方矿业",
        "最新价": 0.067,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.067,
        "最高": 0.069,
        "最低": 0.067,
        "昨收": 0.067,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3381,
        "symbol": "00430",
        "name": "东方兴业控股",
        "最新价": 0.295,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.295,
        "最高": 0.295,
        "最低": 0.295,
        "昨收": 0.295,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3382,
        "symbol": "00426",
        "name": "万华媒体",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3383,
        "symbol": "00423",
        "name": "经济日报集团",
        "最新价": 0.94,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.95,
        "最高": 0.95,
        "最低": 0.94,
        "昨收": 0.94,
        "成交量": 4000.0,
        "成交额": 3780.0
    },
    {
        "序号": 3384,
        "symbol": "00420",
        "name": "福田实业",
        "最新价": 0.395,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.395,
        "最高": 0.395,
        "最低": 0.395,
        "昨收": 0.395,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3385,
        "symbol": "00416",
        "name": "锦州银行",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 1.38,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3386,
        "symbol": "00411",
        "name": "南顺(香港)",
        "最新价": 8.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 8.0,
        "最高": 8.0,
        "最低": 8.0,
        "昨收": 8.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3387,
        "symbol": "00408",
        "name": "叶氏化工集团",
        "最新价": 1.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.55,
        "最高": 1.55,
        "最低": 1.55,
        "昨收": 1.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3388,
        "symbol": "00406",
        "name": "有利集团",
        "最新价": 1.16,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.16,
        "最高": 1.16,
        "最低": 1.16,
        "昨收": 1.16,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3389,
        "symbol": "00401",
        "name": "万嘉集团",
        "最新价": 0.099,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.099,
        "最高": 0.099,
        "最低": 0.099,
        "昨收": 0.099,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3390,
        "symbol": "00399",
        "name": "领航医药生物科技",
        "最新价": 0.212,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.213,
        "最高": 0.213,
        "最低": 0.206,
        "昨收": 0.212,
        "成交量": 55000.0,
        "成交额": 11420.0
    },
    {
        "序号": 3391,
        "symbol": "00398",
        "name": "东方表行集团",
        "最新价": 4.13,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 4.13,
        "最高": 4.15,
        "最低": 4.12,
        "昨收": 4.13,
        "成交量": 260000.0,
        "成交额": 1076580.0
    },
    {
        "序号": 3392,
        "symbol": "00393",
        "name": "旭日企业",
        "最新价": 0.83,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.83,
        "最高": 0.83,
        "最低": 0.82,
        "昨收": 0.83,
        "成交量": 56000.0,
        "成交额": 46440.0
    },
    {
        "序号": 3393,
        "symbol": "00391",
        "name": "美亚娱乐资讯",
        "最新价": 0.165,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.165,
        "最高": 0.165,
        "最低": 0.165,
        "昨收": 0.165,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3394,
        "symbol": "00385",
        "name": "建联集团",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.4,
        "最低": 0.4,
        "昨收": 0.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3395,
        "symbol": "00383",
        "name": "中国医疗网络",
        "最新价": 0.81,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.81,
        "最高": 0.81,
        "最低": 0.81,
        "昨收": 0.81,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3396,
        "symbol": "00381",
        "name": "侨雄国际",
        "最新价": 0.095,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.091,
        "最高": 0.097,
        "最低": 0.091,
        "昨收": 0.095,
        "成交量": 206000.0,
        "成交额": 18860.0
    },
    {
        "序号": 3397,
        "symbol": "00380",
        "name": "中国管业",
        "最新价": 0.07,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.07,
        "最高": 0.07,
        "最低": 0.07,
        "昨收": 0.07,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3398,
        "symbol": "00379",
        "name": "恒嘉融资租赁",
        "最新价": 0.056,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.056,
        "最高": 0.056,
        "最低": 0.056,
        "昨收": 0.056,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3399,
        "symbol": "00375",
        "name": "YGM TRADING",
        "最新价": 1.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.29,
        "最高": 1.29,
        "最低": 1.29,
        "昨收": 1.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3400,
        "symbol": "00374",
        "name": "四洲集团",
        "最新价": 2.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.38,
        "最高": 2.52,
        "最低": 2.32,
        "昨收": 2.5,
        "成交量": 50000.0,
        "成交额": 118980.0
    },
    {
        "序号": 3401,
        "symbol": "00373",
        "name": "联合集团",
        "最新价": 1.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.58,
        "最高": 1.58,
        "最低": 1.58,
        "昨收": 1.58,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3402,
        "symbol": "00372",
        "name": "保德国际发展",
        "最新价": 0.063,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.062,
        "最高": 0.063,
        "最低": 0.062,
        "昨收": 0.063,
        "成交量": 1652400.0,
        "成交额": 102457.0
    },
    {
        "序号": 3403,
        "symbol": "00371",
        "name": "北控水务集团",
        "最新价": 1.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.51,
        "最高": 1.53,
        "最低": 1.5,
        "昨收": 1.51,
        "成交量": 10651750.0,
        "成交额": 16108002.0
    },
    {
        "序号": 3404,
        "symbol": "00370",
        "name": "国华",
        "最新价": 0.224,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.224,
        "最高": 0.224,
        "最低": 0.223,
        "昨收": 0.224,
        "成交量": 99100.0,
        "成交额": 20705.0
    },
    {
        "序号": 3405,
        "symbol": "00369",
        "name": "永泰地产",
        "最新价": 2.73,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.73,
        "最高": 2.73,
        "最低": 2.73,
        "昨收": 2.73,
        "成交量": 12000.0,
        "成交额": 32760.0
    },
    {
        "序号": 3406,
        "symbol": "00368",
        "name": "德合集团",
        "最新价": 0.34,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.34,
        "最高": 0.34,
        "最低": 0.34,
        "昨收": 0.34,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3407,
        "symbol": "00366",
        "name": "陆氏集团(越南)",
        "最新价": 0.91,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.91,
        "最高": 0.91,
        "最低": 0.91,
        "昨收": 0.91,
        "成交量": 6000.0,
        "成交额": 5460.0
    },
    {
        "序号": 3408,
        "symbol": "00362",
        "name": "中国天化工",
        "最新价": 0.101,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.098,
        "最高": 0.102,
        "最低": 0.097,
        "昨收": 0.101,
        "成交量": 2020250.0,
        "成交额": 201961.0
    },
    {
        "序号": 3409,
        "symbol": "00361",
        "name": "顺龙控股",
        "最新价": 0.044,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.044,
        "最低": 0.042,
        "昨收": 0.044,
        "成交量": 1300000.0,
        "成交额": 55800.0
    },
    {
        "序号": 3410,
        "symbol": "00355",
        "name": "世纪城市国际",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.246,
        "最高": 0.25,
        "最低": 0.246,
        "昨收": 0.25,
        "成交量": 16000.0,
        "成交额": 3984.0
    },
    {
        "序号": 3411,
        "symbol": "00353",
        "name": "能源国际投资",
        "最新价": 0.85,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.84,
        "最高": 0.89,
        "最低": 0.82,
        "昨收": 0.85,
        "成交量": 1468000.0,
        "成交额": 1244960.0
    },
    {
        "序号": 3412,
        "symbol": "00348",
        "name": "中国智能健康",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.043,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3413,
        "symbol": "00347",
        "name": "鞍钢股份",
        "最新价": 1.51,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.5,
        "最高": 1.53,
        "最低": 1.49,
        "昨收": 1.51,
        "成交量": 10406670.0,
        "成交额": 15646659.0
    },
    {
        "序号": 3414,
        "symbol": "00346",
        "name": "延长石油国际",
        "最新价": 0.355,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.355,
        "最低": 0.345,
        "昨收": 0.355,
        "成交量": 25500.0,
        "成交额": 8687.0
    },
    {
        "序号": 3415,
        "symbol": "00340",
        "name": "潼关黄金",
        "最新价": 0.465,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.465,
        "最高": 0.465,
        "最低": 0.46,
        "昨收": 0.465,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3416,
        "symbol": "00336",
        "name": "华宝国际",
        "最新价": 2.48,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.47,
        "最高": 2.48,
        "最低": 2.44,
        "昨收": 2.48,
        "成交量": 1097000.0,
        "成交额": 2697560.0
    },
    {
        "序号": 3417,
        "symbol": "00333",
        "name": "黛丽斯国际",
        "最新价": 0.345,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.345,
        "昨收": 0.345,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3418,
        "symbol": "00321",
        "name": "德永佳集团",
        "最新价": 1.02,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.02,
        "最高": 1.02,
        "最低": 1.01,
        "昨收": 1.02,
        "成交量": 246000.0,
        "成交额": 249020.0
    },
    {
        "序号": 3419,
        "symbol": "00318",
        "name": "黄河实业",
        "最新价": 0.315,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.315,
        "最高": 0.315,
        "最低": 0.315,
        "昨收": 0.315,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3420,
        "symbol": "00315",
        "name": "数码通电讯",
        "最新价": 3.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.9,
        "最高": 3.93,
        "最低": 3.9,
        "昨收": 3.9,
        "成交量": 179500.0,
        "成交额": 701440.0
    },
    {
        "序号": 3421,
        "symbol": "00313",
        "name": "裕田中国",
        "最新价": 0.01,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.01,
        "最高": 0.01,
        "最低": 0.01,
        "昨收": 0.01,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3422,
        "symbol": "00312",
        "name": "岁宝百货",
        "最新价": 0.058,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.058,
        "最高": 0.058,
        "最低": 0.058,
        "昨收": 0.058,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3423,
        "symbol": "00309",
        "name": "新华通讯频媒",
        "最新价": 0.032,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.031,
        "最高": 0.032,
        "最低": 0.031,
        "昨收": 0.032,
        "成交量": 8000.0,
        "成交额": 248.0
    },
    {
        "序号": 3424,
        "symbol": "00301",
        "name": "三和精化",
        "最新价": 1.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.28,
        "最高": 1.28,
        "最低": 1.28,
        "昨收": 1.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3425,
        "symbol": "00299",
        "name": "宝新置地",
        "最新价": 0.25,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.25,
        "昨收": 0.25,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3426,
        "symbol": "00298",
        "name": "庄士中国",
        "最新价": 0.149,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.149,
        "最高": 0.149,
        "最低": 0.149,
        "昨收": 0.149,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3427,
        "symbol": "00296",
        "name": "英皇娱乐酒店",
        "最新价": 0.42,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.42,
        "最低": 0.4,
        "昨收": 0.42,
        "成交量": 114800.0,
        "成交额": 47374.0
    },
    {
        "序号": 3428,
        "symbol": "00295",
        "name": "江山控股",
        "最新价": 0.033,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.033,
        "最高": 0.033,
        "最低": 0.033,
        "昨收": 0.033,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3429,
        "symbol": "00294",
        "name": "长江制衣",
        "最新价": 1.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.15,
        "最高": 1.15,
        "最低": 1.15,
        "昨收": 1.15,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3430,
        "symbol": "00292",
        "name": "泛海酒店",
        "最新价": 0.086,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.086,
        "最高": 0.086,
        "最低": 0.086,
        "昨收": 0.086,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3431,
        "symbol": "00289",
        "name": "WING ON CO",
        "最新价": 11.88,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 11.88,
        "最高": 11.88,
        "最低": 11.88,
        "昨收": 11.88,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3432,
        "symbol": "00287",
        "name": "永发置业",
        "最新价": 5.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.8,
        "最高": 5.8,
        "最低": 5.8,
        "昨收": 5.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3433,
        "symbol": "00286",
        "name": "爱帝宫",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.285,
        "最高": 0.29,
        "最低": 0.27,
        "昨收": 0.29,
        "成交量": 13540010.0,
        "成交额": 3745122.0
    },
    {
        "序号": 3434,
        "symbol": "00278",
        "name": "华厦置业",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 4.06,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3435,
        "symbol": "00277",
        "name": "太兴置业",
        "最新价": 2.788,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.788,
        "最高": 2.788,
        "最低": 2.788,
        "昨收": 2.788,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3436,
        "symbol": "00271",
        "name": "亚证地产",
        "最新价": 0.179,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.179,
        "最高": 0.179,
        "最低": 0.179,
        "昨收": 0.179,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3437,
        "symbol": "00269",
        "name": "中国资源交通",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.01,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3438,
        "symbol": "00266",
        "name": "天德地产",
        "最新价": 2.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.4,
        "最高": 2.4,
        "最低": 2.4,
        "昨收": 2.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3439,
        "symbol": "00260",
        "name": "幸福控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.01,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3440,
        "symbol": "00259",
        "name": "亿都(国际控股)",
        "最新价": 2.77,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.78,
        "最高": 2.78,
        "最低": 2.77,
        "昨收": 2.77,
        "成交量": 182000.0,
        "成交额": 505300.0
    },
    {
        "序号": 3441,
        "symbol": "00258",
        "name": "汤臣集团",
        "最新价": 1.64,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.64,
        "最高": 1.64,
        "最低": 1.64,
        "昨收": 1.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3442,
        "symbol": "00256",
        "name": "冠城钟表珠宝",
        "最新价": 1.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.11,
        "最高": 1.14,
        "最低": 1.1,
        "昨收": 1.12,
        "成交量": 2292000.0,
        "成交额": 2559220.0
    },
    {
        "序号": 3443,
        "symbol": "00255",
        "name": "龙记集团",
        "最新价": 1.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.45,
        "最高": 1.45,
        "最低": 1.45,
        "昨收": 1.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3444,
        "symbol": "00253",
        "name": "顺豪控股",
        "最新价": 0.71,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.71,
        "最高": 0.71,
        "最低": 0.71,
        "昨收": 0.71,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3445,
        "symbol": "00252",
        "name": "华信地产财务",
        "最新价": 3.21,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 3.21,
        "最高": 3.21,
        "最低": 3.21,
        "昨收": 3.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3446,
        "symbol": "00248",
        "name": "香港通讯国际控股",
        "最新价": 0.031,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.031,
        "最高": 0.031,
        "最低": 0.031,
        "昨收": 0.031,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3447,
        "symbol": "00247",
        "name": "TST PROPERTIES",
        "最新价": 18.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 18.0,
        "最高": 18.0,
        "最低": 18.0,
        "昨收": 18.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3448,
        "symbol": "00242",
        "name": "信德集团",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.01,
        "最高": 1.05,
        "最低": 1.0,
        "昨收": 1.0,
        "成交量": 598254.0,
        "成交额": 607571.0
    },
    {
        "序号": 3449,
        "symbol": "00240",
        "name": "利基控股",
        "最新价": 1.12,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.11,
        "最高": 1.12,
        "最低": 1.11,
        "昨收": 1.12,
        "成交量": 50000.0,
        "成交额": 55600.0
    },
    {
        "序号": 3450,
        "symbol": "00237",
        "name": "安全货仓",
        "最新价": 1.98,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.98,
        "最高": 1.98,
        "最低": 1.98,
        "昨收": 1.98,
        "成交量": 6000.0,
        "成交额": 11880.0
    },
    {
        "序号": 3451,
        "symbol": "00236",
        "name": "香港生力啤",
        "最新价": 0.77,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.77,
        "最高": 0.77,
        "最低": 0.77,
        "昨收": 0.77,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3452,
        "symbol": "00234",
        "name": "新世纪集团",
        "最新价": 0.043,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.041,
        "最高": 0.043,
        "最低": 0.041,
        "昨收": 0.043,
        "成交量": 264000.0,
        "成交额": 10872.0
    },
    {
        "序号": 3453,
        "symbol": "00232",
        "name": "大陆航空科技控股",
        "最新价": 0.068,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.066,
        "最高": 0.068,
        "最低": 0.066,
        "昨收": 0.068,
        "成交量": 1470000.0,
        "成交额": 97646.0
    },
    {
        "序号": 3454,
        "symbol": "00230",
        "name": "五矿地产",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.28,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3455,
        "symbol": "00229",
        "name": "利民实业",
        "最新价": 0.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.79,
        "昨收": 0.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3456,
        "symbol": "00226",
        "name": "力宝",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.0,
        "最高": 1.0,
        "最低": 1.0,
        "昨收": 1.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3457,
        "symbol": "00225",
        "name": "博富临置业",
        "最新价": 6.18,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.18,
        "最高": 6.18,
        "最低": 6.18,
        "昨收": 6.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3458,
        "symbol": "00219",
        "name": "顺豪物业",
        "最新价": 0.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.84,
        "最高": 0.84,
        "最低": 0.84,
        "昨收": 0.84,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3459,
        "symbol": "00216",
        "name": "建业实业",
        "最新价": 1.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.0,
        "最高": 1.0,
        "最低": 1.0,
        "昨收": 1.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3460,
        "symbol": "00214",
        "name": "汇汉控股",
        "最新价": 0.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.4,
        "最高": 0.4,
        "最低": 0.4,
        "昨收": 0.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3461,
        "symbol": "00213",
        "name": "NATIONAL ELEC H",
        "最新价": 0.66,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.6,
        "最高": 0.66,
        "最低": 0.6,
        "昨收": 0.66,
        "成交量": 60000.0,
        "成交额": 36120.0
    },
    {
        "序号": 3462,
        "symbol": "00212",
        "name": "NANYANG HOLD",
        "最新价": 23.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 23.8,
        "最高": 23.8,
        "最低": 23.8,
        "昨收": 23.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3463,
        "symbol": "00211",
        "name": "STYLAND HOLD",
        "最新价": 0.175,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.175,
        "最高": 0.175,
        "最低": 0.175,
        "昨收": 0.175,
        "成交量": 20000.0,
        "成交额": 3500.0
    },
    {
        "序号": 3464,
        "symbol": "00210",
        "name": "达芙妮国际",
        "最新价": 0.195,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.195,
        "最高": 0.195,
        "最低": 0.193,
        "昨收": 0.195,
        "成交量": 96000.0,
        "成交额": 18716.0
    },
    {
        "序号": 3465,
        "symbol": "00207",
        "name": "大悦城地产",
        "最新价": 0.226,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.223,
        "昨收": 0.226,
        "成交量": 2492000.0,
        "成交额": 564276.0
    },
    {
        "序号": 3466,
        "symbol": "00206",
        "name": "华商能源",
        "最新价": 0.225,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.225,
        "最高": 0.225,
        "最低": 0.225,
        "昨收": 0.225,
        "成交量": 418000.0,
        "成交额": 94050.0
    },
    {
        "序号": 3467,
        "symbol": "00205",
        "name": "财讯传媒",
        "最新价": 0.23,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.231,
        "最高": 0.231,
        "最低": 0.23,
        "昨收": 0.23,
        "成交量": 35000.0,
        "成交额": 8040.0
    },
    {
        "序号": 3468,
        "symbol": "00202",
        "name": "润中国际控股",
        "最新价": 0.118,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.117,
        "最高": 0.118,
        "最低": 0.114,
        "昨收": 0.118,
        "成交量": 225000.0,
        "成交额": 26200.0
    },
    {
        "序号": 3469,
        "symbol": "00201",
        "name": "华大酒店",
        "最新价": 0.088,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.088,
        "最高": 0.088,
        "最低": 0.088,
        "昨收": 0.088,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3470,
        "symbol": "00197",
        "name": "亨泰",
        "最新价": 0.028,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.029,
        "最高": 0.029,
        "最低": 0.027,
        "昨收": 0.028,
        "成交量": 150509.0,
        "成交额": 4230.0
    },
    {
        "序号": 3471,
        "symbol": "00194",
        "name": "廖创兴企业",
        "最新价": 5.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.4,
        "最高": 5.4,
        "最低": 5.4,
        "昨收": 5.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3472,
        "symbol": "00193",
        "name": "冠中地产",
        "最新价": 0.169,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.169,
        "最高": 0.169,
        "最低": 0.169,
        "昨收": 0.169,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3473,
        "symbol": "00191",
        "name": "丽新国际",
        "最新价": 0.91,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.91,
        "最高": 0.91,
        "最低": 0.91,
        "昨收": 0.91,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3474,
        "symbol": "00188",
        "name": "新华汇富金融",
        "最新价": 0.235,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.235,
        "最高": 0.235,
        "最低": 0.235,
        "昨收": 0.235,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3475,
        "symbol": "00186",
        "name": "敏捷控股",
        "最新价": 0.29,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.29,
        "昨收": 0.29,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3476,
        "symbol": "00184",
        "name": "激成投资",
        "最新价": 2.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.0,
        "最高": 2.0,
        "最低": 2.0,
        "昨收": 2.0,
        "成交量": 4000.0,
        "成交额": 8000.0
    },
    {
        "序号": 3477,
        "symbol": "00182",
        "name": "协合新能源",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.66,
        "最低": 0.64,
        "昨收": 0.65,
        "成交量": 10690000.0,
        "成交额": 6950900.0
    },
    {
        "序号": 3478,
        "symbol": "00181",
        "name": "闽港控股",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.1,
        "昨收": 0.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3479,
        "symbol": "00176",
        "name": "先机企业集团",
        "最新价": 0.017,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.017,
        "最高": 0.017,
        "最低": 0.017,
        "昨收": 0.017,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3480,
        "symbol": "00173",
        "name": "嘉华国际",
        "最新价": 1.84,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.83,
        "最高": 1.87,
        "最低": 1.83,
        "昨收": 1.84,
        "成交量": 895117.0,
        "成交额": 1653191.0
    },
    {
        "序号": 3481,
        "symbol": "00169",
        "name": "万达酒店发展",
        "最新价": 0.246,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.246,
        "最高": 0.246,
        "最低": 0.246,
        "昨收": 0.246,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3482,
        "symbol": "00167",
        "name": "IDT INT'L",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.018,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3483,
        "symbol": "00166",
        "name": "新时代能源",
        "最新价": 0.075,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.079,
        "最高": 0.077,
        "最低": 0.069,
        "昨收": 0.075,
        "成交量": 714000.0,
        "成交额": 52880.0
    },
    {
        "序号": 3484,
        "symbol": "00164",
        "name": "中国宝力科技",
        "最新价": 0.495,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.495,
        "最低": 0.495,
        "昨收": 0.495,
        "成交量": 35.0,
        "成交额": 15.0
    },
    {
        "序号": 3485,
        "symbol": "00162",
        "name": "世纪金花",
        "最新价": 0.105,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.105,
        "最高": 0.105,
        "最低": 0.105,
        "昨收": 0.105,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3486,
        "symbol": "00160",
        "name": "汉国置业",
        "最新价": 1.4,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.4,
        "最高": 1.4,
        "最低": 1.39,
        "昨收": 1.4,
        "成交量": 332000.0,
        "成交额": 464200.0
    },
    {
        "序号": 3487,
        "symbol": "00159",
        "name": "布莱克万矿业",
        "最新价": 0.145,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.145,
        "昨收": 0.145,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3488,
        "symbol": "00157",
        "name": "自然美",
        "最新价": 0.5,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.5,
        "最低": 0.495,
        "昨收": 0.5,
        "成交量": 30000.0,
        "成交额": 14900.0
    },
    {
        "序号": 3489,
        "symbol": "00156",
        "name": "力宝华润",
        "最新价": 0.75,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.75,
        "最高": 0.77,
        "最低": 0.75,
        "昨收": 0.75,
        "成交量": 56600.0,
        "成交额": 42720.0
    },
    {
        "序号": 3490,
        "symbol": "00149",
        "name": "中国农产品交易",
        "最新价": 0.084,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.084,
        "最高": 0.084,
        "最低": 0.084,
        "昨收": 0.084,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3491,
        "symbol": "00147",
        "name": "国际商业结算",
        "最新价": 0.069,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.069,
        "最高": 0.069,
        "最低": 0.069,
        "昨收": 0.069,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3492,
        "symbol": "00145",
        "name": "信能低碳",
        "最新价": 0.052,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.05,
        "最高": 0.052,
        "最低": 0.049,
        "昨收": 0.052,
        "成交量": 5280000.0,
        "成交额": 264760.0
    },
    {
        "序号": 3493,
        "symbol": "00137",
        "name": "金辉集团",
        "最新价": 0.65,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.65,
        "最高": 0.65,
        "最低": 0.65,
        "昨收": 0.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3494,
        "symbol": "00129",
        "name": "泛海集团",
        "最新价": 0.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.45,
        "昨收": 0.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3495,
        "symbol": "00126",
        "name": "佳宁娜",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.28,
        "最低": 0.275,
        "昨收": 0.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3496,
        "symbol": "00125",
        "name": "新兴光学",
        "最新价": 0.74,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.74,
        "最高": 0.74,
        "最低": 0.74,
        "昨收": 0.74,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3497,
        "symbol": "00124",
        "name": "粤海置地",
        "最新价": 0.58,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.58,
        "昨收": 0.58,
        "成交量": 742000.0,
        "成交额": 430360.0
    },
    {
        "序号": 3498,
        "symbol": "00122",
        "name": "鳄鱼恤",
        "最新价": 0.138,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.138,
        "最高": 0.138,
        "最低": 0.138,
        "昨收": 0.138,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3499,
        "symbol": "00118",
        "name": "大同机械",
        "最新价": 0.241,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.241,
        "最高": 0.241,
        "最低": 0.241,
        "昨收": 0.241,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3500,
        "symbol": "00117",
        "name": "天利控股集团",
        "最新价": 0.31,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.31,
        "昨收": 0.31,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3501,
        "symbol": "00115",
        "name": "钧濠集团",
        "最新价": 0.103,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.103,
        "最高": 0.103,
        "最低": 0.103,
        "昨收": 0.103,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3502,
        "symbol": "00114",
        "name": "HERALD HOLD",
        "最新价": 0.495,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.495,
        "最高": 0.495,
        "最低": 0.495,
        "昨收": 0.495,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3503,
        "symbol": "00111",
        "name": "信达国际控股",
        "最新价": 0.285,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.285,
        "最低": 0.28,
        "昨收": 0.285,
        "成交量": 44000.0,
        "成交额": 12340.0
    },
    {
        "序号": 3504,
        "symbol": "00106",
        "name": "朗诗绿色管理",
        "最新价": 0.072,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.072,
        "最高": 0.072,
        "最低": 0.072,
        "昨收": 0.072,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3505,
        "symbol": "00104",
        "name": "ASIA COMM HOLD",
        "最新价": 0.28,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.28,
        "最高": 0.29,
        "最低": 0.27,
        "昨收": 0.28,
        "成交量": 425000.0,
        "成交额": 118600.0
    },
    {
        "序号": 3506,
        "symbol": "00099",
        "name": "王氏国际",
        "最新价": 1.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 1.72,
        "最高": 1.72,
        "最低": 1.72,
        "昨收": 1.72,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3507,
        "symbol": "00098",
        "name": "兴发铝业",
        "最新价": 6.15,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 6.15,
        "最高": 6.15,
        "最低": 6.15,
        "昨收": 6.15,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3508,
        "symbol": "00097",
        "name": "恒基发展",
        "最新价": 0.148,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.148,
        "最高": 0.148,
        "最低": 0.147,
        "昨收": 0.148,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3509,
        "symbol": "00093",
        "name": "TERMBRAY IND",
        "最新价": 0.63,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.63,
        "最高": 0.63,
        "最低": 0.63,
        "昨收": 0.63,
        "成交量": 4000.0,
        "成交额": 2520.0
    },
    {
        "序号": 3510,
        "symbol": "00092",
        "name": "冠军科技集团",
        "最新价": 0.1,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.1,
        "最高": 0.101,
        "最低": 0.099,
        "昨收": 0.1,
        "成交量": 4018719.0,
        "成交额": 402119.0
    },
    {
        "序号": 3511,
        "symbol": "00091",
        "name": "金禧国际控股",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.024,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3512,
        "symbol": "00090",
        "name": "普星能量",
        "最新价": 0.385,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.38,
        "最高": 0.385,
        "最低": 0.37,
        "昨收": 0.385,
        "成交量": 278000.0,
        "成交额": 105520.0
    },
    {
        "序号": 3513,
        "symbol": "00079",
        "name": "世纪建业",
        "最新价": 0.107,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.107,
        "最高": 0.107,
        "最低": 0.107,
        "昨收": 0.107,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3514,
        "symbol": "00077",
        "name": "进智公共交通",
        "最新价": 0.72,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.72,
        "最高": 0.72,
        "最低": 0.72,
        "昨收": 0.72,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3515,
        "symbol": "00075",
        "name": "渝太地产",
        "最新价": 0.38,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.38,
        "最高": 0.38,
        "最低": 0.38,
        "昨收": 0.38,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3516,
        "symbol": "00070",
        "name": "金粤控股",
        "最新价": 0.051,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.046,
        "最高": 0.051,
        "最低": 0.044,
        "昨收": 0.051,
        "成交量": 1548000.0,
        "成交额": 71674.0
    },
    {
        "序号": 3517,
        "symbol": "00066",
        "name": "港铁公司",
        "最新价": 27.9,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 27.9,
        "最高": 28.3,
        "最低": 27.7,
        "昨收": 27.9,
        "成交量": 2860491.0,
        "成交额": 79894015.0
    },
    {
        "序号": 3518,
        "symbol": "00061",
        "name": "绿领控股",
        "最新价": 0.066,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.066,
        "最高": 0.066,
        "最低": 0.066,
        "昨收": 0.066,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3519,
        "symbol": "00060",
        "name": "香港食品投资",
        "最新价": 0.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.55,
        "最高": 0.55,
        "最低": 0.55,
        "昨收": 0.55,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3520,
        "symbol": "00059",
        "name": "天誉置业",
        "最新价": 0.016,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.017,
        "最高": 0.017,
        "最低": 0.015,
        "昨收": 0.016,
        "成交量": 3362000.0,
        "成交额": 53484.0
    },
    {
        "序号": 3521,
        "symbol": "00058",
        "name": "新威国际",
        "最新价": 0.074,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.074,
        "最高": 0.074,
        "最低": 0.074,
        "昨收": 0.074,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3522,
        "symbol": "00055",
        "name": "中星集团控股",
        "最新价": 0.241,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.241,
        "最高": 0.241,
        "最低": 0.241,
        "昨收": 0.241,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3523,
        "symbol": "00053",
        "name": "国浩集团",
        "最新价": 52.0,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 52.5,
        "最高": 52.5,
        "最低": 52.0,
        "昨收": 52.0,
        "成交量": 7000.0,
        "成交额": 367000.0
    },
    {
        "序号": 3524,
        "symbol": "00048",
        "name": "中国汽车内饰",
        "最新价": 0.118,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.118,
        "最高": 0.118,
        "最低": 0.118,
        "昨收": 0.118,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3525,
        "symbol": "00046",
        "name": "科联系统",
        "最新价": 2.55,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 2.55,
        "最高": 2.55,
        "最低": 2.55,
        "昨收": 2.55,
        "成交量": 116000.0,
        "成交额": 295800.0
    },
    {
        "序号": 3526,
        "symbol": "00042",
        "name": "东北电气",
        "最新价": 0.22,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.22,
        "最高": 0.222,
        "最低": 0.22,
        "昨收": 0.22,
        "成交量": 228000.0,
        "成交额": 50504.0
    },
    {
        "序号": 3527,
        "symbol": "00036",
        "name": "远东控股国际",
        "最新价": 0.075,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.075,
        "最高": 0.075,
        "最低": 0.075,
        "昨收": 0.075,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3528,
        "symbol": "00032",
        "name": "港通控股",
        "最新价": 7.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 7.8,
        "最高": 7.8,
        "最低": 7.8,
        "昨收": 7.8,
        "成交量": 1000.0,
        "成交额": 7800.0
    },
    {
        "序号": 3529,
        "symbol": "00030",
        "name": "云白国际",
        "最新价": null,
        "涨跌额": null,
        "涨跌幅": null,
        "今开": null,
        "最高": null,
        "最低": null,
        "昨收": 0.249,
        "成交量": null,
        "成交额": null
    },
    {
        "序号": 3530,
        "symbol": "00026",
        "name": "中华汽车",
        "最新价": 60.45,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 60.45,
        "最高": 60.45,
        "最低": 60.45,
        "昨收": 60.45,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3531,
        "symbol": "00025",
        "name": "CHEVALIER INT'L",
        "最新价": 5.8,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 5.8,
        "最高": 5.8,
        "最低": 5.8,
        "昨收": 5.8,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3532,
        "symbol": "00022",
        "name": "茂盛控股",
        "最新价": 0.055,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.055,
        "最高": 0.055,
        "最低": 0.055,
        "昨收": 0.055,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3533,
        "symbol": "00009",
        "name": "金奥国际",
        "最新价": 0.014,
        "涨跌额": 0.0,
        "涨跌幅": 0.0,
        "今开": 0.014,
        "最高": 0.014,
        "最低": 0.014,
        "昨收": 0.014,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3534,
        "symbol": "07348",
        "name": "南方一倍看空中国A50",
        "最新价": 9.99,
        "涨跌额": -0.005,
        "涨跌幅": -0.05,
        "今开": 9.98,
        "最高": 9.99,
        "最低": 9.98,
        "昨收": 9.995,
        "成交量": 600.0,
        "成交额": 5988.0
    },
    {
        "序号": 3535,
        "symbol": "04246",
        "name": "政府债券二四零六",
        "最新价": 98.8,
        "涨跌额": -0.05,
        "涨跌幅": -0.05,
        "今开": 98.8,
        "最高": 98.8,
        "最低": 98.8,
        "昨收": 98.85,
        "成交量": 22600.0,
        "成交额": 2232900.0
    },
    {
        "序号": 3536,
        "symbol": "83130",
        "name": "恒生沪深三百-R",
        "最新价": 17.64,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "今开": 17.64,
        "最高": 17.64,
        "最低": 17.64,
        "昨收": 17.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3537,
        "symbol": "02800",
        "name": "盈富基金",
        "最新价": 16.46,
        "涨跌额": -0.01,
        "涨跌幅": -0.06,
        "今开": 16.5,
        "最高": 16.6,
        "最低": 16.38,
        "昨收": 16.47,
        "成交量": 363929360.0,
        "成交额": 5991973376.0
    },
    {
        "序号": 3538,
        "symbol": "03031",
        "name": "海通AESG",
        "最新价": 7.955,
        "涨跌额": -0.005,
        "涨跌幅": -0.06,
        "今开": 7.96,
        "最高": 7.97,
        "最低": 7.95,
        "昨收": 7.96,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3539,
        "symbol": "03129",
        "name": "中银大湾气候",
        "最新价": 7.93,
        "涨跌额": -0.005,
        "涨跌幅": -0.06,
        "今开": 7.93,
        "最高": 7.93,
        "最低": 7.93,
        "昨收": 7.935,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3540,
        "symbol": "07299",
        "name": "南方两倍做多黄金",
        "最新价": 7.89,
        "涨跌额": -0.005,
        "涨跌幅": -0.06,
        "今开": 7.895,
        "最高": 7.915,
        "最低": 7.88,
        "昨收": 7.895,
        "成交量": 505400.0,
        "成交额": 3991405.0
    },
    {
        "序号": 3541,
        "symbol": "02827",
        "name": "标智沪深300",
        "最新价": 31.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.06,
        "今开": 31.46,
        "最高": 31.5,
        "最低": 31.38,
        "昨收": 31.4,
        "成交量": 9400.0,
        "成交额": 295348.0
    },
    {
        "序号": 3542,
        "symbol": "07333",
        "name": "南方一倍做空沪深300",
        "最新价": 7.825,
        "涨跌额": -0.005,
        "涨跌幅": -0.06,
        "今开": 7.83,
        "最高": 7.83,
        "最低": 7.795,
        "昨收": 7.83,
        "成交量": 7200.0,
        "成交额": 56223.0
    },
    {
        "序号": 3543,
        "symbol": "09820",
        "name": "GX中国生科-U",
        "最新价": 7.3,
        "涨跌额": -0.005,
        "涨跌幅": -0.07,
        "今开": 7.235,
        "最高": 7.3,
        "最低": 7.235,
        "昨收": 7.305,
        "成交量": 7850.0,
        "成交额": 57120.0
    },
    {
        "序号": 3544,
        "symbol": "82846",
        "name": "安硕沪深三百-R",
        "最新价": 22.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.09,
        "今开": 22.2,
        "最高": 22.24,
        "最低": 22.2,
        "昨收": 22.26,
        "成交量": 42000.0,
        "成交额": 933240.0
    },
    {
        "序号": 3545,
        "symbol": "09801",
        "name": "安硕中国-U",
        "最新价": 2.208,
        "涨跌额": -0.002,
        "涨跌幅": -0.09,
        "今开": 2.21,
        "最高": 2.22,
        "最低": 2.2,
        "昨收": 2.21,
        "成交量": 57320.0,
        "成交额": 126913.0
    },
    {
        "序号": 3546,
        "symbol": "03161",
        "name": "A华夏人民币",
        "最新价": 110.3,
        "涨跌额": -0.1,
        "涨跌幅": -0.09,
        "今开": 110.4,
        "最高": 110.4,
        "最低": 110.25,
        "昨收": 110.4,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3547,
        "symbol": "89888",
        "name": "百度集团-SWR",
        "最新价": 102.1,
        "涨跌额": -0.1,
        "涨跌幅": -0.1,
        "今开": 103.5,
        "最高": 103.5,
        "最低": 102.1,
        "昨收": 102.2,
        "成交量": 8700.0,
        "成交额": 891710.0
    },
    {
        "序号": 3548,
        "symbol": "03115",
        "name": "安硕恒生指数",
        "最新价": 60.58,
        "涨跌额": -0.06,
        "涨跌幅": -0.1,
        "今开": 60.98,
        "最高": 61.06,
        "最低": 60.28,
        "昨收": 60.64,
        "成交量": 21200.0,
        "成交额": 1284938.0
    },
    {
        "序号": 3549,
        "symbol": "01415",
        "name": "高伟电子",
        "最新价": 19.34,
        "涨跌额": -0.02,
        "涨跌幅": -0.1,
        "今开": 19.3,
        "最高": 19.52,
        "最低": 18.92,
        "昨收": 19.36,
        "成交量": 2968800.0,
        "成交额": 57212989.0
    },
    {
        "序号": 3550,
        "symbol": "03306",
        "name": "江南布衣",
        "最新价": 9.47,
        "涨跌额": -0.01,
        "涨跌幅": -0.11,
        "今开": 9.46,
        "最高": 9.57,
        "最低": 9.16,
        "昨收": 9.48,
        "成交量": 490500.0,
        "成交额": 4576030.0
    },
    {
        "序号": 3551,
        "symbol": "02825",
        "name": "标智香港100",
        "最新价": 17.92,
        "涨跌额": -0.02,
        "涨跌幅": -0.11,
        "今开": 17.92,
        "最高": 17.92,
        "最低": 17.92,
        "昨收": 17.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3552,
        "symbol": "01675",
        "name": "亚信科技",
        "最新价": 8.84,
        "涨跌额": -0.01,
        "涨跌幅": -0.11,
        "今开": 8.8,
        "最高": 9.43,
        "最低": 8.76,
        "昨收": 8.85,
        "成交量": 1551200.0,
        "成交额": 14099612.0
    },
    {
        "序号": 3553,
        "symbol": "00425",
        "name": "敏实集团",
        "最新价": 15.7,
        "涨跌额": -0.02,
        "涨跌幅": -0.13,
        "今开": 15.7,
        "最高": 16.1,
        "最低": 15.44,
        "昨收": 15.72,
        "成交量": 1269682.0,
        "成交额": 19897927.0
    },
    {
        "序号": 3554,
        "symbol": "06667",
        "name": "美因基因",
        "最新价": 7.82,
        "涨跌额": -0.01,
        "涨跌幅": -0.13,
        "今开": 7.83,
        "最高": 7.9,
        "最低": 7.81,
        "昨收": 7.83,
        "成交量": 120200.0,
        "成交额": 940558.0
    },
    {
        "序号": 3555,
        "symbol": "09846",
        "name": "安硕沪深三百-U",
        "最新价": 3.1,
        "涨跌额": -0.004,
        "涨跌幅": -0.13,
        "今开": 3.116,
        "最高": 3.116,
        "最低": 3.1,
        "昨收": 3.104,
        "成交量": 360700.0,
        "成交额": 1117302.0
    },
    {
        "序号": 3556,
        "symbol": "00522",
        "name": "ASMPT",
        "最新价": 75.4,
        "涨跌额": -0.1,
        "涨跌幅": -0.13,
        "今开": 75.5,
        "最高": 76.9,
        "最低": 74.85,
        "昨收": 75.5,
        "成交量": 425700.0,
        "成交额": 32242300.0
    },
    {
        "序号": 3557,
        "symbol": "01385",
        "name": "上海复旦",
        "最新价": 14.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.14,
        "今开": 14.48,
        "最高": 14.56,
        "最低": 14.08,
        "昨收": 14.26,
        "成交量": 1189000.0,
        "成交额": 17021050.0
    },
    {
        "序号": 3558,
        "symbol": "00051",
        "name": "海港企业",
        "最新价": 7.05,
        "涨跌额": -0.01,
        "涨跌幅": -0.14,
        "今开": 7.06,
        "最高": 7.06,
        "最低": 7.05,
        "昨收": 7.06,
        "成交量": 36000.0,
        "成交额": 254000.0
    },
    {
        "序号": 3559,
        "symbol": "09997",
        "name": "康基医疗",
        "最新价": 6.94,
        "涨跌额": -0.01,
        "涨跌幅": -0.14,
        "今开": 6.92,
        "最高": 7.08,
        "最低": 6.86,
        "昨收": 6.95,
        "成交量": 489500.0,
        "成交额": 3406586.0
    },
    {
        "序号": 3560,
        "symbol": "00105",
        "name": "凯联国际酒店",
        "最新价": 6.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.16,
        "今开": 6.41,
        "最高": 6.41,
        "最低": 6.4,
        "昨收": 6.41,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3561,
        "symbol": "03015",
        "name": "XTRN50印度",
        "最新价": 1899.0,
        "涨跌额": -3.0,
        "涨跌幅": -0.16,
        "今开": 1899.0,
        "最高": 1899.0,
        "最低": 1899.0,
        "昨收": 1902.0,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3562,
        "symbol": "01820",
        "name": "济丰包装",
        "最新价": 6.31,
        "涨跌额": -0.01,
        "涨跌幅": -0.16,
        "今开": 6.32,
        "最高": 6.32,
        "最低": 6.31,
        "昨收": 6.32,
        "成交量": 6000.0,
        "成交额": 37880.0
    },
    {
        "序号": 3563,
        "symbol": "02812",
        "name": "三星中国龙网",
        "最新价": 9.425,
        "涨跌额": -0.015,
        "涨跌幅": -0.16,
        "今开": 9.44,
        "最高": 9.44,
        "最低": 9.34,
        "昨收": 9.44,
        "成交量": 49200.0,
        "成交额": 462083.0
    },
    {
        "序号": 3564,
        "symbol": "09812",
        "name": "三星中国龙网-U",
        "最新价": 1.21,
        "涨跌额": -0.002,
        "涨跌幅": -0.17,
        "今开": 1.21,
        "最高": 1.21,
        "最低": 1.21,
        "昨收": 1.212,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3565,
        "symbol": "02325",
        "name": "云康集团",
        "最新价": 11.92,
        "涨跌额": -0.02,
        "涨跌幅": -0.17,
        "今开": 11.92,
        "最高": 11.96,
        "最低": 11.88,
        "昨收": 11.94,
        "成交量": 95000.0,
        "成交额": 1133540.0
    },
    {
        "序号": 3566,
        "symbol": "06661",
        "name": "湖州燃气",
        "最新价": 5.94,
        "涨跌额": -0.01,
        "涨跌幅": -0.17,
        "今开": 5.95,
        "最高": 5.95,
        "最低": 5.94,
        "昨收": 5.95,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3567,
        "symbol": "83188",
        "name": "华夏沪深三百-R",
        "最新价": 34.76,
        "涨跌额": -0.06,
        "涨跌幅": -0.17,
        "今开": 34.82,
        "最高": 34.92,
        "最低": 34.74,
        "昨收": 34.82,
        "成交量": 420800.0,
        "成交额": 14639512.0
    },
    {
        "序号": 3568,
        "symbol": "02801",
        "name": "安硕中国",
        "最新价": 17.24,
        "涨跌额": -0.03,
        "涨跌幅": -0.17,
        "今开": 17.3,
        "最高": 17.32,
        "最低": 17.16,
        "昨收": 17.27,
        "成交量": 81875.0,
        "成交额": 1408439.0
    },
    {
        "序号": 3569,
        "symbol": "80941",
        "name": "中国移动-R",
        "最新价": 57.3,
        "涨跌额": -0.1,
        "涨跌幅": -0.17,
        "今开": 57.3,
        "最高": 57.45,
        "最低": 57.1,
        "昨收": 57.4,
        "成交量": 71000.0,
        "成交额": 4074325.0
    },
    {
        "序号": 3570,
        "symbol": "01501",
        "name": "康德莱医械",
        "最新价": 27.65,
        "涨跌额": -0.05,
        "涨跌幅": -0.18,
        "今开": 27.1,
        "最高": 27.65,
        "最低": 27.1,
        "昨收": 27.7,
        "成交量": 12200.0,
        "成交额": 336130.0
    },
    {
        "序号": 3571,
        "symbol": "02809",
        "name": "GX中国洁能",
        "最新价": 72.86,
        "涨跌额": -0.14,
        "涨跌幅": -0.19,
        "今开": 73.0,
        "最高": 73.32,
        "最低": 72.54,
        "昨收": 73.0,
        "成交量": 17614.0,
        "成交额": 1282232.0
    },
    {
        "序号": 3572,
        "symbol": "01952",
        "name": "云顶新耀-B",
        "最新价": 19.62,
        "涨跌额": -0.04,
        "涨跌幅": -0.2,
        "今开": 19.94,
        "最高": 20.05,
        "最低": 19.28,
        "昨收": 19.66,
        "成交量": 2169109.0,
        "成交额": 42583536.0
    },
    {
        "序号": 3573,
        "symbol": "06922",
        "name": "康沣生物-B",
        "最新价": 19.3,
        "涨跌额": -0.04,
        "涨跌幅": -0.21,
        "今开": 19.3,
        "最高": 19.3,
        "最低": 19.3,
        "昨收": 19.34,
        "成交量": 200.0,
        "成交额": 3860.0
    },
    {
        "序号": 3574,
        "symbol": "09836",
        "name": "安硕印度-U",
        "最新价": 4.74,
        "涨跌额": -0.01,
        "涨跌幅": -0.21,
        "今开": 4.762,
        "最高": 4.762,
        "最低": 4.74,
        "昨收": 4.75,
        "成交量": 1600.0,
        "成交额": 7604.0
    },
    {
        "序号": 3575,
        "symbol": "03188",
        "name": "华夏沪深三百",
        "最新价": 37.9,
        "涨跌额": -0.08,
        "涨跌幅": -0.21,
        "今开": 38.0,
        "最高": 38.18,
        "最低": 37.86,
        "昨收": 37.98,
        "成交量": 1400073.0,
        "成交额": 53204576.0
    },
    {
        "序号": 3576,
        "symbol": "03124",
        "name": "GX中国电商物流",
        "最新价": 37.56,
        "涨跌额": -0.08,
        "涨跌幅": -0.21,
        "今开": 37.56,
        "最高": 37.56,
        "最低": 37.56,
        "昨收": 37.64,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3577,
        "symbol": "03141",
        "name": "华夏亚投债",
        "最新价": 14.07,
        "涨跌额": -0.03,
        "涨跌幅": -0.21,
        "今开": 14.1,
        "最高": 14.1,
        "最低": 14.06,
        "昨收": 14.1,
        "成交量": 12400.0,
        "成交额": 174824.0
    },
    {
        "序号": 3578,
        "symbol": "01896",
        "name": "猫眼娱乐",
        "最新价": 9.07,
        "涨跌额": -0.02,
        "涨跌幅": -0.22,
        "今开": 9.09,
        "最高": 9.12,
        "最低": 8.92,
        "昨收": 9.09,
        "成交量": 4800200.0,
        "成交额": 43407535.0
    },
    {
        "序号": 3579,
        "symbol": "01513",
        "name": "丽珠医药",
        "最新价": 22.6,
        "涨跌额": -0.05,
        "涨跌幅": -0.22,
        "今开": 22.6,
        "最高": 22.8,
        "最低": 22.45,
        "昨收": 22.65,
        "成交量": 577413.0,
        "成交额": 13100560.0
    },
    {
        "序号": 3580,
        "symbol": "82020",
        "name": "安踏体育-R",
        "最新价": 67.55,
        "涨跌额": -0.15,
        "涨跌幅": -0.22,
        "今开": 67.55,
        "最高": 67.55,
        "最低": 67.55,
        "昨收": 67.7,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3581,
        "symbol": "00939",
        "name": "建设银行",
        "最新价": 4.44,
        "涨跌额": -0.01,
        "涨跌幅": -0.22,
        "今开": 4.49,
        "最高": 4.49,
        "最低": 4.4,
        "昨收": 4.45,
        "成交量": 274155232.0,
        "成交额": 1216176672.0
    },
    {
        "序号": 3582,
        "symbol": "02811",
        "name": "海通沪深三百",
        "最新价": 13.19,
        "涨跌额": -0.03,
        "涨跌幅": -0.23,
        "今开": 13.22,
        "最高": 13.22,
        "最低": 13.19,
        "昨收": 13.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3583,
        "symbol": "02611",
        "name": "国泰君安",
        "最新价": 8.78,
        "涨跌额": -0.02,
        "涨跌幅": -0.23,
        "今开": 8.83,
        "最高": 8.84,
        "最低": 8.7,
        "昨收": 8.8,
        "成交量": 1516600.0,
        "成交额": 13291652.0
    },
    {
        "序号": 3584,
        "symbol": "00551",
        "name": "裕元集团",
        "最新价": 8.72,
        "涨跌额": -0.02,
        "涨跌幅": -0.23,
        "今开": 8.75,
        "最高": 8.86,
        "最低": 8.7,
        "昨收": 8.74,
        "成交量": 1883500.0,
        "成交额": 16489865.0
    },
    {
        "序号": 3585,
        "symbol": "82828",
        "name": "恒生中国企业-R",
        "最新价": 52.0,
        "涨跌额": -0.12,
        "涨跌幅": -0.23,
        "今开": 52.18,
        "最高": 52.18,
        "最低": 51.74,
        "昨收": 52.12,
        "成交量": 8600.0,
        "成交额": 447468.0
    },
    {
        "序号": 3586,
        "symbol": "06690",
        "name": "海尔智家",
        "最新价": 21.3,
        "涨跌额": -0.05,
        "涨跌幅": -0.23,
        "今开": 21.2,
        "最高": 21.5,
        "最低": 21.15,
        "昨收": 21.35,
        "成交量": 7012178.0,
        "成交额": 149536867.0
    },
    {
        "序号": 3587,
        "symbol": "00868",
        "name": "信义玻璃",
        "最新价": 8.49,
        "涨跌额": -0.02,
        "涨跌幅": -0.24,
        "今开": 8.51,
        "最高": 8.73,
        "最低": 8.48,
        "昨收": 8.51,
        "成交量": 6387627.0,
        "成交额": 54499746.0
    },
    {
        "序号": 3588,
        "symbol": "01234",
        "name": "中国利郎",
        "最新价": 4.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.24,
        "今开": 4.02,
        "最高": 4.14,
        "最低": 4.02,
        "昨收": 4.14,
        "成交量": 256000.0,
        "成交额": 1052260.0
    },
    {
        "序号": 3589,
        "symbol": "00874",
        "name": "白云山",
        "最新价": 20.4,
        "涨跌额": -0.05,
        "涨跌幅": -0.24,
        "今开": 20.5,
        "最高": 20.7,
        "最低": 20.35,
        "昨收": 20.45,
        "成交量": 554000.0,
        "成交额": 11355300.0
    },
    {
        "序号": 3590,
        "symbol": "01548",
        "name": "金斯瑞生物科技",
        "最新价": 20.2,
        "涨跌额": -0.05,
        "涨跌幅": -0.25,
        "今开": 20.25,
        "最高": 20.75,
        "最低": 19.92,
        "昨收": 20.25,
        "成交量": 5270000.0,
        "成交额": 106829490.0
    },
    {
        "序号": 3591,
        "symbol": "02823",
        "name": "安硕A50",
        "最新价": 12.07,
        "涨跌额": -0.03,
        "涨跌幅": -0.25,
        "今开": 12.12,
        "最高": 12.16,
        "最低": 12.07,
        "昨收": 12.1,
        "成交量": 1130399.0,
        "成交额": 13691453.0
    },
    {
        "序号": 3592,
        "symbol": "09995",
        "name": "荣昌生物",
        "最新价": 39.95,
        "涨跌额": -0.1,
        "涨跌幅": -0.25,
        "今开": 39.95,
        "最高": 40.7,
        "最低": 39.25,
        "昨收": 40.05,
        "成交量": 834000.0,
        "成交额": 33200425.0
    },
    {
        "序号": 3593,
        "symbol": "06198",
        "name": "青岛港",
        "最新价": 3.96,
        "涨跌额": -0.01,
        "涨跌幅": -0.25,
        "今开": 4.01,
        "最高": 4.01,
        "最低": 3.96,
        "昨收": 3.97,
        "成交量": 652500.0,
        "成交额": 2595895.0
    },
    {
        "序号": 3594,
        "symbol": "00293",
        "name": "国泰航空",
        "最新价": 7.8,
        "涨跌额": -0.02,
        "涨跌幅": -0.26,
        "今开": 7.82,
        "最高": 7.85,
        "最低": 7.76,
        "昨收": 7.82,
        "成交量": 9270362.0,
        "成交额": 72331032.0
    },
    {
        "序号": 3595,
        "symbol": "03040",
        "name": "GX中国",
        "最新价": 23.38,
        "涨跌额": -0.06,
        "涨跌幅": -0.26,
        "今开": 23.36,
        "最高": 23.38,
        "最低": 23.36,
        "昨收": 23.44,
        "成交量": 600.0,
        "成交额": 14016.0
    },
    {
        "序号": 3596,
        "symbol": "01972",
        "name": "太古地产",
        "最新价": 15.36,
        "涨跌额": -0.04,
        "涨跌幅": -0.26,
        "今开": 15.46,
        "最高": 15.52,
        "最低": 15.32,
        "昨收": 15.4,
        "成交量": 886266.0,
        "成交额": 13633904.0
    },
    {
        "序号": 3597,
        "symbol": "03130",
        "name": "恒生沪深三百",
        "最新价": 19.17,
        "涨跌额": -0.05,
        "涨跌幅": -0.26,
        "今开": 19.22,
        "最高": 19.22,
        "最低": 19.16,
        "昨收": 19.22,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3598,
        "symbol": "00836",
        "name": "华润电力",
        "最新价": 14.98,
        "涨跌额": -0.04,
        "涨跌幅": -0.27,
        "今开": 15.08,
        "最高": 15.16,
        "最低": 14.92,
        "昨收": 15.02,
        "成交量": 3565786.0,
        "成交额": 53671788.0
    },
    {
        "序号": 3599,
        "symbol": "01398",
        "name": "工商银行",
        "最新价": 3.63,
        "涨跌额": -0.01,
        "涨跌幅": -0.27,
        "今开": 3.67,
        "最高": 3.68,
        "最低": 3.59,
        "昨收": 3.64,
        "成交量": 210592613.0,
        "成交额": 760786736.0
    },
    {
        "序号": 3600,
        "symbol": "03108",
        "name": "嘉实ESG领",
        "最新价": 7.175,
        "涨跌额": -0.02,
        "涨跌幅": -0.28,
        "今开": 7.175,
        "最高": 7.175,
        "最低": 7.175,
        "昨收": 7.195,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3601,
        "symbol": "09923",
        "name": "移卡",
        "最新价": 14.08,
        "涨跌额": -0.04,
        "涨跌幅": -0.28,
        "今开": 14.3,
        "最高": 14.3,
        "最低": 13.74,
        "昨收": 14.12,
        "成交量": 708878.0,
        "成交额": 9933513.0
    },
    {
        "序号": 3602,
        "symbol": "09806",
        "name": "GX中国消费-U",
        "最新价": 5.215,
        "涨跌额": -0.015,
        "涨跌幅": -0.29,
        "今开": 5.22,
        "最高": 5.255,
        "最低": 5.2,
        "昨收": 5.23,
        "成交量": 12358.0,
        "成交额": 64443.0
    },
    {
        "序号": 3603,
        "symbol": "03309",
        "name": "希玛眼科",
        "最新价": 3.47,
        "涨跌额": -0.01,
        "涨跌幅": -0.29,
        "今开": 3.49,
        "最高": 3.49,
        "最低": 3.41,
        "昨收": 3.48,
        "成交量": 349000.0,
        "成交额": 1198940.0
    },
    {
        "序号": 3604,
        "symbol": "06655",
        "name": "华新水泥",
        "最新价": 6.93,
        "涨跌额": -0.02,
        "涨跌幅": -0.29,
        "今开": 7.08,
        "最高": 7.08,
        "最低": 6.91,
        "昨收": 6.95,
        "成交量": 371100.0,
        "成交额": 2589655.0
    },
    {
        "序号": 3605,
        "symbol": "02238",
        "name": "广汽集团",
        "最新价": 3.46,
        "涨跌额": -0.01,
        "涨跌幅": -0.29,
        "今开": 3.47,
        "最高": 3.48,
        "最低": 3.41,
        "昨收": 3.47,
        "成交量": 12746500.0,
        "成交额": 43871109.0
    },
    {
        "序号": 3606,
        "symbol": "83403",
        "name": "华夏恒ESG-R",
        "最新价": 33.84,
        "涨跌额": -0.1,
        "涨跌幅": -0.29,
        "今开": 33.84,
        "最高": 33.84,
        "最低": 33.84,
        "昨收": 33.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3607,
        "symbol": "02318",
        "name": "中国平安",
        "最新价": 33.75,
        "涨跌额": -0.1,
        "涨跌幅": -0.3,
        "今开": 33.55,
        "最高": 34.1,
        "最低": 33.55,
        "昨收": 33.85,
        "成交量": 22411471.0,
        "成交额": 756350000.0
    },
    {
        "序号": 3608,
        "symbol": "01177",
        "name": "中国生物制药",
        "最新价": 3.37,
        "涨跌额": -0.01,
        "涨跌幅": -0.3,
        "今开": 3.42,
        "最高": 3.45,
        "最低": 3.33,
        "昨收": 3.38,
        "成交量": 51503576.0,
        "成交额": 173489316.0
    },
    {
        "序号": 3609,
        "symbol": "03323",
        "name": "中国建材",
        "最新价": 3.34,
        "涨跌额": -0.01,
        "涨跌幅": -0.3,
        "今开": 3.35,
        "最高": 3.39,
        "最低": 3.32,
        "昨收": 3.35,
        "成交量": 11234758.0,
        "成交额": 37628726.0
    },
    {
        "序号": 3610,
        "symbol": "00973",
        "name": "L'OCCITANE",
        "最新价": 19.76,
        "涨跌额": -0.06,
        "涨跌幅": -0.3,
        "今开": 20.0,
        "最高": 20.0,
        "最低": 19.62,
        "昨收": 19.82,
        "成交量": 778750.0,
        "成交额": 15461110.0
    },
    {
        "序号": 3611,
        "symbol": "06858",
        "name": "本间高尔夫",
        "最新价": 3.27,
        "涨跌额": -0.01,
        "涨跌幅": -0.3,
        "今开": 3.27,
        "最高": 3.27,
        "最低": 3.27,
        "昨收": 3.28,
        "成交量": 500.0,
        "成交额": 1635.0
    },
    {
        "序号": 3612,
        "symbol": "01876",
        "name": "百威亚太",
        "最新价": 12.94,
        "涨跌额": -0.04,
        "涨跌幅": -0.31,
        "今开": 12.98,
        "最高": 13.1,
        "最低": 12.82,
        "昨收": 12.98,
        "成交量": 6725333.0,
        "成交额": 87150950.0
    },
    {
        "序号": 3613,
        "symbol": "09913",
        "name": "智勤控股",
        "最新价": 3.22,
        "涨跌额": -0.01,
        "涨跌幅": -0.31,
        "今开": 3.24,
        "最高": 3.24,
        "最低": 3.2,
        "昨收": 3.23,
        "成交量": 24000.0,
        "成交额": 77360.0
    },
    {
        "序号": 3614,
        "symbol": "01083",
        "name": "港华智慧能源",
        "最新价": 3.21,
        "涨跌额": -0.01,
        "涨跌幅": -0.31,
        "今开": 3.22,
        "最高": 3.27,
        "最低": 3.16,
        "昨收": 3.22,
        "成交量": 4888000.0,
        "成交额": 15677760.0
    },
    {
        "序号": 3615,
        "symbol": "02828",
        "name": "恒生中国企业",
        "最新价": 56.66,
        "涨跌额": -0.18,
        "涨跌幅": -0.32,
        "今开": 57.0,
        "最高": 57.22,
        "最低": 56.32,
        "昨收": 56.84,
        "成交量": 35759519.0,
        "成交额": 2026121360.0
    },
    {
        "序号": 3616,
        "symbol": "82318",
        "name": "中国平安-R",
        "最新价": 30.9,
        "涨跌额": -0.1,
        "涨跌幅": -0.32,
        "今开": 30.95,
        "最高": 31.15,
        "最低": 30.8,
        "昨收": 31.0,
        "成交量": 114000.0,
        "成交额": 3529975.0
    },
    {
        "序号": 3617,
        "symbol": "00016",
        "name": "新鸿基地产",
        "最新价": 77.2,
        "涨跌额": -0.25,
        "涨跌幅": -0.32,
        "今开": 78.65,
        "最高": 78.75,
        "最低": 77.0,
        "昨收": 77.45,
        "成交量": 2420057.0,
        "成交额": 187871342.0
    },
    {
        "序号": 3618,
        "symbol": "03136",
        "name": "恒指ESGETF",
        "最新价": 9.255,
        "涨跌额": -0.03,
        "涨跌幅": -0.32,
        "今开": 9.255,
        "最高": 9.255,
        "最低": 9.255,
        "昨收": 9.285,
        "成交量": 200.0,
        "成交额": 1851.0
    },
    {
        "序号": 3619,
        "symbol": "03033",
        "name": "南方恒生科技",
        "最新价": 3.638,
        "涨跌额": -0.012,
        "涨跌幅": -0.33,
        "今开": 3.66,
        "最高": 3.678,
        "最低": 3.606,
        "昨收": 3.65,
        "成交量": 500673824.0,
        "成交额": 1819191744.0
    },
    {
        "序号": 3620,
        "symbol": "09040",
        "name": "GX中国-U",
        "最新价": 3.002,
        "涨跌额": -0.01,
        "涨跌幅": -0.33,
        "今开": 3.012,
        "最高": 3.012,
        "最低": 2.986,
        "昨收": 3.012,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3621,
        "symbol": "07332",
        "name": "富邦一倍看空富时台湾",
        "最新价": 5.98,
        "涨跌额": -0.02,
        "涨跌幅": -0.33,
        "今开": 5.975,
        "最高": 5.98,
        "最低": 5.975,
        "昨收": 6.0,
        "成交量": 7000.0,
        "成交额": 41835.0
    },
    {
        "序号": 3622,
        "symbol": "03088",
        "name": "华夏恒生科技",
        "最新价": 4.682,
        "涨跌额": -0.016,
        "涨跌幅": -0.34,
        "今开": 4.728,
        "最高": 4.728,
        "最低": 4.65,
        "昨收": 4.698,
        "成交量": 128200.0,
        "成交额": 598935.0
    },
    {
        "序号": 3623,
        "symbol": "01860",
        "name": "汇量科技",
        "最新价": 2.89,
        "涨跌额": -0.01,
        "涨跌幅": -0.34,
        "今开": 2.9,
        "最高": 2.92,
        "最低": 2.85,
        "昨收": 2.9,
        "成交量": 741000.0,
        "成交额": 2136100.0
    },
    {
        "序号": 3624,
        "symbol": "02837",
        "name": "GX恒生科技",
        "最新价": 4.528,
        "涨跌额": -0.016,
        "涨跌幅": -0.35,
        "今开": 4.502,
        "最高": 4.558,
        "最低": 4.49,
        "昨收": 4.544,
        "成交量": 1106500.0,
        "成交额": 4985050.0
    },
    {
        "序号": 3625,
        "symbol": "09096",
        "name": "A南方美元-U",
        "最新价": 109.55,
        "涨跌额": -0.4,
        "涨跌幅": -0.36,
        "今开": 109.55,
        "最高": 109.55,
        "最低": 109.55,
        "昨收": 109.95,
        "成交量": 55.0,
        "成交额": 6025.0
    },
    {
        "序号": 3626,
        "symbol": "09809",
        "name": "GX中国洁能-U",
        "最新价": 9.305,
        "涨跌额": -0.035,
        "涨跌幅": -0.37,
        "今开": 9.335,
        "最高": 9.355,
        "最低": 9.305,
        "昨收": 9.34,
        "成交量": 7500.0,
        "成交额": 69974.0
    },
    {
        "序号": 3627,
        "symbol": "00220",
        "name": "统一企业中国",
        "最新价": 5.28,
        "涨跌额": -0.02,
        "涨跌幅": -0.38,
        "今开": 5.25,
        "最高": 5.34,
        "最低": 5.25,
        "昨收": 5.3,
        "成交量": 2219000.0,
        "成交额": 11734731.0
    },
    {
        "序号": 3628,
        "symbol": "02820",
        "name": "GX中国生科",
        "最新价": 56.98,
        "涨跌额": -0.22,
        "涨跌幅": -0.38,
        "今开": 56.8,
        "最高": 57.0,
        "最低": 56.66,
        "昨收": 57.2,
        "成交量": 28100.0,
        "成交额": 1596153.0
    },
    {
        "序号": 3629,
        "symbol": "00270",
        "name": "粤海投资",
        "最新价": 5.18,
        "涨跌额": -0.02,
        "涨跌幅": -0.38,
        "今开": 5.19,
        "最高": 5.22,
        "最低": 5.14,
        "昨收": 5.2,
        "成交量": 6365238.0,
        "成交额": 32991074.0
    },
    {
        "序号": 3630,
        "symbol": "02888",
        "name": "渣打集团",
        "最新价": 64.1,
        "涨跌额": -0.25,
        "涨跌幅": -0.39,
        "今开": 63.55,
        "最高": 64.3,
        "最低": 63.55,
        "昨收": 64.35,
        "成交量": 190033.0,
        "成交额": 12166255.0
    },
    {
        "序号": 3631,
        "symbol": "01448",
        "name": "福寿园",
        "最新价": 5.11,
        "涨跌额": -0.02,
        "涨跌幅": -0.39,
        "今开": 5.13,
        "最高": 5.15,
        "最低": 5.07,
        "昨收": 5.13,
        "成交量": 2269600.0,
        "成交额": 11596237.0
    },
    {
        "序号": 3632,
        "symbol": "00071",
        "name": "美丽华酒店",
        "最新价": 10.16,
        "涨跌额": -0.04,
        "涨跌幅": -0.39,
        "今开": 10.16,
        "最高": 10.16,
        "最低": 10.16,
        "昨收": 10.2,
        "成交量": 3000.0,
        "成交额": 30480.0
    },
    {
        "序号": 3633,
        "symbol": "06919",
        "name": "人瑞人才",
        "最新价": 5.04,
        "涨跌额": -0.02,
        "涨跌幅": -0.4,
        "今开": 5.0,
        "最高": 5.06,
        "最低": 4.99,
        "昨收": 5.06,
        "成交量": 31300.0,
        "成交额": 156890.0
    },
    {
        "序号": 3634,
        "symbol": "00941",
        "name": "中国移动",
        "最新价": 62.35,
        "涨跌额": -0.25,
        "涨跌幅": -0.4,
        "今开": 62.6,
        "最高": 62.85,
        "最低": 62.2,
        "昨收": 62.6,
        "成交量": 6105418.0,
        "成交额": 381542224.0
    },
    {
        "序号": 3635,
        "symbol": "09067",
        "name": "安硕恒生科技-U",
        "最新价": 0.994,
        "涨跌额": -0.004,
        "涨跌幅": -0.4,
        "今开": 1.002,
        "最高": 1.004,
        "最低": 0.992,
        "昨收": 0.998,
        "成交量": 309900.0,
        "成交额": 308744.0
    },
    {
        "序号": 3636,
        "symbol": "00144",
        "name": "招商局港口",
        "最新价": 9.93,
        "涨跌额": -0.04,
        "涨跌幅": -0.4,
        "今开": 10.12,
        "最高": 10.12,
        "最低": 9.83,
        "昨收": 9.97,
        "成交量": 2823871.0,
        "成交额": 28066929.0
    },
    {
        "序号": 3637,
        "symbol": "81024",
        "name": "快手-WR",
        "最新价": 49.35,
        "涨跌额": -0.2,
        "涨跌幅": -0.4,
        "今开": 49.0,
        "最高": 49.6,
        "最低": 48.7,
        "昨收": 49.55,
        "成交量": 3800.0,
        "成交额": 186630.0
    },
    {
        "序号": 3638,
        "symbol": "00995",
        "name": "安徽皖通高速公路",
        "最新价": 7.4,
        "涨跌额": -0.03,
        "涨跌幅": -0.4,
        "今开": 7.46,
        "最高": 7.46,
        "最低": 7.39,
        "昨收": 7.43,
        "成交量": 670000.0,
        "成交额": 4970980.0
    },
    {
        "序号": 3639,
        "symbol": "01088",
        "name": "中国神华",
        "最新价": 24.65,
        "涨跌额": -0.1,
        "涨跌幅": -0.4,
        "今开": 24.85,
        "最高": 24.85,
        "最低": 24.4,
        "昨收": 24.75,
        "成交量": 12511155.0,
        "成交额": 307400816.0
    },
    {
        "序号": 3640,
        "symbol": "03039",
        "name": "易方达恒指ESG",
        "最新价": 2.462,
        "涨跌额": -0.01,
        "涨跌幅": -0.4,
        "今开": 2.464,
        "最高": 2.464,
        "最低": 2.46,
        "昨收": 2.472,
        "成交量": 384300.0,
        "成交额": 946028.0
    },
    {
        "序号": 3641,
        "symbol": "06138",
        "name": "哈尔滨银行",
        "最新价": 0.244,
        "涨跌额": -0.001,
        "涨跌幅": -0.41,
        "今开": 0.244,
        "最高": 0.246,
        "最低": 0.243,
        "昨收": 0.245,
        "成交量": 851000.0,
        "成交额": 207642.0
    },
    {
        "序号": 3642,
        "symbol": "02458",
        "name": "望尘科技控股",
        "最新价": 4.88,
        "涨跌额": -0.02,
        "涨跌幅": -0.41,
        "今开": 5.01,
        "最高": 5.06,
        "最低": 4.88,
        "昨收": 4.9,
        "成交量": 50000.0,
        "成交额": 251284.0
    },
    {
        "序号": 3643,
        "symbol": "01339",
        "name": "中国人民保险集团",
        "最新价": 2.44,
        "涨跌额": -0.01,
        "涨跌幅": -0.41,
        "今开": 2.45,
        "最高": 2.46,
        "最低": 2.43,
        "昨收": 2.45,
        "成交量": 10059656.0,
        "成交额": 24612555.0
    },
    {
        "序号": 3644,
        "symbol": "01810",
        "name": "小米集团-W",
        "最新价": 14.62,
        "涨跌额": -0.06,
        "涨跌幅": -0.41,
        "今开": 14.76,
        "最高": 14.82,
        "最低": 14.4,
        "昨收": 14.68,
        "成交量": 94540322.0,
        "成交额": 1377804944.0
    },
    {
        "序号": 3645,
        "symbol": "00288",
        "name": "万洲国际",
        "最新价": 4.82,
        "涨跌额": -0.02,
        "涨跌幅": -0.41,
        "今开": 4.84,
        "最高": 4.9,
        "最低": 4.8,
        "昨收": 4.84,
        "成交量": 14446533.0,
        "成交额": 69874308.0
    },
    {
        "序号": 3646,
        "symbol": "09803",
        "name": "PP中国基石-U",
        "最新价": 0.963,
        "涨跌额": -0.004,
        "涨跌幅": -0.41,
        "今开": 0.963,
        "最高": 0.963,
        "最低": 0.963,
        "昨收": 0.967,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3647,
        "symbol": "03773",
        "name": "银盛数惠",
        "最新价": 2.38,
        "涨跌额": -0.01,
        "涨跌幅": -0.42,
        "今开": 2.31,
        "最高": 2.42,
        "最低": 2.31,
        "昨收": 2.39,
        "成交量": 280000.0,
        "成交额": 666740.0
    },
    {
        "序号": 3648,
        "symbol": "01413",
        "name": "广联工程控股",
        "最新价": 0.238,
        "涨跌额": -0.001,
        "涨跌幅": -0.42,
        "今开": 0.233,
        "最高": 0.239,
        "最低": 0.231,
        "昨收": 0.239,
        "成交量": 7885000.0,
        "成交额": 1870630.0
    },
    {
        "序号": 3649,
        "symbol": "09403",
        "name": "华夏恒ESG-U",
        "最新价": 4.72,
        "涨跌额": -0.02,
        "涨跌幅": -0.42,
        "今开": 4.72,
        "最高": 4.72,
        "最低": 4.72,
        "昨收": 4.74,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3650,
        "symbol": "06830",
        "name": "华众车载",
        "最新价": 2.35,
        "涨跌额": -0.01,
        "涨跌幅": -0.42,
        "今开": 2.36,
        "最高": 2.38,
        "最低": 2.33,
        "昨收": 2.36,
        "成交量": 3840000.0,
        "成交额": 9048680.0
    },
    {
        "序号": 3651,
        "symbol": "03110",
        "name": "GX恒生高股息率",
        "最新价": 18.8,
        "涨跌额": -0.08,
        "涨跌幅": -0.42,
        "今开": 18.9,
        "最高": 18.91,
        "最低": 18.76,
        "昨收": 18.88,
        "成交量": 247532.0,
        "成交额": 4655595.0
    },
    {
        "序号": 3652,
        "symbol": "00839",
        "name": "中教控股",
        "最新价": 4.68,
        "涨跌额": -0.02,
        "涨跌幅": -0.43,
        "今开": 4.68,
        "最高": 4.75,
        "最低": 4.62,
        "昨收": 4.7,
        "成交量": 9829300.0,
        "成交额": 46090488.0
    },
    {
        "序号": 3653,
        "symbol": "00363",
        "name": "上海实业控股",
        "最新价": 9.32,
        "涨跌额": -0.04,
        "涨跌幅": -0.43,
        "今开": 9.32,
        "最高": 9.36,
        "最低": 9.25,
        "昨收": 9.36,
        "成交量": 220500.0,
        "成交额": 2048255.0
    },
    {
        "序号": 3654,
        "symbol": "07233",
        "name": "南方两倍做多沪深300",
        "最新价": 3.258,
        "涨跌额": -0.014,
        "涨跌幅": -0.43,
        "今开": 3.286,
        "最高": 3.3,
        "最低": 3.258,
        "昨收": 3.272,
        "成交量": 165100.0,
        "成交额": 541151.0
    },
    {
        "序号": 3655,
        "symbol": "03403",
        "name": "华夏恒ESG",
        "最新价": 36.86,
        "涨跌额": -0.16,
        "涨跌幅": -0.43,
        "今开": 37.02,
        "最高": 37.02,
        "最低": 36.84,
        "昨收": 37.02,
        "成交量": 104140.0,
        "成交额": 3838866.0
    },
    {
        "序号": 3656,
        "symbol": "03182",
        "name": "标智新经济50",
        "最新价": 9.17,
        "涨跌额": -0.04,
        "涨跌幅": -0.43,
        "今开": 9.17,
        "最高": 9.17,
        "最低": 9.17,
        "昨收": 9.21,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3657,
        "symbol": "00616",
        "name": "高山企业",
        "最新价": 0.229,
        "涨跌额": -0.001,
        "涨跌幅": -0.43,
        "今开": 0.229,
        "最高": 0.23,
        "最低": 0.229,
        "昨收": 0.23,
        "成交量": 80000.0,
        "成交额": 18380.0
    },
    {
        "序号": 3658,
        "symbol": "00135",
        "name": "昆仑能源",
        "最新价": 6.87,
        "涨跌额": -0.03,
        "涨跌幅": -0.43,
        "今开": 6.9,
        "最高": 6.97,
        "最低": 6.8,
        "昨收": 6.9,
        "成交量": 5887437.0,
        "成交额": 40483204.0
    },
    {
        "序号": 3659,
        "symbol": "01125",
        "name": "丽丰控股",
        "最新价": 2.27,
        "涨跌额": -0.01,
        "涨跌幅": -0.44,
        "今开": 2.28,
        "最高": 2.28,
        "最低": 2.27,
        "昨收": 2.28,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3660,
        "symbol": "01970",
        "name": "IMAX CHINA",
        "最新价": 6.77,
        "涨跌额": -0.03,
        "涨跌幅": -0.44,
        "今开": 6.82,
        "最高": 6.93,
        "最低": 6.77,
        "昨收": 6.8,
        "成交量": 71500.0,
        "成交额": 488679.0
    },
    {
        "序号": 3661,
        "symbol": "01399",
        "name": "锐信控股",
        "最新价": 0.22,
        "涨跌额": -0.001,
        "涨跌幅": -0.45,
        "今开": 0.22,
        "最高": 0.22,
        "最低": 0.22,
        "昨收": 0.221,
        "成交量": 106000.0,
        "成交额": 23320.0
    },
    {
        "序号": 3662,
        "symbol": "00683",
        "name": "嘉里建设",
        "最新价": 13.02,
        "涨跌额": -0.06,
        "涨跌幅": -0.46,
        "今开": 13.1,
        "最高": 13.16,
        "最低": 13.0,
        "昨收": 13.08,
        "成交量": 881500.0,
        "成交额": 11507590.0
    },
    {
        "序号": 3663,
        "symbol": "09936",
        "name": "稀美资源",
        "最新价": 4.3,
        "涨跌额": -0.02,
        "涨跌幅": -0.46,
        "今开": 4.35,
        "最高": 4.38,
        "最低": 4.19,
        "昨收": 4.32,
        "成交量": 285000.0,
        "成交额": 1218375.0
    },
    {
        "序号": 3664,
        "symbol": "00012",
        "name": "恒基地产",
        "最新价": 21.25,
        "涨跌额": -0.1,
        "涨跌幅": -0.47,
        "今开": 21.55,
        "最高": 21.95,
        "最低": 21.05,
        "昨收": 21.35,
        "成交量": 2615599.0,
        "成交额": 56118700.0
    },
    {
        "序号": 3665,
        "symbol": "03301",
        "name": "融信中国",
        "最新价": 0.21,
        "涨跌额": -0.001,
        "涨跌幅": -0.47,
        "今开": 0.21,
        "最高": 0.224,
        "最低": 0.21,
        "昨收": 0.211,
        "成交量": 64000.0,
        "成交额": 13495.0
    },
    {
        "序号": 3666,
        "symbol": "01371",
        "name": "中国生态旅游",
        "最新价": 0.209,
        "涨跌额": -0.001,
        "涨跌幅": -0.48,
        "今开": 0.2,
        "最高": 0.209,
        "最低": 0.2,
        "昨收": 0.21,
        "成交量": 13000.0,
        "成交额": 2582.0
    },
    {
        "序号": 3667,
        "symbol": "02660",
        "name": "禅游科技",
        "最新价": 4.15,
        "涨跌额": -0.02,
        "涨跌幅": -0.48,
        "今开": 4.21,
        "最高": 4.3,
        "最低": 4.11,
        "昨收": 4.17,
        "成交量": 1368000.0,
        "成交额": 5738200.0
    },
    {
        "序号": 3668,
        "symbol": "06958",
        "name": "正荣服务",
        "最新价": 0.205,
        "涨跌额": -0.001,
        "涨跌幅": -0.49,
        "今开": 0.195,
        "最高": 0.205,
        "最低": 0.195,
        "昨收": 0.206,
        "成交量": 15000.0,
        "成交额": 2960.0
    },
    {
        "序号": 3669,
        "symbol": "02388",
        "name": "中银香港",
        "最新价": 20.45,
        "涨跌额": -0.1,
        "涨跌幅": -0.49,
        "今开": 20.55,
        "最高": 20.75,
        "最低": 20.4,
        "昨收": 20.55,
        "成交量": 5110586.0,
        "成交额": 104821821.0
    },
    {
        "序号": 3670,
        "symbol": "03032",
        "name": "恒生科技ETF",
        "最新价": 3.674,
        "涨跌额": -0.018,
        "涨跌幅": -0.49,
        "今开": 3.692,
        "最高": 3.716,
        "最低": 3.646,
        "昨收": 3.692,
        "成交量": 2839600.0,
        "成交额": 10432883.0
    },
    {
        "序号": 3671,
        "symbol": "03029",
        "name": "GX恒生ESG",
        "最新价": 3.254,
        "涨跌额": -0.016,
        "涨跌幅": -0.49,
        "今开": 3.278,
        "最高": 3.278,
        "最低": 3.254,
        "昨收": 3.27,
        "成交量": 150.0,
        "成交额": 491.0
    },
    {
        "序号": 3672,
        "symbol": "00008",
        "name": "电讯盈科",
        "最新价": 4.02,
        "涨跌额": -0.02,
        "涨跌幅": -0.5,
        "今开": 4.04,
        "最高": 4.05,
        "最低": 3.99,
        "昨收": 4.04,
        "成交量": 5489509.0,
        "成交额": 22003228.0
    },
    {
        "序号": 3673,
        "symbol": "03866",
        "name": "青岛银行",
        "最新价": 1.98,
        "涨跌额": -0.01,
        "涨跌幅": -0.5,
        "今开": 1.99,
        "最高": 1.99,
        "最低": 1.97,
        "昨收": 1.99,
        "成交量": 424000.0,
        "成交额": 838410.0
    },
    {
        "序号": 3674,
        "symbol": "00907",
        "name": "高雅光学",
        "最新价": 0.195,
        "涨跌额": -0.001,
        "涨跌幅": -0.51,
        "今开": 0.195,
        "最高": 0.199,
        "最低": 0.195,
        "昨收": 0.196,
        "成交量": 100000.0,
        "成交额": 19820.0
    },
    {
        "序号": 3675,
        "symbol": "01541",
        "name": "宜明昂科-B",
        "最新价": 29.2,
        "涨跌额": -0.15,
        "涨跌幅": -0.51,
        "今开": 30.5,
        "最高": 30.5,
        "最低": 28.9,
        "昨收": 29.35,
        "成交量": 122200.0,
        "成交额": 3632400.0
    },
    {
        "序号": 3676,
        "symbol": "01963",
        "name": "重庆银行",
        "最新价": 3.89,
        "涨跌额": -0.02,
        "涨跌幅": -0.51,
        "今开": 3.91,
        "最高": 3.91,
        "最低": 3.89,
        "昨收": 3.91,
        "成交量": 794000.0,
        "成交额": 3096775.0
    },
    {
        "序号": 3677,
        "symbol": "03067",
        "name": "安硕恒生科技",
        "最新价": 7.765,
        "涨跌额": -0.04,
        "涨跌幅": -0.51,
        "今开": 7.805,
        "最高": 7.86,
        "最低": 7.705,
        "昨收": 7.805,
        "成交量": 8726980.0,
        "成交额": 67831297.0
    },
    {
        "序号": 3678,
        "symbol": "00171",
        "name": "银建国际",
        "最新价": 0.194,
        "涨跌额": -0.001,
        "涨跌幅": -0.51,
        "今开": 0.177,
        "最高": 0.194,
        "最低": 0.177,
        "昨收": 0.195,
        "成交量": 70000.0,
        "成交额": 13354.0
    },
    {
        "序号": 3679,
        "symbol": "02402",
        "name": "亿华通",
        "最新价": 38.7,
        "涨跌额": -0.2,
        "涨跌幅": -0.51,
        "今开": 39.0,
        "最高": 39.0,
        "最低": 36.8,
        "昨收": 38.9,
        "成交量": 23100.0,
        "成交额": 867907.0
    },
    {
        "序号": 3680,
        "symbol": "09863",
        "name": "零跑汽车",
        "最新价": 38.65,
        "涨跌额": -0.2,
        "涨跌幅": -0.51,
        "今开": 38.95,
        "最高": 39.5,
        "最低": 37.8,
        "昨收": 38.85,
        "成交量": 6434400.0,
        "成交额": 249474225.0
    },
    {
        "序号": 3681,
        "symbol": "00019",
        "name": "太古股份公司A",
        "最新价": 57.75,
        "涨跌额": -0.3,
        "涨跌幅": -0.52,
        "今开": 58.35,
        "最高": 59.5,
        "最低": 57.35,
        "昨收": 58.05,
        "成交量": 1215392.0,
        "成交额": 70650604.0
    },
    {
        "序号": 3682,
        "symbol": "00065",
        "name": "弘海高新资源",
        "最新价": 0.19,
        "涨跌额": -0.001,
        "涨跌幅": -0.52,
        "今开": 0.191,
        "最高": 0.191,
        "最低": 0.189,
        "昨收": 0.191,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3683,
        "symbol": "01579",
        "name": "颐海国际",
        "最新价": 11.38,
        "涨跌额": -0.06,
        "涨跌幅": -0.52,
        "今开": 11.5,
        "最高": 11.66,
        "最低": 11.32,
        "昨收": 11.44,
        "成交量": 1755000.0,
        "成交额": 20212984.0
    },
    {
        "序号": 3684,
        "symbol": "06610",
        "name": "飞天云动",
        "最新价": 1.89,
        "涨跌额": -0.01,
        "涨跌幅": -0.53,
        "今开": 1.88,
        "最高": 1.93,
        "最低": 1.87,
        "昨收": 1.9,
        "成交量": 7672000.0,
        "成交额": 14485120.0
    },
    {
        "序号": 3685,
        "symbol": "01882",
        "name": "海天国际",
        "最新价": 18.88,
        "涨跌额": -0.1,
        "涨跌幅": -0.53,
        "今开": 19.56,
        "最高": 19.64,
        "最低": 18.74,
        "昨收": 18.98,
        "成交量": 882000.0,
        "成交额": 16737337.0
    },
    {
        "序号": 3686,
        "symbol": "01561",
        "name": "联洋智能控股",
        "最新价": 0.188,
        "涨跌额": -0.001,
        "涨跌幅": -0.53,
        "今开": 0.184,
        "最高": 0.19,
        "最低": 0.183,
        "昨收": 0.189,
        "成交量": 3048000.0,
        "成交额": 560756.0
    },
    {
        "序号": 3687,
        "symbol": "08540",
        "name": "胜利证券",
        "最新价": 3.74,
        "涨跌额": -0.02,
        "涨跌幅": -0.53,
        "今开": 3.76,
        "最高": 3.94,
        "最低": 3.69,
        "昨收": 3.76,
        "成交量": 260000.0,
        "成交额": 983240.0
    },
    {
        "序号": 3688,
        "symbol": "00554",
        "name": "汉思能源",
        "最新价": 0.187,
        "涨跌额": -0.001,
        "涨跌幅": -0.53,
        "今开": 0.195,
        "最高": 0.199,
        "最低": 0.187,
        "昨收": 0.188,
        "成交量": 184000.0,
        "成交额": 35692.0
    },
    {
        "序号": 3689,
        "symbol": "02836",
        "name": "安硕印度",
        "最新价": 37.0,
        "涨跌额": -0.2,
        "涨跌幅": -0.54,
        "今开": 37.2,
        "最高": 37.2,
        "最低": 37.0,
        "昨收": 37.2,
        "成交量": 38800.0,
        "成交额": 1439392.0
    },
    {
        "序号": 3690,
        "symbol": "00136",
        "name": "中国儒意",
        "最新价": 1.84,
        "涨跌额": -0.01,
        "涨跌幅": -0.54,
        "今开": 1.86,
        "最高": 1.93,
        "最低": 1.83,
        "昨收": 1.85,
        "成交量": 67186564.0,
        "成交额": 125898266.0
    },
    {
        "序号": 3691,
        "symbol": "02618",
        "name": "京东物流",
        "最新价": 9.16,
        "涨跌额": -0.05,
        "涨跌幅": -0.54,
        "今开": 9.25,
        "最高": 9.33,
        "最低": 9.15,
        "昨收": 9.21,
        "成交量": 3194400.0,
        "成交额": 29462752.0
    },
    {
        "序号": 3692,
        "symbol": "01024",
        "name": "快手-W",
        "最新价": 53.85,
        "涨跌额": -0.3,
        "涨跌幅": -0.55,
        "今开": 54.5,
        "最高": 55.15,
        "最低": 53.05,
        "昨收": 54.15,
        "成交量": 15639290.0,
        "成交额": 840322464.0
    },
    {
        "序号": 3693,
        "symbol": "00668",
        "name": "东银国际控股",
        "最新价": 0.179,
        "涨跌额": -0.001,
        "涨跌幅": -0.56,
        "今开": 0.179,
        "最高": 0.179,
        "最低": 0.179,
        "昨收": 0.18,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3694,
        "symbol": "03004",
        "name": "南方东英越南30",
        "最新价": 6.23,
        "涨跌额": -0.035,
        "涨跌幅": -0.56,
        "今开": 6.23,
        "最高": 6.23,
        "最低": 6.23,
        "昨收": 6.265,
        "成交量": 3000.0,
        "成交额": 18690.0
    },
    {
        "序号": 3695,
        "symbol": "01381",
        "name": "粤丰环保",
        "最新价": 3.56,
        "涨跌额": -0.02,
        "涨跌幅": -0.56,
        "今开": 3.6,
        "最高": 3.6,
        "最低": 3.51,
        "昨收": 3.58,
        "成交量": 323000.0,
        "成交额": 1155930.0
    },
    {
        "序号": 3696,
        "symbol": "00148",
        "name": "建滔集团",
        "最新价": 17.78,
        "涨跌额": -0.1,
        "涨跌幅": -0.56,
        "今开": 17.98,
        "最高": 18.0,
        "最低": 17.58,
        "昨收": 17.88,
        "成交量": 1278500.0,
        "成交额": 22729689.0
    },
    {
        "序号": 3697,
        "symbol": "07200",
        "name": "南方两倍看多恒指",
        "最新价": 2.83,
        "涨跌额": -0.016,
        "涨跌幅": -0.56,
        "今开": 2.86,
        "最高": 2.88,
        "最低": 2.8,
        "昨收": 2.846,
        "成交量": 69078100.0,
        "成交额": 195853548.0
    },
    {
        "序号": 3698,
        "symbol": "03606",
        "name": "福耀玻璃",
        "最新价": 35.35,
        "涨跌额": -0.2,
        "涨跌幅": -0.56,
        "今开": 35.55,
        "最高": 35.55,
        "最低": 34.95,
        "昨收": 35.55,
        "成交量": 776738.0,
        "成交额": 27395471.0
    },
    {
        "序号": 3699,
        "symbol": "03939",
        "name": "万国国际矿业",
        "最新价": 3.51,
        "涨跌额": -0.02,
        "涨跌幅": -0.57,
        "今开": 3.53,
        "最高": 3.58,
        "最低": 3.49,
        "昨收": 3.53,
        "成交量": 202000.0,
        "成交额": 711940.0
    },
    {
        "序号": 3700,
        "symbol": "03933",
        "name": "联邦制药",
        "最新价": 7.02,
        "涨跌额": -0.04,
        "涨跌幅": -0.57,
        "今开": 7.08,
        "最高": 7.08,
        "最低": 6.77,
        "昨收": 7.06,
        "成交量": 14312200.0,
        "成交额": 99002792.0
    },
    {
        "序号": 3701,
        "symbol": "01427",
        "name": "中国天保集团",
        "最新价": 0.175,
        "涨跌额": -0.001,
        "涨跌幅": -0.57,
        "今开": 0.174,
        "最高": 0.175,
        "最低": 0.174,
        "昨收": 0.176,
        "成交量": 33000.0,
        "成交额": 5754.0
    },
    {
        "序号": 3702,
        "symbol": "06990",
        "name": "科伦博泰生物-B",
        "最新价": 95.25,
        "涨跌额": -0.55,
        "涨跌幅": -0.57,
        "今开": 95.0,
        "最高": 97.8,
        "最低": 94.05,
        "昨收": 95.8,
        "成交量": 128300.0,
        "成交额": 12252730.0
    },
    {
        "序号": 3703,
        "symbol": "00069",
        "name": "香格里拉(亚洲)",
        "最新价": 5.19,
        "涨跌额": -0.03,
        "涨跌幅": -0.57,
        "今开": 5.25,
        "最高": 5.25,
        "最低": 5.05,
        "昨收": 5.22,
        "成交量": 1054537.0,
        "成交额": 5464778.0
    },
    {
        "序号": 3704,
        "symbol": "06055",
        "name": "中烟香港",
        "最新价": 10.32,
        "涨跌额": -0.06,
        "涨跌幅": -0.58,
        "今开": 10.48,
        "最高": 10.54,
        "最低": 10.32,
        "昨收": 10.38,
        "成交量": 242000.0,
        "成交额": 2520380.0
    },
    {
        "序号": 3705,
        "symbol": "00183",
        "name": "宏辉集团",
        "最新价": 0.169,
        "涨跌额": -0.001,
        "涨跌幅": -0.59,
        "今开": 0.169,
        "最高": 0.169,
        "最低": 0.169,
        "昨收": 0.17,
        "成交量": 8000.0,
        "成交额": 1352.0
    },
    {
        "序号": 3706,
        "symbol": "02845",
        "name": "GX中国电车",
        "最新价": 74.24,
        "涨跌额": -0.44,
        "涨跌幅": -0.59,
        "今开": 74.7,
        "最高": 74.7,
        "最低": 74.16,
        "昨收": 74.68,
        "成交量": 74148.0,
        "成交额": 5512839.0
    },
    {
        "序号": 3707,
        "symbol": "08147",
        "name": "汇思太平洋",
        "最新价": 0.168,
        "涨跌额": -0.001,
        "涨跌幅": -0.59,
        "今开": 0.168,
        "最高": 0.168,
        "最低": 0.168,
        "昨收": 0.169,
        "成交量": 390500.0,
        "成交额": 65565.0
    },
    {
        "序号": 3708,
        "symbol": "03678",
        "name": "弘业期货",
        "最新价": 1.67,
        "涨跌额": -0.01,
        "涨跌幅": -0.6,
        "今开": 1.69,
        "最高": 1.71,
        "最低": 1.64,
        "昨收": 1.68,
        "成交量": 5618000.0,
        "成交额": 9414330.0
    },
    {
        "序号": 3709,
        "symbol": "02602",
        "name": "万物云",
        "最新价": 24.7,
        "涨跌额": -0.15,
        "涨跌幅": -0.6,
        "今开": 25.15,
        "最高": 25.15,
        "最低": 24.1,
        "昨收": 24.85,
        "成交量": 915200.0,
        "成交额": 22515490.0
    },
    {
        "序号": 3710,
        "symbol": "01766",
        "name": "中国中车",
        "最新价": 3.27,
        "涨跌额": -0.02,
        "涨跌幅": -0.61,
        "今开": 3.3,
        "最高": 3.33,
        "最低": 3.25,
        "昨收": 3.29,
        "成交量": 13022795.0,
        "成交额": 42758610.0
    },
    {
        "序号": 3711,
        "symbol": "80388",
        "name": "香港交易所-R",
        "最新价": 228.6,
        "涨跌额": -1.4,
        "涨跌幅": -0.61,
        "今开": 230.0,
        "最高": 230.4,
        "最低": 227.0,
        "昨收": 230.0,
        "成交量": 10000.0,
        "成交额": 2283240.0
    },
    {
        "序号": 3712,
        "symbol": "02359",
        "name": "药明康德",
        "最新价": 81.15,
        "涨跌额": -0.5,
        "涨跌幅": -0.61,
        "今开": 81.2,
        "最高": 81.9,
        "最低": 80.2,
        "昨收": 81.65,
        "成交量": 3615678.0,
        "成交额": 293501456.0
    },
    {
        "序号": 3713,
        "symbol": "02160",
        "name": "心通医疗-B",
        "最新价": 1.62,
        "涨跌额": -0.01,
        "涨跌幅": -0.61,
        "今开": 1.62,
        "最高": 1.66,
        "最低": 1.6,
        "昨收": 1.63,
        "成交量": 998000.0,
        "成交额": 1615780.0
    },
    {
        "序号": 3714,
        "symbol": "02356",
        "name": "大新银行集团",
        "最新价": 4.83,
        "涨跌额": -0.03,
        "涨跌幅": -0.62,
        "今开": 4.9,
        "最高": 5.09,
        "最低": 4.83,
        "昨收": 4.86,
        "成交量": 212000.0,
        "成交额": 1030802.0
    },
    {
        "序号": 3715,
        "symbol": "09069",
        "name": "华夏恒生生科-U",
        "最新价": 1.284,
        "涨跌额": -0.008,
        "涨跌幅": -0.62,
        "今开": 1.284,
        "最高": 1.284,
        "最低": 1.284,
        "昨收": 1.292,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3716,
        "symbol": "00819",
        "name": "天能动力",
        "最新价": 6.35,
        "涨跌额": -0.04,
        "涨跌幅": -0.63,
        "今开": 6.3,
        "最高": 6.44,
        "最低": 6.23,
        "昨收": 6.39,
        "成交量": 3757700.0,
        "成交额": 23840735.0
    },
    {
        "序号": 3717,
        "symbol": "09845",
        "name": "GX中国电车-U",
        "最新价": 9.51,
        "涨跌额": -0.06,
        "涨跌幅": -0.63,
        "今开": 9.605,
        "最高": 9.605,
        "最低": 9.49,
        "昨收": 9.57,
        "成交量": 90452.0,
        "成交额": 861401.0
    },
    {
        "序号": 3718,
        "symbol": "01204",
        "name": "博维智慧",
        "最新价": 3.17,
        "涨跌额": -0.02,
        "涨跌幅": -0.63,
        "今开": 3.22,
        "最高": 3.22,
        "最低": 3.12,
        "昨收": 3.19,
        "成交量": 178000.0,
        "成交额": 566380.0
    },
    {
        "序号": 3719,
        "symbol": "01520",
        "name": "天机控股",
        "最新价": 0.158,
        "涨跌额": -0.001,
        "涨跌幅": -0.63,
        "今开": 0.159,
        "最高": 0.159,
        "最低": 0.152,
        "昨收": 0.159,
        "成交量": 9378000.0,
        "成交额": 1481058.0
    },
    {
        "序号": 3720,
        "symbol": "00778",
        "name": "置富产业信托",
        "最新价": 4.7,
        "涨跌额": -0.03,
        "涨跌幅": -0.63,
        "今开": 4.73,
        "最高": 4.78,
        "最低": 4.7,
        "昨收": 4.73,
        "成交量": 840000.0,
        "成交额": 3978918.0
    },
    {
        "序号": 3721,
        "symbol": "80700",
        "name": "腾讯控股-R",
        "最新价": 280.0,
        "涨跌额": -1.8,
        "涨跌幅": -0.64,
        "今开": 282.4,
        "最高": 282.8,
        "最低": 276.4,
        "昨收": 281.8,
        "成交量": 119300.0,
        "成交额": 33253220.0
    },
    {
        "序号": 3722,
        "symbol": "00579",
        "name": "京能清洁能源",
        "最新价": 1.54,
        "涨跌额": -0.01,
        "涨跌幅": -0.65,
        "今开": 1.55,
        "最高": 1.56,
        "最低": 1.53,
        "昨收": 1.55,
        "成交量": 3618000.0,
        "成交额": 5599400.0
    },
    {
        "序号": 3723,
        "symbol": "02601",
        "name": "中国太保",
        "最新价": 15.3,
        "涨跌额": -0.1,
        "涨跌幅": -0.65,
        "今开": 15.48,
        "最高": 15.54,
        "最低": 15.16,
        "昨收": 15.4,
        "成交量": 5604600.0,
        "成交额": 86035361.0
    },
    {
        "序号": 3724,
        "symbol": "00306",
        "name": "冠忠巴士集团",
        "最新价": 1.53,
        "涨跌额": -0.01,
        "涨跌幅": -0.65,
        "今开": 1.53,
        "最高": 1.53,
        "最低": 1.53,
        "昨收": 1.54,
        "成交量": 4000.0,
        "成交额": 6120.0
    },
    {
        "序号": 3725,
        "symbol": "01240",
        "name": "青建国际",
        "最新价": 0.152,
        "涨跌额": -0.001,
        "涨跌幅": -0.65,
        "今开": 0.15,
        "最高": 0.152,
        "最低": 0.149,
        "昨收": 0.153,
        "成交量": 52500.0,
        "成交额": 7860.0
    },
    {
        "序号": 3726,
        "symbol": "02002",
        "name": "阳光纸业",
        "最新价": 3.02,
        "涨跌额": -0.02,
        "涨跌幅": -0.66,
        "今开": 3.05,
        "最高": 3.06,
        "最低": 2.98,
        "昨收": 3.04,
        "成交量": 668000.0,
        "成交额": 2012520.0
    },
    {
        "序号": 3727,
        "symbol": "01713",
        "name": "四川能投发展",
        "最新价": 1.51,
        "涨跌额": -0.01,
        "涨跌幅": -0.66,
        "今开": 1.52,
        "最高": 1.56,
        "最低": 1.51,
        "昨收": 1.52,
        "成交量": 106000.0,
        "成交额": 162260.0
    },
    {
        "序号": 3728,
        "symbol": "01263",
        "name": "栢能集团",
        "最新价": 3.01,
        "涨跌额": -0.02,
        "涨跌幅": -0.66,
        "今开": 3.03,
        "最高": 3.09,
        "最低": 2.97,
        "昨收": 3.03,
        "成交量": 174000.0,
        "成交额": 524040.0
    },
    {
        "序号": 3729,
        "symbol": "03808",
        "name": "中国重汽",
        "最新价": 15.0,
        "涨跌额": -0.1,
        "涨跌幅": -0.66,
        "今开": 15.18,
        "最高": 15.28,
        "最低": 14.9,
        "昨收": 15.1,
        "成交量": 2023000.0,
        "成交额": 30468649.0
    },
    {
        "序号": 3730,
        "symbol": "09608",
        "name": "宋都服务",
        "最新价": 0.148,
        "涨跌额": -0.001,
        "涨跌幅": -0.67,
        "今开": 0.149,
        "最高": 0.149,
        "最低": 0.146,
        "昨收": 0.149,
        "成交量": 7650000.0,
        "成交额": 1132180.0
    },
    {
        "序号": 3731,
        "symbol": "00934",
        "name": "中石化冠德",
        "最新价": 2.96,
        "涨跌额": -0.02,
        "涨跌幅": -0.67,
        "今开": 2.98,
        "最高": 3.0,
        "最低": 2.95,
        "昨收": 2.98,
        "成交量": 1686000.0,
        "成交额": 5004700.0
    },
    {
        "序号": 3732,
        "symbol": "01186",
        "name": "中国铁建",
        "最新价": 4.43,
        "涨跌额": -0.03,
        "涨跌幅": -0.67,
        "今开": 4.46,
        "最高": 4.51,
        "最低": 4.43,
        "昨收": 4.46,
        "成交量": 1291500.0,
        "成交额": 5747485.0
    },
    {
        "序号": 3733,
        "symbol": "03150",
        "name": "GX日本全球领导",
        "最新价": 53.14,
        "涨跌额": -0.36,
        "涨跌幅": -0.67,
        "今开": 53.14,
        "最高": 53.14,
        "最低": 53.14,
        "昨收": 53.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3734,
        "symbol": "09959",
        "name": "联易融科技-W",
        "最新价": 1.47,
        "涨跌额": -0.01,
        "涨跌幅": -0.68,
        "今开": 1.48,
        "最高": 1.5,
        "最低": 1.43,
        "昨收": 1.48,
        "成交量": 13006000.0,
        "成交额": 18966717.0
    },
    {
        "序号": 3735,
        "symbol": "03174",
        "name": "南方医疗健康",
        "最新价": 2.352,
        "涨跌额": -0.016,
        "涨跌幅": -0.68,
        "今开": 2.384,
        "最高": 2.384,
        "最低": 2.35,
        "昨收": 2.368,
        "成交量": 20400.0,
        "成交额": 48121.0
    },
    {
        "序号": 3736,
        "symbol": "00631",
        "name": "三一国际",
        "最新价": 7.34,
        "涨跌额": -0.05,
        "涨跌幅": -0.68,
        "今开": 7.49,
        "最高": 7.5,
        "最低": 7.27,
        "昨收": 7.39,
        "成交量": 2052000.0,
        "成交额": 15157073.0
    },
    {
        "序号": 3737,
        "symbol": "01921",
        "name": "达力普控股",
        "最新价": 4.37,
        "涨跌额": -0.03,
        "涨跌幅": -0.68,
        "今开": 4.34,
        "最高": 4.43,
        "最低": 4.27,
        "昨收": 4.4,
        "成交量": 1364000.0,
        "成交额": 5932540.0
    },
    {
        "序号": 3738,
        "symbol": "01618",
        "name": "中国中冶",
        "最新价": 1.44,
        "涨跌额": -0.01,
        "涨跌幅": -0.69,
        "今开": 1.44,
        "最高": 1.46,
        "最低": 1.44,
        "昨收": 1.45,
        "成交量": 1976000.0,
        "成交额": 2862750.0
    },
    {
        "序号": 3739,
        "symbol": "00951",
        "name": "超威动力",
        "最新价": 1.43,
        "涨跌额": -0.01,
        "涨跌幅": -0.69,
        "今开": 1.43,
        "最高": 1.43,
        "最低": 1.41,
        "昨收": 1.44,
        "成交量": 16000.0,
        "成交额": 22820.0
    },
    {
        "序号": 3740,
        "symbol": "00302",
        "name": "中手游",
        "最新价": 1.43,
        "涨跌额": -0.01,
        "涨跌幅": -0.69,
        "今开": 1.45,
        "最高": 1.45,
        "最低": 1.41,
        "昨收": 1.44,
        "成交量": 1934000.0,
        "成交额": 2748460.0
    },
    {
        "序号": 3741,
        "symbol": "03315",
        "name": "金邦达宝嘉",
        "最新价": 1.42,
        "涨跌额": -0.01,
        "涨跌幅": -0.7,
        "今开": 1.4,
        "最高": 1.43,
        "最低": 1.4,
        "昨收": 1.43,
        "成交量": 180000.0,
        "成交额": 255020.0
    },
    {
        "序号": 3742,
        "symbol": "00241",
        "name": "阿里健康",
        "最新价": 4.25,
        "涨跌额": -0.03,
        "涨跌幅": -0.7,
        "今开": 4.28,
        "最高": 4.38,
        "最低": 4.25,
        "昨收": 4.28,
        "成交量": 27148893.0,
        "成交额": 116195155.0
    },
    {
        "序号": 3743,
        "symbol": "06989",
        "name": "卓越商企服务",
        "最新价": 1.41,
        "涨跌额": -0.01,
        "涨跌幅": -0.7,
        "今开": 1.42,
        "最高": 1.44,
        "最低": 1.39,
        "昨收": 1.42,
        "成交量": 423000.0,
        "成交额": 595046.0
    },
    {
        "序号": 3744,
        "symbol": "03709",
        "name": "赢家时尚",
        "最新价": 14.1,
        "涨跌额": -0.1,
        "涨跌幅": -0.7,
        "今开": 14.32,
        "最高": 14.54,
        "最低": 13.9,
        "昨收": 14.2,
        "成交量": 1552000.0,
        "成交额": 21954266.0
    },
    {
        "序号": 3745,
        "symbol": "00841",
        "name": "木薯资源",
        "最新价": 0.141,
        "涨跌额": -0.001,
        "涨跌幅": -0.7,
        "今开": 0.142,
        "最高": 0.142,
        "最低": 0.138,
        "昨收": 0.142,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3746,
        "symbol": "00546",
        "name": "阜丰集团",
        "最新价": 4.2,
        "涨跌额": -0.03,
        "涨跌幅": -0.71,
        "今开": 4.23,
        "最高": 4.27,
        "最低": 4.19,
        "昨收": 4.23,
        "成交量": 1310000.0,
        "成交额": 5530040.0
    },
    {
        "序号": 3747,
        "symbol": "00057",
        "name": "震雄集团",
        "最新价": 1.4,
        "涨跌额": -0.01,
        "涨跌幅": -0.71,
        "今开": 1.4,
        "最高": 1.4,
        "最低": 1.38,
        "昨收": 1.41,
        "成交量": 30000.0,
        "成交额": 41960.0
    },
    {
        "序号": 3748,
        "symbol": "01606",
        "name": "国银金租",
        "最新价": 1.39,
        "涨跌额": -0.01,
        "涨跌幅": -0.71,
        "今开": 1.41,
        "最高": 1.41,
        "最低": 1.39,
        "昨收": 1.4,
        "成交量": 2568000.0,
        "成交额": 3589840.0
    },
    {
        "序号": 3749,
        "symbol": "00629",
        "name": "悦达国际控股",
        "最新价": 0.139,
        "涨跌额": -0.001,
        "涨跌幅": -0.71,
        "今开": 0.14,
        "最高": 0.14,
        "最低": 0.139,
        "昨收": 0.14,
        "成交量": 78000.0,
        "成交额": 10920.0
    },
    {
        "序号": 3750,
        "symbol": "00700",
        "name": "腾讯控股",
        "最新价": 305.6,
        "涨跌额": -2.2,
        "涨跌幅": -0.71,
        "今开": 307.4,
        "最高": 309.2,
        "最低": 301.0,
        "昨收": 307.8,
        "成交量": 16600566.0,
        "成交额": 5058680832.0
    },
    {
        "序号": 3751,
        "symbol": "00476",
        "name": "科轩动力控股",
        "最新价": 0.138,
        "涨跌额": -0.001,
        "涨跌幅": -0.72,
        "今开": 0.134,
        "最高": 0.138,
        "最低": 0.128,
        "昨收": 0.139,
        "成交量": 922000.0,
        "成交额": 120576.0
    },
    {
        "序号": 3752,
        "symbol": "00855",
        "name": "中国水务",
        "最新价": 4.12,
        "涨跌额": -0.03,
        "涨跌幅": -0.72,
        "今开": 4.15,
        "最高": 4.18,
        "最低": 4.11,
        "昨收": 4.15,
        "成交量": 1861106.0,
        "成交额": 7700700.0
    },
    {
        "序号": 3753,
        "symbol": "00811",
        "name": "新华文轩",
        "最新价": 6.84,
        "涨跌额": -0.05,
        "涨跌幅": -0.73,
        "今开": 7.03,
        "最高": 7.03,
        "最低": 6.8,
        "昨收": 6.89,
        "成交量": 465000.0,
        "成交额": 3186920.0
    },
    {
        "序号": 3754,
        "symbol": "00439",
        "name": "光启科学",
        "最新价": 0.136,
        "涨跌额": -0.001,
        "涨跌幅": -0.73,
        "今开": 0.135,
        "最高": 0.137,
        "最低": 0.13,
        "昨收": 0.137,
        "成交量": 687000.0,
        "成交额": 91952.0
    },
    {
        "序号": 3755,
        "symbol": "00308",
        "name": "香港中旅",
        "最新价": 1.36,
        "涨跌额": -0.01,
        "涨跌幅": -0.73,
        "今开": 1.37,
        "最高": 1.37,
        "最低": 1.34,
        "昨收": 1.37,
        "成交量": 820000.0,
        "成交额": 1105460.0
    },
    {
        "序号": 3756,
        "symbol": "03633",
        "name": "中裕能源",
        "最新价": 5.37,
        "涨跌额": -0.04,
        "涨跌幅": -0.74,
        "今开": 5.4,
        "最高": 5.51,
        "最低": 5.3,
        "昨收": 5.41,
        "成交量": 1924000.0,
        "成交额": 10419620.0
    },
    {
        "序号": 3757,
        "symbol": "06689",
        "name": "洪九果品",
        "最新价": 5.31,
        "涨跌额": -0.04,
        "涨跌幅": -0.75,
        "今开": 5.35,
        "最高": 5.47,
        "最低": 5.26,
        "昨收": 5.35,
        "成交量": 5033900.0,
        "成交额": 26884283.0
    },
    {
        "序号": 3758,
        "symbol": "00085",
        "name": "中电华大科技",
        "最新价": 1.32,
        "涨跌额": -0.01,
        "涨跌幅": -0.75,
        "今开": 1.34,
        "最高": 1.34,
        "最低": 1.32,
        "昨收": 1.33,
        "成交量": 1606000.0,
        "成交额": 2135540.0
    },
    {
        "序号": 3759,
        "symbol": "01846",
        "name": "德视佳",
        "最新价": 5.26,
        "涨跌额": -0.04,
        "涨跌幅": -0.75,
        "今开": 5.26,
        "最高": 5.29,
        "最低": 5.26,
        "昨收": 5.3,
        "成交量": 12000.0,
        "成交额": 63150.0
    },
    {
        "序号": 3760,
        "symbol": "00101",
        "name": "恒隆地产",
        "最新价": 10.42,
        "涨跌额": -0.08,
        "涨跌幅": -0.76,
        "今开": 10.58,
        "最高": 10.78,
        "最低": 10.36,
        "昨收": 10.5,
        "成交量": 3723161.0,
        "成交额": 38928401.0
    },
    {
        "序号": 3761,
        "symbol": "01065",
        "name": "天津创业环保股份",
        "最新价": 2.59,
        "涨跌额": -0.02,
        "涨跌幅": -0.77,
        "今开": 2.59,
        "最高": 2.62,
        "最低": 2.58,
        "昨收": 2.61,
        "成交量": 270000.0,
        "成交额": 701700.0
    },
    {
        "序号": 3762,
        "symbol": "00669",
        "name": "创科实业",
        "最新价": 77.55,
        "涨跌额": -0.6,
        "涨跌幅": -0.77,
        "今开": 78.0,
        "最高": 78.55,
        "最低": 76.95,
        "昨收": 78.15,
        "成交量": 2057924.0,
        "成交额": 160235507.0
    },
    {
        "序号": 3763,
        "symbol": "01271",
        "name": "佳明集团控股",
        "最新价": 3.87,
        "涨跌额": -0.03,
        "涨跌幅": -0.77,
        "今开": 3.92,
        "最高": 3.92,
        "最低": 3.79,
        "昨收": 3.9,
        "成交量": 20000.0,
        "成交额": 77320.0
    },
    {
        "序号": 3764,
        "symbol": "03759",
        "name": "康龙化成",
        "最新价": 15.44,
        "涨跌额": -0.12,
        "涨跌幅": -0.77,
        "今开": 15.9,
        "最高": 15.9,
        "最低": 15.3,
        "昨收": 15.56,
        "成交量": 4545591.0,
        "成交额": 70448276.0
    },
    {
        "序号": 3765,
        "symbol": "01988",
        "name": "民生银行",
        "最新价": 2.57,
        "涨跌额": -0.02,
        "涨跌幅": -0.77,
        "今开": 2.59,
        "最高": 2.61,
        "最低": 2.56,
        "昨收": 2.59,
        "成交量": 7834664.0,
        "成交额": 20231983.0
    },
    {
        "序号": 3766,
        "symbol": "02361",
        "name": "中康控股",
        "最新价": 5.05,
        "涨跌额": -0.04,
        "涨跌幅": -0.79,
        "今开": 5.05,
        "最高": 5.05,
        "最低": 5.05,
        "昨收": 5.09,
        "成交量": 3000.0,
        "成交额": 15150.0
    },
    {
        "序号": 3767,
        "symbol": "06969",
        "name": "思摩尔国际",
        "最新价": 6.28,
        "涨跌额": -0.05,
        "涨跌幅": -0.79,
        "今开": 6.33,
        "最高": 6.39,
        "最低": 6.22,
        "昨收": 6.33,
        "成交量": 3512000.0,
        "成交额": 22056348.0
    },
    {
        "序号": 3768,
        "symbol": "01698",
        "name": "腾讯音乐-SW",
        "最新价": 31.15,
        "涨跌额": -0.25,
        "涨跌幅": -0.8,
        "今开": 31.15,
        "最高": 31.15,
        "最低": 31.15,
        "昨收": 31.4,
        "成交量": 3000.0,
        "成交额": 93450.0
    },
    {
        "序号": 3769,
        "symbol": "08049",
        "name": "吉林长龙药业",
        "最新价": 1.24,
        "涨跌额": -0.01,
        "涨跌幅": -0.8,
        "今开": 1.25,
        "最高": 1.25,
        "最低": 1.24,
        "昨收": 1.25,
        "成交量": 32000.0,
        "成交额": 39720.0
    },
    {
        "序号": 3770,
        "symbol": "06886",
        "name": "华泰证券",
        "最新价": 9.8,
        "涨跌额": -0.08,
        "涨跌幅": -0.81,
        "今开": 9.91,
        "最高": 9.95,
        "最低": 9.75,
        "昨收": 9.88,
        "成交量": 3370000.0,
        "成交额": 33044605.0
    },
    {
        "序号": 3771,
        "symbol": "01336",
        "name": "新华保险",
        "最新价": 14.68,
        "涨跌额": -0.12,
        "涨跌幅": -0.81,
        "今开": 14.9,
        "最高": 14.9,
        "最低": 14.5,
        "昨收": 14.8,
        "成交量": 5290622.0,
        "成交额": 77700926.0
    },
    {
        "序号": 3772,
        "symbol": "01635",
        "name": "大众公用",
        "最新价": 1.22,
        "涨跌额": -0.01,
        "涨跌幅": -0.81,
        "今开": 1.24,
        "最高": 1.25,
        "最低": 1.21,
        "昨收": 1.23,
        "成交量": 364000.0,
        "成交额": 448770.0
    },
    {
        "序号": 3773,
        "symbol": "00702",
        "name": "中国油气控股",
        "最新价": 0.122,
        "涨跌额": -0.001,
        "涨跌幅": -0.81,
        "今开": 0.123,
        "最高": 0.123,
        "最低": 0.121,
        "昨收": 0.123,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3774,
        "symbol": "00667",
        "name": "中国东方教育",
        "最新价": 2.41,
        "涨跌额": -0.02,
        "涨跌幅": -0.82,
        "今开": 2.45,
        "最高": 2.47,
        "最低": 2.38,
        "昨收": 2.43,
        "成交量": 3054600.0,
        "成交额": 7390988.0
    },
    {
        "序号": 3775,
        "symbol": "03692",
        "name": "翰森制药",
        "最新价": 14.4,
        "涨跌额": -0.12,
        "涨跌幅": -0.83,
        "今开": 14.48,
        "最高": 14.72,
        "最低": 14.08,
        "昨收": 14.52,
        "成交量": 5208000.0,
        "成交额": 74981483.0
    },
    {
        "序号": 3776,
        "symbol": "09088",
        "name": "华夏恒生科技-U",
        "最新价": 0.598,
        "涨跌额": -0.005,
        "涨跌幅": -0.83,
        "今开": 0.598,
        "最高": 0.598,
        "最低": 0.598,
        "昨收": 0.603,
        "成交量": 1000.0,
        "成交额": 598.0
    },
    {
        "序号": 3777,
        "symbol": "00382",
        "name": "中汇集团",
        "最新价": 2.38,
        "涨跌额": -0.02,
        "涨跌幅": -0.83,
        "今开": 2.41,
        "最高": 2.44,
        "最低": 2.38,
        "昨收": 2.4,
        "成交量": 307958.0,
        "成交额": 741906.0
    },
    {
        "序号": 3778,
        "symbol": "00127",
        "name": "华人置业",
        "最新价": 1.19,
        "涨跌额": -0.01,
        "涨跌幅": -0.83,
        "今开": 1.18,
        "最高": 1.23,
        "最低": 1.18,
        "昨收": 1.2,
        "成交量": 436000.0,
        "成交额": 524545.0
    },
    {
        "序号": 3779,
        "symbol": "01119",
        "name": "创梦天地",
        "最新价": 2.35,
        "涨跌额": -0.02,
        "涨跌幅": -0.84,
        "今开": 2.41,
        "最高": 2.42,
        "最低": 2.31,
        "昨收": 2.37,
        "成交量": 4244200.0,
        "成交额": 10026134.0
    },
    {
        "序号": 3780,
        "symbol": "01316",
        "name": "耐世特",
        "最新价": 4.68,
        "涨跌额": -0.04,
        "涨跌幅": -0.85,
        "今开": 4.67,
        "最高": 4.77,
        "最低": 4.54,
        "昨收": 4.72,
        "成交量": 8300000.0,
        "成交额": 38834360.0
    },
    {
        "序号": 3781,
        "symbol": "02235",
        "name": "微泰医疗-B",
        "最新价": 4.66,
        "涨跌额": -0.04,
        "涨跌幅": -0.85,
        "今开": 4.31,
        "最高": 4.7,
        "最低": 4.31,
        "昨收": 4.7,
        "成交量": 135200.0,
        "成交额": 618918.0
    },
    {
        "序号": 3782,
        "symbol": "02107",
        "name": "第一服务控股",
        "最新价": 0.233,
        "涨跌额": -0.002,
        "涨跌幅": -0.85,
        "今开": 0.236,
        "最高": 0.236,
        "最低": 0.233,
        "昨收": 0.235,
        "成交量": 742500.0,
        "成交额": 174147.0
    },
    {
        "序号": 3783,
        "symbol": "02031",
        "name": "澳至尊",
        "最新价": 0.231,
        "涨跌额": -0.002,
        "涨跌幅": -0.86,
        "今开": 0.235,
        "最高": 0.238,
        "最低": 0.231,
        "昨收": 0.233,
        "成交量": 295000.0,
        "成交额": 69945.0
    },
    {
        "序号": 3784,
        "symbol": "01528",
        "name": "红星美凯龙",
        "最新价": 2.31,
        "涨跌额": -0.02,
        "涨跌幅": -0.86,
        "今开": 2.4,
        "最高": 2.4,
        "最低": 2.31,
        "昨收": 2.33,
        "成交量": 162600.0,
        "成交额": 385208.0
    },
    {
        "序号": 3785,
        "symbol": "00878",
        "name": "金朝阳集团",
        "最新价": 5.75,
        "涨跌额": -0.05,
        "涨跌幅": -0.86,
        "今开": 5.8,
        "最高": 5.83,
        "最低": 5.72,
        "昨收": 5.8,
        "成交量": 35500.0,
        "成交额": 204795.0
    },
    {
        "序号": 3786,
        "symbol": "00045",
        "name": "大酒店",
        "最新价": 5.75,
        "涨跌额": -0.05,
        "涨跌幅": -0.86,
        "今开": 5.8,
        "最高": 5.84,
        "最低": 5.75,
        "昨收": 5.8,
        "成交量": 89000.0,
        "成交额": 514145.0
    },
    {
        "序号": 3787,
        "symbol": "07331",
        "name": "华夏一倍看空纳斯达克100",
        "最新价": 3.218,
        "涨跌额": -0.028,
        "涨跌幅": -0.86,
        "今开": 3.214,
        "最高": 3.218,
        "最低": 3.214,
        "昨收": 3.246,
        "成交量": 15300.0,
        "成交额": 49234.0
    },
    {
        "序号": 3788,
        "symbol": "02170",
        "name": "贝康医疗-B",
        "最新价": 2.29,
        "涨跌额": -0.02,
        "涨跌幅": -0.87,
        "今开": 2.25,
        "最高": 2.36,
        "最低": 2.25,
        "昨收": 2.31,
        "成交量": 83500.0,
        "成交额": 193315.0
    },
    {
        "序号": 3789,
        "symbol": "86618",
        "name": "京东健康-R",
        "最新价": 34.3,
        "涨跌额": -0.3,
        "涨跌幅": -0.87,
        "今开": 34.3,
        "最高": 34.3,
        "最低": 34.3,
        "昨收": 34.6,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3790,
        "symbol": "08516",
        "name": "广骏集团控股",
        "最新价": 0.114,
        "涨跌额": -0.001,
        "涨跌幅": -0.87,
        "今开": 0.102,
        "最高": 0.114,
        "最低": 0.102,
        "昨收": 0.115,
        "成交量": 70000.0,
        "成交额": 7860.0
    },
    {
        "序号": 3791,
        "symbol": "00873",
        "name": "世茂服务",
        "最新价": 1.14,
        "涨跌额": -0.01,
        "涨跌幅": -0.87,
        "今开": 1.15,
        "最高": 1.16,
        "最低": 1.13,
        "昨收": 1.15,
        "成交量": 5431000.0,
        "成交额": 6206780.0
    },
    {
        "序号": 3792,
        "symbol": "00028",
        "name": "天安",
        "最新价": 3.42,
        "涨跌额": -0.03,
        "涨跌幅": -0.87,
        "今开": 3.42,
        "最高": 3.42,
        "最低": 3.42,
        "昨收": 3.45,
        "成交量": 82000.0,
        "成交额": 280440.0
    },
    {
        "序号": 3793,
        "symbol": "02416",
        "name": "易点云",
        "最新价": 5.68,
        "涨跌额": -0.05,
        "涨跌幅": -0.87,
        "今开": 5.73,
        "最高": 5.76,
        "最低": 5.47,
        "昨收": 5.73,
        "成交量": 1010500.0,
        "成交额": 5684845.0
    },
    {
        "序号": 3794,
        "symbol": "02835",
        "name": "辉立香港新股",
        "最新价": 9.625,
        "涨跌额": -0.085,
        "涨跌幅": -0.88,
        "今开": 9.61,
        "最高": 9.625,
        "最低": 9.61,
        "昨收": 9.71,
        "成交量": 7000.0,
        "成交额": 67300.0
    },
    {
        "序号": 3795,
        "symbol": "09900",
        "name": "德益控股",
        "最新价": 1.13,
        "涨跌额": -0.01,
        "涨跌幅": -0.88,
        "今开": 1.14,
        "最高": 1.14,
        "最低": 1.13,
        "昨收": 1.14,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3796,
        "symbol": "02020",
        "name": "安踏体育",
        "最新价": 73.4,
        "涨跌额": -0.65,
        "涨跌幅": -0.88,
        "今开": 73.75,
        "最高": 74.2,
        "最低": 72.6,
        "昨收": 74.05,
        "成交量": 5241951.0,
        "成交额": 384958848.0
    },
    {
        "序号": 3797,
        "symbol": "01330",
        "name": "绿色动力环保",
        "最新价": 2.24,
        "涨跌额": -0.02,
        "涨跌幅": -0.88,
        "今开": 2.26,
        "最高": 2.3,
        "最低": 2.24,
        "昨收": 2.26,
        "成交量": 323000.0,
        "成交额": 728470.0
    },
    {
        "序号": 3798,
        "symbol": "00684",
        "name": "亚伦国际",
        "最新价": 1.12,
        "涨跌额": -0.01,
        "涨跌幅": -0.88,
        "今开": 1.1,
        "最高": 1.13,
        "最低": 1.1,
        "昨收": 1.13,
        "成交量": 14000.0,
        "成交额": 15540.0
    },
    {
        "序号": 3799,
        "symbol": "00390",
        "name": "中国中铁",
        "最新价": 3.34,
        "涨跌额": -0.03,
        "涨跌幅": -0.89,
        "今开": 3.37,
        "最高": 3.39,
        "最低": 3.33,
        "昨收": 3.37,
        "成交量": 6707000.0,
        "成交额": 22500471.0
    },
    {
        "序号": 3800,
        "symbol": "01156",
        "name": "CHINANEWENERGY",
        "最新价": 0.111,
        "涨跌额": -0.001,
        "涨跌幅": -0.89,
        "今开": 0.112,
        "最高": 0.112,
        "最低": 0.111,
        "昨收": 0.112,
        "成交量": 96000.0,
        "成交额": 10712.0
    },
    {
        "序号": 3801,
        "symbol": "09857",
        "name": "柠萌影视",
        "最新价": 7.75,
        "涨跌额": -0.07,
        "涨跌幅": -0.9,
        "今开": 7.99,
        "最高": 7.99,
        "最低": 7.67,
        "昨收": 7.82,
        "成交量": 204400.0,
        "成交额": 1600976.0
    },
    {
        "序号": 3802,
        "symbol": "06680",
        "name": "金力永磁",
        "最新价": 9.91,
        "涨跌额": -0.09,
        "涨跌幅": -0.9,
        "今开": 9.9,
        "最高": 10.1,
        "最低": 9.82,
        "昨收": 10.0,
        "成交量": 181320.0,
        "成交额": 1807004.0
    },
    {
        "序号": 3803,
        "symbol": "01196",
        "name": "伟禄集团",
        "最新价": 5.49,
        "涨跌额": -0.05,
        "涨跌幅": -0.9,
        "今开": 5.53,
        "最高": 5.66,
        "最低": 5.49,
        "昨收": 5.54,
        "成交量": 2162000.0,
        "成交额": 12017840.0
    },
    {
        "序号": 3804,
        "symbol": "00552",
        "name": "中国通信服务",
        "最新价": 3.28,
        "涨跌额": -0.03,
        "涨跌幅": -0.91,
        "今开": 3.24,
        "最高": 3.33,
        "最低": 3.23,
        "昨收": 3.31,
        "成交量": 5970000.0,
        "成交额": 19546388.0
    },
    {
        "序号": 3805,
        "symbol": "00338",
        "name": "上海石油化工股份",
        "最新价": 1.09,
        "涨跌额": -0.01,
        "涨跌幅": -0.91,
        "今开": 1.1,
        "最高": 1.12,
        "最低": 1.09,
        "昨收": 1.1,
        "成交量": 3526000.0,
        "成交额": 3893720.0
    },
    {
        "序号": 3806,
        "symbol": "01977",
        "name": "安乐工程",
        "最新价": 1.08,
        "涨跌额": -0.01,
        "涨跌幅": -0.92,
        "今开": 1.09,
        "最高": 1.09,
        "最低": 1.07,
        "昨收": 1.09,
        "成交量": 130000.0,
        "成交额": 140140.0
    },
    {
        "序号": 3807,
        "symbol": "03058",
        "name": "AGX中国创新者",
        "最新价": 29.68,
        "涨跌额": -0.28,
        "涨跌幅": -0.93,
        "今开": 29.6,
        "最高": 29.68,
        "最低": 29.6,
        "昨收": 29.96,
        "成交量": 300.0,
        "成交额": 8880.0
    },
    {
        "序号": 3808,
        "symbol": "00826",
        "name": "天工国际",
        "最新价": 2.12,
        "涨跌额": -0.02,
        "涨跌幅": -0.93,
        "今开": 2.15,
        "最高": 2.15,
        "最低": 2.06,
        "昨收": 2.14,
        "成交量": 4496000.0,
        "成交额": 9421860.0
    },
    {
        "序号": 3809,
        "symbol": "00511",
        "name": "电视广播",
        "最新价": 3.18,
        "涨跌额": -0.03,
        "涨跌幅": -0.93,
        "今开": 3.19,
        "最高": 3.28,
        "最低": 3.16,
        "昨收": 3.21,
        "成交量": 451690.0,
        "成交额": 1442181.0
    },
    {
        "序号": 3810,
        "symbol": "00063",
        "name": "中亚烯谷集团",
        "最新价": 0.106,
        "涨跌额": -0.001,
        "涨跌幅": -0.93,
        "今开": 0.096,
        "最高": 0.106,
        "最低": 0.096,
        "昨收": 0.107,
        "成交量": 102000.0,
        "成交额": 9811.0
    },
    {
        "序号": 3811,
        "symbol": "02666",
        "name": "环球医疗",
        "最新价": 4.21,
        "涨跌额": -0.04,
        "涨跌幅": -0.94,
        "今开": 4.24,
        "最高": 4.26,
        "最低": 4.16,
        "昨收": 4.25,
        "成交量": 4904500.0,
        "成交额": 20720345.0
    },
    {
        "序号": 3812,
        "symbol": "00548",
        "name": "深圳高速公路股份",
        "最新价": 6.29,
        "涨跌额": -0.06,
        "涨跌幅": -0.94,
        "今开": 6.3,
        "最高": 6.34,
        "最低": 6.26,
        "昨收": 6.35,
        "成交量": 276000.0,
        "成交额": 1736820.0
    },
    {
        "序号": 3813,
        "symbol": "01968",
        "name": "兴纺控股",
        "最新价": 0.104,
        "涨跌额": -0.001,
        "涨跌幅": -0.95,
        "今开": 0.105,
        "最高": 0.105,
        "最低": 0.104,
        "昨收": 0.105,
        "成交量": 80000.0,
        "成交额": 8400.0
    },
    {
        "序号": 3814,
        "symbol": "02822",
        "name": "南方A50",
        "最新价": 11.42,
        "涨跌额": -0.11,
        "涨跌幅": -0.95,
        "今开": 11.54,
        "最高": 11.57,
        "最低": 11.42,
        "昨收": 11.53,
        "成交量": 6595872.0,
        "成交额": 75953466.0
    },
    {
        "序号": 3815,
        "symbol": "00388",
        "name": "香港交易所",
        "最新价": 248.4,
        "涨跌额": -2.4,
        "涨跌幅": -0.96,
        "今开": 250.2,
        "最高": 252.6,
        "最低": 246.8,
        "昨收": 250.8,
        "成交量": 4947948.0,
        "成交额": 1232006576.0
    },
    {
        "序号": 3816,
        "symbol": "01979",
        "name": "天宝集团",
        "最新价": 1.03,
        "涨跌额": -0.01,
        "涨跌幅": -0.96,
        "今开": 1.03,
        "最高": 1.03,
        "最低": 1.03,
        "昨收": 1.04,
        "成交量": 32000.0,
        "成交额": 32960.0
    },
    {
        "序号": 3817,
        "symbol": "00861",
        "name": "神州控股",
        "最新价": 2.06,
        "涨跌额": -0.02,
        "涨跌幅": -0.96,
        "今开": 2.06,
        "最高": 2.09,
        "最低": 2.03,
        "昨收": 2.08,
        "成交量": 2362140.0,
        "成交额": 4867930.0
    },
    {
        "序号": 3818,
        "symbol": "00981",
        "name": "中芯国际",
        "最新价": 20.55,
        "涨跌额": -0.2,
        "涨跌幅": -0.96,
        "今开": 20.95,
        "最高": 21.2,
        "最低": 20.55,
        "昨收": 20.75,
        "成交量": 16742500.0,
        "成交额": 348348880.0
    },
    {
        "序号": 3819,
        "symbol": "03993",
        "name": "洛阳钼业",
        "最新价": 4.06,
        "涨跌额": -0.04,
        "涨跌幅": -0.98,
        "今开": 4.1,
        "最高": 4.1,
        "最低": 4.02,
        "昨收": 4.1,
        "成交量": 11107376.0,
        "成交额": 44987628.0
    },
    {
        "序号": 3820,
        "symbol": "02161",
        "name": "健倍苗苗",
        "最新价": 1.01,
        "涨跌额": -0.01,
        "涨跌幅": -0.98,
        "今开": 1.01,
        "最高": 1.01,
        "最低": 1.0,
        "昨收": 1.02,
        "成交量": 54885.0,
        "成交额": 54989.0
    },
    {
        "序号": 3821,
        "symbol": "00517",
        "name": "中远海运国际",
        "最新价": 3.01,
        "涨跌额": -0.03,
        "涨跌幅": -0.99,
        "今开": 3.01,
        "最高": 3.04,
        "最低": 3.01,
        "昨收": 3.04,
        "成交量": 310000.0,
        "成交额": 938360.0
    },
    {
        "序号": 3822,
        "symbol": "02016",
        "name": "浙商银行",
        "最新价": 2.0,
        "涨跌额": -0.02,
        "涨跌幅": -0.99,
        "今开": 2.01,
        "最高": 2.03,
        "最低": 2.0,
        "昨收": 2.02,
        "成交量": 5722300.0,
        "成交额": 11498764.0
    },
    {
        "序号": 3823,
        "symbol": "01883",
        "name": "中信国际电讯",
        "最新价": 3.0,
        "涨跌额": -0.03,
        "涨跌幅": -0.99,
        "今开": 3.03,
        "最高": 3.03,
        "最低": 2.99,
        "昨收": 3.03,
        "成交量": 1973000.0,
        "成交额": 5944940.0
    },
    {
        "序号": 3824,
        "symbol": "00777",
        "name": "网龙",
        "最新价": 13.9,
        "涨跌额": -0.14,
        "涨跌幅": -1.0,
        "今开": 14.16,
        "最高": 14.16,
        "最低": 13.5,
        "昨收": 14.04,
        "成交量": 4217114.0,
        "成交额": 58234082.0
    },
    {
        "序号": 3825,
        "symbol": "07226",
        "name": "南方两倍做多恒生科技",
        "最新价": 3.768,
        "涨跌额": -0.038,
        "涨跌幅": -1.0,
        "今开": 3.822,
        "最高": 3.856,
        "最低": 3.702,
        "昨收": 3.806,
        "成交量": 190605960.0,
        "成交额": 719014976.0
    },
    {
        "序号": 3826,
        "symbol": "03989",
        "name": "首创环境",
        "最新价": 0.099,
        "涨跌额": -0.001,
        "涨跌幅": -1.0,
        "今开": 0.098,
        "最高": 0.099,
        "最低": 0.098,
        "昨收": 0.1,
        "成交量": 116000.0,
        "成交额": 11378.0
    },
    {
        "序号": 3827,
        "symbol": "00039",
        "name": "中国北大荒",
        "最新价": 0.099,
        "涨跌额": -0.001,
        "涨跌幅": -1.0,
        "今开": 0.1,
        "最高": 0.1,
        "最低": 0.098,
        "昨收": 0.1,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3828,
        "symbol": "09928",
        "name": "时代邻里",
        "最新价": 0.49,
        "涨跌额": -0.005,
        "涨跌幅": -1.01,
        "今开": 0.48,
        "最高": 0.495,
        "最低": 0.48,
        "昨收": 0.495,
        "成交量": 169000.0,
        "成交额": 81275.0
    },
    {
        "序号": 3829,
        "symbol": "01447",
        "name": "新福港",
        "最新价": 0.49,
        "涨跌额": -0.005,
        "涨跌幅": -1.01,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.49,
        "昨收": 0.495,
        "成交量": 28000.0,
        "成交额": 13860.0
    },
    {
        "序号": 3830,
        "symbol": "03669",
        "name": "永达汽车",
        "最新价": 2.91,
        "涨跌额": -0.03,
        "涨跌幅": -1.02,
        "今开": 2.91,
        "最高": 2.96,
        "最低": 2.91,
        "昨收": 2.94,
        "成交量": 859290.0,
        "成交额": 2519717.0
    },
    {
        "序号": 3831,
        "symbol": "01851",
        "name": "银杏教育",
        "最新价": 0.97,
        "涨跌额": -0.01,
        "涨跌幅": -1.02,
        "今开": 0.98,
        "最高": 0.98,
        "最低": 0.97,
        "昨收": 0.98,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3832,
        "symbol": "01596",
        "name": "翼辰实业",
        "最新价": 3.87,
        "涨跌额": -0.04,
        "涨跌幅": -1.02,
        "今开": 3.96,
        "最高": 4.1,
        "最低": 3.81,
        "昨收": 3.91,
        "成交量": 105000.0,
        "成交额": 406730.0
    },
    {
        "序号": 3833,
        "symbol": "00570",
        "name": "中国中药",
        "最新价": 3.86,
        "涨跌额": -0.04,
        "涨跌幅": -1.03,
        "今开": 3.89,
        "最高": 3.94,
        "最低": 3.84,
        "昨收": 3.9,
        "成交量": 13512000.0,
        "成交额": 52239200.0
    },
    {
        "序号": 3834,
        "symbol": "02994",
        "name": "丰盛控股",
        "最新价": 0.48,
        "涨跌额": -0.005,
        "涨跌幅": -1.03,
        "今开": 0.485,
        "最高": 0.485,
        "最低": 0.425,
        "昨收": 0.485,
        "成交量": 252460.0,
        "成交额": 118246.0
    },
    {
        "序号": 3835,
        "symbol": "02271",
        "name": "众安智慧生活",
        "最新价": 0.96,
        "涨跌额": -0.01,
        "涨跌幅": -1.03,
        "今开": 0.96,
        "最高": 0.96,
        "最低": 0.96,
        "昨收": 0.97,
        "成交量": 2000.0,
        "成交额": 1920.0
    },
    {
        "序号": 3836,
        "symbol": "01211",
        "name": "比亚迪股份",
        "最新价": 210.8,
        "涨跌额": -2.2,
        "涨跌幅": -1.03,
        "今开": 214.2,
        "最高": 214.8,
        "最低": 209.0,
        "昨收": 213.0,
        "成交量": 6827410.0,
        "成交额": 1441836512.0
    },
    {
        "序号": 3837,
        "symbol": "07204",
        "name": "XL二南方美油气",
        "最新价": 5.725,
        "涨跌额": -0.06,
        "涨跌幅": -1.04,
        "今开": 5.785,
        "最高": 5.785,
        "最低": 5.655,
        "昨收": 5.785,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3838,
        "symbol": "01393",
        "name": "恒鼎实业",
        "最新价": 0.095,
        "涨跌额": -0.001,
        "涨跌幅": -1.04,
        "今开": 0.093,
        "最高": 0.097,
        "最低": 0.093,
        "昨收": 0.096,
        "成交量": 95000.0,
        "成交额": 9026.0
    },
    {
        "序号": 3839,
        "symbol": "00677",
        "name": "金源发展国际实业",
        "最新价": 0.475,
        "涨跌额": -0.005,
        "涨跌幅": -1.04,
        "今开": 0.48,
        "最高": 0.485,
        "最低": 0.475,
        "昨收": 0.48,
        "成交量": 596000.0,
        "成交额": 285090.0
    },
    {
        "序号": 3840,
        "symbol": "03660",
        "name": "奇富科技-S",
        "最新价": 56.9,
        "涨跌额": -0.6,
        "涨跌幅": -1.04,
        "今开": 57.5,
        "最高": 57.5,
        "最低": 56.0,
        "昨收": 57.5,
        "成交量": 1500.0,
        "成交额": 85475.0
    },
    {
        "序号": 3841,
        "symbol": "06616",
        "name": "环球新材国际",
        "最新价": 3.78,
        "涨跌额": -0.04,
        "涨跌幅": -1.05,
        "今开": 3.81,
        "最高": 3.82,
        "最低": 3.67,
        "昨收": 3.82,
        "成交量": 2233000.0,
        "成交额": 8336360.0
    },
    {
        "序号": 3842,
        "symbol": "01244",
        "name": "3D MEDICINES",
        "最新价": 8.5,
        "涨跌额": -0.09,
        "涨跌幅": -1.05,
        "今开": 8.68,
        "最高": 8.7,
        "最低": 8.4,
        "昨收": 8.59,
        "成交量": 757500.0,
        "成交额": 6457345.0
    },
    {
        "序号": 3843,
        "symbol": "02367",
        "name": "巨子生物",
        "最新价": 32.9,
        "涨跌额": -0.35,
        "涨跌幅": -1.05,
        "今开": 32.75,
        "最高": 33.7,
        "最低": 32.7,
        "昨收": 33.25,
        "成交量": 1911800.0,
        "成交额": 63051468.0
    },
    {
        "序号": 3844,
        "symbol": "01549",
        "name": "永丰集团控股",
        "最新价": 0.094,
        "涨跌额": -0.001,
        "涨跌幅": -1.05,
        "今开": 0.095,
        "最高": 0.095,
        "最低": 0.093,
        "昨收": 0.095,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3845,
        "symbol": "01029",
        "name": "铁货",
        "最新价": 0.093,
        "涨跌额": -0.001,
        "涨跌幅": -1.06,
        "今开": 0.093,
        "最高": 0.093,
        "最低": 0.092,
        "昨收": 0.094,
        "成交量": 2100000.0,
        "成交额": 195260.0
    },
    {
        "序号": 3846,
        "symbol": "00050",
        "name": "香港小轮(集团)",
        "最新价": 4.63,
        "涨跌额": -0.05,
        "涨跌幅": -1.07,
        "今开": 4.68,
        "最高": 4.68,
        "最低": 4.58,
        "昨收": 4.68,
        "成交量": 57000.0,
        "成交额": 262730.0
    },
    {
        "序号": 3847,
        "symbol": "00014",
        "name": "希慎兴业",
        "最新价": 14.76,
        "涨跌额": -0.16,
        "涨跌幅": -1.07,
        "今开": 14.92,
        "最高": 15.18,
        "最低": 14.74,
        "昨收": 14.92,
        "成交量": 1162000.0,
        "成交额": 17308875.0
    },
    {
        "序号": 3848,
        "symbol": "02806",
        "name": "GX中国消费",
        "最新价": 40.46,
        "涨跌额": -0.44,
        "涨跌幅": -1.08,
        "今开": 40.9,
        "最高": 40.9,
        "最低": 40.46,
        "昨收": 40.9,
        "成交量": 67000.0,
        "成交额": 2717577.0
    },
    {
        "序号": 3849,
        "symbol": "00018",
        "name": "东方企控集团",
        "最新价": 0.455,
        "涨跌额": -0.005,
        "涨跌幅": -1.09,
        "今开": 0.46,
        "最高": 0.46,
        "最低": 0.44,
        "昨收": 0.46,
        "成交量": 8000.0,
        "成交额": 3620.0
    },
    {
        "序号": 3850,
        "symbol": "01044",
        "name": "恒安国际",
        "最新价": 27.25,
        "涨跌额": -0.3,
        "涨跌幅": -1.09,
        "今开": 27.55,
        "最高": 27.9,
        "最低": 27.1,
        "昨收": 27.55,
        "成交量": 1183350.0,
        "成交额": 32451705.0
    },
    {
        "序号": 3851,
        "symbol": "07288",
        "name": "南方两倍看多国指",
        "最新价": 1.632,
        "涨跌额": -0.018,
        "涨跌幅": -1.09,
        "今开": 1.654,
        "最高": 1.666,
        "最低": 1.616,
        "昨收": 1.65,
        "成交量": 3308600.0,
        "成交额": 5425647.0
    },
    {
        "序号": 3852,
        "symbol": "08275",
        "name": "中国新消费集团",
        "最新价": 0.181,
        "涨跌额": -0.002,
        "涨跌幅": -1.09,
        "今开": 0.186,
        "最高": 0.193,
        "最低": 0.18,
        "昨收": 0.183,
        "成交量": 5320000.0,
        "成交额": 965120.0
    },
    {
        "序号": 3853,
        "symbol": "03382",
        "name": "天津港发展",
        "最新价": 0.45,
        "涨跌额": -0.005,
        "涨跌幅": -1.1,
        "今开": 0.455,
        "最高": 0.46,
        "最低": 0.45,
        "昨收": 0.455,
        "成交量": 10428000.0,
        "成交额": 4746690.0
    },
    {
        "序号": 3854,
        "symbol": "00506",
        "name": "中国食品",
        "最新价": 2.68,
        "涨跌额": -0.03,
        "涨跌幅": -1.11,
        "今开": 2.71,
        "最高": 2.78,
        "最低": 2.66,
        "昨收": 2.71,
        "成交量": 2654000.0,
        "成交额": 7187920.0
    },
    {
        "序号": 3855,
        "symbol": "08379",
        "name": "懒猪科技",
        "最新价": 0.089,
        "涨跌额": -0.001,
        "涨跌幅": -1.11,
        "今开": 0.095,
        "最高": 0.095,
        "最低": 0.089,
        "昨收": 0.09,
        "成交量": 170000.0,
        "成交额": 15930.0
    },
    {
        "序号": 3856,
        "symbol": "00376",
        "name": "云锋金融",
        "最新价": 0.89,
        "涨跌额": -0.01,
        "涨跌幅": -1.11,
        "今开": 0.88,
        "最高": 0.89,
        "最低": 0.85,
        "昨收": 0.9,
        "成交量": 12000.0,
        "成交额": 10520.0
    },
    {
        "序号": 3857,
        "symbol": "01681",
        "name": "康臣药业",
        "最新价": 4.42,
        "涨跌额": -0.05,
        "涨跌幅": -1.12,
        "今开": 4.43,
        "最高": 4.51,
        "最低": 4.4,
        "昨收": 4.47,
        "成交量": 766000.0,
        "成交额": 3398920.0
    },
    {
        "序号": 3858,
        "symbol": "03347",
        "name": "泰格医药",
        "最新价": 35.35,
        "涨跌额": -0.4,
        "涨跌幅": -1.12,
        "今开": 36.4,
        "最高": 36.6,
        "最低": 35.25,
        "昨收": 35.75,
        "成交量": 870711.0,
        "成交额": 31081259.0
    },
    {
        "序号": 3859,
        "symbol": "00520",
        "name": "呷哺呷哺",
        "最新价": 2.65,
        "涨跌额": -0.03,
        "涨跌幅": -1.12,
        "今开": 2.68,
        "最高": 2.71,
        "最低": 2.62,
        "昨收": 2.68,
        "成交量": 3717000.0,
        "成交额": 9867175.0
    },
    {
        "序号": 3860,
        "symbol": "09956",
        "name": "安能物流",
        "最新价": 6.16,
        "涨跌额": -0.07,
        "涨跌幅": -1.12,
        "今开": 6.26,
        "最高": 6.26,
        "最低": 6.1,
        "昨收": 6.23,
        "成交量": 1803500.0,
        "成交额": 11102450.0
    },
    {
        "序号": 3861,
        "symbol": "03938",
        "name": "LFG投资控股",
        "最新价": 0.174,
        "涨跌额": -0.002,
        "涨跌幅": -1.14,
        "今开": 0.185,
        "最高": 0.187,
        "最低": 0.162,
        "昨收": 0.176,
        "成交量": 194000.0,
        "成交额": 33246.0
    },
    {
        "序号": 3862,
        "symbol": "00536",
        "name": "贸易通",
        "最新价": 0.87,
        "涨跌额": -0.01,
        "涨跌幅": -1.14,
        "今开": 0.87,
        "最高": 0.87,
        "最低": 0.86,
        "昨收": 0.88,
        "成交量": 58000.0,
        "成交额": 50440.0
    },
    {
        "序号": 3863,
        "symbol": "00483",
        "name": "包浩斯国际",
        "最新价": 0.435,
        "涨跌额": -0.005,
        "涨跌幅": -1.14,
        "今开": 0.445,
        "最高": 0.445,
        "最低": 0.435,
        "昨收": 0.44,
        "成交量": 72000.0,
        "成交额": 31640.0
    },
    {
        "序号": 3864,
        "symbol": "00297",
        "name": "中化化肥",
        "最新价": 0.87,
        "涨跌额": -0.01,
        "涨跌幅": -1.14,
        "今开": 0.86,
        "最高": 0.88,
        "最低": 0.86,
        "昨收": 0.88,
        "成交量": 1166000.0,
        "成交额": 1010780.0
    },
    {
        "序号": 3865,
        "symbol": "02265",
        "name": "鸿承环保科技",
        "最新价": 0.86,
        "涨跌额": -0.01,
        "涨跌幅": -1.15,
        "今开": 0.87,
        "最高": 0.88,
        "最低": 0.85,
        "昨收": 0.87,
        "成交量": 110000.0,
        "成交额": 94300.0
    },
    {
        "序号": 3866,
        "symbol": "01112",
        "name": "H&H国际控股",
        "最新价": 10.3,
        "涨跌额": -0.12,
        "涨跌幅": -1.15,
        "今开": 10.36,
        "最高": 10.66,
        "最低": 10.24,
        "昨收": 10.42,
        "成交量": 925033.0,
        "成交额": 9593546.0
    },
    {
        "序号": 3867,
        "symbol": "00392",
        "name": "北京控股",
        "最新价": 25.65,
        "涨跌额": -0.3,
        "涨跌幅": -1.16,
        "今开": 26.0,
        "最高": 26.2,
        "最低": 25.5,
        "昨收": 25.95,
        "成交量": 957100.0,
        "成交额": 24662200.0
    },
    {
        "序号": 3868,
        "symbol": "06865",
        "name": "福莱特玻璃",
        "最新价": 11.96,
        "涨跌额": -0.14,
        "涨跌幅": -1.16,
        "今开": 12.6,
        "最高": 12.6,
        "最低": 11.76,
        "昨收": 12.1,
        "成交量": 5687356.0,
        "成交额": 67843235.0
    },
    {
        "序号": 3869,
        "symbol": "01906",
        "name": "博尼控股",
        "最新价": 0.42,
        "涨跌额": -0.005,
        "涨跌幅": -1.18,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.42,
        "昨收": 0.425,
        "成交量": 1620000.0,
        "成交额": 690400.0
    },
    {
        "序号": 3870,
        "symbol": "00832",
        "name": "建业地产",
        "最新价": 0.084,
        "涨跌额": -0.001,
        "涨跌幅": -1.18,
        "今开": 0.09,
        "最高": 0.09,
        "最低": 0.082,
        "昨收": 0.085,
        "成交量": 153000.0,
        "成交额": 13479.0
    },
    {
        "序号": 3871,
        "symbol": "00021",
        "name": "大中华控股",
        "最新价": 0.084,
        "涨跌额": -0.001,
        "涨跌幅": -1.18,
        "今开": 0.085,
        "最高": 0.085,
        "最低": 0.083,
        "昨收": 0.085,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3872,
        "symbol": "81211",
        "name": "比亚迪股份-R",
        "最新价": 193.1,
        "涨跌额": -2.3,
        "涨跌幅": -1.18,
        "今开": 196.5,
        "最高": 196.5,
        "最低": 192.4,
        "昨收": 195.4,
        "成交量": 8500.0,
        "成交额": 1654000.0
    },
    {
        "序号": 3873,
        "symbol": "00322",
        "name": "康师傅控股",
        "最新价": 9.21,
        "涨跌额": -0.11,
        "涨跌幅": -1.18,
        "今开": 9.66,
        "最高": 9.66,
        "最低": 9.19,
        "昨收": 9.32,
        "成交量": 2316000.0,
        "成交额": 21540624.0
    },
    {
        "序号": 3874,
        "symbol": "81810",
        "name": "小米集团-WR",
        "最新价": 13.28,
        "涨跌额": -0.16,
        "涨跌幅": -1.19,
        "今开": 13.42,
        "最高": 13.46,
        "最低": 13.28,
        "昨收": 13.44,
        "成交量": 135600.0,
        "成交额": 1814000.0
    },
    {
        "序号": 3875,
        "symbol": "01949",
        "name": "PLATT NERA",
        "最新价": 0.415,
        "涨跌额": -0.005,
        "涨跌幅": -1.19,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.415,
        "昨收": 0.42,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3876,
        "symbol": "00123",
        "name": "越秀地产",
        "最新价": 6.64,
        "涨跌额": -0.08,
        "涨跌幅": -1.19,
        "今开": 6.8,
        "最高": 6.8,
        "最低": 6.58,
        "昨收": 6.72,
        "成交量": 4213607.0,
        "成交额": 27992347.0
    },
    {
        "序号": 3877,
        "symbol": "00966",
        "name": "中国太平",
        "最新价": 6.6,
        "涨跌额": -0.08,
        "涨跌幅": -1.2,
        "今开": 6.66,
        "最高": 6.71,
        "最低": 6.59,
        "昨收": 6.68,
        "成交量": 1238466.0,
        "成交额": 8214538.0
    },
    {
        "序号": 3878,
        "symbol": "01901",
        "name": "飞扬集团",
        "最新价": 0.82,
        "涨跌额": -0.01,
        "涨跌幅": -1.2,
        "今开": 0.83,
        "最高": 0.83,
        "最低": 0.8,
        "昨收": 0.83,
        "成交量": 498000.0,
        "成交额": 402280.0
    },
    {
        "序号": 3879,
        "symbol": "01376",
        "name": "RAFFLESINTERIOR",
        "最新价": 0.41,
        "涨跌额": -0.005,
        "涨跌幅": -1.2,
        "今开": 0.44,
        "最高": 0.44,
        "最低": 0.405,
        "昨收": 0.415,
        "成交量": 696000.0,
        "成交额": 289040.0
    },
    {
        "序号": 3880,
        "symbol": "02100",
        "name": "百奥家庭互动",
        "最新价": 0.245,
        "涨跌额": -0.003,
        "涨跌幅": -1.21,
        "今开": 0.248,
        "最高": 0.248,
        "最低": 0.24,
        "昨收": 0.248,
        "成交量": 176000.0,
        "成交额": 42644.0
    },
    {
        "序号": 3881,
        "symbol": "07345",
        "name": "南方一倍看空原油",
        "最新价": 4.234,
        "涨跌额": -0.052,
        "涨跌幅": -1.21,
        "今开": 4.238,
        "最高": 4.244,
        "最低": 4.234,
        "昨收": 4.286,
        "成交量": 6000.0,
        "成交额": 25446.0
    },
    {
        "序号": 3882,
        "symbol": "03331",
        "name": "维达国际",
        "最新价": 19.54,
        "涨跌额": -0.24,
        "涨跌幅": -1.21,
        "今开": 19.8,
        "最高": 19.9,
        "最低": 19.5,
        "昨收": 19.78,
        "成交量": 1513184.0,
        "成交额": 29752441.0
    },
    {
        "序号": 3883,
        "symbol": "01673",
        "name": "华章科技",
        "最新价": 0.405,
        "涨跌额": -0.005,
        "涨跌幅": -1.22,
        "今开": 0.42,
        "最高": 0.42,
        "最低": 0.405,
        "昨收": 0.41,
        "成交量": 34000.0,
        "成交额": 14070.0
    },
    {
        "序号": 3884,
        "symbol": "06639",
        "name": "瑞尔集团",
        "最新价": 7.22,
        "涨跌额": -0.09,
        "涨跌幅": -1.23,
        "今开": 7.3,
        "最高": 7.44,
        "最低": 7.08,
        "昨收": 7.31,
        "成交量": 756000.0,
        "成交额": 5407270.0
    },
    {
        "序号": 3885,
        "symbol": "02455",
        "name": "润华服务",
        "最新价": 0.8,
        "涨跌额": -0.01,
        "涨跌幅": -1.23,
        "今开": 0.82,
        "最高": 0.82,
        "最低": 0.75,
        "昨收": 0.81,
        "成交量": 170000.0,
        "成交额": 133240.0
    },
    {
        "序号": 3886,
        "symbol": "01283",
        "name": "高陞集团控股",
        "最新价": 0.8,
        "涨跌额": -0.01,
        "涨跌幅": -1.23,
        "今开": 0.81,
        "最高": 0.82,
        "最低": 0.77,
        "昨收": 0.81,
        "成交量": 655000.0,
        "成交额": 522300.0
    },
    {
        "序号": 3887,
        "symbol": "03069",
        "name": "华夏恒生生科",
        "最新价": 9.995,
        "涨跌额": -0.125,
        "涨跌幅": -1.24,
        "今开": 10.18,
        "最高": 10.18,
        "最低": 9.935,
        "昨收": 10.12,
        "成交量": 22100.0,
        "成交额": 222217.0
    },
    {
        "序号": 3888,
        "symbol": "02727",
        "name": "上海电气",
        "最新价": 1.59,
        "涨跌额": -0.02,
        "涨跌幅": -1.24,
        "今开": 1.61,
        "最高": 1.62,
        "最低": 1.59,
        "昨收": 1.61,
        "成交量": 2050000.0,
        "成交额": 3297040.0
    },
    {
        "序号": 3889,
        "symbol": "01765",
        "name": "希望教育",
        "最新价": 0.395,
        "涨跌额": -0.005,
        "涨跌幅": -1.25,
        "今开": 0.4,
        "最高": 0.405,
        "最低": 0.395,
        "昨收": 0.4,
        "成交量": 10072000.0,
        "成交额": 4011740.0
    },
    {
        "序号": 3890,
        "symbol": "09676",
        "name": "十月稻田",
        "最新价": 27.6,
        "涨跌额": -0.35,
        "涨跌幅": -1.25,
        "今开": 28.15,
        "最高": 28.15,
        "最低": 27.25,
        "昨收": 27.95,
        "成交量": 138300.0,
        "成交额": 3820020.0
    },
    {
        "序号": 3891,
        "symbol": "00384",
        "name": "中国燃气",
        "最新价": 7.09,
        "涨跌额": -0.09,
        "涨跌幅": -1.25,
        "今开": 7.18,
        "最高": 7.22,
        "最低": 7.01,
        "昨收": 7.18,
        "成交量": 6494000.0,
        "成交额": 46142293.0
    },
    {
        "序号": 3892,
        "symbol": "08137",
        "name": "洪桥集团",
        "最新价": 0.39,
        "涨跌额": -0.005,
        "涨跌幅": -1.27,
        "今开": 0.395,
        "最高": 0.4,
        "最低": 0.39,
        "昨收": 0.395,
        "成交量": 104000.0,
        "成交额": 41060.0
    },
    {
        "序号": 3893,
        "symbol": "00613",
        "name": "梧桐国际",
        "最新价": 0.39,
        "涨跌额": -0.005,
        "涨跌幅": -1.27,
        "今开": 0.395,
        "最高": 0.395,
        "最低": 0.39,
        "昨收": 0.395,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3894,
        "symbol": "01099",
        "name": "国药控股",
        "最新价": 18.7,
        "涨跌额": -0.24,
        "涨跌幅": -1.27,
        "今开": 19.02,
        "最高": 19.02,
        "最低": 18.48,
        "昨收": 18.94,
        "成交量": 7415380.0,
        "成交额": 138550075.0
    },
    {
        "序号": 3895,
        "symbol": "01799",
        "name": "新特能源",
        "最新价": 9.33,
        "涨跌额": -0.12,
        "涨跌幅": -1.27,
        "今开": 9.42,
        "最高": 9.57,
        "最低": 9.31,
        "昨收": 9.45,
        "成交量": 1218000.0,
        "成交额": 11466804.0
    },
    {
        "序号": 3896,
        "symbol": "01905",
        "name": "海通恒信",
        "最新价": 0.77,
        "涨跌额": -0.01,
        "涨跌幅": -1.28,
        "今开": 0.76,
        "最高": 0.81,
        "最低": 0.76,
        "昨收": 0.78,
        "成交量": 3044000.0,
        "成交额": 2383760.0
    },
    {
        "序号": 3897,
        "symbol": "01358",
        "name": "普华和顺",
        "最新价": 0.77,
        "涨跌额": -0.01,
        "涨跌幅": -1.28,
        "今开": 0.78,
        "最高": 0.78,
        "最低": 0.77,
        "昨收": 0.78,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3898,
        "symbol": "01284",
        "name": "新传企划",
        "最新价": 0.385,
        "涨跌额": -0.005,
        "涨跌幅": -1.28,
        "今开": 0.37,
        "最高": 0.385,
        "最低": 0.37,
        "昨收": 0.39,
        "成交量": 25000.0,
        "成交额": 9425.0
    },
    {
        "序号": 3899,
        "symbol": "02628",
        "name": "中国人寿",
        "最新价": 9.97,
        "涨跌额": -0.13,
        "涨跌幅": -1.29,
        "今开": 10.16,
        "最高": 10.18,
        "最低": 9.92,
        "昨收": 10.1,
        "成交量": 21799212.0,
        "成交额": 218135050.0
    },
    {
        "序号": 3900,
        "symbol": "08195",
        "name": "传承教育集团",
        "最新价": 1.53,
        "涨跌额": -0.02,
        "涨跌幅": -1.29,
        "今开": 1.53,
        "最高": 1.54,
        "最低": 1.5,
        "昨收": 1.55,
        "成交量": 448000.0,
        "成交额": 679500.0
    },
    {
        "序号": 3901,
        "symbol": "00863",
        "name": "BC科技集团",
        "最新价": 9.93,
        "涨跌额": -0.13,
        "涨跌幅": -1.29,
        "今开": 9.9,
        "最高": 10.32,
        "最低": 9.7,
        "昨收": 10.06,
        "成交量": 1384500.0,
        "成交额": 13814755.0
    },
    {
        "序号": 3902,
        "symbol": "01057",
        "name": "浙江世宝",
        "最新价": 2.29,
        "涨跌额": -0.03,
        "涨跌幅": -1.29,
        "今开": 2.32,
        "最高": 2.34,
        "最低": 2.27,
        "昨收": 2.32,
        "成交量": 3066000.0,
        "成交额": 7037420.0
    },
    {
        "序号": 3903,
        "symbol": "06606",
        "name": "诺辉健康",
        "最新价": 22.85,
        "涨跌额": -0.3,
        "涨跌幅": -1.3,
        "今开": 23.2,
        "最高": 23.2,
        "最低": 21.85,
        "昨收": 23.15,
        "成交量": 1954000.0,
        "成交额": 44072645.0
    },
    {
        "序号": 3904,
        "symbol": "01967",
        "name": "信恳智能",
        "最新价": 0.38,
        "涨跌额": -0.005,
        "涨跌幅": -1.3,
        "今开": 0.405,
        "最高": 0.405,
        "最低": 0.37,
        "昨收": 0.385,
        "成交量": 56800.0,
        "成交额": 21578.0
    },
    {
        "序号": 3905,
        "symbol": "00994",
        "name": "中天顺联",
        "最新价": 0.38,
        "涨跌额": -0.005,
        "涨跌幅": -1.3,
        "今开": 0.385,
        "最高": 0.385,
        "最低": 0.38,
        "昨收": 0.385,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3906,
        "symbol": "00896",
        "name": "兴胜创建",
        "最新价": 0.76,
        "涨跌额": -0.01,
        "涨跌幅": -1.3,
        "今开": 0.76,
        "最高": 0.76,
        "最低": 0.76,
        "昨收": 0.77,
        "成交量": 22000.0,
        "成交额": 16720.0
    },
    {
        "序号": 3907,
        "symbol": "02101",
        "name": "福禄控股",
        "最新价": 2.27,
        "涨跌额": -0.03,
        "涨跌幅": -1.3,
        "今开": 2.3,
        "最高": 2.3,
        "最低": 2.27,
        "昨收": 2.3,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3908,
        "symbol": "08406",
        "name": "中国口腔产业",
        "最新价": 0.151,
        "涨跌额": -0.002,
        "涨跌幅": -1.31,
        "今开": 0.15,
        "最高": 0.152,
        "最低": 0.149,
        "昨收": 0.153,
        "成交量": 1610000.0,
        "成交额": 241470.0
    },
    {
        "序号": 3909,
        "symbol": "02115",
        "name": "捷芯隆",
        "最新价": 0.226,
        "涨跌额": -0.003,
        "涨跌幅": -1.31,
        "今开": 0.225,
        "最高": 0.226,
        "最低": 0.225,
        "昨收": 0.229,
        "成交量": 30000.0,
        "成交额": 6760.0
    },
    {
        "序号": 3910,
        "symbol": "01916",
        "name": "江西银行",
        "最新价": 0.75,
        "涨跌额": -0.01,
        "涨跌幅": -1.32,
        "今开": 0.77,
        "最高": 0.77,
        "最低": 0.75,
        "昨收": 0.76,
        "成交量": 18000.0,
        "成交额": 13740.0
    },
    {
        "序号": 3911,
        "symbol": "00897",
        "name": "位元堂",
        "最新价": 0.375,
        "涨跌额": -0.005,
        "涨跌幅": -1.32,
        "今开": 0.375,
        "最高": 0.375,
        "最低": 0.375,
        "昨收": 0.38,
        "成交量": 29046.0,
        "成交额": 10811.0
    },
    {
        "序号": 3912,
        "symbol": "00825",
        "name": "新世界百货中国",
        "最新价": 0.375,
        "涨跌额": -0.005,
        "涨跌幅": -1.32,
        "今开": 0.38,
        "最高": 0.38,
        "最低": 0.365,
        "昨收": 0.38,
        "成交量": 359000.0,
        "成交额": 132660.0
    },
    {
        "序号": 3913,
        "symbol": "00620",
        "name": "大唐西市",
        "最新价": 0.375,
        "涨跌额": -0.005,
        "涨跌幅": -1.32,
        "今开": 0.335,
        "最高": 0.375,
        "最低": 0.335,
        "昨收": 0.38,
        "成交量": 374000.0,
        "成交额": 140090.0
    },
    {
        "序号": 3914,
        "symbol": "00601",
        "name": "稀镁科技",
        "最新价": 0.075,
        "涨跌额": -0.001,
        "涨跌幅": -1.32,
        "今开": 0.077,
        "最高": 0.077,
        "最低": 0.072,
        "昨收": 0.076,
        "成交量": 710000.0,
        "成交额": 52640.0
    },
    {
        "序号": 3915,
        "symbol": "00410",
        "name": "SOHO中国",
        "最新价": 0.75,
        "涨跌额": -0.01,
        "涨跌幅": -1.32,
        "今开": 0.75,
        "最高": 0.77,
        "最低": 0.74,
        "昨收": 0.76,
        "成交量": 1775000.0,
        "成交额": 1333975.0
    },
    {
        "序号": 3916,
        "symbol": "02846",
        "name": "安硕沪深三百",
        "最新价": 23.94,
        "涨跌额": -0.32,
        "涨跌幅": -1.32,
        "今开": 24.26,
        "最高": 24.38,
        "最低": 23.94,
        "昨收": 24.26,
        "成交量": 123500.0,
        "成交额": 2995084.0
    },
    {
        "序号": 3917,
        "symbol": "09987",
        "name": "百胜中国",
        "最新价": 312.8,
        "涨跌额": -4.2,
        "涨跌幅": -1.32,
        "今开": 315.4,
        "最高": 316.4,
        "最低": 310.4,
        "昨收": 317.0,
        "成交量": 415853.0,
        "成交额": 130153708.0
    },
    {
        "序号": 3918,
        "symbol": "01789",
        "name": "爱康医疗",
        "最新价": 6.66,
        "涨跌额": -0.09,
        "涨跌幅": -1.33,
        "今开": 6.52,
        "最高": 6.85,
        "最低": 6.51,
        "昨收": 6.75,
        "成交量": 3094000.0,
        "成交额": 20674248.0
    },
    {
        "序号": 3919,
        "symbol": "09658",
        "name": "特海国际",
        "最新价": 11.68,
        "涨跌额": -0.16,
        "涨跌幅": -1.35,
        "今开": 11.9,
        "最高": 12.0,
        "最低": 11.52,
        "昨收": 11.84,
        "成交量": 516300.0,
        "成交额": 6032658.0
    },
    {
        "序号": 3920,
        "symbol": "01359",
        "name": "中国信达",
        "最新价": 0.73,
        "涨跌额": -0.01,
        "涨跌幅": -1.35,
        "今开": 0.74,
        "最高": 0.75,
        "最低": 0.73,
        "昨收": 0.74,
        "成交量": 34006000.0,
        "成交额": 24920796.0
    },
    {
        "序号": 3921,
        "symbol": "00866",
        "name": "中国秦发",
        "最新价": 0.219,
        "涨跌额": -0.003,
        "涨跌幅": -1.35,
        "今开": 0.219,
        "最高": 0.219,
        "最低": 0.213,
        "昨收": 0.222,
        "成交量": 8000.0,
        "成交额": 1740.0
    },
    {
        "序号": 3922,
        "symbol": "07252",
        "name": "南方两倍看多中证证券",
        "最新价": 7.615,
        "涨跌额": -0.105,
        "涨跌幅": -1.36,
        "今开": 7.625,
        "最高": 7.625,
        "最低": 7.615,
        "昨收": 7.72,
        "成交量": 2500.0,
        "成交额": 19062.0
    },
    {
        "序号": 3923,
        "symbol": "01801",
        "name": "信达生物",
        "最新价": 39.75,
        "涨跌额": -0.55,
        "涨跌幅": -1.36,
        "今开": 40.15,
        "最高": 40.15,
        "最低": 38.35,
        "昨收": 40.3,
        "成交量": 11335623.0,
        "成交额": 444888704.0
    },
    {
        "序号": 3924,
        "symbol": "01458",
        "name": "周黑鸭",
        "最新价": 2.16,
        "涨跌额": -0.03,
        "涨跌幅": -1.37,
        "今开": 2.2,
        "最高": 2.22,
        "最低": 2.13,
        "昨收": 2.19,
        "成交量": 2361500.0,
        "成交额": 5104381.0
    },
    {
        "序号": 3925,
        "symbol": "01397",
        "name": "碧瑶绿色集团",
        "最新价": 0.72,
        "涨跌额": -0.01,
        "涨跌幅": -1.37,
        "今开": 0.73,
        "最高": 0.74,
        "最低": 0.72,
        "昨收": 0.73,
        "成交量": 56000.0,
        "成交额": 40520.0
    },
    {
        "序号": 3926,
        "symbol": "00451",
        "name": "协鑫新能源",
        "最新价": 0.36,
        "涨跌额": -0.005,
        "涨跌幅": -1.37,
        "今开": 0.355,
        "最高": 0.37,
        "最低": 0.355,
        "昨收": 0.365,
        "成交量": 557029.0,
        "成交额": 201353.0
    },
    {
        "序号": 3927,
        "symbol": "80011",
        "name": "恒生银行-R",
        "最新价": 78.75,
        "涨跌额": -1.1,
        "涨跌幅": -1.38,
        "今开": 79.8,
        "最高": 79.8,
        "最低": 78.75,
        "昨收": 79.85,
        "成交量": 500.0,
        "成交额": 39900.0
    },
    {
        "序号": 3928,
        "symbol": "00752",
        "name": "PICO FAR EAST",
        "最新价": 1.43,
        "涨跌额": -0.02,
        "涨跌幅": -1.38,
        "今开": 1.45,
        "最高": 1.45,
        "最低": 1.43,
        "昨收": 1.45,
        "成交量": 123000.0,
        "成交额": 176660.0
    },
    {
        "序号": 3929,
        "symbol": "01308",
        "name": "海丰国际",
        "最新价": 11.42,
        "涨跌额": -0.16,
        "涨跌幅": -1.38,
        "今开": 11.7,
        "最高": 11.7,
        "最低": 11.18,
        "昨收": 11.58,
        "成交量": 5779807.0,
        "成交额": 65626674.0
    },
    {
        "序号": 3930,
        "symbol": "01809",
        "name": "浦林成山",
        "最新价": 6.41,
        "涨跌额": -0.09,
        "涨跌幅": -1.38,
        "今开": 6.5,
        "最高": 6.53,
        "最低": 6.4,
        "昨收": 6.5,
        "成交量": 19500.0,
        "成交额": 126710.0
    },
    {
        "序号": 3931,
        "symbol": "01679",
        "name": "瑞斯康集团",
        "最新价": 0.71,
        "涨跌额": -0.01,
        "涨跌幅": -1.39,
        "今开": 0.69,
        "最高": 0.73,
        "最低": 0.69,
        "昨收": 0.72,
        "成交量": 59000.0,
        "成交额": 41470.0
    },
    {
        "序号": 3932,
        "symbol": "02142",
        "name": "和铂医药-B",
        "最新价": 1.41,
        "涨跌额": -0.02,
        "涨跌幅": -1.4,
        "今开": 1.43,
        "最高": 1.46,
        "最低": 1.4,
        "昨收": 1.43,
        "成交量": 1503000.0,
        "成交额": 2124970.0
    },
    {
        "序号": 3933,
        "symbol": "06030",
        "name": "中信证券",
        "最新价": 15.46,
        "涨跌额": -0.22,
        "涨跌幅": -1.4,
        "今开": 15.68,
        "最高": 15.72,
        "最低": 15.36,
        "昨收": 15.68,
        "成交量": 8007100.0,
        "成交额": 123834502.0
    },
    {
        "序号": 3934,
        "symbol": "06069",
        "name": "盛业",
        "最新价": 4.91,
        "涨跌额": -0.07,
        "涨跌幅": -1.41,
        "今开": 5.03,
        "最高": 5.03,
        "最低": 4.87,
        "昨收": 4.98,
        "成交量": 1271000.0,
        "成交额": 6217710.0
    },
    {
        "序号": 3935,
        "symbol": "00719",
        "name": "山东新华制药股份",
        "最新价": 5.61,
        "涨跌额": -0.08,
        "涨跌幅": -1.41,
        "今开": 5.68,
        "最高": 5.76,
        "最低": 5.61,
        "昨收": 5.69,
        "成交量": 1214000.0,
        "成交额": 6896460.0
    },
    {
        "序号": 3936,
        "symbol": "00771",
        "name": "自动系统",
        "最新价": 0.7,
        "涨跌额": -0.01,
        "涨跌幅": -1.41,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.69,
        "昨收": 0.71,
        "成交量": 808000.0,
        "成交额": 565200.0
    },
    {
        "序号": 3937,
        "symbol": "09688",
        "name": "再鼎医药",
        "最新价": 20.95,
        "涨跌额": -0.3,
        "涨跌幅": -1.41,
        "今开": 21.25,
        "最高": 21.65,
        "最低": 20.75,
        "昨收": 21.25,
        "成交量": 2892812.0,
        "成交额": 60929935.0
    },
    {
        "序号": 3938,
        "symbol": "02708",
        "name": "艾伯科技",
        "最新价": 0.139,
        "涨跌额": -0.002,
        "涨跌幅": -1.42,
        "今开": 0.136,
        "最高": 0.139,
        "最低": 0.129,
        "昨收": 0.141,
        "成交量": 1378000.0,
        "成交额": 183608.0
    },
    {
        "序号": 3939,
        "symbol": "02018",
        "name": "瑞声科技",
        "最新价": 20.85,
        "涨跌额": -0.3,
        "涨跌幅": -1.42,
        "今开": 21.2,
        "最高": 21.75,
        "最低": 20.5,
        "昨收": 21.15,
        "成交量": 12350042.0,
        "成交额": 259271519.0
    },
    {
        "序号": 3940,
        "symbol": "00177",
        "name": "江苏宁沪高速公路",
        "最新价": 6.92,
        "涨跌额": -0.1,
        "涨跌幅": -1.42,
        "今开": 6.93,
        "最高": 6.98,
        "最低": 6.88,
        "昨收": 7.02,
        "成交量": 1830024.0,
        "成交额": 12700136.0
    },
    {
        "序号": 3941,
        "symbol": "06837",
        "name": "海通证券",
        "最新价": 4.14,
        "涨跌额": -0.06,
        "涨跌幅": -1.43,
        "今开": 4.18,
        "最高": 4.2,
        "最低": 4.1,
        "昨收": 4.2,
        "成交量": 11468280.0,
        "成交额": 47379641.0
    },
    {
        "序号": 3942,
        "symbol": "01612",
        "name": "永胜医疗",
        "最新价": 0.345,
        "涨跌额": -0.005,
        "涨跌幅": -1.43,
        "今开": 0.335,
        "最高": 0.345,
        "最低": 0.335,
        "昨收": 0.35,
        "成交量": 54000.0,
        "成交额": 18340.0
    },
    {
        "序号": 3943,
        "symbol": "01171",
        "name": "兖矿能源",
        "最新价": 13.76,
        "涨跌额": -0.2,
        "涨跌幅": -1.43,
        "今开": 13.96,
        "最高": 13.96,
        "最低": 13.62,
        "昨收": 13.96,
        "成交量": 5684466.0,
        "成交额": 78183240.0
    },
    {
        "序号": 3944,
        "symbol": "01908",
        "name": "建发国际集团",
        "最新价": 15.12,
        "涨跌额": -0.22,
        "涨跌幅": -1.43,
        "今开": 15.44,
        "最高": 15.44,
        "最低": 14.98,
        "昨收": 15.34,
        "成交量": 2094876.0,
        "成交额": 31626311.0
    },
    {
        "序号": 3945,
        "symbol": "00011",
        "name": "恒生银行",
        "最新价": 85.85,
        "涨跌额": -1.25,
        "涨跌幅": -1.44,
        "今开": 87.2,
        "最高": 87.65,
        "最低": 85.6,
        "昨收": 87.1,
        "成交量": 1455391.0,
        "成交额": 125775714.0
    },
    {
        "序号": 3946,
        "symbol": "00968",
        "name": "信义光能",
        "最新价": 4.12,
        "涨跌额": -0.06,
        "涨跌幅": -1.44,
        "今开": 4.2,
        "最高": 4.21,
        "最低": 4.08,
        "昨收": 4.18,
        "成交量": 14105745.0,
        "成交额": 58542476.0
    },
    {
        "序号": 3947,
        "symbol": "00772",
        "name": "阅文集团",
        "最新价": 27.45,
        "涨跌额": -0.4,
        "涨跌幅": -1.44,
        "今开": 28.0,
        "最高": 28.25,
        "最低": 27.15,
        "昨收": 27.85,
        "成交量": 2740412.0,
        "成交额": 75661864.0
    },
    {
        "序号": 3948,
        "symbol": "01055",
        "name": "中国南方航空股份",
        "最新价": 3.43,
        "涨跌额": -0.05,
        "涨跌幅": -1.44,
        "今开": 3.5,
        "最高": 3.56,
        "最低": 3.42,
        "昨收": 3.48,
        "成交量": 13468000.0,
        "成交额": 46885030.0
    },
    {
        "序号": 3949,
        "symbol": "06163",
        "name": "彭顺国际",
        "最新价": 0.34,
        "涨跌额": -0.005,
        "涨跌幅": -1.45,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.34,
        "昨收": 0.345,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 3950,
        "symbol": "00769",
        "name": "中国稀土",
        "最新价": 0.34,
        "涨跌额": -0.005,
        "涨跌幅": -1.45,
        "今开": 0.35,
        "最高": 0.35,
        "最低": 0.34,
        "昨收": 0.345,
        "成交量": 999215.0,
        "成交额": 343538.0
    },
    {
        "序号": 3951,
        "symbol": "00732",
        "name": "信利国际",
        "最新价": 0.68,
        "涨跌额": -0.01,
        "涨跌幅": -1.45,
        "今开": 0.69,
        "最高": 0.69,
        "最低": 0.67,
        "昨收": 0.69,
        "成交量": 1408000.0,
        "成交额": 961080.0
    },
    {
        "序号": 3952,
        "symbol": "02096",
        "name": "先声药业",
        "最新价": 6.77,
        "涨跌额": -0.1,
        "涨跌幅": -1.46,
        "今开": 6.89,
        "最高": 6.96,
        "最低": 6.76,
        "昨收": 6.87,
        "成交量": 3230000.0,
        "成交额": 22017431.0
    },
    {
        "序号": 3953,
        "symbol": "06881",
        "name": "中国银河",
        "最新价": 4.04,
        "涨跌额": -0.06,
        "涨跌幅": -1.46,
        "今开": 4.1,
        "最高": 4.13,
        "最低": 4.01,
        "昨收": 4.1,
        "成交量": 25021500.0,
        "成交额": 101105780.0
    },
    {
        "序号": 3954,
        "symbol": "02338",
        "name": "潍柴动力",
        "最新价": 13.4,
        "涨跌额": -0.2,
        "涨跌幅": -1.47,
        "今开": 13.58,
        "最高": 13.7,
        "最低": 13.3,
        "昨收": 13.6,
        "成交量": 6348000.0,
        "成交额": 85639298.0
    },
    {
        "序号": 3955,
        "symbol": "01898",
        "name": "中煤能源",
        "最新价": 6.7,
        "涨跌额": -0.1,
        "涨跌幅": -1.47,
        "今开": 6.74,
        "最高": 6.78,
        "最低": 6.65,
        "昨收": 6.8,
        "成交量": 8638500.0,
        "成交额": 57902484.0
    },
    {
        "序号": 3956,
        "symbol": "01072",
        "name": "东方电气",
        "最新价": 6.7,
        "涨跌额": -0.1,
        "涨跌幅": -1.47,
        "今开": 6.8,
        "最高": 6.85,
        "最低": 6.69,
        "昨收": 6.8,
        "成交量": 793400.0,
        "成交额": 5355594.0
    },
    {
        "序号": 3957,
        "symbol": "00274",
        "name": "复兴亚洲",
        "最新价": 0.067,
        "涨跌额": -0.001,
        "涨跌幅": -1.47,
        "今开": 0.064,
        "最高": 0.067,
        "最低": 0.064,
        "昨收": 0.068,
        "成交量": 410000.0,
        "成交额": 27050.0
    },
    {
        "序号": 3958,
        "symbol": "08223",
        "name": "紫元元",
        "最新价": 1.33,
        "涨跌额": -0.02,
        "涨跌幅": -1.48,
        "今开": 1.38,
        "最高": 1.4,
        "最低": 1.28,
        "昨收": 1.35,
        "成交量": 36000.0,
        "成交额": 48880.0
    },
    {
        "序号": 3959,
        "symbol": "01808",
        "name": "企展控股",
        "最新价": 1.33,
        "涨跌额": -0.02,
        "涨跌幅": -1.48,
        "今开": 1.32,
        "最高": 1.33,
        "最低": 1.32,
        "昨收": 1.35,
        "成交量": 50000.0,
        "成交额": 66000.0
    },
    {
        "序号": 3960,
        "symbol": "00808",
        "name": "泓富产业信托",
        "最新价": 1.32,
        "涨跌额": -0.02,
        "涨跌幅": -1.49,
        "今开": 1.34,
        "最高": 1.34,
        "最低": 1.32,
        "昨收": 1.34,
        "成交量": 546000.0,
        "成交额": 726860.0
    },
    {
        "序号": 3961,
        "symbol": "00975",
        "name": "MONGOL MINING",
        "最新价": 4.58,
        "涨跌额": -0.07,
        "涨跌幅": -1.51,
        "今开": 4.66,
        "最高": 4.67,
        "最低": 4.55,
        "昨收": 4.65,
        "成交量": 2645200.0,
        "成交额": 12147432.0
    },
    {
        "序号": 3962,
        "symbol": "03360",
        "name": "远东宏信",
        "最新价": 5.85,
        "涨跌额": -0.09,
        "涨跌幅": -1.52,
        "今开": 5.78,
        "最高": 5.9,
        "最低": 5.78,
        "昨收": 5.94,
        "成交量": 5119000.0,
        "成交额": 29959226.0
    },
    {
        "序号": 3963,
        "symbol": "03330",
        "name": "灵宝黄金",
        "最新价": 1.3,
        "涨跌额": -0.02,
        "涨跌幅": -1.52,
        "今开": 1.32,
        "最高": 1.32,
        "最低": 1.28,
        "昨收": 1.32,
        "成交量": 860000.0,
        "成交额": 1106340.0
    },
    {
        "序号": 3964,
        "symbol": "01416",
        "name": "CTR HOLDINGS",
        "最新价": 0.065,
        "涨跌额": -0.001,
        "涨跌幅": -1.52,
        "今开": 0.065,
        "最高": 0.065,
        "最低": 0.065,
        "昨收": 0.066,
        "成交量": 200000.0,
        "成交额": 13000.0
    },
    {
        "序号": 3965,
        "symbol": "00686",
        "name": "北京能源国际",
        "最新价": 0.13,
        "涨跌额": -0.002,
        "涨跌幅": -1.52,
        "今开": 0.147,
        "最高": 0.147,
        "最低": 0.124,
        "昨收": 0.132,
        "成交量": 6982000.0,
        "成交额": 891466.0
    },
    {
        "序号": 3966,
        "symbol": "00655",
        "name": "香港华人有限公司",
        "最新价": 0.325,
        "涨跌额": -0.005,
        "涨跌幅": -1.52,
        "今开": 0.32,
        "最高": 0.33,
        "最低": 0.32,
        "昨收": 0.33,
        "成交量": 41923.0,
        "成交额": 13715.0
    },
    {
        "序号": 3967,
        "symbol": "00268",
        "name": "金蝶国际",
        "最新价": 10.38,
        "涨跌额": -0.16,
        "涨跌幅": -1.52,
        "今开": 10.54,
        "最高": 10.88,
        "最低": 10.3,
        "昨收": 10.54,
        "成交量": 15307978.0,
        "成交额": 160927351.0
    },
    {
        "序号": 3968,
        "symbol": "01910",
        "name": "新秀丽",
        "最新价": 22.55,
        "涨跌额": -0.35,
        "涨跌幅": -1.53,
        "今开": 22.9,
        "最高": 23.05,
        "最低": 22.2,
        "昨收": 22.9,
        "成交量": 3732600.0,
        "成交额": 84049776.0
    },
    {
        "序号": 3969,
        "symbol": "03389",
        "name": "亨得利",
        "最新价": 0.128,
        "涨跌额": -0.002,
        "涨跌幅": -1.54,
        "今开": 0.128,
        "最高": 0.128,
        "最低": 0.127,
        "昨收": 0.13,
        "成交量": 168000.0,
        "成交额": 21472.0
    },
    {
        "序号": 3970,
        "symbol": "02347",
        "name": "友和集团",
        "最新价": 0.64,
        "涨跌额": -0.01,
        "涨跌幅": -1.54,
        "今开": 0.65,
        "最高": 0.65,
        "最低": 0.62,
        "昨收": 0.65,
        "成交量": 244000.0,
        "成交额": 158520.0
    },
    {
        "序号": 3971,
        "symbol": "02268",
        "name": "药明合联",
        "最新价": 28.8,
        "涨跌额": -0.45,
        "涨跌幅": -1.54,
        "今开": 28.95,
        "最高": 29.3,
        "最低": 28.45,
        "昨收": 29.25,
        "成交量": 937310.0,
        "成交额": 26889990.0
    },
    {
        "序号": 3972,
        "symbol": "01719",
        "name": "中国通商集团",
        "最新价": 0.64,
        "涨跌额": -0.01,
        "涨跌幅": -1.54,
        "今开": 0.74,
        "最高": 0.74,
        "最低": 0.64,
        "昨收": 0.65,
        "成交量": 24000.0,
        "成交额": 15840.0
    },
    {
        "序号": 3973,
        "symbol": "00412",
        "name": "山高控股",
        "最新价": 6.36,
        "涨跌额": -0.1,
        "涨跌幅": -1.55,
        "今开": 6.43,
        "最高": 6.51,
        "最低": 6.32,
        "昨收": 6.46,
        "成交量": 2955539.0,
        "成交额": 19080834.0
    },
    {
        "序号": 3974,
        "symbol": "08282",
        "name": "智傲控股",
        "最新价": 0.126,
        "涨跌额": -0.002,
        "涨跌幅": -1.56,
        "今开": 0.128,
        "最高": 0.128,
        "最低": 0.126,
        "昨收": 0.128,
        "成交量": 12000.0,
        "成交额": 1344.0
    },
    {
        "序号": 3975,
        "symbol": "06928",
        "name": "万马控股",
        "最新价": 0.315,
        "涨跌额": -0.005,
        "涨跌幅": -1.56,
        "今开": 0.3,
        "最高": 0.315,
        "最低": 0.3,
        "昨收": 0.32,
        "成交量": 48000.0,
        "成交额": 14520.0
    },
    {
        "序号": 3976,
        "symbol": "01147",
        "name": "伊登软件",
        "最新价": 0.063,
        "涨跌额": -0.001,
        "涨跌幅": -1.56,
        "今开": 0.061,
        "最高": 0.063,
        "最低": 0.061,
        "昨收": 0.064,
        "成交量": 880000.0,
        "成交额": 53970.0
    },
    {
        "序号": 3977,
        "symbol": "00335",
        "name": "美建集团",
        "最新价": 0.63,
        "涨跌额": -0.01,
        "涨跌幅": -1.56,
        "今开": 0.61,
        "最高": 0.63,
        "最低": 0.61,
        "昨收": 0.64,
        "成交量": 24000.0,
        "成交额": 14680.0
    },
    {
        "序号": 3978,
        "symbol": "00031",
        "name": "航天控股",
        "最新价": 0.315,
        "涨跌额": -0.005,
        "涨跌幅": -1.56,
        "今开": 0.32,
        "最高": 0.325,
        "最低": 0.315,
        "昨收": 0.32,
        "成交量": 604000.0,
        "成交额": 194500.0
    },
    {
        "序号": 3979,
        "symbol": "03160",
        "name": "华夏日股对冲",
        "最新价": 16.96,
        "涨跌额": -0.27,
        "涨跌幅": -1.57,
        "今开": 17.0,
        "最高": 17.06,
        "最低": 16.91,
        "昨收": 17.23,
        "成交量": 3903400.0,
        "成交额": 66218686.0
    },
    {
        "序号": 3980,
        "symbol": "00498",
        "name": "蓝河控股",
        "最新价": 0.31,
        "涨跌额": -0.005,
        "涨跌幅": -1.59,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.31,
        "昨收": 0.315,
        "成交量": 60000.0,
        "成交额": 18600.0
    },
    {
        "序号": 3981,
        "symbol": "03066",
        "name": "FA南方比特币",
        "最新价": 17.89,
        "涨跌额": -0.29,
        "涨跌幅": -1.6,
        "今开": 18.17,
        "最高": 18.17,
        "最低": 17.86,
        "昨收": 18.18,
        "成交量": 686100.0,
        "成交额": 12333820.0
    },
    {
        "序号": 3982,
        "symbol": "00960",
        "name": "龙湖集团",
        "最新价": 12.3,
        "涨跌额": -0.2,
        "涨跌幅": -1.6,
        "今开": 12.62,
        "最高": 12.66,
        "最低": 12.26,
        "昨收": 12.5,
        "成交量": 10813247.0,
        "成交额": 133721051.0
    },
    {
        "序号": 3983,
        "symbol": "02138",
        "name": "医思健康",
        "最新价": 1.84,
        "涨跌额": -0.03,
        "涨跌幅": -1.6,
        "今开": 1.86,
        "最高": 1.92,
        "最低": 1.84,
        "昨收": 1.87,
        "成交量": 2108000.0,
        "成交额": 3938215.0
    },
    {
        "序号": 3984,
        "symbol": "01638",
        "name": "佳兆业集团",
        "最新价": 0.184,
        "涨跌额": -0.003,
        "涨跌幅": -1.6,
        "今开": 0.183,
        "最高": 0.19,
        "最低": 0.183,
        "昨收": 0.187,
        "成交量": 427000.0,
        "成交额": 78369.0
    },
    {
        "序号": 3985,
        "symbol": "01052",
        "name": "越秀交通基建",
        "最新价": 4.28,
        "涨跌额": -0.07,
        "涨跌幅": -1.61,
        "今开": 4.43,
        "最高": 4.43,
        "最低": 4.25,
        "昨收": 4.35,
        "成交量": 782000.0,
        "成交额": 3355240.0
    },
    {
        "序号": 3986,
        "symbol": "02880",
        "name": "辽港股份",
        "最新价": 0.61,
        "涨跌额": -0.01,
        "涨跌幅": -1.61,
        "今开": 0.62,
        "最高": 0.63,
        "最低": 0.61,
        "昨收": 0.62,
        "成交量": 1551306.0,
        "成交额": 952930.0
    },
    {
        "序号": 3987,
        "symbol": "02878",
        "name": "晶门半导体",
        "最新价": 0.305,
        "涨跌额": -0.005,
        "涨跌幅": -1.61,
        "今开": 0.3,
        "最高": 0.305,
        "最低": 0.3,
        "昨收": 0.31,
        "成交量": 1426000.0,
        "成交额": 434230.0
    },
    {
        "序号": 3988,
        "symbol": "02528",
        "name": "尚晋国际控股",
        "最新价": 0.305,
        "涨跌额": -0.005,
        "涨跌幅": -1.61,
        "今开": 0.305,
        "最高": 0.305,
        "最低": 0.305,
        "昨收": 0.31,
        "成交量": 4000.0,
        "成交额": 1220.0
    },
    {
        "序号": 3989,
        "symbol": "01668",
        "name": "华南城",
        "最新价": 0.305,
        "涨跌额": -0.005,
        "涨跌幅": -1.61,
        "今开": 0.31,
        "最高": 0.31,
        "最低": 0.305,
        "昨收": 0.31,
        "成交量": 2036000.0,
        "成交额": 624900.0
    },
    {
        "序号": 3990,
        "symbol": "00251",
        "name": "爪哇控股",
        "最新价": 1.82,
        "涨跌额": -0.03,
        "涨跌幅": -1.62,
        "今开": 1.98,
        "最高": 1.98,
        "最低": 1.82,
        "昨收": 1.85,
        "成交量": 8000.0,
        "成交额": 15200.0
    },
    {
        "序号": 3991,
        "symbol": "03969",
        "name": "中国通号",
        "最新价": 2.41,
        "涨跌额": -0.04,
        "涨跌幅": -1.63,
        "今开": 2.42,
        "最高": 2.46,
        "最低": 2.41,
        "昨收": 2.45,
        "成交量": 849000.0,
        "成交额": 2066370.0
    },
    {
        "序号": 3992,
        "symbol": "00175",
        "name": "吉利汽车",
        "最新价": 7.83,
        "涨跌额": -0.13,
        "涨跌幅": -1.63,
        "今开": 7.97,
        "最高": 8.0,
        "最低": 7.7,
        "昨收": 7.96,
        "成交量": 53777344.0,
        "成交额": 419115696.0
    },
    {
        "序号": 3993,
        "symbol": "01881",
        "name": "富豪产业信托",
        "最新价": 0.6,
        "涨跌额": -0.01,
        "涨跌幅": -1.64,
        "今开": 0.6,
        "最高": 0.61,
        "最低": 0.59,
        "昨收": 0.61,
        "成交量": 316000.0,
        "成交额": 189110.0
    },
    {
        "序号": 3994,
        "symbol": "01857",
        "name": "中国光大水务",
        "最新价": 1.2,
        "涨跌额": -0.02,
        "涨跌幅": -1.64,
        "今开": 1.22,
        "最高": 1.22,
        "最低": 1.2,
        "昨收": 1.22,
        "成交量": 204000.0,
        "成交额": 245810.0
    },
    {
        "序号": 3995,
        "symbol": "00576",
        "name": "浙江沪杭甬",
        "最新价": 4.79,
        "涨跌额": -0.08,
        "涨跌幅": -1.64,
        "今开": 4.94,
        "最高": 4.9,
        "最低": 4.74,
        "昨收": 4.87,
        "成交量": 6960100.0,
        "成交额": 33401569.0
    },
    {
        "序号": 3996,
        "symbol": "80175",
        "name": "吉利汽车-R",
        "最新价": 7.18,
        "涨跌额": -0.12,
        "涨跌幅": -1.64,
        "今开": 7.11,
        "最高": 7.18,
        "最低": 7.1,
        "昨收": 7.3,
        "成交量": 499000.0,
        "成交额": 3550100.0
    },
    {
        "序号": 3997,
        "symbol": "01138",
        "name": "中远海能",
        "最新价": 7.17,
        "涨跌额": -0.12,
        "涨跌幅": -1.65,
        "今开": 7.27,
        "最高": 7.29,
        "最低": 7.12,
        "昨收": 7.29,
        "成交量": 8063015.0,
        "成交额": 57891189.0
    },
    {
        "序号": 3998,
        "symbol": "06626",
        "name": "越秀服务",
        "最新价": 2.38,
        "涨跌额": -0.04,
        "涨跌幅": -1.65,
        "今开": 2.43,
        "最高": 2.45,
        "最低": 2.38,
        "昨收": 2.42,
        "成交量": 1637500.0,
        "成交额": 3962220.0
    },
    {
        "序号": 3999,
        "symbol": "02373",
        "name": "美丽田园医疗健康",
        "最新价": 13.06,
        "涨跌额": -0.22,
        "涨跌幅": -1.66,
        "今开": 13.16,
        "最高": 13.16,
        "最低": 12.92,
        "昨收": 13.28,
        "成交量": 22500.0,
        "成交额": 292980.0
    },
    {
        "序号": 4000,
        "symbol": "01650",
        "name": "HYGIEIA GROUP",
        "最新价": 0.059,
        "涨跌额": -0.001,
        "涨跌幅": -1.67,
        "今开": 0.059,
        "最高": 0.059,
        "最低": 0.059,
        "昨收": 0.06,
        "成交量": 48000.0,
        "成交额": 2832.0
    },
    {
        "序号": 4001,
        "symbol": "00670",
        "name": "中国东方航空股份",
        "最新价": 2.36,
        "涨跌额": -0.04,
        "涨跌幅": -1.67,
        "今开": 2.41,
        "最高": 2.44,
        "最低": 2.35,
        "昨收": 2.4,
        "成交量": 4398000.0,
        "成交额": 10485420.0
    },
    {
        "序号": 4002,
        "symbol": "00581",
        "name": "中国东方集团",
        "最新价": 1.18,
        "涨跌额": -0.02,
        "涨跌幅": -1.67,
        "今开": 1.2,
        "最高": 1.2,
        "最低": 1.18,
        "昨收": 1.2,
        "成交量": 1174000.0,
        "成交额": 1397220.0
    },
    {
        "序号": 4003,
        "symbol": "02179",
        "name": "瑞科生物-B",
        "最新价": 9.99,
        "涨跌额": -0.17,
        "涨跌幅": -1.67,
        "今开": 10.16,
        "最高": 10.28,
        "最低": 9.98,
        "昨收": 10.16,
        "成交量": 11000.0,
        "成交额": 111005.0
    },
    {
        "序号": 4004,
        "symbol": "03333",
        "name": "中国恒大",
        "最新价": 0.235,
        "涨跌额": -0.004,
        "涨跌幅": -1.67,
        "今开": 0.241,
        "最高": 0.246,
        "最低": 0.232,
        "昨收": 0.239,
        "成交量": 13028000.0,
        "成交额": 3099253.0
    },
    {
        "序号": 4005,
        "symbol": "00895",
        "name": "东江环保",
        "最新价": 1.75,
        "涨跌额": -0.03,
        "涨跌幅": -1.69,
        "今开": 1.8,
        "最高": 1.8,
        "最低": 1.75,
        "昨收": 1.78,
        "成交量": 217500.0,
        "成交额": 385887.0
    },
    {
        "序号": 4006,
        "symbol": "03135",
        "name": "FA三星比特币",
        "最新价": 17.45,
        "涨跌额": -0.3,
        "涨跌幅": -1.69,
        "今开": 17.6,
        "最高": 17.6,
        "最低": 17.45,
        "昨收": 17.75,
        "成交量": 42600.0,
        "成交额": 745291.0
    },
    {
        "序号": 4007,
        "symbol": "00303",
        "name": "VTECH HOLDINGS",
        "最新价": 43.5,
        "涨跌额": -0.75,
        "涨跌幅": -1.69,
        "今开": 44.1,
        "最高": 44.2,
        "最低": 43.0,
        "昨收": 44.25,
        "成交量": 329100.0,
        "成交额": 14318632.0
    },
    {
        "序号": 4008,
        "symbol": "00345",
        "name": "VITASOY INT'L",
        "最新价": 7.51,
        "涨跌额": -0.13,
        "涨跌幅": -1.7,
        "今开": 7.65,
        "最高": 7.69,
        "最低": 7.39,
        "昨收": 7.64,
        "成交量": 938806.0,
        "成交额": 7057870.0
    },
    {
        "序号": 4009,
        "symbol": "01951",
        "name": "锦欣生殖",
        "最新价": 3.45,
        "涨跌额": -0.06,
        "涨跌幅": -1.71,
        "今开": 3.53,
        "最高": 3.53,
        "最低": 3.44,
        "昨收": 3.51,
        "成交量": 9493000.0,
        "成交额": 32935400.0
    },
    {
        "序号": 4010,
        "symbol": "00914",
        "name": "海螺水泥",
        "最新价": 17.22,
        "涨跌额": -0.3,
        "涨跌幅": -1.71,
        "今开": 17.58,
        "最高": 17.6,
        "最低": 17.12,
        "昨收": 17.52,
        "成交量": 5346087.0,
        "成交额": 92592258.0
    },
    {
        "序号": 4011,
        "symbol": "06618",
        "name": "京东健康",
        "最新价": 37.05,
        "涨跌额": -0.65,
        "涨跌幅": -1.72,
        "今开": 37.1,
        "最高": 39.3,
        "最低": 37.0,
        "昨收": 37.7,
        "成交量": 9780457.0,
        "成交额": 365651856.0
    },
    {
        "序号": 4012,
        "symbol": "02132",
        "name": "誉燊丰控股",
        "最新价": 0.285,
        "涨跌额": -0.005,
        "涨跌幅": -1.72,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.28,
        "昨收": 0.29,
        "成交量": 656000.0,
        "成交额": 189680.0
    },
    {
        "序号": 4013,
        "symbol": "02119",
        "name": "捷荣国际控股",
        "最新价": 0.57,
        "涨跌额": -0.01,
        "涨跌幅": -1.72,
        "今开": 0.58,
        "最高": 0.58,
        "最低": 0.56,
        "昨收": 0.58,
        "成交量": 50000.0,
        "成交额": 28460.0
    },
    {
        "序号": 4014,
        "symbol": "00881",
        "name": "中升控股",
        "最新价": 17.06,
        "涨跌额": -0.3,
        "涨跌幅": -1.73,
        "今开": 17.5,
        "最高": 17.5,
        "最低": 17.0,
        "昨收": 17.36,
        "成交量": 2357800.0,
        "成交额": 40494654.0
    },
    {
        "序号": 4015,
        "symbol": "02128",
        "name": "中国联塑",
        "最新价": 3.97,
        "涨跌额": -0.07,
        "涨跌幅": -1.73,
        "今开": 4.06,
        "最高": 4.11,
        "最低": 3.97,
        "昨收": 4.04,
        "成交量": 3855345.0,
        "成交额": 15465630.0
    },
    {
        "序号": 4016,
        "symbol": "01378",
        "name": "中国宏桥",
        "最新价": 5.66,
        "涨跌额": -0.1,
        "涨跌幅": -1.74,
        "今开": 5.87,
        "最高": 5.87,
        "最低": 5.6,
        "昨收": 5.76,
        "成交量": 24357500.0,
        "成交额": 137672789.0
    },
    {
        "序号": 4017,
        "symbol": "01302",
        "name": "先健科技",
        "最新价": 2.26,
        "涨跌额": -0.04,
        "涨跌幅": -1.74,
        "今开": 2.3,
        "最高": 2.34,
        "最低": 2.17,
        "昨收": 2.3,
        "成交量": 28086000.0,
        "成交额": 62335920.0
    },
    {
        "序号": 4018,
        "symbol": "00970",
        "name": "新耀莱",
        "最新价": 1.13,
        "涨跌额": -0.02,
        "涨跌幅": -1.74,
        "今开": 1.05,
        "最高": 1.22,
        "最低": 1.05,
        "昨收": 1.15,
        "成交量": 138687.0,
        "成交额": 164016.0
    },
    {
        "序号": 4019,
        "symbol": "03968",
        "name": "招商银行",
        "最新价": 25.4,
        "涨跌额": -0.45,
        "涨跌幅": -1.74,
        "今开": 25.5,
        "最高": 25.8,
        "最低": 25.2,
        "昨收": 25.85,
        "成交量": 31258425.0,
        "成交额": 796155808.0
    },
    {
        "序号": 4020,
        "symbol": "01888",
        "name": "建滔积层板",
        "最新价": 6.74,
        "涨跌额": -0.12,
        "涨跌幅": -1.75,
        "今开": 6.86,
        "最高": 6.86,
        "最低": 6.6,
        "昨收": 6.86,
        "成交量": 4028500.0,
        "成交额": 27031640.0
    },
    {
        "序号": 4021,
        "symbol": "01162",
        "name": "莹岚集团",
        "最新价": 0.28,
        "涨跌额": -0.005,
        "涨跌幅": -1.75,
        "今开": 0.275,
        "最高": 0.29,
        "最低": 0.275,
        "昨收": 0.285,
        "成交量": 3008000.0,
        "成交额": 854400.0
    },
    {
        "序号": 4022,
        "symbol": "01558",
        "name": "东阳光长江药业",
        "最新价": 9.45,
        "涨跌额": -0.17,
        "涨跌幅": -1.77,
        "今开": 9.62,
        "最高": 9.7,
        "最低": 9.4,
        "昨收": 9.62,
        "成交量": 1632600.0,
        "成交额": 15510596.0
    },
    {
        "序号": 4023,
        "symbol": "02120",
        "name": "康宁医院",
        "最新价": 13.26,
        "涨跌额": -0.24,
        "涨跌幅": -1.78,
        "今开": 13.3,
        "最高": 13.3,
        "最低": 13.26,
        "昨收": 13.5,
        "成交量": 2300.0,
        "成交额": 30186.0
    },
    {
        "序号": 4024,
        "symbol": "02357",
        "name": "中航科工",
        "最新价": 3.31,
        "涨跌额": -0.06,
        "涨跌幅": -1.78,
        "今开": 3.37,
        "最高": 3.37,
        "最低": 3.28,
        "昨收": 3.37,
        "成交量": 9622000.0,
        "成交额": 31926820.0
    },
    {
        "序号": 4025,
        "symbol": "02219",
        "name": "朝聚眼科",
        "最新价": 4.41,
        "涨跌额": -0.08,
        "涨跌幅": -1.78,
        "今开": 4.53,
        "最高": 4.53,
        "最低": 4.39,
        "昨收": 4.49,
        "成交量": 1440000.0,
        "成交额": 6411130.0
    },
    {
        "序号": 4026,
        "symbol": "02150",
        "name": "奈雪的茶",
        "最新价": 3.3,
        "涨跌额": -0.06,
        "涨跌幅": -1.79,
        "今开": 3.42,
        "最高": 3.42,
        "最低": 3.25,
        "昨收": 3.36,
        "成交量": 2891500.0,
        "成交额": 9539441.0
    },
    {
        "序号": 4027,
        "symbol": "00733",
        "name": "合富辉煌",
        "最新价": 1.1,
        "涨跌额": -0.02,
        "涨跌幅": -1.79,
        "今开": 1.11,
        "最高": 1.11,
        "最低": 1.1,
        "昨收": 1.12,
        "成交量": 40000.0,
        "成交额": 44080.0
    },
    {
        "序号": 4028,
        "symbol": "00337",
        "name": "绿地香港",
        "最新价": 0.275,
        "涨跌额": -0.005,
        "涨跌幅": -1.79,
        "今开": 0.285,
        "最高": 0.295,
        "最低": 0.27,
        "昨收": 0.28,
        "成交量": 2192000.0,
        "成交额": 623295.0
    },
    {
        "序号": 4029,
        "symbol": "09926",
        "name": "康方生物",
        "最新价": 43.2,
        "涨跌额": -0.8,
        "涨跌幅": -1.82,
        "今开": 43.65,
        "最高": 44.45,
        "最低": 42.15,
        "昨收": 44.0,
        "成交量": 6859578.0,
        "成交额": 295672448.0
    },
    {
        "序号": 4030,
        "symbol": "01400",
        "name": "满地科技股份",
        "最新价": 0.27,
        "涨跌额": -0.005,
        "涨跌幅": -1.82,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.25,
        "昨收": 0.275,
        "成交量": 770000.0,
        "成交额": 205950.0
    },
    {
        "序号": 4031,
        "symbol": "02669",
        "name": "中海物业",
        "最新价": 5.89,
        "涨跌额": -0.11,
        "涨跌幅": -1.83,
        "今开": 6.0,
        "最高": 6.06,
        "最低": 5.73,
        "昨收": 6.0,
        "成交量": 6222047.0,
        "成交额": 36335822.0
    },
    {
        "序号": 4032,
        "symbol": "00604",
        "name": "深圳控股",
        "最新价": 1.07,
        "涨跌额": -0.02,
        "涨跌幅": -1.83,
        "今开": 1.1,
        "最高": 1.1,
        "最低": 1.07,
        "昨收": 1.09,
        "成交量": 2572000.0,
        "成交额": 2784060.0
    },
    {
        "序号": 4033,
        "symbol": "02459",
        "name": "升能集团",
        "最新价": 5.88,
        "涨跌额": -0.11,
        "涨跌幅": -1.84,
        "今开": 5.98,
        "最高": 6.1,
        "最低": 5.77,
        "昨收": 5.99,
        "成交量": 526000.0,
        "成交额": 3102940.0
    },
    {
        "序号": 4034,
        "symbol": "02279",
        "name": "雍禾医疗",
        "最新价": 2.67,
        "涨跌额": -0.05,
        "涨跌幅": -1.84,
        "今开": 2.71,
        "最高": 2.77,
        "最低": 2.67,
        "昨收": 2.72,
        "成交量": 382500.0,
        "成交额": 1031495.0
    },
    {
        "序号": 4035,
        "symbol": "00694",
        "name": "北京首都机场股份",
        "最新价": 2.67,
        "涨跌额": -0.05,
        "涨跌幅": -1.84,
        "今开": 2.73,
        "最高": 2.75,
        "最低": 2.66,
        "昨收": 2.72,
        "成交量": 14536000.0,
        "成交额": 39241598.0
    },
    {
        "序号": 4036,
        "symbol": "00131",
        "name": "卓能(集团)",
        "最新价": 1.6,
        "涨跌额": -0.03,
        "涨跌幅": -1.84,
        "今开": 1.54,
        "最高": 1.6,
        "最低": 1.54,
        "昨收": 1.63,
        "成交量": 4000.0,
        "成交额": 6220.0
    },
    {
        "序号": 4037,
        "symbol": "09996",
        "name": "沛嘉医疗-B",
        "最新价": 7.46,
        "涨跌额": -0.14,
        "涨跌幅": -1.84,
        "今开": 7.59,
        "最高": 7.8,
        "最低": 7.3,
        "昨收": 7.6,
        "成交量": 1496000.0,
        "成交额": 11111990.0
    },
    {
        "序号": 4038,
        "symbol": "09696",
        "name": "天齐锂业",
        "最新价": 39.95,
        "涨跌额": -0.75,
        "涨跌幅": -1.84,
        "今开": 41.85,
        "最高": 42.2,
        "最低": 39.65,
        "昨收": 40.7,
        "成交量": 2161200.0,
        "成交额": 87633320.0
    },
    {
        "序号": 4039,
        "symbol": "06855",
        "name": "亚盛医药-B",
        "最新价": 23.95,
        "涨跌额": -0.45,
        "涨跌幅": -1.84,
        "今开": 24.25,
        "最高": 24.75,
        "最低": 23.65,
        "昨收": 24.4,
        "成交量": 1286100.0,
        "成交额": 30845960.0
    },
    {
        "序号": 4040,
        "symbol": "03366",
        "name": "华侨城(亚洲)",
        "最新价": 0.265,
        "涨跌额": -0.005,
        "涨跌幅": -1.85,
        "今开": 0.27,
        "最高": 0.27,
        "最低": 0.265,
        "昨收": 0.27,
        "成交量": 162000.0,
        "成交额": 43730.0
    },
    {
        "序号": 4041,
        "symbol": "02098",
        "name": "卓尔智联",
        "最新价": 0.265,
        "涨跌额": -0.005,
        "涨跌幅": -1.85,
        "今开": 0.255,
        "最高": 0.265,
        "最低": 0.25,
        "昨收": 0.27,
        "成交量": 151000.0,
        "成交额": 39250.0
    },
    {
        "序号": 4042,
        "symbol": "00497",
        "name": "资本策略地产",
        "最新价": 0.106,
        "涨跌额": -0.002,
        "涨跌幅": -1.85,
        "今开": 0.106,
        "最高": 0.106,
        "最低": 0.106,
        "昨收": 0.108,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4043,
        "symbol": "01773",
        "name": "天立国际控股",
        "最新价": 3.16,
        "涨跌额": -0.06,
        "涨跌幅": -1.86,
        "今开": 3.22,
        "最高": 3.22,
        "最低": 3.11,
        "昨收": 3.22,
        "成交量": 1482595.0,
        "成交额": 4686024.0
    },
    {
        "序号": 4044,
        "symbol": "00419",
        "name": "华谊腾讯娱乐",
        "最新价": 0.105,
        "涨跌额": -0.002,
        "涨跌幅": -1.87,
        "今开": 0.109,
        "最高": 0.109,
        "最低": 0.102,
        "昨收": 0.107,
        "成交量": 3840000.0,
        "成交额": 397750.0
    },
    {
        "序号": 4045,
        "symbol": "09960",
        "name": "康圣环球",
        "最新价": 1.57,
        "涨跌额": -0.03,
        "涨跌幅": -1.88,
        "今开": 1.57,
        "最高": 1.62,
        "最低": 1.57,
        "昨收": 1.6,
        "成交量": 78500.0,
        "成交额": 123990.0
    },
    {
        "序号": 4046,
        "symbol": "01848",
        "name": "中国飞机租赁",
        "最新价": 3.65,
        "涨跌额": -0.07,
        "涨跌幅": -1.88,
        "今开": 3.56,
        "最高": 3.68,
        "最低": 3.5,
        "昨收": 3.72,
        "成交量": 18500.0,
        "成交额": 66980.0
    },
    {
        "序号": 4047,
        "symbol": "01480",
        "name": "恩达集团控股",
        "最新价": 1.04,
        "涨跌额": -0.02,
        "涨跌幅": -1.89,
        "今开": 1.05,
        "最高": 1.09,
        "最低": 1.01,
        "昨收": 1.06,
        "成交量": 46000.0,
        "成交额": 47860.0
    },
    {
        "序号": 4048,
        "symbol": "03908",
        "name": "中金公司",
        "最新价": 11.24,
        "涨跌额": -0.22,
        "涨跌幅": -1.92,
        "今开": 11.52,
        "最高": 11.52,
        "最低": 11.14,
        "昨收": 11.46,
        "成交量": 12918500.0,
        "成交额": 145476664.0
    },
    {
        "序号": 4049,
        "symbol": "08083",
        "name": "中国有赞",
        "最新价": 0.102,
        "涨跌额": -0.002,
        "涨跌幅": -1.92,
        "今开": 0.103,
        "最高": 0.104,
        "最低": 0.101,
        "昨收": 0.104,
        "成交量": 22892000.0,
        "成交额": 2346028.0
    },
    {
        "序号": 4050,
        "symbol": "01982",
        "name": "南旋控股",
        "最新价": 0.51,
        "涨跌额": -0.01,
        "涨跌幅": -1.92,
        "今开": 0.53,
        "最高": 0.53,
        "最低": 0.51,
        "昨收": 0.52,
        "成交量": 2024000.0,
        "成交额": 1043500.0
    },
    {
        "序号": 4051,
        "symbol": "01753",
        "name": "兑吧",
        "最新价": 0.255,
        "涨跌额": -0.005,
        "涨跌幅": -1.92,
        "今开": 0.26,
        "最高": 0.27,
        "最低": 0.25,
        "昨收": 0.26,
        "成交量": 664400.0,
        "成交额": 171306.0
    },
    {
        "序号": 4052,
        "symbol": "01598",
        "name": "21世纪教育",
        "最新价": 0.255,
        "涨跌额": -0.005,
        "涨跌幅": -1.92,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.255,
        "昨收": 0.26,
        "成交量": 90000.0,
        "成交额": 23370.0
    },
    {
        "序号": 4053,
        "symbol": "02333",
        "name": "长城汽车",
        "最新价": 10.18,
        "涨跌额": -0.2,
        "涨跌幅": -1.93,
        "今开": 10.26,
        "最高": 10.28,
        "最低": 9.93,
        "昨收": 10.38,
        "成交量": 27189654.0,
        "成交额": 273855232.0
    },
    {
        "序号": 4054,
        "symbol": "06608",
        "name": "百融云-W",
        "最新价": 13.2,
        "涨跌额": -0.26,
        "涨跌幅": -1.93,
        "今开": 13.48,
        "最高": 13.58,
        "最低": 13.12,
        "昨收": 13.46,
        "成交量": 1758420.0,
        "成交额": 23350406.0
    },
    {
        "序号": 4055,
        "symbol": "00119",
        "name": "保利置业集团",
        "最新价": 1.52,
        "涨跌额": -0.03,
        "涨跌幅": -1.94,
        "今开": 1.56,
        "最高": 1.56,
        "最低": 1.51,
        "昨收": 1.55,
        "成交量": 1203000.0,
        "成交额": 1839180.0
    },
    {
        "序号": 4056,
        "symbol": "08622",
        "name": "华康生物医学",
        "最新价": 0.101,
        "涨跌额": -0.002,
        "涨跌幅": -1.94,
        "今开": 0.1,
        "最高": 0.101,
        "最低": 0.1,
        "昨收": 0.103,
        "成交量": 320000.0,
        "成交额": 32216.0
    },
    {
        "序号": 4057,
        "symbol": "00377",
        "name": "中国华君",
        "最新价": 1.01,
        "涨跌额": -0.02,
        "涨跌幅": -1.94,
        "今开": 1.29,
        "最高": 1.29,
        "最低": 1.01,
        "昨收": 1.03,
        "成交量": 4560.0,
        "成交额": 5209.0
    },
    {
        "序号": 4058,
        "symbol": "00178",
        "name": "莎莎国际",
        "最新价": 1.01,
        "涨跌额": -0.02,
        "涨跌幅": -1.94,
        "今开": 1.03,
        "最高": 1.03,
        "最低": 1.01,
        "昨收": 1.03,
        "成交量": 1750000.0,
        "成交额": 1781670.0
    },
    {
        "序号": 4059,
        "symbol": "02778",
        "name": "冠君产业信托",
        "最新价": 2.52,
        "涨跌额": -0.05,
        "涨跌幅": -1.95,
        "今开": 2.57,
        "最高": 2.57,
        "最低": 2.49,
        "昨收": 2.57,
        "成交量": 628798.0,
        "成交额": 1592628.0
    },
    {
        "序号": 4060,
        "symbol": "06098",
        "name": "碧桂园服务",
        "最新价": 6.54,
        "涨跌额": -0.13,
        "涨跌幅": -1.95,
        "今开": 6.72,
        "最高": 6.76,
        "最低": 6.53,
        "昨收": 6.67,
        "成交量": 18212333.0,
        "成交额": 120582239.0
    },
    {
        "序号": 4061,
        "symbol": "02273",
        "name": "固生堂",
        "最新价": 47.75,
        "涨跌额": -0.95,
        "涨跌幅": -1.95,
        "今开": 48.7,
        "最高": 48.7,
        "最低": 46.9,
        "昨收": 48.7,
        "成交量": 1029742.0,
        "成交额": 49052635.0
    },
    {
        "序号": 4062,
        "symbol": "08606",
        "name": "倢冠控股",
        "最新价": 0.1,
        "涨跌额": -0.002,
        "涨跌幅": -1.96,
        "今开": 0.095,
        "最高": 0.1,
        "最低": 0.095,
        "昨收": 0.102,
        "成交量": 110000.0,
        "成交额": 10500.0
    },
    {
        "序号": 4063,
        "symbol": "02212",
        "name": "高鹏矿业",
        "最新价": 0.25,
        "涨跌额": -0.005,
        "涨跌幅": -1.96,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.25,
        "昨收": 0.255,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4064,
        "symbol": "01623",
        "name": "海隆控股",
        "最新价": 0.25,
        "涨跌额": -0.005,
        "涨跌幅": -1.96,
        "今开": 0.255,
        "最高": 0.26,
        "最低": 0.241,
        "昨收": 0.255,
        "成交量": 194000.0,
        "成交额": 47824.0
    },
    {
        "序号": 4065,
        "symbol": "01108",
        "name": "凯盛新能",
        "最新价": 4.0,
        "涨跌额": -0.08,
        "涨跌幅": -1.96,
        "今开": 4.04,
        "最高": 4.09,
        "最低": 4.0,
        "昨收": 4.08,
        "成交量": 408000.0,
        "成交额": 1648420.0
    },
    {
        "序号": 4066,
        "symbol": "01050",
        "name": "嘉利国际",
        "最新价": 0.5,
        "涨跌额": -0.01,
        "涨跌幅": -1.96,
        "今开": 0.5,
        "最高": 0.51,
        "最低": 0.5,
        "昨收": 0.51,
        "成交量": 56000.0,
        "成交额": 28020.0
    },
    {
        "序号": 4067,
        "symbol": "00290",
        "name": "国富创新",
        "最新价": 0.25,
        "涨跌额": -0.005,
        "涨跌幅": -1.96,
        "今开": 0.23,
        "最高": 0.25,
        "最低": 0.23,
        "昨收": 0.255,
        "成交量": 357200.0,
        "成交额": 84509.0
    },
    {
        "序号": 4068,
        "symbol": "00709",
        "name": "佐丹奴国际",
        "最新价": 2.49,
        "涨跌额": -0.05,
        "涨跌幅": -1.97,
        "今开": 2.51,
        "最高": 2.57,
        "最低": 2.49,
        "昨收": 2.54,
        "成交量": 1105857.0,
        "成交额": 2778523.0
    },
    {
        "序号": 4069,
        "symbol": "02419",
        "name": "德康农牧",
        "最新价": 37.25,
        "涨跌额": -0.75,
        "涨跌幅": -1.97,
        "今开": 38.0,
        "最高": 38.5,
        "最低": 36.4,
        "昨收": 38.0,
        "成交量": 106500.0,
        "成交额": 3998095.0
    },
    {
        "序号": 4070,
        "symbol": "01347",
        "name": "华虹半导体",
        "最新价": 16.86,
        "涨跌额": -0.34,
        "涨跌幅": -1.98,
        "今开": 17.36,
        "最高": 17.4,
        "最低": 16.8,
        "昨收": 17.2,
        "成交量": 5402280.0,
        "成交额": 91807824.0
    },
    {
        "序号": 4071,
        "symbol": "01526",
        "name": "瑞慈医疗",
        "最新价": 0.99,
        "涨跌额": -0.02,
        "涨跌幅": -1.98,
        "今开": 0.99,
        "最高": 0.99,
        "最低": 0.97,
        "昨收": 1.01,
        "成交量": 12000.0,
        "成交额": 11820.0
    },
    {
        "序号": 4072,
        "symbol": "00673",
        "name": "中国卫生集团",
        "最新价": 0.99,
        "涨跌额": -0.02,
        "涨跌幅": -1.98,
        "今开": 1.03,
        "最高": 1.03,
        "最低": 0.99,
        "昨收": 1.01,
        "成交量": 1146000.0,
        "成交额": 1149720.0
    },
    {
        "序号": 4073,
        "symbol": "00082",
        "name": "疯狂体育",
        "最新价": 0.099,
        "涨跌额": -0.002,
        "涨跌幅": -1.98,
        "今开": 0.092,
        "最高": 0.1,
        "最低": 0.092,
        "昨收": 0.101,
        "成交量": 4285000.0,
        "成交额": 411706.0
    },
    {
        "序号": 4074,
        "symbol": "00598",
        "name": "中国外运",
        "最新价": 2.96,
        "涨跌额": -0.06,
        "涨跌幅": -1.99,
        "今开": 2.98,
        "最高": 3.02,
        "最低": 2.9,
        "昨收": 3.02,
        "成交量": 9132289.0,
        "成交额": 26950236.0
    },
    {
        "序号": 4075,
        "symbol": "00586",
        "name": "海螺创业",
        "最新价": 5.92,
        "涨跌额": -0.12,
        "涨跌幅": -1.99,
        "今开": 6.07,
        "最高": 6.09,
        "最低": 5.92,
        "昨收": 6.04,
        "成交量": 6247000.0,
        "成交额": 37447259.0
    },
    {
        "序号": 4076,
        "symbol": "00512",
        "name": "远大医药",
        "最新价": 3.94,
        "涨跌额": -0.08,
        "涨跌幅": -1.99,
        "今开": 4.04,
        "最高": 4.05,
        "最低": 3.85,
        "昨收": 4.02,
        "成交量": 7504500.0,
        "成交额": 29307795.0
    },
    {
        "序号": 4077,
        "symbol": "00929",
        "name": "国际精密",
        "最新价": 0.49,
        "涨跌额": -0.01,
        "涨跌幅": -2.0,
        "今开": 0.5,
        "最高": 0.5,
        "最低": 0.49,
        "昨收": 0.5,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4078,
        "symbol": "82333",
        "name": "长城汽车-R",
        "最新价": 9.3,
        "涨跌额": -0.19,
        "涨跌幅": -2.0,
        "今开": 9.33,
        "最高": 9.33,
        "最低": 9.26,
        "昨收": 9.49,
        "成交量": 25000.0,
        "成交额": 232375.0
    },
    {
        "序号": 4079,
        "symbol": "02269",
        "name": "药明生物",
        "最新价": 29.35,
        "涨跌额": -0.6,
        "涨跌幅": -2.0,
        "今开": 29.95,
        "最高": 30.0,
        "最低": 28.6,
        "昨收": 29.95,
        "成交量": 51164057.0,
        "成交额": 1489568352.0
    },
    {
        "序号": 4080,
        "symbol": "06819",
        "name": "中智全球",
        "最新价": 3.9,
        "涨跌额": -0.08,
        "涨跌幅": -2.01,
        "今开": 3.9,
        "最高": 3.9,
        "最低": 3.9,
        "昨收": 3.98,
        "成交量": 31000.0,
        "成交额": 120900.0
    },
    {
        "序号": 4081,
        "symbol": "01373",
        "name": "国际家居零售",
        "最新价": 1.95,
        "涨跌额": -0.04,
        "涨跌幅": -2.01,
        "今开": 2.02,
        "最高": 2.02,
        "最低": 1.94,
        "昨收": 1.99,
        "成交量": 986283.0,
        "成交额": 1933241.0
    },
    {
        "序号": 4082,
        "symbol": "00158",
        "name": "万邦投资",
        "最新价": 106.8,
        "涨跌额": -2.2,
        "涨跌幅": -2.02,
        "今开": 106.8,
        "最高": 106.8,
        "最低": 106.8,
        "昨收": 109.0,
        "成交量": 500.0,
        "成交额": 53400.0
    },
    {
        "序号": 4083,
        "symbol": "06160",
        "name": "百济神州",
        "最新价": 106.7,
        "涨跌额": -2.2,
        "涨跌幅": -2.02,
        "今开": 109.7,
        "最高": 109.7,
        "最低": 105.6,
        "昨收": 108.9,
        "成交量": 1469921.0,
        "成交额": 156925268.0
    },
    {
        "序号": 4084,
        "symbol": "01051",
        "name": "国际资源",
        "最新价": 1.94,
        "涨跌额": -0.04,
        "涨跌幅": -2.02,
        "今开": 1.9,
        "最高": 1.94,
        "最低": 1.83,
        "昨收": 1.98,
        "成交量": 130000.0,
        "成交额": 241800.0
    },
    {
        "序号": 4085,
        "symbol": "00165",
        "name": "中国光大控股",
        "最新价": 4.36,
        "涨跌额": -0.09,
        "涨跌幅": -2.02,
        "今开": 4.44,
        "最高": 4.45,
        "最低": 4.36,
        "昨收": 4.45,
        "成交量": 1177194.0,
        "成交额": 5163574.0
    },
    {
        "序号": 4086,
        "symbol": "01396",
        "name": "粤港湾控股",
        "最新价": 0.239,
        "涨跌额": -0.005,
        "涨跌幅": -2.05,
        "今开": 0.239,
        "最高": 0.239,
        "最低": 0.239,
        "昨收": 0.244,
        "成交量": 2000.0,
        "成交额": 478.0
    },
    {
        "序号": 4087,
        "symbol": "09909",
        "name": "宝龙商业",
        "最新价": 2.38,
        "涨跌额": -0.05,
        "涨跌幅": -2.06,
        "今开": 2.44,
        "最高": 2.45,
        "最低": 2.36,
        "昨收": 2.43,
        "成交量": 303500.0,
        "成交额": 728275.0
    },
    {
        "序号": 4088,
        "symbol": "06158",
        "name": "正荣地产",
        "最新价": 0.095,
        "涨跌额": -0.002,
        "涨跌幅": -2.06,
        "今开": 0.094,
        "最高": 0.097,
        "最低": 0.094,
        "昨收": 0.097,
        "成交量": 1247000.0,
        "成交额": 118574.0
    },
    {
        "序号": 4089,
        "symbol": "06178",
        "name": "光大证券",
        "最新价": 5.21,
        "涨跌额": -0.11,
        "涨跌幅": -2.07,
        "今开": 5.17,
        "最高": 5.26,
        "最低": 5.16,
        "昨收": 5.32,
        "成交量": 1244200.0,
        "成交额": 6482434.0
    },
    {
        "序号": 4090,
        "symbol": "02202",
        "name": "万科企业",
        "最新价": 7.09,
        "涨跌额": -0.15,
        "涨跌幅": -2.07,
        "今开": 7.22,
        "最高": 7.29,
        "最低": 7.07,
        "昨收": 7.24,
        "成交量": 23858400.0,
        "成交额": 170835743.0
    },
    {
        "序号": 4091,
        "symbol": "08425",
        "name": "兴铭控股",
        "最新价": 0.189,
        "涨跌额": -0.004,
        "涨跌幅": -2.07,
        "今开": 0.192,
        "最高": 0.192,
        "最低": 0.189,
        "昨收": 0.193,
        "成交量": 52000.0,
        "成交额": 9984.0
    },
    {
        "序号": 4092,
        "symbol": "02448",
        "name": "恒宇集团",
        "最新价": 0.047,
        "涨跌额": -0.001,
        "涨跌幅": -2.08,
        "今开": 0.046,
        "最高": 0.047,
        "最低": 0.046,
        "昨收": 0.048,
        "成交量": 70000.0,
        "成交额": 3262.0
    },
    {
        "序号": 4093,
        "symbol": "01335",
        "name": "顺泰控股",
        "最新价": 0.047,
        "涨跌额": -0.001,
        "涨跌幅": -2.08,
        "今开": 0.047,
        "最高": 0.048,
        "最低": 0.047,
        "昨收": 0.048,
        "成交量": 100000.0,
        "成交额": 4710.0
    },
    {
        "序号": 4094,
        "symbol": "01231",
        "name": "新矿资源",
        "最新价": 0.47,
        "涨跌额": -0.01,
        "涨跌幅": -2.08,
        "今开": 0.48,
        "最高": 0.48,
        "最低": 0.47,
        "昨收": 0.48,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4095,
        "symbol": "02192",
        "name": "医脉通",
        "最新价": 7.93,
        "涨跌额": -0.17,
        "涨跌幅": -2.1,
        "今开": 8.1,
        "最高": 8.22,
        "最低": 7.92,
        "昨收": 8.1,
        "成交量": 797000.0,
        "成交额": 6403813.0
    },
    {
        "序号": 4096,
        "symbol": "02177",
        "name": "优趣汇控股",
        "最新价": 1.39,
        "涨跌额": -0.03,
        "涨跌幅": -2.11,
        "今开": 1.41,
        "最高": 1.41,
        "最低": 1.31,
        "昨收": 1.42,
        "成交量": 10200.0,
        "成交额": 13556.0
    },
    {
        "序号": 4097,
        "symbol": "03662",
        "name": "奥园健康",
        "最新价": 0.46,
        "涨跌额": -0.01,
        "涨跌幅": -2.13,
        "今开": 0.47,
        "最高": 0.475,
        "最低": 0.46,
        "昨收": 0.47,
        "成交量": 180000.0,
        "成交额": 83815.0
    },
    {
        "序号": 4098,
        "symbol": "01971",
        "name": "弘阳服务",
        "最新价": 0.46,
        "涨跌额": -0.01,
        "涨跌幅": -2.13,
        "今开": 0.47,
        "最高": 0.47,
        "最低": 0.46,
        "昨收": 0.47,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4099,
        "symbol": "02196",
        "name": "复星医药",
        "最新价": 16.48,
        "涨跌额": -0.36,
        "涨跌幅": -2.14,
        "今开": 16.84,
        "最高": 16.9,
        "最低": 16.34,
        "昨收": 16.84,
        "成交量": 2958000.0,
        "成交额": 48771031.0
    },
    {
        "序号": 4100,
        "symbol": "02400",
        "name": "心动公司",
        "最新价": 11.88,
        "涨跌额": -0.26,
        "涨跌幅": -2.14,
        "今开": 12.28,
        "最高": 12.32,
        "最低": 11.72,
        "昨收": 12.14,
        "成交量": 2451400.0,
        "成交额": 29220776.0
    },
    {
        "序号": 4101,
        "symbol": "02689",
        "name": "玖龙纸业",
        "最新价": 3.65,
        "涨跌额": -0.08,
        "涨跌幅": -2.14,
        "今开": 3.73,
        "最高": 3.73,
        "最低": 3.63,
        "昨收": 3.73,
        "成交量": 6358800.0,
        "成交额": 23259753.0
    },
    {
        "序号": 4102,
        "symbol": "08372",
        "name": "君百延集团",
        "最新价": 0.09,
        "涨跌额": -0.002,
        "涨跌幅": -2.17,
        "今开": 0.09,
        "最高": 0.09,
        "最低": 0.09,
        "昨收": 0.092,
        "成交量": 200000.0,
        "成交额": 18000.0
    },
    {
        "序号": 4103,
        "symbol": "03650",
        "name": "KEEP",
        "最新价": 18.9,
        "涨跌额": -0.42,
        "涨跌幅": -2.17,
        "今开": 19.32,
        "最高": 20.65,
        "最低": 18.66,
        "昨收": 19.32,
        "成交量": 229200.0,
        "成交额": 4470831.0
    },
    {
        "序号": 4104,
        "symbol": "01209",
        "name": "华润万象生活",
        "最新价": 26.55,
        "涨跌额": -0.6,
        "涨跌幅": -2.21,
        "今开": 27.45,
        "最高": 27.65,
        "最低": 26.4,
        "昨收": 27.15,
        "成交量": 2467000.0,
        "成交额": 66112441.0
    },
    {
        "序号": 4105,
        "symbol": "08320",
        "name": "沛然环保",
        "最新价": 0.088,
        "涨跌额": -0.002,
        "涨跌幅": -2.22,
        "今开": 0.09,
        "最高": 0.09,
        "最低": 0.08,
        "昨收": 0.09,
        "成交量": 40000.0,
        "成交额": 3400.0
    },
    {
        "序号": 4106,
        "symbol": "06666",
        "name": "恒大物业",
        "最新价": 0.44,
        "涨跌额": -0.01,
        "涨跌幅": -2.22,
        "今开": 0.45,
        "最高": 0.465,
        "最低": 0.44,
        "昨收": 0.45,
        "成交量": 4223500.0,
        "成交额": 1891332.0
    },
    {
        "序号": 4107,
        "symbol": "02407",
        "name": "高视医疗",
        "最新价": 35.2,
        "涨跌额": -0.8,
        "涨跌幅": -2.22,
        "今开": 38.65,
        "最高": 38.7,
        "最低": 34.1,
        "昨收": 36.0,
        "成交量": 92100.0,
        "成交额": 3364750.0
    },
    {
        "序号": 4108,
        "symbol": "01417",
        "name": "浦江中国",
        "最新价": 0.44,
        "涨跌额": -0.01,
        "涨跌幅": -2.22,
        "今开": 0.44,
        "最高": 0.445,
        "最低": 0.435,
        "昨收": 0.45,
        "成交量": 22000.0,
        "成交额": 9680.0
    },
    {
        "序号": 4109,
        "symbol": "01243",
        "name": "宏安地产",
        "最新价": 0.044,
        "涨跌额": -0.001,
        "涨跌幅": -2.22,
        "今开": 0.044,
        "最高": 0.045,
        "最低": 0.044,
        "昨收": 0.045,
        "成交量": 588000.0,
        "成交额": 25956.0
    },
    {
        "序号": 4110,
        "symbol": "01150",
        "name": "米兰站",
        "最新价": 0.088,
        "涨跌额": -0.002,
        "涨跌幅": -2.22,
        "今开": 0.088,
        "最高": 0.088,
        "最低": 0.088,
        "昨收": 0.09,
        "成交量": 10000.0,
        "成交额": 880.0
    },
    {
        "序号": 4111,
        "symbol": "00459",
        "name": "鋑联控股",
        "最新价": 0.088,
        "涨跌额": -0.002,
        "涨跌幅": -2.22,
        "今开": 0.084,
        "最高": 0.088,
        "最低": 0.084,
        "昨收": 0.09,
        "成交量": 740000.0,
        "成交额": 64860.0
    },
    {
        "序号": 4112,
        "symbol": "00132",
        "name": "中国兴业控股",
        "最新价": 0.131,
        "涨跌额": -0.003,
        "涨跌幅": -2.24,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.119,
        "昨收": 0.134,
        "成交量": 61000.0,
        "成交额": 8152.0
    },
    {
        "序号": 4113,
        "symbol": "02391",
        "name": "涂鸦智能-W",
        "最新价": 16.56,
        "涨跌额": -0.38,
        "涨跌幅": -2.24,
        "今开": 16.94,
        "最高": 16.94,
        "最低": 16.54,
        "昨收": 16.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4114,
        "symbol": "02688",
        "name": "新奥能源",
        "最新价": 50.0,
        "涨跌额": -1.15,
        "涨跌幅": -2.25,
        "今开": 51.55,
        "最高": 51.55,
        "最低": 49.7,
        "昨收": 51.15,
        "成交量": 3174465.0,
        "成交额": 160980940.0
    },
    {
        "序号": 4115,
        "symbol": "08139",
        "name": "长安仁恒",
        "最新价": 1.3,
        "涨跌额": -0.03,
        "涨跌幅": -2.26,
        "今开": 1.33,
        "最高": 1.33,
        "最低": 1.26,
        "昨收": 1.33,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4116,
        "symbol": "00397",
        "name": "嬴集团",
        "最新价": 0.13,
        "涨跌额": -0.003,
        "涨跌幅": -2.26,
        "今开": 0.131,
        "最高": 0.134,
        "最低": 0.128,
        "昨收": 0.133,
        "成交量": 20580000.0,
        "成交额": 2705760.0
    },
    {
        "序号": 4117,
        "symbol": "08178",
        "name": "中国信息科技",
        "最新价": 2.15,
        "涨跌额": -0.05,
        "涨跌幅": -2.27,
        "今开": 2.17,
        "最高": 2.17,
        "最低": 2.08,
        "昨收": 2.2,
        "成交量": 138000.0,
        "成交额": 295660.0
    },
    {
        "序号": 4118,
        "symbol": "01508",
        "name": "中国再保险",
        "最新价": 0.43,
        "涨跌额": -0.01,
        "涨跌幅": -2.27,
        "今开": 0.435,
        "最高": 0.435,
        "最低": 0.43,
        "昨收": 0.44,
        "成交量": 3855000.0,
        "成交额": 1670423.0
    },
    {
        "序号": 4119,
        "symbol": "01382",
        "name": "互太纺织",
        "最新价": 1.29,
        "涨跌额": -0.03,
        "涨跌幅": -2.27,
        "今开": 1.32,
        "最高": 1.32,
        "最低": 1.29,
        "昨收": 1.32,
        "成交量": 1412000.0,
        "成交额": 1840630.0
    },
    {
        "序号": 4120,
        "symbol": "09666",
        "name": "金科服务",
        "最新价": 8.98,
        "涨跌额": -0.21,
        "涨跌幅": -2.29,
        "今开": 9.19,
        "最高": 9.21,
        "最低": 8.89,
        "昨收": 9.19,
        "成交量": 805800.0,
        "成交额": 7276519.0
    },
    {
        "序号": 4121,
        "symbol": "01870",
        "name": "益美国际控股",
        "最新价": 1.71,
        "涨跌额": -0.04,
        "涨跌幅": -2.29,
        "今开": 1.77,
        "最高": 1.77,
        "最低": 1.7,
        "昨收": 1.75,
        "成交量": 405000.0,
        "成交额": 694950.0
    },
    {
        "序号": 4122,
        "symbol": "01854",
        "name": "中国万天控股",
        "最新价": 1.28,
        "涨跌额": -0.03,
        "涨跌幅": -2.29,
        "今开": 1.31,
        "最高": 1.31,
        "最低": 1.22,
        "昨收": 1.31,
        "成交量": 10330000.0,
        "成交额": 13165200.0
    },
    {
        "序号": 4123,
        "symbol": "83118",
        "name": "嘉实明晟A股-R",
        "最新价": 12.36,
        "涨跌额": -0.29,
        "涨跌幅": -2.29,
        "今开": 12.65,
        "最高": 12.65,
        "最低": 12.29,
        "昨收": 12.65,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4124,
        "symbol": "07522",
        "name": "华夏两倍看空纳斯达克100",
        "最新价": 1.276,
        "涨跌额": -0.03,
        "涨跌幅": -2.3,
        "今开": 1.308,
        "最高": 1.308,
        "最低": 1.272,
        "昨收": 1.306,
        "成交量": 2116400.0,
        "成交额": 2699574.0
    },
    {
        "序号": 4125,
        "symbol": "02057",
        "name": "中通快递-W",
        "最新价": 164.1,
        "涨跌额": -3.9,
        "涨跌幅": -2.32,
        "今开": 166.6,
        "最高": 166.6,
        "最低": 163.6,
        "昨收": 168.0,
        "成交量": 160050.0,
        "成交额": 26310102.0
    },
    {
        "序号": 4126,
        "symbol": "08066",
        "name": "品创控股",
        "最新价": 0.042,
        "涨跌额": -0.001,
        "涨跌幅": -2.33,
        "今开": 0.043,
        "最高": 0.043,
        "最低": 0.042,
        "昨收": 0.043,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4127,
        "symbol": "06036",
        "name": "光丽科技",
        "最新价": 0.42,
        "涨跌额": -0.01,
        "涨跌幅": -2.33,
        "今开": 0.415,
        "最高": 0.45,
        "最低": 0.415,
        "昨收": 0.43,
        "成交量": 1005000.0,
        "成交额": 443825.0
    },
    {
        "序号": 4128,
        "symbol": "02483",
        "name": "K CASH集团",
        "最新价": 1.68,
        "涨跌额": -0.04,
        "涨跌幅": -2.33,
        "今开": 1.73,
        "最高": 1.78,
        "最低": 1.68,
        "昨收": 1.72,
        "成交量": 1858000.0,
        "成交额": 3158640.0
    },
    {
        "序号": 4129,
        "symbol": "01346",
        "name": "利华控股集团",
        "最新价": 0.84,
        "涨跌额": -0.02,
        "涨跌幅": -2.33,
        "今开": 0.84,
        "最高": 0.84,
        "最低": 0.84,
        "昨收": 0.86,
        "成交量": 8000.0,
        "成交额": 6720.0
    },
    {
        "序号": 4130,
        "symbol": "01221",
        "name": "SINO HOTELS",
        "最新价": 1.68,
        "涨跌额": -0.04,
        "涨跌幅": -2.33,
        "今开": 1.68,
        "最高": 1.68,
        "最低": 1.68,
        "昨收": 1.72,
        "成交量": 3040.0,
        "成交额": 5044.0
    },
    {
        "序号": 4131,
        "symbol": "01134",
        "name": "恒发光学",
        "最新价": 0.42,
        "涨跌额": -0.01,
        "涨跌幅": -2.33,
        "今开": 0.415,
        "最高": 0.42,
        "最低": 0.405,
        "昨收": 0.43,
        "成交量": 252000.0,
        "成交额": 103740.0
    },
    {
        "序号": 4132,
        "symbol": "09896",
        "name": "名创优品",
        "最新价": 39.85,
        "涨跌额": -0.95,
        "涨跌幅": -2.33,
        "今开": 40.75,
        "最高": 40.75,
        "最低": 39.5,
        "昨收": 40.8,
        "成交量": 2875700.0,
        "成交额": 114655645.0
    },
    {
        "序号": 4133,
        "symbol": "07568",
        "name": "南方两倍做空纳斯达克100",
        "最新价": 8.59,
        "涨跌额": -0.205,
        "涨跌幅": -2.33,
        "今开": 8.57,
        "最高": 8.59,
        "最低": 8.55,
        "昨收": 8.795,
        "成交量": 1193900.0,
        "成交额": 10225520.0
    },
    {
        "序号": 4134,
        "symbol": "02319",
        "name": "蒙牛乳业",
        "最新价": 20.9,
        "涨跌额": -0.5,
        "涨跌幅": -2.34,
        "今开": 21.25,
        "最高": 21.5,
        "最低": 20.75,
        "昨收": 21.4,
        "成交量": 16613136.0,
        "成交额": 350553328.0
    },
    {
        "序号": 4135,
        "symbol": "06127",
        "name": "昭衍新药",
        "最新价": 11.68,
        "涨跌额": -0.28,
        "涨跌幅": -2.34,
        "今开": 12.1,
        "最高": 12.1,
        "最低": 11.52,
        "昨收": 11.96,
        "成交量": 951395.0,
        "成交额": 11148643.0
    },
    {
        "序号": 4136,
        "symbol": "02607",
        "name": "上海医药",
        "最新价": 10.84,
        "涨跌额": -0.26,
        "涨跌幅": -2.34,
        "今开": 11.0,
        "最高": 11.06,
        "最低": 10.84,
        "昨收": 11.1,
        "成交量": 1861700.0,
        "成交额": 20293101.0
    },
    {
        "序号": 4137,
        "symbol": "00239",
        "name": "白花油",
        "最新价": 1.66,
        "涨跌额": -0.04,
        "涨跌幅": -2.35,
        "今开": 1.69,
        "最高": 1.69,
        "最低": 1.62,
        "昨收": 1.7,
        "成交量": 245000.0,
        "成交额": 406150.0
    },
    {
        "序号": 4138,
        "symbol": "01890",
        "name": "中国科培",
        "最新价": 1.24,
        "涨跌额": -0.03,
        "涨跌幅": -2.36,
        "今开": 1.27,
        "最高": 1.29,
        "最低": 1.23,
        "昨收": 1.27,
        "成交量": 4662000.0,
        "成交额": 5821840.0
    },
    {
        "序号": 4139,
        "symbol": "00587",
        "name": "海螺环保",
        "最新价": 1.65,
        "涨跌额": -0.04,
        "涨跌幅": -2.37,
        "今开": 1.68,
        "最高": 1.72,
        "最低": 1.64,
        "昨收": 1.69,
        "成交量": 806000.0,
        "成交额": 1356840.0
    },
    {
        "序号": 4140,
        "symbol": "03833",
        "name": "新疆新鑫矿业",
        "最新价": 0.82,
        "涨跌额": -0.02,
        "涨跌幅": -2.38,
        "今开": 0.82,
        "最高": 0.82,
        "最低": 0.82,
        "昨收": 0.84,
        "成交量": 75000.0,
        "成交额": 61500.0
    },
    {
        "序号": 4141,
        "symbol": "03822",
        "name": "三和建筑集团",
        "最新价": 0.041,
        "涨跌额": -0.001,
        "涨跌幅": -2.38,
        "今开": 0.038,
        "最高": 0.041,
        "最低": 0.038,
        "昨收": 0.042,
        "成交量": 16000.0,
        "成交额": 620.0
    },
    {
        "序号": 4142,
        "symbol": "01341",
        "name": "昊天国际建投",
        "最新价": 1.23,
        "涨跌额": -0.03,
        "涨跌幅": -2.38,
        "今开": 1.25,
        "最高": 1.27,
        "最低": 1.21,
        "昨收": 1.26,
        "成交量": 4656000.0,
        "成交额": 5761280.0
    },
    {
        "序号": 4143,
        "symbol": "02218",
        "name": "安德利果汁",
        "最新价": 6.93,
        "涨跌额": -0.17,
        "涨跌幅": -2.39,
        "今开": 6.93,
        "最高": 6.95,
        "最低": 6.81,
        "昨收": 7.1,
        "成交量": 13500.0,
        "成交额": 93090.0
    },
    {
        "序号": 4144,
        "symbol": "01866",
        "name": "中国心连心化肥",
        "最新价": 3.26,
        "涨跌额": -0.08,
        "涨跌幅": -2.4,
        "今开": 3.35,
        "最高": 3.35,
        "最低": 3.26,
        "昨收": 3.34,
        "成交量": 260000.0,
        "成交额": 861530.0
    },
    {
        "序号": 4145,
        "symbol": "06066",
        "name": "中信建投证券",
        "最新价": 6.91,
        "涨跌额": -0.17,
        "涨跌幅": -2.4,
        "今开": 7.08,
        "最高": 7.08,
        "最低": 6.85,
        "昨收": 7.08,
        "成交量": 3802000.0,
        "成交额": 26291546.0
    },
    {
        "序号": 4146,
        "symbol": "01062",
        "name": "国开国际投资",
        "最新价": 0.081,
        "涨跌额": -0.002,
        "涨跌幅": -2.41,
        "今开": 0.076,
        "最高": 0.081,
        "最低": 0.076,
        "昨收": 0.083,
        "成交量": 220000.0,
        "成交额": 16810.0
    },
    {
        "序号": 4147,
        "symbol": "00596",
        "name": "浪潮数字企业",
        "最新价": 2.02,
        "涨跌额": -0.05,
        "涨跌幅": -2.42,
        "今开": 2.13,
        "最高": 2.13,
        "最低": 2.0,
        "昨收": 2.07,
        "成交量": 2800000.0,
        "成交额": 5710062.0
    },
    {
        "序号": 4148,
        "symbol": "01858",
        "name": "春立医疗",
        "最新价": 12.1,
        "涨跌额": -0.3,
        "涨跌幅": -2.42,
        "今开": 12.5,
        "最高": 12.54,
        "最低": 12.1,
        "昨收": 12.4,
        "成交量": 194250.0,
        "成交额": 2376630.0
    },
    {
        "序号": 4149,
        "symbol": "00558",
        "name": "力劲科技",
        "最新价": 5.21,
        "涨跌额": -0.13,
        "涨跌幅": -2.43,
        "今开": 5.37,
        "最高": 5.37,
        "最低": 5.14,
        "昨收": 5.34,
        "成交量": 2163750.0,
        "成交额": 11363700.0
    },
    {
        "序号": 4150,
        "symbol": "09958",
        "name": "力天影业",
        "最新价": 1.2,
        "涨跌额": -0.03,
        "涨跌幅": -2.44,
        "今开": 1.23,
        "最高": 1.23,
        "最低": 1.05,
        "昨收": 1.23,
        "成交量": 18000.0,
        "成交额": 21900.0
    },
    {
        "序号": 4151,
        "symbol": "06893",
        "name": "衍生集团",
        "最新价": 0.4,
        "涨跌额": -0.01,
        "涨跌幅": -2.44,
        "今开": 0.41,
        "最高": 0.41,
        "最低": 0.4,
        "昨收": 0.41,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4152,
        "symbol": "01907",
        "name": "中国旭阳集团",
        "最新价": 3.2,
        "涨跌额": -0.08,
        "涨跌幅": -2.44,
        "今开": 3.28,
        "最高": 3.29,
        "最低": 3.18,
        "昨收": 3.28,
        "成交量": 3299000.0,
        "成交额": 10601395.0
    },
    {
        "序号": 4153,
        "symbol": "01741",
        "name": "日赢控股",
        "最新价": 0.8,
        "涨跌额": -0.02,
        "涨跌幅": -2.44,
        "今开": 0.8,
        "最高": 0.83,
        "最低": 0.8,
        "昨收": 0.82,
        "成交量": 3476000.0,
        "成交额": 2802880.0
    },
    {
        "序号": 4154,
        "symbol": "01575",
        "name": "慕容家居",
        "最新价": 0.16,
        "涨跌额": -0.004,
        "涨跌幅": -2.44,
        "今开": 0.156,
        "最高": 0.16,
        "最低": 0.153,
        "昨收": 0.164,
        "成交量": 358000.0,
        "成交额": 55672.0
    },
    {
        "序号": 4155,
        "symbol": "02600",
        "name": "中国铝业",
        "最新价": 3.56,
        "涨跌额": -0.09,
        "涨跌幅": -2.47,
        "今开": 3.65,
        "最高": 3.65,
        "最低": 3.55,
        "昨收": 3.65,
        "成交量": 14503615.0,
        "成交额": 51814800.0
    },
    {
        "序号": 4156,
        "symbol": "06669",
        "name": "先瑞达医疗-B",
        "最新价": 11.86,
        "涨跌额": -0.3,
        "涨跌幅": -2.47,
        "今开": 11.84,
        "最高": 12.44,
        "最低": 11.8,
        "昨收": 12.16,
        "成交量": 208268.0,
        "成交额": 2522253.0
    },
    {
        "序号": 4157,
        "symbol": "01258",
        "name": "中国有色矿业",
        "最新价": 4.68,
        "涨跌额": -0.12,
        "涨跌幅": -2.5,
        "今开": 4.8,
        "最高": 4.81,
        "最低": 4.65,
        "昨收": 4.8,
        "成交量": 1862000.0,
        "成交额": 8731080.0
    },
    {
        "序号": 4158,
        "symbol": "00610",
        "name": "WAI KEE HOLD",
        "最新价": 1.17,
        "涨跌额": -0.03,
        "涨跌幅": -2.5,
        "今开": 1.17,
        "最高": 1.19,
        "最低": 1.12,
        "昨收": 1.2,
        "成交量": 32000.0,
        "成交额": 37200.0
    },
    {
        "序号": 4159,
        "symbol": "02869",
        "name": "绿城服务",
        "最新价": 2.72,
        "涨跌额": -0.07,
        "涨跌幅": -2.51,
        "今开": 2.83,
        "最高": 2.83,
        "最低": 2.7,
        "昨收": 2.79,
        "成交量": 4602000.0,
        "成交额": 12607420.0
    },
    {
        "序号": 4160,
        "symbol": "00564",
        "name": "郑煤机",
        "最新价": 7.38,
        "涨跌额": -0.19,
        "涨跌幅": -2.51,
        "今开": 7.42,
        "最高": 7.44,
        "最低": 7.35,
        "昨收": 7.57,
        "成交量": 366200.0,
        "成交额": 2708846.0
    },
    {
        "序号": 4161,
        "symbol": "06993",
        "name": "蓝月亮集团",
        "最新价": 1.94,
        "涨跌额": -0.05,
        "涨跌幅": -2.51,
        "今开": 1.99,
        "最高": 1.99,
        "最低": 1.92,
        "昨收": 1.99,
        "成交量": 404500.0,
        "成交额": 786920.0
    },
    {
        "序号": 4162,
        "symbol": "08201",
        "name": "宝联控股",
        "最新价": 0.077,
        "涨跌额": -0.002,
        "涨跌幅": -2.53,
        "今开": 0.079,
        "最高": 0.079,
        "最低": 0.077,
        "昨收": 0.079,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4163,
        "symbol": "00659",
        "name": "新创建集团",
        "最新价": 6.93,
        "涨跌额": -0.18,
        "涨跌幅": -2.53,
        "今开": 7.07,
        "最高": 7.14,
        "最低": 6.9,
        "昨收": 7.11,
        "成交量": 778000.0,
        "成交额": 5440415.0
    },
    {
        "序号": 4164,
        "symbol": "09878",
        "name": "汇通达网络",
        "最新价": 25.0,
        "涨跌额": -0.65,
        "涨跌幅": -2.53,
        "今开": 25.6,
        "最高": 26.15,
        "最低": 24.3,
        "昨收": 25.65,
        "成交量": 1638400.0,
        "成交额": 40968675.0
    },
    {
        "序号": 4165,
        "symbol": "00405",
        "name": "越秀房产信托基金",
        "最新价": 1.15,
        "涨跌额": -0.03,
        "涨跌幅": -2.54,
        "今开": 1.18,
        "最高": 1.18,
        "最低": 1.14,
        "昨收": 1.18,
        "成交量": 3376355.0,
        "成交额": 3876351.0
    },
    {
        "序号": 4166,
        "symbol": "00726",
        "name": "筑友智造科技",
        "最新价": 0.153,
        "涨跌额": -0.004,
        "涨跌幅": -2.55,
        "今开": 0.155,
        "最高": 0.16,
        "最低": 0.152,
        "昨收": 0.157,
        "成交量": 145000.0,
        "成交额": 22250.0
    },
    {
        "序号": 4167,
        "symbol": "02337",
        "name": "众诚能源",
        "最新价": 4.95,
        "涨跌额": -0.13,
        "涨跌幅": -2.56,
        "今开": 5.1,
        "最高": 5.25,
        "最低": 4.86,
        "昨收": 5.08,
        "成交量": 515000.0,
        "成交额": 2591280.0
    },
    {
        "序号": 4168,
        "symbol": "01310",
        "name": "香港宽频",
        "最新价": 3.42,
        "涨跌额": -0.09,
        "涨跌幅": -2.56,
        "今开": 3.49,
        "最高": 3.5,
        "最低": 3.42,
        "昨收": 3.51,
        "成交量": 2450995.0,
        "成交额": 8479368.0
    },
    {
        "序号": 4169,
        "symbol": "03319",
        "name": "雅生活服务",
        "最新价": 3.41,
        "涨跌额": -0.09,
        "涨跌幅": -2.57,
        "今开": 3.56,
        "最高": 3.56,
        "最低": 3.4,
        "昨收": 3.5,
        "成交量": 6821750.0,
        "成交额": 23487103.0
    },
    {
        "序号": 4170,
        "symbol": "00867",
        "name": "康哲药业",
        "最新价": 13.6,
        "涨跌额": -0.36,
        "涨跌幅": -2.58,
        "今开": 13.8,
        "最高": 13.92,
        "最低": 13.46,
        "昨收": 13.96,
        "成交量": 7053144.0,
        "成交额": 96021321.0
    },
    {
        "序号": 4171,
        "symbol": "00360",
        "name": "新焦点",
        "最新价": 0.226,
        "涨跌额": -0.006,
        "涨跌幅": -2.59,
        "今开": 0.232,
        "最高": 0.232,
        "最低": 0.213,
        "昨收": 0.232,
        "成交量": 22988000.0,
        "成交额": 5127924.0
    },
    {
        "序号": 4172,
        "symbol": "02480",
        "name": "绿竹生物-B",
        "最新价": 30.0,
        "涨跌额": -0.8,
        "涨跌幅": -2.6,
        "今开": 31.3,
        "最高": 31.8,
        "最低": 30.0,
        "昨收": 30.8,
        "成交量": 38200.0,
        "成交额": 1188610.0
    },
    {
        "序号": 4173,
        "symbol": "00915",
        "name": "道和环球",
        "最新价": 0.075,
        "涨跌额": -0.002,
        "涨跌幅": -2.6,
        "今开": 0.077,
        "最高": 0.077,
        "最低": 0.075,
        "昨收": 0.077,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4174,
        "symbol": "00474",
        "name": "信铭生命科技",
        "最新价": 0.15,
        "涨跌额": -0.004,
        "涨跌幅": -2.6,
        "今开": 0.15,
        "最高": 0.15,
        "最低": 0.15,
        "昨收": 0.154,
        "成交量": 9593.0,
        "成交额": 1403.0
    },
    {
        "序号": 4175,
        "symbol": "00468",
        "name": "纷美包装",
        "最新价": 1.5,
        "涨跌额": -0.04,
        "涨跌幅": -2.6,
        "今开": 1.55,
        "最高": 1.56,
        "最低": 1.5,
        "昨收": 1.54,
        "成交量": 1962000.0,
        "成交额": 2986700.0
    },
    {
        "序号": 4176,
        "symbol": "01877",
        "name": "君实生物",
        "最新价": 20.6,
        "涨跌额": -0.55,
        "涨跌幅": -2.6,
        "今开": 21.1,
        "最高": 21.45,
        "最低": 20.55,
        "昨收": 21.15,
        "成交量": 634200.0,
        "成交额": 13271300.0
    },
    {
        "序号": 4177,
        "symbol": "00038",
        "name": "第一拖拉机股份",
        "最新价": 4.49,
        "涨跌额": -0.12,
        "涨跌幅": -2.6,
        "今开": 4.64,
        "最高": 4.73,
        "最低": 4.48,
        "昨收": 4.61,
        "成交量": 1998000.0,
        "成交额": 9157986.0
    },
    {
        "序号": 4178,
        "symbol": "08047",
        "name": "中国海洋发展",
        "最新价": 0.074,
        "涨跌额": -0.002,
        "涨跌幅": -2.63,
        "今开": 0.079,
        "最高": 0.079,
        "最低": 0.07,
        "昨收": 0.076,
        "成交量": 384000.0,
        "成交额": 27276.0
    },
    {
        "序号": 4179,
        "symbol": "06189",
        "name": "爱得威建设集团",
        "最新价": 0.37,
        "涨跌额": -0.01,
        "涨跌幅": -2.63,
        "今开": 0.395,
        "最高": 0.395,
        "最低": 0.36,
        "昨收": 0.38,
        "成交量": 34000.0,
        "成交额": 13005.0
    },
    {
        "序号": 4180,
        "symbol": "01289",
        "name": "盛力达科技",
        "最新价": 0.74,
        "涨跌额": -0.02,
        "涨跌幅": -2.63,
        "今开": 0.74,
        "最高": 0.74,
        "最低": 0.74,
        "昨收": 0.76,
        "成交量": 2000.0,
        "成交额": 1480.0
    },
    {
        "序号": 4181,
        "symbol": "01025",
        "name": "嘉艺控股",
        "最新价": 0.37,
        "涨跌额": -0.01,
        "涨跌幅": -2.63,
        "今开": 0.37,
        "最高": 0.37,
        "最低": 0.37,
        "昨收": 0.38,
        "成交量": 24000.0,
        "成交额": 8880.0
    },
    {
        "序号": 4182,
        "symbol": "00023",
        "name": "东亚银行",
        "最新价": 9.25,
        "涨跌额": -0.25,
        "涨跌幅": -2.63,
        "今开": 9.46,
        "最高": 9.55,
        "最低": 9.21,
        "昨收": 9.5,
        "成交量": 1388958.0,
        "成交额": 12957460.0
    },
    {
        "序号": 4183,
        "symbol": "03931",
        "name": "中创新航",
        "最新价": 18.36,
        "涨跌额": -0.5,
        "涨跌幅": -2.65,
        "今开": 18.5,
        "最高": 18.58,
        "最低": 18.2,
        "昨收": 18.86,
        "成交量": 59200.0,
        "成交额": 1086716.0
    },
    {
        "序号": 4184,
        "symbol": "00329",
        "name": "东建国际",
        "最新价": 0.365,
        "涨跌额": -0.01,
        "涨跌幅": -2.67,
        "今开": 0.37,
        "最高": 0.37,
        "最低": 0.35,
        "昨收": 0.375,
        "成交量": 53109.0,
        "成交额": 19281.0
    },
    {
        "序号": 4185,
        "symbol": "00224",
        "name": "建生国际",
        "最新价": 0.73,
        "涨跌额": -0.02,
        "涨跌幅": -2.67,
        "今开": 0.75,
        "最高": 0.75,
        "最低": 0.73,
        "昨收": 0.75,
        "成交量": 42000.0,
        "成交额": 30980.0
    },
    {
        "序号": 4186,
        "symbol": "01880",
        "name": "中国中免",
        "最新价": 75.7,
        "涨跌额": -2.1,
        "涨跌幅": -2.7,
        "今开": 77.75,
        "最高": 78.4,
        "最低": 75.4,
        "昨收": 77.8,
        "成交量": 685031.0,
        "成交额": 52264963.0
    },
    {
        "序号": 4187,
        "symbol": "02429",
        "name": "友宝在线",
        "最新价": 14.4,
        "涨跌额": -0.4,
        "涨跌幅": -2.7,
        "今开": 14.64,
        "最高": 15.0,
        "最低": 14.24,
        "昨收": 14.8,
        "成交量": 306500.0,
        "成交额": 4444990.0
    },
    {
        "序号": 4188,
        "symbol": "01252",
        "name": "中国天瑞水泥",
        "最新价": 5.01,
        "涨跌额": -0.14,
        "涨跌幅": -2.72,
        "今开": 5.15,
        "最高": 5.15,
        "最低": 4.62,
        "昨收": 5.15,
        "成交量": 1627000.0,
        "成交额": 8155510.0
    },
    {
        "序号": 4189,
        "symbol": "03613",
        "name": "同仁堂国药",
        "最新价": 12.16,
        "涨跌额": -0.34,
        "涨跌幅": -2.72,
        "今开": 12.52,
        "最高": 12.52,
        "最低": 12.08,
        "昨收": 12.5,
        "成交量": 1581000.0,
        "成交额": 19295960.0
    },
    {
        "序号": 4190,
        "symbol": "02068",
        "name": "中铝国际",
        "最新价": 1.43,
        "涨跌额": -0.04,
        "涨跌幅": -2.72,
        "今开": 1.45,
        "最高": 1.46,
        "最低": 1.43,
        "昨收": 1.47,
        "成交量": 739000.0,
        "成交额": 1063130.0
    },
    {
        "序号": 4191,
        "symbol": "01565",
        "name": "成实外教育",
        "最新价": 0.142,
        "涨跌额": -0.004,
        "涨跌幅": -2.74,
        "今开": 0.145,
        "最高": 0.149,
        "最低": 0.136,
        "昨收": 0.146,
        "成交量": 2674000.0,
        "成交额": 378753.0
    },
    {
        "序号": 4192,
        "symbol": "06660",
        "name": "艾美疫苗",
        "最新价": 8.15,
        "涨跌额": -0.23,
        "涨跌幅": -2.74,
        "今开": 8.53,
        "最高": 8.53,
        "最低": 8.15,
        "昨收": 8.38,
        "成交量": 639400.0,
        "成交额": 5256490.0
    },
    {
        "序号": 4193,
        "symbol": "06186",
        "name": "中国飞鹤",
        "最新价": 4.23,
        "涨跌额": -0.12,
        "涨跌幅": -2.76,
        "今开": 4.35,
        "最高": 4.35,
        "最低": 4.18,
        "昨收": 4.35,
        "成交量": 15418381.0,
        "成交额": 65418811.0
    },
    {
        "序号": 4194,
        "symbol": "06878",
        "name": "鼎丰集团汽车",
        "最新价": 0.07,
        "涨跌额": -0.002,
        "涨跌幅": -2.78,
        "今开": 0.073,
        "最高": 0.076,
        "最低": 0.069,
        "昨收": 0.072,
        "成交量": 1720886000.0,
        "成交额": 125206612.0
    },
    {
        "序号": 4195,
        "symbol": "01843",
        "name": "快餐帝国",
        "最新价": 0.21,
        "涨跌额": -0.006,
        "涨跌幅": -2.78,
        "今开": 0.215,
        "最高": 0.215,
        "最低": 0.2,
        "昨收": 0.216,
        "成交量": 428573.0,
        "成交额": 88486.0
    },
    {
        "序号": 4196,
        "symbol": "01737",
        "name": "亚洲实业集团",
        "最新价": 0.105,
        "涨跌额": -0.003,
        "涨跌幅": -2.78,
        "今开": 0.105,
        "最高": 0.105,
        "最低": 0.105,
        "昨收": 0.108,
        "成交量": 160000.0,
        "成交额": 16800.0
    },
    {
        "序号": 4197,
        "symbol": "00672",
        "name": "众安集团",
        "最新价": 0.105,
        "涨跌额": -0.003,
        "涨跌幅": -2.78,
        "今开": 0.103,
        "最高": 0.108,
        "最低": 0.102,
        "昨收": 0.108,
        "成交量": 1422400.0,
        "成交额": 146230.0
    },
    {
        "序号": 4198,
        "symbol": "02015",
        "name": "理想汽车-W",
        "最新价": 135.7,
        "涨跌额": -3.9,
        "涨跌幅": -2.79,
        "今开": 138.0,
        "最高": 138.4,
        "最低": 133.2,
        "昨收": 139.6,
        "成交量": 7275012.0,
        "成交额": 984682752.0
    },
    {
        "序号": 4199,
        "symbol": "00828",
        "name": "王朝酒业",
        "最新价": 0.243,
        "涨跌额": -0.007,
        "涨跌幅": -2.8,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.242,
        "昨收": 0.25,
        "成交量": 334000.0,
        "成交额": 81716.0
    },
    {
        "序号": 4200,
        "symbol": "02256",
        "name": "和誉-B",
        "最新价": 3.45,
        "涨跌额": -0.1,
        "涨跌幅": -2.82,
        "今开": 3.61,
        "最高": 3.61,
        "最低": 3.38,
        "昨收": 3.55,
        "成交量": 4896000.0,
        "成交额": 17198605.0
    },
    {
        "序号": 4201,
        "symbol": "00422",
        "name": "越南制造加工出口",
        "最新价": 0.69,
        "涨跌额": -0.02,
        "涨跌幅": -2.82,
        "今开": 0.7,
        "最高": 0.7,
        "最低": 0.68,
        "昨收": 0.71,
        "成交量": 202000.0,
        "成交额": 138280.0
    },
    {
        "序号": 4202,
        "symbol": "00007",
        "name": "智富资源投资",
        "最新价": 0.069,
        "涨跌额": -0.002,
        "涨跌幅": -2.82,
        "今开": 0.071,
        "最高": 0.071,
        "最低": 0.064,
        "昨收": 0.071,
        "成交量": 800000.0,
        "成交额": 53850.0
    },
    {
        "序号": 4203,
        "symbol": "00133",
        "name": "招商局中国基金",
        "最新价": 7.58,
        "涨跌额": -0.22,
        "涨跌幅": -2.82,
        "今开": 7.79,
        "最高": 7.85,
        "最低": 7.56,
        "昨收": 7.8,
        "成交量": 176000.0,
        "成交额": 1348480.0
    },
    {
        "序号": 4204,
        "symbol": "03311",
        "name": "中国建筑国际",
        "最新价": 8.61,
        "涨跌额": -0.25,
        "涨跌幅": -2.82,
        "今开": 8.86,
        "最高": 8.86,
        "最低": 8.52,
        "昨收": 8.86,
        "成交量": 3749317.0,
        "成交额": 32313420.0
    },
    {
        "序号": 4205,
        "symbol": "01349",
        "name": "复旦张江",
        "最新价": 2.41,
        "涨跌额": -0.07,
        "涨跌幅": -2.82,
        "今开": 2.48,
        "最高": 2.48,
        "最低": 2.41,
        "昨收": 2.48,
        "成交量": 696000.0,
        "成交额": 1687210.0
    },
    {
        "序号": 4206,
        "symbol": "01111",
        "name": "华视集团控股",
        "最新价": 2.06,
        "涨跌额": -0.06,
        "涨跌幅": -2.83,
        "今开": 2.09,
        "最高": 2.14,
        "最低": 2.06,
        "昨收": 2.12,
        "成交量": 5648000.0,
        "成交额": 11787440.0
    },
    {
        "序号": 4207,
        "symbol": "01738",
        "name": "飞尚无烟煤",
        "最新价": 0.24,
        "涨跌额": -0.007,
        "涨跌幅": -2.83,
        "今开": 0.247,
        "最高": 0.247,
        "最低": 0.24,
        "昨收": 0.247,
        "成交量": 32500.0,
        "成交额": 7987.0
    },
    {
        "序号": 4208,
        "symbol": "00179",
        "name": "德昌电机控股",
        "最新价": 11.64,
        "涨跌额": -0.34,
        "涨跌幅": -2.84,
        "今开": 11.84,
        "最高": 11.84,
        "最低": 11.38,
        "昨收": 11.98,
        "成交量": 694000.0,
        "成交额": 8068234.0
    },
    {
        "序号": 4209,
        "symbol": "03380",
        "name": "龙光集团",
        "最新价": 0.68,
        "涨跌额": -0.02,
        "涨跌幅": -2.86,
        "今开": 0.69,
        "最高": 0.7,
        "最低": 0.68,
        "昨收": 0.7,
        "成交量": 5137202.0,
        "成交额": 3533451.0
    },
    {
        "序号": 4210,
        "symbol": "02668",
        "name": "百德国际",
        "最新价": 0.17,
        "涨跌额": -0.005,
        "涨跌幅": -2.86,
        "今开": 0.185,
        "最高": 0.198,
        "最低": 0.16,
        "昨收": 0.175,
        "成交量": 5340000.0,
        "成交额": 968970.0
    },
    {
        "序号": 4211,
        "symbol": "02250",
        "name": "小黄鸭德盈",
        "最新价": 1.36,
        "涨跌额": -0.04,
        "涨跌幅": -2.86,
        "今开": 1.39,
        "最高": 1.39,
        "最低": 1.35,
        "昨收": 1.4,
        "成交量": 867000.0,
        "成交额": 1187660.0
    },
    {
        "序号": 4212,
        "symbol": "01643",
        "name": "现代中药集团",
        "最新价": 0.34,
        "涨跌额": -0.01,
        "涨跌幅": -2.86,
        "今开": 0.34,
        "最高": 0.34,
        "最低": 0.34,
        "昨收": 0.35,
        "成交量": 30000.0,
        "成交额": 10200.0
    },
    {
        "序号": 4213,
        "symbol": "01312",
        "name": "华控康泰",
        "最新价": 0.034,
        "涨跌额": -0.001,
        "涨跌幅": -2.86,
        "今开": 0.035,
        "最高": 0.035,
        "最低": 0.034,
        "昨收": 0.035,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4214,
        "symbol": "00713",
        "name": "世界(集团)",
        "最新价": 0.34,
        "涨跌额": -0.01,
        "涨跌幅": -2.86,
        "今开": 0.34,
        "最高": 0.34,
        "最低": 0.34,
        "昨收": 0.35,
        "成交量": 150000.0,
        "成交额": 51000.0
    },
    {
        "序号": 4215,
        "symbol": "03320",
        "name": "华润医药",
        "最新价": 4.73,
        "涨跌额": -0.14,
        "涨跌幅": -2.87,
        "今开": 4.92,
        "最高": 4.92,
        "最低": 4.73,
        "昨收": 4.87,
        "成交量": 7770200.0,
        "成交额": 37109104.0
    },
    {
        "序号": 4216,
        "symbol": "01717",
        "name": "澳优",
        "最新价": 2.7,
        "涨跌额": -0.08,
        "涨跌幅": -2.88,
        "今开": 2.79,
        "最高": 2.94,
        "最低": 2.7,
        "昨收": 2.78,
        "成交量": 251000.0,
        "成交额": 710540.0
    },
    {
        "序号": 4217,
        "symbol": "01999",
        "name": "敏华控股",
        "最新价": 5.05,
        "涨跌额": -0.15,
        "涨跌幅": -2.88,
        "今开": 5.16,
        "最高": 5.19,
        "最低": 5.05,
        "昨收": 5.2,
        "成交量": 5483400.0,
        "成交额": 27974614.0
    },
    {
        "序号": 4218,
        "symbol": "06929",
        "name": "业聚医疗",
        "最新价": 5.71,
        "涨跌额": -0.17,
        "涨跌幅": -2.89,
        "今开": 5.8,
        "最高": 6.3,
        "最低": 5.69,
        "昨收": 5.88,
        "成交量": 307000.0,
        "成交额": 1812475.0
    },
    {
        "序号": 4219,
        "symbol": "08395",
        "name": "齐家控股",
        "最新价": 0.67,
        "涨跌额": -0.02,
        "涨跌幅": -2.9,
        "今开": 0.69,
        "最高": 0.71,
        "最低": 0.67,
        "昨收": 0.69,
        "成交量": 1060000.0,
        "成交额": 734200.0
    },
    {
        "序号": 4220,
        "symbol": "08125",
        "name": "仁德资源",
        "最新价": 0.335,
        "涨跌额": -0.01,
        "涨跌幅": -2.9,
        "今开": 0.33,
        "最高": 0.335,
        "最低": 0.325,
        "昨收": 0.345,
        "成交量": 179200.0,
        "成交额": 58952.0
    },
    {
        "序号": 4221,
        "symbol": "01183",
        "name": "澳能建设",
        "最新价": 0.335,
        "涨跌额": -0.01,
        "涨跌幅": -2.9,
        "今开": 0.345,
        "最高": 0.345,
        "最低": 0.325,
        "昨收": 0.345,
        "成交量": 1194250.0,
        "成交额": 397147.0
    },
    {
        "序号": 4222,
        "symbol": "06049",
        "name": "保利物业",
        "最新价": 28.35,
        "涨跌额": -0.85,
        "涨跌幅": -2.91,
        "今开": 29.2,
        "最高": 29.6,
        "最低": 28.2,
        "昨收": 29.2,
        "成交量": 1192600.0,
        "成交额": 34383370.0
    },
    {
        "序号": 4223,
        "symbol": "08121",
        "name": "国恩控股",
        "最新价": 0.2,
        "涨跌额": -0.006,
        "涨跌幅": -2.91,
        "今开": 0.2,
        "最高": 0.201,
        "最低": 0.2,
        "昨收": 0.206,
        "成交量": 154000.0,
        "成交额": 30798.0
    },
    {
        "序号": 4224,
        "symbol": "00938",
        "name": "民生国际",
        "最新价": 1.0,
        "涨跌额": -0.03,
        "涨跌幅": -2.91,
        "今开": 1.0,
        "最高": 1.0,
        "最低": 1.0,
        "昨收": 1.03,
        "成交量": 2000.0,
        "成交额": 2000.0
    },
    {
        "序号": 4225,
        "symbol": "82331",
        "name": "李宁-R",
        "最新价": 19.66,
        "涨跌额": -0.59,
        "涨跌幅": -2.91,
        "今开": 20.15,
        "最高": 20.15,
        "最低": 19.66,
        "昨收": 20.25,
        "成交量": 3500.0,
        "成交额": 70360.0
    },
    {
        "序号": 4226,
        "symbol": "06988",
        "name": "乐享集团",
        "最新价": 0.33,
        "涨跌额": -0.01,
        "涨跌幅": -2.94,
        "今开": 0.35,
        "最高": 0.39,
        "最低": 0.3,
        "昨收": 0.34,
        "成交量": 34631000.0,
        "成交额": 12060410.0
    },
    {
        "序号": 4227,
        "symbol": "00330",
        "name": "思捷环球",
        "最新价": 0.33,
        "涨跌额": -0.01,
        "涨跌幅": -2.94,
        "今开": 0.335,
        "最高": 0.345,
        "最低": 0.325,
        "昨收": 0.34,
        "成交量": 1255752.0,
        "成交额": 420015.0
    },
    {
        "序号": 4228,
        "symbol": "00285",
        "name": "比亚迪电子",
        "最新价": 31.35,
        "涨跌额": -0.95,
        "涨跌幅": -2.94,
        "今开": 32.7,
        "最高": 32.7,
        "最低": 31.05,
        "昨收": 32.3,
        "成交量": 6463551.0,
        "成交额": 202661133.0
    },
    {
        "序号": 4229,
        "symbol": "00827",
        "name": "玖源集团",
        "最新价": 0.065,
        "涨跌额": -0.002,
        "涨跌幅": -2.99,
        "今开": 0.061,
        "最高": 0.065,
        "最低": 0.06,
        "昨收": 0.067,
        "成交量": 1484000.0,
        "成交额": 92952.0
    },
    {
        "序号": 4230,
        "symbol": "00084",
        "name": "宝光实业",
        "最新价": 0.065,
        "涨跌额": -0.002,
        "涨跌幅": -2.99,
        "今开": 0.069,
        "最高": 0.069,
        "最低": 0.059,
        "昨收": 0.067,
        "成交量": 4000.0,
        "成交额": 253.0
    },
    {
        "序号": 4231,
        "symbol": "01456",
        "name": "国联证券",
        "最新价": 3.57,
        "涨跌额": -0.11,
        "涨跌幅": -2.99,
        "今开": 3.68,
        "最高": 3.69,
        "最低": 3.57,
        "昨收": 3.68,
        "成交量": 3298500.0,
        "成交额": 11956720.0
    },
    {
        "序号": 4232,
        "symbol": "00816",
        "name": "金茂服务",
        "最新价": 1.62,
        "涨跌额": -0.05,
        "涨跌幅": -2.99,
        "今开": 1.66,
        "最高": 1.8,
        "最低": 1.61,
        "昨收": 1.67,
        "成交量": 1389953.0,
        "成交额": 2306346.0
    },
    {
        "序号": 4233,
        "symbol": "02899",
        "name": "紫金矿业",
        "最新价": 11.64,
        "涨跌额": -0.36,
        "涨跌幅": -3.0,
        "今开": 12.0,
        "最高": 12.0,
        "最低": 11.6,
        "昨收": 12.0,
        "成交量": 25362085.0,
        "成交额": 297367312.0
    },
    {
        "序号": 4234,
        "symbol": "00806",
        "name": "惠理集团",
        "最新价": 2.26,
        "涨跌额": -0.07,
        "涨跌幅": -3.0,
        "今开": 2.29,
        "最高": 2.36,
        "最低": 2.26,
        "昨收": 2.33,
        "成交量": 3948500.0,
        "成交额": 9062615.0
    },
    {
        "序号": 4235,
        "symbol": "00593",
        "name": "梦东方",
        "最新价": 1.29,
        "涨跌额": -0.04,
        "涨跌幅": -3.01,
        "今开": 1.34,
        "最高": 1.34,
        "最低": 1.26,
        "昨收": 1.33,
        "成交量": 22500.0,
        "成交额": 28535.0
    },
    {
        "序号": 4236,
        "symbol": "02025",
        "name": "瑞丰动力",
        "最新价": 1.6,
        "涨跌额": -0.05,
        "涨跌幅": -3.03,
        "今开": 1.64,
        "最高": 1.7,
        "最低": 1.6,
        "昨收": 1.65,
        "成交量": 406000.0,
        "成交额": 665820.0
    },
    {
        "序号": 4237,
        "symbol": "01238",
        "name": "宝龙地产",
        "最新价": 0.64,
        "涨跌额": -0.02,
        "涨跌幅": -3.03,
        "今开": 0.66,
        "最高": 0.66,
        "最低": 0.63,
        "昨收": 0.66,
        "成交量": 22574000.0,
        "成交额": 14474100.0
    },
    {
        "序号": 4238,
        "symbol": "00756",
        "name": "森美控股",
        "最新价": 0.095,
        "涨跌额": -0.003,
        "涨跌幅": -3.06,
        "今开": 0.084,
        "最高": 0.099,
        "最低": 0.084,
        "昨收": 0.098,
        "成交量": 1084000.0,
        "成交额": 104376.0
    },
    {
        "序号": 4239,
        "symbol": "02678",
        "name": "天虹国际集团",
        "最新价": 4.74,
        "涨跌额": -0.15,
        "涨跌幅": -3.07,
        "今开": 5.02,
        "最高": 5.06,
        "最低": 4.65,
        "昨收": 4.89,
        "成交量": 740300.0,
        "成交额": 3588008.0
    },
    {
        "序号": 4240,
        "symbol": "08040",
        "name": "快意智能",
        "最新价": 0.63,
        "涨跌额": -0.02,
        "涨跌幅": -3.08,
        "今开": 0.65,
        "最高": 0.66,
        "最低": 0.62,
        "昨收": 0.65,
        "成交量": 790000.0,
        "成交额": 502900.0
    },
    {
        "序号": 4241,
        "symbol": "03396",
        "name": "联想控股",
        "最新价": 6.57,
        "涨跌额": -0.21,
        "涨跌幅": -3.1,
        "今开": 6.78,
        "最高": 6.8,
        "最低": 6.57,
        "昨收": 6.78,
        "成交量": 925700.0,
        "成交额": 6156575.0
    },
    {
        "序号": 4242,
        "symbol": "00912",
        "name": "信佳国际",
        "最新价": 1.25,
        "涨跌额": -0.04,
        "涨跌幅": -3.1,
        "今开": 1.2,
        "最高": 1.28,
        "最低": 1.2,
        "昨收": 1.29,
        "成交量": 50000.0,
        "成交额": 63000.0
    },
    {
        "序号": 4243,
        "symbol": "03893",
        "name": "易纬集团",
        "最新价": 0.465,
        "涨跌额": -0.015,
        "涨跌幅": -3.13,
        "今开": 0.485,
        "最高": 0.485,
        "最低": 0.465,
        "昨收": 0.48,
        "成交量": 1460000.0,
        "成交额": 688120.0
    },
    {
        "序号": 4244,
        "symbol": "01408",
        "name": "濠江机电",
        "最新价": 0.155,
        "涨跌额": -0.005,
        "涨跌幅": -3.13,
        "今开": 0.162,
        "最高": 0.162,
        "最低": 0.137,
        "昨收": 0.16,
        "成交量": 454000.0,
        "成交额": 71098.0
    },
    {
        "序号": 4245,
        "symbol": "01166",
        "name": "星凯控股",
        "最新价": 0.031,
        "涨跌额": -0.001,
        "涨跌幅": -3.13,
        "今开": 0.029,
        "最高": 0.031,
        "最低": 0.027,
        "昨收": 0.032,
        "成交量": 120000.0,
        "成交额": 3520.0
    },
    {
        "序号": 4246,
        "symbol": "00358",
        "name": "江西铜业股份",
        "最新价": 10.52,
        "涨跌额": -0.34,
        "涨跌幅": -3.13,
        "今开": 11.0,
        "最高": 11.0,
        "最低": 10.4,
        "昨收": 10.86,
        "成交量": 5289949.0,
        "成交额": 55797655.0
    },
    {
        "序号": 4247,
        "symbol": "09939",
        "name": "开拓药业-B",
        "最新价": 1.85,
        "涨跌额": -0.06,
        "涨跌幅": -3.14,
        "今开": 1.93,
        "最高": 1.97,
        "最低": 1.85,
        "昨收": 1.91,
        "成交量": 6779000.0,
        "成交额": 12876370.0
    },
    {
        "序号": 4248,
        "symbol": "01996",
        "name": "弘阳地产",
        "最新价": 0.092,
        "涨跌额": -0.003,
        "涨跌幅": -3.16,
        "今开": 0.091,
        "最高": 0.092,
        "最低": 0.088,
        "昨收": 0.095,
        "成交量": 251000.0,
        "成交额": 22588.0
    },
    {
        "序号": 4249,
        "symbol": "01208",
        "name": "五矿资源",
        "最新价": 2.14,
        "涨跌额": -0.07,
        "涨跌幅": -3.17,
        "今开": 2.2,
        "最高": 2.2,
        "最低": 2.12,
        "昨收": 2.21,
        "成交量": 14632000.0,
        "成交额": 31256920.0
    },
    {
        "序号": 4250,
        "symbol": "02473",
        "name": "喜相逢集团",
        "最新价": 2.44,
        "涨跌额": -0.08,
        "涨跌幅": -3.17,
        "今开": 2.52,
        "最高": 2.79,
        "最低": 2.38,
        "昨收": 2.52,
        "成交量": 5600000.0,
        "成交额": 14580775.0
    },
    {
        "序号": 4251,
        "symbol": "01705",
        "name": "宾仕国际",
        "最新价": 0.305,
        "涨跌额": -0.01,
        "涨跌幅": -3.17,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.305,
        "昨收": 0.315,
        "成交量": 60000.0,
        "成交额": 18900.0
    },
    {
        "序号": 4252,
        "symbol": "00189",
        "name": "东岳集团",
        "最新价": 5.48,
        "涨跌额": -0.18,
        "涨跌幅": -3.18,
        "今开": 5.61,
        "最高": 5.67,
        "最低": 5.46,
        "昨收": 5.66,
        "成交量": 7042000.0,
        "成交额": 39144162.0
    },
    {
        "序号": 4253,
        "symbol": "08620",
        "name": "亚洲速运",
        "最新价": 0.121,
        "涨跌额": -0.004,
        "涨跌幅": -3.2,
        "今开": 0.121,
        "最高": 0.121,
        "最低": 0.118,
        "昨收": 0.125,
        "成交量": 125000.0,
        "成交额": 14830.0
    },
    {
        "序号": 4254,
        "symbol": "03878",
        "name": "VICON HOLDINGS",
        "最新价": 0.121,
        "涨跌额": -0.004,
        "涨跌幅": -3.2,
        "今开": 0.125,
        "最高": 0.125,
        "最低": 0.115,
        "昨收": 0.125,
        "成交量": 208000.0,
        "成交额": 24156.0
    },
    {
        "序号": 4255,
        "symbol": "02289",
        "name": "创美药业",
        "最新价": 11.46,
        "涨跌额": -0.38,
        "涨跌幅": -3.21,
        "今开": 11.3,
        "最高": 11.56,
        "最低": 11.3,
        "昨收": 11.84,
        "成交量": 233000.0,
        "成交额": 2664720.0
    },
    {
        "序号": 4256,
        "symbol": "01666",
        "name": "同仁堂科技",
        "最新价": 6.33,
        "涨跌额": -0.21,
        "涨跌幅": -3.21,
        "今开": 6.51,
        "最高": 6.58,
        "最低": 6.33,
        "昨收": 6.54,
        "成交量": 299000.0,
        "成交额": 1922138.0
    },
    {
        "序号": 4257,
        "symbol": "03302",
        "name": "精技集团",
        "最新价": 0.3,
        "涨跌额": -0.01,
        "涨跌幅": -3.23,
        "今开": 0.315,
        "最高": 0.335,
        "最低": 0.3,
        "昨收": 0.31,
        "成交量": 2042000.0,
        "成交额": 682230.0
    },
    {
        "序号": 4258,
        "symbol": "01683",
        "name": "旷逸国际",
        "最新价": 0.12,
        "涨跌额": -0.004,
        "涨跌幅": -3.23,
        "今开": 0.127,
        "最高": 0.127,
        "最低": 0.115,
        "昨收": 0.124,
        "成交量": 24128000.0,
        "成交额": 2975968.0
    },
    {
        "序号": 4259,
        "symbol": "00138",
        "name": "中建富通",
        "最新价": 0.24,
        "涨跌额": -0.008,
        "涨跌幅": -3.23,
        "今开": 0.234,
        "最高": 0.248,
        "最低": 0.228,
        "昨收": 0.248,
        "成交量": 204750.0,
        "成交额": 47261.0
    },
    {
        "序号": 4260,
        "symbol": "02405",
        "name": "力盟科技",
        "最新价": 5.95,
        "涨跌额": -0.2,
        "涨跌幅": -3.25,
        "今开": 6.06,
        "最高": 6.24,
        "最低": 5.85,
        "昨收": 6.15,
        "成交量": 1880000.0,
        "成交额": 11456680.0
    },
    {
        "序号": 4261,
        "symbol": "00257",
        "name": "光大环境",
        "最新价": 2.38,
        "涨跌额": -0.08,
        "涨跌幅": -3.25,
        "今开": 2.46,
        "最高": 2.46,
        "最低": 2.38,
        "昨收": 2.46,
        "成交量": 19940952.0,
        "成交额": 48086992.0
    },
    {
        "序号": 4262,
        "symbol": "06078",
        "name": "海吉亚医疗",
        "最新价": 41.4,
        "涨跌额": -1.4,
        "涨跌幅": -3.27,
        "今开": 43.05,
        "最高": 43.45,
        "最低": 40.5,
        "昨收": 42.8,
        "成交量": 3364730.0,
        "成交额": 138642413.0
    },
    {
        "序号": 4263,
        "symbol": "02159",
        "name": "麦迪卫康",
        "最新价": 0.44,
        "涨跌额": -0.015,
        "涨跌幅": -3.3,
        "今开": 0.425,
        "最高": 0.445,
        "最低": 0.41,
        "昨收": 0.455,
        "成交量": 99200.0,
        "成交额": 42748.0
    },
    {
        "序号": 4264,
        "symbol": "03900",
        "name": "绿城中国",
        "最新价": 7.59,
        "涨跌额": -0.26,
        "涨跌幅": -3.31,
        "今开": 7.87,
        "最高": 7.92,
        "最低": 7.53,
        "昨收": 7.85,
        "成交量": 9625500.0,
        "成交额": 73244610.0
    },
    {
        "序号": 4265,
        "symbol": "02488",
        "name": "元征科技",
        "最新价": 1.75,
        "涨跌额": -0.06,
        "涨跌幅": -3.31,
        "今开": 1.88,
        "最高": 1.88,
        "最低": 1.75,
        "昨收": 1.81,
        "成交量": 204500.0,
        "成交额": 368920.0
    },
    {
        "序号": 4266,
        "symbol": "08535",
        "name": "荧德控股",
        "最新价": 0.058,
        "涨跌额": -0.002,
        "涨跌幅": -3.33,
        "今开": 0.058,
        "最高": 0.058,
        "最低": 0.058,
        "昨收": 0.06,
        "成交量": 1140000.0,
        "成交额": 66120.0
    },
    {
        "序号": 4267,
        "symbol": "08493",
        "name": "龙皇集团",
        "最新价": 0.29,
        "涨跌额": -0.01,
        "涨跌幅": -3.33,
        "今开": 0.27,
        "最高": 0.29,
        "最低": 0.27,
        "昨收": 0.3,
        "成交量": 1141200.0,
        "成交额": 317772.0
    },
    {
        "序号": 4268,
        "symbol": "08448",
        "name": "环球印馆",
        "最新价": 0.029,
        "涨跌额": -0.001,
        "涨跌幅": -3.33,
        "今开": 0.03,
        "最高": 0.031,
        "最低": 0.029,
        "昨收": 0.03,
        "成交量": 4200000.0,
        "成交额": 125880.0
    },
    {
        "序号": 4269,
        "symbol": "06118",
        "name": "奥星生命科技",
        "最新价": 1.74,
        "涨跌额": -0.06,
        "涨跌幅": -3.33,
        "今开": 1.74,
        "最高": 1.82,
        "最低": 1.71,
        "昨收": 1.8,
        "成交量": 90000.0,
        "成交额": 155740.0
    },
    {
        "序号": 4270,
        "symbol": "03398",
        "name": "华鼎控股",
        "最新价": 0.29,
        "涨跌额": -0.01,
        "涨跌幅": -3.33,
        "今开": 0.315,
        "最高": 0.315,
        "最低": 0.285,
        "昨收": 0.3,
        "成交量": 6000.0,
        "成交额": 1830.0
    },
    {
        "序号": 4271,
        "symbol": "01861",
        "name": "保宝龙科技",
        "最新价": 1.45,
        "涨跌额": -0.05,
        "涨跌幅": -3.33,
        "今开": 1.45,
        "最高": 1.45,
        "最低": 1.45,
        "昨收": 1.5,
        "成交量": 6000.0,
        "成交额": 8700.0
    },
    {
        "序号": 4272,
        "symbol": "00931",
        "name": "中国港能",
        "最新价": 0.435,
        "涨跌额": -0.015,
        "涨跌幅": -3.33,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.435,
        "昨收": 0.45,
        "成交量": 46500.0,
        "成交额": 20277.0
    },
    {
        "序号": 4273,
        "symbol": "00550",
        "name": "京基智慧文化",
        "最新价": 0.58,
        "涨跌额": -0.02,
        "涨跌幅": -3.33,
        "今开": 0.6,
        "最高": 0.62,
        "最低": 0.55,
        "昨收": 0.6,
        "成交量": 202000.0,
        "成交额": 118360.0
    },
    {
        "序号": 4274,
        "symbol": "00265",
        "name": "东胜智慧城市服务",
        "最新价": 0.029,
        "涨跌额": -0.001,
        "涨跌幅": -3.33,
        "今开": 0.029,
        "最高": 0.029,
        "最低": 0.028,
        "昨收": 0.03,
        "成交量": 40000.0,
        "成交额": 1140.0
    },
    {
        "序号": 4275,
        "symbol": "01100",
        "name": "飞达控股",
        "最新价": 1.73,
        "涨跌额": -0.06,
        "涨跌幅": -3.35,
        "今开": 1.73,
        "最高": 1.73,
        "最低": 1.7,
        "昨收": 1.79,
        "成交量": 18000.0,
        "成交额": 31080.0
    },
    {
        "序号": 4276,
        "symbol": "06699",
        "name": "时代天使",
        "最新价": 54.75,
        "涨跌额": -1.9,
        "涨跌幅": -3.35,
        "今开": 56.65,
        "最高": 57.45,
        "最低": 54.1,
        "昨收": 56.65,
        "成交量": 430800.0,
        "成交额": 23836810.0
    },
    {
        "序号": 4277,
        "symbol": "02099",
        "name": "中国黄金国际",
        "最新价": 31.4,
        "涨跌额": -1.1,
        "涨跌幅": -3.38,
        "今开": 32.55,
        "最高": 32.55,
        "最低": 31.4,
        "昨收": 32.5,
        "成交量": 1689963.0,
        "成交额": 53587610.0
    },
    {
        "序号": 4278,
        "symbol": "09933",
        "name": "GHW INTL",
        "最新价": 0.57,
        "涨跌额": -0.02,
        "涨跌幅": -3.39,
        "今开": 0.57,
        "最高": 0.57,
        "最低": 0.57,
        "昨收": 0.59,
        "成交量": 120000.0,
        "成交额": 68400.0
    },
    {
        "序号": 4279,
        "symbol": "01303",
        "name": "汇力资源",
        "最新价": 0.285,
        "涨跌额": -0.01,
        "涨跌幅": -3.39,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.285,
        "昨收": 0.295,
        "成交量": 42000.0,
        "成交额": 12110.0
    },
    {
        "序号": 4280,
        "symbol": "02331",
        "name": "李宁",
        "最新价": 21.35,
        "涨跌额": -0.75,
        "涨跌幅": -3.39,
        "今开": 22.1,
        "最高": 22.3,
        "最低": 21.25,
        "昨收": 22.1,
        "成交量": 11023533.0,
        "成交额": 238449433.0
    },
    {
        "序号": 4281,
        "symbol": "01133",
        "name": "哈尔滨电气",
        "最新价": 1.99,
        "涨跌额": -0.07,
        "涨跌幅": -3.4,
        "今开": 2.06,
        "最高": 2.06,
        "最低": 1.99,
        "昨收": 2.06,
        "成交量": 434000.0,
        "成交额": 870040.0
    },
    {
        "序号": 4282,
        "symbol": "01440",
        "name": "应星控股",
        "最新价": 1.98,
        "涨跌额": -0.07,
        "涨跌幅": -3.41,
        "今开": 2.05,
        "最高": 2.05,
        "最低": 1.97,
        "昨收": 2.05,
        "成交量": 70000.0,
        "成交额": 138350.0
    },
    {
        "序号": 4283,
        "symbol": "00656",
        "name": "复星国际",
        "最新价": 4.22,
        "涨跌额": -0.15,
        "涨跌幅": -3.43,
        "今开": 4.33,
        "最高": 4.35,
        "最低": 4.16,
        "昨收": 4.37,
        "成交量": 6349000.0,
        "成交额": 26875722.0
    },
    {
        "序号": 4284,
        "symbol": "00180",
        "name": "开达集团",
        "最新价": 0.42,
        "涨跌额": -0.015,
        "涨跌幅": -3.45,
        "今开": 0.38,
        "最高": 0.5,
        "最低": 0.38,
        "昨收": 0.435,
        "成交量": 54000.0,
        "成交额": 22150.0
    },
    {
        "序号": 4285,
        "symbol": "00389",
        "name": "通天酒业",
        "最新价": 0.83,
        "涨跌额": -0.03,
        "涨跌幅": -3.49,
        "今开": 0.86,
        "最高": 0.86,
        "最低": 0.82,
        "昨收": 0.86,
        "成交量": 650000.0,
        "成交额": 548560.0
    },
    {
        "序号": 4286,
        "symbol": "00852",
        "name": "海峡石油化工",
        "最新价": 0.138,
        "涨跌额": -0.005,
        "涨跌幅": -3.5,
        "今开": 0.144,
        "最高": 0.144,
        "最低": 0.138,
        "昨收": 0.143,
        "成交量": 756000.0,
        "成交额": 106918.0
    },
    {
        "序号": 4287,
        "symbol": "00590",
        "name": "六福集团",
        "最新价": 21.95,
        "涨跌额": -0.8,
        "涨跌幅": -3.52,
        "今开": 22.5,
        "最高": 22.5,
        "最低": 21.85,
        "昨收": 22.75,
        "成交量": 510088.0,
        "成交额": 11261272.0
    },
    {
        "序号": 4288,
        "symbol": "02799",
        "name": "中国华融",
        "最新价": 0.41,
        "涨跌额": -0.015,
        "涨跌幅": -3.53,
        "今开": 0.43,
        "最高": 0.43,
        "最低": 0.41,
        "昨收": 0.425,
        "成交量": 34201000.0,
        "成交额": 14195930.0
    },
    {
        "序号": 4289,
        "symbol": "01020",
        "name": "赛伯乐国际控股",
        "最新价": 0.082,
        "涨跌额": -0.003,
        "涨跌幅": -3.53,
        "今开": 0.081,
        "最高": 0.082,
        "最低": 0.08,
        "昨收": 0.085,
        "成交量": 732000.0,
        "成交额": 58724.0
    },
    {
        "序号": 4290,
        "symbol": "01345",
        "name": "上海先锋控股",
        "最新价": 1.91,
        "涨跌额": -0.07,
        "涨跌幅": -3.54,
        "今开": 1.91,
        "最高": 1.91,
        "最低": 1.91,
        "昨收": 1.98,
        "成交量": 2000.0,
        "成交额": 3820.0
    },
    {
        "序号": 4291,
        "symbol": "01733",
        "name": "易大宗",
        "最新价": 1.36,
        "涨跌额": -0.05,
        "涨跌幅": -3.55,
        "今开": 1.39,
        "最高": 1.41,
        "最低": 1.36,
        "昨收": 1.41,
        "成交量": 2695550.0,
        "成交额": 3703012.0
    },
    {
        "序号": 4292,
        "symbol": "02423",
        "name": "贝壳-W",
        "最新价": 39.2,
        "涨跌额": -1.45,
        "涨跌幅": -3.57,
        "今开": 40.0,
        "最高": 40.05,
        "最低": 39.15,
        "昨收": 40.65,
        "成交量": 469600.0,
        "成交额": 18516989.0
    },
    {
        "序号": 4293,
        "symbol": "03997",
        "name": "电讯首科",
        "最新价": 0.405,
        "涨跌额": -0.015,
        "涨跌幅": -3.57,
        "今开": 0.39,
        "最高": 0.41,
        "最低": 0.39,
        "昨收": 0.42,
        "成交量": 62000.0,
        "成交额": 24220.0
    },
    {
        "序号": 4294,
        "symbol": "01313",
        "name": "华润建材科技",
        "最新价": 1.62,
        "涨跌额": -0.06,
        "涨跌幅": -3.57,
        "今开": 1.68,
        "最高": 1.7,
        "最低": 1.61,
        "昨收": 1.68,
        "成交量": 11254746.0,
        "成交额": 18493532.0
    },
    {
        "序号": 4295,
        "symbol": "01265",
        "name": "天津津燃公用",
        "最新价": 0.27,
        "涨跌额": -0.01,
        "涨跌幅": -3.57,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.27,
        "昨收": 0.28,
        "成交量": 70000.0,
        "成交额": 19100.0
    },
    {
        "序号": 4296,
        "symbol": "01115",
        "name": "西藏水资源",
        "最新价": 0.27,
        "涨跌额": -0.01,
        "涨跌幅": -3.57,
        "今开": 0.295,
        "最高": 0.295,
        "最低": 0.27,
        "昨收": 0.28,
        "成交量": 167000.0,
        "成交额": 49240.0
    },
    {
        "序号": 4297,
        "symbol": "00754",
        "name": "合生创展集团",
        "最新价": 4.03,
        "涨跌额": -0.15,
        "涨跌幅": -3.59,
        "今开": 4.12,
        "最高": 4.21,
        "最低": 4.0,
        "昨收": 4.18,
        "成交量": 1763573.0,
        "成交额": 7156737.0
    },
    {
        "序号": 4298,
        "symbol": "01855",
        "name": "中庆股份",
        "最新价": 24.1,
        "涨跌额": -0.9,
        "涨跌幅": -3.6,
        "今开": 24.5,
        "最高": 24.9,
        "最低": 23.35,
        "昨收": 25.0,
        "成交量": 146000.0,
        "成交额": 3503600.0
    },
    {
        "序号": 4299,
        "symbol": "00095",
        "name": "绿景中国地产",
        "最新价": 0.8,
        "涨跌额": -0.03,
        "涨跌幅": -3.61,
        "今开": 0.83,
        "最高": 0.83,
        "最低": 0.78,
        "昨收": 0.83,
        "成交量": 4942000.0,
        "成交额": 3987480.0
    },
    {
        "序号": 4300,
        "symbol": "02427",
        "name": "GUANZE MEDICAL",
        "最新价": 0.53,
        "涨跌额": -0.02,
        "涨跌幅": -3.64,
        "今开": 0.52,
        "最高": 0.54,
        "最低": 0.51,
        "昨收": 0.55,
        "成交量": 1085000.0,
        "成交额": 576350.0
    },
    {
        "序号": 4301,
        "symbol": "01727",
        "name": "河北建设",
        "最新价": 0.53,
        "涨跌额": -0.02,
        "涨跌幅": -3.64,
        "今开": 0.58,
        "最高": 0.59,
        "最低": 0.53,
        "昨收": 0.55,
        "成交量": 162000.0,
        "成交额": 92075.0
    },
    {
        "序号": 4302,
        "symbol": "00580",
        "name": "赛晶科技",
        "最新价": 1.32,
        "涨跌额": -0.05,
        "涨跌幅": -3.65,
        "今开": 1.37,
        "最高": 1.38,
        "最低": 1.31,
        "昨收": 1.37,
        "成交量": 1326000.0,
        "成交额": 1759180.0
    },
    {
        "序号": 4303,
        "symbol": "03383",
        "name": "雅居乐集团",
        "最新价": 0.79,
        "涨跌额": -0.03,
        "涨跌幅": -3.66,
        "今开": 0.82,
        "最高": 0.83,
        "最低": 0.78,
        "昨收": 0.82,
        "成交量": 13698000.0,
        "成交额": 11013420.0
    },
    {
        "序号": 4304,
        "symbol": "02314",
        "name": "理文造纸",
        "最新价": 2.1,
        "涨跌额": -0.08,
        "涨跌幅": -3.67,
        "今开": 2.18,
        "最高": 2.18,
        "最低": 2.08,
        "昨收": 2.18,
        "成交量": 2902000.0,
        "成交额": 6121200.0
    },
    {
        "序号": 4305,
        "symbol": "00921",
        "name": "海信家电",
        "最新价": 17.24,
        "涨跌额": -0.66,
        "涨跌幅": -3.69,
        "今开": 18.06,
        "最高": 18.06,
        "最低": 17.08,
        "昨收": 17.9,
        "成交量": 2099181.0,
        "成交额": 36449042.0
    },
    {
        "序号": 4306,
        "symbol": "00535",
        "name": "金地商置",
        "最新价": 0.235,
        "涨跌额": -0.009,
        "涨跌幅": -3.69,
        "今开": 0.243,
        "最高": 0.246,
        "最低": 0.235,
        "昨收": 0.244,
        "成交量": 23428000.0,
        "成交额": 5643574.0
    },
    {
        "序号": 4307,
        "symbol": "00351",
        "name": "亚洲能源物流",
        "最新价": 0.26,
        "涨跌额": -0.01,
        "涨跌幅": -3.7,
        "今开": 0.231,
        "最高": 0.265,
        "最低": 0.215,
        "昨收": 0.27,
        "成交量": 116120.0,
        "成交额": 28624.0
    },
    {
        "序号": 4308,
        "symbol": "00326",
        "name": "中国星集团",
        "最新价": 0.78,
        "涨跌额": -0.03,
        "涨跌幅": -3.7,
        "今开": 0.78,
        "最高": 0.79,
        "最低": 0.77,
        "昨收": 0.81,
        "成交量": 52000.0,
        "成交额": 40500.0
    },
    {
        "序号": 4309,
        "symbol": "01760",
        "name": "英恒科技",
        "最新价": 2.59,
        "涨跌额": -0.1,
        "涨跌幅": -3.72,
        "今开": 2.77,
        "最高": 2.77,
        "最低": 2.52,
        "昨收": 2.69,
        "成交量": 974000.0,
        "成交额": 2509790.0
    },
    {
        "序号": 4310,
        "symbol": "00583",
        "name": "长城环亚控股",
        "最新价": 0.23,
        "涨跌额": -0.009,
        "涨跌幅": -3.77,
        "今开": 0.23,
        "最高": 0.23,
        "最低": 0.229,
        "昨收": 0.239,
        "成交量": 70000.0,
        "成交额": 16076.0
    },
    {
        "序号": 4311,
        "symbol": "00332",
        "name": "元亨燃气",
        "最新价": 0.051,
        "涨跌额": -0.002,
        "涨跌幅": -3.77,
        "今开": 0.051,
        "最高": 0.051,
        "最低": 0.051,
        "昨收": 0.053,
        "成交量": 232000.0,
        "成交额": 11832.0
    },
    {
        "序号": 4312,
        "symbol": "06063",
        "name": "智中国际",
        "最新价": 0.076,
        "涨跌额": -0.003,
        "涨跌幅": -3.8,
        "今开": 0.075,
        "最高": 0.076,
        "最低": 0.075,
        "昨收": 0.079,
        "成交量": 56000.0,
        "成交额": 4248.0
    },
    {
        "序号": 4313,
        "symbol": "01627",
        "name": "安保工程控股",
        "最新价": 0.38,
        "涨跌额": -0.015,
        "涨跌幅": -3.8,
        "今开": 0.375,
        "最高": 0.38,
        "最低": 0.375,
        "昨收": 0.395,
        "成交量": 12000.0,
        "成交额": 4500.0
    },
    {
        "序号": 4314,
        "symbol": "00553",
        "name": "南京熊猫电子股份",
        "最新价": 2.78,
        "涨跌额": -0.11,
        "涨跌幅": -3.81,
        "今开": 2.98,
        "最高": 3.01,
        "最低": 2.72,
        "昨收": 2.89,
        "成交量": 8094000.0,
        "成交额": 22872320.0
    },
    {
        "序号": 4315,
        "symbol": "02185",
        "name": "百心安-B",
        "最新价": 3.78,
        "涨跌额": -0.15,
        "涨跌幅": -3.82,
        "今开": 3.93,
        "最高": 3.93,
        "最低": 3.75,
        "昨收": 3.93,
        "成交量": 2800.0,
        "成交额": 10751.0
    },
    {
        "序号": 4316,
        "symbol": "01122",
        "name": "庆铃汽车股份",
        "最新价": 0.5,
        "涨跌额": -0.02,
        "涨跌幅": -3.85,
        "今开": 0.51,
        "最高": 0.52,
        "最低": 0.5,
        "昨收": 0.52,
        "成交量": 3052000.0,
        "成交额": 1538640.0
    },
    {
        "序号": 4317,
        "symbol": "00707",
        "name": "亚洲电视控股",
        "最新价": 0.025,
        "涨跌额": -0.001,
        "涨跌幅": -3.85,
        "今开": 0.025,
        "最高": 0.026,
        "最低": 0.024,
        "昨收": 0.026,
        "成交量": 1894000.0,
        "成交额": 47744.0
    },
    {
        "序号": 4318,
        "symbol": "00235",
        "name": "中策资本控股",
        "最新价": 0.025,
        "涨跌额": -0.001,
        "涨跌幅": -3.85,
        "今开": 0.026,
        "最高": 0.026,
        "最低": 0.025,
        "昨收": 0.026,
        "成交量": 3315000.0,
        "成交额": 85630.0
    },
    {
        "序号": 4319,
        "symbol": "00688",
        "name": "中国海外发展",
        "最新价": 12.96,
        "涨跌额": -0.52,
        "涨跌幅": -3.86,
        "今开": 13.56,
        "最高": 13.7,
        "最低": 12.82,
        "昨收": 13.48,
        "成交量": 21956057.0,
        "成交额": 285376032.0
    },
    {
        "序号": 4320,
        "symbol": "01098",
        "name": "路劲",
        "最新价": 1.49,
        "涨跌额": -0.06,
        "涨跌幅": -3.87,
        "今开": 1.54,
        "最高": 1.55,
        "最低": 1.48,
        "昨收": 1.55,
        "成交量": 330000.0,
        "成交额": 498680.0
    },
    {
        "序号": 4321,
        "symbol": "01268",
        "name": "美东汽车",
        "最新价": 3.47,
        "涨跌额": -0.14,
        "涨跌幅": -3.88,
        "今开": 3.3,
        "最高": 3.59,
        "最低": 3.3,
        "昨收": 3.61,
        "成交量": 4792000.0,
        "成交额": 16714338.0
    },
    {
        "序号": 4322,
        "symbol": "01830",
        "name": "完美医疗",
        "最新价": 3.22,
        "涨跌额": -0.13,
        "涨跌幅": -3.88,
        "今开": 3.36,
        "最高": 3.36,
        "最低": 3.2,
        "昨收": 3.35,
        "成交量": 1090520.0,
        "成交额": 3553563.0
    },
    {
        "序号": 4323,
        "symbol": "00875",
        "name": "中国金控",
        "最新价": 2.72,
        "涨跌额": -0.11,
        "涨跌幅": -3.89,
        "今开": 2.75,
        "最高": 2.8,
        "最低": 2.55,
        "昨收": 2.83,
        "成交量": 208000.0,
        "成交额": 568480.0
    },
    {
        "序号": 4324,
        "symbol": "02486",
        "name": "普乐师集团控股",
        "最新价": 4.94,
        "涨跌额": -0.2,
        "涨跌幅": -3.89,
        "今开": 5.0,
        "最高": 5.1,
        "最低": 4.76,
        "昨收": 5.14,
        "成交量": 451000.0,
        "成交额": 2228836.0
    },
    {
        "序号": 4325,
        "symbol": "01117",
        "name": "现代牧业",
        "最新价": 0.74,
        "涨跌额": -0.03,
        "涨跌幅": -3.9,
        "今开": 0.76,
        "最高": 0.77,
        "最低": 0.74,
        "昨收": 0.77,
        "成交量": 5111000.0,
        "成交额": 3855305.0
    },
    {
        "序号": 4326,
        "symbol": "08487",
        "name": "ISP GLOBAL",
        "最新价": 0.245,
        "涨跌额": -0.01,
        "涨跌幅": -3.92,
        "今开": 0.25,
        "最高": 0.25,
        "最低": 0.238,
        "昨收": 0.255,
        "成交量": 74000.0,
        "成交额": 18126.0
    },
    {
        "序号": 4327,
        "symbol": "03883",
        "name": "中国奥园",
        "最新价": 0.245,
        "涨跌额": -0.01,
        "涨跌幅": -3.92,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.24,
        "昨收": 0.255,
        "成交量": 2139000.0,
        "成交额": 521676.0
    },
    {
        "序号": 4328,
        "symbol": "02171",
        "name": "科济药业-B",
        "最新价": 9.02,
        "涨跌额": -0.37,
        "涨跌幅": -3.94,
        "今开": 9.34,
        "最高": 9.35,
        "最低": 9.02,
        "昨收": 9.39,
        "成交量": 538500.0,
        "成交额": 4915785.0
    },
    {
        "序号": 4329,
        "symbol": "01787",
        "name": "山东黄金",
        "最新价": 14.6,
        "涨跌额": -0.6,
        "涨跌幅": -3.95,
        "今开": 15.04,
        "最高": 15.08,
        "最低": 14.56,
        "昨收": 15.2,
        "成交量": 3736350.0,
        "成交额": 54921040.0
    },
    {
        "序号": 4330,
        "symbol": "08186",
        "name": "曼妠",
        "最新价": 1.7,
        "涨跌额": -0.07,
        "涨跌幅": -3.95,
        "今开": 1.61,
        "最高": 1.7,
        "最低": 1.61,
        "昨收": 1.77,
        "成交量": 65000.0,
        "成交额": 110050.0
    },
    {
        "序号": 4331,
        "symbol": "00357",
        "name": "美兰空港",
        "最新价": 6.31,
        "涨跌额": -0.26,
        "涨跌幅": -3.96,
        "今开": 6.62,
        "最高": 6.71,
        "最低": 6.31,
        "昨收": 6.57,
        "成交量": 447000.0,
        "成交额": 2894880.0
    },
    {
        "序号": 4332,
        "symbol": "06968",
        "name": "港龙中国地产",
        "最新价": 0.194,
        "涨跌额": -0.008,
        "涨跌幅": -3.96,
        "今开": 0.209,
        "最高": 0.209,
        "最低": 0.19,
        "昨收": 0.202,
        "成交量": 1257000.0,
        "成交额": 242715.0
    },
    {
        "序号": 4333,
        "symbol": "01292",
        "name": "长安民生物流",
        "最新价": 1.93,
        "涨跌额": -0.08,
        "涨跌幅": -3.98,
        "今开": 1.95,
        "最高": 1.95,
        "最低": 1.9,
        "昨收": 2.01,
        "成交量": 21000.0,
        "成交额": 40460.0
    },
    {
        "序号": 4334,
        "symbol": "09698",
        "name": "万国数据-SW",
        "最新价": 8.64,
        "涨跌额": -0.36,
        "涨跌幅": -4.0,
        "今开": 9.13,
        "最高": 9.13,
        "最低": 8.6,
        "昨收": 9.0,
        "成交量": 5121900.0,
        "成交额": 44624642.0
    },
    {
        "序号": 4335,
        "symbol": "08167",
        "name": "中国新电信",
        "最新价": 0.024,
        "涨跌额": -0.001,
        "涨跌幅": -4.0,
        "今开": 0.025,
        "最高": 0.025,
        "最低": 0.023,
        "昨收": 0.025,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4336,
        "symbol": "00997",
        "name": "普汇中金国际",
        "最新价": 0.024,
        "涨跌额": -0.001,
        "涨跌幅": -4.0,
        "今开": 0.024,
        "最高": 0.024,
        "最低": 0.024,
        "昨收": 0.025,
        "成交量": 208000.0,
        "成交额": 4992.0
    },
    {
        "序号": 4337,
        "symbol": "00817",
        "name": "中国金茂",
        "最新价": 0.72,
        "涨跌额": -0.03,
        "涨跌幅": -4.0,
        "今开": 0.76,
        "最高": 0.76,
        "最低": 0.72,
        "昨收": 0.75,
        "成交量": 33875579.0,
        "成交额": 24885398.0
    },
    {
        "序号": 4338,
        "symbol": "00800",
        "name": "A8新媒体",
        "最新价": 0.168,
        "涨跌额": -0.007,
        "涨跌幅": -4.0,
        "今开": 0.169,
        "最高": 0.169,
        "最低": 0.163,
        "昨收": 0.175,
        "成交量": 112000.0,
        "成交额": 18898.0
    },
    {
        "序号": 4339,
        "symbol": "02415",
        "name": "梅斯健康",
        "最新价": 5.02,
        "涨跌额": -0.21,
        "涨跌幅": -4.02,
        "今开": 4.82,
        "最高": 5.23,
        "最低": 4.82,
        "昨收": 5.23,
        "成交量": 532500.0,
        "成交额": 2710917.0
    },
    {
        "序号": 4340,
        "symbol": "03990",
        "name": "美的置业",
        "最新价": 5.49,
        "涨跌额": -0.23,
        "涨跌幅": -4.02,
        "今开": 5.72,
        "最高": 5.72,
        "最低": 5.47,
        "昨收": 5.72,
        "成交量": 374800.0,
        "成交额": 2079138.0
    },
    {
        "序号": 4341,
        "symbol": "00757",
        "name": "阳光能源",
        "最新价": 0.142,
        "涨跌额": -0.006,
        "涨跌幅": -4.05,
        "今开": 0.148,
        "最高": 0.149,
        "最低": 0.14,
        "昨收": 0.148,
        "成交量": 1143000.0,
        "成交额": 162090.0
    },
    {
        "序号": 4342,
        "symbol": "01696",
        "name": "复锐医疗科技",
        "最新价": 4.65,
        "涨跌额": -0.2,
        "涨跌幅": -4.12,
        "今开": 4.9,
        "最高": 4.9,
        "最低": 4.54,
        "昨收": 4.85,
        "成交量": 885600.0,
        "成交额": 4119011.0
    },
    {
        "序号": 4343,
        "symbol": "09992",
        "name": "泡泡玛特",
        "最新价": 19.94,
        "涨跌额": -0.86,
        "涨跌幅": -4.13,
        "今开": 20.8,
        "最高": 20.9,
        "最低": 19.7,
        "昨收": 20.8,
        "成交量": 10075560.0,
        "成交额": 201392645.0
    },
    {
        "序号": 4344,
        "symbol": "08547",
        "name": "PACIFIC LEGEND",
        "最新价": 0.23,
        "涨跌额": -0.01,
        "涨跌幅": -4.17,
        "今开": 0.24,
        "最高": 0.249,
        "最低": 0.23,
        "昨收": 0.24,
        "成交量": 130000.0,
        "成交额": 31700.0
    },
    {
        "序号": 4345,
        "symbol": "01611",
        "name": "新火科技控股",
        "最新价": 2.3,
        "涨跌额": -0.1,
        "涨跌幅": -4.17,
        "今开": 2.41,
        "最高": 2.47,
        "最低": 2.29,
        "昨收": 2.4,
        "成交量": 2411000.0,
        "成交额": 5732475.0
    },
    {
        "序号": 4346,
        "symbol": "08329",
        "name": "海王英特龙",
        "最新价": 0.137,
        "涨跌额": -0.006,
        "涨跌幅": -4.2,
        "今开": 0.137,
        "最高": 0.137,
        "最低": 0.133,
        "昨收": 0.143,
        "成交量": 510000.0,
        "成交额": 68470.0
    },
    {
        "序号": 4347,
        "symbol": "00950",
        "name": "李氏大药厂",
        "最新价": 1.37,
        "涨跌额": -0.06,
        "涨跌幅": -4.2,
        "今开": 1.43,
        "最高": 1.43,
        "最低": 1.32,
        "昨收": 1.43,
        "成交量": 9000.0,
        "成交额": 12665.0
    },
    {
        "序号": 4348,
        "symbol": "01940",
        "name": "CGII HLDGS",
        "最新价": 0.455,
        "涨跌额": -0.02,
        "涨跌幅": -4.21,
        "今开": 0.46,
        "最高": 0.475,
        "最低": 0.45,
        "昨收": 0.475,
        "成交量": 196000.0,
        "成交额": 89840.0
    },
    {
        "序号": 4349,
        "symbol": "01489",
        "name": "GC CONSTRUCTION",
        "最新价": 0.45,
        "涨跌额": -0.02,
        "涨跌幅": -4.26,
        "今开": 0.45,
        "最高": 0.45,
        "最低": 0.45,
        "昨收": 0.47,
        "成交量": 90000.0,
        "成交额": 40500.0
    },
    {
        "序号": 4350,
        "symbol": "01080",
        "name": "胜利管道",
        "最新价": 0.045,
        "涨跌额": -0.002,
        "涨跌幅": -4.26,
        "今开": 0.047,
        "最高": 0.047,
        "最低": 0.045,
        "昨收": 0.047,
        "成交量": 118500.0,
        "成交额": 5433.0
    },
    {
        "序号": 4351,
        "symbol": "00417",
        "name": "谢瑞麟",
        "最新价": 0.9,
        "涨跌额": -0.04,
        "涨跌幅": -4.26,
        "今开": 0.94,
        "最高": 0.94,
        "最低": 0.9,
        "昨收": 0.94,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4352,
        "symbol": "01755",
        "name": "新城悦服务",
        "最新价": 3.36,
        "涨跌额": -0.15,
        "涨跌幅": -4.27,
        "今开": 3.5,
        "最高": 3.5,
        "最低": 3.36,
        "昨收": 3.51,
        "成交量": 673000.0,
        "成交额": 2301120.0
    },
    {
        "序号": 4353,
        "symbol": "02166",
        "name": "芯智控股",
        "最新价": 1.56,
        "涨跌额": -0.07,
        "涨跌幅": -4.29,
        "今开": 1.6,
        "最高": 1.6,
        "最低": 1.51,
        "昨收": 1.63,
        "成交量": 24000.0,
        "成交额": 37760.0
    },
    {
        "序号": 4354,
        "symbol": "02777",
        "name": "富力地产",
        "最新价": 1.11,
        "涨跌额": -0.05,
        "涨跌幅": -4.31,
        "今开": 1.17,
        "最高": 1.17,
        "最低": 1.09,
        "昨收": 1.16,
        "成交量": 3722000.0,
        "成交额": 4186856.0
    },
    {
        "序号": 4355,
        "symbol": "09869",
        "name": "海伦司",
        "最新价": 4.4,
        "涨跌额": -0.2,
        "涨跌幅": -4.35,
        "今开": 4.6,
        "最高": 4.6,
        "最低": 4.39,
        "昨收": 4.6,
        "成交量": 907000.0,
        "成交额": 4051030.0
    },
    {
        "序号": 4356,
        "symbol": "01233",
        "name": "时代中国控股",
        "最新价": 0.33,
        "涨跌额": -0.015,
        "涨跌幅": -4.35,
        "今开": 0.34,
        "最高": 0.34,
        "最低": 0.31,
        "昨收": 0.345,
        "成交量": 3404000.0,
        "成交额": 1094895.0
    },
    {
        "序号": 4357,
        "symbol": "00928",
        "name": "莲和医疗",
        "最新价": 0.066,
        "涨跌额": -0.003,
        "涨跌幅": -4.35,
        "今开": 0.066,
        "最高": 0.067,
        "最低": 0.064,
        "昨收": 0.069,
        "成交量": 3360000.0,
        "成交额": 223320.0
    },
    {
        "序号": 4358,
        "symbol": "00209",
        "name": "瀛晟科学",
        "最新价": 0.066,
        "涨跌额": -0.003,
        "涨跌幅": -4.35,
        "今开": 0.064,
        "最高": 0.066,
        "最低": 0.063,
        "昨收": 0.069,
        "成交量": 70000.0,
        "成交额": 4510.0
    },
    {
        "序号": 4359,
        "symbol": "00081",
        "name": "中国海外宏洋集团",
        "最新价": 2.41,
        "涨跌额": -0.11,
        "涨跌幅": -4.37,
        "今开": 2.48,
        "最高": 2.51,
        "最低": 2.39,
        "昨收": 2.52,
        "成交量": 3309000.0,
        "成交额": 8048375.0
    },
    {
        "序号": 4360,
        "symbol": "01164",
        "name": "中广核矿业",
        "最新价": 1.53,
        "涨跌额": -0.07,
        "涨跌幅": -4.38,
        "今开": 1.56,
        "最高": 1.6,
        "最低": 1.47,
        "昨收": 1.6,
        "成交量": 16767500.0,
        "成交额": 25567444.0
    },
    {
        "序号": 4361,
        "symbol": "09866",
        "name": "蔚来-SW",
        "最新价": 57.8,
        "涨跌额": -2.65,
        "涨跌幅": -4.38,
        "今开": 59.8,
        "最高": 59.8,
        "最低": 57.15,
        "昨收": 60.45,
        "成交量": 2019719.0,
        "成交额": 116460890.0
    },
    {
        "序号": 4362,
        "symbol": "06939",
        "name": "美佳音控股",
        "最新价": 0.65,
        "涨跌额": -0.03,
        "涨跌幅": -4.41,
        "今开": 0.66,
        "最高": 0.66,
        "最低": 0.61,
        "昨收": 0.68,
        "成交量": 194000.0,
        "成交额": 124140.0
    },
    {
        "序号": 4363,
        "symbol": "01110",
        "name": "金活医药集团",
        "最新价": 0.65,
        "涨跌额": -0.03,
        "涨跌幅": -4.41,
        "今开": 0.68,
        "最高": 0.68,
        "最低": 0.65,
        "昨收": 0.68,
        "成交量": 792000.0,
        "成交额": 530160.0
    },
    {
        "序号": 4364,
        "symbol": "02135",
        "name": "瑞丽医美",
        "最新价": 0.086,
        "涨跌额": -0.004,
        "涨跌幅": -4.44,
        "今开": 0.087,
        "最高": 0.088,
        "最低": 0.086,
        "昨收": 0.09,
        "成交量": 360000.0,
        "成交额": 31060.0
    },
    {
        "序号": 4365,
        "symbol": "00261",
        "name": "GBA集团",
        "最新价": 0.129,
        "涨跌额": -0.006,
        "涨跌幅": -4.44,
        "今开": 0.129,
        "最高": 0.129,
        "最低": 0.129,
        "昨收": 0.135,
        "成交量": 47400.0,
        "成交额": 6041.0
    },
    {
        "序号": 4366,
        "symbol": "08446",
        "name": "耀星科技集团",
        "最新价": 1.07,
        "涨跌额": -0.05,
        "涨跌幅": -4.46,
        "今开": 1.15,
        "最高": 1.22,
        "最低": 1.05,
        "昨收": 1.12,
        "成交量": 5720000.0,
        "成交额": 6343860.0
    },
    {
        "序号": 4367,
        "symbol": "06998",
        "name": "嘉和生物-B",
        "最新价": 1.07,
        "涨跌额": -0.05,
        "涨跌幅": -4.46,
        "今开": 1.12,
        "最高": 1.23,
        "最低": 1.06,
        "昨收": 1.12,
        "成交量": 70000.0,
        "成交额": 82825.0
    },
    {
        "序号": 4368,
        "symbol": "03658",
        "name": "新希望服务",
        "最新价": 1.07,
        "涨跌额": -0.05,
        "涨跌幅": -4.46,
        "今开": 1.12,
        "最高": 1.12,
        "最低": 1.07,
        "昨收": 1.12,
        "成交量": 366000.0,
        "成交额": 396000.0
    },
    {
        "序号": 4369,
        "symbol": "02772",
        "name": "中梁控股",
        "最新价": 0.32,
        "涨跌额": -0.015,
        "涨跌幅": -4.48,
        "今开": 0.345,
        "最高": 0.35,
        "最低": 0.305,
        "昨收": 0.335,
        "成交量": 772000.0,
        "成交额": 249570.0
    },
    {
        "序号": 4370,
        "symbol": "01499",
        "name": "欧科云链",
        "最新价": 0.32,
        "涨跌额": -0.015,
        "涨跌幅": -4.48,
        "今开": 0.335,
        "最高": 0.335,
        "最低": 0.32,
        "昨收": 0.335,
        "成交量": 230000.0,
        "成交额": 74550.0
    },
    {
        "序号": 4371,
        "symbol": "01672",
        "name": "歌礼制药-B",
        "最新价": 1.7,
        "涨跌额": -0.08,
        "涨跌幅": -4.49,
        "今开": 1.77,
        "最高": 1.77,
        "最低": 1.66,
        "昨收": 1.78,
        "成交量": 1993000.0,
        "成交额": 3385680.0
    },
    {
        "序号": 4372,
        "symbol": "01030",
        "name": "新城发展",
        "最新价": 1.26,
        "涨跌额": -0.06,
        "涨跌幅": -4.55,
        "今开": 1.32,
        "最高": 1.34,
        "最低": 1.26,
        "昨收": 1.32,
        "成交量": 17866000.0,
        "成交额": 22906610.0
    },
    {
        "序号": 4373,
        "symbol": "02162",
        "name": "康诺亚-B",
        "最新价": 51.4,
        "涨跌额": -2.45,
        "涨跌幅": -4.55,
        "今开": 53.85,
        "最高": 54.85,
        "最低": 47.15,
        "昨收": 53.85,
        "成交量": 4423306.0,
        "成交额": 220792651.0
    },
    {
        "序号": 4374,
        "symbol": "00979",
        "name": "绿色能源科技集团",
        "最新价": 0.167,
        "涨跌额": -0.008,
        "涨跌幅": -4.57,
        "今开": 0.151,
        "最高": 0.169,
        "最低": 0.151,
        "昨收": 0.175,
        "成交量": 1658050.0,
        "成交额": 265075.0
    },
    {
        "序号": 4375,
        "symbol": "02222",
        "name": "雷士国际",
        "最新价": 0.83,
        "涨跌额": -0.04,
        "涨跌幅": -4.6,
        "今开": 0.8,
        "最高": 0.83,
        "最低": 0.8,
        "昨收": 0.87,
        "成交量": 18000.0,
        "成交额": 14160.0
    },
    {
        "序号": 4376,
        "symbol": "02211",
        "name": "大健康国际",
        "最新价": 0.103,
        "涨跌额": -0.005,
        "涨跌幅": -4.63,
        "今开": 0.103,
        "最高": 0.103,
        "最低": 0.103,
        "昨收": 0.108,
        "成交量": 15000.0,
        "成交额": 1545.0
    },
    {
        "序号": 4377,
        "symbol": "09993",
        "name": "金辉控股",
        "最新价": 3.5,
        "涨跌额": -0.17,
        "涨跌幅": -4.63,
        "今开": 3.63,
        "最高": 3.63,
        "最低": 3.49,
        "昨收": 3.67,
        "成交量": 819000.0,
        "成交额": 2893290.0
    },
    {
        "序号": 4378,
        "symbol": "09983",
        "name": "建业新生活",
        "最新价": 1.44,
        "涨跌额": -0.07,
        "涨跌幅": -4.64,
        "今开": 1.49,
        "最高": 1.51,
        "最低": 1.43,
        "昨收": 1.51,
        "成交量": 270000.0,
        "成交额": 400880.0
    },
    {
        "序号": 4379,
        "symbol": "01109",
        "name": "华润置地",
        "最新价": 25.7,
        "涨跌额": -1.25,
        "涨跌幅": -4.64,
        "今开": 27.05,
        "最高": 27.1,
        "最低": 25.45,
        "昨收": 26.95,
        "成交量": 15377282.0,
        "成交额": 397376624.0
    },
    {
        "序号": 4380,
        "symbol": "00842",
        "name": "理士国际",
        "最新价": 1.23,
        "涨跌额": -0.06,
        "涨跌幅": -4.65,
        "今开": 1.28,
        "最高": 1.28,
        "最低": 1.22,
        "昨收": 1.29,
        "成交量": 186000.0,
        "成交额": 234820.0
    },
    {
        "序号": 4381,
        "symbol": "01452",
        "name": "迪诺斯环保",
        "最新价": 0.061,
        "涨跌额": -0.003,
        "涨跌幅": -4.69,
        "今开": 0.061,
        "最高": 0.061,
        "最低": 0.06,
        "昨收": 0.064,
        "成交量": 250000.0,
        "成交额": 15125.0
    },
    {
        "序号": 4382,
        "symbol": "02439",
        "name": "中宝新材",
        "最新价": 2.02,
        "涨跌额": -0.1,
        "涨跌幅": -4.72,
        "今开": 2.08,
        "最高": 2.09,
        "最低": 1.91,
        "昨收": 2.12,
        "成交量": 7586000.0,
        "成交额": 15165200.0
    },
    {
        "序号": 4383,
        "symbol": "08317",
        "name": "财华社集团",
        "最新价": 0.12,
        "涨跌额": -0.006,
        "涨跌幅": -4.76,
        "今开": 0.126,
        "最高": 0.126,
        "最低": 0.115,
        "昨收": 0.126,
        "成交量": 126000.0,
        "成交额": 14660.0
    },
    {
        "序号": 4384,
        "symbol": "06128",
        "name": "烯石电车新材料",
        "最新价": 0.4,
        "涨跌额": -0.02,
        "涨跌幅": -4.76,
        "今开": 0.4,
        "最高": 0.405,
        "最低": 0.4,
        "昨收": 0.42,
        "成交量": 165230.0,
        "成交额": 66132.0
    },
    {
        "序号": 4385,
        "symbol": "00365",
        "name": "芯成科技",
        "最新价": 0.238,
        "涨跌额": -0.012,
        "涨跌幅": -4.8,
        "今开": 0.24,
        "最高": 0.24,
        "最低": 0.238,
        "昨收": 0.25,
        "成交量": 8000.0,
        "成交额": 1910.0
    },
    {
        "序号": 4386,
        "symbol": "08069",
        "name": "纵横游控股",
        "最新价": 0.078,
        "涨跌额": -0.004,
        "涨跌幅": -4.88,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.074,
        "昨收": 0.082,
        "成交量": 1625000.0,
        "成交额": 128135.0
    },
    {
        "序号": 4387,
        "symbol": "06900",
        "name": "上坤地产",
        "最新价": 0.039,
        "涨跌额": -0.002,
        "涨跌幅": -4.88,
        "今开": 0.041,
        "最高": 0.041,
        "最低": 0.039,
        "昨收": 0.041,
        "成交量": 150000.0,
        "成交额": 5950.0
    },
    {
        "序号": 4388,
        "symbol": "01709",
        "name": "德林控股",
        "最新价": 3.9,
        "涨跌额": -0.2,
        "涨跌幅": -4.88,
        "今开": 4.1,
        "最高": 4.25,
        "最低": 3.83,
        "昨收": 4.1,
        "成交量": 699000.0,
        "成交额": 2804170.0
    },
    {
        "序号": 4389,
        "symbol": "01269",
        "name": "首控集团",
        "最新价": 0.039,
        "涨跌额": -0.002,
        "涨跌幅": -4.88,
        "今开": 0.038,
        "最高": 0.039,
        "最低": 0.036,
        "昨收": 0.041,
        "成交量": 260000.0,
        "成交额": 9570.0
    },
    {
        "序号": 4390,
        "symbol": "00717",
        "name": "英皇资本",
        "最新价": 0.039,
        "涨跌额": -0.002,
        "涨跌幅": -4.88,
        "今开": 0.04,
        "最高": 0.041,
        "最低": 0.039,
        "昨收": 0.041,
        "成交量": 1224000.0,
        "成交额": 49962.0
    },
    {
        "序号": 4391,
        "symbol": "01758",
        "name": "博骏教育",
        "最新价": 0.214,
        "涨跌额": -0.011,
        "涨跌幅": -4.89,
        "今开": 0.204,
        "最高": 0.239,
        "最低": 0.198,
        "昨收": 0.225,
        "成交量": 338000.0,
        "成交额": 73794.0
    },
    {
        "序号": 4392,
        "symbol": "02297",
        "name": "润迈德-B",
        "最新价": 0.58,
        "涨跌额": -0.03,
        "涨跌幅": -4.92,
        "今开": 0.61,
        "最高": 0.61,
        "最低": 0.58,
        "昨收": 0.61,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4393,
        "symbol": "01813",
        "name": "合景泰富集团",
        "最新价": 0.58,
        "涨跌额": -0.03,
        "涨跌幅": -4.92,
        "今开": 0.61,
        "最高": 0.62,
        "最低": 0.58,
        "昨收": 0.61,
        "成交量": 11347000.0,
        "成交额": 6763350.0
    },
    {
        "序号": 4394,
        "symbol": "00128",
        "name": "安宁控股",
        "最新价": 0.29,
        "涨跌额": -0.015,
        "涨跌幅": -4.92,
        "今开": 0.3,
        "最高": 0.305,
        "最低": 0.29,
        "昨收": 0.305,
        "成交量": 164000.0,
        "成交额": 48200.0
    },
    {
        "序号": 4395,
        "symbol": "06110",
        "name": "滔搏",
        "最新价": 5.75,
        "涨跌额": -0.3,
        "涨跌幅": -4.96,
        "今开": 5.89,
        "最高": 6.05,
        "最低": 5.75,
        "昨收": 6.05,
        "成交量": 4500893.0,
        "成交额": 26229224.0
    },
    {
        "序号": 4396,
        "symbol": "02155",
        "name": "森松国际",
        "最新价": 5.71,
        "涨跌额": -0.3,
        "涨跌幅": -4.99,
        "今开": 5.94,
        "最高": 6.06,
        "最低": 5.6,
        "昨收": 6.01,
        "成交量": 1299500.0,
        "成交额": 7553505.0
    },
    {
        "序号": 4397,
        "symbol": "03860",
        "name": "EPS创健科技",
        "最新价": 0.95,
        "涨跌额": -0.05,
        "涨跌幅": -5.0,
        "今开": 1.0,
        "最高": 1.0,
        "最低": 0.95,
        "昨收": 1.0,
        "成交量": 10000.0,
        "成交额": 9750.0
    },
    {
        "序号": 4398,
        "symbol": "02892",
        "name": "万城控股",
        "最新价": 0.38,
        "涨跌额": -0.02,
        "涨跌幅": -5.0,
        "今开": 0.41,
        "最高": 0.455,
        "最低": 0.38,
        "昨收": 0.4,
        "成交量": 62000.0,
        "成交额": 25100.0
    },
    {
        "序号": 4399,
        "symbol": "02197",
        "name": "三叶草生物-B",
        "最新价": 0.57,
        "涨跌额": -0.03,
        "涨跌幅": -5.0,
        "今开": 0.6,
        "最高": 0.61,
        "最低": 0.57,
        "昨收": 0.6,
        "成交量": 3957831.0,
        "成交额": 2325990.0
    },
    {
        "序号": 4400,
        "symbol": "01912",
        "name": "康特隆",
        "最新价": 0.038,
        "涨跌额": -0.002,
        "涨跌幅": -5.0,
        "今开": 0.04,
        "最高": 0.04,
        "最低": 0.037,
        "昨收": 0.04,
        "成交量": 110000.0,
        "成交额": 4195.0
    },
    {
        "序号": 4401,
        "symbol": "01718",
        "name": "宏基集团控股-新",
        "最新价": 1.33,
        "涨跌额": -0.07,
        "涨跌幅": -5.0,
        "今开": 1.48,
        "最高": 1.48,
        "最低": 1.33,
        "昨收": 1.4,
        "成交量": 212000.0,
        "成交额": 295970.0
    },
    {
        "序号": 4402,
        "symbol": "03680",
        "name": "瑞和数智",
        "最新价": 1.32,
        "涨跌额": -0.07,
        "涨跌幅": -5.04,
        "今开": 1.39,
        "最高": 1.42,
        "最低": 1.29,
        "昨收": 1.39,
        "成交量": 2814998.0,
        "成交额": 3837497.0
    },
    {
        "序号": 4403,
        "symbol": "01314",
        "name": "翠华控股",
        "最新价": 0.188,
        "涨跌额": -0.01,
        "涨跌幅": -5.05,
        "今开": 0.189,
        "最高": 0.197,
        "最低": 0.187,
        "昨收": 0.198,
        "成交量": 108000.0,
        "成交额": 20482.0
    },
    {
        "序号": 4404,
        "symbol": "02007",
        "name": "碧桂园",
        "最新价": 0.75,
        "涨跌额": -0.04,
        "涨跌幅": -5.06,
        "今开": 0.8,
        "最高": 0.8,
        "最低": 0.74,
        "昨收": 0.79,
        "成交量": 240927127.0,
        "成交额": 184357737.0
    },
    {
        "序号": 4405,
        "symbol": "00813",
        "name": "世茂集团",
        "最新价": 0.56,
        "涨跌额": -0.03,
        "涨跌幅": -5.08,
        "今开": 0.59,
        "最高": 0.61,
        "最低": 0.56,
        "昨收": 0.59,
        "成交量": 12019736.0,
        "成交额": 7044474.0
    },
    {
        "序号": 4406,
        "symbol": "00094",
        "name": "绿心集团",
        "最新价": 0.037,
        "涨跌额": -0.002,
        "涨跌幅": -5.13,
        "今开": 0.036,
        "最高": 0.038,
        "最低": 0.034,
        "昨收": 0.039,
        "成交量": 690000.0,
        "成交额": 24472.0
    },
    {
        "序号": 4407,
        "symbol": "00710",
        "name": "京东方精电",
        "最新价": 6.45,
        "涨跌额": -0.35,
        "涨跌幅": -5.15,
        "今开": 6.8,
        "最高": 6.88,
        "最低": 6.38,
        "昨收": 6.8,
        "成交量": 5648000.0,
        "成交额": 36997252.0
    },
    {
        "序号": 4408,
        "symbol": "02157",
        "name": "乐普生物-B",
        "最新价": 3.3,
        "涨跌额": -0.18,
        "涨跌幅": -5.17,
        "今开": 3.48,
        "最高": 3.48,
        "最低": 3.3,
        "昨收": 3.48,
        "成交量": 1343000.0,
        "成交额": 4528910.0
    },
    {
        "序号": 4409,
        "symbol": "01966",
        "name": "中骏集团控股",
        "最新价": 0.165,
        "涨跌额": -0.009,
        "涨跌幅": -5.17,
        "今开": 0.188,
        "最高": 0.188,
        "最低": 0.165,
        "昨收": 0.174,
        "成交量": 2379000.0,
        "成交额": 399234.0
    },
    {
        "序号": 4410,
        "symbol": "01815",
        "name": "金猫银猫",
        "最新价": 0.275,
        "涨跌额": -0.015,
        "涨跌幅": -5.17,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.275,
        "昨收": 0.29,
        "成交量": 95100.0,
        "成交额": 26176.0
    },
    {
        "序号": 4411,
        "symbol": "00698",
        "name": "通达集团",
        "最新价": 0.11,
        "涨跌额": -0.006,
        "涨跌幅": -5.17,
        "今开": 0.116,
        "最高": 0.118,
        "最低": 0.11,
        "昨收": 0.116,
        "成交量": 6000000.0,
        "成交额": 687100.0
    },
    {
        "序号": 4412,
        "symbol": "01460",
        "name": "扬科集团",
        "最新价": 0.201,
        "涨跌额": -0.011,
        "涨跌幅": -5.19,
        "今开": 0.192,
        "最高": 0.201,
        "最低": 0.192,
        "昨收": 0.212,
        "成交量": 16000.0,
        "成交额": 3144.0
    },
    {
        "序号": 4413,
        "symbol": "02156",
        "name": "建发物业",
        "最新价": 3.47,
        "涨跌额": -0.19,
        "涨跌幅": -5.19,
        "今开": 3.77,
        "最高": 3.77,
        "最低": 3.47,
        "昨收": 3.66,
        "成交量": 2136000.0,
        "成交额": 7657940.0
    },
    {
        "序号": 4414,
        "symbol": "01929",
        "name": "周大福",
        "最新价": 10.3,
        "涨跌额": -0.57,
        "涨跌幅": -5.24,
        "今开": 10.84,
        "最高": 10.9,
        "最低": 10.22,
        "昨收": 10.87,
        "成交量": 13734580.0,
        "成交额": 142869177.0
    },
    {
        "序号": 4415,
        "symbol": "08392",
        "name": "舍图控股",
        "最新价": 0.036,
        "涨跌额": -0.002,
        "涨跌幅": -5.26,
        "今开": 0.038,
        "最高": 0.038,
        "最低": 0.036,
        "昨收": 0.038,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4416,
        "symbol": "01407",
        "name": "交运燃气",
        "最新价": 0.54,
        "涨跌额": -0.03,
        "涨跌幅": -5.26,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.54,
        "昨收": 0.57,
        "成交量": 744000.0,
        "成交额": 408780.0
    },
    {
        "序号": 4417,
        "symbol": "00493",
        "name": "国美零售",
        "最新价": 0.054,
        "涨跌额": -0.003,
        "涨跌幅": -5.26,
        "今开": 0.057,
        "最高": 0.063,
        "最低": 0.054,
        "昨收": 0.057,
        "成交量": 3251615984.0,
        "成交额": 188462967.0
    },
    {
        "序号": 4418,
        "symbol": "00139",
        "name": "中达集团控股",
        "最新价": 0.018,
        "涨跌额": -0.001,
        "涨跌幅": -5.26,
        "今开": 0.019,
        "最高": 0.019,
        "最低": 0.018,
        "昨收": 0.019,
        "成交量": 4942000.0,
        "成交额": 89502.0
    },
    {
        "序号": 4419,
        "symbol": "01357",
        "name": "美图公司",
        "最新价": 3.55,
        "涨跌额": -0.2,
        "涨跌幅": -5.33,
        "今开": 3.8,
        "最高": 3.87,
        "最低": 3.51,
        "昨收": 3.75,
        "成交量": 177505000.0,
        "成交额": 639277504.0
    },
    {
        "序号": 4420,
        "symbol": "01780",
        "name": "B & D STRATEGIC",
        "最新价": 2.12,
        "涨跌额": -0.12,
        "涨跌幅": -5.36,
        "今开": 2.24,
        "最高": 2.25,
        "最低": 2.1,
        "昨收": 2.24,
        "成交量": 470000.0,
        "成交额": 1031675.0
    },
    {
        "序号": 4421,
        "symbol": "01348",
        "name": "滉达富控股",
        "最新价": 0.035,
        "涨跌额": -0.002,
        "涨跌幅": -5.41,
        "今开": 0.037,
        "最高": 0.037,
        "最低": 0.035,
        "昨收": 0.037,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4422,
        "symbol": "01097",
        "name": "有线宽频",
        "最新价": 0.035,
        "涨跌额": -0.002,
        "涨跌幅": -5.41,
        "今开": 0.035,
        "最高": 0.035,
        "最低": 0.035,
        "昨收": 0.037,
        "成交量": 3368589.0,
        "成交额": 118246.0
    },
    {
        "序号": 4423,
        "symbol": "08070",
        "name": "侨洋国际控股",
        "最新价": 0.26,
        "涨跌额": -0.015,
        "涨跌幅": -5.45,
        "今开": 0.26,
        "最高": 0.26,
        "最低": 0.26,
        "昨收": 0.275,
        "成交量": 45000.0,
        "成交额": 11700.0
    },
    {
        "序号": 4424,
        "symbol": "06133",
        "name": "维太创科",
        "最新价": 0.26,
        "涨跌额": -0.015,
        "涨跌幅": -5.45,
        "今开": 0.275,
        "最高": 0.275,
        "最低": 0.26,
        "昨收": 0.275,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4425,
        "symbol": "00262",
        "name": "迪臣发展国际",
        "最新价": 0.052,
        "涨跌额": -0.003,
        "涨跌幅": -5.45,
        "今开": 0.05,
        "最高": 0.052,
        "最低": 0.05,
        "昨收": 0.055,
        "成交量": 125000.0,
        "成交额": 6250.0
    },
    {
        "序号": 4426,
        "symbol": "00789",
        "name": "雅天妮集团",
        "最新价": 0.69,
        "涨跌额": -0.04,
        "涨跌幅": -5.48,
        "今开": 0.73,
        "最高": 0.74,
        "最低": 0.69,
        "昨收": 0.73,
        "成交量": 2372100.0,
        "成交额": 1703513.0
    },
    {
        "序号": 4427,
        "symbol": "00108",
        "name": "国锐生活",
        "最新价": 0.345,
        "涨跌额": -0.02,
        "涨跌幅": -5.48,
        "今开": 0.365,
        "最高": 0.38,
        "最低": 0.345,
        "昨收": 0.365,
        "成交量": 702000.0,
        "成交额": 251120.0
    },
    {
        "序号": 4428,
        "symbol": "00305",
        "name": "五菱汽车",
        "最新价": 0.42,
        "涨跌额": -0.025,
        "涨跌幅": -5.62,
        "今开": 0.445,
        "最高": 0.445,
        "最低": 0.42,
        "昨收": 0.445,
        "成交量": 2860000.0,
        "成交额": 1219150.0
    },
    {
        "序号": 4429,
        "symbol": "01061",
        "name": "亿胜生物科技",
        "最新价": 2.18,
        "涨跌额": -0.13,
        "涨跌幅": -5.63,
        "今开": 2.31,
        "最高": 2.41,
        "最低": 2.14,
        "昨收": 2.31,
        "成交量": 412000.0,
        "成交额": 908070.0
    },
    {
        "序号": 4430,
        "symbol": "08405",
        "name": "恒智控股",
        "最新价": 0.66,
        "涨跌额": -0.04,
        "涨跌幅": -5.71,
        "今开": 0.66,
        "最高": 0.66,
        "最低": 0.66,
        "昨收": 0.7,
        "成交量": 28000.0,
        "成交额": 18480.0
    },
    {
        "序号": 4431,
        "symbol": "01137",
        "name": "香港科技探索",
        "最新价": 2.47,
        "涨跌额": -0.15,
        "涨跌幅": -5.73,
        "今开": 2.68,
        "最高": 2.73,
        "最低": 2.45,
        "昨收": 2.62,
        "成交量": 9524000.0,
        "成交额": 24448860.0
    },
    {
        "序号": 4432,
        "symbol": "01516",
        "name": "融创服务",
        "最新价": 1.97,
        "涨跌额": -0.12,
        "涨跌幅": -5.74,
        "今开": 2.11,
        "最高": 2.12,
        "最低": 1.94,
        "昨收": 2.09,
        "成交量": 31311450.0,
        "成交额": 62569654.0
    },
    {
        "序号": 4433,
        "symbol": "02487",
        "name": "科笛-B",
        "最新价": 16.4,
        "涨跌额": -1.0,
        "涨跌幅": -5.75,
        "今开": 19.0,
        "最高": 19.0,
        "最低": 15.0,
        "昨收": 17.4,
        "成交量": 75755.0,
        "成交额": 1211718.0
    },
    {
        "序号": 4434,
        "symbol": "00471",
        "name": "中播数据",
        "最新价": 0.18,
        "涨跌额": -0.011,
        "涨跌幅": -5.76,
        "今开": 0.188,
        "最高": 0.193,
        "最低": 0.168,
        "昨收": 0.191,
        "成交量": 12532000.0,
        "成交额": 2243364.0
    },
    {
        "序号": 4435,
        "symbol": "09868",
        "name": "小鹏汽车-W",
        "最新价": 60.4,
        "涨跌额": -3.7,
        "涨跌幅": -5.77,
        "今开": 63.1,
        "最高": 63.15,
        "最低": 59.85,
        "昨收": 64.1,
        "成交量": 12859894.0,
        "成交额": 782095664.0
    },
    {
        "序号": 4436,
        "symbol": "00080",
        "name": "中国新经济投资",
        "最新价": 0.13,
        "涨跌额": -0.008,
        "涨跌幅": -5.8,
        "今开": 0.13,
        "最高": 0.13,
        "最低": 0.13,
        "昨收": 0.138,
        "成交量": 5000000.0,
        "成交额": 650000.0
    },
    {
        "序号": 4437,
        "symbol": "02108",
        "name": "K2 F&B",
        "最新价": 0.161,
        "涨跌额": -0.01,
        "涨跌幅": -5.85,
        "今开": 0.16,
        "最高": 0.162,
        "最低": 0.16,
        "昨收": 0.171,
        "成交量": 48000.0,
        "成交额": 7724.0
    },
    {
        "序号": 4438,
        "symbol": "08613",
        "name": "东方支付集团控股",
        "最新价": 0.079,
        "涨跌额": -0.005,
        "涨跌幅": -5.95,
        "今开": 0.084,
        "最高": 0.084,
        "最低": 0.078,
        "昨收": 0.084,
        "成交量": 40000.0,
        "成交额": 3300.0
    },
    {
        "序号": 4439,
        "symbol": "01608",
        "name": "伟能集团",
        "最新价": 0.395,
        "涨跌额": -0.025,
        "涨跌幅": -5.95,
        "今开": 0.4,
        "最高": 0.42,
        "最低": 0.385,
        "昨收": 0.42,
        "成交量": 153000.0,
        "成交额": 60630.0
    },
    {
        "序号": 4440,
        "symbol": "09985",
        "name": "卫龙",
        "最新价": 6.74,
        "涨跌额": -0.43,
        "涨跌幅": -6.0,
        "今开": 7.18,
        "最高": 7.2,
        "最低": 6.7,
        "昨收": 7.17,
        "成交量": 1025600.0,
        "成交额": 7167200.0
    },
    {
        "序号": 4441,
        "symbol": "08311",
        "name": "圆美光电",
        "最新价": 0.047,
        "涨跌额": -0.003,
        "涨跌幅": -6.0,
        "今开": 0.047,
        "最高": 0.047,
        "最低": 0.047,
        "昨收": 0.05,
        "成交量": 200000.0,
        "成交额": 9400.0
    },
    {
        "序号": 4442,
        "symbol": "01716",
        "name": "毛记葵涌",
        "最新价": 0.39,
        "涨跌额": -0.025,
        "涨跌幅": -6.02,
        "今开": 0.415,
        "最高": 0.415,
        "最低": 0.39,
        "昨收": 0.415,
        "成交量": 108000.0,
        "成交额": 43700.0
    },
    {
        "序号": 4443,
        "symbol": "08507",
        "name": "爱世纪集团",
        "最新价": 0.093,
        "涨跌额": -0.006,
        "涨跌幅": -6.06,
        "今开": 0.094,
        "最高": 0.094,
        "最低": 0.093,
        "昨收": 0.099,
        "成交量": 8000.0,
        "成交额": 748.0
    },
    {
        "序号": 4444,
        "symbol": "00460",
        "name": "四环医药",
        "最新价": 0.62,
        "涨跌额": -0.04,
        "涨跌幅": -6.06,
        "今开": 0.65,
        "最高": 0.66,
        "最低": 0.62,
        "昨收": 0.66,
        "成交量": 17163913.0,
        "成交额": 10828516.0
    },
    {
        "序号": 4445,
        "symbol": "00853",
        "name": "微创医疗",
        "最新价": 8.02,
        "涨跌额": -0.52,
        "涨跌幅": -6.09,
        "今开": 8.56,
        "最高": 8.61,
        "最低": 7.93,
        "昨收": 8.54,
        "成交量": 33548700.0,
        "成交额": 272623280.0
    },
    {
        "序号": 4446,
        "symbol": "08307",
        "name": "密迪斯肌",
        "最新价": 0.199,
        "涨跌额": -0.013,
        "涨跌幅": -6.13,
        "今开": 0.199,
        "最高": 0.199,
        "最低": 0.199,
        "昨收": 0.212,
        "成交量": 208000.0,
        "成交额": 41392.0
    },
    {
        "序号": 4447,
        "symbol": "09979",
        "name": "绿城管理控股",
        "最新价": 5.3,
        "涨跌额": -0.35,
        "涨跌幅": -6.19,
        "今开": 5.63,
        "最高": 5.72,
        "最低": 5.3,
        "昨收": 5.65,
        "成交量": 2160000.0,
        "成交额": 11691840.0
    },
    {
        "序号": 4448,
        "symbol": "00120",
        "name": "COSMOPOL INT'L",
        "最新价": 2.56,
        "涨跌额": -0.17,
        "涨跌幅": -6.23,
        "今开": 2.73,
        "最高": 2.73,
        "最低": 2.52,
        "昨收": 2.73,
        "成交量": 2695203.0,
        "成交额": 7003427.0
    },
    {
        "序号": 4449,
        "symbol": "08269",
        "name": "富誉控股",
        "最新价": 0.045,
        "涨跌额": -0.003,
        "涨跌幅": -6.25,
        "今开": 0.048,
        "最高": 0.048,
        "最低": 0.045,
        "昨收": 0.048,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4450,
        "symbol": "08057",
        "name": "麦迪森控股",
        "最新价": 0.15,
        "涨跌额": -0.01,
        "涨跌幅": -6.25,
        "今开": 0.16,
        "最高": 0.16,
        "最低": 0.117,
        "昨收": 0.16,
        "成交量": 92000.0,
        "成交额": 12652.0
    },
    {
        "序号": 4451,
        "symbol": "01532",
        "name": "中国派对文化",
        "最新价": 0.09,
        "涨跌额": -0.006,
        "涨跌幅": -6.25,
        "今开": 0.096,
        "最高": 0.096,
        "最低": 0.09,
        "昨收": 0.096,
        "成交量": 267000.0,
        "成交额": 24876.0
    },
    {
        "序号": 4452,
        "symbol": "03918",
        "name": "金界控股",
        "最新价": 3.26,
        "涨跌额": -0.22,
        "涨跌幅": -6.32,
        "今开": 3.5,
        "最高": 3.5,
        "最低": 3.25,
        "昨收": 3.48,
        "成交量": 2614476.0,
        "成交额": 8690259.0
    },
    {
        "序号": 4453,
        "symbol": "02469",
        "name": "粉笔",
        "最新价": 5.1,
        "涨跌额": -0.35,
        "涨跌幅": -6.42,
        "今开": 5.45,
        "最高": 5.45,
        "最低": 4.9,
        "昨收": 5.45,
        "成交量": 82073214.0,
        "成交额": 419784768.0
    },
    {
        "序号": 4454,
        "symbol": "02168",
        "name": "佳兆业美好",
        "最新价": 1.31,
        "涨跌额": -0.09,
        "涨跌幅": -6.43,
        "今开": 1.4,
        "最高": 1.4,
        "最低": 1.3,
        "昨收": 1.4,
        "成交量": 15000.0,
        "成交额": 20240.0
    },
    {
        "序号": 4455,
        "symbol": "00712",
        "name": "卡姆丹克太阳能",
        "最新价": 0.156,
        "涨跌额": -0.011,
        "涨跌幅": -6.59,
        "今开": 0.165,
        "最高": 0.17,
        "最低": 0.15,
        "昨收": 0.167,
        "成交量": 2867500.0,
        "成交额": 452827.0
    },
    {
        "序号": 4456,
        "symbol": "03639",
        "name": "亿达中国",
        "最新价": 0.17,
        "涨跌额": -0.012,
        "涨跌幅": -6.59,
        "今开": 0.182,
        "最高": 0.187,
        "最低": 0.16,
        "昨收": 0.182,
        "成交量": 2970000.0,
        "成交额": 517416.0
    },
    {
        "序号": 4457,
        "symbol": "02112",
        "name": "恩典生命科技",
        "最新价": 0.35,
        "涨跌额": -0.025,
        "涨跌幅": -6.67,
        "今开": 0.35,
        "最高": 0.375,
        "最低": 0.345,
        "昨收": 0.375,
        "成交量": 136000.0,
        "成交额": 47910.0
    },
    {
        "序号": 4458,
        "symbol": "01825",
        "name": "STERLING GP",
        "最新价": 0.14,
        "涨跌额": -0.01,
        "涨跌幅": -6.67,
        "今开": 0.147,
        "最高": 0.15,
        "最低": 0.137,
        "昨收": 0.15,
        "成交量": 395000.0,
        "成交额": 58487.0
    },
    {
        "序号": 4459,
        "symbol": "00682",
        "name": "超大现代",
        "最新价": 0.014,
        "涨跌额": -0.001,
        "涨跌幅": -6.67,
        "今开": 0.014,
        "最高": 0.014,
        "最低": 0.014,
        "昨收": 0.015,
        "成交量": 1114370.0,
        "成交额": 15600.0
    },
    {
        "序号": 4460,
        "symbol": "00990",
        "name": "荣晖国际",
        "最新价": 0.485,
        "涨跌额": -0.035,
        "涨跌幅": -6.73,
        "今开": 0.52,
        "最高": 0.53,
        "最低": 0.48,
        "昨收": 0.52,
        "成交量": 14750000.0,
        "成交额": 7289250.0
    },
    {
        "序号": 4461,
        "symbol": "08413",
        "name": "亚洲富思",
        "最新价": 0.11,
        "涨跌额": -0.008,
        "涨跌幅": -6.78,
        "今开": 0.114,
        "最高": 0.12,
        "最低": 0.11,
        "昨收": 0.118,
        "成交量": 3250000.0,
        "成交额": 370670.0
    },
    {
        "序号": 4462,
        "symbol": "02251",
        "name": "鹰瞳科技-B",
        "最新价": 11.0,
        "涨跌额": -0.8,
        "涨跌幅": -6.78,
        "今开": 11.76,
        "最高": 11.78,
        "最低": 10.84,
        "昨收": 11.8,
        "成交量": 269100.0,
        "成交额": 3077418.0
    },
    {
        "序号": 4463,
        "symbol": "00076",
        "name": "谊砾控股",
        "最新价": 0.178,
        "涨跌额": -0.013,
        "涨跌幅": -6.81,
        "今开": 0.172,
        "最高": 0.178,
        "最低": 0.172,
        "昨收": 0.191,
        "成交量": 5085.0,
        "成交额": 867.0
    },
    {
        "序号": 4464,
        "symbol": "01251",
        "name": "华油能源",
        "最新价": 0.231,
        "涨跌额": -0.017,
        "涨跌幅": -6.85,
        "今开": 0.248,
        "最高": 0.248,
        "最低": 0.222,
        "昨收": 0.248,
        "成交量": 152000.0,
        "成交额": 35250.0
    },
    {
        "序号": 4465,
        "symbol": "08428",
        "name": "国茂控股",
        "最新价": 1.08,
        "涨跌额": -0.08,
        "涨跌幅": -6.9,
        "今开": 0.95,
        "最高": 1.15,
        "最低": 0.95,
        "昨收": 1.16,
        "成交量": 201601.0,
        "成交额": 212997.0
    },
    {
        "序号": 4466,
        "symbol": "00708",
        "name": "恒大汽车",
        "最新价": 0.27,
        "涨跌额": -0.02,
        "涨跌幅": -6.9,
        "今开": 0.29,
        "最高": 0.295,
        "最低": 0.27,
        "昨收": 0.29,
        "成交量": 10061500.0,
        "成交额": 2824122.0
    },
    {
        "序号": 4467,
        "symbol": "00311",
        "name": "联泰控股",
        "最新价": 0.27,
        "涨跌额": -0.02,
        "涨跌幅": -6.9,
        "今开": 0.26,
        "最高": 0.275,
        "最低": 0.26,
        "昨收": 0.29,
        "成交量": 17000.0,
        "成交额": 4515.0
    },
    {
        "序号": 4468,
        "symbol": "02126",
        "name": "药明巨诺-B",
        "最新价": 2.94,
        "涨跌额": -0.22,
        "涨跌幅": -6.96,
        "今开": 3.02,
        "最高": 3.07,
        "最低": 2.93,
        "昨收": 3.16,
        "成交量": 403000.0,
        "成交额": 1205695.0
    },
    {
        "序号": 4469,
        "symbol": "03686",
        "name": "祈福生活服务",
        "最新价": 0.465,
        "涨跌额": -0.035,
        "涨跌幅": -7.0,
        "今开": 0.465,
        "最高": 0.465,
        "最低": 0.465,
        "昨收": 0.5,
        "成交量": 20000.0,
        "成交额": 9300.0
    },
    {
        "序号": 4470,
        "symbol": "09690",
        "name": "途虎-W",
        "最新价": 29.75,
        "涨跌额": -2.25,
        "涨跌幅": -7.03,
        "今开": 31.85,
        "最高": 32.1,
        "最低": 29.35,
        "昨收": 32.0,
        "成交量": 1259100.0,
        "成交额": 38444020.0
    },
    {
        "序号": 4471,
        "symbol": "01782",
        "name": "国际商业数字技术",
        "最新价": 6.56,
        "涨跌额": -0.51,
        "涨跌幅": -7.21,
        "今开": 6.51,
        "最高": 6.56,
        "最低": 6.5,
        "昨收": 7.07,
        "成交量": 30000.0,
        "成交额": 195050.0
    },
    {
        "序号": 4472,
        "symbol": "01818",
        "name": "招金矿业",
        "最新价": 9.48,
        "涨跌额": -0.74,
        "涨跌幅": -7.24,
        "今开": 10.18,
        "最高": 10.18,
        "最低": 9.47,
        "昨收": 10.22,
        "成交量": 11836994.0,
        "成交额": 114847350.0
    },
    {
        "序号": 4473,
        "symbol": "03377",
        "name": "远洋集团",
        "最新价": 0.44,
        "涨跌额": -0.035,
        "涨跌幅": -7.37,
        "今开": 0.47,
        "最高": 0.48,
        "最低": 0.43,
        "昨收": 0.475,
        "成交量": 160580000.0,
        "成交额": 72408737.0
    },
    {
        "序号": 4474,
        "symbol": "08619",
        "name": "WAC HOLDINGS",
        "最新价": 0.138,
        "涨跌额": -0.011,
        "涨跌幅": -7.38,
        "今开": 0.15,
        "最高": 0.15,
        "最低": 0.13,
        "昨收": 0.149,
        "成交量": 8116000.0,
        "成交额": 1138968.0
    },
    {
        "序号": 4475,
        "symbol": "01980",
        "name": "天鸽互动",
        "最新价": 0.375,
        "涨跌额": -0.03,
        "涨跌幅": -7.41,
        "今开": 0.385,
        "最高": 0.4,
        "最低": 0.375,
        "昨收": 0.405,
        "成交量": 1342000.0,
        "成交额": 518975.0
    },
    {
        "序号": 4476,
        "symbol": "01566",
        "name": "华夏文化科技",
        "最新价": 0.025,
        "涨跌额": -0.002,
        "涨跌幅": -7.41,
        "今开": 0.027,
        "最高": 0.027,
        "最低": 0.025,
        "昨收": 0.027,
        "成交量": 37000.0,
        "成交额": 989.0
    },
    {
        "序号": 4477,
        "symbol": "08007",
        "name": "环球战略集团",
        "最新价": 0.62,
        "涨跌额": -0.05,
        "涨跌幅": -7.46,
        "今开": 0.67,
        "最高": 0.69,
        "最低": 0.6,
        "昨收": 0.67,
        "成交量": 3710025.0,
        "成交额": 2322514.0
    },
    {
        "序号": 4478,
        "symbol": "01878",
        "name": "南戈壁",
        "最新价": 1.48,
        "涨跌额": -0.12,
        "涨跌幅": -7.5,
        "今开": 1.64,
        "最高": 1.64,
        "最低": 1.45,
        "昨收": 1.6,
        "成交量": 1442300.0,
        "成交额": 2219152.0
    },
    {
        "序号": 4479,
        "symbol": "03836",
        "name": "和谐汽车",
        "最新价": 0.61,
        "涨跌额": -0.05,
        "涨跌幅": -7.58,
        "今开": 0.65,
        "最高": 0.67,
        "最低": 0.61,
        "昨收": 0.66,
        "成交量": 776000.0,
        "成交额": 487485.0
    },
    {
        "序号": 4480,
        "symbol": "02131",
        "name": "云想科技",
        "最新价": 0.61,
        "涨跌额": -0.05,
        "涨跌幅": -7.58,
        "今开": 0.65,
        "最高": 0.68,
        "最低": 0.58,
        "昨收": 0.66,
        "成交量": 2162000.0,
        "成交额": 1328630.0
    },
    {
        "序号": 4481,
        "symbol": "01875",
        "name": "东曜药业-B",
        "最新价": 1.83,
        "涨跌额": -0.15,
        "涨跌幅": -7.58,
        "今开": 1.84,
        "最高": 1.87,
        "最低": 1.81,
        "昨收": 1.98,
        "成交量": 50400.0,
        "成交额": 92216.0
    },
    {
        "序号": 4482,
        "symbol": "01661",
        "name": "智美体育",
        "最新价": 0.061,
        "涨跌额": -0.005,
        "涨跌幅": -7.58,
        "今开": 0.066,
        "最高": 0.076,
        "最低": 0.055,
        "昨收": 0.066,
        "成交量": 1815000.0,
        "成交额": 116040.0
    },
    {
        "序号": 4483,
        "symbol": "08510",
        "name": "TOPSTANDARDCORP",
        "最新价": 0.024,
        "涨跌额": -0.002,
        "涨跌幅": -7.69,
        "今开": 0.029,
        "最高": 0.034,
        "最低": 0.024,
        "昨收": 0.026,
        "成交量": 33008000.0,
        "成交额": 972736.0
    },
    {
        "序号": 4484,
        "symbol": "00923",
        "name": "综合环保集团",
        "最新价": 0.035,
        "涨跌额": -0.003,
        "涨跌幅": -7.89,
        "今开": 0.036,
        "最高": 0.036,
        "最低": 0.035,
        "昨收": 0.038,
        "成交量": 36000.0,
        "成交额": 1286.0
    },
    {
        "序号": 4485,
        "symbol": "01930",
        "name": "勋龙",
        "最新价": 0.29,
        "涨跌额": -0.025,
        "涨跌幅": -7.94,
        "今开": 0.305,
        "最高": 0.305,
        "最低": 0.29,
        "昨收": 0.315,
        "成交量": 20000.0,
        "成交额": 5960.0
    },
    {
        "序号": 4486,
        "symbol": "08106",
        "name": "升华兰德",
        "最新价": 0.069,
        "涨跌额": -0.006,
        "涨跌幅": -8.0,
        "今开": 0.075,
        "最高": 0.075,
        "最低": 0.069,
        "昨收": 0.075,
        "成交量": 0.0,
        "成交额": null
    },
    {
        "序号": 4487,
        "symbol": "01842",
        "name": "植华集团",
        "最新价": 0.092,
        "涨跌额": -0.008,
        "涨跌幅": -8.0,
        "今开": 0.095,
        "最高": 0.095,
        "最低": 0.09,
        "昨收": 0.1,
        "成交量": 1484000.0,
        "成交额": 136076.0
    },
    {
        "序号": 4488,
        "symbol": "09929",
        "name": "澳达控股",
        "最新价": 0.045,
        "涨跌额": -0.004,
        "涨跌幅": -8.16,
        "今开": 0.043,
        "最高": 0.045,
        "最低": 0.043,
        "昨收": 0.049,
        "成交量": 140000.0,
        "成交额": 6040.0
    },
    {
        "序号": 4489,
        "symbol": "01865",
        "name": "卓航控股",
        "最新价": 0.045,
        "涨跌额": -0.004,
        "涨跌幅": -8.16,
        "今开": 0.049,
        "最高": 0.049,
        "最低": 0.045,
        "昨收": 0.049,
        "成交量": 6080000.0,
        "成交额": 278084.0
    },
    {
        "序号": 4490,
        "symbol": "00926",
        "name": "碧生源",
        "最新价": 3.35,
        "涨跌额": -0.3,
        "涨跌幅": -8.22,
        "今开": 3.5,
        "最高": 3.6,
        "最低": 3.35,
        "昨收": 3.65,
        "成交量": 7000.0,
        "成交额": 23890.0
    },
    {
        "序号": 4491,
        "symbol": "00513",
        "name": "恒和集团",
        "最新价": 0.221,
        "涨跌额": -0.02,
        "涨跌幅": -8.3,
        "今开": 0.241,
        "最高": 0.241,
        "最低": 0.221,
        "昨收": 0.241,
        "成交量": 34000.0,
        "成交额": 7890.0
    },
    {
        "序号": 4492,
        "symbol": "08471",
        "name": "新达控股",
        "最新价": 0.165,
        "涨跌额": -0.015,
        "涨跌幅": -8.33,
        "今开": 0.183,
        "最高": 0.183,
        "最低": 0.165,
        "昨收": 0.18,
        "成交量": 220000.0,
        "成交额": 38820.0
    },
    {
        "序号": 4493,
        "symbol": "08250",
        "name": "丝路能源",
        "最新价": 0.187,
        "涨跌额": -0.017,
        "涨跌幅": -8.33,
        "今开": 0.209,
        "最高": 0.209,
        "最低": 0.186,
        "昨收": 0.204,
        "成交量": 45000.0,
        "成交额": 8620.0
    },
    {
        "序号": 4494,
        "symbol": "08232",
        "name": "CLASSIFIED GP",
        "最新价": 0.55,
        "涨跌额": -0.05,
        "涨跌幅": -8.33,
        "今开": 0.56,
        "最高": 0.56,
        "最低": 0.55,
        "昨收": 0.6,
        "成交量": 35000.0,
        "成交额": 18900.0
    },
    {
        "序号": 4495,
        "symbol": "00755",
        "name": "上海证大",
        "最新价": 0.011,
        "涨跌额": -0.001,
        "涨跌幅": -8.33,
        "今开": 0.012,
        "最高": 0.012,
        "最低": 0.01,
        "昨收": 0.012,
        "成交量": 495000.0,
        "成交额": 5535.0
    },
    {
        "序号": 4496,
        "symbol": "01873",
        "name": "维亚生物",
        "最新价": 0.98,
        "涨跌额": -0.09,
        "涨跌幅": -8.41,
        "今开": 1.07,
        "最高": 1.08,
        "最低": 0.98,
        "昨收": 1.07,
        "成交量": 3721746.0,
        "成交额": 3737960.0
    },
    {
        "序号": 4497,
        "symbol": "00948",
        "name": "阿尔法企业",
        "最新价": 0.37,
        "涨跌额": -0.035,
        "涨跌幅": -8.64,
        "今开": 0.395,
        "最高": 0.395,
        "最低": 0.37,
        "昨收": 0.405,
        "成交量": 66000.0,
        "成交额": 25960.0
    },
    {
        "序号": 4498,
        "symbol": "01176",
        "name": "珠光控股",
        "最新价": 0.242,
        "涨跌额": -0.023,
        "涨跌幅": -8.68,
        "今开": 0.265,
        "最高": 0.285,
        "最低": 0.24,
        "昨收": 0.265,
        "成交量": 500138000.0,
        "成交额": 130564694.0
    },
    {
        "序号": 4499,
        "symbol": "02330",
        "name": "中国上城",
        "最新价": 0.21,
        "涨跌额": -0.02,
        "涨跌幅": -8.7,
        "今开": 0.225,
        "最高": 0.225,
        "最低": 0.21,
        "昨收": 0.23,
        "成交量": 147200.0,
        "成交额": 30931.0
    },
    {
        "序号": 4500,
        "symbol": "00959",
        "name": "世纪娱乐国际",
        "最新价": 0.21,
        "涨跌额": -0.02,
        "涨跌幅": -8.7,
        "今开": 0.225,
        "最高": 0.225,
        "最低": 0.166,
        "昨收": 0.23,
        "成交量": 86000.0,
        "成交额": 17370.0
    },
    {
        "序号": 4501,
        "symbol": "03928",
        "name": "S&T HLDGS",
        "最新价": 1.46,
        "涨跌额": -0.14,
        "涨跌幅": -8.75,
        "今开": 1.4,
        "最高": 1.46,
        "最低": 1.4,
        "昨收": 1.6,
        "成交量": 40000.0,
        "成交额": 56000.0
    },
    {
        "序号": 4502,
        "symbol": "02216",
        "name": "堃博医疗-B",
        "最新价": 0.72,
        "涨跌额": -0.07,
        "涨跌幅": -8.86,
        "今开": 0.78,
        "最高": 0.79,
        "最低": 0.71,
        "昨收": 0.79,
        "成交量": 898800.0,
        "成交额": 679586.0
    },
    {
        "序号": 4503,
        "symbol": "01915",
        "name": "泰和小贷",
        "最新价": 0.72,
        "涨跌额": -0.07,
        "涨跌幅": -8.86,
        "今开": 0.72,
        "最高": 0.77,
        "最低": 0.7,
        "昨收": 0.79,
        "成交量": 226000.0,
        "成交额": 160260.0
    },
    {
        "序号": 4504,
        "symbol": "00884",
        "name": "旭辉控股集团",
        "最新价": 0.25,
        "涨跌额": -0.025,
        "涨跌幅": -9.09,
        "今开": 0.28,
        "最高": 0.285,
        "最低": 0.249,
        "昨收": 0.275,
        "成交量": 50332506.0,
        "成交额": 13086601.0
    },
    {
        "序号": 4505,
        "symbol": "00509",
        "name": "世纪阳光",
        "最新价": 0.01,
        "涨跌额": -0.001,
        "涨跌幅": -9.09,
        "今开": 0.01,
        "最高": 0.011,
        "最低": 0.01,
        "昨收": 0.011,
        "成交量": 11465000.0,
        "成交额": 114660.0
    },
    {
        "序号": 4506,
        "symbol": "08079",
        "name": "仍志集团控股",
        "最新价": 0.079,
        "涨跌额": -0.008,
        "涨跌幅": -9.2,
        "今开": 0.085,
        "最高": 0.089,
        "最低": 0.07,
        "昨收": 0.087,
        "成交量": 2617000.0,
        "成交额": 208172.0
    },
    {
        "序号": 4507,
        "symbol": "09963",
        "name": "高科桥",
        "最新价": 0.29,
        "涨跌额": -0.03,
        "涨跌幅": -9.38,
        "今开": 0.29,
        "最高": 0.29,
        "最低": 0.29,
        "昨收": 0.32,
        "成交量": 2000.0,
        "成交额": 580.0
    },
    {
        "序号": 4508,
        "symbol": "08131",
        "name": "辰罡科技",
        "最新价": 0.131,
        "涨跌额": -0.014,
        "涨跌幅": -9.66,
        "今开": 0.145,
        "最高": 0.145,
        "最低": 0.126,
        "昨收": 0.145,
        "成交量": 430000.0,
        "成交额": 56180.0
    },
    {
        "序号": 4509,
        "symbol": "00932",
        "name": "顺腾国际控股",
        "最新价": 0.056,
        "涨跌额": -0.006,
        "涨跌幅": -9.68,
        "今开": 0.056,
        "最高": 0.056,
        "最低": 0.056,
        "昨收": 0.062,
        "成交量": 196000.0,
        "成交额": 10976.0
    },
    {
        "序号": 4510,
        "symbol": "00228",
        "name": "中能控股",
        "最新价": 0.084,
        "涨跌额": -0.009,
        "涨跌幅": -9.68,
        "今开": 0.088,
        "最高": 0.091,
        "最低": 0.084,
        "昨收": 0.093,
        "成交量": 2918000.0,
        "成交额": 254582.0
    },
    {
        "序号": 4511,
        "symbol": "01585",
        "name": "雅迪控股",
        "最新价": 13.32,
        "涨跌额": -1.44,
        "涨跌幅": -9.76,
        "今开": 14.7,
        "最高": 14.7,
        "最低": 13.18,
        "昨收": 14.76,
        "成交量": 15108806.0,
        "成交额": 207109306.0
    },
    {
        "序号": 4512,
        "symbol": "01034",
        "name": "富汇国际集团控股",
        "最新价": 0.166,
        "涨跌额": -0.018,
        "涨跌幅": -9.78,
        "今开": 0.186,
        "最高": 0.186,
        "最低": 0.165,
        "昨收": 0.184,
        "成交量": 584000.0,
        "成交额": 97640.0
    },
    {
        "序号": 4513,
        "symbol": "01725",
        "name": "香港航天科技",
        "最新价": 3.68,
        "涨跌额": -0.4,
        "涨跌幅": -9.8,
        "今开": 4.08,
        "最高": 4.11,
        "最低": 3.68,
        "昨收": 4.08,
        "成交量": 4440600.0,
        "成交额": 17096426.0
    },
    {
        "序号": 4514,
        "symbol": "02105",
        "name": "来凯医药-B",
        "最新价": 15.18,
        "涨跌额": -1.68,
        "涨跌幅": -9.96,
        "今开": 16.62,
        "最高": 16.66,
        "最低": 14.6,
        "昨收": 16.86,
        "成交量": 583000.0,
        "成交额": 8845770.0
    },
    {
        "序号": 4515,
        "symbol": "08645",
        "name": "米虫元宇宙",
        "最新价": 0.315,
        "涨跌额": -0.035,
        "涨跌幅": -10.0,
        "今开": 0.315,
        "最高": 0.315,
        "最低": 0.315,
        "昨收": 0.35,
        "成交量": 6000.0,
        "成交额": 1890.0
    },
    {
        "序号": 4516,
        "symbol": "01280",
        "name": "奇点国际",
        "最新价": 2.6,
        "涨跌额": -0.29,
        "涨跌幅": -10.03,
        "今开": 2.89,
        "最高": 2.89,
        "最低": 2.6,
        "昨收": 2.89,
        "成交量": 168000.0,
        "成交额": 457000.0
    },
    {
        "序号": 4517,
        "symbol": "01631",
        "name": "REF HOLDINGS",
        "最新价": 0.31,
        "涨跌额": -0.035,
        "涨跌幅": -10.14,
        "今开": 0.32,
        "最高": 0.32,
        "最低": 0.31,
        "昨收": 0.345,
        "成交量": 830000.0,
        "成交额": 264425.0
    },
    {
        "序号": 4518,
        "symbol": "09663",
        "name": "国鸿氢能",
        "最新价": 15.2,
        "涨跌额": -1.72,
        "涨跌幅": -10.17,
        "今开": 17.3,
        "最高": 17.48,
        "最低": 15.1,
        "昨收": 16.92,
        "成交量": 899500.0,
        "成交额": 14556910.0
    },
    {
        "序号": 4519,
        "symbol": "08241",
        "name": "英记茶庄集团",
        "最新价": 0.148,
        "涨跌额": -0.017,
        "涨跌幅": -10.3,
        "今开": 0.158,
        "最高": 0.158,
        "最低": 0.148,
        "昨收": 0.165,
        "成交量": 265000.0,
        "成交额": 39715.0
    },
    {
        "序号": 4520,
        "symbol": "08140",
        "name": "人和科技",
        "最新价": 0.074,
        "涨跌额": -0.009,
        "涨跌幅": -10.84,
        "今开": 0.083,
        "最高": 0.083,
        "最低": 0.074,
        "昨收": 0.083,
        "成交量": 680000.0,
        "成交额": 53240.0
    },
    {
        "序号": 4521,
        "symbol": "08073",
        "name": "兴业新材料",
        "最新价": 0.086,
        "涨跌额": -0.011,
        "涨跌幅": -11.34,
        "今开": 0.086,
        "最高": 0.097,
        "最低": 0.086,
        "昨收": 0.097,
        "成交量": 1436000.0,
        "成交额": 123680.0
    },
    {
        "序号": 4522,
        "symbol": "00531",
        "name": "顺诚",
        "最新价": 0.176,
        "涨跌额": -0.023,
        "涨跌幅": -11.56,
        "今开": 0.205,
        "最高": 0.205,
        "最低": 0.169,
        "昨收": 0.199,
        "成交量": 5000.0,
        "成交额": 989.0
    },
    {
        "序号": 4523,
        "symbol": "02482",
        "name": "维天运通",
        "最新价": 1.06,
        "涨跌额": -0.14,
        "涨跌幅": -11.67,
        "今开": 1.06,
        "最高": 1.2,
        "最低": 1.06,
        "昨收": 1.2,
        "成交量": 52000.0,
        "成交额": 61760.0
    },
    {
        "序号": 4524,
        "symbol": "08249",
        "name": "瑞远智控",
        "最新价": 0.045,
        "涨跌额": -0.006,
        "涨跌幅": -11.76,
        "今开": 0.051,
        "最高": 0.051,
        "最低": 0.045,
        "昨收": 0.051,
        "成交量": 150000.0,
        "成交额": 6900.0
    },
    {
        "序号": 4525,
        "symbol": "01195",
        "name": "京维集团",
        "最新价": 0.015,
        "涨跌额": -0.002,
        "涨跌幅": -11.76,
        "今开": 0.017,
        "最高": 0.017,
        "最低": 0.015,
        "昨收": 0.017,
        "成交量": 168889.0,
        "成交额": 2620.0
    },
    {
        "序号": 4526,
        "symbol": "08052",
        "name": "陆庆娱乐",
        "最新价": 0.11,
        "涨跌额": -0.015,
        "涨跌幅": -12.0,
        "今开": 0.11,
        "最高": 0.112,
        "最低": 0.11,
        "昨收": 0.125,
        "成交量": 220000.0,
        "成交额": 24260.0
    },
    {
        "序号": 4527,
        "symbol": "00809",
        "name": "大成生化科技",
        "最新价": 0.065,
        "涨跌额": -0.009,
        "涨跌幅": -12.16,
        "今开": 0.074,
        "最高": 0.074,
        "最低": 0.065,
        "昨收": 0.074,
        "成交量": 834000.0,
        "成交额": 56520.0
    },
    {
        "序号": 4528,
        "symbol": "00396",
        "name": "兴利(香港)控股",
        "最新价": 0.057,
        "涨跌额": -0.008,
        "涨跌幅": -12.31,
        "今开": 0.059,
        "最高": 0.059,
        "最低": 0.05,
        "昨收": 0.065,
        "成交量": 2266000.0,
        "成交额": 121142.0
    },
    {
        "序号": 4529,
        "symbol": "06696",
        "name": "多想云",
        "最新价": 0.27,
        "涨跌额": -0.04,
        "涨跌幅": -12.9,
        "今开": 0.3,
        "最高": 0.3,
        "最低": 0.27,
        "昨收": 0.31,
        "成交量": 812000.0,
        "成交额": 223200.0
    },
    {
        "序号": 4530,
        "symbol": "01332",
        "name": "透云生物",
        "最新价": 0.335,
        "涨跌额": -0.05,
        "涨跌幅": -12.99,
        "今开": 0.385,
        "最高": 0.385,
        "最低": 0.335,
        "昨收": 0.385,
        "成交量": 113387812.0,
        "成交额": 38552676.0
    },
    {
        "序号": 4531,
        "symbol": "06955",
        "name": "博安生物",
        "最新价": 14.2,
        "涨跌额": -2.14,
        "涨跌幅": -13.1,
        "今开": 16.2,
        "最高": 16.8,
        "最低": 14.06,
        "昨收": 16.34,
        "成交量": 510200.0,
        "成交额": 8033688.0
    },
    {
        "序号": 4532,
        "symbol": "00073",
        "name": "亚洲果业",
        "最新价": 0.031,
        "涨跌额": -0.005,
        "涨跌幅": -13.89,
        "今开": 0.032,
        "最高": 0.039,
        "最低": 0.027,
        "昨收": 0.036,
        "成交量": 27135869.0,
        "成交额": 848939.0
    },
    {
        "序号": 4533,
        "symbol": "08370",
        "name": "智升集团控股",
        "最新价": 0.048,
        "涨跌额": -0.008,
        "涨跌幅": -14.29,
        "今开": 0.048,
        "最高": 0.048,
        "最低": 0.048,
        "昨收": 0.056,
        "成交量": 144000.0,
        "成交额": 6912.0
    },
    {
        "序号": 4534,
        "symbol": "03978",
        "name": "卓越教育集团",
        "最新价": 0.76,
        "涨跌额": -0.13,
        "涨跌幅": -14.61,
        "今开": 0.86,
        "最高": 0.87,
        "最低": 0.75,
        "昨收": 0.89,
        "成交量": 789000.0,
        "成交额": 618020.0
    },
    {
        "序号": 4535,
        "symbol": "09998",
        "name": "光荣控股",
        "最新价": 0.178,
        "涨跌额": -0.034,
        "涨跌幅": -16.04,
        "今开": 0.178,
        "最高": 0.178,
        "最低": 0.178,
        "昨收": 0.212,
        "成交量": 5000.0,
        "成交额": 890.0
    },
    {
        "序号": 4536,
        "symbol": "00310",
        "name": "嘉进投资国际",
        "最新价": 0.01,
        "涨跌额": -0.002,
        "涨跌幅": -16.67,
        "今开": 0.011,
        "最高": 0.011,
        "最低": 0.01,
        "昨收": 0.012,
        "成交量": 570000.0,
        "成交额": 6180.0
    },
    {
        "序号": 4537,
        "symbol": "03789",
        "name": "御佳控股",
        "最新价": 0.079,
        "涨跌额": -0.016,
        "涨跌幅": -16.84,
        "今开": 0.078,
        "最高": 0.079,
        "最低": 0.078,
        "昨收": 0.095,
        "成交量": 12000.0,
        "成交额": 936.0
    },
    {
        "序号": 4538,
        "symbol": "01592",
        "name": "基石控股",
        "最新价": 0.065,
        "涨跌额": -0.014,
        "涨跌幅": -17.72,
        "今开": 0.078,
        "最高": 0.078,
        "最低": 0.06,
        "昨收": 0.079,
        "成交量": 24450000.0,
        "成交额": 1688725.0
    },
    {
        "序号": 4539,
        "symbol": "01918",
        "name": "融创中国",
        "最新价": 1.79,
        "涨跌额": -0.43,
        "涨跌幅": -19.37,
        "今开": 2.2,
        "最高": 2.24,
        "最低": 1.79,
        "昨收": 2.22,
        "成交量": 419118960.0,
        "成交额": 817631680.0
    },
    {
        "序号": 4540,
        "symbol": "02358",
        "name": "久融控股",
        "最新价": 0.021,
        "涨跌额": -0.006,
        "涨跌幅": -22.22,
        "今开": 0.029,
        "最高": 0.031,
        "最低": 0.02,
        "昨收": 0.027,
        "成交量": 9020000.0,
        "成交额": 206920.0
    },
    {
        "序号": 4541,
        "symbol": "01220",
        "name": "志道国际",
        "最新价": 0.062,
        "涨跌额": -0.019,
        "涨跌幅": -23.46,
        "今开": 0.08,
        "最高": 0.08,
        "最低": 0.062,
        "昨收": 0.081,
        "成交量": 3280000.0,
        "成交额": 242500.0
    },
    {
        "序号": 4542,
        "symbol": "06816",
        "name": "瑞港建设",
        "最新价": 0.25,
        "涨跌额": -0.08,
        "涨跌幅": -24.24,
        "今开": 0.255,
        "最高": 0.255,
        "最低": 0.25,
        "昨收": 0.33,
        "成交量": 50000.0,
        "成交额": 12600.0
    },
    {
        "序号": 4543,
        "symbol": "08153",
        "name": "嘉鼎国际集团",
        "最新价": 0.042,
        "涨跌额": -0.015,
        "涨跌幅": -26.32,
        "今开": 0.052,
        "最高": 0.054,
        "最低": 0.04,
        "昨收": 0.057,
        "成交量": 64690000.0,
        "成交额": 2730160.0
    },
    {
        "序号": 4544,
        "symbol": "02728",
        "name": "金泰能源控股",
        "最新价": 0.029,
        "涨跌额": -0.011,
        "涨跌幅": -27.5,
        "今开": 0.04,
        "最高": 0.044,
        "最低": 0.027,
        "昨收": 0.04,
        "成交量": 7148000.0,
        "成交额": 237500.0
    },
    {
        "序号": 4545,
        "symbol": "01255",
        "name": "TATA健康",
        "最新价": 0.32,
        "涨跌额": -0.175,
        "涨跌幅": -35.35,
        "今开": 0.2,
        "最高": 0.42,
        "最低": 0.2,
        "昨收": 0.495,
        "成交量": 294000.0,
        "成交额": 106220.0
    },
    {
        "序号": 4546,
        "symbol": "00810",
        "name": "中国铸晨81",
        "最新价": 0.46,
        "涨跌额": -0.35,
        "涨跌幅": -43.21,
        "今开": 0.83,
        "最高": 0.85,
        "最低": 0.45,
        "昨收": 0.81,
        "成交量": 21600000.0,
        "成交额": 14703840.0
    }
]