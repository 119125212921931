export default class KLineWaveNode {
    constructor(startNode, endNode, volume, waveBase) {
        // 波段起点
        this.startNode = startNode
        // 波段终点
        this.endNode = endNode
        // 成交量
        this.volume = volume
        // 线的宽度
        this.lineWidth = 1
        // 是否是上升
        this.isUp = true
        // 波段的基准
        this.waveBase = waveBase

        if (this.endNode.kLineModel.high < this.startNode.kLineModel.high) {
            this.isUp = false
        }
       
    }
    refreshWaveEndNode(endNode) {
        this.endNode = endNode
        if (this.endNode.kLineModel.high < this.startNode.kLineModel.high) {
            this.isUp = false
        }
    }
    
    renderWave(visibleWidth, visibleHeight, maxPrice, minPrice,pointWidth) {
        let everyPriceHeight = visibleHeight / (maxPrice - minPrice)
        let startY = everyPriceHeight * (maxPrice - this.startNode.kLineModel.high)
        let startX = this.startNode.index * pointWidth + pointWidth / 2.0

        this.startPoint = { 'x': startX, 'y': startY }

        let endY = (maxPrice - this.endNode.kLineModel.high) * everyPriceHeight
        let endX = this.endNode.index * pointWidth + pointWidth / 2.0
        this.endPoint = { 'x': endX, 'y': endY }
    }

    draw(context) {
        if (context != null) {
            context.beginPath()
            context.strokeStyle = 'rgba(54, 177, 228,1)'
            context.lineWidth = this.lineWidth

            context.moveTo(this.startPoint.x, this.startPoint.y)
            context.lineTo(this.endPoint.x, this.endPoint.y)
            context.stroke()

            this.drawEndText(context)
        }
    }

    drawEndText(context) {
        let gapIndex = this.endNode.index - this.startNode.index
        if (context != null) {
            context.beginPath();
            context.font = "15px Arial";
            
            if (this.isUp) {
                let fixY = this.endPoint.y
                if(fixY < 15){
                    fixY = 15
                }
                context.fillText(`t:${gapIndex}`, this.endPoint.x, fixY);
            } else {
                context.fillText(`t:${gapIndex}`, this.endPoint.x, this.endPoint.y + 20);
            }
        }
    }
}