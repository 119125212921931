import dayjs from 'dayjs'
const KLineNodeType = {
    NORMAL: 'NORMAL',
    NONE: 'NONE',
};


class KLineNode {
    constructor(kLineModel, index) {
        this.kLineModel = kLineModel
        this.index = index
        this.highPoint = null
        this.lowPoint = null
        this.solidTopPoint = null
        this.solidBottomPoint = null
        this.isUp = true
        // k线程交量标准化
        this.normalizeVolumValue = 0

        // 波段成交量
        this.waveVolumSum = 0
        // 波段累计成交量标准化
        this.normalizeWaveVolumSumValue = 0
        this.isWaveUp = true
        // 节点类型
        this.type = KLineNodeType.NORMAL
    }

    // 索引，pointWidth,可视宽度，可视高度
    caculateDisplayPoint(index, nodeWidth, maxPrice, minPrice, visibleWidth, visibleHeight) {
        this.nodeWidth = nodeWidth
        if (this.type == KLineNodeType.NONE) {
            return
        }
        // 每个价位对应的高度
        let everyPriceHeight = visibleHeight / (maxPrice - minPrice)
        let x = (nodeWidth - 1) / 2.0
        // 最高点
        this.highPoint = { 'x': x, 'y': everyPriceHeight * (maxPrice - this.kLineModel.high) }
        // 最低点
        this.lowPoint = { 'x': x, 'y': everyPriceHeight * (maxPrice - this.kLineModel.low) }

        let solidTopPrice = this.kLineModel.open
        let solidBottomPrice = this.kLineModel.close
        if (this.kLineModel.open < this.kLineModel.close) {
            solidTopPrice = this.kLineModel.close
            solidBottomPrice = this.kLineModel.open
        }
        // 实体的高点
        this.solidTopPoint = { 'x': x, 'y': everyPriceHeight * (maxPrice - solidTopPrice) }
        // 实体的低点
        this.solidBottomPoint = { 'x': x, 'y': everyPriceHeight * (maxPrice - solidBottomPrice) }

        if (this.kLineModel.close < this.kLineModel.open) {
            this.isUp = false
        }
    }

    // 标准化成交量
    nomalizeVolume(maxVolumeNode, minVolumeNode) {
        if (this.type == KLineNodeType.NONE) {
            return
        }
        let normal = (this.kLineModel.volume - minVolumeNode.kLineModel.volume) / (maxVolumeNode.kLineModel.volume - minVolumeNode.kLineModel.volume)
        this.normalizeVolumValue = normal
    }

    // 绘制
    draw(context) {
        if (this.type == KLineNodeType.NONE) {
            return
        }
        if (context != null) {
            let color = this.isUp ? `rgb(181,98,101` : `rgb(124,164,219)`
            // 绘制从顶部到底部
            context.beginPath()
            context.strokeStyle = color
            context.lineWidth = this.nodeWidth * (1.0 / 8)
            context.moveTo(this.highPoint.x, this.highPoint.y)
            context.lineTo(this.lowPoint.x, this.lowPoint.y)
            context.closePath()
            context.stroke()

            // 绘制实体从哪个顶部到底部
            context.beginPath()
            context.strokeStyle = color
            context.lineWidth = this.nodeWidth * (3.0 / 8)
            context.moveTo(this.solidTopPoint.x, this.solidTopPoint.y)
            context.lineTo(this.solidBottomPoint.x, this.solidBottomPoint.y)
            context.closePath()
            context.stroke()
        }
    }
    
    tradeTimeString() {
        if (this.kLineModel == null) {
            return ""
        }
        const tradeTime = this.kLineModel.tradeTime
        const dateObject = dayjs(tradeTime);

        const month = dateObject.month() + 1; // 月份从0开始，所以需要加1  
        const day = dateObject.date();
        return `${month}月${day}日`
    }
}
export { KLineNode, KLineNodeType }