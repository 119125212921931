import React, { Component } from 'react'
import './PFVolumeView.css'
import PFVolumeBarView from './PFVolumeBarView'

export default class PFVolumeView extends Component {
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    this.setVolumeScrollCallBack(this.volumeContainer)
  }
  render() {
    let { pfData,volumeViewHeight, pointContentRealWidth, setVolumeScrollCallBack} = this.props
    let lastRowNodes = pfData.displayNodes[pfData.displayNodes.length - 1]
    this.setVolumeScrollCallBack = setVolumeScrollCallBack
    return (
      <div className='PFVolumeView' style={{ height: volumeViewHeight}} ref={(scrollableRef) => { this.volumeContainer = scrollableRef }}>
        {
          lastRowNodes.map((node, index) => (
            <PFVolumeBarView
              index={index}
              colSumNomalizeVolume={node.colSumNomalizeVolume}
              maxColSumNomalizeVolume={pfData.maxColSumNomalizeVolume}
              volumeViewHeight={volumeViewHeight}
              isUpCol={node.isUpCol}
              pointWidth={pfData.pointWidth}
            > </PFVolumeBarView>
          ))
        }
      </div>
    )
  }
}
