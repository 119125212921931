import React, { Component } from 'react'
import './PFPointView.css'

export default class PFPointView extends Component {
  constructor(props) {
    super(props)
  }

  nodeClick() {
    let { node, index, subIndex } = this.props

    console.log(index)
    console.log(subIndex)

    // this.props.pointClick(node)
  }

  render() {
    let { node, index, pointWidth,basePointWidth, mode } = this.props

    let pointFeatureStyle = node.selected ? 'PFPointView-selected' : 'PFPointView'
    var displayString = node.displayOX
    if (mode == 'VO') {
      displayString = node.displayNomalizeVolume
    }

    var displayFontSize = 14 * pointWidth  / basePointWidth
    var displayTextColor = `rgb(0,0,0)`
    if (mode == 'VO') {
      displayFontSize = 12 * pointWidth  / basePointWidth
      let pfPoint = node.pfPoint
      if (pfPoint != undefined && pfPoint != null) {
        if (pfPoint.type == 0) {
          displayTextColor =  `rgb(124,164,219)`
        } else {
          displayTextColor = `rgb(181,98,101)`
        }
      }
    }

    return (
      <div className={pointFeatureStyle} style={{ width: pointWidth, height: pointWidth, fontSize: displayFontSize, color: displayTextColor }} onClick={() => this.nodeClick()}>
        {displayString}
      </div>
    )
  }
}