import React, { Component } from 'react'
import './KLineVolumeYTitleView.css'
export default class KLineVolumeYTitle extends Component {
  render() {
    let yTitle = [1.0, 0.8, 0.6, 0.4, 0.2, 0]
    // 0.4, 0.2, 0.0
    let { isRightY, yTitleFontSize } = this.props
    let yClassName = "KLineVolumeYTitleView-Left"
    if (isRightY) {
      yClassName = "KLineVolumeYTitleView-Right"
    }
    let yTitleClassName = "KLineVolumeYTitleView-Left-Title "
    if (isRightY) {
      yTitleClassName = "KLineVolumeYTitleView-Right-Title"
    }
    let fontSize = yTitleFontSize

    return (
      <div className={yClassName} style={{ width: this.props.rightPriceAxisYContainerWeight }} >
        {
          yTitle.map((title) => (
            <div className={yTitleClassName} style={{ fontSize: fontSize }}>{title}</div>
          )
          )
        }
      </div>
    )
  }
}
