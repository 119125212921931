import { KLineNode, KLineNodeType } from './KLineNode'
import KLineWaveModel from './KLineWaveNode'

export default class KLineDataLogic {
   constructor(kLineModels, waveBase) {
      this.kLineModels = kLineModels
      this.waveBase = waveBase

      // 最大价格与最小价格
      this.maxPriceNode = null
      this.minPriceNode = null

      // 最大成交量与最小成交量
      this.maxVolumeNode = null
      this.minVolumeNode = null
      // 展示的点
      this.displayNodes = []
      // 波段
      this.displayWaveNodes = []


      // 点的宽度
      this.pointWidth = 8
      this.basePointWidth = 8 // 基准值

      this.visibleWidth = 200
      this.visibleHeight = 200

      // 所有的绘制线
      this.paintLineModels = []

      // 是否是波段成交量
      this.isWaveVolume = true
      // 计算k线显示节点
      this.createKLineDisplayNode()
      // 计算波段节点
      this.createWaveNode()
      // 计算显示节点
      this.caculateKLineDisplay(this.visibleWidth, this.visibleHeight)

   }

   // 更新waveBase
   refreshWaveWithWaveBase(waveBase) {
      this.waveBase = waveBase
      // 重新计算波段
      this.createWaveNode()
      //
      this.caculateKLineDisplay(this.visibleWidth, this.visibleHeight)
   }
   // 刷新
   refreshWave() {
      this.caculateKLineDisplay(this.visibleWidth, this.visibleHeight)
   }
   // 给定宽高，计算显示
   caculateKLineDisplay(visibleWidth, visibleHeight) {
      // 1. 计算最高价，最小价
      // 2. 根据最高级，最小价
      if (this.kLineModels.length <= 1) {
         return
      }

      this.visibleWidth = visibleWidth
      this.visibleHeight = visibleHeight

      // 计算纵坐标显示
      this.createKLineDisplayYTitle(visibleWidth, visibleHeight)
      // 计算显示的node
      this.caculateKLineDisplayPoint(visibleWidth, visibleHeight)
      // 显示波段
      this.caculateDisplayWavePoint()
      // 计算累计成交量
      this.caculateKLineWaveVolum(visibleWidth, visibleHeight)
   }

   createWaveNode() {
      let pointWidth = this.pointWidth
      let localSumVolume = 0
      this.displayWaveNodes = []
      for (let i = 0; i < this.displayNodes.length; i++) {
         let node = this.displayNodes[i]
         // 是空白点
         if (node.type == KLineNodeType.NONE) {

            // let lastWaveNode = this.displayWaveNodes[this.displayWaveNodes.length - 1]
            // console.log("进来了吗")
            // if (node != lastWaveNode.endNode) {
            //    console.log("你好")
            // }
            // let isUp = false
            // if (node.high > lastWaveNode.endNode.high) {
            //    isUp = true
            // }
            // let waveNode = new KLineWaveModel(lastWaveNode.endNode, node, localSumVolume)
            // this.displayWaveNodes.push(waveNode)

            break
         }
         if (this.displayWaveNodes.length == 0) {
            // 虚拟头节点
            let waveNode = new KLineWaveModel(node, node, node.kLine)
            this.displayWaveNodes.push(waveNode)
            continue
         }
         let lastWaveNode = this.displayWaveNodes[this.displayWaveNodes.length - 1]
         if (lastWaveNode.isUp) {
            // 继续上升
            if (node.kLineModel.high - lastWaveNode.endNode.kLineModel.high > 0) {
               lastWaveNode.refreshWaveEndNode(node)
               lastWaveNode.volume = localSumVolume
            } else {
               // 反转产生新的波段
               if (Math.abs(node.kLineModel.high - lastWaveNode.endNode.kLineModel.high) >= this.waveBase) {
                  let waveNode = new KLineWaveModel(lastWaveNode.endNode, node, localSumVolume)
                  this.displayWaveNodes.push(waveNode)
                  localSumVolume = 0
               }
            }
         } else {
            // 下降列
            // 继续下降
            if (node.kLineModel.high - lastWaveNode.endNode.kLineModel.high <= 0) {
               lastWaveNode.refreshWaveEndNode(node)
               lastWaveNode.volume = localSumVolume
            } else {
               // 反转产生新的波段
               if (Math.abs(node.kLineModel.high - lastWaveNode.endNode.kLineModel.high) >= this.waveBase) {
                  let waveNode = new KLineWaveModel(lastWaveNode.endNode, node, localSumVolume)
                  this.displayWaveNodes.push(waveNode)
                  localSumVolume = 0
               }
            }
         }
         localSumVolume = node.kLineModel.volume
         
         // 补充最后一个波段
         if (i < this.displayNodes.length - 1) {
            let nextNode = this.displayNodes[i + 1]
            if (nextNode.type == KLineNodeType.NONE) {
               if (node != lastWaveNode.endNode) {
                  let isUp = false
                  if (node.high > lastWaveNode.endNode.high) {
                     isUp = true
                  }
                  let waveNode = new KLineWaveModel(lastWaveNode.endNode, node, localSumVolume)
                  this.displayWaveNodes.push(waveNode)
               }
            }
         }
      }
   }
   caculateDisplayWavePoint() {
      for (let i = 0; i < this.displayWaveNodes.length; i++) {
         let waveNode = this.displayWaveNodes[i]
         waveNode.renderWave(this.visibleWidth, this.visibleHeight, this.maxPriceNode.kLineModel.high, this.minPriceNode.kLineModel.low, this.pointWidth)
      }
   }
   // 将k线转化为节点
   createKLineDisplayNode() {
      for (let i = 0; i < this.kLineModels.length; i++) {
         const kLine = this.kLineModels[i]
         // 将kLinne转换为节点
         let node = new KLineNode(kLine, i)
         this.displayNodes.push(node)

         // 找到最大的kLinne
         if (this.maxPriceNode == null) {
            this.maxPriceNode = node
         }
         if (this.maxPriceNode.kLineModel.high < node.kLineModel.high) {
            this.maxPriceNode = node
         }

         // 找到最小的kLine
         if (this.minPriceNode == null) {
            this.minPriceNode = node
         }
         if (this.minPriceNode.kLineModel.low > node.kLineModel.low) {
            this.minPriceNode = node
         }

         // 找到最大成交量
         if (this.maxVolumeNode == null) {
            this.maxVolumeNode = node
         }
         if (this.maxVolumeNode.kLineModel.volume < node.kLineModel.volume) {
            this.maxVolumeNode = node
         }

         // 找到最小成交量
         if (this.minVolumeNode == null) {
            this.minVolumeNode = node
         }
         if (this.minVolumeNode.kLineModel.volume > node.kLineModel.volume) {
            this.minVolumeNode = node
         }
      }
      // 右边补充空白点
      for (let i = 0; i < 6; i++) {
         let node = new KLineNode(null, i)
         node.type = KLineNodeType.NONE
         this.displayNodes.push(node)
      }
   }
   // 计算纵坐标
   createKLineDisplayYTitle(visibleWidth, visibleHeight) {
      // 纵坐标
      this.priceTitles = []
      // 一共有几个可读
      const yCount = visibleHeight / 21
      // 每个可读的高度
      let everyHeightPrice = (this.maxPriceNode.kLineModel.high - this.minPriceNode.kLineModel.low) / visibleHeight
      // 更新坐标
      const priceTitles = []

      for (let i = 0; i < yCount; i++) {
         let price = this.maxPriceNode.kLineModel.high - (i * everyHeightPrice * 21)
         let axisYModel = {
            'price': parseFloat(price.toFixed(2)),
            'percent': 0
         }
         priceTitles.push(axisYModel)
      }
      this.priceTitles = priceTitles
   }
   caculateKLineDisplayPoint(visibleWidth, visibleHeight) {
      // 计算displayNodes的坐标
      let maxPriceNode = this.maxPriceNode
      let minPriceNode = this.minPriceNode

      for (let i = 0; i < this.displayNodes.length; i++) {
         let node = this.displayNodes[i]
         node.caculateDisplayPoint(i, this.pointWidth, maxPriceNode.kLineModel.high, minPriceNode.kLineModel.low, visibleWidth, visibleHeight)
         node.nomalizeVolume(this.maxVolumeNode, this.minVolumeNode)
      }
   }
   // 计算累计成交量
   caculateKLineWaveVolum(visibleWidth, visibleHeight) {
      let maxWaveVolumSum = 0
      for (let i = 0; i < this.displayWaveNodes.length; i++) {
         let wave = this.displayWaveNodes[i]
         // 成交量
         let waveVolumSum = 0
         for (let j = wave.startNode.index; j <= wave.endNode.index; j++) {
            if (j == wave.startNode.index) {
               continue
            }
            let node = this.displayNodes[j]

            node.isWaveUp = wave.isUp

            this.isWaveUp = true
            waveVolumSum = waveVolumSum + node.kLineModel.volume
            node.waveVolumSum = waveVolumSum

            console.log(`waveIndex:${i}, nodeIndex:${j},成交量${node.waveVolumSum}`)
         }
         if (waveVolumSum > maxWaveVolumSum) {
            maxWaveVolumSum = waveVolumSum
         }
      }
      console.log(`最大成交量${maxWaveVolumSum}`)

      // 标准化
      for (let i = 0; i < this.displayNodes.length; i++) {
         let node = this.displayNodes[i]
         node.normalizeWaveVolumSumValue = node.waveVolumSum / maxWaveVolumSum

         console.log(`标准化的成交量${node.normalizeWaveVolumSumValue}`)
      }
   }
   // 删除一个元素
   deleteElement(selectedElement) {
      // 判定删除的是否是线条
      let paintLineModels = this.paintLineModels
      let lineSelectIndex = paintLineModels.indexOf(selectedElement)
      if (lineSelectIndex != - 1) {
         paintLineModels.splice(lineSelectIndex, 1)
      }
   }
   // 新增一条绘制的线
   addPaintLine(lineModel) {
      lineModel.pointWidth = this.pointWidth
      this.paintLineModels.push(lineModel)
      console.log("这是paintLine", this.paintLineModels)
   }
   // 进行缩放
   makeScale(plusOrSub) {
      // let scalValue = 0
      if (plusOrSub > 0) {
         // 放大
         this.pointWidth = this.pointWidth + 3
      } else if (plusOrSub == 0) {
         // 恢复
         this.pointWidth = this.basePointWidth
      } else {
         // 缩小
         this.pointWidth = this.pointWidth - 3
      }

      // 缩放
      this.refreshWave()

      // 缩放绘制
      this.makeScalePainLines()
   }

   // 重新加载painLines
   makeScalePainLines() {
      for (let i = 0; i < this.paintLineModels.length; i++) {
         let lineModel = this.paintLineModels[i]
         lineModel.pointWidth = this.pointWidth
         lineModel.refresh(this.pointWidth, this.basePointWidth)
      }
   }
}