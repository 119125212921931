import PFPoint from './PFPoint'
export default class PFAlgorithm {
    constructor(kLineModels, gridVlue, revertValue) {
        // k线数据
        this.kLineModels = kLineModels
        // 格值
        this.pfGridValue = gridVlue
        // 反转数
        this.revertValue = revertValue

        // 计算过后的最高点与最低点
        this.maxPFPrice = 0
        this.minPFPrice = 0
        // col:[price:pfPoint]
        this.colToPoints = {}

        // 高低点法计算
        this.caculateMaxMinPointFeature()
    }
    // 高低点法
    caculateMaxMinPointFeature() {
        // 当前所有的kLines
        let kLineModels = this.kLineModels

        // 第一个k线数据
        let firstKline = kLineModels[0]
        // 计算第一个pfPoint（假设第一个pfPoint是x）
        let grideMuch = parseInt(firstKline.high / this.pfGridValue, 10)
        let firstPFPrice = this.floatDot3(grideMuch * this.pfGridValue)
        //  parseFloat((testInt * this.pfGridValue).toFixed(3))
        let firstPFPoint = new PFPoint(1, firstPFPrice, 0, firstKline.volume)

        let pfPrice = firstPFPoint.pfPrice
        // .toString()
        let dictionary = { [pfPrice]: firstPFPoint }
        // {列：有哪些价格}
        this.colToPoints = { 0: dictionary }
        // 表格的最低价与最高价

        this.minPFPrice = firstPFPoint.pfPrice
        this.maxPFPrice = firstPFPoint.pfPrice

        let lastPFPoint = firstPFPoint
        this.maxVolume = 0
        for (let i = 0; i < kLineModels.length; i++) {
            if (lastPFPoint.col == 4) {
                console.log('到第5行了')
            }
            let oneKLine = kLineModels[i]
            if (lastPFPoint.type == 1) {
                // 当前是上升列
                if (oneKLine.high > lastPFPoint.pfPrice) {
                    // 向上产生新点所需要的点数图价格
                    let newPFPrice = this.floatDot3Add(lastPFPoint.pfPrice, this.pfGridValue)

                    while (oneKLine.high >= newPFPrice) {
                        // 向上添加一个点
                        let pfPoint = new PFPoint(1, newPFPrice, lastPFPoint.col)
                        let dictionary = this.colToPoints[lastPFPoint.col]
                        dictionary[pfPoint.pfPrice] = pfPoint

                        if (lastPFPoint.col != pfPoint.col) {
                            // 如果产生了新列，同一列的加入顺序设置为0
                            pfPoint.putIndex = 0
                        } else {
                            // 如果未产生新列，同一列的加入顺序+1
                            pfPoint.putIndex = lastPFPoint.putIndex + 1
                        }

                        lastPFPoint = pfPoint
                        newPFPrice = this.floatDot3Add(newPFPrice, this.pfGridValue)
                    }

                } else if (oneKLine.low < lastPFPoint.pfPrice - (this.revertValue - 1) * this.pfGridValue) {
                    // 向下反转需要的点数图价格
                    let newPFPrice = this.floatDot3Sub(lastPFPoint.pfPrice, this.pfGridValue)
                    // 判定是否需要重新另起一行
                    let needCheckNewCol = true
                    let toCol = lastPFPoint.col
                    while (oneKLine.low <= newPFPrice) {

                        // 因为要反转了。判定当前列的点数小于等于1，不用重新起一列。
                        let dictionary = this.colToPoints[lastPFPoint.col]
                        let keys = Object.keys(dictionary)
                        if (needCheckNewCol) {
                            if (keys.length > 1) {
                                toCol = toCol + 1
                            }
                            needCheckNewCol = false
                        }

                        // 向上添加一个点
                        let pfPoint = new PFPoint(0, newPFPrice, toCol)
                        let toColDictionary = this.colToPoints[toCol]
                        if (toColDictionary == undefined) {
                            toColDictionary = {}
                            this.colToPoints[toCol] = toColDictionary
                        }
                        toColDictionary[pfPoint.pfPrice] = pfPoint


                        if (lastPFPoint.col != pfPoint.col) {
                            // 如果产生了新列，同一列的加入顺序设置为0
                            pfPoint.putIndex = 0
                        } else {
                            // 如果未产生新列，同一列的加入顺序+1
                            pfPoint.putIndex = lastPFPoint.putIndex + 1
                        }

                        lastPFPoint = pfPoint

                        newPFPrice = this.floatDot3Sub(newPFPrice, this.pfGridValue)
                        //  newPFPrice - this.pfGridValue
                    }
                }

            } else {
                // 当前是下降列
                if (oneKLine.low < lastPFPoint.pfPrice) {
                    // 向下是否添加一个点
                    let newPFPrice = this.floatDot3Sub(lastPFPoint.pfPrice, this.pfGridValue)
                    // lastPFPoint.pfPrice - this.pfGridValue
                    while (oneKLine.low <= newPFPrice) {

                        let pfPoint = new PFPoint(0, newPFPrice, lastPFPoint.col)
                        let dictionary = this.colToPoints[lastPFPoint.col]
                        dictionary[pfPoint.pfPrice] = pfPoint

                        if (lastPFPoint.col != pfPoint.col) {
                            // 如果产生了新列，同一列的加入顺序设置为0
                            pfPoint.putIndex = 0
                        } else {
                            // 如果未产生新列，同一列的加入顺序+1
                            pfPoint.putIndex = lastPFPoint.putIndex + 1
                        }

                        lastPFPoint = pfPoint
                        newPFPrice = this.floatDot3Sub(newPFPrice, this.pfGridValue)
                        // newPFPrice - this.pfGridValue
                    }
                } else if (oneKLine.high > lastPFPoint.pfPrice + (this.revertValue - 1) * this.pfGridValue) {
                    // 是否反转
                    let newPFPrice = this.floatDot3Add(lastPFPoint.pfPrice, this.pfGridValue)
                    // lastPFPoint.pfPrice + this.pfGridValue
                    let needCheckNewCol = true
                    let toCol = lastPFPoint.col
                    while (oneKLine.high >= newPFPrice) {
                        // 判定是否重新起一列
                        let dictionary = this.colToPoints[lastPFPoint.col]
                        let keys = Object.keys(dictionary)
                        if (needCheckNewCol) {
                            if (keys.length > 1) {
                                toCol = toCol + 1
                            }
                            needCheckNewCol = false
                        }

                        // 向上添加一个点
                        let pfPoint = new PFPoint(1, newPFPrice, toCol)
                        let toColDictionary = this.colToPoints[toCol]
                        if (toColDictionary == undefined) {
                            toColDictionary = {}
                            this.colToPoints[toCol] = toColDictionary
                        }
                        toColDictionary[pfPoint.pfPrice] = pfPoint

                        if (lastPFPoint.col != pfPoint.col) {
                            // 如果产生了新列，同一列的加入顺序设置为0
                            pfPoint.putIndex = 0
                        } else {
                            // 如果未产生新列，同一列的加入顺序+1
                            pfPoint.putIndex = lastPFPoint.putIndex + 1
                        }

                        lastPFPoint = pfPoint
                        newPFPrice = this.floatDot3Add(newPFPrice, this.pfGridValue)
                    }
                }
            }
            // 将成交量放在最后产生的点上
            // 1. 一个kLine也可能产生多个pf点，成交量放在最后的点上。
            // 2. 一个kLine也可能不产生pf点，长交量
            lastPFPoint.volume = lastPFPoint.volume + oneKLine.volume
            if (lastPFPoint.volume > this.maxVolume) {
                this.maxVolume = lastPFPoint.volume
            }
            // 计算表格的最大值与最小值
            if (lastPFPoint.pfPrice < this.minPFPrice) {
                this.minPFPrice = lastPFPoint.pfPrice
            }
            if (lastPFPoint.pfPrice > this.maxPFPrice) {
                this.maxPFPrice = lastPFPoint.pfPrice
            }
        }

        console.log(this.colToPoints)
    }

    floatDot3Add(one, two) {
        return parseFloat((one + two).toFixed(3))
    }
    floatDot3Sub(one, two) {
        return parseFloat((one - two).toFixed(3))
    }
    floatDot3(value) {
        return parseFloat(value.toFixed(3))
    }
}