import React, { Component } from 'react'
import PFTopView from './PFTopView'
import PFContentView from './PFContentView'
import PFDataLogic from './PFDataLogic'
import KLineDataLogic from '../Wave/KLineDataLogic'
import PFEmptyView from './PFEmptyView'
import KlineResponseData from './KlineResponseData'
import { getkLineModels } from '../API/KlineDataAPI'
import PFOperateMode from './PFOperateMode'
import dayjs from 'dayjs'
import KLineContentView from '../Wave/KLineContentView'
import ChartType from './ChartType'

export default class PFPage extends Component {
  constructor(props) {
    super(props)
    // 页面状态
    this.state = {
      'symbolModel': null, // 代码
      'period': '1d', // 数据周期
      'startDate': null,  // 开始时间
      'endDate': new Date(),  // 结束时间

      'kLineModels': null, // K线数据

      'chartType': ChartType.PF, // 图表类型
      'waveBase': '自动',// wave的基点
      'grideValue': "自动", // 格值
      'revertValue': 1, // 转向
      'mode': "OX", // 显示模式
      'operateMode': PFOperateMode.NONE, // 操作模式
      'refresh': false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { symbolModel, period, startDate, endDate, grideValue, revertValue, waveBase } = this.state

    // 在symbol发生变化，period，startDate, endDate变化时，重新请求数据
    let needRequest = false
    if (prevState.symbolModel != symbolModel) {
      needRequest = true
    }
    if (prevState.period != period) {
      needRequest = true
    }
    if (prevState.startDate != startDate) {
      needRequest = true
    }
    if (prevState.endDate != endDate) {
      needRequest = true
    }
    // 是否需要请求数据
    let canRequest = false
    if (symbolModel != null && period != null && startDate != null && endDate != null) {
      canRequest = true
    }

    // 重新请求数据
    if (canRequest && needRequest) {
      getkLineModels(symbolModel.symbol, symbolModel.market, startDate, endDate, period, (kLineModels) => {
        // 重新计算
        grideValue = this.caculateGrideValue(kLineModels)

        // 重新计算
        waveBase = this.caculateWaveBase(kLineModels)

        this.setState({
          'grideValue': grideValue,
          'waveBase': waveBase,
          'pfData': new PFDataLogic(kLineModels, grideValue, revertValue),
          'kLineLogic': new KLineDataLogic(kLineModels, waveBase)
        })
      })
    } else {
      // grideValue发生变化
      if (prevState.grideValue != grideValue || prevState.revertValue != revertValue) {
        this.state.pfData.refreshPFPointDisplay(grideValue, revertValue)
        this.refreshView()
      }
      // waveBase发生变化
      if (prevState.waveBase != waveBase) {
        this.state.kLineLogic.refreshWaveWithWaveBase(waveBase)
        this.refreshView()
      }
    }
  }
  refreshView() {
    this.setState({ 'refresh': !this.state.refresh })
  }
  pfConfigDataChangedCallBack(changedObject) {
    this.setState(changedObject)
  }

  // 根据价格计算推荐的格值
  caculateGrideValue(kLineModels) {
    let grideValue = 1
    if (kLineModels.length > 0) {
      let item = kLineModels[0]
      if (item.open < 0.25) {
        grideValue = 0.0625
      } else if (item.open < 1) {
        grideValue = 0.125
      } else if (item.open < 5) {
        grideValue = 0.25
      } else if (item.open < 20) {
        grideValue = 0.5
      } else if (item.open < 100) {
        grideValue = 1
      } else if (item.open < 200) {
        grideValue = 2
      } else if (item.open < 500) {
        grideValue = 4
      } else if (item.open < 1000) {
        grideValue = 5
      } else if (item.open < 25000) {
        grideValue = 50
      } else {
        grideValue = 500
      }
    }
    return grideValue
  }
  // 根据价格加速推荐的波段基础
  caculateWaveBase(kLineModels) {
    let waveBase = 1
    if (kLineModels.length > 0) {
      let item = kLineModels[0]
      if (item.open < 0.25) {
        waveBase = 0.0625
      } else if (item.open < 1) {
        waveBase = 0.125
      } else if (item.open < 5) {
        waveBase = 0.2
      } else if (item.open < 10){
        waveBase = 0.25
      }
      else if (item.open < 20) {
        waveBase = 0.5
      } else if (item.open < 100) {
        waveBase = 1
      } else if (item.open < 200) {
        waveBase = 2
      } else if (item.open < 500) {
        waveBase = 4
      } else if (item.open < 1000) {
        waveBase = 10
      } else if (item.open < 10000) {
        waveBase = 20
      } else {
        waveBase = 50
      }
    }
    return waveBase
  }


  render() {
    const { pfData, kLineLogic } = this.state
    let isEmpty = true
    if (pfData != null) {
      isEmpty = false
    }

    let pfConfigData = {
      ...this.state
    }
    let chart = <PFContentView
      pfConfigData={pfConfigData}
      pfData={pfData}
      pfConfigDataChangedCallBack={(state) => this.pfConfigDataChangedCallBack(state)}></PFContentView>
    if (pfConfigData.chartType == ChartType.WAVE) {
      chart = <KLineContentView
        pfConfigData={pfConfigData}
        kLineLogic={kLineLogic}
        pfConfigDataChangedCallBack={(state) => this.pfConfigDataChangedCallBack(state)}
      ></KLineContentView>
    }
    return (
      <div className='PFPage'>
        <PFTopView pfConfigData={pfConfigData} pfConfigDataChangedCallBack={(state) => this.pfConfigDataChangedCallBack(state)}
        ></PFTopView>
        {
          isEmpty ?
            <PFEmptyView></PFEmptyView> : chart
        }
      </div>
    )
  }
}
