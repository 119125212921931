export default class PFOneBaseModel {
    constructor() {
        this.selectedCircleWidth = 4
    }
    moveByOffset(diffX, diffY) {
        // 空实现
    }
    adjust() {
        // 空实现
    }
    draw(context, selected) {

    }
    isPointIn(point) {

    }
    isPointOnCircle(center, radius, point) {
        let centerX = center.x
        let centerY = center.y
        let x = point.x
        let y = point.y
        const distance = Math.sqrt(Math.pow(x - centerX, radius / 2.0) + Math.pow(y - centerY, radius / 2.0));

        if (distance < radius) {
            console.log("点在圆内");
            return true
        } else {
            return false
            console.log("点在圆外");
        }
    }
    drawOneDot(context, point) {
        // 绘制圆
        context.beginPath();
        context.arc(point.x, point.y, this.selectedCircleWidth, 0, 2 * Math.PI, false);
        context.fillStyle = 'rgba(231, 231, 231,1)'
        context.fill();

        context.lineWidth = 1;
        context.strokeStyle = `rgb(181,98,101`;
        context.stroke();
    }

}