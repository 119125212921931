import React, { Component } from 'react'
import './KLineVolumeBarView.css'
export default class KLineVolumeBarView extends Component {
  constructor(props) {
    super(props)
  }
  render() {

    let { node, volumeBarViewHeight, pointWidth, isWaveVolume} = this.props

    let barWidth = pointWidth -  1
    if (barWidth <= 0) {
      barWidth = 1
    }
    // bar的高度
    let barTopMargin = 2
    let barHeight = (volumeBarViewHeight - barTopMargin) * node.normalizeVolumValue
    if (isWaveVolume){
      // 是波段你的成交量
      barHeight = (volumeBarViewHeight - barTopMargin)  * node.normalizeWaveVolumSumValue
      console.log(`计算的成交量高度${barHeight},成交量${node.normalizeWaveVolumSumValue}`)
    }

    // bar的颜色
    let backgroundColor = node.isUp ? `rgb(181,98,101`:  `rgb(124,164,219)`
    if(isWaveVolume){
      backgroundColor = node.isWaveUp ? `rgb(181,98,101`:  `rgb(124,164,219)`
    }
    return (
      <div className='KLineVolumeBarView' style={{ width: pointWidth}}>
        <div className='KLineVolumeBarViewBar' style={{height: barHeight, backgroundColor: backgroundColor, width: barWidth}}></div>
      </div>
    )
  }
}
