import PFOneBaseModel from './PFOneBaseModel'
export default class PFOneLineModel extends PFOneBaseModel {
    constructor(startPoint, endPoint, pointWidth) {
        super()
        this.startPoint = startPoint
        this.endPoint = endPoint
        this.lineWidth = 2

        this.isOnStartPoint = false
        this.isOnEndPoint = false
        
        this.pointWidth = pointWidth
        if(this.pointWidth == null){
            this.pointWidth = 21
        }
    }

    isPointIn(point) {
        super.isPointIn(point)
        let x = point.x
        let y = point.y

        let x1 = this.startPoint.x
        let y1 = this.startPoint.y

        let x2 = this.endPoint.x
        let y2 = this.endPoint.y

        var isOnline = this.isPointOnLine(x, y, x1, y1, x2, y2)
        this.isOnStartPoint = this.isPointOnCircle(this.startPoint, this.selectedCircleWidth, point)
        this.isOnEndPoint = this.isPointOnCircle(this.endPoint, this.selectedCircleWidth, point)

        return isOnline || this.isOnStartPoint || this.isOnEndPoint
    }

    isPointOnLine(x, y, x1, y1, x2, y2) {
        const slope = (y2 - y1) / (x2 - x1);
        const intercept = y1 - slope * x1;
        const calculatedY = slope * x + intercept;
        return Math.abs(y - calculatedY) <= this.lineWidth / 2.0; // 考虑线段宽度为 5 的情况，允许一定的误差范围
    }
    // 缩放
    makeScale(scalValue) {
        console.log(`缩放值scale:${scalValue}`)
        console.log(`原始值startX:${this.startPoint.x},endX:${this.endPoint.x}`)
        let startPoint = { 'x': this.startPoint.x * (1 + scalValue), 'y': this.startPoint.y * (1 + scalValue) }
        let endPoint = { 'x': this.endPoint.x * (1 + scalValue), 'y': this.endPoint.y * (1 + scalValue) }
        this.startPoint = startPoint
        this.endPoint = endPoint
        console.log(`修改后原始值startX:${this.startPoint.x},endX:${this.endPoint.x}`)
    }
    // 给出肌醇值
    makeScaleRefresh(pointWidth, basePointWidth){
       let scalValue = pointWidth / basePointWidth
        console.log(`缩放值scale:${scalValue}`)
        console.log(`原始值startX:${this.startPoint.x},endX:${this.endPoint.x}`)
        let startPoint = { 'x': this.startPoint.x * (1 + scalValue), 'y': this.startPoint.y * (1 + scalValue) }
        let endPoint = { 'x': this.endPoint.x * (1 + scalValue), 'y': this.endPoint.y * (1 + scalValue) }
        this.startPoint = startPoint
        this.endPoint = endPoint
        console.log(`修改后原始值startX:${this.startPoint.x},endX:${this.endPoint.x}`)
    }
    // 移动
    moveByOffset(diffX, diffY) {
        super.moveByOffset(diffX, diffY)
        if (this.isOnStartPoint) {
            let startPoint = { 'x': this.startPoint.x + diffX, 'y': this.startPoint.y + diffY }
            this.startPoint = startPoint
        } else if (this.isOnEndPoint) {
            let endPoint = { 'x': this.endPoint.x + diffX, 'y': this.endPoint.y + diffY }
            this.endPoint = endPoint
        } else {
            let startPoint = { 'x': this.startPoint.x + diffX, 'y': this.startPoint.y + diffY }
            let endPoint = { 'x': this.endPoint.x + diffX, 'y': this.endPoint.y + diffY }
            this.startPoint = startPoint
            this.endPoint = endPoint
        }
    }

    draw(context, selected) {
        super.draw(context, selected)
        if (context != null) {
            let lineModel = this
            context.beginPath()
            context.strokeStyle = 'rgba(54, 177, 228,1)'
            context.lineWidth = this.lineWidth

            // this.startPoint.x = this.pointWidth * 2
            // this.endPoint.x = this.pointWidth * 3

            console.log(`绘制startX:${this.startPoint.x},startY:${this.startPoint.y},endX:${this.endPoint.x}, endY:${this.endPoint.y}`)

            context.moveTo(lineModel.startPoint.x, lineModel.startPoint.y)
            context.lineTo(lineModel.endPoint.x, lineModel.endPoint.y)

            context.closePath()
            context.stroke()

            if (selected) {
                this.drawOneDot(context, lineModel.startPoint)
                this.drawOneDot(context, lineModel.endPoint)
            }
        }
    }
}