import React, { Component } from 'react'
import './PFTopView.css'
import { Select, DatePicker, Input, Button, Space, Radio, Divider } from 'antd'
import PFOperateMode from './PFOperateMode'
import { EditOutlined, BorderOuterOutlined, VerticalAlignTopOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import { stock_symbol_sh } from '../API/stock_symbol_sh';
import { stock_symbol_hk } from '../API/stock_symbol_hk'
import { stock_symbol_us } from '../API/stock_symbol_us'
import StockSymbolModel from './StockSymbolModel'
import us_basic_1_json from '../API/us_basic_1.json'
import us_basic_2_json from '../API/us_basic_2.json'
import us_basic_3_json from '../API/us_basic_3.json'
import us_basic_4_json from '../API/us_basic_4.json'
import dayjs from 'dayjs'
import ChartType from './ChartType'
export default class PFTopView extends Component {
    constructor(props) {
        super(props)
        this.options = []
        this.loadAllMargetSymbol()
    }
    loadAllMargetSymbol() {

        this.allSymbolModel = []
        // 上证的股市场数据
        for (let i = 0; i < stock_symbol_sh.length; i++) {
            let symbolObj = stock_symbol_sh[i]
            let stockModel = new StockSymbolModel(symbolObj.symbol, symbolObj.name, 'sh')
            this.allSymbolModel.push(stockModel)

            let index = this.allSymbolModel.length - 1
            this.options.push({ value: index, label: stockModel.symbol + '(' + stockModel.name + ')' })
        }
        // 美股市场数据
        // 美股股票数据
        // let us_basic_1_items = us_basic_1_json.data.items
        // let us_basic_2_items = us_basic_2_json.data.items
        // let us_basic_3_items = us_basic_3_json.data.items
        // let us_basic_4_items = us_basic_4_json.data.items
        // let us_basic_all = [...us_basic_1_items, ...us_basic_2_items, ...us_basic_3_items, ...us_basic_4_items]

        // for (let i = 0; i < us_basic_all.length; i++) {
        //     let item = us_basic_all[i]
        //     let symbol = item[0]
        //     let name = item[2]

        //     let stockModel = new StockSymbolModel(symbol, name, 'us')
        //     this.allSymbolModel.push(stockModel)

        //     let index = this.allSymbolModel.length - 1
        //     this.options.push({ value: index, label: stockModel.symbol + '(' + stockModel.name + ')' })
        // }
        // 香港市场数据
        for (let i = 0; i < stock_symbol_hk.length; i++) {
            let symbolObj = stock_symbol_hk[i]
            let stockModel = new StockSymbolModel(symbolObj.symbol, symbolObj.name, 'hk')
            this.allSymbolModel.push(stockModel)

            let index = this.allSymbolModel.length - 1
            this.options.push({ value: index, label: stockModel.symbol + '(' + stockModel.name + ')' })
        }
        // 美股市场数据
        for (let i = 0; i < stock_symbol_us.length; i++) {
            let symbolObj = stock_symbol_us[i]
            // 美股的单独处理下
            // "107.CGV";  
            let splitArray = symbolObj.symbol.split(".");  
            let symbolName = symbolObj.symbol
            if (splitArray.length >= 2){
                symbolObj.symbol = splitArray[1]
            }
            let stockModel = new StockSymbolModel(symbolObj.symbol, symbolObj.name, 'us')
            this.allSymbolModel.push(stockModel)

            let index = this.allSymbolModel.length - 1
            this.options.push({ value: index, label: stockModel.symbol + '(' + stockModel.name + ')' })
        }
    }

    // 符号变化了
    symbolChanged(index) {
        let stockModel = this.allSymbolModel[index]
        this.props.pfConfigDataChangedCallBack({ 'symbolModel': stockModel })
    }
    // 周期发生变化
    periodChanged(period) {
        this.props.pfConfigDataChangedCallBack({ 'period': period })
    }
    // 开始时间变化了
    startDateChanged(date) {
        this.props.pfConfigDataChangedCallBack({ 'startDate': date.toDate() })
    }
    // 结束时间变化了
    endDateChanged(date) {
        this.props.pfConfigDataChangedCallBack({ 'endDate': date.toDate() })
    }
    // 格值变化了
    gridValueChanged(value) {
        this.props.pfConfigDataChangedCallBack({ 'grideValue': parseFloat(value) })
    }
    // 反转变化了
    revertValueChanged(value) {
        this.props.pfConfigDataChangedCallBack({ 'revertValue': value })
    }
    // 开始生成点数图
    generalPointFeature() {

    }
    // 模式的变化了
    onModeChange(event) {
        this.props.pfConfigDataChangedCallBack({ 'mode': event.target.value })
    }
    // 操作模式变化了
    onOperateModeChange(operateMode) {
        // operateMode
        this.props.pfConfigDataChangedCallBack({ 'operateMode': operateMode })
    }
    onchartTypeChange(value) {
        this.props.pfConfigDataChangedCallBack({ 'chartType': value })
    }
    onwaveBaseChange(value) {
        this.props.pfConfigDataChangedCallBack({ 'waveBase': value })
    }
    render() {
        const options = this.options

        let { grideValue, operateMode, period, endDate, revertValue, mode, chartType, waveBase } = this.props.pfConfigData

        let measureUpStyle = {}
        let measureDownStyle = {}
        let distributionStyle = {}
        let paintStyle = {}
        let seletedStyle = { color: 'rgba(22, 92, 254,1)' }

        // 当前的操作模式
        if (operateMode == PFOperateMode.MEASURE_UP) {
            measureUpStyle = seletedStyle
        } else if (operateMode == PFOperateMode.MEASURE_DOWN) {
            measureDownStyle = seletedStyle
        } else if (operateMode == PFOperateMode.DISTRIBUTION) {
            distributionStyle = seletedStyle
        } else if (operateMode == PFOperateMode.PAINT) {
            paintStyle = seletedStyle
        }

        // 当前的图标类型
        let isPFChartType = true
        if (chartType == ChartType.WAVE) {
            isPFChartType = false
        }
        let modeToolView = <div>
            <Space size={4} style={{ flexShrink: 0 }}>
                {/* 格值的选择，自动推荐，自定义，atr */}
                <div className='TitleStyle'>格值:</div>
                <Input placeholder={grideValue} style={{ width: 60, minWidth: 60 }} onBlur={(event) => {
                    this.gridValueChanged(event.target.value)
                }} />

                <div className='TitleStyle'>转向:</div>
                <Select
                    defaultValue={revertValue}
                    style={{ width: 65, minWidth: 65 }}
                    onChange={(value) => this.revertValueChanged(parseInt(value))}
                    options={[
                        { value: '1', label: '1格' },
                        { value: '3', label: '3格' },
                        { value: '5', label: '5格' },
                    ]}
                />

                <Radio.Group defaultValue={mode} onChange={(event) => this.onModeChange(event)}>
                    <Radio value='OX'>OX</Radio>
                    <Radio value='VO'>量格</Radio>
                </Radio.Group>

                <Divider type="vertical" className='DividerStyle' />

                <Button style={measureUpStyle} onClick={() => this.onOperateModeChange(PFOperateMode.MEASURE_UP)}>测上涨</Button>
                <Button style={measureDownStyle} onClick={() => this.onOperateModeChange(PFOperateMode.MEASURE_DOWN)} >测下跌</Button>
                <Button style={distributionStyle} onClick={() => this.onOperateModeChange(PFOperateMode.DISTRIBUTION)} >分布</Button>
                <Button style={paintStyle} onClick={() => this.onOperateModeChange(PFOperateMode.PAINT)}>划线</Button>
            </Space>
        </div>
        if (isPFChartType == false) {
            modeToolView = <Space size={4} style={{ flexShrink: 0 }}>
                {/* 格值的选择，自动推荐，自定义，atr */}
                <div className='TitleStyle'>基点:</div>
                <Input placeholder={waveBase} style={{ width: 70, minWidth: 70 }} onBlur={(event) => {
                    this.onwaveBaseChange(event.target.value)
                }} />
                <Divider type="vertical" className='DividerStyle' />
                <Button style={paintStyle} onClick={() => this.onOperateModeChange(PFOperateMode.PAINT)}>划线</Button>
            </Space>

        }
        return (
            <div className='PFTopView'>

                <div style={{ width: 10, flexShrink: 0 }}></div>
                <Space size={4} style={{ flexShrink: 0 }}>
                    <div className='TitleStyle'>代码:</div>

                    <Select
                        showSearch
                        style={{
                            width: 180,
                            minWidth: 180
                        }}
                        placeholder="代码编号"
                        onChange={(index) => this.symbolChanged(index)}
                        filterOption={(input, option) => (option?.label ?? '').includes(input)   || (option?.label ?? '').includes(input.toUpperCase())  }
                        options={options}
                    />

                    <div className='TitleStyle'>周期:</div>
                    <Select
                        defaultValue={period}
                        style={{ width: 75, minWidth: 75 }}
                        onChange={(value) => this.periodChanged(value)}
                        options={[
                            { value: '1d', label: '日' },
                            { value: '1w', label: '周' },
                            { value: '1M', label: '月' },
                            { value: 'm5', label: '5min' },
                            { value: 'm15', label: '15min' },
                            { value: 'm30', label: '30min' },
                            { value: 'm60', label: '60min' },
                        ]}
                    />

                    <div className='TitleStyle'>起始:</div>
                    <DatePicker style={{ width: 120, minWidth: 120 }} onChange={(date) => this.startDateChanged(date)} allowClear={false} />

                    {/* <div className='TitleStyle'>截止:</div> */}
                    {/* <DatePicker style={{ width: 120, minWidth: 120 }} defaultValue={dayjs(endDate)} onChange={(date) => this.endDateChanged(date)} /> */}

                    <Divider type="vertical" className='DividerStyle' />

                    <Select
                        defaultValue={chartType}
                        style={{ width: 85, minWidth: 85 }}
                        onChange={(value) => this.onchartTypeChange(value)}
                        options={[
                            { value: ChartType.PF, label: '点数图' },
                            { value: ChartType.WAVE, label: '波形图' },
                        ]}
                    />
                    <Divider type="vertical" className='DividerStyle' />
                    {modeToolView}
                </Space>
            </div>
        )
    }
}


